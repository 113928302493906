import { Injectable } from '@angular/core';
import { HttpClient,  HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from '../../../shared/services/shared.service';

@Injectable()
export class SearchService {

    //#region Global Variables


    //#endregion


    //#region Init

    constructor(private http: HttpClient, private _sharedService: SharedService) {
    }

    //#endregion

    //#region service methods


    createSearchComponents(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Search/CreateContainers?assetID=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    createAssetSearchComponents(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Search/CreateAzureContainers`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getSearchComponents(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Search/Detail`, '', this._sharedService.getReqOptWithAccessToken());
    }

    rebuildSearch(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Search/RebuildSearch`, body, this._sharedService.getReqOptWithAccessToken());
    }

    deleteIndexer(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Search/DeleteIndexer`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getSearchDatabase(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Search/SearchDatabase`, '', this._sharedService.getReqOptWithAccessToken());
    }
    //#endregion


}