<div class="jarviswidget-ctrls" role="menu">
    <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="showAddDialog()" id="btnadd"
        (click)="showAddDialog()" title="Add Container (Ctrl+A)">
        <i class="glyphicon glyphicon-plus"></i>
    </a>
</div>
<form (ngSubmit)="onSubmit(heroForm)" #heroForm="ngForm" *ngIf="display">
    <p-dialog [(visible)]="display" showEffect="fade" [modal]="true" [style]="{width: '733px'}" [resizable]="false">
        <ng-template pTemplate="header"  (keyup.shift.tab)="onKey()">
            {{dialogTitle}}
        </ng-template>
        <div class="upload-dialog">
            <!--style="overflow-y: hidden !important;overflow-x:hidden !important"-->
            <div class="smart-form">
                <div class="row" *ngIf="recordType=='DOC'||recordType=='BND'">
                    <fieldset>
                        <label class="label">
                            {{_sharedService.getContainerLabel(recordType, "ID")}}
                            <span [hidden]="model.ID" class="text-danger">
                                *
                            </span>
                        </label>
                        <label class="input minzindex">
                            <input id="ID" type="text" name="ID" placeholder="container ID" [(ngModel)]="model.ID"
                                #ID="ngModel" [required]="recordType=='DOC'||recordType=='BND'">
                        </label>
                    </fieldset>
                </div>
                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                {{_sharedService.getContainerLabel(recordType, "Type")}}
                                <span [hidden]="wotype.valid" class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" [autofocus]="true" id="wotype" name="wotype" [options]="WOTypes" required
                                placeholder="Select Type" [(ngModel)]="model.wotype" #wotype="ngModel" [filter]="true"
                                #dd #addwotypefilter [resetFilterOnHide]="true">

                            </p-dropdown>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6" *ngIf="recordType=='MXR'||recordType=='DOC'||recordType=='BND'">
                        <fieldset>
                            <label class="label">
                                {{_sharedService.getContainerLabel(recordType, "ReferenceKey")}}
                                <!-- {{referenceKeyLabel}} -->
                                <span [hidden]="recordType=='DOC'||recordType=='BND' || model.wonumber"
                                    class="text-danger">
                                    *
                                </span>
                                <span *ngIf="lastIsDotWonum" class="text-danger">
                                    Dot(.) is not allowed at the end
                                </span>
                            </label>

                            <label class="input rgxErr">
                                <i *ngIf="wonum.value && !wonum.valid && (wonum.touched || wonum.dirty )"
                                    class="icon-append fa fa-ban"
                                    title="{{_sharedService.error_rgx_pre}} {{_sharedService.re_WorkOrderNumber_Message}} {{_sharedService.error_rgx_post}}">
                                </i>
                                <input id="wonum" type="text" name="wonum" placeholder={{referenceKeyLabel}}
                                    [(ngModel)]="model.wonumber" [pattern]="_sharedService.re_WorkOrderNumber"
                                    (focusout)="isLastDotWonum(wonum.value)"
                                    [required]="(recordType!=='DOC' && recordType!=='BND') && model.enddate==null"
                                    #wonum="ngModel"
                                    [disabled]="(recordType!=='DOC' && recordType!=='BND') && model.enddate!=null"
                                    [ngClass]="{'readonlyinput2': (recordType!=='DOC' && recordType!=='BND') && model.enddate!=null}" />

                            </label>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6" *ngIf="recordType=='GEN'">
                        <fieldset>
                            <label class="label">
                                <!-- {{WorkOrderLabel}} -->
                                {{_sharedService.getContainerLabel(recordType, "ReferenceKey")}}
                                <span [hidden]="model.generalID" class="text-danger">
                                    *
                                </span>
                                <span *ngIf="lastIsDot" class="text-danger">
                                    Dot(.) is not allowed at the end
                                </span>
                            </label>
                            <label class="input rgxErr">
                                <span
                                    *ngIf="generalID.value && !generalID.valid && (generalID.touched || generalID.dirty )"
                                    class="icon-append fa fa-ban"
                                    title="{{_sharedService.error_rgx_pre}} {{_sharedService.re_WorkOrderNumber_Message}} {{_sharedService.error_rgx_post}}">
                                </span>

                                <input id="generalID" type="text" name="generalID" placeholder={{WorkOrderLabel}}
                                    [pattern]="_sharedService.re_WorkOrderNumber" required [(ngModel)]="model.generalID"
                                    (focusout)="isLastDot(generalID.value)" #generalID="ngModel">

                            </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                {{_sharedService.getContainerLabel(recordType, "Date")}}
                                <span [hidden]="crsdate.valid" class="text-danger">
                                    *
                                </span>
                                ({{ViewDateFormat}})
                            </label>
                            <label class="input">
                                <i class='icon-append fa fa-calendar'></i>
                                <p-calendar appendTo="body" [attr.tabindex]="0" placeholder="{{ViewDateFormat}}"
                                    class='datepicker hasDatepicker' id='crsdate' (onClose)="onCloseDate()"
                                    name="crsdate" [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030"
                                    [required]="(recordType!=='DOC' && recordType!=='BND')" [(ngModel)]="model.crsdate"
                                    #crsdate="ngModel" [dateFormat]="dateFormatCalender" [style]="{width:'315px'}">
                                </p-calendar>

                            </label>

                        </fieldset>

                    </div>
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                <!-- End Date  -->
                                {{_sharedService.getContainerLabel(recordType, "EndDate")}}
                                <span [hidden]="enddate.valid || model.wonumber.length >0" class="text-danger">
                                    *
                                </span>
                                ({{ViewDateFormat}})
                            </label>
                            <label class="input enddate"
                                [ngClass]="{'readonlyinput': recordType=='MXR' && (model.wonumber.length>0)}">
                                <i class='icon-append fa fa-calendar'></i>
                                <p-calendar appendTo="body" [attr.tabindex]="0" placeholder="{{ViewDateFormat}}"
                                    class='datepicker hasDatepicker' id='enddate' (onClose)="onCloseEnddate()"
                                    name="enddate" [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030"
                                    [required]="(recordType=='MXR' && (model.wonumber.length==0))||recordType=='LOG'"
                                    [disabled]="recordType=='MXR' && (model.wonumber.length>0)"
                                    [(ngModel)]="model.enddate" #enddate="ngModel" [dateFormat]="dateFormatCalender"
                                    [style]="{width:'315px'}">

                                </p-calendar>
                            </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                <!-- Description -->
                                {{_sharedService.getContainerLabel(recordType, "Description")}}
                            </label>
                            <label class="input">
                                <span id="desr" [attr.tabindex]="0">
                                    <input [attr.tabindex]="0" id="desr" type="text" name="desr"
                                        placeholder="Description" [(ngModel)]="model.description" #desr="ngModel">
                                    <!-- style="max-width: 680px !important" -->
                                </span>
                            </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                <!-- Box Type -->
                                {{_sharedService.getContainerLabel(recordType, "BoxType")}}
                            </label>
                            <label class="input">
                                <p-autoComplete id="boxtype" name="boxtype" placeholder="Box Type"
                                    [(ngModel)]="model.boxtype" [suggestions]="boxTypeSuggestion"
                                    (completeMethod)="searchBoxType($event)" #boxtype="ngModel" [style]="{width:'100%'}"
                                    scrollHeight="130px"></p-autoComplete> <!-- [style]="{width:'226px'}" -->

                            </label>
                        </fieldset>
                    </div>
                    <!--<div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">Box ID</label>
                            <label class="input">
                                <input id="boxid" type="text" name="boxid"
                                       placeholder="Box ID" [(ngModel)]="model.boxid" #boxid="ngModel">
                            </label>
                        </fieldset>
                    </div>-->
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                <!-- Box ID -->
                                {{_sharedService.getContainerLabel(recordType, "BoxID")}}
                            </label>
                            <label class="input">
                                <p-autoComplete id="boxid" name="boxid" placeholder="Box ID" [(ngModel)]="model.boxid"
                                    [suggestions]="boxIDSuggestion" (completeMethod)="searchBoxID($event)"
                                    #boxid="ngModel" [style]="{width:'100%'}" scrollHeight="130px"></p-autoComplete>
                                <!-- [style]="{width:'226px'}" -->

                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                <!-- Box Location -->
                                {{_sharedService.getContainerLabel(recordType, "BoxLocation")}}
                            </label>
                            <label class="input">
                                <p-autoComplete id="boxlocation" name="boxlocation" placeholder="Box Location"
                                    [(ngModel)]="model.boxlocation" [suggestions]="boxLocationSuggestion"
                                    (completeMethod)="searchBoxLocation($event)" #boxlocation="ngModel"
                                    [style]="{width:'100%'}" scrollHeight="130px"></p-autoComplete>
                                <!-- [style]="{width:'220px'}" -->
                            </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                <!-- Scanned On  -->
                                {{_sharedService.getContainerLabel(recordType, "ScannedOn")}}
                                ({{ViewDateFormat}})
                            </label>
                            <label class="input">
                                <i class='icon-append fa fa-calendar'></i>
                                <p-calendar appendTo="body" placeholder="{{ViewDateFormat}}" (onClose)="onCloseScannedOn()"
                                    class='datepicker hasDatepicker' id='scanneddate' name="scanneddate"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030"
                                    [(ngModel)]="model.scanneddate" #scanneddate="ngModel" [style]="{width:'93%'}"
                                    [dateFormat]="dateFormatCalender">
                                    <!-- [style]="{width:'315px'}" -->
                                </p-calendar>
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6 zindex">
                        <fieldset>
                            <label class="label">
                                <!-- Scanned By -->
                                {{_sharedService.getContainerLabel(recordType, "ScannedBy")}}
                            </label>
                            <label class="input minzindex">
                                <span id="scannedby" [attr.tabindex]="0">
                                    <p-autoComplete id="scannedby" name="scannedby" [(ngModel)]="model.scannedby"
                                        [suggestions]="scannedBySuggestion" (completeMethod)="searchScannedBy($event)"
                                        #scannedby="ngModel" [style]="{width:'100%'}" scrollHeight="67px">
                                    </p-autoComplete>
                                </span>
                                <!-- [style]="{width:'332px'}" -->
                            </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row" *ngIf="recordType=='LOG'">
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                <!-- IncrementValue -->
                                {{_sharedService.getContainerLabel(recordType, "IncrementValue")}}
                                <span [hidden]="model.IncrementValue>=1" class="text-danger">
                                    *
                                </span>
                            </label>
                            <label class="input">
                                <input id="IncrementValue" name="IncrementValue" type="number" requried
                                    placeholder="Increment Value" [(ngModel)]="model.IncrementValue"
                                    #IncrementValue="ngModel" [style]="{width:'100%'}" scrollHeight="130px">
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                <!-- StartNumber -->
                                {{_sharedService.getContainerLabel(recordType, "StartNumber")}}
                            </label>
                            <label class="input">
                                <input id="StartNumber" name="StartNumber" type="number" placeholder="Start Number"
                                    [(ngModel)]="model.StartNumber" #StartNumber="ngModel" [style]="{width:'100%'}"
                                    scrollHeight="130px">

                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                <!-- NumberOfPages -->
                                {{_sharedService.getContainerLabel(recordType, "NumberOfPages")}}
                            </label>
                            <label class="input">
                                <input id="NumberOfPages" name="NumberOfPages" type="number"
                                    placeholder="Number of Pages" [(ngModel)]="model.NumberOfPages"
                                    #NumberOfPages="ngModel" [style]="{width:'100%'}" scrollHeight="130px">

                            </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                <!-- Live -->
                                {{_sharedService.getContainerLabel(recordType, "Live")}}
                            </label>
                            <label class="checkbox">
                                <span class="onoffswitch tablIndexFocus" [attr.tabindex]="0"
                                    (keyup.enter)="ToggleChecked()">
                                    <input type="checkbox" name="islive" [checked]="isChecked"
                                        class="onoffswitch-checkbox" checked="checked" id="islive"
                                        [(ngModel)]="model.islive" #islive="ngModel" [disabled]="!canMakeContainerLive">
                                    <label class="onoffswitch-label" for="islive">
                                        <span class="onoffswitch-inner" data-swchon-text="YES"
                                            data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span>
                                    </label>
                                </span>
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6"
                        *ngIf="((canGENQualityStatus && processGENQualityStatusEnabled) || (canMXRQualityStatus && processMXRQualityStatusEnabled) || (canLOGQualityStatus && processLOGQualityStatusEnabled)|| (canDOCQualityStatus && processDOCQualityStatusEnabled)|| (canBNDQualityStatus && processBNDQualityStatusEnabled))">
                        <fieldset>
                            <label class="label">
                                <!-- Status  -->
                                {{_sharedService.getContainerLabel(recordType, "Status")}}
                                <span class="text-danger">*</span>
                            </label>
                            <span>
                                <p-dropdown appendTo="body" [panelStyle]="{'bottom': 'calc(100% + 5px)'}" [options]="qualityStatusList" requried name="QualityStatusID"
                                    #QualityStatusID="ngModel" placeholder="Select Quality Status"
                                    [style]="{width:'300px'}" [(ngModel)]="model.QualityStatusID" [filter]="true">
                                </p-dropdown>
                            </span>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="submit" pButton icon="fa fa-check" class="btn btn-primary minzindex" label="Add"
                    [disabled]="!heroForm.form.valid || isSubmitted || lastIsDot  || lastIsDotWonum || (recordType=='LOG' && model.IncrementValue <= 0)  || (recordType=='LOG' && (model.StartNumber < 0 || model.NumberOfPages < 0 ))"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnCancelClick(heroForm)" label="Cancel" [disabled]="isSubmitted"
                    (focusout)="dd.applyFocus()"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>