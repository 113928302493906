import { DomSanitizer } from '@angular/platform-browser';
import { ArticleService } from './../../services/article/article.service';
import { Router } from '@angular/router';
import { SharedService } from './../../../shared/services/shared.service';
import { Component, OnInit } from '@angular/core';
import { sectionDetail } from '../articles/article';
var _router:Router
@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styles: []
})

export class FolderComponent implements OnInit {

  public sectionDetail;
  public sectionName:string;
  public ArticleDetail=[];

  constructor(private _article:ArticleService,private _sharedService:SharedService,private router:Router,private _sanitizer: DomSanitizer ) {
    if (!this._sharedService.isAuthUser()) {
      this.router.navigate(['login']);
     }
    else {
      this.updateRoles();
     }
  }

  ngOnInit() {
    this._article.currentData.subscribe(m =>this.sectionDetail=m);

  }
  updateRoles() {
    if (!this._sharedService.UserRole) {
        this._sharedService.updateRole();
    }
 }

 redirectArticle(ArticleId){

  this.ArticleDetail=this.sectionDetail.Articles.find(x=>x.ArticleId==ArticleId);
  this._article.ArticleChangeInfo(this.ArticleDetail as any);
  this._article.sectionNameChangeInfo(this.sectionDetail.Name);

  this._article.sectionChangeInfo(this.sectionDetail as any);

  this.router.navigate(["/help/article", ArticleId]);
 }


}
