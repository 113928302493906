//#region Refernces

//Components
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';

//interfaces
import { IMenu } from '../../../shared/services/menu/menu';

//#endregion

//#region Type Decorator
@Component({
    selector: 'api',
    templateUrl: './api.component.html'
})

//#endregion

export class APIComponent implements OnInit {

    //#region Component Initialization
    constructor(private _sharedService: SharedService,  private _router: Router) {
    }
    ngOnInit() {
        this._sharedService.prevURL = this._router.url;
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }else{
            this._sharedService.updateAssignedRole();
        }
    }
  //#endregion

}





   
