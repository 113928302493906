<aside id="left-panel">
    <menu></menu>
</aside>
<!-- END NAVIGATION -->
<!-- MAIN PANEL -->
<div id="main" role="main" class="whitebackground">

    <!-- MAIN CONTENT -->
    <div id="content">
        <router-outlet></router-outlet>

    </div>
    <!-- END MAIN CONTENT -->

</div>
<!--</div>-->
<!-- END MAIN PANEL -->