//Components
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, Params, NavigationEnd, NavigationStart } from '@angular/router';

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { ConfirmationService, SelectItem, TreeNode } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { GridOptions, GridApi, ColumnApi, RowNode, ITooltipParams, IRowNode } from "ag-grid-community";
import { AssetService } from '../../services/asset/asset.service';
import { ViewService } from '../../services/view/view.service';
import { ViewModel, Column, Condition, OrderBy } from '../../services/view/view';
import { BuilderSearchService } from '../../services/builderSearch/builderSearch.service';
import { APIService } from '../../../api/services/api.service';
import { CheckboxUniqueComponent } from '../../../api/components/field-mapping/checkbox-unique.component';
import { CheckboxHistoryComponent } from '../../../api/components/field-mapping/checkbox-history.component';
import { SearchContainerModel, container } from '../../../records/services/container/container';
import { ContainerService } from '../../../records/services/container/container.service';
import * as moment from 'moment/moment';
import { IConfig } from '../../../shared/helpers/config';
import { ViewDataTypeService } from '../../services/view-data-type/view-data-type.service';
import { QualityStatusService } from '../../services/qualitystatus/qualitystatus.service';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
import { Settings } from '../settings/settings';
import { Subscription } from 'rxjs';
import { ITreeNode } from './ITreeNode';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { CustomNormalTooltip } from '../../../shared/components/tooltip-component';

var allNodes: RowNode[] = [];
var allNodesAdd: RowNode[] = [];
var referenceKeyLabel: string = '';
var dateFormatF: string;
var dateSeparatorS: string;
var dayD: number;
var monthM: number;
var yearY: number;
var dateTimeConfig: IDateFormatInfo;
var conColumnDefs_1: any[];
var statusList: any[] = [];
var columnDefs_1: any[];


@Component({
    selector: 'configview',
    templateUrl: './config-view.component.html',
    providers: [AssetService, ViewService, BuilderSearchService, APIService, ContainerService, ViewDataTypeService, QualityStatusService, ConfirmationService]


})
export class ConfigViewComponent implements OnInit {

    @ViewChild('buildersettingspanel') buildersettingspanel: ElementRef;

    availableAircrafts: any[];
    selectedAircrafts: any[];
    conditions: any[];
    whereConditions: any[];
    orderBy: any[];
    fields: any[] = [];
    fieldsLabelsForGrid: string[] = [];
    fieldsWithLable: any[] = [];
    assetTypes: SelectItem[] = [];
    dataSource: SelectItem[] = [];
    dataViewType: SelectItem[] = [];
    spec2500ViewDropDown: SelectItem[] = [];
    public ViewSettingPath: string = "/admin/viewsetting";
    public gridOptions: GridOptions;
    public OcrGridOptions: GridOptions;
    public api: GridApi;
    columnDefs: any[];
    OcrGridColumnDefs: any[];
    public gridData: any[];
    public OcrGridData: any[];
    public canAddEditAirCraft: boolean = false;
    isSubmitted: boolean = false;
    display: boolean = false;

    model: ViewModel;
    isValidInputField: boolean = false;
    screenName: string = "";
    routeName: string = "";
    displayViewDlg: boolean = false;
    selectedView: string;
    showBuilderSetting: boolean = false;
    builderSearchID: string; // number;
    viewID: string;
    previousUrl: string;
    isAutoSearchTab: boolean = false;
    columnDefsColumns: any[];
    public gridOptionsColumns: GridOptions;
    public gridDataColumns: any[] = [];
    public gridApiColumn: GridApi;
    public gridColumnApiColumn: ColumnApi;
    public rowDataCol: any[];
    getRowNodeId: any;
    filecolumns: any[] = [];
    dfpStatusList: SelectItem[] = [];
    viewDataTypeList: any[] = [];

    public displayContainerDlg: boolean = false;
    conTypes: SelectItem[] = [];
    categories: SelectItem[] = [];
    assets: SelectItem[] = [];
    conGridOptions: GridOptions;
    conColumnDefs: any[];
    conGridData: any[] = [];
    conTotalCount: number = 0;
    containerModel: SearchContainerModel;
    conRowSelected: boolean = false;
    public selectedComboCatergorySelection: string = "";
    public dateConfig: IConfig[] = [];
    public useSelectedAsset: boolean = false;

    public Container_MXR_Columns: any[] = [];
    public Container_LOG_Columns: any[] = [];
    public Container_GEN_Columns: any[] = [];
    public Container_DOC_Columns: any[] = [];
    public Container_BND_Columns: any[] = [];
    public appHotkeys: Hotkey[] = [];
    @ViewChild('pdfexportheader') pdfexportheader: ElementRef;
    @ViewChild('pdfexportfooter') pdfexportfooter: ElementRef;
    isPDFExportStatusData
    isExcelAPIExportStatusData
    isExcelGridExportStatusData
    selectedCtrl: any = null;

    qualityStatusList: any[] = [];

    tabValue: string = "0";
    @ViewChild('txtbox') txtbox: ElementRef;
    isTextclicked: boolean = false;
    spec2500NodeData: ITreeNode[];
    selectedspec2500Node: ITreeNode[];
    isBrowseScreen: boolean;
    isClassVisible: boolean = false;
    selectedSpec2500NodeName: string;
    selectedClassName: string;
    searchTreeNode: string;
    selectedFontClassName: string = "standard-fontsize";
    panalClicksubs: Subscription;
    displayTargetNodeDialog:boolean=false;
    hideSearch: boolean;
    // #region Init
    placeholderComplianceStatus = "{0} items selected"
    constructor(public _sharedService: SharedService, private _containerService: ContainerService,
        private router: Router, private route: ActivatedRoute, private _assetService: AssetService,
        private _viewService: ViewService, private _builderSearchService: BuilderSearchService, private cRef: ChangeDetectorRef,
        private renderer: Renderer2, private _apiService: APIService, private _viewDataTypeService: ViewDataTypeService,
        private _qualityStatusService: QualityStatusService, private _confirmationService: ConfirmationService ,private _hotkeysService: HotkeysService) {
        if (!this._sharedService.isAuthUser()) {
            this.router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.updateRoles();
            //this.getRowNodeId = function (data: any) {
            //    return data.FieldOrder;
            //};
            //  this.setGridColumnsDef();
        }
    }
    CoverSheet=false
    ExportSettingsTab=false
    
    changeTabsStatus(flag: boolean, settingType: string) {
        this.isPDFExportStatusData = settingType == "isPDFExportStatusData" ? true : flag;
        this.isExcelAPIExportStatusData = settingType == "isExcelAPIExportStatusData" ? true : flag;
        this.isExcelGridExportStatusData = settingType == "isExcelGridExportStatusData" ? true : flag;
        
    }

    PDFExportSettingsClick(){
        this.changeTabsStatus(false, "isPDFExportStatusData");
    }
    AssignPDFExportToken(_controlId: string, _token: string) {
        var startPos = 0;
        switch (_controlId) {
            case "pdfexportfooter":
                this.selectedCtrl = this.pdfexportfooter;
                startPos = this.selectedCtrl.nativeElement.selectionStart;
                this.selectedCtrl.nativeElement.value =
                    this.selectedCtrl.nativeElement.value.substr(0, startPos) + _token +
                    this.selectedCtrl.nativeElement.value.substr(startPos, this.selectedCtrl.nativeElement.value.length);
                this.model.PDFExportFooter = this.selectedCtrl.nativeElement.value;
                break;
            case "pdfexportheader":
                this.selectedCtrl = this.pdfexportheader;
                startPos = this.selectedCtrl.nativeElement.selectionStart;
                this.selectedCtrl.nativeElement.value =
                    this.selectedCtrl.nativeElement.value.substr(0, startPos) + _token +
                    this.selectedCtrl.nativeElement.value.substr(startPos, this.selectedCtrl.nativeElement.value.length);
                this.model.PDFExportHeader = this.selectedCtrl.nativeElement.value;
                break;
            default:
                break;
        }
    }

    getPDFExportSettingsTab(settingType: string, askForConfirmation: boolean = true, event: Event) {
        if (!askForConfirmation) {

                    // switch (settingType) {
                    //     case 'isCoverLatter':
                    //         this.changeTabsStatus(false, settingType);
                    //         break;
                    //     default:
                    // }
                }
                else {
                    switch (settingType) {
                        case 'isExcelAPIExportStatusData':
                            this.changeTabsStatus(false, settingType);
                            break;
                        case 'isExcelGridExportStatusData':
                            this.changeTabsStatus(false, settingType);
                            break;
                        case 'isPDFExportStatusData':
                            
                            this.changeTabsStatus(false, settingType);
                            break;
                        default:
                    }
                
        }
    }


    ngOnInit() {
        let viewId: string = this.route.snapshot.params['id'];
        let tab: string = this.route.snapshot.params['tab'];
        if (tab == "auto") {
            this.isAutoSearchTab = true;
        }

        let screen: string;



        this.model = new ViewModel();
        this.model.AllAsset = false;
        // this.model.ViewSettingId = Number(viewId);
        // this.viewID = Number(viewId);
        this.model.ViewSettingId = viewId;
        this.viewID = viewId;

        if (viewId != "" && viewId != null) {
            this.screenName = "Edit";
            this.showBuilderSetting = true;
        }
        else {
            this.screenName = "Add";
            this.showBuilderSetting = false;
        }
        //this.gridDataColumns = [];
        
        this.populateQualityStatusDropdown();
        this.getDateFormatInfo();
        this.prepareContainerGridColumns();
        this.populateDFPStatus();
        this.populateViewDataType();
        this.populateFieldForGrid();
        this.populateAssetTypeDropdown();
        this.populateDataSourceDropdown();
        this.populateViewTypeDropdown();
        this.populatspec2500Dropdown();
        this.populateConditions();
        this.populateWhereConditions();
        this.populateOrderByDropDown();
        this.populateView();
        this.setHotKeys();
        this.availableAircrafts = [];
        this.selectedAircrafts = [];
        this.gridOptionsColumns = <GridOptions>
            {
                rowSelection: 'single',
                rowData: [],
                onGridReady: (params) => {
                    this.gridOptionsColumns.api.sizeColumnsToFit();
                    if (params.api) {
                        this.setGridColumnsDef();
                        this.gridOptionsColumns.columnApi = params.columnApi;
                        this.gridApiColumn = params.api;
                        //this.gridApiColumn.setGridAutoHeight(true);
                    }
                    if (this.gridOptionsColumns.api != undefined) {
                        if (this.gridDataColumns.length > 0) { this.gridOptionsColumns.api.hideOverlay(); }
                    }
                },
                onModelUpdated: (event: any) => {
                    this.gridOptionsColumns.api.sizeColumnsToFit();
                    //this.gridApiColumn.setGridAutoHeight(true);
                },
                onCellEditingStopped: this.updateFields,
                //suppressContextMenu: true,
                getContextMenuItems: this.getCustomContextMenuItems,
                stopEditingWhenCellsLoseFocus: true,
                context: {
                    gridCtx: this
                },
                components: {  CustomNormalTooltip: CustomNormalTooltip },
            };
        this.gridOptions = <GridOptions>
            {
                rowSelection: 'single',

                onGridReady: () => {
                    this.setViewGridColumns();

                },
                suppressContextMenu: true,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    filter: true
                }
            };


            this.bindBuilderSearchGrid();
            this.loadBuilderSearchGridData(viewId);//(Number(viewId));


        let screenWidth = window.innerWidth;
        if (screenWidth < 1025) {
            this.placeholderComplianceStatus = "{0} items...";

        }

        this.initDefaultContainer();
        var autosearch = this._sharedService.getShowAutoSearchBatchJobScheduled();
        if( autosearch == "" || autosearch == "false" )
        {
            this.hideSearch = false;
        }
        else
        {
            this.hideSearch = true;
        }
    }

    populateDFPStatus() {
        this._viewService.getDFPStatus()
        .subscribe({
            next: (response) => {
                this.dfpStatusList = response;
                if (!this.model.ViewSettingId) {
                    this.model.selectedDfpStatus = this.dfpStatusList.map(s => s.value);
                }                
            },
            error: (error) => {
                this.dfpStatusList = [];
                console.log(error);                
            }
        });
    }

    populateViewDataType() {
        this._viewDataTypeService.getAllActiveViewDataTypeData(null)
        .subscribe({
            next: (response) => {
                this.viewDataTypeList = response.map((c) => { return { label: c.Name, value: c.Name }; });                
            },
            error: (error) => {
                this.viewDataTypeList = [];
                console.log(error);                
            }
        });
    }

    AssignToken(_controlId: string, _token: string) {
        this.model.Conditions[_controlId].Value = _token;
    }

    AssignOrToken(_controlId: string, _token: string) {
        this.model.OrConditions[_controlId].Value = _token;
    }

    updateRowNode(rowIndex: number, columnKey: string) {
        let newValue: boolean = false;
        //  let row: RowNode = this.gridOptionsMapping.api.getDisplayedRowAtIndex(rowIndex);
        let row: IRowNode = this.gridOptionsColumns.api.getRenderedNodes().find(x => x.data.FieldOrder == rowIndex);
        switch (columnKey) {
            case 'uniqueKey':
                if (row.data.UniqueKey == true) {
                    newValue = false;
                }
                else if (row.data.UniqueKey == false) {
                    newValue = true;
                }
                break;
            case 'historyKey':
                if (row.data.HistoryKey == true) {
                    newValue = false;
                }
                else if (row.data.HistoryKey == false) {
                    newValue = true;
                }
        }
        row.setDataValue(columnKey, newValue);
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    // #endregion


    // #region OCR Builder Search Panel
    bindBuilderSearchGrid() {

        this.setBuilderSearchGridColumns();
        this.OcrGridOptions = <GridOptions>{
            rowSelection: 'single',
            //suppressContextMenu: true,
            //    onSelectionChanged: this.handleSelectedDoc,
            onGridReady: () => {
                if (this.OcrGridOptions.api != undefined) {
                    this.OcrGridOptions.api.setColumnDefs(this.OcrGridColumnDefs);
                    this.OcrGridOptions.api.sizeColumnsToFit();
                    this.api = this.OcrGridOptions.api;
                }
            },
            onModelUpdated: (event: any) => {

                if (this.OcrGridOptions.rowData != null && this.OcrGridOptions.rowData != undefined && this.OcrGridOptions.rowData.length > 0) {
                    if (this.api != undefined) {
                        this.api.sizeColumnsToFit();
                        //this.api.selectIndex(0, true, false);
                        //this.api.getDisplayedRowAtIndex(0).setSelected(true);

                    }
                }
            },
            getContextMenuItems: this.getCustomContextMenuItemsOCR,
            context: {
                gridContext: this
            },
            defaultColDef: {
                sortable: true,
                resizable: true
            }
        }


    }

    setBuilderSearchGridColumns(): void {
        this.OcrGridColumnDefs = [];
        this.OcrGridColumnDefs =
            [
                { headerName: 'Order', field: 'OrderNo', filter: 'text', suppressMenu: true, sort: 'asc' },
                { headerName: 'Search Name', field: 'SearchName', filter: 'text', suppressMenu: true },
                //{ headerName: 'Execution', field: 'Execution', filter: 'text' },
                {
                    headerName: 'Execution', field: 'Execution', filter: 'text', suppressMenu: true, valueFormatter: function (params: any) {
                        if (params.value == "Find and Move") {
                            return "Execute (Next ASQ)";
                        }
                        else if (params.value == "Find and Stop") {
                            return "Terminate (On Record Found)";
                        }
                    }
                },
                {
                    headerName: 'Status', field: 'Status', filter: 'text', suppressMenu: true, valueFormatter: function (params: any) {
                        if (params.value == true) {
                            return "Active";
                        }
                        else {
                            return "Inactive";
                        }
                    }
                },
                { headerName: 'Select Rows', field: 'SelectRows', filter: 'text', suppressMenu: true },
                //{ headerName: 'Created On  ', field: 'CreatedOn', filter: 'text' },
            ];

    }

    loadBuilderSearchGridData(viewSettingID: string): void {
        this._sharedService.showLoader("Processing...");
        this._builderSearchService.getListBuilderSearchbyViewID(viewSettingID)
        .subscribe({
            next: (response) => {
                //   this.gridOptions.api.sizeColumnsToFit();
                this.OcrGridData = [];
                this.OcrGridData = response;
                this.api.sizeColumnsToFit();
                this._sharedService.hideLoader();
                // this.totalCount = response.length;                
            },
            error: (error) => {
                this._sharedService.hideLoader();
                console.log(error);                
            }
        });
    }

    addSearchConfig(event: any): void {

        if (this.viewID) {
            this.router.navigate(["/admin/addBuilderSearch", this.viewID]);
            return;
        }

    }

    btnEditDoubleClick(event: any) {
        this.editSearchConfig(event);
    }

    setHotKeys() {
        let hotKeyObj: Hotkey = null;
        hotKeyObj = new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
            this.editSearchConfig();
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

        // Export Excel
        hotKeyObj = new Hotkey('enter', (event: KeyboardEvent): boolean => {
            this.editSearchConfig();
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
    }

    editSearchConfig(event?: any): void {

        if (this.builderSearchID) {
            this.router.navigate(["/admin/addBuilderSearch", this.viewID, this.builderSearchID]);
            return;
        }

        this._sharedService.clearToast();
        this._sharedService.showToast({ severity: 'warn', summary: 'no record selected', detail: 'Please select a record.' });
        return;

    }


    deleteSearchConfig(event: any): void {

        if (this.builderSearchID) {

            this._confirmationService.confirm({

                message: 'Are you sure you want to delete selected record?',
                header: 'Delete AutoSearch Query Confirmation',
                icon: 'fa fa-exclamation-triangle',
                accept: () => {
                    // this.router.navigate(["/admin/addBuilderSearch", this.viewID, this.builderSearchID]);
                    this.deleteBuilderSearchbyID(this.builderSearchID);
                },
                reject: () => {
                }
            });

            return;


        }

        this._sharedService.clearToast();
        this._sharedService.showToast({ severity: 'warn', summary: 'no record selected', detail: 'Please select a record.' });
        return;

    }

    deleteBuilderSearchbyID(builderSearchID: string): void {

        this._sharedService.showLoader("Processing...");
        this._builderSearchService.deleteBuilderSearchbyID(builderSearchID)
        .subscribe({
            next: (response) => {
                this.builderSearchID = null;
                this.loadBuilderSearchGridData(this.viewID);
                this._sharedService.hideLoader();
            },
            error: (error) => {
                console.log(error)
            }
        });
    }


    handleSelectedDoc() {
        var rowCount = this.api.getSelectedNodes().length;
        if (rowCount > 0) {
            var lastRowData = this.api.getSelectedNodes()[rowCount - 1].data;
            this.builderSearchID = lastRowData.SearchId;
        }
    }


    // #endregion



    // #region Populate Dropdowns and  View's Data

    populateConditions() {
        this.conditions = [{ label: " is equal to", value: "=" },
        { label: "is not equal to", value: "!=" },
        { label: "is greater than", value: ">" },
        { label: " is less than", value: "<" },
        { label: "Not In", value: "<>" },
        { label: "In", value: "IN" },
        { label: " contains", value: "%%" }
        ];
    }

    populateOrderByDropDown() {
        this.orderBy = [{ label: "Ascending", value: "ASC" },
        { label: "Descending ", value: "DESC" }
        ];
    }

    populateWhereConditions() {
        this.whereConditions = [{ label: "And", value: "And" },
        { label: "Or", value: "Or" }
        ];
    }

    populateFields() {
        // this._viewService.getColumns("Task").subscribe( response => {
        //     this.fields = response;
        //     this.fieldsLabelsForGrid = this.fields.map(sts => sts.label.trim());
        //     //if (this.fieldsLabelsForGrid && this.fieldsLabelsForGrid.length > 0) {
        //     //    let col1 = this.gridOptionsColumns.columnApi.getColumn("fieldname").getColDef();
        //     //    col1.cellEditorParams.values = this.fieldsLabelsForGrid;
        //     //}
        //     this.fieldsWithLable = response;
        // });
        //let columns = this.model.Columns.map((c) => { return { label: c.FieldName, value: c.FieldName }; })
        let columns = this.model.Columns.map((c) => {
            return (c.FieldName == 'TaskType') ? { label: 'DataType', value: 'DataType' } : { label: c.FieldName, value: c.FieldName };

            //return { label: c.FieldName, value: c.FieldName }; 

        })
        this.fieldsWithLable = columns;
    }

    populateFieldForGrid() {
        this._viewService.getColumns("Task")
        .subscribe({
            next: (response) => {
                this.fields = response;
                this.fieldsLabelsForGrid = this.fields.map(sts => sts.label.trim());
                let index = this.fieldsLabelsForGrid.findIndex(f => f == 'TaskType');
                this.fieldsLabelsForGrid[index] = 'DataType';
                let index1 = this.fieldsLabelsForGrid.findIndex(f => f == 'TaskID');
                this.fieldsLabelsForGrid[index1] = 'ID';                
            },
            error: (error) => {
                console.log(error);                
            }
        });
    }

    updateFieldLabels() {
        if (this.fieldsWithLable.length > 0) {
            for (var i = 0; i < this.fieldsWithLable.length; i++) {
                let col = this.model.Columns.find(c => c.FieldName == this.fieldsWithLable[i].label);
                if (col && col.FieldLabel) {
                    this.fieldsWithLable[i].label = col.FieldLabel + ' - ' + this.fieldsWithLable[i].label;
                    this.fieldsWithLable[i].label = col.GroupLabel ? (col.GroupLabel + ' - ' + this.fieldsWithLable[i].label) : this.fieldsWithLable[i].label;
                }
            }
        }
    }

    populateView() {
        if (this.model.ViewSettingId) {
            this._sharedService.showLoader("Processing...");
            this._viewService.populateView(this.model.ViewSettingId)
            .subscribe({
                next: (response) => {
                    //  this.getDummyData();
                    this.model = this.updateTaskTypeLabel(response, 'TaskType', 'DataType');
                    this.model = this.updateTaskTypeLabel(response, 'TaskID', 'ID');
                     this.populateFields();
                    this.updateFieldLabels();
                    this.gridDataColumns = [];
                    this.setGridColumnsDef();
                    for (let i = 0; i < this.model.Columns.length; i++) {
                        (this.model.Columns[i].Spec2500==null || this.model.Columns[i].Spec2500 =="")? this.model.Columns[i].Spec2500 = " " :this.model.Columns[i].Spec2500 = this.model.Columns[i].Spec2500;
                    }
                    this.gridDataColumns = this.model.Columns;
                    if (this.fieldsLabelsForGrid && this.fieldsLabelsForGrid.length > 0) {
                        if(this.gridOptionsColumns.columnApi.getColumn("fieldname")){
                        let col1 = this.gridOptionsColumns.columnApi.getColumn("fieldname")?.getColDef();
                        col1.cellEditorParams.values = this.fieldsLabelsForGrid;
                    }
                    }
                    this.gridOptionsColumns.api.sizeColumnsToFit();
                    this._sharedService.hideLoader();
                    this._viewService.getSpec2500TreeNode(this.model.Spec2500View)
                    .subscribe({
                        next: (response) => {
                            this.spec2500NodeData = response;
                            this.previousSpec2500View=this.model.Spec2500View;
                            if(this.model.Spec2500View == null || this.model.Spec2500View == ""|| this.model.Spec2500View == undefined)
                            {
                                this.model.Spec2500View = this.spec2500ViewDropDown[0].value;
                                this.previousSpec2500View= this.spec2500ViewDropDown[0].value;
                            }                        
                        },
                        error: (error) => {
                            console.log(error);                        
                        }
                    });
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    console.log(error)
                }
            });
        } else {
            //populate Empty Model
            this.model.selectedAssets = [];
            this.model.selectedDfpStatus = [];
            this.model.Columns = [];
            this.model.Conditions = [];
            this.model.OrConditions = [];
            this.model.ConditionsWithOutToken = [];
            this.model.SelectedConditions = [];
            this.model.NonSelectedConditions = [];
            this.model.DefaultSelectedConditions = [];
            this.model.DefaultNotSelectedConditions = [];
            this.model.OrderBy = [];
            this.model.GroupBy = [];
            this.model.Visible = true;
            this.model.ShowOnDashboard = true;
            this.model.Status = true;
            this.model.TextWrap = false;
            this.model.Source = true;
            this.model.ViewType = 'Status';
            this.gridDataColumns = [];
            if (this.fieldsLabelsForGrid && this.fieldsLabelsForGrid.length > 0) {
                let col1 = this.gridOptionsColumns.columnApi.getColumn("fieldname").getColDef();
                col1.cellEditorParams.values = this.fieldsLabelsForGrid;
            }

            this.model = this.updateTaskTypeLabel(this.model, 'TaskType', 'DataType');
            this.model = this.updateTaskTypeLabel(this.model, 'TaskID', 'ID');
        }
    }




    populateAssetTypeDropdown() {
        this.assetTypes = [
            { label: "Airframe", value: "Airframe" },
            { label: "Engine", value: "Engine" },
            { label: "Landing Gear", value: "LDG" },
            { label: "Propeller", value: "Propeller" },
            { label: "APU", value: "APU" },
            { label: "Item", value: "Item" }
        ]
    }

    populateDataSourceDropdown() {
        this.dataSource = [
            { label: "Local", value: true },
            { label: "External", value: false }
        ]
    }

    populateViewTypeDropdown() {
        this.dataViewType = [
            { label: 'Status', value: 'Status' },
            { label: 'Repair', value: 'Repair' }
        ]
    }
    populatspec2500Dropdown() {
        this.spec2500ViewDropDown = [
            { label: 'None', value: 'none' },
            { label: 'Airworthiness Directive (AD) Status (ATA_AD_Status)', value: 'ATA_AD_Status' },
            { label: 'Service Bulletin / Mod / STC Status (ATA_SB_MOD_STC_Status)', value: 'ATA_SB_MOD_STC_Status' },
            { label: 'Repair Damage Status', value: 'ATA_RepairDamageStatus' },
            { label: 'Last Done Next Due Maintenance Status', value: 'ATA_LastDoneNextDueMaintenanceStatus' },
            { label: 'Installed Component Status', value: 'ATA_InstalledComponentStatus' },
            { label: 'Aircraft or Top Asset Status', value: 'ATA_AssetStatus' }
        ]
        this.model.Spec2500View = this.spec2500ViewDropDown[0].value;
    }

    changeAllAsset() {
        if (!this.model.ViewSettingId) {
            if (this.model.AllAsset == false) {
                if (this.model.AssetType) {
                    this._viewService.populateAsset(this.model.AssetType, '')
                    .subscribe({
                        next: (response) => {
                            let viewAssets = response;
                            this.model.availableAssets = viewAssets[0];
                            this.model.selectedAssets = viewAssets[1];
                        },
                        error: (error) => {
                            console.log(error)
                        }
                    });
                } else {

                    this._sharedService.showToast({ severity: 'warn', summary: 'No Asset Type', detail: "Please select an asset type." });
                }
            }
            else {
                this.model.availableAssets = []
                this.model.selectedAssets = [];
            }
        }
        else {
            if (this.model.AllAsset == false) {
                this._viewService.populateAsset(this.model.AssetType, this.model.ViewSettingId.toString())
                .subscribe({
                    next: (response) => {
                        let viewAssets = response;
                        this.model.availableAssets = viewAssets[0];
                        this.model.selectedAssets = viewAssets[1];
                    },
                    error: (error) => {
                        console.log(error)
                    }
                });

            } else {

                this.model.availableAssets = []
                this.model.selectedAssets = [];
            }
        }
    }

    onAssetChange(event: any) {
        this.model.selectedAssets = [];
        if (this.model.AllAsset == false) {
            this._viewService.populateAsset(this.model.AssetType, '')
            .subscribe({
                next: (response) => {
                    let viewAssets = response;
                    this.model.availableAssets = viewAssets[0];
                    this.selectedAircrafts = viewAssets[1];
                },
                error: (error) => {
                    console.log(error)
                }
            });
        } else {
            this.model.availableAssets = []
            this.model.selectedAssets = [];
        }
    }

    // #endregion

    // #region Add/Delete Query Components

    addColumn() {
        try {
            if (this.fieldsLabelsForGrid && this.fieldsLabelsForGrid.length > 0) {
                let col1 = this.gridOptionsColumns.columnApi.getColumn("fieldname").getColDef();
                col1.cellEditorParams.values = this.fieldsLabelsForGrid;
            }
        } catch (e) {
            console.log(e);
        }
        let maxOrder = 0;
        if (this.model.Columns && this.model.Columns.length > 0) {
            maxOrder = Math.max.apply(Math, this.model.Columns.map(function (o) { return Number(o.FieldOrder); }));
            maxOrder = maxOrder + 1;
        }
        let col: Column = { FieldName: "", FieldLabel: "", GroupLabel: "", FieldOrder: maxOrder, ColumnWidth: '90', UniqueKey: false, HistoryKey: false,Spec2500:' ' ,Formula: ''};
        allNodesAdd = [];
        this.gridOptionsColumns.api.forEachNode(this.addModelValue);
        let colArray: Column[] = [];
        for (var i = 0; i < allNodesAdd.length; i++) {
            let col1: Column = { FieldName: allNodesAdd[i].data.FieldName, FieldLabel: allNodesAdd[i].data.FieldLabel, GroupLabel: allNodesAdd[i].data.GroupLabel, FieldOrder: allNodesAdd[i].rowIndex, ColumnWidth: allNodesAdd[i].data.ColumnWidth, UniqueKey: allNodesAdd[i].data.UniqueKey, HistoryKey: allNodesAdd[i].data.HistoryKey,Spec2500:allNodesAdd[i].data.Spec2500 , Formula:allNodesAdd[i].data.Formula };
            colArray.push(col1);
        }
        colArray.push(col);
        this.model.Columns = [];
        this.model.Columns = colArray;
        this.gridDataColumns = this.model.Columns;
        this.gridOptionsColumns.api.setRowData(this.model.Columns);
        this.populateFields();
    }

    deleteColumn(column: Column) {
        let index: number = this.model.Columns.indexOf(column);
        this.model.Columns.splice(index, 1);
    }

    addCondition() {
        let cond = new Condition('');
        cond.JoinCondition = 'AND';
        this.model.Conditions.push(cond);

    }

    deleteCondition(index: number) {
        this.model.Conditions.splice(index, 1);
    }

    addOrCondition() {
        let cond = new Condition('');
        cond.JoinCondition = 'OR';
        this.model.OrConditions.push(cond);

    }

    deleteOrCondition(index: number) {
        this.model.OrConditions.splice(index, 1);
    }

    addAppCondition() {
        this.model.SelectedConditions.push(new Condition(''));

    }

    deleteAppCondition(index: number) {
        this.model.SelectedConditions.splice(index, 1);
    }

    addNonAppCondition() {
        this.model.NonSelectedConditions.push(new Condition(''));

    }

    deleteNonAppCondition(index: number) {
        this.model.NonSelectedConditions.splice(index, 1);
    }

    addOrderBy() {
        this.model.OrderBy.push(new OrderBy(''));

    }

    deleteOrderBy(index: number) {
        this.model.OrderBy.splice(index, 1);
    }

    addGroupBy() {
        this.model.GroupBy.push("");

    }

    deleteGroupBy(index: number) {
        this.model.GroupBy.splice(index, 1);
    }

    onChangeGroupBy(event: any) {
        let col = this.model.Columns.filter(c => c.FieldName == event.target.value);
        if (col.length == 0) {
            event.target.value = '';

            this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'This column is not included in the view' });
        }
    }

    /* updateCondFieldLabel(event: any) {
         let conditions = this.model.Conditions.filter(f => f.FieldName == event.target.value);

         let column = this.model.Columns.find(f => f.FieldName == conditions[0].FieldName);

         if (column && column.FieldLabel) {
             for (var i = 0; i < this.model.Conditions.length; i++) {
                 if(this.model.Conditions[i].FieldName == column.FieldName )
                 this.model.Conditions[i].FieldLabel = column.FieldLabel;
             }
         }
     } */

    // #endregion

    // #region Submit Events

    btnCancelClick(cancelForm: NgForm) {
        this.display = false;
        cancelForm.resetForm();
        this.model = new ViewModel();
    }


    // #endregion

    // #region MISC

    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }

    resetModel() {
        this.model = new ViewModel();

    }

    updateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }

        this.canAddEditAirCraft = this._sharedService.UserRole.Admin_Aircraft;

    }

    returntoAction(url: string) {

        setTimeout(() => {

            this.router.navigateByUrl(url);

        },
            2000);
    }

    // #endregion

    // #region Add/Update View
    addModelValue(rowNodes: RowNode) {
        allNodesAdd.push(rowNodes);
    }
    updateModelValues(rowNode: RowNode) {
        allNodes.push(rowNode);
    }
    clearDefaultContainer()
    {
        this.model.DefaultContainer=null;
    }
    addUpdateView() {
        // if(!this.model.Columns.find(c=>c.UniqueKey)){
        //     this._sharedService.clearToast();
        //     this._sharedService.showToast({ severity: 'error', summary: 'Unique key is missing', detail: 'Cannot add/update view without unique key.' });
        //     return;}
        this.changeButtonsStatus(true);
        allNodes = [];
        this._sharedService.showLoader("Processing...");
        if (this.model.ViewSettingId) {
            this.gridOptionsColumns.api.forEachNode(this.updateModelValues);
            let colArray: Column[] = [];
            for (var i = 0; i < allNodes.length; i++) {
                let col: Column;
                col = { FieldName: allNodes[i].data.FieldName, FieldLabel: allNodes[i].data.FieldLabel, GroupLabel: allNodes[i].data.GroupLabel, FieldOrder: allNodes[i].rowIndex, ColumnWidth: allNodes[i].data.ColumnWidth, UniqueKey: allNodes[i].data.UniqueKey, HistoryKey: allNodes[i].data.HistoryKey,Spec2500:allNodes[i].data.Spec2500,Formula:allNodes[i].data.Formula };
                colArray.push(col);
            }
            this.model.Columns = [];
            this.model.Columns = colArray;
            //Ignore Empty Field Name Conditions
            this.model.SelectedConditions = this.model.SelectedConditions.filter(c => c.FieldName);
            this.model.NonSelectedConditions = this.model.NonSelectedConditions.filter(c => c.FieldName);

            this.skipEmptyRows();
            //Update TaskType Label
            // let model:ViewModel = this._sharedService.cloneObject(this.model);
            let model: ViewModel = this.model;
            model = this.updateTaskTypeLabel(model, 'DataType', 'TaskType');
            model = this.updateTaskTypeLabel(model, 'ID', 'TaskID');

            this._viewService.updateView(model)
            .subscribe({
                next: (response) => {
                    this._sharedService.hideLoader();
                    this.changeButtonsStatus(false);
                    this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'View updated succesfully.' });
                    this.returntoAction(this.ViewSettingPath);                    
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this.changeButtonsStatus(false);
                    if (error.status == 409) {
                        this._sharedService.showToast({ severity: 'warn', summary: 'Error Occured', detail: error.error });
                    } else {
                        this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Cannot update view, please try again.' });
                    }                    
                }
            });

        } else {
            this.skipEmptyRows();
            // let model:ViewModel = this._sharedService.cloneObject(this.model);
            let model: ViewModel = this.model;
            model = this.updateTaskTypeLabel(model, 'DataType', 'TaskType');
            model = this.updateTaskTypeLabel(model, 'ID', 'TaskID');

            this._viewService.addView(model)
            .subscribe({
                next: (response) => {
                    this._sharedService.hideLoader();
                    this.changeButtonsStatus(false);
                    this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'View added succesfully.' });
                    this.returntoAction(this.ViewSettingPath);                    
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this.changeButtonsStatus(false);
                    if (error.status == 409) {
                        this._sharedService.showToast({ severity: 'warn', summary: 'Error Occured', detail: error.error });
                    } else {
                        this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Cannot add View, please try again.' });
                    }                    
                }
            });
        }
    }

    skipEmptyRows() {
        this.model.Columns = this.model.Columns.filter(c => c.FieldName != '');
        this.model.Conditions = this.model.Conditions.filter(cnd => cnd.FieldName != '');
        this.model.OrConditions = this.model.OrConditions.filter(cnd => cnd.FieldName != '');
        this.model.GroupBy = this.model.GroupBy.filter(grp => grp != '');
        this.model.OrderBy = this.model.OrderBy.filter(odr => odr.FieldName != '');
        this.model.SelectedConditions = this.model.SelectedConditions.filter(sel => sel.FieldName != '');
        this.model.NonSelectedConditions = this.model.NonSelectedConditions.filter(non => non.FieldName != '');
    }

    updateTaskTypeLabel(model: ViewModel, currLabel: string, newLabel: string) {
        if (model) {
            //Update in Columns
            let colIndex = model.Columns.findIndex(f => f.FieldName == currLabel);
            if (colIndex != -1) {
                model.Columns[colIndex].FieldName = newLabel;
                //if(!model.Columns[colIndex].FieldLabel){ model.Columns[colIndex].FieldLabel= newLabel; }
            }
              //Update in Columns
              let ConditionsWithOutTokenIndex = model.ConditionsWithOutToken.findIndex(f => f.FieldName == currLabel);
              if (ConditionsWithOutTokenIndex != -1) {
                  model.ConditionsWithOutToken[ConditionsWithOutTokenIndex].FieldName = newLabel;
                  //if(!model.Columns[colIndex].FieldLabel){ model.Columns[colIndex].FieldLabel= newLabel; }
              }
            //Update in Conditions
            let condIndex = model.Conditions.findIndex(f => f.FieldName == currLabel);
            if (condIndex != -1) {
                model.Conditions[condIndex].FieldName = newLabel;
            }
            //Update in OrConditions
            // let orCondIndex = model.Conditions.findIndex(f => f.FieldName == currLabel);
            // if (orCondIndex != -1) {
            //     model.OrConditions[condIndex].FieldName = newLabel;
            // }
            if (model.OrConditions.length > 0) {

                for (var i = 0; i < model.OrConditions.length; i++) {
                    if (model.OrConditions[i].FieldName == currLabel) {
                        model.OrConditions[i].FieldName = newLabel;
                    }
                }
            }
        }

        return model;
    }

    // #endregion

    // #region Copy View

    openCopyDlg() {
        this.displayViewDlg = true;
        this.populateCopyViewGrid();
    }

    populateCopyViewGrid(): void {
        this._viewService.getAllViews()
        .subscribe({
            next: (response) => {
                this.gridData = [];
                this.gridData = response;                
            },
            error: (error) => {
                this.gridData = [];                
            }
        });
    }
    setGridColumnsDef(): void {
        if (this.model.Source) {
            this.columnDefsColumns = [
                {
                    headerName: 'Field Name', field: 'FieldName', colId: "fieldname", editable: true, cellEditor: 'agRichSelectCellEditor', cellEditorParams: {
                        values: this.fieldsLabelsForGrid,

                    }, filter: false, suppressMenu: true, rowDrag: true
                },
                { headerName: 'Column Label', field: 'FieldLabel', colId: "FieldLabel", editable: true, filter: false, suppressMenu: true },

                { headerName: 'Column Group', field: 'GroupLabel', colId: "GroupLabel", editable: true, suppressMenu: true, filter: false },
                {
                    headerName: 'Unique Key', field: 'UniqueKey', minWidth: 30, colId: "uniqueKey", suppressMenu: true, filter: false, cellRenderer: CheckboxUniqueComponent
                },
                {
                    headerName: 'History Key', field: 'HistoryKey', minWidth: 30, colId: "historyKey", suppressMenu: true, filter: false, cellRenderer: CheckboxHistoryComponent
                },
                { headerName: "Width ('0' for hidden)", field: 'ColumnWidth', colId: "ColumnWidth", editable: true, suppressMenu: true, filter: false },
                { headerName: 'Formula', field: 'Formula', colId: "Formula", editable: true, suppressMenu: true, filter: false, tooltipComponent: 'CustomNormalTooltip',  headerTooltip: commentTooltipValueGetter },
                {headerName: 'Spec 2500 Element', field: 'Spec2500', colId: "spec2500",  suppressMenu: true, filter: false,hide:false, Width: 50,tooltipField: "Spec2500",cellStyle: {'display': 'flex', 'justify-content': 'flex-end'}},
                { headerName: '', field: '', cellRenderer: this.tagsCellRenderer, suppressMenu: true, filter: false, width: 40 },
            ];
        } else {
            this.columnDefsColumns = [
                {
                    headerName: 'Field Name', field: 'FieldName', colId: "fieldname", editable: true, cellEditor: 'agRichSelectCellEditor', cellEditorParams: {
                        values: this.fieldsLabelsForGrid,

                    }, filter: false, suppressMenu: true, rowDrag: true
                },
                { headerName: 'Column Label', field: 'FieldLabel', editable: true, filter: false, suppressMenu: true },

                { headerName: 'Column Group', field: 'GroupLabel', editable: true, suppressMenu: true, filter: false },
                { headerName: "Width ('0' for hidden)", field: 'ColumnWidth', editable: true, suppressMenu: true, filter: false },
                { headerName: "Formula", field: 'Formula', colId: "Formula", editable: true, suppressMenu: true, filter: false },
                { headerName: '', field: '', cellRenderer: this.tagsCellRenderer, suppressMenu: true, filter: false, width: 40 },
            ];

        }
    }

    onGridReadyCol(params: any) {
        //  this.gridApiColumn = params.api;
        this.gridColumnApiColumn = params.columnApi;
    }

    updateFields(event: any) {
        if (event.colDef.field == 'FieldName') {
            // let maxOrder = 0;
            // if (event.context.gridCtx.model.Columns && event.context.gridCtx.model.Columns.length > 0)
            // {
            //     maxOrder = Math.max.apply(Math, event.context.gridCtx.model.Columns.map(function (o) { return Number(o.FieldOrder); }));
            //     maxOrder = maxOrder + 1;
            // }
            // let col: Column = { FieldName: event.data.FieldName, FieldLabel: "", GroupLabel: "", FieldOrder: maxOrder, ColumnWidth: '90', UniqueKey: false, HistoryKey: false };
            // allNodesAdd = [];
            // event.api.forEachNode(event.context.gridCtx.addModelValue);
            // let colArray: Column[] = [];
            // for (var i = 0; i < allNodesAdd.length; i++) {
            //     let col1: Column = { FieldName: allNodesAdd[i].data.FieldName, FieldLabel: allNodesAdd[i].data.FieldLabel, GroupLabel: allNodesAdd[i].data.GroupLabel, FieldOrder: allNodesAdd[i].rowIndex, ColumnWidth: allNodesAdd[i].data.ColumnWidth, UniqueKey: allNodesAdd[i].data.UniqueKey, HistoryKey:allNodesAdd[i].data.HistoryKey};
            //     colArray.push(col1);
            // }
            // colArray.push(col);
            // event.context.gridCtx.model.Columns = [];
            // event.context.gridCtx.model.Columns = colArray;
            event.context.gridCtx.populateFields();

        } else if (event.colDef.field == 'FieldLabel') {
            event.context.gridCtx.updateFieldLabels();
        }
    }

    //getFileColumns() {

    //        //  this._sharedService.showLoader("Processing...");
    //        this._apiService.getFileColumns("3").subscribe( response => {
    //            this.filecolumns = response;
    //            //console.log(response);
    //            //this.fileColumnsLabels = this.filecolumns.map(sts => sts.label.trim());
    //            //for (var i = 0; i < this.fileColumnsLabels.length; i++) {
    //            //    console.log(this.fileColumnsLabels[i]);
    //            //}
    //            //this.model = new MappingModel();
    //            //this.getDefaultValues();
    //        }, error => { });


    //}
    tagsCellRenderer(params: any) {
        let html: string = '';

        html = html + '<a (click)="removeClicked(' + params.data.FieldOrder + ')"><span id=' + params.data.FieldOrder + ' class="button-icon glyphicon glyphicon-remove hand-cursor" title="delete"></span></a>';
        var eDiv = document.createElement('div');
        eDiv.innerHTML = html;
        return eDiv;
    }
    setViewGridColumns(): void {
        this.columnDefs =
            [
                { headerName: 'View Name', field: 'ViewName', menuTabs: ['filterMenuTab'] },
                { headerName: 'Display Name', field: 'DisplayName', menuTabs: ['filterMenuTab'] },
                {
                    headerName: 'Visible', field: 'Visible', filter: false, suppressMenu: true, valueFormatter: function (params: any) {
                        if (params.value == true) {
                            return "True";
                        }
                        else {
                            return "False";
                        }
                    }
                },
                {
                    headerName: 'Status', field: 'Status', filter: false, suppressMenu: true, valueFormatter: function (params: any) {
                        if (params.value == true) {
                            return "Active";
                        }
                        else {
                            return "Inactive";
                        }
                    }
                }
            ];
    }

    onCellClicked(event: any, type?: string) {
        if ((event.colDef && event.colDef.field == '') || (type == 'Context Menu')) {
            if (this.model.Conditions.find(c => c.FieldName == event.data.FieldName)
                || this.model.OrderBy.find(c => c.FieldName == event.data.FieldName)
                || this.model.GroupBy.indexOf(event.data.FieldName) != -1) {

                this._sharedService.showToast({ severity: 'warn', summary: 'Used in conditions', detail: 'Column has been used in conditions, remove it first.' });
                return;
            }
            let index: number = this.model.Columns.findIndex(x => x.FieldOrder == event.data.FieldOrder);
            this.model.Columns.splice(index, 1);

            this.gridDataColumns = [];
            this.gridDataColumns = this.model.Columns;
            this.gridOptionsColumns.api.setRowData(this.model.Columns);
        }
        if(event.colDef.field == 'Spec2500' && this.model.Spec2500View != 'none'&& this.model.Spec2500View != undefined && this.model.Spec2500View !=""){
            this.btnCloseTreeNode();
            this.displayTargetNodeDialog=true;
        }
    }

    //onCellValueChanged
    onCellValueChanged(event: any) {
        if ((event.colDef && event.colDef.field == 'FieldName')) {
            var selectedRows: any = this.gridOptionsColumns.api.getSelectedNodes();
            selectedRows[0].setDataValue('spec2500', ' ');
            if (event.oldValue != event.newValue && event.newValue == 'CommSummary') {
                if (!this.model.TextWrap) {
                    this._sharedService.showToast({ severity: 'warn', summary: 'Wrap Text Enabled', detail: 'Wrap text has been enabled for CommSummary to work properly.' });
                    this.model.TextWrap = true;
                }
                return;
            }
        }
    }

    //#region Grid Drag Events

    onRowDragEnd(e: any) {
        //let indexToBeMoved: number = this.model.Columns.findIndex(x => x.FieldOrder == e.node.data.FieldOrder);
        //console.log("Old field Order:" + this.model.Columns[indexToBeMoved].FieldOrder);
        //console.log("Index:" + indexToBeMoved);

        //this.model.Columns[indexToBeMoved].FieldOrder = e.overIndex;
        //console.log("New field Order:" + this.model.Columns[indexToBeMoved].FieldOrder);

        //console.log("Old field Order of Move Over:" + this.model.Columns[e.overIndex].FieldOrder);
        //this.model.Columns[e.overIndex].FieldOrder = indexToBeMoved;
        //console.log("New field Order of Move Over:" + this.model.Columns[e.overIndex].FieldOrder);
    }


    //#endregion
    btnCopyViewClick() {
        if (this.selectedView) {
            this._sharedService.showLoader("Processing...");
            let viewId: string = this.selectedView.toString();

            this._viewService.getViewById(viewId)
            .subscribe({
                next: (response) => {
                    let result = response;
                    result.ViewName = this.model.ViewName;
                    result.DisplayName = this.model.DisplayName;
                    result.Status = this.model.Status;
                    result.Visible = this.model.Visible;
                    result.ShowOnDashboard = this.model.ShowOnDashboard;
                    result.selectedDfpStatus = this.model.selectedDfpStatus;
                    result.TextWrap = this.model.TextWrap;
                    result.Source = this.model.Source;
                    result.ViewSettingId = 0;
                    // result.DefaultField = this.model.DefaultField;
                    // result.AssetType = this.model.AssetType;
                    // result.DisplayOrder = this.model.DisplayOrder;
        
                    this.model.DefaultField = result.DefaultField;
                    this.model.AssetType = result.AssetType;
                    this.model.DisplayOrder = result.DisplayOrder;
                    this._viewService.getSpec2500TreeNode(result.Spec2500View)
                    .subscribe({
                        next: (response) => {
                            this.spec2500NodeData = response;
                            this.previousSpec2500View=this.model.Spec2500View;
                            if(this.model.Spec2500View == null || this.model.Spec2500View == ""|| this.model.Spec2500View == undefined)
                            {
                                this.model.Spec2500View = this.spec2500ViewDropDown[0].value;
                                this.previousSpec2500View= this.spec2500ViewDropDown[0].value;
                            }                            
                        },
                        error: (error) => {
                            console.log(error);                            
                        }
                    });
                    this.model = result;
                    let andConditionIndex = this.model.Conditions.findIndex(c => c.FieldName == 'TaskType')
                    if (andConditionIndex != -1) {
                        this.model.Conditions[andConditionIndex].FieldName = 'DataType';
                    }
        
                    let orConditionIndex = this.model.OrConditions.findIndex(c => c.FieldName == 'TaskType')
                    if (orConditionIndex != -1) {
                        this.model.OrConditions[orConditionIndex].FieldName = 'DataType';
                    }
        
                    this.populateFields();
                    this.updateFieldLabels();
                    this.gridDataColumns = [];
        
                    var modelColumns = this.model.Columns;
                    let index = modelColumns.findIndex(f => f.FieldName == 'TaskType');
                    if (index != -1) {
                        // modelColumns[index].FieldLabel = 'DataType';
                        modelColumns[index].FieldName = 'DataType';
                    }
                    //this.gridDataColumns = this.model.Columns;
                    this.gridDataColumns = modelColumns;//this.model.Columns;
                    if (this.fieldsLabelsForGrid && this.fieldsLabelsForGrid.length > 0) {
                        let col1 = this.gridOptionsColumns.columnApi.getColumn("fieldname").getColDef();
                        col1.cellEditorParams.values = this.fieldsLabelsForGrid;
                    }
                    ///
                    this._sharedService.hideLoader();
                    this.displayViewDlg = false;
                    
                },
                error: (error) => {
                    this._sharedService.hideLoader();
        
                    this._sharedService.showToast(
                        { 
                            severity: 'error', 
                            summary: 'Error Occurred', 
                            detail: 'Please try again later.' 
                        }
                    );                    
                }
            });
        }
        else {
            this._sharedService.showToast(
                { 
                    severity: 'error', 
                    summary: 'No View Selected', 
                    detail: 'Please select an view.' 
                }
            );
        }
    }

    updateSelectedViewRow(event: any) {
        this.selectedView = event.data.ViewSettingId;
    }

    btnCancelDialogueClick() {
        this.displayViewDlg = false;
    }


    // #endregion

    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];

        result.push({
            name: "Add Column",
            action: function () {

                params.context.gridCtx.addColumn();
            },
            icon: params.context.gridCtx._sharedService.addIconHTMLasString,
        });

        result.push({
            name: "Delete Column",
            action: function () {
                if (params.node) {
                    params.context.gridCtx.onCellClicked({ data: params.node.data }, 'Context Menu');
                }

            },
            icon: params.context.gridCtx._sharedService.removeIconHTMLasString,
        });

        return result;


    }

    getCustomContextMenuItemsOCR(params: any): any {
        let result: any[] = [];

        result.push({
            name: "Add Search Configuration",
            action: function () {

                params.context.gridContext.addSearchConfig(null);
            },
            icon: params.context.gridContext._sharedService.addIconHTMLasString,
        });

        result.push({
            name: "Edit Search Configuration",
            action: function () {
                params.context.gridContext.builderSearchID = null;
                if (params.node) {
                    params.context.gridContext.builderSearchID = params.node.data.SearchId;
                    params.context.gridContext.OcrGridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                    params.context.gridContext.editSearchConfig(null);
                }

            },
            icon: params.context.gridContext._sharedService.editIconHTMLasString,
        });

        return result;


    }
    //#endregion

    btnCancel() {
        this.router.navigate(['/admin/viewsetting']);
    }

    ValidateInputToken() {
        this._sharedService.showLoader("Processing...");
        if (!this.model.Conditions) {
            this.setColumnSizeFit();
            return;

        }

        let inputTokenConditions = this.model.Conditions.filter(f => f.Value == "{INPUT}");
        if (inputTokenConditions && inputTokenConditions.length > 0) {
            this.model.BatchAutoSearchStatus = false;
            this.isValidInputField = true;
            this.setColumnSizeFit();
            return
        }

        this.isValidInputField = false;
        this.loadBuilderSearchGridData(this.viewID);//(Number(viewId));
    }
    setColumnSizeFit(){
        setTimeout(() => {
            this.OcrGridOptions.api.sizeColumnsToFit();
            this._sharedService.hideLoader();
        }, 1000);
    }
    removeQuote(val: string) {
        val = val.replace(/'/g, '');
        val = val.replace(/"/g, '');
        return val;
    }

    //#region Default Container
    initDefaultContainer() {
        this.dateConfig = this._sharedService.getConfiguration();
        referenceKeyLabel = this._sharedService.getReferenceKeyLabel();
        this.getDateFormatInfo();
        this.containerModel = new SearchContainerModel("", null, null, false);
        this.conGridData = [];
        this.conGridOptions = <GridOptions>
            {
                rowSelection: 'single',
                onGridReady: () => {
                    this.setContainerGridColumns();
                    //this.conGridOptions.api.sizeColumnsToFit();
                },
                suppressContextMenu: true,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                }
            }
        this.populateAsset();
    }

    populateAsset() {
        this._assetService
            .getAssetWithAssetIdByUserRole()
            .subscribe({
                next: (response) => {
                    this.assets = response;                    
                },
                error: (error) => {
                    this.assets = [];
                    console.log(error);                    
                }
            });
    }

    onChangeAsset(event: any) {
        this.conRowSelected = false;
        this.conGridData = [];
        //let assetid = event.value.split('|').pop().trim();
        //US-8181 //let assetid = event.value.split('|')[3].trim();
        //this.populateCategoryByAssetID(assetid);
        this.populateCategoryByAssetID(JSON.parse(event.value).AssetID);
    }

    populateCategoryByAssetID(assetID: string): void {
        this.categories = [];
        this._assetService.getAssetbyID(assetID)
        .subscribe({
            next: (response) => {
                this.categories = [];
        
                if (response.MXRecords) {
                    this.categories.push({ value: 'MXR', label: 'MX Records' });
                }
                if (response.LogBooks) {
                    this.categories.push({ value: 'LOG', label: 'Log Books' });
                }
                if (response.General) {
                    this.categories.push({ value: 'GEN', label: 'General' });
                }
                if (response.Documents) {
                    this.categories.push({ value: 'DOC', label: 'Documents' });
                }
                if (response.Binders) {
                    this.categories.push({ value: 'BND', label: 'Binders' });
                }                
            },
            error: (error) => {
                this.categories = [];
                console.log(error);                
            }
        });
    }


    onChangeCategory(event: any) {
        this.conRowSelected = false;
        this.conGridData = [];
        this._sharedService.recordType = event.value;
        this.setContainerGridColumns();
        this.populateContainerType(event.value);
        this.selectedComboCatergorySelection = event.value;
    }

    populateContainerGrid(): void {

        this._containerService.getFilteredContainer(this.containerModel)
        .subscribe({
            next: (response) => {
                this.setContainerGridColumns();
                this.conGridData = response;
                //    this.conTotalCount = this.conGridData.length;
                this.gridOptions.api.sizeColumnsToFit();                
            },
            error: (error) => {
                this.conGridData = [];
                this.conTotalCount = 0;
                console.log(error);                
            }
        });
    }

    btnCancelContainerClick(containerForm: NgForm) {
        this.displayContainerDlg = false;
        if (containerForm != null) {
            containerForm.resetForm();
        }

    }

    populateContainerType(category: string) {
        this.conTypes = [];
        let body: container = {
            Category: category
        }
        this._containerService.getContainerType(body)
        .subscribe({
            next: (response) => {
                let res = response;
                for (let i = 0; i < res.length; i++) {
                    this.conTypes.push({ value: res[i].Type, label: res[i].Type })
                }                
            },
            error: (error) => {
                this.conGridData = [];
                this.conTotalCount = 0;                
            }
        });
    }

    prepareContainerGridColumns(): void {

        var appConfigs = this._sharedService.getConfiguration();
        if (appConfigs != null) {
            this.Container_MXR_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_MX_Records_Column_Setting')[0].Value);
            this.Container_LOG_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Log_Books_Column_Setting')[0].Value);
            this.Container_GEN_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_General_Column_Setting')[0].Value);
            this.Container_DOC_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Documents_Column_Setting')[0].Value);
            this.Container_BND_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Binders_Column_Setting')[0].Value);
            this.Container_MXR_Sort_By = appConfigs.filter(x => x.Key == 'Container_MXR_Sort_By')[0].Value;
            this.Container_LOG_Sort_By = appConfigs.filter(x => x.Key == 'Container_LOG_Sort_By')[0].Value;
            this.Container_GEN_Sort_By = appConfigs.filter(x => x.Key == 'Container_GEN_Sort_By')[0].Value;
            this.Container_DOC_Sort_By = appConfigs.filter(x => x.Key == 'Container_DOC_Sort_By')[0].Value;
            this.Container_BND_Sort_By = appConfigs.filter(x => x.Key == 'Container_BND_Sort_By')[0].Value;
        }
    }

    public Container_MXR_Sort_By: string = "";
    public Container_LOG_Sort_By: string = "";
    public Container_GEN_Sort_By: string = "";
    public Container_DOC_Sort_By: string = "";
    public Container_BND_Sort_By: string = "";

    setContainerGridColumns(): void {
        if (!this.selectedComboCatergorySelection) {
            return;
        }
        this.conColumnDefs = [];
        columnDefs_1 = [];
        var containerGridCols: any[];
        var container_Col_Sort_On_By: string = '';
        if (this.selectedComboCatergorySelection.toUpperCase() == 'MXR') {
            containerGridCols = this.Container_MXR_Columns;
            container_Col_Sort_On_By = this.Container_MXR_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'LOG') {
            containerGridCols = this.Container_LOG_Columns;
            container_Col_Sort_On_By = this.Container_LOG_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'GEN') {
            containerGridCols = this.Container_GEN_Columns;
            container_Col_Sort_On_By = this.Container_GEN_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'DOC') {
            containerGridCols = this.Container_DOC_Columns;
            container_Col_Sort_On_By = this.Container_DOC_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'BND') {
            containerGridCols = this.Container_BND_Columns;
            container_Col_Sort_On_By = this.Container_BND_Sort_By;
        }
        containerGridCols.forEach((col, index) => {
            let _filter = 'set';
            if (col.FieldName == 'Date' || col.FieldName == 'EndDate' || col.FieldName == 'ScannedOn') {
                _filter = 'agDateColumnFilter';
            }
            let gridCol: any;
            if (col.FieldName == 'ContainerQualityStatus') {
                gridCol = {
                    headerName: col.ColumnLabel,
                    field: 'QualityStatus',//'QualityStatus---ID',//24-02-2021
                    width: col.Width,
                    filter: _filter,
                    tooltipField: col.FieldName,
                    menuTabs: ['filterMenuTab'],
                    hide: !col.IsVisible,
                    filterParams: this.gridStatusFilterParams,
                };
            }
            else {
                gridCol = {
                    headerName: col.ColumnLabel,
                    field: col.FieldName,
                    width: col.Width,
                    filter: _filter,
                    tooltipField: col.FieldName,
                    menuTabs: ['filterMenuTab'],
                    hide: !col.IsVisible
                };
            }
            if (col.FieldName == 'Date' || col.FieldName == 'EndDate' || col.FieldName == 'ScannedOn') {
                gridCol.filterParams =
                {
                    comparator: this.dateComparater,
                    inRangeInclusive: 'true'
                }
                gridCol.valueFormatter = function (params: any) {
                    if (params.value != '' && params.value != null) {
                        let dateString = params.value.substring(0, 10);
                        return moment(dateString).format(dateFormatF.toUpperCase());
                    }
                }
            }
            else if (col.FieldName == 'ContainerQualityStatus') {
                gridCol.filterParams = {
                    cellRenderer: this.statusCellRenderer, // formats filter values
                }
                gridCol.cellRenderer = this.statusCellRenderer;
            }
            columnDefs_1.push(gridCol);
        });
        this.conColumnDefs = columnDefs_1;
        let sortOnCol = container_Col_Sort_On_By.split("||")[0];
        let orderBy = container_Col_Sort_On_By.split("||")[1];
        if (sortOnCol == "ContainerQualityStatus") {
            sortOnCol = "QualityStatus";
        }
        this.conColumnDefs.forEach(function (col, index) {
            if (col.field == sortOnCol) {
                this.conColumnDefs[index].sort = orderBy;
            }
        }.bind(this));
    }


    gridStatusFilterParams = { cellRenderer: this.statusCellRenderer };

    statusCellRenderer(params: any) {
        let html: string = '';
        var eDiv = document.createElement('div');
        if (params.value) {
            try {
                let currentStatus: any = statusList.filter(x => x.QualityStatusID == params.value)[0];
                if (currentStatus && currentStatus.Name) {
                    eDiv.style.background = currentStatus.CSSClass;
                    html = html + currentStatus.Name;
                } else {
                    let currentStatus: any = statusList.filter(x => x.Name == params.value)[0];//params.value)[0];
                    if (currentStatus && currentStatus.Name) {
                        eDiv.style.background = currentStatus.CSSClass;
                        html = html + currentStatus.Name;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            return params.value;
        }
        eDiv.style.height = '100%';
        eDiv.style.width = '100%';
        eDiv.style.color = 'white';
        eDiv.style.textAlign = 'left';
        eDiv.style.paddingLeft = '5px';
        eDiv.innerHTML = html;
        return eDiv;
    }


    setContainerGridColumns_BackUp(): void {
        this.conColumnDefs = [];

        this.conColumnDefs =
            [
                {
                    headerName: 'Type', field: 'Type', filter: 'set', tooltipField: 'Description', menuTabs: ['filterMenuTab'], minWidth: 70,
                    //, filterParams: { comparator: this.ContainerTypeComparater }
                },
                {
                    headerName: 'Date', field: 'Date', filter: 'agDateColumnFilter', filterParams:
                    {
                        comparator: this.dateComparater,
                        inRangeInclusive: 'true'
                    }, valueFormatter: function (params: any) {
                        if (params.value != "" && params.value != null) {

                            let dateString = params.value.substring(0, 10);
                            return moment(dateString).format(dateFormatF.toUpperCase());
                        }
                    }, sort: 'desc', menuTabs: ['filterMenuTab'], minWidth: 90,
                },
                {
                    headerName: 'End Date', field: 'EndDate', filter: 'agDateColumnFilter', filterParams: {
                        comparator: this.dateComparater,
                        inRangeInclusive: 'true'

                    }, valueFormatter: function (params: any) {
                        if (params.value != "" && params.value != null) {
                            let dateString = params.value.substring(0, 10);
                            return moment(dateString).format(dateFormatF.toUpperCase());
                        }
                    }, menuTabs: ['filterMenuTab'], minWidth: 90,
                },
            ];
        if (this.selectedComboCatergorySelection == 'MXR') {
            this.conColumnDefs.push({ headerName: referenceKeyLabel, field: 'ReferenceKey', tooltipField: 'ReferenceKey', menuTabs: ['filterMenuTab'], minWidth: 90, filter: 'set' });
        } else if (this.selectedComboCatergorySelection == 'LOG') {
            this.conColumnDefs.push({ headerName: 'Description', field: 'Description', tooltipField: 'Description', menuTabs: ['filterMenuTab'], minWidth: 110, filter: 'set' });
        } else if (this.selectedComboCatergorySelection == 'GEN') {
            this.conColumnDefs.push({ headerName: 'Title', field: 'ReferenceKey', tooltipField: 'ReferenceKey', menuTabs: ['filterMenuTab'], minWidth: 90, filter: 'set' });
        }
    }

    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            // let dateString = cellValue.substring(0, 10).split(dateSeparatorS);
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateFormatF.toUpperCase()).split(dateSeparatorS);
            var day = Number(dateParts[Number(dayD)].substring(0, 2));
            var month = Number(dateParts[Number(monthM)]) - 1;
            var year = Number(dateParts[Number(yearY)]);
            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        } else {
            return -1;
        }
    }

    getDateFormatInfo(): any {

        if (this.dateConfig != undefined) {
            dateFormatF = this.dateConfig.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
            dateSeparatorS = this.dateConfig.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
            dayD = Number(this.dateConfig.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
            monthM = Number(this.dateConfig.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
            yearY = Number(this.dateConfig.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
        } else {
            dateFormatF = localStorage.getItem('DateFormat');
            dateSeparatorS = localStorage.getItem('DateSeparater');
            dayD = Number(localStorage.getItem('DayIndex'));
            monthM = Number(localStorage.getItem('MonthIndex'));
            yearY = Number(localStorage.getItem('YearIndex'));

        }
    }

    formatContainerGrid(event: any) {
        this.conGridOptions.api.sizeColumnsToFit();
    }

    onContainerRowClick(event: any) {
        this.conRowSelected = true;
    }

    btnSelectContainerClick(containerForm: NgForm) {
        let selectedRow = this.conGridOptions.api.getSelectedRows()[0];

        if (this.useSelectedAsset) {
            this.model.DefaultContainer = `${selectedRow.Type}|${selectedRow.Category}|${this._sharedService.getDateInUserFormat(selectedRow.Date)}|${this._sharedService.getDateInUserFormat(selectedRow.EndDate)}|${selectedRow.ReferenceKey}|{ASN}`;
        } else {
            this.model.DefaultContainer = `${selectedRow.Type}|${selectedRow.Category}|${this._sharedService.getDateInUserFormat(selectedRow.Date)}|${selectedRow.ASN}|${selectedRow.ContainerID}`;
        }

        this.displayContainerDlg = false;
    }

    openDefaultContainerDlg() {
        this.containerModel = new SearchContainerModel("", null, null, false);
        this.conGridData = [];

        this.displayContainerDlg = true;
    }



    //#endregion

    populateQualityStatusDropdown() {
        let qualityStatusListValues = this._sharedService.getContainerQualityStatusList();
        if (qualityStatusListValues == null || qualityStatusListValues.length == 0) {
            this._qualityStatusService.getActiveQualityStatusData(null)
            .subscribe({
                next: (response) => {
                    try {
                        this.qualityStatusList = response;
                        statusList = response;
                    } catch (ex) {
                        this.qualityStatusList = response;
                        statusList = response;
                        console.log(ex);
                    }                    
                },
                error: (error) => {
                    
                }
            });
        } else {
            this.qualityStatusList = qualityStatusListValues;
            statusList = qualityStatusListValues;
        }
    }
    isCheckedStatus: boolean = false
    ToggleCheckedStatus() {

        if (this.isCheckedStatus) {
            this.isCheckedStatus = false;
            this.model.Status = false;
        }
        else {
            this.isCheckedStatus = true;
            this.model.Status = true;
        }
    }
    isCheckedTextWrap: boolean = false
    ToggleCheckedTextWrap() {

        if (this.isCheckedTextWrap) {
            this.isCheckedTextWrap = false;
            this.model.TextWrap = false;
        }
        else {
            this.isCheckedTextWrap = true;
            this.model.TextWrap = true;
        }
    }
    isCheckedVisible: boolean = false
    ToggleCheckedVisible() {

        if (this.isCheckedVisible) {
            this.isCheckedVisible = false;
            this.model.Visible = false;
        }
        else {
            this.isCheckedVisible = true;
            this.model.Visible = true;
        }
    }
    isCheckedShowOnDashboard: boolean = false
    ToggleCheckShowOnDashboard() {

        if (this.isCheckedShowOnDashboard) {
            this.isCheckedShowOnDashboard = false;
            this.model.ShowOnDashboard = false;
        }
        else {
            this.isCheckedShowOnDashboard = true;
            this.model.ShowOnDashboard = true;
        }
    }
    astradio() {
    }

    ngOnDestroy() {
        if (this.panalClicksubs) {
            this.panalClicksubs.unsubscribe();
        }
        this.appHotkeys.forEach(hotkey => {
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
        this.displayTargetNodeDialog = false;

    }
    btnCloseTreeNode() {
        this.displayTargetNodeDialog = false;
        this.selectedRowNode = null;
        this.selectedspec2500Node = null;
    }

    btnClearTreeNode() {
        var selectedRows: any = this.gridOptionsColumns.api.getSelectedNodes();
        selectedRows[0].setDataValue('spec2500',null);
        this.cRef.markForCheck();
        this.displayTargetNodeDialog = false;
        this.selectedRowNode= null;
        this.selectedspec2500Node = [];
    }
    
    btnSaveTreeNode() {

        this.model.Columns
        for(let i=0; i<this.model.Columns.length; i++){
            if(this.model.Columns[i].Spec2500 == this.selectedRowNode.data)
            {
                this._sharedService.showToast({ severity: 'warn', summary: 'Mapping field is mapped', detail: 'Target Field already exists.' });
                return
            }
        }
        var FieldNameLowerCase: any = this.gridOptionsColumns.api.getSelectedNodes()[0].data.FieldName.toLowerCase();
        var splitedNodeData = this.selectedRowNode.label.split("--");
        if (splitedNodeData.length > 1) {
            var FieldName: any = this.gridOptionsColumns.api.getSelectedNodes()[0].data.FieldName;
            //let nodeDataType = splitedNodeData[1].trim().toLowerCase();
            if(FieldName == "ID" || FieldName == "ASN" || FieldName == "DataType" || FieldName == "DFPStatus" || FieldName == "Comments" ||
            FieldName == "History" || FieldName == "CreatedBy" || FieldName == "ModifiedBy" || FieldName == "CommSummary" ||
            FieldName == "Comms" || FieldName == "FeedLogId" || FieldName == "LatestRecord" || FieldName == "Comments" ||
            FieldName == "CreatedOn" || FieldName == "ModifiedOn" || FieldName == "Status"){
                this._sharedService.showToast({ severity: 'warn', summary: 'Mapping field is invalid', detail: 'Can not map this field.' });

            }
            else if (FieldNameLowerCase.includes(splitedNodeData[1].trim().toLowerCase())) {
                this.setNodeDataOnSave();
            }
            else {
                this.setTypeMismatchError();
            }
        }
        else {
             this.setNodeDataOnSave();
        }
    }
    setNodeDataOnSave() {
        var selectedRows: any = this.gridOptionsColumns.api.getSelectedNodes();
        selectedRows[0].setDataValue('spec2500', this.selectedRowNode.data);
        this.cRef.markForCheck();
        this.displayTargetNodeDialog = false;
        this.selectedRowNode = null;
        this.selectedspec2500Node = [];
    }
    setTypeMismatchError(){
        var splitedNodeData = this.selectedRowNode.label.split("--");
        this._sharedService.showToast({ severity: 'warn', summary: 'Mapping field is mismatched', detail: 'Field Name and mapping field('+splitedNodeData[1].trim()+') is mismatched.' });
    }
    txtSearchMouseDown() {
        this.isTextclicked = true;
    }

    setSelectedAssetText(selectedspec2500Node: any) {
        this.selectedSpec2500NodeName = selectedspec2500Node[0].label, this.selectedClassName = selectedspec2500Node[0].expandedIcon ? selectedspec2500Node[0].expandedIcon : selectedspec2500Node[0].icon;
        this.setAssetClass();
        this.cRef.markForCheck();
    }
    //#endregion

    //#region Asset Class
    setAssetClass() {
        if (this.selectedSpec2500NodeName) {
            if (this.selectedSpec2500NodeName.length > 16) {
                this.selectedFontClassName = "small-fontsize";
            }
            else {
                this.selectedFontClassName = "standard-fontsize";
            }

            this.cRef.markForCheck();
        }

    }
    //#endregion

    //#region events
    selectedRowNode = null;
    spec2500NodeSelect(event: any) {
        if (this.selectedspec2500Node && this.selectedspec2500Node.length > 1) {
            this.selectedspec2500Node = [];
            this.selectedspec2500Node.push(event.node);
        }

        this.selectedRowNode = event.node
        this.cRef.markForCheck();
    }

    assetClick() {
        if (!this.isClassVisible) {
            this.isClassVisible = true;
        }
        this.cRef.markForCheck();
    }

    setAssetControlVisibility(status: any) {
        this.isBrowseScreen = status;
        this.cRef.markForCheck();
    }

    spec2500NodeUnselect(event: any) {
        if (this.selectedspec2500Node && this.selectedspec2500Node.length == 0) {
            this.selectedspec2500Node = [];
            this.selectedSpec2500NodeName = "Select An Target Value";
            this.selectedClassName = "";
            this.setAssetClass();
        }
        this.cRef.markForCheck();
    }
    onBlur(control?: string) {

        if (this.isTextclicked == false) {
            this.isClassVisible = false;
        }
        else
            if (control && control == "child") {
                this.isTextclicked = false;
                this.txtbox.nativeElement.focus();
            }
    }
    previousSpec2500View=null;
    Spec2500ViewChage(event) {
        if(this.previousSpec2500View !=null && this.previousSpec2500View != undefined && this.previousSpec2500View != "" && this.previousSpec2500View != "none")
        this.confirmDeleteContainer(event)
        else{
            var rows = this.gridOptionsColumns.api.getDisplayedRowCount();
            for (let i = 0; i < rows; i++) {
                var randomId = i.toString();
                var rowNode = this.gridOptionsColumns.api.getRowNode(randomId);
                rowNode.setDataValue('spec2500', '')
            }
            this._viewService.getSpec2500TreeNode(event.value)
            .subscribe({
                next: (response) => {
                    this.spec2500NodeData = response;
                    this.previousSpec2500View = this.model.Spec2500View;
                    
                },
                error: (error) => {
                    console.log(error);                    
                }
            });
        }
    }
    confirmDeleteContainer(event: any) {
        this._confirmationService.confirm({
            message: 'Existing mapping will be removed.',
            header: 'Confirm change view',
            icon: 'fa fa-exclamation-triangle',
            accept: () => {
                var rows = this.gridOptionsColumns.api.getDisplayedRowCount();
                for (let i = 0; i < rows; i++) {
                    var randomId = i.toString();
                    var rowNode = this.gridOptionsColumns.api.getRowNode(randomId);
                    rowNode.setDataValue('spec2500', '')
                }
                this._viewService.getSpec2500TreeNode(event.value)
                .subscribe({
                    next: (response) => {
                        this.spec2500NodeData = response;
                        this.previousSpec2500View = this.model.Spec2500View                        
                    },
                    error: (error) => {
                        console.log(error);                        
                    }
                });
            },
            reject: () => {
                    this.model.Spec2500View = this.previousSpec2500View;
            }
        });
        this.cRef.markForCheck();
    }
    StatusTab(){
        this.populateView();
    }
}

  
    const commentTooltipValueGetter = 
    
    "Examples: <br>if(Condition,Success,Fail) <br>if(Date2 == TODAY,\"sucess\",\"fail\") <br>if(Date1 >= TIMENOW,\"sucess\",\"fail\")"