import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SharedService } from '../../../shared/services/shared.service';
import { azureSearchKeywords } from '../../../shared/services/AzureSearch/azure-search.keywords';

@Injectable()
export class AzureSearchService {
    constructor(private _sharedService: SharedService, private http: HttpClient) {
    }

    getAzureSearchData(keywords: azureSearchKeywords): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Search/BrowseOCRSearch`, keywords,this._sharedService.getReqOptWithAccessToken());
    }


}