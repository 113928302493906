
//Components
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';

@Component({
    selector: 'status',
    templateUrl: './status.component.html'
})

export class StatusComponent implements OnInit {
    
    //#region Gloabal Variables
   
    //#endregion

    //#region Initialization of Component
    constructor(private _sharedService: SharedService,  private _router: Router) {              
    }
    ngOnInit() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else
        {
            this._sharedService.prevURL = this._router.url;
            this._sharedService.updateRole();
            this._sharedService.getConfiguration();
            this._sharedService.onModuleChanged("status");
        }
    }
    //#endregion


  
    
}