import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppVersionXML } from 'src/app/admin/components/settings/settings';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: "app-toolbox-download",
  templateUrl: "./toolbox-download.component.html",
})
export class ToolboxDownloadComponent implements OnInit {
  constructor(private _sharedService: SharedService, private _router: Router) {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(["login"]);
    }
  }

  ngOnInit() {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(["login"]);
    }
  }
  downloadToolbox() {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(["login"]);
    }
    this._sharedService.GetSetupVersionByKey(AppVersionXML[1].toString())
    .subscribe({
        next: (response) => {
            var RDOCBaseVersion=response;
            let fileName: string = `latest/ROAMToolbox-v${RDOCBaseVersion}-installer.exe`;
        let container: string = "roamtoolbox";
        this._sharedService.downloadAUP(fileName, container).subscribe({
          next: (response) => {
            let fileURL = response;
            console.log(fileURL);
            var a = document.createElement("a");
            a.style.display = "none";
            a.href = fileURL;
            a.target = "_blank";
            a.download = fileName;
            window.document.body.appendChild(a);
            a.click();
            window.document.body.removeChild(a);
            URL.revokeObjectURL(fileURL);
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        this._sharedService.showToast({
          severity: "error",
          summary: "Error Occured",
          detail: "No file exists",
        });
        console.log(error);
      },
    });
  }
  downloadAUP() {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(["login"]);
    }
    let containerName: string = "misc";
    let fileName: string = "Documents/Acceptable Use Policy.pdf";
    this._sharedService.downloadAUP(fileName, containerName).subscribe({
      next: (response) => {
        let fileURL = response;
        console.log(fileURL);
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = fileURL;
        a.target = "_blank";
        a.download = fileName;
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }
}
