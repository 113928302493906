
<!-- <div class="jarviswidget-ctrls" role="menu" title="Add Feed (Ctrl+A)">
    <a [attr.tabindex]="0"  class="button-icon tablIndexFocus" id="btnaddfeed" (keyup.enter)="showAddDialog()" (click)="showAddDialog()">
        <i class="glyphicon glyphicon-plus"></i>
    </a>
</div>  -->

<form style="text-align: left;" #containerForm="ngForm">
    <p-dialog [appendTo]="!showReportButton?'body':''"
    [(visible)]="containerSelectionDlg" showEffect="fade" [modal]="true"
        [style]="{'width': '720px'}" [resizable]="false">
        <ng-template pTemplate="header"  style="float: left;">
            Select Target Container
        </ng-template>
        <div style="padding: 3px;" class="upload-dialog">
            <div class="smart-form">
                <input type="text" hidden>
                <div style="text-align: left;" class="row">
                    <div class="form-group col-sm-5 fix-Padding">
                            <!-- [(ngModel)]="containerModel.asn" #asn="ngModel" -->
                            <label class="label">Asset</label>
                            <p-dropdown appendTo="body" id="asn" class="comboFit" name="asn" [options]="assets"
                                (onChange)="onChangeAsset($event)" placeholder="Select Asset" required
                                 [filter]="true"
                                 [(ngModel)]="containerModel.asn" #asn="ngModel" 
                                [resetFilterOnHide]="true">
                                <ng-template style="padding-bottom:20px;padding-left: 0px !important;" let-item
                                    pTemplate="selectedItem">
                                    <span>{{item.label}}</span>
                                    <!-- <span style="font-size:14px;background-color:lightgreen;">{{item.AssetType}}</span> -->
                                </ng-template>
                                <ng-template style="padding-bottom:20px !important;padding-left: 0px !important;"
                                    let-asset pTemplate="item">
                                    <div style="padding-bottom: 20px;">
                                        <!-- <span > -->
                                        <span style="padding:0" class="col-md-8">{{asset.label}}</span>

                                        <!-- </div> -->
                                        <!-- <div class="col-md-4"> -->
                                        <span style="padding:0" class="col-md-4">
                                            <span
                                                style="font-size: 11px;padding: 4px;background-color:lightgreen;color: white;border-radius: 3px;">
                                                {{asset.AssetType}}
                                            </span>
                                        </span>

                                        <!-- </div> -->
                                    </div>
                                </ng-template>
                            </p-dropdown>
                    </div>
                    <div style="padding-left: 5px;" class="form-group col-sm-5 fix-Padding">
                            <!-- [(ngModel)]="containerModel.category" #category="ngModel" -->
                            <label class="label">Category </label>
                            <p-dropdown appendTo="body" id="category" name="category" class="comboFit" [options]="categories"
                                (onChange)="onChangeCategory($event)" placeholder="Select Category"
                                [(ngModel)]="containerModel.category" #category="ngModel">
                            </p-dropdown>
                    </div>
                    <div class="form-group col-sm-2">
                        <fieldset class="pull-right">
                            <label class="label" style="height: 16px;"></label>
                            
                            <button pButton type="button" class="custom-button" style="line-height:17px !important" (click)="populateContainerGrid()"
                                icon="fa fa-search" label="Search" [disabled]="!containerForm.form.valid"></button>
                        </fieldset>
                    </div>
                </div>
                <div class="row" style="margin-top: 5px;">
                    <div class="form-group col-sm-12">
                        <ag-grid-angular #containerGrid [gridOptions]="conGridOptions" [columnDefs]="conColumnDefs"
                            [rowData]="conGridData"
                            enableFiltering sortable="true" style="width: 100% !important; height:300px !important;"
                            class="ag-theme-balham" (rowClicked)='onContainerRowClick($event)'>
                        </ag-grid-angular>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton  class="btn btn-primary minzindex"
                    (click)="btnContainerSelectionClick(containerForm)"
                    [disabled]="!containerForm.form.valid ||!conRowSelected" label="Select Container"></button>
                <button type="button" pButton  class="btn btn-default minzindex"
                    (click)="btnCancelContainerClick(containerForm)" label="Cancel"></button>

            </div>
        </ng-template>

    </p-dialog>
</form>

<span *ngIf="showFeedContainerIcon">
    <i style="margin-top: -15px;" class='fa fa-cog fa-lg pull-right'(click)="TargetContainerSelection()"></i>

</span>

<button *ngIf="showReportButton" type="button" class="btn btn-primary margin-right-3px custom-button"
    (click)="TargetContainerSelection()">
    Generate to ROAM Container
</button>

