import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from '../../../shared/services/shared.service';
import { SelectItem } from 'src/app/admin/services/builderSearch/builderSearch';
import { TagsModel } from 'src/app/admin/components/tags/tags';
import { Tag } from './tag';

@Injectable()
export class TagService {
    constructor(private _sharedService: SharedService, private http: HttpClient) {
    }

    //#region Service methods
    getAllTags(): Observable<any> {
        return this.http.post<SelectItem[]>(`${this._sharedService.baseUrl}/api/Tags/GetAllTags`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getAllTagsWithColor(): Observable<Tag[]> {
        return this.http.post<Tag[]>(`${this._sharedService.baseUrl}/api/Tags/GetAllTagsWithColor`, '', this._sharedService.getReqOptWithAccessToken());
    }

    addUpdateTags(body: any): Observable<object> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Tags/AddUpdateTags`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getTagByID(body: string): Observable<TagsModel> {
        return this.http.post<TagsModel>(`${this._sharedService.baseUrl}/api/Tags/GetTagByID?tagID=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getAllTagsData(body: any): Observable<any[]> {
        return this.http.post<any[]>(`${this._sharedService.baseUrl}/api/Tags/GetAllTagsData`, body, this._sharedService.getReqOptWithAccessToken());
    }
    //#endregion




}