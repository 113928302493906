import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SharedService } from '../../../shared/services/shared.service';
import { BatchGapAnalyzer, container, containerForAdd, containerForEdit,containerForDateChange, MoveContainerModel } from '../../services/container/container';

@Injectable()
export class ContainerService {
    constructor(private http: HttpClient, private _sharedService: SharedService) {
    }


    getContainerData(body: container): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainer`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getContainerByAsn(asn: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainerByAsn?asn=${asn}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    getContainerByAsnAndModule(asn: string, module: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainerByASNandModule?asn=${asn}&module=${module}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    getContainerInfoForFeed(asn: string, module: string,containerPath: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainerInfoForFeed?asn=${asn}&module=${module}&containerPath=${containerPath}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    

    getFilteredContainer(body: container): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetFilteredContainer`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getContainerDetail(containerId: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainerDetail?containerId=${containerId}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    moveContainers(body: MoveContainerModel): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/MoveContainer`, body, this._sharedService.getReqOptWithAccessToken());
    }
    addContainerData(body: containerForAdd): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/AddContainer`, body, this._sharedService.getReqOptWithAccessToken());
    }

    updateContainerData(body: containerForEdit): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/UpdateContainer`, body, this._sharedService.getReqOptWithAccessToken());
    }
    changeContainerDate(body: containerForDateChange): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/UpdateContainerDate`, body, this._sharedService.getReqOptWithAccessToken());
    }
    deleteContainer(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/DeleteContainer`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getContainerType(body: container): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainerType`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getBoxType(body: container): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainerBoxType`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getBoxLocation(body: container): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainerLocation`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getScannedBy(body: container): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainerScannedBy`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getBoxID(body: container): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainerBoxID`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getAllContainerTypesData(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetAllContainerTypesData`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getAllActiveContainerTypes(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetAllActiveContainerTypes`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getContainerCategories(): any {
        return [
            { value: 'MXR', label: 'MX Records' },
            { value: 'LOG', label: 'Log Books' },
            { value: 'GEN', label: 'General' },
            { value: 'DOC', label: 'Documents' },
            { value: 'BND', label: 'Binders' }
        ];

    }
    addUpdateContainerType(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/AddUpdateContainerType`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getContainerTypeByID(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainerTypeByID`, body, this._sharedService.getReqOptWithAccessToken());
    }
    deleteAllDocuments(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/DeleteAllDocument`, body, this._sharedService.getReqOptWithAccessToken());
    }
    UpdateContainerQualityStatus(body: container): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/UpdateContainerQualityStatus`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getSelectedContainerCategory(containerType): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainerCategoryByType?containerType=${containerType}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    runBatchGapAnalyzer(body: BatchGapAnalyzer): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/RunGapAnalyzerFunction`, body, this._sharedService.getReqOptWithAccessToken());
    }
}
