// Components
import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { NgForm, UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { GridOptions, GridApi, CsvExportParams, ExcelCell, RowNode, ColumnApi, RowDataTransaction, ExcelRow, IRowNode } from "ag-grid-community";
import { SelectItem, TreeNode, ConfirmationService } from 'primeng/api';
import { Subscription, Subject, pairwise, filter } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as moment from 'moment/moment';
import { Observable } from "rxjs";
import jsPDF from 'jspdf';
import { Location } from '@angular/common';
import * as cloneDeep from 'lodash/cloneDeep';
// Services
import { SharedService } from '../../../shared/services/shared.service';
import { AssetService } from '../../../admin/services/asset/asset.service';
import { ContainerService } from '../../../records/services/container/container.service';
import { StatusService } from '../../services/status.service';
import { SearchContainerModel } from '../../../records/services/container/container';
import { ViewService } from '../../../admin/services/view/view.service';
import { ViewModel, Column, Condition } from '../../../admin/services/view/view';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
import { TaskService } from '../../../admin/services/task/task.service';
import { TaskDocumentService } from '../../../admin/services/task-document/task-document.service';
import { TaskDocument } from '../../../admin/services/task-document/task-document';
import { AzureBlob, BlobMetaData } from '../../../shared/services/AzureBlob/blob-metadata';
import { IssueService } from '../../../issue/services/issue.service';
import { CommPartial } from '../../../comm/components/comm/commpartial';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { APIService } from '../../../api/services/api.service';
import { AuditModel } from '../../../shared/services/Audit/audit';
import { DFPStatusModel } from "../../../records/components/home/dfpstatus.model";
import { DashboardService } from "../../../records/services/dashboard/dashboard.service";
import { runJob } from "./run-Job";
import { ChannelService, ChannelEvent } from '../../../shared/services/signalR/channel.Service';
import { viewJobProgress } from "../../../status/components/view-job-progress/view-job-progress";
// import { stringify } from '@angular/core/src/util';
import { EmbedVideoService } from 'ngx-embed-video';
import { ViewFilter, ManualFilter } from '../../../admin/services/view/viewfilter';
//import { RowDataTransaction } from 'ag-grid-community/dist/lib/rowModels/clientSide/clientSideRowModel';
import { CustomDateComponent } from '../../../shared/components/custom-date/custom-date.component';
import { AzureBlobService } from '../../../shared/services/AzureBlob/azure-blob.service';
import { fabric } from 'fabric';
import { MarkerTypeService } from '../../../admin/services/marker-type/marker-type.service';
import { CanvasObject } from '../../../admin/services/view/canvasobject';
import { CardViewListComponent } from '../card-view-list/card-view-list.component';
import { UserService } from '../../../records/services/User/user.service';
import { CommService } from '../../../comm/services/comm.service';

var dateTimeConfig: IDateFormatInfo;

var dateTimeConfig: IDateFormatInfo;
var _gridOptions: GridOptions;
var taskService: TaskService;
var sharedService: SharedService;
var router: Router;
var detailGridParams: any;
var detailGridApi: GridApi;
var detailGridColumnApi: ColumnApi;
var detailGridColumnDefs: any[];
var selectedRow = new Subject<object>();
var selectedViewId = "";
var isDetailGridRowSelected: boolean = false;
var IsDetailRowClicked: boolean = false;
var dfpStatusesTask: DFPStatusModel[] = [];
var _viewList: ViewList = null;
var isGroupColumnHeaders = false;

@Component({
    selector: 'viewlist',
    templateUrl: './view-list.component.html',
    //directives: [DocManipulation],
    providers: [ContainerService, AssetService, StatusService, ViewService, TaskService, TaskDocumentService, IssueService, ConfirmationService, APIService, DashboardService, AzureBlobService, MarkerTypeService, UserService, CommService]


})



export class ViewList implements OnInit, OnDestroy {

    // @ViewChild(DocManipulation, { static: false }) _childDocManipulation: DocManipulation;


    uploadedFile: any = [];
    isFileSelected: boolean = false;

    @ViewChild('videoPlayer') _videoPlayer: ElementRef;

    @ViewChild('audioPlayer') _audioPlayer: ElementRef;

    private eventName = "tasks"
    private channel = "Status";
    displayRunningTaskDialog: boolean = false;
    //#region View List  Variables

    issueColumnDefs: any[];
    public issueGridData: any[];
    public issueGridOptions: GridOptions;
    public issueApi: GridApi;
    displayissuedialogue: boolean = false;
    displayTaskDialog: boolean = false;
    columnDefs: any[];
    public gridData: any[];
    chartColumnDefs: any[];
    public chartGridData: any[];
    public gridOptions: GridOptions;
    public chartGridOptions: GridOptions;
    public detailGridOptions: GridOptions;
    public api: GridApi;
    public totalCount: number = 0;
    paramsSub: Subscription;
    subscription: Subscription;
    DetailGridSub: Subscription;
    assetStatusSub: Subscription;
    routeSub: Subscription;
    populateTaskSubscription: Subscription;
    ViewModel: ViewModel;

    taskDocuments: TaskDocument[] = [];
    selectedVideoUrl: string;
    video_html: string = "";
    taskDocumentsList: any[] = [];
    @Input() feedlogid: string = '';
    @Input() isFromAPIModule: string = null;
    isInRepairModule = false;
    asn: string = "";
    isActive: string = '';
    viewHistoryKey: any[];
    rowUniqueKey: string = '';
    dateFormatCalender = "";
    // tskForm: FormGroup = new FormGroup({});
    selectedTaskStatus: string = '';
    commobj: CommPartial = null;
    subscriptionMessage: Subscription;
    dialoguesubscription: Subscription;
    refreshSubsription: Subscription;
    taskList: any[] = [];
    taskDlgTitle: string = '';
    taskButtonTitle: string = 'Hide Entry';
    taskFeedlogId: number = 0;

    public appHotkeys: Hotkey[] = [];

    //#endregion

    //#region Record Popup Variables

    displayDialog: boolean = false;
    displayConfirmDlg: boolean = false;
    containerModel: SearchContainerModel;
    containerData: SelectItem[] = [];
    assets: SelectItem[] = [];
    dfpStatusList: SelectItem[] = [];
    filteredDfpStatusList: SelectItem[] = [];
    categories: SelectItem[] = [];
    recordType: string;
    selectedAsset: TreeNode[] = [];
    showSearch: boolean = false;
    showMXRBtn: boolean = false;
    showLogBookBtn: boolean = false;
    showGeneralBtn: boolean = false;
    showDocumentBtn: boolean = false;
    showBindersBtn: boolean = false;
    isDisplayExtractPagesDialog: boolean = false;

    PageRange: string = '';
    viewID: string = '';
    getRowNodeId: any;
    getDetailRowNodeId: any;
    taskGridOptions: GridOptions
    taskColumnDefs: any[] = [];
    taskGridData: any[] = [];
    showSelectedTask: boolean = true;
    lastUpdatedDate: string = "";
    // public excelStyles: ExcelStyle;
    public excelStyles: any;
    //#endregion

    //#region PDF Viewer variables
    // public displayMode: string;
    selectedDoc: string;
    selectedTaskDoc: TaskDocument;
    sltdDocId: string;
    pdfSrc: SafeResourceUrl;
    sanitiz: DomSanitizer;
    zoomOption: SelectItem[] = [];
    selectedOption: string;
    selectedUrl: string;
    selectedZoom: string = "#zoom=auto";
    isCurrentModule: boolean = false;
    ViewSettingId: string;
    canUpdateDFPStatus: boolean = false;
    canLinkDocumentStatus: boolean = false;
    canViewHistory: boolean = false;
    canAddTask: boolean = false;
    canEditTask: boolean = false;
    canDeleteTask: boolean = false;
    canViewInRecord: boolean = false;
    canBulkDownload: boolean = false;
    canViewStatusAudit: boolean = false;
    canRunSearchAutoLink: boolean = false;
    public displayConfigdialogue: boolean = false;
    public feedTypeInput: string = "ExportTemp";
    public isContainerFilterVisible: boolean = false;
    private ChannelSubscription: Subscription;
    public ManualFilterList: ManualFilter[] = [];
    ManualFilterFormGroup: UntypedFormGroup = new UntypedFormGroup({});
    public isInternalView: boolean = true; // Tre for Local/Internal
    public canViewFeedFromStatus: boolean = false;
    // An internal "copy" of the connection state stream used because
    //  we want to map the values of the original stream. If we didn't
    //  need to do that then we could use the service's observable
    //  right in the template.
    //
    connectionState$: Observable<string>;
    signalrService: any;
    public isProcessed: boolean = false;
    selectedTask: any[] = [];

    public isAddVisible: Boolean = false;
    public statusTaskObj: any;
    public canDetachDocumentStatus: boolean = false;
    public canUploadDocumentStatus: boolean = false;
    public canEditDocumentStatus: boolean = false;
    public rowHeight;
    public displayFeedDialog: boolean = false;
    public feedType: string = 'Import';
    public targetType = "Repair";
    public previousTaskUrl;
    public componentName = 'STAV';
    public mimeTypeImageSrc = '';
    public mimeTypeFileName = '';

    fileType: string = 'application/pdf';

    canvas: fabric.Canvas;
    isMouseDown: boolean = false;
    isAddObject: boolean = false;
    orignX: any;
    orignY: any;
    selectedPoint: any[];
    objectType: string;
    currentCircle: fabric.Circle;
    currentPath: any;
    markerTypeList: any[] = [];
    selectedMarkerType: any;
    markerTypeFilterList: any[] = [];
    selectedMarkerTypeFilter: any;
    pointGuid: string;
    canvasObject: CanvasObject;
    zoomPercentage: number = 100;
    isInEditMode: boolean = false;
    editModeText: string = 'Switch to Edit Mode';
    editModeIcon: string = 'fa fa-plus';
    previousFilePath: string;
    renderedTaskDocumentList: any[];

    currentImg: any = null;

    showPDF_or_ImageViewer: boolean = true;
    showAudioPlayer: boolean = true;
    showVideoPlayer: boolean = true;

    isAudioTypeFound: string = null;
    isVideoTypeFound: string = null;
    isVideoURLTypeFound: string = null;

    isNonPreviewTypeFound: string = null;
    isDocumentTypeFound: string = null;
    isImageTypeFound: string = null;
    public maxAllowedFileSize: number = 0;
    public maxAllowedFileSizeMB: number = 0;
    viewDataType: string = null;
    public acceptFileTypes = "";

    //#endregion


    assignedToList: SelectItem[] = [];
    requestedByList: SelectItem[] = [];
    commStatusList: SelectItem[] = [];

    public showUpdateFileDlg: boolean = false;
    public showUploadAsImage = false;
    public disableImageLoadOption: boolean = false;
    public isImageSwitch: number = 0;
    public BlobName: string;
    //#region Initialization of Component
    constructor(private channelService: ChannelService, private _sharedService: SharedService, private location: Location, private _router: Router, private _containerService: ContainerService, private _assetService: AssetService,
        private sanitizer: DomSanitizer, private _statusService: StatusService, private _route: ActivatedRoute, private _viewService: ViewService,
        private _taskService: TaskService, private cRef: ChangeDetectorRef, private _taskDocumentService: TaskDocumentService,
        private confirmationService: ConfirmationService, private _issueService: IssueService, private fb: UntypedFormBuilder, private _hotkeysService: HotkeysService, private _apiService: APIService, private _dashboardService: DashboardService, private embedVideoService: EmbedVideoService, private _azureBlobService: AzureBlobService,
        private _markerTypeService: MarkerTypeService, private _userService: UserService, private _commService: CommService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        this.getDFPStatus();
        dateTimeConfig = this._sharedService.getDateTimeFormatConfiguration();
        this.populateDFPStatus();
        this.populateAsset();
        taskService = this._taskService;
        this.sanitiz = sanitizer;
        this.getZoomOptions();
        // this.createTaskForm();
        this.getRowNodeId = function (params: any) {
            return params.data.TaskID;
        };

        this.getDetailRowNodeId = function (data: any) {
            return data.TaskID;
        };
        // For Navigation to Task Status
        if (this._router.url.indexOf('/status/') != -1 && this._sharedService.navigationInfo && this._sharedService.navigationInfo.isNavigate) {
            this._router.navigateByUrl("/status/viewlist/" + this._sharedService.navigationInfo.id);
        }

        // For Navigation to Task Repair
        if (this._router.url.indexOf('/repair/') != -1 && this._sharedService.navigationInfo && this._sharedService.navigationInfo.isNavigate) {
            this._router.navigateByUrl("/repair/viewlist/" + this._sharedService.navigationInfo.id);
        }

        _viewList = this;
    }

    ngOnInit() {
        this.maxAllowedFileSize = this._sharedService.getMaxDocumentLimitInBytes();
        this.maxAllowedFileSizeMB = this._sharedService.getMaxDocumentLimit();
        this.setHotKeys();
        this.UpdateRoles();
        this.channelService.startHubConnection();
        this.setCalenderDateFormat();
        this.resetCanvasAndControls();
        this.populateMarkerType();

        //this.populateUsersList();
        //this.populateCommStatus();
        this.getAssignedToUsersList();

        this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_DOC_CATEGORY + "," + this._sharedService.allowedAzureIndexFileContentTypes.toString();

        if (this._router.url.indexOf("status/viewlist") != -1 || this._router.url.indexOf("repair/viewlist") != -1 || (this.isFromAPIModule && this.isFromAPIModule == 'API')) {
            //if (this._router.url.indexOf("status/viewlist") != -1) {
            if (this.paramsSub) { this.paramsSub.unsubscribe(); }
            this.paramsSub = this._route.params
                .subscribe((params: Params) => {
                    this.resetCanvasAndControls();
                    if (params['id'] || this.isFromAPIModule == 'API') {
                        if (params['id']) {
                            this.ViewSettingId = params['id'];
                            selectedViewId = this.ViewSettingId;
                            this.gridData = [];
                            this.taskDocuments = [];
                            this.resetCanvasAndControls();
                        }
                        this.containerModel = new SearchContainerModel("", "", null, false);
                        this.ManualFilterList = [];
                        this.selectedVideoUrl = null;
                        this.ManualFilterFormGroup = new UntypedFormGroup({});
                        this.populateGrid();
                        if (this._sharedService.runningViewTasks && this._sharedService.runningViewTasks.length > 0) {
                            this.isProcessed = true;
                        }
                        else {
                            this.isProcessed = false;
                        }
                        if (!this.feedlogid) {
                            this.isCurrentModule = this._sharedService.selectedModule == "status";
                        }
                    } else {
                        this.gridData = [];
                        this.columnDefs = [];
                    }

                    if (this._router.url.indexOf("/repair/viewlist") != -1) {
                        this.isInRepairModule = true;
                        this.componentName = 'REPV';
                    } else {
                        this.isInRepairModule = false;
                        this.componentName = 'STAV';
                    }

                });

            // this.subscriptionRefreshViewList = this._sharedService._isRefreshViewList.subscribe(
            //     (status: any) => this.closeDialogueModel(status)
            // );
            this.subscriptionMessage = this._sharedService._isShowMsg
                .subscribe({
                    next: (status: any) => {
                        this.displayMessages(status)
                    }
                })
            // this.dialoguesubscription = this._sharedService._changeStatusConfigDialogueStatus.subscribe(
            //    (flag: any) => {
            //       // this.OpencloseConfigDialogue(flag.showDialogue)
            //        if (flag.showMessage) {
            //            this._sharedService.clearToast();
            //            this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Request to process feed started.' });
            //        }

            //    }

            // );
        } else if (this._router.url.indexOf("record/document") != -1 || this._router.url.indexOf("record/browse") != -1 || this._router.url.indexOf("record/logs") != -1 || this._router.url.indexOf("record/general") != -1 || this._router.url.indexOf("record/search") != -1 || this._router.url.indexOf("transition/search") != -1 || this._router.url.indexOf("transition/binders") != -1) {
            this.assetStatusSub = this._sharedService._selectedAssetStatus
                .subscribe({
                    next: (selAsset: any) => {
                        if (this._sharedService.selectedAssetStatus && this._sharedService.selectedAssetStatus[0]) {

                            this.UpdateRoles();
                            //US-8181 //
                            // if (this._sharedService.selectedAssetStatus[0].data.split('|')[1]) {
                            //     this.asn = this._sharedService.selectedAssetStatus[0].data.split('|')[1];
                            // }
                            // if (this._sharedService.selectedAssetStatus[0].data.split('|')[2]) {
                            //     this.ViewSettingId = this._sharedService.selectedAssetStatus[0].data.split('|')[2];
                            //     selectedViewId = this.ViewSettingId;
                            // }

                            let assetDataObj = JSON.parse(this._sharedService.selectedAssetStatus[0].data);
                            this.asn = assetDataObj.ASN;
                            if (assetDataObj.viewSettingId) {
                                this.ViewSettingId = assetDataObj.viewSettingId;//SearchPath;
                                selectedViewId = this.ViewSettingId;
                            }

                            this.gridOptions.rowSelection = 'multiple';
                            try {
                                this.selectedVideoUrl = null;
                                this.containerModel = new SearchContainerModel("", "", null, false);
                                this.ManualFilterList = [];
                                this.ManualFilterFormGroup = new UntypedFormGroup({});
                                this.populateGrid();
                            } catch (e) {
                                console.log(e);
                            }

                        }
                        else { // on open dialogue> view-list....; viewSettngID is set to 0 i.e. this.containerModel.viewSettingId = "0"; in component status-popup
                            // in this component grid will reset in order to refrsh old state
                            // fixed issue > 440 Records>Attach Document
                            this.resetCanvasAndControls();
                            this.pdfSrc = null
                            this.taskDocuments = [];
                            this.showPDF_or_ImageViewer = false;
                            this.gridData = [];
                            this.columnDefs = [];
                            this.totalCount = 0;
                            this.cRef.markForCheck();

                        }
                    }
                });
        }

        sharedService = this._sharedService;
        router = this._router;

        this.detailGridOptions = <GridOptions>{
            rowSelection: 'multiple',
            singleClickEdit: this.canUpdateDFPStatus,
            onGridReady: function (params) {
                if (params.api) {
                    detailGridApi = params.api;
                    detailGridColumnApi = params.columnApi;
                    // Remove Group Column
                    if (detailGridColumnDefs.length > 0 && detailGridColumnDefs[0].field == 'TaskID') {
                        detailGridColumnDefs.splice(0, 1);
                    }
                    params.api.setColumnDefs(detailGridColumnDefs);
                    if (params.api.getSelectedRows().length > 0 && params.api.getSelectedRows()[0]['LatestRecord'] != 1) {
                        params.api.hideOverlay();
                    }
                    detailGridApi.resetRowHeights();
                }
            },
            onNewColumnsLoaded: this.updateDetailGridColumns,
            onVirtualColumnsChanged: this.updateDetailGridColumns,
            onColumnRowGroupChanged: this.updateDetailGridColumns,
            onRowClicked: this.detailRowClicked,
            onSelectionChanged: this.onDetailGridRowsSelectionChanged,
            onCellClicked: this.onCellClickedForDetail,
            onRowDoubleClicked: this.showDetailGridEditTask,
            // suppressContextMenu: true,
            filter: false,
            //onCellEditingStopped: this.updateDFPStatus,
            onCellValueChanged: this.updateDFPStatus.bind(this),
            // groupSelectsChildren: false,
            rowGroupPanelShow: 'always',
            getRowNodeId: this.getDetailRowNodeId,
            getContextMenuItems: this.getCustomContextMenuItemsDetail,
            context: {
                detailGridCtx: this
            },
            toolPanelSuppressSideButtons: true,
            defaultColDef: {
                resizable: true,
            },
            components: { agDateInput: CustomDateComponent }
        }

        //calling initializeGridOptions
        this.InitializeGridOptions();


        this.taskGridOptions = <GridOptions>
            {
                // rowSelection: 'single',

                // onGridReady: () =>
                //  {
                //     this.taskGridOptions.api.sizeColumnsToFit();
                // },
                // onModelUpdated: (event: any) => {

                //     this.taskGridOptions.api.sizeColumnsToFit();
                // },
                onFirstDataRendered: () => {
                    try {
                        if (this.ViewModel && this.ViewModel.TextWrap) {
                            this.taskGridOptions.api.resetRowHeights();
                        }
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                },
                suppressContextMenu: true,
                defaultColDef: {
                    resizable: true,
                    sortable: true
                },
                components: { agDateInput: CustomDateComponent }

            };


        // this.populateGrid();

        this.subscription = this._sharedService._isRefreshTaskDocuments
            .subscribe({
                next: (RefreshTaskDocuments: boolean) => {
                    this.resetCanvasAndControls();
                    this.refreshTaskDocuments();
                }

            });

        this.issueGridOptions = <GridOptions>
            {
                rowSelection: 'single',

                onGridReady: () => {
                    this.setIssueGridColumns();

                },
                suppressContextMenu: true,
                components: { agDateInput: CustomDateComponent }

            };

        // Update Selected Task Document
        this.DetailGridSub = selectedRow
            .subscribe({
                next: (selRow: any) => {
                    this.populateTaskDocument(selRow);
                }
            });

        // this.dialogueSubsc == this._sharedService._isShowDialogue.subscribe(
        //    (status: any) => this.displayissuedialogue = true
        // );
        //#region Refresh Status Grid
        this.refreshSubsription = this._sharedService._isRefreshTaskDocuments
            .subscribe({
                next: (Refreshgrid: boolean) => {
                    this.resetCanvasAndControls();
                    this.populateGrid();
                }

            });

        //#endregion
        this.setExcelStyle();

        this.joinHubChannel();

        this.showMXRBtn = false;
        this.showLogBookBtn = false;
        this.showGeneralBtn = false;
        this.showDocumentBtn = false;
        this.showBindersBtn = false;


        this.populateTaskSubscription = this._sharedService._selectedTaskRecord
            .subscribe({
                next: (selDoc: any) => {
                    this.populateTaskDocument(selDoc)
                }
            });
    }

    InitializeGridOptions() {
        // this.gridOptions = null;
        // _gridOptions = null;
        // this.api = null;
        this.gridOptions = <GridOptions>
            {
                rowSelection: 'multiple',
                singleClickEdit: this.canUpdateDFPStatus,
                onGridReady: (params) => {
                    this.gridOptions.api = params.api;
                    this.gridOptions.columnApi = params.columnApi;
                    this.api = params.api;
                    _gridOptions.api = params.api;

                    if (this.gridOptions.api != undefined) {
                        this.setGridColumns();
                        // this.gridOptions.api.setColumnDefs(this.columnDefs);
                        // this.gridOptions.api.sizeColumnsToFit();
                        this.api = this.gridOptions.api;
                        this.api.resetRowHeights();
                    }
                    this._sharedService.showHideStatusMenu();
                },
                onFirstDataRendered: () => {
                    try {
                        if (this.ViewModel && this.ViewModel.TextWrap) {
                            this.api.resetRowHeights();
                        }
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                },
                onRowDataChanged: () => {
                    try {
                        // if (this.ViewModel && this.ViewModel.TextWrap) {
                        if (this.api) {
                            this.api.resetRowHeights();
                        }
                        // }
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                },
                onRowDataUpdated: () => {
                    try {
                        if (this.ViewModel && this.ViewModel.TextWrap) {
                            this.api.resetRowHeights();
                        }
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                },
                headerHeight: 48,
                groupHeaderHeight: 25,
                masterDetail: this.canViewHistory,
                detailCellRendererParams: {
                    // provide detail grid options
                    detailGridOptions: this.detailGridOptions,
                    // extract and supply row data for detail
                    getDetailRowData: function (params: any) {
                        // Get Child Records
                        detailGridParams = params.data;
                        params.successCallback([]);
                        // Set Child Records
                        // setTimeout(params.successCallback([params.data]), 1000);
                    }

                },
                // onSelectionChanged: this.onMultipleRowsSelectionChanged,
                onSelectionChanged: (event: any) => {
                    this.onMultipleRowsSelectionChanged(event)
                },
                onModelUpdated: (event: any) => {

                    if (this.gridOptions.api != undefined && this.ViewModel && this.gridData.length == 0
                        && (this._router.url.indexOf("/record/") != -1 || this._router.url.indexOf("/transition/") != -1)) {
                        this.columnDefs = [];
                    }

                    this.gridOptions.api = event.api;
                    this.gridOptions.columnApi = event.columnApi;
                    this.api = event.api;
                    _gridOptions.api = event.api;

                    this.totalCount = this.gridOptions.api.getDisplayedRowCount();
                    // if (event.newData) {
                    if (this.gridOptions.api) {
                        this.gridOptions.api.deselectAll();
                        this.selectFirstRow();
                        // this.totalCount = this.gridOptions.api.getDisplayedRowCount();
                    }
                    // }
                },
                // onRowDataChanged: this.hideIfNextEmpty,
                // onBodyScroll: this.hideIfNextEmpty,
                //onCellEditingStopped: this.updateDFPStatus,
                onCellValueChanged: this.updateDFPStatus.bind(this),
                // onGridReady: () => {
                //    this.setGridColumns();
                //    //this.gridData = [];
                // },
                // suppressContextMenu: true,
                groupSelectsChildren: false,
                rowGroupPanelShow: 'always',
                isRowMaster: function (dataItem) {
                    let isMaster = dataItem ? dataItem.LatestRecord == 2 : false;
                    dataItem.LatestRecord = '';
                    _gridOptions.api.refreshCells({ columns: ['LatestRecord'] });
                    return isMaster;
                },
                context: {
                    mainGridCtx: this
                },
                getContextMenuItems: this.getCustomContextMenuItems,
                defaultColDef: {
                    resizable: true,
                },
                components: { agDateInput: CustomDateComponent },
                navigateToNextCell: this.navigateToNextCell,
                // showToolPanel: true,
                // pivotPanelShow: 'onlyWhenPivoting'
            };

        this.chartGridOptions = <GridOptions>
            {
                onGridReady: () => {
                },
                context: {
                    gridContextChart: this
                },
                onModelUpdated: (event: any) => {

                    if (this.chartGridOptions.api != undefined && this.ViewModel && this.gridData.length == 0
                        && (this._router.url.indexOf("/record/") != -1 || this._router.url.indexOf("/transition/") != -1)) {
                        this.chartColumnDefs = [];
                    }
                    this.chartGridOptions.api = event.api;
                    this.chartGridOptions.columnApi = event.columnApi;
                },
            }
        _gridOptions = this.gridOptions;
        if (this.gridOptions.api)
            this.api = this.gridOptions.api;
        if (this.api)
            _gridOptions.api = this.api;

    }


    ngOnDestroy() {
        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                pairwise()
            )
            .subscribe((event: [NavigationEnd, NavigationEnd]) => {
                if (event[0].url.indexOf('status') == 1) {
                    this._sharedService.onAssetChanged(this._sharedService.selectedAssetTreeNode);
                }
            });
        if (this.paramsSub) {
            this.paramsSub.unsubscribe();
        }

        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        if (this.assetStatusSub) {
            this.assetStatusSub.unsubscribe();
        }

        if (this.subscriptionMessage) {
            this.subscriptionMessage.unsubscribe();
        }
        if (this.dialoguesubscription) {
            this.dialoguesubscription.unsubscribe();
        }

        if (this.routeSub) {
            this.routeSub.unsubscribe();
        }
        if (this.refreshSubsription) {
            this.refreshSubsription.unsubscribe();
        }

        if (this.ChannelSubscription) {
            this.ChannelSubscription.unsubscribe();
        }
        if (this.populateTaskSubscription) {
            this.populateTaskSubscription.unsubscribe();
        }
        this.appHotkeys.forEach(hotkey => {
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];

        this.disposeCanvas();

        if (this.currentImg) {
            this.currentImg = null;
        }
        // due to this bug commented below line Bug 10339: Records>Contianer>Document>Search>AND/OR with 0 result shows all documents
        // this._sharedService.onAssetChanged(this._sharedService.selectedAssetTreeNode);

    }
    navigateToNextCell(params) {

        var previousCell = params.previousCellPosition;
        // var previousCell = params.previousCellDef;
        // var suggestedNextCell = params.nextCellDef;
        var suggestedNextCell = params.nextCellPosition;
        var currentSelectedNode = null;

        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        switch (params.key) {
            case KEY_DOWN:
                previousCell = params.previousCellPosition;
                _gridOptions.api.deselectAll()
                // set selected cell on current cell + 1
                _gridOptions.api.forEachNode((node) => {
                    if (previousCell.rowIndex + 1 === node.rowIndex) {
                        node.setSelected(true);
                        if (_viewList.gridOptions.api.getSelectedNodes().length > 0) {
                            _viewList.populateTaskDocument(_viewList.gridOptions.api.getSelectedNodes()[0].data);
                        }
                    }
                    if (previousCell.rowIndex === node.rowIndex) {
                        currentSelectedNode = node;
                    }
                });
                // return suggestedNextCell;
                if (suggestedNextCell != null) {
                    return suggestedNextCell;
                }
                else {
                    currentSelectedNode.setSelected(true);
                    return currentSelectedNode
                }
            case KEY_UP:
                previousCell = params.previousCellPosition;
                _gridOptions.api.deselectAll()
                // set selected cell on current cell - 1
                _gridOptions.api.forEachNode((node) => {
                    if (previousCell.rowIndex - 1 === node.rowIndex) {
                        node.setSelected(true);
                        if (_viewList.gridOptions.api.getSelectedNodes().length > 0) {
                            _viewList.populateTaskDocument(_viewList.gridOptions.api.getSelectedNodes()[0].data);
                        }
                    }
                    if (previousCell.rowIndex === node.rowIndex) {
                        currentSelectedNode = node;
                    }
                });
                // return suggestedNextCell;
                if (suggestedNextCell != null) {
                    return suggestedNextCell;
                }
                else {
                    currentSelectedNode.setSelected(true);
                    return currentSelectedNode
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw "this will never happen, navigation is always one of the 4 keys above";
        }
    }

    private joinHubChannel(): void {

        try {

            // Get an observable for events emitted on this channel
            //
            //

            this.ChannelSubscription = this.channelService.sub(this._sharedService.getGroupNameKey(this.channel))
                .subscribe({
                    next: (x: ChannelEvent) => {
                        switch (x.ChannelName) {
                            case this._sharedService.getGroupNameKey(this.channel): { this.appendStatusUpdate(x); }
                        }
                    },
                    error: (error) => {
                        console.warn("Attempt to join channel failed!", error);
                    }
                });
        }
        catch (ex) {
            console.log(ex.error)
        }
    }

    private appendStatusUpdate(ev: ChannelEvent): void {
        // Just prepend this to the messages string shown in the textarea
        //
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
            return;
        }

        let date = new Date();
        this._sharedService.setViewTaskProgress(ev.Data);
        switch (ev.Data.State) {
            case "Starting":
            case "starting": {
                // console.log(`${date.toLocaleTimeString()} : starting\n`);
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: ev.Data.Message });
                break;
            }
            case "complete": {
                //  console.log(`${date.toLocaleTimeString()} : complete\n`);
                break;
            }
            case "Complete": {

                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: ev.Data.Message });
                if (this.ViewSettingId == ev.Data.ViewID.toString()) {

                    if (this.isInternalView != true) {
                        try {
                            if (ev.Data.Task) {
                                this.gridOptions.api.applyTransaction(ev.Data as RowDataTransaction);
                            }
                        }
                        catch (e) {

                        }
                    }
                    else {
                        this.confirStatusViewGridRefresh();

                    }

                }

                break;

            }
            case "InProgress": {


                if (this.isInternalView != true) {
                    try {
                        if (ev.Data.Task) {

                            let nodeID = ev.Data.Task.TaskID;
                            let rowNode = this.gridOptions.api.getRowNode(nodeID);
                            // if(!ev.Data.hasOwnProperty("TaskDocuments"))
                            // {
                            //     this.columnDefs.push({colId: "TaskDocuments", field:"TaskDocuments", hide: true,  rowGroupIndex: null, width: 90,  pinned: 'right'});
                            //     this.gridOptions.api.setColumnDefs(this.columnDefs);

                            // }
                            rowNode.data.DFPStatus = ev.Data.Task.DFPStatus;
                            rowNode.data.TaskDocuments = ev.Data.Task.TaskDocuments;
                            rowNode.setData(rowNode.data);
                            // this.gridOptions.api.updateRowData(ev.Data.Task as RowDataTransaction);
                        }
                    }
                    catch (e) {
                        //console.log(e);
                    }
                }

                break;

            }
            case "Error":
            case "error": {
                console.log(`${date.toLocaleTimeString()} : complete\n`);
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Success', detail: ev.Data.Message });
                break;
            }
            default: {
                // console.log(`${date.toLocaleTimeString()} : ${ev.Data.Message} : ${ev.Data} % complete\n`);
            }
        }
    }



    confirStatusViewGridRefresh() {

        this.confirmationService.confirm({
            key: "confirmDlg",
            message: "Data has been changed, do you want to refresh screen to update data?",
            accept: () => {
                this.gridData = [];
                setTimeout(() => {
                    this.populateGrid();
                }, 500);
            },
            reject: () => {
                return;
            }
        });

    }



    closeDialogueModel(status: boolean) {
        this.displayissuedialogue = false;
    }
    //#endregion

    //#region View List

    onPanalsClick(event: any) {
        this._sharedService.onGridRowClickStatus(true);
    }

    compareFirstNames(a, b) {
        if (a.first_name < b.first_name) {
            return -1;
        }
        if (a.first_name > b.first_name) {
            return 1;
        }
        return 0;
    }

    onChartCreated(event) {
        setTimeout(() => {
            var tagId: any = document.getElementsByClassName('ag-panel ag-default-panel ag-dialog ag-ltr ag-popup-child')[0];
            tagId.style.left = '1vw';
            tagId.style.width = '96vw';
            tagId.style.maxWidth = '100vw';
            tagId.style.height = '86vh';
            tagId.style.top = '10vh';
            tagId.style.maxHeight = '88vh';
        }, 500);
    }
    dateArray = ["Date1", "Date2", "Date3", "Date4", "Date5", "Date6", "Date7", "Date8", "Date9", "Date10"]
    generateStatusChart(selectedChart) {
        if (this.gridData && this.gridData.length > 0) {
            if (this.chartConfiguration) {
                this.chartGridData = []
                let rowStartIndex = 0;
                this._sharedService.showLoader("Processing...");
                let rowEndIndex = this.gridData.length;
                var columns = this.chartColumnDefs.filter(x => x.hide == false).map(ele => ele.field);
                if (this.gridOptions.api.getCellRanges().length > 0) {
                    rowStartIndex = this.gridOptions.api.getCellRanges()[0].startRow.rowIndex;
                    rowEndIndex = this.gridOptions.api.getCellRanges()[0].endRow.rowIndex;
                    columns = [];
                    this.gridOptions.api.getCellRanges()[0].columns.forEach(x => {
                        columns.push(x.getColDef().field);
                    });
                }
                this.chartColumnDefs = cloneDeep(this.columnDefs);
                this.chartGridData = cloneDeep(this.gridData);
                var selectedChartData = this.chartConfiguration.find(x => x.chartType == selectedChart)
                // var chartConfig = this.chartConfiguration.chartConfigurationList.find(x => x.FieldName == this.chartColumnDefs[i].field);
                var filterTypeEntity = selectedChartData.chartConfigurationList.find(x => x.FilterType != undefined);
                // var dateSortColumns= selectedChartData.chartConfigurationList.find(x => x.FieldName.includes('Date'));
                var sortByColumns = selectedChartData.chartConfigurationList[1].FieldName;
                if (sortByColumns) {
                    var sortBy = sortByColumns
                    this.chartGridData.sort((a, b) => {
                        if (!a[sortBy]) {
                            // Change this values if you want to put `null` values at the end of the array
                            return -1;
                        }

                        if (!b[sortBy]) {
                            // Change this values if you want to put `null` values at the end of the array
                            return +1;
                        }
                        return b[sortBy].localeCompare(a[sortBy])
                    });
                    var typeObj =
                        [
                            { key: "year", value: 4 },
                            { key: "month", value: 7 },
                            { key: "day", value: 10 },
                        ]

                    if (filterTypeEntity && (filterTypeEntity.FilterType.toString().toLowerCase() == 'year' || filterTypeEntity.FilterType.toString().toLowerCase() == 'month' || filterTypeEntity.FilterType.toString().toLowerCase() == 'day')) {
                        let arrLength = typeObj.find(x => x.key == filterTypeEntity.FilterType.toString().toLowerCase()).value
                        this.chartGridData.forEach(function (part, index) {
                            this.dateArray.forEach(function (part, childIndex) {
                                let property = this.dateArray[childIndex];
                                if (this.chartGridData[index][property] && this.chartGridData[index][property].length > arrLength)
                                    this.chartGridData[index][property] = this.chartGridData[index][property].substring(0, arrLength);
                            }.bind(this));
                        }.bind(this));
                    }
                }
                for (let i = 0; i < this.chartColumnDefs.length; i++) {
                    var selectedChartData = this.chartConfiguration.find(x => x.chartType == selectedChart)
                    // var chartConfig = this.chartConfiguration.chartConfigurationList.find(x => x.FieldName == this.chartColumnDefs[i].field);
                    var chartConfig = selectedChartData.chartConfigurationList.find(x => x.FieldName == this.chartColumnDefs[i].field);


                    // var chartConfig = this.chartConfiguration.chartConfigurationList.find(x => x.FieldName == this.chartColumnDefs[i].field);

                    if (chartConfig && chartConfig.aggFunc) {
                        (chartConfig.aggFunc) ? this.chartColumnDefs[i].aggFunc = chartConfig.aggFunc : this.chartColumnDefs[i].aggFunc = null
                    }
                    if (chartConfig && chartConfig.rowGroup) {
                        (chartConfig.rowGroup) ? this.chartColumnDefs[i].rowGroup = chartConfig.rowGroup : this.chartColumnDefs[i].rowGroup = null
                    }
                    if (chartConfig && chartConfig.pivot) {
                        (chartConfig.pivot) ? this.chartColumnDefs[i].pivot = chartConfig.pivot : this.chartColumnDefs[i].pivot = null
                    }
                    if (chartConfig && chartConfig.chartDataType) {
                        (chartConfig.chartDataType) ? this.chartColumnDefs[i].chartDataType = chartConfig.chartDataType : this.chartColumnDefs[i].chartDataType = 'Excluded'
                    }
                    if (chartConfig && chartConfig.rowGroupIndex) {
                        (chartConfig.rowGroupIndex) ? this.chartColumnDefs[i].rowGroupIndex = chartConfig.rowGroupIndex : this.chartColumnDefs[i].rowGroupIndex = 0
                    }
                }
                var params = selectedChartData.chartParams;
                // var params = this.chartConfiguration.chartParams;// JSON.parse(this.selectedViewData.View.ChartConfiguration)
                params.cellRange["rowStartIndex"] = rowStartIndex;
                params.cellRange["rowEndIndex"] = rowEndIndex;
                params.cellRange["columns"] = columns;

                setTimeout(() => {
                    this.chartGridOptions.api.createPivotChart(params);
                    this._sharedService.hideLoader();
                }, 2000);
            }
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Record', detail: 'No record exists.' });
        }
    }
    setGridColumns(): void {
        this.columnDefs = [];
        // Adding selection check box in Status popup
        this.addSelectionColumn();
        // Add Group Column
        this.addGroupColumn();
        if (this.ViewModel && this.ViewModel.Columns.length > 0) {
            for (var i = 0; i < this.ViewModel.Columns.length; i++) {
                let column: Column = this.ViewModel.Columns[i];
                column.colId = this.ViewModel.Columns[i].FieldName;
                if (!column.Processed) {
                    if (column.GroupLabel) {
                        isGroupColumnHeaders = true;
                        //#region Prepare Grouped Column Def
                        let groupColumn = this.ViewModel.Columns.filter(c => c.GroupLabel == column.GroupLabel);

                        let gridcol: any = {
                            headerName: groupColumn[0].GroupLabel,
                            children: []
                        }
                        for (let j = 0; j < groupColumn.length; j++) {

                            let childColumn = this.getGridColumn(groupColumn[j]);

                            // Add Column in Group Col Childrens
                            gridcol.children.push(childColumn);
                            //Update column As processed in Columns List
                            this.ViewModel.Columns[this.ViewModel.Columns.indexOf(groupColumn[j])].Processed = true;
                        }

                        // Add Group Column in Col Array
                        this.columnDefs.push(gridcol);
                    }

                    //#endregion

                    else {

                        //#region Prepare Simple Column Def

                        let gridCol = this.getGridColumn(column);
                        // var chartConfig = this.chartConfiguration.chartConfigurationList.find(x => x.FieldName == gridCol.field);
                        // // Add Column in Col Array
                        // // (chartConfig) ? gridCol.chartDataType = chartConfig.ChartDataType : gridCol.chartDataType = 'excluded';

                        // // if (chartConfig && chartConfig.aggFunc) {
                        // //     (chartConfig.aggFunc) ? gridCol.aggFunc = chartConfig.aggFunc : gridCol.aggFunc = null
                        // // }
                        // // if(chartConfig && chartConfig.aggFunc)
                        // //     {
                        // //         // (chartConfig.aggFunc)?childColumn.aggFunc=chartConfig.aggFunc:childColumn.aggFunc=null
                        // //         gridCol.aggFunc='count'
                        // //     }
                        // // if (chartConfig && chartConfig.rowGroup) {
                        // //     (chartConfig.rowGroup) ? gridCol.rowGroup = chartConfig.rowGroup : gridCol.rowGroup = null
                        // // }
                        // // if (chartConfig && chartConfig.pivot) {
                        // //     (chartConfig.pivot) ? gridCol.pivot = chartConfig.pivot : gridCol.pivot = null
                        // // }

                        this.columnDefs.push(gridCol);

                        //#endregion
                    }
                }

            }
            this.columnDefs[0].chartDataType = 'excluded'
            this.columnDefs[1].chartDataType = 'excluded'
            try {
                if (this.api) {
                    this.api.resetRowHeights();
                }
            }
            catch (ex) {
                console.log(ex);
            }
        }
        this.chartColumnDefs = cloneDeep(this.columnDefs);
        // populate Detail Grid Columns
        this.setDetailGridColumns();//R.A. 31-12-2020
    }
    setHotKeys() {
        if (this._router.url.indexOf("/status/viewlist") != -1 || this._router.url.indexOf("/repair/viewlist") != -1) {
            let hotKeyObj: Hotkey = null;

            // Export CSV
            hotKeyObj = new Hotkey('ctrl+x', (event: KeyboardEvent): boolean => {
                this.exportGridData();
                return false; // Prevent bubbling
            });
            this._hotkeysService.add(hotKeyObj);
            this.appHotkeys.push(hotKeyObj);

            // Export Excel
            hotKeyObj = new Hotkey('ctrl+z', (event: KeyboardEvent): boolean => {
                this.exportExcelGrid();
                return false; // Prevent bubbling
            });
            this._hotkeysService.add(hotKeyObj);
            this.appHotkeys.push(hotKeyObj);

            // Delete Documents
            if (this.canDeleteTask) {
                hotKeyObj = new Hotkey('ctrl+d', (event: KeyboardEvent): boolean => {
                    this.showDeleteTask();
                    return false; // Prevent bubbling
                });

                this._hotkeysService.add(hotKeyObj);
                this.appHotkeys.push(hotKeyObj);
            }

            // Add Task
            if (this.canAddTask) {
                hotKeyObj = new Hotkey('ctrl+a', (event: KeyboardEvent): boolean => {
                    this._sharedService.UpdateTaskAdd(true);
                    return false; // Prevent bubbling
                });
                this._hotkeysService.add(hotKeyObj);
                this.appHotkeys.push(hotKeyObj);
            }

            // Edit Task
            if (this.canEditTask) {
                hotKeyObj = new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
                    //this._sharedService.UpdateTaskAdd(true);
                    this._sharedService.UpdateTaskEdit(true);
                    return false; // Prevent bubbling
                });
                this._hotkeysService.add(hotKeyObj);
                this.appHotkeys.push(hotKeyObj);
                hotKeyObj = new Hotkey('enter', (event: KeyboardEvent): boolean => {
                    //this._sharedService.UpdateTaskAdd(true);
                    this._sharedService.UpdateTaskEdit(true);
                    return false; // Prevent bubbling
                });
                this._hotkeysService.add(hotKeyObj);
                this.appHotkeys.push(hotKeyObj);
            }

            // Deatach Task
            if (this.canDetachDocumentStatus) {
                hotKeyObj = new Hotkey('ctrl+shift+d', (event: KeyboardEvent): boolean => {
                    this.btnDetachDocument();
                    return false; // Prevent bubbling
                });
                this._hotkeysService.add(hotKeyObj);
                this.appHotkeys.push(hotKeyObj);
            }

            // attach Task
            if (this.canLinkDocumentStatus) {
                hotKeyObj = new Hotkey('ctrl+shift+a', (event: KeyboardEvent): boolean => {
                    if (this._sharedService.selectedModule.toString().toLowerCase() == "status") {
                        this.btnAttachRecord();
                    }
                    return false; // Prevent bubbling
                });
                this._hotkeysService.add(hotKeyObj);
                this.appHotkeys.push(hotKeyObj);
            }

            // show History
            hotKeyObj = new Hotkey('ctrl+shift+h', (event: KeyboardEvent): boolean => {
                this.showTaskHistory();
                return false; // Prevent bubbling
            });
            this._hotkeysService.add(hotKeyObj);
            this.appHotkeys.push(hotKeyObj);

        }
    }
    setDetailGridColumns() {
        detailGridColumnDefs = [];
        let childGridCol: any = {// for run now search check box added for selection
            headerName: '',//'rTask',//'',//rTask 
            field: 'rTask',
            colId: 'rTask',
            width: 22,
            checkboxSelection: true,
            //headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            // suppressFilter: true,
            sortable: false,
            menuTabs: [],
        };
        // Add Column in Col Array
        detailGridColumnDefs.push(childGridCol);

        for (var i = 2; i < this.columnDefs.length; i++) { // startig inserting parent grid columns from index 2 ignore 1st two columns element
            detailGridColumnDefs.push(this.columnDefs[i]);
        }
    }

    setTaskGridColumns() {
        this.taskColumnDefs = [];
        var taskGrid = document.getElementById('taskGrid');
        if (isGroupColumnHeaders) {
            taskGrid.style.height = '115px';
        }
        else {
            taskGrid.style.height = '90px';
        }

        for (var i = 1; i < this.columnDefs.length; i++) { // startig from index 1 ignore 1st column element
            if (this.columnDefs[i].field == 'DFPStatus') { this.columnDefs[i].editable = false; }
            this.taskColumnDefs.push(this.columnDefs[i]);
        }
    }
    addSelectionColumn() {
        this.columnDefs = [];
        if (this._router.url.indexOf("record/browse") != -1) {
            let gridCol: any = {
                headerName: ' ',
                field: '',
                width: 22,
                checkboxSelection: true,
                headerCheckboxSelection: true,
                filter: false,
                sortable: false,
                menuTabs: []
            };
            // Add Column in Col Array
            this.columnDefs.push(gridCol);
        }
        else {
            let gridCol: any = {
                colId: "checkboxcol",
                headerName: '',//'rTask',//'',//rTask
                field: 'rTask',
                width: 22,
                checkboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                headerCheckboxSelection: true,
                // suppressFilter: true,
                sortable: false,
                menuTabs: []
            };

            // Add Column in Col Array
            this.columnDefs.push(gridCol);
        }
    }

    addGroupColumn() {
        // if (this._router.url.indexOf("record/browse") == -1) {
        //    if (this.columnDefs.length > 0) {
        //        this.columnDefs[0].cellRenderer = 'agGroupCellRenderer';
        //        this.columnDefs[0].cellRendererParams = { suppressCount: true };
        //    }
        //}
        if (this._router.url.indexOf("record/browse") == -1 && (!this.isFromAPIModule || this.isFromAPIModule != 'API') && this.canViewHistory) {
            let gridCol: any = {
                headerName: ' ',
                // field: 'TaskID',
                field: 'LatestRecord',
                //colId: "checkboxcol_LatestRecord",
                width: 20,
                filter: false,
                sortable: false,
                menuTabs: [],
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: { suppressCount: true }

            };
            try {
                if (this.ViewModel && this.ViewModel.TextWrap) {
                    gridCol.autoHeight = true;
                    gridCol.cellClass = "cell-wrap-text";
                }
            }
            catch (ex) {
                console.log(ex);
            }
            // Add Column in Col Array
            this.columnDefs.push(gridCol);
        }
    }

    getGridColumn(col: Column): any {
        // Check Group By
        let isGroupCol: boolean = false;
        let groupCol: string = this.ViewModel.GroupBy.find(c => c == col.FieldName);
        groupCol ? isGroupCol = true : isGroupCol = false;
        // Set Column Label
        let colLabel: string = col.FieldLabel ? col.FieldLabel : col.FieldName;
        // Set Column Width
        let colWidth: string = col.ColumnWidth ? col.ColumnWidth : '90';

        let dateCol = ['Date1', 'Date2', 'Date3', 'Date4', 'Date5', 'Date6', 'Date7', 'Date8', 'Date9', 'Date10'];
        let dateTimeCol = ['DateTime1', 'DateTime2', 'DateTime3', 'DateTime4', 'DateTime5', 'CreatedOn', 'ModifiedOn']
        let boolCol = ['Bit1', 'Bit2', 'Bit3', 'Bit4', 'Bit5', 'Bit6', 'Bit7', 'Bit8', 'Bit9', 'Bit10', 'Status'];

        let column: any;
        // Prepare Date Type Column Def
        if (dateCol.find(c => c == col.FieldName)) {

            column = {
                headerName: colLabel,
                field: col.FieldName,
                //R.A//colId: col.FieldName,
                width: Number(col.ColumnWidth),
                filter: 'agDateColumnFilter',
                sortable: true,
                menuTabs: ['filterMenuTab'],
                rowGroup: isGroupCol,
                enableRowGroup: true,
                filterParams:
                {
                    comparator: this.dateComparater,
                    inRangeInclusive: 'true'
                },
                valueFormatter: this.dateValueFormatter,
                hide: col.ColumnWidth == "'0'" ? true : false,
            };

        }
        else if (dateTimeCol.find(c => c == col.FieldName)) {
            column = {
                headerName: colLabel,
                field: col.FieldName,
                //R.A//colId: col.FieldName,
                width: Number(col.ColumnWidth),
                filter: 'agDateColumnFilter',
                sortable: true,
                menuTabs: ['filterMenuTab'],
                rowGroup: isGroupCol,
                enableRowGroup: true,
                filterParams:
                {
                    comparator: this.dateComparater,
                    inRangeInclusive: 'true'
                },
                valueFormatter: this.dateTimeValueFormater,
                hide: col.ColumnWidth == "'0'" ? true : false,
            };

        }
        else if (boolCol.find(c => c == col.FieldName)) {
            // Prepare Boolen Type Column Def
            column = {
                headerName: colLabel,
                field: col.FieldName,
                //R.A//colId: col.FieldName,
                width: Number(col.ColumnWidth),
                filter: 'set',
                sortable: true,
                menuTabs: ['filterMenuTab'],
                rowGroup: isGroupCol,
                enableRowGroup: true,
                tooltipField: col.FieldName,
                valueFormatter: this.boolCellFormater,
                hide: col.ColumnWidth == "'0'" ? true : false,
            };
        }
        else if (col.FieldName == 'DFPStatus') {
            // Prepare DFP Status Column Def
            column = {
                headerName: colLabel,
                field: col.FieldName,
                //R.A//colId: col.FieldName,
                width: Number(col.ColumnWidth),
                filter: 'set',
                sortable: true,
                menuTabs: ['filterMenuTab'],
                rowGroup: isGroupCol,
                enableRowGroup: true,
                tooltipField: col.FieldName,
                cellRenderer: this.statusDFPCellRenderer,
                cellStyle: this.dfpCellStyle,
                editable: this.canUpdateDFPStatus,
                cellEditor: 'agRichSelectCellEditor',
                cellEditorParams: {
                    values: this.filteredDfpStatusList.map(sts => sts.label.trim()),
                    cellRenderer: this.statusDFPCellEditorRenderer
                },
                hide: col.ColumnWidth == "'0'" ? true : false,
            }
        }
        else if (col.FieldName == 'Comms') {
            // Prepare Issues Column Def
            column = {
                headerName: colLabel,
                field: col.FieldName,
                //R.A//colId: col.FieldName,
                width: Number(col.ColumnWidth),
                filter: 'set',
                sortable: true,
                menuTabs: ['filterMenuTab'],
                rowGroup: isGroupCol,
                enableRowGroup: true,
                cellRenderer: this.issueCellRenderer,
                hide: col.ColumnWidth == "'0'" ? true : false
            };

        }
        else if (col.FieldName == 'CommSummary') {
            // Prepare Issues Column Def
            column = {
                headerName: colLabel,
                field: col.FieldName,
                width: Number(col.ColumnWidth),
                //R.A//colId: col.FieldName,
                filter: 'set',
                sortable: true,
                menuTabs: ['filterMenuTab'],
                rowGroup: isGroupCol,
                enableRowGroup: true,
                wrapText: true,
                cellStyle: { 'background-color': '#FFFFFF', 'border': '1px solid #c8c8c8' },
                //cellRenderer: this.commSummaryCellRenderer,
                cellRenderer: CardViewListComponent,

                //cellRendererParams: { AssignedToList: this.assignedToList, RequestedByList: this.requestedByList, commStatusList: this.commStatusList },
                hide: col.ColumnWidth == "'0'" ? true : false
            };

        }
        else if (col.FieldName == 'TaskType') {
            column = {
                headerName: (colLabel == 'TaskType') ? 'DataType' : colLabel,
                field: col.FieldName,
                //R.A//colId: col.FieldName,
                tooltipField: col.FieldName,
                width: Number(col.ColumnWidth),
                filter: 'set',
                sortable: true,
                menuTabs: ['filterMenuTab'],
                rowGroup: isGroupCol,
                enableRowGroup: true,
                hide: col.ColumnWidth == "'0'" ? true : false,
                valueFormatter: function (params: any) {
                    return (!params.value || params.value === null) ? "" : params.value.toString().replace(/\r?\n|\r/g, ' ');
                },
            };
        }
        else if (col.FieldName == 'TaskID') {
            column = {
                headerName: (colLabel == 'TaskID') ? 'ID' : colLabel,
                field: col.FieldName,
                //R.A//colId: col.FieldName,
                tooltipField: col.FieldName,
                width: Number(col.ColumnWidth),
                filter: 'set',
                sortable: true,
                menuTabs: ['filterMenuTab'],
                rowGroup: isGroupCol,
                enableRowGroup: true,
                hide: col.ColumnWidth == "'0'" ? true : false,
                valueFormatter: function (params: any) {
                    return (!params.value || params.value === null) ? "" : params.value.toString().replace(/\r?\n|\r/g, ' ');
                },
            };

        }
        else {
            column = {
                headerName: colLabel,
                field: col.FieldName,
                //R.A//colId: col.FieldName,
                tooltipField: col.FieldName,
                width: Number(col.ColumnWidth),
                filter: 'set',
                sortable: true,
                menuTabs: ['filterMenuTab'],
                rowGroup: isGroupCol,
                enableRowGroup: true,
                hide: col.ColumnWidth == "'0'" ? true : false,
                valueFormatter: function (params: any) {
                    return (!params.value || params.value === null) ? "" : params.value.toString().replace(/\r?\n|\r/g, ' ');
                },// ,
            };
        }
        try {
            if (this.ViewModel.TextWrap) {
                column.autoHeight = true;
                column.cellClass = "cell-wrap-text";
            };
        }
        catch (ex) {
            console.log(ex);
        }
        return column;
    }

    getUniqueColGroups(groupColumns: Column[]): any {
        let groups = groupColumns.map((c) => {
            return c.GroupLabel;
        });

        let uniqueGroups = groups.filter((item, pos) => { return groups.indexOf(item) == pos; });
        return uniqueGroups;
    }

    updateDFPStatus(event: any) {
        this.showHideCommDialogue(false);
        if (event.colDef.field == 'DFPStatus') {
            if (event.oldValue != event.newValue) {
                // Update Status
                if (event.data) {
                    taskService.updateDFPStatus(event.data)
                        .subscribe({
                            next: (response) => {
                                if (response) {
                                    sharedService.clearToast();
                                    sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Status updated succesfully.' });
                                    this.isAddCommItem(event.data);
                                }
                            },
                            error: (error) => {
                                sharedService.clearToast();
                                sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Cannot Update Status, please try again.' });
                            }
                        });
                }
            }
        }
    }

    refreshTaskDocuments() {
        if (IsDetailRowClicked) {
            let selRow = detailGridApi.getSelectedRows();
            this.populateTaskDocument(selRow[0]);
        }
        else {
            let selRow = this.api.getSelectedRows();
            this.populateTaskDocument(selRow[0]);
        }

    }

    //#region Issue Cell Clicked
    issueCellClicked(params: any) {
        //console.log("");
    }
    //#endregion

    //#region custom cell Render/Formaters/Comparater

    statusDFPCellRenderer(params: any) {
        let html: string = '';

        if (params.value) {

            let dfpStatusName: string = params.value.toString().trim();
            let currentStatus: DFPStatusModel = dfpStatusesTask.filter(x => x.Name == dfpStatusName)[0];
            if (currentStatus && currentStatus.Name) {
                if (currentStatus.Name == 'Suggested - no document found' || currentStatus.Name == 'Suggested - partial documents found' || currentStatus.Name == 'Suggested - documents found' || currentStatus.Name == 'Suggested - not applicable') {
                    html = html + '<span style="padding: 3px;color:' + currentStatus.CSSClass + ';">' + params.value.trim() + '</span>';
                }
                else {
                    html = html + '<span style="padding: 5px;color: white;background-color:' + currentStatus.CSSClass + ';">' + params.value.toString().trim() + '</span>';

                }
            } else {
                html = html + '<span style="padding: 5px;color: white;background-color:#333;">' + params.value.toString().trim() + '</span>';
            }
        }

        var eDiv = document.createElement('div');
        eDiv.innerHTML = html;
        eDiv.style.textAlign = 'center';
        eDiv.className = 'ag-grid-cell-custom';
        return eDiv;
    }

    dfpCellStyle(params: any) {
        if (params && params.value) {

            let dfpStatusName: string = params.value.toString().trim();
            let currentStatus: DFPStatusModel = dfpStatusesTask.filter(x => x.Name == dfpStatusName)[0];
            if (currentStatus && currentStatus.Name) {
                if (currentStatus.Name == 'Suggested - no document found' || currentStatus.Name == 'Suggested - partial documents found' || currentStatus.Name == 'Suggested - documents found' || currentStatus.Name == 'Suggested - not applicable') {
                    return;
                }
                else {
                    return { backgroundColor: currentStatus.CSSClass };

                }
            } else {
                return { backgroundColor: '#333' };
            }
        }
    }

    statusDFPCellEditorRenderer(params: any) {
        let html: string = '';
        if (params.value) {
            let dfpStatusName: string = params.value.trim();
            let currentStatus: DFPStatusModel = dfpStatusesTask.filter(x => x.Name == dfpStatusName)[0];
            if (currentStatus) {
                //html = html + '<img border="0" width="15" height="12" style="margin-right: 5px;margin-bottom: 4px;background-color:' + currentStatus.CSSClass + '">' + params.value.trim();
                html = html + params.value.trim();

                var eDiv = document.createElement('div');
                eDiv.style.background = currentStatus.CSSClass;
                eDiv.style.height = '100%';
                eDiv.style.width = '100%';
                eDiv.style.color = 'white';
                eDiv.style.textAlign = 'center';
                eDiv.style.paddingTop = '6px';
                eDiv.innerHTML = html;
                return eDiv;
            }
        }
    }

    issueCellRenderer(params: any) {
        let html: string = '';
        if (params.value == '1') {
            html = html + '<span class="fa fa-comment" style="color:red;"></span>'; /*(click) = onIssueCellClicked('+params+')*/

        }
        else if (params.value == '2') {
            html = html + '<span class="fa fa-comment" style="color:green;"></span>';

        }
        else {
            html = html + '<span class="fa fa-comment-o"></span>';
        }

        var eDiv = document.createElement('div');
        eDiv.innerHTML = html;
        eDiv.style.textAlign = 'center';
        return eDiv;
    }


    commSummaryCellRenderer(params: any) {

        let html: string = '';
        if (params.value) {

            let comObjs = JSON.parse(params.value);
            html = html + '<span class="fa fa-comment" style="color:red;"></span>'; /*(click) = onIssueCellClicked('+params+')*/

        }
        else if (params.value == '2') {
            html = html + '<span class="fa fa-comment" style="color:green;"></span>';

        }
        else {
            html = html + '<span class="fa fa-comment-o"></span>';
        }

        var eDiv = document.createElement('div');
        eDiv.innerHTML = html;
        eDiv.style.textAlign = 'center';
        return eDiv;
    }


    boolCellFormater(params: any) {
        if (params.value == true) {
            return "True";
        }
        else {
            return "False";
        }
    }

    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).split(dateTimeConfig.dateSeparator);
            var day = Number(dateParts[Number(dateTimeConfig.day)].substring(0, 2));
            var month = Number(dateParts[Number(dateTimeConfig.month)]) - 1;
            var year = Number(dateParts[Number(dateTimeConfig.year)]);

            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        }
        else {
            return -1;
        }
    }
    getDateFormatTime(date: any) {
        if (date) {
            //return moment.utc(date).format("DD-MM-YYYY h:mm a").toString();
            return moment(date).format(dateTimeConfig.dateformat.toUpperCase() + " h:mm a").toString();

        } else {
            return '';
        }

    }
    getFormatedDate(date: any) {
        if (date) {
            return moment(date).format(dateTimeConfig.dateformat.toUpperCase());
        } else {
            return '';
        }
    }

    dateValueFormatter(params: any) {
        if (params.value != "" && params.value != null) {

            let dateString = params.value.substring(0, 10);
            return moment(dateString).format(dateTimeConfig.dateformat.toUpperCase());
        }
    }

    dateTimeValueFormater(params: any) {
        if (params.value != "" && params.value != null) {
            let dateString = params.value;
            let date = moment(dateString);
            return date.format(dateTimeConfig.dateformat.toUpperCase() + ' ' + dateTimeConfig.timeformat);
        }
    }

    //#endregion

    public selectedViewData;
    public chartConfiguration;
    public chartMenuList;
    populateGrid(fromView: string = null): void {
        this.selectedViewData = null;
        this.InitializeGridOptions();
        this.ViewModel = null;
        this.asn = "";
        if (this._sharedService.selectedAssetStatus && this._sharedService.selectedAssetStatus.length > 0) {
            //US-8181 //this.asn = this._sharedService.selectedAssetStatus[0].data.split('|')[1];
            let assetDataObj = JSON.parse(this._sharedService.selectedAssetStatus[0].data);
            this.asn = assetDataObj.ASN;

        }
        if (this.ViewSettingId || this.feedlogid) {
            this._sharedService.showLoader("Loading...");
            let showHistoryRecord = this._router.url.indexOf("record/browse") != -1 ? true : false;
            let viewFilter: ViewFilter = {
                ViewSettingId: this.ViewSettingId,
                ASN: this.asn,
                FeedLogId: this.feedlogid,
                ShowHistoryRecord: showHistoryRecord,
                InputParamList: []
            };

            let formData = this.ManualFilterFormGroup.getRawValue();
            for (let field in formData) {
                if (field.toString().indexOf('Date') != -1) {
                    viewFilter.InputParamList.push({ FieldName: field.replace('field_', ''), Value: this.getDate(formData[field]) });
                } else {
                    viewFilter.InputParamList.push({ FieldName: field.replace('field_', ''), Value: this.ManualFilterFormGroup.get(field).value });
                }
            }
            this._viewService.getViewDataById(viewFilter)
                .subscribe({
                    next: (response) => {
                        this.chartMenuList = [];
                        let result: any = response;
                        this.selectedViewData = response
                        this.chartConfiguration = null;
                        if (this.selectedViewData.View && this.selectedViewData.View.ChartConfiguration) {
                            this.chartConfiguration = JSON.parse(this.selectedViewData.View.ChartConfiguration);
                            if (this.chartConfiguration && this.chartConfiguration.length > 0) {
                                this.chartConfiguration.forEach(element => {
                                    let chartConfig = {
                                        label: element.chartType
                                    }
                                    this.chartMenuList.push(chartConfig)
                                });
                            }
                        }
                        // if(this.selectedViewData.View.ChartConfiguration)
                        // this.chartConfiguration = JSON.parse(this.selectedViewData.View.ChartConfiguration);
                        let view = response.View;
                        this.viewDataType = null;
                        if (view && view.DataType) {
                            this.viewDataType = view.DataType
                        }

                        this.gridData = [];
                        // this.columnDefs = [];

                        this.setHotKeys();
                        // For Input Params
                        // if (result.InputParamList && result.canvasDivInputParamList.length > 0) {
                        if (result.InputParamList && result.InputParamList.length > 0) {
                            this.prepareManualFilter(result.InputParamList);
                            this.gridData = [];
                            // this.columnDefs = [];
                            this._sharedService.hideLoader();
                            return;
                        }

                        //For Error
                        if (!result.Tasks && result.ErrorMsg) {
                            this.gridData = [];
                            this.columnDefs = []
                            this._sharedService.hideLoader();
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: result.ErrorMsg });
                            return;
                        }

                        // For Navigation to Task
                        if (this._sharedService.navigationInfo && this._sharedService.navigationInfo.isNavigate) {
                            let tasks: any[] = result.Tasks;
                            this.gridData = tasks.filter(t => t.TaskID == this._sharedService.navigationInfo.recordId);
                            this.isContainerFilterVisible = true;
                            // If No Record Found Fetch Parent From DB
                            if (this.gridData.length == 0) {
                                this.taskList = tasks;
                                this.GetParentTaskID(this._sharedService.navigationInfo.recordId);
                            }
                            let navUrl = (this.isInRepairModule ? "/repair/viewlist/" : "/status/viewlist/") + this._sharedService.navigationInfo.id;
                            if (this._router.url == navUrl) { this._sharedService.navigationInfo = null; }
                        } else {
                            this.isContainerFilterVisible = false;
                            this.gridData = result.Tasks;
                        }
                        this.ViewModel = result.View;
                        this.chartGridData = this.gridData;
                        this.filterDfpStatus();
                        //Update View Source
                        this.isInternalView = result.View.Source;
                        this._sharedService.isInternalView = result.View.Source;
                        //this.InitializeGridOptions();
                        this.setGridColumns();
                        this.populateViewHistoryKey();
                        this.checkLastUpdatedOn(result.Tasks);
                        this.taskFeedlogId = (this.gridData && this.gridData.length > 0) ? this.gridData[0].FeedLogId : 0;
                        try {
                            if (this.api) {
                                this.api.resetRowHeights();
                            }
                        }
                        catch (ex) {
                            console.log(ex);
                        }
                        this._sharedService.hideLoader();
                    },
                    error: (error) => {
                        this.gridData = [];
                        this.columnDefs = [];
                        // this.totalCount = 0;
                        this._sharedService.hideLoader();
                        this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: error.error });
                    }
                });
        }
        //this.updateColumns(null);
    }

    prepareManualFilter(inputParamList: any[]) {
        this.ManualFilterFormGroup = new UntypedFormGroup({});
        inputParamList.forEach(filter => {
            this.ManualFilterFormGroup.addControl("field_" + filter.FieldName, new UntypedFormControl('', Validators.required));
            this.ManualFilterList.push({ FieldName: filter.FieldName, FieldLabel: filter.FieldLabel ? filter.FieldLabel : filter.FieldName });
        });
    }

    filterDfpStatus() {
        if (this.ViewModel.selectedDfpStatus && this.ViewModel.selectedDfpStatus.length > 0) {
            this.filteredDfpStatusList = this.dfpStatusList.filter(s => this.ViewModel.selectedDfpStatus.includes(s.value));
        } else {
            this.filteredDfpStatusList = [];
        }
    }

    updateColumns($event: any) {
        try {
            if (this.gridOptions && this.gridOptions.columnApi) {
                let columns: any[] = this.gridOptions.columnApi.getAllDisplayedVirtualColumns();// .filter(c=>c.getUserProvidedColDef() == true);.map(s=>s.getId());
                if (columns && columns.length > 0) {
                    columns.forEach(col => {
                        if (!col.colDef.Group) {
                            //[col-id=0
                            let headerEl = document.querySelector(`[col-id=${col.colId}]`);
                            //let headerEl = document.querySelector(`[col-id=0]`);
                            if (headerEl) {
                                headerEl.classList.add('custom-header');

                            }
                        }

                    });
                }
            }

        } catch (ex) {
            console.log(ex);
        }
    }

    updateDetailGridColumns() {
        try {
            if (detailGridColumnApi) {
                let columns: any[] = detailGridColumnApi.getAllDisplayedVirtualColumns();// .filter(c=>c.getUserProvidedColDef() == true);.map(s=>s.getId());
                if (columns && columns.length > 0) {
                    columns.forEach(col => {
                        if (!col.colDef.Group) {
                            let headerEl = document.querySelectorAll(`.ag-header-cell[col-id=${col.colId}`);
                            if (headerEl) {
                                headerEl.forEach(ele => {
                                    ele.classList.add('custom-header');
                                });

                            }
                        }

                    });
                }
            }

        } catch (ex) {
            console.log(ex);
        }
    }

    checkLastUpdatedOn(tasks: any) {
        this.lastUpdatedDate = "";
        if (this.ViewModel.Source) {
            if (tasks && tasks.length > 0) {

                let maxDateCreated = new Date(Math.max.apply(null, tasks.map(function (e: any) {
                    return new Date(e.CreatedOn);
                })));

                let maxDateModified = new Date(Math.max.apply(null, tasks.map(function (e: any) {
                    return new Date(e.ModifiedOn);
                })));

                if (maxDateCreated > maxDateModified) {
                    this.lastUpdatedDate = this.getDateFormatTime(maxDateCreated) + ' (GMT)';
                }
                else {
                    this.lastUpdatedDate = this.getDateFormatTime(maxDateModified) + ' (GMT)';
                }
            }
        } else {
            this.lastUpdatedDate = this.getDateFormatTime(new Date()) + ' (GMT)';
        }
    }
    GetParentTaskID(taskID: string) {
        this._taskService.getLatestRecord(taskID, this.ViewSettingId)
            .subscribe({
                next: (response) => {
                    let latestTaskId = response;
                    this.gridData = this.taskList.filter(t => t.TaskID == latestTaskId);
                },
                error: (error) => {
                    console.log(error);
                }
            });
    }

    populateDetailGrid(param: any) {
        if (param.node.expanded && param.data && param.data['LatestRecord'] != 1) {
            let viewEntity: ViewModel = new ViewModel();
            viewEntity.ViewSettingId = this.ViewSettingId;
            viewEntity.selectedAssets = [{ label: this.asn, value: this.asn }];

            viewEntity.Conditions = [];
            if (this.viewHistoryKey && this.viewHistoryKey.length > 0) {
                for (var i = 0; i < this.viewHistoryKey.length; i++) {
                    let con = new Condition('');
                    con.FieldName = this.viewHistoryKey[i];
                    con.ConditionType = '=';
                    con.Value = param.data[this.viewHistoryKey[i]];
                    viewEntity.Conditions.push(con);
                }

                // if (detailGridApi) { detailGridApi.showLoadingOverlay(); }
                this._viewService.getTaskHistory(viewEntity)
                    .subscribe({
                        next: (response) => {
                            let result = response;
                            detailGridApi.setRowData(result);
                            if (result.length > 0) { detailGridApi.hideOverlay(); }
                            else { detailGridApi.showNoRowsOverlay(); }
                        },
                        error: (error) => {
                            detailGridApi.setRowData([]);
                            detailGridApi.showNoRowsOverlay();
                            console.log(error);
                        }
                    });
            }
        }
    }

    populateViewHistoryKey() {
        if (this.ViewSettingId) {
            this._viewService.getViewHistoryKey(this.ViewSettingId)
                .subscribe({
                    next: (response) => {
                        this.viewHistoryKey = response;
                    },
                    error: (error) => {
                        this.viewHistoryKey = [];
                        console.log(error);
                    }
                });
        }

    }

    onTaskSelectionChanged(event: any) {

        this.showHideCommDialogue(false);
        if (!_gridOptions.api) {
            _gridOptions.api = event.api;
            this.api = event.api;
            this.gridOptions.api = event.api;
        }
        IsDetailRowClicked = false;
        if (!this.feedlogid) {
            if (_gridOptions.api.getSelectedNodes().length > 0) {

                if (this.firstSelectedTask != _gridOptions.api.getSelectedNodes()[0].data.TaskID) {
                    this.populateTaskDocument(_gridOptions.api.getSelectedNodes()[0].data);

                    // sharedService.onTaskRecordSelection(_gridOptions.api.getSelectedNodes()[0].data);
                    this.firstSelectedTask = _gridOptions.api.getSelectedNodes()[0].data.TaskID;
                }
                this.selectedTask = _gridOptions.api.getSelectedNodes().map(d => d.data);
                this._sharedService.selectedTaskEntity = _gridOptions.api.getSelectedNodes()[0].data;
                //  this._selectedTaskId = _gridOptions.api.getSelectedNodes()[0].data.TaskId;
                // this._selectedViewId = _gridOptions.api.getSelectedNodes()[0].data.ViewSettingId;
            }
        }
    }
    onCellClickedForDetail(event: any) {
        IsDetailRowClicked = true;
        if (event.colDef.field == 'Comms') {
            if ((!this.isFromAPIModule || this.isFromAPIModule != 'API')
                && ((router.url.indexOf("record/browse") == -1) || (router.url.indexOf("transition/binders") == -1))) {
                let comObj = new CommPartial();
                //US-8181 //comObj.ASN = event.context.detailGridCtx._sharedService.selectedAssetStatus[0].data.split('|')[1];
                comObj.ASN = JSON.parse(event.context.detailGridCtx._sharedService.selectedAssetStatus[0].data).ASN;
                comObj.Module = this.isInRepairModule ? "Repairs" : "Status";
                comObj.Record = event.data.TaskID;
                comObj.Section = selectedViewId;
                comObj.TaskId = event.data.TaskID;
                event.context.detailGridCtx._sharedService.setModulesSelectedOptionForComm(comObj);
                event.context.detailGridCtx.displayissuedialogue = true;
                isDetailGridRowSelected = true;
            }

        }

    }
    onCellClicked(event: any) {
        if (event.colDef.field == 'Comms' || event.colDef.field == 'CommSummary') {
            if ((!this.isFromAPIModule || this.isFromAPIModule != 'API')
                && ((router.url.indexOf("record/browse") == -1) || (router.url.indexOf("transition/binders")))

            ) {
                this.commobj = new CommPartial();
                //US-8181 ////this.commobj.ASN = this._sharedService.selectedAssetStatus[0].data.split('|')[1];
                this.commobj.ASN = JSON.parse(this._sharedService.selectedAssetStatus[0].data).ASN;
                this.commobj.Module = this.isInRepairModule ? "Repairs" : "Status";;
                this.commobj.Record = event.data.TaskID;
                this.commobj.Section = this.ViewSettingId;
                this.commobj.TaskId = event.data.TaskID;
                this._sharedService.setModulesSelectedOptionForComm(this.commobj);

                this.displayissuedialogue = true;
                isDetailGridRowSelected = false;
            }

        }

    }
    onMultipleRowsSelectionChanged(event: any) {
        try {
            if (router.url.indexOf("record/browse") != -1 || router.url.indexOf("record/logs") != -1
                || router.url.indexOf("record/general") != -1 || router.url.indexOf("record/search") != -1 || router.url.indexOf("transition/search") != -1
                || router.url.indexOf("record/documents") != -1 || router.url.indexOf("transition/binders") != -1) {
                let docList: BlobMetaData[] = [];
                docList.push(sharedService.selectedDocument);
                docList[0].taskId = _gridOptions.api.getSelectedNodes().map(node => {
                    return node.data.TaskID;
                });
                sharedService.docToAttach = docList;
            }
            else if (router.url.indexOf("status/viewlist") != -1 || router.url.indexOf("repair/viewlist") != -1) {
                IsDetailRowClicked = false;
                if (!this.feedlogid) {
                    if (_gridOptions.api.getSelectedNodes().length > 0) {
                        sharedService.selectedTaskID = _gridOptions.api.getSelectedNodes()[0].data.TaskID;
                        var rowCount = _gridOptions.api.getSelectedNodes().length;
                        sharedService.selectedTaskEntity = _gridOptions.api.getSelectedNodes()[0].data;
                        if (detailGridApi != undefined && detailGridApi.getSelectedNodes().length) {
                            sharedService.selectedTaskCount = rowCount + detailGridApi.getSelectedNodes().length;
                        }
                        else {
                            sharedService.selectedTaskCount = rowCount;
                        }
                        if (this.firstSelectedTask != _gridOptions.api.getSelectedNodes()[0].data.TaskID) {
                            sharedService.onTaskRecordSelection(_gridOptions.api.getSelectedNodes()[0].data);
                            this.firstSelectedTask = _gridOptions.api.getSelectedNodes()[0].data.TaskID;
                        }
                        this.selectedTask = _gridOptions.api.getSelectedNodes().map(d => d.data);;
                    }
                    else if (detailGridApi != undefined && detailGridApi.getSelectedNodes().length > 0) {
                        var rowCount = detailGridApi.getSelectedNodes().length;
                        sharedService.selectedTaskCount = rowCount;
                        sharedService.selectedTaskID = detailGridApi.getSelectedNodes()[0].data.TaskID;
                    }
                    else {
                        sharedService.selectedTaskCount = 0;
                        sharedService.selectedTaskID = null;
                    }
                }
            }

        } catch (error) {
            console.log(error);
        }
    }

    onDetailGridRowsSelectionChanged(event: any) {
        IsDetailRowClicked = true;
        if (detailGridApi.getSelectedNodes().length > 0) {
            selectedRow.next(detailGridApi.getSelectedNodes()[0].data);
        }
        if (router.url.indexOf("record/browse") != -1 || router.url.indexOf("record/logs") != -1
            || router.url.indexOf("record/general") != -1 || router.url.indexOf("record/search") != -1 || router.url.indexOf("transition/search") != -1
            || router.url.indexOf("record/documents") != -1 || router.url.indexOf("transition/binders") != -1) {
            let docList: BlobMetaData[] = [];
            docList.push(sharedService.selectedDocument);
            docList[0].taskId = detailGridApi.getSelectedNodes().map(node => {
                return node.data.TaskID;
            });
            sharedService.docToAttach = docList;
        }
        else if (router.url.indexOf("status/viewlist") != -1 || router.url.indexOf("repair/viewlist") != -1) {
            //IsDetailRowClicked = false;
            if (!this.feedlogid) {
                if (detailGridApi.getSelectedNodes().length > 0) {

                    var rowCount = detailGridApi.getSelectedNodes().length;
                    sharedService.selectedTaskEntity = detailGridApi.getSelectedNodes()[0].data;
                    if (_gridOptions.api.getSelectedNodes().length) {
                        sharedService.selectedTaskCount = rowCount + _gridOptions.api.getSelectedNodes().length;
                    }
                    else {
                        sharedService.selectedTaskCount = rowCount
                    }
                    sharedService.onTaskRecordSelection(detailGridApi.getSelectedNodes()[0].data);
                    this.selectedTask = detailGridApi.getSelectedNodes().map(d => d.data);
                }
                else if (_gridOptions.api != undefined && _gridOptions.api.getSelectedNodes().length > 0) {
                    var rowCount = _gridOptions.api.getSelectedNodes().length;
                    sharedService.selectedTaskCount = rowCount;
                    sharedService.selectedTaskID = _gridOptions.api.getSelectedNodes()[0].data.TaskID;
                }
                else {
                    sharedService.selectedTaskCount = 0;
                    sharedService.selectedTaskID = null;
                }
            }
        }
    }
    detailRowClicked(event: any) {

        IsDetailRowClicked = true;
        if (detailGridApi.getSelectedNodes().length > 0) {
            selectedRow.next(detailGridApi.getSelectedNodes()[0].data);
        }
        if (router.url.indexOf("record/browse") != -1 || router.url.indexOf("record/logs") != -1 || router.url.indexOf("record/general") != -1 || router.url.indexOf("record/search") != -1 || router.url.indexOf("transition/binders") != -1) {
            let docList: BlobMetaData[] = [];
            docList.push(sharedService.selectedDocument);
            docList[0].taskId = detailGridApi.getSelectedNodes().map(node => {
                return node.data.TaskID;
            });
            sharedService.docToAttach = docList;
        }
    }
    firstSelectedTask;
    selectFirstRow() {
        // this.setGridColumnWidth();
        if (!this.feedlogid) {
            if (this.gridData && this.gridData.length) {
                if (this.api) {
                    if (this._sharedService.selectedTaskID != null && this._sharedService.selectedTaskID != "") {
                        let lastIdx = this.gridData.findIndex(n => n.TaskID == this._sharedService.selectedTaskID);
                        this.api.getDisplayedRowAtIndex(lastIdx).setSelected(true);
                    }
                    else {
                        this.api.getDisplayedRowAtIndex(0).setSelected(true);
                    }
                    let selRow = this.api.getSelectedRows();
                    var currentUrl = this._router.url;
                    // if(selRow[0].TaskID && !this.previousTskId)
                    // if(this.previousTaskUrl != currentUrl)
                    // {
                    // }
                    this.populateTaskDocument(selRow[0]);
                    this.firstSelectedTask = selRow[0].TaskID

                    // else if (this.previousTskId != selRow[0].TaskID){
                    //     this.populateTaskDocument(selRow[0]);
                    //     this.previousTskId =  selRow[0].TaskID
                    // }
                    this.previousTaskUrl = this._router.url;

                    this.selectedTask = selRow;
                    this._sharedService.selectedTaskEntity = selRow[0];
                }
            } else {
                this.populateTaskDocument(this.gridData);
                this.previousTaskUrl = this._router.url;
            }
        }
    }

    setGridColumnWidth() {
        // get the current grids width
        //  var gridWidth = document.getElementById('viewGrid').offsetWidth;

        var allColumns = this.gridOptions.columnApi.getColumns();
        // var allColumns = _gridOptions.columnApi.getColumns();
        // _gridOptions.columnApi.autoSizeColumns(allColumns);
        this.gridOptions.columnApi.autoSizeColumns(allColumns);
    }

    // hideIfNextEmpty() {
    //    let elementArray = document.querySelectorAll('span.ag-group-value');
    //    if (elementArray) {
    //        for (var i = 0; i < elementArray.length; i++) {
    //            if (elementArray[i].innerHTML == '1') {
    //                let checkBox = elementArray[i].previousElementSibling;
    //                checkBox.previousElementSibling.remove();
    //                elementArray[i].remove();
    //            }
    //        }
    //    }
    // }





    //#endregion

    //#region Attach from Record Popup
    populateDFPStatus() {
        this._viewService.getDFPStatus()
            .subscribe({
                next: (response) => {
                    this.dfpStatusList = response;
                },
                error: (error) => {
                    this.assets = [];
                    console.log(error);
                }
            });
    }

    populateAsset() {
        let userRole: string = localStorage.getItem('roles').trim();
        let body = { UserRole: userRole };
        this._assetService
            .getAssetWithAssetIdByUserRole()
            .subscribe({
                next: (response) => {
                    this.assets = response;
                },
                error: (error) => {
                    this.assets = [];
                    console.log(error);
                }
            });
    }



    onChangeCategory(event: any) {
        if (event.value == 'Search') {
            this.showSearch = true;

        } else {
            this.showSearch = false;
        }
        this._sharedService.recordType = event.value ? event.value : 'mxr';
        this.containerModel.category = event.value ? event.value : 'mxr';
        this.populateRecords();
    }

    onChangeAsset(event: any) {
        if (this._sharedService.recordType) {
            this._sharedService.onAsnChanged(this.containerModel.asn);
            this.populateRecords();
        }
        //let assetid = event.value.split('|').pop().trim();
        //US-8181 //let assetid = event.value.split('|')[3].trim();
        let assetid = JSON.parse(event.value).AssetID;
        this.populateCategoryByAssetID(assetid);
    }

    populateCategoryByAssetID(assetID: string): void {
        this.categories = [];
        this._assetService.getAssetbyID(assetID)
            .subscribe({
                next: (response) => {
                    this.categories = [];
                    this.showMXRBtn = false;
                    this.showLogBookBtn = false;
                    this.showGeneralBtn = false;
                    this.showDocumentBtn = false;
                    this.showBindersBtn = false;

                    if (response.MXRecords) {
                        this.categories.push({ value: 'MXR', label: 'MX Records' });
                        this.showMXRBtn = true;
                    }
                    if (response.LogBooks) {
                        this.categories.push({ value: 'LOG', label: 'Log Books' });
                        this.showLogBookBtn = true;
                    }
                    if (response.General) {
                        this.categories.push({ value: 'GEN', label: 'General' });
                        this.showGeneralBtn = true;
                    }
                    if (response.Documents) {
                        this.categories.push({ value: 'DOC', label: 'Documents' });
                        this.showDocumentBtn = true;
                    }
                    if (response.Binders) {
                        this.categories.push({ value: 'BND', label: 'Binders' });
                        this.showBindersBtn = true;
                    }

                    this.categories.push({ value: 'Search', label: 'Search' });
                },
                error: (error) => {
                    this.categories = [];
                    console.log(error);
                }
            });
    }

    onCategorySelection(category: string) {
        if (category == 'Search') {
            this.showSearch = true;
            this.viewID = '';
            let filterPanel = document.getElementById('search-col1');
            if (filterPanel) {
                filterPanel.style.display = 'block';
            }
            let documentGrid = document.getElementById('search-col2');
            if (documentGrid) {
                if (documentGrid.classList.contains("col-sm-6")) {
                    documentGrid.classList.remove("col-sm-6", "col-md-6", "col-lg-6");
                    documentGrid.classList.add("col-sm-4", "col-md-4", "col-lg-4");
                }
            }

        } else if (category == 'AutoSearch') {
            this.showSearch = true;
            this.viewID = this.ViewSettingId;
            let filterPanel = document.getElementById('search-col1');
            if (filterPanel) {
                filterPanel.style.display = 'none';
            }

        }
        else {
            this.showSearch = false;
            this.viewID = '';
        }
        this._sharedService.recordType = category ? category : 'MXR';
        this.isActive = category = category;
        this.populateRecords();
        this.changeCssOnTabChange();
    }

    updateRowData(event: any) {
        this.populateRecords();
    }

    populateRecords() {
        if (this.containerModel.asn && this._sharedService.recordType) {
            this.recordType = this._sharedService.recordType;// this.containerModel.category;
            this.selectedAsset = [];
            let selectTreeNode: TreeNode = { data: this.containerModel.asn };
            this.selectedAsset.push(selectTreeNode);
            this._sharedService.onAssetChanged(this.selectedAsset);
        }
    }


    attachDocument() {
        if (this._sharedService.docToAttach.length > 0) {
            this._sharedService.showLoader("Processing...");
            let viewTaskDocument = {
                TaskBlobList: this._sharedService.docToAttach,
                View: this.ViewModel,
                TaskEntity: this.selectedTask[0]
            }
            if (this._sharedService.docToAttach.length > 100) {
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Limit Exceeded', detail: 'Only 100 or less documents can be attach at once.' });
            }
            else{
            this._taskDocumentService.addTaskDocuments(viewTaskDocument)
                .subscribe({
                    next: (response) => {
                        this._sharedService.hideLoader();

                        this.refreshTaskDocuments();
                        this.displayDialog = false;
                        this._sharedService.onAssetChanged(this._sharedService.selectedAssetTreeNode);//this.tempSelectedAsset
                        this.resetPopup(null);
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Document(s) attached succesfully.' });
                    },
                    error: (error) => {
                        if (error.status == 501) {
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'warn', summary: 'Unable to update document URL', detail: error.error });
                            this._sharedService.hideLoader();
                            this.refreshTaskDocuments();
                            this.displayDialog = false;
                            this.resetPopup(null);
                        }
                        else {
                            this._sharedService.hideLoader();
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: error.error });
                            //this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Cannot attach document(s), please try again.' });
                        }
                    }
                });
            }

        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Warning', detail: 'No document(s) selected.' });
    }
    }

    FetchPDFNameByBlobNameOrUrl(docToAttach: any): string {
        let pdfFileName: string = null;
        if (docToAttach.BlobName) {
            var docToAttachFileName = docToAttach.BlobName.toLowerCase().split('.');
            var docToAttachFileType = docToAttachFileName[docToAttachFileName.length - 1].toLowerCase();
            if (docToAttachFileType == 'pdf' && docToAttach.BlobName.indexOf('videourl') == -1 && docToAttach.Url.indexOf('videourl') == -1) {
                pdfFileName = docToAttach.BlobName;
            }
        }
        else if (docToAttach.Url.toLowerCase().indexOf('.pdf') > -1 && docToAttach.Url.toLowerCase().indexOf('videourl') == -1) {
            pdfFileName = docToAttach.Url.split('?')[0].replace(/^.*[\\\/]/, '');
        }
        return pdfFileName;
    }

    OpenExtractModal() {
        //var _docToAttach1 = this._sharedService.docToAttach[0];
        let _pdfFileName = this.FetchPDFNameByBlobNameOrUrl(this._sharedService.docToAttach[0]);
        //_pdfFileName eg JJH09041887421.pdf
        if (_pdfFileName) {
            var _docToAttach1FileName = _pdfFileName.split('.');
            var _docToAttach1FileType = _docToAttach1FileName[_docToAttach1FileName.length - 1].toLowerCase();

            if (this._sharedService.docToAttach.length == 1 && _docToAttach1FileType == 'pdf' && _pdfFileName.indexOf('videourl') == -1) {
                this.isDisplayExtractPagesDialog = true;
            }
            else {
                this.isDisplayExtractPagesDialog = false;
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Invalid or multiple files selected...', detail: 'Only single .PDF file is allowed to select and extract.' });
                this._sharedService.hideLoader();
            }
        } else {
            this.isDisplayExtractPagesDialog = false;
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No file is selected...', detail: 'Only single .PDF file is allowed to select and extract.' });
            this._sharedService.hideLoader();
        }
    }

    SplitAndAttachDocument() {
        if (this._sharedService.docToAttach.length > 0) {
            this._sharedService.showLoader("Processing...");
            let viewTaskDocument = {
                TaskBlobList: this._sharedService.docToAttach,
                View: this.ViewModel,
                TaskEntity: this.selectedTask[0],
                PageRange: this.PageRange
            }
            this._taskDocumentService.addTaskDocuments(viewTaskDocument)
                .subscribe({
                    next: (response) => {
                        this._sharedService.hideLoader();

                        this.refreshTaskDocuments();
                        this.displayDialog = false;
                        this._sharedService.onAssetChanged(this._sharedService.selectedAssetTreeNode);//this.tempSelectedAsset
                        this.resetPopup(null);
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Document(s) attached succesfully.' });
                    },
                    error: (error) => {
                        if (error.status == 501) {
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'warn', summary: 'Unable to update document URL', detail: error.error });
                            this._sharedService.hideLoader();
                            this.refreshTaskDocuments();
                            this.displayDialog = false;
                            this.resetPopup(null);
                        }
                        else {
                            this._sharedService.hideLoader();
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: error.error });
                            //this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Cannot attach document(s), please try again.' });
                        }
                    }
                });

        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Warning', detail: 'No document(s) selected.' });
        }
    }

    btnCancelClick(form: NgForm) {
        //this.changeButtonsStatus(false);
        this.isDisplayExtractPagesDialog = false;
        form.resetForm();
        this.PageRange = '';
        //this.resetModel(MergeOperation.Extract);
    }

    resetPopup(event: any) {
        this._sharedService.isStatusAttachDocDialogueOpen = false;
        this.showSearch = false;
        this.selectedAsset = [];
        this.containerModel.asn = '';
        this._sharedService.recordType = '';
        //this._sharedService.onAssetChanged(this.selectedAsset);
        this._sharedService.onAssetChanged(this._sharedService.selectedAssetTreeNode);
        this._sharedService.onBrowseContainerSelection([]);
        this.isDisplayExtractPagesDialog = false;
        this.PageRange = '';
    }

    updateCss() {
        let conPanelHeader = document.getElementById('conPanelHeader');
        let docPanelHeader = document.getElementById('docPanelHeader');
        let pdfPanelHeader = document.getElementById('pdfPanelHeader');

        if (conPanelHeader && conPanelHeader.style) { conPanelHeader.style.margin = '0px'; }
        docPanelHeader.style.margin = '0px';
        pdfPanelHeader.style.margin = '0px';
    }

    adjustPanelsWidth() {
        let containerGrid = document.getElementById('containerGrid');
        let documentGrid = document.getElementById('documentGrid');
        let pdfPanel = document.getElementById('DocumentShow');

        documentGrid.style.height = '85vh';
        containerGrid.style.height = '90vh';
        pdfPanel.style.height = '90vh';
    }


    btnAttachRecord() {
        if (this._sharedService.docToAttach && this._sharedService.docToAttach.length > 0) {
            this._sharedService.docToAttach = [];
        }
        this.isCurrentModule = true;
        this.actionAfterinterval();
        if (this.ViewModel.DefaultContainer) {
            let containerDetail = this.ViewModel.DefaultContainer.split('|');
            if (containerDetail.length == 5) { //Query using ContainerId
                this._containerService.getContainerDetail(containerDetail[containerDetail.length - 1].trim())
                    .subscribe({
                        next: (response) => {
                            if (response && response.ASN != null) {
                                let container = response;
                                container.ASN = container.ASN.toLowerCase() + '|' + container.ASN; //+ '|' + this.ViewModel.AssetType;
                                this._sharedService.isNavigationFilterCanceled = true;
                                this._sharedService.setRouteInfoAndNavigateStatusPopup({ MenuID: '', PageURL: '', id: container.ContainerID, recordId: '', ColorCode: '' });
                                this.displayDialog = true;
                                this.setSelAssetAndPopulateAutoSearch(container);

                            } else {
                                this.displayDialog = true;
                                this.setSelAssetAndPopulateAutoSearch();
                            }
                        },
                        error: (error) => {
                            console.log(error);
                            this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Error Occurred, please try again.' });
                        }
                    });

            } else {
                let assetDetailsArray = JSON.parse(this._sharedService.selectedAsset[0].data.split('|')).ASN;
                let container = {
                    ASN: assetDetailsArray.toLowerCase() + '|' + assetDetailsArray, //+ '|' + this.ViewModel.AssetType,
                    Type: containerDetail[0],
                    Category: containerDetail[1],
                    Date: containerDetail[2],
                    EndDate: containerDetail[3],
                    ReferenceKey: containerDetail[4],
                };
                this._sharedService.isNavigationFilterCanceled = true;
                this._sharedService.setRouteInfoAndNavigateStatusPopup({ MenuID: '', PageURL: '', id: this.ViewModel.DefaultContainer.replace('{ASN}', this.asn), recordId: '', ColorCode: '' });
                this.displayDialog = true;
                this.setSelAssetAndPopulateAutoSearch(container);
            }
        } else {

            this.displayDialog = true;
            this.setSelAssetAndPopulateAutoSearch();
        }
    }

    onShowAttachDocumnet(event: any) {
        this._sharedService.onDialogueOpen(true);
        this._sharedService.isStatusAttachDocDialogueOpen = true;
        this.setShowTaskRowCss();

        let grid = document.getElementsByClassName('set-searchDoc-height-task');
        if (grid && grid[0]) {
            grid[0].classList.add('set-searchDoc-height');
            grid[0].classList.remove('set-searchDoc-height-task');
        }

        let grid1 = document.getElementsByClassName('set-searchgrid-height-task');
        if (grid1 && grid1[0]) {
            grid1[0].classList.add('set-searchgrid-width');
            grid1[0].classList.remove('set-searchgrid-height-task');
        }

        let grid2 = document.getElementsByClassName('set-screen-height-task');
        if (grid2 && grid2[0]) {
            grid2[0].classList.add('set-screen-width');
            grid2[0].classList.remove('set-screen-height-task');
        }
        // Populate Selected Task
        this.setTaskGridColumns();
        this.taskGridData = this.api.getSelectedRows();
        this.cRef.markForCheck();
    }

    setSelAssetAndPopulateAutoSearch(defaultContainer: any = null) {
        // Set selected Asset and
        if (defaultContainer) {
            if (this.assets.length > 0) {
                let _asn_: string = defaultContainer.ASN.split('|')[0];
                let selectedASN: string = "";
                let assetid: string = "";

                this.assets.some(function (asset, index) {
                    if (asset.value) {
                        var asn = JSON.parse(asset.value).ASN
                        if (asn == _asn_) {
                            selectedASN = asset.value;
                            //assetid = asset.value.split('|')[3].trim();
                            assetid = JSON.parse(asset.value).AssetID;//asset.value.split('|')[3].trim();
                        }
                    }

                });
                this.containerModel.asn = selectedASN;
                this.populateCategoryByAssetID(assetid);
            }
            else {
                this.containerModel.asn = defaultContainer.ASN;
            }

            // populate Container Category
            this.onCategorySelection(defaultContainer.Category);

        } else {
            if (this._sharedService.selectedAssetStatus && this._sharedService.selectedAssetStatus[0]) {
                //US-8181 //let dataArray = this._sharedService.selectedAssetStatus[0].data.split('|');                
                let assetDataObj = JSON.parse(this._sharedService.selectedAssetStatus[0].data);

                if (this.assets.length > 0) {
                    //US-8181 //let _asn_: string = dataArray[0] + '|' + dataArray[1] + '|' + dataArray[4];
                    let _asn_: string = this._sharedService.selectedAssetStatus[0].data;
                    let selectedASN: string = "";
                    let assetid: string = "";
                    this.assets.some(function (asset, index) {
                        //if (asset.value.includes(_asn_)) {
                        if (JSON.parse(asset.value).ASN == JSON.parse(_asn_).ASN) {
                            selectedASN = asset.value;
                            assetid = assetDataObj.AssetID;//asset.value.split('|')[3].trim();
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                    );
                    assetid = assetDataObj.AssetID;// asset.value.split('|')[3].trim();
                    this.containerModel.asn = selectedASN;
                    this.populateCategoryByAssetID(assetid);
                }
                else {
                    //this.containerModel.asn = dataArray[0] + '|' + dataArray[1] + '|' + dataArray[4];
                    this.containerModel.asn = this._sharedService.selectedAssetStatus[0].data;//dataArray[0] + '|' + dataArray[1] + '|' + dataArray[4] + '|' + dataArray[3] + '|' + dataArray[9] + '|' + dataArray[10];
                    var assetid = assetDataObj.AssetID;//dataArray[3].trim();
                    this.populateCategoryByAssetID(assetid);
                }
            }

            // populate Auto Search
            this.onCategorySelection('AutoSearch');

        }
    }

    btnDetachDocument() {
        let selectedDoc = this.taskDocuments.find(d => d.DocumentPath == this.selectedUrl);
        if (selectedDoc && selectedDoc.TaskDocumentId) {
            this.confirmationService.confirm({
                key: "confirmDlg",
                message: "Do you really want to detach document(s)?",
                accept: () => {
                    this._sharedService.showLoader("Processing...");
                    let viewTaskDocument = {
                        TaskDocumentList: [selectedDoc],
                        View: this.ViewModel,
                        TaskEntity: this.selectedTask[0]
                    }
                    this._taskDocumentService.removeTaskDocuments(viewTaskDocument)
                        .subscribe({
                            next: (response) => {
                                this._sharedService.hideLoader();
                                this.refreshTaskDocuments();
                                this._sharedService.clearToast();
                                this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Document(s) have been detached succesfully.' });
                            },
                            error: (error) => {
                                if (error.status == 501) {
                                    this._sharedService.clearToast();
                                    this._sharedService.showToast({ severity: 'warn', summary: 'Unable to update document URL', detail: error.error });
                                    this._sharedService.hideLoader();
                                    this.refreshTaskDocuments();
                                }
                                else {
                                    this._sharedService.hideLoader();
                                    console.log(error);
                                    this._sharedService.clearToast();
                                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occurred', detail: 'Please try again later.' });
                                }
                            }
                        });
                },
                reject: () => {

                }
            });
        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Task/Documnet', detail: 'No task/document selected' });
        }

    }

    showHideSelectedTask() {
        if (this.showSelectedTask == true) {
            this.taskButtonTitle = 'Show Entry';
            this.showSelectedTask = false;
            this.setHideTaskRowCss();
        }
        else {
            this.taskButtonTitle = 'Hide Entry';
            this.showSelectedTask = true;
            this.setShowTaskRowCss();
        }
    }

    changeCssOnTabChange() {
        if (this.showSelectedTask == true) {
            this.setHideTaskRowCss();
        }
        else {
            this.setShowTaskRowCss();
        }

    }
    setShowTaskRowCss() {
        let gridDiv = document.getElementsByClassName('set-searchgrid-width');
        if (gridDiv && gridDiv[0]) {
            gridDiv[0].classList.add('set-searchgrid-height-task');
            gridDiv[0].classList.remove('set-searchgrid-width');
        }
        let grid = document.getElementsByClassName('set-screen-width');
        if (grid && grid[0]) {
            grid[0].classList.add('set-screen-height-task');
            grid[0].classList.remove('set-screen-width');
        }
        let pdfDiv = document.getElementsByClassName('set-searchDoc-height');
        if (pdfDiv && pdfDiv[0]) {
            pdfDiv[0].classList.add('set-searchDoc-height-task');
            pdfDiv[0].classList.remove('set-searchDoc-height');
        }
        let pdfDoc = document.getElementsByClassName('set-grid-width');
        if (pdfDoc && pdfDoc[0]) {
            pdfDoc[0].classList.add('set-grid-height-task');
            pdfDoc[0].classList.remove('set-grid-width');
        }
        let searchfltrpnl = document.getElementsByClassName('set-filterpanel-height');
        if (searchfltrpnl && searchfltrpnl[0]) {
            searchfltrpnl[0].classList.add('set-filterpanel-height-task');
            searchfltrpnl[0].classList.remove('set-filterpanel-height');
        }
    }
    setHideTaskRowCss() {
        let gridDiv = document.getElementsByClassName('set-searchgrid-height-task');
        if (gridDiv && gridDiv[0]) {
            gridDiv[0].classList.add('set-searchgrid-width');
            gridDiv[0].classList.remove('set-searchgrid-height-task');
        }
        let grid = document.getElementsByClassName('set-screen-height-task');
        if (grid && grid[0]) {
            grid[0].classList.add('set-screen-width');
            grid[0].classList.remove('set-screen-height-task');
        }
        let pdfDiv = document.getElementsByClassName('set-searchDoc-height-task');
        if (pdfDiv && pdfDiv[0]) {
            pdfDiv[0].classList.add('set-searchDoc-height');
            pdfDiv[0].classList.remove('set-searchDoc-height-task');
        }
        let pdfDoc = document.getElementsByClassName('set-grid-height-task');
        if (pdfDoc && pdfDoc[0]) {
            pdfDoc[0].classList.add('set-grid-width');
            pdfDoc[0].classList.remove('set-grid-height-task');
        }

        let searchfltrpnl = document.getElementsByClassName('set-filterpanel-height-task');
        if (searchfltrpnl && searchfltrpnl[0]) {
            searchfltrpnl[0].classList.add('set-filterpanel-height');
            searchfltrpnl[0].classList.remove('set-filterpanel-height-task');
        }
    }

    //#endregion


    actionAfterinterval() {

        setTimeout(() => {

            this.updateCss();

        },
            2000);
    }

    // #region Add Edit Task

    taskRowDoubleClicked(event: any) {
        if (this.canEditTask) {
            IsDetailRowClicked = false;
            if (this._router.url.indexOf('status/viewlist') != -1 || this._router.url.indexOf('repair/viewlist') != -1) {
                this._sharedService.UpdateTaskEdit(true);
            }
        }
    }

    showDeleteTask() {
        if (this.gridData && this.gridData.length > 0) {
            // if (this._sharedService.selectedTaskID && this.api.getRowNode(this._sharedService.selectedTaskID) && this.api.getRowNode(this._sharedService.selectedTaskID).master) {
            //     this.msgs = [];
            //     this.msgs.push({ severity: 'warn', summary: 'History record(s) exist', detail: 'Please delete the history record(s) first !' });
            // } else
            {

                this.confirmationService.confirm({
                    key: 'confirmDlg',
                    message: 'Are you Sure?',
                    accept: () => {
                        let listOfIdsToDelete: string = "";
                        let isCheckCommsExistence: boolean = true;

                        this.deleteTask(listOfIdsToDelete, isCheckCommsExistence);
                    },
                    reject: () => {

                    }
                });
            }
        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Records Selected', detail: 'Please select a record(s) first !' });
        }
    }

    deleteTask(listOfIdsToDelete: string, isCheckCommsExistence: boolean) {

        let selectedTasksList = [];
        let viewSettingId: any = this.ViewSettingId;
        //US-8181 //let asn: any = this._sharedService.selectedAssetStatus[0].data.split('|')[1];
        let asn: any = JSON.parse(this._sharedService.selectedAssetStatus[0].data).ASN;

        let parentNodes: IRowNode[] = _gridOptions.api ? _gridOptions.api!.getSelectedNodes() : [];

        parentNodes.forEach(function (rowNode, index) {
            let body = {
                TaskID: rowNode.data.TaskID,    // this._sharedService.selectedTaskID,
                ViewSettingId: viewSettingId,// this.ViewSettingId,
                ASN: asn,// this._sharedService.selectedAssetStatus[0].data.split('|')[1],
                listOfIdsToDelete: listOfIdsToDelete,
                IsCheckCommsExistence: isCheckCommsExistence,
            };
            selectedTasksList.push(body);
            // console.log('parent node -- '+index + ' = ' +body.TaskID );
        });

        _gridOptions.api.forEachDetailGridInfo(function (detailGridInfo) {

            let childNodes: IRowNode[] = detailGridInfo.api ? detailGridInfo.api!.getSelectedNodes() : [];
            childNodes.forEach(function (rowNode, index) {
                let body = {
                    TaskID: rowNode.data.TaskID,    // this._sharedService.selectedTaskID,
                    ViewSettingId: viewSettingId,// this.ViewSettingId,
                    ASN: asn,// this._sharedService.selectedAssetStatus[0].data.split('|')[1],
                    listOfIdsToDelete: listOfIdsToDelete,
                    IsCheckCommsExistence: isCheckCommsExistence,
                };
                selectedTasksList.push(body);
                // console.log('child node -- '+index + ' = ' + body.TaskID );
            });

        });

        if (selectedTasksList.length == 0) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Records Selected', detail: 'Please select a record(s) first !' });
            return;
        }

        this._sharedService.showLoader('Working...');
        // let body = {
        //     TaskID: this._sharedService.selectedTaskID,
        //     ViewSettingId: this.ViewSettingId,
        //     ASN: this._sharedService.selectedAssetStatus[0].data.split('|')[1],
        //     listOfIdsToDelete: listOfIdsToDelete,
        //     IsCheckCommsExistence: isCheckCommsExistence,
        // };
        taskService.deleteTasks(selectedTasksList)
            .subscribe({
                next: (response) => {
                    if (response == true) {
                        this.populateGrid();
                        this._sharedService.hideLoader();
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Record has been deleted succesfully.' });
                    }
                    else {
                        this._sharedService.hideLoader();
                        this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Cannot delete the task, please try again.' });
                    }
                },
                error: (error) => {
                    if (error.status == 406) {
                        let listOfIdsToDelete: string = error.error;
                        let str = error.error.replace(/^"(.*)"$/, '$1');
                        this._sharedService.clearToast();
                        // this.refreshDocGrid();
                        this._sharedService.hideLoader();
                        this.confirmDeleteComms(str);
                    }
                    else {
                        this._sharedService.hideLoader();
                        console.log(error);
                        this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Cannot delete the task, please try again.' });
                    }
                }
            });
    }
    confirmDeleteComms(CompletelistOfIdsToDelete: string) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }

        this.confirmationService.confirm({
            key: "confirmDlg",
            message: 'There is communication attach to this record, do you still want to delete?',
            header: 'Delete Confirmation',
            icon: 'fa fa-trash',
            accept: () => {
                let listOfIdsToDelete: string = CompletelistOfIdsToDelete;
                let isCheckCommsExistence: boolean = false;
                this.deleteTask(listOfIdsToDelete, isCheckCommsExistence)
            },
            reject: () => {
            }
        });

    }
    showDetailGridEditTask(event: any) {
        IsDetailRowClicked = true;
        event.context.detailGridCtx._sharedService.UpdateTaskEdit(true);
    }

    onAddUpdateTaskItem(event: any) {
        if (event && event.isUpdated && event.updatedObj) {
            this.isAddCommItem(event.updatedObj)
        }
        this.populateGrid();
    }

    getDate(date: Date): string {
        if (date != null) {
            // let newDate = moment(date).format("YYYY-MM-DD");
            // return date.toDateString()
            let newDate = moment(date).format("YYYY-MM-DD");
            return newDate; // new Date(newDate).toDateString();
        }
        return null;
    }


    adjustTaskDlgHeight(event: any) {
        let tskDlgfrm = document.getElementById('tskDlgfrm');

        if (tskDlgfrm) {
            tskDlgfrm.style.height = '70vh';
            tskDlgfrm.style.overflowY = 'scroll';
            this.setScrollPostition();
        }
    }

    // resetTskForm(event: any) {
    //     this.tskForm.reset();
    // }

    // #endregion

    RunAutoSearch(): void {

        if (!this.ViewSettingId) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No view Selected', detail: 'Please select a view.' });
            return;
        }


        if (!(this.gridData && this.gridData.length)) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No task selected', detail: 'Please select Task(s) to run the process.' });
            return;
        }


        let pCount: number = _gridOptions.api ? _gridOptions.api!.getSelectedNodes()!.length : 0;
        let cCount: number = detailGridApi ? detailGridApi!.getSelectedNodes()!.length : 0;

        let totalselectedTaskCount = pCount + cCount;

        if (totalselectedTaskCount <= 0) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No task selected', detail: 'Please select Task(s) to run the process.' });
            return;
        }


        this.ValidateTaskSearchQuery(this.ViewSettingId, totalselectedTaskCount);
        // if (fnlCount > 5000) {
        // this._sharedService.clearToast();
        // this._sharedService.showToast({ severity: 'warn', summary: '5 thousand-Threshold exceeds', detail: 'Please select records under threshold limit.' });
        // return;
        // }



    }


    ValidateTaskSearchQuery(viewSettingID: string, totalselectedTaskCount: number) {

        this._taskService.getSearchQueriesbyViewID(viewSettingID)
            .subscribe({
                next: (response) => {
                    let isInternal = this.isInternalView;
                    let viewTaskQueries = response;
                    if (viewTaskQueries) {

                        let builderQueries: any = viewTaskQueries.AutoSearchQueries;
                        if (builderQueries && builderQueries.length <= 0) {
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'error', summary: 'Auto search Query', detail: 'NO OCR Search query found for selected view.' });
                            return;
                        }
                        else {

                            this.confirmationService.confirm({
                                key: "confirmTaskStatusChange",
                                message: "Warning! Compliance status will be changed",
                                accept: () => {

                                    if (isInternal != true) {

                                        let pageSize: number = 100;
                                        let totalPage: number = Math.ceil(totalselectedTaskCount / 100);

                                        for (let page = 0; page < totalPage; page++) {

                                            let startIndex = page * pageSize;
                                            let endIndex = startIndex + pageSize;
                                            if (endIndex > totalselectedTaskCount) {
                                                endIndex = totalselectedTaskCount;
                                            }
                                            this.validateThreshold(totalselectedTaskCount, false, startIndex, endIndex);
                                        }
                                    }
                                    else {
                                        this.validateThreshold(totalselectedTaskCount);

                                    }



                                },
                                reject: () => {

                                }
                            });


                        }


                    }
                },
                error: (error) => {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'an error occured, please try again.' });
                }
            });
    }


    validateThreshold(totalselectedTaskCount: number, isInternal: boolean = true, pStartIndex: number = 0, pEndIndex: number = 0): void {

        let selectedTaskIDs: string[] = [];
        let selectedTasks: any[] = [];

        this._sharedService.showLoader("Processing...");
        // setTimeout(() => {this._sharedService.showLoader("Processing..."); }, 2000);
        setTimeout(() => {
            // this._sharedService.showLoader("Processing...");
            let isThresholdExceeds: Boolean = false;

            if (totalselectedTaskCount > 1000 && isInternal == true) {
                isThresholdExceeds = true;
            }
            else {
                isThresholdExceeds = false;
                if (isInternal != true) {
                    this.GetSelectedTask(selectedTasks, pStartIndex, pEndIndex);
                    totalselectedTaskCount = 0;
                    if (!(selectedTasks && selectedTasks.length)) {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'No task selected', detail: 'Please select Task(s) to run the process.' });
                        this._sharedService.hideLoader();
                        return;
                    }
                    totalselectedTaskCount = selectedTasks.length;
                }
                else {
                    this.GetSelectedTaskIDs(selectedTaskIDs);
                    if (!(selectedTaskIDs && selectedTaskIDs.length)) {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'No task selected', detail: 'Please select Task(s) to run the process.' });
                        this._sharedService.hideLoader();
                        return;
                    }
                }


            }

            this._sharedService.hideLoader();


            this.confirmChangeTaskStatus(isThresholdExceeds, selectedTaskIDs, totalselectedTaskCount, selectedTasks, isInternal);
        }, 1000);



    }


    confirmChangeTaskStatus(isConfirmation: Boolean, selectedTaskIds: string[], totalselectedTaskCount: number, selectedTasks: any[], isInternal: boolean) {

        if (isConfirmation) {

            if (this.ManualFilterList && this.ManualFilterList.length > 0) {

                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'You cannot submit Auto-Search', detail: 'Batch processing for more than 1000 rows from the screen, please revise your filter criteria' });
                return;
            }

            this.confirmationService.confirm({
                key: "confirmTaskStatusChange",
                message: "Warning! Threshold limit (1000) exceeds. Do you want to run job for complete view",
                accept: () => {
                    this.runFeed(selectedTaskIds, totalselectedTaskCount, selectedTasks, isInternal);
                },
                reject: () => {

                }
            });
        } else {
            this.runFeed(selectedTaskIds, totalselectedTaskCount, selectedTasks, isInternal);
        }



    }


    runFeed(selectedTaskIDs: string[], totalselectedTaskCount: number, selectedTasks: any[], isInternal: boolean) {
        // let channleInfo=new ChannelEvent();
        // channleInfo.ChannelName=this.channel;
        // channleInfo.Name=this.eventName;
        // channleInfo.Data="Azure search triggered";
        // this.channelService.publish(channleInfo);

        //
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            let vTask = new viewJobProgress();
            vTask.ViewID = this.ViewSettingId;//parseInt(this.ViewSettingId);
            if (selectedTaskIDs && selectedTaskIDs.length) {
                vTask.TotalRecords = selectedTaskIDs.length;
            }
            else {

                vTask.TotalRecords = this.gridData.length;
            }
            vTask.JobID = Math.random().toString();// this.GetProcessID();
            vTask.State = "Default";
            vTask.CurrentTaskIndex = 0;
            vTask.ViewName = "";
            vTask.ActiveChannel = this._sharedService.getGroupNameKey(this.channel);
            //
            this._sharedService.setViewTaskProgress(vTask)
            let taskJob: runJob = {
                ViewID: this.ViewSettingId,//parseInt(this.ViewSettingId),
                TaskIDs: selectedTaskIDs,
                Tasks: selectedTasks,
                IsInternal: isInternal,
                JobID: vTask.JobID,
                ActiveChannel: vTask.ActiveChannel,
                ASN: this.asn,
                TotalRecords: totalselectedTaskCount,
                AccessToken: this._sharedService.GetToken()
            };

            this._apiService.runAutoSearch(taskJob)
                .subscribe({
                    next: (response) => {
                        let body = response;
                        this._sharedService.clearToast();
                        if (body.indexOf("Success") != -1) {
                            this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Request to process Job Submitted.' });
                            this.isProcessed = true;
                            //this.populateDataFeedGrid();
                            //setTimeout(() => {
                            //    this.populateDataFeedGrid();
                            //}, 10000);
                        }
                        else if (body.indexOf("In Active") != -1) {
                            this._sharedService.showToast({ severity: 'warn', summary: 'Auto Search Inactive', detail: 'Change the Auto Search to Active.' });
                        }
                        else if (body.indexOf("In progress") != -1) {
                            this._sharedService.showToast({ severity: 'warn', summary: 'Auto Search In progress', detail: 'Another Auto Search is in progress.' });
                        }
                        else if (body.indexOf("Failed") != -1) {
                            this._sharedService.showToast({ severity: 'error', summary: 'Job failed', detail: 'Request to process job is un-successful.' });
                        }
                        else {
                            this._sharedService.showToast({
                                severity: 'error', summary: 'Auto Search failed to start', detail: 'Request to run Auto Search is un- successful.'
                            });
                        }

                        // this._sharedService.clearToast();
                        // this._sharedService.showToast({ severity: 'success', summary: 'Feed started', detail: 'Request to run feed is successful.' });
                    },
                    error: (error) => {
                        this._sharedService.showToast({
                            severity: 'error', summary: 'Auto Search failed to start', detail: 'Request to run Auto Search is un- successful.'
                        });
                    }
                });
        }
    }

    GetProcessID(): string {
        if (this._sharedService.runningViewTasks && this._sharedService.runningViewTasks.length > 0) {
            return (this._sharedService.runningViewTasks.length + 1).toString();
        } else {
            return "1";
        }

    }


    GetSelectedTask(selectedTaskData: any[], pStartIndex: number, pEndIndex: number): Boolean {

        let isStatus: boolean = false;
        let dFPStatus: string = "";

        let pData = _gridOptions.api.getSelectedNodes();
        let pLen = pData.length;


        for (let i = pStartIndex; i < pEndIndex; i++) {
            let pData = _gridOptions.api.getSelectedNodes()[i].data;
            // if (!(pData && pData.hasOwnProperty('TaskID'))) {
            // continue;
            // }

            dFPStatus = (pData != null && pData!.hasOwnProperty('DFPStatus') && pData.DFPStatus != null) ? pData.DFPStatus.trim() : null;// ? pData.DFPStatus.trim() : "";
            if (!dFPStatus)// && !(dFPStatus == "Suggested - no document found" || dFPStatus == "Suggested - partial documents found" || dFPStatus == "Suggested - documents found" || dFPStatus == "Suggested - not applicable" || dFPStatus == "New"))
            {
                continue;
            }

            selectedTaskData.push(pData);


        }
        // if (detailGridApi) {
        // let cLen = detailGridApi.getSelectedNodes().length;
        // for (let j = 0; j < cLen; j++) {
        // let childData = detailGridApi.getSelectedNodes()[j].data;
        // if (!(childData && childData.hasOwnProperty('TaskID'))) {
        // continue;
        // }
        // dFPStatus = (childData != null && childData.hasOwnProperty('DFPStatus') && childData.DFPStatus != null) ? childData.DFPStatus.trim() : null;
        // if (dFPStatus && !(dFPStatus == "Suggested - no document found" || dFPStatus == "Suggested - partial documents found" || dFPStatus == "Suggested - documents found" || dFPStatus == "Suggested - not applicable" || dFPStatus == "New")) {
        // isStatus = true;
        // }
        // if (childData.TaskID) {
        // selectedTaskData.push(childData.TaskID);
        // }

        // }
        // }
        return isStatus;

    }


    GetSelectedTaskIDs(selectedTaskData: string[]): Boolean {

        let isStatus: boolean = false;
        let dFPStatus: string = "";
        let pLen = _gridOptions.api.getSelectedNodes().length
        for (let i = 0; i < pLen; i++) {
            let pData = _gridOptions.api.getSelectedNodes()[i].data;
            if (!(pData && pData.hasOwnProperty('TaskID'))) {
                continue;
            }

            dFPStatus = (pData != null && pData!.hasOwnProperty('DFPStatus') && pData.DFPStatus != null) ? pData.DFPStatus.trim() : null;// ? pData.DFPStatus.trim() : "";
            if (dFPStatus && !(dFPStatus == "Suggested - no document found" || dFPStatus == "Suggested - partial documents found" || dFPStatus == "Suggested - documents found" || dFPStatus == "Suggested - not applicable" || dFPStatus == "New")) {
                isStatus = true;
            }

            if (pData.TaskID) {
                selectedTaskData.push(pData.TaskID);
            }

        }
        if (detailGridApi) {
            let cLen = detailGridApi.getSelectedNodes().length;
            for (let j = 0; j < cLen; j++) {
                let childData = detailGridApi.getSelectedNodes()[j].data;
                if (!(childData && childData.hasOwnProperty('TaskID'))) {
                    continue;
                }
                dFPStatus = (childData != null && childData.hasOwnProperty('DFPStatus') && childData.DFPStatus != null) ? childData.DFPStatus.trim() : null;
                if (dFPStatus && !(dFPStatus == "Suggested - no document found" || dFPStatus == "Suggested - partial documents found" || dFPStatus == "Suggested - documents found" || dFPStatus == "Suggested - not applicable" || dFPStatus == "New")) {
                    isStatus = true;
                }
                if (childData.TaskID) {
                    selectedTaskData.push(childData.TaskID);
                }

            }
        }
        return isStatus;

    }





    showRunningTasksbyView() {
        if (this._sharedService.runningViewTasks && this._sharedService.runningViewTasks.length > 0) {
            this.displayRunningTaskDialog = true;

        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Job', detail: 'No job inProgress' });
        }
    }
    // #endregion
    //#region PDF Viewer
    populateTaskDocument(selTask: any) {
        this.resetCanvasAndControls();

        if (this.isInternalView == false && selTask!.hasOwnProperty('TaskDocuments') && selTask.TaskDocuments && selTask.TaskDocuments.length > 0) {
            try {

                this.taskDocuments = selTask.TaskDocuments;

                if (this.taskDocuments[0].DocumentPath.indexOf('api/Blob/GetDocument/') != -1) {
                    this.LoadDocument(this.taskDocuments[0].DocumentPath, false)
                } else {
                    this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(this.taskDocuments[0].DocumentPath);
                }

                this.selectedUrl = this.taskDocuments[0].DocumentPath;
                this._sharedService.setStatusModuleSelectedDocument(this.selectedUrl);
                this._sharedService._selectedViewRecord;

                if (this.taskDocuments.length > 2) {
                    this.setTaskDocumentsList();
                }

            }
            catch (error) {
                console.log(error);
                this.taskDocuments = [];
                this._sharedService.setStatusModuleSelectedDocument(null);
            }
        } else
            if (selTask && selTask.TaskID) {

                selTask.ViewSettingId = this.ViewSettingId;
                // if(selTask.LatestRecord==""){
                //     selTask.LatestRecord=null;
                // }
                // else{
                //     selTask.LatestRecord= Number(selTask.LatestRecord);
                // }
                this._taskDocumentService.getTaskDocument(selTask)
                    .subscribe({
                        next: (response) => {
                            //For Error
                            if (!response.TaskDocuments && response.ErrorMsg) {
                                this._sharedService.hideLoader();
                                this._sharedService.clearToast();
                                this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: response.ErrorMsg });
                                return;
                            }
                            this.taskDocuments = response.TaskDocuments as TaskDocument[];
                            //this.resetCanvasAndControls();
                            if (this.taskDocuments.length > 0) {
                                //Bug 12137: Repair>Add '-' in key and after save first image is shown whereas combo display what was saved
                                if (this.sltdDocId) {
                                    this.selectedTaskOnSaveObj();
                                    return;
                                }
                                this.fileType = 'image/png';
                                this.selectedTaskDoc = this.taskDocuments[0];
                                this.selectedUrl = this.taskDocuments[0].DocumentPath;
                                this._sharedService.setStatusModuleSelectedDocument(this.selectedUrl);
                                this._sharedService._selectedViewRecord;

                                let docType = "";
                                let urlSegments = this.taskDocuments[0].DocumentPath.split('?');
                                this.mimeTypeFileName = this.taskDocuments[0].DocumentName;
                                if (urlSegments.length == 2) {
                                    docType = urlSegments[0].split(".").pop();
                                }
                                else {
                                    //to do
                                    docType = urlSegments[0].split(".").pop();
                                }

                                this.resetCanvasAndControls();
                                if (docType) {
                                    this.isAudioTypeFound = this._sharedService.allowedAudioFileTypes.filter(x => x == docType)[0];
                                    this.isVideoTypeFound = this._sharedService.allowedVideoFileTypes.filter(x => x == docType)[0];
                                    this.isNonPreviewTypeFound = this._sharedService.allowedNonPreviewFileTypes.filter(x => x == docType)[0];
                                    this.isDocumentTypeFound = this._sharedService.allowedDocumentTypes.filter(x => x == docType)[0];
                                    this.isImageTypeFound = this._sharedService.allowedImageFileTypes.filter(x => x == docType)[0];
                                    this.isVideoURLTypeFound = docType == "videourl" ? 'videourl' : undefined;
                                    if (docType == "videourl" || this.selectedTaskDoc.VideoUrl != undefined) {
                                        this.isVideoURLTypeFound = 'videourl';
                                        this.isDocumentTypeFound = undefined;
                                    }

                                    this.fileType = docType;

                                    if (!this.isAudioTypeFound && !this.isVideoTypeFound && !this.isVideoURLTypeFound &&
                                        !this.isNonPreviewTypeFound && !this.isDocumentTypeFound && !this.isImageTypeFound) {
                                        this.isNonPreviewTypeFound = docType;// handle blob with non-preview file type
                                    }
                                    this.selectedUrl = this.taskDocuments[0].DocumentPath;

                                    if (!this.isImageTypeFound) { this.taskDocuments[0].IsImage = "false"; }
                                }


                                if (this.taskDocuments[0].IsImage == "true" || this.isImageTypeFound) {
                                    this.fileType = 'image/png';
                                    this.previousFilePath = undefined;
                                    if (this.selectedTaskDoc && this.selectedTaskDoc.OtherDetail) {
                                        this.drawCanvasObject(this.selectedTaskDoc);
                                        this.setCanvasBackgroundImage(this.taskDocuments[0].DocumentPath, false);

                                    } else {
                                        this.pointGuid = this._sharedService.GetGuid();
                                        this.setCanvasBackgroundImage(this.taskDocuments[0].DocumentPath, true);

                                    }
                                }
                                else if (this.taskDocuments[0].DocumentPath) {
                                    this.selectedUrl = this.taskDocuments[0].DocumentPath;

                                    if (this.isVideoTypeFound) {
                                        this.fileType = this.isVideoTypeFound;
                                        this.showPDF_or_ImageViewer = false;
                                        this.taskDocuments[0].IsImage = "false";

                                        //for VM case
                                        if (this.selectedUrl.lastIndexOf('api/Blob/GetDocument/') >= 0) {
                                            if (this.selectedUrl.lastIndexOf('.') >= 0) {
                                                let lastIndex = this.selectedUrl.lastIndexOf('.');
                                                //this.selectedUrl = this._azureBlobService.replaceAt(this.selectedUrl, lastIndex, 1, '^|^');
                                                this.selectedUrl = this.selectedUrl;
                                                this.load_VM_AudioVideo(this.selectedUrl);
                                            }
                                        }
                                        else {//Azure
                                            setTimeout(() => {
                                                if (this._videoPlayer) {
                                                    const video: HTMLVideoElement = this._videoPlayer.nativeElement;
                                                    video.setAttribute('src', this.selectedUrl);
                                                    video.load();
                                                    video.play();

                                                    //setTimeout(() => {
                                                    const vid: HTMLVideoElement = this._videoPlayer.nativeElement;
                                                    if (vid.error) {
                                                        console.log("Video err: " + vid.error.code);
                                                    }
                                                    //}, 2000);
                                                }
                                            }, 2000);
                                        }
                                    }
                                    else if (this.isAudioTypeFound) {
                                        this.fileType = this.isAudioTypeFound;
                                        this.showPDF_or_ImageViewer = false;
                                        this.taskDocuments[0].IsImage = "false";
                                        //for VM case
                                        if (this.selectedUrl.lastIndexOf('api/Blob/GetDocument/') >= 0) {
                                            if (this.selectedUrl.lastIndexOf('.') >= 0) {
                                                let lastIndex = this.selectedUrl.lastIndexOf('.');
                                                //this.selectedUrl = this._azureBlobService.replaceAt(this.selectedUrl, lastIndex, 1, '^|^');
                                                this.selectedUrl = this.selectedUrl;
                                                this.load_VM_AudioVideo(this.selectedUrl);
                                            }
                                        }
                                        else {//Azure

                                            setTimeout(() => {
                                                if (this._audioPlayer) {
                                                    const audio: HTMLAudioElement = this._audioPlayer.nativeElement;
                                                    audio.setAttribute('src', this.selectedUrl);
                                                    audio.load();
                                                    audio.play();

                                                    //setTimeout(() => {
                                                    const aud: HTMLAudioElement = this._audioPlayer.nativeElement;
                                                    if (aud.error) {
                                                        console.log("Audio err: " + aud.error.code);
                                                    }
                                                    //}, 2000);
                                                }
                                            }, 2000);
                                        }

                                    }

                                    else if (this.isNonPreviewTypeFound) {
                                        this.showPDF_or_ImageViewer = false;
                                        this.taskDocuments[0].IsImage = "false";
                                        this.fileType = this.isNonPreviewTypeFound;
                                        let IsTypeFound = this._sharedService.allowedMimeTypesImages.filter(x => x == this.fileType)[0]
                                        if (IsTypeFound) {
                                            this.mimeTypeImageSrc = '/assets/img/MimeTypes/' + this.fileType + '.png'
                                        }
                                        else {
                                            this.mimeTypeImageSrc = '/assets/img/MimeTypes/file.png'
                                        }
                                    }
                                    else if (this.isDocumentTypeFound) {
                                        this.showPDF_or_ImageViewer = true;
                                        this.selectedUrl = this.taskDocuments[0].DocumentPath;
                                        this.selectedVideoUrl = null;


                                        if (this._sharedService.searchText && this._sharedService.searchText.indexOf('"') == -1) {
                                            if (this.selectedUrl.indexOf('api/Blob/GetDocument/') != -1) {
                                                this.loadImage(this.selectedUrl, false);
                                            } else {
                                                this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(this.selectedUrl + this.selectedZoom + '&search="' + this._sharedService.searchText + '"');

                                            }

                                        } else {

                                            if (this.selectedUrl.indexOf('api/Blob/GetDocument/') != -1) {
                                                this.loadImage(this.selectedUrl, true);
                                            } else {
                                                if (this.fileType == 'pdf') {
                                                    this.fileType = 'application/pdf'
                                                }
                                                this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(this.selectedUrl + this.selectedZoom + '&search="' + this._sharedService.searchText + '"&phrase=true');
                                            }
                                        }
                                    }
                                    else if (this.isImageTypeFound) {
                                        this.fileType = 'image/png';
                                        this.setCanvasBackgroundImage(this.taskDocuments[0].DocumentPath, true);
                                    }
                                    else if (this.isVideoURLTypeFound) {
                                        this.fileType = 'application/pdf';
                                        this.pdfSrc = "";
                                        this.selectedVideoUrl = this.taskDocuments[0].VideoUrl;
                                        this.selectedUrl = this.taskDocuments[0].DocumentPath;
                                        this.video_html = this.embedVideoService.embed(this.selectedVideoUrl, {
                                            query: { portrait: 0, color: '333' },
                                            attr: { width: '100%' }
                                        });
                                    }
                                    //}
                                }

                                // if (this.taskDocuments[0].IsImage == "true") {
                                //     this.fileType = 'image/png';
                                //     this.previousFilePath = undefined;
                                //     if (this.selectedTaskDoc && this.selectedTaskDoc.OtherDetail) {
                                //         this.drawCanvasObject(this.selectedTaskDoc);
                                //         this.setCanvasBackgroundImage(this.taskDocuments[0].DocumentPath, false);

                                //     } else {
                                //         this.pointGuid = this._sharedService.GetGuid();
                                //         this.setCanvasBackgroundImage(this.taskDocuments[0].DocumentPath, true);

                                //     }

                                // } 
                                else {
                                    this.fileType = 'application/pdf';


                                    if (!this.taskDocuments[0].VideoUrl) {
                                        if (this.taskDocuments[0].DocumentPath.indexOf('api/Blob/GetDocument/') != -1) {
                                            this.LoadDocument(this.taskDocuments[0].DocumentPath, false);
                                        } else {
                                            this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(this.taskDocuments[0].DocumentPath);
                                        }
                                        this.selectedUrl = this.taskDocuments[0].DocumentPath;
                                        this._sharedService.setStatusModuleSelectedDocument(this.selectedUrl);
                                        this._sharedService._selectedViewRecord;
                                    } else {
                                        this.pdfSrc = "";
                                        this.selectedVideoUrl = this.taskDocuments[0].VideoUrl;
                                        this.selectedUrl = this.taskDocuments[0].DocumentPath;
                                        this.video_html = this.embedVideoService.embed(this.selectedVideoUrl, {
                                            query: { portrait: 0, color: '333' },
                                            attr: { width: '100%' }
                                        });

                                        this._sharedService.setStatusModuleSelectedDocument(this.selectedUrl);
                                        this._sharedService._selectedViewRecord;
                                    }

                                }

                                if (this.taskDocuments.length > 2) {
                                    this.setTaskDocumentsList();
                                }

                            } else {
                                this.pdfSrc = "";
                                this.selectedUrl = null;
                                this.selectedVideoUrl = null;
                                this.taskDocuments = [];
                                this.taskDocumentsList = [];
                                this._sharedService.setStatusModuleSelectedDocument(null);
                            }
                            this.cRef.markForCheck();
                        },
                        error: (error) => {
                            console.log(error);
                            this.taskDocuments = [];
                            this._sharedService.setStatusModuleSelectedDocument(null);
                        }
                    });

                // this._sharedService.selectedTaskID = selTask.TaskID;
            } else {
                this.taskDocuments = [];
                this.taskDocumentsList = [];
                this._sharedService.setStatusModuleSelectedDocument(null);
            }
    }

    selectedTaskOnSaveObj() {
        var selectedTaskDoc = this.taskDocuments.find(x => x.TaskDocumentId == this.sltdDocId);
        this.selectedTaskDoc = selectedTaskDoc;
        this.selectedUrl = selectedTaskDoc.DocumentPath;
        this._sharedService.setStatusModuleSelectedDocument(this.selectedUrl);
        this._sharedService._selectedViewRecord;
        this.mimeTypeFileName = selectedTaskDoc.DocumentName;
        this.fileType = 'image/png';
        this.previousFilePath = undefined;
        if (this.selectedTaskDoc && this.selectedTaskDoc.OtherDetail) {
            this.drawCanvasObject(this.selectedTaskDoc);
            this.setCanvasBackgroundImage(selectedTaskDoc.DocumentPath, false);
        } else {
            this.pointGuid = this._sharedService.GetGuid();
            this.setCanvasBackgroundImage(selectedTaskDoc.DocumentPath, true);
        }
        if (this.taskDocuments.length > 2) {
            this.setTaskDocumentsList();
        }
        this.sltdDocId = null;
        this.cRef.markForCheck();
    }
    LoadDocument(documentPath: any, isPharase: Boolean): void {

        let selDoc = documentPath;
        if (!documentPath) {
            selDoc = undefined;
            this.previousFilePath = undefined;
            return;
        }

        if (this.previousFilePath == selDoc && this._router.url.indexOf("/repair/") == -1) {
            return;
        }

        this.previousFilePath = selDoc;
        this.selectedVideoUrl = null;

        if (!this.previousFilePath) {
            return;
        }

        this.loadImage(selDoc, isPharase);

    }

    loadImage(url: string, isPharase: Boolean): any {
        this._azureBlobService.getPDFBlob(url)
            .subscribe({
                next: (res) => {
                    if (isPharase) {

                        this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(
                            URL.createObjectURL(res) + this.selectedZoom + '&search="' + this._sharedService.searchText + '"&phrase=true'
                        );
                    } else {
                        this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(
                            URL.createObjectURL(res) + this.selectedZoom + '&search="' + this._sharedService.searchText + '"'

                        );
                    }
                },
                error: (err) => {
                    this.selectedUrl = undefined;
                    //.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(undefined);
                    console.log(err);
                    //        // ERROR HANDLING HERE
                }
            })
    }

    load_VM_AudioVideo(url: string): any {
        this._azureBlobService.getPDFBlob(url)
            .subscribe({
                next: (res: any) => {
                    this.resetCanvasAndControls();
                    this.fileType = res.type;
                    this.selectedUrl = URL.createObjectURL(res);

                    if (this.fileType.indexOf('video/') != -1) {

                        this.isVideoTypeFound = this._sharedService.allowedVideoFileTypes.filter(x => x == this.fileType)[0];
                        if (this.isVideoTypeFound) {

                            setTimeout(() => {
                                if (this._videoPlayer) {
                                    const video: HTMLVideoElement = this._videoPlayer.nativeElement;
                                    video.setAttribute('src', this.selectedUrl);
                                    video.setAttribute('type', this.fileType);
                                    video.load();
                                    video.play();

                                    setTimeout(() => {
                                        const vid: HTMLVideoElement = this._videoPlayer.nativeElement;
                                        if (vid.error) {
                                            console.log("Video err: " + vid.error.code);
                                        }
                                    }, 2000);
                                }
                            }, 2000);
                        }
                        else {
                            this.isNonPreviewTypeFound = this.fileType;
                        }
                    }
                    else {

                        this.isAudioTypeFound = this._sharedService.allowedAudioFileTypes.filter(x => x == this.fileType)[0];
                        if (this.isAudioTypeFound) {
                            setTimeout(() => {
                                if (this._audioPlayer) {
                                    const audio: HTMLAudioElement = this._audioPlayer.nativeElement;
                                    audio.setAttribute('src', this.selectedUrl);
                                    audio.setAttribute('type', this.fileType);
                                    audio.load();
                                    audio.play();

                                    //setTimeout(() => {
                                    const aud: HTMLAudioElement = this._audioPlayer.nativeElement;
                                    if (aud.error) {
                                        console.log("Audio err: " + aud.error.code);
                                    }
                                    //}, 2000);
                                }
                            }, 2000);
                        }
                        else {
                            this.isNonPreviewTypeFound = this.fileType;
                        }
                    }
                },
                error: (err) => {
                    this.selectedUrl = undefined;
                    //.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(undefined);
                    console.log(err);
                    //        // ERROR HANDLING HERE
                }
            });
    }
    GetTokenAndUpdateDoc(document: any) {
        if (document && document.DocumentPath) {
            if (document.DocumentPath && document.DocumentPath.indexOf('api/Blob/GetDocument/') == -1) {
                let body = { Url: document.DocumentPath }
                this._azureBlobService.getDocumentURLWithSASToken(body)
                    .subscribe({
                        next: (res: any) => {
                            document.DocumentPath = res;
                            this.updateTaskDoc(document);
                            // this.updateDoc(document);
                        },
                        error: (err) => {
                            console.log(err);
                        }
                    });
            } else {
                this.updateDoc(document);
            }
        } else {
            this.updateDoc(document);
        }
    }

    updateDoc(document: any) {
        this.selectedTaskDoc = document;
        this.resetCanvasAndControls();
        if (this.selectedTaskDoc.IsImage == "true") {
            this.showPDF_or_ImageViewer = true;
            this.fileType = 'image/png';
            this.previousFilePath = undefined;
            if (this.selectedTaskDoc && this.selectedTaskDoc.OtherDetail) {
                this.drawCanvasObject(this.selectedTaskDoc);
                this.setCanvasBackgroundImage(document.DocumentPath, false);
            } else {
                this.setCanvasBackgroundImage(document.DocumentPath, true);

            }
        } else {
            this.fileType = 'application/pdf';
            if (!document.VideoUrl) {
                if (document.DocumentPath.indexOf('api/Blob/GetDocument/') != -1) {
                    this.LoadDocument(document.DocumentPath, false);
                } else {
                    this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(document.DocumentPath);
                }

            } else {
                this.pdfSrc = "";
                this.selectedUrl = null;
                this.selectedVideoUrl = document.VideoUrl;
                this.video_html = this.embedVideoService.embed(this.selectedVideoUrl, {
                    query: { portrait: 0, color: '333' },
                    attr: { width: '100%' }
                });

            }
        }

        this.selectedUrl = document.DocumentPath;
        this._sharedService.setStatusModuleSelectedDocument(document.DocumentPath);
    }

    setTaskDocumentsList() {
        //this.taskDocumentsList = null;
        this.taskDocumentsList = [];
        for (var i = 0; i < this.taskDocuments.length; i++) {
            this.taskDocumentsList.push({
                value: this.taskDocuments[i].TaskDocumentId,
                label: this.taskDocuments[i].DocumentType ? this.taskDocuments[i].DocumentType : " ",
                icon: this.getDocIcon(this.taskDocuments[i])
            });
        }
    }

    GetTokenAndUpdateTaskDoc(event: any) {
        let document = this.taskDocuments.find(t => t.TaskDocumentId == event.value);
        if (document && document.DocumentPath) {
            if (document.DocumentPath && document.DocumentPath.indexOf('api/Blob/GetDocument/') == -1) {
                let body = { Url: document.DocumentPath }
                this._azureBlobService.getDocumentURLWithSASToken(body)
                    .subscribe({
                        next: (res: any) => {
                            document.DocumentPath = res;
                            this.updateTaskDoc(document);
                        },
                        error: (err) => {
                            console.log(err);
                        }
                    });
            } else {
                this.updateTaskDoc(document);
            }
        } else {
            this.updateTaskDoc(document);
        }
    }

    updateTaskDoc(doc: any) {
        if (doc.value) {
            doc = this.taskDocuments.find(t => t.TaskDocumentId == doc.value);
        }
        // let doc = this.taskDocuments.find(t => t.TaskDocumentId == event.value);
        this.selectedTaskDoc = doc;
        //if (doc.DocumentPath) {
        this.selectedUrl = doc.DocumentPath;
        let docType = "";
        let urlSegments = doc.DocumentPath.split('?');
        if (urlSegments.length == 2) {
            docType = urlSegments[0].split(".").pop();
        }
        else {
            //to do
            docType = urlSegments[0].split(".").pop();
        }
        this.resetCanvasAndControls();
        if (docType) {
            this.isAudioTypeFound = this._sharedService.allowedAudioFileTypes.filter(x => x == docType)[0];
            this.isVideoTypeFound = this._sharedService.allowedVideoFileTypes.filter(x => x == docType)[0];
            this.isNonPreviewTypeFound = this._sharedService.allowedNonPreviewFileTypes.filter(x => x == docType)[0];
            this.isDocumentTypeFound = this._sharedService.allowedDocumentTypes.filter(x => x == docType)[0];
            this.isImageTypeFound = this._sharedService.allowedImageFileTypes.filter(x => x == docType)[0];
            this.isVideoURLTypeFound = docType == "videourl" ? 'videourl' : undefined;

            if (docType == "videourl" || doc.VideoUrl != undefined) {
                this.isVideoURLTypeFound = 'videourl';
                this.isDocumentTypeFound = undefined;
            }


            if (!this.isAudioTypeFound && !this.isVideoTypeFound && !this.isVideoURLTypeFound &&
                !this.isNonPreviewTypeFound && !this.isDocumentTypeFound && !this.isImageTypeFound) {
                this.isNonPreviewTypeFound = docType;// handle blob with non-preview file type
            }
            this.selectedUrl = doc.DocumentPath;

            if (!this.isImageTypeFound) { doc.IsImage = "false"; }
        }
        if (doc.IsImage == "true") {
            this.fileType = 'image/png';
            this.showPDF_or_ImageViewer = true;
            this.previousFilePath = undefined;
            if (doc.OtherDetail) {
                this.setCanvasBackgroundImage(doc.DocumentPath, true);
                this.drawCanvasObject(doc, false);

            } else {
                this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(doc.DocumentPath);
                //this.setCanvasBackgroundImage(doc.DocumentPath, true);
                //this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(doc.DocumentPath);
                setTimeout(() => {
                    this.setCanvasBackgroundImage(doc.DocumentPath, true);
                }, 1000);
            }
        }
        else if (doc.DocumentPath) {
            this.selectedUrl = doc.DocumentPath;
            if (this.isVideoTypeFound) {
                this.fileType = this.isVideoTypeFound;
                this.showPDF_or_ImageViewer = false;
                doc.IsImage = "false";

                //for VM case
                if (this.selectedUrl.lastIndexOf('api/Blob/GetDocument/') >= 0) {
                    if (this.selectedUrl.lastIndexOf('.') >= 0) {
                        let lastIndex = this.selectedUrl.lastIndexOf('.');
                        //this.selectedUrl = this._azureBlobService.replaceAt(this.selectedUrl, lastIndex, 1, '^|^');
                        this.selectedUrl = this.selectedUrl;
                        this.load_VM_AudioVideo(this.selectedUrl);
                    }
                }
                else {//Azure
                    setTimeout(() => {
                        if (this._videoPlayer) {
                            const video: HTMLVideoElement = this._videoPlayer.nativeElement;
                            video.setAttribute('src', this.selectedUrl);
                            video.load();
                            video.play();

                            //setTimeout(() => {
                            const vid: HTMLVideoElement = this._videoPlayer.nativeElement;
                            if (vid.error) {
                                console.log("Video err: " + vid.error.code);
                            }
                            //}, 2000);
                        }
                    }, 2000);
                }
            }
            else if (this.isAudioTypeFound) {
                this.fileType = this.isAudioTypeFound;
                this.showPDF_or_ImageViewer = false;
                doc.IsImage = "false";
                //for VM case
                if (this.selectedUrl.lastIndexOf('api/Blob/GetDocument/') >= 0) {
                    if (this.selectedUrl.lastIndexOf('.') >= 0) {
                        let lastIndex = this.selectedUrl.lastIndexOf('.');
                        //this.selectedUrl = this._azureBlobService.replaceAt(this.selectedUrl, lastIndex, 1, '^|^');
                        this.selectedUrl = this.selectedUrl;
                        this.load_VM_AudioVideo(this.selectedUrl);
                    }
                }
                else {//Azure

                    setTimeout(() => {
                        if (this._audioPlayer) {
                            const audio: HTMLAudioElement = this._audioPlayer.nativeElement;
                            audio.setAttribute('src', this.selectedUrl);
                            audio.load();
                            audio.play();

                            //setTimeout(() => {
                            const aud: HTMLAudioElement = this._audioPlayer.nativeElement;
                            if (aud.error) {
                                console.log("Audio err: " + aud.error.code);
                            }
                            //}, 2000);
                        }
                    }, 2000);
                }

            }

            else if (this.isNonPreviewTypeFound) {
                this.showPDF_or_ImageViewer = false;
                doc.IsImage = "false";
                this.fileType = this.isNonPreviewTypeFound;
            }
            else if (this.isDocumentTypeFound) {
                this.fileType = 'application/pdf';
                this.showPDF_or_ImageViewer = true;
                this.selectedUrl = doc.DocumentPath;
                this.selectedVideoUrl = null;
                if (this._sharedService.searchText && this._sharedService.searchText.indexOf('"') == -1) {
                    if (this.selectedUrl.indexOf('api/Blob/GetDocument/') != -1) {
                        this.loadImage(this.selectedUrl, false);
                    } else {
                        this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(this.selectedUrl + this.selectedZoom + '&search="' + this._sharedService.searchText + '"');
                    }

                } else {
                    if (this.selectedUrl.indexOf('api/Blob/GetDocument/') != -1) {
                        this.loadImage(this.selectedUrl, true);
                    } else {
                        this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(this.selectedUrl + this.selectedZoom + '&search="' + this._sharedService.searchText + '"&phrase=true');
                    }
                }
            }
            else if (this.isImageTypeFound) {
                this.fileType = 'image/png';
                this.setCanvasBackgroundImage(doc.DocumentPath, true);
            }

            else if (this.isVideoURLTypeFound) {
                this.fileType = 'application/pdf';
                this.pdfSrc = "";
                this.selectedVideoUrl = this.selectedTaskDoc.VideoUrl;
                this.selectedUrl = this.selectedTaskDoc.DocumentPath;
                this.video_html = this.embedVideoService.embed(this.selectedVideoUrl, {
                    query: { portrait: 0, color: '333' },
                    attr: { width: '100%' }
                });
            }
            //}
        }


        else {
            this.fileType = 'application/pdf';
            if (!doc.VideoUrl) {
                if (doc.DocumentPath.indexOf('api/Blob/GetDocument/') != -1) {
                    this.LoadDocument(doc.DocumentPath, false);
                } else {
                    this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(doc.DocumentPath);
                }

            } else {
                this.pdfSrc = "";
                this.selectedUrl = null;
                this.selectedVideoUrl = doc.VideoUrl;
                this.video_html = this.embedVideoService.embed(this.selectedVideoUrl, {
                    query: { portrait: 0, color: '333' },
                    attr: { width: '100%' }
                });
            }
        }

        this.selectedUrl = doc.DocumentPath;
        this._sharedService.setStatusModuleSelectedDocument(this.selectedUrl);
    }

    btnDownloadBlob() {

        // var fileNameWOExt = this.selectedUrl.split('.')[0];
        // this.downloadBlobFromStorage(this.selectedUrl, fileNameWOExt);
        if (this.selectedUrl.indexOf('?sv=') != -1) {
            var fileNameWOExt = this.selectedUrl.split('.')[0];
            this.downloadBlobFromStorage(this.selectedUrl, fileNameWOExt);
        }
        else {
            this._sharedService.showLoader("Processing...");
            this._azureBlobService.getPDFBlob(this.selectedUrl)
                .subscribe({
                    next: (res: any) => {
                        this._sharedService.hideLoader();
                        let blob = new Blob([res], { type: res.type });
                        let url = URL.createObjectURL(blob);
                        let fileNameArray = this.selectedUrl.split('/');
                        var fileNameIndex = fileNameArray.length - 1;
                        var fileName = fileNameArray[fileNameIndex];
                        this.downloadBlobFromStorage(url, fileName);
                    },
                    error: (err) => {
                        this._sharedService.hideLoader();
                        console.log(err);
                    }
                });
        }

    }
    downloadBlobFromStorage(fileURL: string, fileName: string) {
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = fileURL;
        a.target = '_blank';
        a.download = fileName;
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
    }

    onChange(event: any) {

        this.uploadedFile = [];
        if (event.srcElement.files.length > 0) {
            this.isFileSelected = true;
            if (((this.recordType != 'DOC' && this.recordType != 'BND') && (this._sharedService.allowedImageFileContentTypes.includes(event.srcElement.files[0].type) || event.srcElement.files[0].type == 'application/pdf')) || (this.recordType == 'DOC' || this.recordType == 'BND')) {
                if (event.srcElement.files[0].size <= this._sharedService.MAX_ALLOWED_FILE_SIZE_FOR_DOC) {
                    if ((event.srcElement.files[0].size <= this.maxAllowedFileSize) || (event.srcElement.files[0].type != "application/pdf")) {

                        //|| (event.srcElement.files[0].size <= this.maxAllowedFileSize) && (event.srcElement.files[0].type != "application/pdf")) {
                        this.uploadedFile = event.srcElement.files;
                    }
                    else {
                        this.isFileSelected = false;
                        this.uploadedFile = [];
                        event.srcElement.files = null;
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File', detail: 'File size exceeds the maximum limit of ' + this.maxAllowedFileSizeMB + ' MB for PDF or 1 GB for other types' });
                    }
                }
                else {
                    this.isFileSelected = false;
                    this.uploadedFile = [];
                    event.srcElement.files = null;
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File', detail: 'File size exceeds the maximum limit of ' + this.maxAllowedFileSizeMB + ' MB for PDF or 1 GB for other types' });
                }
            }
            else {
                this.uploadedFile = [];
                this.isFileSelected = false;
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Invalid File', detail: 'Only valid files are allowed' });

            }
        }
        else {
            this.uploadedFile = [];
            this.isFileSelected = false;
        }
    }

    onChange_UploadBlob(event: any) {
        if (event.srcElement.files.length > 0) {
            this.isFileSelected = true;
            this.uploadedFile = event.srcElement.files;
            this.disableImageLoadOption = true;
            if (this.recordType == "DOC" || this.recordType == "BND") {
                this.disableImageLoadOption = false;
                //  this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_DOC_CATEGORY + "," + this._sharedService.allowedAzureIndexFileContentTypes.toString();
            }
            if (event.srcElement.files[0].type.indexOf('image/') != -1) {
                this.showUploadAsImage = true;
                this.isImageSwitch = 1;

            } else {
                this.showUploadAsImage = false;
                this.isImageSwitch = 0;
                //  this.disableImageLoadOption=false;
            }
        }
        //this.onChange(event);
    }

    btnUploadBlob(event: any) {
        if (this.isFileSelected && this.uploadedFile.length > 0) {
            // if (((this.recordType != 'DOC') 
            //     && (this._sharedService.allowedImageFileContentTypes.includes(this.uploadedFile[0].type) 
            //         || this.uploadedFile[0].type == 'application/pdf')) || (this.recordType == 'DOC')) 
            {
                if (this.uploadedFile[0].size <= this._sharedService.MAX_ALLOWED_FILE_SIZE_FOR_DOC) {
                    if ((this.uploadedFile[0].size <= this.maxAllowedFileSize) || this.uploadedFile[0].type != "application/pdf") {

                        let xhr = new XMLHttpRequest(), formData = new FormData();

                        //Append File and Form Data in xhr Request
                        formData.append("uploads[]", this.uploadedFile[0], this.uploadedFile[0].name);
                        formData.append('ExistingBlobURL', this.selectedUrl);
                        if (this.isImageSwitch == 1) {
                            formData.append("IsImage", "false");
                            this.BlobName = this.uploadedFile[0].name.split('.')[0] + '.pdf';
                            formData.append("BlobName", this.BlobName);
                        }
                        else {
                            this.BlobName = this.uploadedFile[0].name;
                            formData.append("IsImage", "true");
                            formData.append("BlobName", this.BlobName);
                        }
                        let uploadUrl = `${this._sharedService.baseUrl}/api/AzureBlob/UpdateUploadBlob`;
                        xhr.open('POST', uploadUrl, true);
                        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
                        xhr.send(formData);
                        this._sharedService.showLoader("Processing...");
                        xhr.onreadystatechange = () => {
                            if (xhr.readyState === 4) {
                                this._sharedService.hideLoader();
                                if (xhr.status === 200) {
                                    this.showUpdateFileDlg = false;
                                    this._sharedService.clearToast();
                                    this._sharedService.showToast({ severity: 'success', summary: 'Document uploaded', detail: 'Document has been uploaded successfully.' });
                                    this.showUploadAsImage = false;
                                    this.disableImageLoadOption = false;
                                    this.isImageSwitch = 0;
                                    this.resetCanvasAndControls();
                                    this.populateGrid();
                                    //    this.isDiaplayDialog = false;
                                    //    this.changeButtonsStatus(false);
                                    //    this.clearSingleFile();
                                    // this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                                    // this._sharedService.onAssetChanged(this._sharedService.selectedAsset);//R.A 13-09-2020
                                }


                                else if (xhr.status == 409) {
                                    this._sharedService.clearToast();
                                    this._sharedService.showToast({ severity: 'error', summary: 'Invalid File', detail: 'Only valid files are allowed' });
                                    this.uploadedFile = [];
                                    this.isFileSelected = false;
                                    this.showUploadAsImage = false;
                                    this.disableImageLoadOption = false;
                                    this.isImageSwitch = 0;
                                }

                                else if (xhr.status == 501) {
                                    this._sharedService.clearToast();
                                    this._sharedService.showToast({ severity: 'warn', summary: 'Unable to update document URL', detail: xhr.responseText });
                                    this.showUploadAsImage = false;
                                    this.disableImageLoadOption = false;
                                    this.isImageSwitch = 0;
                                    //    this.isDiaplayDialog = false;
                                    //    this.changeButtonsStatus(false);
                                    //    this.clearSingleFile();
                                    //this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                                }
                                else {
                                    //    this.isDiaplayDialog = false;
                                    //    this.changeButtonsStatus(false);
                                    this._sharedService.clearToast();
                                    this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while uploading document.' });
                                    this.showUploadAsImage = false;
                                    this.disableImageLoadOption = false;
                                    this.isImageSwitch = 0;

                                }
                            }
                        };


                    }
                    else {
                        this.isFileSelected = false;
                        this.uploadedFile = [];
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File', detail: 'File size exceeds the maximum limit of ' + this.maxAllowedFileSizeMB + ' MB for PDF or 1 GB for other types' });

                    }
                }
                else {
                    this.isFileSelected = false;
                    this.uploadedFile = [];
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File', detail: 'File size exceeds the maximum limit of ' + this.maxAllowedFileSizeMB + ' MB for PDF or 1 GB for other types' });
                }
            }
            // else {
            //     this.uploadedFile = [];
            //     this._sharedService.clearToast();
            //     this._sharedService.showToast({ severity: 'error', summary: 'Invalid File', detail: 'Only valid files are allowed' });

            // }
        }
        else {
            this.uploadedFile = [];
        }
    }

    btnShowUpdateFileDlg() {
        let documentCategory;
        let selectedContainerName = this.selectedTaskDoc.DocumentPath.split("/")[5];
        this._containerService.getSelectedContainerCategory(selectedContainerName)
            .subscribe({
                next: (response) => {
                    documentCategory = response;
                    if (documentCategory == "MXR" || documentCategory == "LOG") {
                        this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_MXR_LOG_GEN_CATEGORY;
                    }
                    else {
                        this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_DOC_CATEGORY + "," + this._sharedService.allowedAzureIndexFileContentTypes.toString();
                    }
                    this.showUpdateFileDlg = true;
                }
            });
    }

    btnCancelUpdateFileClick() {
        this.showUpdateFileDlg = false;
        this.showUploadAsImage = false;
        this.disableImageLoadOption = false;
        this.isImageSwitch = 0;
    }
    getBlobList(): AzureBlob[] {

        let _selDoc = this._sharedService.selectedDocument;
        let blobList: AzureBlob[] = [];

        blobList.push(new AzureBlob(this._sharedService.getFileNameFromURL(_selDoc.Url, true), this._sharedService.selectedContainer.ContainerName, this._sharedService.selectedContainer.ID,
            this._sharedService.selectedContainer.Type, this._sharedService.selectedContainer.ASN,
            this._sharedService.getFileNameFromURL(_selDoc.Url, true), // this.getFileName(selectedRows[i].Url),
            _selDoc.DocType, _selDoc.DocDate, _selDoc.DocNo, null, null, _selDoc.WONumber,null,false,null,_selDoc.Uuid));
        return blobList;
    }

    formatZipFileName(fileURL: string, fileName: string) {
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = fileURL;
        a.target = '_blank';
        a.download = fileName;
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
    }

    zoomChange(event: any) {
        if (this._sharedService.searchText && this._sharedService.searchText.indexOf('"') == -1) {
            if (this.selectedUrl.indexOf('api/Blob/GetDocument/') != -1) {
                this.LoadDocument(this.selectedUrl, false);
            } else {
                this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(this.selectedUrl + this.selectedOption + '&search="' + this._sharedService.searchText + '"');
            }

        } else {
            if (this.selectedUrl.indexOf('api/Blob/GetDocument/') != -1) {
                this.LoadDocument(this.selectedUrl, true);
            } else {
                this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(this.selectedUrl + this.selectedOption + '&search="' + this._sharedService.searchText + '"&phrase=true');
            }
        }
        this.selectedZoom = this.selectedOption;
    }

    getZoomOptions() {
        this.zoomOption.push({ value: '#zoom=auto', label: 'Automatic Zoom' })
        this.zoomOption.push({ value: '#zoom=scale', label: 'Actual Size' })
        this.zoomOption.push({ value: '#zoom=50', label: 'Page Fit' })
        this.zoomOption.push({ value: '#zoom=auto', label: 'Page Width' })
        this.zoomOption.push({ value: '#zoom=50', label: '50%' })
        this.zoomOption.push({ value: '#zoom=100', label: '100%' })
        this.zoomOption.push({ value: '#zoom=200', label: '125%' })
        this.zoomOption.push({ value: '#zoom=150', label: '150%' })
        this.zoomOption.push({ value: '#zoom=200', label: '200%' })
        this.zoomOption.push({ value: '#zoom=300', label: '300%' })
        this.zoomOption.push({ value: '#zoom=400', label: '400%' })
    }

    //#region Wide / Full Screen
    wideStatusViewListPanalTotle = "Expand half";
    wideStatusViewListPanal() {
        let viewListPanel = document.getElementById('listcol1');
        let viewManualFilter = document.getElementById('viewManualFilter');
        let pdfPanel = document.getElementById('listcol3');
        let lastUpdatedSpan = document.getElementById('lastUpdatedSpan');

        if (viewListPanel.classList.contains('col-sm-6') || viewListPanel.classList.contains('col-sm-3')) {
            // Remove old Classes
            viewListPanel.classList.remove('col-sm-6', 'col-md-6', 'col-lg-6', 'col-sm-3', 'col-md-3', 'col-lg-3');
            if (viewManualFilter) { viewManualFilter.classList!.remove('col-sm-12', 'col-md-12', 'col-lg-12'); }
            pdfPanel.classList.remove('col-sm-9', 'col-md-9', 'col-lg-9');
            //Add new Classes
            viewListPanel.classList.add('col-sm-12', 'col-md-12', 'col-lg-12');
            if (viewManualFilter) { viewManualFilter.classList!.add('col-sm-6', 'col-md-6', 'col-lg-6'); }
            pdfPanel.classList.add('col-sm-6', 'col-md-6', 'col-lg-6');
            pdfPanel.style.display = 'none';
            this.wideStatusViewListPanalTotle = "Back to normal view";
        } else {
            // Remove old Classes
            viewListPanel.classList.remove('col-sm-12', 'col-md-12', 'col-lg-12');
            if (viewManualFilter) { viewManualFilter.classList!.remove('col-sm-6', 'col-md-6', 'col-lg-6'); }
            pdfPanel.classList.remove('col-sm-9', 'col-md-9', 'col-lg-9');
            //Add new Classes
            viewListPanel.classList.add('col-sm-6', 'col-md-6', 'col-lg-6');
            if (viewManualFilter) { viewManualFilter.classList!.add('col-sm-12', 'col-md-12', 'col-lg-12'); }
            pdfPanel.classList.add('col-sm-6', 'col-md-6', 'col-lg-6');
            pdfPanel.style.display = 'block';
            this.wideStatusViewListPanalTotle = "Expand half";
            // Enable Full Screen Button
            // btnFullScreen.classList.remove('disable-anchor');
        }

        lastUpdatedSpan.style.display = 'block';
    }

    wideDocPanalTitle = "Expand half";
    wideDocPanal() {
        let viewListPanel = document.getElementById('listcol1');
        let viewManualFilter = document.getElementById('viewManualFilter');
        let pdfPanel = document.getElementById('listcol3');
        let lastUpdatedSpan = document.getElementById('lastUpdatedSpan');

        if (viewListPanel.classList.contains('col-sm-6')) {
            // Remove old Classes
            viewListPanel.classList.remove('col-sm-6', 'col-md-6', 'col-lg-6');
            if (viewManualFilter) { viewManualFilter.classList!.remove('col-sm-6', 'col-md-6', 'col-lg-6'); }
            pdfPanel.classList.remove('col-sm-6', 'col-md-6', 'col-lg-6');
            //Add new Classes
            viewListPanel.classList.add('col-sm-3', 'col-md-3', 'col-lg-3');
            if (viewManualFilter) { viewManualFilter.classList!.add('col-sm-12', 'col-md-12', 'col-lg-12'); }
            pdfPanel.classList.add('col-sm-9', 'col-md-9', 'col-lg-9');
            this.wideDocPanalTitle = "Back to normal view";
            lastUpdatedSpan.style.display = 'none';

        } else {
            // Remove old Classes
            viewListPanel.classList.remove('col-sm-3', 'col-md-3', 'col-lg-3');
            pdfPanel.classList.remove('col-sm-9', 'col-md-9', 'col-lg-9');
            if (viewManualFilter) { viewManualFilter.classList!.remove('col-sm-12', 'col-md-12', 'col-lg-12'); }
            //Add new Classes
            viewListPanel.classList.add('col-sm-6', 'col-md-6', 'col-lg-6');
            if (viewManualFilter) { viewManualFilter.classList!.add('col-sm-6', 'col-md-6', 'col-lg-6'); }
            pdfPanel.classList.add('col-sm-6', 'col-md-6', 'col-lg-6');
            lastUpdatedSpan.style.display = 'block';
            this.wideDocPanalTitle = "Expand half";
        }
        if (this.fileType == 'image/png') {
            // this.confirmationService.confirm({
            //     key: 'confirmDlg',
            //     message: 'Do you want to fit image size to width, unsaved Data will be lost.  Do you still want to continue?',
            //     accept: () => {
            //         // this.resetCanvasAndControls();
            //         this.updateTaskDoc({value: this.selectedTaskDoc.TaskDocumentId});
            //     },
            //     reject: () => {

            //     }
            // });
            let objArray = this.getFormatedObjectArray();
            let objArrayToRender = this.getFormatedObjectArrayToRender();
            this.selectedTaskDoc.OtherDetail = JSON.stringify(objArrayToRender);//this.canvas;
            let selectedMarkerTypeFilter = this.selectedMarkerTypeFilter;

            this.resetCanvasAndControls('image/png');
            this.initCanvas();
            this.resetBackgroundImage(false);
            if (selectedMarkerTypeFilter.Name == 'Current') {
                this.drawCanvasObject(this.selectedTaskDoc, false);
            } else {
                this.selectedMarkerTypeFilter = selectedMarkerTypeFilter;
                if (this.renderedTaskDocumentList && this.renderedTaskDocumentList.length > 0) {
                    for (let index = 0; index < this.renderedTaskDocumentList.length; index++) {
                        this.drawCanvasObject(this.renderedTaskDocumentList[index], false);
                    }
                }
            }

            this.selectedTaskDoc.OtherDetail = JSON.stringify(objArray);//this.canvas;
            //this.updateTaskDoc({value: this.selectedTaskDoc.TaskDocumentId});

        }

    }

    fullScreenPdfPanal() {
        let containerPanel, docPanel;
        let pdfPanel = document.getElementById('col3');
        let btnWideScreen = document.getElementById('btnPdfWideScreen');

        if (this._router.url.indexOf("/record/search") == -1) {
            containerPanel = document.getElementById('col1');
            docPanel = document.getElementById('col2');
        }
        else if (this._router.url.indexOf("/transition/search") == -1) {
            containerPanel = document.getElementById('col1');
            docPanel = document.getElementById('col2');
        }
        else {
            containerPanel = document.getElementById('search-col1');
            docPanel = document.getElementById('search-col2');
        }

        if (pdfPanel.classList.contains('col-sm-6')) {
            // Remove old Classes
            pdfPanel.classList.remove('col-sm-6', 'col-md-6', 'col-lg-6');
            // Add new Classes
            pdfPanel.classList.add('col-sm-12', 'col-md-12', 'col-lg-12');
            containerPanel.style.display = 'none';
            docPanel.style.display = 'none';

            // Disable Wide Screen Button
            btnWideScreen.classList.add('disable-anchor');

        } else {
            // Remove old Classes
            pdfPanel.classList.remove('col-sm-12', 'col-md-12', 'col-lg-12');
            // Add new Classes
            pdfPanel.classList.add('col-sm-6', 'col-md-6', 'col-lg-6');
            containerPanel.style.display = 'block';
            docPanel.style.display = 'block';

            // Enable Wide Screen Button
            btnWideScreen.classList.remove('disable-anchor');
        }
    }

    //#endregion

    // setDisplayMode() {
    //    let url = this._router.url;
    //    if (url.indexOf("/record/") > -1) {
    //        this.displayMode = "record";

    //    } else if (url.indexOf("/status/") > -1) {
    //        this.displayMode = "status";

    //    } else if (url.indexOf("/admin/") > -1) {
    //        this.displayMode = "admin";
    //    }
    // }



    //#endregion

    //#region Issue Details
    setIssueGridColumns(): void {
        this.issueColumnDefs = [];
        this.issueColumnDefs =
            [
                { headerName: 'Issue Id', field: 'IssueId', menuTabs: ['filterMenuTab'] },
                { headerName: 'Source Module', field: 'SourceModule', menuTabs: ['filterMenuTab'] },
                { headerName: 'Requested By', field: 'Requestedby', menuTabs: ['filterMenuTab'] },
                { headerName: 'Requested By', field: 'Requestedby', menuTabs: ['filterMenuTab'] },
                { headerName: 'Priority', field: 'Priority', menuTabs: ['filterMenuTab'] },
                { headerName: 'Due Date', field: 'DueDate', menuTabs: ['filterMenuTab'] },
                {
                    headerName: 'Status', field: 'Status', filter: false, suppressMenu: true, valueFormatter: function (params: any) {
                        if (params.value == true) {
                            return "Active";
                        }
                        else {
                            return "Inactive";
                        }
                    }
                }
            ];

    }
    // populateIssueDetailGrid(value: any): void {
    //    let body: issue = {
    //        SourceReference: "1"
    //    }

    //    this._issueService.getIssueDetail(body).subscribe( response => {
    //        this.issueGridData = [];
    //        this.issueGridData = response;
    //        this.issueGridOptions.api.sizeColumnsToFit();
    //        this.displayissuedialogue = true;
    //    });

    // }
    btnCancelDialogueClick() {

        this.displayissuedialogue = false;
    }
    // onIssueCellClicked(event: any) {
    //    this.populateIssueDetailGrid(event.value);
    // }
    showInboxPopup() {
        if (this.gridData && this.gridData.length) {
            this.commobj = new CommPartial();
            //US-8181 //this.commobj.ASN = this._sharedService.selectedAssetStatus[0].data.split('|')[1];
            this.commobj.ASN = JSON.parse(this._sharedService.selectedAssetStatus[0].data).ASN;
            this.commobj.Module = this.isInRepairModule ? "Repairs" : "Status";
            this.commobj.Record = this._sharedService.selectedTaskID;
            this.commobj.Section = this.ViewSettingId;
            this.commobj.TaskId = this._sharedService.selectedTaskID;
            this._sharedService.setModulesSelectedOptionForComm(this.commobj);

            this.displayissuedialogue = true;

        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No row selected', detail: 'Please select a row' });
        }
    }
    //#endregion

    //#region Roles
    UpdateRoles() {
        //if (!this._sharedService.UserRole) {
        //this._sharedService.updateRole();
        //}

        if (this._sharedService.selectedAssetStatus && this._sharedService.selectedAssetStatus[0]) {
            this._sharedService.updateRoleBySelectedAsset(this._sharedService.selectedAssetStatus[0]);
        }
        else {
            this._sharedService.updateRole();
        }

        if (this._router.url.indexOf("status/") != -1) {
            this.targetType = "Status"
        }
        if ((this._router.url.indexOf("status/") != -1 &&
            //this._sharedService.UserRole["Status_Dashboard"] && 
            this._sharedService.UserRole["Status_Readonly"]
        ) ||
            (this._router.url.indexOf("repair/") != -1 &&
                this._sharedService.UserRole["Repairs_Readonly"]
            )) {



            let isInStatusModule = (this._router.url.indexOf('/status/') != -1) ? true : false;

            // Update DFP Status Role
            this.canUpdateDFPStatus = isInStatusModule ? this._sharedService.UserRole.Status_Row_Edit_DFPStatus : this._sharedService.UserRole.Repairs_Row_Edit_DFPStatus;

            // Attach Document Role
            this.canLinkDocumentStatus = isInStatusModule ? this._sharedService.UserRole.Status_Row_LinkDocument : this._sharedService.UserRole.Repairs_Row_LinkDocument;

            // Run now Auto search Role
            this.canRunSearchAutoLink = isInStatusModule ? this._sharedService.UserRole.Status_Row_Batch_LinkDocument : this._sharedService.UserRole.Repairs_Row_Batch_LinkDocument;

            // Attach Document Role
            this.canViewHistory = isInStatusModule ? this._sharedService.UserRole.Status_Row_History : this._sharedService.UserRole.Repairs_Row_History;

            // Add Edit Delete Task Role
            this.canAddTask = isInStatusModule ? this._sharedService.UserRole.Status_Row_Add : this._sharedService.UserRole.Repairs_Row_Add;
            this.canEditTask = isInStatusModule ? this._sharedService.UserRole.Status_Row_Edit : this._sharedService.UserRole.Repairs_Row_Edit;
            this.canDeleteTask = isInStatusModule ? this._sharedService.UserRole.Status_Row_Delete : this._sharedService.UserRole.Repairs_Row_Delete;

            // Status Audit
            this.canViewStatusAudit = isInStatusModule ? this._sharedService.UserRole.Status_Row_Audit : this._sharedService.UserRole.Repairs_Row_Audit;

            if (this._router.url.indexOf("record/browse") != -1) {
                this.canViewInRecord = false;
            }
            else {
                this.canViewInRecord = true;
            }
            this.canDetachDocumentStatus = isInStatusModule ? this._sharedService.UserRole.Status_Row_Doc_Detach : this._sharedService.UserRole.Repairs_Row_Doc_Detach;
            this.canUploadDocumentStatus = isInStatusModule ? this._sharedService.UserRole.Status_Row_Doc_Upload : this._sharedService.UserRole.Repairs_Row_Doc_Upload;
            this.canEditDocumentStatus = isInStatusModule ? this._sharedService.UserRole.Status_Row_Doc_Edit_MetaData : this._sharedService.UserRole.Repairs_Row_Doc_Edit_MetaData;

            // View feed button
            this.canViewFeedFromStatus = this._sharedService.UserRole.API_Import_Readonly;
        }
        else {
            this._sharedService.clearToast();
        }
    }
    //#endregion

    // #region MISC
    setCalenderDateFormat() {
        let dateParts: string[] = dateTimeConfig.dateformat.split(dateTimeConfig.dateSeparator);

        if (dateTimeConfig.year == 0) {
            this.dateFormatCalender = (dateParts[dateTimeConfig.year].substr(0, 2) + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day]).toLowerCase();
        }
        else if (dateTimeConfig.year == 2) {
            if (dateTimeConfig.month == 1) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
            else if (dateTimeConfig.month == 0) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
        }
        else {
            this.dateFormatCalender = "yy-mm-dd"
        }

    }


    displayMessages(msg: any) {
        this.displayissuedialogue = false;
        this._sharedService.clearToast();
        this._sharedService.showToast({ severity: msg.Severity, summary: msg.Summary, detail: msg.Detail });
        // Update detail Row Comms Status
        if (isDetailGridRowSelected) {
            let selectedRows = detailGridApi.getSelectedRows();
            if (selectedRows && selectedRows.length > 0) {
                let rowNode = detailGridApi.getRowNode(selectedRows[0].TaskID);
                if (this.gridOptions.columnApi.getColumn("Comms")) {
                    rowNode.setDataValue("Comms", msg.commStatus);
                }
                if (this.gridOptions.columnApi.getColumn("CommSummary")) {
                    let body: any = {
                        TaskID: this._sharedService.selectedTaskID,
                        ViewSettingID: rowNode.data.ViewSettingId
                    };

                    this._taskService.getTaskByIDForEdit(body)
                        .subscribe({
                            next: (response) => {
                                rowNode.setDataValue("CommSummary", response.TaskEntity.CommSummary);
                                rowNode.setRowHeight(175);
                                this.gridOptions.api.onRowHeightChanged();
                            },
                            error: (error) => {
                                this._sharedService.clearToast();
                                this._sharedService.showToast({ severity: 'error', summary: 'error', detail: "Cannot get the task's details, please try again." });
                            }
                        });
                }
            }

        } else {
            // Update master Row Comms Status
            let taskID = this._sharedService.getModulesSelectedOptionForComm()?.TaskId;
            if (taskID) {
                let rowNode = this.api.getRowNode(taskID.toString());
                if (this.gridOptions.columnApi.getColumn("Comms")) {
                    rowNode.setDataValue("Comms", msg.commStatus);
                }

                if (this.gridOptions.columnApi.getColumn("CommSummary")) {
                    let body: any = {
                        TaskID: this._sharedService.selectedTaskID,
                        ViewSettingID: rowNode.data.ViewSettingId
                    };

                    this._taskService.getTaskByIDForEdit(body)
                        .subscribe({
                            next: (response) => {
                                rowNode.setDataValue("CommSummary", response.TaskEntity.CommSummary);
                                rowNode.setRowHeight(175);
                                this.gridOptions.api.onRowHeightChanged();
                            },
                            error: (error) => {
                                this._sharedService.clearToast();
                                this._sharedService.showToast({ severity: 'error', summary: 'error', detail: "Cannot get the task's details, please try again." });
                            }
                        });
                }
            }
        }
    }
    // #endregion

    // #region Export

    exportGridData() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }

        if (this.gridOptions.rowData.length > 0) {
            let fileName = this.ViewModel.DisplayName + ".csv";
            var params: CsvExportParams = {
                skipColumnHeaders: false,
                skipColumnGroupHeaders: true,
                skipRowGroups: false,
                // skipPinnedTop: false,
                // skipPinnedBottom: false,
                allColumns: false,
                onlySelected: false, // true
                suppressQuotes: false,
                fileName: fileName,
                columnKeys: this.ViewModel.Columns.filter(c => c.FieldName != 'rTask' && c.FieldName != 'LatestRecord' && c.ColumnWidth != "'0'").map(row => { return row.FieldName }),
                columnSeparator: ',',
                processCellCallback: function (params: any) {
                    if (params.column.colDef.field.includes("DateTime")) {
                        if (params.value) {
                            var date: Date = params.value;
                            var formattedDate = moment(date).format(dateTimeConfig.dateformat.toUpperCase() + " h:mm:ss").toString();
                            return formattedDate;
                        }
                        else {
                            return params.value;
                        }
                    }
                    else if (params.column.colDef.headerName.indexOf('Date') != -1 || params.column.colDef.field == 'ModifiedOn' || params.column.colDef.field == 'CreatedOn') {
                        if (params.value) {
                            var date: Date = params.value;
                            var formattedDate = moment(date).format(dateTimeConfig.dateformat.toUpperCase());
                            return formattedDate;
                        }
                        else {
                            return params.value;
                        }
                    }

                    else if (params.column.colDef.field == 'CommSummary' || params.column.getColId() == "CommSummary") {
                        params.value = this.prepareCommSummaryForExport(params.value);
                        return params.value;
                    } else {
                        return params.value;
                    }

                }.bind(this)
            };
            this.gridOptions.api.exportDataAsCsv(params);
        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Document', detail: 'There is no  document to export' });
        }
    }

    prepareCommSummaryForExport(commSummaryJSON: string): string {
        let finalCommSummary = "";
        if (commSummaryJSON) {
            let comObjs = JSON.parse(commSummaryJSON);
            if (comObjs.length > 0) {
                comObjs.forEach(comm => {
                    var _requestedByName = comm.RequestedByName ? " - Requested By: " + comm.RequestedByName : '';
                    var _assignedToName = comm.AssignedToName ? " -  Assigned To: " + comm.AssignedToName : '';
                    var _comStatusDescription = comm.CommStatusDescription ? " - " + comm.CommStatusDescription : '';
                    comm.Description = comm.Description ? comm.Description.replace(/<[^>]*>/g, '') : '';
                    comm.Conversation = comm.Conversation ? comm.Conversation : '';

                    let _commSummary = comm.CommId + (comm.DueDate ? " - Due Date: " + this.getFormatedDate(comm.DueDate) : "") + _requestedByName + _assignedToName + _comStatusDescription + " \n " +
                        " Subject: " + comm.Subject + " \n" +
                        " Description: " + comm.Description + " \n" +
                        " Coversations: " + comm.Conversation + " \n";
                    finalCommSummary += _commSummary.replace("?|?", "");
                });
                finalCommSummary = finalCommSummary.replace("?|?", "");
            }
        }
        return finalCommSummary;
    }

    getDisplayedColumnNames(): any[] {
        //var columns = this.gridOptions.columnApi.getAllDisplayedColumns()[0].colDef.field

        var displayedColumns = this.gridOptions.columnApi.getAllDisplayedColumns();//[0].colDef.field
        let columnFields: any[] = [];

        displayedColumns.forEach(function (col, index) {
            columnFields.push(col.getColId());//colDef.field);
        });

        return columnFields;
    }
    exportExcelGrid() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            var columns = this.ViewModel.Columns.filter(c => c.FieldName != 'rTask' && c.FieldName != 'LatestRecord' && c.ColumnWidth != "'0'").map(row => { return row.FieldName });
            //var columns1 = this.getDisplayedColumnNames();
            // columns=columns.filter(c => c != 'rTask' && c != 'LatestRecord' && c.ColumnWidth != "'0'").map(row => { return row.FieldName });

            if (this.gridOptions.rowData.length > 0) {
                var params: any = {
                    skipColumnGroupHeaders: true,
                    allColumns: false,
                    onlySelected: false,
                    fileName: this.ViewModel.DisplayName + ".xlsx",
                    sheetName: this.ViewModel.AssetType,
                    columnWidth: 100,
                    columnKeys: columns,
                    processHeaderCallback: function (params) {
                        return params.column.getColDef().headerName;
                    },
                    processCellCallback: function (params: any) {
                        if (params.column.colDef.field.includes("DateTime")) {
                            if (params.value) {
                                var date: Date = params.value;
                                var formattedDate = moment(date).format(dateTimeConfig.dateformat.toUpperCase() + " h:mm:ss").toString();
                                return formattedDate;
                            }
                            else {
                                return params.value;
                            }
                        }
                        else if (params.column.colDef.headerName.indexOf('Date') != -1 ||
                            params.column.colDef.field.includes("Date") ||
                            params.column.colDef.field == 'ModifiedOn' ||
                            params.column.colDef.field == 'CreatedOn') {
                            if (params.value) {
                                var date: Date = params.value;
                                var formattedDate = moment(date).format(dateTimeConfig.dateformat.toUpperCase());
                                return formattedDate;
                            }
                            else {
                                return params.value;
                            }
                        }
                        else if (params.column.colDef.field == 'CommSummary' || params.column.getColId() == "CommSummary") {
                            params.value = this.prepareCommSummaryForExport(params.value);
                            return params.value;
                        }
                        else {
                            return params.value;
                        }
                    }.bind(this)
                };
                //this.gridOptions.api.exportDataAsExcel(params);
                this.setCustomHeaderAndExport(params);

            } else {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'No Document', detail: 'There is no  document to export' });
            }
        }
    }

    setCustomHeaderAndExport(params: any) {
        var ExcelGridExport = this.selectedViewData.View.ExcelGridExport
        if (ExcelGridExport == null || ExcelGridExport == undefined || ExcelGridExport == "") {
            this._sharedService.getConfigurationByKey("Excel_Export_Header_Status")
                .subscribe({
                    next: (response) => {
                        let result = response;
                        let headerString = result.Value ? result.Value : '';
                        headerString = headerString.replace('{TenantName}', this._sharedService.tenantName);
                        headerString = headerString.replace('{ViewTitle}', this.ViewModel.DisplayName);
                        headerString = headerString.replace('{AirframeTitle}', this.ViewModel.AssetType == 'Airframe' || this.ViewModel.AssetType == 'Helicopter' ? this._sharedService.selectedAssetStatus[0].label : this._sharedService.selectedAssetStatus[0].parent.label);
                        headerString = headerString.replace('{AssetType}', this.ViewModel.AssetType);
                        headerString = headerString.replace('{AssetTitle}', this._sharedService.selectedAssetStatus[0].label);
                        headerString = headerString.replace('{ASN}', this.asn);
                        headerString = headerString.replace('{UserName}', this._sharedService.getCurrentUserName());
                        headerString = headerString.replace('{DateTime}', this.getFormatedDate(new Date()));
                        headerString = headerString.replace('{Filter}', this.getFilterString());
                        const getRows: () => ExcelRow[] = () => JSON.parse(headerString);
                        params.prependContent = getRows();
                        setTimeout(() => {
                            this.gridOptions.api.exportDataAsExcel(params);
                        }, 500);
                    },
                    error: (error) => {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while downloading.' });
                        console.log(error);
                    }
                });
        }
        else {
            let headerString = ExcelGridExport;
            headerString = headerString.replace('{TenantName}', this._sharedService.tenantName);
            headerString = headerString.replace('{ViewTitle}', this.ViewModel.DisplayName);
            headerString = headerString.replace('{AirframeTitle}', this.ViewModel.AssetType == 'Airframe' || this.ViewModel.AssetType == 'Helicopter' ? this._sharedService.selectedAssetStatus[0].label : this._sharedService.selectedAssetStatus[0].parent.label);
            headerString = headerString.replace('{AssetType}', this.ViewModel.AssetType);
            headerString = headerString.replace('{AssetTitle}', this._sharedService.selectedAssetStatus[0].label);
            headerString = headerString.replace('{ASN}', this.asn);
            headerString = headerString.replace('{UserName}', this._sharedService.getCurrentUserName());
            headerString = headerString.replace('{DateTime}', this.getFormatedDate(new Date()));
            headerString = headerString.replace('{Filter}', this.getFilterString());
            const getRows: () => ExcelRow[] = () => JSON.parse(headerString);
            params.prependContent = getRows();
            this.gridOptions.api.exportDataAsExcel(params);
        }
    }

    setExcelStyle() {
        this.excelStyles = [
            {
                id: "greenBackground",
                interior: {
                    color: "#90ee90",
                    pattern: "Solid"
                }
            },
            {
                id: "redFont",
                font: {
                    underline: "Single",
                    italic: true,
                    color: "#ff0000"
                }
            },
            {
                id: "darkGreyBackground",
                interior: {
                    color: "#888888",
                    pattern: "Solid"
                }
            },
            {
                id: "boldBorders",
                borders: {
                    borderBottom: {
                        color: "#000000",
                        lineStyle: "Continuous",
                        weight: 3
                    },
                    borderLeft: {
                        color: "#000000",
                        lineStyle: "Continuous",
                        weight: 3
                    },
                    borderRight: {
                        color: "#000000",
                        lineStyle: "Continuous",
                        weight: 3
                    },
                    borderTop: {
                        color: "#000000",
                        lineStyle: "Continuous",
                        weight: 3
                    }
                }
            },
            {
                id: "header",
                interior: {
                    color: "#CCCCCC",
                    pattern: "Solid"
                }
            },
            {
                id: "twoDecimalPlaces",
                numberFormat: { format: "#,##0.00" }
            },
            {
                id: "textFormat",
                dataType: "string"
            },
            {
                id: "bigHeader",
                font: {
                    size: 16,
                    bold: true
                },
                alignment: {
                    horizontal: 'Center', vertical: 'Center'
                }
            }
        ];
    }

    getFilterString(): string {
        let filterModel = this.api.getFilterModel();
        let filterString = '';
        for (var filter in filterModel) {
            let column: any = this.gridOptions.columnApi.getColumn(filter);
            filterString = filterString.concat(column.colDef.headerName, ', ');
        }

        return filterString ? filterString.slice(0, -2) : 'No';
    }
    // showBulkExport(dataType: string) {
    //    if (!this._sharedService.isAuthUser()) {
    //        this._router.navigate(['login']);
    //    }
    //    else {
    //        if (this._sharedService.selectedAssetStatus && this._sharedService.selectedAssetStatus.length > 0)
    //        {
    //            let feedData: datafeed =
    //                {
    //                    DataType: dataType,
    //                    FeedName: this._apiService.getTempFeedName(),
    //                    FeedType: "ExportTemp",
    //                    Interval: "None",
    //                    SourceType: "Status",
    //                    SourceDetail: this._sharedService.selectedAssetStatus[0].data.split('|')[1],
    //                    TargetType: 'Email Link',
    //                    TargetDetail: '',
    //                    Status: true
    //                }
    //            this._apiService.addFeedAndReturnFeedID(feedData).subscribe( response => {
    //                let feedDataId = response;
    //                this._sharedService.statusConfig = new StatusConfig(Number(feedDataId), feedData.FeedName, feedData.DataType, feedData.SourceDetail);
    //                this.OpencloseConfigDialogue(true);
    //            },
    //                error => {
    //                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Error Occurred, please try again.' });
    //                });
    //        }
    //        else {
    //            this._sharedService.clearToast();
    //            this._sharedService.showToast({ severity: 'warn', summary: 'No Asset Selected', detail: 'Please select an asset' });
    //        }
    //    }


    // }
    // OpencloseConfigDialogue(flag: boolean) {
    //    this.displayConfigdialogue = flag;
    // }
    // adjustConfigDlgHeight(event: any) {
    //    let tskDlgfrm = document.getElementById('statusConfigDlg');
    //    let tskDlgfrm1 = document.getElementById('tblStatusMapping');
    //    if (tskDlgfrm) {
    //        tskDlgfrm.style.height = '543px';
    //        tskDlgfrm1.style.height = '60vh';
    //        tskDlgfrm.style.overflowX = 'hidden';
    //    }
    // }
    // #endregion

    changeContainerFilterState(): void {
        this.populateGrid();
    }

    // #region Audit

    showTaskHistory() {

        let parentCount: number = _gridOptions.api ? _gridOptions.api!.getSelectedNodes()!.length : 0;
        let childCount: number = 0;

        _gridOptions.api.forEachDetailGridInfo(function (detailGridInfo) {
            childCount += detailGridInfo ? detailGridInfo.api!.getSelectedNodes()!.length : 0;
        });
        let totalTaskCount = parentCount + childCount;
        // console.log("totalTaskCount: ", totalTaskCount);

        if (totalTaskCount > 1) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Multiple Records Selected', detail: 'Please select only 1 record!' });
            return;
        }

        // let pCount: number = _gridOptions.api ? _gridOptions.api!.getSelectedNodes()!.length : 0;
        // let cCount: number = detailGridApi ? detailGridApi!.getSelectedNodes()!.length : 0;


        let auditCong: AuditModel = new AuditModel();
        auditCong.TableName = "Task";
        // if (IsDetailRowClicked) {
        //     let selRow = detailGridApi.getSelectedRows();
        //     auditCong.PK = selRow[0].TaskID;
        // }
        // else {
        //     let selRow = this.api.getSelectedRows();
        //     auditCong.PK = selRow[0].TaskID;
        // }

        let selRow = this.api.getSelectedRows(); // Main Grid
        if (selRow.length > 0) {
            auditCong.PK = selRow[0].TaskID;
        } else {
            selRow = detailGridApi.getSelectedRows(); // Detail Grid
            auditCong.PK = selRow[0].TaskID;
        }

        this._sharedService.showAuditHistory(auditCong);
    }
    // #endregion

    // #region Scroll Position
    setScrollPostition() {
        let elmnt = document.getElementById("tskDlgfrm");
        elmnt.scrollTop = 0;
    }
    // #endregion

    getDFPStatus() {

        this._dashboardService.getAllDFPStatuses()
            .subscribe({
                next: (response) => {
                    dfpStatusesTask = [];
                    dfpStatusesTask = response;
                }
            });

    }
    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];
        let childResultDownload: any[] = [];

        if (!params.context.mainGridCtx.feedlogid && params.context.mainGridCtx.canViewInRecord && params.context.mainGridCtx.canAddTask) {
            result.push({
                name: "Add Entry",
                action: function () {
                    if (params.node) {
                        params.context.mainGridCtx._sharedService.UpdateTaskAdd(true);
                    }

                },
                icon: params.context.mainGridCtx._sharedService.addIconHTMLasString,
            });
        }
        if (!params.context.mainGridCtx.feedlogid && params.context.mainGridCtx.canViewInRecord && params.context.mainGridCtx.canEditTask) {
            result.push({
                name: "Edit Entry",
                action: function () {
                    if (params.node) {
                        params.context.mainGridCtx.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.mainGridCtx.onTaskSelectionChanged(null);
                        params.context.mainGridCtx._sharedService.UpdateTaskEdit(true);
                    }

                },
                icon: params.context.mainGridCtx._sharedService.editIconHTMLasString,
            });
        }

        if (!params.context.mainGridCtx.feedlogid && params.context.mainGridCtx.canViewInRecord && params.context.mainGridCtx.canDeleteTask) {
            result.push({
                name: "Delete Entry",
                action: function () {
                    if (params.node) {
                        params.context.mainGridCtx.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.mainGridCtx.onTaskSelectionChanged(null);
                        params.context.mainGridCtx.showDeleteTask();
                    }

                },
                icon: params.context.mainGridCtx._sharedService.trashIconHTMLasString,
            });
        }

        if (!params.context.mainGridCtx.feedlogid && params.context.mainGridCtx.canViewInRecord && params.context.mainGridCtx.canViewStatusAudit) {
            result.push({
                name: "Show History",
                action: function () {
                    if (params.node) {
                        params.context.mainGridCtx.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.mainGridCtx.onTaskSelectionChanged(null);
                        params.context.mainGridCtx.showTaskHistory();
                    }

                },
                icon: params.context.mainGridCtx._sharedService.copyIconHTMLasString,
            });
        }
        if (!params.context.mainGridCtx.feedlogid && params.context.mainGridCtx.canViewInRecord) {

            childResultDownload.push({
                name: "Excel",
                action: function () {
                    params.context.mainGridCtx.exportExcelGrid();
                },
                icon: params.context.mainGridCtx._sharedService.downloadLogFeedIconHTMLasString,
            });

            childResultDownload.push({
                name: "CSV",
                action: function () {

                    params.context.mainGridCtx.exportGridData();

                },
                icon: params.context.mainGridCtx._sharedService.saveLogFeedIconHTMLasString,
            });

            result.push({
                name: "Download",
                action: function () {


                },
                icon: params.context.mainGridCtx._sharedService.downloadsFeedIconHTMLasString,
                subMenu: childResultDownload,
            });

        }
        return result;


    }

    getCustomContextMenuItemsDetail(params: any): any {
        let result: any[] = [];
        let childResultDownload: any[] = [];

        if (!params.context.detailGridCtx.feedlogid && params.context.detailGridCtx.canViewInRecord && params.context.detailGridCtx.canAddTask) {
            result.push({
                name: "Add Entry",
                action: function () {
                    if (params.node) {
                        params.context.detailGridCtx._sharedService.UpdateTaskAdd(true);
                    }

                },
                icon: params.context.detailGridCtx._sharedService.addIconHTMLasString,
            });
        }
        if (!params.context.detailGridCtx.feedlogid && params.context.detailGridCtx.canViewInRecord && params.context.detailGridCtx.canEditTask) {
            result.push({
                name: "Edit Entry",
                action: function () {
                    if (params.node) {
                        detailGridApi.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.detailGridCtx.detailRowClicked(null);
                        params.context.detailGridCtx._sharedService.UpdateTaskEdit(true);;
                    }

                },
                icon: params.context.detailGridCtx._sharedService.editIconHTMLasString,
            });
        }

        if (!params.context.detailGridCtx.feedlogid && params.context.detailGridCtx.canViewInRecord && params.context.detailGridCtx.canDeleteTask) {
            result.push({
                name: "Delete Entry",
                action: function () {
                    if (params.node) {
                        detailGridApi.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.detailGridCtx.detailRowClicked(null);
                        params.context.detailGridCtx.showDeleteTask();
                    }

                },
                icon: params.context.detailGridCtx._sharedService.trashIconHTMLasString,
            });
        }

        if (!params.context.detailGridCtx.feedlogid && params.context.detailGridCtx.canViewInRecord && params.context.detailGridCtx.canViewStatusAudit) {
            result.push({
                name: "Show History",
                action: function () {
                    if (params.node) {
                        detailGridApi.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.detailGridCtx.detailRowClicked(null);
                        params.context.detailGridCtx.showTaskHistory();
                    }

                },
                icon: params.context.detailGridCtx._sharedService.copyIconHTMLasString,
            });
        }
        if (!params.context.detailGridCtx.feedlogid && params.context.detailGridCtx.canViewInRecord) {

            childResultDownload.push({
                name: "Excel",
                action: function () {
                    params.context.detailGridCtx.exportExcelGrid();
                },
                icon: params.context.detailGridCtx._sharedService.downloadLogFeedIconHTMLasString,
            });

            childResultDownload.push({
                name: "CSV",
                action: function () {

                    params.context.detailGridCtx.exportGridData();

                },
                icon: params.context.detailGridCtx._sharedService.saveLogFeedIconHTMLasString,
            });

            result.push({
                name: "Download",
                action: function () {


                },
                icon: params.context.detailGridCtx._sharedService.downloadsFeedIconHTMLasString,
                subMenu: childResultDownload,
            });


        }
        return result;


    }
    //#endregion

    btnShowFeed(event: any) {
        if (!this.ViewSettingId) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No view Selected', detail: 'Please select a view.' });
            return;
        }
        this.showHideFeedDialogue(true);
    }

    showHideFeedDialogue(flag: boolean) {
        this.displayFeedDialog = flag;
    }


    //#region Fabricjs ImageViewer
    initCanvas(isFullScreen: boolean = false) {
        if (this.canvas) {
            this.canvas.clear();
            //this.canvas.dispose();
        }
        this.canvas = new fabric.Canvas('statusCanvas', { selection: false });

        //Reset Canvas Width
        this.canvas.setWidth(0);
        this.canvas.setHeight(0);
        this.zoomPercentage = 100;
        //Set Canvas Width
        let selectedElement = document.getElementById('documentview');
        if (!isFullScreen) {
            this.canvas.setWidth(Math.floor(selectedElement.clientWidth));
            this.canvas.setHeight(Math.floor(selectedElement.clientHeight - 25));
            if (selectedElement.classList.contains('fullscreen')) { selectedElement.classList.remove("fullscreen"); }
        } else {
            if (!selectedElement.classList.contains('fullscreen')) { selectedElement.classList.add("fullscreen"); }
            this.canvas.setWidth(Math.floor(selectedElement.clientWidth));
            this.canvas.setHeight(Math.floor(selectedElement.clientHeight));
        }
        this.selectedMarkerTypeFilter = this.markerTypeFilterList[1];
        this.canvas.preserveObjectStacking = true;

        this.isInEditMode = false;
        this.editModeText = 'Switch to Edit Mode';
        this.editModeIcon = 'fa fa-plus';

        //  this.populateMarkerType();

        // //this.initMouseZoomEvent()
        this.initMouseDownEvent();
        this.initMouseMoveEvent();
        this.initObjectMovingEvent();
        this.initObjectScalingEvent();
        this.initMouseUpEvent();
        this.initPathCreatedEvent();
        this.initMouseOverEvent();
        this.initMouseOutEvent();
    }

    disposeCanvas() {
        if (this.canvas) {
            this.canvas.clear();
            //this.canvas.dispose();
        }
    }

    addObject(objectType: string) {
        if (objectType == 'path') {
            this.canvas.isDrawingMode = true;
            this.canvas.freeDrawingBrush.color = this.selectedMarkerType.CSSClass;
            this.canvas.freeDrawingBrush.width = 1;
        } else {
            this.canvas.isDrawingMode = false;
        }
        this.objectType = objectType;
        this.isAddObject = true;

    }

    setCanvasBackgroundImage(imageUrl: any, initCanvas: boolean = false) {

        if (initCanvas) { this.initCanvas(); }

        if (imageUrl.indexOf('api/Blob/GetDocument/') != -1) {

            this._azureBlobService.getPDFBlob(imageUrl)
                .subscribe({
                    next: (res: any) => {
                        let safeurl = this.sanitiz.bypassSecurityTrustResourceUrl(URL.createObjectURL(res));
                        let url = URL.createObjectURL(res);

                        fabric.util.loadImage(url, (img) => {
                            if (!img) { return; }
                            this.currentImg = img;
                            let docPanal = document.getElementById('documentview');

                            let canvasDiv = document.getElementById('statusCanvasDiv');
                            canvasDiv.style.width = Math.floor(docPanal.clientWidth).toString() + 'px';
                            canvasDiv.style.height = Math.floor(docPanal.clientHeight - 25).toString() + 'px';

                            if (docPanal.clientWidth > this.currentImg.width) {
                                this.canvas.setWidth(Math.floor(docPanal.clientWidth));
                                canvasDiv.style.overflowX = 'hidden';
                            } else {
                                this.canvas.setWidth(Math.floor(this.currentImg.width));
                                canvasDiv.style.overflowX = 'scroll';
                            }

                            if (docPanal.clientHeight > this.currentImg.height) {
                                this.canvas.setHeight(Math.floor(docPanal.clientHeight));
                                canvasDiv.style.overflowY = 'hidden';
                            } else {
                                this.canvas.setHeight(Math.floor(this.currentImg.height));
                                canvasDiv.style.overflowY = 'scroll';
                            }

                            let imgObj = new fabric.Image(img);
                            this.canvas.setBackgroundImage(imgObj, this.canvas.renderAll.bind(this.canvas), {
                                // width: this.canvas.width,
                                // height: this.canvas.height,
                                originX: 'left',
                                originY: 'top',
                                // scaleX: this.canvas.width / img.width,
                                // scaleY: this.canvas.height / img.height
                            });

                            // let height = this.canvas.height / img.height;
                            // let width = this.canvas.width / img.width;      
                            // let delta = height < width? height : width;
                            // if(delta < 1){
                            //     this.btnZoomOut(delta);
                            // }

                            //}, null, 'anonymous');
                        }, { crossOrigin: 'anonymous' });
                    },
                    error: (err) => {
                        console.log(err);
                    }
                });
        } else {

            imageUrl = imageUrl.indexOf('?') == -1 ? imageUrl : imageUrl.split('?')[0];
            this._azureBlobService.getBlob(imageUrl)
                .subscribe({
                    next: (res: any) => {
                        //let safeurl = this.sanitiz.bypassSecurityTrustResourceUrl(URL.createObjectURL(res));
                        let url = URL.createObjectURL(res);

                        fabric.util.loadImage(url, (img) => {
                            if (!img) { return; }
                            this.currentImg = img;
                            let docPanal = document.getElementById('documentview');

                            let canvasDiv = document.getElementById('statusCanvasDiv');
                            canvasDiv.style.width = Math.floor(docPanal.clientWidth).toString() + 'px';
                            canvasDiv.style.height = Math.floor(docPanal.clientHeight - 25).toString() + 'px';

                            if (docPanal.clientWidth > this.currentImg.width) {
                                this.canvas.setWidth(Math.floor(docPanal.clientWidth));
                                canvasDiv.style.overflowX = 'hidden';
                            } else {
                                this.canvas.setWidth(Math.floor(this.currentImg.width));
                                canvasDiv.style.overflowX = 'scroll';
                            }

                            if (docPanal.clientHeight > this.currentImg.height) {
                                this.canvas.setHeight(Math.floor(docPanal.clientHeight));
                                canvasDiv.style.overflowY = 'hidden';
                            } else {
                                this.canvas.setHeight(Math.floor(this.currentImg.height));
                                canvasDiv.style.overflowY = 'scroll';
                            }

                            let imgObj = new fabric.Image(img);
                            this.canvas.setBackgroundImage(imgObj, this.canvas.renderAll.bind(this.canvas), {
                                // width: this.canvas.width,
                                // height: this.canvas.height,
                                originX: 'left',
                                originY: 'top',
                                // scaleX: this.canvas.width / img.width,
                                // scaleY: this.canvas.height / img.height
                            });

                            // let height = this.canvas.height / img.height;
                            // let width = this.canvas.width / img.width;      
                            // let delta = height < width? height : width;
                            // if(delta < 1){
                            //     this.btnZoomOut(delta);
                            // }

                            //}, null, 'anonymous');
                        }, { crossOrigin: 'anonymous' });
                    },
                    error: (err) => {
                        console.log(err);
                    }
                });

            // fabric.util.loadImage(imageUrl, (img) => {
            //     this.currentImg = img;
            //     let docPanal = document.getElementById('documentview');

            //     let canvasDiv = document.getElementById('statusCanvasDiv');
            //     canvasDiv.style.width = Math.floor(docPanal.clientWidth).toString() + 'px';
            //     canvasDiv.style.height = Math.floor(docPanal.clientHeight - 25).toString() + 'px';

            //     if(docPanal.clientWidth > this.currentImg.width){
            //         this.canvas.setWidth(Math.floor(docPanal.clientWidth));
            //         canvasDiv.style.overflowX = 'hidden';
            //     }else{
            //         this.canvas.setWidth(Math.floor(this.currentImg.width));
            //         canvasDiv.style.overflowX = 'scroll';
            //     }

            //     if(docPanal.clientHeight > this.currentImg.height){
            //         this.canvas.setHeight(Math.floor(docPanal.clientHeight));
            //         canvasDiv.style.overflowY = 'hidden';
            //     }else{
            //         this.canvas.setHeight(Math.floor(this.currentImg.height));
            //         canvasDiv.style.overflowY = 'scroll';
            //     }

            //     let imgObj = new fabric.Image(img);
            //     this.canvas.setBackgroundImage(imgObj, this.canvas.renderAll.bind(this.canvas),{
            //         // width: this.canvas.width,
            //         // height: this.canvas.height,
            //         originX: 'left',
            //         originY: 'top',
            //         // scaleX: this.canvas.width / img.width,
            //         // scaleY: this.canvas.height / img.height
            //     });

            //     // let height = this.canvas.height / img.height;
            //     // let width = this.canvas.width / img.width;      
            //     // let delta = height < width? height : width;
            //     // if(delta < 1){
            //     //     this.btnZoomOut(delta);
            //     // }

            // }, null, 'anonymous');
        }
    }

    resetBackgroundImage(fitToImageSize: boolean = true) {
        let imgObj = new fabric.Image(this.currentImg);
        if (fitToImageSize) {
            this.canvas.setWidth(Math.floor(this.currentImg.width));
            this.canvas.setHeight(Math.floor(this.currentImg.height));


        } else {
            let docPanal = document.getElementById('documentview');

            let canvasDiv = document.getElementById('statusCanvasDiv');
            canvasDiv.style.width = Math.floor(docPanal.clientWidth).toString() + 'px';
            canvasDiv.style.height = Math.floor(docPanal.clientHeight - 25).toString() + 'px';

            if (docPanal.clientWidth > this.currentImg.width) {
                this.canvas.setWidth(Math.floor(docPanal.clientWidth));
                canvasDiv.style.overflowX = 'hidden';
            } else {
                this.canvas.setWidth(Math.floor(this.currentImg.width));
                canvasDiv.style.overflowX = 'scroll';
            }

            if (docPanal.clientHeight > this.currentImg.height) {
                this.canvas.setHeight(Math.floor(docPanal.clientHeight));
                canvasDiv.style.overflowY = 'hidden';
            } else {
                this.canvas.setHeight(Math.floor(this.currentImg.height));
                canvasDiv.style.overflowY = 'scroll';
            }
        }

        this.canvas.setBackgroundImage(imgObj, this.canvas.renderAll.bind(this.canvas), {
            // width: this.canvas.width,
            // height: this.canvas.height,
            originX: 'left',
            originY: 'top',
            // scaleX: this.canvas.width / img.width,
            // scaleY: this.canvas.height / img.height
        });

        // let height = this.canvas.height / this.currentImg.height;
        // let width = this.canvas.width / this.currentImg.width;      
        // let delta = height < width? height : width;
        // if(delta < 1){
        //     this.btnZoomOut(delta);
        // }


    }

    randomId() {
        return Math.floor(Math.random() * 999999) + 1;
    }

    extend(obj, id) {
        obj.toObject = ((toObject) => {
            return function () {
                return fabric.util.object.extend(toObject.call(this), {
                    id
                });
            };
        })(obj.toObject);
    }

    btnClear() {
        let canvasObjects = this.canvas.getObjects();
        console.log(canvasObjects);

        for (let index = 0; index < canvasObjects.length; index++) {
            this.canvas.remove(canvasObjects[index]);
        }

    }


    btnZoomIn() {
        let delta = 1.1;//opt.e.deltaY;
        let zoom = this.canvas.getZoom();
        zoom = zoom * delta;
        if (zoom > 20 || this.zoomPercentage == 300) {
            zoom = this.canvas.getZoom();
            this.zoomPercentage = this.zoomPercentage;
        } else {
            this.zoomPercentage = this.zoomPercentage + 10;
            this.canvas.setZoom(zoom);

            let docPanal = document.getElementById('documentview');
            let canvasDiv = document.getElementById('statusCanvasDiv');
            canvasDiv.scrollTop = 0;
            canvasDiv.scrollLeft = 0;
            let canvasWidth = Math.floor(this.currentImg.width * zoom);
            let canvasHeight = Math.floor(this.currentImg.height * zoom);

            if (docPanal.clientWidth > canvasWidth) {
                this.canvas.setWidth(docPanal.clientWidth);
                canvasDiv.style.overflowX = 'hidden';
            } else {
                this.canvas.setWidth(canvasWidth);
                canvasDiv.style.overflowX = 'scroll';
            }

            if (docPanal.clientHeight > canvasHeight) {
                this.canvas.setHeight(docPanal.clientHeight);
                canvasDiv.style.overflowY = 'hidden';
            } else {
                this.canvas.setHeight(canvasHeight);
                canvasDiv.style.overflowY = 'scroll';
            }
        }
    }

    btnZoomOut(deltaNumber: any) {
        if (deltaNumber) {
            this.canvas.setZoom(deltaNumber);
            this.zoomPercentage = Math.round(deltaNumber * 100);
        } else {
            let delta = 1.1;//opt.e.deltaY;
            let zoom = this.canvas.getZoom();
            zoom = zoom / delta;
            if (zoom < 0.1 || this.zoomPercentage == 10) {
                zoom = this.canvas.getZoom();
                this.zoomPercentage = this.zoomPercentage;
            } else {
                this.zoomPercentage = this.zoomPercentage - 10;

                let docPanal = document.getElementById('documentview');
                let canvasDiv = document.getElementById('statusCanvasDiv');
                canvasDiv.scrollTop = 0;
                canvasDiv.scrollLeft = 0;
                let canvasWidth = Math.floor(this.currentImg.width * zoom);
                let canvasHeight = Math.floor(this.currentImg.height * zoom);

                if (docPanal.clientWidth > canvasWidth) {
                    this.canvas.setWidth(docPanal.clientWidth);
                    canvasDiv.style.overflowX = 'hidden';
                } else {
                    this.canvas.setWidth(canvasWidth);
                    canvasDiv.style.overflowX = 'scroll';
                }

                if (docPanal.clientHeight > canvasHeight) {
                    this.canvas.setHeight(docPanal.clientHeight);
                    canvasDiv.style.overflowY = 'hidden';
                } else {
                    this.canvas.setHeight(canvasHeight);
                    canvasDiv.style.overflowY = 'scroll';
                }
                this.canvas.setZoom(zoom);
            }
        }
    }

    initMouseZoomEvent() {
        // this.canvas.on('mouse:wheel', (opt) => {
        //     var delta = opt.e.deltaY;
        //     var pointer = this.canvas.getPointer(opt.e);
        //     var zoom = this.canvas.getZoom();
        //     zoom *= 0.999 ** delta;
        //     if (zoom > 20) zoom = 20;
        //     if (zoom < 0.01) zoom = 0.01;
        //     this.canvas.setZoom(zoom);
        //     //this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
        //     // opt.e.preventDefault();
        //     // opt.e.stopPropagation();
        // });
    }

    initMouseDownEvent() {
        this.canvas.on('mouse:down', (options) => {
            if (!this.isAddObject) return;
            let pointer = this.canvas.getPointer(options.e);
            this.isMouseDown = true;
            this.orignX = pointer.x;
            this.orignY = pointer.y;
            if (!this.isInEditMode) {
                this.removeObject();
                //this.pointGuid = this._sharedService.GetGuid().toString();
            }
            switch (this.objectType) {
                case 'point':
                    this.addPointOnCanvas(pointer);
                    break;
                case 'circle':
                    this.addCircleOnCanvas(pointer);
                    break;
                default:
                    break;
            }

            //this.objectType = null;
            this.isAddObject = false;
            //this.updateCanvasObject();
        });

    }

    updateCanvasObject() {
        let canvasObj = new CanvasObject();
        canvasObj.canvasObjectId = this.pointGuid;
        canvasObj.markerType = this.selectedMarkerType.Name;
        canvasObj.objectArray = [];
        canvasObj.objectArray = this.canvas.getObjects();
        this.canvasObject = canvasObj;
    }

    addPointOnCanvas(pointer: any) {
        let pointCircle = new fabric.Circle({
            name: this.pointGuid + '_point',
            radius: 2,
            fill: this.selectedMarkerType.CSSClass,
            left: pointer.x,
            top: pointer.y,
            hasControls: false,
        });

        this.currentCircle = pointCircle;
        let pointerCenter = pointCircle.getCenterPoint();

        if (!this.isInEditMode) {
            let label = this.getDrawingLabel();
            let line = new fabric.Line([pointerCenter.x, pointerCenter.y + pointCircle.radius, pointerCenter.x, pointerCenter.y + 100], {
                name: this.pointGuid + '_line',
                stroke: this.selectedMarkerType.CSSClass,
                hasControls: false,
                selectable: false
            });

            let labelCircle = new fabric.Rect({
                name: this.pointGuid + '_group_rect',
                width: this.getTextRectWidth(label),
                height: 20,
                fill: '#fff',
                stroke: this.selectedMarkerType.CSSClass,
                strokeWidth: 1,
                originX: 'center',
                originY: 'center',
                top: 200,

            });

            let labelText = new fabric.Text(label, {
                name: this.pointGuid + '_group_text',
                fontSize: 10,
                stroke: this.selectedMarkerType.CSSClass,
                originX: 'center',
                originY: 'center',

                top: 200
            });

            let group = new fabric.Group([labelCircle, labelText], {
                name: this.pointGuid + '_group',
                left: pointer.x + 2,
                top: pointer.y + 110,
                hasControls: false,
                originX: 'center',
                originY: 'center'
            });

            this.canvas.add(pointCircle, line, group);
            group.bringToFront();
            line.sendToBack();

        } else {

            let groupObject = this.getCanvasObjectsByType('group');
            let line = new fabric.Line([pointerCenter.x, pointerCenter.y + pointCircle.radius, groupObject[0].left + 2, groupObject[0].top - 10], {
                name: this.pointGuid + '_line',
                stroke: this.selectedMarkerType.CSSClass,
                hasControls: false,
                selectable: false
            });

            this.canvas.add(pointCircle, line);
            line.sendToBack();
            groupObject[0].bringToFront();
        }

        this.updateCanvasObject();

    }

    addCircleOnCanvas(pointer: any) {
        let pointCircle = new fabric.Circle({
            name: this.pointGuid + '_point',
            radius: 1,
            fill: 'transparent',
            strokeWidth: 1,
            stroke: this.selectedMarkerType.CSSClass,
            left: pointer.x,
            top: pointer.y,
            noScaleCache: false,
            centeredScaling: true,
            strokeUniform: true
            //objectCaching:false
        });

        this.canvas.add(pointCircle);
        this.currentCircle = pointCircle;
    }

    addCircleLabel() {
        let pointer = this.currentCircle.getCenterPoint();

        if (!this.isInEditMode) {
            let label = this.getDrawingLabel();
            let line = new fabric.Line([pointer.x, pointer.y + this.currentCircle.radius, pointer.x, pointer.y + 100], {
                name: this.pointGuid + '_line',
                stroke: this.selectedMarkerType.CSSClass,
                hasControls: false,
                selectable: false
            });

            let labelCircle = new fabric.Rect({
                name: this.pointGuid + '_group_rect',
                width: this.getTextRectWidth(label),
                height: 20,
                fill: '#fff',
                stroke: this.selectedMarkerType.CSSClass,
                strokeWidth: 1,
                originX: 'center',
                originY: 'center',
                top: 200,

            });

            let labelText = new fabric.Text(label, {
                name: this.pointGuid + '_group_text',
                fontSize: 10,
                stroke: this.selectedMarkerType.CSSClass,
                originX: 'center',
                originY: 'center',

                top: 200
            });

            let group = new fabric.Group([labelCircle, labelText], {
                name: this.pointGuid + '_group',
                left: pointer.x + 2,
                top: pointer.y + 110,
                hasControls: false,
                originX: 'center',
                originY: 'center'
            });

            this.canvas.add(line, group);
            group.bringToFront();
            line.sendToBack();

        } else {

            let groupObject = this.getCanvasObjectsByType('group');
            let line = new fabric.Line([pointer.x, pointer.y + this.currentCircle.radius, groupObject[0].left + 2, groupObject[0].top - 10], {
                name: this.pointGuid + '_line',
                stroke: this.selectedMarkerType.CSSClass,
                hasControls: false,
                selectable: false
            });

            this.canvas.add(line);
            line.sendToBack();
            groupObject[0].bringToFront();

        }

        this.updateCanvasObject();
    }


    addPathLabel() {
        if (!this.isInEditMode) {

            let label = this.getDrawingLabel();
            let x = this.currentPath.getCenterPoint().x;
            let y = this.currentPath.getCenterPoint().y;
            let line = new fabric.Line([x, y, x, y + 100], {
                name: this.pointGuid + '_line',
                stroke: this.selectedMarkerType.CSSClass,
                hasControls: false,
                selectable: false
            });

            let labelCircle = new fabric.Rect({
                name: this.pointGuid + '_group_rect',
                width: this.getTextRectWidth(label),
                height: 20,
                fill: '#fff',
                stroke: this.selectedMarkerType.CSSClass,
                strokeWidth: 1,
                originX: 'center',
                originY: 'center',
                top: 200,

            });

            let labelText = new fabric.Text(label, {
                name: this.pointGuid + '_group_text',
                fontSize: 10,
                stroke: this.selectedMarkerType.CSSClass,
                originX: 'center',
                originY: 'center',
                top: 200
            });

            let group = new fabric.Group([labelCircle, labelText], {
                name: this.pointGuid + '_group',
                left: x + 2,
                top: y + 110,
                hasControls: false,
                originX: 'center',
                originY: 'center'
            });

            this.canvas.add(line, group);
            line.sendToBack();
            group.bringToFront();
            this.currentPath.sendToBack();
        } else {

            let groupObject = this.getCanvasObjectsByType('group');
            // let x = this.currentPath.aCoords.tl.x;
            // let y = this.currentPath.path.find(p => Math.trunc(p[1]) == Math.trunc(x))[2];
            // let x = this.currentPath.path[0][1];
            // let y = this.currentPath.path[0][2];
            // let x = this.currentPath.left;
            // let y = this.currentPath.top;
            let x = this.currentPath.getCenterPoint().x;
            let y = this.currentPath.getCenterPoint().y;
            let line = new fabric.Line([x, y, groupObject[0].left + 2, groupObject[0].top - 10], {
                name: this.pointGuid + '_line',
                stroke: this.selectedMarkerType.CSSClass,
                hasControls: false,
                selectable: false
            });

            this.canvas.add(line);
            line.sendToBack();
            groupObject[0].bringToFront();
        }
    }



    getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    initObjectMovingEvent() {
        this.canvas.on('object:moving', (options) => {
            let p = options.target;
            let activeObject: fabric.Object = this.canvas.getActiveObject();
            let id = activeObject.name.split('_')[0];
            if (activeObject) {

                this.removeObjectsByTypeAndId(id, 'line');
                let circleList = this.getCanvasObjectsByTypeAndId(id, 'circle');
                let pathList = this.getCanvasObjectsByTypeAndId(id, 'path');
                let group;

                if (activeObject.type == 'group') {
                    group = activeObject;
                    //re-draw connecting Lines
                    circleList.forEach(cir => {
                        let grpTop = (cir.top + cir.getRadiusY() * 2) < p.top ? p.top : (p.top + 10);
                        let cirTop = (cir.top + cir.getRadiusY() * 2) < group.top ? (cir.top + cir.getRadiusY() * 2) : cir.top;

                        let line = new fabric.Line([cir.left + cir.getRadiusX(), cirTop, p.left + 2, grpTop], {
                            name: id + '_line',
                            stroke: cir.stroke ? cir.stroke : cir.fill,
                            hasControls: false,
                            selectable: false
                        });

                        this.canvas.add(line);
                        line.sendToBack();
                    });

                    pathList.forEach(pth => {
                        let x = pth.getCenterPoint().x;
                        let y = pth.getCenterPoint().y;
                        let grpTop = y < group.top ? group.top : (group.top + 10);

                        let line = new fabric.Line([x, y, p.left + 2, grpTop], {
                            name: id + '_line',
                            stroke: pth.stroke ? pth.stroke : pth.fill,
                            hasControls: false,
                            selectable: false
                        });

                        pth.bringToFront();
                        this.canvas.add(line);
                        line.sendToBack();
                    });
                }
                else {
                    if (activeObject.type == 'circle') {
                        group = this.getCanvasObjectByName(activeObject.name.replace('point', 'group'));

                    }
                    else if (activeObject.type == 'path') {
                        group = this.getCanvasObjectByName(activeObject.name.replace('path', 'group'));
                    }

                    //re-draw connecting Lines
                    circleList.forEach(cir => {
                        let grpTop = (cir.top + cir.radius * 2) < group.top ? group.top : (group.top + 10);
                        let cirTop = (cir.top + cir.getRadiusY() * 2) < group.top ? (cir.top + cir.getRadiusY() * 2) : cir.top;

                        let line = new fabric.Line([cir.left + cir.getRadiusX(), cirTop, group.left + 2, grpTop], {
                            name: id + '_line',
                            stroke: cir.stroke ? cir.stroke : cir.fill,
                            hasControls: false,
                            selectable: false
                        });

                        this.canvas.add(line);
                        line.sendToBack();
                        cir.bringToFront();
                    });

                    pathList.forEach(pth => {
                        let x = pth.getCenterPoint().x;
                        let y = pth.getCenterPoint().y;
                        let grpTop = y < group.top ? group.top : (group.top + 10);

                        let line = new fabric.Line([x, y, group.left + 2, grpTop], {
                            name: id + '_line',
                            stroke: pth.stroke ? pth.stroke : pth.fill,
                            hasControls: false,
                            selectable: false
                        });

                        pth.bringToFront();
                        this.canvas.add(line);
                        line.sendToBack();
                    });


                }



                this.selectedPoint = [];
                this.selectedPoint.push(circleList, group);
                group.bringToFront();
                this.canvas.renderAll();
            }
        });
    }


    initObjectScalingEvent() {
        this.canvas.on('object:scaled', (options) => {
            let p = options.target;
            let activeObject: fabric.Object = this.canvas.getActiveObject();
            let id = activeObject.name.split('_')[0];
            if (activeObject) {
                this.removeObjectsByTypeAndId(id, 'line');
                let group;

                if (activeObject.type == 'circle') {
                    let circleList = this.getCanvasObjectsByTypeAndId(id, 'circle');
                    group = this.getCanvasObjectByName(activeObject.name.replace('point', 'group'));
                    //Set Selected Point
                    // this.selectedPoint = [];
                    // this.selectedPoint.push(pointCircle, group);

                    //updated selected Point cords
                    // let pointer = pointCircle.getCenterPoint();
                    // //line && line.set({ 'x1': p.getWidth(), 'y1': p.getHeight() + pointCircle.radius });
                    // line && line.set({ 'x1': pointer.x, 'y1': pointer.y + pointCircle.radius });

                    //group.bringToFront();

                    //re-draw connecting Lines
                    circleList.forEach(cir => {
                        cir.set({ strokeWidth: 1 });
                        let grpTop = (cir.top + cir.getRadiusY() * 2) < group.top ? group.top : (group.top + 10);
                        let cirTop = (cir.top + cir.getRadiusY() * 2) < group.top ? (cir.top + cir.getRadiusY() * 2) : cir.top;
                        let line = new fabric.Line([cir.left + cir.getRadiusX(), cirTop, group.left + 2, grpTop], {
                            name: id + '_line',
                            stroke: cir.stroke ? cir.stroke : cir.fill,
                            hasControls: false,
                            selectable: false
                        });

                        this.canvas.add(line);
                        line.sendToBack();
                        cir.bringToFront();
                    });
                } else if (activeObject.type == 'path') {
                    let pathList = this.getCanvasObjectsByTypeAndId(id, 'path');
                    group = this.getCanvasObjectByName(activeObject.name.replace('path', 'group'));
                    //re-draw connecting Lines
                    pathList.forEach(pth => {
                        let x = pth.getCenterPoint().x;
                        let y = pth.getCenterPoint().y;
                        let grpTop = y < group.top ? group.top : (group.top + 10);

                        let line = new fabric.Line([x, y, group.left + 2, grpTop], {
                            name: id + '_line',
                            stroke: pth.stroke ? pth.stroke : pth.fill,
                            hasControls: false,
                            selectable: false
                        });

                        pth.bringToFront();
                        this.canvas.add(line);
                        line.sendToBack();
                    });
                }

                this.canvas.renderAll();

            }
        });
    }




    getCanvasObjectByName(ObjectName: string): any {
        let canvasObj: any = null;
        this.canvas.getObjects().forEach(function (object: any) {
            if (object.name === ObjectName) {
                canvasObj = object;
                return;
            }
        });
        return canvasObj;
    }

    getCanvasObjectsByName(ObjectName: string): any {
        let canvasObj: any[] = [];
        this.canvas.getObjects().forEach(function (object: any) {
            if (object.name === ObjectName) {
                canvasObj.push(object);
            }
        });
        return canvasObj;
    }

    getCanvasObjectsById(ObjectId: string): any {
        let canvasObj: any[] = [];
        this.canvas.getObjects().forEach(function (object: any) {
            if (object.name.indexOf(ObjectId) != -1) {
                canvasObj.push(object);
            }
        });

        return canvasObj;
    }

    getCanvasObjectsByTypeAndId(ObjectId: string, type: string): any {
        let canvasObj: any[] = [];
        this.canvas.getObjects().forEach(function (object: any) {
            if (object.type == type && object.name.indexOf(ObjectId) != -1) {
                canvasObj.push(object);
            }
        });

        return canvasObj;
    }

    getCanvasObjectsByType(objectType: string): any {
        let canvasObj: any[] = [];
        this.canvas.getObjects().forEach(function (object: any) {
            if (object.type == objectType) {
                canvasObj.push(object);
            }
        });

        return canvasObj;
    }


    initMouseMoveEvent() {
        this.canvas.on('mouse:move', (options) => {
            let pointer = this.canvas.getPointer(options.e);
            if (!this.isMouseDown) return;
            switch (this.objectType) {
                case 'circle':
                    this.currentCircle.set({ radius: Math.abs(this.orignX - pointer.x), dirty: true });
                    this.currentCircle.left = this.currentCircle.left;
                    this.currentCircle.top = this.currentCircle.top;
                    this.currentCircle.setCoords();
                    //this.canvas.requestRenderAll();
                    break;
                case 'rect':
                    //this.currentRect.set({ width: Math.abs(this.orignX - pointer.x), height: Math.abs(this.orignY - pointer.y) });
                    break;
                default:
                    break;
            }

            this.canvas.renderAll();
        });
    }


    initMouseUpEvent() {
        this.canvas.on('mouse:up', (options) => {
            switch (this.objectType) {
                case 'point':
                    break;
                case 'circle':
                    this.addCircleLabel();
                    break;
                // case 'path':
                //     this.currentPath = this.canvas.getActiveObject();
                //     this.canvas.isDrawingMode = false;
                //     this.addPathLabel();
                //     break;
                default:
                    break;
            }
            this.isMouseDown = false;
            this.objectType = null;
        });
    }



    initMouseOverEvent() {
        // this.canvas.on('mouse:over', (options) => {
        //     if (options.target.type == 'circle') {
        //         options.target.set('fill', 'red');
        //     } else if (options.target.type == 'group') {
        //         options.target.item(0).set('stroke', 'red');
        //         options.target.item(1).set('stroke', 'red');
        //     }
        //     this.canvas.renderAll();
        // });
    }

    initMouseOutEvent() {
        // this.canvas.on('mouse:out', (options) => {
        //     if (options.target.type == 'circle') {
        //         options.target.set('fill', this.selectedMarkerType.CSSClass);
        //     } else if (options.target.type == 'group') {
        //         options.target.item(0).set('stroke', this.selectedMarkerType.CSSClass);
        //         options.target.item(1).set('stroke', this.selectedMarkerType.CSSClass);
        //     }
        //     this.canvas.renderAll();
        // });
    }


    initPathCreatedEvent() {
        this.canvas.on('path:created', (options: any) => {
            switch (this.objectType) {
                // case 'point':
                //     break;
                // case 'circle':
                //     this.addCircleLabel();
                //     break;
                case 'path':
                    this.currentPath = options.path;
                    this.currentPath.set({
                        name: this.pointGuid + '_path',
                        strokeUniform: true
                        //selectable :false,
                        //hasControls : false,

                    });
                    this.currentPath.bringToFront();

                    this.canvas.isDrawingMode = false;
                    this.addPathLabel();
                    this.updateCanvasObject();
                    break;
                default:
                    break;
            }
            this.isMouseDown = false;
            this.objectType = null;
        });
    }


    btnMove(direction: string) {
        let units = 10;
        let delta = null;
        switch (direction) {
            case 'left':
                delta = new fabric.Point(-units, 0);
                break;
            case 'right':
                delta = new fabric.Point(units, 0);
                break;
            case 'up':
                delta = new fabric.Point(0, -units);
                break;
            case 'down':
                delta = new fabric.Point(0, units);
                break;
            default:
                break;
        }

        if (delta) {
            this.canvas.relativePan(delta);
        }

    }
    populateMarkerType() {
        // this.markerTypeList = [
        //     {Name: 'dent and buckle', CSSClass: '#71843f'},
        //     {Name: 'Repair', CSSClass: '#a90329'},
        //     {Name: 'Review', CSSClass: '#c79121'}
        // ];

        this._markerTypeService.getAllActiveMarkerTypeData(null)
            .subscribe({
                next: (response) => {
                    this.markerTypeList = response;
                    if (this.markerTypeList.length > 0) { this.selectedMarkerType = this.markerTypeList[0]; }
                    this.populateMarkerTypeFilter();
                },
                error: (error) => {
                    this.markerTypeList = [];
                    console.log(error);
                }
            });
    }

    populateMarkerTypeFilter() {
        this.markerTypeFilterList = [
            { Name: 'All' },
            { Name: 'Current' }
        ];

        for (let index = 0; index < this.markerTypeList.length; index++) {
            let markerType = this.markerTypeList[index];
            this.markerTypeFilterList.push(markerType);
        }

        this.selectedMarkerTypeFilter = this.markerTypeFilterList[1];
    }

    setMode() {
        if (this.isInEditMode) {
            this.isInEditMode = false;
            this.editModeText = 'Switch to Edit Mode';
            this.editModeIcon = 'fa fa-plus';
        } else {
            if (this.canvas.getObjects().length > 0) {
                this.isInEditMode = true;
                this.editModeText = 'Switch to Add Mode';
                this.editModeIcon = 'fa fa-edit';
            } else {

            }
        }
    }

    saveObject() {
        this._sharedService.showLoader("Processing...");
        let objArray = this.getFormatedObjectArray();
        let objArrayToRender = this.getFormatedObjectArrayToRender();
        this.selectedTaskDoc.OtherDetail = JSON.stringify(objArrayToRender);//this.canvas;
        this.sltdDocId = this.selectedTaskDoc.TaskDocumentId;
        let selectedMarkerTypeFilter = this.selectedMarkerTypeFilter;

        this.resetCanvasAndControls();
        this.initCanvas(true);
        this.resetBackgroundImage();
        if (selectedMarkerTypeFilter.Name == 'Current') {
            this.drawCanvasObject(this.selectedTaskDoc, false);
        } else {
            this.selectedMarkerTypeFilter = selectedMarkerTypeFilter;
            if (this.renderedTaskDocumentList && this.renderedTaskDocumentList.length > 0) {
                for (let index = 0; index < this.renderedTaskDocumentList.length; index++) {
                    this.drawCanvasObject(this.renderedTaskDocumentList[index], false);
                }
            }
        }

        let imgData = this.canvas.toDataURL();
        let imgBlob = this.b64toBlob(imgData.split(',')[1], 'image/png');

        this.selectedTaskDoc.OtherDetail = JSON.stringify(objArray);//this.canvas;
        this.updateTaskDoc({ value: this.selectedTaskDoc.TaskDocumentId });

        let formData = new FormData();
        //formData.append('data', pdf);
        let docNameArray = this.selectedTaskDoc.DocumentName.split('.');
        formData.append("data", imgBlob, docNameArray[0]);
        formData.append('TaskDocumentId', this.selectedTaskDoc.TaskDocumentId);
        formData.append('TaskId', this.selectedTaskDoc.TaskId);
        formData.append('DocumentPath', this.selectedTaskDoc.DocumentPath);
        formData.append('DocumentType', this.selectedTaskDoc.DocumentType);
        formData.append('OtherDetail', this.selectedTaskDoc.OtherDetail);
        formData.append('ViewSettingId', this.ViewModel.ViewSettingId);
        formData.append('ViewSource', this.ViewModel.Source.toString());
        formData.append('MarkerType', this.selectedMarkerType.Name);
        formData.append('ASN', this.asn);

        if (this._sharedService.selectedAsset.length > 0) {
            //US-8181 //// let containerName = this._sharedService.selectedAsset[0].data.split('|')[0];
            // formData.append('ContainerName', containerName);
            formData.append('ContainerName', JSON.parse(this._sharedService.selectedAsset[0].data).StoragePath);
        }
        var xhr = new XMLHttpRequest();
        xhr.open('POST', `${this._sharedService.baseUrl}/api/TaskDocument/UpdateTaskDocuments`, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        xhr.send(formData);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                this._sharedService.hideLoader();
                if (xhr.status === 200) {
                    this._sharedService.clearToast();
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Document has been marked succesfully.' });
                    this.refreshTaskDocuments();
                }
                else {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Cannot Update point, please try again.' });
                }
            }
        }
    }

    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    getFormatedObjectArray() {
        let canvasObj = new CanvasObject();
        canvasObj.canvasObjectId = this.pointGuid;
        canvasObj.markerType = this.selectedMarkerType.Name;
        canvasObj.objectArray = [];
        if (this.selectedMarkerTypeFilter.Name == 'Current') {
            canvasObj.objectArray = this.canvas.getObjects();
        } else {
            canvasObj.objectArray = this.getCanvasObjectsById(this.pointGuid);
        }

        return canvasObj;
    }

    getFormatedObjectArrayToRender() {
        let canvasObj = new CanvasObject();
        canvasObj.canvasObjectId = this.pointGuid;
        canvasObj.markerType = this.selectedMarkerType.Name;
        canvasObj.objectArray = [];
        canvasObj.objectArray = this.canvas.getObjects();
        return canvasObj;
    }

    drawCanvasObject(TaskDocument: TaskDocument, initCanvas: boolean = true) {

        if (initCanvas) { this.initCanvas(); }
        try {

            let canvasObj = JSON.parse(TaskDocument.OtherDetail);
            if (!canvasObj.canvasObjectId) {
                console.log('No id found for the object');
                console.log(canvasObj);
                this.pointGuid = this._sharedService.GetGuid();
                console.log('New Guid Assigned' + this.pointGuid);
                return;
            }
            if (!this.getDrawingLabel(TaskDocument.TaskId)) {
                console.log('No Label found for the object');
                console.log(canvasObj);
                return;
            }

            if (this.selectedMarkerTypeFilter.Name == 'Current') {
                this.pointGuid = canvasObj.canvasObjectId;
                this.canvasObject = canvasObj;
            }
            let currMarkerType = this.markerTypeList.find(m => m.Name.trim() == canvasObj.markerType.trim());
            if (!currMarkerType) { currMarkerType = { CSSClass: 'black' }; }
            else {
                this.selectedMarkerType = currMarkerType;
            }

            let groupObj = canvasObj.objectArray.find(o => o.type == 'group');

            let circleList = canvasObj.objectArray.filter(o => o.type == 'circle');
            if (circleList && circleList.length > 0) {
                for (let index = 0; index < circleList.length; index++) {
                    let cir = this.drawCircle(TaskDocument.TaskId[0], canvasObj.canvasObjectId, currMarkerType.CSSClass, circleList[index]);

                    let grpTop = circleList[index].top < groupObj.top ? (groupObj.top - 10) : (groupObj.top + 10);
                    let cirTop = (cir.top + cir.getRadiusY() * 2) < groupObj.top ? (cir.top + cir.getRadiusY() * 2) : cir.top;
                    let line = new fabric.Line([circleList[index].left + cir.getRadiusX(), cirTop, groupObj.left + 2, grpTop], {
                        name: canvasObj.canvasObjectId + '_line',
                        stroke: currMarkerType.CSSClass,
                        hasControls: false,
                        selectable: false
                    });
                    this.canvas.add(line);
                    // line.sendBackwards(canvasGroup);
                    this.canvas.sendBackwards(line, true);
                }
            }

            let pathList = canvasObj.objectArray.filter(o => o.type == 'path');
            if (pathList && pathList.length > 0) {
                for (let index = 0; index < pathList.length; index++) {
                    let currentPath = this.drawPath(TaskDocument.TaskId[0], canvasObj.canvasObjectId, currMarkerType.CSSClass, pathList[index]);
                    let x = currentPath.getCenterPoint().x;
                    let y = currentPath.getCenterPoint().y;

                    let grpTop = y < groupObj.top ? (groupObj.top - 10) : (groupObj.top + 10);
                    let line = new fabric.Line([x, y, groupObj.left + 2, grpTop], {
                        name: canvasObj.canvasObjectId + '_line',
                        stroke: currMarkerType.CSSClass,
                        hasControls: false,
                        selectable: false
                    });

                    this.canvas.add(line);
                    // line.sendBackwards(canvasGroup);
                    this.canvas.sendBackwards(line, true);
                }
            }

            let canvasGroup;
            if (groupObj) {
                canvasGroup = this.drawGroup(TaskDocument.TaskId[0], canvasObj.canvasObjectId, currMarkerType.CSSClass, groupObj);
                this.canvas.bringForward(canvasGroup, true);
            }

            this.canvas.renderAll();

        } catch (error) {
            console.log(error);
        }

    }

    removeObject() {
        //this.canvas.remove(this.canvas.getActiveObject());
        let canvalObjects = this.canvas.getObjects();
        for (let index = 0; index < canvalObjects.length; index++) {
            this.canvas.remove(canvalObjects[index]);
        }

    }

    removeObjectsByType(type: string) {
        //this.canvas.remove(this.canvas.getActiveObject());
        let canvalObjects = this.canvas.getObjects();
        for (let index = 0; index < canvalObjects.length; index++) {
            if (canvalObjects[index].type == type) this.canvas.remove(canvalObjects[index]);
        }
    }

    removeObjectsById(id: string) {
        //this.canvas.remove(this.canvas.getActiveObject());
        let canvalObjects = this.canvas.getObjects();
        for (let index = 0; index < canvalObjects.length; index++) {
            if (canvalObjects[index].name.indexOf(id) != -1) this.canvas.remove(canvalObjects[index]);
        }
    }

    removeObjectsByTypeAndId(id: string, type: string) {
        //this.canvas.remove(this.canvas.getActiveObject());
        let canvalObjects = this.canvas.getObjects();
        for (let index = 0; index < canvalObjects.length; index++) {
            if (canvalObjects[index].type == type && canvalObjects[index].name.indexOf(id) != -1) {
                this.canvas.remove(canvalObjects[index]);
            }
        }
    }

    resetCanvasAndControls(fileType: string = 'application/pdf') {
        this.fileType = fileType;
        this.isVideoTypeFound = undefined;
        this.isAudioTypeFound = undefined;
        this.isNonPreviewTypeFound = undefined;
        this.isVideoURLTypeFound = undefined;

        if (this.canvas) {
            //Reset Canvas Width
            this.canvas.setWidth(0);
            this.canvas.setHeight(0);

            this.canvas.clear();
        }
        if (this._videoPlayer) {
            const video: HTMLVideoElement = this._videoPlayer.nativeElement;
            video.pause();
            video.currentTime = 0;
        }
        if (this._audioPlayer) {
            const audio: HTMLAudioElement = this._audioPlayer.nativeElement;
            audio.pause();
            audio.currentTime = 0;
        }
        this.isAudioTypeFound = null;
        this.isVideoTypeFound = null;
        this.isNonPreviewTypeFound = null;
        this.isDocumentTypeFound = null;
        this.isImageTypeFound = null;


    }

    drawCircle(taskId: string, pointGuid: string, markerColor: string, cirObj: fabric.Circle): fabric.Circle {
        let pointCircle = new fabric.Circle({
            name: pointGuid + '_point',
            radius: cirObj.radius,
            fill: cirObj.radius == 2 ? markerColor : 'transparent',
            strokeWidth: cirObj.strokeWidth,
            stroke: markerColor,
            left: cirObj.left,
            top: cirObj.top,
            // hasControls: false,
            scaleX: cirObj.scaleX,
            scaleY: cirObj.scaleY,
            centeredScaling: true,
            strokeUniform: true
        });

        this.canvas.add(pointCircle);
        return pointCircle;
    }

    drawPath(taskId: string, pointGuid: string, markerColor: string, pathObj: fabric.Path): fabric.Path {
        let path = new fabric.Path(pathObj.path);
        path.set({
            name: pointGuid + '_path',
            strokeWidth: 1,
            top: pathObj.top,
            left: pathObj.left,
            stroke: markerColor,
            fill: 'transparent',
            strokeUniform: true
            // hasControls: false,
            //selectable:false
        });

        this.canvas.add(path);
        path.setCoords();
        // path.bringToFront();
        return path;
    }


    drawLine(taskId: string, pointGuid: string, markerColor: string, lineObj: fabric.Line) {
        let pointsArray: any[];
        let line = new fabric.Line([lineObj.x1, lineObj.y1, lineObj.x2, lineObj.y2], {
            // line = new fabric.Line(null ,{
            name: pointGuid + '_line',
            stroke: markerColor,
            originX: "left",
            originY: "top",
            top: lineObj.top,
            left: lineObj.left,
            width: lineObj.width,
            height: lineObj.height,
            hasControls: false,
            selectable: false,

            // angle: 0,
            // backgroundColor: "",
            // clipTo: null,
            // fill: "rgb(0,0,0)",
            // fillRule: "nonzero",
            // flipX: false,
            // flipY: false,
            // globalCompositeOperation: "source-over",

            // opacity: 1,
            // shadow: null,
            // skewX: 0,
            // skewY: 0,

            // strokeDashArray: null,
            // strokeDashOffset: 0,
            // strokeLineCap: "butt",
            // strokeLineJoin: "miter",
            // strokeMiterLimit: 4,

        });

        this.canvas.add(line);
        line.sendToBack();
    }

    drawGroup(taskId: string, pointGuid: string, markerColor: string, grpObj: any) {
        let grpObjList: fabric.Object[] = [];
        for (let index = 0; index < grpObj.objects.length; index++) {
            let currItem = grpObj.objects[index];
            let label = this.getDrawingLabel(taskId);
            switch (currItem.type) {
                case 'rect':
                    let labelRect = new fabric.Rect({
                        name: pointGuid + '_group_rect',
                        width: this.getTextRectWidth(label),
                        height: 20,
                        fill: this.getRectFillColor(pointGuid, markerColor),
                        stroke: markerColor,
                        strokeWidth: 1,
                        originX: 'center',
                        originY: 'center',
                        top: 200,
                    });
                    grpObjList.push(labelRect);

                    break;
                case 'text':
                    let labelText = new fabric.Text(label, {
                        name: pointGuid + '_group_text',
                        fontSize: 10,
                        stroke: this.getTextStrokeColor(pointGuid, markerColor),
                        originX: 'center',
                        originY: 'center',

                        top: 200
                    });
                    grpObjList.push(labelText);
                    break;
                default:
                    break;
            }

        }

        let group = new fabric.Group(grpObjList, {
            name: pointGuid + '_group',
            left: grpObj.left,
            top: grpObj.top,
            hasControls: false,
            originX: 'center',
            originY: 'center'
        });

        this.canvas.add(group);
        return group;
    }


    getTextRectWidth(text: string) {
        if (text.length <= 2) {
            return 20;
        }

        if (text.length > 2) {
            return text.length * 7;
        }
    }

    getRectFillColor(pointGuid: string, markerColor: string) {
        if (pointGuid == this.canvasObject.canvasObjectId) {
            return this.selectedMarkerTypeFilter.Name == 'Current' ? '#fff' : markerColor;
        }
    }

    getTextStrokeColor(pointGuid: string, markerColor: string) {
        if (pointGuid == this.canvasObject.canvasObjectId) {
            return this.selectedMarkerTypeFilter.Name == 'Current' ? markerColor : '#FFFFFF';
        }
    }

    onMarkerTypeChange(event: any) {
        if (this.selectedMarkerTypeFilter.Name != 'Current') { return; }

        let canvasObjects: any[] = this.canvas.getObjects();
        for (let index = 0; index < canvasObjects.length; index++) {
            switch (canvasObjects[index].type) {
                case 'circle':
                    if (canvasObjects[index].radius == 2) {
                        canvasObjects[index].set({ fill: this.selectedMarkerType.CSSClass, stroke: this.selectedMarkerType.CSSClass });
                    } else {
                        canvasObjects[index].set({ stroke: this.selectedMarkerType.CSSClass });
                    }
                    break;
                case 'line':
                    canvasObjects[index].set({ stroke: this.selectedMarkerType.CSSClass });
                    break;
                case 'group':
                    let grpObjects = canvasObjects[index].getObjects();
                    for (let gIndex = 0; gIndex < grpObjects.length; gIndex++) {
                        let currItem = grpObjects[gIndex];
                        currItem.set({ stroke: this.selectedMarkerType.CSSClass });
                    }
                    break;
                case 'path':
                    canvasObjects[index].set({ stroke: this.selectedMarkerType.CSSClass });
                    break;
                default:
                    break;
            }

            this.canvas.renderAll();
        }
    }

    onMarkerTypeFilterChange(event: any) {
        this._sharedService.showLoader("Loading...");
        if (this.selectedMarkerTypeFilter.Name == 'Current') {
            this.removeObject();
            this.drawCanvasObject(this.selectedTaskDoc, false);
            // this.updateCanvasStack();
            this._sharedService.hideLoader();

        } else {
            this.removeObject();
            let viewTaskDocument = {
                MarkerType: this.selectedMarkerTypeFilter.Name,
                TaskDocumentList: [this.selectedTaskDoc],
                View: this.ViewModel,
                TaskEntity: this.selectedTask[0]
            }
            this._taskDocumentService.getImagePoints(viewTaskDocument)
                .subscribe({
                    next: (response) => {
                        this.renderedTaskDocumentList = response;
                        if (this.renderedTaskDocumentList && this.renderedTaskDocumentList.length > 0) {
                            for (let index = 0; index < this.renderedTaskDocumentList.length; index++) {

                                if ((this.selectedMarkerTypeFilter.Name.toLowerCase() != "all") || (this.renderedTaskDocumentList[index].TaskDocumentId != this.selectedTaskDoc.TaskDocumentId)) {

                                    this.drawCanvasObject(this.renderedTaskDocumentList[index], false);
                                }
                            }
                            if (this.selectedMarkerTypeFilter.Name.toLowerCase() == "all")
                                this.drawCanvasObject(this.selectedTaskDoc, false);
                            // this.updateCanvasStack();
                        }
                        this._sharedService.hideLoader();
                    },
                    error: (error) => {
                        console.log(error);
                        this._sharedService.hideLoader();
                    }
                });
        }


    }

    updateCanvasStack() {
        let canvasObjects: any[] = this.canvas.getObjects();
        for (let index = 0; index < canvasObjects.length; index++) {
            switch (canvasObjects[index].type) {
                case 'circle':
                case 'group':
                case 'path':
                    canvasObjects[index].bringToFront();
                    break;
                case 'line':
                    canvasObjects[index].sendToBack();
                    break;
                default:
                    break;
            }

            this.canvas.renderAll();
        }
    }


    getDrawingLabel(taskId: string = '') {
        let labelCol = this.ViewModel.Columns.find(c => (c.FieldLabel != null) && (c.FieldLabel.indexOf('#') != -1));
        if (taskId) {
            if (labelCol) {
                let labelTask = this.gridData.find(t => t.TaskID == taskId);
                if (labelCol.FieldName.indexOf('Date') != -1) {
                    return this.getFormatedDate(labelTask[labelCol.FieldName]);
                } else {
                    return labelTask[labelCol.FieldName].toString();
                }

            }

        } else {

            if (labelCol) { return this.selectedTask[0][labelCol.FieldName] ? this.selectedTask[0][labelCol.FieldName].toString() : ''; }

        }
        return '';
    }


    downloadPDF() {
        let objArray = this.getFormatedObjectArray();
        let objArrayToRender = this.getFormatedObjectArrayToRender();
        this.selectedTaskDoc.OtherDetail = JSON.stringify(objArrayToRender);//this.canvas;
        let selectedMarkerTypeFilter = this.selectedMarkerTypeFilter;

        this.resetCanvasAndControls();
        this.initCanvas(true);
        this.resetBackgroundImage();
        if (selectedMarkerTypeFilter.Name == 'Current') {
            this.drawCanvasObject(this.selectedTaskDoc, false);
        } else {
            this.selectedMarkerTypeFilter = selectedMarkerTypeFilter;
            if (this.renderedTaskDocumentList && this.renderedTaskDocumentList.length > 0) {
                for (let index = 0; index < this.renderedTaskDocumentList.length; index++) {
                    this.drawCanvasObject(this.renderedTaskDocumentList[index], false);
                }
            }
        }


        let imgData = this.canvas.toDataURL();

        this.selectedTaskDoc.OtherDetail = JSON.stringify(objArray);//this.canvas;

        let pdf = new jsPDF({ orientation: 'l' });//, unit: 'px'});
        let widthInMM = Math.floor(this.currentImg.width * 0.264583);
        let heightInMM = Math.floor(this.currentImg.height * 0.264583)

        pdf.addImage(imgData, 'PNG', 0, 0, widthInMM - 5, heightInMM - 5);
        //pdf.addImage(imgData, 'PNG', 0, 0);
        let docNameArray = this.selectedTaskDoc.DocumentName.split('.');
        pdf.save(docNameArray[0] + ".pdf");

        this.updateTaskDoc({ value: this.selectedTaskDoc.TaskDocumentId });
        this._sharedService.hideLoader();
    }

    //#endregion

    getDocIcon(doc: TaskDocument) {
        // if (doc && doc.IsImage == 'true') {
        //     return 'fa-file-image-o';
        // } else 
        {
            if (doc.VideoUrl) {
                return 'fa-file-video-o';
            } else {
                //return 'fa-file-pdf';
                let docType = "";
                let urlSegments = doc.DocumentPath.split('?');
                if (urlSegments.length == 2) {
                    docType = urlSegments[0].split(".").pop();
                }
                else {
                    //to do
                    docType = urlSegments[0].split(".").pop();
                }
                if (docType) {
                    let isAudioTypeFound = this._sharedService.allowedAudioFileTypes.filter(x => x == docType)[0];
                    let isVideoTypeFound = this._sharedService.allowedVideoFileTypes.filter(x => x == docType)[0];
                    let isNonPreviewTypeFound = this._sharedService.allowedNonPreviewFileTypes.filter(x => x == docType)[0];
                    let isDocumentTypeFound = this._sharedService.allowedDocumentTypes.filter(x => x == docType)[0];
                    let isImageTypeFound = this._sharedService.allowedImageFileTypes.filter(x => x == docType)[0];

                    if (isAudioTypeFound) return 'fa-file-audio-o';
                    else if (isVideoTypeFound) return 'fa-file-video-o';
                    else if (isNonPreviewTypeFound) {
                        if (isNonPreviewTypeFound == "zip" || isNonPreviewTypeFound == "rar" || isNonPreviewTypeFound == "gz" || isNonPreviewTypeFound == "epub") return 'fa fa-file-archive-o';
                        else if (isNonPreviewTypeFound == "msg" || isNonPreviewTypeFound == "eml") return 'fa fa-envelope-o';
                        else if (isNonPreviewTypeFound == "doc" || isNonPreviewTypeFound == "docx" || isNonPreviewTypeFound == "docm" || isNonPreviewTypeFound == "odt" || isNonPreviewTypeFound == "rtf") return 'fa-file-word-o';
                        else if (isNonPreviewTypeFound == "xls" || isNonPreviewTypeFound == "xlsx" || isNonPreviewTypeFound == "xlsm" || isNonPreviewTypeFound == "ods") return 'fa-file-excel-o';
                        else if (isNonPreviewTypeFound == "ppt" || isNonPreviewTypeFound == "pptx" || isNonPreviewTypeFound == "pptm" || isNonPreviewTypeFound == "odp") return 'fa-file-powerpoint-o';
                        else return 'fa fa-file-o';
                    }

                    else if (isDocumentTypeFound) {
                        if (isDocumentTypeFound == "pdf") return 'fa-file-pdf';
                        else if (isDocumentTypeFound == "doc" || isDocumentTypeFound == "docx" || isDocumentTypeFound == "docm" || isDocumentTypeFound == "odt" || isDocumentTypeFound == "rtf") return 'fa-file-word-o';
                        else if (isDocumentTypeFound == "xls" || isDocumentTypeFound == "xlsx" || isDocumentTypeFound == "xlsm" || isDocumentTypeFound == "ods") return 'fa-file-excel-o';
                        else if (isDocumentTypeFound == "ppt" || isDocumentTypeFound == "pptx" || isDocumentTypeFound == "pptm" || isDocumentTypeFound == "odp") return 'fa-file-powerpoint-o';
                        else if (isDocumentTypeFound == "txt" || isDocumentTypeFound == "xml" || isDocumentTypeFound == "html" || isDocumentTypeFound == "json") return 'fa-file-text-o';
                        else return 'fa fa-file-o';//'fa-file-text-o';
                    }
                    else if (isImageTypeFound) return 'fa-file-image-o';//'fa fa-file-o';
                }

                return 'fa fa-file-o';//'fa-file-pdf';





            }
        }
    }



    populateUsersList() {
        this._userService.getCurrentTenantUsers(this._sharedService.baseUrl, null)
            .subscribe({
                next: (response) => {
                    this.requestedByList = response;
                }
            });

    }

    getAssignedToUsersList() {
        this.assignedToList = this._sharedService.getAssignedToUsersList();
        if (this.assignedToList == null) {
            this._userService.getCurrentTenantUsersWithType(this._sharedService.baseUrl, null)
                .subscribe({
                    next: (response) => {
                        this.assignedToList = response;
                        this._sharedService.setAssignedToUsersList(response);
                    }
                });
        }
    }

    populateCommStatus() {
        this._commService.getAllCommStatus()
            .subscribe({
                next: (response) => {
                    this.commStatusList = response;
                },
                error: (error) => {
                }
            });
    }

    showHideCommDialogue(value: Boolean) {
        this.isAddVisible = value
    }

    prepareAddCommObj(event: any, commTypeId: string) {
        let commTypeIdInNumber = Number(commTypeId);
        let module =this.isInRepairModule ? "Repairs" : "Status"
        this.statusTaskObj =
        {
            CommTypeId: commTypeIdInNumber,
            ASN: event.ASN,
            Module:  module,
            Subject: `${module} | ${event.ASN} | ${this.ViewModel.DisplayName} | ${event.TaskID}`,
            Section: selectedViewId, //viewid
            Record: event.TaskID, //taskid
            TaskId :event.TaskID
        };        
            
        this._sharedService.setModulesSelectedOptionForComm(this.statusTaskObj);
        setTimeout(() => {
            this.showHideCommDialogue(true);
        }, 100);
    }

    isAddCommItem(event: any) {
        this.showHideCommDialogue(false);
        this._commService.isAddCommItem(event.TaskID, 'Status', event.DFPStatus).subscribe(commTypeId => {
            if (commTypeId) {
                this.prepareAddCommObj(event, commTypeId);
            }
        })
    }
}
