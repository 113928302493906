import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';

@Component({
  selector: 'tooltip-component',
  template: ` <div class="custom-nor-tooltip" ><div [innerHTML]="params.value"></div></div>`,
  styles: [
    `
    :host {
      position: absolute;
      width: 210px;
      height: 100px;
      pointer-events: none;
      transition: opacity 1s;
      border : 1px solid
    }

    
      .custom-nor-tooltip div{
        margin: 2px;
        width:206px;
        height:96px;
        white-space: nowrap;
        background:white;
      }

      
    `,
  ],
})
export class CustomNormalTooltip implements ITooltipAngularComp {
  public params!: ITooltipParams;
  public valueToDisplay!: string;
  public isHeader!: boolean;
  public isGroupedHeader!: boolean;

  agInit(params: ITooltipParams): void {
    this.params = params;
    this.params.value = this.params.value.replace(/&lt;/g,'<').replace(/&gt;/g,'>').replace(/&quot;/g,'"');
    this.isHeader = params.rowIndex === undefined;
    this.isGroupedHeader = !!(params.colDef && (params.colDef as any).children);
    this.valueToDisplay = params.value.value
      ? params.value.value
      : '- Missing -';
  }
}