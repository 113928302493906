
//Components
import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';
import { SelectItem } from 'primeng/api';
import { AddJobModel } from './add-job';
import { jobs } from '../job/job';
import { NgForm } from '@angular/forms';
import { ContainerService } from '../../../records/services/container/container.service'
import { JobService } from '../../services/dp-jobs.service'
import { container } from '../../../records/services/container/container'
import { AssetService } from '../../../admin/services/asset/asset.service'
import { UserService } from '../../../records/services/User/user.service';
import { IConfig, AddUpdateStatusCode } from '../../../shared/helpers/config';
import * as moment from 'moment/moment';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { Subscription } from "rxjs";




var batchTypesList_global: SelectItem[] = [];
@Component({
    selector: 'add-job',
    templateUrl: './add-job.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ContainerService, JobService, AssetService, UserService]
})

export class AddJobComponent implements OnInit, OnDestroy {

    display: boolean = false;
    @Input() recordType: string;
    dialogTitle: string = "";
    selectedWOType: string;
    WOTypes: SelectItem[] = [];
    priorityList: SelectItem[] = [];
    jobInternalList: SelectItem[] = [];
    internalUsersList: SelectItem[] = [];
    jobStatusList: SelectItem[] = [];
    batchTypesList: SelectItem[] = [];
    batchTypesListMaster: SelectItem[] = [];
    assets: SelectItem[] = [];
    model: AddJobModel;
    //cont: container = [];
    dateSeparator = "";
    dateFormat = "";
    dateFormatCalender = "";
    configuration: IConfig[] = [];
    private day: number;
    private month: number;
    private year: number;
    isSubmitted: boolean = false;
    isAuthorizedUser: boolean = false;
    userType: string = "";

    WorkOrderLabel: string = '';
    addJobSub: Subscription;
    containerSub: Subscription;
    public referenceKeyLabel: string = "";
    public isJobModule: boolean = true;
    public screenmode: string = 'ADD';
    public displayFtpDlg: boolean = false;
    public fullPath: string = '';
    public folderName: string = '';

    public appHotkeys: Hotkey[] = [];
    public ViewDateFormat: string;
    public setAddPopupWidth = '61vw'

    placeholderContainerType = "Select Container Type";
    placeholderRDOCBatchType = "Select RDOC Batch Type";
    //#region Initialization of Component
    constructor(public _sharedService: SharedService, private cRef: ChangeDetectorRef, private _router: Router, private _containerService: ContainerService, private _jobService: JobService, private _assetService: AssetService, private _userService: UserService, private _hotkeysService: HotkeysService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.configuration = this._sharedService.getConfiguration();
            this.setHotKeys();
        }
    }
    ngOnInit() {
        this.referenceKeyLabel = this._sharedService.getReferenceKeyLabel();
        this.isJobModule = this.IsInvokedFromJobModule();
        this.initializeModel();
        this.addJobSub = this._sharedService._addJob
        .subscribe({
            next: (response) => {
                this.showAddDialog();
            },
            error: (error) => {
            }
        })
        this.containerSub = this._sharedService._selectedContainerFromComponentAdd
        .subscribe({
            next: (params:any) => {
                this.getContainerDataByID(params.ContainerID);
            },
            error: (error) => {
            }
        })
        this.ViewDateFormat = this._sharedService.getLabelTextDateFormat();

        let screenWidth = window.innerWidth;
        if (screenWidth < 1025) {
            this.placeholderContainerType = "Select Container...";
            this.placeholderRDOCBatchType = "Select RDOC Batch...";
        }

        let screenSize = window.innerWidth;
        if (screenSize <= 1080) {
            this.setAddPopupWidth = '70vw';
        }

    }
    ngOnDestroy() {

        if (this.addJobSub) {
            this.addJobSub.unsubscribe();
        }
        if (this.containerSub) {
            this.containerSub.unsubscribe();
        }

        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];


    }

    //#endregion

    // #region Form Events

    showAddDialog() {
        if (!this._sharedService.isAuthUser()) {
          this._router.navigate(["login"]);
          this._sharedService.SessionExpiredMessage();
          }
         else {
          this.setCalenderDateFormat();
          this.dialogTitle = "Add Job";
          this.setDefaultComponentValues();
          if (this.isJobModule) {
            this.display = true;
            this.cRef.markForCheck();
          } else {
            let containerID: string =
              this._sharedService.selectedContainer.ContainerID;
            this.getContainerDataByID(containerID);
          }
        }
    }
    getContainerDataByID(containerID: string) {
        this._containerService.getContainerDetail(containerID)
        .subscribe({
            next: (response) => {
                this.model.asset = response.ASN;
                this.model.date = this._sharedService.getFormatedDate(response.Date);
                this.model.enddate = this._sharedService.getFormatedDate(response.EndDate);
                this.model.referencekey = response.ReferenceKey;
                this.model.type = response.Type;
                this.model.containerid = containerID;
                this.containerTypeChanged(response.Type);
                this.model.batchtypeid = null;//0;//"11|||C";
                if (!this.display) {
                    this.display = true;
                }
                this.batchTypesListMaster = batchTypesList_global;
                this.batchTypesList = batchTypesList_global;
                this.cRef.markForCheck();
            },
            error: (error) => {
            }
        })
    }
    adjustJobDlgHeight(event: any) {
        let tskDlgfrm = document.getElementById('jobAddDlg');

        if (tskDlgfrm) {
            tskDlgfrm.style.height = '78vh';
            //    tskDlgfrm.style.overflowY = 'scroll';
            tskDlgfrm.style.overflowX = 'scroll !important';
        }
    }
    setHotKeys() {

        let hotKeyObj: Hotkey = null;

        hotKeyObj = new Hotkey('ctrl+shift+a', (event: KeyboardEvent): boolean => {
            this.showAddDialog();
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

    }
    CheckJobStatusId(commStatusId) {
        var value = "0";
        switch (commStatusId) {

            //JobStatus
            // Ready for processing 5e8097268c488e5cd2ba5001 =>6
            // Complete 5e8097268c488e5cd2ba5000 =>5
            // In Progress 5e8097268c488e5cd2ba4fff =>4
            // Pending 5e8097268c488e5cd2ba4ffe =>3
            // Received 5e8097268c488e5cd2ba4ffd =>2
            // New 5e8097268c488e5cd2ba4ffc =>1
            case "5e8097268c488e5cd2ba5001":
                value = "6";
                break;
            case "5e8097268c488e5cd2ba5000":
                value = "5";
                break;
            case "5e8097268c488e5cd2ba4fff":
                value = "4";
                break;
            case "5e8097268c488e5cd2ba4ffe":
                value = "3";
                break;
            case "5e8097268c488e5cd2ba4ffd":
                value = "2";
                break;
            case "5e8097268c488e5cd2ba4ffc":
                value = "1";
                break;
            default:
                value = commStatusId
                break;
        }
        return value;

    }

    onSubmit(form: NgForm) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.changeButtonsStatus(true);
            let body: jobs =
            {
                Status: this.model.status,
                Type: this.model.type,
                ReferenceKey: this.model.referencekey,
                Date: this.getDate(this.model.date),
                EndDate: this.getDate(this.model.enddate),
                //  Asset: this.model.asset.split('|')[1],
                Asset: this.model.asset,
                AssignedTo: this.getAssignedTo(),
                Comments: this.model.comments,
                Documents: this.model.documents,
                Pages: this.model.pages,
                DpJobInternalStatusId: this.model.dpjobinternalstatusid,
                DpJobStatusId: this.model.dpjobstatusid,
                Priority: this.model.priority,
                Conversation: this.getConversation(),
                AssignedToEmail: this.getAssignedToEmail(),
                BatchTypeID: this.getBatchTypeId(this.model.batchtypeid),
                ContainerID: this.model.containerid
            }

            this._sharedService.showLoader("Processing...");
            this._jobService.addJob(body)
            .subscribe({
                next: (response) => {
                    this._sharedService.hideLoader();
                    if (response != AddUpdateStatusCode.Error && response != AddUpdateStatusCode.ReadOnlyAsset) {
                        this.display = false;
                        this.model.dpjobstatusid = this.CheckJobStatusId(this.model.dpjobstatusid)
                        if (this.model.dpjobstatusid == this.recordType) {
                            this._sharedService.resfreshJobGrid(true);
                        }
                        if (!this.isJobModule) {
                            this._sharedService.resfreshJobGrid(true);
                        }
                        this.initializeModel();
                        form.resetForm();
                        this._sharedService.clearToast();
                        this.fullPath = response.FullPath;
                        this.folderName = response.FolderName;
                        this.displayFtpDlg = true;
                        //  this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Job added succesfully.' });
                        this.changeButtonsStatus(false);
                    } else if (response == AddUpdateStatusCode.ReadOnlyAsset) {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'Asset is Readonly.', detail: 'Cannot create job associated with readonly asset.' });
                        this.display = false;
                        this.initializeModel();
                        form.resetForm();
                        this.changeButtonsStatus(false);
                    }
                    else {
                        this.display = false;
                        if (this.model.dpjobstatusid == this.recordType) {
                            this._sharedService.resfreshJobGrid(true);
                        }
                        if (!this.isJobModule) {
                            this._sharedService.resfreshJobGrid(true);
                        }
                        this.changeButtonsStatus(false);
                        this.initializeModel();
                        form.resetForm();
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'Warning', detail: 'Job has been created but FTP folder not created because of Invalid FTP configuration.' });
                    }
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    if (error.status === 409) {
                        this._sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Job already exists.' });
                    }
                    else {
                        console.log(error);
                        this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Job not added, please try again.' });
                    }
                    this.display = false;
                    this.displayFtpDlg = false;
                    this.initializeModel();
                    form.resetForm();
                    this.changeButtonsStatus(false);
                }
            });
        }
    }
    getLinkClick() {
        this._sharedService.clearToast();
        this._sharedService.showToast({ severity: 'success', summary: 'Copied!', detail: 'Link has been copied succesfully.' });
    }
    CloseLinkDlgClick() {
        this.displayFtpDlg = false;
    }
    getAssignedTo(): string {
        try {
            if (this.model && this.model.assignedto) {
                if (this.model.assignedto != "") {
                    return this.model.assignedto.split("||")[0];
                }
            }
            return null;
        } catch (e) {
            console.log(e);
            return null;
        }
    }
    getAssignedToEmail(): string {
        try {
            if (this.model && this.model.assignedto) {
                if (this.model.assignedto != "") {
                    return this.model.assignedto.split("||")[1];
                }
            }
            return null;
        } catch (e) {
            console.log(e);
            return null;
        }

    }
    btnCancelClick(cancelForm: NgForm) {
        this.display = false;
        this.initializeModel();
        cancelForm.resetForm();

    }
    //// #endregion

    //#region Types Data
    setWOType() {
        this.WOTypes = [];
        this._containerService.getAllActiveContainerTypes(null)
        .subscribe({
            next: (response) => {
                this.WOTypes = response;
            },
            error: (error) => {
                console.log(error)
            }
        });
    }
    //#endregion
    //#region Assets
    populateAsset() {
        this._assetService.getAllAssetsWithAsn()
        .subscribe({
            next: (response) => {
                this.assets = response;
            },
            error: (error) => {
                console.log(error);
            }
        });
    }
    //#endregion
    // #region Priority List
    populatePriortityList() {
        this.priorityList = [
            { value: '1', label: 'High' },
            { value: '2', label: 'Normal' },
            { value: '3', label: 'Low' }
        ];
    }
    //#endregion
    //#region Job Status
    populateJobStatus() {
        this._jobService.getAllActiveJobStatus()
        .subscribe({
            next: (response) => {
                this.jobStatusList = response;
                this.model.dpjobstatusid = this.jobStatusList[0].value
            },
            error: (error) => {
            }
        });
    }
    //#endregion
    //#region Batch Types List
    populateBatchTypesList() {
        this._jobService.getAllActiveBatchTypes()
        .subscribe({
            next: (response) => {
                this.batchTypesListMaster = response;
                this.batchTypesList = response;
                batchTypesList_global = response;
            },
            error: (error) => {
            }
        });
    }
    //#endregion
    //#region Job Internal Status
    populateJobInternalStatus() {
        this._jobService.getAllActiveInternalJobStatus()
        .subscribe({
            next: (response) => {
                this.jobInternalList = response;
            },
            error: (error) => {
            }
        })
    }
    //#endregion
    //#region Job Internal Status
    populateJobInternalUsers() {
        this._userService.getAllInternalUsersWithEmail(this._sharedService.baseUrl, null)
        .subscribe({
            next: (response) => {
                this.internalUsersList = response;
            },
            error: (error) => {
            }
        });
    }
    //#endregion
    //#region Date Manipulation Helper Methods
    getPlainDate(date: Date): string {
        if (date != null) {
            var formatted = moment(date).format(this.dateFormat.toUpperCase());
            if (this.dateSeparator == "-") {
                let newStr = formatted.toString().replace(/-/g, "");
                return newStr;
            }
            else if (this.dateSeparator == "/") {
                let newStr = formatted.toString().replace(/\//g, "");
                return newStr;
            }
        }
        else {
            return "";

        }
    }

    setCalenderDateFormat() {
        try {
            this.dateFormat = this.configuration.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
            this.dateSeparator = this.configuration.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
            this.day = Number(this.configuration.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
            this.month = Number(this.configuration.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
            this.year = Number(this.configuration.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
            let dateParts: string[] = this.dateFormat.split(this.dateSeparator);

            if (this.year == 0) {
                this.dateFormatCalender = (dateParts[this.year].substr(0, 2) + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.day]).toLowerCase();
            }
            else if (this.year == 2) {
                if (this.month == 1) {
                    this.dateFormatCalender = (dateParts[this.day] + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
                }
                else if (this.month == 0) {
                    this.dateFormatCalender = (dateParts[this.month] + this.dateSeparator + dateParts[this.day] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
                }
            }
            else {
                this.dateFormatCalender = "yy-mm-dd"
            }
        }
        catch (ex) {
            console.log(ex);
        }
    }

    setDefaultComponentValues(): void {
        this.setWOType();
        this.populateAsset();
        if (this.userType == "Internal") {
            this.populateJobInternalUsers();
            this.populateJobInternalStatus();
        }
        this.populateJobStatus();
        this.populatePriortityList();
        this.populateBatchTypesList();
        this.initializeModel();
        this.changeButtonsStatus(false);
    }

    getDate(date: Date): string {
        if (date != null) {
            let newDate = moment(date).format("YYYY-MM-DD");
            return new Date(newDate).toDateString();
        }
        return null;
    }
    //#endregion


    initializeModel() {

        //this.model = new AddJobModel(null, null, null, null, null, null, null, null, true, null, null, null, null);
        this.model = new AddJobModel(null, null, null, null, null, null, null, null, true, null, null, null, null, null, null, null, null, null, null, null, null);
        this.model.priority = '2';
        // this.model.dpjobstatusid = 1;
        this.userType = this._sharedService.getCurrentUserType();
        //this.model.batchtypeid = 0;
    }


    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }
    getConversation() {
        if (this.model.conversation != "" && this.model.conversation != "null" && this.model.conversation != null) {
            return this._sharedService.getCurrentUserName() + "||" + this.getCurrentDateTime() + "||" + this.model.conversation + "?|?";
        }
        return null;
    }
    getCurrentDateTime() {
        let result = moment().format("MM/DD/YYYY" + " HH:mm");
        return result;
    }
    IsInvokedFromJobModule(): boolean {
        if ((!this.recordType || this.recordType == 'MXR' || this.recordType == 'LOG' || this.recordType == 'GEN' || this.recordType == 'DOC' || this.recordType == 'BND') && this._sharedService.selectedContainer && this._sharedService.selectedContainer.ContainerID) {
            return false;
        }
        else {
            return true;
        }
    }
    containerTypeChanged(selectedValue: string) {
        this.model.batchtypeid = null;
        this.batchTypesList = [];
        if (selectedValue) {
            this.batchTypesListMaster.forEach(element => {

                let itemValue = element.value.split('|||')[1];
                if (itemValue == selectedValue || (!itemValue)) {
                    this.batchTypesList.push(element);
                }

            });


        }

    }
    getBatchTypeId(batchType: any) {
        if (batchType) {
            let itemValue = batchType.split('|||')[0];
            return itemValue;
        }
        return null;
    }

    onKeyAddJob() {
        this.display = false;
        this.initializeModel();
    }

    onCloseDate() {
        var enddate: any = document.getElementsByClassName('enddate')[0].children[1];
        enddate.focus();
    }
    onCloseEnddate() {
        var desr = document.getElementById('woNumberId');
        desr.focus();
    }
}
