import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
import { TagService } from '../../../shared/services/tag/tag.service';
import { DocumentTypeService } from '../../../admin/services/document-type/document-type.service';
import { PDFExtractPage, PDFMerge, MergeOperation, PDFDelete } from '../../services/pdfManipultation/pdfmanipulation';
import { PDFManipulationService } from '../../services/pdfManipultation/pdfmanipultation.service';
import { IConfig } from '../../../shared/helpers/config';
import { NgForm } from '@angular/forms';
import * as moment from 'moment/moment';
import { QualityStatusService } from '../../../admin/services/qualitystatus/qualitystatus.service';

@Component({
    selector: 'doc-manpulation',
    templateUrl: './doc-manipulation.component.html',
    providers: [DocumentTypeService, ConfirmationService, TagService, PDFManipulationService, QualityStatusService]
})

export class DocManipulation implements OnInit {
    @Input() recordType: string;
    isDisplayExtractPagesDialog: boolean = false;
    DocType: SelectItem[];
    selectedDocType: SelectItem;
    dialogTitle: string;
    uploadUrl: string;
    uploadedFile: any = [];
    uploadedFiles: any[] = [];
    fileNames: any[] = [];
    metaData: PDFExtractPage;
    tags: SelectItem[] = [];
    selectedTags: string[] = [];
    parentWoNumber: string;

    //dateFormatCalender = "";
    dateFormatCalender = "";
    dateSeparator = "";
    dateFormat = "";
    configuration: IConfig[] = [];
    private day: number;
    private month: number;
    private year: number
    isSubmitted: boolean = false;

    public bulkFile: any[] = [];
    public isNonPdfFileExist: boolean = false;
    public mergeOperation: MergeOperation;
    public ViewDateFormat: string;

    qualityStatusList: SelectItem[] = [];

    canMXRQualityStatus: boolean = false;
    canLOGQualityStatus: boolean = false;
    canGENQualityStatus: boolean = false;
    canDOCQualityStatus: boolean = false;
    canBNDQualityStatus: boolean = false;

    processMXRQualityStatusEnabled: boolean = false;
    processLOGQualityStatusEnabled: boolean = false;
    processGENQualityStatusEnabled: boolean = false;
    processDOCQualityStatusEnabled: boolean = false;
    processBNDQualityStatusEnabled: boolean = false;

    //#region Share

    DocLocation: SelectItem[];
    //#endregion
    //#region Merge Local
    isDisplayMergeLocalDialog: boolean = false;
    mergeLocalDialogTitle: string;
    canAddLocalMerge: boolean = false;
    selectedDocs: SelectItem[];
    pdfMergeLocalModel: PDFMerge;


    //#endregion

    //#region Merge Online
    isDisplayMergeOnlineDialog: boolean = false;
    mergeOnlineDialogTitle: string;
    canAddOnlineMerge: boolean = false;
    selectedOnlineDocs: SelectItem[];
    pdfMergeOnlineModel: PDFMerge;


    //#endregion

    //#region Merge Online
    isDisplayDeleteOnlineDialog: boolean = false;
    deleteDialogTitle: string;
    canDeletePages: boolean = false;
    pdfDeleteModel: PDFDelete;
    //#endregion
    public referenceKeyLabel: string = "";
    @ViewChild('singleFileUpload') selectedSingleFile: any;
    public maxAllowedFileSize: number = 0;
    public maxAllowedFileSizeMB: number = 0;
    @ViewChild('focus') focusInput: ElementRef;
    @ViewChild('focusLocal') focusLocal: ElementRef;
    public showExtractPageRangePDF = false;

    constructor(public _sharedService: SharedService, private _documentTypeService: DocumentTypeService, private confirmationService: ConfirmationService, private _tagService: TagService, private _router: Router, private pdfManipulationService: PDFManipulationService, private _confirmationService: ConfirmationService,
        private _qualityStatusService: QualityStatusService) {
        //this.uploadUrl = `${this._sharedService.baseUrl}/api/AzureBlob/UploadBlob`;
        this.metaData = new PDFExtractPage();
        this.pdfMergeOnlineModel = new PDFMerge();
        this.pdfMergeLocalModel = new PDFMerge();
        this.pdfDeleteModel = new PDFDelete();
    }

    ngOnInit() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        this.maxAllowedFileSize = this._sharedService.getMaxDocumentLimitInBytes();
        this.maxAllowedFileSizeMB = this._sharedService.getMaxDocumentLimit();
        this.referenceKeyLabel = this._sharedService.getReferenceKeyLabel();
        this.getRecordType();
        this.UpdateRoles();
        this.populateQualityStatusDropdown();
        this.showExtractPageRangePDF = false;
    }

    populateQualityStatusDropdown() {
        this._qualityStatusService.getActiveQualityStatusDataForDropDown(null)
        .subscribe({
            next: (response) => {
                this.qualityStatusList = response;
            },
            error: (error) => {
            }
        });
    }

    //#region Extract Pages
    clearSingleFile() {
        this.selectedSingleFile.nativeElement.value = "";
    }
    initPopupModel() {
        this.uploadUrl = `${this._sharedService.baseUrl}/api/AzureBlob/UploadBlob`;
        this.recordType = this._sharedService.recordType;
        this.getRecordType();
        this.parentWoNumber = '';
        this.parentWoNumber = this._sharedService.selectedContainer ? this._sharedService.selectedContainer.ReferenceKey : '';
        if (this._sharedService.selectedContainer.Date && this._sharedService.selectedContainer.Date.length > 10) {
            let dateString = this._sharedService.selectedContainer.Date.substr(0, 10);
            let dateParts = moment(dateString).format(this.dateFormat.toUpperCase()).split(this.dateSeparator);
            let day = Number(dateParts[Number(this.day)].substring(0, 2));
            let month = Number(dateParts[Number(this.month)]) - 1;
            let year = Number(dateParts[Number(this.year)]);
            let cellDate = new Date(year, month, day);
            if (this.parentWoNumber) {
                this.metaData = new PDFExtractPage();
                this.metaData.ReferenceKey = this._sharedService.selectedContainer.ReferenceKey;
                this.metaData.DocDate = cellDate;
            }
            else {
                if (this.parentWoNumber == null) {
                    this.parentWoNumber = '';
                }
                this.metaData = new PDFExtractPage();
            }
        }
        else {
            if (this.parentWoNumber) {
                this.metaData = new PDFExtractPage();
                this.metaData.ReferenceKey = this._sharedService.selectedContainer.ReferenceKey;
                this.metaData.DocDate = this._sharedService.selectedContainer.Date.substr(0, 10);
            }
            else {
                if (this.parentWoNumber == null) {
                    this.parentWoNumber = '';
                }
                this.metaData = new PDFExtractPage();
            }
        }
        this.selectedTags = [];
    }
    OpenExtractModal() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }

        let selectedRows = this._sharedService.selectedDocCount;
        let selectedDocs = this._sharedService.selectedDocuments;
        if (selectedDocs && selectedRows && selectedRows == 1) {
            this.changeButtonsStatus(false);
            this.resetModel(MergeOperation.Extract);
            this.metaData.DocType = '';
            this.setCalenderDateFormat();
            this.initPopupModel();
            //this.dialogTitle = this.recordType + 'Extract Pages';
            this.dialogTitle = 'Extract Pages';
            this.ViewDateFormat = this._sharedService.getLabelTextDateFormat();
            this.populateDocTypeDropdown();
            this.populateTagsDropdown();
            this.isDisplayExtractPagesDialog = true;
            if (this.qualityStatusList && this.qualityStatusList.length > 1) {
                this.metaData.Status = this.qualityStatusList[0].value;
            }

        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Warning', detail: 'Please select one document for process.' });
        }
    }



    btnCancelClick(form: NgForm) {
        this.changeButtonsStatus(false);
        this.isDisplayExtractPagesDialog = false;
        form.resetForm();
        this.resetModel(MergeOperation.Extract);
    }

    btnSubmitClick(form: NgForm) {

        if (this.metaData.IsDelete) {
            this._confirmationService.confirm({
                message: 'Do you want to delete page(s)?',
                header: 'Delete Confirmation',
                icon: 'fa fa-trash',
                accept: () => {
                    this.extractPages(form);
                },
                reject: () => {
                }
            });
        }
        else {
            this.extractPages(form);
        }



    }


    extractPages(form: NgForm): void {
        this.changeButtonsStatus(true);
        this.metaData.MasterFile = this._sharedService.selectedDocuments[0].Url
        this.metaData.SeqNo = this._sharedService.selectedDocuments[0].SeqNo;
        this.metaData.OCR = this._sharedService.selectedDocuments[0].OCR;
        //this.metaData.ReferenceKey
        //this.metaData.PageRange = this.metaData.PageRange;

        //US-8181 //let asset = this._sharedService.selectedAsset[0].data.split('|');
        let assetDataObj = JSON.parse(this._sharedService.selectedAsset[0].data);
        this.metaData.ContainerName = assetDataObj.StoragePath;//asset[0];
        this.metaData.ASN = this._sharedService.selectedContainer.ASN;
        this.metaData.Type = this._sharedService.selectedContainer.Type;
        this.metaData.ID = this._sharedService.selectedContainer.ID;
        this.metaData.RunDuplicationCheck = "true";
        this.metaData.Tags = this.selectedTags.join(",");
        let postData = this.metaData;
        if (typeof (postData.DocDate) == "string") {
            let dateString = (postData["DocDate"] as string).substr(0, 10);
            let date = moment(dateString).format("YYYY-MM-DD").toString();
            this.metaData.DocumentDate = date;
        }
        else {
            //let date = moment(postData.DocDate).format(this.dateFormat.toUpperCase()).toString();
            let date = moment(postData.DocDate).format("YYYY-MM-DD").toString();
            this.metaData.DocumentDate = date;
        }
        let body = this.metaData;
        this._sharedService.showLoader("Processing...");
        this.pdfManipulationService.extractPDF(body)
        .subscribe({
            next: (response) => {
                this._sharedService.hideLoader();
                this.resetModel(MergeOperation.Extract);
                form.resetForm();
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Pages extracted succesfully.' });
                this.changeButtonsStatus(false);
                this._sharedService.isDocManipulated = true;
                setTimeout(() => {
                    this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                    this._sharedService.onAssetChanged(this._sharedService.selectedAsset);
                }, 2000);
        
                this.isDisplayExtractPagesDialog = false;
            },
            error: (error) => {
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                if (error.status === 400) {
                    this._sharedService.showToast({ severity: 'error', summary: 'Invalid page range', detail: 'Please enter valid page range.' });
                    this.changeButtonsStatus(false);
                }
                else if (error.status === 300) {
                    this._sharedService.showToast({ severity: 'error', summary: 'Duplicate', detail: 'File already exist.' });
                    this.changeButtonsStatus(false);
                }
                else if (error.status === 406) {
                    this._sharedService.showToast({ severity: 'error', summary: 'Warning', detail: 'This is the last page in the document.' });
                    this.changeButtonsStatus(false);
                }
                else if (error.status === 409) {
                    var errMsg = "";
                    errMsg = error.error.replace(/"/g, "");//globally replace
                    this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: errMsg });
                    this.changeButtonsStatus(false);
                }
                else if (error.status === 416) {
                    this._sharedService.showToast({ severity: 'error', summary: 'Warning', detail: 'All pages in document cannot be deleted.' });
                    this.changeButtonsStatus(false);
                }
                else if (error.status == 501) {
                    this._sharedService.showToast({ severity: 'warn', summary: 'Unable to update document URL', detail: error.error });
                    this._sharedService.hideLoader();
                    this.resetModel(MergeOperation.Extract);
                    form.resetForm();
                    this.changeButtonsStatus(false);
                    this._sharedService.isDocManipulated = true;
                    setTimeout(() => {
                        this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                        this._sharedService.onAssetChanged(this._sharedService.selectedAsset);
                    }, 2000);
                    this.isDisplayExtractPagesDialog = false;
                }
                else {
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Operation fail, please try again.' });
                    this.isDisplayExtractPagesDialog = false;
                    this.resetModel(MergeOperation.Extract);
                    this.changeButtonsStatus(false);
                }
            }
        });


    }

    // #endregion

    //#region Merge Local
    OpenInsertModal() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }

        let selectedRows = this._sharedService.selectedDocCount;
        let selectedDocs = this._sharedService.selectedDocuments;
        if (selectedDocs && selectedRows && selectedRows == 1) {
            this.mergeLocalDialogTitle = "Insert File (PC)";
            this.populateDropDown_Location();
            this.resetModel(MergeOperation.Insert);
            this.pdfMergeLocalModel.Location = "After";
            this.isDisplayMergeLocalDialog = true;
            this.changeButtonsStatus(false);
            this.showExtractPageRangePDF = false;

        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Warning', detail: 'Please select one Document for merging.' });
        }
    }
    btnSubmitMergeLocal() {
        this.changeButtonsStatus(true);
        this.uploadUrl = `${this._sharedService.baseUrl}/api/Document/MergeLocal`;
        this.pdfMergeLocalModel.MasterFile = this._sharedService.selectedDocuments[0].Url;
        let xhr = new XMLHttpRequest(), formData = new FormData();
        //Append File and Form Data in xhr Request

        formData.append("LocalFile", this.uploadedFile[0], this.uploadedFile[0].name);
        formData.append("PageNumber", this.pdfMergeLocalModel.PageNumber == undefined ? "0" : this.pdfMergeLocalModel.PageNumber);
        //formData.append("IsLocal", this.model.IsLocal.toString());
        //formData.append("IsDelete", this.model.IsDelete.toString());
        formData.append("Location", this.pdfMergeLocalModel.Location);
        formData.append("MasterFile", this.pdfMergeLocalModel.MasterFile);
        formData.append("MergeOptions", this.pdfMergeLocalModel.MergeOptions);
        formData.append("PageRange", this.pdfMergeLocalModel.PageRange);

        xhr.open('POST', this.uploadUrl, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));

        xhr.send(formData);
        this._sharedService.showLoader("Processing...");
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                this._sharedService.hideLoader();
                if (xhr.status === 200) {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Document(s) merged succesfully.' });
                    this.isDisplayMergeLocalDialog = false;
                    this._sharedService.isDocManipulated = true;
                    setTimeout(() => {
                        this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                        this._sharedService.onAssetChanged(this._sharedService.selectedAsset);
                    }, 2000);
                    this.uploadedFile = [];
                    this.clearSingleFile();
                    this.resetModel(MergeOperation.Insert);

                }
                else {
                    if (xhr.status === 400) {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'error', summary: 'Invalid page no.', detail: 'Please enter valid page no.' });
                        this.changeButtonsStatus(false);
                    }
                    else if (xhr.status === 409) {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File', detail: 'File size exceeds the maximum limit of ' + this.maxAllowedFileSizeMB + ' MB' });
                        this.changeButtonsStatus(false);
                    }
                    else {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Operation fails, please try again.' });
                        this.isDisplayMergeLocalDialog = false;
                        this.uploadedFile = [];
                        this.clearSingleFile();
                        this.resetModel(MergeOperation.Insert);
                    }
                }
            }
        };
    }
    btnMergeLocalCancelClick(form: NgForm) {
        this.changeButtonsStatus(false);
        this.isDisplayMergeLocalDialog = false;
        form.resetForm();
        this.resetModel(MergeOperation.Insert);
        this.clearSingleFile();
    }
    onChange(event: any) {
        if (event.srcElement.files.length > 0) {
            // if (event.srcElement.files[0].type == 'application/pdf')
            if (this._sharedService.allowedFileTypes.includes(event.srcElement.files[0].type)) {
                if (event.srcElement.files[0].size <= this.maxAllowedFileSize) {
                    this.uploadedFile = event.srcElement.files
                    if (event.srcElement.files[0].type.indexOf('application/pdf') != -1) {
                        this.showExtractPageRangePDF = true;
                    } else {
                        this.showExtractPageRangePDF = false;
                    }
                }
                else {
                    this.uploadedFile = [];
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File', detail: 'File size exceeds the maximum limit of ' + this.maxAllowedFileSizeMB + ' MB' });
                    this.clearSingleFile();
                    this.showExtractPageRangePDF = false;
                }
            }
            else {
                this.uploadedFile = [];
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Invalid File.', detail: 'Only valid files are allowed.' });
                this.clearSingleFile();
                this.showExtractPageRangePDF = false;
            }
        }
    }
    //#endregion


    //#region Merge Online
    OpenMergeModal() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }

        let selectedRows = this._sharedService.selectedDocCount;
        let selectedDocs = this._sharedService.selectedDocuments;
        if (selectedDocs && selectedRows && selectedRows == 2) {

            this.mergeOnlineDialogTitle = "Insert Files (ROAM)";
            this.isDisplayMergeOnlineDialog = true;
            this.populateDropDown_Location();
            this.populateDropDown_Document();
            this.resetModel(MergeOperation.Merge);
            this.changeButtonsStatus(false);
            if (this._sharedService.selectedDocuments.length > 0) {
                this.pdfMergeOnlineModel.MasterFile = this._sharedService.selectedDocuments[0].Url;
            }
            this.pdfMergeOnlineModel.Location = "After";


        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Warning', detail: 'Please select two Documents for merging.' });
        }
    }



    btnSubmitMergeOnline(form: NgForm) {

        if (this.pdfMergeOnlineModel.IsDelete == true) {

            this._confirmationService.confirm({
                message: 'Do you want to delete document?',
                header: 'Delete Confirmation',
                icon: 'fa fa-trash',
                accept: () => {
                    this.mergeOnline(form);
                },
                reject: () => {
                }
            });
        }
        else {
            this.mergeOnline(form);
        }



    }


    mergeOnline(form: NgForm): void {

        this.changeButtonsStatus(true);
        this.pdfMergeOnlineModel.SecondaryFile = this.selectedDocs.find(a => a.value != this.pdfMergeOnlineModel.MasterFile).value;
        let body = this.pdfMergeOnlineModel;
        this._sharedService.showLoader("Processing...");
        this.pdfManipulationService.mergeDocumentsOnline(body)
        .subscribe({
            next: (response) => {
                this._sharedService.hideLoader();
                this.isDisplayMergeOnlineDialog = false;
                this._sharedService.isDocManipulated = true;
                setTimeout(() => {
                    this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                    this._sharedService.onAssetChanged(this._sharedService.selectedAsset);
                }, 2000);
                this.resetModel(MergeOperation.Merge);
                form.resetForm();
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Document(s) merged succesfully.' });
                this.changeButtonsStatus(false);
            },
            error: (error) => {
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                if (error.status === 400) {
                    this._sharedService.showToast({ severity: 'error', summary: 'Invalid page range', detail: 'Please enter valid page no.' });
                    this.changeButtonsStatus(false);
                }
                else if (error.status === 409) {
        
                    this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File', detail: 'File size exceeds the maximum limit of ' + this.maxAllowedFileSizeMB + ' MB' });
                    this.changeButtonsStatus(false);
                }
                else {
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Operation fails, please try again.' });
                    this.isDisplayMergeOnlineDialog = false;
                    this.resetModel(MergeOperation.Merge);
                    this.changeButtonsStatus(true);
                }
            }
        });
    }

    btnMergeOnlineCancelClick(form: NgForm) {
        this.changeButtonsStatus(false);
        this.isDisplayMergeOnlineDialog = false;
        form.resetForm();
        this.resetModel(MergeOperation.Merge);
    }
    //#endregion




    //#region Delete Online
    OpenDeleteModal() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        let selectedRows = this._sharedService.selectedDocCount;
        let selectedDocs = this._sharedService.selectedDocuments;
        if (selectedDocs && selectedRows && selectedRows == 1) {
            this.deleteDialogTitle = "Delete Pages";
            this.resetModel(MergeOperation.Delete);
            this.isDisplayDeleteOnlineDialog = true;
            this.changeButtonsStatus(false);

        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Warning', detail: 'Please select one document for processing.' });
        }
    }




    btnSubmitDeleteClick(form: NgForm) {

        this._confirmationService.confirm({
            message: 'Do you want to delete page(s)?',
            header: 'Delete Confirmation',
            icon: 'fa fa-trash',
            accept: () => {
                this.deleteDocumentPages(form);
            },
            reject: () => {
            }
        });
    }


    deleteDocumentPages(form: NgForm): void {

        this.changeButtonsStatus(true);
        this.pdfDeleteModel.MasterFile = this._sharedService.selectedDocuments[0].Url
        let body = this.pdfDeleteModel;
        this._sharedService.showLoader("Processing...");
        this.pdfManipulationService.deletePDF(body)
        .subscribe({
            next: (response) => {
                this._sharedService.hideLoader();
                this.resetModel(MergeOperation.Delete);
                form.resetForm();
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Pages deleted succesfully.' });
                this.changeButtonsStatus(false);
                this._sharedService.isDocManipulated = true;
                setTimeout(() => {
                    this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                    this._sharedService.onAssetChanged(this._sharedService.selectedAsset);
                }, 2000);
                this.isDisplayDeleteOnlineDialog = false;
            },
            error: (error) => {
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                if (error.status === 400) {
                    this._sharedService.showToast({ severity: 'error', summary: 'Invalid page range', detail: 'Please enter valid page range.' });
                    this.changeButtonsStatus(false);
                }
                else if (error.status === 406) {
                    this._sharedService.showToast({ severity: 'error', summary: 'Warning', detail: 'This is the last page in the document.' });
                    this.changeButtonsStatus(false);
                }
                else if (error.status === 416) {
                    this._sharedService.showToast({ severity: 'error', summary: 'Warning', detail: 'All pages in document cannot be deleted.' });
                    this.changeButtonsStatus(false);
                }
                else {
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Operation fail, please try again.' });
                    this.isDisplayDeleteOnlineDialog = false;
                    this.resetModel(MergeOperation.Delete);
                    this.changeButtonsStatus(true);
                }
            }
        });
    }



    btnDeletePDFCancelClick(form: NgForm) {
        this.changeButtonsStatus(false);
        this.isDisplayDeleteOnlineDialog = false;
        form.resetForm();
        this.resetModel(MergeOperation.Delete);
    }
    //#endregion


    resetModel(operation: MergeOperation): void {
        switch (operation) {
            case MergeOperation.Extract:
                this.metaData = new PDFExtractPage();
                break;
            case MergeOperation.Insert:
                this.pdfMergeLocalModel = new PDFMerge();
                this.uploadedFile = [];
                break;
            case MergeOperation.Merge:
                this.pdfMergeOnlineModel = new PDFMerge();
                break;
            case MergeOperation.Delete:
                this.pdfDeleteModel = new PDFDelete();
                break;
            default:
        }
    }

    populateDropDown_Document(): void {

        this._sharedService.selectedDocument
        let SelectedDocs = this._sharedService.selectedDocuments;
        let formattedData = this.getSelectItems(SelectedDocs);
        this.selectedDocs = formattedData;/*<SelectItem[]>JSON.parse(document);*/


    }

    getSelectItems(selectedRows: any[]): SelectItem[] {
        let itemList: SelectItem[] = [];
        for (let i = 0; i < selectedRows.length; i++) {
            let label = this._sharedService.getFileNameFromURL(selectedRows[i].Url, true); // this.getFileName(selectedRows[i].Url);
            itemList.push({ label: label, value: selectedRows[i].Url, styleClass: null });
        }
        return itemList;
    }

    // getFileName(fileUrl: string): string {
    //     let newString: string[] = fileUrl.split('?')[0].split('/');
    //     let fileName: string = newString[newString.length - 1];
    //     return fileName;
    // }
    populateDocTypeDropdown() {
        this._documentTypeService.getDocumentTypes('')
        .subscribe({
            next: (response) => {
                this.DocType = [];
                this.DocType = response;
            },
            error: (error) => {
            }
        });
    }
    populateTagsDropdown() {
        this._tagService.getAllTags()
        .subscribe({
            next: (response) => {
                this.tags = response;
            },
            error: (error) => {
            }
        });
    }
    populateDropDown_Location(): void {

        let location = '[{ "label": "Before", "value": "Before" }, { "label": "After", "value": "After" }]';
        this.DocLocation = <SelectItem[]>JSON.parse(location);

    }


    setCalenderDateFormat() {
        this.configuration = this._sharedService.getConfiguration();
        this.dateFormat = this.configuration.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
        this.dateSeparator = this.configuration.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
        this.day = Number(this.configuration.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
        this.month = Number(this.configuration.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
        this.year = Number(this.configuration.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
        let dateParts: string[] = this.dateFormat.split(this.dateSeparator);

        if (this.year == 0) {
            this.dateFormatCalender = (dateParts[this.year].substr(0, 2) + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.day]).toLowerCase();
        }
        else if (this.year == 2) {
            if (this.month == 1) {
                this.dateFormatCalender = (dateParts[this.day] + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
            }
            else if (this.month == 0) {
                this.dateFormatCalender = (dateParts[this.month] + this.dateSeparator + dateParts[this.day] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
            }

        }
        else {
            this.dateFormatCalender = "yy-mm-dd"
        }

    }


    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }
        switch (this.recordType) {
            case 'MXR':
                this.canMXRQualityStatus = this._sharedService.UserRole.Records_MXR_Doc_Quality_Status;
                this.processMXRQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('MX_Records_Quality_Process');
                break;
            case 'LOG':
                this.canLOGQualityStatus = this._sharedService.UserRole.Records_LOG_Doc_Quality_Status;
                this.processLOGQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('Log_Books_Quality_Process');
                break;
            case 'GEN':
                this.canGENQualityStatus = this._sharedService.UserRole.Records_GEN_Doc_Quality_Status;
                this.processGENQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('General_Quality_Process');
                break;
            case 'DOC':
                this.canDOCQualityStatus = this._sharedService.UserRole.Records_DOC_Doc_Quality_Status;
                this.processDOCQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('Documents_Quality_Process');
                break;
            case 'BND':
                this.canBNDQualityStatus = this._sharedService.UserRole.Transition_BND_Doc_Quality_Status;
                this.processBNDQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('Binders_Quality_Process');
                break;
        }
    }

    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }
    getOCRStatus(value: any): number {
        if (value) {
            return 1;
        }
        else {
            return 0;
        }
    }


    getRecordType() {

        let url = this._router.url;

        if (url.indexOf("/record/browse") > -1) {
            this.recordType = "MXR";

        }
        else if (url.indexOf("/record/logs") > -1) {
            this.recordType = "LOG";
        }
        else if (url.indexOf("/record/general") > -1) {
            this.recordType = "GEN";
        }
        else if (url.indexOf("/record/documents") > -1) {
            this.recordType = "DOC";
        }
        else if (url.indexOf("/transition/binders") > -1) {
            this.recordType = "BND";
        }
        else if ((url.indexOf("/record/search") > -1) || (url.indexOf("/transition/search") == -1)) {
            this.recordType = "Search";

        }

    }

    applyFocusToInput() {

        this.focusInput.nativeElement.focus();
    }

    onKey() {

        this.changeButtonsStatus(false);
        this.isDisplayExtractPagesDialog = false;
        this.resetModel(MergeOperation.Extract);
    }

    onKeyDeletePop() {
        this.changeButtonsStatus(false);
        this.isDisplayDeleteOnlineDialog = false;
        this.resetModel(MergeOperation.Delete);
    }

    onKeyMergeOnline() {
        this.changeButtonsStatus(false);
        this.isDisplayMergeOnlineDialog = false;
        this.resetModel(MergeOperation.Merge);

    }

    onKeyMergeLocal() {
        this.changeButtonsStatus(false);
        this.isDisplayMergeLocalDialog = false;
        this.resetModel(MergeOperation.Insert);
        this.clearSingleFile();
    }

    applyFocusToLocal() {

        this.focusLocal.nativeElement.focus();
    }
    isChecked: boolean = false
    ToggleChecked() {

        if (this.isChecked) {
            this.isChecked = false;
            this.metaData.IsDelete = false;
        }
        else {
            this.isChecked = true;
            this.metaData.IsDelete = true;
        }
    }
    isCheckedIsDelete: boolean = false
    ToggleCheckedIsDelete() {

        if (this.isCheckedIsDelete) {
            this.isCheckedIsDelete = false;
            this.pdfMergeOnlineModel.IsDelete = false;
        }
        else {
            this.isCheckedIsDelete = true;
            this.pdfMergeOnlineModel.IsDelete = true;
        }
    }
    onCloseEnddate() {
        var docNumber = document.getElementById('docNumber');
        docNumber.focus();
    }
}
