import { Component, EventEmitter, Input, Output, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { SharedService } from 'src/app/shared/services/shared.service';
import { APIService } from '../../services/api.service';
import { AssetService } from 'src/app/admin/services/asset/asset.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { datafeed } from '../list-datafeed/data-feed';
import { Router } from '@angular/router';
import { AddUpdateStatusCode } from 'src/app/shared/helpers/config';

@Component({
  selector: 'export-container-configuration',
  templateUrl: './export-container-configuration.component.html',
  providers: [ConfirmationService, SharedService, APIService, AssetService]
})
export class ExportContainerConfigurationComponent implements OnInit,AfterViewInit,OnDestroy {

  @Input() feedId;
  @Input() isRecordExport: boolean;
  @Input() redirectUri: string;
  @Input() sourceType;
  @Output() btnCancelEvent = new EventEmitter<any>();
  @Input() selectedFeedConfiguration: string = '';
  tempAssetList: SelectItem[] = [];
  assetList: SelectItem[] = [];
  selectedAssets: string[] = [];
  trackByIndex: number;
  isCheckedAll: boolean = false;
  @Input() isTempFeed: boolean = false;
  generateButtonLabel: string;
  generateoMyReportsBtn: string;
  dataTypes: SelectItem[] = [
    { label: 'CSV', value: 'CSV' },
    { label: 'TSV', value: 'TSV' },
    { label: 'XLSX', value: 'XLSX' },
    { label: 'XML', value: 'XML' }
  ];
  containerCategoriesList: SelectItem[] = [];
  config: {
    recordType: string,
    inputFeedId: string
  };
  /**
   *
   */
  constructor(
    private _sharedService: SharedService,
    private _apiService: APIService,
    private _assetService: AssetService,
    private _router: Router,
    private _cdr:ChangeDetectorRef
  ) {
    // if (this.feedId) this.isTempFeed = false; else this.isTempFeed = true;
    }
  ngOnInit(): void {
    this.setContainerCategoryList()
    this.setButtonLabels();
    this.prepareFeedObject()
    this.getAssets();
  }
  ngAfterViewInit(): void {
    document.getElementById('row').classList.remove('row');  
  }
  ngOnDestroy(): void {
    if (this.config.recordType == 'ExportTemp') {
      /// Delete the current Feed      
      this._apiService.removeTempFeed(this.tempFeed)
        .subscribe({
          next: (response) => {
          },
          error: (error) => {
            console.error('Error occurred while removing feed.');
          }
        })
    }
  }
  setContainerCategoryList() {
    this.containerCategoriesList = [];
    this.containerCategoriesList = this._sharedService.getContainerCategoryList();
    if (SharedService.current_ParentMenu_Id == 113) { // transition
      this.containerCategoriesList = this.containerCategoriesList
        .filter(a => a.value == 'BND');
    } else if (SharedService.current_ParentMenu_Id == 16){ // records 
      this.containerCategoriesList = this.containerCategoriesList
        .filter(a => a.value != 'BND');      
    }
  }
  onSearch(event:string) {
    this.tempAssetList = this.assetList
      .filter(a => a.label.toLocaleLowerCase().includes(event.toLocaleLowerCase()));
    this.isCheckedAll = this.tempAssetList.some(a => !a['isSelected']) ? false : true;
  }
  setButtonLabels() {
    this.generateoMyReportsBtn = 'Generate to My Reports';
    this.generateButtonLabel = !this.isTempFeed ? 'Save' : 'Generate and Email';
  }
  prepareFeedObject() {
    if (this.isTempFeed) {
      this.config = {
        recordType: 'ExportTemp',
        inputFeedId: null,
      }
      this.selectedFeedConfiguration = `CON_LST_${this._apiService.getTempFeedTimeStamp()}`;
      this.tempFeed =
      {
        FeedName: `CON_LST_${this._apiService.getTempFeedTimeStamp()}`,
        FeedType: this.config.recordType,
        Interval: "None",
        SourceType: this.isRecordExport ? 'Records Export' :'Transition Export',
        TargetType: 'Email Link',
        Status: true,
        TemplateName: 'Export Container List',
        FeedScope: "Private"
      }      
      this.tempFeed.DataType = 'CSV';
      this.tempFeed.SourceDetail = 'MXR';
      this.addTempFeed()
    }
    if (!this.isTempFeed) {
      this.config = {
        recordType: null,
        inputFeedId: this.feedId,
      }
      this.selectedFeedConfiguration = `${this.selectedFeedConfiguration}}`;
      this.getFeedMappingById();
    }
    
  }
  getFeedMappingById() {
    this._sharedService.showLoader("Processing...");
    this._apiService.GetFeedMappingById(this.config.inputFeedId).subscribe(result => {
      if (result) {
        this.selectedAssets = result.map(a => a.toString());
        this.mapAssetIds()
      }
    }).add(() => this._sharedService.hideLoader());
  }
  mapAssetIds() {
    // onlyin case if feed is running form export
    if (this.config && this.selectedAssets && this.tempAssetList) {
      this.selectedAssets.forEach(assetId => {
        let asset = this.tempAssetList.find(a => a.value == assetId);
        asset['isSelected'] = true;
      });
      let event={target:{checked:true}}
      this.onChangeCheckBox(event)
    }
  }
  tempFeed: datafeed ;
  addTempFeed() {    
    this._apiService.addFeedAndReturnFeedID(this.tempFeed)
      .subscribe({
        next: (response) => {
          // this._sharedService.hideLoader();
          this.tempFeed.FeedId = response;
          this.config.inputFeedId = this.tempFeed.FeedId;
        },
        error: (error) => {
          this._sharedService.clearToast();
          this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Error Occurred, please try again.' });
        }
      });
  }
  getAssets() {
    // this._sharedService.showLoader('Loading...')
    this._assetService.getAllAssetsWithAsn()
      .subscribe(result => {
        this.tempAssetList = result;
        this.assetList = result;
      })
      .add(() => {
        this.mapAssetIds();
        // this._sharedService.hideLoader()
      });
  }

  btnCancelClick() {
    if (this.config.recordType == 'ExportTemp') {     
      /// Delete the current Feed      
      this._apiService.removeTempFeed(this.tempFeed)
        .subscribe({
          next: (response) => {
          },
          error: (error) => {
            this._sharedService.showToast(
              {
                severity: 'error',
                summary: 'Error Occured',
                detail: 'Error occurred while removing feed.'
              }
            );
          }
        })
    }
    this.close()
  }
  close() {
    if (this.isTempFeed)
    {
      this._router.navigate([this.redirectUri]);
    }
    if (!this.isTempFeed) {
      this.btnCancelEvent.emit();
    }
  }
  onChangeCheckBox(event: any, asset: any = null) {
    let isChecked = event.target.checked;

    let tempAsset = this.assetList.find(a => a.value == asset?.value);
    if(tempAsset)
      tempAsset['isSelected'] = isChecked;
    if (!isChecked) {
      this.isCheckedAll = isChecked
    };
    if (isChecked) {
      this.isCheckedAll = this.tempAssetList.some(a => !a['isSelected']) ? false : true;
    }
  }
  chechAll(event: any) {
    this.isCheckedAll = event.target.checked;
    for (var i = 0; i < this.tempAssetList.length; i++) {
      this.tempAssetList[i]['isSelected'] = this.isCheckedAll;
      this.assetList[i]['isSelected'] = this.isCheckedAll;
    }
  }
  onTargetContainerChange(eventData) {
    this.tempFeed.TargetType = 'ROAM Container';
    this.tempFeed.TargetDetail = eventData.targetDetail;
    this.save()
  }
  generateToMyReports() {
    this.tempFeed.TargetType = 'My Report';
    this.save();
  }
  generateAndEmail() {
    if(this.tempFeed)
    {
      this.tempFeed.TargetType = 'Email Link';
    }
    this.save()
  }
  save() {
    let mapping = this.assetList.filter(a => a['isSelected']).map(a => a.value).join(',');
    if (!mapping) {
      this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Please select atleast one asset.' });
      return;
    }

    if (this.isTempFeed) {
      this.tempFeed.Mapping = mapping;
      this._apiService.updateFeed(this.tempFeed).subscribe({
        next: (result) => {          
          this._apiService.RunTempFeed(this.tempFeed.FeedId)
          .subscribe(() => { 
            this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'The Compilation process has now started.' });
              this.tempFeed.FeedId = null;
              this.close();
            });
        },
        error: (err) => {
          this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'An error occured.' });
        }
      })
      return;
    }
    let SaveExportContainerApiMapping = this._apiService.SaveExportContainerApiMapping(this.config.inputFeedId, mapping);
    this._sharedService.showLoader("Processing...");
    SaveExportContainerApiMapping.subscribe({
      next: (result) => {
        this._sharedService.hideLoader();
        this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Configuration Saved.' });
        this.close();
      },
      error: (error) => {
        this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'An error occured.' });
      }
    })
  }
}

