import { Component, OnInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, Params, NavigationEnd, NavigationStart } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
import { GridOptions, GridApi, ColumnApi, RowNode } from "ag-grid-community";
import { SearchService } from '../../services/search/search.service';
import { RebuildSearch } from '../reset-azure-search/reset-azure-search';
import * as moment from 'moment/moment';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
import { ConfirmationService } from 'primeng/api';

var dateTimeConfig: IDateFormatInfo;
@Component({
  selector: 'app-reset-azure-search',
  templateUrl: './reset-azure-search.component.html',
  providers: [SearchService, ConfirmationService]
})
export class ResetAzureSearchComponent implements OnInit {


  constructor(public _sharedService: SharedService, private _confirmationService: ConfirmationService, private router: Router, private route: ActivatedRoute, private renderer: Renderer2, private adminSearchService: SearchService) {
    dateTimeConfig = this._sharedService.getDateTimeFormatConfiguration();
  }


  public gridIndexOptionsColumns: GridOptions;
  indexColumnDefinition: any[];
  public gridIndexDataColumns: any[] = [];
  indexers: any[] = [];

  public gridIndexerOptionsColumns: GridOptions;
  indexerColumnDefinition: any[];
  public gridIndexerDataColumns: any[] = [];



  ngOnInit() {
    if (!this._sharedService.isAuthUser()) {
      this.router.navigate(['login']);
    }

    this.initilaizeIndexGrid();
    this.initilaizeIndexerGrid();
    this.loadSearchComponentsDetail();

  }

  initilaizeIndexGrid() {
    this.gridIndexOptionsColumns = <GridOptions>
      {
        rowSelection: 'single',
        rowData: [],
        onGridReady: (params) => {

          this.setIndexGridColumnsDef();
        },


        stopEditingWhenCellsLoseFocus: true,
        context: {
          gridCtx: this
        }
      };


  }

  setIndexGridColumnsDef(): void {
    this.indexColumnDefinition = [

      { headerName: '', field: 'SearchDatabaseID', editable: false, filter: false, suppressMenu: true, hide: true, },
      { headerName: 'Search Database', field: 'SearchDatabase', editable: false, filter: false, suppressMenu: true },
      { headerName: 'Name', field: 'Name', editable: false, filter: false, suppressMenu: true },

      { headerName: 'Document Count', field: 'DocumentCount', editable: false, suppressMenu: true, filter: false },
      {
        headerName: 'Storage Size', field: 'FormatedStorageSize', editable: false, suppressMenu: true, filter: false
      },

    ];
  }



  initilaizeIndexerGrid() {


    this.gridIndexerOptionsColumns = <GridOptions>
      {
        rowSelection: 'multiple',
        rowData: [],
        onGridReady: (params) => {
          this.setIndexerGridColumnsDef();
        },
        defaultColDef: {
          sortable: true,
          resizable: true,
        },
        onModelUpdated: (event: any) => {
          if (this.gridIndexerOptionsColumns.api) {
            this.gridIndexerOptionsColumns.api.sizeColumnsToFit();
          }

        },
        context: {
          gridCtx: this
        }
      };


  }

  setIndexerGridColumnsDef(): void {
    this.indexerColumnDefinition = [

      { headerName: 'Status', field: 'Status', cellRenderer: this.statusCellRenderer, editable: false, suppressMenu: true, filter: false, minWidth: 262 },
      { headerName: 'Name', field: 'Name', editable: false, filter: false, suppressMenu: true , minWidth: 262},
      { headerName: 'Target Index', field: 'IndexName', editable: false, filter: false, suppressMenu: true , minWidth: 262},
      { headerName: 'Last Run', field: 'LastRun', editable: false, suppressMenu: true, filter: false, valueFormatter: this.dateTimeValueFormater, minWidth: 262 },
      { headerName: 'Docs succeeded', field: 'ExecutionCount', editable: false, suppressMenu: true, filter: false, minWidth: 262 },
    ];
  }

  onTabChange() {
    this.gridIndexerDataColumns = [];
    let selectedIndexes = this.gridIndexOptionsColumns.api.getSelectedNodes();
    if (!(selectedIndexes && selectedIndexes.length > 0)) {
      this._sharedService.clearToast();
      this._sharedService.showToast({ severity: 'warn', summary: 'Warning!', detail: 'No Indexes row Selected' });
      return;
    }

    if (this.indexers) {
      this.gridIndexerOptionsColumns.api.sizeColumnsToFit();
      let index = selectedIndexes[0].data;
      this.gridIndexerDataColumns = this.indexers.filter(a => a.SearchDatabaseID == index.SearchDatabaseID);
    }

  }

  loadSearchComponentsDetail(): void {

    if (!this._sharedService.isAuthUser()) {
      this.router.navigate(['login']);
    }

    this._sharedService.clearToast();
    this._sharedService.showLoader("Processing...");
    this.adminSearchService.getSearchComponents()
      .subscribe({
        next: (response) => {
          this._sharedService.hideLoader();

          this.gridIndexDataColumns = [];
          this.gridIndexerDataColumns = [];
          this.indexers = [];
          let msgDictionary = response;
          let indexes: any[] = [];

          for (let index = 0; index < response.length; index++) {
            indexes.push.apply(indexes, response[index].Indexes);
            this.indexers.push.apply(this.indexers, response[index].Indexers);
          }

          this.gridIndexDataColumns = indexes;
          //this.gridIndexerDataColumns = this.indexers;      

          let selectedIndexes = this.gridIndexOptionsColumns.api.getSelectedNodes();
          if (!(selectedIndexes && selectedIndexes.length > 0)) {
            this.gridIndexerDataColumns = [];

          }
          else if (this.indexers) {
            this.gridIndexerDataColumns = [];
            let index = selectedIndexes[0].data;
            this.gridIndexerDataColumns = this.indexers.filter(a => a.SearchDatabaseID == index.SearchDatabaseID);
          }

          //this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Aircraft Updated succesfully.' });
        },
        error: (error) => {
          this._sharedService.hideLoader();
          this._sharedService.clearToast();
          this._sharedService.showToast(
            {
              severity: 'warn',
              summary: 'Warning!',
              detail: 'An error occured! please try again after a few minutes'
            });
        }
      });
  }


  rebuildAzureSearch(): void {
    if (!this._sharedService.isAuthUser()) {
      this.router.navigate(['login']);
    }

    this._sharedService.clearToast();
    let selectedIndexes = this.gridIndexOptionsColumns.api.getSelectedNodes();
    if (selectedIndexes.length <= 0) {
      this._sharedService.clearToast();
      this._sharedService.showToast({ severity: 'warn', summary: 'No record Selected!', detail: 'Please select a record.' });
      return;
    }

    let msg = "";
    msg = 'Are you sure you want continue, It may take some time?';
    this._confirmationService.confirm({
      message: msg,
      header: 'Warning!',
      //icon: 'fa fa-w',
      accept: () => {
        this.rebuildSearch();
      },
      reject: () => {

      }
    });
  }


  confirmDeleteOperation(): void {
    if (!this._sharedService.isAuthUser()) {
      this.router.navigate(['login']);
    }

    this._sharedService.clearToast();
    let selectedIndexers = this.gridIndexerOptionsColumns.api.getSelectedNodes();
    if (!(selectedIndexers && selectedIndexers.length > 0)) {
      this._sharedService.clearToast();
      this._sharedService.showToast({ severity: 'warn', summary: 'Warning!', detail: 'No Indexer row(s) Selected' });
      return;
    }

    let msg = "";
    msg = 'Are you sure you want continue, It may take some time?';
    this._confirmationService.confirm({
      message: msg,
      header: 'Warning!',
      //icon: 'fa fa-w',
      accept: () => {
        this.deleteIndexer();
      },
      reject: () => {

      }
    });
  }

  deleteIndexer() {

    let selectedIndexes = this.gridIndexOptionsColumns.api.getSelectedNodes();
    let selectedIndexers = this.gridIndexerOptionsColumns.api.getSelectedNodes();
    let indexers: string[] = [];

    if (selectedIndexers) {
      for (let index = 0; index < selectedIndexers.length; index++) {
        let indexerName = selectedIndexers[index].data.Name;
        indexers.push(indexerName);
      }
    }

    let index = selectedIndexes[0].data;
    if (!(index && index.hasOwnProperty('Name'))) {
      return;
    }

    this._sharedService.showLoader("Processing...");
    let body: RebuildSearch = {
      indexName: index.Name,
      SearchDatabase: index.SearchDatabaseID,
      isDelete: true,
      indexers: indexers

    }


    this.adminSearchService.deleteIndexer(body)
      .subscribe({
        next: (response) => {
          this._sharedService.hideLoader();
          let msgDictionary = response;
          this._sharedService.clearToast();
          this._sharedService.showToast(
            {
              severity: 'success',
              summary: 'Success',
              detail: 'Indexer(s) deleted Successfully.'
            }
          );

          this.loadSearchComponentsDetail();
          //this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Aircraft Updated succesfully.' });
        },
        error: (error) => {
          this._sharedService.hideLoader();
          this._sharedService.clearToast();
          this._sharedService.showToast(
            {
              severity: 'warn',
              summary: 'Warning!',
              detail: 'An error occured! please try again after a few minutes'
            }
          );
        }
      });
  }


  rebuildSearch() {

    let selectedIndexes = this.gridIndexOptionsColumns.api.getSelectedNodes();
    let index = selectedIndexes[0].data;
    if (!(index && index.hasOwnProperty('Name'))) {
      return;
    }

    this._sharedService.showLoader("Processing...");
    let body: RebuildSearch = {
      indexName: index.Name,
      SearchDatabase: index.SearchDatabaseID,
      isDelete: false,
      indexers: []
    }

    this.adminSearchService.rebuildSearch(body)
      .subscribe({
        next: (response) => {
          this._sharedService.hideLoader();
          let msgDictionary = response;
          this._sharedService.clearToast();
          this._sharedService.showToast(
            {
              severity: 'success',
              summary: 'Success',
              detail: 'Search rebuilt successfully.'
            }
          );

          //this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Aircraft Updated succesfully.' });
        },
        error: (error) => {
          this._sharedService.hideLoader();
          this._sharedService.clearToast();
          this._sharedService.showToast(
            {
              severity: 'warn',
              summary: 'Warning!',
              detail: 'An error occured! please try again after a few minutes'
            }
          );
        }
      });
  }

  //#region custom cell render
  statusCellRenderer(params: any) {
    let html: string = '';
    let arrStatus = String(params.value).split("!");
    let status = arrStatus[0];
    let msg = arrStatus[1];

    if (status) {

      if (status == 'Success') {
        html = html + '<i class="fa fa-check-circle" style= "color:green !important"></i>&nbsp;&nbsp;' + '<span class="padding-5 label" style= "text-align: center !important; display: inline !important; font-size: small;" title="' + msg + '" >' + status + '</span>';
        // html = html + '<span class="padding-5 label fa fa-check-circle"" style= "text-align: center !important; display: inline !important; font-size: small; color:green !important; title="' + msg + '" >' + '</span>'+status;
      }
      else if (status == 'InProgress') {
        html = html + '<i class="fa fa-exclamation-circle" style= "color:blue !important; margin-left:17px !important; "></i>' + '<span class="padding-5 label" style= "text-align: center !important; display: inline !important; font-size: small; margin-left:7px !important;" title="' + msg + '" >' + "In progress" + '</span>';
        // html = html + '<span class="padding-5 label fa fa-check-circle"" style= "text-align: center !important; display: inline !important; font-size: small; color:green !important; title="' + msg + '" >' + '</span>'+status;
      }
      else if (status == 'Reset') {
        html = html + '<i class="fa fa-exclamation-circle" style= "color:blue !important"></i>&nbsp;&nbsp;' + '<span class="padding-5 label" style= "text-align: center !important; display: inline !important; font-size: small;" title="' + msg + '" >' + status + '</span>&nbsp;&nbsp;&nbsp;';
        // html = html + '<span class="padding-5 label fa fa-check-circle"" style= "text-align: center !important; display: inline !important; font-size: small; color:green !important; title="' + msg + '" >' + '</span>'+status;
      }
      else {
        // html = html + '<span class="padding-5 label" style= "background-color:red !important;text-align: center !important; color: #eeeeee !important; display: inline !important; font-size: small;" title="' + params.value + '" >' + params.value + '<i class="fas fa-exclamation-circle"></i>'+ '</span>';
        // html = html + '<span class="padding-5 label fa fa-exclamation-circle"" style= "text-align: center !important; display: inline !important; font-size: small; color:red !important; title="'+ 'hello' +  '" >hello' + '</span>'+status+'&nbsp;&nbsp;&nbsp;&nbsp;';
        html = html + '<i class="fa fa-exclamation-circle" style= "color:red !important"></i>&nbsp;&nbsp;' + '<span class="padding-5 label" style= "text-align: center !important; display: inline !important; font-size: small;" title="' + msg + '" >' + status + '</span>&nbsp;&nbsp;&nbsp;';
      }
    }

    var eDiv = document.createElement('div');
    eDiv.innerHTML = html;
    eDiv.style.textAlign = 'center';
    return eDiv;
  }
  //#endregion

  dateTimeValueFormater(params: any) {
    if (params.value != "" && params.value != null) {
      let dateString = params.value;
      return moment(dateString).format(dateTimeConfig.dateformat.toUpperCase() + ' ' + dateTimeConfig.timeformat);
    }
  }



}