//#region References
import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
// import { APIService } from '../../services/api.service';
// import { feedlog } from '../../components/feed-log/feed-log';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { GridOptions, RowDataTransaction } from "ag-grid-community";
import "ag-grid-enterprise";
import { Subscription } from 'rxjs';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
import { IConfig } from '../../../shared/helpers/config';

import * as moment from 'moment/moment';
import { CustomDateComponent } from '../../../shared/components/custom-date/custom-date.component';
import { feedlog } from 'src/app/api/components/feed-log/feed-log';
import { APIService } from 'src/app/api/services/api.service';
import { HelpService } from 'src/app/shared/services/Help/help.service';
import { ViewLinkedRecordInboxComponent } from 'src/app/comm/components/comm/view-inbox-button.component';
import { DownloadReportComponent } from '../download-report/download-report.component';
import { OnDestroy } from '@angular/core';
import { AzureBlobService } from 'src/app/shared/services/AzureBlob/azure-blob.service';

var dateTimeConfig: IDateFormatInfo;
var gridTimeout: any[] = [];

//#endregion

//#region Type Decorator
@Component({
    selector: 'app-report-screen',
    templateUrl: './report-screen.component.html',
    providers: [APIService, HelpService,AzureBlobService]
})
//#endregion

export class ReportScreenComponent implements OnInit, OnDestroy {

    //#region Locals Declaration

    feedlogid: string;
    screenName: string = "";
    routeName: string = "";

    totalRecords: Number;
    public totalCount: number;
    dataFeedId: string;

    feedlogobj: feedlog;
    subscription: Subscription;
    public gridOptions: GridOptions;

    selectedDpJob: feedlog[];
    columnDefs: any[];

    public gridData: any[] = [];

    getRowNodeId: any;

    //#endregion

    //#region Initialization of Component
    constructor(private _sharedService: SharedService,private _azureBlobService: AzureBlobService, private _router: Router, private _route: ActivatedRoute, private _apiService: APIService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.getRowNodeId = function (params: any) {
                // selectedFeedId = data.FeedId ;
                return params.data.FeedId;
            };
            dateTimeConfig = this._sharedService.getDateTimeFormatConfiguration();
        }
    }


    DownloadLinkForReport() {
        // let FeedLog: feedlog = { FeedLogId: this.feedlogobj.FeedLogId };
        let FeedLog: feedlog = { FeedLogId: '3' };
        this._apiService.DownloadLinkForReport(FeedLog)
        .subscribe({
            next: (response) => {
                this._sharedService.hideLoader();
                let blob = new Blob([response], { type: 'application/zip' });
                let url = URL.createObjectURL(blob);
                // this.formatZipFileName(url, this.feedlogobj.FileName.substring(0, this.feedlogobj.FileName.lastIndexOf(".")));
            },
            error: (error) => {
                this._sharedService.hideLoader();
                // if (error.status === 409) 
                // {
                //     this._sharedService.clearToast();
                //     this._sharedService.showToast({ severity: 'warn', summary: 'Not Allowed', detail: `Not functional for FTP`});
                // }
                // else
                // {
                console.log(error);
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Error.', detail: 'File not exists.' });
                // }
            }
        });
    }

    ngOnInit() {
        this.gridOptions = <GridOptions>
            {
                rowSelection: 'single',
                rowData: [],
                onGridReady: () => {
                    this.setFeedLogGridColumns();
                    if (this.gridOptions.api != undefined) {
                        if (this.gridData.length > 0) {
                            this.gridOptions.api.hideOverlay();
                            try {
                                this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSizeHavingBeadCrumb('agGridFeedLog'));
                                this.gridOptions.api.hideOverlay();
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }

                },
                onModelUpdated: (event: any) => {
                    if (this.gridOptions.api) {
                        this.totalCount = this.gridOptions.api.getDisplayedRowCount();
                        this.gridOptions.api.sizeColumnsToFit();
                    }
                },
                pagination: true,
                paginationPageSize: 20,
                getContextMenuItems: this.getCustomContextMenuItems,
                context: {
                    gridContext: this
                }, defaultColDef: {
                    sortable: true,
                    resizable: true
                },
                components: { agDateInput: CustomDateComponent }
            }

        this.populateFeedLogGrid();

    }

    //#endregion
    ngOnDestroy() {
        if (gridTimeout && gridTimeout.length > 0) {
            gridTimeout.forEach(element => {
                clearTimeout(element);
            });
            gridTimeout = [];
        }
    }
    //#region Ag Grid Date Comparer
    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).split(dateTimeConfig.dateSeparator);
            var day = Number(dateParts[Number(dateTimeConfig.day)].substring(0, 2));
            var month = Number(dateParts[Number(dateTimeConfig.month)]) - 1;
            var year = Number(dateParts[Number(dateTimeConfig.year)]);

            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        }
        else {
            return -1;
        }
    }
    //#endregion

    //#region Ag Grid Population Event

    populateFeedLogGrid(): void {
        let body: feedlog = {
        }
        let sourceType: string = this._route.snapshot.data[0].recordType;
        this._apiService.getMyReport(sourceType)
        .subscribe({
            next: (response) => {
                this.gridData = [];
                this.gridData = response;
                this.totalCount = this.gridData.length;
                // if (this.totalCount > 0) {
                if (this.gridOptions.api) {
                    this.gridOptions.api.applyTransaction(this.gridData as RowDataTransaction);
                    this.gridOptions.api.refreshCells({ force: true });
        
                }
                let gridTime = setTimeout(() => {
                    this.populateFeedLogGridNew();
                }, 5000);
                gridTimeout.push(gridTime);
                //    }
            },
            error: (error) => {
            }
        });

    }


    populateFeedLogGridNew(): void {
        let body: feedlog = {
        }
        let sourceType: string = this._route.snapshot.data[0].recordType;
        this._apiService.getMyReport(sourceType)
        .subscribe({
            next: (response) => {
                this.gridData = [];
                this.gridData = response;
                this.totalCount = this.gridData.length;
        
        
        
                this.totalCount = this.gridData.length;
                if (this.gridData && this.gridData != null && this.totalCount >= 0) {
                    if (this.gridOptions.api) {
                        this.gridOptions.api.applyTransaction(this.gridData as RowDataTransaction);
                        this.gridOptions.api.refreshCells({ force: true });
        
                    }
                }
        
                if (this.totalCount && this.totalCount >= 0) { /*&& this.recordType == 'Import'*/
                    let gridTime = setTimeout(() => {
                        this.populateFeedLogGridNew();
                    }, 7000);
                    gridTimeout.push(gridTime);
                }
            }
        });

    }

    reBindGrid(selAsset: any) {
        this.populateFeedLogGrid();
    }

    DownloadReport(data: any) {
        let FeedLog: feedlog = { FileName: data.FeedName };
        this._apiService.DownloadLinkForReport(FeedLog)
        .subscribe({
            next: (response) => {
                if (response.url.indexOf('?sv=') != -1) {
                    this._sharedService.hideLoader();
                    // let blob = new Blob([response], { type: 'application/zip' });
                    // let url = URL.createObjectURL(blob);
                    let zipFileName = data.FeedName.replace(" ", "").replace(".", "");
                    this.formatZipFileName(response.url, zipFileName);
                }
                else{
                    this._azureBlobService.getPDFBlob(response.url)
                    .subscribe({
                        next: (res:any) => {
                            this._sharedService.hideLoader();
                            let blob = new Blob([res], { type: res.type });
                            let url = URL.createObjectURL(blob);
                            let fileNameArray = response.url.split('/');
                            var fileNameIndex = fileNameArray.length - 1;
                            var fileName = fileNameArray[fileNameIndex];
                            let zipFileName = data.FeedName.replace(" ", "").replace(".", "");
                            this.formatZipFileName(url, fileName);
                        },
                        error: (err) => {
                            this._sharedService.hideLoader();
                            console.log(err);
                        }
                    });
                }
                // this._sharedService.hideLoader();
                // // let blob = new Blob([response], { type: 'application/zip' });
                // // let url = URL.createObjectURL(blob);
                // let zipFileName = data.FeedName.replace(" ", "").replace(".", "");
                // this.formatZipFileName(response.url, zipFileName);
            },
            error: (error) => {
                this._sharedService.hideLoader();
                console.log(error);
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Error.', detail: 'File not exists.' });
                // }
            }
        });

    }
    //#endregion
    formatZipFileName(fileURL: string, fileName: string) {
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = fileURL;
        a.target = '_blank';
        a.download = fileName;
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
    }
    //#region Set Columns of Data Feeds Ag Grid
    setFeedLogGridColumns(): void {
        this.columnDefs = [];

        this.columnDefs =
            [
                {
                    headerName: 'Description', field: 'TemplateName', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 250,
                },
                {
                    headerName: 'Data Type', field: 'DataType', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 50
                },
                {
                    headerName: 'Source Type', field: 'SourceType', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 50,
                },
                {
                    headerName: 'Source Detail', field: 'SourceDetail', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 50,
                },
                // {
                //     headerName: 'Rows Recieved', field: 'RowsRecieved', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 50,
                // },
                {
                    headerName: 'Feed Name', field: 'FeedName', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 50, hide: true
                },
                {
                    headerName: 'Files Processed', field: 'RowsPopulated', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 50,
                },
                // {
                //     headerName: 'Rows Inserted', field: 'RowsInserted', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 30
                // },
                // {
                //     headerName: 'Rows Updated', field: 'RowsUpdated', filter: 'set', menuTabs: ['filterMenuTab']
                // },
                {
                    headerName: 'Executed On', field: 'ExecutedOn', filter: 'agDateColumnFilter', filterParams: {
                        comparator: this.dateComparater,
                        inRangeInclusive: 'true'

                    }, valueFormatter: function (params: any) {
                        if (params.value != "" && params.value != null) {
                            let dateString = params.value;
                            return moment(dateString).format(dateTimeConfig.dateformat.toUpperCase() + ' ' + dateTimeConfig.timeformat);
                        }
                    }, menuTabs: ['filterMenuTab'], minWidth: 140,
                },
                {
                    headerName: 'Feed Status', field: 'FeedStatus', filter: 'set', menuTabs: ['filterMenuTab'], cellRenderer: this.statusCellRenderer
                },
                {
                    headerName: 'Target Type', field: 'TargetType', filter: 'set', menuTabs: ['filterMenuTab'], hide: true
                },
                {
                    headerName: '', field: '', cellRenderer: DownloadReportComponent, suppressMenu: true, filter: false, Width: 50
                }
            ];


    }

    //#endregion
    //#region custom cell render
    statusCellRenderer(params: any) {
        let html: string = '';
        var eDiv = document.createElement('div');
        if (params.value) {

            if (params.value == 'Success') {
                eDiv.style.background = 'green';
                html = html + params.value;
            }
            else if (params.value == 'In progress') {
                eDiv.style.background = '#2196F3';
                html = html + params.value;
            }
            else {
                eDiv.style.background = 'red';
                html = html + params.value;
            }
        }

        eDiv.innerHTML = html;
        eDiv.style.height = '100%';
        eDiv.style.width = '100%';
        eDiv.style.color = 'white';
        eDiv.style.textAlign = 'center';
        //eDiv.style.paddingTop = '6px';
        eDiv.style.textAlign = 'center';
        return eDiv;
    }
    //#endregion

    //#region Roles

    //#endregion

    //#region Helper Methods

    //#endregion
    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];
        let childResult: any[] = [];
        // if (params.context.gridContext.routeName == 'import') {
        childResult.push({
            name: "Log",
            action: function () {
                params.context.gridContext.feedlogid = null;
                if (params.node) {
                    params.context.gridContext.feedlogid = params.node.data.FeedLogId;
                    params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                    params.context.gridContext._sharedService.feedLogDownloadLog({
                        FeedLogId: params.context.gridContext.feedlogid,
                        FileName: params.node.data.FileName,
                        FeedId: params.context.gridContext.dataFeedId
                    });
                }

            },
            icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
        });
        return result;


    }

    clickedJobRow(event: any) {
        this.feedlogobj = {};
        this.feedlogobj = {
            FeedLogId: event.data.FeedLogId,
            FileName: event.data.FileName,
            FeedId: event.data.FeedId
        }
        //  this.feedlogid = Number(event.data.FeedLogId);
        this.feedlogid = event.data.FeedLogId;
    }

    jobRowDoubleClicked(event: any) {
        this.feedlogobj = {};
        this.feedlogobj = {
            FeedLogId: event.data.FeedLogId,
            FileName: event.data.FileName,
            FeedId: event.data.FeedId
        }
        //  this.feedlogid = Number(event.data.FeedLogId);
        this.feedlogid = event.data.FeedLogId;
    }
    //#endregion
}
