//Components
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';

@Component({
    selector: 'report',
    templateUrl: './report.component.html'
})

export class ReportComponent implements OnInit {
    constructor(private _sharedService: SharedService,  private _router: Router) {
    }
    ngOnInit() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
       else{
        this._sharedService.getConfiguration();
        this._router.navigate(['reports/finance']);
       }
        
    }
  
}