import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Login, User } from './user'
import { SharedService } from '../../../shared/services/shared.service';

@Injectable()
export class UserService {
    constructor(private http: HttpClient, private _sharedService: SharedService) {
    }

    registerUser(apiUrl: string, user: User, ): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/Register`, JSON.stringify(user), this._sharedService.getReqOptWithAccessToken());
    }

    // loginAndGetToken(apiUrl: string, body: string): Observable<any> {

    //     let token: any = this.http.post(`${apiUrl}/Token`, body, { headers: this._sharedService.getContentTypeUrlReqOpt() });
    //     return token;
    // }
// .net core test
    loginAndGetToken(apiUrl: string, body: string): Observable<any> {

        let token: any = this.http.post(`${apiUrl}/api/Account/login`, body, { headers: this._sharedService.getContentTypeUrlReqOpt() });
        return token;
    }

    callWithToken(apiUrl: string, body: string): Observable<any> {
        return this.http.post(`${apiUrl}/Home/GetUserInfo`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getTenantInfoExternal(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetTenantInformationForExternal`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getTenantInfoInternal(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetTenantInformationForInternal`, body, this._sharedService.getReqOptWithAccessToken());
    }

    verifyCode(code: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Account/VerifyCode?code=${code}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    saveTenantInfoInternal(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetSpecificTenantInformationForInternal`, body, this._sharedService.getReqOptWithAccessToken());
    }
    forgetPassword(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/ForgetPassword`, body, this._sharedService.getReqOptWithAccessToken());
    }

    sendSharedKey(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Account/SendSharedKey`, '', this._sharedService.getReqOptWithAccessToken());
    }

    removeUserCacheInfo(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/RemoveUserCacheInfo`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getAllExternalUsers(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetAllExternalUsers`, body, this._sharedService.getReqOptWithAccessToken())

    }
    getAllExternalActiveUsers(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetAllExternalActiveUsers`, body, this._sharedService.getReqOptWithAccessToken())

    }

    getAllRoles(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetAllRoles`, body, this._sharedService.getReqOptWithAccessToken())
    }

    getAllRolesAndGroups(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetAllRolesAndGroups`, body, this._sharedService.getReqOptWithAccessToken())
    }

    GetOnlyRoles(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetOnlyRoles`, body, this._sharedService.getReqOptWithAccessToken())
    }
    
    createUpdateUser(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/Register`, body, this._sharedService.getReqOptWithAccessToken())
    }
    UpdateUser(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/UpdateUser`, body, this._sharedService.getReqOptWithAccessToken())
    }
    GetUserByEmail(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetUserByEmail`, body, this._sharedService.getReqOptWithAccessToken())
    }
    getSelectedUserData(apiUrl: string, userId: string): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetExistingUserData?userid=${userId}`, '', this._sharedService.getReqOptWithAccessToken())
    }
    getAllTenants(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetAllTenants`, body, this._sharedService.getReqOptWithAccessToken())
    }
    getCopiedUserData(apiUrl: string, userId: string): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetCopiedUserData?userid=${userId}`, '', this._sharedService.getReqOptWithAccessToken())
    }
    //updateExistingUser(apiUrl: string, body: any): Observable<any> {
    //    return this.http.post(`${apiUrl}/api/Account/UpdateRegisteredUser`, body, reqOption)
    //}   
    getExistingUserAsset(apiUrl: string, userId: string): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetExistingUserAsset?userid=${userId}`, '', this._sharedService.getReqOptWithAccessToken())
    }
    getLoggedInTenantName(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetLoggedInTenantName`, body, this._sharedService.getReqOptWithAccessToken())
    }
    getLoggedInUserIP(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Account/GetLoggedInUserIP`, null, this._sharedService.getReqOptWithAccessToken())
    }
    logout() {
        localStorage.clear();
    }
    getAllInternalUsers(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetAllInternalUsers`, body, this._sharedService.getReqOptWithAccessToken())

    }
    getExistingUserViews(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetExistingUserViews`, body, this._sharedService.getReqOptWithAccessToken())
    }
    getCurrentTenantUsers(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetCurrentTenantUsers`, body, this._sharedService.getReqOptWithAccessToken())

    }
    getCurrentTenantUserList(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Account/GetCurrentTenantUserList`, null, this._sharedService.getReqOptWithAccessToken());

    }
    getCurrentTenantUsersWithType(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetCurrentTenantUsersWithType`, body, this._sharedService.getReqOptWithAccessToken())

    }
    getAllInternalExternalUsers(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetAllInternalExternalUsers`, body, this._sharedService.getReqOptWithAccessToken())

    }
    getAllInternalExternalActiveUsers(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetAllInternalExternalActiveUsers`, body, this._sharedService.getReqOptWithAccessToken())

    }
    getUserGroupList(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetGroupList`, body, this._sharedService.getReqOptWithAccessToken())

    }
    //#region UserType
    getUserType() {
        let userType = [
            { value: 'Internal', label: 'Internal' },
            { value: 'External', label: 'External' }
        ];
        return userType;
    }
    //#endregion

    //#region TenantList
    getAllTenantsList(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Account/GetAllTenantsList`, null, this._sharedService.getReqOptWithAccessToken())
    }

    getTenantId(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Account/GetTenantId`, null, this._sharedService.getReqOptWithAccessToken())
    }

    getTenantBySubDomain(subDomain: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Account/GetTenantBySubDomain?subDomain=${subDomain}&authKey=rlRViVFs8fcRASYdDTpXPNRiq8X`, null, this._sharedService.getReqOptWithAccessToken())
    }

    getRoamVersion(key: string): Observable<any> {
        return this.http.get(`${this._sharedService.baseUrl}/api/AppConfig/GetAppVersion?key=${key}`, this._sharedService.getReqOptWithAccessToken())
    }

    isValidTenantBySubDomain(subDomain: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Account/ValidateTenantBySubDomain?subDomain=${subDomain}&authKey=rlRViVFs8fcRASYdDTpXPNRiq8X`, null, this._sharedService.getReqOptWithAccessToken())
    }

    getAllInternalUsersWithEmail(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetAllInternalUsersWithEmail`, body, this._sharedService.getReqOptWithAccessToken())

    }

    getCurrentTenantUsersWithEmail(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/GetCurrentTenantUsersWithEmail`, body, this._sharedService.getReqOptWithAccessToken())

    }

    downloadTemplateFile(templateType: number): any {
        return this.http.post(`${this._sharedService.baseUrl}/api/Account/GetTemplateFile?templateid=${templateType}`, '', this._sharedService.getReqOptWithAccessTokenForZipFile())
    }

    registerUsers(users: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Account/RegisterBulkUsers`,users, this._sharedService.getReqOptWithAccessToken());
    }

    getUserTenantUrlByEmail(UserName: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Account/GetUserTenantUrlByEmail?UserName=${UserName}`, null,this._sharedService.getContentTypeUrlReqOpt() );
    }

    samlRedirectSaml(): string {
        return `${this._sharedService.baseUrl}/api/account/samlredirect`;
    }
}
