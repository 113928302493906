

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { JobService } from '../../services/dp-jobs.service';
import { jobs } from '../../components/job/job';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { GridOptions, CsvExportParams, ExcelExportParams, ExcelStyle, ExcelCell, GridApi, ExcelRow } from "ag-grid-community";
import { SelectItem } from 'primeng/api';
import "ag-grid-enterprise";
import { Subscription } from 'rxjs';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
import { IConfig } from '../../../shared/helpers/config';
import * as moment from 'moment/moment';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { ViewLinkedRecordComponent } from '../job/view-job-button.component';
import { container } from '../../../records/services/container/container';
import { CustomDateComponent } from '../../../shared/components/custom-date/custom-date.component';
var dateFormatF: string;
var dateSeparatorS: string;
var dayD: number;
var monthM: number;
var yearY: number;
var statusList: any[] = [];
@Component({
    selector: 'list-job',
    templateUrl: './list-job.component.html',
    providers: [JobService]
})
export class ListJobsComponent implements OnInit, OnDestroy {
    //@Input() recordType: string;
    recordType: string;
    jobId: string = "";
    selectedAsset: any;
    subscription: Subscription;
    viewItemsubscription: Subscription;
    totalRecords: Number;
    selectedDpJob: jobs[];
    columnDefs: any[];
    //tempCols: any[];
    public gridOptions: GridOptions;
    public gridData: any[] = [];
    public totalCount: number;
    public browseRowData: any;
    public dateConfig: IConfig[] = [];
    public isAddVisible: boolean = false;
    public isEditVisible: boolean = false;
    public iconClass = '';
    //public isHideLiveRecords: number = 1;
    public isEditVisibleReadonly: boolean = false;
    isCurrentUserOnly: boolean = false;
    userType: string = "";

    //public excelStyles: ExcelStyle;
    public excelStyles: any;
    public gridApiJob: GridApi;
    public selectedRowData: any = null;
    public referenceKeyLabel: string = "";
    public componentName: string = "DPRJ";
    @Input() selectedContainerRow: container = null;
    public isJobModule: boolean = true;
    jobStatusList: any[] = [];

    public appHotkeys: Hotkey[] = [];
    statusOrder = ["New", "Received", "Ready for processing", "Pending", "In Progress", "Complete"];
    //#region Component Initilization

    // set colum width  of table
    public screenWidth;
    setWidthDpJobId;
    setWidthJobStatus;
    setWidthpriority;
    setWidthCreatedOn;
    setwidthAsset;
    setwidthTitle;
    setwidthDate;
    setWidthReferenceKey;
    setWidthType;
    setWidthDocuments;
    setwidthCreatedBy;
    setwidthPages;
    setWidthEmpty;
    setwidthAssignedTo;





    constructor(public _sharedService: SharedService, public _dpJobService: JobService, public _router: Router, public _route: ActivatedRoute, public _hotkeysService: HotkeysService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.dateConfig = this._sharedService.getConfiguration();
            this.userType = this._sharedService.getCurrentUserType();
            this.setHotKeys();
            this.populateJobStatus();
            this.navigateToNextCell = this.navigateToNextCell.bind(this);
        }
    }
    gridIcon() {
        if (this._router.url == '/jobs/new') {
            this.iconClass = 'fa fa-ticket';
        }
        else if (this._router.url == '/jobs/ready') {
            this.iconClass = 'fa fa-lg fa-fw fa fa-check-circle';
        }
        else if (this._router.url == '/jobs/pending') {
            this.iconClass = 'fa fa-lg fa-fw fa fa-clock-o'
        }
        else if (this._router.url == '/jobs/inprogress') {
            this.iconClass = 'fa fa-lg fa-fw fa-line-chart'
        }
        else if (this._router.url == '/jobs/complete') {
            this.iconClass = 'fa fa-lg fa-fw fa fa-check'
        }
    }


    ngOnInit() {
        this.gridIcon();
        this.referenceKeyLabel = this._sharedService.getReferenceKeyLabel();
        this.recordType = this._route.snapshot.data[0].recordType;
        this.isJobModule = this.IsInvokedFromJobModule();
        this.getDateFormatInfo();
        this.UpdateRoles();
        this.gridOptions = <GridOptions>
            {
                rowSelection: 'single',
                onGridReady: (params) => {
                    this.setDpJobGridColumns(params);
                    if (this.gridOptions.api != undefined) {
                        if (this.gridData.length > 0) {
                            this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridlistJobs'));
                            this.gridOptions.api.hideOverlay();
                        }
                    }
                    if (params.api) {
                        this.gridApiJob = params.api;

                    }
                },
                onModelUpdated: (event: any) => {
                    if (this.gridOptions.api) {
                        this.totalCount = this.gridOptions.api.getDisplayedRowCount();
                        this.gridOptions.api.sizeColumnsToFit();
                    }

                },
                //suppressContextMenu: true,
                pagination: true,
                paginationPageSize: 20,
                getContextMenuItems: this.getCustomContextMenuItems,
                context: {
                    gridContext: this
                },
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                },
                components: { agDateInput: CustomDateComponent },
                onCellEditingStopped: this.updateJobStatus,
                singleClickEdit: true
            }

        this.populateJobGrid();
        this.setExcelStyle();
        this.subscription = this._sharedService._isRefreshJobGrid
        .subscribe({
            next: (status:any) => {
                this.reBindGrid(status)
            },
            error: (error) => {
            }
        });
        this.viewItemsubscription = this._sharedService._isViewItem
        .subscribe({
            next: (response) => {
                this.viewItem()
            },
            error: (error) => {
            }
        })
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.viewItemsubscription) {
            this.viewItemsubscription.unsubscribe();
        }

        // let hotKeyObj = this._hotkeysService.get('ctrl+x');
        // if (hotKeyObj) {
        //     this._hotkeysService.remove(hotKeyObj);
        // }

        // hotKeyObj = this._hotkeysService.get('ctrl+z');
        // if (hotKeyObj) {
        //     this._hotkeysService.remove(hotKeyObj);
        // }

        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];

    }
    setHotKeys() {
        let hotKeyObj: Hotkey = null;


        if (this._router.url == "/record/browse")// open in popup
        {
            //Export CSV
            hotKeyObj = new Hotkey('ctrl+shift+x', (event: KeyboardEvent): boolean => {
                this.exportGridData();
                return false; // Prevent bubbling
            });
            this._hotkeysService.add(hotKeyObj);
            this.appHotkeys.push(hotKeyObj);

            //Export Excel
            hotKeyObj = new Hotkey('ctrl+shift+z', (event: KeyboardEvent): boolean => {
                this.exportExcelGrid();
                return false; // Prevent bubbling
            });
            this._hotkeysService.add(hotKeyObj);
            this.appHotkeys.push(hotKeyObj);
        }
        else {
            //Export CSV
            hotKeyObj = new Hotkey('ctrl+x', (event: KeyboardEvent): boolean => {
                this.exportGridData();
                return false; // Prevent bubbling
            });
            this._hotkeysService.add(hotKeyObj);
            this.appHotkeys.push(hotKeyObj);

            //Export Excel
            hotKeyObj = new Hotkey('ctrl+z', (event: KeyboardEvent): boolean => {
                this.exportExcelGrid();
                return false; // Prevent bubbling
            });
            this._hotkeysService.add(hotKeyObj);
            this.appHotkeys.push(hotKeyObj);
        }
    }
    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateFormatF.toUpperCase()).split(dateSeparatorS);
            var day = Number(dateParts[Number(dayD)].substring(0, 2));
            var month = Number(dateParts[Number(monthM)]) - 1;
            var year = Number(dateParts[Number(yearY)]);
            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        }
        else {
            return -1;
        }
    }
    //#endregion

    //#region Ag Grid Population Event
    populateJobGrid(): void {
        this._sharedService.showLoader("Processing...");
        let currentUser = "";
        if (this.isCurrentUserOnly) {
            currentUser = this._sharedService.getCurrentUserName();
        }
        let body: jobs = {
            DpJobStatusId: this.recordType,
            AssignedTo: currentUser
        }
        if (!this.isJobModule) {
            body.DpJobStatusId = "0";
            body.ContainerID = this.selectedContainerRow.ContainerID;
            this.UpdateRoles();
        }
        this._dpJobService.getDpJob(body)
        .subscribe({
            next: (response) => {
                this.gridData = [];
                this.gridData = response;
                this.totalCount = this.gridData.length;
                this._sharedService.hideLoader();
            },
            error: (error) => {
                this._sharedService.hideLoader();
            }
        })
    }

    reBindGrid(selAsset: any) {
        this.populateJobGrid();
    }
    clickedJobRow(event: any) {
        this.jobId = "";
        this.jobId = event.data.DpJobId;
        this.selectedRowData = event.data;
    }

    jobRowDoubleClicked(event: any) {
        if (this.isEditVisible || this.isEditVisibleReadonly) {
            this.jobId = event.data.DpJobId;
            this._sharedService.updateEditJob(null);
        }
    }

    //#endregion
    //#region  Export Grid
    exportGridData() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        if (this.gridOptions.rowData.length > 0) {
            let fileName = "Jobs_" + this.getSelectedJobTitle() + ".csv";
            var params: CsvExportParams = {
                skipColumnHeaders: false,
                skipColumnGroupHeaders: false,
                skipRowGroups: false,
                allColumns: false,
                onlySelected: false,
                suppressQuotes: false,
                fileName: fileName,
                columnSeparator: ',',
                processCellCallback: function (params: any) {
                    if (params.column.colDef.field == 'Date' || params.column.colDef.field == 'EndDate' || params.column.colDef.field == 'CreatedOn') {
                        return params.value ? params.value.substring(0, 10) : params.value;
                    }
                    else if (params.column.colDef.field == 'Priority') {
                        if (params.value == "1") {
                            return "High";
                        }
                        else if (params.value == "2") {
                            return "Normal";
                        }
                        else {
                            return "Low";
                        }
                    }
                    else {
                        return params.value;
                    }
                }
            };
            //params.columnKeys = ['DpJobId', 'Asset', 'Type', 'Date', 'EndDate', 'ReferenceKey', 'Priority', 'Documents', 'Pages', 'CreatedBy', 'CreatedOn'];

            if (this.userType == 'Internal') {
                //params.columnKeys.push('AssignedTo');
                params.columnKeys = ['DpJobId', "JobStatus", 'CreatedOn', 'Priority', 'AssignedTo', 'Title', 'Date', 'EndDate', 'ReferenceKey', 'Type', 'Documents', 'Pages', 'CreatedBy'];
            }
            else {
                params.columnKeys = ['DpJobId', "JobStatus", 'CreatedOn', 'Priority', 'Title', 'Date', 'EndDate', 'ReferenceKey', 'Type', 'Documents', 'Pages', 'CreatedBy'];
            }
            this.gridOptions.api.exportDataAsCsv(params);
        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Job Found', detail: 'There is no job to export' });
        }
    }
    getSelectedJobTitle(): string {
        let jobStatus = "";
        switch (this.recordType) {

            case '1':
                jobStatus = "New";
                break;
            case '2':
                jobStatus = "Received";
                break;
            case '3':
                jobStatus = "Pending";
                break;
            case '4':
                jobStatus = "InProgress";
                break;
            case '5':
                jobStatus = "Complete";
                break;
            case '6':
                jobStatus = "Ready for processing";
                break;
        }
        return jobStatus;
    }

    exportExcelGrid() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }

        if (this.gridOptions.rowData.length > 0) {
            var params: ExcelExportParams = {
                skipColumnGroupHeaders: true,
                allColumns: true,
                onlySelected: false,
                fileName: "Jobs-" + this.getSelectedJobTitle() + ".xlsx",
                sheetName: "Jobs-" + this.getSelectedJobTitle(),
                processCellCallback: function (params: any) {
                    if (params.column.colDef.field == 'Date' || params.column.colDef.field == 'EndDate' || params.column.colDef.field == 'CreatedOn') {
                        return params.value ? params.value.substring(0, 10) : params.value;
                    }
                    else if (params.column.colDef.field == 'Priority') {
                        if (params.value == "1") {
                            return "High";
                        }
                        else if (params.value == "2") {
                            return "Normal";
                        }
                        else {
                            return "Low";
                        }
                    }
                    else {
                        return params.value;
                    }
                }
            };
            if (this.userType == 'Internal') {
                //params.columnKeys.push('AssignedTo');
                params.columnKeys = ['DpJobId', "JobStatus", 'CreatedOn', 'Priority', 'AssignedTo', 'Title', 'Date', 'EndDate', 'ReferenceKey', 'Type', 'Documents', 'Pages', 'CreatedBy'];
            }
            else {
                params.columnKeys = ['DpJobId', "JobStatus", 'CreatedOn', 'Priority', 'Title', 'Date', 'EndDate', 'ReferenceKey', 'Type', 'Documents', 'Pages', 'CreatedBy'];
            }
            this.setCustomHeaderAndExport(params);

        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Document', detail: 'There is no  document to export' });
        }
    }

    setCustomHeaderAndExport(params: ExcelExportParams) {
        this._sharedService.getConfigurationByKey("Excel_Export_Header_Jobs")
        .subscribe({
            next: (response) => {
                let result = response;
                let headerString = result.Value ? result.Value : '';
                headerString = headerString.replace('{TenantName}', this._sharedService.tenantName);
                headerString = headerString.replace('{JobStatus}', this.getSelectedJobTitle());
                headerString = headerString.replace('{UserName}', this._sharedService.getCurrentUserName());
                headerString = headerString.replace('{DateTime}', this.getFormatedDate(new Date()));
                headerString = headerString.replace('{Filter}', this.getFilterString());
            const getRows: () => ExcelRow[] = () => JSON.parse(headerString);
            params.prependContent = getRows();
                this.gridOptions.api.exportDataAsExcel(params);
            },
            error: (error) => {
                console.log(error);
            }
        });
    }

    setExcelStyle() {
        this.excelStyles = [
            {
                id: "greenBackground",
                interior: {
                    color: "#90ee90",
                    pattern: "Solid"
                }
            },
            {
                id: "redFont",
                font: {
                    underline: "Single",
                    italic: true,
                    color: "#ff0000"
                }
            },
            {
                id: "darkGreyBackground",
                interior: {
                    color: "#888888",
                    pattern: "Solid"
                }
            },
            {
                id: "boldBorders",
                borders: {
                    borderBottom: {
                        color: "#000000",
                        lineStyle: "Continuous",
                        weight: 3
                    },
                    borderLeft: {
                        color: "#000000",
                        lineStyle: "Continuous",
                        weight: 3
                    },
                    borderRight: {
                        color: "#000000",
                        lineStyle: "Continuous",
                        weight: 3
                    },
                    borderTop: {
                        color: "#000000",
                        lineStyle: "Continuous",
                        weight: 3
                    }
                }
            },
            {
                id: "header",
                interior: {
                    color: "#CCCCCC",
                    pattern: "Solid"
                }
            },
            {
                id: "twoDecimalPlaces",
                numberFormat: { format: "#,##0.00" }
            },
            {
                id: "textFormat",
                dataType: "string"
            },
            {
                id: "bigHeader",
                font: {
                    size: 18,
                    bold: true
                },
                alignment: {
                    horizontal: 'Center', vertical: 'Center'
                }
            }
        ];
    }
    getFormatedDate(date: any) {
        if (date) {
            return moment(date).format(this._sharedService.getDateTimeFormatConfiguration().dateformat.toUpperCase());
        } else {
            return '';
        }
    }

    getFilterString(): string {
        let filterModel = this.gridOptions.api.getFilterModel();
        let filterString = '';
        for (var filter in filterModel) {
            filterString = filterString.concat(filter, ', ');
        }

        return filterString ? filterString.slice(0, -2) : 'No';
    }
    //#endregion
    // #region All Jobs or Current Jobs Switch Events
    showAllUsersRecords() {
        this.populateJobGrid();
    }
    //#endregion

    setWidthOnTablet() {
        this.screenWidth = window.innerWidth;
        if (this.screenWidth < 1025) {
            // column size for tablet
            this.setWidthEmpty = 50;
            this.setWidthJobStatus = 50;
            this.setWidthpriority = 120;
            this.setwidthTitle = 50;
            this.setWidthDpJobId = 100;
            this.setWidthCreatedOn = 50;
            this.setwidthAsset = 50;
            this.setwidthDate = 50;
            this.setWidthReferenceKey = 50;
            this.setWidthType = 50;
            this.setWidthDocuments = 150;
            this.setwidthCreatedBy = 50;
            this.setwidthPages = 50;
            this.setwidthAssignedTo = 50;

        }
        else {
            // size for laptop pc and large screens
            this.setWidthDpJobId = 130;
            this.setWidthJobStatus = 170;
            this.setWidthpriority = 150;
            this.setWidthCreatedOn = 80;
            this.setwidthAsset = 50;
            this.setwidthTitle = 50;
            this.setwidthDate = 80;
            this.setWidthReferenceKey = 120;
            this.setWidthType = 100;
            this.setWidthDocuments = 170;
            this.setwidthCreatedBy = 200;
            this.setwidthPages = 160;
            this.setWidthEmpty = 40;
            this.setwidthAssignedTo = 90;
        }
    }
    //#region Set Columns of Browse Ag Grid
    setDpJobGridColumns(params: any): void {

        this.columnDefs = [];
        this.setWidthOnTablet();
        if (this.isEditVisible) {
            this.columnDefs =
                [
                    {
                        headerName: 'Job #', colId: 'DpJobId', field: 'DpJobId', filter: 'set', menuTabs: ['filterMenuTab'],minWidth: 70, width: this.setWidthDpJobId,
                    },
                    {
                        headerName: 'Job Status', colId: 'JobStatus', field: 'JobStatus', filter: 'set', menuTabs: ['filterMenuTab'], cellRenderer: this.statusCellRenderer, editable: true,
                        cellEditor: 'agRichSelectCellEditor',
                        cellEditorParams: {
                            values: this.jobStatusList.map(sts => sts.Description.trim()),
                            cellRenderer: this.statusEditorCellRenderer
                        }, minWidth: this.setWidthJobStatus,
                    },
                    {
                        headerName: 'Created On', colId: 'CreatedOn', field: 'CreatedOn', filter: 'agDateColumnFilter', filterParams: {
                            comparator: this.dateComparater,
                            inRangeInclusive: 'true'

                        }, valueFormatter: function (params: any) {
                            if (params.value != "" && params.value != null) {

                                let dateString = params.value.substring(0, 10);
                                return moment(dateString).format(dateFormatF.toUpperCase());
                            }
                        }, menuTabs: ['filterMenuTab'], minWidth: this.setWidthCreatedOn,
                    },
                    {
                        headerName: 'Priority', colId: 'Priority', field: 'Priority', filter: 'set', menuTabs: ['filterMenuTab'], width: this.setWidthpriority,
                        // valueFormatter: function (params: any) {
                        //     if (params.value != "" && params.value != null) {

                        //         if (params.value == "1") {
                        //             return "High";
                        //         }
                        //         else if (params.value == "2") {
                        //             return "Normal";
                        //         }
                        //         else {
                        //             return "Low";
                        //         }
                        //     }
                        // }
                    },
                ];
        }
        else {
            this.columnDefs =
                [
                    {
                        headerName: 'Job #', colId: 'DpJobId', field: 'DpJobId', filter: 'set', menuTabs: ['filterMenuTab'], width: this.setWidthDpJobId,
                    },
                    {
                        headerName: 'Job Status', colId: 'JobStatus', field: 'JobStatus', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: this.setWidthJobStatus,
                    },
                    {
                        headerName: 'Created On', colId: 'CreatedOn', field: 'CreatedOn', filter: 'agDateColumnFilter', filterParams: {
                            comparator: this.dateComparater,
                            inRangeInclusive: 'true'

                        }, valueFormatter: function (params: any) {
                            if (params.value != "" && params.value != null) {

                                let dateString = params.value.substring(0, 10);
                                return moment(dateString).format(dateFormatF.toUpperCase());
                            }
                        }, menuTabs: ['filterMenuTab'], minWidth: this.setWidthCreatedOn,
                    },
                    {
                        headerName: 'Priority', colId: 'Priority', field: 'Priority', filter: 'set', menuTabs: ['filterMenuTab'],
                        // valueFormatter: function (params: any) {
                        //     if (params.value != "" && params.value != null) {

                        //         if (params.value == "1") {
                        //             return "High";
                        //         }
                        //         else if (params.value == "2") {
                        //             return "Normal";
                        //         }
                        //         else {
                        //             return "Low";
                        //         }
                        //     }
                        // }
                    },
                ];
        }
        if (this.userType == 'Internal') {
            this.columnDefs.push({
                headerName: 'Assigned To', colId: 'AssignedTo', field: 'AssignedTo', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: this.setwidthAssignedTo,
            });
        }
        this.columnDefs.push(
            {
                headerName: 'Asset', field: 'Asset', colId: 'Asset', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: this.setwidthAsset, hide: true
            },
            {
                headerName: 'Title', field: 'Title', colId: 'Title', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: this.setwidthTitle,
            },

            {
                headerName: 'Date', field: 'Date', colId: 'Date', filter: 'agDateColumnFilter', filterParams:
                {
                    comparator: this.dateComparater,
                    inRangeInclusive: 'true'
                }, valueFormatter: function (params: any) {
                    if (params.value != "" && params.value != null) {

                        let dateString = params.value.substring(0, 10);
                        return moment(dateString).format(dateFormatF.toUpperCase());
                    }
                }, menuTabs: ['filterMenuTab'], minWidth: this.setwidthDate,
            },
            {
                headerName: 'End Date', field: 'EndDate', colId: 'EndDate', filter: 'agDateColumnFilter', filterParams: {
                    comparator: this.dateComparater,
                    inRangeInclusive: 'true'

                }, valueFormatter: function (params: any) {
                    if (params.value != "" && params.value != null) {
                        let dateString = params.value.substring(0, 10);
                        return moment(dateString).format(dateFormatF.toUpperCase());
                    }
                }, menuTabs: ['filterMenuTab'], minWidth: this.setwidthDate,
            },
            {
                headerName: this.referenceKeyLabel, colId: 'ReferenceKey', field: 'ReferenceKey', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: this.setWidthReferenceKey,
            },
            {
                headerName: 'Type', field: 'Type', colId: 'Type', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: this.setWidthType,
            },
            {
                headerName: 'Document Count', colId: 'Documents', field: 'Documents', filter: 'set', menuTabs: ['filterMenuTab'], width: this.setWidthDocuments,
            },
            // {
            //     headerName: 'Page Count', field: 'Pages', filter: 'set', menuTabs: ['filterMenuTab']
            // },
            // {
            //     headerName: 'Created By', field: 'CreatedBy', filter: 'set', menuTabs: ['filterMenuTab']
            // },
            // { headerName: '', field: '', cellRenderer: ViewLinkedRecordComponent, suppressMenu: true, filter:false, width: 40 },
        );
        if (this.isJobModule) {
            this.columnDefs.push({
                headerName: 'Page Count', colId: 'Pages', field: 'Pages', filter: 'set', menuTabs: ['filterMenuTab'], width: 160
            }, {
                headerName: 'Created By', colId: 'CreatedBy', field: 'CreatedBy', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: this.setwidthCreatedBy,
            }, { headerName: '', field: '', cellRenderer: ViewLinkedRecordComponent, suppressMenu: true, filter: false, width: this.setWidthEmpty });

        }
        else {
            this.columnDefs.push(
                {
                    headerName: 'Page Count', colId: 'Pages', field: 'Pages', filter: 'set', menuTabs: ['filterMenuTab'], width: this.setwidthPages,
                }, {
                headerName: 'Created By', colId: 'CreatedBy', field: 'CreatedBy', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: this.setwidthCreatedBy,
            });
        }
    }
    statusCellRenderer(params: any) {
        let html: string = '';
        var eDiv = document.createElement('div');
        if (params.value) {
            let jobStatusName: string = params.value.trim();
            let currentStatus: any = statusList.filter(x => x.Description == jobStatusName)[0];
            if (currentStatus && currentStatus.Description) {
                eDiv.innerHTML = html + jobStatusName;
                eDiv.style.backgroundColor = currentStatus.ColorCode;
            }

        }
        else {
            return params.value;
        }

        eDiv.style.height = '100%';
        eDiv.style.width = '100%';
        eDiv.style.color = 'white';
        eDiv.style.textAlign = 'center';
        eDiv.style.paddingLeft = '5px';
        eDiv.style.textAlign = 'left';
        return eDiv;
    }

    statusEditorCellRenderer(params: any) {
        let html: string = '';
        var eDiv = document.createElement('div');
        if (params.value) {
            let jobStatusName: string = params.value.trim();
            let currentStatus: any = statusList.filter(x => x.Description == jobStatusName)[0];
            if (currentStatus && currentStatus.Description) {
                eDiv.innerHTML = html + jobStatusName;
                eDiv.style.backgroundColor = currentStatus.ColorCode;
            }

        }
        else {
            return params.value;
        }

        eDiv.style.height = '100%';
        eDiv.style.width = '100%';
        eDiv.style.color = 'white';
        eDiv.style.textAlign = 'center';
        eDiv.style.paddingTop = '5px';
        eDiv.style.paddingLeft = '5px';
        eDiv.style.textAlign = 'left';
        return eDiv;
    }
    //#endregion

    // #region date Format Settings

    getDateFormatInfo(): any {

        if (this.dateConfig != undefined) {
            dateFormatF = this.dateConfig.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
            dateSeparatorS = this.dateConfig.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
            dayD = Number(this.dateConfig.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
            monthM = Number(this.dateConfig.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
            yearY = Number(this.dateConfig.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
        }
        else {
            dateFormatF = localStorage.getItem('DateFormat');
            dateSeparatorS = localStorage.getItem('DateSeparater');
            dayD = Number(localStorage.getItem('DayIndex'));
            monthM = Number(localStorage.getItem('MonthIndex'));
            yearY = Number(localStorage.getItem('YearIndex'));

        }
    }


    // #endregion

    //#region Roles
    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }
        this.isAddVisible = false;
        this.isEditVisible = false;
        if (this.recordType != "") {
            this.isAddVisible = this._sharedService.UserRole.DP_Jobs_Add;
            this.isEditVisible = this._sharedService.UserRole.DP_Jobs_Edit;
            this.isEditVisibleReadonly = this._sharedService.UserRole.DP_Jobs_Readonly;
        }
    }
    //#endregion

    //#region Pagination Logic
    getGridPageSize(): number {
        let defaultSize: number = 20;
        let gridHeight = document.getElementById('agGridlistJobs').clientHeight;
        try {
            defaultSize = (gridHeight / 27);
        } catch (e) {
            console.log(e);
        }
        let size: number = Math.round(defaultSize);

        return size - 1;
    }
    //#endregion
    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];
        let childResult: any[] = [];
        if (params.context.gridContext.isAddVisible) {
            result.push({
                name: "Add Job",
                action: function () {

                    params.context.gridContext._sharedService.addJob(null);
                },
                icon: params.context.gridContext._sharedService.addIconHTMLasString,
            });
        }
        if (params.context.gridContext.isEditVisible || params.context.gridContext.isEditVisibleReadonly) {
            result.push({
                name: "Edit Job",
                action: function () {
                    params.context.gridContext.jobId = null;
                    if (params.node) {
                        params.context.gridContext.jobId = params.node.data.DpJobId;
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext._sharedService.updateJobUsingId(params.context.gridContext.jobId);
                    }

                },
                icon: params.context.gridContext._sharedService.editIconHTMLasString,
            });
        }

        childResult.push({
            name: "Excel",
            action: function () {
                params.context.gridContext.exportExcelGrid();
            },
            icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
        });

        childResult.push({
            name: "CSV",
            action: function () {

                params.context.gridContext.exportGridData();

            },
            icon: params.context.gridContext._sharedService.saveLogFeedIconHTMLasString,
        });

        result.push({
            name: "Download",
            action: function () {


            },
            icon: params.context.gridContext._sharedService.downloadsFeedIconHTMLasString,
            subMenu: childResult,
        });

        if (params.context.gridContext.userType == 'Internal') {
            if (params.context.gridContext.isCurrentUserOnly) {
                result.push({
                    name: "All Jobs",
                    action: function () {
                        params.context.gridContext.isCurrentUserOnly = false;
                        params.context.gridContext.showAllUsersRecords();
                    },
                    icon: params.context.gridContext._sharedService.jobsIconHTMLasString,

                });
            }
            else {
                result.push({
                    name: "My Jobs",
                    action: function () {
                        params.context.gridContext.isCurrentUserOnly = true;
                        params.context.gridContext.showAllUsersRecords();
                    },
                    icon: params.context.gridContext._sharedService.jobsIconHTMLasString,
                });
            }

        }
        return result;


    }
    //#endregion

    //#region View Job
    viewItem() {
        if (this.selectedRowData != null) {
            if (this.selectedRowData.Asset != null && this.selectedRowData.Asset != "") {
                let body: any = {
                    DpJobId: this.selectedRowData.DpJobId,
                    ASN: this.selectedRowData.Asset
                }
                this._dpJobService.getViewJobDetails(body)
                .subscribe({
                    next: (response) => {
                        let result = response;
                        if (result && result.AssetEntity != null && result.ContainerID != null && result.Category != null) {
                            //Set Selected Asset
                            this._sharedService.selectedAsset = [];
                            this._sharedService.selectedAsset.push(result.AssetEntity);
                            //Get Container Details
                            let containerId = result.ContainerID;
                            this._sharedService.isNavigationFilterCanceled = true;
                            let category = result.Category;
                            var _assetDataObj = JSON.parse(result.AssetEntity.data);

                            switch (category) {
                                case 'MXR':
                                    if (this._sharedService.UserRole.Records_MXR_Readonly && _assetDataObj.PlanRoles.indexOf("Records_MXR_Readonly") > -1) {
                                        this._sharedService.setRouteInfoAndNavigate({ MenuID: 17, PageURL: '/record/browse', cat: 'mxr', id: containerId, recordId: null, ColorCode: 'bg-color-msgreen' });
                                    }
                                    else {
                                        this._sharedService.clearToast();
                                        this._sharedService.showToast({ severity: 'warn', summary: 'Restricted Access', detail: 'You are not allowed to view details.' });
                                    }
                                    break;
                                case 'LOG':
                                    if (this._sharedService.UserRole.Records_LOG_Readonly && _assetDataObj.PlanRoles.indexOf("Records_LOG_Readonly") > -1) {
                                        this._sharedService.setRouteInfoAndNavigate({ MenuID: 19, PageURL: '/record/browse', cat: 'logs', id: containerId, recordId: null, ColorCode: 'bg-color-msgreen' });
                                    }
                                    else {
                                        this._sharedService.clearToast();
                                        this._sharedService.showToast({ severity: 'warn', summary: 'Restricted Access', detail: 'You are not allowed to view details.' });
                                    }
                                    break;
                                case 'GEN':
                                    if (this._sharedService.UserRole.Records_GEN_Readonly && _assetDataObj.PlanRoles.indexOf("Records_GEN_Readonly") > -1) {
                                        this._sharedService.setRouteInfoAndNavigate({ MenuID: 20, PageURL: '/record/browse', cat: 'general', id: containerId, recordId: null, ColorCode: 'bg-color-msgreen' });
                                    }
                                    else {
                                        this._sharedService.clearToast();
                                        this._sharedService.showToast({ severity: 'warn', summary: 'Restricted Access', detail: 'You are not allowed to view details.' });
                                    }

                                    break;
                                case 'DOC':
                                    if (this._sharedService.UserRole.Records_DOC_Readonly && _assetDataObj.PlanRoles.indexOf("Records_DOC_Readonly") > -1) {
                                        this._sharedService.setRouteInfoAndNavigate({ MenuID: 96, PageURL: '/record/browse', cat: 'documents', id: containerId, recordId: null, ColorCode: 'bg-color-msgreen' });
                                    }
                                    else {
                                        this._sharedService.clearToast();
                                        this._sharedService.showToast({ severity: 'warn', summary: 'Restricted Access', detail: 'You are not allowed to view details.' });
                                    }

                                    break;
                                case 'BND':
                                    if (this._sharedService.UserRole.Transition_BND_Readonly && _assetDataObj.PlanRoles.indexOf("Transition_BND_Readonly") > -1) {
                                        this._sharedService.setRouteInfoAndNavigate({ MenuID: 114, PageURL: '/transition/binders', cat: 'binders', id: containerId, recordId: null, ColorCode: 'bg-color-greenpea' });
                                    }
                                    else {
                                        this._sharedService.clearToast();
                                        this._sharedService.showToast({ severity: 'warn', summary: 'Restricted Access', detail: 'You are not allowed to view details.' });
                                    }

                                    break;
                            }

                        }
                        else {
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'warn', summary: 'No container exists to view', detail: 'No container exists to view' });
                        }
                    },
                    error: (error) => {
                        this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again later.' });
                    }
                })
            }
            else {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'No container exists to view', detail: 'No container exists to view' });
            }
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Item Selected', detail: 'Please select a row first' });
        }
    }
    //#region Job Status
    populateJobStatus() {
        let jobStatusListValues = this._sharedService.getJobStatusList();
        if (jobStatusListValues == null || jobStatusListValues.length == 0) {
            this._dpJobService.getActiveJobsStatus()
            .subscribe({
                next: (response) => {
                    try {

                        this.jobStatusList = response;
                        statusList = response;
                    }
                    catch (ex) {
                        this.jobStatusList = response;
                        statusList = response;
                        console.log(ex);
                    }
                },
                error: (error) => {
                }
            });
        }
        else {
            this.jobStatusList = jobStatusListValues;
            statusList = jobStatusListValues;
        }
    }
    updateJobStatus(event: any) {
        if (event.colDef.field == 'JobStatus') {
            let selectedData = event.data;
            let selectedDpJobStatusId = event.context.gridContext.jobStatusList.find(x => x.Description == selectedData.JobStatus).DpJobStatusId;
            let existingDpJobStatusLabel = event.context.gridContext.jobStatusList.find(x => x.DpJobStatusId == selectedData.DpJobStatusId).Description;
            if (selectedDpJobStatusId != selectedData.DpJobStatusId) {
                let body: jobs =
                {
                    DpJobId: selectedData.DpJobId,
                    DpJobStatusId: selectedDpJobStatusId,
                    History: event.context.gridContext.getHistory(existingDpJobStatusLabel, selectedData.JobStatus)
                };
                event.context.gridContext._dpJobService.updateJobStatus(body)
                .subscribe({
                    next: (response) => {
                        event.context.gridContext.msgs = [];
                        event.context.gridContext.msgs.push({ severity: 'success', summary: 'Success', detail: 'Job updated succesfully.' });
                        event.context.gridContext.populateJobGrid();
                    },
                    error: (error) => {
                        event.context.gridContext.msgs = [];
                        event.context.gridContext.msgs.push({ severity: 'error', summary: 'Error Occured', detail: 'Job not updated, please try again.' });
                    }
                });
            }
            //Update Status
            // _dpJobService.updateJobStatus(event.data).subscribe( response => {
            //
            //     this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Job updated succesfully.' });
            // },
            //     error => {
            //
            //         this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Job not updated, please try again.' });
            //     }
            // );

        }
    }
    //#endregion
    //#endregion

    //#region Helper Methods

    IsInvokedFromJobModule(): boolean {
        if ((!this.recordType || this.recordType == 'MXR' || this.recordType == 'LOG' || this.recordType == 'GEN' || this.recordType == 'DOC' || this.recordType == 'BND') && this._sharedService.selectedContainer && this._sharedService.selectedContainer.ContainerID) {
            return false;
        }
        else {
            return true;
        }
    }
    getCurrentDateTime() {
        let result = moment().format("MM/DD/YYYY" + " HH:mm");
        return result;
    }
    getHistory(oldValue: string, newValue: string) {
        let currentTimeStamp = this.getCurrentDateTime();
        let newHistory = '';
        newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "job status" + "," + oldValue + "," + newValue + "\r\n" + newHistory;
        return newHistory;
    }
    navigateToNextCell(params: any) {
        var previousCell = params.previousCellPosition;
        // var previousCell = params.previousCellDef;
        var suggestedNextCell = params.nextCellPosition;
        // var suggestedNextCell = params.nextCellDef;
        var self = this;
        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        var commId, selectedRowData;
        switch (params.key) {
            case KEY_DOWN:
                var nextRowIndex = previousCell.rowIndex + 1;
                var renderedRowCount = this.gridOptions.api.getModel().getRowCount();
                if (nextRowIndex >= renderedRowCount) {
                    return null;
                } else {
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
                    rowNode.setSelected(true);
                    this.jobId = "";
                    this.jobId = rowNode.data.DpJobId;
                    this.selectedRowData = rowNode.data;
                    return {
                        rowIndex: nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }


            case KEY_UP:
                var _nextRowIndex = previousCell.rowIndex - 1;
                if (_nextRowIndex < 0) {
                    return null;
                } else {
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
                    rowNode.setSelected(true); this.jobId = "";
                    this.jobId = rowNode.data.DpJobId;
                    this.selectedRowData = rowNode.data
                    return {
                        rowIndex: _nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw 'this will never happen, navigation is always on of the 4 keys above';
        }
    }
    //#endregion
}
