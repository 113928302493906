
//Components
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { GridOptions, GridApi } from "ag-grid-community";

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { SelectItem } from 'primeng/api';
import { ContainerQualityStatusService } from '../../services/containerqualitystatus/container-quality-status.service';
import { ContainerQualityStatusModel, OperationMode } from './container-quality-status';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
var _router_: Router;

@Component({
  selector: 'containerqualitystatus', //'container-quality-status',//'app-container-quality-status',
  templateUrl: './container-quality-status.component.html',
  //styleUrls: ['./container-quality-status.component.sass']
  providers: [ContainerQualityStatusService]
})
export class ContainerQualityStatusComponent implements OnInit, OnDestroy {
  display: boolean = false;
  public gridOptions: GridOptions;
  columnDefs: any[];
  public gridData: any[];
  public containerQualityDDList: any[];

  public canAddEditContainerQualityStatus: boolean = false;

  selectedContainerQualityStatusID: string = null;

  public totalCount: number;
  public model: ContainerQualityStatusModel;
  public isSubmitted: boolean = false;
  TagList: SelectItem[] = [];
  public dialogTitle: string = 'Add Quality Status';
  public lblsubmitButton: string = 'Add';
  public operationMode: OperationMode = 1;
  public readonlyMode: boolean = false;
  public appHotkeys: Hotkey[] = [];
  //#region Initialization of Component
  constructor(public _sharedService: SharedService, public _router: Router, public _containerQualityStatusService: ContainerQualityStatusService, private _hotkeysService: HotkeysService) {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(['login']);
    }
    else {
      this.UpdateRoles();
      _router_ = this._router;
      this.setHotKeys();
      this.navigateToNextCell = this.navigateToNextCell.bind(this);
    }
  }
  setHotKeys() {

    let hotKeyObj: Hotkey = null;

    //Add quality status
    hotKeyObj = new Hotkey('ctrl+a', (event: KeyboardEvent): boolean => {
      if (_router_.url == "/admin/containerqualitystatus") {
        this.btnAddClick();
      }
      return false; // Prevent bubbling
    });
    this._hotkeysService.add(hotKeyObj);
    this.appHotkeys.push(hotKeyObj);


    //Edit quality status
    hotKeyObj = new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
      if (_router_.url == "/admin/containerqualitystatus") {
        this.btnEditClick();
      }
      return false; // Prevent bubbling
    });
    this._hotkeysService.add(hotKeyObj);
    this.appHotkeys.push(hotKeyObj);
    hotKeyObj = new Hotkey('enter', (event: KeyboardEvent): boolean => {
      if (_router_.url == "/admin/containerqualitystatus") {
        this.btnEditClick();
      }
      return false; // Prevent bubbling
    });
    this._hotkeysService.add(hotKeyObj);
    this.appHotkeys.push(hotKeyObj);

  }
  ngOnInit() {
    this.model = new ContainerQualityStatusModel();
    if (this.canAddEditContainerQualityStatus) {
      this.gridOptions = <GridOptions>
        {
          rowSelection: 'single',

          onGridReady: () => {
            this.setContainerQualityStatusGridColumns();
            try {
              this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridDFPStatus'));
              this.gridOptions.api.hideOverlay();
            } catch (e) {
              console.log(e);
            }
          },
          //suppressContextMenu: true,
          pagination: true,
          paginationPageSize: 20,
          getContextMenuItems: this.getCustomContextMenuItems,
          context: {
            gridContext: this
          },
          defaultColDef: {
            sortable: true,
            filter: true
          }

        };
      // this.getCategoryList();
      this.populateQualityStatusGrid();
    }
  }
  ngOnDestroy() {
    this.appHotkeys.forEach(hotkey => {
      //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
      this._hotkeysService.remove(hotkey);
    });
    this.appHotkeys = [];
  }
  //#endregion

  //#region Ag Grid Population Event
  populateQualityStatusGrid(): void {

    this._containerQualityStatusService.getAllQualityStatusData(null)
      .subscribe({
        next: (response) => {
          this.gridOptions.api.sizeColumnsToFit();
          this.gridData = [];
          this.gridData = response;
          this.containerQualityDDList = [];
          this.containerQualityDDList = this.populateQualityStatusDDList(this.gridData, null);
          this.totalCount = response.length;
        },
        error: (error) => {
          this.gridData = [];
          this.containerQualityDDList = [];
          this.totalCount = 0;
        }
      });
  }

  populateQualityStatusDDList(containerQualityStatusData: any[], QualityStatusID: string): any[] {

    this.containerQualityDDList = [];
    this.containerQualityDDList.push({ value: '-1', label: 'N/A' });

    if (QualityStatusID == undefined || QualityStatusID == null) {
      containerQualityStatusData.forEach(cqs => {
        var contQS = { value: cqs.QualityStatusID, label: cqs.Name };
        this.containerQualityDDList.push(contQS);
      });
    }
    else {
      containerQualityStatusData.forEach(cqs => {
        if (cqs.QualityStatusID != QualityStatusID) {
          var contQS = { value: cqs.QualityStatusID, label: cqs.Name };
          this.containerQualityDDList.push(contQS);
        }
      });

    }
    return this.containerQualityDDList;
  }
  //#endregion
  //#region Set Columns of Browse Ag Grid
  setContainerQualityStatusGridColumns(): void {
    this.columnDefs = [];
    this.columnDefs =
      [
        { headerName: 'Quality Status', field: 'Name', menuTabs: ['filterMenuTab'], cellRenderer: this.tagsCellRenderer },
        { headerName: 'Description', field: 'Description', menuTabs: ['filterMenuTab'] },
        { headerName: 'Reset Change', field: 'ResetChangeID', menuTabs: ['filterMenuTab'], cellRenderer: this.resetChangeCellRenderer },
        {
          headerName: 'Status', field: 'Status', filter: false, suppressMenu: true, valueFormatter: function (params: any) {
            if (params.value == true) {
              return "Active";
            }
            else {
              return "Inactive";
            }
          }
        }
      ];

  }
  tagsCellRenderer(params: any) {
    let html: string = '';
    if (params.value) {
      let qualitystatus: string = params.value.trim();
      html = html + '<span class="padding-2 label" style= "background-color:' + params.data.CSSClass + ';">' + qualitystatus + '</span>';
    }

    var eDiv = document.createElement('div');
    eDiv.innerHTML = html

    return eDiv;
  }


  resetChangeCellRenderer(params: any) {
    let html: string = '';

    if (params.data.ResetChangeID) {
      let resetChangeQSName: string = params.data.ResetChangeName.trim();
      html = html + '<span class="padding-2 label" style= "background-color:' + params.data.ResetChangeCSSClass + ';">' + resetChangeQSName + '</span>';
    }
    else {
      html = html + '<span>' + 'N/A' + '</span>';
    }

    var eDiv = document.createElement('div');
    eDiv.innerHTML = html

    return eDiv;
  }

  //#endregion

  //#region Ag-Grid Events
  updateSelectedRow(event: any) {
    this.selectedContainerQualityStatusID = null;
    this.selectedContainerQualityStatusID = event.data.QualityStatusID;
  }
  //#endregion

  //#region Edit User Events
  containerRowDoubleClicked(event: any) {
    if (this.canAddEditContainerQualityStatus) {
      this.selectedContainerQualityStatusID = null;
      this.selectedContainerQualityStatusID = event.data.QualityStatusID;
      this.btnEditClick();
    }
  }
  btnEditClick() {
    let selectedRows = this.gridOptions.api.getSelectedRows();
    if (this.selectedContainerQualityStatusID && (selectedRows && selectedRows.length > 0)) {
      this.operationMode = 2;
      this.dialogTitle = 'Edit Container Quality Status';
      this.lblsubmitButton = 'Update';

      this._containerQualityStatusService.getQualityStatusByID(this.selectedContainerQualityStatusID)
        .subscribe({
          next: (response) => {
            let data = response;
            this.model = new ContainerQualityStatusModel();
            this.model.CSSClass = data.CSSClass.trim();
            this.model.QualityStatusID = data.QualityStatusID;
            this.model.Description = data.Description ? data.Description.trim() : data.Description;
            this.model.Status = data.Status;
            this.model.Name = data.Name.trim();
            this.model.ResetChangeID = data.ResetChangeID;
            this.model.ResetChangeName = data.ResetChangeName;
            this.model.ResetChangeCSSClass = data.ResetChangeCSSClass;

            this.populateQualityStatusDDList(this.gridData, this.model.QualityStatusID);

            this.markQualityStatusForReadonlyMode(data.Name.trim());
            this.showHideDialogue(true);
          },
          error: (error) => {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Quality status not added, please try again.' });

            this.showHideDialogue(false);
          }
        });
    }
    else {
      this._sharedService.clearToast();
      this._sharedService.showToast({ severity: 'warn', summary: 'No quality status Selected', detail: 'Please select a record.' });
    }
  }
  //#endregion
  //#region Add User Events
  btnAddClick() {
    this.operationMode = 1;
    this.dialogTitle = 'Add Container Quality Status';
    this.lblsubmitButton = 'Add';
    this.model = new ContainerQualityStatusModel();
    this.populateQualityStatusDDList(this.gridData, null);
    this.model.Status = true;
    this.model.CSSClass = '#0976e3';
    this.readonlyMode = false;
    this.showHideDialogue(true);
  }
  //#endregion

  //#region User Roles Assignment
  UpdateRoles() {
    if (!this._sharedService.UserRole) {
      this._sharedService.updateRole();
    }

    this.canAddEditContainerQualityStatus = this._sharedService.UserRole.Admin_Quality_Status;
  }
  //#endregion

  //#region Cancel Dialogue
  btnCancelClick(cancelForm: NgForm) {
    this.showHideDialogue(false);
    this.readonlyMode = false;
    this.model = new ContainerQualityStatusModel();
    cancelForm.resetForm();
  }
  //#endregion
  //#region Helper Methods
  showHideDialogue(flag: boolean) {
    this.display = flag;
  }
  changeButtonsStatus(status: boolean) {
    this.isSubmitted = status;
  }

  markQualityStatusForReadonlyMode(qualityStatus: string) {

    this.readonlyMode = false;


  }
  //#endregion

  //#region form submit
  onSubmit(form: NgForm) {
    this.changeButtonsStatus(true);
    this._sharedService.showLoader("Processing...");
    if (this.operationMode == 1) {
      let body: any =
      {
        QualityStatusID: 0,
        Status: this.model.Status,
        Name: this.model.Name,
        CSSClass: this.model.CSSClass,
        Description: this.model.Description,
        ResetChange: this.model.ResetChangeID
      }
      this._containerQualityStatusService.addUpdateQualityStatus(body)
        .subscribe({
          next: (response) => {
            this.showHideDialogue(false);
            form.resetForm();
            this._sharedService.hideLoader();
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Container Quality status added succesfully.' });
            this.changeButtonsStatus(false);
            this.populateQualityStatusGrid();
          },
          error: (error) => {
            this._sharedService.hideLoader();
            this._sharedService.clearToast();
            if (error.status === 409) {
              this._sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Container Quality status already exists.' });
            }
            else {
              this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Container Quality status not added, please try again.' });
            }
            this.showHideDialogue(false);
            this.model = new ContainerQualityStatusModel();
            form.resetForm();
            this.changeButtonsStatus(false);
          }
        });
    }
    else if (this.operationMode == 2) {
      let body: any =
      {
        QualityStatusID: this.selectedContainerQualityStatusID,
        Status: this.model.Status,
        Name: this.model.Name,
        CSSClass: this.model.CSSClass,
        Description: this.model.Description,
        ResetChange: this.model.ResetChangeID
      }
      this._containerQualityStatusService.addUpdateQualityStatus(body)
        .subscribe({
          next: (response) => {
            this.showHideDialogue(false);
            form.resetForm();
            this._sharedService.hideLoader();
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Container Quality status updated succesfully.' });
            this.changeButtonsStatus(false);
            this.populateQualityStatusGrid();
          },
          error: (error) => {
            this._sharedService.hideLoader();
            this._sharedService.clearToast();
            if (error.status === 409) {
              this._sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Container Quality status already exists.' });
            }
            else {
              this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Container Quality status not updated, please try again.' });
            }
            this.showHideDialogue(false);
            this.model = new ContainerQualityStatusModel();
            form.resetForm();
            this.changeButtonsStatus(false);
          }
        });
    }
  }
  //#endregion

  //#region Grid Context Menu
  getCustomContextMenuItems(params: any): any {
    let result: any[] = [];

    if (params.context.gridContext.canAddEditContainerQualityStatus) {
      result.push({
        name: "Add Container Quality Status",
        action: function () {
          params.context.gridContext.btnAddClick()
        },
        icon: params.context.gridContext._sharedService.addIconHTMLasString,
      });

      result.push({
        name: "Edit Container Quality Status",
        action: function () {
          params.context.gridContext.selectedContainerQualityStatusID = null;
          if (params.node) {
            params.context.gridContext.selectedContainerQualityStatusID = params.node.data.QualityStatusID;
            params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
            params.context.gridContext.btnEditClick()
          }
        },
        icon: params.context.gridContext._sharedService.editIconHTMLasString,
      });

      let childResult: any[] = [];
      childResult.push({
        name: 'Excel',
        action: function () {

          params.api.exportDataAsExcel();

        },
        icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
      });

      childResult.push({
        name: 'CSV',
        action: function () {
          params.api.exportDataAsCsv();
        },
        icon: params.context.gridContext._sharedService.saveLogFeedIconHTMLasString,
      });

      result.push({
        name: 'Download',
        action: function () {


        },
        icon: params.context.gridContext._sharedService.downloadsFeedIconHTMLasString,
        subMenu: childResult,
      });

    }
    return result;
  }
  navigateToNextCell(params: any) {
    var previousCell = params.previousCellPosition;
    var suggestedNextCell = params.nextCellPosition;
    var KEY_UP = 'ArrowUp';
    var KEY_DOWN = 'ArrowDown';
    var KEY_LEFT = 'ArrowLeft';
    var KEY_RIGHT = 'ArrowRight';
    switch (params.key) {
      case KEY_DOWN:
        var nextRowIndex = previousCell.rowIndex + 1;
        var renderedRowCount = this.gridOptions.api.getModel().getRowCount();
        if (nextRowIndex >= renderedRowCount) {
          return null;
        } else {
          this.gridOptions.api.deselectAll();
          var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
          rowNode.setSelected(true);
          this.selectedContainerQualityStatusID = null;
          this.selectedContainerQualityStatusID = rowNode.data.QualityStatusID;
          return {
            rowIndex: nextRowIndex,
            column: previousCell.column,
            floating: previousCell.floating
          };
        }
      case KEY_UP:
        var _nextRowIndex = previousCell.rowIndex - 1;
        if (_nextRowIndex < 0) {
          return null;
        } else {
          this.gridOptions.api.deselectAll();
          var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
          rowNode.setSelected(true);
          this.selectedContainerQualityStatusID = null;
          this.selectedContainerQualityStatusID = rowNode.data.QualityStatusID;
          return {
            rowIndex: _nextRowIndex,
            column: previousCell.column,
            floating: previousCell.floating
          };
        }
      case KEY_LEFT:
      case KEY_RIGHT:
        return suggestedNextCell;
      default:
        throw 'this will never happen, navigation is always on of the 4 keys above';
    }
  }
  //#endregion
}