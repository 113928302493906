export class CustomGeneralSetting {

    constructor(
    public title: string,
    public color: string,
    public columns: string,
    public rows: string,
    ) { }

}
