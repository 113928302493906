
//Components
import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { ContainerService } from '../../services/container/container.service'
import { container, containerForEdit, containerForDateChange } from '../../services/container/container'
import { IConfig } from '../../../shared/helpers/config';
import { ClipboardModule } from 'ngx-clipboard';
import * as moment from 'moment/moment';
import { Subscription } from 'rxjs';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { GridOptions, GridApi, RowNode, ColDef } from "ag-grid-community";
import { ContainerQualityStatusService } from '../../../admin/services/containerqualitystatus/container-quality-status.service';
import { isNullOrUndefined } from '../../../shared/classes/nullorundefined';
import { stdin } from 'process';
import { DateContainer } from '../edit-container/edit-container';
var dateFormatF: string;
var dateSeparatorS: string;
var dayD: number;
var monthM: number;
var yearY: number;
//import 'moment/locale/uk';
@Component({
  selector: 'datechange-container',
  templateUrl: './datechange-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ContainerService, ConfirmationService, ContainerQualityStatusService]
})

export class DateChangeContainerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() recordType: string;
  isSubmitted: boolean = false;
  display: boolean = false;
  @Input() inputData: DateContainer;
  dateSeparator = "";
  dateFormat = "";
  dateFormatCalender = "";
  configuration: IConfig[] = [];
  CreatedOn: string;
  CreatedBy: string;
  ViewDateFormat: string;
  model: DateContainer;
  private day: number;
  private month: number;
  private year: number;
  public lastIsDot: boolean = false;
  public lastIsDotWonum: boolean = false;
  WOTypes: SelectItem[] = [];


  constructor(public _sharedService: SharedService, private _router: Router, private cRef: ChangeDetectorRef,
    private _containerService: ContainerService, private _confirmationService: ConfirmationService, private _hotkeysService: HotkeysService, private _qualityStatusService: ContainerQualityStatusService) {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(['login']);
      this._sharedService.SessionExpiredMessage();
    }
    this.model = new DateContainer(null, null, null, null, null, null, null, null, null, null)

  }
  ngOnInit() {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(['login']);
      this._sharedService.SessionExpiredMessage();
    }

  }

  ngOnDestroy() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes != null) {
      this.showEditDialog();

      this.display = true;
    }

  }
  public showEditDialog() {
    this.setWOType();
    this.setCalenderDateFormat();
    this.ViewDateFormat = this._sharedService.getLabelTextDateFormat();

  }

  onSubmit(form: NgForm) {
    this._confirmationService.confirm({
      key: "confirmdateupdate",
      message: "This change is in key metadata fields, it may take few minutes to complete, Do you want to proceed?",
      accept: () => {
        this.update(form);
      },
      reject: () => {

      }
    });


  }
  update(form: NgForm) {
    this.changeButtonsStatus(true);
    //US-8181 //let assetInfo: string = this._sharedService.selectedAsset[0].data.split('|');
    let assetDataObj = JSON.parse(this._sharedService.selectedAsset[0].data);
    let body: containerForDateChange =
    {
      ID: this.getID(),
      ContainerID: this.model.containerID.toString(),
      Date: this.getDate(this.model.crsdate),
      EndDate: this.getDate(this.model.enddate),
      Type: this.model.wotype,
      ReferenceKey: this.recordType == "LOG" ? this.setTechLogAnalyzerConfig() : this.getWONumber(),
    }
    this._sharedService.showLoader("Processing...");
    this._containerService.changeContainerDate(body)
      .subscribe({
        next: (response) => {
          this.changeButtonsStatus(false);
          this.display = false;
          form.resetForm();
          //this.setDefaultComponentValues();
          this._sharedService.hideLoader();
          this._sharedService.clearToast();
          this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Container updated succesfully.' });
          this._sharedService.onAssetChanged(this._sharedService.selectedAsset);
          this._sharedService.onAssetStatusChanged(this._sharedService.selectedAsset);
        },
        error: (error) => {
          this._sharedService.hideLoader();
          this._sharedService.clearToast();
          this.changeButtonsStatus(false);
          if (error.status === 409) {
            this._sharedService.showToast({
              severity: "error",
              summary: "Already Exists",
              detail: "Container already exists.",
            });
          }
          else if (error.status === 500 && error.error === '304') {
            this._sharedService.showToast({
              severity: "error",
              summary: "Error Occured",
              detail: "No Change In Data.",
            });
          }
          else {
            this._sharedService.showToast({
              severity: "error",
              summary: "Error Occured",
              detail: "Container not added, please try again.",
            });
          }
        }
      });
  }
  getDate(date: Date): string {
    if (date != null) {
      //let newDate = moment(date).format(this.dateFormat.toUpperCase());
      // let newDate = moment(date).format("YYYY-MM-DD");
      //return new Date(newDate).toDateString();
      let typeCheck = date;
      if (typeCheck.toString().length == 10) {
        let dateString = date.toString().substring(0, 10);
        let dateParts = dateString.split(this.dateSeparator);
        let day = Number(dateParts[Number(this.day)].substring(0, 2));
        let month = Number(dateParts[Number(this.month)]) - 1;
        let year = Number(dateParts[Number(this.year)]);
        return new Date(year, month, day).toDateString();
      }
      else {
        let newDate = moment(date).format("YYYY-MM-DD");
        return new Date(newDate).toDateString();
      }
    }
    return null;
  }


  btnCancelClick(cancelForm: NgForm) {
    this.display = false;
    this.changeButtonsStatus(false);
    cancelForm.resetForm();
    this.model = new DateContainer(null, null, null, null, null, null, null, null, null, null)
  }
  changeButtonsStatus(status: boolean) {
    this.isSubmitted = status;
  }

  setCalenderDateFormat() {
    this.configuration = this._sharedService.getConfiguration();
    this.dateFormat = this.configuration.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
    this.dateSeparator = this.configuration.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
    this.day = Number(this.configuration.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
    this.month = Number(this.configuration.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
    this.year = Number(this.configuration.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
    let dateParts: string[] = this.dateFormat.split(this.dateSeparator);

    if (this.year == 0) {
      this.dateFormatCalender = (dateParts[this.year].substr(0, 2) + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.day]).toLowerCase();
    }
    else if (this.year == 2) {
      if (this.month == 1) {
        this.dateFormatCalender = (dateParts[this.day] + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
      }
      else if (this.month == 0) {
        this.dateFormatCalender = (dateParts[this.month] + this.dateSeparator + dateParts[this.day] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
      }

    }
    else {
      this.dateFormatCalender = "yy-mm-dd"
    }

  }

  isLastDot(str) {
    let val = str.replace(/\s/g, "");
    if (val != "") {
      let lastChar;
      lastChar = val.slice(-1);
      if (lastChar === ".") {
        this.lastIsDot = true;
      } else {
        this.lastIsDot = false;
      }
    } else {
      this.lastIsDot = false;
    }
  }

  isLastDotWonum(str) {
    let val = str.replace(/\s/g, "");
    if (val != "") {
      let lastChar;
      lastChar = val.slice(-1);
      if (lastChar === ".") {
        this.lastIsDotWonum = true;
      } else {
        this.lastIsDotWonum = false;
      }
    } else {
      this.lastIsDotWonum = false;
    }
  }

  onCloseDate() {
    var enddate: any = document.getElementsByClassName('enddate')[0].children[1]
    enddate.focus();
  }
  onCloseEnddate() {
    var desr = document.getElementById('desr');
    desr.focus();
  }

  getID(): string {
    if (this.recordType == "MXR") {
      if (this.model.wonumber.length > 0) {
        return (
          this.convertToShortISOFormat(this.model.crsdate) +
          "-" +
          this.model.wonumber
          // this.removeSlashFromString(this.model.wonumber)
        );
      } else {
        return (
          this.convertToShortISOFormat(this.model.crsdate) +
          "-" +
          this.convertToShortISOFormat(this.model.enddate)
        );
      }
    } else if (this.recordType == "LOG") {
      return (
        this.convertToShortISOFormat(this.model.crsdate) +
        "-" +
        this.convertToShortISOFormat(this.model.enddate)
      );
    } else if (this.recordType == "GEN") {
      // return this.model.generalID
      return (
        this.convertToShortISOFormat(this.model.crsdate) +
        "-" +
        this.model.generalID
        // this.removeSlashFromString(this.model.generalID)
      );
    }
    else if (this.recordType == "DOC" || this.recordType == "BND") {
      return (this.model.ID);
    }
  }

  setTechLogAnalyzerConfig(): string {
    if (this.recordType == "LOG") {
      let _IncrementValue: string = '';
      let _StartNumber: string = '';
      let _NumberOfPages: string = '';

      _IncrementValue = isNullOrUndefined(this.model.IncrementValue) ? "" : this.model.IncrementValue.toString();
      _StartNumber = isNullOrUndefined(this.model.StartNumber) ? "" : this.model.StartNumber.toString();
      _NumberOfPages = isNullOrUndefined(this.model.NumberOfPages) ? "" : this.model.NumberOfPages.toString();

      return _IncrementValue + "-" + _StartNumber + "-" + _NumberOfPages;
      //return this.model.IncrementValue + "-" + this.model.StartNumber + "-" + this.model.NumberOfPages;
    }
  }

  convertToShortISOFormat(date: Date): string {
    return moment(date).format("YYYYMMDD").toString();
  }

  getWONumber(): string {
    if (this.recordType != "GEN") {
      return this.model.wonumber;
    } else {
      return this.model.generalID;
    }
  }

  setWOType() {
    this.WOTypes = [];
    let body: container = {
      Category: this.recordType,
    };
    this._containerService.getContainerType(body)
      .subscribe({
        next: (response) => {
          let res = response;
          for (let i = 0; i < res.length; i++) {
            this.WOTypes.push({ value: res[i].Type, label: res[i].Type });
          }
          this.cRef.markForCheck();
          this.model = Object.assign({}, this.inputData);
          this.cRef.markForCheck();
        },
        error: (error) => {
        }
      });
  }
}
