//#region References
//Components
import { Component, OnInit, Input, ViewChild,ElementRef} from '@angular/core';
import { Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';

import { NgForm } from '@angular/forms';
import { TemplateType } from '../user-management/user-management';
import 'ag-grid-enterprise';
import { GridOptions,GridApi, RowNode  } from 'ag-grid-community';
import { UserService } from '../../../records/services/User/user.service';
//#endregion

//#region Type Decorator
@Component({
    selector: 'upload-template',
    templateUrl: './upload-template.component.html',
    providers:[UserService]
})
//#endregion

export class UploadTemplateComponent implements OnInit {

    //#region Locals Declaration
    uploaddisplay: boolean = false;
    isSubmitted: boolean = false;

    dialogTitle: string = "";
    dialogLable:string="";

    uploadUrl: string;

    selectedCCs: string[] = [];
    public bulkFiles: any[] = [];
    uploadedFiles: any[] = [];

    public errorGridOptionUser:GridOptions;
    errorColumnDefsUser: any[];
    public userGridData: any[] = [];
    public displayUserGrid:boolean=false;

    public errorGridOptionAircraft:GridOptions;
    errorColumnDefsAircraft: any[];
    public aircraftGridData: any[] = [];
    public displayAircraftGrid:boolean=false;

    @ViewChild('feedfile') selectedFeedFiles: any;


    @Input() templateType: number=0;

    attachedFiles: string[] = [];
    orignalAttachedFiles: string[] = [];
    isAnyFileRemoved: boolean = false;
    selectedFeedDataType: string = "";

    acceptfilestype: string = "";
    messageFileType: string = "";
    allowedFileTypes: string[] = ['xlsx', 'XLSX'];
    orignalDataUser:any[]=[];    
    orignalDataAircraft:any[]=[];    
    //#endregion

    //#region Initialization of Component
    constructor(private _sharedService: SharedService, private _router: Router, private _userService:UserService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
    }
    ngOnInit() {
        switch (this.templateType) {
            case TemplateType.User:
              this.dialogTitle="Bulk Upload";
              this.dialogLable="User Template File";
              this.errorGridOptionUser = <GridOptions>
                {
                    onGridReady: () => {
                        this.setErrorGridColumnsUser();
                        if (this.errorGridOptionUser.api) {
                            try {
                               // this.errorGridOptionUser.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridUserManagement'));
                                this.errorGridOptionUser.api.hideOverlay();
                                this.errorGridOptionUser.api.sizeColumnsToFit();
                            } catch (e)
                            {
                                console.log(e);
                            }                               
                
                        }
                    },  
                    onModelUpdated:()=>{
                        try {
                        this.errorGridOptionUser.api.sizeColumnsToFit();
                    } catch (e)
                    {
                        console.log(e);
                    }  
                    },                                                   
                    defaultColDef: {
                        sortable: true,
                        filter: true,
                        resizable:true
                    },
                    suppressContextMenu: true,
                };
                break;
            case TemplateType.Aircraft:
            this.dialogTitle="Bulk Upload";
            this.dialogLable="Aircraft Template File";
            this.errorGridOptionAircraft = <GridOptions>
                {

                    onGridReady: () => {
                        this.setErrorGridColumnsAircraft();
                        if (this.errorGridOptionAircraft.api) {
                            try {
                               // this.errorGridOptionUser.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridUserManagement'));
                                this.errorGridOptionAircraft.api.hideOverlay();
                                this.errorGridOptionAircraft.api.sizeColumnsToFit();
                            } catch (e)
                            {
                                console.log(e);
                            }                               
                
                        }
                    },
                    onModelUpdated:()=>{
                        try {
                        this.errorGridOptionAircraft.api.sizeColumnsToFit();
                    } catch (e)
                    {
                        console.log(e);
                    }  
                    },                
                    pagination: true,
                    paginationPageSize: 10,                   
                    defaultColDef: {
                        sortable: true,
                        filter: true,
                        resizable:true
                    }
                };
                break;
            default:
                break;
        }
        this.setDefaultComponentValues();
    }

    //#endregion
  
    // #region Form Events    

    showUploadDialog() {
       this.uploaddisplay=true;
       this.setDefaultComponentValues();
    }

    btnCancelClick(cancelForm: NgForm) {
        cancelForm.resetForm();
        this.clearSelectedFiles();
        this.uploaddisplay = false;      
    }
    //#endregion

    //#region File Uploader Events
    onSelect(event: any) {

        let allFilesValid: boolean = true;
        for (let file of event.files)
        {
            let validFileType = true;
            let lastOccurenceOfDot: number = file.name.lastIndexOf('.');
            if (lastOccurenceOfDot != -1) {
                let extenstion: string = file.name.substr(lastOccurenceOfDot + 1, file.name.length);
                let isCurrentFeedAllowedType: boolean = this.checkFileExtensionValidity(extenstion.toLowerCase(), this.selectedFeedDataType);
                if (!isCurrentFeedAllowedType)
                {
                    validFileType = false;
                    allFilesValid = false;
                }
                else {
                    validFileType = true;
                }
            }
            if (validFileType)
            {
                let fileCurrent: any = this.uploadedFiles.filter(x => x.name == file.name);
                if (fileCurrent.length > 0) {
                    for (let i = 0; i < this.selectedFeedFiles.files.length; i++) {
                        if (this.selectedFeedFiles.files[i].name == file.name) {
                            this.selectedFeedFiles.files.splice(i, 1);
                            break;
                        }

                    }

                }
                else {

                    this.uploadedFiles.push(file);

                }
            }
            else
            {

                let index: any = this.selectedFeedFiles.files.findIndex((x: any) => x.name == file.name);

                this.selectedFeedFiles.files.splice(index, 1);
               
            }
            if (!allFilesValid)
            {
                this.uploadedFiles = [];
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File(s)', detail: 'Only files of type '+this.messageFileType+' are valid' });
            }
        }
    }
    onRemove(event: any) {

        for (let i = 0; i < this.uploadedFiles.length; i++) {
            if (this.uploadedFiles[i].name == event.file.name) {
                this.uploadedFiles.splice(i, 1);
                break;
            }
        }

    }
    onBeforeUpload(event: any) {

        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }
    }

    clearSelectedFiles() {
        if(this.selectedFeedFiles){
        this.selectedFeedFiles.files = [];
        }
    }

    onBulkUpload(event: any, addForm: NgForm) {
        this.changeButtonsStatus(true);

        if (this.uploadedFiles.length > 0) {

            let xhr = new XMLHttpRequest(), formData = new FormData();
            for (let i = 0; i < this.uploadedFiles.length; i++) {
                formData.append("uploads['" + i + "']", this.uploadedFiles[i], this.uploadedFiles[i].name);
            }
            

            if (!(this.uploadedFiles.length >= 2)) {
                //formData.append("TemplateId", this.templateType.toString());
                switch (this.templateType) {
                    case TemplateType.User:
                    this.uploadUrl = `${this._sharedService.baseUrl}/api/Account/UploadTemplate`;
                        break;
                    case TemplateType.Aircraft:
                    this.uploadUrl = `${this._sharedService.baseUrl}/api/Asset/UploadTemplate`;
                    default:
                        break;
                }
                xhr.open('POST', this.uploadUrl, true);
                xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
                this._sharedService.showLoader("Processing...");
                xhr.send(formData);

                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        this._sharedService.hideLoader();
                        if (xhr.status === 200) {
                            this.isAnyFileRemoved = false;
                            switch (this.templateType) {
                                case TemplateType.User:
                                this.orignalDataUser=JSON.parse(xhr.response);
                                this.userGridData=this.orignalDataUser;

                                this.displayUserGrid=true;
                                this.uploaddisplay = false;
                                this._sharedService.resfreshUsersGrid(true);
                                break;

                                case TemplateType.Aircraft:
                                this.orignalDataAircraft=JSON.parse(xhr.response);
                                this.aircraftGridData=this.orignalDataAircraft;
                                this.displayAircraftGrid=true;
                                this.uploaddisplay = false;
                                this._sharedService.resfreshAircraftGrid(true);
                                //refresh aircraft grid
                                default:
                                    break;
                            }
                           
                            
                            
                            this.uploadedFiles = [];
                            this.clearSelectedFiles();
                            addForm.resetForm();
                            this.changeButtonsStatus(false);
                            
                            // TO DO: Referesh User Grid
                        }
                        // else if(xhr.status === 200){
                        //     this._sharedService.clearToast();
                        //     switch (this.templateType) {
                        //         case TemplateType.User:
                        //         this._sharedService.showToast({ severity: 'success', summary: 'Success.', detail: 'Users created successfully.' });
                        //         this._sharedService.resfreshUsersGrid(true);
                        //         break;

                        //         case TemplateType.Aircraft:
                        //         this._sharedService.showToast({ severity: 'success', summary: 'Success.', detail: 'Aircraft created successfully.' });
                        //         //this._sharedService.resfreshAircraftGrid(true);
                        //         default:
                        //             break;
                        //     }
                          
                        // }
                        else {
                            this.isAnyFileRemoved = false;
                            this.uploaddisplay = false;

                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while uploading files.' });

                            addForm.resetForm();
                            this.initializeEmptyModel();

                            this.changeButtonsStatus(false);
                        }
                    }
                };
            }
            else {
              this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Multiple Files.', detail: 'Only one file can be uploaded for template.' });
                this.changeButtonsStatus(false);
            }
        }
        else {
            this.changeButtonsStatus(false);
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No File Selected', detail: 'No file(s) have been uploaded.' });
        }
    }
    //#endregion

    //#region Helper Methods

    setDefaultComponentValues(): void {
        this.initializeEmptyModel();
        this.changeButtonsStatus(false);
        this.uploadUrl = `${this._sharedService.baseUrl}/api/Account/UploadTemplate`;
    }

    initializeEmptyModel() {
        this.bulkFiles = [];
        this.uploadedFiles = [];
        this.clearSelectedFiles();

    }


    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }

    checkFileExtensionValidity(fileExtension: string, allowedType: string): boolean
    {
        let status: boolean = false;
        // In case file type not provided
        if(!allowedType)
        {
            return true;
        }
        switch (allowedType)
        {
            case 'XLSX':
                if (fileExtension == 'xlsx')
                {
                    status = true;
                }
                break;           
        }

        return status;
    }
    setFileUploadRequiredFields(): void {

        switch (this.selectedFeedDataType) {
            case 'XLSX':
                this.acceptfilestype = ".xlsx,.XLSX";
                this.messageFileType = "xlsx";
                break;            
        }
    }
    //#endregion    

    //#region Get Existing Files

    removeAttachedFile(file: any) {
        this.isAnyFileRemoved = false;
        for (let i = 0; i < this.attachedFiles.length; i++) {
            if (this.attachedFiles[i] == file) {
                this.attachedFiles.splice(i, 1);
                this.isAnyFileRemoved = true;
                break;
            }
        }

    }
            //#endregion 
// #region Grid Events
setErrorGridColumnsUser(): void {
    this.errorColumnDefsUser = [];
    this.errorColumnDefsUser =
        [
            { headerName: 'First Name', field: 'FirstName', menuTabs: ['filterMenuTab'] },
            { headerName: 'Last Name', field: 'LastName', menuTabs: ['filterMenuTab'] },
            { headerName: 'Email', field: 'Email', menuTabs: ['filterMenuTab'] },
            { headerName: 'Team', field: 'UserGroup', menuTabs: ['filterMenuTab'],valueFormatter: function (params: any) {
                if (params.value) {
                    return params.value.Label;
                }              
            }},
            {
                headerName: 'Status', field: 'Status', filter: false, suppressMenu: true,valueFormatter: function (params: any) {
                    if (params.value) {
                        return params.value.Label;
                    }                    
                }                    
            },
            { headerName: 'State', field: 'State', menuTabs: ['filterMenuTab'],cellRenderer: this.stateCellRenderer },
            { headerName: 'Errors', field: 'Error', menuTabs: ['filterMenuTab'], tooltipField: 'Error' },
        ];
}
setErrorGridColumnsAircraft(): void {
    this.errorColumnDefsAircraft = [];
    this.errorColumnDefsAircraft =
        [
            { headerName: 'Title', field: 'Description', filter: 'text', menuTabs : ['filterMenuTab']  },
            { headerName: 'Manufacturer/Group Level 1', field: 'Manufacturer', filter: 'text', menuTabs: ['filterMenuTab']  },
            { headerName: 'Number/Serial', field: 'ASN', filter: 'text', menuTabs: ['filterMenuTab']  },
            {
                headerName: 'Status', field: 'Status', filter: false, suppressMenu: true,valueFormatter: function (params: any) {
                    if (params.value) {
                        return params.value;
                    }                    
                }                    
            },
            { headerName: 'State', field: 'State', menuTabs: ['filterMenuTab'],cellRenderer: this.stateCellRenderer },
            { headerName: 'Errors', field: 'Error', menuTabs: ['filterMenuTab'], tooltipField: 'Error' },
            { headerName: 'Messages', field: 'Messages', menuTabs: ['filterMenuTab'], tooltipField: 'Messages' }
        ];
}
statusCellRenderer(params: any) {
    let html: string = '';

    if (params.value && params.value!="") 
    {
      //  let errorsList= params.value.split(',');

      params.value.forEach(element => {
            html = html + element +'</br>'
        });  
       
    
    }

    var eDiv = document.createElement('div');
    eDiv.innerHTML = html;
    eDiv.style.textAlign = 'center';
    return eDiv;
}
stateCellRenderer(params: any) {
    let html: string = '';

    if (params.value) {

        if (params.value == 'Success') {
            html = html + '<span class="padding-5 label" style= "background-color:green;font-size: small;" title="' + params.value + '" >' + params.value + '</span>';
        }
        else if(params.value == 'Duplicate')
        {
            html = html + '<span class="padding-5 label" style= "background-color:#CCCC00;font-size: small;" title="' + params.value + '" >' + params.value + '</span>';
        }       
        else 
        {
            html = html + '<span class="padding-5 label" style= "background-color:red;font-size: small;" title="' + params.value + '" >' + params.value + '</span>';
        }
    }

    var eDiv = document.createElement('div');
    eDiv.innerHTML = html;
    eDiv.style.textAlign = 'center';
    return eDiv;
}
btnCancelDialogueClick(){
    this.uploaddisplay=false;
    this.displayUserGrid=false;
    this.displayAircraftGrid=false;
    this.userGridData=[];
    this.aircraftGridData=[];
}
// onProceedUser()
// {
//     try {
    
//     let toBeProcessedData:any[]= this.orignalDataUser.filter(x=>x.Error==null || x.Error=='');
//     if(toBeProcessedData.length>0){
//         this._sharedService.showLoader("Processing...");
//         this._userService.registerUsers(toBeProcessedData).subscribe( response => {
//             this._sharedService.hideLoader();
//             this.hideDailogues();
//             this._sharedService.clearToast();
//             this._sharedService.showToast({ severity: 'success', summary: 'User(s) Created', detail: 'User(s) have been created successfully.' });
//             this._sharedService.resfreshUsersGrid(true);
//         }, error => {
//             this._sharedService.hideLoader();
//             this._sharedService.clearToast();
//                 this._sharedService.showToast({ severity: 'error', summary: 'Error Occurred', detail: 'Error occurred.' });
//         });
//     }
//     else
//     {
//         this.hideDailogues();
//     this._sharedService.clearToast();
//     this._sharedService.showToast({ severity: 'warn', summary: 'No Records Found', detail: 'No records found for processing.' });
//     }
        
// } catch (error) {
//         console.error(error);
// }
// }
refreshGridColumns(event:any){
    if (this.errorGridOptionUser.api) {
        try {           
            this.errorGridOptionUser.api.sizeColumnsToFit();
        } catch (e)
        {
            console.log(e);
        }                               

    }
}
hideDailogues(){
    this.uploaddisplay=false;
    this.displayUserGrid=false;
    this.userGridData=[];
}
//#endregion



}
