export class ViewDataTypeModel {
    constructor(
        public ViewDataTypeID?: string,
        public Name?: string,
        public Description?: string,        
        public CSSClass?:string,
        public Status?: boolean,
        public CreatedBy?: string,
        public CreatedOn?: Date,
        public ModifiedBy?: string,
        public ModifiedOn?: Date,
       
     ) {
    }
}

export enum OperationMode {
    Add = 1,
    Edit = 2,
    Delete = 3
}