import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {SharedService } from '../../../shared/services/shared.service';
@Injectable()
export class HelpService {

    constructor(private _sharedService: SharedService ,  private http: HttpClient) {
    }
  
    getHelp(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Help/GeHelp`, body, this._sharedService.getReqOptWithAccessToken());
    }
}