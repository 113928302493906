import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';

import { SharedService } from '../../../shared/services/shared.service';

@Injectable()
export class DFPStatusService {
    constructor(private http : HttpClient, private _sharedService: SharedService ) {
    }

    addUpdateDFPStatus(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/DFPStatus/AddUpdateDFPStatus`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getDFPStatusByID(body: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/DFPStatus/GetDFPStatusByID?dfpStatusID=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getAllDFPStatusData(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/DFPStatus/GetAllDFPStatusData`, body, this._sharedService.getReqOptWithAccessToken());
    }
}