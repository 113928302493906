
//Components
import { Component, OnInit, Input,OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';
import { SelectItem } from 'primeng/api';
import { EditJobModel, ConversationList } from './edit-job';
import { jobs } from '../job/job';
import { NgForm } from '@angular/forms';
import { ContainerService } from '../../../records/services/container/container.service'
import { JobService } from '../../services/dp-jobs.service'
import { container } from '../../../records/services/container/container'
import { AssetService } from '../../../admin/services/asset/asset.service'
import { UserService } from '../../../records/services/User/user.service';
import { IConfig } from '../../../shared/helpers/config';
import * as moment from 'moment/moment';
import { Subscription } from 'rxjs';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { SafeHtmlPipe } from '../../../shared/pipes/safe-html-pipe';
import { IDateFormatInfo } from 'src/app/shared/helpers/dateFormatInfo';
var dateTimeConfig: IDateFormatInfo;
//import 'moment/locale/uk';
@Component({
    selector: 'edit-job',
    templateUrl: './edit-job.component.html',
    changeDetection:ChangeDetectionStrategy.OnPush,
    providers: [ContainerService, JobService, AssetService, UserService]
})

export class EditJobComponent implements OnInit, OnDestroy {

    display: boolean = false;
    @Input() recordType: string;
    @Input() jobId: string;
    dialogTitle: string = "";
    selectedWOType: string;
    WOTypes: SelectItem[] = [];
    priorityList: SelectItem[] = [];
    jobInternalList: SelectItem[] = [];
    internalUsersList: SelectItem[] = [];
    jobStatusList: SelectItem[] = [];
    batchTypesList: SelectItem[] = [];
    assets: SelectItem[] = [];
    model: EditJobModel;
    //newEditModel: EditJobModel;
    orignalModel: EditJobModel;
    //cont: container = [];
    dateSeparator = "";
    dateFormat = "";
    dateFormatCalender = "";
    configuration: IConfig[] = [];
    public day: number;
    public month: number;
    public year: number;
    isSubmitted: boolean = false;
    isAuthorizedUser: boolean = false;
    userType: string = "";

    WorkOrderLabel: string = '';
    isDataChanged: boolean = false;
    public isEditVisible: boolean = false;
    public isEditVisibleReadonly: boolean = false;
    public editJobSub: Subscription;
    public editJobByIdSub: Subscription;
    oldConversation: string = "";
    allConversations: ConversationList;
    public referenceKeyLabel:string="";
    public isJobModule:boolean=true;
    containerSub: Subscription;
    public screenmode:string='EDIT';
    fullPath:string='';
    batchTypesListMaster: SelectItem[] = [];

    public appHotkeys:  Hotkey[] = [];

    CreatedOn:string;
    ViewDateFormat:string;

    placeholderContainerType="Select Container Type";
    placeholderRDOCBatchType="Select RDOC Batch Type";
    //#region Initialization of Component
    constructor(public _sharedService: SharedService, private cRef:ChangeDetectorRef, private _router: Router, private _containerService: ContainerService, private _jobService: JobService, private _assetService: AssetService, private _userService: UserService, private _hotkeysService: HotkeysService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.configuration = this._sharedService.getConfiguration();           
            this.setHotKeys();
        }
    }
    ngOnInit() {
        this.referenceKeyLabel=this._sharedService.getReferenceKeyLabel();
        dateTimeConfig = this._sharedService.getDateTimeFormatConfigFromLocalStorage();
        this.UpdateRoles();
        this.initializeModel();
        this.populateBatchTypesList();
        this.isJobModule=this.IsInvokedFromJobModule();
        if (this.userType=="Internal")
        {
            this.populateJobInternalUsers();
        }
         this.editJobSub = this._sharedService._editJob
         .subscribe({
            next: (response) => {
                this.showEditDialog();
            },
            error: (error) => {
            }
        })

         this.editJobByIdSub = this._sharedService._editJobByID
         .subscribe({
            next: (jobID) => {
                this.jobId = jobID;
                this.showEditDialog();
            },
            error: (error) => {
            }
        })
         this.containerSub =  this._sharedService._selectedContainerFromComponentEdit
         .subscribe({
            next: (params:any) => {
                this.getContainerDataByID(params.ContainerID);
            },
            error: (error) => {
            }
        })

      let screenWidth = window.innerWidth;
      if (screenWidth < 1025) {
      this.placeholderContainerType="Select Container...";
      this.placeholderRDOCBatchType="Select RDOC Batch...";
        }
    }

    ngOnDestroy() {
        if (this.editJobSub) {
            this.editJobSub.unsubscribe();
        }
        if (this.editJobByIdSub) {
            this.editJobByIdSub.unsubscribe();
        }
        if (this.containerSub) {
            this.containerSub.unsubscribe();
        }

        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
    }
    getContainerDataByID(containerID:string)
    {
        this._containerService.getContainerDetail(containerID)
        .subscribe({
            next: (response) => {
                this.model.asset=response.ASN;
                this.model.date=this._sharedService.getFormatedDate(response.Date);
                this.model.enddate=this._sharedService.getFormatedDate(response.EndDate);
                this.model.referencekey=response.ReferenceKey;
                this.model.type=response.Type;
                this.model.containerid=containerID;
                this.containerTypeChanged(response.Type);
                if(!this.display)
                {
                    this.display = true;
                }
                this.cRef.markForCheck();
            },
            error: (error) => {
            }
        });
    }
    setHotKeys() {

        let hotKeyObj : Hotkey = null;
        //appHotkeys

        hotKeyObj = new Hotkey('ctrl+shift+e', (event: KeyboardEvent): boolean => {
            this.showEditDialog();
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
        hotKeyObj = new Hotkey('enter', (event: KeyboardEvent): boolean => {
            this.showEditDialog();
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);


    }
    ////#endregion

    // #region Form Events

    showEditDialog() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            if (this.jobId != "") {
                this.initializeModel();
                this.setCalenderDateFormat();
                this.dialogTitle = "Edit Job";
                this.setDefaultComponentValues();
                //this.display = true;
            }
            else {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'No Job Selected', detail: 'Please select a row first' });
            }
        }
    }


    onSubmit(form: NgForm) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.changeButtonsStatus(true);
            let history = this.getHistory(this.orignalModel, this.model);
            if (this.isDataChanged) {
                let body: jobs =
                    {
                        Status: this.model.status,
                        Type: this.model.type,
                        ReferenceKey: this.model.referencekey,
                        Date: this.getDate(this.model.date),
                        EndDate: this.getDate(this.model.enddate),
                        Asset: this.model.asset,
                        AssignedTo: this.getAssignedTo(),
                        Comments: this.model.comments,
                        Documents: this.model.documents,
                        Pages: this.model.pages,
                        DpJobInternalStatusId: this.model.dpjobinternalstatusid,
                        DpJobStatusId: this.model.dpjobstatusid,
                        Priority: this.model.priority,
                        DpJobId: this.model.dpjobid,
                        History: history,
                        Conversation: this.getConversation(),
                        AssignedToEmail:this.getAssignedToEmail(),
                        BatchTypeID:this.getBatchTypeId(this.model.batchtypeid),
                        ContainerID:this.model.containerid
                    }
                this._sharedService.showLoader("Processing...");
                this._jobService.updateJob(body)
                .subscribe({
                    next: (response) => {
                        this._sharedService.hideLoader();
                        this.display = false;
        
                        this._sharedService.resfreshJobGrid(true);
        
                        form.resetForm();
                        this.initializeModel();
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Job updated succesfully.' });
                        this.changeButtonsStatus(false);
                    },
                    error: (error) => {
                        this._sharedService.hideLoader();
                        this._sharedService.clearToast();
                        if (error.status === 409) {
                            this._sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Job already exists.' });
                        }
                        else if (error.status === 400) {
                            this._sharedService.showToast({ severity: 'warn', summary: 'Asset is Readonly.', detail: 'Cannot update an item associated with readonly asset.' });
                        }
                        else {
                            console.log(error);
                            this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Job not updated, please try again.' });
                        }
                        this.display = false;
                        form.resetForm();
                        this.initializeModel();
                        this.changeButtonsStatus(false);
                    }
                });
            }
            else {
                this.display = false;
                this.changeButtonsStatus(false);
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'info', summary: 'No Change Applied', detail: 'Job data not changed.' });

            }
        }
    }
    onViewItem(form: NgForm) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.changeButtonsStatus(true);
            this.display = false;
            form.resetForm();
            this._sharedService.viewItemDetails(null);
        }
    }
    btnCancelClick(cancelForm: NgForm) {
        this.display = false;
        cancelForm.resetForm();
        this.initializeModel();
    }
    // #endregion

    //#region Types Data
    setWOType() {
        this.WOTypes = [];
        this._containerService.getAllActiveContainerTypes(null)
        .subscribe({
            next: (response) => {
                this.WOTypes = response;
            },
            error: (error) => {
            }
        });
    }
    //#endregion
    //#region Assets
    populateAsset() {
        this._assetService.getAllAssetsWithAsn()
        .subscribe({
            next: (response) => {
                this.assets = response;
                this.getSelectedJobData();
            },
            error: (error) => {
            }
        })
    }
    //#endregion
    // #region Priority List
    populatePriortityList() {
        this.priorityList = [
            { value: '1', label: 'High' },
            { value: '2', label: 'Normal' },
            { value: '3', label: 'Low' }
        ];
    }
    //#endregion
    //#region Job Status
    populateJobStatus() {
        this._jobService.getAllActiveJobStatus()
        .subscribe({
            next: (response) => {
                this.jobStatusList = response;
            },
            error: (error) => {
            }
        });
    }
    //#endregion
     //#region Batch Types List
     populateBatchTypesList() {
        this._jobService.getAllActiveBatchTypes()
        .subscribe({
            next: (response) => {
                this.batchTypesListMaster=response;
                this.batchTypesList = response;
            },
            error: (error) => {
            }
        });
    }
    //#endregion
    //#region Job Internal Status
    populateJobInternalStatus() {
        this._jobService.getAllActiveInternalJobStatus()
        .subscribe({
            next: (response) => {
                this.jobInternalList = response;
            },
            error: (error) => {
            }
        });
    }
    //#endregion
    //#region Job Internal Status
    populateJobInternalUsers() {
        this._userService.getAllInternalUsersWithEmail(this._sharedService.baseUrl, null)
        .subscribe({
            next: (response) => {
                this.internalUsersList = response;
            },
            error: (error) => {
            }
        });
    }
    //#endregion
    //#region Date Manipulation Helper Methods
    getPlainDate(date: Date): string {
        if (date != null) {
            var formatted = moment(date).format(this.dateFormat.toUpperCase());
            if (this.dateSeparator == "-") {
                let newStr = formatted.toString().replace(/-/g, "");
                return newStr;
            }
            else if (this.dateSeparator == "/") {
                let newStr = formatted.toString().replace(/\//g, "");
                return newStr;
            }
        }
        else {
            return "";

        }
    }

    setCalenderDateFormat() {
        this.dateFormat = this.configuration.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
        this.dateSeparator = this.configuration.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
        this.day = Number(this.configuration.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
        this.month = Number(this.configuration.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
        this.year = Number(this.configuration.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
        let dateParts: string[] = this.dateFormat.split(this.dateSeparator);

        if (this.year == 0) {
            this.dateFormatCalender = (dateParts[this.year].substr(0, 2) + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.day]).toLowerCase();
        }
        else if (this.year == 2) {
            if (this.month == 1) {
                this.dateFormatCalender = (dateParts[this.day] + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
            }
            else if (this.month == 0) {
                this.dateFormatCalender = (dateParts[this.month] + this.dateSeparator + dateParts[this.day] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
            }
        }
        else {
            this.dateFormatCalender = "yy-mm-dd"
        }

    }
    getFormatedDate(date?: any): Date {
        if (date != null) {
            let dateString = date.substr(0, 10);
            let dateParts = moment(dateString).format(this.dateFormat.toUpperCase()).split(this.dateSeparator);
            let day = Number(dateParts[Number(this.day)].substring(0, 2));
            let month = Number(dateParts[Number(this.month)]) - 1;
            let year = Number(dateParts[Number(this.year)]);
            let cellDate = new Date(year, month, day);
            return cellDate;
        }
        else
        {
            return null;
        }
    }
    setDefaultComponentValues(): void
    {
        this.setWOType();
        this.populateJobStatus();
        this.populatePriortityList();
        if (this.userType=="Internal")
        {
            //this.populateJobInternalUsers();
            this.populateJobInternalStatus();

        }
        this.populateBatchTypesList();
        this.changeButtonsStatus(false);
        this.populateAsset();

    }
    getDate(date: Date): string {
        if (date != null) {
            let newDate = moment(date).format("YYYY-MM-DD");
            return new Date(newDate).toDateString();
        }
        return null;
    }
    getDateForHistory(date: Date): string {
        if (date != null) {
            let typeCheck = date;
            if (typeCheck.toString().length == 10) {
                let dateString = date.toString().substring(0, 10);
                let dateParts = dateString.split(this.dateSeparator);
                let day = Number(dateParts[Number(this.day)].substring(0, 2));
                let month = Number(dateParts[Number(this.month)]) - 1;
                let year = Number(dateParts[Number(this.year)]);
                return new Date(year, month, day).toDateString();
            }
            else {
                let newDate = moment(date).format("YYYY-MM-DD");
                return newDate;
            }
        }
        return null;
    }
    getCurrentDateTime() {
        let result = moment().format("MM/DD/YYYY" + " HH:mm");
        return result;
    }
    //#endregion
    //#region Model Initialization and updation
    getSelectedJobData() {
        let body: jobs =
            {
                DpJobId: this.jobId
            }
        this._jobService.getDpJobByID(body)
        .subscribe({
            next: (response) => {
                let result = response;
                this.fillModelValues(result);
            },
            error: (error) => {
            }
        });
    }
    fillModelValues(result: any) {
        this.oldConversation = result.Conversation;
        this.fillConversation(result.Conversation);
        this.model.type = result.Type;
        this.model.history = result.History;
        this.model.comments = result.Comments;
        this.model.documents = result.Documents;
        this.model.pages = result.Pages;
        this.model.assignedto = this.getAssignedToValue(result.AssignedTo);
        this.model.referencekey = result.ReferenceKey;
        this.model.priority = result.Priority;
        this.model.dpjobinternalstatusid = result.DpJobInternalStatusId;
        this.model.dpjobstatusid = result.DpJobStatusId.toString();
        this.model.asset = result.Asset;
        this.model.date = this.getFormatedDate(result.Date);
        this.model.enddate = this.getFormatedDate(result.EndDate);
        this.model.dpjobid = this.jobId;
        this.model.conversation = null;
        this.containerTypeChanged(result.Type);
        this.model.createdby=result.CreatedBy;
        this.CreatedOn=this._sharedService.getDateFormatTimeDialogBottom(result.CreatedOn);
        this.ViewDateFormat=this._sharedService.getLabelTextDateFormat();
        if(result.BatchTypeID)
        {
            this.model.batchtypeid=this.getBatchTypeAssignedValue(result.BatchTypeID.toString());
        }
        else
        {
            this.model.batchtypeid=null;
        }
        this.model.containerid=result.ContainerID;
        this.fullPath=result.FtpPath;
        if (this.userType == "Internal" && this.recordType == "4") {
            this.model.dpjobinternalstatusid = result.DpJobInternalStatusId;
        }

        this.display = true;
        this.cRef.markForCheck();
        this.orignalModel = new EditJobModel(this.model.asset, this.model.type, this.model.date, this.model.priority, this.model.dpjobstatusid, this.getAssignedToValue(this.model.assignedto), this.model.documents, this.model.pages, true, this.model.enddate, this.model.referencekey, this.model.dpjobinternalstatusid, this.model.comments, this.model.history, this.model.dpjobid,null,null,null,null,null,null,this.model.batchtypeid);
    }
    getLinkClick(){
      this._sharedService.clearToast();
        this._sharedService.showToast({ severity: 'success', summary: 'Copied!', detail: 'Link has been copied succesfully.' });
    }
    getAssignedToValue(assignedTo:string) {
        if (assignedTo && assignedTo != "")
        {
            if (this.internalUsersList && this.internalUsersList.length > 0)
            {
                let assignedUser = this.internalUsersList.find(x => x.label == assignedTo);
                if (assignedUser) {
                    return assignedUser.value;
                }
            }
            return null;
        }
        return null;
    }
    initializeModel() {

        this.model = new EditJobModel(null, null, null, null, null, null, null, null, true, null, null, null, null, null,null,null,null,null,null,null,null,null);
        this.oldConversation = "";
        this.model.conversation = null;
        this.fullPath='';
        this.userType = this._sharedService.getCurrentUserType();
    }
    //#endregion

    //#region History Manipulation
    getHistory(orignalModel: EditJobModel, currentModel: EditJobModel): string {
        // let changedData = [];
        //  this.newEditModel = new EditJobModel(null, null, null, null, null, null, null, null, true, null, null, null, null, null,null);
        try{
        let oldHistory = orignalModel.history != null ? orignalModel.history : "";
        let newHistory = '';
        let currentTimeStamp = this.getCurrentDateTime();
        for (let prop in orignalModel) {
            if (prop != "history") {
                if (orignalModel[prop] !== currentModel[prop]) {
                    this.isDataChanged = true;

                    if (prop != "date" && prop != "enddate") {
                        if (prop == "dpjobstatusid") {
                            newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "job status" + "," + this.jobStatusList.find(x => x.value == orignalModel[prop]).label + "," + this.jobStatusList.find(x => x.value == currentModel[prop]).label + "\r\n" + newHistory;
                        }
                        else if (prop == "priority") {
                            newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "priority" + "," + this.priorityList.find(x => x.value == orignalModel[prop]).label + "," + this.priorityList.find(x => x.value == currentModel[prop]).label + "\r\n" + newHistory;
                        }
                        else if (prop == "dpjobinternalstatusid") {
                            if (orignalModel[prop] == null)
                            {
                                newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "job internal status" + "," + "" + "," + this.jobInternalList.find(x => x.value == currentModel[prop]).label + "\r\n" + newHistory;
                            }
                            else
                            {
                                newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "job internal status" + "," + this.jobInternalList.find(x => x.value == orignalModel[prop]).label + "," + this.jobInternalList.find(x => x.value == currentModel[prop]).label + "\r\n" + newHistory;
                            }
                        }
                        else if (prop == "referencekey")
                        {
                            newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "wo/package number" + "," + orignalModel[prop] + "," + currentModel[prop] + "\r\n" + newHistory;
                        }
                        else if (prop == "assignedto")
                        {
                            if (orignalModel[prop] == null) {
                                newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "assigned to" + "," + "" + "," + this.getAssignedTo()+ "\r\n" + newHistory;
                            }
                            else {
                                newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "assigned to" + "," + orignalModel[prop] + "," + this.getAssignedTo()+ "\r\n" + newHistory;
                            }
                        }
                        else if (prop == "batchtypeid")
                        {
                            if (orignalModel[prop] == null) {
                                newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "batch type" + "," + "" + "," + this.batchTypesListMaster.find(x => x.value == currentModel[prop]).label+ "\r\n" + newHistory;
                            }
                            else {
                                newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "batch type" + "," + this.batchTypesListMaster.find(x => x.value == orignalModel[prop]).label + "," + this.batchTypesListMaster.find(x => x.value == currentModel[prop]).label+ "\r\n" + newHistory;
                            }
                        }
                        else {
                            if (prop != "conversation") {
                                if(prop != "containerid" && prop != "modifiedon" && prop != "modifiedby" && prop != "createdby" && prop != "createdon"){
                                newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + prop + "," + orignalModel[prop] + "," + currentModel[prop] + "\r\n" + newHistory;
                            }
                            }
                        }
                    }
                    else
                    {
                        if (prop == "date")
                        {
                            newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "date" + "," + this.getDateForHistory(orignalModel[prop]) + "," + this.getDateForHistory(currentModel[prop]) + "\r\n" + newHistory;
                        }
                        else
                        {
                            newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "end date" + "," + this.getDateForHistory(orignalModel[prop]) + "," + this.getDateForHistory(currentModel[prop]) + "\r\n" + newHistory;
                        }
                    }
                }


            }

        }
        if (this.isDataChanged) {
            return newHistory + oldHistory;
        }
    }
    catch(e){
console.log(e);
    }
    }
    //#endregion

   //#region Roles
    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }
        if (this.recordType != "") {
            this.isEditVisible = this._sharedService.UserRole.DP_Jobs_Edit;
        }
    }
     //#endregion
    //#region Dialogue Height Adjust
    adjustJobDlgHeight(event: any) {
        let tskDlgfrm = document.getElementById('jobeditDlg');

        if (tskDlgfrm) {
            tskDlgfrm.style.height = '79vh';
        //    tskDlgfrm.style.overflowY = 'scroll';
            tskDlgfrm.style.overflowX = 'hidden';
        }
    }
    //#endregion

    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }

    getConversation() {
        let oldConv = (this.oldConversation != "" && this.oldConversation != "null" && this.oldConversation != null) ? this.oldConversation : "";
        if (this.model.conversation != "" && this.model.conversation != "null" && this.model.conversation != null) {
            let newConv = this._sharedService.getCurrentUserName() + "||" + this.getCurrentDateTime() + "||" + this.model.conversation + "?|?";
            if (oldConv != "") {
                return newConv + oldConv;
            }
            return newConv;
        }
        return oldConv;
    }
    fillConversation(conversation: string) {
        this.allConversations = new ConversationList();
        this.allConversations.Conversation = [];
        if (conversation != '' && conversation != null) {
            let allConvList = [];
            allConvList = conversation.split("?|?");
            if (allConvList.length > 0) {
                for (var i = 0; i < allConvList.length; i++) {
                    let eachCon = [];
                    eachCon = allConvList[i].split("||");
                    if (eachCon.length > 1) {
                        this.allConversations.Conversation.push({ Name: eachCon[0], Comment: eachCon[2], Date: moment(eachCon[1]).format(dateTimeConfig.dateformat.toUpperCase() + " h:mm a").toString() });
                    }

                }
            }

        }

    }
    getAssignedTo(): string {
        try {
            if (this.model && this.model.assignedto) {
                if (this.model.assignedto != "") {
                    return this.model.assignedto.split("||")[0];
                }
            }
            return null;
        } catch (e) {
            console.log(e);
            return null;
        }
    }
    getAssignedToEmail(): string {
        try {
            if (this.model && this.model.assignedto) {
                if (this.model.assignedto != "") {
                    return this.model.assignedto.split("||")[1];
                }
            }
            return null;
        } catch (e) {
            console.log(e);
            return null;
        }

    }
    IsInvokedFromJobModule():boolean
    {
        if((!this.recordType || this.recordType=='MXR' || this.recordType=='LOG' || this.recordType=='GEN' || this.recordType=='DOC' || this.recordType=='BND') && this._sharedService.selectedContainer && this._sharedService.selectedContainer.ContainerID)
        {
            return false;
        }
        else
        {
            return true;
        }
    }
    containerTypeChanged(selectedValue:string)
    {
        this.model.batchtypeid=null;
        this.batchTypesList=[];
        if(selectedValue)
        {
            this.batchTypesListMaster.forEach(element => {

                let itemValue=element.value.split('|||')[1];
                if(itemValue==selectedValue || (!itemValue))
                {
                    this.batchTypesList.push(element);
                }

            });


        }

    }
    getBatchTypeId(batchType:any)
    {
        if(batchType)
        {
            let itemValue=batchType.split('|||')[0];
            return itemValue;
        }
        return null;
    }

    getBatchTypeAssignedValue(batchTypeid:any):any
    {

      for (var i = 0; i <  this.batchTypesListMaster.length; i++)
      {

        let itemValue=this.batchTypesListMaster[i].value.split('|||')[0];
        if(itemValue==batchTypeid)
        {
            return this.batchTypesListMaster[i].value;
        }
      }
        return null;
    }
    onCloseDate() {
        var enddate: any = document.getElementsByClassName('enddate')[0].children[1]
        enddate.focus();
    }
    onCloseEnddate() {
        var desr = document.getElementById('woNumberId');
        desr.focus();
    }
}
