import { CatSecAndArticle, sectionDetail } from './../articles/article';
import { SharedService } from './../../../shared/services/shared.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ArticleService } from './../../services/article/article.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
//import { defaultIterableDiffers } from '@angular/core/src/change_detection/change_detection';
var _router: Router;
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styles: []
})
export class HelpComponent implements OnInit {


  public CatSecAndArticle: CatSecAndArticle = {};
  public SectionDescription;
  public ArticleDetail;
  public Sections: sectionDetail[] = [];
  public LeftSideSections: sectionDetail[] = [];
  public RightSideSections: sectionDetail[] = [];
  public Name: '';
  paramsSub: Subscription;

  constructor(private _sharedService: SharedService, private _route: ActivatedRoute, private _articleHelp: ArticleService, private router: Router) {

    if (!this._sharedService.isAuthUser()) {
      this.router.navigate(['login']);
    }
    else {

      this._sharedService.updateAssignedRole();
      _router = this.router;
    }
  }
  public helpId;

  ngOnInit() {
    this._sharedService.prevURL = _router.url;
    if (this.paramsSub) { this.paramsSub.unsubscribe(); }
    this.paramsSub = this._route.params
      .subscribe((params: Params) => {
        if (params['id']) {
          this.helpId = params['id'];
          this.loadData(this.helpId);
        }
        else {
          var splitUrl = window.location.href.split('/');
          this.loadData(splitUrl.length - 1);

        }
      });


    // this.loadData(null);
  }

  trackByIndexFunc(index: number, value: number) {
    return index;
  }

  updateRoles() {
    if (!this._sharedService.UserRole) {
      this._sharedService.updateRole();
    }

  }

  redirect(id) {
    this.SectionDescription = this.CatSecAndArticle[0].Sections.find(x => x.ArticleSectionId === id);
    this._articleHelp.sectionChangeInfo(this.SectionDescription as any);
    _router.navigate(["/help/folder", id]);
  }

  redirectToArticle(ArticleId) {

    _router.navigate(["/help/article", ArticleId]);

  }
  loadData(id): void {

    this._sharedService.showLoader("Processing...");
    this._articleHelp.getArticleDetailByCategory(id)
    .subscribe({
      next: (response) => {
        this._sharedService.hideLoader();
        this.CatSecAndArticle = response;
        this.Sections = this.CatSecAndArticle[0].Sections;
        this.RightSideSections = this.Sections.filter(({ DisplayColumn }) => DisplayColumn === 2)
        this.LeftSideSections = this.Sections.filter(({ DisplayColumn }) => DisplayColumn === 1)
        this.Name = this.CatSecAndArticle[0].Name;
  },
      error: (error) => {
        console.log("some thing wrong  while getting data !");
  }
  });
  }
  ngOnDestroy() {
    if (this.paramsSub) {
      this.paramsSub.unsubscribe();
    }
  }
}
