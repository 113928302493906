//Components
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';

//interfaces
import { IMenu } from '../../../shared/services/menu/menu';

@Component({
    selector: 'comm',
    templateUrl: './comm.component.html'
})

export class CommComponent implements OnInit {
    constructor(private _sharedService: SharedService,  private _router: Router) {
    }
    ngOnInit() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }else {
            this._sharedService.updateAssignedRole();
        }
       
        
    }
  
}





   
