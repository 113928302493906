import { SelectItem } from "primeng/api/selectitem";

export class AddDataFeedModel {

  constructor(
    public feedid?: number,

    public feedname?: string,

    public feedtype?: string,

    public datatype?: string,

    public sourcetype?: string,

    public sourcedetail?: string,

    public targettype?: string,

    public targetdetail?: string,

    public interval?: string,

    public lastrun?: Date,

    public mapping?: string,

    public feedstatus?: string,

    public status?: boolean,

    public createdby?: string,

    public createdon?: Date,

    public modifiedby?: string,

    public modifiedon?: Date,
    public templatename?: string,
    public feedscope?: string
  ) { }

}
export class EncryptionAndDecryption{
    public Value : string
}

export class FtpModel {

  constructor(
    public FtpUserName?: string,

    public FtpPassword?: string,

    public FtpHost?: string,

    public FtpPort?: string,

    public FtpPath?: string,
    public FtpPasswordTemp?: string

  ) { }


}
export class AFSModel {

  constructor(
    public AFSStorageName?: string,

    public AFSKey?: string,

    public AFSPath?: string

  ) { }
}
export class WebServiceModel {

  constructor(
    public WebServiceUserName?: string,

    public WebServicePassword?: string,

    public WebServiceAuthType?: string,

    public WebServicePort?: string,

    public WebServicePath?: string,
    public WebServicePasswordTemp?: string

  ) { }

}


export class FeedTemplate {

  public FeedTemplateId: number;

  public TemplateName: string;

  public FeedType: string;

  public FeedScope: SelectItem[];

  public DataType: SelectItem[];

  public SourceType: SelectItem[];
  public SourceDetail: SelectItem[];

  public TargetType: SelectItem[];

  public TargetDetail: SelectItem[];

  public Mapping: string;

  public Interval: SelectItem[];

  public UserRole: string;

  public CreatedBy: string;

  public CreatedOn: Date;

  public ModifiedBy: string;

  public ModifiedOn: Date;

  public status: Boolean;

  
}