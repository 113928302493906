    <!-- Left panel : Navigation area -->
<!-- Note: This width of the aside area can be adjusted through LESS variables -->

<aside id="left-panel">
    <menu></menu>
    <asset-status-tree></asset-status-tree>
</aside>
    <!--routerLink="/logout"-->  <!--<a href="" (click)="logout($event)" class="mmetrofooter"  data-action="userLogout"><span class="iconbox"><i class="fa fa-sign-out fa-4x"></i><strong><u>L</u>ogout</strong></span></a>-->
    <!--parentItem.ColorCode?parentItem.ColorCode:''-->
    <!-- END NAVIGATION -->
    <!-- MAIN PANEL -->
    <div id="main" role="main">
        <!--class="whitebackground"-->
        <!-- MAIN CONTENT -->
        <div id="content">
            <router-outlet></router-outlet>

        </div>
        <!-- END MAIN CONTENT -->

    </div>
    <!--</div>-->
    <!-- END MAIN PANEL -->
