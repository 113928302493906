<section id="widget-grid" class="adminaircraftcls" *ngIf="canAddEditContainerType">

    <div class="row">
        <article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable" id="col1">

            <div class="no-margin jarviswidget jarviswidget-sortable" id="wid-id-2" data-widget-colorbutton="false"
                data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false"
                data-widget-togglebutton="false" role="widget">
                <p-confirmDialog></p-confirmDialog>
                <header role="heading" class="p-sortable-handle">

                    <span class="widget-icon"><i class="fa fa-search"></i></span>
                    <h2>Search Synonym</h2>
                  
      
                      
                    <div class="jarviswidget-ctrls" style="float: left; margin-top: 9px;">
                    
                        <div style="float: left; margin-left: 20px;margin-right: 10px;">
                            <label >Search Database:</label>
                        </div>
                        <div class="input-group" style="width: 150px;margin-top:-2px">
                            <p-dropdown id="searchDB" name="searchDB" [options]="searchDatabaseList" required
                                placeholder="Select Search Database" [(ngModel)]="searchDatabaseID" #searchDB="ngModel"
                                (onChange)="onSearchDatabaseChange($event)" [style]="{'margin-left':'6px !important'}" appendTo="body">
                            </p-dropdown>
                    
                        </div>
                    </div>
                   

                    <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>

                    <div class="jarviswidget-ctrls" role="menu" *ngIf="canEditContainerType"
                        title="Edit Search Synonyms (Ctrl+E)">
                        <a class="button-icon hand-cursor tablIndexFocus" [attr.tabindex]="0" id="btnedit"
                            (keyup.enter)="btnEditClick()" (click)="btnEditClick()">
                            <i class="glyphicon glyphicon-pencil"></i>
                        </a>
                    </div>
                    <div class="jarviswidget-ctrls" role="menu" *ngIf="canAddContainerType"
                        title="Add Search Synonyms (Ctrl+A)">
                        <a class="button-icon hand-cursor tablIndexFocus" [attr.tabindex]="1"
                            (keyup.enter)="btnAddClick()" id="btnaddsearchsynonyms" (click)="btnAddClick()">
                            <i class="glyphicon glyphicon-plus"></i>
                        </a>
                    </div>
                    <div class="jarviswidget-ctrls" role="menu" *ngIf="canAddContainerType"
                        title="Delete Search Synonyms (Ctrl+D)">
                        <a class="button-icon hand-cursor tablIndexFocus" [attr.tabindex]="2" (click)="confirmDelete()"
                            (keyup.enter)="confirmDelete()" id="btndeletesearchsynonyms">
                            <i class="glyphicon glyphicon-trash"></i>
                        </a>
                    </div>


                </header>


                <div class="widgetdiv">

                    <div class="jarviswidget-editbox">


                    </div>

                    <div id="containerTypelisting" class="no-bottom-padding widget-body">

                        <section id="widget-grid2" class="">

                            <div class="jqgridboxadminaircraft">
                                <!--style="height:80vh !important;"-->

                                <ag-grid-angular id="agGridSearchSynonyms" #agGridSearchSynonyms [navigateToNextCell]="navigateToNextCell"
                                    [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="gridData"
                                    sortable="true" filter=true style="width: 100%;"
                                    class="ag-theme-balham containergrid" (rowClicked)='updateSelectedRow($event)'
                                    (rowDoubleClicked)="containerRowDoubleClicked($event)">
                                    <!--width: 100%;-->
                                </ag-grid-angular>
                            </div>
                        </section>

                    </div>

                </div>

            </div>

        </article>

    </div>
</section>
<form #addeditcontainertypeform="ngForm">
    <p-dialog [(visible)]="display" showEffect="fade" [modal]="true" [style]="{width: '420px'}" [resizable]="false">
        <ng-template pTemplate="header"  (keyup.shift.tab)="btnCancelClick(addeditcontainertypeform)">
            {{dialogTitle}}
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Type<span [hidden]="addeditCategory?.valid|| (model.Type!=null && model.Type.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown [autofocus]="true" id="addeditCategory" name="addeditCategory"
                                [options]="categoryTypeList" required placeholder="Select Type" [(ngModel)]="model.Type"
                                #Focusdd #addeditCategory="ngModel" (onChange)="onTypeChange($event)" appendTo="body">

                            </p-dropdown>
                            <label style="font-size: x-small;">{{synonymTypeMessage}}</label>
                        </fieldset>
                    </div>


                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Keyword<span [hidden]="addeditKeyword?.valid" class="text-danger">
                                    *
                                </span>

                            </label>

                            <label class="input rgxErr">

                                <input id="addeditKeyword" type="text" name="addeditKeyword" placeholder="Keyword"
                                    required [(ngModel)]="model.Keyword" #addeditKeyword="ngModel" required>

                            </label>


                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Synonym (coma separated)<span [hidden]="addeditSynonym?.valid" class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <!-- <input id="addeditSynonym" type="text" name="addeditSynonym" placeholder="Synonym"
                                    required [(ngModel)]="model.Synonym" #addeditSynonym="ngModel"> -->

                                <textarea id="addeditSynonym" name="addeditSynonym" placeholder="Synonym" required
                                    [(ngModel)]="model.Synonym" #addeditSynonym="ngModel" class="smart-form"
                                    style="width:100%; height:100px; resize:none"></textarea>
                            </label>

                        </fieldset>
                    </div>
                </div>


            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex"
                    (click)="onSubmit(addeditcontainertypeform)" [label]="lblsubmitButton"
                    [disabled]="!addeditcontainertypeform.form.valid || isSubmitted"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnCancelClick(addeditcontainertypeform)" label="Cancel" [disabled]="isSubmitted"
                    (focusout)="Focusdd.applyFocus()"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>