<div  [ngStyle]="{'float':FloatDirection}" class="jarviswidget-ctrls" title="Edit Item (Ctrl+Shift+E)" *ngIf="showEditComm">    <!--  *ngIf="showEditComm" -->
    <a    class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="showEditDialog()" id="btnedititem"
        (click)="showEditDialog()">
        <i class="glyphicon glyphicon-pencil"></i>
    </a>
</div>


<form *ngIf="commdisplay" #editItemForm="ngForm">
    <p-dialog id="edititemdlg" *ngIf="commdisplay" [(visible)]="commdisplay" showEffect="fade" [modal]="true"
        [style]="{width: '80vw'}" [resizable]="false" (onShow)="adjustComDlgHeight($event)">
        <ng-template pTemplate="header"  (keyup.shift.tab)="onKey()">
            {{dialogTitle}}
        </ng-template>
        <div class="upload-dialog">
            <div id="commDlg" class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                Type<span
                                    [hidden]="editcommtype.valid || (model.CommTypeId!=null && model.CommTypeId >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" [autofocus]="true" [ngClass]="{'fix-height': onDashboad }" id="editcommtype"
                                name="editcommtype" [options]="commTypeList" required placeholder="Select Type"
                                [(ngModel)]="model.CommTypeId" #d_edit #editcommtype="ngModel">

                            </p-dropdown>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                Priority<span
                                    [hidden]="editcommpriority.valid || (model.Priority!=null && model.Priority >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" [ngClass]="{'fix-height': onDashboad }" id="editcommpriority"
                                name="editcommpriority" [options]="priorityList" required placeholder="Select Priority"
                                [(ngModel)]="model.Priority" #editcommpriority="ngModel">

                            </p-dropdown>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                Status<span
                                    [hidden]="editcommstatus.valid || (model.CommStatusId!=null && model.CommStatusId >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" [ngClass]="{'fix-height': onDashboad }" id="editcommstatus"
                                name="editcommstatus" [options]="commStatusList" [filter]="false"
                                placeholder="Select Status" required [(ngModel)]="model.CommStatusId"
                                #editcommstatus="ngModel">
                            </p-dropdown>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                Requested by
                            </label>

                            <p-dropdown appendTo="body" [ngClass]="{'fix-height': onDashboad }" id="editrequestedby"
                                name="editrequestedby" filter="true" #editreqby [resetFilterOnHide]="true"
                                placeholder="Select Requested By" [options]="requestedByList"
                                [(ngModel)]="model.RequestedBy" #editrequestedby="ngModel" [style]="{width:'341px'}">

                            </p-dropdown>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                Assigned To
                            </label>

                            <!--<p-dropdown appendTo="body" id="editcommassignedto" name="editcommassignedto" [options]="assignedToList" placeholder="Select Type" [(ngModel)]="model.AssignedTo" #editcommassignedto="ngModel">

                            </p-dropdown>-->
                            <p-dropdown appendTo="body" [ngClass]="{'fix-assignedto-height': onDashboad }" id="editcommassignedto"
                                name="editcommassignedto" filter="true" #editcomassto [resetFilterOnHide]="true"
                                [options]="assignedToList" placeholder="Select Type" [(ngModel)]="model.AssignedTo"
                                #editcommassignedto="ngModel">
                                <ng-template let-item pTemplate="selectedItem">
                                    <!--<div [ngClass]="getSelectedClass(item.value)"></div>
                                    <span>{{item.label}}</span>-->
                                    <div class="col-md-9" [ngClass]="getSelectedClass(item.value)">
                                        <span>{{item.label}}</span>
                                    </div>
                                    <div class="col-md-3"><span class="group-tags"
                                            [style.background-color]="getTagColor(item.value)">{{getUserDepartment(item.value)}}</span>
                                    </div>
                                </ng-template>
                                <ng-template let-users pTemplate="item">
                                    <div style="position: relative;height: 14px;">
                                        <!--<div [ngClass]="getOptionsClass(users.value)"></div>
                                        <span>{{users.label}}</span>-->
                                        <div class="col-md-9" [ngClass]="getSelectedClass(users.value)">
                                            <span>{{users.label}}</span>
                                        </div>
                                        <div class="col-md-3"><span class="group-tags"
                                                [style.background-color]="getTagColor(users.value)">{{getUserDepartment(users.value)}}</span>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <!--<p-dropdown appendTo="body" id="commassignedto" name="commassignedto" [options]="assignedToList" placeholder="Select Type" [(ngModel)]="model.AssignedTo" #commassignedto="ngModel">
                                <ng-template let-item pTemplate="selectedItem">
                                    <div class="col-md-9"><span>{{item.label}}</span></div>
                                    <div class="col-md-3"><span class="padding-2" style="font-size: small !important;" [style.background-color]="getTagColor(item.value)">{{getUserDepartment(item.value)}}</span></div>
                                </ng-template>
                                <ng-template let-users pTemplate="item">
                                    <div style="position: relative;height: 25px;">
                                        <div class="col-md-9"><span>{{users.label}}</span></div>
                                        <div class="col-md-3"><span class="padding-2" style="font-size: small !important;" [style.background-color]="getTagColor(users.value)">{{getUserDepartment(users.value)}}</span></div>
                                    </div>
                                </ng-template>
                            </p-dropdown>-->
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-4 job-dropdown">
                        <fieldset>
                            <label class="label">
                                Due Date ( {{ViewDateFormat}})
                            </label>
                            <!-- <label class="input">
                                <i class='icon-append fa fa-calendar padding-left-tablet'></i>

                                <p-calendar appendTo="body" placeholder="{{ViewDateFormat}}" [attr.tabindex]="-1" class='datepicker hasDatepicker' id='editduedate' name="editduedate" [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030" [(ngModel)]="model.DueDate" #editduedate="ngModel" [dateFormat]="dateFormatCalender" [style]="{width:'22.8vw'}">

                                </p-calendar>

                            </label> -->

                            <label class="input">

                                <span class="col-sm-11">
                                    <p-calendar appendTo="body" placeholder="{{ViewDateFormat}}" [attr.tabindex]="-1"
                                        class='datepicker hasDatepicker' id='editduedate' name="editduedate"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030"
                                        [(ngModel)]="model.DueDate" #editduedate="ngModel"
                                        (onClose)="onCloseDate($event)" [dateFormat]="dateFormatCalender"
                                        [style]="{width:'22.8vw'}">

                                    </p-calendar>
                                </span>
                                <span class="col-sm-1">
                                    <i class='icon-append fa fa-calendar padding-left-tablet'></i>
                                </span>



                            </label>


                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">CCs</label>
                            <!-- <p-multiSelect id="editemailccs" defaultLabel="Email Ccs" [options]="emailCcsList" [(ngModel)]="selectedCCs" [style]="{width:'100%',height:'33px'}" name="editemailccs" #editemailccs="ngModel"></p-multiSelect> -->
                            <div [attr.tabindex]="0" class="tablIndexFocus">
                                <!-- <angular2-multiselect [attr.tabindex]="-1" id="editemailccs" [data]="emailCcsList"
                                    [(ngModel)]="selectedCCsTemp" [settings]="dropdownSettings"
                                    (keyup.esc)="emailCCEscClick($event)" (onAddFilterNewItem)="onAddItem($event)"
                                    name="editemailccs" #editemailccs="ngModel">
                                </angular2-multiselect> -->
                                <p-multiSelect id="editemailccs" [options]="emailCcsList" placeholder="Select Users" [(ngModel)]="selectedCCsTemp" name="editemailccs" [maxSelectedLabels]="4" selectedItemsLabel="{0} items selected" appendTo="body"></p-multiSelect>

                            </div>
                        </fieldset>
                    </div>
                    <!--<div class="form-group col-sm-8">
                        <fieldset>
                            <label class="label">
                                CCs(Other User)
                            </label>
                            <p-chips id="otheremailcss" [(ngModel)]="model.CssOther" name="otheremailcss" #otheremailcss="ngModel" styleClass="chips-control" inputStyleClass="chips-control-input" addOnBlur="true" addOnTab="true" allowDuplicate="false"></p-chips>
                        </fieldset>
                    </div>-->
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Subject<span [hidden]="editsubject.valid || model.Subject" class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="editsubject" type="text" name="editsubject" placeholder="Subject"
                                    (keyup.enter)="subjectEnterClick($event)" [(ngModel)]="model.Subject" required
                                    #editsubject="ngModel">

                            </label>

                        </fieldset>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Description
                            </label>
                            <label class="textarea">
                                <p-editor name="description" [(ngModel)]="model.Description"
                                    [style]="{'height':'150px'}">
                                    <!--<ng-template pTemplate="header" >
                                            <span class="ql-formats">
                                                <button class="ql-bold" aria-label="Bold"></button>
                                                <button class="ql-italic" aria-label="Italic"></button>
                                                <button class="ql-underline" aria-label="Underline"></button>
                                            </span>
                                            <span class="ql-formats">
                                                <button class="ql-list" value="ordered" type="button"></button>
                                                <button class="ql-list" value="bullet" type="button"></button>
                                                <span class="ql-align ql-picker ql-icon-picker"></span>
                                            </span>
                                        </ng-template>-->
                                </p-editor>
                            </label>

                        </fieldset>

                    </div>

                </div>
                <!--<div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Conversation
                            </label>

                            <label class="textarea">

                                <textarea id="editconversation" name="editconversation" #editconversation="ngModel" [(ngModel)]="model.Conversation" class="custom-scrollNote no-overflow-x" rows="6"></textarea>

                            </label>

                        </fieldset>

                    </div>
                </div>-->
                <div class="row">
                    <fieldset>
                        <div class="jarviswidget" id="wid-id-1" data-widget-togglebutton="false"
                            data-widget-sortable="false" data-widget-fullscreenbutton="false"
                            data-widget-colorbutton="false" data-widget-editbutton="false"
                            data-widget-custombutton="false" data-widget-deletebutton="false" role="widget">

                            <header role="heading" style="margin-left: 0px;margin-right:0px;">
                                <span class="widget-icon"><i class="fa fa-comments"></i></span>
                                <h2>Conversation</h2>
                            </header>
                            <!-- widget div-->
                            <div class="widgetdiv no-padding" style="width: 100%;">
                                <!-- widget content -->
                                <div class="widget-body">
                                    <div>
                                        <div class="comments-list">
                                            <div class="media">
                                                <div class="media-body">
                                                    <textarea id="editconversation" placeholder="Write your comment..."
                                                        name="editconversation" #editconversation="ngModel"
                                                        [(ngModel)]="model.Conversation"
                                                        class="custom-scrollNote no-overflow-x padding-left inbox-textarea"
                                                        rows="2" style="width:100%"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="oldConversation!='' && oldConversation!=null">
                                            <div class="comments-list"
                                                *ngFor="let conv of allConversations.Conversation">
                                                <div class="media">
                                                    <div class="media-body">
                                                        <h4 class="media-heading user_name">{{conv.Name}} <span
                                                                style="font-weight:lighter;"><i> added a comment -
                                                                </i></span><small>{{conv.Date}}</small></h4>
                                                        <div [innerHTML]="conv.Comment | safehtml"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end widget content -->
                                </div>

                                <!-- end widget div -->
                            </div>
                            <!-- end widget -->
                        </div>
                    </fieldset>
                </div>
                <div class="row">
                    <div class="form-group"
                        [ngClass]="{'col-sm-6': attachedFiles!=null && attachedFiles.length>0,'col-sm-12': attachedFiles==null || attachedFiles.length==0}">
                        <fieldset>
                            <label class="label">Attachment</label>
                            <p-fileUpload name="editcommbulkupload" (onSelect)="onSelect($event)"
                                (onRemove)="onRemove($event)" multiple="multiple" customUpload="true"
                                uploadHandler="onBulkUpload($event,editItemForm)" chooseLabel="Browse"
                                [(ngModel)]="bulkFile" #editcommbulkupload ngDefaultControl>

                            </p-fileUpload>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6" *ngIf="attachedFiles!=null && attachedFiles.length>0">
                        <fieldset>
                            <label class="label"><br /></label>
                            <div style="max-height: 110px !important;overflow-y: auto !important;">
                                <table class="table table-hover">
                                    <tbody>
                                        <tr *ngFor="let file of attachedFiles">
                                            <td>{{file}}</td>
                                            <td><a (click)="downloadFile(file)"
                                                    class="glyphicon glyphicon-save"></a><span></span> </td>
                                            <td><a (click)="removeAttachedFile(file)"
                                                    class="fa fa-fw fa fa-close"></a><span></span> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">History</label>

                            <label class="textarea"><textarea id="edithistory" name="edithistory" #edithistory="ngModel"
                                    [(ngModel)]="model.History" class="custom-scrollNote readonlyinput2" rows="4"
                                    disabled></textarea></label>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">

            <div class="p-dialog-buttonpane">
                <p class="pull-left CreatedbyBottomText">Created by {{model.CreatedBy}} on {{CreatedOn}} </p>
                <button type="button" pButton icon="fa fa-file" class="btn btn-primary minzindex "
                    (click)="onViewItem(editItemForm)" label="View linked record" [disabled]="isSubmitted"
                    *ngIf="isViewable"></button>
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex"
                    (click)="onBulkUpload($event,editItemForm)" label="Update"
                    [disabled]="!editItemForm.form.valid || !canEditComm || isSubmitted"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnCancelClick(editItemForm)" label="Cancel" [disabled]="isSubmitted"
                    (focusout)="d_edit.applyFocus()"></button>
            </div>
        </ng-template>

    </p-dialog>

</form>