//#region References
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SharedService } from '../../../shared/services/shared.service';

//#endregion
var shared: SharedService;

@Injectable()
export class DashboardService {
  constructor(private http: HttpClient, private _sharedService: SharedService) {
    shared = _sharedService;
  }
  getRefreshData(): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/Dashboad/RefreshRecordData`, '', this._sharedService.getReqOptWithAccessToken());
  }
  getNextPreviousYearRecordData(year: string): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/Dashboad/NextPreviousYearRecordData?year=${year}`, '', this._sharedService.getReqOptWithAccessToken());
  }
  getCommData(): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/Dashboad/GetCommData`, null, this._sharedService.getReqOptWithAccessToken());
  }
  getContainerStatusData(selectedasn, viewType: string): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/Dashboad/GetContainerStatusData?asn=${selectedasn}&viewType=${viewType}`, null, this._sharedService.getReqOptWithAccessToken());
  }
  refreshContainerStatusData(selectedasn,viewType:string): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/Dashboad/RefreshGetContainerStatusData?asn=${selectedasn}&viewType=${viewType}`, null, this._sharedService.getReqOptWithAccessToken());
  }

  getRecordData(): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/Dashboad/GetRecordData`, '', this._sharedService.getReqOptWithAccessToken());
  }

  getAssetCountData(): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/Dashboad/GetAircraftCountData`, '', this._sharedService.getReqOptWithAccessToken());
  }

  getdashboardStatsData(): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/Dashboad/GetDashboardStats`, '', this._sharedService.getReqOptWithAccessToken());
  }
  getInboxData(): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/Dashboad/GetInboxData`, '', this._sharedService.getReqOptWithAccessToken());
  }
  getBinderData(): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/Dashboad/GetBinderData`, '', this._sharedService.getReqOptWithAccessToken());
  }
  refreshDashboardBinderData(): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/Dashboad/RefreshBinderData`, '', this._sharedService.getReqOptWithAccessToken());
  }
  refreshDashboardInboxData(): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/Dashboad/RefreshInboxData`, '', this._sharedService.getReqOptWithAccessToken());
  }
  refreshDashboardStatsData(): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/Dashboad/RefreshDashboardStatsData`, '', this._sharedService.getReqOptWithAccessToken());
  }
  getStatusParentData(viewType: string = ""): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/Dashboad/GetStatusParentData?viewType=${viewType}`, '', this._sharedService.getReqOptWithAccessToken());
  }

  refreshStatusData(asn: string, viewType: string): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/Dashboad/RefreshStatusData?asn=${asn}&viewType=${viewType}`, '', this._sharedService.getReqOptWithAccessToken());
  }

  getStatusChildData(dfpStatus: string): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/Dashboad/GetStatusChildData?dfpStatus=${dfpStatus}`, '', this._sharedService.getReqOptWithAccessToken());
  }

  getAllDFPStatuses(): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/Dashboad/GetAllDFPStatuses`, '', this._sharedService.getReqOptWithAccessToken());
  }

  setUserHomeDashboardSettings(apiUrl: string, body: any): Observable<any> {

    let dashboardSettings = { DashboardSettingsData: body };
    return this.http.post(`${apiUrl}/api/Account/UpdateUserHomeDashboardSettings`, dashboardSettings, this._sharedService.getReqOptWithAccessToken());

  }

  getASNTasksCount(): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/Task/GetASNTasksCount`, '', this._sharedService.getReqOptWithAccessToken());
  }

  filterAndAggregateDFPStatusData(dfpStatusResult: any) {
    let data = [{}];// = [];
    data = [];
    dfpStatusResult.reduce(function (res, value) {
      value.DFPStatus = value.DFPStatus.trim();
      if (!res[value.DFPStatus]) {
        res[value.DFPStatus] = {}
        for (var x in value) {
          if (x != 'ASN') {
            res[value.DFPStatus][x] = value[x];
          }
        }
        data.push(res[value.DFPStatus]);
      }
      else {
        for (var y in value) {
          if (y != 'DFPStatus' && y != 'ASN') {
            res[value.DFPStatus][y] += value[y];
          }
        }
      }
      return res;
    }, {});

    return data;

  }

  filterAndAggregateDFPStatusDataOfASN(dfpStatusResult: any) {
    let data = [{}];// = [];
    data = [];
    dfpStatusResult.reduce(function (res, value) {
      value.DFPStatus = value.DFPStatus.trim();
      if (!res[value.DFPStatus]) {
        res[value.DFPStatus] = {}
        let self = this;
        for (var x in value) {
          // if()
          let as = shared.selectedASNViews.find(c => c == x);
          if (x != 'ASN' && as) {
            res[value.DFPStatus][x] = value[x];
          }
        }
        data.push(res[value.DFPStatus]);
      }
      else {
        for (var y in value) {
          if (y != 'DFPStatus' && y != 'ASN') {
            res[value.DFPStatus][y] += value[y];
          }
        }
      }
      return res;
    }, {});

    return data;

  }

  filterAndAggregateAllDFPStatusData(dfpStatusResult: any) {
    let data = [{}];// = [];
    data = [];
    dfpStatusResult.reduce(function (res, value) {
      // if (value.DFPStatus == undefined) {
      //   value.DFPStatus = '';
      // }
      value.DFPStatus = value.DFPStatus.trim();
      let statusViewAggregate = 0;
      if (!res[value.DFPStatus]) {
        res[value.DFPStatus] = {}
        for (var x in value) {
          if (x != 'DFPStatus' && x != 'ASN') {
            statusViewAggregate += value[x];
            //res[value.DFPStatus][x] = value[x];
          }
        }
        res[value.DFPStatus].key = value.DFPStatus;
        res[value.DFPStatus].value = statusViewAggregate;
        data.push(res[value.DFPStatus]);
      }
      else {
        for (var y in value) {
          if (y != 'DFPStatus' && y != 'ASN') {
            res[value.DFPStatus].value += value[y];
          }
        }
      }

      return res;
    }, {});

    return data;

  }

  filterAndAggregateAllViewWithDFPStatusDataInPercentage(dataResult: any) {

    let finalData = [];
    let data = [];
    let viewList = [];

    for (var view in dataResult[0]) {
      if (view != 'DFPStatus' && view != 'ASN') {
        viewList.push(view);
      }
    }

    for (var view in viewList) {
      let _viewName = viewList[view]
      let noOfRecords = dataResult.map(p => p[_viewName]).reduce((sum, current) => sum + current, 0);

      let DFPWithViewValue = dataResult.map(item => {
        let dataItem = {};

        //if (noOfRecords > 0) {
        dataItem = {
          DFPStatus: item.DFPStatus,
          view: _viewName,
          value: item[_viewName],
          percentValue: (noOfRecords > 0) ? parseFloat(((item[_viewName] / noOfRecords) * 100).toFixed(2)) : 0
        };
        data.push(dataItem);
        //}
        return dataItem;
      });

      let finalDataItem = {};
      finalDataItem = {
        view: _viewName,
        data: DFPWithViewValue
      }
      finalData.push(finalDataItem);
    }

    ///now restructuring
    var _restructuredData = this.filterAndAggregateDFPStatusData_Restructuring(data);

    // return data;
    //return finalData;
    return _restructuredData;
  }
  filterAndAggregateInboxDataInPercentage(dataResult: any) {
    let finalData = [];
    let data = [];
    let statusList = [];

    for (var view in dataResult[0]) {
      if (view != 'Type') {
        statusList.push(view);
      }
    }
    dataResult.forEach(dataRes => {
      let noOfRecords=0
      statusList.forEach(element => {
        if(dataRes[element])
        noOfRecords =noOfRecords + dataRes[element];
      });
      statusList.forEach(element => {
        if(dataRes[element])
       dataResult.filter(x=>x.Type==dataRes.Type)[0][element] = parseFloat(((dataRes[element]/noOfRecords) * 100).toFixed(2))
      //  dataResult.filter(x=>x.Type==dataRes.Type)[0][element]=((dataRes[element]/noOfRecords)*100).toFixed(2)
      });
    });
    return dataResult;
  }

  filterAndAggregateDFPStatusData_Restructuring(dfpStatusResult: any) {
    let data = [{}];// = [];
    data = [];
    dfpStatusResult.reduce(function (res, value) {
      value.DFPStatus = value.DFPStatus.trim();
      if (!res[value.DFPStatus]) {
        res[value.DFPStatus] = {};
        res[value.DFPStatus]['DFPStatus'] = value.DFPStatus;
        res[value.DFPStatus][value.view] = value.percentValue;
        data.push(res[value.DFPStatus]);
      }
      else {
        res[value.DFPStatus][value.view] = value.percentValue;
      }
      return res;
    }, {});

    return data;

  }
}