
<article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable no-padding standardpadding" style="margin-left:0px" id="col1">

    <div class="jarviswidget" id="wid-id-1" data-widget-togglebutton="false" data-widget-sortable="false" data-widget-fullscreenbutton="false" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" role="widget">
 
        <header role="heading">
            <span class="widget-icon"><i [className]="listIcon"></i></span>
            <h2>Feeds <span *ngIf="totalCount">({{totalCount}})</span></h2>

            <div class="jarviswidget-ctrls" title="Refresh">
                <a  [attr.tabindex]="0"  class="button-icon tablIndexFocus" id="btnRefresh" (click)="refresh()" (keyup.enter)="refresh()" >
                    <i class="glyphicon glyphicon-refresh"  [ngClass]="{'fa-spin': updatingRecord}"></i>
                </a>
            </div>
            <div class="jarviswidget-ctrls" title="Help">
                <app-component-articles [componentName]="componentName">
                </app-component-articles>
            </div>
            <div class="jarviswidget-ctrls" title="Run Feed (Ctrl+Shift+U)" *ngIf="recordType == 'Import' && shoePlayButton==true">
                <a  [attr.tabindex]="0"  class="button-icon tablIndexFocus" id="btnrunFeed" (keyup.enter)="runFeed()" (click)="runFeed()">
                    <i class="glyphicon glyphicon-play"></i>
                </a>
            </div>
            <div class="jarviswidget-ctrls" title="Run Feed (Ctrl+Shift+U)" *ngIf="recordType!='Import' && shoePlayButton==true ">
                <a  [attr.tabindex]="0" class="button-icon tablIndexFocus" id="btnrunOutgoingFeed"  (keyup.enter)="runOutgoingFeed()" (click)="runOutgoingFeed()">
                    <i class="glyphicon glyphicon-play"></i>
                </a>
            </div>
            <div class="jarviswidget-ctrls" *ngIf="((isLogVisibleIncoming && recordType=='Import' && feedType!='Import')||(feedType && feedType=='Import' && isLogVisibleIncomingStatus))" title="Feed Log (Ctrl+Shift+L)">
                <a  [attr.tabindex]="0" class="button-icon tablIndexFocus" id="btnfeedlog" (keyup.enter)="openFeedLog()" (click)="openFeedLog()">
                    <i class="glyphicon glyphicon-file"></i>
                </a>
            </div>
            <div class="jarviswidget-ctrls" *ngIf="isLogVisibleOutgoing && recordType=='Export'" title="Feed Log (Ctrl+Shift+L)">
                <a [attr.tabindex]="0" class="button-icon tablIndexFocus" id="btnfeedlogoutgoing" (keyup.enter)="openFeedLog()" (click)="openFeedLog()">
                    <i class="glyphicon glyphicon-file"></i>
                </a>
            </div>
            <div class="jarviswidget-ctrls" *ngIf="((isMappingVisibleIncoming && recordType=='Import' && feedType!='Import')||(feedType && feedType=='Import' && isMappingVisibleIncomingStatus))" title="Mapping (Ctrl+M)">
                <a [attr.tabindex]="0" class="button-icon tablIndexFocus" id="btnfieldmap"  (keyup.enter)="openFieldMapping()"  (click)="openFieldMapping()">
                    <i class="glyphicon glyphicon-list-alt"></i>
                </a>
            </div>
            <div class="jarviswidget-ctrls" *ngIf="isMappingVisibleOutgoing && recordType=='Export'" title="Configuration (Ctrl+C)">
                <a [attr.tabindex]="0"  class="button-icon tablIndexFocus" id="btnfieldmap"  (keyup.enter)="openConfiguration()" (click)="openConfiguration()">
                    <i class="glyphicon glyphicon-list-alt"></i>
                </a>
            </div>
            <div class="jarviswidget-ctrls hand-cursor" title="Export Feed (Ctrl+X)">
                <a class="button-icon  tablIndexFocus"  [attr.tabindex]="0" id="Downloadviewdata" (click)="ExportFeed()">
                    <i class="glyphicon glyphicon-download"></i>
                </a>
            </div>
            <!-- for upload files -->
            <dataimporter *ngIf="((recordType=='Import' && isAddVisibleIncoming && feedType!='Import')||(recordType=='Export' && isAddVisibleOutgoing) ||(feedType && feedType=='Import' && isAddVisibleIncomingStatus))"></dataimporter>
            <edit-datafeed [recordType]="recordType" [feedId]="feedId" [viewId]="viewId" *ngIf="((recordType=='Import' && isEditVisibleIncoming && feedType!='Import')||(recordType=='Export' && isEditVisibleOutgoing) ||(feedType && feedType=='Import' && isEditVisibleIncomingStatus))"></edit-datafeed>
            <add-datafeed [recordType]="recordType" [viewId]="viewId" *ngIf="((recordType=='Import' && isAddVisibleIncoming && feedType!='Import')||(recordType=='Export' && isAddVisibleOutgoing) ||(feedType && feedType=='Import' && isAddVisibleIncomingStatus))"></add-datafeed>
            <upload-feed *ngIf="recordType=='Import' || recordType=='Export'" [feedTempleInterval]="feedTempleInterval" [recordType]="recordType" [feedId]="feedId"></upload-feed>
        </header>

        <!-- widget div-->
        <div class="widgetdiv no-padding standardpadding">
            <!--set-screen-width-->

            <div class="widget-body set-widget-body-width">
                <div>
                    <fieldset>
                        <div class="form-group">
                            <div id="s1">

                                <div class="jqgridboxcontainer" *ngIf="this.gridData.length > 0">
                                    <ag-grid-angular id="agGridFeedData" [navigateToNextCell]="navigateToNextCell" #agGridFeedData [gridOptions]="gridOptions" [columnDefs]="columnDefs" (rowClicked)='clickedFeedRow($event)' (rowDoubleClicked)="datafeedRowDoubleClicked($event)"  [rowData]="gridData" [getRowId]="getRowNodeId"  sortable=true filter=true style="width: 100% !important;" class="ag-theme-balham api-inc-out-grid">
                                    </ag-grid-angular>
                                </div>
                                <div id="nofeedlist" class="alert alert-block alert-warning" *ngIf="this.gridData.length == 0">

                                    <h4 class="alert-heading">Warning!</h4>
                                    No Results Found!
                                </div>
                            </div>

                        </div>
                    </fieldset>
                </div>

            </div>
            <!-- end widget content -->
        </div>

        <!-- end widget div -->
    </div>
    <!-- end widget -->
</article>
<p-dialog contentStyleClass="no-overflow-y" [(visible)]="displayMapping" *ngIf="displayMapping" showEffect="fade" [modal]="true" [style]="{width: '100vw'}" showHeader="true" [resizable]="false"  [closeOnEscape]="false">
        <ng-template pTemplate="header" >
                Mapping
        </ng-template>   
    <field-mapping *ngIf="displayMapping" [inputFeedId]="feedId" [inputScreen]="recordType"></field-mapping>
</p-dialog>

<p-dialog contentStyleClass="no-overflow-y" [(visible)]="displayFeedLog" *ngIf="displayFeedLog" showEffect="fade" [modal]="true" [style]="{width: '100vw'}"  showHeader="true" [resizable]="false"  [closeOnEscape]="false">
        <ng-template pTemplate="header" >
                Feed Log
        </ng-template>   
    <feed-log *ngIf="displayFeedLog"  [inputFeedId]="feedId" [inputScreen]="recordType"></feed-log>
</p-dialog>
<p-dialog contentStyleClass="no-overflow-y" id="recordExportDialogue" [(visible)]="displayRecordsExport" *ngIf="displayRecordsExport" showEffect="fade" [modal]="true" [style]="{width: '100vw'}"  showHeader="true" [resizable]="false"  [closeOnEscape]="false" (onHide)="onHideRecordsConfigDialogue($event)">
        <ng-template pTemplate="header" >
                Configuration
        </ng-template>   
        <record-configuration *ngIf="displayRecordsExport" [feedTypeInput]="recordType" [inputFeedId]="feedId" ></record-configuration> 
</p-dialog>
<p-dialog contentStyleClass="no-overflow-y" id="statusExportDialogue" [(visible)]="displayStatusExport" *ngIf="displayStatusExport" showEffect="fade" [modal]="true" [style]="{width: '100vw'}"  showHeader="true" [resizable]="false"  [closeOnEscape]="false" (onHide)="onHideStatusConfigDialogue()">
        <ng-template pTemplate="header" >
                Configuration
        </ng-template>   
        <status-configuration *ngIf="displayStatusExport" [feedTypeInput]="recordType" [templateMapping]="feedTempleMapping" [inputFeedId]="feedId" ></status-configuration> 
</p-dialog>
<p-dialog contentStyleClass="no-overflow-y" id="statusExportDialogue" [(visible)]="showContainerExport" *ngIf="showContainerExport" showEffect="fade" [modal]="true" [style]="{width: '100vw'}"  showHeader="true" [resizable]="false"  [closeOnEscape]="false" (onHide)="hideExportContainerConfiguration()">
        <ng-template pTemplate="header" >
                Configuration
        </ng-template>   
        <export-container-configuration [feedId]="feedId" [isTempFeed]="false"
                                        [selectedFeedConfiguration]="selectedFeedName"
                                        (btnCancelEvent)="hideExportContainerConfiguration()"></export-container-configuration>
</p-dialog>
