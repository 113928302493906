<p-dialog *ngIf="displayHistoryDlg" [(visible)]="displayHistoryDlg" showEffect="fade" [modal]="true"
    [style]="{width: '800px'}" [resizable]="false">
    <ng-template pTemplate="header" >
        History
    </ng-template>
    <div class="upload-dialog">
        <div id="commAddDlg" class="smart-form" style="min-height:5vh;overflow-y:auto;">
            <div class="row">
                <div class="comments" *ngFor="let item of auditHistoryList">
                    <div class="media">
                        <div class="media-body">
                            <h4 *ngIf="item.Type != 'L' && item.Type != 'E' && item.Type != 'A'"
                                class="media-heading user_name">{{item.UserName}} <span style="font-weight:lighter;"><i>
                                        {{(item.Type == "I")? "created":((item.Type == "U")? "modified": "deleted") }}
                                        following record at </i></span><small>{{ getDateTime(item.UpdateDate)}}</small>
                            </h4>
                            <h4 *ngIf="item.Type == 'L'" class="media-heading user_name">{{item.UserName}} <span
                                    style="font-weight:lighter;"><i> loged-in/loged-out at </i></span><small>{{
                                    getDateTime(item.UpdateDate)}}</small></h4>

                            <h4 *ngIf="item.Type == 'E' || item.Type == 'A'" class="media-heading user_name">
                                {{item.UserName}} <span style="font-weight:lighter;"><i> {{(item.Type == "E")?
                                        "logged-in " :((item.Type == "A")? "logged-out ": "loged-in/loged-out ") }}
                                        at </i></span><small>{{ getDateTime(item.UpdateDate)}}</small></h4>

                            <!-- <h4 *ngIf="item.Type == 'E'" class="media-heading user_name">{{item.UserName}} <span style="font-weight:lighter;"><i>  logged-in at  </i></span><small>{{ getDateTime(item.UpdateDate)}}</small></h4>
                            <h4 *ngIf="item.Type == 'A'" class="media-heading user_name">{{item.UserName}} <span style="font-weight:lighter;"><i>  logged-out at  </i></span><small>{{ getDateTime(item.UpdateDate)}}</small></h4> -->
                            <ul class="chat-file"
                                style="border-radius: 4px;background: rgba(0,0,0,.03);margin:10px;min-height: 45px;word-break: break-word;">
                                <li *ngFor="let field of item.Fields"
                                    style="margin-bottom: 1px;padding-left: 5px;padding-top: 5px;"><i
                                        class="fa fa-check-circle-o fa-lg"
                                        style="margin: 5px;"></i><strong>{{field.FieldName}} </strong>
                                    {{field.NewValue}} <del
                                        *ngIf="item.Type != 'I' && item.Type != 'D'">{{field.OldValue== null? '':
                                        field.OldValue}}</del> </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">
            <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                (click)="btnCancelClick()" label="Close"></button>
            <!--<button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex" (click)="onBulkUpload($event,addItemForm)" label="Add" [disabled]="!addItemForm.form.valid || isSubmitted"></button>-->
        </div>
    </ng-template>

</p-dialog>