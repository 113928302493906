//Components
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';

import { GridOptions, GridApi, ExcelRow } from 'ag-grid-community';
import { ReportService } from '../../services/report.service';
import * as moment from 'moment/moment';
import { IConfig } from '../../../shared/helpers/config';
import { CustomDateComponent } from '../../../shared/components/custom-date/custom-date.component';
import { SelectItem } from 'primeng/api';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
var dateTimeConfig: IDateFormatInfo;

var dateFormatF: string;
var dateSeparatorS: string;
var dayD: number;
var monthM: number;
var yearY: number;
var assetReportTimeout: any;
@Component({
    selector: 'asset-report',
    templateUrl: './asset.component.html',
    providers: [ReportService]
})

export class AssetReportComponent implements OnInit, OnDestroy {

    model: AssetReportFilterEntity;
    display: boolean = false;
    dialogTitle: string;
    isSubmitted: boolean = false;
    //totalCount: string;
    public dateConfig: IConfig[] = [];
    gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[] = [];
    public canAddEditAirCraft: boolean = false;
    public addAircraftPath: string = "/admin/addAsset";
    public assetPath = "/admin/asset";
    private api: GridApi;
    recordLastUpdated: string = '';
    ASNTitle: string = "";
    ASNTitleAirframe: string = "Asset Serial Number";
    ASNTitleCollection: string = "Number";

    ManufacturerTitle: string = "";
    ManufacturerTitleAirframe: string = "Manufacturer";
    ManufacturerTitleCollection: string = "Attribute";
    updatingRecord: boolean = false;
    excelStyles: any
    public processState: string = '';
    //@ViewChild('assetsEditdlg') addEditdlg: ElementRef;

    tenantList: SelectItem[] = [];
    public ViewDateFormat: string;
    public dateFormatCalender: string;

    constructor(private sharedService: SharedService, private router: Router, private reportService: ReportService) {
        if (!this.sharedService.isAuthUser()) {
            this.router.navigate(['login']);
            this.sharedService.SessionExpiredMessage();
        }
        dateTimeConfig = this.sharedService.getDateTimeFormatConfiguration();
    }

    ngOnInit() {
        if (!this.dateConfig || this.dateConfig.length == 0) {
            this.sharedService.getConfigurationFromDB(this.sharedService.baseUrl, null)
            .subscribe({
                next: (response) => {
                    this.sharedService.setConfiguration(response as IConfig[]);
                    this.dateConfig = response;
                    this.getDateFormatInfo();
                    this.bindGrid();
                    this.getAssetReport(this.model);
                },
                error: (error) => {
                    console.log(error);
                }
            });
        }
        else {
            this.getDateFormatInfo();
            this.bindGrid();
            this.getAssetReport(this.model);
        }
        this.setExcelStyle();
        this.initializeEmptyModel();
    }
    ngOnDestroy() {
        if (assetReportTimeout) {
            clearTimeout(assetReportTimeout);
        }
    }
    initializeEmptyModel() {
        this.model = new AssetReportFilterEntity();
    }
    getDateFormatInfo(): any {

        if (this.dateConfig != undefined) {
            dateFormatF = this.dateConfig.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
            dateSeparatorS = this.dateConfig.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
            dayD = Number(this.dateConfig.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
            monthM = Number(this.dateConfig.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
            yearY = Number(this.dateConfig.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
        }
        else {
            dateFormatF = localStorage.getItem('DateFormat');
            dateSeparatorS = localStorage.getItem('DateSeparater');
            dayD = Number(localStorage.getItem('DayIndex'));
            monthM = Number(localStorage.getItem('MonthIndex'));
            yearY = Number(localStorage.getItem('YearIndex'));

        }
        let dateParts: string[] = dateTimeConfig.dateformat.split(dateTimeConfig.dateSeparator);

        if (dateTimeConfig.year == 0) {
            this.dateFormatCalender = (dateParts[dateTimeConfig.year].substr(0, 2) + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day]).toLowerCase();
        }
        else if (dateTimeConfig.year == 2) {
            if (dateTimeConfig.month == 1) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
            else if (dateTimeConfig.month == 0) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
        }
        else {
            this.dateFormatCalender = "yy-mm-dd"
        }
    }


    bindGrid() {
        this.gridOptions = <GridOptions>
            {
                rowSelection: 'single',
                onGridReady: (params) => {
                    this.setAircraftGridColumns();
                    if (this.gridOptions.api != undefined) {
                        if (this.gridData.length > 0) {
                            //this.gridOptions.api.paginationSetPageSize(this._sharedService.getReportGridPageSize('agGridlistJobsReport'));
                            this.gridOptions.api.hideOverlay();
                        }
                        if (this.gridOptions.api != undefined) {
                            if (this.gridData.length > 0) {
                                this.gridOptions.api.hideOverlay();
                                try {
                                    this.gridOptions.api.paginationSetPageSize(this.sharedService.getGridPageSize('agGridAircraft'));
                                    this.gridOptions.api.hideOverlay();
                                } catch (e) {
                                    console.log(e);
                                }
                            }
                        }
                    }
                    if (params.api) {
                        this.api = params.api;

                    }
                },
                onModelUpdated: (event: any) => {
                    if (this.gridOptions.api) {
                        // this.totalCount = this.gridOptions.api.getDisplayedRowCount();
                        this.gridOptions.api.sizeColumnsToFit();
                    }

                },
                //suppressContextMenu: true,
                pagination: true,
                paginationPageSize: 19,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                },
                components: { agDateInput: CustomDateComponent },
                rowGroupPanelShow: 'always',
                suppressMakeColumnVisibleAfterUnGroup: true,
                suppressDragLeaveHidesColumns: true

            }
        this.gridOptions.getRowStyle = function (params) {
            if (params.data.Action == 'Plan downgraded') {
                return { background: '#ffc6aa' }
            }
            else if (params.data.Action == 'Plan upgraded') {
                return { background: '#aaffaa' }
            }
        }
        this.ViewDateFormat = this.sharedService.getLabelTextDateFormat();
    }


    bindGrid1() {
        this.gridOptions = <GridOptions>{
            rowSelection: 'single',
            // suppressContextMenu: true,
            onGridReady: () => {
                this.setAircraftGridColumns();
                if (this.gridOptions.api != undefined) {
                    // this.gridOptions.api.setColumnDefs(this.columnDefs);
                    // this.gridOptions.api.sizeColumnsToFit();
                    this.api = this.gridOptions.api;
                    if (this.gridOptions.api != undefined) {
                        if (this.gridData.length > 0) {
                            this.gridOptions.api.hideOverlay();
                            try {
                                this.gridOptions.api.paginationSetPageSize(this.sharedService.getGridPageSize('agGridAircraft'));
                                this.gridOptions.api.hideOverlay();
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }
                }


            },
            onModelUpdated: (event: any) => {

                if (this.gridOptions.rowData != null && this.gridOptions.rowData != undefined && this.gridOptions.rowData.length > 0) {
                    if (this.api != undefined) {
                        this.api.sizeColumnsToFit();

                    }
                }
            },
            defaultColDef: {
                sortable: true,
                resizable: true,
            },
            components: { agDateInput: CustomDateComponent },
            pagination: true,
            paginationPageSize: 19,
            rowGroupPanelShow: 'always',
            suppressMakeColumnVisibleAfterUnGroup: true,
            suppressDragLeaveHidesColumns: true
        }

        this.gridOptions.getRowStyle = function (params) {
            if (params.data.Action == 'Plan downgraded') {
                return { background: '#ffc6aa' }
            }
            else if (params.data.Action == 'Plan upgraded') {
                return { background: '#aaffaa' }
            }
        }
        this.ViewDateFormat = this.sharedService.getLabelTextDateFormat();

        //this.gridOptions.api.redrawRows({ rowNodes: uniqueRows });

        this.getAssetReport(this.model);
    }
    refresh() {

        this.getAssetReport(this.model);
    }
    runJob() {
        if (!this.sharedService.isAuthUser()) {
            this.router.navigate(['login']);
            this.sharedService.SessionExpiredMessage();
        }
        else {
            let requestBody: any = {
                TenantId: 0,
                TableId: 1
            };
            this.reportService.runCopyDataUtility(requestBody)
            .subscribe({
                next: (response) => {
                    let body = response;
                    this.sharedService.clearToast();
                    if (body.indexOf("Success") != -1) {
                        this.sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Request processed successfuly.' });
        
                        this.getAssetReport(this.model);
                    }
                    else {
                        this.sharedService.showToast({ severity: 'error', summary: 'Request failed', detail: 'Request to run finance report is un-successful.' });
                    }
        
                    //this._sharedService.clearToast();
                    //this._sharedService.showToast({ severity: 'success', summary: 'Feed started', detail: 'Request to run feed is successful.' });
                },
                error: (error) => {
                    if (error.status === 409) {
                        this.sharedService.clearToast();
                        this.sharedService.showToast({ severity: 'warn', summary: 'Warning', detail: 'Already in progress.' });
                    }
                    else {
                        this.sharedService.clearToast();
                        this.sharedService.showToast({ severity: 'error', summary: 'Request failed', detail: 'Request to run finance report is un-successful.' });
                    }
                }
            });
        }

    }
    getDateFormatTime(date: any) {
        if (date) {
            return moment.utc(date).format("DD-MM-YYYY h:mm a").toString() + ' (GMT)';
        } else {
            return '';
        }
    }
    getAssetReport(assetFilterModel: any) {
        this.updatingRecord = true;
        this.reportService.getAssetReport(assetFilterModel)
        .subscribe({
            next: (response) => {
                this.updatingRecord = false;
                this.gridData = [];
                this.gridData = response[0];
                this.lastRun(response[1]);
                this.processState = response[2];
                this.prepareTenantList(this.gridData);
        
                assetReportTimeout = setTimeout(() => {
                    if (this.processState == 'In progress') {
                        this.getAssetReport(assetFilterModel);
                    }
                }, 7000);
            },
            error: (error) => {
                this.updatingRecord = false;
                console.log(error);
            }
        });
    }
    prepareTenantList(gridData: any[]) {

        //if (this.tenantList == null || this.tenantList.length == 0) {
        var allTenants = this.gridData.map(val => ({
            label: val.TenantName,
            value: val.TenantId
        }));

        const result = [];
        const map = new Map();
        for (const item of allTenants) {
            if (!map.has(item.value)) {
                map.set(item.value, true);    // set any value to Map
                result.push({
                    value: item.value,
                    label: item.label
                });
            }
        }
        //const uniqueVals = [...new Set( array.map(obj => obj.value)) ];
        this.tenantList == null;
        this.tenantList = result;
        //}
    }
    lastRun(lastRunValue: string) {
        this.recordLastUpdated = lastRunValue;
    }
    setAircraftGridColumns(): void {

        this.columnDefs = [];
        this.columnDefs =
            [
                { headerName: 'ASN', field: 'ASN', filter: 'text', menuTabs: ['filterMenuTab'], enableRowGroup: true, suppressSizeToFit: false },
                {
                    headerName: 'Plan Changed Date', field: 'ModifiedOn', suppressSizeToFit: false, filter: 'agDateColumnFilter', filterParams: {
                        comparator: this.dateComparater,
                        inRangeInclusive: 'true'

                    }, valueFormatter: function (params: any) {
                        if (params.value != "" && params.value != null) {
                            let dateString = params.value.substring(0, 10);
                            return moment(dateString).format(dateFormatF.toUpperCase());
                        }
                    }, menuTabs: ['filterMenuTab'], minWidth: 100, enableRowGroup: true,
                },
                {
                    headerName: 'Action', field: 'Action', suppressSizeToFit: false, filter: 'text', menuTabs: ['filterMenuTab'], enableRowGroup: true, valueFormatter: function (params: any) {
                        if (params.value == null || params.value == '') {
                            return "Current";
                        }
                        else {
                            return params.value;
                        }
                    }
                },
                { headerName: 'Plan', field: 'PlanName', suppressSizeToFit: false, filter: 'text', menuTabs: ['filterMenuTab'], enableRowGroup: true },
                { headerName: 'Title', field: 'Description', filter: 'text', menuTabs: ['filterMenuTab'], enableRowGroup: true, suppressSizeToFit: false },
                { headerName: 'Registration', field: 'RegNumber', filter: 'text', menuTabs: ['filterMenuTab'], enableRowGroup: true, suppressSizeToFit: false },
                { headerName: 'Manufacturer/Group Level 1', field: 'Manufacturer', filter: 'text', menuTabs: ['filterMenuTab'], enableRowGroup: true, suppressSizeToFit: false },
                { headerName: 'Type/Group Level 2', field: 'Type', filter: 'text', menuTabs: ['filterMenuTab'], enableRowGroup: true, suppressSizeToFit: false },
                { headerName: 'Variant/Group Level 3', field: 'Varient', filter: 'text', menuTabs: ['filterMenuTab'], enableRowGroup: true, suppressSizeToFit: false },
                { headerName: 'Number/Serial', field: 'ASN', filter: 'text', menuTabs: ['filterMenuTab'], enableRowGroup: true, suppressSizeToFit: false },
                { headerName: 'Owner', field: 'Owner', filter: 'text', menuTabs: ['filterMenuTab'], enableRowGroup: true, suppressSizeToFit: false },
                { headerName: 'Registration Authority', field: 'RegAuthority', filter: 'text', menuTabs: ['filterMenuTab'], enableRowGroup: true, suppressSizeToFit: false },
                { headerName: 'Order', field: 'Order', filter: 'text', menuTabs: ['filterMenuTab'], enableRowGroup: true, suppressSizeToFit: false },

                { headerName: 'Tenant', field: 'TenantName', suppressSizeToFit: false, filter: 'text', menuTabs: ['filterMenuTab'], enableRowGroup: true },
                {
                    headerName: 'Status', field: 'Status', suppressSizeToFit: false, filter: 'text', suppressMenu: true, enableRowGroup: true, valueFormatter: function (params: any) {
                        if (params.value == true) {
                            return "Active";
                        }
                        else {
                            return "Inactive";
                        }
                    }
                }


            ];
    }
    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateFormatF.toUpperCase()).split(dateSeparatorS);
            var day = Number(dateParts[Number(dayD)].substring(0, 2));
            var month = Number(dateParts[Number(monthM)]) - 1;
            var year = Number(dateParts[Number(yearY)]);
            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        }
        else {
            return -1;
        }
    }
    onFinanceGridExport() {
        let headerString = null
        var params: any = {
            olumnBestFit: true,
            skipColumnGroupHeaders: false,
            allColumns: false,
            onlySelected: false,
            fileName: 'Finance-Report-' + moment(Date.now()).format('YYYYMMDD') + '.xlsx',
            sheetName: 'Report',
            processCellCallback: function (params: any) {
                if (params.column.colDef.field == 'Status') {
                    if (params.value == true) {
                        return 'Active'
                    }
                    else {
                        return 'Inactive'
                    }
                }
                else if (params.column.colDef.field == 'CreatedOn' || params.column.colDef.field == 'StatusOffDate') {
                    return params.value ? params.value.substring(0, 10) : params.value;
                }
                else {
                    return params.value;
                }
            },
        };
        const getRows: () => ExcelRow[] = () => JSON.parse(headerString);
        params.prependContent = getRows();
        var api = this.api;
        api.exportDataAsExcel(
            params
        );
    }
    setExcelStyle() {
        this.excelStyles = [
            {
                id: "header",
                interior: {
                    color: "#CCCCCC",
                    pattern: "Solid"
                }
            },
            {
                id: "twoDecimalPlaces",
                numberFormat: { format: "#,##0.00" }
            },
            {
                id: "textFormat",
                dataType: "string"
            },
        ];
    }
    getDate(date: Date): string {
        if (date != null) {
            let newDate = moment(date).format("YYYY-MM-DD");
            return new Date(newDate).toDateString();
        }
        return null;
    }
    SearchAssets() {
        this.getAssetReport(this.model);
    }
    //#endregion
    //#region Helper Methods

    //#endregion
}

export class AssetReportFilterEntity {

    public Tenant: string;
    public FromDate: Date;
    public ToDate: Date;
}






