<article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable no-padding standardpadding" style="margin-left:0px" id="col1">
    <div class="jarviswidget" id="wid-id-1" data-widget-togglebutton="false" data-widget-sortable="false" data-widget-fullscreenbutton="false" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" role="widget">

        <header role="heading">
            <span class="widget-icon"><i class="fa fa-filter"></i></span>
            <h2>Jobs <span *ngIf="totalCount">({{totalCount}})</span></h2>   
            <!-- <div class="jarviswidget-ctrls" title="Refresh">
                <a class="button-icon" id="btnRefresh" (click)="refresh()">
                    <i class="glyphicon glyphicon-refresh"></i>
                </a>
            </div> -->
            <div class="jarviswidget-ctrls pdfoperations" role="menu" >
                <div class="btn-group">
                    <a class="button-icon" (click)="onJobsGridExport()" [attr.tabindex]="0"  data-toggle="dropdown" aria-expanded="false" title="Download">
                        <i class="glyphicon glyphicon-save"></i>
                    </a>
                </div>
            </div>

            <div class="widget-toolbar hidden-mobile" style="cursor:auto;" id="btnRefresh"  title="Refresh">
                    <span (click)="refresh()" class="onoffswitch-title" style="display: inline !important"><i class="glyphicon glyphicon-refresh" [ngClass]="{'fa-spin': updatingRecord}"  style="margin-left: 5px;cursor:pointer;"></i></span>                           
            </div>
            <div class="jarviswidget-ctrls" title="Run Job Report">
                <a class="button-icon" id="btnrunFeed" (click)="runJob()">
                    <i class="glyphicon glyphicon-play"></i>
                </a>
            </div>   
            <h2 style="float:right;" *ngIf="recordLastUpdated!='' && processState!='In progress'"> Last updated {{getDateFormatTime(recordLastUpdated)}} </h2>
            <h2 style="float:right;margin-right: 5px;" *ngIf="processState=='In progress'"> <span class="padding-5 label" style= "background-color:#2196F3;font-size: small;">{{processState}}</span></h2>    
        </header>

        <!-- widget div-->
        <div class="widgetdiv no-padding standardpadding" role="content"> <!--set-screen-width-->
          
            <div class="widget-body set-widget-body-width">
                <div>
                    <fieldset>
                        <div class="form-group">
                            <div id="s1">
                                
                                <div id="agGridlistJobsDiv" class="jqgridboxcontainer" *ngIf="this.gridData.length > 0">
                                    <ag-grid-angular id="agGridlistJobsReport"  #agGridlistJobsReport [gridOptions]="gridOptions"  [columnDefs]="columnDefs" [rowData]="gridData" sortable="true" [excelStyles]="excelStyles" filter=true style="width: 100% !important;" class="ag-theme-balham  set-grid-width jobsgrid">
                                    </ag-grid-angular>
                                </div>
                                <div id="nojoblist" class="alert alert-block alert-warning" *ngIf="this.gridData.length == 0">

                                    <h4 class="alert-heading">Warning!</h4>
                                    No Results Found!
                                </div>
                            </div>

                        </div>
                    </fieldset>
                </div>

            </div>
            <!-- end widget content -->
        </div>
      
        <!-- end widget div -->
    </div>
    <!-- end widget -->
</article>