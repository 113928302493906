import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-download-report',
  changeDetection:ChangeDetectionStrategy.OnPush,
  templateUrl: './download-report.component.html',
})
export class DownloadReportComponent implements OnInit { paramsGlobal: any;
  index: number = 0;
  isDisplayButton: boolean = false;
  constructor(private cRef:ChangeDetectorRef) {

  }
  agInit(params:any)
  {
      this.paramsGlobal = params;
      this.index = params.data.FeedName;
      // if (params.data && params.data.FeedStatus != 'My Report' && params.data.FeedStatus == 'Success') {
      // if (params.data && params.data.FeedStatus == 'Success' && params.data.RowsPopulated > 0 && params.data.TargetType !="ROAM Container") {
      if (params.data && params.data.FeedStatus == 'Success' && params.data.TargetType !="ROAM Container") {
          this.isDisplayButton = false;
      }
      else {
          this.isDisplayButton = true;
      }
  }
  DownloadReport()
  {
      this.paramsGlobal.context.gridContext.DownloadReport(this.paramsGlobal.data)
      this.cRef.markForCheck();
  }
  ngOnInit() {
  }

}
