import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Ag-Grid enterprise License
import { LicenseManager } from "ag-grid-enterprise";
//LicenseManager.setLicenseKey("Gamit_Ltd._ROAM_1Devs_1_Deployment_License_8_April_2020_MTU4NjMwNDAwMDAwMA==4116e71621fea9baf2714de6c1097792");
//LicenseManager.setLicenseKey("CompanyName=Gamit Ltd.,LicensedApplication=ROAM,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-010372,ExpiryDate=11_September_2021_[v2]_MTYzMTMxNDgwMDAwMA==f2afbe55224632bb6907c561619bd1ac");
// LicenseManager.setLicenseKey("CompanyName=GAMIT LIMITED,LicensedApplication=ROAM,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-017789,ExpiryDate=11_September_2022_[v2]_MTY2Mjg1MDgwMDAwMA==c6ae155d0335f9aa9ea9a860ce20104c");
LicenseManager.setLicenseKey("CompanyName=GAMIT LIMITED,LicensedApplication=ROAM,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-030822,SupportServicesEnd=11_September_2023_[v2]_MTY5NDM4NjgwMDAwMA==b8f20283cadbe2c6f527d4678290a784");

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
