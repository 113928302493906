<section id="widget-grid" class="adminaircraftcls" *ngIf="canAddEditContainerQualityStatus">
    <div class="row">
        <article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable" id="col1">
            <div class="no-margin jarviswidget jarviswidget-sortable" id="wid-id-2" data-widget-colorbutton="false"
                data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false"
                data-widget-togglebutton="false" role="widget">
                <header role="heading" class="p-sortable-handle">
                    <span class="widget-icon"><i class="glyphicon glyphicon-check"></i></span>
                    <h2>Container Quality Status</h2>
                    <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>

                    <div class="jarviswidget-ctrls" role="menu" *ngIf="canAddEditContainerQualityStatus"
                        title="Edit Container Quality Status (Ctrl+E)">
                        <a class="button-icon hand-cursor tablIndexFocus" [attr.tabindex]="0"
                            (keyup.enter)="btnEditClick()" id="btneditDFPStatus" (click)="btnEditClick()">
                            <i class="glyphicon glyphicon-pencil"></i>
                        </a>
                    </div>
                    <div class="jarviswidget-ctrls" role="menu" *ngIf="canAddEditContainerQualityStatus"
                        title="Add Container Quality Status (Ctrl+A)">
                        <a class="button-icon hand-cursor tablIndexFocus" [attr.tabindex]="0"
                            (keyup.enter)="btnAddClick()" id="btnaddDFPStatus" (click)="btnAddClick()">
                            <i class="glyphicon glyphicon-plus"></i>
                        </a>
                    </div>
                </header>

                <div class="widgetdiv">
                    <div class="jarviswidget-editbox"></div>

                    <div id="tagslisting" class="no-bottom-padding widget-body">
                        <section id="widget-grid2" class="">
                            <div class="jqgridboxadminaircraft">
                                <!--style="height:80vh !important;"-->

                                <ag-grid-angular id="agGridDFPStatus" #agGridDFPStatus [gridOptions]="gridOptions"
                                    [columnDefs]="columnDefs" [rowData]="gridData" sortable="true" filter="true"
                                    style="width: 100%;" class="ag-theme-balham dfpstatusgrid"
                                    (rowClicked)="updateSelectedRow($event)" [navigateToNextCell]="navigateToNextCell"
                                    (rowDoubleClicked)="containerRowDoubleClicked($event)">
                                    <!--width: 100%;-->
                                </ag-grid-angular>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>
<form #addeditdfpstatusform="ngForm">
    <p-dialog id="containerqualitystatusdlg" [(visible)]="display" showEffect="fade" [modal]="true" [style]="{ width: '420px' }" [resizable]="false">
        <ng-template pTemplate="header" (keyup.shift.tab)="btnCancelClick(addeditdfpstatusform)">
            {{ dialogTitle }}
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Quality Status<span [hidden]="addeditDFPStatus?.valid || model.Name"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="addeditDFPStatus" type="text" name="addeditDFPStatus"
                                    placeholder="Quality Status" required [(ngModel)]="model.Name"
                                    #addeditDFPStatus="ngModel" [disabled]="readonlyMode"
                                    [ngClass]="{ readonlyinput2: readonlyMode }" />
                            </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Description
                            </label>

                            <label class="input">
                                <input id="addeditDFPStatusDescription" type="text" name="addeditDFPStatusDescription"
                                    placeholder="Description" [(ngModel)]="model.Description"
                                    #addeditDFPStatusDescription="ngModel" />
                            </label>
                        </fieldset>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Reset Change
                            </label>

                            <p-dropdown  [autofocus]="true" id="addeditResetChange" name="addeditResetChange" [options]="containerQualityDDList" placeholder="Select Reset Change" [(ngModel)]="model.ResetChangeID" #Focusdd  #addeditResetChange="ngModel" appendTo="body">

                            </p-dropdown>

                        </fieldset>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Color Code<span [hidden]="
                    (addeditDFPStatusColorCode?.valid &&
                      addeditDFPStatusColorCodeEditor?.valid) ||
                    model.CSSClass
                  " class="text-danger">
                                    *
                                </span>
                            </label>
                            <p-colorPicker appendTo="body" id="addeditDFPStatusColorCode" name="addeditDFPStatusColorCode"
                                [(ngModel)]="model.CSSClass" #addeditDFPStatusColorCode="ngModel"></p-colorPicker>
                            <input id="addeditDFPStatusColorCodeEditor" type="text" class="color-picker-input"
                                name="addeditDFPStatusColorCodeEditor" required [(ngModel)]="model.CSSClass"
                                #addeditDFPStatusColorCodeEditor="ngModel" style="width: 30%;" />
                        </fieldset>
                    </div>

                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">Status</label>
                            <label class="checkbox">
                                <span class="onoffswitch tablIndexFocus onoffswitch-Width" [attr.tabindex]="0">
                                    <input type="checkbox" name="addeditdfpstatuscolorcodestatus"
                                        class="onoffswitch-checkbox" checked="checked"
                                        id="addeditdfpstatuscolorcodestatus" [(ngModel)]="model.Status"
                                        #addeditdfpstatuscolorcodestatus="ngModel" [disabled]="readonlyMode" />
                                    <label class="onoffswitch-label" for="addeditdfpstatuscolorcodestatus">
                                        <span class="onoffswitch-inner" data-swchon-text="Active"
                                            data-swchoff-text="Inactive"></span><span class="onoffswitch-switch onoffswitch-switch-Right"></span>
                                    </label>
                                </span>
                            </label>
                        </fieldset>
                    </div>

                    
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex"
                    (click)="onSubmit(addeditdfpstatusform)" [label]="lblsubmitButton"
                    [disabled]="!addeditdfpstatusform.form.valid || isSubmitted"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnCancelClick(addeditdfpstatusform)" label="Cancel" [disabled]="isSubmitted"></button>
            </div>
        </ng-template>
    </p-dialog>
</form>