import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from '../../../shared/services/shared.service';
import * as moment from 'moment/moment';
import { Settings, settingsEntity, IsettingsUpdateEntity, settingsUpdateEntity } from '../../components/settings/settings'
@Injectable()
export class SettingsService {
    //#region Global Variables


    //#endregion


    constructor(private _sharedService: SharedService, private http: HttpClient) {
    }
    //MM-dd-yyyy||-||1||0||2
    //#region service methods
    getDateFormates() {

        let dateFormatList = [
            { value: 'dd-MM-yyyy||-||0||1||2', label: '(DD-MM-YYYY) ' + moment(Date.now()).format('DD-MM-YYYY') },
            { value: 'MM-dd-yyyy||-||1||0||2', label: '(MM-DD-YYYY) ' + moment(Date.now()).format('MM-DD-YYYY') },
            { value: 'yyyy-MM-dd||-||2||1||0', label: '(YYYY-MM-DD) ' + moment(Date.now()).format('YYYY-MM-DD') }
            
            // { value: 'dd-MM-yyyy||-||0||1||2', label: '(DD-MM-YYYY) 28-06-2018' },
            // { value: 'MM-dd-yyyy||-||1||0||2', label: '(MM-DD-YYYY) 06-28-2018' },
            // { value: 'yyyy-MM-dd||-||2||1||0', label: '(YYYY-MM-DD) 2018-06-28' },
        ];
        return dateFormatList;
    }
    getTimeFormates() {
        let timeFormatList = [
            { value: 'HH:mm:ss', label: 'HH:MM:SS' }
        ];
        return timeFormatList;
    }

    getInterval() {
        let intervalList = [
            { value: 'EveryTime', label: 'Every Time' },
            { value: 'Daily', label: 'Daily' },
            { value: 'Weekly', label: 'Weekly' },
            { value: 'Monthly', label: 'Monthly' },
            { value: 'None', label: 'None' }
        ];
        return intervalList;
    }
    getSettings(settingType: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AppConfig/GetSettings?settingtype=${settingType}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    setSettings(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AppConfig/SetSettings`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getConfigurationValueByKey(key: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AppConfig/GetConfigurationValueByKey?key=${key}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    getAllSettings(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AppConfig/GetAllSettings`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getConfigById(id: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AppConfig/GetConfigByID?id=${id}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    updateConfigByID(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AppConfig/UpdateConfigByID`, body, this._sharedService.getReqOptWithAccessToken());
    }
    //#endregion


}