import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
    template: '<h2>{{this.showMessage}}</h2>'
})
export class PageNotFoundComponent implements OnInit {
showMessage:string
    constructor(private _router: Router) {
       

    }
    ngOnInit(): void {
        if(this._router.url.indexOf("/.") != -1)
        {
            this.showMessage=""
        }
        else{
            this.showMessage="Page not found"
        }
    }
 }