//#region References

//Components
import { Component, OnInit, Input} from '@angular/core';
import { Router } from '@angular/router';

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { UserService } from '../../../records/services/User/user.service';
import { TemplateType } from '../user-management/user-management';

//#endregion

//#region TypeDecorator
@Component({
    selector: 'download-template',
    templateUrl: './download-template.component.html',
    providers: [UserService]
})
//#endregion

export class DownloadTemplateComponent implements OnInit {

    //#region Locals Declaration

    display: boolean = false;

    @Input() templateType: number=0;
    
    //#endregion

    //#region Initialization of Component
    constructor(private _sharedService: SharedService, private _router: Router, private _userService: UserService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
    }

    ngOnInit() {     

    }
    //#endregion

    //#region Form Events

    downloadTemplateFile() {
        if (this.templateType && this.templateType!=0) 
        {                   
            this._sharedService.showLoader("Processing...");
            const templateType: number = this.templateType;
            this._userService.downloadTemplateFile(templateType)
            .subscribe({
                next: (response) => {
                    this._sharedService.hideLoader();                   
                    let blob = new Blob([response], { type: 'application/zip' });
                    let url = URL.createObjectURL(blob);
                    this.formatZipFileName(url);                    
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    console.log(error);                    
                    this._sharedService.clearToast();
                    this._sharedService.showToast(
                        { 
                            severity: 'error',
                            summary: 'Error.',
                            detail: 'File not exists.' 
                        }
                    );                    
                }
            });
        }
    }
    //#endregion

    //#region Helper Methods

    formatZipFileName(fileURL: string) {
        let fileName:string;
        switch (this.templateType) {
            case TemplateType.User:
                fileName="User-Template";
                break;
            case TemplateType.Aircraft:
                fileName="Aircraft-Template";
                break;
            default:
                break;
        }
        fileName = fileName.replace(/[.]/g, "");
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = fileURL;
        a.target = '_blank';
        a.download = fileName;
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
    }


    //#endregion

}
