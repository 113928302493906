export class EditCommModel {

    constructor(
        public CommId?: string,

        public ASN?: string,

        public Module?: string,

        public Section?: string,
        public Record?: string,
        public CommStatusId?: string,

        public CommTypeId?: string,

        public Priority?: number,

        public Subject?: string,
        public Description?: string,
        public Attachment?: boolean,
        public AssignedTo?: string,
        public RequestedBy?: string,
        public DueDate?: Date,
        public CCs?: string,
        public Conversation?: string,
        public History?: string,

        public Status?: boolean,

        public CreatedBy?: string,

        public CreatedOn?: Date,

        public ModifiedBy?: string,

        public ModifiedOn?: Date,

        public CssOther: string[] = [],

        public AssignedToName?: string,
        public RequestedByName?: string,

        public CommStatusDescription?: string,
        public CommTypeDescription?: string,

        public PriorityDescription?: string


    ) { }

}

export class Conversation {
    constructor(Name: string, Date: string, Comment: string) {
        this.Name = Name;
        this.Date = Date;
        this.Comment = Comment;
    }
    public Name: string;
    public Date: string;
    public Comment: string;

}

export class ConversationList {

    public Conversation: Conversation[];
}
