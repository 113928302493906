import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UserService } from '../../../records/services/User/user.service';
import { SharedService } from '../../../shared/services/shared.service';
import { SelectItem } from 'primeng/api';
import { ContainerService } from '../../../records/services/container/container.service';
import { IConfig } from '../../helpers/config';
import { ChannelService, ConnectionState, ChannelEvent } from '../../../shared/services/signalR/channel.Service';
@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    providers: [
        HttpClientModule, UserService, ContainerService
    ]
})
export class LoginComponent implements OnInit {
    private title = 'Login';

    public login_Wheel = ''
    @ViewChild('usercode') Usercode: any;
    public incorrectpswd: boolean;
    errorMessage:string = 'The server could not sign you in. Make sure your URL, user name and password/Code are correct, and then try again.';
    defaultMessage:string = 'The server could not sign you in. Make sure your URL, user name and password/Code are correct, and then try again.';
    errorMsgClass:string = 'alert-warning';
    clients: SelectItem[];
    selectedClient: string;
    isInternalUser: boolean = false;
    isEnable2FactorAuth: boolean = false;
    loginResponse: any;
    isForgetPswd: boolean = false;
    emailForget: string = null;

    isSubmitted: boolean = false;
    isIpChanged: boolean = false;
    isNormalLogin: boolean = false;
    appVersion: any;
    
    constructor(private channelService: ChannelService, private _router: Router, private _http: HttpClient, private _userService: UserService, public _sharedService: SharedService, private _containerService: ContainerService) {
        this.clients = [];
        this.selectedClient = "";
        this._sharedService.resetSharedData();
        if (!this._sharedService.isAuthUser()) {
            this._sharedService.showLoader('Loading...');
            this._userService.getRoamVersion("RoamVersion")
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.appVersion = response;
                    } else {
                        this.appVersion="V 0.0.0"
                    }
                },
                error: (error) => {
                    console.log(error);
                }
            });
            if (!this._sharedService.isDefaultLogin) {
                this._userService.getTenantBySubDomain(this._sharedService.getSubdomain())
                .subscribe({
                    next: (response) => {
                        if (response) {
                            let authConfig = JSON.parse(response.value);
                            var customConfig=authConfig.CustomAttribute;
                            
                            this._sharedService.configureSSOAuth(authConfig);

                        } else {
                            this.isNormalLogin = true;
                            this._sharedService.hideLoader();
                            this._router.navigate(['login']);
                        }
                    },
                    error: (error) => {
                        console.log(error);
                        this.isNormalLogin = true;
                        this._sharedService.hideLoader();
                        this._router.navigate(['login']);
                    }
                });
                // this._userService.getRoamVersion("RoamVersion")
                // .subscribe({
                //     next: (response) => {
                //         if (response) {
                //             this.appVersion = response;
                //         } else {
                //             this.appVersion="V 0.0.0"
                //         }
                //     },
                //     error: (error) => {
                //         console.log(error);
                //     }
                // });
            }
            else {
                this._userService.isValidTenantBySubDomain(this._sharedService.getSubdomain())
                .subscribe({
                    next: (response) => {
                        if (response) {
                            this.isNormalLogin = true;
                            this._sharedService.hideLoader();
                        } else {
                            this._sharedService.showLoader('invalid tenant', 'fa-exclamation-triangle');
                        }
                    },
                    error: (error) => {
                        console.log(error);
                        this._sharedService.showLoader('invalid tenant', 'fa-exclamation-triangle');
                    }
                })
            }

            try {


                // Let's wire up to the signalr observables
                //
                this.channelService.connectionState$
                .subscribe({
                    next: (state:ConnectionState) => {
                        return ConnectionState[state];
                    },
                    error: (error) => {
                        console.log(error)
                    }
                });

                this.channelService.error$
                .subscribe({
                    next: (response) => {
                        console.warn(response); 
                    },
                    error: (error) => {
                        console.error("errors$ error", error);
                    }
                });


                // Wire up a handler for the starting$ observable to log the
                //  success/fail result
                //
                // this.channelService.starting$.subscribe( 
                //     () => { console.log("signalr service has been started"); },
                //     () => { console.warn("signalr service failed to start!"); }
                // );
            }
            catch (ex) {

                console.log(ex.Message);

            }

        }

    }
    onSubmit(loginForm: NgForm) {

        this._sharedService.runningViewTasks = [];
        this.incorrectpswd = false;
        SharedService.current_ParentMenu_Id = -1;
        this.login_Wheel = 'fa fa-spinner fa-spin';
        //event.preventDefault();
        var email = loginForm.value.email;
        var password = loginForm.value.password;
        let body = this._sharedService.getLoginBody(email, password,false);

        let tokenObject = this._userService.loginAndGetToken(this._sharedService.baseUrl, body)
        .subscribe({
            next: (response) => {
                this.loginResponse = response;
                this.checkUserIP();
                localStorage.setItem('access_token', this.loginResponse['access_token']);
                // localStorage.setItem('expires', loginResponse['.expires']);
                // localStorage.setItem('token_type', loginResponse['token_type']);
                // localStorage.setItem('userName', loginResponse['userName']);
                // localStorage.setItem('firstName', loginResponse['firstName']);
                // localStorage.setItem('lastName', loginResponse['lastName']);
                // localStorage.setItem('roles', loginResponse['roles']);
                // localStorage.setItem('userType', loginResponse['userType']);
        
                // //Update Roles Array
                // this._sharedService.updateRole();
        
                // Validate External/ Internal User
                // if (this.loginResponse['userType'] == 'External') {
                this._userService.getTenantInfoExternal(this._sharedService.baseUrl, null)
                .subscribe({
                    next: (response) => {
                        this._sharedService.getConfigurationFromDB(this._sharedService.baseUrl, null)
                        .subscribe({
                            next: (response) => {
                                this._sharedService.setConfiguration(response as IConfig[]);
                                this._sharedService.getDateTimeFormatConfiguration();
                
                                if (this.isNeed2FactAuth()) {
                                    this.isEnable2FactorAuth = true;
                                } else {
                                    this.setLocalStroageKeys();
                                    //R.A. this.getContainerTypes();
                                    //this._router.navigate(['record']);
                                    this.navigateToHome();
                                }
                            },
                            error: (error) => {
                                this.login_Wheel = '';
                                this.incorrectpswd = true;
                                this.errorMessage = error.error.error_description? error.error.error_description : this.defaultMessage;
                                if(error.error.error == "expiredUser_grant"){
                                    this.errorMsgClass = 'alert-danger';
                                }else{
                                    this.errorMsgClass = 'alert-warning';
                                }
                                this._router.navigate(['login']);
                            }
                        });
                    },
                    error: (error) => {
                        if (error.status == "409") {
                            this.login_Wheel = '';
                            this.incorrectpswd = false;
                            this._router.navigate(['login']);
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'error', summary: 'Unable to login', detail: 'Please contact administrator to resolve login issue.' });
                        }
                        else {
                            this.login_Wheel = '';
                            this.incorrectpswd = true;
                            this.errorMessage = error.error.error_description? error.error.error_description : this.defaultMessage;;
                            if(error.error.error == "expiredUser_grant"){
                                this.errorMsgClass = 'alert-danger';
                            }else{
                                this.errorMsgClass = 'alert-warning';
                            }
                            this._router.navigate(['login']);
                        }
                    }
                })
        
        
                // }
                // else {
                //     event.stopPropagation();
        
                //     this.isInternalUser = true;
                //     this._userService.getTenantInfoInternal(this._sharedService.baseUrl, null).subscribe( response => {
                //         let res = response;
                //         this.clients = res;
                //     },
                //         error => {
                //             this.isInternalUser = false;
                //             this.login_Wheel = '';
                //             this.incorrectpswd = true;
                //             this._router.navigate(['login']);
                //         });
        
                // }
        
                this.login_Wheel = '';
            },
            error: (error) => {
                this.isInternalUser = false;
                this.login_Wheel = '';
                this.incorrectpswd = true;
                if(error.error.error == "expiredUser_grant"){
                    this.errorMsgClass = 'alert-danger';
                }else{
                    this.errorMsgClass = 'alert-warning';
                }
                this.errorMessage = error.error.error_description? error.error.error_description : this.defaultMessage;;
                if(error.error.error == "expiredUser_grant"){
                    this.errorMsgClass = 'alert-danger';
                }else{
                    this.errorMsgClass = 'alert-warning';
                }
                this._router.navigate(['login']);
            }
        });
    }

    isNeed2FactAuth() {
        if(this.loginResponse['isMust2FA'] === true)
        {
            return true;
        }

        if (this.loginResponse['userType'] == 'External') {
            if (this._sharedService.isTwoFactAuthEnabled() == '1') {
                return true;
            } else if (this._sharedService.isTwoFactAuthEnabled() == '2' && this.isIpChanged) {
                return true;
            }
        } else {
            if (this.isIpChanged) {
                return true;
            }
        }
        return false;
    }

    validate2FactorAuth() {
        this._userService.verifyCode(this.Usercode.nativeElement.value)
        .subscribe({
            next: (response) => {
                if (response == true) {
        
                    if (this.loginResponse['userType'] == 'External') {
                        this.setLocalStroageKeys();
                        this.channelService.startHubConnection();
                        //R.A. this.getContainerTypes();
                        //this._router.navigate(['record']);
                        this.navigateToHome();
                    } else {
        
                        this.setLocalStroageKeys();
                        if (this._sharedService.getSubdomain().toLowerCase() == this._sharedService.Tenant_Help) {//(loggedInTenant == "9999") {
                            this._router.navigate(['/help/category']);
                        }
                        else if (this._sharedService.getSubdomain().toLowerCase() == this._sharedService.Tenant_Internal) {//if (loggedInTenant && loggedInTenant == "0") {
                            this._sharedService.getConfigurationFromDB(this._sharedService.baseUrl, null)
                            .subscribe({
                                next: (response) => {
                                    this._sharedService.setConfiguration(response as IConfig[]);
                                    // Start the connection up!
                                    //
                                    console.log("Starting the channel service");
                                    this.channelService.startHubConnection();
                                },
                                error: (error) => {
                                    this.login_Wheel = '';
                                    this.incorrectpswd = true;
                                    this._router.navigate(['login']);
                                }
                            });
                            this._router.navigate(['reports']);
                        }
                        else //if (loggedInTenant != "0")
                        {
                            //R.A. this.getContainerTypes();
                            this._sharedService.getConfigurationFromDB(this._sharedService.baseUrl, null)
                            .subscribe({
                                next: (response) => {
                                    this._sharedService.setConfiguration(response as IConfig[]);
                                    // Start the connection up!
                                    //
                                    console.log("Starting the channel service");
                                    this.channelService.startHubConnection();
                                },
                                error: (error) => {
                                    this.login_Wheel = '';
                                    this.incorrectpswd = true;
                                    this._router.navigate(['login']);
                                }
                            });
        
                            //this._router.navigate(['record']);
                            this.navigateToHome();
                        }
        
                    }
        
                } else if (response == false) {
                    this.login_Wheel = '';
                    this.incorrectpswd = true;
                    this._router.navigate(['login']);
                }
            },
            error: (error) => {
                this.login_Wheel = '';
                this.incorrectpswd = true;
                this.errorMsgClass = 'alert-danger';
                this.errorMessage=error.error
                this._router.navigate(['login']);
            }
        });

    }

    setLocalStroageKeys() {
        var homeDashboardSettings = null;
        homeDashboardSettings = (this.loginResponse['homeDashboardSettings'] ==null || this.loginResponse['homeDashboardSettings'] == "" || this.loginResponse['homeDashboardSettings'] == "null") ? this._sharedService._configuration.find((x=>x.Key=="Home_Dashboard_Settings")).Value:this.loginResponse['homeDashboardSettings'];
        //localStorage.setItem('access_token', this.loginResponse['access_token']);
        localStorage.setItem('expires', this.loginResponse['expires']);
        localStorage.setItem('token_type', this.loginResponse['token_type']);
        localStorage.setItem('userName', this.loginResponse['userName']);
        localStorage.setItem('firstName', this.loginResponse['firstName']);
        localStorage.setItem('lastName', this.loginResponse['lastName']);
        localStorage.setItem('roles', this.loginResponse['roles']);
        localStorage.setItem('userType', this.loginResponse['userType']);
        // localStorage.setItem('HomeDashboardSettings', this.loginResponse['homeDashboardSettings']);
        localStorage.setItem('HomeDashboardSettings', homeDashboardSettings);

        //Update Roles Array
        this._sharedService.updateRole();
        this.channelService.createConnection();
        this.channelService.startHubConnection();
    }

    actionProceedClick() {

        let body = { TenantID: this.selectedClient };
        if (body.TenantID != "") {
            this._userService.saveTenantInfoInternal(this._sharedService.baseUrl, body)
            .subscribe({
                next: (response) => {
                    if (this.isNeed2FactAuth()) {
                        this.isEnable2FactorAuth = true;
                        this._sharedService.setLoggedInTenantId(body.TenantID);
                    } else {
        
                        this._sharedService.setLoggedInTenantId(body.TenantID);
                        this.setLocalStroageKeys();
        
                        if (body.TenantID == '9999') {
                            this._router.navigate(['/help/category']);
                        }
                        else if (body.TenantID != "0") {
                            //R.A. this.getContainerTypes();
                            this._sharedService.getConfigurationFromDB(this._sharedService.baseUrl, null)
                            .subscribe({
                                next: (response) => {
                                    this._sharedService.setConfiguration(response as IConfig[]);
                                    // Start the connection up!
                                    //
                                    console.log("Starting the channel service");
                                    this.channelService.startHubConnection();
                                },
                                error: (error) => {
                                    this.login_Wheel = '';
                                    this.incorrectpswd = true;
                                    this._router.navigate(['login']);
                                }
                            });
        
                            //this._router.navigate(['record']);
                            this.navigateToHome();
                        }
        
                        else {
        
                            this._sharedService.getConfigurationFromDB(this._sharedService.baseUrl, null)
                            .subscribe({
                                next: (response) => {
                                    this._sharedService.setConfiguration(response as IConfig[]);
                                    // Start the connection up!
                                    //
                                    console.log("Starting the channel service");
                                    this.channelService.startHubConnection();
                                },
                                error: (error) => {
                                    this.login_Wheel = '';
                                    this.incorrectpswd = true;
                                    this._router.navigate(['login']);
                                }
                            })
        
                            this._router.navigate(['reports']);
                        }
                    }
                },
                error: (error) => {
                    this.isInternalUser = false;
                    this.login_Wheel = '';
                    this.incorrectpswd = true;
                    this._router.navigate(['login']);
                }
            });
        }
    }


    ngOnInit() {
        this.addClassOnBody();
        //if (!this._sharedService.isAuthUser()) {
        //    this._router.navigate(['login']);
        //}
        //else {
        localStorage.clear();
        this.isForgetPswd = false;
        this.isSubmitted = false;
        // }
    }
    rememberPasswordClick() {
        this.isForgetPswd = false;
        this.emailForget = null;
        this.isSubmitted = false;
    }
    forgetPasswordClick() {
        this.isForgetPswd = true;
        this.emailForget = null;
        this.isSubmitted = false;
    }
    onForgetPswd(forgetForm: NgForm) {
        this.isSubmitted = true;
        this.login_Wheel = 'fa fa-spinner fa-spin';
        let body =
        {
            Email: this.emailForget

        }
        this._userService.forgetPassword(this._sharedService.baseUrl, body)
        .subscribe({
            next: (response) => {
                this.isSubmitted = false;
                this.login_Wheel = '';
                this.isForgetPswd = false;
                this.emailForget = null;
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'success', summary: 'Password has been changed', detail: 'An email has been sent with login details.' });
            },
            error: (error) => {
                this.isSubmitted = false;
                this.login_Wheel = '';
                this.isForgetPswd = false;
                this.emailForget = null;
        
                if (error.status == "404") {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'User does not exist', detail: 'User does not exist.' });
                }
                else if (error.status == "409") {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'Unable to change the password', detail: 'Please try again later.' });
                }
                else {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Please try again later.' });
                }
            }
        });
    }

    navigateToHome() {
        if (this._sharedService.isViewDocOnly) {
            this._router.navigate(['Document']);
            // if (this._sharedService.UserRole.Records_SingleDocument_View) {
            //     this._router.navigate(['Document']);
            // } else {
            //     this._sharedService.showToast({ severity: 'error',life:8000, summary: 'Unauthorized!', detail: 'You are not authorized to Single Document View module. Please contact your ROAM administrator.' });
            //     this._router.navigate(['record']);
            // }
        } else {
            this._router.navigate(['dashboard','record']);
        }
    }

    checkUserIP() {
        this._userService.getLoggedInUserIP()
        .subscribe({
            next: (response) => {
                console.log('Current IP: ' + response);
                let currentIP = response;
                if (currentIP != this.loginResponse['LastIP']) {
                    this.isIpChanged = true;
                } else {
                    this.isIpChanged = false;
                }
            },
            error: (error) => {
                console.log(error)
            }
        });

       
        // if (this.isPrivateIP(this.loginResponse['LastIP'])) {
        //     if (this.loginResponse['CurrentIP'] != this.loginResponse['LastIP']) {
        //         this.isIpChanged = true;
        //     } else {
        //         this.isIpChanged = false;
        //     }
        // } else {

        //     this._userService.getUserIP().subscribe( data => {
        //         if (data['ip'] != this.loginResponse['LastIP']) {
        //             this.isIpChanged = true;
        //         } else {
        //             this.isIpChanged = false;
        //         }
        //     },
        //         error => {
        //             console.log(error);
        //         }
        //     );
        // }
    }

    isPrivateIP(ip:any) {
        var parts = ip.split('.');
        return parts[0] === '10' || 
           (parts[0] === '172' && (parseInt(parts[1], 10) >= 16 && parseInt(parts[1], 10) <= 31)) || 
           (parts[0] === '192' && parts[1] === '168');
     }

    sendUserSharedKey() {
        this._userService.sendSharedKey()
        .subscribe({
            next: (response) => {
                this.isSubmitted = false;
                this.login_Wheel = '';
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'success', summary: 'Registration key Sent', detail: 'An email has been sent with Registration key details.' });
            },
            error: (error) => {
                this.isSubmitted = false;
                this.login_Wheel = '';
        
        
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Please try again later.' });
            }
        });
    }

    ngOnDestroy() {
        this.removeClassFromBody();
    }

    addClassOnBody() {
        //Add the class to body tag when the View is initialized
        let body = document.getElementsByTagName('body')[0];
        body.classList.add("remove-padding-margin");
        body.classList.remove("menu-on-top");
        body.classList.remove("fixed-header");
    }

    removeClassFromBody() {
        //Remove the class from body tag when the View is destroyed
        let body = document.getElementsByTagName('body')[0];
        body.classList.remove("remove-padding-margin");
        body.classList.add("menu-on-top");
        body.classList.add("fixed-header");
    }
    getContainerTypes(): void {

        this._containerService.getAllContainerTypesData(null)
        .subscribe({
            next: (response) => {
                this._sharedService.setContainerTypes(response);
            },
            error: (error) => {
                console.log(error)
            }
        });
    }    
}
