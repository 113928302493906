export class articleSectionModel {
  constructor(
      public ArticleSectionId?: string,
      public ArticleCategoryId?:string,
      public ArticleCategoryName?:string,
      public Name?: string,
      public Description?:string,
      public Order?:number,
      public DisplayColumn?:number,
      public Status?: boolean,
      public Color?:string,
      public CreatedBy?: string,
      public CreatedOn?: Date,
      public ModifiedBy?: string,
      public ModifiedOn?: Date,

   ) {


  }
}

