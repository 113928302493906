//Components
import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//Services
import { SelectItem, ConfirmationService } from 'primeng/api';
import { SharedService } from '../../../shared/services/shared.service';
import { NgForm } from '@angular/forms';
import { GridOptions, GridApi } from "ag-grid-community";
import { AssetService } from '../../services/asset/asset.service';
import { AssetModel, MoveAssetModel, AssetDependents } from '../../services/asset/asset';
import { ViewService } from '../../services/view/view.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';

var _router: Router;
var assetID: string;

@Component({
    selector: 'assets',
    templateUrl: './assets.component.html',
    providers: [AssetService, ViewService, ConfirmationService]


})
export class AssetsComponent implements OnInit ,OnDestroy {


    @Input() assetType: string;
    @Input() aircraftID: string;
    @Input() aircraftASN: string;
    @Input() assetCategory: string;
    @Output() assignedUserClicked: EventEmitter<string> = new EventEmitter<string>();
    selectedSubassetID:string;
    assetTypes: SelectItem[] = [];
    drpUserAircraft: SelectItem[] = [];
    linkToAssetDrp: SelectItem[] = [];
    drpUserAssets: SelectItem[] = [];
    drpAssets: any[] = [];
    isLinkAsset:boolean=false;
    isAdd: boolean;
    isEditVisible: boolean = true;
    isAddVisible: boolean = true;
    display: boolean = false;
    dlgAttachAssetdisplay: boolean = false;
    dialogTitle: string;
    model: AssetModel;
    modelAttachAsset: AssetModel;
    isSubmitted: boolean = false;
    isSubmittedAttachAsset: boolean = false;
    //totalCount: string;

    gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[];
    public canAddEditAirCraft: boolean = false;
    public isSysAdmin: boolean = false;
    public isAdminAircraft: boolean = false;
    public disableReadOnlyButton: boolean = false;

    public addAircraftPath: string = "/admin/addAsset";
    public assetPath = "/admin/asset";
    private api: GridApi;

    public selectedAssetToMove: AssetModel = null;
    public displayMoveAssetWindow: boolean = false;

    availableAircrafts: SelectItem[] = [];

    public selectedAircraftDestination: string;

    public assetDependents: AssetDependents = null;

    public appHotkeys: Hotkey[] = [];
    runAutoGapAnalyser: string = "";
    ASNTitle: string = "";
    ASNTitleAirframe: string = "Asset Serial Number";
    ASNTitleCollection: string = "Number";

    ManufacturerTitle: string = "";
    ManufacturerTitleAirframe: string = "Manufacturer";
    ManufacturerTitleCollection: string = "Attribute";
    //@ViewChild('assetsEditdlg') addEditdlg: ElementRef;

    isChecked = false
    isCheckedReadOnly = false;
    constructor(public sharedService: SharedService, private router: Router, private _confirmationService: ConfirmationService,
        private assetManagementService: AssetService, private _viewService: ViewService,private _hotkeysService: HotkeysService) {
        if (!this.sharedService.isAuthUser()) {
            this.router.navigate(['login']);
            this.sharedService.SessionExpiredMessage();
        }
        else {
            _router = this.router;
            this.updateRoles();
            this.navigateToNextCell=this.navigateToNextCell.bind(this);
        }
    }

    ngOnInit() {
        if (this.canAddEditAirCraft) {
            this.model = new AssetModel();
            this.modelAttachAsset = new AssetModel();
            //this.model.status = true;
            this.bindGrid();
            this.getAutoRunGapAnalyserValue();
            //this.populateAssetTypeDropdown();
            this.loadSubAssetTypes();
            this.loadGridData();
            this.PopulateUserAircraftDropDown();
            this.PopulateUserAssetDropDown();
            this.setHotKeys()
            assetID = null;
        }
    }


    populateAssetTypeDropdown() {
        this.assetTypes = [];
        this.assetTypes = [
            { label: "Engine", value: "Engine" },
            { label: "Landing Gear", value: "LDG" },
            { label: "Propeller", value: "Propeller" },
            { label: "APU", value: "APU" }
        ]
    }

    PopulateUserAircraftDropDown() {

        let userRole: string = localStorage.getItem('roles').trim();
        let body = { UserRole: userRole,Parent:this.aircraftID  };
        this.drpUserAircraft = [];
        this.assetManagementService.getParentAssetDropdownList(body)
        .subscribe({
            next: (response) => {
                this.drpUserAircraft = response;
            },
            error: (error) => {
                console.log(error)
            }
        });
    }

    PopulateUserAssetDropDown() {

        let userRole: string = localStorage.getItem('roles').trim();
        let body = { UserRole: userRole, Parent:this.aircraftID };
        this.drpUserAssets = [];
        this.drpAssets=[];
        this.assetManagementService.getAssetDropdownList(body)
        .subscribe({
            next: (response) => {
                this.drpUserAssets = response;
                this.drpAssets=response;
            },
            error: (error) => {
                console.log(error)
            }
        });
    }

bindGrid() {

        this.setAircraftGridColumns();
        this.gridOptions = <GridOptions>{
            rowSelection: 'single',
            getRowStyle:this.getRowStyleScheduled,
            // suppressContextMenu: true,
            onSelectionChanged: this.handleSelectedDoc,
            onGridReady: () => {
                if (this.gridOptions.api != undefined) {
                    //this.gridOptions.api.sizeColumnsToFit();
                    this.gridOptions.api.setColumnDefs(this.columnDefs);
                    this.api = this.gridOptions.api;
                }


            },
            onModelUpdated: (event: any) => {

                if (this.gridOptions.rowData != null && this.gridOptions.rowData != undefined && this.gridOptions.rowData.length > 0) {
                    if (this.api != undefined) {
                        this.api.sizeColumnsToFit();
                        //this.api.selectIndex(0, true, false);
                        //this.api.getDisplayedRowAtIndex(0).setSelected(true);

                    }
                }
            },
            getContextMenuItems: this.getCustomContextMenuItems,
            context: {
                gridContext: this
            },
            defaultColDef: {
                sortable: true,
                resizable: true,
            }

        }


    }

    getRowStyleScheduled(params) {
        if (params.context.gridContext.aircraftID && params.data.ParentAsset != params.context.gridContext.aircraftID) {
            return {
                color: 'blue'
            }
        }
        return null;
    };

    setAircraftGridColumns(): void {

        this.columnDefs = [];
        this.columnDefs =
            [
                { headerName: 'Sequence Number', field: 'Order', filter: 'text', menuTabs: ['filterMenuTab'] },
                { headerName: 'Type', field: 'AssetType', filter: 'text', menuTabs: ['filterMenuTab'] },
                { headerName: 'Title', field: 'Description', filter: 'text', menuTabs: ['filterMenuTab'] },
                { headerName: 'Number/Serial', field: 'ASN', filter: 'text', menuTabs: ['filterMenuTab'] },
                { headerName: 'Manufacturer/Attribute', field: 'Manufacturer', filter: 'text', menuTabs: ['filterMenuTab'] },
                { headerName: 'Owner', field: 'Owner', filter: 'text', menuTabs: ['filterMenuTab'] },
                { headerName: 'Part Number', field: 'PartNumber', filter: 'text', menuTabs: ['filterMenuTab'] },
                { headerName: 'Linked To', field: 'LinkAssetTitle', filter: 'text', menuTabs: ['filterMenuTab'] },
                { headerName: 'Position', field: 'Position', filter: 'text', menuTabs: ['filterMenuTab'] },
                {
                    headerName: 'Status', field: 'Status', filter: 'text', suppressMenu: true, valueFormatter: function (params: any) {
                        if (params.value == true) {
                            return "Active";
                        }
                        else {
                            return "Inactive";
                        }
                    }
                }
            ];


        this.columnDefs.push({
            headerName: 'ReadOnly', field: 'ReadOnly', filter: 'text', suppressMenu: true, valueFormatter: function (params: any) {
                if (params.value == true) {
                    return "Yes";
                }
                else {
                    return "No";
                }
            }
        });

    }

    loadGridData(): void {
        if (this.aircraftID) {
            let userRole: string = localStorage.getItem('roles').trim();
            let body = this.aircraftID;
            this.assetManagementService.getAssetsbyParentID(body)
            .subscribe({
                next: (response) => {
                    this.gridData = [];
                    this.gridData = response;
                    //this.api.sizeColumnsToFit();
                    //this.totalCount = this.gridData.length;
                },
                error: (error) => {
                    console.log(error)
                }
            });
        }

    }

    updateRoles() {
        if (!this.sharedService.UserRole) {
            this.sharedService.updateRole();
        }

        this.canAddEditAirCraft = this.sharedService.UserRole.Admin_User;
        this.isSysAdmin = this.sharedService.UserRole.Admin_Sys_Admin;
        this.isAdminAircraft = this.sharedService.UserRole.Admin_Aircraft;
    }

    initializeForm(assetD: string): void {
        if (assetD) {
            this.loadAssetData(assetD);
        }

    }

    loadAssetData(assetID: string): void {

        let userRole: string = localStorage.getItem('roles').trim();

        this.assetManagementService.getAssetbyID(assetID).subscribe({
          next: (response) => {
            this.linkToAssetDrp = this.drpUserAircraft;
            //   this.gridOptions.api.sizeColumnsToFit();
            if (this.drpUserAircraft.find((x) => x.value == assetID)) {
              this.linkToAssetDrp = this.drpUserAircraft.filter((item) => item.value != assetID);
            }
            this.model = response;
            this.updateReadOnly();
            this.isChecked = this.model.Status;
            this.onAssetChange(null);
          },
          error: (error) => {
            this.sharedService.hideLoader();
            console.log(error);
          },
        });
    }

    updateReadOnly() {
        if (this.isSysAdmin) {
            this.disableReadOnlyButton = false;
        } else {
            if (this.isAdminAircraft && this.model.ReadOnly == false) {
                this.disableReadOnlyButton = false;
            } else {
                this.disableReadOnlyButton = true;
            }
        }

    }

    btnSubmitClick(form: NgForm) {
        this.changeButtonsStatus(true);
        //this.model.assetType = "Airframe";
        let assetModel = this.model;
        assetModel.parentAsset = this.aircraftID;
        assetModel.StoragePath = this.aircraftASN.toLowerCase();
        assetModel.Level = this.assetManagementService.AssetLevelChild;
        // if (assetModel.AssetID != null && assetModel.AssetID > 0) {
        if (assetModel.AssetID) {
            this.updateAsset(assetModel, form);
        }
        else {
            this.addAsset(assetModel, form);
        }


    }


    btnAttachAssetSubmitClick(form: NgForm) {
        this.changeButtonsStatusAttachAsset(true);
        //this.model.assetType = "Airframe";
        let assetModel = this.modelAttachAsset;
         
        // assetModel.parentAsset = this.aircraftID;
        assetModel.LinkAsset = this.aircraftID;
        var flteredAsset= this.drpAssets.find(a=>a.value==assetModel.AssetID)

        if (!(flteredAsset && flteredAsset.LinkAsset && flteredAsset.LinkAsset!="0")) {
            
            this.linkSubAsset(assetModel, form);
        }
      else{
        this._confirmationService.confirm({

            message: 'Selected asset is already linked, do you want to delink first and then attach with current asset? Yes/No',
            header: 'Delink Sub-asset',
            icon: 'fa fa-link',
            accept: () => {

                assetModel.Status=true;
                this.linkSubAsset(assetModel, form);
            },
            reject: () => {
                return ;
            }
        
        });
    }




    }


    delinkAssetClick() {
        
        //this.model.assetType = "Airframe";
        let assetModel = new AssetModel();
         assetModel.AssetID=this.selectedSubassetID
        // assetModel.parentAsset = this.aircraftID;
        assetModel.LinkAsset = '0';
     
        this._confirmationService.confirm({

            message: 'Do you want to delink Sub-asset? Yes/No',
            header: 'Delink Sub-asset',
            icon: 'fa fa-link',
            accept: () => {

            this.delinkSubAsset(assetModel);
            },
            reject: () => {
                return ;
            }
        
        });
    
}


    delinkSubAsset(body: AssetModel) {
        this.sharedService.showLoader("Processing...");
        this.assetManagementService.attachSubAsset(body)
        .subscribe({
            next: (response) => {
                this.sharedService.hideLoader();
                this.sharedService.clearToast();
                this.sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Sub-asset Delinked succesfully.' });
                this.isLinkAsset=false;
                this.loadGridData();
            },
            error: (error) => {
                this.sharedService.hideLoader();
                this.sharedService.clearToast();
                if (error.status === 500) {
                    this.sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'An error Occured, please try again.' });
                }
                else {
                    this.sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'An error Occured, please try again.' });
                }
            }
        });
    }
    getAutoRunGapAnalyserValue(): void {
        this.runAutoGapAnalyser = this.sharedService.getRunAutoGapAnalyserValue().toLocaleLowerCase();
    }


linkSubAsset(body: AssetModel, form: NgForm) {
    this.sharedService.showLoader("Processing...");
    this.assetManagementService.attachSubAsset(body)
    .subscribe({
        next: (response) => {
            this.dlgAttachAssetdisplay = false;
            this.resetModelAttachAsset();
            form.resetForm();
            this.sharedService.hideLoader();
            this.sharedService.clearToast();
            this.sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Asset linked succesfully.' });
            this.changeButtonsStatusAttachAsset(false);
            this.loadGridData();
            this.isLinkAsset=false;			
		},
        error: (error) => {
            this.sharedService.hideLoader();
            this.sharedService.clearToast();
            if (error.status === 500) {
                this.sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'An error Occured, please try again.' });
            }
            else {
                this.sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'An error Occured, please try again.' });
            }
            this.dlgAttachAssetdisplay= false;
            this.resetModelAttachAsset();
            this.changeButtonsStatusAttachAsset(false);			
		}
    });
}



addAsset(body: AssetModel, form: NgForm) {
    this.sharedService.showLoader("Processing...");
    this.assetManagementService.addAssetData(body)
    .subscribe({
        next: (response) => {
            this.display = false;
            this.resetModel();
            form.resetForm();
            this.sharedService.hideLoader();
            this.sharedService.clearToast();
            this.sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Asset added succesfully.' });
            this.changeButtonsStatus(false);
            this.loadGridData();
            //this.returntoAction(this.addAircraftPath);			
		},
        error: (error) => {
            this.sharedService.hideLoader();
            this.sharedService.clearToast();
            if (error.status === 409) {
                this.sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Asset already exists.' });
            }
            else {
                this.sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Asset not added, please try again.' });
            }
            this.display = false;
            this.resetModel();
            this.changeButtonsStatus(false);			
		}
    });

}

    updateAsset(body: AssetModel, form: NgForm) {
        this.sharedService.showLoader("Processing...");
        this.assetManagementService.updateAsset(body)
        .subscribe({
            next: (response) => {
                this.display = false;
                this.resetModel();
                form.resetForm();
                this.sharedService.hideLoader();
                this.sharedService.clearToast();
                this.sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Asset updated succesfully.' });
                this.changeButtonsStatus(false);
                this.loadGridData();
                //this.returntoAction(this.addAircraftPath);                
            },
            error: (error) => {
                this.sharedService.hideLoader();
                this.sharedService.clearToast();
                if (error.status === 409) {
                    this.sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Asset already exists.' });
                }
                else {
                    this.sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Asset not updated, please try again.' });
                }
                this.display = false;
                this.resetModel();
                this.changeButtonsStatus(false);                
            }
        });
    }

   

    btnCancelClick(cancelForm: NgForm) {
        this.display = false;
        cancelForm.resetForm();
        this.model = new AssetModel();
    }


    btnCancelAttachAssetClick(cancelForm: NgForm) {
        this.dlgAttachAssetdisplay = false;
        cancelForm.resetForm();
        this.modelAttachAsset = new AssetModel();
    }

    returntoAction(url: string) {
        setTimeout(() => {
            this.router.navigateByUrl(url);
        },
            2000);
    }

    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }

    changeButtonsStatusAttachAsset(status: boolean) {
        this.isSubmittedAttachAsset = status;
    }

    resetModel() {
        this.model = new AssetModel();
    }

    resetModelAttachAsset() {
        this.modelAttachAsset = new AssetModel();
    }



    handleSelectedDoc(): void {

        var rowCount = this.api.getSelectedNodes().length;
        if (rowCount > 0) {
            var lastRowData = this.api.getSelectedNodes()[rowCount - 1].data;
            assetID = lastRowData.AssetID;
        }
    }


    subAssetGridRowClick(event: any): void {
        if (event) {
            this.selectedSubassetID = event.data.AssetID;
            let parentAssetID = event.data.ParentAsset;
            let linkAsset = event.data.LinkAsset;
            if(this.aircraftID && parentAssetID != this.aircraftID){
                this.isLinkAsset=true;
            }
            else{
                this.isLinkAsset=false; 
            }
        }
    }
    ngOnDestroy() {

        this.appHotkeys.forEach(hotkey => {
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];

    }

    setHotKeys() {
        let hotKeyObj: Hotkey = null;
        hotKeyObj = new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
            this.showEditDialog(null);
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

        // Export Excel
        hotKeyObj = new Hotkey('enter', (event: KeyboardEvent): boolean => {
            this.showEditDialog(null);
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
    }
    showEditDialog(event: any): void {
        
        this.sharedService.showLoader("Processing...");
        if (event) {
            let assetID = event.data.AssetID;
            let parentAssetID = event.data.ParentAsset;
            if(parentAssetID != this.aircraftID){
                return;
            }
            this.isAdd = false;
            this.dialogTitle = "Sub Asset > Edit";
            // this.display = true;
            this.initializeForm(assetID);
        }
        else {
            this.model = new AssetModel();
            if (assetID) {
                this.isAdd = false;
                this.dialogTitle = "Sub Asset > Edit";
                // this.display = true;
                this.initializeForm(assetID);
                //assetID = null;
            }
            else {

                this.sharedService.hideLoader();
                this.sharedService.showToast({ severity: 'warn', summary: 'No asset selected', detail: 'Please select asset' });
                
            }
        }
    }



    showMoveAssetDialog(): void {
        var rowCount = this.api.getSelectedNodes().length;
        if (rowCount > 0) {

            var rowData = this.api.getSelectedNodes()[rowCount - 1].data;
            this.selectedAssetToMove = rowData;

            assetID = rowData.AssetID;
            this.displayMoveAssetWindow = true;
            this.availableAircrafts = [];
            this.selectedAircraftDestination = null;
            this.assetDependents = null;

            this.sharedService.showLoader('Please wait...');


            let assetType = 'Collection';

            // if (this.sharedService.selectedAsset.AssetType == 'Airframe')
            // {
            //     assetType = 'Airframe,' + assetType;
            // }
            if (this.selectedAssetToMove.AssetType !== 'Item') {
                assetType = 'Airframe,' + assetType;
            }


            this.assetManagementService.PopulateAssetsOrCollections(assetType)
            .subscribe({
                next: (response) => {
                    let afc_assets = response;
                    let filter = this.sharedService.selectedAsset.AssetID + '|' + this.sharedService.selectedAsset.ASN;
        
                    // tslint:disable-next-line: only-arrow-functions
                    var filteredAssets = afc_assets[0].filter(function (asset) {
                        return asset.value != filter;
                    });
        
                    this.availableAircrafts = filteredAssets.sort((a, b) => (a.label > b.label) ? 1 : -1);
                },
                error: (error) => {
                    console.log(error)
                }
            });

            this.assetManagementService
                .getAssetDependents(this.selectedAssetToMove)
                .subscribe({
                    next: (response) => {
                        this.assetDependents = response;
                        this.sharedService.hideLoader();
                    },
                    error: (error) => {
                        console.log(error)
                    }
                });

        } else {
            this.sharedService.showToast({ severity: 'warn', summary: 'No asset selected', detail: 'Please select asset' });
        }
    }
    onAircraftSelect(event: any) {
        this.selectedAircraftDestination = event.value;
    }
    aircraftSelectEvent(aircraft: any) {
        this.selectedAircraftDestination = aircraft.value;
    }
    btnCancelAssetMoveClick(): void {
        this.displayMoveAssetWindow = false;
    }

    btnAssetMoveClick(): void {
        if (this.selectedAircraftDestination != null
            && this.selectedAssetToMove != null) {
            this._confirmationService.confirm({

                message: 'Do you want to move Asset? Please make sure nobody else is working on this asset, any changes done in meantime will not be depicted.',
                header: 'Asset Move Confirmation',
                icon: 'fa fa-exchange',
                accept: () => {

                    let moveAsset: MoveAssetModel = new MoveAssetModel();

                    moveAsset.SelectedAssetASN_ToMove = this.selectedAssetToMove.ASN;
                    moveAsset.SelectedAssetId_ToMove = this.selectedAssetToMove.AssetID;
                    moveAsset.SelectedDestinationAssetId = this.selectedAircraftDestination;

                    this.assetManagementService.moveAsset(moveAsset)
                    .subscribe({
                        next: (response) => {
                            var resp = response;
                            if (resp == 'Success') {
                                this.sharedService.clearToast();
                                this.btnCancelAssetMoveClick();
                                this.loadGridData();
                                // tslint:disable-next-line: max-line-length
                                this.sharedService.showToast({ severity: 'success', summary: 'Asset movement request sent successfully.', detail: 'Asset movement job request is successfully sent.' });
        
                            }
                        },
                        error: (error) => {
                            console.log(error)
                        }
                    });

                },
                reject: () => {
                }
            });
        } else {
            this.sharedService.clearToast();
            // tslint:disable-next-line: max-line-length
            this.sharedService.showToast({ severity: 'warn', summary: 'No aircraft selected', detail: 'Select destination aircraft to move asset' });
        }

    }

    showAddDialog(): void {
        
        this.sharedService.showLoader("Processing...");
        this.model = new AssetModel();
        this.model.Status = true;
        this.model.MXRecords = true;
        this.model.LogBooks = true;
        this.model.General = true;
        this.model.Documents = true;
        this.model.Binders = true;
        this.model.AutoGapAnalyser = false;
        this.model.LinkAsset = "0";
        this.isAdd = true;
        this.dialogTitle = "Sub Asset > Add";
        this.loadSubAssetTypes();
        this.model.AssetType = "Engine";
        this.setFieldsTitle();
        this.sharedService.hideLoader()
        this.display = true;
        
    }

    showLinkAssetDialog(): void {
        this.modelAttachAsset = new AssetModel();
        this.dlgAttachAssetdisplay = true;
    }


    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];

        if (params.context.gridContext.canAddEditAirCraft) {
            result.push({
                name: "Add Asset",
                action: function () {
                    params.context.gridContext.showAddDialog()
                },
                icon: params.context.gridContext.sharedService.addIconHTMLasString,
            });

            result.push({
                name: "Edit Asset",
                action: function () {
                    params.context.gridContext.assetID = null;
                    if (params.node) {
                        params.context.gridContext.assetID = params.node.data.AssetID;
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext.isAdd = false;
                        params.context.gridContext.dialogTitle = "Sub Asset > Edit";
                        params.context.gridContext.display = true;
                        params.context.gridContext.initializeForm(params.context.gridContext.assetID);
                    }

                },
                icon: params.context.gridContext.sharedService.editIconHTMLasString,
            });
        }
        return result;


    }
    loadSubAssetTypes() {
        if (this.assetCategory == this.assetManagementService.AssetTypeAirframe || this.assetCategory == this.assetManagementService.AssetTypeHelicopter) {
            this.populateAssetTypeDropdown();
        }
        else {
            this.assetTypes = this.assetManagementService.populateAssetTypeDropdownCollection();
        }
    }
    setFieldsTitle() {
        if (this.assetCategory == this.assetManagementService.AssetTypeItem) {
            this.ASNTitle = this.ASNTitleCollection;
            this.ManufacturerTitle = this.ManufacturerTitleCollection;
        }
        else {
            this.ASNTitle = this.ASNTitleAirframe;
            this.ManufacturerTitle = this.ManufacturerTitleAirframe;
        }

    }

    onAssetChange(event: any) {
        if (this.model.AssetType == this.assetManagementService.AssetTypeItem) {
            this.ASNTitle = this.ASNTitleCollection;
            this.ManufacturerTitle = this.ManufacturerTitleCollection;
        }
        else {
            this.ASNTitle = this.ASNTitleAirframe;
            this.ManufacturerTitle = this.ManufacturerTitleAirframe;
        }
        this.adjustJobDlgHeight();
    }

    adjustJobDlgHeight() {
        let tskDlgfrm = document.getElementById('assetsEditdlgform');
        let taskDlgWindow = document.getElementById('assetsEditdlg');
        if (tskDlgfrm) {
            switch (this.model.AssetType) {
                case this.assetManagementService.AssetTypeAirframe:
                    taskDlgWindow.style.height = '80vh';
                    //tskDlgfrm.style.height = '80vh';
                    tskDlgfrm.style.overflowY = 'hidden';
                    tskDlgfrm.style.overflowX = 'hidden';
                    break;
                case this.assetManagementService.AssetTypeHelicopter:
                    taskDlgWindow.style.height = '80vh';
                    //tskDlgfrm.style.height = '80vh';
                    tskDlgfrm.style.overflowY = 'hidden';
                    tskDlgfrm.style.overflowX = 'hidden';
                    break;
                case this.assetManagementService.AssetTypeItem:
                    taskDlgWindow.style.height = '55vh';
                    //tskDlgfrm.style.height = '45vh';
                    tskDlgfrm.style.overflowY = 'hidden';
                    tskDlgfrm.style.overflowX = 'hidden';
                    break;
                default:
                    taskDlgWindow.style.height = '65vh';
                    //tskDlgfrm.style.height = '80vh';
                    tskDlgfrm.style.overflowY = 'hidden';
                    tskDlgfrm.style.overflowX = 'hidden';
            }
        }
        
        this.sharedService.hideLoader();
        this.display = true;
    }
    //#endregion

    ToggleChecked() {

        if (this.isChecked) {
            this.isChecked = false;
            this.model.Status = false;
        }
        else {
            this.isChecked = true;
            this.model.Status = true;
        }
    }

    ToggleCheckedReadOnly() {

        if (this.isCheckedReadOnly) {
            this.isCheckedReadOnly = false;
        }
        else {
            this.isCheckedReadOnly = true;
        }
    }

    openAssignedUserDlg() {
        this.assignedUserClicked.emit(this.model.AssetID);
    }
    navigateToNextCell(params: any) {
        var previousCell = params.previousCellPosition;
        var suggestedNextCell = params.nextCellPosition;
        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        switch (params.key) {
            case KEY_DOWN:
                var nextRowIndex = previousCell.rowIndex + 1;
                var renderedRowCount = this.gridOptions.api.getModel().getRowCount();
                if (nextRowIndex >= renderedRowCount) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
                    rowNode.setSelected(true);
                    assetID = rowNode.data.AssetID;
                    return {
                        rowIndex: nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }


            case KEY_UP:
                var _nextRowIndex = previousCell.rowIndex - 1;
                if (_nextRowIndex < 0) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
                    rowNode.setSelected(true);
                    assetID = rowNode.data.AssetID;
                    return {
                        rowIndex: _nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw 'this will never happen, navigation is always on of the 4 keys above';
        }
    }
    isAutoGapAnalyser: boolean = false
    ToggleAutoGapAnalyser() {

        if (this.isAutoGapAnalyser) {
            this.isAutoGapAnalyser = false;
            this.model.AutoGapAnalyser = false;
        }
        else {
            this.isAutoGapAnalyser = true;
            this.model.AutoGapAnalyser = true;
        }
    }
}
