//Components
import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { GridOptions } from "ag-grid-community";

//Services

import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { ViewService } from 'src/app/admin/services/view/view.service';
import { ContainerService } from 'src/app/records/services/container/container.service';
import { SharedService } from '../../services/shared.service';
import { APIService } from 'src/app/api/services/api.service';
import { Subscription } from 'rxjs';
var _router_: Router;
@Component({
  selector: 'dataimporter',
  templateUrl: './data-importer.component.html',
  providers: [ContainerService, ViewService, APIService]

})

export class DataImporterComponent implements OnInit, OnDestroy {
  display: boolean = false;
  public gridOptions: GridOptions;
  columnDefs: any[];
  public gridData: any[] = [];
  selectedFileName: string;
  public dialogTitle;
  public uploaddisplay;
  public displayFileGrid;
  isSubmitted: boolean = false;
  uploadedFiles: any[] = [];
  viewFile: any;
  acceptfilestype: string = "";
  @ViewChild('feedfile') selectedFeedFiles: any;
  templateTitle;
  private totalCount: number;
  public appHotkeys: Hotkey[] = [];
  // #region Initialization of Component
  constructor(private _sharedService: SharedService, private _router: Router, private _viewService: ViewService,
    private route: ActivatedRoute, private _hotkeysService: HotkeysService, private _apiService: APIService,) {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(['login']);
    }
    else {
      _router_ = this._router;
      this.setHotKeys();
    }
  }
  private ImportFeedComputerContextMenu: Subscription;
  private ImportFeedStorageContextMenuSub: Subscription;
  ngOnInit() {
    this.gridOptions = <GridOptions>
      {
        rowSelection: 'single',
        onGridReady: () => {
          this.setViewGridColumns();
          if (this.gridOptions.api != undefined) {
            try {
              this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridUploadGrid'));
              this.gridOptions.api.hideOverlay();
            } catch (e) {
              console.log(e);
            }
          }
        },
        onModelUpdated: (event: any) => {
          this.gridOptions.api.sizeColumnsToFit();
        },
        //suppressContextMenu: true,
        pagination: true,
        paginationPageSize: 20,
        context: {
          gridContext: this
        },
        defaultColDef: {
          sortable: true,
          filter: true
        }
      };
      
    this.templateTitle = (this._router.url.indexOf("apifeed") > -1 || (this._router.url.indexOf("record") > -1)) ? "Import Feed" : "Import Status View";
    this.ImportFeedStorageContextMenuSub = this._sharedService._importFeedComputerContextMenu
      .subscribe({
        next: (response) => {
          this.showUploadDialog();
        },
        error: (error) => {
          console.log(error)
        }
      });
    this.ImportFeedComputerContextMenu = this._sharedService._importFeedStorageApiFeedContextMenu
      .subscribe({
        next: (response) => {
          this.showFileGrid();
        },
        error: (error) => {
          console.log(error)
        }
      });
  }

  setHotKeys() {
    let _hotKeyObj: Hotkey = null;
    let hotKeyObj = this._hotkeysService.get('ctrl+q');
    if (hotKeyObj == null || hotKeyObj == undefined) {
      _hotKeyObj = new Hotkey('ctrl+q', (event: KeyboardEvent): boolean => {
        this.showUploadDialog();
        return false; // Prevent bubbling
      });

      this._hotkeysService.add(_hotKeyObj);
      this.appHotkeys.push(_hotKeyObj);
    }
    hotKeyObj = this._hotkeysService.get('ctrl+y');
    if (hotKeyObj == null || hotKeyObj == undefined) {
      //feed log
      _hotKeyObj = new Hotkey('ctrl+y', (event: KeyboardEvent): boolean => {
        // if (_router_.url == "/apifeed/import" || _router_.url == "/apifeed/export") {
        this.showFileGrid();
        // }
        return false; // Prevent bubbling
      });
      this._hotkeysService.add(_hotKeyObj);
      this.appHotkeys.push(_hotKeyObj);
    }
  }


  ngOnDestroy() {
  }
  // #endregion

  // #region create data from grid selected data
  btnCreateViewFromFileBlobStorage() {

    let templateType = (this._router.url.indexOf("apifeed") > -1 || (this._router.url.indexOf("record") > -1)) ? 2 : 1;
    if (this.gridOptions.api.getSelectedRows().length > 0) {
      this._sharedService.showLoader("Processing...");
      if (templateType == 1)
        this.createViewFromFileBlobStorage(templateType, this.selectedFileName);
      else {
        this.createFeedFromFileBlobStorage(templateType, this.selectedFileName);
      }

    }
    else {
      var message = (templateType == 1) ? "View" : "Feed";
      this._sharedService.showToast({ severity: 'warn', summary: 'No ' + message + ' Selected.', detail: 'Please select a row first.' });

    }
  }

  createFeedFromFileBlobStorage(templateType: any, selectedFileName: any) {
    this._apiService.createFeedFromFileBlobStorage(templateType, this.selectedFileName)
      .subscribe({
        next: (response) => {
          this._sharedService.hideLoader();
          this.selectedFileName = null;
          if (response == 400) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while reading file.' });
          }
          else if (response == 409) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Feed not added', detail: 'Feed having same name or values already exist.' });
          }
          else {
            this.insertSuccessMessage(templateType)
            this._sharedService.resfreshDataFeedGrid(true);
          }
        },
        error: (error) => {
          console.log(error)
          this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while reading file.' });
          this._sharedService.hideLoader();
        }
      });
  }
  createViewFromFileBlobStorage(templateType: any, selectedFileName: any) {
    this._viewService.createViewFromFileBlobStorage(templateType, this.selectedFileName)
      .subscribe({
        next: (response) => {
          this._sharedService.hideLoader();
          this.selectedFileName = null;
          if (response == 400) {
            this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while reading file.' });
          }
          else {
            this.insertSuccessMessage(templateType)
            this._sharedService.resfreshViewGrid(true);
          }
        },
        error: (error) => {
          this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while reading file.' });
          this._sharedService.hideLoader();
          console.log(error)
        }
      });
  }
  // #endregion
  // #region Ag Grid Population Event
  populateViewGrid(): void {
    let templateType = 0;
    templateType = (this._router.url.indexOf("apifeed") > -1 || (this._router.url.indexOf("record") > -1)) ? 2 : 1;
    this._sharedService.showLoader("Processing...");
    this._viewService.getImportDataFileNameList(templateType)
      .subscribe({
        next: (response) => {
          this.gridData = [];
          this.setViewGridColumns();
          this.gridData = response;
          this.totalCount = response.length;
          this._sharedService.hideLoader();
        },
        error: (error) => {
          console.log(error)
          this.gridData = [];
          this.totalCount = 0;
          this._sharedService.hideLoader();
        }
      });
  }
  // #endregion

  // #region Set Columns of Browse Ag Grid
  setViewGridColumns(): void {
    this.columnDefs = [];
    this.columnDefs =
      [
        { headerName: 'File Name', filter: true, field: 'FileName', menuTabs: ['filterMenuTab'] },
      ];
  }
  // #endregion

  showFileGrid() {
    this.displayFileGrid = true;
    this.gridData = [];
    this.populateViewGrid();
  }

  // #region Upload file and Create View

  showUploadDialog() {
    
    this.acceptfilestype = (this._router.url.indexOf("apifeed") > -1 || (this._router.url.indexOf("record") > -1)) ? ".rfeed": ".rview";
    // this.acceptfilestype = ".rview,.rfeed";
    this.dialogTitle = "Upload Feed Files";
    this.uploaddisplay = true;
    this.uploadedFiles = []
    this.clearSelectedFiles()
  }

  // #region create record from file
  onUploadFileClick(event: any, form: NgForm) {
    this.changeButtonsStatus(true);
    let deletedfileslist: string[] = [];
    if (this.uploadedFiles.length > 0) {
      let xhr = new XMLHttpRequest(), formData = new FormData();
      for (let i = 0; i < this.uploadedFiles.length; i++) {
        formData.append("uploads['" + i + "']", this.uploadedFiles[i], this.uploadedFiles[i].name);
      }
      let templateType = 0;
      templateType = (this._router.url.indexOf("apifeed") > -1 || (this._router.url.indexOf("record") > -1)) ? 2 : 1;
      var uploadUrl = null;
      uploadUrl = (templateType == 1) ? `${this._sharedService.baseUrl}/api/View/CreateViewFromFile` : `${this._sharedService.baseUrl}/api/APIModule/CreateFeedFromFile`;
      if (!(this.uploadedFiles.length > 1)) {
        xhr.open('POST', uploadUrl, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        this._sharedService.showLoader("Processing...");
        xhr.send(formData);
        xhr.onreadystatechange = () => {
          this.uploadedFiles = []
          if (xhr.readyState === 4) {
            this._sharedService.hideLoader();
            if (xhr.status === 200) {
              this.insertSuccessMessage(templateType)
              this.uploaddisplay = false;
              this.uploadedFiles = [];
              this.clearSelectedFiles();
              form.resetForm();
              this.changeButtonsStatus(false);
              (templateType == 1) ? this._sharedService.resfreshViewGrid(true) : this._sharedService.resfreshDataFeedGrid(true);
            }
            else if (xhr.status === 409) {
              this.uploaddisplay = false;
              this.uploadedFiles = [];
              this.clearSelectedFiles();
              form.resetForm();
              this.changeButtonsStatus(false);
              this._sharedService.clearToast();
              this._sharedService.showToast({ severity: 'warn', summary: 'Feed not added', detail: 'Feed having same name or values already exist.' });
              this.changeButtonsStatus(false);
            }
            else {
              this.uploaddisplay = false;
              this._sharedService.clearToast();
              this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while reading file.' });
              form.resetForm();
              this.clearSelectedFiles();
              this.changeButtonsStatus(false);
            }
          }
        };
      }
      else {
        this._sharedService.clearToast();
        this._sharedService.showToast({ severity: 'warn', summary: 'Multiple Files.', detail: 'Only one file can be uploaded for template.' });
        this.changeButtonsStatus(false);
      }
    }
    else {
      this.changeButtonsStatus(false);
      this._sharedService.clearToast();
      this._sharedService.showToast({ severity: 'warn', summary: 'No File Selected', detail: 'No file(s) have been uploaded.' });
    }
  }
  //#endregion

  onFileSelection(event: any) {
    let invalidFiles: string[] = [];
    var maxAllowedFileSize = this._sharedService.getMaxDocumentLimitInBytes();
    for (let file of event.files) {
      // if (file.size <= maxAllowedFileSize && this._sharedService.allowedViewDocumentTypes.includes(file.type)) {
      // if (this._sharedService.allowedViewDocumentTypes.includes(file.type)) {
      this.uploadedFiles = [];
      let fileCurrent: any = this.uploadedFiles.filter(x => x.name == file.name);
      this.uploadedFiles.push(file);
      // }
      // else {
      //   invalidFiles.push(file.name);
      // }
    }
    // if (invalidFiles.length > 0) {
    //     var maxAllowedFileSizeMB = this._sharedService.getMaxDocumentLimit();
    //     this._sharedService.clearToast();
    //     this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File(s)', detail: 'File(s) size exceeds the maximum limit of ' + maxAllowedFileSizeMB + ' MB for PDF or 1 GB for other types' });
    // }
  }

  btnCancelClick(form: any) {
    this.changeButtonsStatus(false);
    this.uploaddisplay = false;
    form.clear();
    this.uploadedFiles = [];
    this.viewFile = [];
    this.uploadedFiles = [];
    this.clearSelectedFiles();
  }

  btnCancelClickBlobStorage() {
    this.displayFileGrid = false;
    this.gridData = [];
  }

  onRemove(event: any) {
    for (let i = 0; i < this.uploadedFiles.length; i++) {
      if (this.uploadedFiles[i].name == event.file.name) {
        this.uploadedFiles.splice(i, 1);
        break;
      }
    }
  }

  clearSelectedFiles() {
    if (this.selectedFeedFiles && this.selectedFeedFiles.files) {
      this.selectedFeedFiles.files = [];
    }
  }

  changeButtonsStatus(status: boolean) {
    this.isSubmitted = status;
  }

  // #region Ag-Grid Events
  updateSelectedRow(event: any) {
    this.selectedFileName = event.data.FileName;
  }
  // #endregion

  // #region create data message
  public insertSuccessMessage(templateType) {
    this._sharedService.clearToast();
    if (templateType == 1) {
      this._sharedService.showToast({ severity: 'success', summary: 'View Created', detail: 'View has been created successfully.' });
    }
    else {
      this._sharedService.showToast({ severity: 'success', summary: 'Feed Created', detail: 'Feed has been created successfully.' });
    }
  }
  //#endregion
  //#endregion
}
