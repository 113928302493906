import { SharedService } from '../services/shared.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { HomeComponent } from 'src/app/records/components/home/home.component';


interface CanDeactivate<T> {
  canDeactivate(component: T, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
}

@Injectable()
export class CanDeactivateDashoardGuard implements CanDeactivate<HomeComponent> {

  public fromChildToParent: boolean = false;
  constructor(private _sharedService: SharedService) { }

  canDeactivate(
    component: HomeComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (nextState.url == "/.") return;
    let result = component.confirmSaveChanges(nextState);
    return result;
  }

}




