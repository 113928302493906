import { ArticleService } from '../../services/article/article.service';
import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { SharedService } from './../../../shared/services/shared.service';
import { Subscription } from 'rxjs';
import { articleSectionModel } from './../section/section';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
var _router: Router;
@Component({
  selector: 'add-section',
  templateUrl: './add-section.component.html',
  styles: []
})
export class AddSectionComponent implements OnInit {

  display: boolean = false;
  public dialogTitle: string = 'Add Section';
  public isSubmitted: boolean = false;
  public lblsubmitButton: string = 'Add';
  public btnlabel = 'Add';
  public model: articleSectionModel;
  private editSection: Subscription;
  private addSection: Subscription;
  private editFromMenuSection: Subscription;
  private canAddEditArticleSection: boolean = false;
  ArticleCategoryList: SelectItem[] = [];


  @Input() ArticleSectionID: string;

  @Output()
  refreshGridEvent = new EventEmitter<boolean>();
  constructor(private _sharedService: SharedService, private router: Router, private _articleService: ArticleService) {
    if (!this._sharedService.isAuthUser()) {
      this.router.navigate(['login']);
    }
    else {


      _router = this.router;
      _router = this.router;
      this.updateRoles();
      this.model = new articleSectionModel();
    }

    this.resetModel();

  }

  ngOnInit() {

    this.getListArticleCategory();
    this.editSection = this._sharedService._editAdminSection
      .subscribe({
        next: (param: any) => {
          this.ArticleSectionID = param;
          this.showEditDialog();
        },
        error: (error) => {
        }
      });

    this.addSection = this._sharedService._addAdminSection
      .subscribe({
        next: (response) => {
          this.resetModel();
          this.showDialog();
        },
        error: (error) => {
        }
      });

    this.editFromMenuSection = this._sharedService._editFromMenuSectionId
      .subscribe({
        next: (ArticleSectionID) => {
          this.ArticleSectionID = null;
          this.ArticleSectionID = ArticleSectionID;

          this.showEditDialog();
        },
        error: (error) => {
        }
      });
  }



  getListArticleCategory() {
    this._articleService.getListArticleCatgory(null)
      .subscribe({
        next: (response) => {
          this.ArticleCategoryList = response;
        },
        error: (error) => {
        }
      });
  }

  resetModel() {
    this.model = new articleSectionModel();
    this.model.Status = true;
    this.model.Color = '#0976e3';
  }
  updateRoles() {
    if (!this._sharedService.UserRole) {
      this._sharedService.updateRole();
    }

    this.canAddEditArticleSection = true;

  }

  btnSubmitClick(form: NgForm) {

    this.isSubmitted = true;
    let articleSection = this.model;
    if (articleSection.ArticleSectionId) {
      this.updateSection(articleSection, form);
    }
    else {
      this.addArticleSection(articleSection, form)
    }

  }

  addArticleSection(body: articleSectionModel, form: NgForm) {

    this._sharedService.showLoader("Processing...");
    this._articleService.addArticleSection(body)
      .subscribe({
        next: (response) => {
          this._sharedService.hideLoader();
          this.display = false;
          this.changeButtonsStatus(false);
          this.resetModel();
          this.refreshGridEvent.emit(true);
        },
        error: (error) => {
          this._sharedService.hideLoader();
          this._sharedService.clearToast();
          if (error.status === 409) {
            this._sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Article Section already exists.' });
          }
          else {
            this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Article Section not added, please try again.' });
          }
          this.display = false;
          this.resetModel();
          this.changeButtonsStatus(false);
        }
      });
  }

  updateSection(body: articleSectionModel, form: NgForm) {
    this._sharedService.showLoader("Processing...");
    this._articleService.updateArticleSection(body)
      .subscribe({
        next: (response) => {
          this._sharedService.hideLoader();
          this.display = false;
          this.resetModel();
          this._sharedService.clearToast();
          this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Article Section updated succesfully.' });
          this.changeButtonsStatus(false);
          this.refreshGridEvent.emit(true);
          //this.returntoAction(this.documentTypesURL);
        },
        error: (error) => {
          this._sharedService.hideLoader();
          this._sharedService.clearToast();
          if (error.status === 409) {
            this._sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Article Section already exists.' });
          }
          else {
            this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Article Section not updated, please try again.' });
          }
          this.display = false;
          this.resetModel();
          this.changeButtonsStatus(false);
        }
      });
  }

  changeButtonsStatus(status: boolean) {
    this.isSubmitted = status;
  }
  btnCancelClick() {
    this.display = false;
    this.resetModel();
  }

  showEditDialog() {
    if (this.ArticleSectionID) {
      this.dialogTitle = "Edit Section";
      this.btnlabel = "Update"
      this.display = true;
      this.initializeForm(this.ArticleSectionID);

    }
    else {
      this._sharedService.clearToast();
      this._sharedService.showToast({ severity: 'warn', summary: 'No section selected', detail: 'Please select Section' });
    }
  }


  showDialog() {
    this.display = true;
    this.dialogTitle = "Add Section";
    this.btnlabel = "Save"
  }

  initializeForm(articleCategoryID: string): void {
    if (articleCategoryID) {

      this.loadArticleSectionData(articleCategoryID);
    }
  }

  loadArticleSectionData(CategoryID: string): void {

    let userRole: string = localStorage.getItem('roles').trim();

    this._articleService.getArticleSectionbyId(CategoryID)
      .subscribe({
        next: (response) => {
          this.model = response;

          // this.ArticleCategoryList=[
          //   {label: response.ArticleCategoryName, value: response.ArticleCategoryId}
          // ];
        },
        error: (error) => {
        }
      });
  }

  ngOnDestroy() {
    if (this.editSection) {
      this.editSection.unsubscribe();
    }
    if (this.addSection) {
      this.addSection.unsubscribe();
    }
    if (this.editFromMenuSection) {
      this.editFromMenuSection.unsubscribe();
    }


  }

}
