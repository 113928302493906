import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {SharedService } from '../../../shared/services/shared.service';
import { ViewModel } from './view';
import { ViewFilter } from './viewfilter';

@Injectable()
export class ViewService {

    //#region Global Variables


    //#endregion


    constructor(private _sharedService: SharedService ,  private http: HttpClient) {
    }
    
     ///#region service methods
    getColumns(tableName?:string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/GetColumns?tableName=${tableName}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getAllViews(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/GetAllViews`, '', this._sharedService.getReqOptWithAccessToken());
    }
    getImportDataFileNameList(templateType:number): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/GetImportFileNameList?templateid=${templateType}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    createViewFromFileBlobStorage(templateType:number,fileNmae:string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/CreateViewFromFileBlobStorage?templateid=${templateType}&fileNmae=${fileNmae}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    exportView(viewId: any) {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/ExportView?viewId=${viewId}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    addView(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/AddView`, body, this._sharedService.getReqOptWithAccessToken());
    }

    updateView(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/UpdateView`, body, this._sharedService.getReqOptWithAccessToken());
    }

    populateView(viewId: any) {
        return this.http.post<ViewModel>(`${this._sharedService.baseUrl}/api/View/PopulateView?viewId=${viewId}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    populateAsset(assetType: string, viewSettingId:string) {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/PopulateAsset?assetType=${assetType}&viewSettingId=${viewSettingId}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getViewById(viewId: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/GetViewByID?viewId=${viewId}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    getAllActiveViewsList(body: any): Observable<any>
    {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/GetAllActiveViewsList`, '', this._sharedService.getReqOptWithAccessToken());
    }
    getUserViewsList(): Observable<any>
    {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/GetUserViewsList`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getViewsDropDownList(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/GetViewsDropDownList?asn=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getViewDescriptionById(viewId: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/GetViewDescriptionByID?viewId=${viewId}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getViewDataById(viewFilter: ViewFilter): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/GetViewDataByID`, viewFilter, this._sharedService.getReqOptWithAccessToken());
    }

    getViewHistoryKey(viewSettingId: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/GetViewHistoryKey?viewSettingId=${viewSettingId}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    
    getTaskHistory(viewEntity: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/GetTaskHistory`, viewEntity, this._sharedService.getReqOptWithAccessToken());
    }

    getDFPStatus(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/GetDFPStatusDropDown`, '', this._sharedService.getReqOptWithAccessToken());
    }
    getAllActiveViewsListWithId(body: any): Observable<any>
    {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/GetAllActiveViewsListWithId`, '', this._sharedService.getReqOptWithAccessToken());
    }
    getSpec2500TreeNode(viewName: any): Observable<any>
    {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/GetSpec2500TreeNode?viewName=${viewName}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    getViewNameByViewId(viewId: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/View/GetViewNameByID?viewId=${viewId}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    //#endregion
  


}