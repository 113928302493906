
import { ArticleService } from './../../services/article/article.service';
import { SharedService } from './../../../shared/services/shared.service';
import { articleModel } from './../articles/article';
import { articleSectionModel } from './../section/section';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../../records/services/User/user.service';
import { timingSafeEqual } from 'crypto';
declare const tinymce: any;
@Component({
  selector: 'app-edit-article',
  templateUrl: './edit-article.component.html',
  styles: [],
  providers: [ConfirmationService]
})
export class EditArticleComponent implements OnInit {

  public screenName: string = 'Edit Article';
  selectCategory: SelectItem[];
  public model: articleModel;
  public isSubmitted: boolean = false;
  public btnlabel = 'save';
  public canAddEditArticle: boolean = false;
  public ArticlestPath: string = "/help/articles";
  ArticleCategoryList: SelectItem[];
  ArticleSectionList: SelectItem[];
  public init;
  @Input() public _content: string;
  selectSection: articleSectionModel;
  public EditorKey = '';
  CreatedOn: string;
  seclectedSectionValue: number = 0;
  seclectedCategoryValue: number = 0;
  tenantSelectionList: SelectItem[] = [];
  availableRoles = [];
  selectedRoles = [];
  selectedComponents = [];
  availableComponents = [];
  displayArticleUserRole: boolean = false;



  constructor(private _confirmationService: ConfirmationService, private _sharedService: SharedService, private elementRef: ElementRef, private _articleService: ArticleService, private router: Router, private route: ActivatedRoute, private sanitized: DomSanitizer, private _userService: UserService) {

    if (!this._sharedService.isAuthUser()) {
      this.router.navigate(['login']);
    }
    else {
      this.updateRoles();
    }



  }
  ngOnInit() {

    this.availableRoles = [];
    this.selectedRoles = [];
    this.selectedComponents = [];
    this.availableComponents = this._sharedService.getHelpArticleComponents();
    //this.getAllRolesAndGroups();
    this.getListArticleCategory();
    this.populateTenantsList();
    /*  this.resetModel(); */

    var articleID: string = this.route.snapshot.params["id"];
    this.loadArticleData(articleID);
    this.EditorKey = this._sharedService.TinyMecAPIKey;
    this.init = ({
      selector: 'textarea',
      plugins: 'preview fullpage fullscreen link table image media  code  codesample  fullscreen hr file ',
      statusbar: false,
      menubar: false,
      media_filter_html: true,
      media_live_embeds: true,

      toolbar: "styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | fontsizeselect |fontselect | link | image |media |insert|code | codesample|hr |preview |fullscreen ",
      automatic_uploads: true,
      file_picker_types: 'file image ',
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*,.doc,.docx,.txt,.rtf,.odt,.pdf');

        input.onchange = function () {
          var file = input.files[0];
          var reader = new FileReader();

          reader.onload = function () {
            var id = 'blobid' + (new Date()).getTime();
            var blobCache = tinymce.activeEditor.editorUpload.blobCache;
            // var blobCache =this.editor.nativeElement.value.get("editor").editorUpload.blobCache;
            // var blobCache =this.editor.nativeElement.value.editorUpload.blobCache;
            var base64 = (<string>reader.result).split(',')[1];
            var blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);

            // call the callback and populate the Title field with the file name
            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      }

    })

  }




  loadArticleData(ArticleId: string): void {

    let userRole: string = localStorage.getItem('roles').trim();
    this.model = new articleModel();
    this._sharedService.showLoader("Processing...");
    this._articleService.getArticlebyId(ArticleId)
      .subscribe({
        next: (response) => {
          this.model = response;
          this.getArticleSectionById();


          //this.CreatedOn=this._sharedService.getDateFormatTimeDialogBottom(response.CreatedOn);
          //  this._content=response.ArticleContent;
          //this.model.ArticleCategoryId=response.ArticleCategoryId
          //  if(this.model.ArticleCategoryName !=" "){
          //   this.ArticleCategoryList=[
          //     {label: response.ArticleCategoryName, value: response.ArticleCategoryId}
          //   ];
          //  }
          //  else{
          //   this.seclectedCategoryValue=-1;
          //  }

          //  if(this.model.ArticleSectionName==" " || this.model.ArticleCategoryName ==" " ){
          //    this.seclectedSectionValue=-1;
          //  }
          //  else{
          //   this.ArticleSectionList=[
          //     {label: response.ArticleSectionName, value: response.ArticleSectionId}
          //        ];

          //  }

          this.getAllRolesAndGroups();
          this.setArticleTenants(this.model.Tenants);
          this._sharedService.hideLoader();
        },
        error: (error) => {
        }
      });
  }
  filterSelectedComponents() {
    if (this.model.ArticleComponents != null && this.model.ArticleComponents != undefined) {

      let _selectedComponents = JSON.parse(this.model.ArticleComponents);// this.model.UserRoles.split(',');
      if (_selectedComponents.length > 0) {
        this.selectedComponents = [];
        let allComponents = this.availableComponents;

        var existing_AssignedComponents = _selectedComponents.filter(function (obj) {
          return allComponents.some(function (obj2) {
            return obj.value.trim() == obj2.value.trim();//obj2.Name.trim() == obj;
          });
        });

        var missing_AvailableComponents = this.availableComponents.filter(function (obj) {
          return !existing_AssignedComponents.some(function (obj2) {
            return obj.value.trim() == obj2.value.trim();//obj == obj2.trim();
          });
        });

        this.availableComponents = missing_AvailableComponents;
        this.selectedComponents = existing_AssignedComponents;
      }
      else {
        this.availableComponents = this.availableComponents;
        this.selectedComponents = [];
      }
    }
  }

  setArticleTenants(tenants: string) {
    if (tenants != null && tenants != undefined) {
      this.model.Tenants = '';
      this.model.SelectedTenants = [];
      let _selTenants = [];
      let tenantIds: string[] = tenants.split(',');

      for (var i = 0; i < tenantIds.length; i++) {
        this.model.SelectedTenants.push(tenantIds[i]);
      }

      //tenantSelectionList

      // var _selTenantsObj = this.tenantSelectionList.filter(function (obj) {
      //   return tenantIds.some(function (obj2) {
      //     return obj.value.trim() == obj2.trim();//obj2.Name.trim() == obj;
      //   });
      // });
      //this.model.SelectedTenants.push(_selTenantsObj[0].label);
      this.model.Tenants = (tenants != null && tenants != undefined) ? tenants.toString() : '';
    }
    else {
      this.model.SelectedTenants = [];
      this.model.Tenants = null;

      //tenants are null, it means all tenants will be selected
      this.tenantSelectionList.forEach(_tenant => {
        this.model.SelectedTenants.push(_tenant.value);
      });
    }

  }



  updateRoles() {
    if (!this._sharedService.UserRole) {
      this._sharedService.updateRole();
    }

    this.canAddEditArticle = true;

  }
  populateTenantsList() {
    this._userService.getAllTenantsList()
      .subscribe({
        next: (response) => {
          this.tenantSelectionList = response;
        },
        error: (error) => {
        }
      });
  }


  returntoAction(url: string) {

    setTimeout(() => {

      this.router.navigateByUrl(url);

    },
      2000);
  }

  resetModel() {
    this.model = new articleModel();
    this.model.Status = true;
    this.model.Color = '#0976e3';
    this.model.ArticleSectionName = '';
    this.model.ArticleSectionName = '';
    this.model.ArticleCategoryId = null;
    this.model.ArticleSectionId = null;
    this.model.Title = '';
    this.model.ArticleContentHtml = '';

  }
  btnSubmitClick(form: NgForm) {

    this.isSubmitted = true;
    let article = this.model;
    if (this.model.SelectedTenants == undefined || this.model.SelectedTenants.length == 0) {
      article.Tenants = null;
    }
    else if (this.model.SelectedTenants.length == this.tenantSelectionList.length) {
      article.Tenants = null;
    }
    else {
      article.Tenants = article.SelectedTenants.toString();
    }
    if (this.selectedRoles.length == 0 || !this.model.showRoles) {
      article.UserRoles = null;
    } else {
      //article.UserRoles = this.selectedRoles.map(p => p.Name.trim()).toString();
      article.UserRoles = JSON.stringify(this.selectedRoles);//_selectedArticleRoles;
    }
    if (this.selectedComponents.length == 0) {
      article.ArticleComponents = null;
    } else {
      //article.UserRoles = this.selectedRoles.map(p => p.Name.trim()).toString();
      article.ArticleComponents = JSON.stringify(this.selectedComponents);//_selectedArticleRoles;
    }
    this.updateArticle(article, form)

    /*  this.updateArticle(article,form); */
  }


  updateArticle(body: articleModel, form: NgForm) {
    this._sharedService.showLoader("Processing...");
    this._articleService.updateArticle(body)
      .subscribe({
        next: (response) => {
          this._sharedService.hideLoader();
          this._sharedService.clearToast();
          this.changeButtonsStatus(false);
          this.returntoAction(this.ArticlestPath);
        },
        error: (error) => {
          this._sharedService.hideLoader();
          this._sharedService.clearToast();
          if (error.status === 409) {
            this._sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Article  already exists.' });
          }
          else {
            this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Article should have some text.' });
          }

          this.changeButtonsStatus(false);
        }
      })
  }
  btnCancelClick() {

    this.returntoAction(this.ArticlestPath);

  }
  getArticleSectionbyCategory(event) {

    let ArticleCategoryId = event;
    this._articleService.getArticleSectionbyCategory(ArticleCategoryId)
      .subscribe({
        next: (response) => {
          this.ArticleSectionList = response;
          this.seclectedSectionValue = 0;
        },
        error: (error) => {
        }
      });
  }

  getSectionAndCategoryByCategory() {

    let ArticleCategoryId = this.model.ArticleCategoryId;
    this._articleService.getArticleSectionbyCategory(ArticleCategoryId)
      .subscribe({
        next: (response) => {
          this.model.ArticleSectionId = null
          this.ArticleSectionList = response;
          if (!response) {
            this.seclectedSectionValue = 0;
          }
          else {
            this.seclectedSectionValue = -1;
          }
        },
        error: (error) => {
        }
      });
  }

  getListArticleCategory() {
    this._articleService.getListArticleCatgory(null)
      .subscribe({
        next: (response) => {
          this.ArticleCategoryList = response;
        },
        error: (error) => {
        }
      });
  }

  selectedCategoryVal(ary) {
    let node = 0;
    this.seclectedCategoryValue = -1;
    for (let index = 0; index < ary.length; index++) {

      if (ary[index].value == this.model.ArticleCategoryId.toString()) {
        this.seclectedCategoryValue = node;
        break;

      }
      node = node + 1;
    }

  }


  getArticleSectionById() {
    if (this.model.ArticleCategoryId) {
      let ArticleCategoryId = this.model.ArticleCategoryId;
      this._articleService.getArticleSectionbyCategory(ArticleCategoryId)
        .subscribe({
          next: (response) => {
            this.ArticleSectionList = response;
            this.selectedSectionVal(this.ArticleSectionList);
            this.filterSelectedComponents();
          },
          error: (error) => {
          }
        });
    }
  }


  selectedSectionVal(ary) {
    let j = 0;
    if (!ary) {
      this.seclectedSectionValue = 0;
    }
    else {
      this.seclectedSectionValue = -1;
    }

    for (let i = 0; i < ary.length; i++) {
      if (ary[i].value == this.model.ArticleSectionId.toString()) {
        this.seclectedSectionValue = j;
        break;

      }

      j = j + 1;
    }

  }

  changeButtonsStatus(status: boolean) {
    this.isSubmitted = status;
  }

  deleteArticle(id) {

    this._sharedService.showLoader("Processing...");
    this._articleService.deleteArticle(id)
      .subscribe({
        next: (response) => {
          this._sharedService.hideLoader();
          this._sharedService.clearToast();
          this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Article deleted successfully.' });
          this.returntoAction(this.ArticlestPath);
        },
        error: (error) => {
          this._sharedService.hideLoader();
          this._sharedService.clearToast();

          this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Article is not deleted.' });
        }
      })
  }
  confirm() {
    this._confirmationService.confirm({
      message: 'Are you sure you want to delete this article ?',
      header: 'Warning!',
      //icon: 'fa fa-w',
      accept: () => {
        let id = this.model.ArticleId
        this.deleteArticle(id);
      },
      reject: () => {
      }
    });
  }

  getAllRolesAndGroups() {
    this._userService.getAllRolesAndGroups(this._sharedService.baseUrl, null)
      .subscribe({
        next: (response) => {
          let _allRoles = response;

          if (this.model.UserRoles != null && this.model.UserRoles != undefined) {

            let _selectedRoles = JSON.parse(this.model.UserRoles);// this.model.UserRoles.split(',');
            if (_selectedRoles.length > 0) {
              this.availableRoles = [];
              //this.selectedRoles  = [];


              if (_allRoles == null) _allRoles = [];

              var existing_AssignedViews = _selectedRoles.filter(function (obj) {
                return _allRoles.some(function (obj2) {
                  return obj.Name.trim() == obj2.Name.trim();//obj2.Name.trim() == obj;
                });
              });

              var missing_AvailableViews = _allRoles.filter(function (obj) {
                return !existing_AssignedViews.some(function (obj2) {
                  return obj.Name.trim() == obj2.Name.trim();//obj == obj2.trim();
                });
              });

              this.availableRoles = missing_AvailableViews;
              this.selectedRoles = existing_AssignedViews;
              this.model.showRoles = true;
            }
            else {
              this.availableRoles = _allRoles;
              this.selectedRoles = [];

              this.model.showRoles = false;
            }
          }
          else {
            this.availableRoles = _allRoles;
            this.selectedRoles = [];

            this.model.showRoles = false;
          }
          //this.cRef.markForCheck();
        },
        error: (error) => {
        }
      })
  }

  openAssignedUserRolesDlg() {

    this.displayArticleUserRole = true;
    if (this.model.UserRoles != null && this.model.UserRoles != undefined && this.model.UserRoles != '[]') {
      this.model.showRoles = true;
    }
    else {
      this.model.showRoles = false;
    }

  }

  onSubmit(form: NgForm) {
    let _availableRoles = this.availableRoles;
    let _selectedRoles = this.selectedRoles;

    // get role names only
    let _selectedArticleRoles = this.selectedRoles.map(p => p.Name.trim()).toString();
    this.model.UserRoles = JSON.stringify(this.selectedRoles);//_selectedArticleRoles;
    this.displayArticleUserRole = false;
  }

  btnCancelArticleUserRoleClick(cancelForm: NgForm) {
    this.displayArticleUserRole = false;
    cancelForm.resetForm();
  }

}
