export interface searchType {
    id: number;
    searchType: string;
}

export interface searchTags {
    id: number;
    tag: string;
}

export class AdvanceSearch {
    Condition: string;
    SearchValue: string;
}