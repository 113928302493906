export class EditContainer {

    constructor(
        public ID: string,
        public containerID: number,
        public wotype: string,
        public crsdate: Date,
        public enddate: Date,
        public wonumber: string,
        public generalID: string,
        public description: string,
        public boxtype: string,
        public boxid: string,
        public boxlocation: string,
        public scanneddate: Date,
        public scannedby: string,
        public islive: boolean,
        public isactive: boolean,
        public modifiedby: string,
        public QualityStatusID: string,

        public IncrementValue?: number,
        public StartNumber?: number,
        public NumberOfPages?: number
    ) { }

}
export class DateContainer {

    constructor(
        public ID: string,
        public containerID: number,
        public wotype: string,
        public crsdate: Date,
        public enddate: Date,
        public wonumber: string,
        public generalID: string,

        public IncrementValue?: number,
        public StartNumber?: number,
        public NumberOfPages?: number
    ) { }

}