import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from '../../../shared/services/shared.service';
import { AssetModel } from './asset';
import { SelectItemDto } from 'src/app/shared/classes/SelectItemDto';

@Injectable()
export class AssetService {

    //#region Global Variables
    public AssetTypeAirframe: string = "Airframe";
    public AssetTypeHelicopter: string = "Helicopter";
    public AssetTypeCollection: string = "Collection";
    public AssetTypeItem: string = "Item";

    public AssetLevelParent: string = "Parent";
    public AssetLevelChild: string = "Child";
    //#endregion


    //#region Init

    constructor(private http: HttpClient, private _sharedService: SharedService) {
    }

    //#endregion

    //#region service methods

    getUserAsset(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Asset/GetAsset`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getAssetTreeNode(asn: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Asset/GetAssetTreeNode?asn=${asn}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getUserAircraft(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Asset/GetAircraft`,
            body,
            this._sharedService.getReqOptWithAccessToken());
    }

    getAircraftUser(assetID: any, isSysAdmin:any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Asset/GetAircraftUser?assetID=${assetID}&isSysAdmin=${isSysAdmin}`,
            '',
            this._sharedService.getReqOptWithAccessToken());
    }

    UpdateAircraftUser(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Asset/UpdateAircraftUser`,
            body,
            this._sharedService.getReqOptWithAccessToken());
    }

    addAssetData(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Asset/AddAsset`,
            body,
            this._sharedService.getReqOptWithAccessToken());
    }

    //getAllAssets(apiUrl: string, body: any, reqOption: RequestOptions): Observable<Response> {
    //    return this.http.post(`${apiUrl}/api/Asset/GetAllAsset`, body, reqOption);
    //}

    getAssetbyID(body: any): Observable<AssetModel> {
        return this.http.post<AssetModel>(`${this._sharedService.baseUrl}/api/Asset/Detail?assetID=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    getAssetByASN(body: any): Observable<AssetModel> {
        return this.http.post<AssetModel>(`${this._sharedService.baseUrl}/api/Asset/getAssetByASN?asn=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    
    getAssetsbyParentID(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Asset/SubAssets?parentID=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getAssetByUserRole(): Observable<any> {
        return this.http.get(`${this._sharedService.baseUrl}/api/Asset/GetAssetByUserRole`, this._sharedService.getReqOptWithAccessToken());
    }
    getAssetWithAssetIdByUserRole(): Observable<any> {
        return this.http.get(`${this._sharedService.baseUrl}/api/Asset/GetAssetWithAssetIdByUserRole`, this._sharedService.getReqOptWithAccessToken());
    }
    getAllAirframe(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Asset/GetAllAirframe`, body, this._sharedService.getReqOptWithAccessToken())
    }

    getAllAssetList(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Asset/getAllAssetList`, body, this._sharedService.getReqOptWithAccessToken())
    }

    getAllAssetAndGroupList(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Asset/GetAllAssetAndGroupList`, body, this._sharedService.getReqOptWithAccessToken())
    }

    getAllAircraft(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Asset/GetAllAircraft`, '', this._sharedService.getReqOptWithAccessToken())
    }


    getAllAirframeForView(apiUrl: string, body: any ): Observable<any> {
        return this.http.post(`${apiUrl}/api/Asset/GetAllAirframeForView`, body, this._sharedService.getReqOptWithAccessToken())
    }

    PopulateAssetsOrCollections(assetTypes: string) {
        return this.http.post(`${this._sharedService.baseUrl}/api/Asset/PopulateAssetsOrCollections?assetTypes=${assetTypes}`, '', this._sharedService.getReqOptWithAccessToken());
    }


    updateAsset(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Asset/UpdateAsset`, body, this._sharedService.getReqOptWithAccessToken());
    }

    attachSubAsset(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Asset/AttachSubAsset`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getAllAssetsWithAsn(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Asset/GetAllAssetsWithAsn`, null, this._sharedService.getReqOptWithAccessToken());
    }

    getAllViews(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/View/GetViewsDropDownListByAssetId`, body, this._sharedService.getReqOptWithAccessToken())
    }

    getAssetDependents(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Asset/GetAssetDependents`, body, this._sharedService.getReqOptWithAccessToken());
    }

    moveAsset(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Asset/MoveAsset`, body, this._sharedService.getReqOptWithAccessToken());
    }


    getParentAssetDropdownList(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Asset/GetParentAssetDropdownList`,
            body,
            this._sharedService.getReqOptWithAccessToken());
    }
    getAssetDropdownList(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Asset/GetAssetDropdownList`,
            body,
            this._sharedService.getReqOptWithAccessToken());
    }

    getAssetTypes() {

        let targetModuleList = [
            { value: 'Airframe', label: 'Airframe' },
            { value: 'Helicopter', label: 'Helicopter' },
            { value: 'Collection', label: 'Collection' }
        ];

        return targetModuleList;
    }
    populateAssetTypeDropdownCollection() {
       return  [
            { label: "Engine", value: "Engine" },
            { label: "Landing Gear", value: "LDG" },
            { label: "Propeller", value: "Propeller" },
            { label: "APU", value: "APU" },
            { label: "Airframe", value: "Airframe" },
            { label: "Item", value: "Item" }
        ]
    }

    getAllBillingPlans(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/BillingPlan/GetAllBillingPlans`, '', this._sharedService.getReqOptWithAccessToken())
    }


    updateAssetBillingPlan(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Asset/UpdateAssetBillingPlan`, body, this._sharedService.getReqOptWithAccessToken());
    }
  searchAsset(input: string): Observable<SelectItemDto[] > {
    return this.http.get<SelectItemDto [] >(
        `${this._sharedService.baseUrl}/api/Asset/SearchAssets?input=${input}`,
        this._sharedService.getReqOptWithAccessToken()
      );
    }
  
    //#endregion


}