<div class="jarviswidget-ctrls pdfoperations">
    <div style="cursor: default;" class="btn-group">
        <a class="button-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="true" title="{{templateTitle}}">
            <i class="glyphicon glyphicon-upload"></i>
        </a>
        <ul style="cursor: default;"  class="dropdown-menu pull-right js-status-update">
            <li>
            <!-- <li *ngIf="isShowDataFileOption"> -->
                <a (click)="showUploadDialog()"><i class="fa fa-file-o"></i>&nbsp;Import from Computer (Ctrl+Q)</a>
            </li>
            <li>
            <!-- <li *ngIf="isShowTemplateFileOption"> -->
                <a (click)="showFileGrid()">
                    <i class="fa fa-cloud-upload"></i>&nbsp;Import from App Storage (Ctrl+Y)
                </a>
            </li>

        </ul>
    </div>
</div>


<p-dialog style="cursor: default;"  *ngIf="displayFileGrid"  [(visible)]="displayFileGrid" showEffect="fade" [modal]="true" [resizable]="false">
    <ng-template pTemplate="header" >
        File List
    </ng-template>
    <div id="containerTypelisting" class="no-bottom-padding widget-body">
        <section id="widget-grid2" class="">
            <div style="cursor: default;" class="jqgridboxadminaircraft">
                <ag-grid-angular id="agGridUploadGrid" pagination='false' (rowClicked)='updateSelectedRow($event)'  #agGridUploadGrid [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="gridData" style="overflow-x: hidden;overflow-y: auto; width: 80rem !important;height:500px !important;" class="ag-theme-balham">
                </ag-grid-angular>
            </div>
        </section>
    </div>
    
    <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">           
            <button type="submit" pButton icon="fa fa-check" class="btn btn-primary minzindex" label="Upload" (click)="btnCreateViewFromFileBlobStorage()"></button>
            <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" (click)="btnCancelClickBlobStorage()" label="Cancel"></button>                
        </div>
    </ng-template>
</p-dialog>



<form style="cursor: default;" #addItemForm="ngForm">
    <p-dialog [(visible)]="uploaddisplay" showEffect="fade" [modal]="true" [style]="{width: '550px'}" [resizable]="false">
        <ng-template pTemplate="header" >
            {{dialogTitle}}
        </ng-template>
        <div class="upload-dialog">
            <div id="commAddDlg" class="smart-form" >
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">Source Files</label>
                            <p-fileUpload name="viewfile" #form (onSelect)="onFileSelection($event)" (onRemove)="onRemove($event)" [accept]="acceptfilestype" customUpload="true" uploadHandler="onUploadFileClick($event,addItemForm)" chooseLabel="Browse"  [(ngModel)]="viewFile" [accept]="acceptfilestype" invalidFileTypeMessageSummary="Invalid File(s)"  #feedfile ngDefaultControl>
                            </p-fileUpload>                         
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                    <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex" (click)="onUploadFileClick($event,addItemForm)" label="Upload"></button>
                    <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" (click)="btnCancelClick(form)" label="Cancel"></button>                
            </div>
        </ng-template>

    </p-dialog>
</form>