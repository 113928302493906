//Components
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';

//Services
import { SharedService } from '../../../shared/services/shared.service';

import { Dropdown } from 'primeng/dropdown';
import { NgForm } from '@angular/forms';
import { GridOptions } from "ag-grid-community";
import { AssetService } from '../../services/asset/asset.service';
import { TagService } from '../../../shared/services/tag/tag.service';
import { DocumentTypeService } from '../../../admin/services/document-type/document-type.service';
import { BuilderSearchService } from '../../services/builderSearch/builderSearch.service';
import { TaskService } from '../../services/task/task.service';
import { ViewService } from '../../services/view/view.service';
import { IConfig } from '../../../shared/helpers/config';
import * as moment from 'moment/moment';
import { BuilderSearchConfig, AzureSearchFilter, AzureSearchKeyword, SearchPatternOption, searchTags, searchType, SelectItem } from '../../services/builderSearch/builderSearch';
import { SearchService } from '../../services/search/search.service';


@Component({
    selector: 'add-builderSearch',
    templateUrl: './add-builderSearch.component.html',
    providers: [AssetService, DocumentTypeService, TagService, BuilderSearchService, ViewService, TaskService, ViewService, SearchService]


})
export class AddBuilderSearchComponent implements OnInit {



    //@Input() builderSearchID: string;

    public statusConfigPath: string = "/admin/configview";
    public tab: string = "auto";
    public canAddEditBuilder: boolean = false;
    public canEditBuilder: boolean = false;
    isSubmitted: boolean = false;
    display: boolean = false;

    builderSearchConfig: BuilderSearchConfig;
    azureSearchKeywords: Array<AzureSearchKeyword>;
    azureSearchFilter: AzureSearchFilter;
    searchPatternOption: SearchPatternOption;


    isAdd: boolean;
    btnlabel: string;
    isEditVisible: boolean = true;
    isAddVisible: boolean = true;
    dialogTitle: string;


    //region dropdown value fields //

    statusViews: SelectItem[];

    searchQueryPatterns: searchType[];
    keywordConditions: any[];
    viewFields: any[];

    assets: SelectItem[] = [];
    tags: SelectItem[];
    documentTypes: SelectItem[];
    searchDatabaseList: SelectItem[] = [];

    viewExecutionPeriorities: SelectItem[];
    searchResultSetRows: SelectItem[];

    public viewId: string = "";
    public viewName: string = "";
    public viewDisplayName = "";
    public builderSearchID: string;

    //---------------------//


    dateSeparator = "";
    dateFormat = "";
    dateFormatCalender = "";
    configuration: IConfig[] = [];
    private day: number;
    private month: number;
    private year: number;


    //region object binded fields//

    //assetASN: string;
    //viewExecutionPeriority: string = "0";
    //searchQueryPattern: number = 1;
    //queryResultRows: string = "10";

    //---------------------------//

    constructor(private _sharedService: SharedService, private router: Router, private route: ActivatedRoute, private _assetService: AssetService, private _documentTypeService: DocumentTypeService, private _tagService: TagService, private _builderSearchService: BuilderSearchService, private _viewService: ViewService, private _taskService: TaskService, private adminSearchService: SearchService) {
        if (!this._sharedService.isAuthUser()) {
            this.router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.updateRoles();
        }
    }
    ngOnInit() {
        this.init();
        this.configuration = this._sharedService.getConfiguration();
        this.setCalenderDateFormat();
    }


    init() {
        this.builderSearchID = this.route.snapshot.params['id'] ? this.route.snapshot.params['id'] : '';
        this.viewId = this.route.snapshot.params['vID'] ? this.route.snapshot.params['vID'] : '';
        this.resetViewModels();
        this.populateViewFields();
        this.populateKeywordConditions();
        this.populateSearchQueryPatterns();
        this.populateDocTypeDropdown();
        this.populateTagsComplete();
        this.populateAsset();
        this.populateSearchResultRowsSelection();
        this.populateViewExecutionSettings();
        this.populateSearchDatabase();
        if (this.builderSearchID) {
            this.showEditDialog();
        }
        else {
            this.showAddDialog();
        }

        if (this.viewId) {
            this.loadViewDescription(this.viewId);
        }



    }
    

    populateSearchDatabase() {
        this.searchDatabaseList = [];

        this.adminSearchService.getSearchDatabase().subscribe({
            next: (response) => {
            let res = response;
            this.searchDatabaseList = res;
            },
            error : (error)=>{
                console.log(error)
            }
        });
    }

    Toggle_Fields(field: string, value: string) {
        switch (field) {
            case "Asset":
                this.azureSearchFilter.AssetControlMode = value;
                this.azureSearchFilter.AssetASN = null;
                break;
            case "FromDate":
                this.azureSearchFilter.FromDateMode = value;
                this.azureSearchFilter.FromDate = null;
                break;
            case "ToDate":
                this.azureSearchFilter.ToDateMode = value;
                this.azureSearchFilter.ToDate = null;
                break;
            case "DocType":
                this.azureSearchFilter.DocumentTypeMode = value;
                this.azureSearchFilter.DocumentTypes = [];
                break;
            case "DocNumber":
                this.azureSearchFilter.DocumentNoMode = value;
                this.azureSearchFilter.DocNo = null;
                break;
            case "WONumber":
                this.azureSearchFilter.WoNoMode = value;
                this.azureSearchFilter.WONo = null;
                break;
            case "Tag":
                this.azureSearchFilter.TagsMode = value;
                this.azureSearchFilter.Tags = [];
                break;
        }
    }
    populateAsset() {
        let userRole: string = localStorage.getItem('roles').trim();
        let body = { UserRole: userRole };
        this._assetService.getAllAssetsWithAsn().subscribe({
            next: (response) => {
                this.assets = response;
            },
            error: (error) => {
                this.assets=[]
                console.log(error)
            }
        })
    }
    populateSearchQueryPatterns() {
        this.searchQueryPatterns = [
            // { id: 1, searchType: "Exact" },
            // { id: 2, searchType: "Begin" }

            { id: 1, searchType: "Exact" },
            { id: 4, searchType: "Partial" },
            { id: 2, searchType: "Begin" },
            { id: 3, searchType: "End" }
        ];
    }
    populateDocTypeDropdown() {
        //populate Doc Types
        this._documentTypeService.getDocumentTypes("")
        .subscribe({
            next: (response) => {
                this.documentTypes = [];
                this.documentTypes = response;
            },
            error: (error) => {
                this.documentTypes = [];
                console.log(error);
            }
        })
    }
    populateTagsComplete() {

        this._tagService.getAllTags().subscribe({
            next: (response) => {
                this.tags = [];
                this.tags = response;
            },
            error: (error) => {
                this.tags = [];
                console.log(error);
            },
        })

    }
    populateSearchResultRowsSelection() {

        this.searchResultSetRows = [];
        this.searchResultSetRows = [
            { label: "All", value: "1000" },
            { label: "First 1", value: "1" },
            { label: "First 2", value: "2" },
            { label: "First 3", value: "3" },
            { label: "First 4", value: "4" },
            { label: "First 5", value: "5" },
        ]


    }
    populateViewExecutionSettings() {

        this.viewExecutionPeriorities = [];
        this.viewExecutionPeriorities = [
            { label: "Execute (Next ASQ)", value: "Find and Move" },
            { label: "Terminate (On Record Found)", value: "Find and Stop" }
        ]


    }
    populateKeywordConditions() {
        this.keywordConditions = [{ label: "AND", value: "AND" },
        { label: "OR", value: "OR" },
        { label: "NOT", value: "NOT" },
        ];
    }
    populateViewFields() {
        //this.viewFields = [{ label: "Text1", value: "{Text1}" },
        //{ label: "Text2", value: "{Text2}" },
        //{ label: "Text3", value: "{Text3}" },
        //{ label: "ASN", value: "{ASN}" },
        //{ label: "TaskType", value: "{TaskType}" }
        //];

        this.viewFields = [];
        let viewID = this.viewId
        this._taskService.getStatusTaskColumnsbyViewID(viewID)
        .subscribe({
            next: (response) => {
                this.viewFields = response;
                if (this.viewFields.indexOf(f => f.label == 'TaskType')) {
                    let taskType = this.viewFields.find(f => f.label == 'TaskType');
                    taskType.label = 'DataType';
                }
        
                if (this.viewFields.indexOf(f => f.label == 'TaskID')) {
                    let taskID = this.viewFields.find(f => f.label == 'TaskID');
                    taskID.label = 'ID';
                }
            },
            error: (error) => {
                console.log(error)
            }
        });
    }




    addSearchKeyword() {

        let azureSearchKeyword = new AzureSearchKeyword();
        this.azureSearchKeywords.push(azureSearchKeyword);

    }

    uniqueValue() {
        // Math.random should be unique because of its seeding algorithm.
        // Convert it to base 36 (numbers + letters), and grab the first 9 characters
        // after the decimal.
        return '_' + Math.random().toString(36).substr(2, 9);
    };


    trackByIndex(index: number, value: number) {
        return index;
    }


    deleteInstance(objKeyWord: any) {

        let index: number = this.azureSearchKeywords.indexOf(objKeyWord);
        this.azureSearchKeywords.splice(index, 1);
        //let col: Column = new Column();
        //let col: Column = this.model.Columns.find(c => c.FieldOrder == fieldOrder);
        //    this.model.Conditions = this.model.Conditions.filter(c => c.Key != objCond.Key);
    }

    btnSubmitClick(form: NgForm) {

        this.changeButtonsStatus(true);

        let azuresearchConfig = this.builderSearchConfig;
        azuresearchConfig.ViewId = this.viewId; //Number(this.viewId);
        azuresearchConfig.ViewName = this.viewName;
        azuresearchConfig.AzureSearchFilter = this.azureSearchFilter;
        azuresearchConfig.AzureSearchKeywords = this.azureSearchKeywords;
        this.FormatValuestoISOFormat(azuresearchConfig.AzureSearchFilter);

        //Ignore empty fieldName
        azuresearchConfig.AzureSearchKeywords = azuresearchConfig.AzureSearchKeywords.filter(k => k.TokenValue)

        //if (azuresearchConfig.SearchId != null && azuresearchConfig.SearchId > 0) {
        if (azuresearchConfig.SearchId != null && azuresearchConfig.SearchId != null) {
            this.updateBuilderSearch(azuresearchConfig, form);
        }
        else {
            this.addBuilderSearch(azuresearchConfig, form);
        }


    }
    addBuilderSearch(body: BuilderSearchConfig, form: NgForm) {
        this.changeButtonsStatus(true);
        this._sharedService.showLoader("Processing...");
        this._builderSearchService.addBuilderSearch(body)
        .subscribe({
            next: (response) => {
                this._sharedService.hideLoader();
                this.display = false;
                //     this.resetViewModels();
                form.resetForm();
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Auto search Configuration has been added succesfully.' });
                this.returntoAction(this.statusConfigPath, this.viewId);
            },
            error: (error) => {
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                if (error.status === 409) {
                    this._sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Search name already exists.' });
                }
                else {
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Auto search Configuration not added, please try again.' });
                }
                this.display = false;
                //this.resetViewModels();
                this.changeButtonsStatus(false);
            }
        });

    }
    updateBuilderSearch(body: BuilderSearchConfig, form: NgForm) {

        this.changeButtonsStatus(true);
        this._sharedService.showLoader("Processing...");
        this._builderSearchService.updateBuilderSearch(body)
        .subscribe({
            next: (response) => {
                this._sharedService.hideLoader();
                this.display = false;
                //this.resetViewModels();
                form.resetForm();
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Auto search Configuration has been updated succesfully.' });
                this.returntoAction(this.statusConfigPath, this.viewId);
            },
            error: (error) => {
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                if (error.status === 409) {
                    this._sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Search name already exists.' });
                }
                else {
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Auto search Configuration is not updated, please try again.' });
                }
                this.display = false;
                //  this.resetViewModels();
                this.changeButtonsStatus(false);
            }
        });

    }
    btnCancelClick(cancelForm: NgForm) {
        this.display = false;
        cancelForm.resetForm();
        this.resetViewModels();
    }
    resetViewModels(): void {

        this.builderSearchConfig = new BuilderSearchConfig();
        this.azureSearchFilter = new AzureSearchFilter();
        this.azureSearchKeywords = this.builderSearchConfig.AzureSearchKeywords;


    }
    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }
    updateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }
        this.canAddEditBuilder = this._sharedService.UserRole.Admin_Status_Builder;
        this.canEditBuilder = this._sharedService.UserRole.Status_Builder_Readonly;
    }

    returntoAction(url: string, param: string) {

        setTimeout(() => {

            //  this.router.navigateByUrl(url);
            this.router.navigate([url, param, this.tab]);

        },
            2000);
    }




    loadBuilderSearchData(builderSearchID: string): void {

        let userRole: string = localStorage.getItem('roles').trim();

        this._builderSearchService.getBuilderSearchbyID(builderSearchID)
        .subscribe({
            next: (response) => {
                //   this.gridOptions.api.sizeColumnsToFit();
                //  this.model = response;
                var searchBuilderConfig = response;
                this.builderSearchConfig = searchBuilderConfig;
                this.azureSearchFilter = searchBuilderConfig.AzureSearchFilter;
                this.FormatCalendarDateValues(this.azureSearchFilter);
                this.azureSearchKeywords = searchBuilderConfig.AzureSearchKeywords;
            },
            error: (error) => {
                console.log(error)
            }
        });
    }



    FormatValuestoISOFormat(azureSearch: AzureSearchFilter): void {

        //this.azureSearchFilter.FromDate = this.convertToISOFormat(this.azureSearchFilter.FromDate);
        //this.azureSearchFilter.ToDate = this.convertToISOFormat(this.azureSearchFilter.ToDate);
        if (Date.parse(this.azureSearchFilter.FromDate)) {
            this.azureSearchFilter.FromDate = this.convertToISOFormat(this.azureSearchFilter.FromDate);
        }
        if (Date.parse(this.azureSearchFilter.ToDate)) {
            this.azureSearchFilter.ToDate = this.convertToISOFormat(this.azureSearchFilter.ToDate);
        }

    }

    FormatCalendarDateValues(azureSearch: AzureSearchFilter): void {
        if (Date.parse(this.azureSearchFilter.FromDate)) {
            this.azureSearchFilter.FromDate = this.getFormatedDate(this.azureSearchFilter.FromDate);
        }
        if (Date.parse(this.azureSearchFilter.ToDate)) {
            this.azureSearchFilter.ToDate = this.getFormatedDate(this.azureSearchFilter.ToDate);
        }

    }


    getCalendarDate(date?: any): string {
        if (date != null) {
            let _date = new Date(Date.parse(date));
            var formattedDate = moment(_date).format(this.dateFormat.toUpperCase());
            return formattedDate
        }
        else {
            return null;
        }
    }


    convertToISOFormat(date: any) {
        if (date) {
            return moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        } else {
            return null;
        }
    }


    getFormatedDate(date?: any): string {
        if (date != null) {
            let dateString = date.substr(0, 10);
            let dateParts = moment(dateString).format(this.dateFormat.toUpperCase()).split(this.dateSeparator);
            let day = Number(dateParts[Number(this.day)].substring(0, 2));
            let month = Number(dateParts[Number(this.month)]) - 1;
            let year = Number(dateParts[Number(this.year)]);
            let cellDate = new Date(year, month, day);
            let formattedDate = moment(cellDate).format(this.dateFormat.toUpperCase());
            return formattedDate;

        }
        else {
            return null;
        }
    }

    loadViewDescription(viewID: string): void {

        let userRole: string = localStorage.getItem('roles').trim();

        this._viewService.getViewDescriptionById(viewID)
        .subscribe({
            next: (response) => {
                var viewDescription = response;
                this.viewId = viewDescription.ViewSettingId;
                this.viewName = viewDescription.ViewName;
            },
            error: (error) => {
                console.log(error)
            }
        });
    }



    initializeForm(builderSearchID: string): void {
        if (builderSearchID) {
            this.loadBuilderSearchData(builderSearchID);
        }

    }

    showEditDialog(): void {

        if (this.builderSearchID) {
            this.isAdd = false;
            this.dialogTitle = "Auto Search Configuration";
            this.btnlabel = "Update";
            this.display = true;
            this.initializeForm(this.builderSearchID);
            //assetID = null;
        }

    }

    showAddDialog(): void {

        this.isAdd = true;
        this.dialogTitle = "Auto Search Configuration";
        this.btnlabel = "Add";
        this.display = true;
    }



    setCalenderDateFormat() {
        this.dateFormat = this.configuration.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
        this.dateSeparator = this.configuration.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
        this.day = Number(this.configuration.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
        this.month = Number(this.configuration.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
        this.year = Number(this.configuration.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
        let dateParts: string[] = this.dateFormat.split(this.dateSeparator);

        if (this.year == 0) {
            this.dateFormatCalender = (dateParts[this.year].substr(0, 2) + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.day]).toLowerCase();
        }
        else if (this.year == 2) {
            if (this.month == 1) {
                this.dateFormatCalender = (dateParts[this.day] + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
            }
            else if (this.month == 0) {
                this.dateFormatCalender = (dateParts[this.month] + this.dateSeparator + dateParts[this.day] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
            }
        }
        else {
            this.dateFormatCalender = "yy-mm-dd"
        }

    }
}
