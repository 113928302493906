<!-- MAIN PANEL -->
<div role="main">

    <!-- MAIN CONTENT -->
    <div id="content" style="opacity: 1;">
        <section id="widget-grid-main" class="well" style="height:91vh;">
            <div id="content">
                <!-- row -->
                <div class="row">
                    <div class="col-sm-12">
                        <div class="well padding-10" style="background-color: #fff;margin-top: 20px;">
                            <div class="row">
                                <div class="col-sm-12" style="height: 80vh !important;overflow-y: scroll;">
                                    <div class="row">
                                        <!--<div class="col-md-1">
                                            <img src="/Content/img/help/icon_chrome.jpg" class="img-responsive" alt="img">
                                        </div>-->
                                        <div class="col-md-12 padding-left-0">
                                            <h3 class="margin-top-0">
                                                <a href="javascript:void(0);">How to send files to your ROAM Processing Team </a>
                                                <br>
                                            </h3>
                                            <p>
                                                <iframe allowFullScreen frameborder="0" height="350" mozallowfullscreen src="https://player.vimeo.com/external/258820117.hd.mp4?s=eb6745b62c44f2eae31aa7f9ca32873f7f34a422&profile_id=175" webkitAllowFullScreen width="640"></iframe>
                                            </p>
                                        </div>
                                        
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 padding-left-0">
                                            <h3 class="margin-top-0">
                                                <a href="javascript:void(0);">How to upload files into ROAM </a>
                                                <br>
                                            </h3>
                                            <p>
                                                <iframe allowFullScreen frameborder="0" height="350" mozallowfullscreen src="https://player.vimeo.com/external/258819653.hd.mp4?s=cf070e61c5b293bdb88751b73532efb8cdc9674b&profile_id=175" webkitAllowFullScreen width="640"></iframe> 
                                            </p>
                                        </div>
                                        </div>
                                        <hr>
                                        <div class="row">

                                            <div class="col-md-12 padding-left-0">
                                                <h3 class="margin-top-0">
                                                    <a href="javascript:void(0);">Managing Containers </a>
                                                    <br>
                                                </h3>
                                                <p>
                                                    <iframe allowFullScreen frameborder="0" height="350" mozallowfullscreen src="https://player.vimeo.com/external/258994757.hd.mp4?s=5b78d1781cdb6f328f443f404af35edb7b5afdd1&profile_id=175" webkitAllowFullScreen width="640"></iframe>
                                                </p>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row">

                                            <div class="col-md-12 padding-left-0">
                                                <h3 class="margin-top-0">
                                                    <a href="javascript:void(0);">Log number checker tool</a>
                                                    <br>
                                                </h3>
                                                <p>
                                                    <iframe allowFullScreen frameborder="0" height="350" mozallowfullscreen src="https://player.vimeo.com/external/341584778.hd.mp4?s=e976d1878b239380f2f3eedc4f8b76a7bbe482b8&profile_id=175" webkitAllowFullScreen width="640"></iframe>
                                                </p>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row">

                                            <div class="col-md-12 padding-left-0">
                                                <h3 class="margin-top-0">
                                                    <a href="javascript:void(0);">Re-order document list</a>
                                                    <br>
                                                </h3>
                                                <p>
                                                    <iframe allowFullScreen frameborder="0" height="350" mozallowfullscreen src="https://player.vimeo.com/external/341589191.hd.mp4?s=e4645bd897dc3994a54e8400e8f6eb484f6e17df&profile_id=175" webkitAllowFullScreen width="640"></iframe>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
