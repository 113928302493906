//Components
import { Component, OnInit, Input, OnDestroy,ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { GridOptions,GridApi } from "ag-grid-community";

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { SelectItem } from 'primeng/api';
import { ViewDataTypeService } from '../../services/view-data-type/view-data-type.service';;
import { ViewDataTypeModel, OperationMode } from './view-data-type';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
var _router_: Router;

@Component({
    selector: 'view-data-type',
    templateUrl: './view-data-type.component.html',
    providers: [ViewDataTypeService]

})

export class ViewDataTypeComponent implements OnInit, OnDestroy {
    display: boolean = false;
    public gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[];
    public hideViewDataTypeMessage: boolean = false;
    public canAddEditViewDataType: boolean = false;

    selectedViewDataTypeID: string = null;
    
    public totalCount: number;
    public model: ViewDataTypeModel;
    public isSubmitted: boolean = false;
    TagList: SelectItem[] = [];
    public dialogTitle: string = 'Add View Data Type';
    public lblsubmitButton: string = 'Add';
    public operationMode: OperationMode = 1;
    public readonlyMode: boolean = false;
    public appHotkeys:  Hotkey[] = [];

    //#region Initialization of Component
    constructor(public _sharedService: SharedService, public _router: Router, public _viewDataTypeService: ViewDataTypeService, private _hotkeysService: HotkeysService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.UpdateRoles();
            _router_ = this._router;
            this.setHotKeys();
            this.navigateToNextCell=this.navigateToNextCell.bind(this);
        }
    }
    setHotKeys() {

        let hotKeyObj : Hotkey = null;

        //Add View Data Type
        hotKeyObj = new Hotkey('ctrl+a', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/viewdatatype")
            {
                this.btnAddClick();
            }
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

        
        //Edit ViewData Type
        hotKeyObj = new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/viewdatatype")
            {
                this.btnEditClick();
            }
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
        hotKeyObj = new Hotkey('enter', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/viewdatatype")
            {
                this.btnEditClick();
            }
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

    }
    ngOnInit() {
        this.model = new ViewDataTypeModel();
        if (this.canAddEditViewDataType) {
            this.gridOptions = <GridOptions>
                {
                    rowSelection: 'single',

                    onGridReady: () => {
                        this.setUserGridColumns();
                        try {
                            this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridViewDataType'));
                            this.gridOptions.api.hideOverlay();
                        } catch (e) {
                            console.log(e);
                        }
                    },
                    //suppressContextMenu: true,
                    pagination: true,
                    paginationPageSize: 20,
                    getContextMenuItems: this.getCustomContextMenuItems,
                    context: {
                        gridContext: this
                    },
                    defaultColDef: {
                        sortable: true,
                        filter:true
                    }
                
                };
           // this.getCategoryList();
            this.populateViewDataTypeGrid();
        }
    }
    ngOnDestroy() {
        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
    }
    //#endregion
   
    //#region Ag Grid Population Event
    populateViewDataTypeGrid(): void {

        this._viewDataTypeService.getAllViewDataTypeData(null)
        .subscribe({
            next: (response) => {
                this.gridOptions.api.sizeColumnsToFit();
                this.gridData = [];
                this.gridData = response;
                this.totalCount = response.length;
            },
            error: (error) => {
                this.gridData = [];
                this.totalCount = 0;
            }
        })
    }
    //#endregion
    //#region Set Columns of Browse Ag Grid
    setUserGridColumns(): void {
        this.columnDefs = [];
        this.columnDefs =
            [
            { headerName: 'View Data Type', field: 'Name', menuTabs: ['filterMenuTab'], cellRenderer: this.tagsCellRenderer },    
            { headerName: 'Description', field: 'Description', menuTabs: ['filterMenuTab'] },
                {
                    headerName: 'Status', field: 'Status', filter:false, suppressMenu: true, valueFormatter: function (params: any) {
                        if (params.value == true) {
                            return "Active";
                        }
                        else {
                            return "Inactive";
                        }
                    }
                }
            ];

    }
    tagsCellRenderer(params: any) {
        let html: string = '';

        if (params.value) {
            let viewdata: string = params.value.trim();
            html = html + '<span class="padding-2 label" style= "background-color:' + params.data.CSSClass + ';">' + viewdata + '</span>';

            // if (viewdata == 'Suggested - no document found' || viewdata == 'Suggested - partial documents found' || viewdata == 'Suggested - documents found' || viewdata == 'Suggested - not applicable')
            // {
            //     html = html + '<span class="padding-2 label" style="color:' + params.data.CSSClass + ';">' + viewdata + '</span>';
            // }
            // else {
            //     html = html + '<span class="padding-2 label" style= "background-color:' + params.data.CSSClass + ';">' + viewdata + '</span>';
            // }
            

        }

        var eDiv = document.createElement('div');
        eDiv.innerHTML = html

        return eDiv;
    }

    //#endregion

    //#region Ag-Grid Events
    updateSelectedRow(event: any) {
        this.selectedViewDataTypeID = null;
        this.selectedViewDataTypeID = event.data.ViewDataTypeID;
    }
    //#endregion

    //#region Edit User Events
    containerRowDoubleClicked(event: any) {
        if (this.canAddEditViewDataType)
        {
            this.selectedViewDataTypeID = null;
            this.selectedViewDataTypeID = event.data.ViewDataTypeID;
            this.btnEditClick();
        }
    }
    btnEditClick() {
        let selectedRows=this.gridOptions.api.getSelectedRows();
        if (this.selectedViewDataTypeID && (selectedRows && selectedRows.length>0)) {
            this.operationMode = 2;
            this.dialogTitle = 'Edit View Data Type';
            this.lblsubmitButton = 'Update';

            this._viewDataTypeService.getViewDataTypeByID(this.selectedViewDataTypeID)
            .subscribe({
                next: (response) => {
                    let data = response;
                    this.model = new ViewDataTypeModel();
                    this.model.CSSClass = data.CSSClass.trim();
                    this.model.ViewDataTypeID = data.ViewDataTypeID;
                    this.model.Description = data.Description?data.Description.trim():data.Description;
                    this.model.Status = data.Status;
                    this.model.Name = data.Name.trim();
                    this.DPFStatusForReadonlyMode(data.Name.trim());
                    this.showHideDialogue(true);               
                },
                error: (error) => {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'View Data Type not added, please try again.' });
        
                    this.showHideDialogue(false);
                }
            });
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No View Data Type Selected', detail: 'Please select a record.' });
        }
    }
    //#endregion
    //#region Add User Events
    btnAddClick() {
        this.operationMode = 1;
        this.dialogTitle = 'Add View Data Type';
        this.lblsubmitButton = 'Add';
        this.model = new ViewDataTypeModel();

        this.model.Status = true;
        this.model.CSSClass = '#0976e3';
        this.readonlyMode = false;
        this.showHideDialogue(true);
    }

    onViewDataTypeChange(event: any){
        this.hideViewDataTypeMessage = true;
    }
    //#endregion

    //#region User Roles Assignment
    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }

        this.canAddEditViewDataType = this._sharedService.UserRole.Admin_View_Data_Type;
    }
    //#endregion

    //#region Cancel Dialogue
    btnCancelClick(cancelForm: NgForm) {
        this.showHideDialogue(false);
        this.readonlyMode = false;
        this.model = new ViewDataTypeModel();
        cancelForm.resetForm();
    }
    //#endregion
    //#region Helper Methods
    showHideDialogue(flag: boolean) {
        this.display = flag;
    }
    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }

    DPFStatusForReadonlyMode(viewDataType:string)
    {
        if (viewDataType == 'Suggested - no document found' || viewDataType == 'Suggested - partial documents found' || viewDataType == 'Suggested - documents found' || viewDataType == 'Suggested - not applicable')
        {
            this.readonlyMode = true;
        }
        else
        {
            this.readonlyMode = false;
        }

    }
    //#endregion

    //#region form submit
    onSubmit(form: NgForm) {
        this.changeButtonsStatus(true);
        this._sharedService.showLoader("Processing...");
        if (this.operationMode == 1) {
            let body: any =
                {
                    ViewDataTypeID: 0,
                    Status: this.model.Status,
                    Name: this.model.Name,
                    CSSClass: this.model.CSSClass,
                    Description: this.model.Description
                }
            this._viewDataTypeService.addUpdateViewDataType(body)
            .subscribe({
                next: (response) => {
                    this.showHideDialogue(false);
                    form.resetForm();
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'View Data Type added succesfully.' });
                    this.changeButtonsStatus(false);
                    this.populateViewDataTypeGrid();
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();            
                    if (error.status === 409) {
                        this._sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'View Data Type already exists.' });
                    }
                    else {
                        this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'View Data Type not added, please try again.' });
                    }
                    this.showHideDialogue(false);
                    this.model = new ViewDataTypeModel();
                    form.resetForm();
                    this.changeButtonsStatus(false);
                }
            });
        }
        else if (this.operationMode == 2) {
            let body: any =
                {
                    ViewDataTypeID: this.selectedViewDataTypeID,
                    Status: this.model.Status,
                    Name: this.model.Name,
                    CSSClass: this.model.CSSClass,
                    Description: this.model.Description
                }
            this._viewDataTypeService.addUpdateViewDataType(body)
            .subscribe({
                next: (response) => {
                    this.showHideDialogue(false);
                    form.resetForm();
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'View Data Type updated succesfully.' });
                    this.changeButtonsStatus(false);
                    this.populateViewDataTypeGrid();
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    if (error.status === 409) {
                        this._sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'View Data Type already exists.' });
                    }
                    else {
                        this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'View Data Type not updated, please try again.' });
                    }
                    this.showHideDialogue(false);
                    this.model = new ViewDataTypeModel();
                    form.resetForm();
                    this.changeButtonsStatus(false);
                }
            });
        }



    }
    //#endregion

    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];

        if (params.context.gridContext.canAddEditViewDataType) {
            result.push({
                name: "Add View Data Type",
                action: function () {
                    params.context.gridContext.btnAddClick()
                },
                icon: params.context.gridContext._sharedService.addIconHTMLasString,
            });

            result.push({
                name: "Edit View Data Type",
                action: function () {
                    params.context.gridContext.selectedViewDataTypeID = null;
                    if (params.node) {
                        params.context.gridContext.selectedViewDataTypeID = params.node.data.ViewDataTypeID;
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext.btnEditClick()
                    }                  
                },
                icon: params.context.gridContext._sharedService.editIconHTMLasString,
            });

            let childResult: any[] = [];
            childResult.push({
                name: 'Excel',
                action: function () {
                    
                    params.api.exportDataAsExcel();
                    
                },
                icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
            });

            childResult.push({
                name: 'CSV',
                action: function () {
                    params.api.exportDataAsCsv();
                },
                icon: params.context.gridContext._sharedService.saveLogFeedIconHTMLasString,
            });

            result.push({
                name: 'Download',
                action: function () {


                },
                icon: params.context.gridContext._sharedService.downloadsFeedIconHTMLasString,
                subMenu: childResult,
            });
			
        }
        return result;
    }
    navigateToNextCell(params: any) {
        var previousCell = params.previousCellPosition;
        var suggestedNextCell = params.nextCellPosition;
        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        switch (params.key) {
            case KEY_DOWN:
                var nextRowIndex = previousCell.rowIndex + 1;
                var renderedRowCount = this.gridOptions.api.getModel().getRowCount();
                if (nextRowIndex >= renderedRowCount) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedViewDataTypeID = null;
                    this.selectedViewDataTypeID = rowNode.data.ViewDataTypeID;
                        return {
                        rowIndex: nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_UP:
                var _nextRowIndex = previousCell.rowIndex - 1;
                if (_nextRowIndex < 0) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedViewDataTypeID = null;
                    this.selectedViewDataTypeID = rowNode.data.ViewDataTypeID;
                        return {
                        rowIndex: _nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw 'this will never happen, navigation is always on of the 4 keys above';
        }
    }
    
}