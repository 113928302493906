<style>
  span.p-dropdown-label.p-inputtext {
    padding: 8px 0 !important;
  }
</style>
<p-confirmDialog header="Confirmation" icon="fa fa-exclamation-triangle"></p-confirmDialog>

<div>
  <!--style="height:1000px;overflow:scroll"-->
  <div class="row addaircraftcls">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" id="col1">
        <h1 class="page-title-user txt-color-blueDark">

          <!-- PAGE HEADER -->
          <i class="glyphicon glyphicon-pencil"></i>
          <a routerLink="/help/articles">Help Content Management </a>
          <span>
            &gt;
            <span id="page-mode">{{screenName}}</span>
          </span>
        </h1>
      </div>
      <article class="col-sm-12 col-md-12 col-lg-12" id="col2">
          <!-- Widget ID (each widget will need unique ID)-->

          
          <form form (ngSubmit)="btnSubmitClick(ArticleForm)" #ArticleForm="ngForm">
              <div class="jarviswidget" id="wid-id-0" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false">

                <header>
                  <span class="widget-icon"><i class="fa fa-pencil-square-o"></i></span>
                  <h2>{{screenName}}</h2>
        
                </header>

                  <!-- widget div-->
                  <div class="widgetdiv">
                      <!-- widget content -->
                      <div class="widget-body" style="height: 78vh;overflow-y: scroll;overflow-x:hidden;">
                          <!-- widget grid -->
                          <section id="widget-grid0" class="">
                              <div class="navbar-form form-group">
                                  <!--<ul id="myTab1" class="nav nav-tabs bordered">
                                      <li class="active">
                                          <a href="#s1" data-toggle="tab" aria-expanded="true">Airframe</a>
                                      </li>
                                  </ul>-->
                                  <div class="tab-content">
                                      <!--id="myTabContent1"-->
                                      <div class="tab-pane fade active in" id="s1">
                                          <div class="smart-form">


                                                  <div class="row">
                                                    <div class="form-group col-sm-5">
                              
                                                      <fieldset>
                                                        <label class="label">
                                                          Title<span [hidden]="articleTitile?.valid || model.Title" class="text-danger">
                                                            *
                                                          </span>
                              
                                                        </label>
                                                        <label class="input">
                              
                                                          <input name="articleTitile" id="articleTitile" required [(ngModel)]="model.Title"
                                                            #articleTitile="ngModel" type="text" placeholder="Article Title">
                                                        </label>
                                                      </fieldset>
                                                    </div>
                                                    <div class="form-group col-sm-2">
                                                      <fieldset>
                                                        <label class="label">Status</label>
                                                        <label class="checkbox">
                                                          <span class="onoffswitch tablIndexFocus onoffswitch-Width">
                                                            <input type="checkbox" name="onoffswitch" [(ngModel)]="model.Status"
                                                              class="onoffswitch-checkbox" checked="checked" id="isMactive">
                                                            <label class="onoffswitch-label" for="isMactive"><span class="onoffswitch-inner"
                                                                data-swchon-text="Active" data-swchoff-text="Inactive"></span><span
                                                                class="onoffswitch-switch onoffswitch-switch-Right"></span></label>
                                                          </span>
                                                        </label>
                                                      </fieldset>
                                                    </div>
                              
                                                    <div class="form-group col-sm-2">
                                                      <fieldset>
                                                        <label class="label">
                                                          Color Code<span
                                                            [hidden]="(addeditTagsColorCode?.valid && addeditTagsColorCodeEditor?.valid)|| model.Color"
                                                            class="text-danger">
                                                            *
                                                          </span>
                                                        </label>
                                                        <p-colorPicker appendTo="body" id="addeditTagsColorCode" name="addeditTagsColorCode" [(ngModel)]="model.Color"
                                                          #addeditTagsColorCode="ngModel"></p-colorPicker>
                                                        <input id="addeditTagsColorCodeEditor"  class="color-picker-input"  type="text" name="addeditTagsColorCodeEditor" required
                                                          [(ngModel)]="model.Color" #addeditTagsColorCodeEditor="ngModel" style="width:30%">
                                                      </fieldset>
                                                    </div>
                              
                                                    <div class="form-group col-sm-3">
                              
                                                      <fieldset>
                                                        <label class="label">
                                                          Order<span>
                              
                                                          </span>
                                                        </label>
                                                        <label class="input">
                                                          <input name="order" id="order" [(ngModel)]="model.Order" #order="ngModel" type="text"
                                                            placeholder="Order Number">
                              
                                                        </label>
                                                      </fieldset>
                                                    </div>
                              
                              
                                                  </div>
                              
                                                  <div class="row">
                              
                                                    <div class="form-group col-sm-3 articleCombo">
                              
                                                      <fieldset>
                              
                                                        <label class="label">
                                                          Category<span [hidden]="articleCategory.valid|| (model.ArticleCategoryId)"
                                                            class="text-danger">
                                                            *
                                                          </span>
                                                        </label>
                              
                                                        <p-dropdown appendTo="body" [style]="{padding:'0.5em 0',background:'#fff'}" name="articleCategory"
                                                          id="articleCategory" (click)="getSectionAndCategoryByCategory()"
                                                          (keyup.enter)="getSectionAndCategoryByCategory()" [options]="ArticleCategoryList"
                                                          [filter]="true" placeholder="Select Category" required [(ngModel)]="model.ArticleCategoryId"
                                                          #articleCategory="ngModel" [style]="{'padding':'0.5em 0','width':'22.058823529411764vw'}">
                              
                                                          <!--   <ng-template let-item pTemplate="selectedItem">
                                                            <span > {{ArticleCategoryList[seclectedCategoryValue].label}} </span>
                                                       </ng-template> -->
                              
                                                        </p-dropdown>
                              
                                                      </fieldset>
                                                    </div>
                                                    <div class="form-group col-sm-3 articleCombo">
                              
                                                      <fieldset>
                                                        <label class="label">
                                                          Section<span [hidden]="articleSection.valid|| (model.ArticleSectionId)" class="text-danger">
                                                            *
                                                          </span>
                                                        </label>
                              
                                                        <p-dropdown appendTo="body" [style]="{padding:'0.5em 0',background:'#fff' }" name="articleSection"
                                                          id="articleSection" [options]="ArticleSectionList" required [filter]="true"
                                                          (onChange)="getArticleSectionById()" [(ngModel)]="model.ArticleSectionId"
                                                          #articleSection="ngModel" placeholder="Select Section"
                                                          [style]="{'padding':'0.5em 0','width':'22.058823529411764vw'}">
                              
                                                          <!-- <ng-template let-item pTemplate="selectedItem">
                                                             <span > {{ArticleSectionList[seclectedSectionValue].label}} </span>
                                                        </ng-template> -->
                              
                                                        </p-dropdown>
                              
                              
                                                      </fieldset>
                                                    </div>
                                                    <div class="form-group col-sm-3 articleCombo">
                              
                                                      <fieldset>
                                                        <label class="label">
                                                          Tenant<span [hidden]="tenantselect.valid|| (model.Tenants!=null && model.Tenants.length >0)"
                                                            class="text-danger">
                                                            *
                                                          </span>
                                                        </label>
                              
                                                        <p-multiSelect name="tenantselect" id="tenantselect" [options]="tenantSelectionList"
                                                          [filter]="true" [(ngModel)]="model.SelectedTenants" #tenantselect="ngModel"
                                                          placeholder="Select Tenant" [style]="{'padding':'0.5em 0','width':'22.058823529411764vw'}" [maxSelectedLabels]="0" selectedItemsLabel="{0} items selected">
                                                        </p-multiSelect>
                              
                                                      </fieldset>
                                                    </div>
                              
                              
                              
                                                    <div class="form-group col-sm-3">
                                                      <fieldset>
                                                        <!-- <button type="button" class="btn btn-primary margin-left-3px"
                                                          (click)="openAssignedUserRolesDlg()" style="float: left;">
                                                          Assigned Roles
                                                        </button> -->
                              
                                                        <label class="label">Roles</label>
                                                        <div class="inline-group" id="rdobuttonview">
                                                          <label class="radio" style="padding-left:10px;">
                                                            <input type="radio" name="radio-inlineview" checked="checked"
                                                              [(ngModel)]="model.showRoles" [value]="false">
                                                            <i></i>All
                                                          </label>
                                                          <label class="radio" style="padding-left:10px;">
                                                            <input type="radio" name="radio-inlineview" [(ngModel)]="model.showRoles" [value]="true">
                                                            <i></i>Selected
                                                          </label>
                                                        </div>
                                                      </fieldset>
                                                    </div>
                              
                                                  </div>
                              
                                                  <div class="row" *ngIf="model.showRoles">
                                                    <div class="form-group col-sm-12" *ngIf="model.showRoles">
                                                      <div class="panel panel-default">
                                                        <div class="panel-heading panalheading-padding">
                                                          <h3 class="panel-title">Permissions</h3>
                                                        </div>
                                                        <div class="panel-body">
                                                          <div class="col-sm-12 picklist-padding fix-picklist-width">
                                                            <p-pickList id="rolespicklist" responsive="true" sourceHeader="Available Roles"
                                                              targetHeader="Selected Roles" [source]="availableRoles" [target]="selectedRoles"
                                                              filterBy="RoleLabel" dragdrop="true" sourceFilterPlaceholder="Search by role"
                                                              targetFilterPlaceholder="Search by role" [sourceStyle]="{'height':'270px'}"
                                                              [targetStyle]="{'height':'270px'}">
                                                              <ng-template let-role pTemplate="item">
                                                                <div>
                                                                  <div class="col-md-8" style="font-size:14px"><span>{{role.RoleLabel}}</span></div>
                                                                  <div class="col-md-4">
                                                                    <span *ngIf="role.Type == 'Group'" (click)="displayGroupDetail(role.Id)"
                                                                      class="group-tags" style="background-color: darkgoldenrod;margin-left: 5px;">
                                                                      <span class="fa fa-external-link">
                                                                      </span>
                                                                      &nbsp; Group</span>
                                                                  </div>
                                                                </div>
                                                              </ng-template>
                                                            </p-pickList>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div class="row">
                                                    <div class="form-group col-sm-12">
                                                      <div class="panel panel-default">
                                                        <div class="panel-heading panalheading-padding">
                                                          <h3 class="panel-title">Related Components</h3>
                                                        </div>
                                                        <div class="panel-body">
                                                          <div class="col-sm-12 picklist-padding fix-picklist-width">
                                                            <p-pickList id="rolespicklist" responsive="true" sourceHeader="Available component"
                                                              targetHeader="Selected component" [source]="availableComponents" [target]="selectedComponents"
                                                              filterBy="lable" dragdrop="true" sourceFilterPlaceholder="Search by component"
                                                              targetFilterPlaceholder="Search by component" [sourceStyle]="{'height':'270px'}"
                                                              [targetStyle]="{'height':'270px'}">
                                                              <ng-template let-role pTemplate="item">
                                                                <div>
                                                                  <div class="col-md-8" style="font-size:14px"><span>{{role.lable}}</span></div>
                                                                </div>
                                                              </ng-template>
                                                            </p-pickList>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div class="row  tinymecrow">
                                                    <div class="form-group col-sm-12">
                                                      <div>
                                                        <fieldset>
                                                          <!-- [initialValue]="_content"  -->
                                                          <editor [(ngModel)]="model.ArticleContentHtml" class="tinymce_editor"
                                                            name="ArticleContentHtml" #articleContent="ngModel" apiKey='EditorKey'
                                                            [initialValue]="model.ArticleContentHtml" [init]="init">
                              
                              
                                                          </editor>
                                                        </fieldset>
                                                      </div>
                              
                                                    </div>
                                                  </div>
                              

                                            


                                          </div>
                                      </div>

                                  </div>

                              </div>
                              <!-- end row -->

                          </section>
                          <!-- end widget grid -->
                          <div class="widget-footer">

                            <div ><p class="pull-left  CreatedbyBottomText" >Created by {{model.CreatedBy}} on {{model.CreatedOn}} </p> 
                            </div>
                            <div>
                            

                
                              <!-- <p  class="pull-left CreatedbyBottomText">Created by  {{model.CreatedBy}}   on  {{CreatedOn}}   </p> -->
                              <button type="button" class="btn btn-primary   p-button-danger margin-right-3px" (click)="confirm()" style="margin-bottom:15px;">
                                Delete</button>
                              <button type="submit" class="btn btn-primary margin-right-3px"
                                [disabled]="!ArticleForm.form.valid || isSubmitted || seclectedSectionValue==-1 || seclectedCategoryValue==-1" style="margin-bottom:15px;">
                                Update
                              </button>
                              <button type="button" class="btn btn-default" [disabled]="isSubmitted" (click)="btnCancelClick()" style="margin-bottom:15px;">
                                Cancel
                              </button>
                              
                              
                            
                            </div>
                          </div>
                      </div>
                      <!-- end widget content -->
                  </div>
                  <!-- end widget div -->

              </div>
              <!-- end widget -->
          </form>
      </article>
      <!-- END COL -->

  </div>

</div>