export class ContainerTypeModel {
    constructor(
        public ContainerTypeID?: number,
        public ContainerType?: string,
        public ContainerWorkPack?: boolean,
        public ContainerDate?: string,
        public ContainerCategory?: string,
        public ContainerDescription?: string,
        public ColorCode?:string,
        public Status?: boolean,
        public CreatedBy?: string,
        public CreatedOn?: Date,
        public ModifiedBy?: string,
        public ModifiedOn?: Date,
     ) {
    }
}

export enum OperationMode {
    Add = 1,
    Edit = 2,
    Delete = 3
}