//Components
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';

//interfaces
import { IMenu } from '../../../shared/services/menu/menu';
import { JobService } from '../../services/dp-jobs.service';

@Component({
    selector: 'job',
    templateUrl: './job.component.html',
    providers: [JobService]
})

export class JobsComponent implements OnInit 
{
    constructor(private _sharedService: SharedService,  private _router: Router, public _dpJobService: JobService) {
    }
    ngOnInit() {
        this._sharedService.prevURL = this._router.url;
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
       else 
       {
        this._sharedService.updateAssignedRole();
        this.populateJobStatus();
       }
        
    }
    populateJobStatus() {
        this._dpJobService.getActiveJobsStatus()
        .subscribe({
            next: (response) => {
                this._sharedService.setJobStatusList(response);
                try {
                }
                catch (ex) {
                    console.log(ex);
                }
            },
            error: (error) => {
            }
        });
    }
}





   
