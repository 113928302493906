//Components
import { Component, OnInit,} from '@angular/core';
import { Router} from '@angular/router';

import { Observable } from 'rxjs'

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { DocumentTypeService } from '../../services/document-type/document-type.service';
import { DocumentTypeEntity } from '../../services/document-type/document-type';
import { GridOptions, GridApi } from "ag-grid-community";

var _router: Router;

@Component({
    selector: 'document-types',
    templateUrl: './document-types.component.html',
    providers: [DocumentTypeService]

})

export class DocumentTypesComponent implements OnInit {

    public docTypeID: string;
    public gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[];
    public canAddEditDocType: boolean = false;
    public addDocTypePath: string = "/admin/addDocType";
    private api: GridApi;
    totalCount: string;
    model: DocumentTypeEntity;
    
    private data: Observable<string>;

    constructor(private sharedService: SharedService, private router: Router, private documentTypeService: DocumentTypeService) {
        if (!this.sharedService.isAuthUser()) {
            this.router.navigate(['login']);
            this.sharedService.SessionExpiredMessage();
        }
        else {
            this.updateRoles();
            _router = this.router;
            this.navigateToNextCell=this.navigateToNextCell.bind(this);
        }
    }


    ngOnInit() {
        if (this.canAddEditDocType) {
            this.bindGrid();
            this.loadGridData();
        }
    }
    bindGrid() {
       // this.setGridColumns();
        this.gridOptions = <GridOptions>{
            rowSelection: 'single',
            //suppressContextMenu: true,
            //onSelectionChanged: this.handleSelectedDocTypes,
            onGridReady: () => {
                this.setGridColumns();
                if (this.gridOptions.api != undefined) {
                    this.gridOptions.api.sizeColumnsToFit();                   
                  //  this.gridOptions.api.setColumnDefs(this.columnDefs);
                    this.api = this.gridOptions.api;
                    try {
                        this.gridOptions.api.paginationSetPageSize(this.sharedService.getGridPageSize('agGridDocumentTypes'));
                        this.gridOptions.api.hideOverlay();
                    } catch (e) {
                        console.log(e);
                    }
                }
            },
            onModelUpdated: (event: any) => {
                if (this.gridOptions.rowData != null && this.gridOptions.rowData != undefined && this.gridOptions.rowData.length > 0) {
                    if (this.api != undefined) {
                        this.api.sizeColumnsToFit();
                    }
                }
            },
            pagination: true,
            paginationPageSize: 20,
            getContextMenuItems: this.getCustomContextMenuItems,
            context: {
                gridContext: this
            },
            defaultColDef: {
                sortable: true,
                resizable:true,
                filter:true
            }
        }
    }
    setGridColumns(): void {
        this.columnDefs = [];
        this.columnDefs =
            [
                { headerName: 'Document Type', field: 'Name', menuTabs: ['filterMenuTab'] },
                {
                    headerName: 'Status', field: 'Status', filter:false, suppressMenu: true, valueFormatter: function (params: any) {
                        if (params.value == true) {
                            return "Active";
                        }
                        else {
                            return "Inactive";
                        }
                    }
                }
            ];
    }
    loadGridData(): void {

        let userRole: string = localStorage.getItem('roles').trim();
        this.documentTypeService.getDocumentTypesDetail()
        .subscribe({
            next: (response) => {
                this.gridData = [];
                this.gridData = response;
                this.totalCount = response.length;
            },
            error: (error) => {
                console.log(error)
            }
        });
    }
    updateRoles() {
        if (!this.sharedService.UserRole) {
            this.sharedService.updateRole();
        }

        this.canAddEditDocType = this.sharedService.UserRole.Admin_Doctype;
    }

    docTypeRowDoubleClicked(event: any) {

        this.docTypeID = event.data.DocumentTypeID;
        this.sharedService.updateAdminDocType(null);
    }
    handleSelectedDocTypes() {
        this.sharedService.addIconHTMLasString;
        var rowCount = this.api.getSelectedNodes().length;
        if (rowCount > 0) {
            var lastRowData = this.api.getSelectedNodes()[rowCount - 1].data;
            this.docTypeID = lastRowData.DocumentTypeID.toString();
        }
    }
    handlereloadGrid(condition: boolean): void {
        this.loadGridData();
    }
    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];

        if (params.context.gridContext.canAddEditDocType) {
            result.push({
                name: "Add Document Type",
                action: function () {
                    params.context.gridContext.sharedService.addAdminDocType(null);
                },                
                icon: params.context.gridContext.sharedService.addIconHTMLasString,
            });

                result.push({
                    name: "Edit Document Type",
                    action: function () {
                        params.context.gridContext.docTypeID = null;
                        if (params.node)
                        {                            
                            params.context.gridContext.docTypeID = params.node.data.DocumentTypeID;
                            params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                            params.context.gridContext.sharedService.updateFromMenuAdminDocType(params.node.data.DocumentTypeID);
                        }
                    },
                    icon: params.context.gridContext.sharedService.editIconHTMLasString,
                });
           
                let childResult: any[] = [];
                childResult.push({
                    name: 'Excel',
                    action: function () {
                        
                        params.api.exportDataAsExcel();
                        
                    },
                    icon: params.context.gridContext.sharedService.downloadLogFeedIconHTMLasString,
                });
    
                childResult.push({
                    name: 'CSV',
                    action: function () {
                        params.api.exportDataAsCsv();
                    },
                    icon: params.context.gridContext.sharedService.saveLogFeedIconHTMLasString,
                });
    
                result.push({
                    name: 'Download',
                    action: function () {
    
    
                    },
                    icon: params.context.gridContext.sharedService.downloadsFeedIconHTMLasString,
                    subMenu: childResult,
                });
                
        }
        return result;
    }
    navigateToNextCell(params: any) {
        var previousCell = params.previousCellPosition;
        var suggestedNextCell = params.nextCellPosition;
        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        switch (params.key) {
            case KEY_DOWN:
                var nextRowIndex = previousCell.rowIndex + 1;
                var renderedRowCount = this.gridOptions.api.getModel().getRowCount();
                if (nextRowIndex >= renderedRowCount) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
                    rowNode.setSelected(true);
                    this.docTypeID = rowNode.data.DocumentTypeID;
                        return {
                        rowIndex: nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_UP:
                var _nextRowIndex = previousCell.rowIndex - 1;
                if (_nextRowIndex < 0) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
                    rowNode.setSelected(true);
                    this.docTypeID = rowNode.data.DocumentTypeID;
                        return {
                        rowIndex: _nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw 'this will never happen, navigation is always on of the 4 keys above';
        }
    }
    //#endregion
    /**
 * Window resize event handling
 */
    //@HostListener('window:resize', ['$event'])
    //onResize(event: any) {
    //    console.log("Width: " + event.target.innerWidth);
    //    if (this.api) {
    //        this.api.sizeColumnsToFit();
    //    }
    //}  
}   