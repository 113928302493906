//Components
import { Component, OnInit, Input, OnDestroy,ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { GridOptions,GridApi } from "ag-grid-community";

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { SelectItem } from 'primeng/api';
import { DFPStatusService } from '../../services/dfpstatus/dfpstatus.service';;
import { DFPStatusModel, OperationMode } from './dfpstatus';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { CommService } from 'src/app/comm/services/comm.service';
var _router_: Router;

@Component({
    selector: 'dfpstatus',
    templateUrl: './dfpstatus.component.html',
    providers: [DFPStatusService,CommService]

})

export class DFPStatusComponent implements OnInit, OnDestroy {
    display: boolean = false;
    public gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[];
    public hideDFPStatusMessage: boolean = false;
    public canAddEditDFPStatus: boolean = false;

    selectedDFPStatusID: string = null;
    
    public totalCount: number;
    public model: DFPStatusModel;
    public isSubmitted: boolean = false;
    TagList: SelectItem[] = [];
    public dialogTitle: string = 'Add Compliance Status';
    public lblsubmitButton: string = 'Add';
    public operationMode: OperationMode = 1;
    public readonlyMode: boolean = false;
    public appHotkeys:  Hotkey[] = [];
    public commTypeList: any[];

    //#region Initialization of Component
    constructor(public _sharedService: SharedService, public _router: Router,private _commService :CommService, public _dfpStatusService: DFPStatusService, private _hotkeysService: HotkeysService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.UpdateRoles();
            _router_ = this._router;
            this.setHotKeys();
            this.navigateToNextCell=this.navigateToNextCell.bind(this);
        }
    }
    setHotKeys() {

        let hotKeyObj : Hotkey = null;

        //Add Compliance Status
        hotKeyObj = new Hotkey('ctrl+a', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/dfpstatus")
            {
                this.btnAddClick();
            }
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

        
        //Edit Compliance Status
        hotKeyObj = new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/dfpstatus")
            {
                this.btnEditClick();
            }
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
        hotKeyObj = new Hotkey('enter', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/dfpstatus")
            {
                this.btnEditClick();
            }
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

    }
    ngOnInit() {
        this.model = new DFPStatusModel();
        if (this.canAddEditDFPStatus) {
            this.gridOptions = <GridOptions>
                {
                    rowSelection: 'single',

                    onGridReady: () => {
                        this.setUserGridColumns();
                        try {
                            this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridDFPStatus'));
                            this.gridOptions.api.hideOverlay();
                        } catch (e) {
                            console.log(e);
                        }
                    },
                    //suppressContextMenu: true,
                    pagination: true,
                    paginationPageSize: 20,
                    getContextMenuItems: this.getCustomContextMenuItems,
                    context: {
                        gridContext: this
                    },
                    defaultColDef: {
                        sortable: true,
                        filter:true
                    }
                
                };
           // this.getCategoryList();
            this.populateDFPStatusGrid();
            this.PopulateCommTypeList();
        }
    }
    ngOnDestroy() {
        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
    }
    //#endregion
    PopulateCommTypeList(): void {
        this._commService.getAllCommType()
        .subscribe({
            next: (response) => {
                this.commTypeList=[];
                this.commTypeList = response;
                this.commTypeList.push({ value: '-1', label: 'N/A' });
                this.commTypeList = this.commTypeList.slice().sort((a, b) => a.value - b.value);
            },
            error: (error) => {
            }
        });
    }
    //#region Ag Grid Population Event
    populateDFPStatusGrid(): void {

        this._dfpStatusService.getAllDFPStatusData(null)
        .subscribe({
            next: (response) => {
                this.gridOptions.api.sizeColumnsToFit();
                this.gridData = [];
                this.gridData = response;
                this.totalCount = response.length;                
            },
            error: (error) => {
                this.gridData = [];
                this.totalCount = 0;                
            }
        });
    }
    //#endregion
    //#region Set Columns of Browse Ag Grid
    setUserGridColumns(): void {
        this.columnDefs = [];
        this.columnDefs =
            [
            { headerName: 'Compliance Status', field: 'Name', menuTabs: ['filterMenuTab'], cellRenderer: this.tagsCellRenderer },    
            { headerName: 'Description', field: 'Description', menuTabs: ['filterMenuTab'] },
            { headerName: 'Auto Open Comm Item', field: 'AutoOpenItem', menuTabs: ['filterMenuTab'] },
                {
                    headerName: 'Status', field: 'Status', filter:false, suppressMenu: true, valueFormatter: function (params: any) {
                        if (params.value == true) {
                            return "Active";
                        }
                        else {
                            return "Inactive";
                        }
                    }
                }
            ];

    }
    tagsCellRenderer(params: any) {
        let html: string = '';

        if (params.value) {
            let dfpstatus: string = params.value.trim();
            if (dfpstatus == 'Suggested - no document found' || dfpstatus == 'Suggested - partial documents found' || dfpstatus == 'Suggested - documents found' || dfpstatus == 'Suggested - not applicable')
            {
                html = html + '<span class="padding-2 label" style="color:' + params.data.CSSClass + ';">' + dfpstatus + '</span>';
            }
            else {
                html = html + '<span class="padding-2 label" style= "background-color:' + params.data.CSSClass + ';">' + dfpstatus + '</span>';
            }
            

        }

        var eDiv = document.createElement('div');
        eDiv.innerHTML = html

        return eDiv;
    }

    //#endregion

    //#region Ag-Grid Events
    updateSelectedRow(event: any) {
        this.selectedDFPStatusID = null;
        this.selectedDFPStatusID = event.data.DFPStatusID;
    }
    //#endregion

    //#region Edit User Events
    containerRowDoubleClicked(event: any) {
        if (this.canAddEditDFPStatus)
        {
            this.selectedDFPStatusID = null;
            this.selectedDFPStatusID = event.data.DFPStatusID;
            this.btnEditClick();
        }
    }
    btnEditClick() {
        let selectedRows=this.gridOptions.api.getSelectedRows();
        if (this.selectedDFPStatusID && (selectedRows && selectedRows.length>0)) {
            this.operationMode = 2;
            this.dialogTitle = 'Edit Compliance Status';
            this.lblsubmitButton = 'Update';

            this._dfpStatusService.getDFPStatusByID(this.selectedDFPStatusID)
            .subscribe({
                next: (response) => {
                    let data = response;
                    this.model = new DFPStatusModel();
                    this.model.CSSClass = data.CSSClass.trim();
                    this.model.DFPStatusID = data.DFPStatusID;
                    this.model.Description = data.Description?data.Description.trim():data.Description;
                    this.model.Status = data.Status;
                    this.model.Name = data.Name.trim();
                    if (
                        data.AutoOpenItem === null ||
                        data.AutoOpenItem === ""
                      ) {
                        this.model.AutoOpenItem = "-1";
                      } else {
                        this.model.AutoOpenItem = data.AutoOpenItem;
                      }
                    this.markDPFStatusForReadonlyMode(data.Name.trim());
                    this.showHideDialogue(true);
                },
                error: (error) => {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Compliance Status not added, please try again.' });
        
                    this.showHideDialogue(false);                    
                }
            });
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Compliance Status Selected', detail: 'Please select a record.' });
        }
    }
    //#endregion
    //#region Add User Events
    btnAddClick() {
        this.operationMode = 1;
        this.dialogTitle = 'Add Compliance Status';
        this.lblsubmitButton = 'Add';
        this.model = new DFPStatusModel();

        this.model.Status = true;
        this.model.CSSClass = '#0976e3';
        this.readonlyMode = false;
        this.showHideDialogue(true);
    }

    onDFPStatusChange(event: any){
        this.hideDFPStatusMessage = true;
    }
    //#endregion

    //#region User Roles Assignment
    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }

        this.canAddEditDFPStatus = this._sharedService.UserRole.Admin_DFP;
    }
    //#endregion

    //#region Cancel Dialogue
    btnCancelClick(cancelForm: NgForm) {
        this.showHideDialogue(false);
        this.readonlyMode = false;
        this.model = new DFPStatusModel();
        cancelForm.resetForm();
    }
    //#endregion
    //#region Helper Methods
    showHideDialogue(flag: boolean) {
        this.display = flag;
    }
    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }

    markDPFStatusForReadonlyMode(dfpstatus:string)
    {
        if (dfpstatus == 'Suggested - no document found' || dfpstatus == 'Suggested - partial documents found' || dfpstatus == 'Suggested - documents found' || dfpstatus == 'Suggested - not applicable')
        {
            this.readonlyMode = true;
        }
        else
        {
            this.readonlyMode = false;
        }

    }
    //#endregion

    //#region form submit
    onSubmit(form: NgForm) {
        this.changeButtonsStatus(true);
        this._sharedService.showLoader("Processing...");
        if (this.operationMode == 1) {
            let body: any =
                {
                    DFPStatusID: 0,
                    Status: this.model.Status,
                    Name: this.model.Name,
                    CSSClass: this.model.CSSClass,
                    Description: this.model.Description,
                    AutoOpenItem: this.model.AutoOpenItem
                }
            this._dfpStatusService.addUpdateDFPStatus(body)
            .subscribe({
                next: (response) => {
                    this.showHideDialogue(false);
                    form.resetForm();
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Compliance Status added succesfully.' });
                    this.changeButtonsStatus(false);
                    this.populateDFPStatusGrid();                    
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();            
                    if (error.status === 409) {
                        this._sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Compliance Status already exists.' });
                    }
                    else {
                        this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Compliance Status not added, please try again.' });
                    }
                    this.showHideDialogue(false);
                    this.model = new DFPStatusModel();
                    form.resetForm();
                    this.changeButtonsStatus(false);                    
                }
            });
        }
        else if (this.operationMode == 2) {
            let body: any =
                {
                    DFPStatusID: this.selectedDFPStatusID,
                    Status: this.model.Status,
                    Name: this.model.Name,
                    CSSClass: this.model.CSSClass,
                    Description: this.model.Description,
                    AutoOpenItem: this.model.AutoOpenItem
                }
            this._dfpStatusService.addUpdateDFPStatus(body)
            .subscribe({
                next: (response) => {
                    this.showHideDialogue(false);
                    form.resetForm();
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast(
                        { 
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Compliance Status updated succesfully.' 
                        }
                    );
                    this.changeButtonsStatus(false);
                    this.populateDFPStatusGrid();                    
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    if (error.status === 409) {
                        this._sharedService.showToast(
                            {
                                 severity: 'error',
                                 summary: 'Already Exists',
                                 detail: 'Compliance Status already exists.' 
                            }
                        );
                    }
                    else {
                        this._sharedService.showToast(
                            {
                                 severity: 'error',
                                 summary: 'Error Occured',
                                 detail: 'Compliance Status not updated, please try again.' 
                            }
                        );
                    }
                    this.showHideDialogue(false);
                    this.model = new DFPStatusModel();
                    form.resetForm();
                    this.changeButtonsStatus(false);                    
                }
            });
        }
    }
    //#endregion

    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];

        if (params.context.gridContext.canAddEditDFPStatus) {
            result.push({
                name: "Add Compliance Status",
                action: function () {
                    params.context.gridContext.btnAddClick()
                },
                icon: params.context.gridContext._sharedService.addIconHTMLasString,
            });

            result.push({
                name: "Edit Compliance Status",
                action: function () {
                    params.context.gridContext.selectedDFPStatusID = null;
                    if (params.node) {
                        params.context.gridContext.selectedDFPStatusID = params.node.data.DFPStatusID;
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext.btnEditClick()
                    }                  
                },
                icon: params.context.gridContext._sharedService.editIconHTMLasString,
            });

            let childResult: any[] = [];
            childResult.push({
                name: 'Excel',
                action: function () {
                    
                    params.api.exportDataAsExcel();
                    
                },
                icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
            });

            childResult.push({
                name: 'CSV',
                action: function () {
                    params.api.exportDataAsCsv();
                },
                icon: params.context.gridContext._sharedService.saveLogFeedIconHTMLasString,
            });

            result.push({
                name: 'Download',
                action: function () {


                },
                icon: params.context.gridContext._sharedService.downloadsFeedIconHTMLasString,
                subMenu: childResult,
            });
			
        }
        return result;
    }
    navigateToNextCell(params: any) {
        var previousCell = params.previousCellPosition;
        var suggestedNextCell = params.nextCellPosition;
        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        switch (params.key) {
            case KEY_DOWN:
                var nextRowIndex = previousCell.rowIndex + 1;
                var renderedRowCount = this.gridOptions.api.getModel().getRowCount();
                if (nextRowIndex >= renderedRowCount) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedDFPStatusID = null;
                    this.selectedDFPStatusID = rowNode.data.DFPStatusID;
                        return {
                        rowIndex: nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_UP:
                var _nextRowIndex = previousCell.rowIndex - 1;
                if (_nextRowIndex < 0) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedDFPStatusID = null;
                    this.selectedDFPStatusID = rowNode.data.DFPStatusID;
                        return {
                        rowIndex: _nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw 'this will never happen, navigation is always on of the 4 keys above';
        }
    }
    
}