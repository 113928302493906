<div class="jarviswidget-ctrls" role="menu" title="Edit Job (Ctrl+Shift+E)">
    <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="showEditDialog()" id="btnedit"
        (click)="showEditDialog()">
        <i class="glyphicon glyphicon-pencil"></i>
    </a>
</div>

<form *ngIf="display" #editjobForm="ngForm">
    <!--(ngSubmit)="onSubmit(editjobForm)"-->
    <p-dialog id="editjobdlg" *ngIf="display" [(visible)]="display" showEffect="fade" [modal]="true"
        [style]="{top:'0px',width: '80vw'}" [resizable]="false">
        <!--(onShow)="adjustJobDlgHeight($event)"-->
        <ng-template pTemplate="header"  (keyup.shift.tab)="btnCancelClick(editjobForm)">
            {{dialogTitle}}
        </ng-template>
        <div class="upload-dialog">
            <div id="jobeditDlg" class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-3" *ngIf="isJobModule">

                        <fieldset>
                            <label class="label" style="height: 18px;">
                            </label>
                            <container-selection *ngIf="display" [screenmode]="screenmode"></container-selection>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Asset<span [hidden]="asset.valid" class="text-danger">
                                    *
                                </span>
                            </label>
                            <span [ngClass]="{'readonlyinput': isJobModule==false}" class="width-100-percent">
                                <!-- <p-dropdown appendTo="body" id="asset" name="asset" [options]="assets" [filter]="true"
                                    placeholder="Select Asset" required [(ngModel)]="model.asset" #asset="ngModel"
                                    #editassetjob [resetFilterOnHide]="true" [disabled]="isJobModule==false">
                                </p-dropdown> -->

                                <p-dropdown appendTo="body" id="asset" name="asset" [options]="assets" [filter]="true"
                                placeholder="Select Asset" required [(ngModel)]="model.asset" #asset="ngModel"
                                #editassetjob [resetFilterOnHide]="true" [disabled]="isJobModule==false">
                                    <ng-template style="padding-bottom:20px;padding-left: 0px !important;" let-item pTemplate="selectedItem">
                                        <span>{{item.label}}</span>
                                        <!-- <span style="font-size:14px;background-color:lightgreen;">{{item.AssetType}}</span> -->
                                    </ng-template>
                                    <ng-template  style="padding-bottom:20px !important;padding-left: 0px !important;" let-asset pTemplate="item">
                                        <div style="padding-bottom: 20px;">
                                            <!-- <span > -->
                                                <span style="padding:0" class="col-md-9">{{asset.label}}</span>

                                            <!-- </div> -->
                                            <!-- <div class="col-md-4"> -->
                                                <span style="padding:0" class="col-md-3">
                                                    <span  style="font-size: 11px;padding: 4px; background-color:lightgreen;color: white;border-radius: 4px;">
                                                        {{asset.AssetType}}
                                                </span>
                                                </span>

                                        <!-- </div> -->
                                        </div>
                                    </ng-template>
                                </p-dropdown>

                            </span>
                        </fieldset>
                    </div>
                    <div class="form-group width-100 col-sm-3 pull-right">
                        <fieldset>
                            <label class="label">
                                Job Status<span
                                    [hidden]="jobstatus.valid || (model.dpjobstatusid!=null && model.dpjobstatusid>0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="jobstatus" name="jobstatus" [options]="jobStatusList" required
                                placeholder="Select Job Status" [(ngModel)]="model.dpjobstatusid" #jobstatus="ngModel">

                            </p-dropdown>

                        </fieldset>
                    </div>

                </div>
                <div class="row">
                    <div class="form-group col-sm-3">
                        <fieldset>
                            <label class="label">
                                Container Type<span [hidden]="type.valid" class="text-danger">
                                    *
                                </span>
                            </label>
                            <span [ngClass]="{'readonlyinput': isJobModule==false}" class="width-100-percent">
                                <p-dropdown appendTo="body" id="type" name="type" [options]="WOTypes" required
                                    placeholder="{{placeholderContainerType}}" [(ngModel)]="model.type" #type="ngModel"
                                    [filter]="true" #edittypejob [resetFilterOnHide]="true"
                                    [disabled]="isJobModule==false">
                                </p-dropdown>
                            </span>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-3">
                        <fieldset>
                            <label class="label">
                                Date<span [hidden]="date.valid" class="text-danger">
                                    *
                                </span>
                                ({{ViewDateFormat}})
                            </label>
                            <label class="input" [ngClass]="{'readonlyinput': isJobModule==false}">
                                <i class='icon-append fa fa-calendar'></i>
                                <p-calendar appendTo="body" class='datepicker hasDatepicker' id='date' name="date" (onClose)="onCloseDate()"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030" required
                                    [(ngModel)]="model.date" #date="ngModel" [dateFormat]="dateFormatCalender"
                                    [style]="{width:'87%'}" placeholder="{{ViewDateFormat}}"
                                    [disabled]="isJobModule==false">
                                </p-calendar>

                            </label>

                        </fieldset>

                    </div>
                    <div class="form-group col-sm-3">
                        <fieldset>
                            <label class="label">
                                End Date<span
                                    [hidden]="enddate.valid ||(model.referencekey!=null && model.referencekey.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                                ({{ViewDateFormat}})
                            </label>
                            <label class="input enddate"
                                [ngClass]="{'readonlyinput':(model.referencekey!=null && model.referencekey.length >0)|| isJobModule==false}">
                                <i class='icon-append fa fa-calendar'></i>
                                <p-calendar appendTo="body"  [attr.tabindex]="0" class='datepicker hasDatepicker' id='enddate' name="enddate"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030" (onClose)="onCloseEnddate()"
                                    [required]="((model.referencekey!=null && model.referencekey.length==0))"
                                    [disabled]="(model.referencekey!=null && model.referencekey.length>0)|| isJobModule==false"
                                    [(ngModel)]="model.enddate" #enddate="ngModel" [dateFormat]="dateFormatCalender"
                                    placeholder="{{ViewDateFormat}}" [style]="{width:'87%'}">

                                </p-calendar>
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-3">
                        <fieldset>
                            <label class="label">
                                {{referenceKeyLabel}}<span [hidden]="wonumber.valid || model.enddate"
                                    class="text-danger">
                                    *
                                </span>
                            </label>
                            <label class="input rgxErr">
                                <i *ngIf="wonumber.value && !wonumber.valid && (wonumber.touched || wonumber.dirty )"
                                    class="icon-append fa fa-ban"
                                    title="{{_sharedService.error_rgx_pre}} {{_sharedService.re_WorkOrderNumber_Message}} {{_sharedService.error_rgx_post}}">
                                </i>
                                <span id="woNumberId" [attr.tabindex]="0">
                                <input id="wonumber" type="text" name="wonumber" [(ngModel)]="model.referencekey"
                                    [required]="model.enddate==null" #wonumber="ngModel"  [attr.tabindex]="0"
                                    [pattern]="_sharedService.re_WorkOrderNumber"
                                    [disabled]="model.enddate!=null || isJobModule==false"
                                    [ngClass]="{'readonlyinput2': model.enddate!=null || isJobModule==false}" />
                                </span>
                            </label>
                            <!-- <label class="input">
                                    <input id="wonumber" type="text" name="wonumber"
                                        [(ngModel)]="model.referencekey" [required]="model.enddate==null" #wonumber="ngModel"
                                        [disabled]="model.enddate!=null || isJobModule==false" [ngClass]="{'readonlyinput2': model.enddate!=null || isJobModule==false}" />
                                </label> -->
                        </fieldset>
                    </div>
                    <!-- <div class="form-group width-100 col-sm-3">
                            <fieldset>
                                <label class="label">
                                    Job Status<span [hidden]="jobstatus.valid || (model.dpjobstatusid!=null && model.dpjobstatusid>0)"
                                        class="text-danger">
                                        *
                                    </span>
                                </label>

                                <p-dropdown appendTo="body" id="jobstatus" name="jobstatus" [options]="jobStatusList" required placeholder="Select Job Status"
                                    [(ngModel)]="model.dpjobstatusid" #jobstatus="ngModel">

                                </p-dropdown>

                            </fieldset>
                        </div> -->
                    <!-- <div class="form-group col-sm-3">
                                <fieldset>
                                    <label class="label">
                                        Batch Type
                                    </label>

                                    <p-dropdown appendTo="body" id="editbatchtype" name="editbatchtype" [options]="batchTypesList" placeholder="Select Batch Type"
                                        [(ngModel)]="model.batchtypeid" #editbatchtype="ngModel">

                                    </p-dropdown>

                                </fieldset>
                            </div> -->
                </div>
                <div class="row" *ngIf="userType=='Internal'">
                    <div class="form-group col-sm-3">
                        <fieldset>
                            <label class="label">
                                Priority<span
                                    [hidden]="priority.valid || (model.priority!=null && model.priority.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="priority" name="priority" [options]="priorityList" required
                                placeholder="Select Priority" [(ngModel)]="model.priority" #priority="ngModel">

                            </p-dropdown>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-3">
                        <fieldset>
                            <label class="label">
                                RDOC Batch Type
                            </label>

                            <p-dropdown appendTo="body" id="editbatchtype" name="editbatchtype" [options]="batchTypesList"
                                placeholder="{{placeholderRDOCBatchType}}" [(ngModel)]="model.batchtypeid"
                                #editbatchtype="ngModel">

                            </p-dropdown>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6 width-100">
                        <fieldset>
                            <label class="label">
                                Assigned To
                            </label>

                            <p-dropdown appendTo="body" id="assignedto" name="assignedto" [options]="internalUsersList"
                                placeholder="Select Assignee" [(ngModel)]="model.assignedto" #assignedto="ngModel"
                                [filter]="true" #editassignedtojob [resetFilterOnHide]="true">

                            </p-dropdown>

                        </fieldset>
                    </div>
                </div>
                <div class="row" *ngIf="recordType=='4' && userType=='Internal'">
                    <!-- <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Job Internal Status
                            </label>

                            <p-dropdown appendTo="body" id="jobintstatus" name="jobintstatus" [options]="jobInternalList" placeholder="Select Internal Status" [(ngModel)]="model.dpjobinternalstatusid" #jobintstatus="ngModel">

                            </p-dropdown>

                        </fieldset>
                    </div> -->
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Document Count
                            </label>

                            <label class="input">
                                <input id="documentcount" type="text" name="documentcount" placeholder="Document Count"
                                    [(ngModel)]="model.documents" #documentcount="ngModel"
                                    onkeypress="return String.fromCharCode(event.charCode).match(/[0-9]/g) != null">

                            </label>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Page Count
                            </label>

                            <label class="input">
                                <input id="pagecount" type="text" name="pagecount" placeholder="Page Count"
                                    [(ngModel)]="model.pages" #pagecount="ngModel"
                                    onkeypress="return String.fromCharCode(event.charCode).match(/[0-9]/g) != null">

                            </label>

                        </fieldset>
                    </div>
                </div>

                <div class="row" *ngIf="userType=='External'">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Priority<span
                                    [hidden]="priority.valid || (model.priority!=null && model.priority.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="priority" name="priority" [options]="priorityList" required
                                placeholder="Select Priority" [(ngModel)]="model.priority" #priority="ngModel">

                            </p-dropdown>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                RDOC Batch Type
                            </label>

                            <p-dropdown appendTo="body" id="editbatchtype" name="editbatchtype" [options]="batchTypesList"
                                placeholder="Select RDOC Batch Type" [(ngModel)]="model.batchtypeid"
                                #editbatchtype="ngModel">

                            </p-dropdown>

                        </fieldset>
                    </div>
                </div>
                <div class="row" *ngIf="userType=='Internal' && recordType!='4'">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Document Count
                            </label>

                            <label class="input">
                                <input id="documentcount" type="text" name="documentcount" placeholder="Document Count"
                                    [(ngModel)]="model.documents" #documentcount="ngModel"
                                    onkeypress="return String.fromCharCode(event.charCode).match(/[0-9]/g) != null">

                            </label>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Page Count
                            </label>

                            <label class="input">
                                <input id="pagecount" type="text" name="pagecount" placeholder="Page Count"
                                    [(ngModel)]="model.pages" #pagecount="ngModel"
                                    onkeypress="return String.fromCharCode(event.charCode).match(/[0-9]/g) != null">

                            </label>

                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">FTP Link <i class='fa fa-copy fa-lg pull-right' pTooltip="{{fullPath}}"
                                    ngxClipboard [cbContent]="fullPath" (click)='getLinkClick()'></i> <span
                                    class="text-danger"></span></label>
                            <label class="input">

                                <input id="txtFtpLink" type="text" name="txtFtpLink" [disabled]="true"
                                    [(ngModel)]="fullPath" style="width: 100%;" class="input-smText readonlyinput">
                            </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">Description</label>
                            <label class="textarea"><textarea id="comments" name="comments" #comments="ngModel"
                                    [(ngModel)]="model.comments" class="custom-scrollNote" rows="3"></textarea></label>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <fieldset>
                        <div class="jarviswidget" id="wid-id-1" data-widget-togglebutton="false"
                            data-widget-sortable="false" data-widget-fullscreenbutton="false"
                            data-widget-colorbutton="false" data-widget-editbutton="false"
                            data-widget-custombutton="false" data-widget-deletebutton="false" role="widget">

                            <header role="heading" style="margin-left: 0px;margin-right: 0px;">
                                <span class="widget-icon"><i class="fa fa-comments"></i></span>
                                <h2>Conversation</h2>
                            </header>
                            <!-- widget div-->
                            <div class="widgetdiv no-padding" style="width: 100%;">
                                <!-- widget content -->
                                <div class="widget-body">
                                    <div>
                                        <div class="comments-list">
                                            <div class="media">
                                                <div class="media-body">
                                                    <textarea id="jobeditconversation"
                                                        placeholder="Write your comment..." name="jobeditconversation"
                                                        #jobeditconversation="ngModel" [(ngModel)]="model.conversation"
                                                        class="custom-scrollNote no-overflow-x padding-left inbox-textarea"
                                                        rows="2" style="width:100%"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="oldConversation!='' && oldConversation!=null">
                                            <div class="comments-list"
                                                *ngFor="let conv of allConversations.Conversation">
                                                <div class="media">
                                                    <div class="media-body">
                                                        <h4 class="media-heading user_name">{{conv.Name}} <span
                                                                style="font-weight:lighter;"><i>
                                                                    added a comment -
                                                                </i></span><small>{{conv.Date}}</small></h4>
                                                        <div [innerHTML]="conv.Comment | safehtml"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="row" *ngIf="userType=='Internal'">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">History</label>
                            <label class="textarea"><textarea id="history" name="history" #history="ngModel"
                                    [(ngModel)]="model.history" class="custom-scrollNote readonlyinput2" rows="3"
                                    disabled></textarea></label>
                        </fieldset>
                    </div>
                </div>


            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <p class="pull-left CreatedbyBottomText">Created by {{model.createdby}} on {{CreatedOn}} </p>
                <button type="button" pButton icon="fa fa-search" class="btn btn-primary minzindex"
                    (click)="onViewItem(editjobForm)" label="View linked record"
                    [disabled]="(isSubmitted) || (!isEditVisible)" [hidden]="isJobModule==false"
                    *ngIf="isJobModule==true"></button>
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex"
                    (click)="onSubmit(editjobForm)" label="Update"
                    [disabled]="(!editjobForm.form.valid || isSubmitted) || (!isEditVisible)"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnCancelClick(editjobForm)" (focusout)="editassetjob.applyFocus()" label="Cancel"
                    [disabled]="isSubmitted"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>