    <p-panel  id="CommSummeryDropDown" [style]="{overflow: auto}" [showHeader]="false" *ngIf="commList.length > 0" [ngClass]="{'p-panel-status': commList.length > 0}">

        <p-card *ngFor="let _comm of commList; let index = index;"
            [styleClass]="(0 === index % 2)?'p-card-even':'p-card-odd'">
    
            <b>{{_comm.comm.CommId}}</b> - Due Date: {{_comm.comm.DueDate | date: 'dd-MM-yyyy'}} - Requested By: {{_comm.comm.RequestedByName}} -
            Assigned To: {{_comm.comm.AssignedToName}} - {{_comm.comm.CommStatusDescription}}
    
            <p><b>Subject:</b> {{_comm.comm.Subject}}</p>
            <!-- <p><b>Description:</b> {{_comm.comm.Description}}</p> -->
            <p><b>Description:</b></p>
            <p [innerHTML]=_comm.descriptionSafeHTML></p>
            <b>Coversations:</b>
    
            <div class="comments-list" *ngFor="let conv of _comm.conversationList">
                <div class="media">
                    <div class="media-body">
                        <h4 class="media-heading user_name">{{conv.Name}} <span style="font-weight:lighter;"><i> added a
                                    comment - </i></span><small>{{conv.Date}}</small></h4>
                        <div [innerHTML]="conv.Comment | safehtml"></div>
                    </div>
                </div>
            </div>
        </p-card>
    </p-panel>