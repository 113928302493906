export class AuditModel {
    public AuditId: number;
    public Type: string;
    public TableName: string;
    public PK: string;
    public FieldName: string;
    public OldValue: string;
    public NewValue: string;
    public UpdateDate: string;
    public UserName: string;


}

export class AuditFilterModel {
    public AuditId: number;
    public Type: string;
    public TableName: string;
    public PK: string;
    public FieldName: string;
    public OldValue: string;
    public NewValue: string;
    public UpdateDate: string;
    public Changeset: string;
    public UserName: string;
    public FromDate: Date;
    public ToDate: Date;
}

export class AuditHistoryModel {
    public UserName: string;
    public Type: string;
    public UpdateDate: string;
    public Fields: AuditModel[];
}