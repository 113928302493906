//Components
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';
import { UserService } from '../../../records/services/User/user.service';
import { AssetService } from '../../services/asset/asset.service';
import { AssetGroupService } from '../../services/asset-group/asset-group.service';
import { SelectItem } from 'primeng/api';
import { GridOptions } from "ag-grid-community";
import { NewUserManagement, NewUser } from './user-management';
import { RoleGroupService } from '../../services/role-group/role-group.service';
import * as moment from 'moment/moment';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
var dateTimeConfig: IDateFormatInfo;
@Component({
    selector: 'user-management',
    templateUrl: './user-management.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UserService, AssetService, RoleGroupService, AssetGroupService]
})

export class UserManagementComponent implements OnInit {
    availableRoles: any[];
    selectedRoles: any[];

    availableAircrafts: any[];
    selectedAircrafts: any[];

    availableViews: any[];
    selectedViews: any[];
    selectedUserAvailableViews: any[];
    selectedUserAssignedViews: any[];

    isShowAircraft: boolean = false;
    isSubmitted: boolean = false;
    newUserModel: NewUserManagement;

    hasUserId: boolean = false;
    screenName: string = "";
    buttonText: string = "";
    display: boolean = false;
    public gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[];
    selectedUser: any[];
    selectedUserIdEdit: string = "";
    userTypeList: SelectItem[] = [];
    userGroupList: SelectItem[] = [];
    isInternalExternalRights: boolean = false;
    tenantSelectionList: SelectItem[] = [];
    userOfType: string = "";
    loggedInTenantId: string = "";
    visibleTenantList: boolean = false;
    displayGroupForm: boolean = false;
    displayAssetGroupForm: boolean = false;
    groupRoleList: any[] = [];
    assetGroupList: any[] = [];
    dateFormatCalender = "";

    //#region Initialization of Component
    constructor(private _sharedService: SharedService, private _router: Router, private route: ActivatedRoute, private _userService: UserService,
        private _assetService: AssetService, private _assetGroupService: AssetGroupService, private cRef: ChangeDetectorRef, private _roleGroupService: RoleGroupService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        } else {
            dateTimeConfig = this._sharedService.getDateTimeFormatConfiguration();
        }
    }
    ngOnInit() {
        this.setCalenderDateFormat();
        let userId: string = this.route.snapshot.params["id"];
        this.userOfType = this._sharedService.getCurrentUserType();
        //if (this.userOfType=='Internal') {
        //    this.visibleTenantList = true;
        //}
        //else {
        //    this.visibleTenantList = false;
        //}
        this.populateTenantsList();
        this.loggedInTenantId = this._sharedService.getLoggedInTenantId();
        //  this.getLoggedInTenantId();
        this.hasUserId = userId != undefined;
        if (!this.hasUserId) {
            this.initializeDefaultValues();
            //this.getLoggedInTenantId();
            this.gridOptions = <GridOptions>
                {
                    rowSelection: 'single',

                    onGridReady: (params) => {
                        this.setUserGridColumns();
                        params.api.sizeColumnsToFit();
                        this.cRef.markForCheck();
                    },
                    defaultColDef: {
                        sortable: true,
                        filter: true,
                        resizable: true
                    },
                    suppressContextMenu: true,

                };
        }
        else {
            this.initializeDefaultValuesEdit(userId);
            // this.getLoggedInTenantId();
        }

        this.isInternalExternalRights = (this._sharedService.UserRole.Admin_Sys_Admin && this.userOfType == "Internal");
    }

    //#endregion
    //#region 
    populateTenantsList() {
        this._userService.getAllTenantsList()
        .subscribe({
            next: (response) => {
                this.tenantSelectionList = response;
                //this.model.CommStatusId = 1;
                // this.getLoggedInTenantId();
            },
            error: (error) => {
            }
        })
    }
    getLoggedInTenantId() {
        this._userService.getTenantId()
        .subscribe({
            next: (response) => {
                this.loggedInTenantId = response;
                this.newUserModel.userTenants = [];
                this.newUserModel.userTenants.push(this.loggedInTenantId);
            },
            error: (error) => {
            }
        });
    }
    //#endregion
    //#region Helper Methods
    getAllRolesAndGroups() {
        this._userService.getAllRolesAndGroups(this._sharedService.baseUrl, null)
        .subscribe({
            next: (response) => {
                this.availableRoles = response;
                this.cRef.markForCheck();
            },
            error: (error) => {
            }
        });
    }


    initializeDefaultValues() {
        this.screenName = "Add";
        this.buttonText = "Save";
        this.availableRoles = [];
        this.selectedRoles = [];
        this.availableAircrafts = [];
        this.selectedAircrafts = [];
        this.availableViews = [];
        this.selectedViews = [];
        this.getAllRolesAndGroups();
        this.selectedUserIdEdit = "";
        this.newUserModel = new NewUserManagement("", "", "", true, true, null, null, false, null, "External", null, [], null);
        this.isSubmitted = false;
        this.userTypeList = [];
        this.getUserType();
        this.userGroupList = [];
        this.getUserGroup();
        this.newUserModel.userTenants = [];
        this.newUserModel.userTenants.push(this.loggedInTenantId);
        this.PopulateAssets();
    }

    initializeDefaultValuesEdit(userId: string) {
        this._sharedService.showLoader("Processing...");
        this.getUserType();
        this.getUserGroup();
        this.selectedUserIdEdit = "";
        this.screenName = "Edit";
        this.buttonText = "Update";
        this.reInitilializeLocalVariables();
        this.newUserModel = new NewUserManagement("", "", "", true, true, null, null, false, null, "External", null, [], null);
        this.selectedUserIdEdit = userId;
        this.getSelectedUserData(userId);

    }

    // changeShowAircrafts() {
    //     if (!this.hasUserId) {
    //         if (this.newUserModel.showAircraft) {

    //                 this._assetService.getAllAssetList(this._sharedService.baseUrl, null).subscribe( response => {

    //                     this.availableAircrafts = [];
    //                     this.availableAircrafts = response;
    //                     this.cRef.markForCheck();
    //                 });

    //         }
    //         else {
    //             this.availableAircrafts = [];
    //             this.selectedAircrafts = [];
    //             // if all aircraft option is selected then bring all the view
    //             if (this.newUserModel.showViews) {
    //                 this.getUserViews("");
    //             }
    //             this.cRef.markForCheck();
    //         }
    //     }
    //     else
    //     {
    //         if (this.newUserModel.showAircraft)
    //         {
    //                     this.availableAircrafts = [];
    //                     this.selectedAircrafts = [];
    //                     let userId = this.selectedUserIdEdit;
    //                     this._userService.getExistingUserAsset(this._sharedService.baseUrl, userId).subscribe( response => {
    //                         let result = response;
    //                         if (result.UnassignedAircraft != null) {
    //                             this.availableAircrafts = result.UnassignedAircraft;
    //                         }
    //                         else {
    //                             this.availableAircrafts = [];
    //                         }
    //                         if (result.AssignedAircraft != null) {
    //                             this.selectedAircrafts = result.AssignedAircraft;
    //                         }
    //                         else {
    //                             this.selectedAircrafts = [];
    //                         }
    //                         this.cRef.markForCheck();
    //                     });            
    //         }
    //         else
    //         {
    //             this.availableAircrafts = [];
    //             this.selectedAircrafts = [];
    //             // if all aircraft option is selected then bring all the view
    //             if (this.newUserModel.showViews)
    //             {
    //                 this.getUserViews("");
    //             }
    //             this.cRef.markForCheck();
    //         }

    //     }
    // }

    PopulateAssets() {
        this._assetService.getAllAssetAndGroupList(this._sharedService.baseUrl, null)
        .subscribe({
            next: (response) => {
                this.availableAircrafts = [];
                this.availableAircrafts = response;
                this.cRef.markForCheck();
            },
            error: (error) => {
                console.log(error)
            }
        });
    }

    changeShowViews() {
        if (!this.hasUserId) {
            if (this.newUserModel.showViews) {
                // let selectedAssetids: any[] = this.getSelectedAircraftAndGroup();

                // let body: any = {
                //     AssetIdsList: selectedAssetids
                // }

                // this._assetService.getAllViews(this._sharedService.baseUrl, body).subscribe( response => {

                //     this.availableViews = [];
                //     this.availableViews = response;
                //     this.cRef.markForCheck();
                // });

                this.SetAssignedViewsInSelectedView();

            }
            else {
                this.availableViews = [];
                this.selectedViews = [];
                this.cRef.markForCheck();
            }
        }
        else {
            if (this.newUserModel.showViews) {

                this.getUserViews(this.selectedUserIdEdit);
            }
            else {
                this.availableViews = [];
                this.selectedViews = [];
                this.cRef.markForCheck();
            }

        }
    }

    //#endregion
    //#region Aircraft Pick List Events
    OnMovedToSelectedAircraft(events: any) {
        if (this.newUserModel.showViews) {

            // let alreadySelectedIds: any[] = this.getSelectedAircraftAndGroup();


            // let body: any = {
            //     AssetIdsList: alreadySelectedIds
            // }

            // this._assetService.getAllViews(this._sharedService.baseUrl, body).subscribe( response => {

            //     this.availableViews = [];
            //     this.selectedViews = []; // added to refresh
            //     this.availableViews = response;
            //     this.cRef.markForCheck();
            // });

            this.SetAssignedViewsInSelectedView();
        }

    }
    OnMovedAllToSelectedAircraft(events: any) {
        if (this.newUserModel.showViews) {

            // let alreadySelectedIds: any[] = this.getSelectedAircraftAndGroup();

            // let body: any = {
            //     AssetIdsList: alreadySelectedIds
            // }

            // this._assetService.getAllViews(this._sharedService.baseUrl, body).subscribe( response => {

            //     this.availableViews = [];
            //     this.selectedViews = [];
            //     this.availableViews = response;
            //     this.cRef.markForCheck();

            // });

            this.SetAssignedViewsInSelectedView();
        }

    }

    OnMovedToAvailableAircraft(events: any) {
        if (this.newUserModel.showViews) {
            // let selectedAircraftIds: any[] = this.getSelectedAircraftAndGroup();

            // let body: any = {
            //     AssetIdsList: selectedAircraftIds
            // }

            // this._assetService.getAllViews(this._sharedService.baseUrl, body).subscribe( response => {

            //     this.availableViews = [];
            //     this.selectedViews = [];
            //     this.availableViews = response;
            //     this.cRef.markForCheck();
            // });

            this.SetAssignedViewsInSelectedView();
        }

    }
    OnMovedAllToAvailableAircraft(events: any) {
        if (this.newUserModel.showViews) {
            // let selectedAircraftIds: any[] = this.getSelectedAircraftAndGroup();

            // let body: any = {
            //     AssetIdsList: selectedAircraftIds
            // }

            // this._assetService.getAllViews(this._sharedService.baseUrl, body).subscribe( response => {

            //     this.availableViews = [];
            //     this.selectedViews = [];
            //     this.availableViews = response;
            //     this.cRef.markForCheck();
            // });

            this.SetAssignedViewsInSelectedView();
        }

    }


    SetAssignedViewsInSelectedView()
    {
        let selectedAircraftIds: any[] = this.getSelectedAircraftAndGroup();

        let body: any = {
            AssetIdsList: selectedAircraftIds
        }
        var allViewsOfSelectedAssets = [];
        this._assetService.getAllViews(this._sharedService.baseUrl, body)
        .subscribe({
            next: (response) => {
                this.availableViews = [];
                this.selectedViews = [];
        
                allViewsOfSelectedAssets = response;
                if (allViewsOfSelectedAssets == null) allViewsOfSelectedAssets = [];
                this.selectedUserAssignedViews = [];
                var existing_AssignedViews = this.selectedUserAssignedViews.filter(function (obj) {
                    return allViewsOfSelectedAssets.some(function (obj2) {
                        return obj.value == obj2.value;
                    });
                });
        
                var missing_AvailableViews = allViewsOfSelectedAssets.filter(function (obj) {
                    return !existing_AssignedViews.some(function (obj2) {
                        return obj.value == obj2.value;
                    });
                });
        
                this.availableViews = missing_AvailableViews;
                this.selectedViews = existing_AssignedViews;
        
                this.cRef.markForCheck();
            },
            error: (error) => {
            }
        });
    }
    ToggleCheckedSelected(){
        this.newUserModel.showViews=true;
        this.changeShowViews();
    }
    ToggleCheckedAll(){
        this.newUserModel.showViews=false;
        this.changeShowViews();
    }
    //#endregion
    btnCancelClick() {
        this._router.navigate(['/admin/users']);
    }
    btnSaveClick() {
        this.isSubmitted = true; // disable buttons
        this._sharedService.showLoader("Processing...");
        if (!this.hasUserId) // Save Case
        {
            let body: NewUser = {
                FirstName: this.newUserModel.firstName,
                LastName: this.newUserModel.lastName,
                Email: this.newUserModel.email,
                Status: this.newUserModel.status,
                UserType: this.newUserModel.userType,
                ShowAircraft: this.newUserModel.showAircraft,
                RoleIds: this.getSelectedRolesIds(),
                AircraftIds: this.getSelectedAircraftAndGroup(),
                ShowViews: this.newUserModel.showViews,
                ViewIds: this.getSelectedViewIds(),
                UserGroupId: this.newUserModel.UserGroupId,
                UserTenants: this.newUserModel.userTenants.join(),
                ExpiryDate: this.newUserModel.ExpiryDate ? this._sharedService.getISODateTimeFormat(this._sharedService.getUTCDateFromLocalDate(this.newUserModel.ExpiryDate)) : null//? new Date(this.getDate(this.newUserModel.ExpiryDate)) : null
            }
            this._userService.createUpdateUser(this._sharedService.baseUrl, body)
            .subscribe({
                next: (response) => {
                    this._sharedService.hideLoader();
                    this.isSubmitted = false;
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'User Added', detail: 'User has been added successfully.' });
        
                    setTimeout(() => {
                        this._router.navigate(['/admin/users']);
                    },
                        1500);
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    if (error.error[0] != null && error.error[0] != undefined) {
                        this._sharedService.showToast({ severity: 'error', summary: 'Error Occurred', detail: error.error[0] });
                    }
                    else {
                        this._sharedService.showToast({ severity: 'error', summary: 'Error Occurred', detail: 'Error Occurred' });
                    }
                    this.isSubmitted = false;
                }
            });
        }
        else // Update Case
        {
            let body: NewUser = {
                FirstName: this.newUserModel.firstName,
                LastName: this.newUserModel.lastName,
                Status: this.newUserModel.status,
                ShowAircraft: this.newUserModel.showAircraft,
                RoleIds: this.getSelectedRolesIds(),
                AircraftIds: this.getSelectedAircraftAndGroup(),
                UserId: this.selectedUserIdEdit,
                ShowViews: this.newUserModel.showViews,
                ViewIds: this.getSelectedViewIds(),
                UserType: this.newUserModel.userType,
                UserGroupId: this.newUserModel.UserGroupId,
                UserTenants: this.newUserModel.userTenants.join(),
                ExpiryDate: this.newUserModel.ExpiryDate ? this._sharedService.getISODateTimeFormat(this._sharedService.getUTCDateFromLocalDate(this.newUserModel.ExpiryDate)) : null//? new Date(this.getDate(this.newUserModel.ExpiryDate)) : null
            }
            this._userService.createUpdateUser(this._sharedService.baseUrl, body)
            .subscribe({
                next: (response) => {
                    this._sharedService.hideLoader();
                    this.isSubmitted = false;
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Update Successful', detail: 'User has been updated successfully.' });
                    setTimeout(() => {
                        this._router.navigate(['/admin/users']);
                    },
                        1500);
                },
                error: (error) => {
                    this._sharedService.hideLoader()
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occurred', detail: 'User is not updated.' });
                    this.isSubmitted = false;
                }
            });
        }

        this.cRef.markForCheck();
    }

    setCalenderDateFormat() {
        let dateParts: string[] = dateTimeConfig.dateformat.split(dateTimeConfig.dateSeparator);

        if (dateTimeConfig.year == 0) {
            this.dateFormatCalender = (dateParts[dateTimeConfig.year].substr(0, 2) + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day]).toLowerCase();
        }
        else if (dateTimeConfig.year == 2) {
            if (dateTimeConfig.month == 1) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
            else if (dateTimeConfig.month == 0) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
        }
        else {
            this.dateFormatCalender = "yy-mm-dd"
        }

    }

    delay() {
        return new Promise(resolve => setTimeout(resolve, 2000));
    }
    getSelectedRolesIds(): string[] {
        let selectedRolesIds: string[] = [];
        for (let i = 0; i < this.selectedRoles.length; i++) {
            selectedRolesIds.push(this.selectedRoles[i].Name);
        }
        return selectedRolesIds;
    }
    getSelectedViewIds(): string[] {
        let selectedViewIds: string[] = [];
        for (let i = 0; i < this.selectedViews.length; i++) {
            selectedViewIds.push(this.selectedViews[i].value);
        }
        return selectedViewIds;
    }
    getSelectedAircraftIds(): string[] {
        let selectedAircraftsIds: string[] = [];
        for (let i = 0; i < this.selectedAircrafts.length; i++) {
            selectedAircraftsIds.push(this.selectedAircrafts[i].value);
        }
        return selectedAircraftsIds;
    }
    getSelectedAircraftAndGroup(): any[] {
        let selectedAircraftsIds: any[] = [];
        for (let i = 0; i < this.selectedAircrafts.length; i++) {
            selectedAircraftsIds.push({ value: this.selectedAircrafts[i].value, UserAssetType: this.selectedAircrafts[i].UserAssetType });
        }
        return selectedAircraftsIds;
    }

    getSelectedUserData(userId: string) {
        this.isSubmitted = true;
        this.selectedUserAvailableViews = [];
        this.selectedUserAssignedViews = [];

        this._userService.getSelectedUserData(this._sharedService.baseUrl, userId)
        .subscribe({
            next: (response) => {
                this.isSubmitted = false;
                let result = response;
                if (result.UnassignedAircraft != null) {
                    this.availableAircrafts = result.UnassignedAircraft;
                }
                else {
                    this.availableAircrafts = [];
                }
                if (result.AssignedAircraft != null) {
                    this.selectedAircrafts = result.AssignedAircraft;
                }
                else {
                    this.selectedAircrafts = [];
                }
                // Assign Views
                if (result.UnassignedViews != null) {
                    this.availableViews = result.UnassignedViews;
                    this.selectedUserAvailableViews = result.UnassignedViews;
                }
                else {
                    this.availableViews = [];
                }
                if (result.AssignedViews != null) {
                    this.selectedViews = result.AssignedViews;
                    this.selectedUserAssignedViews = result.AssignedViews;
                }
                else {
                    this.selectedViews = [];
                }
        
                this.selectedRoles = result.AssignedRoles;
                this.availableRoles = result.UnassignedRoles;
                this.newUserModel.firstName = result.FirstName;
                this.newUserModel.lastName = result.LastName;
                this.newUserModel.email = result.Email;
                this.newUserModel.showAircraft = result.ShowAircraft;
                this.newUserModel.status = result.Status;
                this.newUserModel.showViews = result.ShowViews;
                this.newUserModel.userType = result.UserType;
                this.newUserModel.ExpiryDate = result.ExpiryDate ? this._sharedService.getLocalDateFromUTCDate(new Date(result.ExpiryDate)) : null;
                if (!this._sharedService.UserRole.Admin_Sys_Admin && result.UserGroupId == '1') {
                    this.newUserModel.UserGroupId = null;
                }
                else {
                    this.newUserModel.UserGroupId = result.UserGroupId.toString();
                    this.cRef.markForCheck();
                }
        
        
                this.getUserTenants(result.UserTenants);
                this.cRef.markForCheck();
                this._sharedService.hideLoader();
            },
            error: (error) => {
                this._sharedService.hideLoader();
                this.isSubmitted = false;
                this._sharedService.clearToast();
                this._sharedService.showToast(
                    { 
                        severity: 'error', 
                        summary: 'Error Occurred', 
                        detail: 'User does not exist.' 
                    }
                );
            }
        });
    }
    onTenantSelectionChanged(event: any) {
        this.newUserModel.userTenants = [];
        this.newUserModel.userTenants.push(this.loggedInTenantId);

        //if (!this.hasUserId)
        //{
        //    this.newUserModel.userTenant = [];    
        //    this.newUserModel.userTenant.push(this.loggedInTenantId);
        //}
        //else {
        //    if (event.value == 'Internal')
        //    {
        //        this.newUserModel.userTenant = [];

        //    }
        //    else
        //    {
        //        this.newUserModel.userTenant = [];
        //        this.newUserModel.userTenant.push(this.loggedInTenantId);

        //    }
        //}             

    }
    getUserTenants(tenants: string) {
        this.newUserModel.userTenants = [];
        if (this.userOfType == "External") {
            this.newUserModel.userTenants.push(this.loggedInTenantId);
        }
        else {
            let tenantIds: string[] = tenants.split(',');
            for (var i = 0; i < tenantIds.length; i++) {
                this.newUserModel.userTenants.push(tenantIds[i]);
            }
        }

    }

    getDate(date: Date): string {
        if (date != null) {
            let newDate = moment(date).format("YYYY-MM-DD");
            return new Date(newDate).toDateString();
        }
        return null;
    }

    // #region Copy User
    btnCopyClick() {
        this.selectedUser = [];

        this.populateUserGrid();
    }
    btnCancelDialogueClick() {
        this.display = false;
    }
    onSubmit() {
        if (this.selectedUser.length > 0) {
            this._sharedService.showLoader("Processing...");
            let userId: string = this.selectedUser.toString();
            this._userService.getCopiedUserData(this._sharedService.baseUrl, userId)
            .subscribe({
                next: (response) => {
                    this.reInitilializeLocalVariables();
                    //let result = response;
                    //this.availableAircrafts = result.UnassignedAircraft;
                    //this.selectedAircrafts = result.AssignedAircraft;
                    let result = response;
                    if (result.UnassignedAircraft != null) {
                        this.availableAircrafts = result.UnassignedAircraft;
                    }
                    else {
                        this.availableAircrafts = [];
                    }
                    if (result.AssignedAircraft != null) {
                        this.selectedAircrafts = result.AssignedAircraft;
                    }
                    else {
                        this.selectedAircrafts = [];
                    }
        
                    // Assign Views
                    if (result.UnassignedViews != null) {
                        this.availableViews = result.UnassignedViews;
                    }
                    else {
                        this.availableViews = [];
                    }
                    if (result.AssignedViews != null) {
                        this.selectedViews = result.AssignedViews;
                    }
                    else {
                        this.selectedViews = [];
                    }
        
                    this.selectedRoles = result.AssignedRoles;
                    this.availableRoles = result.UnassignedRoles;
                    this.newUserModel.showAircraft = result.ShowAircraft;
        
                    // Views
                    //this.availableViews = result.UnassignedViews;
                    //this.selectedViews = result.AssignedViews;
                    this.newUserModel.showViews = result.ShowViews;
                    this.newUserModel.userType = result.UserType;
                    this.newUserModel.UserGroupId = result.UserGroupId.toString();
                    this.getUserTenants(result.UserTenants);
                    this._sharedService.hideLoader();
                    this.display = false;
                    this.cRef.markForCheck();
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast(
                        { 
                            severity: 'error', 
                            summary: 'Error Occurred', 
                            detail: 'Please try again later.' 
                        }
                    );
                }
            });
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'error', summary: 'No User Selected', detail: 'Please select an user.' });
        }
    }
    populateUserGrid(): void {
        this.display = true;
        this.cRef.markForCheck();
    }

    refreshGrid(event: any) {
        this._userService.getAllExternalUsers(this._sharedService.baseUrl, null)
        .subscribe({
            next: (response) => {
                this.gridData = [];
                this.gridData = response;
                this.display = true;
                this.gridOptions.api.sizeColumnsToFit();
                this.cRef.markForCheck();
            },
            error: (error) => {
            }
        })
    }
    updateSelectedUserRow(event: any) {
        this.selectedUser = [];
        this.selectedUser = event.data.Id;
    }
    // #endregion
    //#region Set Columns of Browse Ag Grid
    setUserGridColumns(): void {
        this.columnDefs = [];
        this.columnDefs =
            [
                { headerName: 'First Name', field: 'FirstName', menuTabs: ['filterMenuTab'] },
                { headerName: 'Last Name', field: 'LastName', menuTabs: ['filterMenuTab'] },
                { headerName: 'Email', field: 'Email', menuTabs: ['filterMenuTab'] },
                { headerName: 'User Group', field: 'UserGroup', menuTabs: ['filterMenuTab'], cellRenderer: this.tagsCellRenderer },
                {
                    headerName: 'Status', field: 'Status', filter: false, suppressMenu: true, valueFormatter: function (params: any) {
                        if (params.value == true) {
                            return "Active";
                        }
                        else {
                            return "Inactive";
                        }
                    }
                }
            ];

    }

    reInitilializeLocalVariables() {
        this.availableRoles = [];
        this.selectedRoles = [];
        this.availableAircrafts = [];
        this.selectedAircrafts = [];
        this.availableViews = [];
        this.selectedViews = [];
    }
    getUserType() {
        this.userTypeList = this._userService.getUserType();

    }
    getUserGroup() {
        this._userService.getUserGroupList(this._sharedService.baseUrl, null)
        .subscribe({
            next: (response) => {
                this.userGroupList = response;
            },
            error: (error) => {
                console.log(error)
            }
        });
    }
    tagsCellRenderer(params: any) {
        let html: string = '';

        if (params.value) {

            html = html + '<span class="padding-2 label" style= "background-color:' + params.data.ColorCode + ';">' + params.value + '</span>';

        }


        var eDiv = document.createElement('div');
        eDiv.innerHTML = html

        return eDiv;
    }
    getLabel(params: string) {
        return params.split("||")[0];

    }
    getType(params: string) {
        return params.split("||")[1];

    }
    getVisibleStatus(params: string) {
        let state = params.split("||")[3];
        if (state == "True") {
            return 'Visible';

        }
        else {
            return 'Invisible';
        }
    }
    getStatus(params: string) {
        let state = params.split("||")[2];
        if (state == "False") {
            return 'red-text-color';
        }
    }
    getAssetClass(params: string) {
        let assetType: string = params.split("||")[1];
        switch (assetType) {
            case "Airframe":
                return "fa fa-plane margin-airframe";
            case "Helicopter":
                return "fa fa-helicopter margin-airframe";
            case "Engine":
                return "fa icon-Engine asset-fontsize margin-enginge";
            case "APU":
                return "fa icon-APU2 margin-apu";
            case "Propeller":
                return "fa icon-propeller margin-properller";
            case "LDG":
                return "fa icon-landinggear margin-ldg";
            case "Collection":
                return "fa fa-cubes margin-collection";
            case "Item":
                return "fa fa-cube margin-item";
            default:
                return "";
        }
    }
    //#region Edit Mode User Views
    getUserViews(assigendUserId: string) {
        this.availableViews = [];
        this.selectedViews = [];
        let selectedAssetids: any[] = this.getSelectedAircraftAndGroup();

        let body: any = {
            AssetIdsList: selectedAssetids,
            UserID: assigendUserId
        }
        this._userService.getExistingUserViews(this._sharedService.baseUrl, body)
        .subscribe({
            next: (response) => {
                let result = response;
                if (result.UnassignedViews != null) {
                    this.availableViews = result.UnassignedViews;
                }
                else {
                    this.availableViews = [];
                }
                if (result.AssignedViews != null) {
                    this.selectedViews = result.AssignedViews;
                }
                else {
                    this.selectedViews = [];
                }
                this.cRef.markForCheck();
            },
            error: (error) => {
            }
        });
    }
    //#endregion 

    //#endregion

    displayGroupDetail(val: string) {
        this._roleGroupService.getGroupRoleByID(val)
        .subscribe({
            next: (response) => {
                this.groupRoleList = response;
                this.displayGroupForm = true;
                this.cRef.markForCheck();
            },
            error: (error) => {
                console.log(error);
            }
        });
    }

    btnCloseClick() {
        this.groupRoleList = [];
        this.displayGroupForm = false;
        this.cRef.markForCheck();
    }

    displayAssetGroupDetail(val: string) {
        this._assetGroupService.getAssetGroupAssetByID(val)
        .subscribe({
            next: (response) => {
                this.assetGroupList = response.SelectedAssets;
                this.displayAssetGroupForm = true;
                this.cRef.markForCheck();
            },
            error: (error) => {
                console.log(error);
            }
        });
    }

    btnAssetGroupCloseClick() {
        this.groupRoleList = [];
        this.displayAssetGroupForm = false;
        this.cRef.markForCheck();
    }
    isChecked:boolean=false
    ToggleChecked() {
  
        if (this.isChecked) {
            this.isChecked = false;
            this.newUserModel.status=false;
        }
        else {
            this.isChecked = true;
            this.newUserModel.status=true;
        }
    }

}
