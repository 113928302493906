// Components
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

// Services
import { SharedService } from '../../../shared/services/shared.service';
import { NgForm } from '@angular/forms';
import { GridOptions, GridApi } from "ag-grid-community";
import { AssetService } from '../../services/asset/asset.service';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { TemplateType } from '../user-management/user-management';
import { SearchService } from '../../services/search/search.service';
import { Observable, Subject, Subscription, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs';
import { SelectItemDto } from "../../../shared/classes/SelectItemDto";
var _router: Router;
var aircraftID: string;
interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
@Component({
    selector: 'aircraft',
    templateUrl: './aircraft.component.html',
  styles: [`
    ::ng-deep .aircraft-search .p-inputtext.p-component{
      height: 2.7rem !important;
      margin-right: 0.3rem !important;
      margin-top: 0.3rem !important;
    }
    ::ng-deep .aircraft-search .p-autocomplete .p-autocomplete-loader{
      margin-right: 0.6rem !important;
    }
    `],
    providers: [AssetService, SearchService]
})
export class AircraftComponent implements OnInit, OnDestroy {

    public gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[];
    public canAddEditAirCraft: boolean = false;
    public isSysAdmin: boolean = false;
    public addAircraftPath: string = "/admin/addAsset";
    private api: GridApi;
    public appHotkeys: Hotkey[] = [];
    totalCount: string;
    public templateType: number = TemplateType.Aircraft;
    public canUploadTemplate: boolean = false;
    private refreshAircraftGridSub: Subscription;
    assetSeachOptions$: Observable<SelectItemDto[]>;
    searchTermSubject: Subject<string> = new Subject<string>();

    constructor(private sharedService: SharedService, private router: Router, private assetManagementService: AssetService, private _hotkeysService: HotkeysService, private _searchService: SearchService) {
        if (!this.sharedService.isAuthUser()) {
            this.router.navigate(['login']);
            this.sharedService.SessionExpiredMessage();
        } else {
            aircraftID = null;
            this.updateRoles();
            _router = this.router;
            this.setHotKeys();
            this.navigateToNextCell = this.navigateToNextCell.bind(this);
        }
    }
    setHotKeys() {

        let hotKeyObj: Hotkey = null;

        // Add aircraft
        hotKeyObj = new Hotkey('ctrl+a', (event: KeyboardEvent): boolean => {
            if (_router.url == "/admin/asset") {
                _router.navigate(["/admin/addAsset"]);
            }
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

        // Edit aircraft
        hotKeyObj = new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
            if (_router.url == "/admin/asset") {
                this.editAircraft(null);
            }
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
        hotKeyObj = new Hotkey('enter', (event: KeyboardEvent): boolean => {
            if (_router.url == "/admin/asset") {
                this.editAircraft(null);
            }
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
        // Create Container
        hotKeyObj = new Hotkey('ctrl+c', (event: KeyboardEvent): boolean => {
            if (_router.url == "/admin/asset") {
                this.createContainer();
            }
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

    }

    ngOnInit() {
        if (this.canAddEditAirCraft) {
            this.bindGrid();
            this.loadGridData();
        }
        this.refreshAircraftGridSub = this.sharedService._isRefreshAircraftGrid
        .subscribe({
            next: (flag:boolean) => {
                this.loadGridData();
            },
            error: (error) => {
                console.log(error)
            }
        });
      this.assetSeachOptions$ = this.searchTermSubject.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((searchInput) => this.assetManagementService.searchAsset(searchInput))
      );
    }

    ngOnDestroy() {
        if (this.refreshAircraftGridSub) {
            this.refreshAircraftGridSub.unsubscribe();
        }
        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
    }

    bindGrid() {

        // this.setAircraftGridColumns();
        this.gridOptions = <GridOptions>{
            rowSelection: 'multiple',
            // suppressContextMenu: true,
            onSelectionChanged: this.handleSelectedDoc,
            onGridReady: () => {
                if (this.gridOptions.api) {
                    this.gridOptions.api.sizeColumnsToFit();
                    // Added for 7813 bug
                    this.setAircraftGridColumns();
                    // this.gridOptions.api.setColumnDefs(this.columnDefs);
                    this.api = this.gridOptions.api;
                    try {
                        this.gridOptions.api.paginationSetPageSize(this.sharedService.getGridPageSize('agGridAircraft'));
                        this.gridOptions.api.hideOverlay();
                    } catch (e) {
                        console.log(e);
                    }
                }


            },
            onModelUpdated: (event: any) => {

                if (this.gridOptions.rowData != null && this.gridOptions.rowData != undefined && this.gridOptions.rowData.length > 0) {
                    if (this.api != undefined) {
                        this.api.sizeColumnsToFit();
                        // this.api.selectIndex(0, true, false);
                        // this.api.getDisplayedRowAtIndex(0).setSelected(true);

                    }
                }
            },
            pagination: true,
            paginationPageSize: 20,
            getContextMenuItems: this.getCustomContextMenuItems,
            context: {
                gridContext: this
            },
            defaultColDef: {
                sortable: true,
                resizable: true,
            }

        }

    }


    setAircraftGridColumns(): void {
        this.columnDefs = [];
        this.sharedService.getConfigurationByKey("Run_Auto_Gap_Analyser")
        .subscribe({
            next: (response) => {
                this.columnDefs =
                    [
                        { headerName: 'Title', field: 'Description', filter: 'text', menuTabs: ['filterMenuTab'], minWidth: 140 },
                        { headerName: 'Registration', field: 'RegNumber', filter: 'text', menuTabs: ['filterMenuTab'], minWidth: 120 },
                        { headerName: 'Manufacturer/Group Level 1', field: 'Manufacturer', filter: 'text', menuTabs: ['filterMenuTab'], minWidth: 170 },
                        { headerName: 'Type/Group Level 2', field: 'Type', filter: 'text', menuTabs: ['filterMenuTab'], minWidth: 130 },
                        { headerName: 'Variant/Group Level 3', field: 'Varient', filter: 'text', menuTabs: ['filterMenuTab'], minWidth: 130 },
                        { headerName: 'Number/Serial', field: 'ASN', filter: 'text', menuTabs: ['filterMenuTab'], minWidth: 120 },
                        { headerName: 'Owner', field: 'Owner', filter: 'text', menuTabs: ['filterMenuTab'], minWidth: 70 },
                        { headerName: 'Registration Authority', field: 'RegAuthority', filter: 'text', menuTabs: ['filterMenuTab'], minWidth: 140 },
                        { headerName: 'Order', field: 'Order', filter: 'text', menuTabs: ['filterMenuTab'], minWidth: 100 },
                        {
                            headerName: 'Status', field: 'Status',  filter: 'agSetColumnFilter', menuTabs: ['filterMenuTab'], minWidth: 70, valueFormatter: function (params: any) {
                                if (params.value == true) {
                                    return "Active";
                                } else {
                                    return "Inactive";
                                }
                            },
                            filterParams: {
                                valueFormatter: this.sharedService.boolToStatus
                              }
                        }// ,
                        // { headerName: 'Created On  ', field: 'CreatedOn', filter: 'text' },
                    ];
        
                this.columnDefs.push({
                    headerName: 'ReadOnly', field: 'ReadOnly', filter: 'text', suppressMenu: true, minWidth: 70, valueFormatter: function (params: any) {
                        if (params.value == true) {
                            return "Yes";
                        } else {
                            return "No";
                        }
                    }
                });
        
                this.columnDefs.push(
                    { headerName: 'Plan', field: 'PlanName', filter: 'text', menuTabs: ['filterMenuTab'], minWidth: 70 }
                );
                if(response.Value.toLocaleLowerCase() == "true")
                this.columnDefs.push(
                    { headerName: 'Auto Gap Analyser', field: 'AutoGapAnalyser', filter: 'text',  minWidth: 70 }
                );
            },
            error: (error) => {
                console.log(error)
            }
        });
    }


    loadGridData(): void {

        let userRole: string = localStorage.getItem('roles').trim();
        let body = { UserRole: userRole };
        this.assetManagementService.getUserAircraft(body)
        .subscribe({
            next: (response) => {
                //   this.gridOptions.api.sizeColumnsToFit();
                this.gridData = [];
                this.gridData = response;
                this.totalCount = response.length;
            },
            error: (error) => {
                console.log(error)
            }
        });
    }
    updateRoles() {
        if (!this.sharedService.UserRole) {
            this.sharedService.updateRole();
        }
        this.isSysAdmin = this.sharedService.UserRole.Admin_Sys_Admin;
        this.canAddEditAirCraft = this.sharedService.UserRole.Admin_Aircraft;
        this.canUploadTemplate = this.sharedService.UserRole.Admin_Sys_Admin;
  }
  search(event: AutoCompleteCompleteEvent) {
    this.searchTermSubject.next(event.query);
  }
  onAirCraftSelect(event: SelectItemDto) {
    _router.navigate(["/admin/editAsset", event?.Other?.ParentId ?? event.Value]);
  }

    handleSelectedDoc() { // (selDoc: any) {

        var rowCount = this.api.getSelectedNodes().length;
        if (rowCount > 0) {
            var lastRowData = this.api.getSelectedNodes()[rowCount - 1].data;
            aircraftID = lastRowData.AssetID;
        }
    }

    editAircraft(event: any): void {
        if (event) {
            let aircraftID = event.data.AssetID;

            _router.navigate(["/admin/editAsset", aircraftID]);
        } else {
            if (aircraftID == undefined || aircraftID == null || aircraftID == "") {
                this.sharedService.clearToast();
                this.sharedService.showToast({ severity: 'warn', summary: 'no asset selected', detail: 'please select an asset.' });
                return;
            }

            _router.navigate(["/admin/editAsset", aircraftID]);
        }


    }

    createContainer(): void {

        let selectedRows = this.api.getSelectedRows();
        if (selectedRows && selectedRows.length > 0) {
            console.log(selectedRows.length);
            let assets: string[] = [];
            for (let index = 0; index < selectedRows.length; index++) {
                assets.push(selectedRows[index].AssetID);

            }
            this.CreateAssetSearchComponnts(assets);
        }
        else {

            this.sharedService.clearToast();
            this.sharedService.showToast({ severity: 'warn', summary: 'no asset selected', detail: 'please select asset(s).' });

        }
    }



    CreateAssetSearchComponnts(assetIds: string[]): void {


        let body = assetIds;
        this.sharedService.showLoader("Processing...");
        this._searchService.createAssetSearchComponents(body)
        .subscribe({
            next: (response) => {
                //   this.gridOptions.api.sizeColumnsToFit();
                this.sharedService.hideLoader();
                let msgDictionary = response;
                for (let msg of msgDictionary) {
                    this.sharedService.showToast({ severity: msg.Key, summary: msg.Key, detail: msg.Value });
                }
                //this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Asset Updated succesfully.' });
            },
            error: (error) => {
                this.sharedService.hideLoader();
                this.sharedService.clearToast();
                this.sharedService.showToast({ severity: 'warn', summary: 'warn', detail: error });
            }
        });

    }

    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];

        if (params.context.gridContext.canAddEditAirCraft) {
            result.push({
                name: "Add Asset",
                action: function () {
                    params.context.gridContext.router.navigate(["/admin/addAsset"]);
                },
                icon: params.context.gridContext.sharedService.addIconHTMLasString,
            });

            result.push({
                name: "Edit Asset",
                action: function () {
                    params.context.gridContext.aircraftID = null;
                    if (params.node) {
                        params.context.gridContext.aircraftID = params.node.data.AssetID;
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext.router.navigate(["/admin/editAsset", params.context.gridContext.aircraftID]);
                    }

                },
                icon: params.context.gridContext.sharedService.editIconHTMLasString,
            });

            let childResult: any[] = [];
            childResult.push({
                name: 'Excel',
                action: function () {
                    
                    params.api.exportDataAsExcel();
                    
                },
                icon: params.context.gridContext.sharedService.downloadLogFeedIconHTMLasString,
            });

            childResult.push({
                name: 'CSV',
                action: function () {
                    params.api.exportDataAsCsv();
                },
                icon: params.context.gridContext.sharedService.saveLogFeedIconHTMLasString,
            });

            result.push({
                name: 'Download',
                action: function () {


                },
                icon: params.context.gridContext.sharedService.downloadsFeedIconHTMLasString,
                subMenu: childResult,
            });
        }
        return result;
    }
    navigateToNextCell(params: any) {
        var previousCell = params.previousCellPosition;
        var suggestedNextCell = params.nextCellPosition;
        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        switch (params.key) {
            case KEY_DOWN:
                var nextRowIndex = previousCell.rowIndex + 1;
                var renderedRowCount = this.gridOptions.api.getModel().getRowCount();
                if (nextRowIndex >= renderedRowCount) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
                    rowNode.setSelected(true);
                    aircraftID = rowNode.data.AssetID;
                    return {
                        rowIndex: nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }


            case KEY_UP:
                var _nextRowIndex = previousCell.rowIndex - 1;
                if (_nextRowIndex < 0) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
                    rowNode.setSelected(true);
                    aircraftID = rowNode.data.AssetID;
                    return {
                        rowIndex: _nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw 'this will never happen, navigation is always on of the 4 keys above';
        }
    }
    //#endregion
}
