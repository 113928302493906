<div class="logout-center">
    <div id="ms-error-header" class="ms-pr">
        <h1 class="ms-core-pageTitle">

            Please close the browser to complete sign out.

        </h1>
        <div>
        </div>
    </div>
    <div id="ms-error">
        <div id="ms-error-top">

        </div>
        <div id="ms-error-content">
            <div id="ms-error-error-content">
                <div id="DeltaPlaceHolderMain">
                </div>
            </div>

            <div id="ms-error-gobackcont" class="ms-calloutLink">
                <a [attr.tabindex]="0" routerLink="/login" routerLinkActive="active" class="ms-calloutLink tablIndexFocus">Go back to site</a>
            </div>

        </div>
    </div>
</div>
