//Components
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';
import { NgForm } from '@angular/forms';
import { GridOptions } from "ag-grid-community";
import { UserService } from '../../../records/services/User/user.service';

import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { TemplateType } from '../user-management/user-management';
import { Subscription } from 'rxjs';
var _router_: Router;
var _selectedUser: any[];
@Component({
    selector: 'users',
    templateUrl: './users.component.html',
    providers: [UserService]
})

export class UsersComponent implements OnInit, OnDestroy {
    display: boolean = false;
    checkuser:boolean=true;
    public gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[] = [];
    public canAddEditUser: boolean = false;
    selectedUser: any[];

    public appHotkeys:  Hotkey[] = [];

    private totalCount: number;
    public templateType:number=TemplateType.User;
    public canUploadTemplate:boolean=false;
    private refreshUserGridSub: Subscription;
    //#region Initialization of Component
    constructor(private _sharedService: SharedService, private _router: Router, private _userService: UserService, private _hotkeysService: HotkeysService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.navigateToNextCell=this.navigateToNextCell.bind(this);
            this._sharedService.updateAssignedRole();
            this.UpdateRoles();
            this.getUserTenantId();
            _router_ = this._router;
            this.setHotKeys();
        }
    }
    setHotKeys() {

        let hotKeyObj : Hotkey = null;

        //Add user
        hotKeyObj = new Hotkey('ctrl+a', (event: KeyboardEvent): boolean => {
            if (_router_.url == "/admin/users")
                {
                    this.btnAddClick();
                }
                return false; // Prevent bubbling
            });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);


        //Edit user
        hotKeyObj = new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
            if (_router_.url == "/admin/users") {
                this.btnEditClick(null);
            }
                return false; // Prevent bubbling
            });

            this._hotkeysService.add(hotKeyObj);
            this.appHotkeys.push(hotKeyObj);
            
        hotKeyObj = new Hotkey('enter', (event: KeyboardEvent): boolean => {
            if (_router_.url == "/admin/users") {
                this.btnEditClick(null);
            }
                return false; // Prevent bubbling
            });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

    }
    ngOnInit() {
        if (this.canAddEditUser) {
            this.gridOptions = <GridOptions>
                {
                    rowSelection: 'single',

                    onGridReady: () => {
                        this.setUserGridColumns();
                        if (this.gridOptions.api) {
                            try {
                                this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridUserManagement'));
                                this.gridOptions.api.hideOverlay();
                            } catch (e)
                            {
                                console.log(e);
                            }

                        }
                    },

                    //suppressContextMenu: true,
                    getRowStyle: function (params: any)
                    {
                        if (params.data.UserType == 'Internal')
                        {
                            return { color: 'red' }
                        }
                        else
                        {
                            return { color: 'black' }
                        }

                    },
                    pagination: true,
                    paginationPageSize: 20,
                    getContextMenuItems: this.getCustomContextMenuItems,
                    context: {
                        gridContext: this
                    },
                    defaultColDef: {
                        sortable: true,
                        filter: true
                    }
                };
            this.populateUserGrid();

        }
        this.refreshUserGridSub = this._sharedService._isRefreshUsersGrid
        .subscribe({
            next: (response) => {
                this.populateUserGrid();
            },
            error: (error) => {
            }
        })
    }

    ngOnDestroy() {
        if(this.refreshUserGridSub)
        {
            this.refreshUserGridSub.unsubscribe();
        }
        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
    }

    //#endregion

    //#region Ag Grid Population Event
    populateUserGrid(): void {
        let userType: string = localStorage.getItem('userType').trim();
        if(this.checkuser){
        if (this._sharedService.UserRole.Admin_Sys_Admin && userType == 'Internal')
        {
            this._userService.getAllInternalExternalActiveUsers(this._sharedService.baseUrl, null)
            .subscribe({
                next: (response) => {
                    this.gridData = [];
                    this.gridData = response;
                    this.totalCount = response.length;
                    this.gridOptions.api?.sizeColumnsToFit();
                },
                error: (error) => {
                    this.gridData = [];
                    this.totalCount = 0;
                }
            });
        } else {
            this._userService.getAllExternalActiveUsers(this._sharedService.baseUrl, null)
            .subscribe({
                next: (response) => {
                    this.gridData = [];
                    this.gridData = response;
                    this.totalCount = response.length;
                    this.gridOptions.api?.sizeColumnsToFit();
                },
                error: (error) => {
                    this.gridData = [];
                    this.totalCount = 0;
                }
            });
        }}else{
            if (this._sharedService.UserRole.Admin_Sys_Admin && userType == 'Internal')
            {
                this._userService.getAllInternalExternalUsers(this._sharedService.baseUrl, null)
                .subscribe({
                    next: (response) => {
                        this.gridData = [];
                        this.gridData = response;
                        this.totalCount = response.length;
                        this.gridOptions.api.sizeColumnsToFit();
                    },
                    error: (error) => {
                        this.gridData = [];
                        this.totalCount = 0;
                    }
                });    
            } else {
                this._userService.getAllExternalUsers(this._sharedService.baseUrl, null)
                .subscribe({
                    next: (response) => {
                        this.gridData = [];
                        this.gridData = response;
                        this.totalCount = response.length;
                        this.gridOptions.api.sizeColumnsToFit();
                    },
                    error: (error) => {
                        this.gridData = [];
                        this.totalCount = 0;
                    }
                });
            }
        }
        _selectedUser = [];
    }
    //#endregion
    //#region Set Columns of Browse Ag Grid
    setUserGridColumns(): void {
        this.columnDefs = [];
        this.columnDefs =
            [
                { headerName: 'First Name', field: 'FirstName', menuTabs: ['filterMenuTab'] },
                { headerName: 'Last Name', field: 'LastName', menuTabs: ['filterMenuTab'] },
                { headerName: 'Email', field: 'Email', menuTabs: ['filterMenuTab'],minWidth:250 },
                { headerName: 'User Group', field: 'UserGroup', menuTabs: ['filterMenuTab'], cellRenderer: this.tagsCellRenderer },
                {
                    headerName: 'Status', field: 'Status', filter: false, suppressMenu: true, valueFormatter: function (params: any) {
                        if (params.value == true) {
                            return "Active";
                        }
                        else {
                            return "Inactive";
                        }
                    }
                }
            ];

    }

    //#endregion

    //#region Ag-Grid Events
    updateSelectedUserRow(event: any) {
        this.selectedUser = [];
        this.selectedUser = event.data.Id;
        _selectedUser = this.selectedUser;
    }
    //#endregion

    //#region Edit User Events
    btnEditClick(event: any) {
        if (event) {
            let id = event.data.Id;
            this._router.navigate(['/admin/usermanagement', id]);
        }
        else {
            if (_selectedUser && _selectedUser.length > 0) {
                let id = _selectedUser; //this.selectedUser;
                this._router.navigate(['/admin/usermanagement', id]);
            }
            else {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'No User Selected', detail: 'Please select an user.' });
            }
        }
    }
    //#endregion
    //#region Add User Events
    btnAddClick() {
        this._router.navigate(['/admin/usermanagement']);
    }
    //#endregion
    //#region Copy User Events
    btnCopyClick() {
        this.display = true;
    }

    onSubmit(form: NgForm) {
        this.display = false;
    }

    btnCancelClick(form: NgForm) {
        this.display = false;
    }
    //#endregion

    //#region User Roles Assignment
    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }
        this.canAddEditUser = this._sharedService.UserRole.Admin_User;
        this.canUploadTemplate=this._sharedService.UserRole.Admin_Sys_Admin;
    }
    //#endregion
    //#region Helper Methods

    tagsCellRenderer(params: any) {
        let html: string = '';

        if (params.value)
        {
            html = html + '<span class="padding-2 label" style= "background-color:' + params.data.ColorCode + ';">' + params.value + '</span>';
        }

        var eDiv = document.createElement('div');
        eDiv.innerHTML = html

        return eDiv;
    }

    getUserTenantId() {
        this._userService.getTenantId()
        .subscribe({
            next: (response) => {
                this._sharedService.setLoggedInTenantId(response);
            },
            error: (error) => {
            }
        })
    }
    //#endregion
    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];

        if (params.context.gridContext.canAddEditUser) {
            result.push({
                name: "Add User",
                action: function () {
                    params.context.gridContext.btnAddClick()
                },
                icon: params.context.gridContext._sharedService.addIconHTMLasString,
            });

            result.push({
                name: "Edit User",
                action: function () {
                    params.context.gridContext.selectedUser = null;
                    if (params.node) {
                        params.context.gridContext.selectedUser = params.node.data.Id;
                        _selectedUser = params.node.data.Id;
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext.btnEditClick()
                    }

                },
                icon: params.context.gridContext._sharedService.editIconHTMLasString,
            });

            let childResult: any[] = [];
            childResult.push({
                name: 'Excel',
                action: function () {
                    
                    params.api.exportDataAsExcel();
                    
                },
                icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
            });

            childResult.push({
                name: 'CSV',
                action: function () {
                    params.api.exportDataAsCsv();
                },
                icon: params.context.gridContext._sharedService.saveLogFeedIconHTMLasString,
            });

            result.push({
                name: 'Download',
                action: function () {


                },
                icon: params.context.gridContext._sharedService.downloadsFeedIconHTMLasString,
                subMenu: childResult,
            });
        }
        return result;
    }
    navigateToNextCell(params: any) {
        var previousCell = params.previousCellPosition;
        // var previousCell = params.previousCellDef;
        var suggestedNextCell = params.nextCellPosition;
        // var suggestedNextCell = params.nextCellDef;
        var self = this;
        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        switch (params.key) {
            case KEY_DOWN:
                var nextRowIndex = previousCell.rowIndex + 1;
                var renderedRowCount = this.gridOptions.api.getModel().getRowCount();
                if (nextRowIndex >= renderedRowCount) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedUser = [];
                    this.selectedUser = rowNode.data.Id;
                    _selectedUser = this.selectedUser;
                    return {
                        rowIndex: nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }


            case KEY_UP:
                var _nextRowIndex = previousCell.rowIndex - 1;
                if (_nextRowIndex < 0) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedUser = [];
                    this.selectedUser = rowNode.data.Id;
                    _selectedUser = this.selectedUser;
                    return {
                        rowIndex: _nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw 'this will never happen, navigation is always on of the 4 keys above';
        }
    }
    showAllUsersRecords(){
        this.populateUserGrid();
    }
    //#endregion

}