<form *ngIf="display" #changePswdForm="ngForm">
    <p-dialog *ngIf="display" id="changepswdDlg" [(visible)]="display" appendTo="body" showEffect="fade" [modal]="true"
      [style]="{width: '500px'}" [resizable]="false">
    
      <!--appendTo="body" showEffect="fade" [modal]="true"-->
      <!--[modal]="true"-->
      <!--showEffect="fade"-->
      <ng-template pTemplate="header" >
        Authentication Issue
      </ng-template>
      
        <!--(ngSubmit)="onSubmit(changePswdForm)"-->
        <div>
          <!--class="upload-dialog" style="overflow-y: hidden !important;overflow-x:hidden !important"-->
          <div class="smart-form">
            <div class="row custom-bottom-padding">
              <label class="label">
                {{error}}
                <!-- Email does not exist: {{email}} -->
                <br>
                <!-- href={{authConfigConfig.logoutUrl}} -->
                <a *ngIf="ssoError.error.error == 'invalid_User'" (click)="redirect()" > click here to logout </a> 
              </label>
            </div>
          </div>
        </div>
    </p-dialog>
    </form>