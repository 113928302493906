import { Component, Input, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
import { AzureBlobService } from '../../../shared/services/AzureBlob/azure-blob.service';
import { DocumentTypeService } from '../../../admin/services/document-type/document-type.service';
import { BlobMetaData } from '../../../shared/services/AzureBlob/blob-metadata';
import { IConfig } from '../../../shared/helpers/config';
import { NgForm } from '@angular/forms';
import * as moment from 'moment/moment';
import { GridOptions } from "ag-grid-community";
import { container, SearchContainerModel } from '../../../records/services/container/container';
import { AssetService } from '../../../admin/services/asset/asset.service';
import { ContainerService } from '../../../records/services/container/container.service';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { CustomDateComponent } from '../../../shared/components/custom-date/custom-date.component';
import { QualityStatusService } from '../../../admin/services/qualitystatus/qualitystatus.service';
//#region Global Variables for Ag-Grid
var dateFormatF: string;
var dateSeparatorS: string;
var dayD: number;
var monthM: number;
var yearY: number;
var sharedService: SharedService;
var _gridOptions: GridOptions
var _searchQuery: string;
var dateTimeConfig: IDateFormatInfo;
var conColumnDefs_1: any[];
var statusList: any[] = [];
var columnDefs_1: any[];

//#endregion



@Component({
    selector: 'container-selection',
    templateUrl: './container-selection.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DocumentTypeService, ConfirmationService, AzureBlobService, AssetService, ContainerService, QualityStatusService]
})



export class ContainerSelectionComponent implements OnInit, OnDestroy {
    @Input() screenmode: string;
    isDisplayDialog: boolean = false;
    DocType: SelectItem[];
    selectedDocType: SelectItem;
    dialogTitle: string;
    bulkDialogTitle: string;
    uploadUrl: string;
    uploadedFile: any = [];
    uploadedFiles: any[] = [];
    fileNames: any[] = [];
    metaData: BlobMetaData;
    metaDataBulk: BlobMetaData;
    tags: SelectItem[] = [];
    selectedTags: string[] = [];
    parentWoNumber: string;

    //dateFormatCalender = "";
    dateFormatCalender = "";
    dateSeparator = "";
    dateFormat = "";
    configuration: IConfig[] = [];
    public bulkUploadArray: any = [];
    private day: number;
    private month: number;
    private year: number
    isSubmitted: boolean = false;
    public canUpload: boolean = false;
    public canBulkUpload: boolean = false;
    public bulkFile: any[] = [];
    public isNonPdfFileExist: boolean = false;
    containerModel: SearchContainerModel;
    selectedContainer: container;
    public gridOptions: GridOptions;
    conGridOptions: GridOptions;
    assets: SelectItem[] = [];
    conTypes: SelectItem[] = [];
    conGridData: any[] = [];
    conTotalCount: number = 0;
    categories: SelectItem[] = [];
    conColumnDefs: any[];
    conRowSelected: boolean = false;
    isuploadBulkDoc: boolean = true;
    FileTypeList: SelectItem[];
    selectedBulkUrl: string;
    VideoUrlList: SelectItem[] = [];
    public referenceKeyLabel: string = "";

    public Container_MXR_Columns: any[] = [];
    public Container_LOG_Columns: any[] = [];
    public Container_GEN_Columns: any[] = [];
    public Container_DOC_Columns: any[] = [];
    public Container_BND_Columns: any[] = [];

    public Container_MXR_Sort_By: string = "";
    public Container_LOG_Sort_By: string = "";
    public Container_GEN_Sort_By: string = "";
    public Container_DOC_Sort_By: string = "";
    public Container_BND_Sort_By: string = "";
    public dateConfig: IConfig[] = [];

    qualityStatusList: any[] = [];

    constructor(private _sharedService: SharedService, private _azureBlobService: AzureBlobService, private _documentTypeService: DocumentTypeService, private confirmationService: ConfirmationService,
        private cRef: ChangeDetectorRef, private _router: Router, private _assetService: AssetService, private _containerService: ContainerService, private _hotkeysService: HotkeysService, private _qualityStatusService: QualityStatusService) {
        dateTimeConfig = this._sharedService.getDateTimeFormatConfiguration();
        this.dateConfig = this._sharedService.getConfiguration();
        this.populateAsset()

    }
    ngOnInit() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        this.populateQualityStatusDropdown();
        this.referenceKeyLabel = this._sharedService.getReferenceKeyLabel();
        this.setCalenderDateFormat();
        // this.UpdateRoles();
        this.prepareContainerGridColumns();
        this.conGridOptions = <GridOptions>
            {
                rowSelection: 'single',

                onGridReady: () => {
                    this.setContainerGridColumns();
                },
                suppressContextMenu: true,
                defaultColDef: {
                    resizable: true,
                },
                components: { agDateInput: CustomDateComponent },
            }
        this.containerModel = new SearchContainerModel("", null, null, false);

        this.getDateFormatInfo();

    }
    ngOnDestroy() {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.

    }

    //#region Bulk Upload Code

    onContainerOpenClick() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        this.containerModel = new SearchContainerModel("", null, null, false);
        this.conGridData = [];
        this.changeButtonsStatus(false);

        this.isDisplayDialog = true;
        this.cRef.markForCheck();

    }

    btnCancelClick(form: NgForm) {
        form.resetForm();
        this.isDisplayDialog = false;
        this.changeButtonsStatus(false);
        this.containerModel = new SearchContainerModel("", null, null, false);
        this.conGridData = [];
    }
    adjustDlgHeight(event: any) {
        let tskDlgfrm = document.getElementById('containerSelection');

        if (tskDlgfrm) {
            tskDlgfrm.style.height = '93vh';
            tskDlgfrm.style.overflowX = 'hidden';
            this.cRef.markForCheck();
        }
    }
    //#endregion

    setCalenderDateFormat() {
        this.configuration = this._sharedService.getConfiguration();
        this.dateFormat = this.configuration.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
        this.dateSeparator = this.configuration.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
        this.day = Number(this.configuration.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
        this.month = Number(this.configuration.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
        this.year = Number(this.configuration.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
        let dateParts: string[] = this.dateFormat.split(this.dateSeparator);

        if (this.year == 0) {
            this.dateFormatCalender = (dateParts[this.year].substr(0, 2) + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.day]).toLowerCase();
        }
        else if (this.year == 2) {
            if (this.month == 1) {
                this.dateFormatCalender = (dateParts[this.day] + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
            }
            else if (this.month == 0) {
                this.dateFormatCalender = (dateParts[this.month] + this.dateSeparator + dateParts[this.day] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
            }

        }
        else {
            this.dateFormatCalender = "yy-mm-dd"
        }

    }


    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }

    //#region Container
    populateQualityStatusDropdown() {
        let qualityStatusListValues = this._sharedService.getContainerQualityStatusList();
        if (qualityStatusListValues == null || qualityStatusListValues.length == 0) {
            this._qualityStatusService.getActiveQualityStatusData(null)
            .subscribe({
                next: (response) => {
                    try {
                        this.qualityStatusList = response;
                        statusList = response;
                    } catch (ex) {
                        this.qualityStatusList = response;
                        statusList = response;
                        console.log(ex);
                    }
                },
                error: (error) => {
                }
            });
        } else {
            this.qualityStatusList = qualityStatusListValues;
            statusList = qualityStatusListValues;
        }
    }

    populateAsset() {
        // let userRole: string = localStorage.getItem('roles').trim();
        // let body = { UserRole: userRole };
        this._assetService.getAssetWithAssetIdByUserRole()
        .subscribe({
            next: (response) => {
                this.assets = response;
            },
            error: (error) => {
                this.assets = [];
                console.log(error);
            }
        });
    }

    populateContainerType(category: string) {
        this.conTypes = [];
        let body: container = {
            Category: category
        }
        this._containerService.getContainerType(body)
        .subscribe({
            next: (response) => {
                let res = response;
                for (let i = 0; i < res.length; i++) {
                    this.conTypes.push({ value: res[i].Type, label: res[i].Type })
                }
                this.cRef.markForCheck();
            },
            error: (error) => {
                this.conGridData = [];
                this.conTotalCount = 0;
            }
        });
    }

    populateCategoryByAssetID(assetID: string): void {
        this.categories = [];
        this._assetService.getAssetbyID(assetID)
        .subscribe({
            next: (response) => {
                this.categories = [];
        
                if (response.MXRecords) {
                    this.categories.push({ value: 'MXR', label: 'MX Records' });
                }
                if (response.LogBooks) {
                    this.categories.push({ value: 'LOG', label: 'Log Books' });
                }
                if (response.General) {
                    this.categories.push({ value: 'GEN', label: 'General' });
                }
                if (response.Documents) {
                    this.categories.push({ value: 'DOC', label: 'Documents' });
                }
                if (response.Binders) {
                    this.categories.push({ value: 'BND', label: 'Binders' });
                }
            },
            error: (error) => {
                this.categories = [];
                console.log(error);
            }
        });
    }

    selectedComboCatergorySelection
    onChangeCategory(event: any) {
        this.conGridData = [];
        this.conRowSelected = false;
        //Rizwan
        this._sharedService.recordType = event.value;
        this.selectedComboCatergorySelection = event.value;
        this.setContainerGridColumns();

        this.populateContainerType(event.value);
    }
    onChangeAsset(event: any) {
        this.conGridData = [];
        this.conRowSelected = false;
        //US-8181 //let assetid = event.value.split('|')[3].trim();
        let assetDataObj = JSON.parse(event.value);
        let assetid = assetDataObj.AssetID;
        this.populateCategoryByAssetID(assetid);
    }

    populateContainerGrid(): void {

        this._containerService.getFilteredContainer(this.containerModel)
        .subscribe({
            next: (response) => {
                this.conGridData = response;
                this.conTotalCount = this.conGridData.length;
                this.cRef.markForCheck();
                //this.gr.api.sizeColumnsToFit();
            },
            error: (error) => {
                this.conGridData = [];
                this.conTotalCount = 0;
                console.log(error);
            }
        });
    }

    prepareContainerGridColumns(): void {

        var appConfigs = this._sharedService.getConfiguration();
        if (appConfigs != null) {
            this.Container_MXR_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_MX_Records_Column_Setting')[0].Value);
            this.Container_LOG_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Log_Books_Column_Setting')[0].Value);
            this.Container_GEN_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_General_Column_Setting')[0].Value);
            this.Container_DOC_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Documents_Column_Setting')[0].Value);
            this.Container_BND_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Binders_Column_Setting')[0].Value);
            this.Container_MXR_Sort_By = appConfigs.filter(x => x.Key == 'Container_MXR_Sort_By')[0].Value;
            this.Container_LOG_Sort_By = appConfigs.filter(x => x.Key == 'Container_LOG_Sort_By')[0].Value;
            this.Container_GEN_Sort_By = appConfigs.filter(x => x.Key == 'Container_GEN_Sort_By')[0].Value;
            this.Container_DOC_Sort_By = appConfigs.filter(x => x.Key == 'Container_DOC_Sort_By')[0].Value;
            this.Container_BND_Sort_By = appConfigs.filter(x => x.Key == 'Container_BND_Sort_By')[0].Value;
        }
    }


    setContainerGridColumns(): void {
        if (!this.selectedComboCatergorySelection) {
            return;
        }
        this.conColumnDefs = [];
        columnDefs_1 = [];
        var containerGridCols: any[];
        var container_Col_Sort_On_By: string = '';
        if (this.selectedComboCatergorySelection.toUpperCase() == 'MXR') {
            containerGridCols = this.Container_MXR_Columns;
            container_Col_Sort_On_By = this.Container_MXR_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'LOG') {
            containerGridCols = this.Container_LOG_Columns;
            container_Col_Sort_On_By = this.Container_LOG_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'GEN') {
            containerGridCols = this.Container_GEN_Columns;
            container_Col_Sort_On_By = this.Container_GEN_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'DOC') {
            containerGridCols = this.Container_DOC_Columns;
            container_Col_Sort_On_By = this.Container_DOC_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'BND') {
            containerGridCols = this.Container_BND_Columns;
            container_Col_Sort_On_By = this.Container_BND_Sort_By;
        }
        containerGridCols.forEach((col, index) => {
            let _filter = 'set';
            if (col.FieldName == 'Date' || col.FieldName == 'EndDate' || col.FieldName == 'ScannedOn') {
                _filter = 'agDateColumnFilter';
            }
            let gridCol: any;
            if (col.FieldName == 'ContainerQualityStatus') {
                gridCol = {
                    headerName: col.ColumnLabel,
                    field: 'QualityStatus',//'QualityStatus---ID',//24-02-2021
                    width: col.Width,
                    filter: _filter,
                    tooltipField: col.FieldName,
                    menuTabs: ['filterMenuTab'],
                    hide: !col.IsVisible,
                    filterParams: this.gridStatusFilterParams,
                };
            }
            else {
                gridCol = {
                    headerName: col.ColumnLabel,
                    field: col.FieldName,
                    width: col.Width,
                    filter: _filter,
                    tooltipField: col.FieldName,
                    menuTabs: ['filterMenuTab'],
                    hide: !col.IsVisible
                };
            }
            if (col.FieldName == 'Date' || col.FieldName == 'EndDate' || col.FieldName == 'ScannedOn') {
                gridCol.filterParams =
                {
                    comparator: this.dateComparater,
                    inRangeInclusive: 'true'
                }
                gridCol.valueFormatter = function (params: any) {
                    if (params.value != '' && params.value != null) {
                        let dateString = params.value.substring(0, 10);
                        return moment(dateString).format(dateFormatF.toUpperCase());
                    }
                }
            }
            else if (col.FieldName == 'ContainerQualityStatus') {
                gridCol.filterParams = {
                    cellRenderer: this.statusCellRenderer, // formats filter values
                }
                gridCol.cellRenderer = this.statusCellRenderer;
            }
            columnDefs_1.push(gridCol);
        });
        this.conColumnDefs = columnDefs_1;
        let sortOnCol = container_Col_Sort_On_By.split("||")[0];
        let orderBy = container_Col_Sort_On_By.split("||")[1];
        if (sortOnCol == "ContainerQualityStatus") {
            sortOnCol = "QualityStatus";
        }
        this.conColumnDefs.forEach(function (col, index) {
            if (col.field == sortOnCol) {
                this.conColumnDefs[index].sort = orderBy;
            }
        }.bind(this));
    }


    gridStatusFilterParams = { cellRenderer: this.statusCellRenderer };

    statusCellRenderer(params: any) {
        let html: string = '';
        var eDiv = document.createElement('div');
        if (params.value) {
            try {
                let currentStatus: any = statusList.filter(x => x.QualityStatusID == params.value)[0];
                if (currentStatus && currentStatus.Name) {
                    eDiv.style.background = currentStatus.CSSClass;
                    html = html + currentStatus.Name;
                } else {
                    let currentStatus: any = statusList.filter(x => x.Name == params.value)[0];//params.value)[0];
                    if (currentStatus && currentStatus.Name) {
                        eDiv.style.background = currentStatus.CSSClass;
                        html = html + currentStatus.Name;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            return params.value;
        }
        eDiv.style.height = '100%';
        eDiv.style.width = '100%';
        eDiv.style.color = 'white';
        eDiv.style.textAlign = 'left';
        eDiv.style.paddingLeft = '5px';
        eDiv.innerHTML = html;
        return eDiv;
    }

    setContainerGridColumns_BackUp(): void {
        this.conColumnDefs = [];
        this.conColumnDefs =
            [
                {
                    headerName: 'Type', field: 'Type', filter: 'set', tooltipField: 'Description', menuTabs: ['filterMenuTab'], minWidth: 70,
                    //, filterParams: { comparator: this.ContainerTypeComparater }
                },
                {
                    headerName: 'Date', field: 'Date', filter: 'agDateColumnFilter', filterParams:
                    {
                        comparator: this.dateComparater,
                        inRangeInclusive: 'true'
                    }, valueFormatter: this.dateValueFormatter, sort: 'desc', menuTabs: ['filterMenuTab'], minWidth: 90,
                },
                {
                    headerName: 'End Date', field: 'EndDate', filter: 'agDateColumnFilter', filterParams: {
                        comparator: this.dateComparater,
                        inRangeInclusive: 'true'

                    }, valueFormatter: this.dateValueFormatter
                    , menuTabs: ['filterMenuTab'], minWidth: 90,
                },
            ];

        this.conColumnDefs.push({ headerName: this.referenceKeyLabel, field: 'ReferenceKey', tooltipField: 'ReferenceKey', menuTabs: ['filterMenuTab'], minWidth: 90 });
        this.conColumnDefs.push({ headerName: 'Description', field: 'Description', tooltipField: 'Description', menuTabs: ['filterMenuTab'], minWidth: 110 });
        //this.conColumnDefs.push({ headerName: 'Title', field: 'ReferenceKey', tooltipField: 'ReferenceKey', menuTabs: ['filterMenuTab'], minWidth: 90 });
    }

    formatContainerGrid(event: any) {
        this.conGridOptions.api.sizeColumnsToFit();
    }

    onContainerRowClick(event: any) {
        this.conRowSelected = true;
        this.selectedContainer = event.data;
    }
    onContainerSelected() {
        if (this.selectedContainer) {
            if (this.screenmode == 'ADD') {
                this._sharedService.onContainerSelectedFromComponentAdd(this.selectedContainer);
            }
            else if (this.screenmode == 'EDIT') {
                this._sharedService.onContainerSelectedFromComponentEdit(this.selectedContainer);
            }

            this.isDisplayDialog = false;
            this.conGridData = [];
            this.cRef.markForCheck();
        }
    }
    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).split(dateTimeConfig.dateSeparator);
            var day = Number(dateParts[Number(dateTimeConfig.day)].substring(0, 2));
            var month = Number(dateParts[Number(dateTimeConfig.month)]) - 1;
            var year = Number(dateParts[Number(dateTimeConfig.year)]);

            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        }
        else {
            return -1;
        }
    }

    dateValueFormatter(params: any) {
        if (params.value != "" && params.value != null) {

            let dateString = params.value.substring(0, 10);
            return moment(dateString).format(dateTimeConfig.dateformat.toUpperCase());
        }
    }

    //#endregion

    //// #region date Format Settings

    getDateFormatInfo(): any {

        if (this.dateConfig != undefined) {
            dateFormatF = this.dateConfig.filter(x => x.Key == 'DateFormat').map(({ Value }) => Value).toString();
            dateSeparatorS = this.dateConfig.filter(x => x.Key == 'DateSeparater').map(({ Value }) => Value).toString();
            dayD = Number(this.dateConfig.filter(x => x.Key == 'DayIndex').map(({ Value }) => Value));
            monthM = Number(this.dateConfig.filter(x => x.Key == 'MonthIndex').map(({ Value }) => Value));
            yearY = Number(this.dateConfig.filter(x => x.Key == 'YearIndex').map(({ Value }) => Value));
        } else {
            dateFormatF = localStorage.getItem('DateFormat');
            dateSeparatorS = localStorage.getItem('DateSeparater');
            dayD = Number(localStorage.getItem('DayIndex'));
            monthM = Number(localStorage.getItem('MonthIndex'));
            yearY = Number(localStorage.getItem('YearIndex'));

        }
    }
}
