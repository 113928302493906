import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {SharedService } from '../../../shared/services/shared.service';
import { DocumentType } from './document-type'

@Injectable()
export class DocumentTypeService {

    //#region Global Variables

    _lstDocumentType: DocumentType[];

    //#endregion


    constructor(private _sharedService: SharedService ,  private http: HttpClient) {
    }
    
     /// start region service methods
    getDocumentTypes(category?:string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/DocumentType/GetDocumentTypeCategory?category=${category}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getDocumentTypesDetail(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/DocumentType/GetDocumentType`, '', this._sharedService.getReqOptWithAccessToken());
    }


    getDocumentTypebyID(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/DocumentType/Detail?docTypeID=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }


    updateDocumentType(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/DocumentType/Update`, body, this._sharedService.getReqOptWithAccessToken());
    }


    addDocumentType(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/DocumentType/Add`,
            body,
            this._sharedService.getReqOptWithAccessToken());
    }
     /// end region service methods

    ////////////////////////////// start region data methods ////////////////////////////
    setDocumentTypeList(documentTypeList: any): void {
        this._lstDocumentType = documentTypeList;
        
    }

    getDocumentTypeList(): any {
        return this._lstDocumentType;
    }

    ////////////////////////////////////////// end region data methods /////////////////////
  


}