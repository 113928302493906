<div class="row adduserscls">
    <p-confirmDialog></p-confirmDialog>
    <article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable no-padding standardpadding" style="margin-left:0px"
        id="col1">
        <div class="jarviswidget" id="wid-id-0" data-widget-colorbutton="false" data-widget-editbutton="false"
            data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false">

            <header>
                <span class="widget-icon"><i class="glyphicon glyphicon-list-alt"></i></span>
                <h2>Feed Name: {{FeedName}}</h2>
                <div class="jarviswidget-ctrls" title="Mapping Help">
                    <a title="Mapping Help" class="button-icon hand-cursor  tablIndexFocus" [attr.tabindex]="0" id="btnmappinghelp" (keyup.enter)="btnMappingHelpClick()" (click)="btnMappingHelpClick()">
                        <i class="glyphicon glyphicon-question-sign"></i>
                    </a>
                </div>
                <div class="jarviswidget-ctrls" title="Copy Mapping">
                    <a title="Copy Mapping" class="button-icon hand-cursor tablIndexFocus" [attr.tabindex]="0" id="btncopymapping" (keyup.enter)="btnCopyClick()" (click)="btnCopyClick()">
                        <i class="glyphicon glyphicon-copy"></i>
                    </a>
                </div>
                <div class="jarviswidget-ctrls" title="Show/Hide Template">
                    <a title="Show/Hide Template" class="button-icon hand-cursor tablIndexFocus" [attr.tabindex]="0" id="btnShowHideTemplate"  (keyup.enter)="btnShowHideTemplateClick()" (click)="btnShowHideTemplateClick()">
                        <i class="glyphicon glyphicon-resize-horizontal"></i>
                    </a>
                </div>
            </header>

            <form #fieldMappingForm="ngForm">
                <div class="jarviswidget" id="wid-id-0" data-widget-colorbutton="false" data-widget-editbutton="false"
                    data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false">


                    <div class="widgetdiv" style="padding-top: 0px;padding-right: 0px;">

                        <div class="widget-body">

                            <section id="widget-grid0" class="">
                                <div class="navbar-form form-group">

                                    <div id="myTabContent1" class="tab-content">
                                        <div class="tab-pane fade active in" id="s1">
                                            <div class="smart-form">
                                                <div class="row">
                                                    <div id="templatePanel" class="form-group col-sm-4" style="padding: 0px;">
                                                            <div class="panel panel-default" style="margin-top: 7px;">
                                                                    <div class="panel-body">
                                                        <div class="row">

                                                                    <label class="label" style="margin-top: 5px !important;margin-left: 20px;">
                                                                        Fix Value:

                                                                    </label>
                                                                    <label class="input  tablIndexFocus"  [attr.tabindex]="0"  style="margin-left: 18px;width: 100%;">
                                                                           <input id="fixedValue" type="text" name="fixedValue"
                                                                            [(ngModel)]="fixedValueText"
                                                                            #fixedValue="ngModel" [disabled]="screenName=='export'" [ngClass]="{'readonlyinput2': screenName=='export'}"/>

                                                                        </label>
                                                        </div>

                                                                <div class="col-md-12 picklist-padding" style="overflow-x:hidden">
                                                                    <ag-grid-angular #agGridMappingList id="agGridMappingList" (rowDoubleClicked)="templateRowDoubleClicked($event)"
                                                                        [gridOptions]="gridOptionsMappingList"
                                                                        [columnDefs]="columnDefsMappingList" [rowData]="gridDataMappingList"
                                                                        (rowClicked)='clickedMappingListGridRow($event)'
                                                                        filter=false style="height: 51vh !important;width:100% !important"
                                                                        class="ag-theme-balham">
                                                                    </ag-grid-angular>

                                                                </div>
                                                            

                                                            </div>
                                                       
                                                        </div>
                                                        <div class="detailtext" *ngIf="isToolBoxFeed==false && (targetType=='Records' || targetType=='Status' || targetType=='Repair' || targetType=='Transition')">
                                                            Tokens are:
                                                            <label   *ngIf="isToolBoxFeed==false && (targetType=='Records' || targetType=='Transition' || targetType=='Status' || targetType=='Repair')" class="token" (click)="btnAssignToken(_sharedService.Token_ASN)">{{_sharedService.Token_ASN }}</label>
                                                            <label *ngIf="isToolBoxFeed==false && (targetType=='Status' || targetType=='Repair')" class="token"
                                                                (click)="btnAssignToken(_sharedService.Token_DataType)">{{_sharedService.Token_DataType }}</label>
                                                        </div>
                                                    </div>
                                                    <div id="templatePanelButton" class="form-group col-sm-1" style="height:60vh;overflow-x:hidden;padding:0px" >
                                                        <button title="Assign" style="margin-top: 170% !important;margin-left: 30px;" (click)="btnAssignClicked($event)">></button>
                                                    </div>
                                                    <div id="mappingPanel" class="form-group col-sm-7" style="padding-left:0px">
                                                        <div class="panel panel-default">
                                                            <div class="panel-body">
                                                                <div class="col-md-12 picklist-padding" style="overflow-x:hidden">
                                                                    <ag-grid-angular #agGridMapping id="agGridMapping"
                                                                        [gridOptions]="gridOptionsMapping" [columnDefs]="columnDefsMapping"
                                                                        [rowData]="gridDataMapping" (cellClicked)="onCellClickedMap($event)"
                                                                        (rowClicked)='clickedMappingGridRow($event)'
                                                                        [rowDragManaged]="true" [animateRows]="true"
                                                                        filter=false style="height:61.5vh !important;width:100% !important"
                                                                        class="ag-theme-balham">
                                                                        <!--[rowDragManaged]="true" [animateRows]="true"-->
                                                                    </ag-grid-angular>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>

                            <div class="widget-footer">
                                <button type="button" class="btn btn-primary margin-right-3px" [disabled]="isSubmitted"
                                    (click)="btnSaveClick(fieldMappingForm)">
                                    Save
                                </button>

                                <button type="button" class="btn btn-default" [disabled]="isSubmitted" (click)="btnCancelClick(fieldMappingForm)">
                                    Cancel
                                </button>
                            </div>
                        </div>

                    </div>


                </div>

            </form>
        </div>

    </article>

    <!-- <p-dialog [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" [style]="{width: '1020px', height:'600px'}" [resizable]="false"> -->
    <p-dialog id="copymappingdlg" [(visible)]="display" showEffect="fade" [modal]="true" [style]="{ 'width': '1020px', 'top':'-90px'}" [resizable]="false">
        <ng-template pTemplate="header" >
            Copy Mapping
        </ng-template>
        <div class="upload-dialog">
            <!--style="overflow-y: hidden !important;overflow-x:hidden !important"-->
            <div class="smart-form">

                <div class="row" style="margin-top: 5px;">
                    <div class="form-group col-sm-12">
                        <ag-grid-angular id="agGridFeedDataCopy" #agGridFeedDataCopy [gridOptions]="gridOptions"
                            [columnDefs]="columnDefs" (rowClicked)='clickedFeedRow($event)' [rowData]="gridData"
                            sortable="true" filter=true style="width: 100% !important;height:500px !important;" class="ag-theme-balham">
                        </ag-grid-angular>
                    </div>
                </div>
            </div>
        </div>
        <!--class="upload-dialog"-->
        <!--class="jqgridboxcontainer"-->
        <!-- <div class="row" style="margin-top: 5px;">
                    <div *ngIf="this.gridData.length > 0" class="form-group col-sm-12">
                <ag-grid-angular id="agGridFeedDataCopy"  #agGridFeedDataCopy [gridOptions]="gridOptions" [columnDefs]="columnDefs" (rowClicked)='clickedFeedRow($event)' [rowData]="gridData" sortable="true" filter=true style="width: 100% !important;height:500px !important;" class="ag-theme-balham">
                </ag-grid-angular>
            </div>
        </div>-->
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="submit" pButton icon="fa fa-check" class="btn btn-primary minzindex" label="Copy" (click)="onSubmit()"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" label="Cancel"
                    (click)="btnCancelDialogueClick()"></button>
            </div>
        </ng-template>

    </p-dialog>


    <p-dialog *ngIf="displayHelpWindow" [(visible)]="displayHelpWindow" showEffect="fade" [modal]="false" [style]="{width: '50%'}" [resizable]="false">
        <ng-template pTemplate="header" >
            {{_dataType}} Mapping Help
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row" style="margin-top: 5px;">
                    <div class="form-group col-sm-12" [innerHTML]=htmlMappingHelp>
                    </div>

                </div>
            </div>
        </div>

        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" label="Close"
                    (click)="btnCancelMappingHelpClick()"></button>
            </div>
        </ng-template>

    </p-dialog>

</div>
<form #docDefinitionListingMapForm="ngForm">
    <p-dialog [(visible)]="displayDocDefinitionDlg" *ngIf="displayDocDefinitionDlg" showEffect="fade" [modal]="true" [style]="{width: '551px', height:'500px'}" [resizable]="false" [closeOnEscape]="false" [closable]="false">
        <ng-template pTemplate="header" >
            Mapping updated succesfully.
        </ng-template>
        <div class="upload-dialog" style="max-height: 365px;height: 365px;">
            <div class="smart-form">
                <div class="row"> Following RDOC document definition(s) have been deactivated.</div>
                <div class="row">Kindly visit RDOC and activate document definitions after verifying Metadata API setting.</div>
                <div class="row" style="max-height: 270px !important;overflow-y: auto !important;">
                        <table class="table table-hover">
                            <tbody>
                                <tr *ngFor="let docDefinitionName of docDefinitionList">
                                    <td>{{docDefinitionName}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" (click)="btnOkClick()" label="Ok"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>
