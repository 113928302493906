import { Component, OnInit,ViewChild,Input,Output,ElementRef,AfterViewInit,OnChanges } from '@angular/core';
import Cropper from 'cropperjs';

@Component({
  selector: 'image-croppers',
  templateUrl: './image-cropper.component.html'
})
export class ImageCropperComponent implements OnInit,AfterViewInit,OnChanges {
@ViewChild("image")
public imageElement:ElementRef;
@Input("src") 
ImageSource:string;
public ImageDestination:string;
public croppable:Boolean=false;
private cropper:Cropper;
@ViewChild("canvas1",{static:true})
canvas1:ElementRef;

context: CanvasRenderingContext2D;
  constructor() {
    this.ImageDestination="";
   }
ngOnChanges(){
  if(this.canvas1==null ||this.canvas1.nativeElement==null)
  return;
 // this.context = this.canvas1.nativeElement.getContext('2d');

    //var width = 500;
  //  var height = 300;
    //this.context.canvas.height=height;
    //this.context.canvas.width;
   // this.img=null;
    //this.img = document.createElement('img');
 //this.img.src = this.imageSource;
  //  this.img.onload = function() {
    //this.context.clearRect(0, 0, this.context.canvas.width,this.context.canvas.height);
       // this.context.drawImage(this.imageElement.nativeElement,width,height);
        // this.cropper = new Cropper(this.imageElement.nativeElement,{
        //   zoomable:true,
        //   scalable:true,
        //   aspectRatio:1,
        //   viewMode:1,

        //   });
  //console.log(this.ImageSource);
}
  ngOnInit() {
 
  }
  ngAfterViewInit(){
   
    this.cropper = new Cropper(this.imageElement.nativeElement,{
      zoomable:true,
      scalable:true,
      aspectRatio:1,
      viewMode:1,
      crop:()=>{
        var canvas=this.cropper.getCroppedCanvas();
        if(canvas.toDataURL()!=null)
        this.ImageDestination=canvas.toDataURL();
      }
      });
  /*  const image=document.getElementById('image');
   // this.canvas = <HTMLCanvasElement> document.getElementById('canvas1');
    this.context = this.canvas1.nativeElement.getContext("2d");
    var width = 500;
    var height = 300;
    this.context.canvas.height=height;
    this.context.canvas.width;
        this.context.drawImage(this.imageElement.nativeElement,width,height);
        this.cropper = new Cropper(this.imageElement.nativeElement,{
          aspectRatio: 1,
          minCanvasHeight:400,
          minCanvasWidth:400,
          scalable:true,
          zoomable:true
          });*/
   /* this.cropper=new Cropper(this.imageElement.nativeElement,{
zoomable:true,
scalable:true,
aspectRatio:1,
viewMode:1,
ready:function(){
 this.croppable=true;
},
crop:()=>{
  var canvas=this.cropper.getCroppedCanvas();
  if(canvas.toDataURL()!=null)
  this.ImageDestination=canvas.toDataURL();
}
    })*/
   /* this.cropper=new Cropper(this.imageElement.nativeElement,{
      zoomable:true,
      scalable:true,
      aspectRatio:1,
      viewMode:1,
      ready:function(){
       this.croppable=true;
      },
      crop:()=>{
        var canvas=this.cropper.getCroppedCanvas();
        if(canvas.toDataURL()!=null)
        this.ImageDestination=canvas.toDataURL();
      }
          })*/
  }
  GetCroper(ev){
    if(this.canvas1==null ||this.canvas1.nativeElement==null)
  return;
  //  this.context = this.canvas1.nativeElement.getContext('2d');

   // var width = 500;
   // var height = 300;
   // this.context.canvas.height=height;
   // this.context.canvas.width=width;
   // this.img=null;
    //this.img = document.createElement('img');
 //this.img.src = this.imageSource;
  //  this.img.onload = function() {
    //this.context.clearRect(0, 0, this.context.canvas.width,this.context.canvas.height);
      // this.context.drawImage(this.imageElement.nativeElement,width,height);
      
        // this.cropper = new Cropper(this.imageElement.nativeElement,{
        //   zoomable:true,
        //   scalable:true,
        //   aspectRatio:1,
        //   viewMode:1,
         
        //   });
 // console.log(this.ImageSource);
  }

}
