
<div class="jarviswidget-ctrls" role="menu" title="Edit Document Type (Ctrl+E)">
    <a class="button-icon tablIndexFocus" [attr.tabindex]="0"  (keyup.enter)="showEditDialog()" id="btnedit" (click)="showEditDialog()">
        <i class="glyphicon glyphicon-pencil"></i>
    </a> 
</div>
<div class="jarviswidget-ctrls" role="menu" title="Add Document Type (Ctrl+A)">
    <a class="button-icon hand-cursor tablIndexFocus" [attr.tabindex]="0"  id="btnadd" style="color: black;"  (keyup.enter)="showAddDialog()" (click)="showAddDialog()">
        <i class="glyphicon glyphicon-plus"></i>
    </a>
</div>

<form (ngSubmit)="btnSubmitClick(assetEditForm)" #assetEditForm="ngForm">
    <p-dialog [(visible)]="display" showEffect="fade" [modal]="true" [style]="{width: '400px'}" [resizable]="false">
        <ng-template pTemplate="header" (keyup.shift.tab)="btnCancelClick(assetEditForm)">
            {{dialogTitle}}
        </ng-template>
        <!-- Modal -->

        <div class="upload-dialog">
            <!--<div class="modal-body">-->
            <div class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Title
                                 <span [hidden]="model.Name" class="text-danger">
                                    *
                                </span>
                            </label>	
    
                              <label class="input rgxErr">
                                <i *ngIf="mfMTitle.value && !mfMTitle.valid && (mfMTitle.touched || mfMTitle.dirty )"
                                    class="icon-append fa fa-ban"
                                    title="{{sharedService.error_rgx_pre}} {{sharedService.re_DocumentType_Message}} {{sharedService.error_rgx_post}}" >
                                </i>
                                  <input id="mfMTitle" type="text" name="mfMName" [(ngModel)]="model.Name" #mffocus #mfMTitle="ngModel"
                                  [pattern]="sharedService.re_DocumentType"
                                  required class="input-sm" />
                              </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">Status</label>
                            <label class="checkbox" >
                                <span class="onoffswitch tablIndexFocus onoffswitch-Width" [attr.tabindex]="0">
                                    <input type="checkbox" name="onoffswitch" [(ngModel)]="model.Status" class="onoffswitch-checkbox" checked="checked" id="isMactive">
                                    <label class="onoffswitch-label" for="isMactive"><span class="onoffswitch-inner" data-swchon-text="Active" data-swchoff-text="Inactive"></span><span class="onoffswitch-switch onoffswitch-switch-Right"></span></label>
                                </span>
                            </label>
                        </fieldset>
                    </div>
                </div> 
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="submit" pButton icon="fa fa-check" class="btn btn-primary minzindex" label="{{btnlabel}}" [disabled]="!assetEditForm.form.valid || isSubmitted"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" (click)="btnCancelClick(assetEditForm)" label="Cancel" [disabled]="isSubmitted" (focusout)="applyFocusToInput()"></button>
                <!--<button type="submit" *ngIf="isAdd" pButton icon="fa fa-check" class="btn btn-primary minzindex" label="Add" [disabled]="!assetEditForm.form.valid || isSubmitted"></button>-->
            </div>
        </ng-template>
    </p-dialog>
</form>
