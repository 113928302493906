//#region References
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs'
import { PDFMerge, PDFDelete, PDFExtract } from '../../services/pdfManipultation/pdfmanipulation';
import { SharedService } from '../../../shared/services/shared.service';

//#endregion

@Injectable()
export class PDFManipulationService {
    constructor(private http: HttpClient, private _sharedService: SharedService) {
    }

    mergeDocumentsOnline(body:PDFMerge): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Document/MergeOnline`, body, this._sharedService.getReqOptWithAccessToken());
    }

    mergeDocumentsLocal(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Document/MergeLocal`, body, { headers:this._sharedService.getReqOptWithAccessTokenFileUpoad()});
    }

    deletePDF(body: PDFDelete): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Document/DeletePages`, body, this._sharedService.getReqOptWithAccessToken());
    }

    extractPDF(body: PDFExtract): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Document/ExtractPages`, body, this._sharedService.getReqOptWithAccessToken());
    }

    
}