export class viewJobProgress {

    ViewID: string;
    ViewName: string;
    JobID: string;
    TotalRecords: number = 0;
    CurrentTaskIndex: number = 0;
    Message: string;
    State: string;
    ActiveChannel:string;
    Percentage: number = 0;

    viewID: string;
    viewName: string;
    jobID: string;
    totalRecords: number = 0;
    currentTaskIndex: number = 0;
    message: string;
    state: string;
    activeChannel:string;
    percentage: number = 0;

    // get Percentage(): number {
    //     return this.getPercentage(this.CurrentTaskIndex, this.TotalRecords);
    // }


    // set Percentage(v: number) {

    //     this.percentage = this.getPercentage(this.CurrentTaskIndex, this.TotalRecords);
    // }

    getPercentage(obtainedValue, totalValue): number {
        var result = ((obtainedValue / totalValue) * 100)
        return parseInt(result.toFixed(2));
    }

}