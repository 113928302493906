//#region References
//Components
import { Component, OnInit, Input, ViewChild, ElementRef,OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';

import { NgForm } from '@angular/forms';
import { APIService } from '../../services/api.service';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
import { UploadFeedModel } from './upload-feed';
import { Subscription } from "rxjs";
var dateTimeConfig: IDateFormatInfo;
//#endregion

//#region Type Decorator
@Component({
    selector: 'upload-feed',
    templateUrl: './upload-feed.component.html',
    providers: [APIService]
})
//#endregion

export class UploadFeedComponent implements OnInit,OnDestroy {

    //#region Locals Declaration
    uploaddisplay: boolean = false;
    isSubmitted: boolean = false;

    dialogTitle: string = "";

    uploadUrl: string;

    model: UploadFeedModel;

    selectedCCs: string[] = [];
    public bulkFiles: any[] = [];
    uploadedFiles: any[] = [];
    

    @ViewChild('feedfile') selectedFeedFiles: any;
    @Input() recordType: string;
    @Input() feedId: string;
    @Input() feedTempleInterval: string;

    attachedFiles: string[] = [];
    orignalAttachedFiles: string[] = [];
    isAnyFileRemoved: boolean = false;
    selectedFeedDataType: string = "";
    //selectedSouceType:string="";
    acceptfilestype: string = "";
    messageFileType: string = "";
    isDisableDataFile:boolean=false;
    //selectedTargetType:string="";
    private showUploadDialogSub: Subscription;
    private showHideUploadButtonSub:Subscription;

    fileTypeTemplate:string='2';
    isShowDataFileOption:boolean=false;
    isShowTemplateFileOption:boolean=false;
    //#endregion

    //#region Initialization of Component
    constructor(private _sharedService: SharedService, private _router: Router, private _aPIService: APIService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
    }
    ngOnInit() {
        this.initializeEmptyModel();

        this.showUploadDialogSub = this._sharedService._uploadFeedSub
        .subscribe({
            next: (data:any) => {
                this.feedId = data.feedID;
                this.showUploadDialog(data.fileType);
            },
            error: (error) => {
            }
        });

        this.showHideUploadButtonSub = this._sharedService._uploadFeedOptionsSub
        .subscribe({
            next: (feedData:any) => {
                this.setUploadButtonsVisibility(feedData);
            },
            error: (error) => {
            }
        });
    }

    //#endregion
    //#region Destroy
    ngOnDestroy() {
        if (this.showUploadDialogSub) {
            this.showUploadDialogSub.unsubscribe();
        }
        if (this.showHideUploadButtonSub) {
            this.showUploadDialogSub.unsubscribe();
        }
    }
    //#endregion
    // #region Form Events
    setUploadButtonsVisibility(feedData:any)
    {
        try 
        {
            if(feedData)
            {
               if(this.recordType=='Import' && feedData.SourceType=='Status')
                {
                    // Not Allowed
                    this.showHideDataFileOption(false);
                    this.showHideTemplateFileOption(false);
                }
                else if(this.recordType=='Import' && (feedData.SourceType=='Web Service Pull' || feedData.SourceType=='Web Service Push' || feedData.SourceType=='Shared Folder' || feedData.SourceType=='FTP'))
                {
                    // Template Allowed, Data File Not Allowed
                    this.showHideDataFileOption(false);
                    this.showHideTemplateFileOption(true);
                }
                else if(this.recordType=='Import' && (feedData.SourceType=='Records'))
                {
                    // Not Allowed
                    this.showHideDataFileOption(false);
                    this.showHideTemplateFileOption(false);
                }
                else if(this.recordType=='Import')
                {
                    // Allowed
                    this.showHideDataFileOption(true);
                    this.showHideTemplateFileOption(true);
                }
                else if(this.recordType=='Export' && (feedData.DataType=='XML') && (feedData.SourceType=='RDOC' || feedData.SourceType=='Records Link'))
                {
                    // Template Allowed, Data File Not Allowed
                    this.showHideDataFileOption(false);
                    this.showHideTemplateFileOption(true);
                }
                else if(this.recordType=='Export' && feedData.SourceType=='Status Link')
                {
                     // Template Allowed, Data File Not Allowed
                     this.showHideDataFileOption(false);
                     this.showHideTemplateFileOption(true);
                }
                else
                {
                      // Not Allowed
                      this.showHideDataFileOption(false);
                      this.showHideTemplateFileOption(false);
                }

                // if (this.feedTempleInterval == "Not Applicable" || feedData.FeedTemplateInterval=="Not Applicable") {

                //     this.showHideTemplateFileOption(false);

                //  }

                
            }
        } catch (error) {
           console.log(error); 
        }
    }

    showUploadDialog(fileType:string) {
        if (this.feedId && this.feedId != "") {
            this._sharedService.showLoader("Processing...");
            this.dialogTitle = "Upload Feed Files";
            this._aPIService.getFeedDataType(this.feedId)
            .subscribe({
                next: (response) => {
                    this.selectedFeedDataType = response;
                    // this.selectedSouceType  =response.SourceType;
                    // this.selectedTargetType =response.TargetType;
        
                    this._sharedService.hideLoader();
                    this.setDefaultComponentValues(fileType);
                    this.setFileUploadRequiredFields();
                    this.uploaddisplay = true;
        
                    // if(this.recordType=='Import' && response.SourceType=='Status')
                    // {
                    //     this._sharedService.hideLoader();
                    //     this.uploaddisplay = false;
                    //     this._sharedService.clearToast();
                    //     this.isDisableDataFile=false;
                    //     this._sharedService.showToast({ severity: 'warn', summary: 'Not Allowed', detail: `Not functional for ${response.SourceType}` });
                    // }
                    // else if(this.recordType=='Import' && (response.SourceType=='Web Service' || response.SourceType=='Shared Folder' || response.SourceType=='FTP'))
                    // {
                    //     if(this.fileTypeTemplate==fileType)
                    //     {
                    //         this.setDefaultComponentValues(fileType);
                    //         this.isDisableDataFile=true;
                    //         this.setFileUploadRequiredFields();
                    //         this._sharedService.hideLoader();
                    //         this.uploaddisplay = true;
                    //     }
                    //     else
                    //     {
                    //         this._sharedService.hideLoader();
                    //         this._sharedService.clearToast();
                    //         this._sharedService.showToast({ severity: 'warn', summary: 'Not Allowed', detail: `Not functional for ${response.SourceType}`});
                    //     }
                    // }
                    // else if(this.recordType=='Import')
                    // {
                    //     this.setDefaultComponentValues(fileType);
                    //     this.setFileUploadRequiredFields();
                    //     this._sharedService.hideLoader();
                    //     this.uploaddisplay = true;
                    //   //  this.isDisableDataFile=false;
                    // }
                    // else if(this.recordType=='Export' && (this.selectedFeedDataType=='XML') && (this.selectedSouceType=='RDOC' || this.selectedSouceType=='Records'))
                    // {
                    //     if(this.fileTypeTemplate==fileType)
                    //     {
                    //     this.setDefaultComponentValues(fileType);
                    //   //this.isDisableDataFile=true;
                    //     this.setFileUploadRequiredFields();
                    //     this._sharedService.hideLoader();
                    //     this.uploaddisplay = true;
                    // }
                    // else
                    // {
                    //     this._sharedService.hideLoader();
                    //     this._sharedService.clearToast();
                    //     this._sharedService.showToast({ severity: 'warn', summary: 'Not Allowed', detail: `Not functional for ${response.SourceType}`});
                    // }
                        
                    // }
                    // else if(this.recordType=='Export' && this.selectedSouceType=='Status Link')
                    // {
                    //     if(this.fileTypeTemplate==fileType)
                    //     {
                    //     this.setDefaultComponentValues(fileType);
                    //    // this.isDisableDataFile=true;
                    //     this.setFileUploadRequiredFields();
                    //     this._sharedService.hideLoader();
                    //     this.uploaddisplay = true;
                    //     }
                    //     else
                    //     {
                    //         this._sharedService.hideLoader();
                    //         this._sharedService.clearToast();
                    //         this._sharedService.showToast({ severity: 'warn', summary: 'Not Allowed', detail: `Not functional for ${response.SourceType}`});
                
                    //     }
                    // }
                    // else
                    // {
                    //     this._sharedService.hideLoader();
                    //     this.uploaddisplay = false;
                    //     this.isDisableDataFile=false;
                    //     this._sharedService.clearToast();
                    //     this._sharedService.showToast({ severity: 'warn', summary: 'Not Allowed', detail: `Not functional for ${response.SourceType}`});
                    // }
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast(
                        { 
                            severity: 'warn', 
                            summary: 'No Feed Selected', 
                            detail: 'Please select a row first' 
                        }
                    );
                }
            });

            //this.uploaddisplay = true;
           // this.setDefaultComponentValues();
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Feed Selected', detail: 'Please select a row first' });
        }

    }

    btnCancelClick(cancelForm: NgForm) {
        cancelForm.resetForm();
        this.clearSelectedFiles();
        this.uploaddisplay = false;
        this.initializeEmptyModel();
        this.isDisableDataFile=false;
    }
    //#endregion

    //#region File Uploader Events
    onSelect(event: any) {
        let allowedFileTypes: string[] = this._aPIService.getAllowedFileTypes();
        let allFilesValid: boolean = true;
        for (let file of event.files)
        {
            let validFileType = true;
            let lastOccurenceOfDot: number = file.name.lastIndexOf('.');
            if (lastOccurenceOfDot != -1) {
                let extenstion: string = file.name.substr(lastOccurenceOfDot + 1, file.name.length);
                //let result: number = allowedFileTypes.findIndex(x => x == extenstion);
                let isCurrentFeedAllowedType: boolean = this.checkFileExtensionValidity(extenstion.toLowerCase(), this.selectedFeedDataType);
                if (!isCurrentFeedAllowedType)
                {
                    validFileType = false;
                    allFilesValid = false;
                }
                else {
                    validFileType = true;
                }
            }
            if (validFileType)
            {
                let fileCurrent: any = this.uploadedFiles.filter(x => x.name == file.name);
                if (fileCurrent.length > 0) {
                    for (let i = 0; i < this.selectedFeedFiles.files.length; i++) {
                        if (this.selectedFeedFiles.files[i].name == file.name) {
                            this.selectedFeedFiles.files.splice(i, 1);
                            break;
                        }

                    }

                }
                else {

                    this.uploadedFiles.push(file);

                }
            }
            else
            {

                let index: any = this.selectedFeedFiles.files.findIndex((x: any) => x.name == file.name);

                this.selectedFeedFiles.files.splice(index, 1);
               
            }
            if (!allFilesValid)
            {
                this.uploadedFiles = [];
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File(s)', detail: 'Only files of type '+this.messageFileType+' are valid' });
            }
        }
    }
    onRemove(event: any) {

        for (let i = 0; i < this.uploadedFiles.length; i++) {
            if (this.uploadedFiles[i].name == event.file.name) {
                this.uploadedFiles.splice(i, 1);
                break;
            }
        }

    }
    onBeforeUpload(event: any) {

        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }
    }

    clearSelectedFiles() {
        if(this.selectedFeedFiles && this.selectedFeedFiles.files){
        this.selectedFeedFiles.files = [];
        }
    }

    onBulkUpload(event: any, addForm: NgForm) {
        this.changeButtonsStatus(true);

        // Files Added removed logic
        let deletedfileslist: string[] = [];
        let allFileDeleted: boolean = false;
        let newUploadedFilesList: string[] = [];

        for (let i = 0; i < this.orignalAttachedFiles.length; i++) {
            let fileName = this.attachedFiles.find(x => x == this.orignalAttachedFiles[i]);
            if (!fileName) {
                deletedfileslist.push(this.orignalAttachedFiles[i]);
            }
        }
        if (this.orignalAttachedFiles.length > 0) {
            if (this.orignalAttachedFiles.length == deletedfileslist.length) {
                allFileDeleted = true;
            }
        }

        if (this.uploadedFiles.length > 0 || deletedfileslist.length > 0) {


            for (let j = 0; j < this.uploadedFiles.length; j++) {
                let fileNameOldRemaining = "";
                let fileNameDeleted = "";
                fileNameOldRemaining = this.attachedFiles.find(x => x == this.uploadedFiles[j].name);
                fileNameDeleted = deletedfileslist.find(x => x == this.uploadedFiles[j].name);
                if ((!fileNameOldRemaining) && (!fileNameDeleted)) {
                    newUploadedFilesList.push(this.uploadedFiles[j].name);
                }
            }
            //



            this.bulkFiles;
            let xhr = new XMLHttpRequest(), formData = new FormData();
            for (let i = 0; i < this.uploadedFiles.length; i++) {
                formData.append("uploads['" + i + "']", this.uploadedFiles[i], this.uploadedFiles[i].name);
            }
            formData.append('DeletedFilesList', deletedfileslist.join(';'));
            //Append File and Form Data in xhr Request    

            let fileType = "";
            if (this.model.FileType) {
                fileType = "1"; // Data File 
            }
            else {
                fileType = "2"; // Template File
            }
            if (!(fileType == "2" && this.uploadedFiles.length >= 2)) {
                formData.append("FeedId", this.feedId);
                formData.append("FileType", fileType);
                xhr.open('POST', this.uploadUrl, true);
                xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
                this._sharedService.showLoader("Processing...");
                xhr.send(formData);

                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        this._sharedService.hideLoader();
                        if (xhr.status === 200) {
                            this.isAnyFileRemoved = false;
                            this.uploaddisplay = false;
                            if (this.uploadedFiles.length > 0 && this.isAnyFileRemoved) {
                              this._sharedService.clearToast();
                                this._sharedService.showToast({ severity: 'success', summary: 'Files Uploaded And Removed ', detail: 'File(s) have been uploaded and removed successfully.' });
                            }
                            else if (deletedfileslist.length > 0 && this.uploadedFiles.length == 0) {
                              this._sharedService.clearToast();
                                this._sharedService.showToast({ severity: 'success', summary: 'Files Removed', detail: 'File(s) have been removed successfully.' });
                            }
                            else if (this.uploadedFiles.length > 0 && deletedfileslist.length == 0) {
                              this._sharedService.clearToast();
                                this._sharedService.showToast({ severity: 'success', summary: 'Files Uploaded', detail: 'File(s) have been uploaded successfully.' });
                            }
                            this.uploadedFiles = [];
                            this.clearSelectedFiles();
                            addForm.resetForm();
                            this.changeButtonsStatus(false);
                            this.clearAttachedFiles();

                           // this._sharedService.resfreshInboxGrid(true);
                           this._sharedService.resfreshDataFeedGrid(true);

                        }
                        else {
                            this.isAnyFileRemoved = false;
                            this.uploaddisplay = false;

                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while uploading files.' });

                            addForm.resetForm();
                            this.initializeEmptyModel();

                            this.changeButtonsStatus(false);
                            this.clearAttachedFiles();
                        }
                    }
                };
            }
            else {
              this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Multiple Files.', detail: 'Only one file can be uploaded for template.' });
                this.changeButtonsStatus(false);
            }
        }
        else {
            this.changeButtonsStatus(false);
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No File Selected', detail: 'No file(s) have been uploaded or deleted.' });
        }
    }
    //#endregion

    //#region Helper Methods
    setDefaultComponentValues(fileType:string): void {
        this.initializeEmptyModel();
        this.changeButtonsStatus(false);
        this.uploadUrl = `${this._sharedService.baseUrl}/api/APIModule/UploadFeedFiles`;
        this.selectedFileOption(fileType);
       // this.model.FileType = flag;
        this.getExistingFiles(null);
        this.isAnyFileRemoved = false;
    }

    initializeEmptyModel() {
        this.model = new UploadFeedModel(null, null);
        this.selectedCCs = [];
        this.bulkFiles = [];
        this.uploadedFiles = [];
        this.clearSelectedFiles();
        // this.selectedSouceType='';
        // this.selectedTargetType ='';

    }


    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }

    checkFileExtensionValidity(fileExtension: string, allowedType: string): boolean
    {
        let status: boolean = false;
        // In case file type not provided
        if(!allowedType)
        {
            return true;
        }
        switch (allowedType)
        {
            case 'XLSX':
                if (fileExtension == 'xlsx')
                {
                    status = true;
                }
                break;
            case 'CSV':
                if (fileExtension == 'csv' || fileExtension == 'txt')
                {
                    status = true;
                }
                break;
            case 'TSV':
                if (fileExtension == 'csv' || fileExtension == 'txt') {
                    status = true;
                }
                break;
            case 'XML':
                if (fileExtension == 'xml') {
                    status = true;
                }
                break;
        }

        return status;
    }
    setFileUploadRequiredFields(): void {

        switch (this.selectedFeedDataType) {
            case 'XLSX':
                this.acceptfilestype = ".xlsx,.XLSX";
                this.messageFileType = "xlsx";
                break;
            case 'CSV':
                this.acceptfilestype = ".csv,.CSV,.txt,.TXT";
                this.messageFileType = "csv and txt";
                break;
            case 'TSV':
                this.acceptfilestype = ".csv,.CSV,.txt,.TXT";
                this.messageFileType = "csv and txt";
                break;
            case 'XML':
                this.acceptfilestype = ".xml,.XML";
                this.messageFileType = "xml";
                break;
        }
    }
    //#endregion    

    //#region Get Existing Files
    getExistingFiles(fileTypeSelected: string) {
        let fileType = "";
        if (this.model.FileType) {
            fileType = "1"; // Data File 
        }
        else {
            fileType = "2"; // Template File
        }
        if (fileTypeSelected) {
            fileType = fileTypeSelected;
        }
        let body = {
            FeedId: this.feedId,
            FileType: fileType
        }
        this.clearAttachedFiles();
        this._aPIService.getUploadedFiles(body)
        .subscribe({
            next: (response) => {
                let temp = response;
                if (temp != null && temp.length > 0) {
                    for (var i = 0; i < temp.length; i++) {
                        this.orignalAttachedFiles.push(decodeURIComponent(temp[i]));
                        this.attachedFiles.push(decodeURIComponent(temp[i]));
                    }
                }
            },
            error: (error) => {
            }
        });
    }

    removeAttachedFile(file: any) {
        this.isAnyFileRemoved = false;
        for (let i = 0; i < this.attachedFiles.length; i++) {
            if (this.attachedFiles[i] == file) {
                this.attachedFiles.splice(i, 1);
                this.isAnyFileRemoved = true;
                break;
            }
        }

    }
    changeFileType(fileType: string) {
        this.isAnyFileRemoved = false;
        this.getExistingFiles(fileType);
    }
            //#endregion 

    //#region Download File
    downloadFile(file: string) {
        let fileType = "";
        if (this.model.FileType) {
            fileType = "1"; // Data File 
        }
        else {
            fileType = "2"; // Template File
        }
        this._sharedService.showLoader("Processing...");
        let body = { FeedId: this.feedId, FileName: file, FileType: fileType };
        this._aPIService.downloadAttachedFile(body)
        .subscribe({
            next: (response) => {
                this._sharedService.hideLoader();
                let blob = new Blob([response], { type: 'application/zip' });
                let url = URL.createObjectURL(blob);
                this.formatZipFileName(url, file.substring(0, file.lastIndexOf(".")));
            },
            error: (error) => {
                console.log(error);
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                this._sharedService.showToast(
                    { 
                        severity: 'error', 
                        summary: 'Error.', 
                        detail: 'File not exists.' 
                    }
                );
            }
        });
    }

    formatZipFileName(fileURL: string, fileName: string) {
        fileName = fileName.replace(/[.]/g, "");
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = fileURL;
        a.target = '_blank';
        a.download = fileName;
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
    }

    clearAttachedFiles() {

        this.orignalAttachedFiles = [];
        this.attachedFiles = [];
    }
    selectedFileOption(fileType:string)
    {
        if (fileType == "1") {
         // Data File 
            this.model.FileType=true;
        }
        else if(fileType == "2") {
            // Template File
            this.model.FileType=false; 
        }

    }

    showHideDataFileOption(flag:boolean){
        this.isShowDataFileOption=flag;
    }
    showHideTemplateFileOption(flag:boolean){
        this.isShowTemplateFileOption=flag;
    }
    //#endregion

}
