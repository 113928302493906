import { Router } from '@angular/router';
import { ArticleService } from './../../services/article/article.service';
import { SharedService } from '../../../shared/services/shared.service';
import { Component, OnInit } from '@angular/core';
import { GridOptions, GridApi } from "ag-grid-community";


var _router: Router;
var airticleID: string;

@Component({
  selector: 'articles',
  templateUrl: './articles.component.html',

})


export class ArticleComponent implements OnInit {
  public gridOptions: GridOptions;
  public columnDefs: any[];
  public rowData: any[];
  public gridData: any[];
  public docTypeID: string;
  private api: GridApi;
  public addArticlePath: string = "/help/addArticle";
  public canAddEditAricle: boolean = false;
  public totalCount: string;


  constructor(private _sharedService: SharedService, private _article: ArticleService, private router: Router) {
    if (!this._sharedService.isAuthUser()) {
      this.router.navigate(['login']);
    }
    else {
      airticleID = null;
      this.updateRoles();
      _router = this.router;
    }
  }


  ngOnInit() {

    this.bindGrid();
    this.loadGridData();
  }

  updateRoles() {
    if (!this._sharedService.UserRole) {
      this._sharedService.updateRole();
    }

    this.canAddEditAricle = true;
  }
  bindGrid() {

    this.gridOptions = <GridOptions>{
      rowSelection: 'single',
      /* pagination:true, */
      onSelectionChanged: this.handleSelectedDoc,
      onGridReady: () => {
        this.setGridColumns();
        if (this.gridOptions.api != undefined) {
          this.gridOptions.api.sizeColumnsToFit();
          this.api = this.gridOptions.api;
          try {
            this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridArticle'));
            this.gridOptions.api.hideOverlay();
          } catch (e) {
            console.log(e);
          }

        }
      },
      onModelUpdated: (event: any) => {
        if (this.gridOptions.rowData != null && this.gridOptions.rowData != undefined && this.gridOptions.rowData.length > 0) {
          if (this.api != undefined) {
            this.api.sizeColumnsToFit();
          }
        }
      },
      pagination: true,
      paginationPageSize: 20,
      getContextMenuItems: this.getCustomContextMenuItems,
      context: {
        gridContext: this
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      }
    }
  }


  //#region Grid Context Menu
  getCustomContextMenuItems(params: any): any {
    let result: any[] = [];

    if (params.context.gridContext.canAddEditAricle) {
      result.push({
        name: "Add Article",
        action: function () {
          params.context.gridContext.router.navigate(["/help/addArticle"]);
        },
        icon: params.context.gridContext._sharedService.addIconHTMLasString,
      });

      result.push({
        name: "Edit Article",
        action: function () {
          params.context.gridContext.airticleID = null;
          if (params.node) {
            params.context.gridContext.airticleID = params.node.data.ArticleId;
            params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
            params.context.gridContext.router.navigate(["/help/editArticle", params.context.gridContext.airticleID]);
          }

        },
        icon: params.context.gridContext._sharedService.editIconHTMLasString,
      });

    }
    return result;


  }

  //#endregion

  setGridColumns(): void {
    this.columnDefs = [];
    this.columnDefs = [
      { headerName: 'Title', field: 'Title', menuTabs: ['filterMenuTab'], cellRenderer: this.tagsCellRenderer },
      { headerName: 'Category', field: 'ArticleCategoryName', menuTabs: ['filterMenuTab'] },
      { headerName: 'Section', field: 'ArticleSectionName', menuTabs: ['filterMenuTab'] },
      { headerName: 'Order', field: 'Order', minWidth: 50, menuTabs: ['filterMenuTab'] },

      {
        headerName: 'Status', field: 'Status', filter: false, suppressMenu: true, valueFormatter: function (params: any) {
          if (params.value == true) {
            return "Active";
          }
          else {
            return "Inactive";
          }
        }
      },
    ];
  }

  tagsCellRenderer(params: any) {
    let html: string = '';

    if (params.value) {

      html = html + '<span class="padding-2 label" style= "background-color:' + params.data.Color + ';">' + params.value + '</span>';

    }

    var eDiv = document.createElement('div');
    eDiv.innerHTML = html

    return eDiv;
  }


  loadGridData(): void {
    this._sharedService.showLoader("Processing...");
    let userRole: string = localStorage.getItem('roles').trim();
    this._article.getArticleDetail()
      .subscribe({
        next: (response) => {
          this._sharedService.hideLoader();
          this.gridData = [];
          this.gridData = response;
          this.totalCount = response.length;
        },
        error: (error) => {
          console.log("some thing wrong  while getting data !");
          this._sharedService.hideLoader();
        }
      });
  }
  returntoAction(url: string) {

    setTimeout(() => {

      this.router.navigateByUrl(url);

    },
      2000);
  }

  handleSelectedDoc() { // (selDoc: any) {

    var rowCount = this.api.getSelectedNodes().length;
    if (rowCount > 0) {
      var lastRowData = this.api.getSelectedNodes()[rowCount - 1].data;
      airticleID = lastRowData.ArticleId;

    }
  }

  editArticle(event: any): void {
    if (event) {
      let articleID = event.data.ArticleId;

      _router.navigate(["/help/editArticle", articleID]);
    } else {
      if (airticleID == undefined || airticleID == null || airticleID == "") {
        this._sharedService.clearToast();
        this._sharedService.showToast({ severity: 'warn', summary: 'no article selected', detail: 'please select an Article.' });
        return;
      }
      if (airticleID == '0') {
        _router.navigate(["/help/editArticle", airticleID]);
      }

      _router.navigate(["/help/editArticle", airticleID]);
    }


  }



}
