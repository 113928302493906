import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SharedService } from '../../../shared/services/shared.service';
import { container, containerForSearch } from '../../../records/services/container/container';
import { map } from 'rxjs/operators';
import { CopyMoveDocumentInputEntity } from '../../classes/CopyMoveDocumentInputEntity';

@Injectable()
export class AzureBlobService {
    constructor(private _sharedService: SharedService, private http: HttpClient) {
    }

    getContainerData(body: container): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/GetContainerData`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getContainerListData(body: container[]): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/GetContainerListData`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getContainerWOGapData(body: container): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/GetContainerWOGapData`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getContainerBlobNames(body: container): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/GetContainerBlobName`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getSpecificBlobData(body: containerForSearch): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/GetSpecificBlobData`, body, this._sharedService.getReqOptWithAccessToken());
    }

    deleteBlob(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/DeleteDocument`, body, this._sharedService.getReqOptWithAccessToken());
    }

    moveCopyBlob(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/MoveCopyDocument`, body, { headers: this._sharedService.getReqOptWithAccessTokenAndTimeOffset() });
    }
    copyMoveBlobBatch(body: CopyMoveDocumentInputEntity): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/CopyMoveDocumentsBatch`, body, { headers: this._sharedService.getReqOptWithAccessTokenAndTimeOffset() });
    }
    getDocumentReferences(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/GetDocumentReferences`, body, { headers: this._sharedService.getReqOptWithAccessTokenAndTimeOffset() });
    }

    downloadBlob(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/DownloadBlob`, body, this._sharedService.getReqOptWithAccessTokenForZipFile());
    }
    downloadAttachedFile(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/DownloadAttachment`, body, this._sharedService.getReqOptWithAccessTokenForZipFile());
    }
    updateDocumentSeqNo(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/UpdateDocumentSeqNo`, body, this._sharedService.getReqOptWithAccessToken());
    }
    deleteAllDocuments(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/DeleteAllDocument`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getDocUrl(docPath: string, validateRole: boolean): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/GetDocumentURL?docPath=${docPath}&validateRole=${validateRole}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    getZipBlobUrl(docPath: string, validateRole: boolean): Observable<any> {

        const requestOptions: Object = {
            headers: this._sharedService.getReqOptWithAccessToken(),
            responseType: 'arraybuffer'
        }
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/GetDocumentURL?docPath=${docPath}&validateRole=${validateRole}`,
            '', requestOptions);

        //return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/GetDocumentURL?docPath=${docPath}&validateRole=${validateRole}`, '', this._sharedService.getReqOptWithAccessToken(),);
    }
    updateQualityStatus(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/UpdateQualityStatus`, body, this._sharedService.getReqOptWithAccessToken());
    }
    updateBlobMetaDataBulk(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/UpdateBlobMetaDataBulk`, body, this._sharedService.getReqOptWithAccessToken());
    }


    getBlob(url: string): any {
        // let headers = this._sharedService.getReqOptWithAccessToken();
        // const requestOptions: Object = {
        //     headers: headers,
        //     responseType: 'Blob',
        // }
        let headers = new HttpHeaders();
        headers  = headers.append('Content-Type', 'application/json');
        headers  = headers.append('Accept', 'application/json');
        headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        const options = { headers: headers, responseType: 'blob' as const };
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/GetBlobFile`, { Uri: url }, options)
            .pipe(
                map(res => {
                    return new Blob([res], { type: res.type, });//R.A 06-01-2021
                    //return new Blob([res], { type: 'application/pdf', }); // or any other operator
                }));
    }

    getPDFBlob(url: string): any {
        let headers = this._sharedService.getReqOptWithAccessToken();
        const requestOptions: Object = {
            headers: headers,
            responseType: 'blob'
        }
        //handle .pdf in code
        if (url.lastIndexOf('.') >= 0) {
            let lastIndex = url.lastIndexOf('.');
            url = this.replaceAt(url, lastIndex, 1, '^|^');
        }

        return this.http.get<any>(`${url}`, requestOptions)
            .pipe(
                map(res => {
                    return new Blob([res], { type: res.type, }); // or any other operator
                    //R.A. //return new Blob([res], { type: 'application/pdf', }); // or any other operator
                }));
    }

    downloadSearchDocument(docUrl:string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Search/DownloadSearchDocument?docName=${docUrl}`, '', this._sharedService.getReqOptWithAccessTokenForTextFile());
    }

    DownloadBlobForStatus(url: string): Observable<any> {
        //return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/DownloadBlobForStatus`, body, this._sharedService.getReqOptWithAccessTokenForZipFile());
       
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/DownloadBlobForStatus?blobURL=${url}`, this._sharedService.getReqOptWithAccessTokenForZipFile())

        //return this.http.get()`${this._sharedService.baseUrl}/api/AzureBlob/DownloadBlobForStatus`, body, this._sharedService.getReqOptWithAccessTokenForZipFile());
    }



    downloadBlobToSystem(url: string, fileType: string): any {
        let headers = this._sharedService.getReqOptWithAccessToken();
        // headers.headers.set('Access-Control-Allow-Origin', '*');
        // headers.headers.set('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
        // headers.headers.set('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');
        const requestOptions: Object = {
            headers: headers,
            responseType: 'blob'
            //responseType: 'arraybuffer'
        }

        //handle .pdf in code
        // if(url.lastIndexOf('.') >= 0){
        //     let lastIndex = url.lastIndexOf('.');
        //     url =  this.replaceAt(url, lastIndex, 1, '^|^');
        // }
        fileType = 'application/zip';
        return this.http.get<any>(`${url}`, requestOptions)
            .pipe(
                map(res => {
                    //return new Blob([res], { type: 'application/pdf', }); // or any other operator
                    return new Blob([res], { type: fileType, });
                }));
    }

    replaceAt(stringVal, index, charLength, replacement) {
        return stringVal.substr(0, index) + replacement + stringVal.substr(index + charLength);
    }

    getZipBlob(url: string): any {

        let headers = this._sharedService.getReqOptWithAccessToken();
        const requestOptions: Object = {
            headers: headers,
            responseType: 'blob'
        }
        //handle .pdf in code
        if (url.indexOf('.zip') >= 0) {
            let urlArray = url.split('.zip');
            url = urlArray[0] + '^|^zip';
        }

        return this.http.get<any>(`${url}`, requestOptions)
            .pipe(
                map(res => {
                    return new Blob([res], { type: 'application/zip', }); // or any other operator
                }));
    }

    getDocumentURLWithSASToken(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AzureBlob/GetDocumentURLWithSASToken`, body, this._sharedService.getReqOptWithAccessToken());
    }
}
