<p-confirmDialog id="detachDocument" header="Confirmation" key="confirmdetachdoc" icon="fa fa-question-circle"
  [style]="{width: '450px'}"></p-confirmDialog>
<article class="col-sm-6 col-md-6 col-lg-6 sortable-grid p-sortable no-padding standardpadding" id="col3">

  <!-- Widget ID (each widget will need unique ID)-->
  <div class="jarviswidget" id="wid-id-3" data-widget-sortable="false" data-widget-colorbutton="false"
    data-widget-editbutton="false" data-widget- custombutton="false" data-widget-deletebutton="false"
    data-widget-togglebutton="false" role="widget">

    <header role="heading" id="pdfPanelHeader">
      <div *ngIf="fileType != ''">
        <div class="jarviswidget-ctrls" role="menu" *ngIf="displayMode!='status'">
          <a title={{fullScreenPdfPanalTitle}} href="javascript:void(0);"
            class="button-icon jarviswidget-fullscreen-btn tablIndexFocus" [attr.tabindex]="0"
            (keyup.enter)="fullScreenPdfPanal()" (click)="fullScreenPdfPanal()" id="btnPdfFullScreen" rel="tooltip"
            data-placement="bottom" data-original-title="Fullscreen"><i class="fa fa-expand"></i></a>
        </div>
        <ng-template [ngIf]="!isCurrentModule">
          <span style="padding-right: 4px !important;padding-left: 3px !important;" class="widget-icon"><i
              class="fa fa-file-pdf-o"></i></span>
          <h2>Document Vie<span><a style="color:black;cursor:pointer;" (click)='Download()'>w</a></span></h2>
        </ng-template>
        <div style="float:left;"
          *ngIf="(!(selectedUrl==null && selectedUrl==undefined) || !(selectedVideoUrl==null && selectedVideoUrl==undefined))">
          <status-popup
            *ngIf="canMXRLinkToStatus || canLogLinkToStatus || canGenLinkToStatus || canDocLinkToStatus || canSearchLinkToStatus">
          </status-popup>
        </div>
        <!--<div *ngIf="displayMode!='status' && !(selectedUrl==null && selectedUrl==undefined)">
                <div class="jarviswidget-ctrls pull-left" *ngFor="let docTask of selectedDocTask" role="menu" >
                    <div style="text-align: center;"><span class="btn-primary label padding-5" style="font-size: small;MARGIN-RIGHT: 1px;" title="{{docTask.TaskType}}-{{docTask.TaskID}}">{{docTask.TaskType}}-{{docTask.TaskID}}</span></div>
                </div>
            </div>-->
        <div class="jarviswidget-ctrls pull-left" role="menu"
          *ngIf="displayMode!='status' && (!(selectedUrl==null && selectedUrl==undefined) || !(selectedVideoUrl==null && selectedVideoUrl==undefined))">
          <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="showStatusLinksPopup()"
            href="javascript:void(0);" id="btnAttachRecord" (click)="showStatusLinksPopup()"
            title="Status Links (Ctrl+Shift+L)">
            <i class="fa fa-list"></i>
          </a>
        </div>
        <div class="jarviswidget-ctrls pull-left"
          *ngIf="displayMode!='status' && (!(selectedUrl==null && selectedUrl==undefined) || !(selectedVideoUrl==null && selectedVideoUrl==undefined))">
          <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="showInboxPopup()" id="btnViewInbox"
            (click)="showInboxPopup()" title="Inbox (Ctrl+Shift+I)">
            <i class="fa fa-comments"></i>
          </a>
        </div>
        <div class="jarviswidget-ctrls pull-left"
          *ngIf="displayMode!='status' && (!(selectedUrl==null && selectedUrl==undefined) || !(selectedVideoUrl==null && selectedVideoUrl==undefined))">
          <a class="button-icon tablIndexFocus" [attr.tabindex]="0" target="_blank" href={{selectedUrl}}
            title="View Document">
            <i style="color: black;" class="fa fa-external-link"></i>
          </a>
        </div>
        <div class="jarviswidget-ctrls pull-left" *ngIf="!(selectedUrl==null && selectedUrl==undefined)">
          <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="btnDownloadBlob()" id="DownloadBlob"
            (click)="btnDownloadBlob()" title="Download File">
            <i class="fa fa-download"></i>
          </a>
        </div>


        <div class="jarviswidget-ctrls pull-left" *ngIf="canUpload && !(selectedUrl==null && selectedUrl==undefined)">
          <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="btnShowUpdateFileDlg()"
            id="UpdateBlob" (click)="btnShowUpdateFileDlg()" title="Replace Document">
            <i class="fa fa-retweet"></i>
          </a>
        </div>
        <div class="jarviswidget-ctrls pull-left">
          <div class="jarviswidget-ctrls" title="Help">
            <app-component-articles [componentName]="componentName"
              *ngIf="displayMode!='status' && (!(selectedUrl==null && selectedUrl==undefined) || !(selectedVideoUrl==null && selectedVideoUrl==undefined))">
            </app-component-articles>
          </div>
        </div>


        <div class="jarviswidget-ctrls" role="menu" *ngIf="displayMode!='status'">
          <a title={{wideRecordPdfPanalTitle}} class="button-icon tablIndexFocus" [attr.tabindex]="0"
            (keyup.enter)="widePdfPanal()" href="javascript:void(0);" (click)="widePdfPanal()" id="btnPdfWideScreen">
            <i class="glyphicon glyphicon-resize-horizontal"></i>
          </a>
        </div>



        <doc-manpulation [recordType]="recordType" *ngIf="displayMode!='status' && canManipulatePDF==true
          && !(selectedUrl==null && selectedUrl==undefined) && (selectedVideoUrl==null && selectedVideoUrl==undefined)
          && fileType=='application/pdf'">
        </doc-manpulation>


        <div class="" role="menu"
          *ngIf="displayMode!='status' && (selectedVideoUrl==null && selectedVideoUrl==undefined) && fileType=='application/pdf'">

          <p-dropdown appendTo="body" id="doczoomselect" [options]="zoomOption" [(ngModel)]="selectedOption" tabindex="0"
            (onChange)="zoomChange($event)" [style]="{'width':'140px !important'}"></p-dropdown>
        </div>
        <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>
      </div>
    </header>
    <!-- widget div-->
    <div class="widgetdiv no-padding standardpadding set-screen-width">
      <!--style="height: 535px;"-->
      <!--<pdf-viewer [src]="pdfSrc"
                        [original-size]="false" [show-all]="true"
                        style="display: block;"></pdf-viewer>-->
      <!-- widget edit box -->
      <div class="jarviswidget-editbox">
        <!-- This area used as dropdown edit box -->

      </div>
      <!-- end widget edit box -->
      <!-- widget content -->
      <div class="widget-body set-widget-body-width">
        <!--
                The ID "widget-grid" will start to initialize all widgets below
                You do not need to use widgets if you dont want to. Simply remove
                the <section></section> and you can use wells or panels instead
                -->
        <!-- widget grid -->
        <section id="widget-grid" class="">
          <div class="form-group">
            <div class="documentview docViewRecord" id="documentview">
              <div id="DocumentShow" class="fix-record-pdf-doc">

                <object [data]="pdfSrc" type="{{fileType}}" width="100%" height="100%"
                  *ngIf="showPDF_or_ImageViewer && !isIEOrEdge && pdfSrc && !(selectedUrl==null && selectedUrl==undefined) && (selectedVideoUrl==null && selectedVideoUrl==undefined)">
                  <param name="view" value="Fit" />
                </object>

                <!-- <object [data]="pdfSrc" type="application/pdf" width="100%" height="100%"
                  *ngIf="showPDF_or_ImageViewer && isIEOrEdge  && pdfSrc && !(selectedUrl==null && selectedUrl==undefined) && (selectedVideoUrl==null && selectedVideoUrl==undefined)">
                  <param name="view" value="Fit" />
                </object> -->

                <div id="videolink"
                  *ngIf="!(selectedVideoUrl==null && selectedVideoUrl==undefined) && fileType == 'application/pdf'"
                  [innerHtml]="video_html"></div>

                <div id="recordsImageViewer" *ngIf="showPDF_or_ImageViewer">
                  <div id="imgViewertoolbar" *ngIf="fileType.indexOf('image/') != -1"
                    style="height: 25px;background-color: lightgray;">
                    <!-- <div class="p-toolbar-group-left">
                      <a class="btn btn-default btn-xs" href="javascript:void(0);"><i class="fa fa-plus"></i></a>
                      <p-dropdown appendTo="body" [options]="markerTypeList" name="markerTypes" placeholder="Marker Type"  optionLabel="name" [(ngModel)]="selectedMarkerType" [filter]="true"></p-dropdown>
                        <a class="btn btn-default btn-xs" href="javascript:void(0);" (click)="addObject('point');"><i class="fa fa-circle" style="font-size: xx-small;"></i></a>
                        <a class="btn btn-default btn-xs" href="javascript:void(0);" (click)="addObject('multiplePoint')"><i class="fa fa-caret-down" style="font-size: small;"></i></a>
                        <a class="btn btn-default btn-xs" href="javascript:void(0);" (click)="addObject('circle')"><i class="fa fa-circle-o" style="font-size: small;"></i></a>
                        <a class="btn btn-default btn-xs" href="javascript:void(0);" (click)="addObject('rect')"><i class="fa fa-square-o" style="font-size: small;"></i></a>
                        <a class="btn btn-default btn-xs" href="javascript:void(0);" (click)="addObject('polygon')"><i class="fa fa-lemon-o" style="font-size: small;"></i></a>
                        <a class="btn btn-default btn-xs" href="javascript:void(0);" (click)="addObject('pancil')"><i class="fa fa-pencil" style="font-size: small;"></i></a>
                      </div> -->

                    <div class="p-toolbar-group-right">
                      <a class="btn btn-default drawing-toolbar-btn" href="javascript:void(0);" (click)="btnZoomIn()"><i
                          class="fa fa-search-plus" style="font-size: small;"></i></a>
                      <a class="btn btn-default drawing-toolbar-percent-button"
                        href="javascript:void(0);">{{zoomPercentage}}%</a>
                      <a class="btn btn-default drawing-toolbar-btn" href="javascript:void(0);"
                        (click)="btnZoomOut()"><i class="fa  fa-search-minus" style="font-size: small;"></i></a>

                      <!-- <a class="btn btn-default drawing-toolbar-btn" href="javascript:void(0);" (click)="btnMove('down')"><i class="fa fa-angle-down" style="font-size: small;"></i></a>
                        <a class="btn btn-default drawing-toolbar-btn" href="javascript:void(0);" (click)="btnMove('up')"><i class="fa fa-angle-up" style="font-size: small;"></i></a>
                        <a class="btn btn-default drawing-toolbar-btn" href="javascript:void(0);" (click)="btnMove('left')"><i class="fa fa-angle-left" style="font-size: small;"></i></a>
                        <a class="btn btn-default drawing-toolbar-btn" href="javascript:void(0);" (click)="btnMove('right')"><i class="fa fa-angle-right" style="font-size: small;"></i></a> -->

                      <!-- <p-dropdown appendTo="body" [options]="markerTypeList" name="markerTypeFilter" placeholder="Marker Type" optionLabel="name" [(ngModel)]="selectedMarkerType" [filter]="true"></p-dropdown>

                        <a class="btn btn-default drawing-toolbar-btn" href="javascript:void(0);" (click)="saveObject()"><i class="fa fa-save" style="font-size: small;"></i></a>
                        <a class="btn btn-default drawing-toolbar-btn" href="javascript:void(0);"><i class="fa fa-download" style="font-size: small;"></i></a> -->
                    </div>
                  </div>
                  <div id="recordCanvasDiv">
                    <canvas id="recordCanvas" width="0" height="0" style="border-width:0px;"></canvas>
                  </div>
                </div>

                <!-- <div id="officeDocuments" class="office-documents" *ngIf="!(isOfficeDocumentTypeFound==null && isOfficeDocumentTypeFound==undefined)">
                  <iframe *ngIf="!(isOfficeDocumentTypeFound==null && isOfficeDocumentTypeFound==undefined)"
                  src="pdfSrc"  frameborder="0" width="100%" height="100%">
                  </iframe>
                </div> -->

                <div class="video" *ngIf="!(isVideoTypeFound==null && isVideoTypeFound==undefined)">
                  <video controls autoplay #videoPlayer>
                    <!-- crossorigin="anonymous" -->
                    <!-- src="{{selectedUrl}} type="video/mp4"" -->
                    <source />
                    Browser not supported
                  </video>
                </div>

                <div class="audio" *ngIf="!(isAudioTypeFound==null && isAudioTypeFound==undefined)">
                  <audio controls autoplay #audioPlayer>
                    <!-- crossorigin="anonymous" -->
                    <!-- src="{{selectedUrl}} type="audio/mpeg" -->
                    <source />

                    Your browser does not support the audio tag.
                  </audio>
                </div>

                <div role="main" *ngIf="!(isNonPreviewTypeFound==null && isNonPreviewTypeFound==undefined)">
                  <!-- MAIN CONTENT -->
                  <div id="content" style="opacity: 1;">
                    <section id="widget-grid-main" class="well">
                      <div id="content">
                        <!-- row -->
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="well padding-10" style="background-color: #fff;">
                              <div class="row">
                                <div class="col-md-offset-1 col-md-3">
                                  <img style="height: 92px;" src="{{mimeTypeImageSrc}}" class="img-responsive"
                                    alt="img">
                                </div>
                                <div class="col-md-8 padding-left-0 ">
                                  <h3 class="margin-top-0 margin-bottom-9">
                                    <a href="javascript:void(0);">No preview available. </a>

                                    <br>
                                  </h3>
                                  <p>
                                    {{mimeTypeFileName}}
                                  </p>
                                  <div *ngIf ="selectedDocLength > 0">
                                    <button type="button" pButton icon="fa fa-download" class="btn btn-primary minzindex"
                                    (click)="btnDownloadBlob()" label="Download"></button>
                                  <button *ngIf="canUpload" style="margin-left: 10px;" type="button" pButton icon="fa fa-retweet" class="btn btn-default minzindex" (click)="btnShowUpdateFileDlg()"
                                    label="Replace Document"></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </section>
        <!-- end widget grid -->
      </div>
      <!-- end widget content -->
    </div>
    <!-- end widget div -->
  </div>
  <!-- end widget -->
</article>

<p-dialog *ngIf="showStatusLinkDlg" [(visible)]="showStatusLinkDlg" showEffect="fade" [modal]="true"
  [style]="{width: '730px'}" [resizable]="false">
  <ng-template pTemplate="header"  (keyup.shift.tab)="btnCancelDlgClick()">
    Status Link(s)
  </ng-template>
  <div class="top-border-statuslinks" [attr.tabindex]="0">
    <ag-grid-angular #agGridStatusLink [gridOptions]="gridOptions" [columnDefs]="columnDefs"
      (rowClicked)='updateSelectedLinks($event)' [rowData]="selectedStatusLink" sortable="true" filter=true
      style="width: 100%;height:350px" class="ag-theme-balham tablIndexFocus">
    </ag-grid-angular>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane">
      <button autofocus="true" [attr.tabindex]="0" type="submit" pButton icon="fa fa-unlink"
        class="btn btn-primary minzindex p-button-danger" label="Detach Document(s)" (click)="btnDetachClick()"
        [disabled]="(canDetachMXR ==false && currentType=='mxr')|| (canDetachLog==false  && currentType=='log' )||(canDetachGen==false  && currentType=='gen')||(canDetachSearch==false  && currentType=='search')"></button>
      <button type="button" [attr.tabindex]="0" pButton icon="fa fa-close" class="btn btn-default minzindex"
        label="Cancel" (click)="btnCancelDlgClick()"></button>
    </div>
  </ng-template>

</p-dialog>
<p-dialog *ngIf="displayissuedialogue" id="issuedialogue" [(visible)]="displayissuedialogue" showEffect="fade"
  [modal]="true" [style]="{width: '96vw',height:'96vh'}" [resizable]="false">
  <ng-template pTemplate="header"  (keyup.shift.tab)="onKeyDocumentInbox()">
    Document Inbox Items
  </ng-template>
  <div class="upload-dialog">
    <list-comm *ngIf="displayissuedialogue"></list-comm>
  </div>
  <!--<ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">
            <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" label="Cancel" (click)="btnCancelDialogueClick()"></button>
        </div>
    </ng-template>-->

</p-dialog>


<p-dialog *ngIf="showUpdateFileDlg" [(visible)]="showUpdateFileDlg" showEffect="fade" [modal]="true"
  [style]="{width: '730px'}" [resizable]="false">
  <ng-template pTemplate="header"  (keyup.shift.tab)="btnCancelDlgClick()">
    Replace Document
  </ng-template>
  <div class="top-border-statuslinks p-dialog-content p-component-content" [attr.tabindex]="0">
    <div class="row form-group ">

      <div id="UpdateUploadBlob" class="col-sm-6">
        <input #singleFileUpload type="file" class="form-control" (change)="onChange_UploadBlob($event)"
          accept={{acceptFileTypes}}>
        <!-- (change)="onChange($event)"-->
        <!--accept="application/pdf,image/bmp,image/dcx,image/pcx,image/png,image/jpg,image/jpeg,image/jp2,image/jpc,image/jfif,image/tif,image/tiff,image/gif,image/djvu,image/djv,image/jb2,audio/mpeg,audio/wav,audio/ogg,video/webm,video/mp4,video/ogg,application/zip,application/vnd.rar,application/rar,.zip,.rar,.7zip"-->

      </div>
      <div class="col-sm-3">
        <span class="pull-right" *ngIf="showUploadAsImage">
          Convert to PDF
        </span>
      </div>
      <div class="col-sm-3">
        <span class="onoffswitch " *ngIf="showUploadAsImage">
          <input type="checkbox" name="isImageSwitch" class="onoffswitch-checkbox" checked="checked"
            [disabled]="disableImageLoadOption" id="isImageSwitch" [(ngModel)]="isImageSwitch" #IsImage="ngModel">
          <label class="onoffswitch-label" for="isImageSwitch">
            <span class="onoffswitch-inner" data-swchon-text="YES" data-swchoff-text="NO"></span><span
              class="onoffswitch-switch"></span>
          </label>
        </span>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane">
      <button type="button" [attr.tabindex]="0" pButton class="btn btn-default minzindex" (click)="btnUploadBlob()"
        label="Replace Document"></button>
      <button type="button" [attr.tabindex]="0" pButton icon="fa fa-close" class="btn btn-default minzindex"
        label="Cancel" (click)="btnCancelUpdateFileClick(containerForm)"></button>
    </div>
  </ng-template>
</p-dialog>