<section id="widget-grid" class="adminaircraftcls" *ngIf="canAddEditContainerType">
    
    <div class="row">
        <article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable" id="col1">

            <div class="no-margin jarviswidget jarviswidget-sortable" id="wid-id-2" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false" role="widget">

                <header role="heading" class="p-sortable-handle">

                    <span class="widget-icon"><i class="fa fa-lg fa-fw fa-folder-open-o"></i></span>
                    <h2>Container Type</h2>                    
                    <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>

                    <div class="jarviswidget-ctrls" role="menu" *ngIf="canEditContainerType" title="Edit Container (Ctrl+E)">
                        <a class="button-icon hand-cursor tablIndexFocus" [attr.tabindex]="0" id="btneditContinerType"  (keyup.enter)="btnEditClick()" (click)="btnEditClick()">
                            <i class="glyphicon glyphicon-pencil"></i>
                        </a>
                    </div>
                    <div class="jarviswidget-ctrls" role="menu" *ngIf="canAddContainerType" title="Add Container (Ctrl+A)">
                        <a class="button-icon hand-cursor tablIndexFocus" [attr.tabindex]="0"  (keyup.enter)="btnAddClick()" id="btnaddContinerType" (click)="btnAddClick()">
                            <i class="glyphicon glyphicon-plus"></i> 
                        </a>
                    </div>

                </header>


                <div class="widgetdiv">


                    <div class="jarviswidget-editbox">


                    </div>

                    <div id="containerTypelisting" class="no-bottom-padding widget-body">

                        <section id="widget-grid2" class="">

                            <div class="jqgridboxadminaircraft">
                                <!--style="height:80vh !important;"-->

                                <ag-grid-angular id="agGridContainerTypes" [navigateToNextCell]="navigateToNextCell"  #agGridContainerTypes [gridOptions]="gridOptions" [columnDefs]="columnDefs"  [rowData]="gridData" sortable="true" filter=true style="width: 100%;" class="ag-theme-balham containergrid" (rowClicked)='updateSelectedRow($event)' (rowDoubleClicked)="containerRowDoubleClicked($event)">
                                    <!--width: 100%;-->
                                </ag-grid-angular>
                            </div>
                        </section>

                    </div>

                </div>

            </div>

        </article>

    </div>    
</section>
<form #addeditcontainertypeform="ngForm">
    <p-dialog id="addeditcontainerdialog" [(visible)]="display" showEffect="fade" [modal]="true" [style]="{width: '420px'}" [resizable]="false">
        <ng-template pTemplate="header" (keyup.shift.tab)="btnCancelClick(addeditcontainertypeform)">
            {{dialogTitle}}
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form"> 
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Category<span [hidden]="addeditCategory?.valid|| (model.ContainerCategory!=null && model.ContainerCategory.length >0)" class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" (ngModelChange)="categoryChange($event)"  [autofocus]="true" id="addeditCategory" name="addeditCategory" [options]="categoryTypeList" required placeholder="Select Category" [(ngModel)]="model.ContainerCategory" #Focusdd  #addeditCategory="ngModel">

                            </p-dropdown>

                        </fieldset>
                    </div>

                    
                </div>
                
                <div  class="row" [hidden]="model.ContainerCategory != 'MXR'">
                    <fieldset>
                        <label class="label">
                            {{referenceKeyLabel}}
                        </label>
                        <div class="inline-group" id="rdobutton_WP">
                            <label class="radio">
                                <input type="radio" name="astradio_WP"
                                    checked="checked" [(ngModel)]="model.ContainerWorkPack"
                                    [value]="false" (change)="changeWorkPack($event)" required>
                                <i></i>Single
                            </label>
                            <label class="radio">
                                <input type="radio" name="astradio_WP"
                                    [(ngModel)]="model.ContainerWorkPack" [value]="true" required (change)="changeWorkPack($event)">
                                <i></i>Multiple
                            </label>
                        </div>
                    </fieldset>
                </div>
                <div class="row" [hidden]="((model.ContainerCategory == 'MXR' || model.ContainerCategory == 'DOC' || model.ContainerCategory == 'BND') && (model.ContainerWorkPack == false || model.ContainerWorkPack == null))  ">
                    <div class="form-group col-sm-12">
                        
                        <fieldset>
                            <label class="label">
                                Date<span  class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown  [hidden]="(contanierTypeList == null || contanierTypeList.length < 1)"  [autofocus]="true" id="addeditContainer" name="addeditContainer" [options]="contanierTypeList" [(ngModel)]="model.ContainerDate" required placeholder="Select Date Range"  #Focusdd #addeditContainer="ngModel" appendTo="body">

                            </p-dropdown>

                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Type<span [hidden]="addeditContainerType?.valid  || model.ContainerType" class="text-danger">
                                    *
                                </span><span [hidden]="model.ContainerType?.length < 5" class="text-danger">
                                    Recommended to have less than five characters
                                </span>
                            </label>

                            <label class="input rgxErr">
                                <i *ngIf="addeditContainerType.value && !addeditContainerType.valid
                                    && (addeditContainerType.touched || addeditContainerType.dirty )"
                                    class="icon-append fa fa-ban"
                                    title="{{_sharedService.error_rgx_pre}} {{this._sharedService.re_ContainerType_Message}} {{_sharedService.error_rgx_post}}" >
                                    <!-- <span [hidden]="ishidden">
                                      {{_sharedService.error_rgx_pre}}
                                      <b>{{this._sharedService.re_ContainerType_Message}}</b>
                                      {{_sharedService.error_rgx_post}}
                                    </span> -->
                                </i>
                                <input id="addeditContainerType" type="text" name="addeditContainerType"
                                       placeholder="Type" required [(ngModel)]="model.ContainerType" #addeditContainerType="ngModel"
                                       [pattern]="this._sharedService.re_ContainerType"
                                       [disabled]="operationMode==2" [ngClass]="{'readonlyinput2': operationMode==2}" required>

                            </label>
                            <label class="label" *ngIf="model.ContainerType?.length> 0">
                                <span [hidden]="model.ContainerType?.indexOf(' ') == -1" class="text-danger">
                                    Container type should not have space
                                </span>
                            </label>

                        </fieldset>
                    </div>                                    
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Description<span [hidden]="addeditContainerDescription?.valid || model.ContainerDescription" class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="addeditContainerDescription" type="text" name="addeditContainerDescription"
                                       placeholder="Description" required [(ngModel)]="model.ContainerDescription" #addeditContainerDescription="ngModel">

                            </label>

                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset #addeditcontainerdialog>
                            <label class="label">
                                Color Code<span [hidden]="(addeditContainerColorCode?.valid && addeditContainerColorCodeEditor?.valid)|| model.ColorCode" class="text-danger">
                                    *
                                </span>
                            </label>
                            <p-colorPicker appendTo="body" id="addeditContainerColorCode" name="addeditContainerColorCode" [(ngModel)]="model.ColorCode" #addeditContainerColorCode="ngModel"></p-colorPicker>
                            <input id="addeditContainerColorCodeEditor" class="color-picker-input" type="text" name="addeditContainerColorCodeEditor" required [(ngModel)]="model.ColorCode" #addeditContainerColorCodeEditor="ngModel">
                        </fieldset>
                    </div>
                   
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">Status</label>
                            <label class="checkbox">
                                <span class="onoffswitch tablIndexFocus onoffswitch-Width"  [attr.tabindex]="0">
                                    <input type="checkbox" name="addeditcontstatus"
                                           class="onoffswitch-checkbox" checked="checked" id="addeditcontstatus" [(ngModel)]="model.Status" #addeditcontstatus="ngModel">
                                    <label class="onoffswitch-label"
                                           for="addeditcontstatus">
                                        <span class="onoffswitch-inner" data-swchon-text="Active" data-swchoff-text="Inactive"></span><span class="onoffswitch-switch onoffswitch-switch-Right"></span>
                                    </label>
                                </span>
                            </label>  
                        </fieldset>
                    </div>
                </div>

            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex" (click)="onSubmit(addeditcontainertypeform)" [label]="lblsubmitButton" [disabled]="!addeditcontainertypeform.form.valid || isSubmitted"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" (click)="btnCancelClick(addeditcontainertypeform)" label="Cancel" [disabled]="isSubmitted"(focusout)="Focusdd.applyFocus()" ></button>
            </div>
        </ng-template>

    </p-dialog>
</form>