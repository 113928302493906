export interface DocumentType {

    id: number;
}

 export class DocumentTypeEntity {
        public DocumentTypeID: string;
        public assetType: string;
        public category: string;
        public Name: string;
        public Status: boolean;
        public createdBy: string;
        public createdOn: Date;
        public modifiedBy: string;
        public modifiedOn: Date;
    }
