export class RecordMappingModel {
    public FeedId: string;
    public FeedName: string;
    public Map: string;
}

export class RecordConfig {
    constructor(FeedId: string, FeedName: string, FeedType: string, ASN: string,DataType?:any) {
        this.FeedId = FeedId;
        this.FeedName = FeedName;
        this.FeedType = FeedType;
        this.ASN = ASN;
        this.DataType=[];
        for(let i=0;i<DataType.length;i++){
            let data={
                label:DataType[i],
                value:DataType[i]
            }
            this.DataType.push(data)
    }
    }
    public FeedId: string;
    public FeedName: string;
    public FeedType: string;
    public ASN: string;
    public DataType: any;

}