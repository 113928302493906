import { ArticleService } from './../../services/article/article.service';
import { Router } from '@angular/router';
import { FileType } from './../../../api/services/api.helper-classes';
import { Subscriber } from 'rxjs';
import { CatSecAndArticle, sectionDetail, articleModel } from './../articles/article';
import { SharedService } from './../../../shared/services/shared.service';
import { Component, OnInit, Input, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
var _router: Router
@Component({
  selector: 'app-search-article',
  templateUrl: './search-article.component.html',
  styles: []
})
export class SearchArticleComponent implements OnInit {

  public searchdata = '';
  public articleSearch = [];
  public results = [];
  public Sections: sectionDetail = {};
  public CatSecAndArticle;
  public AllArticles = [];
  public firstCategoryName = '';
  public filterArticle = [];

  constructor(private _sharedService: SharedService, private _article: ArticleService, private router: Router, private sanitizer: DomSanitizer) {
    if (!this._sharedService.isAuthUser()) {
      this.router.navigate(['login']);
    }
    else {
      this.updateRoles();
      _router = this.router;

    }
  }

  ngOnInit() {

  }
  updateRoles() {
    if (!this._sharedService.UserRole) {
      this._sharedService.updateRole();
    }

  }

  redirect(event: any) {
    let id;
    if (event.ArticleId == undefined) {
      id = event;
    }
    else {
      id = event.ArticleId;
    }

    this.results = undefined;
    this.searchdata = undefined;

    _router.navigate(["/help/article", id]);

  }

  btnSubmitClick() {
    _router.navigate(["/help/search-articles", this.searchdata]);
  }

  search(event) {

    let query = event.query;
    this.GetFilteredData(query);

  }


  GetFilteredData(query) {

    //  this.results=[];
    this._article.getArticlesForSearch(query)
      .subscribe({
        next: (res) => {
          this.results = res;
        },
        error: (error) => {
          console.log("Server Error !");
        }
      });
  }

}

