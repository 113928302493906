<ng-template [ngIf]="isCurrentModule">
    <div class="jarviswidget-ctrls pull-left" role="menu">
        <div class="btn-group">
            <a class="button-icon dropdown-toggle tablIndexFocus" [attr.tabindex]="0" title="Link Documents"
                data-toggle="dropdown" aria-expanded="true">
                <i class="fa fa-link"></i>
            </a>
            <ul class="dropdown-menu js-status-update">
                <li>
                    <a style="text-align: left;padding-left: 5px;" id="btnadd" (click)="btnAddClick()">
                        <i class="fa fa-link "></i>&nbsp;
                        Link to ROAM-Status (Ctrl+Shift+F)
                    </a>
                </li>
                <li *ngIf="canLinkToExternal">
                    <a style="text-align: left;padding-left: 5px;" class="tablIndexFocus" id="btnLinkExternal"
                        [attr.tabindex]="0" (keyup.enter)="btnLinkExternalClick()" (click)="btnLinkExternalClick()">
                        <i class="fa fa-link "></i>&nbsp;
                        Link to External System (Ctrl+Shift+S)
                    </a>
                </li>
            </ul>
        </div>
    </div>
        <div class="jarviswidget-ctrls pull-left">
        <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="ShowGetDocDlg()" id="btnGetDocLink"
            (click)="ShowGetDocDlg()" title="Get Document Link">
            <i class="fa fa-code"></i>
        </a>
    </div>
    

    <form #containerForm="ngForm">
        <p-dialog id="statuspopuprecd" [(visible)]="diaplayDialog" header="Attach Task" showEffect="fade" [modal]="true"
            [resizable]="false" responsive="true" styleClass="fix-status-model"
            (onHide)="afterStatusPopupClosed($event)">
            <div class="status-dialog">
                <div class="smart-form">
                    <div class="row" style="padding-top: 5px;">
                        <div class="form-group col-sm-3 fix-Padding sv-customheight">
                            <fieldset>
                                <!--<label class="label">&nbsp;</label>-->
                                <span (keyup.shift.tab)="onKeyStatus()" [attr.tabindex]="0">Asset </span>
                                <!-- <p-dropdown appendTo="body"  autofocus="true" #dAsset autofocus="true" [attr.tabindex]="0"  id="asn" name="asn" filter="filter" [options]="assets" placeholder="Select Asset"  [(ngModel)]="containerModel.asn" #asn="ngModel" (onChange)="onChangeAsset($event)">
                                </p-dropdown> -->

                                <p-dropdown appendTo="body" autofocus="true" #dAsset autofocus="true" [attr.tabindex]="0" id="asn"
                                    name="asn" filter="filter" [options]="assets" placeholder="Select Asset"
                                    [(ngModel)]="containerModel.asn" #asn="ngModel" (onChange)="onChangeAsset($event)">
                                    <ng-template style="padding-bottom:20px;padding-left: 0px !important;" let-item
                                        pTemplate="selectedItem">
                                        <span>{{item.label}}</span>
                                        <!-- <span style="font-size:14px;background-color:lightgreen;">{{item.AssetType}}</span> -->
                                    </ng-template>
                                    <ng-template style="padding-bottom:20px !important;padding-left: 0px !important;"
                                        let-asset pTemplate="item">
                                        <div style="padding-bottom: 20px;">
                                            <!-- <span > -->
                                            <span style="padding:0" class="col-md-8">{{asset.label}}</span>

                                            <!-- </div> -->
                                            <!-- <div class="col-md-4"> -->
                                            <span style="padding:0" class="col-md-4">
                                                <span
                                                    style="font-size: 11px;padding: 4px; background-color:lightgreen;color: white;border-radius: 4px;">
                                                    {{asset.AssetType}}
                                                </span>
                                            </span>

                                            <!-- </div> -->
                                        </div>
                                    </ng-template>
                                </p-dropdown>

                            </fieldset>
                        </div>
                        <div class="form-group col-sm-3 fix-Padding sv-customheight">
                            <fieldset>
                                <span>View </span>
                                <p-dropdown appendTo="body" id="view" autofocus="true" name="nmview" filter="filter"
                                    [options]="assetViewList" required [(ngModel)]="containerModel.viewSettingId"
                                    placeholder="Select View" (onChange)="onChangeView($event)">
                                </p-dropdown>
                            </fieldset>
                        </div>
                        <div class="form-group col-sm-1" id="attackbtn">
                            <fieldset class="pull-right">
                                <button pButton type="button" (click)="attachDocument()" icon="fa fa-paperclip"
                                    label="Attach Task" [disabled]="!containerForm.form.valid" class="attachment-button"
                                    (focusout)="dAsset.applyFocus()"></button>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row sv-custommargin" id="statusPopup" *ngIf="!showSearch">
                        <viewlist></viewlist>
                    </div>

                </div>
            </div>
            <!--<ng-template pTemplate="footer">
                <div class="p-dialog-buttonpane">
                    <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" label="Cancel"></button>
                    <button type="button" pButton icon="fa fa-angle-double-right" class="btn btn-primary minzindex" label="Attach"></button>
                </div>
            </ng-template>-->

        </p-dialog>

    </form>

    <form [formGroup]="externalLinkForm" *ngIf="diaplayExternalDialog">
        <p-dialog id="externalLinkDlg" [(visible)]="diaplayExternalDialog" header="Document URL-Link to External System"
            showEffect="fade" [modal]="true" [resizable]="false" responsive="true" [style]="{width: '700px'}">
            <div class="upload-dialog">

                <div id="smart-form" class="smart-form dialogSettings">
                    <div id="form-fields form-group">
                        <div class="row" *ngFor="let col of FeedMapping;">
                            <div class="col-sm-6" *ngIf='col.Field !="URL" && col.Field !="DocDate" && col.Field != "uuid"'>
                                <fieldset>
                                    <label class="label">{{col.Label}} <span class="text-danger"></span></label>
                                    <label class="input">
                                        <input id="field_{{col.Field}}" type="text" class="input-smText"
                                            name="field_{{col.Field}}" formControlName="field_{{col.Field}}">
                                    </label>
                                </fieldset>
                            </div>
                            <div class="col-sm-6" *ngIf='col.Field =="DocDate" && col.Field != "uuid"'>
                                <fieldset>
                                    <label class="label">{{col.Label}} <span class="text-danger"></span></label>
                                    <label class="input">
                                        <i class="icon-append fa fa-calendar"></i>
                                        <!-- <p-calendar appendTo="body" [monthNavigator]="true" [yearNavigator]="true" class="datepicker hasDatepicker" yearRange="1950:2030" [style]="{width:'285px'}"
                                                id="field_{{col.Field}}"  name="field_{{col.Field}}"  [dateFormat]="dateFormatCalender" ></p-calendar> -->
                                        <p-calendar appendTo="body" class='datepicker' id="field_{{col.Field}}"
                                            formControlName="field_{{col.Field}}" [style]="{'width':'92%'}"
                                            name="field_{{col.Field}}" [monthNavigator]="true" [yearNavigator]="true"
                                            yearRange="1950:2030" [dateFormat]="dateFormatCalender">
                                        </p-calendar>
                                    </label>
                                </fieldset>
                            </div>
                            <div class="col-sm-12" *ngIf='col.Field =="URL" && col.Field != "uuid"'>
                                <fieldset>
                                    <label class="label">{{col.Label}} <span class="text-danger"></span></label>
                                    <label class="input">
                                        <textarea id="field_{{col.Field}}" type="text" style="width: 100%;"
                                            class="input-smText readonlyinput" name="field_{{col.Field}}"
                                            [disabled]="true" formControlName="field_{{col.Field}}"></textarea>
                                    </label>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ng-template pTemplate="footer">
                <div class="p-dialog-buttonpane">
                    <button type="button" pButton icon="fa fa-check" class="btn btn-primary" label="Link"
                        (click)="CreateUpdateLink()"></button>
                    <button type="button" pButton icon="fa fa-close" class="btn btn-default" label="Cancel"
                        (click)="CancelExternalLinkClick()"></button>

                </div>
            </ng-template>
        </p-dialog>
    </form>

    <p-dialog *ngIf="diaplayDocLinkDlg" id="docLinkDlg" [(visible)]="diaplayDocLinkDlg" header="Get Document Link"
        showEffect="fade" [modal]="true" [resizable]="false" responsive="true" [style]="{width: '700px'}">
        <div class="upload-dialog">
            <div id="smart-form" class="smart-form dialogSettings">
                <div id="form-fields form-group">
                    <div class="row">
                        <div class="col-sm-12">
                            <fieldset>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <label (keyup.shift.tab)="CloseLinkDlgClick()" [attr.tabindex]="0"
                                            class="label">Document Lin<span class="text-danger"><a
                                                    style="color:black;cursor:pointer;"
                                                    (click)='Download()'>k</a></span></label>
                                        <!-- <a (click)='Download()'>Download</a> -->
                                    </div>
                                    <div class="col-sm-6 ">
                                        <!-- <button #dCopy [attr.tabindex]="0" autofocus="true" style="border-style: none;" type="button" class="btn btn-default pull-right tablIndexFocus" onclick="getLinkClick()" pTooltip="{{docUrl}}"  ngxClipboard [cbContent]="docUrl" (keyup.enter)='getLinkClick()' (click)='getLinkClick()'>
                                                        </button> -->
                                        <i #dCopy [attr.tabindex]="0" autofocus="true"
                                            class="fa fa-copy fa-lg tablIndexFocus pull-right" pTooltip="{{docUrl}}"
                                            ngxClipboard [cbContent]="docUrl" (keyup.enter)='getLinkClick()'
                                            (click)='getLinkClick()'></i>

                                    </div>
                                </div>


                                <!-- <button tabindex="0">  <i class='fa fa-copy fa-lg pull-right' pTooltip="{{docUrl}}"  ngxClipboard [cbContent]="docUrl" (click)='getLinkClick()'  ></i></button> -->
                                <label class="input">
                                    <textarea id="field_DocLink" type="text" style="width: 100%;"
                                        class="input-smText readonlyinput" name="field_DocLink"
                                        [disabled]="true">{{docUrl}}</textarea>
                                </label>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <!-- <button type="button" pButton  icon="fa fa-check" class="btn btn-primary" label="Link" (click) = "CreateUpdateLink()"></button> -->
                <button type="button" pButton icon="fa fa-close" class="btn btn-default" label="Close"
                    [attr.tabindex]="0" (click)="CloseLinkDlgClick()" (focusout)="applyFocusToCopyLink()"></button>

            </div>
        </ng-template>
    </p-dialog>
</ng-template>