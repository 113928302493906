import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { Hotkey, HotkeysService } from "angular2-hotkeys";
import { SelectItem } from "primeng/api";
import { AssetService } from "src/app/admin/services/asset/asset.service";
import { SharedService } from "../../../shared/services/shared.service";
import { MoveContainerModel } from "../../services/container/container";
import { ContainerService } from "../../services/container/container.service";

@Component({
  selector: "move-container",
  templateUrl: "./move-container.component.html",
  providers: [AssetService, ContainerService],
})
export class MoveContainerComponent implements OnInit, OnDestroy {
  public appHotkeys: Hotkey[] = [];
  display: boolean = false;
  @Input() recordType: string;
  assets: SelectItem[] = [];
  isSubmitted: boolean = false;
  public targetAssetId;
  moveContainerAssetList: SelectItem[] = [];
  constructor(
    public _sharedService: SharedService,
    private _hotkeysService: HotkeysService,
    private _assetService: AssetService,
    private _containerService: ContainerService,
    private _router: Router
  ) {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(["login"]);
    } else {
      this.setHotKeys();
    }
  }

  ngOnInit() { }
  btnCancelClick() {
    this.targetAssetId = null;
    this.display = false;
  }
  showMoveContainerDialog() {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(["login"]);
    }
    this.targetAssetId = null;
    let assetDataObj = JSON.parse(this._sharedService.selectedAsset[0].data);
    let isAssetSelected: string[] = this._sharedService.selectedAsset;
    let selectedRow: any = this._sharedService.selectedContainer;
    this.populateAsset();
    if (
      selectedRow.length == undefined ||
      (selectedRow.length != undefined && selectedRow.length > 0)
    ) {
      this.display = true;
    } else {
      this._sharedService.clearToast();
      this._sharedService.showToast({
        severity: "warn",
        summary: "No Container selected",
        detail: "Please select a Container.",
      });
    }
  }

  onSubmit(form: NgForm) {
    let assetDataObj = JSON.parse(this._sharedService.selectedAsset[0].data);
    let selectedRow: any = this._sharedService.selectedContainer;
    var containerIds = null;
    if (typeof this.targetAssetId != "undefined" && this.targetAssetId) {
      if (selectedRow.length == undefined) {
        containerIds = selectedRow.ContainerID;
      } else if (selectedRow.length > 1) {
        containerIds = selectedRow.map((x) => x.ContainerID).join(",");
      }
      let body: MoveContainerModel = {
        SourceASN: assetDataObj.ASN,
        DestinationASN: this.targetAssetId,
        MoveContainerIDs: containerIds,
      };
      this._sharedService.showLoader("Processing...");
      this._assetService
        .getAssetByASN(body.DestinationASN)
        .subscribe({
          next: (response) => {
            var selectedAsset = response;
            var assetRoles = selectedAsset.PlanRoles.split(",");
            var categoryRole = this.getCurrentAssetRole(this.recordType);
            if (assetRoles.indexOf(categoryRole) < 0) {
              this._sharedService.hideLoader();
              this._sharedService.clearToast();
              this._sharedService.showToast({
                severity: "warn",
                summary: "Role not assigned.",
                detail: "Target container asset has " + selectedAsset.PlanName + ". This plan does not allow create container.",
              });
            }
            else if (selectedAsset.ReadOnly) {
              this._sharedService.hideLoader();
              this._sharedService.clearToast();
              this._sharedService.showToast({
                severity: "warn",
                summary: "Target asset is read only.",
                detail: "Container(s) cannot be moved.",
              });
            } else {
              this._containerService.moveContainers(body)
                .subscribe({
                  next: (response) => {
                    this._sharedService.hideLoader();
                    this.display = false;
                    response;
                    form.resetForm();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({
                      severity: "success",
                      summary: "Success",
                      detail:
                        "Move container process has been started.Rebuild Indexes from Admin>Search Service.",
                    });
                  },
                  error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({
                      severity: "error",
                      summary: "Error Occured",
                      detail: "Container not added, please try again.",
                    });
                    this.display = false;
                    this.changeButtonsStatus(false);
                  }
                });
            }
          }
        });
    } else {
      this._sharedService.showToast({
        severity: "warn",
        summary: "No target asset selected",
        detail: "Please select an asset.",
      });
    }
  }
  changeButtonsStatus(status: boolean) {
    this.isSubmitted = status;
  }
  getCurrentAssetRole(recordType) {
    switch (recordType.toUpperCase()) {
      case "MXR":
        return "Records_MXR_Con_Add";
        break;
      case "LOG":
        return "Records_LOG_Con_Add";
        break;
      case "GEN":
        return "Records_GEN_Con_Add";
        break;
      case "DOC":
        return "Records_DOC_Con_Add";
        break;
      case "BND":
        return "Transition_BND_Con_Add";
        break;
    }
  }
  populateAsset() {
    // let userRole: string = localStorage.getItem("roles").trim();
    // let body = { UserRole: userRole };
    let assetDataObj = JSON.parse(this._sharedService.selectedAsset[0].data);

    this._assetService.getAssetWithAssetIdByUserRole()
      .subscribe({
        next: (response) => {
          this.assets = response;
          this.moveContainerAssetList = this.assets.filter(
            (x) => JSON.parse(x.value).ASN != assetDataObj.ASN
          );
        },
        error: (error) => {
          this.assets = [];
          console.log(error);
        }
      });
  }
  onChangeAsset(event: any) {
    this.targetAssetId = JSON.parse(event.value).ASN;
  }
  ngOnDestroy() {
    this.appHotkeys.forEach((hotkey) => {
      this._hotkeysService.remove(hotkey);
    });
    this.appHotkeys = [];
  }
  setHotKeys() {
    let hotKeyObj: Hotkey = null;

    hotKeyObj = new Hotkey("ctrl+j", (event: KeyboardEvent): boolean => {
      this.showMoveContainerDialog();
      return false; // Prevent bubbling
    });

    this._hotkeysService.add(hotKeyObj);
    this.appHotkeys.push(hotKeyObj);
  }
}
