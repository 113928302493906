
//Components
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';


//interfaces


@Component({
    selector: 'record',
    templateUrl: './record.component.html',
    //animations: [routerTransition()],
    //host: { '[@routerTransition]': '' },
    //providers: [MenuService]
})

export class RecordComponent implements OnInit {

    //#region Gloabal Variables

    name: string;

    //#endregion

    //#region Initialization of Component
    constructor(private _sharedService: SharedService, private _router: Router) {
    }
    ngOnInit() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this._sharedService.prevURL = this._router.url;
            this._sharedService.updateRole();
            if (this._sharedService.getSubdomain().toLowerCase() == this._sharedService.Tenant_Internal) {
                this._router.navigate(['reports']);
            }
            else if (this._sharedService.getSubdomain().toLowerCase() == this._sharedService.Tenant_Help) {
                this._router.navigate(['/help/category']);
            }
            else {
                this._sharedService.getConfiguration();

                if (this._router.url.indexOf('record') != -1) {
                    this._sharedService.onModuleChanged("record");
                }
                else if (this._router.url.indexOf('transition') != -1) {
                    this._sharedService.onModuleChanged("transition");
                }
            }
        }
    }
    //#endregion




}
