<form id="recordConfigDlg" #addForm="ngForm">
    <header role="heading" class="tab-header">

        <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>

        <p-confirmDialog [style]="{width: '430px'}" #cd>
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="fa fa-check" label="Ok" (click)="cd.accept()"></button>
                <!--<button type="button" pButton icon="fa fa-close" label="Cancel" (click)="cd.reject()"></button>-->
            </ng-template>
        </p-confirmDialog>
    </header>
    <div class="row addaircraftcls">
        <article class="col-sm-12 col-md-12 col-lg-12" id="col2">
            <!-- Widget ID (each widget will need unique ID)-->

            <div class="jarviswidget" id="wid-id-0" data-widget-colorbutton="false" data-widget-editbutton="false"
                data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false">

                <header>
                    <span class="widget-icon"><i [className]="iconClass"></i></span>
                    <h2>{{moduleName}} - {{feedName}} - Configuration</h2>
                    <!-- <h2>Records - {{feedName}} - Configuration</h2> -->
                </header>

                <!-- widget div-->
                <div class="widgetdiv">
                    <!-- widget content -->
                    <div class="widget-body">
                        <!-- widget grid -->
                        <section id="widget-grid0" class="">
                            <div class="smart-form">
                                <div class="row">
                                    <div class="form-group col-sm-12">
                                        <ag-grid-angular id="recordDocPackGrid" #recordDocPackGrid
                                            [gridOptions]="gridOptions" [getRowId]="getRowNodeId"
                                            [columnDefs]="columnDefs" [rowData]="gridData" sortable="true" filter=true
                                            [style.height]="height" style="width: 100% !important;"
                                            class="ag-theme-balham">
                                        </ag-grid-angular>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div class="widget-footer">
                            <p-dropdown appendTo="body" [panelStyle]="{'bottom': 'calc(100% + 5px)'}"
                                *ngIf="feedTypeInput == 'ExportTemp'" class="col-md-2" [style]="{'text-align':'left','max-width':'100%','width':'100%'}"
                                [(ngModel)]="selectedDataType" name='selectedDataType' [autofocus]="true"
                                (onChange)="setFeedDataType($event)" [options]="dataTypes">
                            </p-dropdown>
                            <select-target-container class="margin-right-3px" *ngIf="feedTypeInput=='ExportTemp'"
                                [inputDataType]='selectedDataType'
                                [configurationSelectedRows]="configurationSelectedRows"
                                (targetContainer)="onTargetContainerChange($event)"></select-target-container>

                            <button *ngIf="feedTypeInput=='ExportTemp'" type="button"
                                class="btn btn-primary margin-right-3px custom-button" (click)="confirmGenrate()">
                                {{generateButtonLabel}}
                            </button>
                            <button type="button" class="btn btn-primary margin-right-3px custom-button"
                                (click)="confirmSave()">
                                {{buttonLabelName}}
                            </button>
                            <a class="btn btn-default" (click)="btnCancelClick()">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end widget -->
        </article>
        <!-- END COL -->
    </div>
</form>


<form #expiryDate="ngForm">
    <p-dialog [(visible)]="dateDisplay" showEffect="fade" [modal]="true" [style]="{'width': '328px','text-align':'center'}" [resizable]="false">
        <ng-template pTemplate="header" (keyup.shift.tab)="btnExpiryCancelClick()">
            Expiry Date
        </ng-template>
        <div>
            <div class="smart-form">
                <p-calendar appendTo="body" class='datepicker hasDatepicker audit-datepicker' [showTime]="true"
                    [(ngModel)]="sharedAccessExpiryTime" [dateFormat]="dateFormatCalender" name="SharedAccessExpiryTime"
                    (onSelect)='dateChange()' [minDate]="minDateValue" [maxDate]="maxDateValue" [inline]="true">
                </p-calendar>

            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton class="btn btn-primary minzindex" (click)="btnExpiryTime()"
                    [disabled]="emailButtonDisable" label="Email"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>