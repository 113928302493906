<div class="row adduserscls">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" id="col1">
        <h1 class="page-title-user txt-color-blueDark">

            <!-- PAGE HEADER -->
            <i class="fa fa-fw fa-user"></i>
            <a routerLink="/admin/users">Users</a>
            <span>
                &gt;
                <span id="page-mode">{{screenName}}</span>
            </span>
        </h1>
    </div>

    <article class="col-sm-12 col-md-12 col-lg-12" id="col2">
        <!-- Widget ID (each widget will need unique ID)-->
        <div class="jarviswidget" id="wid-id-0" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false">
            <header>
                <span class="widget-icon"><i class="fa fa-pencil-square-o"></i></span>
                <h2>Users</h2>
                <div class="jarviswidget-ctrls" *ngIf="!hasUserId" title="Copy">
                    <a title="Copy Roles & Asset" class="button-icon hand-cursor tablIndexFocus"  [attr.tabindex]="0" (keyup.enter)="btnCopyClick()" id="btncopy" (click)="btnCopyClick()">
                        <i class="glyphicon glyphicon-copy"></i>
                    </a>
                </div >
            </header>
            <!-- widget div-->
            <div class="widgetdiv" style="height:80vh;overflow-x: hidden !important; overflow-y: scroll !important;">
                <!--style="height:80vh;overflow-x: hidden !important;-->
                <!--max-height: 529.444px; overflow-x: hidden; overflow-y: scroll;-->
                <!-- widget content -->
                <div class="widget-body">
                    <!-- widget grid -->
                    <section id="widget-grid0" style="height: 70vh !important;">
                        <!--style="height: 70vh !important;"-->
                        <div class="navbar-form form-group">
                            <div class="smart-form">
                                <!--<div class="row">
                                    <div class="form-group col-sm-4">
                                        <fieldset>
                                            <label class="label">First Name</label>
                                            <label class="input">
                                                <i class="icon-append fa fa-user"></i>
                                                <input id="fname" type="text" name="fname" placeholder="First name" [(ngModel)]="newUserModel.firstName">
                                            </label>
                                        </fieldset>
                                    </div>
                                    <div class="form-group col-sm-4">
                                        <fieldset>
                                            <label class="label">Last Name</label>
                                            <label class="input">
                                                <i class="icon-append fa fa-user"></i>
                                                <input id="lname" type="text" name="lname" [(ngModel)]="newUserModel.lastName" placeholder="Last name">
                                            </label>
                                        </fieldset>
                                    </div>
                                    <div class="form-group col-sm-4">
                                        <fieldset>
                                            <label class="label">Email</label>
                                            <label class="input">
                                                <i class="icon-append fa fa-envelope-o"></i>
                                                <input type="email" id="email" name="email" [(ngModel)]="newUserModel.email" placeholder="E-mail" [ngClass]="{'readonlyinput2': hasUserId}" [disabled]="hasUserId">
                                            </label>
                                        </fieldset>
                                    </div>
                                </div>-->
                                <div class="row">
                                    <div class="form-group col-sm-3">
                                        <!--[ngClass]="{userOfType=='Internal'?'col-sm-4':'col-sm-3' }"-->
                                        <fieldset>
                                            <label class="label">
                                                First Name<span [hidden]="newUserModel.firstName.length>0" class="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <label class="input">
                                                <i class="icon-append fa fa-user"></i>
                                                <input id="fname" type="text" name="fname" placeholder="First name" [(ngModel)]="newUserModel.firstName">
                                            </label>
                                        </fieldset>
                                    </div>
                                    <div class="form-group col-sm-3">
                                        <!--[ngClass]="{userOfType=='Internal'?'col-sm-4':'col-sm-3' }"-->
                                        <fieldset>
                                            <label class="label">
                                                Last Name<span [hidden]="newUserModel.lastName.length>0" class="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <label class="input">
                                                <i class="icon-append fa fa-user"></i>
                                                <input id="lname" type="text" name="lname" [(ngModel)]="newUserModel.lastName" placeholder="Last name">
                                            </label>
                                        </fieldset>
                                    </div>
                                    <div class="form-group col-sm-3">
                                        <!--[ngClass]="{userOfType=='Internal'?'col-sm-4':'col-sm-3' }"-->
                                        <fieldset>
                                            <label class="label">
                                                Email<span [hidden]="newUserModel.email.length>0" class="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <label class="input">
                                                <i class="icon-append fa fa-envelope-o"></i>
                                                <input type="email" id="email" name="email" [(ngModel)]="newUserModel.email" placeholder="E-mail" [ngClass]="{'readonlyinput2': hasUserId}" [disabled]="hasUserId">
                                            </label>
                                        </fieldset>
                                    </div>
                                    <div class="form-group col-sm-3 user-group-dropdown">
                                        <!--*ngIf="userOfType!='Internal'"-->
                                        <fieldset>
                                            <label class="label">
                                                Team<span [hidden]="usergroup.valid|| (newUserModel.UserGroupId && newUserModel.UserGroupId!='0')" class="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <p-dropdown id="usergroup" name="usergroup" [options]="userGroupList" placeholder="Select Team" required [(ngModel)]="newUserModel.UserGroupId" #usergroup="ngModel" [filter]="true" #usergroupfilter [resetFilterOnHide]="true" appendTo="body">

                                            </p-dropdown>
                                        </fieldset>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-sm-3">
                                        <fieldset>
                                            <label class="label">
                                                Expiry Date
                                            </label>
                                            <label class="input" >
                                                <i class='icon-append fa fa-calendar'></i>
                                                <p-calendar appendTo="body" class='datepicker hasDatepicker' id='date' name="date" panelStyleClass="panelTopUser"
                                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030" 
                                                    [(ngModel)]="newUserModel.ExpiryDate" #expiryDate="ngModel" [dateFormat]="dateFormatCalender"
                                                    [style]="{width:'87%'}" placeholder="Expiry Date" [showButtonBar]="true" [showTime]="true" hourFormat="24"
                                                    >
                                                </p-calendar>
                
                                            </label>
                
                                        </fieldset>
                                    </div>
                                    <div class="form-group" [ngClass]="{'col-sm-3':isInternalExternalRights,'col-sm-6': (!isInternalExternalRights)}">
                                        <fieldset>
                                            <label class="label">Status</label>
                                            <label for="isactive" class="checkbox col-sm-2">

                                                <span class="onoffswitch tablIndexFocus onoffswitch-Width"  [attr.tabindex]="0" (keyup.enter)="ToggleChecked()">
                                                    <input [checked]="isChecked" type="checkbox" name="onoffswitchstatus" class="onoffswitch-checkbox" [(ngModel)]="newUserModel.status" id="isactive">
                                                    <label class="onoffswitch-label" for="isactive"><span class="onoffswitch-inner" data-swchon-text="Active" data-swchoff-text="Inactive"></span><span class="onoffswitch-switch onoffswitch-switch-Right"></span></label>
                                                </span>
                                            </label>
                                        </fieldset>
                                    </div>
                                    <div id="usertypeadmin" class="form-group col-sm-3" *ngIf="isInternalExternalRights">
                                        <fieldset>
                                            <label class="label">
                                                User Type<span [hidden]="usertype.valid|| (newUserModel.userType!=null && newUserModel.userType.length >0)" class="text-danger">
                                                    *
                                                </span>
                                            </label>

                                            <p-dropdown id="usertype" name="usertype" [options]="userTypeList" placeholder="Select User Type" [(ngModel)]="newUserModel.userType" #usertype="ngModel" (onChange)="onTenantSelectionChanged($event)" appendTo="body">

                                            </p-dropdown>

                                        </fieldset>
                                    </div>
                                    <div id="usertenantsadmin" class="form-group col-sm-3" *ngIf="isInternalExternalRights && newUserModel.userType!='External'">
                                        <fieldset>
                                            <label class="label">
                                                Tenant<span [hidden]="tenantselect.valid|| (newUserModel.userTenants!=null && newUserModel.userTenants.length >0)" class="text-danger">
                                                    *
                                                </span>
                                            </label>

                                            <p-multiSelect  id="tenantselect" [options]="tenantSelectionList" [(ngModel)]="newUserModel.userTenants" name="tenantselect" #tenantselect="ngModel" [style]="{'width':'22.058823529411764vw'}"  [maxSelectedLabels]="3" selectedItemsLabel="{0} items selected" appendTo="body"></p-multiSelect> <!--[disabled]="newUserModel.userType=='External'"-->

                                        </fieldset>
                                    </div>
                                </div>
                                <!--<br />-->
                                <div class="row">
                                    <div class="form-group col-sm-6">
                                        <div class="panel panel-default">
                                            <div class="panel-heading panalheading-padding">
                                                <h3 class="panel-title">Permissions</h3>
                                            </div>
                                            <div class="panel-body">
                                                <div class="col-sm-12 picklist-padding fix-picklist-width">
                                                    <p-pickList id="rolespicklist" responsive="true" sourceHeader="Available Roles" targetHeader="Selected Roles"
                                                        [source]="availableRoles" [target]="selectedRoles" filterBy="RoleLabel" dragdrop="true"
                                                        sourceFilterPlaceholder="Search by role" targetFilterPlaceholder="Search by role"
                                                        [sourceStyle]="{'height':'270px'}" [targetStyle]="{'height':'270px'}">
                                                        <ng-template let-role pTemplate="item">
                                                            <div>
                                                                <div class="col-md-8" style="font-size:14px"><span>{{role.RoleLabel}}</span></div>
                                                                <div class="col-md-4">
                                                                    <span *ngIf="role.Type == 'Group'" (click)="displayGroupDetail(role.Id)" class="group-tags"
                                                                        style="background-color: darkgoldenrod;margin-left: 5px;">
                                                                        <span class="fa fa-external-link">
                                                                        </span>
                                                                        &nbsp; Group</span>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                            
                                                        <!-- <ng-template   let-role pTemplate="item">
                                                                                                        <div>
                                                                                                            <div style="font-size:14px">{{role.RoleLabel}}
                                                                                                                <span *ngIf="role.Type == 'Group'" (click)="displayGroupDetail(role.Id)" class="group-tags" style="background-color: darkgoldenrod;margin-left: 5px;">
                                                                                                                    <span class="fa fa-external-link">
                                                                                                                    </span>
                                                                                                                &nbsp; Group</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </ng-template> -->
                                                    </p-pickList>
                                                </div>
                                            </div>
                                            </div>
                                    </div>
                                    <div class="form-group col-sm-6" >
                                        <div class="panel panel-default">
                                            <div class="panel-heading">
                                                <h3 class="panel-title panalheading-padding">Assets </h3>
                                            </div>
                                            <div class="panel-body">
                                                <div class="col-sm-12 picklist-padding fix-picklist-width" >
                                                  <p-pickList  id="aircraftspicklist" responsive="true" dragdrop="true" sourceHeader="Available Assets" targetHeader="Selected Assets" [source]="availableAircrafts" [target]="selectedAircrafts" filterBy="label" sourceFilterPlaceholder="search by asset" targetFilterPlaceholder="search by asset" [sourceStyle]="{'height':'270px'}" [targetStyle]="{'height':'270px'}" (onMoveToTarget)="OnMovedToSelectedAircraft($event)" (onMoveAllToTarget)="OnMovedAllToSelectedAircraft($event)" (onMoveToSource)="OnMovedToAvailableAircraft($event)" (onMoveAllToSource)="OnMovedAllToAvailableAircraft($event)">
                                                    <ng-template let-asset pTemplate="item">
                                                    <div>
                                                        <div class="col-md-8" style="font-size:14px" ><span>{{asset.label}}</span></div>
                                                        <div class="col-md-4" style="font-size:14px;padding-left:0px !important;padding-right:0px !important"><span *ngIf="asset.UserAssetType != 'Group'" class="group-tags" style="background-color:lightgreen">{{asset.ViewType}}</span><span *ngIf="asset.UserAssetType == 'Group'" (click)="displayAssetGroupDetail(asset.value)" class="group-tags" style="background-color: rgb(15, 203, 228);margin-left: 5px;"><span class="fa fa-external-link"></span> &nbsp; Group</span></div>
                                                    </div>
                                                </ng-template>
                                                    <!-- <ng-template let-car pTemplate="item">
                                                      <div>
                                                        <div style="font-size:14px">{{car.label}}</div>
                                                      </div>
                                                    </ng-template> -->
                                                  </p-pickList>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-sm-6">
                                        <fieldset>
                                            <label class="label">Views</label>
                                            <div class="inline-group" id="rdobuttonview">
                                                <label class="radio" style="padding-left:4px;">
                                                    <input type="radio" name="radio-inlineview" checked="checked" [(ngModel)]="newUserModel.showViews" [value]="false" (ngModelChange)="changeShowViews()">
                                                    <i></i>
                                                    
                                                    <span class="onoffswitch tablIndexFocus"  [attr.tabindex]="0" (keyup.enter)="ToggleCheckedAll()">
                                                        All
                                                    </span>
                                                    
                                                </label>
                                                <label class="radio" style="padding-left:4px;">
                                                    <input type="radio" name="radio-inlineview" [(ngModel)]="newUserModel.showViews" [value]="true" (ngModelChange)="changeShowViews()">
                                                    <i></i>
                                                    <span class="onoffswitch tablIndexFocus"  [attr.tabindex]="0" (keyup.enter)="ToggleCheckedSelected()">
                                                        Selected
                                                    </span>
                                                </label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-sm-6" *ngIf="newUserModel.showViews">
                                        <div class="panel panel-default">
                                            <div class="panel-heading">
                                                <h3 class="panel-title panalheading-padding">Views </h3>
                                            </div>
                                            <div class="panel-body">
                                                <div class="col-sm-12 picklist-padding fix-picklist-width">
                                                    <!-- <ng-template let-car pTemplate="item">
                                                        <div>
                                                            <img src="assets/showcase/images/demo/car/{{car.brand}}.png" style="display:inline-block;margin:2px 0 2px 2px" width="48">
                                                            <div style="font-size:14px;float:right;margin:15px 5px 0 0">{{car.brand}} - {{car.year}} - {{car.color}}</div>
                                                        </div>
                                                    </ng-template> -->
                                                    <p-pickList  *ngIf="newUserModel.showViews" id="viewspicklist" responsive="true" sourceHeader="Available Views" targetHeader="Selected Views" [source]="availableViews" [target]="selectedViews" filterBy="label" sourceFilterPlaceholder="Search by view" targetFilterPlaceholder="Search by view" [sourceStyle]="{'height':'270px'}" [targetStyle]="{'height':'270px'}">
                                                        <ng-template  let-car pTemplate="item">
                                                            <div>
                                                                <div class="col-md-5" style="font-size:14px" [ngClass]="getStatus(car.label)"><span>{{getLabel(car.label)}}</span></div>
                                                                <div class="col-md-7" style="font-size:14px;padding-left:0px !important;padding-right:0px !important"><span [ngClass]="getAssetClass(car.label)"></span><span class="group-tags" style="background-color:lightgreen">{{getType(car.label)}}</span><span class="group-tags" style="background-color:lightgreen;margin-left: 1px">{{getVisibleStatus(car.label)}}</span></div>
                                                            </div>
                                                        </ng-template>
                                                    </p-pickList>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!-- end row -->
                        <div class="widget-footer">
                            <button type="button" class="btn btn-primary margin-right-3px" [disabled]="(newUserModel.firstName.length==0)||(newUserModel.lastName.length==0)||(newUserModel.email.length==0) || isSubmitted ||(newUserModel.showAircraft && selectedAircrafts.length==0)||(isInternalExternalRights &&(newUserModel.userTenants.length==0 && newUserModel.userType=='Internal'))||(newUserModel.UserGroupId==null)" (click)="btnSaveClick()">
                                {{buttonText}}
                            </button>
                            <button type="button" class="btn btn-default" [disabled]="isSubmitted" (click)="btnCancelClick()">
                                Cancel
                            </button>
                        </div>

                    </section>
                    <!-- end widget grid -->
                    <!--<div class="widget-footer">
                        <button type="button" class="btn btn-primary" [disabled]="(newUserModel.firstName.length==0)||(newUserModel.lastName.length==0)||(newUserModel.email.length==0) || isSubmitted ||(newUserModel.showAircraft && selectedAircrafts.length==0)" (click)="btnSaveClick()">
                            {{buttonText}}
                        </button>
                        <button type="button" class="btn btn-default" [disabled]="isSubmitted" (click)="btnCancelClick()">
                            Cancel
                        </button>
                    </div>-->
                </div>

                <!-- end widget content -->
            </div>
            <!-- end widget div -->
        </div>
        <!-- end widget -->
    </article>
    <!-- END COL -->
    <p-dialog *ngIf="display" [(visible)]="display" showEffect="fade" [modal]="true" [style]="{width: '750px'}" [resizable]="false" (onShow)="refreshGrid($event)">
        <ng-template pTemplate="header" >
            Copy Roles And Aircraft
        </ng-template>
        <div class="upload-dialog">
            <ag-grid-angular  #agGridCopyUser [gridOptions]="gridOptions" [columnDefs]="columnDefs" (rowClicked)='updateSelectedUserRow($event)' [rowData]="gridData" sortable="true" filter=true style="width: 100%;height:350px" class="ag-theme-balham">
            </ag-grid-angular>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="submit" pButton icon="fa fa-check" class="btn btn-primary minzindex" label="Copy" (click)="onSubmit()"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" label="Cancel" (click)="btnCancelDialogueClick()"></button>
            </div>
        </ng-template>

    </p-dialog>
    <form #addeditPGform="ngForm" *ngIf="displayGroupForm">
        <p-dialog [(visible)]="displayGroupForm" showEffect="fade" [modal]="true" [style]="{width: '400px'}" [resizable]="false">
            <ng-template pTemplate="header" >
               Group Roles
            </ng-template>
            <div class="upload-dialog">
                <div class="smart-form">
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <p-listbox  [options]="groupRoleList" optionLabel="RoleLabel" filter="filter" [listStyle]="{'max-height':'50vh'}" [style]="{'width':'360px'}">
                                <ng-template pTemplate="header" >
                                    <i class="fa fa-table"></i>
                                    Permissions
                                    </ng-template>
                            </p-listbox>
                        </div>
                    </div>

                </div>
            </div>
            <ng-template pTemplate="footer">
                <div class="p-dialog-buttonpane">
                    <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" (click)="btnCloseClick()" label="Close" ></button>
                </div>
            </ng-template>

        </p-dialog>
    </form>
    <form #addeditPGform="ngForm" *ngIf="displayAssetGroupForm">
        <p-dialog [(visible)]="displayAssetGroupForm" showEffect="fade" [modal]="true" [style]="{width: '400px'}" [resizable]="false">
            <ng-template pTemplate="header" >
              Asset Permission Group
            </ng-template>
            <div class="upload-dialog">
                <div class="smart-form">
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <p-listbox  [options]="assetGroupList" optionLabel="label" filter="filter" [listStyle]="{'max-height':'50vh'}" [style]="{'width':'360px'}">
                                <ng-template pTemplate="header" >
                                    <i class="fa fa-table"></i>
                                    Assets
                                    </ng-template>
                            </p-listbox>
                        </div>
                    </div>

                </div>
            </div>
            <ng-template pTemplate="footer">
                <div class="p-dialog-buttonpane">
                    <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" (click)="btnAssetGroupCloseClick()" label="Close" ></button>
                </div>
            </ng-template>

        </p-dialog>
    </form>
</div>
