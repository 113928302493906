import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute,  } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';


@Component({
    selector: 'Training',
    templateUrl: './training.component.html',
    //animations: [routerTransition()],
    //host: { '[@routerTransition]': '' }    

})
export class TrainingComponent implements OnInit {
    recordType: string;

    constructor(
        private _sharedService: SharedService,
        private _router: Router,
        private _route: ActivatedRoute

    ) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
    }


    ngOnInit() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }

        //let id = this._route.snapshot.params['id'];
        //this.recordType = this._route.snapshot.data[0].recordType ? this._route.snapshot.data[0].recordType : 'mxr';
       

    }



}