
export class BuilderSearchConfig {

    constructor() {

        this.SelectRows = "1";
        this.Execution = "Find and Move";
        this.AzureSearchKeywords = [{ TokenValue: null, Condition: "AND", PrefixValue: null, PostFixValue: null, Pattern: SearchPatternOption.Exact, TokenPattern: null },
            { TokenValue: null, Condition: "AND", PrefixValue: null, PostFixValue: null, Pattern: SearchPatternOption.Exact, TokenPattern: null },
            { TokenValue: null, Condition: "AND", PrefixValue: null, PostFixValue: null, Pattern: SearchPatternOption.Exact, TokenPattern: null },
        ];
    }

    public SearchId: string;//number;
    public ViewId: string;//number;
    public ViewName: string;
    public OrderNo: number;
    public SearchQuery: string;
    public SelectRows: string;
    public Execution: string;
    public SearchName: string;
    public AzureSearchFilter: AzureSearchFilter;
    public AzureSearchKeywords: AzureSearchKeyword[];
    public Status: boolean;

}

export class AzureSearchFilter {

    constructor() {

        this.AssetControlMode = "Fixed Value";
        this.FromDateMode = "Fixed Value";
        this.ToDateMode = "Fixed Value";
        this.DocumentTypeMode = "Fixed Value";
        this.DocumentNoMode = "Fixed Value";
        this.WoNoMode = "Fixed Value";
        this.TagsMode = "Fixed Value";
        this.SearchDatabaseID="0";

    }

    public AssetControlMode: string;
    public FromDateMode: string;
    public ToDateMode: string;
    public DocumentTypeMode: string;
    public WoNoMode: string;
    public DocumentNoMode: string;
    public TagsMode: string;
    public AssetASN: string;
    public Tags: string[];
    public WONo: string;
    public DocNo: string;
    public DocumentNo: string;
    public DocumentTypes: string[];
    public FromDate: string;
    public ToDate: string;
    public SearchDatabaseID: string;

}

export class AzureSearchKeyword {

    constructor() {

        this.Condition = "AND";
        this.Pattern = SearchPatternOption.Exact;
    }

    public Pattern: SearchPatternOption;
    public Condition: string;
    public PrefixValue: string;
    public TokenValue: string;
    public PostFixValue: string;
    public TokenPattern: string;

}

export enum SearchPatternOption {

    Exact = 1,

    Begin = 2,

    //Partial = 3
    End = 3,
    Partial = 4
}





export interface searchType {
    id: number;
    searchType: string;
}

export interface searchTags {
    id: number;
    tag: string;
}

export interface SelectItem {
    label: string;
    value: any;
}
