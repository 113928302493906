import { Component, OnChanges, Input, ElementRef, ViewChild, SimpleChange, Renderer2 } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { SelectItem } from 'primeng/api';
//import { IMyDrpOptions } from 'mydaterangepicker'
import { Subscription } from 'rxjs';
import { GridOptions, GridApi } from "ag-grid-community";
import { AzureBlobService } from '../../../shared/services/AzureBlob/azure-blob.service';
import { SharedService } from '../../../shared/services/shared.service';
import { TagService } from '../../../shared/services/tag/tag.service';
import { AzureSearchService } from '../../../shared/services/AzureSearch/azure-search.service'
import { DocumentTypeService } from '../../../admin/services/document-type/document-type.service';
import { azureSearchKeywords } from '../../../shared/services/AzureSearch/azure-search.keywords'
import { searchType, searchTags, AdvanceSearch } from '../../services/search/search-type';
import { BrowserModule, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IConfig } from '../../../shared/helpers/config';
import * as moment from 'moment/moment';
import { BlobMetaData } from '../../../shared/services/AzureBlob/blob-metadata';
import { TaskService } from '../../../admin/services/task/task.service';
import { BatchAutoSearchService } from '../../services/autoSearch/batch-autoSearch.service';
import { QualityStatusService } from '../../../admin/services/qualitystatus/qualitystatus.service';
import { ContainerService } from '../../services/container/container.service';
import { container } from '../../services/container/container';

var _dateFormat = "";
//#region Global Variables for Ag-Grid
var sharedService: SharedService;
var displayMode: string;
var _gridOptions: GridOptions;
var statusList: any[] = [];
//#endregion

@Component({
    selector: 'search',
    templateUrl: './search.component.html',
    providers: [DocumentTypeService, AzureSearchService, TagService, AzureBlobService, TaskService, BatchAutoSearchService, QualityStatusService, ContainerService]
})

export class SearchComponent implements OnChanges {

    @ViewChild('fltPanel') fltPanel: ElementRef;
    @ViewChild('refinerPanel') refinerPanel: ElementRef;
    @ViewChild('searchinput') searchInput: ElementRef;
    @Input() ViewSettingId: "";
    subscription: Subscription;
    dialogueSubscription: Subscription;
    //#region Global Variables
    recordType: string = "Search";
    selectedSearchKeywords: azureSearchKeywords;
    documentTypes: SelectItem[];
    selectedDocumentType: number;
    _searchTypes: searchType[];
    searchType: searchType;
    tags: SelectItem[];
    dateSeparator = "";
    dateFormat = "";
    dateFormatCalender = "";
    configuration: IConfig[] = [];
    public day: number;
    public month: number;
    public year: number;

    filteredtags: any[]
    searchTags: searchTags[];
    toggleSearchIcon: boolean = false;
    toggleRefinerIcon: boolean = false;
    isSearchCount: boolean;
    searchresultext: string;

    //filter binding variables
    searchQuery: string;
    formattedSearchkeyword: string;
    searchMode: string = "Exact";
    SelectMaxDocumentCount: string = "50";
    fltFromDate: string;
    fltToDate: string;

    fltSelectedDocType: string;
    fltDocumentNumber: string;
    fltWONumber: string;
    fltselectedTags: string[];
    public canEditMetaData: boolean = false;
    public canEditMetaDataReadonly: boolean = false;
    isAutoSearch: boolean = false;

    //facets variables
    facets: any[];
    searchResultCount: string;


    columnDefs: any[];
    public gridOptions: GridOptions;
    public api: GridApi;
    public docGridData: any[];
    public rowData: any[];
    keywordConditions: any[];

    datePipe = DatePipe;


    viewTaskQueries: any

    clpSearchQuery: string;

    _displayMode: string = 'search';
    toolTipSearchText: string = 'Special characters must be escaped to be used as part of the search text.You can escape them using phrase in quotation marks.</br>Special characters that need to be escaped include the following:';
    isToolTipDisabled: boolean = true;
    toolTipSearchTextValue: string = "";
    customClass: string = "search-tooltip";
    public referenceKeyLabel: string = "";
    public IsStatusColInSearchHide: boolean;
    public Is_Records_Search_Global: boolean;
    //whitelistExpression: string = '/[\+\-()&[\]?*~^|!{}:\\/"]/g';
    //#endregion

    //#region Init
    advanceSearch: Array<AdvanceSearch> = [];
    public ViewDateFormat: string;
    public height: string;
    public PlaceholderDocumentType = "Select Document Type";
    BNDContainerTypes: any[] = [];
    //constructor(public _sharedService: SharedService, private _router: Router, private _documentTypeService: DocumentTypeService, private _azureSearchService: AzureSearchService, private sanitizer: DomSanitizer, private _tagService: TagService, private renderer: Renderer, private _route: ActivatedRoute, private _taskService: TaskService, private _autoSearchService: BatchAutoSearchService, private _qualityStatusService: QualityStatusService, private _azureBlobService: AzureBlobService) {
    constructor(public _sharedService: SharedService, private _router: Router,
        private _documentTypeService: DocumentTypeService, private _azureSearchService: AzureSearchService,
        private sanitizer: DomSanitizer, private _tagService: TagService,
        private renderer: Renderer2, private _route: ActivatedRoute,
        private _taskService: TaskService, private _autoSearchService: BatchAutoSearchService,
        private _qualityStatusService: QualityStatusService, private _azureBlobService: AzureBlobService,
        private _containerService: ContainerService) {
        this.init();
        this.ViewDateFormat = this._sharedService.getLabelTextDateFormat();
    }
    ngOnInit() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.configuration = [];
            this.configuration = this._sharedService.getConfiguration();

            this.populateQualityStatus();
            this.referenceKeyLabel = this._sharedService.getReferenceKeyLabel();

            var records_Search_Global = this.configuration.filter(x => x.Key == "Records_Search_Global").map(({ Value }) => Value).toString().toLowerCase();
            this.Is_Records_Search_Global = (records_Search_Global == 'true') ? true : false;
            this.populateContainerTypes();
            this.setDisplayMode();
            if (this._route.snapshot.data[0] && this._route.snapshot.data[0].recordType) {
                this._sharedService.recordType = this._route.snapshot.data[0].recordType;
            }

            if (this._sharedService.selectedAsset && this._sharedService.selectedAsset.length > 1) {
                this._sharedService.resetAssetControl(true);
            }
            else {

                this._sharedService.resetAssetControl(false);
            }
            this._sharedService.setAssetControlVisible(true);
            this.setCalenderDateFormat();
            this._sharedService.selectedDocument = null; // Added to reset the selected document of other components
            this.BindGrid();
        }

        this._sharedService.recordType = this.recordType;
        this.subscription = this._sharedService._selectedAsn
        .subscribe({
            next: (selAsset:any) => {
                let viewSettingID = this.ViewSettingId; //Number(this.ViewSettingId);
                let taskID = this._sharedService.selectedTaskID;
                let selectedAsset = this._sharedService.selectedAsset;
        
                //US-8181 //let assetInfo: string[] = selAsset.split('|');// selectedAsset[0].data.split('|');
                let assetDataObj = JSON.parse(selAsset);
                let asn = assetDataObj.ASN;// assetInfo[1];
                this.loadAutoSearch(viewSettingID, taskID, asn);
            },
            error: (error) => {
            }
        });

        this.dialogueSubscription = this._sharedService._isDialogueOpen
        .subscribe({
            next: (flag:boolean) => {
                this.wideSearchDocPanal(flag);
            }
        });

        let screenWidth = window.innerWidth;
        if (screenWidth < 1025) {

            this.PlaceholderDocumentType = "Select Document...";

        }
    }

    addAdvanceSearch() {
        let url = this._router.url;
        if (url.endsWith('/search') == true) {
            let element = document.getElementById('searchId');
            element.style.height = '100vh'
        }
        else {
            let element = document.getElementById('searchId');
            element.style.height = '72vh'
        }
        let advanceSearch = new AdvanceSearch();
        advanceSearch.Condition = ' || ';
        advanceSearch.SearchValue = '';
        this.advanceSearch.push(advanceSearch);
        // let element=document.getElementById('abc');
        // let height = element.offsetHeight
        // if(height >= 116){
        //     element.style.maxHeight='23vh'
        //     element.style.overflowY='scroll';
        // }
        // else{
        //     element.style.overflow='hidden';
        //     element.style.maxHeight='auto'
        // }
    }


    deleteInstance(objKeyWord: any) {

        let index: number = this.advanceSearch.indexOf(objKeyWord);
        this.advanceSearch.splice(index, 1);
    }

    populateKeywordConditions() {
        this.keywordConditions = [
            { label: "OR", value: " || " },
            { label: "AND", value: " && " },
            { label: "NOT", value: " -" },
        ];
    }

    resetRearchQuery() {
        this.searchQuery = null;
        this.advanceSearch = [];
    }
    onModelChange(event: any, overl: any, target: any) {
        if (this.searchQuery && this.searchQuery != null) {
            let status: boolean = /[\+\-()&[\]?*~^|!{}:\\/"]/g.test(this.searchQuery);
            if (status) {
                overl.show(event, target);

            }
            else {
                overl.hide();
            }
        }
        else {
            overl.hide();
        }

    }

    init() {
        this.UpdateRoles();
        this.populateSearchTypeDropdown();
        this.populateKeywordConditions();
        this.populateDocTypeDropdown();
        this.populateTagsAutoComplete();
    }


    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        let log: string[] = [];
        for (let propName in changes) {
            let changedProp = changes[propName];
            let to = changedProp.currentValue;//JSON.stringify(changedProp.currentValue);

            if (propName == "ViewSettingId") {
                this._sharedService.selectedDocument = null;
                let viewSettingID = to;//Number(to);
                let taskID = this._sharedService.selectedTaskID;
                let selectedAsset = this._sharedService.selectedAsset;

                //US-8181 //let assetInfo: string[] = selectedAsset[0].data.split('|');
                let assetDataObj = JSON.parse(selectedAsset[0].data);
                let asn = assetDataObj.ASN;//assetInfo[1];
                this.loadAutoSearch(viewSettingID, taskID, asn);

            }
        }
    }


    ngOnDestroy() {
        //this._sharedService._selectedAsset.unsubscribe();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.dialogueSubscription) {
            this.dialogueSubscription.unsubscribe();
        }
        this._sharedService.resetAssetControl(false);
    }

    loadAutoSearch(viewSettingID: string, taskID: string, asn: string) {
        //this.isAutoSearch = viewSettingID > 0 && taskID > 0;
        this.isAutoSearch = (viewSettingID && taskID) ? true : false;
        this.wideSearchDocPanal(this.isAutoSearch);
        if (this.isAutoSearch) {
            this.BindGrid();
            this.clearPDFComponent();
            this.rowData = [];
            this.searchResultCount = null;
            //        this.SetSearchResultText = null;
            if (taskID) {
                this.populateAutoSearchRecords(viewSettingID, taskID, asn);
            }

        }
        else {
            this.BindGrid();
            this.clearPDFComponent();
            this.rowData = [];
            this.searchResultCount = null;
            //           this.SetSearchResultText = null;
        }
    }

    clearPDFComponent() {

        let tempData = [];
        tempData = [{ Url: null }];
        this._sharedService.onDocumentContainerSelection(tempData[0]);
    }

    populateAutoSearchRecords(viewSettingID: string, taskID: string, asn: string) {
        this._taskService.getTaskQueriesbyViewID(viewSettingID, taskID)
        .subscribe({
            next: (response) => {
                let viewTaskQueries = response;
                viewTaskQueries.Task.ASN = asn;
        
                this.executeTaskQueries(viewTaskQueries);
            },
            error: (error) => {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'an error occured, please try again.' });
            }
        });
    }


    executeTaskQueries(viewTaskQueries: any): void {
        if (viewTaskQueries) {
            let viewSettingID = viewTaskQueries.ViewSettingID;
            let task = viewTaskQueries.Task;
            let builderQueries: any = viewTaskQueries.AutoSearchQueries;
            this.rowData = [];

            this.searchResultCount = "0";
            this.searchresultext = "result(s) found";

            if (!task) {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Batch Task', detail: 'please select task' });
                return;
            }
            if (builderQueries && builderQueries.length <= 0) {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Auto search Query', detail: 'NO OCR Search query found for selected view.' });
            }

            if (task && builderQueries && builderQueries.length > 0) {
                this.runTaskQueries(builderQueries, task);
            }


        }


    }


    public runTaskQueries(builderQueries: any, task: any): void {

        this.searchResultCount = null;
        let searchGridRecords: any[] = [];
        for (var i = 0; i < builderQueries.length; i++) {

            let objquery: any = builderQueries[i];

            this._sharedService.showLoader("Loading search result...");
            let body: any = { Task: task, AutoSearchQuery: objquery };
            this._autoSearchService.getAzureAutoSearchData(body)
            .subscribe({
                next: (response) => {
                    let result = response;
                    if (result && result.length > 0) {
                        searchGridRecords.push.apply(searchGridRecords, result);
                        this.rowData = [];
                        this.rowData = searchGridRecords;
                        if (this.api) {
                            this.api.setRowData(this.rowData);
                            this.api.redrawRows({rowNodes: this.rowData});
                        }        
                        this.searchResultCount = searchGridRecords.length.toString();
                        this.searchresultext = "result(s) found";
                    }
                    this._sharedService.hideLoader();
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'an error occured, while executing auto search query.' });
                }
            });
        }
    }

    //executeTaskQueries(viewTaskQueries: any): void {

    //    if (viewTaskQueries) {
    //        let viewSettingID = viewTaskQueries.ViewSettingID;
    //        let lstTask = viewTaskQueries.Tasks;
    //        let builderQueries: any = viewTaskQueries.AutoSearchQueries;
    //        this.searchResultCount = null;
    //        let searchGridRecords: any[] = [];
    //        this.rowData = [];

    //        this.searchResultCount = "0";
    //        this.searchresultext = "results found";

    //        if (lstTask && lstTask.length <= 0) {
    //
    //            this._sharedService.showToast({ severity: 'error', summary: 'Batch Task', detail: 'No task available for executing auto search query. please check BatchAutoSearchSelectedSQL query' });
    //        }
    //        if (builderQueries && builderQueries.length <= 0) {
    //
    //            this._sharedService.showToast({ severity: 'error', summary: 'Auto search Query', detail: 'NO OCR Search found for selected view.' });
    //        }


    //        for (var i = 0; i < lstTask.length; i++) {

    //            let objtask: any = lstTask[i];
    //            if (builderQueries.length > 0) {
    //                this._sharedService.showLoader("Loading search result...");
    //                let body: any = { Task: objtask, AutoSearchQueries: builderQueries };
    //                this._autoSearchService.getAzureAutoSearchData(body).subscribe( response => {

    //                    let result = response;
    //                    if (result && result.length > 0) {
    //                        searchGridRecords.push.apply(searchGridRecords, result);
    //                        this.rowData = [];
    //                        this.rowData = searchGridRecords;
    //                        if (this.api) {
    //                            this.api.setRowData(this.rowData);
    //                            this.api.refreshRows(this.rowData);
    //                        }


    //                        this.searchResultCount = searchGridRecords.length.toString();
    //                        this.searchresultext = "results found";
    //                    }

    //                    this._sharedService.hideLoader();

    //                }, error => {

    //
    //                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'an error occured, while executing auto search query.' });
    //                    this._sharedService.hideLoader();

    //                });

    //                //setTimeout(() => {
    //                //    let wait: number = 0;
    //                //    wait++;
    //                //    console.log(wait);

    //                //},
    //                //    5000);

    //            }
    //        }


    //    }


    //}


    populateSearchTypeDropdown() {
        this._searchTypes = [
            { id: 1, searchType: "Exact" },
            { id: 4, searchType: "Partial" },
            { id: 2, searchType: "Begin" },
            { id: 3, searchType: "End" }

        ];
    }
    populateDocTypeDropdown() {
        //populate Doc Types
        this._documentTypeService.getDocumentTypes("")
        .subscribe({
            next: (response) => {
                this.documentTypes = [];
                this.documentTypes = response;
            }
        });

    }
    populateTagsAutoComplete() {

        this.tags = [];
        //this.tags = [
        //    { label: "Component Certificate", value: "Component Certificate" },
        //    { label: "Document", value: "Document" },
        //    { label: "Repair", value: "Repair" }
        //]
        this._tagService.getAllTags()
        .subscribe({
            next: (response) => {
                this.tags = response;
            }
        });
    }
    LoadGrid() {
        this.loadGridData();
    }

    BindGrid() {
        sharedService = this._sharedService;
        this.getColDef();
        this.gridOptions = <GridOptions>{
            rowSelection: 'multiple',
            // suppressContextMenu: true,
            //onSelectionChanged: this.handleSelectedDoc,
            onGridReady: () => {
                if (this.gridOptions.api != undefined) {
                    this.gridOptions.api.sizeColumnsToFit();
                    this.gridOptions.api.setColumnDefs(this.columnDefs);
                    this.api = this.gridOptions.api;
                }
            },
            onModelUpdated: (event: any) => {

                if (this.gridOptions.rowData != null && this.gridOptions.rowData != undefined && this.gridOptions.rowData.length > 0) {
                    if (this.api != undefined) {
                        this.api.sizeColumnsToFit();
                        this.api.getDisplayedRowAtIndex(0).setSelected(true);
                        //this._sharedService.onDocumentContainerSelection(this.gridOptions.rowData[0]);
                    }
                }
            },
            getContextMenuItems: this.getCustomContextMenuItems,
            context: {
                gridContext: this
            },
            defaultColDef: {
                resizable: true,
                sortable: true
            },
            navigateToNextCell: this.navigateToNextCell,
            onCellValueChanged: this.updateDocumentQualityStatus,
            singleClickEdit: true
        }

        _gridOptions = this.gridOptions;
    }
    navigateToNextCell(params) {

        var previousCell = params.previousCellDef;
        var suggestedNextCell = params.nextCellDef;

        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        switch (params.key) {
            case KEY_DOWN:
                previousCell = params.previousCellDef;
                _gridOptions.api.deselectAll()
                // set selected cell on current cell + 1
                _gridOptions.api.forEachNode((node) => {
                    if (previousCell.rowIndex + 1 === node.rowIndex) {
                        node.setSelected(true);
                    }
                });
                return suggestedNextCell;
            case KEY_UP:
                previousCell = params.previousCellDef;
                _gridOptions.api.deselectAll()
                // set selected cell on current cell - 1
                _gridOptions.api.forEachNode((node) => {
                    if (previousCell.rowIndex - 1 === node.rowIndex) {
                        node.setSelected(true);
                    }
                });
                return suggestedNextCell;
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw "this will never happen, navigation is always one of the 4 keys above";
        }
    }
    loadGridData() {

        this._azureSearchService.getAzureSearchData(this.selectedSearchKeywords)
        .subscribe({
            next: (response) => {
                this.rowData = [];
                this.facets = [];
                this._sharedService.selectedDocument = null;//R.A.
                this.rowData = response["ListAzureBlob"];
                this.facets = response["Facets"];
                let totalCount = response["TotalCount"];
                this.SetSearchResultText(totalCount);
                if (response.ListAzureBlob.length > 0) {
                    //this.api.getDisplayedRowAtIndex(0).setSelected(true);//R.A.
                    this.IsStatusColInSearchHide = (response.ListAzureBlob[0].Status == null ? true : false);
                }
                this.getColDef();
            }
        });

        let event = new MouseEvent('click', { bubbles: true });
        this.refinerPanel.nativeElement.dispatchEvent(event);
        this.refinerPanel.nativeElement.click();
        //this._sharedService.hideLoader();

    }

    populateQualityStatus() {
        let qualityStatusListValues = this._sharedService.getQualityStatusList();
        if (qualityStatusListValues == null || qualityStatusListValues.length == 0) {
            this._qualityStatusService.getActiveQualityStatusData(null)
            .subscribe({
                next: (response) => {
                    try {
        
                        //this.qualityStatusList = response;
                        statusList = response;
                    } catch (ex) {
                        //this.qualityStatusList = response;
                        statusList = response;
                        console.log(ex);
                    }
                }
            });
        } else {
            //this.qualityStatusList = qualityStatusListValues;
            statusList = qualityStatusListValues;
        }
    }

    populateContainerTypes() {

        this.BNDContainerTypes = [];
        //let ContainerTypes: any[] = [];
        let body: container = {
            Category: 'BND',
        };
        this._containerService.getContainerType(body)
        .subscribe({
            next: (response) => {
                let res = response;
                for (let i = 0; i < res.length; i++) {
                    this.BNDContainerTypes.push(res[i].Type);
                }
            },
            error: (error) => {
            }
        });
        //return ContainerTypes;
    }

    getColDef() {
        this.columnDefs = [];
        // if(this._router.url.indexOf('/record/documents') != -1)

        if (this.isAutoSearch) {
            this.columnDefs =
                [
                    { headerName: '', field: '', minWidth: 30, width: 30, checkboxSelection: true, filter: false, menuTabs: [] },
                    //{ headerName: '', field: 'ASN', width: 30, cellRenderer: this.editCellRenderer, filter:false },
                    { headerName: 'Order', field: 'Order', filter: 'text', minWidth: 35, width: 35, menuTabs: [], sort: 'asc' },
                    { headerName: 'Search Name', field: 'SearchName', filter: 'text', menuTabs: [] },
                    { headerName: 'Asset MSN', field: 'ASN', filter: 'text', menuTabs: [], cellRenderer: this.assetCellRenderer, minWidth: 183 }, /*, width: 100*/
                    { headerName: 'Document Type', field: 'DocType', filter: 'text', tooltipField: 'DocType', menuTabs: [], width: 140 }, /*width: 140,*/
                    {
                        headerName: 'Document Date', field: 'DocDate', filter: 'agDateColumnFilter', menuTabs: [], width: 100  /*width: 100,*/
                        , valueFormatter: function (params: any) {
                            if (params.value != "" && params.value != null) {
                                let dateString = params.value.substring(0, 10);
                                return moment(dateString).format(_dateFormat.toUpperCase());
                            }
                        }
                    },
                    {
                        headerName: this.referenceKeyLabel, field: 'ReferenceKey', filter: 'text', tooltipField: 'WONumber', menuTabs: []
                        , valueFormatter: function (params: any) {
                            if (params.value != null && params.value != '' && (params.value.indexOf('||') != -1 || params.value.indexOf('--') != -1)) {
                                return "";
                            }
                            else { return params.value; }
                        }
                    },/* width: 100,*/
                    { headerName: 'DocumentNumber', field: 'DocNo', filter: 'text', tooltipField: 'DocNo', menuTabs: [] }, /*width: 100,*/
                    //{ headerName: 'FileName', field: 'BlobName', filter: 'text', tooltipField: 'BlobName', menuTabs: [] }
                ]
        }
        else {
            if (this._router.url.indexOf('/record/documents') != -1) {
                this.columnDefs =
                    [
                        { headerName: '', field: '', minWidth: 30, width: 30, checkboxSelection: true, filter: false, menuTabs: [] },
                        //{ headerName: '', field: 'ASN', width: 30, cellRenderer: this.editCellRenderer, filter:false },
                        { headerName: 'Asset MSN', field: 'ASN', filter: 'text', menuTabs: [], width: 100 }, /*, width: 100*/
                        { headerName: 'Document Type', field: 'DocType', filter: 'text', tooltipField: 'DocType', menuTabs: [] }, /*width: 140,*/
                        {
                            headerName: 'Document Date', field: 'DocDate', filter: 'agDateColumnFilter', menuTabs: [], width: 100 /*width: 100,*/
                            , valueFormatter: function (params: any) {
                                if (params.value != "" && params.value != null) {
                                    let dateString = params.value.substring(0, 10);
                                    return moment(dateString).format(_dateFormat.toUpperCase());
                                }
                            }
                        },
                        {
                            headerName: this.referenceKeyLabel, field: 'ReferenceKey', filter: 'text', tooltipField: 'WONumber', menuTabs: []
                            , valueFormatter: function (params: any) {
                                if (params.value != null && params.value != '' && (params.value.indexOf('||') != -1 || params.value.indexOf('--') != -1)) {
                                    return "";
                                }
                                else { return params.value; }
                            }
                        },/* width: 100,*/
                        { headerName: 'DocumentNumber', field: 'DocNo', filter: 'text', tooltipField: 'DocNo', menuTabs: [] }, /*width: 100,*/
                        // { headerName: 'FileName', field: 'BlobName', filter: 'text', tooltipField: 'BlobName', menuTabs: [] },
                    ]
                let colFileName: any = { headerName: 'FileName', field: 'BlobName', filter: 'text', tooltipField: 'BlobName', menuTabs: [], width: 350 };
                if (this.IsStatusColInSearchHide == false) {
                    // let colStatus = { headerName: 'Status', field: 'Status', filter: 'text', tooltipField: 'Status', menuTabs: [] };
                    // this.columnDefs.push(colStatus);



                    let colStatus: any = {
                        headerName: 'Status', field: 'Status', filter: 'set', menuTabs: ['filterMenuTab'], cellRenderer: this.statusCellRenderer,
                        editable: this.canEditMetaData,
                        minWidth: 55
                    };

                    if (this.canEditMetaData) {
                        colStatus.cellEditor = 'agRichSelectCellEditor';
                        colStatus.cellEditorParams = {
                            values: statusList.map(sts => sts.Name.trim()),
                            cellRenderer: this.statusCellRendererEdit
                        }
                    }


                    this.columnDefs.push(colStatus);
                }

                this.columnDefs.push(colFileName);
            } else {
                this.columnDefs =
                    [
                        { headerName: '', field: '', minWidth: 30, width: 30, checkboxSelection: true, filter: false, menuTabs: [] },
                        //{ headerName: '', field: 'ASN', width: 30, cellRenderer: this.editCellRenderer, filter:false },
                        { headerName: 'Asset MSN', field: 'ASN', filter: 'text', menuTabs: [], minWidth: 50, width: 50, }, /*, width: 100*/
                        { headerName: 'Document Type', field: 'DocType', filter: 'text', tooltipField: 'DocType', menuTabs: [], minWidth: 90, width: 90, }, /*width: 140,*/
                        {
                            headerName: 'Document Date', field: 'DocDate', filter: 'agDateColumnFilter', menuTabs: [], minWidth: 90, width: 90  /*width: 100,*/
                            , valueFormatter: function (params: any) {
                                if (params.value != "" && params.value != null) {
                                    let dateString = params.value.substring(0, 10);
                                    return moment(dateString).format(_dateFormat.toUpperCase());
                                }
                            }
                        },
                        {
                            headerName: this.referenceKeyLabel, field: 'ReferenceKey', filter: 'text', tooltipField: 'WONumber', menuTabs: [], minWidth: 90, width: 90
                            , valueFormatter: function (params: any) {
                                if (params.value != null && params.value != '' && (params.value.indexOf('||') != -1 || params.value.indexOf('--') != -1)) {
                                    return "";
                                }
                                else { return params.value; }
                            }
                        },/* width: 100,*/
                        { headerName: 'DocumentNumber', field: 'DocNo', filter: 'text', tooltipField: 'DocNo', menuTabs: [], minWidth: 90, width: 90 }, /*width: 100,*/
                    ]

                if (this.IsStatusColInSearchHide == false) {
                    // let colStatus = { headerName: 'Status', field: 'Status', filter: 'text', tooltipField: 'Status', menuTabs: [] };
                    // this.columnDefs.push(colStatus);



                    let colStatus: any = {
                        headerName: 'Status', field: 'Status', filter: 'set', menuTabs: ['filterMenuTab'], cellRenderer: this.statusCellRenderer,
                        editable: this.canEditMetaData,
                        minWidth: 90,
                        width: 90
                    };

                    if (this.canEditMetaData) {
                        colStatus.cellEditor = 'agRichSelectCellEditor';
                        colStatus.cellEditorParams = {
                            values: statusList.map(sts => sts.Name.trim()),
                            cellRenderer: this.statusCellRendererEdit
                        }
                    }


                    this.columnDefs.push(colStatus);
                }
                var fileName: any =
                {
                    headerName: 'FileName', field: 'BlobName', filter: 'text',
                    tooltipField: 'BlobName', menuTabs: [], minWidth: 500, width: 500
                }
                this.columnDefs.push(fileName);
            }
        }

    }
    statusCellRenderer(params: any) {
        let html: string = '';
        var eDiv = document.createElement('div');
        if (params.value) {
            try {
                let jobStatusName: string = params.value.trim();
                let currentStatus: any = statusList.filter(x => x.QualityStatusID == params.value)[0];
                if (currentStatus && currentStatus.Name) {
                    eDiv.style.background = currentStatus.CSSClass;
                    html = html + currentStatus.Name;
                } else {
                    let currentStatus: any = statusList.filter(x => x.Name == params.value)[0];
                    if (currentStatus && currentStatus.Name) {
                        eDiv.style.background = currentStatus.CSSClass;
                        html = html + currentStatus.Name;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            return params.value;
        }


        eDiv.style.height = '100%';
        eDiv.style.width = '100%';
        eDiv.style.color = 'white';
        eDiv.style.textAlign = 'left';
        eDiv.style.paddingLeft = '5px';
        eDiv.innerHTML = html;
        return eDiv;
    }
    statusCellRendererEdit(params: any) {
        let html: string = '';
        var eDiv = document.createElement('div');
        if (params.value) {
            try {
                let jobStatusName: string = params.value.trim();
                let currentStatus: any = statusList.filter(x => x.QualityStatusID == params.value)[0];
                if (currentStatus && currentStatus.Name) {
                    eDiv.style.background = currentStatus.CSSClass;
                    html = html + currentStatus.Name;
                } else {
                    let currentStatus: any = statusList.filter(x => x.Name == params.value)[0];
                    if (currentStatus && currentStatus.Name) {
                        eDiv.style.background = currentStatus.CSSClass;
                        html = html + currentStatus.Name;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            return params.value;
        }


        eDiv.style.height = '100%';
        eDiv.style.width = '100%';
        eDiv.style.color = 'white';
        eDiv.style.textAlign = 'left';
        eDiv.style.paddingTop = '6px';
        eDiv.style.paddingLeft = '5px';
        eDiv.innerHTML = html;
        return eDiv;
    }

    updateDocumentQualityStatus(event: any) {

        if (event.colDef.field == 'Status') {
            if (event.oldValue != event.newValue) {
                try {
                    let qualityStatusId = statusList.find(x => x.Name == event.data.Status).QualityStatusID;
                    //let asset = event.context.gridContext._sharedService.selectedAsset[0].data.split('|');
                    let body: any = {
                        ContainerName: event.data.Container,//asset[0],
                        ASN: event.data.ASN, //event.context.gridContext._sharedService.selectedContainer.ASN,
                        Type: event.data.ContainerType,//event.context.gridContext._sharedService.selectedContainer.Type,
                        ID: event.data.ContainerID,//event.context.gridContext._sharedService.selectedContainer.ID,
                        Status: qualityStatusId,
                        Url: event.context.gridContext.extractBlobReferenceFromURL(event.data)
                    };
                    event.context.gridContext._azureBlobService.updateQualityStatus(body)
                    .subscribe({
                        next: (response) => {
                            event.context.gridContext._sharedService.clearToast();
                            event.context.gridContext._sharedService.showToast({ severity: 'success', summary: 'MetaData updated', detail: 'Document metadata has been updated successfully.' });
                        },
                        error: (error) => {
                            event.context.gridContext._sharedService.clearToast();
                            event.context.gridContext._sharedService.showToast({ severity: 'error', summary: 'MetaData not updated', detail: 'Unable to update document metadata' });
                        }
                    });

                } catch (error) {
                    console.log(error);
                }
            }
        }


    }
    docGridRowDoubleClicked(event: any) {
        if (this._displayMode && this._displayMode != 'status' && this._displayMode != 'repair') {

            this._sharedService.updateEditMetadata(null);
        }

    }
    //#region custom cell render
    assetCellRenderer(params: any) {
        let html: string = '';

        if (params.value) {
            if (params.value == 'No document(s) found' || params.value == 'Required parameter(s) missing') {
                html = html + '<font color="red" title="' + params.value + '" >' + params.value + '</font>';
                var eDiv = document.createElement('div');
                eDiv.innerHTML = html;
                return eDiv;
            }
            else {
                return params.value;
            }
        }

    }
    //#endregion
    editCellRenderer(params: any) {
        var eDiv = document.createElement('div');
        eDiv.innerHTML = '<a class="btn btn-xs btn-default" data-val ="' + params.value + '"> <i class="fa fa-pencil" > </i></a>';
        return eDiv;
    }

    facetDocType: string = null;
    facetStatus: string = null;
    PrepareFilterObject(serchType: string, facet: any) {
        let selectedAssets = this._sharedService.selectedAsset;
        let blobContainers = "blobindex";
        let facetASN = [];
        let searchDatabase = "0";
        if (selectedAssets.length > 0) {
            //let firstSelectedAsset = selectedAssets[0].data.split('|');
            //searchDatabase = firstSelectedAsset[firstSelectedAsset.length - 1];

            //US-8181 //let firstSelectedAsset = selectedAssets[0].data.split('|');
            let assetDataObj = JSON.parse(selectedAssets[0].data);
            searchDatabase = assetDataObj.SearchDatabaseID;
        }
        this.searchModeChange();
        switch (serchType) {
            case "filter":

                for (let selectedAsset of selectedAssets) {

                    // let blobContainer = selectedAsset.data.split('|');
                    // let selectedAssetDataObj = JSON.parse(selectedAsset.data);
                    // if (selectedAsset.parent && selectedAsset.selectable != false) {  // Ignore Grand Parent and non-selectable Aircraft
                    //     if (blobContainer.length = 2) {
                    //         facetASN.push(blobContainer[1]);
                    //         //blobContainers.push(blobContainer[2]);
                    //     }
                    // }

                    let selectedAssetDataObj = JSON.parse(selectedAsset.data);
                    if (selectedAsset.parent && selectedAsset.selectable != false) {  // Ignore Grand Parent and non-selectable Aircraft
                        //if (blobContainer.length = 2) {
                        facetASN.push(selectedAssetDataObj.ASN);
                        //}
                    }
                }

                this.toggleSearchIcon = true;

                this.selectedSearchKeywords = {
                    ContainerIndex: blobContainers,
                    SearchQuery: this.formattedSearchkeyword,
                    FromDate: this.convertToISOFormat(this.fltFromDate),
                    ToDate: this.convertToISOFormat(this.isSameDate ? this.fltFromDate : this.fltToDate),
                    DocNo: this.fltDocumentNumber,
                    WONo: this.fltWONumber,
                    DocumentType: this.fltSelectedDocType,
                    TagsFacets: this.fltselectedTags,
                    Top: this.SelectMaxDocumentCount,
                    AssetMSNFacets: facetASN,
                    SearchTerm: this.searchMode,
                    SearchDatabaseID: searchDatabase,
                    ContainerType: null, //(this._router.url.indexOf("transition/search") != -1) ? "BND" : "NonBND",
                    Module: null
                };
                if (this._router.url.indexOf("record/search") != -1) {
                    if (this.Is_Records_Search_Global) {
                        this.selectedSearchKeywords.ContainerType = null;
                        this.selectedSearchKeywords.Module = null;
                    }
                    else {
                        this.selectedSearchKeywords.ContainerType = this.BNDContainerTypes.toString() + ",NonBND";
                        this.selectedSearchKeywords.Module = "Records";
                    }
                }
                else if (this._router.url.indexOf("transition/search") != -1) {
                    this.selectedSearchKeywords.ContainerType = this.BNDContainerTypes.toString();
                    this.selectedSearchKeywords.Module = "Transition";
                }
                break;
            case "refiner":
                this.toggleRefinerIcon = true;
                if (facet.attributes['data-type'].value == "DocType") {
                    this.facetDocType = facet.attributes['data-refiner'].value;
                    for (let a = 0; a < this.facets.length; a++) {
                        let objArray = this.facets[a];
                        if (objArray.Key == "ASN") {
                            let objValueArray = objArray.Value;
                            for (let j = 0; j < objValueArray.length; j++) {
                                facetASN.push(objValueArray[j].value)
                            }
                        }
                    }
                }
                else if (facet.attributes['data-type'].value == "ASN") {
                    facetASN.push(facet.attributes['data-refiner'].value);
                }
                else if (facet.attributes['data-type'].value == "Status") {
                    this.facetStatus = facet.attributes['data-from'].value;
                    for (let a = 0; a < this.facets.length; a++) {
                        let objArray = this.facets[a];
                        if (objArray.Key == "ASN") {
                            let objValueArray = objArray.Value;
                            for (let j = 0; j < objValueArray.length; j++) {
                                facetASN.push(objValueArray[j].value)
                            }
                        }
                    }
                }
                this.searchModeChange();

                this.selectedSearchKeywords = {
                    ContainerIndex: blobContainers,
                    SearchQuery: this.formattedSearchkeyword,
                    DocumentType: this.facetDocType,
                    Top: this.SelectMaxDocumentCount,
                    AssetMSNFacets: facetASN,
                    Status: this.facetStatus,
                    SearchTerm: this.searchMode,
                    FromDate: this.convertToISOFormat(this.fltFromDate),
                    ToDate: this.convertToISOFormat(this.isSameDate ? this.fltFromDate : this.fltToDate),
                    DocNo: this.fltDocumentNumber,
                    WONo: this.fltWONumber,
                    SearchDatabaseID: searchDatabase,
                    ContainerType: null,//(this._router.url.indexOf("transition/search") != -1) ? "BND" : "NonBND"
                    Module: null
                };
                if (this._router.url.indexOf("record/search") != -1) {
                    if (this.Is_Records_Search_Global) {
                        this.selectedSearchKeywords.ContainerType = null;
                        this.selectedSearchKeywords.Module = null;
                    }
                    else {
                        this.selectedSearchKeywords.ContainerType = this.BNDContainerTypes.toString() + ",NonBND";
                        this.selectedSearchKeywords.Module = "Records";
                    }
                }
                else if (this._router.url.indexOf("transition/search") != -1) {
                    this.selectedSearchKeywords.ContainerType = this.BNDContainerTypes.toString();
                    this.selectedSearchKeywords.Module = "Transition";
                }
                break;
        }
        // this.isSameDate = false;

    }

    convertToISOFormat(date: any) {
        if (date) {
            return moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        } else {
            return null;
        }
    }



    SetSearchResultText(totalCount: any) {

        let totalcount = parseInt(totalCount);
        this.searchResultCount = totalcount.toString();
        if (totalcount > 0) {
            if (parseInt(this.SelectMaxDocumentCount) > totalcount) {
                this.isSearchCount = false;
                this.searchresultext = "result(s) found";
            }
            else {
                this.isSearchCount = true;
                this.searchresultext = "result(s) found, showing first";
            }
        }
        else {
            this.searchresultext = "result(s) found";
            this.isSearchCount = false;
        }
    }
    dateComparater(cellValue: any) {
        if (cellValue != null) {
            var dateParts = cellValue.split(this.dateSeparator);
            var day = Number(dateParts[this.day].substring(0, 2));
            var month = Number(dateParts[this.month]) - 1;
            var year = Number(dateParts[this.year]);
            var cellDate = new Date(year, month, day);
            return cellDate;
        }
        else return null;
    }
    //#endregion


    //#region events
    handleDropdownClick(event: any) {
        //this._sharedService.showLoader();

        this.filteredtags = [];
        this.filteredtags = this.tags;

    }
    handleRefinerClick(item: any) {

        //this._sharedService.showLoader();

        let seletedfacet = item.target;
        this.PrepareFilterObject("refiner", seletedfacet);
        this.LoadGrid();

        if (this.searchQuery) {
            this._sharedService.updateSearchText(this.searchQuery);
        }

    }
    handleSearchButtonClick() {
        if (this._sharedService.selectedAsset == undefined || this._sharedService.selectedAsset.length <= 0) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'no asset selected', detail: 'please select an asset.' });
            return;
        }

        else if ((this.searchQuery == undefined || this.searchQuery == "") && this.advanceSearch.length > 0) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Warning', detail: 'Search string in missing' });
        }
        else {

            var searchValue = this.searchQuery;
            if (!this._sharedService.isAuthUser()) {
                this._router.navigate(['login']);
                this._sharedService.SessionExpiredMessage();
            }

            this.UpdateRoles();

            // if (this._sharedService.selectedAsset == undefined || this._sharedService.selectedAsset.length <= 0) {
            //     this._sharedService.clearToast();
            //     this._sharedService.showToast({ severity: 'warn', summary: 'no asset selected', detail: 'please select an asset.' });
            //     return;
            // }


            this.toggleRefinerIcon = false;
            this.clearPDFComponent();
            for (let i = 0; i < this.advanceSearch.length; i++) {
                if (this.advanceSearch[i].SearchValue != "") {
                    // if(this.advanceSearch[i].Condition == '*')
                    // {
                    //     if(this.searchQuery==undefined || this.searchQuery == "")
                    //     {
                    //         this.searchQuery=this.advanceSearch[i].SearchValue + "*";
                    //     }
                    //     else{
                    //         this.searchQuery=this.searchQuery + " || "+this.advanceSearch[i].SearchValue + "*";
                    //     }
                    // }
                    // else if(this.advanceSearch[i].Condition == '"'){
                    //     if(this.searchQuery==undefined || this.searchQuery == "")
                    //     {
                    //     this.searchQuery = this.advanceSearch[i].SearchValue +'"';
                    //     }
                    //     else{
                    //         this.searchQuery=this.searchQuery + " "+'"'+this.advanceSearch[i].SearchValue +'"';
                    //     }
                    // }
                    // else if(this.advanceSearch[i].Condition == '('){
                    //     if(this.searchQuery==undefined || this.searchQuery == "")
                    //     {
                    //     this.searchQuery = this.searchQuery + ' ('+this.advanceSearch[i].SearchValue +')';
                    //     }
                    //     else{
                    //         this.searchQuery = this.searchQuery + '('+this.advanceSearch[i].SearchValue +')';
                    //     }
                    // }
                    // if (this.searchMode.toString() == "Exact") {
                    //     if (this.searchQuery == undefined || this.searchQuery == "") {
                    //         this.searchQuery = "" + this.advanceSearch[i].Condition
                    //         this.searchQuery = this.searchQuery + this.advanceSearch[i].SearchValue
                    //     }
                    // }
                    // else {

                    if (this.searchMode.toString() == "Partial") {

                        if (i == 0) {
                            this.searchQuery = this.searchQuery + ".*/" + this.advanceSearch[i].Condition + "/.*"
                        }
                        else {
                            this.searchQuery = this.searchQuery + this.advanceSearch[i].Condition + "/.*"

                        }
                        if (i + 1 == this.advanceSearch.length) {
                            this.searchQuery = this.searchQuery + this.advanceSearch[i].SearchValue
                        }
                        else {
                            this.searchQuery = this.searchQuery + this.advanceSearch[i].SearchValue + ".*/"
                        }
                    }
                    else if (this.searchMode.toString() == "Begin") {

                        if (i == 0) {
                            this.searchQuery = this.searchQuery + "*" + this.advanceSearch[i].Condition

                        }
                        else {
                            this.searchQuery = this.searchQuery + this.advanceSearch[i].Condition

                        }
                        if (i + 1 == this.advanceSearch.length) {
                            this.searchQuery = this.searchQuery + this.advanceSearch[i].SearchValue
                        }
                        else {
                            this.searchQuery = this.searchQuery + this.advanceSearch[i].SearchValue + "*"
                        }
                    }
                    else if (this.searchMode.toString() == "End") {
                        if (i == 0) {
                            this.searchQuery = this.searchQuery + "/" + this.advanceSearch[i].Condition + "/.*"

                        }
                        else {
                            this.searchQuery = this.searchQuery + this.advanceSearch[i].Condition + "/.*"

                        }
                        if (i + 1 == this.advanceSearch.length) {
                            this.searchQuery = this.searchQuery + this.advanceSearch[i].SearchValue
                        }
                        else {
                            this.searchQuery = this.searchQuery + this.advanceSearch[i].SearchValue + "/"
                        }
                    }
                    else {
                        this.searchQuery = this.searchQuery + this.advanceSearch[i].Condition
                        this.searchQuery = this.searchQuery + this.advanceSearch[i].SearchValue
                    }


                }
            }

            // if (this.searchQuery) {
            this._sharedService.updateSearchText(this.searchQuery);
            // }
            //this._sharedService.showLoader();
            this.PrepareFilterObject("filter", "");
            this.LoadGrid();
            this.searchQuery = searchValue;
        }
    }


    handleSelectedDoc() {
        if (this.gridOptions.api == undefined) {
            this.gridOptions.api = this.api;
        }

        var rowCount = this.gridOptions.api.getSelectedNodes().length;
        this._sharedService.selectedDocumentforMetaData = this.gridOptions.api.getSelectedRows();
        if (rowCount > 0) {
            var lastRowData = this.gridOptions.api.getSelectedNodes()[rowCount - 1].data;

            if (lastRowData != this._sharedService.selectedDocument) {

                sharedService.onDocumentContainerSelection(lastRowData);
                sharedService.selectedDocCount = rowCount;
                if (lastRowData.hasOwnProperty('SearchQuery')) {
                    this.clpSearchQuery = lastRowData.SearchQuery;
                    let regex = "content:\\(([\\.\\w\\d\\-\\W]+)\\)";

                    // returns the values in the input
                    // inside an ARRAY *if* matched
                    let match = this.clpSearchQuery.match(regex);
                    if (match && match.length > 1) {
                        let KeyWord = match[1].toString();
                        this._sharedService.updateSearchText(KeyWord);
                    }

                }
                if (displayMode == 'status' || displayMode == "repair") {
                    sharedService.docToAttach = this.gridOptions.api.getSelectedNodes().map(node => {
                        let doc: BlobMetaData = node.data;
                        doc.taskId = [sharedService.selectedTaskID];
                        return doc;
                    });
                }
            }
        }
    }

    isSameDate: boolean;
    handleFCalendarSelect(event: any) {
        //if (this.fltToDate == null && this.fltToDate == undefined) {
        let date = new Date(Date.parse(event));
        var formatted = moment(date).format(this.dateFormat.toUpperCase());
        //let formattedDate = this.getPlainDate(date);
        //let datePipe = new this.datePipe("en-US");
        //let formattedDate = datePipe.transform(date, this.dateFormat);
        this.fltToDate = formatted.toString();// formatted;
        this.isSameDate = true;
        //  }
    }


    handleTCalendarSelect(event: any) {
        this.isSameDate = false;
    }
    ClearFilters() {
        this.searchQuery = null;
        this.fltFromDate = null;
        this.fltToDate = null;
        this.fltDocumentNumber = null;
        this.fltWONumber = null;
        this.fltSelectedDocType = null;
        this.fltselectedTags = [];
        this.facetDocType = null;
        this.toggleSearchIcon = !this.toggleSearchIcon;
        this.fltPanel.nativeElement.click();
        return false;

    }
    searchModeChange() {
        this.formattedSearchkeyword = null;
        this.formattedSearchkeyword = this.searchQuery;
        // if (this.searchQuery != undefined && this.searchMode == "Begin") {
        //     this.formattedSearchkeyword += "*";
        // }
        // else if (this.searchQuery != undefined && this.searchQuery != null && this.searchMode == "End") {
        //     this.formattedSearchkeyword = "/.*" + this.formattedSearchkeyword + "/";
        // }
        // else if (this.searchQuery != undefined && this.searchQuery != null && this.searchMode == "Partial") {
        //     this.formattedSearchkeyword = "/.*" + this.formattedSearchkeyword + ".*/";
        // }

    }
    //#endregion


    //// #region date Format Settings

    setCalenderDateFormat() {
        // this.configuration = [];
        // this.configuration = this._sharedService.getConfiguration();
        this.dateFormat = this.configuration.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
        this.dateSeparator = this.configuration.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
        this.day = Number(this.configuration.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
        this.month = Number(this.configuration.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
        this.year = Number(this.configuration.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
        let dateParts: string[] = this.dateFormat.split(this.dateSeparator);
        _dateFormat = this.dateFormat;

        if (this.year == 0) {
            this.dateFormatCalender = (dateParts[this.year].substr(0, 2) + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.day]).toLowerCase();
        }
        else if (this.year == 2) {
            if (this.month == 1) {
                this.dateFormatCalender = (dateParts[this.day] + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
            }
            else if (this.month == 0) {
                this.dateFormatCalender = (dateParts[this.month] + this.dateSeparator + dateParts[this.day] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
            }

        }
        else {
            this.dateFormatCalender = "yy-mm-dd"
        }

    }


    getPlainDate(date: Date): string {
        if (date != null) {
            var formatted = moment(date).format(this.dateFormat.toUpperCase());
            if (this.dateSeparator == "-") {
                let newStr = formatted.toString().replace(/-/g, "");
                return newStr;
            }
            else if (this.dateSeparator == "/") {
                let newStr = formatted.toString().replace(/\//g, "");
                return newStr;
            }
        }
        else {
            return "";

        }
    }

    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }
        if (this._router.url.indexOf("record/search") != -1) {
            this.canEditMetaData = this._sharedService.UserRole.Records_OCRSearch_Edit_Metadata;
            this.canEditMetaDataReadonly = this._sharedService.UserRole.Records_OCRSearch_Readonly;
        }
        else if (this._router.url.indexOf("transition/search") != -1) {
            this.canEditMetaDataReadonly = this._sharedService.UserRole.Transition_OCRSearch_Readonly;
            this.canEditMetaData = this._sharedService.UserRole.Transition_OCRSearch_Edit_Metadata;
        }
    }

    //#region Wide / Full Screen
    wideSearchDocPanalTitle="Expand half";
    wideSearchDocPanal(autoSearch: boolean) {
        let filterPanel = document.getElementById('search-col1');
        let docPanel = document.getElementById('search-col2');
        let pdfPanel = document.getElementById('col3');
        let btnFullScreen = document.getElementById('btnSearchFullScreen');

        if (docPanel) {
            if (docPanel.classList.contains('col-sm-4') && autoSearch == true) {
                //Remove old Classes
                docPanel.classList.remove('col-sm-4', 'col-md-4', 'col-lg-4');
                //Add new Classes
                docPanel.classList.add('col-sm-6', 'col-md-6', 'col-lg-6');
                filterPanel.style.display = 'none';
                //Update Doc Panel if it's wide
                if (pdfPanel.classList.contains('col-sm-8')) {
                    pdfPanel.classList.remove('col-sm-8', 'col-md-8', 'col-lg-8');
                    pdfPanel.classList.add('col-sm-6', 'col-md-6', 'col-lg-6');
                }

                //Disable Full Screen Button
                if (btnFullScreen) {
                    btnFullScreen.classList.add('disable-anchor');
                }
                this.wideSearchDocPanalTitle = "Back to normal view";
            } else {
                //Remove old Classes
                docPanel.classList.remove('col-sm-6', 'col-md-6', 'col-lg-6');
                //Add new Classes
                docPanel.classList.add('col-sm-4', 'col-md-4', 'col-lg-4');
                filterPanel.style.display = 'initial';
                if (btnFullScreen) {
                    btnFullScreen.classList.remove('disable-anchor');
                }
                this.wideSearchDocPanalTitle = "Expand half";
            }
            // // // // else if (docPanel.classList.contains('col-sm-4') == false && autoSearch == true) {
            // // // // //    return false;
            // // // // //}

            // // // // //else {
            // // // //     //Remove old Classes
            // // // //     docPanel.classList.remove('col-sm-6', 'col-md-6', 'col-lg-6');
            // // // //     //Add new Classes
            // // // //     docPanel.classList.add('col-sm-4', 'col-md-4', 'col-lg-4');
            // // // //     filterPanel.style.display = 'block';

            // // // //     //Enable Full Screen Button
            // // // //     if (btnFullScreen) {
            // // // //         btnFullScreen.classList.remove('disable-anchor');
            // // // //     }
            // // // // }
        }
    }
    fullScreenSearchDocPanalTitle = "Expand full";
    fullScreenSearchDocPanal() {
        let filterPanel = document.getElementById('search-col1');
        let docPanel = document.getElementById('search-col2');
        let pdfPanel = document.getElementById('col3');
        let btnWideScreen = document.getElementById('btnSearchWideScreen');

        if (docPanel.classList.contains('col-sm-4')) {
            //Remove old Classes
            docPanel.classList.remove('col-sm-4', 'col-md-4', 'col-lg-4');
            //Add new Classes
            docPanel.classList.add('col-sm-12', 'col-md-12', 'col-lg-12');
            filterPanel.style.display = 'none';
            pdfPanel.style.display = 'none';
            this.fullScreenSearchDocPanalTitle="Back to normal view";
            //Disable Wide Screen Button
            if (btnWideScreen) {
                btnWideScreen.classList.add('disable-anchor');
            }

        } else {
            //Remove old Classes
            docPanel.classList.remove('col-sm-12', 'col-md-12', 'col-lg-12');
            //Add new Classes
            docPanel.classList.add('col-sm-4', 'col-md-4', 'col-lg-4');
            filterPanel.style.display = 'block';
            pdfPanel.style.display = 'block';
            this.fullScreenSearchDocPanalTitle="Expand full";
            //Enable Wide Screen Button
            if (btnWideScreen) {
                btnWideScreen.classList.remove('disable-anchor');
            }

        }
    }

    setDisplayMode() {
        let url = this._router.url;
        if (url.indexOf("/search/") > -1) {
            displayMode = "search";
            this._displayMode = "search";
        } else if (url.indexOf("/status/") > -1) {
            displayMode = "status";
            this._displayMode = "status";
        } else if (url.indexOf("/repair/") > -1) {
            displayMode = "repair";
            this._displayMode = "repair";
        }
    }

    //#endregion
    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];
        let childResultDownload: any[] = [];

        if ((params.context.gridContext.canEditMetaData || params.context.gridContext.canEditMetaDataReadonly) && params.context.gridContext.rowData && params.context.gridContext._displayMode != 'status' && params.context.gridContext._displayMode != 'repair') {
            result.push({
                name: "Edit Metadata",
                action: function () {
                    if (params.node) {
                        params.context.gridContext.gridOptions.api.deselectAll();
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext.handleSelectedDoc();
                        params.context.gridContext._sharedService.updateEditMetadata(null);
                    }

                },
                icon: params.context.gridContext._sharedService.editIconHTMLasString,
            });
        }

        return result;


    }
    //#endregion

    extractBlobReferenceFromURL(blob): string {
        let newString: string[] = [];

        newString = decodeURIComponent(blob.Url).split('?')[0].split('/');

        return newString[newString.length - 1];
    }

    onShowOfDateTo(element){
        element.contentViewChild.nativeElement.style.display='block'
    }
    onShowOfDateFrom(element){
        element.contentViewChild.nativeElement.style.display='block'
    }
}

