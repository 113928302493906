<add-comm *ngIf="isAddVisible" [browseDocumentParams]="browseDocumentObj" [recordType]="recordType" [isDirectModal]="true"></add-comm>
<p-confirmDialog *ngIf="isDisplayBulkDialog" class="jarviswidget" header="Duplicate Files"
    [style]="{width: '435px'}"></p-confirmDialog>
<article class="col-sm-3 col-md-3 col-lg-3 sortable-grid p-sortable no-padding standardpadding" id="col2">
    <!-- Widget ID (each widget will need unique ID)-->

    <p-overlayPanel #opdoc id="searchoverlydoc" styleClass="searchoverly-custom-doc">
        <div>Special characters must be escaped to be used as part of the search text.<br />You can escape them using
            phrase in quotation marks. Special characters that need to be escaped include the following:<br /> + - && ||
            ! ( ) {{ '{' }} {{ '}' }} [ ] ^ " ~ * ? : \ / <br />For example: 1/2345 should be written as "1/2345"</div>
    </p-overlayPanel>
    <div class="jarviswidget" id="wid-id-2" data-widget-sortable="false" data-widget-colorbutton="false"
        data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false"
        data-widget-togglebutton="false" data-widget-fullscreenbutton="false" role="widget"
        style="display: flex;flex-direction: column;">

        <header role="heading" id="docPanelHeader"
            style="height: auto !important;background: linear-gradient(to bottom, #fafafa 35px, transparent 35px);">
            <div class="jarviswidget-ctrls" role="menu" *ngIf="_displayMode!='status' && !inWOGapAnalysisMode">
                <a title={{fullScreenRecordDocPanalTitle}} href="javascript:void(0);"
                    class="button-icon jarviswidget-fullscreen-btn tablIndexFocus" [attr.tabindex]="0"
                    (keyup.enter)="fullScreenRecordDocPanal()" rel="tooltip" data-placement="bottom"
                    data-original-title="Fullscreen" (click)="fullScreenRecordDocPanal()" id="btnRecordFullScreen">
                    <i class="fa fa-expand"></i>
                </a>
            </div>
            <!--<span class="widget-icon"><i class="fa fa-list"></i></span>-->
            <h2 *ngIf="directoryCount && directoryCount>0">
                <a href="javascript:void(0);" class="button-icon jarviswidget-fullscreen-btn tablIndexFocus"
                    rel="tooltip" title="{{directoryCount}}" data-placement="bottom" data-original-title=""
                    (click)="GetDirectoryFiles()" id="btnRecordFullScreen">
                    Documents
                </a>
                <span *ngIf="totalCount">({{totalCount}})</span>
            </h2>


            <h2 *ngIf="!directoryCount">Documents <span *ngIf="totalCount">({{totalCount}})</span> </h2>
            <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>
            <div class="jarviswidget-ctrls" role="menu" *ngIf="_displayMode!='status' && !inWOGapAnalysisMode">
                <a title={{wideRecordDocPanalTitle}} class="button-icon tablIndexFocus" [attr.tabindex]="0"
                    (keyup.enter)="wideRecordDocPanal()" href="javascript:void(0);" (click)="wideRecordDocPanal()"
                    id="btnRecordWideScreen">
                    <i class="glyphicon glyphicon-resize-horizontal"></i>
                </a>
            </div>

            <!--Upload Component Start-->
            <upload-document [recordType]="recordType"
                *ngIf="selectedContainer && _displayMode!='status' && !inWOGapAnalysisMode"></upload-document>
            <edit-metadata [recordType]="recordType" (updateDocumentItem)="isAddCommItem($event,true)"
                *ngIf="(canEditMetaData||canEditMetaDataReadonly) && selectedContainer  && _displayMode!='status' ">
            </edit-metadata>


            <div class="jarviswidget-ctrls pdfoperations" role="menu"
                *ngIf="selectedContainer && _displayMode!='status' && !inWOGapAnalysisMode && chartMenuList.length > 0">
                <div class="btn-group">
                    <a class="button-icon dropdown-toggle tablIndexFocus" [attr.tabindex]="0" data-toggle="dropdown"
                        aria-expanded="true" title="View Graph">
                        <i class="fa fa-bar-chart"></i>
                    </a>

                    <ul class="dropdown-menu pull-right js-status-update">
                        <li *ngFor="let chart of chartMenuList;">
                            <a href="javascript:void(0);" (keyup.enter)="generateDocCharts(chart.label)"
                                (click)="generateDocCharts(chart.label)">
                                <i class="fa fa-bar-chart txt-color-green"></i>&nbsp;{{chart.label}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="jarviswidget-ctrls pdfoperations" role="menu"
                *ngIf="selectedContainer && _displayMode!='status' && !inWOGapAnalysisMode">
                <!--canDownloadPDF &&-->
                <div class="btn-group" *ngIf="selectedContainer && _displayMode!='status'">
                    <a class="button-icon dropdown-toggle tablIndexFocus" [attr.tabindex]="0" data-toggle="dropdown"
                        aria-expanded="true" title="Download">
                        <i class="glyphicon glyphicon-compressed"></i>
                    </a>
                    <ul class="dropdown-menu pull-right js-status-update">
                        <li>
                            <a href="javascript:void(0);" (click)="validatePdfRequest()"> <i
                                    class="fa fa-columns txt-color-green"></i>&nbsp;Download (Ctrl+Shift+P)</a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" (click)="exportExcelGrid()"><i
                                    class="fa fa-columns txt-color-green"></i>&nbsp;Excel (Ctrl+Shift+Z)
                                <!--(Ctrl+Shift+T)-->
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" (click)="exportGridData()">
                                <i class="fa fa-save txt-color-green"></i>&nbsp;CSV (Ctrl+Shift+X)
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="jarviswidget-ctrls" role="menu"
                *ngIf="canDelete && selectedContainer && _displayMode!='status' && !inWOGapAnalysisMode ">
                <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="confirmDelete()" id="btndelete"
                    href="javascript:void(0);" (click)="confirmDelete()" title="Delete Document(s) (Ctrl+D)">
                    <i class="glyphicon glyphicon-trash"></i>
                </a>
            </div>

            <div class="btn-group jarviswidget-ctrls" role="menu" style="cursor: pointer;"
                *ngIf="canCopyMove && selectedContainer && _displayMode!='status' && !inWOGapAnalysisMode">
                <a class="button-icon dropdown-toggle tablIndexFocus" [attr.tabindex]="0" data-toggle="dropdown"
                    aria-expanded="true" id="btnMoveCopy" href="javascript:void(0);" title="Copy/Move Document(s)">
                    <i class="glyphicon glyphicon-copy"></i>
                </a>
                <ul class="dropdown-menu pull-right js-status-update">
                    <li *ngIf="canUpload">
                        <a href="javascript:void(0);" style="float: left;" class="tablIndexFocus" [attr.tabindex]="0"
                            (keyup.enter)="copyMoveDocuments()" (click)="copyMoveDocuments();">
                            <i class="fa fa-copy txt-color-green clsdownload"></i>&nbsp;Copy/Move To (Ctrl+Q)
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" (click)="OpenCopyFromModel()" class="tablIndexFocus"
                            [attr.tabindex]="0">
                            <i class="fa fa-copy txt-color-green clsdownload"></i>&nbsp;Copy/Move From (Ctrl+B)
                        </a>
                    </li>

                </ul>
            </div>

            <div class="jarviswidget-ctrls"
                *ngIf="selectedContainer && _displayMode!='status' && totalCount>1 && !inWOGapAnalysisMode && canEditMetaData">
                <a [style.color]="iconcolor " [attr.tabindex]="0" class="button-icon tablIndexFocus" id="showdragdrop"
                    (keyup.enter)="enableDisableRowDrag()" (click)="enableDisableRowDrag()" [title]="titleValue">
                    <i class="fa fa-arrows"></i>
                </a>
            </div>

            <div class="jarviswidget-ctrls"
                *ngIf="selectedContainer && _displayMode!='status' && totalCount>=1 && !inWOGapAnalysisMode">
                <a [style.color]="iconcolorSidebar " [attr.tabindex]="0" class="button-icon tablIndexFocus"
                    id="showDocGridSideBar" (keyup.enter)="enableDisableDocGridSideBar()"
                    (click)="enableDisableDocGridSideBar()" [title]="sideBarTitleValue">
                    <i class="fa fa-bars"></i>
                </a>
            </div>

            <div class="jarviswidget-ctrls pull-right" role="menu"
                *ngIf="(canRunWOGapAnalysis || inTechLogGapAnalysisMode) && selectedContainer ">


                <a *ngIf="!inWOGapAnalysisMode" [attr.tabindex]="0" (keyup.enter)="showWoGapManualDlg()"
                    class="button-icon tablIndexFocus pull-right" id="btnWoGap" href="javascript:void(0);"
                    (click)="showWoGapManualDlg()" title="{{woGapButtonManualTitle}}">
                    <i class="fa fa-exchange"></i>
                </a>


                <div class="btn-group" *ngIf="inWOGapAnalysisMode">
                    <a class="button-icon dropdown-toggle tablIndexFocus" [attr.tabindex]="0" data-toggle="dropdown"
                        aria-expanded="true" title="Filter">
                        <i class="fa fa-filter"></i>
                    </a>
                    <ul class="dropdown-menu pull-right js-status-update">
                        <li *ngIf="!filterGrid">
                            <a href="javascript:void(0);" (keyup.enter)="showHideWhiteRows()"
                                (click)="showHideWhiteRows()">
                                <i style="float: left;" class="glyphicon glyphicon-tasks"></i>&nbsp;Show compared
                                documents</a>
                        </li>
                        <li *ngIf="!filterGrid">
                            <a href="javascript:void(0);" (keyup.enter)="showHideRedRows()" (click)="showHideRedRows()">
                                <i style="float: left;" class="glyphicon glyphicon-remove"></i>&nbsp;Show no document
                                found</a>
                        </li>
                        <li *ngIf="filterGrid">
                            <a href="javascript:void(0);" (keyup.enter)="resetGridFilter()" (click)="resetGridFilter()">
                                <i style="float: left;" class="glyphicon glyphicon-remove"></i>&nbsp;Clear filter</a>
                        </li>
                    </ul>
                </div>

                <div class="btn-group" *ngIf="inWOGapAnalysisMode">
                    <a class="button-icon dropdown-toggle tablIndexFocus" [attr.tabindex]="0" data-toggle="dropdown"
                        aria-expanded="true" title="Download">
                        <i class="glyphicon glyphicon-compressed"></i>
                    </a>
                    <ul class="dropdown-menu pull-right js-status-update" style="min-width: 90px !important">
                        <li>
                            <a *ngIf="inWOGapAnalysisMode" href="javascript:void(0);"
                                (click)="downloadGapanalyserGridExcel()">
                                <i class="fa fa-columns txt-color-green"></i>&nbsp;Excel
                            </a>
                        </li>
                        <li>
                            <a *ngIf="inWOGapAnalysisMode" href="javascript:void(0);"
                                (click)="downloadGapanalyserGridCSV()">
                                <i class="fa fa-save txt-color-green"></i>&nbsp;CSV
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="pull-right" role="menu"
                *ngIf="(canRunWOGapAnalysis || inTechLogGapAnalysisMode) && selectedContainer"
                style="margin-right: 10px;border-left: 1px solid rgba(0,0,0,.09)">
                <a *ngIf="inWOGapAnalysisMode || inTechLogGapAnalysisMode" [attr.tabindex]="0"
                    (keyup.enter)="goBackToNormalView()" class="badge bg-color-green tablIndexFocus"
                    id="btnGoBackToNormal" href="javascript:void(0);" (click)="goBackToNormalView()"
                    style="margin-left:10%;margin-top:6px" title="Go back to normal view">
                    <i class="fa fa-arrow-left"></i>
                    Back
                </a>
            </div>

            <div class="jarviswidget-ctrls" role="menu"
                *ngIf="canAnalyzeTechLog && selectedContainer  && totalCount>1 && _displayMode!='status' && !inWOGapAnalysisMode">
                <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="runTechlogAnalyzer()"
                    id="btnTechlogAnalyzer" href="javascript:void(0);" (click)="runTechlogAnalyzer()"
                    title="Gap Analyser (Ctrl+Y)">
                    <i class="fa fa-list-ol"></i>
                </a>
            </div>

            <div class="jarviswidget-ctrls" role="menu"
                *ngIf="selectedContainer  && totalCount>1 && _displayMode!='status' && !inWOGapAnalysisMode">
                <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="runDuplicateAnalyzer()"
                    id="btnTechlogAnalyzer" href="javascript:void(0);" (click)="runDuplicateAnalyzer()"
                    title="Duplicate Analyser (Ctrl+Shift+Y)">
                    <i class="fa fa-indent"></i>
                </a>
            </div>
            <!-- <div *ngIf="_displayMode!='status' && canViewFeedFromStatus"
            class="jarviswidget-ctrls" role="menu">
            <a class="button-icon btnShowFeed tablIndexFocus" id="btnShowFeed" (click)="btnShowFeed()"
                [attr.tabindex]="0" (keyup.enter)="btnShowFeed()" title="API">
                <i class="glyphicon glyphicon-import"></i>
            </a>
           </div> -->
            <div class="jarviswidget-ctrls" title="Help">
                <app-component-articles [componentName]="componentName" *ngIf="_displayMode!='status'">
                </app-component-articles>
            </div>
        </header>
        <!-- widget div-->

        <div *ngIf="!inWOGapAnalysisMode" style="padding: 3px 3px 3px 3px;">
            <div class="row" id="searchblock">
                <!--style="display: block;"-->
                <div class="col-sm-3 col-md-3 col-lg-3 nopaddingnmargin">
                    <select class="form-control searchtype nopaddingnmargin" id="searchtype" [attr.tabindex]="0"
                        [(ngModel)]="searchMode">
                        <option value="Exact">Exact</option>
                        <option value="Partial">Partial</option>
                        <option value="Begin">Begin</option>
                        <option value="End">End</option>

                    </select>
                </div>
                <div class="col-sm-9 col-md-9 col-lg-9 nopaddingnmargin ocrsearchbar" #searchcoldoc>
                    <div class="input-group">
                        <input id="searchinputscoped" name="focus" class="form-control searchcontrol"
                            [attr.tabindex]="0" [(ngModel)]="searchQuery" #searchinputscoped type="text"
                            placeholder="OCR Search..." (keyup.enter)="handleSearchButtonClick()"
                            (ngModelChange)="onModelChange($event,opdoc,searchcoldoc)">
                        <span title="Clear"
                            [ngStyle]="{'visibility': (isBlankSearchQuery||searchQuery?.length)?'visible':''}"
                            (click)="crossSignClicked()">×</span>
                        <div class="input-group-btn">
                            <button pButton id="docSearchBtn"
                                class="btn btn-default search-input-pdf p-button-secondary fix-search-button tablIndexFocus"
                                [attr.tabindex]="0" icon="fa fa-search" label="Search" type="button" [attr.tabindex]="0"
                                (click)="handleSearchButtonClick()">
                            </button>
                        </div>
                        <div class="input-group-btn">
                            <button ID="addAdvanceSearch"
                                style="background-color: transparent !important;border: none !important;" #searchbutton
                                pButton class="p-button-secondary search-input-pdf fix-font-width"
                                icon="glyphicon glyphicon-plus" type="button" (click)="addAdvanceSearch()">
                            </button>
                        </div>

                        <!-- <div>
                        <a class="button-icon hand-cursor pull-right"
                    style="padding-bottom: 6px;" id="btnAddkeywordsearch"
                    (click)="addAdvanceSearch()">
                    <i class="glyphicon glyphicon-plus"></i>
                </a>
                    </div> -->
                        <!-- <div class="input-group-btn">
                        <button #searchbutton pButton class="p-button-secondary search-input-pdf fix-font-width" icon="fa fad fa-plus fa-w-16 fa-7x" type="button" (click)="addAdvanceSearch()">
                        </button>
                    </div> -->
                    </div>
                </div>

                <!-- <div class="col-sm-1 col-md-1 col-lg-1" id="searchdv" style="padding-right: 0px;padding-top: 8px"> 
                <a class="button-icon hand-cursor pull-right"
                    style="padding-right: 50%;padding-bottom: 6px;" id="btnAddkeywordsearch"
                    (click)="addAdvanceSearch()">
                    <i class="glyphicon glyphicon-plus"></i>
                </a>
            </div> -->

            </div>
            <fieldset class="fset" id="fset">
                <div *ngFor="let advanceSearch of advanceSearch; let j = index">
                    <div style="padding: 0px !important;margin: 0px !important"
                        class="form-group col-sm-3 col-md-3 col-lg-3">
                        <ng-template [ngIf]="j >=0">
                            <select class="form-control searchtype nopaddingnmargin valid" id="condition{{j}}"
                                name="condition{{j}}" [(ngModel)]="advanceSearch.Condition"
                                placeholder="Select Condition">
                                <option *ngFor="let c of keywordConditions" [value]="c.value">
                                    {{c.label}}
                                </option>
                            </select>
                        </ng-template>
                    </div>
                    <div style="padding: 0px !important" class="form-group col-sm-9 col-md-9 col-lg-9">
                        <div class="input-group">
                            <input id="cndValue{{j}}" class="form-control searchcontrol" type="text"
                                name="cndValue{{j}}" [(ngModel)]="advanceSearch.SearchValue"
                                placeholder="OCR Search..." />
                            <div class="input-group-btn">
                                <button #searchbutton pButton class="p-button-secondary search-input-pdf fix-font-width"
                                    icon="fa fas fa-close fa-w-16 fa-7x" type="button"
                                    (click)="deleteInstance(advanceSearch)">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
        <div id="conPanelContent" class="widgetdiv no-padding standardpadding set-screen-width" style="flex: 1;"
            role="content">
            <!--style="height:535px;"-->

            <!-- widget edit box -->
            <div class="jarviswidget-editbox">
                <!-- This area used as dropdown edit box -->

            </div>
            <!-- end widget edit box -->
            <!-- widget content -->
            <div class="widget-body set-widget-body-width">
                <!--
                The ID "widget-grid" will start to initialize all widgets below
                You do not need to use widgets if you dont want to. Simply remove
                the <section></section> and you can use wells or panels instead
                -->
                <!-- widget grid -->
                <!--<primeng-tree></primeng-tree>-->
                <section id="widget-grid2" class="">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 nopaddingnmargin">

                                <div id="gridContainer" *ngIf="docGridData">
                                    <!--AG Grid Start-->
                                    <!--<ag-grid-angular  id="documentGrid" #docGrid [gridOptions]="gridOptions" [columnDefs]="columnDefs" [excelStyles]="excelStyles" [rowData]="docGridData" (componentStateChanged)="selectFirstRow($event)" (rowDoubleClicked)="docGridRowDoubleClicked($event)" sortable="true"  style="width: 100% !important;" class="ag-theme-balham set-docgrid-width">
                                    </ag-grid-angular>-->
                                    <ag-grid-angular id="documentGrid" #docGrid [gridOptions]="gridOptions"
                                        [columnDefs]="columnDefs" [excelStyles]="excelStyles" [rowData]="docGridData"
                                        (componentStateChanged)="selectFirstRow($event)" [rowDragManaged]="true"
                                        [animateRows]="true" [sideBar]="sideBar"
                                        (rowDoubleClicked)="docGridRowDoubleClicked($event)"
                                        (rowDragEnd)="docRowDragEnd($event)" sortable="true" filter=true
                                        style="width: 100% !important; " class="ag-theme-balham set-docgrid-width">
                                    </ag-grid-angular>

                                    <!--AG Grid End-->
                                </div>
                                <div style="display: none;">
                                    <ag-grid-angular id="chartDocumentGrid" #chartDocGrid
                                        [gridOptions]="chartGridOptions"
                                        [columnDefs]="chartColumnDefs" [rowData]="chartDocGridData" 
                                        [popupParent]="popupParent" [pivotMode]="true"
                                        sortable="true" filter=true style="width: 100% !important;"
                                        class="ag-theme-balham set-docgrid-width">
                                    </ag-grid-angular>

                                    <!--AG Grid End-->
                                </div>
                                <!--<div id="dvNoRecordContainer" class="alert alert-block alert-warning" *ngIf="!docGridData">
                                    <h4 class="alert-heading">Warning!</h4>
                                    No container selected!
                                </div>-->
                            </div>
                        </div>
                        <!-- end row -->
                    </div>
                </section>
                <!-- end widget grid -->
            </div>
            <!-- end widget content -->
        </div>
        <!-- end widget div -->
        <p-confirmDialog></p-confirmDialog>
    </div>
    <!-- end widget -->
    <form #containerForm="ngForm" *ngIf="displayMoveCopyDlg">
        <p-dialog [(visible)]="displayMoveCopyDlg" *ngIf="displayMoveCopyDlg" showEffect="fade" [modal]="true"
            [style]="{width: '720px'}" [resizable]="false">
            <ng-template pTemplate="header">
                Select Target Container
            </ng-template>
            <div class="upload-dialog">
                <!--style="overflow-y: hidden !important;overflow-x:hidden !important"-->
                <div class="smart-form">
                    <div class="row">
                        <div class="form-group col-sm-5 fix-Padding">
                            <fieldset>
                                <label class="label">Asset</label>
                                <p-dropdown appendTo="body" id="asn" class="comboFit" name="asn" [options]="assets"
                                    (onChange)="onChangeAsset($event)" placeholder="Select Asset" required
                                    [(ngModel)]="containerModel.asn" #asn="ngModel" [filter]="true" #asncopymovefilter
                                    [resetFilterOnHide]="true">
                                    <ng-template style="padding-bottom:20px;padding-left: 0px !important;" let-item
                                        pTemplate="selectedItem">
                                        <span>{{item.label}}</span>
                                        <!-- <span style="font-size:14px;background-color:lightgreen;">{{item.AssetType}}</span> -->
                                    </ng-template>
                                    <ng-template style="padding-bottom:20px !important;padding-left: 0px !important;"
                                        let-asset pTemplate="item">
                                        <div style="padding-bottom: 20px;">
                                            <!-- <span > -->
                                            <span style="padding:0" class="col-md-8">{{asset.label}}</span>

                                            <!-- </div> -->
                                            <!-- <div class="col-md-4"> -->
                                            <span style="padding:0" class="col-md-4">
                                                <span
                                                    style="font-size: 11px;padding: 4px;background-color:lightgreen;color: white;border-radius: 3px;">
                                                    {{asset.AssetType}}
                                                </span>
                                            </span>

                                            <!-- </div> -->
                                        </div>
                                    </ng-template>
                                </p-dropdown>


                            </fieldset>
                        </div>
                        <div class="form-group col-sm-5 fix-Padding">
                            <fieldset>
                                <label class="label">Category </label>
                                <p-dropdown appendTo="body" id="category" name="category" class="comboFit"
                                    [options]="categories" (onChange)="onChangeCategory($event)"
                                    placeholder="Select Category" [(ngModel)]="containerModel.category"
                                    #category="ngModel">
                                </p-dropdown>
                            </fieldset>
                        </div>
                        <div class="form-group col-sm-2">
                            <fieldset class="pull-right">
                                <label class="label" style="height: 19px;"></label>
                                <button pButton type="button" (click)="populateContainerGrid()" class="custom-button"
                                    icon="fa fa-search" label="Search" [disabled]="!containerForm.form.valid"></button>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 5px;">
                        <div class="form-group col-sm-12">
                            <ag-grid-angular #containerGrid [gridOptions]="conGridOptions" [columnDefs]="conColumnDefs"
                                [rowData]="conGridData" (componentStateChanged)="formatContainerGrid($event)"
                                enableFiltering sortable="true" style="width: 100% !important; height:300px !important;"
                                class="ag-theme-balham" (rowClicked)='onContainerRowClick($event)'>
                                <!--(rowClicked)='updateSelectedDocument($event)'-->
                            </ag-grid-angular>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template pTemplate="footer">
                <div class="p-dialog-buttonpane">
                    <app-attach-selected-pages [FormDisabled]="containerForm.form.valid"
                        [ConRowSelected]="conRowSelected"
                        (emitSelectedPageEmitter)="selectedPageRange($event,containerForm)"></app-attach-selected-pages>
                    <button type="button" pButton icon="fa fa-copy" class="btn btn-primary minzindex"
                        (click)="btnMoveCopyDocumentClick(true, containerForm)"
                        [disabled]="!containerForm.form.valid ||!conRowSelected" label="Copy"></button>
                    <button type="button" pButton icon="fa fa-angle-double-right" class="btn btn-primary minzindex"
                        [disabled]="!containerForm.form.valid || !conRowSelected"
                        (click)="btnMoveCopyDocumentClick(false, containerForm)" label="Move"></button>
                    <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                        (click)="btnCancelContainerClick(containerForm)" label="Cancel"></button>
                </div>
            </ng-template>

        </p-dialog>
    </form>

    <!-- copy from dialog -->

    <form #containerForm="ngForm" *ngIf="displayCopyFromDlg">
        <p-dialog [(visible)]="displayCopyFromDlg" *ngIf="displayCopyFromDlg" showEffect="fade" [modal]="true"
            [style]="{width:'87vw'}" [resizable]="false">
            <ng-template pTemplate="header">
                Select Source Document(s)
            </ng-template>
            <div class="upload-dialog">
                <!--style="overflow-y: hidden !important;overflow-x:hidden !important"-->
                <div>
                    <div class="row">
                        <div class="form-group col-sm-6 fix-Padding" style="padding-left: 0px !important">
                            <fieldset>
                                <div class="col-sm-12" style="padding: 0px;">
                                    <label class="label" style="color: #404040;">Asset</label>
                                    <p-dropdown appendTo="body" id="asn" class="comboFit1" name="asn" [options]="assets"
                                        (onChange)="onChangeAsset($event)" placeholder="Select Asset" required
                                        [(ngModel)]="containerModel.asn" #asn="ngModel" [filter]="true"
                                        #asncopymovefilter [resetFilterOnHide]="true">
                                        <ng-template style="padding-bottom:20px;padding-left: 0px !important;" let-item
                                            pTemplate="selectedItem">
                                            <span>{{item.label}}</span>
                                            <!-- <span style="font-size:14px;background-color:lightgreen;">{{item.AssetType}}</span> -->
                                        </ng-template>
                                        <ng-template
                                            style="padding-bottom:20px !important;padding-left: 0px !important;"
                                            let-asset pTemplate="item">
                                            <div style="padding-bottom: 20px;">
                                                <!-- <span > -->
                                                <span style="padding:0" class="col-md-8">{{asset.label}}</span>

                                                <!-- </div> -->
                                                <!-- <div class="col-md-4"> -->
                                                <span style="padding:0" class="col-md-4">
                                                    <span
                                                        style="font-size: 11px;padding: 4px;background-color:lightgreen;color: white;border-radius: 3px;">
                                                        {{asset.AssetType}}
                                                    </span>
                                                </span>

                                                <!-- </div> -->
                                            </div>
                                        </ng-template>
                                    </p-dropdown>

                                </div>
                            </fieldset>
                        </div>
                        <div class="col-sm-6" style="padding-left: 0px !important; padding-right: 0px;">
                            <fieldset>
                                <div class="col-sm-12" style="padding: 0px;">
                                    <div class="form-group col-sm-10 fix-Padding">
                                        <label style="color: #404040;" class="label">Category </label>
                                        <br>
                                        <p-dropdown appendTo="body" id="category" name="category" class="comboFit1"
                                            [options]="categories" (onChange)="onChangeCategory($event)"
                                            placeholder="Select Category" [(ngModel)]="containerModel.category"
                                            #category="ngModel">
                                        </p-dropdown>
                                    </div>
                                    <br>
                                    <div class="form-group col-sm-2"
                                        style="float: right !important; margin: 0px; padding: 0px;">
                                        <button pButton style="float: right !important;" type="button"
                                            (click)="populateContainerGrid()" class="custom-button" icon="fa fa-search"
                                            label="Search" [disabled]="!containerForm.form.valid">
                                        </button>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 5px;">
                        <div style="padding-left: 0px;" class="col-sm-6">
                            <div class="jarviswidget" id="wid-id-2" data-widget-sortable="false"
                                data-widget-colorbutton="false" data-widget-editbutton="false"
                                data-widget-custombutton="false" data-widget-deletebutton="false"
                                data-widget-togglebutton="false" data-widget-fullscreenbutton="false" role="widget">

                                <header role="heading" id="docCopyFromPanelHeader">
                                    <h2>Containers</h2>
                                </header>
                                <ag-grid-angular #containerGrid [gridOptions]="conGridOptions"
                                    [columnDefs]="conColumnDefs" [rowData]="conGridData"
                                    (componentStateChanged)="formatContainerGrid($event)" enableFiltering
                                    sortable="true" style="width: 100% !important; height:360px !important;"
                                    class="ag-theme-balham" (rowClicked)='onContainerRowClick($event)'>
                                    <!--(rowClicked)='updateSelectedDocument($event)'-->
                                </ag-grid-angular>
                            </div>
                        </div>
                        <div style="padding-right: 0px;" class="col-sm-6">
                            <div class="jarviswidget" id="wid-id-2" data-widget-sortable="false"
                                data-widget-colorbutton="false" data-widget-editbutton="false"
                                data-widget-custombutton="false" data-widget-deletebutton="false"
                                data-widget-togglebutton="false" data-widget-fullscreenbutton="false" role="widget">

                                <header role="heading" id="docCopyFromPanelHeader">
                                    <h2>Documents</h2>
                                    <div class="jarviswidget-ctrls pull-right">
                                        <a class="button-icon tablIndexFocus" title="View Document"
                                            (click)="changeSelectedDoc()">
                                            <i style="color: black;" class="fa fa-external-link"></i>
                                        </a>
                                    </div>
                                </header>
                                <ag-grid-angular #CopyFromGrid [gridOptions]="copyDocGridOptions"
                                    [columnDefs]="columnDefsCopyFrom" [rowData]="docCopyFromGridData"
                                    (componentStateChanged)="formatContainerGrid($event)" enableFiltering
                                    sortable="true" style="width: 100% !important; height:360px !important;"
                                    class="ag-theme-balham" [rowDragManaged]="true"
                                    (rowClicked)='docCopyFromRowClick($event)'
                                    (rowDoubleClicked)="docCopyFromRowDoubleClicked($event)">
                                    <!--(rowClicked)='updateSelectedDocument($event)'-->
                                </ag-grid-angular>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template pTemplate="footer">
                <div class="p-dialog-buttonpane">
                    <app-attach-selected-pages [FormDisabled]="containerForm.form.valid"
                        [ConRowSelected]="conRowSelected"
                        (emitSelectedPageEmitter)="selectedPageRange($event,containerForm)"></app-attach-selected-pages>
                    <button type="button" pButton icon="fa fa-copy" class="btn btn-primary minzindex"
                        (click)="btnMoveCopyCopyFrom(true, containerForm)"
                        [disabled]="!containerForm.form.valid ||!conRowSelected" label="Copy"></button>
                    <button type="button" pButton icon="fa fa-angle-double-right" class="btn btn-primary minzindex"
                        [disabled]="!containerForm.form.valid || !conRowSelected"
                        (click)="btnMoveCopyCopyFrom(false, containerForm)" label="Move"></button>
                    <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                        (click)="btnCancelCopyFromDlgClick(containerForm)" label="Cancel"></button>
                </div>
                <!-- [disabled]=" isDocCopyFromSelectedRows == false" -->

            </ng-template>

        </p-dialog>
    </form>
    <p-dialog *ngIf="showInvalidOrMissingInfoDlg" [(visible)]="showInvalidOrMissingInfoDlg" showEffect="fade"
        [modal]="true" [style]="{width: '730px'}" [resizable]="false">
        <ng-template pTemplate="header" (keyup.shift.tab)="btnCancelDlgClick()">
            Some document(s) are invalid or have missing information
        </ng-template>
        <div class="top-border-statuslinks" [attr.tabindex]="0">
            <ag-grid-angular #agGridInvalidOrMissingInfoDoc [gridOptions]="gridOptionsInvalidOrMissingInfoDoc"
                [columnDefs]="columnDefsInvalidOrMissingInfoDoc" [rowData]="docInvalidOrMissingInfoGridData"
                sortable="true" filter=true style="width: 100%;height:350px" class="ag-theme-balham tablIndexFocus">
            </ag-grid-angular>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button autofocus="true" [attr.tabindex]="0" type="submit" pButton icon="fa fa-share" title="OK."
                    class="btn btn-Default minzindex p-button-default" label="Proceed"
                    (click)="btnInvalidOrMissingDocClick('Proceed')"></button>

                <button type="button" [attr.tabindex]="0" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    label="Cancel" (click)="btnCancelContainerClick(containerForm)"></button>
            </div>
        </ng-template>
    </p-dialog>


    <p-dialog *ngIf="showDependencyDlg" [(visible)]="showDependencyDlg" showEffect="fade" [modal]="true"
        [style]="{width: '730px'}" [resizable]="false">
        <ng-template pTemplate="header" (keyup.shift.tab)="btnCancelDlgClick()">
            Some document(s) are attached with inbox/status row(s)
        </ng-template>
        <div class="top-border-statuslinks" [attr.tabindex]="0">
            <ag-grid-angular #agGridDependencies [gridOptions]="gridOptionsDependencies" [columnDefs]="columnDefsDpt"
                [rowData]="docRefToDependenciesGridData" sortable="true" filter=true style="width: 100%;height:350px"
                class="ag-theme-balham tablIndexFocus">
            </ag-grid-angular>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button autofocus="true" [attr.tabindex]="0" type="submit" pButton icon="fa fa-share"
                    title="Ignore attached document(s) and move remaining." class="btn minzindex custom-button"
                    label="Ignore Document(s)" (click)="btnDocDependencyClick('Ignore')"
                    [disabled]="(canDetachMXR ==false && currentType=='mxr')|| (canDetachLog==false  && currentType=='log' )||(canDetachGen==false  && currentType=='gen')||(canDetachSearch==false  && currentType=='search')"></button>

                <button autofocus="true" [attr.tabindex]="0" type="submit" pButton icon="fa fa-unlink"
                    title="Detach link(s) and move" class="btn minzindex p-button-danger" label="Detach Document(s)"
                    (click)="btnDocDependencyClick('Detach')"
                    [disabled]="(canDetachMXR ==false && currentType=='mxr')|| (canDetachLog==false  && currentType=='log' )||(canDetachGen==false  && currentType=='gen')||(canDetachSearch==false  && currentType=='search')"></button>

                <button autofocus="true" [attr.tabindex]="0" type="submit" pButton icon="fa fa-pencil-square-o"
                    title="Update link(s) with new location and move" class="btn btn-success minzindex p-button-success"
                    label="Update Document(s)" (click)="btnDocDependencyClick('Update')"
                    [disabled]="(canDetachMXR ==false && currentType=='mxr')|| (canDetachLog==false  && currentType=='log' )||(canDetachGen==false  && currentType=='gen')||(canDetachSearch==false  && currentType=='search')"></button>

                <button type="button" [attr.tabindex]="0" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    label="Cancel" (click)="btnCancelContainerClick(containerForm)"></button>
            </div>
        </ng-template>
    </p-dialog>


    <p-dialog *ngIf="showImageToPDFDlg" [(visible)]="showImageToPDFDlg" showEffect="fade" [modal]="true"
        [style]="{width: '730px'}" [resizable]="false">
        <ng-template pTemplate="header" (keyup.shift.tab)="btnCancelDlgClick()">
            Following image(s) will convert to PDF
        </ng-template>
        <div class="top-border-statuslinks" [attr.tabindex]="0">
            <ag-grid-angular #agGridImageToPDF [gridOptions]="gridOptionsImageToPDF" [columnDefs]="columnDefsImageToPDF"
                [rowData]="docImageToPDFGridData" sortable="true" filter=true style="width: 100%;height:350px"
                class="ag-theme-balham tablIndexFocus">
            </ag-grid-angular>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button autofocus="true" [attr.tabindex]="0" type="submit" pButton icon="fa fa-share" title="OK."
                    class="btn btn-Default minzindex p-button-default" label="OK"
                    (click)="btnImageFileToPDFDocClick('OK')"></button>

                <button autofocus="true" [attr.tabindex]="0" type="submit" pButton icon="fa fa-unlink"
                    title="Ignore Image(s)" class="btn btn-primary minzindex p-button-danger" label="Ignore Image(s)"
                    (click)="btnImageFileToPDFDocClick('Ignore')"></button>

                <button type="button" [attr.tabindex]="0" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    label="Cancel" (click)="btnCancelContainerClick(containerForm)"></button>
            </div>
        </ng-template>
    </p-dialog>

    <form #docDeleteConfirmForm="ngForm" *ngIf="displayDeleteConfirmDlg">
        <p-dialog [(visible)]="displayDeleteConfirmDlg" *ngIf="displayDeleteConfirmDlg" showEffect="fade" [modal]="true"
            [style]="{width: '920px'}" [resizable]="false">
            <ng-template pTemplate="header">
                Following document(s) are attached with inbox / status rows. Do you want to delete?
            </ng-template>
            <div class="upload-dialog">
                <div class="smart-form" style="height: 60vh;">
                    <div class="row">
                        <div id="containerDeleteConfirmGrid" class="form-group col-sm-12">
                            <ag-grid-angular #docDeleteConfirmGrid [gridOptions]="docDeleteConfirmGridOptions"
                                [columnDefs]="columnDefs" [rowData]="docDeleteConfirmGridData"
                                style="width: 100% !important; height: 60vh !important;" class="ag-theme-balham">
                            </ag-grid-angular>
                            <!-- <ag-grid-angular #docDeleteConfirmGrid [gridOptions]="docDeleteConfirmGridOptions"
                                [columnDefs]="docDeleteConfirmColumnDefs" [rowData]="docDeleteConfirmGridData"
                                style="width: 100% !important; height: 60vh !important;" class="ag-theme-balham">
                            </ag-grid-angular> -->
                        </div>
                    </div>
                </div>
            </div>
            <ng-template pTemplate="footer">
                <div class="p-dialog-buttonpane">
                    <button type="button" pButton icon="fa fa-trash" class="btn btn-primary minzindex"
                        (click)="deleteAllDocuments(true)" label="Remaining"
                        title="Documents having link with status rows will not deleted"></button>
                    <button type="button" pButton icon="fa fa-trash" class="btn btn-primary minzindex"
                        (click)="deleteAllDocuments(false)" label="All"
                        title="Link with Status rows will be removed"></button>
                    <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                        (click)="displayDeleteConfirmDlg=!displayDeleteConfirmDlg" label="Cancel"></button>
                </div>
            </ng-template>

        </p-dialog>
    </form>

    <form [formGroup]="WOGapFormGroup" *ngIf="diaplayWOGapManualDialog">
        <p-dialog id="wOGapManualDlg" appendTo="body" *ngIf="diaplayWOGapManualDialog"
            [(visible)]="diaplayWOGapManualDialog" header="Gap Analyser" showEffect="fade" [modal]="true"
            [resizable]="false" responsive="true" [style]="{width: '45vw',top:'20px'}"
            [contentStyle]="{ 'max-height':'70vh', 'overflow-y': 'scroll',  'padding-top': '0px', 'padding-bottom': '8px', 'padding-left': '8px', 'padding-right': '8px'}"
            (onShow)="setFocus($event)">
            <div class="upload-dialog">
                <div id="smart-form" class="smart-form dialogSettings">
                    <div class="row">
                        <div class="form-group col-sm-12" style="margin-top:10px;">
                            <div class="panel panel-default" style="border-right-width: 2px;">
                                <div class="panel-heading">
                                    <h3 class="panel-title panalheading-padding">
                                        Parameter(s)
                                        <a class="button-icon hand-cursor pull-right" style="padding-right: 5px;"
                                            id="btnAddFieldSet" (click)="addWOManualParam()">
                                            <i class="glyphicon glyphicon-plus"></i>
                                        </a>
                                    </h3>
                                </div>
                                <div class="panel-body">
                                    <div id="form-fields form-group"
                                        *ngFor="let WOGapMapping of WOGapMappingList; index as i">
                                        <div class="col-sm-12" style="padding-left: 5px;"
                                            *ngFor="let field of WOGapMapping.stringParam; index as j">
                                            <div class="col-sm-6" *ngIf="field.Value.indexOf('Date') == -1">
                                                <fieldset>
                                                    <label class="label">{{getWOManualFieldLabel(field.Value)}} <span
                                                            class="text-danger"></span>
                                                        <a class="button-icon hand-cursor pull-right"
                                                            style="padding-right: 5px;" id="btnRemoveFieldSet"
                                                            *ngIf="WOGapMappingList.length>1 && j==0"
                                                            (click)="removeWOManualParam(WOGapMapping.id)">
                                                            <i class="glyphicon glyphicon-remove"></i>
                                                        </a>
                                                    </label>
                                                    <label class="input">
                                                        <input id="field{{WOGapMapping.id}}_{{field.Value}}" type="text"
                                                            class="input-smText"
                                                            name="field{{WOGapMapping.id}}_{{field.Value}}"
                                                            formControlName="field{{WOGapMapping.id}}_{{field.Value}}"
                                                            required>
                                                        <input id="field{{WOGapMapping.id}}_{{field.Value}}1"
                                                            type="hidden" class="input-smText"
                                                            name="field{{WOGapMapping.id}}_{{field.Value}}1"
                                                            formControlName="field{{WOGapMapping.id}}_{{field.Value}}1">
                                                    </label>
                                                </fieldset>
                                            </div>
                                            <div class="col-sm-6" *ngIf="field.Value.indexOf('Date') >= 0">
                                                <fieldset>
                                                    <label class="label">{{getWOManualFieldLabel(field.Value)}} <span
                                                            class="text-danger"></span>
                                                        <a class="button-icon hand-cursor pull-right"
                                                            style="padding-right: 5px;" id="btnRemoveFieldSet"
                                                            *ngIf="WOGapMappingList.length>1  && j==0"
                                                            (click)="removeWOManualParam(WOGapMapping.id)">
                                                            <i class="glyphicon glyphicon-remove"></i>
                                                        </a>
                                                    </label>
                                                    <label class="input" id="woGapDate">
                                                        <p-calendar appendTo="body" class='datepicker'
                                                            (onFocus)="onBlurWoDate($event)"
                                                            (onClose)="onCloseWoDate($event)"
                                                            id="field{{WOGapMapping.id}}_{{field.Value}}"
                                                            formControlName="field{{WOGapMapping.id}}_{{field.Value}}"
                                                            [style]="{'width':'89%'}"
                                                            name="field{{WOGapMapping.id}}_{{field.Value}}"
                                                            [monthNavigator]="true" [yearNavigator]="true"
                                                            yearRange="1950:2030" [dateFormat]="dateFormatCalender"
                                                            required>
                                                        </p-calendar>
                                                        <i class='icon-append fa fa-calendar'
                                                            style="font-size:medium;"></i>
                                                        <input id="field{{WOGapMapping.id}}_{{field.Value}}1"
                                                            type="hidden" class="input-smText"
                                                            name="field{{WOGapMapping.id}}_{{field.Value}}1"
                                                            formControlName="field{{WOGapMapping.id}}_{{field.Value}}1">
                                                    </label>
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <hr style="border-width: medium;" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ng-template pTemplate="footer">
                <div class="p-dialog-buttonpane">
                    <span class="pull-left">{{WOGapMappingList.length}} unique record set found. </span>
                    <button type="button" pButton icon="fa fa-check" class="btn btn-primary" label="Analyse"
                        [disabled]="!WOGapFormGroup.valid || WOGapMappingList.length == 0"
                        (click)="wOGapAnalysisManualClick()"></button>
                    <button type="button" pButton icon="fa fa-close" class="btn btn-default" label="Cancel"
                        (click)="CancelWOGapManualClick()"></button>
                </div>
            </ng-template>
        </p-dialog>
    </form>

    <p-dialog *ngIf="showUpdateFileDlg" [(visible)]="showUpdateFileDlg" showEffect="fade" [modal]="true"
        [style]="{width: '730px'}" [resizable]="false">
        <ng-template pTemplate="header" (keyup.shift.tab)="btnCancelDlgClick()">
            Replace Document
        </ng-template>
        <div class="top-border-statuslinks" [attr.tabindex]="0">
            <div class="row">
                <div class="col-sm-1">
                </div>
                <div id="UpdateUploadBlob" class="col-sm-6">
                    <input #singleFileUpload type="file" class="form-control" (change)="onChange($event)"
                        accept={{acceptFileTypes}}>
                    <!--accept="application/pdf,image/bmp,image/dcx,image/pcx,image/png,image/jpg,image/jpeg,image/jp2,image/jpc,image/jfif,image/tif,image/tiff,image/gif,image/djvu,image/djv,image/jb2,audio/mpeg,audio/wav,audio/ogg,video/webm,video/mp4,video/ogg,application/zip,application/vnd.rar,application/rar,.zip,.rar,.7zip"-->

                </div>
                <div class="col-sm-4">
                    <button type="button" class="btn btn-default" (click)="btnUploadBlob()" title="Replace File">
                        Replace File </button>
                </div>

            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" [attr.tabindex]="0" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    label="Cancel" (click)="btnCancelUpdateFileClick(containerForm)"></button>
            </div>
        </ng-template>
    </p-dialog>

    <!-- <p-dialog id="statusListFeed" *ngIf="displayFeedDialog && canViewFeedFromStatus" [positionTop]='5'
    [(visible)]="displayFeedDialog" showEffect="false" [modal]="true" [style]="{width: '100vw', height: '80vh'}"
    [resizable]="false">
    <ng-template pTemplate="header" >
        Feeds
    </ng-template>
    <list-datafeed *ngIf="displayFeedDialog && _displayMode!='status' && canViewFeedFromStatus" id="feedsPopup" [feedType]="feedType"
        [viewId]="ViewSettingId" [targetType]="targetType" [viewDataType]="" ></list-datafeed>
    </p-dialog> -->

    <!-- dialog for batch process -->
    <!-- need to modify below code according to above requirements -->
    <p-dialog *ngIf="showCopyMoveDlg" [(visible)]="showCopyMoveDlg" showEffect="fade" [modal]="true" [resizable]="false">
        <ng-template pTemplate="header" (keyup.shift.tab)="btnCloseDocumentsBatchDialog()">
            Copy/Move Documents Batch
        </ng-template>
        <div [attr.tabindex]="0">
            Processing {{selectedBatchSize}} documents in batch mode, do you want to continue?
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">

                <button autofocus="true" [attr.tabindex]="0" type="submit" pButton icon="fa fa-share"
                    title="Start Copy/Move Documents Batch." class="btn minzindex custom-button"
                    label="Start Copy/Move Document(s) Batch" (click)="btnOpenDocumentsBatchSkipLinkedDocsDlg()"
                    [disabled]="(canDetachMXR ==false && currentType=='mxr')|| (canDetachLog==false  && currentType=='log' )||(canDetachGen==false  && currentType=='gen')||(canDetachSearch==false  && currentType=='search')"></button>

                <button type="button" [attr.tabindex]="0" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    label="Cancel" (click)="btnCloseDocumentsBatchDialog()"></button>
            </div>
        </ng-template>
    </p-dialog>

    <!-- dialog for referenced documents should be processed or not? -->
    <!-- need to modify below code according to above requirements -->
    <p-dialog *ngIf="showCopyMoveSkipLinkedDocsDlg" [(visible)]="showCopyMoveSkipLinkedDocsDlg" showEffect="fade" [modal]="true" [resizable]="false">
        <ng-template pTemplate="header" (keyup.shift.tab)="btnCloseDocumentsBatchSkipLinkedDocsDlg()">
            Linked documents
        </ng-template>
        <div [attr.tabindex]="0">
            Do you want to Move linked documents?
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button autofocus="true" [attr.tabindex]="0" type="submit" pButton icon="fa fa-share"
                title="Ignore attached document(s) and move remaining." class="btn minzindex custom-button"
                label="Ignore Document(s)" (click)="btnProcessDocumentsBatch(true)"
                [disabled]="(canDetachMXR ==false && currentType=='mxr')|| (canDetachLog==false  && currentType=='log' )||(canDetachGen==false  && currentType=='gen')||(canDetachSearch==false  && currentType=='search')"></button>

                <button autofocus="true" [attr.tabindex]="0" type="submit" pButton icon="fa fa-pencil-square-o"
                    title="Update link(s) with new location and move" class="btn btn-success minzindex p-button-success"
                    label="Update Document(s)" (click)="btnProcessDocumentsBatch(false)"
                    [disabled]="(canDetachMXR ==false && currentType=='mxr')|| (canDetachLog==false  && currentType=='log' )||(canDetachGen==false  && currentType=='gen')||(canDetachSearch==false  && currentType=='search')"></button>

                <button type="button" [attr.tabindex]="0" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    label="Cancel" (click)="btnCloseDocumentsBatchSkipLinkedDocsDlg()"></button>
            </div>
        </ng-template>
    </p-dialog>

</article>