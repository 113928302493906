<div class="jarviswidget-ctrls" role="menu" *ngIf="canEditTask" >
    <a class="button-icon tablIndexFocus" id="btnEditTask" [attr.tabindex]="0" (keyup.enter)="showEditTask()" (click)="showEditTask()"
        title="Edit Entry (Ctrl+E)">
        <i class="glyphicon glyphicon-pencil"></i>
    </a>
</div>
 <div class="jarviswidget-ctrls" role="menu" *ngIf="canAddTask && isInternalView ">
    <a class="button-icon tablIndexFocus" id="btnAddTask" (click)="showAddTask()" [attr.tabindex]="0" (keyup.enter)="showAddTask()" title="Add Entry (Ctrl+A)">
        <i class="glyphicon glyphicon-plus"></i>
    </a>
</div>
<p-confirmDialog *ngIf="displayTaskDialog" header="Confirmation" key="confirmDlg" icon="fa fa-question-circle"
    [style]="{width: '450px'}"></p-confirmDialog>
<p-dialog *ngIf="displayConfirmDlg" [(visible)]="displayConfirmDlg"  showEffect="false" [modal]="true" [style]="{width: '600px'}"
    [resizable]="false">
    <ng-template pTemplate="header" >
        Confirmation
    </ng-template>
    <div>
        <span><i class="fa fa-question-circle"></i> Some entries already exist with this identification.</span>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="fa fa-history" style="height:27px;"
            title="Mark this entry as history and leave the existing latest entry." label="Mark this as History Record"
            (click)="addUpdateTask(2)"></button>
        <button type="button" pButton icon="fa fa-plus" style="height:27px;"
            title="Mark this entry as latest and change the existing latest entry to history."
            label="Mark this as Latest Record" (click)="addUpdateTask(1)"></button>
    </ng-template>
</p-dialog>
<form  *ngIf="displayTaskDialog" [formGroup]="tskForm" novalidate >
    <p-dialog [(visible)]="displayTaskDialog"  id="tskDlg" (onShow)="adjustTaskDlgHeight($event)"
        showEffect="fade" [modal]="true" [style]="{width: '700px'}" [resizable]="false">
        <ng-template pTemplate="header"   (keyup.shift.tab)="hideTaskPopup()">
            {{taskDlgTitle}} [{{asn}}]
        </ng-template>
        <!-- Modal  (onHide)="resetTskForm($event)" -->

        <div class="upload-dialog">
            <!--<div class="modal-body">-->
            <div class="smart-form" id="tskDlgfrm">
                <!--<div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">ASN</label>
                            <label class="input">
                                <input id="tskASN" type="text" name="tskASN" required class="input-sm" />
                            </label>
                        </fieldset>
                    </div>
                </div>-->
                <div class="row" *ngFor="let col of formColumns;trackBy:trackByIndex" id="taskformcontrols">
                    <!-- TaskID, FeedLogId, Comms-->
                    <input type="hidden" id="{{col.field}}" name="{{col.field}}" formControlName="{{col.field}}"
                        class="form-group col-sm-12"
                        *ngIf="col.field == 'TaskID' || col.field == 'Comms' ||  col.field == 'FeedLogId'">
                    <!-- DFP STatus--> 
                    <div class="form-group col-sm-6" *ngIf="col.field == 'DFPStatus'">
                        <fieldset>
                            <label class="label">{{col.Group? col.Group: ''}} {{col.headerName}}</label>
                            <!--<label class="input">-->
                              <!-- tabindex="{{trackBy}} +01"  remove from select  -->
                            <select  [autofocus]="true"  #dDf   [attr.tabindex]="0"  class="form-control" id="{{col.field}}" name="{{col.field}}"
                                formControlName="{{col.field}}">
                                <option *ngFor="let s of dfpStatusList;trackBy:trackByIndex" value="{{s.value}}">{{s.label}}</option>
                            </select>
                            <!-- <p-dropdown appendTo="body" [autofocus]="true"  #dDf   [attr.tabindex]="0" id="tskDFPStatus" name="tskDFPStatus" [options]="dfpStatusList" >
                                <ng-template let-selItem pTemplate="selectedItem">
                                    <div class="col-md-12" [ngStyle]="{'text-align': 'center', 'color': 'white','background-color': selItem.value}" ><span>{{selItem.label}}</span></div>
                                </ng-template>
                                <ng-template let-item pTemplate="item">
                                    <div style="position: relative;height: 14px;">
                                        <div class="col-md-12" [ngStyle]="{'text-align': 'center', 'color': 'white','background-color': selItem.value}"  ><span>{{item.label}}</span></div>
                                    </div>
                                </ng-template>
                            </p-dropdown> -->
                            <!--</label>-->
                        </fieldset>
                    </div>
                    <!-- Date Columns-->
                    <div class="form-group col-sm-6" *ngIf="col.field.indexOf('Date') >= 0 && col.field.indexOf('DateTime') == -1">                        <fieldset>
                            <label class="label">{{col.Group? col.Group: ''}} {{col.headerName}} ({{ViewDateFormat}})</label>
                            <label class="input" tabindex="{{trackBy}} +02">
                                <i tabindex="{{trackBy}} +02" class='icon-append fa fa-calendar'></i>
                                <!-- tabindex="{{trackBy}} +02"  remove from p-calendar -->
                                <p-calendar appendTo="body" placeholder="{{ViewDateFormat}}"  [attr.tabindex]="-1"   class='datepicker' id="tsk{{col.field}}" formControlName="{{col.field}}"
                                    [style]="{'width':'92%'}" name="tsk{{col.field}}" [monthNavigator]="true"
                                    [yearNavigator]="true" yearRange="1950:2030" [dateFormat]="dateFormatCalender">
                                </p-calendar>
                            </label>
                        </fieldset>
                    </div>
                    <!-- DateTime Columns-->
                    <div class="form-group col-sm-6" *ngIf="col.field.indexOf('DateTime') >= 0">
                        <fieldset>
                            <label class="label">{{col.Group? col.Group: ''}} {{col.headerName}} ({{ViewDateTimeFormat}})</label>
                            <label class="input" tabindex="{{trackBy}} +02">
                                <i tabindex="{{trackBy}} +02" class='icon-append fa fa-calendar'></i>
                                <p-calendar appendTo="body" placeholder="{{ViewDateTimeFormat}}"  [attr.tabindex]="-1"   class='datepicker' id="tsk{{col.field}}" formControlName="{{col.field}}"
                                    [style]="{'width':'92%'}" name="tsk{{col.field}}" [monthNavigator]="true"
                                    [yearNavigator]="true" [showTime]="true" hourFormat="24" yearRange="1950:2030" [dateFormat]="dateFormatCalender">
                                </p-calendar>
                            </label>
                        </fieldset>
                    </div>
                    <!-- TaskType Columns-->
                    <div class="form-group col-sm-12" *ngIf="col.field == 'TaskType'">
                        <fieldset>
                            <label class="label">{{col.Group? col.Group: ''}} {{col.headerName}}</label>
                            <label class="input">
                                <input tabindex="{{trackBy}} +03"  [attr.tabindex]="0"  id="tsk{{col.field}}" type="text" formControlName="{{col.field}}"
                                    name="tsk{{col.field}}" class="input-sm" />
                            </label>
                        </fieldset>
                    </div>
                    <!-- Number Columns-->
                    <div class="form-group col-sm-12" *ngIf="col.field.indexOf('Number') >= 0">
                        <fieldset>
                            <label class="label">{{col.Group? col.Group: ''}} {{col.headerName}}</label>
                            <label class="input">
                                <input tabindex="{{trackBy}} +04"  [attr.tabindex]="0"  id="tsk{{col.field}}" type="number" formControlName="{{col.field}}"
                                    name="tsk{{col.field}}" class="input-sm" />
                            </label>
                        </fieldset>
                    </div>
                    <!-- Text Columns-->
                    <div class="form-group col-sm-12"
                        *ngIf="col.field != 'TaskID' && col.field != 'FeedLogId' && col.field != 'Comms' && col.field != 'CommSummary' && col.field != 'TaskType' && col.field != 'DFPStatus' && col.field.indexOf('Date') == -1 && col.field.indexOf('Number') == -1 && col.field != 'rTask'">
                        <fieldset>
                            <label class="label">{{col.Group? col.Group: ''}} {{col.headerName}}</label>
                            <label class="textarea">
                                <textarea tabindex="{{trackBy}} +05"  [attr.tabindex]="0"  id="tsk{{col.field}}" name="tsk{{col.field}}" rows="2"
                                    style="overflow:hidden;" cols="100" formControlName="{{col.field}}" pInputTextarea
                                    autoResize="autoResize"></textarea>
                            </label>
                        </fieldset>
                    </div>
                </div>
            </div>

        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
               <p *ngIf="IsfooterText" class="pull-left CreatedbyBottomText">Created by  {{Createdby}}   on  {{CreatedOn}}   </p>
                <button tabindex="{{trackBy}} +06"  [attr.tabindex]="0"  type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex" label="Save"
                    (click)="addUpdateTask()" [disabled]="!isInternalView"></button>
                <button tabindex="{{trackBy}} +07"  [attr.tabindex]="0"  type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" label="Cancel"
                    (click)="hideTaskPopup()"   (focusout)="applyFocusToInput()"></button>
            </div>
        </ng-template>
    </p-dialog>
</form>
<!-- <p-dialog *ngIf="displayConfirmDlg" [(visible)]="displayConfirmDlg"  showEffect="false" [modal]="true" [style]="{width: '600px'}"
    [resizable]="false">
    <ng-template pTemplate="header" >
        Confirmation
    </ng-template>
    <div>
        <span><i class="fa fa-question-circle"></i> Some entries already exist with this identification.</span>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="fa fa-history" style="height:27px;"
            title="Mark this entry as history and leave the existing latest entry." label="Mark this as History Record"
            (click)="addUpdateTask(2)"></button>
        <button type="button" pButton icon="fa fa-plus" style="height:27px;"
            title="Mark this entry as latest and change the existing latest entry to history."
            label="Mark this as Latest Record" (click)="addUpdateTask(1)"></button>
    </ng-template>
</p-dialog> -->
