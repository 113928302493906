import { Component, Input, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef, Renderer2, RendererStyleFlags2, HostListener, ViewEncapsulation } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { ContainerService } from '../../services/container/container.service'
import { container } from '../../services/container/container'
import { Router } from '@angular/router';
import * as cloneDeep from 'lodash/cloneDeep';
import { GridOptions, CsvExportParams, ExcelExportParams, ExcelStyle, ExcelCell, RowDataTransaction, ChartType, ExcelRow } from 'ag-grid-community';


import { Subscription } from 'rxjs';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
import { IConfig } from '../../../shared/helpers/config';
import * as moment from 'moment/moment';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { APIService } from '../../../api/services/api.service';
import { datafeed } from '../../../api/components/list-datafeed/data-feed';
import { RecordConfig } from '../../../api/components/record-configuration/record-mapping';

import { CustomDateComponent } from '../../../shared/components/custom-date/custom-date.component';
import { ContainerQualityStatusService } from '../../../admin/services/containerqualitystatus/container-quality-status.service';
import 'ag-grid-enterprise';
import { ChannelService, ConnectionState, ChannelEvent } from 'src/app/shared/services/signalR/channel.Service';
import { ConfirmationService } from 'primeng/api';
import { DateContainer } from '../edit-container/edit-container';
var dateFormatF: string;
var dateSeparatorS: string;
var setRowSelection: string = 'single';
var dayD: number;
var monthM: number;
var yearY: number;
var _gridOptions: GridOptions;
var sharedService: SharedService;
var columnDefs_1: any[];
//var referenceKeyLabel: string = '';
var statusList: any[] = [];

@Component({
    selector: 'browse-container',
    templateUrl: './browse-container.component.html',
    styles: [
        `.ag-dialog
        {
        left: 5vw !important;
        width: 90vw !important;
        max-width: 90vw !important;
        height: 80vh !important;
        top: 10vh !important;
        max-height: 88vh !important;
        }
        `],
    encapsulation: ViewEncapsulation.None,
    providers: [ContainerService, APIService, ContainerQualityStatusService, ConfirmationService]
})
export class BrowseContainerComponent implements OnInit, OnDestroy {
    public displayMode: string;
    recordType: string;
    selectedAsset: any = null;
    subscription: Subscription;
    dialoguesubscription: Subscription;
    totalRecords: Number;

    selectedContainer: container[];
    selectedContainerRow: container = null;
    columnDefs: any[];
    chartColumnDefs: any[];
    tempCols: any[];
    public gridOptions: GridOptions;
    public chartGridOptions: GridOptions;
    public gridData: any[] = [];
    public chartGridData: any[] = [];
    public totalCount: number;
    public browseRowData: any;
    public dateConfig: IConfig[] = [];
    public isAddVisible: boolean = false;
    public isMoveContainerVisible: boolean = false;
    public isEditVisible: boolean = false;
    public isHideLiveRecords: number = 1;
    public isEditVisibleReadonly: boolean = false;
    public isViewJobsVisible: boolean = false;
    public hasCurrentCategoryAccess: boolean = false;
    canViewFeedFromStatus: boolean = false;
    //public excelStyles: ExcelStyle;
    public excelStyles: any;
    public isContainerFilterVisible: boolean = false;
    public isfocus: boolean = false;
    public displayConfigdialogue: boolean = false;
    public feedTypeInput: string = 'ExportTemp';
    public isBulkDownloadVisible: boolean = false;
    public isFilterCanceled: boolean = true;
    public displayJobsdialogue: boolean = false;
    public iconClass;
    public appHotkeys: Hotkey[] = [];
    public isFirstTime: boolean = false;


    public Container_MXR_Columns: any[] = [];
    public Container_LOG_Columns: any[] = [];
    public Container_GEN_Columns: any[] = [];
    public Container_DOC_Columns: any[] = [];
    public Container_BND_Columns: any[] = [];


    public Container_MXR_Sort_By: string = "";
    public Container_LOG_Sort_By: string = "";
    public Container_GEN_Sort_By: string = "";
    public Container_DOC_Sort_By: string = "";
    public Container_BND_Sort_By: string = "";


    qualityStatusList: any[] = [];

    public displayFeedDialog: boolean = false;
    public displayDateChangeDialog: boolean = false;
    public ViewSettingId: string = '0';
    public feedType: string = 'Import';
    public targetType = "Records";

    public isContainerSidebarVisible: boolean = false;
    public iconColorContainerSidebar: string = "black";
    public containerSideBarTitleValue: string = "Side Bar Disabled";
    public sideBar;
    public getRowNodeId;
    componentName = 'MXRC';
    private ChannelSubscription: Subscription;
    private channel = "RecordGapanalyser";
    public DateChange: DateContainer;

    gridIcon() {
        if (this._router.url == '/record/browse') {
            setRowSelection = 'multiple';
            this.iconClass = 'fa fa-files-o'
            this.componentName = 'RECC'
        }
        else if (this._router.url == '/record/logs') {
            setRowSelection = 'single';
            this.iconClass = 'fa fa-book';
            this.componentName = 'RECC'
        }
        else if (this._router.url == '/record/general') {
            setRowSelection = 'single';
            this.iconClass = 'fa fa-certificate'
            this.componentName = 'RECC'
        }
        else if (this._router.url == '/record/documents') {
            setRowSelection = 'single';
            this.iconClass = 'fa fa-newspaper-o'
            this.componentName = 'RECC'
        }
        else if (this._router.url == '/transition/binders') {
            setRowSelection = 'single';
            this.iconClass = 'fa fa-folder-open'
            this.componentName = 'TRAC'
        }
    }

    constructor(private elRef: ElementRef, private renderer: Renderer2, private channelService: ChannelService, private confirmationService: ConfirmationService, private _sharedService: SharedService, private _containerService: ContainerService, private _router: Router, private _hotkeysService: HotkeysService, private _apiService: APIService, private _qualityStatusService: ContainerQualityStatusService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        } else {
            this.isFirstTime = true;
            this.isEditVisible = true;//container quality status to be editable
            sharedService = this._sharedService;
            this.dateConfig = this._sharedService.getConfiguration();
            //Bug 8402: Binders: Filter on container not working properly
            if (this._router.url.indexOf('/record/') != -1 && this._sharedService.navigationInfo && this._sharedService.navigationInfo.cat.toLowerCase() == 'binders') {
                this._sharedService.navigationInfo = null;
            }
            //For Navigation to Record
            if (this._router.url.indexOf('/record/') != -1 && this._sharedService.navigationInfo && this._sharedService.navigationInfo.isNavigate) {
                let urlToNavigate = '/record/' + this._sharedService.navigationInfo.cat;
                if (this._sharedService.navigationInfo.cat != 'mxr' && this._router.url != urlToNavigate) {
                    this._router.navigateByUrl(urlToNavigate);
                }
            }
            if (this._router.url.indexOf('/record/') != -1 && this._sharedService.navigationInfoDashboard && this._sharedService.navigationInfoDashboard.isNavigate) {
                let urlToNavigate = '/record/' + this._sharedService.navigationInfoDashboard.cat;
                if (this._sharedService.navigationInfoDashboard.cat != 'mxr' && this._router.url != urlToNavigate) {
                    this._router.navigateByUrl(urlToNavigate);

                }
            }

            this.setHotKeys();
            this.getRowNodeId = (params: any) => {
                return params.data.ContainerID;
            };

        }
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize(event: Event) {
        this.calculateGridHeight();
    }
    private removeDivHeight() {
        const contentElement = this.elRef.nativeElement.querySelector('#containerGrid');
        this.renderer.removeStyle(contentElement, 'height');
    }

    setHotKeys() {

        let hotKeyObj: Hotkey = null;

        //Export CSV
        hotKeyObj = new Hotkey('ctrl+x', (event: KeyboardEvent): boolean => {
            this.exportGridData();
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

        //Export Excel
        hotKeyObj = new Hotkey('ctrl+z', (event: KeyboardEvent): boolean => {
            this.exportExcelGrid();
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

    }

    ngOnInit() {
        this.isFirstTime = true;
        this.gridIcon();
        this.channelService.startHubConnection();
        this.sideBar = {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressSideButtons: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
            defaultToolPanel: 'columns',
        };

        this.prepareBrowseGridColumns();
        this.populateQualityStatus();
        this.setDisplayMode();
        this.cleanUpActiveMenu();
        this.gridOptions = <GridOptions>
            {
                rowSelection: setRowSelection,//'multiple',//'single ',
                rowData: [],
                onGridReady: () => {
                    if (this._sharedService.parentMenuClicked) {
                        this._sharedService.showHideRecordMenu();
                        this._sharedService.parentMenuClicked = false;
                    }
                    this.setBrowseGridColumns();
                    if (this.gridOptions.api != undefined) {
                        if (this.gridData.length > 0) { this.gridOptions.api.hideOverlay(); }
                    }

                    this.gridOptions.api.setSideBarVisible(false);
                    this.iconColorContainerSidebar = "black";
                    this.isContainerSidebarVisible = false;
                    this.containerSideBarTitleValue = "Side Bar Disabled";
                },
                onModelUpdated: (event: any) => {
                    try {
                        this.totalCount = this.gridOptions.api.getDisplayedRowCount();
                    } catch (e) {
                        console.log(e);
                    }
                },
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    filterParams: { defaultToNothingSelected: true }
                },
                components: { agDateInput: CustomDateComponent },
                getContextMenuItems: this.getCustomContextMenuItems,
                context: {
                    gridContext: this
                },
                onCellValueChanged: this.updateContainerQualityStatus,
                singleClickEdit: true
            }
        this.chartGridOptions = <GridOptions>
            {
                onGridReady: () => {
                },
                context: {
                    gridContextChart: this
                }
            }
        _gridOptions = this.gridOptions;
        //this.checkUserRoles();
        ////31-08-2021 console.log("ngOnInit - browse-container");this.UpdateRoles();31-08-2021 Bug 8953: Remaining issues(2)
        if (this._sharedService.selectedAsset && this._sharedService.selectedAsset.length > 1) {
            this._sharedService.resetAssetControl(true);

            //R.A/ 25-06-2021-New Added
            // this._sharedService.onAssetStatusChanged(this._sharedService.selectedAsset);
            // this._sharedService.onAssetChanged(this._sharedService.selectedAsset);
        }

        this.subscription = this._sharedService._selectedAsset
            .subscribe({
                next: (selAsset: any) => {
                    if (selAsset) {
                        this._sharedService.isNavigationFilterCanceled = false;
                        this.UpdateRoles();
                        this.gridIcon();
                        if (this._sharedService.isCurrentAssetReadOnly()) {
                            this.setBrowseGridColumns();
                        }
                        this.reBindGrid(selAsset);
                        this._sharedService.setBrowseDocumentHeight();
                    }
                },
                error: (error) => {
                }
            });
        if (this._sharedService.selectedAsset && this._sharedService.selectedAsset.length) {
            this.reBindGrid(this._sharedService.selectedAsset);
            this.warningforPlan();

        }

        this._sharedService.setAssetControlVisible(true);

        this.getDateFormatInfo();
        try {
            this.recordType = this._sharedService.recordType ? this._sharedService.recordType.toUpperCase() : this.recordType;
        } catch (e) {
            console.log(e);
        }
        this.setExcelStyle();
        this.joinHubChannel();
    }
    private joinHubChannel(): void {
        try {

            // Get an observable for events emitted on this channel
            //
            //

            this.ChannelSubscription = this.channelService.sub(this._sharedService.getGroupNameKey(this.channel))
                .subscribe({
                    next: (x: ChannelEvent) => {
                        switch (x.ChannelName) {
                            case this._sharedService.getGroupNameKey(this.channel): { this.updateContainerStatus(x); }
                        }
                    },
                    error: (error) => {
                        console.warn("Attempt to join channel failed!", error);
                    }
                });
        }
        catch (ex) {
            console.log(ex.error)
        }
    }

    private updateContainerStatus(ev: ChannelEvent): void {
        // Just prepend this to the messages string shown in the textarea
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
            return;
        }
        let date = new Date();
        // this._sharedService.setViewTaskProgress(ev.Data);
        switch (ev.Data.State) {
            case "Starting":
            case "starting": {
                // console.log(`${date.toLocaleTimeString()} : starting\n`);
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: ev.Data.Message });
                break;
            }
            case "complete": {
                //  console.log(`${date.toLocaleTimeString()} : complete\n`);
                break;
            }
            case "Complete": {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: ev.Data.Message });
                this._sharedService.recordType = this._sharedService.recordType.toUpperCase();
                this.populateContainerGrid();
                // if (this._sharedService.recordType == 'MXR') {
                //     this.confirmationService.confirm({
                //         key: "confirmDlg",
                //         message: "Data has been changed, do you want to refresh screen to update data?",
                //         accept: () => {
                //             this.populateContainerGrid();
                //         },
                //         reject: () => {
                //             return;
                //         }
                //     });
                // }
                break;
            }
            case "Error":
            case "error": {
                console.log(`${date.toLocaleTimeString()} : complete\n`);
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Success', detail: ev.Data.Message });
                break;
            }
            default: {
                // console.log(`${date.toLocaleTimeString()} : ${ev.Data.Message} : ${ev.Data} % complete\n`);
            }
        }
    }

    warningforPlan(): void {
        try {
            this._sharedService.clearToast();
            this._sharedService.recordType = this._sharedService.recordType.toUpperCase();// ? this._sharedService.recordType.toUpperCase() : this._sharedService.recordType;
            if ((this._sharedService.recordType == 'MXR' && this._sharedService.UserRole.Records_MXR_Readonly)
                || (this._sharedService.recordType == 'LOG' && this._sharedService.UserRole.Records_LOG_Readonly)
                || (this._sharedService.recordType == 'GEN' && this._sharedService.UserRole.Records_GEN_Readonly)
                || (this._sharedService.recordType == 'DOC' && this._sharedService.UserRole.Records_DOC_Readonly)
                || (this._sharedService.recordType == 'BND' && this._sharedService.UserRole.Transition_BND_Readonly)
            ) { }
            else {
                let assetDataObj = JSON.parse(this._sharedService.selectedAsset[0].data);
                var notAllowedMsg = 'This asset is on ' + assetDataObj.PlanName + ', please contact administrator to upgrade plan for this asset.';//PlanName
                //this._sharedService.showToast({ severity: 'warn', summary: 'Access denied', detail: notAllowedMsg });
                setTimeout(() => {
                    this._sharedService.showToast({ severity: 'warn', summary: 'Access denied', detail: notAllowedMsg });
                    console.log("Delayed browse-container.");
                }, 500);

            }
        } catch (e) {
            console.log(e);
        }
    }
    updateContainerQualityStatus(event: any) {
        //if (event.colDef.field == 'QualityStatus---ID') { //24-02-2021

        if (event.colDef.field == 'QualityStatus') {

            //let qualityStatusId = event.context.gridContext.qualityStatusList.find(x => x.Name == event.data.QualityStatusID).QualityStatusID;
            let selectedQualityStatusObj = event.context.gridContext.qualityStatusList.find(x => x.Name == event.newValue);
            let qualityStatusId = selectedQualityStatusObj.QualityStatusID;
            let qualityStatus = selectedQualityStatusObj.Name;

            if (event.oldValue != qualityStatus) {
                try {
                    //ContainerQualityStatus
                    //US-8181 //let asset = event.context.gridContext._sharedService.selectedAsset[0].data.split('|');
                    let assetDataObj = JSON.parse(event.context.gridContext._sharedService.selectedAsset[0].data);
                    let body: any = {
                        ContainerName: assetDataObj.StoragePath,//asset[0],
                        ASN: event.context.gridContext._sharedService.selectedContainer.ASN,
                        Type: event.context.gridContext._sharedService.selectedContainer.Type,
                        ID: event.context.gridContext._sharedService.selectedContainer.ID,
                        QualityStatusID: qualityStatusId,
                        //Url: event.context.gridContext.extractBlobReferenceFromURL()
                    };

                    let selectedRow = event.context.gridContext.gridData.find(c => c.ContainerID == event.data.ContainerID);
                    selectedRow.QualityStatusID = qualityStatusId;
                    selectedRow.ContainerQualityStatus = event.newValue;
                    event.context.gridContext.gridOptions.api.applyTransaction(event.context.gridContext.gridData as RowDataTransaction);
                    event.context.gridContext.gridOptions.api.refreshCells({ force: true });

                    event.context.gridContext._containerService.UpdateContainerQualityStatus(body)
                        .subscribe({
                            next: (response) => {
                                //event.context.gridContext._sharedService.onAssetChanged(event.context.gridContext._sharedService.selectedAsset);
                                event.context.gridContext._sharedService.clearToast();
                                event.context.gridContext._sharedService.showToast({ severity: 'success', summary: 'Container updated', detail: 'Container quality status has been updated successfully.' });
                            },
                            error: (error) => {
                                event.context.gridContext._sharedService.clearToast();
                                event.context.gridContext._sharedService.showToast({ severity: 'error', summary: 'Container not updated', detail: 'Unable to update container quality status' });
                            }
                        });

                } catch (error) {
                    console.log(error);
                }
            }
        }
    }

    ngOnDestroy() {
        //this._sharedService._selectedAsset.unsubscribe();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.dialoguesubscription) {
            this.dialoguesubscription.unsubscribe();
        }
        if (this.isContainerFilterVisible) {
            this._sharedService.navigationInfo = null;
        }
        this.appHotkeys.forEach(hotkey => {
            this._hotkeysService.remove(hotkey);
        });
        if (this.ChannelSubscription) {
            this.ChannelSubscription.unsubscribe();
        }
        this.appHotkeys = [];
        if (!this._sharedService.prevURL.includes("/record"))
            this._sharedService.navigationInfoDashboard = null;

        // this.removeDivHeight()
    }

    //#region Grid Row Events
    private selectedRowNode;
    // private selectedRowIndex;
    updateSelectedContainer(event: any) {
        var selectedRows = this.gridOptions.api.getSelectedRows();

        //US-8181 //let assetInfo: string[] = this.selectedAsset[0].data.split('|');
        let assetDataObj = JSON.parse(this._sharedService.selectedAsset[0].data);
        if (selectedRows.length == 1) {
            // this.selectedRowIndex = this.gridData.findIndex(x=>x.ContainerID==selectedRows[0].ContainerID);

            if (this.gridData.length > 1) {
                // this.selectedRowIndex = this.gridData.findIndex(x=>x.ContainerID==selectedRows[0].ContainerID);
                this.selectedRowNode = this.gridOptions.api.getRowNode(selectedRows[0].ContainerID);
            }
            else {
                this.selectedRowNode = null;
                // this.selectedRowIndex=null;
            }
            this._sharedService.onMultiContainerSelection(selectedRows);
            this._sharedService.onBrowseContainerSelection(selectedRows[0])//event.data);
            //this._sharedService.hideLoader();
        } else if (selectedRows.length > 1) {
            for (let i = 0; i < selectedRows.length; i++) {
                selectedRows[i].ContainerName = assetDataObj.StoragePath;// assetInfo[0];
            }
            this.selectedContainer = selectedRows;
            this._sharedService.onBrowseContainerSelection(selectedRows);
            this._sharedService.onMultiContainerSelection(selectedRows);
        }
        else {
            this.selectedContainer = event.data;
        }
        this.selectedContainerRow = event.data;

    }

    navigateToNextCell(params: any) {
        var previousCell = params.previousCellPosition;
        // var previousCell = params.previousCellDef;
        var suggestedNextCell = params.nextCellPosition;
        // var suggestedNextCell = params.nextCellDef;

        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';

        switch (params.key) {
            case KEY_DOWN:
                // previousCell = params.previousCellDef;
                // // set selected cell on current cell + 1
                // _gridOptions.api.forEachNode((node) => {
                //     if (previousCell.rowIndex + 1 === node.rowIndex) {
                //         node.setSelected(true);
                //         this._sharedService.onBrowseContainerSelection(node.data);
                //     }
                // });
                // return suggestedNextCell;

                var nextRowIndex = previousCell.rowIndex + 1;
                var renderedRowCount = _gridOptions.api.getModel().getRowCount();
                if (nextRowIndex >= renderedRowCount) {
                    return null;
                } else {
                    _gridOptions.api.deselectAll();
                    var rowNode = _gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
                    rowNode.setSelected(true);
                    sharedService.onBrowseContainerSelection(rowNode.data);
                    return {
                        rowIndex: nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }


            case KEY_UP:
                // previousCell = params.previousCellDef;
                // // set selected cell on current cell - 1
                // _gridOptions.api.forEachNode((node) => {
                //     if (previousCell.rowIndex - 1 === node.rowIndex) {
                //         node.setSelected(true);
                //         this._sharedService.onBrowseContainerSelection(node.data);
                //     }
                // });
                // return suggestedNextCell;

                var _nextRowIndex = previousCell.rowIndex - 1;
                if (_nextRowIndex < 0) {
                    return null;
                } else {
                    _gridOptions.api.deselectAll();
                    var rowNode = _gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
                    rowNode.setSelected(true);
                    sharedService.onBrowseContainerSelection(rowNode.data);
                    return {
                        rowIndex: _nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw 'this will never happen, navigation is always on of the 4 keys above';
        }
    }

    containerRowDoubleClicked(event: any) {
        if (this.isEditVisible || this.isEditVisibleReadonly) {
            this._sharedService.updateEditContainer(null);
        }
    }

    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            // let dateString = cellValue.substring(0, 10).split(dateSeparatorS);
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateFormatF.toUpperCase()).split(dateSeparatorS);
            var day = Number(dateParts[Number(dayD)].substring(0, 2));
            var month = Number(dateParts[Number(monthM)]) - 1;
            var year = Number(dateParts[Number(yearY)]);
            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        } else {
            return -1;
        }
    }
    //#endregion


    populateQualityStatus() {
        let qualityStatusListValues = this._sharedService.getContainerQualityStatusList();
        if (qualityStatusListValues == null || qualityStatusListValues.length == 0) {
            this._qualityStatusService.getActiveQualityStatusData(null)
                .subscribe({
                    next: (response) => {
                        try {
                            this.qualityStatusList = response;
                            statusList = response;
                        } catch (ex) {
                            this.qualityStatusList = response;
                            statusList = response;
                            console.log(ex);
                        }
                    },
                    error: (error) => {
                    }
                });
        } else {
            this.qualityStatusList = qualityStatusListValues;
            statusList = qualityStatusListValues;
        }
    }
    statusCellRenderer(params: any) {
        let html: string = '';
        var eDiv = document.createElement('div');
        if (params.value) {
            try {
                let currentStatus: any = statusList.filter(x => x.QualityStatusID == params.value)[0];
                if (currentStatus && currentStatus.Name) {
                    eDiv.style.background = currentStatus.CSSClass;
                    html = html + currentStatus.Name;
                } else {
                    let currentStatus: any = statusList.filter(x => x.Name == params.value)[0];//params.value)[0];
                    if (currentStatus && currentStatus.Name) {
                        eDiv.style.background = currentStatus.CSSClass;
                        html = html + currentStatus.Name;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            return params.value;
        }


        eDiv.style.height = '100%';
        eDiv.style.width = '100%';
        eDiv.style.color = 'white';
        eDiv.style.textAlign = 'left';
        eDiv.style.paddingLeft = '5px';
        eDiv.innerHTML = html;
        return eDiv;
    }
    reBindGrid(selAsset: any) {
        //this._sharedService.showLoader();
        this.isContainerFilterVisible = false;
        this.selectedAsset = selAsset;
        this.populateContainerGrid();
        if (this.gridOptions.api)
            this._sharedService.onMultiContainerSelection(this.gridOptions.api.getSelectedRows());
        //var abc=  document.querySelector('#agGridBrowse')//.style.height = '400px';

    }

    //#region Ag Grid Population Event
    populateContainerGrid(): void {
        try {
            this._sharedService.clearToast();
            this._sharedService.recordType = this._sharedService.recordType.toUpperCase();// ? this._sharedService.recordType.toUpperCase() : this._sharedService.recordType;
            if ((this._sharedService.recordType == 'MXR' && this._sharedService.UserRole.Records_MXR_Readonly)
                || (this._sharedService.recordType == 'LOG' && this._sharedService.UserRole.Records_LOG_Readonly)
                || (this._sharedService.recordType == 'GEN' && this._sharedService.UserRole.Records_GEN_Readonly)
                || (this._sharedService.recordType == 'DOC' && this._sharedService.UserRole.Records_DOC_Readonly)
                || (this._sharedService.recordType == 'BND' && this._sharedService.UserRole.Transition_BND_Readonly)
            ) {
                if (this.selectedAsset && this.selectedAsset.length) {
                    let assetDataInfo = JSON.parse(this.selectedAsset[0].data);//this._sharedService.selectedAsset[0].data ? JSON.parse(this._sharedService.selectedAsset[0].data) : JSON.parse(this._sharedService.selectedAsset[0].data);
                    //US-8181 ////let assetInfo: string[] = this.selectedAsset[0].data.split('|');
                    let hasAccess: boolean = this.HasAccessToCategoryInCurrenAsset();
                    this.canViewFeedFromStatus = this._sharedService.UserRole.API_Import_Readonly;
                    //re-updating few more properties
                    switch (this._sharedService.recordType.toUpperCase()) {
                        case 'MXR':
                            this.isAddVisible = this._sharedService.UserRole.Records_MXR_Con_Add;
                            this.isMoveContainerVisible = this._sharedService.UserRole.Records_MXR_Move_Container;
                            this.isEditVisible = this._sharedService.UserRole.Records_MXR_Container_Quality_Status;
                            this.isHideLiveRecords = this._sharedService.UserRole.Records_MXR_Con_Admin == true ? 0 : 1;
                            this.isEditVisibleReadonly = this._sharedService.UserRole.Records_MXR_Readonly;
                            break;
                        case 'LOG':
                            this.isAddVisible = this._sharedService.UserRole.Records_LOG_Con_Add;
                            this.isMoveContainerVisible = this._sharedService.UserRole.Records_LOG_Move_Container;
                            this.isEditVisible = this._sharedService.UserRole.Records_LOG_Container_Quality_Status;
                            this.isHideLiveRecords = this._sharedService.UserRole.Records_LOG_Con_Admin == true ? 0 : 1;
                            this.isEditVisibleReadonly = this._sharedService.UserRole.Records_LOG_Readonly;
                            break;
                        case 'GEN':
                            this.isAddVisible = this._sharedService.UserRole.Records_GEN_Con_Add;
                            this.isMoveContainerVisible = this._sharedService.UserRole.Records_GEN_Move_Container;
                            this.isEditVisible = this._sharedService.UserRole.Records_GEN_Container_Quality_Status;
                            this.isHideLiveRecords = this._sharedService.UserRole.Records_GEN_Con_Admin == true ? 0 : 1;
                            this.isEditVisibleReadonly = this._sharedService.UserRole.Records_GEN_Readonly;
                            break;
                        case 'DOC':
                            this.isAddVisible = this._sharedService.UserRole.Records_DOC_Con_Add;
                            this.isMoveContainerVisible = this._sharedService.UserRole.Records_DOC_Move_Container;
                            this.isEditVisible = this._sharedService.UserRole.Records_DOC_Container_Quality_Status;
                            this.isHideLiveRecords = this._sharedService.UserRole.Records_DOC_Con_Admin == true ? 0 : 1;
                            this.isEditVisibleReadonly = this._sharedService.UserRole.Records_DOC_Readonly;
                            break;
                        case 'BND':
                            this.isAddVisible = this._sharedService.UserRole.Transition_BND_Con_Add;
                            this.isMoveContainerVisible = this._sharedService.UserRole.Records_BND_Move_Container;
                            this.isEditVisible = this._sharedService.UserRole.Transition_BND_Container_Quality_Status;
                            this.isHideLiveRecords = this._sharedService.UserRole.Transition_BND_Con_Admin == true ? 0 : 1;
                            this.isEditVisibleReadonly = this._sharedService.UserRole.Transition_BND_Readonly;
                            break;
                    }
                    this.isViewJobsVisible = this._sharedService.UserRole.DP_Jobs_Readonly;
                    if (hasAccess) {
                        let body: container = {
                            Category: this._sharedService.recordType,
                            ASN: assetDataInfo.ASN,// assetInfo[1],
                            ContainerName: assetDataInfo.StoragePath,//assetInfo[0],
                            Live: this.isHideLiveRecords
                        };

                        this._containerService.getContainerData(body)
                            .subscribe({
                                next: (response) => {
                                    this.gridData = [];
                                    //For Navigation to Containter From Dashboard
                                    if (this._router.url.indexOf('/record/') != -1 && this._sharedService.navigationInfoDashboard
                                        && this._sharedService.navigationInfoDashboard.isNavigate && this._sharedService.isNavigationFilterCanceled) { /*&& this.isFilterCanceled*/
                                        //when it comes here?
                                        let containers: any[] = response;
                                        this.isContainerFilterVisible = true;
                                        //this.isFilterCanceled = false;
                                        //this._sharedService.isNavigationFilterCanceled = false;
                                        if (this._sharedService.navigationInfoDashboard.containerType) {
                                            this.gridData = containers.filter(t => t.Type == this._sharedService.navigationInfoDashboard.containerType);
                                        } else {
                                            this.gridData = containers.filter(t => t.ContainerID == this._sharedService.navigationInfoDashboard.id);
                                        }

                                        //this.totalCount = this.gridData.length;
                                        this._sharedService.setAssetControlTextForDashboard(this._sharedService.selectedAsset);
                                    }
                                    else if (this._router.url.indexOf('/transition/') != -1 && this._sharedService.navigationInfoDashboard
                                        && this._sharedService.navigationInfoDashboard.isNavigate && this._sharedService.isNavigationFilterCanceled) { /*&& this.isFilterCanceled*/
                                        //when it comes here?
                                        let containers: any[] = response;
                                        this.isContainerFilterVisible = true;
                                        //this.isFilterCanceled = false;
                                        //this._sharedService.isNavigationFilterCanceled = false;
                                        if (this._sharedService.navigationInfoDashboard.containerType) {
                                            this.gridData = containers.filter(t => t.Type == this._sharedService.navigationInfoDashboard.containerType);
                                        } else {
                                            this.gridData = containers.filter(t => t.ContainerID == this._sharedService.navigationInfoDashboard.id);
                                        }

                                        //this.totalCount = this.gridData.length;
                                        this._sharedService.setAssetControlTextForDashboard(this._sharedService.selectedAsset);
                                    } else if (this._router.url.indexOf('/record/') != -1 && this._sharedService.navigationInfo
                                        && this._sharedService.navigationInfo.isNavigate && this._sharedService.isNavigationFilterCanceled) {/*&& this.isFilterCanceled*/
                                        let containers: any[] = response;
                                        //this.isFilterCanceled = false;
                                        //this._sharedService.isNavigationFilterCanceled = false;
                                        this.isContainerFilterVisible = true;
                                        this.gridData = containers.filter(t => t.ContainerID == this._sharedService.navigationInfo.id);
                                        this.totalCount = this.gridData.length;
                                    }
                                    else if (this._router.url.indexOf('/transition/') != -1 && this._sharedService.navigationInfo
                                        && this._sharedService.navigationInfo.isNavigate && this._sharedService.isNavigationFilterCanceled) {/*&& this.isFilterCanceled*/
                                        let containers: any[] = response;
                                        //this.isFilterCanceled = false;
                                        //this._sharedService.isNavigationFilterCanceled = false;
                                        this.isContainerFilterVisible = true;
                                        this.gridData = containers.filter(t => t.ContainerID == this._sharedService.navigationInfo.id);
                                        this.totalCount = this.gridData.length;
                                    } else if ((this._router.url.indexOf('/status/') != -1 || this._router.url.indexOf('/repair/') != -1) && this._sharedService.navigationInfoStatusPopup && this._sharedService.navigationInfoStatusPopup.isNavigate && this._sharedService.isNavigationFilterCanceled) {/*&& this.isFilterCanceled*/
                                        let containers: any[] = response;
                                        //this.isFilterCanceled = false;
                                        //this._sharedService.isNavigationFilterCanceled = false;
                                        this.isContainerFilterVisible = true;
                                        if (this._sharedService.navigationInfoStatusPopup.id.indexOf('|') == -1) {
                                            this.gridData = containers.filter(t => t.ContainerID == this._sharedService.navigationInfoStatusPopup.id);
                                        } else {
                                            let containerDetail = this._sharedService.navigationInfoStatusPopup.id.split('|');

                                            this.gridData = containers.filter(t => t.Type == containerDetail[0]
                                                && t.Category == containerDetail[1]
                                                && this._sharedService.getDateInUserFormat(t.Date) == containerDetail[2]
                                                && this._sharedService.getDateInUserFormat(t.EndDate) == containerDetail[3]
                                                && t.ReferenceKey == containerDetail[4]
                                                && t.ASN == containerDetail[5]);
                                        }

                                        this.totalCount = this.gridData.length;
                                    } else {
                                        this.isContainerFilterVisible = false;
                                        //this._sharedService.isNavigationFilterCanceled = false;
                                        //this.isFilterCanceled = false;
                                        if (this._sharedService.isStatusAttachDocDialogueOpen) {
                                            this.setBrowseGridColumns();
                                        }
                                        this.gridData = response;
                                        if (this._sharedService.isChangeSelectedContainer) {
                                            let selectedContainer = this.gridData.find(x => x.ContainerID == this._sharedService.selectedContainer.ContainerID)
                                            this._sharedService.onMultiContainerSelection(selectedContainer);
                                            this._sharedService.onBrowseContainerSelection(selectedContainer)
                                            this._sharedService.isChangeSelectedContainer = false;
                                        }
                                        setTimeout(() => {
                                            if (this.gridOptions.api) {
                                                this.gridOptions.api.redrawRows();
                                            }

                                        }, 500);
                                        this.totalCount = this.gridData.length;
                                    }
                                    this.isFirstTime = true;
                                    this.selectFirstRow();
                                    //this._sharedService.hideLoader();
                                    this.calculateGridHeight()
                                },
                                error: (error) => {
                                    console.log(error);
                                }
                            });
                    }
                    else {
                        // to show waring No Asset Selected
                        this.gridData = [];
                        var _categoryName = this.getCategoryName(this._sharedService.recordType);

                        // var notAllowedMsg = 'Category ' + _categoryName + ' is not active, please contact administrator.';
                        // this._sharedService.isNavigationFilterCanceled = false;
                        // setTimeout(() => {
                        //     this._sharedService.showToast({ severity: 'error', summary: 'Access denied', detail: notAllowedMsg });
                        // }, 500);
                    }
                } else {
                    // to show waring No Asset Selected
                    this.gridData = null;
                    this._sharedService.onBrowseContainerSelection(this.gridData)
                    this.selectedAsset = null;
                }
            }
        } catch (e) {
            console.log(e);
        }
    }
    getCategoryName(recordType: string): string {
        var _catName = "";
        switch (recordType.toUpperCase()) {
            case 'MXR':
                _catName = "MX Records";
                break;
            case 'LOG':
                _catName = "Log Books";
                break;
            case 'GEN':
                _catName = "General";
                break;
            case 'DOC':
                _catName = "Documents";
                break;
            case 'BND':
                _catName = "Binders";
                break;
        }
        return _catName;
    }

    cleanUpActiveMenu() {
        let headerEl = document.querySelectorAll(`a.active`);
        if (headerEl) {
            headerEl.forEach(ele => {
                if (ele.getAttribute('href') != this._router.url)
                    ele.classList.remove('active');
            });

        }
    }

    enableDisableContainerGridSideBar() {

        if (this.isContainerSidebarVisible) {
            this.gridOptions.api.setSideBarVisible(false);
            this.iconColorContainerSidebar = "black";
            this.isContainerSidebarVisible = false;
            this.containerSideBarTitleValue = "Side Bar Disabled";
        } else {
            this.gridOptions.api.setSideBarVisible(true);
            this.iconColorContainerSidebar = "red";
            this.isContainerSidebarVisible = true;
            this.containerSideBarTitleValue = "Side Bar Enabled";
        }
    }

    selectFirstRow(event?: any) {
        //this.setGridColumnWidth();
        if (this._sharedService.containerSelectFirstRow) {

            if (this.gridOptions.api) {
                if (this.gridData.length > 0 && this.gridData.length) {
                    this.gridOptions.api.deselectAll();
                    // const rowNode = this.gridOptions.api.getRowNode(selRows[0].ContainerID);
                    // selRows = this.gridOptions.api.getSelectedRows();
                    // if((this.selectedRowNode.rowIndex)==this.gridData.length)
                    // {
                    //     let indexData = this.gridOptions.api.getDisplayedRowAtIndex(this.selectedRowNode.rowIndex-1)
                    //     const rowNode = this.gridOptions.api.getRowNode(indexData.id);
                    //     rowNode.setSelected(true);
                    //     this._sharedService.onBrowseContainerSelection(indexData.data);
                    // }
                    // else {
                    let indexData
                    if ((this.selectedRowNode.rowIndex - 1) == -1) {
                        indexData = this.gridOptions.api.getDisplayedRowAtIndex(1)

                    }
                    else {
                        indexData = this.gridOptions.api.getDisplayedRowAtIndex(this.selectedRowNode.rowIndex - 1)
                    }
                    const rowNode = this.gridOptions.api.getRowNode(indexData.id);
                    rowNode.setSelected(true);
                    this.selectedRowNode = indexData;
                    this._sharedService.onBrowseContainerSelection(indexData.data);
                    this.isFirstTime = false;
                    // }
                }
                else {
                    this._sharedService.onBrowseContainerSelection(null)
                }
                this._sharedService.containerSelectFirstRow = false;
            }
        }

        if (this.isFirstTime) {
            if ((event && event.rowData && event.rowData.currentValue.length > 0)) {
                this.isFirstTime = false;
            }
            let selRows = [];
            if (this.gridOptions.api) {
                selRows = this.gridOptions.api.getSelectedRows();
                if (selRows && selRows.length > 1 && this.gridData.length > 0 && this.gridData.length) {
                    // this.gridOptions.api.deselectAll();
                    // const rowNode = this.gridOptions.api.getRowNode(selRows[0].ContainerID);

                    // // do something with the row, e.g. select it
                    // rowNode.setSelected(true);
                    // // selRows = this.gridOptions.api.getSelectedRows();
                    // this._sharedService.onBrowseContainerSelection(selRows[0]);
                }

                else if (this.gridData.length && (selRows.length == 1 || selRows.length == 0)) {
                    this.gridOptions.api.deselectAll();
                    if (selRows.length == 0 && this.gridOptions.api.getDisplayedRowAtIndex(0) != null) {
                        this.gridOptions.api.getDisplayedRowAtIndex(0).setSelected(true);
                        let selRow = this.gridOptions.api.getSelectedRows();
                        this._sharedService.onBrowseContainerSelection(selRow[0]);
                    }
                    //this.gridOptions.api.deselectAll();
                    if (selRows.length == 1) {
                        const rowNode = this.gridOptions.api.getRowNode(selRows[0].ContainerID);
                        rowNode.setSelected(true);
                        this._sharedService.onBrowseContainerSelection(selRows[0]);
                    }

                } else {
                    this._sharedService.onBrowseContainerSelection(this.gridData);

                }
            }
        }
    }
    //#endregion

    prepareBrowseGridColumns(): void {

        var appConfigs = this._sharedService.getConfiguration();
        this.appConfigs = appConfigs;
        if (appConfigs != null) {
            this.Container_MXR_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_MX_Records_Column_Setting')[0].Value);
            this.Container_LOG_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Log_Books_Column_Setting')[0].Value);
            this.Container_GEN_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_General_Column_Setting')[0].Value);
            this.Container_DOC_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Documents_Column_Setting')[0].Value);
            this.Container_BND_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Binders_Column_Setting')[0].Value);

            this.Container_MXR_Sort_By = appConfigs.filter(x => x.Key == 'Container_MXR_Sort_By')[0].Value;
            this.Container_LOG_Sort_By = appConfigs.filter(x => x.Key == 'Container_LOG_Sort_By')[0].Value;
            this.Container_GEN_Sort_By = appConfigs.filter(x => x.Key == 'Container_GEN_Sort_By')[0].Value;
            this.Container_DOC_Sort_By = appConfigs.filter(x => x.Key == 'Container_DOC_Sort_By')[0].Value;
            this.Container_BND_Sort_By = appConfigs.filter(x => x.Key == 'Container_BND_Sort_By')[0].Value;
        }
    }
    public popupParent;
    containerChartSettings = null;
    appConfigs;
    generateContainerChart(selectedChart) {
        if (this.gridData && this.gridData.length > 0) {
            let rowStartIndex = 0;
            let rowEndIndex = this.gridData.length;
            this._sharedService.showLoader("Processing...");
            var columns = this.columnDefs.filter(x => x.hide == false).map(ele => ele.field);
            if (this.gridOptions.api.getCellRanges().length > 0) {
                rowStartIndex = this.gridOptions.api.getCellRanges()[0].startRow.rowIndex;
                rowEndIndex = this.gridOptions.api.getCellRanges()[0].endRow.rowIndex;
                columns = [];
                this.gridOptions.api.getCellRanges()[0].columns.forEach(x => {
                    columns.push(x.getColDef().field);
                });
            }
            this.chartColumnDefs = cloneDeep(this.columnDefs);
            this.chartGridData = cloneDeep(this.gridData);
            var selectedChartData = this.containerChartSettings.find(x => x.chartType == selectedChart)
            // var dateSortColumns= selectedChartData.chartConfigurationList.find(x => x.FieldName.includes('Date'));
            var filterTypeEntity = selectedChartData.chartConfigurationList.find(x => x.FilterType != undefined);
            var sortByColumns = selectedChartData.chartConfigurationList[1].FieldName;
            if (sortByColumns) {
                var dateColumn = sortByColumns
                // this.chartGridData.sort((a, b) => b[dateColumn].localeCompare(a[dateColumn]))
                this.chartGridData.sort((a, b) => {
                    if (!a[dateColumn]) {
                        // Change this values if you want to put `null` values at the end of the array
                        return -1;
                    }

                    if (!b[dateColumn]) {
                        // Change this values if you want to put `null` values at the end of the array
                        return +1;
                    }
                    return b[dateColumn].localeCompare(a[dateColumn])
                });
                this.chartGridData.sort((a, b) => {
                    if (a[dateColumn] < b[dateColumn]) {
                        return -1;
                    }
                    if (a[dateColumn] > b[dateColumn]) {
                        return 1;
                    }
                    return 0;
                })
                var typeObj =
                    [
                        { key: "year", value: 4 },
                        { key: "month", value: 7 },
                        { key: "day", value: 10 },
                    ]

                if (filterTypeEntity && (filterTypeEntity.FilterType.toString().toLowerCase() == 'year' || filterTypeEntity.FilterType.toString().toLowerCase() == 'month' || filterTypeEntity.FilterType.toString().toLowerCase() == 'day')) {
                    let arrLength = typeObj.find(x => x.key == filterTypeEntity.FilterType.toString().toLowerCase()).value;
                    if (dateColumn.includes("Date"))
                        this.chartGridData.forEach(function (part, index) {
                            if (this.chartGridData[index][dateColumn] && this.chartGridData[index][dateColumn].length > arrLength)
                                this.chartGridData[index][dateColumn] = this.chartGridData[index][dateColumn].substring(0, arrLength);
                        }.bind(this));
                }
            }
            for (let i = 0; i < this.chartColumnDefs.length; i++) {

                var selectedChartData = this.containerChartSettings.find(x => x.chartType == selectedChart)
                // var chartConfig = this.chartConfiguration.chartConfigurationList.find(x => x.FieldName == this.chartColumnDefs[i].field);
                var chartConfig = selectedChartData.chartConfigurationList.find(x => x.FieldName == this.chartColumnDefs[i].field);

                // var chartConfig = this.containerChartSettings.chartConfigurationList.find(x => x.FieldName == this.chartColumnDefs[i].field);
                if (chartConfig && chartConfig.aggFunc) {
                    (chartConfig.aggFunc) ? this.chartColumnDefs[i].aggFunc = chartConfig.aggFunc : this.chartColumnDefs[i].aggFunc = null
                }
                if (chartConfig && chartConfig.rowGroup) {
                    (chartConfig.rowGroup) ? this.chartColumnDefs[i].rowGroup = chartConfig.rowGroup : this.chartColumnDefs[i].rowGroup = false
                }
                if (chartConfig && chartConfig.pivot) {
                    (chartConfig.pivot) ? this.chartColumnDefs[i].pivot = chartConfig.pivot : this.chartColumnDefs[i].pivot = false
                }
                if (chartConfig && chartConfig.chartDataType) {
                    (chartConfig.chartDataType) ? this.chartColumnDefs[i].chartDataType = chartConfig.chartDataType : this.chartColumnDefs[i].chartDataType = 'Excluded'
                }
                if (chartConfig && chartConfig.rowGroupIndex) {
                    (chartConfig.rowGroupIndex) ? this.chartColumnDefs[i].rowGroupIndex = chartConfig.rowGroupIndex : this.chartColumnDefs[i].rowGroupIndex = 0
                }
            }

            var params = selectedChartData.chartParams;
            // var params=this.containerChartSettings.chartParams;
            params.cellRange["rowStartIndex"] = rowStartIndex;
            params.cellRange["rowEndIndex"] = rowEndIndex;
            params.cellRange["columns"] = columns;
            setTimeout(() => {
                this.chartGridOptions.api.createPivotChart(params);
                this._sharedService.hideLoader();
            }, 2000);
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Record', detail: 'No record exists.' });
        }
    }
    chartMenuList = [];
    //#region Set Columns of Browse Ag Grid
    setBrowseGridColumns(): void {
        this.columnDefs = [];
        columnDefs_1 = [];
        var containerGridCols: any[];
        var container_Col_Sort_On_By: string = '';

        if (this._sharedService.recordType && this._sharedService.recordType.toUpperCase() == 'MXR') {
            this.columnDefs = [];
            columnDefs_1 = [];
            containerGridCols = this.Container_MXR_Columns;
            container_Col_Sort_On_By = this.Container_MXR_Sort_By;
            this.containerChartSettings = JSON.parse(this.appConfigs.filter(x => x.Key == 'Container_MX_Records_Chart_Settings')[0].Value);
            if (this.containerChartSettings && this.containerChartSettings.length > 0) {
                this.containerChartSettings.forEach(element => {
                    let chartConfig = {
                        label: element.chartType
                    }
                    this.chartMenuList.push(chartConfig)
                });
            }
        }
        else if (this._sharedService.recordType && this._sharedService.recordType.toUpperCase() == 'LOG') {
            this.columnDefs = [];
            columnDefs_1 = [];
            containerGridCols = this.Container_LOG_Columns;
            container_Col_Sort_On_By = this.Container_LOG_Sort_By;
            this.containerChartSettings = JSON.parse(this.appConfigs.filter(x => x.Key == 'Container_Log_Books_Chart_Settings')[0].Value);
            if (this.containerChartSettings && this.containerChartSettings.length > 0) {
                this.containerChartSettings.forEach(element => {
                    let chartConfig = {
                        label: element.chartType
                    }
                    this.chartMenuList.push(chartConfig)
                });
            }
        }
        else if (this._sharedService.recordType && this._sharedService.recordType.toUpperCase() == 'GEN') {

            this.columnDefs = [];
            columnDefs_1 = [];
            containerGridCols = this.Container_GEN_Columns;
            container_Col_Sort_On_By = this.Container_GEN_Sort_By;
            this.containerChartSettings = JSON.parse(this.appConfigs.filter(x => x.Key == 'Container_General_Chart_Settings')[0].Value);
            if (this.containerChartSettings && this.containerChartSettings.length > 0) {
                this.containerChartSettings.forEach(element => {
                    let chartConfig = {
                        label: element.chartType
                    }
                    this.chartMenuList.push(chartConfig)
                });
            }
        }
        else if (this._sharedService.recordType && this._sharedService.recordType.toUpperCase() == 'DOC') {
            this.columnDefs = [];
            columnDefs_1 = [];
            containerGridCols = this.Container_DOC_Columns;
            container_Col_Sort_On_By = this.Container_DOC_Sort_By;
            this.containerChartSettings = JSON.parse(this.appConfigs.filter(x => x.Key == 'Container_Documents_Chart_Settings')[0].Value);
            if (this.containerChartSettings && this.containerChartSettings.length > 0) {
                this.containerChartSettings.forEach(element => {
                    let chartConfig = {
                        label: element.chartType
                    }
                    this.chartMenuList.push(chartConfig)
                });
            }
        }
        else if (this._sharedService.recordType && this._sharedService.recordType.toUpperCase() == 'BND') {
            this.columnDefs = [];
            columnDefs_1 = [];
            containerGridCols = this.Container_BND_Columns;
            container_Col_Sort_On_By = this.Container_BND_Sort_By;
            this.containerChartSettings = JSON.parse(this.appConfigs.filter(x => x.Key == 'Container_Binders_Chart_Settings')[0].Value);
            if (this.containerChartSettings && this.containerChartSettings.length > 0) {
                this.containerChartSettings.forEach(element => {
                    let chartConfig = {
                        label: element.chartType
                    }
                    this.chartMenuList.push(chartConfig)
                });
            }
        }
        this.popupParent = document.body;
        containerGridCols.forEach((col, index) => {

            let _filter = 'set';

            if (col.FieldName == 'Date' || col.FieldName == 'EndDate' || col.FieldName == 'ScannedOn') {
                _filter = 'agDateColumnFilter';
            }
            let gridCol: any;
            if (col.FieldName == 'ContainerQualityStatus') {
                gridCol = {
                    headerName: col.ColumnLabel,
                    field: 'QualityStatus',//'QualityStatus---ID',//24-02-2021
                    width: col.Width,
                    filter: _filter,
                    tooltipField: col.FieldName,
                    menuTabs: ['filterMenuTab'],
                    hide: !col.IsVisible,
                    filterParams: this.gridStatusFilterParams,
                    //    chartDataType:(col.chartDataType)?'series':'category'//?col.chartDataType:'excluded'
                };
            }
            else {
                gridCol = {
                    headerName: col.ColumnLabel,
                    field: col.FieldName,
                    width: col.Width,
                    // chartDataType:(col.chartDataType)?'series':'category',//?col.chartDataType:'excluded',
                    filter: _filter,
                    tooltipField: col.FieldName,
                    menuTabs: ['filterMenuTab'],
                    hide: !col.IsVisible,
                    rowGroup: false,
                };

            }


            if (col.FieldName == 'Date' || col.FieldName == 'EndDate' || col.FieldName == 'ScannedOn') {
                gridCol.filterParams =
                {
                    comparator: this.dateComparater,
                    inRangeInclusive: 'true'
                }

                gridCol.valueFormatter = function (params: any) {
                    if (params.value != '' && params.value != null) {

                        let dateString = params.value.substring(0, 10);
                        return moment(dateString).format(dateFormatF.toUpperCase());
                    }
                }
            }
            else if (col.FieldName == 'ContainerQualityStatus') {
                gridCol.filterParams = {
                    cellRenderer: this.statusCellRenderer, // formats filter values
                }
                gridCol.cellRenderer = this.statusCellRenderer;
                gridCol.editable = this.isEditVisible;
                if (this.isEditVisible) {
                    gridCol.cellEditor = 'agRichSelectCellEditor';
                    gridCol.cellEditorParams = {
                        values: statusList.map(sts => sts.Name.trim()),
                        cellRenderer: this.statusCellRendererEdit
                    };
                }
            }
            columnDefs_1.push(gridCol);

        });

        this.columnDefs = columnDefs_1;

        let sortOnCol = container_Col_Sort_On_By.split("||")[0];
        let orderBy = container_Col_Sort_On_By.split("||")[1];
        if (sortOnCol == "ContainerQualityStatus") {
            sortOnCol = "QualityStatus";
        }
        this.chartColumnDefs = cloneDeep(this.columnDefs);
        this.columnDefs.forEach(function (col, index) {
            if (col.field == sortOnCol) {
                this.columnDefs[index].sort = orderBy;
            }
        }.bind(this));
    }

    gridStatusFilterParams = { cellRenderer: this.statusCellRenderer };
    statusCellRendererEdit(params: any) {
        let html: string = '';
        var eDiv = document.createElement('div');
        if (params.value) {
            try {
                let currentStatus: any = statusList.filter(x => x.QualityStatusID == params.value)[0];
                if (currentStatus && currentStatus.Name) {
                    eDiv.style.background = currentStatus.CSSClass;
                    html = html + currentStatus.Name;
                } else {
                    let currentStatus: any = statusList.filter(x => x.Name == params.value)[0];
                    if (currentStatus && currentStatus.Name) {
                        eDiv.style.background = currentStatus.CSSClass;
                        html = html + currentStatus.Name;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            return params.value;
        }


        eDiv.style.height = '100%';
        eDiv.style.width = '100%';
        eDiv.style.color = 'white';
        eDiv.style.textAlign = 'left';
        eDiv.style.paddingTop = '6px';
        eDiv.style.paddingLeft = '5px';
        eDiv.innerHTML = html;
        return eDiv;
    }

    //#endregion

    //// #region date Format Settings

    getDateFormatInfo(): any {

        if (this.dateConfig != undefined) {
            dateFormatF = this.dateConfig.filter(x => x.Key == 'DateFormat').map(({ Value }) => Value).toString();
            dateSeparatorS = this.dateConfig.filter(x => x.Key == 'DateSeparater').map(({ Value }) => Value).toString();
            dayD = Number(this.dateConfig.filter(x => x.Key == 'DayIndex').map(({ Value }) => Value));
            monthM = Number(this.dateConfig.filter(x => x.Key == 'MonthIndex').map(({ Value }) => Value));
            yearY = Number(this.dateConfig.filter(x => x.Key == 'YearIndex').map(({ Value }) => Value));
        } else {
            dateFormatF = localStorage.getItem('DateFormat');
            dateSeparatorS = localStorage.getItem('DateSeparater');
            dayD = Number(localStorage.getItem('DayIndex'));
            monthM = Number(localStorage.getItem('MonthIndex'));
            yearY = Number(localStorage.getItem('YearIndex'));

        }
    }
    //// #endregion
    UpdateRoles() {
        //if (!this._sharedService.UserRole) {
        this._sharedService.updateRole();
        //}
        this.isAddVisible = false;
        this.isMoveContainerVisible = false;
        this.isEditVisible = false;
        this.isHideLiveRecords = 1;
        if (!this._sharedService.selectedAsset || (this._sharedService.selectedAsset && this._sharedService.selectedAsset.length == 1)) {
            switch (this._sharedService.recordType.toUpperCase()) {
                case 'MXR':
                    this.isEditVisible = this._sharedService.UserRole.Records_MXR_Container_Quality_Status;
                    // this.isEditVisible = this._sharedService.UserRole.Records_MXR_Con_Edit;
                    break;
                case 'LOG':
                    this.isEditVisible = this._sharedService.UserRole.Records_LOG_Container_Quality_Status;
                    // this.isEditVisible = this._sharedService.UserRole.Records_LOG_Con_Edit;
                    break;
                case 'GEN':
                    this.isEditVisible = this._sharedService.UserRole.Records_GEN_Container_Quality_Status;
                    // this.isEditVisible = this._sharedService.UserRole.Records_GEN_Con_Edit;
                    break;
                case 'DOC':
                    this.isEditVisible = this._sharedService.UserRole.Records_DOC_Container_Quality_Status;;
                    break;
                case 'BND':
                    this.isEditVisible = this._sharedService.UserRole.Transition_BND_Container_Quality_Status;
                    break;
            }

            this.canViewFeedFromStatus = this._sharedService.UserRole.API_Import_Readonly;
        }
        //new check //8833
        if (
            (this._sharedService.recordType.toUpperCase() == 'MXR' && this._sharedService.UserRole.Records_MXR_Readonly) ||
            (this._sharedService.recordType.toUpperCase() == 'LOG' && this._sharedService.UserRole.Records_LOG_Readonly) ||
            (this._sharedService.recordType.toUpperCase() == 'GEN' && this._sharedService.UserRole.Records_GEN_Readonly) ||
            (this._sharedService.recordType.toUpperCase() == 'DOC' && this._sharedService.UserRole.Records_DOC_Readonly) ||
            (this._sharedService.recordType.toUpperCase() == 'BND' && this._sharedService.UserRole.Transition_BND_Readonly) ||
            (this._sharedService.recordType.toUpperCase() == 'SEARCH' && this._sharedService.UserRole.Records_OCRSearch_Readonly && this._router.url.indexOf('/record/search') > -1) ||
            (this._sharedService.recordType.toUpperCase() == 'SEARCH' && this._sharedService.UserRole.Transition_OCRSearch_Readonly && this._router.url.indexOf('/transition/search') > -1)

        ) {

            let hasAccess: boolean = this.HasAccessToCategoryInCurrenAsset();
            if (hasAccess) {
                switch (this._sharedService.recordType.toUpperCase()) {
                    case 'MXR':
                        this.isAddVisible = this._sharedService.UserRole.Records_MXR_Con_Add;
                        this.isMoveContainerVisible = this._sharedService.UserRole.Records_MXR_Move_Container;
                        this.isEditVisible = this._sharedService.UserRole.Records_MXR_Container_Quality_Status;
                        this.isHideLiveRecords = this._sharedService.UserRole.Records_MXR_Con_Admin == true ? 0 : 1;
                        this.isEditVisibleReadonly = this._sharedService.UserRole.Records_MXR_Readonly;
                        break;
                    case 'LOG':
                        this.isAddVisible = this._sharedService.UserRole.Records_LOG_Con_Add;
                        this.isMoveContainerVisible = this._sharedService.UserRole.Records_LOG_Move_Container;
                        this.isEditVisible = this._sharedService.UserRole.Records_LOG_Container_Quality_Status;
                        this.isHideLiveRecords = this._sharedService.UserRole.Records_LOG_Con_Admin == true ? 0 : 1;
                        this.isEditVisibleReadonly = this._sharedService.UserRole.Records_LOG_Readonly;
                        break;
                    case 'GEN':
                        this.isAddVisible = this._sharedService.UserRole.Records_GEN_Con_Add;
                        this.isMoveContainerVisible = this._sharedService.UserRole.Records_GEN_Move_Container;
                        this.isEditVisible = this._sharedService.UserRole.Records_GEN_Container_Quality_Status;
                        this.isHideLiveRecords = this._sharedService.UserRole.Records_GEN_Con_Admin == true ? 0 : 1;
                        this.isEditVisibleReadonly = this._sharedService.UserRole.Records_GEN_Readonly;
                        break;
                    case 'DOC':
                        this.isAddVisible = this._sharedService.UserRole.Records_DOC_Con_Add;
                        this.isMoveContainerVisible = this._sharedService.UserRole.Records_DOC_Move_Container;
                        this.isEditVisible = this._sharedService.UserRole.Records_DOC_Container_Quality_Status;
                        this.isHideLiveRecords = this._sharedService.UserRole.Records_DOC_Con_Admin == true ? 0 : 1;
                        this.isEditVisibleReadonly = this._sharedService.UserRole.Records_DOC_Readonly;
                        break;
                    case 'BND':
                        this.isAddVisible = this._sharedService.UserRole.Transition_BND_Con_Add;
                        this.isMoveContainerVisible = this._sharedService.UserRole.Records_BND_Move_Container;
                        this.isEditVisible = this._sharedService.UserRole.Transition_BND_Container_Quality_Status;
                        this.isHideLiveRecords = this._sharedService.UserRole.Transition_BND_Con_Admin == true ? 0 : 1;
                        this.isEditVisibleReadonly = this._sharedService.UserRole.Transition_BND_Readonly;
                        break;
                }
            }

            this.isViewJobsVisible = this._sharedService.UserRole.DP_Jobs_Readonly;

        }
        else {
            this._sharedService.clearToast();

            if (this._sharedService.recordType.toUpperCase() != '' && this._sharedService.recordType.toUpperCase() != 'SEARCH' && this._sharedService.recordType.toUpperCase() != 'AUTOSEARCH') {

                var notAllowedMsg = 'Access denied';
                if (this._sharedService.selectedAsset && this._sharedService.selectedAsset.length > 0 && this._sharedService.selectedAsset[0].data) {
                    var nonSearchableAssetsToRemove: any[] = [];
                    nonSearchableAssetsToRemove.push(this._sharedService.selectedAsset[0]);
                    let assetDataObj = JSON.parse(this._sharedService.selectedAsset[0].data);
                    notAllowedMsg = 'This asset is on ' + assetDataObj.PlanName + ', please contact administrator to upgrade plan for this asset.';//PlanName
                    //this._sharedService.showToast({ severity: 'warn', summary: 'Access denied', detail: notAllowedMsg });
                    // setTimeout(() => {
                    //     this._sharedService.showToast({ severity: 'warn', summary: 'Access denied', detail: notAllowedMsg });
                    //     console.log("Delayed browse-container.");
                    // }, 500);
                    // if (nonSearchableAssetsToRemove.length > 0) {
                    //     //this._sharedService.selectedAsset = this._sharedService.selectedAsset.filter((el) => !nonSearchableAssetsToRemove.includes(el));
                    //     // this._sharedService.onAssetChanged(null);
                    //     // this._sharedService.onAssetStatusChanged(null);
                    // }
                }
            }
        }
    }

    HasAccessToCategoryInCurrenAsset(): boolean {
        let hasAccess: boolean = false;
        if (this._sharedService.selectedAsset && this._sharedService.selectedAsset.length > 0) {
            let assetDataObj = JSON.parse(this._sharedService.selectedAsset[0].data);

            switch (this._sharedService.recordType.toUpperCase()) {
                case 'MXR':
                    hasAccess = assetDataObj.MXRecords;
                    break;
                case 'LOG':
                    hasAccess = assetDataObj.LogBooks;
                    break;
                case 'GEN':
                    hasAccess = assetDataObj.General;
                    break;
                case 'DOC':
                    hasAccess = assetDataObj.Documents;
                    break;
                case 'BND':
                    hasAccess = assetDataObj.Binders;
                    break;
            }
        }
        this.hasCurrentCategoryAccess = hasAccess;
        return hasAccess;
    }

    //#region MISC
    setGridColumnWidth() {
        // get the current grids width
        //var gridWidth = document.getElementById('containerGrid').offsetWidth;

        var allColumns = _gridOptions.columnApi.getColumns();

        _gridOptions.columnApi.autoSizeColumns(allColumns);
    }

    exportGridData() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        let selRow = this.gridOptions.api.getSelectedRows();
        if (selRow.length > 1) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Multi Row selected', detail: 'Please select single container row.' });
            this.displayJobsdialogue = false;
            return;
        }
        var columnsToExport = this.getDisplayedColumnNames();
        if (this.gridOptions.rowData.length > 0) {
            let fileName = this._sharedService.selectedContainer.ASN + '-' + this._sharedService.selectedContainer.Category + '-Containers' + '-' + moment(Date.now()).format('YYYYMMDD') + '.csv';
            var params: CsvExportParams = {
                skipColumnHeaders: false,
                skipColumnGroupHeaders: false,
                skipRowGroups: false,
                //skipPinnedTop: false,
                //skipPinnedBottom: false,
                allColumns: false,
                onlySelected: false, //true
                suppressQuotes: false,
                fileName: fileName,
                columnSeparator: ',',
                columnKeys: columnsToExport,

                processCellCallback: function (params: any) {
                    //if (params.column.colDef.field == 'QualityStatus---ID') {//24-02-2021
                    if (params.column.colDef.field == 'QualityStatus') {
                        let _status: any = statusList.filter(x => x.Name == params.value)[0];
                        return _status.Name.trim();
                    }
                    else if (params.column.colDef.field == 'Date' || params.column.colDef.field == 'EndDate' || params.column.colDef.field == 'ScannedOn') {
                        return params.value ? params.value.substring(0, 10) : params.value;
                    } else {
                        return params.value;
                    }
                }
            };


            //params.columnKeys = ['DocType', 'DocNo', 'Tags'];
            this.gridOptions.api.exportDataAsCsv(params);
        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Container', detail: 'There is no container to export' });
        }
    }

    getDisplayedColumnNames(): any[] {
        //var columns = this.gridOptions.columnApi.getAllDisplayedColumns()[0].colDef.field

        var displayedColumns = this.gridOptions.columnApi.getAllDisplayedColumns();//[0].colDef.field
        let columnFields: any[] = [];

        displayedColumns.forEach(function (col, index) {
            columnFields.push(col.getColId());//colDef.field);
        });

        return columnFields;
    }
    setDisplayMode() {
        let url = this._router.url;
        if (url.indexOf('/record/') > -1) {
            this.displayMode = 'record';
            this.targetType = 'Records';

        } else if (url.indexOf('/status/') > -1) {
            this.displayMode = 'status';
        }
        else if (url.indexOf('/transition/') > -1) {
            this.displayMode = 'transition';
            this.targetType = 'transition';
        }
    }


    btnShowFeed(event: any) {
        if (!this.ViewSettingId) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No view Selected', detail: 'Please select a view.' });
            return;
        }
        this.showHideFeedDialogue(true);
        this._sharedService.UpdateFeedMappingComponentValue(true);
    }

    showHideFeedDialogue(flag: boolean) {
        this.displayFeedDialog = flag;
    }

    showHideDateChangeDialogue(data: DateContainer) {
        this.DateChange = data;
        this.displayDateChangeDialog = true;
    }


    exportExcelGrid() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        let selRow = this.gridOptions.api.getSelectedRows();
        if (selRow.length > 1) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Multi Row selected', detail: 'Please select single container row.' });
            this.displayJobsdialogue = false;
            return;
        }
        // var columnsToExport = this.getDisplayedColumnNames();
        if (this.gridOptions.rowData.length > 0) {
            let fileName = this._sharedService.selectedContainer.ASN + '-' + this._sharedService.selectedContainer.Category + '-Containers' + '-' + moment(Date.now()).format('YYYYMMDD') + '.xlsx';
            let sheetName = this._sharedService.selectedContainer.Category == 'BND' ? 'Transition-Containers' : 'Records-Containers';

            var params: any = {
                skipColumnGroupHeaders: true,
                allColumns: false,
                onlySelected: false,
                fileName: fileName,
                exportMode: 'xlsx',
                sheetName: sheetName,//'Records-Containers',
                columnWidth: 170,
                // columnKeys: columnsToExport,
                processCellCallback: function (params: any) {
                    //if (params.column.colDef.field == 'QualityStatus---ID') { //24-04-2021
                    if (params.column.colDef.field == 'QualityStatus') {
                        let _status: any = statusList.filter(x => x.Name == params.value)[0];
                        return _status.Name.trim();
                    }
                    else if (params.column.colDef.field == 'Date' || params.column.colDef.field == 'EndDate' || params.column.colDef.field == 'ScannedOn') {
                        return params.value ? params.value.substring(0, 10) : params.value;
                    } else {
                        return params.value;
                    }
                }
            };
            this.setCustomHeaderAndExport(params);

        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Container', detail: 'There is no  container to export' });
        }
    }

    setCustomHeaderAndExport(params: any) {
        this._sharedService.getConfigurationByKey('Excel_Export_Header_Records')
            .subscribe({
                next: (response) => {
                    let result = response;
                    let headerString = result.Value ? result.Value : '';
                    var columnsToExport = this.getDisplayedColumnNames();
                    let moduleName = this._sharedService.selectedModule == 'transition' ? 'Transition' : "Records";
                    headerString = headerString.replace('{ModuleName}', moduleName);
                    headerString = headerString.replace('{DataType}', 'Containers');
                    headerString = headerString.replace('{TenantName}', this._sharedService.tenantName);
                    headerString = headerString.replace('{AirframeTitle}', this._sharedService.selectedAsset[0].label);
                    //US-8181 ////headerString = headerString.replace('{AssetType}', this._sharedService.selectedAsset[0].data.split('|')[4]);
                    headerString = headerString.replace('{AssetType}', JSON.parse(this._sharedService.selectedAsset[0].data).AssetType);
                    headerString = headerString.replace('{AssetTitle}', this._sharedService.selectedAsset[0].label);
                    //US-8181 ////US-8181 //headerString = headerString.replace('{ASN}', this._sharedService.selectedAsset[0].data.split('|')[1]);
                    headerString = headerString.replace('{ASN}', JSON.parse(this._sharedService.selectedAsset[0].data).ASN);
                    headerString = headerString.replace('{UserName}', this._sharedService.getCurrentUserName());
                    headerString = headerString.replace('{DateTime}', this.getFormatedDate(new Date()));
                    //headerString = headerString.replace('{Filter}', JSON.stringify(this.gridOptions.api.getFilterModel()).replace(/[^a-zA-Z: ]/g, "").replace(':',' '));
                    headerString = headerString.replace('{Container}', '');
                    headerString = headerString.replace('Container:', ' ');
                    headerString = headerString.replace('{Filter}', this.getFilterString());
                    const getRows: () => ExcelRow[] = () => JSON.parse(headerString);
                    params.prependContent = getRows();
                    // var largestArrayLength = params.prependContent.reduce(function (a, i, ii) {
                    //     if (ii === 1) {
                    //         return a
                    //     };
                    //     if (i.length > a.length) {
                    //         return i
                    //     }
                    //     return a
                    // }).length

                    // params.columnWidth = function (params: any) {

                    //     if (largestArrayLength > columnsToExport.length) {
                    //         return 100
                    //     }
                    //     else {
                    //         return params.column.getActualWidth();
                    //     }
                    // };

                    this.gridOptions.api.exportDataAsExcel(params);
                },
                error: (error) => {
                    console.log(error);
                }
            });
    }

    setExcelStyle() {
        this.excelStyles = [
            {
                id: 'greenBackground',
                interior: {
                    color: '#90ee90',
                    pattern: 'Solid'
                }
            },
            {
                id: 'redFont',
                font: {
                    underline: 'Single',
                    italic: true,
                    color: '#ff0000'
                }
            },
            {
                id: 'darkGreyBackground',
                interior: {
                    color: '#888888',
                    pattern: 'Solid'
                }
            },
            {
                id: 'boldBorders',
                borders: {
                    borderBottom: {
                        color: '#000000',
                        lineStyle: 'Continuous',
                        weight: 3
                    },
                    borderLeft: {
                        color: '#000000',
                        lineStyle: 'Continuous',
                        weight: 3
                    },
                    borderRight: {
                        color: '#000000',
                        lineStyle: 'Continuous',
                        weight: 3
                    },
                    borderTop: {
                        color: '#000000',
                        lineStyle: 'Continuous',
                        weight: 3
                    }
                }
            },
            {
                id: 'header',
                interior: {
                    color: '#CCCCCC',
                    pattern: 'Solid'
                }
            },
            {
                id: 'twoDecimalPlaces',
                numberFormat: { format: '#,##0.00' }
            },
            {
                id: 'textFormat',
                dataType: 'string'
            },
            {
                id: 'bigHeader',
                font: {
                    size: 16,
                    bold: true
                },
                alignment: {
                    horizontal: 'Center', vertical: 'Center'
                }
            }
        ];
    }
    getFormatedDate(date: any) {
        if (date) {
            return moment(date).format(this._sharedService.getDateTimeFormatConfiguration().dateformat.toUpperCase());
        } else {
            return '';
        }
    }

    getFilterString(): string {
        let filterModel = this.gridOptions.api.getFilterModel();
        let filterString = '';
        for (var filter in filterModel) {
            filterString = filterString.concat(filter, ', ');
        }

        return filterString ? filterString.slice(0, -2) : 'No';
    }

    ///#endregion
    //#region Contianer Filter
    changeContainerFilterState(): void {
        this.isContainerFilterVisible = false;
        this._sharedService.isNavigationFilterCanceled = false;
        this._sharedService.navigationInfo = null;
        this._sharedService.navigationInfoDashboard = null;
        this._sharedService.navigationInfoStatusPopup = null;
        this.reBindGrid(this._sharedService.selectedAsset);

    }
    focusContainerFilterState(): void {
        this.isfocus = true;
    }
    blurContainerFilterState(): void {
        this.isfocus = false;
    }
    //#endregion
    //#region Export
    //showBulkExport(dataType:string)
    //{
    //    let feedData: datafeed =
    //        {
    //            DataType: dataType,
    //            FeedName: this._apiService.getTempFeedName(),
    //            FeedType: "ExportTemp",
    //            Interval: "None",
    //            SourceType: "Records",
    //            SourceDetail: this._sharedService.selectedAsset[0].data.split('|')[1],
    //            TargetType: 'Email Link',
    //            TargetDetail:'',
    //            Status: true
    //        }
    //    this._apiService.addFeedAndReturnFeedID(feedData).subscribe( response => {
    //        let feedDataId = response;
    //        this._sharedService.recordConfig = new RecordConfig(Number(feedDataId), feedData.FeedName,feedData.DataType, feedData.SourceDetail);
    //        this.OpencloseConfigDialogue(true);
    //    },
    //        error => {
    //            this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Error Occurred, please try again.' });
    //        });

    //}

    //OpencloseConfigDialogue(flag: boolean)
    //{
    //    this.displayConfigdialogue = flag;
    //}
    //adjustConfigDlgHeight(event: any) {
    //    let tskDlgfrm = document.getElementById('recordConfigDlg');
    //    let tskDlgfrm1 = document.getElementById('recordDocPackGrid');
    //    if (tskDlgfrm) {
    //        tskDlgfrm.style.height = '543px';
    //        tskDlgfrm1.style.height = '60vh';
    //        tskDlgfrm.style.overflowX = 'hidden';
    //    }
    //}
    //#endregion
    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];
        let childResult: any[] = [];
        if (params.context.gridContext.isAddVisible && params.context.gridContext.selectedAsset && params.context.gridContext.displayMode != 'status') {
            result.push({
                name: 'Add Container',
                action: function () {

                    params.context.gridContext._sharedService.addContainerByObject(null);
                },
                icon: params.context.gridContext._sharedService.addIconHTMLasString,
            });
        }
        if ((params.context.gridContext.isEditVisible || params.context.gridContext.isEditVisibleReadonly) && params.context.gridContext.selectedAsset && params.context.gridContext.displayMode != 'status') {
            result.push({
                name: 'Edit Container',
                action: function () {
                    if (params.node) {
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext._sharedService.onBrowseContainerSelection(params.node.data);
                        params.context.gridContext._sharedService.updateContainerByObject(params.node.data);
                    }

                },
                icon: params.context.gridContext._sharedService.editIconHTMLasString,
            });
        }
        if (params.context.gridContext.selectedAsset && params.context.gridContext.displayMode != 'status') {
            childResult.push({
                name: 'Excel',
                action: function () {
                    params.context.gridContext.exportExcelGrid();
                },
                icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
            });

            childResult.push({
                name: 'CSV',
                action: function () {

                    params.context.gridContext.exportGridData();

                },
                icon: params.context.gridContext._sharedService.saveLogFeedIconHTMLasString,
            });

            result.push({
                name: 'Download',
                action: function () {


                },
                icon: params.context.gridContext._sharedService.downloadsFeedIconHTMLasString,
                subMenu: childResult,
            });
        }

        return result;


    }
    //#endregion
    //#region View Job
    viewJobs() {
        if (this.gridData.length) {
            let selRow = this.gridOptions.api.getSelectedRows();
            if (selRow.length > 1) {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Multi Row selected', detail: 'Please select single container row.' });
                this.displayJobsdialogue = false;
                return;
            }
            if (selRow && selRow.length > 0) {
                this._sharedService.onBrowseContainerSelection(selRow[0]);
                this.selectedContainerRow = selRow[0];
            }
        }
        if (this.selectedContainerRow) {
            this.displayJobsdialogue = true;
        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Row selected', detail: 'Please select container row.' });
            this.displayJobsdialogue = false;
        }
    }
    adjustJobsDlgHeight(event: any) {

    }
    //#endregion
    calculateGridHeight() {

        if (this._router.url.indexOf('/status/') == -1 && this._router.url.indexOf('/repair/') == -1)
            setTimeout(() => {

                // Get the header element
                const headerElement = this.elRef.nativeElement.querySelector('#conPanelHeader');

                // Calculate header height (including padding and borders)
                const headerHeight = headerElement.offsetHeight;

                // Set the remaining height to the div below header
                const contentElement = this.elRef.nativeElement.querySelector('#containerGrid')
                const windowHeight = window.innerHeight;
                const remainingHeight = windowHeight - headerHeight - 69;
                this.renderer.setStyle(contentElement, 'height', `${remainingHeight}px`, RendererStyleFlags2.Important);
            }, 700);
    }
}

// function getDatePicker() {
//     function Datepicker() { }
//     Datepicker.prototype.init = function (params) {
//         this.eInput = document.createElement('input');
//         this.eInput.value = params.value;
//         (this.eInput).datepicker({ dateFormat: 'dd/mm/yy' });
//     };
//     Datepicker.prototype.getGui = function () {
//         return this.eInput;
//     };
//     Datepicker.prototype.afterGuiAttached = function () {
//         this.eInput.focus();
//         this.eInput.select();
//     };
//     Datepicker.prototype.getValue = function () {
//         return this.eInput.value;
//     };
//     Datepicker.prototype.destroy = function () { };
//     Datepicker.prototype.isPopup = function () {
//         return false;
//     };
//     return Datepicker;
// }
