//Components
import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, AfterViewChecked, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, Params, NavigationEnd, NavigationStart, RouterStateSnapshot } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { Editor } from 'primeng/editor';
import { NgForm, FormsModule } from '@angular/forms';
import { GridOptions, GridApi, RowNode, ColDef } from "ag-grid-community";
import { SettingsService } from '../../services/settings/setting.service';
import { Settings, settingsEntity, IsettingsUpdateEntity, settingsUpdateEntity, settingsEntityList } from './settings';
//import { CheckboxRendererComponent } from '../../../shared/components/checkbox-renderer/checkbox-renderer.component';

const maxAllowedImageFileSize: number = 300000;
var nextRoute: string = null;
// const SubDomain_Tenant_Internal: string = "internal";
// const SubDomain_Tenant_Help: string = "help";
//var Current_SubDomain_Tenant: string = "";

var _gridOptions_Document_MXR: any;
var _gridOptions_Document_Log: any;
var _gridOptions_Document_Gen: any;
var _gridOptions_Document_Doc: any;
var _gridOptions_Document_Bnd: any;

var _gridOptions_Container_MXR: any;
var _gridOptions_Container_Log: any;
var _gridOptions_Container_Gen: any;
var _gridOptions_Container_Doc: any;
var _gridOptions_Container_Bnd: any;




@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    providers: [SettingsService, ConfirmationService]
})
export class SettingsComponent implements OnInit, AfterViewInit {
    dateFormatList: SelectItem[] = [];
    timeFormatList: SelectItem[] = [];
    twoFactList: SelectItem[] = [];
    intervalList: SelectItem[] = [];
    storageCapacity: number = 6;
    DateFormat: any;
    Settings: Settings = new Settings();
    SettingsClone: Settings = new Settings();
    isSubmitted: boolean = false;

    currentTabIndex: string = "";
    isActiveTabApplication: boolean = false;
    isActiveTabUser: boolean = false;
    isActiveTabInbox: boolean = false;
    isActiveTabDashboard: boolean = false;
    isActiveTabAutoSearch: boolean = false;
    isActiveTabGapAnalyzer: boolean = false;
    isActiveTabJobs: boolean = false;
    isActiveTabExport: boolean = false;
    isActiveTabAPI: boolean = false;
    isActiveTabOCR: boolean = false;
    isActiveTabAlert: boolean = false;
    isActiveTabQualityStatus: boolean = false;

    isActiveTabContainer: boolean = false;
    isActiveTabContainerColList_MXR1: boolean = false;
    isActiveTabContainerColList_Log2: boolean = false;
    isActiveTabContainerColList_Gen3: boolean = false;
    isActiveTabContainerColList_Doc4: boolean = false;
    isActiveTabContainerColList_Bnd5: boolean = false;

    isActiveTabDocumentColList_MXR1: boolean = false;
    isActiveTabDocumentColList_Log2: boolean = false;
    isActiveTabDocumentColList_Gen3: boolean = false;
    isActiveTabDocumentColList_Doc4: boolean = false;
    isActiveTabDocumentColList_Bnd5: boolean = false;

    public gridOptionsContainerCol_MXR: GridOptions;
    public gridOptionsContainerCol_Log: GridOptions;
    public gridOptionsContainerCol_Gen: GridOptions;
    public gridOptionsContainerCol_Doc: GridOptions;
    public gridOptionsContainerCol_Bnd: GridOptions;

    public gridOptionsDocumentCol_MXR: GridOptions;
    public gridOptionsDocumentCol_Log: GridOptions;
    public gridOptionsDocumentCol_Gen: GridOptions;
    public gridOptionsDocumentCol_Doc: GridOptions;
    public gridOptionsDocumentCol_Bnd: GridOptions;

    public containerColumnDefs: any[] = [];
    public documentColumnDefs: any[] = [];

    public Container_MX_Records_Column_Setting_Grid_Data: any[] = [];
    public Container_Log_Column_Setting_Grid_Data: any[] = [];
    public Container_Gen_Column_Setting_Grid_Data: any[] = [];
    public Container_Doc_Column_Setting_Grid_Data: any[] = [];
    public Container_Bnd_Column_Setting_Grid_Data: any[] = [];

    public Document_MX_Records_Column_Setting_Grid_Data: any[] = [];
    public Document_Log_Column_Setting_Grid_Data: any[] = [];
    public Document_Gen_Column_Setting_Grid_Data: any[] = [];
    public Document_Doc_Column_Setting_Grid_Data: any[] = [];
    public Document_Bnd_Column_Setting_Grid_Data: any[] = [];

    public Container_MXR_Sort_By_Setting_Options: any[] = [];
    public Container_LOG_Sort_By_Setting_Options: any[] = [];
    public Container_GEN_Sort_By_Setting_Options: any[] = [];
    public Container_DOC_Sort_By_Setting_Options: any[] = [];
    public Container_BND_Sort_By_Setting_Options: any[] = [];

    public Document_MXR_Sort_By_Setting_Options: any[] = [];
    public Document_LOG_Sort_By_Setting_Options: any[] = [];
    public Document_GEN_Sort_By_Setting_Options: any[] = [];
    public Document_DOC_Sort_By_Setting_Options: any[] = [];
    public Document_BND_Sort_By_Setting_Options: any[] = [];

    public Column_Order_By_Setting_Options: any[] = [];


    private gridColumnApiContainer_MXR;
    private gridColumnApiContainer_Log;
    private gridColumnApiContainer_Gen;
    private gridColumnApiContainer_Doc;
    private gridColumnApiContainer_Bnd;



    public rowSelection;

    isSysAdmin: boolean = false;
    userEmailBody: Editor = null;
    userForgotEmailRef: any = null;
    //loggedInTenant:string="";
    imgURL: any;

    @ViewChild('newjobemailto') newjobemailto: ElementRef;
    @ViewChild('newjobemailsbjt') newjobemailsbjt: ElementRef;
    @ViewChild('newjobemailbody') newjobemailbody: ElementRef;

    @ViewChild('cmpltjobemailto') cmpltjobemailto: ElementRef;
    @ViewChild('cmpltjobemailsbjt') cmpltjobemailsbjt: ElementRef;
    @ViewChild('cmpltjobemailbody') cmpltjobemailbody: ElementRef;

    @ViewChild('updtjobemailto') updtjobemailto: ElementRef;
    @ViewChild('updtjobemailsbjt') updtjobemailsbjt: ElementRef;
    @ViewChild('updtjobemailbody') updtjobemailbody: ElementRef;

    @ViewChild('outgoingfileemailbody') outgoingfileemailbody: ElementRef;
    @ViewChild('incomingissueemailbody') incomingissueemailbody: ElementRef;
    @ViewChild('outgoingissueemailbody') outgoingissueemailbody: ElementRef;
    @ViewChild('alertmailbody') alertmailbody: Editor;

    @ViewChild('frgtemailbody') frgtemailbody: ElementRef;
    @ViewChild('shardKeyemailbody') shardKeyemailbody: ElementRef;
    @ViewChild('newusremailbody') newusremailbody: ElementRef;
    @ViewChild('inboxemailbody') inboxemailbody: Editor;

    isTextEditor: boolean = false;
    cursorPosition: number = 0;
    selectedCtrl: any = null;

    Current_SubDomain_Tenant: string = "";
    SubDomain_Tenant_Internal: string = "internal";
    SubDomain_Tenant_Help: string = "help";
    isTabViewChange: boolean;
    //// #region Init

    constructor(public _sharedService: SharedService, private router: Router, private _settingsService: SettingsService, private _confirmationService: ConfirmationService) {
        if (!this._sharedService.isAuthUser()) {
            this.router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.updateRoles();
            // router.events.subscribe((event) => {
            //     if (event instanceof NavigationStart) {
            //         // Check if form is dirty
            //         this._sharedService._canActivateRoute = false;
            //         console.log("Route Deactivated");
            //     }
            // });
        }

        this.Current_SubDomain_Tenant = this._sharedService.getSubdomain().toLowerCase();
    }

    ngOnInit() {

        this.rowSelection = 'multiple';

        this.Current_SubDomain_Tenant = this._sharedService.getSubdomain().toLowerCase();
        this.populateDateFormat();
        this.populateTimeFormat();

        //this.loggedInTenant=this._sharedService.getLoggedInTenantId();
        // if (this._sharedService.getSubdomain().toLowerCase() == this._sharedService.Tenant_Help ||
        //     this._sharedService.getSubdomain().toLowerCase() == this._sharedService.Tenant_Internal)
        //( this.loggedInTenant!="0") //0 or 9999
        if (this.Current_SubDomain_Tenant == this.SubDomain_Tenant_Internal ||
            this.Current_SubDomain_Tenant == this.SubDomain_Tenant_Help) {
            //this.populateTwoFactAuth();
        }
        else {
            this.populateTwoFactAuth();
        }
        this.populateInterval();
        this.getSettings('Application', false, null);
    }
    RemoveLogo() {
        this.Settings.TenantLogo = null;
  }
  
  confirmSaveChanges(nextState: RouterStateSnapshot): Promise<boolean> {

    
    if (!this.IsValuesChanged()) {
      if (this._sharedService.next_not_navigated_ParentMenu_Id) {
        this._sharedService.onParentMenuChanged(this._sharedService.next_not_navigated_ParentMenu_Id);
      }
      return Promise.resolve(true)
    };

    return new Promise((resolve) => {
      this._confirmationService.confirm({
        message: 'You have unsaved changes!',
        header: 'Are you sure you want to discard changes?',
        icon: 'fa fa-trash',
        accept: () => {
          // User accepted
          this._sharedService.onParentMenuChanged(this._sharedService.next_not_navigated_ParentMenu_Id);
          this._sharedService.isUnsavedChanges = false;
          resolve(true);
        },
        reject: () => {
          // User rejected
          resolve(false);
        }
      });
    });
  }


  ngAfterViewInit() {
    this._sharedService.isUnsavedChanges = this.IsValuesChanged();
    }
    AssignToken(_controlId: string, _token: string) {
        var startPos = 0;

        this.isTextEditor = false;
        switch (_controlId) {
            case "newjobemailto":
                this.selectedCtrl = this.newjobemailto;
                startPos = this.selectedCtrl.nativeElement.selectionStart;
                this.selectedCtrl.nativeElement.value =
                    this.selectedCtrl.nativeElement.value.substr(0, startPos) + _token +
                    this.selectedCtrl.nativeElement.value.substr(startPos, this.selectedCtrl.nativeElement.value.length);
                this.Settings.JobNewTo = this.selectedCtrl.nativeElement.value;
                break;
            case "newjobemailsbjt":
                this.selectedCtrl = this.newjobemailsbjt;
                startPos = this.selectedCtrl.nativeElement.selectionStart;
                this.selectedCtrl.nativeElement.value =
                    this.selectedCtrl.nativeElement.value.substr(0, startPos) + _token +
                    this.selectedCtrl.nativeElement.value.substr(startPos, this.selectedCtrl.nativeElement.value.length);
                this.Settings.JobNewSubject = this.selectedCtrl.nativeElement.value;
                break;
            case "newjobemailbody":
                this.isTextEditor = true;
                this.selectedCtrl = this.newjobemailbody;
                startPos = this.selectedCtrl.quill.getSelection(true).index;
                this.selectedCtrl.quill.insertText(startPos, _token);
                var _content = this.selectedCtrl.quill.getContents();
                this.Settings.JobNewBody = this.selectedCtrl.quill.root.innerHTML;
                this.cursorPosition = startPos + _token.length;
                break;

            case "cmpltjobemailto":
                this.selectedCtrl = this.cmpltjobemailto;
                startPos = this.selectedCtrl.nativeElement.selectionStart;
                this.selectedCtrl.nativeElement.value =
                    this.selectedCtrl.nativeElement.value.substr(0, startPos) + _token +
                    this.selectedCtrl.nativeElement.value.substr(startPos, this.selectedCtrl.nativeElement.value.length);
                this.Settings.JobCompleteTo = this.selectedCtrl.nativeElement.value;
                break;
            case "cmpltjobemailsbjt":
                this.selectedCtrl = this.cmpltjobemailsbjt;
                startPos = this.selectedCtrl.nativeElement.selectionStart;
                this.selectedCtrl.nativeElement.value =
                    this.selectedCtrl.nativeElement.value.substr(0, startPos) + _token +
                    this.selectedCtrl.nativeElement.value.substr(startPos, this.selectedCtrl.nativeElement.value.length);
                this.Settings.JobCompleteSubject = this.selectedCtrl.nativeElement.value;
                break;
            case "cmpltjobemailbody":
                this.isTextEditor = true;
                this.selectedCtrl = this.cmpltjobemailbody;
                startPos = this.selectedCtrl.quill.getSelection(true).index;
                this.selectedCtrl.quill.insertText(startPos, _token);
                var _content = this.selectedCtrl.quill.getContents();
                this.Settings.JobCompleteBody = this.selectedCtrl.quill.root.innerHTML;
                this.cursorPosition = startPos + _token.length;
                break;

            case "updtjobemailto":
                this.selectedCtrl = this.updtjobemailto;
                startPos = this.selectedCtrl.nativeElement.selectionStart;
                this.selectedCtrl.nativeElement.value =
                    this.selectedCtrl.nativeElement.value.substr(0, startPos) + _token +
                    this.selectedCtrl.nativeElement.value.substr(startPos, this.selectedCtrl.nativeElement.value.length);
                this.Settings.JobUpdateTo = this.selectedCtrl.nativeElement.value;
                break;
            case "updtjobemailsbjt":
                this.selectedCtrl = this.updtjobemailsbjt;
                startPos = this.selectedCtrl.nativeElement.selectionStart;
                this.selectedCtrl.nativeElement.value =
                    this.selectedCtrl.nativeElement.value.substr(0, startPos) + _token +
                    this.selectedCtrl.nativeElement.value.substr(startPos, this.selectedCtrl.nativeElement.value.length);
                this.Settings.JobUpdateSubject = this.selectedCtrl.nativeElement.value;
                break;
            case "updtjobemailbody":
                this.isTextEditor = true;
                this.selectedCtrl = this.updtjobemailbody;
                startPos = this.selectedCtrl.quill.getSelection(true).index;
                this.selectedCtrl.quill.insertText(startPos, _token);
                this.Settings.JobUpdateBody = this.selectedCtrl.quill.root.innerHTML;
                this.cursorPosition = startPos + _token.length;

                break;
            case "outgoingfileemailbody":
                this.isTextEditor = true;
                this.selectedCtrl = this.outgoingfileemailbody;
                startPos = this.selectedCtrl.quill.getSelection(true).index;
                this.selectedCtrl.quill.insertText(startPos, _token);
                this.Settings.ExportCompleteBody = this.selectedCtrl.quill.root.innerHTML;
                this.cursorPosition = startPos + _token.length;
                break;
            case "incomingissueemailbody":
                this.isTextEditor = true;
                this.selectedCtrl = this.incomingissueemailbody;
                startPos = this.selectedCtrl.quill.getSelection(true).index;
                this.selectedCtrl.quill.insertText(startPos, _token);
                this.Settings.IncomingIssueBody = this.selectedCtrl.quill.root.innerHTML;
                this.cursorPosition = startPos + _token.length;
                break;
            case "outgoingissueemailbody":
                this.isTextEditor = true;
                this.selectedCtrl = this.outgoingissueemailbody;
                startPos = this.selectedCtrl.quill.getSelection(true).index;
                this.selectedCtrl.quill.insertText(startPos, _token);
                this.Settings.OutgoingIssueBody = this.selectedCtrl.quill.root.innerHTML;
                this.cursorPosition = startPos + _token.length;
                break;
            case "alertmailbody":
                this.isTextEditor = true;
                this.selectedCtrl = this.alertmailbody;
                startPos = this.selectedCtrl.quill.getSelection(true).index;
                this.selectedCtrl.quill.insertText(startPos, _token);
                this.Settings.AlertBody = this.selectedCtrl.quill.root.innerHTML;
                this.cursorPosition = startPos + _token.length;

                break;

            case "frgtemailbody":
                this.isTextEditor = true;
                this.selectedCtrl = this.frgtemailbody;
                startPos = this.selectedCtrl.quill.getSelection(true).index;
                this.selectedCtrl.quill.insertText(startPos, _token);
                this.Settings.ForgetBody = this.selectedCtrl.quill.root.innerHTML;
                this.cursorPosition = startPos + _token.length;
                break;

            case "shardKeyemailbody":
                this.isTextEditor = true;
                this.selectedCtrl = this.shardKeyemailbody;
                startPos = this.selectedCtrl.quill.getSelection(true).index;
                this.selectedCtrl.quill.insertText(startPos, _token);
                this.Settings.ShardKeyBody = this.selectedCtrl.quill.root.innerHTML;
                this.cursorPosition = startPos + _token.length;
                break;

            case "newusremailbody":
                this.isTextEditor = true;
                this.selectedCtrl = this.newusremailbody;
                startPos = this.selectedCtrl.quill.getSelection(true).index;
                this.selectedCtrl.quill.insertText(startPos, _token);
                this.Settings.NewBody = this.selectedCtrl.quill.root.innerHTML;
                this.cursorPosition = startPos + _token.length;
                break;

            case "inboxemailbody":
                this.isTextEditor = true;
                this.selectedCtrl = this.inboxemailbody;
                startPos = this.selectedCtrl.quill.getSelection(true).index;
                this.selectedCtrl.quill.insertText(startPos, _token);
                this.Settings.InboxBody = this.selectedCtrl.quill.root.innerHTML;
                this.cursorPosition = startPos + _token.length;
                break;
            default:
                break;
        }

        setTimeout(() => {
            if (this.isTextEditor == true) {
                this.selectedCtrl.quill.update('user');
                this.selectedCtrl.quill.setSelection(this.cursorPosition, 0, 'user');
            }
        }, 100);
    }
    //#region Date & Time
    populateDateFormat() {

        this.dateFormatList = this._settingsService.getDateFormates();
    }

    populateTimeFormat() {

        this.timeFormatList = this._settingsService.getTimeFormates();
    }

    populateTwoFactAuth() {

        this.twoFactList = [
            {
                label: "Disable",
                value: "0"
            },
            {
                label: "Always On",
                value: "1"
            },
            {
                label: "On IP change",
                value: "2"
            }
        ];
    }
    populateInterval() {

        this.intervalList = this._settingsService.getInterval();
    }
    //#endregion

    updateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }
        this.isSysAdmin = this._sharedService.UserRole.Admin_Sys_Admin;
    }
    getSelectedDateFormat(body: settingsEntity[]): string {
        //'dd-MM-yyyy||-||0||1||2'
        let dateFormatValue = body.filter(x => x.Key == 'DateFormat')[0].Value + '||' + body.filter(x => x.Key == 'DateSeparater')[0].Value + '||' + body.filter(x => x.Key == 'DayIndex')[0].Value + '||' + body.filter(x => x.Key == 'MonthIndex')[0].Value + '||' + body.filter(x => x.Key == 'YearIndex')[0].Value;
        return dateFormatValue;
    }
    getSettings(settingType: string, askForConfirmation: boolean = true, event: Event) {
        if (!askForConfirmation) {
            // this._sharedService.showLoader("Processing...");
            this._settingsService.getSettings(settingType)
            .subscribe({
                next: (response) => {
                    let body: settingsEntity[] = response;
                    this.Settings = new Settings();
                    this.SettingsClone = new Settings();
                    //let loggedInTenant=this._sharedService.getLoggedInTenantId();
        
                    //if (this._sharedService.getSubdomain().toLowerCase() == this._sharedService.Tenant_Internal)//(loggedInTenant=="0")
                    if (this.Current_SubDomain_Tenant == this.SubDomain_Tenant_Internal) {
                        switch (settingType) {
                            case 'Application':
                                this.currentTabIndex = settingType;
                                this.changeTabsStatus(false, settingType);
                                //this.isActiveTabApplication = true;
                                this.Settings.DateFormat = this.getSelectedDateFormat(body);
                                this.Settings.TimeFormat = body.filter(x => x.Key == 'TimeFormat')[0].Value;
                                this.Settings.IntervalAssetReport = body.filter(x => x.Key == 'Batch_Interval_DataCopy_Asset')[0].Value;
                                this.Settings.IntervalJobReport = body.filter(x => x.Key == 'Batch_Interval_DataCopy_DpJob')[0].Value;
        
        
                                break;
                            default:
                        }
                    }
                    else {
                        switch (settingType) {
                            case 'Application':
                                this.currentTabIndex = settingType;
                                this.changeTabsStatus(false, settingType);
                                //this.isActiveTabApplication = true;
                                this.Settings.DateFormat = this.getSelectedDateFormat(body);
                                this.Settings.TimeFormat = body.filter(x => x.Key == 'TimeFormat')[0].Value;
                                this.Settings.MaxDocumentSize = body.filter(x => x.Key == 'Max_Document_Size')[0].Value;
                                this.Settings.TwoFactAuth = body.filter(x => x.Key == 'Two_Factor_Auth')[0].Value;
                                this.Settings.TenantLogo = body.filter(x => x.Key == 'Tenant_Logo')[0].Value;
        
                                break;
                            case 'User':
                                this.currentTabIndex = settingType;
                                this.changeTabsStatus(false, settingType);
                                //this.isActiveTabUser = true;
                                this.Settings.ForgetAlias = body.filter(x => x.Key == 'Forget_Pswd_Email_From_Alias')[0].Value;
                                this.Settings.ForgetBody = body.filter(x => x.Key == 'Forget_Pswd_Email_Body')[0].Value;
                                this.Settings.ForgetFrom = body.filter(x => x.Key == 'Forget_Pswd_Email_From')[0].Value;
                                this.Settings.ForgetSubject = body.filter(x => x.Key == 'Forget_Pswd_Email_Subject')[0].Value;
        
                                this.Settings.ShardKeyAlias = body.filter(x => x.Key == 'Forget_Shared_Key_Email_Alias')[0].Value;
                                this.Settings.ShardKeyBody = body.filter(x => x.Key == 'Forget_Shared_Key_Email_Body')[0].Value;
                                this.Settings.ShardKeyFrom = body.filter(x => x.Key == 'Forget_Shared_Key_Email_From')[0].Value;
                                this.Settings.ShardKeySubject = body.filter(x => x.Key == 'Forget_Shared_Key_Email_Subject')[0].Value;
        
                                this.Settings.NewAlias = body.filter(x => x.Key == 'New_User_Email_From_Alias')[0].Value;
                                this.Settings.NewBody = body.filter(x => x.Key == 'New_User_Email_Body')[0].Value;
                                this.Settings.NewFrom = body.filter(x => x.Key == 'New_User_Email_From')[0].Value;
                                this.Settings.NewSubject = body.filter(x => x.Key == 'New_User_Email_Subject')[0].Value;
        
                                break;
                            case 'API':
                                this.currentTabIndex = settingType;
                                this.changeTabsStatus(false, settingType);
                                //this.isActiveTabAPI = true;
                                this.Settings.IncomingIssueAlias = body.filter(x => x.Key == 'Feed_Issue_Email_From_Alias')[0].Value;
                                this.Settings.IncomingIssueBody = body.filter(x => x.Key == 'Feed_Issue_Body')[0].Value;
                                this.Settings.IncomingIssueFrom = body.filter(x => x.Key == 'Feed_Issue_Email_From')[0].Value;
                                this.Settings.IncomingIssueSubject = body.filter(x => x.Key == 'Feed_Issue_Subject')[0].Value;
                                this.Settings.IncomingIssueTo = body.filter(x => x.Key == 'Feed_Issue_To')[0].Value;
        
                                this.Settings.OutgoingIssueAlias = body.filter(x => x.Key == 'Feed_Outgoing_Issue_Email_From_Alias')[0].Value;
                                this.Settings.OutgoingIssueBody = body.filter(x => x.Key == 'Feed_Outgoing_Issue_Body')[0].Value;
                                this.Settings.OutgoingIssueFrom = body.filter(x => x.Key == 'Feed_Outgoing_Issue_Email_From')[0].Value;
                                this.Settings.OutgoingIssueSubject = body.filter(x => x.Key == 'Feed_Outgoing_Issue_Subject')[0].Value;
                                this.Settings.OutgoingIssueTo = body.filter(x => x.Key == 'Feed_Outgoing_Issue_To')[0].Value;
        
                                break;
                            case 'Inbox':
                                this.currentTabIndex = settingType;
                                this.changeTabsStatus(false, settingType);
                                //this.isActiveTabInbox = true;
                                this.Settings.InboxAlias = body.filter(x => x.Key == 'Comm_Update_Email_From_Alias')[0].Value;
                                this.Settings.InboxBody = body.filter(x => x.Key == 'Comm_Update_Email_Body')[0].Value;
                                this.Settings.InboxFrom = body.filter(x => x.Key == 'Comm_Update_Email_From')[0].Value;
                                break;
                            case 'Dashboard':
                                this.currentTabIndex = settingType;
                                this.changeTabsStatus(false, settingType);
                                //this.isActiveTabDashboard = true;
                                this.Settings.StorageCapacity = Number(body.filter(x => x.Key == 'Dashboard_Storage_Usage_Capacity')[0].Value);
        
                                this.Settings.IntervalRecord = body.filter(x => x.Key == 'Batch_Interval_Dashboard_Record')[0].Value;
                                this.Settings.IntervalStatus = body.filter(x => x.Key == 'Batch_Interval_Dashboard_Status')[0].Value;
                                this.Settings.IntervalStats = body.filter(x => x.Key == 'Batch_Interval_Dashboard_Stats')[0].Value;
                                this.Settings.IntervalAssetCount = body.filter(x => x.Key == 'Batch_Interval_Dashboard_Asset')[0].Value;
                            this.Settings.ContainerStatus = body.filter(x => x.Key == 'Batch_Interval_Dashboard_ContainerStatus')[0].Value;
                                this.Settings.IntervalInbox = body.filter(x => x.Key == 'Batch_Interval_Dashboard_Inbox')[0].Value;
                                break;
                            case 'AutoSearch':
                                this.currentTabIndex = settingType;
                                this.changeTabsStatus(false, settingType);
                                //this.isActiveTabAutoSearch = true;
                                this.Settings.IntervalAutoSearch = body.filter(x => x.Key == 'Batch_Interval_AutoSearch')[0].Value;
                                this.Settings.DefaultSelected = body.filter(x => x.Key == 'DefaultSelectedCondition')[0].Value;
                                this.Settings.DefaultNotSelected = body.filter(x => x.Key == 'DefaultNotSelectedCondition')[0].Value;
                                this.Settings.AutoSearchLastRun = this._sharedService.getDateFormatTime(body.filter(x => x.Key == 'Batch_Interval_AutoSearch_LastRun')[0].Value);
                                break;
                            case 'GapAnalyzer':
                                this.currentTabIndex = settingType;
                                this.changeTabsStatus(false, settingType);
                                this.Settings.IntervalGapAnalyzer = body.filter(x => x.Key == 'Batch_Interval_Gap_Analyser')[0].Value;
                                this.Settings.GapAnalyzerQualityStatus = body.filter(x => x.Key == 'Gap_Analyser_QualityStatus')[0].Value;
                                this.Settings.GapAnalyzerLastRun = this._sharedService.getDateFormatTime(body.filter(x => x.Key == 'Batch_Interval_Gap_Analyser_LastRun')[0].Value);
                                break;
                            case 'Jobs':
                                this.currentTabIndex = settingType;
                                this.changeTabsStatus(false, settingType);
                                //this.isActiveTabJobs= true;
                                this.Settings.JobNewAlias = body.filter(x => x.Key == 'Job_New_Email_From_Alias')[0].Value;
                                this.Settings.JobNewBody = body.filter(x => x.Key == 'Job_New_Email_Body')[0].Value;
                                this.Settings.JobNewFrom = body.filter(x => x.Key == 'Job_New_Email_From')[0].Value;
                                this.Settings.JobNewSubject = body.filter(x => x.Key == 'Job_New_Email_Subject')[0].Value;
                                this.Settings.JobNewTo = body.filter(x => x.Key == 'Job_New_Email_To')[0].Value;
        
                                this.Settings.JobCompleteAlias = body.filter(x => x.Key == 'Job_Complete_Email_Alias')[0].Value;
                                this.Settings.JobCompleteBody = body.filter(x => x.Key == 'Job_Complete_Email_Body')[0].Value;
                                this.Settings.JobCompleteFrom = body.filter(x => x.Key == 'Job_Complete_Email_From')[0].Value;
                                this.Settings.JobCompleteSubject = body.filter(x => x.Key == 'Job_Complete_Email_Subject')[0].Value;
                                this.Settings.JobCompleteTo = body.filter(x => x.Key == 'Job_Complete_Email_To')[0].Value;
        
                                this.Settings.JobUpdateAlias = body.filter(x => x.Key == 'Job_Update_Email_Alias')[0].Value;
                                this.Settings.JobUpdateBody = body.filter(x => x.Key == 'Job_Update_Email_Body')[0].Value;
                                this.Settings.JobUpdateFrom = body.filter(x => x.Key == 'Job_Update_Email_From')[0].Value;
                                this.Settings.JobUpdateSubject = body.filter(x => x.Key == 'Job_Update_Email_Subject')[0].Value;
                                this.Settings.JobUpdateTo = body.filter(x => x.Key == 'Job_Update_Email_To')[0].Value;
        
                                break;
                            case 'OCR':
                                this.currentTabIndex = settingType;
                                this.changeTabsStatus(false, settingType);
                                //this.isActiveTabOCR = true;
                                this.Settings.IntervalOCR = body.filter(x => x.Key == 'Batch_Interval_OCR')[0].Value;
                                this.Settings.OCRLastRun = this._sharedService.getDateFormatTime(body.filter(x => x.Key == 'Batch_Interval_OCR_LastRun')[0].Value);
                                break;
                            case 'Export':
                                this.currentTabIndex = settingType;
                                this.changeTabsStatus(false, settingType);
                                //this.isActiveTabExport= true;
                                this.Settings.FTPHostName = body.filter(x => x.Key == 'FTP_Hostname')[0].Value;
                                this.Settings.FTPUserName = body.filter(x => x.Key == 'FTP_Username')[0].Value;
                                this.Settings.FTPPswd = body.filter(x => x.Key == 'FTP_Pswd')[0].Value;
        
                                this.Settings.RecordHeader = body.filter(x => x.Key == 'Excel_Export_Header_Records')[0].Value;
                                this.Settings.StatusHeader = body.filter(x => x.Key == 'Excel_Export_Header_Status')[0].Value;
                                this.Settings.JobHeader = body.filter(x => x.Key == 'Excel_Export_Header_Jobs')[0].Value;
        
                                this.Settings.APIRecordHeader = body.filter(x => x.Key == 'Excel_API_Export_Header_Records')[0].Value;
                                this.Settings.APIStatusHeader = body.filter(x => x.Key == 'Excel_API_Export_Header_Status')[0].Value;
        
                                //this.Settings.PDFAPIRecordHeader = body.filter(x => x.Key == 'PDF_API_Export_Header_Records')[0].Value;
                                //this.Settings.PDFAPIRecordFooter = body.filter(x => x.Key == 'PDF_API_Export_Footer_Records')[0].Value;
                                this.Settings.PDFAPIStatusHeader = body.filter(x => x.Key == 'PDF_API_Export_Header_Status')[0].Value;
                                this.Settings.PDFAPIStatusFooter = body.filter(x => x.Key == 'PDF_API_Export_Footer_Status')[0].Value;
        
                                this.Settings.ExportCompleteAlias = body.filter(x => x.Key == 'Feed_Outgoing_Email_From_Alias')[0].Value;
                                this.Settings.ExportCompleteBody = body.filter(x => x.Key == 'Feed_Outgoing_Email_Body')[0].Value;
                                this.Settings.ExportCompleteFrom = body.filter(x => x.Key == 'Feed_Outgoing_Email_From')[0].Value;
                                this.Settings.ExportCompleteSubject = body.filter(x => x.Key == 'Feed_Outgoing_Email_Subject')[0].Value;
        
                                break;
                            case 'Alert':
                                this.currentTabIndex = settingType;
                                this.changeTabsStatus(false, settingType);
                                this.Settings.AlertAlias = body.filter(x => x.Key == 'Alert_Email_From_Alias')[0].Value;
                                this.Settings.AlertBody = body.filter(x => x.Key == 'Alert_Email_Body')[0].Value;
                                this.Settings.AlertFrom = body.filter(x => x.Key == 'Alert_Email_From')[0].Value;
                                this.Settings.AlertSubject = body.filter(x => x.Key == 'Alert_Email_Subject')[0].Value;
        
                                break;
                            case 'QualityStatus':
                                this.currentTabIndex = settingType;
                                this.changeTabsStatus(false, settingType);
                                try {
                                    this.Column_Order_By_Setting_Options = this.populate_Order_By_Options();
        
                                    this.Settings.MXRQualityStatus = Boolean(JSON.parse(body.filter(x => x.Key == 'MX_Records_Quality_Process')[0].Value));
                                    this.Settings.LOGQualityStatus = Boolean(JSON.parse(body.filter(x => x.Key == 'Log_Books_Quality_Process')[0].Value));
                                    this.Settings.GENQualityStatus = Boolean(JSON.parse(body.filter(x => x.Key == 'General_Quality_Process')[0].Value));
                                    this.Settings.DOCQualityStatus = Boolean(JSON.parse(body.filter(x => x.Key == 'Documents_Quality_Process')[0].Value));
                                    this.Settings.BNDQualityStatus = Boolean(JSON.parse(body.filter(x => x.Key == 'Binders_Quality_Process')[0].Value));
        
                                    // this.Settings.Document_MXR_Sort_By = body.filter(x => x.Key == 'Document_MXR_Sort_By')[0].Value;
                                    // this.Settings.Document_LOG_Sort_By = body.filter(x => x.Key == 'Document_LOG_Sort_By')[0].Value;
                                    // this.Settings.Document_GEN_Sort_By = body.filter(x => x.Key == 'Document_GEN_Sort_By')[0].Value;
                                    // this.Settings.Document_DOC_Sort_By = body.filter(x => x.Key == 'Document_DOC_Sort_By')[0].Value;
                                    // this.Settings.Document_BND_Sort_By = body.filter(x => x.Key == 'Document_BND_Sort_By')[0].Value;
        
                                    let Document_MXR_Sort_On_By = body.filter(x => x.Key == 'Document_MXR_Sort_By')[0].Value;
                                    let Document_LOG_Sort_On_By = body.filter(x => x.Key == 'Document_LOG_Sort_By')[0].Value;
                                    let Document_GEN_Sort_On_By = body.filter(x => x.Key == 'Document_GEN_Sort_By')[0].Value;
                                    let Document_DOC_Sort_On_By = body.filter(x => x.Key == 'Document_DOC_Sort_By')[0].Value;
                                    let Document_BND_Sort_On_By = body.filter(x => x.Key == 'Document_BND_Sort_By')[0].Value;
        
                                    this.Settings.Document_MXR_Sort_By = Document_MXR_Sort_On_By.split("||")[0];
                                    this.Settings.Document_LOG_Sort_By = Document_LOG_Sort_On_By.split("||")[0];
                                    this.Settings.Document_GEN_Sort_By = Document_GEN_Sort_On_By.split("||")[0];
                                    this.Settings.Document_DOC_Sort_By = Document_DOC_Sort_On_By.split("||")[0];
                                    this.Settings.Document_BND_Sort_By = Document_BND_Sort_On_By.split("||")[0];
        
                                    this.Settings.Document_MXR_Order_By = Document_MXR_Sort_On_By.split("||")[1];
                                    this.Settings.Document_LOG_Order_By = Document_LOG_Sort_On_By.split("||")[1];
                                    this.Settings.Document_GEN_Order_By = Document_GEN_Sort_On_By.split("||")[1];
                                    this.Settings.Document_DOC_Order_By = Document_DOC_Sort_On_By.split("||")[1];
                                    this.Settings.Document_BND_Order_By = Document_BND_Sort_On_By.split("||")[1];
        
                                    this.Settings.Document_MX_Records_Column_Setting = body.filter(x => x.Key == 'Document_MX_Records_Column_Setting')[0].Value;
                                    this.Settings.Document_Log_Books_Column_Setting = body.filter(x => x.Key == 'Document_Log_Books_Column_Setting')[0].Value;
                                    this.Settings.Document_General_Column_Setting = body.filter(x => x.Key == 'Document_General_Column_Setting')[0].Value;
                                    this.Settings.Document_Documents_Column_Setting = body.filter(x => x.Key == 'Document_Documents_Column_Setting')[0].Value;
                                    this.Settings.Document_Binders_Column_Setting = body.filter(x => x.Key == 'Document_Binders_Column_Setting')[0].Value;
        
                                    this.Settings.Document_MX_Records_Column_Setting_Data = JSON.parse(this.Settings.Document_MX_Records_Column_Setting);
                                    this.Settings.Document_Log_Books_Column_Setting_Data = JSON.parse(this.Settings.Document_Log_Books_Column_Setting);
                                    this.Settings.Document_General_Column_Setting_Data = JSON.parse(this.Settings.Document_General_Column_Setting);
                                    this.Settings.Document_Documents_Column_Setting_Data = JSON.parse(this.Settings.Document_Documents_Column_Setting);
                                    this.Settings.Document_Binders_Column_Setting_Data = JSON.parse(this.Settings.Document_Binders_Column_Setting);
        
                                    this.Document_MXR_Sort_By_Setting_Options = this.populate_Sort_By_Setting_Options(this.Settings.Document_MX_Records_Column_Setting_Data);
                                    this.Document_LOG_Sort_By_Setting_Options = this.populate_Sort_By_Setting_Options(this.Settings.Document_Log_Books_Column_Setting_Data);
                                    this.Document_GEN_Sort_By_Setting_Options = this.populate_Sort_By_Setting_Options(this.Settings.Document_General_Column_Setting_Data);
                                    this.Document_DOC_Sort_By_Setting_Options = this.populate_Sort_By_Setting_Options(this.Settings.Document_Documents_Column_Setting_Data);
                                    this.Document_BND_Sort_By_Setting_Options = this.populate_Sort_By_Setting_Options(this.Settings.Document_Binders_Column_Setting_Data);
        
                                    this.setDocumentGridColumns();
                                    this.initDocumentColumnGrid();
                                    this.Document_MX_Records_Column_Setting_Grid_Data = this.Settings.Document_MX_Records_Column_Setting_Data;
                                    this.Document_Log_Column_Setting_Grid_Data = this.Settings.Document_Log_Books_Column_Setting_Data;
                                    this.Document_Gen_Column_Setting_Grid_Data = this.Settings.Document_General_Column_Setting_Data;
                                    this.Document_Doc_Column_Setting_Grid_Data = this.Settings.Document_Documents_Column_Setting_Data;
                                    this.Document_Bnd_Column_Setting_Grid_Data = this.Settings.Document_Binders_Column_Setting_Data;
        
                                    this.isActiveTabDocumentColList_MXR1 = true;
        
                                } catch (error) {
                                    console.log(error);
                                }
                                break;
                            case 'Container':
                                this.currentTabIndex = settingType;
                                this.changeTabsStatus(false, settingType);
                                try {
        
                                    this.Column_Order_By_Setting_Options = this.populate_Order_By_Options();
                                    this.Settings.Container_MXRQualityStatus = Boolean(JSON.parse(body.filter(x => x.Key == 'Container_MX_Records_Quality_Process')[0].Value));
                                    this.Settings.Container_LOGQualityStatus = Boolean(JSON.parse(body.filter(x => x.Key == 'Container_Log_Books_Quality_Process')[0].Value));
                                    this.Settings.Container_GENQualityStatus = Boolean(JSON.parse(body.filter(x => x.Key == 'Container_General_Quality_Process')[0].Value));
                                    this.Settings.Container_DOCQualityStatus = Boolean(JSON.parse(body.filter(x => x.Key == 'Container_Documents_Quality_Process')[0].Value));
                                    this.Settings.Container_BNDQualityStatus = Boolean(JSON.parse(body.filter(x => x.Key == 'Container_Binders_Quality_Process')[0].Value));
        
                                    // this.Settings.Container_MXR_Sort_By = body.filter(x => x.Key == 'Container_MXR_Sort_By')[0].Value;
                                    // this.Settings.Container_LOG_Sort_By = body.filter(x => x.Key == 'Container_LOG_Sort_By')[0].Value;
                                    // this.Settings.Container_GEN_Sort_By = body.filter(x => x.Key == 'Container_GEN_Sort_By')[0].Value;
                                    // this.Settings.Container_DOC_Sort_By = body.filter(x => x.Key == 'Container_DOC_Sort_By')[0].Value;
                                    // this.Settings.Container_BND_Sort_By = body.filter(x => x.Key == 'Container_BND_Sort_By')[0].Value;
        
                                    let Container_MXR_Sort_On_By = body.filter(x => x.Key == 'Container_MXR_Sort_By')[0].Value;
                                    let Container_LOG_Sort_On_By = body.filter(x => x.Key == 'Container_LOG_Sort_By')[0].Value;
                                    let Container_GEN_Sort_On_By = body.filter(x => x.Key == 'Container_GEN_Sort_By')[0].Value;
                                    let Container_DOC_Sort_On_By = body.filter(x => x.Key == 'Container_DOC_Sort_By')[0].Value;
                                    let Container_BND_Sort_On_By = body.filter(x => x.Key == 'Container_BND_Sort_By')[0].Value;
        
                                    this.Settings.Container_MXR_Sort_By = Container_MXR_Sort_On_By.split("||")[0];
                                    this.Settings.Container_LOG_Sort_By = Container_LOG_Sort_On_By.split("||")[0];
                                    this.Settings.Container_GEN_Sort_By = Container_GEN_Sort_On_By.split("||")[0];
                                    this.Settings.Container_DOC_Sort_By = Container_DOC_Sort_On_By.split("||")[0];
                                    this.Settings.Container_BND_Sort_By = Container_BND_Sort_On_By.split("||")[0];
        
                                    this.Settings.Container_MXR_Order_By = Container_MXR_Sort_On_By.split("||")[1];
                                    this.Settings.Container_LOG_Order_By = Container_LOG_Sort_On_By.split("||")[1];
                                    this.Settings.Container_GEN_Order_By = Container_GEN_Sort_On_By.split("||")[1];
                                    this.Settings.Container_DOC_Order_By = Container_DOC_Sort_On_By.split("||")[1];
                                    this.Settings.Container_BND_Order_By = Container_BND_Sort_On_By.split("||")[1];
        
                                    this.Settings.Container_MX_Records_Column_Setting = body.filter(x => x.Key == 'Container_MX_Records_Column_Setting')[0].Value;
                                    this.Settings.Container_Log_Books_Column_Setting = body.filter(x => x.Key == 'Container_Log_Books_Column_Setting')[0].Value;
                                    this.Settings.Container_General_Column_Setting = body.filter(x => x.Key == 'Container_General_Column_Setting')[0].Value;
                                    this.Settings.Container_Documents_Column_Setting = body.filter(x => x.Key == 'Container_Documents_Column_Setting')[0].Value;
                                    this.Settings.Container_Binders_Column_Setting = body.filter(x => x.Key == 'Container_Binders_Column_Setting')[0].Value;
        
                                    this.Settings.Container_MX_Records_Column_Setting_Data = JSON.parse(this.Settings.Container_MX_Records_Column_Setting);
                                    this.Settings.Container_Log_Books_Column_Setting_Data = JSON.parse(this.Settings.Container_Log_Books_Column_Setting);
                                    this.Settings.Container_General_Column_Setting_Data = JSON.parse(this.Settings.Container_General_Column_Setting);
                                    this.Settings.Container_Documents_Column_Setting_Data = JSON.parse(this.Settings.Container_Documents_Column_Setting);
                                    this.Settings.Container_Binders_Column_Setting_Data = JSON.parse(this.Settings.Container_Binders_Column_Setting);
        
                                    this.Container_MXR_Sort_By_Setting_Options = this.populate_Sort_By_Setting_Options(this.Settings.Container_MX_Records_Column_Setting_Data);
                                    this.Container_LOG_Sort_By_Setting_Options = this.populate_Sort_By_Setting_Options(this.Settings.Container_Log_Books_Column_Setting_Data);
                                    this.Container_GEN_Sort_By_Setting_Options = this.populate_Sort_By_Setting_Options(this.Settings.Container_General_Column_Setting_Data);
                                    this.Container_DOC_Sort_By_Setting_Options = this.populate_Sort_By_Setting_Options(this.Settings.Container_Documents_Column_Setting_Data);
                                    this.Container_BND_Sort_By_Setting_Options = this.populate_Sort_By_Setting_Options(this.Settings.Container_Binders_Column_Setting_Data);
        
                                    this.setContainerGridColumns();
                                    this.initContainerColumnGrid();
                                    this.Container_MX_Records_Column_Setting_Grid_Data = this.Settings.Container_MX_Records_Column_Setting_Data;
                                    this.Container_Log_Column_Setting_Grid_Data = this.Settings.Container_Log_Books_Column_Setting_Data;
                                    this.Container_Gen_Column_Setting_Grid_Data = this.Settings.Container_General_Column_Setting_Data;
                                    this.Container_Doc_Column_Setting_Grid_Data = this.Settings.Container_Documents_Column_Setting_Data;
                                    this.Container_Bnd_Column_Setting_Grid_Data = this.Settings.Container_Binders_Column_Setting_Data;
        
        
                                    this.isActiveTabContainerColList_MXR1 = true;
                                } catch (error) {
                                    console.log(error);
                                }
        
                                break;
                        }
                        // this._sharedService.hideLoader();
                    }
                    Object.assign(this.SettingsClone, this.Settings);
                },
                error: (error) => {
                }
            })            
        }
        else {
            if (this.IsValuesChanged()) {
                this.confirmationDialogue(settingType, event);
            }
            else {
                this.getSettings(settingType, false, null);
            }
        }
    }

    initContainerColumnGrid() {
        this.gridOptionsContainerCol_MXR = <GridOptions>{
            rowSelection: 'multiple',
            rowData: [],

            onGridReady: (params) => {

                this.gridOptionsContainerCol_MXR.api = params.api;
                this.gridColumnApiContainer_MXR = params.columnApi;
                if (this.gridOptionsContainerCol_MXR.api != undefined) {
                    if (this.Container_MX_Records_Column_Setting_Grid_Data.length > 0) {
                        this.gridOptionsContainerCol_MXR.api.hideOverlay();
                    }
                    this.gridOptionsContainerCol_MXR.api.setSuppressRowDrag(true);
                }
            },
            onModelUpdated: (event: any) => {

                this.gridOptionsContainerCol_MXR.api.sizeColumnsToFit();
                try {
                    this.gridOptionsContainerCol_MXR.api.forEachNode(function (node) {
                        node.setSelected(node.data.IsVisible === true);
                    });
                } catch (e) {
                    console.log(e);
                }
            },
            defaultColDef: {
                // sortable: true,
                resizable: true,
            },

            //suppressContextMenu: true,
            context: {
                gridContext: this
            },
            suppressScrollOnNewData: true,
        }

        this.gridOptionsContainerCol_Log = <GridOptions>{
            rowSelection: 'multiple',
            rowData: [],
            onGridReady: (params) => {

                this.gridOptionsContainerCol_Log.api = params.api;
                this.gridColumnApiContainer_Log = params.columnApi;

                if (this.gridOptionsContainerCol_Log.api != undefined) {
                    if (this.Container_Log_Column_Setting_Grid_Data.length > 0) {
                        this.gridOptionsContainerCol_Log.api.hideOverlay();
                    }
                    this.gridOptionsContainerCol_Log.api.setSuppressRowDrag(true);
                }
            },
            onModelUpdated: (event: any) => {

                this.gridOptionsContainerCol_Log.api.sizeColumnsToFit();
                try {
                    this.gridOptionsContainerCol_Log.api.forEachNode(function (node) {
                        node.setSelected(node.data.IsVisible === true);
                    });
                } catch (e) {
                    console.log(e);
                }
            },
            defaultColDef: {
                // sortable: true,
                resizable: true,
            },

            //suppressContextMenu: true,
            context: {
                gridContext: this
            },
            suppressScrollOnNewData: true,
        }

        this.gridOptionsContainerCol_Gen = <GridOptions>{
            rowSelection: 'multiple',
            rowData: [],
            onGridReady: (params) => {

                this.gridOptionsContainerCol_Gen.api = params.api;
                this.gridColumnApiContainer_Gen = params.columnApi;

                if (this.gridOptionsContainerCol_Gen.api != undefined) {

                    if (this.Container_Gen_Column_Setting_Grid_Data.length > 0) {
                        this.gridOptionsContainerCol_Gen.api.hideOverlay();
                    }
                    this.gridOptionsContainerCol_Gen.api.setSuppressRowDrag(true);
                }
            },
            onModelUpdated: (event: any) => {

                this.gridOptionsContainerCol_Gen.api.sizeColumnsToFit();
                try {

                    this.gridOptionsContainerCol_Gen.api.forEachNode(function (node) {
                        node.setSelected(node.data.IsVisible === true);
                    });
                    //this.totalCount = this.gridOptionsContainerCol_Gen.api.getDisplayedRowCount();
                } catch (e) {
                    console.log(e);
                }
            },
            defaultColDef: {
                // sortable: true,
                resizable: true,
            },

            //suppressContextMenu: true,
            context: {
                gridContext: this
            },
            suppressScrollOnNewData: true,
        }

        this.gridOptionsContainerCol_Doc = <GridOptions>{
            rowSelection: 'multiple',
            rowData: [],
            onGridReady: (params) => {

                this.gridOptionsContainerCol_Doc.api = params.api;
                this.gridColumnApiContainer_Doc = params.columnApi;

                if (this.gridOptionsContainerCol_Doc.api != undefined) {

                    if (this.Container_Doc_Column_Setting_Grid_Data.length > 0) {
                        this.gridOptionsContainerCol_Doc.api.hideOverlay();
                    }
                    this.gridOptionsContainerCol_Doc.api.setSuppressRowDrag(true);
                }
            },
            onModelUpdated: (event: any) => {

                this.gridOptionsContainerCol_Doc.api.sizeColumnsToFit();
                try {

                    this.gridOptionsContainerCol_Doc.api.forEachNode(function (node) {
                        node.setSelected(node.data.IsVisible === true);
                    });
                } catch (e) {
                    console.log(e);
                }
            },
            defaultColDef: {
                // sortable: true,
                resizable: true,
            },

            //suppressContextMenu: true,
            context: {
                gridContext: this
            },
            suppressScrollOnNewData: true,
        }
        this.gridOptionsContainerCol_Bnd = <GridOptions>{
            rowSelection: 'multiple',
            rowData: [],
            onGridReady: (params) => {

                this.gridOptionsContainerCol_Bnd.api = params.api;
                this.gridColumnApiContainer_Bnd = params.columnApi;

                if (this.gridOptionsContainerCol_Bnd.api != undefined) {

                    if (this.Container_Bnd_Column_Setting_Grid_Data.length > 0) {
                        this.gridOptionsContainerCol_Bnd.api.hideOverlay();
                    }
                    this.gridOptionsContainerCol_Bnd.api.setSuppressRowDrag(true);
                }
            },
            onModelUpdated: (event: any) => {

                this.gridOptionsContainerCol_Bnd.api.sizeColumnsToFit();
                try {

                    this.gridOptionsContainerCol_Bnd.api.forEachNode(function (node) {
                        node.setSelected(node.data.IsVisible === true);
                    });
                } catch (e) {
                    console.log(e);
                }
            },
            defaultColDef: {
                // sortable: true,
                resizable: true,
            },

            //suppressContextMenu: true,
            context: {
                gridContext: this
            },
            suppressScrollOnNewData: true,
        }

    }

    setContainerGridColumns(): void {
        this.containerColumnDefs = [];

        let gridCol: any = {
            headerName: '',
            field: '',
            width: 50,
            minWidth: 50,
            maxWidth: 50,
            checkboxSelection: false,
            headerCheckboxSelection: false,
            filter: false,
            sortable: false,
            rowDrag: true,
            menuTabs: [],
            suppressMenu: true,
            editable: false
        };
        // Add Column in Col Array
        this.containerColumnDefs.push(gridCol);

        this.containerColumnDefs.push({ headerName: 'Field Name', field: 'FieldName', minWidth: 100, width: 150, maxWidth: 150, suppressMenu: true });
        this.containerColumnDefs.push({ headerName: 'Label for List, Add & Edit', field: 'ColumnLabel', minWidth: 175, width: 175, maxWidth: 175, editable: true, suppressMenu: true });
        this.containerColumnDefs.push({ headerName: 'Width', field: 'Width', minWidth: 100, width: 100, maxWidth: 100, editable: true, suppressMenu: true });

        let gridColForList: any = {
            headerName: 'Show in list',
            field: 'IsVisible',
            width: 150,
            minWidth: 100,
            maxWidth: 100,
            checkboxSelection: true,
            headerCheckboxSelection: false,
            filter: false,
            sortable: false,
            rowDrag: false,
            menuTabs: [],
            suppressMenu: true,
            editable: true,

        };

        gridColForList.valueFormatter = function (params: any) {
            return "";
        }
        //
        this.containerColumnDefs.push(gridColForList);
    }

    onGridReady_DocumentMXR(params) {
        _gridOptions_Document_MXR = params;
    }
    onGridReady_DocumentLOG(params) {
        _gridOptions_Document_Log = params;
    }
    onGridReady_DocumentGEN(params) {
        _gridOptions_Document_Gen = params;
    }
    onGridReady_DocumentDOC(params) {
        _gridOptions_Document_Doc = params;
    }
    onGridReady_DocumentBND(params) {
        _gridOptions_Document_Bnd = params;
    }


    onGridReady_ContainerMXR(params) {
        _gridOptions_Container_MXR = params;
    }
    onGridReady_ContainerLOG(params) {
        _gridOptions_Container_Log = params;
    }
    onGridReady_ContainerGEN(params) {
        _gridOptions_Container_Gen = params;
    }
    onGridReady_ContainerDOC(params) {
        _gridOptions_Container_Doc = params;
    }
    onGridReady_ContainerBND(params) {
        _gridOptions_Container_Bnd = params;
    }

    initDocumentColumnGrid() {
        this.gridOptionsDocumentCol_MXR = <GridOptions>{
            rowSelection: 'multiple',
            rowData: [],
            onGridReady: (params) => {

                //this.setDocumentGridColumns();
                if (this.gridOptionsDocumentCol_MXR.api != undefined) {
                    //if (this.Settings.Document_MX_Records_Column_Setting_Data.length > 0) {
                    if (this.Document_MX_Records_Column_Setting_Grid_Data.length > 0) {
                        this.gridOptionsDocumentCol_MXR.api.hideOverlay();
                    }
                    this.gridOptionsDocumentCol_MXR.api.setSuppressRowDrag(true);
                }
            },
            onModelUpdated: (event: any) => {

                this.gridOptionsDocumentCol_MXR.api.sizeColumnsToFit();
                try {
                    //this.totalCount = this.gridOptionsDocumentCol_MXR.api.getDisplayedRowCount();
                    this.gridOptionsDocumentCol_MXR.api.forEachNode(function (node) {
                        node.setSelected(node.data.IsVisible === true);
                    });
                } catch (e) {
                    console.log(e);
                }
            },
            defaultColDef: {
                // sortable: true,
                resizable: true,
            },
            context: {
                gridCtx: this
            }
        }

        this.gridOptionsDocumentCol_Log = <GridOptions>{
            rowSelection: 'multiple',
            rowData: [],
            onGridReady: (params) => {
                this.setDocumentGridColumns();
                if (this.gridOptionsDocumentCol_Log.api != undefined) {
                    //if (this.Settings.Document_MX_Records_Column_Setting_Data.length > 0) {
                    if (this.Document_Log_Column_Setting_Grid_Data.length > 0) {
                        this.gridOptionsDocumentCol_Log.api.hideOverlay();
                    }
                    this.gridOptionsDocumentCol_Log.api.setSuppressRowDrag(true);
                }
            },
            onModelUpdated: (event: any) => {

                this.gridOptionsDocumentCol_Log.api.sizeColumnsToFit();
                try {
                    //this.totalCount = this.gridOptionsDocumentCol_Log.api.getDisplayedRowCount();
                    this.gridOptionsDocumentCol_Log.api.forEachNode(function (node) {
                        node.setSelected(node.data.IsVisible === true);
                    });
                } catch (e) {
                    console.log(e);
                }
            },
            defaultColDef: {
                // sortable: true,
                resizable: true,
            },
            //suppressContextMenu: true,
            context: {
                gridContext: this //gridCtx
            },
            suppressScrollOnNewData: true,
        }

        this.gridOptionsDocumentCol_Gen = <GridOptions>{
            rowSelection: 'multiple',
            rowData: [],
            onGridReady: (params) => {
                this.setDocumentGridColumns();
                if (this.gridOptionsDocumentCol_Gen.api != undefined) {

                    this.gridOptionsDocumentCol_Gen.api.forEachNode(function (node) {
                        node.setSelected(true);
                    });
                    //if (this.Settings.Document_MX_Records_Column_Setting_Data.length > 0) {
                    if (this.Document_Gen_Column_Setting_Grid_Data.length > 0) {
                        this.gridOptionsDocumentCol_Gen.api.hideOverlay();
                        this.gridOptionsDocumentCol_Gen.api.forEachNode(node => {
                            node.rowIndex ? 0 : node.setSelected(true)
                        });
                    }
                    this.gridOptionsDocumentCol_Gen.api.setSuppressRowDrag(true);
                }
            },
            onModelUpdated: (event: any) => {

                this.gridOptionsDocumentCol_Gen.api.sizeColumnsToFit();
                try {

                    this.gridOptionsDocumentCol_Gen.api.forEachNode(function (node) {
                        node.setSelected(node.data.IsVisible === true);
                    });
                    //this.totalCount = this.gridOptionsDocumentCol_Gen.api.getDisplayedRowCount();
                } catch (e) {
                    console.log(e);
                }
            },
            defaultColDef: {
                // sortable: true,
                resizable: true,
            },
            context: {
                gridContext: this
            }
        }

        this.gridOptionsDocumentCol_Doc = <GridOptions>{
            rowSelection: 'multiple',
            rowData: [],
            onGridReady: (params) => {
                this.setDocumentGridColumns();
                if (this.gridOptionsDocumentCol_Doc.api != undefined) {

                    this.gridOptionsDocumentCol_Doc.api.forEachNode(function (node) {
                        node.setSelected(true);
                    });
                    //if (this.Settings.Document_MX_Records_Column_Setting_Data.length > 0) {
                    if (this.Document_Doc_Column_Setting_Grid_Data.length > 0) {
                        this.gridOptionsDocumentCol_Doc.api.hideOverlay();
                        this.gridOptionsDocumentCol_Doc.api.forEachNode(node => {
                            node.rowIndex ? 0 : node.setSelected(true)
                        });
                    }
                    this.gridOptionsDocumentCol_Doc.api.setSuppressRowDrag(true);
                }
            },
            onModelUpdated: (event: any) => {

                this.gridOptionsDocumentCol_Doc.api.sizeColumnsToFit();
                try {

                    this.gridOptionsDocumentCol_Doc.api.forEachNode(function (node) {
                        node.setSelected(node.data.IsVisible === true);
                    });
                } catch (e) {
                    console.log(e);
                }
            },
            defaultColDef: {
                // sortable: true,
                resizable: true,
            },
            context: {
                gridContext: this
            }
        }

        this.gridOptionsDocumentCol_Bnd = <GridOptions>{
            rowSelection: 'multiple',
            rowData: [],
            onGridReady: (params) => {
                this.setDocumentGridColumns();
                if (this.gridOptionsDocumentCol_Bnd.api != undefined) {

                    this.gridOptionsDocumentCol_Bnd.api.forEachNode(function (node) {
                        node.setSelected(true);
                    });
                    //if (this.Settings.Document_MX_Records_Column_Setting_Data.length > 0) {
                    if (this.Document_Bnd_Column_Setting_Grid_Data.length > 0) {
                        this.gridOptionsDocumentCol_Bnd.api.hideOverlay();
                        this.gridOptionsDocumentCol_Bnd.api.forEachNode(node => {
                            node.rowIndex ? 0 : node.setSelected(true)
                        });
                    }
                    this.gridOptionsDocumentCol_Bnd.api.setSuppressRowDrag(true);
                }
            },
            onModelUpdated: (event: any) => {

                this.gridOptionsDocumentCol_Bnd.api.sizeColumnsToFit();
                try {

                    this.gridOptionsDocumentCol_Bnd.api.forEachNode(function (node) {
                        node.setSelected(node.data.IsVisible === true);
                    });
                } catch (e) {
                    console.log(e);
                }
            },
            defaultColDef: {
                // sortable: true,
                resizable: true,
            },
            context: {
                gridContext: this
            }
        }
    }

    preSelectGridRows(event: any) {
        if (_gridOptions_Document_MXR && _gridOptions_Document_MXR.api) {
            _gridOptions_Document_MXR.api.sizeColumnsToFit();
            // this.setGridColumnWidth();
            if (this.Document_MX_Records_Column_Setting_Grid_Data.length) {
                _gridOptions_Document_MXR.api.forEachNode(function (node) {
                    let isVisible = node.data.IsVisible;
                    if (isVisible) {
                        //_gridOptions_Document_MXR.api.selectNode(node, true);
                        node.setSelected(true);
                    }
                });
            }
        }

        if (_gridOptions_Document_Log && _gridOptions_Document_Log.api) {
            _gridOptions_Document_Log.api.sizeColumnsToFit();
            if (this.Document_Log_Column_Setting_Grid_Data.length) {
                _gridOptions_Document_Log.api.forEachNode(function (node) {
                    let isVisible = node.data.IsVisible;
                    if (isVisible) {
                        node.setSelected(true);
                    }
                });
            }
        }

        if (_gridOptions_Document_Gen && _gridOptions_Document_Gen.api) {
            _gridOptions_Document_Gen.api.sizeColumnsToFit();
            if (this.Document_Gen_Column_Setting_Grid_Data.length) {
                _gridOptions_Document_Gen.api.forEachNode(function (node) {
                    let isVisible = node.data.IsVisible;
                    if (isVisible) {
                        node.setSelected(true);
                    }
                });
            }
        }

        if (_gridOptions_Document_Doc && _gridOptions_Document_Doc.api) {
            _gridOptions_Document_Doc.api.sizeColumnsToFit();
            if (this.Document_Doc_Column_Setting_Grid_Data.length) {
                _gridOptions_Document_Doc.api.forEachNode(function (node) {
                    let isVisible = node.data.IsVisible;
                    if (isVisible) {
                        node.setSelected(true);
                    }
                });
            }
        }

        if (_gridOptions_Document_Bnd && _gridOptions_Document_Bnd.api) {
            _gridOptions_Document_Bnd.api.sizeColumnsToFit();
            if (this.Document_Bnd_Column_Setting_Grid_Data.length) {
                _gridOptions_Document_Bnd.api.forEachNode(function (node) {
                    let isVisible = node.data.IsVisible;
                    if (isVisible) {
                        node.setSelected(true);
                    }
                });
            }
        }

        if (_gridOptions_Container_MXR && _gridOptions_Container_MXR.api) {
            _gridOptions_Container_MXR.api.sizeColumnsToFit();
            if (this.Container_MX_Records_Column_Setting_Grid_Data.length) {
                _gridOptions_Container_MXR.api.forEachNode(function (node) {
                    let isVisible = node.data.IsVisible;
                    if (isVisible) {
                        node.setSelected(true);
                    }
                });
            }
        }

        if (_gridOptions_Container_Log && _gridOptions_Container_Log.api) {
            _gridOptions_Container_Log.api.sizeColumnsToFit();
            if (this.Container_Log_Column_Setting_Grid_Data.length) {
                _gridOptions_Container_Log.api.forEachNode(function (node) {
                    let isVisible = node.data.IsVisible;
                    if (isVisible) {
                        node.setSelected(true);
                    }
                });
            }
        }

        if (_gridOptions_Container_Gen && _gridOptions_Container_Gen.api) {
            _gridOptions_Container_Gen.api.sizeColumnsToFit();
            if (this.Container_Gen_Column_Setting_Grid_Data.length) {
                _gridOptions_Container_Gen.api.forEachNode(function (node) {
                    let isVisible = node.data.IsVisible;
                    if (isVisible) {
                        node.setSelected(true);
                    }
                });
            }
        }

        if (_gridOptions_Container_Doc && _gridOptions_Container_Doc.api) {
            _gridOptions_Container_Doc.api.sizeColumnsToFit();
            if (this.Container_Doc_Column_Setting_Grid_Data.length) {
                _gridOptions_Container_Doc.api.forEachNode(function (node) {
                    let isVisible = node.data.IsVisible;
                    if (isVisible) {
                        node.setSelected(true);
                    }
                });
            }
        }

        if (_gridOptions_Container_Bnd && _gridOptions_Container_Bnd.api) {
            _gridOptions_Container_Bnd.api.sizeColumnsToFit();
            if (this.Container_Bnd_Column_Setting_Grid_Data.length) {
                _gridOptions_Container_Bnd.api.forEachNode(function (node) {
                    let isVisible = node.data.IsVisible;
                    if (isVisible) {
                        node.setSelected(true);
                    }
                });
            }
        }

    }



    setDocumentGridColumns(): void {

        this.documentColumnDefs = [];

        let gridCol: any = {
            headerName: '',
            field: '',
            width: 50,
            minWidth: 50,
            maxWidth: 50,
            checkboxSelection: false,
            headerCheckboxSelection: false,
            filter: false,
            sortable: false,
            rowDrag: true,
            menuTabs: [],
            suppressMenu: true,
            editable: false
        };
        // Add Column in Col Array
        this.documentColumnDefs.push(gridCol);

        this.documentColumnDefs.push({ headerName: 'Field Name', field: 'FieldName', minWidth: 100, width: 150, maxWidth: 150, suppressMenu: true });
        this.documentColumnDefs.push({ headerName: 'Label for List, Add & Edit', field: 'ColumnLabel', minWidth: 175, width: 175, maxWidth: 175, editable: true, suppressMenu: true });
        this.documentColumnDefs.push({ headerName: 'Width', field: 'Width', minWidth: 100, width: 100, maxWidth: 100, editable: true, suppressMenu: true });

        let gridColForList: any = {
            headerName: 'Show in list',
            field: 'IsVisible',
            width: 100,
            minWidth: 100,
            maxWidth: 100,
            checkboxSelection: true,
            headerCheckboxSelection: false,
            filter: false,
            sortable: false,
            rowDrag: false,
            menuTabs: [],
            suppressMenu: true,
            editable: true,

        };

        gridColForList.valueFormatter = function (params: any) {
            return "";
        }
        //
        this.documentColumnDefs.push(gridColForList);


    }


    IsValuesChanged(): boolean {
        switch (this.currentTabIndex) {
            case 'Application':

                //let loggedInTenant=this._sharedService.getLoggedInTenantId();
                //if (this._sharedService.getSubdomain().toLowerCase() == this._sharedService.Tenant_Internal)//(loggedInTenant=="0")
                if (this.Current_SubDomain_Tenant == this.SubDomain_Tenant_Internal) {
                    if (this.Settings.DateFormat != this.SettingsClone.DateFormat) {
                        return true;
                    }
                    if (this.Settings.TimeFormat != this.SettingsClone.TimeFormat) {
                        return true;
                    }
                    if (this.Settings.IntervalAssetReport != this.SettingsClone.IntervalAssetReport) {
                        return true;
                    }
                    if (this.Settings.IntervalJobReport != this.SettingsClone.IntervalJobReport) {
                        return true;
                    }
                    if (this.Settings.TenantLogo != this.SettingsClone.TenantLogo) {
                        return true;
                    }
                    return false;
                }
                else {
                    if (this.Settings.DateFormat != this.SettingsClone.DateFormat) {
                        return true;
                    }
                    if (this.Settings.TimeFormat != this.SettingsClone.TimeFormat) {
                        return true;
                    }
                    if (this.Settings.MaxDocumentSize != this.SettingsClone.MaxDocumentSize) {
                        return true;
                    }
                    if (this.Settings.TwoFactAuth != this.SettingsClone.TwoFactAuth) {
                        return true;
                    }
                    return false;
                }

            case 'User':

                if (this.Settings.ForgetAlias != this.SettingsClone.ForgetAlias) {
                    return true;
                }
                if (this.Settings.ForgetBody != this.SettingsClone.ForgetBody) {
                    return true;
                }
                if (this.Settings.ForgetFrom != this.SettingsClone.ForgetFrom) {
                    return true;
                }

                if (this.Settings.ForgetSubject != this.SettingsClone.ForgetSubject) {
                    return true;
                }
                if (this.Settings.NewAlias != this.SettingsClone.NewAlias) {
                    return true;
                }
                if (this.Settings.NewBody != this.SettingsClone.NewBody) {
                    return true;
                }
                if (this.Settings.NewFrom != this.SettingsClone.NewFrom) {
                    return true;
                }
                if (this.Settings.NewSubject != this.SettingsClone.NewSubject) {
                    return true;
                }
                return false;

            case 'API':

                if (this.Settings.IncomingIssueAlias != this.SettingsClone.IncomingIssueAlias) {
                    return true;
                }
                if (this.Settings.IncomingIssueBody != this.SettingsClone.IncomingIssueBody) {
                    return true;
                }
                if (this.Settings.IncomingIssueFrom != this.SettingsClone.IncomingIssueFrom) {
                    return true;
                }

                if (this.Settings.IncomingIssueSubject != this.SettingsClone.IncomingIssueSubject) {
                    return true;
                }
                if (this.Settings.IncomingIssueTo != this.SettingsClone.IncomingIssueTo) {
                    return true;
                }
                if (this.Settings.OutgoingIssueAlias != this.SettingsClone.OutgoingIssueAlias) {
                    return true;
                }
                if (this.Settings.OutgoingIssueBody != this.SettingsClone.OutgoingIssueBody) {
                    return true;
                }
                if (this.Settings.OutgoingIssueFrom != this.SettingsClone.OutgoingIssueFrom) {
                    return true;
                }
                if (this.Settings.OutgoingIssueSubject != this.SettingsClone.OutgoingIssueSubject) {
                    return true;
                }
                if (this.Settings.OutgoingIssueTo != this.SettingsClone.OutgoingIssueTo) {
                    return true;
                }
                return false;

            case 'Inbox':
                if (this.Settings.InboxAlias != this.SettingsClone.InboxAlias) {
                    return true;
                }
                if (this.Settings.InboxBody != this.SettingsClone.InboxBody) {
                    return true;
                }
                if (this.Settings.InboxFrom != this.SettingsClone.InboxFrom) {
                    return true;
                }
                return false;
            case 'Dashboard':
                if (this.Settings.StorageCapacity != this.SettingsClone.StorageCapacity) {
                    return true;
                }
                if (this.Settings.IntervalRecord != this.SettingsClone.IntervalRecord) {
                    return true;
                }
                if (this.Settings.IntervalStatus != this.SettingsClone.IntervalStatus) {
                    return true;
                }

                if (this.Settings.IntervalStats != this.SettingsClone.IntervalStats) {
                    return true;
                }
                if (this.Settings.IntervalAssetCount != this.SettingsClone.IntervalAssetCount) {
                    return true;
                }
                if (this.Settings.ContainerStatus != this.SettingsClone.ContainerStatus) {
                    return true;
                }
                if (this.Settings.IntervalInbox != this.SettingsClone.IntervalInbox) {
                    return true;
                }

                return false;

            case 'AutoSearch':

                if (this.Settings.IntervalAutoSearch != this.SettingsClone.IntervalAutoSearch) {
                    return true;
                }

                if (this.Settings.DefaultSelected != this.SettingsClone.DefaultSelected) {
                    return true;
                }
                if (this.Settings.DefaultNotSelected != this.SettingsClone.DefaultNotSelected) {
                    return true;
                }

                return false;
            case 'GapAnalyzer':

                if (this.Settings.IntervalGapAnalyzer != this.SettingsClone.IntervalGapAnalyzer) {
                    return true;
                }
                if (this.Settings.GapAnalyzerQualityStatus != this.SettingsClone.GapAnalyzerQualityStatus) {
                    return true;
                }
                return false;

            case 'Jobs':

                if (this.Settings.JobCompleteTo != this.SettingsClone.JobCompleteTo) {
                    return true;
                }
                if (this.Settings.JobCompleteSubject != this.SettingsClone.JobCompleteSubject) {
                    return true;
                }
                if (this.Settings.JobCompleteFrom != this.SettingsClone.JobCompleteFrom) {
                    return true;
                }

                if (this.Settings.JobCompleteBody != this.SettingsClone.JobCompleteBody) {
                    return true;
                }
                if (this.Settings.JobCompleteAlias != this.SettingsClone.JobCompleteAlias) {
                    return true;
                }
                if (this.Settings.JobNewTo != this.SettingsClone.JobNewTo) {
                    return true;
                }
                if (this.Settings.JobNewSubject != this.SettingsClone.JobNewSubject) {
                    return true;
                }
                if (this.Settings.JobNewFrom != this.SettingsClone.JobNewFrom) {
                    return true;
                }
                if (this.Settings.JobNewBody != this.SettingsClone.JobNewBody) {
                    return true;
                }
                if (this.Settings.JobNewAlias != this.SettingsClone.JobNewAlias) {
                    return true;
                }
                return false;

            case 'OCR':
                if (this.Settings.IntervalOCR != this.SettingsClone.IntervalOCR) {
                    return true;
                }
                return false;
            case 'Export':
                if (this.Settings.FTPHostName != this.SettingsClone.FTPHostName) {
                    return true;
                }
                if (this.Settings.FTPUserName != this.SettingsClone.FTPUserName) {
                    return true;
                }
                if (this.Settings.FTPPswd != this.SettingsClone.FTPPswd) {
                    return true;
                }

                if (this.Settings.RecordHeader != this.SettingsClone.RecordHeader) {
                    return true;
                }
                if (this.Settings.StatusHeader != this.SettingsClone.StatusHeader) {
                    return true;
                }
                if (this.Settings.JobHeader != this.SettingsClone.JobHeader) {
                    return true;
                }

                if (this.Settings.APIRecordHeader != this.SettingsClone.APIRecordHeader) {
                    return true;
                }
                if (this.Settings.APIStatusHeader != this.SettingsClone.APIStatusHeader) {
                    return true;
                }
                
                if (this.Settings.PDFAPIRecordHeader != this.SettingsClone.PDFAPIRecordHeader) {
                    return true;
                }
                if (this.Settings.PDFAPIRecordFooter != this.SettingsClone.PDFAPIRecordFooter) {
                    return true;
                }
                if (this.Settings.PDFAPIStatusFooter != this.SettingsClone.PDFAPIStatusFooter) {
                    return true;
                }
                if (this.Settings.PDFAPIStatusHeader != this.SettingsClone.PDFAPIStatusHeader) {
                    return true;
                }

                if (this.Settings.ExportCompleteAlias != this.SettingsClone.ExportCompleteAlias) {
                    return true;
                }
                if (this.Settings.ExportCompleteBody != this.SettingsClone.ExportCompleteBody) {
                    return true;
                }
                if (this.Settings.ExportCompleteFrom != this.SettingsClone.ExportCompleteFrom) {
                    return true;
                }
                if (this.Settings.ExportCompleteSubject != this.SettingsClone.ExportCompleteSubject) {
                    return true;
                }
                return false;
            case 'Alert':
                if (this.Settings.AlertAlias != this.SettingsClone.AlertAlias) {
                    return true;
                }
                if (this.Settings.AlertBody != this.SettingsClone.AlertBody) {
                    return true;
                }
                if (this.Settings.AlertFrom != this.SettingsClone.AlertFrom) {
                    return true;
                }
                if (this.Settings.AlertSubject != this.SettingsClone.AlertSubject) {
                    return true;
                }
                return false;
            case 'QualityStatus':
                if (this.Settings.MXRQualityStatus != this.SettingsClone.MXRQualityStatus) {
                    return true;
                }
                if (this.Settings.LOGQualityStatus != this.SettingsClone.LOGQualityStatus) {
                    return true;
                }
                if (this.Settings.GENQualityStatus != this.SettingsClone.GENQualityStatus) {
                    return true;
                }
                if (this.Settings.DOCQualityStatus != this.SettingsClone.DOCQualityStatus) {
                    return true;
                }
                if (this.Settings.BNDQualityStatus != this.SettingsClone.BNDQualityStatus) {
                    return true;
                }
                if (this.Settings.Document_MXR_Sort_By != this.SettingsClone.Document_MXR_Sort_By) {
                    return true;
                }
                if (this.Settings.Document_LOG_Sort_By != this.SettingsClone.Document_LOG_Sort_By) {
                    return true;
                }
                if (this.Settings.Document_GEN_Sort_By != this.SettingsClone.Document_GEN_Sort_By) {
                    return true;
                }
                if (this.Settings.Document_DOC_Sort_By != this.SettingsClone.Document_DOC_Sort_By) {
                    return true;
                }
                if (this.Settings.Document_BND_Sort_By != this.SettingsClone.Document_BND_Sort_By) {
                    return true;
                }

                if (this.Settings.Document_MXR_Order_By != this.SettingsClone.Document_MXR_Order_By) {
                    return true;
                }
                if (this.Settings.Document_LOG_Order_By != this.SettingsClone.Document_LOG_Order_By) {
                    return true;
                }
                if (this.Settings.Document_GEN_Order_By != this.SettingsClone.Document_GEN_Order_By) {
                    return true;
                }
                if (this.Settings.Document_DOC_Order_By != this.SettingsClone.Document_DOC_Order_By) {
                    return true;
                }
                if (this.Settings.Document_BND_Order_By != this.SettingsClone.Document_BND_Order_By) {
                    return true;
                }


                return false;

            case 'Container':
                if (this.Settings.Container_MXRQualityStatus != this.SettingsClone.Container_MXRQualityStatus) {
                    return true;
                }
                if (this.Settings.Container_LOGQualityStatus != this.SettingsClone.Container_LOGQualityStatus) {
                    return true;
                }
                if (this.Settings.Container_GENQualityStatus != this.SettingsClone.Container_GENQualityStatus) {
                    return true;
                }
                if (this.Settings.DOCQualityStatus != this.SettingsClone.DOCQualityStatus) {
                    return true;
                }
                if (this.Settings.BNDQualityStatus != this.SettingsClone.BNDQualityStatus) {
                    return true;
                }

                if (this.Settings.Container_MXR_Sort_By != this.SettingsClone.Container_MXR_Sort_By) {
                    return true;
                }
                if (this.Settings.Container_LOG_Sort_By != this.SettingsClone.Container_LOG_Sort_By) {
                    return true;
                }
                if (this.Settings.Container_GEN_Sort_By != this.SettingsClone.Container_GEN_Sort_By) {
                    return true;
                }
                if (this.Settings.Container_DOC_Sort_By != this.SettingsClone.Container_DOC_Sort_By) {
                    return true;
                }
                if (this.Settings.Container_BND_Sort_By != this.SettingsClone.Container_BND_Sort_By) {
                    return true;
                }


                if (this.Settings.Container_MXR_Order_By != this.SettingsClone.Container_MXR_Order_By) {
                    return true;
                }
                if (this.Settings.Container_LOG_Order_By != this.SettingsClone.Container_LOG_Order_By) {
                    return true;
                }
                if (this.Settings.Container_GEN_Order_By != this.SettingsClone.Container_GEN_Order_By) {
                    return true;
                }
                if (this.Settings.Container_DOC_Order_By != this.SettingsClone.Container_DOC_Order_By) {
                    return true;
                }
                if (this.Settings.Container_BND_Order_By != this.SettingsClone.Container_BND_Order_By) {
                    return true;
                }

                return false;

            default:
        }
        return false;
    }


    GetContainerColumnData() {

        var ContainerColumnSetting_MXR = [];
        var ContainerColumnSetting_LOG = [];
        var ContainerColumnSetting_GEN = [];
        var ContainerColumnSetting_DOC = [];
        var ContainerColumnSetting_BND = [];

        _gridOptions_Container_MXR.api.forEachNode(function (rowNode, index) {

            var rowData = rowNode.data;
            rowData.IsVisible = rowNode.selected;
            rowData.SequenceNo = index + 1;
            ContainerColumnSetting_MXR.push(rowData);
        });

        _gridOptions_Container_Log.api.forEachNode(function (rowNode, index) {

            var rowData = rowNode.data;
            rowData.IsVisible = rowNode.selected;
            rowData.SequenceNo = index + 1;
            ContainerColumnSetting_LOG.push(rowData);
        });

        _gridOptions_Container_Gen.api.forEachNode(function (rowNode, index) {

            var rowData = rowNode.data;
            rowData.IsVisible = rowNode.selected;
            rowData.SequenceNo = index + 1;
            ContainerColumnSetting_GEN.push(rowData);
        });

        _gridOptions_Container_Doc.api.forEachNode(function (rowNode, index) {

            var rowData = rowNode.data;
            rowData.IsVisible = rowNode.selected;
            rowData.SequenceNo = index + 1;
            ContainerColumnSetting_DOC.push(rowData);
        });

        _gridOptions_Container_Bnd.api.forEachNode(function (rowNode, index) {

            var rowData = rowNode.data;
            rowData.IsVisible = rowNode.selected;
            rowData.SequenceNo = index + 1;
            ContainerColumnSetting_BND.push(rowData);
        });


        var containerColumnSettings = {
            ContainerColumnSetting_MXR: ContainerColumnSetting_MXR,
            ContainerColumnSetting_LOG: ContainerColumnSetting_LOG,
            ContainerColumnSetting_GEN: ContainerColumnSetting_GEN,
            ContainerColumnSetting_DOC: ContainerColumnSetting_DOC,
            ContainerColumnSetting_BND: ContainerColumnSetting_BND
        };

        return containerColumnSettings;

    }

    GetDocumentColumnData() {
        var DocumentColumnSetting_MXR = [];
        var DocumentColumnSetting_LOG = [];
        var DocumentColumnSetting_GEN = [];
        var DocumentColumnSetting_DOC = [];
        var DocumentColumnSetting_BND = [];

        _gridOptions_Document_MXR.api.forEachNode(function (rowNode, index) {

            var rowData = rowNode.data;
            rowData.IsVisible = rowNode.selected;
            rowData.SequenceNo = index + 1;
            DocumentColumnSetting_MXR.push(rowData);
        });

        _gridOptions_Document_Log.api.forEachNode(function (rowNode, index) {

            var rowData = rowNode.data;
            rowData.IsVisible = rowNode.selected;
            rowData.SequenceNo = index + 1;
            DocumentColumnSetting_LOG.push(rowData);
        });

        _gridOptions_Document_Gen.api.forEachNode(function (rowNode, index) {

            var rowData = rowNode.data;
            rowData.IsVisible = rowNode.selected;
            rowData.SequenceNo = index + 1;
            DocumentColumnSetting_GEN.push(rowData);
        });

        _gridOptions_Document_Doc.api.forEachNode(function (rowNode, index) {

            var rowData = rowNode.data;
            rowData.IsVisible = rowNode.selected;
            rowData.SequenceNo = index + 1;
            DocumentColumnSetting_DOC.push(rowData);
        });

        _gridOptions_Document_Bnd.api.forEachNode(function (rowNode, index) {

            var rowData = rowNode.data;
            rowData.IsVisible = rowNode.selected;
            rowData.SequenceNo = index + 1;
            DocumentColumnSetting_BND.push(rowData);
        });



        var documentColumnSettings = {
            DocumentColumnSetting_MXR: DocumentColumnSetting_MXR,
            DocumentColumnSetting_LOG: DocumentColumnSetting_LOG,
            DocumentColumnSetting_GEN: DocumentColumnSetting_GEN,
            DocumentColumnSetting_DOC: DocumentColumnSetting_DOC,
            DocumentColumnSetting_BND: DocumentColumnSetting_BND
        };

        return documentColumnSettings;

    }


    setSettings(settingType: string, form: NgForm) {
        let body: any;
        let configValues: any[] = [];
        this.Settings;
        switch (settingType) {
            case 'Application':
                //let loggedInTenant=this._sharedService.getLoggedInTenantId();

                //if (this._sharedService.getSubdomain().toLowerCase() == this._sharedService.Tenant_Internal)//(loggedInTenant=="0")
                if (this.Current_SubDomain_Tenant == this.SubDomain_Tenant_Internal) {
                    configValues.push({ Key: "TimeFormat", Value: this.Settings.TimeFormat });
                    configValues.push({ Key: "DateFormat", Value: this.getValue(this.Settings.DateFormat, "DateFormat") });
                    configValues.push({ Key: "DateSeparater", Value: this.getValue(this.Settings.DateFormat, "DateSeparater") });
                    configValues.push({ Key: "DayIndex", Value: this.getValue(this.Settings.DateFormat, "DayIndex") });
                    configValues.push({ Key: "MonthIndex", Value: this.getValue(this.Settings.DateFormat, "MonthIndex") });
                    configValues.push({ Key: "YearIndex", Value: this.getValue(this.Settings.DateFormat, "YearIndex") });
                    configValues.push({ Key: "Batch_Interval_DataCopy_Asset", Value: this.Settings.IntervalAssetReport });
                    configValues.push({ Key: "Batch_Interval_DataCopy_DpJob", Value: this.Settings.IntervalJobReport });
                }
                else {
                    configValues.push({ Key: "TimeFormat", Value: this.Settings.TimeFormat });
                    configValues.push({ Key: "DateFormat", Value: this.getValue(this.Settings.DateFormat, "DateFormat") });
                    configValues.push({ Key: "DateSeparater", Value: this.getValue(this.Settings.DateFormat, "DateSeparater") });
                    configValues.push({ Key: "DayIndex", Value: this.getValue(this.Settings.DateFormat, "DayIndex") });
                    configValues.push({ Key: "MonthIndex", Value: this.getValue(this.Settings.DateFormat, "MonthIndex") });
                    configValues.push({ Key: "YearIndex", Value: this.getValue(this.Settings.DateFormat, "YearIndex") });
                    configValues.push({ Key: "Max_Document_Size", Value: this.Settings.MaxDocumentSize });
                    configValues.push({ Key: "Two_Factor_Auth", Value: this.Settings.TwoFactAuth });
                    configValues.push({ Key: "Tenant_Logo", Value: this.Settings.TenantLogo });
                }

                break;
            case 'User':
                configValues.push({ Key: "Forget_Pswd_Email_From_Alias", Value: this.Settings.ForgetAlias });
                configValues.push({ Key: "Forget_Pswd_Email_Body", Value: this._sharedService.filterHTMLBody(this.Settings.ForgetBody) });
                configValues.push({ Key: "Forget_Pswd_Email_From", Value: this.Settings.ForgetFrom });
                configValues.push({ Key: "Forget_Pswd_Email_Subject", Value: this.Settings.ForgetSubject });

                configValues.push({ Key: "Forget_Shared_Key_Email_Alias", Value: this.Settings.ShardKeyAlias });
                configValues.push({ Key: "Forget_Shared_Key_Email_Body", Value: this._sharedService.filterHTMLBody(this.Settings.ShardKeyBody) });
                configValues.push({ Key: "Forget_Shared_Key_Email_From", Value: this.Settings.ShardKeyFrom });
                configValues.push({ Key: "Forget_Shared_Key_Email_Subject", Value: this.Settings.ShardKeySubject });

                configValues.push({ Key: "New_User_Email_From_Alias", Value: this.Settings.NewAlias });
                configValues.push({ Key: "New_User_Email_Body", Value: this._sharedService.filterHTMLBody(this.Settings.NewBody) });
                configValues.push({ Key: "New_User_Email_From", Value: this.Settings.NewFrom });
                configValues.push({ Key: "New_User_Email_Subject", Value: this.Settings.NewSubject });


                break;
            case 'API':
                configValues.push({ Key: "Feed_Issue_Email_From_Alias", Value: this.Settings.IncomingIssueAlias });
                configValues.push({ Key: "Feed_Issue_Body", Value: this._sharedService.filterHTMLBody(this.Settings.IncomingIssueBody) });
                configValues.push({ Key: "Feed_Issue_Email_From", Value: this.removeExtraSpaces(this.Settings.IncomingIssueFrom) });
                configValues.push({ Key: "Feed_Issue_Subject", Value: this.Settings.IncomingIssueSubject });
                configValues.push({ Key: "Feed_Issue_To", Value: this.removeExtraSpaces(this.Settings.IncomingIssueTo) });

                configValues.push({ Key: "Feed_Outgoing_Issue_Email_From_Alias", Value: this.Settings.OutgoingIssueAlias });
                configValues.push({ Key: "Feed_Outgoing_Issue_Body", Value: this._sharedService.filterHTMLBody(this.Settings.OutgoingIssueBody) });
                configValues.push({ Key: "Feed_Outgoing_Issue_Email_From", Value: this.removeExtraSpaces(this.Settings.OutgoingIssueFrom) });
                configValues.push({ Key: "Feed_Outgoing_Issue_Subject", Value: this.Settings.OutgoingIssueSubject });
                configValues.push({ Key: "Feed_Outgoing_Issue_To", Value: this.removeExtraSpaces(this.Settings.OutgoingIssueTo) });

                break;
            case 'Inbox':
                configValues.push({ Key: "Comm_Update_Email_From_Alias", Value: this.Settings.InboxAlias });
                configValues.push({ Key: "Comm_Update_Email_Body", Value: this._sharedService.filterHTMLBody(this.Settings.InboxBody) });
                configValues.push({ Key: "Comm_Update_Email_From", Value: this.Settings.InboxFrom });

                break;
            case 'Dashboard':

                configValues.push({ Key: "Dashboard_Storage_Usage_Capacity", Value: this.Settings.StorageCapacity.toString() });
                configValues.push({ Key: "Batch_Interval_Dashboard_Record", Value: this.Settings.IntervalRecord });
                configValues.push({ Key: "Batch_Interval_Dashboard_Status", Value: this.Settings.IntervalStatus });
                configValues.push({ Key: "Batch_Interval_Dashboard_Stats", Value: this.Settings.IntervalStats });
                configValues.push({ Key: "Batch_Interval_Dashboard_Asset", Value: this.Settings.IntervalAssetCount });
                configValues.push({ Key: "Batch_Interval_Dashboard_Inbox", Value: this.Settings.IntervalInbox });
            configValues.push({ Key: "Batch_Interval_Dashboard_ContainerStatus", Value: this.Settings.ContainerStatus });

                break;
            case 'AutoSearch':

                configValues.push({ Key: "Batch_Interval_AutoSearch", Value: this.Settings.IntervalAutoSearch });
                configValues.push({ Key: "DefaultSelectedCondition", Value: this.Settings.DefaultSelected });
                configValues.push({ Key: "DefaultNotSelectedCondition", Value: this.Settings.DefaultNotSelected });

                break;
            case 'GapAnalyzer':

                configValues.push({ Key: "Batch_Interval_Gap_Analyser", Value: this.Settings.IntervalGapAnalyzer });
                configValues.push({ Key: "Gap_Analyser_QualityStatus", Value: this.Settings.GapAnalyzerQualityStatus });

                break;
            case 'Jobs':

                configValues.push({ Key: "Job_New_Email_From_Alias", Value: this.Settings.JobNewAlias });
                configValues.push({ Key: "Job_New_Email_Body", Value: this._sharedService.filterHTMLBody(this.Settings.JobNewBody) });
                configValues.push({ Key: "Job_New_Email_From", Value: this.removeExtraSpaces(this.Settings.JobNewFrom) });
                configValues.push({ Key: "Job_New_Email_Subject", Value: this.Settings.JobNewSubject });
                configValues.push({ Key: "Job_New_Email_To", Value: this.removeExtraSpaces(this.Settings.JobNewTo) });

                configValues.push({ Key: "Job_Complete_Email_Alias", Value: this.Settings.JobCompleteAlias });
                configValues.push({ Key: "Job_Complete_Email_Body", Value: this._sharedService.filterHTMLBody(this.Settings.JobCompleteBody) });
                configValues.push({ Key: "Job_Complete_Email_From", Value: this.removeExtraSpaces(this.Settings.JobCompleteFrom) });
                configValues.push({ Key: "Job_Complete_Email_Subject", Value: this.Settings.JobCompleteSubject });
                configValues.push({ Key: "Job_Complete_Email_To", Value: this.removeExtraSpaces(this.Settings.JobCompleteTo) });

                configValues.push({ Key: "Job_Update_Email_Alias", Value: this.Settings.JobUpdateAlias });
                configValues.push({ Key: "Job_Update_Email_Body", Value: this._sharedService.filterHTMLBody(this.Settings.JobUpdateBody) });
                configValues.push({ Key: "Job_Update_Email_From", Value: this.removeExtraSpaces(this.Settings.JobUpdateFrom) });
                configValues.push({ Key: "Job_Update_Email_Subject", Value: this.Settings.JobUpdateSubject });
                configValues.push({ Key: "Job_Update_Email_To", Value: this.removeExtraSpaces(this.Settings.JobUpdateTo) });

                break;
            case 'OCR':
                configValues.push({ Key: "Batch_Interval_OCR", Value: this.Settings.IntervalOCR });
                break;
            case 'Export':
                configValues.push({ Key: "FTP_Hostname", Value: this.Settings.FTPHostName });
                configValues.push({ Key: "FTP_Username", Value: this.Settings.FTPUserName });
                configValues.push({ Key: "FTP_Pswd", Value: this.Settings.FTPPswd });

                configValues.push({ Key: "Excel_Export_Header_Records", Value: this.Settings.RecordHeader });
                configValues.push({ Key: "Excel_Export_Header_Status", Value: this.Settings.StatusHeader });
                configValues.push({ Key: "Excel_Export_Header_Jobs", Value: this.Settings.JobHeader });

                configValues.push({ Key: "Excel_API_Export_Header_Records", Value: this.Settings.APIRecordHeader });
                configValues.push({ Key: "Excel_API_Export_Header_Status", Value: this.Settings.APIStatusHeader });

                configValues.push({ Key: "PDF_API_Export_Header_Status", Value: this.Settings.PDFAPIStatusHeader });
                configValues.push({ Key: "PDF_API_Export_Footer_Status", Value: this.Settings.PDFAPIStatusFooter });
                // configValues.push({ Key: "PDF_API_Export_Header_Records", Value: this.Settings.PDFAPIRecordHeader });
                // configValues.push({ Key: "PDF_API_Export_Footer_Records", Value: this.Settings.PDFAPIRecordFooter });

                configValues.push({ Key: "Feed_Outgoing_Email_From_Alias", Value: this.Settings.ExportCompleteAlias });
                configValues.push({ Key: "Feed_Outgoing_Email_Body", Value: this._sharedService.filterHTMLBody(this.Settings.ExportCompleteBody) });
                configValues.push({ Key: "Feed_Outgoing_Email_From", Value: this.Settings.ExportCompleteFrom });
                configValues.push({ Key: "Feed_Outgoing_Email_Subject", Value: this.Settings.ExportCompleteSubject });

                break;
            case 'Alert':
                configValues.push({ Key: "Alert_Email_From_Alias", Value: this.Settings.AlertAlias });
                configValues.push({ Key: "Alert_Email_Body", Value: this._sharedService.filterHTMLBody(this.Settings.AlertBody) });
                configValues.push({ Key: "Alert_Email_From", Value: this.Settings.AlertFrom });
                configValues.push({ Key: "Alert_Email_Subject", Value: this.Settings.AlertSubject });
                break;
            case 'QualityStatus':

                var documentSettings = this.GetDocumentColumnData();

                configValues.push({ Key: "MX_Records_Quality_Process", Value: this.Settings.MXRQualityStatus });
                configValues.push({ Key: "Log_Books_Quality_Process", Value: this.Settings.LOGQualityStatus });
                configValues.push({ Key: "General_Quality_Process", Value: this.Settings.GENQualityStatus });
                configValues.push({ Key: "Documents_Quality_Process", Value: this.Settings.DOCQualityStatus });
                configValues.push({ Key: "Binders_Quality_Process", Value: this.Settings.BNDQualityStatus });

                configValues.push({ Key: "Document_MX_Records_Column_Setting", Value: JSON.stringify(documentSettings.DocumentColumnSetting_MXR) });
                configValues.push({ Key: "Document_Log_Books_Column_Setting", Value: JSON.stringify(documentSettings.DocumentColumnSetting_LOG) });
                configValues.push({ Key: "Document_General_Column_Setting", Value: JSON.stringify(documentSettings.DocumentColumnSetting_GEN) });
                configValues.push({ Key: "Document_Documents_Column_Setting", Value: JSON.stringify(documentSettings.DocumentColumnSetting_DOC) });
                configValues.push({ Key: "Document_Binders_Column_Setting", Value: JSON.stringify(documentSettings.DocumentColumnSetting_BND) });

                // configValues.push({ Key: "Document_MXR_Sort_By", Value: this.Settings.Document_MXR_Sort_By });
                // configValues.push({ Key: "Document_LOG_Sort_By", Value: this.Settings.Document_LOG_Sort_By });
                // configValues.push({ Key: "Document_GEN_Sort_By", Value: this.Settings.Document_GEN_Sort_By });
                // configValues.push({ Key: "Document_DOC_Sort_By", Value: this.Settings.Document_DOC_Sort_By });
                // configValues.push({ Key: "Document_BND_Sort_By", Value: this.Settings.Document_BND_Sort_By });


                configValues.push({ Key: "Document_MXR_Sort_By", Value: this.Settings.Document_MXR_Sort_By + '||' + this.Settings.Document_MXR_Order_By });
                configValues.push({ Key: "Document_LOG_Sort_By", Value: this.Settings.Document_LOG_Sort_By + '||' + this.Settings.Document_LOG_Order_By });
                configValues.push({ Key: "Document_GEN_Sort_By", Value: this.Settings.Document_GEN_Sort_By + '||' + this.Settings.Document_GEN_Order_By });
                configValues.push({ Key: "Document_DOC_Sort_By", Value: this.Settings.Document_DOC_Sort_By + '||' + this.Settings.Document_DOC_Order_By });
                configValues.push({ Key: "Document_BND_Sort_By", Value: this.Settings.Document_BND_Sort_By + '||' + this.Settings.Document_BND_Order_By });



                break;

            case 'Container':
                var containerSettings = this.GetContainerColumnData();

                configValues.push({ Key: "Container_MX_Records_Quality_Process", Value: this.Settings.Container_MXRQualityStatus });
                configValues.push({ Key: "Container_Log_Books_Quality_Process", Value: this.Settings.Container_LOGQualityStatus });
                configValues.push({ Key: "Container_General_Quality_Process", Value: this.Settings.Container_GENQualityStatus });
                configValues.push({ Key: "Container_Documents_Quality_Process", Value: this.Settings.Container_DOCQualityStatus });
                configValues.push({ Key: "Container_Binders_Quality_Process", Value: this.Settings.Container_BNDQualityStatus });


                configValues.push({ Key: "Container_MX_Records_Column_Setting", Value: JSON.stringify(containerSettings.ContainerColumnSetting_MXR) });
                configValues.push({ Key: "Container_Log_Books_Column_Setting", Value: JSON.stringify(containerSettings.ContainerColumnSetting_LOG) });
                configValues.push({ Key: "Container_General_Column_Setting", Value: JSON.stringify(containerSettings.ContainerColumnSetting_GEN) });
                configValues.push({ Key: "Container_Documents_Column_Setting", Value: JSON.stringify(containerSettings.ContainerColumnSetting_DOC) });
                configValues.push({ Key: "Container_Binders_Column_Setting", Value: JSON.stringify(containerSettings.ContainerColumnSetting_BND) });

                // configValues.push({ Key: "Container_MXR_Sort_By", Value: this.Settings.Container_MXR_Sort_By });
                // configValues.push({ Key: "Container_LOG_Sort_By", Value: this.Settings.Container_LOG_Sort_By });
                // configValues.push({ Key: "Container_GEN_Sort_By", Value: this.Settings.Container_GEN_Sort_By });
                // configValues.push({ Key: "Container_DOC_Sort_By", Value: this.Settings.Container_DOC_Sort_By });
                // configValues.push({ Key: "Container_BND_Sort_By", Value: this.Settings.Container_BND_Sort_By });

                configValues.push({ Key: "Container_MXR_Sort_By", Value: this.Settings.Container_MXR_Sort_By + '||' + this.Settings.Container_MXR_Order_By });
                configValues.push({ Key: "Container_LOG_Sort_By", Value: this.Settings.Container_LOG_Sort_By + '||' + this.Settings.Container_LOG_Order_By });
                configValues.push({ Key: "Container_GEN_Sort_By", Value: this.Settings.Container_GEN_Sort_By + '||' + this.Settings.Container_GEN_Order_By });
                configValues.push({ Key: "Container_DOC_Sort_By", Value: this.Settings.Container_DOC_Sort_By + '||' + this.Settings.Container_DOC_Order_By });
                configValues.push({ Key: "Container_BND_Sort_By", Value: this.Settings.Container_BND_Sort_By + '||' + this.Settings.Container_BND_Order_By });


                break;
            default:
        }
        //this._sharedService.showLoader("Processing...");
        //body = new settingsUpdateEntity(configValues, settingType);
        body = {
            settingType: settingType,
            setting: configValues
        };

        this._settingsService.setSettings(body)
        .subscribe({
            next: (response) => {
                form.resetForm();
                this.getSettings(settingType, false, null);
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                this._sharedService.showToast(
                    { 
                        severity: 'success', 
                        summary: 'Success', 
                        detail: 'Settings updated succesfully.' 
                    }
                );
                this.changeButtonsStatus(false);
            },
            error: (error) => {
                this._sharedService.hideLoader();
                form.resetForm();
                this._sharedService.clearToast();
        
                this._sharedService.showToast(
                    { 
                        severity: 'error', 
                        summary: 'Error Occured', 
                        detail: 'Settings not updated, please try again.' 
                    }
                );
        
                this.changeButtonsStatus(false);
            }
        });
    }

    getValue(value: string, key: string): string {
        let dateParts: string[] = value.split('||');
        switch (key) {
            case 'DateFormat':
                return dateParts[0];
            case 'DateSeparater':
                return dateParts[1];
            case 'DayIndex':
                return dateParts[2];
            case 'MonthIndex':
                return dateParts[3];
            case 'YearIndex':
                return dateParts[4];
            default:
                return null;
        }

    }

    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }

    confirmationDialogue(settingType: string, event: Event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
        this.changeTabsStatus(false, this.currentTabIndex);

        this._confirmationService.confirm({
            message: 'You have unsaved changes!',
            header: 'Are you sure you want to discard changes?',
            icon: 'fa fa-trash',
            accept: () => {
                this.getSettings(settingType, false, null);
                // Discard and move to clicked tab
            },
            reject: () => {

            }
        });

    }

    changeTabsStatus(flag: boolean, settingType: string) {
        this.isActiveTabApplication = settingType == "Application" ? true : flag;
        this.isActiveTabUser = settingType == "User" ? true : flag;
        this.isActiveTabInbox = settingType == "Inbox" ? true : flag;
        this.isActiveTabDashboard = settingType == "Dashboard" ? true : flag;
        this.isActiveTabAutoSearch = settingType == "AutoSearch" ? true : flag;
        this.isActiveTabGapAnalyzer = settingType == "GapAnalyzer" ? true : flag;
        this.isActiveTabJobs = settingType == "Jobs" ? true : flag;
        this.isActiveTabExport = settingType == "Export" ? true : flag;
        this.isActiveTabAPI = settingType == "API" ? true : flag;
        this.isActiveTabOCR = settingType == "OCR" ? true : flag;
        this.isActiveTabAlert = settingType == "Alert" ? true : flag;
        this.isActiveTabQualityStatus = settingType == "QualityStatus" ? true : flag;
        this.isActiveTabContainer = settingType == "Container" ? true : flag;
    }

    frgtemailbodyFocus(event: any) {
        // this.userForgotEmailRef = event.editor;
        console.log("got focus");
    }

    removeExtraSpaces(value: string) {
        let newString = null;
        if (value && value != "") {
            newString = value.replace(/\s/g, '');
        }
        return newString;
    }

    onChangeUpload(files: any) {
        if (files.length === 0)
            return;

        var mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File', detail: 'Only image files are allowed.' });
            return;
        }
        let fileSize = files[0].size;
        if (fileSize > maxAllowedImageFileSize) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'File Size Limit Exceeded', detail: 'Maximum allowed file size is 300kb.' });
            return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        //let bindaryResult= btoa(reader.result);
        reader.onload = (_event) => {

            this.imgURL = reader.result;
            this.Settings.TenantLogo = this.imgURL;
        }
    }
    //// #endregion


    ToggleChecked_MX() {

        if (this.Settings.MXRQualityStatus) {
            this.Settings.MXRQualityStatus = false;
        }
        else {
            this.Settings.MXRQualityStatus = true;
        }
    }
    ToggleChecked_Container_MXR() {

        if (this.Settings.Container_MXRQualityStatus) {
            this.Settings.Container_MXRQualityStatus = false;
        }
        else {
            this.Settings.Container_MXRQualityStatus = true;
        }
    }

    ToggleChecked_Log() {

        if (this.Settings.LOGQualityStatus) {
            this.Settings.LOGQualityStatus = false;
        }
        else {
            this.Settings.LOGQualityStatus = true;
        }
    }
    ToggleChecked_Container_Log() {

        if (this.Settings.Container_LOGQualityStatus) {
            this.Settings.Container_LOGQualityStatus = false;
        }
        else {
            this.Settings.Container_LOGQualityStatus = true;
        }
    }


    ToggleChecked_G() {

        if (this.Settings.GENQualityStatus) {
            this.Settings.GENQualityStatus = false;
        }
        else {
            this.Settings.GENQualityStatus = true;
        }
    }

    ToggleChecked_Doc() {

        if (this.Settings.DOCQualityStatus) {
            this.Settings.DOCQualityStatus = false;
        }
        else {
            this.Settings.DOCQualityStatus = true;
        }
    }

    ToggleChecked_Container_Gen() {

        if (this.Settings.Container_GENQualityStatus) {
            this.Settings.Container_GENQualityStatus = false;
        }
        else {
            this.Settings.Container_GENQualityStatus = true;
        }
    }

    ToggleChecked_Container_Doc() {

        if (this.Settings.Container_DOCQualityStatus) {
            this.Settings.Container_DOCQualityStatus = false;
        }
        else {
            this.Settings.Container_DOCQualityStatus = true;
        }
    }

    ToggleChecked_Bnd() {

        if (this.Settings.BNDQualityStatus) {
            this.Settings.BNDQualityStatus = false;
        }
        else {
            this.Settings.BNDQualityStatus = true;
        }
    }

    ToggleChecked_Container_Bnd() {

        if (this.Settings.Container_BNDQualityStatus) {
            this.Settings.Container_BNDQualityStatus = false;
        }
        else {
            this.Settings.Container_BNDQualityStatus = true;
        }
    }

    populate_Sort_By_Setting_Options(collArr: any[]) {
        let _collDataList : any[] = new Array();
        Object.assign(_collDataList, collArr);
        //_collDataList = collArr;
        var dropDownList: SelectItem[] = [];
        _collDataList = _collDataList.sort((a, b) => a.ColumnLabel.localeCompare(b.ColumnLabel));
        _collDataList.forEach(col => {
            var colObj = { value: col.FieldName, label: col.ColumnLabel };
            dropDownList.push(colObj);
        });

        return dropDownList;
    }

    populate_Order_By_Options() {

        var orderByList: SelectItem[] = [];
        orderByList.push({ value: 'asc', label: "Ascending" });
        orderByList.push({ value: 'desc', label: "Descending" });
        return orderByList;
    }


}
