//#region References 

//Components
import { Component, OnInit, Input, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GridOptions, GridApi, ColumnApi, ColDef, RowNode, CssClassApplier, Column, RefreshCellsParams, IRowNode } from "ag-grid-community";
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
//Services
import { SharedService } from '../../../shared/services/shared.service';
import { ConfirmationService } from 'primeng/api';
import { MappingModel, Mapping } from './field-mapping';

import { datafeed } from '../list-datafeed/data-feed';
import { NgForm } from '@angular/forms';
import { ContainerService } from '../../../records/services/container/container.service';
import { APIService } from '../../services/api.service';
import * as moment from 'moment/moment';
//import tabledragger from 'table-dragger/table-dragger/dist';
var dateTimeConfig: IDateFormatInfo;
// import { CheckboxUniqueComponent } from './checkbox-unique.component';
// import { CheckboxHistoryComponent } from './checkbox-history.component';
import { CustomButtonComponent } from './custom-button.component';
import { constants } from 'os';
import { HelpRequest, Help } from '../../../shared/services/Help/help';
import { HelpService } from '../../../shared/services/Help/help.service';
import { ViewDataTypeService } from '../../../admin/services/view-data-type/view-data-type.service';
var allNodesMap: RowNode[] = [];
var allNodesAddMap: RowNode[] = [];
//#endregion
//var mapcolumns: any[] = [];

//#region Type Decorator
@Component({
    selector: 'field-mapping',
    templateUrl: './field-mapping.component.html',
    providers: [APIService, ConfirmationService, ViewDataTypeService]
})
//#endregion

export class FieldMappingComponent implements OnInit {

    //#region Locals Declaration

    dialogTitle: string = "";
    feedId: string;
    selectedfeedId: string = "";
    WorkOrderLabel: string = '';
    screenName: string = "";
    routeName: string = "";
    FeedName: string = '';
    FeedMappingHelpContent: string = '';

    isSubmitted: boolean = false;
    display: boolean = false;
    displayHelpWindow: boolean = false;
    htmlMappingHelp: string = '';

    _targetType: string = "";//// "RDOC"
    _targetDetail: string = "";////"Document Metadata"
    _sourceDetail: string = "";////"WorkOrderHeader"
    _sourceType: string = "";//// "Status"
    _dataType: string = "";//// "XML, CSV, NULL"

    model: MappingModel;

    filecolumns: any[] = [];
    mapcolumns: any[] = [];

    // mapcolumnsTemp: any[] = [];
    //fileColumnsLabels: string[] = [];
    columnDefs: any[];
    public gridData: any[] = [];
    private selectedRowData: any = null;
    private totalCount: number = 0;

    public gridOptions: GridOptions;
    public gridOptionsMapping: GridOptions;
    public gridDataMapping: any[] = [];
    columnDefsMapping: ColDef[];
    public gridApiMapping: GridApi;
    public gridColumnApiMapping: ColumnApi;

    public gridOptionsMappingList: GridOptions;
    public gridDataMappingList: any[] = [];
    columnDefsMappingList: ColDef[];
    public gridApiMappingList: GridApi;
    public gridColumnApiMappingList: ColumnApi;
    public fixedValueText: string;

    gridApiMappingRowData: any;
    gridApiTemplateColsRowData: any;
    public selectedLabelValue: string[] = [];
    displayDocDefinitionDlg: boolean = false;
    docDefinitionList: string[] = [];
    @Input() inputFeedId: string;
    @Input() inputScreen: string;
    @Input() feedTemplateMapping: string;
    targetType: string = "";
    public isToolBoxFeed:boolean=false;
    viewDataTypeList: any[] = [];
    //#endregion

    //#region Initialization of Component
    constructor(public _sharedService: SharedService, private _router: Router, private route: ActivatedRoute, private _apiService: APIService,
        private renderer: Renderer2, private _confirmationService: ConfirmationService, private _helpService: HelpService, private _viewDataTypeService: ViewDataTypeService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
    }
    ngOnInit() {
        dateTimeConfig = this._sharedService.getDateTimeFormatConfiguration();
        this.targetType = this._sharedService.selectedFeedRowData.TargetType;
        this.isToolBoxFeed = (String)(this._sharedService.selectedFeedRowData.TemplateName).startsWith('Toolbox');
        // let feedId: string = this.route.snapshot.params["id"];
        // let screen: string = this.route.snapshot.params["screen"];

       this.screenName = this.inputScreen.toLowerCase();
       this.routeName = this.screenName.toLowerCase();
       this.feedId = this.inputFeedId;
        this.model = new MappingModel();
        this.getDefaultValues();
        this.populateViewDataType();
        this.gridOptionsMapping = <GridOptions>
            {
                rowSelection: 'single',
                context: {

                    parentComponent: this
                },
                onGridReady: (params) => {
                    this.gridOptionsMapping.api.sizeColumnsToFit();
                    //this.setMappingGridColumns();
                    if (params.api) {
                        this.setMappingGridColumns();
                        this.gridOptionsMapping.columnApi = params.columnApi;
                        this.gridApiMapping = params.api;

                    }

                },
                onModelUpdated: (event: any) => {

                },
                //suppressContextMenu: true,
                // onCellEditingStopped: this.mappingGridCellEditingStopped,
                getContextMenuItems: this.getCustomContextMenuItems,
                enableBrowserTooltips: true,
                stopEditingWhenCellsLoseFocus: true
            }
        this.gridOptions = <GridOptions>
            {
                rowSelection: 'single',
                rowData: [],
                onGridReady: () => {
                    this.setDataFeedGridColumns();
                    if (this.gridOptions.api != undefined) {
                        if (this.gridData.length > 0) { this.gridOptions.api.hideOverlay(); }
                    }
                },
                onModelUpdated: (event: any) => {
                    if (this.gridOptions) {
                        this.gridOptions.api.sizeColumnsToFit();
                    }

                },
                suppressContextMenu: true,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                }
            }

        this.gridOptionsMappingList = <GridOptions>
            {
                rowSelection: 'single',
                context: {

                    listComponent: this
                },
                onGridReady: (params) => {
                    this.gridOptionsMappingList.api.sizeColumnsToFit();
                    //this.setMappingGridColumns();
                    if (params.api) {
                        this.setMappingGridColumnsList();
                        this.gridOptionsMappingList.columnApi = params.columnApi;
                        this.gridApiMappingList = params.api;

                    }

                },
                onModelUpdated: (event: any) => {
                    this.gridOptionsMappingList.api.sizeColumnsToFit();
                },
                enableBrowserTooltips: true,
                resizable: true
            }

    }
    mappingGridCellEditingStopped(params: any) {
        if (params.colDef.field == 'FileColumn') {
            if (params.node.data.FileColumn != 'Fixed Value') {

                let node: RowNode = params.node;
                params.node.columnApi.getColumn('fixedValue').colDef.editable = false;
                //params.node.columnApi.getColumn('fixedValue').colDef.cellClass = '';
                params.node.setDataValue('fixedValue', null);
                //     this.gridOptionsMapping.api.refreshCells();
                //let row: RowNode = this.gridOptionsMapping.api.getR;
                //  params.node.data.FixedValue = null;
            }
            else if (params.node.data.FileColumn == 'Fixed Value') {
                params.node.columnApi.getColumn('fixedValue').colDef.editable = true;
                params.node.columnApi.getColumn('fixedValue').colDef.cellClass = 'readonlyinput';
            }
        }
    }
    //#endregion

    // #region Form Events    

    trackByIndex(index: number, value: number) {
        return index;
    }

    updateRowNode(rowIndex: number, columnKey: string) {
        let newValue: boolean = false;
        //  let row: RowNode = this.gridOptionsMapping.api.getDisplayedRowAtIndex(rowIndex);
        let row: IRowNode = this.gridOptionsMapping.api.getRenderedNodes().find(x => x.data.FieldOrder == rowIndex);
        switch (columnKey) {
            case 'uniqueKey':
                if (row.data.UniqueKey == true) {
                    newValue = false;
                }
                else if (row.data.UniqueKey == false) {
                    newValue = true;
                }
                break;
            case 'historyKey':
                if (row.data.HistoryKey == true) {
                    newValue = false;
                }
                else if (row.data.HistoryKey == false) {
                    newValue = true;
                }
        }

        row.setDataValue(columnKey, newValue);

    }
    btnSaveClick(saveForm: NgForm) {
        if (!this.isSubmitted) {
            allNodesMap = [];
            this.gridOptionsMapping.api.forEachNode(this.updateModelValues);
            let colArray: Mapping[] = [];
            for (var i = 0; i < allNodesMap.length; i++) {
                // let fColValue = "";
                let fColLabel = allNodesMap[i].data.FileColumn;
                // if (fColLabel) {
                //     let fCol = this.filecolumns.find(x => x.label == allNodesMap[i].data.FileColumn);

                //     if (fCol) {
                //         fColValue = fCol.value.trim();
                //     }
                // }
                let mColLabel = allNodesMap[i].data.MappingColumn;
                let mCol: any = null;
                if (this.mapcolumns) {
                    mCol = this.mapcolumns.find(x => x.label == allNodesMap[i].data.MappingColumn);
                }
                let mColValue = "";
                if (mCol) {
                    mColValue = mCol.value.trim();
                }
                else {
                    mColValue = mColLabel;
                }
                //  let col: Mapping = { FileColumn: fColValue, FixedValue: allNodesMap[i].data.FixedValue, MappingColumn: mColValue, UniqueKey: allNodesMap[i].data.UniqueKey, HistoryKey: allNodesMap[i].data.HistoryKey, FieldOrder: allNodesMap[i].rowIndex };
                let col: Mapping = { FileColumn: fColLabel, FixedValue: allNodesMap[i].data.FixedValue, MappingColumn: mColValue, FieldOrder: allNodesMap[i].rowIndex };

                // If any Target Column is empty
                if (!col.MappingColumn) {
                    this._sharedService.clearToast();
                    if (this.screenName == "outgoing") {
                        this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Mapping', detail: 'Source column is empty.' });

                    }
                    else {
                        this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Mapping', detail: 'Target column is empty.' });
                    }

                    return;
                }
                if (!col.FileColumn) {
                    this._sharedService.clearToast();
                    if (this.screenName == "outgoing") {
                        this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Mapping', detail: 'Target column is empty.' });
                    }
                    else {
                        this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Mapping', detail: 'Source column is empty.' });
                    }
                    return;
                }

                if (col.MappingColumn == 'TaskType' && col.FileColumn) {

                    if (!this.isValidDataType(col)) {
                        if (this.screenName == "outgoing") {
                            this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Data Type', detail: 'Target Data type is not valid.' });
                        }
                        else {
                            this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Column Data Type', detail: col.FileColumn+ ' does not exists as Master > Data Type' });
                        }
                        return;
                    }
                }

                colArray.push(col);
            }
            this.model.Map = [];
            this.model.Map = colArray;

            this.changeButtonsStatus(true);
            //let validValues: Mapping[] = this.model.Map.filter(x => (x.FileColumn || x.FixedValue) && (x.MappingColumn))
            let validValues: Mapping[] = this.model.Map.filter(x => x.MappingColumn)
            let body: any =
            {
                FeedId: this.feedId,
                Map: validValues
            }
            this._sharedService.showLoader("Processing...");
            this._apiService.addUpdateMapping(body)
            .subscribe({
                next: (response) => {
                    this._sharedService.hideLoader();
                    if (response.DocumentDefinitionNamesList) {
                        this.displayDocDefinitionDlg = true;
                        this.docDefinitionList = [];
                        this.docDefinitionList = response.DocumentDefinitionNamesList;
                    }
                    else if (response.AddUpdateStatusCode && response.AddUpdateStatusCode == 3) {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Mapping', detail: 'ASN and TaskType are mandatory.' });
                        this.changeButtonsStatus(false);
                    }
                    else {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Mapping updated succesfully.' });
                        this.changeButtonsStatus(false);
                        setTimeout(() => {
                            // this._router.navigate([`/apifeed/${this.routeName}`]);
                            this._sharedService.onMappingDialogueStatusChanged(false);
                        },
                            1500);
                    }
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
        
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Mapping not updated, please try again.' });
                    this.changeButtonsStatus(false);
                }
            });
        }
    }

    btnCancelClick(cancelForm: NgForm) {
        this.display = false;
        //this._router.navigate([`/apifeed/${this.routeName}`]);
        cancelForm.resetForm();
        this._sharedService.onMappingDialogueStatusChanged(false);
    }

    addColumn() {
        if (!this.isSubmitted) {
            let maxOrder = 0;
            if (this.model.Map && this.model.Map.length > 0) {
                maxOrder = Math.max.apply(Math, this.model.Map.map(function (o) { return Number(o.FieldOrder); }));
                maxOrder = maxOrder + 1;
            }
            // let col: Mapping = { FileColumn: null, FixedValue: null, MappingColumn: null, UniqueKey: false, HistoryKey: false, FieldOrder: maxOrder };
            let col: Mapping = { FileColumn: null, FixedValue: null, MappingColumn: null, FieldOrder: maxOrder };

            allNodesAddMap = [];
            this.gridOptionsMapping.api.forEachNode(this.addModelValue);

            let colArray: Mapping[] = [];
            for (var i = 0; i < allNodesAddMap.length; i++) {
                // let fColValue = "";
                // if (allNodesAddMap[i].data.FileColumn) {
                let fColLabel = allNodesAddMap[i].data.FileColumn;
                //     let fCol = this.filecolumns.find(x => x.label == allNodesAddMap[i].data.FileColumn);

                //     if (fCol) {
                //         fColValue = fCol.value.trim();
                //     }
                // }
                let mColLabel = allNodesAddMap[i].data.MappingColumn;
                let mCol: any = null;
                if (this.mapcolumns) {
                    mCol = this.mapcolumns.find(x => x.label == allNodesAddMap[i].data.MappingColumn);
                }
                let mColValue = "";
                if (mCol) {
                    mColValue = mCol.value.trim();
                }
                else {
                    mColValue = mColLabel;
                }
                // let col1: Mapping = { FileColumn: fColValue, FixedValue: allNodesAddMap[i].data.FixedValue, MappingColumn: mColValue, UniqueKey: allNodesAddMap[i].data.UniqueKey, HistoryKey: allNodesAddMap[i].data.HistoryKey, FieldOrder: allNodesAddMap[i].rowIndex };
                let col1: Mapping = { FileColumn: fColLabel, FixedValue: allNodesAddMap[i].data.FixedValue, MappingColumn: mColValue, FieldOrder: allNodesAddMap[i].rowIndex };
                colArray.push(col1);
            }
            colArray.push(col);
            this.model.Map = [];

            for (var i = 0; i < colArray.length; i++) {

                let mColValue = colArray[i].MappingColumn;
                let mColLabel = "";
                let mCol: any = null;
                if (this.mapcolumns) {
                    mCol = this.mapcolumns.find(x => x.value == mColValue);
                }
                if (mCol) {
                    mColLabel = mCol.label.trim();
                }
                else {
                    mColLabel = mColValue;
                }

                // this.model.Map.push({ FileColumn: colArray[i].FileColumn != '^Fixed Value^' ? colArray[i].FileColumn : 'Fixed Value', FixedValue: colArray[i].FixedValue, MappingColumn: mColLabel, UniqueKey: colArray[i].UniqueKey, HistoryKey: colArray[i].HistoryKey, FieldOrder: colArray[i].FieldOrder });
                // this.model.Map.push({ FileColumn: colArray[i].FileColumn != '^Fixed Value^' ? colArray[i].FileColumn : 'Fixed Value', FixedValue: colArray[i].FixedValue, MappingColumn: mColLabel, FieldOrder: colArray[i].FieldOrder });
                this.model.Map.push({ FileColumn: colArray[i].FileColumn, FixedValue: colArray[i].FixedValue, MappingColumn: mColLabel, FieldOrder: colArray[i].FieldOrder });
            }

            //this.model.Map = colArray;
            this.gridDataMapping = this.model.Map;
            this.gridOptionsMapping.api.setRowData(this.model.Map);
            //this.gridDataMapping = [];
            //this.gridDataMapping = this.model.Map;
            //this.gridOptionsMapping.api.setRowData(this.model.Map);
        }
    }
    filecolumnchange(obj: any, modelValue: any) {
        if (modelValue.FileColumn != '^Fixed Value^') {
            modelValue.FixedValue = null;
        }
    }

    deleteColumn(map: Mapping) {
        if (!this.isSubmitted) {
            let index: number = this.model.Map.indexOf(map);
            this.model.Map.splice(index, 1);
        }

    }

    // #endregion

    //#region Get File Field Name
    getDefaultValues() {

        this.filecolumns = [];
        this.mapcolumns = [];
        // if (this.screenName == "Incoming") {

        this._sharedService.showLoader("Processing...");
        this._apiService.getFileMappingDetails(this.feedId)
        .subscribe({
            next: (response) => {
                let body = response;
                // if (!body.FileColumns) {
                //     this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'File template is missing, please try again.' });
                //     this.changeButtonsStatus(true);
                // }
                // else {
        
                this.filecolumns = body.FileColumns;
                this.mapcolumns = body.MappingColumns;
        
                //Update TaskType Label to DataType
                if(this.mapcolumns && this.mapcolumns.find(f=>f.label == 'TaskType')){
                    let taskType =  this.mapcolumns.find(f=>f.label == 'TaskType');
                    taskType.label = 'DataType';
                }
        
                //Update TaskID Label to DataType
                if(this.mapcolumns && this.mapcolumns.find(f=>f.label == 'TaskID')){
                    let taskID =  this.mapcolumns.find(f=>f.label == 'TaskID');
                    taskID.label = 'ID';
                }
        
                this.populateView(body.Map);
        
                if (this.mapcolumns && this.mapcolumns.length > 0) {
                    let col = this.gridOptionsMapping.columnApi.getColumn("mapColumn").getColDef();
                    col.cellEditorParams.values = this.mapcolumns.map(sts => sts.label.trim());
                }
                // if (this.filecolumns && this.filecolumns.length > 0) {
                //     let col1 = this.gridOptionsMapping.columnApi.getColumn("fileColumn").getColDef();
                //     col1.cellEditorParams.values = this.filecolumns.map(sts => sts.label.trim());
                // }
                this.gridDataMappingList = [];
                if (this.filecolumns) {
                    let label = this.filecolumns;
                    this.gridDataMappingList = label;
                }
        
                this.FeedName = body.FeedName;
                this.gridOptionsMapping.api.sizeColumnsToFit();
                //   }
        
                this._sharedService.hideLoader();
            },
            error: (error) => {
                this._sharedService.hideLoader();
                this.gridOptionsMapping.api.sizeColumnsToFit();
            }
        });
    }

    //#endregion

    //#region Helper Methods
    setDefaultComponentValues(): void {
        this.changeButtonsStatus(false);
    }

    populateView(body: any) {

        this.model.Map = [];
        if (body && body != null) {

            for (var i = 0; i < body.length; i++) {

                let mColValue = body[i].MappingColumn;
                let mCol: any = null;
                if (this.mapcolumns) {
                    mCol = this.mapcolumns.find(x => x.value == mColValue);
                }
                let mColLabel = "";
                if (mCol) {
                    mColLabel = mCol.label.trim();
                }
                else {
                    mColLabel = mColValue;
                }

                // this.model.Map.push({ FileColumn: body[i].FileColumn != '^Fixed Value^' ? body[i].FileColumn : 'Fixed Value', FixedValue: body[i].FixedValue, MappingColumn: mColLabel, UniqueKey: body[i].UniqueKey, HistoryKey: body[i].HistoryKey, FieldOrder: body[i].FieldOrder });
                //this.model.Map.push({ FileColumn: body[i].FileColumn != '^Fixed Value^' ? body[i].FileColumn : 'Fixed Value', FixedValue: body[i].FixedValue, MappingColumn: mColLabel, FieldOrder: body[i].FieldOrder });
                this.model.Map.push({ FileColumn: body[i].FileColumn, FixedValue: body[i].FixedValue, MappingColumn: mColLabel, FieldOrder: body[i].FieldOrder });
            }

        }
        else {
            if (this.filecolumns) {
                if (this.mapcolumns) {
                    for (var i = 0; i < this.filecolumns.length; i++) {
                        // this.model.Map.push({ FileColumn: null, FixedValue: null, MappingColumn: null, UniqueKey: false, HistoryKey: false, FieldOrder: i });
                        this.model.Map.push({ FileColumn: null, FixedValue: null, MappingColumn: null, FieldOrder: i });
                    }
                }
            }
        }
        this.gridDataMapping = [];
        this.gridDataMapping = this.model.Map;
    }

    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }

    //#endregion

    btnShowHideTemplateClick() {
        let templatePanel = document.getElementById("templatePanel");
        let templatePanelButton = document.getElementById("templatePanelButton");
        let mappingPanel = document.getElementById("mappingPanel");

        if (templatePanel && templatePanel.style.display == 'none') {
            templatePanel.style.display = 'block';
            templatePanelButton.style.display = 'block';

            mappingPanel.classList.remove('col-sm-12');
            mappingPanel.classList.add('col-sm-7');
        } else {
            templatePanel.style.display = 'none';
            templatePanelButton.style.display = 'none';

            mappingPanel.classList.remove('col-sm-7');
            mappingPanel.classList.add('col-sm-12');
        }

        this.gridOptionsMapping.api.sizeColumnsToFit();
    }

    //#region Copy Mapping
    btnCopyClick() {
        this.display = true;
        let feedType: string = 'Import'
        if (this.screenName == 'export') {
            feedType = 'Export';
        }
        let body: datafeed = {
            FeedType: feedType,
            FeedId: this.feedId
        }
        this._sharedService.showLoader("Processing...");
        this._apiService.getDataFeedsListCopy(body)
        .subscribe({
            next: (response) => {
                this._sharedService.hideLoader();
                this.gridData = [];
                this.gridData = response;
            },
            error: (error) => {
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again later.' });
            }
        });
    }
    onSubmit() {
        if (this.selectedfeedId && this.selectedfeedId != "") {
            if (!(this.model.Map) || (this.model.Map && this.model.Map.length == 0)) //|| ((this.model && this.model.Map && this.model.Map.length==0))
            {
                this.proceedCopyMapping();
            }
            else {
                this._confirmationService.confirm({
                    message: 'Existing mapping and template file will be discarded and new would be copied?',
                    header: 'Mapping Exist',
                    icon: 'fa fa-trash',
                    accept: () => {
                        this.proceedCopyMapping();
                    },
                    reject: () => {
                        this.display = false;
                        this.selectedfeedId = "";
                        this.selectedRowData = null;
                    }
                });
            }

        }
        else {
            this._sharedService.clearToast();

            this._sharedService.showToast({ severity: 'warn', summary: 'Warning', detail: 'No record selected' });
        }

    }

    proceedCopyMapping() {

        this.display = false;

        let body: any =
        {
            ToFeedId: this.feedId,
            FromFeedId: this.selectedfeedId
        }
        this._sharedService.showLoader("Processing...");
        this._apiService.copyMappingToFeed(body)
        .subscribe({
            next: (response) => {
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Mapping copied succesfully.' });
                this.model = new MappingModel();
                this.getDefaultValues();
                this.changeButtonsStatus(false);
                this.selectedfeedId = "";
            },
            error: (error) => {
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
        
                this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Mapping not copied, please try again.' });
                this.changeButtonsStatus(false);
                this.selectedfeedId = "";
            }
        });
    }
    btnCancelDialogueClick() {
        this.display = false;
        this.selectedfeedId = "";
    }
    //#region Mapping Help
    btnMappingHelpClick() {

        let _help: HelpRequest = new HelpRequest();
        _help.DataType = this._sharedService.selectedFeedRowData.DataType;
        _help.FeedType = this.screenName;//this._sharedService.selectedFeedRowData.FeedType;

        _help.SourceType = this._sharedService.selectedFeedRowData.SourceType;
        _help.SourceDetail = this._sharedService.selectedFeedRowData.SourceDetail;

        _help.TargetType = this._sharedService.selectedFeedRowData.TargetType;
        _help.TargetDetail = this._sharedService.selectedFeedRowData.TargetDetail;
        this.htmlMappingHelp = "";

        this._helpService.getHelp(_help)
        .subscribe({
            next: (response) => {
                if (response != null) {
                    this._sharedService.clearToast();
                    let _helpResponse: Help = new Help();
                    _helpResponse = response;
                    if (typeof _helpResponse.HelpContent != 'undefined' && _helpResponse.HelpContent) {
                        this.htmlMappingHelp = _helpResponse.HelpContent;
                        this.displayHelpWindow = true;
                    } else {
                        this._sharedService.showToast({ severity: 'error', summary: 'Mapping Help!', detail: 'No mapping help is available.' });
                        this.displayHelpWindow = false;
                    }
                } else {
                    this._sharedService.showToast({ severity: 'error', summary: 'Mapping Help!', detail: 'No mapping help is available.' });
                    this.displayHelpWindow = false;
                }
            },
            error: (error) => {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Mapping Help!', detail: 'No mapping help is available.' });
                this.displayHelpWindow = false;
            }
        });
    }

    btnCancelMappingHelpClick() {
        this.displayHelpWindow = false;
    }

    //#region Set Columns of Data Feeds Ag Grid
    removeCellRenderer(params: any) {
        let html: string = '';
        html = html + '<a (click)="removeClicked(' + params.data.FieldOrder + ')"><span id=' + params.data.FieldOrder + ' class="button-icon glyphicon glyphicon-remove hand-cursor" title="delete"></span></a>';
        var eDiv = document.createElement('div');
        eDiv.innerHTML = html;
        return eDiv;
    }
    fileColumnCellRenderer(params: any) {
        if (params.data.FileColumn == '^Fixed Value^') {
            return 'Fixed Value';
        }

        return params.data.FileColumn;
    }
    mapColumnCellRenderer(params: any) {
        if (params.data) {
            let mColValue = "";
            if (params.context.parentComponent.mapcolumns) {
                let columns: any[] = [];
                columns = params.context.parentComponent.mapcolumns;
                let mCol = columns.find(x => x.label == params.data.MappingColumn);

                if (mCol) {
                    mColValue = mCol.label;
                }
                else {
                    return null;
                }

                return mColValue;
            }

        }

        return null;
    }
    //headerCellRenderer(params: any) {
    //    let html: string = '';
    //    html = html + '<a (click)="headerClicked()"><span class="button-icon glyphicon glyphicon-plus hand-cursor" title="add"></span></a>";
    //    var eDiv = document.createElement('div');
    //    eDiv.innerHTML = html;
    //    return eDiv;
    //}
    onCellClickedMap(event: any, type?: string) {
        if (event.colDef && event.colDef.field == '' || (type == 'Context Menu')) {

            let index: number = this.model.Map.findIndex(x => x.FieldOrder == event.data.FieldOrder);
            this.model.Map.splice(index, 1);

            this.gridDataMapping = [];
            this.gridDataMapping = this.model.Map;
            this.gridOptionsMapping.api.setRowData(this.model.Map);
        }
    }
    clickedMappingListGridRow(event: any) {
        this.gridApiTemplateColsRowData = event.data;

    }
    clickedMappingGridRow(event: any) {
        this.gridApiMappingRowData = event.data;

    }
    templateRowDoubleClicked(event: any) {
        this.gridApiTemplateColsRowData = event.data;
        this.btnAssignClicked(null);

    }
    btnAssignClicked(event: any) {
        if (this.gridApiTemplateColsRowData && this.gridApiMappingRowData) {
            // let rowTemplateCols: RowNode = this.gridOptionsMappingList.api.getRenderedNodes().find(x => x.data.label == this.gridApiTemplateColsRowData.label);

            if (this.fixedValueText) {

                let row: IRowNode = this.gridOptionsMapping.api.getRenderedNodes().find(x => x.data.FieldOrder == this.gridApiMappingRowData.FieldOrder);

                row.setDataValue('fileColumn', `<<${this.fixedValueText}>>`);
                this.fixedValueText = '';
            }
            else {

                this.selectedLabelValue.push(this.gridApiTemplateColsRowData.label);

                // change fix value with quotes
                let row: IRowNode = this.gridOptionsMapping.api.getRenderedNodes().find(x => x.data.FieldOrder == this.gridApiMappingRowData.FieldOrder);

                row.setDataValue('fileColumn', this.gridApiTemplateColsRowData.label);
                this.gridApiMappingList.redrawRows();
            }

        }
        else if (this.gridApiMappingRowData) {
            if (this.fixedValueText) {
                let row: IRowNode = this.gridOptionsMapping.api.getRenderedNodes().find(x => x.data.FieldOrder == this.gridApiMappingRowData.FieldOrder);

                row.setDataValue('fileColumn', `<<${this.fixedValueText}>>`);
                this.fixedValueText = '';
            }
        }
        else {

            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Row Selected', detail: 'Please select Mapping Row first' });
        }
    }


    btnAssignToken(token: string): void {

        if (this.gridApiMappingRowData) {
            let row: IRowNode = this.gridOptionsMapping.api.getRenderedNodes().find(x => x.data.FieldOrder == this.gridApiMappingRowData.FieldOrder);
            row.setDataValue('fileColumn', token);

        }
        else {

            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Row Selected', detail: 'Please select Mapping Row first' });
        }
    }

    getCellClass(params: any) {
        let result = params.context.listComponent.selectedLabelValue.findIndex(x => x == params.data.label);
        if (result != -1) {
            return ['selectedRow', 'cellTextDirection'];
        }
        else {
            return 'cellTextDirection'
        }

    }
    setMappingGridColumnsList(): void {
        this.columnDefsMappingList = [];
        this.columnDefsMappingList =
            [
                {
                    headerName: 'Template Columns', field: 'label', tooltipField: 'label', colId: "fileColumnListId", filter: false, suppressMenu: true, cellClass: this.getCellClass
                }

            ];

    }

    OldsetMappingGridColumns(): void {
        this.columnDefsMapping = [];
        let sourceType = this._sharedService.selectedFeedRowData.SourceType;
        let targetType = this._sharedService.selectedFeedRowData.TargetType;
        if (this.screenName == 'export') {
            this.columnDefsMapping =
                [
                    {
                        headerName: 'Source Columns', field: 'MappingColumn', tooltipField: 'MappingColumn', colId: "mapColumn", editable: true, filter: false, suppressMenu: true, rowDrag: true
                    },
                    {
                        headerName: 'Target Columns', field: 'FileColumn', tooltipField: 'FileColumn', colId: "fileColumn", editable: true, filter: false, suppressMenu: true, width: 50, resizable: true
                    },
                    { headerName: '', field: '', headerComponent: CustomButtonComponent, cellRenderer: this.removeCellRenderer, suppressMenu: true, filter: false, width: 10 },

                ];
        }
        else {
            
            if (sourceType == 'Web Service Pull') {
                if (targetType == 'Records') {
                    this.columnDefsMapping =
                        [
                            {
                                headerName: 'Source Columns', field: 'FileColumn', tooltipField: 'FileColumn', colId: "fileColumn", editable: true, filter: false, suppressMenu: true, rowDrag: true, resizable: true
                            },
                            {
                                headerName: 'Target Columns', field: 'MappingColumn', tooltipField: 'MappingColumn', colId: "mapColumn", editable: true, filter: false, suppressMenu: true, width: 50, resizable: true
                            },
                            { headerName: '', field: '', headerComponent: CustomButtonComponent, cellRenderer: this.removeCellRenderer, suppressMenu: true, filter: false, width: 10 },

                        ];
                }
                else if (targetType == 'Status') {
                    this.columnDefsMapping =
                        [
                            {
                                headerName: 'Source Columns', field: 'FileColumn', tooltipField: 'FileColumn', colId: "fileColumn", editable: true, filter: false, suppressMenu: true, rowDrag: true, resizable: true
                            },
                            {
                                headerName: 'Target Columns', field: 'MappingColumn', tooltipField: 'MappingColumn', colId: "mapColumn", editable: true, cellEditor: 'agRichSelectCellEditor', cellEditorParams: {
                                    values: this.mapcolumns.map(sts => sts.label.trim()),

                                }, cellRenderer: this.mapColumnCellRenderer, filter: false, suppressMenu: true, width: 50, resizable: true
                            },
                            { headerName: '', field: '', headerComponent: CustomButtonComponent, cellRenderer: this.removeCellRenderer, suppressMenu: true, filter: false, width: 10 },

                        ];
                }
                else if (targetType == 'RDOC') {
                    this.columnDefsMapping =
                        [
                            {
                                headerName: 'Source Columns', field: 'FileColumn', tooltipField: 'FileColumn', colId: "fileColumn", editable: true, filter: false, suppressMenu: true, rowDrag: true, minWidth: 80, resizable: true
                            },
                            {
                                headerName: 'Target Columns', field: 'MappingColumn', tooltipField: 'MappingColumn', colId: "mapColumn", editable: true, filter: false, suppressMenu: true, width: 50, resizable: true
                            },
                            { headerName: '', field: '', headerComponent: CustomButtonComponent, cellRenderer: this.removeCellRenderer, suppressMenu: true, filter: false, width: 10 },
                        ];
                }
                else if (targetType == 'Status Link') {
                    this.columnDefsMapping =
                        [
                            {
                                headerName: 'Source Columns', field: 'FileColumn', tooltipField: 'FileColumn', colId: "fileColumn", editable: true, filter: false, suppressMenu: true, rowDrag: true, minWidth: 80, resizable: true
                            },
                            {
                                headerName: 'Target Columns', field: 'MappingColumn', tooltipField: 'MappingColumn', colId: "mapColumn", editable: true, filter: false, suppressMenu: true, width: 50, resizable: true
                            },
                            { headerName: '', field: '', headerComponent: CustomButtonComponent, cellRenderer: this.removeCellRenderer, suppressMenu: true, filter: false, width: 10 },
                        ];
                }
            }
            else if ((sourceType == 'App Storage' || sourceType == 'Shared Folder' || sourceType == 'FTP' || sourceType == 'Status' || sourceType == 'Status Link'  || sourceType == 'Records') && (targetType == 'Records' || targetType == 'RDOC')) {
                if (targetType == 'Records') {
                    this.columnDefsMapping =
                        [
                            {
                                headerName: 'Source Columns', field: 'FileColumn', tooltipField: 'FileColumn', colId: "fileColumn", editable: true, filter: false, suppressMenu: true, rowDrag: true, resizable: true
                            },
                            {
                                headerName: 'Target Columns', field: 'MappingColumn', tooltipField: 'MappingColumn', colId: "mapColumn", editable: true, filter: false, suppressMenu: true, width: 50, resizable: true
                            },
                            { headerName: '', field: '', headerComponent: CustomButtonComponent, cellRenderer: this.removeCellRenderer, suppressMenu: true, filter: false, width: 10 },

                        ];
                }
                else if (targetType == 'RDOC') {
                    this.columnDefsMapping =
                        [
                            {
                                headerName: 'Source Columns', field: 'FileColumn', tooltipField: 'FileColumn', colId: "fileColumn", editable: true, filter: false, suppressMenu: true, rowDrag: true, resizable: true
                            },
                            {
                                headerName: 'Target Columns', field: 'MappingColumn', tooltipField: 'MappingColumn', colId: "mapColumn", editable: true, filter: false, suppressMenu: true, width: 50, resizable: true
                            },
                            { headerName: '', field: '', headerComponent: CustomButtonComponent, cellRenderer: this.removeCellRenderer, suppressMenu: true, filter: false, width: 10 },
                        ];
                }
            }
            else {
                if (targetType == 'Status') {
                    this.columnDefsMapping =
                        [
                            {
                                headerName: 'Source Columns', field: 'FileColumn', tooltipField: 'FileColumn', colId: "fileColumn", editable: true, filter: false, suppressMenu: true, rowDrag: true, resizable: true
                            },

                            {
                                headerName: 'Target Columns', field: 'MappingColumn', tooltipField: 'MappingColumn', colId: "mapColumn", editable: true, cellEditor: 'agRichSelectCellEditor', cellEditorParams: {
                                    values: this.mapcolumns.map(sts => sts.label.trim()),

                                }, cellRenderer: this.mapColumnCellRenderer, filter: false, suppressMenu: true, width: 50, resizable: true
                            },

                            { headerName: '', field: '', headerComponent: CustomButtonComponent, cellRenderer: this.removeCellRenderer, suppressMenu: true, filter: false, width: 10 },

                        ];
                }
                else if (targetType == 'Status Link') {
                    this.columnDefsMapping =
                        [
                            {
                                headerName: 'Source Columns', field: 'FileColumn', tooltipField: 'FileColumn', colId: "fileColumn", editable: true, filter: false, suppressMenu: true, rowDrag: true, minWidth: 80, resizable: true
                            },
                            {
                                headerName: 'Target Columns', field: 'MappingColumn', tooltipField: 'MappingColumn', colId: "mapColumn", editable: true, filter: false, suppressMenu: true, width: 50, resizable: true
                            },
                            { headerName: '', field: '', headerComponent: CustomButtonComponent, cellRenderer: this.removeCellRenderer, suppressMenu: true, filter: false, width: 10 },
                        ];
                }
                else if (targetType == 'Asset') {
                    this.columnDefsMapping =
                        [
                            {
                                headerName: 'Source Columns', field: 'FileColumn', tooltipField: 'FileColumn', colId: "fileColumn", editable: true, filter: false, suppressMenu: true, rowDrag: true, minWidth: 80, resizable: true
                            },
                            {
                                headerName: 'Target Columns', field: 'MappingColumn', tooltipField: 'MappingColumn', colId: "mapColumn", editable: true, filter: false, suppressMenu: true, width: 50, resizable: true
                            },
                            { headerName: '', field: '', headerComponent: CustomButtonComponent, cellRenderer: this.removeCellRenderer, suppressMenu: true, filter: false, width: 10 },
                        ];
                }
                else {
                    this.columnDefsMapping =
                        [
                            {
                                headerName: 'Source Columns', field: 'FileColumn', tooltipField: 'FileColumn', colId: "fileColumn", editable: true, filter: false, suppressMenu: true, rowDrag: true, resizable: true
                            },

                            {
                                headerName: 'Mapping Columns', field: 'MappingColumn', tooltipField: 'MappingColumn', colId: "mapColumn", editable: true, cellEditor: 'agRichSelectCellEditor', cellEditorParams: {
                                    values: this.mapcolumns.map(sts => sts.label.trim()),

                                }, cellRenderer: this.mapColumnCellRenderer, filter: false, suppressMenu: true, width: 50, resizable: true,
                            },

                            { headerName: '', field: '', headerComponent: CustomButtonComponent, cellRenderer: this.removeCellRenderer, suppressMenu: true, filter: false, width: 10 },

                        ];
                }
            }
        }
    }


    setMappingGridColumns(): void {
        this.columnDefsMapping = [];
        let templateMapping = this._sharedService.selectedFeedRowData.TemplateMapping;
        let sourceType = this._sharedService.selectedFeedRowData.SourceType;
        let targetType = this._sharedService.selectedFeedRowData.TargetType;
        if (this.screenName == 'export') {
            this.columnDefsMapping =
                [
                    {
                        headerName: 'Source Columns', field: 'MappingColumn', tooltipField: 'MappingColumn', colId: "mapColumn", editable: true, filter: false, suppressMenu: true, rowDrag: true
                    },
                    {
                        headerName: 'Target Columns', field: 'FileColumn', tooltipField: 'FileColumn', colId: "fileColumn", editable: true, filter: false, suppressMenu: true, width: 50, resizable: true
                    },
                    { headerName: '', field: '', headerComponent: CustomButtonComponent, cellRenderer: this.removeCellRenderer, suppressMenu: true, filter: false, width: 10 },

                ];
        }
        else {
            
                if (templateMapping == 'Records Container Column') {
                    this.columnDefsMapping =
                        [
                            {
                                headerName: 'Source Columns', field: 'FileColumn', tooltipField: 'FileColumn', colId: "fileColumn", editable: true, filter: false, suppressMenu: true, rowDrag: true, resizable: true
                            },
                            // {
                            //     headerName: 'Target Columns', field: 'MappingColumn', tooltipField: 'MappingColumn', colId: "mapColumn", editable: true, filter: false, suppressMenu: true, width: 50, resizable: true
                            // },
                            {
                                headerName: 'Target Columns', field: 'MappingColumn', tooltipField: 'MappingColumn', colId: "mapColumn", editable: true, cellEditor: 'agRichSelectCellEditor', cellEditorParams: {
                                    values: this.mapcolumns.map(sts => sts.label.trim()),

                                }, cellRenderer: this.mapColumnCellRenderer, filter: false, suppressMenu: true, width: 50, resizable: true
                            }, { headerName: '', field: '', headerComponent: CustomButtonComponent, cellRenderer: this.removeCellRenderer, suppressMenu: true, filter: false, width: 10 },

                        ];
                }
                else if (templateMapping == 'Status View Columns' || templateMapping == 'Repair View Columns') {
                    this.columnDefsMapping =
                        [
                            {
                                headerName: 'Source Columns', field: 'FileColumn', tooltipField: 'FileColumn', colId: "fileColumn", editable: true, filter: false, suppressMenu: true, rowDrag: true, resizable: true
                            },
                            {
                                headerName: 'Target Columns', field: 'MappingColumn', tooltipField: 'MappingColumn', colId: "mapColumn", editable: true, cellEditor: 'agRichSelectCellEditor', cellEditorParams: {
                                    values: this.mapcolumns.map(sts => sts.label.trim()),

                                }, cellRenderer: this.mapColumnCellRenderer, filter: false, suppressMenu: true, width: 50, resizable: true
                            },
                            { headerName: '', field: '', headerComponent: CustomButtonComponent, cellRenderer: this.removeCellRenderer, suppressMenu: true, filter: false, width: 10 },

                        ];
                }
                else if (templateMapping == 'Blank Source Target') {
                    this.columnDefsMapping =
                        [
                            {
                                headerName: 'Source Columns', field: 'FileColumn', tooltipField: 'FileColumn', colId: "fileColumn", editable: true, filter: false, suppressMenu: true, rowDrag: true, minWidth: 80, resizable: true
                            },
                            {
                                headerName: 'Target Columns', field: 'MappingColumn', tooltipField: 'MappingColumn', colId: "mapColumn", editable: true, filter: false, suppressMenu: true, width: 50, resizable: true
                            },
                            { headerName: '', field: '', headerComponent: CustomButtonComponent, cellRenderer: this.removeCellRenderer, suppressMenu: true, filter: false, width: 10 },
                        ];
                }

                else {
                    this.columnDefsMapping =
                        [
                            {
                                headerName: 'Source Columns', field: 'FileColumn', tooltipField: 'FileColumn', colId: "fileColumn", editable: true, filter: false, suppressMenu: true, rowDrag: true, resizable: true
                            },

                            {
                                headerName: 'Mapping Columns', field: 'MappingColumn', tooltipField: 'MappingColumn', colId: "mapColumn", editable: true, cellEditor: 'agRichSelectCellEditor', cellEditorParams: {
                                    values: this.mapcolumns.map(sts => sts.label.trim()),

                                }, cellRenderer: this.mapColumnCellRenderer, filter: false, suppressMenu: true, width: 50, resizable: true,
                            },

                            { headerName: '', field: '', headerComponent: CustomButtonComponent, cellRenderer: this.removeCellRenderer, suppressMenu: true, filter: false, width: 10 },

                        ];
                }
            
        }
    }

    IsFixedValueColumnEditableClass(params: any): any {
        if (params.data.FileColumn == "Fixed Value") {
            return 'readonlyinput'
        }
        else {
            return ''
        }
        //console.log(params);
        //return '';
    }

    checkColumnEditable(params: any): boolean {
        if (params.data.FileColumn == "Fixed Value") {
            return true;
        }
        else {
            return false;
        }

    }
    setDataFeedGridColumns(): void {
        this.columnDefs = [];
        this.columnDefs =
            [
                {
                    headerName: 'Feed Name', field: 'FeedName', filter: 'set', menuTabs: ['filterMenuTab']
                },
                {
                    headerName: 'Data Type', field: 'DataType', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 30,
                },
                {
                    headerName: 'Source Type', field: 'SourceType', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 50,
                },
                {
                    headerName: 'Source Detail', field: 'SourceDetail', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 50,
                },
                {
                    headerName: 'Target Type', field: 'TargetType', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 30
                },
                {
                    headerName: 'Target Detail', field: 'TargetDetail', filter: 'set', menuTabs: ['filterMenuTab']
                },
                //{
                //    headerName: 'Interval', field: 'Interval', filter: 'set', menuTabs: ['filterMenuTab']
                //},
                //{
                //    headerName: 'Last Run', field: 'LastRun', filter: 'agDateColumnFilter', filterParams: {
                //        comparator: this.dateComparater,
                //        inRangeInclusive: 'true'

                //    }, valueFormatter: function (params: any) {
                //        if (params.value != "" && params.value != null) {
                //            let dateString = params.value;
                //            return moment(dateString).format(dateTimeConfig.dateformat.toUpperCase() + ' ' + dateTimeConfig.timeformat);
                //        }
                //    }, menuTabs: ['filterMenuTab'], minWidth: 140,
                //},
                //{
                //    headerName: 'Feed Status', field: 'FeedStatus', cellRenderer: this.statusCellRenderer, filter: 'set', menuTabs: ['filterMenuTab']
                //},
                {
                    headerName: 'Status', field: 'Status', filter: false, suppressMenu: true, valueFormatter: function (params: any) {
                        if (params.value == true) {
                            return "Active";
                        }
                        else {
                            return "Inactive";
                        }
                    }
                },
            ];

    }
    statusCellRenderer(params: any) {
        let html: string = '';

        if (params.value) {

            if (params.value == 'Live') {
                html = html + '<span class="padding-5 label" style= "background-color:green;font-size: small;" title="' + params.value + '" >' + params.value + '</span>';
            }
            else if (params.value == 'In progress') {
                html = html + '<span class="padding-5 label" style= "background-color:#2196F3;font-size: small;" title="' + params.value + '" >' + params.value + '</span>';
            }
            else if (params.value == 'Pause') {
                html = html + '<span class="padding-5 label" style= "background-color:#CCCC00;font-size: small;" title="' + params.value + '" >' + params.value + '</span>';
            }
            else {
                html = html + '<span class="padding-5 label" style= "background-color:red;font-size: small;" title="' + params.value + '" >' + params.value + '</span>';
            }
        }

        var eDiv = document.createElement('div');
        eDiv.innerHTML = html;
        eDiv.style.textAlign = 'center';
        return eDiv;
    }
    clickedFeedRow(event: any) {
        this.selectedfeedId = "";
        this.selectedfeedId = event.data.FeedId;
        this.selectedRowData = event.data;
    }
    //#region AG Grid Date Comparer
    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).split(dateTimeConfig.dateSeparator);
            var day = Number(dateParts[Number(dateTimeConfig.day)].substring(0, 2));
            var month = Number(dateParts[Number(dateTimeConfig.month)]) - 1;
            var year = Number(dateParts[Number(dateTimeConfig.year)]);

            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        }
        else {
            return -1;
        }
    }
    addModelValue(rowNodes: RowNode) {
        allNodesAddMap.push(rowNodes);
    }
    updateModelValues(rowNode: RowNode) {
        allNodesMap.push(rowNode);
    }
    //#endregion
    //#endregion
    //#endregion
    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];

        result.push({
            name: "Add Column",
            action: function () {

                params.context.parentComponent.addColumn();
            },
            icon: params.context.parentComponent._sharedService.addIconHTMLasString,
        });

        result.push({
            name: "Delete Column",
            action: function () {
                if (params.node) {
                    params.context.parentComponent.onCellClickedMap({ data: params.node.data }, 'Context Menu');
                }
            },
            icon: params.context.parentComponent._sharedService.removeIconHTMLasString,
        });
        return result;


    }
    //#endregion

    btnOkClick() {
        this.changeButtonsStatus(false);
        this.displayDocDefinitionDlg = false;
        // this._router.navigate([`/apifeed/${this.routeName}`]);        
        this._sharedService.onMappingDialogueStatusChanged(false);
    }

    populateViewDataType() {
        this._viewDataTypeService.getAllActiveViewDataTypeData(null)
        .subscribe({
            next: (response) => {
                this.viewDataTypeList = response.map((c) => { return { label: c.Name, value: c.Name }; });
            },
            error: (error) => {
                this.viewDataTypeList = [];
                console.log(error);
            }
        });
    }

    isValidDataType(col: Mapping) {
        if (col.FileColumn && col.FileColumn.indexOf('<<') != -1) {
            let fixVal = col.FileColumn.replace('<<', '').replace('>>', '');
            if (!this.viewDataTypeList.find(d => d.value == fixVal)) {
                return false;
            }
        }
        return true;
    }
}
