<p-confirmDialog *ngIf="display" header="Are you sure?" key="confirmdateupdate" icon="fa fa-question-circle"
    [style]="{width: '435px'}"></p-confirmDialog>
<form (ngSubmit)="onSubmit(heroEditForm)" #heroEditForm="ngForm" *ngIf="display ">
    <p-dialog [(visible)]="display" showEffect="fade" [modal]="true" [style]="{width: '733px',height:'500px'}" [resizable]="false">
        <ng-template pTemplate="header"  (keyup.shift.tab)="onKey()">
            Edit Container Date
        </ng-template>
        <div class="upload-dialog">
            <!--style="overflow-y: hidden !important;overflow-x:hidden !important"-->
            <div class="smart-form" >

                <div class="row">
                    <div class="form-group col-sm-6" >
                        <fieldset>
                            <label class="label">
                                {{_sharedService.getContainerLabel(recordType, "Type")}}
                                <span [hidden]="wotype.valid" class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" [autofocus]="true" id="wotype" name="wotype" [options]="WOTypes" required
                                placeholder="Select Type" [(ngModel)]="model.wotype" #wotype="ngModel" [filter]="true"
                                #dd #addwotypefilter [resetFilterOnHide]="true">

                            </p-dropdown>
                            
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6" *ngIf="recordType=='MXR'||recordType=='DOC'||recordType=='BND'">
                        <fieldset>
                            <label class="label">
                                {{_sharedService.getContainerLabel(recordType, "ReferenceKey")}}
                                <!-- {{referenceKeyLabel}} -->
                                <span [hidden]="recordType=='DOC'||recordType=='BND' || model.wonumber"
                                    class="text-danger">
                                    *
                                </span>
                                <span *ngIf="lastIsDotWonum" class="text-danger">
                                    Dot(.) is not allowed at the end
                                </span>
                            </label>

                            <label class="input rgxErr">
                                <i *ngIf="wonum.value && !wonum.valid && (wonum.touched || wonum.dirty )"
                                    class="icon-append fa fa-ban"
                                    title="{{_sharedService.error_rgx_pre}} {{_sharedService.re_WorkOrderNumber_Message}} {{_sharedService.error_rgx_post}}">
                                </i>
                                <input id="wonum" type="text" name="wonum" placeholder={{referenceKeyLabel}}
                                    [(ngModel)]="model.wonumber" [pattern]="_sharedService.re_WorkOrderNumber"
                                    (focusout)="isLastDotWonum(wonum.value)"
                                    [required]="(recordType!=='DOC' && recordType!=='BND') && model.enddate==null"
                                    #wonum="ngModel"
                                    [disabled]="(recordType!=='DOC' && recordType!=='BND') && model.enddate!=null"
                                    [ngClass]="{'readonlyinput2': (recordType!=='DOC' && recordType!=='BND') && model.enddate!=null}" />

                            </label>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6" *ngIf="recordType=='GEN'">
                        <fieldset>
                            <label class="label">
                                <!-- {{WorkOrderLabel}} -->
                                {{_sharedService.getContainerLabel(recordType, "ReferenceKey")}}
                                <span [hidden]="model.generalID" class="text-danger">
                                    *
                                </span>
                                <span *ngIf="lastIsDot" class="text-danger">
                                    Dot(.) is not allowed at the end
                                </span>
                            </label>
                            <label class="input rgxErr">
                                <span
                                    *ngIf="generalID.value && !generalID.valid && (generalID.touched || generalID.dirty )"
                                    class="icon-append fa fa-ban"
                                    title="{{_sharedService.error_rgx_pre}} {{_sharedService.re_WorkOrderNumber_Message}} {{_sharedService.error_rgx_post}}">
                                </span>

                                <input id="generalID" type="text" name="generalID" placeholder={{WorkOrderLabel}}
                                    [pattern]="_sharedService.re_WorkOrderNumber" required [(ngModel)]="model.generalID"
                                    (focusout)="isLastDot(generalID.value)" #generalID="ngModel">

                            </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                {{_sharedService.getContainerLabel(recordType, "Date")}}
                                <span [hidden]="crsdate.valid" class="text-danger">
                                    *
                                </span>
                                ({{ViewDateFormat}})
                            </label>
                            <label class="input">
                                <i class='icon-append fa fa-calendar'></i>
                                <p-calendar appendTo="body" [attr.tabindex]="0" placeholder="{{ViewDateFormat}}"
                                    class='datepicker hasDatepicker' id='crsdate' (onClose)="onCloseDate()"
                                    name="crsdate" [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030"
                                    [required]="(recordType!=='DOC' && recordType!=='BND')" [(ngModel)]="model.crsdate"
                                    #crsdate="ngModel" [dateFormat]="dateFormatCalender" [style]="{width:'315px'}">
                                </p-calendar>

                            </label>

                        </fieldset>

                    </div>
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                <!-- End Date  -->
                                {{_sharedService.getContainerLabel(recordType, "EndDate")}}
                                <span [hidden]="enddate.valid || model.wonumber?.length >0" class="text-danger">
                                    *
                                </span>
                                ({{ViewDateFormat}})
                            </label>
                            <label class="input enddate"
                                [ngClass]="{'readonlyinput': recordType=='MXR' && (model.wonumber?.length>0)}">
                                <i class='icon-append fa fa-calendar'></i>
                                <p-calendar appendTo="body" [attr.tabindex]="0" placeholder="{{ViewDateFormat}}"
                                    class='datepicker hasDatepicker' id='enddate' (onClose)="onCloseEnddate()"
                                    name="enddate" [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030"
                                    [required]="(recordType=='MXR' && (model.wonumber?.length==0))||recordType=='LOG'"
                                    [disabled]="recordType=='MXR' && (model.wonumber?.length>0)"
                                    [(ngModel)]="model.enddate" #enddate="ngModel" [dateFormat]="dateFormatCalender"
                                    [style]="{width:'315px'}">

                                </p-calendar>
                            </label>
                        </fieldset>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane" >
                <button type="submit" pButton icon="fa fa-check" class="btn btn-primary minzindex" label="Update"
                    [disabled]="!heroEditForm.form.valid "></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnCancelClick(heroEditForm)" label="Cancel" [disabled]="isSubmitted"
                    (focusout)="applyFocusToInput()"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>