import * as $ from 'jquery'
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { Router, CanActivate } from '@angular/router'

import { IMenu } from './Menu/menu';
import { IConfig } from '../helpers/config';
import { IDateFormatInfo } from '../helpers/dateFormatInfo'
import { Role } from '../services/Menu/role';
import { Loader } from '../components/loader/loader'
import { CommPartial } from '../../comm/components/comm/commpartial';
import * as moment from 'moment/moment';
import { RecordConfig } from '../../api/components/record-configuration/record-mapping'
import { StatusConfig } from '../../api/components/status-configuration/status-configuration'
import { Config } from 'protractor';
import { Options } from 'selenium-webdriver/safari';
// import { HttpParamsOptions } from '@angular/common/http/src/params';
import { settingsEntity } from '../../admin/components/settings/settings';
import { environment } from '../../../environments/environment';
import { viewJobProgress } from '../../status/components/view-job-progress/view-job-progress';
import { SelectItem, MessageService, Message } from 'primeng/api';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { OAuthService, AuthConfig, JwksValidationHandler } from 'angular-oauth2-oidc';

// var dateTimeConfig: IDateFormatInfo;
declare global {
    interface Navigator {
        msSaveBlob: (blob: Blob, fileName: string) => boolean
    }
}
@Injectable()
export class SharedService implements CanActivate {

    //#region Global Variables

    readonly baseUrl: string = environment.baseUrl;
    readonly TinyMecAPIKey = environment.tinyMceKey;
    // readonly appUrl: string = environment.appUrl
    prevHomeDashboardValue = null;
    public static current_ParentMenu_Id = -1;
    next_not_navigated_ParentMenu_Id: number;
    public static previous_ParentMenu_Id;

    ssoEnabled: boolean = false;
    isDefaultLogin: boolean = false;
    isAuthenticatedUser: boolean = false;
    isViewDocOnly: boolean = false;
    documentUrl: string;
    public prevURL: string;
    private _menuList: IMenu[];
    public UserRole: Role;
    _assetActualResult: any;
    _assetStatusActualResult: any;
    public selectedAsset: any;
    public selectedAssetTreeNode: any;
    public selectedAsn: any;
    public selectedAssetStatus: any;
    public StatusToggleValue: any;
    public selectedContainer: any;
    public selectedContainerFromComponentAdd: any;
    public selectedContainerFromComponentEdit: any;
    public selectedDocument: any;
    public selectedDocumentforMetaData: any;
    public selectedDocuments: any[];
    public selectedDocumentNode: string;
    public selectedDocumentsGridState: any[];
    private dateConfig: IConfig[] = [];
    public _configuration: IConfig[];
    public dateInformation: IDateFormatInfo;
    public searchText: string;
    public docCount: number;
    public selectedDocCount: number;
    public selectedModule: any;
    public viewRecords: any;
    public selectedTaskID: any;
    public selectedSection: any;
    public selectedTaskCount: any;
    public availableComponents: any[] = [];

    private _container_MXR_Properties: any;
    private _container_LOG_Properties: any;
    private _container_GEN_Properties: any;
    private _container_DOC_Properties: any;
    private _container_BND_Properties: any;

    private _document_MXR_Properties: any;
    private _document_LOG_Properties: any;
    private _document_GEN_Properties: any;
    private _document_DOC_Properties: any;
    private _document_BND_Properties: any;

    // public appHotkeys:  Hotkey[] = [];

    public selectedTaskEntity: any;

    public docToAttach: any[] = [];
    private _commObj: CommPartial;
    public navigationInfo: any;
    public navigationInfoDashboard: any;
    public navigationInfoStatusPopup: any;
    public tenantName: string;
    public selectedOutgoingFeed: any;
    public recordConfig: RecordConfig;
    public statusConfig: StatusConfig;
    public isDocManipulated: boolean;
    public isNavigationFilterCanceled: boolean = true;
    private _loggedInTenantId: string = "";
    private _containerType: any[] = [];
    public _canActivateRoute: boolean = true;
    public parentMenuClicked: boolean = false;
    public runningViewTasks: any[] = [];
    private _cosmosAuthToken = "";
    public isStatusAttachDocDialogueOpen: boolean = false;
    public _ContainerGridSelectedRows: any;
    public inWOGapAnalysisMode: boolean = false;
    private _userListofCurrentTenants: SelectItem[] = [];
    private _listOfJobStatus: any[] = [];
    private _listOfQualityStatus: any[] = [];
    private _listOfContainerQualityStatus: any[] = [];
    public containerSelectFirstRow: boolean = false;
    public isUnsavedChanges = false;
    //#endregion
    //#region Constants
    public addIconHTMLasString: string = '<div class="glyphicon glyphicon-plus"></div>';
    public editIconHTMLasString: string = '<div class="glyphicon glyphicon-pencil"></div>';
    public importFeedIconHTMLasString: string = '<div class="glyphicon glyphicon-upload"></div>';
    public importComputerIconHTMLasString: string = '<div class="glyphicon glyphicon-upload"></div>';
    public importStorageIconHTMLasString: string = '<div class="glyphicon glyphicon-upload"></div>';
    public exportFeedIconHTMLasString: string = '<div class="glyphicon glyphicon-download"></div>';
    public removeIconHTMLasString: string = '<div class="glyphicon glyphicon-remove"></div>';
    public refreshIconHTMLasString: string = '<div class="glyphicon glyphicon-refresh"></div>';
    public runIconHTMLasString: string = '<div class="glyphicon glyphicon-play"></div>';
    public logIconHTMLasString: string = '<div class="glyphicon glyphicon-file"></div>';
    public feedConfigIconHTMLasString: string = '<div class="glyphicon glyphicon-list-alt"></div>';
    public uploadFeedIconHTMLasString: string = '<div class="glyphicon glyphicon-open"></div>';
    public downloadLogFeedIconHTMLasString: string = '<div class="fa fa-columns"></div>';
    public saveLogFeedIconHTMLasString: string = '<div class="fa fa-save"></div>';
    public downloadsFeedIconHTMLasString: string = '<div class="glyphicon glyphicon-download-alt"></div>';
    public jobsIconHTMLasString: string = '<div class="fa fa-ticket"></div>';
    public uploadIconHTMLasString: string = '<div class="glyphicon glyphicon-open"></div>';
    public copyIconHTMLasString: string = '<div class="glyphicon glyphicon-copy"></div>';
    public trashIconHTMLasString: string = '<div class="glyphicon glyphicon-trash"></div>';
    //public allowedFileTypes: string[] = [] = new Array("application/pdf", "image/bmp", "image/png", "image/jpg", "image/jpeg", "image/jp2", "image/jpc", "image/jfif", "image/tif", "image/tiff", "image/gif");
    public allowedFileTypes: string[] = [] = new Array(".rar", "application/pdf", "image/bmp", "image/png", "image/jpg", "image/jpeg", "image/jp2", "image/jpc", "image/jfif", "image/tif", "image/tiff", "image/gif", "audio/mpeg", "audio/mp3", "audio/wav", "audio/ogg", "video/webm", "video/mp4", "video/mp4v", "video/ogg", "application/zip", "application/vnd.rar", "application/rar", "application/epub+zip", "application/gzip", "application/x-zip-compressed",
        "text/html", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword", "application/vnd.ms-word.document.macroEnabled.12", "application/vnd.ms-word2006ml", "application/vnd.ms-wordml",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel", "application/vnd.ms-excel.sheet.macroEnabled.12", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.ms-powerpoint", "application/vnd.ms-powerpoint.presentation.macroEnabled.12"
        , "application/vnd.ms-outlook", "application/vnd.oasis.opendocument.text", "application/vnd.oasis.opendocument.spreadsheet", "application/vnd.oasis.opendocument.presentation", "application/zip", "application/gzip", "application/epub+zip", "application/xml", "application/json", "message/rfc822", "application/rtf", "text/plain", "application/vnd.ms-outlook", "text/csv",
        "audio/*", "video/*", "image/*", "application/*", "text/*", ".rtf", ".msg", ".csv", ".tsv", ".tab");

    public allowedAudioVideoFileTypes: string[] = [] = new Array("mpeg", "mpeg3", "mp3", "wav", "ogg", "webm", "mp4");
    public allowedAudioFileTypes: string[] = [] = new Array("mpeg3", "mp3", "wav", "audio/mpeg", "audio/mpeg3", "audio/ogg", "audio/wav");
    public allowedVideoFileTypes: string[] = [] = new Array("webm", "mp4", "ogg", "video/mp4", "video/webm", "video/ogg");
    public allowedNonPreviewFileTypes: string[] = [] = new Array("zip", "rar", "epub", "gz", "x-gzip", "doc", "docx", "docm", "xls", "xlsx", "xlsm", "ppt", "pptx", "pptm", "odp", "ods", "odt", "xml", "txt", "html", "rtf", "json", "csv");
    public allowedMimeTypesImages: string[] = [] = new Array("zip", "rar", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "xml", "txt", "html", "rtf", "json", "csv", "css");
    public allowedDocumentTypes: string[] = [] = new Array("pdf");//, "xml", "txt", "html", "rtf", "json", "csv");
    public allowedViewDocumentTypes: string[] = [] = new Array("text/plain");//, "xml", "txt", "html", "rtf", "json", "csv");
    public allowedImageFileTypes: string[] = [] = new Array("gif", "png", "jpg", "jpeg", "bmp");
    public allowedImageFileContentTypes: string[] = [] = new Array("image/jpg", "image/jpeg", "image/jp2", "image/jpc", "image/jfif", "image/tif", "image/tiff", "image/gif", "image/png", "image/apng", "image/bmp", "image/apng", "image/tiff");
    //.pdf,.bmp,.dcx,.pcx,.png,.jpg,.jpeg,.jp2,.jpc,.jfif,.tif,.tiff,.gif,.djvu,.djv,.jb2.
    public allowedAzureIndexFileTypes: string[] = [] = new Array(".pdf", ".docx", ".doc", ".docm", ".xlsx", ".xls",
        ".xlsm", ".pptx", ".ppt", ".pptm", ".msg", ".xml", ".odt", ".ods", ".odp", ".html", ".xml", ".zip", ".gz", ".epub", ".eml", ".rtf", ".json", ".csv");

    public allowedAzureIndexFileContentTypes: string[] = [] = new Array("text/html", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword", "application/vnd.ms-word.document.macroEnabled.12", "application/vnd.ms-word2006ml", "application/vnd.ms-wordml",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel", "application/vnd.ms-excel.sheet.macroEnabled.12", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.ms-powerpoint", "application/vnd.ms-powerpoint.presentation.macroEnabled.12"
        , "application/vnd.ms-outlook", "application/vnd.oasis.opendocument.text", "application/vnd.oasis.opendocument.spreadsheet", "application/vnd.oasis.opendocument.presentation", "application/zip", "application/gzip", "application/epub+zip", "application/xml", "application/json", "message/rfc822", "application/rtf", "text/plain", "application/vnd.ms-outlook", "text/csv");

    public ACCEPT_FILE_TYPES_DOC_CATEGORY: string = ".rar,audio/*,video/*,image/*,application/*,text/*,.rtf,.msg,.csv,.tsv,.tab,.tar.gz,application/epub+zip,application/vnd.rar";//"*";
    public ACCEPT_FILE_TYPES_MXR_LOG_GEN_CATEGORY: string = ".pdf,.bmp,.dcx,.pcx,.png,.jpg,.jpeg,.jp2,.jpc,.jfif,.tif,.tiff,.gif,.djvu,.djv,.jb2";
    // accept=".pdf,.bmp,.dcx,.pcx,.png,.jpg,.jpeg,.jp2,.jpc,.jfif,.tif,.tiff,.gif,.djvu,.djv,.jb2,.mp3,.wav,.ogg,.mp4,.webm,.zip,.rar"
    // accept="application/pdf,image/bmp,image/dcx,image/pcx,image/png,image/jpg,image/jpeg,image/jp2,image/jpc,image/jfif,image/tif,image/tiff,image/gif,image/djvu,image/djv,image/jb2,audio/mpeg,audio/wav,audio/ogg,video/webm,video/mp4,video/ogg,application/zip,application/vnd.rar,application/rar,.zip,.rar,.7zip" 

    public MAX_ALLOWED_FILE_SIZE_FOR_DOC: number = 1073741824;


    //#endregion

    //#region Regular Expressions As Constants
    public re_AssetSerialNumber: String = "^[0-9a-zA-Z\\s-_!$()*:]*$";
    public re_AssetSerialNumber_Message: String = "-_!$()*:";

    public re_ContainerType: String = "^[0-9a-zA-Z\\s-_!$()*:]*$";
    public re_ContainerType_Message: String = "-_!$()*:";

    public re_WorkOrderNumber: String = "";// "^[0-9a-zA-Z\\s-_\.\{\}\/\\\\\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\u0061-\u007A\u0041-\u005A\u017E\u0178\u0161\u017D\u0152\u0160\u0192\u0153]*$";
    public re_WorkOrderNumber_Message: String = "";// '\\/_-.{}';

    public re_DocumentNumber: String = "";//"^[0-9a-zA-Z\\s~!@#%$^&*()-_+=`{}:;'<>?,./<>=%\"|\\\\\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\u0061-\u007A\u0041-\u005A\u017E\u0178\u0161\u017D\u0152\u0160\u0192\u0153]*$";
    public re_DocumentNumber_Message: String = "";//"!\”#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~";

    public re_DocumentType: String = "^[0-9a-zA-Z\\[\\]~!@#%$^&*(){}_+-:;<\"\\s\`\=\'>?,\.]*$";
    public re_DocumentType_Message: String = "~!@#%$^&*()_+=-`{}[]:\";'<>?,./";

    public re_ViewDisplayName: String = "^[a-zA-Z][0-9a-zA-Z\\s-_]*$";
    public re_ViewDisplayName_Message: String = "-_";

    public re_SelectPageRange: String = "^\d+(?:-\d+)?(?:,\d+(?:-\d+)?)*";


    public error_rgx_pre: string = 'only these';
    public error_rgx_post: string = 'special characters are allowed';
    //#endregion


    //#region Clickable Tokens As Constants
    public Token_CreatedBy: string = "{CreatedBy}";
    public Token_AssignedTo: string = "{AssignedTo}";
    public Token_jobid: string = "{jobid}";
    public Token_tenantname: string = "{tenantname}";
    public Token_ASN: string = "{ASN}";
    public Token_DataType: string = "{DataType}"
    public Token_Priority: string = "{Priority}";
    public Token_Conversation: string = "{Conversation}";
    public Token_Status: string = "{Status}";
    public Token_FileName: string = "{FileName}";
    public Token_ZipPassword: string = "{ZipPassword}";
    public Token_FileURL: string = "{FileURL}";
    public Token_Tenant: string = "{Tenant}";
    public Token_FeedName: string = "{FeedName}";
    public Token_FirstName: string = "{FirstName}";
    public Token_LastName: string = "{LastName}";
    public Token_Me: string = "{Me}";
    public Token_Today: string = "{Today}";
    public Token_Latest: string = "{Latest}";
    public Token_INPUT: string = "{INPUT}";
    public Token_SourceFilePath: string = "{SourceFilePath}";
    public Token_ErrorLogPath: string = "{ErrorLogPath}";

    public Token_Password: string = "{Password}";
    public Token_Email: string = "{Email}";
    public Token_UserName: string = "{UserName}";
    public Token_SharedKey: string = "{SharedKey}";

    public Token_RequestedBy: string = "{RequestedBy}";
    public Token_State: string = "{State}";
    public Token_Type: string = "{Type}";
    public Token_Description: string = "{Description}";
    public Token_TenantURL: string = "{TenantURL}";
    public Token_CurrentIP: string = "{UserIP}";
    public Token_UserUniqueID: string = "{UserUniqueID}";
    public Token_SignImage: string = "{SignImage}";
    public Token_UserLogin: string = "{UserLogin}";
    // public Token_AirframeTitle: string = "{AirframeTitle}";
    public Token_AssetType: string = "{AssetType}";
    public Token_AssetTitle: string = "{AssetTitle}";
    public Token_DateTime: string = "{DateTime}";
    public Token_ViewTitle: string = "{ViewTitle}";
    public Token_TenantLogo: string = "{TenantLogo}";
    public Token_ViewDisplayName: string = "{ViewDisplayName}";
    public Token_ROAMLogo: string = "{ROAMLogo}";
    public Token_AssetManufacturer: string = "{AssetManufacturer}";
    public Token_AssetManType: string = "{AssetManType}";
    public Token_AssetManVariant: string = "{AssetManVariant}";
    public Token_AssetTime: string = "{AssetTime}";
    public Token_CurrentDate: string = "{CurrentDate}";
    public Token_CurrentTime: string = "{CurrentTime}";
    public Token_PageNumber: string = "{PageNumber}";
    public Token_AssetCycle: string = "{AssetCycle}";
    public Token_AssetSerialNo: string = "{AssetSerialNo}";
    public Token_AssetReg: string = "{AssetReg}";
    public Token_ParentAssetReg: string = "{ParentAssetReg}";
    public Token_ParentAssetTitle: string = "{ParentAssetTitle}";
    public Token_ParentAssetManufacturer: string = "{ParentAssetManufacturer}";
    public Token_ParentAssetManType: string = "{ParentAssetManType}";
    public Token_ParentAssetManVariant: string = "{ParentAssetManVariant}";
    public Token_ParentAssetTime: string = "{ParentAssetTime}";
    public Token_ParentAssetCycle: string = "{ParentAssetCycle}";
    public Token_AssetPartNo: string = "{AssetPartNo}";
    public Token_Encryptedcode: string = "{Encryptedcode}";
    public Token_FilterComplianceStatus: string = "{FilterComplianceStatus}";
    //#endregion

    public Tenant_Internal: string = "internal";
    public Tenant_Help: string = "help";
    // public Tenant_Help: string = "helpuatcore";


    // public GridRowGroupHeight: number = 32;
    // public GridRowHeight: number = 27;
    //#region Observable Variables and Setters

    public _bulkSelectedDocuments = new Subject<object>();
    public _bulkSelectedDocumentsLst :any[];
    public _selectedAsset = new Subject<object>();
    public isChangeSelectedContainer = false;

    public _currentTempFeedASN = new Subject<object>();

    public _feedUpdated = new Subject<object>();

    public _selectedAsn = new Subject<object>();

    public _statusMenuButtons = new Subject<object>();

    public _statusMenuCount = new Subject<object>();

    public _selectedAssetStatus = new Subject<object>();

    public _StatusToggleValue = new Subject<object>();

    public _selectedModule = new Subject<object>();

    public _selectedViewRecord = new Subject<object>();

    public _isAssetControlVisible = new Subject<boolean>();

    public _isAssetStatusControlVisible = new Subject<boolean>();

    public _profileVisible = new Subject<boolean>();

    public _routeInfo = new Subject<boolean>();

    public _selectedContainer = new Subject<object>();
    public _selectedContainerFromComponentAdd = new Subject<object>();
    public _selectedContainerFromComponentEdit = new Subject<object>();
    public _selectedDocument = new Subject<object>();

    public _selectedTaskRecord = new Subject<object>();

    public _selectedDocuments = new Subject<object>();

    public _resetAssetControl = new Subject<boolean>();

    public _resetAssetStatusControl = new Subject<boolean>();

    public _isRefreshJobGrid = new Subject<boolean>();

    public _isRefreshDataFeedGrid = new Subject<boolean>();

    public _isRefreshTaskDocuments = new Subject<boolean>();

    public _isRefreshViewGrid = new Subject<boolean>();

    public _isRefreshInboxGrid = new Subject<boolean>();

    public _isRefreshDashboardComms = new Subject<boolean>();
    public _routeInfoDashboard = new Subject<boolean>();

    public _viewTasksChangeEvent = new Subject<any[]>();

    public recordType: string;
    public selectedTaskDocumentUrl: string;

    public _isShowTaskRow: any;

    public _isShowMsg = new Subject<object>();

    public _setAssetControlTextForDashboard = new Subject<any>();

    public _changeRecordConfigDialogueStatus = new Subject<any>();
    public _changeStatusConfigDialogueStatus = new Subject<any>();
    public _isViewItem = new Subject<any>();
    public _isViewItemInbox = new Subject<any>();
    public _isDialogueOpen = new Subject<boolean>();
    public _isRefreshStatusGrid = new Subject<boolean>();
    public _ishideRecordTreeControl = new Subject<boolean>();
    public _ishideStatusTreeControl = new Subject<boolean>();
    public _openCloseMappingDialogue = new Subject<boolean>();
    public _openCloseFeedLogDialogue = new Subject<boolean>();
    public _openCloseRecordConfigDialogue = new Subject<boolean>();
    public _openCloseStatusConfigDialogue = new Subject<boolean>();
    public _getDocGridrows = new Subject<object>();
    public _isRefreshUsersGrid = new Subject<boolean>();
    public _isRefreshAircraftGrid = new Subject<boolean>();
    public _menuIDFromParentMenu$ = new Subject<number>();
    public selectedFeedRowData: any = null;
    public selectedFeedRowForView: any = null;


    private _isFeedMappingOfChildComponent = new BehaviorSubject<boolean>(false);
    public isFeedMappingOfChildComponent$ = this._isFeedMappingOfChildComponent.asObservable();
    
    public UpdateFeedMappingComponentValue(newValue: boolean) {
        this._isFeedMappingOfChildComponent.next(newValue);
    }
    // public _searchText = new Subject<string>();
    public selectedASNViews = [];
    public isChecked: boolean = false;
    public _isTabViewChange = new Subject<boolean>();

    tabViewChange(isValueChanged: boolean) {
        this._isTabViewChange.next(isValueChanged);
    }
    public onFeedLogDialogueStatusChanged(flag: boolean) {
        this._openCloseFeedLogDialogue.next(flag);

    }
    public onMappingDialogueStatusChanged(flag: boolean) {
        this._openCloseMappingDialogue.next(flag);

    }
    public onRecordConfigDialogueStatusChanged(flag: boolean) {
        this._openCloseRecordConfigDialogue.next(flag);
    }
    public onStatusConfigDialogueStatusChanged(flag: boolean) {
        this._openCloseStatusConfigDialogue.next(flag);
    }
    public onStatusGridRefresh(flag: boolean) {
        this._isRefreshStatusGrid.next(flag);

    }
    public onGridRowClick(flag: boolean) {
        this._ishideRecordTreeControl.next(flag);

    }

    public onGridRowClickStatus(flag: boolean) {
        this._ishideStatusTreeControl.next(flag);

    }
    public onAssetChanged(selectAsset: any) {
        this.selectedAsset = selectAsset;
        this.updateRole();
        this._selectedAsset.next(selectAsset);

    }

    public feedUpdated() {
        this._feedUpdated.next(undefined);
    }
    public bulkSelectDocuments(selectDocuments: any) {
        this._bulkSelectedDocuments.next(selectDocuments);
        this._bulkSelectedDocumentsLst = selectDocuments;
    }

    public setCurrentFeedASN(asn: any) {
        this._currentTempFeedASN.next(asn);
    }

    public onAsnChanged(selectAsn: any) {
        this._selectedAsn.next(selectAsn);
      this.selectedAsn = selectAsn;
      
    }
    public onDialogueOpen(flag: boolean) {
        this._isDialogueOpen.next(flag);

    }

    public onAssetStatusChanged(selectAsset: any) {
        this.selectedAssetStatus = selectAsset;
        this.updateRole();
      this._selectedAssetStatus.next(selectAsset);
    }
    public StatusToggleChanged(selectStatus: any) {
        this.StatusToggleValue = selectStatus;
        this._StatusToggleValue.next(selectStatus);

    }

    public onViewRecordSelectionChanged(selectRecord: any) {
        this._selectedViewRecord.next(selectRecord);
        this.viewRecords = selectRecord;
    }

    public onModuleChanged(Module: any) {
        this._selectedModule.next(Module);
        this.selectedModule = Module;
    }

    public setAssetControlVisible(status: any) {
        this._isAssetControlVisible.next(status);
    }

    public setAssetStatusControlVisible(status: any) {
        this._isAssetStatusControlVisible.next(status);
    }

    public setProfileVisible(status: any) {
        this._profileVisible.next(status);
    }
    public setRouteInfoAndNavigate(routeInfo: any) {
        this._routeInfo.next(routeInfo);
        this.navigationInfo = ({ isNavigate: true, cat: routeInfo.cat, id: routeInfo.id, recordId: routeInfo.recordId, ColorCode: routeInfo.ColorCode });
    }

    public setRouteInfoAndNavigateStatusPopup(routeInfo: any) {
        this.navigationInfoStatusPopup = ({ isNavigate: true, cat: routeInfo.cat, id: routeInfo.id, recordId: routeInfo.recordId, ColorCode: routeInfo.ColorCode });
    }
  public resetAssetControl(status: any) {
      
        this._resetAssetControl.next(status);
    }

    public resetAssetStatusControl(status: any) {
        this._resetAssetStatusControl.next(status);
    }

    public onBrowseContainerSelection(browseRowData: any) {
        this._selectedContainer.next(browseRowData);
        this.selectedContainer = browseRowData;
    }
    public onMultiContainerSelection(browseRowData: any) {
        //this._selectedContainer.next(browseRowData);
        this._ContainerGridSelectedRows = browseRowData;
    }

    public onParentMenuChanged(menuID: number) {
      // setting current menu Id
      SharedService.current_ParentMenu_Id = menuID;
      // setting previous menu Id
      SharedService.previous_ParentMenu_Id = menuID == SharedService.previous_ParentMenu_Id ? SharedService.previous_ParentMenu_Id : menuID;
      // passing data to observable stream
      this._menuIDFromParentMenu$.next(menuID);
    }

    public onContainerSelectedFromComponentAdd(containerRow: any) {
        this._selectedContainerFromComponentAdd.next(containerRow);
        this.selectedContainerFromComponentAdd = containerRow;
    }
    public onContainerSelectedFromComponentEdit(containerRow: any) {
        this._selectedContainerFromComponentEdit.next(containerRow);
        this.selectedContainerFromComponentEdit = containerRow;
    }
    public onDocumentContainerSelection(documentRowData: any) {
        //R.A.
        //console.log("onDocumentContainerSelection - " + JSON.stringify(documentRowData));
        this._selectedDocument.next(documentRowData);
        this.selectedDocument = documentRowData;
    }

    public onTaskRecordSelection(taskRowData: any) {
        this._selectedTaskRecord.next(taskRowData);
    }
    public onSelectedDocumentsChange(documentRows: any,rowIndex:string) {
        this._selectedDocuments.next(documentRows);
        this.selectedDocuments = documentRows;
        this.selectedDocumentNode=rowIndex;
        this.selectedDocumentsGridState = documentRows;
    }

    public updateSearchText(searchedText: string) {
        // this._searchText.next(searchedText);
        this.searchText = searchedText;
        if (this.searchText == null || this.searchText == "" || this.searchText == undefined) {
            this.searchText = this.searchText
        }
        else {
            this.searchText = this.searchText.replace(" +", " || ");
        }
    }

    public resfreshJobGrid(status: any) {
        this._isRefreshJobGrid.next(status);
    }

    public resfreshDataFeedGrid(status: any) {
        this._isRefreshDataFeedGrid.next(status);
    }

    public resfreshViewGrid(status: any) {
        this._isRefreshViewGrid.next(status);
    }

    public resfreshTaskDocuments(status: boolean) {
        this._isRefreshTaskDocuments.next(status);
    }
    public resfreshInboxGrid(status: any) {
        this._isRefreshInboxGrid.next(status);
    }
    public showAlertMsg(status: any) {
        this._isShowMsg.next(status);
    }

    public updateDashboardComms(status: boolean) {
        this._isRefreshDashboardComms.next(status);

    }
    public setRouteInfoAndNavigateFromDashboard(routeInfo: any) {
        this._routeInfoDashboard.next(routeInfo);
        this.navigationInfoDashboard = ({ isNavigate: true, cat: routeInfo.cat, id: routeInfo.id, containerType: routeInfo.containerType, ColorCode: routeInfo.ColorCode });
    }
    public setAssetControlTextForDashboard(assetValue: any) {
        this._setAssetControlTextForDashboard.next(assetValue);
    }

    public changeRecordConfigDialogueStatus(status: any) {
        this._changeRecordConfigDialogueStatus.next(status);
    }
    public changeStatusConfigDialogueStatus(status: any) {
        this._changeStatusConfigDialogueStatus.next(status);
    }
    public viewItemDetails(status: any) {
        this._isViewItem.next(status);

    }
    public viewInboxItemDetails(status: any) {
        this._isViewItemInbox.next(status);

    }
    public resfreshUsersGrid(status: any) {
        this._isRefreshUsersGrid.next(status);
    }
    public resfreshAircraftGrid(status: any) {
        this._isRefreshAircraftGrid.next(status);
    }

    //#region Loader

    public _loading = new Subject<Loader>();

    public showLoader(loadingText: string, iconClass: string = 'fa-spin fa-spinner') {
        let loader: Loader = new Loader("block", loadingText, iconClass);
        this._loading.next(loader);
    }

    public hideLoader() {
        let loader: Loader = new Loader("none", "", "");
        this._loading.next(loader);
    }

    //#endregion


    //#endregion

    // #region Grid Double Click Events

    // Jobs
    public _editJob = new Subject<object>();

    updateEditJob(data: any) {
        this._editJob.next(data);
    }

    public _addJob = new Subject<object>();

    addJob(data: any) {
        this._addJob.next(data);
    }

    public _editJobByID = new Subject<string>();

    updateJobUsingId(jobID: string) {
        this._editJobByID.next(jobID);
    }
    // Comms
    public _editComm = new Subject<object>();

    updateCommJob(data: any) {
        this._editComm.next(data);
    }

    public _editCommById = new Subject<string>();

    updateCommUsingId(commID: string) {
        this._editCommById.next(commID);
    }

    public _addComm = new Subject<object>();

    addComm(comm: any) {
        this._addComm.next(comm);
    }

    // Status - Task
    public _addTask = new Subject<object>();
    UpdateTaskAdd(data: any) {
        this._addTask.next(data);
    }
    public _editTask = new Subject<object>();
    UpdateTaskEdit(data: any) {
        this._editTask.next(data);
    }
    // admin - DocumentType
    public _editAdminDocType = new Subject<object>();
    public _addAdminDocType = new Subject<object>();
    public _editFromMenuDocType = new Subject<string>();

    updateFromMenuAdminDocType(docTypeID: string) {
        this._editFromMenuDocType.next(docTypeID);
    }
    updateAdminDocType(data: any) {
        this._editAdminDocType.next(data);
    }
    addAdminDocType(data: any) {
        this._addAdminDocType.next(data);
    }
    // API Feed
    public _editApiFeed = new Subject<object>();

    updateApiFeed(data: any) {
        this._editApiFeed.next(data);
    }
    public _addFeedSub = new Subject<string>();

    addFeed(feedID: string) {
        this._addFeedSub.next(feedID);
    }

    public _editApiFeedContextMenu = new Subject<string>();
    editFeedContextMenu(feedID: string) {
        this._editApiFeedContextMenu.next(feedID);
    }
    public _importFeedStorageApiFeedContextMenu = new Subject<string>();
    ImportFeedStorageContextMenu(feedID: string) {
        this._importFeedStorageApiFeedContextMenu.next(feedID);
    }
    public _importFeedComputerContextMenu = new Subject<string>();
    ImportFeedComputerContextMenu(feedID: string) {
        this._importFeedComputerContextMenu.next(feedID);
    }
    public _uploadFeedSub = new Subject<object>();

    uploadFeed(data: any) {
        this._uploadFeedSub.next(data);
    }

    public _uploadFeedOptionsSub = new Subject<object>();

    uploadFeedOptions(data: any) {
        this._uploadFeedOptionsSub.next(data);
    }
    public _feedLogDetailSub = new Subject<string>();
    feedLogDetail(feedLogID: string) {
        this._feedLogDetailSub.next(feedLogID);
    }

    public _feedLogDownloadLogSub = new Subject<any>();
    feedLogDownloadLog(feedLogID: any) {
        this._feedLogDownloadLogSub.next(feedLogID);
    }

    public _feedLogDownloadDataSub = new Subject<any>();
    feedLogDownloadData(feedLogID: any) {
        this._feedLogDownloadDataSub.next(feedLogID);
    }
    // Container
    public _editContainer = new Subject<object>();

    updateEditContainer(data: any) {
        this._editContainer.next(data);
    }

    public _editContainerByObject = new Subject<object>();

    updateContainerByObject(data: any) {
        this._editContainerByObject.next(data);
    }
    public _addContainerByObject = new Subject<object>();

    addContainerByObject(data: any) {
        this._addContainerByObject.next(data);
    }
    // Browse Document
    public _editMetaData = new Subject<object>();

    updateEditMetadata(data: any) {
        this._editMetaData.next(data);
    }

    public _singleUpload = new Subject<object>();
    singleDocumentUpload(data: any) {
        this._singleUpload.next(data);
    }
    public _bulkUpload = new Subject<object>();
    bulkDocumentUpload(data: any) {
        this._bulkUpload.next(data);
    }
    // #endregion

    // #region PDF Manipulation Click Events
    public _extractPDF = new Subject<boolean>();

    openExtractPDFModal(data: any) {
        this._extractPDF.next(data);
    }
    public _MergePDF = new Subject<boolean>();

    openMergePDFModal(data: any) {
        this._MergePDF.next(data);
    }
    public _insertPDF = new Subject<boolean>();

    openInsertPDFModal(data: any) {
        this._insertPDF.next(data);
    }
    public _deletePDF = new Subject<boolean>();

    openDeletePDFModal(data: any) {
        this._deletePDF.next(data);
    }
    // #endregion


    // #region Audit

    public _currentAuditScreen = new Subject<object>();

    public showAuditHistory(auditConfiguration: any) {
        this._currentAuditScreen.next(auditConfiguration);
    }

    // #endregion

    public isInternalView: boolean = true; // Tre for Local/Internal


    //#region Init

    constructor(private http: HttpClient, private _router: Router, private messageService: MessageService,
        private _hotkeysService: HotkeysService, private oauthService: OAuthService) {
    }

    //#endregion

    //#region Autherization

    isAuthUser(): boolean {
        if (!localStorage.getItem('access_token') || !localStorage.getItem('expires') || +(new Date(localStorage.getItem('expires'))) <= +(new Date())) {
            return false;
        } else {
            return true;
        }
    }


    GetToken(): string {
        if (!localStorage.getItem('access_token') || !localStorage.getItem('expires') || +(new Date(localStorage.getItem('expires'))) <= +(new Date())) {
            return null;
        } else {
            return localStorage.getItem('access_token');
        }
    }

    //.net core test
    getLoginBody(userName: string, password: string, ssoValue): any {
        var loginBody = {
            userName: userName,
            password: password,
            sso: ssoValue
        }
        // let loginBody: string = "username=" + userName + "&password=" + password + "&grant_type=password";
        return loginBody;
    }
    // getLoginBody(userName: string, password: string,ssoValue): string {
    //     let loginBody: string = "username=" + userName + "&password=" + password + "&grant_type=password";
    //     return loginBody;
    // }

    getLoginBody2(userName: string, password: string): any {
        let loginBody: any =
        {
            userName: userName,
            password: password
        };
        return loginBody;
    }

    getContentTypeJsonReqOpt(): any {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        let options = ({ headers: headers });
        return options;

    }

    getContentTypeJsonPReqOpt(): any {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/jsonp');
        let options = ({ headers: headers });
        return options;
    }

    getContentTypeUrlReqOpt(): any {
        // let headers = new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     // 'Access-Control-Allow-Origin': '*',
        // });
        // // headers.append('Content-Type', 'application/x-www-form-urlencoded');
        // let options = ({ headers: headers });
        // return options;
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json');
        return headers;
    }
    getReqOptWithAccessToken() {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        // headers  = headers.append('Access-Control-Allow-Origin', 'true');
        headers = headers.append('Accept', 'application/json');
        // headers  = headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        // let headers = new HttpHeaders ({
        //     //'Content-Type': 'application/json',
        //     // 'Access-Control-Allow-Origin': '*',
        //      Authorization: 'Bearer ' + localStorage.getItem('access_token')
        //      });


        // headers.set('Content-Type', 'application/json');
        // headers.set('Accept', 'application/json');
        headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        let options = ({ headers: headers });
        return options;
    }

    getReqOptWithAccessTokenFileUpoad(): HttpHeaders {
        let options = new HttpHeaders({ Content: undefined, Authorization: 'Bearer ' + localStorage.getItem('access_token') });
        return options;
    }

    getReqOptWithAccessTokenForZipFile(): any {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        headers.append('Accept', 'application/octet-stream');
        let options = ({ headers: headers, responseType: 'arraybuffer' });
        return options;

    }


    getReqOptWithAccessTokenForTextFile(): any {
        let headers = new Headers();
        headers.append('Content-Type', 'application/text');
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        headers.append('Accept', 'application/octet-stream');
        let options = ({ headers: headers, responseType: 'arraybuffer' });
        return options;

    }

    getReqOptWithAccessTokenForExcelFile() {
        let headers = new Headers();
        headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        headers.append('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        let options = ({ headers: headers, responseType: 'arraybuffer' });
        return options;
    }
    getReqOptWithAccessTokenAndTimeOffset(): HttpHeaders {
        // let offsetvalue = new Date().getTimezoneOffset();
        // let headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        // headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        // headers.append('Offset', offsetvalue.toString());
        // let options = ({ headers: headers });
        // return options;

        let offsetvalue = new Date().getTimezoneOffset();
        let options = new HttpHeaders(
            {
                Content: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                Offset: offsetvalue.toString()
            });
        return options;
    }
    getCurrentUserName(): string {
        return localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName');
    }

    getCurrentUserType(): string {
        return localStorage.getItem('userType');
    }
    // Moved to UserService
    // logout() {
    //    localStorage.clear();
    // }

    // abandonSession(apiUrl: string, body: any, reqOption: RequestOptions): Observable<Response> {
    //    return this.http.post(`${apiUrl}/api/Account/AbandonUserSession`, body, reqOption);
    // }
    //#endregion

    // # Toast Messages
    showToast(msg: Message) {
        this.messageService.add(msg);
    }

    showToastMultiple(msgArray: Message[]) {
        this.messageService.addAll(msgArray);
    }

    clearToast() {
        this.messageService.clear();
    }
    //#endregion


    //#region Main Menu

    setMenuList(menuList: IMenu[]): void {
        this._menuList = menuList;
    }

    getMenuList(): IMenu[] {
        return this._menuList;
    }
    getAuthTokenForCosmos(): string {

        return this._cosmosAuthToken;
    }
    setAuthTokenForCosmos(token: string) {

        this._cosmosAuthToken = token;
    }
    setStatusModuleSelectedDocument(docUrl: string): any {
        this.selectedTaskDocumentUrl = null;
        this.selectedTaskDocumentUrl = docUrl;
    }
    getStatusModuleSelectedDocument(): any {
        return decodeURIComponent(this.selectedTaskDocumentUrl);
    }

    setModulesSelectedOptionForComm(com: CommPartial): void {
        this._commObj = new CommPartial();
        this._commObj.ASN = com.ASN;
        this._commObj.Module = com.Module;
        this._commObj.Record = com.Record;
        this._commObj.Section = com.Section;
        this._commObj.TaskId = com.TaskId;
    }
    getModulesSelectedOptionForComm(): CommPartial {
        return this._commObj;
    }
    //#endregion

    //#region Asset

    setAssetList(assetList: any): void {
        this._assetActualResult = assetList;
    }

    getAssetList(): any {
        return this._assetActualResult;
    }

    setAssetStatusList(assetList: any): void {
        this._assetStatusActualResult = assetList;
    }

    getAssetStatusList(): any {
        return this._assetStatusActualResult;
    }


    //#endregion

    //#region Configuration

    getConfigurationFromDB(apiUrl: string, body: any): Observable<IConfig[]> {
        return this.http.post<IConfig[]>(`${apiUrl}/api/AppConfig/GetApplicationConfigurations`, body, this.getReqOptWithAccessToken());

    }


    chartToolTip(params) {
        return {
            title: params.xValue,
            content: params.yValue.toFixed(0),
        };
    }
    GetSetupConfigByKey(key: any): Observable<settingsEntity> {
        return this.http.post<settingsEntity>(`${this.baseUrl}/api/AppConfig/GetSetupConfigByKey?key=${key}`, null, this.getReqOptWithAccessToken());
    }
    GetSetupVersionByKey(key: any): Observable<settingsEntity> {
        return this.http.post<settingsEntity>(`${this.baseUrl}/api/AppConfig/GetSetupVersionByKey?key=${key}`, null, this.getReqOptWithAccessToken());
    }
    getConfigurationByKey(key: any): Observable<settingsEntity> {
        return this.http.post<settingsEntity>(`${this.baseUrl}/api/AppConfig/GetConfigurationsByKey?key=${key}`, null, this.getReqOptWithAccessToken());

    }

    setConfiguration(config: IConfig[]): void {
        this._configuration = config;
        this.setDateConfigurationLocal();
        this.setLabelConfigurationLocal();
    }

    setDateConfigurationLocal() {
        localStorage.setItem('DateFormat', this._configuration.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString());
        localStorage.setItem('DateSeparater', this._configuration.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString());
        localStorage.setItem('DayIndex', this._configuration.filter(x => x.Key == "DayIndex").map(({ Value }) => Value).toString());
        localStorage.setItem('MonthIndex', this._configuration.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value).toString());
        localStorage.setItem('YearIndex', this._configuration.filter(x => x.Key == "YearIndex").map(({ Value }) => Value).toString());
        localStorage.setItem('TimeFormat', this._configuration.filter(x => x.Key == "TimeFormat").map(({ Value }) => Value).toString());
    }
    setLabelConfigurationLocal() {
        localStorage.setItem('Label_ReferenceKey', this._configuration.filter(x => x.Key == "Label_ReferenceKey").map(({ Value }) => Value).toString());
    }
    isTwoFactAuthEnabled() {
        return this._configuration.filter(x => x.Key == "Two_Factor_Auth").map(({ Value }) => Value).toString();
    }

    getConfiguration() {
        if (this._configuration != undefined && this._configuration.length > 0) {

            return this._configuration;
        } else {
            this.getConfigurationFromDB(this.baseUrl, null)
                .subscribe({
                    next: (response) => {
                        this.setConfiguration(response as IConfig[]);

                        return this._configuration;
                    }
                })
        }

    }


    getContainerLabel(category: string, fieldName: string) {
        if (this._container_MXR_Properties == null
            || this._container_LOG_Properties == null
            || this._container_GEN_Properties == null
            || this._container_DOC_Properties == null
            || this._container_BND_Properties == null) {
            this.settingUpContainerProperties();
        }

        var containerProperty: any = null;

        switch (category) {
            case "MXR":
                containerProperty = {};
                containerProperty = this._container_MXR_Properties.filter(x => x.FieldName == fieldName)[0];
                break;
            case "LOG":
                containerProperty = {};
                containerProperty = this._container_LOG_Properties.filter(x => x.FieldName == fieldName)[0];
                break;
            case "GEN":
                containerProperty = {};
                containerProperty = this._container_GEN_Properties.filter(x => x.FieldName == fieldName)[0];
                break;
            case "DOC":
                containerProperty = {};
                containerProperty = this._container_DOC_Properties.filter(x => x.FieldName == fieldName)[0];
                break;
            case "BND":
                containerProperty = {};
                containerProperty = this._container_BND_Properties.filter(x => x.FieldName == fieldName)[0];
                break;
        }
        if (containerProperty != null) {
            return containerProperty.ColumnLabel;
        }
        else return fieldName;

    }
    settingUpContainerProperties() {
        var appConfigs = this.getConfiguration();
        if (appConfigs != null) {
            this._container_MXR_Properties = JSON.parse(appConfigs.filter(x => x.Key == 'Container_MX_Records_Column_Setting')[0].Value);
            this._container_LOG_Properties = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Log_Books_Column_Setting')[0].Value);
            this._container_GEN_Properties = JSON.parse(appConfigs.filter(x => x.Key == 'Container_General_Column_Setting')[0].Value);
            this._container_DOC_Properties = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Documents_Column_Setting')[0].Value);
            this._container_BND_Properties = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Binders_Column_Setting')[0].Value);
        }
    }
    settingUpDocumentProperties() {
        var appConfigs = this.getConfiguration();
        if (appConfigs != null) {
            this._document_MXR_Properties = JSON.parse(appConfigs.filter(x => x.Key == 'Document_MX_Records_Column_Setting')[0].Value);
            this._document_LOG_Properties = JSON.parse(appConfigs.filter(x => x.Key == 'Document_Log_Books_Column_Setting')[0].Value);
            this._document_GEN_Properties = JSON.parse(appConfigs.filter(x => x.Key == 'Document_General_Column_Setting')[0].Value);
            this._document_DOC_Properties = JSON.parse(appConfigs.filter(x => x.Key == 'Document_Documents_Column_Setting')[0].Value);
            this._document_BND_Properties = JSON.parse(appConfigs.filter(x => x.Key == 'Document_Binders_Column_Setting')[0].Value);
        }
    }

    getDocumentLabel(category: string, fieldName: string) {
        if (this._document_MXR_Properties == null
            || this._document_LOG_Properties == null
            || this._document_GEN_Properties == null
            || this._document_DOC_Properties == null
            || this._document_BND_Properties == null) {
            this.settingUpDocumentProperties();
        }

        var documentProperty: any = null;

        switch (category) {
            case "MXR":
                documentProperty = {};
                documentProperty = this._document_MXR_Properties.filter(x => x.FieldName == fieldName)[0];
                break;
            case "LOG":
                documentProperty = {};
                documentProperty = this._document_LOG_Properties.filter(x => x.FieldName == fieldName)[0];
                break;
            case "GEN":
                documentProperty = {};
                documentProperty = this._document_GEN_Properties.filter(x => x.FieldName == fieldName)[0];
                break;
            case "DOC":
                documentProperty = {};
                documentProperty = this._document_DOC_Properties.filter(x => x.FieldName == fieldName)[0];
                break;

            case "BND":
                documentProperty = {};
                documentProperty = this._document_BND_Properties.filter(x => x.FieldName == fieldName)[0];
                break;
        }

        //return documentProperty.ColumnLabel;
        if (documentProperty != null) {
            return documentProperty.ColumnLabel;
        }
        else return fieldName;

    }

    //#endregion

    //#region Date Format Information
    getDateTimeFormatConfiguration(): IDateFormatInfo {
        this.dateConfig = this.getConfiguration();
        if (this.dateConfig) {
            let configs: IDateFormatInfo = {
                dateformat: this.dateConfig.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString(),
                dateSeparator: this.dateConfig.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString(),
                day: Number(this.dateConfig.filter(x => x.Key == "DayIndex").map(({ Value }) => Value)),
                month: Number(this.dateConfig.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value)),
                year: Number(this.dateConfig.filter(x => x.Key == "YearIndex").map(({ Value }) => Value)),
                timeformat: this.dateConfig.filter(x => x.Key == "TimeFormat").map(({ Value }) => Value).toString()
            }
            this.dateInformation = configs;
            return configs;
        } else {
            return this.getDateTimeFormatConfigFromLocalStorage();
        }

    }

    getFormatedDate(date?: any): Date {
        if (date != null) {
            let dateString = date.substr(0, 10);
            try {
                if (this.dateInformation && this.dateInformation.dateformat) {
                    let dateParts = moment(dateString).format(this.dateInformation.dateformat.toUpperCase()).split(this.dateInformation.dateSeparator);
                    let day = Number(dateParts[Number(this.dateInformation.day)].substring(0, 2));
                    let month = Number(dateParts[Number(this.dateInformation.month)]) - 1;
                    let year = Number(dateParts[Number(this.dateInformation.year)]);

                    let cellDate = new Date(year, month, day);
                    return cellDate;
                } else {
                    let localConfigs = this.getDateTimeFormatConfigFromLocalStorage();
                    let dateParts = moment(dateString).format(localConfigs.dateformat.toUpperCase()).split(localConfigs.dateSeparator);
                    let day = Number(dateParts[Number(localConfigs.day)].substring(0, 2));
                    let month = Number(dateParts[Number(localConfigs.month)]) - 1;
                    let year = Number(dateParts[Number(localConfigs.year)]);

                    let cellDate = new Date(year, month, day);
                    return cellDate;
                }
            } catch (ex) {
                console.log(ex);
            }
        } else {
            return null;
        }
    }

    getFormatedDateTime(date?: any): Date {
        if (date != null) {
            let dateString = date.substr(0, 10);
            try {
                if (this.dateInformation && this.dateInformation.dateformat) {
                    let dateParts = moment(dateString).format(this.dateInformation.dateformat.toUpperCase()).split(this.dateInformation.dateSeparator);
                    let day = Number(dateParts[Number(this.dateInformation.day)].substring(0, 2));
                    let month = Number(dateParts[Number(this.dateInformation.month)]) - 1;
                    let year = Number(dateParts[Number(this.dateInformation.year)]);
                    let hours = new Date(date).getHours();
                    let minits = new Date(date).getMinutes();
                    let cellDate = new Date(year, month, day, hours, minits);
                    return cellDate;
                } else {
                    let localConfigs = this.getDateTimeFormatConfigFromLocalStorage();
                    let dateParts = moment(dateString).format(localConfigs.dateformat.toUpperCase()).split(localConfigs.dateSeparator);
                    let day = Number(dateParts[Number(localConfigs.day)].substring(0, 2));
                    let month = Number(dateParts[Number(localConfigs.month)]) - 1;
                    let year = Number(dateParts[Number(localConfigs.year)]);
                    let hours = new Date(date).getHours();
                    let minits = new Date(date).getMinutes();
                    let cellDate = new Date(year, month, day, hours, minits);
                    return cellDate;
                }
            } catch (ex) {
                console.log(ex);
            }
        } else {
            return null;
        }
    }

    getCalenderDateFormat(): string {
        try {
            let dateConfig: IDateFormatInfo = this.getDateTimeFormatConfiguration();
            let dateParts: string[] = dateConfig.dateformat.split(dateConfig.dateSeparator);

            if (dateConfig.year == 0) {
                return (dateParts[dateConfig.year].substr(0, 2) + dateConfig.dateSeparator + dateParts[dateConfig.month] + dateConfig.dateSeparator + dateParts[dateConfig.day]).toLowerCase();
            } else if (dateConfig.year == 2) {
                if (dateConfig.month == 1) {
                    return (dateParts[dateConfig.day] + dateConfig.dateSeparator + dateParts[dateConfig.month] + dateConfig.dateSeparator + dateParts[dateConfig.year].substr(0, 2)).toLowerCase();
                } else if (dateConfig.month == 0) {
                    return (dateParts[dateConfig.month] + dateConfig.dateSeparator + dateParts[dateConfig.day] + dateConfig.dateSeparator + dateParts[dateConfig.year].substr(0, 2)).toLowerCase();
                }
            } else {
                return "yy-mm-dd"
            }
        } catch (ex) {
            console.log(ex);
        }
    }


    getDateFormatTimeDialogBottom(date: any) {

        if (date) {
            let dateConfig = this.getDateTimeFormatConfiguration();
            return moment.utc(date).format(dateConfig.timeformat + "  " + dateConfig.dateformat.toUpperCase()).toString();
        } else {
            return '';
        }
    }

    getLabelTextDateFormat() {
        let dateConfig = this.getDateTimeFormatConfiguration();
        return dateConfig.dateformat.toString();

    }

    getDateInUserFormat(date: any) {
        if (date) {
            return moment(date).format(this.getDateTimeFormatConfiguration().dateformat.toUpperCase());
        } else {
            return '';
        }
    }

    //#endregion
    //#region Date Comparator
    // dateComparater(filterLocalDateAtMidnight: any, cellValue: any)
    // {
    //    if (cellValue != null) {
    //        let dateString = cellValue.substring(0, 10);
    //        var dateParts = moment(dateString).format(dateTimeConfig.format.toUpperCase()).split(dateTimeConfig.dateSeparator);
    //        var day = Number(dateParts[Number(dateTimeConfig.day)].substring(0, 2));
    //        var month = Number(dateParts[Number(dateTimeConfig.month)]) - 1;
    //        var year = Number(dateParts[Number(dateTimeConfig.year)]);
    //        var cellDate = new Date(year, month, day);

    //        if (cellDate < filterLocalDateAtMidnight) {
    //            return -1;
    //        } else if (cellDate > filterLocalDateAtMidnight) {
    //            return 1;
    //        } else {
    //            return 0;
    //        }
    //    }
    //    else {
    //        return -1;
    //    }

    // }
    // endregion
    //#region Date Format Information from local storage
    getDateTimeFormatConfigFromLocalStorage(): IDateFormatInfo {
        let configs: IDateFormatInfo = {
            dateformat: localStorage.getItem('DateFormat'),
            dateSeparator: localStorage.getItem('DateSeparater'),
            day: Number(localStorage.getItem('DayIndex')),
            month: Number(localStorage.getItem('MonthIndex')),
            year: Number(localStorage.getItem('YearIndex')),
            timeformat: localStorage.getItem('TimeFormat')
        }
        this.dateInformation = configs;
        return configs;
    }
    // endregion
    //#region Roles

    isInRole(role: string): boolean {
        let userRole: string = localStorage.getItem("roles");
        let userRoleArry: string[] = userRole.split(",");

        if (userRoleArry.some(x => x == role)) {
            return true;
        } else {
            return false;
        }
    }

    isCurrentAssetReadOnly() {
        let isReadOnly: boolean = false;
        try {
            if (this.selectedAsset && this.selectedAsset.length > 0 && this.selectedAsset[0].data) {

                //US-8181 // let assetdetailArray = this.selectedAsset[0].data.split('|');
                // if (assetdetailArray.length >= 10) {
                //     if (assetdetailArray[9] == 'True') {
                //         isReadOnly = true;
                //     } else {
                //         isReadOnly = false;
                //     }
                // } else {
                //     isReadOnly = false;
                // }

                let assetDataObj = JSON.parse(this.selectedAsset[0].data);
                isReadOnly = assetDataObj.ReadOnly;
            } else {
                isReadOnly = false;
            }

        } catch (ex) {
            console.log(ex);
        }

        return isReadOnly;
    }

    isReadOnlyAssetExist() {
        let isReadOnly: boolean = false;
        try {
            if (this.selectedAsset && this.selectedAsset.length > 0 && this.selectedAsset[0].data) {
                for (let index = 0; index < this.selectedAsset.length; index++) {
                    //US-8181 //let assetdetailArray = this.selectedAsset[index].data.split('|');
                    // if (assetdetailArray.length == 10) {
                    //     if (assetdetailArray[9] == 'True') {
                    //         isReadOnly = true;
                    //         return;
                    //     } else {
                    //         isReadOnly = false;
                    //     }
                    // } else {
                    //     isReadOnly = false;
                    // }
                    let assetDataObj = JSON.parse(this.selectedAsset[index].data);
                    if (assetDataObj.ReadOnly) {
                        isReadOnly = true;
                        return;
                    }
                }

            } else {
                isReadOnly = false;
            }

        } catch (ex) {
            console.log(ex);
        }

        return isReadOnly;
    }


    updateRole(): void {
        try {
            if (this.selectedAsset && this.selectedAsset.length > 0 && this.selectedAsset[0].data) {
                //US-8181 //// let assetdetailArray = this.selectedAsset[0].data.split('|');
                // if (assetdetailArray.length >= 10) {
                //     if (assetdetailArray[9] == 'True') {
                //         this.updateReadOnlyRole();
                //     } else {
                //         this.updateAssignedRole();
                //     }
                // } 
                let assetDataObj = JSON.parse(this.selectedAsset[0].data);
                if (assetDataObj.ReadOnly) {
                    this.updateReadOnlyRole();
                }
                else {
                    //this.updateAssignedRole();
                    this.updateAssignedPlanRole();
                }
            } else {
                this.updateAssignedRole();
            }

        } catch (ex) {
            console.log(ex);
        }
    }

    updateRoleBySelectedAsset(currentSelectedAsset: any) {
        try {
            if (currentSelectedAsset && currentSelectedAsset.data) {

                let assetDataObj = JSON.parse(currentSelectedAsset.data);
                if (assetDataObj.ReadOnly) {
                    this.updateReadOnlyRole();
                }
                else {
                    this.updateSelectedAssetPlanRole(assetDataObj);
                }
            } else {
                this.updateAssignedRole();
            }

        } catch (ex) {
            console.log(ex);
        }
    }
    updateAssignedRole(): void {
        try {
            let userRole: string = localStorage.getItem("roles");
            let userRoleArray: string[] = userRole.split(",");
            this.UserRole = new Role();
            for (let i = 0; i < userRoleArray.length; i++) {
                this.UserRole[userRoleArray[i]] = true;
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    updateReadOnlyRole(): void {
        try {
            let userRole: string = localStorage.getItem("roles");
            let readonlyRole = this._configuration.filter(x => x.Key == "Readonly_Roles").map(({ Value }) => Value).toString();
            let readonlyRoleArray = readonlyRole ? readonlyRole.split(",") : [];
            let userRoleArray: string[] = [];
            if (readonlyRoleArray && readonlyRoleArray.length > 0) {

                readonlyRoleArray.forEach(role => {
                    if (userRole.indexOf(role.trim()) != -1) { userRoleArray.push(role); }
                });
            }

            this.UserRole = new Role();
            for (let i = 0; i < userRoleArray.length; i++) {
                this.UserRole[userRoleArray[i]] = true;
            }

        } catch (ex) {
            console.log(ex);
        }
    }

    updateAssignedPlanRole(): void {
        try {
            //console.log("-----------------------------");

            let userRole: string = localStorage.getItem("roles");
            let userRoleArray: string[] = userRole.split(",");
            //console.log("UserRoles: "  + userRole);


            let assetDataObj = JSON.parse(this.selectedAsset[0].data);
            let planRoleArray: string[] = assetDataObj.PlanRoles.split(",");

            //console.log("PlanRoles: "  + assetDataObj.PlanRoles);
            this.UserRole = new Role();

            const filtered_IntersectedRoles = userRoleArray.filter(value => planRoleArray.includes(value));

            for (let i = 0; i < filtered_IntersectedRoles.length; i++) {
                this.UserRole[filtered_IntersectedRoles[i]] = true;
            }
            //console.log("filtered_IntersectedRoles: "  + filtered_IntersectedRoles.toString());
        } catch (ex) {
            console.log(ex);
        }
    }
    updateSelectedAssetPlanRole(currentSelectedAsset: any): void {
        try {
            //console.log("-----------------------------");

            let userRole: string = localStorage.getItem("roles");
            let userRoleArray: string[] = userRole.split(",");
            //console.log("UserRoles: "  + userRole);


            let assetDataObj = currentSelectedAsset;//JSON.parse(this.selectedAsset[0].data);
            let planRoleArray: string[] = assetDataObj.PlanRoles.split(",");

            //console.log("PlanRoles: "  + assetDataObj.PlanRoles);
            this.UserRole = new Role();

            const filtered_IntersectedRoles = userRoleArray.filter(value => planRoleArray.includes(value));

            for (let i = 0; i < filtered_IntersectedRoles.length; i++) {
                this.UserRole[filtered_IntersectedRoles[i]] = true;
            }
            //console.log("filtered_IntersectedRoles: "  + filtered_IntersectedRoles.toString());
        } catch (ex) {
            console.log(ex);
        }
    }
    //#endregion

    //#region Utilities
    cloneObject(obj: any): any {
        let newObj = new Object();
        for (let prop in obj) {
            if (obj[prop]) {
                newObj[prop] = obj[prop];
            }
        }
        return newObj;
    }


    clearSharedData() {
        this.isAuthenticatedUser = false;
        this._menuList = null;
        this._assetActualResult = null;
        this.selectedAsset = null;
        this.selectedAssetStatus = null;
        this._configuration = null;
    }

    resetSharedData() {
        this._menuList = [];
        this._assetActualResult = [];
        this.selectedAsset = [];
        this._configuration = [];
        this.selectedAssetStatus = [];
        localStorage.clear();

    }


    //#endregion

    //#region Greeting
    getGreetingLabel(): string {
        let sGreetingMsg: string = "Welcome";
        let dateObj = new Date();
        let currentHour = dateObj.getHours();
        if (currentHour < 12)
            return sGreetingMsg = "Good morning";
        else if (currentHour < 17)
            return sGreetingMsg = "Good afternoon";
        else
            return sGreetingMsg = "Good evening";
    }
    //#endregion

    // #region Show Hide Menu

    showHideStatusMenu() {
        let statusMenus = document.getElementsByClassName("status-menu");
        for (var i = 0; i < statusMenus.length; i++) {
            statusMenus[i].setAttribute("style", "display:none;");
            let currentMenu: any = statusMenus[i];
            if (currentMenu.innerText.trim() != "Dashboard")
                statusMenus[i].parentElement.setAttribute("style", "display:none;");
            // .style.display = "none";
        }

        let repairMenus = document.getElementsByClassName("repair-menu");
        for (var i = 0; i < repairMenus.length; i++) {
            repairMenus[i].setAttribute("style", "display:none;");
            let currentMenu: any = repairMenus[i];
            if (currentMenu.innerText.trim() != "Dashboard")
                repairMenus[i].parentElement.setAttribute("style", "display:none;");
            // .style.display = "none";
        }

        if (SharedService.current_ParentMenu_Id == 22) {
            // let statusMenus = document.getElementsByClassName("status-menu");
            // for (var i = 0; i < statusMenus.length; i++) {
            //     statusMenus[i].setAttribute("style", "display:none;");
            //     // .style.display = "none";
            // }
            this.selectedASNViews = [];
            this.selectedASNViews.push('DFPStatus')
            if (this.selectedAssetStatus && this.selectedAssetStatus[0] && this.UserRole["Status_Readonly"]) {
                //US-8181 //let dataArray = this.selectedAssetStatus[0].data.split('|');
                let assetDataObj = JSON.parse(this.selectedAssetStatus[0].data);
                // Show selected Asset's Menu(s)
                let menuClass = `${assetDataObj.AssetID}_${assetDataObj.AssetType}`;//`${dataArray[3]}_${dataArray[4]}`;
                let menu = document.getElementsByClassName(menuClass);
                if (menu.length > 0) {
                    for (var i = 0; i < menu.length; i++) {
                        let currentMenu: any = menu[i];
                        this.selectedASNViews.push(currentMenu.innerText.replaceAll('-', '_').trim())
                        menu[i].setAttribute("style", "display:block;");
                        menu[i].parentElement.style.display = "block"
                    }
                }

                // Show All Asset's Menu(s)
                let allAssetMenuClass = `${assetDataObj.AssetType}_True`;//`${dataArray[4]}_True`;
                let allAssetMenus = document.getElementsByClassName(allAssetMenuClass);
                for (var i = 0; i < allAssetMenus.length; i++) {
                    let currentMenu: any = allAssetMenus[i];
                    this.selectedASNViews.push(currentMenu.innerText.replaceAll('-', '_').trim())
                    allAssetMenus[i].setAttribute("style", "display:block;");
                    allAssetMenus[i].parentElement.style.display = "block"
                }

            }
        } else if (SharedService.current_ParentMenu_Id == 99) {
            // let repairMenus = document.getElementsByClassName("repair-menu");
            // for (var i = 0; i < repairMenus.length; i++) {
            //     repairMenus[i].setAttribute("style", "display:none;");
            //     // .style.display = "none";
            // }

            if (this.selectedAssetStatus && this.selectedAssetStatus[0] && this.UserRole["Repairs_Readonly"]) {
                //US-8181 //let dataArray = this.selectedAssetStatus[0].data.split('|');
                let assetDataObj = JSON.parse(this.selectedAssetStatus[0].data);
                // Show selected Asset's Menu(s)
                let menuClass = `${assetDataObj.AssetID}_${assetDataObj.AssetType}`;// `${dataArray[3]}_${dataArray[4]}`;
                let menu = document.getElementsByClassName(menuClass);
                if (menu.length > 0) {
                    for (var i = 0; i < menu.length; i++) {
                        let currentMenu: any = menu[i];
                        this.selectedASNViews.push(currentMenu.innerText.replaceAll('-', '_').trim())
                        menu[i].setAttribute("style", "display:block;");
                        menu[i].parentElement.style.display = "block";
                    }
                }

                // Show All Asset's Menu(s)
                let allAssetMenuClass = `${assetDataObj.AssetType}_True`;//`${dataArray[4]}_True`;
                let allAssetMenus = document.getElementsByClassName(allAssetMenuClass);
                for (var i = 0; i < allAssetMenus.length; i++) {
                    allAssetMenus[i].setAttribute("style", "display:block;");
                    allAssetMenus[i].parentElement.style.display = "block";
                    let currentMenu: any = allAssetMenus[i];
                    this.selectedASNViews.push(currentMenu.innerText.replaceAll('-', '_').trim())

                }
            }
        }
        // if (this.screenMenuCount == this.totalcount || this.screenMenuCount < this.totalcount) {
    }


    // public onStatusSelection(isMenu: any) {
    //     this._statusMenuButtons.next(isMenu);
    // }

    showHideRecordMenu() {
        try {
          
          if (SharedService.current_ParentMenu_Id != 22
            && SharedService.current_ParentMenu_Id != 99) {

                let mxrecords = document.getElementsByClassName("menu-mxrecords");
                if (mxrecords.length > 0) {
                    mxrecords[0].setAttribute("style", "display:none;");
                    mxrecords[0].parentElement.style.display = 'none';
                }
                let logbooks = document.getElementsByClassName("menu-logbooks");
                if (logbooks.length > 0) {
                    logbooks[0].setAttribute("style", "display:none;");
                    logbooks[0].parentElement.style.display = 'none';
                }
                let general = document.getElementsByClassName("menu-general");
                if (general.length > 0) {
                    general[0].setAttribute("style", "display:none;");
                    general[0].parentElement.style.display = 'none';
                }
                let _document = document.getElementsByClassName("menu-documents");
                if (_document.length > 0) {
                    _document[0].setAttribute("style", "display:none;");
                    _document[0].parentElement.style.display = 'none';
                }
                let binders = document.getElementsByClassName("menu-binders");
                if (binders.length > 0) {
                    binders[0].setAttribute("style", "display:none;");
                    binders[0].parentElement.style.display = 'none';
                }

                if (this.selectedAsset && this.selectedAsset[0]) {

                    let assetDataObject = JSON.parse(this.selectedAsset[0].data);


                  if (SharedService.current_ParentMenu_Id == 16){

                        if (assetDataObject.MXRecords) {
                            if (mxrecords[0]) {
                                mxrecords[0].setAttribute("style", "display:block;");
                                mxrecords[0].parentElement.style.display = 'block';
                                // this.recordType = 'mxr';
                                // this._router.navigate(['/record/browse']);
                            }
                        }

                        if (assetDataObject.LogBooks) {
                            if (logbooks[0]) {
                                logbooks[0].setAttribute("style", "display:block;");
                                logbooks[0].parentElement.style.display = 'block';
                                // this.recordType = 'log';
                                // this._router.navigate(['/record/logs']);
                            }
                        }

                        if (assetDataObject.General) {
                            if (general[0]) {
                                general[0].setAttribute("style", "display:block;");
                                general[0].parentElement.style.display = 'block';
                                // this.recordType = 'gen';
                                // this._router.navigate(['/record/general']);
                            }
                        }

                        if (assetDataObject.Documents) {
                            if (_document[0]) {
                                _document[0].setAttribute("style", "display:block;");
                                _document[0].parentElement.style.display = 'block';
                                // this.recordType = 'doc';
                                // this._router.navigate(['/record/documents']);
                            }
                        }
                        ////////////////////////////////
                        if (!this.recordType) return;
                        //code for MXRecords selected.
                        if (this.recordType.toLowerCase() == 'mxr') {
                            if (assetDataObject.MXRecords && mxrecords[0]) {
                                this.recordType = 'mxr';
                                // this._router.navigate(['/record/browse']);
                            }
                            else if (assetDataObject.LogBooks && logbooks[0]) {
                                // else if (assetDataObject.LogBooks) {
                                this.recordType = 'log';
                                // this._router.navigate(['/record/logs']);
                            }
                            else if (assetDataObject.General && general[0]) {
                                this.recordType = 'gen';
                                // this._router.navigate(['/record/general']);
                            }
                            else if (assetDataObject.Documents && _document[0]) {
                                // else if (assetDataObject.Documents) {
                                this.recordType = 'doc';
                                // this._router.navigate(['/record/documents']);
                            }
                        }

                        //code for Logs selected.
                        else if (this.recordType.toLowerCase() == 'log') {
                            if (assetDataObject.LogBooks && logbooks[0]) {
                                // else if (assetDataObject.LogBooks) {
                                this.recordType = 'log';
                                // this._router.navigate(['/record/logs']);
                            }
                            else if (assetDataObject.MXRecords && mxrecords[0]) {
                                this.recordType = 'mxr';
                                // this._router.navigate(['/record/browse']);
                            }
                            else if (assetDataObject.General && general[0]) {
                                this.recordType = 'gen';
                                // this._router.navigate(['/record/general']);
                            }
                            else if (assetDataObject.Documents && _document[0]) {
                                // else if (assetDataObject.Documents) {
                                this.recordType = 'doc';
                                // this._router.navigate(['/record/documents']);
                            }
                        }

                        //code for General selected.
                        else if (this.recordType.toLowerCase() == 'gen') {
                            if (assetDataObject.General && general[0]) {
                                this.recordType = 'gen';
                                // this._router.navigate(['/record/general']);
                            }
                            else if (assetDataObject.MXRecords && mxrecords[0]) {
                                this.recordType = 'mxr';
                                // this._router.navigate(['/record/browse']);
                            }
                            else if (assetDataObject.LogBooks && logbooks[0]) {
                                // else if (assetDataObject.LogBooks) {
                                this.recordType = 'log';
                                // this._router.navigate(['/record/logs']);
                            }
                            else if (assetDataObject.Documents && _document[0]) {
                                // else if (assetDataObject.Documents) {
                                this.recordType = 'doc';
                                // this._router.navigate(['/record/documents']);
                            }
                        }

                        //code for document selected.
                        else if (this.recordType.toLowerCase() == 'doc') {
                            if (assetDataObject.Documents && _document[0]) {
                                // else if (assetDataObject.Documents) {
                                this.recordType = 'doc';
                                // this._router.navigate(['/record/documents']);
                            }
                            else if (assetDataObject.MXRecords && mxrecords[0]) {
                                this.recordType = 'mxr';
                                // this._router.navigate(['/record/browse']);
                            }
                            else if (assetDataObject.LogBooks && logbooks[0]) {
                                // else if (assetDataObject.LogBooks) {
                                this.recordType = 'log';
                                // this._router.navigate(['/record/logs']);
                            }
                            else if (assetDataObject.General && general[0]) {
                                this.recordType = 'gen';
                                // this._router.navigate(['/record/general']);
                            }
                        }
                    }
                    else if (SharedService.current_ParentMenu_Id == 113) {
                        if (assetDataObject.Binders) {
                            if (binders[0]) {
                                binders[0].setAttribute("style", "display:block;");
                                binders[0].parentElement.style.display = 'block';
                                this.recordType = 'bnd';
                                // this._router.navigate(['/transition/binders'])
                            }
                        }
                    }





                    /* OLD CODE
                                        let dataArray = this.selectedAsset[0].data.split('|');
                                        // Show selected Menu(s)
                                        if (dataArray[5] == "True") {
                                            if (mxrecords[0]) {
                                                mxrecords[0].setAttribute("style", "display:block;");
                                                mxrecords[0].parentElement.style.display = 'block';
                                            }
                                            this.recordType = 'mxr';
                                            // Display LogBook
                                            if (dataArray[6] == "True") {
                                                if (logbooks[0]) {
                                                    logbooks[0].setAttribute("style", "display:block;");
                                                    logbooks[0].parentElement.style.display = 'block';
                                                }
                                            }
                                            // Display General
                                            if (dataArray[7] == "True") {
                                                if (general[0]) {
                                                    general[0].setAttribute("style", "display:block;");
                                                    general[0].parentElement.style.display = 'block';
                                                }
                                            }
                                            // Display Documents
                                            if (dataArray[8] == "True") {
                                                if (_document[0]) {
                                                    _document[0].setAttribute("style", "display:block;");
                                                    _document[0].parentElement.style.display = 'block';
                                                }
                                            }
                    
                                            this._router.navigate(['/record/browse']);
                    
                                        }
                                        if (dataArray[6] == "True") {
                                            if (logbooks[0]) {
                                                logbooks[0].setAttribute("style", "display:block;");
                                            }
                                            if (dataArray[5] != "True") {
                                                this.recordType = 'log';
                                                // Display General
                                                if (dataArray[7] == "True") {
                                                    if (general[0]) {
                                                        general[0].setAttribute("style", "display:block;");
                                                    }
                                                }
                    
                                                this._router.navigate(['/record/logs'])
                                            }
                                        }
                                        if (dataArray[7] == "True") {
                                            if (general[0]) { general[0].setAttribute("style", "display:block;"); }
                    
                                            if (dataArray[5] != "True" && dataArray[6] != "True") {
                                                this.recordType = 'gen';
                                                this._router.navigate(['/record/general'])
                                            }
                                        }
                    
                                        // Display Documents
                                        if (dataArray[8] == "True") {
                    
                                            if (_document[0]) { _document[0].setAttribute("style", "display:block;"); }
                    
                                            if (dataArray[5] != "True" && dataArray[6] != "True" && dataArray[7] != "True") {
                                                this.recordType = 'doc';
                                                this._router.navigate(['/record/documents'])
                                            }
                                        }
                    
                    
                                        */
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    // #endregion

    //#region Date Time Format
    getDateFormatTime(date: any) {
        if (date) {
            return moment.utc(date).format("DD-MM-YYYY h:mm a").toString() + ' (GMT)';
        } else {
            return '';
        }
    }
    //#endregion

    getLoggedInTenantId(): string {
        if (this._loggedInTenantId == "") {
            let tenantID = localStorage.getItem('LoggedInTenantID');
            if (tenantID == "0" || tenantID == "9999") {
                this._loggedInTenantId = tenantID;
                return this._loggedInTenantId;
            } else { return this._loggedInTenantId; }
        } else { return this._loggedInTenantId; }

    }

    setLoggedInTenantId(tenantId: string) {
        this._loggedInTenantId = "";
        this._loggedInTenantId = tenantId;
        localStorage.setItem('LoggedInTenantID', tenantId);

    }

    getContainerTypes(): any[] {

        return this._containerType;
    }

    setContainerTypes(containerTypes: any[]) {
        this._containerType = [];
        this._containerType = containerTypes;
    }

    //#region Pagination Logic
    getGridPageSize(selectorId: string): number {
        let defaultSize: number = 20;
        let selectedElement = document.getElementById(selectorId);
        let gridElement = selectedElement.getElementsByClassName("ag-body-viewport").item(0); // //get Grid Body Div Height
        let clientGridHeight = Math.floor(gridElement.clientHeight) - 1;
        // let clientGridHeight = Math.floor(gridElement.clientHeight) - 1;
        let remainder = clientGridHeight % 26;
        remainder = remainder == 0 ? 27 : remainder;
        let gridHeight = clientGridHeight - remainder;//3;//19;//-16; //get Grid Body Div Height (-12 for New ag-theme-balham theme)

        let size: number = 0;
        try {
            let screenWidth = window.innerWidth;
            if (screenWidth <= 1024) {
                defaultSize = (gridHeight / 28);
            }
            else {
                defaultSize = (gridHeight / 27.5);
                //defaultSize = (gridHeight / 27);
            }

            var isNumber = Number(defaultSize) === defaultSize && defaultSize % 1 === 0;
            let rowHeight = Math.floor(defaultSize);
            if (isNumber) {
                size = rowHeight - 1;//Math.floor(defaultSize) - 1;

            }
            else {
                size = rowHeight - 1;//Math.floor(defaultSize) - 1;
            }

            // if((gridHeight + rowHeight) < clientGridHeight)
            // {
            //     console.log("(gridHeight + rowHeight) < clientGridtHeight");
            //     size = rowHeight; //Math.floor(defaultSize);
            // }
            // else{
            //     console.log("remove 1 row...");
            //     size = rowHeight - 1 ;//Math.floor(defaultSize) - 1;
            // }

            //let size: number = Math.floor(defaultSize) - 1;


            try {
                // let gridElement = selectedElement.getElementsByClassName("ag-body-viewport").item(0);
                // border: 1px solid red !important;
                gridElement.setAttribute("style", "overflow-y:hidden !important;height: 100vh !important;");
            } catch (ex) {
                console.log(ex);
            }
            return size;
        } catch (e) {
            console.log(e);
        }

        return defaultSize;


    }
    getGridPageSizeAircraft(selectorId: string): number {
        let defaultSize: number = 20;
        let selectedElement = document.getElementById(selectorId);
        let gridElement = selectedElement.getElementsByClassName("ag-body-viewport").item(0); // //get Grid Body Div Height
        let clientGridHeight = Math.floor(gridElement.clientHeight) - 1;
        // let clientGridHeight = Math.floor(gridElement.clientHeight) - 1;
        let remainder = clientGridHeight % 26;
        remainder = remainder == 0 ? 27 : remainder;
        let gridHeight = clientGridHeight - remainder;//3;//19;//-16; //get Grid Body Div Height (-12 for New ag-theme-balham theme)

        let size: number = 0;
        try {
            let screenWidth = window.innerWidth;
            if (screenWidth <= 1024) {
                defaultSize = (gridHeight / 28);
            }
            else {
                defaultSize = (gridHeight / 27);
                //defaultSize = (gridHeight / 27);
            }

            var isNumber = Number(defaultSize) === defaultSize && defaultSize % 1 === 0;
            let rowHeight = Math.floor(defaultSize);
            if (isNumber) {
                size = rowHeight - 1;//Math.floor(defaultSize) - 1;

            }
            else {
                size = rowHeight - 1;//Math.floor(defaultSize) - 1;
            }

            // if((gridHeight + rowHeight) < clientGridHeight)
            // {
            //     console.log("(gridHeight + rowHeight) < clientGridtHeight");
            //     size = rowHeight; //Math.floor(defaultSize);
            // }
            // else{
            //     console.log("remove 1 row...");
            //     size = rowHeight - 1 ;//Math.floor(defaultSize) - 1;
            // }

            //let size: number = Math.floor(defaultSize) - 1;


            try {
                // let gridElement = selectedElement.getElementsByClassName("ag-body-viewport").item(0);
                // border: 1px solid red !important;
                gridElement.setAttribute("style", "overflow-y:hidden !important;height: 100vh !important;");
            } catch (ex) {
                console.log(ex);
            }
            return size;
        } catch (e) {
            console.log(e);
        }

        return defaultSize;


    }
    getGridPageSizeHavingBeadCrumb(selectorId: string): number {
        let defaultSize: number = 20;
        let gridHeight = document.getElementById(selectorId).clientHeight;
        try {
            defaultSize = (gridHeight / 28);

            let size: number = Math.round(defaultSize) - 1;

            return size;
        } catch (e) {
            console.log(e);
        }
        return defaultSize;
    }
    getGridPageSizeAudit(selectorId: string): number {
        let defaultSize: number = 20;
        let selectedElement = document.getElementById(selectorId);
        let gridHeight = selectedElement.clientHeight;
        try {
            let screenWidth = window.innerWidth;
            if (screenWidth <= 1024) {
                defaultSize = (gridHeight / 28);
            }
            else if (screenWidth >= 2050) {
                defaultSize = (gridHeight / 29);
            }
            else {
                defaultSize = (gridHeight / 27);
            }

            let size: number = Math.round(defaultSize) - 1;
            try {
                let gridElement = selectedElement.getElementsByClassName("ag-body-viewport").item(0);
                // gridElement.setAttribute("style", "overflow-y:hidden !important;");
            } catch (ex) {
                console.log(ex);
            }
            return size;
        } catch (e) {
            console.log(e);
        }
        return defaultSize;
    }
    getGridRowHeight(/*params: any*/): number {
        return 27;
        // if (params.node.group) {
        //     return 50;
        // } else {
        //     return 27;
        // }
    }
    //#endregion

    //#region html tags sanitizor
    filterHTMLBody(htmlBody: string): string {
        let body: string = '';
        if (htmlBody) {
            try {
                body = htmlBody.replace(new RegExp("<\/p><p>", 'g'), "<br>").replace(new RegExp("<br><br><br><br>", 'g'), "<br>").replace(new RegExp("<br><br><br>", 'g'), "<br><br>");
            } catch (e) {
                console.log(e);
                return htmlBody;
            }

        }
        return body;
    }
    //#endregion

    downloadRDOC(filename: string): Observable<string> {
        return this.http.post<string>(`${this.baseUrl}/api/AzureBlob/GetRSADownload?filename=${filename}`, null, this.getReqOptWithAccessToken())

    }

    downloadAUP(filename: string, conainerName: string): Observable<string> {
        return this.http.get<string>(`${this.baseUrl}/api/AzureBlob/GetRSADownloadUrl?filename=${filename}&container=${conainerName}`, this.getReqOptWithAccessToken())

    }

    canActivate() {
        return this._canActivateRoute;
    }

    //#region Init

    getGroupNameKey(channel: string): string {

        return channel + "-" + this.getCurrentUserName() + "-" + this.tenantName;
    }

    setViewTaskProgress(task: viewJobProgress): void {
        let existedTaskIndex = this.runningViewTasks.findIndex(a => a.JobID == task.JobID)
        if (existedTaskIndex != -1) {
            if (task.CurrentTaskIndex > 0) {

                this.runningViewTasks[existedTaskIndex].TotalRecords = task.TotalRecords;
                this.runningViewTasks[existedTaskIndex].CurrentTaskIndex = task.CurrentTaskIndex;
                this.runningViewTasks[existedTaskIndex].ViewID = task.ViewID;
                this.runningViewTasks[existedTaskIndex].JobID = task.JobID;
                this.runningViewTasks[existedTaskIndex].Message = task.Message;
                this.runningViewTasks[existedTaskIndex].ActiveChannel = task.ActiveChannel;
                this.runningViewTasks[existedTaskIndex].State = task.State;
                this.runningViewTasks[existedTaskIndex].Percentage = this.getPercentage(task.CurrentTaskIndex, task.TotalRecords);
            }
            if (task.ViewName) {
                this.runningViewTasks[existedTaskIndex].ViewName = task.ViewName;
            }

        } else {

            task.Percentage = this.getPercentage(task.CurrentTaskIndex, task.TotalRecords);
            this.runningViewTasks.push(task);
        }

        this._viewTasksChangeEvent.next(this.runningViewTasks);
    }
    setViewTaskProgressFromSignalR(task: viewJobProgress): void {
        let existedTaskIndex = this.runningViewTasks.findIndex(a => a.JobID == task.jobID)
        if (existedTaskIndex != -1) {
            if (task.CurrentTaskIndex > 0) {

                this.runningViewTasks[existedTaskIndex].TotalRecords = task.totalRecords;
                this.runningViewTasks[existedTaskIndex].CurrentTaskIndex = task.currentTaskIndex;
                this.runningViewTasks[existedTaskIndex].ViewID = task.viewID;
                this.runningViewTasks[existedTaskIndex].JobID = task.jobID;
                this.runningViewTasks[existedTaskIndex].Message = task.message;
                this.runningViewTasks[existedTaskIndex].ActiveChannel = task.activeChannel;
                this.runningViewTasks[existedTaskIndex].State = task.state;
                this.runningViewTasks[existedTaskIndex].Percentage = this.getPercentage(task.currentTaskIndex, task.totalRecords);
            }
            if (task.viewName) {
                this.runningViewTasks[existedTaskIndex].ViewName = task.viewName;
            }

        } else {

            task.Percentage = this.getPercentage(task.currentTaskIndex, task.totalRecords);
            this.runningViewTasks.push(task);
        }

        this._viewTasksChangeEvent.next(this.runningViewTasks);
    }


    getPercentage(obtainedValue, totalValue): number {
        var result = ((obtainedValue / totalValue) * 100);
        return parseInt(result.toFixed(2));
    }


    getReferenceKeyLabel(): string {
        let labelValue: string = this._configuration ? this._configuration.filter(x => x.Key == "Label_ReferenceKey").map(({ Value }) => Value).toString() : '';
        if (labelValue && labelValue != "") {
            return labelValue;
        } else {
            return localStorage.getItem('Label_ReferenceKey');
        }
    }


    getConfigValueFromKey(key): string {
        let labelValue: string = this._configuration ? this._configuration.filter(x => x.Key == key).map(({ Value }) => Value).toString() : '';
        if (labelValue && labelValue != "") {
            return labelValue;
        }
    }
    getShowAutoSearchBatchJobScheduled(): string {
        let labelValue: string = this._configuration ? this._configuration.filter(x => x.Key == "Show_Auto_Search_Batch_Job_Scheduled").map(({ Value }) => Value).toString() : '';
        if (labelValue && labelValue != "") {
            return labelValue;
        } else {
            return localStorage.getItem('Show_Auto_Search_Batch_Job_Scheduled');
        }
    }

    getMaxDocumentLimitInBytes(): number {
        let documentValue: string = this._configuration.filter(x => x.Key == "Max_Document_Size").map(({ Value }) => Value).toString();
        if (documentValue && documentValue != "") {
            return Number(documentValue) * (1024 * 1024);

        }
    }
    getSharedAccessExpiryTimeThreshold(): any {
        let Value: string = this._configuration.filter(x => x.Key == "SharedAccessExpiryTimeThreshold").map(({ Value }) => Value).toString();
        var toDayDate = new Date();
        if (Value && Value != "") {
            let returnValue = new Date(toDayDate.setDate(toDayDate.getDate() + (+Value)));
            return returnValue;
        }
    }
    getMaxDocumentLimit(): number {
        let documentValue: string = this._configuration.filter(x => x.Key == "Max_Document_Size").map(({ Value }) => Value).toString();
        if (documentValue && documentValue != "") {
            return Number(documentValue);

        }
    }

    public setAssignedToUsersList(userList: any[]) {
        this._userListofCurrentTenants = [];
        this._userListofCurrentTenants = userList;
    }
    public getAssignedToUsersList() {
        return this._userListofCurrentTenants;
    }
    public setJobStatusList(jobsStatusList: any[]) {
        this._listOfJobStatus = [];
        this._listOfJobStatus = jobsStatusList;
    }

    public getJobStatusList() {
        return this._listOfJobStatus;
    }
    public setQualityStatusList(qualityStatusList: any[]) {
        this._listOfQualityStatus = [];
        this._listOfQualityStatus = qualityStatusList;
    }
    public getDocumentQualityStatusList() {
        return this._listOfQualityStatus;
    }
    public setContainerQualityStatusList(containerQualityStatusList: any[]) {
        this._listOfContainerQualityStatus = [];
        this._listOfContainerQualityStatus = containerQualityStatusList;
    }
    getQualityStatusConfigurations(key: string): boolean {
        let labelValue: string = this._configuration ? this._configuration.filter(x => x.Key == key).map(({ Value }) => Value).toString() : '';
        if (labelValue && labelValue != "") {
            let convertedValue = labelValue.trim().toLowerCase() == "true" ? true : false;// Boolean(labelValue);
            return convertedValue;
        } else {
            return false;
        }
    }

    public getQualityStatusList() {
        return this._listOfQualityStatus;
    }
    public getContainerQualityStatusList() {
        return this._listOfContainerQualityStatus;
    }

    isDocFileNameConventionGUID(): boolean {
        let isGUID: string = this._configuration.filter(x => x.Key == "Document_File_Name_Create_GUID").map(({ Value }) => Value).toString();
        if (isGUID && isGUID != "") {
            let convertedValue = isGUID.trim().toLowerCase() == "true" ? true : false;
            return convertedValue;
        } else {
            return false;
        }
    }

    isRegenerateFileName(): boolean {
        let isRegenFileName: string = this._configuration.filter(x => x.Key == "Document_File_Name_Update_Regenerate_Metadata").map(({ Value }) => Value).toString();
        if (isRegenFileName && isRegenFileName != "") {
            let convertedValue = isRegenFileName.trim().toLowerCase() == "true" ? true : false;
            return convertedValue;
        } else {
            return false;
        }
    }


    isDocFileNameConventionGUIDExport(): boolean {
        let isGUIDForExport: string = this._configuration.filter(x => x.Key == "Document_File_Name_Export_Regenerate_Metadata").map(({ Value }) => Value).toString();
        if (isGUIDForExport && isGUIDForExport != "") {
            let convertedValue = isGUIDForExport.trim().toLowerCase() == "true" ? true : false;
            return convertedValue;
        } else {
            return false;
        }
    }

    //#endregion

    // Article Category
    public _editAdminCategory = new Subject<object>();
    public _addAdminCategory = new Subject<object>();
    public _editFromMenuCategoryId = new Subject<string>();

    updateFromMenuAdminCategory(CategoryID: any) {

        this._editFromMenuCategoryId.next(CategoryID);
    }
    updateAdminCategory(data: any) {
        this._editAdminCategory.next(data);
    }

    addAdminCategory(data: any) {
        this._addAdminCategory.next(data);
    }

    // Article Section

    public _editAdminSection = new Subject<object>();
    public _addAdminSection = new Subject<object>();
    public _editFromMenuSectionId = new Subject<string>();

    updateFromMenuAdminSection(CategoryID: any) {
        this._editFromMenuSectionId.next(CategoryID);
    }
    updateAdminSection(data: any) {
        this._editAdminSection.next(data);
    }
    addAdminSection(data: any) {
        this._addAdminSection.next(data);
    }

    // SSO
    getSubdomain() {
        let domain = window.location.hostname;
        let subDomain: string;
        if (domain.indexOf('.') > 0 || domain.split('.')[0] !== 'www') {
            subDomain = domain.split('.')[0];
        }
        return subDomain.replace('https://', '').replace('http://', '');
    }

    configureSSOAuth(config) {
        // setup oauthService
        var authConfig: AuthConfig = config.Configuration;
        var customConfig = config.CustomAttribute;
        this.oauthService.configure(authConfig);
        // this.oauthService.setStorage(sessionStorage);
        this.oauthService.tokenValidationHandler = new JwksValidationHandler();
        // subscribe to token events
        //  this.oauthService.events
        //  .pipe(filter((e: any) => e.type === 'token_received'))
        //  .subscribe(() => this.handleNewToken());

        // continue initializing app (provoking a token_received event) or redirect to login-page

        if (customConfig.ssoProvider.toLowerCase() == "adfs" && customConfig.ver == "2016") {
            this.oauthService.tryLogin().then(isLoggedIn => {
                if (isLoggedIn) {
                    this.oauthService.setupAutomaticSilentRefresh();
                } else {
                    this.oauthService.initImplicitFlow();
                }
            });
        }
        else {
            this.oauthService.loadDiscoveryDocumentAndTryLogin().then(isLoggedIn => {
                if (isLoggedIn) {
                    this.oauthService.setupAutomaticSilentRefresh();
                } else {
                    this.oauthService.initImplicitFlow();
                }
            }).catch(error => { debugger});
        }
    }


    ToggleChecked() {

        if (this.isChecked) {
            this.isChecked = false;
        }
        else {
            this.isChecked = true;
        }
    }



    getFileNameFromURL(fileUrl?: any, isDocumentIncluded: boolean = false): string {


        try {
            let newString: string[] = [];
            newString = isDocumentIncluded ? fileUrl.split('?')[0].split('/') : fileUrl.split('/');

            // let newString: string[] = fileUrl.split('/');
            // let newString: string[] = fileUrl.split('?')[0].split('/');
            let fileName: string = newString[newString.length - 1];

            //console.log('getFileNameFromURL : => ' + fileName);
            return fileName;

        }
        catch (e) {
            console.log(e);
        }

    }

    public deepCopy(o: any) {
        var copy = o, k;

        if (o && typeof o === 'object') {
            copy = Object.prototype.toString.call(o) === '[object Array]' ? [] : {};
            for (k in o) {
                copy[k] = this.deepCopy(o[k]);
            }
        }

        return copy;
    }

    GetGuid() {
        // return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        //     var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        //     return v.toString(16);
        //   });
        return (this.chr4() + this.chr4() + '-' + this.chr4() + '-' + this.chr4() + '-' + this.chr4() + '-' + this.chr4() + this.chr4() + this.chr4()).toLowerCase();
    }

    chr4() {
        return Math.random().toString(16).slice(-4);
    }

    getISOFormat(date: any) {
        if (date) {
            return moment(date).format("YYYY-MM-DD");
        } else {
            return '';
        }
    }

    getISODateTimeFormat(date: any) {
        if (date) {
            return moment(date).format("YYYY-MM-DDTHH:mm:ss");
        } else {
            return '';
        }
    }

    getLocalDateFromUTCDate(date: Date) {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    }

    getUTCDateFromLocalDate(date: Date) {
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    }

    setBrowseDocumentHeight() {


        if (!$('browse-container>article>div').css('height') || !$('browse-document>article>div').css('height') || !$('browse-document>article>div>div>div:nth-child(2)').find('#searchblock').css('height')) {

            return;
        }
        let browseContainerHeight: number = $('browse-container>article>div').css('height').replace('px', '');
        let browseDocumentHeight: number = $('browse-document>article>div').css('height').replace('px', '');
        if (browseContainerHeight == 0) {
            let fieldSetheight = $('.fset').css('height').replace('px', '');
            if (fieldSetheight < 12) {
                fieldSetheight = fieldSetheight + $('.fset').css('padding').replace('px', '');
            }
            let innerGridHeight: number = $('browse-document>article>div>div').css('height').replace('px', '');
            let innerGridPadding: number = $('browse-document>article>div>div').css('padding').replace('px', '');
            let innerGridHeaderHeight: number = $('browse-document>article>div>div>div:nth-child(2)').find('#searchblock').css('height').replace('px', '');
            let ActualGridHeight = innerGridHeight - innerGridPadding - innerGridHeaderHeight - fieldSetheight - 1;
            let currentHeight: string = ActualGridHeight + 'px !important';
            // currentHeight.includes("NaN") && (currentHeight="64.5vh !important")
            // currentHeight.includes("NaN") && ($("#documentGrid").attr('style', 'min-height:' + currentHeight + ''))
            $("#documentGrid").attr('style', 'height:' + currentHeight + '');
        }
        else {
            if (browseDocumentHeight > browseContainerHeight) {
                let difference = browseDocumentHeight - browseContainerHeight;
                let fieldSetheight = $('.fset').css('height').replace('px', '');
                if (fieldSetheight < 12) {
                    fieldSetheight = fieldSetheight + $('.fset').css('padding').replace('px', '');
                }
                let innerGridHeight: number = $('browse-document>article>div>div').css('height').replace('px', '');
                let innerGridPadding: number = $('browse-document>article>div>div').css('padding').replace('px', '');
                let innerGridHeaderHeight: number = $('browse-document>article>div>div>div:nth-child(2)').find('#searchblock').css('height').replace('px', '');
                let ActualGridHeight = innerGridHeight - innerGridPadding - innerGridHeaderHeight - difference - fieldSetheight - 1;
                let currentHeight: string = ActualGridHeight + 'px !important';
                // currentHeight.includes("NaN") && (currentHeight="64.5vh !important")
                // currentHeight.includes("NaN") && ($("#documentGrid").attr('style', 'min-height:' + currentHeight + ''))
                $("#documentGrid").attr('style', 'height:' + currentHeight + '');
            }
            else {
                let fieldSetheight = $('.fset').css('height').replace('px', '');
                if (fieldSetheight < 12) {
                    fieldSetheight = fieldSetheight + $('.fset').css('padding').replace('px', '');
                }
                let innerGridHeight: number = $('browse-document>article>div>div').css('height').replace('px', '');
                let innerGridPadding: number = $('browse-document>article>div>div').css('padding').replace('px', '');
                let innerGridHeaderHeight: number = $('browse-document>article>div>div>div:nth-child(2)').find('#searchblock').css('height').replace('px', '');
                let ActualGridHeight = innerGridHeight - innerGridPadding - innerGridHeaderHeight - fieldSetheight - 1;
                let currentHeight: string = ActualGridHeight + 'px !important';
                // currentHeight.includes("NaN") && (currentHeight="64.5vh !important")
                // currentHeight.includes("NaN") && ($("#documentGrid").attr('style', 'min-height:' + currentHeight + ''))
                $("#documentGrid").attr('style', 'height:' + currentHeight + '');
            }
        }
    }

    getHelpArticleComponents() {
        this.availableComponents = []
        this.availableComponents.push({ lable: 'Records Container', value: 'RECC' });
        this.availableComponents.push({ lable: 'Records Document', value: 'RECD' });
        this.availableComponents.push({ lable: 'Records PDF', value: 'RECP' });
        this.availableComponents.push({ lable: 'Transition Container', value: 'TRAC' });
        this.availableComponents.push({ lable: 'Transition Document', value: 'TRAD' });
        this.availableComponents.push({ lable: 'Transition PDF', value: 'TRAP' });
        this.availableComponents.push({ lable: 'Data Processing Jobs', value: 'DPRJ' });
        this.availableComponents.push({ lable: 'Repair Views', value: 'REPV' });
        this.availableComponents.push({ lable: 'Status Views', value: 'STAV' });
        this.availableComponents.push({ lable: 'Status Admin View', value: 'SADV' });
        this.availableComponents.push({ lable: 'API Import', value: 'APII' });
        this.availableComponents.push({ lable: 'API Export', value: 'APIE' });
        this.availableComponents.push({ lable: 'Communication Inbox', value: 'COMI' });
        this.availableComponents.push({ lable: 'Roam-Dashboard', value: 'RDAS' });
        // this.availableComponents.push({ lable: 'Roam-Help', value: 'RHLP' });
        // this.availableComponents.push({ lable: 'Roam-Navigation', value: 'RNAV' });
        // this.availableComponents.push({ lable: 'Roam-Page Numbering', value: 'RPNO' });
        // this.availableComponents.push({ lable: 'Roam-Api', value: 'RAPI' });
        //this.availableComponents.push({ lable: 'Roam-Glossary', value: 'RGLO' });
        return this.availableComponents;
    }

    getRunAutoGapAnalyserValue(): string {
        let labelValue: string = this._configuration ? this._configuration.filter(x => x.Key == "Run_Auto_Gap_Analyser").map(({ Value }) => Value).toString() : '';
        if (labelValue && labelValue != "") {
            return labelValue.toLocaleLowerCase();
        }
    }
    getGapAnalyserLastRunValue(): string {
        let labelValue: string = this._configuration ? this._configuration.filter(x => x.Key == "Batch_Interval_Gap_Analyser_LastRun").map(({ Value }) => Value).toString() : '';
        if (labelValue && labelValue != "") {
            return labelValue.toLocaleLowerCase();
        }
    }

    boolToStatus(params) {
        return (params.value === true || params.value === 'true') ? 'Active' : 'Inactive';
    }

    SessionExpiredMessage() {
        this.clearToast();
        this.showToast({ severity: "error", summary: "Session has expired.", detail: "Your session has expired. Please relogin." });
  }
  // #region COLOR_GENERATOR
  colorListGenerator(requiredColors: number = 1, existingColorsList: string[] = []):string[] {
    let r: number, g: number, b: number, color: string;
    let brightness: number = 0;
    let requiredColorsList: string[] = JSON.parse(JSON.stringify(existingColorsList));
    do {
      r = Math.floor(Math.random() * 256); // Red component
      g = Math.floor(Math.random() * 256); // Green component
      b = Math.floor(Math.random() * 256); // Blue component

      // Calculate brightness (average of R, G, and B)
      brightness = (r + g + b) / 3;

      // Check if the brightness is too high (light) or too low (dark)
    } while (brightness > 200 || brightness < 55); // Regenerate if the color is too light or too dark

    // Convert RGB components to a hexadecimal color code
    // << is left shift binary operator | is the bitwise OR operator
    color = `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;
    
    // Check if the color is unique
    if (requiredColorsList.includes(color) || this.isColorTooSimilar(color, requiredColorsList)) {
      // If the color is not unique or similar, generate a new color
      let remaininfRequiredColors: number = existingColorsList.length + requiredColors - requiredColorsList.length;

      return this.colorListGenerator(remaininfRequiredColors, requiredColorsList);
    }
    requiredColorsList.push(color);
    if (requiredColorsList.length < requiredColors) {
      let remaininfRequiredColors: number = existingColorsList.length + requiredColors - requiredColorsList.length;

      return this.colorListGenerator(remaininfRequiredColors, requiredColorsList);
    }

    return requiredColorsList; 
  }
  isColorTooSimilar(newColor:string, existingColors:string[]):boolean {
    for (let i = 0; i < existingColors.length; i++) {
      if (this.colorDifference(newColor, existingColors[i]) < 50) {
        // Adjust the threshold (50) to control the level of similarity
        return true;
      }
    }
    return false;
  }
  colorDifference(color1, color2) {
    let r1 = parseInt(color1.substr(1, 2), 16);
    let g1 = parseInt(color1.substr(3, 2), 16);
    let b1 = parseInt(color1.substr(5, 2), 16);

    let r2 = parseInt(color2.substr(1, 2), 16);
    let g2 = parseInt(color2.substr(3, 2), 16);
    let b2 = parseInt(color2.substr(5, 2), 16);

    return Math.abs(r1 - r2) + Math.abs(g1 - g2) + Math.abs(b1 - b2);
  }
  //#endregion

  getContainerCategoryList():SelectItem[] {
    return [
      { label: 'MX Records', value: 'MXR' },
      { label: 'Log Books', value: 'LOG' },
      { label: 'General', value: 'GEN' },
      { label: 'Documents', value: 'DOC' },
      { label: 'Transition', value: 'BND' }
    ];
  }
}        