<section id="widget-grid" class="adminaircraftcls" >

    <div class="row">
        <article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable" id="col1">

            <div class="no-margin jarviswidget jarviswidget-sortable" id="wid-id-2" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false" asset="widget">

                <header asset="heading" class="p-sortable-handle">

                    <span class="widget-icon"><i class="fa fa-columns"></i></span>
                    <h2>Asset Permission Group</h2>
                    <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>

                    <div class="jarviswidget-ctrls" asset="menu" *ngIf="canAddEditAGroup" title="Edit Asset Permission Group (Ctrl+E)">
                        <a class="button-icon hand-cursor tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="btnEditClick()" id="btneditAssetGroup" (click)="btnEditClick()">
                            <i class="glyphicon glyphicon-pencil"></i>
                        </a>
                    </div>
                    <div class="jarviswidget-ctrls" asset="menu" *ngIf="canAddEditAGroup" title="Add Asset Permission Group (Ctrl+A)">
                        <a class="button-icon hand-cursor tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="btnAddClick()" id="btnaddAssetGroup" (click)="btnAddClick()">
                            <i class="glyphicon glyphicon-plus"></i>
                        </a>
                    </div>

                </header>


                <div class="widgetdiv">
                    <div class="jarviswidget-editbox">
                    </div>
                    <div id="tagslisting" class="no-bottom-padding widget-body">
                        <section id="widget-grid2" class="">
                            <div class="jqgridboxadminaircraft">
                                <!--style="height:80vh !important;"-->

                                <ag-grid-angular id="agGridAssetGroup" [navigateToNextCell]="navigateToNextCell"  #agGridPGroups [gridOptions]="gridOptions" [columnDefs]="columnDefs"  [rowData]="gridData" sortable="true" filter=true style="width: 100%;" class="ag-theme-balham tagsgrid" (rowClicked)='updateSelectedRow($event)' (rowDoubleClicked)="rowDoubleClicked($event)">
                                    <!--width: 100%;-->
                                </ag-grid-angular>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </article>

    </div>
</section>
<form #addeditAssetGroupform="ngForm" *ngIf="display">
    <p-dialog [(visible)]="display" showEffect="fade" [modal]="true" [style]="{width: '700px'}" [resizable]="false">
        <ng-template pTemplate="header" (keyup.shift.tab)="btnCancelClick(addeditAssetGroupform)">
            {{dialogTitle}}
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row" style="margin-bottom: 10px;">
                    <div class="form-group col-sm-12">
                        <fieldset >
                            <label class="label">
                                Group Label<span  class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="addeditAssetGroupLabel" type="text" name="addeditAssetGroupLabel"
                                       placeholder="Group Label" required [(ngModel)]="model.Name" #addeditName="ngModel">

                            </label>

                        </fieldset>
                    </div>
                    <!-- <div class="form-group col-sm-2">
                        <fieldset>
                            <label class="label">
                                Status
                            </label>
                            <label class="checkbox" style="margin-left: 5px;">
                                <span class="onoffswitch tablIndexFocus">
                                    <input type="checkbox" name="chkStatus" 
                                        class="onoffswitch-checkbox" checked="checked" id="chkStatus"
                                        [(ngModel)]="model.Status" #addeditStatus="ngModel" >
                                    <label class="onoffswitch-label" for="chkStatus">
                                        <span class="onoffswitch-inner" data-swchon-text="YES"
                                            data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span>
                                    </label>
                                </span>
                            </label>
                        </fieldset>
                    </div> -->
                </div>

                <div class="row">
                    <div class="form-group col-sm-12">
                        <div class="panel panel-default">
                            <div class="panel-heading panalheading-padding">
                                <h3 class="panel-title">Assets </h3>
                            </div>
                            <div class="panel-body">
                                <div class="col-sm-12 picklist-padding fix-picklist-width" >  <!-- style="height: 53vh;" -->
                                    <p-pickList id="assetspicklist" responsive="true" sourceHeader="Available Assets" targetHeader="Selected Assets" [source]="model.AvailableAssets" [target]="model.SelectedAssets" filterBy="label" dragdrop="true" sourceFilterPlaceholder="Search by asset" targetFilterPlaceholder="Search by asset" [sourceStyle]="{'height':'270px'}" [targetStyle]="{'height':'270px'}">
                                        <ng-template let-asset pTemplate="item">
                                            <div>
                                                <div>
                                                    <div class="col-md-8" style="font-size:14px" ><span>{{asset.label}}</span></div>
                                                    <div class="col-md-4" style="font-size:14px;padding-left:0px !important;padding-right:0px !important"><span class="group-tags" style="background-color:lightgreen">{{asset.UserAssetType}}</span></div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-pickList>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex" (click)="onSubmit(addeditAssetGroupform)" [label]="lblsubmitButton" [disabled]="!addeditAssetGroupform.form.valid || isSubmitted"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" (click)="btnCancelClick(addeditAssetGroupform)" label="Cancel" [disabled]="isSubmitted"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>
