<div class="col-lg-2 col-md-3 col-sm-3 pull-right clear-padding tablIndexFocus"  id="toprightassetmenu" (blur)="onBlur()" #txtbox style="height: 58px;" *ngIf="isBrowseScreen"    (click)="assetClick()" [attr.tabindex]="0"> <!--  -->

    <ul class="col-lg-12 col-md-12 col-sm-12 col-xs-12  header-dropdown-list hidden-xs padding-5 custom-top-margin">
        <li class="mainmenunew" [ngClass]="{'open': isClassVisible }" >
            <a   style="color: white;" title="Main" class="selectAsset " [ngClass]="selectedFontClassName"><!--font-size: 21px;-->
                <span [ngClass]="selectedClassName"></span>
                <span> {{selectedAssetName}}</span>
                <span class="fa fa-angle-down pull-right custom-top-padding"></span>
            </a>

            <ul class="col-lg-12 col-md-12 col-sm-12 col-xs-12 dropdown-menu pull-right"  style="padding: 0px;height:calc(100vh - 60px);top: 48px;;font-size:15px;"> <!--width: calc(100vh - 60vh);right: -15px;-->
                <li>
                    <a><span>Select Asset</span></a>
                </li>
                <li>
                    <div class="input-group input-group-md">
                        <span class="input-group-addon"><i class="glyphicon glyphicon-filter"></i></span>
                        <div class="icon-addon addon-md">
                           
                            <input type="text" placeholder="Search" class="form-control" id="fontstyle" [(ngModel)]="searchAsset" (ngModelChange)="modelChanged($event)" (mousedown)="txtSearchMouseDown()" (blur)="onBlur('child')"/>
                            <label for="search" class="glyphicon glyphicon-search" rel="tooltip" title="" data-original-title="search"></label>
                        </div>
                    </div>
                </li>
                <li>
                    <p-tree (mousedown)="txtSearchMouseDown()" (focusout)="onBlur('child')" [filter]="false" [loading]="loading" [value]="assets" selectionMode="checkbox" class="set-tree-Height" [(selection)]="selectedAsset" (onNodeSelect)="assetSelect($event)" (onNodeUnselect)="assetUnselect($event)"
                            propagateSelectionUp=false propagateSelectionDown=false [style]="{'width':'100%','overflow':'auto','background-color':'#eee', 'height': 'calc(100vh - 150px)'}"></p-tree>
                </li><!--(onNodeUnselect)="assetUnselect($event)"-->
                <li>
                    <a><span>Select one item.</span></a>
                </li>
            </ul>
        </li>
    </ul>
    </div>
