//Components
import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { GridOptions,GridApi } from "ag-grid-community";

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { SelectItem } from 'primeng/api';
import { OperationMode, RoleGroupModel, UserRole } from './role-group';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { RoleGroupService } from '../../services/role-group/role-group.service';
import { UserService } from '../../../records/services/User/user.service';
var _router_: Router;
@Component({
    selector: 'role-group',
    templateUrl: './role-group.component.html',
    changeDetection:ChangeDetectionStrategy.Default,
    providers: [RoleGroupService, UserService]

})

export class RoleGroupComponent implements OnInit {
    display: boolean = false;
    public gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[];
    // availableRoles: any[] = [];
    // selectedRoles: any[] = [];

    public canAddEditPGroup: boolean = false;

    selectedPGroupID: string = null; 
    
    public totalCount: number;
    public model: RoleGroupModel;
    public isSubmitted: boolean = false;
    PGroupList: SelectItem[] = [];
    public dialogTitle: string = 'Add Permission Group';
    public lblsubmitButton: string = 'Add';
    public operationMode: OperationMode = 1;
    //#region Initialization of Component
    constructor(private _sharedService: SharedService,private cRef:ChangeDetectorRef, private _router: Router, private _RoleGroupService: RoleGroupService, private _hotkeysService: HotkeysService,private _userService: UserService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.UpdateRoles();
            _router_ = this._router;
            this.setHotKeys();
            this.initModel();
            this.navigateToNextCell=this.navigateToNextCell.bind(this);
        }
    }
    setHotKeys() {
        //Add Permission Group
        this._hotkeysService.add(new Hotkey('ctrl+a', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/rolegroup")
            {
                this.btnAddClick();
            }
            return false; // Prevent bubbling
        }));
        //Edit Permission Group
        this._hotkeysService.add(new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/rolegroup")
            {
                this.btnEditClick();
            }
            return false; // Prevent bubbling
        }));
        this._hotkeysService.add(new Hotkey('enter', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/rolegroup")
            {
                this.btnEditClick();
            }
            return false; // Prevent bubbling
        }));
    }
    ngOnInit() {
        if (this.canAddEditPGroup) {
            this.gridOptions = <GridOptions>
                {
                    rowSelection: 'single',

                    onGridReady: () => {
                        this.setUserGridColumns();
                        try {
                            this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridPGroups'));
                            this.gridOptions.api.hideOverlay();
                        } catch (e) {
                            console.log(e);
                        }
                    },
                    //suppressContextMenu: true,
                    pagination: true,
                    paginationPageSize: 20,
                    getContextMenuItems: this.getCustomContextMenuItems,
                    context: {
                        gridContext: this
                    },
                    defaultColDef: {
                        sortable: true,
                        filter:true
                    }

                };
           // this.getCategoryList();
            this.populateRoleGroupGrid();
        }
    }

    //#endregion
   
    //#region Ag Grid Population Event
    populateRoleGroupGrid(): void {

        this._RoleGroupService.getRoleGroupList()
        .subscribe({
            next: (response) => {
                this.gridOptions.api.sizeColumnsToFit();
                this.gridData = [];
                this.gridData = response;
                this.totalCount = response.length;
            },
            error: (error) => {
                this.gridData = [];
                this.totalCount = 0;
            }
        });
    }
    //#endregion
    //#region Set Columns of Browse Ag Grid
    setUserGridColumns(): void {
        this.columnDefs = [];
        this.columnDefs =
            [
            { headerName: 'Group Label', field: 'RoleLabel', menuTabs: ['filterMenuTab'] },
            { headerName: 'Team Name', field: 'Name', menuTabs: ['filterMenuTab'] },              
                // {
                //     headerName: 'Status', field: 'Status', filter:false, suppressMenu: true, valueFormatter: function (params: any) {
                //         if (params.value == true) {
                //             return "Active";
                //         }
                //         else {
                //             return "Inactive";
                //         }
                //     }
                // }
            ];

    }
    

    //#endregion

    //#region Ag-Grid Events
    updateSelectedRow(event: any) {
        this.selectedPGroupID = null;
        this.selectedPGroupID = event.data.Id;
    }
    //#endregion

    //#region Edit User Events
    rowDoubleClicked(event: any) {
        if (this.canAddEditPGroup)
        {
            this.btnEditClick();
        }
    }
    btnEditClick() {
        let selectedRows=this.gridOptions.api.getSelectedRows();
        if (this.canAddEditPGroup && (selectedRows && selectedRows.length>0)) {
            this.initModel();
            this.operationMode = 2;
            this.dialogTitle = 'Edit Permission Group';
            this.lblsubmitButton = 'Update';

            this._RoleGroupService.getRoleGroupByID(selectedRows[0].Id)
            .subscribe({
                next: (response) => {
                    let data = response;
                    this.model = response;
                    this.showHideDialogue(true);               
                },
                error: (error) => {
                    this._sharedService.clearToast();
                    this._sharedService.showToast(
                        { 
                            severity: 'error', 
                            summary: 'Error Occured', 
                            detail: 'Permission Group not added, please try again.' 
                        }
                    );        
                    this.showHideDialogue(false);
                }
            });
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast(
                { 
                    severity: 'warn', 
                    summary: 'No Permission Group Selected', 
                    detail: 'Please select a record.' 
                }
            );
        }
    }
    //#endregion
    //#region Add User Events
    btnAddClick() {
       this.initModel();

        this.operationMode = 1;
        this.dialogTitle = 'Add Permission Group';
        this.lblsubmitButton = 'Add';

        this._RoleGroupService.getRoleGroupByID(null)
        .subscribe({
            next: (response) => {
                let data = response;
                this.model = response;
                this.showHideDialogue(true);               
            },
            error: (error) => {
                this._sharedService.clearToast();
                this._sharedService.showToast(
                    { 
                        severity: 'error', 
                        summary: 'Error Occured', 
                        detail: 'Permission Group not added, please try again.' 
                    }
                );
        
                this.showHideDialogue(false);
            }
        });
    }

initModel(){
            this.model = new RoleGroupModel();
            this.model.UserGroup = new UserRole();
            this.model.UserGroup.Name = '';
            this.model.UserGroup.RoleLabel = '';
}

    //#endregion

    //#region User Roles Assignment
    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }

        this.canAddEditPGroup = this._sharedService.UserRole.Admin_Role_Group;
    }
    //#endregion

    //#region Cancel Dialogue
    btnCancelClick(cancelForm: NgForm) {
        this.showHideDialogue(false);
        this.initModel();
        cancelForm.resetForm();
    }
    //#endregion
    //#region Helper Methods
    showHideDialogue(flag: boolean) {
        this.display = flag;
    }
    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }
    //#endregion

    //#region form submit
    onSubmit(form: NgForm) {
        this.changeButtonsStatus(true);
        this._sharedService.showLoader("Processing...");
        if (this.operationMode == 1) {
            this._RoleGroupService.addUpdateRoleGroup(this.model)
            .subscribe({
                next: (response) => {
                    this.showHideDialogue(false);
                    form.resetForm();
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast(
                        { 
                            severity: 'success', 
                            summary: 'Success', 
                            detail: 'Permission Group added succesfully.' 
                        }
                    );
                    this.changeButtonsStatus(false);
                    this.populateRoleGroupGrid();
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();            
                    if (error.status === 409) {
                        this._sharedService.showToast(
                            { 
                                severity: 'error', 
                                summary: 'Already Exists', 
                                detail: 'Permission Group already exists.' 
                            }
                        );
                    }
                    else {
                        this._sharedService.showToast(
                            { 
                                severity: 'error', 
                                summary: 'Error Occured', 
                                detail: 'Permission Group not added, please try again.' 
                            }
                        );
                    }
                    this.showHideDialogue(false);
                    this.initModel();
                    form.resetForm();
                    this.changeButtonsStatus(false);
                }
            });
        }
        else if (this.operationMode == 2) {
            this._RoleGroupService.addUpdateRoleGroup(this.model)
            .subscribe({
                next: (response) => {
                    this.showHideDialogue(false);
                    form.resetForm();
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast(
                        { 
                            severity: 'success', 
                            summary: 'Success', 
                            detail: 'Permission Group updated succesfully.' 
                        }
                    );
                    this.changeButtonsStatus(false);
                    this.populateRoleGroupGrid();
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    if (error.status === 409) {
                        this._sharedService.showToast(
                            { 
                                severity: 'error', 
                                summary: 'Already Exists', 
                                detail: 'Permission Group already exists.' 
                            }
                        );
                    }
                    else {
                        this._sharedService.showToast(
                            { 
                                severity: 'error', 
                                summary: 'Error Occured', 
                                detail: 'Permission Group not updated, please try again.' 
                            }
                        );
                    }
                    this.showHideDialogue(false);
                    this.initModel();
                    form.resetForm();
                    this.changeButtonsStatus(false);
                }
            });
        }
    }
    //#endregion

    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];

        if (params.context.gridContext.canAddEditPGroup) {
            result.push({
                name: "Add Permission Group",
                action: function () {

                    params.context.gridContext.btnAddClick()
                },
                icon: params.context.gridContext._sharedService.addIconHTMLasString,
            });

            result.push({
                name: "Edit Permission Group",
                action: function () {
                    params.context.gridContext.selectedPGroupID = null;
                    if (params.node) {
                        params.context.gridContext.selectedPGroupID = params.node.data.GroupID;
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext.btnEditClick()
                    }
                   
                },
                icon: params.context.gridContext._sharedService.editIconHTMLasString,
            });

            let childResult: any[] = [];
            childResult.push({
                name: 'Excel',
                action: function () {
                    
                    params.api.exportDataAsExcel();
                    
                },
                icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
            });

            childResult.push({
                name: 'CSV',
                action: function () {
                    params.api.exportDataAsCsv();
                },
                icon: params.context.gridContext._sharedService.saveLogFeedIconHTMLasString,
            });

            result.push({
                name: 'Download',
                action: function () {


                },
                icon: params.context.gridContext._sharedService.downloadsFeedIconHTMLasString,
                subMenu: childResult,
            });
			
        }
        return result;
    }
    navigateToNextCell(params: any) {
        var previousCell = params.previousCellPosition;
        var suggestedNextCell = params.nextCellPosition;
        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        switch (params.key) {
            case KEY_DOWN:
                var nextRowIndex = previousCell.rowIndex + 1;
                var renderedRowCount = this.gridOptions.api.getModel().getRowCount();
                if (nextRowIndex >= renderedRowCount) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedPGroupID = null;
                    this.selectedPGroupID = rowNode.data.Id;
                        return {
                        rowIndex: nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_UP:
                var _nextRowIndex = previousCell.rowIndex - 1;
                if (_nextRowIndex < 0) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedPGroupID = null;
                    this.selectedPGroupID = rowNode.data.Id;
                        return {
                        rowIndex: _nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw 'this will never happen, navigation is always on of the 4 keys above';
        }
    }
    //#endregion
}