
<p-confirmDialog [style]="{width: '435px'}"></p-confirmDialog>

<div class="row addaircraftcls">
    
    <article class="col-sm-12 col-md-12 col-lg-12" id="col2">
        <!-- Widget ID (each widget will need unique ID)-->
        <div class="jarviswidget" style="margin-bottom: 10px;" id="wid-id-0" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false">


            <header> 
                <!--<span class="widget-icon"><i class="fa fa-pencil-square-o"></i></span>-->
                <span class="widget-icon"><i class="fa fa-list"></i></span>
                <h2>Sub Assets</h2>

                <div>
                
                    <div class="jarviswidget-ctrls" >
                        <a class="button-icon tablIndexFocus" [attr.tabindex]="0"  id="btnadd" (keyup.enter)="showAddDialog()" (click)="showAddDialog()" title="Add Asset">
                            <i class="glyphicon glyphicon-plus"></i>
                        </a>
                    </div>
                    <div class="jarviswidget-ctrls" *ngIf="!isLinkAsset">
                        <a class="button-icon tablIndexFocus" [attr.tabindex]="0" id="btnMetaEdit" (keyup.enter)="showEditDialog(null)"  (click)="showEditDialog(null)" title="Edit Asset (Ctrl+E)">
                            <i class="glyphicon glyphicon-pencil"></i>
                        </a>
                    </div>
                    <div *ngIf="isSysAdmin && !isLinkAsset" class="jarviswidget-ctrls">
                        <a class="button-icon tablIndexFocus" [attr.tabindex]="0" id="btnMoveAsset" (keyup.enter)="showMoveAssetDialog()" (click)="showMoveAssetDialog()" title="Move Asset">
                            <i class="glyphicon glyphicon-transfer"></i>
                        </a>
                    </div>
                    <div class="jarviswidget-ctrls">
                        <a class="button-icon tablIndexFocus" [attr.tabindex]="0"  id="btnLinkAsset" (keyup.enter)="showLinkAssetDialog()" (click)="showLinkAssetDialog()" title="Link Asset">
                            <i class="fa fa-link"></i>
                        </a>
                    </div>
                    <div class="jarviswidget-ctrls" *ngIf="isLinkAsset">
                        <a class="button-icon tablIndexFocus" [attr.tabindex]="0"  id="btnDelinkAsset" (keyup.enter)="delinkAssetClick()" (click)="delinkAssetClick()" title="Delink Asset">
                            <i class="fa fa-unlink"></i>
                        </a>
                    </div>
                </div>
                <!--<asset-modal [recordID]="recordID" *ngIf="isEditVisible"></asset-modal>-->

            </header>
            <!-- widget div-->
            <div class="widgetdiv">
                <!-- widget content -->
                <div class="widget-body" style="padding-bottom: 0px;">
                    <!-- widget grid -->
                    <section id="widget-grid0" class="">
                        <div class="navbar-form form-group">
                            <!--<ul id="myTab1" class="nav nav-tabs bordered">
                                <li class="active">
                                    <a href="#s1" data-toggle="tab" aria-expanded="true">Airframe</a>
                                </li>
                            </ul>-->
                            <div id="myTabContent1" class="tab-content">
                                <div class="tab-pane fade active in" id="s2">
                                    <div class="smart-form">
                                        <div style="width:100%;margin:0 auto;">
                                            <ag-grid-angular id="agGridAssets" [navigateToNextCell]="navigateToNextCell" #agGridAssets [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="gridData" (rowDoubleClicked)='showEditDialog($event)'  (rowClicked)='subAssetGridRowClick($event)' sortable="true" style="width: 100%; height: 200px !important;" class="ag-theme-balham">
                                            </ag-grid-angular>
                                            <!--pagination=true paginationPageSize="20"-->
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                        <!-- end row -->

                    </section>



                    <!-- end widget grid -->
                    <!--<div class="widget-footer">-->
                    <!--<button type="submit" class="btn btn-success" onclick="return CreateAssets()" style="float: left;">
                        Create Containers
                    </button>-->
                    <!--</div>-->
                </div>
                <!-- end widget content -->
            </div>
            <!-- end widget div -->

        </div>
        <!-- end widget -->
    </article>


    

    <!-- END COL -->
</div>


<p-dialog id="moveAssetWindow" *ngIf="displayMoveAssetWindow" [visible]="displayMoveAssetWindow" [closable]="false" showEffect="fade" [modal]="true" [style]="{width: '70%'}" [resizable]="false" #moveAssetWindow>
    <ng-template pTemplate="header">
            Move Asset
    </ng-template>
    <div class="upload-dialog">
        <div class="smart-form">
            <div class="row" style="margin-top: 5px;">
                <div class="col-sm-6 col-md-6 col-lg-6">
                    <div class="panel-heading panalheading-padding" (keyup.shift.tab)="btnCancelAssetMoveClick()"  [attr.tabindex]="0">
                        <h3 class="panel-title">Asset Information</h3>
                    </div>
                    <div class="panel-body margin-configuration-panal" style="min-height: 90px !important;" >
                        <div class="col-sm-10">
                            <div class="row">
                                <div class="form-group col-sm-6">
                                    <label class="label">
                                            ASN
                                    </label>
                                </div>
                                <div class="form-group col-sm-6">
                                    <div class="detailtext">{{selectedAssetToMove.ASN}}</div>
                                </div>

                                <div class="form-group col-sm-6">
                                    <label class="label">
                                            Asset Type
                                    </label>
                                </div>
                                <div class="form-group col-sm-6">
                                    <div class="detailtext">{{selectedAssetToMove.AssetType}}</div>
                                </div>

                                <div class="form-group col-sm-6">
                                    <label class="label">
                                            Description
                                    </label>
                                </div>
                                <div class="form-group col-sm-6">
                                    <div class="detailtext">{{selectedAssetToMove.Description}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="assetDependents">
                        <div class="panel-heading panalheading-padding">
                                <h3 class="panel-title">Asset Dependency Information</h3>
                        </div>
                        <div class="panel-body margin-configuration-panal" style="min-height: 90px !important;" >
                            <div class="col-sm-10">
                                <div class="row">
                                    <div class="form-group col-sm-10">
                                        <label class="label">
                                            # of Blobs
                                        </label>
                                    </div>
                                    <div class="form-group col-sm-2">
                                        <div class="detailtext">{{assetDependents.BlobsCount}}</div>
                                    </div>

                                    <div class="form-group col-sm-10">
                                        <label class="label">
                                            # of Communication Items
                                        </label>
                                    </div>
                                    <div class="form-group col-sm-2">
                                        <div class="detailtext">{{assetDependents.CommsCount}}</div>
                                    </div>

                                    <div class="form-group col-sm-10">
                                        <label class="label">
                                            # of Jobs
                                        </label>
                                    </div>
                                    <div class="form-group col-sm-2">
                                        <div class="detailtext">{{assetDependents.DpJobsCount}}</div>
                                    </div>

                                    <div class="form-group col-sm-10">
                                        <label class="label">
                                            # of Tasks
                                        </label>
                                    </div>
                                    <div class="form-group col-sm-2">
                                        <div class="detailtext">{{assetDependents.TasksCount}}</div>
                                    </div>

                                    <div class="form-group col-sm-10">
                                        <label class="label">
                                            # of Task Documents
                                        </label>
                                    </div>
                                    <div class="form-group col-sm-2">
                                        <div class="detailtext">{{assetDependents.TaskDocumentCount}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                    <div class="panel-heading panalheading-padding">
                        <h3 class="panel-title">Select Destination Airframe/Helicopter/Collection</h3>
                    </div>

                    <div class="panel-body margin-configuration-panal" style="min-height: 90px !important;">
                        <div class="col-sm-12">
                            <div class="row">
                                <p-dropdown  [options]="availableAircrafts" required placeholder="Select Asset" [(ngModel)]="selectedAircraftDestination" (onChange)="onAircraftSelect($event)" [filter]="true" [style]="{'margin-left':'6px !important', 'width':'300px'}" appendTo="body">
                                    <ng-template style="padding-bottom:20px;padding-left: 0px !important;" let-item pTemplate="selectedItem">
                                        <span>{{item.label}}</span>
                                        <!-- <span style="font-size:14px;background-color:lightgreen;">{{item.AssetType}}</span> -->
                                    </ng-template>
                                    <ng-template  style="padding-bottom:20px !important;padding-left: 0px !important;" let-asset pTemplate="item">
                                        <div style="padding-bottom: 20px;">
                                            <!-- <span > -->
                                                <span style="padding:0" class="col-md-9">{{asset.label}}</span>

                                            <!-- </div> -->
                                            <!-- <div class="col-md-4"> -->
                                                <span style="padding:0" class="col-md-3">
                                                    <span  style="font-size: 11px;padding: 4px; background-color:lightgreen;color: white;border-radius: 4px;">
                                                        {{asset.AssetType}}
                                                </span>
                                                </span>

                                        <!-- </div> -->
                                        </div>
                                    </ng-template>
                                </p-dropdown>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">
            <button *ngIf="assetDependents" type="button" pButton icon="fa fa-exchange" class="btn btn-default minzindex" label="Move Asset" (click)="btnAssetMoveClick()"></button>
            <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" label="Close" (click)="btnCancelAssetMoveClick()"></button>
        </div>
    </ng-template>
</p-dialog>

<!-- end row -->
<!--start asset modal-->
<form (ngSubmit)="btnSubmitClick(assetEditForm)" #assetEditForm="ngForm">
    <p-dialog id="assetsEditdlg" [(visible)]="display" showEffect="fade" [modal]="true" [style]="{width: '745px'}" [resizable]="false" #assetsEditdlg>
        <ng-template pTemplate="header" (keyup.shift.tab)="btnCancelClick(assetEditForm)">
            {{dialogTitle}}
        </ng-template> 
        
        <div class="upload-dialog">
            <!--<div class="modal-body">-->
            <div id="assetsEditdlgform" class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-6 fix-dropdown-Padding">
                        <fieldset>
                            <label class="label">
                                <!--<span class="text-danger">*</span>-->
                                Asset Type<span [hidden]="model.AssetType" class="text-danger">
                                    *
                                </span>
                            </label>                            
                            <p-dropdown #dfocus id="AssetType" name="mfMAssetType" [options]="assetTypes" required placeholder="Select Type" [(ngModel)]="model.AssetType" (onChange)="onAssetChange($event)" appendTo="body">

                            </p-dropdown>


                        </fieldset>
                    </div>

                    <div class="form-group col-sm-6 fix-dropdown-Padding">
                        <fieldset>
                            <label class="label">
                                <!--<span class="text-danger">*</span>-->
                                Link to Airframe/Helicopter/Collection<span [hidden]="model.LinkAsset" class="text-danger">
                                    *
                                </span>
                            </label>                            
                            <p-dropdown #dfocus id="linkAsset" name="mfMLinkAsset" [options]="linkToAssetDrp" required placeholder="Select Asset" [(ngModel)]="model.LinkAsset" [filter]="true" appendTo="body">
                                <ng-template style="padding-bottom:20px;padding-left: 0px !important;" let-item pTemplate="selectedItem">
                                    <span>{{item.label}}</span>
                                    <!-- <span style="font-size:14px;background-color:lightgreen;">{{item.AssetType}}</span> -->
                                </ng-template>
                                <ng-template  style="padding-bottom:20px !important;padding-left: 0px !important;" let-asset pTemplate="item">
                                    <div style="padding-bottom: 20px;">
                                        <!-- <span > -->
                                            <span style="padding:0" class="col-md-9">{{asset.label}}</span>

                                        <!-- </div> -->
                                        <!-- <div class="col-md-4"> -->
                                            <span style="padding:0" class="col-md-3">
                                                <span *ngIf="asset.AssetType"  style="font-size: 11px;padding: 4px; background-color:lightgreen;color: white;border-radius: 4px;">
                                                    {{asset.AssetType}}
                                            </span>
                                            </span>

                                    <!-- </div> -->
                                    </div>
                                </ng-template>

                            </p-dropdown>


                        </fieldset>
                    </div>

                </div>
                    <div class="row">
                    <div class="form-group col-sm-2">
                        <fieldset>
                            <label class="label">MX Records</label>
                            <label for="mxrecordasset" class="checkbox">
                                <p-checkbox id="mxrecordasset" name="mxrecordasset" [(ngModel)]="model.MXRecords" binary="true"></p-checkbox>
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-2">
                        <fieldset>
                            <label class="label">Log Books</label>
                            <label for="logbooksasset" class="checkbox">
                                <p-checkbox id="logbooksasset" name="logbooksasset" [(ngModel)]="model.LogBooks" binary="true"></p-checkbox>
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-2">
                        <fieldset>
                            <label class="label">General</label>
                            <label for="generalasset" class="checkbox">
                                <p-checkbox id="generalasset" name="generalasset" [(ngModel)]="model.General" binary="true"></p-checkbox>
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-2">
                        <fieldset>
                            <label class="label">Documents</label>
                            <label for="documentsasset" class="checkbox">
                                <p-checkbox id="documentsasset" name="documentsasset" [(ngModel)]="model.Documents" binary="true"></p-checkbox>
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-2">
                        <fieldset>
                            <label class="label">Binders</label>
                            <label for="bindersasset" class="checkbox">
                                <p-checkbox id="bindersasset" name="bindersasset" [(ngModel)]="model.Binders" binary="true"></p-checkbox>
                            </label>
                        </fieldset>
                    </div>
                    <div *ngIf="(runAutoGapAnalyser && runAutoGapAnalyser == 'true')" class="form-group col-sm-2">
                        <fieldset>
                            <label class="label">Auto Gap Analyser</label>
                            <label for="autogapanalyser" class="checkbox">
                                <p-checkbox id="autogapanalyser" name="autogapanalyser"
                                    [(ngModel)]="model.AutoGapAnalyser" binary="true"
                                    (keyup.space)="ToggleAutoGapAnalyser()"></p-checkbox>
                            </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Title<span [hidden]="model.Description" class="text-danger">
                                    *
                                </span>
                            </label>
                            <label class="input">
                                <input id="mfMTitle" type="text" name="mfMTitle" [(ngModel)]="model.Description" required class="input-sm" />
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                {{ASNTitle}}<span [hidden]="model.ASN" class="text-danger">
                                    *
                                </span>
                            </label>
                            <label class="input rgxErr">
                                <i *ngIf="mfAssetMSN.value && !mfAssetMSN.valid &&(mfAssetMSN.touched || mfAssetMSN.dirty )"
                                    class="icon-append fa fa-ban" 
                                    title="{{sharedService.error_rgx_pre}} {{sharedService.re_AssetSerialNumber_Message}} {{sharedService.error_rgx_post}}" >
                                </i>
                                <input id="mfMAssetMSN" type="text" name="mfMAssetMSN"  [(ngModel)]="model.ASN" required
                                #mfAssetMSN="ngModel"  [pattern]="sharedService.re_AssetSerialNumber"
                                class="input-sm" [disabled]="!isAdd" />
                            </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">Asset Order</label>
                            <label class="input">
                                <input id="mfMAssetOrder" type="text" name="mfMAssetOrder" [(ngModel)]="model.Order" class="input-sm" />
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">{{ManufacturerTitle}}</label>
                            <label class="input">
                                <input id="mfMManufacturer" type="text" name="mfMManufacturer" [(ngModel)]="model.Manufacturer" class="input-sm" /> <!--required-->
                            </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row" *ngIf="model.AssetType && (model.AssetType=='Airframe' || model.AssetType=='Helicopter')">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">Type</label>
                            <label class="input">
                                <input id="mfMType" type="text" name="mfMType" [(ngModel)]="model.Type" class="input-sm" />
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">Variant</label>
                            <label class="input">
                                <input id="mfMVarient" type="text" name="mfMVarient" [(ngModel)]="model.Varient" class="input-sm" /> <!--required-->
                            </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row" *ngIf="model.AssetType && (model.AssetType=='Airframe' || model.AssetType=='Helicopter')">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">Registration Authority</label>
                            <label class="input">
                                <input id="mfMRegistrationAuthority" type="text" name="mfMRegistrationAuthority" [(ngModel)]="model.RegAuthority" class="input-sm" />
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">Registration</label>
                            <label class="input">
                                <input id="mfMRegistrationNumber" type="text" name="mfMRegistrationNumber" [(ngModel)]="model.RegNumber" class="input-sm" /> <!--required-->
                            </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-6" *ngIf="model.AssetType && (model.AssetType!='Item')">
                        <fieldset>
                            <label class="label">Owner</label>
                            <label class="input">
                                <input id="mfMOwner" type="text" name="mfMOwner" [(ngModel)]="model.Owner" class="input-sm" />
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6" *ngIf="model.AssetType && (model.AssetType!='Item' && model.AssetType!='Airframe' && model.AssetType!='Helicopter')">
                        <fieldset>
                            <label class="label">Part Number</label>
                            <label class="input">
                                <input id="mfPartNo" type="text" name="mfPartNo" [(ngModel)]="model.PartNumber" class="input-sm" />
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6" *ngIf="(model.AssetType && (model.AssetType=='Airframe' || model.AssetType=='Helicopter'))">
                        <fieldset>
                            <label class="label">Time</label>
                            <label class="input">
                                <input id="mfTime" type="text" name="mfTime" [(ngModel)]="model.Time" class="input-sm" />
                            </label>
                        </fieldset>

                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-6" *ngIf="model.AssetType && (model.AssetType!='Item' && model.AssetType!='Airframe' && model.AssetType!='Helicopter')">
                        <fieldset>
                            <label class="label">Position</label>
                            <label class="input">
                                <input id="mfPosition" type="text" name="mfPosition" [(ngModel)]="model.Position" class="input-sm" />
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6" *ngIf="!model.AssetType || (model.AssetType && (model.AssetType!='Item') && model.AssetType!='Airframe'  && model.AssetType!='Helicopter')">
                        <fieldset>
                            <label class="label">Time</label>
                            <label class="input">
                                <input id="mfTime" type="text" name="mfTime" [(ngModel)]="model.Time" class="input-sm" />
                            </label>
                        </fieldset>

                    </div>
                    <!-- <div class="form-group col-sm-6" *ngIf="model.AssetType && (model.AssetType=='Item')">
                        <fieldset>
                            <label class="label">Status</label>
                            <label for="isactiveasset" class="checkbox" *ngIf="isAdd">
                                <span class="onoffswitch tablIndexFocus"  [attr.tabindex]="0"  (keyup.enter)="ToggleChecked()" >
                                    <input [checked]="isChecked" type="checkbox" name="onoffswitchasset" [(ngModel)]="model.Status" class="onoffswitch-checkbox" id="isactiveasset">
                                    <label class="onoffswitch-label" for="isactiveasset"><span class="onoffswitch-inner" data-swchon-text="YES" data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span></label>
                                </span>
                            </label>
                            <label for="isactiveasset" class="checkbox" *ngIf="!isAdd">
                                <span class="onoffswitch tablIndexFocus"  [attr.tabindex]="0"  (keyup.enter)="ToggleChecked()">
                                    <input [checked]="isChecked" type="checkbox" name="onoffswitchasset" [(ngModel)]="model.Status" class="onoffswitch-checkbox" id="isactiveasset">
                                    <label class="onoffswitch-label" for="isactiveasset"><span class="onoffswitch-inner" data-swchon-text="YES" data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span></label>
                                </span>
                            </label>
                        </fieldset>
                    </div> -->
                </div>
                <div class="row">
                    <div class="form-group col-sm-6" *ngIf="!model.AssetType || (model.AssetType && (model.AssetType!='Item'))">
                        <fieldset>
                            <label class="label">Cycle</label>
                            <label class="input">
                                <input id="mfCycle" type="text" name="mfCycle" [(ngModel)]="model.Cycle" class="input-sm" />
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-2" *ngIf="!model.AssetType || (model.AssetType && (model.AssetType!='Item'))">
                        <fieldset>
                            <label class="label">Status</label>                         
                            <label for="isactiveasset" class="checkbox" *ngIf="isAdd">
                                <span class="onoffswitch  tablIndexFocus onoffswitch-Width"  [attr.tabindex]="0"  (keyup.enter)="ToggleChecked()">
                                    <input [checked]="isChecked" type="checkbox" name="onoffswitchasset" [(ngModel)]="model.Status" class="onoffswitch-checkbox" id="isactiveasset">
                                    <label class="onoffswitch-label" for="isactiveasset"><span class="onoffswitch-inner" data-swchon-text="Active" data-swchoff-text="Inactive"></span><span class="onoffswitch-switch onoffswitch-switch-Right"></span></label>
                                </span>
                            </label>
                            <label for="isactiveasset" class="checkbox" *ngIf="!isAdd">
                                <span class="onoffswitch tablIndexFocus onoffswitch-Width"  [attr.tabindex]="0"  (keyup.enter)="ToggleChecked()">
                                    <input [checked]="isChecked" type="checkbox" name="onoffswitchasset" [(ngModel)]="model.Status" class="onoffswitch-checkbox" id="isactiveasset">
                                    <label class="onoffswitch-label" for="isactiveasset"><span class="onoffswitch-inner" data-swchon-text="Active" data-swchoff-text="Inactive"></span><span class="onoffswitch-switch onoffswitch-switch-Right"></span></label>
                                </span>
                            </label>
                        </fieldset>
                    </div>
                    <!-- <div class="form-group col-sm-2" *ngIf="!model.AssetType || (model.AssetType && (model.AssetType!='Item'))">
                        <fieldset>
                            <label class="label">Read Only</label>                         
                            <label for="isReadOnlyAircraft" class="checkbox" *ngIf="isAdd">
                                <span class="onoffswitch  tablIndexFocus"  [attr.tabindex]="0"  (keyup.enter)="ToggleCheckedReadOnly()">
                                    <input [disabled]="!isSysAdmin" [checked]="isCheckedReadonly" type="checkbox" name="onoffswitchasset" [(ngModel)]="model.ReadOnly" class="onoffswitch-checkbox" id="isReadOnlyAircraft">
                                    <label class="onoffswitch-label" for="isReadOnlyAircraft"><span class="onoffswitch-inner" data-swchon-text="YES" data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span></label>
                                </span>
                            </label>
                            <label for="isReadOnlyAircraft" class="checkbox" *ngIf="!isAdd">
                                <span  class="onoffswitch tablIndexFocus"  [attr.tabindex]="0"  (keyup.enter)="ToggleCheckedReadOnly()">
                                    <input [disabled]="!isSysAdmin" [checked]="isCheckedReadonly" type="checkbox" name="onoffswitchasset" [(ngModel)]="model.ReadOnly" class="onoffswitch-checkbox" id="isReadOnlyAircraft">
                                    <label class="onoffswitch-label" for="isReadOnlyAircraft"><span class="onoffswitch-inner" data-swchon-text="YES" data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span></label>
                                </span>
                            </label>
                        </fieldset>
                    </div> -->
                    <div class="form-group col-sm-2" >
                        <fieldset>
                            <label class="label">Read Only</label>
                            <label for="isReadOnlyAsset" class="checkbox"  *ngIf="isAdd">
                                <span class="onoffswitch tablIndexFocus"  [attr.tabindex]="0"  (keyup.enter)="ToggleCheckedReadOnly()">
                                    <input [checked]="isCheckedReadOnly" [disabled]="disableReadOnlyButton" type="checkbox" name="onoffswitchair" [(ngModel)]="model.ReadOnly" class="onoffswitch-checkbox" id="isReadOnlyAsset">
                                    <label class="onoffswitch-label" for="isReadOnlyAsset"><span class="onoffswitch-inner" data-swchon-text="YES" data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span></label>
                                </span>
                            </label>
                            <label for="isReadOnlyAsset" class="checkbox" *ngIf="!isAdd">
                                <span class="onoffswitch tablIndexFocus"  [attr.tabindex]="0"  (keyup.enter)="ToggleCheckedReadOnly()">
                                    <input [checked]="isCheckedReadOnly" [disabled]="disableReadOnlyButton" type="checkbox" name="onoffswitchair" [(ngModel)]="model.ReadOnly" class="onoffswitch-checkbox" id="isReadOnlyAsset">
                                    <label class="onoffswitch-label" for="isReadOnlyAsset"><span class="onoffswitch-inner" data-swchon-text="YES" data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span></label>
                                </span>
                            </label>
                        </fieldset>
                    </div>
                </div>
               
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" *ngIf="!isAdd" pButton  class="btn p-button-info minzindex pull-left" (click)="openAssignedUserDlg(model.AssetID)" label="Assigned Users" ></button>
                <button type="submit" *ngIf="!isAdd" pButton icon="fa fa-check" class="btn btn-primary minzindex" label="Update" [disabled]="!assetEditForm.form.valid || isSubmitted"></button>
                <button type="submit" *ngIf="isAdd" pButton icon="fa fa-check" class="btn btn-primary minzindex" label="Add" [disabled]="!assetEditForm.form.valid || isSubmitted"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" (click)="btnCancelClick(assetEditForm)" label="Cancel" [disabled]="isSubmitted"  (focusout)="dfocus.applyFocus()"></button>
            </div>
        </ng-template>
    </p-dialog>
</form>
<!--end asset modal-->


<!--start attach asset modal-->
<form (ngSubmit)="btnAttachAssetSubmitClick(assetEditForm)" #attachAssetForm="ngForm">
    <p-dialog id="attachAssetFormdlg" [(visible)]="dlgAttachAssetdisplay" showEffect="fade" [modal]="true" [style]="{width: '360px'}" [resizable]="false" #attachAssetFormdlg>
        <ng-template pTemplate="header" (keyup.shift.tab)="btnCancelAttachAssetClick(attachAssetForm)">
            Link Sub-asset
        </ng-template> 
        
        <div class="upload-dialog">
            <!--<div class="modal-body">-->
            <div id="assetsEditdlgform" class="smart-form">
                <div class="row">
                        <div class="form-group col-sm-6 fix-dropdown-Padding">
                        <fieldset>
                            <label class="label">
                                Asset:<span [hidden]="modelAttachAsset.AssetID" class="text-danger">
                                    *
                                </span>
                            </label>                            
                            <p-dropdown #dfocus [style]="{'z-index':'auto'}" id="attachAsset" name="mfMAttachAsset" [options]="drpUserAssets" required placeholder="Select Asset" [filter]="true"
                                [(ngModel)]="modelAttachAsset.AssetID" appendTo="body">
                                <ng-template style="padding-bottom:20px;padding-left: 0px !important;" let-item pTemplate="selectedItem">
                                    <span>{{item.label}}</span>
                                    <!-- <span style="font-size:14px;background-color:lightgreen;">{{item.AssetType}}</span> -->
                                </ng-template>
                                <ng-template  style="padding-bottom:20px !important;padding-left: 0px !important;" let-asset pTemplate="item">
                                    <div style="padding-bottom: 20px;">
                                        <!-- <span > -->
                                            <span style="padding:0" class="col-md-9">{{asset.label}}</span>

                                        <!-- </div> -->
                                        <!-- <div class="col-md-4"> -->
                                            <span style="padding:0" class="col-md-3">
                                                <span *ngIf="asset.AssetType"  style="font-size: 11px;padding: 4px; background-color:lightgreen;color: white;border-radius: 4px;">
                                                    {{asset.AssetType}}
                                            </span>
                                            </span>

                                    <!-- </div> -->
                                    </div>
                                </ng-template>

                            </p-dropdown>


                        </fieldset>
                    </div>

                </div>
                   
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="submit" pButton icon="fa fa-check" class="btn btn-primary minzindex" label="Link"
                    [disabled]="!attachAssetForm.form.valid || isSubmitted"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnCancelAttachAssetClick(attachAssetForm)" label="Cancel" [disabled]="isSubmittedAttachAsset"
                    (focusout)="dfocus.applyFocus()"></button>
            </div>
        </ng-template>
    </p-dialog>
</form>
<!--end attach asset modal-->
