<section id="widget-grid" class="adminaircraftcls">
    
    <div class="row">
        <article class="col-sm-12 col-md-12 col-lg-12" id="col1">
            <!-- Widget ID (each widget will need unique ID)-->
            <div class="jarviswidget" id="wid-id-2" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false">
                <div class="row">
                    <div class="form-group col-sm-12">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h3 class="panel-title panalheading-padding">
                                    Selected Conditions
                                    <a class="button-icon hand-cursor pull-right" style="padding-right: 5px;padding-top: 5px;" id="btnCndAdd" (click)="addCondition()">
                                        <i class="glyphicon glyphicon-plus"></i>
                                    </a>
                                </h3>
                            </div>
                            <div class="panel-body">
                                <div class="col-sm-12 picklist-padding fix-picklist-width">
                                    <div class="table-responsive" style="max-height: 185px;">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th>Field Name</th>
                                                    <th>Condition</th>
                                                    <th>Value</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let cnd of model.Conditions; let j = index; trackBy:trackByIndex">
                                                    <td>
                                                        <select *ngIf="j!=0" class="form-control" id="{{'whereCondition' + j }}" name="{{'whereCondition' + j }}" [(ngModel)]="cnd.JoinCondition" style="width:200px;">
                                                            <option *ngFor="let w of whereConditions" value="{{w.value}}">{{w.label}}</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select class="form-control" id="{{'condFieldName' + j }}" name="{{'condFieldName' + j }}" [(ngModel)]="cnd.FieldName" style="width:200px;">
                                                            <option *ngFor="let f of mappingfields" value="{{f.value}}">{{f.label}}</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select class="form-control" id="{{'condition' + j }}" name="{{'condition' + j }}" [(ngModel)]="cnd.Condition" style="width:200px;">
                                                            <option *ngFor="let c of conditions" value="{{c.value}}">{{c.label}}</option>
                                                        </select>
                                                    </td>
                                                    <td><input id="{{'cndValue' + j }}" type="text" name="{{'cndValue' + j }}" [(ngModel)]="cnd.Value" class="input-sm" /></td>
                                                    <td>
                                                        <a class="button-icon hand-cursor pull-right" style="padding-right: 5px;" name="{{'btnCndDel' + j }}" id="{{'btnCndDel' + j }}" (click)="deleteCondition(j)">
                                                            <i class="glyphicon glyphicon-remove"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h3 class="panel-title panalheading-padding">
                                    Non Selected Conditions
                                    <a class="button-icon hand-cursor pull-right" style="padding-right: 5px;padding-top: 5px;" id="btnCndAdd" (click)="addCondition()">
                                        <i class="glyphicon glyphicon-plus"></i>
                                    </a>
                                </h3>
                            </div>
                            <div class="panel-body">
                                <div class="col-sm-12 picklist-padding fix-picklist-width">
                                    <div class="table-responsive" style="max-height: 185px;">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th>Field Name</th>
                                                    <th>Condition</th>
                                                    <th>Value</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let cnd of model.Conditions; let j = index; trackBy:trackByIndex">
                                                    <td>
                                                        <select *ngIf="j!=0" class="form-control" id="{{'whereCondition' + j }}" name="{{'whereCondition' + j }}" [(ngModel)]="cnd.JoinCondition" style="width:200px;">
                                                            <option *ngFor="let w of whereConditions" value="{{w.value}}">{{w.label}}</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select class="form-control" id="{{'condFieldName' + j }}" name="{{'condFieldName' + j }}" [(ngModel)]="cnd.FieldName" style="width:200px;">
                                                            <option *ngFor="let f of mappingfields" value="{{f.value}}">{{f.label}}</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select class="form-control" id="{{'condition' + j }}" name="{{'condition' + j }}" [(ngModel)]="cnd.Condition" style="width:200px;">
                                                            <option *ngFor="let c of conditions" value="{{c.value}}">{{c.label}}</option>
                                                        </select>
                                                    </td>
                                                    <td><input id="{{'cndValue' + j }}" type="text" name="{{'cndValue' + j }}" [(ngModel)]="cnd.Value" class="input-sm" /></td>
                                                    <td>
                                                        <a class="button-icon hand-cursor pull-right" style="padding-right: 5px;" name="{{'btnCndDel' + j }}" id="{{'btnCndDel' + j }}" (click)="deleteCondition(j)">
                                                            <i class="glyphicon glyphicon-remove"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <header>
                    <span class="widget-icon"><i class="fa fa-list"></i></span>
                    <h2>OCR Search Queries</h2>
                </header>
                <!-- widget div-->
                <div class="widgetdiv">

                    <!-- widget edit box -->
                    <div class="jarviswidget-editbox">
                        <!-- This area used as dropdown edit box -->

                    </div>
                    <!-- end widget edit box -->
                    <!-- widget content -->
                    <div class="widget-body">
                        
                        <!-- widget grid -->
                        <section id="widget-grid2" class="">
                            <div class="jqgridboxadminaircraft">
                            
                                <div class="jqgridboxadminaircraft" style="width: 100%; height: 530px !important;">
                                    <ag-grid-angular id="agGridBuilderSettings"   #agGridBuilderSettings [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="gridData" pagination=true paginationPageSize="20" sortable="true" style="width: 100%; height: 530px !important;" class="ag-theme-balham">
                                    </ag-grid-angular>
                                </div>
                                <!-- end row -->
                            </div>
                        </section>
                        <!-- end widget grid -->
                    </div>
                    <!-- end widget content -->
                </div>
                <!-- end widget div -->
            </div>
            <!-- end widget -->
        </article>
        <!-- END COL -->
    </div>
</section>


