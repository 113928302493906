//Components
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, Params, NavigationEnd, NavigationStart } from '@angular/router';
import { GridOptions, GridApi, ColumnApi,AgEvent,AgGridEvent } from "ag-grid-community";

@Component({
    selector: 'custom-button',
    changeDetection:ChangeDetectionStrategy.OnPush,
    templateUrl: './custom-button.component.html',

})
export class CustomButtonComponent implements OnInit {
    paramsGlobal: any;
    constructor(private cRef:ChangeDetectorRef) {

    }
    agInit(params:any)
    {
        this.paramsGlobal = params;
    }
    addColumn()
    {
        this.paramsGlobal.context.parentComponent.addColumn();
        this.cRef.markForCheck();
      //  console.log('Index::' + index + ' CurrentValue:' + currentValue + 'Event:' + event);
    }
    ngOnInit() {
    }

}