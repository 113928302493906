
//#region References
//Components
import { Component, OnInit, Input, ElementRef, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';
import { NgForm } from '@angular/forms';
import { UserService } from '../../../records/services/User/user.service';
import { SettingsService } from '../../services/settings/setting.service';
import { Alerts, SelectedTenant } from './alert';
import { SelectItem} from 'primeng/api';
import { Settings, settingsEntity } from '../settings/settings';
import { AlertService } from '../../services/alert/alert.service';

//#endregion
//#region Type Decorator
@Component({
    selector: 'alert',
    templateUrl: './alert.component.html',
    providers: [UserService, SettingsService, AlertService]
})
//#endregion

export class AlertComponent implements OnInit {
    Settings: Alerts = new Alerts();
    tenantSelectionList: SelectItem[] = [];
    availableUsers: any[];
    selectedUsers: any[];

    @ViewChild('mtncemailbody') mtncemailbody: ElementRef;

    isTextEditor: boolean = false;
    cursorPosition: number = 0;
    selectedCtrl: any = null;
    

    // Constructor
    constructor(public _sharedService: SharedService, private router: Router, private route: ActivatedRoute, private _userService: UserService, private _settingService: SettingsService, private _alertService: AlertService) {
        if (!this._sharedService.isAuthUser()) {
            this.router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
    }

    //#region Initialization of Component
    ngOnInit() {
        this.populatePredefinedConfigs();
        this.populateTenantsList();
        this.clearUsersLists();
    }
    //#endregion


    //#region CRUD
    changeShowUsers() {
        try {

            if (this.Settings.ShowUsers) {
                if (this.Settings.UserTenants.length > 0) {

                    let selectedTenantIds: string[] = this.Settings.UserTenants;
                    let TenantsList: any[] = [];
                    for (var i = 0; i < this.Settings.UserTenants.length; i++) {
                        let temp: SelectItem[] = this.tenantSelectionList.filter(x => x.value == this.Settings.UserTenants[i]);
                        if (temp.length > 0) {
                            TenantsList.push({
                                label: temp[0].label,
                                value: temp[0].value
                            });

                        }
                    }

                    let body: any = {
                        Tenants: TenantsList,
                        IsAllUsers: this.Settings.ShowUsers
                    }

                    this._alertService.GetSelectedTenantUsersWithTenantName(this._sharedService.baseUrl, body, this._sharedService.getReqOptWithAccessToken())
                    .subscribe({
                        next: (response) => {
                            this.clearUsersLists();
                            this.availableUsers = response;
                        },
                        error: (error) => {
                            console.log(error)
                        }
                    });
                }
                else {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'warn', summary: 'No Tenant Selected', detail: 'Please select a tenant.' });
                }
            }
            else {
                this.clearUsersLists();
            }

        } catch (e) {
            console.log(e);
        }
    }


    changeShowUsersOnTenantChange() {
        try {

            if (this.Settings.ShowUsers) {
                if (this.Settings.UserTenants.length > 0) {

                    let selectedTenantIds: string[] = this.Settings.UserTenants;
                    let TenantsList: any[] = [];
                    for (var i = 0; i < this.Settings.UserTenants.length; i++) {
                        let temp: SelectItem[] = this.tenantSelectionList.filter(x => x.value == this.Settings.UserTenants[i]);
                        if (temp.length > 0) {
                            TenantsList.push({
                                label: temp[0].label,
                                value: temp[0].value
                            });

                        }
                    }

                    let body: any = {
                        Tenants: TenantsList,
                        IsAllUsers: this.Settings.ShowUsers
                    }

                    this._alertService.GetSelectedTenantUsersWithTenantName(this._sharedService.baseUrl, body, this._sharedService.getReqOptWithAccessToken())
                    .subscribe({
                        next: (response) => {
                            this.clearUsersLists();
                            this.availableUsers = response;
                        },
                        error: (error) => {
                            console.log(error)
                        }
                    });
                }
                else {
                    this.clearUsersLists();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'warn', summary: 'No Tenant Selected', detail: 'Please select a tenant.' });
                }
            }
            else {
                this.clearUsersLists();
            }

        } catch (e) {
            console.log(e);
        }
    }

    sendNotification(form: any) {
        try {

            if (this.Settings.ShowUsers) {
                if (this.Settings.UserTenants.length > 0) {

                    let selectedTenantIds: string[] = this.Settings.UserTenants;

                    let body: any = {
                        TenantsIds: selectedTenantIds,
                        ShowUsers: this.Settings.ShowUsers,
                        EmailUsers: this.getSelectedUsers(),
                        From: this.Settings.MaintenanceFrom,
                        Subject: this.Settings.MaintenanceSubject,
                        Alias: this.Settings.MaintenanceAlias,
                        Body: this._sharedService.filterHTMLBody(this.Settings.MaintenanceBody)
                    }

                    this._alertService.SendNotification(this._sharedService.baseUrl, body, this._sharedService.getReqOptWithAccessToken())
                    .subscribe({
                        next: (response) => {
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Notification Sent Successfully.' });
                        },
                        error: (error) => {
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'error', summary: 'Failed', detail: 'Notification Failed.' });
                        }
                    });
                }
                else {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'warn', summary: 'No Tenant Selected', detail: 'Please select a tenant.' });

                }
            }
            else {
                let body: any = {
                    TenantsIds: this.Settings.UserTenants,
                    IsAllUsers: this.Settings.ShowUsers,
                    From: this.Settings.MaintenanceFrom,
                    Subject: this.Settings.MaintenanceSubject,
                    Alias: this.Settings.MaintenanceAlias,
                    Body: this._sharedService.filterHTMLBody(this.Settings.MaintenanceBody)
                }

                this._alertService.SendNotification(this._sharedService.baseUrl, body, this._sharedService.getReqOptWithAccessToken())
                .subscribe({
                    next: (response) => {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Notification Sent Successfully.' });
                    },
                    error: (error) => {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'error', summary: 'Failed', detail: 'Notification Failed.' });
                    }
                });
            }
        } catch (e) {
            console.log(e);
        }
    }
    //#endregion

    //#region Get Predefined Data
    populateTenantsList() {
        this._userService.getAllTenantsList()
        .subscribe({
            next: (response) => {
                this.tenantSelectionList = response;
                this.Settings.UserTenants.push(this._sharedService.getLoggedInTenantId());
            },
            error: (error) => {
                
            }
        });
    }
    populatePredefinedConfigs() {
        this._settingService.getSettings('Alert')
        .subscribe({
            next: (response) => {
                try {
                    let body: settingsEntity[] = response;
                    this.Settings.MaintenanceAlias = body.filter(x => x.Key == 'Alert_Email_From_Alias')[0].Value;
                    this.Settings.MaintenanceBody = body.filter(x => x.Key == 'Alert_Email_Body')[0].Value;
                    this.Settings.MaintenanceFrom = body.filter(x => x.Key == 'Alert_Email_From')[0].Value;
                    this.Settings.MaintenanceSubject = body.filter(x => x.Key == 'Alert_Email_Subject')[0].Value;
                } catch (e) {
                    console.log(e);
                }
            },
            error: (error) => {
                
            }
        })
    }
    //#endregion

    //#region Helper Methods

    getSelectedUsers(): any[] {

        let listOfUsers: any[] = [];
        try {

            for (var i = 0; i < this.selectedUsers.length; i++) {
                listOfUsers.push({
                    To: this.selectedUsers[i].value.split('||')[3],
                    FirstName: this.selectedUsers[i].value.split('||')[4],
                    LastName: this.selectedUsers[i].value.split('||')[5],
                    TenantName: this.selectedUsers[i].value.split('||')[2]
                });
            }

        } catch (e) {
            console.log(e);
        }
        return listOfUsers;
    }

    getSelectedClass(param: string): string {
        let userType = param.split('||')[1];
        if (userType == "Internal") {
            return 'text-danger';
        }
        else {
            return 'text-dark';
        }
    }

    getUserDepartment(param: string) {
        return param.split('||')[2];
    }

    clearUsersLists(): void {

        this.availableUsers = [];
        this.selectedUsers = [];
    }

    AssignToken(_controlId: string, _token: string)
    {
        var startPos=0;
        
        this.isTextEditor = false;
        switch(_controlId) {

            case "mtncemailbody":
                this.isTextEditor = true;
                this.selectedCtrl = this.mtncemailbody;
                startPos = this.selectedCtrl.quill.getSelection(true).index;
                this.selectedCtrl.quill.insertText(startPos, _token);
                this.Settings.MaintenanceBody = this.selectedCtrl.quill.root.innerHTML;
                this.cursorPosition = startPos + _token.length;
                break;
                default:
            break;
        }

        setTimeout(() => {
            if(this.isTextEditor == true)
            {
                this.selectedCtrl.quill.update('user');
                this.selectedCtrl.quill.setSelection(this.cursorPosition, 0, 'user');
            }
          }, 100);
}
    //#endregion
}