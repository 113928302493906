<section id="widget-grid" class="adminaircraftcls">
    
    <div class="row">
        <article class="col-sm-12 col-md-12 col-lg-12" id="col1">
            <!-- Widget ID (each widget will need unique ID)-->
            <div class="jarviswidget" id="wid-id-2" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false">
                <!-- widget options:
                    usage: <div class="jarviswidget" id="wid-id-0" data-widget-editbutton="false">

                    data-widget-colorbutton="false"
                    data-widget-editbutton="false"
                    data-widget-togglebutton="false"
                    data-widget-deletebutton="false"
                    data-widget-fullscreenbutton="false"
                    data-widget-custombutton="false"
                    data-widget-collapsed="true"
                    data-widget-sortable="false"
                    ddd
                    -->
                <header>
                    <span class="widget-icon"><i class="fa fa-file"></i></span>
                    <h2>Document Types</h2>
        
                    <!--<div class="jarviswidget-ctrls" role="menu">
                        <a class="button-icon" id="btnhidefilter" href="javascript:void(0);"><i class="glyphicon glyphicon-resize-horizontal"></i></a>
                    </div>-->
                    <!--<div class="jarviswidget-ctrls">
                        <a class="button-icon" id="btnMetaEdit" (click)="EditDocumentType()">
                            <i class="glyphicon glyphicon-pencil"></i>
                        </a>
                    </div>
                    <div class="jarviswidget-ctrls" role="menu">
                        <a class="button-icon hand-cursor" id="btnadd" style="color: black;" (click)="addDocumentType()">
                            <i class="glyphicon glyphicon-plus"></i>
                        </a>
                    </div>-->
                    <document-type [docTypeID]="docTypeID" (refreshGridEvent) = "handlereloadGrid($event)"></document-type>

                </header>
                <!-- widget div-->
                <div class="widgetdiv">

                    <!-- widget edit box -->
                    <div class="jarviswidget-editbox">
                        <!-- This area used as dropdown edit box -->

                    </div>
                    <!-- end widget edit box -->
                    <!-- widget content -->
                    <div class="widget-body">
                        <!--
                        The ID "widget-grid" will start to initialize all widgets below
                        You do not need to use widgets if you dont want to. Simply remove
                        the <section></section> and you can use wells or panels instead
                        -->
                        <!-- widget grid -->
                        <section id="widget-grid2" class="">
                            <div class="jqgridboxadminaircraft">
                                <!--<div class="p-pg-button p-corner-all jqgridnavbtn">
                                    <a routerLink="{{addAircraftPath}}" tabindex="1" class="btn btn-primary btn-labeled">
                                        <span class="btn-label"><i class="fa fa-lg fa-fw fa-plane"></i></span>New Aircraft
                                    </a>
                                </div>-->
                                <div class="jqgridboxadminaircraft" style="width: 100%;">
                                    <ag-grid-angular id="agGridDocumentTypes"  [navigateToNextCell]="navigateToNextCell"  #agGridDocumentTypes [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="gridData" sortable="true" filter=true style="width: 100%;" class="ag-theme-balham documenttypegrid" (selectionChanged)="handleSelectedDocTypes()" (rowDoubleClicked)="docTypeRowDoubleClicked($event)">
                                    </ag-grid-angular>
                                </div>
                                <!-- end row -->
                            </div>
                        </section>
                        <!-- end widget grid -->
                    </div>
                    <!-- end widget content -->
                </div>
                <!-- end widget div -->
            </div>
            <!-- end widget -->
        </article>
        <!-- END COL -->
    </div>
</section>


