import { Injectable, Inject, OnDestroy } from "@angular/core";
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { debug } from "console";
import { ENGINE_METHOD_CIPHERS } from "constants";
import { Subject, Subscription } from "rxjs";
import { Observable } from "rxjs";
import { SharedService } from "../../services/shared.service";

/**
 * When SignalR runs it will add functions to the global $ variable 
 * that you use to create connections to the hub. However, in this
 * class we won't want to depend on any global variables, so this
 * class provides an abstraction away from using $ directly in here.
 */
export class SignalrWindow extends Window {
    $: any;
}

export enum ConnectionState {
    Connecting = 1,
    Connected = 2,
    Reconnecting = 3,
    Disconnected = 4
}

export class ChannelConfig {
    url: string;
    hubName: string;
    channel: string;
}

export class ChannelEvent {
    Name: string;
    ChannelName: string;
    Timestamp: Date;
    Data: any;
    Task:any;
    Json: string;
    name: string;
    channelName: string;
    timestamp: Date;
    data: any;
    task:any;
    json: string;

    constructor() {
        this.Timestamp = new Date();
        this.timestamp = new Date();
    }
}

class ChannelSubject {
    channel: string;
    subject: Subject<ChannelEvent>;
}

/**
 * ChannelService is a wrapper around the functionality that SignalR
 * provides to expose the ideas of channels and events. With this service
 * you can subscribe to specific channels (or groups in signalr speak) and
 * use observables to react to specific events sent out on those channels.
 */
@Injectable()
export class ChannelService implements OnDestroy{

    private eventName = "tasks"
    private channel = "Status";
    private GlobaleventName = "GlobalEvent"
    private Globalchannel = "Globalchannel";
    /**
     * starting$ is an observable available to know if the signalr 
     * connection is ready or not. On a successful connection this
     * stream will emit a value.
     */
    starting$: Observable<any>;

    /**
     * connectionState$ provides the current state of the underlying
     * connection as an observable stream.
     */
    connectionState$: Observable<ConnectionState>;

    /**
     * error$ provides a stream of any error messages that occur on the 
     * SignalR connection
     */
    error$: Observable<string>;

    // These are used to feed the public observables 
    //
    private connectionStateSubject = new Subject<ConnectionState>();
    private startingSubject = new Subject<any>();
    private errorSubject = new Subject<any>();

    // These are used to track the internal SignalR state 
    //
   // private hubConnection: any;
    //private hubProxy: any;
    private ChannelSubscription: Subscription;
    // An internal array to track what channel subscriptions exist 
    
    private subjects = new Array<ChannelSubject>();

    private _hubConnection: HubConnection; 

    constructor(@Inject("channel.config") private channelConfig: ChannelConfig,private _sharedService: SharedService){

        this.starting$ = this.startingSubject.asObservable();
        
    }


    public createConnection() {  
        if (!this._sharedService.isAuthUser()) {
            return;
        }
        const tokenValue = '?access_token=' + this._sharedService.GetToken();
        const hubUrl = `${this.channelConfig.url}${tokenValue}`;
        this._hubConnection = new HubConnectionBuilder()
            .withUrl(hubUrl).withAutomaticReconnect().configureLogging(LogLevel.Error)
            .build();
    }

    public startHubConnection(): void {

        if (this._hubConnection == null)
            return
        var state = this._hubConnection.state;//.state((state: any) => {
        let newState = ConnectionState.Connecting;

        switch (state) {
            case HubConnectionState.Connected:
                newState = ConnectionState.Connecting;
                break;
            case HubConnectionState.Connected:
                newState = ConnectionState.Connected;
                break;
            case HubConnectionState.Reconnecting:
                newState = ConnectionState.Reconnecting;
                break;
            case HubConnectionState.Disconnected:
                newState = ConnectionState.Disconnected;
                this._hubConnection
                    .start()
                    .then(() => {
                        //this.connectionIsEstablished = true;  
                        this.joinHubChannel();
                        console.log('Hub connection started');
                        //   this.connectionEstablished.emit(true);  
                    })
                    .catch(err => {
                        console.log('Error while establishing connection, retrying... ' + err);
                        //setTimeout(function () { this.startHubConnection(); }, 5000);  
                    });
                break;
        }

        // Push the new state on our subject
        //
        this.connectionStateSubject.next(newState);

    }
    ngOnDestroy()  {

        if (this.ChannelSubscription) {
            this.ChannelSubscription.unsubscribe();
        }

    }
    /**
     * Start the SignalR connection. The starting$ stream will emit an 
     * event if the connection is established, otherwise it will emit an
     * error.
     */
    // start(): void {
    //     // Now we only want the connection started once, so we have a special
    //     //  starting$ observable that clients can subscribe to know know if
    //     //  if the startup sequence is done.
    //     //
    //     // If we just mapped the start() promise to an observable, then any time
    //     //  a client subscried to it the start sequence would be triggered
    //     //  again since it's a cold observable.
    //     //
    //     this.hubConnection.start({ transport: 'longPolling' })
    //         .done(() => {
    //             this.startingSubject.next();
    //               this.joinHubChannel();
    //         })
    //         .fail((error: any) => {
    //             this.startingSubject.error(error);
    //         });
    // }


    private joinHubChannel(): void {

        try {

            // Get an observable for events emitted on this channel
            if (!this._sharedService.isAuthUser()) {
                return;
            }
            this.sub(this._sharedService.getGroupNameKey(this.Globalchannel)).subscribe(
                (x: ChannelEvent) => {
                    switch (x.Name) {
                        case this._sharedService.getGroupNameKey(this.GlobaleventName): { this.appendStatusUpdate(x); }
                    }
                },
                (error: any) => {
                    console.warn("Attempt to join channel failed!", error);
                }
            )
        }
        catch (ex) {
            console.log(ex.error)
        }
    }


    private appendStatusUpdate(ev: ChannelEvent): void {
        // Just prepend this to the messages string shown in the textarea
        //
        if (!this._sharedService.isAuthUser()) {
            return;
        }

        if (ev.Data) {
            this._sharedService.onAssetChanged(this._sharedService.selectedAsset);
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: ev.Data.Severity, summary: ev.Data.Summary, detail: ev.Data.Detail });
        }
    }


    /** 
     * Get an observable that will contain the data associated with a specific 
     * channel 
     * */
    sub(channel: string): Observable<ChannelEvent> {
        // Try to find an observable that we already created for the requested 
        //  channel
        //
        let channelSub = this.subjects.find((x: ChannelSubject) => {
            return x.channel === channel;
        }) as ChannelSubject;

        // If we already have one for this event, then just return it
        //
        if (channelSub !== undefined) {
            console.log(`Found existing observable for ${channel} channel`)

            return channelSub.subject.asObservable();
        }


    
        //
        // If we're here then we don't already have the observable to provide the
        //  caller, so we need to call the server method to join the channel 
        //  and then create an observable that the caller can use to received
        //  messages.
        //

        // Now we just create our internal object so we can track this subject
        //  in case someone else wants it too
        //
        channelSub = new ChannelSubject();
        channelSub.channel = channel;
        channelSub.subject = new Subject<ChannelEvent>();
        this.subjects.push(channelSub);

        // Now SignalR is asynchronous, so we need to ensure the connection is
        //  established before we call any server methods. So we'll subscribe to 
        //  the starting$ stream since that won't emit a value until the connection
        //  is ready
        //
   
         //  this.starting$.subscribe(() => {

               this._hubConnection.invoke('Subscribe', channel)
                   .then(() => {
                       console.log(`Successfully subscribed to ${channel} channel`);
                   })
                   .catch((error: any) => {
                       channelSub.subject.error(error);
                   });
        //    },
        //        (error: any) => {
        //            channelSub.subject.error(error);
        //        });

            // this._hubConnection.invoke("Subscribe", channel)
            //     .then(() => {
            //         console.log(`Successfully subscribed to ${channel} channel`);
            //     })
            //     .catch();
            //     ((error: any) => {
            //     channelSub.subject.error(error);
            // });
        // },
        //     (error: any) => {
        //         channelSub.subject.error(error);
        //     });

        this._hubConnection.on(channel, (ev: ChannelEvent) => {
            // Multicast the event.
            let channelEvents:ChannelEvent= new ChannelEvent();
            channelEvents.Name = ev.name;
            channelEvents.ChannelName = ev.channelName;
            channelEvents.Timestamp = ev.timestamp;
            channelEvents.Data = ev.data;
            channelEvents.Task = ev.task;
            channelEvents.json = ev.Json;
            channelEvents.Data.ViewID=ev.data.viewID
            channelEvents.Data.ViewName=ev.data.viewName
            channelEvents.Data.JobID=ev.data.jobID
            channelEvents.Data.TotalRecords=ev.data.totalRecords
            channelEvents.Data.CurrentTaskIndex=ev.data.currentTaskIndex
            channelEvents.Data.Message=ev.data.message
            channelEvents.Data.State=ev.data.state
            channelEvents.Data.ActiveChannel=ev.data.activeChannel
            channelEvents.Data.Percentage=ev.data.percentage

            channelSub.subject.next(channelEvents);

        });



        this._hubConnection.onclose((err?: Error) => {

            if (err) {

                // An error occurs

                channelSub.subject.error(err); 

            } else {

                // No more events to be sent.

                channelSub.subject.complete();

            }

        });
        
        return channelSub.subject.asObservable();
    }


    unSub(channel: string) {

        // Try to find an observable that we already created for the requested 
        //  channel
        //
        let channelSub = this.subjects.find((x: ChannelSubject) => {
            return x.channel === channel;
        }) as ChannelSubject;

        // If we already have one for this event, then just return it
        //
        if (channelSub !== undefined) {
            //console.log(`Found existing observable for ${channel} channel`)
            channelSub.subject.unsubscribe();
        }

        //
        // If we're here then we don't already have the observable to provide the
        //  caller, so we need to call the server method to join the channel 
        //  and then create an observable that the caller can use to received
        //  messages.
        //

        // Now we just create our internal object so we can track this subject
        //  in case someone else wants it too
        //
        channelSub = new ChannelSubject();
        channelSub.channel = channel;
        channelSub.subject = new Subject<ChannelEvent>();
        //this.subjects.findIndex(channelSub)

        // Now SignalR is asynchronous, so we need to ensure the connection is
        //  established before we call any server methods. So we'll subscribe to 
        //  the starting$ stream since that won't emit a value until the connection
        //  is ready
        //

    }



    // Not quite sure how to handle this (if at all) since there could be
    //  more than 1 caller subscribed to an observable we created
    //
    // unsubscribe(channel: string): Observable<any> {
    //     this.observables = this.observables.filter((x: ChannelObservable) => {
    //         return x.channel === channel;
    //     });
    // }

    /** publish provides a way for calles to emit events on any channel. In a 
     * production app the server would ensure that only authorized clients can
     * actually emit the message, but here we're not concerned about that.
     */
    publish(ev: ChannelEvent): void {
        this._hubConnection.invoke("Publish",ev).then(() => {
            console.log(`Successfully subscribed to ${ev.ChannelName} channel`);
        })
            .catch();
        ((error: any) => {
            console.log(error);
        });
        // this.hubProxy.invoke("Publish", ev);
    }

}