import { Router, ActivatedRoute } from '@angular/router';
import { ArticleService } from './../../services/article/article.service';
import { Subscription, Subscriber } from 'rxjs';
import { SharedService } from './../../../shared/services/shared.service';
import { NgForm } from '@angular/forms';

import { articleCategoryModel } from './../category/category';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

var _router: Router;

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  providers: [ArticleService]
})
export class AddCategoryComponent implements OnInit {
  display: boolean = false;
  public dialogTitle: string = 'Add Category';
  public isSubmitted: boolean = false;
  public lblsubmitButton: string = 'Add';
  public btnlabel = 'Add';
  public model: articleCategoryModel;
  private editCategory: Subscription;
  private addCategory: Subscription;
  private editFromMenuCategory: Subscription;
  private canAddEditArticleCategory: boolean = false;

  @Input() categoryID: string;
  constructor(private _sharedService: SharedService, private route: ActivatedRoute, private router: Router, private _articleService: ArticleService) {
    if (!this._sharedService.isAuthUser()) {
      this.router.navigate(['login']);
    }

    else {

      _router = this.router;
      this.updateRoles();
      this.model = new articleCategoryModel();

    }

    this.resetModel();

  }

  @Output()
  refreshGridEvent = new EventEmitter<boolean>();
  ngOnInit() {


    this.editCategory = this._sharedService._editAdminCategory
      .subscribe({
        next: (param:any) => {
          this.categoryID = param;
          this.showEditDialog();
        },
        error: (error) => {
        }
      });

    this.addCategory = this._sharedService._addAdminCategory
    .subscribe({
      next: (param:any) => {
        this.resetModel();
        this.showDialog();
      },
      error: (error) => {
      }
    });

    this.editFromMenuCategory = this._sharedService._editFromMenuCategoryId
    .subscribe({
      next: (CategoryID:string) => {
        this.categoryID = null;
        this.categoryID = CategoryID;
        this.showEditDialog();
      },
      error: (error) => {
      }
    });
  }

  updateRoles() {
    if (!this._sharedService.UserRole) {
      this._sharedService.updateRole();
    }

    this.canAddEditArticleCategory = true;

  }
  resetModel() {
    this.model = new articleCategoryModel();
    this.model.Color = '#0976e3';
    this.model.Status = true;

  }
  btnSubmitClick(form: NgForm) {

    this.isSubmitted = true;
    let articleCategory = this.model;
    if (articleCategory.ArticleCategoryId) {
      this.updateCategory(articleCategory, form);
    }
    else {

      this.addArticleCategory(articleCategory, form);
    }


  }


  addArticleCategory(body: articleCategoryModel, form: NgForm) {

    this._sharedService.showLoader("Processing...");
    this._articleService.addArticleCategory(body)
    .subscribe({
      next: (response) => {
        this._sharedService.hideLoader();
        this.display = false;
        this.changeButtonsStatus(false);
        /* form.reset(); */
        this.resetModel();
        this.refreshGridEvent.emit(true);
      },
      error: (error) => {
        this._sharedService.hideLoader();
        this._sharedService.clearToast();
        if (error.status === 409) {
          this._sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Article Category already exists.' });
        }
        else {
          this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Article Category not added, please try again.' });
        }
        this.display = false;
        this.resetModel();
        this.changeButtonsStatus(false);
      }
    });
  }


  updateCategory(body: articleCategoryModel, form: NgForm) {
    this._sharedService.showLoader("Processing...");
    this._articleService.updateArticleCategory(body)
    .subscribe({
      next: (response) => {
        this._sharedService.hideLoader();
        this.display = false;
        this.resetModel();
        /* form.resetForm(); */
        this._sharedService.clearToast();
        this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Article Category updated succesfully.' });
        this.changeButtonsStatus(false);
        this.refreshGridEvent.emit(true);
        //this.returntoAction(this.documentTypesURL);
      },
      error: (error) => {
        this._sharedService.hideLoader();
        this._sharedService.clearToast();
        if (error.status === 409) {
          this._sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Article Category already exists.' });
        }
        else {
          this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Article Category not updated, please try again.' });
        }
        this.display = false;
        this.resetModel();
        this.changeButtonsStatus(false);
      }
    });
  }
  changeButtonsStatus(status: boolean) {
    this.isSubmitted = status;
  }


  btnCancelClick(/*cancelForm:NgForm*/) {
    this.display = false;
    /*   cancelForm.resetForm(); */
    this.resetModel();
  }



  showEditDialog() {
    if (this.categoryID) {
      this.dialogTitle = "Edit Category";
      this.btnlabel = "Update"
      this.display = true;
      this.initializeForm(this.categoryID);

    }
    else {
      this._sharedService.clearToast();
      this._sharedService.showToast({ severity: 'warn', summary: 'No Category selected', detail: 'Please select Category' });
    }
  }


  showDialog() {
    this.display = true;
    this.dialogTitle = "Add Category";
    this.btnlabel = "Save"
  }
  initializeForm(CategoryID: string): void {
    if (CategoryID) {

      this.loadArticleCategoryData(CategoryID);
    }
  }

  loadArticleCategoryData(CategoryID: string): void {


    let userRole: string = localStorage.getItem('roles').trim();
    this._articleService.getArticleCategorbyId(CategoryID)
    .subscribe({
      next: (response) => {
        this.model = response;
      },
      error: (error) => {
      }
    });
  }

  ngOnDestroy() {
    if (this.editCategory) {
      this.editCategory.unsubscribe();
    }
    if (this.addCategory) {
      this.addCategory.unsubscribe();
    }
    if (this.editFromMenuCategory) {
      this.editFromMenuCategory.unsubscribe();
    }
  }
}

