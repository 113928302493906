
  <!-- MAIN PANEL -->
  <div role="main" style="background-color: #fbfbfb !important;" >

      <!-- MAIN CONTENT -->
      <div id="content" style="opacity: 1;">
            <section id="widget-grid-main" class="well" style="height: 90vh;overflow-x: hidden; overflow-y: scroll; border: none !important;">
              <div id="content">
                <div class="row" style="padding-left:6%;padding-right:6%;">
                    <div class="col-sm-12" >
                    <app-search-article ></app-search-article>
                  </div>
                 </div>
                  <!-- row -->
                  <div class="row" style="padding-left:6%;padding-right:6%;margin-top:1em;">
                      <div class="col-sm-12">
                          <div class="well padding-10 " style="background-color: #fff; ">

                           <!-- <div class="forum-attachment" style="margin-top: 1em;">
                            <a routerLink="/help/helparticles"  href="javascript:void(0);"  style="color: #3276b1; "> Solution Home</a> /
                            <a routerLink="/help/helparticles"   href="javascript:void(0);" style="color: #3276b1;"> ROAM Help Center</a>
                           </div> -->


                               <h1 class="page-title txt-color-blueDark " > <i class="fa fa-folder-open-o"> </i>  {{sectionDetail.Name}} </h1>
                               <p>{{sectionDetail.Description}} </p>


                               <table class="table table-forum">
                                <tbody>
                                 <tr *ngFor="let article  of sectionDetail.Articles;">
                                              <td class="text-center" style="width: 40px;">
                                                <i class="fa fa-book fa-2x text-muted"></i></td>
                                               <td style="padding-left: 1em;">

                                                    <a href="javascript:void(0);" style="text-decoration: none;font-family:Arial, Helvetica, sans-serif !important;;font-size:16px;font-weight:600;color:rgb(24,50,71)" (click)="redirectArticle(article.ArticleId)" >
                                                        {{article.Title}}
                                                    </a>

                                                  <p style="line-height:1.5em;height: 1.5em;overflow: hidden;margin-bottom: 0 !important;padding-bottom: 0 !important;" >
                                                    <small >{{article.ArticleContentPlainText}}</small>
                                                  </p>
                                                  <small> {{article.ModifiedOn | date : "medium"}}</small>

                                                </td>
                                            </tr>

                               </tbody>
                            </table>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
  </div>
