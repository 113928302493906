<div class="jarviswidget-ctrls pdfoperations" role="menu">
    <div class="btn-group">
        <a class="button-icon dropdown-toggle" data-toggle="dropdown" title="PDF Operations" aria-expanded="true">
            <i class="fa fa-lg fa-fw fa-file-pdf tablIndexFocus" [attr.tabindex]="0"></i>
        </a>
        <ul class="dropdown-menu pull-right js-status-update">
            <li>
                <a (click)="OpenExtractModal();"><i class="fa fa-columns txt-color-green"></i>&nbsp;Extract Pages</a>
            </li>
            <li>
                <a (click)="OpenMergeModal();"><i class="fa fa-compress txt-color-orange"></i>&nbsp;Insert File
                    (ROAM)</a>
            </li>
            <li>
                <a (click)="OpenInsertModal();"><i class="fa fa-plus txt-color-red"></i>&nbsp;Insert File (PC)</a>
            </li>
            <li>
                <a (click)="OpenDeleteModal();"><i class="fa fa-trash-o"></i>&nbsp;Delete Pages </a>
            </li>
        </ul>
    </div>
</div>



<p-confirmDialog></p-confirmDialog>
<!----------------------------------------- Extract PDF dialogue------------------------------------------------------------------->
<form *ngIf="isDisplayExtractPagesDialog" #frmUploadDoc="ngForm">
    <!--name="frmUploadDoc"-->
    <p-dialog *ngIf="isDisplayExtractPagesDialog" [(visible)]="isDisplayExtractPagesDialog" id="docUpload"
        showEffect="fade" [resizable]="false" [modal]="true" [style]="{width: '700px'}">
        <ng-template pTemplate="header"  (keyup.shift.tab)="onKey()">
            {{dialogTitle}}
        </ng-template>

        <!--Contents Start Here-->


        <div class="upload-dialog">
            <!-- commented for tabing -->
            <!-- <div class="alert alert-danger fade in" style="display: none">
                <button class="close" data-dismiss="alert">×</button>
                <h4 class="alert-heading"><i class="fa-fw fa fa-times"></i>Error!</h4>
                <p id="errorMsg"></p>
            </div> -->
            <div id="smart-form" class="smart-form">
                <div id="form-fields">
                    <div class="row">
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">Pages
                                </label>
                                <label class="input">
                                    <input type="text" required placeholder="  e.g. 1-5, 8, 11-13" class="input-smText"
                                        id="PageRange" name="PageRange" [(ngModel)]="metaData.PageRange" />
                                </label>
                            </fieldset>
                        </div>

                        <!-- <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">Delete Pages after Extracting</label>
                                <label class="">
                                    <span class="onoffswitch tablIndexFocus" [attr.tabindex]="0"  (keyup.enter)="_sharedService.ToggleChecked()">
                                        <input  [checked]="_sharedService.isChecked" type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" checked="checked" id="mfIsDeleteFile" [(ngModel)]="metaData.IsDelete" />
                                        <label class="onoffswitch-label" for="mfIsDeleteFile"><span class="onoffswitch-inner" data-swchon-text="YES" data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span></label>
                                    </span>
                                </label>
                            </fieldset>
                        </div> -->
                    </div>

                    <div class="row">
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">Delete Pages after Extracting</label>
                                <label class="">
                                    <span class="onoffswitch tablIndexFocus" [attr.tabindex]="0"
                                        (keyup.enter)="ToggleChecked()">
                                        <input [checked]="isChecked" type="checkbox" name="onoffswitch"
                                            class="onoffswitch-checkbox" checked="checked" id="mfIsDeleteFile"
                                            [(ngModel)]="metaData.IsDelete" />
                                        <label class="onoffswitch-label" for="mfIsDeleteFile"><span
                                                class="onoffswitch-inner" data-swchon-text="YES"
                                                data-swchoff-text="NO"></span><span
                                                class="onoffswitch-switch"></span></label>
                                    </span>
                                </label>
                            </fieldset>
                        </div>
                        <!-- <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">Delete Pages after Extracting</label>
                                <label class="">
                                    <span class="onoffswitch tablIndexFocus" [attr.tabindex]="0"  (keyup.enter)="_sharedService.ToggleChecked()">
                                        <input  [checked]="_sharedService.isChecked" type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" checked="checked" id="mfIsDeleteFile" [(ngModel)]="metaData.IsDelete" />
                                        <label class="onoffswitch-label" for="mfIsDeleteFile"><span class="onoffswitch-inner" data-swchon-text="YES" data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span></label>
                                    </span>
                                </label>
                            </fieldset>
                        </div> -->

                        <div class="col-sm-6" *ngIf="((canGENQualityStatus && processGENQualityStatusEnabled) 
                                    || (canMXRQualityStatus && processMXRQualityStatusEnabled) 
                                    || (canLOGQualityStatus && processLOGQualityStatusEnabled)
                                    || (canDOCQualityStatus && processDOCQualityStatusEnabled)
                                    || (canBNDQualityStatus && processBNDQualityStatusEnabled))">
                            <fieldset>
                                <label class="label">
                                    <!-- Status -->
                                    {{_sharedService.getDocumentLabel(recordType, "Status")}}
                                    <span [hidden]="(recordType=='DOC'||recordType=='BND')" class="text-danger">
                                        *
                                    </span>
                                </label>
                                <!-- <span [ngClass]="{ readonlyinput2: recordType == 'Search' }"> -->
                                <span>
                                    <p-dropdown appendTo="body" [options]="qualityStatusList" requried name="qualityStatus"
                                        #qualityStatus="ngModel" placeholder="Select Quality Status"
                                        [style]="{ width: '300px' }" [(ngModel)]="metaData.Status" [filter]="true">
                                    </p-dropdown>
                                </span>
                            </fieldset>
                        </div>


                    </div>

                    <div class="row">
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">
                                    <!-- Document Type -->
                                    {{_sharedService.getDocumentLabel(recordType, "DocType")}}
                                    <span [hidden]="(recordType=='DOC'||recordType=='BND')" class="text-danger">
                                        *
                                    </span>
                                </label>
                                <p-dropdown appendTo="body" [options]="DocType" name="docType" #docType="ngModel"
                                    [(ngModel)]="metaData.DocType" #dd [resetFilterOnHide]="true" [filter]="true"
                                    placeholder="Select Document Type"></p-dropdown>
                                <!--[ngModelOptions]="{standalone: true}"-->
                            </fieldset>
                        </div>
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">
                                    <!-- Document Date -->
                                    {{_sharedService.getDocumentLabel(recordType, "DocDate")}}
                                    <span [hidden]="(recordType=='DOC'||recordType=='BND')" class="text-danger">
                                        *
                                    </span>
                                    ({{ViewDateFormat}})
                                </label>
                                <label class="input"
                                    [ngClass]="{'readonlyinput': parentWoNumber !='' && recordType=='MXR'}">
                                    <!--|| parentWoNumber !='null'-->
                                    <i class="icon-append fa fa-calendar"></i>
                                    <!--<input type="text" tabindex="3" placeholder="Select a date" class="datepicker hasDatepicker" id="DocDate">-->
                                    <p-calendar appendTo="body" placeholder="{{ViewDateFormat}}" [monthNavigator]="true"
                                        [yearNavigator]="true" class="datepicker hasDatepicker" yearRange="1950:2030"
                                        [style]="{width:300}" placeholder="Select a date"
                                        [required]="(recordType!=='DOC' && recordType!=='BND')" name="docDate"
                                        #docDate="ngModel" [(ngModel)]="metaData.DocDate"
                                        [dateFormat]="dateFormatCalender" (onClose)="onCloseEnddate()"
                                        [disabled]="parentWoNumber !='' && recordType=='MXR'"></p-calendar>
                                    <!-- [style]="{width:300}" [(ngModel)]="date3"-->
                                </label>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">
                                    <!-- Document Number -->
                                    {{_sharedService.getDocumentLabel(recordType, "DocNo")}}
                                    <!--<span class="text-danger">*</span>-->
                                </label>
                                <label class="input rgxErr">
                                    <i *ngIf="docNo.value && !docNo.valid && (docNo.touched || docNo.dirty )"
                                        class="icon-append fa fa-ban"
                                        title="{{_sharedService.error_rgx_pre}} {{_sharedService.re_DocumentNumber_Message}} {{_sharedService.error_rgx_post}}">
                                    </i>
                                    <span id="docNumber" [attr.tabindex]="0">
                                        <input id="docNumber" [attr.tabindex]="0" type="text" class="input-smText"
                                            [(ngModel)]="metaData.DocNo" [pattern]="_sharedService.re_DocumentNumber"
                                            name="docNo" #docNo="ngModel">
                                    </span>
                                    <!--required name="docNo" #docNo="ngModel" [(ngModel)]="metaData.DocNo"-->
                                </label>
                            </fieldset>
                        </div>
                        <div class="form-group col-sm-6" *ngIf="recordType == 'MXR'">
                            <fieldset>
                                <label class="label">
                                    <!-- {{referenceKeyLabel}} -->
                                    {{_sharedService.getDocumentLabel(recordType, "ReferenceKey")}}
                                    <span class="text-danger">*</span>
                                </label>
                                <label class="input rgxErr">
                                    <i *ngIf="woNumber.value && !woNumber.valid && (woNumber.touched || woNumber.dirty )"
                                        class="icon-append fa fa-ban"
                                        title="{{_sharedService.error_rgx_pre}} {{_sharedService.re_WorkOrderNumber_Message}} {{_sharedService.error_rgx_post}}">
                                    </i>
                                    <input id="woNumber" required name="woNumber" #woNumber="ngModel"
                                        [(ngModel)]="metaData.ReferenceKey" [disabled]="parentWoNumber != ''"
                                        [ngClass]="{'readonlyinput2': parentWoNumber != ''}" type="text"
                                        [pattern]="_sharedService.re_WorkOrderNumber" class="input-smText">
                                    <!--[disabled]="metaData.WONumber==null || metaData.WONumber==''"-->
                                </label>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <fieldset>
                                <label class="label">
                                    <!-- Tags -->
                                    {{_sharedService.getDocumentLabel(recordType, "Tags")}}
                                </label>
                                <p-multiSelect id="docTags" [options]="tags" [(ngModel)]="selectedTags" placeholder="Choose"
                                    [style]="{width:'100%'}" name="docTags" #docTags="ngModel" [maxSelectedLabels]="4" selectedItemsLabel="{0} items selected" appendTo="body"></p-multiSelect>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <fieldset>
                                <label class="label">
                                    <!-- Comments -->
                                    {{_sharedService.getDocumentLabel(recordType, "Comments")}}
                                </label>
                                <label class="textarea"><textarea id="comments" name="comments" #comments="ngModel"
                                        [(ngModel)]="metaData.Comments" class="custom-scrollNote" rows="2"></textarea>
                                </label>
                            </fieldset>
                        </div>
                    </div>
                    <!--<div class="row">
                        <div class="form-group col-sm-3">
                            <fieldset>
                                <label class="label">OCR</label>
                                <label class="label">
                                    <span class="onoffswitch">
                                        <input type="checkbox" name="ocr"
                                               class="onoffswitch-checkbox" checked="checked" id="ocr" [(ngModel)]="metaData.OCR" #ocr="ngModel">
                                        <label class="onoffswitch-label"
                                               for="ocr">
                                            <span class="onoffswitch-inner" data-swchon-text="YES" data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span>
                                        </label>
                                    </span>
                                </label>
                            </fieldset>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>

        <!--Contents End Here-->

        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary"
                    (click)="btnSubmitClick(frmUploadDoc)"
                    [disabled]="!frmUploadDoc.form.valid || isSubmitted 
                    || (recordType !== 'DOC' && recordType !=='BND' && metaData.DocType=='') 
                    || (recordType !== 'DOC' && recordType !=='BND' && metaData.DocDate==null)
                    || (recordType == 'MXR' && metaData.ReferenceKey.length==0) 
                    || (recordType !== 'DOC' && recordType !=='BND' && ( metaData.Status==null || metaData.Status==''))" label="Extract"></button>
                <!--||metaData.DocDate.length==0-->
                <button type="button" pButton icon="fa fa-close" class="btn btn-default"
                    (click)="btnCancelClick(frmUploadDoc)" label="Cancel" [disabled]="isSubmitted"
                    (focusout)="applyFocusToInput()"></button>
            </div>
        </ng-template>
    </p-dialog>
</form>
<!----------------------------------------- Extract PDF dialogue------------------------------------------------------------------->
<!----------------------------------------- Merge PDF Local------------------------------------------------------------------->
<form *ngIf="isDisplayMergeLocalDialog" #frmmergelocal="ngForm">
    <p-dialog *ngIf="isDisplayMergeLocalDialog" [(visible)]="isDisplayMergeLocalDialog" id="mergepdflocal"
        showEffect="fade" [modal]="true" [style]="{width: '700px'}" [resizable]="false">
        <ng-template pTemplate="header"  (keyup.shift.tab)="onKeyMergeLocal()">
            {{mergeLocalDialogTitle}}
        </ng-template>

        <div class="upload-dialog">

            <div id="insmart-form" class="smart-form">

                <fieldset>
                    <!-- <label class="label">File Input <span class="text-danger">*</span></label>
                    <div class="form-group col-sm-12">
                        <input #focusLocal #singleFileUpload type="file" class="form-control"
                            (change)="onChange($event)"
                            accept="application/pdf,image/bmp,image/png,image/jpg,image/jpeg,image/jp2,image/jpc,image/jfif,image/tif,image/tiff,image/gif"
                            required>
                    </div> -->


                    <div class="row">
                        <div class="form-group col-sm-6">
                            <label class="label">File Input <span class="text-danger">*</span></label>
                            <div class="form-group col-sm-12">
                                <input #focusLocal #singleFileUpload type="file" class="form-control"
                                    (change)="onChange($event)"
                                    accept="application/pdf,image/bmp,image/png,image/jpg,image/jpeg,image/jp2,image/jpc,image/jfif,image/tif,image/tiff,image/gif"
                                    required>
                            </div>
                        </div>

                        <!---*ngIf="showExtractPageRangePDF"-->
                        <div class="form-group col-sm-6" *ngIf="showExtractPageRangePDF">
                            <div class="pull-right">
                                <label class="label">Pages</label>
                                <div class="form-group col-sm-12">
                                    <input type="text" placeholder="  e.g. 1-5, 8, 11-13" class="form-control"
                                        id="PageRange" name="PageRange" [(ngModel)]="pdfMergeLocalModel.PageRange" />
                                </div>
                            </div>
                        </div>
                    </div>

                </fieldset>
                <fieldset>
                    <label class="select">Location </label>
                    <div id="indrp" class="form-group col-sm-12">
                        <p-dropdown appendTo="body" [options]="DocLocation" required name="indocs" #docs="ngModel"
                            placeholder="Select location" [(ngModel)]="pdfMergeLocalModel.Location" [filter]="false">
                        </p-dropdown>
                    </div>
                </fieldset>
                <fieldset>
                    <section>
                        <label class="label">Page</label>
                        <div class="row">
                            <div class="col col-2">
                                <label class="radio">
                                    <input type="radio" name="inradio" checked="checked"
                                        [(ngModel)]="pdfMergeLocalModel.MergeOptions" id="mfFirstPage" value="First" />
                                    <i></i>First
                                </label>
                                <label class="radio">
                                    <input type="radio" name="inradio" id="inLastPage"
                                        [(ngModel)]="pdfMergeLocalModel.MergeOptions" value="Last" />
                                    <i></i>Last
                                </label>
                                <label class="radio">
                                    <input type="radio" name="inradio" id="inmfPage"
                                        [(ngModel)]="pdfMergeLocalModel.MergeOptions" value="Number" />
                                    <i></i>Number
                                </label>
                            </div>
                            <div class="col col-2 pdfform_pageno">
                                <input type="text" class="input-smText"
                                    [required]="pdfMergeLocalModel.MergeOptions=='Number'" id="inPageNo" name="pageno"
                                    [(ngModel)]="pdfMergeLocalModel.PageNumber" />
                            </div>
                        </div>
                    </section>
                </fieldset>

                <!-- <fieldset>
                    <div class="row">
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">Pages<span class="text-danger">*</span></label>
                            </fieldset>
                        </div>
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="input">
                                    <input type="text" required placeholder="e.g. 1-5, 8, 11-13" class="input-smText"
                                        id="PageRange" name="PageRange" [(ngModel)]="pdfMergeLocalModel.PageRange" required />
                                </label>
                            </fieldset>
                        </div>
                    </div>
                </fieldset> -->
            </div>
        </div>
        <!--Contents End Here-->
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary" (click)="btnSubmitMergeLocal()"
                    label="Insert"
                    [disabled]="!frmmergelocal.form.valid || isSubmitted || uploadedFile.length==0"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default"
                    (click)="btnMergeLocalCancelClick(frmmergelocal)" label="Cancel" (focusout)="applyFocusToLocal()"
                    [disabled]="isSubmitted"></button>
                <!--|| (metaData.DocType=='')|| (metaData.DocNo.length==0)||(recordType == 'MXR' && metaData.ReferenceKey.length==0)||metaData.DocDate==null-->
            </div>
        </ng-template>
    </p-dialog>
</form>
<!----------------------------------------- Merge PDF Local------------------------------------------------------------------->
<!----------------------------------------- Merge PDF Online------------------------------------------------------------------->
<form *ngIf="isDisplayMergeOnlineDialog" #frmmergeonline="ngForm">
    <p-dialog *ngIf="isDisplayMergeOnlineDialog" [(visible)]="isDisplayMergeOnlineDialog" id="mergonline"
        showEffect="fade" [modal]="true" [style]="{width: '700px'}" [resizable]="false">
        <ng-template pTemplate="header"  (keyup.shift.tab)="onKeyMergeOnline()" [attr.tabindex]="0">
            {{mergeOnlineDialogTitle}}
        </ng-template>

        <div class="upload-dialog">

            <div id="olsmart-form" class="smart-form">

                <fieldset>
                    <label class="label">Main File (Insert into)<span class="text-danger">*</span></label>
                    <div id="oldocdrp" class="form-group col-sm-12">
                        <p-dropdown appendTo="body" #dmerg [options]="selectedDocs" required name="oldocs" #onlinedocs="ngModel"
                            placeholder="Select main document" [(ngModel)]="pdfMergeOnlineModel.MasterFile"
                            [filter]="false"></p-dropdown>
                    </div>
                </fieldset>

                <fieldset>
                    <label class="select">Location</label>
                    <div id="mtypedrp" class="form-group col-sm-12">
                        <p-dropdown appendTo="body" [options]="DocLocation" required name="ollocation" #ollocation="ngModel"
                            placeholder="Select location" [(ngModel)]="pdfMergeOnlineModel.Location" [filter]="false">
                        </p-dropdown>
                    </div>
                </fieldset>
                <fieldset>
                    <section>
                        <label class="label">Page</label>
                        <div class="row">
                            <div class="col col-2">
                                <label class="radio">
                                    <input type="radio" tablIndexFocus [attr.tabindex]="0" name="olradio"
                                        checked="checked" [(ngModel)]="pdfMergeOnlineModel.MergeOptions"
                                        id="mfFirstPage" value="First" />
                                    <i></i>First
                                </label>
                                <label class="radio">
                                    <input type="radio" tablIndexFocus [attr.tabindex]="0" name="olradio"
                                        id="mfLastPage" [(ngModel)]="pdfMergeOnlineModel.MergeOptions" value="Last" />
                                    <i></i>Last
                                </label>
                                <label class="radio">
                                    <input type="radio" tablIndexFocus [attr.tabindex]="0" name="olradio" id="mfPage"
                                        [(ngModel)]="pdfMergeOnlineModel.MergeOptions" value="Number" />
                                    <i></i>Number
                                </label>
                            </div>
                            <div class="col col-2 pdfform_pageno">
                                <input type="text" class="input-smText"
                                    [required]="pdfMergeOnlineModel.MergeOptions=='Number'" id="mfPageNo"
                                    name="olpageno" [(ngModel)]="pdfMergeOnlineModel.PageNumber" required />
                            </div>
                        </div>
                    </section>
                </fieldset>
                <fieldset>
                    <label class="label">Delete File after Insert</label>
                    <label class="checkbox">
                        <span class="onoffswitch tablIndexFocus" [attr.tabindex]="0"
                            (keyup.enter)="ToggleCheckedIsDelete()">
                            <input type="checkbox" [attr.tabindex]="-1" [checked]="isCheckedIsDelete"
                                name="olonoffswitche" [(ngModel)]="pdfMergeOnlineModel.IsDelete"
                                class="onoffswitch-checkbox" checked="checked" id="olisactive">
                            <label class="onoffswitch-label" for="olisactive"><span class="onoffswitch-inner"
                                    data-swchon-text="YES" data-swchoff-text="NO"></span><span
                                    class="onoffswitch-switch"></span></label>
                        </span>
                    </label>
                </fieldset>

            </div>
        </div>
        <!--Contents End Here-->
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary"
                    (click)="btnSubmitMergeOnline(frmmergeonline)" label="Insert"
                    [disabled]="!frmmergeonline.form.valid || isSubmitted"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default"
                    (click)="btnMergeOnlineCancelClick(frmmergeonline)" label="Cancel" [disabled]="isSubmitted"
                    (focusout)="dmerg.applyFocus()"></button>
            </div>
        </ng-template>
    </p-dialog>
</form>
<!----------------------------------------- Merge PDF Online------------------------------------------------------------------->
<!----------------------------------------- PDF Delete Online------------------------------------------------------------------->

<form *ngIf="isDisplayDeleteOnlineDialog" #frmpdfdelete="ngForm">
    <p-dialog *ngIf="isDisplayDeleteOnlineDialog" [(visible)]="isDisplayDeleteOnlineDialog" id="docpdfdelete"
        showEffect="fade" [modal]="true" [style]="{width: '700px'}" [resizable]="false">
        <ng-template pTemplate="header"  (keyup.shift.tab)="onKeyDeletePop()">
            {{deleteDialogTitle}}
        </ng-template>

        <div class="upload-dialog">

            <div id="smart-form" class="smart-form">

                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">From</label>
                            <label class="input">
                                <input #focus type="text" placeholder="From Page" class="input-smText" id="dfFrompage"
                                    name="deldfFrompage" [(ngModel)]="pdfDeleteModel.FromPage" required />
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">To</label>
                            <label class="input">
                                <input type="text" placeholder="To Page" class="input-smText" id="dftopage"
                                    name="dftoPage" [(ngModel)]="pdfDeleteModel.ToPage" required />
                            </label>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <!--Contents End Here-->
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-danger"
                    (click)="btnSubmitDeleteClick(frmpdfdelete)" label="Delete"
                    [disabled]="!frmpdfdelete.form.valid ||  isSubmitted"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default"
                    (click)="btnDeletePDFCancelClick(frmpdfdelete)" label="Cancel" [disabled]="isSubmitted"
                    (focusout)="applyFocusToInput()"></button>
            </div>
        </ng-template>
    </p-dialog>
</form>

<!----------------------------------------- PDF Delete Online------------------------------------------------------------------->