// Components
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
// Services
import { SharedService } from "../../../shared/services/shared.service";
import { MenuService } from "../../../shared/services/Menu/menu.service";
import "ag-grid-enterprise";
import { GridOptions, GridApi, ExcelRow } from "ag-grid-community";
import { SelectItem } from "primeng/api";
import { DashboardService } from "../../../records/services/dashboard/dashboard.service";
import { AssetService } from "../../../admin/services/asset/asset.service";
import { ContainerService } from "../../../records/services/container/container.service";
import { AssetTypes, Stats } from "../../../records/components/home/dashboard";
import { Subscription } from "rxjs";
let containerTypes: any[] = [];
import * as moment from "moment/moment";
import { DFPStatusModel } from "../../../records/components/home/dfpstatus.model";
import { stat } from "fs";

let statusDetailGridApi: GridApi;
let statusDetailGridColumnDefs: any[];
let dfpStatuses: DFPStatusModel[] = [];

@Component({
  selector: "status-dashboard",
  templateUrl: "./status-dashboard.component.html",
  providers: [MenuService, DashboardService, AssetService, ContainerService]
})
export class StatusDashboardComponent implements OnInit {
  //#region graph variables
  doughnutData: any;
  doughnutOptions: any;
  barData: any;
  public barOptions: any;
  //  pieData: any;
  //pieOptions: any;

  //#endregion

  // #region Variables
  groupDefaultExpanded = false;
  getDataPath: any;
  autoGroupColumnDef: any;
  private selectedRowData: any = null;
  name: string;
  menuList: any;
  greetinglabel: string;
  //private recordMasterGridData: any[] = [];
  public statusgridData: any[] = [];
  public checked2: boolean
  public statusgridOptions: GridOptions;
  columnDefs: any[];
  statuscolumnDefs: any[];
  commAssignedToMe: any[] = [];
  commRequestedByMe: any[] = [];
  getRowNodeId: any;
  getDetailRowNodeId: any;
  assignedtomeCount = 0;
  requestedbymeCount = 0;
  commId = "";

  // childdata: any[] = [];
  public api: GridApi;
  public isEditVisible = true;
  defaultYearString = "Past 12 Months";
  selectedYear: string = this.defaultYearString;
  assetsTypes: AssetTypes;
  statsData: Stats[] = [];
  components: any;
  recordLastUpdated = "";
  containerLastUpdated = "";
  statsLastUpdated = "";

  statusDetailGridOptions: GridOptions;
  statusDetailGridColumnDefs: any[];
  subscription: Subscription;
  isNavigationAllowed = false;

  // userAssets: any;
  statsChartdata: any;
  storageCapacity: number;

  updatingRecord = false;
  updatingStatus = false;
  updatingStats = "";
  detailRowHeight = 230;

  userAssets: SelectItem[] = [];
  public selectedAsset: any;
  public selectedComplianceStatus: any;
  public excelStyles: any;
  statusToggle: any = false;
  public statusToggleTypes: any[] = [];
  canViewDashboard: boolean = false;

  // #endregion

  constructor(
    public _sharedService: SharedService,
    private _router: Router,
    private _dashboardService: DashboardService,
    private _assetService: AssetService,
    private _containerService: ContainerService
  ) {
    this.getDFPStatus();
    this.getRowNodeId = function (data: any) {
      return data.AssetId;
    };

    this.autoGroupColumnDef = {
      cellRenderer: "agGroupCellRenderer",
      headerName: "Asset",
      width: 180,
      pinned: "left",
      menuTabs: ["filterMenuTab"],
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: this.recordGridGroupRenderer
      }
    };

    this.getDataPath = function (data: any) {
      return data.ASNTree;
    };

    this.getDetailRowNodeId = function (data: any) {
      return data.DFPStatus;
    };
  }

  ngOnInit() {
    this.canViewDashboard = false;
    this.statusToggle = 'Fleet';
    this.statusToggleTypes = [];
    this.statusToggleTypes.push({ label: 'Fleet', value: 'Fleet' });
    this.statusToggleTypes.push({ label: 'Asset', value: 'Asset' });
    this._sharedService._StatusToggleValue
      .subscribe({
        next: (selValue: any) => {
          if (selValue == 'Fleet') {
            this.populateDashboardStatusListingGrid();
          }
          else {
            this.populateDashboardStatusAssetListingGrid();

          }
        }
      })

    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(["login"]);
    } else {
      this._sharedService.showLoader("Processing...");
      this.getDFPStatus();
      this._sharedService.setAssetControlVisible(false);
      this.UpdateRoles(null);
      this.getAsset();

      this._sharedService.getDateTimeFormatConfiguration();

      this.statusDetailGridOptions = {
        rowSelection: "single",
        onGridReady(params) {
          if (params.api) {
            statusDetailGridApi = params.api;
            params.api.setColumnDefs(statusDetailGridColumnDefs);
          }
        },
        getRowNodeId: this.getDetailRowNodeId,
        getContextMenuItems: this.getCustomContextMenuItemsStatusDetail,
        context: {
          detailGridCtx: this
        },
        defaultColDef: {
          sortable: true,
          resizable: true,
          filter: true
        }
      } as GridOptions;

      this.statusgridOptions = {
        rowSelection: "single",
        onGridReady: () => { },
        onModelUpdated: (event: any) => {
          this.selectFirstRow();
          if (this.statusgridOptions.api != undefined) {
            this.statusgridOptions.api.sizeColumnsToFit();
          }
        },
        masterDetail: true,
        detailCellRendererParams: {
          // provide detail grid options
          detailGridOptions: this.statusDetailGridOptions,
          // extract and supply row data for detail
          getDetailRowData(params: any) {
            params.successCallback([]);
          }
        },
        context: {
          statusGridCtx: this,
          gridOptions: this.statusgridOptions
        },
        getContextMenuItems: this.getCustomContextMenuItemsStatus,
        defaultColDef: {
          sortable: true,
          resizable: true,
          filter: true
        }
      } as GridOptions;

      this.populateDashboardStatusListingGrid();
      //prevent multiple notifications to stack
      this.subscription = this._sharedService._selectedAssetStatus
        .subscribe({
          next: (selAsset: any) => {
            this.UpdateRoles(selAsset);

            if ((this._router.url.indexOf("status/") != -1 && this._sharedService.UserRole["Status_Readonly"]) ||
              (this._router.url.indexOf("repair/") != -1 && this._sharedService.UserRole["Repairs_Readonly"])) {
              this.selectedAsset = (selAsset && selAsset.length) > 0 ? selAsset[0] : null;
              this._sharedService.selectedAsset = selAsset;
              this.populateDashboardStatusListingGrid();
            }
            else if (this._router.url.indexOf("status/") != -1 || this._router.url.indexOf("repair/") != -1) {
              this._sharedService.clearToast();
              var notAllowedMsg = 'Access denied';
              if (selAsset && selAsset.length > 0 && selAsset[0].data) {
                let assetDataObj = JSON.parse(selAsset[0].data);
                notAllowedMsg = 'This asset is on ' + assetDataObj.PlanName + ', please contact administrator to upgrade plan for this asset.';//PlanName
                //this._sharedService.showToast({ severity: 'warn', summary: 'Access denied', detail: notAllowedMsg });
                setTimeout(() => {
                  this._sharedService.showToast({ severity: 'warn', summary: 'Access denied', detail: notAllowedMsg });
                  console.log("Delayed status-dashboard.");
                }, 500);
              }
            }
            else {
              this._sharedService.clearToast();
            }
          }
        });


    }
    this.setExcelStyle();
  }

  //#region Roles
  UpdateRoles(selAsset: any) {
    // if (!this._sharedService.UserRole) {
    //   this._sharedService.updateRole();
    // }
    if (selAsset && selAsset[0]) {
      this._sharedService.updateRoleBySelectedAsset(selAsset[0]);
    }
    else {
      this._sharedService.updateRole();
    }

    if (this._router.url.indexOf("status/") != -1) {
      this.canViewDashboard = this._sharedService.UserRole.Dashboard_Status;
    }
    else if (this._router.url.indexOf("repair/") != -1) {
      this.canViewDashboard = this._sharedService.UserRole.Dashboard_Repairs;
    }

  }
  //#endregion


  // #region Status Grid

  selectFirstRow() {
    if (this.statusgridData.length && this.statusgridOptions.api) {
      this.statusgridOptions.api.getDisplayedRowAtIndex(0).setSelected(true);
      let selRow = this.statusgridOptions.api.getSelectedRows();
      this.selectedRowData = selRow[0];
    }
  }

  populateDashboardStatusListingGrid(): void {
    this.statusgridData = [];
    let viewType = (this._router.url.indexOf("status/") != -1) ? 'Status' : 'Repair';
    this._dashboardService.getStatusParentData(viewType)
      .subscribe({
        next: (response) => {
          // if (this._sharedService.selectedAssetStatus != null && this._sharedService.selectedAssetStatus.length > 0) {
          //   this._sharedService.showHideStatusMenu();
          // }
          this._sharedService.showHideStatusMenu();

          this._sharedService.hideLoader();
          const result = response;
          let data: any;
          let selectedASN = null;
          if (
            this._sharedService.selectedAsset != null &&
            this._sharedService.selectedAsset.length > 0
          ) {
            //US-8181 //const selectedAsset = this._sharedService.selectedAsset[0];
            // const assetInfo: string[] = selectedAsset.data.split("|"); // selectedAsset[0].data.split('|');
            // selectedASN = assetInfo[1];

            selectedASN = JSON.parse(this._sharedService.selectedAsset[0].data).ASN;
            data = result[0].filter(r => r.ASN == selectedASN);
            data = this._dashboardService.filterAndAggregateDFPStatusDataOfASN(data);
            this.statusgridOptions.masterDetail = false;
            if (this.statusgridOptions.api) { this.statusgridOptions.api.refreshClientSideRowModel(); }
            //this.ShowHideIconStatusGridOptions(true);
          } else {
            this.statusgridOptions.masterDetail = true;
            if (this.statusgridOptions.api) { this.statusgridOptions.api.refreshClientSideRowModel(); }
            //this.ShowHideIconStatusGridOptions(false);
            data = [];
            data = this._dashboardService.filterAndAggregateDFPStatusData(
              result[0]
            );
          }
          this.AddOrphanDFPStatusAsBlack(data);
          this.statusgridData = data; // result[0];
          this.containerLastUpdated = result[1];

          // this.drawDonutGraph(result[0]);
          this.drawDonutGraph(data);
          try {
            if (this.statusgridData.length > 0) {
              this.setDashboardStatusGridColumns(
                Object.getOwnPropertyNames(this.statusgridData[0]), selectedASN
              );
              this.prepareBarGraph(true);
            }
            else {
              this.prepareBarGraph(false);
              this.statuscolumnDefs = [];
            }

          } catch (e) {
            //this.prepareBarGraph();
            if (this.statusgridData.length > 0) {
              this.prepareBarGraph(true);
            }
            else {
              this.prepareBarGraph(false);
              this.statuscolumnDefs = [];
            }
          }
        },
        error: (error) => {
          this._sharedService.hideLoader();
          console.log(error);
        }
      });
  }
  refreshDashboardStatusListingGrid(): void {
    let selectedASN = "";
    if (
      this._sharedService.selectedAsset != null &&
      this._sharedService.selectedAsset.length > 0
    ) {
      //US-8181 // const selectedAsset = this._sharedService.selectedAsset[0];
      // const assetInfo: string[] = selectedAsset.data.split("|"); // selectedAsset[0].data.split('|');
      // selectedASN = assetInfo[1];
      selectedASN = JSON.parse(this._sharedService.selectedAsset[0].data).ASN;
    }
    this.updatingStatus = true;
    this.statusgridData = [];
    let viewType = (this._router.url.indexOf("status/") != -1) ? 'Status' : 'Repair';
    this._dashboardService.refreshStatusData(selectedASN, viewType)
      .subscribe({
        next: (response) => {
          //let viewType = (this._router.url.indexOf("status/") != -1) ? 'Status' : 'Repair';
          // this._dashboardService.getStatusParentData(viewType).subscribe(
          //   response => {

          const result = response;
          let data: any;
          if (
            this._sharedService.selectedAsset != null &&
            this._sharedService.selectedAsset.length > 0
          ) {
            let selectedASN = null;
            // US-8181 //// const selectedAsset = this._sharedService.selectedAsset[0];
            // const assetInfo: string[] = selectedAsset.data.split("|");
            // selectedASN = assetInfo[1];
            selectedASN = JSON.parse(this._sharedService.selectedAsset[0].data).ASN;
            data = result[0].filter(r => r.ASN == selectedASN);
            data = this._dashboardService.filterAndAggregateDFPStatusDataOfASN(data);
            this.statusgridOptions.masterDetail = false;
            if (this.statusgridOptions.api) { this.statusgridOptions.api.refreshClientSideRowModel(); }
            //this.ShowHideIconStatusGridOptions(true);
          } else {
            this.statusgridOptions.masterDetail = true;
            if (this.statusgridOptions.api) { this.statusgridOptions.api.refreshClientSideRowModel(); }
            //this.ShowHideIconStatusGridOptions(false);
            data = [];
            data = this._dashboardService.filterAndAggregateDFPStatusData(
              result[0]
            );
          }
          this.AddOrphanDFPStatusAsBlack(data);
          this.updatingStatus = false;
          this.statusgridData = data; // result[0];
          this.containerLastUpdated = result[1];
          try {
            if (this.statusgridData.length > 0) {
              this.drawDonutGraph(data); // (result[0]);
              this.setDashboardStatusGridColumns(
                Object.getOwnPropertyNames(this.statusgridData[0]), selectedASN
              );
              this.prepareBarGraph(true);
            }
            else {
              this.statuscolumnDefs = [];
              this.prepareBarGraph(false);
            }
          } catch (e) {
            //this.prepareBarGraph(false);

            if (this.statusgridData.length > 0) {
              this.prepareBarGraph(true);
            }
            else {
              this.statuscolumnDefs = [];
              this.prepareBarGraph(false);
            }
          }
          //   },
          //   error => {
          //     this._sharedService.hideLoader();
          //     console.log(error);
          //   }
          // );
        },
        error: (error) => {
          console.log(error);
          this.updatingStatus = false;
        }
      });
  }

  setDashboardStatusGridColumns(columnNames: string[], selectedASN?: any): void {
    this.statuscolumnDefs = [];
    let arr = [];
    for (let i = 0; i < columnNames.length; i++) {
      let a = this._sharedService.selectedASNViews.find(c => c == columnNames[i]);
      if (a != null) {
        arr.push(columnNames[i]);
      }
    }

    columnNames = columnNames.filter(c => c != "ASN");
    if (!selectedASN) {
      for (let i = 0; i < columnNames.length; i++) {
        // let headerName =
        //   columnNames[i].indexOf("_") > 0
        //     ? columnNames[i].substring(columnNames[i].indexOf("_") + 1)
        //     : columnNames[i];
        let headerName = columnNames[i]; //columnNames[i].indexOf("_") > 0 ? columnNames[i].substring(columnNames[i].indexOf("_") + 1) : columnNames[i];
        //if asset is selected
        // if (!selectedASN) {
        if (columnNames[i] == "DFPStatus") {
          headerName = 'Compliance Status';
          this.statuscolumnDefs.push({
            headerName,
            field: columnNames[i],
            menuTabs: ["filterMenuTab"],
            width: 267,
            pinned: "left",
            cellRenderer: "agGroupCellRenderer",
            cellRendererParams: {
              suppressCount: false, // turn off the row count
              checkbox: false, // enable checkbox selection
              padding: 10,
              innerRenderer: this.statusDFPInnerCellRenderer, // provide an inner renderer
              // footerValueGetter: myFooterValueGetter // provide a footer value getter
              //cellRenderer: this.statusEditorCellRenderer
            }
          });
        } else {
          if (
            this.statuscolumnDefs.filter(c => c.headerName == headerName).length >
            0
          ) {
            this.statuscolumnDefs.push({
              headerName: headerName + i,
              field: columnNames[i],
              menuTabs: ["filterMenuTab"],
              // cellEditorParams: {
              //   cellRenderer: this.statusEditorCellRenderer
              // }
              //minwidth: 90
            });
          } else {
            this.statuscolumnDefs.push({
              headerName,
              field: columnNames[i],
              menuTabs: ["filterMenuTab"],
              // cellEditorParams: {
              //   cellRenderer: this.statusEditorCellRenderer
              // }
              //minwidth: 90
            });
          }
        }
        //End of if asset is selected
      }
    }
    else {

      for (let i = 0; i < arr.length; i++) {
        let headerName = arr[i];
        if (arr[i] == "DFPStatus") {
          headerName = 'Compliance Status';
          this.statuscolumnDefs.push({
            headerName,
            field: arr[i],
            menuTabs: ["filterMenuTab"],
            width: 267,
            pinned: "left",
            cellRenderer: this.statusDFPInnerCellRenderer,
            // cellRendererParams: {
            //   suppressCount: false, // turn off the row count
            //   checkbox: false, // enable checkbox selection
            //   padding: 10,
            //   innerRenderer: this.statusDFPInnerCellRenderer, // provide an inner renderer
            //   // footerValueGetter: myFooterValueGetter // provide a footer value getter
            //   //cellRenderer: this.statusEditorCellRenderer
            // }
          });
        }
        else {
          this.statuscolumnDefs.push({
            headerName,
            field: arr[i],
            menuTabs: ["filterMenuTab"],
            // cellEditorParams: {
            //   cellRenderer: this.statusEditorCellRenderer
            // }
            //minwidth: 90
          });
        }
      }
    }

    this.setDetailGridColumns();
  }
  populateStatusDetailGrid(param: any) {
    // if (this.statusgridOptions.masterDetail === false) {
    //   //this.statusDetailGridOptions = null;
    //   //this.statusgridOptions.detailCellRendererParams.detailGridOptions = null;

    //   //statusDetailGridApi.showNoRowsOverlay();
    //   //statusDetailGridApi.setRowData([]);
    //   //statusDetailGridApi = null;

    //   return;
    // }
    if (param.node.expanded && param.data && param.data.DFPStatus) {
      const dfpStatus: string = param.data.DFPStatus;
      this._sharedService.showLoader("Processing...");
      this._dashboardService.getStatusChildData(dfpStatus.trim())
        .subscribe({
          next: (response) => {
            let result = response;
            if (
              this._sharedService.selectedAsset != null &&
              this._sharedService.selectedAsset.length > 0
            ) {
              let selectedASN = "";
              //US-8181 // const selectedAsset = this._sharedService.selectedAsset[0];
              // const assetInfo: string[] = selectedAsset.data.split("|"); // selectedAsset[0].data.split('|');
              // selectedASN = assetInfo[1];
              selectedASN = JSON.parse(this._sharedService.selectedAsset[0].data).ASN;
              result = result.filter(r => r.ASN == selectedASN);
            }
            statusDetailGridApi.setRowData(result);
            this._sharedService.hideLoader();
          },
          error: (error) => {
            statusDetailGridApi.setRowData([]);
            statusDetailGridApi.showNoRowsOverlay();
            console.log(error);
          }
        });
    }
  }

  setDetailGridColumns() {
    statusDetailGridColumnDefs = [];
    statusDetailGridColumnDefs.push({
      headerName: "Asset",
      field: "ASN",
      menuTabs: ["filterMenuTab"],
      width: 266,
      pinned: "left",
      cellRenderer: this.recordGridGroupRenderer
    });

    for (let i = 1; i < this.statuscolumnDefs.length; i++) {
      // startig from index 1 ignore 1st column element
      statusDetailGridColumnDefs.push(this.statuscolumnDefs[i]);
    }
  }

  statusDFPInnerCellRenderer(params: any) {
    let eSpan = document.createElement("span");
    if (params.value) {
      const dfpStatusName: string = params.value.trim();
      const currentStatus: DFPStatusModel = dfpStatuses.filter(
        x => x.Name == dfpStatusName
      )[0];
      if (currentStatus && currentStatus.Name) {
        if (
          currentStatus.Name == "Suggested - no document found" ||
          currentStatus.Name == "Suggested - partial documents found" ||
          currentStatus.Name == "Suggested - documents found" ||
          currentStatus.Name == "Suggested - not applicable"
        ) {
          eSpan.style.color = currentStatus.CSSClass;
        } else {
          eSpan.style.color = "white";
          eSpan.style.backgroundColor = currentStatus.CSSClass;
        }
      }
    }

    eSpan.style.padding = "3px";
    eSpan.innerHTML = params.value.trim();
    eSpan.style.textAlign = "center";
    return eSpan;
  }

  // #endregion

  // #region Record Grid

  recordGridGroupRenderer(params: any) {
    let eSpan = document.createElement("span");
    if (params.value) {
      const type = params.data.AssetType;

      switch (type) {
        case "Airframe":
          eSpan.className = "fa fa-plane";
          break;
        case "APU":
          eSpan.className = "icon-APU2";
          break;
        case "Engine":
          eSpan.className = "icon-Engine";
          break;
        case "LDG":
          eSpan.className = "icon-landinggear";
          break;
        case "Propeller":
          eSpan.className = "fa icon-propeller";
          break;
        case "Collection":
          eSpan.className = "fa fa-cubes";
          break;
        case "Item":
          eSpan.className = "fa fa-cube";
          break;
        default:
          break;
      }

      eSpan.innerHTML =
        "<span style='margin-left: 5px;'>" + params.value + "</span>";
    }
    return eSpan;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onRowClicked(event: any) {
    this.selectedRowData = event.data;
    //this.drawPieChart(this.selectedRowData);
  }
  showDounetChart = false;
  drawDonutGraph(data: any) {
    const columnNames: string[] = [];
    let indexedArray: { [key: string]: number } = {};
    let keyValuePairArray: any;
    keyValuePairArray = [];

    const distinctLabels: string[] = [];
    const allLabels: string[] = [];
    const graphData: number[] = [];
    const colorCodes: string[] = [];

    let chartId = document.getElementById('DFPStatusDoughnutChartId');
    if (chartId) {
      document.getElementById('DFPStatusDoughnutChartId').parentElement.style.height = '55vh';
      // document.getElementById('DFPStatusDoughnutChartId').parentElement.style.height = chartId.style.height;
      document.getElementById('DFPStatusDoughnutChartId').parentElement.style.overflowY = 'auto';
      document.getElementById('DFPStatusDoughnutChartId').style.height = null;
    }
    keyValuePairArray = [];
    keyValuePairArray = this._dashboardService.filterAndAggregateAllDFPStatusData(
      data
    );
    keyValuePairArray.sort((a, b) => a.value - b.value);
    keyValuePairArray.forEach(obj => {
      allLabels.push(obj.key);
      distinctLabels.push(obj.key);
      graphData.push(obj.value);
      let _color = this.getDFPStatusColor(obj.key);
      colorCodes.push(_color);
    });
    this.doughnutData = {
      labels: distinctLabels,
      datasets: [
        {
          data: graphData,
          backgroundColor: colorCodes,
          hoverBackgroundColor: colorCodes
        }
      ]
    };
    let colors = []
    distinctLabels.forEach((val, index) => {
      let dfpData = dfpStatuses.filter(x => x.Name == val)[0];
      if (dfpData) {
        colors.push(dfpData.CSSClass)
      }
      else {
        let randomColor = this.randomColor();
        colors.push(randomColor)
      }
    })
    this.doughnutOptions = {
      data: keyValuePairArray,
      legend: {
        position: "bottom",
      },
      series: [
        {
          calloutLabel: { enabled: false },
          type: 'pie',
          calloutLabelKey: 'key',
          angleKey: 'value',
          innerRadiusOffset: -70,
          fills: colors,
          strokes: colors,
        },
      ],

    };

    this.showDounetChart = true;
  }

  statusEditorCellRenderer(params: any) {
    return { color: 'red', 'font-size': '52px !important' };
    // let html: string = '';
    // var eDiv = document.createElement('div');
    // if (params.value) {
    //     let jobStatusName: string = params.value.trim();
    //     let currentStatus:any= statusList.filter(x => x.Description == jobStatusName)[0];
    //     if (currentStatus && currentStatus.Description) {
    //         eDiv.innerHTML = html + jobStatusName;
    //         eDiv.style.backgroundColor = currentStatus.ColorCode;
    //     }

    // }
    // else {
    //     return params.value;
    // }

    // eDiv.style.height = '100%';
    // eDiv.style.width = '100%';
    // eDiv.style.color = 'white';
    // eDiv.style.textAlign = 'center';
    // eDiv.style.paddingTop = '5px';
    // eDiv.style.paddingLeft = '5px';
    // eDiv.style.textAlign = 'left';
    // return eDiv;
  }
  getDFPStatusColor(stausName: string): string {
    const status = dfpStatuses.filter(x => x.Name == stausName);
    if (status && status.length > 0) {
      return status[0].CSSClass;
    } else {
      return "";
    }
  }

  getRandomColor(): string {
    let hexColor = "";
    while (hexColor.length < 7) {
      hexColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
    }
    // return '#' + Math.floor(Math.random() * 16777215).toString(16);
    return hexColor;
  }

  typeClicked(selectedValue: string, containerID: string) {
    this.isNavigationAllowed =
      this.checkUserRoles() && this.checkUserPermissionForScreen(selectedValue);
    if (this.isNavigationAllowed) {
      this._assetService
        .getAssetTreeNode(this.selectedRowData.Aircraft)
        .subscribe({
          next: (response) => {
            // Set Selected Asset
            this._sharedService.selectedAsset = [];
            this._sharedService.selectedAsset.push(response);
            this._sharedService.isNavigationFilterCanceled = true;
            // Get Container Details
            const containerId = Number(containerID);
            const categoryTypes = containerTypes.filter(
              x => x.Type == selectedValue
            );
            let category = categoryTypes[0].Category;
            if (!category) {
              category = "MXR";
            }
            switch (category) {
              case "MXR":
                this._sharedService.setRouteInfoAndNavigateFromDashboard({
                  MenuID: 16,
                  PageURL: "/record/browse",
                  cat: "mxr",
                  id: containerId,
                  containerType: null,
                  ColorCode: "bg-color-msgreen"
                });
                break;
              case "LOG":
                this._sharedService.setRouteInfoAndNavigateFromDashboard({
                  MenuID: 16,
                  PageURL: "/record/browse",
                  cat: "logs",
                  id: containerId,
                  containerType: null,
                  ColorCode: "bg-color-msgreen"
                });
                break;
              case "GEN":
                this._sharedService.setRouteInfoAndNavigateFromDashboard({
                  MenuID: 16,
                  PageURL: "/record/browse",
                  cat: "general",
                  id: containerId,
                  containerType: null,
                  ColorCode: "bg-color-msgreen"
                });
                break;
              case "BND":
                this._sharedService.setRouteInfoAndNavigateFromDashboard({
                  MenuID: 113,
                  PageURL: "/transition/binders",
                  cat: "binders",
                  id: containerId,
                  containerType: null,
                  ColorCode: "bg-color-greenpea"
                });
                break;
            }
          }
        });
    } else {
      this._sharedService.clearToast();
      this._sharedService.showToast({
        severity: "warn",
        summary: "Restricted Access",
        detail: "You are not allowed to view details."
      });
    }
  }

  // #endregion

  // #region Comms Region

  getDateFormat(date: any) {
    const dateString = date.substr(0, 10);
    return moment(dateString)
      .format("DD-MM-YYYY")
      .toString();
  }
  getDateFormatTime(date: any) {
    if (date) {
      var dateConfig = this._sharedService.getDateTimeFormatConfiguration();
      return moment(date).format(dateConfig.dateformat.toUpperCase() + " h:mm a").toString();

    } else {
      return "";
    }
  }

  //#endregion
  barChart = false;
  // #region Bar Graph
  prepareBarGraph(hasData: Boolean) {
    if (hasData) {
      this.barData = {
        labels: this.statuscolumnDefs
          .filter(c => c.headerName != "DFPStatus" && c.headerName != "ASN" && c.headerName != "Compliance Status")
          .map(col => col.headerName.replace(/[_]/g, "-")), // ['AD', 'MOD', 'OCCM', 'SB', 'HT'],
        datasets: this.getBarChartDataset()
      };
    }
    else {
      this.barData = {
        labels: [],
        datasets: []
      };
    }


    var percentageResult: any = this._dashboardService.filterAndAggregateAllViewWithDFPStatusDataInPercentage(this.statusgridData);
    var yAxisArray = [];
    percentageResult.forEach((element: any) => {
      yAxisArray.push(element.DFPStatus)
    })
    var data = this.prepareAgGridChartData(this.barData)
    let colors = []
    yAxisArray.forEach((val, index) => {
      let dfpData = dfpStatuses.filter(x => x.Name == val)[0]
      if (dfpData) {
        colors.push(dfpData.CSSClass)
      }
      else {
        let randomColor = this.randomColor();
        colors.push(randomColor)
      }
    })
    this.barChart = true;
    this.barOptions = {
      data: data,
      series: [
        {
          type: 'column',
          xKey: "viewName",
          yKey: yAxisArray,
          grouped: true,
          fills: colors,
          strokes: colors,
        },
      ],
      tooltip: {
        enabled: true,
        delay: 0
      },
      axes: [
        {
          type: 'category',
          position: 'bottom',
          label: { rotation: 290 },
        },
        {
          type: 'number',
          position: 'left',
          tick:{
            interval: 10
          },
          label: {
            formatter: function (params) {
              return params.value;
            },
          },
        },
      ],
      legend: {
        position: "bottom",
      },
    };
  }
  prepareAgGridChartData(obj) {
    let chartData = [];
    let labelValue = null;
    obj.labels.forEach((element, labelsIndex) => {

      labelValue = (element.length > 10) ? element.substring(0, 7) + "..." : element;

      var currentElement = {

        viewName: labelValue
      }
      obj.datasets.forEach((dataSetsElement, index) => {
        currentElement[dataSetsElement.label] = dataSetsElement.data[labelsIndex];
      });
      chartData.push(currentElement)
    });
    return chartData;

  }

  randomColor() {
    return Math.floor(Math.random() * 16777215).toString(16);
  }

  getBarChartDataset(): any[] {
    const chartDataset: any[] = [];

    var percentageResult = this._dashboardService.filterAndAggregateAllViewWithDFPStatusDataInPercentage(this.statusgridData);

    // for (let i = 0; i < this.statusgridData.length; i++) {
    for (let i = 0; i < percentageResult.length; i++) {
      //let itemData: any[] = (Object as any).values(this.statusgridData[i]);
      //  let _item = this.statusgridData[i];
      let _item = percentageResult[i];
      let itemData: any[] = (Object as any).values(_item);

      itemData = itemData.map(val => (val == null ? 0 : val));


      const color = this.getDFPStatusColor(itemData[0].trim());
      // Totals List

      const totalsList = [];

      for (let index = 1; index < itemData.length; index++) {
        totalsList.push(itemData[index]);
      }

      const chartDataItem = {
        label: itemData[0].trim(),
        backgroundColor: color,
        borderColor: color,
        data: totalsList,
        hoverBackgroundColor: color,
        hoverBorderColor: color,
        barPercentage: 0.5
      };
      chartDataset.push(chartDataItem);
    }

    return chartDataset;
  }

  // #endregion

  //#region User Roles
  checkUserRoles(): boolean {
    let status = false;

    if (this.userAssets && this.userAssets.length > 0) {
      const result = this.userAssets.filter(
        x => x.value.split("|")[1] == this.selectedRowData.Aircraft
      );
      if (result && result.length > 0) {
        status = true;
      }
    }

    return status;
  }

  checkUserPermissionForScreen(selectedType: string): boolean {
    let status = false;

    if (containerTypes && containerTypes.length > 0) {
      const result = containerTypes.filter(x => x.Type == selectedType)[0]
        .Category;
      if (result) {
        switch (result) {
          case "MXR":
            status = this._sharedService.UserRole.Records_MXR_Readonly;
            break;
          case "LOG":
            status = this._sharedService.UserRole.Records_LOG_Readonly;
            break;
          case "GEN":
            status = this._sharedService.UserRole.Records_GEN_Readonly;
            break;
        }
      }
      return status;
    }

    return status;
  }
  getAsset() {
    this._assetService.getAssetByUserRole()
      .subscribe({
        next: (response) => {
          this.userAssets = response;
        },
        error: (error) => {
          this.userAssets = [];
          console.log(error);
        }
      });
  }
  //#endregion

  //#region DFP Status
  getDFPStatus() {
    this._dashboardService.getAllDFPStatuses()
      .subscribe({
        next: (response) => {
          dfpStatuses = [];
          dfpStatuses = response;
        }
      });
  }

  AddOrphanDFPStatusAsBlack(data: any[]) {
    data.forEach(element => {
      var _result = dfpStatuses.filter(r => r.Name == element.DFPStatus);
      if (_result == null || _result.length == 0) {
        var newOrphanDFPId =
          Math.max.apply(
            Math,
            dfpStatuses.map(function (o) {
              return o.DFPStatusID;
            })
          ) + 1;
        var newOrphanDFP = {
          DFPStatusID: newOrphanDFPId,
          Name: element.DFPStatus,
          Description: null,
          CSSClass: "#000000",
          Status: true
        };
        dfpStatuses.push(newOrphanDFP);
      }
    });
  }

  //#endregion

  //#region Grid Context Menu Status
  getCustomContextMenuItemsStatus(params: any): any {
    const result: any[] = [];

    result.push({
      name: "Refresh",
      action() {
        params.context.statusGridCtx.refreshDashboardStatusListingGrid();
      },
      icon: params.context.statusGridCtx._sharedService.refreshIconHTMLasString
    });

    return result;
  }
  getCustomContextMenuItemsStatusDetail(params: any): any {
    const result: any[] = [];

    result.push({
      name: "Refresh",
      action() {
        params.context.detailGridCtx.refreshDashboardStatusListingGrid();
      },
      icon: params.context.detailGridCtx._sharedService.refreshIconHTMLasString
    });

    return result;
  }
  //#endregion

  ShowHideIconStatusGridOptions(isSingleAssetSelected) {
    //Add the class to body tag when the View is initialized
    if (isSingleAssetSelected) {
      this.statusgridOptions.api.forEachNode(node => {
        node.expanded = false;
      });
      this.statusgridOptions.api.onGroupExpandedOrCollapsed();

      setTimeout(() => {
        let span = document.getElementsByTagName("span");
        let spanlength = document.getElementsByTagName("span").length;
        let i = 0;
        for (i = 0; i < spanlength; i++) {
          if (span[i].className === "ag-icon ag-icon-expanded") {
            //old theme grid
            span[i].classList.remove("ag-icon-expanded");
          }
          if (span[i].className === "ag-icon ag-icon-contracted") {
            //new theme grid
            span[i].classList.remove("ag-icon-contracted");
          }
        }
      }, 500);
    }
  }
  setExcelStyle() {
    this.excelStyles = [
      {
        id: "header",
        interior: {
          color: "#CCCCCC",
          pattern: "Solid"
        }
      },
      {
        id: "twoDecimalPlaces",
        numberFormat: { format: "#,##0.00" }
      },
      {
        id: "textFormat",
        dataType: "string"
      },
      {
        id: "bigHeader",
        font: {
          size: 18,
          bold: true
        },
        alignment: {
          horizontal: 'Center', vertical: 'Center'
        }
      }
    ];
  }
  onStatusGridExport() {
    let headerString = null
    if (this._sharedService.selectedAsset && this._sharedService.selectedAsset.length > 0) {
      headerString = '[{"cells":[{"styleId":"bigHeader","data":{"type":"String","value":"{TenantName}"}},{"styleId":"bigHeader","data":{"type":"String","value":"{ExcelTitle}"},"mergeAcross":5},{"styleId":"bigHeader","data":{"type":"String","value":"ROAM"}}]},{"cells":[{"styleId":"","data":{"type":"String","value":"ASN:"}},{"styleId":"","data":{"type":"String","value":"{ASN}"}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":"Date/Time:"}},{"styleId":"","data":{"type":"String","value":"{DateTime}"}}]},{"cells":[{"styleId":"","data":{"type":"String","value":"Filter:"}},{"styleId":"","data":{"type":"String","value":"{filter}"}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":"User:"}},{"styleId":"","data":{"type":"String","value":"{UserName}"}}]},{ "cells": [] }]'

      headerString = headerString.replace('{ASN}', this._sharedService.selectedAsset[0].label);

    }
    else {
      headerString = '[{"cells":[{"styleId":"bigHeader","data":{"type":"String","value":"{TenantName}"}},{"styleId":"bigHeader","data":{"type":"String","value":"{ExcelTitle}"},"mergeAcross":5},{"styleId":"bigHeader","data":{"type":"String","value":"ROAM"}}]},{"cells":[{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":"Date/Time:"}},{"styleId":"","data":{"type":"String","value":"{DateTime}"}}]},{"cells":[{"styleId":"","data":{"type":"String","value":"Filter:"}},{"styleId":"","data":{"type":"String","value":"{filter}"}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":"User:"}},{"styleId":"","data":{"type":"String","value":"{UserName}"}}]},{ "cells": [] }]'

    }
    headerString = headerString.replace('{TenantName}', this._sharedService.tenantName);
    headerString = headerString.replace('{UserName}', this._sharedService.getCurrentUserName());
    headerString = headerString.replace('{DateTime}', this.getFormatedDate(new Date()));
    headerString = headerString.replace('{filter}', this.getFilterStatusString());
    var fileName = null;
    if (this.statusToggle == 'Asset' || this.selectedAsset) {
      headerString = headerString.replace('{ExcelTitle}', "Asset - Compliance Status Report");
      fileName = 'Asset-Status Report-' + moment(Date.now()).format('YYYYMMDD') + '.xlsx'
    }
    else {
      headerString = headerString.replace('{ExcelTitle}', "Fleet - Compliance Status Report");
      fileName = 'Fleet-Status Report-' + moment(Date.now()).format('YYYYMMDD') + '.xlsx'
    }
    var params: any = {
      columnWidth: function (params) {
        // var originalWidth = params.column.actualWidth;
        if (params.index == 0) {
          return 200;
        }
        return 70;
      },
      olumnBestFit: true,
      skipColumnGroupHeaders: false,
      allColumns: false,
      onlySelected: false,
      fileName: fileName,
      sheetName: 'Report',
    };
    const getRows: () => ExcelRow[] = () => JSON.parse(headerString);
    params.prependContent = getRows();
    var api = this.statusgridOptions.api;
    api.exportDataAsExcel(
      params
    );
  }
  getFormatedDate(date: any) {
    if (date) {
      return moment(date).format(this._sharedService.getDateTimeFormatConfiguration().dateformat.toUpperCase());
    } else {
      return '';
    }
  }
  // getFilterStatusString(): string {
  //   let filterModel = this.statusgridOptions.api.getFilterModel();
  //   let filterString = '';
  //   for (var filter in filterModel) {
  //     if(filter == 'DFPStatus_1' || filter == 'DFPStatus'){
  //       filter='Compliance Status'
  //     }
  //       filterString = filterString.concat(filter, ', ');
  //   }

  //   return filterString ? filterString.slice(0, -2) : 'No';
  // }


  getFilterStatusString(): string {
    let filterModel = this.statusgridOptions.api.getFilterModel();
    let filterString = '';
    for (var filter in filterModel) {
      let isEndsWith_1 = filter.endsWith('_1')
      if (isEndsWith_1) {
        let newValue = filter.replace('_1', '')
        let filterValue = this.getFilterFieldValue(newValue);
        if (filterValue == '') {
          filterString = filterString.concat(filter, ', ');
        }
        else {
          filterString = filterString.concat(filterValue, ', ');
        }
      }
      else {
        filterString = filterString.concat(filter, ', ');
      }

    }

    return filterString ? filterString.slice(0, -2) : 'No';
  }
  getFilterFieldValue(value) {
    let filterValue = this.statuscolumnDefs.find(({ field }) => field === value);
    if (filterValue) {
      return filterValue.field;
    }
    else {
      return '';
    }

  }


  populateDashboardStatusAssetListingGrid(): void {
    this.statusgridData = [];
    this._dashboardService.getStatusParentData()
      .subscribe({
        next: (response) => {
          // if (this._sharedService.selectedAssetStatus != null && this._sharedService.selectedAssetStatus.length > 0) {
          //   this._sharedService.showHideStatusMenu();
          // }
          this._sharedService.showHideStatusMenu();

          this._sharedService.hideLoader();
          const result = response;
          let data: any;
          let selectedASN = null;
          if (
            this._sharedService.selectedAsset != null &&
            this._sharedService.selectedAsset.length > 0
          ) {
            //US-8181 //// const selectedAsset = this._sharedService.selectedAsset[0];
            // const assetInfo: string[] = selectedAsset.data.split("|"); // selectedAsset[0].data.split('|');
            // selectedASN = assetInfo[1];
            selectedASN = JSON.parse(this._sharedService.selectedAsset[0].data).ASN;
            data = result[0].filter(r => r.ASN == selectedASN);
            data = this._dashboardService.filterAndAggregateDFPStatusData(data);
            this.statusgridOptions.masterDetail = false;
            if (this.statusgridOptions.api) { this.statusgridOptions.api.refreshClientSideRowModel(); }
          } else {
            this.statusgridOptions.masterDetail = true;
            if (this.statusgridOptions.api) { this.statusgridOptions.api.refreshClientSideRowModel(); }
            data = [];
            data = result[0];
          }
          this.AddOrphanDFPStatusAsBlack(data);
          this.statusgridData = data; // result[0];
          this.containerLastUpdated = result[1];
          try {
            if (this.statusgridData.length > 0) {
              this.setDashboardassetStatusGridColumns(
                Object.getOwnPropertyNames(this.statusgridData[0]), selectedASN
              );
            }
            else {
            }

          } catch (e) {
            //this.prepareBarGraph();
            if (this.statusgridData.length > 0) {
              this.prepareBarGraph(true);
            }
            else {
              this.prepareBarGraph(false);
            }
          }
        },
        error: (error) => {
          this._sharedService.hideLoader();
          console.log(error);
        }
      });
  }
  setDashboardassetStatusGridColumns(columnNames: string[], selectedASN?: any): void {
    this.statuscolumnDefs = [];
    for (let i = 0; i < columnNames.length; i++) {
      let headerName =
        columnNames[i].indexOf("_") > 0
          ? columnNames[i].substring(columnNames[i].indexOf("_") + 1)
          : columnNames[i];
      //if asset is selected
      if (!selectedASN) {
        if (columnNames[i] == "DFPStatus") {
          headerName = 'Compliance Status';
          this.statuscolumnDefs.push({
            headerName,
            field: columnNames[i],
            menuTabs: ["filterMenuTab"],
            width: 267,
            pinned: "left",
            cellRenderer: this.statusDFPInnerCellRenderer,
          });
        } else {
          // if (
          //   this.statuscolumnDefs.filter(c => c.headerName == headerName).length >
          //   0
          // ) {
          //   this.statuscolumnDefs.push({
          //     headerName: headerName + i,
          //     field: columnNames[i],
          //     menuTabs: ["filterMenuTab"],
          //   });
          // } else {
          this.statuscolumnDefs.push({
            headerName,
            field: columnNames[i],
            menuTabs: ["filterMenuTab"],
          });
          // }
        }
      }
      else {
        this.statuscolumnDefs.push({
          headerName,
          field: columnNames[i],
          menuTabs: ["filterMenuTab"],
        });
      }
    }

    this.setDetailGridColumns();
  }
  statusToggleChange() {
    this._sharedService.StatusToggleChanged(this.statusToggle);
  }
}
