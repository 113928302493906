export class PDFMerge {

    constructor() {

        this.Location = "Before";
        //this.IsDelete = false;
        this.MergeOptions = "Last";
    }

    MasterFile: string;
    SecondaryFile: string;
    Location: string;
    IsLocal: boolean;
    IsDelete: boolean;
    MergeOptions: string;
    PageNumber: string;
    PageRange: string;
}


export enum MergeOptions {
    First = 1,
    Last = 2,
    Number = 3
}

export enum MergeOperation {
    Extract = 1,
    Merge = 2,
    Insert = 3,
    Delete = 4
}

export class PDFDelete {

    constructor() {
    }

    MasterFile: string;
    FromPage: number;
    ToPage: number;
}


export class PDFExtract {

    constructor() {
    }

    MasterFile: string;
    FromPage: number;
    ToPage: number;
    IsDelete: boolean;
}


export class PDFExtractPage {

    //public constructor(init?: Partial<PDFExtractPage>) {
    //    Object.assign(this, init);
    //}

    public constructor() {

        this.ASN = "";
        this.DocType = "";
        this.DocNo = "";
        this.Tags = "";
        this.Comments = null;
        this.ReferenceKey = "";
        this.SeqNo = "";
        this.Url = "";
        this.Status = "";
    }

    public MasterFile: string;
    public FromPage: number;
    public ToPage: number;
    public PageRange: string;
    public IsDelete: boolean;
    public ASN: string;
    public DocType: string;
    public DocNo: string;
    public Tags: string;
    public Comments: string;
    public ReferenceKey: string;
    public SeqNo: string;
    public Url: string;
    public File?: File;
    public DocDate?: Date;
    public DocumentDate: string;
    public OCR?: number;
    public docNumberSwitch?: number;
    public taskId?: number[];
    public ContainerName: string;
    public Type: string;
    public ID: string;
    public RunDuplicationCheck: string;
    public Status?: string

}

