<div id="homeview" role="main">
    <!-- MAIN CONTENT -->
    <div id="mainDashBoard" class="" style="height: 91vh;overflow-y:scroll;background-color: #EDEDED;">
        <!--Title and Sparks-->
        <div class="row">
            <div class="col-xs-12 col-sm-4 col-md-6 col-lg-4">
                <h1 class="page-title txt-color-blueDark"><i class="fa-fw fa fa-gauge-high"></i> {{dashBoardName}}</h1>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-6 col-lg-8">
                <div class="jarviswidget-ctrls" title="Help">
                    <app-component-articles class="dashboardMenu" [componentName]="componentName">
                    </app-component-articles>
                </div>
                <div class="dashboardMenu" *ngIf="!showSettingButtons && isDashboardModule" (click)="showSettingButtonsFun()"
                    style="padding-right:10px">
                    <ul id="sparks" *ngIf="assetsTypes!=null && assetsTypes!=undefined">

                        <li>
                            <h3 class="h3DashboardStyle">
                                <i class="fa-fw txt-color-greenLight fa fa-pencil"></i> Edit
                            </h3>
                        </li>
                    </ul>
                </div>
                <div *ngIf="showSettingButtons" style="padding-top: 5px;">
                    <ul id="sparks" *ngIf="assetsTypes!=null && assetsTypes!=undefined">

                        <li style="cursor: pointer;" class="sparks-info" *ngIf="showSettingButtons"
                            (click)="ShowWidgetSettingAndPositionAddForm()">
                            <h3 class="h3DashboardStyle">
                                <i class="fa-fw txt-color-greenLight fa fa-plus"></i> Add Page
                            </h3>
                        </li>

                        <li style="cursor: pointer;" class="sparks-info" *ngIf="showSettingButtons"
                            (click)="ShowWidgetSettingAndPositionForm()">
                            <h3 class="h3DashboardStyle">
                                <i class="fa fa-columns"></i> Page Layout
                            </h3>
                        </li>
                        <li style="cursor: pointer;padding-right:10px" *ngIf="showSettingButtons" (click)="SaveDashboardSetting()">
                            <h3 id="dashboardSavebtn" class="h3DashboardStyle">
                                <i class=" fa-fw fa fa-save"></i> Save
                            </h3>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <div class="col-xs-12 col-sm-8 col-md-6 col-lg-8" style="padding-top:5px;">
                <ul id="sparks" class="" *ngIf="assetsTypes!=null && assetsTypes!=undefined">

                    <li class="sparks-info" (click)="ShowWidgetSettingAndPositionAddForm()">
                        <h5 style="margin-top: 5px;margin-bottom: 5px;font-size: 12px;"> Add Page<span
                                class="txt-color-greenLight" style="margin-top: 3px;"><i
                                    class="glyphicon glyphicon-plus"></i>&nbsp;</span></h5>
                    </li>

                    <li class="sparks-info" *ngIf="showSettingButtons" (click)="ShowWidgetSettingAndPositionForm()">
                        <h5 style="margin-top: 5px;margin-bottom: 5px;font-size: 12px;"> Page Layout <span
                                class="txt-color-greenLight" style="margin-top: 3px;"><i
                                    class="glyphicon glyphicon-pencil"></i>&nbsp;</span></h5>
                    </li>
                    <li *ngIf="showSettingButtons" (click)="SaveDashboardSetting()">
                        <h5 style="margin-top: 5px;margin-bottom: 5px;font-size: 12px;"> Save <span
                                class="txt-color-greenLight" style="margin-top: 3px;"><i
                                    class="fa fa-save"></i>&nbsp;</span></h5>
                    </li>

                    <li *ngIf="!showSettingButtons" (click)="showSettingButtonsFun()">
                        <h5 style="margin-top: 5px;margin-bottom: 5px;font-size: 12px;">
                            Edit
                            <span class="txt-color-greenLight" style="margin-top: 3px;"><i
                                    class="glyphicon glyphicon-pencil"></i>&nbsp;</span>
                        </h5>
                    </li>
                </ul>
            </div> -->
        </div>
        <!-- End Title and Sparks-->
        <!-- Dashboard -->
        <div class="row">
            <sa-widgets-grid>
                <div style="display: none;">
                    <article class="col-sm-12" *ngIf="canViewStatus || canViewRecords">

                        <!-- Compliance Status Table widget -->

                        <div sa-widget style="margin-bottom: 5px;" id="wid-id-0" [deletebutton]="false">
                            <header>
                                <span class="widget-icon"> <i class="glyphicon glyphicon-stats txt-color-darken"></i>
                                </span>
                                <h2 #statusTableGraphHeader>Compliance Status Table</h2>
                                <div class="jarviswidget-ctrls" title="Remove Widget">
                                    <a tabindex="0" class="button-icon tablIndexFocus hand-cursor"
                                        (click)="deleteWidget('--compliance-status-table')" [attr.tabindex]="0"
                                        (keyup.enter)="deleteWidget('--compliance-status-table')">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </header>
                            <!-- widget div-->
                            <div class="no-padding">
                                <div class="widget-body">
                                    <!-- content -->
                                    <div>

                                        <div>

                                            <div class="jarviswidget-ctrls pdfoperations" role="menu">
                                                <div class="btn-group">
                                                    <p-selectButton [options]="statusToggleTypes" 
                                                        [(ngModel)]="statusToggle" #assetRptintervalsetting="ngModel"
                                                        (onChange)="statusToggleChange()">
                                                    </p-selectButton>
                                                </div>
                                            </div>
                                            <div class="widget-body-toolbar bg-color-white"
                                                style="padding-top:0px !important;padding-bottom:0px !important;min-height:30px !important">
                                                <form class="form-inline" role="form">
                                                    <div class="widget-toolbar hidden-mobile"
                                                        style="border-left: none;cursor:auto">
                                                        <span (click)="refreshDashboardStatusListingGrid()"
                                                            class="onoffswitch-title">Last updated
                                                            {{getDateFormatTime(containerLastUpdated)}}<i
                                                                class="fa fa-refresh"
                                                                [ngClass]="{'fa-spin': updatingStatus}"
                                                                style="margin-left: 5px;cursor:pointer;"></i></span>
                                                                <a style="margin-left: 10px" class="button-icon" (click)="onStatusGridExport()"
                                                                [attr.tabindex]="0" data-toggle="dropdown" aria-expanded="true"
                                                                title="Download">
                                                                <i class="glyphicon glyphicon-save"></i>
                                                            </a>
                                                            </div>
                                                </form>
                                            </div>
                                            <div class="row no-space">
                                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div class="jqgridboxcontainer" style="padding-top: 10px;">
                                                        <ag-grid-angular id="agGridDashboardStatus"
                                                            #agGridDashboardStatus [gridOptions]="statusgridOptions"
                                                            [detailRowHeight]="detailRowHeight"
                                                            [columnDefs]="statuscolumnDefs" [rowData]="statusgridData"
                                                            (rowGroupOpened)="populateStatusDetailGrid($event)"
                                                            sortable="true" filter=true [excelStyles]="excelStyles"
                                                            style="width: 100% !important;height:38vh !important;"
                                                            class="ag-theme-balham set-grid-width">
                                                        </ag-grid-angular>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div *ngIf="showFleetBarChart" class="widget-body-toolbar bg-color-white"
                                                style="padding-top:0px !important;padding-bottom:0px !important;min-height:15px !important">
                                            </div>
                                            <div *ngIf="showFleetBarChart" class="row no-space">
                                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 show-stats">
                                                    <p-chart id="barChart" type="bar" [data]="barData"
                                                        [options]="barOptions" height="calc(40vh)"></p-chart>
                                                </div>
                                            </div> -->



                                            <!--</div>-->
                                        </div>
                                        <!-- end s2 tab pane -->
                                    </div>
                                    <!-- end content -->
                                </div>
                            </div>
                            <!-- end widget div -->
                        </div>

                        <!-- Records monthly summery view widget -->

                        <!-- new widget -->
                        <div sa-widget style="margin-bottom: 5px;" id="wid-id-0" [deletebutton]="false">
                            <header>
                                <span class="widget-icon"> <i class="glyphicon glyphicon-stats txt-color-darken"></i>
                                </span>
                                <h2>Records Monthly Summary View</h2>
                                <div class="jarviswidget-ctrls" title="Remove Widget">
                                    <a tabindex="0" class="button-icon tablIndexFocus hand-cursor"
                                        (click)="deleteWidget('--records-monthly-summary-view')" [attr.tabindex]="0"
                                        (keyup.enter)="deleteWidget('--records-monthly-summary-view')">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    </a>
                                </div>
                                <div class="float-div-right">
                                    <!--*ngIf="recordType!='5'"-->
                                    <label class="checkbox col-sm-1 no-margin">

                                        <span class="onoffswitch tablIndexFocus" [attr.tabindex]="0"
                                            style="width: 53px !important;">
                                            <input [checked]="isRecordsSummeryChecked" type="checkbox" name="allusers"
                                                class="onoffswitch-checkbox" id="allusers"
                                                [(ngModel)]="isRecordsSummeryChecked"
                                                (ngModelChange)="changeGraphStatus('--records-monthly-summary-view')">
                                            <label class="onoffswitch-label" for="allusers"><span
                                                    class="onoffswitch-inner onoffswitchpadding" data-swchon-text="Show"
                                                    data-swchoff-text="Hide"
                                                    style="padding-left: 0% !important;"></span><span
                                                    class="onoffswitch-switch"
                                                    style="height:19px;width:19px;"></span></label>
                                        </span>


                                    </label>
                                </div>
                            </header>
                            <!-- widget div-->
                            <div class="no-padding">
                                <div class="widget-body">
                                    <!-- content -->
                                    <div id="myTabContent" class="tab-content">
                                        <div class="tab-pane fade active in padding-10 no-padding-bottom"
                                            style="padding-top:0px !important" id="s1">
                                            <div class="widget-body-toolbar bg-color-white no-padding"
                                                style="padding-top:0px !important;padding-bottom:0px !important;min-height:30px !important">
                                                <form class="form-inline" role="form">
                                                    <div class="btn-group col-md-7" style="padding-right: 0px;">
                                                        <!-- style="text-align:center !important;padding-left: 40vw;"> -->
                                                        <!--<span>
                                                        <a (click)="previousClick(selectedYear)" class="btn btn-default btn-xs" id="btn-prev" style="margin-top:0px !important;"><i class="fa fa-chevron-left"></i></a>
                                                        <span class="btn btn-default btn-xs">{{selectedYear}}</span>
                                                        <a (click)="nextClick(selectedYear)" class="btn btn-default btn-xs" style="margin-top:0px !important;" id="btn-next"><i class="fa fa-chevron-right"></i></a>
                                                    </span>-->
                                                        <ul class="pagination pagination-xs no-margin pull-right ">
                                                            <li class="prev">
                                                                <a (click)="previousClick(selectedYear)"
                                                                    href="javascript:void(0);"><i
                                                                        style="color: rgb(51, 51, 51);"
                                                                        class="fa fa-chevron-left"></i></a>
                                                            </li>
                                                            <li class="">
                                                                <a style="color: rgb(51, 51, 51);"
                                                                    href="javascript:void(0);">{{selectedYear}}</a>
                                                            </li>
                                                            <li class="next">
                                                                <a (click)="nextClick(selectedYear)"
                                                                    href="javascript:void(0);"><i
                                                                        style="color: rgb(51, 51, 51);"
                                                                        class="fa fa-chevron-right"></i></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="widget-toolbar hidden-mobile col-md-5"
                                                        style="border-left: none;cursor:auto; padding-right: 0px; padding-left: 0px;">
                                                        <span (click)="refreshRecordGrid()" class="onoffswitch-title"
                                                            style="padding-right: 5px;">Last
                                                            updated {{getDateFormatTime(recordLastUpdated)}}<i
                                                                class="fa fa-refresh"
                                                                [ngClass]="{'fa-spin': updatingRecord}"
                                                                style="margin-left: 5px;cursor:pointer;"></i></span>
                                                        <div class="btn-group jarviswidget-ctrls pdfoperations">
                                                            <a class="button-icon" (click)="onAssetGridExport()"
                                                                [attr.tabindex]="0" data-toggle="dropdown"
                                                                aria-expanded="true" title="Download">
                                                                <i class="glyphicon glyphicon-save"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="row no-space">
                                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div class="jqgridboxcontainer make-ag-grid-header-sticky" style="padding-top: 10px;"
                                                        *ngIf="this.recordMasterGridData && this.recordMasterGridData.length > 0">
                                                        <ag-grid-angular id="agGridDashboardContainer"
                                                            [gridOptions]="containerMasterGridOptions"
                                                            [columnDefs]="columnDefs"
                                                            (rowClicked)='onRowSelectionChanged($event)'
                                                            (cellClicked)="onCellClicked($event)"
                                                            [rowData]="recordMasterGridData" [treeData]="true"
                                                            [groupDefaultExpanded]="groupDefaultExpanded"
                                                            [getDataPath]="getDataPath"
                                                            [autoGroupColumnDef]="autoGroupColumnDef"
                                                            [excelStyles]="excelStyles" [getRowId]="getRowNodeId"
                                                            sortable="true" filter=true
                                                            style="width: 100% !important;"
                                                            class="ag-theme-balham  set-grid-width">
                                                        </ag-grid-angular>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="showAssetBarChart && isRecordsSummeryChecked"
                                                class="widget-body-toolbar bg-color-white"
                                                style="padding-top:0px !important;padding-bottom:0px !important;min-height:15px !important">
                                            </div>
                                            <div class="row no-space"
                                                *ngIf="this.showAssetBarChart && isRecordsSummeryChecked">
                                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 show-stats">
                                                    <!-- <p-chart id="assetBarChart" type="bar" [data]="assetBarGraphData"
                                                        [options]="assetBarGraphOptions" height="calc(40vh)"></p-chart> -->
                                                    <ag-charts-angular id="assetBarChart"
                                                        [options]="assetBarGraphOptions">
                                                    </ag-charts-angular>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end content -->
                                </div>
                            </div>
                            <!-- end widget div -->
                        </div>

                        <!-- bar graph widget-->
                        <div sa-widget [deletebutton]="false" style="margin-bottom: 5px !important;">
                            <header>
                                <span class="widget-icon"> <i class="fa fa-bar-chart txt-color-darken"></i> </span>
                                <h2 #statusBarGraphHeader>Compliance Status Graph (Bar)</h2>
                                <div class="jarviswidget-ctrls" title="Remove Widget">
                                    <a tabindex="0" class="button-icon tablIndexFocus hand-cursor"
                                        (click)="deleteWidget('--compliance-status-graph-bar-')" [attr.tabindex]="0"
                                        (keyup.enter)="deleteWidget('--compliance-status-graph-bar-')">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </header>
                            <!-- widget div-->
                            <div>
                                <!-- widget edit box -->
                                <!-- end widget edit box -->
                                <div class="widget-body no-padding" id="barChartId">

                                    <!-- <p-chart *ngIf="showFleetBarChart" id="barChart" type="bar" [data]="barData"
                                        [options]="barOptions" height="calc(40vh)"></p-chart> -->


                                    <ag-charts-angular *ngIf="showFleetBarChart" id="barChart" [options]="barOptions">
                                    </ag-charts-angular>

                                    <!-- end content -->
                                </div>
                            </div>
                            <!-- end widget div -->
                        </div>

                        <!-- Donut graph widget-->
                        <div sa-widget [deletebutton]="false" style="margin-bottom: 5px !important;">
                            <header>
                                <span class="widget-icon"> <i class="fa fa-bar-chart txt-color-darken"></i> </span>
                                <h2 #statusDonutGraphHeader>Compliance Status Graph (Donut)</h2>
                                <div class="jarviswidget-ctrls" title="Remove Widget">
                                    <a tabindex="0" class="button-icon tablIndexFocus hand-cursor"
                                        (click)="deleteWidget('--compliance-status-graph-donut-')" [attr.tabindex]="0"
                                        (keyup.enter)="deleteWidget('--compliance-status-graph-donut-')">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </header>
                            <!-- widget div-->
                            <div>
                                <!-- widget edit box -->
                                <!-- end widget edit box -->
                                <div class="widget-body no-padding" id="DFPStatusDoughnutChartId">

                                    <ag-charts-angular *ngIf="showFleetBarChart" id="doughnutChart"
                                        [options]="doughnutOptions" height="calc(40vh)">
                                    </ag-charts-angular>
                                    <!-- <p-chart *ngIf="showFleetBarChart" id="doughnutChart" type="doughnut"
                                        [data]="doughnutData" [options]="doughnutOptions" height="calc(40vh)"></p-chart> -->



                                    <!-- end content -->
                                </div>
                            </div>
                            <!-- end widget div -->
                        </div>
                        <!-- Inbox Graph (bar) -->
                        <div sa-widget [deletebutton]="false" style="margin-bottom: 5px !important;">
                            <header>
                                <span class="widget-icon"> <i class="fa fa-bar-chart txt-color-darken"></i> </span>
                                <h2>Inbox Graph (bar)</h2>
                                <div class="jarviswidget-ctrls" title="Remove Widget">
                                    <a tabindex="0" class="button-icon tablIndexFocus hand-cursor"
                                        (click)="deleteWidget('--inbox-graph-bar-')" [attr.tabindex]="0"
                                        (keyup.enter)="deleteWidget('--inbox-graph-bar-')">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    </a>
                                </div>
                                <div class="widget-toolbar hidden-mobile" style="cursor:auto;">
                                    <span (click)="refreshInboxData()" class="onoffswitch-title">Last updated
                                        {{getDateFormatTime(inboxLastUpdated)}}<i
                                            class="fa fa-refresh {{updatingInbox}}"
                                            style="margin-left: 5px;cursor:pointer;color:#4c4f53"></i></span>
                                </div>
                            </header>
                            <!-- widget div-->
                            <div>
                                <!-- widget edit box -->
                                <!-- end widget edit box -->
                                <div class="widget-body no-padding" id="InboxStatusGraphId">

                                    <ag-charts-angular *ngIf="inboxDataChart" id="inboxChart"
                                        [options]="inboxChartOptions">
                                    </ag-charts-angular>
                                    <!-- end content -->
                                </div>
                            </div>
                            <!-- end widget div -->
                        </div>
                        <!-- Transition Graph (bar) -->
                        <!-- <div sa-widget [deletebutton]="false" style="margin-bottom: 5px !important;">
                            <header>
                                <span class="widget-icon"> <i class="fa fa-bar-chart txt-color-darken"></i> </span>
                                <h2>Transition Graph (bar)</h2>
                                <div class="jarviswidget-ctrls" title="Remove Widget">
                                    <a tabindex="0" class="button-icon tablIndexFocus hand-cursor"
                                        (click)="deleteWidget('--transition-graph-bar-')" [attr.tabindex]="0"
                                        (keyup.enter)="deleteWidget('--transition-graph-bar-')">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    </a>
                                </div>
                                <div class="widget-toolbar hidden-mobile" style="cursor:auto;">
                                    <span (click)="refreshBinderData()" class="onoffswitch-title">Last updated
                                        {{getDateFormatTime(binderLastUpdated)}}<i
                                            class="fa fa-refresh {{updatingBinder}}"
                                            style="margin-left: 5px;cursor:pointer;color:#4c4f53"></i></span>
                                </div>
                            </header>
                            <div>
                                <div class="widget-body no-padding" id="TransitionGraphId">

                                    <ag-charts-angular *ngIf="transitionChartChart" id="TransitionChart"
                                        [options]="binderChartOptions">
                                    </ag-charts-angular>

                                </div>
                            </div>
                        </div> -->
                    </article>

                    <!-- end row -->
                  <!-- Container Status Graph (Bar) widget-->
                  <div sa-widget [deletebutton]="false" style="margin-bottom: 5px !important;" >
                    <header>
                      <span class="widget-icon"> <i class="fa fa-bar-chart txt-color-darken"></i> </span>
                      <h2>Container Status Graph (Bar)</h2>
                      <div class="jarviswidget-ctrls" title="Remove Widget">
                        <a tabindex="0" class="button-icon tablIndexFocus hand-cursor"
                          (click)="deleteWidget('--container-status-graph-bar-')" [attr.tabindex]="0"
                          (keyup.enter)="deleteWidget('--container-status-graph-bar-')">
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div class="widget-toolbar hidden-mobile" style="cursor:auto;">
                        <span (click)="refreshContainerStatusData()" class="onoffswitch-title">Last updated
                          {{getDateFormatTime(containerStatusLastUpdated)}}<i class="fa fa-refresh {{updatingContainerStatus}}"
                            style="margin-left: 5px;cursor:pointer;color:#4c4f53"></i></span>
                      </div>
                    </header>
                    <!-- widget div-->
                    <div>
                      <!-- widget edit box -->
                      <!-- end widget edit box -->
                      <div class="widget-body no-padding" id="containerStatusGraph">
                        <ag-charts-angular id="containerStatusGraphId" [options]="recordSummaryBarOptions" *ngIf="recordSummaryBarData" height="calc(40vh)">
                        </ag-charts-angular>
                        <!-- end content -->
                      </div>
                    </div>
                    <!-- end widget div -->
                  </div>


                    <!-- row -->
                    <div class="row">
                        <article class="col-sm-12 col-md-12 col-lg-6  articleHeight">
                            <div sa-widget [deletebutton]="false" style="margin-bottom: 5px !important;">
                                <header>
                                    <span class="widget-icon"> <i class="fa fa-bar-chart txt-color-darken"></i> </span>
                                    <h2>Asset Counter</h2>
                                    <div class="jarviswidget-ctrls" title="Remove Widget">
                                        <a tabindex="0" class="button-icon tablIndexFocus hand-cursor"
                                            (click)="deleteWidget('--asset-counter')" [attr.tabindex]="0"
                                            (keyup.enter)="deleteWidget('--asset-counter')">
                                            <i class="fa fa-times" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </header>
                                <!-- widget div-->
                                <!-- widget edit box -->
                                <div>
                                    <!-- end widget edit box -->
                                    <div class="widget-body no-padding" style="min-height:60px">


                                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <ul id="sparks" style="margin: 0px;"
                                                *ngIf="assetsTypes!=null && assetsTypes!=undefined">

                                                <li class="sparks-info" *ngIf="assetsTypes.Airframe!=0">
                                                    <h5 style="margin-top: 5px;margin-bottom: 5px;font-size: 12px;">
                                                        Asset <span class="txt-color-greenLight"
                                                            style="margin-top: 3px;"><i
                                                                class="fa fa-plane"></i>&nbsp;{{assetsTypes.Airframe}}</span>
                                                    </h5>
                                                </li>
                                                <li class="sparks-info" *ngIf="assetsTypes.Helicopter!=0">
                                                    <h5 style="margin-top: 5px;margin-bottom: 5px;font-size: 12px;">
                                                        Asset <span class="txt-color-greenLight"
                                                            style="margin-top: 3px;"><i
                                                                class="fa fa-plane"></i>&nbsp;{{assetsTypes.Helicopter}}</span>
                                                    </h5>
                                                </li>
                                                <li class="sparks-info" *ngIf="assetsTypes.Engine!=0">
                                                    <h5 style="margin-top: 5px;margin-bottom: 5px;font-size: 12px;">
                                                        Engine <span class="txt-color-purple"
                                                            style="margin-top: 3px;"><i
                                                                class="fa icon-Engine"></i>&nbsp;{{assetsTypes.Engine}}</span>
                                                    </h5>
                                                </li>
                                                <li class="sparks-info" *ngIf="assetsTypes.LDG!=0">
                                                    <h5 style="margin-top: 5px;margin-bottom: 5px;font-size: 12px;">
                                                        Landing Gear <span class="txt-color-blueDark"
                                                            style="margin-top: 3px;margin-left: 10px;"><i
                                                                class="fa icon-landinggear"></i>&nbsp;{{assetsTypes.LDG}}</span>
                                                    </h5>
                                                </li>
                                                <li class="sparks-info" *ngIf="assetsTypes.APU!=0">
                                                    <h5 style="margin-top: 5px;margin-bottom: 5px;font-size: 12px;"> APU
                                                        <span class="txt-color-greenDark" style="margin-top: 3px;"><i
                                                                class="fa icon-APU2"></i>&nbsp;{{assetsTypes.APU}}</span>
                                                    </h5>
                                                </li>
                                                <li class="sparks-info" *ngIf="assetsTypes.Propeller!=0">
                                                    <h5 style="margin-top: 5px;margin-bottom: 5px;font-size: 12px;">
                                                        Propeller <span class="txt-color-red"
                                                            style="margin-top: 3px;margin-left: 10px;"><i
                                                                class="fa icon-propeller"></i>&nbsp;{{assetsTypes.Propeller}}</span>
                                                    </h5>
                                                </li>
                                            </ul>
                                        </div>




                                        <!-- end content -->
                                    </div>
                                </div>
                                <!-- end widget div -->
                            </div>
                            <!-- new widget -->
                            <div sa-widget id="wid-id-4" [deletebutton]="false" style="margin-bottom: 5px !important"
                                *ngIf="canViewComms">
                                <header>
                                    <span class="widget-icon"> <i class="fa fa-comments txt-color-darken"></i> </span>
                                    <h2>Inbox - My Communications</h2>
                                    <div class="jarviswidget-ctrls" title="Remove Widget">
                                        <a tabindex="0" class="button-icon tablIndexFocus hand-cursor"
                                            (click)="deleteWidget('--inbox-my-communications')" [attr.tabindex]="0"
                                            (keyup.enter)="deleteWidget('--inbox-my-communications')">
                                            <i class="fa fa-times" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </header>
                                <!-- widget div-->
                                <div>
                                    <!-- widget edit box -->
                                    <div class="jarviswidget-editbox">
                                        <div>
                                            <label>Title:</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <!-- end widget edit box -->
                                    <div class="widget-body no-padding smart-form">
                                        <!-- content goes here -->
                                        <h5 class="todo-group-title"><i class="fa fa-exclamation"></i> Assigned to me
                                            (<small class="num-of-tasks">{{assignedtomeCount}}</small>)</h5>
                                        <ul id="sortable1" class="todo">
                                            <li *ngFor="let con of commAssignedToMe; let i = index;">
                                                <span>
                                                    <!--<label class="checkbox">
                                                <input type="checkbox" name="checkbox-inline">

                                            </label>-->
                                                </span>
                                                <p style="padding-bottom: 0px;padding-top: 2px;">
                                                    <strong>Ticket #{{con.CommId}}</strong> - {{con.Subject}} [<a
                                                        (click)="comMoreDetailsClick(con)"
                                                        class="font-xs hand-cursor">More
                                                        Details</a>]
                                                    <!--<span class="text-muted">Sea deep blessed bearing under darkness from God air living isn't. </span>-->
                                                    <span
                                                        class="date">{{getDateFormat(con.CreatedOn)}}&nbsp;{{con.RequestedBy}}</span>
                                                </p>
                                            </li>
                                        </ul>
                                        <h5 class="todo-group-title"><i class="fa fa-male"></i> Requested by me (<small
                                                class="num-of-tasks">{{requestedbymeCount}}</small>)</h5>
                                        <ul id="sortable2" class="todo">
                                            <li
                                                *ngFor="let con of commRequestedByMe; let i = index; trackBy:trackByIndexDefault">
                                                <span class="handle">
                                                    <label class="checkbox">
                                                        <input type="checkbox" name="checkbox-inline">
                                                        <!--<i></i>-->
                                                    </label>
                                                </span>
                                                <p>
                                                    <strong>Ticket #{{con.CommId}}</strong> - {{con.Subject}} [<a
                                                        (click)="comMoreDetailsClick(con)"
                                                        class="font-xs hand-cursor">More
                                                        Details</a>] <span
                                                        class="date">{{getDateFormat(con.CreatedOn)}}&nbsp;{{con.AssignedTo}}</span>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <!-- end widget div -->
                            </div>
                            <!-- end widget -->
                        </article>
                        <article class="col-sm-12 col-md-12 col-lg-6 p-l-r" *ngIf="canViewStats">
                            <!-- new widget -->
                            <div id="wid-id-2" sa-widget [deletebutton]="false" style="margin-bottom: 5px !important;">
                                <header>
                                    <span class="widget-icon"> <i class="fa fa-bar-chart txt-color-darken"></i> </span>
                                    <h2>Asset's Document Statistics</h2>
                                    <div class="jarviswidget-ctrls" title="Remove Widget">
                                        <a tabindex="0" class="button-icon tablIndexFocus hand-cursor"
                                            (click)="deleteWidget('--asset-s-document-statistics')" [attr.tabindex]="0"
                                            (keyup.enter)="deleteWidget('--asset-s-document-statistics')">
                                            <i class="fa fa-times" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                    <div class="widget-toolbar hidden-mobile" style="cursor:auto;">
                                        <span (click)="refreshStats()" class="onoffswitch-title">Last updated
                                            {{getDateFormatTime(statsLastUpdated)}}<i
                                                class="fa fa-refresh {{updatingStats}}"
                                                style="margin-left: 5px;cursor:pointer;color:#4c4f53"></i></span>
                                    </div>
                                </header>
                                <!-- widget div-->
                                <div>
                                    <!-- widget edit box -->
                                    <div class="jarviswidget-editbox">
                                        <div>
                                            <label>Title:</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <!-- end widget edit box -->
                                    <div class="widget-body no-padding">
                                        <table class="table table-striped table-hover table-condensed">
                                            <thead>
                                                <tr>
                                                    <th style="width:25%">Asset</th>
                                                    <th style="width:14%" class="text-align-center">Document Count</th>
                                                    <th style="width:26%" class="text-align-center">Storage Space</th>
                                                    <!--<th style="width:8%" class="text-align-left">Usage</th>-->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let con of statsData; let i = index; trackBy:trackByIndex">
                                                    <td><span class="fa fa-plane"
                                                            style="margin-right: 5px;"></span>{{con.Aircraft}}
                                                    </td>
                                                    <td class="text-align-center">{{con.DocumentCount}}</td>
                                                    <td class="text-align-center">{{con.StorageSpace}}</td>
                                                    <!--<td class="text-align-center">
                                                <p-chart id="piechart{{i}}" type="pie" [data]="con.StatsPieChart"  width="25px" height="25px" [responsive]="false" [options]="pieOptions"></p-chart>
                                            </td>-->
                                                </tr>

                                            </tbody>
                                        </table>
                                        <!-- end content -->
                                    </div>
                                </div>
                                <!-- end widget div -->
                            </div>
                            <!-- end widget -->

                        </article>

                    </div>
                </div>


                <div class="row" *ngFor="let widgetPluginPositionsGrid of widgetPluginPositions.grid; let i = index"
                  style="padding: 0px 0.4rem;">
                    <div class="{{'c_'+i}}" *ngFor="let pluginPosition of widgetPluginPositionsGrid.section; let j = index">

                        <article id="{{i}}_{{j}}" style="padding-left: 0.3rem;padding-right: 0.2rem;"
                            class=" pl-1 sortable-grid col-xs-12 col-sm-2 col-md-2 col-lg-{{pluginPosition.noOfColumns}}">


                            <div class="jarviswidget" id="builtin{{i}}_{{j}}"         
                                data-widget-togglebutton="false"
                                style="display: none;"
                                data-widget-sortable="false" data-widget-fullscreenbutton="false"
                                data-widget-colorbutton="false" 
                                data-widget-editbutton="false"
                                data-widget-custombutton="false" data-widget-deletebutton="false" role="widget"
                                (keyup.enter)="addViewInSelectedWidget(i+j,i,j)"
                                (click)="addViewInSelectedWidget(i+j,i,j)">

                                <header>
                                    <div style="text-align: center;cursor: pointer;">
                                        <h2>Add Widget</h2>
                                    </div>


                                </header>
                            </div>

                        </article>

                    </div>
                </div>

            </sa-widgets-grid>
        </div>
    </div>
</div>


<form #pluginGeneralSettingForm="ngForm" *ngIf="displayWidgetsettingsAndPositionForm">
    <p-dialog [(visible)]="displayWidgetsettingsAndPositionForm" showEffect="fade" [modal]="true"
        [style]="{width: '1200px'}" [resizable]="false">
        <ng-template pTemplate="header" >
            Dashboard Layout Settings
            <!-- <a (click)="addWidgetsettingsAndPositionRow()" (keyup.enter)="addWidgetsettingsAndPositionRow()">
                <i class="glyphicon glyphicon-plus"></i>
            </a> -->
        </ng-template>
        <div class="upload-dialog" style="height: 470px;overflow-y: auto;">

            <div class="smart-form">

                <div class="row">
                    <div class="form-group col-sm-2 ">
                        <fieldset>
                            <label class="label">
                                Dashboard Name *
                            </label>
                            <label class="input">
                                <input id="editFormPageName" type="text" name="editFormPageName"
                                    [(ngModel)]="editFormPageName" required>
                            </label>
                        </fieldset>
                    </div>
                    <div *ngIf="_router.url!='/dashboard/record'" class="form-group col-sm-2 ">
                        <fieldset >
                            <label class="label">
                                Dashboard Order *
                            </label>
                            <label class="input">
                                <input id="pageMenuOrder" type="number" name="pageMenuOrder" [(ngModel)]="pageMenuOrder"
                                    min="1" (change)="DisplayOrderChange()" required>
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-2 ">
                      <fieldset>
                        <label class="label">
                          Landing page for module
                        </label>
                        <p-dropdown appendTo="body" [panelStyle]="{'bottom': 'calc(100% + 5px)'}" [attr.tabindex]="0" appendTo="body"
                                    id="modulesList" name="modulesList" [options]="modulesList" [editable]="false"
                                    [(ngModel)]="selectedModuleId" placeholder="Select Module" [filter]="true"></p-dropdown>
                      </fieldset>
                    </div>
                    <div class="col-sm-8" style="text-align: right;">
                        <button type="button" pButton icon="glyphicon glyphicon-plus"
                            (click)="addWidgetsettingsAndPositionRow()"
                            (keyup.enter)="addWidgetsettingsAndPositionRow()" label="Click to add new row">
                        </button>
                    </div>
                </div>
                <div class="row">
                </div>
                <div *ngFor="let grid of widgetSettingAndPositionModel.grid; let j = index;">
                    <div class="row">
                        <div class="form-group col-sm-2">
                            <fieldset>
                                <label class="label">
                                    Display Row Number*
                                </label>
                                <label class="input">
                                    <input readonly type="text" name="rowId{{j}}" id="rowId{{j}}" value="{{j+1}}">
                                </label>
                            </fieldset>
                        </div>
                        <div class="form-group col-sm-1">
                            <fieldset>
                                <label class="label">
                                    Widget Height*
                                </label>
                                <label class="input">
                                    <input required type="number" name="widgetHeight{{j}}" id="widgetHeight{{j}}"
                                        [(ngModel)]="grid.widgetHeight">
                                </label>
                            </fieldset>
                        </div>
                        <div *ngFor="let section of grid.section; let k = index;trackBy:trackByIndex">
                            <div class="form-group col-sm-2 col-lg-2">
                                <fieldset>
                                    <label class="label">
                                        Columns Width *
                                    </label>

                                    <div id="columnWidth">
                                        <p-dropdown appendTo="body" [panelStyle]="{'bottom': 'calc(100% + 5px)'}" [attr.tabindex]="0" class="form-control" id="noOfColumns{{j}}{{k}}"
                                            name="noOfColumns{{j}}{{k}}" [(ngModel)]="section.noOfColumns" required
                                            [options]="columnListForWidgetSetting"
                                            (onChange)="countSectionNumberOfColumns(widgetSettingAndPositionModel.grid)"
                                            [editable]="false" placeholder="Column width" [filter]="true"></p-dropdown>
                                    </div>

                                </fieldset>
                            </div>
                        </div>
                        <div class="col-sm-1 col-md-1 col-lg-1">


                            <fieldset style="top:24px">
                                <label class="input">
                                    <div style="float: right;" type="button" (click)='RemoveWidget(j)'
                                        class="btn btn-default btn-sm">
                                        <span aria-hidden="true" class="fa fa-times"></span>
                                    </div>
                                </label>

                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">

            <div class="p-dialog-buttonpane">
                <button *ngIf="_router.url!='/dashboard/record'" type="button" pButton icon="fa fa-times"
                    class="btn btn-danger minzindex"
                    (click)="btnDeleteWidgetSettingAndPosition(pluginGeneralSettingForm)" label="Delete"></button>
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex"
                    (click)="btnSaveWidgetSettingAndPosition(pluginGeneralSettingForm)" label="Update"
                    [disabled]="!pluginGeneralSettingForm.form.valid || isDisableUpdateButton"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnCencalWidgetSettingAndPosition(pluginGeneralSettingForm)" label="Cancel"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>


<form #addPluginGeneralSettingForm="ngForm" *ngIf="displayWidgetsettingsAndPositionAddForm">
    <p-dialog [(visible)]="displayWidgetsettingsAndPositionAddForm" showEffect="fade" [modal]="true"
        [style]="{width: '1200px'}" [resizable]="false">
        <ng-template pTemplate="header">
            Dashboard Layout Settings
        </ng-template>
        <div class="upload-dialog" style="height: 470px;overflow-y: auto;">
            <div class="smart-form">

                <div class="row">
                    <div class="form-group col-sm-2">
                        <fieldset>
                            <label class="label">
                                Dashboard Name *
                            </label>
                            <label class="input">
                                <input [attr.tabindex]="0" id="addFormPageName" type="text" name="addFormPageName"
                                    [(ngModel)]="addFormPageName" required>
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-2 ">
                        <fieldset>
                            <label class="label">
                                Dashboard Order *
                            </label>
                            <label class="input">
                                <input [attr.tabindex]="-1" id="addPageMenuOrder" type="number" name="addPageMenuOrder"
                                    [(ngModel)]="addPageMenuOrder" min="1" (change)="DisplayOrderChangeForAddPage()"
                                    required>
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-2 ">
                      <fieldset >
                        <label class="label">
                          Landing page for module
                        </label>
                        <p-dropdown appendTo="body" 
                                    [panelStyle]="{'bottom': 'calc(100% + 5px)'}" 
                                    [attr.tabindex]="0" appendTo="body"
                                    id="modulesList" name="modulesList"
                                    [options]="modulesList" [editable]="false" 
                                    [(ngModel)]="selectedModuleId"
                                    placeholder="Select Module" [filter]="true"></p-dropdown>
                      </fieldset>
                    </div>
                    <div [attr.tabindex]="0" class="col-sm-8" style="text-align: right;">
                        <button type="button" pButton icon="glyphicon glyphicon-plus"
                            (click)="addWidgetsettingsAndPositionRowForAddForm()" label="Click to add new row">
                        </button>
                    </div>
                </div>

                <div *ngFor="let grid of widgetSettingAndPositionAddModel.grid; let j = index; ">

                    <div class="row">
                        <div class="form-group col-sm-2">
                            <fieldset>
                                <label class="label">
                                    Display Row Number*
                                </label>
                                <label class="input">
                                    <input readonly type="text" name="rowId{{j}}" id="rowId{{j}}" value="{{j+1}}">
                                </label>
                            </fieldset>
                        </div>
                        <div class="form-group col-sm-1">
                            <fieldset>
                                <label class="label">
                                    Widget Height*
                                </label>
                                <label class="input">
                                    <input required type="number" name="widgetHeight{{j}}" id="widgetHeight{{j}}"
                                        [(ngModel)]="grid.widgetHeight">
                                </label>
                            </fieldset>
                        </div>
                        <div *ngFor="let section of grid.section; let k = index;trackBy:trackByIndex">
                            <div class="form-group col-sm-2">
                                <fieldset>
                                    <label class="label">
                                        Columns Width *
                                    </label>

                                    <div id="columnWidth">
                                        <p-dropdown appendTo="body" [panelStyle]="{'bottom': 'calc(100% + 5px)'}" [attr.tabindex]="0" class="form-control" id="noOfColumns{{j}}{{k}}"
                                            name="noOfColumns{{j}}{{k}}" [(ngModel)]="section.noOfColumns" required
                                            [options]="columnListForWidgetSetting"
                                            (onChange)="countSectionNumberOfColumns(widgetSettingAndPositionAddModel.grid)"
                                            [editable]="false" placeholder="Column width" [filter]="true"></p-dropdown>
                                    </div>

                                </fieldset>
                            </div>
                        </div>
                        <div class="col-sm-1 col-md-1 col-lg-1">


                            <fieldset style="top:24px">
                                <label class="input">
                                    <div style="float: right;" type="button" (click)='RemoveAddWidget(j)'
                                        class="btn btn-default btn-sm">
                                        <span aria-hidden="true" class="fa fa-times"></span>
                                    </div>
                                </label>

                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">

            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex"
                    (click)="btnAddSaveWidgetSettingAndPosition(addPluginGeneralSettingForm)" label="Create"
                    [disabled]="!addPluginGeneralSettingForm.form.valid || isDisableUpdateButton || widgetSettingAndPositionAddModel.grid==0"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnAddCencalWidgetSettingAndPosition(addPluginGeneralSettingForm)" label="Cancel"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>



<form #widgetToSpecificArticle="ngForm" *ngIf="displayWidgetToSpecificArticle">
    <p-dialog [(visible)]="displayWidgetToSpecificArticle" showEffect="fade" [modal]="true" [style]="{width: '400px'}"
        [resizable]="false">
        <ng-template pTemplate="header" >
            Update
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Select Widget *
                            </label>

                            <p-dropdown appendTo="body" [panelStyle]="{'bottom': 'calc(100% + 5px)'}" [attr.tabindex]="0" appendTo="body" class="form-control" id="AssetType"
                                name="selectedWidgetToSpecificArticle" [options]="widgetDropdownListValues"
                                [editable]="false" [(ngModel)]="selectedWidgetToSpecificArticle"
                                placeholder="Select widget" [filter]="true" required></p-dropdown>

                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">

            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex"
                    (click)="btnAddwidgetToSpecificArticle(widgetToSpecificArticle)" label="Update"
                    [disabled]="!widgetToSpecificArticle.form.valid"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnCancelwidgetToSpecificArticle(widgetToSpecificArticle)" label="Cancel"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>



<form #addeditPGform="ngForm" *ngIf="displayGroupCheckForm">
    <p-dialog [(visible)]="displayGroupCheckForm" showEffect="fade" [modal]="true" [style]="{width: '400px'}"
        [resizable]="false">
        <ng-template pTemplate="header" >
            Group Check
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-12">
                        <p-listbox [options]="groupCheckList" optionLabel="checkType"
                            (onClick)="onlistboxCheckClicked($event)"
                            [listStyle]="{'max-height':'50vh'}" [style]="{'width':'360px'}">
                            <ng-template pTemplate="header" >
                                <i class="fa fa-table"></i>
                                {{selectedCheck.checkType}} ({{selectedCheck.checkCount}})
                            </ng-template>
                        </p-listbox>
                    </div>
                </div>

            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnCloseClick()" label="Close"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>






<form #customGeneralSettingForm="ngForm" *ngIf="displayCustomGeneralSettingForm">
    <p-dialog [(visible)]="displayCustomGeneralSettingForm" showEffect="fade" [modal]="true" [style]="{width: '400px'}"
        [resizable]="false">
        <ng-template pTemplate="header" >
            General Settings
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Title
                            </label>

                            <label class="input">
                                <input id="title" type="text" name="title" [(ngModel)]="customGeneralSetting.title"
                                    required #title="ngModel">

                            </label>

                        </fieldset>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Columns
                            </label>

                            <label class="input">
                                <input id="columns" type="number" name="columns"
                                    [(ngModel)]="customGeneralSetting.columns" required #columns="ngModel">

                            </label>

                        </fieldset>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Color
                            </label>

                            <label class="input">

                                <ul class="color-select pull-right">
                                    <li><span (click)="SelectHeaderColor($event)" class="bg-color-green"
                                            data-widget-setstyle="jarviswidget-color-green" rel="tooltip"
                                            data-placement="left" data-original-title="Green Grass"></span></li>
                                    <li><span (click)="SelectHeaderColor($event)" class="bg-color-greenDark"
                                            data-widget-setstyle="jarviswidget-color-greenDark" rel="tooltip"
                                            data-placement="top" data-original-title="Dark Green"></span></li>
                                    <li><span (click)="SelectHeaderColor($event)" class="bg-color-greenLight"
                                            data-widget-setstyle="jarviswidget-color-greenLight" rel="tooltip"
                                            data-placement="top" data-original-title="Light Green"></span></li>
                                    <li><span (click)="SelectHeaderColor($event)" class="bg-color-purple"
                                            data-widget-setstyle="jarviswidget-color-purple" rel="tooltip"
                                            data-placement="top" data-original-title="Purple"></span></li>
                                    <li><span (click)="SelectHeaderColor($event)" class="bg-color-magenta"
                                            data-widget-setstyle="jarviswidget-color-magenta" rel="tooltip"
                                            data-placement="top" data-original-title="Magenta"></span></li>
                                    <li><span (click)="SelectHeaderColor($event)" class="bg-color-pink"
                                            data-widget-setstyle="jarviswidget-color-pink" rel="tooltip"
                                            data-placement="right" data-original-title="Pink"></span></li>
                                    <li><span (click)="SelectHeaderColor($event)" class="bg-color-pinkDark"
                                            data-widget-setstyle="jarviswidget-color-pinkDark" rel="tooltip"
                                            data-placement="left" data-original-title="Fade Pink"></span></li>
                                    <li><span (click)="SelectHeaderColor($event)" class="bg-color-blueLight"
                                            data-widget-setstyle="jarviswidget-color-blueLight" rel="tooltip"
                                            data-placement="top" data-original-title="Light Blue"></span></li>
                                    <li><span (click)="SelectHeaderColor($event)" class="bg-color-teal"
                                            data-widget-setstyle="jarviswidget-color-teal" rel="tooltip"
                                            data-placement="top" data-original-title="Teal"></span></li>
                                    <li><span (click)="SelectHeaderColor($event)" class="bg-color-blue"
                                            data-widget-setstyle="jarviswidget-color-blue" rel="tooltip"
                                            data-placement="top" data-original-title="Ocean Blue"></span></li>
                                    <li><span (click)="SelectHeaderColor($event)" class="bg-color-blueDark"
                                            data-widget-setstyle="jarviswidget-color-blueDark" rel="tooltip"
                                            data-placement="top" data-original-title="Night Sky"></span></li>
                                    <li><span (click)="SelectHeaderColor($event)" class="bg-color-darken"
                                            data-widget-setstyle="jarviswidget-color-darken" rel="tooltip"
                                            data-placement="right" data-original-title="Night"></span></li>
                                    <li><span (click)="SelectHeaderColor($event)" class="bg-color-yellow"
                                            data-widget-setstyle="jarviswidget-color-yellow" rel="tooltip"
                                            data-placement="left" data-original-title="Day Light"></span></li>
                                    <li><span (click)="SelectHeaderColor($event)" class="bg-color-orange"
                                            data-widget-setstyle="jarviswidget-color-orange" rel="tooltip"
                                            data-placement="bottom" data-original-title="Orange"></span></li>
                                    <li><span (click)="SelectHeaderColor($event)" class="bg-color-orangeDark"
                                            data-widget-setstyle="jarviswidget-color-orangeDark" rel="tooltip"
                                            data-placement="bottom" data-original-title="Dark Orange"></span></li>
                                    <li><span (click)="SelectHeaderColor($event)" class="bg-color-red"
                                            data-widget-setstyle="jarviswidget-color-red" rel="tooltip"
                                            data-placement="bottom" data-original-title="Red Rose"></span></li>
                                    <li><span (click)="SelectHeaderColor($event)" class="bg-color-redLight"
                                            data-widget-setstyle="jarviswidget-color-redLight" rel="tooltip"
                                            data-placement="bottom" data-original-title="Light Red"></span></li>
                                    <li><span (click)="SelectHeaderColor($event)" class="bg-color-white"
                                            data-widget-setstyle="jarviswidget-color-white" rel="tooltip"
                                            data-placement="right" data-original-title="Purity"></span></li>
                                </ul>

                                <!-- <input id="color" type="text" name="color" placeholder="Subject"
                                    (keyup.enter)="subjectEnterClick($event)" [(ngModel)]="customGeneralSetting.Color"
                                    required #color="ngModel"> -->

                            </label>

                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">

            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex"
                    (click)="btnSaveGeneralSettingClick(customGeneralSettingForm)" label="Update"
                    [disabled]="!customGeneralSettingForm.form.valid"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnCancelGeneralSettingClick(customGeneralSettingForm)" label="Cancel"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>
<p-confirmDialog header="Are you sure?" key="confirmdelete" icon="fa fa-question-circle" [style]="{width: '435px'}">
</p-confirmDialog>

<edit-comm></edit-comm>