import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-tech-support',
  templateUrl: './tech-support.component.html',
})
export class TechSupportComponent implements OnInit,AfterViewInit,OnDestroy {

  constructor(private elementRef:ElementRef) {};

ngAfterViewInit() {
  // var s = document.createElement("script");
  // s.type = "text/javascript";
  // s.id="ze-snippet";
  // s.src = "https://static.zdassets.com/ekr/snippet.js?key=94bed896-481a-48f9-9758-d65f1c97a23a";
  // document.getElementById('abc').appendChild(s);
  // document.getElementById('webWidget').style.visibility = "visible";
  // var a=document.getElementById('webWidget');
  // a.style.width='374px';
  // a.style.height='572px';
  // a.style.maxHeight='calc(100vh - 32px)';
  // a.style.position='fixed';
  // a.style.zIndex='999999';
  // a.style.opacity='1';

}
ngOnDestroy(){

  // var aa  = document.getElementById('webWidget').style.visibility = "hidden";
  

}
  ngOnInit() {
    //  this.loadScripts()
  }
}
