import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
import { AppVersionXML } from 'src/app/admin/components/settings/settings';


@Component({
    selector: 'Download',
    templateUrl: './download.component.html',
    //animations: [routerTransition()],
    //host: { '[@routerTransition]': '' }    

})
export class DownloadComponent implements OnInit {

    constructor(
        private _sharedService: SharedService,
        private _router: Router,

    ) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
    }


    ngOnInit() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }

    }
    downloadRDOC() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        this._sharedService.GetSetupVersionByKey(AppVersionXML[0].toString())
        .subscribe({
            next: (response) => {
                var RDOCBaseVersion=response;
                let fileName: string = `latest/RDOC-v${RDOCBaseVersion}-installer.exe`;
        this._sharedService.downloadRDOC(fileName)
            .subscribe({
                next: (response) => {
                    let fileURL = response;
                    console.log(fileURL);
                    var a = document.createElement("a");
                    a.style.display = "none";
                    a.href = fileURL;
                    a.target = '_blank';
                    a.download = fileName;
                    window.document.body.appendChild(a);
                    a.click();
                    window.document.body.removeChild(a);
                    URL.revokeObjectURL(fileURL);
                },
                error: (error) => {
                    console.log(error);
                }
            });
            },
            error: (error) => {
                this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'No file exists' });
                console.log(error);
            }
        });
    }

    downloadRDOCPatch() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }

        let fileName: string = "latest/RDOC-v1.93.30-installer.exe";
        this._sharedService.downloadRDOC(fileName)
            .subscribe({
                next: (response) => {
                    let fileURL = response;
                    console.log(fileURL);
                    var a = document.createElement("a");
                    a.style.display = "none";
                    a.href = fileURL;
                    a.target = '_blank';
                    a.download = fileName;
                    window.document.body.appendChild(a);
                    a.click();
                    window.document.body.removeChild(a);
                    URL.revokeObjectURL(fileURL);
                },
                error: (error) => {
                    console.log(error);
                }
            });
    }

    downloadAUP() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        let containerName: string = "misc";
        let fileName: string = "Documents/Acceptable Use Policy.pdf";
        this._sharedService.downloadAUP(fileName, containerName)
            .subscribe({
                next: (response) => {
                    let fileURL = response;
                    console.log(fileURL);
                    var a = document.createElement("a");
                    a.style.display = "none";
                    a.href = fileURL;
                    a.target = '_blank';
                    a.download = fileName;
                    window.document.body.appendChild(a);
                    a.click();
                    window.document.body.removeChild(a);
                    URL.revokeObjectURL(fileURL);
                },
                error: (error) => {
                    console.log(error);
                }
            });
    }
}


