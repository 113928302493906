export class HelpRequest {
    public FeedType: string;
    public DataType: string;
    public SourceType: string;
    public SourceDetail: string;
    public TargetType: string;
    public TargetDetail: string;
}

export class Help {
    public HelpId: number;
    public FeedType: string;
    public DataType: string;
    public SourceType: string;
    public SourceDetail: string;
    public TargetType: string;
    public TargetDetail: string;
    public HelpContent: string;
    public Status: boolean;
    public CreatedBy: string;
    public CreatedOn: Date;
    public ModifiedBy: string;
    public ModifiedOn: Date;
}