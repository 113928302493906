export class UploadFeedModel {

    constructor(
      public  FeedId?: number,

      public  FileType?: boolean
     
    ) { }

}
