import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from '../../../shared/services/shared.service';


@Injectable()
export class TaskService {

    //#region Global Variables



    //#endregion


    constructor(private _sharedService: SharedService, private http: HttpClient) {
    }

    /// start region service methods


    getStatusTaskColumnsbyViewID(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Task/GetColumnTokens?viewID=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getTaskByID(taskId: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Task/GetTaskByID?TaskID=${taskId}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    deleteTask(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Task/DeleteTask`,body, this._sharedService.getReqOptWithAccessToken());
    }

    deleteTasks(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Task/DeleteTasks`,body, this._sharedService.getReqOptWithAccessToken());
    }

    getLatestRecord(taskId: any, viewSettingId:any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Task/GetLatestRecord?TaskID=${taskId}&viewSettingId=${viewSettingId}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    getMasterStatusReport(asn: any, assetType:any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Task/GetMasterStatusReport?asn=${asn}&assetType=${assetType}`, '', this._sharedService.getReqOptWithAccessToken());
    }


    updateDFPStatus(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Task/UpdateDFPStatus`, body, this._sharedService.getReqOptWithAccessToken());
    }

    addUpdateTask(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Task/AddUpdateTask`, body, this._sharedService.getReqOptWithAccessToken());
    }


    getTaskQueriesbyViewID(viewID: any, taskID: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Task/TaskQueries?viewID=${viewID}&taskID=${taskID}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    getTaskByIDForEdit(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Task/GetTaskByID`, body, this._sharedService.getReqOptWithAccessToken());
    }
    /// end region service methods
    getSearchQueriesbyViewID(viewID: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Task/ViewSearchQueries?viewID=${viewID}`, '', this._sharedService.getReqOptWithAccessToken());
    }

}