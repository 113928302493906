import { CatSecAndArticle } from './../../components/articles/article';
import { articleSectionModel } from './../../components/section/section';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '../../../shared/services/shared.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IDate } from 'ag-grid-community';
import { sectionDetail } from '../../components/articles/article';


@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  private  SectionInfo  =new BehaviorSubject<CatSecAndArticle>({} as CatSecAndArticle);
  currentData = this.SectionInfo.asObservable();

  private ArticleInfo = new BehaviorSubject<sectionDetail>({} as sectionDetail);
  articleCurrentData= this.ArticleInfo.asObservable();

  private  searchArticleInfo = new BehaviorSubject([]);
  currentSearch= this.searchArticleInfo.asObservable();

  private searchkeywords = new BehaviorSubject('');
  currentKeywordSearch= this.searchkeywords.asObservable();


  private sectionName = new BehaviorSubject('');
  currentsectionName= this.sectionName.asObservable();

  constructor(private _sharedService:SharedService,private http: HttpClient) {


  }

       sectionNameChangeInfo(m:any){
       this.sectionName.next(m);
       }
      searchKeywordChangeInfo(m:any){
        this.searchkeywords.next(m);
      }
      searchChangeInfo(m:any){
        this.searchArticleInfo.next(m);
      }
      sectionChangeInfo(message:CatSecAndArticle){
       this.SectionInfo.next(message);
       }

      ArticleChangeInfo(m:sectionDetail){
        this.ArticleInfo.next(m);
      }


       //#region Article Category services
        addArticleCategory(body: any): Observable<any> {
          return this.http.post(`${this._sharedService.baseUrl}/api/Article/AddArticleCategory`,body,this._sharedService.getReqOptWithAccessToken());
          }

        getArticleCategoryDetail(): Observable<any> {
          return this.http.post(`${this._sharedService.baseUrl}/api/Article/GetArticleCategory`, '', this._sharedService.getReqOptWithAccessToken());
          }


        getArticleCategorbyId(body: any): Observable<any> {
          return this.http.post(`${this._sharedService.baseUrl}/api/Article/CategoryDetail?ArticleCategoryId=${body}`, '', this._sharedService.getReqOptWithAccessToken());
           }

        updateArticleCategory(body: any): Observable<any> {
          return this.http.post(`${this._sharedService.baseUrl}/api/Article/UpdateArticleCategory`, body, this._sharedService.getReqOptWithAccessToken());
           }

       //#endregion


        //#region Article Section services
        addArticleSection(body: any): Observable<any> {
          return this.http.post(`${this._sharedService.baseUrl}/api/Article/AddArticleSection`,body,this._sharedService.getReqOptWithAccessToken());
           }

        getArticleSectionyDetail(): Observable<any> {
          return this.http.post(`${this._sharedService.baseUrl}/api/Article/GetArticleSection`, '', this._sharedService.getReqOptWithAccessToken());
          }


        getArticleSectionbyId(body: any): Observable<any> {
          return this.http.post(`${this._sharedService.baseUrl}/api/Article/SectionDetail?ArticleSectionId=${body}`, '', this._sharedService.getReqOptWithAccessToken());
           }

        updateArticleSection(body: any): Observable<any> {
          return this.http.post(`${this._sharedService.baseUrl}/api/Article/UpdateArticleSection`, body, this._sharedService.getReqOptWithAccessToken());
            }
      //#endregion

       //#region Article Section services
        addArticle(body: any): Observable<any> {
          return this.http.post(`${this._sharedService.baseUrl}/api/Article/AddArticle`,body,this._sharedService.getReqOptWithAccessToken());
          }

        getListArticleCatgory(body: any): Observable<any> {
          return this.http.post(`${this._sharedService.baseUrl}/api/Article/GetArticleCategoryLst`,body,this._sharedService.getReqOptWithAccessToken());
          }

        getArticleSectionbyCategory(body: any): Observable<any> {
          return this.http.post(`${this._sharedService.baseUrl}/api/Article/GetArticleSectionbyCategory?ArticleCategoryId=${body}`,this._sharedService.getReqOptWithAccessToken());
          }

          getArticleSectionById(body: any): Observable<any> {
            return this.http.post(`${this._sharedService.baseUrl}/api/Article/GetArticleSectionsbyId?ArticleSectionId=${body}`,this._sharedService.getReqOptWithAccessToken());
            }

        getArticleDetail(): Observable<any> {
          return this.http.post(`${this._sharedService.baseUrl}/api/Article/GetArticle`, this._sharedService.getReqOptWithAccessToken());
          }

        getArticlesForSearch(body: any): Observable<any> {
          return this.http.get(`${this._sharedService.baseUrl}/api/Article/GetArticlesForSearch?articleQuery=${body}`, this._sharedService.getReqOptWithAccessToken());
          }

        getArticlebyId(body: any): Observable<any> {

          return this.http.post(`${this._sharedService.baseUrl}/api/Article/Detail?ArticleId=${body}`, '', this._sharedService.getReqOptWithAccessToken());
            }

        relatedArticles(body: any): Observable<any> {

              return this.http.post(`${this._sharedService.baseUrl}/api/Article/RelatedArticles?ArticleSectionId=${body}`, '', this._sharedService.getReqOptWithAccessToken());
                }

        updateArticle(body: any): Observable<any> {
          return this.http.post(`${this._sharedService.baseUrl}/api/Article/UpdateArticle`, body, this._sharedService.getReqOptWithAccessToken());
            }


        getArticleDetailByCategory(id): Observable<any> {
          return this.http.post(`${this._sharedService.baseUrl}/api/Article/view?CategoryId=${id}`, '', this._sharedService.getReqOptWithAccessToken());
          }
        getArticleDetailByComponent(componentName): Observable<any> {
          return this.http.post(`${this._sharedService.baseUrl}/api/Article/ComponentArticles?componentName=${componentName}`, '', this._sharedService.getReqOptWithAccessToken());
          }

          deleteArticle(body: any): Observable<any> {
            return this.http.post(`${this._sharedService.baseUrl}/api/Article/deleteArticle?ArticleId=${body}`, '', this._sharedService.getReqOptWithAccessToken());
              }

         //#endregion


}
