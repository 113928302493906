//#region References
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';

import { SharedService } from '../../shared/services/shared.service';
import { datafeed } from '../components/list-datafeed/data-feed';
import { feedlog } from '../components/feed-log/feed-log';
import { runJob } from '../../status/components/view-list/run-Job'
import * as moment from 'moment/moment';
import { KeyValPair } from '../../records/services/container/container';
import { AFSModel,EncryptionAndDecryption } from '../components/add-datafeed/add-datafeed';
const allowedFileTypes: string[] = ['xlsx', 'XLSX', 'xml', 'XML', 'txt', 'TXT', 'csv', 'CSV'];
//#endregion

@Injectable()
export class APIService {
    constructor(private http: HttpClient, private _sharedService: SharedService) {
    }

    getDataFeeds(body: datafeed): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetDataFeeds`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getWoGapManualOutgoingParams():Observable<KeyValPair[]>{
        return this.http.post<KeyValPair[]>(`${this._sharedService.baseUrl}/api/APIModule/GetWoGapManualOutgoingParams`, null, this._sharedService.getReqOptWithAccessToken());
    }

    getFeedDataForTransferScan(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetFeedDataForTransferScan`, null, this._sharedService.getReqOptWithAccessToken());
    }
    addFeedData(body: datafeed): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/AddFeedData`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getFeedDataByID(body: datafeed): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetFeedDataByID`, body, this._sharedService.getReqOptWithAccessToken());
    }
    updateFeedData(body: datafeed): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/UpdateFeedData`, body, this._sharedService.getReqOptWithAccessToken());
    }
    updateFeed(body: datafeed): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/UpdateFeed`, body, this._sharedService.getReqOptWithAccessToken());
    }

    updateFeedDataType(body: datafeed): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/UpdateFeedDataType`, body, this._sharedService.getReqOptWithAccessToken());
    }

    updateLink(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/UpdateLink`, body, this._sharedService.getReqOptWithAccessToken());
    }

    updateTempFeed(body: datafeed): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/UpdateTempFeed`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getDataFeedLog(body: feedlog): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetDataFeedLog`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getMyReport(sourceType:any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetMyReport?sourceType=${sourceType}`, this._sharedService.getReqOptWithAccessToken());
    }
    downloadFeedFile(body: feedlog): Observable<any> {
        
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/DownloadFeedFile`, body, this._sharedService.getReqOptWithAccessTokenForZipFile());
    }
    DownloadLinkForReport(body: feedlog): Observable<any> {        
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/DownloadLinkForReport`, body, this._sharedService.getReqOptWithAccessToken());
    }


    downloadSearchDocument(docUrl:string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Search/DownloadSearchDocument?docName=${docUrl}`, '', this._sharedService.getReqOptWithAccessTokenForTextFile());
  }
  
  SaveExportContainerApiMapping(feedId:string, mapping:string): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/SaveExportContainerApiMapping?username=${this._sharedService.getCurrentUserName()}&feedId=${feedId}&mapping=${mapping}`,'', this._sharedService.getReqOptWithAccessToken());
  }
  
  GetFeedMappingById(feedId:string): Observable<any> {
      return this.http.get(`${this._sharedService.baseUrl}/api/APIModule/GetFeedMappingById?feedId=${feedId}`, this._sharedService.getReqOptWithAccessToken());
    }
  RunTempFeed(feedId:string): Observable<any> {
    return this.http.get(`${this._sharedService.baseUrl}/api/APIModule/RunTempFeed?feedId=${feedId}`, this._sharedService.getReqOptWithAccessToken());
    }




    getSourceType() {
        let sourceTypeList = [
            { value: 'File Storage', label: 'File Storage' },
            { value: 'FTP', label: 'FTP' },
            { value: 'Shared Folder', label: 'Shared Folder' },
            { value: 'Status', label: 'Status' },
            { value: 'Web Service', label: 'Web Service' },
            { value: 'Records', label: 'Records' },
            // { value: 'Status Link', label: 'Status Link' }
        ];
        return sourceTypeList;
    }
    getSourceOutgoinType() {
        let sourceTypeList = [
            { value: 'Email Link', label: 'Email Link' },
            { value: 'File Storage', label: 'File Storage' },
            // { value: 'Shared Folder', label: 'Shared Folder' },
            { value: 'FTP', label: 'FTP' },
            // { value: 'Web Service', label: 'Web Service' }
        ];
        return sourceTypeList;
    }
    getSourceOutgoinTypeRecords() {
        let sourceTypeList = [
            { value: 'File Storage', label: 'File Storage' },
            // { value: 'Shared Folder', label: 'Shared Folder' },
            // { value: 'Email Link', label: 'Email Link' },
            { value: 'FTP', label: 'FTP' },
            { value: 'Web Service', label: 'Web Service' }
        ];
        return sourceTypeList;
    }
    getSourceOutgoinTypeRDOC() {
        let sourceTypeList = [
            { value: 'File Storage', label: 'File Storage' },
            { value: 'FTP', label: 'FTP' },
            // { value: 'Email Link', label: 'Email Link' },
            { value: 'Shared Folder', label: 'Shared Folder' },
            { value: 'Web Service', label: 'Web Service' }
        ];
        return sourceTypeList;
    }


    getSourceOutgoinTypeRDOCDocumentExport() {
        let sourceTypeList = [
            { value: 'FTP', label: 'FTP' },
            { value: 'Shared Folder', label: 'Shared Folder' },
            // { value: 'Email Link', label: 'Email Link' },
        ];
        return sourceTypeList;
    }

    getSourceTypesForStatusIncoming() {
        let sourceTypeList = [
            { value: 'File Storage', label: 'File Storage' },
            { value: 'FTP', label: 'FTP' },
            { value: 'Web Service', label: 'Web Service' }
        ];
        return sourceTypeList;
    }
    getDataType() {
        let dataTypeList = [
            { value: 'CSV', label: 'CSV - Comma Delimited' },
            { value: 'TSV', label: 'CSV - Tab Delimited' },
            { value: 'XLSX', label: 'XLSX' },
            { value: 'XML', label: 'XML' }
        ];
        return dataTypeList;
    }
    getOutgoingDataType() {
        let dataTypeList = [
            { value: 'CSV', label: 'CSV - Comma Delimited' },
            { value: 'TSV', label: 'CSV - Tab Delimited' },
            { value: 'PDF', label: 'PDF' },
            { value: 'XLSX', label: 'XLSX' },
            { value: 'XML', label: 'XML' }
        ];
        return dataTypeList;
    }
    getOutgoingDataTypeInteractive() {
        let dataTypeList = [
            { value: 'XML', label: 'XML' }
        ];
        return dataTypeList;
    }

    getOutgoingDataTypeRDOCDocumentExport() {
        let dataTypeList = [
            { value: 'PDF', label: 'PDF' }
        ];
        return dataTypeList;
    }

    getIncomingDataTypeInteractive() {
        let dataTypeList = [
            { value: 'XLSX', label: 'XLSX' },
            { value: 'XML', label: 'XML' }
        ];
        return dataTypeList;
    }
    getTargetDetailsList(){
        let targetDetailList = [
            { value: 'Document Metadata', label: 'Document Metadata' },
            { value: 'MX Records-Gap Analysis', label: 'MX Records-Gap Analysis' },
        ];

        return targetDetailList;

    }
    getTargetDetailsListRecord(){
        let targetDetailList = [
            { value: 'MX Records-Gap Analysis', label: 'MX Records-Gap Analysis' },
            { value: 'Container', label: 'Container' },
        ];

        return targetDetailList;

    }
    getTargetDetailsListRDOC(){
        let targetDetailList = [
            { value: 'Document Metadata', label: 'Document Metadata' },
        ];

        return targetDetailList;

    }
    //type
    getTargetModule() {

        let targetModuleList = [
            { value: 'RDOC', label: 'RDOC' },
            { value: 'Records', label: 'Records' },
            { value: 'Status', label: 'Status' },
            { value: 'Status Link', label: 'Status Link' }
        ];

        return targetModuleList;
    }
    getTargetTypeIncomingSharedFolder() {

        let targetModuleList = [
            { value: 'RDOC', label: 'RDOC' }
        ];

        return targetModuleList;
    }
    getTargetTypeIncomingStatus() {

        let targetModuleList = [
            { value: 'Records', label: 'Records' },
            { value: 'RDOC', label: 'RDOC' }
        ];

        return targetModuleList;
    }

    getTargetTypeForRecordsIncomingStatus() {

        let targetModuleList = [
            { value: 'RDOC', label: 'RDOC' }
        ];

        return targetModuleList;
    }
    getTargetModuleOutgoing() {

        let targetModuleList = [
            { value: 'RDOC', label: 'RDOC' },
            { value: 'Records Export', label: 'Records Export' },
            { value: 'Records', label: 'Records Link' },
            { value: 'Status Export', label: 'Status Export' },
            { value: 'Status Link', label: 'Status Link' },

        ];

        return targetModuleList;
    }
    getSourceDetailOutgoingForRecord() {

        let targetModuleList = [           
            { value: 'Document URL', label: 'Document URL' }
        ];

        return targetModuleList;
    }

    getSourceDetailForRecord() {

        let targetModuleList = [           
            { value: 'Container', label: 'Container' }
        ];

        return targetModuleList;
    }

    getSourceDetailOutgoingForRDOC() {

        let targetModuleList = [           
            { value: 'Document URL', label: 'Document URL' },
            { value: 'Document Export', label: 'Document Export' }
        ];

        return targetModuleList;
    }

    getIntervalDetails() {
        let intervalList = [
            { value: 'EveryTime', label: 'Every Time' },
            { value: 'Daily', label: 'Daily' },
            { value: 'Weekly', label: 'Weekly' },
            { value: 'Monthly', label: 'Monthly' },
            { value: 'None', label: 'None' }
        ];
        return intervalList;
    }
    getIntervalDetailsInteractive() {
        let intervalList = [
            { value: 'None', label: 'None' }
        ];
        return intervalList;
    }
    getFileMappingDetails(feedId: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetFileMappingDetails?feedId=${feedId}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    getStatusMapping(feedId: string, viewType:string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetStatusFeedMapping?feedId=${feedId}&viewType=${viewType}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    getFileColumns(feedId: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetFileColumns?feedId=${feedId}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    getMapColumns(feedId: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetColumnByViewName?feedId=${feedId}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    addUpdateMapping(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/AddUpdateMapping`, body, this._sharedService.getReqOptWithAccessToken());
    }

    addUpdateRecordMapping(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/AddUpdateRecordMapping`, body, this._sharedService.getReqOptWithAccessToken());
    }

    addUpdateStatusMapping(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/AddUpdateStatusMapping`, body, this._sharedService.getReqOptWithAccessToken());
    }

    // runFeed(feedId: string): Observable<any> {
    //     return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/RunFeed?feedId=${feedId}`, '', this._sharedService.getReqOptWithAccessToken());
    // }

    runFeed(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/RunFeed`, body, this._sharedService.getReqOptWithAccessToken());
    }

    runOutgoingFeed(feedId: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/RunOutgoingFeed?feedId=${feedId}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    checkTempOutgoingFeed(feedId: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/CheckTempOutgoingFeed?feedId=${feedId}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    getUploadedFiles(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetFilesByID`, body, this._sharedService.getReqOptWithAccessToken());
    }
    downloadAttachedFile(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/DownloadAttachedFile`, body, this._sharedService.getReqOptWithAccessTokenForZipFile());
    }
    downloadFeedLogFile(body: feedlog): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/DownloadFeedLogFile`, body, this._sharedService.getReqOptWithAccessTokenForZipFile());
    }
    getFeedDataByIDForRecord(body: datafeed): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetFeedDataByIDForRecord`, body, this._sharedService.getReqOptWithAccessToken());
    }
    addFeedAndReturnFeedID(body: datafeed): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/AddFeedAndReturnID`, body, this._sharedService.getReqOptWithAccessToken());
    }
    addUpdateRecordMappingAndRunFeed(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/AddUpdateRecordMappingAndRunFeed`, body, this._sharedService.getReqOptWithAccessToken());
    }
    removeTempFeed(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/RemoveTempFeed`, body, this._sharedService.getReqOptWithAccessToken());
    }
    addUpdateStatusMappingAndRunFeed(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/AddUpdateStatusMappingAndRunFeed`, body, this._sharedService.getReqOptWithAccessToken());
    }
    validMail(mail: string): boolean {
        let status: boolean = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{1,}|[\d\.]+))$/.test(mail);
        if (status) {
            return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(mail);
        }
        else {
            return status;
        }
    }
    getTempFeedName() {
        // let feedName = "Temp_" + this._sharedService.getCurrentUserName().replace(/\W+/g, "") + "_" + moment(Date.now()).format("YYYYMMDDHHmmSSS").replace(/\W+/g, "");
        let feedName = this._sharedService.getCurrentUserName().replace(/\W+/g, "") + "_" + moment(Date.now()).format("YYYYMMDDHHmmSSS").replace(/\W+/g, "");
        return feedName;
    }
    getTempFeedTimeStamp() {
        let feedName =  moment(Date.now()).format("YYYYMMDDHHmmSSS").replace(/\W+/g, "");
        return feedName;
    }
    deleteIncomingFeed(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/DeleteIncomingFeed`, body, this._sharedService.getReqOptWithAccessToken());
    }
    deleteOutgoingFeed(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/DeleteOutgoingFeed`, body, this._sharedService.getReqOptWithAccessToken());
    }
    copyMappingToFeed(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/CopyMappingToFeed`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getDataFeedsListCopy(body: datafeed): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetFeedListCopyMapping`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getAllowedFileTypes(): string[] {

        return allowedFileTypes;
    }
    getFeedDataType(feedId: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetFeedDataType?feedId=${feedId}`, '',this._sharedService.getReqOptWithAccessToken());
    }
    GetFeedDataTypeFromTemplate(templateName: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetFeedDataTypeFromTemplate?templateName=${templateName}`, '',this._sharedService.getReqOptWithAccessToken());
    }
    getFeedTemplates(feedType: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetFeedTemplates?feedType=${feedType}`, '',this._sharedService.getReqOptWithAccessToken());
    }
 
    runAutoSearch(body: runJob): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/RunSearchFunction`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getEncryptedString(stringvalue: any): Observable<any> {
        var encryption: EncryptionAndDecryption= new EncryptionAndDecryption();
        encryption.Value=stringvalue;
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetEncryptedString`, encryption,this._sharedService.getReqOptWithAccessToken());
        // return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetEncryptedString?stringvalue=${value}`, '',this._sharedService.getReqOptWithAccessToken());
    }
    getEncryptedAzureFileStorageConnectionObject(value: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetEncryptedAzureStorageConnectionModel`, value,this._sharedService.getReqOptWithAccessToken());
    }
    getdecryptedString(value: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetdecryptedString`,value ,this._sharedService.getReqOptWithAccessToken());
    }
    getFtpDetails(value: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetFtpDetails?value=${value}`,'',this._sharedService.getReqOptWithAccessToken());
    }
    getAsfDetails(value: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetAsfDetails?value=${value}`,'',this._sharedService.getReqOptWithAccessToken());
    }
    getWebServiceDetails(value: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/GetWebServiceDetails?value=${value}`,'',this._sharedService.getReqOptWithAccessToken());
    }
    ExportFeedData(feedId: string) {
        return this.http.post<string>(`${this._sharedService.baseUrl}/api/APIModule/ExportFeedData?feedId=${feedId}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    createFeedFromFileBlobStorage(templateType:number,fileNmae:string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/APIModule/CreateFeedFromFileBlobStorage?templateid=${templateType}&fileNmae=${fileNmae}`, '', this._sharedService.getReqOptWithAccessToken());
    }
}
