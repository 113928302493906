import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from '../../../shared/services/shared.service';

@Injectable()
export class AuditTableService {

    //#region Global Variables


    //#endregion


    //#region Init

    constructor(private http: HttpClient, private _sharedService: SharedService) {
    }

    //#endregion

    //#region service methods

    getAuditTableData(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AuditTable/GetAuditTableData`, null,this._sharedService.getReqOptWithAccessToken());
    }

    getEntityFieldNames(entityName:string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AuditTable/GetEntityFieldNames?entityName=${entityName}`, null, this._sharedService.getReqOptWithAccessToken());
    }

    
    //#endregion


}