//Components
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, Params, NavigationEnd, NavigationStart } from '@angular/router';
import { GridOptions, GridApi, ColumnApi,AgEvent,AgGridEvent } from "ag-grid-community";

@Component({
    selector: 'view-job-button',
    changeDetection:ChangeDetectionStrategy.OnPush,
    templateUrl: './view-job-button.component.html',

})
export class ViewLinkedRecordComponent implements OnInit {
    paramsGlobal: any;
    index: number = 0;
    constructor(private cRef:ChangeDetectorRef) {

    }
    agInit(params:any)
    {
        this.paramsGlobal = params;
        this.index = params.data.DpJobId;
    }
    viewRecord()
    {
        this.paramsGlobal.context.gridContext.clickedJobRow(this.paramsGlobal);
        this.paramsGlobal.context.gridContext.viewItem();
        this.cRef.markForCheck();
    }
    ngOnInit() {
    }

}