//Components
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { UserService } from '../../../records/services/User/user.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import { OAuthService, AuthConfig, JwksValidationHandler  } from 'angular-oauth2-oidc';
import { IConfig } from '../../helpers/config';
import { ContainerService } from '../../../records/services/container/container.service';

//interfaces

@Component({
    selector: 'sso',
    templateUrl: './sso.component.html',
    providers: [UserService, ContainerService]
})

export class SSOComponent  {
    private jwtHelper: JwtHelperService = new JwtHelperService();
    loginResponse: any;
    authConfigConfig:any
    email:any
    error:any
    ssoError:any
    display:boolean=false;
    constructor(private _sharedService: SharedService, private _containerService: ContainerService, private _userService: UserService,private oauthService: OAuthService, private _router:Router) {
       
        if(window.location.href.indexOf('access_token')> 0){
            this._sharedService.ssoEnabled = true;
            this._sharedService.showLoader('Validating User...');
            this._userService.getTenantBySubDomain(this._sharedService.getSubdomain())
            .subscribe({
                next: (response) => {
                    if (response) {
                        let config = JSON.parse(response.value);
        
                        var authConfig: AuthConfig=config.Configuration;
                        this.authConfigConfig=config.Configuration;
                        var customConfig=config.CustomAttribute;
        
                        this.oauthService.configure(authConfig);
        
                        if(customConfig.ssoProvider.toLowerCase()=="adfs" && customConfig.ver == "2016")
                        {
                            this.oauthService.tryLogin()
                            .then(() => {
                                this.oauthService.tokenValidationHandler = new JwksValidationHandler();
                                if (this.oauthService.hasValidAccessToken()) {
                                    this.ProcessSSO(config.CustomAttribute);
                                } else {
                                  this.oauthService.initImplicitFlow();
                                }
                              }); 
                        }
                        else{
                            this.oauthService.loadDiscoveryDocument()
                            .then(() => this.oauthService.tryLogin())
                            .then(() => {
                                this.oauthService.tokenValidationHandler = new JwksValidationHandler();
                                if (this.oauthService.hasValidAccessToken()) {
                                    this.ProcessSSO(config.CustomAttribute);
                                } else {
                                  this.oauthService.initImplicitFlow();
                                }
                              });
                        }
                    }
                },
                error: (error) => {
                    console.log(error);
                }
            });
            
        }else{
            this._userService.getTenantBySubDomain(this._sharedService.getSubdomain())
            .subscribe({
                next: (response) => {
                    if (response) {
                        this._sharedService.ssoEnabled = true;
                        let authConfig = JSON.parse(response.value);
                        this._sharedService.configureSSOAuth(authConfig);
                    } else {
        
                        this._router.navigate(['login']);
                    }
                },
                error: (error) => {
                    console.log(error);
                    this._router.navigate(['login']);
                }
            }); 
        }
    }

    ngOnInit() {}
     ProcessSSO(tokenCustomAttribute){
        debugger;
        this._sharedService.showLoader('Loading user profile...');
        let id_token =null
        if(tokenCustomAttribute.token == "access_token"){
            id_token = this.jwtHelper.decodeToken(this.oauthService.getAccessToken());
        }
        else{
            id_token = this.jwtHelper.decodeToken(this.oauthService.getIdToken()); 
        }

         let email = this.getEmailFromIdToken(id_token, tokenCustomAttribute);
         this.email=email;
         if (!email) {
             console.log("email field: " + tokenCustomAttribute.emailField)
         }

        //let body = this._sharedService.getLoginBody(email, this.oauthService.getIdToken()) + '&scope=sso';
        let body = this._sharedService.getLoginBody(email, this.oauthService.getIdToken(),true);

         let tokenObject = this._userService.loginAndGetToken(this._sharedService.baseUrl, body)
         .subscribe({
            next: (response) => {
                this.loginResponse = response;
                localStorage.setItem('access_token', this.loginResponse['access_token']);
        
                // Validate External/ Internal User
                    this._userService.getTenantInfoExternal(this._sharedService.baseUrl, null)
                    .subscribe({
                        next: (response) => {
                            this._sharedService.getConfigurationFromDB(this._sharedService.baseUrl, null)
                            .subscribe({
                                next: (response) => {
                                    this._sharedService.setConfiguration(response as IConfig[]);
                                    this._sharedService.getDateTimeFormatConfiguration();
                
                                        this.setLocalStroageKeys();
                                        this.getContainerTypes();
                                        //this._router.navigate(['record']);
                                        this.navigateToHome();
                                        this._sharedService.hideLoader();
                                },
                                error: (error) => {
                                    console.log(error);
                                }
                            });
                        },
                        error: (error) => {
                            console.log(error);
                        }
                    })
            },
            error: (error) => {
                this._sharedService.hideLoader();
                this.ssoError=error
                this.display=true;
                if(error.error.error == "invalid_User"){
                    this.error=error.error.error_description + this.email
                }
                else{
                    this.error=error.error.error_description
                }
                 console.log(error);
            }
        })
 
     }
    redirect() {

        this._userService.logout();
        window.location.href = this.authConfigConfig.logoutUrl;
    }
     getEmailFromIdToken(id_token,tokenCustomAttribute){
        // if(id_token.hasKey(tokenCustomAttribute.emailField.valueOf())=="preferred_username"){
        if(id_token.hasOwnProperty(tokenCustomAttribute.emailField)){
            return id_token[tokenCustomAttribute.emailField]
            // return id_token.preferred_username;
        }
        // //AAD
        // if(id_token.preferred_username){ return id_token.preferred_username;}

        //Auth0

        // if(tokenCustomAttribute.emailField.valueOf()=="name"){
        //     return id_token.name;
        // }
        // // //Auth0
        // // if(id_token.name){ return id_token.name;}
        // //ADFS
        // if(tokenCustomAttribute.emailField.valueOf()=="email"){
        //     return id_token.email;
        // }
        // //ADFS
        // // if(id_token.upn){ return id_token.upn;}

        return null;
     }

     setLocalStroageKeys() {
        var homeDashboardSettings = null;
        homeDashboardSettings = (this.loginResponse['homeDashboardSettings'] == "" || this.loginResponse['homeDashboardSettings'] == "null"|| this.loginResponse['homeDashboardSettings'] == null) ? this._sharedService._configuration.find((x=>x.Key=="Home_Dashboard_Settings")).Value:this.loginResponse['homeDashboardSettings'];
        //localStorage.setItem('access_token', this.loginResponse['access_token']);
        localStorage.setItem('expires', this.loginResponse['expires']);
        localStorage.setItem('token_type', this.loginResponse['token_type']);
        localStorage.setItem('userName', this.loginResponse['userName']);
        localStorage.setItem('firstName', this.loginResponse['firstName']);
        localStorage.setItem('lastName', this.loginResponse['lastName']);
        localStorage.setItem('roles', this.loginResponse['roles']);
        localStorage.setItem('userType', this.loginResponse['userType']);
        localStorage.setItem('HomeDashboardSettings', homeDashboardSettings);

        //Update Roles Array
        this._sharedService.updateRole();
    }

    getContainerTypes(): void {

        this._containerService.getAllContainerTypesData(null)
        .subscribe({
            next: (response) => {
                this._sharedService.setContainerTypes(response);
            },
            error: (error) => {
                console.log(error);
            }
        });
    }

    navigateToHome() {
        if (this._sharedService.isViewDocOnly) {
            this._router.navigate(['Document']);
            // if (this._sharedService.UserRole.Records_SingleDocument_View) {
            //     this._router.navigate(['Document']);
            // } else {
            //     this._sharedService.showToast({ severity: 'error',life:8000, summary: 'Unauthorized!', detail: 'You are not authorized to Single Document View module. Please contact your ROAM administrator.' });
            //     this._router.navigate(['record']);
            // }
        } else {
            this._router.navigate(['dashboard','record']);
        }
    }

}