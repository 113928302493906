
<!-- MAIN PANEL -->
<div role="main" style="background-color: #fbfbfb !important;" >



<!-- MAIN CONTENT -->
<div id="content" style="opacity: 1;">
    <section id="widget-grid-main" class="well"  style="height: 91vh;overflow-x: hidden; overflow-y: scroll; border: none !important;">
        <div id="content">
          <div class="row" style="padding-left:6%;padding-right:6%;">
            <div class="col-sm-12" >
            <app-search-article ></app-search-article>
          </div>
         </div>
            <!-- row -->
            <div class="row"  style="padding-left:6%;padding-right:6%;margin-top:1em;">
                <div class="col-sm-12">
                    <div class="well padding-10" style="background-color: #fff;">
                        <!-- <div class="forum-attachment" style="margin-top: 1em;">
                            <a routerLink="/help/helparticles"  href="javascript:void(0);"  style="color: #3276b1 !important; "> Solution Home</a> /
                            <a routerLink="/help/helparticles"   href="javascript:void(0);" style="color: #3276b1 !important;"> ROAM Help Center</a>
                           </div> -->
                      <h1 class="page-title txt-color-blueDark " > Search results for " {{searchedKeyword }} " </h1>

                      <p  *ngIf="searchResults?.length == 0">  {{NotFound}}  </p>

                         <table class="table table-forum">
                          <tbody>
                           <tr *ngFor="let article  of searchResults;">
                                        <td class="text-center" style="width: 40px;">
                                          <i class="fa fa-files-o fa-2x text-muted"></i></td>
                                         <td style="padding-left: 1em;">
                                            <h6 >

                                              <a href="javascript:void(0);" style="text-decoration: none;color:#4c4f53!important;" (click)="redirectArticle(article.ArticleId)"  innerHTML="{{article.Title | searchighlight:searchedKeyword}}" >

                                              </a>
                                            </h6>
                                              <p style="line-height:1.5em;height: 3em;overflow: hidden;"  innerHTML="{{article.ArticleContentPlainText | searchighlight:searchedKeyword}}"> </p>
                                              <small> {{article.ModifiedOn | date : "medium"}}</small>

                                          </td>
                                      </tr>

                         </tbody>
                      </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
