import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { SharedService } from '../../shared/services/shared.service';


@Injectable()
export class StatusService {
    constructor(private http: HttpClient, private _sharedService: SharedService) {
    }

    getViewListData(body: any): any {
        return [{
            TaskID: "100",
            ASN: "28199",
            EffectiveDate: "29-JUL-96",
            TaskType: "Y",

            TaskStatus: "Auto no documents found",

            Issues: "",

            Text1: "FAA AD",
            Text2: "74-08-09R3",
            Text3: "Rev. 03",

            Text4: "CL_FAA AD 74-08-09R3 NG_FAA AD 74-08 -09R3 B737- 400 AMP B38- TWI - 01",
            Test5: "LAVATORY PAPER OR LINEN WASTE REC",
            Text6: "Y",
            Text7: "Open",

            //Threshhold
            Text8: "",     	//FH
            Text9: "",     // FC
            Text10: "",   // Day

            //Interval
            Text11: "1000:00", 	//FH
            Text12: "",            // FC
            Text13: "",           // Day

            //Last Accomplishment
            Date1: "28/09/16",		//date
            Text14: "59125:01",	 //FH
            Text15: "29856",	         //FC

            // Next Due 
            Date2: "",	//date
            Text16: "60125:01", //FH
            Text17: "", //FC

            // Time Remaining
            Text18: "595:22", 	//FH
            Text19: "",  // FC
            Text20: "", // Day

            // Remarks
            Text21: "ALASKA W/C: 48138000. TWI TASK CARD: B38 - TWI - 01",
            WO: "9119",
            Url:'https://amac1.blob.core.windows.net/25107-26072017/25107/OOP/25072017-26072017/1280-123789-Aircraft%20Accountability%20Countdown%20Sheet-20170712-1280-Technical-1000-005-a-20170711121935?sv=2016-05-31&sig=kYKbYXMybIgYC%2Bl0geOWUyte0t4crvMolo8sGSoT7ZI%3D&spr=https&st=2017-10-02T05%3A58%3A28Z&se=2017-10-02T07%3A58%3A28Z&srt=co&ss=b&sp=r#zoom=auto&search="null"'
        },
        {
            TaskID: "101",
            ASN: "28199",
            EffectiveDate: "01-JUN-11",
            TaskType: "N",

            TaskStatus: "Auto partial documents found",

            Issues: "Issue",

            Text1: "EASA AD 2011-0090R1/AD",
            Text2: "2015-08-07",
            Text3: "Rev. 01",

            Text4: "B737-400 EO 4I35-002 TWI- F - EM - 03 4I35-002 INTERTECHNIQUE SB MXH-35 - 240",//References

            Test5: "OXYGEN - OXYGEN MASK REGULATOR INFLATABLE HARNESS - IDENTIFICATION / REPLACEMENT",//Description

            Text6: "N", //Type

            Text7: "CLOSED",//Status

            //Threshhold
            Text8: "",     	//FH
            Text9: "",     // FC
            Text10: "730",   // Day

            //Interval
            Text11: "", 	//FH
            Text12: "",            // FC
            Text13: "",           // Day

            //Last Accomplishment
            Date1: "25/07/12",		//date
            Text14: "48480:17",	 //FH
            Text15: "26276",	         //FC

            // Next Due 
            Date2: "",	//date
            Text16: "", //FH
            Text17: "", //FC

            // Time Remaining
            Text18: "", 	//FH
            Text19: "",  // FC
            Text20: "", // Day

            // Remarks
            Text21: "TWI FLEET HAS P/N:MC10-25-104 FLIGHT CREW OXY MASKS & REGULATORS",
            WO: "",
            Url: 'https://amac1.blob.core.windows.net/25107-26072017/25107/OOP/25072017-26072017/1280-123789-Aircraft%20Accountability%20Countdown%20Sheet-20170712-1280-Technical-1000-005-a-20170711121935?sv=2016-05-31&sig=kYKbYXMybIgYC%2Bl0geOWUyte0t4crvMolo8sGSoT7ZI%3D&spr=https&st=2017-10-02T05%3A58%3A28Z&se=2017-10-02T07%3A58%3A28Z&srt=co&ss=b&sp=r#zoom=auto&search="null"'
        },
        {
            TaskID: "102",
            ASN: "28199",
            EffectiveDate: "10-NOV-88",
            TaskType: "Y",

            TaskStatus: "Auto documents found",

            Issues: "",

            Text1: "FAA AD",
            Text2: "88-22-09",
            Text3: "Rev. 00",

            Text4: "B737-400 AMP 31-006-01 B737-400 AMP 31-006 - 01",//References

            Test5: "TAKEOFF CONFIGURATION WARNING SYSTEM AMOC:ANM-130S-8040 - 7 / AH: 1-OPERATIONAL CHECK THE AURAL WARNING MODULE; INTERVAL NOT EXCEED 40 FH.2-FUNCTIONALLY CHECK THE TAKE-OFF WARNING SYSTEM; TWI INTERVAL 500 FH",//Description

            Text6: "Y", //Type

            Text7: "OPEN",//Status

            //Threshhold
            Text8: "",     	//FH
            Text9: "",     // FC
            Text10: "",   // Day

            //Interval
            Text11: "500:00", 	//FH
            Text12: "",            // FC
            Text13: "",           // Day

            //Last Accomplishment
            Date1: "09/01/17",		//date
            Text14: "59529:39",	 //FH
            Text15: "30049",	         //FC

            // Next Due 
            Date2: "",	//date
            Text16: "60029:39", //FH
            Text17: "", //FC

            // Time Remaining
            Text18: "500:00", 	//FH
            Text19: "",  // FC
            Text20: "", // Day

            // Remarks
            Text21: "1-AURAL WARNING MODULE TEST IS MONITORING AT DAILY CHECK WITH BTC 31-002 - 01 2-TAKE - OFF WARNING SYSTEM TEST IS MONITORING AT 500 FH INTERVAL WITH BTC 31- 006 - 01 AMOC ANM-130S-8040-7/AH APPROVED BY TURKISH DGCA FOR TWI FLEET.",
            WO: "9515"
        },
        {
            TaskID: "103",
            ASN: "28199",
            EffectiveDate: "31-JUL-90",
            TaskType: "Y",

            TaskStatus: "Documents found",

            Issues: "",

            Text1: "FAA AD",
            Text2: "90-12-11-R1",
            Text3: "Rev. 01",

            Text4: "BSB-73725A1270_R2 B737-400 EO 4I25-003 TWI-F -",//References

            Test5: "ESCAPE SLIDE RELEASE CABLE",//Description

            Text6: "Y", //Type

            Text7: "OPEN",//Status

            //Threshhold
            Text8: "",     	//FH
            Text9: "",     // FC
            Text10: "",   // Day

            //Interval
            Text11: "", 	//FH
            Text12: "",            // FC
            Text13: "365",           // Day

            //Last Accomplishment
            Date1: "11/10/16",		//date
            Text14: "59154:46",	 //FH
            Text15: "29877",	         //FC

            // Next Due 
            Date2: "11/10/17",	//date
            Text16: "", //FH
            Text17: "", //FC

            // Time Remaining
            Text18: "", 	//FH
            Text19: "",  // FC
            Text20: "271 D", // Day

            // Remarks
            Text21: "1-AURAL WARNING MODULE TEST IS MONITORING AT DAILY CHECK WITH BTC 31-002 - 01 2-TAKE - OFF WARNING SYSTEM TEST IS MONITORING AT 500 FH INTERVAL WITH BTC 31- 006 - 01 AMOC ANM-130S-8040-7/AH APPROVED BY TURKISH DGCA FOR TWI FLEET.",
            WO: "2560"
        },
        {
            TaskID: "104",
            ASN: "28199",
            EffectiveDate: "23-DEC-92",
            TaskType: "N",

            TaskStatus: "Partial documents found",

            Issues: "Issue",

            Text1: "FAA AD",
            Text2: "92-25-04",
            Text3: "Rev. 00",

            Text4: "BSB 737-35A1037 BSB 737- 35A1038",//References

            Test5: "TEST STOP PLUNGERS",//Description

            Text6: "N", //Type

            Text7: "NOT-APP",//Status

            //Threshhold
            Text8: "",     	//FH
            Text9: "",     // FC
            Text10: "",   // Day

            //Interval
            Text11: "180:00", 	//FH
            Text12: "",            // FC
            Text13: "900",           // Day

            //Last Accomplishment
            Date1: "",		//date
            Text14: "",	 //FH
            Text15: "",	         //FC

            // Next Due 
            Date2: "",	//date
            Text16: "", //FH
            Text17: "", //FC

            // Time Remaining
            Text18: "", 	//FH
            Text19: "",  // FC
            Text20: "", // Day

            // Remarks
            Text21: "N/A AS PER BOEING AD STATUS, R-6744-96-102A.",
            WO: ""
        },
        {
            TaskID: "105",
            ASN: "28199",
            EffectiveDate: "25-NOV-94",
            TaskType: "Y",

            TaskStatus: "Documents found",

            Issues: "",

            Text1: "FAA AD",
            Text2: "94-21-05-R1",
            Text3: "Rev. 01",

            Text4: "B737-400 EO 4I78-001 TWI- F - EM - 03 4I78-001",//References

            Test5: "THRUST REVERSER SYNC-LOCK TEST",//Description

            Text6: "Y", //Type

            Text7: "OPEN",//Status

            //Threshhold
            Text8: "",     	//FH
            Text9: "",     // FC
            Text10: "",   // Day

            //Interval
            Text11: "4000:00", 	//FH
            Text12: "",            // FC
            Text13: "",           // Day

            //Last Accomplishment
            Date1: "28/03/16",		//date
            Text14: "57701:16",	 //FH
            Text15: "29389",	         //FC

            // Next Due 
            Date2: "",	//date
            Text16: "61701:16", //FH
            Text17: "", //FC

            // Time Remaining
            Text18: "2171:37", 	//FH
            Text19: "",  // FC
            Text20: "", // Day

            // Remarks
            Text21: "ALASKA W/C: 48578003",
            WO: "2560"
        },
        {
            TaskID: "106",
            ASN: "28199",
            EffectiveDate: "03-FEB-97",
            TaskType: "N",

            TaskStatus: "Auto documents found",

            Issues: "",

            Text1: "FAA AD",
            Text2: "96-25-17",
            Text3: "Rev. 00",

            Text4: "BSB 737-27A1194",//References

            Test5: "TIE LINKS",//Description

            Text6: "N", //Type

            Text7: "OPEN",//Status

            //Threshhold
            Text8: "",     	//FH
            Text9: "1000",     // FC
            Text10: "",   // Day

            //Interval
            Text11: "", 	//FH
            Text12: "1000",            // FC
            Text13: "",           // Day

            //Last Accomplishment
            Date1: "",		//date
            Text14: "",	 //FH
            Text15: "",	         //FC

            // Next Due 
            Date2: "",	//date
            Text16: "", //FH
            Text17: "", //FC

            // Time Remaining
            Text18: "", 	//FH
            Text19: "",  // FC
            Text20: "", // Day

            // Remarks
            Text21: "N/A DUE TO AIRCRAFT L/N: 2826",
            WO: ""
        },
        {
            TaskID: "107",
            ASN: "28199",
            EffectiveDate: "21-APR-97",
            TaskType: "N",

            TaskStatus: "Not applicable",

            Issues: "",

            Text1: "FAA AD",
            Text2: "97-06-09",
            Text3: "Rev. 00",

            Text4: "BSB 737-27A1198",//References

            Test5: "AILERON/RUDDER TRIM CONTROL MODULE",//Description

            Text6: "N", //Type

            Text7: "CLOSED",//Status

            //Threshhold
            Text8: "",     	//FH
            Text9: "",     // FC
            Text10: "720 D",   // Day

            //Interval
            Text11: "", 	//FH
            Text12: "",            // FC
            Text13: "",           // Day

            //Last Accomplishment
            Date1: "11/03/98",		//date
            Text14: "",	 //FH
            Text15: "",	         //FC

            // Next Due 
            Date2: "",	//date
            Text16: "", //FH
            Text17: "", //FC

            // Time Remaining
            Text18: "", 	//FH
            Text19: "",  // FC
            Text20: "", // Day

            // Remarks
            Text21: "ALASKA EM 2700-01018",
            WO: ""
        },
        {
            TaskID: "108",
            ASN: "28199",
            EffectiveDate: "3-DEC-99",
            TaskType: "Y",

            TaskStatus: "Documents found",

            Issues: "",

            Text1: "FAA AD",
            Text2: "99-24-08(B)",
            Text3: "Rev. 00",

            Text4: "B737-400 AMP 24-026-01 B737-400 AMP 24-026 - 01",//References

            Test5: "ELECTRICALLY POWERED AIRPLANE SYSTEMS MAIN BATTERY",//Description

            Text6: "Y", //Type

            Text7: "OPEN",//Status

            //Threshhold
            Text8: "",     	//FH
            Text9: "",     // FC
            Text10: "",   // Day

            //Interval
            Text11: "750:00 FH", 	//FH
            Text12: "",            // FC
            Text13: "",           // Day

            //Last Accomplishment
            Date1: "09/01/17",		//date
            Text14: "59529:39",	 //FH
            Text15: "30049",	         //FC

            // Next Due 
            Date2: "",	//date
            Text16: "", //FH
            Text17: "60279:39", //FC

            // Time Remaining
            Text18: "750:00 FH", 	//FH
            Text19: "",  // FC
            Text20: "", // Day

            // Remarks
            Text21: "ALASKA EM 2700-01018",
            WO: "9530"
        },
        {
            TaskID: "109",
            ASN: "28199",
            EffectiveDate: "14-JUL-03",
            TaskType: "Y",

            TaskStatus: "Partial documents found",

            Issues: "Issue",

            Text1: "FAA AD",
            Text2: "2003-14-06",
            Text3: "Rev. 00",

            Text4: "B737-400 EO 4I53-012 TWI- F - EM - 03 4I53-012 BSB-737 53-1179_R2",//References

            Test5: "LAP JOINTS",//Description

            Text6: "Y", //Type

            Text7: "OPEN",//Status

            //Threshhold
            Text8: "",     	//FH
            Text9: "500 FC",     // FC
            Text10: "",   // Day

            //Interval
            Text11: "", 	//FH
            Text12: "500 FC",            // FC
            Text13: "",           // Day

            //Last Accomplishment
            Date1: "16/08/16",		//date
            Text14: "58814:20",	 //FH
            Text15: "29749",	         //FC

            // Next Due 
            Date2: "",	//date
            Text16: "", //FH
            Text17: "30249", //FC

            // Time Remaining
            Text18: "", 	//FH
            Text19: "200 FC",  // FC
            Text20: "", // Day

            // Remarks
            Text21: "TWI EO 4I53-012",
            WO: "2560"
        }


        ];
    }
   
}