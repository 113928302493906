import { Component, OnInit, ElementRef, ViewChild, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router'

import { TreeNode } from 'primeng/api'
import { SharedService } from '../../../shared/services/shared.service';
import { AssetService } from '../../../admin/services/asset/asset.service';
import { Subscription } from 'rxjs';
@Component({
    moduleId: module.id,
    selector: 'asset-tree',
    templateUrl: './asset.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AssetService]
})
export class AssetComponent implements OnInit, OnDestroy {

    //#region globals variables
    //@ViewChild('myinout') myinout: any;
    tabValue: string = "0";
    @ViewChild('txtbox') txtbox: ElementRef;
    isTextclicked: boolean = false;
    assets: TreeNode[];
    tempAsset: TreeNode[];
    selectedAsset: TreeNode[];
    isBrowseScreen: boolean;
    isClassVisible: boolean = false;
    selectedAssetName: string;
    selectedClassName: string;
    searchAsset: string;
    selectedFontClassName: string = "standard-fontsize";
    panalClicksubs: Subscription;
    //#endregion

    //#region init

    ngOnInit() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            if (this._sharedService.selectedAsset && this._sharedService.selectedAsset.length) {
                this.selectedAsset = [];
                this.selectedAsset.push(this._sharedService.selectedAsset[0]);
                //this.selectedAsset = this._sharedService.selectedAsset;
                if (this.selectedAsset[0]) {
                    this.selectedAssetName = this.selectedAsset[0].label, this.selectedClassName = this.selectedAsset[0].expandedIcon ? this.selectedAsset[0].expandedIcon : this.selectedAsset[0].icon;

                }
            } else {
                this.selectedAssetName = "Select An Asset";
            }
            this.setAssetClass();
            this._sharedService.setAssetControlVisible(false);
            this.panalClicksubs = this._sharedService._ishideRecordTreeControl
            .subscribe({
                next: (response) => {
                    if (this.isClassVisible) {
                        this.isClassVisible = false;
                        this.cRef.markForCheck();
                    }
                },
                error: (error) => {
                }
            });
        }
    }
    ngOnDestroy() {
        if (this.panalClicksubs) {
            this.panalClicksubs.unsubscribe();
        }

    }
    modelChanged(event: any) { // without type info

        if (event) {
            this.searchAsset = event;
            this.assets = this.deepCopy(this.tempAsset);
            this.assets = this.filterNodeArray(this.assets, this.searchAsset);
        } else {
            this.assets = this.deepCopy(this.tempAsset);
        }

        if (this._router.url == "/record/search" || this._router.url == "/transition/search") {
            this._sharedService.resetAssetControl(true);
        }
        else {
            this._sharedService.resetAssetControl(false);
        }
    }

    filterNodeArray(oNodes: TreeNode[], filter: string): TreeNode[] {

        let tempNodes: TreeNode[] = oNodes;
        let fnlNodes: TreeNode[] = [];
        let airCraft: TreeNode;
        let assets: TreeNode[];

        tempNodes.forEach((pNode) => {
            let ch2Node = pNode.children;
            airCraft = pNode;
            airCraft.expanded = true;
            assets = [];
            if (ch2Node) {
                ch2Node.forEach((cNode) => {
                    //let ch3Nodes = cNode.children;
                    let ch3Nodes: TreeNode[] = [];
                    if (cNode && cNode.children) {
                        ch3Nodes = cNode.children == null ? [] : cNode.children;
                    }
                    let filterNodes = ch3Nodes.filter(a => a.label.toLowerCase().includes(filter.toLowerCase()));
                    cNode.children = filterNodes;
                    if (cNode.children && cNode.children.length > 0) {
                        cNode.expanded = true;
                        assets.push(cNode);

                    }
                })
            }

            if (assets && assets.length > 0) {
                airCraft.children = assets;
                fnlNodes.push(airCraft);
            }
        });

        return fnlNodes;

    }



    public deepCopy(o: any) {
        var copy = o, k;

        if (o && typeof o === 'object') {
            copy = Object.prototype.toString.call(o) === '[object Array]' ? [] : {};
            for (k in o) {
                copy[k] = this.deepCopy(o[k]);
            }
        }

        return copy;
    }

    constructor(private _router: Router, private _sharedService: SharedService, private _assetService: AssetService, private cRef: ChangeDetectorRef) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            if ((this._sharedService.getSubdomain().toLowerCase() != this._sharedService.Tenant_Internal) &&
                (this._sharedService.getSubdomain().toLowerCase() != this._sharedService.Tenant_Help)) {
                let userRole: string = localStorage.getItem('roles').trim();
                let body = { UserRole: userRole };
                this._assetService.getUserAsset(this._sharedService.baseUrl, body)
                .subscribe({
                    next: (response) => {
                        this._sharedService.setAssetList(response);
                        let assetsList = this._sharedService.getAssetList();
                        this.assets = assetsList;
                        this.tempAsset = [];
                        this.tempAsset = this.deepCopy(response);
                        this.cRef.markForCheck();
                    },
                    error: (error) => {
                    }
                });

                this._sharedService._selectedAsset
                .subscribe({
                    next: (selectedAsset:any) => {
                        //  if (selectedAsset[0]) { ****OCR Search:for multiple asset selection message should be display instead of asset value *****
                        if (selectedAsset && selectedAsset.length > 1) {
                            let aircraftLabel = this.getAirCraftifSelected(selectedAsset);
                            if (aircraftLabel) {
                                this.selectedAssetName = aircraftLabel, this.selectedClassName = null;
                            }
                            else {
        
                                this.selectedAssetName = "Multiple selection", this.selectedClassName = null;
                            }
                            this.setAssetClass();
        
                        }
                        else if (selectedAsset && selectedAsset.length == 1) {
                            this.selectedAssetName = selectedAsset[0].label, this.selectedClassName = selectedAsset[0].expandedIcon ? selectedAsset[0].expandedIcon : selectedAsset[0].icon;
                            this.setAssetClass();
                        }
                        else {
                            this.selectedAssetName = "Select An Asset";
                            this.selectedClassName = "";
                            this.setAssetClass();
                        }
                    },
                    error: (error) => {
                    }
                });

                this._sharedService._isAssetControlVisible
                .subscribe({
                    next: (assetVisibility) => {
                        this.setAssetControlVisibility(assetVisibility)
                    },
                    error: (error) => {
                    }
                });

                this._sharedService._resetAssetControl
                .subscribe({
                    next: (resetAsset:any) => {
                        this.resetAsetControle(resetAsset)
                    },
                    error: (error) => {
                    }
                });

                this._sharedService._setAssetControlTextForDashboard
                .subscribe({
                    next: (resetAsset) => {
                        this.setSelectedAssetText(resetAsset)
                    },
                    error: (error) => {
                    }
                });
            }


        }
    }

    setSelectedAssetText(selectedAsset: any) {
        this.selectedAssetName = selectedAsset[0].label, this.selectedClassName = selectedAsset[0].expandedIcon ? selectedAsset[0].expandedIcon : selectedAsset[0].icon;
        this.setAssetClass();
        this.selectedAsset = this._sharedService.selectedAsset;
        this.cRef.markForCheck();
    }
    //#endregion
    //#region Asset Class
    setAssetClass() {
        if (this.selectedAssetName) {
            if (this.selectedAssetName.length > 16) {
                this.selectedFontClassName = "small-fontsize";
            }
            else {
                this.selectedFontClassName = "standard-fontsize";
            }

            this.cRef.markForCheck();
        }

    }
    //#endregion
    //#region internal methods
    getAirCraftifSelected(selectedAsset: TreeNode[]): string {
        let assets: TreeNode[];
        let aircraft: any[] = [];
        let parentAsset: any[] = [];
        assets = selectedAsset;
        let aircraftName = "";
        assets.forEach((asset) => {
            if (asset.styleClass) {
                let aircarft = asset.styleClass.split(" ")[0];
                if (aircarft && aircarft == "P") {
                    aircraft.push(asset.label);
                }

                let childAssets = asset.styleClass.split(" ")[0];
                if (childAssets && childAssets != "P" && parentAsset.indexOf(childAssets) == -1) {
                    parentAsset.push(childAssets);
                }
            }
        });

        if (aircraft && parentAsset && parentAsset.length == 1 && aircraft.length == 1) {
            return aircraft[0];
        }
        else {

            return null;

        }


    }
    //#endregion



    //#region events

    resetAsetControle(resetAsset: any) {
      //  this.assets = [];
        if (this.assets)
            if ((this._router.url.indexOf("/record/search") == -1) && (this._router.url.indexOf("/transition/search") == -1)) {
                this.assets.forEach(a => a.selectable = false);
            }
            else {
                this.assets.forEach(a => a.selectable = true);
            }

        if (resetAsset == true) {
            this.selectedAsset = [];
            this._sharedService.selectedAsset = [];
            this.selectedAssetName = "Select An Asset";
            this.selectedClassName = "";
            this.setAssetClass();
        }

        this.cRef.markForCheck();
    }

    assetSelect(event: any) {
        //console.log(event.node.label);
        //console.log(JSON.parse(event.node.data));
        if ((this._router.url.indexOf("/record/search") != -1) || (this._router.url.indexOf("/transition/search") != -1)) {
            console.log(event);
            //let clickedNodeArray = event.node.data.split("|");
            let clickedNodeParent = event.node.parent;
            let clickedNodeData = JSON.parse(event.node.data);

            var nonSearchableAssetsToRemove: TreeNode[] = [];
            var currentURL = this._router.url;
            this._sharedService.clearToast();
            this.selectedAsset.forEach(function (asset, index, object) {
                var _assetData = JSON.parse(asset.data);
                if ((currentURL.indexOf("/record/search") != -1) && (_assetData.PlanRoles.indexOf("Records_OCRSearch_Readonly")) == -1) {
                    nonSearchableAssetsToRemove.push(asset);
                }
                else if ((currentURL.indexOf("/transition/search") != -1) && (_assetData.PlanRoles.indexOf("Transition_OCRSearch_Readonly")) == -1) {
                    nonSearchableAssetsToRemove.push(asset);
                }
                // else {
                //     // var notAllowedMsg = 'Asset: ' + asset.label + ' is on ' + _assetData.PlanName + ', which contains OCR.';//PlanName
                //     // console.log(notAllowedMsg);
                // }

            });
            if (nonSearchableAssetsToRemove.length > 0) {
                // setTimeout(() => {
                //     this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: "Some asset(s) do not exist in searchable plan. Please contact administrator to upgrade their plan." });
                //     console.log("Delayed nonSearchableAssetsToRemove.");
                // }, 500);
                this.selectedAsset = this.selectedAsset.filter((el) => !nonSearchableAssetsToRemove.includes(el));
            }
            // this.selectedAsset.forEach(asset => {
            //     var _assetData = JSON.parse(asset.data);
            //     if ((this._router.url.indexOf("/record/search") != -1) && (_assetData.PlanRoles.indexOf("Records_OCRSearch_Readonly")) == -1){
            //         nonSearchableAssetsToRemove.push(asset);
            //     }
            //     if ((this._router.url.indexOf("/transition/search") != -1) && (_assetData.PlanRoles.indexOf("Transition_OCRSearch_Readonly")) == -1){
            //         nonSearchableAssetsToRemove.push(asset);
            //     }    
            // });


            let tempSelectedAsset: TreeNode[] = [];
            if (this.selectedAsset.length > 0) {
                let isCompare = false;
                for (let index = 0; index < this.selectedAsset.length; index++) {
                    // let SelectedNodeDataArray = this.selectedAsset[index].data.split('|');
                    // if (clickedNodeArray[clickedNodeArray.length - 1] != SelectedNodeDataArray[SelectedNodeDataArray.length - 1]) {
                    //     isCompare = true;
                    // }

                    let currentIterationNodeData = JSON.parse(this.selectedAsset[index].data);
                    if (clickedNodeData.SearchDatabaseID != currentIterationNodeData.SearchDatabaseID) {
                        isCompare = true;
                    }
                }

                //let firstSelectedNodeArray = this.selectedAsset[0].data.split('|');
                let firstSelectedNodeData = JSON.parse(this.selectedAsset[0].data);
                //if (clickedNodeArray[clickedNodeArray.length - 1] != firstSelectedNodeArray[firstSelectedNodeArray.length - 1] || isCompare) {
                if (clickedNodeData.SearchDatabaseID != firstSelectedNodeData.SearchDatabaseID || isCompare) {

                    let selectedAssetCount = this.selectedAsset.length;
                    for (let index = 0; index < selectedAssetCount; index++) {
                        // let selectedNodeData = this.selectedAsset[index].data.split('|');
                        // if (selectedNodeData[selectedNodeData.length - 1] == clickedNodeArray[clickedNodeArray.length - 1]) {
                        //     let node = this.selectedAsset[index]
                        //     tempSelectedAsset.push(node);
                        // }

                        let selectedNodeData = JSON.parse(this.selectedAsset[index].data);
                        if (selectedNodeData.SearchDatabaseID == clickedNodeData.SearchDatabaseID) {
                            let node = this.selectedAsset[index]
                            tempSelectedAsset.push(node);
                        }

                    }
                    if (tempSelectedAsset.length > 0) {

                        // if (!clickedNodeParent) {
                        //     tempSelectedAsset = [];
                        // }
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Please select assets from one database for search searvice to work' });

                        this.selectedAsset = tempSelectedAsset;
                    }

                    return;
                }
            }
        }

        if ((this._router.url.indexOf("/record/search") == -1) && (this._router.url.indexOf("/transition/search") == -1)) {
            if (this.selectedAsset && this.selectedAsset.length > 1) {
                this.selectedAsset = [];
                this.selectedAsset.push(event.node);
            }
        }

        this._sharedService.selectedAssetTreeNode = this.selectedAsset;
        this._sharedService.onAssetChanged(this.selectedAsset);
        this._sharedService.onAssetStatusChanged(this.selectedAsset);
        if (this._router.url == '/record/excel'
            || this._router.url == '/record/csv'
            || this._router.url == '/record/tsv'
            || this._router.url == '/record/pdf'
            || this._router.url == '/record/xml') {
            this._sharedService.resetAssetControl(false);

            //US-8181 //this._sharedService.setCurrentFeedASN(this.selectedAsset[0].data.split('|')[1]);
            this._sharedService.setCurrentFeedASN(JSON.parse(this.selectedAsset[0].data).ASN);
        }
        if (SharedService.current_ParentMenu_Id == 113
            || SharedService.current_ParentMenu_Id == 16) {
            this._sharedService.showHideRecordMenu();
            if (this._sharedService.parentMenuClicked == false) {
                this.warningforPlan();
            }
            this._sharedService.resetAssetControl(false);
        }
        this.cRef.markForCheck();
    }
    warningforPlan(): void {
        try {
            this._sharedService.clearToast();
            this._sharedService.recordType = this._sharedService.recordType.toUpperCase();// ? this._sharedService.recordType.toUpperCase() : this._sharedService.recordType;
            if ((this._sharedService.recordType == 'MXR' && this._sharedService.UserRole.Records_MXR_Readonly)
                || (this._sharedService.recordType == 'LOG' && this._sharedService.UserRole.Records_LOG_Readonly)
                || (this._sharedService.recordType == 'GEN' && this._sharedService.UserRole.Records_GEN_Readonly)
                || (this._sharedService.recordType == 'DOC' && this._sharedService.UserRole.Records_DOC_Readonly)
                || (this._sharedService.recordType == 'BND' && this._sharedService.UserRole.Transition_BND_Readonly)
            ) { }
            else {
                let assetDataObj = JSON.parse(this._sharedService.selectedAsset[0].data);
                var notAllowedMsg = 'This asset is on ' + assetDataObj.PlanName + ', please contact administrator to upgrade plan for this asset.';//PlanName
                //this._sharedService.showToast({ severity: 'warn', summary: 'Access denied', detail: notAllowedMsg });
                setTimeout(() => {
                    this._sharedService.showToast({ severity: 'warn', summary: 'Access denied', detail: notAllowedMsg });
                    console.log("Delayed browse-container.");
                }, 500);

            }
        } catch (e) {
            console.log(e);
        }
    }
    assetClick() {
        //this.myinout.nativeElement.focus();
        if (!this.isClassVisible) {
            this.isClassVisible = true;
        }
        this.cRef.markForCheck();
    }

    setAssetControlVisibility(status: any) {
      this.isBrowseScreen = status;
      if (this.isBrowseScreen) {
        if (this._sharedService.selectedAsset && this._sharedService.selectedAsset.length) {
          this.selectedAsset = [];
          this.selectedAsset.push(this._sharedService.selectedAsset[0]);
          this.selectedAssetName = this.selectedAsset[0].label
          this.selectedClassName = this.selectedAsset[0].expandedIcon ? this.selectedAsset[0].expandedIcon : this.selectedAsset[0].icon;
        }
        else if (this._sharedService.selectedAssetStatus && this._sharedService.selectedAssetStatus.length) {
          this.selectedAsset = [];
          this.selectedAsset.push(this._sharedService.selectedAssetStatus[0]);
          this.selectedAssetName = this.selectedAsset[0].label
          this.selectedClassName = this.selectedAsset[0].expandedIcon ? this.selectedAsset[0].expandedIcon : this.selectedAsset[0].icon;
        }
        else {
          this.selectedAssetName = "Select An Asset";
        }
      }
        this.cRef.markForCheck();
    }

    assetUnselect(event: any) {
        if (this.selectedAsset && this.selectedAsset.length == 0) {
            this.selectedAsset = [];
            this._sharedService.selectedAssetTreeNode = null;
            this.selectedAssetName = "Select An Asset";
            this.selectedClassName = "";
            this.setAssetClass();
            //this.selectedAsset[0].label = "";
            //this.selectedAsset[0].collapsedIcon = "";
            //this._sharedService.onAssetChanged(this.selectedAsset);
        }
        this._sharedService.onAssetChanged(this.selectedAsset);
        this._sharedService.onAssetStatusChanged(this.selectedAsset);
        this.cRef.markForCheck();
        this.selectedAsset = [];

    }




    txtSearchMouseDown() {
        this.isTextclicked = true;
    }

    onBlur(control?: string) {

        if (this.isTextclicked == false) {
            this.isClassVisible = false;
        }
        else
            if (control && control == "child") {
                this.isTextclicked = false;
                this.txtbox.nativeElement.focus();
            }
    }



    //#endregion 
}