<!-- Left panel : Navigation area -->
<!-- Note: This width of the aside area can be adjusted through LESS variables -->

<!-- <aside id="left-panel">
    <menu></menu>
    <asset-tree></asset-tree>
</aside> -->
<!-- END NAVIGATION -->
<!-- MAIN PANEL -->
<div id="main" role="main" style="margin-top: -60px !important;">
    <!--class="whitebackground"-->
    <!-- MAIN CONTENT -->
    <div id="content">
        <!-- <router-outlet></router-outlet> -->

        <article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable no-padding standardpadding" id="col3">
            <!-- Widget ID (each widget will need unique ID)-->
            <div class="jarviswidget" id="wid-id-3" data-widget-sortable="false" data-widget-colorbutton="false"
                data-widget-editbutton="false" data-widget- custombutton="false" data-widget-deletebutton="false"
                data-widget-togglebutton="false" role="widget">

                <header role="heading" id="pdfPanelHeader">
                    <span class="widget-icon"><i class="fa fa-file-pdf"></i></span>
                    <h2>Document View</h2>
                </header>
                <!-- widget div-->
                <div class="widgetdiv no-padding standardpadding set-screen-width" style="height: 94vh !important;">
                    <div class="jarviswidget-editbox">
                    </div>
                    <!-- end widget edit box -->
                    <!-- widget content -->
                    <div class="widget-body set-widget-body-width">
                        <!-- widget grid -->
                        <section id="widget-grid" class="">
                            <div class="form-group">
                                <div class="documentview" id="documentview">
                                    <div id="DocumentShow" class="fix-record-pdf-doc" style="height: 100vh !important;">
                                        <!-- <object [data]="pdfSrc" type="application/pdf" width="100%" height="100%" >
                                                    <param name="view" value="Fit" />
                                                </object> -->
                                        <object [data]="pdfSrc" type="application/pdf" width="100%" height="100%"
                                            *ngIf="(pdfSrc) && (!(selectedUrl==null && selectedUrl==undefined) && (selectedVideoUrl==null && selectedVideoUrl==undefined) && !showWarning)">
                                            <param name="view" value="Fit" />
                                        </object>
                                        <div id="videolink"
                                            *ngIf="!(selectedVideoUrl==null && selectedVideoUrl==undefined) && !showWarning"
                                            [innerHtml]="video_html"></div>
                                        <div id="nodocFound" class="alert alert-block alert-warning"
                                            *ngIf="showWarning">

                                            <h4 class="alert-heading">{{warningHeading}}</h4>
                                            {{warningMsg}}
                                        </div>
                                        <div id="zipFile" class="alert alert-block alert-success"
                                            *ngIf="showZipFileMsg">

                                            <h4 class="alert-heading">Please Wait!</h4>
                                            {{zipFileMsg}}
                                        </div>

                                        <div class="video"
                                            *ngIf="!(isVideoTypeFound==null && isVideoTypeFound==undefined)">
                                            <video controls autoplay #videoPlayer>
                                                <!-- crossorigin="anonymous" -->
                                                <!-- src="{{selectedUrl}} type="video/mp4"" -->
                                                <source />
                                                Browser not supported
                                            </video>
                                        </div>

                                        <div class="audio"
                                            *ngIf="!(isAudioTypeFound==null && isAudioTypeFound==undefined)">
                                            <audio controls autoplay #audioPlayer>
                                                <!-- crossorigin="anonymous" -->
                                                <!-- src="{{selectedUrl}} type="audio/mpeg" -->
                                                <source />

                                                Your browser does not support the audio tag.
                                            </audio>
                                        </div>

                                        <div id="statusImageViewer" *ngIf="isImageTypeFound">
                                            <div id="imgViewertoolbar" *ngIf="fileType.indexOf('image/') != -1"
                                                style="height: 25px;background-color: lightgray;">
                                                
                                                
                                            </div>
                                            <div id="statusCanvasDiv">
                                                <canvas id="statusCanvas" width="0" height="0"
                                                    style="border-width:0px;"></canvas>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </section>
                        <!-- end widget grid -->
                    </div>
                    <!-- end widget content -->
                </div>
                <!-- end widget div -->
            </div>
            <!-- end widget -->
        </article>



    </div>
    <!-- END MAIN CONTENT -->

</div>
<!--</div>-->
<!-- END MAIN PANEL -->