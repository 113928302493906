<div id="homeview" role="main">
    <!-- MAIN CONTENT -->
    <div>
        <div class="row">
            <div class="well">
                <ag-grid-angular [gridOptions]="gridOptions"  id="msrGrid" [columnDefs]="columnDefs" sortable="true" filter=true style="width: 100%;height:80vh;" [rowData]="gridData" class="ag-theme-balham"></ag-grid-angular>
            </div>
        </div>
    </div>
</div>
