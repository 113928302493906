//Components
import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgForm, Validators } from '@angular/forms';
import { GridOptions } from "ag-grid-community";

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { SelectItem } from 'primeng/api';
import { ContainerService } from '../../../records/services/container/container.service'
import { ContainerTypeModel, OperationMode } from './container-type';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
var _router_: Router;
@Component({
    selector: 'container-type',
    templateUrl: './container-type.component.html',
    providers: [ContainerService]

})

export class ContainerTypeComponent implements OnInit, OnDestroy {
    display: boolean = false;
    public gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[];
    public canAddEditContainerType: boolean = false;
    public canAddContainerType: boolean = false;
    public canEditContainerType: boolean = false;
    selectedContainerTypeID: string = null;
    ishidden:boolean=false;
    
    public totalCount: number;
    public model: ContainerTypeModel;
    public isSubmitted: boolean = false;
    categoryTypeList: SelectItem[] = [];
    public contanierTypeList: SelectItem[] = [];
    public dialogTitle: string = 'Add Container Type';
    public lblsubmitButton: string = 'Add';
    public operationMode: OperationMode = 1;
    public referenceKeyLabel: string = "";

    public appHotkeys:  Hotkey[] = [];
    @ViewChild('addeditcontainertypeform') public containertypeform: NgForm;
    //#region Initialization of Component
    constructor(public _sharedService: SharedService, private _router: Router, private _containerService: ContainerService, private _hotkeysService: HotkeysService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.UpdateRoles();
            _router_ = this._router;
            this.setHotKeys();
            this.navigateToNextCell=this.navigateToNextCell.bind(this);
        }

        this.contanierTypeList =  [
            { value: 0, label: 'Start of week' },
            { value: 1, label: 'Start of month' },
            { value: 2, label: 'Start of quarter' },
            { value: 3, label: 'BiAnnual' },
            { value: 4, label: 'Annual' },
        ];
        
    }
    setHotKeys() {
        
        let hotKeyObj : Hotkey = null;
        
        //Add container type
        hotKeyObj = new Hotkey('ctrl+a', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/containertype")
            {
                this.btnAddClick();
            }
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

        //Edit container type
        hotKeyObj = new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/containertype")
            {
                this.btnEditClick();
            }
            return false; // Prevent bubbling
        });
        
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
        hotKeyObj = new Hotkey('enter', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/containertype")
            {
                this.btnEditClick();
            }
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
    }
    ngOnInit() {
        this.model = new ContainerTypeModel();
        if (this.canAddEditContainerType) {
            this.gridOptions = <GridOptions>
                {
                    rowSelection: 'single',

                    onGridReady: () => {
                        this.setUserGridColumns();
                        try {
                            this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridContainerTypes'));
                            this.gridOptions.api.hideOverlay();
                        } catch (e) {
                            console.log(e);
                        }
                    },
                   // suppressContextMenu: true,
                    pagination: true,
                    paginationPageSize: 20,
                    getContextMenuItems: this.getCustomContextMenuItems,
                    context: {
                        gridContext: this
                    },
                    defaultColDef: {
                        sortable: true,
                        filter:true
                    }
                };
            this.getCategoryList();
            this.populateContainerTypeGrid();
        }
        this.referenceKeyLabel=this._sharedService.getReferenceKeyLabel()
    }
    ngOnDestroy() {
        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
    }
    //#endregion
    // #region Category List
    getCategoryList(): void {
        this.categoryTypeList = this._containerService.getContainerCategories();
    }
    //#endregion
    //#region Ag Grid Population Event
    populateContainerTypeGrid(): void {

        this._containerService.getAllContainerTypesData(null)
        .subscribe({
            next: (response) => {
                this.gridOptions.api.sizeColumnsToFit();
                this.gridData = [];
                this.gridData = response;
                this.totalCount = response.length;                
            },
            error: (error) => {
                this.gridData = [];
                this.totalCount = 0;                
            }
        });
    }
    //#endregion
    //#region Set Columns of Browse Ag Grid
    setUserGridColumns(): void {
        this.columnDefs = [];
        this.columnDefs =
            [
                { headerName: 'Category', field: 'Category', menuTabs: ['filterMenuTab'] },
                { headerName: 'Type', field: 'Type', menuTabs: ['filterMenuTab'], cellRenderer: this.tagsCellRenderer },
                { headerName: 'Description', field: 'Description', menuTabs: ['filterMenuTab'] },
                {
                    headerName: 'Status', field: 'Status', filter:false, suppressMenu: true, valueFormatter: function (params: any) {
                        if (params.value == true) {
                            return "Active";
                        }
                        else {
                            return "Inactive";
                        }
                    }
                }
            ];

    }
    tagsCellRenderer(params: any) {
        let html: string = '';

        if (params.value) {

            html = html + '<span class="padding-2 label" style= "background-color:' + params.data.ColorCode + ';">' + params.value + '</span>';

        }

        var eDiv = document.createElement('div');
        eDiv.innerHTML = html

        return eDiv;
    }

    //#endregion

    //#region Ag-Grid Events
    updateSelectedRow(event: any) {
        this.selectedContainerTypeID = null;
        this.selectedContainerTypeID = event.data.ContainerTypeID;
    }
    //#endregion

    //#region Edit User Events
    containerRowDoubleClicked(event: any) {
        if (this.canEditContainerType)
        {
            this.selectedContainerTypeID = null;
            this.selectedContainerTypeID = event.data.ContainerTypeID;
            this.btnEditClick();
        }
    }
    btnEditClick() {
        if (this.selectedContainerTypeID) {
            this.operationMode = 2;
            this.dialogTitle = 'Edit Container Type';
            this.lblsubmitButton = 'Update';

            this.ishidden=true;

            let body: any =
                {
                    ContainerTypeID: this.selectedContainerTypeID
                }
            this._containerService.getContainerTypeByID(body)
            .subscribe({
                next: (response) => {
                    let data = response;
                    this.model = new ContainerTypeModel();
                    this.model.ColorCode = data.ColorCode;
                    this.model.ContainerCategory = data.Category;
                    this.model.ContainerType = data.Type;
                    this.model.ContainerTypeID = data.ContainerTypeID;
                    this.model.Status = data.Status;
                    this.model.ContainerDescription = data.Description;
                    this.model.ContainerDate = data.ContainerDate;
                    this.model.ContainerWorkPack = data.ContainerWorkPack;
                    this.showHideDialogue(true);      
                    this.categoryChange(data.Category );                    
                },
                error: (error) => {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Container type not added, please try again.' });
        
                    this.showHideDialogue(false);                    
                }
            });
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Container Type Selected', detail: 'Please select a record.' });
        }
    }
    //#endregion
    //#region Add User Events
    btnAddClick() {
        this.operationMode = 1;
        this.dialogTitle = 'Add Container Type';
        this.lblsubmitButton = 'Add';
        this.model = new ContainerTypeModel();

        this.model.Status = true;
        this.model.ColorCode = '#0976e3';
        this.showHideDialogue(true);
        
        this.ishidden = false;
    }
    //#endregion

    //#region User Roles Assignment
    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }

        this.canAddEditContainerType = this._sharedService.UserRole.Admin_Container_Type_Management;
        this.canAddContainerType = this._sharedService.UserRole.Admin_Container_Type_Management;
        this.canEditContainerType = this._sharedService.UserRole.Admin_Container_Type_Management;
    }
    //#endregion

    //#region Cancel Dialogue
    btnCancelClick(cancelForm: NgForm) {
        this.showHideDialogue(false);
        this.model = new ContainerTypeModel();
        cancelForm.resetForm();
    }
    //#endregion
    //#region Helper Methods
    
    showHideDialogue(flag: boolean) {
        this.display = flag;
    }
    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }
    //#endregion

    //#region form submit
    onSubmit(form: NgForm) {
        if(this.model.ContainerType.indexOf(' ') != -1){
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'error', summary: 'Container type', detail: 'Container type should not have space.' });
            return;
        }

        if(this.model.ContainerCategory != 'MXR')
        {
            this.model.ContainerWorkPack = null;
        }
        else if(this.model.ContainerWorkPack == false)
        {
            this.model.ContainerDate = null;
        }
        this.changeButtonsStatus(true);
        this._sharedService.showLoader("Processing...");
        if (this.operationMode == 1) {
            let body: any =
                {
                    ContainerTypeID: 0,
                    Status: this.model.Status,
                    Type: this.model.ContainerType,
                    Category: this.model.ContainerCategory,
                    Description: this.model.ContainerDescription,
                    ColorCode: this.model.ColorCode,
                    ContainerWorkPack: this.model.ContainerWorkPack,
                    ContainerDate: this.model.ContainerDate
                }
            this._containerService.addUpdateContainerType(body)
            .subscribe({
                next: (response) => {
                    this.showHideDialogue(false);
                    form.resetForm();
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Container type added succesfully.' });
                    this.changeButtonsStatus(false);
                    this.populateContainerTypeGrid();                    
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();            
                    if (error.status === 409) {
                        this._sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Container Type already exists.' });
                    }
                    else {
                        this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Container type not added, please try again.' });
                    }
                    this.showHideDialogue(false);
                    this.model = new ContainerTypeModel();
                    form.resetForm();
                    this.changeButtonsStatus(false);                    
                }
            });
        }
        else if (this.operationMode == 2) {
            let body: any =
                {
                    ContainerTypeID: this.selectedContainerTypeID,
                    Status: this.model.Status,
                    Type: this.model.ContainerType,
                    Category: this.model.ContainerCategory,
                    Description: this.model.ContainerDescription,
                    ContainerDate: this.model.ContainerDate,
                    ContainerWorkPack: this.model.ContainerWorkPack,
                    ColorCode: this.model.ColorCode
                }
            this._containerService.addUpdateContainerType(body)
            .subscribe({
                next: (response) => {
                    this.showHideDialogue(false);
                    form.resetForm();
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Container type updated succesfully.' });
                    this.changeButtonsStatus(false);
                    this.populateContainerTypeGrid();                    
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    if (error.status === 409) {
                        this._sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Container Type already exists.' });
                    }
                    else {
                        this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Container type not updated, please try again.' });
                    }
                    this.showHideDialogue(false);
                    this.model = new ContainerTypeModel();
                    form.resetForm();
                    this.changeButtonsStatus(false);                    
                }
            });
        }
    }
    //#endregion
    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];

        if (params.context.gridContext.canAddEditContainerType) {
            result.push({
                name: "Add Container Type",
                action: function () {
                    params.context.gridContext.btnAddClick()
                },
                icon: params.context.gridContext._sharedService.addIconHTMLasString,
            });

            result.push({
                name: "Edit Container Type",
                action: function () {
                    params.context.gridContext.selectedContainerTypeID = null;
                    if (params.node) {
                        params.context.gridContext.selectedContainerTypeID = params.node.data.ContainerTypeID;
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext.btnEditClick()
                    }
                    
                },
                icon: params.context.gridContext._sharedService.editIconHTMLasString,
            });

            let childResult: any[] = [];
                childResult.push({
                    name: 'Excel',
                    action: function () {
                        
                        params.api.exportDataAsExcel();
                        
                    },
                    icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
                });
    
                childResult.push({
                    name: 'CSV',
                    action: function () {
                        params.api.exportDataAsCsv();
                    },
                    icon: params.context.gridContext._sharedService.saveLogFeedIconHTMLasString,
                });
    
                result.push({
                    name: 'Download',
                    action: function () {
    
    
                    },
                    icon: params.context.gridContext._sharedService.downloadsFeedIconHTMLasString,
                    subMenu: childResult,
                });
        }
        return result;
    }
    navigateToNextCell(params: any) {
        var previousCell = params.previousCellPosition;
        var suggestedNextCell = params.nextCellPosition;
        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        switch (params.key) {
            case KEY_DOWN:
                var nextRowIndex = previousCell.rowIndex + 1;
                var renderedRowCount = this.gridOptions.api.getModel().getRowCount();
                if (nextRowIndex >= renderedRowCount) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedContainerTypeID = null;
                    this.selectedContainerTypeID = rowNode.data.ContainerTypeID;
                        return {
                        rowIndex: nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_UP:
                var _nextRowIndex = previousCell.rowIndex - 1;
                if (_nextRowIndex < 0) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedContainerTypeID = null;
                    this.selectedContainerTypeID = rowNode.data.ContainerTypeID;
                        return {
                        rowIndex: _nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw 'this will never happen, navigation is always on of the 4 keys above';
        }
    }

    categoryChange(event )
    {
        if(event == 'MXR' || event == 'DOC' || event == 'BND')
        {
            if (this.operationMode == 1) {
            this.model.ContainerWorkPack = false;
            }
            this.containertypeform.form.get('addeditContainer').clearValidators();
            this.containertypeform.form.get('addeditContainer').updateValueAndValidity();
            
        }
        else
        {
            this.model.ContainerWorkPack = null;
            this.containertypeform.form.get('addeditContainer').setValidators([Validators.required]);
            this.containertypeform.form.get('addeditContainer').updateValueAndValidity();
        }
        
        if(event == 'MXR')
        {
            this.containertypeform.form.get('astradio_WP').setValidators([Validators.required]);
            this.containertypeform.form.get('astradio_WP').updateValueAndValidity();
        }
        else
        {
            this.containertypeform.form.get('astradio_WP').clearValidators();
            this.containertypeform.form.get('astradio_WP').updateValueAndValidity();
        }
    }

    changeWorkPack(e)
    {
        if(this.containertypeform.form.get('astradio_WP').value == false)
        {
            this.containertypeform.form.get('addeditContainer').clearValidators();
            this.containertypeform.form.get('addeditContainer').updateValueAndValidity();
        }
        else
        {
            this.containertypeform.form.get('addeditContainer').setValidators([Validators.required]);
            this.containertypeform.form.get('addeditContainer').updateValueAndValidity();
        }
    }
    //#endregion
}
