import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-attach-selected-pages',
  templateUrl: './attach-selected-pages.component.html',
})
export class AttachSelectedPagesComponent implements OnInit {

  @Input() FormDisabled:boolean
  @Input() ConRowSelected:boolean
  @Output()
  emitSelectedPageEmitter = new EventEmitter<string>();
  constructor(private _sharedService: SharedService) { }
  isDisplayExtractPagesDialog = false;
  PageRange:string
  ngOnInit() {
  }
  AttachSelectedPaged() {
    this.isDisplayExtractPagesDialog = true;
  }
  btnCancelClick(){
    this.isDisplayExtractPagesDialog = false;
  }
  EmitSelectedPages(){
    this.emitSelectedPageEmitter.emit(this.PageRange);
    this.isDisplayExtractPagesDialog = false;
  }
}
