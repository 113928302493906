import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from '../../services/shared.service';

@Injectable()
export class MenuService {
    constructor(private http: HttpClient, private _sharedService: SharedService) {
    }

    getUserMenu(apiUrl: string, body:any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Menu/GetParentMenu`, body, this._sharedService.getReqOptWithAccessToken());
    }

    changePassword(apiUrl: string, body: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Account/ChangePassword`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getAllModuleNames(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Menu/GetAllModuleNames`, body, this._sharedService.getReqOptWithAccessToken());
    }
}