import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
import { QualityStatusService } from '../../../admin/services/qualitystatus/qualitystatus.service';
import { ContainerQualityStatusService } from '../../../admin/services/containerqualitystatus/container-quality-status.service';
@Component({
    selector: 'browse',
    templateUrl: './browse.component.html',
    providers: [QualityStatusService, ContainerQualityStatusService]
    //animations: [routerTransition()],
    //host: { '[@routerTransition]': '' }    

})
export class BrowseComponent implements OnInit {


    constructor(
        private _sharedService: SharedService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _qualityStatusService: QualityStatusService,
        private _containerQualityStatusService: ContainerQualityStatusService
    ) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
    }


    ngOnInit() {
        //if (!this._sharedService.isAuthUser()) {
        //    this._router.navigate(['login']);
        //}

        //let id = this._route.snapshot.params['id'];
        //this.recordType = this._route.snapshot.data[0].recordType ? this._route.snapshot.data[0].recordType : 'mxr';
        this._sharedService.recordType = this._route.snapshot.data[0].recordType ? this._route.snapshot.data[0].recordType : 'mxr';
        this._sharedService.setAssetControlVisible(true);
        this.populateQualityStatus();
        this.populateContainerQualityStatus();
    }
    onPanalsClick(event: any) {
        this._sharedService.onGridRowClick(true);
    }
    populateQualityStatus() {
        this._qualityStatusService.getActiveQualityStatusData(null)
            .subscribe({
                next: (response) => {
                    try {
                        this._sharedService.setQualityStatusList(response);
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                },
                error: (error) => {
                }
            });
    }
    populateContainerQualityStatus() {
        this._containerQualityStatusService.getActiveQualityStatusData(null)
            .subscribe({
                next: (response) => {
                    this._sharedService.setContainerQualityStatusList(response);
                    try {
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                },
                error: (error) => {
                }
            });
    }
}
