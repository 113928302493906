//Components
import { Component, OnInit } from '@angular/core';

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { UserService } from '../../../records/services/User/user.service';
import { OAuthService  } from 'angular-oauth2-oidc';

//interfaces

@Component({
    selector: 'logout',
    templateUrl: './logout.component.html',
    //animations: [routerTransition()],
    //host: { '[@routerTransition]': '' },
    providers: [UserService]
})

export class LogoutComponent  {

    constructor(private _sharedService: SharedService, private _userService: UserService, private oauthService: OAuthService) {
    }

    ngOnInit() {

        // Remove User's Server Side Cache info
        this._userService.removeUserCacheInfo(this._sharedService.baseUrl, null)
        .subscribe({
            next: (response) => {
                // Remove User's Client Side Cache info
               this._userService.logout();
               if(this._sharedService.ssoEnabled){
               this.oauthService.logOut();
               }
            },
            error: (error) => {
                console.log(error)
            }
        })
    }

}