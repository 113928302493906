<div class="row adduserscls">
    <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" id="col1">
        <h1 class="page-title-user txt-color-blueDark">

            <i class="fa fa-fw fa-list-alt"></i>
            <a routerLink="/apifeed/{{routeName}}">{{screenName}}</a>
            <span>&gt; <span id="page-mode">Feed Log</span></span>              
        </h1>
    </div> -->
    
    <article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable no-padding standardpadding" style="margin-left:0px" id="col1">

        <div class="jarviswidget" id="wid-id-1" data-widget-togglebutton="false" data-widget-sortable="false" data-widget-fullscreenbutton="false" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" role="widget">

            <header role="heading">
                <span class="widget-icon">
                    <i class="glyphicon glyphicon-list-alt"></i>
                </span>
                <h2>My Reports <span *ngIf="totalCount">({{totalCount}})</span></h2>
                <download-feed [feedlogobj]="feedlogobj"></download-feed>
            </header>

            <!-- widget div-->
            <div class="widgetdiv no-padding standardpadding" role="content">
                <!--set-screen-width-->
                <!--style="height: 535px;"-->
                <!-- widget edit box -->
                <!--<div class="jarviswidget-editbox">

                </div>-->
                <!-- end widget edit box -->
                <!-- widget content -->
                <div class="widget-body set-widget-body-width">
                    <div>
                        <fieldset>
                            <div class="form-group">
                                <div id="s1">
                                    <!-- (rowClicked)='clickedJobRow($event)' -->
                                    <div class="jqgridboxcontainer" *ngIf="this.gridData.length > 0">
                                        <ag-grid-angular id="agGridFeedLog"   #agGridFeedLog [gridOptions]="gridOptions" [columnDefs]="columnDefs"  [rowData]="gridData" sortable="true" filter=true style="width: 100% !important" class="ag-theme-balham api-inc-out-grid-log" [getRowId]="getRowNodeId" (rowDoubleClicked)="jobRowDoubleClicked($event)" (rowClicked)='clickedJobRow($event)'>
                                        </ag-grid-angular>
                                    </div>
                                    <div id="nofeedlog" class="alert alert-block alert-warning" *ngIf="this.gridData.length == 0">

                                        <h4 class="alert-heading">Warning!</h4>
                                        No Results Found!
                                    </div>
                                </div>

                            </div>
                        </fieldset>
                    </div>

                </div>
                <!-- end widget content -->
            </div>

            <!-- end widget div -->
        </div>
        <!-- end widget -->
    </article>

</div>

