import { SelectItem } from 'primeng/api';
import { Column, Condition } from 'src/app/admin/services/view/view';

export class StatusConfiguration {
    public FeedId: number;
    public FeedName: string;
    public SharedAccessExpiryTime:Date;
    public Map: StatusMapping[] = [];
}


export class StatusMapping {
    //constructor() {
       
    //}
    public isInclude: boolean;
    public asset: string;
    public view: string;
    public assetId: number;
    public viewId: number;
    public selectedDFPStatus: string[];
    public output: boolean;
    public fileFieldName: string;
    public fileCover: boolean;  
    public fieldList: any[];
    public Columns: Column[];  
    public Conditions: Condition[];
    public OrConditions: Condition[];
}

export class StatusConfig {
    // constructor(DataType:any,) {
    //     // this.FeedId = FeedId;
    //     // this.FeedName = FeedName;
    //     // this.FeedType = FeedType;
    //     // this.ASN = ASN;
    //     for(let i=0;i<DataType.length;i++){
    //         this.DataType={
    //             label:DataType[i],
    //             value:DataType[i]
    //         }
    //     }
    // }
    
    constructor(FeedId: string, FeedName: string, FeedType: string, ASN: string,DataType?:any) {
        this.FeedId = FeedId;
        this.FeedName = FeedName;
        this.FeedType = FeedType;
        this.ASN = ASN;
        this.DataType=[];
        for(let i=0;i<DataType.length;i++){
            let data={
                label:DataType[i],
                value:DataType[i]
            }
            this.DataType.push(data)
    }
}
    
    public FeedId: string;
    public FeedName: string;
    public FeedType: string;
    public ASN: string;
    public DataType: any;


    

}