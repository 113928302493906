<div class="jarviswidget-ctrls" role="menu">
    <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="showAddDialog()" id="btnadd"
        (click)="showAddDialog()" title="Add Container (Ctrl+A)">
        <i class="glyphicon glyphicon-plus"></i>
    </a>
</div>
<form (ngSubmit)="onSubmit(heroForm)" #heroForm="ngForm" *ngIf="display">
    <p-dialog [(visible)]="display" showEffect="fade" [modal]="true" [style]="{width: '513px'}" [resizable]="false">
        <ng-template pTemplate="header"  (keyup.shift.tab)="onKey()">
            {{dialogTitle}}
        </ng-template>
        <div class="upload-dialog">
            <!--style="overflow-y: hidden !important;overflow-x:hidden !important"-->


            <h3 class="panel-title panalheading-padding">
                Keyword Search:
                <a class="button-icon hand-cursor pull-right" style="padding-right: 5px;" id="btnAddkeywordsearch"
                    (click)="addSearchKeyword()">
                    <i class="glyphicon glyphicon-plus"></i>
                </a>
            </h3>
            <div class="row">
                <div class="col-sm-12  col-md-12 col-lg-12 ocrsearchbar">
                    <div class="form-group">
                        <div class="form-group col-sm-2 col-md-2 col-lg-2 nopaddingnmargin">
                            <select class="form-control searchtype nopaddingnmargin valid" [(ngModel)]="searchMode"
                                name="searchMode" tabindex="1" id="searchtype" aria-invalid="false"
                                (onChange)="searchModeChange()">
                                <option *ngFor="let x of _searchTypes" [value]="x.searchType">{{x.searchType}}</option>
                                <!--<option value="2">Begin</option>-->
                            </select>
                        </div>
                        <div class="form-group col-sm-10 col-md-10 col-lg-10 nopaddingnmargin">
                            <div class="input-group">
                                <input id="searchinput" class="form-control searchcontrol" tabindex="2"
                                    [(ngModel)]="searchQuery" name="searchQuery" #searchinput type="text"
                                    placeholder="OCR Search..." (keyup.enter)="handleSearchButtonClick()"
                                    (ngModelChange)="onModelChange($event,op,searchcol)" />
                                <!-- (ngModelChange)="onModelChange($event,op,searchcol)"-->
                                <span title="Clear" [ngStyle]="{'visibility': searchQuery?.length ? 'visible':''}"
                                    (click)="searchQuery=null;">×</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12  col-md-12 col-lg-12 ocrsearchbar">
                    <table>
                        <tbody id="autosearchtablebody">
                            <tr *ngFor="let azureSearchKeyword of azureSearchKeywords; let j = index">
                                <td class="form-group col-sm-2 col-md-2 col-lg-2 nopaddingnmargin">
                                    <ng-template [ngIf]="j >=0">
                                        <select class="form-control" id="condition{{j}}" name="condition{{j}}"
                                            [(ngModel)]="azureSearchKeyword.Condition" placeholder="Select Condition">
                                            <option *ngFor="let c of keywordConditions" [value]="c.value">{{c.label}}
                                            </option>
                                        </select>
                                    </ng-template>
                                </td>
                                <td class="nopaddingnmargin">
                                    <input id="cndValue{{j}}" type="text" name="cndValue{{j}}"
                                        [(ngModel)]="azureSearchKeyword.PostFixValue" class="input-sm" />
                                </td>
                                <td>
                                    <a class="button-icon hand-cursor pull-right" style="padding-right: 5px;"
                                        name="btnCndDel{{j}}" id="btnCndDel{{j}}"
                                        (click)="deleteInstance(azureSearchKeyword)">
                                        <i class="glyphicon glyphicon-remove"></i>
                                    </a>
                                </td>
                                <br>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="submit" pButton icon="fa fa-check" class="btn btn-primary minzindex" label="Add"
                    [disabled]="!heroForm.form.valid || isSubmitted || lastIsDot  || lastIsDotWonum"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnCancelClick(heroForm)" label="Cancel" [disabled]="isSubmitted"
                    (focusout)="dd.applyFocus()"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>