<!-- MAIN PANEL -->
<div role="main">

    <!-- MAIN CONTENT -->
    <div id="content" style="opacity: 1;">
        <section id="widget-grid-main" class="well" style="height:91vh;">
            <div id="content">
                <!-- row -->
                <div class="row">
                    <div class="col-sm-12">
                        <div class="well padding-10" style="background-color: #fff;margin-top: 20px;">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="row">
                                        <div class="col-md-1">
                                            <img src="/assets/img/logo-rdoc.png" class="img-responsive" alt="img">
                                        </div>
                                        <div class="col-md-11 padding-left-0">
                                            <h3 class="margin-top-0">
                                                <a href="javascript:void(0);">RDOC - Full Setup </a>
                                                <br>
                                            </h3>
                                            <p>
                                                RDOC can be installed from below link
                                            </p>
                                            <a class="btn btn-primary" href="javascript:void(0)" (click)="downloadRDOC()">Download </a>
                                        </div>
                                    </div>
                                    <hr>

                                    <!-- <div class="row">
                                      <div class="col-md-1">
                                          <img src="/assets/img/logo-rdoc.png" class="img-responsive" alt="img">
                                      </div>
                                      <div class="col-md-11 padding-left-0">
                                          <h3 class="margin-top-0">
                                              <a href="javascript:void(0);">RDOC - Latest Patch </a>
                                              <br>
                                          </h3>
                                          <p>
                                              RDOC latest patch can be installed from below link
                                          </p>
                                          <a class="btn btn-primary" href="javascript:void(0)" (click)="downloadRDOCPatch()">Download </a>
                                      </div>
                                  </div>
                                  <hr> -->
                                    <div class="row">
                                        <div class="col-md-1">
                                            <img style="height: 100px;" src="/assets/img/help/icon_pdf_file.png" class="img-responsive" alt="img">
                                        </div>
                                        <div class="col-md-11 padding-left-0">
                                            <h3 class="margin-top-0">
                                                <a href="javascript:void(0);">Acceptable User Policy </a>
                                                <br>
                                            </h3>
                                            <p>
                                                Acceptable User Policy can be downloaded from below link
                                            </p>
                                            <a class="btn btn-primary" href="javascript:void(0)" (click)="downloadAUP()">Download </a>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</div>
