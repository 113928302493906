import { Injectable } from "@angular/core";
export class NewUserManagement {

    constructor(
        public firstName: string,
        public lastName: string,
        public email: string,
        public status: boolean,
        public showAircraft: boolean,
        public permissions: any[],
        public aircraft: any[],
        public showViews: boolean,
        public views: any[],
        public userType: string,
        public UserGroupId: string,
        public userTenants:string[],
        public ExpiryDate?:Date
    ) { }

}
export interface NewUser {
    FirstName: string,
    LastName: string,
    Email?: string,
    Status: boolean,
    UserType?:string,
    ShowAircraft: boolean,
    RoleIds?: string[],
    AircraftIds?: any[],
    Password?: string,
    UserId?: string,
    ShowViews: boolean,
    ViewIds?: string[],
    UserGroupId: string,
    UserTenants:string
    ExpiryDate?:string
}
@Injectable()
export class UserPersonalInfo {
   public FirstName: string;
   public LastName: string;
   public Email?: string;
   public UserName:string;
   public PhoneNumber ?:string;
   public ProfileImage?:string
   public SignatureImage?:string
    
}

export enum TemplateType {
    User = 1,
    Aircraft = 2    
}