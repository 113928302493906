export class FileType {
    static CSV = "CSV";
    static TSV = "TSV";
    static XLSX = "XLSX";
    static XML = "XML";
    static TXT = "TXT";
    static PDF = "PDF";
}

export class FeedStatus {
    static Failed = "Failed";
    static Live = "Live";
    static Pause = "Pause";
}
export class FeedLogStatus {
    static Failed = "Failed";
    static Success = "Success";
    static Inprogress = "In progress";
}
export class FeedType {
    static Incoming = "Import";
    static Outgoing = "Export";
}

export class Interval {
    static Daily = "Daily";
    static Weekly = "Weekly";
    static Monthly = "Monthly";
    static EveryTime = "EveryTime";
    static None = "None";
}
export class SourceType {
    static FileStorage = "App Storage";
    static WebService = "Web Service Pull";
}
export class TargetType {
    static FileStorage = "App Storage";
    static EmailLink = "Email Link";
    static ROAMContainer = "ROAM Container";
}
export class ContainerCategory {
    static ContainerCategoryMXR = "MXR";
    static ContainerCategoryLOG = "LOG";
    static ContainerCategoryGEN = "GEN";
    static ContainerCategoryDOC = "DOC";
    static ContainerCategoryBND = "BND";
}