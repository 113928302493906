<p-confirmDialog [style]="{width: '517px'}"></p-confirmDialog>
<header role="heading" class="tab-header">

    <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>
</header>
<div class="row addaircraftcls">

    <article class="col-sm-12 col-md-12 col-lg-12" id="col2">
        <!-- Widget ID (each widget will need unique ID)-->

        <div class="jarviswidget" id="wid-id-0" data-widget-colorbutton="false" data-widget-editbutton="false"
            data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false">

            <header>
                <span class="widget-icon"><i class="fa fa-search"></i></span>
                <h2>Search Service</h2>

            </header>

            <!-- widget div-->
            <div class="widgetdiv">
                <!-- widget content -->
                <div class="widget-body">
                    <!-- widget grid -->
                    <section id="widget-grid0" class="">
                        <div class="navbar-form form-group">

                            <div id="myTabContent1" class="tab-content">
                                <div class="tab-pane fade active in" id="s1">
                                    <div class="smart-form">
                                        <ul id="widget-tab-1" class="nav nav-tabs">
                                            <li class="active">
                                                <a id="indexes" data-toggle="tab" [attr.tabindex]="0"
                                                    data-target="#hr11" aria-expanded="true" style="cursor: pointer;">
                                                    <span class="hidden-mobile hidden-tablet">Indexes </span>
                                                </a>
                                            </li>
                                            <li>
                                                <a id="indexers" data-toggle="tab" [attr.tabindex]="0"
                                                    data-target="#hr22" aria-expanded="false" 
                                                    (click)="onTabChange()"
                                                    style="cursor: pointer;">
                                                    <span class="hidden-mobile hidden-tablet" onclick="return false;"
                                                        (click)="onTabChange()">Indexers </span>
                                                </a>
                                            </li>

                                        </ul>
                                        <div class="tab-content">
                                            <div class="tab-pane fade active in" id="hr11">

                                                <div class="row">
                                                    <div class="form-group col-sm-12" style="margin-top:10px;">
                                                        <div class="panel panel-default"
                                                            style="border-right-width: 2px;">
                                                            <div class="panel-heading" role="heading">
                                                                <!-- <span class="widget-icon"><i class="fa fa-rss"></i></span>
                                                                    <h2>Feeds 32</h2> -->

                                                                <div class="jarviswidget-ctrls" title="Refresh">
                                                                    <a class="button-icon tablIndexFocus"
                                                                        [attr.tabindex]="2" id="btnRefresh"
                                                                        (keyup.enter)="loadSearchComponentsDetail()"
                                                                        (click)="loadSearchComponentsDetail()">
                                                                        <i class="glyphicon glyphicon-refresh"></i>
                                                                    </a>
                                                                </div>
                                                                <div class="jarviswidget-ctrls" title="Rebuild">
                                                                    <a class="button-icon tablIndexFocus"
                                                                        [attr.tabindex]="1" id="btnrunFeed"
                                                                        (keyup.enter)="rebuildAzureSearch()"
                                                                        (click)="rebuildAzureSearch()">
                                                                        <i class="glyphicon glyphicon-play"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div class="panel-body">
                                                                <div
                                                                    class="col-sm-12 picklist-padding fix-picklist-width">
                                                                    <ag-grid-angular #agGridIndexer id="agGridIndex"
                                                                        [gridOptions]="gridIndexOptionsColumns"
                                                                        [columnDefs]="indexColumnDefinition"
                                                                        [rowData]="gridIndexDataColumns"
                                                                        [animateRows]="true"
                                                                        style="height:48vh !important;width:100% !important"
                                                                        class="ag-theme-balham">
                                                                        <!-- (rowDragEnter)="onRowDragEnter($event)" (rowDragMove)="onRowDragMove($event)" (rowDragLeave)="onRowDragLeave($event)"  (rowDragEnd)="onRowDragEnd($event)" -->
                                                                    </ag-grid-angular>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="tab-pane fade" id="hr22">


                                                <div class="row">
                                                    <div class="form-group col-sm-12" style="margin-top:10px;">
                                                        <div class="panel panel-default"
                                                            style="border-right-width: 2px;">

                                                            <div class="panel-heading" role="heading">
                                                                <div class="jarviswidget-ctrls" title="Delete Indexer">
                                                                    <a class="button-icon tablIndexFocus"
                                                                        [attr.tabindex]="0" id="btnrunFeed"
                                                                        (keyup.enter)="confirmDeleteOperation()"
                                                                        (click)="confirmDeleteOperation()">
                                                                        <i class="glyphicon glyphicon-trash"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div class="panel-body">
                                                                <div
                                                                    class="col-sm-12 picklist-padding fix-picklist-width">
                                                                    <ag-grid-angular #agGridIndexer id="agGridIndexer"
                                                                        [gridOptions]="gridIndexerOptionsColumns"
                                                                        [columnDefs]="indexerColumnDefinition"
                                                                        [rowData]="gridIndexerDataColumns"
                                                                        [animateRows]="true"
                                                                        style="height:48vh !important;width:100% !important"
                                                                        class="ag-theme-balham api-inc-out-grid">
                                                                        <!-- (rowDragEnter)="onRowDragEnter($event)" (rowDragMove)="onRowDragMove($event)" (rowDragLeave)="onRowDragLeave($event)"  (rowDragEnd)="onRowDragEnd($event)" -->
                                                                    </ag-grid-angular>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                    <!-- <div class="widget-footer">
                          <button type="submit" class="btn btn-primary margin-right-3px">
                              Reset
                          </button>
                          <button type="button" class="btn btn-default" >
                              Cancel
                          </button> 
                          
                      </div> -->
                </div>
            </div>
        </div>
        <!-- end widget -->
    </article>
    <!-- END COL -->
</div>