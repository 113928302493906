export class Alerts {

    constructor(

        public MaintenanceFrom: string = "",
        public MaintenanceSubject: string = "",
        public MaintenanceAlias: string = "",
        public MaintenanceBody: string = "",
        public ShowUsers: boolean = false,
        public UserTenants: string[] = [],
    ) { }

}

export interface SelectedTenant {

    label?: string;
    value?: string;

}