//#region References

//Components
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

//Services
import { SharedService } from '../../../shared/services/shared.service';

import { APIService } from '../../services/api.service';
import { feedlog } from '../../components/feed-log/feed-log';
import * as moment from 'moment/moment';
import { Subscription } from "rxjs";
import { AddUpdateStatusCode } from '../../../shared/helpers/config';

//#endregion

//#region TypeDecorator
@Component({
    selector: 'download-feed',
    templateUrl: './download-feed.component.html',
    providers: [APIService]
})
//#endregion

export class DownloadFeedComponent implements OnInit, OnDestroy {

    //#region Locals Declaration

    display: boolean = false;

    @Input() feedlogobj: feedlog;
    showDataButton:boolean = true;
    
    private downloadFeedLogSub: Subscription;
    private downloadFeedDataSub: Subscription;
    //#endregion

    //#region Initialization of Component
    constructor(private _sharedService: SharedService, private _router: Router, private _apiService: APIService, private _route: ActivatedRoute) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
    }

    ngOnInit() {
        if (this._router.url.indexOf("Export") != -1 || this._router.url.indexOf("export") != -1) {
            this.showDataButton = false;
        }
        this.downloadFeedLogSub = this._sharedService._feedLogDownloadLogSub
        .subscribe({
            next: (feeddata: feedlog) => {
                this.feedlogobj = feeddata;
                this.downloadFeedLogFile();
            },
            error: (error) => {
            }
        })

        this.downloadFeedDataSub = this._sharedService._feedLogDownloadDataSub
        .subscribe({
            next: (feeddata: feedlog) => {
                this.feedlogobj = feeddata;
                this.downloadFeedFile();
            },
            error: (error) => {
            }
        });

    }
    ngOnDestroy() {
        if (this.downloadFeedLogSub) {
            this.downloadFeedLogSub.unsubscribe();
        }

        if (this.downloadFeedLogSub) {
            this.downloadFeedLogSub.unsubscribe();
        }
    }
    //#endregion

    //#region Form Events

    downloadFeedFile() {

        if (this.feedlogobj && this.feedlogobj.FeedLogId != null) {
            this._sharedService.showLoader("Processing...");
            let FeedLog: feedlog = { FeedLogId: this.feedlogobj.FeedLogId };
            this._apiService.downloadFeedFile(FeedLog)
            .subscribe({
                next: (response) => {
                    this._sharedService.hideLoader();                   
                    let blob = new Blob([response], { type: 'application/zip' });
                    let url = URL.createObjectURL(blob);
                    this.formatZipFileName(url, this.feedlogobj.FileName.substring(0, this.feedlogobj.FileName.lastIndexOf(".")));
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    // if (error.status === 409) 
                    // {
                    //     this._sharedService.clearToast();
                    //     this._sharedService.showToast({ severity: 'warn', summary: 'Not Allowed', detail: `Not functional for FTP`});
                    // }
                    // else
                    // {
                    console.log(error);                    
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'Error.', detail: 'File not exists.' });
                }
            });
        }
        else {
          this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Feed Log Selected', detail: 'Please select a row first' });
        }
    }
    downloadFeedLogFile() {

        if (this.feedlogobj && this.feedlogobj.FeedLogId != null) {
            this._sharedService.showLoader("Processing...");
            let FeedLog: feedlog = { FeedLogId: this.feedlogobj.FeedLogId, FeedId: this.feedlogobj.FeedId };
            this._apiService.downloadFeedLogFile(FeedLog)
            .subscribe({
                next: (response) => {
                    this._sharedService.hideLoader();
                    let blob = new Blob([response], { type: 'application/zip' });
                    try {
                        let url = URL.createObjectURL(blob);
                        if (this.showDataButton) 
                        { 
                            if (this.feedlogobj.FileName) {
                                this.formatZipFileName(url, this.feedlogobj.FileName.substring(0, this.feedlogobj.FileName.lastIndexOf("."))); 
                            }
                            else{
                                this.formatZipFileName(url,'Log File'); 
                            }
                           
                        }
                        else { this.formatZipFileName(url, this.feedlogobj.FeedLogId + "_" + moment().format("YYYYMMDD"));  }
                    } catch (error) {
                     console.log(error);   
                    }
                },
                error: (error) => {
                    console.log(error);
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'Error.', detail: 'File does not exists.' });
                }
            });
        }
        else {
          this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Feed Log Selected', detail: 'Please select a row first' });
        }
    }
    //#endregion

    //#region Helper Methods

    formatZipFileName(fileURL: string, fileName: string) {
        fileName = fileName.replace(/[.]/g, "");
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = fileURL;
        a.target = '_blank';
        a.download = fileName;
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
    }


    //#endregion

}
