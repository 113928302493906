export class AzureBlob {
    constructor(
        public BlobName: string,
        public ContainerName?: string,
        public ID?: string,
        public Type?: string,
        public ASN?: string,
        public Url?: string,
        public DocType?: string,
        public DocDate?: string,
        public DocNo?: string,
        public Tags?: string,
        public Comments?: string,
        public WONumber?: string,
        public SeqNo?: string,
        public OCR?: boolean,
        public ReferenceKey?: string,
        public Uuid?:string
    ) { }
}

export class BlobMetaData {
    constructor(
        public BlobName: string,
        public ASN: string,
        public DocType: string,
        public DocNo: string,
        public Tags: string,
        public Comments: string,
        public ReferenceKey: string,
        public SeqNo: string,
        public FileType: string,
        public Url: string,
        public File?: File,
        public DocDate?: Date,
        public OCR?: number,
        public docNumberSwitch?: number,
        public taskId?: string[],
        public Status?: string,
        public isImageSwitch?: number,
        public IsImage?: string,
        public PageRange?: string
    ) { }

}

export class BlobEditMetaData {
    constructor(
        public BlobName: string,
        public ASN: string,
        public DocType: string,
        public DocNo: string,
        public Tags: string,
        public Comments: string,
        public ReferenceKey: string,
        public Url: string,
        public DocDate?: Date,
        public History?: string,
        public FileName?: string,
        public NewFileName?: string,
        public SeqNo?: string,
        public Status?: string,
        public ContainerID?: string,
        public ContainerCatergory?: string,
        public Uuid?:string
    ) { }

}

export class AzureBlobForWOGap {
    constructor(
        public ContainerName?: string,
        public ID?: string,
        public Type?: string,
        public ASN?: string,
        public Url?: string,
        public DocType?: string,
        public DocDate?: string,
        public DocNo?: string,
        public Tags?: string,
        public Comments?: string,
        public ReferenceKey?: string,
        public SeqNo?: string,
        public OCR?: boolean,
        public ContainerDescription?: string
    ) { }
}
