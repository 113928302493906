<!--<header id="header">

    <div id="logo-group">
        <span id="logo">
            <img src="../../../Content/img/ROAMTLogo.png" alt="ROAM">

        </span>
    </div>

</header>-->

<div class="no-top-padding back-image">

    <!-- MAIN CONTENT -->
    <div class="container" *ngIf="isNormalLogin">

        <div class="row login-top-padding">
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 hidden-xs hidden-sm">

            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4" *ngIf="!isForgetPswd">
                <div style="background-color:transparent;margin-top:80px;">
                    <!--well no-padding-->
                    <!--<div *ngIf="incorrectpswd" style="color: red;">
                        The server could not sign you in. Make sure your user name and password are correct, and then try again.
                    </div>-->
                    <div *ngIf="incorrectpswd"  class="alert adjusted fade in {{errorMsgClass}}" style="margin-bottom:10px;">
                        <button class="close" data-dismiss="alert">
                            ×
                        </button>
                        <i class="fa fa-fw fa-lg fa-exclamation"></i>
                        {{errorMessage}}
                    </div>

                    <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)"
                          class="smart-form client-form" novalidate>
                        <header>
                            <!--Sign In-->
                            <span id="logo" style="margin:0px;">
                                <img src="../../../../assets/img/ROAMLogo.png" alt="ROAM">

                            </span>
                        </header>

                        <fieldset>

                            <section>
                                <label class="label">E-mail</label>
                                <label class="input">
                                    <i class="icon-append fa fa-user"></i>
                                    <input [attr.tabindex]="0" class="tablIndexFocus" type="email" #username name="email"
                                           id="email" ngModel disabled="{{isInternalUser}}">
                                    <b class="tooltip tooltip-top-right">
                                        <i class="fa fa-user txt-color-teal"></i> Please enter email address/username
                                    </b>
                                </label>
                            </section>

                            <section>
                                <label class="label">Password</label>
                                <label class="input">
                                    <i class="icon-append fa fa-lock"></i>
                                    <input [attr.tabindex]="0" class="tablIndexFocus" type="password" #password
                                           name="password" id="password" ngModel disabled="{{isInternalUser}}">
                                    <b class="tooltip tooltip-top-right">
                                        <i class="fa fa-lock txt-color-teal"></i> Enter your password
                                    </b>
                                </label>
                                <div  class="note hand-cursor">
                                    <a [attr.tabindex]="0" class="tablIndexFocus" (keyup.enter)="forgetPasswordClick()" (click)="forgetPasswordClick()">Forgot password?</a>
                                </div>
                            </section>

                            <section *ngIf="isInternalUser && !isEnable2FactorAuth">
                                <label class="label">Tenant</label>

                                <p-dropdown appendTo="body" [attr.tabindex]="0" id="ddlTenant" class="tablIndexFocus" [options]="clients" [(ngModel)]="selectedClient" [ngModelOptions]="{standalone: true}" placeholder="Select Tenant"></p-dropdown>
                            </section>

                            <section *ngIf="isEnable2FactorAuth">
                                <label class="label"> Authenticator Code</label>
                                <label class="input">
                                    <i class="icon-append fa fa-key"></i>
                                    <input [attr.tabindex]="0"  class="tablIndexFocus" type="password" #usercode name="code"
                                           id="code" ngModel>
                                    <b class="tooltip tooltip-top-right">
                                        <i class="fa fa-user txt-color-teal"></i> Please enter 6 Digit Authenticator Code
                                    </b>
                                </label>
                                <div  class="note hand-cursor">
                                    <a [attr.tabindex]="0"  class="tablIndexFocus" (keyup.enter)="sendUserSharedKey()" (click)="sendUserSharedKey()">Email two factor authenticator registration key.</a>
                                </div>
                            </section>

                            <section>
                                <label class="checkbox">
                                    <input [attr.tabindex]="-1" type="checkbox" name="remember" [checked]="_sharedService.isChecked"  >
                                    <i [attr.tabindex]="0" class="tablIndexFocus" (keyup.enter)="_sharedService.ToggleChecked()"></i>Stay signed in
                                </label>
                            </section>
                        </fieldset>
                        <footer>
                            <label style="padding-top: 20px;font-size: smaller; font-weight: bold;">{{appVersion}}</label>
                            <button [attr.tabindex]="0"  type="submit" class="btn btn-primary" *ngIf="!isInternalUser && !isEnable2FactorAuth">
                                <i [className]="login_Wheel"></i>  Sign in
                            </button>
                            <button [attr.tabindex]="0"  type="button" class="btn btn-primary" *ngIf="isInternalUser && !isEnable2FactorAuth" (click)="actionProceedClick()">
                                <i></i>  Proceed
                            </button>
                            <button [attr.tabindex]="0"  type="button" class="btn btn-primary" *ngIf="isEnable2FactorAuth" (click)="validate2FactorAuth()">
                                <i [className]="login_Wheel"></i>  Continue
                            </button>
                        </footer>
                    </form>

                </div>

            </div>
            <div id="forgetpswd" class="col-xs-4 col-sm-4 col-md-4 col-lg-4 top-margin-forgetpswd" *ngIf="isForgetPswd">
                <div style="background-color:transparent;">
                    <div *ngIf="forgetpswdmsg" style="color: red;">

                    </div>

                    <form #forgetpswdForm="ngForm" (ngSubmit)="onForgetPswd(forgetpswdForm)"
                          class="smart-form client-form" novalidate>
                        <header>
                           <h3>Forgot Password</h3>
                        </header>

                        <fieldset>

                            <section>
                                <label class="label">Enter your email address</label>
                                <label class="input">
                                    <i class="icon-append fa fa-user"></i>
                                    <input [attr.tabindex]="0"  class="tablIndexFocus"  id="emailforget" type="email" name="emailforget" [(ngModel)]="emailForget" #emailforget="ngModel" required>
                                    <b class="tooltip tooltip-top-right">
                                        <i class="fa fa-user txt-color-teal"></i> Please enter email address/username
                                    </b>
                                </label>
                                <div class="note hand-cursor">
                                    <a [attr.tabindex]="0"  class="tablIndexFocus"  (keyup.enter)="rememberPasswordClick()" (click)="rememberPasswordClick()">I remembered my password!</a>
                                </div>
                            </section>
                        </fieldset>
                        <footer>
                            <button [attr.tabindex]="0" type="submit" class="btn btn-primary" [disabled]="!forgetpswdForm.form.valid || isSubmitted">
                                <i [className]="login_Wheel"></i>  Reset Password
                            </button>
                        </footer>
                    </form>

                </div>

            </div>
        </div>
    </div>

</div>

