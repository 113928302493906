import { Component } from '@angular/core';
import "ag-grid-enterprise";
import { ITooltipParams } from "ag-grid-community";
import { ITooltipAngularComp } from "ag-grid-angular";
import { comm } from './comm';

@Component({
  selector: 'custom-tooltip',
  template: ` <div class="custom-tooltip" [style.background-color]="color">
    <p>
      <span>ASN: {{ data.ASN }}</span>
    </p>
    <p><span>Title: </span>{{ data.Title }}</p>
    <p><span>Module: </span>{{ data.Module }}</p>
  </div>`,
  styles: [
    `
      :host {
        position: absolute;
        width: 220px;
        height: 70px;
        pointer-events: none;
        transition: opacity 1s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip p {
        margin: 5px;
        white-space: nowrap;
      }

      .custom-tooltip p:first-of-type {
        font-weight: bold;
      }
    `,
  ],
})
export class CustomTooltip implements ITooltipAngularComp {
  private params: { color: string } & ITooltipParams;
  public data: comm;
  public color: string;

  agInit(params: { color: string } & ITooltipParams): void {
    this.params = params;
    this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
    this.color = this.params.color || 'white';
  }
}