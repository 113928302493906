<div class="jarviswidget-ctrls hand-cursor" title="Bulk Upload">
    <a class="button-icon tablIndexFocus"  [attr.tabindex]="0"  (keyup.enter)="showUploadDialog()" id="btnuploadtemplate" (click)="showUploadDialog()">
        <i class="glyphicon glyphicon-open"></i>
    </a>
</div>

<form #uploadtemplateform="ngForm">
    <p-dialog [(visible)]="uploaddisplay" showEffect="fade" [modal]="true" [style]="{width: '550px'}" [resizable]="false">
        <ng-template pTemplate="header"  (keyup.shift.tab)="btnCancelClick(uploadtemplateform)">
            {{dialogTitle}}
        </ng-template>
        <div class="upload-dialog">
            <div id="commAddDlg" class="smart-form" >               
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">{{dialogLable}}</label> 
                            <p-fileUpload   name="feedfile" (onSelect)="onSelect($event)" (onRemove)="onRemove($event)" customUpload="true" uploadHandler="onBulkUpload($event,uploadtemplateform)" chooseLabel="Browse"  [(ngModel)]="bulkFiles" [accept]="acceptfilestype" invalidFileTypeMessageSummary="Invalid File(s)" invalidFileTypeMessageDetail="Only Excel files are valid"  #feedfile ngDefaultControl>
                            </p-fileUpload>                         
                        </fieldset>
                    </div>
                </div>               
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex" (click)="onBulkUpload($event,uploadtemplateform)" label="Process" [disabled]="!uploadtemplateform.form.valid || isSubmitted" *ngIf="(uploadedFiles.length>0)"></button>                    
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" (click)="btnCancelClick(uploadtemplateform)" label="Cancel" [disabled]="isSubmitted" ></button>                
            </div>
        </ng-template>

    </p-dialog>
</form>

<p-dialog id="agGridUserUploaddialoge"  *ngIf="displayUserGrid"  [(visible)]="displayUserGrid" showEffect="fade" [modal]="true" [style]="{width: '1050px'}" [resizable]="false">
    <ng-template pTemplate="header" >
        Process Status
    </ng-template>
    <div class="upload-dialog">
        <ag-grid-angular id="agGridUserUploadGrid"  #agGridUserUploadGrid [gridOptions]="errorGridOptionUser" [columnDefs]="errorColumnDefsUser" [rowData]="userGridData" style="width: 100% !important;height:500px !important;" pagination="true" paginationPageSize="10" class="ag-theme-balham">
        </ag-grid-angular>
    </div>
    
    <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">           
            <button type="submit" pButton icon="fa fa-check" class="btn btn-primary minzindex" label="Ok" (click)="btnCancelDialogueClick()"></button>
        </div>
    </ng-template>
</p-dialog>


<p-dialog id="agGridAircraftUploaddialoge" *ngIf="displayAircraftGrid" [(visible)]="displayAircraftGrid" showEffect="fade" [modal]="true" [style]="{width: '1050px'}" [resizable]="false">
    <ng-template pTemplate="header" >
        Process Status
    </ng-template>
    <div class="upload-dialog">
        <ag-grid-angular id="agGridAircraftUploadGrid"  #agGridAircraftUploadGrid [gridOptions]="errorGridOptionAircraft" [columnDefs]="errorColumnDefsAircraft" [rowData]="aircraftGridData" style="width: 100% !important;height:500px !important;" pagination="true" paginationPageSize="10" class="ag-theme-balham">
        </ag-grid-angular>
    </div>

    <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">
            <button type="submit" pButton icon="fa fa-check" class="btn btn-primary minzindex" label="Ok" (click)="btnCancelDialogueClick()"></button>        
        </div>
    </ng-template>
</p-dialog>