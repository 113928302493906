<div class="jarviswidget-ctrls pdfoperations" role="menu" *ngIf="canUpload || canBulkUpload">
    <div class="btn-group" style="cursor: pointer;">
        <a class="button-icon dropdown-toggle tablIndexFocus" [attr.tabindex]="0" data-toggle="dropdown"
            aria-expanded="true" title="Upload Document(s)">
            <i class="glyphicon glyphicon-open"></i>
        </a>
        <ul class="dropdown-menu pull-right js-status-update">
            <li *ngIf="canUpload">
                <a href="javascript:void(0);" class="tablIndexFocus" [attr.tabindex]="0"
                    (keyup.enter)="OpenSingleModal()" (click)="OpenSingleModal();">
                    <i class="fa fa-columns txt-color-green clsdownload"></i>&nbsp;Single (Ctrl+U)
                </a>
            </li>
            <li *ngIf="canBulkUpload">
                <a href="javascript:void(0);" class="tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="OpenBulkModal()"
                    (click)="OpenBulkModal();">
                    <i class="fa fa-columns txt-color-green clsdownload"></i>&nbsp;Bulk (Ctrl+Shift+U)
                </a>
            </li>

        </ul>
    </div>
</div>


<p-confirmDialog *ngIf="isDisplayDialog" header="Duplicate Files, Override" icon="fa fa-question-circle"
    [style]="{width: '435px'}">
</p-confirmDialog>

<form #frmUploadDoc="ngForm" *ngIf="isDisplayDialog">
    <!--name="frmUploadDoc"-->
    <p-dialog [(visible)]="isDisplayDialog" *ngIf="isDisplayDialog" id="docUpload" showEffect="fade" [resizable]="false"
        [modal]="true" [style]="{width: '700px'}">
        <ng-template pTemplate="header">
            {{dialogTitle}}
        </ng-template>

        <!--Contents Start Here-->


        <div class="upload-dialog">
            <!-- Comment for tabbing -->
            <div id="smart-form" class="smart-form">
                <div id="form-fields">
                    <div class="row" *ngIf="((canGENQualityStatus && processGENQualityStatusEnabled)
                                                || (canMXRQualityStatus && processMXRQualityStatusEnabled)
                                                || (canLOGQualityStatus && processLOGQualityStatusEnabled)
                                                || (canDOCQualityStatus && processDOCQualityStatusEnabled)
                                                || (canBNDQualityStatus && processBNDQualityStatusEnabled))">
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">
                                    <!-- Status  -->
                                    {{_sharedService.getDocumentLabel(recordType, "Status")}}
                                    <!-- <span class="text-danger">*</span> -->
                                    <span [hidden]="(recordType=='DOC'||recordType=='BND')" class="text-danger">
                                        *
                                    </span>
                                </label>
                                <span>
                                    <p-dropdown appendTo="body" [options]="qualityStatusList" requried name="qualityStatus"
                                        #qualityStatus="ngModel" placeholder="Select Quality Status"
                                        [style]="{width:'300px'}" [(ngModel)]="metaData.Status" [filter]="true">
                                    </p-dropdown>
                                </span>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">
                                    <!-- Document Type  -->
                                    {{_sharedService.getDocumentLabel(recordType, "DocType")}}
                                    <!-- <span class="text-danger">*</span> -->

                                    <span [hidden]="(recordType=='DOC'||recordType=='BND')" class="text-danger">
                                        *
                                    </span>

                                </label>
                                <p-dropdown appendTo="body" [options]="DocType" requried name="docType1" #docType1="ngModel"
                                    [(ngModel)]="metaData.DocType" [filter]="true" #doct [resetFilterOnHide]="true"
                                    placeholder="Select Document Type"
                                    [required]="(recordType!=='DOC' && recordType!=='BND')"></p-dropdown>
                            </fieldset>
                        </div>
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">
                                    <!-- Document Date  -->
                                    {{_sharedService.getDocumentLabel(recordType, "DocDate")}}
                                    <!-- <span class="text-danger">*</span> -->
                                    <span [hidden]="(recordType=='DOC'||recordType=='BND')" class="text-danger">
                                        *
                                    </span>
                                    ({{ViewDateFormat}})
                                </label>
                                <label class="input"
                                    [ngClass]="{'readonlyinput': parentWoNumber !='' && recordType=='MXR'}">
                                    <!--|| parentWoNumber !='null'-->
                                    <i class="icon-append fa fa-calendar"></i>
                                    <!--<input type="text" tabindex="3" placeholder="Select a date" class="datepicker hasDatepicker" id="DocDate">-->
                                    <p-calendar appendTo="body" [monthNavigator]="true" [yearNavigator]="true"
                                        (onClose)="onCloseDocDate()" class="datepicker hasDatepicker"
                                        yearRange="1950:2030" [style]="{width:300}" placeholder="Select a date"
                                        [required]="(recordType!=='DOC' && recordType!=='BND')" name="docDate"
                                        #docDate="ngModel" [(ngModel)]="metaData.DocDate"
                                        [dateFormat]="dateFormatCalender" placeholder="{{ViewDateFormat}}"
                                        [disabled]="parentWoNumber !='' && recordType=='MXR'"></p-calendar>
                                    <!-- [style]="{width:300}" [(ngModel)]="date3"-->
                                </label>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <fieldset>
                                <div class="form-group col-sm-6">
                                    <span id="filetypesingle" [attr.tabindex]="0">
                                        <!-- <label class="label">File input<span class="text-danger">*</span></label> -->
                                        <p-selectButton [attr.tabindex]="0" id="filetypesingle" name="filetypesingle"
                                            [options]="FileTypeList" (onChange)="onChangeDocTypeSingle($event)"
                                            [(ngModel)]="metaData.FileType">
                                        </p-selectButton>
                                    </span>
                                </div>
                                <div *ngIf="showExtractPageRangePDF && isuploadDoc">
                                    <div class="form-group col-sm-2">
                                        <label style="padding-top: 12px;" class="label">
                                            Pages to Extract
                                        </label>
                                    </div>
                                    <div class="form-group col-sm-4">
                                        <fieldset>
                                            <input type="text" placeholder="  e.g. 1-5, 8, 11-13" class="pull-right"
                                                id="PageRange" name="PageRange" [(ngModel)]="metaData.PageRange"
                                                #PageRange="ngModel" />
                                        </fieldset>
                                    </div>
                                </div>
                                <span class="onoffswitch pull-right" *ngIf="showUploadAsImage">
                                    <input type="checkbox" name="isImageSwitch" class="onoffswitch-checkbox"
                                        checked="checked" [disabled]="disableImageLoadOption" id="isImageSwitch"
                                        [(ngModel)]="metaData.isImageSwitch" #IsImage="ngModel">
                                    <label class="onoffswitch-label" for="isImageSwitch">
                                        <span class="onoffswitch-inner" data-swchon-text="YES"
                                            data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span>
                                    </label>
                                </span>
                                <span class="pull-right" style="padding-top: 3px;color: #333;"
                                    *ngIf="showUploadAsImage">Convert to PDF</span>
                                <div class="row col-sm-12" *ngIf="isuploadDoc">
                                    <div class="form-group col-sm-2">
                                        <fieldset>
                                            <div class="input input-file" *ngIf="isuploadDoc">
                                                <label for="singleFileUpload" class="btn custom-button" >Choose File</label>
                                                <input style="padding-left: 13px; visibility: hidden;display: none;" id="singleFileUpload" #singleFileUpload type="file"
                                                    class="form-control" (change)="onChange($event)"
                                                    accept={{acceptFileTypes}}>
                                                <!-- accept="application/pdf,image/bmp,image/dcx,image/pcx,image/png,image/jpg,image/jpeg,image/jp2,image/jpc,image/jfif,image/tif,image/tiff,image/gif,image/djvu,image/djv,image/jb2,audio/mpeg,audio/wav,audio/ogg,video/webm,video/mp4,video/ogg,application/zip,application/vnd.rar,application/rar,.zip,.rar,.7zip" -->
                                                <!--requried name="fileUpload" #fileUpload="ngModel" [(ngModel)]="metaData.File"-->
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div class="form-group col-sm-10">
                                        <fieldset>
                                            <label class="input">
                                                <input id="BlobName" type="text" [readonly]="!(recordType==='DOC'|| recordType==='BND')" tabindex="6" class="input-smText"
                                                    [(ngModel)]="metaData.BlobName" name="BlobName" #BlobName="ngModel">
                                            </label>
                                        </fieldset>
                                    </div>
                                </div>
                                <div class="row" *ngIf="!isuploadDoc">
                                    <div class="form-group col-sm-12">
                                        <fieldset>
                                            <div class="input input-file" *ngIf="!isuploadDoc">
                                                <input id="docUrlSingle" type="url" class="form-control"
                                                    placeholder="https://www.youtube.com/watch?v=-Z4eexcTMNA, https://vimeo.com/76979871"
                                                    [(ngModel)]="metaData.Url" name="docUrlSingle"
                                                    #docUrlSingle="ngModel">
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                            </fieldset>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">
                                    <!-- Document Number -->
                                    {{_sharedService.getDocumentLabel(recordType, "DocNo")}}
                                </label>

                                <label class="input rgxErr">
                                    <i *ngIf="docNo.value && !docNo.valid && (docNo.touched || docNo.dirty )"
                                        class="icon-append fa fa-ban"
                                        title="{{_sharedService.error_rgx_pre}} {{_sharedService.re_DocumentNumber_Message}} {{_sharedService.error_rgx_post}}">
                                    </i>
                                    <input id="docNumber" type="text" tabindex="6" class="input-smText"
                                        [pattern]="_sharedService.re_DocumentNumber" [(ngModel)]="metaData.DocNo"
                                        name="docNo" #docNo="ngModel">
                                    <!--requried name="docNo" #docNo="ngModel" [(ngModel)]="metaData.DocNo"-->
                                </label>
                            </fieldset>
                        </div>
                        <div class="form-group col-sm-6" *ngIf="recordType == 'MXR'">
                            <fieldset>
                                <label class="label">
                                    <!-- {{referenceKeyLabel}}  -->
                                    {{_sharedService.getDocumentLabel(recordType, "ReferenceKey")}}
                                    <span class="text-danger">*</span>
                                </label>
                                <label class="input rgxErr">
                                    <i *ngIf="woNumber.value && !woNumber.valid && (woNumber.touched || woNumber.dirty )"
                                        class="icon-append fa fa-ban"
                                        title="{{_sharedService.error_rgx_pre}} {{_sharedService.re_WorkOrderNumber_Message}} {{_sharedService.error_rgx_post}}">
                                    </i>
                                    <input id="woNumber" requried name="woNumber" #woNumber="ngModel"
                                        [(ngModel)]="metaData.ReferenceKey" [disabled]="parentWoNumber != ''"
                                        [ngClass]="{'readonlyinput2': parentWoNumber != ''}" type="text" tabindex="6"
                                        [pattern]="_sharedService.re_WorkOrderNumber" class="input-smText">
                                    <!--[disabled]="metaData.WONumber==null || metaData.WONumber==''"-->
                                </label>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <fieldset>
                                <label class="label">
                                    <!-- Tags -->
                                    {{_sharedService.getDocumentLabel(recordType, "Tags")}}
                                </label>
                                <p-multiSelect [options]="tags" [(ngModel)]="selectedTags" name="tags" placeholder="Choose"
                                    [ngModelOptions]="{standalone: true}" [style]="{width:'100%'}" appendTo="body"
                                    [maxSelectedLabels]="4" selectedItemsLabel="{0} items selected"></p-multiSelect>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <fieldset>
                                <label class="label">
                                    <!-- Comments -->
                                    {{_sharedService.getDocumentLabel(recordType, "Comments")}}
                                </label>
                                <label class="textarea"><textarea id="comments" requried name="comments"
                                        #comments="ngModel" [(ngModel)]="metaData.Comments" tabindex="4"
                                        class="custom-scrollNote" rows="2"></textarea></label>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--Contents End Here-->

        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary" (click)="btnUpload()"
                    [disabled]="!frmUploadDoc.form.valid || isSubmitted || (recordType !== 'DOC' && recordType !=='BND' && metaData.DocType=='')||
                    (recordType == 'MXR' && metaData.ReferenceKey.length==0)||(recordType !== 'DOC' && recordType !=='BND' && metaData.DocDate==null)
                    || ((
                        (canGENQualityStatus && processGENQualityStatusEnabled)
                        || (canMXRQualityStatus && processMXRQualityStatusEnabled)
                        || (canLOGQualityStatus && processLOGQualityStatusEnabled)) && ( metaData.Status==null || metaData.Status==''))"
                    label="Upload Document"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default"
                    (click)="btnCancelClick(frmUploadDoc)" label="Cancel" [disabled]="isSubmitted"></button>
                <!--||metaData.DocDate.length==0-->
            </div>
        </ng-template>
    </p-dialog>
</form>


<p-confirmDialog *ngIf="isDisplayBulkDialog" header="Duplicate Files" [style]="{width: '435px'}">
</p-confirmDialog>

<form #bulkUploadForm="ngForm" *ngIf="isDisplayBulkDialog">
    <!--name="frmBulkUpload"-->
    <p-dialog [(visible)]="isDisplayBulkDialog" id="bulkUpload" showEffect="fade" [modal]="true"
        [style]="{width: '700px',top:'-13px'}" closable="false" [resizable]="false">
        <ng-template pTemplate="header">
            {{bulkDialogTitle}}
        </ng-template>


        <div class="upload-dialog" style="height: 78vh; overflow-x: hidden;">
            <!-- comment for tabbing -->
            <div id="smart-form" class="smart-form">
                <div id="form-fields">
                    <div class="row" *ngIf="((canGENQualityStatus && processGENQualityStatusEnabled)
                                                || (canMXRQualityStatus && processMXRQualityStatusEnabled)
                                                || (canLOGQualityStatus && processLOGQualityStatusEnabled)
                                                || (canDOCQualityStatus && processDOCQualityStatusEnabled)
                                                || (canBNDQualityStatus && processBNDQualityStatusEnabled)
                                                )">
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">
                                    <!-- Status  -->
                                    {{_sharedService.getDocumentLabel(recordType, "Status")}}
                                    <span [hidden]="(recordType=='DOC'||recordType=='BND')" class="text-danger">
                                        *
                                    </span>
                                </label>
                                <span>
                                    <p-dropdown appendTo="body" [options]="qualityStatusList" requried name="qualityStatus"
                                        #qualityStatus="ngModel" placeholder="Select Quality Status"
                                        [style]="{width:'300px'}" [(ngModel)]="metaDataBulk.Status" [filter]="true">
                                    </p-dropdown>
                                </span>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">
                                    <!-- Document Type  -->
                                    {{_sharedService.getDocumentLabel(recordType, "DocType")}}
                                    <span [hidden]="(recordType=='DOC'||recordType=='BND')" class="text-danger">
                                        *
                                    </span>
                                </label>
                                <p-dropdown appendTo="body" [options]="DocType" requried id="docTypeb" name="docTypeb"
                                    #docTypeb="ngModel" [(ngModel)]="metaDataBulk.DocType" [filter]="true"
                                    placeholder="Select Document Type" #bdoctype [resetFilterOnHide]="true">
                                </p-dropdown>
                            </fieldset>
                        </div>
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">
                                    <!-- Document Date  -->
                                    {{_sharedService.getDocumentLabel(recordType, "DocDate")}}
                                    <span [hidden]="(recordType=='DOC'||recordType=='BND')" class="text-danger">
                                        *
                                    </span>
                                    ({{ViewDateFormat}})
                                </label>
                                <label class="input"
                                    [ngClass]="{'readonlyinput': parentWoNumber != '' && recordType=='MXR'}">
                                    <i class="icon-append fa fa-calendar"></i>
                                    <p-calendar appendTo="body" class='datepicker hasDatepicker' id='docDate' name="docDate"
                                        (onClose)="onCloseDocDateMultipleUpload()" [monthNavigator]="true"
                                        [yearNavigator]="true" yearRange="1950:2030" requried
                                        [disabled]="parentWoNumber != '' && recordType=='MXR'"
                                        [(ngModel)]="metaDataBulk.DocDate" #docDate="ngModel"
                                        placeholder="{{ViewDateFormat}}" [dateFormat]="dateFormatCalender"
                                        [style]="{width:300}">
                                    </p-calendar>
                                </label>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <fieldset>
                                <label class="label">
                                    <!-- Document Number -->
                                    {{_sharedService.getDocumentLabel(recordType, "DocNo")}}
                                </label>
                                <label class="checkbox" id="onoffswitch" [attr.tabindex]="0">
                                    <span class="onoffswitch tablIndexFocus" [attr.tabindex]="0"
                                        (keyup.enter)="ToggleChecked()">
                                        <input type="checkbox" name="docNumberSwitch" [attr.tabindex]="0"
                                            class="onoffswitch-checkbox" checked="checked" id="docNumberSwitch"
                                            [checked]="isChecked" [(ngModel)]="metaDataBulk.docNumberSwitch"
                                            #docNumberSwitch="ngModel">
                                        <label class="onoffswitch-label" for="docNumberSwitch">
                                            <span class="onoffswitch-inner" data-swchon-text="YES"
                                                data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span>
                                        </label>
                                    </span>
                                </label>
                                <label class="label">Files should be named as document number, if file name contains
                                    extra characters please specify the text to be removed for document number (use ","
                                    for multiple values)</label>
                            </fieldset>

                            <fieldset>

                                <label class="input rgxErr">
                                    <i *ngIf="docNo.value && !docNo.valid && (docNo.touched || docNo.dirty )"
                                        class="icon-append fa fa-ban"
                                        title="{{_sharedService.error_rgx_pre}} {{_sharedService.re_DocumentNumber_Message}} {{_sharedService.error_rgx_post}}">
                                    </i>
                                    <input id="docNumber" type="text" tabindex="0" class="input-smText"
                                        [disabled]="metaDataBulk.docNumberSwitch==0"
                                        [pattern]="_sharedService.re_DocumentNumber"
                                        [ngClass]="{'readonlyinput2': metaDataBulk.docNumberSwitch==0}"
                                        [(ngModel)]="metaDataBulk.DocNo" requried name="docNo" #docNo="ngModel">
                                </label>
                            </fieldset>
                        </div>

                    </div>
                    <div class="row">

                        <div class="form-group col-sm-6" *ngIf="recordType == 'MXR'">
                            <fieldset>
                                <label class="label">
                                    <!-- {{referenceKeyLabel}}  -->
                                    {{_sharedService.getDocumentLabel(recordType, "ReferenceKey")}} <span
                                        class="text-danger">*</span>
                                </label>
                                <label class="input rgxErr">
                                    <i *ngIf="woNumber.value && !woNumber.valid && (woNumber.touched || woNumber.dirty )"
                                        class="icon-append fa fa-ban"
                                        title="{{_sharedService.error_rgx_pre}} {{_sharedService.re_WorkOrderNumber_Message}} {{_sharedService.error_rgx_post}}">
                                    </i>
                                    <input id="woNumber" requried name="woNumber" #woNumber="ngModel"
                                        [(ngModel)]="metaDataBulk.ReferenceKey" [disabled]="parentWoNumber != ''"
                                        [ngClass]="{'readonlyinput2': parentWoNumber != ''}" type="text" tabindex="6"
                                        [pattern]="_sharedService.re_WorkOrderNumber" class="input-smText">
                                </label>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <fieldset>
                                <!-- <label class="label">File input <span class="text-danger">*</span></label>-->
                                <!-- <p-dropdown appendTo="body" [options]="FileType" id="filetype" name="filetype"  [(ngModel)]="metaDataBulk.filetype" ></p-dropdown> -->
                                <div class="row">
                                    <div class="col-md-6">
                                        <p-selectButton id="filetype" name="filetype" [options]="FileTypeList"
                                            (onChange)="onChangeDocTypeBulk($event)" [(ngModel)]="metaDataBulk.FileType">
                                        </p-selectButton>
                                    </div>
                                    <div class="col-md-6">
                                        <span *ngIf="showUploadAsImageBulk" class="onoffswitch  pull-right" style="margin-top:10px">
                                            <input type="checkbox" name="isImageSwitchBulk" class="onoffswitch-checkbox"
                                                [disabled]="disableImageLoadOption" checked="checked" id="isImageSwitchBulk"
                                                [(ngModel)]="metaDataBulk.isImageSwitch" #IsImage="ngModel">
                                            <label class="onoffswitch-label" for="isImageSwitchBulk">
                                                <span class="onoffswitch-inner" data-swchon-text="YES"
                                                    data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span>
                                            </label>
                                        </span>
                                        <span *ngIf="showUploadAsImageBulk" class="pull-right"
                                            style="padding-top: 8px;color: #333;">Convert to PDF</span>
                                    </div>
                                </div>
                                <p-fileUpload *ngIf="isuploadBulkDoc" name="bulkFileUpload"
                                    (onSelect)="onSelect($event)" (onRemove)="onRemove($event)"
                                    accept={{acceptFileTypes}} multiple="multiple" customUpload="true"
                                    chooseLabel="Browse" [(ngModel)]="bulkFile" #bulkFileUpload ngDefaultControl>
                                    <!--#bulkFile="ngModel"-->
                                    <!--name=bulk[]-->
                                    <!--url="uploadUrl"-->
                                    <!--accept=".pdf"-->
                                </p-fileUpload>
                                <!-- uploadHandler="onBulkUpload($event)" -->
                                <!-- accept=".pdf,.bmp,.dcx,.pcx,.png,.jpg,.jpeg,.jp2,.jpc,.jfif,.tif,.tiff,.gif,.djvu,.djv,.jb2,.mp3,.wav,.ogg,.mp4,.webm,.zip,.rar" -->
                                <!--id="bulkFileUpload"-->
                                <!--#bulkFileUpload="ngModel"-->
                                <div *ngIf="!isuploadBulkDoc">
                                    <input id="bulkUrl" #bulkUrl
                                        style="width:77%;height:28px;margin-right:2px;margin-bottom: 2px;"
                                        name="bulkUrl" type="text" tabindex="6" class="input-smText">
                                    <button type="button" id="bulkUrlAdd" (click)="addbulkUrl()" pButton
                                        style="height:28px;margin-right:2px;margin-bottom: 2px;vertical-align: middle;"
                                        icon="fa fa-plus" class="btn btn-default" label="Add"></button>
                                    <button type="button" id="bulkUrlRemove" *ngIf="VideoUrlList.length > 0"
                                        (click)="removeVideoUrl()" pButton
                                        style="height:28px;vertical-align: middle;margin-bottom: 2px;"
                                        icon="fa fa-close" class="btn btn-danger" label="Delete"></button>
                                    <p-listbox id="videoListbox" [style]="{width: '100%'}"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedBulkUrl"
                                        [listStyle]="{'max-height':'100px'}" [options]="VideoUrlList">
                                    </p-listbox>
                                </div>
                            </fieldset>
                        </div>
                    </div>

                </div>
            </div>
        </div>



        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary" (click)="onBulkUpload($event)"
                    label="Upload Document" [disabled]=" (!bulkUploadForm.form.valid) || (recordType !== 'DOC' && recordType!=='BND' && metaDataBulk.DocType=='')||(recordType == 'MXR' && metaDataBulk.ReferenceKey.length==0)
                    || ((
                        (canGENQualityStatus && processGENQualityStatusEnabled)
                        || (canMXRQualityStatus && processMXRQualityStatusEnabled)
                        || (canLOGQualityStatus && processLOGQualityStatusEnabled)) && ( metaDataBulk.Status==null || metaDataBulk.Status==''))
                    || isSubmitted"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default"
                    (click)="btnBulkCancelClick(bulkUploadForm)" label="Cancel" [disabled]="isSubmitted"></button>
                <!--(click)="onBulkUpload($event)"-->
                <!--||isNonPdfFileExist-->
            </div>
        </ng-template>
    </p-dialog>
</form>