<p-confirmDialog id="confirmDlg" header="Confirmation" key="confirmDlg" icon="fa fa-question-circle"
    [style]="{width: '450px'}"></p-confirmDialog>
    
<article class="col-sm-3 col-md-3 col-lg-3 sortable-grid p-sortable no-padding standardpadding" style="margin-left:0px"
    id="col1">

    <div class="jarviswidget" id="wid-id-1" data-widget-togglebutton="false" data-widget-sortable="false"
        data-widget-fullscreenbutton="false" data-widget-colorbutton="false" data-widget-editbutton="false"
        data-widget-custombutton="false" data-widget-deletebutton="false" role="widget" style="display: flex;flex-direction: column;">

        <header role="heading" id="conPanelHeader" style="height: auto !important;background: linear-gradient(to bottom, #fafafa 35px, transparent 35px);">
            <span class="widget-icon"><i [className]="iconClass"></i></span>
            <h2>Containers <span *ngIf="totalCount">({{totalCount}})</span></h2>

            <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>
            <!--<div class="jarviswidget-ctrls" role="menu">
                <a class="button-icon" id="btnhideAll" href="javascript:void(0);">
                    <i class="glyphicon glyphicon-plus"></i>
                </a>
            </div>
            <div class="jarviswidget-ctrls" role="menu">
                <a class="button-icon" id="btnhideAll" href="javascript:void(0);">
                    <i class="glyphicon glyphicon-pencil"></i>
                </a>
                </div>-->
                
                    <add-container [recordType]="recordType" *ngIf="hasCurrentCategoryAccess && isAddVisible && selectedAsset && displayMode!='status'">
                    </add-container>
                    <edit-container [recordType]="recordType" (editDateShow)="showHideDateChangeDialogue($event)" *ngIf="(isEditVisibleReadonly) && selectedAsset && displayMode!='status'">
                    </edit-container>
                    <move-container [recordType]="recordType" *ngIf="hasCurrentCategoryAccess && isMoveContainerVisible && selectedAsset && displayMode!='status'">
                    </move-container>
                    <datechange-container [recordType]="recordType" *ngIf="displayDateChangeDialog" [inputData]="DateChange">
                    </datechange-container>
                        
                    
                    <div class="jarviswidget-ctrls" title="View Jobs"
                        *ngIf="isViewJobsVisible && selectedAsset && displayMode!='status'">
                        <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="viewJobs()" id="btnviewjobcontainer"
                            (click)="viewJobs()">
                            <i class="fa fa-ticket"></i>
                        </a>
                    </div>
                    <div class="jarviswidget-ctrls pdfoperations" role="menu"
                        *ngIf="selectedAsset && displayMode!='status' && chartMenuList.length > 0 ">
                        <div class="btn-group">
                            <a class="button-icon dropdown-toggle tablIndexFocus" [attr.tabindex]="0" data-toggle="dropdown"
                                aria-expanded="true" title="View Graph">
                                <i class="fa fa-bar-chart"></i>
                            </a>
                
                            <ul class="dropdown-menu pull-right js-status-update">
                                <li *ngFor="let chart of chartMenuList;">
                                    <a href="javascript:void(0);" (click)="generateContainerChart(chart.label)">
                                        <i class="fa fa-bar-chart txt-color-green"></i>&nbsp;{{chart.label}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                
                    <!-- 
                
                
                
                            <div class="jarviswidget-ctrls" title="View Graph" *ngIf="selectedAsset && displayMode!='status'">
                                <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="generateContainerChart()"
                                    id="btnviewjobcontainer" (click)="generateContainerChart()">
                                    <i class="fa fa-bar-chart"></i>
                                </a>
                            </div> -->
                    <div class="jarviswidget-ctrls pdfoperations" role="menu" *ngIf="selectedAsset && displayMode!='status'">
                        <a [style.color]="iconColorContainerSidebar " [attr.tabindex]="0" class="button-icon tablIndexFocus"
                            id="showContainerGridSideBar" (keyup.enter)="enableDisableContainerGridSideBar()"
                            (click)="enableDisableContainerGridSideBar()" [title]="containerSideBarTitleValue">
                            <i class="fa fa-bars"></i>
                        </a>
                    </div>
                
                    <div class="jarviswidget-ctrls pdfoperations" role="menu" *ngIf="selectedAsset && displayMode!='status'">
                        <div class="btn-group">
                            <a class="button-icon dropdown-toggle tablIndexFocus" [attr.tabindex]="0" data-toggle="dropdown"
                                aria-expanded="true" title="Download">
                                <i class="glyphicon glyphicon-save"></i>
                            </a>
                
                            <ul class="dropdown-menu pull-right js-status-update">
                                <li>
                                    <a href="javascript:void(0);" (click)="exportExcelGrid()"><i
                                            class="fa fa-columns txt-color-green"></i>&nbsp;Excel (Ctrl+Z)
                                        <!--(Ctrl+T)-->
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" (click)="exportGridData()">
                                        <i class="fa fa-save txt-color-green"></i>&nbsp;CSV (Ctrl+X)
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="jarviswidget-ctrls pdfoperations" role="menu" *ngIf="selectedAsset && displayMode!='status'">
                
                        <div *ngIf="displayMode!='status' && canViewFeedFromStatus" class="jarviswidget-ctrls" role="menu">
                            <a class="button-icon btnShowFeed tablIndexFocus" id="btnShowFeed" (click)="btnShowFeed()"
                                [attr.tabindex]="0" (keyup.enter)="btnShowFeed()" title="API">
                                <i class="glyphicon glyphicon-import"></i>
                            </a>
                        </div>
                
                
                    </div>
                    <div class="jarviswidget-ctrls" title="Help">
                        <app-component-articles [componentName]="componentName" *ngIf="selectedAsset && displayMode!='status'">
                        </app-component-articles>
                    </div>
            <div style="float: left;" *ngIf="isContainerFilterVisible" (click)="changeContainerFilterState()">
                <a class="badge bg-color-green" style="margin-left:10%"><i class="fa fa-remove"></i> Filter</a>

            </div>
        </header>

        <!-- widget div-->
        <div id="conPanelContent" class="widgetdiv no-padding standardpadding set-screen-width" style="flex: 1;" role="content">
            <!--style="height: 535px;"-->

            <!-- widget edit box -->
            <div class="jarviswidget-editbox">
                <!-- This area used as dropdown edit box -->
            </div>
            <!-- end widget edit box -->
            <!-- widget content -->
            <div class="widget-body set-widget-body-width">
                <div class="divAssetNav">
                    <fieldset>
                        <div id="assetdetails" class="form-group">
                            <div id="s1" class="nopaddingnmargin">

                                <div class="jqgridboxcontainer" *ngIf="gridData">
                                    <ag-grid-angular id="containerGrid" #agGridBrowse [gridOptions]="gridOptions"
                                        [columnDefs]="columnDefs" [excelStyles]="excelStyles" [rowData]="gridData"
                                        (componentStateChanged)="selectFirstRow($event)" [animateRows]="true"
                                        [sideBar]="sideBar" (rowClicked)='updateSelectedContainer($event)'
                                        (rowDoubleClicked)="containerRowDoubleClicked($event)"
                                        [getRowId]="getRowNodeId"
                                        [navigateToNextCell]="navigateToNextCell" sortable="true" filter=true 
                                        [enableRangeSelection]="true"
                                        [enableCharts]="true"                                      
                                        style="width: 100% !important;" class="ag-theme-balham set-grid-width">
                                    </ag-grid-angular>

                                    <div style="display: none;">
                                        <ag-grid-angular id="chartContainerGrid" #chartAgGridBrowse [gridOptions]="chartGridOptions"
                                        [columnDefs]="chartColumnDefs" [excelStyles]="excelStyles" [rowData]="chartGridData"
                                        [popupParent]="popupParent"                                  
                                        class="ag-theme-balham set-grid-width">
                                    </ag-grid-angular>
                                    </div>


                                </div>

                                <!--<div id="dvNoRecordContainer" class="alert alert-block alert-warning" *ngIf="!gridData">
                                    <h4 class="alert-heading">Warning!</h4>
                                    0 result found!
                                </div>-->
                                <!--<div id="jstreeGeneral" style="height: 325px;"></div>-->
                                <div id="divNoAssetSel" class="alert alert-block alert-warning" *ngIf="!gridData">

                                    <h4 class="alert-heading">Warning!</h4>
                                    No asset selected!
                                </div>
                            </div>

                        </div>
                    </fieldset>
                </div>

            </div>
            <!-- end widget content -->
        </div>
        <!-- end widget div -->
    </div>
    <!-- end widget -->
</article>
<!--<p-dialog id="recordConfigDisplay" [(visible)]="displayConfigdialogue" showEffect="fade" [modal]="true" [width]="1385" showHeader="true" [resizable]="false" [minHeight]="620"(onShow)="adjustConfigDlgHeight($event)" [closeOnEscape]="false">
    <ng-template pTemplate="header" >
        Container Selection
    </ng-template>

            <record-configuration *ngIf="displayConfigdialogue" [feedTypeInput]="feedTypeInput"></record-configuration>

</p-dialog>-->
<p-dialog contentStyleClass="no-overflow-y" id="jobsDisplay" *ngIf="displayJobsdialogue" [(visible)]="displayJobsdialogue" showEffect="fade"
    [modal]="true" [style]="{width: '96.5vw'}" showHeader="true" [resizable]="false" [closeOnEscape]="false">
    <ng-template pTemplate="header" >
        Container Jobs
    </ng-template>

    <list-job *ngIf="displayJobsdialogue" [selectedContainerRow]="selectedContainerRow"></list-job>

</p-dialog>
<p-dialog contentStyleClass="no-overflow-y" id="statusListFeed" *ngIf="displayFeedDialog && canViewFeedFromStatus"
    [(visible)]="displayFeedDialog" showEffect="false" [modal]="true" [style]="{width: '100vw'}"
    [resizable]="false">
    <ng-template pTemplate="header" >
        Feeds
    </ng-template>
    <list-datafeed *ngIf="displayFeedDialog && displayMode!='status' && canViewFeedFromStatus" id="feedsPopup"
        [feedType]="feedType" [viewId]="ViewSettingId" [targetType]="targetType" [viewDataType]=""></list-datafeed>
</p-dialog>



