<div style="height: 92vh;overflow-y: scroll;overflow-x: hidden;">
    <div class="row addaircraftcls">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" id="col1">
            <h1 class="page-title txt-color-blueDark">

                <!-- PAGE HEADER -->
                <i class="fa fa-gear fa"></i>
                <a [routerLink]="[statusConfigPath,viewId,tab]">{{dialogTitle}}</a>
                <span>
                    &gt;
                    <span id="page-mode">{{btnlabel}}</span>
                </span>
            </h1>
        </div>
        <article class="col-sm-12 col-md-12 col-lg-12" id="col2">
            <!-- Widget ID (each widget will need unique ID)-->

            
            <form #addForm="ngForm" (ngSubmit)="btnSubmitClick(addForm)">
                <div class="jarviswidget" id="wid-id-0" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false">

                    <header>
                        <span class="widget-icon"><i class="fa fa-pencil-square-o"></i></span>
                        <h2>{{btnlabel}} Configuration <span id="page-mode">({{viewName}})</span></h2>
                    </header>

                    <!-- widget div-->
                    <div class="widgetdiv">
                        <!-- widget content -->
                        <div class="widget-body">
                            <!-- widget grid -->
                            <section id="widget-grid0" class="">
                                <div class="navbar-form form-group">
                                    <!--<ul id="myTab1" class="nav nav-tabs bordered">
                                        <li class="active">
                                            <a href="#s1" data-toggle="tab" aria-expanded="true">Airframe</a>
                                        </li>
                                    </ul>-->
                                    <div id="myTabContent1" class="tab-content">
                                        <div class="tab-pane fade active in" id="s1">
                                            <div class="smart-form">
                                                <div class="row">
                                                    <!--<div class="form-group col-sm-10">

                                                        <fieldset>
                                                            <label class="label">View:</label>
                                                            <label class="input">
                                                                <span id="page-mode">{{viewName}}</span>
                                                            </label>
                                                        </fieldset>
                                                    </div>-->


                                                    <div class="form-group col-sm-4">

                                                        <fieldset>
                                                            <label class="label">Search Name:</label>
                                                            <label class="input">
                                                                <input id="searchname" type="text" name="searchname" required class="input-sm" [(ngModel)]="builderSearchConfig.SearchName" />
                                                            </label>
                                                        </fieldset>
                                                    </div>


                                                    <div class="form-group col-sm-4">
                                                        <fieldset>
                                                            <label class="label">Order:</label>
                                                            <label class="input">
                                                                <input id="order" type="text" name="order" required class="input-sm" [(ngModel)]="builderSearchConfig.OrderNo" />
                                                            </label>
                                                        </fieldset>
                                                    </div>

                                                    <div class="form-group col-sm-4">
                                                        <fieldset>
                                                            <label class="label">Status:</label>
                                                            <label class="checkbox">
                                                                <span class="onoffswitch">
                                                                    <input type="checkbox" name="onoffswitch" [(ngModel)]="builderSearchConfig.Status" class="onoffswitch-checkbox" checked="checked" id="isMactive">
                                                                    <label class="onoffswitch-label" for="isMactive"><span class="onoffswitch-inner" data-swchon-text="YES" data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span></label>
                                                                </span>
                                                            </label>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div class="row">

                                                    <div class="form-group col-sm-12">
                                                        <div class="panel panel-default">
                                                            <div class="panel-heading">
                                                                <h3 class="panel-title panalheading-padding">
                                                                    Keyword Search:
                                                                    <a class="button-icon hand-cursor pull-right" style="padding-right: 5px;" id="btnAddkeywordsearch" (click)="addSearchKeyword()">
                                                                        <i class="glyphicon glyphicon-plus"></i>
                                                                    </a>
                                                                </h3>
                                                            </div>


                                                            <div class="panel-body" style="height:205px;overflow:scroll">
                                                                <div class="col-sm-12 picklist-padding fix-picklist-width">
                                                                    <div class="table-responsive" style="overflow-y: hidden;">
                                                                        <table class="table table-hover">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Condition</th>
                                                                                    <th>Option</th>
                                                                                    <th>Prefix Value</th>
                                                                                    <th>Field Name</th>
                                                                                    <th>Pattern</th>
                                                                                    <th>Postfix Value</th>
                                                                                    <th>&nbsp;</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody id="autosearchtablebody">
                                                                                <tr *ngFor="let azureSearchKeyword of azureSearchKeywords; let j = index  trackBy:trackByIndex">
                                                                                    <td>
                                                                                        <ng-template [ngIf]="j!=0">
                                                                                            <select class="form-control" id="condition{{j}}" name="condition{{j}}" [(ngModel)]="azureSearchKeyword.Condition" style="width:80px;" placeholder="Select Condition">
                                                                                                <option *ngFor="let c of keywordConditions" [value]="c.value">{{c.label}}</option>
                                                                                            </select>
                                                                                        </ng-template>
                                                                                    </td>
                                                                                    <td>
                                                                                        <select class="form-control searchtype nopaddingnmargin valid" tabindex="1" id="searchtype{{j}}" aria-invalid="false" name="keywordpattern{{j}}" [ngStyle]="{'width':'100%'}" placeholder="Select Pattern" [(ngModel)]="azureSearchKeyword.Pattern">
                                                                                            <option *ngFor="let x of searchQueryPatterns" [value]="x.id">{{x.searchType}}</option>
                                                                                        </select>
                                                                                    </td>

                                                                                    <td><input id="PrefixValue{{j}}" type="text" name="PrefixValue{{j}}" [(ngModel)]="azureSearchKeyword.PrefixValue" class="input-sm" /></td>
                                                                                    <td>
                                                                                        <p-dropdown appendTo="body" appendTo="body" class="form-control" id="TokenValue{{j}}" name="TokenValue{{j}}" [options]="viewFields" [editable]="false" [(ngModel)]="azureSearchKeyword.TokenValue" placeholder="Select Token" [filter]="true"></p-dropdown>
                                                                                    </td>

                                                                                    <td>
                                                                                        <input id="TokenPattern{{j}}" type="text" name="TokenPattern{{j}}" [(ngModel)]="azureSearchKeyword.TokenPattern" class="input-sm" />
                                                                                    </td>

                                                                                    <td><input id="cndValue{{j}}" type="text" name="cndValue{{j}}" [(ngModel)]="azureSearchKeyword.PostFixValue" class="input-sm" /></td>
                                                                                    <td>
                                                                                        <a class="button-icon hand-cursor pull-right" style="padding-right: 5px;" name="btnCndDel{{j}}" id="btnCndDel{{j}}" (click)="deleteInstance(azureSearchKeyword)">
                                                                                            <i class="glyphicon glyphicon-remove"></i>
                                                                                        </a>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="row" style="padding-top:10px;">
                                                    <div class="form-group col-sm-12">
                                                        <div class="panel panel-default">
                                                            <div class="panel-heading">
                                                                <h3 class="panel-title panalheading-padding">
                                                                    Scope
                                                                    <!--<a class="button-icon hand-cursor pull-right" style="padding-right: 5px;" id="btnadd">
                                                                        <i class="glyphicon glyphicon-plus"></i>
                                                                    </a>-->
                                                                </h3>
                                                            </div>
                                                            <div class="panel-body">
                                                                <div id="filter-form" class="smart-form" style="padding-top:40px;">

                                                                    <div class='row' *ngIf="!azureSearchFilter.AssetASN">
                                                                        <div class='form-group sbfrm-fieldgroup col-sm-12'>
                                                                            <fieldset>

                                                                                <div class="col-sm-2">
                                                                                    <label class="label">Search Database:</label>
                                                                                </div>
                                                                                <div class="input-group" style="width: 437px;">
                                                                                    <p-dropdown appendTo="body" id="SearchDatabaseDD" name="SearchDatabaseDD" [options]="searchDatabaseList"  placeholder="Select Search Database" [(ngModel)]="azureSearchFilter.SearchDatabaseID" #addSearchDatabase="ngModel"  [style]="{'height': '31px','margin-left':'6px !important; width: 410px;'}">
                                                                                    </p-dropdown> 
                                                                             
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>


                                                                    </div>


                                                                    <div class='row'>
                                                                        <div class='form-group sbfrm-fieldgroup col-sm-12'>
                                                                            <fieldset>

                                                                                <div class="col-sm-2">
                                                                                    <label class="label">Asset:</label>
                                                                                </div>
                                                                                <div class="input-group customdrp-width">
                                                                                    <div class="input-group-btn" style="width:10%;">
                                                                                        <button type="button" class="btn btn-default btn-custom" tabindex="-1">{{azureSearchFilter.AssetControlMode}}</button>
                                                                                        <button type="button" class="btn btn-default dropdown-toggle drp-custom" data-toggle="dropdown" tabindex="-1" aria-expanded="false">
                                                                                            <span class="caret"></span>
                                                                                        </button>
                                                                                        <ul class="dropdown-menu" role="menu">
                                                                                            <li><a href="javascript:void(0);" (click)="Toggle_Fields('Asset','Fixed Value')">Fixed Value</a></li>
                                                                                            <li><a href="javascript:void(0);" (click)="Toggle_Fields('Asset','Column Value')">Column Value</a></li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <ng-template [ngIf]="azureSearchFilter.AssetControlMode == 'Fixed Value'">
                                                                                        <div id="frmagdrp">
                                                                                            <p-dropdown appendTo="body" [options]="assets" [(ngModel)]="azureSearchFilter.AssetASN" [editable]="false"  placeholder="Select Asset" name="ASN" [filter]="true" #addautosearchasset [resetFilterOnHide]="true"></p-dropdown>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                    <ng-template [ngIf]="azureSearchFilter.AssetControlMode=='Column Value'">
                                                                                        <div id="frmagdrp">
                                                                                            <p-dropdown appendTo="body" [options]="viewFields" [editable]="false" [(ngModel)]="azureSearchFilter.AssetASN"  placeholder="Select Column" name="customASN" [filter]="true" #addautosearchcolumn [resetFilterOnHide]="true"></p-dropdown>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>


                                                                    </div>
                                                                    <div class='row'>
                                                                        <div class='col-sm-12'>
                                                                            <div class='row'>
                                                                                <div class='form-group sbfrm-fieldgroup col-sm-12'>
                                                                                    <fieldset>
                                                                                        <div class="col-sm-2">
                                                                                            <label class='label'>Document Date (From)</label>
                                                                                        </div>
                                                                                        <div class="input-group customdrp-width">
                                                                                            <div class="input-group-btn" style="width:10%;">
                                                                                                <button type="button" class="btn btn-default btn-custom" tabindex="-1">{{azureSearchFilter.FromDateMode}}</button>
                                                                                                <button type="button" class="btn btn-default dropdown-toggle drp-custom" data-toggle="dropdown" tabindex="-1" aria-expanded="false">
                                                                                                    <span class="caret"></span>
                                                                                                </button>
                                                                                                <ul class="dropdown-menu" role="menu">
                                                                                                    <li><a (click)="Toggle_Fields('FromDate','Fixed Value')">Fixed Value</a></li>
                                                                                                    <li><a (click)="Toggle_Fields('FromDate','Column Value')">Column Value</a></li>
                                                                                                </ul>
                                                                                            </div>
                                                                                            <ng-template [ngIf]="azureSearchFilter.FromDateMode=='Fixed Value'">
                                                                                                <div class="input" style="width:300px;">
                                                                                                    <label class='input set-date-control-width'>
                                                                                                        <i class='icon-append fa fa-calendar'></i>
                                                                                                        <p-calendar appendTo="body" class='datepicker hasDatepicker' [(ngModel)]="azureSearchFilter.FromDate" [dateFormat]="dateFormatCalender" yearNavigator="true" yearRange="1950:2030"
                                                                                                                    name="fromDate" monthNavigator="true">
                                                                                                        </p-calendar>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </ng-template>
                                                                                            <ng-template [ngIf]="azureSearchFilter.FromDateMode=='Column Value'">
                                                                                                <div id="frmagdrp">
                                                                                                    <p-dropdown appendTo="body" [options]="viewFields" [editable]="false" [(ngModel)]="azureSearchFilter.FromDate"  placeholder="Select Column" name="customfromDate" [filter]="true" #addautocustomfromDate [resetFilterOnHide]="true"></p-dropdown>
                                                                                                </div>
                                                                                            </ng-template>

                                                                                        </div>


                                                                                        <!--<p-dropdown appendTo="body" [options]="documentTypes" ></p-dropdown>-->

                                                                                    </fieldset>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class='col-sm-12'>
                                                                            <div class='row'>
                                                                                <div class='form-group sbfrm-fieldgroup col-sm-12'>
                                                                                    <fieldset>
                                                                                        <div class="col-sm-2">
                                                                                            <label class='label'>Document Date (To)</label>
                                                                                        </div>
                                                                                        <div class="input-group customdrp-width">
                                                                                            <div class="input-group-btn" style="width:10%;">
                                                                                                <button type="button" class="btn btn-default btn-custom" tabindex="-1">{{azureSearchFilter.ToDateMode}}</button>
                                                                                                <button type="button" class="btn btn-default dropdown-toggle drp-custom" data-toggle="dropdown" tabindex="-1" aria-expanded="false">
                                                                                                    <span class="caret"></span>
                                                                                                </button>
                                                                                                <ul class="dropdown-menu" role="menu">
                                                                                                    <li><a (click)="Toggle_Fields('ToDate','Fixed Value')">Fixed Value</a></li>
                                                                                                    <li><a (click)="Toggle_Fields('ToDate','Column Value')">Column Value</a></li>
                                                                                                </ul>
                                                                                            </div>
                                                                                            <ng-template [ngIf]="azureSearchFilter.ToDateMode== 'Fixed Value'">
                                                                                                <div class="input" style="width:300px;">
                                                                                                    <label class='input set-date-control-width'>
                                                                                                        <i class='icon-append fa fa-calendar'></i>
                                                                                                        <p-calendar appendTo="body" class='datepicker hasDatepicker' [(ngModel)]="azureSearchFilter.ToDate" [dateFormat]="dateFormatCalender" yearNavigator="true" yearRange="1950:2030" name="toDate"
                                                                                                                    monthNavigator="true">
                                                                                                        </p-calendar>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </ng-template>
                                                                                            <ng-template [ngIf]="azureSearchFilter.ToDateMode=='Column Value'">
                                                                                                <div id="frmagdrp">
                                                                                                    <p-dropdown appendTo="body" [options]="viewFields" [editable]="false" [(ngModel)]="azureSearchFilter.ToDate"  placeholder="Select Column" name="customtoDate" [filter]="true" #addautocustomtoDate [resetFilterOnHide]="true"></p-dropdown>
                                                                                                </div>
                                                                                            </ng-template>

                                                                                        </div>

                                                                                    </fieldset>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class='row'>
                                                                        <div class='form-group sbfrm-fieldgroup col-sm-12'>
                                                                            <fieldset>
                                                                                <div class="col-sm-2">
                                                                                    <label class="label">Document Type</label>
                                                                                </div>
                                                                                <div class="input-group customdrp-width">
                                                                                    <div class="input-group-btn" style="width:10%;">
                                                                                        <button type="button" class="btn btn-default btn-custom" tabindex="-1">{{azureSearchFilter.DocumentTypeMode}}</button>
                                                                                        <button type="button" class="btn btn-default dropdown-toggle drp-custom" data-toggle="dropdown" tabindex="-1" aria-expanded="false">
                                                                                            <span class="caret"></span>
                                                                                        </button>
                                                                                        <ul class="dropdown-menu" role="menu">
                                                                                            <li><a (click)="Toggle_Fields('DocType','Fixed Value')">Fixed Value</a></li>
                                                                                            <li><a (click)="Toggle_Fields('DocType','Column Value')">Column Value</a></li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <ng-template [ngIf]="azureSearchFilter.DocumentTypeMode == 'Fixed Value'">
                                                                                        <div id="drpDocType" style="width: 290px;">
                                                                                            <p-multiSelect [options]="documentTypes" [(ngModel)]="azureSearchFilter.DocumentTypes" name="doctype" [maxSelectedLabels]="0" selectedItemsLabel="{0} items selected"></p-multiSelect>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                    <ng-template [ngIf]="azureSearchFilter.DocumentTypeMode== 'Column Value'">
                                                                                        <div id="drpDocType"  style="width: 290px;">
                                                                                            <p-multiSelect [options]="viewFields" [(ngModel)]="azureSearchFilter.DocumentTypes" name="customdoctype" placeholder="Select Column" [maxSelectedLabels]="0" selectedItemsLabel="{0} items selected"></p-multiSelect>
                                                                                        </div>
                                                                                        <!--<div id="frmagdrp">
                                                                                            <p-dropdown appendTo="body" [options]="viewFields" [editable]="false" [(ngModel)]="azureSearchFilter.DocumentType"  placeholder="Select Column" name="customdoctype"></p-dropdown>
                                                                                        </div>-->
                                                                                    </ng-template>

                                                                                </div>


                                                                                <!--<p-dropdown appendTo="body" [options]="documentTypes" ></p-dropdown>-->

                                                                            </fieldset>
                                                                        </div>


                                                                    </div>
                                                                    <div class='row'>
                                                                        <div class='form-group sbfrm-fieldgroup col-sm-12'>
                                                                            <fieldset>
                                                                                <div class="col-sm-2">
                                                                                    <label class="label">Document Number</label>
                                                                                </div>
                                                                                <div class="input-group customdrp-width">
                                                                                    <div class="input-group-btn" style="width:10%;">
                                                                                        <button type="button" class="btn btn-default btn-custom" tabindex="-1">{{azureSearchFilter.DocumentNoMode}}</button>
                                                                                        <button type="button" class="btn btn-default dropdown-toggle drp-custom" data-toggle="dropdown" tabindex="-1" aria-expanded="false">
                                                                                            <span class="caret"></span>
                                                                                        </button>
                                                                                        <ul class="dropdown-menu" role="menu">
                                                                                            <li><a (click)="Toggle_Fields('DocNumber','Fixed Value')">Fixed Value</a></li>
                                                                                            <li><a (click)="Toggle_Fields('DocNumber','Column Value')">Column Value</a></li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <ng-template [ngIf]="azureSearchFilter.DocumentNoMode == 'Fixed Value'">
                                                                                        <div class="frm-customtext rgxErr">
                                                                                            <i *ngIf="docNo.value && !docNo.valid && (docNo.touched || docNo.dirty )" class="icon-append fa fa-ban"
                                                                                            style="right: -48px !important;"
                                                                                                title="{{_sharedService.error_rgx_pre}} {{_sharedService.re_DocumentNumber_Message}} {{_sharedService.error_rgx_post}}">
                                                                                            </i>
                                                                                            <input id="fltdocno" name="docNO" type="text" tabindex="7" 
                                                                                            [pattern]="_sharedService.re_DocumentNumber"
                                                                                            #docNo="ngModel"
                                                                                            class="input-sm" data-property="DocNumberOWSTEXT" [(ngModel)]="azureSearchFilter.DocNo" />
                                                                                        </div>
                                                                                    </ng-template>
                                                                                    <ng-template [ngIf]="azureSearchFilter.DocumentNoMode == 'Column Value'">
                                                                                        <div id="frmagdrp">
                                                                                            <p-dropdown appendTo="body" [options]="viewFields" [editable]="false" [(ngModel)]="azureSearchFilter.DocNo"  placeholder="Select Column" name="customdocNO" [filter]="true" #addautocustomdocNO [resetFilterOnHide]="true"></p-dropdown>
                                                                                        </div>
                                                                                    </ng-template>

                                                                                </div>


                                                                            </fieldset>
                                                                        </div>


                                                                    </div>
                                                                    <div class='row'>
                                                                        <div class='form-group sbfrm-fieldgroup col-sm-12'>
                                                                            <fieldset>
                                                                                <div class="col-sm-2">
                                                                                    <label class="label">Work Order Number</label>
                                                                                </div>
                                                                                <div class="input-group customdrp-width">
                                                                                    <div class="input-group-btn" style="width:10%;">
                                                                                        <button type="button" class="btn btn-default btn-custom" tabindex="-1">{{azureSearchFilter.WoNoMode}}</button>
                                                                                        <button type="button" class="btn btn-default dropdown-toggle drp-custom" data-toggle="dropdown" tabindex="-1" aria-expanded="false">
                                                                                            <span class="caret"></span>
                                                                                        </button>
                                                                                        <ul class="dropdown-menu" role="menu">
                                                                                            <li><a (click)="Toggle_Fields('WONumber','Fixed Value')">Fixed Value</a></li>
                                                                                            <li><a (click)="Toggle_Fields('WONumber','Column Value')">Column Value</a></li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <ng-template [ngIf]="azureSearchFilter.WoNoMode=='Fixed Value'">
                                                                                        <div class="frm-customtext rgxErr">
                                                                                            <i *ngIf="woNumber.value && !woNumber.valid && (woNumber.touched || woNumber.dirty )" class="icon-append fa fa-ban"
                                                                                            style="right: -48px !important;"    
                                                                                            title="{{_sharedService.error_rgx_pre}} {{_sharedService.re_WorkOrderNumber_Message}} {{_sharedService.error_rgx_post}}">
                                                                                            </i>
                                                                                            <input id="fltwono" name="wono" type="text" tabindex="8" 
                                                                                            [pattern]="_sharedService.re_WorkOrderNumber" #woNumber="ngModel"
                                                                                            class="input-sm" data-property="WONumberOWSTEXT" [(ngModel)]="azureSearchFilter.WONo" />
                                                                                        </div>
                                                                                    </ng-template>
                                                                                    <ng-template [ngIf]="azureSearchFilter.WoNoMode=='Column Value'">
                                                                                        <div id="frmagdrp">
                                                                                            <p-dropdown appendTo="body" [options]="viewFields" [editable]="false" [(ngModel)]="azureSearchFilter.WONo"  placeholder="Select Column" name="customwono" [filter]="true" #addautocustomwono [resetFilterOnHide]="true"></p-dropdown>
                                                                                        </div>
                                                                                    </ng-template>

                                                                                </div>


                                                                                <!--<p-dropdown appendTo="body" [options]="documentTypes" ></p-dropdown>-->

                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <div class='row'>
                                                                        <div class='form-group sbfrm-fieldgroup col-sm-12'>
                                                                            <fieldset>
                                                                                <div class="col-sm-2">
                                                                                    <label class="label">Tags</label>
                                                                                </div>
                                                                                <div class="input-group customdrp-width">
                                                                                    <div class="input-group-btn" style="width:10%;">
                                                                                        <button type="button" class="btn btn-default btn-custom" tabindex="-1">{{azureSearchFilter.TagsMode}}</button>
                                                                                        <button type="button" class="btn btn-default dropdown-toggle drp-custom" data-toggle="dropdown" tabindex="-1" aria-expanded="false">
                                                                                            <span class="caret"></span>
                                                                                        </button>
                                                                                        <ul class="dropdown-menu" role="menu">
                                                                                            <li><a (click)="Toggle_Fields('Tag','Fixed Value')">Fixed Value</a></li>
                                                                                            <li><a (click)="Toggle_Fields('Tag','Column Value')">Column Value</a></li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <ng-template [ngIf]="azureSearchFilter.TagsMode=='Fixed Value'">
                                                                                        <div id="drpDocType" style="width: 290px;">
                                                                                            <p-multiSelect [options]="tags" [(ngModel)]="azureSearchFilter.Tags" name="tags" [maxSelectedLabels]="0" selectedItemsLabel="{0} items selected"></p-multiSelect>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                    <ng-template [ngIf]="azureSearchFilter.TagsMode=='Column Value'">
                                                                                        <div id="drpDocType" style="width: 290px;">
                                                                                            <p-multiSelect [options]="viewFields" [(ngModel)]="azureSearchFilter.Tags" name="customtags" placeholder="Select Column" [maxSelectedLabels]="0" selectedItemsLabel="{0} items selected"></p-multiSelect>
                                                                                        </div>
                                                                                        <!--<div id="frmagdrp">
                                                                                            <p-dropdown appendTo="body" [options]="viewFields" [editable]="false" [(ngModel)]="azureSearchFilter.Tags"  placeholder="Select Column" name="customtags"></p-dropdown>
                                                                                        </div>-->
                                                                                    </ng-template>
                                                                                </div>

                                                                            </fieldset>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row" style="padding-top:10px;">
                                                    <div class="form-group col-sm-12">
                                                        <div class="panel panel-default">
                                                            <div class="panel-heading">
                                                                <h3 class="panel-title panalheading-padding">
                                                                    Batch Execution Parameters
                                                                </h3>
                                                            </div>
                                                            <div class="panel-body">
                                                                <div class="form-group col-sm-4" id="drpDocTypes">

                                                                    <div class='row'>
                                                                        <div class='form-group sbfrm-fieldgroup col-sm-12'>
                                                                            <fieldset>
                                                                                <label class="label">Selection:</label>
                                                                                <div class="input-group col-sm-12 sb-selectiondrp-customwidth">
                                                                                    <!--<label class="input">
                                                                                        <p-dropdown appendTo="body" [options]="searchResultSetRows" [(ngModel)]="builderSearchConfig.SelectRows"  name="rows" required></p-dropdown>
                                                                                    </label>-->
                                                                                    <div id="frmagdrp sb-selectiondrp-customwidth">
                                                                                        <p-dropdown appendTo="body" [options]="searchResultSetRows" [(ngModel)]="builderSearchConfig.SelectRows"  name="rows" required></p-dropdown>
                                                                                    </div>
                                                                                </div>

                                                                            </fieldset>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div class="form-group col-sm-4" id="drpDocTypes">
                                                                    <div class='row'>
                                                                        <div class='form-group sbfrm-fieldgroup col-sm-12'>
                                                                            <fieldset>
                                                                                <label class="label">Execute:</label>
                                                                                <div class="input-group col-sm-12 sb-selectiondrp-customwidth">
                                                                                    <!--<label class="input">
                                                                                        <p-dropdown appendTo="body" [options]="viewExecutionPeriorities" [(ngModel)]="builderSearchConfig.Execution" [editable]="false"  name="queryMode"></p-dropdown>
                                                                                    </label>-->

                                                                                    <div id="frmagdrp sb-selectiondrp-customwidth">
                                                                                        <p-dropdown appendTo="body" [options]="viewExecutionPeriorities" [(ngModel)]="builderSearchConfig.Execution" [editable]="false"  name="queryMode"></p-dropdown>
                                                                                    </div>

                                                                                </div>


                                                                                <!--<p-dropdown appendTo="body" [options]="documentTypes" ></p-dropdown>-->

                                                                            </fieldset>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end row -->
                                </div>
                            </section>
                            <!-- end widget grid -->
                            <div class="widget-footer">


                                <button type="submit" class="btn btn-primary" [disabled]="!addForm.form.valid || isSubmitted || (!canAddEditBuilder)">
                                    Save
                                </button>
                                <a class="btn btn-default" [routerLink]="[statusConfigPath,viewId,tab]">Cancel</a>
                            </div>
                        </div>
                        <!-- end widget content -->
                    </div>
                    <!-- end widget div -->

                </div>
                <!-- end widget -->
            </form>
        </article>
        <!-- END COL -->

    </div>

</div>