<div id="homeview" role="main">
    <!-- MAIN CONTENT -->
    <div id="mainDashBoard" style="height: 94vh;overflow-y:scroll;background-color: #EDEDED;">
        <!-- Dashboard -->
        <section id="widget-grid" style="padding-top: 0.5em;">
            <!-- row -->
            <div class="row">
                <article class="col-sm-12" *ngIf="canViewDashboard">                    
                    <!-- new widget -->
                    <div class="jarviswidget" style="margin-bottom: 5px;" id="wid-id-0" data-widget-togglebutton="false"
                        data-widget-editbutton="false" data-widget-fullscreenbutton="false"
                        data-widget-colorbutton="false" data-widget-deletebutton="false">
                        <header>
                            <span class="widget-icon">
                                <i class="fa fa-lg fa-fw fa fa-gauge-high"></i>
                            </span>
                            <h2>Live Feeds</h2>
                            <div class="widget-toolbar hidden-mobile" style="cursor:auto;border: none !important;">
                                <span (click)="refreshDashboardStatusListingGrid()" class="onoffswitch-title">Last
                                    updated {{ getDateFormatTime(containerLastUpdated)
                                    }}<i class="fa fa-refresh" [ngClass]="{ 'fa-spin': updatingStatus }"
                                        style="margin-left: 5px;cursor:pointer;margin-top: 0px !important;margin-right:0.3em !important;"></i></span>
                            </div>
                        </header>
                        <!-- widget div-->
                        <div class="no-padding">
                            <!-- end widget edit box -->
                            <div class="widget-body">
                                <!-- content -->
                                <div id="myTabContent" class="tab-content">
                                    <div class="tab-pane fade active in" id="s2"
                                        style="padding-left: 0em;padding-top: 1em;">
                                        <div class="row">
                                            <article class="col-sm-12 col-md-8 col-lg-8"
                                                style="padding-bottom: 0em; padding-right: 0.3em;">
                                                <!-- new widget -->
                                                <div class="jarviswidget " id="wid-id-2"
                                                    data-widget-fullscreenbutton="false"
                                                    data-widget-deletebutton="false" data-widget-togglebutton="false"
                                                    data-widget-colorbutton="false" data-widget-editbutton="false"
                                                    style="margin-bottom: 5px !important;">
                                                    <header>
                                                        <span class="widget-icon">
                                                            <i class="fa fa-bar-chart txt-color-darken"></i>
                                                        </span>
                                                        <h2>
                                                            {{
                                                            selectedAsset == null
                                                            ? "Fleet"
                                                            : selectedAsset.label
                                                            }}
                                                            Asset View Status
                                                        </h2>
                                                    </header>
                                                    <!-- widget div-->

                                                    <div style="height: 55vh;" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 show-stats">
                                                        <!-- <p-chart id="barChart" type="bar" [data]="barData"
                                                            height="calc(40vh)" [options]="barOptions"></p-chart> -->

                                                            <!-- <ag-charts-angular
                                                            style="position: absolute; top: 0; right: 0; bottom: 0; left: 0;"
                                                            [options]="options">
                                                        </ag-charts-angular> -->
                                                            <ag-charts-angular *ngIf="barChart" id="barChart" [options]="barOptions">
                                                            </ag-charts-angular>


                                                    </div>

                                                    <!-- end widget div -->
                                                </div>
                                                <!-- end widget -->
                                            </article>
                                            <article class="col-sm-12 col-md-4 col-lg-4" style="padding-left: 0.2em">
                                                <!-- new widget -->
                                                <div class="jarviswidget" id="wid-id-2"
                                                    data-widget-fullscreenbutton="false"
                                                    data-widget-deletebutton="false" data-widget-togglebutton="false"
                                                    data-widget-colorbutton="false" data-widget-editbutton="false"
                                                    style="margin-bottom: 5px !important;">
                                                    <header>
                                                        <span class="widget-icon">
                                                            <i class="fa fa-bar-chart txt-color-darken"></i>
                                                        </span>
                                                        <h2>
                                                            {{
                                                            selectedAsset == null
                                                            ? "Fleet"
                                                            : selectedAsset.label
                                                            }}
                                                            Compliance Status
                                                        </h2>
                                                    </header>
                                                    <div style="height: 55vh;">
                                                    <!-- widget div-->
                                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 show-stats" id="DFPStatusDoughnutChartId">
                                                        <!-- <p-chart id="doughnutChart" type="doughnut"
                                                            [data]="doughnutData" [options]="doughnutOptions"
                                                            height="calc(40vh)"></p-chart> -->


                                                            <ag-charts-angular *ngIf="showDounetChart" id="doughnutChart" [options]="doughnutOptions">
                                                            </ag-charts-angular>
                                                    </div>
                                                    <!-- end widget div -->
                                                </div>
                                            </div>
                                                <!-- end widget -->
                                            </article>
                                        </div>

                                        <div class="row">
                                            <article class="col-sm-12 col-md-12 col-lg-12">
                                                <div class="jarviswidget" id="wid-id-2"
                                                    data-widget-fullscreenbutton="false"
                                                    data-widget-deletebutton="false" data-widget-togglebutton="false"
                                                    data-widget-colorbutton="false" data-widget-editbutton="false"
                                                    style="margin-bottom: 5px !important;">
                                                    <header>
                                                        <span class="widget-icon">
                                                            <i class="fa fa-bar-chart txt-color-darken"></i>
                                                        </span>
                                                        <h2>
                                                            {{
                                                            selectedAsset == null
                                                            ? "Fleet"
                                                            : selectedAsset.label
                                                            }}
                                                            Compliance Status
                                                        </h2>

                                                        <div class="jarviswidget-ctrls pdfoperations" style="margin-right: 3px;" role="menu">
                                                            <div class="btn-group">
                                                                <p-selectButton id="statusDashboardGroupButton"
                                                                    *ngIf="!(_sharedService.selectedAsset && _sharedService.selectedAsset.length > 0)"
                                                                    [options]="statusToggleTypes"
                                                                    [(ngModel)]="statusToggle"
                                                                    #assetRptintervalsetting="ngModel"
                                                                    (onChange)="statusToggleChange()"></p-selectButton>
                                                            </div>
                                                        </div>
                                                        <div class="jarviswidget-ctrls widget-toolbar hidden-mobile"
                                                        style="border-left: none;cursor:auto">
                                                        <a class="button-icon" (click)="onStatusGridExport()" style="padding-left: 10px;"
                                                        [attr.tabindex]="0" data-toggle="dropdown"
                                                        aria-expanded="true" title="Download">
                                                        <i class="glyphicon glyphicon-save"></i>
                                                    </a>
                                                    </div>
                                                </header>
                                                    <!-- widget div-->
                                                    <div class="widget-body">
                                                        <ag-grid-angular id="agGridDashboardStatus"
                                                            #agGridDashboardStatus [gridOptions]="statusgridOptions"
                                                            [detailRowHeight]="detailRowHeight"
                                                            [excelStyles]="excelStyles"
                                                            (rowClicked)="onRowClicked($event)"
                                                            [columnDefs]="statuscolumnDefs" [rowData]="statusgridData"
                                                            (rowGroupOpened)="populateStatusDetailGrid($event)" sortable="true"
                                                            filter="true" style="height:calc(40vh);" class="ag-theme-balham">
                                                        </ag-grid-angular>
                                                    </div>
                                                    <!-- end widget div -->
                                                </div>
                                            </article>
                                        </div>
                                        <!--</div>-->
                                    </div>
                                    <!-- end s2 tab pane -->
                                </div>
                                <!-- end content -->
                            </div>
                        </div>
                        <!-- end widget div -->
                    </div>
                    <!-- end widget -->
                </article>
            </div>
            <!-- end row -->
        </section>
        <!-- End Dashboard -->
    </div>
</div>