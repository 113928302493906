
<div class="upload-dialog" style="Max-height:230px;">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 show-stats">
        <div class="row" style="Max-height: 215px; overflow: auto">
            <ng-template [ngIf]="_processes && _processes.length" [ngIfElse]="loading">
            <ng-container *ngFor="let process of _processes; index as i">
                <div class="col-xs-6 col-sm-6 col-md-12 col-lg-12">
                    <span class="text">{{i+1}}.{{process?.ViewName}}
                        <span class="pull-right">{{process?.CurrentTaskIndex}}/{{process?.TotalRecords}}</span>
                    </span>
                    <div class="progress">
                        <div class="progress-bar bg-color-greenLight" [style.width.%]="process?.Percentage"></div>
                    </div>
                </div>
            </ng-container>
        </ng-template>
        <ng-template #loading>
            <div>No job(s) in progress...</div>
        </ng-template>
        </div>
    </div>

</div>
<!--Contents End Here-->
<ng-template pTemplate="footer">
    <!-- <div class="p-dialog-buttonpane">
        <button type="button" pButton icon="fa fa-close" class="btn btn-default"
            (click)="btnProgressCancelClick()" label="Cancel"></button>
    </div> -->
</ng-template>
