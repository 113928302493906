//#region References

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { CommService } from '../../services/comm.service';
import { comm } from '../../components/comm/comm';
import { Router, ActivatedRoute } from '@angular/router';
import { GridOptions } from "ag-grid-community";
import "ag-grid-enterprise";
import { Subscription } from 'rxjs';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
//import { IConfig } from '../../../shared/helpers/config';
import { TreeNode, SelectItem } from 'primeng/api';

import * as moment from 'moment/moment';
import { CommPartial, CommSearch } from '../../../comm/components/comm/commpartial';
import { AssetService } from '../../../admin/services/asset/asset.service';
import { ContainerService } from '../../../records/services/container/container.service';
import { UserService } from '../../../records/services/User/user.service';
import { NgForm } from '@angular/forms';
import { ViewLinkedRecordInboxComponent } from '../../components/comm/view-inbox-button.component';
import { CustomDateComponent } from '../../../shared/components/custom-date/custom-date.component';
import { CustomTooltip } from '../comm/comm-attachment-tooltip.component';
import { ViewService } from '../../../admin/services/view/view.service'
var dateTimeConfig: IDateFormatInfo;
var statusList: any[] = [];
var colorCodes: any[] = [];

//#endregion

//#region Type Decorator
@Component({
    selector: 'list-comm',
    templateUrl: './list-comm.component.html',
    providers: [CommService, AssetService, ContainerService, UserService, ViewService]
})
//#endregion

export class ListCommComponent implements OnInit, OnDestroy {
    //#region Locals Declaration

    recordType: string;
    commId: string = "";


    columnDefs: any[];
    public gridData: any[] = [];
    private selectedRowData: any = null;
    public totalCount: number;

    public gridOptions: GridOptions;

    public isAddVisible: boolean = false;
    public isEditVisible: boolean = false;
    public isCurrentUserOnly: boolean = false;
    public myCommsOnly: boolean = false;
    public isViewVisible: boolean = false;
    public displaysidebar: boolean = false;
    public isViewItemClickedFirstTime: boolean = true;
    subscription: Subscription;
    viewItemsubscription: Subscription;
    commStatusList: any[] = [];
    private userType: string = "";
    selectedAsset: TreeNode[] = [];
    requestedByList: SelectItem[] = [];
    assignedToList: SelectItem[] = [];
    commTypeList: SelectItem[] = [];
    model: CommSearch;
    dateFormatCalender = "";
    state: string = '';
    public iconClass = '';
    public componentName = 'COMI';
    ChangeWidthCalendar = '87.5%';
    private frameworkComponents;
    //@Input() commobj: CommPartial = null;
    //#endregion

    //#region Component Initilization
    constructor(private _sharedService: SharedService, private _commService: CommService, private _containerService: ContainerService, private _userService: UserService, private _assetService: AssetService, public _router: Router, private _route: ActivatedRoute, private _viewService: ViewService,) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            dateTimeConfig = this._sharedService.getDateTimeFormatConfiguration();
            this.userType = this._sharedService.getCurrentUserType();
            if (this._router.url.indexOf("inbox") != -1) { this._sharedService.updateAssignedRole(); }
            this.UpdateRoles();
            this.populateCommStatus();
        }
        this.navigateToNextCell = this.navigateToNextCell.bind(this);
        this.frameworkComponents = { customTooltip: CustomTooltip };
        this.isViewItemClickedFirstTime = true;
    }
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.viewItemsubscription) {
            this.viewItemsubscription.unsubscribe();
        }
    }
    gridIcon() {
        if (this._router.url == '/inbox/active') {
            this.iconClass = 'fa fa-lg fa-fw fa fa-check-square-o'
        }
        else if (this._router.url == '/inbox/new') {
            this.iconClass = 'fa fa-lg fa-fw fa fa-envelope'

        }
        else if (this._router.url == '/inbox/open') {
            this.iconClass = 'glyphicon glyphicon-folder-open'
        }
        else if (this._router.url == '/inbox/pending') {
            this.iconClass = 'fa fa-lg fa-fw fa fa-clock-o'
        }
        else if (this._router.url == '/inbox/solved') {
            this.iconClass = 'fa fa-lg fa-fw fa fa-check'
        }
        else if (this._router.url == '/inbox/closed') {
            this.iconClass = 'fa fa-lg fa-fw fa fa-close'
        }
    }
    ngOnInit() {
        this.isViewItemClickedFirstTime = true;
        this.populateCommStatus();
        this.gridIcon();
        colorCodes = this._commService.getCommColorCodes();
        this.setCalenderDateFormat();
        this.getAssignedToUsersList();
        this.setDefaultComponentValues();
        if (this._route.snapshot.data[0]) {
            this.recordType = this._route.snapshot.data[0].recordType;
        }
        else {
            this.recordType = "0";
        }
        this.setScreenNameInGrid();
        this.gridOptions = <GridOptions>
            {
                rowSelection: 'single',
                rowData: [],
                onGridReady: () => {
                    this.setInboxListingGridColumns();
                    if (this.gridOptions.api != undefined) {
                        if (this.gridData.length > 0) {
                            this.gridOptions.api.hideOverlay();
                            try {
                                if (this._router.url.indexOf("inbox") != -1) {
                                    let gridDiv = document.getElementById('agGridlistItems');
                                    if (gridDiv) {
                                        gridDiv.classList.add("inbox-status-closed-grid");
                                        this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridlistItems'));
                                    }
                                }
                                else {
                                    this.UpdateRoles();
                                    let gridDiv = document.getElementById('agGridlistItems');
                                    if (gridDiv) {
                                        gridDiv.classList.add("inbox-statuses-grid");
                                        this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridlistItems'));
                                    }
                                    this.displaysidebar = false;
                                }
                                // else if(this._router.url.indexOf("inbox") != -1) {
                                //     let gridDiv = document.getElementById('agGridlistItems');
                                //     if (gridDiv) {
                                //         gridDiv.classList.add("inbox-statuses-grid");
                                //         this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridlistItems'));
                                //     }
                                // }

                                this.gridOptions.api.hideOverlay();
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }
                },
                onModelUpdated: (event: any) => {
                    if (this.gridOptions.api) {
                        this.totalCount = this.gridOptions.api.getDisplayedRowCount();
                    }
                    this.gridOptions.api.sizeColumnsToFit();
                },
                //suppressContextMenu: true,
                pagination: true,
                paginationPageSize: 20,
                getContextMenuItems: this.getCustomContextMenuItems,
                context: {
                    gridContext: this
                },
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                },
                components: { agDateInput: CustomDateComponent, customTooltip: CustomTooltip },
                // getRowStyle: function (params: any)
                // {
                //     if (this.recordType=="6")
                //     {
                //         return { color: 'red' }
                //     }

                // }
                onCellEditingStopped: this.updateJobStatus,
                singleClickEdit: true
            }

        this.populateCommsListingGrid();
        this.subscription = this._sharedService._isRefreshInboxGrid
        .subscribe({
            next: (status:any) => {
                this.reBindGrid(status)
            },
            error: (error) => {
            }
        });
        this.viewItemsubscription = this._sharedService._isViewItemInbox
        .subscribe({
            next: (status:any) => {
                this.viewItem()
            },
            error: (error) => {
            }
        });

        let screenSize = window.innerWidth;
        if (screenSize <= 1024) {

            this.ChangeWidthCalendar = '84%'
        }

    }
    setScreenNameInGrid() {
        try {
            this.state = null;
            if (this.recordType != "") {
                switch (this.recordType) {
                    case "1":
                        this.state = 'New';
                        break;
                    case "2":
                        this.state = 'Open';
                        break;
                    case "3":
                        this.state = 'Pending';
                        break;
                    case "4":
                        this.state = 'Solved';
                        break;
                    case "5":
                        this.state = 'Closed';
                        break;
                    case "6":
                        this.state = 'Active';
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {

        }
    }

    commRowDoubleClicked(event: any) {
        //if (this.isEditVisible) {
        this.commId = event.data.CommId;
        this._sharedService.updateCommJob(this.commId);
        //}
    }
    getAssignedToUsersList() {
        this._userService.getCurrentTenantUsersWithType(this._sharedService.baseUrl, null)
        .subscribe({
            next: (response) => {
                this._sharedService.setAssignedToUsersList(response);
            },
            error: (error) => {
            }
        });
    }
    //#endregion


    //#region AG Grid Date Comparer
    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).split(dateTimeConfig.dateSeparator);
            var day = Number(dateParts[Number(dateTimeConfig.day)].substring(0, 2));
            var month = Number(dateParts[Number(dateTimeConfig.month)]) - 1;
            var year = Number(dateParts[Number(dateTimeConfig.year)]);

            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        }
        else {
            return -1;
        }
    }
    //#endregion
    //#region Search
    setDefaultComponentValues(): void {
        this.initializeEmptyModel();
        this.populateUsersList();
        this.populateCommType();
        this.model.FromDate = this.getFromDate();
        this.model.ToDate = this.getToDate();
    }
    initializeEmptyModel() {
        this.model = new CommSearch(null, null, null);
    }
    getFromDate(): Date {
        let currentDate = new Date();
        let cellDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
        return cellDate;
    }
    getToDate(): Date {
        let currentDate = new Date();
        let cellDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        return cellDate;
    }
    //#region Comm Type
    populateCommType() {
        this.commTypeList = [];
        this._commService.getAllCommType()
        .subscribe({
            next: (response) => {
                this.commTypeList = response;
            },
            error: (error) => {
            }
        });
    }
    //#endregion
    //#region Application Users
    populateUsersList() {
        this.requestedByList = [];
        this._userService.getCurrentTenantUsers(this._sharedService.baseUrl, null)
        .subscribe({
            next: (response) => {
                this.requestedByList = response;
        
                //this.model.RequestedBy = this.requestedByList.find(x => x.label == this._sharedService.getCurrentUserName()).value;
            },
            error: (error) => {
            }
        });
    }
    //#endregion
    btnCancelClick(form: NgForm) {
        this.displaysidebar = false;
        this.initializeEmptyModel();
        form.resetForm();
    }
    btnResetClick() {
        this.initializeEmptyModel();
        let commStatusId = Number(this.recordType ? this.recordType : 0);
        if (commStatusId == 5) {
            this.populateCommsListingGrid();
            this.model.FromDate = this.getFromDate();
            this.model.ToDate = this.getToDate();
        }
        else {
            this.populateCommsListingGrid();
        }
    }
    onSearch() {
        let currentUser = "";
        if (this.isCurrentUserOnly) {
            currentUser = this._sharedService.getCurrentUserName();
        }
        let body: any = null;
        body =
        {
            Search: this.model.Search,
            AssignedTo: currentUser,
            FromDate: this.getDate(this.model.FromDate),
            ToDate: this.getDate(this.model.ToDate),
            CommStatusId: Number(this.recordType ? this.recordType : 0),
        }
        this._sharedService.showLoader("Processing...");
        this._commService.getCommDataWithSearch(body)
        .subscribe({
            next: (response) => {
                this.gridData = [];
                this.gridData = response;
                this.totalCount = this.gridData.length;
                this._sharedService.hideLoader();
            },
            error: (error) => {
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again later.' });
            }
        });
    }
    setCalenderDateFormat() {
        let dateParts: string[] = dateTimeConfig.dateformat.split(dateTimeConfig.dateSeparator);

        if (dateTimeConfig.year == 0) {
            this.dateFormatCalender = (dateParts[dateTimeConfig.year].substr(0, 2) + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day]).toLowerCase();
        }
        else if (dateTimeConfig.year == 2) {
            if (dateTimeConfig.month == 1) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
            else if (dateTimeConfig.month == 0) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
        }
        else {
            this.dateFormatCalender = "yy-mm-dd"
        }

    }
    getDate(date: Date): string {
        if (date != null) {
            let newDate = moment(date).format("YYYY-MM-DD");
            return new Date(newDate).toDateString();
        }
        return null;
    }

    btnSearchCriteria() {
        if (this.displaysidebar) {
            this.displaysidebar = false;
        }
        else {
            this.displaysidebar = true;
        }
        this.setDefaultComponentValues();
    }
    //#endregion


    //#region Ag Grid Population Event
    populateCommsListingGrid(): void {
        this.populateCommStatus();
        this._sharedService.showLoader("Processing...");
        let currentUser = "";

        if (!this._sharedService.UserRole.Comm_Readonly && this._sharedService.prevURL.indexOf('/help/') != -1) {
            this.isCurrentUserOnly = true;
            this.myCommsOnly = true;
        }


        if (this.isCurrentUserOnly) {
            currentUser = this._sharedService.getCurrentUserName();
        }
        let body: comm = null;
        if (this._router.url.indexOf("status/viewlist") != -1 || this._router.url.indexOf("repair/viewlist") != -1 || this._router.url.indexOf("record") != -1 || this._router.url.indexOf("transition") != -1) {

            let commobj = this._sharedService.getModulesSelectedOptionForComm();
            body =
            {
                ASN: commobj.ASN,
                Record: commobj.Record,
                Section: commobj.Section,
                Module: commobj.Module,
                CommStatusId: Number(this.recordType ? this.recordType : 0),
                AssignedTo: currentUser
            }
        }
        else {
            body = {
                CommStatusId: Number(this.recordType ? this.recordType : 0),
                AssignedTo: currentUser
            }
        }
        if (body.CommStatusId != 5) {
            this._commService.getCommData(body)
            .subscribe({
                next: (response) => {
                    this.gridData = [];
                    this.gridData = response;
                    this.initializeEmptyModel();
                    if (this._router.url.indexOf("status/viewlist") != -1 || this._router.url.indexOf("repair/viewlist") != -1 || this._router.url.indexOf("record") != -1 || this._router.url.indexOf("transition") != -1) {
                        this.displaysidebar = false;
                    }
                    else { this.displaysidebar = true }
        
                    if (body.CommStatusId == 6) {
                        this.gridOptions.getRowStyle = function (params) {
                            if (params.data && params.data.IsOverDue == true) {
                                return { color: 'red' }
                            }
                        }
                    }
                    this.totalCount = this.gridData.length;
                    this._sharedService.hideLoader();
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again later.' });
                }
            });
        }
        else {
            if (body.CommStatusId == 5 && this._router.url.indexOf("status/viewlist") == -1 && this._router.url.indexOf("repair/viewlist") == -1 && this._router.url.indexOf("record") == -1 && this._router.url.indexOf("transition") == -1) {
                this.setDefaultComponentValues();
                this._sharedService.hideLoader();
                this.displaysidebar = true;
                this.onSearch();
            }
        }

    }

    reBindGrid(selAsset: any) {
        this.setScreenNameInGrid();
        this.populateCommsListingGrid();
    }


    clickedItemsRow(event: any) {
        this.commId = "";
        this.commId = event.data.CommId;
        this.selectedRowData = event.data;
    }
    viewItem() {
        
        if (this.isViewItemClickedFirstTime) {
            this.isViewItemClickedFirstTime = false;
            if (this.selectedRowData != null) {
                if (this.selectedRowData.ASN != null && this.selectedRowData.ASN != "") {
                    let assetsList = this._sharedService.getAssetList();
                    if (this.findInAsset_NewVersion(this.selectedRowData.ASN, assetsList)) {
                        let assetHasRole = false;
                        if (this.selectedRowData.Module == 'Status' && this._sharedService.UserRole['Status_Readonly']) {
                            assetHasRole = true;
                        }
                        else if (this.selectedRowData.Module == 'Repairs' && this._sharedService.UserRole['Repairs_Readonly']) {
                            assetHasRole = true;
                        }
                        else if (this.selectedRowData.Module == 'Record' && (this._sharedService.UserRole['Records_DOC_Readonly'] ||
                            this._sharedService.UserRole['Records_GEN_Readonly'] ||
                            this._sharedService.UserRole['Records_LOG_Readonly'] ||
                            this._sharedService.UserRole['Records_MXR_Readonly'])) {
                            assetHasRole = true;
                        }
                        else if (this.selectedRowData.Module == 'Transition' && this._sharedService.UserRole['Transition_BND_Readonly']) {
                            assetHasRole = true;
                        }

                        if (assetHasRole) {
                            
                            switch (this.selectedRowData.Module) {
                                case 'Status':
                                    this._viewService.getViewById(this.selectedRowData.Section)
                                    .subscribe({
                                        next: (response) => {
                                            if (response.Status) {
                                                this._assetService.getAssetTreeNode(this.selectedRowData.ASN)
                                                .subscribe({
                                                    next: (response) => {
                                                        var arrayResult = [];
                                                        arrayResult.push(response)
                                                        this._sharedService.selectedAssetTreeNode = arrayResult;
                                                        this._sharedService.onAssetChanged(arrayResult);
                                                        this._sharedService.onAssetStatusChanged(arrayResult);
            
                                                        let selectedViewId = this.selectedRowData.Section;
                                                        this._sharedService.setRouteInfoAndNavigate({ MenuID: 22, PageURL: '/status/viewlist', id: Number(selectedViewId), recordId: this.selectedRowData.Record, ColorCode: 'bg-color-msblue' });
                                                    },
                                                    error: (error) => {
                                                    }
                                                });
                                            }
                                            else {
                                                this.isViewItemClickedFirstTime = true;
                                                this._sharedService.clearToast();
                                                this._sharedService.showToast({ severity: 'warn', summary: 'Access Denied', detail: 'You are not allowed to view details.' });
                                            }
                                        },
                                        error: (error) => {
                                        }
                                    });
                                    break;
                                case 'Repairs':
                                    this._viewService.getViewById(this.selectedRowData.Section)
                                    .subscribe({
                                        next: (response) => {
                                            if (response.Status) {
                                                this._assetService.getAssetTreeNode(this.selectedRowData.ASN)
                                                .subscribe({
                                                    next: (response) => {
                                                        var arrayResult = [];
                                                        arrayResult.push(response)
                                                        this._sharedService.selectedAssetTreeNode = arrayResult;
                                                        this._sharedService.onAssetChanged(arrayResult);
                                                        this._sharedService.onAssetStatusChanged(arrayResult);
            
                                                        let selectedViewId = this.selectedRowData.Section;
                                                        this._sharedService.setRouteInfoAndNavigate({ MenuID: 99, PageURL: '/repair/viewlist', id: Number(selectedViewId), recordId: this.selectedRowData.Record, ColorCode: 'bg-color-msdarkcyan' });
                                                    },
                                                    error: (error) => {
                                                    }
                                                })
                                            }
                                            else {
                                                this.isViewItemClickedFirstTime = true;
                                                this._sharedService.clearToast();
                                                this._sharedService.showToast({ severity: 'warn', summary: 'Access Denied', detail: 'You are not allowed to view details.' });
                                            }
                                        },
                                        error: (error) => {
                                        }
                                    });
                                    break;
                                case 'Record':
                                    this._assetService.getAssetTreeNode(this.selectedRowData.ASN)
                                    .subscribe({
                                        next: (response) => {
                                            //Set Selected Asset
                                            var arrayResult = [];
                                            arrayResult.push(response)
                                            this._sharedService.selectedAssetTreeNode = arrayResult;
                                            this._sharedService.onAssetChanged(arrayResult);
                                            this._sharedService.onAssetStatusChanged(arrayResult);
                                            //Get Container Details
                                            let containerId = this.selectedRowData.Section;
                                            this._containerService.getContainerDetail(containerId)
                                            .subscribe({
                                                next: (response) => {
                                                    let result = response;
                                                    this._sharedService.isNavigationFilterCanceled = true;
                                                    let category = result.Category;
                                                    
                                                    switch (category) {
                                                        case 'MXR':
                                                            let assetMXRRecordStatus = JSON.parse(this._sharedService.selectedAsset[0].data).MXRecords;
                                                            if (assetMXRRecordStatus) {
                                                                if (this._sharedService.UserRole.Records_MXR_Readonly) {
                                                                    this._sharedService.setRouteInfoAndNavigate({ MenuID: 17, PageURL: '/record/browse', cat: 'mxr', id: containerId, recordId: this.selectedRowData.Record, ColorCode: 'bg-color-msgreen' });
                                                                }
                                                                else {
                                                                    this.isViewItemClickedFirstTime = true;
                                                                    this._sharedService.clearToast();
                                                                    this._sharedService.showToast({ severity: 'warn', summary: 'Restricted Access', detail: 'You are not allowed to view details.' });
                                                                }
                                                            }
                                                            else {
                                                                this.isViewItemClickedFirstTime = true;
                                                                this._sharedService.clearToast();
                                                                this._sharedService.showToast({ severity: 'warn', summary: 'Restricted Access', detail: 'You are not allowed to view details.' });
                                                                this._sharedService.isNavigationFilterCanceled = true;
                                                            }
            
                                                            break;
                                                        case 'LOG':
                                                            let assetLogStatus = JSON.parse(this._sharedService.selectedAsset[0].data).LogBooks;
                                                            if (assetLogStatus) {
                                                                if (this._sharedService.UserRole.Records_LOG_Readonly) {
                                                                    this._sharedService.setRouteInfoAndNavigate({ MenuID: 19, PageURL: '/record/logs', cat: 'logs', id: containerId, recordId: this.selectedRowData.Record, ColorCode: 'bg-color-msgreen' });
                                                                }
                                                                else {
                                                                    this.isViewItemClickedFirstTime = true;
                                                                    this._sharedService.clearToast();
                                                                    this._sharedService.showToast({ severity: 'warn', summary: 'Restricted Access', detail: 'You are not allowed to view details.' });
                                                                }
                                                            }
                                                            else {
                                                                this.isViewItemClickedFirstTime = true;
                                                                this._sharedService.clearToast();
                                                                this._sharedService.showToast({ severity: 'warn', summary: 'Restricted Access', detail: 'You are not allowed to view details.' });
                                                                this._sharedService.isNavigationFilterCanceled = true;
                                                            }
                                                            break;
                                                        case 'GEN':
                                                            let assetGeneralStatus = JSON.parse(this._sharedService.selectedAsset[0].data).General;
                                                            if (assetGeneralStatus) {
                                                                if (this._sharedService.UserRole.Records_GEN_Readonly) {
                                                                    this._sharedService.setRouteInfoAndNavigate({ MenuID: 20, PageURL: '/record/general', cat: 'general', id: containerId, recordId: this.selectedRowData.Record, ColorCode: 'bg-color-msgreen' });
                                                                }
                                                                else {
                                                                    this.isViewItemClickedFirstTime = true;
                                                                    this._sharedService.clearToast();
                                                                    this._sharedService.showToast({ severity: 'warn', summary: 'Restricted Access', detail: 'You are not allowed to view details.' });
                                                                }
                                                            }
                                                            else {
                                                                this.isViewItemClickedFirstTime = true;
                                                                this._sharedService.clearToast();
                                                                this._sharedService.showToast({ severity: 'warn', summary: 'Restricted Access', detail: 'You are not allowed to view details.' });
                                                                this._sharedService.isNavigationFilterCanceled = true;
                                                            }
                                                            break;
                                                        case 'DOC':
                                                            let assetDocumentStatus = JSON.parse(this._sharedService.selectedAsset[0].data).Documents;
                                                            if (assetDocumentStatus) {
                                                                if (this._sharedService.UserRole.Records_DOC_Readonly) {//OLD MenuID: 110 don't know Why
                                                                    this._sharedService.setRouteInfoAndNavigate({ MenuID: 96, PageURL: '/record/documents', cat: 'documents', id: containerId, recordId: this.selectedRowData.Record, ColorCode: 'bg-color-msgreen' });
                                                                }
                                                                else {
                                                                    this.isViewItemClickedFirstTime = true;
                                                                    this._sharedService.clearToast();
                                                                    this._sharedService.showToast({ severity: 'warn', summary: 'Restricted Access', detail: 'You are not allowed to view details.' });
                                                                }
                                                            }
                                                            else {
                                                                this.isViewItemClickedFirstTime = true;
                                                                this._sharedService.clearToast();
                                                                this._sharedService.showToast({ severity: 'warn', summary: 'Restricted Access', detail: 'You are not allowed to view details.' });
                                                                this._sharedService.isNavigationFilterCanceled = true;
                                                            }
                                                            break;
                                                        // case 'BND':
                                                        //     if (this._sharedService.UserRole.Transition_BND_Readonly) {
                                                        //         this._sharedService.setRouteInfoAndNavigate({ MenuID: 114, PageURL: '/transition/binders', cat: 'binders', id: containerId, recordId: this.selectedRowData.Record, ColorCode: 'bg-color-msgreen' });
                                                        //     }
                                                        //     else {
                                                        //         this._sharedService.clearToast();
                                                        //         this._sharedService.showToast({ severity: 'warn', summary: 'Restricted Access', detail: 'You are not allowed to view details.' });
                                                        //     }
            
                                                        //     break;
                                                    }
                                                },
                                                error: (error) => {
                                                    this.isViewItemClickedFirstTime = true;
                                                    this._sharedService.clearToast();
                                                    this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again later.' });
                                                }
                                            });
                                        },
                                        error: (error) => {
                                            this.isViewItemClickedFirstTime = true;
                                            this._sharedService.clearToast();
                                            this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again later.' });
                                        }
                                    });
                                    break;
                                case 'Transition':
                                    this._assetService.getAssetTreeNode(this.selectedRowData.ASN)
                                    .subscribe({
                                        next: (response) => {
                                            //Set Selected Asset
                                            var arrayResult = [];
                                            arrayResult.push(response)
                                            this._sharedService.selectedAssetTreeNode = arrayResult;
                                            this._sharedService.onAssetChanged(arrayResult);
                                            this._sharedService.onAssetStatusChanged(arrayResult);
        
                                            //Get Container Details
                                            let containerId = this.selectedRowData.Section;
                                            this._containerService.getContainerDetail(containerId)
                                            .subscribe({
                                                next: (response) => {
                                                    let result = response;
                                                    this._sharedService.isNavigationFilterCanceled = true;
                                                    let category = result.Category;
                                                    switch (category) {
                                                        case 'BND':
                                                            let assetBinderStatus = JSON.parse(this._sharedService.selectedAsset[0].data).Binders;
                                                            if (assetBinderStatus) {
                                                                if (this._sharedService.UserRole.Transition_BND_Readonly) {
                                                                    this._sharedService.setRouteInfoAndNavigate({ MenuID: 114, PageURL: '/transition/binders', cat: 'binders', id: containerId, recordId: this.selectedRowData.Record, ColorCode: 'bg-color-msgreen' });
                                                                }
                                                                else {
                                                                    this.isViewItemClickedFirstTime = true;
                                                                    this._sharedService.clearToast();
                                                                    this._sharedService.showToast({ severity: 'warn', summary: 'Restricted Access', detail: 'You are not allowed to view details.' });
                                                                }
                                                            }
                                                            else {
                                                                this.isViewItemClickedFirstTime = true;
                                                                this._sharedService.clearToast();
                                                                this._sharedService.showToast({ severity: 'warn', summary: 'Restricted Access', detail: 'You are not allowed to view details.' });
                                                                this._sharedService.isNavigationFilterCanceled = true;
                                                            }
                                                            break;
                                                    }
                                                },
                                                error: (error) => {
                                                    this.isViewItemClickedFirstTime = true;
                                                    this._sharedService.clearToast();
                                                    this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again later.' });
                                                }
                                            });
                                        },
                                        error: (error) => {
                                            this.isViewItemClickedFirstTime = true;
                                            this._sharedService.clearToast();
                                            this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again later.' });
                                        }
                                    });
                                    break;
                            }
                        }
                        else {
                            this.isViewItemClickedFirstTime = true;
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'warn', summary: 'Access Denied', detail: 'Selected Asset does not have access to view the item' });
                        }

                    } else {
                        this.isViewItemClickedFirstTime = true;
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'Access Denied', detail: 'User does not have access to view the item' });
                    }
                }
                else {
                    this.isViewItemClickedFirstTime = true;
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'warn', summary: 'No item exist to view', detail: 'Please select valid item to view' });
                }
            }
            else {
                this.isViewItemClickedFirstTime = true;
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'No Item Selected', detail: 'Please select a row first' });
            }
        }
    }

    findInAsset(asn: string, assetList: any[]) {
        let isvalidAsset: Boolean = false;
        assetList.forEach((groupRow) => {
            groupRow.children.forEach((aircraftRow) => {
                if (aircraftRow.children != null) {
                    aircraftRow.children.forEach((asset) => {
                        if (asset.data.indexOf(asn) != -1) {
                            isvalidAsset = true;
                        }
                    });
                }
            });
        });
        return isvalidAsset;
    }


    findInAsset_NewVersion(asn: string, assetList: any[]) {
        let _selectedAsset = null;
        let isvalidAsset: Boolean = false;
        assetList.forEach((groupRow) => {
            groupRow.children.forEach((aircraftRow) => {
                if (aircraftRow.children != null) {
                    aircraftRow.children.forEach((asset) => {
                        let assetDataObj = JSON.parse(asset.data);
                        if (assetDataObj.ASN == asn) {
                            _selectedAsset = asset;
                            isvalidAsset = true;
                        }
                    });
                }
            });
        });

        if (isvalidAsset) {
            this._sharedService.updateRoleBySelectedAsset(_selectedAsset);
        }
        return isvalidAsset;
    }
    //#endregion
    // #region All Comms or Current Comms Switch Events
    showAllUsersRecords() {
        this.populateCommsListingGrid();
    }
    //#endregion
    navigateToNextCell(params: any) {
        var previousCell = params.previousCellPosition;
        // var previousCell = params.previousCellDef;
        var suggestedNextCell = params.nextCellPosition;
        // var suggestedNextCell = params.nextCellDef;
        var self = this;
        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        var commId, selectedRowData;
        switch (params.key) {
            case KEY_DOWN:
                var nextRowIndex = previousCell.rowIndex + 1;
                var renderedRowCount = this.gridOptions.api.getModel().getRowCount();
                if (nextRowIndex >= renderedRowCount) {
                    return null;
                } else {
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
                    rowNode.setSelected(true);
                    // self.commId = rowNode.data.CommId;
                    // self.selectedRowData = rowNode.data;
                    this.commId = rowNode.data.CommId;
                    this.selectedRowData = rowNode.data;
                    return {
                        rowIndex: nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }


            case KEY_UP:
                var _nextRowIndex = previousCell.rowIndex - 1;
                if (_nextRowIndex < 0) {
                    return null;
                } else {
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
                    rowNode.setSelected(true);
                    this.commId = rowNode.data.CommId;
                    this.selectedRowData = rowNode.data;
                    return {
                        rowIndex: _nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw 'this will never happen, navigation is always on of the 4 keys above';
        }

    }
    //#region Set Columns of Inbox Ag Grid
    setInboxListingGridColumns(): void {

        let screenWidth = window.innerWidth;
        this.columnDefs = [];
        if (screenWidth < 1025) {
            this.columnDefs =
                [
                    {
                        headerName: 'Item Number', field: 'CommId', filter: 'set', menuTabs: ['filterMenuTab'], width: 60
                    },
                    {
                        headerName: 'ASN', field: 'ASN', filter: 'set', menuTabs: ['filterMenuTab'], hide: true, width: 40
                    },
                    {
                        headerName: 'Title', field: 'Title', filter: 'set', menuTabs: ['filterMenuTab'], width: 110
                    },
                    {
                        headerName: 'Module', field: 'Module', filter: 'set', menuTabs: ['filterMenuTab'], width: 80
                    },
                    //{
                    //    headerName: 'Section', field: 'Section', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 50,
                    //},
                    {
                        headerName: 'Requested By', field: 'RequestedBy', filter: 'set', menuTabs: ['filterMenuTab'], width: 92
                    },
                    {
                        headerName: 'Type', field: 'CommType', filter: 'set', menuTabs: ['filterMenuTab'], width: 60
                    },
                    {
                        headerName: 'Priority', field: 'PriorityString', filter: 'set', menuTabs: ['filterMenuTab'], width: 60
                    },
                    // {
                    //     headerName: 'Priority', field: 'Priority', filter: 'set', menuTabs: ['filterMenuTab'], width: 60, valueFormatter: function (params: any) {
                    //         if (params.value != "" && params.value != null) {

                    //             if (params.value == "1") {
                    //                 params.value = "Urgent";
                    //                 return "Urgent";
                    //             }
                    //             else if (params.value == "2") {
                    //                 params.value = "High";
                    //                 return "High";
                    //             }
                    //             else if (params.value == "3") {
                    //                 params.value = "Normal";
                    //                 return "Normal";
                    //             }
                    //             else {
                    //                 params.value = "Low";
                    //                 return "Low";
                    //             }
                    //         }
                    //     }
                    // },
                    {
                        headerName: 'Subject', field: 'Subject', filter: 'set', minWidth: 200, menuTabs: ['filterMenuTab']
                    },
                    {
                        headerName: 'Due Date', field: 'DueDate', filter: 'agDateColumnFilter', width: 90, filterParams: {
                            comparator: this.dateComparater,
                            inRangeInclusive: 'true'

                        }, valueFormatter: function (params: any) {
                            if (params.value != "" && params.value != null) {
                                let dateString = params.value.substring(0, 10);
                                return moment(dateString).format(dateTimeConfig.dateformat.toUpperCase());
                            }
                        }, menuTabs: ['filterMenuTab'], /* minWidth: 80, */
                    },
                    {
                        headerName: 'Assigned To', field: 'AssignedTo', filter: 'set', width: 92, menuTabs: ['filterMenuTab']
                    },
                ];

            if (this._router.url.indexOf('status/viewlist') != -1 || this._router.url.indexOf("record")) {
                this.columnDefs.push({
                    headerName: 'Status', field: 'CommStatus', filter: 'set', width: 60, menuTabs: ['filterMenuTab']
                });
            }
        }
        else {
            this.columnDefs =
                [
                    {
                        headerName: 'Item Number', field: 'CommId', filter: 'set', menuTabs: ['filterMenuTab'], width: 30
                    },
                    {
                        headerName: 'ASN', field: 'ASN', filter: 'set', menuTabs: ['filterMenuTab'], hide: true, width: 40
                    },
                    {
                        headerName: 'Title', field: 'Title', filter: 'set', menuTabs: ['filterMenuTab'], width: 110
                    },
                    {
                        headerName: 'Module', field: 'Module', filter: 'set', menuTabs: ['filterMenuTab'], width: 50
                    },
                    //{
                    //    headerName: 'Section', field: 'Section', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 50,
                    //},
                    {
                        headerName: 'Requested By', field: 'RequestedBy', filter: 'set', menuTabs: ['filterMenuTab'], width: 92
                    },
                    {
                        headerName: 'Type', field: 'CommType', filter: 'set', menuTabs: ['filterMenuTab'], width: 60
                    },
                    {
                        headerName: 'Priority', field: 'PriorityString', filter: 'set', menuTabs: ['filterMenuTab'], width: 60
                    },
                    // {
                    //     headerName: 'Priority', field: 'Priority', filter: 'set', menuTabs: ['filterMenuTab'], width: 60, valueFormatter: function (params: any) {
                    //         if (params.value != "" && params.value != null) {

                    //             if (params.value == "1") {
                    //                 params.value = "Urgent";
                    //                 return "Urgent";
                    //             }
                    //             else if (params.value == "2") {
                    //                 params.value = "High";
                    //                 return "High";
                    //             }
                    //             else if (params.value == "3") {
                    //                 params.value = "Normal";
                    //                 return "Normal";
                    //             }
                    //             else {
                    //                 params.value = "Low";
                    //                 return "Low";
                    //             }
                    //         }
                    //     }
                    // },
                    {
                        headerName: 'Subject', field: 'Subject', filter: 'set', minWidth: 280, menuTabs: ['filterMenuTab']
                    },
                    {
                        headerName: 'Due Date', field: 'DueDate', filter: 'agDateColumnFilter', width: 90, filterParams: {
                            comparator: this.dateComparater,
                            inRangeInclusive: 'true'

                        }, valueFormatter: function (params: any) {
                            if (params.value != "" && params.value != null) {
                                let dateString = params.value.substring(0, 10);
                                return moment(dateString).format(dateTimeConfig.dateformat.toUpperCase());
                            }
                        }, menuTabs: ['filterMenuTab'], /* minWidth: 80, */
                    },
                    {
                        headerName: 'Assigned To', field: 'AssignedTo', filter: 'set', width: 92, menuTabs: ['filterMenuTab']
                    },
                ];

            if (this._router.url.indexOf('status/viewlist') != -1 || this._router.url.indexOf("record")) {
                this.columnDefs.push({
                    headerName: 'Status',
                    field: 'CommStatus',
                    filter: 'set',
                    width: 40,
                    menuTabs: ['filterMenuTab'],
                    editable: this._sharedService.UserRole.Comm_Edit,//this.isEditVisible,
                    cellEditor: 'agRichSelectCellEditor',
                    cellStyle: function (params) {
                        if (params.value != null && params.value.toLowerCase() === 'new') {
                            return { color: 'white', backgroundColor: '#0082c8' };
                        }
                        else if (params.value != null && params.value.toLowerCase() == 'open') {
                            return { color: 'white', backgroundColor: '#a90329' };
                        }
                        else if (params.value != null && params.value.toLowerCase() == 'pending') {
                            return { color: 'white', backgroundColor: '#999' };
                        }
                        else if (params.value != null && params.value.toLowerCase() == 'closed') {
                            return { color: 'white', backgroundColor: '#71843f' };
                        }
                        else {
                            return { color: 'white', backgroundColor: '#c79121' };
                        }
                    },
                    cellEditorParams: {
                        values: this.commStatusList.map(sts => sts.Description.trim()),
                        cellRenderer: this.statusEditorCellRenderer
                    }
                });
            }
        }

        if (this._router.url.indexOf("record") == -1 && this._router.url.indexOf("status") == -1) {
            this.columnDefs.unshift({ tooltipField: 'ASN', headerName: '', field: '', cellRenderer: ViewLinkedRecordInboxComponent, suppressMenu: true, filter: false, width: 15, tooltipComponent: 'customTooltip' });
        }
    }

    //#endregion



    //#region Roles
    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }
        this.isAddVisible = false;
        //this.isEditVisible = false;

        this.isAddVisible = this._sharedService.UserRole.Comm_Add;
        this.isEditVisible = true;//this._sharedService.UserRole.Comm_Edit;
        //set isEditVisible - true for now to let user can open the communication but user can't update it (Readonly Mode)
        if (this._router.url.indexOf("status/viewlist") != -1 || this._router.url.indexOf("record") != -1) {
            this.isViewVisible = false;
        }
        else {
            this.isViewVisible = true;
        }
    }
    //#endregion

    //#region Helper Methods

    //#endregion

    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];

        if (params.context.gridContext.isAddVisible) {
            result.push({
                name: "Add Item",
                action: function () {

                    params.context.gridContext._sharedService.addComm(null);
                },
                icon: params.context.gridContext._sharedService.addIconHTMLasString,
            });
        }
        //if (params.context.gridContext.isEditVisible) {
        result.push({
            name: "Edit Item",
            action: function () {
                params.context.gridContext.commId = null;
                if (params.node) {
                    params.context.gridContext.commId = params.node.data.CommId;
                    params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                    params.context.gridContext._sharedService.updateCommUsingId(params.context.gridContext.commId);
                }

            },
            icon: params.context.gridContext._sharedService.editIconHTMLasString,
        });
        //}
        if (params.context.gridContext._router.url.indexOf("status/viewlist") == -1 && params.context.gridContext._router.url.indexOf("record") == -1) {
            result.push({
                name: "View Item",
                action: function () {
                    params.context.gridContext.commId = null;
                    params.context.gridContext.selectedRowData = null;
                    if (params.node) {
                        params.context.gridContext.commId = params.node.data.CommId;
                        params.context.gridContext.selectedRowData = params.node.data;
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext.viewItem();
                    }

                },
                icon: params.context.gridContext._sharedService.logIconHTMLasString,
            });
        }

        if (params.context.gridContext.isCurrentUserOnly) {
            result.push({
                name: "All Items",
                action: function () {
                    params.context.gridContext.isCurrentUserOnly = false;
                    params.context.gridContext.showAllUsersRecords();
                },
                icon: params.context.gridContext._sharedService.jobsIconHTMLasString,

            });
        }
        else {
            result.push({
                name: "My Items",
                action: function () {
                    params.context.gridContext.isCurrentUserOnly = true;
                    params.context.gridContext.showAllUsersRecords();
                },
                icon: params.context.gridContext._sharedService.jobsIconHTMLasString,
            });
        }


        return result;

    }
    //#endregion
    //#region Status Change on grid 
    updateJobStatus(event: any) {
        if (event.colDef.field == 'CommStatus') {
            let selectedData = event.data;
            let selectedCommStatusId = event.context.gridContext.commStatusList.find(x => x.Description == selectedData.CommStatus).CommStatusId;
            let existingCommStatusLabel = event.context.gridContext.commStatusList.find(x => x.CommStatusId == selectedData.CommStatusId).Description;
            if (selectedCommStatusId != selectedData.CommStatusId) {
                let body: comm =
                {
                    CommId: selectedData.CommId,
                    CommStatusId: selectedCommStatusId,
                    History: event.context.gridContext.getHistory(existingCommStatusLabel, selectedData.CommStatus)
                };
                event.context.gridContext._commService.updateCommStatus(body)
                .subscribe({
                    next: (response) => {
                        event.context.gridContext.msgs = [];
                        event.context.gridContext.msgs.push({ severity: 'success', summary: 'Success', detail: 'Comm updated succesfully.' });
                        event.context.gridContext.populateCommsListingGrid();
                    },
                    error: (error) => {
                        event.context.gridContext.msgs = [];
                        event.context.gridContext.msgs.push({ severity: 'error', summary: 'Error Occured', detail: 'Comm not updated, please try again.' });
                    }
                });
            }

        }
    }

    getCurrentDateTime() {
        let result = moment().format("MM/DD/YYYY" + " HH:mm");
        return result;
    }

    getHistory(oldValue: string, newValue: string) {
        let currentTimeStamp = this.getCurrentDateTime();
        let newHistory = '';
        newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "job status" + "," + oldValue + "," + newValue + "\r\n" + newHistory;
        return newHistory;
    }
    populateCommStatus() {
        this._commService.getAllActiveCommStatus()
        .subscribe({
            next: (response) => {
                this.commStatusList = response;
                statusList = response;
            },
            error: (error) => {
                console.log(error);
            }
        });
    }

    statusEditorCellRenderer(params: any) {
        let html: string = '';
        var eDiv = document.createElement('div');
        if (params.value) {
            let commStatusName: string = params.value.trim();
            let currentStatus: any = statusList.filter(x => x.Description == commStatusName)[0];
            if (currentStatus && currentStatus.Description) {
                let colorCode = colorCodes.filter(x => x.status == commStatusName.toLowerCase());
                eDiv.innerHTML = html + commStatusName;
                eDiv.style.backgroundColor = colorCode[0].colorCode;
            }

        }
        else {
            return params.value;
        }

        eDiv.style.height = '100%';
        eDiv.style.width = '100%';
        eDiv.style.color = 'white';
        eDiv.style.textAlign = 'center';
        eDiv.style.paddingTop = '5px';
        eDiv.style.paddingLeft = '5px';
        eDiv.style.textAlign = 'left';
        return eDiv;
    }
    //#endregion
}
