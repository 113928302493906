export class articleCategoryModel {
  constructor(
      public ArticleCategoryId?: string,
      public Name?: string,
      public Description?:string,
      public Color?:string,
      public Order?:string,
      public Status?: boolean,
      public CreatedBy?: string,
      public CreatedOn?: Date,
      public ModifiedBy?: string,
      public IconClass?: string,
      public ModifiedOn?: Date,

   ) {
  }
}
