<div class="jarviswidget-ctrls" role="menu" title="Add Job (Ctrl+Shift+A)">
    <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="showAddDialog()" id="btnadd"
        (click)="showAddDialog()">
        <!--(click)="showAddDialog()"-->
        <i class="glyphicon glyphicon-plus"></i>
    </a>
</div>

<form *ngIf="display" #addjobForm="ngForm">
    <p-dialog *ngIf="display" [focusOnShow]="true" id="addJobDlg" [(visible)]="display" showEffect="fade" [modal]="true"
        [style]="{width:setAddPopupWidth}" [resizable]="false" (onShow)="adjustJobDlgHeight($event)">
        <ng-template pTemplate="header"  (keyup.shift.tab)="onKeyAddJob()">
            {{dialogTitle}}
        </ng-template>
        <div class="upload-dialog">
            <div id="jobAddDlg" class="smart-form AddJobDlgContent">
                <div class="row">
                    <div class="form-group col-sm-3" *ngIf="isJobModule">
                        <fieldset>
                            <label class="label" style="height: 18px;">
                            </label>
                            <container-selection [attr.tabindex]="-1"  *ngIf="display" [screenmode]="screenmode"></container-selection>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Asset<span [hidden]="asset.valid" class="text-danger">
                                    *
                                </span>
                            </label>
                            <span [ngClass]="{'readonlyinput': isJobModule==false}" class="width-100-percent">

                                <p-dropdown appendTo="body" id="asset" name="asset" [options]="assets" [filter]="true"
                                placeholder="Select Asset" required [(ngModel)]="model.asset" #asset="ngModel"
                                #addassetjob [resetFilterOnHide]="true" [disabled]="isJobModule==false"
                                [style]="{margin:'2px'}">
                                    <ng-template style="padding-bottom:20px;padding-left: 0px !important;" let-item pTemplate="selectedItem">
                                        <span>{{item.label}}</span>
                                        <!-- <span style="font-size:14px;background-color:lightgreen;">{{item.AssetType}}</span> -->
                                    </ng-template>
                                    <ng-template  style="padding-bottom:20px !important;padding-left: 0px !important;" let-asset pTemplate="item">
                                        <div style="padding-bottom: 20px;">
                                            <!-- <span > -->
                                                <span style="padding:0" class="col-md-9">{{asset.label}}</span>

                                            <!-- </div> -->
                                            <!-- <div class="col-md-4"> -->
                                                <span style="padding:0" class="col-md-3">
                                                    <span  style="font-size: 11px;padding: 4px; background-color:lightgreen;color: white;border-radius: 4px;">
                                                        {{asset.AssetType}}
                                                </span>
                                                </span>

                                        <!-- </div> -->
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                                <!-- <p-dropdown appendTo="body" id="asset" name="asset" [options]="assets" [filter]="true"
                                    placeholder="Select Asset" required [(ngModel)]="model.asset" #asset="ngModel"
                                    #addassetjob [resetFilterOnHide]="true" [disabled]="isJobModule==false"
                                    [style]="{margin:'2px'}">
                                </p-dropdown> -->
                            </span>
                        </fieldset>
                    </div>
                    <div class="form-group width-100 col-sm-3 pull-right">
                        <fieldset>
                            <label class="label">
                                Job Status<span
                                    [hidden]="jobstatus.valid || (model.dpjobstatusid!=null && model.dpjobstatusid>0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="jobstatus" name="jobstatus" [options]="jobStatusList" required
                                [(ngModel)]="model.dpjobstatusid" #jobstatus="ngModel" placeholder="Select Status"
                                [style]="{'width':'341px','margin':'2px'}">

                            </p-dropdown>

                        </fieldset>
                    </div>

                </div>
                <div class="row">
                    <div class="form-group col-sm-3">
                        <fieldset>
                            <label class="label" style="margin-bottom:2px">
                                Container Type<span [hidden]="type.valid" class="text-danger">
                                    *
                                </span>
                            </label>
                            <span [ngClass]="{'readonlyinput': isJobModule==false}" class="width-100-percent">
                                <p-dropdown appendTo="body" id="type" name="type" [options]="WOTypes" required
                                    placeholder="{{placeholderContainerType}}" [(ngModel)]="model.type" #type="ngModel"
                                    [filter]="true" #addtypejob [style]="{'margin':'2px'}" [resetFilterOnHide]="true"
                                    [disabled]="isJobModule==false" (onChange)=containerTypeChanged($event?.value)>
                                </p-dropdown>
                            </span>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-3">
                        <fieldset>
                            <label class="label">
                                Date<span [hidden]="date.valid" class="text-danger">
                                    *
                                </span>
                                ({{ViewDateFormat}})
                            </label>
                            <label class="input" [ngClass]="{'readonlyinput': isJobModule==false}">
                                <i class='icon-append fa fa-calendar'></i>
                                <p-calendar appendTo="body" class='datepicker hasDatepicker' (onClose)="onCloseDate($event)" id='date' name="date"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030" required
                                    [(ngModel)]="model.date" #date="ngModel" [dateFormat]="dateFormatCalender"
                                    [style]="{width:'87%'}" [disabled]="isJobModule==false"
                                    placeholder="{{ViewDateFormat}}">
                                </p-calendar>

                            </label>

                        </fieldset>

                    </div>
                    <div class="form-group col-sm-3">
                        <fieldset>
                            <label class="label">
                                End Date<span
                                    [hidden]="enddate.valid ||(model.referencekey!=null && model.referencekey.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                                ({{ViewDateFormat}})
                            </label>
                            <label class="input enddate"
                                [ngClass]="{'readonlyinput':(model.referencekey!=null && model.referencekey.length >0) || isJobModule==false}">
                                <i class='icon-append fa fa-calendar'></i>
                                <p-calendar appendTo="body"  [attr.tabindex]="0" class='datepicker hasDatepicker' id='enddate' name="enddate" (onClose)="onCloseEnddate()"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030"
                                    [required]="((model.referencekey!=null && model.referencekey.length==0))"
                                    [disabled]="(model.referencekey!=null && model.referencekey.length>0) || isJobModule==false"
                                    [(ngModel)]="model.enddate" #enddate="ngModel" [dateFormat]="dateFormatCalender"
                                    placeholder="{{ViewDateFormat}}" [style]="{width:'87%'}">

                                </p-calendar>

                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-3">
                        <fieldset>
                            <label class="label">
                                {{referenceKeyLabel}}<span [hidden]="model.referencekey || model.enddate"
                                    class="text-danger">
                                    *
                                </span>
                            </label>
                            <label class="input rgxErr">
                                <i *ngIf="wonumber.value && !wonumber.valid && (wonumber.touched || wonumber.dirty )"
                                    class="icon-append fa fa-ban"
                                    title="{{_sharedService.error_rgx_pre}} {{_sharedService.re_WorkOrderNumber_Message}} {{_sharedService.error_rgx_post}}">
                                </i>
                                <span id="woNumberId" [attr.tabindex]="0">
                                <input id="wonumber" type="text" name="wonumber" [(ngModel)]="model.referencekey"
                                    [required]="model.enddate==null" #wonumber="ngModel"
                                    [pattern]="_sharedService.re_WorkOrderNumber"
                                    [disabled]="model.enddate!=null || isJobModule==false"
                                    [ngClass]="{'readonlyinput2': model.enddate!=null || isJobModule==false}" />
                                </span>
                                </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row" *ngIf="userType=='Internal'">
                    <div class="form-group col-sm-3">
                        <fieldset>
                            <label class="label">
                                Priority<span
                                    [hidden]="priority.valid || (model.priority!=null && model.priority.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="priority" name="priority" [options]="priorityList" required
                                placeholder="Select Priority" [(ngModel)]="model.priority" #priority="ngModel"
                                [style]="{margin:'2px'}">

                            </p-dropdown>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-3">
                        <fieldset>
                            <label class="label">
                                RDOC Batch Type
                            </label>

                            <p-dropdown appendTo="body" id="addbatchtype" name="addbatchtype" [options]="batchTypesList"
                                placeholder="{{placeholderRDOCBatchType}}" [(ngModel)]="model.batchtypeid"
                                #addbatchtype="ngModel" [style]="{margin:'2px'}">

                            </p-dropdown>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6 width-100">
                        <fieldset>
                            <label class="label">
                                Assigned To
                            </label>

                            <p-dropdown appendTo="body" id="assignedto" name="assignedto" [options]="internalUsersList"
                                placeholder="Select Assignee" [(ngModel)]="model.assignedto" #assignedto="ngModel"
                                [filter]="true" #addassignedtojob [resetFilterOnHide]="true" [style]="{margin:'2px'}">

                            </p-dropdown>

                        </fieldset>
                    </div>
                </div>

                <div class="row" *ngIf="recordType=='4' && userType=='Internal'">
                    <!-- <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Job Internal Status
                            </label>

                            <p-dropdown appendTo="body" id="jobintstatus" name="jobintstatus" [options]="jobInternalList" placeholder="Select Internal Status" [(ngModel)]="model.dpjobinternalstatusid" #jobintstatus="ngModel">

                            </p-dropdown>

                        </fieldset>
                    </div> -->
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Document Count
                            </label>

                            <label class="input">
                                <input id="documentcount" type="text" name="documentcount" placeholder="Document Count"
                                    [(ngModel)]="model.documents" #documentcount="ngModel"
                                    onkeypress="return String.fromCharCode(event.charCode).match(/[0-9]/g) != null">

                            </label>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Page Count
                            </label>

                            <label class="input">
                                <input id="pagecount" type="text" name="pagecount" placeholder="Page Count"
                                    [(ngModel)]="model.pages" #pagecount="ngModel"
                                    onkeypress="return String.fromCharCode(event.charCode).match(/[0-9]/g) != null">

                            </label>

                        </fieldset>
                    </div>
                </div>

                <div class="row" *ngIf="userType=='External'">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Priority<span
                                    [hidden]="priority.valid || (model.priority!=null && model.priority.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="priority" name="priority" [options]="priorityList" required
                                placeholder="Select Priority" [(ngModel)]="model.priority" #priority="ngModel">

                            </p-dropdown>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                RDOC Batch Type
                            </label>

                            <p-dropdown appendTo="body" id="addbatchtypeExt" name="addbatchtypeExt" [options]="batchTypesList"
                                placeholder="Select RDOC Batch Type" [(ngModel)]="model.batchtypeid"
                                #addbatchtypeExt="ngModel">

                            </p-dropdown>

                        </fieldset>
                    </div>
                </div>
                <div class="row" *ngIf="userType=='Internal' && recordType!='4'">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Document Count
                            </label>

                            <label class="input">
                                <input id="documentcount" type="text" name="documentcount" placeholder="Document Count"
                                    [(ngModel)]="model.documents" #documentcount="ngModel"
                                    onkeypress="return String.fromCharCode(event.charCode).match(/[0-9]/g) != null">

                            </label>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Page Count
                            </label>

                            <label class="input">
                                <input id="pagecount" type="text" name="pagecount" placeholder="Page Count"
                                    [(ngModel)]="model.pages" #pagecount="ngModel"
                                    onkeypress="return String.fromCharCode(event.charCode).match(/[0-9]/g) != null">

                            </label>

                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">Description</label>
                            <label class="textarea"><textarea id="comments" name="comments" #comments="ngModel"
                                    [(ngModel)]="model.comments" class="custom-scrollNote" rows="3"></textarea></label>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <fieldset>
                        <div class="jarviswidget" id="wid-id-1" data-widget-togglebutton="false"
                            data-widget-sortable="false" data-widget-fullscreenbutton="false"
                            data-widget-colorbutton="false" data-widget-editbutton="false"
                            data-widget-custombutton="false" data-widget-deletebutton="false" role="widget">

                            <header role="heading" style="margin-left: 0px;margin-right: 0px;">
                                <span class="widget-icon"><i class="fa fa-comments"></i></span>
                                <h2>Conversation</h2>
                            </header>
                            <!-- widget div-->
                            <div class="widgetdiv no-padding" style="width: 100%;">
                                <!-- widget content -->
                                <div class="widget-body">
                                    <div>
                                        <div class="comments-list">
                                            <div class="media">
                                                <div class="media-body">
                                                    <textarea id="jobconversation" placeholder="Write your comment..."
                                                        name="jobconversation" #jobconversation="ngModel"
                                                        [(ngModel)]="model.conversation"
                                                        class="custom-scrollNote no-overflow-x padding-left inbox-textarea"
                                                        rows="2" style="width:100%"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </fieldset>
                </div>

            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex"
                    (click)="onSubmit(addjobForm)" label="Add"
                    [disabled]="!addjobForm.form.valid || isSubmitted"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (focusout)="addassetjob.applyFocus()" (click)="btnCancelClick(addjobForm)" label="Cancel"
                    [disabled]="isSubmitted"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>

<p-dialog *ngIf="displayFtpDlg" id="ftpLinkDlg" [(visible)]="displayFtpDlg" header="Job Created Successfully"
    showEffect="fade" [modal]="true" [resizable]="false" responsive="true" [style]="{width: '700px'}">
    <div class="upload-dialog">
        <div id="smart-form" class="smart-form dialogSettings">
            <div id="form-fields form-group">
                <div class="row">
                    <div class="col-sm-12">
                        <fieldset>
                            <label class="label">A folder {{folderName}} has been created on ftp site. Please upload all
                                documents to be processed to folder <b>{{fullPath}}</b> .
                                Once all files are copied and ready to be processed, please change the status of this
                                Job to “Ready for processing”. </label>
                            <label class="label"><b>Note: To follow a particular order in processing, files should be
                                    named in alphabetical order.</b></label>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <fieldset>
                            <label class="label">FTP Link <i class='fa fa-copy fa-lg pull-right' pTooltip="{{fullPath}}"
                                    ngxClipboard [cbContent]="fullPath" (click)='getLinkClick()'></i> <span
                                    class="text-danger"></span></label>
                            <label class="input">
                                <!-- <textarea id="txtFtpLink" type="text" style="width: 100%;"   class="input-smText readonlyinput"  name="txtFtpLink"  [disabled]="true" [(ngModel)]="fullPath" ></textarea> -->
                                <input id="txtFtpLink" type="text" name="txtFtpLink" [disabled]="true"
                                    [(ngModel)]="fullPath" style="width: 100%;" class="input-smText readonlyinput">
                            </label>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">
            <button type="button" pButton icon="fa fa-close" class="btn btn-default" label="Close"
                (click)="CloseLinkDlgClick()"></button>

        </div>
    </ng-template>
</p-dialog>