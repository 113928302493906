import { SelectItem} from 'primeng/api';

export class ViewModel {
    public ViewSettingId: string;
    public ViewName: string;
    public DisplayName: string;
    public AssetType: string;
    public DisplayOrder: number;
    public AllAsset: boolean = false;
    public availableAssets: SelectItem[];
    public selectedAssets: SelectItem[];
    public selectedDfpStatus: string[] 
    public Columns: Column[];
    public Conditions: Condition[];
    public OrConditions: Condition[];
    public ConditionsWithOutToken: Condition[];
    public SelectedConditions: Condition[];
    public DefaultSelectedConditions: Condition[];
    public NonSelectedConditions: Condition[];
    public DefaultNotSelectedConditions: Condition[];
    public OrderBy: OrderBy[];
    public GroupBy: string[];
    public Status: boolean;
    public BatchAutoSearchStatus: boolean;
    public Visible: boolean;
    public DefaultField: string;
    public CoverSheetHeader: string;
    public CoverSheetTitle: string;
    public CoverSheetContent: string;
    public CoverSheetFooter: string;
    public TextWrap: boolean = true;
    public Source: boolean = true;
    public ShowOnDashboard :boolean = true;
    public ViewType: string;
    public DefaultContainer: string;
    public PDFExportHeader: string;
    public PDFExportFooter: string;
    public ExcelAPIExport: string;
    public ExcelGridExport: string;
    public Spec2500View: string;
    public ChartConfiguration: string;
}


export class Column {
    constructor(FieldName: string, FieldLabel: string, GroupLabel: string, ColumnWidth: string, FieldOrder:number= 0, Processed:boolean=false,UniqueKey: boolean = false, HistoryKey: boolean = false,Spec2500:string, Formula:string) {
         this.FieldName = FieldName;
         this.FieldLabel = FieldLabel;
         this.GroupLabel = GroupLabel;
         this.ColumnWidth = ColumnWidth;
         this.FieldOrder = FieldOrder;
         this.Processed = false;
         this.UniqueKey = UniqueKey;
         this.HistoryKey = HistoryKey;
         this.Spec2500 = Spec2500;
         this.Formula = Formula;
    }
    public FieldName: string;
    public FieldLabel: string;
    public GroupLabel: string;
    public ColumnWidth: string;
    public FieldOrder: number;
    public Processed?: boolean;
    public UniqueKey: boolean;    
    public HistoryKey: boolean; 
    public Spec2500: string;
    public Formula: string; 
    public colId?:string; 
}

export class Condition {
    constructor(FieldName: string) {
        this.FieldName = FieldName;
    }
    public FieldName: string;
    public FieldLabel: string;
    public ConditionType: string;
    public Value: string;
    public JoinCondition: string;
}

export class OrderBy {
    constructor(FieldName: string) {
        this.FieldName = FieldName;
       
    }
    public FieldName: string;
    public Order: string;
}
