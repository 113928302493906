//#region References

//Components
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

//Services
import { SharedService } from '../../../shared/services/shared.service';

import { APIService } from '../../services/api.service';
import { Subscription } from "rxjs";

//#endregion

//#region Type Decorator
@Component({
    selector: 'feed-log-detail',
    templateUrl: './feed-log-detail.component.html',
    providers: [APIService]
})
//#endregion

export class FeedLogDetailComponent implements OnInit,OnDestroy {

    //#region Locals Declaration

    display: boolean = false;

    @Input() feedlogid: string;

    
    feedLogDetailSub: Subscription;
    currentModule:string='API';
    //#endregion

    //#region Initialization of Component
    constructor(private _sharedService: SharedService, private _router: Router, private _apiService: APIService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
       
    }
    ngOnInit() {
        this.feedLogDetailSub == this._sharedService._feedLogDetailSub
        .subscribe({
            next: (feedLogID:string) => {
                this.feedlogid = feedLogID;
                this.openLogViewDialogue();
            },
            error: (error) => {
            }
        });
    }
    ngOnDestroy() {
        if (this.feedLogDetailSub) {
            this.feedLogDetailSub.unsubscribe();
        }

    }
    //#endregion

    // #region Form Events

    openLogViewDialogue()
    {  
        // if(this._sharedService.selectedFeedRowForView && this._sharedService.selectedFeedRowForView.SourceType=='FTP')
        // {
        //     this._sharedService.clearToast();
        //     this._sharedService.showToast({ severity: 'warn', summary: 'Not Allowed', detail: `Not functional for FTP`});
        // }
        // else
        // {
            if (this.feedlogid)
            {
                this.display = true;
            }
            else
            {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'No Feed Log Selected', detail: 'Please select a row first' });
            }      
        // }
        
    } 

    btnCancelClick() {
        this.display = false;
    }
    // #endregion

    //#region Helper Methods

    //#endregion

}