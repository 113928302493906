export class AddCommModel {

    constructor(
        public CommId?: number,

        public ASN?: string,

        public Module?: string,

        public Section?: string,
        public Record?: string,
        public CommStatusId?: string,//number,

        public CommTypeId?: number,

        public Priority?: number,

        public Subject?: string,
        public Description?: string,
        public Attachment?: boolean,
        public AssignedTo?: string,
        public RequestedBy?: string,
        public DueDate?: Date,
        public CCs?: string,
        public Conversation?: string,
        public History?: string,

        public Status?: boolean,

        public CreatedBy?: string,

        public CreatedOn?: Date,

        public ModifiedBy?: string,

        public ModifiedOn?: Date,

        public CssOther:string[]=[]
    ) { }

}
