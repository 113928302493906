
<!-- MAIN PANEL -->
<div role="main"  class="well" style="height: 94vh;overflow-x: hidden; overflow-y: scroll;padding-right: 7%;padding-left: 7%;" >

  <!-- MAIN CONTENT -->
  <div id="content" style="opacity: 1;">
    <div class="">
      <div class="">
          <div class="">
              <div class="row">
                  <div class="col-sm-12">
                     <h1 style="font-size: poppins, helvetica, sans-serif ;font-size: 24px;font-weight: 400;color: rgb(24, 50, 71) !important;">
                       How can we help you today?
                     </h1>

                    </div>

                    <div class="col-sm-6">
                      <app-search-article ></app-search-article>
                    </div>

                  </div>
              </div>
          </div>
      </div>
  </div>

      <section id="widget-grid-main">
          <div id="content">
              <!-- row -->
              <div class="row">
                  <div class="col-sm-12">
                      <div class="well padding-10" style="background-color: #fff;margin-top: 20px;">
                          <div class="row">
                              <div class="col-sm-12">
                                <div style="border-bottom: 1px solid green;">

                              <h1  style="font-size: poppins, helvetica, sans-serif ;font-size:22px;font-weight: 600;height: 26px;color:rgb(24,50,71) !important;">
                                    <!-- <i class="fa fa-television"></i>  -->
                                    {{Name}}</h1>
                              <!--   <h2 class="page-title txt-color-blueDark " style="font-size: 30px;margin-bottom:2px !important;"> {{Name}} </h2> -->
                              <!-- <h2 style="padding-bottom: 0.5em;font-size: poppins, helvetica, sans-serif !important;;font-size:18px;font-weight: 400;color:rgb(127,136,146) !important;margin-bottom: 0 !important;">ROAM  Help Center</h2> -->
                                 <!-- <hr style="color:green !important" /> -->

                                </div>
                               <div class="row" *ngIf="Sections!=null && Sections!=undefined" style="display: flex !important; flex-wrap: wrap !important;">

                                <div class="col-sm-12"  style="display: flex">

                                  <div class="col-sm-6" style="flex: 1;">
                                    <div *ngFor="let section of LeftSideSections; let i = index">
                                      <div style="height:auto !important;">
                                         <h3  (click)="redirect(section.ArticleSectionId)" style="margin-bottom: 0;"  >
                                        <a href="javascript:void(0);"  style="font-size: poppins, helvetica, sans-serif !important;;font-size:16px;font-weight: 600;color:rgb(11,23,33) !important; " >    {{section.Name}} </a>
                                        </h3>


                                        <ul *ngFor="let  article of section.Articles;" style="list-style-type:none;margin-bottom: 3px !important;padding-left: 0 !important;" >
                                        <li style="margin-top: 0.7em;"> <span class="fa fa-files-o"> </span>
                                          <a href="javascript:void(0);"  (click)="redirectToArticle(article.ArticleId,i)" class="helpArticles"> {{article.Title}}</a>
                                        </li>

                                      </ul>
                                    </div>
                                   </div>
                                  </div>
                                  
                                  <div class="col-sm-6" style="margin-right: 20px;">
                                    <div *ngFor="let section of RightSideSections; let i = index">
                                      <div style="height:auto !important;">
                                         <h3  (click)="redirect(section.ArticleSectionId)" style="margin-bottom: 0;"  >
                                        <a href="javascript:void(0);"  style="font-size: poppins, helvetica, sans-serif !important;;font-size:16px;font-weight: 600;color:rgb(11,23,33) !important; " >    {{section.Name}} </a>
                                        </h3>


                                        <ul *ngFor="let  article of section.Articles;" style="list-style-type:none;margin-bottom: 3px !important;padding-left: 0 !important;" >
                                        <li style="margin-top: 0.7em;"> <span class="fa fa-files-o"> </span>
                                          <a href="javascript:void(0);"  (click)="redirectToArticle(article.ArticleId,i)" class="helpArticles"> {{article.Title}}</a>
                                        </li>

                                      </ul>
                                    </div>
                                   </div>
                                  </div>
                                  
                                </div>

                                 
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
</div>


