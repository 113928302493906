//Components
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { GridOptions } from "ag-grid-community";

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { SelectItem } from 'primeng/api';
import { UserGroupService } from '../../services/user-groups/user-groups.service';
import { UserGroupModel, OperationMode } from './user-groups';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
var _router_: Router;
@Component({
    selector: 'user-groups',
    templateUrl: './user-groups.component.html',
    providers: [UserGroupService]
})

export class UserGroupComponent implements OnInit, OnDestroy {
    display: boolean = false;
    public gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[]=[];

    public canAddEditUserGroup: boolean = false;

    selectedUserGroupID: string = null;
    
    public totalCount: number;
    public model: UserGroupModel;
    public isSubmitted: boolean = false;
    userGroupList: SelectItem[] = [];
    public dialogTitle: string = 'Add Team';
    public lblsubmitButton: string = 'Add';
    public operationMode: OperationMode = 1;

    public appHotkeys:  Hotkey[] = [];
    
    //#region Initialization of Component
    constructor(private _sharedService: SharedService, private _router: Router, private _userGroupService: UserGroupService, private _hotkeysService: HotkeysService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.UpdateRoles();            
            _router_ = this._router;
            this.setHotKeys();
            this.navigateToNextCell=this.navigateToNextCell.bind(this);
        }
    }
    setHotKeys() {

        let hotKeyObj : Hotkey = null;

        //Add user group
        hotKeyObj = new Hotkey('ctrl+a', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/usergroup")
            {
                this.btnAddClick();
            }
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

        
        //Edit user group
        hotKeyObj = new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/usergroup")
            {
                this.btnEditClick();
            }
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
        hotKeyObj = new Hotkey('enter', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/usergroup")
            {
                this.btnEditClick();
            }
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

    }
    ngOnInit() {
        this.model = new UserGroupModel();
        if (this.canAddEditUserGroup) {
            this.gridOptions = <GridOptions>
                {
                    rowSelection: 'single',

                    onGridReady: () => {
                        this.setUserGridColumns();
                        try {
                            this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridUserGroup'));
                            this.gridOptions.api.hideOverlay();
                        } catch (e) {
                            console.log(e);
                        }
                    },
                    //suppressContextMenu: true,
                    pagination: true,
                    paginationPageSize: 20,
                    getContextMenuItems: this.getCustomContextMenuItems,
                    context: {
                        gridContext:this
                    },
                    defaultColDef: {
                        sortable: true,
                        filter:true
                    }
                };
           // this.getCategoryList();
            this.populateUserGroupGrid();
        }
    }
    ngOnDestroy() {
        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
    }

    //#endregion
    // #region Category List
    getCategoryList(): void {
        this._userGroupService.getUserGroupList(null)
        .subscribe({
            next: (response) => {
                this.userGroupList = response;
            },
            error: (error) => {
            }
        });   
    }
    //#endregion
    //#region Ag Grid Population Event
    populateUserGroupGrid(): void {

        this._userGroupService.getAllUserGroupsData(null)
        .subscribe({
            next: (response) => {
                this.gridOptions.api.sizeColumnsToFit();
                this.gridData = [];
                this.gridData = response;
                this.totalCount = response.length;
            },
            error: (error) => {
                this.gridData = [];
                this.totalCount = 0;
            }
        });
    }
    //#endregion
    //#region Set Columns of Browse Ag Grid
    setUserGridColumns(): void {
        this.columnDefs = [];
        this.columnDefs =
            [
            { headerName: 'Team Name', field: 'GroupName', menuTabs: ['filterMenuTab'], cellRenderer: this.tagsCellRenderer },              
                {
                    headerName: 'Status', field: 'Status', filter:false, suppressMenu: true, valueFormatter: function (params: any) {
                        if (params.value == true) {
                            return "Active";
                        }
                        else {
                            return "Inactive";
                        }
                    }
                }
            ];

    }
    tagsCellRenderer(params: any) {
        let html: string = '';

        if (params.value) {

            html = html + '<span class="padding-2 label" style= "background-color:' + params.data.ColorCode + ';">' + params.value + '</span>';

        }

        var eDiv = document.createElement('div');
        eDiv.innerHTML = html

        return eDiv;
    }

    //#endregion

    //#region Ag-Grid Events
    updateSelectedRow(event: any) {
        this.selectedUserGroupID = null;
        this.selectedUserGroupID = event.data.UserGroupId;
    }
    //#endregion

    //#region Edit User Events
    containerRowDoubleClicked(event: any) {
        if (this.canAddEditUserGroup)
        {
            this.selectedUserGroupID = null;
            this.selectedUserGroupID = event.data.UserGroupId;
            this.btnEditClick();
        }
    }
    btnEditClick() {
        let selectedRows = this.gridOptions.api.getSelectedRows();
            if (this.selectedUserGroupID && (selectedRows && selectedRows.length > 0)) {
            this.operationMode = 2;
            this.dialogTitle = 'Edit Team';
            this.lblsubmitButton = 'Update';

            this._userGroupService.getUserGroupByID(this.selectedUserGroupID)
            .subscribe({
                next: (response) => {
                    let data = response;
                    this.model = new UserGroupModel();
                    this.model.ColorCode = data.ColorCode;
                    this.model.UserGroupId = data.UserGroupId;
                    this.model.Status = data.Status;
                    this.model.GroupName = data.GroupName;
                    this.showHideDialogue(true);               
                },
                error: (error) => {
                    this._sharedService.clearToast();
                    this._sharedService.showToast(
                        { 
                            severity: 'error', 
                            summary: 'Error Occured', 
                            detail: 'Team not added, please try again.' 
                        }
                    );
        
                    this.showHideDialogue(false);
                }
            });
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Team Selected', detail: 'Please select a record.' });
        }
    }
    //#endregion
    //#region Add User Events
    btnAddClick() {
        this.operationMode = 1;
        this.dialogTitle = 'Add Team';
        this.lblsubmitButton = 'Add';
        this.model = new UserGroupModel();

        this.model.Status = true;
        this.model.ColorCode = '#0976e3';
        this.showHideDialogue(true);
    }
    //#endregion

    //#region User Roles Assignment
    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }

        this.canAddEditUserGroup = this._sharedService.UserRole.Admin_UserGroup;
    }
    //#endregion

    //#region Cancel Dialogue
    btnCancelClick(cancelForm: NgForm) {
        this.showHideDialogue(false);
        this.model = new UserGroupModel();
        cancelForm.resetForm();
    }
    //#endregion
    //#region Helper Methods
    showHideDialogue(flag: boolean) {
        this.display = flag;
    }
    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }
    //#endregion

    //#region form submit
    onSubmit(form: NgForm) {
        this.changeButtonsStatus(true);
        this._sharedService.showLoader("Processing...");
        if (this.operationMode == 1) {
            let body: any =
                {
                    UserGroupID: 0,
                    Status: this.model.Status,
                    GroupName: this.model.GroupName,
                    ColorCode: this.model.ColorCode
                }
            this._userGroupService.addUpdateUserGroup(body)
            .subscribe({
                next: (response) => {
                    this.showHideDialogue(false);
                    form.resetForm();
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast(
                        { 
                            severity: 'success', 
                            summary: 'Success', 
                            detail: 'Team added succesfully.' 
                        }
                    );
                    this.changeButtonsStatus(false);
                    this.populateUserGroupGrid();
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();            
                    if (error.status === 409) {
                        this._sharedService.showToast(
                            { 
                                severity: 'error', 
                                summary: 'Already Exists', 
                                detail: 'Team already exists.' 
                            }
                        );
                    }
                    else {
                        this._sharedService.showToast(
                            { 
                                severity: 'error', 
                                summary: 'Error Occured', 
                                detail: 'Team not added, please try again.' 
                            }
                        );
                    }
                    this.showHideDialogue(false);
                    this.model = new UserGroupModel();
                    form.resetForm();
                    this.changeButtonsStatus(false);
                }
            });
        }
        else if (this.operationMode == 2) {
            let body: any =
                {
                    UserGroupID: this.selectedUserGroupID,
                    Status: this.model.Status,
                    GroupName: this.model.GroupName,
                    ColorCode: this.model.ColorCode
                }
            this._userGroupService.addUpdateUserGroup(body)
            .subscribe({
                next: (response) => {
                    this.showHideDialogue(false);
                    form.resetForm();
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast(
                        { 
                            severity: 'success', 
                            summary: 'Success', 
                            detail: 'Team updated succesfully.' 
                        }
                    );
                    this.changeButtonsStatus(false);
                    this.populateUserGroupGrid();
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    if (error.status === 409) {
                        this._sharedService.showToast(
                            { 
                                severity: 'error', 
                                summary: 'Already Exists', 
                                detail: 'Team already exists.' 
                            }
                        );
                    }
                    else {
                        this._sharedService.showToast(
                            { 
                                severity: 'error', 
                                summary: 'Error Occured', 
                                detail: 'Team not updated, please try again.' 
                            }
                        );
                    }
                    this.showHideDialogue(false);
                    this.model = new UserGroupModel();
                    form.resetForm();
                    this.changeButtonsStatus(false);
                }
            });
        }



    }
    //#endregion

    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any
    {
        let result: any[] = [];
        
        if (params.context.gridContext.canAddEditUserGroup)
        {
            result.push({
                name: "Add User Group",
                action: function () {

                    params.context.gridContext.btnAddClick();
                },
                icon: params.context.gridContext._sharedService.addIconHTMLasString,
            });

            result.push({
                name: "Edit User Group",
                action: function () {
                    params.context.gridContext.selectedUserGroupID = null;
                    if (params.node) {
                        params.context.gridContext.selectedUserGroupID = params.node.data.UserGroupId;
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext.btnEditClick();
                    }
                    
                },
                icon: params.context.gridContext._sharedService.editIconHTMLasString,
            });

            let childResult: any[] = [];
            childResult.push({
                name: 'Excel',
                action: function () {
                    
                    params.api.exportDataAsExcel();
                    
                },
                icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
            });

            childResult.push({
                name: 'CSV',
                action: function () {
                    params.api.exportDataAsCsv();
                },
                icon: params.context.gridContext._sharedService.saveLogFeedIconHTMLasString,
            });

            result.push({
                name: 'Download',
                action: function () {


                },
                icon: params.context.gridContext._sharedService.downloadsFeedIconHTMLasString,
                subMenu: childResult,
            });
			
        }
            return result;     
    }
    navigateToNextCell(params: any) {
        var previousCell = params.previousCellPosition;
        var suggestedNextCell = params.nextCellPosition;
        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        switch (params.key) {
            case KEY_DOWN:
                var nextRowIndex = previousCell.rowIndex + 1;
                var renderedRowCount = this.gridOptions.api.getModel().getRowCount();
                if (nextRowIndex >= renderedRowCount) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedUserGroupID = null;
                    this.selectedUserGroupID = rowNode.data.UserGroupId;
                        return {
                        rowIndex: nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_UP:
                var _nextRowIndex = previousCell.rowIndex - 1;
                if (_nextRowIndex < 0) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedUserGroupID = null;
                    this.selectedUserGroupID = rowNode.data.UserGroupId;
                        return {
                        rowIndex: _nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw 'this will never happen, navigation is always on of the 4 keys above';
        }
    }
    //#endregion
}