<div class="jarviswidget-ctrls" role="menu">
    <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="showMoveContainerDialog()" id="btnmove"
        (click)="showMoveContainerDialog()" title="Move Container (Ctrl+J)">
        <i class="glyphicon glyphicon-move"></i>
    </a>
</div>
<form (ngSubmit)="onSubmit(moveContainerForm)" #moveContainerForm="ngForm" *ngIf="display">
    <p-dialog [(visible)]="display" showEffect="fade" [modal]="true" [style]="{height:'460px',width: '733px'}" [resizable]="false">
        <ng-template pTemplate="header"  (keyup.shift.tab)="onKey()">
            Move Container
        </ng-template>
        <div class="upload-dialog" style="height: 335px;">
            <!--style="overflow-y: hidden !important;overflow-x:hidden !important"-->
            <div class="smart-form">
                <div class="row">
                    <fieldset>
                        <label class="label">
                            Select Target Asset
                        </label>
                        <span>
                            <!-- [(ngModel)]="containerModel.asn" #asn="ngModel" -->
                            <p-dropdown appendTo="body" id="asn" name="asn" filter="filter" [options]="moveContainerAssetList"
                            placeholder="Select Asset" required 
                            (onChange)="onChangeAsset($event)">
                            <ng-template style="padding-bottom:20px;padding-left: 0px !important;" let-item
                                pTemplate="selectedItem">
                                <span>{{item.label}}</span>
                                <!-- <span style="font-size:14px;background-color:lightgreen;">{{item.AssetType}}</span> -->
                            </ng-template>
                            <ng-template style="padding-bottom:20px !important;padding-left: 0px !important;"
                                let-asset pTemplate="item">
                                <div style="padding-bottom: 20px;">
                                    <!-- <span > -->
                                    <span style="padding:0" class="col-md-8">{{asset.label}}</span>

                                    <!-- </div> -->
                                    <!-- <div class="col-md-4"> -->
                                    <span style="padding:0" class="col-md-4">
                                        <span
                                            style="font-size: 11px;padding: 4px;background-color:lightgreen;color: white;border-radius: 3px;">
                                            {{asset.AssetType}}
                                        </span>
                                    </span>

                                    <!-- </div> -->
                                </div>
                            </ng-template>
                        </p-dropdown>
                        </span>
                    </fieldset>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="submit" pButton icon="fa fa-check" class="btn btn-primary minzindex" label="Select Asset"
                    [disabled]="!moveContainerForm.form.valid || isSubmitted"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnCancelClick(moveContainerForm)" label="Cancel" [disabled]="isSubmitted"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>
