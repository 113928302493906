export class RoleGroupModel {
    constructor(
        public UserGroup?: UserRole,
        public AvailableRoles?: UserRole[],
        public SelectedRoles?: UserRole[]
     ) {
    }

    // public UserGroup: UserRole = new UserRole();
    // public AvailableRoles: UserRole[] = [];
    // public SelectedRoles: UserRole[] = [];

}

export enum OperationMode {
    Add = 1,
    Edit = 2,
    Delete = 3
}

export class UserRole{
    public  Id ?: number;
    public  ScreenName ?: string;
    public  RoleLabel ?: string;
    public  Description ?: string;
    public  Name ?: string;
    public  Type ?: string;
    public  TenantId ?: number;
    public RoleIds?: any[]
}

export class PermissionGroup{
    public  RoleId ?: number;
    public  GroupId ?: string;
}

