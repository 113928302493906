<article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable standardpadding mainArcticlePadding" style="margin-left:0px" id="col1">
    <div class="jarviswidget" id="wid-id-1" data-widget-togglebutton="false" data-widget-sortable="false" data-widget-fullscreenbutton="false" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" role="widget">

        <header role="heading">
            <span class="widget-icon"><i class="fa fa-history"></i></span>
            <h2>Audit</h2>
            <!--<div class="jarviswidget-ctrls" role="menu" >
                <a class="button-icon" id="btnSearchAudit" (click)="searchAudit()" title="Search">
                    <i class="glyphicon glyphicon-search"></i>
                </a>
            </div>-->
        </header>
        <!-- widget div-->
        <div class="widgetdiv no-padding">

            <div class="widget-body inbox-searchcriteria">
                <div>

                    <form #auditform="ngForm">
                        <div class="smart-form" id="auditScreen">
                            <div class="row">
                                <div class="form-group col-sm-2">
                                    <fieldset>
                                        <label class="label">
                                            User Name
                                        </label>
                                        <p-dropdown id="userName" name="userName" [options]="userList" [resetFilterOnHide]="true" [showClear]="true" placeholder="Choose User" filter=true  required [(ngModel)]="model.UserName" #userName="ngModel" >
                                        </p-dropdown>

                                    </fieldset>
                                </div>
                                <div class="form-group col-sm-2">
                                    <fieldset>
                                        <label class="label">
                                            Created On (From)
                                        </label>
                                        <label class="input">
                                            <i class='icon-append fa fa-calendar'></i>
                                            <p-calendar appendTo="body" class='datepicker hasDatepicker audit-datepicker' showButtonBar="true" placeholder="{{ViewDateFormat}}"  id='rangedatefrom' name="rangedatefrom" [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030" [(ngModel)]="model.FromDate" #rangedatefrom="ngModel" [dateFormat]="dateFormatCalender" [style]="{width:'100%'}">
                                            </p-calendar>

                                        </label>
                                    </fieldset>
                                </div>
                                <div class="form-group col-sm-2">
                                    <fieldset>
                                        <label class="label">
                                            Created On (To)
                                        </label>

                                        <label class="input">
                                            <i class='icon-append fa fa-calendar'></i>
                                            <p-calendar appendTo="body" class='datepicker hasDatepicker audit-datepicker' showButtonBar="true" id='rangedateto' placeholder="{{ViewDateFormat}}" name="rangedateto" [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030" [(ngModel)]="model.ToDate" #rangedateto="ngModel" [dateFormat]="dateFormatCalender" [style]="{width:'100%'}">
                                            </p-calendar>

                                        </label>
                                    </fieldset>
                                </div>
                                <div class="form-group col-sm-2">
                                    <fieldset>
                                        <label class="label">
                                            Entity Name
                                        </label>

                                        <!--<label class="input">
                                            <input id="TableName" type="text" name="TableName" class="input-sm"
                                                    [(ngModel)]="model.TableName" #tablename="ngModel" />
                                        </label>-->
                                        <p-dropdown id="TableName" name="TableName" [options]="auditTableList" (onChange)="onAuditTableChange($event)" [showClear]="true"  placeholder="Choose Entity" filter=true [resetFilterOnHide]="true"  [(ngModel)]="model.TableName" #TableName="ngModel" appendTo="body">
                                        </p-dropdown>
                                    </fieldset>
                                </div>
                               <div class="form-group col-sm-2">
                                    <fieldset>
                                        <label class="label">
                                            Field Name
                                        </label>

                                        <!--<label class="input">
                                            <input id="FieldName" type="text" name="FieldName" class="input-sm"
                                                    [(ngModel)]="model.FieldName" #fieldname="ngModel"  />
                                        </label>-->
                                        <p-dropdown id="FieldName" name="FieldName" [options]="fieldNameList" placeholder="Choose Field" [showClear]="true" filter=true [resetFilterOnHide]="true"  [(ngModel)]="model.FieldName" #FieldName="ngModel" appendTo="body">
                                        </p-dropdown>

                                    </fieldset>
                                </div>
                                <div class="form-group col-sm-1" style="padding-left: 2px; padding-right: 2px;">
                                    <fieldset>
                                        <label class="label">
                                            Search Keyword
                                        </label>

                                        <label class="input">
                                            <input style="padding-left: 2px; padding-right: 2px;" id="PK" type="text" name="PK" class="input-sm"
                                                   [(ngModel)]="model.PK" #pk="ngModel">
                                        </label>

                                    </fieldset>
                                </div>
                                <div class="form-group col-sm-1">
                                    <fieldset>
                                        <label class="label">
                                            <br />
                                        </label>
                                        <a href="javascript:void(0);" style="padding-top: 5px;" (click)="searchAudit()" class="btn btn-primary btn-sm"><i class="fa fa-search fa-lg"></i></a>
                                        <!--<div class="input-group-btn">
                                            <button pButton class="btn btn-default btn-primary fix-search-button" icon="fa fa-search" label="Search" type="button" (click)="searchAudit()"></button>
                                        </div>-->
                                    </fieldset>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

            </div>
        </div>

        <div class="widgetdiv no-padding standardpadding">

            <!-- widget content -->
            <div class="widget-body set-widget-body-width">
                <div>
                    <fieldset>
                        <div class="form-group">
                            <div id="s1">

                                <div class="jqgridboxcontainer" > <!--*ngIf="this.gridData.length > 0"-->
                                    <ag-grid-angular id="agGridAudit" [navigateToNextCell]="navigateToNextCell"  #agGridAudit [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="gridData" sortable="true" filter=true style="width: 100% !important;" class="ag-theme-balham auditgrid">
                                    </ag-grid-angular>
                                </div>
                                <!--<div id="nojoblist" class="alert alert-block alert-warning" *ngIf="this.gridData.length == 0">

                                    <h4 class="alert-heading">Warning!</h4>
                                    No Results Found!
                                </div>-->
                            </div>

                        </div>
                    </fieldset>
                </div>

            </div>
            <!-- end widget content -->
        </div>

        <!-- end widget div -->
    </div>
    <!-- end widget -->
</article>
<audit-history></audit-history>
