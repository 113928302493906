import { Injectable } from '@angular/core';
//import { ErrorDialogService } from '../error-dialog/errordialog.service';
import { Router } from '@angular/router';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SharedService } from '../services/shared.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private _router: Router, private _sharedService: SharedService, private _oauthService: OAuthService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = localStorage.getItem('access_token');
        this.validateUser();
        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        // if (!request.headers.has('Content-Type')) {
        //     request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        // }

        // request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                    // this.errorDialogService.openDialog(event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data = {};
                data = {
                    reason: error && error.error.reason ? error.error.reason : '',
                    status: error.status
                };
                if (error.status == 401) {
                    if (!window.location.pathname.includes('/login') && !window.location.pathname.includes('/default')) {
                        localStorage.clear();
                        // Redirect to login page
                        !this._sharedService.ssoEnabled && this._router.navigate(['/login']);
                    }
                }
                //console.log('error--->>>', event);
                //this.errorDialogService.openDialog(data);
                return throwError(error);
            }));
    }

    private validateUser() {

        if (!window.location.hash.includes('access_token') && !window.location.pathname.includes('/login') && !window.location.pathname.includes('/default') && !window.location.pathname.includes('/portal') && !this.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: "error", summary: "Session has expired.", detail: "Your session has expired. Please relogin." });

        }
    }

    private isAuthUser(): boolean {
        if (!localStorage.getItem('access_token') || !localStorage.getItem('expires') || +(new Date(localStorage.getItem('expires'))) <= +(new Date())) {
            return false;
        } else {
            return true;
        }
    }
}