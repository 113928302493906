import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { SharedService } from './../../../shared/services/shared.service';
import { ArticleService } from './../../services/article/article.service';
import { Component, OnInit, AfterViewInit, SecurityContext, HostListener } from '@angular/core';
import { zip } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { articleModel } from '../articles/article';
var _router: Router;
@Component({
  selector: 'app-searched-articles',
  templateUrl: './searched-articles.component.html',
  styles: []
})
export class SearchedArticlesComponent implements OnInit {

  public searchResults: any[] = [];
  public FindArticle: any[] = [];
  public searchedKeyword = '';
  public articles: articleModel;
  public content: SafeHtml;
  public contentAsString = '';
  public articletText = '';
  public NotFound = '';

  constructor(private _article: ArticleService, private _sharedService: SharedService, private router: Router, private sanitizer: DomSanitizer, private route: ActivatedRoute) {
    if (!this._sharedService.isAuthUser()) {
      this.router.navigate(['login']);
    }
    else {
      this.updateRoles();


    }
  }

  ngOnInit() {

    this.route.params.subscribe(
      params => {
        this.searchedKeyword = params['id'];
        this.GetFilteredData(this.searchedKeyword);

      }
    );
    this.NotFound = ' \" No results found \" ';
  }

  GetSearchData() {
    this._article.currentKeywordSearch
      .subscribe({
        next: (res) => {
          this.searchedKeyword = res
        },
        error: (error) => {
        }
      });
  }

  strip_html_tags(str) {
    let size = Object.keys(str).length;
    for (let i = 0; i < size; i++) {
      if ((str[i].ArticleContentPlainText === null) || (str[i].ArticleContentPlainText === "")) {
        this.searchResults = str;

      }

      else {
        var content = str[i].ArticleContentPlainText;
        var lst = content.length;
        var getindex = content.toLowerCase().indexOf(this.searchedKeyword.toLowerCase());
        var cropText = content.substring(getindex, lst);
        var privousText = content.substring(0, getindex);
        var index = 0;
        var res = [];

        while ((index = privousText.indexOf(' ', index + 1)) != -1) {
          res.push(index);
        }

        var checkLenght = res.length;
        var previousIndex;
        var newIndex;
        if (checkLenght >= 5 && checkLenght <= lst) {
          previousIndex = (res.length) - 5;
          newIndex = res[previousIndex];

          str[i].ArticleContentPlainText = content.substring(newIndex, lst);
          this.searchResults = str;

        }

        else if (checkLenght == 4) {
          previousIndex = (res.length) - 4;
          newIndex = res[previousIndex];
          str[i].ArticleContentPlainText = content.substring(newIndex, lst);
          this.searchResults = str;
        }

        else if (checkLenght == 3) {
          previousIndex = (res.length) - 3;
          newIndex = res[previousIndex];
          str[i].ArticleContentPlainText = content.substring(newIndex, lst);
          this.searchResults = str;

        }

        else if (checkLenght == 2) {
          previousIndex = (res.length) - 2;
          newIndex = res[previousIndex];
          str[i].ArticleContentPlainText = content.substring(newIndex, lst);
          this.searchResults = str;

        }

        else {
          str[i].ArticleContentPlainText = content.substring(0, lst);
          this.searchResults = str;
        }
      }
    }
  }

  redirectArticle(ArticleId) {

    this.router.navigate(["/help/article", ArticleId]);
  }

  GetFilteredData(query) {
    this.searchResults = [];
    this._sharedService.showLoader("Processing...");
    this.searchResults = [];
    this._article.getArticlesForSearch(query)
      .subscribe({
        next: (res) => {
          this._sharedService.hideLoader();
          this.strip_html_tags(res);
        },
        error: (error) => {
          this._sharedService.hideLoader();
          console.log("Server Error !");
        }
      });
  }

  updateRoles() {
    if (!this._sharedService.UserRole) {
      this._sharedService.updateRole();
    }
  }

}
