import { TaskService } from "../../../admin/services/task/task.service";
import { OnInit, Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, Input, Output, EventEmitter, ElementRef } from "@angular/core";
import { SharedService } from "../../../shared/services/shared.service";
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { IDateFormatInfo } from "../../../shared/helpers/dateFormatInfo";
import { SelectItem, ConfirmationService } from "primeng/api";
import * as moment from 'moment/moment';
import { Subscription } from "rxjs";
import { Dropdown } from "primeng/dropdown";


var dateTimeConfig: IDateFormatInfo;

@Component({
    selector: 'taskcomponent',
    templateUrl: './task-component.html',
    providers: [TaskService],
    changeDetection: ChangeDetectionStrategy.OnPush
})


export class TaskComponent implements OnInit {
    @Input() currentViewColDef: any[];
    @Input() asn: string = '';
    @Input() currentViewId: string = "";
    @Input() feedLogId = 0;
    @Input() dfpStatusList: SelectItem[] = [];
    @Input() isInternalView: boolean = true;
    @Input() selectedTask: any[];
    @Output() addUpdateTaskItem = new EventEmitter<any>();
    canAddTask: boolean = false;
    canEditTask: boolean = false;
    formColumns: any[] = [];
    dateFormatCalender = "";
    dateTimeFormatCalender = "";
    tskForm: UntypedFormGroup = new UntypedFormGroup({});
    displayTaskDialog: boolean = false;
    taskDlgTitle: string = '';
    LatestRecord: any;
    displayConfirmDlg: boolean = false;
    editTaskSub: Subscription;
    addTaskSub: Subscription;
    ViewDateFormat: string;
    ViewDateTimeFormat: string;
    Createdby: string;
    CreatedOn: string;
    IsfooterText: boolean = false;


    @ViewChild('dDf') dDf: Dropdown;
    //#region Initialization of Component
    constructor(private _sharedService: SharedService, private confirmationService: ConfirmationService, private fb: UntypedFormBuilder, private _router: Router, private taskService: TaskService, private cRef: ChangeDetectorRef) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        dateTimeConfig = this._sharedService.getDateTimeFormatConfiguration();
    }

    ngOnInit() {
        this.UpdateRoles();
        this.setCalenderDateFormat();
        this.editTaskSub = this._sharedService._editTask
            .subscribe({
                next: (response) => {
                    this.showEditTask();
                }
            });

        this.addTaskSub = this._sharedService._addTask
            .subscribe({
                next: (response) => {
                    this.showAddTask();
                }
            });

        this.ViewDateFormat = this._sharedService.getLabelTextDateFormat();
        this.ViewDateTimeFormat = this._sharedService.getLabelTextDateFormat();

    }

    setCalenderDateFormat() {
        let dateParts: string[] = dateTimeConfig.dateformat.split(dateTimeConfig.dateSeparator);

        if (dateTimeConfig.year == 0) {
            this.dateFormatCalender = (dateParts[dateTimeConfig.year].substr(0, 2) + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day]).toLowerCase();
        }
        else if (dateTimeConfig.year == 2) {
            if (dateTimeConfig.month == 1) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
            else if (dateTimeConfig.month == 0) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
        }
        else {
            this.dateFormatCalender = "yy-mm-dd"
        }
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }

        //Add Edit Delete Task Role
        let isInStatusModule = (this._router.url.indexOf('/status/') != -1) ? true : false;
        this.canAddTask = isInStatusModule ? this._sharedService.UserRole.Status_Row_Add : this._sharedService.UserRole.Repairs_Row_Add;
        this.canEditTask = isInStatusModule ? this._sharedService.UserRole.Status_Row_Edit : this._sharedService.UserRole.Repairs_Row_Edit;
    }
    generateColumnAndFormForPopup(isAddForm: boolean = true) {
        //this.asn = this._sharedService.currentViewAsn;
        this.formColumns = [];
        this.tskForm = new UntypedFormGroup({});
        this.formColumns.push({ headerName: 'FeedLogId', field: 'FeedLogId', value: "0" });
        this.formColumns.push({ headerName: 'TaskID', field: 'TaskID', value: "0" });
        let cols = this.currentViewColDef.filter(c => c.field != "LatestRecord" && c.field != "TaskID");
        for (var i = 0; i < cols.length; i++) {
            if (cols[i].field && !isNaN(cols[i].width)) {
                //Add Parent Cols
                this.formColumns.push(cols[i]);
                this.tskForm.addControl(cols[i].field, new UntypedFormControl());
                //Set DFP Status Default Value
                if (cols[i].field == 'DFPStatus') {
                    //if (this.selectedTask.length == 1) {
                    if (isAddForm) {
                        this.tskForm.controls['DFPStatus'].setValue('New');
                    }
                    //}
                }
            } else if (cols[i].children && cols[i].children.length > 0) {
                // Adding Childrens
                for (var j = 0; j < cols[i].children.length; j++) {
                    let childCol = cols[i].children[j];
                    childCol.Group = cols[i].headerName;
                    this.formColumns.push(childCol);
                    this.tskForm.addControl(cols[i].children[j].field, new UntypedFormControl());
                }
            }
        }

        //Add Fixed Controls
        this.tskForm.addControl('ViewSettingId', new UntypedFormControl());
        this.tskForm.addControl('FeedLogId', new UntypedFormControl());
        this.tskForm.addControl('TaskID', new UntypedFormControl());
        this.tskForm.addControl('ASN', new UntypedFormControl());
        this.tskForm.addControl('LatestRecord', new UntypedFormControl());
    }

    showAddTask() {
        if (this.currentViewId) {
            if (this.tskForm) {
                this.tskForm.reset();
                this.tskForm.enable();
            }
            this.generateColumnAndFormForPopup();
            this.taskDlgTitle = "Add Entry";
            this.IsfooterText = false;
            this.displayTaskDialog = true;
            this.cRef.markForCheck();
            if (this.dDf) { this.dDf.applyFocus() };
        }
    }

    hideTaskPopup() {
        this.displayTaskDialog = false;
    }

    showEditTask() {
        this.taskDlgTitle = "Edit Entry";
        //To-Do if (this.gridData && this.gridData.length > 0 && this._sharedService.selectedTaskID) {
        // if (this._sharedService.selectedTaskCount == 1){
        if (this._sharedService.selectedTaskID) {
            this.generateColumnAndFormForPopup(false);
            let body: any = {
                TaskID: this._sharedService.selectedTaskID,
                ViewSettingID: this.currentViewId
            };

            if (this.isInternalView) {
                this.taskService.getTaskByIDForEdit(body)
                    .subscribe({
                        next: (response) => {
                            this.LatestRecord = response.TaskEntity.LatestRecord
                            // response.DFPStatus = response.TaskEntity.DFPStatus.trim();
                            if (response.TaskEntity.DFPStatus) {
                                response.DFPStatus = response.TaskEntity.DFPStatus.trim();
                            }
                            this.prepareEditDlg(response);
                        },
                        error: (error) => {
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'error', summary: 'error', detail: "Cannot get the task's details, please try again." });
                        }
                    });
            } else {
                let result = { TaskEntity: this.selectedTask[0] }
                this.prepareEditDlg(result);

            }

        }
        //}
        // else if (this._sharedService.selectedTaskCount > 1){
        //     this._sharedService.clearToast();
        //         this._sharedService.showToast({ severity: 'warn', summary: 'Multiple Records Selected', detail: 'Please select a single record to edit.' });
        // }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Records Selected', detail: 'Please select a record first!' });
        }
    }

    prepareEditDlg(response: any) {
        let result = response;
        let uniqueColumns: string[] = result.UniqueKeyColumns;
        if (this._sharedService.selectedTaskCount == 1) {
            if (result.TaskEntity) {
                this.Createdby = result.TaskEntity.CreatedBy;
                this.CreatedOn = this._sharedService.getDateFormatTimeDialogBottom(result.TaskEntity.CreatedOn);
                this.IsfooterText = true;
                if (this.tskForm) {
                    this.tskForm.enable();
                }
                for (var i = 0; i < this.formColumns.length; i++) {
                    if (result.TaskEntity[this.formColumns[i].field] || this.formColumns[i].field.indexOf("Bit") != -1) {
                        if (this.formColumns[i].field.indexOf("Date") != -1) {
                            this.tskForm.controls[this.formColumns[i].field].setValue(new Date(result.TaskEntity[this.formColumns[i].field]));

                        } else if (this.formColumns[i].field.indexOf("DFPStatus") != -1) {
                            this.tskForm.controls[this.formColumns[i].field].setValue(result.TaskEntity[this.formColumns[i].field].toString().trim());

                            //To-Do this.selectedTaskStatus = result.TaskEntity[this.formColumns[i].field].toString().trim();
                        }
                        else {
                            this.tskForm.controls[this.formColumns[i].field].setValue(result.TaskEntity[this.formColumns[i].field]);

                        }

                    }
                    if (uniqueColumns && uniqueColumns.length > 0) {
                        if (uniqueColumns.findIndex(x => x == this.formColumns[i].field) != -1) {
                            this.tskForm.controls[this.formColumns[i].field].disable();
                        }
                        //this.cd.markForCheck();
                    }
                }

                this.displayTaskDialog = true;
                this.cRef.markForCheck();
            } else {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'error', detail: "Cannot get the task's details, please try again." });
            }
        } else {

            if (uniqueColumns && uniqueColumns.length > 0) {
                for (var i = 0; i < uniqueColumns.length; i++) {
                    //if (uniqueColumns.findIndex(x => x == this.formColumns[i].field) != -1) {
                    if (this.formColumns.findIndex(c => c.field == uniqueColumns[i]) != -1) {
                        this.tskForm.controls[uniqueColumns[i]].disable();
                    }
                    //this.cd.markForCheck();
                }
            }

            this.displayTaskDialog = true;
            this.cRef.markForCheck();

        }
    }

    adjustTaskDlgHeight(event: any) {
        let tskDlgfrm = document.getElementById('tskDlgfrm');

        if (tskDlgfrm) {
            tskDlgfrm.style.height = '70vh';
            // tskDlgfrm.style.overflowY = 'scroll';
            this.setScrollPostition();
        }
    }

    // #region Scroll Position
    setScrollPostition() {
        let elmnt = document.getElementById("tskDlgfrm");
        elmnt.scrollTop = 0;
    }
    // #endregion
    addUpdateTask(historyFlag?: number) {
        this._sharedService.showLoader("Please wait...");
        //this.tskForm.controls['ASN'].setValue(this._sharedService.currentViewAsn);
        this.tskForm.controls['ASN'].setValue(this.asn);
        this.tskForm.controls['ViewSettingId'].setValue(this.currentViewId);

        //For Bulk Update
        if (this.selectedTask.length > 1) {
            this.tskForm.controls['TaskID'].setValue(this.selectedTask.map(t => t.TaskID).join(','));

        } else {
            this.tskForm.controls['FeedLogId'].setValue(this.feedLogId);
        }


        let formData = this.tskForm.getRawValue();
        for (let field in formData) {
            if (field.toString().indexOf('Date') != -1 && field.toString().indexOf('DateTime') == -1) {
                formData[field] = this.getDate(formData[field]);
            } else if (field.toString().indexOf('DateTime') >= 0) {
                formData[field] = this.getDateTime(formData[field]);
            }
        }

        //For Bulk Update
        if (this.selectedTask.length > 1) {
            formData.LatestRecord = null;

        } else {

            formData.LatestRecord = this.LatestRecord;
            //Update History Flag
            if (historyFlag) {
                formData.UpdateHistoryKeyRecord = historyFlag;
            }
        }
        this.taskService.addUpdateTask(formData)
            .subscribe({
                next: (response) => {
                    this.displayTaskRespMsg(response, formData);
                    this.cRef.markForCheck();
                    this._sharedService.hideLoader();
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    if (this.tskForm.get('TaskID').value) {
                        this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Cannot update task, please try again.' });
                    } else {
                        this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Cannot create task, please try again.' });
                    }
                }
            })

        this.displayConfirmDlg = false;

        //this.tskForm.reset();
    }

    getDate(date: Date): string {
        if (date != null) {
            let newDate = moment(date).format("YYYY-MM-DD");
            return new Date(newDate).toDateString();
        }
        return null;
    }

    getDateTime(date: Date): string {
        if (date != null) {
            let newDate = moment(date);
            return newDate.format('yyyy-MM-DD HH:mm');
            //  let newDate = moment(date).format("YYYY-MM-DD HH:mm ZZ");
            // return newDate;
        }
        return null;
    }

    displayTaskRespMsg(response: number, updatedObjData: any = null) {
        this._sharedService.clearToast();
        if (response) {
            switch (response) {

                case 1: //RecordAlreadyExist
                    this._sharedService.showToast({ severity: 'warn', summary: 'Record already exist', detail: 'Record already exist. Please edit existig record.' });
                    this.displayTaskDialog = false;
                    break;
                case 2: //HistoryKeyMatch
                    this.displayTaskDialog = false;
                    this.displayConfirmDlg = true;
                    break;
                case 3: //UpdateRecordAlreadyExist
                    this._sharedService.showToast({ severity: 'warn', summary: 'Record already exist', detail: 'Record already exist with these key columns, please update the existing record.' });
                    this.displayTaskDialog = false;
                    break;
                case 4: //RecordUpdated
                    this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Record has been updated succesfully.' });
                    if (this.selectedTask.length == 1 && this.selectedTask[0].DFPStatus.trim() != this.tskForm.controls['DFPStatus'].getRawValue())
                    this.addUpdateTaskItem.emit({ isUpdated: true, updatedObj: updatedObjData });
                    else {
                        this.addUpdateTaskItem.emit(true);
                    }
                    this.displayTaskDialog = false;
                    break;
                case 5: //RecordInserted
                    this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Record has been inserted succesfully.' });
                    this.addUpdateTaskItem.emit(true);
                    this.displayTaskDialog = false;
                    break;
                case 6: //MappingDoesNotExist
                    this._sharedService.showToast({ severity: 'error', summary: 'Mapping does not exist', detail: 'Mapping does not exist for this View.' });
                    this.addUpdateTaskItem.emit(true);
                    this.displayTaskDialog = false;
                    break;
                case 7: //NoInsertUpdate
                    //this._sharedService.showToast({ severity: 'warn', summary: 'Record already exist', detail: 'Record already exist. Please edit existig record.' });
                    break;
            }
        }
    }

    ngOnDestroy() {
        if (this.editTaskSub) {
            this.editTaskSub.unsubscribe();
        }
        if (this.addTaskSub) {
            this.addTaskSub.unsubscribe();
        }
    }

    applyFocusToInput() {

        if (this.dDf) { this.dDf.applyFocus() };
    }

}
