<a class="button-icon" href="javascript:void(0);" title="Upload From Computer (Ctrl+U)" (click)="OpenBulkModal();">
    <i class="glyphicon glyphicon-open"></i>
</a>


<p-confirmDialog *ngIf="isDisplayBulkDialog" header="Duplicate Files" 
    [style]="{width: '435px'}"></p-confirmDialog>


<!--name="frmBulkUpload"-->
<p-dialog *ngIf="isDisplayBulkDialog" [(visible)]="isDisplayBulkDialog" appendTo="body" id="bulkUpload"
    showEffect="fade" [modal]="true" [style]="{width: '52.9vw'}" closable="false">
    <!--  width: '700px' -->
    <ng-template pTemplate="header" >
        Upload From Computer
    </ng-template>

    <!--  -->
    <div class="upload-dialog" style="height:500px; overflow-y:scroll;">
        <div class="alert alert-danger fade in" style="display: none">
            <button class="close" data-dismiss="alert">×</button>
            <h4 class="alert-heading"><i class="fa-fw fa fa-times"></i>Error!</h4>
            <p id="errorMsg"></p>
        </div>
        <div id="smart-form" class="smart-form">
            <div id="form-fields">
                <form #containerForm="ngForm" *ngIf="isDisplayBulkDialog">

                    <div class="row">
                        <div class="form-group col-sm-5 fix-Padding">
                            <fieldset>
                                <label class="label">Asset
                                    <span class="text-danger">*</span>
                                </label>

                                <p-dropdown appendTo="body" id="asn" name="asn" [options]="assets" placeholder="Select Asset" required
                                    (onChange)="onChangeAsset($event)" [(ngModel)]="containerModel.asn" #asn="ngModel"
                                    [filter]="true" #asnuploadfilter [resetFilterOnHide]="true">
                                    <ng-template style="padding-bottom:20px;padding-left: 0px !important;" let-item
                                        pTemplate="selectedItem">
                                        <span>{{item.label}}</span>
                                        <!-- <span style="font-size:14px;background-color:lightgreen;">{{item.AssetType}}</span> -->
                                    </ng-template>
                                    <ng-template style="padding-bottom:20px !important;padding-left: 0px !important;"
                                        let-asset pTemplate="item">
                                        <div style="padding-bottom: 20px;">
                                            <!-- <span > -->
                                            <span style="padding:0" class="col-md-8">{{asset.label}}</span>

                                            <!-- </div> -->
                                            <!-- <div class="col-md-4"> -->
                                            <span style="padding:0" class="col-md-4">
                                                <span
                                                    style="font-size: 11px;padding: 4px; background-color:lightgreen;color: white;border-radius: 4px;">
                                                    {{asset.AssetType}}
                                                </span>
                                            </span>

                                            <!-- </div> -->
                                        </div>
                                    </ng-template>
                                </p-dropdown>

                                <!-- <p-dropdown appendTo="body" id="asn" name="asn" [options]="assets" placeholder="Select Asset" required (onChange)="onChangeAsset($event)" [(ngModel)]="containerModel.asn" #asn="ngModel" [filter]="true" #asnuploadfilter [resetFilterOnHide]="true">
                                </p-dropdown> -->
                            </fieldset>
                        </div>
                        <div class="form-group col-sm-5 fix-Padding">
                            <fieldset>
                                <label class="label">Category
                                    <span class="text-danger">*</span>
                                </label>
                                <p-dropdown appendTo="body" id="category" name="category" [options]="categories"
                                    (onChange)="onChangeCategory($event)" placeholder="Select Category"
                                    [(ngModel)]="containerModel.category" #category="ngModel">
                                </p-dropdown>
                            </fieldset>
                        </div>
                        <div class="form-group col-sm-2">
                            <fieldset class="pull-right" style="margin-top: 20px;">
                                <label class="label"></label>
                                <button pButton type="button" (click)="populateContainerGrid()" icon="fa fa-search"
                                    label="Search" [disabled]="!containerForm.form.valid"></button>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 5px;">
                        <div class="form-group col-sm-12">
                            <ag-grid-angular #containerGrid [gridOptions]="conGridOptions" [columnDefs]="conColumnDefs"
                                [rowData]="conGridData" (componentStateChanged)="formatContainerGrid($event)"
                                enableFiltering sortable="true" style="width: 100% !important; height:180px !important;"
                                class="ag-theme-balham" (rowClicked)="onContainerRowClick($event)">
                            </ag-grid-angular>
                        </div>
                    </div>
                </form>
                <form #bulkUploadForm="ngForm">
                    <div class="row">
                        <div class="form-group col-sm-6 upload-computer-ddl" *ngIf="
                        (canGENQualityStatus && processGENQualityStatusEnabled) ||
                        (canMXRQualityStatus && processMXRQualityStatusEnabled) ||
                        (canLOGQualityStatus && processLOGQualityStatusEnabled) ||
                        (canDOCQualityStatus && processDOCQualityStatusEnabled) ||
                        (canBNDQualityStatus && processBNDQualityStatusEnabled)
                      ">
                            <fieldset>
                                <label class="label">
                                    <!-- Status -->
                                    {{lbl_Status}}
                                    <span [hidden]="(recordType=='DOC' || recordType=='BND')" class="text-danger">
                                        *
                                    </span>
                                </label>
                                <span>
                                    <p-dropdown appendTo="body" [options]="qualityStatusList" requried name="qualityStatus"
                                        #qualityStatus="ngModel" placeholder="Select Quality Status"
                                        [(ngModel)]="metaDataBulk.Status" [filter]="true"></p-dropdown>
                                </span>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-6 upload-computer-ddl">
                            <fieldset>
                                <label class="label">
                                    <!-- Document Type -->
                                    {{lbl_DocType}}
                                    <span [hidden]="(recordType=='DOC' || recordType=='BND')" class="text-danger">
                                        *
                                    </span>
                                </label>
                                <p-dropdown appendTo="body" [options]="DocType" requried name="docType" #docType="ngModel"
                                    [(ngModel)]="metaDataBulk.DocType" [filter]="true"
                                    placeholder="Select Document Type"></p-dropdown>
                            </fieldset>
                        </div>
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">
                                    <!-- Document Date  -->
                                    {{lbl_DocDate}}
                                    <span [hidden]="(recordType=='DOC' || recordType=='BND')" class="text-danger">
                                        *
                                    </span> ({{ViewDateFormat}})
                                </label>
                                <label class="input"
                                    [ngClass]="{'readonlyinput': parentWoNumber != '' && containerModel.category=='MXR'}">
                                    <i class="icon-append fa fa-calendar"></i>
                                    <p-calendar appendTo="body" placeholder="{{ViewDateFormat}}" class='datepicker hasDatepicker'
                                        id='docDate' name="docDate" [monthNavigator]="true" [yearNavigator]="true"
                                        yearRange="1950:2030" requried
                                        [disabled]="parentWoNumber != '' && containerModel.category=='MXR'"
                                        [(ngModel)]="metaDataBulk.DocDate" #docDate="ngModel"
                                        [dateFormat]="dateFormatCalender" [style]="{width:300}">
                                    </p-calendar>
                                </label>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <fieldset>
                                <label class="label">
                                    <!-- Document Number  -->
                                    {{lbl_DocNo}}
                                </label>
                                <label class="checkbox">
                                    <span class="onoffswitch">
                                        <input type="checkbox" name="docNumberSwitch1" class="onoffswitch-checkbox"
                                            checked="checked" id="docNumberSwitch1"
                                            [(ngModel)]="metaDataBulk.docNumberSwitch" #docNumberSwitch1="ngModel">
                                        <label class="onoffswitch-label" for="docNumberSwitch1">
                                            <span class="onoffswitch-inner" data-swchon-text="YES"
                                                data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span>
                                        </label>
                                    </span>
                                </label>
                                <label class="label">Files should be named as document number, if file name contains
                                    extra characters please specify the text to be removed for document number (use ","
                                    for multiple values)</label>
                            </fieldset>

                            <fieldset>
                                <label class="input rgxErr">
                                    <i *ngIf="docNo.value && !docNo.valid && (docNo.touched || docNo.dirty )"
                                        class="icon-append fa fa-ban"
                                        title="{{_sharedService.error_rgx_pre}} {{_sharedService.re_DocumentNumber_Message}} {{_sharedService.error_rgx_post}}">
                                    </i>
                                    <input id="docNumber" type="text" tabindex="6" class="input-smText"
                                        [disabled]="metaDataBulk.docNumberSwitch==0"
                                        [ngClass]="{'readonlyinput2': metaDataBulk.docNumberSwitch==0}"
                                        [(ngModel)]="metaDataBulk.DocNo" requried name="docNo" #docNo="ngModel"
                                        [pattern]="_sharedService.re_DocumentNumber">
                                </label>
                            </fieldset>
                        </div>

                    </div>
                    <div class="row">
                        <div class="form-group col-sm-6" *ngIf="recordType == 'MXR'">
                            <fieldset>
                                <label class="label">
                                    <!-- {{referenceKeyLabel}}  -->
                                    {{lbl_ReferenceKey}}
                                    <span [hidden]="(recordType=='DOC' || recordType=='BND')" class="text-danger">
                                        *
                                    </span>
                                </label>
                                <label class="input rgxErr">
                                    <i *ngIf="woNumber.value && !woNumber.valid && (woNumber.touched || woNumber.dirty )"
                                        class="icon-append fa fa-ban"
                                        title="{{_sharedService.error_rgx_pre}} {{_sharedService.re_WorkOrderNumber_Message}} {{_sharedService.error_rgx_post}}">
                                    </i>
                                    <input id="woNumber" requried name="woNumber" #woNumber="ngModel"
                                        [(ngModel)]="metaDataBulk.ReferenceKey" [disabled]="parentWoNumber != ''"
                                        [ngClass]="{'readonlyinput2': parentWoNumber != ''}" type="text" tabindex="6"
                                        [pattern]="_sharedService.re_WorkOrderNumber" class="input-smText">
                                </label>
                            </fieldset>
                        </div>
                        <!--<div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">OCR</label>
                                <label class="checkbox">
                                    <span class="onoffswitch">
                                        <input type="checkbox" name="ocr1"
                                               class="onoffswitch-checkbox" checked="checked" id="ocr1" [(ngModel)]="metaDataBulk.OCR" #ocr1="ngModel">
                                        <label class="onoffswitch-label"
                                               for="ocr1">
                                            <span class="onoffswitch-inner" data-swchon-text="YES" data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span>
                                        </label>
                                    </span>
                                </label>
                            </fieldset>
                        </div>-->
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <fieldset>                                <div class="row">
                                    <div class="col-md-6">
                                        <p-selectButton id="filetype" name="filetype" [options]="FileTypeList"
                                        (onChange)="onChangeDocTypeBulk($event)" [(ngModel)]="metaDataBulk.FileType">
                                        </p-selectButton>
                                    </div>
                                    <div class="col-md-6">
                                        <span *ngIf="showUploadAsImageBulk" class="onoffswitch  pull-right" style="margin-top:10px">
                                            <input type="checkbox" name="isImageSwitchBulk" class="onoffswitch-checkbox"
                                                [disabled]="disableImageLoadOption" checked="checked" id="isImageSwitchBulk"
                                                [(ngModel)]="metaDataBulk.isImageSwitch" #IsImage="ngModel">
                                            <label class="onoffswitch-label" for="isImageSwitchBulk">
                                                <span class="onoffswitch-inner" data-swchon-text="YES"
                                                    data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span>
                                            </label>
                                        </span>
                                        <span *ngIf="showUploadAsImageBulk" class="pull-right"
                                            style="padding-top: 8px;color: #333;">Convert to PDF</span>
                                    </div>
                                </div>
                                <p-fileUpload name="bulkFileUpload" *ngIf="isuploadBulkDoc"
                                    (onSelect)="onSelect($event)" (onRemove)="onRemove($event)"
                                    accept={{acceptFileTypes}} multiple="multiple" customUpload="true"
                                    uploadHandler="onBulkUpload($event)" chooseLabel="Browse" [(ngModel)]="bulkFile"
                                    #bulkFileUpload ngDefaultControl>
                                </p-fileUpload>
                                <!-- accept=".pdf,.bmp,.dcx,.pcx,.png,.jpg,.jpeg,.jp2,.jpc,.jfif,.tif,.tiff,.gif,.djvu,.djv,.jb2,.mp3,.wav,.ogg,.mp4,.webm" -->
                                <!-- accept=".pdf,.bmp,.dcx,.pcx,.png,.jpg,.jpeg,.jp2,.jpc,.jfif,.tif,.tiff,.gif,.djvu,.djv,.jb2" -->
                                <div *ngIf="!isuploadBulkDoc">
                                    <input id="bulkUrl" #bulkUrl
                                        style="width:76%;height:28px;margin-right:2px;margin-bottom: 2px;"
                                        name="bulkUrl" type="text" tabindex="6" class="input-smText">
                                    <button type="button" (click)="addbulkUrl()" pButton
                                        style="height: 28px;margin-right:2px;vertical-align: bottom;margin-bottom: 2px;" icon="fa fa-plus" class="btn custom-button"
                                        label="Add"></button>
                                    <button type="button" *ngIf="VideoUrlList.length > 0" (click)="removeVideoUrl()"
                                        pButton style="height:28px;margin-right:2px;vertical-align: bottom;margin-bottom: 2px;" icon="fa fa-close" class="btn custom-button"
                                        label="Delete"></button>
                                    <p-listbox id="videoListbox" [style]="{width: '100%'}"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedBulkUrl"
                                        [listStyle]="{'max-height':'100px'}" [options]="VideoUrlList"></p-listbox>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>



    <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">
            <button type="button" pButton icon="fa fa-check" class="btn btn-primary" (click)="onBulkUpload($event)"
                label="Upload Document"
                [disabled]="(!bulkUploadForm.form.valid) || recordType !== 'DOC' && recordType!=='BND'&&((metaDataBulk.DocType=='') || metaDataBulk.Status=='' || metaDataBulk.Status== 0)||(recordType == 'MXR' && metaDataBulk.ReferenceKey.length==0)||isSubmitted || !showUploadButton"></button>
            <button type="button" pButton icon="fa fa-close" class="btn btn-default"
                (click)="btnBulkCancelClick(bulkUploadForm)" label="Cancel" [disabled]="isSubmitted"></button>
        </div>
    </ng-template>

</p-dialog>