//#region References
//Components
import { Component, NgModule, Pipe, PipeTransform, OnInit, Input, ViewChild, HostListener, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';
import { SelectItem } from 'primeng/api';
import { EditCommModel, ConversationList } from './edit-comm';
import { comm } from '../comm/comm';
import { NgForm } from '@angular/forms';
import { CommService } from '../../services/comm.service';
import { UserService } from '../../../records/services/User/user.service';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
import { AzureBlobService } from '../../../shared/services/AzureBlob/azure-blob.service';
import * as moment from 'moment/moment';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { SafeHtmlPipe } from '../../../shared/pipes/safe-html-pipe';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
var dateTimeConfig: IDateFormatInfo;
//#endregion

//#region Type Decorator
@Component({
    selector: 'edit-comm',
    templateUrl: './edit-comm.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UserService, CommService, AzureBlobService]
})
//#endregion

export class EditCommComponent implements OnInit, OnDestroy {

    //#region Locals Declaration 
    FloatDirection:string='right';
    commdisplay: boolean = false;
    isDataChanged: boolean = false;
    isSubmitted: boolean = false;
    onDashboad: boolean = false;
    public canEditComm: boolean = false;

    @Input() recordType: string;
    @Input() commId: string;
    @ViewChild('editcommbulkupload') selectedBulkFiles: any;
    priorityList: SelectItem[] = [];
    commStatusList: SelectItem[] = [];
    requestedByList: SelectItem[] = [];
    assignedToList: SelectItem[] = [];
    commTypeList: SelectItem[] = [];
    emailCcsList: any[] = [];
    attachedFiles: string[] = [];
    orignalAttachedFiles: string[] = [];
    public bulkFile: any[] = [];
    //selectedCCs: string[] = [];
    selectedCCsTemp: any[] = [];
    uploadedFiles: any[] = []


    private editCommSub: Subscription;
    private editCommByIDSub: Subscription;
    model: EditCommModel;
    orignalModel: EditCommModel;

    dateFormatCalender = "";
    dialogTitle: string = "";
    userType: string = "";
    uploadUrl: string;
    oldConversation: string = "";
    allConversations: ConversationList;
    showEditComm: boolean = true;
    isViewable: boolean = false;

    public appHotkeys: Hotkey[] = [];

    CreatedOn: string;
    ModifiedOn: string;
    ViewDateFormat: string;

    @HostListener('window:resize') onResize() {
        try {
            let tskDlgfrm = document.getElementById('commDlg');
            if (tskDlgfrm) {
                var tskDlgfrmOuter = document.getElementById("edititemdlg").children[0] as HTMLElement;
                tskDlgfrmOuter.style.height = "99vh";
                tskDlgfrm.style.height = '82vh';
                tskDlgfrm.style.overflowY = 'hidden !important';
                this.cRef.markForCheck();
            }
        } catch (error) {
            console.log(error);
        }
    }
    dropdownSettings = {};
    //#endregion

    //#region Initialization of Component
    constructor(private _sharedService: SharedService, private cRef: ChangeDetectorRef, private _router: Router, private _userService: UserService, private _commService: CommService, private _azureBlobService: AzureBlobService, private _hotkeysService: HotkeysService) {

        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this._sharedService.getDateTimeFormatConfiguration();
        }
        this.setHotKeys();
    }
    setHotKeys() {

        let hotKeyObj: Hotkey = null;

        //Edit Inbox-Comm

        hotKeyObj = new Hotkey('ctrl+shift+e', (event: KeyboardEvent): boolean => {
            if(this._router.url.indexOf('/dashboard/')== -1)
            {
                this.showEditDialog();
                return false; // Prevent bubbling
            } // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
        hotKeyObj = new Hotkey('enter', (event: KeyboardEvent): boolean => {
            if(this._router.url.indexOf('/dashboard/')== -1)
            {
                this.showEditDialog();
                return false; // Prevent bubbling
            }
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

    }
    ngOnInit() {
        if(this._router.url.indexOf('/dashboard/')!=-1)
        this.FloatDirection='left';
        this.multiSelectDropdownSettings();
        this.initializeModel();
        dateTimeConfig = this._sharedService.getDateTimeFormatConfigFromLocalStorage();
        this.editCommSub = this._sharedService._editComm
        .subscribe({
            next: (param:any) => {
                if (this._router.url.indexOf("/record") != -1 || this._router.url.indexOf("/dashboard/") != -1) {
                    this.commId = param;
                    this.onDashboad = true;
                }
        
                this.showEditDialog();
            },
            error: (error) => {
            }
        });

        let subString = this._router.url.substring(this._router.url.length - 6, this._router.url.length);

        if (subString == "record" || this._router.url.indexOf("/dashboard/") != -1) {
            this.showEditComm = false;
        }
        else {
            this.showEditComm = true;
        }

        this.editCommByIDSub = this._sharedService._editCommById
        .subscribe({
            next: (CommID:string) => {
                this.commId = CommID;
                this.showEditDialog();
            },
            error: (error) => {
            }
        });
        this.canEditComm = this._sharedService.UserRole.Comm_Edit;

    }

    multiSelectDropdownSettings(): void {
        this.dropdownSettings = {
            singleSelection: false,
            text: "Select Users",
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true,
            addNewItemOnFilter: true,
            labelKey: 'label',
            primaryKey: 'value'
        };

    }
    onAddItem(data: string) {
        this.emailCcsList.push({ label: data, value: data });
        this.selectedCCsTemp.push({ label: data, value: data });
    }
    //#endregion
    //#region Destroy
    ngOnDestroy() {
        if (this.editCommSub) {
            this.editCommSub.unsubscribe();
        }
        if (this.editCommByIDSub) {
            this.editCommByIDSub.unsubscribe();
        }

        //console.log('destroying component =>' + this._router.url);
        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
    }
    //#endregion
    // #region Form Events
    adjustComDlgHeight(event: any) {
        let tskDlgfrm = document.getElementById('commDlg');
        let tskDlgfrmOuter = document.getElementById('edititemdlg');
        if (tskDlgfrm) {
            tskDlgfrmOuter.style.height = '99vh';
            tskDlgfrm.style.height = '82vh';
            tskDlgfrm.style.overflowY = 'hiddent !important';
        }
    }
    showEditDialog() {

        if (this.commId != "") {
            this.clearSelectedFiles();
            this.initializeModel();
            this.setCalenderDateFormat();
            this.dialogTitle = "Edit Item";
            this.setDefaultComponentValues();
            this.getSelectedItemData();
            //this.display = true;
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Item Selected', detail: 'Please select a row first' });
        }
    }
    btnCancelClick(cancelForm: NgForm) {
        this.clearSelectedFiles();
        this.commdisplay = false;
        this.initializeModel();
        this.initializeEmptyModel();
        cancelForm.resetForm();
        this.clearSelectedFiles();
    }

    getSelectedItemData() {
        let body: comm =
        {
            CommId: this.commId
        }
        this._commService.getItemByID(body)
        .subscribe({
            next: (response) => {
                let result = response;
                this.fillModelValues(result);
                this.cRef.markForCheck();
            },
            error: (error) => {
            }
        });
        this.attachedFiles = [];
        let file: comm =
        {
            CommId: this.commId
        }
        this._commService.getAttachmentByID(body)
        .subscribe({
        next: (response) => {
            let temp = response;
            this.orignalAttachedFiles = [];
        
            if (temp != null && temp.length > 0) {
                for (var i = 0; i < temp.length; i++) {
                    this.orignalAttachedFiles.push(decodeURIComponent(temp[i]));
                    this.attachedFiles.push(decodeURIComponent(temp[i]));
                }
            }
            this.cRef.markForCheck();
		},
        error: (error) => {
		}
    });
    }


    getDateForHistory(date: Date): string {
        if (date != null) {
            let typeCheck = date;
            if (typeCheck.toString().length == 10) {
                let dateString = date.toString().substring(0, 10);
                let dateParts = dateString.split(dateTimeConfig.dateSeparator);
                let day = Number(dateParts[Number(dateTimeConfig.day)].substring(0, 2));
                let month = Number(dateParts[Number(dateTimeConfig.month)]) - 1;
                let year = Number(dateParts[Number(dateTimeConfig.year)]);
                return new Date(year, month, day).toDateString();
            }
            else {
                let newDate = moment(date).format("YYYY-MM-DD");
                return newDate;
            }
        }
        return null;
    }
    fillModelValues(result: any) {

        this.oldConversation = result.Conversation;
        this.fillConversation(result.Conversation);
        this.model.AssignedTo = this.fillAssignedTo(result.AssignedTo);
        this.model.RequestedBy = result.RequestedBy;
        this.model.CCs = result.CCs ? result.CCs.split(';') : [];
        //this.selectedCCs = result.CCs ? result.CCs.split(';') : [];
        this.fillEmailCcObject(result);
        this.model.CssOther = result.CssOther ? result.CssOther.split(';') : [];
        this.model.History = result.History;
        if (result.CommStatusId)
            this.model.CommStatusId = result.CommStatusId.toString();
        if (result.CommTypeId)
            this.model.CommTypeId = result.CommTypeId.toString();
        this.model.Conversation = null;
        this.model.Description = result.Description;
        this.model.Priority = result.Priority;
        this.model.DueDate = this.getFormatedDate(result.DueDate);
        this.model.ASN = result.ASN;
        this.model.Module = result.Module;
        this.model.Record = result.Record;
        this.model.Section = result.Section;
        this.model.Attachment = result.Attachment;
        this.model.Subject = result.Subject;
        this.model.CommId = this.commId;
        this.CreatedOn = this.getDateFormatTime(result.CreatedOn);
        this.model.CreatedBy = result.CreatedBy;
        this.ModifiedOn = this.getDateFormatTime(result.ModifiedOn);
        this.model.ModifiedBy = result.ModifiedBy;


        this.commdisplay = true;
        this.cRef.markForCheck();
        if (result && result.ASN != null && result.Record != null && result.Module != null && this._router.url.indexOf("record") == -1 && this._router.url.indexOf("status") == -1) {
            this.isViewable = true;
        }
        else {
            this.isViewable = false;
        }
        this.orignalModel = new EditCommModel(this.commId, this.model.ASN, this.model.Module, this.model.Section, this.model.Record,
            this.model.CommStatusId, this.model.CommTypeId, this.model.Priority, this.model.Subject, this.model.Description, this.model.Attachment, this.model.AssignedTo, this.model.RequestedBy, this.model.DueDate, result.CCs ? result.CCs.split(';') : [], this.model.Conversation, this.model.History, this.model.Status, this.model.CreatedBy, this.model.CreatedOn);
    }

    fillEmailCcObject(result: any) {
        this.selectedCCsTemp = [];
        this.selectedCCsTemp = result.CCs ? result.CCs.split(';') : [];
        // let ccEmailList: string[] = result.CCs ? result.CCs.split(';') : [];
        // if (ccEmailList) {
        //     for (var i = 0; i < ccEmailList.length; i++) {
        //         let emailFound = this.emailCcsList.findIndex(x => x.value == ccEmailList[i]);
        //         if (emailFound == -1) {
        //             this.emailCcsList.push({ label: ccEmailList[i], value: ccEmailList[i] });
        //         }
        //         else {
        //             var emailCcs = this.emailCcsList.find(x => x.value == ccEmailList[i])
        //             this.selectedCCsTemp.push({ label: emailCcs.label, value: ccEmailList[i] });
        //         }
        //         // this.selectedCCsTemp.push({label:ccEmailList[i],value:ccEmailList[i]});
        //     }
        // }

    }
    fillAssignedTo(assigned: string): string {
        if (assigned && assigned != null) {
            try {
                if (assigned.indexOf('||') != -1) {
                    assigned = assigned.split('||')[0];
                }
                let user = this.assignedToList.filter(x => x.value.split('||')[0] == assigned);
                return user[0] ? user[0].value : null;
            } catch (e) {
                console.log(e);
                return null;
            }

        }
        return null;
    }
    fillConversation(conversation: string) {
        this.allConversations = new ConversationList();
        this.allConversations.Conversation = [];
        if (conversation != '' && conversation != null) {
            let allConvList = [];
            allConvList = conversation.split("?|?");
            if (allConvList.length > 0) {
                for (var i = 0; i < allConvList.length; i++) {
                    let eachCon = [];
                    eachCon = allConvList[i].split("||");
                    if (eachCon.length > 1) {
                        this.allConversations.Conversation.push({ Name: eachCon[0], Comment: eachCon[2], Date: moment(eachCon[1].replace('via Email','')).format(dateTimeConfig.dateformat.toUpperCase() + " h:mm a").toString() });
                    }

                }
            }

        }

    }
    getFormatedDate(date?: any): Date {
        if (date != null) {
            let dateString = date.substr(0, 10);
            let dateParts = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).split(dateTimeConfig.dateSeparator);
            let day = Number(dateParts[Number(dateTimeConfig.day)].substring(0, 2));
            let month = Number(dateParts[Number(dateTimeConfig.month)]) - 1;
            let year = Number(dateParts[Number(dateTimeConfig.year)]);
            let cellDate = new Date(year, month, day);
            return cellDate;
        }
        else {
            return null;
        }
    }
    getDateFormatTime(date: any) {

        if (date) {

            var dateConfig = this._sharedService.getDateTimeFormatConfiguration();
            this.ViewDateFormat = dateConfig.dateformat;
            return moment.utc(date).format(dateConfig.timeformat + "  " + dateConfig.dateformat.toUpperCase()).toString();
        } else {
            return '';
        }
    }
    //#endregion

    //#region AG Grid Date Comparer
    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).split(dateTimeConfig.dateSeparator);
            var day = Number(dateParts[Number(dateTimeConfig.day)].substring(0, 2));
            var month = Number(dateParts[Number(dateTimeConfig.month)]) - 1;
            var year = Number(dateParts[Number(dateTimeConfig.year)]);

            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        }
        else {
            return -1;
        }
    }
    //#endregion

    // #region Priority List
    populatePriortityList() {
        this.priorityList = this._commService.getPriorityList();
    }
    //#endregion

    //#region Comm TYpe
    populateCommType() {
        this._commService.getAllCommType()
        .subscribe({
            next: (response) => {
                this.commTypeList = response;
                this.cRef.markForCheck();
            },
            error: (error) => {
            }
        });
    }
    //#endregion

    //#region File Uploader Events

    removeAttachedFile(file: any) {
        for (let i = 0; i < this.attachedFiles.length; i++) {
            if (this.attachedFiles[i] == file) {
                this.attachedFiles.splice(i, 1);
                break;
            }
        }

    }

    onSelect(event: any) {
        for (let file of event.files) {

            let fileCurrent: any = this.uploadedFiles.filter(x => x.name == file.name);
            if (fileCurrent.length > 0) {
                for (let i = 0; i < this.selectedBulkFiles.files.length; i++) {
                    if (this.selectedBulkFiles.files[i].name == file.name) {
                        this.selectedBulkFiles.files.splice(i, 1);
                        break;
                    }

                }

            }
            else {

                this.uploadedFiles.push(file);

            }
        }

    }
    onRemove(event: any) {

        for (let i = 0; i < this.uploadedFiles.length; i++) {
            if (this.uploadedFiles[i].name == event.file.name) {
                this.uploadedFiles.splice(i, 1);
                break;
            }
        }

    }
    clearSelectedFiles() {
        if (this.selectedBulkFiles && this.selectedBulkFiles.files) { this.selectedBulkFiles!.files = []; }
        this.uploadedFiles = [];
    }
    onBulkUpload(event: any, editForm: NgForm) {

        this.changeButtonsStatus(true);

        let deletedfileslist: string[] = [];
        let allFileDeleted: boolean = false;
        let newUploadedFilesList: string[] = [];

        for (let i = 0; i < this.orignalAttachedFiles.length; i++) {
            let fileName = this.attachedFiles.find(x => x == this.orignalAttachedFiles[i]);
            if (!fileName) {
                deletedfileslist.push(this.orignalAttachedFiles[i]);
            }
        }
        if (this.orignalAttachedFiles.length > 0) {
            if (this.orignalAttachedFiles.length == deletedfileslist.length) {
                allFileDeleted = true;
            }
        }
        for (let j = 0; j < this.uploadedFiles.length; j++) {
            let fileNameOldRemaining = "";
            let fileNameDeleted = "";
            fileNameOldRemaining = this.attachedFiles.find(x => x == this.uploadedFiles[j].name);
            fileNameDeleted = deletedfileslist.find(x => x == this.uploadedFiles[j].name);
            if ((!fileNameOldRemaining) && (!fileNameDeleted)) {
                newUploadedFilesList.push(this.uploadedFiles[j].name);
            }
        }

        let history = this.getHistory(this.orignalModel, this.model, deletedfileslist, newUploadedFilesList);

        this.bulkFile;
        let xhr = new XMLHttpRequest(), formData = new FormData();
        for (let i = 0; i < this.uploadedFiles.length; i++) {
            formData.append("uploads['" + i + "']", this.uploadedFiles[i], this.uploadedFiles[i].name);
        }


        formData.append('IsAllFilesDeleted', String(allFileDeleted));
        formData.append('DeletedFilesList', deletedfileslist.join(';'));
        this.model.History = history ? history : null;
        this.model.CCs = this.selectedCCsTemp.join(';');
        this.model.Description = this._sharedService.filterHTMLBody(this.model.Description).replace(new RegExp("<\/p>", 'g'), "").replace(new RegExp("<p>", 'g'), "");
        let postData = this.model;
        if (postData) {
            for (let prop in postData) {
                if (prop != "DueDate") {
                    if (prop == "Conversation") {
                        formData.append(prop, this.getConversation());
                    }
                    else {
                        if (prop == "AssignedTo") {
                            if (postData.AssignedTo != null) {
                                formData.append(prop, postData[prop].split('||')[0]);
                            }
                            else {
                                formData.append(prop, postData[prop]);
                            }
                            if (postData[prop]) {
                                //this.assignedToList
                                let commAssignedNameLabel = this.assignedToList.find(x => x.value == postData[prop]).label;
                                formData.append("AssignedToName", commAssignedNameLabel);
                            }
                        }
                        else if (prop == "CssOther") {
                            formData.append(prop, postData[prop].join(';'));
                        }
                        else if (prop == "RequestedBy") {
                            formData.append(prop, postData[prop]);
                            if (postData[prop]) {
                                //let commRequestedByLabel = this.requestedByList.find(x => x.label == this._sharedService.getCurrentUserName()).label;
                                let commRequestedByLabel = this.requestedByList.find(x => x.value == postData[prop]).label;
                                formData.append("RequestedByName", commRequestedByLabel);
                            }
                        }
                        else if (prop == "CommTypeId") {
                            formData.append(prop, postData[prop].toString());
                            if (postData[prop]) {
                                //this.commTypeList
                                let commTypeLabel = this.commTypeList.filter(ct => ct.value == postData[prop])[0].label;
                                formData.append("CommTypeDescription", commTypeLabel);
                            }
                        }
                        else if (prop == "CommStatusId") {
                            formData.append(prop, postData[prop]);
                            if (postData[prop]) {
                                //this.commStatusList 
                                let commStatusLabel = this.commStatusList.filter(cs => cs.value == postData[prop])[0].label;
                                formData.append("CommStatusDescription", commStatusLabel);
                            }
                        }
                        else if (prop == "Priority") {
                            formData.append(prop, postData[prop].toString());
                            if (postData[prop]) {
                                //this.priorityList
                                let commPriorityLabel = this.priorityList.filter(cp => cp.value == postData[prop])[0].label;
                                formData.append("PriorityDescription", commPriorityLabel);
                            }
                        }
                        else {
                            formData.append(prop, postData[prop]);
                        }
                    }

                }
                else {
                    if (postData.DueDate != null) {
                        if (typeof (postData.DueDate) == "string") {
                            let dateString = postData[prop].toString().substr(0, 10);
                            let date = moment(dateString).format("YYYY-MM-DD").toString();
                            formData.append(prop, date);
                        }
                        else {
                            let date = moment(postData.DueDate).format("YYYY-MM-DD").toString();
                            formData.append(prop, date);
                        }
                    }
                    else {
                        formData.append(prop, null);
                    }
                }
            }
        }

        this._sharedService.showLoader("Processing...");
        xhr.open('POST', this.uploadUrl, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        xhr.send(formData);

        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                this._sharedService.hideLoader();
                if (xhr.status === 200) {

                    this.changeButtonsStatus(false);
                    if (this._router.url.indexOf("inbox") != -1) {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'success', summary: 'Item updated.', detail: 'Item has been updated successfully.' });
                        this.cRef.markForCheck();
                    }
                    else {
                        setTimeout(() => {
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'success', summary: 'Item updated.', detail: 'Item has been updated successfully.' });
                        }, 500);
                        if (this._router.url.indexOf("/record") != -1 || this._router.url.indexOf("/dashboard/") != -1) {
                            this._sharedService.updateDashboardComms(true);
                        }
                    }
                    this.commdisplay = false;

                    this.uploadedFiles = [];
                    this.clearSelectedFiles();

                    this._sharedService.resfreshInboxGrid(true);

                    editForm.resetForm();
                    this.initializeEmptyModel();
                    //this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                } else if (xhr.status === 400) {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'warn', summary: 'Asset is Readonly.', detail: 'Cannot update an item associated with readonly asset.' });
                }
                else {

                    if (this._router.url.indexOf("inbox") != -1) {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: JSON.parse(xhr.responseText) });
                    }
                    else {
                        //this.commdisplay = false;
                        setTimeout(() => {
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: JSON.parse(xhr.responseText) });
                        }, 500);

                    }
                    
                    //this.clearSelectedFiles();
                    this.changeButtonsStatus(false);
                    //   editForm.resetForm();
                    //this.initializeEmptyModel();
                }
            }
        };

    }
    //#endregion
    //#region check Email To And CC
    // checkEmailToAndCCList(): boolean {
    //     if (this.selectedCCs && this.selectedCCs.length > 0) {
    //         let requestedByResultIndex = this.selectedCCs.findIndex(x => x == this.model.RequestedBy);
    //         let assignedByResultIndex = -1;
    //         if (this.model.AssignedTo && this.model.AssignedTo != "") {
    //             assignedByResultIndex = this.selectedCCs.findIndex(x => x == this.model.AssignedTo.split('||')[0]);
    //         }
    //         if (requestedByResultIndex != -1 || assignedByResultIndex != -1) {
    //             return false;
    //         }
    //         else {
    //             return true;
    //         }

    //     }
    //     else {
    //         return true;
    //     }

    // }
    //#endregion
    //#region History Manipulation
    getHistory(orignalModel: EditCommModel, currentModel: EditCommModel, deletedFilesList: string[], newUploadedFilesList: string[]): string {

        let oldHistory = orignalModel.History != null ? orignalModel.History : "";
        let newHistory = '';
        let currentTimeStamp = this.getCurrentDateTime();
        if (this.model.AssignedTo != null) {
            this.model.AssignedTo = this.fillAssignedTo(this.model.AssignedTo);
        }
        for (let prop in orignalModel) {
            if (prop != "History" && prop != "CCs" && prop != "CssOther" && prop != "ModifiedBy" && prop != "ModifiedOn") {
                if (orignalModel[prop] !== currentModel[prop]) {
                    this.isDataChanged = true;

                    if (prop != "DueDate") {
                        if (prop == "CommStatusId") {
                            newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "Status" + "," + this.commStatusList.find(x => x.value == orignalModel[prop]).label + "," + this.commStatusList.find(x => x.value == currentModel[prop]).label + "\r\n" + newHistory;
                        }
                        else if (prop == "CommTypeId") {
                            newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "Type" + "," + this.commTypeList.find(x => x.value == orignalModel[prop]).label + "," + this.commTypeList.find(x => x.value == currentModel[prop]).label + "\r\n" + newHistory;
                        }
                        else if (prop == "RequestedBy") {
                            try {
                                newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "Requested By" + "," + this.requestedByList.find(x => x.value == orignalModel[prop]).label + "," + this.requestedByList.find(x => x.value == currentModel[prop]).label + "\r\n" + newHistory;
                            } catch (e) {
                                console.log(e);
                            }
                        }
                        else if (prop == "AssignedTo") {
                            try {
                                if (orignalModel[prop] == null) {
                                    newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "Assigned To" + "," + "" + "," + this.requestedByList.find(x => x.value == currentModel[prop].split('||')[0]).label + "\r\n" + newHistory;
                                }
                                else {
                                    newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "Assigned To" + "," + this.requestedByList.find(x => x.value == orignalModel[prop].split('||')[0]).label + "," + this.requestedByList.find(x => x.value == currentModel[prop].split('||')[0]).label + "\r\n" + newHistory;
                                }
                            } catch (e) {
                                console.log(e);
                            }
                        }
                        else if (prop == "Priority") {
                            if (orignalModel[prop] == null) {
                                newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "Priority" + "," + "" + "," + this.priorityList.find(x => x.value == currentModel[prop]).label + "\r\n" + newHistory;
                            }
                            else {
                                newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "Priority" + "," + this.priorityList.find(x => x.value == orignalModel[prop]).label + "," + this.priorityList.find(x => x.value == currentModel[prop]).label + "\r\n" + newHistory;
                            }
                        }

                        //else if (prop == "CCs")
                        //{

                        //   newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "CCs" + "," + this.requestedByList.find(x => x.value == orignalModel[prop].split(';')).label + "," + this.commStatusList.find(x => x.value == currentModel[prop]).label + "\r\n" + newHistory;
                        //}
                        else if (prop == "Description") {
                            if (orignalModel[prop] == null) {
                                newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "Description" + "," + "" + "," + currentModel[prop] + "\r\n" + newHistory;
                            }
                            else {
                                newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "Description" + "," + orignalModel[prop] + "," + currentModel[prop] + "\r\n" + newHistory;
                            }
                        }
                        else if (prop != "Conversation") {

                            newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + prop + "," + orignalModel[prop] + "," + currentModel[prop] + "\r\n" + newHistory;
                        }
                    }
                    else {
                        if (prop == "DueDate") {
                            if (orignalModel[prop] == null) {
                                newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "Due Date" + "," + "" + "," + this.getDateForHistory(currentModel[prop]) + "\r\n" + newHistory;
                            }
                            else {
                                newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "Due Date" + "," + this.getDateForHistory(orignalModel[prop]) + "," + this.getDateForHistory(currentModel[prop]) + "\r\n" + newHistory;
                            }

                        }
                    }
                }


            }

        }

        //if ((this.orignalAttachedFiles.length != this.attachedFiles.length) || (deletedFilesList.length > 0) || newUploadedFilesList.length>0)
        //{
        //    //newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "Attachment" + "," + this.orignalAttachedFiles.join(";") + "," + this.attachedFiles.join(";") + "\r\n" + newHistory;
        //  //  newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "Attachment" + "," + "[Attached]:" + newUploadedFilesList.join(";") + "," + " [Deleted]:" + deletedFilesList.join(";") + "\r\n" + newHistory;
        //}
        if (newUploadedFilesList.length > 0) {
            newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "[Attachment Attached]: " + newUploadedFilesList.join(";") + "\r\n" + newHistory;
        }
        if (deletedFilesList.length > 0) {
            newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + "[Attachment Deleted]: " + deletedFilesList.join(";") + "\r\n" + newHistory;
        }
        return newHistory + oldHistory;
    }
    //#endregion

    //#region Comm Status
    populateCommStatus() {
        this._commService.getAllCommStatus()
        .subscribe({
            next: (response) => {
                this.commStatusList = response;
                this.cRef.markForCheck();
            },
            error: (error) => {
            }
        });
    }
    //#endregion
    //#region Download File
    downloadFile(file: string) {
        this._sharedService.showLoader("Processing...");
        let body = { CommId: this.model.CommId, FileName: file };
        this._azureBlobService.downloadAttachedFile(body)
        .subscribe({
            next: (response) => {
                this._sharedService.hideLoader();
                let blob = new Blob([response], { type: 'application/zip' });
                let url = URL.createObjectURL(blob);
                this.formatZipFileName(url, file.substring(0, file.lastIndexOf(".")));
            },
            error: (error) => {
                console.log(error);
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Error.', detail: 'File not exists.' });
            }
        });
    }

    formatZipFileName(fileURL: string, fileName: string) {
        fileName = fileName.replace(/[.]/g, "");
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = fileURL;
        a.target = '_blank';
        a.download = fileName;
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
    }
    //#endregion
    //#region Date Manipulation Helper Methods
    getPlainDate(date: Date): string {
        if (date != null) {
            var formatted = moment(date).format(dateTimeConfig.dateformat.toUpperCase());
            if (dateTimeConfig.dateSeparator == "-") {
                let newStr = formatted.toString().replace(/-/g, "");
                return newStr;
            }
            else if (dateTimeConfig.dateSeparator == "/") {
                let newStr = formatted.toString().replace(/\//g, "");
                return newStr;
            }
        }
        else {
            return "";

        }
    }

    setCalenderDateFormat() {
        if (!dateTimeConfig.dateSeparator) {
            dateTimeConfig = this._sharedService.getDateTimeFormatConfigFromLocalStorage();
        }

        let dateParts: string[] = dateTimeConfig.dateformat.split(dateTimeConfig.dateSeparator);

        if (dateTimeConfig.year == 0) {
            this.dateFormatCalender = (dateParts[dateTimeConfig.year].substr(0, 2) + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day]).toLowerCase();
        }
        else if (dateTimeConfig.year == 2) {
            if (dateTimeConfig.month == 1) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
            else if (dateTimeConfig.month == 0) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
        }
        else {
            this.dateFormatCalender = "yy-mm-dd"
        }

    }



    //#endregion
    setDefaultComponentValues(): void {
        //this.getAssignedToUsersList();
        this.getAssignedToUsersList();
        this.populateUsersList();
        this.populateUsersEmailList();
        this.populatePriortityList();
        this.populateCommType();
        this.populateCommStatus();
        this.initializeModel();
        this.changeButtonsStatus(false);
        this.uploadUrl = `${this._sharedService.baseUrl}/api/Comm/EditItem`;

    }

    getDate(date: Date): string {
        if (date != null) {
            let newDate = moment(date).format("YYYY-MM-DD");
            return new Date(newDate).toDateString();
        }
        return null;
    }
    //#endregion

    //#region Application User
    populateUsersList() {
        this._userService.getCurrentTenantUsers(this._sharedService.baseUrl, null)
        .subscribe({
            next: (response) => {
                this.requestedByList = response;
                this.cRef.markForCheck();
                // this.assignedToList = response;
                //this.emailCcsList = response;
            },
            error: (error) => {
            }
        });
    }
    populateUsersEmailList() {
        this._userService.getCurrentTenantUsersWithEmail(this._sharedService.baseUrl, null)
        .subscribe({
            next: (response) => {
                this.emailCcsList = response;
                this.cRef.markForCheck();
            },
            error: (error) => {
            }
        });
    }
    //#endregion

    //#region Misc

    initializeEmptyModel() {
        this.model = new EditCommModel(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, true, null, null, null, null);
        this.selectedCCsTemp = [];
        //this.selectedBulkFiles = [];
        this.bulkFile = [];
        this.uploadedFiles = [];
        this.oldConversation = "";
        this.orignalAttachedFiles = [];
        this.clearSelectedFiles();
    }
    getConversation() {
        let oldConv = (this.oldConversation != "" && this.oldConversation != "null" && this.oldConversation != null) ? this.oldConversation : "";
        if (this.model.Conversation != "" && this.model.Conversation != "null" && this.model.Conversation != null) {
            //let newConv = "[" + this.getCurrentDateTime() + " by " + this._sharedService.getCurrentUserName() + "] " + this.model.Conversation
            // return this._sharedService.getCurrentUserName() + "||" + this.getCurrentDateTime() + "||" + this.model.Conversation + "?|?";
            // let newConv = this._sharedService.getCurrentUserName() + " added a comment - " + this.getCurrentDateTime() + "\r\n" + this.model.Conversation;;
            let newConv = this._sharedService.getCurrentUserName() + "||" + this.getCurrentDateTime() + "||" + this.model.Conversation + "?|?";
            if (oldConv != "") {
                //return newConv + "\r\n" + oldConv;
                return newConv + oldConv;
            }
            return newConv;
        }
        return oldConv;
    }
    getCurrentDateTime() {
        let result = moment().format("MM/DD/YYYY" + " HH:mm");
        return result;
    }

    initializeModel() {

        this.model = new EditCommModel(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, []);
    }



    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }
    //#endregion

    //#region Assigned to list
    getAssignedToUsersList() {
        this.assignedToList = this._sharedService.getAssignedToUsersList();
        if (this.assignedToList == null) {
            this._userService.getCurrentTenantUsersWithType(this._sharedService.baseUrl, null)
            .subscribe({
                next: (response) => {
                    this.assignedToList = response;
                    this._sharedService.setAssignedToUsersList(response);
                    this.cRef.markForCheck();
                },
                error: (error) => {
                }
            });
        }
    }
    getSelectedClass(param: string): string {
        let userType = param.split('||')[1];
        if (userType == "Internal") {
            return 'text-danger';
        }
        else {
            return 'text-dark';
        }
    }
    getOptionsClass(param: string): string {

        let userType = param.split('||')[1];
        if (userType == "Internal") {
            return 'glyphicon glyphicon-user text-primary';
        }
        else {
            return 'glyphicon glyphicon-user text-success';
        }
    }
    getUserDepartment(param: string) {
        return param.split('||')[2];
    }
    getTagColor(param: string) {
        return param.split('||')[3];
    }

    //#endregion

    onViewItem(form: NgForm) {
        this.changeButtonsStatus(true);
        this.commdisplay = false;
        form.resetForm();
        this._sharedService.viewInboxItemDetails(null);
    }

    onKey() {

        this.clearSelectedFiles();
        this.commdisplay = false;
        this.initializeModel();
        this.initializeEmptyModel();
        this.clearSelectedFiles();
    }
    onCloseDate() {
        var btnDiv: any = document.getElementsByClassName("c-btn");
        btnDiv[0].click();
        var btnDivFirstElement: any = document.getElementsByClassName("c-btn")[0];
        btnDivFirstElement.focus();
    }
    emailCCEscClick() {
        var subject: any = document.getElementById("editsubject");
        subject.focus();
    }
    subjectEnterClick() {
        var editorContent: any = document.getElementsByClassName('p-editor-content')[0].children[0];
        editorContent.focus();
    }
}
