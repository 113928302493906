<form #addForm="ngForm" style="height:92vh;overflow-y: scroll;overflow-x: hidden;">
    <header role="heading" class="tab-header">

        <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>
    </header>
    <div class="row addaircraftcls">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" id="col1">
            <h1 class="page-title txt-color-blueDark">

                <!-- PAGE HEADER -->
                <i class="fa-fw fa fa-eye"></i>
                <a routerLink="/admin/viewsetting"  [attr.tabindex]="-1" >Status Views</a>
                <span>
                    &gt;
                    <span id="page-mode">View Settings</span>
                </span>
            </h1>
        </div>
        <article class="col-sm-12 col-md-12 col-lg-12" id="col2">
            <!-- Widget ID (each widget will need unique ID)-->

            <div class="jarviswidget" id="wid-id-0" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false">

                <header>
                    <span class="widget-icon"><i class="fa fa-pencil-square-o"></i></span>
                    <h2>View Settings</h2>
                    <div class="jarviswidget-ctrls">
                        <a class="button-icon hand-cursor tablIndexFocus"  [attr.tabindex]="0"  id="btncopy" title="Copy View"  (keyup.enter)="openCopyDlg()" (click)="openCopyDlg()" *ngIf="!model.ViewSettingId">
                            <i class="glyphicon glyphicon-copy"></i>
                        </a>
                    </div>
                </header>

                <!-- widget div-->
                <div class="widgetdiv">
                    <!-- widget content -->
                    <div class="widget-body">
                        <!-- widget grid -->
                        <section id="widget-grid0" class="">
                            <div class="navbar-form form-group">
                                <!--<ul id="myTab1" class="nav nav-tabs bordered">
                                    <li class="active">
                                        <a href="#s1" data-toggle="tab" aria-expanded="true">Airframe</a>
                                    </li>
                                </ul>-->
                                <div id="myTabContent1" class="tab-content">
                                    <div class="tab-pane fade active in" id="s1">
                                        <div class="smart-form">
                                            <div class="row">
                                                <div class="form-group col-lg-3 col-md-4 col-sm-4 col-xs-5">
                                                    <fieldset>
                                                        <label class="label">
                                                            View Name<span [hidden]="viewName.valid || model.ViewName" class="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <label class="input rgxErr">
                                                            <input type="hidden" name="viewSettingId" [(ngModel)]="model.ViewSettingId" />

                                                            <i *ngIf="viewName.value && !viewName.valid && (viewName.touched || viewName.dirty )"
                                                                class="icon-append fa fa-ban"
                                                                title="starts with alphabet & {{_sharedService.error_rgx_pre}} {{_sharedService.re_ViewDisplayName_Message}} {{_sharedService.error_rgx_post}}" >
                                                            </i>

                                                            <input style="font-size: 14px;" id="viewName" type="text" name="viewName" required class="input-sm"
                                                            [pattern]="_sharedService.re_ViewDisplayName"
                                                            [(ngModel)]="model.ViewName" #viewName="ngModel" />
                                                        </label>
                                                    </fieldset>
                                                </div>
                                                <div class="form-group col-lg-3 col-md-4 col-sm-4 col-xs-5">
                                                    <fieldset>
                                                        <label class="label">
                                                            Display Name<span [hidden]="displayName.valid || model.DisplayName" class="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <label class="input rgxErr">
                                                                <i *ngIf="displayName.value && !displayName.valid && (displayName.touched || displayName.dirty )"
                                                                class="icon-append fa fa-ban"
                                                                title="starts with alphabet & {{_sharedService.error_rgx_pre}} {{_sharedService.re_ViewDisplayName_Message}} {{_sharedService.error_rgx_post}}" >
                                                                </i>
                                                            <input style="font-size: 14px;" id="displayName" type="text" name="displayName" required class="input-sm"
                                                            [pattern]="_sharedService.re_ViewDisplayName"
                                                            [(ngModel)]="model.DisplayName" #displayName="ngModel" />
                                                        </label>
                                                    </fieldset>
                                                </div>
                                                <div class="form-group col-lg-2 col-md-2 col-sm-2 col-xs-3" >
                                                    <fieldset style="padding: 5px 5px 5px 5px;">
                                                        <label class="label">
                                                            Asset Type<span [hidden]="AssetType.valid || model.AssetType" class="text-danger">
                                                                *
                                                            </span>
                                                        </label>

                                                        <p-dropdown id="AssetType" name="AssetType" [options]="assetTypes" (onChange)="onAssetChange($event)" required placeholder="Select Type" [(ngModel)]="model.AssetType" #AssetType="ngModel" appendTo="body">
                                                        </p-dropdown>

                                                    </fieldset>
                                                </div>
                                                <div class="form-group col-lg-2 col-md-4 col-sm-4 col-xs-6">
                                                    <fieldset>
                                                        <label class="label">
                                                            Field/Folder Name<span [hidden]="DefaultField.valid || model.DefaultField" class="text-danger">
                                                                *
                                                            </span>
                                                        </label>

                                                        <p-dropdown id="DefaultField" name="DefaultField" [options]="fieldsWithLable" (onChange)="onAssetChange($event)" required placeholder="Select Field" [(ngModel)]="model.DefaultField" #DefaultField="ngModel" [filter]="true" #adddefaultfieldfilter [resetFilterOnHide]="true" appendTo="body">
                                                        </p-dropdown>

                                                    </fieldset>
                                                </div>
                                                <div class="form-group col-lg-2 col-md-2 col-sm-2 col-xs-3" >
                                                    <fieldset style="padding: 5px 5px 5px 5px;">
                                                        <label class="label">
                                                            Data Source<span [hidden]="Source.valid || model.Source" class="text-danger">
                                                                *
                                                            </span>
                                                        </label>

                                                        <p-dropdown id="Source" name="Source" [options]="dataSource" (onChange)="setGridColumnsDef()" required placeholder="Source" [(ngModel)]="model.Source" #Source="ngModel" appendTo="body">
                                                        </p-dropdown>

                                                    </fieldset>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="form-group col-lg-3 col-md-2 col-sm-1 col-xs-2">
                                                    <fieldset>
                                                        <label class="label">Compliance Status</label>
                                                            <p-multiSelect id="dfpStatus" [options]="dfpStatusList"  [maxSelectedLabels]="1" selectedItemsLabel="{0} items selected" [(ngModel)]="model.selectedDfpStatus" [style]="{width:'100%'}" name="dfpStatus" #dfpStatus="ngModel" appendTo="body"></p-multiSelect>
                                                    </fieldset>
                                                </div>
                                                <div class="form-group col-lg-2 col-md-2 col-sm-2 col-xs-3" >
                                                    <fieldset style="padding: 5px 5px 5px 5px;">
                                                        <label class="label">
                                                            View Type<span [hidden]="ViewType.valid || model.ViewType" class="text-danger">
                                                                *
                                                            </span>
                                                        </label>

                                                        <p-dropdown id="ViewType" name="ViewType" [options]="dataViewType"  required placeholder="View Type" [(ngModel)]="model.ViewType" #ViewType="ngModel" appendTo="body">
                                                        </p-dropdown>

                                                    </fieldset>
                                                </div>
                                                <div class="form-group col-lg-1 col-md-2 col-sm-1 col-xs-2">
                                                    <fieldset>
                                                        <label class="label">
                                                            Order<span [hidden]="displayOrder.valid || model.DisplayOrder" class="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <label class="input">
                                                            <input id="displayOrder" type="text" name="displayOrder" required class="input-sm" [(ngModel)]="model.DisplayOrder" #displayOrder="ngModel" />
                                                        </label>
                                                    </fieldset>
                                                </div>
                                                <div class="form-group  col-lg-1 col-md-2 col-sm-2 col-xs-3">
                                                    <fieldset>
                                                        <label class="label">Wrap Text</label>
                                                        <label class="_checkbox_" title="Wrap Text in Cell">
                                                            <span class="onoffswitch tablIndexFocus"  [attr.tabindex]="0" (keyup.enter)="ToggleCheckedTextWrap()">
                                                              <input [checked]="isCheckedTextWrap" type="checkbox" name="textwrap"
                                                                     class="onoffswitch-checkbox" checked="checked" id="textwrap" [(ngModel)]="model.TextWrap" #textwrap="ngModel">
                                                              <label class="onoffswitch-label"
                                                                     for="textwrap">
                                                                <span class="onoffswitch-inner" data-swchon-text="YES" data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span>
                                                              </label>
                                                            </span>
                                                          </label>
                                                    </fieldset>
                                                </div>
                                                <div class="form-group col-lg-1 col-md-2 col-sm-2 col-xs-3">
                                                    <fieldset>
                                                        <label class="label" >Visible</label>
                                                        <label class="_checkbox_" title="Show in status menu">
                                                            <span class="onoffswitch  tablIndexFocus"  [attr.tabindex]="0" (keyup.enter)="ToggleCheckedVisible()" >
                                                                <input type="checkbox" [checked]="isCheckedVisible" name="visible"
                                                                       class="onoffswitch-checkbox" checked="checked" id="visible" [(ngModel)]="model.Visible" #visible="ngModel">
                                                                <label class="onoffswitch-label"
                                                                       for="visible">
                                                                    <span class="onoffswitch-inner" data-swchon-text="YES" data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span>
                                                                </label>
                                                            </span>
                                                        </label>
                                                    </fieldset>
                                                </div>
                                                <div class="form-group  col-lg-1 col-md-2 col-sm-2 col-xs-3">
                                                    <fieldset>
                                                        <label class="label">Dashboard</label>
                                                        <label class="_checkbox_" title="Show on Dashboard">
                                                            <span class="onoffswitch tablIndexFocus"  [attr.tabindex]="0" (keyup.enter)="ToggleCheckShowOnDashboard()">
                                                              <input type="checkbox" [checked]="isCheckedShowOnDashboard" name="showOnDashboard" 
                                                                     class="onoffswitch-checkbox" checked="checked" id="showOnDashboard" [(ngModel)]="model.ShowOnDashboard" #showOnDashboard="ngModel">
                                                              <label class="onoffswitch-label"
                                                                     for="showOnDashboard">
                                                                <span class="onoffswitch-inner" data-swchon-text="YES" data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span>
                                                              </label>
                                                            </span>
                                                          </label>
                                                    </fieldset>
                                                </div>
                                                <div class="form-group col-lg-1 col-md-2 col-sm-2 col-xs-3">
                                                    <fieldset>
                                                        <label class="label">Status</label>
                                                        <label class="_checkbox_" title="Active View">
                                                            <span class="onoffswitch tablIndexFocus  onoffswitch-Width"  [attr.tabindex]="0" (keyup.enter)="ToggleCheckedStatus()">
                                                                <input type="checkbox" name="status" [checked]="isCheckedStatus"
                                                                       class="onoffswitch-checkbox" checked="checked" id="status" [(ngModel)]="model.Status" #status="ngModel">
                                                                <label class="onoffswitch-label"
                                                                       for="status">
                                                                    <span class="onoffswitch-inner" data-swchon-text="Active" data-swchoff-text="Inactive"></span><span class="onoffswitch-switch onoffswitch-switch-Right"></span>
                                                                </label>
                                                            </span>
                                                        </label>
                                                    </fieldset>
                                                </div>
                                                <div class="form-group col-lg-2 col-md-2 col-sm-2 col-xs-3">
                                                    <fieldset>
                                                        <label class="label"> Default Container <i class="btn btn-default fa fa-external-link" (click)="openDefaultContainerDlg()" style="height: 20px; width: 20px;text-align: center; padding-top: 4px;"></i></label>
                                                        <!-- <button type="button" pButton icon="fa fa-external-link" class="btn btn-default" ></button> -->
                                                        <label class="input">
                                                            <span class="p-input-icon-right">
                                                            <input id="defaultContainer" type="text" name="defaultContainer" title="{{model.DefaultContainer}}" disabled class="input-sm" pInputText [(ngModel)]="model.DefaultContainer" #defaultContainer="ngModel" />
                                                            <i *ngIf="defaultContainer.value" class="pi pi-times cursor-pointer" (click)="clearDefaultContainer()"></i>
                                                        </span>
                                                        </label>
                                                    </fieldset>
                                                </div>
                                            </div>

                                            <ul id="widget-tab-1" class="nav nav-tabs">
                                                <li [ngClass]="(isAutoSearchTab==false)?'active':''">
                                                    <a id="viewsettings" #viewsettingspanal data-toggle="tab" (click)="StatusTab()" [attr.tabindex]="0"  data-target="#hr11" [attr.aria-expanded]="!isAutoSearchTab" style="cursor: pointer;">
                                                        <span class="hidden-mobile hidden-tablet">View Settings </span>
                                                    </a>
                                                </li>
                                                <li [ngClass]="(isAutoSearchTab==true)?'active':''" *ngIf="showBuilderSetting">
                                                    <a id="buildersettings" #buildersettingspanel data-toggle="tab" (click)="ValidateInputToken()" [attr.tabindex]="0"  data-target="#hr22" [attr.aria-expanded]="isAutoSearchTab" style="cursor: pointer;">
                                                        <span class="hidden-mobile hidden-tablet" >Auto Search Setting </span>
                                                    </a>
                                                </li>
                                                <li *ngIf="showBuilderSetting">
                                                    <a id="buildersettings1" #buildersettingspanel1 data-toggle="tab" [attr.tabindex]="0" data-target="#hr33" [attr.aria-expanded]="CoverSheet" style="cursor: pointer;">
                                                        <span class="hidden-mobile hidden-tablet">Document Cover Sheet</span>
                                                    </a>
                                                </li>
                                                <li *ngIf="showBuilderSetting">
                                                    <a id="ViewDataExportSettings" (click)="PDFExportSettingsClick()" #ViewDataExportSettings data-toggle="tab" [attr.tabindex]="0" data-target="#hr44" [attr.aria-expanded]="ExportSettingsTab" style="cursor: pointer;">
                                                        <span class="hidden-mobile hidden-tablet">View Data Export Settings</span>
                                                    </a>
                                                </li>
                                                <li *ngIf="showBuilderSetting">
                                                    <a id="chartconfig" #chartconfig data-toggle="tab" [attr.tabindex]="0" data-target="#hr55" [attr.aria-expanded]="ChartConfig" style="cursor: pointer;">
                                                        <span class="hidden-mobile hidden-tablet">Chart Settings</span>
                                                    </a>
                                                </li>
                                            </ul>
                                            <div class="tab-content">
                                                <div class="tab-pane fade" [ngClass]="(isAutoSearchTab==false)?'active in':''" id="hr11">
                                                    <div class="row">
                                                        <div class="form-group col-sm-12" style="margin-top:10px;padding-right: 0px">
                                                          <div class="row" style="margin-left:0px !important;margin-right: 10px !important;                                                          ">
                                                            <fieldset>
                                                                <div class="row">
                                                                    <div class="form-group col-sm-1" style="padding-right: 0px;">
                                                                        <label class="label" style="padding-left:4px;padding-top: 6px;">Assets</label>
                                                                    </div>
                                                            
                                                                    <div class="form-group col-lg-2 col-md 2 col-sm-3" style="padding-left: 0px;">
                                                                        <fieldset>
                                                                            <div class="inline-group" id="rdobutton1">
                                                                                <label class="radio" style="padding-left:4px;">
                                                                                    <input type="radio" class="tablIndexFocus" name="astradio" checked="checked" [attr.tabindex]="0"
                                                                                        [(ngModel)]="model.AllAsset" [value]="true" (ngModelChange)="changeAllAsset()">
                                                                                    <i></i> All
                                                                                </label>
                                                                                <label class="radio" style="padding-left:4px;">
                                                                                    <input type="radio" class="tablIndexFocus" name="astradio" [attr.tabindex]="0"
                                                                                        [(ngModel)]="model.AllAsset" [value]="false" (ngModelChange)="changeAllAsset()">
                                                                                    <i></i> Selected
                                                                                </label>
                                                                            </div>
                                                                            <div class="detailtext"></div>
                                                                        </fieldset>
                                                                    </div>
                                                                    <div class="form-group col-lg-3 col-md-3 col-sm-3" >
                                                                    </div>
                                                                    <div class="form-group col-lg-2 col-md-2 col-sm-2" >
                                                                        <fieldset style="padding-top: 10px;float: right;">
                                                                            <label class="label">Spec 2500 Content</label>
                                                                        </fieldset>
                                                                    </div>
                                                                    <div class="form-group pull-right col-lg-4 col-md-4 col-sm-4 no-padding" >
                                                                        <fieldset  style="padding: 5px 5px 5px 5px;">
                                                                            <p-dropdown id="spec2500" name="spec2500" [options]="spec2500ViewDropDown" (onChange)="Spec2500ViewChage($event)" [(ngModel)]="model.Spec2500View" appendTo="body">
                                                                            </p-dropdown>
                                                                        </fieldset>
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                              </div>
                                                              <div class="panel panel-default" *ngIf="model.AllAsset==false" style="border-right-width: 2px;">
                                                                <div class="panel-heading">
                                                                  <h3 class="panel-title panalheading-padding">Assets </h3>
                                                                </div>
                                                                <div class="panel-body">
                                                                  <div class="col-sm-12 picklist-padding fix-picklist-width">
                                                                    <p-pickList id="aircraftspicklist" *ngIf="model.AllAsset==false" responsive="true" sourceHeader="Available" targetHeader="Selected" [source]="model.availableAssets" [target]="model.selectedAssets" filterBy="label" sourceFilterPlaceholder="search by asset" targetFilterPlaceholder="search by asset" [sourceStyle]="{'height':'270px'}" [targetStyle]="{'height':'270px'}">
                                                                      <ng-template let-car pTemplate="item">
                                                                        <div>
                                                                          <div style="font-size:14px">{{car.label}}</div>
                                                                        </div>
                                                                      </ng-template>
                                                                    </p-pickList>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                          </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-sm-12" style="margin-top:10px;">
                                                            <div class="panel panel-default" style="border-right-width: 2px;">
                                                                <div class="panel-heading">
                                                                    <h3 class="panel-title panalheading-padding">
                                                                        Columns
                                                                        <a class="button-icon hand-cursor pull-right tablIndexFocus" style="padding-right: 5px;" id="btnaddColumn" [attr.tabindex]="0" (click)="addColumn()" (keyup.enter)="addColumn()">
                                                                            <i class="glyphicon glyphicon-plus"></i>
                                                                        </a>
                                                                    </h3>
                                                                </div>
                                                                <div class="panel-body">
                                                                    <div class="col-sm-12 picklist-padding fix-picklist-width">
                                                                        <ag-grid-angular #agGridtest id="agGridtest" [gridOptions]="gridOptionsColumns" [columnDefs]="columnDefsColumns" [rowData]="gridDataColumns" [rowDragManaged]="true"
                                                                            (cellClicked)="onCellClicked($event)" (cellValueChanged)="onCellValueChanged($event)" [animateRows]="true" style="height:48vh !important;width:100% !important" class="ag-theme-balham">
                                                                            <!-- (rowDragEnter)="onRowDragEnter($event)" (rowDragMove)="onRowDragMove($event)" (rowDragLeave)="onRowDragLeave($event)"  (rowDragEnd)="onRowDragEnd($event)" -->
                                                                        </ag-grid-angular>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-sm-6" style="margin-top:10px;">
                                                            <div class="panel panel-default" style="border-right-width: 2px;">
                                                                <div class="panel-heading">
                                                                    <h3 class="panel-title panalheading-padding">
                                                                        Meet ALL of the following condition (AND)
                                                                        <a class="button-icon hand-cursor pull-right tablIndexFocus" style="padding-right: 5px;padding-top: 5px;" id="btnCndAdd" [attr.tabindex]="0" (click)="addCondition()" (keyup.enter)="addCondition()">
                                                                            <i class="glyphicon glyphicon-plus"></i>
                                                                        </a>
                                                                    </h3>
                                                                </div>
                                                                <div class="panel-body">
                                                                    <div class="col-sm-12 picklist-padding fix-picklist-width">
                                                                        <!--<div class="table-responsive" style="max-height: 185px;">-->
                                                                        <table class="table table-hover">
                                                                            <thead style="display: block !important; width: 100%;">
                                                                                <tr>
                                                                                    <!-- <th style="width: 24%">&nbsp;</th> -->
                                                                                    <th style="width: 24%">Field Name</th>
                                                                                    <!--<th>Field Label</th>-->
                                                                                    <th style="width: 23.5%">Condition</th>
                                                                                    <th style="width: 22.6%">Value</th>
                                                                                    <th style="width: 1.5%">&nbsp;</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody style="max-height: 185px;display: block !important;overflow-y: auto;" id="configviewbody">
                                                                                <tr *ngFor="let cnd of model.Conditions; let j = index; trackBy:trackByIndex">
                                                                                    <!-- <td style="width: 25.5%">
                                                                                        <select *ngIf="j!=0" class="form-control" [attr.tabindex]="0"  id="{{'whereCondition' + j }}" name="{{'whereCondition' + j }}" [(ngModel)]="cnd.JoinCondition" style="width:200px;">
                                                                                            <option *ngFor="let w of whereConditions;trackBy:trackByIndex" value="{{w.value}}">{{w.label}}</option>
                                                                                        </select>
                                                                                    </td> -->
                                                                                    <td style="width: 25.5%">
                                                                                        <!--<select class="form-control" id="{{'condFieldName' + j }}" name="{{'condFieldName' + j }}" [(ngModel)]="cnd.FieldName" style="width:200px;">
                                                                                            <option *ngFor="let f of fieldsWithLable" value="{{f.value}}">{{f.label}}</option>
                                                                                        </select>-->
                                                                                        <p-dropdown [attr.tabindex]="0"  appendTo="body" class="form-control" id="condFieldName{{j}}" name="condFieldName{{j}}" [options]="fieldsWithLable" [editable]="false" [(ngModel)]="cnd.FieldName" placeholder="Select Field" [filter]="true" [panelStyle]="{'bottom': 'calc(100% + 5px)'}"></p-dropdown>
                                                                                    </td>
                                                                                    <!--<td><label id="{{'condFieldLabel' + j }}" type="text" name="{{'cndValue' + j }}" [(ngModel)]="cnd.FieldLabel" class="label"></label></td>-->
                                                                                    <td *ngIf="cnd.FieldName"  style="width: 25.5%">
                                                                                        <select [attr.tabindex]="0"  class="form-control" id="{{'condition' + j }}" name="{{'condition' + j }}" [(ngModel)]="cnd.ConditionType" required>
                                                                                            <option *ngFor="let c of conditions;trackBy:trackByIndex" value="{{c.value}}">{{c.label}}</option>
                                                                                        </select>
                                                                                    </td>
                                                                                    <td *ngIf="!cnd.FieldName"  style="width: 25.5%">
                                                                                        <select [attr.tabindex]="0"  class="form-control" id="{{'condition' + j }}" name="{{'condition' + j }}" [(ngModel)]="cnd.ConditionType" >
                                                                                            <option *ngFor="let c of conditions;trackBy:trackByIndex" value="{{c.value}}">{{c.label}}</option>
                                                                                        </select>
                                                                                    </td>
                                                                                                <!-- <ng-template #showBlock>
                                                                                                <select [attr.tabindex]="0"  class="form-control" id="{{'condition' + j }}" name="{{'condition' + j }}" [(ngModel)]="cnd.ConditionType" required >
                                                                                                    <option *ngFor="let c of conditions;trackBy:trackByIndex" value="{{c.value}}">{{c.label}}</option>
                                                                                                </select>
                                                                                            </ng-template>
                                                                                            <ng-template #notShow>
                                                                                                <select [attr.tabindex]="0"  class="form-control" id="{{'condition' + j }}" name="{{'condition' + j }}" [(ngModel)]="cnd.ConditionType" >
                                                                                                    <option *ngFor="let c of conditions;trackBy:trackByIndex" value="{{c.value}}">{{c.label}}</option>
                                                                                                </select>
                                                                                            </ng-template> -->
                                                                                    <!-- </td> -->
                                                                                    <!-- <td style="width: 25.5%">
                                                                                        <select [attr.tabindex]="0"  class="form-control" id="{{'condition' + j }}" name="{{'condition' + j }}" [(ngModel)]="cnd.ConditionType" >
                                                                                            <option *ngFor="let c of conditions;trackBy:trackByIndex" value="{{c.value}}">{{c.label}}</option>
                                                                                        </select>
                                                                                    </td> -->
                                                                                    <td style="width: 22.5%">
                                                                                        <tr *ngIf="cnd.FieldName != 'DataType'">
                                                                                            <input id="{{'cndValue' + j }}" style="min-width: 200px;" type="text" name="{{'cndValue' + j }}" [(ngModel)]="cnd.Value" class="input-sm inputWidthTablet" (blur)="cnd.Value=removeQuote($event.target.value)" />
                                                                                        </tr>
                                                                                        <tr *ngIf="cnd.FieldName != 'DataType'">
                                                                                            <label class="token" (click)="AssignToken(j, _sharedService.Token_ASN)">{{_sharedService.Token_ASN}}, </label>
                                                                                            <label class="token" (click)="AssignToken(j, _sharedService.Token_Me)">{{_sharedService.Token_Me}}, </label>
                                                                                            <label class="token" (click)="AssignToken(j, _sharedService.Token_Today)">{{_sharedService.Token_Today}}, </label>
                                                                                            <label class="token" (click)="AssignToken(j, _sharedService.Token_Latest)">{{_sharedService.Token_Latest}}, </label>
                                                                                            <label class="token" (click)="AssignToken(j, _sharedService.Token_INPUT)">{{_sharedService.Token_INPUT}} </label>
                                                                                        </tr>
                                                                                        <tr *ngIf="cnd.FieldName == 'DataType'">
                                                                                            <p-dropdown [attr.tabindex]="0"  appendTo="body" class="form-control" id="{{'cndValue' + j }}" name="{{'cndValue' + j }}" [options]="viewDataTypeList" [editable]="false" [(ngModel)]="cnd.Value" placeholder="Select Data Type" [filter]="true" [panelStyle]="{'bottom': 'calc(100% + 5px)'}"></p-dropdown>
                                                                                        </tr>
                                                                                    </td>
                                                                                    <td style="width: 21.5%">
                                                                                        <a class="button-icon hand-cursor pull-right tablIndexFocus" [attr.tabindex]="0" style="padding-right: 5px;" name="{{'btnCndDel' + j }}" id="{{'btnCndDel' + j }}" (keyup.enter)="deleteCondition(j)" (click)="deleteCondition(j)">
                                                                                            <i class="glyphicon glyphicon-remove"></i>
                                                                                        </a>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <!--</div>-->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-sm-6" style="margin-top:10px;padding-right: 15px !important;padding-left: 0px !important;">
                                                            <div class="panel panel-default" style="border-right-width: 2px;">
                                                                <div class="panel-heading">
                                                                    <h3 class="panel-title panalheading-padding">
                                                                        Meet ANY of the following condition (OR)
                                                                        <a class="button-icon hand-cursor pull-right tablIndexFocus" style="padding-right: 5px;padding-top: 5px;" id="btnOrCndAdd" [attr.tabindex]="0" (click)="addOrCondition()" (keyup.enter)="addOrCondition()">
                                                                            <i class="glyphicon glyphicon-plus"></i>
                                                                        </a>
                                                                    </h3>
                                                                </div>
                                                                <div class="panel-body">
                                                                    <div class="col-sm-12 picklist-padding fix-picklist-width">
                                                                        <!--<div class="table-responsive" style="max-height: 185px;">-->
                                                                        <table class="table table-hover">
                                                                            <thead style="display: block !important;">
                                                                                <tr>
                                                                                    <!-- <th style="width: 24%">&nbsp;</th> -->
                                                                                    <th style="width: 24%">Field Name</th>
                                                                                    <!--<th>Field Label</th>-->
                                                                                    <th style="width: 23.5%">Condition</th>
                                                                                    <th style="width: 22.6%">Value</th>
                                                                                    <th style="width: 1.5%">&nbsp;</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody style="max-height: 185px;display: block !important;overflow-y: auto;" id="configviewbody">
                                                                                <tr *ngFor="let cnd of model.OrConditions; let j = index; trackBy:trackByIndex">
                                                                                    <!-- <td style="width: 25.5%">
                                                                                        <select *ngIf="j!=0" class="form-control" [attr.tabindex]="0"  id="{{'whereCondition' + j }}" name="{{'whereCondition' + j }}" [(ngModel)]="cnd.JoinCondition" style="width:200px;">
                                                                                            <option *ngFor="let w of whereConditions;trackBy:trackByIndex" value="{{w.value}}">{{w.label}}</option>
                                                                                        </select>
                                                                                    </td> -->
                                                                                    <td style="width: 25.5%">
                                                                                        <!--<select class="form-control" id="{{'condFieldName' + j }}" name="{{'condFieldName' + j }}" [(ngModel)]="cnd.FieldName" style="width:200px;">
                                                                                            <option *ngFor="let f of fieldsWithLable" value="{{f.value}}">{{f.label}}</option>
                                                                                        </select>-->
                                                                                        <p-dropdown [attr.tabindex]="0"  appendTo="body" class="form-control" id="orCondFieldName{{j}}" name="orCondFieldName{{j}}" [options]="fieldsWithLable" [editable]="false" [(ngModel)]="cnd.FieldName" placeholder="Select Field" [filter]="true" [panelStyle]="{'bottom': 'calc(100% + 5px)'}"></p-dropdown>
                                                                                    </td>
                                                                                    <!--<td><label id="{{'condFieldLabel' + j }}" type="text" name="{{'cndValue' + j }}" [(ngModel)]="cnd.FieldLabel" class="label"></label></td>-->
                                                                                    <!-- <td style="width: 25.5%">
                                                                                        <select [attr.tabindex]="0"  class="form-control" id="{{'orCondition' + j }}" name="{{'orCondition' + j }}" [(ngModel)]="cnd.ConditionType" >
                                                                                            <option *ngFor="let c of conditions;trackBy:trackByIndex" value="{{c.value}}">{{c.label}}</option>
                                                                                        </select>
                                                                                    </td> -->


                                                                                    <td *ngIf="cnd.FieldName"  style="width: 25.5%">

                                                                                        <select [attr.tabindex]="0"  class="form-control" id="{{'orCondition' + j }}" name="{{'orCondition' + j }}" [(ngModel)]="cnd.ConditionType"  required>
                                                                                            <option *ngFor="let c of conditions;trackBy:trackByIndex" value="{{c.value}}">{{c.label}}</option>
                                                                                        </select>

                                                                                    </td>
                                                                                    <td *ngIf="!cnd.FieldName"  style="width: 25.5%">
                                                                                        <select [attr.tabindex]="0"  class="form-control" id="{{'orCondition' + j }}" name="{{'orCondition' + j }}" [(ngModel)]="cnd.ConditionType" >
                                                                                            <option *ngFor="let c of conditions;trackBy:trackByIndex" value="{{c.value}}">{{c.label}}</option>
                                                                                        </select>
                                                                                    </td>


                                                                                    <td style="width: 22.5%">
                                                                                        <tr *ngIf="cnd.FieldName != 'DataType'">
                                                                                            <input id="{{'orCndValue' + j }}" type="text"  style="min-width: 200px;" name="{{'orCndValue' + j }}" [(ngModel)]="cnd.Value" class="form-control input-sm inputWidthTablet2" (blur)="cnd.Value=removeQuote($event.target.value)" />
                                                                                        </tr>
                                                                                        <tr *ngIf="cnd.FieldName != 'DataType'">
                                                                                            <label class="token" (click)="AssignOrToken(j, _sharedService.Token_ASN)">{{_sharedService.Token_ASN}}, </label>
                                                                                            <label class="token" (click)="AssignOrToken(j, _sharedService.Token_Me)">{{_sharedService.Token_Me}}, </label>
                                                                                            <label class="token" (click)="AssignOrToken(j, _sharedService.Token_Today)">{{_sharedService.Token_Today}}, </label>
                                                                                            <label class="token" (click)="AssignOrToken(j, _sharedService.Token_Latest)">{{_sharedService.Token_Latest}}, </label>
                                                                                            <label class="token" (click)="AssignOrToken(j, _sharedService.Token_INPUT)">{{_sharedService.Token_INPUT}} </label>
                                                                                        </tr>
                                                                                        <tr *ngIf="cnd.FieldName == 'DataType'">
                                                                                            <p-dropdown [attr.tabindex]="0"  appendTo="body" class="form-control" id="{{'orCndValue' + j }}" name="{{'orCndValue' + j }}" [options]="viewDataTypeList" [editable]="false" [(ngModel)]="cnd.Value" placeholder="Select Data Type" [filter]="true" [panelStyle]="{'bottom': 'calc(100% + 5px)'}"></p-dropdown>
                                                                                        </tr>
                                                                                    </td>
                                                                                    <td style="width: 21.5%">
                                                                                        <a class="button-icon hand-cursor pull-right tablIndexFocus" [attr.tabindex]="0" style="padding-right: 5px;" name="{{'btnOrCndDel' + j }}" id="{{'btnOrCndDel' + j }}" (keyup.enter)="deleteOrCondition(j)" (click)="deleteOrCondition(j)">
                                                                                            <i class="glyphicon glyphicon-remove"></i>
                                                                                        </a>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <!--</div>-->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" id="configordergrouprow">
                                                        <div class="form-group col-sm-6" style="margin-top:10px;">
                                                            <div class="panel panel-default" style="border-top-width: 2px;">
                                                                <div class="panel-heading">
                                                                    <h3 class="panel-title panalheading-padding">
                                                                        Order by
                                                                        <a class="button-icon hand-cursor pull-right tablIndexFocus" style="padding-right: 5px;" id="btnOrderByAdd" [attr.tabindex]="0" (keyup.enter)="addOrderBy()"   (click)="addOrderBy()">
                                                                            <i class="glyphicon glyphicon-plus"></i>
                                                                        </a>
                                                                    </h3>
                                                                </div>
                                                                <div class="panel-body">
                                                                    <div class="col-sm-12 picklist-padding fix-picklist-width">
                                                                        <!--<div class="table-responsive" style="max-height: 185px;">-->
                                                                        <table class="table table-hover">
                                                                            <thead style="display: block !important;">
                                                                                <tr>
                                                                                    <th style="width: 50%">Field Name</th>
                                                                                    <th style="width: 52.5%">Order</th>
                                                                                    <th style="width: 1%">&nbsp;</th>
                                                                                </tr>
                                                                            </thead>

                                                                                    <tbody  style="max-height: 185px;display: block !important;overflow-y: auto;">
                                                                                <tr *ngFor="let odrBy of model.OrderBy; let k = index;trackBy:trackByIndex">
                                                                                    <td style="width: 50%">
                                                                                        <!--<select class="form-control" id="{{'orderByFieldName' + k }}" name="{{'orderByFieldName' + k }}" [(ngModel)]="odrBy.FieldName" style="width:200px;">
                                                                                            <option *ngFor="let f of fieldsWithLable" value="{{f.value}}">{{f.label}}</option>
                                                                                        </select>-->
                                                                                        <p-dropdown [attr.tabindex]="0" [panelStyle]="{'bottom': 'calc(100% + 5px)'}" appendTo="body" class="form-control" id="orderByFieldName{{k}}" name="orderByFieldName{{k}}" [options]="fieldsWithLable" [editable]="false" [(ngModel)]="odrBy.FieldName" placeholder="Select Field" [filter]="true"></p-dropdown>
                                                                                    </td>
                                                                                    <td style="width: 50%">
                                                                                        <select [attr.tabindex]="0" class="form-control" id="{{'orderByOrder' + k }}" name="{{'orderByOrder' + k }}" [(ngModel)]="odrBy.Order" style="width:200px;">
                                                                                            <option *ngFor="let f of orderBy;trackBy:trackByIndex" value="{{f.value}}">{{f.label}}</option>
                                                                                        </select>
                                                                                    </td>
                                                                                    <td style="width: 1%">
                                                                                        <a class="button-icon hand-cursor pull-right tablIndexFocus" [attr.tabindex]="0" style="padding-right: 5px;padding-top: 5px;" name="{{'btnOrderByDel' + k }}" id="{{'btnOrderByDel' + k }}" (keyup.enter)="deleteOrderBy(k)" (click)="deleteOrderBy(k)">
                                                                                            <i class="glyphicon glyphicon-remove"></i>
                                                                                        </a>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <!--</div>-->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-sm-6" style="margin-top:10px;border-right-width: 2px;padding-right: 15px !important;padding-left: 0px !important;">
                                                            <div class="panel panel-default" style="border-top-width: 2px;">
                                                                <div class="panel-heading">
                                                                    <h3 class="panel-title panalheading-padding">
                                                                        Group by
                                                                        <a class="button-icon hand-cursor pull-right tablIndexFocus" style="padding-right: 5px;" id="btnGroupByAdd" [attr.tabindex]="0" (keyup.enter)="addGroupBy()" (click)="addGroupBy()">
                                                                            <i class="glyphicon glyphicon-plus"></i>
                                                                        </a>
                                                                    </h3>
                                                                </div>
                                                                <div class="panel-body">
                                                                    <div class="col-sm-12 picklist-padding fix-picklist-width">
                                                                        <!--<div class="table-responsive" style="max-height: 185px;">-->
                                                                        <table class="table table-hover">
                                                                            <thead style="display: block !important;">
                                                                                <tr>
                                                                                    <th style="width: 100%">Field Name</th>
                                                                                    <th>&nbsp;</th>
                                                                                </tr>
                                                                            </thead>

                                                                            <tbody   style="max-height: 185px;display: block !important;overflow-y: auto;">
                                                                                <tr *ngFor="let groupBy of model.GroupBy; let l = index;trackBy:trackByIndex">
                                                                                    <td style="width: 95%">
                                                                                        <!--<select class="form-control" id="{{'groupByFieldName' + l }}" name="{{'groupByFieldName' + l }}" (change)="onChangeGroupBy($event)" [(ngModel)]="model.GroupBy[l]" style="width:200px;">
                                                                                            <option *ngFor="let f of fieldsWithLable" value="{{f.value}}">{{f.label}}</option>
                                                                                        </select>-->
                                                                                        <p-dropdown appendTo="body" [panelStyle]="{'bottom': 'calc(100% + 5px)'}" class="form-control" id="groupByFieldName{{l}}" name="groupByFieldName{{l}}" [options]="fieldsWithLable" [editable]="false" [(ngModel)]="model.GroupBy[l]" placeholder="Select Field" [filter]="true"></p-dropdown>
                                                                                    </td>
                                                                                    <td style="width: 5%">
                                                                                        <a class="button-icon hand-cursor pull-right tablIndexFocus" [attr.tabindex]="0"  style="padding-right: 5px;padding-top: 5px;" name="{{'btnGroupByDel' + l }}" id="{{'btnGroupByDel' + l }}" (keyup.enter)="deleteGroupBy(l)" (click)="deleteGroupBy(l)">
                                                                                            <i class="glyphicon glyphicon-remove"></i>
                                                                                        </a>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <!--</div>-->

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="hr22" *ngIf="showBuilderSetting" [ngClass]="(isAutoSearchTab==true)?'active in':''">

                                                    <div class="row">
                                                        <div class="form-group col-sm-12" style="margin-top:10px;">
                                                            <div class="panel panel-default" style="border-right-width: 2px;height:360px;">
                                                                <div class="panel-heading">
                                                                    <h3 class="panel-title panalheading-padding">
                                                                        Auto Search Queries
                                                                        <a class="button-icon hand-cursor pull-right tablIndexFocus"  [attr.tabindex]="0"  style="padding-right: 5px;" id="btnAutoSearchDel"  (click)="deleteSearchConfig($event)" title="Delete Search Query">
                                                                            <i class="glyphicon glyphicon-trash"></i>
                                                                        </a>
                                                                        <a class="button-icon hand-cursor pull-right tablIndexFocus"  [attr.tabindex]="0"  style="padding-right: 5px;" id="btnOcrEdit"  (click)="editSearchConfig($event)" title="Edit Search Query (Ctrl+E)">
                                                                            <i class="glyphicon glyphicon-pencil"></i>
                                                                        </a>
                                                                        <a class="button-icon hand-cursor pull-right tablIndexFocus" style="padding-right: 5px;" id="btnOcrAdd" [attr.tabindex]="0" (keyup.enter)="addSearchConfig($event)" (click)="addSearchConfig($event)" title="Add Search Query">
                                                                            <i class="glyphicon glyphicon-plus"></i>
                                                                        </a>
                                                                    </h3>
                                                                </div>
                                                                <div class="panel-body">
                                                                    <div class="col-sm-12 picklist-padding fix-picklist-width">
                                                                        <ag-grid-angular  #agGridOcr [gridOptions]="OcrGridOptions" [columnDefs]="OcrGridColumnDefs" [rowData]="OcrGridData" (selectionChanged)="handleSelectedDoc($event)" (rowDoubleClicked)='btnEditDoubleClick($event)' pagination=true paginationPageSize="10" style="width: 100%; height: 325px !important;" class="ag-theme-balham">
                                                                        </ag-grid-angular>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div class="row" [hidden] = "!hideSearch">

                                                        <div class="form-group col-sm-5">
                                                            <fieldset>
                                                                <label class="label">Auto Search Batch Job (Scheduled)</label>
                                                                <label class="checkbox" style="width: 2px;">
                                                                    <span class="onoffswitch">
                                                                        <input type="checkbox" name="dpsstatus" (click)="ValidateInputToken()"
                                                                               class="onoffswitch-checkbox" checked="checked" id="dpsstatus" [disabled]="isValidInputField" [(ngModel)]="model.BatchAutoSearchStatus" #dpsstatus="ngModel">
                                                                        <label class="onoffswitch-label"
                                                                               for="dpsstatus">
                                                                            <span class="onoffswitch-inner" data-swchon-text="YES" data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span>
                                                                        </label>
                                                                    </span>
                                                                </label>
                                                            </fieldset>
                                                        </div>
                                                    </div>


                                                    <div class="row" [hidden] = "!hideSearch" *ngIf="model.BatchAutoSearchStatus">
                                                        <div class="form-group col-sm-12" style="margin-top:10px;">
                                                            <div class="panel panel-default" style="border-right-width: 2px;" id="configoptionalconditions">
                                                                <div class="panel-heading">
                                                                    <h3 class="panel-title panalheading-padding">
                                                                        Batch Auto Search
                                                                    </h3>
                                                                </div>
                                                                <div class="panel-body">
                                                                    <div class="panel panel-default panel-margin" style="border-right-width: 2px;">
                                                                        <div class="panel-heading">
                                                                            <h3 class="panel-title panalheading-padding">
                                                                                Selected Conditions
                                                                            </h3>
                                                                        </div>
                                                                        <div class="panel-body">
                                                                            <div class="row" style="padding:5px;">
                                                                                <div class="form-group col-sm-12">
                                                                                    <div class="panel panel-default panel-margin" style="border-right-width: 2px;">
                                                                                        <div class="panel-heading">
                                                                                            <h3 class="panel-title panalheading-padding">
                                                                                                Default Mandatory Conditions
                                                                                                <!--<a class="button-icon hand-cursor pull-right" style="padding-right: 5px;" id="btnAppCndAdd" (click)="addAppCondition()">
                                                                                                    <i class="glyphicon glyphicon-plus"></i>
                                                                                                </a>-->
                                                                                            </h3>
                                                                                        </div>
                                                                                        <div class="panel-body">
                                                                                            <div class="col-sm-12 picklist-padding fix-picklist-width">
                                                                                                <div class="table-responsive" style="max-height: 185px;">
                                                                                                    <table class="table table-hover">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>&nbsp;</th>
                                                                                                                <th>Field Name</th>
                                                                                                                <th>Condition</th>
                                                                                                                <th>Value</th>

                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            <tr *ngFor="let cndWithoutToken of model.ConditionsWithOutToken; let j = index;trackBy:trackByIndex">
                                                                                                                <td>
                                                                                                                    <select *ngIf="j!=0" class="form-control readonlyinput2 ng-untouched ng-pristine" disabled id="{{'defConditionsWithoutToken' + j }}" name="{{'defConditionsWithoutToken' + j }}" [(ngModel)]="cndWithoutToken.JoinCondition" style="width:200px;">
                                                                                                                        <option *ngFor="let w of whereConditions; trackBy:trackByIndex" value="{{w.value}}">{{w.label}}</option>
                                                                                                                    </select>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <select class="form-control readonlyinput2 ng-untouched ng-pristine" disabled id="{{'appdefCondFieldName' + j }}" name="{{'appdefCondFieldName' + j }}" [(ngModel)]="cndWithoutToken.FieldName" style="width:200px;">
                                                                                                                        <option *ngFor="let f of fieldsWithLable; trackBy:trackByIndex" value="{{f.value}}">{{f.label}}</option>
                                                                                                                    </select>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <select class="form-control readonlyinput2 ng-untouched ng-pristine" disabled id="{{'appdefCondition' + j }}" name="{{'appdefCondition' + j }}" [(ngModel)]="cndWithoutToken.ConditionType" style="width:200px;">
                                                                                                                        <option *ngFor="let c of conditions;trackBy:trackByIndex" value="{{c.value}}">{{c.label}}</option>
                                                                                                                    </select>
                                                                                                                </td>
                                                                                                                <td><input id="{{'appdefCndValue' + j }}" type="text" name="{{'appdefCndwithoutTokenValue' + j }}" [(ngModel)]="cndWithoutToken.Value" disabled class="input-sm readonlyinput2 ng-untouched ng-pristine" /></td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row" style="padding:5px;">
                                                                                <div class="form-group col-sm-12">
                                                                                    <div class="panel panel-default panel-margin" style="border-right-width: 2px;">
                                                                                        <div class="panel-heading">
                                                                                            <h3 class="panel-title panalheading-padding">
                                                                                                Default Configured Condition
                                                                                                <!--<a class="button-icon hand-cursor pull-right" style="padding-right: 5px;" id="btnAppCndAdd" (click)="addAppCondition()">
                                                                                                    <i class="glyphicon glyphicon-plus"></i>
                                                                                                </a>-->
                                                                                            </h3>
                                                                                        </div>
                                                                                        <div class="panel-body">
                                                                                            <div class="col-sm-12 picklist-padding fix-picklist-width">
                                                                                                <div class="table-responsive" style="max-height: 185px;">
                                                                                                    <table class="table table-hover">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>&nbsp;</th>
                                                                                                                <th>Field Name</th>
                                                                                                                <th>Condition</th>
                                                                                                                <th>Value</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            <tr *ngFor="let cndDefaultSelected of model.DefaultSelectedConditions; let j = index;trackBy:trackByIndex">
                                                                                                                <td>
                                                                                                                    <select *ngIf="j!=0" class="form-control readonlyinput2 ng-untouched ng-pristine" disabled id="{{'defaultSelectedConditions' + j }}" name="{{'defaultSelectedConditions' + j }}" [(ngModel)]="cndDefaultSelected.JoinCondition" style="width:200px;">
                                                                                                                        <option *ngFor="let w of whereConditions;trackBy:trackByIndex" value="{{w.value}}">{{w.label}}</option>
                                                                                                                    </select>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <select class="form-control readonlyinput2 ng-untouched ng-pristine" disabled id="{{'defaultSelectedAppCondFieldName' + j }}" name="{{'defaultSelectedAppCondFieldName' + j }}" [(ngModel)]="cndDefaultSelected.FieldName" style="width:200px;">
                                                                                                                        <option *ngFor="let f of fieldsWithLable;trackBy:trackByIndex" value="{{f.value}}">{{f.label}}</option>
                                                                                                                    </select>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <select class="form-control readonlyinput2 ng-untouched ng-pristine" disabled id="{{'defaultselectedappCondition' + j }}" name="{{'defaultselectedappCondition' + j }}" [(ngModel)]="cndDefaultSelected.ConditionType" style="width:200px;">
                                                                                                                        <option *ngFor="let c of conditions;trackBy:trackByIndex" value="{{c.value}}">{{c.label}}</option>
                                                                                                                    </select>
                                                                                                                </td>
                                                                                                                <td><input id="{{'defaultselectedappCndValue' + j }}" type="text" name="{{'defaultselectedappCndValue' + j }}" [(ngModel)]="cndDefaultSelected.Value" disabled class="input-sm readonlyinput2 ng-untouched ng-pristine" /></td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row" style="padding:5px;">
                                                                                <div class="form-group col-sm-12">
                                                                                    <div class="panel panel-default panel-margin" style="border-right-width: 2px;">
                                                                                        <div class="panel-heading">
                                                                                            <h3 class="panel-title panalheading-padding">
                                                                                                Additional (Optional) Conditions
                                                                                                <a class="button-icon hand-cursor pull-right tablIndexFocus" [attr.tabindex]="0" style="padding-right: 5px;" id="btnAppCndAdd"  (keyup.enter)="addAppCondition()" (click)="addAppCondition()">
                                                                                                    <i class="glyphicon glyphicon-plus"></i>
                                                                                                </a>
                                                                                            </h3>
                                                                                        </div>
                                                                                        <div class="panel-body">
                                                                                            <div class="col-sm-12 picklist-padding fix-picklist-width">
                                                                                                <div class="table-responsive" style="max-height: 185px;">
                                                                                                    <table class="table table-hover">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>&nbsp;</th>
                                                                                                                <th>Field Name</th>
                                                                                                                <th>Condition</th>
                                                                                                                <th>Value</th>
                                                                                                                <th>&nbsp;</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            <tr *ngFor="let cnd of model.SelectedConditions; let j = index; trackBy:trackByIndex">
                                                                                                                <td>
                                                                                                                    <select *ngIf="j!=0" class="form-control" id="{{'SelectedConditions' + j }}" name="{{'SelectedConditions' + j }}" [(ngModel)]="cnd.JoinCondition" style="width:200px;">
                                                                                                                        <option *ngFor="let w of whereConditions" value="{{w.value}}">{{w.label}}</option>
                                                                                                                    </select>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <!--<select class="form-control" id="{{'appCondFieldName' + j }}" name="{{'appCondFieldName' + j }}" [(ngModel)]="cnd.FieldName" style="width:200px;">
                                                                                                                        <option *ngFor="let f of fieldsWithLable" value="{{f.value}}">{{f.label}}</option>
                                                                                                                    </select>-->
                                                                                                                    <p-dropdown appendTo="body" class="form-control" id="appCondFieldName{{j}}" name="appCondFieldName{{j}}" [options]="fieldsWithLable" [editable]="false" [(ngModel)]="cnd.FieldName" placeholder="Select Field" [filter]="true"></p-dropdown>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <select class="form-control" id="{{'appCondition' + j }}" name="{{'appCondition' + j }}" [(ngModel)]="cnd.ConditionType" style="width:200px;">
                                                                                                                        <option *ngFor="let c of conditions" value="{{c.value}}">{{c.label}}</option>
                                                                                                                    </select>
                                                                                                                </td>
                                                                                                                <td><input id="{{'appCndValue' + j }}" type="text" name="{{'appCndValue' + j }}" [(ngModel)]="cnd.Value" class="input-sm" (blur)="cnd.Value=removeQuote($event.target.value)" /></td>
                                                                                                                <td>
                                                                                                                    <a class="button-icon hand-cursor pull-right tablIndexFocus" [attr.tabindex]="0" style="padding-right: 5px;" name="{{'btnAppCndDel' + j }}" id="{{'btnAppCndDel' + j }}" (keyup.enter)="deleteAppCondition(j)" (click)="deleteAppCondition(j)" >
                                                                                                                        <i class="glyphicon glyphicon-remove"></i>
                                                                                                                    </a>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="panel panel-default panel-margin" style="border-right-width: 2px;">
                                                                        <div class="panel-heading">
                                                                            <h3 class="panel-title panalheading-padding">
                                                                                Not Selected Conditions
                                                                            </h3>
                                                                        </div>
                                                                        <div class="panel-body">
                                                                            <div class="row" style="padding:5px;">
                                                                                <div class="form-group col-sm-12">
                                                                                    <div class="panel panel-default panel-margin" style="border-right-width: 2px;">
                                                                                        <div class="panel-heading">
                                                                                            <h3 class="panel-title panalheading-padding">
                                                                                                Default Mandatory Conditions
                                                                                                <!--<a class="button-icon hand-cursor pull-right" style="padding-right: 5px;" id="btnAppCndAdd" (click)="addAppCondition()">
                                                                                                    <i class="glyphicon glyphicon-plus"></i>
                                                                                                </a>-->
                                                                                            </h3>
                                                                                        </div>
                                                                                        <div class="panel-body">
                                                                                            <div class="col-sm-12 picklist-padding fix-picklist-width">
                                                                                                <div class="table-responsive" style="max-height: 185px;">
                                                                                                    <table class="table table-hover">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>&nbsp;</th>
                                                                                                                <th>Field Name</th>
                                                                                                                <th>Condition</th>
                                                                                                                <th>Value</th>

                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            <tr *ngFor="let ConditionsNotSelectedWithOutToken of model.ConditionsWithOutToken; let j = index;trackBy:trackByIndex">
                                                                                                                <td>
                                                                                                                    <select *ngIf="j!=0" class="form-control readonlyinput2 ng-untouched ng-pristine" disabled id="{{'cndmandatorySelectedConditions' + j }}" name="{{'cndmandatorySelectedConditions' + j }}" [(ngModel)]="ConditionsNotSelectedWithOutToken.JoinCondition" style="width:200px;">
                                                                                                                        <option *ngFor="let w of whereConditions;trackBy:trackByIndex" value="{{w.value}}">{{w.label}}</option>
                                                                                                                    </select>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <select class="form-control readonlyinput2 ng-untouched ng-pristine" disabled id="{{'cndmandatoryappCondFieldName' + j }}" name="{{'cndmandatoryappCondFieldName' + j }}" [(ngModel)]="ConditionsNotSelectedWithOutToken.FieldName" style="width:200px;">
                                                                                                                        <option *ngFor="let f of fieldsWithLable; trackBy:trackByIndex" value="{{f.value}}">{{f.label}}</option>
                                                                                                                    </select>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <select class="form-control readonlyinput2 ng-untouched ng-pristine" disabled id="{{'cndmandatoryappCondition' + j }}" name="{{'cndmandatoryappCondition' + j }}" [(ngModel)]="ConditionsNotSelectedWithOutToken.ConditionType" style="width:200px;">
                                                                                                                        <option *ngFor="let c of conditions; trackBy:trackByIndex" value="{{c.value}}">{{c.label}}</option>
                                                                                                                    </select>
                                                                                                                </td>
                                                                                                                <td><input id="{{'cndmandatoryappCndValue' + j }}" type="text" name="{{'cndmandatoryappCndValue' + j }}" [(ngModel)]="ConditionsNotSelectedWithOutToken.Value" disabled class="input-sm readonlyinput2 ng-untouched ng-pristine" /></td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row" style="padding:5px;">
                                                                                <div class="form-group col-sm-12" style="margin-top:10px;">
                                                                                    <div class="panel panel-default panel-margin" style="border-right-width: 2px;">
                                                                                        <div class="panel-heading">
                                                                                            <h3 class="panel-title panalheading-padding">
                                                                                                Default Configured Condition
                                                                                                <!--<a class="button-icon hand-cursor pull-right" style="padding-right: 5px;" id="btnCndAdd" (click)="addNonAppCondition()">
                                                                                                    <i class="glyphicon glyphicon-plus"></i>
                                                                                                </a>-->
                                                                                            </h3>
                                                                                        </div>
                                                                                        <div class="panel-body">
                                                                                            <div class="col-sm-12 picklist-padding fix-picklist-width">
                                                                                                <div class="table-responsive" style="max-height: 185px;">
                                                                                                    <table class="table table-hover">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>&nbsp;</th>
                                                                                                                <th>Field Name</th>
                                                                                                                <th>Condition</th>
                                                                                                                <th>Value</th>

                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            <tr *ngFor="let defaultnotselectedcnd of model.DefaultNotSelectedConditions; let j = index;trackBy:trackByIndex">
                                                                                                                <td>
                                                                                                                    <select *ngIf="j!=0" class="form-control readonlyinput2 ng-untouched ng-pristine" disabled id="{{'defaultnotselectedJoinCondition' + j }}" name="{{'defaultnotselectedJoinCondition' + j }}" [(ngModel)]="defaultnotselectedcnd.JoinCondition" style="width:200px;">
                                                                                                                        <option *ngFor="let w of whereConditions;trackBy:trackByIndex" value="{{w.value}}">{{w.label}}</option>
                                                                                                                    </select>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <select class="form-control readonlyinput2 ng-untouched ng-pristine" disabled id="{{'defaultnotselectedcndFieldName' + j }}" name="{{'defaultnotselectedcndFieldName' + j }}" [(ngModel)]="defaultnotselectedcnd.FieldName" style="width:200px;">
                                                                                                                        <option *ngFor="let f of fieldsWithLable;trackBy:trackByIndex" value="{{f.value}}">{{f.label}}</option>
                                                                                                                    </select>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <select class="form-control readonlyinput2 ng-untouched ng-pristine" disabled id="{{'defaultnotselectedcndCondition' + j }}" name="{{'defaultnotselectedcndCondition' + j }}" [(ngModel)]="defaultnotselectedcnd.ConditionType" style="width:200px;">
                                                                                                                        <option *ngFor="let c of conditions;trackBy:trackByIndex" value="{{c.value}}">{{c.label}}</option>
                                                                                                                    </select>
                                                                                                                </td>
                                                                                                                <td><input id="{{'defaultnotselectedValue' + j }}" type="text" name="{{'defaultnotselectedValue' + j }}" [(ngModel)]="defaultnotselectedcnd.Value" class="input-sm readonlyinput2 ng-untouched ng-pristine" disabled /></td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row" style="padding:5px;">
                                                                                <div class="form-group col-sm-12" style="margin-top:10px;">
                                                                                    <div class="panel panel-default panel-margin" style="border-right-width: 2px;">
                                                                                        <div class="panel-heading">
                                                                                            <h3 class="panel-title panalheading-padding">
                                                                                                Additional Conditions
                                                                                                <a class="button-icon hand-cursor pull-right tablIndexFocus" [attr.tabindex]="0" style="padding-right: 5px;" id="btnCndAdd" (keyup.enter)="addNonAppCondition()" (click)="addNonAppCondition()">
                                                                                                    <i class="glyphicon glyphicon-plus"></i>
                                                                                                </a>
                                                                                            </h3>
                                                                                        </div>
                                                                                        <div class="panel-body">
                                                                                            <div class="col-sm-12 picklist-padding fix-picklist-width">
                                                                                                <div class="table-responsive" style="max-height: 185px;">
                                                                                                    <table class="table table-hover">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>&nbsp;</th>
                                                                                                                <th>Field Name</th>
                                                                                                                <th>Condition</th>
                                                                                                                <th>Value</th>
                                                                                                                <th>&nbsp;</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            <tr *ngFor="let NonSelectedcnd of model.NonSelectedConditions; let j = index; trackBy:trackByIndex">
                                                                                                                <td>
                                                                                                                    <select *ngIf="j!=0" class="form-control" id="{{'nonAppWhereCondition' + j }}" name="{{'nonAppWhereCondition' + j }}" [(ngModel)]="NonSelectedcnd.JoinCondition" style="width:200px;">
                                                                                                                        <option *ngFor="let w of whereConditions;trackBy:trackByIndex" value="{{w.value}}">{{w.label}}</option>
                                                                                                                    </select>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <!--<select class="form-control" id="{{'nonAppCondFieldName' + j }}" name="{{'nonAppCondFieldName' + j }}" [(ngModel)]="NonSelectedcnd.FieldName" style="width:200px;">
                                                                                                                        <option *ngFor="let f of fieldsWithLable" value="{{f.value}}">{{f.label}}</option>
                                                                                                                    </select>-->
                                                                                                                    <p-dropdown appendTo="body" class="form-control" id="nonAppCondFieldName{{j}}" name="nonAppCondFieldName{{j}}" [options]="fieldsWithLable" [editable]="false" [(ngModel)]="NonSelectedcnd.FieldName" placeholder="Select Field" [filter]="true"></p-dropdown>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <select class="form-control" id="{{'nonAppCondition' + j }}" name="{{'nonAppCondition' + j }}" [(ngModel)]="NonSelectedcnd.ConditionType" style="width:200px;">
                                                                                                                        <option *ngFor="let c of conditions;trackBy:trackByIndex" value="{{c.value}}">{{c.label}}</option>
                                                                                                                    </select>
                                                                                                                </td>
                                                                                                                <td><input id="{{'nonAppCndValue' + j }}" type="text" name="{{'nonAppCndValue' + j }}" [(ngModel)]="NonSelectedcnd.Value" class="input-sm" (blur)="cnd.Value=removeQuote($event.target.value)" /></td>
                                                                                                                <td>
                                                                                                                    <a class="button-icon hand-cursor pull-right tablIndexFocus" [attr.tabindex]="0" style="padding-right: 5px;" name="{{'btnNonAppCndDel' + j }}" id="{{'btnNonAppCndDel' + j }}" (keyup.enter)="deleteNonAppCondition(j)" (click)="deleteNonAppCondition(j)">
                                                                                                                        <i class="glyphicon glyphicon-remove"></i>
                                                                                                                    </a>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="hr33">
                                                    <div class="panel panel-default" style="border-right-width: 2px;">
                                                        <div class="panel-heading">
                                                            <h3 class="panel-title panalheading-padding">
                                                                Cover Sheet
                                                            </h3>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="row">
                                                                <div class="form-group col-sm-12" style="margin-top:10px;">
                                                                    <div class="panel panel-default" style="border-right-width: 2px;">
                                                                        <div class="panel-heading">
                                                                            <h3 class="panel-title panalheading-padding">
                                                                                Header
                                                                            </h3>
                                                                        </div>
                                                                        <div class="panel-body">
                                                                            <div class="form-group col-sm-12" style="padding:0px;">
                                                                                <fieldset>
                                                                                    <label class="label"></label>
                                                                                    <label class="textarea">
                                                                                        <textarea rows="1" class="custom-scroll" name="CoverSheetHeader"
                                                                                            [(ngModel)]="model.CoverSheetHeader"></textarea>
                                                                                    </label>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="form-group col-sm-12" style="margin-top:10px;">
                                                                    <div class="panel panel-default" style="border-right-width: 2px;">
                                                                        <div class="panel-heading">
                                                                            <h3 class="panel-title panalheading-padding">
                                                                                Title
                                                                            </h3>
                                                                        </div>
                                                                        <div class="panel-body">
                                                                            <div class="form-group col-sm-12" style="padding:0px;">
                                                                                <fieldset>
                                                                                    <label class="label"></label>
                                                                                    <label class="textarea">
                                                                                        <textarea rows="1" class="custom-scroll" name="CoverSheetTitle"
                                                                                            [(ngModel)]="model.CoverSheetTitle"></textarea>
                                                                                    </label>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="form-group col-sm-12" style="margin-top:10px;">
                                                                    <div class="panel panel-default" style="border-right-width: 2px;">
                                                                        <div class="panel-heading">
                                                                            <h3 class="panel-title panalheading-padding">
                                                                                Content
                                                                            </h3>
                                                                        </div>
                                                                        <div class="panel-body">
                                                                            <div class="form-group col-sm-12" style="padding:0px;">
                                                                                <fieldset>
                                                                                    <label class="label"></label>
                                                                                    <label class="textarea">
                                                                                        <textarea rows="15" class="custom-scroll" name="CoverSheetContent"
                                                                                            [(ngModel)]="model.CoverSheetContent"></textarea>
                                                                                    </label>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="form-group col-sm-12" style="margin-top:10px;">
                                                                    <div class="panel panel-default" style="border-right-width: 2px;">
                                                                        <div class="panel-heading">
                                                                            <h3 class="panel-title panalheading-padding">
                                                                                Footer
                                                                            </h3>
                                                                        </div>
                                                                        <div class="panel-body">
                                                                            <div class="form-group col-sm-12" style="padding:0px;">
                                                                                <fieldset>
                                                                                    <label class="label"></label>
                                                                                    <label class="textarea">
                                                                                        <textarea rows="1" class="custom-scroll" name="CoverSheetFooter"
                                                                                            [(ngModel)]="model.CoverSheetFooter"></textarea>
                                                                                    </label>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="hr44">
                                                    <ul id="widget-tab-2" class="nav nav-tabs">
                                                        <li id="isPDFExportStatusData" [ngClass]="(isPDFExportStatusData==true)?'active':''">
                                                            <a #panal2 data-toggle="tab" [attr.aria-expanded]="isPDFExportStatusData" [attr.tabindex]="0"
                                                                data-target="#isPDFExportStatusData" style="cursor: pointer;"
                                                                (keyup.enter)="getPDFExportSettingsTab('isPDFExportStatusData',true,$event)"
                                                                (click)="getPDFExportSettingsTab('isPDFExportStatusData',true,$event)"><span
                                                                    class="hidden-mobile hidden-tablet" style="padding-left: 3px">PDF</span>
                                                            </a>
                                                        </li>
                                                        <li id="isExcelAPIExportStatusData" [ngClass]="(isExcelAPIExportStatusData==true)?'active':''">
                                                            <a #panal1 data-toggle="tab" [attr.tabindex]="0" data-target="#isExcelAPIExportStatusData"
                                                                [attr.aria-expanded]="isExcelAPIExportStatusData" style="cursor: pointer;"
                                                                (keyup.enter)="getPDFExportSettingsTab('isExcelAPIExportStatusData',true,$event)"
                                                                (click)="getPDFExportSettingsTab('isExcelAPIExportStatusData',true,$event)">
                                                                <span class="hidden-mobile hidden-tablet" style="padding-left: 3px">Excel API</span>
                                                            </a>
                                                        </li>
                                                        <li id="isExcelGridExportStatusData" [ngClass]="(isExcelGridExportStatusData==true)?'active':''">
                                                            <a #panal1 data-toggle="tab" [attr.tabindex]="0" data-target="#isExcelGridExportStatusData"
                                                                [attr.aria-expanded]="isExcelGridExportStatusData" style="cursor: pointer;"
                                                                (keyup.enter)="getPDFExportSettingsTab('isExcelGridExportStatusData',true,$event)"
                                                                (click)="getPDFExportSettingsTab('isExcelGridExportStatusData',true,$event)">
                                                                <span class="hidden-mobile hidden-tablet" style="padding-left: 3px">Excel Grid</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                
                                                    <div class="tab-content">
                                                
                                                        <div class="tab-pane fade margin-configuration" [ngClass]="(isPDFExportStatusData==true)?'active in':''"
                                                            id="isPDFExportStatusData">
                                                            <div class="panel panel-default" style="border-right-width: 2px;">
                                                                <div class="panel-heading">
                                                                    <h3 class="panel-title panalheading-padding">
                                                                        PDF
                                                                    </h3>
                                                                </div>
                                                                <div class="panel-body">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-12">
                                                                            <div class="panel panel-default" style="margin:2px; border-right-width: 2px;">
                                                                                <div class="panel-heading">
                                                                                    <h3 class="panel-title panalheading-padding">
                                                                                        Header
                                                                                    </h3>
                                                                                </div>
                                                                                <div class="panel-body">
                                                                                    <div class="form-group col-sm-12" style="padding:0px;">
                                                                                        <label class="label"></label>
                                                                                        <!-- placeholder="Email Subject" -->
                                                                                        <!-- <textarea id="pdfexportheader" type="text" name="pdfexportheader" [(ngModel)]="model.PDFExportHeader" #pdfexportheader
                                                                                                                                                                                    class="custom-scrollNote no-overflow-x" rows="10"></textarea> -->
                                                                                        <fieldset>
                                                                                            <label class="textarea"><textarea id="pdfexportheader" type="text" name="pdfexportheader"
                                                                                                    [(ngModel)]="model.PDFExportHeader" #pdfexportheader class="custom-scrollNote no-overflow-x"
                                                                                                    rows="10"></textarea>
                                                                                            </label>
                                                                                            Tokens are:
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_UserName)">{{_sharedService.Token_UserName}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_DateTime)">{{_sharedService.Token_DateTime}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_CurrentIP)">{{_sharedService.Token_CurrentIP}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_UserUniqueID)">{{_sharedService.Token_UserUniqueID}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_SignImage)">{{_sharedService.Token_SignImage}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_UserLogin)">{{_sharedService.Token_UserLogin}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_TenantLogo)">{{_sharedService.Token_TenantLogo}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_ViewDisplayName)">{{_sharedService.Token_ViewDisplayName}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_ROAMLogo)">{{_sharedService.Token_ROAMLogo}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_AssetManufacturer)">{{_sharedService.Token_AssetManufacturer}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_AssetManType)">{{_sharedService.Token_AssetManType}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_AssetManVariant)">{{_sharedService.Token_AssetManVariant}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_AssetTime)">{{_sharedService.Token_AssetTime}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_CurrentDate)">{{_sharedService.Token_CurrentDate}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_CurrentTime)">{{_sharedService.Token_CurrentTime}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_PageNumber)">{{_sharedService.Token_PageNumber}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_AssetCycle)">{{_sharedService.Token_AssetCycle}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_AssetSerialNo)">{{_sharedService.Token_AssetSerialNo}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_AssetReg)">{{_sharedService.Token_AssetReg}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_ParentAssetReg)">{{_sharedService.Token_ParentAssetReg}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_ParentAssetTitle)">{{_sharedService.Token_ParentAssetTitle}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_ParentAssetManufacturer)">{{_sharedService.Token_ParentAssetManufacturer}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_ParentAssetManType)">{{_sharedService.Token_ParentAssetManType}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_ParentAssetManVariant)">{{_sharedService.Token_ParentAssetManVariant}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_ParentAssetTime)">{{_sharedService.Token_ParentAssetTime}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_ParentAssetCycle)">{{_sharedService.Token_ParentAssetCycle}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_AssetPartNo)">{{_sharedService.Token_AssetPartNo}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_ASN)">{{_sharedService.Token_ASN}}
                                                                                            </label>,
                                                                                            <!-- <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_AirframeTitle)">{{_sharedService.Token_AirframeTitle}}
                                                                                            </label>, -->
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_AssetType)">{{_sharedService.Token_AssetType}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_AssetTitle)">{{_sharedService.Token_AssetTitle}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_DateTime)">{{_sharedService.Token_DateTime}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_ViewTitle)">{{_sharedService.Token_ViewTitle}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_FilterComplianceStatus)">{{_sharedService.Token_FilterComplianceStatus}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportheader', _sharedService.Token_Encryptedcode)">{{_sharedService.Token_Encryptedcode}}
                                                                                            </label>
                                                                                            <div class=""></div>
                                                                                
                                                                                        </fieldset>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-12">
                                                                            <div class="panel panel-default" style="margin:2px; border-right-width: 2px;">
                                                                                <div class="panel-heading">
                                                                                    <h3 class="panel-title panalheading-padding">
                                                                                        Footer
                                                                                    </h3>
                                                                                </div>
                                                                                <div class="panel-body">
                                                                                    <div class="form-group col-sm-12" style="padding:0px;">
                                                                                        <fieldset>
                                                                                            <label class="label"></label>
                                                                                            <label class="textarea"><textarea
                                                                                                id="pdfexportfooter" type="text" name="pdfexportfooter"
                                                                                                    [(ngModel)]="model.PDFExportFooter" #pdfexportfooter
                                                                                                    class="custom-scrollNote no-overflow-x"
                                                                                                    rows="10"></textarea>
                                                                                                </label>
                                                                                            <label class="input">
                                                                                                <!-- placeholder="Email Subject" -->
                                                                                                <!-- <input id="pdfexportfooter" type="text" name="pdfexportfooter"
                                                                                                    [(ngModel)]="model.PDFExportFooter" #pdfexportfooter> -->
                                                
                                                                                            </label>
                                                
                                                                                            Tokens are:
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_UserName)">{{_sharedService.Token_UserName}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_DateTime)">{{_sharedService.Token_DateTime}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_CurrentIP)">{{_sharedService.Token_CurrentIP}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_UserUniqueID)">{{_sharedService.Token_UserUniqueID}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_SignImage)">{{_sharedService.Token_SignImage}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_UserLogin)">{{_sharedService.Token_UserLogin}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_TenantLogo)">{{_sharedService.Token_TenantLogo}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_ASN)">{{_sharedService.Token_ASN}}
                                                                                            </label>,
                                                                                            <!-- <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_AirframeTitle)">{{_sharedService.Token_AirframeTitle}}
                                                                                            </label>, -->
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_ViewDisplayName)">{{_sharedService.Token_ViewDisplayName}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_ROAMLogo)">{{_sharedService.Token_ROAMLogo}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_AssetManufacturer)">{{_sharedService.Token_AssetManufacturer}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_AssetManType)">{{_sharedService.Token_AssetManType}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_AssetManVariant)">{{_sharedService.Token_AssetManVariant}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_AssetType)">{{_sharedService.Token_AssetType}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_AssetTime)">{{_sharedService.Token_AssetTime}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_CurrentDate)">{{_sharedService.Token_CurrentDate}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_CurrentTime)">{{_sharedService.Token_CurrentTime}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_PageNumber)">{{_sharedService.Token_PageNumber}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_AssetCycle)">{{_sharedService.Token_AssetCycle}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_AssetSerialNo)">{{_sharedService.Token_AssetSerialNo}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_AssetReg)">{{_sharedService.Token_AssetReg}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_ParentAssetReg)">{{_sharedService.Token_ParentAssetReg}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_ParentAssetTitle)">{{_sharedService.Token_ParentAssetTitle}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_ParentAssetManufacturer)">{{_sharedService.Token_ParentAssetManufacturer}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_ParentAssetManType)">{{_sharedService.Token_ParentAssetManType}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_ParentAssetManVariant)">{{_sharedService.Token_ParentAssetManVariant}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_ParentAssetTime)">{{_sharedService.Token_ParentAssetTime}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_ParentAssetCycle)">{{_sharedService.Token_ParentAssetCycle}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_AssetPartNo)">{{_sharedService.Token_AssetPartNo}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_AssetTitle)">{{_sharedService.Token_AssetTitle}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_DateTime)">{{_sharedService.Token_DateTime}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_ViewTitle)">{{_sharedService.Token_ViewTitle}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_FilterComplianceStatus)">{{_sharedService.Token_FilterComplianceStatus}}
                                                                                            </label>,
                                                                                            <label class="token"
                                                                                                (click)="AssignPDFExportToken('pdfexportfooter', _sharedService.Token_Encryptedcode)">{{_sharedService.Token_Encryptedcode}}
                                                                                            </label>
                                                                                            <div class=""></div>
                                                                                        </fieldset>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                
                                                        <div class="tab-pane fade margin-configuration" [ngClass]="(isExcelAPIExportStatusData==true)?'active in':''"
                                                            id="isExcelAPIExportStatusData">
                                                            <div class="panel panel-default" style="border-right-width: 2px;">
                                                                <div class="panel-heading">
                                                                    <h3 class="panel-title panalheading-padding">
                                                                        Excel Api
                                                                    </h3>
                                                                </div>
                                                                <div class="panel-body">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-12">
                                                                            <div class="panel panel-default" style="margin:2px; border-right-width: 2px;">
                                                                                <div class="panel-heading">
                                                                                    <h3 class="panel-title panalheading-padding">
                                                                                        Header
                                                                                    </h3>
                                                                                </div>
                                                                                <div class="panel-body">
                                                                                    <div class="form-group col-sm-12" style="padding:0px;">
                                                                                        <fieldset>
                                                                                            <label class="label"></label>
                                                                                            <label class="input">
                                                                                                <label class="textarea"><textarea
                                                                                                    id="ExcelAPIExport" type="text" name="ExcelAPIExport"
                                                                                                        [(ngModel)]="model.ExcelAPIExport" #ExcelAPIExport
                                                                                                        class="custom-scrollNote no-overflow-x"
                                                                                                        rows="10"></textarea>
                                                                                                    </label>                                                
                                                                                            </label>
                                                
                                                                                        </fieldset>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                
                                                        <div class="tab-pane fade margin-configuration" [ngClass]="(isExcelGridExportStatusData==true)?'active in':''"
                                                            id="isExcelGridExportStatusData">
                                                            <div class="panel panel-default" style="border-right-width: 2px;">
                                                                <div class="panel-heading">
                                                                    <h3 class="panel-title panalheading-padding">
                                                                        Excel Grid
                                                                    </h3>
                                                                </div>
                                                                <div class="panel-body">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-12">
                                                                            <div class="panel panel-default" style="margin:2px; border-right-width: 2px;">
                                                                                <div class="panel-heading">
                                                                                    <h3 class="panel-title panalheading-padding">
                                                                                        Header
                                                                                    </h3>
                                                                                </div>
                                                                                <div class="panel-body">
                                                                                    <div class="form-group col-sm-12" style="padding:0px;">
                                                                                        <fieldset>
                                                                                            <label class="label"></label>
                                                                                            <label class="textarea"><textarea
                                                                                                id="ExcelGridExport" type="text" name="ExcelGridExport"
                                                                                                    [(ngModel)]="model.ExcelGridExport" #ExcelGridExport
                                                                                                    class="custom-scrollNote no-overflow-x"
                                                                                                    rows="10"></textarea>
                                                                                                </label>                                                
                                                                                        </fieldset>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="hr55">
                                                    <div class="panel panel-default" style="border-right-width: 2px;">
                                                        <div class="panel-heading">
                                                            <h3 class="panel-title panalheading-padding">
                                                                Chart Settings
                                                            </h3>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="row">
                                                                <div class="form-group col-sm-12" style="margin-top:10px;">
                                                                        <div class="panel panel-default" style="border-right-width: 2px;">
                                                                            <div class="panel-heading">
                                                                                <h3 class="panel-title panalheading-padding">
                                                                                    Configuration
                                                                                </h3>
                                                                            </div>
                                                                            <div class="panel-body">
                                                                                <div class="form-group col-sm-12" style="padding:0px;">
                                                                                    <fieldset>
                                                                                        <label class="label"></label>
                                                                                        <label class="textarea">
                                                                                            <textarea rows="10" class="custom-scroll" name="ChartConfiguration"
                                                                                                [(ngModel)]="model.ChartConfiguration"></textarea>
                                                                                        </label>
                                                                                    </fieldset>
                                                                                </div>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div class="widget-footer">
                            <button type="submit" class="btn btn-primary margin-right-3px" (click)="addUpdateView()" [disabled]="!addForm.form.valid || isSubmitted || (model.AllAsset==false && (model.selectedAssets && model.selectedAssets.length == 0))">
                                Save
                            </button>
                            <button type="button" class="btn btn-default" [disabled]="isSubmitted" (click)="btnCancel()">
                                Cancel
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            <!-- end widget -->
        </article>
        <!-- END COL -->
    </div>


    <p-dialog *ngIf="displayViewDlg" [(visible)]="displayViewDlg" showEffect="fade" [modal]="true" [style]="{width: '830px'}" [resizable]="false">
        <ng-template pTemplate="header">
            Copy View
        </ng-template>
        <div class="upload-dialog">
            <ag-grid-angular  *ngIf="displayViewDlg"   #agGridCopyView [gridOptions]="gridOptions" [columnDefs]="columnDefs" (rowClicked)='updateSelectedViewRow($event)' [rowData]="gridData" filter=true style="width: 100%;height:350px" class="ag-theme-balham">
            </ag-grid-angular>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="submit" pButton icon="fa fa-check" class="btn btn-primary minzindex" label="Copy" (click)="btnCopyViewClick()"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" label="Cancel" (click)="btnCancelDialogueClick()"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>

<form  #defaultContainerForm="ngForm" *ngIf="displayContainerDlg">
    <p-dialog [(visible)]="displayContainerDlg" *ngIf="displayContainerDlg" showEffect="fade" [modal]="true" [style]="{width: '720px'}" [resizable]="false">
        <ng-template pTemplate="header">
            Select Default Container
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row" style="display: flex;align-items: end;">
                    <div class="form-group col-sm-5 fix-Padding">
                        <fieldset>
                            <label class="label">Asset 
                                <span style="margin-left: 5px;font-size: 12px;">[ Use Selected Asset ]  
                                    <p-checkbox id="selectedAsset" name="selectedAsset" [(ngModel)]="useSelectedAsset" binary="true"></p-checkbox></span>
                            </label>
                            <p-dropdown appendTo="body" id="asn" class="comboFit" name="asn" [options]="assets" (onChange)="onChangeAsset($event)" placeholder="Select Asset" required [(ngModel)]="containerModel.asn" #asn="ngModel" [filter]="true" #asncopymovefilter [resetFilterOnHide]="true">
                                <ng-template style="padding-bottom:20px;padding-left: 0px !important;" let-item pTemplate="selectedItem">
                                    <span>{{item.label}}</span>
                                    <!-- <span style="font-size:14px;background-color:lightgreen;">{{item.AssetType}}</span> -->
                                </ng-template>
                                <ng-template  style="padding-bottom:20px !important;padding-left: 0px !important;" let-asset pTemplate="item">
                                    <div style="padding-bottom: 20px;">
                                        <!-- <span > -->
                                            <span style="padding:0" class="col-md-8">{{asset.label}}</span>

                                        <!-- </div> -->
                                        <!-- <div class="col-md-4"> -->
                                            <span style="padding:0" class="col-md-4">
                                                <span  style="font-size: 11px;padding: 4px; background-color:lightgreen;color: white;border-radius: 4px;">
                                                    {{asset.AssetType}}
                                            </span>
                                            </span>

                                    <!-- </div> -->
                                    </div>
                                </ng-template>
                            </p-dropdown>

                            <!-- <p-dropdown id="asn" class="comboFit" name="asn" [options]="assets" (onChange)="onChangeAsset($event)" placeholder="Select Asset" required [(ngModel)]="containerModel.asn" #asn="ngModel" [filter]="true" #asncopymovefilter [resetFilterOnHide]="true">
                            </p-dropdown> -->
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-5 fix-Padding">
                        <fieldset>
                            <label class="label">Category </label>
                            <p-dropdown appendTo="body" id="category" name="category"  class="comboFit" [options]="categories" (onChange)="onChangeCategory($event)" placeholder="Select Category" [(ngModel)]="containerModel.category" #category="ngModel">
                            </p-dropdown>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-2" style="margin-bottom:.3rem">
                        <fieldset class="pull-right" >
                            <label class="label" style="height: 19px;"></label>
                            <button pButton style="height: 29px;" class="custom-button" type="button" (click)="populateContainerGrid()" icon="fa fa-search" label="Search" [disabled]="!defaultContainerForm.form.valid"></button>
                        </fieldset>
                    </div>
                </div>
               
                <div class="row" style="margin-top: 5px;">
                    <div class="form-group col-sm-12">
                        <ag-grid-angular   #containerGrid [gridOptions]="conGridOptions" [columnDefs]="conColumnDefs" [rowData]="conGridData" (componentStateChanged) ="formatContainerGrid($event)" enableFiltering sortable="true" style="width: 100% !important; height:300px !important;" class="ag-theme-balham" (rowClicked)='onContainerRowClick($event)'>
                            <!--(rowClicked)='updateSelectedDocument($event)'-->
                        </ag-grid-angular>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex" (click)="btnSelectContainerClick(defaultContainerForm)" [disabled]="!defaultContainerForm.form.valid ||!conRowSelected" label="Select Container"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" (click)="btnCancelContainerClick(defaultContainerForm)" label="Cancel"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>

    <p-dialog [(visible)]="displayTargetNodeDialog" showEffect="fade" [modal]="true" [style]="{width: '400px'}"
        [resizable]="false">
        <ng-template pTemplate="header">
            Select Target Node
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row">
                    <!-- <div class="input-group input-group-md">
                        <span class="input-group-addon"><i class="glyphicon glyphicon-filter"></i></span>
                        <div class="icon-addon addon-md">                           
                            <input type="text" placeholder="Search" class="form-control" id="fontstyle" [(ngModel)]="searchTreeNode" (ngModelChange)="modelChanged($event)" (mousedown)="txtSearchMouseDown()" (blur)="onBlur('child')"/>
                            <label for="search" class="glyphicon glyphicon-search" rel="tooltip" title="" data-original-title="search"></label>
                        </div>
                    </div> -->
                    <div class="form-group col-sm-12">
                        <p-tree [filter]="true" filterMode="strict" (mousedown)="txtSearchMouseDown()" [value]="spec2500NodeData" selectionMode="checkbox" class="set-tree-padding-for-scroll  set-tree-Height" [(selection)]="selectedspec2500Node" (onNodeSelect)="spec2500NodeSelect($event)" (onNodeUnselect)="spec2500NodeUnselect($event)"
                        propagateSelectionUp=false propagateSelectionDown=false [style]="{'width':'100%','overflow':'auto','overflow-y': 'hidden','background-color':'#eee'}"></p-tree>
                  
                    </div>
                </div>

            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton class="btn btn-default minzindex pull-left"
                    (click)="btnClearTreeNode()" label="Clear" [attr.tabindex]="2"></button>
                    <div class="pull-right">
                        <button type="button" pButton  class="btn btn-default minzindex"
                        (click)="btnSaveTreeNode()" label="Save" [attr.tabindex]="3"></button>
                        <button type="button" pButton class="btn btn-default minzindex"
                        (click)="btnCloseTreeNode()" label="Close" [attr.tabindex]="4"></button>
                    </div>


            </div>
        </ng-template>

    </p-dialog>
<p-confirmDialog></p-confirmDialog>