//Components
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { GridOptions } from "ag-grid-community";
import { AssetService } from '../../services/asset/asset.service';
import { SearchService } from '../../services/search/search.service';
import { AssetModel } from '../../services/asset/asset';
import { AssetUserModel } from '../../services/asset/asset-user';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'edit-aircraft',
    templateUrl: './edit-aircraft.component.html',
    providers: [AssetService, SearchService, ConfirmationService]
})

export class EditAircraftComponent implements OnInit {
    aircraftID: string;
    hasAssetId: boolean;
    assetType: string = "subAsset";
    public addAircraftPath: string = "/admin/asset";
    public gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[];
    public canAddEditAirCraft: boolean = false;
    public isSysAdmin: boolean = false;
    public isAdminAircraft: boolean = false;
    public disableReadOnlyButton: boolean = false;
    isSubmitted: boolean = false;
    display: boolean = false;
    displayAircraftUser: boolean = false;

    model: AssetModel;
    assetModelClone: AssetModel;
    assetTypeList: SelectItem[] = [];
    searchDatabaseList: SelectItem[] = [];
    ASNTitle: string = "";
    ASNTitleAirframe: string = "Asset Serial No.";
    ASNTitleCollection: string = "Number";

    ManufacturerTitle: string = "";
    ManufacturerTitleAirframe: string = "Manufacturer";
    ManufacturerTitleCollection: string = "Group Level 1";

    TypeTitle: string = "";
    TypeTitleAirframe: string = "Type";
    TypeTitleCollection: string = "Group Level 2";

    VariantTitle: string = "";
    VariantTitleAirframe: string = "Variant";
    VariantTitleCollection: string = "Group Level 3";
    assetCategory: string = "";
    assetUserModel: AssetUserModel
    assetList: SelectItem[] = [];
    billingPlanList: any[] = [];
    isDisplayBillingPlanForm: boolean = false;
    selectedBillingPlan: any = null;
    htmlBillingPlanDescription: SafeHtml;

    constructor(public sharedService: SharedService, private router: Router, private route: ActivatedRoute, private assetManagementService: AssetService,
        private adminSearchService: SearchService, private _confirmationService: ConfirmationService, private sanitizer: DomSanitizer) {
        if (!this.sharedService.isAuthUser()) {
            this.router.navigate(['login']);
            this.sharedService.SessionExpiredMessage();
        }
        else {
            this.updateRoles();
        }
    }
    ngOnInit() {
        if (this.canAddEditAirCraft) {
            this.getAssetTypes();
            let assetID: string = this.route.snapshot.params["id"];
            this.hasAssetId = assetID != undefined;
            if (this.hasAssetId) {
                this.initializeForm(assetID);
                this.aircraftID = assetID;
                this.assetType = "subAsset";
            } else {
                this.assetType = "aircraft";
                this.setDefaultValues("Airframe");
            }
            this.populateBillingPlans();
        }
    }

    populateBillingPlans() {
        this.billingPlanList = [];

        this.assetManagementService.getAllBillingPlans()
        .subscribe({
            next: (response) => {
                this.billingPlanList = response;
            },
            error: (error) => {
                console.log(error)
            }
        });
    }

    ngOnDestroy() {

    }

    initializeForm(assetD: string): void {
        if (assetD) {
            this.model = new AssetModel();
            this.assetModelClone = new AssetModel();
            this.loadAssetData(assetD);
            this.populateSearchDatabase();
            this.getAutoRunGapAnalyserValue();
        }

    }

    loadAssetData(assetID: string): void {

        let userRole: string = localStorage.getItem('roles').trim();

        this.assetManagementService.getAssetbyID(assetID)
        .subscribe({
            next: (response) => {
                //   this.gridOptions.api.sizeColumnsToFit();
        
                this.model = response;
                Object.assign(this.assetModelClone, this.model);
                this.updateReadOnly();
                this.sharedService.selectedAsset = this.model;
                this.setDefaultValues(response);
            },
            error: (error) => {
                console.log(error)
            }
        })
    }

    updateReadOnly() {
        if (this.isSysAdmin) {
            this.disableReadOnlyButton = false;
        } else {
            if (this.isAdminAircraft && this.model.ReadOnly == false) {
                this.disableReadOnlyButton = false;
            } else {
                this.disableReadOnlyButton = true;
            }
        }

    }



    ValidateStatusFlag(event: any) {
        this.model.ShouldStatusReflectToChilds = false;

        if (this.model.Status != this.assetModelClone.Status && !this.isSubmitted) {
            this._confirmationService.confirm({
                message: 'Would you like to reflect this Status to child Assets/Items? Changes will be applicable after Saving the asset',
                header: this.model.AssetType + ' Status is changed!',
                icon: 'fa fa-exclamation-triangle',
                accept: () => {
                    this.model.ShouldStatusReflectToChilds = true;
                },
                reject: () => {
                    this.model.ShouldStatusReflectToChilds = false;
                }
            });
        }

    }

    ValidateReadOnlyFlag(event: any) {
        this.model.ShouldReadOnlyReflectToChilds = false;

        if (this.model.ReadOnly != this.assetModelClone.ReadOnly && !this.isSubmitted) {
            this._confirmationService.confirm({
                message: 'Would you like to reflect this Read only status to child Assets/Items? Changes will be applicable after Saving the asset',
                header: this.model.AssetType + ' Read only status is changed!',
                icon: 'fa fa-exclamation-triangle',
                accept: () => {
                    this.model.ShouldReadOnlyReflectToChilds = true;
                },
                reject: () => {
                    this.model.ShouldReadOnlyReflectToChilds = false;
                }
            });
        }
    }

    btnSubmitClick(form: NgForm) {
        this.proceedToUpdateAsset(form);
    }
    proceedToUpdateAsset(form: NgForm) {
        this.changeButtonsStatus(true);
        this.model.AssetType = this.model.AssetType;
        let body = this.model;
        if (this.model.MXRecords == null) {
            this.model.MXRecords = false;
        }
        if (this.model.AutoGapAnalyser == null) {
            this.model.AutoGapAnalyser = false;
        }
        if (this.model.LogBooks == null) {
            this.model.LogBooks = false;
        }
        if (this.model.General == null) {
            this.model.General = false;
        }
        if (this.model.Documents == null) {
            this.model.Documents = false;
        }
        if (this.model.Binders == null) {
            this.model.Binders = false;
        }
        if (this.model.AssetType != this.assetManagementService.AssetTypeAirframe && this.model.AssetType != this.assetManagementService.AssetTypeHelicopter) {
            this.model.ReadOnly = true;
        }
        body = this.model;
        this.sharedService.showLoader("Processing...");
        this.assetManagementService.updateAsset(body)
        .subscribe({
            next: (response) => {
                this.sharedService.hideLoader();
                this.sharedService.clearToast();
                this.sharedService.showToast(
                    {
                         severity: 'success', 
                         summary: 'Success', 
                         detail: 'Aircraft Updated succesfully.' 
                    }
                );
                this.display = false;
                this.resetModel();
                form.resetForm();
                this.changeButtonsStatus(false);
                this.returntoAction(this.addAircraftPath);                
            },
            error: (error) => {
                this.sharedService.hideLoader();
                this.sharedService.clearToast();
                console.log(error);
                if (error.status === 409) {
                    this.sharedService.showToast(
                        { 
                            severity: 'error', 
                            summary: 'Already Exists', 
                            detail: 'Aircraft already exists.' 
                        }
                    );
                }
                else if (error.status === 405) {
                    this.sharedService.showToast(
                        { 
                            severity: 'error', 
                            summary: 'Warning', 
                            detail: 'You cannot change search database because Indexer Already Exists for this asset, Please delete the indexer and clean index' 
                        }
                    );
                }
                else {
                    this.sharedService.showToast(
                        { 
                            severity: 'error', 
                            summary: 'Error Occured', 
                            detail: 'Aircraft not updated, please try again.' 
                        }
                    );
                }
                // this.display = false;
                // this.resetModel();
                this.changeButtonsStatus(false);                
            }
        });
    }

    btnCancelClick(cancelForm: NgForm) {
        this.display = false;
        cancelForm.resetForm();
        this.model = new AssetModel();
    }


    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }

    resetModel() {
        this.model = new AssetModel();

    }


    updateRoles() {
        if (!this.sharedService.UserRole) {
            this.sharedService.updateRole();
        }

        this.canAddEditAirCraft = this.sharedService.UserRole.Admin_Aircraft;
        this.isSysAdmin = this.sharedService.UserRole.Admin_Sys_Admin;
        this.isAdminAircraft = this.sharedService.UserRole.Admin_Aircraft;
    }

    handleCreateContainerClick(): void {
        this.sharedService.clearToast();
        let body = this.aircraftID;
        this.sharedService.showLoader("Processing...");
        this.adminSearchService.createSearchComponents(body)
        .subscribe({
            next: (response) => {
                this.sharedService.hideLoader();
                let msgDictionary = response;
                for (let msg of msgDictionary) {
                    this.sharedService.showToast({ severity: msg.Key, summary: msg.Key, detail: msg.Value });
                }
                //this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Aircraft Updated succesfully.' });                
            },
            error: (error) => {
                this.sharedService.hideLoader();
                this.sharedService.clearToast();
                this.sharedService.showToast(
                    { 
                        severity: 'warn', 
                        summary: 'warn', 
                        detail: error 
                    }
                );                
            }
        });
    }

    returntoAction(url: string) {

        setTimeout(() => {

            this.router.navigateByUrl(url);

        },
            2000);
    }

    // #region Asset Type List
    getAssetTypes(): void {
        this.assetTypeList = this.assetManagementService.getAssetTypes();
    }
    //#endregion


    populateSearchDatabase() {
        this.searchDatabaseList = [];

        this.adminSearchService.getSearchDatabase()
        .subscribe({
            next: (response) => {
                let res = response;
                this.searchDatabaseList = res;                
            },
            error: (error) => {
                
            }
        });
    }

    setDefaultValues(data: any) {
        this.model.AssetType = data.AssetType;
        this.assetCategory = data.AssetType;
        this.setFieldsTitle(data.AssetType);
    }

    onAssetChange(event: any) {
        this.setFieldsTitle(this.model.AssetType);
    }

    setFieldsTitle(assetType: string) {
        this.assetCategory = assetType;
        if (assetType == this.assetManagementService.AssetTypeAirframe) {
            this.ASNTitle = this.ASNTitleAirframe;
            this.ManufacturerTitle = this.ManufacturerTitleAirframe;
            this.VariantTitle = this.VariantTitleAirframe;
            this.TypeTitle = this.TypeTitleAirframe;

            this.isCheckedReadOnly = false;
            this.model.ReadOnly == false;
            this.disableReadOnlyButton = false;
        }
        else if (assetType == this.assetManagementService.AssetTypeHelicopter) {
            this.ASNTitle = this.ASNTitleAirframe;
            this.ManufacturerTitle = this.ManufacturerTitleAirframe;
            this.VariantTitle = this.VariantTitleAirframe;
            this.TypeTitle = this.TypeTitleAirframe;

            this.isCheckedReadOnly = false;
            this.model.ReadOnly == false;
            this.disableReadOnlyButton = false;
        }
        else {
            this.ASNTitle = this.ASNTitleCollection;
            this.ManufacturerTitle = this.ManufacturerTitleCollection;
            this.VariantTitle = this.VariantTitleCollection;
            this.TypeTitle = this.TypeTitleCollection;

            this.isCheckedReadOnly = true;
            this.model.ReadOnly == true;
            this.disableReadOnlyButton = true;
        }

    }

    btnCancel() {
        this.router.navigate([this.addAircraftPath]);
    }
    ChangePlan() {
        this.isDisplayBillingPlanForm = true;
        this.selectedBillingPlan = null;
        this.htmlBillingPlanDescription = null;
    }
    btnCloseClick(event: any) {
        this.isDisplayBillingPlanForm = false;
        this.selectedBillingPlan = null;
        this.htmlBillingPlanDescription = null;
    }

    onlistboxSelectBillingPlanClicked(event: any) {
        this.selectedBillingPlan = event.value;
        this.htmlBillingPlanDescription = this.sanitizer.bypassSecurityTrustHtml(event.value.Description);
        var styleClass: any = document.getElementsByClassName('p-dialog p-component p-component-content')[0];
        styleClass.style.top = "40px";
    }
    btnSelectBillingPlanClick(event: any) {
        //selectedBillingPlan
        //this.selectedBillingPlan.PlanID;
        //this.model.AssetID;
        if (this.selectedBillingPlan != null) {
            this.UpdateAssetBillingPlan();
        }

    }

    UpdateAssetBillingPlan() {
        var body = {
            "SelectedPlanID": this.selectedBillingPlan.PlanID,
            "AssetID": this.model.AssetID
        };

        this.sharedService.showLoader("Processing...");
        this.assetManagementService.updateAssetBillingPlan(body)
        .subscribe({
            next: (response) => {
                this.display = false;
                this.resetModel();
                this.sharedService.hideLoader();
                this.sharedService.clearToast();
                let msgDetails: string = "";
                if (response.PlanStatus == "Plan upgraded") { msgDetails = 'This asset will be available with OCR Search within 24 hours.' }
                else if (response.PlanStatus == "Plan downgraded") { msgDetails = 'This asset will be billed on current plan till last day of the month.' };
                this.sharedService.showToast({ severity: 'success', summary: 'Success', detail: msgDetails });
                this.changeButtonsStatus(false);
                this.returntoAction(this.addAircraftPath);                
            },
            error: (error) => {
                this.sharedService.hideLoader();
                this.sharedService.clearToast();
                console.log(error);
                if (error.status === 409) {
                    this.sharedService.showToast(
                        { 
                            severity: 'error', 
                            summary: 'Already Exists', 
                            detail: 'Aircraft already exists.' 
                        }
                    );
                }
                else if (error.status === 405) {
                    this.sharedService.showToast(
                        { 
                            severity: 'error', 
                            summary: 'Warning', 
                            detail: 'You cannot change search database because Indexer Already Exists for this asset, Please delete the indexer and clean index' 
                        }
                    );
                }
                else {
                    this.sharedService.showToast(
                        { 
                            severity: 'error', 
                            summary: 'Error Occured', 
                            detail: 'Aircraft not updated, please try again.' 
                        }
                    );
                }
                this.display = false;
                this.resetModel();
                this.changeButtonsStatus(false);                
            }
        });
    }

    //#region  Assigned Users   
    openAssignedUserDlg(assetID: string) {
        if (this.assetList.length == 0) this.populateAssetList()
        this.assetManagementService.getAircraftUser(assetID, this.sharedService.UserRole.Admin_Sys_Admin)
        .subscribe({
            next: (response) => {
                this.assetUserModel = response;
                this.displayAircraftUser = true;                
            },
            error: (error) => {
                console.log(error);
                // this.sharedService.clearToast();
                // this.sharedService.showToast({ severity: 'warn', summary: 'warn', detail: error });                
            }
        })
    }

    btnCancelAircraftUserClick(cancelForm: NgForm) {
        this.displayAircraftUser = false;
        cancelForm.resetForm();
    }

    onSubmit(form: NgForm) {
        this.sharedService.showLoader("Processing...");
        this.assetManagementService.UpdateAircraftUser(this.assetUserModel)
        .subscribe({
            next: (response) => {
                this.sharedService.clearToast();
                this.sharedService.showToast(
                    { 
                        severity: 'success', 
                        summary: 'Success', 
                        detail: 'Asseigned users have been updated succesfully.' 
                    }
                );
                this.displayAircraftUser = false;
                this.sharedService.hideLoader();                
            },
            error: (error) => {
                this.sharedService.hideLoader();
                console.log(error);
                this.sharedService.clearToast();
                this.sharedService.showToast(
                    { 
                        severity: 'warn', 
                        summary: 'Error Occured', 
                        detail: "Cannot update assigned users." 
                    }
                );
                
            }
        });
    }

    populateAssetList(): void {
        if (this.model.AssetID) {
            let body = this.model.AssetID;
            // this.assetList = [];
            this.assetList.push({ label: this.model.Description, value: this.model.AssetID });
            this.assetManagementService.getAssetsbyParentID(body)
            .subscribe({
                next: (response) => {
                    if (response && response.length > 0) {
                        response.forEach(asset => {
                            this.assetList.push({ label: asset.Description, value: asset.AssetID });
                        });
                    }
                },
                error: (error) => {
                    console.log(error)
                }
            });
        }
    }

    onAssignAssetChange(event: any) {
        this.openAssignedUserDlg(event.value);
    }
    isCheckedStatus: boolean = false
    ToggleCheckedStatus() {

        if (this.isCheckedStatus) {
            this.isCheckedStatus = false;
            this.model.Status = false;
        }
        else {
            this.isCheckedStatus = true;
            this.model.Status = true;
        }
    }
    isCheckedReadOnly: boolean = false
    ToggleCheckedReadOnly() {

        if (this.isCheckedReadOnly) {
            this.isCheckedReadOnly = false;
            this.model.ReadOnly = false;
        }
        else {
            this.isCheckedReadOnly = true;
            this.model.ReadOnly = true;
        }
    }

    isCheckedMXRecord: boolean = false
    ToggleMXRecord() {

        if (this.isCheckedMXRecord) {
            this.isCheckedMXRecord = false;
            this.model.MXRecords = false;
        }
        else {
            this.isCheckedMXRecord = true;
            this.model.MXRecords = true;
        }
    }
    isCheckedLogBooks: boolean = false
    ToggleLogBooks() {

        if (this.isCheckedLogBooks) {
            this.isCheckedLogBooks = false;
            this.model.LogBooks = false;
        }
        else {
            this.isCheckedLogBooks = true;
            this.model.LogBooks = true;
        }
    }
    isCheckedGeneral: boolean = false
    ToggleGeneral() {

        if (this.isCheckedGeneral) {
            this.isCheckedGeneral = false;
            this.model.General = false;
        }
        else {
            this.isCheckedGeneral = true;
            this.model.General = true;
        }
    }
    isCheckedDocuments: boolean = false
    ToggleDocuments() {

        if (this.isCheckedDocuments) {
            this.isCheckedDocuments = false;
            this.model.Documents = false;
        }
        else {
            this.isCheckedDocuments = true;
            this.model.Documents = true;
        }
    }

    isCheckedBinders: boolean = false
    ToggleBinders() {

        if (this.isCheckedBinders) {
            this.isCheckedBinders = false;
            this.model.Binders = false;
        }
        else {
            this.isCheckedBinders = true;
            this.model.Binders = true;
        }
    }
    isAutoGapAnalyser: boolean = false
    ToggleAutoGapAnalyser() {

        if (this.isAutoGapAnalyser) {
            this.isAutoGapAnalyser = false;
            this.model.AutoGapAnalyser = false;
        }
        else {
            this.isAutoGapAnalyser = true;
            this.model.AutoGapAnalyser = true;
        }
    }
    runAutoGapAnalyser: string = "";
    getAutoRunGapAnalyserValue(): void {
        this.runAutoGapAnalyser = this.sharedService.getRunAutoGapAnalyserValue().toLocaleLowerCase();
    }

    //#endregion
}
