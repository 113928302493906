//Components
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';
import { MenuService } from '../../../shared/services/Menu/menu.service';
import "ag-grid-enterprise";
import { GridOptions, GridApi, ExcelExportParams, ExcelRow } from "ag-grid-community";
import { ConfirmationService, SelectItem } from 'primeng/api';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { AssetService } from '../../../admin/services/asset/asset.service';
import { ContainerService } from '../../../records/services/container/container.service';
import { AssetTypes, Stats } from './dashboard';
import { Observable, Subscription, forkJoin } from 'rxjs';
var containerTypes: any[] = [];
import * as moment from 'moment/moment';
import { DFPStatusModel } from "./dfpstatus.model";
import { QualityStatusService } from '../../../admin/services/qualitystatus/qualitystatus.service';
import { ContainerQualityStatusService } from '../../../admin/services/containerqualitystatus/container-quality-status.service';
import { IDateFormatInfo } from 'src/app/shared/helpers/dateFormatInfo';
import { Role } from '../../../shared/services/Menu/role';
import { CustomGeneralSetting } from './customGeneralSetting';
import { NgForm } from '@angular/forms';
import { DashboardPageSettings, DashboardSettings, HomeDashboardSettings, PluginGeneralSettingsWidget, PluginSettingsWidget, Plugin_position, Plugin_positionGrid, Plugin_positionSection, WidgetPluginGeneralSettings, WidgetPluginPositions, WidgetPluginPositionsGrid, WidgetPluginPositionsGridSection, WidgetPluginSettings } from './WidgetSetting';
import { element, error } from 'protractor';

var statusDetailGridApi: GridApi;
var statusDetailGridColumnDefs: any[];
var dfpStatuses: DFPStatusModel[] = [];
var dateTimeConfig: IDateFormatInfo;
var nextRoute: string = null;

function GetDashBoardExcelValue(params: any) {
    var value = null;
    if (params.value) {
        if (typeof (params.value) == "object") {
            let tagsList = params.value;
            for (let i = 0; i < tagsList.length; i++) {
                let tag = tagsList[i];
                let tagLabel = tag.checkType + (tag.checkDataList.length > 1 ? ' (' + tag.checkDataList.length + ')' : '');
                value = (value) ? value + ", " + tagLabel : value = tagLabel;
            }
        }
        else {
            value = params.value;
        }
        return value;
    }
}

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
  styles: [`::ng-deep #modulesList span.p-dropdown-label.p-inputtext { padding-bottom: 4px !important;
    padding-top: 4px !important;}`],
    providers: [MenuService, DashboardService, AssetService, ContainerService, QualityStatusService, ContainerQualityStatusService, ConfirmationService]
})

export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {

    //#region graph variables
    public doughnutData: any;
    public doughnutOptions: any;
    public barData: any;
    public barOptions: any;
    public inboxChartOptions: any;
    public binderChartOptions: any;
    public viewStatusBarData: any;
    public viewStatusBarOptions: any;
    public assetBarGraphData: any;
    public assetBarGraphOptions: any;
    public componentName = "RDAS";
    public containerQualityStatusList: any;
    public customGeneralSetting: CustomGeneralSetting;
    public recordsViewType: string = '';
    public statusViewType: string = '';
    public selectedAssetName: string;
    //#endregion

    // #region Dashboard Roles
    public canViewRecords: boolean = false;
    public canViewStatus: boolean = false;
    public canViewComms: boolean = false;
    public canViewStats: boolean = false;
    // #endregion

    // #region Variables
    public groupDefaultExpanded: boolean = false;
    public getDataPath: any;
    public autoGroupColumnDef: any;
    private selectedRowData: any = null;
    public name: string;
    public menuList: any;
    public greetinglabel: string;
    private recordMasterGridData: any[] = [];
    private statusgridData: any[] = [];
    private inboxChartData: any[] = [];
    private binderChartData: any[] = [];
    private assetData: any[] = [];
    public statusToggleTypes: any[] = [];
    public containerMasterGridOptions: GridOptions;
    //public containerDetailGridOptions: GridOptions;
    public statusgridOptions: GridOptions;
    public columnDefs: any[];
    public statuscolumnDefs: any[];
    public assetBarChartColumnDefs: any[];
    public commAssignedToMe: any[] = [];
    public commRequestedByMe: any[] = [];
    public getRowNodeId: any;
    public getDetailRowNodeId: any;
    public assignedtomeCount: number = 0;
    public requestedbymeCount: number = 0;
    public commId: string = "";

    //childdata: any[] = [];
    public api: GridApi;
    public statusGridApi: GridApi;
    private gridColumnApi;
    public defaultYearString: string = "Past 12 Months";
    public selectedYear: string = this.defaultYearString;
    public assetsTypes: AssetTypes;
    public statsData: Stats[] = [];
    public components: any;
    public recordLastUpdated: string = '';
    public containerLastUpdated: string = '';
    public inboxLastUpdated: string = '';
    public containerStatusLastUpdated: string = '';
    public binderLastUpdated: string = '';
    public statsLastUpdated: string = '';
    public statusDetailGridOptions: GridOptions;
    public statusDetailGridColumnDefs: any[];
    public subscription: Subscription;
    public isNavigationAllowed: boolean = false;

    //userAssets: any;
    public statsChartdata: any;
    public storageCapacity: number;
    public pieOptions: any;
    public updatingRecord: boolean = false;
    public updatingStatus: boolean = false;
    public updatingStats: string = '';
    public updatingInbox: string = '';
    public updatingBinder: string = '';
    public updatingContainerStatus: string = '';
    public detailRowHeight = 230;
    public showAssetBarChart: boolean = false;
    public showFleetBarChart: boolean = false;
    public inboxDataChart: boolean = false;
    public transitionChartChart: boolean = false;
    public statusToggle: any = false;
    public excelStyles: any;

    public selectedCheck: any;
    public userAssets: SelectItem[] = [];
    public displayGroupCheckForm: boolean = false;
    public displayCustomGeneralSettingForm: boolean = false;
    public displayWidgetsettingsAndPositionForm: boolean = false;
    public displayWidgetsettingsAndPositionAddForm: boolean = false;
    public groupCheckList: any[] = [];
    public displayWidgetToSpecificArticle: boolean = false;
    public selectedWidgetToSpecificArticle;
    public modulesList: SelectItem[] = [];
    public selectedModuleId: number;
    public selectedWidgetIndex
    public selectedArticleId;
    // #endregion

    public addFormSelectedWidgets: any = [];
    public addFormPageName: any;
    public settingWidgetNameStart = "Plugin_settings_";
    public settingWidgetNameEnd = "_widgets-grid";
    public pluginWidgetNameStart = "Plugin_position_";
    public pluginWidgetNameEnd = "_widgets-grid";
    public widgetPluginPositions: WidgetPluginPositions;
    public widgetPluginSettings: WidgetPluginSettings
    public homeDashboardSettings: HomeDashboardSettings = new HomeDashboardSettings();
    public dashboardPageSettings: DashboardPageSettings = new DashboardPageSettings();
    public DashboardSettings: DashboardSettings = new DashboardSettings();
    public widgetSettingAndPositionModel: WidgetPluginPositions
    public widgetSettingAndPositionAddModel: WidgetPluginPositions
    public oldPageName;
    public editFormPageName;
    public pageMenuOrder = 1;
    public addPageMenuOrder = 1;
    public dashBoardName;
    public widgetDropdownListValues: any = [];
    public isDisableUpdateButton = false;
    public isRecordsSummeryChecked: boolean = true;
    public isDashboardModule: boolean = true;
    isTabViewChange: boolean;
    public assetChangeSubscription$: Subscription;
    public assetStatusChangeSubscription$: Subscription;
    parentMenuChanngeSubscription$: Subscription;
    hasUnsavedChanges: boolean = false;
    @ViewChild('statusDonutGraphHeader') statusDonutGraphHeader: ElementRef;
    @ViewChild('statusBarGraphHeader') statusBarGraphHeader: ElementRef;
    @ViewChild('statusTableGraphHeader') statusTableGraphHeader: ElementRef;
    constructor(private activateRoute: ActivatedRoute, private _sharedService: SharedService,
        private cRef: ChangeDetectorRef, private _router: Router, private _dashboardService: DashboardService,
        private _assetService: AssetService, private _containerService: ContainerService,
        private _qualityStatusService: QualityStatusService,
        private _containerQualityStatusService: ContainerQualityStatusService,
        private _confirmationService: ConfirmationService,
        private _renderer: Renderer2) {
        
        activateRoute.params.subscribe(params => {
          this.setupComponent(params['name']);
          if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
            return;
          }
        })
        this.isDashboardModule = SharedService.current_ParentMenu_Id == -1;
        this.homeDashboardSettings.dashboardPageSettings = new Array<DashboardPageSettings>();
        // this._sharedService.onAssetStatusChanged(null);
        dateTimeConfig = this._sharedService.getDateTimeFormatConfiguration();
        if ((this._sharedService.getSubdomain().toLowerCase() != this._sharedService.Tenant_Internal) &&
            (this._sharedService.getSubdomain().toLowerCase() != this._sharedService.Tenant_Help)) {

            this.getContainerTypes();
            this.getDFPStatus();

            this.getRowNodeId = function (params: any) {
                return params.data.AssetId;
              };

            this.autoGroupColumnDef = {
                cellRenderer: 'agGroupCellRenderer',
                headerName: "Asset",
                width: 220,
                pinned: "left",
                menuTabs: ['filterMenuTab'],
                //cellRendererParams: { suppressCount: true, innerRenderer: this.recordGridGroupRenderer, resizeable: true },

                cellRendererParams: {
                    suppressCount: true, // turn off the row count
                    checkbox: false, // enable checkbox selection
                    //padding: 10,
                    innerRenderer: this.recordGridGroupRenderer, // provide an inner renderer
                    resizeable: true
                    //footerValueGetter: myFooterValueGetter // provide a footer value getter
                }
            };

            this.getDataPath = function (data: any) {
                return data.ASNTree;
            };

            this.getDetailRowNodeId = function (data: any) {
                return data.DFPStatus;
            };
      }
      this.validateValues = this.validateValues.bind(this);
  }
  // containerQualityStatuses: any = [];
  // populateQualityStatusGrid(): void {

  //   this._containerQualityStatusService.getAllQualityStatusData(null)
  //     .subscribe({
  //       next: (response: any[]) => {
  //         this.containerQualityStatuses = response.map(a => a.Name);
  //       },
  //       error: (error) => {
  //         this.containerQualityStatuses = [];
  //       }
  //     });
  // }
  setBarStatusGraphHeadings() {

    let tableDonutGraphHeader = `${this.selectedAssetName ? this.selectedAssetName : 'Fleet'} Compliance Status`;
    let statusBarGraphHeader = `${this.selectedAssetName ? this.selectedAssetName : 'Fleet'} Asset View Status`;
    // this.statusDonutGraphHeader.nativeElement.textContent = tableDonutGraphHeader;

    if (this.statusDonutGraphHeader?.nativeElement && 
      this.statusBarGraphHeader?.nativeElement &&
      this.statusTableGraphHeader?.nativeElement) {
        this._renderer
          .setProperty(
            this.statusDonutGraphHeader.nativeElement,
            'textContent',
            tableDonutGraphHeader
          );
        this._renderer
          .setProperty(
            this.statusBarGraphHeader.nativeElement,
            'textContent',
            statusBarGraphHeader
          );
        this._renderer
          .setProperty(
            this.statusTableGraphHeader.nativeElement,
            'textContent',
            tableDonutGraphHeader
        );      
      }
    // this.refreshWidget();
  }
  refreshWidget() {
    
    for (let index = 0; index < this.currentDashboardPageSettings.widgetPluginPositionsGrid.grid.length; index++) {
      var x = index * 4;
      let y = 0;
      while (y < 4) {
        let searchIndex = x + y + 3;
        var currentGrid = this.currentDashboardPageSettings.widgetPluginPositionsLocalStorage.grid[searchIndex]
        
        if (currentGrid != undefined && currentGrid.section.filter(a=>a).length > 0) {
          for (let x = 0; x < currentGrid.section.length; x++) {
            const element = currentGrid.section[x].id;
            if (currentGrid.section.length > 1) {
              let id = "builtin" + index + "_" + y
              let widget = document.getElementById(id);
              if (widget) widget.style.display = 'none'
            }
            if (currentGrid.section.length == 1 ) {
              let id = "builtin" + index + "_" + y
              let widget = document.getElementById(id);
              if (widget) widget.style.display = 'block'
            }
            if (element != "" && element != undefined) {
              let widget = document.getElementById(element);

              if (widget != null && element.indexOf('builtin') == -1) {
                var widgetBody: any = widget.getElementsByClassName("widget-body")[0];
                widgetBody.style.height = this.currentDashboardPageSettings.widgetPluginPositionsGrid.grid[index].widgetHeight + 'px'
                widgetBody.style.overflowY = 'auto'
              }
            }

          }
        }
        y++;
      }
    }
  }
  setModulesList() {
    this.modulesList = this._sharedService.getMenuList()
      .filter(a => a.Parent == 0 && a.DisplayOrder <= 5)
      .map(a => {
        return { label: a.Title, value: a.MenuID }
      });
      this.modulesList.unshift({ label: 'None', value: null });
    }
    setupComponent(prem) {

        if (prem != undefined && this._sharedService.prevHomeDashboardValue != prem) {
            // this._router.navigateByUrl(this._router.url);
            this.reload(this._router.url)
            this._sharedService.prevHomeDashboardValue = prem
        }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.widgetHeight();
        }, 300);
    this.setViewType();
    this.setBarStatusGraphHeadings()
  }
  confirmSaveChanges(nextState: RouterStateSnapshot): Promise<boolean> {
    if (!this._sharedService.isUnsavedChanges) {
      if (this._sharedService.next_not_navigated_ParentMenu_Id) {
        this._sharedService.onParentMenuChanged(this._sharedService.next_not_navigated_ParentMenu_Id);
      }
      return Promise.resolve(true)
    };
    
    return new Promise((resolve) => {
      this._confirmationService.confirm({
        message: 'You have unsaved changes!',
        key: 'confirmdelete',
        header: 'Are you sure you want to discard changes?',
        icon: 'fa fa-trash',
        accept: () => {
          // User accepted
          this._sharedService.onParentMenuChanged(this._sharedService.next_not_navigated_ParentMenu_Id);
          this._sharedService.isUnsavedChanges = false;
          resolve(true);
        },
        reject: () => {
          // User rejected
          resolve(false);
        }
      });
    });
  }
    validateValues():boolean {
        if (!this._sharedService.isUnsavedChanges && this._router.url.indexOf("/dashboard/") != -1) {
            var routeName = this._router.url.slice(1, this._router.url.length);
            if (routeName != "record") {
                routeName = this._router.url.slice(11, this._router.url.length);
            }
            let homeDashboardSettinglocal = localStorage.getItem("HomeDashboardSettings");
            let homeDashboardSettings = JSON.parse(homeDashboardSettinglocal);
            let currentDashboardPageSettings = homeDashboardSettings.dashboardPageSettings.find(x => x.routePath == routeName);
            let currentPath = this._router.url;
            let pluginPosition = this.pluginWidgetNameStart + currentPath + this.pluginWidgetNameEnd;
            let url = this._router.url.slice(1, this._router.url.length).replace('/', '-');
            var pluginPositionValue = localStorage.getItem(pluginPosition)
            let currentDashBoardWidgetPositions: Plugin_position = JSON.parse(pluginPositionValue);
            let pluginSettingWidgetGridPath = this.settingWidgetNameStart + this._router.url + this.settingWidgetNameEnd;
            var plugin_settings: any = localStorage.getItem(pluginSettingWidgetGridPath);
            let widgetPluginSettingsValue = JSON.stringify(currentDashboardPageSettings.widgetPluginSettings)
            let widgetPluginPositionsLocalStorage = JSON.stringify(currentDashboardPageSettings.widgetPluginPositionsLocalStorage)
            if (widgetPluginSettingsValue != plugin_settings || pluginPositionValue != widgetPluginPositionsLocalStorage) {
                this.showSettingButtons = true;
                this.showConfirmNevigateDialouge = true;
                setTimeout(() => {
                    let btn = document.getElementById('dashboardSavebtn');
                    if (btn != undefined) {
                        btn.style.fontWeight = 'bold';
                        this._sharedService.isUnsavedChanges = true;
                    }

                }, 500);
            }
            else {
                return false
            }
        }
    }
    ngOnInit() {
      this._sharedService.isUnsavedChanges = false;
        this.widgetsInitialization();
      this.customGeneralSetting = new CustomGeneralSetting(null, null, null, null);

        this._sharedService.prevURL = this._router.url;
        this.statusToggle = 'Fleet';
        this.componentName = "RDAS"
        //this.isEditCommVisible = this._sharedService.UserRole.Comm_Edit;
        this.statusToggleTypes = [];
        this.statusToggleTypes.push({ label: 'Fleet', value: 'Fleet' });
        this.statusToggleTypes.push({ label: 'Asset', value: 'Asset' });
      
        this.setAssetControlVisible();
      
        this._sharedService._StatusToggleValue
        .subscribe({
            next: (selValue:any) => {
                if (selValue == 'Fleet') {
                    this.populateDashboardStatusListingGrid();
                }
                else {
                    this.populateDashboardStatusAssetListingGrid();
        
                }
            },
            error: (error) => {
            }
        });

        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
          this._sharedService.SessionExpiredMessage();
          return
        }
        else
            if ((this._sharedService.getSubdomain().toLowerCase() != this._sharedService.Tenant_Internal) &&
                (this._sharedService.getSubdomain().toLowerCase() != this._sharedService.Tenant_Help)) {

              this._sharedService.showLoader("Processing...");
              
                this.assetChangeSubscription$ = this._sharedService
                  ._selectedAsset
                  .subscribe({
                    next: (assetChanged: any[]) => {
                      if (SharedService.current_ParentMenu_Id == 16 ||
                        SharedService.current_ParentMenu_Id == 116) {
                        if (assetChanged &&
                          assetChanged.length) {
                          this.selectedAssetName = assetChanged[0].label;
                        }
                        else {
                          this.selectedAssetName = null;
                        }
                        this.onSubscriptionSuccessResponse(assetChanged);
                        this.setBarStatusGraphHeadings();
                      }
                    },
                    error: (err) => {
                      console.log(err);
                    }
                  });
              
                this.assetStatusChangeSubscription$ = this._sharedService
                  ._selectedAssetStatus
                  .subscribe({
                    next: (assetChanged: any[]) => {
                      if (SharedService.current_ParentMenu_Id == 22 ||
                      SharedService.current_ParentMenu_Id == 99) {
                      }
                      if (assetChanged &&
                        assetChanged.length) {
                        this.selectedAssetName = assetChanged[0].label;
                      }
                      else {
                        this.selectedAssetName = null;
                      }
                      this.onSubscriptionSuccessResponse(assetChanged);
                      this.setBarStatusGraphHeadings();                        
                    },
                    error: (err) => {
                      console.log(err);
                    }
                  });
              
              this.parentMenuChanngeSubscription$ = this._sharedService
                ._menuIDFromParentMenu$.subscribe((menuID: number) => {
                  this.isDashboardModule = SharedService.current_ParentMenu_Id == -1;
                  let asset;
                  if (this._sharedService.selectedAsset &&
                    this._sharedService.selectedAsset.length) {
                    asset = this._sharedService.selectedAsset;
                  } else if (this._sharedService.selectedAssetStatus &&
                    this._sharedService.selectedAssetStatus.length) {
                    asset = this._sharedService.selectedAssetStatus;
                  }

                  this.onSubscriptionSuccessResponse(asset);
                  if (menuID == 22) {
                    this._sharedService.showHideRecordMenu()
                  }
                  this.setAssetControlVisible();
                });                
                this.UpdateRoles();
                this.populateQualityStatus();
                this.populateContainerQualityStatus();
                this.getAsset();
                let asset;
                if (this._sharedService.selectedAsset &&
                  this._sharedService.selectedAsset.length)
                {
                    asset = JSON.parse(this._sharedService.selectedAsset[0].data).ASN;
                } else if (this._sharedService.selectedAssetStatus &&
                  this._sharedService.selectedAssetStatus.length)
                {
                  asset = JSON.parse(this._sharedService.selectedAssetStatus[0].data).ASN;
                }
                this.getRecordSummaryView(SharedService.current_ParentMenu_Id == -1 ? null : asset);
                this.getdashboardStats();
                this.initializeAssetTypes();
                this.populateDashboardContainerListingGrid();
                this.getAssetsCount();
                this._sharedService.getDateTimeFormatConfiguration();
                //this.loadTags();
                this.getCommDataForDashboard();
                this.populateDashboardInboxChart();
                this.populateDashboardBinderChart();
                //this.name = this._sharedService.getCurrentUserName();
                //this.greetinglabel = this._sharedService.getGreetingLabel();
                this.containerMasterGridOptions = <GridOptions>
                    {
                        rowSelection: 'single',
                        rowData: [],
                        onGridReady: (params) => {
                            this.gridColumnApi = params.columnApi;
                            this.containerMasterGridOptions.api.getDisplayedRowAtIndex(0).setSelected(true);

                            if (this.containerMasterGridOptions.api != undefined) {

                                if (this.recordMasterGridData.length > 0) { this.containerMasterGridOptions.api.hideOverlay(); this.setScrollPostition(); }
                                this.api = this.containerMasterGridOptions.api;
                            }
                        },
                        onModelUpdated: (event: any) => {
                            this.selectFirstRow();
                            if (this.containerMasterGridOptions) {
                                //this.containerMasterGridOptions.api.sizeColumnsToFit();
                            }
                            this.resizeRecordMasterGridColumns();
                        },
                        //suppressContextMenu: true,
                        context: {
                            masterGridCtx: this
                        },
                        getContextMenuItems: this.getCustomContextMenuItems,
                        defaultColDef: {
                            sortable: true,
                            resizeable: true,
                            filter: true,

                        }

                    }
                this.statusDetailGridOptions = <GridOptions>{
                    rowSelection: 'single',
                    onGridReady: function (params) {
                        if (params.api) {
                            statusDetailGridApi = params.api;
                            params.api.setColumnDefs(statusDetailGridColumnDefs);
                        }
                    },
                    //suppressContextMenu: true,

                    //rowGroupPanelShow: 'always',
                    getRowNodeId: this.getDetailRowNodeId,
                    getContextMenuItems: this.getCustomContextMenuItemsStatusDetail,
                    context: {
                        detailGridCtx: this
                    },
                    defaultColDef: {
                        sortable: true,
                        resizable: true,
                        filter: true,

                    }
                }
                this.statusgridOptions = <GridOptions>
                    {
                        rowSelection: 'single',
                        onGridReady: () => {
                            this.statusgridOptions.api.sizeColumnsToFit();
                            //if (this.gridOptions.api != undefined) {

                            //    this.api = this.gridOptions.api;
                            //}
                            this.statusGridApi = this.statusgridOptions.api;
                            // this.api2=this.statusDetailGridOptions.api;

                        },
                        onModelUpdated: (event: any) => {
                            if (this.statusgridOptions.api != undefined) {
                                this.statusgridOptions.api.sizeColumnsToFit();
                            }
                        },
                        masterDetail: true,
                        detailCellRendererParams: {

                            // provide detail grid options
                            detailGridOptions: this.statusDetailGridOptions,
                            // extract and supply row data for detail
                            getDetailRowData: function (params: any) {
                                params.successCallback([]);

                                //Set Child Records
                                //setTimeout(params.successCallback([params.data]), 1000);
                            },
                        },
                        //suppressContextMenu: true,
                        context: {
                            statusGridCtx: this
                        },
                        getContextMenuItems: this.getCustomContextMenuItemsStatus,
                        defaultColDef: {
                            sortable: true,
                            resizable: true,
                            filter: true,

                        }
                    }
                this.populateDashboardStatusListingGrid();

                this.subscription = this._sharedService._isRefreshDashboardComms
                .subscribe({
                    next: (Refreshcomms:boolean) => {
                        if (Refreshcomms) {
                            this.getCommDataForDashboard();
                        }
                    }
                });
            }
        this.setExcelStyle();
        setInterval(this.validateValues, 9000);
  }
  setAssetControlVisible() {
    if (SharedService.current_ParentMenu_Id > 0 && SharedService.current_ParentMenu_Id != 57)
      this._sharedService.setAssetControlVisible(true);
    else
      this._sharedService.setAssetControlVisible(false);
  }
  onSubscriptionSuccessResponse(assetChanged: any[] = null) {
    let asset;
    if (assetChanged && assetChanged.length) {
      asset = JSON.parse(assetChanged[0].data);
    }
    this.getRecordSummaryView(asset?.ASN);
    if (SharedService.current_ParentMenu_Id == 113
      || SharedService.current_ParentMenu_Id == 16) {
      this._sharedService.showHideRecordMenu();
    } else if (SharedService.current_ParentMenu_Id == 22
      || SharedService.current_ParentMenu_Id == 99) {
      this._sharedService.showHideStatusMenu();
    }

    this.UpdateRoles(assetChanged);

    if ((SharedService.current_ParentMenu_Id == 22 && this._sharedService.UserRole["Dashboard_Status"]) ||
      (SharedService.current_ParentMenu_Id == 99 && this._sharedService.UserRole["Dashboard_Repairs"])) {
      this.populateDashboardStatusListingGrid();
    } else if (SharedService.current_ParentMenu_Id == 22 || SharedService.current_ParentMenu_Id == 99) {
      this._sharedService.clearToast();
      var notAllowedMsg = 'Access denied';
      if (assetChanged && assetChanged.length > 0 && assetChanged[0].data) {
        let assetDataObj = JSON.parse(assetChanged[0].data);
        notAllowedMsg = 'This asset is on ' + assetDataObj.PlanName + ', please contact administrator to upgrade plan for this asset.';
        setTimeout(() => {
          this._sharedService.showToast({ severity: 'warn', summary: 'Access denied', detail: notAllowedMsg });
          console.log("Delayed status-dashboard.");
        }, 500);
      }
    }
    else {
      this._sharedService.clearToast();
    }
  }

    //#region Roles
  UpdateRoles(selAsset: any = null) {
    
    if (selAsset && selAsset[0]) {
      this._sharedService.updateRoleBySelectedAsset(selAsset[0]);
    }
    else {
      this._sharedService.updateRole();
    }

    if (SharedService.current_ParentMenu_Id == 22) { // status module
      this.canViewStatus = this._sharedService.UserRole.Dashboard_Status;
    }
    else if (SharedService.current_ParentMenu_Id == 99) { // repair module
      this.canViewStatus = this._sharedService.UserRole.Dashboard_Repairs;
    }
    
    this.canViewRecords = this._sharedService.UserRole.Dashboard_Records;
    this.canViewComms = this._sharedService.UserRole.Dashboard_Comms;
    this.canViewStats = this._sharedService.UserRole.Dashboard_Statistics;   
    }
  //#endregion

    //#region Dashboard Stats

    populateDashboardRecordsListingGrid(): void {
        this.resizeRecordMasterGridColumns();
        this._dashboardService.getStatusParentData()
        .subscribe({
            next: (response) => {
                this.resizeRecordMasterGridColumns();
                const result = response;
                let data: any;
                if (this._sharedService.selectedAsn != null && this._sharedService.selectedAsn.length > 0) {
                    this.assetData = [];
                    data = result[0].filter(r => r.ASN == this._sharedService.selectedAsn);
                    data = this._dashboardService.filterAndAggregateDFPStatusData(data);
                } else {
                    data = [];
                    data = this._dashboardService.filterAndAggregateDFPStatusData(result[0]);
                }
                this.AddOrphanDFPStatusAsBlack(data);
                this.assetData = data;//
                //this.assetBarGraphData = data; // result[0];
                ///this.setAssetBarChartColumnDefs(Object.getOwnPropertyNames(this.assetData[0]));
        
                if (data.length > 0) {
                    this.showAssetBarChart = true;
                    try {
                        this.setAssetBarChartColumnDefs(Object.getOwnPropertyNames(this.assetData[0]));
                        this.prepareAssetBarGraph(true);
                    } catch (e) {
                        //this.prepareViewStatusBarGraph();
                    }
                }
                else {
                    this.showAssetBarChart = false;
                    this.prepareAssetBarGraph(false);
                }
            },
            error: (error) => {
                console.log(error);
            }
        });
    }
    getdashboardStats() {
        this._dashboardService.getdashboardStatsData()
        .subscribe({
            next: (response) => {
                this.setPieChartOptions();
                this.statsData = [];
                let result = response;
                this.statsData = result[0];
                this.storageCapacity = Number(result[2]);
                //if (result[0])
                //{
                //    for (var i = 0; i < this.statsData.length; i++)
                //    {
                //        this.statsData[i].StatsPieChart = this.getStatsPieChart(this.statsData[i]);
                //    }
                //}
        
                this.statsLastUpdated = result[1];
                // this.getStatsPieChart();
            },
            error: (error) => {
                console.log(error);
            }
        });

    }
    setPieChartOptions() {
        this.pieOptions = {
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            responsive: false,
            tooltips: {
                // Disable the on-canvas tooltip
                enabled: false,

                custom: function (tooltipModel: any) {
                    // Tooltip Element
                    let tooltipEl: any = document.getElementById('chartjs-tooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.innerHTML = "<table></table>";
                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem: any) {
                        return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        var titleLines = tooltipModel.title || [];
                        var bodyLines = tooltipModel.body.map(getBody);

                        var innerHtml = '<thead>';

                        titleLines.forEach(function (title: any) {
                            innerHtml += '<tr><th>' + title + '</th></tr>';
                        });
                        innerHtml += '</thead><tbody>';

                        bodyLines.forEach(function (body: any, i: any) {
                            var colors = tooltipModel.labelColors[i];
                            var style = 'background:' + colors.backgroundColor;
                            style += '; border-color:' + colors.borderColor;
                            style += '; border-width: 2px';
                            var span = '<span style="' + style + '"></span>';
                            innerHtml += '<tr><td>' + span + body + '%' + '</td></tr>';
                        });
                        innerHtml += '</tbody>';

                        var tableRoot = tooltipEl.querySelector('table');
                        tableRoot.innerHTML = innerHtml;
                    }

                    // `this` will be the overall tooltip
                    var position = this._chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.left = position.left + tooltipModel.caretX + 'px';
                    tooltipEl.style.top = position.top + tooltipModel.caretY + 'px';
                    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                }
            }
        }

    }
    getStatsPieChart(statsData: Stats): any {
        let consumed: number = Number(statsData.StorageSpace.split(' ')[0]);
        let used: number = (consumed / this.storageCapacity) * 100;
        let remaining: number = 100 - used;

        return {
            labels: ['Used', 'Remaining'],
            datasets: [
                {
                    data: [Number(used.toFixed(2)), Number(remaining.toFixed(2))],
                    backgroundColor: [
                        "#FF0000",
                        "#00ff00"
                    ],
                    hoverBackgroundColor: [
                        "#FF0000",
                        "#00ff00"
                    ]
                }]
        };

    }
    refreshStats() {
        this.updatingStats = 'fa-spin';
        this._dashboardService.refreshDashboardStatsData()
        .subscribe({
            next: (response) => {
                this.statsData = [];
                let result = response;
                this.statsData = result[0];
                this.statsLastUpdated = result[1];
                this.storageCapacity = Number(result[2]);
                //if (result[0]) {
                //    for (var i = 0; i < this.statsData.length; i++) {
                //        this.statsData[i].StatsPieChart = this.getStatsPieChart(this.statsData[i]);
                //    }
                //}
                this.updatingStats = '';
            },
            error: (error) => {
                console.log(error);
                this.updatingStats = '';
            }
        });
    }
    refreshInboxData() {
        this.updatingInbox = 'fa-spin';
        this._dashboardService.refreshDashboardInboxData()
        .subscribe({
            next: (response) => {
                this.setInboxChartData(response);
                this.updatingInbox = '';
            },
            error: (error) => {
                console.log(error);
                this.updatingInbox = '';
            }
        });
    }
    refreshBinderData() {
        this.updatingBinder = 'fa-spin';
        this._dashboardService.refreshDashboardBinderData()
        .subscribe({
            next: (response) => {
                this.setBinderChartData(response);
                this.updatingBinder = '';
            },
            error: (error) => {
                console.log(error);
                this.updatingBinder = '';
            }
        });        
    }

    //#endregion

    // #region assetCount
    initializeAssetTypes(): void {
        this.assetsTypes = new AssetTypes(0, 0, 0, 0, 0);
    }
    getAssetsCount(): void {
        this._dashboardService.getAssetCountData()
        .subscribe({
            next: (response) => {
                let body = response;
                for (var i = 0; i < body.length; i++) {
                    let type = body[i].AssetType;
        
                    switch (type) {
        
                        case 'Airframe':
                            this.assetsTypes.Airframe = body[i].Count;
                            break;
                        case 'APU':
                            this.assetsTypes.APU = body[i].Count;
                            break;
                        case 'Engine':
                            this.assetsTypes.Engine = body[i].Count;
                            break;
                        case 'LDG':
                            this.assetsTypes.LDG = body[i].Count;
                            break;
                        case 'Propeller':
                            this.assetsTypes.Propeller = body[i].Count;
                            break;
                        default:
                            break;
                    }        
                }
            }
        });

    }
    // #endregion

    // #region Status Grid

    populateDashboardStatusListingGrid(): void {
      this.statusgridData = null;
      this._dashboardService.getStatusParentData(this.statusViewType)
        .subscribe({
            next: (response) => {
                this.statusgridData = [];
                this.statusgridData = this._dashboardService.filterAndAggregateDFPStatusData(response[0]);
                this.containerLastUpdated = response[1];
                if (this.statusgridData.length > 0) {
                    this.showFleetBarChart = true;
                    this.setDashboardStatusGridColumns(Object.getOwnPropertyNames(this.statusgridData[0]));
                    //this.resizeRecordMasterGridColumns();
                    this.prepareBarGraph(true);
                    this.drawDonutGraph(this.statusgridData)
                }
                else {
                    this.showFleetBarChart = false;
                    this.prepareBarGraph(false);
            }
            


            this._sharedService.showHideStatusMenu();

            this._sharedService.hideLoader();
            const result = response;
            let data: any;
            let selectedASN = null;
            if (
              this._sharedService.selectedAsset != null &&
              this._sharedService.selectedAsset.length > 0
            ) {
              //US-8181 //const selectedAsset = this._sharedService.selectedAsset[0];
              // const assetInfo: string[] = selectedAsset.data.split("|"); // selectedAsset[0].data.split('|');
              // selectedASN = assetInfo[1];

              selectedASN = JSON.parse(this._sharedService.selectedAsset[0].data).ASN;
              data = result[0].filter(r => r.ASN == selectedASN);
              data = this._dashboardService.filterAndAggregateDFPStatusDataOfASN(data);
              this.statusgridOptions.masterDetail = false;
              if (this.statusgridOptions.api) { this.statusgridOptions.api.refreshClientSideRowModel(); }
              //this.ShowHideIconStatusGridOptions(true);
            } else {
              this.statusgridOptions.masterDetail = true;
              if (this.statusgridOptions.api) { this.statusgridOptions.api.refreshClientSideRowModel(); }
              //this.ShowHideIconStatusGridOptions(false);
              data = [];
              data = this._dashboardService.filterAndAggregateDFPStatusData(
                result[0]
              );
            }
            this.AddOrphanDFPStatusAsBlack(data);
            this.statusgridData = data; // result[0];
            this.containerLastUpdated = result[1];

            // this.drawDonutGraph(result[0]);
            this.drawDonutGraph(data);
            try {
              if (this.statusgridData.length > 0) {
                this.setDashboardStatusGridColumns(
                  Object.getOwnPropertyNames(this.statusgridData[0]), selectedASN
                );
                this.prepareBarGraph(true);
              }
              else {
                this.prepareBarGraph(false);
                this.statuscolumnDefs = [];
              }

            } catch (e) {
              //this.prepareBarGraph();
              if (this.statusgridData.length > 0) {
                this.prepareBarGraph(true);
              }
              else {
                this.prepareBarGraph(false);
                this.statuscolumnDefs = [];
              }
            }
            },
            error: (error) => {
                console.log(error);
            }
        });
    }
  setBinderChartData(response) {
      return
        this.binderChartData = [];
        this.binderChartData = response[0];
        this.binderLastUpdated = response[1];
        var data = this._dashboardService.filterAndAggregateInboxDataInPercentage(this.binderChartData)
        //var data = []
        if (this.binderChartData.length > 0) {
            let yAxis = Object.getOwnPropertyNames(this.binderChartData[0])
            yAxis.shift();
            let colors = [];
            this.transitionChartChart = true;
            yAxis.forEach((val, index) => {
                let dfpData = this.containerQualityStatusList.filter(x => x.Name == val)[0];
                if (dfpData) {
                    colors.push(dfpData.CSSClass)
                }
                else {
                    let randomColor = this.randomColor();
                    colors.push(randomColor)
                }
            })
            this.binderChartOptions = {
                autoSize: true,
                height: 400,
                data: data,
                series: [
                    {
                        type: 'column',
                        xKey: "Type",
                        yKey: yAxis,
                        grouped: true,
                        fills: colors,
                        strokes:colors,
                        tooltip: {
                            renderer: function (params) {
                                return this._sharedService.chartToolTip(params)
                            }.bind(this)
                        },
                    },
                ],
                axes: [
                    {
                        type: 'category',
                        position: 'bottom',
                        label: { rotation: 290 },
                    },
                    {
                        type: 'number',
                        position: 'left',
                        label: {
                            formatter: function (params) {
                                return params.value;
                            },
                        },
                        tick:{
                            interval: 10
                          }
                    },
                ],
                legend: {
                    position: "bottom",
                },
            };
        }
        else {
            this.transitionChartChart = false;
        }
    }
    populateDashboardBinderChart(): void {
        this.binderChartData = [];
        this._dashboardService.getBinderData()
        .subscribe({
            next: (response) => {
                this.setBinderChartData(response);
            },
            error: (error) => {
                console.log(error);
            }
        });
    }

    setInboxChartData(response) {
        this.inboxChartData = [];
        this.inboxChartData = response[0];
        this.inboxLastUpdated = response[1];
        var data = this._dashboardService.filterAndAggregateInboxDataInPercentage(this.inboxChartData);
        //var data=[]
        if (this.inboxChartData.length > 0) {
            this.inboxDataChart = true;
            let yAxis = Object.getOwnPropertyNames(this.inboxChartData[0])
            yAxis.shift();
            this.inboxChartData[0]
            this.inboxChartOptions = {
                autoSize: true,
                height: 400,
                data: data,
                series: [
                    {
                        type: 'column',
                        xKey: "Type",
                        yKey: yAxis,
                        grouped: true,
                        tooltip: {
                            renderer: function (params) {
                                return this._sharedService.chartToolTip(params)
                            }.bind(this)
                        },
                    },
                ],
                axes: [
                    {
                        type: 'category',
                        position: 'bottom',
                        label: { rotation: 290 },
                    },
                    {
                        type: 'number',
                        position: 'left',
                        label: {
                            formatter: function (params) {
                                return params.value;
                            },
                        },
                    
                    tick:{
                        interval: 10
                      }
                    }
                ],
                legend: {
                    position: "bottom",
                },
            };
        }
        else {
            this.inboxDataChart = false;
        }
    }
    populateDashboardInboxChart(): void {
        this.inboxChartData = [];
        this._dashboardService.getInboxData()
        .subscribe({
            next: (response) => {
                this.setInboxChartData(response);
            },
            error: (error) => {
                console.log(error);
            }
        });
    }


    statusToggleChange() {
        this._sharedService.StatusToggleChanged(this.statusToggle);
    }

    populateDashboardStatusAssetListingGrid(): void {
        this.statusgridData = [];
        this._dashboardService.getStatusParentData(this.statusViewType)
        .subscribe({
            next: (response) => {
                this.statusgridData = [];
                this.statusgridData = response[0];
                this.containerLastUpdated = response[1];
                if (this.statusgridData.length > 0) {
                    this.showFleetBarChart = true;
                    this.setDashboardAssetStatusGridColumns(Object.getOwnPropertyNames(this.statusgridData[0]));
                    //this.resizeRecordMasterGridColumns();
                    // this.prepareBarGraph(true);
                }
                else {
                    this.showFleetBarChart = false;
                    // this.prepareBarGraph(false);
                }
            },
            error: (error) => {
                console.log(error);
            }
        });
  }
  // refreshRecordUpdateStatus: boolean = false;
  // RecordSummaryViewStatusgridData = [];
  // refreshRecordSummaryView() {
  //   this.refreshRecordUpdateStatus = true;
  //   this.RecordSummaryViewStatusgridData = [];

  //   this._dashboardService.getRecordsSummaryViewData()
  //     .subscribe(
  //       {
  //         next(result) {
  //           this.refreshRecordUpdateStatus = false;

  //         },
  //         error(err) {
            
  //         },
  //       }
  //     )
  // }
    refreshDashboardStatusListingGrid(): void {
        this.updatingStatus = true;
        this.statusgridData = [];
        this._dashboardService.refreshStatusData(null, null)
        .subscribe({
            next: (response) => {
                this.statusgridData = [];
                this.updatingStatus = false;
        
                this.statusgridData = this._dashboardService.filterAndAggregateDFPStatusData(response[0]);
                this.containerLastUpdated = response[1];
                if (this.statusgridData.length > 0) {
                    this.showFleetBarChart = true;
                    this.setDashboardStatusGridColumns(Object.getOwnPropertyNames(this.statusgridData[0]));
        
                    this.resizeRecordMasterGridColumns();
                    this.prepareBarGraph(true);
                }
                else {
                    this.showFleetBarChart = false;
                    this.prepareBarGraph(false);
                }
            },
            error: (error) => {
                console.log(error);
                this.updatingStatus = false;
            }
        });
    }

    updateStatusGridUI() {
        if (this.statusgridOptions.api != undefined) {
            this.statusgridOptions.api.sizeColumnsToFit();
        }
    }


    setDashboardAssetStatusGridColumns(columnNames: string[]): void {
        this.statuscolumnDefs = [];
        for (var i = 0; i < columnNames.length; i++) {
            let headerName = columnNames[i]; //columnNames[i].indexOf("_") > 0 ? columnNames[i].substring(columnNames[i].indexOf("_") + 1) : columnNames[i];
            if (columnNames[i] == "DFPStatus") {
                headerName = 'Compliance Status';
                this.statuscolumnDefs.push({
                    headerName: headerName,
                    menuTabs: ['filterMenuTab'],
                    field: columnNames[i],
                    width: 260,
                    pinned: "left",
                    cellRenderer: this.statusDFPInnerCellRenderer,
                })
            }
            else {
                // if (this.statuscolumnDefs.filter(c => c.headerName == headerName).length > 0) {
                //     this.statuscolumnDefs.push({ headerName: (headerName + i), field: columnNames[i], menuTabs: ['filterMenuTab'] });
                // }
                //  else {
                this.statuscolumnDefs.push({ headerName: headerName, field: columnNames[i], menuTabs: ['filterMenuTab'] });
                // }
            }
        }
        this.setDetailGridColumns();
    }



  setDashboardStatusGridColumns(columnNames: string[], selectedASN?: any): void {
      this.statuscolumnDefs = [];
      let arr = [];
      for (let i = 0; i < columnNames.length; i++) {
        let a = this._sharedService.selectedASNViews.find(c => c == columnNames[i]);
        if (a != null) {
          arr.push(columnNames[i]);
        }
      }

      columnNames = columnNames.filter(c => c != "ASN");
      if (!selectedASN) {
        for (let i = 0; i < columnNames.length; i++) {
          // let headerName =
          //   columnNames[i].indexOf("_") > 0
          //     ? columnNames[i].substring(columnNames[i].indexOf("_") + 1)
          //     : columnNames[i];
          let headerName = columnNames[i]; //columnNames[i].indexOf("_") > 0 ? columnNames[i].substring(columnNames[i].indexOf("_") + 1) : columnNames[i];
          //if asset is selected
          // if (!selectedASN) {
          if (columnNames[i] == "DFPStatus") {
            headerName = 'Compliance Status';
            this.statuscolumnDefs.push({
              headerName,
              field: columnNames[i],
              menuTabs: ["filterMenuTab"],
              width: 267,
              pinned: "left",
              cellRenderer: "agGroupCellRenderer",
              cellRendererParams: {
                suppressCount: false, // turn off the row count
                checkbox: false, // enable checkbox selection
                padding: 10,
                innerRenderer: this.statusDFPInnerCellRenderer, // provide an inner renderer
                // footerValueGetter: myFooterValueGetter // provide a footer value getter
                //cellRenderer: this.statusEditorCellRenderer
              }
            });
          } else {
            if (
              this.statuscolumnDefs.filter(c => c.headerName == headerName).length >
              0
            ) {
              this.statuscolumnDefs.push({
                headerName: headerName + i,
                field: columnNames[i],
                menuTabs: ["filterMenuTab"],
                // cellEditorParams: {
                //   cellRenderer: this.statusEditorCellRenderer
                // }
                //minwidth: 90
              });
            } else {
              this.statuscolumnDefs.push({
                headerName,
                field: columnNames[i],
                menuTabs: ["filterMenuTab"],
                // cellEditorParams: {
                //   cellRenderer: this.statusEditorCellRenderer
                // }
                //minwidth: 90
              });
            }
          }
          //End of if asset is selected
        }
      }
      else {

        for (let i = 0; i < arr.length; i++) {
          let headerName = arr[i];
          if (arr[i] == "DFPStatus") {
            headerName = 'Compliance Status';
            this.statuscolumnDefs.push({
              headerName,
              field: arr[i],
              menuTabs: ["filterMenuTab"],
              width: 267,
              pinned: "left",
              cellRenderer: this.statusDFPInnerCellRenderer,
              // cellRendererParams: {
              //   suppressCount: false, // turn off the row count
              //   checkbox: false, // enable checkbox selection
              //   padding: 10,
              //   innerRenderer: this.statusDFPInnerCellRenderer, // provide an inner renderer
              //   // footerValueGetter: myFooterValueGetter // provide a footer value getter
              //   //cellRenderer: this.statusEditorCellRenderer
              // }
            });
          }
          else {
            this.statuscolumnDefs.push({
              headerName,
              field: arr[i],
              menuTabs: ["filterMenuTab"],
              // cellEditorParams: {
              //   cellRenderer: this.statusEditorCellRenderer
              // }
              //minwidth: 90
            });
          }
        }
      }
        this.setDetailGridColumns();
    }

    setAssetBarChartColumnDefs(columnNames: string[]): void {
        this.assetBarChartColumnDefs = [];

        columnNames = columnNames.filter(c => c != 'ASN');
        for (var i = 0; i < columnNames.length; i++) {
            let headerName = columnNames[i]; //columnNames[i].indexOf("_") > 0 ? columnNames[i].substring(columnNames[i].indexOf("_") + 1) : columnNames[i];
            if (columnNames[i] == "DFPStatus") {
                headerName = 'Compliance Status';
                this.assetBarChartColumnDefs.push({
                    headerName: headerName,
                    field: columnNames[i],
                    menuTabs: ['filterMenuTab'],
                    width: 260,
                    pinned: "left",
                    cellRenderer: 'agGroupCellRenderer',
                    cellRendererParams: {
                        suppressCount: false, // turn off the row count
                        checkbox: false, // enable checkbox selection
                        padding: 10,
                        innerRenderer: this.statusDFPInnerCellRenderer, // provide an inner renderer
                        resizeable: true
                        //footerValueGetter: myFooterValueGetter // provide a footer value getter
                    }
                })
            } else {

                this.assetBarChartColumnDefs.push({ headerName: headerName, field: columnNames[i], menuTabs: ['filterMenuTab'], width: 90 });

                // if (this.assetBarChartColumnDefs.filter(c => c.headerName == headerName).length > 0) {
                //     this.assetBarChartColumnDefs.push({ headerName: (headerName + i), field: columnNames[i], menuTabs: ['filterMenuTab'], width: 90 });
                // } else {
                //     this.assetBarChartColumnDefs.push({ headerName: headerName, field: columnNames[i], menuTabs: ['filterMenuTab'], width: 90 });
                // }
            }
        }

    }

    populateStatusDetailGrid(param: any) {
        if (param.node.expanded && param.data && param.data['DFPStatus']) {
            let dfpStatus: string = param.data['DFPStatus'];
            this._dashboardService.getStatusChildData(dfpStatus.trim())
            .subscribe({
                next: (response) => {
                    let result = response;
                    statusDetailGridApi.setRowData(result);
                    //if (result.length > 0) { statusDetailGridApi.hideOverlay(); }
                    //else { statusDetailGridApi.showNoRowsOverlay(); }
                },
                error: (error) => {
                    statusDetailGridApi.setRowData([]);
                    statusDetailGridApi.showNoRowsOverlay();
                    console.log(error);
                }
            });
        }
    }

    setDetailGridColumns() {
        statusDetailGridColumnDefs = [];
        statusDetailGridColumnDefs.push({ headerName: 'Asset', field: 'ASN', menuTabs: ['filterMenuTab'], width: 266, pinned: "left", cellRenderer: this.recordGridGroupRenderer });

        for (var i = 1; i < this.statuscolumnDefs.length; i++) { // startig from index 1 ignore 1st column element
            if (this.statuscolumnDefs[i].headerName != 'ASN') {
                statusDetailGridColumnDefs.push(this.statuscolumnDefs[i]);
            }
        }
    }

    statusDFPInnerCellRenderer(params: any) {
        var eSpan = document.createElement('span');
        if (params.value) {
            let dfpStatusName: string = params.value.trim();
            let currentStatus: DFPStatusModel = dfpStatuses.filter(x => x.Name == dfpStatusName)[0];
            if (currentStatus && currentStatus.Name) {
                if (currentStatus.Name == 'Suggested - no document found' || currentStatus.Name == 'Suggested - partial documents found' || currentStatus.Name == 'Suggested - documents found' || currentStatus.Name == 'Suggested - not applicable') {
                    eSpan.style.color = currentStatus.CSSClass;
                }
                else {
                    eSpan.style.color = 'white';
                    eSpan.style.backgroundColor = currentStatus.CSSClass;
                }
            }
            else {
                eSpan.style.color = 'white';
                eSpan.style.backgroundColor = '#333';
            }
        }

        eSpan.style.padding = '3px';
        eSpan.innerHTML = params.value.trim();
        eSpan.style.textAlign = 'center';
        return eSpan;
    }

    // #endregion

    // #region Record Grid
    selectFirstRow() {
        if (this.recordMasterGridData.length) {
            this.containerMasterGridOptions.api.getDisplayedRowAtIndex(0).setSelected(true);
            let selRow = this.containerMasterGridOptions.api.getSelectedRows();
            this.selectedRowData = selRow[0];
            //this.drawDonutGraph(selRow[0]);
            this._sharedService.selectedAsn = this.selectedRowData.Aircraft;

            this.populateDashboardRecordsListingGrid();
        }
    }
    recordGridGroupRenderer(params: any) {
        var eSpan = document.createElement('span');
        if (params.value) {

            let type = params.data.AssetType;

            switch (type) {

                case 'Airframe':
                    eSpan.className = "fa fa-plane";
                    break;
                case 'Helicopter':
                    eSpan.className = "fa fa-helicopter";
                    break;
                case 'APU':
                    eSpan.className = "icon-APU2";
                    break;
                case 'Engine':
                    eSpan.className = "icon-Engine";
                    break;
                case 'LDG':
                    eSpan.className = "icon-landinggear";
                    break;
                case 'Propeller':
                    eSpan.className = "fa icon-propeller";
                    break;
                case 'Collection':
                    eSpan.className = "fa fa-cubes";
                    break;
                case 'Item':
                    eSpan.className = "fa fa-cube";
                    break;
                default:
                    break;
            }

            eSpan.innerHTML = "<span style='margin-left: 5px;'>" + params.value + "</span>";
        }
        return eSpan;
    }
    populateDashboardContainerListingGrid(): void {
      containerTypes = [];
      forkJoin(
        {
          ContainerTypes: this._containerService.getAllContainerTypesData(null),
          recordData: this._dashboardService.getRecordData()
        })
        .subscribe({
          next: (response)=> {
            containerTypes = response.ContainerTypes;
            this._sharedService.hideLoader();
            let body = response.recordData;
            this.compileRecordDashboardData(body[0]);
            this.recordLastUpdated = body[1];
        },
      })
    }
    getRecordData(): void {
        this._dashboardService.getRecordData()
        .subscribe({
            next: (response) => {
                this._sharedService.hideLoader();
                let body = response;
                this.compileRecordDashboardData(body[0]);
                this.recordLastUpdated = body[1];
            }
        });

    }
    compileRecordDashboardData(body: any) {
        try {
            let data: any[] = [];
            this.recordMasterGridData = [];
            data = body.RecordDashboard;
            if (data) {
                // Set Headers of Record Grid
                if (containerTypes && containerTypes.length > 0) {
                    this.setDashboardContainerGridColumns(body.GridHeaders);

                    let compiledArray: any[] = [];

                    let parentsArrays: any[] = data.filter(x => x.ParentAsset == '0');

                    for (let i = 0; i < parentsArrays.length; i++) {
                        let childArray: any[] = data.filter(x => x.ParentAsset == parentsArrays[i].AssetId || x.LinkAsset == parentsArrays[i].AssetId);
                        let parentASN: string[] = [];

                        //let PlaneTypeArr=parentsArrays[i].PlaneType.split('-');
                        //if(PlaneTypeArr.length == 3 && PlaneTypeArr[PlaneTypeArr.length-1]=="")
                        //{
                        //    parentsArrays[i].PlaneType=PlaneTypeArr[0]+"-"+PlaneTypeArr[1];
                        //}
                        parentASN.push(parentsArrays[i].Description);
                        //compiledArray.push({ ASNTree: parentASN, ContainerID: parentsArrays[i].ContainerID, ASN: parentsArrays[i].ASN, AssetType: parentsArrays[i].AssetType, AssetId: parentsArrays[i].AssetId, ParentAsset: parentsArrays[i].ParentAsset, _1: parentsArrays[i]._1, _2: parentsArrays[i]._2, _3: parentsArrays[i]._3, _4: parentsArrays[i]._4, _5: parentsArrays[i]._5, _6: parentsArrays[i]._6, _7: parentsArrays[i]._7, _8: parentsArrays[i]._8, _9: parentsArrays[i]._9, _10: parentsArrays[i]._10, _11: parentsArrays[i]._11, _12: parentsArrays[i]._12 });
                        //R.A. //compiledArray.push({ ASNTree: parentASN, Aircraft: parentsArrays[i].ASN, Manufacturer: parentsArrays[i].PlaneType, AssetType: parentsArrays[i].AssetType, AssetId: parentsArrays[i].AssetId, ParentAsset: parentsArrays[i].ParentAsset, _1: parentsArrays[i]._1, _2: parentsArrays[i]._2, _3: parentsArrays[i]._3, _4: parentsArrays[i]._4, _5: parentsArrays[i]._5, _6: parentsArrays[i]._6, _7: parentsArrays[i]._7, _8: parentsArrays[i]._8, _9: parentsArrays[i]._9, _10: parentsArrays[i]._10, _11: parentsArrays[i]._11, _12: parentsArrays[i]._12 });
                        compiledArray.push({
                            ASNTree: parentASN, Aircraft: parentsArrays[i].ASN, Manufacturer: parentsArrays[i].PlaneType, AssetType: parentsArrays[i].AssetType, AssetId: parentsArrays[i].AssetId, ParentAsset: parentsArrays[i].ParentAsset,
                            _1: this.filterAndAggregateCheckData(parentsArrays[i]._1),
                            _2: this.filterAndAggregateCheckData(parentsArrays[i]._2),
                            _3: this.filterAndAggregateCheckData(parentsArrays[i]._3),
                            _4: this.filterAndAggregateCheckData(parentsArrays[i]._4),
                            _5: this.filterAndAggregateCheckData(parentsArrays[i]._5),
                            _6: this.filterAndAggregateCheckData(parentsArrays[i]._6),
                            _7: this.filterAndAggregateCheckData(parentsArrays[i]._7),
                            _8: this.filterAndAggregateCheckData(parentsArrays[i]._8),
                            _9: this.filterAndAggregateCheckData(parentsArrays[i]._9),
                            _10: this.filterAndAggregateCheckData(parentsArrays[i]._10),
                            _11: this.filterAndAggregateCheckData(parentsArrays[i]._11),
                            _12: this.filterAndAggregateCheckData(parentsArrays[i]._12)
                        });
                        if (childArray && childArray.length > 0) {
                            for (let j = 0; j < childArray.length; j++) {
                                let childASN: string[] = [];
                                childASN.push(parentsArrays[i].Description);
                                childASN.push(childArray[j].Description);
                                //compiledArray.push({ ASNTree: childASN, ContainerID: childArray[j].ContainerID, ASN: childArray[j].ASN, AssetType: childArray[j].AssetType, AssetId: childArray[j].AssetId, ParentAsset: childArray[j].ParentAsset, _1: childArray[j]._1, _2: childArray[j]._2, _3: childArray[j]._3, _4: childArray[j]._4, _5: childArray[j]._5, _6: childArray[j]._6, _7: childArray[j]._7, _8: childArray[j]._8, _9: childArray[j]._9, _10: childArray[j]._10, _11: childArray[j]._11, _12: childArray[j]._12 });
                                //R.A. //compiledArray.push({ ASNTree: childASN, Manufacturer: childArray[j].PlaneType, Aircraft: childArray[j].ASN, AssetType: childArray[j].AssetType, AssetId: childArray[j].AssetId, ParentAsset: childArray[j].ParentAsset, _1: childArray[j]._1, _2: childArray[j]._2, _3: childArray[j]._3, _4: childArray[j]._4, _5: childArray[j]._5, _6: childArray[j]._6, _7: childArray[j]._7, _8: childArray[j]._8, _9: childArray[j]._9, _10: childArray[j]._10, _11: childArray[j]._11, _12: childArray[j]._12 });
                                compiledArray.push({
                                    ASNTree: childASN, Manufacturer: childArray[j].PlaneType, Aircraft: childArray[j].ASN, AssetType: childArray[j].AssetType, AssetId: childArray[j].AssetId, ParentAsset: childArray[j].ParentAsset,
                                    _1: this.filterAndAggregateCheckData(childArray[j]._1),
                                    _2: this.filterAndAggregateCheckData(childArray[j]._2),
                                    _3: this.filterAndAggregateCheckData(childArray[j]._3),
                                    _4: this.filterAndAggregateCheckData(childArray[j]._4),
                                    _5: this.filterAndAggregateCheckData(childArray[j]._5),
                                    _6: this.filterAndAggregateCheckData(childArray[j]._6),
                                    _7: this.filterAndAggregateCheckData(childArray[j]._7),
                                    _8: this.filterAndAggregateCheckData(childArray[j]._8),
                                    _9: this.filterAndAggregateCheckData(childArray[j]._9),
                                    _10: this.filterAndAggregateCheckData(childArray[j]._10),
                                    _11: this.filterAndAggregateCheckData(childArray[j]._11),
                                    _12: this.filterAndAggregateCheckData(childArray[j]._12)
                                });
                            }
                        }
                    }
                    // compiledArray.PlaneType
                    this.recordMasterGridData = compiledArray;
                }
                else {
                    this._containerService.getAllContainerTypesData(null)
                    .subscribe({
                        next: (response) => {
                            containerTypes = [];
                            containerTypes = response;
                            this.setDashboardContainerGridColumns(body.GridHeaders);
        
                            let compiledArray: any[] = [];
        
                            let parentsArrays: any[] = data.filter(x => x.ParentAsset == '0');
        
                            for (let i = 0; i < parentsArrays.length; i++) {
                                let childArray: any[] = data.filter(x => x.ParentAsset == parentsArrays[i].AssetId);
                                let parentASN: string[] = [];
                                parentASN.push(parentsArrays[i].Description);
                                //compiledArray.push({ ASNTree: parentASN, ContainerID: parentsArrays[i].ContainerID, ASN: parentsArrays[i].ASN, AssetType: parentsArrays[i].AssetType, AssetId: parentsArrays[i].AssetId, ParentAsset: parentsArrays[i].ParentAsset, _1: parentsArrays[i]._1, _2: parentsArrays[i]._2, _3: parentsArrays[i]._3, _4: parentsArrays[i]._4, _5: parentsArrays[i]._5, _6: parentsArrays[i]._6, _7: parentsArrays[i]._7, _8: parentsArrays[i]._8, _9: parentsArrays[i]._9, _10: parentsArrays[i]._10, _11: parentsArrays[i]._11, _12: parentsArrays[i]._12 });
                                compiledArray.push({ ASNTree: parentASN, Aircraft: parentsArrays[i].ASN, Manufacturer: parentsArrays[i].PlaneType, AssetType: parentsArrays[i].AssetType, AssetId: parentsArrays[i].AssetId, ParentAsset: parentsArrays[i].ParentAsset, _1: parentsArrays[i]._1, _2: parentsArrays[i]._2, _3: parentsArrays[i]._3, _4: parentsArrays[i]._4, _5: parentsArrays[i]._5, _6: parentsArrays[i]._6, _7: parentsArrays[i]._7, _8: parentsArrays[i]._8, _9: parentsArrays[i]._9, _10: parentsArrays[i]._10, _11: parentsArrays[i]._11, _12: parentsArrays[i]._12 });
                                if (childArray && childArray.length > 0) {
                                    for (let j = 0; j < childArray.length; j++) {
                                        let childASN: string[] = [];
                                        childASN.push(parentsArrays[i].Description);
                                        childASN.push(childArray[j].Description);
                                        //compiledArray.push({ ASNTree: childASN, ContainerID: childArray[j].ContainerID, ASN: childArray[j].ASN, AssetType: childArray[j].AssetType, AssetId: childArray[j].AssetId, ParentAsset: childArray[j].ParentAsset, _1: childArray[j]._1, _2: childArray[j]._2, _3: childArray[j]._3, _4: childArray[j]._4, _5: childArray[j]._5, _6: childArray[j]._6, _7: childArray[j]._7, _8: childArray[j]._8, _9: childArray[j]._9, _10: childArray[j]._10, _11: childArray[j]._11, _12: childArray[j]._12 });
                                        compiledArray.push({ ASNTree: childASN, Manufacturer: childArray[j].PlaneType, Aircraft: childArray[j].ASN, AssetType: childArray[j].AssetType, AssetId: childArray[j].AssetId, ParentAsset: childArray[j].ParentAsset, _1: childArray[j]._1, _2: childArray[j]._2, _3: childArray[j]._3, _4: childArray[j]._4, _5: childArray[j]._5, _6: childArray[j]._6, _7: childArray[j]._7, _8: childArray[j]._8, _9: childArray[j]._9, _10: childArray[j]._10, _11: childArray[j]._11, _12: childArray[j]._12 });
                                    }
                                }
                            }
                            this.recordMasterGridData = compiledArray;
                        },
                        error: (error) => {
                            console.log(error);
                        }
                    });
                }
            }
        }
        catch (e) {
            console.log(e);
        }
        this.cRef.markForCheck();
    }
    resizeRecordMasterGridColumns() {
        //this.gridColumnApi.sizeColumnsToFit();

        var allColumnIds = [];
        if ((this.gridColumnApi != null || this.gridColumnApi != undefined)
            && (this.gridColumnApi.getColumns() != null || this.gridColumnApi.getColumns() != undefined)) {

            this.gridColumnApi.getColumns().forEach(function (column) {
                allColumnIds.push(column.colId);
            });
            this.gridColumnApi.autoSizeColumns(allColumnIds, false);
        }
    }
    ngOnDestroy() {
        nextRoute = null;
        this.confirmDeleteIncomingFeed();
        if (this.subscription) {
            this.subscription.unsubscribe();
      }
      if (this.assetChangeSubscription$) {
        this.assetChangeSubscription$.unsubscribe();
      }
      if (this.assetStatusChangeSubscription$) {
        this.assetStatusChangeSubscription$.unsubscribe();
      }
      if (this.parentMenuChanngeSubscription$) {
        this.parentMenuChanngeSubscription$.unsubscribe();
      }
    }




    rowGroupCallback(params) {
        return params.node.key;
    }
    setExcelStyle() {
        this.excelStyles = [
            {
                id: "header",
                interior: {
                    color: "#CCCCCC",
                    pattern: "Solid"
                }
            },
            {
                id: "twoDecimalPlaces",
                numberFormat: { format: "#,##0.00" }
            },
            {
                id: "textFormat",
                dataType: "string"
            },
            {
                id: "bigHeader",
                font: {
                    size: 18,
                    bold: true
                },
                alignment: {
                    horizontal: 'Center', vertical: 'Center'
                }
            }
        ];
    }

    onStatusGridExport(params1) {
        let headerString = '[{"cells":[{"styleId":"bigHeader","data":{"type":"String","value":"{TenantName}"}},{"styleId":"bigHeader","data":{"type":"String","value":"{ExcelTitle}"},"mergeAcross":5},{"styleId":"bigHeader","data":{"type":"String","value":"ROAM"}}]},{"cells":[{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":"Date/Time:"}},{"styleId":"","data":{"type":"String","value":"{DateTime}"}}]},{"cells":[{"styleId":"","data":{"type":"String","value":"Filter:"}},{"styleId":"","data":{"type":"String","value":"{Filter}"}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":"User:"}},{"styleId":"","data":{"type":"String","value":"{UserName}"}}]},{ "cells": [] }]'
        headerString = headerString.replace('{TenantName}', this._sharedService.tenantName);
        headerString = headerString.replace('{UserName}', this._sharedService.getCurrentUserName());
        headerString = headerString.replace('{DateTime}', this.getFormatedDate(new Date()));
        headerString = headerString.replace('{Filter}', this.getFilterStatusString());
        var fileName = null;
        if (this.statusToggle == 'Asset') {
            headerString = headerString.replace('{ExcelTitle}', "Asset - Compliance Status Report");
            fileName = 'Asset-Status Report-' + moment(Date.now()).format('YYYYMMDD') + '.xlsx'
        }
        else {
            headerString = headerString.replace('{ExcelTitle}', "Fleet - Compliance Status Report");
            fileName = 'Fleet-Status Report-' + moment(Date.now()).format('YYYYMMDD') + '.xlsx'
        }
        var params: any = {
            columnWidth: function (params) {
                var originalWidth = params.column.actualWidth;
                if (params.index == 0) {
                    return 200;
                }
                return 70;
            },
            olumnBestFit: true,
            skipColumnGroupHeaders: false,
            allColumns: false,
            onlySelected: false,
            fileName: fileName,
            sheetName: 'Report',
        };
        const getRows: () => ExcelRow[] = () => JSON.parse(headerString);
        params.prependContent = getRows();
        this.statusGridApi.exportDataAsExcel(
            params
        );
    }
    getFilterStatusString(): string {
        let filterModel = this.statusGridApi.getFilterModel();
        let filterString = '';
        for (var filter in filterModel) {
            let isEndsWith_1 = filter.endsWith('_1')
            if (isEndsWith_1) {
                let newValue = filter.replace('_1', '')
                let filterValue = this.getFilterFieldValue(newValue);
                if (filterValue == '') {
                    filterString = filterString.concat(filter, ', ');
                }
                else {
                    filterString = filterString.concat(filterValue, ', ');
                }
            }
            else {
                filterString = filterString.concat(filter, ', ');
            }

        }

        return filterString ? filterString.slice(0, -2) : 'No';
    }
    getFilterFieldValue(value) {
        let filterValue = this.statuscolumnDefs.find(({ field }) => field === value);
        if (filterValue) {
            return filterValue.field;
        }
        else {
            return '';
        }

    }
    getFormatedDate(date: any) {
        if (date) {
            return moment(date).format(this._sharedService.getDateTimeFormatConfiguration().dateformat.toUpperCase());
        } else {
            return '';
        }
    }



    onAssetGridExport(params1) {
        let headerString = '[{"cells":[{"styleId":"bigHeader","data":{"type":"String","value":"{TenantName}"}},{"styleId":"bigHeader","data":{"type":"String","value":"{ExcelTitle}"},"mergeAcross":5},{"styleId":"bigHeader","data":{"type":"String","value":"ROAM"}}]},{"cells":[{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":"Date/Time:"}},{"styleId":"","data":{"type":"String","value":"{DateTime}"}}]},{"cells":[{"styleId":"","data":{"type":"String","value":"Filter:"}},{"styleId":"","data":{"type":"String","value":"{Filter}"}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":""}},{"styleId":"","data":{"type":"String","value":"User:"}},{"styleId":"","data":{"type":"String","value":"{UserName}"}}]},{ "cells": [] }]'
        headerString = headerString.replace('{TenantName}', this._sharedService.tenantName);
        headerString = headerString.replace('{ExcelTitle}', "Asset - Records Report");
        headerString = headerString.replace('{UserName}', this._sharedService.getCurrentUserName());
        headerString = headerString.replace('{DateTime}', this.getFormatedDate(new Date()));
        headerString = headerString.replace('{Filter}', this.getFilterAssetString());

        var params: ExcelExportParams = {
            skipColumnGroupHeaders: false,
            allColumns: false,
            onlySelected: false,
            fileName: 'Asset-Records Report-' + moment(Date.now()).format('YYYYMMDD') + '.xlsx',
            sheetName: 'Report',
            processCellCallback: function (params: any) {
                if (params.column.colDef.headerName != 'Asset') {
                    var value = GetDashBoardExcelValue(params)
                    return value;
                }
                else {
                    return params.value;
                }
            },
        };
        const getRows: () => ExcelRow[] = () => JSON.parse(headerString);
        params.prependContent = getRows();
        this.api.exportDataAsExcel(params);
    }

    getFilterAssetString(): string {
        let filterModel = this.api.getFilterModel();
        let filterString = '';
        for (var filter in filterModel) {
            if (filter == 'ag-Grid-AutoColumn') {
                filter = 'Asset';
            }
            filterString = filterString.concat(filter, ', ');
        }

        return filterString ? filterString.slice(0, -2) : 'No';
    }


    setDashboardContainerGridColumns(gridHeaders: string): void {
        this.columnDefs = [];
        this.columnDefs.push({ headerName: 'Type', field: 'Manufacturer', minWidth: 100, width: 150, filter: false, suppressMenu: true });
        if (gridHeaders && gridHeaders != "") {
            let headers: string[] = gridHeaders.split(',');

            for (var i = 0; i < headers.length; i++) {
                this.columnDefs.push({ headerName: headers[i], field: '_' + (i + 1), minWidth: 50, width: 95, cellRenderer: this.tagsCellRenderer, filter: false, suppressMenu: true, resizable: true });
                this.columnDefs[i].autoHeight = true;
                this.columnDefs[i].cellClass = "cell-wrap-text";
            }
        }
        //// Sort Type desc by default
        //this.columnDefs[0].sort = 'desc'
    }


    filterAndAggregateCheckData(params: any) {

        const CONTAINER_CATEGORIES: string[] = ["MXR", "LOG", "GEN", "DOC", "BND"];
        let check = [];
        let checkData = [];
        let reducedCheckData = [];

        if (params) {

            let tagArray = String(params).split('^,^');
            tagArray.sort();
            tagArray.reduce(function (res, value) {

                var chk = String(value).split('^|^');
                check.push(chk[0]);

                //chk[0] // Check Type Name [A, OOP, C]
                //chk[1] // Container ID from DB
                //chk[2] // Work Order Number
                //chk[3] // Container Start Date
                //chk[4] // Container End Date
                //chk[5] // Container Category

                //Setting up date formats

                //if (chk.length >= 4) {
                //console.log('Before => ' + JSON.stringify(chk));
                if (chk[3] && chk[3] != '') {
                    let dateString = chk[3].substr(0, 10);
                    chk[3] = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).toString();
                }

                //chk[4] may contains end date or container category
                var isContainerCategory = CONTAINER_CATEGORIES.find(function (cat) {
                    return cat === chk[4];
                });
                if(typeof chk[4] !=undefined && chk[4]){
                if (!isContainerCategory) {
                    let dateString = chk[4].substr(0, 10);
                    let dtResult = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).toString();
                    chk[4] = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).toString();
                }
                else {
                    chk.push(chk[4]);
                }
            }
                // else if (chk[4] && chk[4] != '' ) {//Add new Check//Rizwan Ahmad
                //     let dateString = chk[4].substr(0, 10);
                //     let dtResult = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).toString();
                //     chk[4] = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).toString();
                // }
                //console.log('After => ' + JSON.stringify(chk));
                // }


                checkData.push({ checkType: chk[0], chk, value });

                return res;
            }, {});


            check = [];
            let checkObjToAdd = null;
            let isCheckTypeFound = false;
            let isFound = false;
            checkData.reduce(function (res, value) {

                checkObjToAdd = null;
                isCheckTypeFound = false;
                isFound = false;

                reducedCheckData.forEach(ch => {
                    if (ch.checkType == value.checkType) {
                        isCheckTypeFound = true;
                        ch.checkDataList.forEach(chkData => {
                            if (chkData.value == value.value) {
                                isFound = true;
                            }
                        });
                    }
                });

                if (!isFound) {
                    checkObjToAdd = value;
                }

                //});



                if (checkObjToAdd && isCheckTypeFound) {

                    let index = reducedCheckData.findIndex(x => x.checkType === checkObjToAdd.checkType);

                    reducedCheckData[index].checkDataList.push(checkObjToAdd);
                    reducedCheckData[index].checkCount++;
                }
                else {
                    reducedCheckData.push({
                        checkType: value.checkType,
                        checkDataList: [value],
                        checkCount: 1
                    })
                }


                return res;
            }, {});


        }

        return reducedCheckData;
    }

    tagsCellRenderer(params: any) {
        let html: string = '';
        let check = [];
        let checkData = [];
        let reducedCheckData = [];


        if (params.value.length > 0) {
            reducedCheckData = params.value;

            for (let i = 0; i < reducedCheckData.length; i++) {
                let color: string = '';
                //Get Tag Color
                let nameIdpair = reducedCheckData[i];//.split('^|^');
                let name = nameIdpair.checkType;
                let checkTypeCount = nameIdpair?.checkDataList?.length??0;
                let tagLabel = name + (checkTypeCount > 1 ? ' (' + checkTypeCount + ')' : '');
                let id = checkTypeCount;//[1];
                let title = checkTypeCount;//[2];
                if (!title) {
                    title = '';
                }
                for (let j = 0; j < containerTypes.length; j++) {
                    if (containerTypes[j].Type == name) {
                        color = containerTypes[j].ColorCode;
                    }
                }

                //html = html + '<a style="margin-right: 0.2em;"><span id=' + id + ' class="padding-5 label hand-cursor" style= "background-color:' + color + ';padding-left: 4px !important;padding-top:1px !important;padding-right: 4px !important;padding-bottom: 1px !important;" title="' + name + '">' + name + ' (' + checkTypeCount + ')</span></a>';
                html = html + '<a style="margin-right: 0.2em;"><span id=' + id + ' class="padding-5 label hand-cursor" style= "background-color:' + color + ';padding-left: 4px !important;padding-top:1px !important;padding-right: 4px !important;padding-bottom: 1px !important;" title="' + name + '">' + tagLabel + '</span></a>';
            }
        }

        var eDiv = document.createElement('div');
        eDiv.innerHTML = html;
        return eDiv;
    }

    onCellClicked(event: any) {
        this.selectedRowData = event.data;
        if (event.colDef && event.colDef.field != 'ASN') {
            let selectedValue = event.event.target.textContent; // This line can be a culprit due to selected text
            if (selectedValue && selectedValue.length > 0) {
                selectedValue = selectedValue.split(' (')[0];
            }
            //this.selectedContainerID = event.event.target.id;
            let selectedMonthChecks = this.selectedRowData[event.column.colDef.field];
            let selectedChecks = selectedMonthChecks.filter(x => x.checkType == selectedValue);
            if (selectedChecks && selectedChecks.length > 0) {
                this.selectedCheck = selectedChecks[0];
                this.displayCheckDetail(this.selectedCheck);
            }
        }
    }

    displayCheckDetail(selectedCheck: any) {
        const CONTAINER_CATEGORIES: string[] = ["MXR", "LOG", "GEN", "DOC", "BND"];
        this.displayGroupCheckForm = true;
        this.groupCheckList = [];
        //let referenceKeyLabel = this._sharedService.getReferenceKeyLabel();
        selectedCheck.checkDataList.forEach(element => {

            //element.chk[0] // Check Type Name [A, OOP, C]
            //element.chk[1] // Container ID from DB
            //element.chk[2] // Work Order Number
            //element.chk[3] // Container Start Date
            //element.chk[4] // Container End Date
            //element.chk[4] // Container Category

            //element.checkType = "Check - " + element.chk[0] + " - " + referenceKeyLabel + " - " + element.chk[2];
            element.checkType = element.chk[0];// + " - " + element.chk[3];// + " - " + element.chk[2];
            if (element.chk[3] && element.chk[3] != '') {
                element.checkType += " - " + element.chk[3];// Container Start Date
            }
            //container type log check
            var isContainerCategory = CONTAINER_CATEGORIES.find(function (cat) {
                return cat === element.chk[element.chk.length - 1];
            });

            // if(!isContainerCategory){
            // }

            if (element.chk[2] && element.chk[2] != '' && isContainerCategory != 'LOG') {
                element.checkType += " - " + element.chk[2];// Work Order Number //Reference No.
            }
            else {
                if (element.chk.length >= 4 && element.chk[4] && element.chk[4] != '') {
                    element.checkType += " - " + element.chk[4];// Container End Date
                }
            }
        });
        this.groupCheckList = selectedCheck.checkDataList;
        this.cRef.markForCheck();
    }

    btnCloseClick() {
        this.groupCheckList = [];
        this.displayGroupCheckForm = false;
        this.cRef.markForCheck();
    }

    onlistboxCheckClicked(event: any) {

        let selectedValue = event.value.checkType;//event.event.target.textContent;
        selectedValue = event.value.chk[0];
        let containerID = event.value.chk[1];//event.event.target.id;
        if (containerID) {
            this.typeClicked(selectedValue, containerID);
        }
        //}
    }

    onRowSelectionChanged(event: any) {

        this.selectedRowData = event.data;
        this._sharedService.selectedAsn = this.selectedRowData.Aircraft;
        this.populateDashboardRecordsListingGrid();
        //this.drawDonutGraph(event.data);

    }
    donutChartClick(data: any) {
        let containerType = data.element._model.label;
        this.isNavigationAllowed = (this.checkUserRoles() && this.checkUserPermissionForScreen(containerType));
        if (this.isNavigationAllowed) {
            this._assetService.getAssetTreeNode(this.selectedRowData.Aircraft)
            .subscribe({
                next: (response) => {
                    //Set Selected Asset
                    var arrayResult = [];
                    arrayResult.push(response)
                    this._sharedService.selectedAssetTreeNode = arrayResult;
                    this._sharedService.onAssetChanged(arrayResult);
                    //this._sharedService.onAssetStatusChanged(arrayResult);
        
                    this._sharedService.isNavigationFilterCanceled = true;
                    //Get Container Details
                    //let containerId = Number(containerID);
                    let categoryTypes = containerTypes.filter(x => x.Type == containerType);
                    let category = categoryTypes[0].Category;
                    if (!category) {
                        category = 'MXR';
                    }
                    switch (category) {
                        case 'MXR':
                            this._sharedService.setRouteInfoAndNavigateFromDashboard({ MenuID: 16, PageURL: '/record/browse', cat: 'mxr', id: 0, containerType: containerType, ColorCode: 'bg-color-msgreen' });
                            break;
                        case 'LOG':
                            this._sharedService.setRouteInfoAndNavigateFromDashboard({ MenuID: 16, PageURL: '/record/browse', cat: 'logs', id: 0, containerType: containerType, ColorCode: 'bg-color-msgreen' });
                            break;
                        case 'GEN':
                            this._sharedService.setRouteInfoAndNavigateFromDashboard({ MenuID: 16, PageURL: '/record/browse', cat: 'general', id: 0, containerType: containerType, ColorCode: 'bg-color-msgreen' });
                            break;
                        case 'DOC':
                            this._sharedService.setRouteInfoAndNavigateFromDashboard({ MenuID: 16, PageURL: '/record/browse', cat: 'documents', id: 0, containerType: null, ColorCode: 'bg-color-msgreen' });
                            break;
                        case 'BND':
                            this._sharedService.setRouteInfoAndNavigateFromDashboard({ MenuID: 113, PageURL: '/transition/binders', cat: 'binders', id: 0, containerType: containerType, ColorCode: 'bg-color-greenpea' });
                            break;
                    }
                }
            });
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Restricted Access', detail: 'You are not allowed to view details.', id: 'dashboardmsg' });
        }

    }

    getColorCodes(distinctLabels: string[]): string[] {
        let colorCodesArray: string[] = [];
        //  let colorTags = this.loadTags();
        if (containerTypes.length == 0) {
            //   console.log('Container types length:' + containerTypes.length);
            containerTypes = this._sharedService.getContainerTypes();
            //   console.log('Container types length new:' + containerTypes.length);
        }
        for (let i = 0; i < distinctLabels.length; i++) {
            let value = containerTypes.filter(x => x.Type == distinctLabels[i]);
            if (value[0]) {
                colorCodesArray.push(value[0].ColorCode);
            }
            else {
                // console.log('Color COdes length:' + colorCodesArray.length);
                // colorCodesArray.push(this.getRandomColor());

            }

        }
        //if (distinctLabels)
        //{
        //   // console.log('Disctinct labels length:' + distinctLabels.length);

        //}
        return colorCodesArray;
    }
    getRandomColor(): string {

        return '#' + Math.floor(Math.random() * 16777215).toString(6);;
    }
    refreshRecordGrid() {
        this.updatingRecord = true;
        this._dashboardService.getRefreshData()
        .subscribe({
            next: (response) => {
                this.updatingRecord = false;
                let body = response;
                if (body[0].RecordDashboard.length > 0) {
                    this.showAssetBarChart = true;
                    this.compileRecordDashboardData(body[0]);
                    this.recordLastUpdated = body[1];
                    //this.resizeRecordMasterGridColumns();
                }
                else {
                    this.showAssetBarChart = false;
                }
            },
            error: (error) => {
                this.updatingRecord = false;
                console.log(error);
            }
        });

    }

    previousClick(year: any) {

        let currentYear = (new Date()).getFullYear().toString();
        //   let previousYear = (new Date()).getFullYear() - 1;
        if (year == 'Past 12 Months') {
            year = (new Date()).getFullYear();
        }

        if (year != 'Past 12 Months') {

            this.selectedYear = (new Date(Number(year), 1, 1).getFullYear() - 1).toString();

            this._dashboardService.getNextPreviousYearRecordData(this.selectedYear)
            .subscribe({
                next: (response) => {
                    this.compileRecordDashboardData(response);
                    if (this.selectedYear == currentYear) {
                        this.selectedYear = this.defaultYearString;
                    }
                }
            });

        }
        else {
            this.getRecordData();
        }
    }
    nextClick(year: any) {
        let currentYear = (new Date()).getFullYear().toString();

        if (year != 'Past 12 Months') {
            //  year = (new Date()).getFullYear();
            if (year != 'Past 12 Months') {

                this.selectedYear = (new Date(Number(year), 1, 1).getFullYear() + 1).toString();
                this._dashboardService.getNextPreviousYearRecordData(this.selectedYear)
                .subscribe({
                    next: (response) => {
                        this.compileRecordDashboardData(response);
                        if (this.selectedYear == currentYear) {
                            this.selectedYear = this.defaultYearString;
                        }
                    }
                });

            }
            else {
                this.getRecordData();
            }
        }
    }

    typeClicked(selectedValue: string, containerID: string) {
        this.isNavigationAllowed = (this.checkUserRoles() && this.checkUserPermissionForScreen(selectedValue));
        if (this.isNavigationAllowed) {
            let isNotFound = true;
            let selectedAirCraft = this.selectedRowData.Aircraft;
            // this._assetService.getAssetTreeNode(this.selectedRowData.Aircraft).subscribe( response => {
            //Select Assets from Shared Service and check current Tree Node
            let response;
            let isexpanded = false;
            let childAssets;
            let assetObject;
            let subchildAssets;
            let subassetObject;
            let parentAssetforExpand;

            //Loop for Parent Node
            this._sharedService._assetActualResult.forEach(function (assets, key) {
                if (isNotFound) {
                    childAssets = assets.children;
                    //Loop for Child Node
                    childAssets.forEach(function (childAsset, skey) {
                        childAsset.expanded = false;
                        if (isNotFound) {
                            parentAssetforExpand = childAsset;

                            // childAsset.styleClass=childAsset.styleClass +" pi pi-minus";
                            assetObject = JSON.parse(childAsset.data);
                            if (assetObject.ASN == selectedAirCraft) {
                                // childAsset.expanded=true;
                                response = childAsset;
                                isNotFound = false;
                                return;

                            }
                            //Loop for Sub Child Node
                            subchildAssets = childAsset.children;
                            subchildAssets.forEach(function (subchildAsset, skey) {
                                subassetObject = JSON.parse(subchildAsset.data);
                                if (subassetObject.ASN == selectedAirCraft) {
                                    parentAssetforExpand.expanded = true;
                                    response = subchildAsset;
                                    isNotFound = false;
                                    isexpanded = true
                                    return;

                                }
                            })

                        }
                        else {

                        }
                    })
                }
            });
            //Set Selected Asset
            var arrayResult = [];
            arrayResult.push(response)
            this._sharedService.selectedAssetTreeNode = arrayResult;
            this._sharedService.onAssetChanged(arrayResult);
            //this._sharedService.onAssetStatusChanged(arrayResult);

            this._sharedService.isNavigationFilterCanceled = true;
            //Get Container Details
            //let containerId = Number(containerID);
            let categoryTypes = containerTypes.filter(x => x.Type == selectedValue);
            let category = categoryTypes[0].Category;
            if (!category) {
                category = 'MXR';
            }
            switch (category) {
                case 'MXR':
                    this._sharedService.setRouteInfoAndNavigateFromDashboard({ MenuID: 16, PageURL: '/record/browse', cat: 'mxr', id: containerID, containerType: null, ColorCode: 'bg-color-msgreen' });
                    break;
                case 'LOG':
                    this._sharedService.setRouteInfoAndNavigateFromDashboard({ MenuID: 16, PageURL: '/record/browse', cat: 'logs', id: containerID, containerType: null, ColorCode: 'bg-color-msgreen' });
                    break;
                case 'GEN':
                    this._sharedService.setRouteInfoAndNavigateFromDashboard({ MenuID: 16, PageURL: '/record/browse', cat: 'general', id: containerID, containerType: null, ColorCode: 'bg-color-msgreen' });
                    break;
                case 'DOC':
                    this._sharedService.setRouteInfoAndNavigateFromDashboard({ MenuID: 16, PageURL: '/record/browse', cat: 'documents', id: containerID, containerType: null, ColorCode: 'bg-color-msgreen' });
                    break;
                case 'BND':
                    this._sharedService.setRouteInfoAndNavigateFromDashboard({ MenuID: 113, PageURL: '/transition/binders', cat: 'binders', id: containerID, containerType: null, ColorCode: 'bg-color-greenpea' });
                    break;
            }

            // },
            //     () => {


            //     });
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Restricted Access', detail: 'You are not allowed to view details.' });
        }
    }

    //loadTags() {
    //    let tag: any[] = [];
    //    tag.push({ TagID: 1, Name: "C", Color: "#e6194b" })
    //    tag.push({ TagID: 2, Name: "A", Color: "#3cb44b" })
    //    tag.push({ TagID: 3, Name: "OOP", Color: "#808080" })
    //    tag.push({ TagID: 4, Name: "TL", Color: "#0082c8" })
    //    tag.push({ TagID: 5, Name: "JL", Color: "#f58231" })
    //    tag.push({ TagID: 6, Name: "GL", Color: "#46f0f0" })
    //    tag.push({ TagID: 7, Name: "ADS", Color: "#00BFA5" })
    //    tag.push({ TagID: 8, Name: "AD", Color: "#008080" })
    //    tag.push({ TagID: 9, Name: "SB", Color: "#aa6e28" })
    //    tag.push({ TagID: 9, Name: "ANN", Color: "#e6194b" })
    //    tag.push({ TagID: 9, Name: "100", Color: "#3cb44b" })
    //    Tags = tag;

    //}
    getContainerTypes(): void {

        this._containerService.getAllContainerTypesData(null)
        .subscribe({
            next: (response) => {
                containerTypes = [];
                containerTypes = response;
            },
            error: (error) => {
                console.log(error);
            }
        });

    }

    // #endregion

    // #region Comms Region

    getCommDataForDashboard() {
        let userName: string = this._sharedService.getCurrentUserName();

        this._dashboardService.getCommData()
        .subscribe({
            next: (response) => {
                let data: any[] = response;
                this.initializeCommData();
                if (data && data.length > 0) {
                    this.commAssignedToMe = data.filter(x => x.AssignedTo == userName);
                    this.commRequestedByMe = data.filter(x => x.RequestedBy == userName || x.CreatedBy == userName);
                    this.assignedtomeCount = this.commAssignedToMe.length;
                    this.requestedbymeCount = this.commRequestedByMe.length;
                }
            }
        });

    }
    initializeCommData(): void {
        this.commAssignedToMe = [];
        this.commRequestedByMe = [];
        this.assignedtomeCount = 0;
        this.requestedbymeCount = 0;
    }
    getDateFormat(date: any) {
        let dateString = date.substr(0, 10);
        return moment(dateString).format("DD-MM-YYYY").toString();

    }
    getDateFormatTime(date: any) {
        if (date) {
            //return moment(date).format("DD-MM-YYYY h:mm a").toString() + ' (GMT)';
            // moment.utc(selectedDate).format(fullFormat)
            // return moment.utc(date).format("DD-MM-YYYY h:mm a").toString() + ' (GMT)';
            return moment(date).format(dateTimeConfig.dateformat.toUpperCase() + " h:mm a").toString();
        } else {
            return '';
        }
    }
    comMoreDetailsClick(con: any) {
        this.commId = con.CommId;
        this._sharedService.updateCommJob(con.CommId);
    }

    //#endregion

    prepareAgGridChartData(obj) {
        let chartData = [];
        let labelValue=null;
        obj.labels.forEach((element, labelsIndex) => {
            labelValue=(element.length > 10) ? element.substring(0,7)+"..." : element;
            var currentElement = {
                viewName: labelValue
            }
            obj.datasets.forEach((dataSetsElement, index) => {
                currentElement[dataSetsElement.label] = dataSetsElement.data[labelsIndex];
            });
            chartData.push(currentElement)
        });
        return chartData;

    }
    // #region Bar Graph
    prepareBarGraph(hasData: Boolean) {
      
        if (hasData) {
            this.barData = {
                labels: this.statuscolumnDefs.filter(c => c.headerName != "DFPStatus" && c.headerName != "ASN" && c.headerName != "Compliance Status").map(col => col.headerName.replace(/[_]/g, '-')), //['AD', 'MOD', 'OCCM', 'SB', 'HT'],
                datasets: this.getBarChartDataset()
            };
        }
        else {
            this.barData = {
                labels: [],
                datasets: []
            };
        }
        var percentageResult: any = this._dashboardService.filterAndAggregateAllViewWithDFPStatusDataInPercentage(this.statusgridData);
        var yAxisArray = [];
        percentageResult.forEach((element: any) => {
            yAxisArray.push(element.DFPStatus)
        })
        var barChart: any = document.getElementById('barChartId');
        if (barChart) {
          // document.getElementById('barChartId').parentElement.style.height = barChart.style.height;
          // document.getElementById('barChartId').parentElement.style.overflowY = barChart.style.overflowY;
          // document.getElementById('barChartId').style.height = null;        
        }
        var data = this.prepareAgGridChartData(this.barData)
        let colors = []
        yAxisArray.forEach((val, index) => {
            let dfpData = dfpStatuses.filter(x => x.Name == val)[0];
            if (dfpData) {
                colors.push(dfpData.CSSClass)
            }
            else {
                let randomColor = this.randomColor();
                colors.push(randomColor)
            }
        })

        this.barOptions = {
            autoSize: true,
            height: 390,
            data: data,
            series: [
                {
                    type: 'column',
                    xKey: "viewName",
                    yKey: yAxisArray,
                    grouped: true,
                    fills: colors,
                    strokes:colors,
                    tooltip: {
                        renderer: function (params) {
                            return this._sharedService.chartToolTip(params)
                        }.bind(this)
                    }
                },
            ],
            axes: [
                {
                    type: 'category',
                    position: 'bottom',
                    label: { rotation: 290 },
                },
                {
                    type: 'number',
                    position: 'left',
                    label: {
                        formatter: function (params) {
                            return params.value;
                        },
                    },
                    tick:{
                        interval: 10
                      }
                },
            ],
            legend: {
                position: "bottom",
            },
        };
    }
    randomColor() {
        return Math.floor(Math.random() * 16777215).toString(16);
    }
    prepareAssetBarGraph(hasData: Boolean) {

        if (hasData) {
            this.assetBarGraphData = {
                labels: this.assetBarChartColumnDefs.filter(c => c.headerName != "DFPStatus" && c.headerName != "ASN" && c.headerName != "Compliance Status").map(col => col.headerName.replace(/[_]/g, '-')), //['AD', 'MOD', 'OCCM', 'SB', 'HT'],
                datasets: this.getAssetBatChartDataset()
            };
        }
        else {
            this.assetBarGraphData = {
                labels: [],
                datasets: []
            };
        }
        var percentageResult: any = this._dashboardService.filterAndAggregateAllViewWithDFPStatusDataInPercentage(this.statusgridData);
        var yAxisArray = [];
        percentageResult.forEach((element: any) => {
            yAxisArray.push(element.DFPStatus)
        })
        var data = this.prepareAgGridChartData(this.assetBarGraphData)
        let colors = []
        yAxisArray.forEach((val, index) => {
            let dfpData = dfpStatuses.filter(x => x.Name == val)[0];
            if (dfpData) {
                colors.push(dfpData.CSSClass)
            }
            else {
                let randomColor = this.randomColor();
                colors.push(randomColor)
            }
        })
        this.assetBarGraphOptions = {
            autoSize: true,
            height: 390,
            data: data,
            series: [
                {
                    type: 'column',
                    xKey: "viewName",
                    yKey: yAxisArray,
                    fillif: colors,
                    grouped: true,
                    tooltip: {
                        renderer: function (params) {
                            return this._sharedService.chartToolTip(params)
                        }.bind(this)
                    }
                },
            ],
            axes: [
                {
                    type: 'category',
                    position: 'bottom',
                    label: { rotation: 290 },
                },
                {
                    type: 'number',
                    position: 'left',
                    label: {
                        formatter: function (params) {
                            return params.value;
                        },
                    },
                    tick:{
                        interval: 10
                      }
                },
            ],
            legend: {
                position: "bottom",
            },
        };
    }

    getAssetBatChartDataset(): any[] {
        const chartDataset: any[] = [];


        var percentageResult = this._dashboardService.filterAndAggregateAllViewWithDFPStatusDataInPercentage(this.assetData);
        //for (let i = 0; i < this.assetData.length; i++) {
        for (let i = 0; i < percentageResult.length; i++) {


            //let itemData: any[] = (Object as any).values(this.assetData[i]);
            let itemData: any[] = (Object as any).values(percentageResult[i]);
            itemData = itemData.map(val => val == null ? 0 : val);
            const color = this.getDFPStatusColor(itemData[0].trim());
            // Totals List
            const totalsList = [];

            for (let index = 1; index < itemData.length; index++) {
                totalsList.push(itemData[index]);
            }



            const chartDataItem = {
                label: itemData[0].trim(),
                backgroundColor: color,
                borderColor: color,
                data: totalsList,
                hoverBackgroundColor: color,
                hoverBorderColor: color,
                barPercentage: 0.5
            };
            chartDataset.push(chartDataItem);


        }

        return chartDataset;
    }


    getBarChartDataset(): any[] {
        let chartDataset: any[] = [];
        var percentageResult = this._dashboardService.filterAndAggregateAllViewWithDFPStatusDataInPercentage(this.statusgridData);
        // for (var i = 0; i < this.statusgridData.length; i++) {
        //     let itemData: any[] = (<any>Object).values(this.statusgridData[i]);

        for (var i = 0; i < percentageResult.length; i++) {
            let itemData: any[] = (<any>Object).values(percentageResult[i]);
            itemData = itemData.map(val => val == null ? 0 : val);
            // let color = this.getBarColor(itemData[0].trim());
            let color = this.getDFPStatusColor(itemData[0].trim()) ? this.getDFPStatusColor(itemData[0].trim()) : '#333';
            //Totals List
            let currentItemLable = this.statuscolumnDefs.filter(c => c.headerName != "DFPStatus" && c.headerName != "ASN" && c.headerName != "Compliance Status").map(col => col.field);
            let totalsList = [];
            for (let index = 1; index < itemData.length; index++) {
                totalsList.push(itemData[index]);
            }

            //let percentList = Number(itemData.splice(1, itemData.length - 1)) == 0 ? 0 : Number(itemData.splice(1, itemData.length - 1)) / Number(totalRecords);
            let chartDataItem = {
                label: itemData[0].trim(),
                backgroundColor: color,
                borderColor: color,
                data: totalsList,
                hoverBackgroundColor: color,
                hoverBorderColor: color,
                barPercentage: 0.5,
            }
            chartDataset.push(chartDataItem);
        }

        return chartDataset;
    }

    AddOrphanDFPStatusAsBlack(data: any[]) {
        data.forEach(element => {
            var _result = dfpStatuses.filter(r => r.Name == element.DFPStatus);
            if (_result == null || _result.length == 0) {
                var newOrphanDFPId = Math.max.apply(Math, dfpStatuses.map(function (o) { return o.DFPStatusID; })) + 1;
                var newOrphanDFP = { "DFPStatusID": newOrphanDFPId, "Name": element.DFPStatus, "Description": null, "CSSClass": "#000000", "Status": true };
                dfpStatuses.push(newOrphanDFP);
            }

        });
    }
    // #endregion

    //#region User Roles
    checkUserRoles(): boolean {
        let status = false;

        if (this.userAssets && this.userAssets.length > 0) {
            //US-8181
            //let result = this.userAssets.filter(x => x.value.split('|')[1] == this.selectedRowData.Aircraft);
            let result = this.userAssets.filter(x => JSON.parse(x.value).ASN == this.selectedRowData.Aircraft);
            if (result && result.length > 0) {
                status = true;
            }
        }
        return status;
    }
    checkSelectedUserAsset(): any {
        let _selUsrAsset = null;

        if (this.userAssets && this.userAssets.length > 0) {
            let result = this.userAssets.filter(x => JSON.parse(x.value).ASN == this.selectedRowData.Aircraft);
            if (result && result.length > 0) {
                _selUsrAsset = result[0];
            }
        }
        return _selUsrAsset;
    }

    checkUserPermissionForScreen(selectedType: string): boolean {
        let status = false;
        let _selUsrAsset = this.checkSelectedUserAsset();
        let _selUsrAssetObj = null;
        if (_selUsrAsset != null) {
            _selUsrAssetObj = JSON.parse(_selUsrAsset.value);
        }
        else {
            return status;
        }

        if (containerTypes && containerTypes.length > 0) {
            let userRole: string = localStorage.getItem("roles");
            let userRoleArray: string[] = userRole.split(",");

            let appUserRoles = new Role();
            for (let i = 0; i < userRoleArray.length; i++) {
                appUserRoles[userRoleArray[i]] = true;
            }

            let result = containerTypes.filter(x => x.Type == selectedType)[0].Category;
            if (result) {
                switch (result) {
                    case 'MXR':
                        status = appUserRoles.Records_MXR_Readonly && _selUsrAssetObj.PlanRoles.indexOf("Records_MXR_Readonly") > -1 && _selUsrAssetObj.MXRecords;
                        break;
                    case 'LOG':
                        status = appUserRoles.Records_LOG_Readonly && _selUsrAssetObj.PlanRoles.indexOf("Records_LOG_Readonly") > -1 && _selUsrAssetObj.LogBooks;
                        break;
                    case 'GEN':
                        status = appUserRoles.Records_GEN_Readonly && _selUsrAssetObj.PlanRoles.indexOf("Records_GEN_Readonly") > -1 && _selUsrAssetObj.General;
                        break;
                    case 'DOC':
                        status = appUserRoles.Records_DOC_Readonly && _selUsrAssetObj.PlanRoles.indexOf("Records_DOC_Readonly") > -1 && _selUsrAssetObj.Documents;
                        break;
                    case 'BND':
                        status = appUserRoles.Transition_BND_Readonly && _selUsrAssetObj.PlanRoles.indexOf("Transition_BND_Readonly") > -1 && _selUsrAssetObj.Binders;
                        break;
                }
            }
            return status;
        }


        return status;

    }
    getAsset() {
        this._assetService.getAssetByUserRole()
        .subscribe({
            next: (response) => {
                this.userAssets = response;
            },
            error: (error) => {
                this.userAssets = [];
                console.log(error);
            }
        });
  }
  
    //#endregion
    //#region Records Scroll
    setScrollPostition() {
        let elmnt = document.getElementsByClassName("ag-body-horizontal-scroll-viewport");
        elmnt[0].scrollLeft = 999;
    }
    //#endregion

  // #region Records Summary View
  
  recordSummaryBarOptions: any;
  recordSummaryBarData: boolean = false;
  getRecordSummaryView(selectedasn: string = null){
    this.setViewType()
    this._sharedService.showLoader("Processing...");
    this._dashboardService.getContainerStatusData(selectedasn, this.recordsViewType )
        .subscribe({
          next: (response) => {
            this.recordSummaryBarData = true;
            this.containerStatusLastUpdated = response[1];
            this.prepareContainerStatusGraphData(response[0], selectedasn);
          },
          error: (error) => {
            console.log(error);
          }
        })
        .add(() => this._sharedService.hideLoader())
  }
  setViewType() {
    this.isDashboardModule = SharedService.current_ParentMenu_Id == -1;
    let ModuleId = this.isDashboardModule ? this.currentDashboardPageSettings.selectedModuleId : SharedService.current_ParentMenu_Id;
    this.recordsViewType = '';
    this.statusViewType = '';
    if (ModuleId == 16) {
      this.recordsViewType = 'record';
    }
    if (ModuleId == 113) {
      this.recordsViewType = 'binder';
    }
    if (ModuleId == 22) {
      this.statusViewType = 'Status';
    }    
    if (ModuleId == 99) {
      this.statusViewType = 'Repair';
    }    
  }

  refreshContainerStatusData(selectedasn = null) {
    this.updatingContainerStatus = 'fa-spin';
    this.setViewType()

    this._dashboardService
      .refreshContainerStatusData(this.selectedAssetName, this.recordsViewType)
      .subscribe({
        next: (response) => {
          this.containerStatusLastUpdated = response[1];
          this.prepareContainerStatusGraphData(response, this.selectedAssetName);
        },
        error: (err) => {
          console.log(err);
        },
      })
      .add(() => {
        this.updatingContainerStatus = '';
      });
  }


  prepareContainerStatusGraphData(response, selectedasn = null) {
    let asns = [];
    if (response && response[0])
    {
      let firstObject = JSON.parse(JSON.stringify(response[0]));
      asns = Object.keys(firstObject).filter(a => a != 'status'.toUpperCase());
      response.forEach(item => {
        for (let key in item) {
          if (key.toLowerCase() != 'status') {
            item[key] = parseInt(item[key]);
          }
        }
      });
  
      
    }

    this.recordSummaryBarOptions = {
      autoSize: true,
      height: 390,
      data: response,
      series: [
        {
          type: 'column',
          xKey: 'STATUS',
          yKey: asns,
          yName: asns,
          stacked: !selectedasn,
          tooltip: {
            renderer: function (params) {
              return {
                title: params.yName,
                content: params.yValue.toFixed(0),
              };
            }.bind(this)
          },
          // formatter: function (params) {
          //   const param = params.datum[params.xKey].split('-');
          //   const color = param && param[1] ? params.datum[params.xKey].split('-')[1] : '';
          //   return {
          //     fill: isASNSelected ? null : color,
          //     stroke: isASNSelected ? null : color
          //   };
          // }
        },
      ],
      axes: [
        {
          type: 'category',
          position: 'bottom',
          label: {
            rotation: 290,
            formatter: function (params) {
              return `${params.value.split('-') ? params.value.split('-')[0] : ''}`;
            },
          },
        },
        {
          type: 'number',
          position: 'left',
          label: {
            formatter: function (params) {
              return `${params.value}`;
            },
          },
        },
      ],
      legend: {
        position: "bottom",
      },
    };
  }
    // #endregion
  
    //#region DFP Status
    getDFPStatus() {

        this._dashboardService.getAllDFPStatuses()
        .subscribe({
            next: (response) => {
                dfpStatuses = [];
                dfpStatuses = response;
            },
            error: (error) => {
                console.log(error);
            }
        });

    }

    getDFPStatusColor(stausName: string): string {
        let status = dfpStatuses.filter(x => x.Name == stausName);
        if (status && status.length > 0) {
            return status[0].CSSClass;
        }
        else {
            return '';
        }
    }
    //#endregion

    //#region Grid Context Menu Records
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];

        result.push({
            name: "Refresh",
            action: function () {

                params.context.masterGridCtx.refreshRecordGrid();
            },
            icon: params.context.masterGridCtx._sharedService.refreshIconHTMLasString,
        });

        return result;


    }
    //#endregion

    //#region Grid Context Menu Status
    getCustomContextMenuItemsStatus(params: any): any {
        let result: any[] = [];

        result.push({
            name: "Refresh",
            action: function () {

                params.context.statusGridCtx.refreshDashboardStatusListingGrid();
            },
            icon: params.context.statusGridCtx._sharedService.refreshIconHTMLasString,
        });

        return result;


    }
    getCustomContextMenuItemsStatusDetail(params: any): any {
        let result: any[] = [];

        result.push({
            name: "Refresh",
            action: function () {

                params.context.detailGridCtx.refreshDashboardStatusListingGrid();
            },
            icon: params.context.detailGridCtx._sharedService.refreshIconHTMLasString,
        });

        return result;


    }
    //#endregion
    populateQualityStatus() {
        this._qualityStatusService.getActiveQualityStatusData(null)
        .subscribe({
            next: (response) => {
                try {
                    this._sharedService.setQualityStatusList(response);
                }
                catch (ex) {
                    console.log(ex);
                }
            },
            error: (error) => {
                console.log(error);
            }
        });
    }

    selectedWidgetId: any;
    showGeneralSettingDialog(widgetId) {

        this.selectedWidgetId = widgetId;


        this.displayCustomGeneralSettingForm = true;
    }
    btnSaveGeneralSettingClick(customGeneralSetting: NgForm) {

    }
    btnCancelGeneralSettingClick(customGeneralSetting: NgForm) {
        this.displayCustomGeneralSettingForm = false;
        customGeneralSetting.resetForm();
    }

    SelectHeaderColor(event) {
        this.customGeneralSetting.color = event.currentTarget.dataset.widgetSetstyle;
    }

    showSettingButtons = false;
    showConfirmNevigateDialouge = false;
    showButtonName = "Show Options";
    showButtonIcons = "glyphicon glyphicon-plus";
    //#region for edit widget page
    showSettingButtonsFun() {
        this.showSettingButtons = true;
        // this.showSettingButtons=this.showSettingButtons==false?true:false;
        this.showButtonName = this.showButtonName == "Show Options" ? "Hide Options" : "Show Options";
        this.showButtonIcons = this.showButtonIcons == "glyphicon glyphicon-plus" ? "fa fa-minus" : "glyphicon glyphicon-plus";
        // this.showSettingButtons=true;
    }
    addWidgetsettingsAndPositionRow() {
        this.addRowInEditWidgetLayoutForm()
    }
    btnDeleteWidgetSettingAndPosition() {
        this._sharedService.showLoader("Processing...");
        var routeName = this._router.url.slice(1, this._router.url.length);
        if (routeName != "record") {
            routeName = this._router.url.slice(11, this._router.url.length);
        }

        let index = this.homeDashboardSettings.dashboardPageSettings.findIndex(x => x.routePath == routeName);

        this.homeDashboardSettings.dashboardPageSettings.splice(index, 1);

        let homeDashboardjsonSearlize = JSON.stringify(this.homeDashboardSettings);

        localStorage.setItem("HomeDashboardSettings", homeDashboardjsonSearlize);

        // this._sharedService.deSearlizeJsonToHomeWidgetObject(homeDashboardjsonSearlize)

        this._dashboardService.setUserHomeDashboardSettings(this._sharedService.baseUrl, homeDashboardjsonSearlize)
        .subscribe({
            next: (response) => {
                try {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Dashboard settings item has been deleted successfully.' });
        
                    this._sharedService.hideLoader();
                    this.reload('dashboard/record')
                }
                catch (ex) {
                    console.log(ex);
                }
            },
            error: (error) => {
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occure while deleting dashboard settings.' });
            }
        });
    }
    btnSaveWidgetSettingAndPosition(form) {
        this._sharedService.showLoader("Processing...");
        this.displayWidgetsettingsAndPositionForm = false;
        var routeName = this._router.url.slice(1, this._router.url.length);
        if (routeName != "record") {
            routeName = this._router.url.slice(11, this._router.url.length);
        }
        var homeSettings = this.homeDashboardSettings.dashboardPageSettings.find(x => x.routePath == routeName);
        let pluginSettingWidgetGridPath = this.settingWidgetNameStart + this._router.url + this.settingWidgetNameEnd;
        var plugin_settings: any = localStorage.getItem(pluginSettingWidgetGridPath);
        this.widgetPluginSettings = JSON.parse(plugin_settings);
        homeSettings.widgetPluginSettings = this.widgetPluginSettings;
        homeSettings.widgetPluginPositionsGrid = this.widgetSettingAndPositionModel;
        homeSettings.pageName = this.editFormPageName;
        homeSettings.DisplayOrder = this.pageMenuOrder;
        homeSettings.selectedModuleId = this.selectedModuleId;
        let pluginPosition = this.pluginWidgetNameStart + this._router.url + this.pluginWidgetNameEnd;
        var localStoragePluginPosition: Plugin_position = JSON.parse(localStorage.getItem(pluginPosition));
        let startIndex = 0
        if (localStoragePluginPosition == null) {
            startIndex = 0;
            localStoragePluginPosition = new Plugin_position();
        }
        else {
            startIndex = localStoragePluginPosition.grid.length;
        }

        let widgetSettingAndPositionModelGridLength = this.widgetSettingAndPositionModel.grid.length * 4 + 3
        let pluginPositionNew = this.pluginWidgetNameStart + this._router.url + this.pluginWidgetNameEnd;
        if (startIndex != widgetSettingAndPositionModelGridLength && startIndex < widgetSettingAndPositionModelGridLength) {
            let count = widgetSettingAndPositionModelGridLength - startIndex;
            let newCount = count / 4
            for (let index = 0; index < newCount; index++) {
                for (let i = 0; i < 4; i++) {
                    let section: Plugin_positionSection = new Plugin_positionSection();
                    let cond = new Plugin_positionGrid();
                    let sectionForDB = new Plugin_positionSection();
                    cond.section = new Array<Plugin_positionSection>()
                    cond.section.push(sectionForDB)
                    localStoragePluginPosition.grid.push(cond);
                }
            }
            var posintionStringLocalStorage = JSON.stringify(localStoragePluginPosition);
            localStorage.setItem(pluginPositionNew, posintionStringLocalStorage)
            homeSettings.widgetPluginPositionsLocalStorage = localStoragePluginPosition;
        }
        else if (this.removeWidget) {
            var posintionStringLocalStorage = JSON.stringify(this.currentRemovedWidgetDashboard);
            localStorage.setItem(pluginPositionNew, posintionStringLocalStorage)
            homeSettings.widgetPluginPositionsLocalStorage = this.currentRemovedWidgetDashboard
        }


        let homeDashboardjsonSearlize = JSON.stringify(this.homeDashboardSettings);

        localStorage.setItem("HomeDashboardSettings", homeDashboardjsonSearlize);

        // this._sharedService.deSearlizeJsonToHomeWidgetObject(homeDashboardjsonSearlize)
      this.hasUnsavedChanges = true;
        this._dashboardService.setUserHomeDashboardSettings(this._sharedService.baseUrl, homeDashboardjsonSearlize)
        .subscribe({
            next: (response) => {
                try {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Dashboard settings has been changed successfully.' });        
                    this._sharedService.hideLoader();
                    this.reload(this._router.url)
                }
                catch (ex) {
                    console.log(ex);
                }
            },
            error: (error) => {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occure while saving dashboard settings.' });
                this._sharedService.hideLoader();
            }
        });
    }
    addRowInEditWidgetLayoutForm() {
        let cond = new WidgetPluginPositionsGrid();
        cond.rowId = (this.widgetSettingAndPositionModel.grid.length + 1).toString();
        let sectionForDB = new WidgetPluginPositionsGridSection();
        let sectionForDB1st = new WidgetPluginPositionsGridSection();
        let sectionForDB2nd = new WidgetPluginPositionsGridSection();
        let sectionForDB3rd = new WidgetPluginPositionsGridSection();
        cond.section = new Array<WidgetPluginPositionsGridSection>()
        cond.section.push(sectionForDB)
        cond.section.push(sectionForDB1st)
        cond.section.push(sectionForDB2nd)
        cond.section.push(sectionForDB3rd)
        this.widgetSettingAndPositionModel.grid.push(cond);
    }

    ShowWidgetSettingAndPositionForm() {
        this.resetWidgetSettingAndPositionModel();
        this.displayWidgetsettingsAndPositionForm = true;
        this.setModulesList();
    }

    btnCencalWidgetSettingAndPosition(form) {
        this.resetWidgetSettingAndPositionModel();
        this.displayWidgetsettingsAndPositionForm = false;
        this.removeWidget = false;
    }

    resetWidgetSettingAndPositionModel() {

        let routeName = this._router.url.slice(1, this._router.url.length);
        if (routeName != "record") {
            routeName = this._router.url.slice(11, this._router.url.length);
        }
        let dashboardPageSettings: DashboardPageSettings = new DashboardPageSettings();
        let homeDashboardSetting = localStorage.getItem("HomeDashboardSettings");
        let homeDashboardSettingsObj = JSON.parse(homeDashboardSetting);
        dashboardPageSettings = homeDashboardSettingsObj.dashboardPageSettings.find(x => x.routePath == routeName);
        this.oldPageName = dashboardPageSettings.pageName;
        this.editFormPageName = this.oldPageName
        this.pageMenuOrder = dashboardPageSettings.DisplayOrder;
        this.widgetSettingAndPositionModel = dashboardPageSettings.
        widgetPluginPositionsGrid;
        this.selectedModuleId = dashboardPageSettings.selectedModuleId;
        this.removeWidget = false;
    }
    DisplayOrderChange() {
        if (this.pageMenuOrder < 1)
            this.pageMenuOrder = 1;
    }

    DisplayOrderChangeForAddPage() {
        if (this.addPageMenuOrder < 1)
            this.addPageMenuOrder = 1
    }
    removeWidget = false;
    currentRemovedWidgetDashboard;
    RemoveWidget(index) {
        if (!this.removeWidget) {
            let pluginPosition = this.pluginWidgetNameStart + this._router.url + this.pluginWidgetNameEnd;
            var localStoragePluginPosition: Plugin_position = JSON.parse(localStorage.getItem(pluginPosition));
            this.currentRemovedWidgetDashboard = localStoragePluginPosition;
        }
        this.removeWidget = true;
        // let pluginPosition = this.pluginWidgetNameStart + this._router.url + this.pluginWidgetNameEnd;
        // var localStoragePluginPosition: Plugin_position = JSON.parse(localStorage.getItem(pluginPosition));
        // this.currentRemovedWidgetDashboard = localStoragePluginPosition;
        // this.currentRemovedWidgetDashboard=this.currentDashboardPageSettings.widgetPluginPositionsLocalStorage
        let removeIndex = index * 4 + 3;
        // for (let x = 0; x < 4; x++) {
        //     const currentGrid = this.currentRemovedWidgetDashboard.grid[removeIndex + x];
        //     if (currentGrid != undefined && currentGrid.section.length > 0) {
        //         this.currentRemovedWidgetDashboard.grid[removeIndex + x].pop();//.section = new Array<Plugin_positionSection>();
        //         // let sectionForDB = new WidgetPluginPositionsGridSection();
        //         // this.currentRemovedWidgetDashboard.grid[removeIndex + x].section.push(sectionForDB)
        //     }
        // }
        this.currentRemovedWidgetDashboard.grid.splice(removeIndex, 4)
        this.widgetSettingAndPositionModel.grid.splice(index, 1);
        this.countSectionNumberOfColumns(this.widgetSettingAndPositionModel.grid);
    }

    //#endregion for edit widget page


    //#region for adding new widget page

  btnAddSaveWidgetSettingAndPosition(form) {
        let selectedModule = this.homeDashboardSettings
          .dashboardPageSettings
          .find(x => this.selectedModuleId > 0 && x.selectedModuleId == this.selectedModuleId);

        if (selectedModule) {
          this._sharedService.clearToast();
          this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Module Graph already exists!' });
          return;
        }

        let isDashboardExists = this.AddDashboardPage(this.addFormPageName);
        if (isDashboardExists) {
            this._sharedService.showLoader("Processing...");
            this.displayWidgetsettingsAndPositionAddForm = false;
            var routeName = this.addFormPageName;
            var homeSettings = this.homeDashboardSettings.dashboardPageSettings.find(x => x.pageName == routeName);
            homeSettings.widgetPluginSettings = new WidgetPluginSettings();
            var position: Plugin_position = new Plugin_position()
            position.grid = new Array<Plugin_positionGrid>();
            let section: Plugin_positionSection = new Plugin_positionSection();
            let cond = new Plugin_positionGrid();
            let sectionForDB = new Plugin_positionSection();
            cond.section = new Array<Plugin_positionSection>()
            cond.section.push(sectionForDB)
            position.grid.push(cond);
            position.grid.push(cond);
            position.grid.push(cond);
            for (let index = 0; index < this.widgetSettingAndPositionAddModel.grid.length; index++) {
                for (let i = 0; i < this.widgetSettingAndPositionAddModel.grid[index].section.length; i++) {
                    let section: Plugin_positionSection = new Plugin_positionSection();
                    let cond = new Plugin_positionGrid();
                    let sectionForDB = new Plugin_positionSection();
                    cond.section = new Array<Plugin_positionSection>()
                    cond.section.push(sectionForDB)
                    position.grid.push(cond);
                }
            }
            homeSettings.widgetPluginPositionsGrid = this.widgetSettingAndPositionAddModel;
            homeSettings.widgetPluginPositionsLocalStorage = position;
            homeSettings.widgetPluginGeneralSettings = new WidgetPluginGeneralSettings();
            homeSettings.DisplayOrder = this.addPageMenuOrder;
            homeSettings.selectedModuleId = this.selectedModuleId;
            var homeDashboardjsonSearlize = JSON.stringify(this.homeDashboardSettings);

            localStorage.setItem("HomeDashboardSettings", homeDashboardjsonSearlize);
            this._sharedService.onParentMenuChanged(SharedService.current_ParentMenu_Id);

            // this._sharedService.deSearlizeJsonToHomeWidgetObject(homeDashboardjsonSearlize)
            this.addPageMenuOrder = 1;
            this._dashboardService.setUserHomeDashboardSettings(this._sharedService.baseUrl, homeDashboardjsonSearlize)
            .subscribe({
                next: (response) => {
                    try {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Dashboard settings has been changed succesfully.' });
                        this._sharedService.hideLoader();
                        this.showSettingButtons = false;
                        this.showConfirmNevigateDialouge = false;
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                },
                error: (error) => {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occure while saving dashboard settings.' });
                }
            });
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Dashboard with same name already exists' });
        }
    }

    countSectionNumberOfColumns(grids) {
        this.isDisableUpdateButton = false;
        let count = 0
        for (let index = 0; index < grids.length; index++) {
            let sections = grids[index].section;
            count = 0
            for (let x = 0; x < sections.length; x++) {
                count = count + (+sections[x].noOfColumns);
            }
            if (count > 12) {
                this.isDisableUpdateButton = true;
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Disabled', detail: 'Row count can not be greater than 12.' });

                break;
                return
            }

        }
    }
    AddDashboardPage(pageName: any) {
        let dashboardPageSettings = new DashboardPageSettings();
        dashboardPageSettings.pageName = pageName;
        dashboardPageSettings.routePath = pageName.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
        // dashboardPageSettings.routePath = pageName.replace(/ /g, "").toLowerCase();
      var homeSettings = this.homeDashboardSettings.dashboardPageSettings.find(x => x.routePath == dashboardPageSettings.routePath);
      
        if (homeSettings == undefined) {
            this.homeDashboardSettings.dashboardPageSettings.push(dashboardPageSettings);
            return true
        }
        else {
            return false;
        }

    }

    ShowWidgetSettingAndPositionAddForm() {
        this.resetAddWidgetSettingAndPositionModel();
        this.addRowOnInAddForm();
        this.addFormPageName = null;
        this.displayWidgetsettingsAndPositionAddForm = true;
        this.setModulesList();
    }
    addWidgetsettingsAndPositionRowForAddForm() {
        this.addRowOnInAddForm();
    }

    addRowOnInAddForm() {
        let cond = new WidgetPluginPositionsGrid();
        cond.rowId = (this.widgetSettingAndPositionAddModel.grid.length + 1).toString();
        let sectionForDB = new WidgetPluginPositionsGridSection();
        let sectionForDB1st = new WidgetPluginPositionsGridSection();
        let sectionForDB2nd = new WidgetPluginPositionsGridSection();
        let sectionForDB3rd = new WidgetPluginPositionsGridSection();
        cond.section = new Array<WidgetPluginPositionsGridSection>()
        cond.section.push(sectionForDB)
        cond.section.push(sectionForDB1st)
        cond.section.push(sectionForDB2nd)
        cond.section.push(sectionForDB3rd)
        this.widgetSettingAndPositionAddModel.grid.push(cond);
    }

    RemoveAddWidget(index) {
        this.widgetSettingAndPositionAddModel.grid.splice(index, 1);
        this.countSectionNumberOfColumns(this.widgetSettingAndPositionAddModel.grid);
    }

    btnAddCencalWidgetSettingAndPosition(form) {
        this.resetAddWidgetSettingAndPositionModel();
        this.displayWidgetsettingsAndPositionAddForm = false
    }

    resetAddWidgetSettingAndPositionModel() {
        this.addPageMenuOrder = 1;
        this.widgetSettingAndPositionAddModel = new WidgetPluginPositions();
    }
    //#endregion

    public SaveDashboardSetting() {
        this._sharedService.showLoader("Processing...");
        this.displayWidgetsettingsAndPositionForm = false;
        // this.ngOnInit();
        var routeName = this._router.url.slice(1, this._router.url.length);
        if (routeName != "record") {
            routeName = this._router.url.slice(11, this._router.url.length);
        }
        var homeSettings = this.homeDashboardSettings.dashboardPageSettings.find(x => x.routePath == routeName);

        let pluginSettingWidgetGridPath = this.settingWidgetNameStart + this._router.url + this.settingWidgetNameEnd;
        var plugin_settings: any = localStorage.getItem(pluginSettingWidgetGridPath);
        this.widgetPluginSettings = JSON.parse(plugin_settings);

        homeSettings.widgetPluginSettings = this.widgetPluginSettings;
        let pluginPosition = this.pluginWidgetNameStart + this._router.url + this.pluginWidgetNameEnd;
        var localStoragePluginPosition: Plugin_position = JSON.parse(localStorage.getItem(pluginPosition));
        homeSettings.widgetPluginPositionsLocalStorage = localStoragePluginPosition;
        let homeDashboardjsonSearlize = JSON.stringify(this.homeDashboardSettings);

      localStorage.setItem("HomeDashboardSettings", homeDashboardjsonSearlize);
      this._sharedService.isUnsavedChanges = false;
        this._dashboardService.setUserHomeDashboardSettings(this._sharedService.baseUrl, homeDashboardjsonSearlize)
        .subscribe({
            next: (response) => {
                try {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Dashboard settings has been changed successfully.' });
        
                    this._sharedService.hideLoader();
                    this.reload(this._router.url)
                }
                catch (ex) {
                    console.log(ex);
                }
            },
            error: (error) => {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occure while saving dashboard settings.' });
            }
        });
    }

    reinitilizeAfterSave() {
        for (let index = 0; index < this.widgetPluginPositions.grid.length; index++) {
            const element = this.widgetPluginPositions.grid[index];
            var gridIndex = 0;
            while (element[gridIndex] != undefined) {
                var idFromIndexes = index + "_" + gridIndex;
                let paragraph = document.getElementById(idFromIndexes);
                //id of current Widget
                var sectionId = element[gridIndex].section[0].id
                //check if id exists in widget setting array;
                var widgetPluginSettingsObj = this.widgetPluginSettings.widget.find((x) => x.id == sectionId);
                // if( widgetPluginSettingsObj!=undefined && widgetPluginSettingsObj.hidden==){
                if (widgetPluginSettingsObj != undefined) {
                    let widgetPluginSettingsObjElement = document.getElementById(widgetPluginSettingsObj.id);
                    widgetPluginSettingsObjElement.style.display = "none"
                    paragraph.appendChild(widgetPluginSettingsObjElement)
                    widgetPluginSettingsObjElement.style.display = "block"
                }
                gridIndex++;
            }

        }
    }
    getDashboardName(name) {
        this.dashBoardName = name.length < 30 ? name : name.substring(0, 30) + "...";
    }
    currentDashboardPageSettings: DashboardPageSettings;
    widgetsInitialization() {
      
        this.widgetSettingAndPositionModel = new WidgetPluginPositions();
        this.widgetSettingAndPositionModel.grid = new Array<WidgetPluginPositionsGrid>();
        this.widgetSettingAndPositionAddModel = new WidgetPluginPositions();
        this.widgetSettingAndPositionAddModel.grid = new Array<WidgetPluginPositionsGrid>();
        this.homeDashboardSettings = new HomeDashboardSettings();
        let routerUrl = this._router.url;
        let pluginLocalStorageName = this.pluginWidgetNameStart + routerUrl + this.pluginWidgetNameEnd;
        var plugin_settings: any = localStorage.getItem(pluginLocalStorageName);
        this.widgetPluginSettings = JSON.parse(plugin_settings);
        var homeDashboardSetting = localStorage.getItem("HomeDashboardSettings");
        if (homeDashboardSetting&&  homeDashboardSetting != "" && homeDashboardSetting != 'null') {
            this.homeDashboardSettings = JSON.parse(homeDashboardSetting);
            var routeName = this._router.url.slice(1, this._router.url.length);
            if (routeName != "record") {
                routeName = this._router.url.slice(11, this._router.url.length);
            }
            this.currentDashboardPageSettings = this.homeDashboardSettings.dashboardPageSettings.find(x => x.routePath == routeName);
            this.getDashboardName(this.currentDashboardPageSettings.pageName)

            let pluginPosition = this.pluginWidgetNameStart + this._router.url + this.pluginWidgetNameEnd;
            let JsonStringOfPosition = JSON.stringify(this.currentDashboardPageSettings.widgetPluginPositionsLocalStorage);
            let JsonStringOfPluginSettings = JSON.stringify(this.currentDashboardPageSettings.widgetPluginSettings);
            let pluginSettingWidgetGrid = this.settingWidgetNameStart + routerUrl + this.settingWidgetNameEnd;
            localStorage.setItem(pluginPosition, JsonStringOfPosition)
            localStorage.setItem(pluginSettingWidgetGrid, JsonStringOfPluginSettings)
            this.widgetPluginPositions = this.homeDashboardSettings.dashboardPageSettings.find(x => x.routePath == routeName).widgetPluginPositionsGrid;
      }

        this.populatewidgetDropdownListValues()
        this.populateRowListForWidgetSetting();
        this.populateDashboardScreenNameList();
        this.isDisableRecordsSummeryGraph()
    }
    isDisableRecordsSummeryGraph() {
        let url = this._router.url.slice(1, this._router.url.length).replace('/', '-');
        let id = '--records-monthly-summary-view';
        id = url + id;
        let widgetObj = this.currentDashboardPageSettings.widgetPluginGeneralSettings.widget;

        if (widgetObj && widgetObj.find((x) => x.id == id)) {
            this.isRecordsSummeryChecked = this.currentDashboardPageSettings.widgetPluginGeneralSettings.widget.find((x) => x.id == id).showGraph;
        }
    }
    widgetHeight() {
        for (let index = 0; index < this.currentDashboardPageSettings.widgetPluginPositionsGrid.grid.length; index++) {
            var x = index * 4;
            let y = 0;
            while (y < 4) {
                let searchIndex = x + y + 3;
                var currentGrid = this.currentDashboardPageSettings.widgetPluginPositionsLocalStorage.grid[searchIndex]
                if (currentGrid.section.length > 1) {
                }
                if (currentGrid != undefined && currentGrid.section.length > 0) {
                    for (let x = 0; x < currentGrid.section.length; x++) {
                        const element = currentGrid.section[x].id;
                        if (currentGrid.section.length > 1) {
                            let id = "builtin" + index + "_" + y
                            let widget = document.getElementById(id);
                            if(widget !=null)
                            widget.style.display = 'none'
                        }
                        if (currentGrid.section.length == 1) {
                            let id = "builtin" + index + "_" + y
                            let widget = document.getElementById(id);
                            widget.style.display = 'block'
                        }
                        if (element != "" && element != undefined) {
                            let widget = document.getElementById(element);
                            // if (currentGrid.section.length > 1) {
                            //     if (widget != null && element.indexOf('builtin') == 0) {
                            //         widget.style.display = 'none'
                            //     }
                            // }
                            if (widget != null && element.indexOf('builtin') == -1) {
                              var widgetBody: any = widget.getElementsByClassName("widget-body")[0];
                              if (widgetBody) {
                                widgetBody.style.height = this.currentDashboardPageSettings.widgetPluginPositionsGrid.grid[index].widgetHeight + 'px';
                                widgetBody.style.overflowY = 'auto'
                            }
                            }
                        }
                    }
                }
                y++;
            }
        }
    }
    showHideWidget() {
        for (let index = 0; index < this.currentDashboardPageSettings.widgetPluginPositionsGrid.grid.length; index++) {
            var x = index * 4;
            let y = 0;
            while (y < 4) {
                let searchIndex = x + y + 3;
                var currentGrid = this.currentDashboardPageSettings.widgetPluginPositionsLocalStorage.grid[searchIndex]
                if (currentGrid.section.length > 1) {
                }
                if (currentGrid != undefined && currentGrid.section.length > 0) {
                    for (let x = 0; x < currentGrid.section.length; x++) {
                        const element = currentGrid.section[x].id;


                        if (element != undefined && currentGrid.section.length > 1) {
                            let id = "builtin" + index + "_" + y
                            let widget = document.getElementById(id);
                            widget.style.display = 'none'
                        }
                        if (currentGrid.section.length == 1) {
                            let id = "builtin" + index + "_" + y
                            let widget = document.getElementById(id);
                            widget.style.display = 'block'
                        }
                    }
                }
                y++;
            }
        }
    }

    changeGraphStatus(id) {
        let url = this._router.url.slice(1, this._router.url.length).replace('/', '-');
        id = url + id;
        let widgetObj = this.currentDashboardPageSettings.widgetPluginGeneralSettings.widget;
        if (widgetObj && widgetObj.find((x) => x.id == id)) {
            this.currentDashboardPageSettings.widgetPluginGeneralSettings.widget.find((x) => x.id == id).showGraph = this.isRecordsSummeryChecked;
        }
        else {
            var pluginGeneralSettingsWidget = new PluginGeneralSettingsWidget();
            pluginGeneralSettingsWidget.id = id;
            pluginGeneralSettingsWidget.showGraph = this.isRecordsSummeryChecked
            this.currentDashboardPageSettings.widgetPluginGeneralSettings.widget.push(pluginGeneralSettingsWidget)
        }
        this.showSettingButtons = true;
        this.showConfirmNevigateDialouge = true;
        setTimeout(() => {
            let btn = document.getElementById('dashboardSavebtn');
            btn.style.fontWeight = 'bold';
        }, 500);
    }

    drawDonutGraph(data: any) {
        const columnNames: string[] = [];
        let indexedArray: { [key: string]: number } = {};
        let keyValuePairArray: any;
        keyValuePairArray = [];
        const distinctLabels: string[] = [];
        const allLabels: string[] = [];
        const graphData: number[] = [];
        const colorCodes: string[] = [];
      let chartId = document.getElementById('DFPStatusDoughnutChartId');
      if (chartId) {
        // document.getElementById('DFPStatusDoughnutChartId').parentElement.style.height = 'chartId.style.height';
        // document.getElementById('DFPStatusDoughnutChartId').parentElement.style.overflowY = 'auto';
        // document.getElementById('DFPStatusDoughnutChartId').style.height = null;        
      }
        keyValuePairArray = this._dashboardService.filterAndAggregateAllDFPStatusData(
            data
        );
        keyValuePairArray.sort((a, b) => a.value - b.value);
        keyValuePairArray.forEach(obj => {
            allLabels.push(obj.key);
            distinctLabels.push(obj.key);
            graphData.push(obj.value);
            let _color = this.getDFPStatusColor(obj.key);
            colorCodes.push(_color);
        });
        this.doughnutData = {
            labels: distinctLabels,
            datasets: [
                {
                    data: graphData,
                    backgroundColor: colorCodes,
                    hoverBackgroundColor: colorCodes
                }
            ]
        };
        let colors = []
        distinctLabels.forEach((val, index) => {
            let dfpData = dfpStatuses.filter(x => x.Name == val)[0];
            if (dfpData) {
                colors.push(dfpData.CSSClass)
            }
            else {
                let randomColor = this.randomColor();
                colors.push(randomColor)
            }
        })
        this.doughnutOptions = {
            data: keyValuePairArray,
            legend: {
                position: "bottom",
            },
            series: [
                {
                    calloutLabel: { enabled: false },
                    type: 'pie',
                    calloutLabelKey: 'key',
                    angleKey: 'value',
                    innerRadiusOffset: -70,
                    fills: colors,
                    strokes:colors,
                },
            ],

        };
    }

    populatewidgetDropdownListValues() {
        this.widgetDropdownListValues = [];
      this.widgetDropdownListValues = [
        { label: "Records Monthly Summary View", value: "--records-monthly-summary-view" },
        { label: "Compliance Status Table", value: "--compliance-status-table" },
        { label: "Compliance Status Graph (Bar)", value: "--compliance-status-graph-bar-" },
        { label: "Compliance Status Graph (Donut)", value: "--compliance-status-graph-donut-" },
        { label: "Asset Counter", value: "--asset-counter" },
        { label: "Inbox - My Communications", value: "--inbox-my-communications" },
        { label: "Inbox - Graph (Bar)", value: "--inbox-graph-bar-" },
        // { label: "Transition Graph (Bar)", value: "--transition-graph-bar-" },
        { label: "Asset's Document Statistics", value: "--asset-s-document-statistics" },
        { label: "Container Status Graph (Bar)", value: "--container-status-graph-bar-" }
      ];
    }
    public dashboardScreenNameList: any = []
    populateDashboardScreenNameList() {
        this.dashboardScreenNameList = [];
        this.dashboardScreenNameList = [
            { label: "Record", value: "Record" },
            { label: "Status", value: "Status" },
            { label: "Inbox", value: "Inbox" }
        ]
    }
    public columnListForWidgetSetting: any = []
    populateRowListForWidgetSetting() {
        this.columnListForWidgetSetting = [];
        this.columnListForWidgetSetting = [
            { label: "0", value: "0" },
            { label: "1", value: "1" },
            { label: "2", value: "2" },
            { label: "3", value: "3" },
            { label: "4", value: "4" },
            { label: "5", value: "5" },
            { label: "6", value: "6" },
            { label: "7", value: "7" },
            { label: "8", value: "8" },
            { label: "9", value: "9" },
            { label: "10", value: "10" },
            { label: "11", value: "11" },
            { label: "12", value: "12" }
        ]
    }

    addViewInSelectedWidget(index, i, j) {
        this.displayWidgetToSpecificArticle = true;
        let x = i;
        let y = j;
        x = x * 4;
        index = x + y;
        this.selectedWidgetIndex = index + 3;
        this.selectedArticleId = i + "_" + j;
    }
    deleteWidget(id) {
        let currentPath = this._router.url;
        let pluginPosition = this.pluginWidgetNameStart + currentPath + this.pluginWidgetNameEnd;
        let url = this._router.url.slice(1, this._router.url.length).replace('/', '-');
        id = url + id;
        var value = localStorage.getItem(pluginPosition)
        let currentDashBoardWidgetPositions: Plugin_position = JSON.parse(value);
        if (currentDashBoardWidgetPositions.grid.find((x) => x.section.find(y => y.id == id))) {
            var result = currentDashBoardWidgetPositions.grid.find((x) => x.section.find(y => y.id == id)).section.findIndex(y => y.id == id);
            currentDashBoardWidgetPositions.grid.find((x) => x.section.find(y => y.id == id)).section.splice(result, 1);
        }
        let selectedWidgetIndex: any = document.getElementById(id);
        selectedWidgetIndex.style.display = 'none'
        var positions = JSON.stringify(currentDashBoardWidgetPositions)
        localStorage.setItem(pluginPosition, positions);
        this.currentDashboardPageSettings.widgetPluginPositionsLocalStorage = currentDashBoardWidgetPositions;
        this.displayWidgetToSpecificArticle = false;
        this.selectedWidgetIndex = null;
        id = null;
        this.widgetHeight();
        // for (let index = 0; index < this.currentDashboardPageSettings.widgetPluginPositionsGrid.grid.length; index++) {
        //     var x = index * 4;
        //     let y = 0;
        //     while (y < 4) {

        //         let searchIndex = x + y + 3;
        //         var currentGrid = this.currentDashboardPageSettings.widgetPluginPositionsLocalStorage.grid[searchIndex]
        //         if (currentGrid.section.length > 1) {

        //         }
        //         if (currentGrid != undefined && currentGrid.section.length > 0) {
        //             for (let x = 0; x < currentGrid.section.length; x++) {

        //                 const element = currentGrid.section[x].id;

        //                 if (currentGrid.section.length > 1) {
        //                     let id = "builtin" + index + "_" + y
        //                     let widget = document.getElementById(id);
        //                     widget.style.display = 'none'
        //                 }
        //                 if (currentGrid.section.length == 1) {
        //                     let id = "builtin" + index + "_" + y
        //                     let widget = document.getElementById(id);
        //                     widget.style.display = 'block'
        //                     // if (widget != null && element.indexOf('builtin') == 0) {
        //                     //     widget.style.display = 'block'
        //                     // }
        //                 }
        //                 if (element != "" && element != undefined) {
        //                     let widget = document.getElementById(element);

        //                     if (widget != null && element.indexOf('builtin') == -1) {
        //                         var widgetBody: any = widget.getElementsByClassName("widget-body")[0];
        //                         widgetBody.style.height = this.currentDashboardPageSettings.widgetPluginPositionsGrid.grid[index].widgetHeight + 'px'
        //                         widgetBody.style.overflowY = 'auto'
        //                     }
        //                 }

        //             }
        //         }
        //         y++;
        //     }
        //     this.showSettingButtons = true;
        //     this.showConfirmNevigateDialouge = true;
        //     setTimeout(() => {
        //         let btn = document.getElementById('dashboardSavebtn');
        //         btn.style.fontWeight = 'bold';
        //     }, 500);
        // }
    }

  btnAddwidgetToSpecificArticle(form) {
        this._sharedService.isUnsavedChanges = true;
        this.currentDashboardPageSettings
        let currentPath = this._router.url;
        let pluginPosition = this.pluginWidgetNameStart + currentPath + this.pluginWidgetNameEnd;
        let url = this._router.url.slice(1, this._router.url.length).replace('/', '-');
        this.selectedWidgetToSpecificArticle = url + this.selectedWidgetToSpecificArticle;
        var value = localStorage.getItem(pluginPosition)
        let currentDashBoardWidgetPositions: Plugin_position = JSON.parse(value);
        if (currentDashBoardWidgetPositions != null && currentDashBoardWidgetPositions.grid.length > 0 && currentDashBoardWidgetPositions.grid.find((x) => x.section.find(y => y.id == this.selectedWidgetToSpecificArticle))) {
            var result = currentDashBoardWidgetPositions.grid.find((x) => x.section.find(y => y.id == this.selectedWidgetToSpecificArticle)).section.findIndex(y => y.id == this.selectedWidgetToSpecificArticle);
            currentDashBoardWidgetPositions.grid.find((x) => x.section.find(y => y.id == this.selectedWidgetToSpecificArticle)).section.splice(result, 1);
        }
        var section: Plugin_positionSection = new Plugin_positionSection();
        section.id = this.selectedWidgetToSpecificArticle;
        currentDashBoardWidgetPositions.grid[this.selectedWidgetIndex].section.push(section);
        let selectedWidgetIndex: any = document.getElementById(this.selectedWidgetToSpecificArticle);
        let article: any = document.getElementById(this.selectedArticleId);
        article.appendChild(selectedWidgetIndex);
        selectedWidgetIndex.style.display = 'block'
        var positions = JSON.stringify(currentDashBoardWidgetPositions)
        localStorage.setItem(pluginPosition, positions);
        this.currentDashboardPageSettings.widgetPluginPositionsLocalStorage = currentDashBoardWidgetPositions;
        this.displayWidgetToSpecificArticle = false;
        this.selectedWidgetIndex = null;
    this.selectedWidgetToSpecificArticle = null;
    
        for (let index = 0; index < this.currentDashboardPageSettings.widgetPluginPositionsGrid.grid.length; index++) {
            var x = index * 4; 
            let y = 0;
            while (y < 4) {
                let searchIndex = x + y + 3;
                var currentGrid = this.currentDashboardPageSettings.widgetPluginPositionsLocalStorage.grid[searchIndex]
                if (currentGrid.section.length > 1) {
                }
                if (currentGrid != undefined && currentGrid.section.length > 0) {
                    for (let x = 0; x < currentGrid.section.length; x++) {
                        const element = currentGrid.section[x].id;
                        if (currentGrid.section.length > 1) {
                            let id = "builtin" + index + "_" + y
                            let widget = document.getElementById(id);
                            widget.style.display = 'none'
                        }
                        if (currentGrid.section.length == 1) {
                            let id = "builtin" + index + "_" + y
                            let widget = document.getElementById(id);
                            widget.style.display = 'block'
                        }
                        if (element != "" && element != undefined) {
                            let widget = document.getElementById(element);

                            if (widget != null && element.indexOf('builtin') == -1) {
                                var widgetBody: any = widget.getElementsByClassName("widget-body")[0];
                                widgetBody.style.height = this.currentDashboardPageSettings.widgetPluginPositionsGrid.grid[index].widgetHeight + 'px'
                                widgetBody.style.overflowY = 'auto'
                            }
                        }

                    }
                }
                y++;
            }
            this.showSettingButtons = true;
            this.showConfirmNevigateDialouge = true;
            setTimeout(() => {
                let btn = document.getElementById('dashboardSavebtn');
                btn.style.fontWeight = 'bold';
            }, 500);

            // const element = this.currentDashboardPageSettings.widgetPluginPositionsGrid.grid[index];
            // this.currentDashboardPageSettings.widgetPluginPositionsLocalStorage
        }





    }
    btnCancelwidgetToSpecificArticle(form) {
        this.displayWidgetToSpecificArticle = false;
        this.selectedWidgetIndex = null;
        this.selectedWidgetToSpecificArticle = null;
    }

    reload(url: string) {
        this._router.navigateByUrl('.', { skipLocationChange: true });
        setTimeout(() => {
            return this._router.navigateByUrl(url);
        },0);
    }



    populateContainerQualityStatus() {
        this._containerQualityStatusService.getActiveQualityStatusData(null)
        .subscribe({
            next: (response) => {
                try {
                    this.containerQualityStatusList = response;
                    this._sharedService.setContainerQualityStatusList(response);
                }
                catch (ex) {
                    console.log(ex);
                }
            },
            error: (error) => {
            }
        });
    }

    confirmDeleteIncomingFeed() {
        // ask for are you sure
        this._confirmationService.confirm({
            key: "confirmdelete",
            message: "Are you sure you want to delete feed?",
            accept: () => {
                alert('Hello')
            },
            reject: () => {

            }
        });
        //
    }
}