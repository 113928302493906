import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from '../../../shared/services/shared.service';


@Injectable()
export class BuilderSearchService {

    //#region Global Variables



    //#endregion


    constructor(private _sharedService: SharedService, private http: HttpClient) {
    }

    /// start region service methods


    getListBuilderSearch(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/DocumentType/GetDocumentType`, '',this._sharedService.getReqOptWithAccessToken());
    }


    getBuilderSearchbyID(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/BuilderSearch/Detail?builderSearchID=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    deleteBuilderSearchbyID(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/BuilderSearch/Delete?builderSearchID=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getListBuilderSearchbyViewID(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/BuilderSearch/GetBuilderSearchbyViewID?viewID=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    
    updateBuilderSearch(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/BuilderSearch/Update`, body, this._sharedService.getReqOptWithAccessToken());
    }


    addBuilderSearch(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/BuilderSearch/Add`,
            body,
            this._sharedService.getReqOptWithAccessToken());
    }
    /// end region service methods

    ////////////////////////////// start region data methods ////////////////////////////
    //setDocumentTypeList(documentTypeList: any): void {
    //    this._lstDocumentType = documentTypeList;

    //}

    //getDocumentTypeList(): any {
    //    return this._lstDocumentType;
    //}

    ////////////////////////////////////////// end region data methods /////////////////////



}