import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SharedService } from '../../../shared/services/shared.service';

@Injectable()
export class AssetGroupService {
    constructor(private http : HttpClient, private _sharedService: SharedService ) {
    }

    addUpdateAssetGroup(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AssetGroup/AddUpdateAssetGroup`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getAssetGroupByID(body: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AssetGroup/GetAssetGroupByID?assetGroupID=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getAssetGroupAssetByID(body: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AssetGroup/GetAssetGroupAssetByID?assetGroupID=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getAssetGroupList(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AssetGroup/GetAllAssetGroup`, null, this._sharedService.getReqOptWithAccessToken());
    }
}