<style>
  input-group .form-control,
  .input-group-addon,
  .input-group-btn {
    display: table-cell !important;
  }

  .input-group-lg>.form-control,
  .input-group-lg>.input-group-addon,
  .input-group-lg>.input-group-btn>.btn {
    height: 45px !important;
    padding: 5px 10px !important;
    font-size: 17px !important;
    line-height: 1.33 !important;
    border-radius: 3px !important;
  }

  .input-group .form-control {
    position: relative !important;
    z-index: 2 !important;
    float: left !important;
    width: 100% !important;
    margin-bottom: 0 !important;
  }

  .p-autocomplete {
    width: 100% ! important;
  }
</style>


<!-- <div class="jarviswidget-ctrls" title="Help"> -->
  <!--  *ngIf="showEditComm" -->
  <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="showEditDialog()" id="btnedititem"
    (click)="showEditDialog()">
    <i class="fa fa-lg fa-fw fa-question-circle"></i>
  </a>
<!-- </div> -->



<p-dialog [(visible)]="display" showEffect="fade" [modal]="true" [style]="{width:'99.9vw'}" [resizable]="false">
  <ng-template pTemplate="header">
    Article List
  </ng-template>
  <div class="upload-dialog defaultCursor">
    <div role="main" id="main">

      <!-- MAIN CONTENT -->
      <div id="content" style="opacity: 1;">
        <div class="">
          <div class="">
            <div class="">
              <div class="row">
                <div class="col-sm-12">
                  <h1
                    style="font-size: poppins, helvetica, sans-serif ;font-size: 24px;font-weight: 400;color: rgb(24, 50, 71) !important;">
                    How can we help you today?
                  </h1>

                </div>

                <div class="col-sm-6">




                  <div class="input-group input-group-lg">
                    <p-autoComplete (onSelect)="redirectSearchArticle($event)" inputId='articleInputId' name='val'
                      #val='ngModel' [(ngModel)]="searchdata" class="form-control input-lg"
                      [style]="{'width':'100%','font-size':'16px !important'}"
                      [inputStyle]="{'width':'100%','border':'none','padding-right':'0','font-size':'16px !important'}"
                      placeholder="Enter your search  here..." [minLength]="3" [suggestions]="results"
                      (completeMethod)="search($event)">
                      <ng-template let-value pTemplate="results">
                        <span (click)=redirectSearchArticle(value) name="autoComplete" style="font-size:14px"
                          innerHtml="{{value.Title | searchighlight:searchdata }}"> </span>
                        <div class="badge bg-color-blue " style="color:#fff !important;margin-left: 0.2em;">
                          {{value.ArticleSectionName}}</div>
                      </ng-template>
                    </p-autoComplete>
                    <div class="input-group-btn">
                      <button type="button" class="btn btn-default">
                        <i class="fa fa-fw fa-search fa-lg"></i>
                      </button>
                    </div>

                  </div>



                  <!-- <app-search-article ></app-search-article> -->
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="widget-grid-main">
        <div id="content">
          <!-- row -->
          <div class="row">
            <div class="col-sm-12">
              <div class="well padding-10" style="background-color: #fff;margin-top: 20px;" *ngIf="Sections!=null && Sections!=undefined && Sections.length>0">
                <div class="row">
                  <div class="col-sm-12">
                    <!-- <div style="border-bottom: 1px solid green;">

                      <h1
                        style="font-size: poppins, helvetica, sans-serif ;font-size:22px;font-weight: 600;height: 26px;color:rgb(24,50,71) !important;">
                        {{Name}}
                      </h1>

                    </div> -->
                    <div class="row" *ngIf="Sections!=null && Sections!=undefined"
                      style="display: flex !important; flex-wrap: wrap !important;">

                      <div class="col-sm-12" style="display: flex">

                        <div class="col-sm-6" style="flex: 1;">
                          <div *ngFor="let section of LeftSideSections; let i = index">
                            <div style="height:auto !important;">
                              <h3 (click)="redirect(section.ArticleSectionId)" style="margin-bottom: 0;">
                                <a href="javascript:void(0);"
                                  style="font-size: poppins, helvetica, sans-serif !important;;font-size:16px;font-weight: 600;color:rgb(11,23,33) !important; ">
                                  {{section.Name}} </a>
                              </h3>


                              <ul *ngFor="let  article of section.Articles;"
                                style="list-style-type:none;margin-bottom: 3px !important;padding-left: 0 !important;">
                                <li style="margin-top: 0.7em;"> <span class="fa fa-files-o"> </span>
                                  <a href="javascript:void(0);" (click)="redirectToArticle(article.ArticleId,i)"
                                  class="helpArticles"> {{article.Title}}</a>
                                </li>

                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-6" style="margin-right: 20px;">
                          <div *ngFor="let section of RightSideSections; let i = index">
                            <div style="height:auto !important;">
                              <h3 (click)="redirect(section.ArticleSectionId)" style="margin-bottom: 0;">
                                <a href="javascript:void(0);"
                                  style="font-size: poppins, helvetica, sans-serif !important;;font-size:16px;font-weight: 600;color:rgb(11,23,33) !important; ">
                                  {{section.Name}} </a>
                              </h3>


                              <ul *ngFor="let  article of section.Articles;"
                                style="list-style-type:none;margin-bottom: 3px !important;padding-left: 0 !important;">
                                <li style="margin-top: 0.7em;"> <span class="fa fa-files-o"> </span>
                                  <a href="javascript:void(0);" (click)="redirectToArticle(article.ArticleId,i)"
                                    class="helpArticles"> {{article.Title}}</a>
                                </li>

                              </ul>
                            </div>
                          </div>
                        </div>

                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

</p-dialog>



<p-dialog [(visible)]="articleDetails" showEffect="fade" [modal]="true" [style]="{width:'99.9vw'}" [resizable]="false">
  <ng-template pTemplate="header">
    Article Details
  </ng-template>
  <div>
    <div style="background-color: #fbfbfb !important;height: 88vh;overflow-x: hidden;overflow-y: auto;">
      <!-- MAIN CONTENT -->
      <div style="opacity: 1; ">
        <section id="widget-grid-main" class="well" style="border: none !important;">
          <div id="content" style="margin-bottom: 3em !important;">
            <div class="row" style="padding-left:6%;padding-right:6%;">
              <div class="col-sm-12">


                <div class="input-group input-group-lg">
                  <p-autoComplete (onSelect)="redirectSearchArticle($event)" inputId='articleInputId1' name='val'
                    #val='ngModel' [(ngModel)]="searchdata" class="form-control input-lg"
                    [style]="{'width':'100%','font-size':'16px !important'}"
                    [inputStyle]="{'width':'100%','border':'none','padding-right':'0','font-size':'16px !important'}"
                    placeholder="Enter your search  here..." [minLength]="3" [suggestions]="results"
                    (completeMethod)="search($event)">
                    <ng-template let-value pTemplate="results">
                      <span (click)=redirectSearchArticle(value) name="autoComplete" style="font-size:14px"
                        innerHtml="{{value.Title | searchighlight:searchdata }}"> </span>
                      <div class="badge bg-color-blue " style="color:#fff !important;margin-left: 0.2em;">
                        {{value.ArticleSectionName}}</div>
                    </ng-template>
                  </p-autoComplete>
                  <div class="input-group-btn">
                    <button type="button" class="btn btn-default">
                      <i class="fa fa-fw fa-search fa-lg"></i>
                    </button>
                  </div>

                </div>





                <!-- <app-search-article></app-search-article> -->
              </div>
            </div>
            <!-- row -->
            <div class="row" style="padding-left:6%;padding-right:6%;margin-top:1em;">
              <div class="col-sm-12">
                <div *ngIf="articleDetail">
                  <div class="row" style="display: flex !important; flex-wrap: wrap !important;">

                    <div class="col-sm-8 "
                      style="background-color: #fff;border:1px solid #ddd; border-right: none !important;">

                      <!-- <div class="forum-attachment" style="margin-top: 1em;">
                                            <a routerLink="/help/helparticles"  href="javascript:void(0);"  style="color: #3276b1;"> Solution Home</a>  /
                                            <a routerLink="/help/helparticles"   href="javascript:void(0);" style="color: #3276b1;"> ROAM Help Center</a>
                                      </div> -->

                      <h1
                        style="font-family: poppins, helvetica, sans-serif !important;;font-size:22px;font-weight: 600;height: 26px;color:rgb(24,50,71) !important;">
                        {{articleDetail.Title}} </h1>

                      <div class="row">
                        <div class="col-sm-7">
                          <p
                            style="font-family:poppins, helvetica, sans-serif !important;font-size:14px;font-weight: 400;height: 21px;color:rgb(24,50,71) !important;">
                            Created by: {{articleDetail.CreatedBy}}</p>
                          <p *ngIf="articleDetail.ModifiedBy"
                            style="font-family:poppins, helvetica, sans-serif !important;font-size:14px;font-weight: 400;height: 21px;color:rgb(24,50,71) !important;">
                            Modified by: {{articleDetail.ModifiedBy}}</p>
                        </div>
                        <div class="col-sm-5">
                          <p
                            style="font-family:poppins, helvetica, sans-serif !important;font-size:14px;font-weight: 400;height: 21px;color:rgb(24,50,71) !important;">
                            Created on: {{articleDetail.CreatedOn}}</p>

                          <p *ngIf="articleDetail.ModifiedBy"
                            style="font-family:poppins, helvetica, sans-serif !important;font-size:14px;font-weight: 400;height: 21px;color:rgb(24,50,71) !important;">
                            Modified on: {{articleDetail.ModifiedOn}}</p>
                        </div>
                      </div>

                      <!-- <p style="font-family:poppins, helvetica, sans-serif !important;font-size:14px;font-weight: 400;height: 21px;color:rgb(24,50,71) !important;">Created by: {{articleDetail.CreatedBy}}</p>
                                      <p  style="font-family:poppins, helvetica, sans-serif !important;font-size:14px;font-weight: 400;height: 21px;color:rgb(24,50,71) !important;">Modified on: {{articleDetail.ModifiedOn}}</p> -->
                      <hr />
                      <div class="imageContent" [innerHTML]='content'></div>

                    </div>
                    <div class="col-sm-4 " style="padding-left: 0 !important;padding-right: 0 !important;">
                      <div class="well"
                        style="padding-left: 0 !important;padding-right: 0 !important;height: 100% !important; ">

                        <div class=" padding-10" style="padding-left:0 ! important">
                          <div>

                          </div>
                          <div style="margin-bottom: 3px !important;padding-left: 0.5em !important;">

                            <ul class="text-left" style="list-style-type:none;">
                              <h6
                                style="font-size: poppins, helvetica, sans-serif !important;;font-size:22px;font-weight: 600;height: 26px;color:rgb(24,50,71) !important;">
                                Related Articles
                              </h6>

                              <li style="margin-top: 0.9em;" *ngFor="let relatedArticle of SameSectionArticle;">
                                <div [hidden]="relatedArticle.ArticleId ==articleDetail.ArticleId">

                                  <p style=" line-height:0.7m;height: 2.5em;overflow: hidden;">
                                    <span class="fa fa-files-o"> </span>&nbsp;
                                    <a (click)="redirectTOArticle(relatedArticle.ArticleId)"
                                      style="cursor: pointer;font-family:poppins, helvetica, sans-serif !important;font-size:14px;font-weight: 400;height: 21px;color:rgb(24,50,71) !important;">
                                      {{relatedArticle.Title}}</a>
                                  </p>

                                </div>

                              </li>
                            </ul>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>


            </div>
          </div>
        </section>
      </div>

    </div>
  </div>

</p-dialog>