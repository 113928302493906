//#region References
import { ContainerCategory, FeedType, FileType, SourceType, TargetType } from '../../services/api.helper-classes';
import { AddUpdateStatusCode, IConfig } from '../../../shared/helpers/config';
import { GridOptions } from 'ag-grid-community';
import { ContainerService } from 'src/app/records/services/container/container.service';
import { container, SearchContainerModel } from 'src/app/records/services/container/container';
import * as moment from 'moment/moment';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { SharedService } from 'src/app/shared/services/shared.service';
import { NgForm } from '@angular/forms';
import { AssetService } from 'src/app/admin/services/asset/asset.service';
import { Router } from '@angular/router';
import { ContainerQualityStatusService } from '../../../admin/services/containerqualitystatus/container-quality-status.service';

var dateFormatF: string;
var dateSeparatorS: string;
var dayD: number;
var monthM: number;
var yearY: number;
var columnDefs_1: any[];
var statusList: any[] = [];
//#endregion

//#region Type Decorator
@Component({
    selector: 'select-target-container',
    templateUrl: './select-target-container.component.html',
    providers: [ContainerService, SharedService, AssetService, ContainerQualityStatusService]
})
//#endregion

export class SelectContainerComponent implements OnInit, OnDestroy {
    public dateConfig: IConfig[] = [];
    public conGridOptions: GridOptions;
    public containerSelectionDlg: boolean = false;
    conRowSelected: boolean = false;
    conGridData: any[] = [];
    public selectedComboCatergorySelection: string = "";
    categories: SelectItem[] = [];
    containerModel: SearchContainerModel;
    conTotalCount: number = 0;
    conTypes: SelectItem[] = [];
    assets: SelectItem[] = [];
    conColumnDefs: any[];
    public Container_MXR_Columns: any[] = [];
    public Container_LOG_Columns: any[] = [];
    public Container_GEN_Columns: any[] = [];
    public Container_DOC_Columns: any[] = [];
    public Container_BND_Columns: any[] = [];
    public Container_MXR_Sort_By: string = "";
    public Container_LOG_Sort_By: string = "";
    public Container_GEN_Sort_By: string = "";
    public Container_DOC_Sort_By: string = "";
    public Container_BND_Sort_By: string = "";
    qualityStatusList: any[] = [];
    showReportButton: boolean = false;
    @Input() inputDataType: any = null
    @Input() configurationSelectedRows: any = null
    @Input() feedTemplate: any;
    constructor(public _sharedService: SharedService, private _router: Router, private _containerService: ContainerService, private _assetService: AssetService, private _qualityStatusService: ContainerQualityStatusService) {

        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.updateRoles();
        }
    }
    updateRoles() {
        //if (!this._sharedService.UserRole) {
        this._sharedService.updateRole();
        //}
    }
    ngOnInit() {

        this.updateRoles();
        this.containerModel = new SearchContainerModel("", null, null, false);

        if (this._router.url.indexOf('/status/exportstatuscompliancedocuments') != -1
            || this._router.url.indexOf('/repair/exportrepaircompliancedocuments') != -1
            || this._router.url.indexOf('/transition/exporttransitiondocumentslist') != -1
            || this._router.url.indexOf('record/exportrecordsdocuments') != -1
          || this._router.url.indexOf('/record/exportcontainer') != -1
          || this._router.url.indexOf('/transition/exportcontainer') != -1) {
            this.showReportButton = true;
        }
        else if (this._router.url.indexOf('/status/exportstatusdata') != -1
            || this._router.url.indexOf('/repair/exportrepairdata') != -1
            || this._router.url.indexOf('/transition/exporttransitiondocuments') != -1
          || this._router.url.indexOf('/record/exportrecordsdocumentslist') != -1
        ) {
            this.showReportButton = true;

        }
        else if (this._router.url.indexOf("Export") != -1 || this._router.url.indexOf("export") != -1) {
            this.showFeedContainerIcon = true;
        }
        this.dateConfig = this._sharedService.getConfiguration();
        this.getDateFormatInfo()
        // this.prepareContainerGridColumns();
        this.populateQualityStatus();
        this.conGridOptions = <GridOptions>
            {
                rowSelection: 'single',
                onGridReady: () => {
                    this.setContainerGridColumns();
                },
                suppressContextMenu: true,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                }
            }
    }
    ngOnDestroy() {
        this.showFeedContainerIcon = false;
        this.showReportButton = false;
    }
    showFeedContainerIcon: boolean = false;

    TargetContainerSelection() {
        this.prepareContainerGridColumns();
        this.containerModel = new SearchContainerModel("", null, null, false);
        this.containerSelectionDlg = true;
        this.conRowSelected = false;
        this.conGridData = []
        this.populateAsset()
    }

    resetContainerModel() {
        this.containerModel = new SearchContainerModel("", null, null, false);
        this.conGridData = [];
    }

    populateAsset() {
        this._assetService
            .getAssetWithAssetIdByUserRole()
            .subscribe({
                next: (response) => {
                    this.assets = response;
                },
                error: (error) => {
                    this.assets = [];
                    console.log(error);
                }
            });
    }

    populateContainerType(category: string) {
        this.conTypes = [];
        let body: container = {
            Category: category
        }
        this._containerService.getContainerType(body)
        .subscribe({
            next: (response) => {
                let res = response;
                for (let i = 0; i < res.length; i++) {
                    this.conTypes.push({ value: res[i].Type, label: res[i].Type })
                }
            },
            error: (error) => {
                this.conGridData = [];
                this.conTotalCount = 0;
            }
        });
    }

    onChangeAsset(event: any) {
        this.conRowSelected = false;
        this.conGridData = [];
        let assetid = JSON.parse(event.value).AssetID;
        this.populateCategoryByAssetID(assetid);
        this.setContainerGridColumns();

    }
    populateCategoryByAssetID(assetID: string): void {
        this.categories = [];
        this._assetService.getAssetbyID(assetID)
        .subscribe({
            next: (response) => {
                this.categories = [];
        
                if (response.MXRecords) {
                    this.categories.push({ value: 'MXR', label: 'MX Records' });
                }
                if (response.LogBooks) {
                    this.categories.push({ value: 'LOG', label: 'Log Books' });
                }
                if (response.General) {
                    this.categories.push({ value: 'GEN', label: 'General' });
                }
                if (response.Documents) {
                    this.categories.push({ value: 'DOC', label: 'Documents' });
                }
                if (response.Binders) {
                    this.categories.push({ value: 'BND', label: 'Binders' });
                }
        
                //For status and repair conditions
                if ((this._router.url == '/status/exportstatusdata' || this._router.url == '/repair/exportrepairdata') && this.inputDataType != "PDF" && this.categories.length > 0) {
        
                    this.categories = this.categories.filter(c => c.value == "BND" || c.value == "DOC")
                }
                //For Record and repair Transation conditions
                else if (this._router.url == '/record/exportrecordsdocumentslist' || this._router.url == '/transition/exporttransitiondocumentslist') {
                    if (this.inputDataType != "PDF" && this.categories.length > 0)
                        this.categories = this.categories.filter(c => c.value == "BND" || c.value == "DOC")
                    else {
                        var pdfCategoriesCount = this.configurationSelectedRows.filter(x => x.data.Category == "BND" || x.data.Category == "DOC")
                        if (pdfCategoriesCount.length > 0) {
                            this.categories = this.categories.filter(c => c.value == "BND" || c.value == "DOC")
                        }
                    }
            }
                else if (this._router.url == '/record/exportcontainer' || 
                  this._router.url == '/transition/exportcontainer' ||
                  this.feedTemplate.TemplateName == 'Export Container List' ||
                  this.feedTemplate.TemplateName == 'Toolbox - Export Container List') {
                this.categories = this.categories.filter(c => c.value == "BND" || c.value == "DOC")
              }
            },
            error: (error) => {
                this.categories = [];
                console.log(error);
            }
        });
    }


    onChangeCategory(event: any) {
        this.conRowSelected = false;
        this.conGridData = [];
        this.populateContainerType(event.value);
        this.selectedComboCatergorySelection = event.value;
    }

    populateContainerGrid(): void {
        this._containerService.getFilteredContainer(this.containerModel)
        .subscribe({
            next: (response) => {
                this.setContainerGridColumns();
                this.conGridData = response;
                // setTimeout(() => {
        
                // }, 2000);
        
                //    this.conTotalCount = this.conGridData.length;
                //this.gridOptions.api.sizeColumnsToFit();
            },
            error: (error) => {
                this.conGridData = [];
                this.conTotalCount = 0;
                console.log(error);
            }
        });
    }
    prepareContainerGridColumns(): void {
        var appConfigs = this._sharedService.getConfiguration();
        if (appConfigs != null) {
            this.Container_MXR_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_MX_Records_Column_Setting')[0].Value);
            this.Container_LOG_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Log_Books_Column_Setting')[0].Value);
            this.Container_GEN_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_General_Column_Setting')[0].Value);
            this.Container_DOC_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Documents_Column_Setting')[0].Value);
            this.Container_BND_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Binders_Column_Setting')[0].Value);
            this.Container_MXR_Sort_By = appConfigs.filter(x => x.Key == 'Container_MXR_Sort_By')[0].Value;
            this.Container_LOG_Sort_By = appConfigs.filter(x => x.Key == 'Container_LOG_Sort_By')[0].Value;
            this.Container_GEN_Sort_By = appConfigs.filter(x => x.Key == 'Container_GEN_Sort_By')[0].Value;
            this.Container_DOC_Sort_By = appConfigs.filter(x => x.Key == 'Container_DOC_Sort_By')[0].Value;
            this.Container_BND_Sort_By = appConfigs.filter(x => x.Key == 'Container_BND_Sort_By')[0].Value;
        }
    }
    setContainerGridColumns(): void {
        if (!this.selectedComboCatergorySelection) {
            return;
        }
        this.conColumnDefs = [];
        columnDefs_1 = [];
        var containerGridCols: any[];
        var container_Col_Sort_On_By: string = '';
        if (this.selectedComboCatergorySelection.toUpperCase() == 'MXR') {
            containerGridCols = this.Container_MXR_Columns;
            container_Col_Sort_On_By = this.Container_MXR_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'LOG') {
            containerGridCols = this.Container_LOG_Columns;
            container_Col_Sort_On_By = this.Container_LOG_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'GEN') {
            containerGridCols = this.Container_GEN_Columns;
            container_Col_Sort_On_By = this.Container_GEN_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'DOC') {
            containerGridCols = this.Container_DOC_Columns;
            container_Col_Sort_On_By = this.Container_DOC_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'BND') {
            containerGridCols = this.Container_BND_Columns;
            container_Col_Sort_On_By = this.Container_BND_Sort_By;
        }
        containerGridCols.forEach((col, index) => {
            let _filter = 'set';
            if (col.FieldName == 'Date' || col.FieldName == 'EndDate' || col.FieldName == 'ScannedOn') {
                _filter = 'agDateColumnFilter';
            }
            let gridCol: any;
            if (col.FieldName == 'ContainerQualityStatus') {
                gridCol = {
                    headerName: col.ColumnLabel,
                    field: 'QualityStatus',//'QualityStatus---ID',//24-02-2021
                    width: col.Width,
                    filter: _filter,
                    tooltipField: col.FieldName,
                    menuTabs: ['filterMenuTab'],
                    hide: !col.IsVisible,
                    filterParams: this.gridStatusFilterParams,
                };
            }
            else {
                gridCol = {
                    headerName: col.ColumnLabel,
                    field: col.FieldName,
                    width: col.Width,
                    filter: _filter,
                    tooltipField: col.FieldName,
                    menuTabs: ['filterMenuTab'],
                    hide: !col.IsVisible
                };
            }
            if (col.FieldName == 'Date' || col.FieldName == 'EndDate' || col.FieldName == 'ScannedOn') {
                gridCol.filterParams =
                {
                    comparator: this.dateComparater,
                    inRangeInclusive: 'true'
                }
                gridCol.valueFormatter = function (params: any) {
                    if (params.value != '' && params.value != null) {
                        let dateString = params.value.substring(0, 10);
                        return moment(dateString).format(dateFormatF.toUpperCase());
                    }
                }
            }
            else if (col.FieldName == 'ContainerQualityStatus') {
                gridCol.filterParams = {
                    cellRenderer: this.statusCellRenderer, // formats filter values
                }
                gridCol.cellRenderer = this.statusCellRenderer;
            }
            columnDefs_1.push(gridCol);
        });
        this.conColumnDefs = columnDefs_1;
        let sortOnCol = container_Col_Sort_On_By.split("||")[0];
        let orderBy = container_Col_Sort_On_By.split("||")[1];
        if (sortOnCol == "ContainerQualityStatus") {
            sortOnCol = "QualityStatus";
        }
        this.conColumnDefs.forEach(function (col, index) {
            if (col.field == sortOnCol) {
                this.conColumnDefs[index].sort = orderBy;
            }
        }.bind(this));
    }
    gridStatusFilterParams = { cellRenderer: this.statusCellRenderer };
    populateQualityStatus() {
        let qualityStatusListValues = this._sharedService.getContainerQualityStatusList();
        if (qualityStatusListValues == null || qualityStatusListValues.length == 0) {
            this._qualityStatusService.getActiveQualityStatusData(null)
            .subscribe({
                next: (response) => {
                    try {
                        this.qualityStatusList = response;
                        statusList = response;
                    } catch (ex) {
                        this.qualityStatusList = response;
                        statusList = response;
                        console.log(ex);
                    }
                },
                error: (error) => {
                }
            });
        } else {
            this.qualityStatusList = qualityStatusListValues;
            statusList = qualityStatusListValues;
        }
    }
    statusCellRenderer(params: any) {
        let html: string = '';
        var eDiv = document.createElement('div');
        if (params.value) {
            try {
                let currentStatus: any = statusList.filter(x => x.QualityStatusID == params.value)[0];
                if (currentStatus && currentStatus.Name) {
                    eDiv.style.background = currentStatus.CSSClass;
                    html = html + currentStatus.Name;
                } else {
                    let currentStatus: any = statusList.filter(x => x.Name == params.value)[0];//params.value)[0];
                    if (currentStatus && currentStatus.Name) {
                        eDiv.style.background = currentStatus.CSSClass;
                        html = html + currentStatus.Name;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            return params.value;
        }
        eDiv.style.height = '100%';
        eDiv.style.width = '100%';
        eDiv.style.color = 'white';
        eDiv.style.textAlign = 'left';
        eDiv.style.paddingLeft = '5px';
        eDiv.innerHTML = html;
        return eDiv;
    }
    setContainerGridColumns_BackUp(): void {
        this.conColumnDefs = [];

        this.conColumnDefs =
            [
                {
                    headerName: this._sharedService.getContainerLabel(this.selectedComboCatergorySelection, 'Type'), field: 'Type', filter: 'set', tooltipField: 'Description', menuTabs: ['filterMenuTab'], minWidth: 70,
                    //, filterParams: { comparator: this.ContainerTypeComparater }
                },
                {
                    headerName: this._sharedService.getContainerLabel(this.selectedComboCatergorySelection, 'Date'), field: 'Date', filter: 'agDateColumnFilter', filterParams:
                    {
                        comparator: this.dateComparater,
                        inRangeInclusive: 'true'
                    }, valueFormatter: function (params: any) {
                        if (params.value != "" && params.value != null) {

                            let dateString = params.value.substring(0, 10);
                            return moment(dateString).format(dateFormatF.toUpperCase());
                        }
                    }, sort: 'desc', menuTabs: ['filterMenuTab'], minWidth: 90,
                },
                {
                    headerName: this._sharedService.getContainerLabel(this.selectedComboCatergorySelection, 'EndDate'), field: 'EndDate', filter: 'agDateColumnFilter', filterParams: {
                        comparator: this.dateComparater,
                        inRangeInclusive: 'true'

                    }, valueFormatter: function (params: any) {
                        if (params.value != "" && params.value != null) {
                            let dateString = params.value.substring(0, 10);
                            return moment(dateString).format(dateFormatF.toUpperCase());
                        }
                    }, menuTabs: ['filterMenuTab'], minWidth: 90,
                },
            ];
        if (this.selectedComboCatergorySelection == 'MXR') {
            this.conColumnDefs.push({ headerName: this._sharedService.getContainerLabel(this.selectedComboCatergorySelection, 'ReferenceKey'), field: 'ReferenceKey', tooltipField: 'ReferenceKey', menuTabs: ['filterMenuTab'], minWidth: 90, filter: 'set' });
        } else if (this.selectedComboCatergorySelection == 'LOG') {
            this.conColumnDefs.push({ headerName: this._sharedService.getContainerLabel(this.selectedComboCatergorySelection, 'Description'), field: 'Description', tooltipField: 'Description', menuTabs: ['filterMenuTab'], minWidth: 110, filter: 'set' });
        } else if (this.selectedComboCatergorySelection == 'GEN') {
            this.conColumnDefs.push({ headerName: this._sharedService.getContainerLabel(this.selectedComboCatergorySelection, 'ReferenceKey'), field: 'ReferenceKey', tooltipField: 'ReferenceKey', menuTabs: ['filterMenuTab'], minWidth: 90, filter: 'set' });
        }
    }

    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            // let dateString = cellValue.substring(0, 10).split(dateSeparatorS);
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateFormatF.toUpperCase()).split(dateSeparatorS);
            var day = Number(dateParts[Number(dayD)].substring(0, 2));
            var month = Number(dateParts[Number(monthM)]) - 1;
            var year = Number(dateParts[Number(yearY)]);
            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        } else {
            return -1;
        }
    }
    getDateFormatInfo(): any {

        if (this.dateConfig != undefined) {
            dateFormatF = this.dateConfig.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
            dateSeparatorS = this.dateConfig.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
            dayD = Number(this.dateConfig.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
            monthM = Number(this.dateConfig.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
            yearY = Number(this.dateConfig.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
        } else {
            dateFormatF = localStorage.getItem('DateFormat');
            dateSeparatorS = localStorage.getItem('DateSeparater');
            dayD = Number(localStorage.getItem('DayIndex'));
            monthM = Number(localStorage.getItem('MonthIndex'));
            yearY = Number(localStorage.getItem('YearIndex'));

        }
    }
    onContainerRowClick(event: any) {
        this.conRowSelected = true;
    }
    public selContainer = null;
    public targetDetail;
    btnContainerSelectionClick(containerForm: NgForm) {
        //US-8181 //if (this.containerModel.asn.split('|')[4] == 'True')
        if (JSON.parse(this.containerModel.asn).ReadOnly) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Readonly Asset', detail: 'Cannot Move/Copy documnet(s) to readonly asset.' });
            return;
        }
        // this._sharedService.showLoader("Processing...");
        this.selContainer = this.conGridOptions.api.getSelectedRows();

        if (this.selContainer.length > 0) {
            var selectedContainer = this.conGridOptions.api.getSelectedRows();
            this.selContainer[0].ContainerName = JSON.parse(this.containerModel.asn).StoragePath;
            var targetDetail = JSON.parse(this.containerModel.asn).StoragePath;
            targetDetail = targetDetail + "/" + JSON.parse(this.containerModel.asn).ASN;
            targetDetail = targetDetail + "/" + this.selContainer[0].Type;
            targetDetail = targetDetail + "/" + this.selContainer[0].ID;
            var obj = {
                targetDetail: targetDetail,
                selectedContainer: selectedContainer
            }
            this.targetContainer.emit(obj)
            this.btnCancelContainerClick(containerForm)
        }

    }
    @Output() targetContainer = new EventEmitter<any>();

    btnCancelContainerClick(containerForm: NgForm) {
        this.containerSelectionDlg = false;
        if (containerForm != null) {
            containerForm.resetForm();
            this.conGridData = [];
        }
    }
    //#endregion
}