export interface IConfig {
    Key: string;
    Value: string;
}

export enum OperationMode {
    Add = 1,
    Edit = 2,
    Delete = 3
}

export enum AddUpdateStatusCode {  
        Error = 0,       
        Success = 1,      
        Duplicate = 2,
        SameFeedName = 4, 
        ReadOnlyAsset = 5    
}
