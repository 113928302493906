//Components
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, Params, NavigationEnd, NavigationStart } from '@angular/router';
import { GridOptions, GridApi, ColumnApi,AgEvent,AgGridEvent } from "ag-grid-community";

@Component({
    selector: 'view-inbox-button',
    changeDetection:ChangeDetectionStrategy.OnPush,
    templateUrl: './view-inbox-button.component.html',

})
export class ViewLinkedRecordInboxComponent implements OnInit {
    paramsGlobal: any;
    index: number = 0;
    isDisplayButton: boolean = false;
    constructor(private cRef:ChangeDetectorRef) {

    }
    agInit(params:any)
    {
        this.paramsGlobal = params;
        this.index = params.data.CommId;
        if (params.data && params.data.Module != null && params.data.Module !="") {
            this.isDisplayButton = true;
        }
        else {
            this.isDisplayButton = false;
        }
    }
    viewRecord()
    {
        this.paramsGlobal.context.gridContext.clickedItemsRow(this.paramsGlobal);
        this.paramsGlobal.context.gridContext.viewItem();
        this.cRef.markForCheck();
    }
    ngOnInit() {
    }

}