<!-- <div class="jarviswidget-ctrls" title="Upload Feed File(s)">
    <a class="button-icon" id="btnuploaddocument" (click)="showUploadDialog()">
        <i class="glyphicon glyphicon-open"></i>
    </a>
</div> -->
<div class="jarviswidget-ctrls pdfoperations" *ngIf="isShowDataFileOption || isShowTemplateFileOption">
    <div class="btn-group">
        <a class="button-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="true" title="Upload">
            <i class="glyphicon glyphicon-open"></i>
        </a>
        <ul class="dropdown-menu pull-right js-status-update">
            <li *ngIf="isShowDataFileOption">
                <a class="defaultCursor" (click)="showUploadDialog('1')"><i class="fa fa-columns txt-color-green"></i>&nbsp;Data File</a>
            </li>
            <li *ngIf="isShowTemplateFileOption">
                <a class="defaultCursor" (click)="showUploadDialog('2')">
                    <i class="fa fa-save txt-color-green"></i>&nbsp;Template File
                </a>
            </li>

        </ul>
    </div>
</div>

<form #addItemForm="ngForm">
    <p-dialog [(visible)]="uploaddisplay" showEffect="fade" [modal]="true" [style]="{width: '550px'}" [resizable]="false">
        <ng-template pTemplate="header" >
            {{dialogTitle}}
        </ng-template>
        <div class="upload-dialog">
            <div id="commAddDlg" class="smart-form" >
                <!-- <div class="row" *ngIf="isDisableDataFile==false">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                File Type
                            </label>

                                <div class="inline-group" id="rdobutton">
                                    <label class="radio">
                                        <input type="radio" checked="checked" name="radio-inline1" [(ngModel)]="model.FileType" [value]="true" (click)="changeFileType('1')">
                                        <i></i>Data File
                                    </label>
                                    <label class="radio">
                                        <input type="radio" name="radio-inline1" [(ngModel)]="model.FileType" [value]="false" (click)="changeFileType('2')">
                                        <i></i>Template File
                                    </label>
                                </div>
                        </fieldset>
                    </div>                                        
                </div> -->
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">Source Files</label>
                            <p-fileUpload name="feedfile" (onSelect)="onSelect($event)" (onRemove)="onRemove($event)" multiple="multiple" customUpload="true" uploadHandler="onBulkUpload($event,addItemForm)" chooseLabel="Browse"  [(ngModel)]="bulkFiles" [accept]="acceptfilestype" invalidFileTypeMessageSummary="Invalid File(s)" invalidFileTypeMessageDetail="Only files of type {{messageFileType}} are valid"  #feedfile ngDefaultControl>
                            </p-fileUpload>                         
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12"> <!--*ngIf="attachedFiles!=null && attachedFiles.length>0"-->
                        <fieldset>
                            <label class="label"><br /></label>
                            <div style="max-height: 110px !important;overflow-y: auto !important;">
                                <table class="table table-hover">
                                    <tbody>
                                        <tr *ngFor="let file of attachedFiles">
                                            <td>{{file}}</td>
                                            <td><a (click)="downloadFile(file)" class="glyphicon glyphicon-save"></a><span></span> </td>
                                            <td><a (click)="removeAttachedFile(file)" class="fa fa-fw fa fa-close"></a><span></span> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </fieldset>
                    </div>

                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                    <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex" (click)="onBulkUpload($event,addItemForm)" label="Upload" [disabled]="!addItemForm.form.valid || isSubmitted" *ngIf="(uploadedFiles.length>0 || (!isAnyFileRemoved))"></button>
                    <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex" (click)="onBulkUpload($event,addItemForm)" label="Update" [disabled]="!addItemForm.form.valid || isSubmitted" *ngIf="(uploadedFiles.length==0 && isAnyFileRemoved)"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" (click)="btnCancelClick(addItemForm)" label="Cancel" [disabled]="isSubmitted"></button>                
            </div>
        </ng-template>

    </p-dialog>
</form>
