export class EditJobModel {

    constructor(
        public asset: string,

        public type: string,

        public date: Date,

        public priority: string,

        public dpjobstatusid: string,

        public assignedto: string,

        public documents: number,

        public pages: number,

        public status: boolean,

        public enddate?: Date,

        public referencekey?: string,

        public dpjobinternalstatusid?: string,

        public comments?: string,

        public history?: string,
        public dpjobid?:string,

        public createdby?: string,

        public createdon?: Date,

        public modifiedby?: string,

        public modifiedon?: Date,
        public conversation?:string,
        public containerid?: string,
        public batchtypeid?: string,
    ) { }

}
export class Conversation {
    constructor(Name: string, Date: string, Comment: string) {
        this.Name = Name;
        this.Date = Date;
        this.Comment = Comment;
    }
    public Name: string;
    public Date: string;
    public Comment: string;

}

export class ConversationList {

    public Conversation: Conversation[];
}