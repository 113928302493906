//Components
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
//Services
import { SharedService } from "../../../shared/services/shared.service";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { AzureBlobService } from "../../../shared/services/AzureBlob/azure-blob.service";

import { EmbedVideoService } from "ngx-embed-video";
//import fabric from "fabric/fabric-impl";
import { fabric } from 'fabric';
import { CanvasObject } from "../../../admin/services/view/canvasobject";

import { Canvas } from 'fabric/fabric-impl';

//interfaces

@Component({
  selector: "document",
  templateUrl: "./document-viewer.component.html",
  //animations: [routerTransition()],
  //host: { '[@routerTransition]': '' },
  providers: [AzureBlobService],
})
export class DocumentViewerComponent implements OnInit {
  //#region Gloabal Variables

  fileType: string = 'application/pdf';
  @ViewChild('videoPlayer') _videoPlayer: ElementRef;

  @ViewChild('audioPlayer') _audioPlayer: ElementRef;

  pdfSrc: SafeResourceUrl;
  sanitiz: DomSanitizer;

  selectedUrl: string;
  selectedVideoUrl: string;
  video_html: string = "";
  showWarning: boolean = false;
  warningHeading: string = "";
  warningMsg: string = "";
  showZipFileMsg: boolean = false;
  zipFileMsg: string = "";

  isAudioTypeFound: string = null;
  isVideoTypeFound: string = null;

  isNonPreviewTypeFound: string = null;
  isDocumentTypeFound: string = null;
  isOfficeDocumentTypeFound: string = null;
  isImageTypeFound: string = null;
  isVideoURLTypeFound: string = null;
  canvas: fabric.Canvas;
  orignX: any;
  orignY: any;
  currentImg: any = null;
  canvasObject: CanvasObject;
  zoomPercentage: number = 100;

  //#endregion

  //#region Initialization of Component
  constructor(
    private _sharedService: SharedService,
    private _router: Router,
    private sanitizer: DomSanitizer,
    private _azureBlobService: AzureBlobService,
    private embedVideoService: EmbedVideoService
  ) {
    this.sanitiz = sanitizer;
  }
  ngOnInit() {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(["login"]);
    } else {
      this.updateRoles();
      this._sharedService.isViewDocOnly = false;
      this.pdfSrc = null;
      if (this._sharedService.UserRole.Records_SingleDocument_View) {
        if (this._sharedService.documentUrl.indexOf(".zip") != -1) {
          this.populateZipFile();
        } else {
          this.populateDoc();
        }
      } else {
        this.showWarning = true;
        this.warningHeading = "Unauthorized!";
        this.warningMsg =
          "You are not authorized to Single Document View module. Please contact your ROAM administrator.";
      }
    }
  }

  updateRoles() {
    if (!this._sharedService.UserRole) {
      this._sharedService.updateRole();
    }
  }

  populateDoc() {

    let docpath = this._sharedService.documentUrl.replace("?", "");
    let validateRole: boolean = !this._sharedService.UserRole.All_Aircraft;
    this._azureBlobService.getDocUrl(docpath, validateRole)
      .subscribe({
        next: (response) => {
          if (response[0] == "Doc" || response[0] == "BND") {
            this.selectedUrl = response[1];
            if (response[1].indexOf("api/Blob/GetDocument/") != -1) {
              this.loadImage(this.selectedUrl);
            } else {
              let docType = "";
              let urlSegments = this.selectedUrl.split('?');
              if (urlSegments.length == 2) {
                docType = urlSegments[0].split(".").pop();
              }
              else {
                //to do
                docType = urlSegments[0].split(".").pop();
              }
              this.isAudioTypeFound = this._sharedService.allowedAudioFileTypes.filter(x => x == docType)[0];
              this.isVideoTypeFound = this._sharedService.allowedVideoFileTypes.filter(x => x == docType)[0];
              this.isNonPreviewTypeFound = this._sharedService.allowedNonPreviewFileTypes.filter(x => x == docType)[0];
              this.isDocumentTypeFound = this._sharedService.allowedDocumentTypes.filter(x => x == docType)[0];
              this.isImageTypeFound = this._sharedService.allowedImageFileTypes.filter(x => x == docType)[0];
              this.isVideoURLTypeFound = docType == "videourl" ? 'videourl' : undefined;
              if (docType == "videourl") {
                this.isVideoURLTypeFound = 'videourl';
                this.isDocumentTypeFound = undefined;
              }
              this.fileType = docType;

              if (!this.isAudioTypeFound && !this.isVideoTypeFound && !this.isVideoURLTypeFound &&
                !this.isNonPreviewTypeFound && !this.isDocumentTypeFound && !this.isImageTypeFound) {
                this.isNonPreviewTypeFound = docType;// handle blob with non-preview file type
              }

              if (this.isNonPreviewTypeFound) {
                this.DownloadBlob();
              }
              else if (this.isDocumentTypeFound) {
                this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(
                  response[1]
                );
              }
              else if (this.isVideoTypeFound) {
                this.fileType = this.isVideoTypeFound;

                //for VM case
                if (this.selectedUrl.lastIndexOf('api/Blob/GetDocument/') >= 0) {
                  if (this.selectedUrl.lastIndexOf('.') >= 0) {
                    let lastIndex = this.selectedUrl.lastIndexOf('.');
                    //this.selectedUrl = this._azureBlobService.replaceAt(this.selectedUrl, lastIndex, 1, '^|^');
                    this.selectedUrl = this.selectedUrl;
                    this.load_VM_AudioVideo(this.selectedUrl);
                  }
                }
                else {//Azure
                  setTimeout(() => {
                    if (this._videoPlayer) {
                      const video: HTMLVideoElement = this._videoPlayer.nativeElement;
                      video.setAttribute('src', this.selectedUrl);
                      video.load();
                      //video.muted = true;
                      video.autoplay = true;
                      //video.play();

                      //setTimeout(() => {
                      const vid: HTMLVideoElement = this._videoPlayer.nativeElement;
                      if (vid.error) {
                        console.log("Video err: " + vid.error.code);
                      }
                      //}, 2000);
                    }
                  }, 2000);
                }
              }
              else if (this.isAudioTypeFound) {
                this.fileType = this.isAudioTypeFound;
                //for VM case
                if (this.selectedUrl.lastIndexOf('api/Blob/GetDocument/') >= 0) {
                  if (this.selectedUrl.lastIndexOf('.') >= 0) {
                    let lastIndex = this.selectedUrl.lastIndexOf('.');
                    //this.selectedUrl = this._azureBlobService.replaceAt(this.selectedUrl, lastIndex, 1, '^|^');
                    this.selectedUrl = this.selectedUrl;
                    this.load_VM_AudioVideo(this.selectedUrl);
                  }
                }
                else {//Azure

                  setTimeout(() => {
                    if (this._audioPlayer) {
                      const audio: HTMLAudioElement = this._audioPlayer.nativeElement;
                      audio.setAttribute('src', this.selectedUrl);
                      audio.load();
                      //audio.muted = true;
                      audio.autoplay = true;
                      //audio.play();

                      //setTimeout(() => {
                      const aud: HTMLAudioElement = this._audioPlayer.nativeElement;
                      if (aud.error) {
                        console.log("Audio err: " + aud.error.code);
                      }
                      //}, 2000);
                    }
                  }, 2000);
                }

              }
              if (this.isImageTypeFound) {
                this.fileType = 'image/png';
                //this.showPDF_or_ImageViewer = true;
                //this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(this.selectedUrl);
                setTimeout(() => {
                  this.setCanvasBackgroundImage(this.selectedUrl, true);
                }, 1000);


              }
            }
          } else if (response[0] == "Video") {
            this.selectedVideoUrl = response[1];
            try {
              this.video_html = this.embedVideoService.embed(
                this.selectedVideoUrl,
                {
                  query: { portrait: 0, color: "333" },
                  attr: { width: "100%" },
                }
              );
            } catch (ex) {
              console.log(ex);
            }
          }
          else if (response[0] == "nodocument") {
            this.showWarning = true;
            this.warningHeading = "No Document Found!";
        this.warningMsg =
          "Document does not exist.";
          }
        },
        error: (error) => {
          if (error.status == 401) {
            this.showWarning = true;
            this.warningHeading = error.statusText;
            this.warningMsg = error.error;
            //this._sharedService.showToast({ severity: 'error', summary: error.statusText, detail: error.error });
          } else if (error.status == 404) {
            this.showWarning = true;
            this.warningHeading = error.statusText;
            this.warningMsg = error.error;
            //this._sharedService.showToast({ severity: 'error', summary: error.statusText, detail: error.error });
          }
          //this._router.navigate(['record']);
          //console.log(error);
        }
      });
  }


  initCanvas(isFullScreen: boolean = false) {
    if (this.canvas) {
      this.canvas.clear();
      //this.canvas.dispose();
    }
    this.canvas = new fabric.Canvas('statusCanvas', { selection: false });

    //Reset Canvas Width
    this.canvas.setWidth(0);
    this.canvas.setHeight(0);
    this.zoomPercentage = 100;
    //Set Canvas Width
    let selectedElement = document.getElementById('documentview');
    if (!isFullScreen) {
      this.canvas.setWidth(Math.floor(selectedElement.clientWidth));
      this.canvas.setHeight(Math.floor(selectedElement.clientHeight - 25));
      if (selectedElement.classList.contains('fullscreen')) { selectedElement.classList.remove("fullscreen"); }
    } else {
      if (!selectedElement.classList.contains('fullscreen')) { selectedElement.classList.add("fullscreen"); }
      this.canvas.setWidth(Math.floor(selectedElement.clientWidth));
      this.canvas.setHeight(Math.floor(selectedElement.clientHeight));
    }

    this.canvas.preserveObjectStacking = true;

    //  this.populateMarkerType();

    // //this.initMouseZoomEvent()
    // this.initMouseDownEvent();
    // this.initMouseMoveEvent();
    // this.initObjectMovingEvent();
    // this.initObjectScalingEvent();
    // this.initMouseUpEvent();
    // this.initPathCreatedEvent();
    // this.initMouseOverEvent();
    // this.initMouseOutEvent();
  }



  setCanvasBackgroundImage(imageUrl: any, initCanvas: boolean = false) {

    if (initCanvas) { this.initCanvas(); }

    if (imageUrl.indexOf('api/Blob/GetDocument/') != -1) {

      this._azureBlobService.getPDFBlob(imageUrl)
        .subscribe({
          next: (res) => {
            let safeurl = this.sanitiz.bypassSecurityTrustResourceUrl(URL.createObjectURL(res));
            let url = URL.createObjectURL(res);

            fabric.util.loadImage(url, (img) => {
              if (!img) { return; }
              this.currentImg = img;
              let docPanal = document.getElementById('documentview');

              let canvasDiv = document.getElementById('statusCanvasDiv');
              canvasDiv.style.width = Math.floor(docPanal.clientWidth).toString() + 'px';
              canvasDiv.style.height = Math.floor(docPanal.clientHeight - 25).toString() + 'px';

              if (docPanal.clientWidth > this.currentImg.width) {
                this.canvas.setWidth(Math.floor(docPanal.clientWidth));
                canvasDiv.style.overflowX = 'hidden';
              } else {
                this.canvas.setWidth(Math.floor(this.currentImg.width));
                canvasDiv.style.overflowX = 'scroll';
              }

              if (docPanal.clientHeight > this.currentImg.height) {
                this.canvas.setHeight(Math.floor(docPanal.clientHeight));
                canvasDiv.style.overflowY = 'hidden';
              } else {
                this.canvas.setHeight(Math.floor(this.currentImg.height));
                canvasDiv.style.overflowY = 'scroll';
              }

              let imgObj = new fabric.Image(img);
              this.canvas.setBackgroundImage(imgObj, this.canvas.renderAll.bind(this.canvas), {
                // width: this.canvas.width,
                // height: this.canvas.height,
                originX: 'left',
                originY: 'top',
                // scaleX: this.canvas.width / img.width,
                // scaleY: this.canvas.height / img.height
              });

            }, { crossOrigin: 'anonymous' });
          },
          error: (err) => {
            console.log(err);
          }
        });
    } else {

      imageUrl = imageUrl.indexOf('?') == -1 ? imageUrl : imageUrl.split('?')[0];
      this._azureBlobService.getBlob(imageUrl)
        .subscribe({
          next: (res) => {
            //let safeurl = this.sanitiz.bypassSecurityTrustResourceUrl(URL.createObjectURL(res));
            let url = URL.createObjectURL(res);

            fabric.util.loadImage(url, (img) => {
              if (!img) { return; }
              this.currentImg = img;
              let docPanal = document.getElementById('documentview');

              let canvasDiv = document.getElementById('statusCanvasDiv');
              canvasDiv.style.width = Math.floor(docPanal.clientWidth).toString() + 'px';
              canvasDiv.style.height = Math.floor(docPanal.clientHeight - 25).toString() + 'px';

              if (docPanal.clientWidth > this.currentImg.width) {
                this.canvas.setWidth(Math.floor(docPanal.clientWidth));
                canvasDiv.style.overflowX = 'hidden';
              } else {
                this.canvas.setWidth(Math.floor(this.currentImg.width));
                canvasDiv.style.overflowX = 'scroll';
              }

              if (docPanal.clientHeight > this.currentImg.height) {
                this.canvas.setHeight(Math.floor(docPanal.clientHeight));
                canvasDiv.style.overflowY = 'hidden';
              } else {
                this.canvas.setHeight(Math.floor(this.currentImg.height));
                canvasDiv.style.overflowY = 'scroll';
              }

              let imgObj = new fabric.Image(img);
              this.canvas.setBackgroundImage(imgObj, this.canvas.renderAll.bind(this.canvas), {
                // width: this.canvas.width,
                // height: this.canvas.height,
                originX: 'left',
                originY: 'top',
                // scaleX: this.canvas.width / img.width,
                // scaleY: this.canvas.height / img.height
              });

            }, { crossOrigin: 'anonymous' });
          },
          error: (err) => {
            console.log(err);
          }
        });
    }
  }

  load_VM_AudioVideo(url: string): any {
    this._azureBlobService.getPDFBlob(url)
      .subscribe({
        next: (res) => {
          this.fileType = res.type;
          this.selectedUrl = URL.createObjectURL(res);

          if (this.fileType.indexOf('video/') != -1) {

            this.isVideoTypeFound = this._sharedService.allowedVideoFileTypes.filter(x => x == this.fileType)[0];
            if (this.isVideoTypeFound) {

              setTimeout(() => {
                if (this._videoPlayer) {
                  const video: HTMLVideoElement = this._videoPlayer.nativeElement;
                  video.setAttribute('src', this.selectedUrl);
                  video.setAttribute('type', this.fileType);
                  video.load();
                  video.play();

                  setTimeout(() => {
                    const vid: HTMLVideoElement = this._videoPlayer.nativeElement;
                    if (vid.error) {
                      console.log("Video err: " + vid.error.code);
                    }
                  }, 2000);
                }
              }, 2000);
            }
            else {
              this.isNonPreviewTypeFound = this.fileType;
            }
          }
          else {

            this.isAudioTypeFound = this._sharedService.allowedAudioFileTypes.filter(x => x == this.fileType)[0];
            if (this.isAudioTypeFound) {
              setTimeout(() => {
                if (this._audioPlayer) {
                  const audio: HTMLAudioElement = this._audioPlayer.nativeElement;
                  audio.setAttribute('src', this.selectedUrl);
                  audio.setAttribute('type', this.fileType);
                  audio.load();
                  audio.play();

                  //setTimeout(() => {
                  const aud: HTMLAudioElement = this._audioPlayer.nativeElement;
                  if (aud.error) {
                    console.log("Audio err: " + aud.error.code);
                  }
                  //}, 2000);
                }
              }, 2000);
            }
            else {
              this.isNonPreviewTypeFound = this.fileType;
            }
          }
        },
        error: (err) => {
          this.selectedUrl = undefined;
          //.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(undefined);
          console.log(err);
          //        // ERROR HANDLING HERE
        }
      })
  }
  populateZipFile() {
    let docpath = this._sharedService.documentUrl.replace("?", "");
    let validateRole: boolean = !this._sharedService.UserRole.All_Aircraft;


    this.showZipFileMsg = true;
    this.zipFileMsg = "Your Zip file is being downloading! If downloading doesn’t start automatically in few second please click on the “pop-up-blocked” icon located at the right corner of the search bar and select always allow option.";
    this._azureBlobService.getZipBlobUrl(docpath, validateRole)
      .subscribe({
        next: (response) => {
          if (response != null) {

            const fileNameArr = this._sharedService.documentUrl.split("\\");
            const fileName: string = fileNameArr[fileNameArr.length - 1];

            this.showZipFileMsg = true;
            this.zipFileMsg = "Your Zip file is being downloading. If downloading doesn’t start automatically in few second please click on the “pop-up-blocked” icon located at the right corner of the search bar and select always allow option.";

            this.selectedUrl = null;///response[1];
            try {
              const blob = new Blob([response], {
                type: "application/zip",
              });
              //const url = window.URL.createObjectURL(blob);
              //window.open(url);

              const objectUrl: string = URL.createObjectURL(blob);
              const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

              a.href = objectUrl;
              a.download = fileName;
              document.body.appendChild(a);
              a.click();

              document.body.removeChild(a);
              URL.revokeObjectURL(objectUrl);


            } catch (ex) {
              console.log(ex);
            }
          }
        },
        error: (error) => {
          if (error.status == 401) {
            this.showWarning = true;
            this.warningHeading = error.statusText;
            this.warningMsg = error.error;
            //this._sharedService.showToast({ severity: 'error', summary: error.statusText, detail: error.error });
          } else if (error.status == 404) {
            this.showWarning = true;
            this.warningHeading = error.statusText;
            this.warningMsg = error.error;
            //this._sharedService.showToast({ severity: 'error', summary: error.statusText, detail: error.error });
          }
          //this._router.navigate(['record']);
          //console.log(error);
        }
      });
  }

  loadImage(url: string): any {
    this._azureBlobService.getPDFBlob(url)
      .subscribe({
        next: (res) => {
          this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(
            URL.createObjectURL(res)
          );
        },
        error: (err) => {
          this.selectedUrl = undefined;
          //.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(undefined);
          console.log(err);
          //        // ERROR HANDLING HERE
        }
      });
  }

  DownloadBlob() {
    this.showZipFileMsg = true;
    this.zipFileMsg = "Your file is being downloading. If downloading doesn’t start automatically in few second please click on the “pop-up-blocked” icon located at the right corner of the search bar and select always allow option.";
    if (this.selectedUrl.indexOf('?sv=') != -1) {
      let urlSegments = this.selectedUrl.split('?');
      var fileNameWOExt = "";
      if (urlSegments.length == 2) {
        fileNameWOExt = this.selectedUrl.split('?')[0].split("/").pop();////urlSegments[0].split(".").pop();
      }
      else {
        //to do
        fileNameWOExt = urlSegments[0].split("/").pop();
      }
      //var fileNameWOExt = this.selectedUrl.split('.')[0];
      this.downloadBlobFromStorage(this.selectedUrl, fileNameWOExt);
    }
    else {
      //this._sharedService.showLoader("Processing...");
      this._azureBlobService.getPDFBlob(this.selectedUrl)
        .subscribe({
          next: (res) => {
            //this._sharedService.hideLoader();
            let blob = new Blob([res], { type: res.type });
            let url = URL.createObjectURL(blob);

            const fileNameArr = this.selectedUrl.split("\\");
            const fileName: string = fileNameArr[fileNameArr.length - 1];

            this.downloadBlobFromStorage(url, fileName);
          },
          error: (err) => {
            //this._sharedService.hideLoader();
            console.log(err);
          }
        });
    }
  }


  downloadBlobFromStorage(fileURL: string, fileName: string) {
    var a = document.createElement("a");
    a.style.display = "none";
    a.href = fileURL;
    a.target = '_blank';
    a.download = fileName;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
    URL.revokeObjectURL(fileURL);
  }
  //#endregion
}
