import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {SharedService } from '../../../shared/services/shared.service';

@Injectable()
export class AuditService {

  

    constructor(private _sharedService: SharedService ,  private http: HttpClient) {
    }
  
    getRecordHistory(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Audit/GetRecordHistory`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getRecordDetail(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Audit/GetRecordDetail`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getAuditData(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Audit/GetAuditData`, body, this._sharedService.getReqOptWithAccessToken());
    }



}