//Components
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, Params, NavigationEnd, NavigationStart } from '@angular/router';
import { GridOptions, GridApi, ColumnApi,AgEvent,AgGridEvent } from "ag-grid-community";

@Component({
    selector: 'checkboxComp-unique',
    changeDetection:ChangeDetectionStrategy.OnPush,
    templateUrl: './checkbox-unique.component.html',

})
export class CheckboxUniqueComponent implements OnInit {
    index: number = 0;
    currentValue: boolean = false;
    paramsGlobal: any;
    selectedorder: any;
    constructor()
    {

    }
    agInit(params:any)
    {
        this.paramsGlobal = params;
        this.currentValue = params.data.UniqueKey;
     //   console.log(this.currentValue);
        this.index = params.data.FieldOrder;
     //   console.log(this.index);

    }
    updateCheckbox(index: any, currentValue: any, event: any)
    {
        this.paramsGlobal.context.gridCtx.updateRowNode(index,"uniqueKey");
     //   console.log('Index::' + index + ' CurrentValue:' + currentValue + 'Event:'+event);
    }
    ngOnInit()
    {
    }

}