export class Role {
    public Records_GEN_Doc_Bulk_Upload: boolean = false;
    public Records_GEN_Doc_Delete: boolean = false;
    public Records_GEN_Doc_Edit_Metadata: boolean = false;
    public Records_MXR_Con_Admin: boolean = false;
    public Records_GEN_Con_Add: boolean = false;
    public All_Aircraft: boolean = false;
    public Records_GEN_Readonly: boolean = false;
    public Records_MXR_Con_Add: boolean = false;
    public Records_BND_Move_Container: boolean = false;
    public Records_GEN_Move_Container: boolean = false;
    public Records_LOG_Move_Container: boolean = false;
    public Records_MXR_Move_Container: boolean = false;
    public Records_DOC_Move_Container: boolean = false;
    public Records_LOG_Readonly: boolean = false;
    public Records_OCRSearch_Edit_Metadata: boolean = false;
    public Records_LOG_Con_Admin: boolean = false;
    public Records_MXR_Doc_Delete: boolean = false;
    public Records_MXR_Doc_Edit_Metadata: boolean = false;
    public Admin_User: boolean = false;
    public Records_LOG_Doc_Single_Upload: boolean = false;
    public Admin_Aircraft: boolean = false;
    public Records_MXR_Doc_Edit_PDF: boolean = false;
    public Records_LOG_Doc_Edit_PDF: boolean = false;
    public Admin_Doctype: boolean = false;
    public Records_GEN_Con_Edit: boolean = false;
    public Records_MXR_Readonly: boolean = false;
    public Records_LOG_Con_Edit: boolean = false;
    public Records_MXR_Doc_Single_Upload: boolean = false;
    public Records_MXR_Doc_Bulk_Upload: boolean = false;
    public Records_GEN_Doc_Single_Upload: boolean = false;
    public Records_LOG_Con_Add: boolean = false;
    public Records_LOG_Doc_Bulk_Upload: boolean = false;
    public Records_OCRSearch_Readonly: boolean = false;
    public Admin_Container_Type_Management: boolean = false;
    public Records_MXR_Con_Edit: boolean = false;
    public Records_LOG_Doc_Edit_Metadata: boolean = false;
    public Records_LOG_Doc_Delete: boolean = false;
    public Records_GEN_Con_Admin: boolean = false;
    public Records_GEN_Doc_Edit_PDF: boolean = false;
    public Records_DOC_Doc_Edit_PDF: boolean = false;
    public Records_MXR_Doc_CopyMove: boolean = false;
    public Records_LOG_Doc_CopyMove: boolean = false;
    public Records_GEN_Doc_CopyMove: boolean = false;
    public DP_Jobs_Readonly: boolean = false;
    public DP_Jobs_Add: boolean = false;
    public DP_Jobs_Edit: boolean = false;
    public API_Import_Readonly: boolean = false;
    public Import_Add: boolean = false;
    public Import_Edit: boolean = false;
    public Import_Log: boolean = false;
    public Import_Mapping: boolean = false;
    public Status_Builder_Readonly: boolean = false;
    public Admin_Status_Builder: boolean = false;
    public Status_Row_Edit_DFPStatus: boolean = false;
    public Status_Row_Edit: boolean = false;
    public Status_Row_LinkDocument: boolean = false;
    public Status_Row_Batch_LinkDocument: boolean = false;
    public Status_Row_Issue: boolean = false;
    public Status_Readonly: boolean = false;
    public Status_Row_History: boolean = false;
    public Status_Row_Add: boolean = false;
    public Status_Row_Delete: boolean = false;
    public Records_MXR_Doc_LinkStatus: boolean = false;
    public Records_LOG_Doc_LinkStatus: boolean = false;
    public Records_GEN_Doc_LinkStatus: boolean = false;
    public Records_DOC_Doc_LinkStatus: boolean = false;
    public Records_OCRSearch_Doc_LinkStatus: boolean = false;
    public Comm_Add: boolean = false;
    public Comm_Edit: boolean = false;
    public Comm_Readonly: boolean = false;
    public Dashboard_Records: boolean = false;
    public Dashboard_Status: boolean = false;    
    public Dashboard_Repairs: boolean = false;
    public Dashboard_Comms: boolean = false;
    public Dashboard_Statistics: boolean = false;
    public Admin_Sys_Admin: boolean = false;
    public Export_Records: boolean = false;
    public Export_Status: boolean = false;
    public Import_Status: boolean = false;
    public Admin_Settings: boolean = false;
    public Status_Dashboard: boolean = false;

    public API_Export_Readonly: boolean = false;
    public Export_Add: boolean = false;
    public Export_Edit: boolean = false;
    public Export_Log: boolean = false;
    public Export_Mapping: boolean = false;
    public Export_Admin: boolean = false;
    public Import_Admin: boolean = false;
    public Export_Delete: boolean = false;
    public Import_Delete: boolean = false;

    public Admin_Audit: boolean = false;
    public Status_Row_Audit: boolean = false;


    public Admin_Search_Synonym_Management: boolean = false;



    public Admin_UserGroup: boolean = false;
    public Admin_Tag: boolean = false;
    public Admin_DFP: boolean = false;
    public Admin_Role_Group: boolean = false;

    public Records_MXR_Link_To_External_System: boolean = false;
    public Records_LOG_Link_To_External_System: boolean = false;
    public Records_GEN_Link_To_External_System: boolean = false;
    public Records_DOC_Link_To_External_System: boolean = false;

    public Records_SingleDocument_View: boolean = false;
    public Records_MXR_WO_GAP_Analysis: boolean = false;

    public Records_GEN_Doc_Detach: boolean = false;
    public Records_DOC_Doc_Detach: boolean = false;
    public Records_LOG_Doc_Detach: boolean = false;
    public Records_MXR_Doc_Detach: boolean = false;
    public Records_OCRSearch_Doc_Detach: boolean = false;
    public Status_Row_Doc_Detach: boolean = false;
    public Status_Row_Doc_Upload: boolean = false;
    public Status_Row_Doc_Edit_MetaData: boolean = false;

    // public Status_API_Incoming_Readonly: boolean = false;
    // public Status_API_Incoming_Add: boolean = false;
    // public Status_API_Incoming_Delete: boolean = false;
    // public Status_API_Incoming_Edit: boolean = false;
    // public Status_API_Incoming_Log: boolean = false;
    // public Status_API_Incoming_Mapping: boolean = false;

    public Admin_Quality_Status: boolean = false;

    public Records_MXR_Doc_Quality_Status = false;
    public Records_LOG_Doc_Quality_Status = false;
    public Records_GEN_Doc_Quality_Status = false;
    public Records_DOC_Doc_Quality_Status = false;

    public Records_MXR_Container_Quality_Status = false;
    public Records_LOG_Container_Quality_Status = false;
    public Records_GEN_Container_Quality_Status = false;
    public Records_DOC_Container_Quality_Status = false;



    public Admin_Marker_Type = false;
    public Admin_View_Data_Type = false;

    //Repair Module
    public Repairs_Row_Edit_DFPStatus: boolean = false;
    public Repairs_Row_Edit: boolean = false;
    public Repairs_Row_LinkDocument: boolean = false;
    public Repairs_Row_Batch_LinkDocument: boolean = false;
    public Repairs_Row_Issue: boolean = false;
    public Repairs_Row_History: boolean = false;
    public Repairs_Row_Add: boolean = false;
    public Repairs_Row_Delete: boolean = false;
    public Repairs_Row_Doc_Detach: boolean = false;
    public Repairs_Row_Doc_Upload: boolean = false;
    public Repairs_Row_Doc_Edit_MetaData: boolean = false;
    public Repairs_Row_Audit: boolean = false;

    //Container - Document Catergory
    public Records_DOC_Con_Add: boolean = false;
    public Records_DOC_Con_Edit: boolean = false;
    public Records_DOC_Con_Admin: boolean = false;
    public Records_DOC_Readonly: boolean = false;

    //Document - Document Catergory
    //public Records_DOC_Doc_Quality_Status: boolean = false;
    public Records_DOC_Doc_Bulk_Upload: boolean = false;
    public Records_DOC_Doc_Edit_Metadata: boolean = false;
    public Records_DOC_Doc_CopyMove: boolean = false;
    //public Records_DOC_Doc_Edit_PDF: boolean = false;
    public Records_DOC_Doc_Single_Upload: boolean = false;
    //public Records_DOC_Doc_Detach: boolean = false;
    //public Records_DOC_Doc_LinkStatus: boolean = false;
    public Records_DOC_Doc_Delete: boolean = false;
    public Admin_Asset_Group: boolean = false;


    public Transition_BND_Doc_Quality_Status: boolean = false;
    public Transition_BND_Container_Quality_Status: boolean = false;
    public Transition_BND_Doc_Bulk_Upload: boolean = false;
    public Transition_BND_Readonly: boolean = false;
    //public Transition_BND_Link_To_External_System: boolean = false;
    public Transition_BND_Doc_Edit_Metadata: boolean = false;
    public Transition_BND_Doc_CopyMove: boolean = false;
    public Transition_BND_Doc_Edit_PDF: boolean = false;
    public Transition_BND_Doc_Single_Upload: boolean = false;
    public Transition_BND_Con_Admin: boolean = false;
    public Transition_BND_Con_Add: boolean = false;
    public Transition_BND_Doc_Detach: boolean = false;
    public Transition_BND_Con_Edit: boolean = false;
    public Transition_BND_Doc_LinkStatus: boolean = false;
    public Transition_BND_Doc_Delete: boolean = false;


    public Transition_OCRSearch_Readonly: boolean = false;
    public Transition_OCRSearch_Edit_Metadata: boolean = false;
    public Transition_OCRSearch_Doc_LinkStatus: boolean = false;
    public Transition_OCRSearch_Doc_Detach: boolean = false;

}