//#region References
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SharedService } from '../../shared/services/shared.service';


//#endregion

@Injectable()
export class ReportService {

    constructor(private http: HttpClient, private _sharedService: SharedService) {
    }

    getAssetReport(body): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Report/GetAssetReport`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getJobReport(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Report/GetJobReport`, null, this._sharedService.getReqOptWithAccessToken());
    }

    runCopyDataUtility(body): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Report/RunCopyData`, body, this._sharedService.getReqOptWithAccessToken());
    }
    
    getAuthTokenForCosmos() {

        return this.http.post(`${this._sharedService.baseUrl}/api/Report/GetAuthenticationTokenForCosmos`, null, this._sharedService.getReqOptWithAccessToken());
    }

}