<div class="jarviswidget-ctrls" role="menu" title="View feed data">
    <a class="button-icon  tablIndexFocus"  [attr.tabindex]="0"  (keyup.enter)="openLogViewDialogue()"  id="btnlogview" (click)="openLogViewDialogue()">
        <i class="glyphicon glyphicon-file"></i>
    </a>
</div>

<div>
    <p-dialog id="feedlogdetails" [(visible)]="display" showEffect="fade" [modal]="true" [style]="{width: '100vw',top:'5px'}" contentStyleClass="no-overflow-y" [resizable]="false" [blockScroll]="true">
        <ng-template pTemplate="header" >
            Log Details
        </ng-template>
        <viewlist [feedlogid]="feedlogid" *ngIf="display" [isFromAPIModule]="currentModule"></viewlist>        
    </p-dialog>
</div>

