<div class="jarviswidget-ctrls" role="menu">
    <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="showEditDialog()" id="btnedit"
        (click)="showEditDialog()" title="Edit Container (Ctrl+E)">
        <i class="glyphicon glyphicon-pencil"></i>
    </a>
</div>

<p-confirmDialog *ngIf="display" header="Are you sure?" key="confirmdelete" icon="fa fa-question-circle"
    [style]="{width: '435px'}"></p-confirmDialog>
<p-confirmDialog *ngIf="display" header="Delete Confirmation" key="reconfirmdelete" icon="fa fa-trash"
    [style]="{width: '435px'}" id="reconfirmdeleteid"></p-confirmDialog>
<form (ngSubmit)="onSubmit(heroEditForm)" #heroEditForm="ngForm" *ngIf="display">
    <p-dialog [(visible)]="display" showEffect="fade" [modal]="true" [style]="{width: '733px'}" [resizable]="false">
        <ng-template pTemplate="header"  (keyup.shift.tab)="onKey()">
            {{dialogTitle}}
        </ng-template>
        <div class="upload-dialog">
            <!--style="overflow-y: hidden !important;overflow-x:hidden !important"-->
            <div class="smart-form">
                <fieldset [ngClass] = " (recordType == 'LOG' || recordType == 'MXR' || recordType == 'GEN') ? 'fieldsetnormal' : 'fieldsethide' ">
                    <div class="row">

                        <div class="form-group" [ngClass]="{'col-sm-12': recordType=='LOG','col-sm-6':recordType!='LOG'}">
                            <fieldset>
                                <label class="label">
                                    <!-- Type -->
                                    {{_sharedService.getContainerLabel(recordType, "Type")}}
                                    <i class='fa fa-copy fa-lg pull-right' pTooltip="{{model.generalID}}" ngxClipboard
                                        [cbContent]="model.generalID" *ngIf="recordType=='LOG'"></i>
                                </label>
                                <label class="input">
                                    <input id="wotype" [attr.tabindex]="-1" type="text" name="wotype" class="readonlyinput2"
                                        [(ngModel)]="model.wotype" #wotype="ngModel" [disabled]="true">
    
                                </label>
    
                            </fieldset>
                        </div>
                        <div class="form-group col-sm-6" *ngIf="recordType!='LOG'">
    
                            <fieldset>
    
                                <label class="label">
                                    <!-- {{WorkOrderLabel}} -->
                                    {{_sharedService.getContainerLabel(recordType, "ReferenceKey")}}
                                    <i class='fa fa-copy fa-lg pull-right' pTooltip="{{model.generalID}}" ngxClipboard
                                        [cbContent]="model.generalID"></i>
    
                                </label>
                                <label class="input">
                                    <input id="wonumber" type="text" name="wonumber" [attr.tabindex]="-1"
                                        [(ngModel)]="model.wonumber" #wonumber="ngModel" class=""
                                        [ngClass]="{'readonlyinput2': (recordType!=='DOC' && recordType!=='BND')}"
                                        style="max-width: 680px !important"
                                        [disabled]="(recordType!=='DOC' && recordType!=='BND')">
                                </label>
                            </fieldset>
    
                        </div>


                    </div>
    
                    <div class="row">
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">
                                    <!-- Date  -->
                                    {{_sharedService.getContainerLabel(recordType, "Date")}}
                                    ({{ViewDateFormat}})
                                </label>
                                <label class="input"
                                    [ngClass]="{'readonlyinput': (recordType=='MXR' ||recordType=='LOG')&& (recordType!=='DOC' && recordType!=='BND')}">
                                    <i class='icon-append fa fa-calendar'></i>
                                    <p-calendar appendTo="body" (onClose)="onCloseDate()" placeholder="{{ViewDateFormat}}"
                                        [attr.tabindex]="-1" class='datepicker hasDatepicker' id='crsdate' name="crsdate"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030"
                                        [disabled]="(recordType!=='DOC' && recordType!=='BND')" [(ngModel)]="model.crsdate"
                                        #crsdate="ngModel" [dateFormat]="dateFormatCalender" [style]="{width:'315px'}">
                                        <!--[required]="recordType=='GEN'"-->
                                    </p-calendar>
    
                                </label>
    
                            </fieldset>
    
                        </div>
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label enddate">
                                    <!-- End Date  -->
                                    {{_sharedService.getContainerLabel(recordType, "EndDate")}}
                                    ({{ViewDateFormat}})
                                </label>
                                <label class="input"
                                    [ngClass]="{'readonlyinput': (recordType=='MXR' ||recordType=='LOG')&& (recordType!=='DOC' && recordType!=='BND')}">
                                    <i class='icon-append fa fa-calendar'></i>
    
                                    <p-calendar appendTo="body" placeholder="{{ViewDateFormat}}" [attr.tabindex]="-1"
                                        (onClose)="onCloseEnddate()" class='datepicker hasDatepicker' id='enddate'
                                        name="enddate" [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030"
                                        [disabled]="(recordType!=='DOC' && recordType!=='BND') && (recordType=='MXR' ||recordType=='LOG')"
                                        [(ngModel)]="model.enddate" #enddate="ngModel" [dateFormat]="dateFormatCalender"
                                        [style]="{width:'315px'}">
    
                                    </p-calendar>
                                </label>
                            </fieldset>
                        </div>
                    </div>
                    <div *ngIf="recordType == 'LOG' || recordType == 'MXR' || recordType == 'GEN'" style="text-align: right;">
                    <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex custom-button" label="Update" (click)="OnDateChange(heroEditForm)"
                    [disabled]="!heroEditForm.form.valid || isSubmitted || (!isEditVisible) || (recordType=='LOG' && model.IncrementValue <= 0) || (recordType=='LOG' && (model.StartNumber < 0 || model.NumberOfPages < 0 ))"></button>
                    </div>
                </fieldset>
                <!--<div class="row" *ngIf="recordType!='LOG'">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">WO Title</label>
                            <label class="input">
                                <input id="wonumber" type="text" name="wonumber"
                                        [(ngModel)]="model.wonumber" #wonumber="ngModel" class="readonlyinput" style="max-width: 680px !important" [disabled]="true">
                            </label>
                        </fieldset>
                    </div>
                </div>-->
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                <!-- Description -->
                                {{_sharedService.getContainerLabel(recordType, "Description")}}
                            </label>
                            <label class="input">
                                <span id="desr" [attr.tabindex]="0">
                                    <input id="description" #focus type="text" name="description"
                                        placeholder="Description" [(ngModel)]="model.description" #description="ngModel"
                                        style="max-width: 100% !important">
                                </span>
                            </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                <!-- Box Type -->
                                {{_sharedService.getContainerLabel(recordType, "BoxType")}}
                            </label>
                            <label class="input">
                                <p-autoComplete id="boxtype" name="boxtype" placeholder="Box Type"
                                    [(ngModel)]="model.boxtype" [suggestions]="boxTypeSuggestion"
                                    (completeMethod)="searchBoxType($event)" #boxtype="ngModel" [style]="{width:'100%'}"
                                    scrollHeight="130px"></p-autoComplete>

                            </label>
                        </fieldset>
                    </div>

                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                <!-- Box ID -->
                                {{_sharedService.getContainerLabel(recordType, "BoxID")}}
                            </label>
                            <label class="input">
                                <p-autoComplete id="boxid" name="boxid" placeholder="Box ID" [(ngModel)]="model.boxid"
                                    [suggestions]="boxIDSuggestion" (completeMethod)="searchBoxID($event)"
                                    #boxid="ngModel" [style]="{width:'100%'}" scrollHeight="130px"></p-autoComplete>

                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                <!-- Box Location -->
                                {{_sharedService.getContainerLabel(recordType, "BoxLocation")}}
                            </label>
                            <label class="input">
                                <p-autoComplete id="boxlocation" name="boxlocation" placeholder="Box Location"
                                    [(ngModel)]="model.boxlocation" [suggestions]="boxLocationSuggestion"
                                    (completeMethod)="searchBoxLocation($event)" #boxlocation="ngModel"
                                    [style]="{width:'100%'}" scrollHeight="130px"></p-autoComplete>
                            </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                <!-- Scanned On  -->
                                {{_sharedService.getContainerLabel(recordType, "ScannedOn")}} ({{ViewDateFormat}})
                            </label>
                            <label class="input">
                                <i class='icon-append fa fa-calendar'></i>
                                <p-calendar appendTo="body" placeholder="{{ViewDateFormat}}" class='datepicker hasDatepicker'
                                    (onClose)="onCloseScannedOn()" id='scanneddate' name="scanneddate"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030"
                                    [(ngModel)]="model.scanneddate" #scanneddate="ngModel"
                                    [dateFormat]="dateFormatCalender" [style]="{width:'93%'}">
                                </p-calendar>
                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6 zindex">
                        <fieldset>
                            <label class="label">
                                <!-- Scanned By -->
                                {{_sharedService.getContainerLabel(recordType, "ScannedBy")}}
                            </label>
                            <label class="input minzindex">
                                <span id="scannedby" [attr.tabindex]="0">
                                    <p-autoComplete id="scannedby" name="scannedby" [(ngModel)]="model.scannedby"
                                        [suggestions]="scannedBySuggestion" (completeMethod)="searchScannedBy($event)"
                                        #scannedby="ngModel" [style]="{width:'100%'}" scrollHeight="67px">
                                    </p-autoComplete>
                                </span>
                            </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row" *ngIf="recordType=='LOG'">
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                <!-- IncrementValue -->
                                {{_sharedService.getContainerLabel(recordType, "IncrementValue")}}
                                <span [hidden]="model.IncrementValue>=1" class="text-danger">
                                    *
                                </span>
                            </label>
                            <label class="input">
                                <input id="IncrementValue" name="IncrementValue" type="number" requried
                                    placeholder="Increment Value" [(ngModel)]="model.IncrementValue"
                                    #IncrementValue="ngModel" [style]="{width:'100%'}"
                                    scrollHeight="130px">

                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                <!-- StartNumber -->
                                {{_sharedService.getContainerLabel(recordType, "StartNumber")}}
                            </label>
                            <label class="input">
                                <input id="StartNumber" name="StartNumber" type="number" placeholder="Start Number"
                                    [(ngModel)]="model.StartNumber"
                                    #StartNumber="ngModel" [style]="{width:'100%'}" scrollHeight="130px">

                            </label>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                <!-- NumberOfPages -->
                                {{_sharedService.getContainerLabel(recordType, "NumberOfPages")}}
                            </label>
                            <label class="input">
                                <input id="NumberOfPages" name="NumberOfPages" type="number" placeholder="Number of Pages"
                                    [(ngModel)]="model.NumberOfPages"
                                    #NumberOfPages="ngModel" [style]="{width:'100%'}" scrollHeight="130px">

                            </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row">


                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                <!-- Live -->
                                {{_sharedService.getContainerLabel(recordType, "Live")}}
                            </label>
                            <label class="checkbox">
                                <span class="onoffswitch tablIndexFocus" [attr.tabindex]="0"
                                    (keyup.enter)="ToggleChecked()">
                                    <input type="checkbox" name="isliveEdit" [checked]="isChecked"
                                        class="onoffswitch-checkbox" checked="checked" id="isliveEdit"
                                        [(ngModel)]="model.islive" #isliveEdit="ngModel" [disabled]="!isContainerAdmin">
                                    <label class="onoffswitch-label" for="isliveEdit">
                                        <span class="onoffswitch-inner" data-swchon-text="YES"
                                            data-swchoff-text="NO"></span><span class="onoffswitch-switch"></span>
                                    </label>
                                </span>
                            </label>
                        </fieldset>
                    </div>

                    <div class="form-group col-sm-6"
                        *ngIf="((canGENQualityStatus && processGENQualityStatusEnabled) || (canMXRQualityStatus && processMXRQualityStatusEnabled) || (canLOGQualityStatus && processLOGQualityStatusEnabled)|| (canDOCQualityStatus && processDOCQualityStatusEnabled)|| (canBNDQualityStatus && processBNDQualityStatusEnabled))">
                        <fieldset>
                            <label class="label">
                                <!-- Status  -->
                                {{_sharedService.getContainerLabel(recordType, "Status")}} <span
                                    class="text-danger">*</span>
                            </label>
                                <p-dropdown appendTo="body" [panelStyle]="{'bottom': 'calc(100% + 5px)'}" [options]="qualityStatusList" requried name="QualityStatusID"
                                    #QualityStatusID="ngModel" placeholder="Select Quality Status"
                                    [style]="{width:'300px'}" [(ngModel)]="model.QualityStatusID" [filter]="true">
                                </p-dropdown>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <p class="pull-left CreatedbyBottomText">Created by {{CreatedBy}} on {{CreatedOn}} </p>
                <button type="button" pButton icon="fa fa-trash" class="btn btn-danger minzindex p-button-danger"
                    label="Delete" (click)="confirmDeleteContainer(heroEditForm)"
                    [disabled]="!isContainerAdmin || isAssetReadOnly"></button>
                <button type="submit" pButton icon="fa fa-check" class="btn btn-primary minzindex" label="Update"
                    [disabled]="!heroEditForm.form.valid || isSubmitted || (!isEditVisible) || (recordType=='LOG' && model.IncrementValue <= 0) || (recordType=='LOG' && (model.StartNumber < 0 || model.NumberOfPages < 0 ))"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnCancelClick(heroEditForm)" label="Cancel" [disabled]="isSubmitted"
                    (focusout)="applyFocusToInput()"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>
<form #containerDeleteConfirmForm="ngForm">
    <p-dialog [(visible)]="displayDeleteConfirmDlg" showEffect="fade" [modal]="true"
        [style]="{width: '920px', height:'80vh'}" [resizable]="false">
        <ng-template pTemplate="header" >
            Following document(s) are attached with status rows. Do you want to delete?
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form" style="height: 60vh;">
                <ag-grid-angular id="containerDeleteConfirmGrid" #containerDeleteConfirmGrid
                    [gridOptions]="docDeleteConfirmGridOptions" [columnDefs]="docDeleteConfirmColumnDefs"
                    [rowData]="docDeleteConfirmGridData" style="width: 100% !important; height: 60vh !important;"
                    class="ag-theme-balham">
                </ag-grid-angular>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-trash" class="btn btn-primary minzindex"
                    (click)="deleteAllDocuments(false)" label="All"
                    title="Link with Status rows will be removed"></button>
                <button type="button" pButton icon="fa fa-trash" class="btn btn-primary minzindex"
                    (click)="deleteAllDocuments(true)" label="Remaining"
                    title="Documents having link with status rows will not deleted"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="displayDeleteConfirmDlg=!displayDeleteConfirmDlg" label="Cancel"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>