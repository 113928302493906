export class AssetModel {
    constructor(
        public AssetID?: string,
        public AssetType?: string,
        public ASN?: string,
        public Description?: string,
        public Manufacturer?: string,
        public Type?: string,
        public Varient?: string,
        public Order?: number,
        public Owner?: string,
        public parentAsset?: string,
        public PartNumber?: string,
        public Position?: string,
        public RegAuthority?: string,
        public RegNumber?: string,
        public StoragePath?: string,
        public Status?: boolean,
        public CreatedBy?: string,
        public CreatedOn?: Date,
        public ModifiedBy?: string,
        public ModifiedOn?: Date,
        public Cycle?: string,
        public Time?: string,
        public Level?: string,
        public MXRecords?: boolean,
        public AutoGapAnalyser?: boolean,
        public LogBooks?: boolean,
        public General?: boolean,
        public Documents?: boolean,
        public Binders?: boolean,
        public ReadOnly?: boolean,
        public SearchDatabaseID?: string,
        public ShouldStatusReflectToChilds?: boolean,
        public ShouldReadOnlyReflectToChilds?: boolean,
        public LinkAsset?: string,
        public PlanName?: string,
        public PlanRoles?: string,
        public PlanID?: number
        
    ) {
    }
}

export class MoveAssetModel {
    constructor(
        public SelectedAssetId_ToMove?: string,
        public SelectedAssetASN_ToMove?: string,
        public SelectedDestinationAssetId?: string
        // , // number,
        // public SelectedDestinationAssetASN?: string
    ) {
    }
}

export class AssetDependents {
    constructor(
        public CommsCount?: number,
        public DpJobsCount?: number,
        public TasksCount?: number,
        public TaskDocumentCount?: number,
        public BlobsCount?: string
    ) {
    }
}
