<section id="widget-grid" class="adminaircraftcls">

  <div class="row">
      <article class="col-sm-12 col-md-12 col-lg-12" id="col1">
          <!-- Widget ID (each widget will need unique ID)-->
          <div class="jarviswidget" id="wid-id-2" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false">

              <header>
               <span class="widget-icon"><i class="glyphicon glyphicon-list-alt"></i></span>
                  <h2>Sections</h2>

                  <add-section  [ArticleSectionID]="articleSectionId" (refreshGridEvent) = "handlereloadGrid($event)" ></add-section>
              </header>
              <!-- widget div-->
              <div class="widgetdiv">

                  <!-- widget edit box -->
                  <div class="jarviswidget-editbox">
                      <!-- This area used as dropdown edit box -->

                  </div>
                  <!-- end widget edit box -->
                  <!-- widget content -->
                  <div class="widget-body">
                      <!--
                      The ID "widget-grid" will start to initialize all widgets below
                      You do not need to use widgets if you dont want to. Simply remove
                      the <section></section> and you can use wells or panels instead
                      -->
                      <!-- widget grid -->
                      <section id="widget-grid2" class="">
                          <div class="jqgridboxadminaircraft">

                             <div class="jqgridboxadminaircraft" style="width: 100%;">
                                  <ag-grid-angular id="agGridArticleSection"   #agGridArticleSection [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="gridData" (selectionChanged)="handleSelectedArticleSection()" (rowDoubleClicked)="RowDoubleClicked($event)"  sortable="true" filter=true style="width: 100%;" class="ag-theme-balham documenttypegrid" >
                                  </ag-grid-angular>
                              </div>
                              <!-- end row -->
                          </div>
                      </section>
                      <!-- end widget grid -->
                  </div>
                  <!-- end widget content -->
              </div>
              <!-- end widget div -->
          </div>
          <!-- end widget -->
      </article>
      <!-- END COL -->
  </div>
</section>



