<form id="statusConfigDlg" #addForm="ngForm">
    <!-- style="height:650px;overflow-y:scroll;overflow-x:hidden" -->
    <header role="heading" class="tab-header">

        <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>

        <p-confirmDialog [style]="{width: '430px'}" #cd>
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="fa fa-check" label="Ok" (click)="cd.accept()"></button>
                <!--<button type="button" pButton icon="fa fa-close" label="Cancel" (click)="cd.reject()"></button>-->
            </ng-template>
        </p-confirmDialog>
    </header>
    <div class="row addaircraftcls">
        <article class="col-sm-12 col-md-12 col-lg-12" id="col2">
            <!-- Widget ID (each widget will need unique ID)-->

            <div class="jarviswidget" id="wid-id-0" data-widget-colorbutton="false" data-widget-editbutton="false"
                data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false">

                <header>
                    <span class="widget-icon"><i [className]="iconClass"></i></span>
                    <h2>Status - {{feedName}} - Configuration</h2>
                </header>

                <!-- widget div-->
                <div class="widgetdiv">
                    <!-- widget content -->
                    <div class="widget-body">
                        <!-- widget grid -->
                        <section id="widget-grid0" class="status-config-dialogue">
                            <div class="smart-form">
                                <div class="row">
                                    <div class="form-group col-sm-12">
                                        <table class="table table-bordered " id="tblStatusMapping">
                                            <thead>
                                                <tr>
                                                    <th><label class=""><input type="checkbox" name="chkIncludeAll"
                                                                id="chkIncludeAll" (change)="chechAll($event)"
                                                                [(ngModel)]="checkAll"><i></i></label></th>
                                                    <th>Asset</th>
                                                    <th>Views</th>
                                                    <th>DFP Status</th>
                                                    <th [width]="'90px'">More Filters</th>
                                                    <!-- <th *ngIf="feedType=='PDF' && feedTypeInput != 'ExportTemp'">File/Folder Name</th> -->
                                                    <th *ngIf="feedType=='PDF'">File/Folder Name</th>
                                                    <th *ngIf="feedType=='PDF'">Cover Letter</th>
                                                    <th *ngIf="feedType=='PDF'">Output File Merge options</th>
                                                </tr>
                                            </thead>
                                            <tbody id="statusBody">
                                                <tr *ngFor="let con of model.Map; let i = index; trackBy:trackByIndex">
                                                    <td style="vertical-align: middle;"><label class=""><input
                                                                type="checkbox" name="chkInclude_{{i}}"
                                                                id="chkInclude_{{i}}"
                                                                [(ngModel)]="con.isInclude"><i></i></label></td>
                                                    <td style="vertical-align: middle;"><label
                                                            id="asset_{{i}}">{{con.asset}}</label></td>
                                                    <td style="vertical-align: middle;"><label
                                                            id="view_{{i}}">{{con.view}}</label></td>
                                                    <td style="vertical-align: middle;">
                                                        <p-multiSelect id="dfpStatus_{{i}}" class="statusreport"
                                                            defaultLabel="All" name="dfpStatus_{{i}}"
                                                            [options]="dfpStatusList"
                                                            [(ngModel)]="con.selectedDFPStatus" [maxSelectedLabels]="0"
                                                            selectedItemsLabel="{0} items selected"
                                                            [style]="{width:'265px !important'}"
                                                            appendTo="body"></p-multiSelect>
                                                    </td>
                                                    <td style="vertical-align: middle;padding-right: 1.7%;">
                                                        <div class="jarviswidget-ctrls" id="morefilters" title="More Filters">
                                                            <a [attr.tabindex]="0" class="button-icon tablIndexFocus"
                                                                id="btnfield"
                                                                (keyup.enter)="openCloseMoreFiltersDialogue(true,con)"
                                                                (click)="openCloseMoreFiltersDialogue(true,con)">
                                                                <i class="glyphicon glyphicon-filter" [style]="setFilterStyle(con)"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <!-- <td *ngIf="feedType=='PDF' && feedTypeInput != 'ExportTemp'" style="vertical-align: middle;"> -->
                                                    <td *ngIf="feedType=='PDF'" style="vertical-align: middle;">
                                                        <p-dropdown appendTo="body" id="fieldDropdown_{{i}}"
                                                            name="fieldDropdown_{{i}}" [(ngModel)]="con.fileFieldName"
                                                            [options]="con.fieldList">
                                                        </p-dropdown>
                                                    </td>
                                                    <td *ngIf="feedType=='PDF'">
                                                        <fieldset>
                                                            <label for="chkCover_{{i}}" style="width: 2px;">
                                                                <span class="onoffswitch">
                                                                    <input type="checkbox" name="chkCover_{{i}}"
                                                                        class="onoffswitch-checkbox" checked="checked"
                                                                        id="chkCover_{{i}}" [(ngModel)]="con.fileCover">
                                                                    <label class="onoffswitch-label"
                                                                        for="chkCover_{{i}}">
                                                                        <span class="onoffswitch-inner"
                                                                            data-swchon-text="YES"
                                                                            data-swchoff-text="NO"></span><span
                                                                            class="onoffswitch-switch"></span>
                                                                    </label>
                                                                </span>
                                                            </label>
                                                        </fieldset>

                                                    <td *ngIf="feedType=='PDF'">
                                                        <fieldset>
                                                            <div class="inline-group" id="rdobutton_{{i}}">
                                                                <label class="radio">
                                                                    <input type="radio" name="astradio_{{i}}"
                                                                        checked="checked" [(ngModel)]="con.output"
                                                                        [value]="false">
                                                                    <i></i>Separate
                                                                </label>
                                                                <label class="radio">
                                                                    <input type="radio" name="astradio_{{i}}"
                                                                        [(ngModel)]="con.output" [value]="true">
                                                                    <i></i>Merged
                                                                </label>
                                                            </div>
                                                        </fieldset>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div class="widget-footer">

                            <p-dropdown appendTo="body" [panelStyle]="{'bottom': 'calc(100% + 5px)'}"
                                [style]="{'text-align':'left','max-width':'100%','width':'100%'}"
                                *ngIf="feedTypeInput=='ExportTemp'" class="col-md-2" [(ngModel)]="selectedDataType"
                                name='selectedDataType' [autofocus]="true" (onChange)="setFeedDataType($event)"
                                [options]="dataTypes">
                            </p-dropdown>
                            <!-- <button *ngIf="feedTypeInput=='ExportTemp'" type="button"
                            class="btn btn-primary margin-right-3px" (click)="confirmContainerExport()">
                            {{containerButtonLabel}}
                        </button> -->
                            <select-target-container class="margin-right-3px" *ngIf="feedTypeInput=='ExportTemp'"
                                [inputDataType]='selectedDataType'
                                [configurationSelectedRows]="configurationSelectedRows"
                                (targetContainer)="onTargetContainerChange($event)"></select-target-container>

                            <button *ngIf="feedTypeInput=='ExportTemp'" type="button"
                                class="btn btn-primary margin-right-3px custom-button" (click)="confirmGenrate()">
                                {{generateButtonLabel}}
                            </button>
                            <button type="button" class="btn btn-primary margin-right-3px custom-button"
                                (click)="confirmSave()">
                                {{buttonLabelName}}
                            </button>
                            <a class="btn btn-default" (click)="btnCancelClick()">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end widget -->
        </article>
        <!-- END COL -->
    </div>
</form>

<form #expiryDate="ngForm">
    <p-dialog [(visible)]="dateDisplay" showEffect="fade" [modal]="true"
        [style]="{'width': '328px','text-align':'center'}" [resizable]="false">
        <ng-template pTemplate="header" (keyup.shift.tab)="btnExpiryCancelClick()">
            Expiry Date
        </ng-template>
        <div>
            <div class="smart-form">
                <p-calendar appendTo="body" class='datepicker hasDatepicker audit-datepicker' [showTime]="true"
                    [(ngModel)]="sharedAccessExpiryTime" name="SharedAccessExpiryTime" [minDate]="minDateValue"
                    [maxDate]="maxDateValue" (onInput)='dateChange()' (onSelect)='dateChange()'
                    [dateFormat]="dateFormatCalender" [inline]="true">
                </p-calendar>

            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton class="btn btn-primary minzindex" (click)="btnExpiryTime()" label="Email"
                    [disabled]="emailButtonDisable"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>

<p-dialog contentStyleClass="no-overflow-y" id="statusExportDialogue" [(visible)]="displayStatusExport"
    *ngIf="displayStatusExport" showEffect="fade" [modal]="true" [style]="{width: '100vw'}" showHeader="true"
    [resizable]="false" [closeOnEscape]="false" (onHide)="openCloseMoreFiltersDialogue(false,_view)">
    <ng-template pTemplate="header">
        More Filters
    </ng-template>
    <div class="row">
        <div class="form-group col-sm-6" style="margin-top:10px;">
            <div class="panel panel-default" style="border-right-width: 2px;">
                <div class="panel-heading">
                    <h3 class="panel-title panalheading-padding">
                        Meet ALL of the following condition (AND)
                        <a class="button-icon hand-cursor pull-right tablIndexFocus"
                            style="padding-right: 5px;padding-top: 5px;" id="btnCndAdd" [attr.tabindex]="0"
                            (click)="addCondition()" (keyup.enter)="addCondition()">
                            <i class="glyphicon glyphicon-plus"></i>
                        </a>
                    </h3>
                </div>
                <div class="panel-body">
                    <div class="col-sm-12 picklist-padding fix-picklist-width">
                        <!--<div class="table-responsive" style="max-height: 185px;">-->
                        <table class="table table-hover">
                            <thead style="display: block !important; width: 100%;">
                                <tr>
                                    <!-- <th style="width: 24%">&nbsp;</th> -->
                                    <th style="width: 20%">Field Name</th>
                                    <!--<th>Field Label</th>-->
                                    <th style="width: 12%">Condition</th>
                                    <th style="width: 22.6%">Value</th>
                                    <th style="width: 1.5%">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody style="max-height: 185px;display: block !important;overflow-y: auto;"
                                id="configviewbody">
                                <tr *ngFor="let cnd of _view.Conditions; let j = index; trackBy:trackByIndex">
                                    <!-- <td style="width: 25.5%">
                                        <select *ngIf="j!=0" class="form-control" [attr.tabindex]="0"  id="{{'whereCondition' + j }}" name="{{'whereCondition' + j }}" [(ngModel)]="cnd.JoinCondition" style="width:200px;">
                                            <option *ngFor="let w of whereConditions;trackBy:trackByIndex" value="{{w.value}}">{{w.label}}</option>
                                        </select>
                                    </td> -->
                                    <td style="width: 25.5%">
                                        <!--<select class="form-control" id="{{'condFieldName' + j }}" name="{{'condFieldName' + j }}" [(ngModel)]="cnd.FieldName" style="width:200px;">
                                            <option *ngFor="let f of fieldsWithLable" value="{{f.value}}">{{f.label}}</option>
                                        </select>-->
                                        <p-dropdown [attr.tabindex]="0" appendTo="body" class="form-control"
                                            id="condFieldName{{j}}" name="condFieldName{{j}}"
                                            [options]="_view.fieldList" [editable]="false" [(ngModel)]="cnd.FieldName"
                                            placeholder="Select Field" [filter]="true"
                                            [panelStyle]="{'bottom': 'calc(100% + 5px)'}"></p-dropdown>
                                    </td>
                                    <!--<td><label id="{{'condFieldLabel' + j }}" type="text" name="{{'cndValue' + j }}" [(ngModel)]="cnd.FieldLabel" class="label"></label></td>-->
                                    <td *ngIf="cnd.FieldName" style="width: 25.5%">
                                        <select [attr.tabindex]="0" class="form-control" id="{{'condition' + j }}"
                                            name="{{'condition' + j }}" [(ngModel)]="cnd.ConditionType" required>
                                            <option *ngFor="let c of conditions;trackBy:trackByIndex"
                                                value="{{c.value}}">{{c.label}}</option>
                                        </select>
                                    </td>
                                    <td *ngIf="!cnd.FieldName" style="width: 25.5%">
                                        <select [attr.tabindex]="0" class="form-control" id="{{'condition' + j }}"
                                            name="{{'condition' + j }}" [(ngModel)]="cnd.ConditionType">
                                            <option *ngFor="let c of conditions;trackBy:trackByIndex"
                                                value="{{c.value}}">{{c.label}}</option>
                                        </select>
                                    </td>
                                    <!-- <ng-template #showBlock>
                                                <select [attr.tabindex]="0"  class="form-control" id="{{'condition' + j }}" name="{{'condition' + j }}" [(ngModel)]="cnd.ConditionType" required >
                                                    <option *ngFor="let c of conditions;trackBy:trackByIndex" value="{{c.value}}">{{c.label}}</option>
                                                </select>
                                            </ng-template>
                                            <ng-template #notShow>
                                                <select [attr.tabindex]="0"  class="form-control" id="{{'condition' + j }}" name="{{'condition' + j }}" [(ngModel)]="cnd.ConditionType" >
                                                    <option *ngFor="let c of conditions;trackBy:trackByIndex" value="{{c.value}}">{{c.label}}</option>
                                                </select>
                                            </ng-template> -->
                                    <!-- </td> -->
                                    <!-- <td style="width: 25.5%">
                                        <select [attr.tabindex]="0"  class="form-control" id="{{'condition' + j }}" name="{{'condition' + j }}" [(ngModel)]="cnd.ConditionType" >
                                            <option *ngFor="let c of conditions;trackBy:trackByIndex" value="{{c.value}}">{{c.label}}</option>
                                        </select>
                                    </td> -->
                                    <td style="width: 22.5%">
                                <tr *ngIf="cnd.FieldName != 'DataType'">
                                    <input id="{{'cndValue' + j }}" style="min-width: 200px;" type="text"
                                        name="{{'cndValue' + j }}" [(ngModel)]="cnd.Value"
                                        class="input-sm inputWidthTablet"
                                        (blur)="cnd.Value=removeQuote($event.target.value)" />
                                </tr>
                                <tr *ngIf="cnd.FieldName != 'DataType'">
                                    <label class="token"
                                        (click)="AssignToken(j, sharedService.Token_Today)">{{sharedService.Token_Today}},
                                    </label>
                                    <label class="token"
                                        (click)="AssignToken(j, sharedService.Token_Latest)">{{sharedService.Token_Latest}},
                                    </label>
                                </tr>
                                <tr *ngIf="cnd.FieldName == 'DataType'">
                                    <p-dropdown [attr.tabindex]="0" appendTo="body" class="form-control"
                                        id="{{'cndValue' + j }}" name="{{'cndValue' + j }}" [options]="viewDataTypeList"
                                        [editable]="false" [(ngModel)]="cnd.Value" placeholder="Select Data Type"
                                        [filter]="true" [panelStyle]="{'bottom': 'calc(100% + 5px)'}"></p-dropdown>
                                </tr>
                                </td>
                                <td style="width: 21.5%">
                                    <a class="button-icon hand-cursor pull-right tablIndexFocus" [attr.tabindex]="0"
                                        style="padding-right: 5px;" name="{{'btnCndDel' + j }}"
                                        id="{{'btnCndDel' + j }}" (keyup.enter)="deleteCondition(j)"
                                        (click)="deleteCondition(j)">
                                        <i class="glyphicon glyphicon-remove"></i>
                                    </a>
                                </td>
                                </tr>
                            </tbody>
                        </table>
                        <!--</div>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group col-sm-6"
            style="margin-top:10px;padding-right: 15px !important;padding-left: 0px !important;">
            <div class="panel panel-default" style="border-right-width: 2px;">
                <div class="panel-heading">
                    <h3 class="panel-title panalheading-padding">
                        Meet ANY of the following condition (OR)
                        <a class="button-icon hand-cursor pull-right tablIndexFocus"
                            style="padding-right: 5px;padding-top: 5px;" id="btnOrCndAdd" [attr.tabindex]="0"
                            (click)="addOrCondition()" (keyup.enter)="addOrCondition()">
                            <i class="glyphicon glyphicon-plus"></i>
                        </a>
                    </h3>
                </div>
                <div class="panel-body">
                    <div class="col-sm-12 picklist-padding fix-picklist-width">
                        <!--<div class="table-responsive" style="max-height: 185px;">-->
                        <table class="table table-hover">
                            <thead style="display: block !important;">
                                <tr>
                                    <!-- <th style="width: 24%">&nbsp;</th> -->
                                    <th style="width: 20%">Field Name</th>
                                    <!--<th>Field Label</th>-->
                                    <th style="width: 12%">Condition</th>
                                    <th style="width: 22.6%">Value</th>
                                    <th style="width: 1.5%">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody style="max-height: 185px;display: block !important;overflow-y: auto;"
                                id="configviewbody">
                                <tr *ngFor="let cnd of _view.OrConditions; let j = index; trackBy:trackByIndex">
                                    <!-- <td style="width: 25.5%">
                                        <select *ngIf="j!=0" class="form-control" [attr.tabindex]="0"  id="{{'whereCondition' + j }}" name="{{'whereCondition' + j }}" [(ngModel)]="cnd.JoinCondition" style="width:200px;">
                                            <option *ngFor="let w of whereConditions;trackBy:trackByIndex" value="{{w.value}}">{{w.label}}</option>
                                        </select>
                                    </td> -->
                                    <td style="width: 25.5%">
                                        <!--<select class="form-control" id="{{'condFieldName' + j }}" name="{{'condFieldName' + j }}" [(ngModel)]="cnd.FieldName" style="width:200px;">
                                            <option *ngFor="let f of fieldsWithLable" value="{{f.value}}">{{f.label}}</option>
                                        </select>-->
                                        <p-dropdown [attr.tabindex]="0" appendTo="body" class="form-control"
                                            id="orCondFieldName{{j}}" name="orCondFieldName{{j}}"
                                            [options]="_view.fieldList" [editable]="false" [(ngModel)]="cnd.FieldName"
                                            placeholder="Select Field" [filter]="true"
                                            [panelStyle]="{'bottom': 'calc(100% + 5px)'}"></p-dropdown>
                                    </td>
                                    <!--<td><label id="{{'condFieldLabel' + j }}" type="text" name="{{'cndValue' + j }}" [(ngModel)]="cnd.FieldLabel" class="label"></label></td>-->
                                    <!-- <td style="width: 25.5%">
                                        <select [attr.tabindex]="0"  class="form-control" id="{{'orCondition' + j }}" name="{{'orCondition' + j }}" [(ngModel)]="cnd.ConditionType" >
                                            <option *ngFor="let c of conditions;trackBy:trackByIndex" value="{{c.value}}">{{c.label}}</option>
                                        </select>
                                    </td> -->


                                    <td *ngIf="cnd.FieldName" style="width: 25.5%">

                                        <select [attr.tabindex]="0" class="form-control" id="{{'orCondition' + j }}"
                                            name="{{'orCondition' + j }}" [(ngModel)]="cnd.ConditionType" required>
                                            <option *ngFor="let c of conditions;trackBy:trackByIndex"
                                                value="{{c.value}}">{{c.label}}</option>
                                        </select>

                                    </td>
                                    <td *ngIf="!cnd.FieldName" style="width: 25.5%">
                                        <select [attr.tabindex]="0" class="form-control" id="{{'orCondition' + j }}"
                                            name="{{'orCondition' + j }}" [(ngModel)]="cnd.ConditionType">
                                            <option *ngFor="let c of conditions;trackBy:trackByIndex"
                                                value="{{c.value}}">{{c.label}}</option>
                                        </select>
                                    </td>


                                    <td style="width: 22.5%">
                                <tr *ngIf="cnd.FieldName != 'DataType'">
                                    <input id="{{'orCndValue' + j }}" type="text" style="min-width: 200px;"
                                        name="{{'orCndValue' + j }}" [(ngModel)]="cnd.Value"
                                        class="form-control input-sm inputWidthTablet2"
                                        (blur)="cnd.Value=removeQuote($event.target.value)" />
                                </tr>
                                <tr *ngIf="cnd.FieldName != 'DataType'">
                                    <label class="token"
                                        (click)="AssignOrToken(j, sharedService.Token_Today)">{{sharedService.Token_Today}},
                                    </label>
                                    <label class="token"
                                        (click)="AssignOrToken(j, sharedService.Token_Latest)">{{sharedService.Token_Latest}},
                                    </label>
                                </tr>
                                <tr *ngIf="cnd.FieldName == 'DataType'">
                                    <p-dropdown [attr.tabindex]="0" appendTo="body" class="form-control"
                                        id="{{'orCndValue' + j }}" name="{{'orCndValue' + j }}"
                                        [options]="viewDataTypeList" [editable]="false" [(ngModel)]="cnd.Value"
                                        placeholder="Select Data Type" [filter]="true"
                                        [panelStyle]="{'bottom': 'calc(100% + 5px)'}"></p-dropdown>
                                </tr>
                                </td>
                                <td style="width: 21.5%">
                                    <a class="button-icon hand-cursor pull-right tablIndexFocus" [attr.tabindex]="0"
                                        style="padding-right: 5px;" name="{{'btnOrCndDel' + j }}"
                                        id="{{'btnOrCndDel' + j }}" (keyup.enter)="deleteOrCondition(j)"
                                        (click)="deleteOrCondition(j)">
                                        <i class="glyphicon glyphicon-remove"></i>
                                    </a>
                                </td>
                                </tr>
                            </tbody>
                        </table>
                        <!--</div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="widget-footer">
        <button type="button" class="btn btn-primary margin-right-3px custom-button" (click)="openCloseMoreFiltersDialogue(false,_view,true)">
            <i class="glyphicon glyphicon-arrow-left"></i>{{" Back"}}
        </button>
        <a class="btn btn-default" (click)="openCloseMoreFiltersDialogue(false,_view)">Cancel</a>
    </div>
</p-dialog>