import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { HttpClientModule, HttpClientJsonpModule, HttpClient, HttpXhrBackend, HttpRequest } from '@angular/common/http';
import { HttpConfigInterceptor } from './shared/intercepters/http-config.interceptor';

import { AppRoutingModule } from './app-routing.module';
//Angular-oAuth2-oidc
import { OAuthModule } from 'angular-oauth2-oidc';

//#region 3rd Party Components

import { AgGridModule } from 'ag-grid-angular';
import "ag-grid-enterprise";
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { TreeModule } from 'primeng/tree';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { PickListModule } from 'primeng/picklist';
import { SliderModule } from 'primeng/slider';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ChipsModule } from 'primeng/chips';
import { ColorPickerModule } from 'primeng/colorpicker';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { CardModule, } from 'primeng/card';
import { EmbedVideo } from 'ngx-embed-video';
import { EditorModule } from 'primeng/editor';
import { ChartModule } from 'primeng/chart';
import { ClipboardModule } from 'ngx-clipboard';
import { HotkeyModule, HotkeysService } from 'angular2-hotkeys';
//#endregion
//#region Shared Services/Components
import { AppComponent } from './shared/components/app-component/app.component';
import {ImageCropperComponent} from './shared/image-cropper/image-cropper.component';
import {ImageCropperModule } from 'ngx-image-cropper';
import { PageNotFoundComponent } from './shared/components/not-found/not-found.component';
import { LoginComponent } from './shared/components/login/login.component';
import { LogoutComponent } from './shared/components/logout/logout.component';
import { SSOComponent } from './shared/components/sso/sso.component';
import { MenuComponent } from './shared/components/menu/menu.component';
import { SharedService } from './shared/services/shared.service';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { AuditHistoryComponent } from './shared/components/audit/audit-history.component';
import { ChannelService, ChannelConfig, SignalrWindow } from './shared/services/signalR/channel.Service';
import { PortalComponent } from './shared/components/portal/portal.component';
//#endregion
//#region Record's Services/Components
import { HomeComponent } from './records/components/home/home.component';
import { RecordComponent } from './records/components/record/record.component';
import { BrowseComponent } from './records/components/browse/browse.component';
import { SearchComponent } from './records/components/search/search.component';
import { AddContainerComponent } from './records/components/add-container/add-container.component';
import { MoveContainerComponent } from './records/components/move-container/move-container.component';
import { EditContainerComponent } from './records/components/edit-container/edit-container.component';
import { StatusPopup } from './records/components/status-popup/status-popup.component';
import { RecordExportComponent } from './records/components/export/export.component';
import { DocumentViewerComponent } from './records/components/document-viewer/document-viewer.component';
//Record's Child Components
import { BrowseContainerComponent } from './records/components/browse-container/browse-container.component';
import { BrowseDocumentComponent } from './records/components/browse-document/browse-document.component';
import { BrowsePDFComponent } from './records/components/browse-pdf/browse-pdf.component';
import { AssetComponent } from './records/components/asset/asset.component';
import { UploadDocument } from './records/components/upload-document/upload-document.component';
import { EditMetaDataComponent } from './records/components/edit-metadata/edit-metadata.component';
import { DocManipulation } from './records/components/doc-manipulation/doc-manipulation.component';
//pipes
import { AssetFilterPipe } from '../app/records/components/asset/tree.pipe';
import { ArraySortPipe } from './shared/pipes/sort.pipe';
import { SafeHtmlPipe } from './shared/pipes/safe-html-pipe';
//#endregion
//#region Admin's Services/Components
import { AdminComponent } from './admin/components/admin/admin.component';
import { UsersComponent } from './admin/components/users/users.component';
import { WorkOrderComponent } from './admin/components/work-order/work-order.component';
import { AircraftComponent } from './admin/components/aircraft/aircraft.component';
import { AddAircraftComponent } from './admin/components/add-aircraft/add-aircraft.component';
import { EditAircraftComponent } from './admin/components/edit-aircraft/edit-aircraft.component';
import { AssetsComponent } from './admin/components/assets/assets.component';
import { DocumentTypesComponent } from './admin/components/document-types/document-types.component';
import { DocumentTypeComponent } from './admin/components/document-type/document-type.component';
import { AutoApproveComponent } from './admin/components/auto-approve/auto-approve.component';
import { InboxComponent } from './admin/components/inbox/inbox.component';
import { UserManagementComponent } from './admin/components/user-management/user-management.component';
import { ContainerTypeComponent } from './admin/components/container-types/container-type.component';
import { AddBuilderSearchComponent } from './admin/components/add-builderSearch/add-builderSearch.component';
import { AuditComponent } from './admin/components/audit/audit.component';
import { ResetAzureSearchComponent } from './admin/components/reset-azure-search/reset-azure-search.component';

import { ViewSettingComponent } from './admin/components/view-setting/view-setting.component';
import { ConfigViewComponent } from './admin/components/config-view/config-view.component';
import { BuilderSettingsComponent } from './admin/components/builder-settings/builder-settings.component';
import { SettingsComponent } from './admin/components/settings/settings.component';

import { AlertComponent } from './admin/components/alert/alert.component';
import { UserGroupComponent } from './admin/components/user-groups/user-groups.component';
import { TagsComponent } from './admin/components/tags/tags.component';
import { DFPStatusComponent } from './admin/components/dfpstatus/dfpstatus.component';
import { HiddenComponent } from './admin/components/hidden/hidden.component';
import { DownloadTemplateComponent } from './admin/components/download-template/download-template.component';
import { UploadTemplateComponent } from './admin/components/upload-template/upload-template.component';
import { MarkerTypeComponent } from './admin/components/marker-type/marker-type.component';
import { ViewDataTypeComponent } from './admin/components/view-data-type/view-data-type.component';
import { AssetGroupComponent } from './admin/components/asset-group/asset-group.component';


//#endregion
//#region DP Job's Services/Components
import { JobsComponent } from './jobs/components/job/job.component';
import { ListJobsComponent } from './jobs/components/list-job/list-job.component';
import { AddJobComponent } from './jobs/components/add-job/add-job.component';
import { EditJobComponent } from './jobs/components/edit-job/edit-job.component';
import { ViewLinkedRecordComponent } from './jobs/components/job/view-job-button.component';
import { ContainerSelectionComponent } from './jobs/components/container-selection/container-selection.component';
//#endregion
//#region Status Services/Components
import { StatusComponent } from '../app/status/components/status/status.component';
import { ViewList } from '../app/status/components/view-list/view-list.component';
//import { TaskComponent } from '../app/status/components/task/task-component';
import { AssetStatusComponent } from '../app/status/components/asset-status/asset-status.component';
import { UploadFromComputer } from '../app/status/components/upload-from-computer/upload-from-computer.component';
import { MSRComponent } from '../app/status/components/msr/msr.component';
import { StatusExportComponent } from '../app/status/components/export/export.component';
import { StatusDashboardComponent } from '../app/status/components/status-dashboard/status-dashboard.component';
//#endregion API Services/Components
import { APIComponent } from '../app/api/components/api/api.component';
import { ListDataFeedComponent } from '../app/api/components/list-datafeed/list-datafeed.component';
import { AddDataFeedComponent } from '../app/api/components/add-datafeed/add-datafeed.component';
import { EditDataFeedComponent } from '../app/api/components/edit-datafeed/edit-datafeed.component';
import { FieldMappingComponent } from '../app/api/components/field-mapping/field-mapping.component';
import { FeedLogComponent } from '../app/api/components/feed-log/feed-log.component';
import { DownloadFeedComponent } from '../app/api/components/download-feed/download-feed.component';
import { FeedLogDetailComponent } from '../app/api/components/feed-log-detail/feed-log-detail.component';
import { UploadFeedComponent } from '../app/api/components/upload-feed/upload-feed.component';
import { RecordConfigurationComponent } from '../app/api/components/record-configuration/record-configuration.component';
import { StatusConfigurationComponent } from '../app/api/components/status-configuration/status-configuration.component';
import { CheckboxUniqueComponent } from '../app/api/components/field-mapping/checkbox-unique.component';
import { CheckboxHistoryComponent } from '../app/api/components/field-mapping/checkbox-history.component';
import { CustomButtonComponent } from '../app/api/components/field-mapping/custom-button.component';
//import { environment } from '../../../roam/src/environments/environment';
import { environment } from '../environments/environment';

//#region  Inbox
import { CommComponent } from '../app/comm/components/comm/comm.component';
import { ListCommComponent } from '../app/comm/components/list-comm/list-comm.component';
import { AddCommComponent } from '../app/comm/components/add-comm/add-comm.component';
import { EditCommComponent } from '../app/comm/components/edit-comm/edit-comm.component';
import { ViewLinkedRecordInboxComponent } from '../app/comm/components/comm/view-inbox-button.component';

//#endregion

//#region  Help
import { PDFExtensionComponent } from '../app/help/components/pdf-extension/pdf-extension.component';
import { DownloadComponent } from '../app/help/components/download/download.component';
import { FTPAplicationComponent } from '../app/help/components/ftp-application/ftp-application.component';
import { TrainingComponent } from '../app/help/components/training/training.component';
import { ViewJobProgressComponent } from './status/components/view-job-progress/view-job-progress.component';
//#endregion
//#region  Report
import { ReportComponent } from '../app/reports/components/report/report.component';
import { AssetReportComponent } from '../app/reports/components/asset/asset.component';
import { JobReportComponent } from '../app/reports/components/job/job.component';
import { TaskComponent } from './status/components/task/task-component';
import { MessageService } from 'primeng/api';
import { RoleGroupComponent } from './admin/components/role-group/role-group.component';
import { CustomDateComponent } from './shared/components/custom-date/custom-date.component';
import { QualityStatusComponent } from './admin/components/qualitystatus/qualitystatus.component';

import { EditorModule as tinymce } from '@tinymce/tinymce-angular';
import { AddCategoryComponent } from './help/components/add-category/add-category.component';
import { AddArticleComponent } from './help/components/add-article/add-article.component';
import { AddSectionComponent } from './help/components/add-section/add-section.component';
import { ArticleViewComponent } from './help/components/article-view/article-view.component';
import { ArticleComponent } from './help/components/articles/articles.component';
import { ViewArticleComponent } from './help/components/help-component-articles/component-articles.component';
import { CategoryComponent } from './help/components/category/category.component';
import { EditArticleComponent } from './help/components/edit-article/edit-article.component';
import { HelpComponent } from './help/components/help/help.component';
import { SectionComponent } from './help/components/section/section.component';
import { FolderComponent } from './help/components/folder/folder.component';
import { SearchedArticlesComponent } from './help/components/searched-articles/searched-articles.component';
import { SearchArticleComponent } from './help/components/search-article/search-article.component';
import { SearchighlightPipe } from './shared/pipes/searchighlight.pipe';
import { SearchSynonymsComponent } from './admin/components/search-synonyms/search-synonyms.component';
import { ContainerQualityStatusComponent } from './admin/components/container-quality-status/container-quality-status.component';
import { ReportScreenComponent } from './status/components/report-screen/report-screen.component';
import { DownloadReportComponent } from './status/components/download-report/download-report.component';
import { ToolboxDownloadComponent } from './help/components/toolbox-download/toolbox-download.component';
import { SearchFormComponent } from './records/components/search-form/search-form.component';
import { CardViewListComponent } from './status/components/card-view-list/card-view-list.component';
import { TechSupportComponent } from './help/components/tech-support/tech-support.component';
import { SelectContainerComponent } from './api/components/select-target-container/select-target-container.component';
import { DataImporterComponent } from './shared/components/data-importer/data-importer.component';
import { CustomTooltip } from './comm/components/comm/comm-attachment-tooltip.component';
import { WidgetsGridComponent } from './widgets/widgets-grid';
import { WidgetComponent } from './widgets';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AttachSelectedPagesComponent } from './records/components/attach-selected-pages/attach-selected-pages.component';
import { DateChangeContainerComponent } from './records/components/datechange-container/datechange-container.component';
import { CustomNormalTooltip } from './shared/components/tooltip-component';
import { ListboxModule } from 'primeng/listbox';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';
import { ExportContainerConfigurationComponent } from './api/components/export-container-configuration/export-container-configuration.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ExportContainerComponent } from './records/components/export/export-container/export-container.component';



//#endregion
let channelConfig = new ChannelConfig();
channelConfig.url = environment.hubUrl;
channelConfig.hubName = environment.hubName;

@NgModule({
    imports: [
        ImageCropperModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        AppRoutingModule,
        OAuthModule.forRoot(),
        //3rd Party
        InputTextModule,
        ButtonModule,
        OverlayPanelModule,
        DialogModule,
        DropdownModule,
        TreeModule,
        CalendarModule,
        AutoCompleteModule,
        FileUploadModule,
        MultiSelectModule,
        //AgGridModule.withComponents(null),
        AgChartsAngularModule,
        AgGridModule,
        ConfirmDialogModule,
        TooltipModule,
        ClipboardModule,
        PickListModule,
        ChartModule,
        SliderModule,
        SelectButtonModule,
        EditorModule,
        InputTextareaModule,
        HotkeyModule.forRoot(),
        ChipsModule,
        ColorPickerModule,
        CheckboxModule,
        ToastModule,
        EmbedVideo.forRoot(),
        ListboxModule,
        ToolbarModule,
        tinymce,
        CardModule,
        PanelModule,
        DynamicDialogModule
    ],
    declarations: [
        //Shared
        AppComponent,
        PageNotFoundComponent,
        ViewArticleComponent,
        LoginComponent,
        LogoutComponent,
        SSOComponent,
        MenuComponent,
        LoaderComponent,
        AuditHistoryComponent,
        PortalComponent,
        WidgetComponent,
        WidgetsGridComponent,
        AttachSelectedPagesComponent,
        //Record's
        HomeComponent,
        RecordComponent,
        BrowseComponent,
        SearchComponent,
        RecordExportComponent,
        DocumentViewerComponent,
        //Record's Childs
        BrowseContainerComponent,
        BrowseDocumentComponent,
        BrowsePDFComponent,
        AssetComponent,
        UploadDocument,
        EditMetaDataComponent,
        StatusPopup,
        DocManipulation,
        //Pipes
        AssetFilterPipe,
        ArraySortPipe,
        SafeHtmlPipe,
        AddContainerComponent,
        EditContainerComponent,
        DateChangeContainerComponent,
        //Admin's
        AdminComponent,
        UsersComponent,
        WorkOrderComponent,
        AircraftComponent,
        AddAircraftComponent,
        EditAircraftComponent,
        AssetsComponent,
        DocumentTypesComponent,
        DocumentTypeComponent,
        AutoApproveComponent,
        InboxComponent,
        UserManagementComponent,
        ContainerTypeComponent,
        JobsComponent,
        ListJobsComponent,
        AddJobComponent,
        EditJobComponent,
        ViewSettingComponent,
        ConfigViewComponent,
        AddBuilderSearchComponent,
        SettingsComponent,
        AuditComponent,
        AlertComponent,
        UserGroupComponent,
        TagsComponent,
        DFPStatusComponent,
        ViewLinkedRecordComponent,
        ContainerSelectionComponent,
        BuilderSettingsComponent,
        HiddenComponent,
        RoleGroupComponent,
        DownloadTemplateComponent,
        UploadTemplateComponent,
        MarkerTypeComponent,
        ViewDataTypeComponent,
        ResetAzureSearchComponent,
        QualityStatusComponent,
        SearchSynonymsComponent,
        AssetGroupComponent,
        //Status
        StatusComponent,
        ViewList,
        TaskComponent,
        // TaskComponent,
        AssetStatusComponent,
        UploadFromComputer,
        MSRComponent,
        StatusExportComponent,
        StatusDashboardComponent,
        //API
        APIComponent,
        ListDataFeedComponent,
        AddDataFeedComponent,
        EditDataFeedComponent,
        FieldMappingComponent,
        FeedLogComponent,
        DownloadFeedComponent,
        FeedLogDetailComponent,
        UploadFeedComponent,
        RecordConfigurationComponent,
        StatusConfigurationComponent,
        CheckboxUniqueComponent,
        CheckboxHistoryComponent,
        CustomButtonComponent,
        //Inbox
        ListCommComponent,
        CommComponent,
        AddCommComponent,
        EditCommComponent,
        ViewLinkedRecordInboxComponent,
        //Help
        PDFExtensionComponent,
        DownloadComponent,
        FTPAplicationComponent,
        TrainingComponent,
        ViewJobProgressComponent,
        //Report
        ReportComponent,
        AssetReportComponent,
        JobReportComponent,
        // Custom Date Component
        CustomDateComponent,
        // Custom Comm Attachment Component
        CustomTooltip,
        CustomNormalTooltip,
        AddCategoryComponent,
        AddArticleComponent,
        AddSectionComponent,
        ArticleViewComponent,
        ArticleComponent,
        CategoryComponent,
        EditArticleComponent,
        HelpComponent,
        SectionComponent,
        FolderComponent,
        SearchedArticlesComponent,
        SearchArticleComponent,
        SearchighlightPipe,
        ContainerQualityStatusComponent,
        ReportScreenComponent,
        DownloadReportComponent,
        ToolboxDownloadComponent,
        SearchFormComponent,
        CardViewListComponent,
        TechSupportComponent,
        ImageCropperComponent,
        SelectContainerComponent,
        DataImporterComponent,
        MoveContainerComponent,
        ExportContainerConfigurationComponent,
        ExportContainerComponent,
  ],
  entryComponents: [
    ExportContainerConfigurationComponent
  ],    
    providers: [
        SharedService,
        MessageService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true
        },
        HotkeysService,
        ChannelService,
        { provide: SignalrWindow, useValue: window },
        { provide: 'channel.config', useValue: channelConfig }
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
