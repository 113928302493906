<div style="height:1000px;overflow:scroll">
    <div class="row addaircraftcls">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" id="col1">
            <h1 class="page-title txt-color-blueDark">

                <!-- PAGE HEADER -->
                <i class="fa-fw fa fa-plane"></i>
                <a routerLink="{{addAircraftPath}}">Asset</a>
                <span>
                    &gt;
                    <span id="page-mode">Add</span>
                </span>
            </h1>
        </div>
        <article class="col-sm-12 col-md-12 col-lg-12" id="col2">
            <!-- Widget ID (each widget will need unique ID)-->


            <form (ngSubmit)="btnSubmitClick(addForm)" #addForm="ngForm">
                <div class="jarviswidget" id="wid-id-0" data-widget-colorbutton="false" data-widget-editbutton="false"
                    data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false">

                    <header>
                        <span class="widget-icon"><i class="fa fa-pencil-square-o"></i>&nbsp;</span>
                        <p-dropdown id="addAssetType" name="addAssetType" [options]="assetTypeList" required
                            placeholder="Select Asset Type" [(ngModel)]="model.AssetType" #addAssetType="ngModel"
                            (onChange)="onAssetChange($event)" [style]="{'margin-left':'6px !important'}" appendTo="body">
                        </p-dropdown>
                    </header>

                    <!-- widget div-->
                    <div class="widgetdiv">
                        <!-- widget content -->
                        <div class="widget-body">
                            <!-- widget grid -->
                            <section id="widget-grid0" class="">
                                <div class="navbar-form form-group">
                                    <!--<ul id="myTab1" class="nav nav-tabs bordered">
                                        <li class="active">
                                            <a href="#s1" data-toggle="tab" aria-expanded="true">Airframe</a>
                                        </li>
                                    </ul>-->
                                    <div id="myTabContent1" class="tab-content">
                                        <div class="tab-pane fade active in" id="s1">
                                            <div class="smart-form">
                                                <div class="row">
                                                    <div class="form-group col-sm-4">
                                                        <fieldset>
                                                            <label class="label">
                                                                Title<span [hidden]="model.Description"
                                                                    class="text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <label class="input">
                                                                <input id="mfTitle" type="text" name="mfTitle"
                                                                    [(ngModel)]="model.Description" required
                                                                    class="input-sm" />
                                                            </label>
                                                        </fieldset>
                                                    </div>
                                                    <div class="form-group col-sm-4">
                                                        <fieldset>
                                                            <label class="label">
                                                                {{ASNTitle}}
                                                                <span [hidden]="model.ASN" class="text-danger"> *
                                                                </span>

                                                                <span
                                                                    *ngIf="mfAssetMSN.invalid && (mfAssetMSN.dirty || mfAssetMSN.touched)"
                                                                    class="text-danger">
                                                                    <span *ngIf="mfAssetMSN.errors.minlength">
                                                                        {{ASNTitle}} must be at least 3 characters long.
                                                                    </span>
                                                                </span>
                                                            </label>

                                                            <label class="input rgxErr">
                                                                <i *ngIf="mfAssetMSN.value && !mfAssetMSN.valid && mfAssetMSN.errors.pattern && (mfAssetMSN.touched || mfAssetMSN.dirty )"
                                                                    class="icon-append fa fa-ban"
                                                                    title="  {{sharedService.error_rgx_pre}} {{sharedService.re_AssetSerialNumber_Message}} {{sharedService.error_rgx_post}}"></i>
                                                                <input id="mfAssetMSN" type="text" name="mfAssetMSN"
                                                                    [(ngModel)]="model.ASN"
                                                                    [pattern]="sharedService.re_AssetSerialNumber"
                                                                    required minlength="3" #mfAssetMSN="ngModel"
                                                                    class="input-sm" />

                                                            </label>
                                                        </fieldset>
                                                    </div>
                                                    <div class="form-group col-sm-1">
                                                        <fieldset>
                                                            <label class="label">Order</label>
                                                            <label class="input">
                                                                <input id="mfOrder" type="text" name="mfOrder"
                                                                    [(ngModel)]="model.Order" class="input-sm" />
                                                            </label>
                                                        </fieldset>
                                                    </div>
                                                    <div class="form-group col-sm-3">
                                                        <div class="row">
                                                            <div class="form-group col-sm-6">
                                                                <fieldset>
                                                                    <label class="label">Billing Plan
                                                                        <span [hidden]="model.PlanName"
                                                                            class="text-danger">
                                                                            *
                                                                        </span></label>
                                                                    <label class="label">{{model.PlanName}}</label>
                                                                </fieldset>
                                                            </div>

                                                            <div class="form-group col-sm-6">
                                                                <fieldset>
                                                                    <button type="button"
                                                                        class="btn btn-default btn-custom" tabindex="-1"
                                                                        (click)="ChangePlan()"
                                                                        style="width: 100%; height: 28px; margin-top: 20%;">Change
                                                                        Plan</button>
                                                                </fieldset>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="row">

                                                    <div class="form-group col-sm-4">
                                                        <fieldset>
                                                            <label class="label">
                                                                {{ManufacturerTitle}}<span [hidden]="model.Manufacturer"
                                                                    class="text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <label class="input">
                                                                <input id="mfManufacturer" type="text"
                                                                    name="mfManufacturer" required
                                                                    [(ngModel)]="model.Manufacturer" class="input-sm" />
                                                            </label>
                                                        </fieldset>
                                                    </div>
                                                    <div class="form-group col-sm-4">
                                                        <fieldset>
                                                            <label class="label">{{TypeTitle}}</label>
                                                            <label class="input">
                                                                <input id="mfType" type="text" name="mfType"
                                                                    [(ngModel)]="model.Type" class="input-sm" />
                                                            </label>
                                                        </fieldset>
                                                    </div>
                                                    <div class="form-group col-sm-4">
                                                        <fieldset>
                                                            <label class="label">{{VariantTitle}}</label>
                                                            <label class="input">
                                                                <input id="mfVarient" type="text" name="mfVarient"
                                                                    [(ngModel)]="model.Varient" class="input-sm" />
                                                            </label>
                                                        </fieldset>
                                                    </div>

                                                </div>
                                                <div class="row" *ngIf="(model && (model.AssetType=='Airframe' || model.AssetType=='Helicopter'))">

                                                    <div class="form-group col-sm-4">
                                                        <fieldset>
                                                            <label class="label">Registration Authority</label>
                                                            <label class="input">
                                                                <input id="mfRegAuthority" type="text"
                                                                    name="mfRegAuthority"
                                                                    [(ngModel)]="model.RegAuthority" class="input-sm" />
                                                            </label>
                                                        </fieldset>
                                                    </div>
                                                    <div class="form-group col-sm-4">
                                                        <fieldset>
                                                            <label class="label">Registration</label>
                                                            <label class="input">
                                                                <input id="mfRegNumber" type="text" name="mfRegNumber"
                                                                    [(ngModel)]="model.RegNumber" class="input-sm" />
                                                            </label>
                                                        </fieldset>
                                                    </div>
                                                    <div class="form-group col-sm-4">
                                                        <fieldset>
                                                            <label class="label">Owner</label>
                                                            <label class="input">
                                                                <input id="mfOwner" type="text" name="mfOwner"
                                                                    [(ngModel)]="model.Owner" class="input-sm" />
                                                            </label>
                                                        </fieldset>
                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div class="form-group col-sm-4"
                                                        *ngIf="(model && (model.AssetType=='Airframe' || model.AssetType=='Helicopter'))">
                                                        <fieldset>
                                                            <label class="label">Time</label>
                                                            <label class="input">
                                                                <input id="mfTime" type="text" name="mfTime"
                                                                    [(ngModel)]="model.Time" class="input-sm" />
                                                            </label>
                                                        </fieldset>
                                                    </div>
                                                    <div class="form-group col-sm-4"
                                                        *ngIf="(model && (model.AssetType=='Airframe' || model.AssetType=='Helicopter'))">
                                                        <fieldset>
                                                            <label class="label">Cycle</label>
                                                            <label class="input">
                                                                <input id="mfCycle" type="text" name="mfCycle"
                                                                    [(ngModel)]="model.Cycle" class="input-sm" />
                                                            </label>
                                                        </fieldset>
                                                    </div>
                                                    <div class="form-group col-sm-4">
                                                        <fieldset>
                                                            <label class="label">Search Database
                                                                <span [hidden]="model.SearchDatabaseID"
                                                                    class="text-danger"> * </span>
                                                            </label>
                                                            <span>
                                                                <p-dropdown id="SearchDatabaseDD"
                                                                    name="SearchDatabaseDD"
                                                                    [options]="searchDatabaseList" required
                                                                    placeholder="Select Search Database"
                                                                    [(ngModel)]="model.SearchDatabaseID"
                                                                    #addSearchDatabase="ngModel"
                                                                    [style]="{'padding-top': '4px !important','height': '31px','margin-left':'6px !important'}"
                                                                    appendTo="body">
                                                                </p-dropdown>
                                                            </span>
                                                        </fieldset>
                                                    </div>

                                                    <!-- <div class="form-group col-sm-4"
                                                        *ngIf="(model && model.AssetType=='Airframe')">
                                                        <div class="form-group col-sm-3">
                                                            <fieldset>
                                                                <label class="label">MX Records</label>
                                                                <label for="mxrecordasset" class="checkbox">
                                                                    <p-checkbox id="mxrecordasset" name="mxrecordasset"
                                                                        [(ngModel)]="model.MXRecords" binary="true"
                                                                        (keyup.space)="ToggleMXRecord()"></p-checkbox>
                                                                </label>
                                                            </fieldset>
                                                        </div>
                                                        <div class="form-group col-sm-3">
                                                            <fieldset>
                                                                <label class="label">Log Books</label>
                                                                <label for="logbooksasset" class="checkbox">
                                                                    <p-checkbox id="logbooksasset" name="logbooksasset"
                                                                        [(ngModel)]="model.LogBooks" binary="true"
                                                                        (keyup.space)="ToggleLogBooks()"></p-checkbox>
                                                                </label>
                                                            </fieldset>
                                                        </div>
                                                        <div class="form-group col-sm-3">
                                                            <fieldset>
                                                                <label class="label">General</label>
                                                                <label for="generalasset" class="checkbox">
                                                                    <p-checkbox id="generalasset" name="generalasset"
                                                                        [(ngModel)]="model.General" binary="true"
                                                                        (keyup.space)="ToggleGeneral()"></p-checkbox>
                                                                </label>
                                                            </fieldset>
                                                        </div>
                                                        <div class="form-group col-sm-3">
                                                            <fieldset>
                                                                <label class="label">Documents</label>
                                                                <label for="documentsasset" class="checkbox">
                                                                    <p-checkbox id="documentsasset"
                                                                        name="documentsasset"
                                                                        [(ngModel)]="model.Documents" binary="true"
                                                                        (keyup.space)="ToggleDocuments()"></p-checkbox>
                                                                </label>
                                                            </fieldset>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <div class="row">
                                                    <div class="form-group col-sm-1">
                                                        <fieldset>
                                                            <label class="label">Status</label>
                                                            <label class="checkbox">
                                                                <span class="onoffswitch tablIndexFocus onoffswitch-Width"
                                                                    [attr.tabindex]="0"
                                                                    (keyup.enter)="ToggleCheckedStatus()">
                                                                    <input [checked]="isCheckedStatus" type="checkbox"
                                                                        name="onoffswitch" [(ngModel)]="model.Status"
                                                                        class="onoffswitch-checkbox" checked="checked"
                                                                        id="isactive">
                                                                    <label class="onoffswitch-label"
                                                                        for="isactive"><span class="onoffswitch-inner"
                                                                            data-swchon-text="Active"
                                                                            data-swchoff-text="Inactive"></span><span
                                                                            class="onoffswitch-switch onoffswitch-switch-Right"></span></label>
                                                                </span>
                                                            </label>
                                                        </fieldset>
                                                    </div>
                                                    <div class="form-group col-sm-1">
                                                        <fieldset>
                                                            <label class="label">Read Only</label>
                                                            <label for="isReadOnlyAir" class="checkbox">
                                                                <span class="onoffswitch tablIndexFocus"
                                                                    [attr.tabindex]="0"
                                                                    (keyup.enter)="ToggleCheckedReadOnly()">
                                                                    <input [checked]="isCheckedReadOnly"
                                                                        [disabled]="disableReadOnlyButton"
                                                                        type="checkbox" name="onoffswitchair"
                                                                        [(ngModel)]="model.ReadOnly"
                                                                        class="onoffswitch-checkbox" id="isReadOnlyAir">
                                                                    <label class="onoffswitch-label"
                                                                        for="isReadOnlyAir"><span
                                                                            class="onoffswitch-inner"
                                                                            data-swchon-text="YES"
                                                                            data-swchoff-text="NO"></span><span
                                                                            class="onoffswitch-switch"></span></label>
                                                                </span>
                                                            </label>
                                                        </fieldset>
                                                    </div>
                                                    <div class="form-group col-sm-10"
                                                        *ngIf="(model && (model.AssetType=='Airframe' || model.AssetType=='Helicopter'))">
                                                        <div class="form-group col-sm-2">
                                                            <fieldset>
                                                                <label class="label">MX Records</label>
                                                                <label for="mxrecordasset" class="checkbox">
                                                                    <p-checkbox id="mxrecordasset" name="mxrecordasset"
                                                                        [(ngModel)]="model.MXRecords" binary="true"
                                                                        (keyup.space)="ToggleMXRecord()"></p-checkbox>
                                                                </label>
                                                            </fieldset>
                                                        </div>
                                                        <div class="form-group col-sm-2">
                                                            <fieldset>
                                                                <label class="label">Log Books</label>
                                                                <label for="logbooksasset" class="checkbox">
                                                                    <p-checkbox id="logbooksasset" name="logbooksasset"
                                                                        [(ngModel)]="model.LogBooks" binary="true"
                                                                        (keyup.space)="ToggleLogBooks()"></p-checkbox>
                                                                </label>
                                                            </fieldset>
                                                        </div>
                                                        <div class="form-group col-sm-2">
                                                            <fieldset>
                                                                <label class="label">General</label>
                                                                <label for="generalasset" class="checkbox">
                                                                    <p-checkbox id="generalasset" name="generalasset"
                                                                        [(ngModel)]="model.General" binary="true"
                                                                        (keyup.space)="ToggleGeneral()"></p-checkbox>
                                                                </label>
                                                            </fieldset>
                                                        </div>
                                                        <div class="form-group col-sm-2">
                                                            <fieldset>
                                                                <label class="label">Documents</label>
                                                                <label for="documentsasset" class="checkbox">
                                                                    <p-checkbox id="documentsasset"
                                                                        name="documentsasset"
                                                                        [(ngModel)]="model.Documents" binary="true"
                                                                        (keyup.space)="ToggleDocuments()"></p-checkbox>
                                                                </label>
                                                            </fieldset>
                                                        </div>
                                                        <div class="form-group col-sm-2">
                                                            <fieldset>
                                                                <label class="label">Binders</label>
                                                                <label for="bindersasset" class="checkbox">
                                                                    <p-checkbox id="bindersasset" name="bindersasset"
                                                                        [(ngModel)]="model.Binders" binary="true"
                                                                        (keyup.space)="ToggleBinders()"></p-checkbox>
                                                                </label>
                                                            </fieldset>
                                                        </div>
                                                        <div *ngIf="(runAutoGapAnalyser && runAutoGapAnalyser == 'true')" class="form-group col-sm-2">
                                                            <fieldset>
                                                                <label class="label">Auto Gap Analyser</label>
                                                                <label for="autogapanalyser" class="checkbox">
                                                                    <p-checkbox id="autogapanalyser" name="autogapanalyser"
                                                                        [(ngModel)]="model.AutoGapAnalyser" binary="true"
                                                                        (keyup.space)="ToggleAutoGapAnalyser()"></p-checkbox>
                                                                </label>
                                                            </fieldset>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <!-- end row -->

                            </section>
                            <!-- end widget grid -->
                            <div class="widget-footer">


                                <button type="submit" class="btn btn-primary margin-right-3px"
                                    [disabled]="!addForm.form.valid || isSubmitted || model.PlanID==null">
                                    Save
                                </button>
                                <button type="button" class="btn btn-default" [disabled]="isSubmitted"
                                    (click)="btnCancel()">
                                    Cancel
                                </button>

                            </div>
                        </div>
                        <!-- end widget content -->
                    </div>
                    <!-- end widget div -->

                </div>
                <!-- end widget -->
            </form>
        </article>
        <!-- END COL -->

    </div>

</div>
<form #storageConfirmationform="ngForm">
    <p-dialog [(visible)]="displayStorageConfirmation" showEffect="fade" [modal]="true" [style]="{width: '425px'}"
        [resizable]="false">
        <ng-template pTemplate="header">
            Asset Number/Serial Confirmation
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                We are going to make some backend structure for this asset and asset number/serial will
                                not be editable anymore. Please confirm your asset number/serial by typing again in the
                                box below.
                            </label>
                        </fieldset>
                    </div>


                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Re-enter asset number/serial:<span class="text-danger" required
                                    *ngIf="(asnReconfirm && model.ASN) &&(asnReconfirm!=model.ASN)"> (Must match with
                                    asset number/serial) </span>
                            </label>

                            <label class="input">
                                <input id="mfAssetMSNReConfirm" type="text" name="mfAssetMSNReConfirm"
                                    [(ngModel)]="asnReconfirm" class="input-sm" #mfAssetMSNReConfirm required />

                            </label>

                        </fieldset>
                    </div>
                </div>

            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="submit" pButton icon="fa fa-check" class="btn btn-primary minzindex"
                    (click)="onSubmitReconfirm(storageConfirmationform)" label="Confirm"
                    [disabled]="!(storageConfirmationform.form.valid) ||(asnReconfirm!=model.ASN)"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>

<form #changeBillingPlanForm="ngForm" *ngIf="isDisplayBillingPlanForm">
    <p-dialog [(visible)]="isDisplayBillingPlanForm" showEffect="fade" [modal]="true" [style]="{width: '80%'}"
        [contentStyle]="{'max-height':'80vh'}" [resizable]="false">
        <ng-template pTemplate="header">
            Billing Plan(s)
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-3">
                        <p-listbox [options]="billingPlanList" optionLabel="Name"
                            (onClick)="onlistboxSelectBillingPlanClicked($event)"
                            [listStyle]="{'max-height':'50vh'}" [style]="{'width':'100%'}">
                            <ng-template pTemplate="header">
                                <i class="fa fa-table"></i>
                                Select Billing Plan
                            </ng-template>
                        </p-listbox>
                    </div>
                    <div class="form-group col-sm-9">
                        <div class="smart-form" [innerHTML]=htmlBillingPlanDescription></div>
                    </div>
                </div>

            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-default minzindex"
                    (click)="btnSelectBillingPlanClick()" [disabled]='selectedBillingPlan==null'
                    label="Select Billing Plan"></button>

                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnCloseClick()" label="Close"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>