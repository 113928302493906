//Components
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

//Services
import { SelectItem, ConfirmationService } from 'primeng/api';
import { SharedService } from '../../../shared/services/shared.service';
import { NgForm } from '@angular/forms';
import { GridOptions, GridApi } from "ag-grid-community";
import { Subscription, map } from 'rxjs';
import { AssetService } from '../../../admin/services/asset/asset.service';
import { ViewService } from '../../../admin/services/view/view.service';
import { APIService } from '../../../api/services/api.service';
import { StatusConfiguration, StatusMapping, StatusConfig } from './status-configuration';
import { datafeed } from '../list-datafeed/data-feed';
import { AddUpdateStatusCode } from 'src/app/shared/helpers/config';
import * as moment from 'moment/moment';
import { IDateFormatInfo } from 'src/app/shared/helpers/dateFormatInfo';
var dateTimeConfig: IDateFormatInfo;
import { container, SearchContainerModel } from 'src/app/records/services/container/container';
import { ContainerCategory, TargetType } from '../../services/api.helper-classes';
import { Column, Condition } from 'src/app/admin/services/view/view';

@Component({
    selector: 'status-configuration',
    templateUrl: './status-configuration.component.html',
    providers: [AssetService, ViewService, APIService, ConfirmationService]
})
export class StatusConfigurationComponent implements OnInit {
    feedId: string;
    screenName: string = "";
    routeName: string = "";
    asn: string = '';
    feedType: string = '';
    feedTypePDF: string = '';

    assets: SelectItem[] = [];
    dfpStatusList: SelectItem[] = [];
    outputTypeList: SelectItem[] = [{ value: 'Separate', label: 'Separate' }, { value: 'Merged', label: 'Merged' }];
    // fieldsWithLable: SelectItem[] = [];
    assetsList: any[] = [];
    viewList: any[] = [];
    selectedDFPStatus: any[];
    model: StatusConfiguration;
    feedName: string = null;
    @Input() feedTypeInput: string = '';
    @Input() inputFeedId: string = null;
    @Input() templateMapping: string = null;
    buttonLabelName: string = 'Save';
    generateButtonLabel: string = 'Save';
    checkAll: boolean = false;
    assetStatusSubscription: Subscription;
    trackByIndex: number;
    public dataTypes: any[] = [];
    public selectedDataType: any
    public TargetType: any
    minDateValue: Date = new Date();
    maxDateValue: Date;
    public dateDisplay: boolean
    public expiryDate: any;
    public sharedAccessExpiryTime = new Date();
    public emailButtonDisable: any = false
    dateFormatCalender = "";
    iconClass: any


    //DFP URL Filters    
    conditions: any[];
    _view:StatusMapping;
    view:StatusMapping;

    displayStatusExport: boolean = false;

    constructor(private sharedService: SharedService, private router: Router, private _confirmationService: ConfirmationService, private route: ActivatedRoute, private _assetService: AssetService, private _viewService: ViewService, private _apiService: APIService) {
        if (!this.sharedService.isAuthUser()) {
            this.router.navigate(['login']);
            this.sharedService.SessionExpiredMessage();
        }
    }
    gridIcon() {
        if (this.router.url == '/status/exportstatuscompliancedocuments' || this.router.url == '/repair/exportrepaircompliancedocuments' || this.router.url == 'record/exportrecordsdocuments') {
            this.iconClass = 'fa fa-file-pdf'
        }
        else if (this.router.url == '/status/exportstatusdata' || this.router.url == '/repair/exportrepairdata' || this.router.url == '/record/exportrecordsdocumentslist') {
            this.iconClass = 'fa fa-file-o'

        }
    }
    ngOnInit() {
        this.gridIcon();
        this.TargetType = '';
        dateTimeConfig = this.sharedService.getDateTimeFormatConfiguration();
        if (this.feedTypeInput == 'ExportTemp') {
            this.buttonLabelName = 'Generate and Email';
            this.generateButtonLabel = 'Generate';
            this.generateButtonLabel = 'Generate to My Reports';
        }
        else {
            this.buttonLabelName = 'Save';
        }
        if (this.inputFeedId) {
            this.feedId = this.inputFeedId;
        }
        else {
            this.feedId = this.route.snapshot.params["id"];
        }
        if (this.feedId) {
            if (this.inputFeedId) {
                //this.screenName = this.route.snapshot.params["screen"];
                this.screenName = 'Export';
            }
            else {
                this.screenName = this.route.snapshot.params["screen"];
            }
            this.asn = this.sharedService.selectedOutgoingFeed.SourceDetail;
            var templateValue = this.sharedService.selectedOutgoingFeed.TemplateName;
            if (templateValue == "Export Transition Documents List" || templateValue == "Export Records Documents List" || templateValue == "Export Views Status Data" || templateValue == "Export Views Repair Data") {
                this.feedType = "";

            }
            else {
                this.feedType = this.sharedService.selectedOutgoingFeed.DataType;

            }
            this.routeName = this.screenName.toLowerCase();
        }
        else {
            this.feedId = this.sharedService.statusConfig.FeedId.toString();
            this.screenName = 'Export';
            this.asn = this.sharedService.statusConfig.ASN;
            this.feedType = this.sharedService.statusConfig.DataType[0].value;
            // this.feedType = this.sharedService.statusConfig.FeedType;
            this.routeName = 'Export';
        }
        this.init();

        this.assetStatusSubscription = this.sharedService._feedUpdated
            .subscribe({
                next: (response) => {
                    this.populateStatusFeedMapping()
                },
                error: (error) => {
                }
            });

        if (this.sharedService.statusConfig && this.sharedService.statusConfig.DataType.length > 0) {
            this.dataTypes = this.sharedService.statusConfig.DataType;
            this.selectedDataType = this.dataTypes[0].value;
            this.minDateValue.setHours(this.minDateValue.getHours() + 1)
            this.sharedAccessExpiryTime = this.minDateValue;
            this.maxDateValue = this.sharedService.getSharedAccessExpiryTimeThreshold();
        }
    }

    init(): void {
        this.model = new StatusConfiguration();
        this.populateStatusFeedMapping();
        this.populateAircraft();
        this.populateDFPStatus();
        this.setCalenderDateFormat();
    }
    ngOnDestroy() {
        if (this.assetStatusSubscription) {
            this.assetStatusSubscription.unsubscribe();
        }
        if (this.sharedService.statusConfig) {
            this.dataTypes = [];
            this.sharedService.statusConfig.DataType = [];
            this.sharedAccessExpiryTime = this.minDateValue;
        }
    }
    populateAircraft() {
        let userRole: string = localStorage.getItem('roles').trim();
        let body = { UserRole: userRole };
        this._assetService.getAllAirframe(this.sharedService.baseUrl, '')
            .subscribe({
                next: (response) => {
                    this.assets = response;
                },
                error: (error) => {
                    this.assets = [];
                    console.log(error);
                }
            });
    }

    populateDFPStatus() {
        this._viewService.getDFPStatus()
            .subscribe({
                next: (response) => {
                    this.dfpStatusList = response;
                },
                error: (error) => {
                    this.assets = [];
                    console.log(error);
                }
            });
    }

    populateStatusFeedMapping() {
        this.sharedService.showLoader("Loading...");
        let viewType = '';
        if (this.templateMapping) {

            switch (this.templateMapping) {
                case '{StatusViews}':
                    viewType = 'Status';
                    break;
                case '{RepairViews}':
                    viewType = 'Repair';
                    break;
                default:
                    viewType = '';
                    break;
            }
        } else {

            viewType = (this.router.url.indexOf("status/") != -1) ? 'Status' : ((this.router.url.indexOf("repair/") != -1) ? 'Repair' : '');
        }


        this._apiService.getStatusMapping(this.feedId, viewType)
            .subscribe({
                next: (response) => {
                    let responseBody = response;
                    this.model = response;
                    this.updateViewColumns()
                    // this.updateFieldLabels();
                    //populate Conditions for DFP URL AND and OR section
                    this.populateConditions();
                    this.feedName = responseBody.FeedName;
                    this.sharedService.hideLoader();
                    this.updateSelectAllCheckBox();
                },
                error: (error) => {
                    this.model.Map = [];
                    this.sharedService.hideLoader();
                    console.log(error);
                }
            });
    }

    dateChange() {
        let a = this.sharedAccessExpiryTime;
        if (this.sharedAccessExpiryTime == null || this.sharedAccessExpiryTime == undefined) {
            this.emailButtonDisable = true
        }
        else {
            this.emailButtonDisable = false
        }
    }
    confirmSave() {
        this.validateAndRemoveEmptyRowsOfMoreFilters()
        let selectedViews = this.model.Map.filter(m => m.isInclude == true).length;
        if (selectedViews > 10) {
            this._confirmationService.confirm({
                message: 'You have selected more than 10 Views, this may take quite a long time to download.',
                header: 'More than 10 Views',
                icon: 'fa fa-info-circle',
                accept: () => {
                    if (this.feedTypeInput == 'ExportTemp') {
                        this.sharedAccessExpiryTime = this.minDateValue;
                        this.TargetType = 'Email Link'
                        this.dateDisplay = true
                        // this.sharedAccessExpiryTime=this.minDateValue;
                        this.emailButtonDisable = false
                    }
                    else {
                        this.btnSaveClick();
                    }
                },
                reject: () => {
                }
            });
        } else {
            if (selectedViews && selectedViews > 0) {
                if (this.feedTypeInput == 'ExportTemp') {
                    this.TargetType = 'Email Link';
                    this.sharedAccessExpiryTime = this.minDateValue;
                    this.dateDisplay = true
                    // this.sharedAccessExpiryTime=this.minDateValue;
                    this.emailButtonDisable = false
                }
                else {
                    this.btnSaveClick();
                }
            }
            else {
                this.sharedService.clearToast();
                this.sharedService.showToast({ severity: 'warn', summary: 'No View Selected', detail: 'Please select a view.' });
            }

        }
    }

    validateAndRemoveEmptyRowsOfMoreFilters() {
        this.model.Map.forEach(view => {
            view.Conditions = view.Conditions.filter(a => a.ConditionType && a.FieldName != "" && a.Value)
            view.OrConditions = view.OrConditions.filter(a => a.ConditionType && a.FieldName != "" && a.Value)
        });
    }

    setCalenderDateFormat() {
        if (!dateTimeConfig.dateSeparator) {
            dateTimeConfig = this.sharedService.getDateTimeFormatConfigFromLocalStorage();
        }

        let dateParts: string[] = dateTimeConfig.dateformat.split(dateTimeConfig.dateSeparator);

        if (dateTimeConfig.year == 0) {
            this.dateFormatCalender = (dateParts[dateTimeConfig.year].substr(0, 2) + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day]).toLowerCase();
        }
        else if (dateTimeConfig.year == 2) {
            if (dateTimeConfig.month == 1) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
            else if (dateTimeConfig.month == 0) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
        }
        else {
            this.dateFormatCalender = "yy-mm-dd"
        }

    }

    btnExpiryTime() {
        this.dateDisplay = false;
        this.btnSaveClick();
    }
    confirmGenrate() {
        let selectedViews = this.model.Map.filter(m => m.isInclude == true).length;
        if (selectedViews > 10) {
            this._confirmationService.confirm({
                message: 'You have selected more than 10 Views, this may take quite a long time to download.',
                header: 'More than 10 Views',
                icon: 'fa fa-info-circle',
                accept: () => {
                    this.TargetType = 'My Report'
                    this.btnSaveClick();
                },
                reject: () => {
                }
            });
        } else {
            if (selectedViews && selectedViews > 0) {
                this.TargetType = 'My Report'
                this.btnSaveClick();
            }
            else {
                this.sharedService.clearToast();
                this.sharedService.showToast({ severity: 'warn', summary: 'No View Selected', detail: 'Please select a view.' });
            }

        }
    }

    confirmContainerExport() {
        let selectedViews = this.model.Map.filter(m => m.isInclude == true).length;
        if (selectedViews > 10) {
            this._confirmationService.confirm({
                message: 'You have selected more than 10 Views, this may take quite a long time to download.',
                header: 'More than 10 Views',
                icon: 'fa fa-info-circle',
                accept: () => {
                    this.TargetType = TargetType.ROAMContainer;
                    this.btnSaveClick();
                },
                reject: () => {
                }
            });
        } else {
            if (selectedViews && selectedViews > 0) {
                this.TargetType = TargetType.ROAMContainer;
                this.btnSaveClick();
            }
            else {
                this.sharedService.clearToast();
                this.sharedService.showToast({ severity: 'warn', summary: 'No View Selected', detail: 'Please select a view.' });
            }

        }
    }

    btnSaveClick() {

        let body: any = this.model;
        if (this.feedTypeInput == 'ExportTemp') {
            body.DataType = this.selectedDataType;
            body.TargetType = this.TargetType;
            if (this.TargetType == 'Email Link') {
                body.SharedAccessExpiryTime = this.sharedAccessExpiryTime;
            }
            if (this.TargetType == TargetType.ROAMContainer) {
                body.TargetDetail = this.targetdetail;

                        }
                    }
                    this.sharedService.showLoader("Processing...");
                    if (this.feedTypeInput == 'ExportTemp') {
                        this._apiService.addUpdateStatusMappingAndRunFeed(body)
                        .subscribe({
                            next: (response) => {
                                this.sharedService.hideLoader();
                                if(response == "Maximum limit exceeded")
                                {
                                    this.sharedService.showToast({ severity: 'warn', summary: 'Maximum limit exceeded', detail: 'Maximum limit for Email and My Report is 300 documents.' });
                                }
                                else{
                                let config: any = {
                                    showDialogue: false,
                                    showMessage: true
                                };
                                this.sharedService.changeStatusConfigDialogueStatus(config);
                                if (this.TargetType == 'Email Link') {
                                    this.sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'The Compilation process has now started, a URL to download the final compilation .ZIP file will be sent to you by email.' });
                                }
                                else {
                                    this.sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'The Compilation process has now started' });
                                }
                            }
                            },
                            error: (error) => {
                                this.sharedService.hideLoader();
                                this.sharedService.showToast({ severity: 'error', summary: 'Feed failed to start', detail: 'Request to run feed is un-successful.' });
                            }
                        });
                    }
                    else {
                        this._apiService.addUpdateStatusMapping(body)
                        .subscribe({
                            next: (response) => {
                                this.sharedService.hideLoader();
                                this.sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Configuration updated succesfully.' });
                                this.sharedService.onStatusConfigDialogueStatusChanged(false);
                            },
                            error: (error) => {
                                this.sharedService.hideLoader();
                                this.sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Configuration not updated, please try again.' });
                            }
                        });
                    }
    }
    btnExpiryCancelClick() {
        this.dateDisplay = false;
        //this.sharedAccessExpiryTime=this.minDateValue;
        //this.emailButtonDisable=false
    }

    btnCancelClick() {
        if (this.feedTypeInput == 'ExportTemp') {
            let config: any = {
                showDialogue: false,
                showMessage: false
            };
            this.sharedAccessExpiryTime = this.minDateValue;
            this.TargetType = '';
            this.selectedDataType = '';
            this.sharedService.changeStatusConfigDialogueStatus(false);

            /// Delete the current Feed
            let body: any =
            {
                FeedId: this.feedId
            }
            this._apiService.removeTempFeed(body)
                .subscribe({
                    next: (response) => {
                    },
                    error: (error) => {
                        this.sharedService.showToast(
                            {
                                severity: 'error',
                                summary: 'Error Occured',
                                detail: 'Error occurred while removing feed.'
                            }
                        );
                    }
                })
            this.router.navigate(["/status/viewlist"]);
        }
        else {
            // this.router.navigate([`/apifeed/${this.routeName}`]);
            this.sharedService.onStatusConfigDialogueStatusChanged(false);
        }

    }

    chechAll(event: any) {
        let checkAll = event.target.checked;
        for (var i = 0; i < this.model.Map.length; i++) {
            this.model.Map[i].isInclude = checkAll;
        }
    }

    updateSelectAllCheckBox() {
        if (this.model.Map.length == this.model.Map.filter(m => m.isInclude == true).length) {
            this.checkAll = true;
        }
    }
    setFeedDataType(event) {
        this.selectedDataType = event.value;
    }
    public selContainer = null;
    public targetdetail = null;
    onTargetContainerChange(event: any) {
        this.targetdetail = event.targetDetail;
        this.selContainer = event.selectedContainer;
        this.confirmContainerExport();
    }

    updateViewColumns()
    {
        this.model.Map.forEach(view => {
            view.Conditions = view.Conditions ?? [];
            view.OrConditions = view.OrConditions ?? [];
            view.Columns = this._view ?this._view.fieldList.map(a => new Column(a.label, a.label, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)):[];
        });
    }

    populateConditions() {
        this.conditions = [{ label: " is equal to", value: "=" },
        { label: "is not equal to", value: "!=" },
        { label: "is greater than", value: ">" },
        { label: " is less than", value: "<" },
        { label: "Not In", value: "<>" },
        { label: "In", value: "IN" },
        { label: " contains", value: "%%" }
        ];
    }
    AssignToken(_controlId: string, _token: string) {
        this._view.Conditions[_controlId].Value = _token;
    }

    AssignOrToken(_controlId: string, _token: string) {
        this._view.OrConditions[_controlId].Value = _token;
    }

    addCondition() {
        let cond = new Condition('');
        cond.JoinCondition = 'AND';
        this._view.Conditions.push(cond);

    }

    deleteCondition(index: number) {
        this._view.Conditions.splice(index, 1);
    }

    addOrCondition() {
        let cond = new Condition('');
        cond.JoinCondition = 'OR';
        this._view.OrConditions.push(cond);

    }

    deleteOrCondition(index: number) {
        this._view.OrConditions.splice(index, 1);
    }
    removeQuote(val: string) {
        val = val.replace(/'/g, '');
        val = val.replace(/"/g, '');
        return val;
    }

    openCloseMoreFiltersDialogue(flag: boolean,_view:StatusMapping,isBack:boolean=false) {
        this.displayStatusExport = flag;
        //managing state
        if(flag)
        { 
            this.view = _view
            //deepCopy
            _view && (this._view = JSON.parse(JSON.stringify(_view)));
        }

        //update dirty change
        if(!flag && isBack)
        {
            let index=this.model.Map.findIndex(v => v==this.view)
            this.model.Map[index] = this._view
        }
    }
    setFilterStyle(view:StatusMapping)
    {
        return {'font-size': view.Conditions.length>0 || view.OrConditions.length >0? 'large':'unset','color': view.Conditions.length>0 || view.OrConditions.length >0? 'black':'gray'};
    }
}
