import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';

import { SharedService } from '../../../shared/services/shared.service';

@Injectable()
export class MarkerTypeService {
    constructor(private http : HttpClient, private _sharedService: SharedService ) {
    }

    addUpdateMarkerType(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/MarkerType/AddUpdateMarkerType`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getMarkerTypeByID(body: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/MarkerType/GetMarkerTypeByID?markerTypeID=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getAllMarkerTypeData(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/MarkerType/GetAllMarkerTypeData`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getAllActiveMarkerTypeData(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/MarkerType/GetAllActiveMarkerTypeData`, body, this._sharedService.getReqOptWithAccessToken());
    }
}