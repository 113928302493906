export class CommPartial {
    constructor(
        public CommId?: string,
        public TaskId?: string,
        public ASN?: string,

        public Module?: string,

        public Section?: string,
        public Record?: string
    ) { }
}

export class CommSearch {
    constructor(
        public Search?: string,
        public FromDate?: Date,
        public ToDate?: Date
    ) { }
}