<article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable no-padding standardpadding" style="margin-left:0px" id="col1">
    <div class="jarviswidget" id="wid-id-1" data-widget-togglebutton="false" data-widget-sortable="false" data-widget-fullscreenbutton="false" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" role="widget">

        <header role="heading">
            <span class="widget-icon"><i [className]="iconClass"></i></span>
            <h2>Jobs <span *ngIf="totalCount">({{totalCount}})</span></h2>
            <div class="jarviswidget-ctrls" title="View Item" *ngIf="isJobModule">
                <a class="button-icon tablIndexFocus"  [attr.tabindex]="0"  (keyup.enter)="viewItem()" id="btnviewjob" (click)="viewItem()">
                    <i class="fa fa-paperclip"></i>
                </a>
            </div>
            <!--<span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>-->
            <edit-job [recordType]="recordType" [jobId]="jobId" *ngIf="isEditVisible || isEditVisibleReadonly"></edit-job>
            <add-job  [recordType]="recordType"  *ngIf="isAddVisible"></add-job>

            <div class="jarviswidget-ctrls pdfoperations" role="menu" >
                <div class="btn-group">
                    <a class="button-icon dropdown-toggle tablIndexFocus" [attr.tabindex]="0"  data-toggle="dropdown" aria-expanded="true" title="Download">
                        <i class="glyphicon glyphicon-save"></i>
                    </a>
                    <ul class="dropdown-menu pull-right js-status-update">
                        <li>
                            <a href="javascript:void(0);" (click)="exportExcelGrid()"><i class="fa fa-columns txt-color-green"></i>&nbsp;Excel (Ctrl+Z)</a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" (click)="exportGridData()">
                                <i class="fa fa-save txt-color-green"></i>&nbsp;CSV (Ctrl+X)
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="float-div-right" *ngIf='isJobModule'>
                <div class="jarviswidget-ctrls" title="Help">
                    <app-component-articles [componentName]="componentName">
                    </app-component-articles>
                </div>
            </div>
            <div class="float-div-right" *ngIf="userType=='Internal'">
                <label for="allrecords" class="checkbox col-sm-1 no-margin">

                    <span class="onoffswitch tablIndexFocus" [attr.tabindex]="0">
                        <input type="checkbox" name="allrecords" class="onoffswitch-checkbox" id="allrecords" [(ngModel)]="isCurrentUserOnly" (ngModelChange)="showAllUsersRecords()">
                        <label class="onoffswitch-label" for="allrecords"><span class="onoffswitch-inner" data-swchon-text="My" data-swchoff-text="All"></span><span class="onoffswitch-switch"></span></label>
                    </span>
                </label>
            </div>

        </header>

        <!-- widget div-->
        <div class="widgetdiv no-padding standardpadding" role="content"> <!--set-screen-width-->
            <!--style="height: 535px;"-->
            <!-- widget edit box -->
            <!--<div class="jarviswidget-editbox">

            </div>-->
            <!-- end widget edit box -->
            <!-- widget content -->
            <div class="widget-body set-widget-body-width">
                <div>
                    <fieldset>
                        <div class="form-group">
                            <div id="s1">

                                <!--<div class="jqgridboxcontainer" *ngIf="this.gridData.length > 0">
            <ag-grid-angular   #agGridlistJobs [gridOptions]="gridOptions" [excelStyles]="excelStyles" [columnDefs]="columnDefs" (rowClicked)='clickedJobRow($event)' (rowDoubleClicked)="jobRowDoubleClicked($event)" pagination=true paginationPageSize="20" [rowData]="gridData" sortable="true"  style="width: 100% !important;height:82vh !important;" class="ag-theme-balham  set-grid-width">-->
                                <div id="agGridlistJobsDiv" class="jqgridboxcontainer" *ngIf="this.gridData.length > 0">
                                    <ag-grid-angular id="agGridlistJobs" [navigateToNextCell]="navigateToNextCell"  #agGridlistJobs [gridOptions]="gridOptions" [excelStyles]="excelStyles" [columnDefs]="columnDefs" (rowClicked)='clickedJobRow($event)' (rowDoubleClicked)="jobRowDoubleClicked($event)" [rowData]="gridData" sortable="true" filter=true style="width: 100% !important;" class="ag-theme-balham  set-grid-width jobsgrid">
                                    </ag-grid-angular>
                                </div>
                                <div id="nojoblist" class="alert alert-block alert-warning" *ngIf="this.gridData.length == 0">

                                    <h4 class="alert-heading">Warning!</h4>
                                    No Results Found!
                                </div>
                            </div>

                        </div>
                    </fieldset>
                </div>

            </div>
            <!-- end widget content -->
        </div>

        <!-- end widget div -->
    </div>
    <!-- end widget -->
</article>
