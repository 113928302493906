//Components
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { SelectItem } from 'primeng/api'
import { NgForm } from '@angular/forms';
import { GridOptions } from "ag-grid-community";
import { AssetService } from '../../services/asset/asset.service';
import { AssetModel } from '../../services/asset/asset'
import { SearchService } from '../../services/search/search.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
    selector: 'add-aircraft',
    templateUrl: './add-aircraft.component.html',
    providers: [AssetService, SearchService]
})
export class AddAircraftComponent implements OnInit {

    public addAircraftPath: string = "/admin/asset";
    public gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[];
    public canAddEditAirCraft: boolean = false;
    public isSysAdmin: boolean = false;
    isSubmitted: boolean = false;
    display: boolean = false;
    displayStorageConfirmation: boolean = false;

    model: AssetModel;
    assetTypeList: SelectItem[] = [];
    searchDatabaseList: SelectItem[] = [];
    ASNTitle: string = "";
    runAutoGapAnalyser: string = "";
    ASNTitleAirframe: string = "Asset Serial No.";
    ASNTitleCollection: string = "Number";

    ManufacturerTitle: string = "";
    ManufacturerTitleAirframe: string = "Manufacturer";
    ManufacturerTitleCollection: string = "Group Level 1";

    TypeTitle: string = "";
    TypeTitleAirframe: string = "Type";
    TypeTitleCollection: string = "Group Level 2";

    VariantTitle: string = "";
    VariantTitleAirframe: string = "Variant";
    VariantTitleCollection: string = "Group Level 3";
    asnReconfirm: string = null;
    isSubmittedConfirm: boolean = false;
    public isAdminAircraft: boolean = false
    public disableReadOnlyButton: boolean = false;

    billingPlanList: any[] = [];
    isDisplayBillingPlanForm: boolean = false;
    selectedBillingPlan: any = null;
    htmlBillingPlanDescription: SafeHtml;

    constructor(public sharedService: SharedService, private router: Router, private route: ActivatedRoute, private assetManagementService: AssetService, private adminSearchService: SearchService, private sanitizer: DomSanitizer) {
        if (!this.sharedService.isAuthUser()) {
            this.router.navigate(['login']);
            this.sharedService.SessionExpiredMessage();
        }
        else {
            this.updateRoles();
        }
    }
    ngOnInit() {
        if (this.canAddEditAirCraft) {
            this.model = new AssetModel();
            this.model.SearchDatabaseID = "0";
            this.model.Status = true;
            this.model.ReadOnly = false;
            this.getAssetTypes();
            this.getAutoRunGapAnalyserValue();
            this.setDefaultValues();
            this.populateSearchDatabase();
            this.updateReadOnly();

            this.populateBillingPlans();
        }
    }

    populateBillingPlans() {
        this.billingPlanList = [];

        this.assetManagementService.getAllBillingPlans()
        .subscribe({
            next: (response) => {
                this.billingPlanList = response;
            },
            error: (error) => {
                console.log(error)
            }
        });
    }

    updateReadOnly() {
        if (this.isSysAdmin) {
            this.disableReadOnlyButton = false;
        } else {
            if (this.isAdminAircraft && this.model.ReadOnly == false) {
                this.disableReadOnlyButton = false;
            } else {
                this.disableReadOnlyButton = true;
            }
        }

    }

    btnSubmitClick(form: NgForm) {
        
        if (!this.isASNValid()) {
            if (form != null) {
                this.displayStorageConfirmation = true;
                this.display = false;
                //form.resetForm();
            }
            else {
                this.displayStorageConfirmation = false;
                //this.changeButtonsStatus(true);
                this.model.AssetType = this.model.AssetType;
                // Always be parent and all the three options are checked by default
                this.model.Level = this.assetManagementService.AssetLevelParent;
                if (this.model.MXRecords == null) {
                    this.model.MXRecords = false;
                }
                if (this.model.AutoGapAnalyser == null) {
                    this.model.AutoGapAnalyser = false;
                }
                if (this.model.LogBooks == null) {
                    this.model.LogBooks = false;
                }
                if (this.model.General == null) {
                    this.model.General = false;
                }
                if (this.model.Documents == null) {
                    this.model.Documents = false;
                }
                if (this.model.Binders == null) {
                    this.model.Binders = false;
                }
                
                if (this.model.AssetType != this.assetManagementService.AssetTypeAirframe && this.model.AssetType != this.assetManagementService.AssetTypeHelicopter) {
                    this.model.ReadOnly = true;
                }

                this.model.StoragePath = this.model.ASN.toLowerCase();
                let body = this.model;
                this.sharedService.showLoader("Processing...");
                this.assetManagementService.addAssetData(body)
                .subscribe({
                    next: (response) => {
                        this.display = false;
                        this.resetModel();
                        // form.resetForm();
                        this.sharedService.hideLoader();
                        this.sharedService.clearToast();
                        //this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Asset added succesfully.' });
                        let msgDictionary = response;
                        for (let msg of msgDictionary) {
                            this.sharedService.showToast({ severity: msg.Key, summary: msg.Key, detail: msg.Value });
                        }
                        this.changeButtonsStatus(false);
                        this.returntoAction(this.addAircraftPath);
                    },
                    error: (error) => {
                        this.sharedService.hideLoader();
                        this.sharedService.clearToast();
                        if (error.status === 409) {
                            this.sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Asset already exists.' });
                        }
                        else {
                            this.sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Asset not added, please try again.' });
                        }
                        this.display = false;
                        this.resetModel();
                        // this.changeButtonsStatus(false);
                    }
                });
            }
        }
        else {
            this.sharedService.clearToast();
            this.sharedService.showToast({ severity: 'warn', summary: 'Warning', detail: this.ASNTitle + ' can not contain space.' });
        }
    }
    isASNValid(): boolean {

        if (this.model && this.model.ASN) {
            return /\s/g.test(this.model.ASN);
        }
        else {
            return true;
        }
    }
    btnCancelClick(cancelForm: NgForm) {
        this.display = false;
        cancelForm.resetForm();
        this.model = new AssetModel();
    }


    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
        this.isSubmittedConfirm = status;
    }

    resetModel() {
        this.model = new AssetModel();

    }


    updateRoles() {
        if (!this.sharedService.UserRole) {
            this.sharedService.updateRole();
        }

        this.canAddEditAirCraft = this.sharedService.UserRole.Admin_Aircraft;
        this.isSysAdmin = this.sharedService.UserRole.Admin_Sys_Admin;
        this.isAdminAircraft = this.sharedService.UserRole.Admin_Aircraft;
    }

    returntoAction(url: string) {

        setTimeout(() => {

            this.router.navigateByUrl(url);

        },
            2000);
    }

    // #region Asset Type List
    getAssetTypes(): void {
        this.assetTypeList = this.assetManagementService.getAssetTypes();
    }
    getAutoRunGapAnalyserValue(): void {
        this.runAutoGapAnalyser = this.sharedService.getRunAutoGapAnalyserValue().toLocaleLowerCase();
    }
    //#endregion

    setDefaultValues() {
        this.model.AssetType = this.assetManagementService.AssetTypeAirframe;
        this.setFieldsTitle();
    }



    populateSearchDatabase() {
        this.searchDatabaseList = [];

        this.adminSearchService.getSearchDatabase()
        .subscribe({
            next: (response) => {
                let res = response;
                this.searchDatabaseList = res;
            },
            error: (error) => {
                console.log(error)
            }
        });
    }

    onAssetChange(event: any) {
        this.setFieldsTitle();
    }

    setFieldsTitle() {

        if (this.model.AssetType == this.assetManagementService.AssetTypeAirframe) {
            this.ASNTitle = this.ASNTitleAirframe;
            this.ManufacturerTitle = this.ManufacturerTitleAirframe;
            this.VariantTitle = this.VariantTitleAirframe;
            this.TypeTitle = this.TypeTitleAirframe;

            this.isCheckedReadOnly = false;
            this.model.ReadOnly == false;
            this.disableReadOnlyButton = false;

        }
        else if (this.model.AssetType == this.assetManagementService.AssetTypeHelicopter) {
            this.ASNTitle = this.ASNTitleAirframe;
            this.ManufacturerTitle = this.ManufacturerTitleAirframe;
            this.VariantTitle = this.VariantTitleAirframe;
            this.TypeTitle = this.TypeTitleAirframe;

            this.isCheckedReadOnly = false;
            this.model.ReadOnly == false;
            this.disableReadOnlyButton = false;

        }
        else {
            this.ASNTitle = this.ASNTitleCollection;
            this.ManufacturerTitle = this.ManufacturerTitleCollection;
            this.VariantTitle = this.VariantTitleCollection;
            this.TypeTitle = this.TypeTitleCollection;

            this.isCheckedReadOnly = true;
            this.model.ReadOnly == true;
            this.disableReadOnlyButton = true;

        }
    }
    btnCancelClickReconfirm(cancelForm: NgForm) {
        this.asnReconfirm = null;
        this.displayStorageConfirmation = false;
        cancelForm.resetForm();
    }
    onSubmitReconfirm(ReconfirmForm: NgForm) {
        //ReconfirmForm.resetForm();
        this.asnReconfirm = null;
        this.displayStorageConfirmation = false;
        this.btnSubmitClick(null);
    }

    btnCancel() {
        this.router.navigate([this.addAircraftPath]);
    }

    ChangePlan() {
        
        this.isDisplayBillingPlanForm = true;
        this.selectedBillingPlan = null;
        this.htmlBillingPlanDescription = null;
    }
    btnCloseClick(event: any) {
        
        this.isDisplayBillingPlanForm = false;
        this.selectedBillingPlan = null;
        this.htmlBillingPlanDescription = null;
        this.model.PlanID = null;
        this.model.PlanName=null
    }
    onlistboxSelectBillingPlanClicked(event: any) {        
        this.selectedBillingPlan = event.value;
        this.model.PlanID = event.value.PlanID;
        this.model.PlanName = event.value.Name;
        this.htmlBillingPlanDescription = this.sanitizer.bypassSecurityTrustHtml(event.value.Description);
        var styleClass: any = document.getElementsByClassName('p-dialog p-component p-component-content')[0];
        styleClass.style.top = "40px";
    }
    btnSelectBillingPlanClick(event: any) {
        
        //selectedBillingPlan
        //this.selectedBillingPlan;
        //this.model.AssetID;
        if (this.selectedBillingPlan != null) {
            this.model.PlanID = this.selectedBillingPlan.PlanID;
            this.isDisplayBillingPlanForm = false;
            //this.UpdateAssetBillingPlan();
        }

    }

    isCheckedStatus: boolean = false
    ToggleCheckedStatus() {

        if (this.isCheckedStatus) {
            this.isCheckedStatus = false;
            this.model.Status = false;
        }
        else {
            this.isCheckedStatus = true;
            this.model.Status = true;
        }
    }
    isCheckedReadOnly: boolean = false
    ToggleCheckedReadOnly() {

        if (this.isCheckedReadOnly) {
            this.isCheckedReadOnly = false;
            this.model.ReadOnly = false;
        }
        else {
            this.isCheckedReadOnly = true;
            this.model.ReadOnly = true;
        }
    }
    isCheckedMXRecord: boolean = false
    ToggleMXRecord() {

        if (this.isCheckedMXRecord) {
            this.isCheckedMXRecord = false;
            this.model.MXRecords = false;
        }
        else {
            this.isCheckedMXRecord = true;
            this.model.MXRecords = true;
        }
    }
    isCheckedLogBooks: boolean = false
    ToggleLogBooks() {

        if (this.isCheckedLogBooks) {
            this.isCheckedLogBooks = false;
            this.model.LogBooks = false;
        }
        else {
            this.isCheckedLogBooks = true;
            this.model.LogBooks = true;
        }
    }
    isCheckedGeneral: boolean = false
    ToggleGeneral() {

        if (this.isCheckedGeneral) {
            this.isCheckedGeneral = false;
            this.model.General = false;
        }
        else {
            this.isCheckedGeneral = true;
            this.model.General = true;
        }
    }
    isCheckedDocuments: boolean = false
    ToggleDocuments() {

        if (this.isCheckedDocuments) {
            this.isCheckedDocuments = false;
            this.model.Documents = false;
        }
        else {
            this.isCheckedDocuments = true;
            this.model.Documents = true;
        }
    }
    isCheckedBinders: boolean = false
    isAutoGapAnalyser: boolean = false
    ToggleBinders() {

        if (this.isCheckedBinders) {
            this.isCheckedBinders = false;
            this.model.Binders = false;
        }
        else {
            this.isCheckedBinders = true;
            this.model.Binders = true;
        }
    }
    ToggleAutoGapAnalyser() {

        if (this.isAutoGapAnalyser) {
            this.isAutoGapAnalyser = false;
            this.model.AutoGapAnalyser = false;
        }
        else {
            this.isAutoGapAnalyser = true;
            this.model.AutoGapAnalyser = true;
        }
    }
}