import { Component, OnInit, OnDestroy, ViewChild, ElementRef, SecurityContext,ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../shared/services/shared.service';
import { BrowserModule, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GridOptions } from "ag-grid-community";
import { SelectItem, ConfirmationService } from 'primeng/api';

import { TaskDocumentService } from '../../../admin/services/task-document/task-document.service';
import { CommPartial } from '../../../comm/components/comm/commpartial';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { EmbedVideoService } from 'ngx-embed-video';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { DFPStatusModel } from '../home/dfpstatus.model';
import { AzureBlobService } from '../../../shared/services/AzureBlob/azure-blob.service';
import { fabric } from 'fabric';
import { APIService } from '../../../api/services/api.service';
import { AzureBlob } from '../../../shared/services/AzureBlob/blob-metadata';
var dBdfpStatuses: DFPStatusModel[] = [];

@Component({
    selector: 'browse-pdf',
    templateUrl: './browse-pdf.component.html',
    providers: [TaskDocumentService, ConfirmationService, DashboardService, AzureBlobService, APIService]
})

export class BrowsePDFComponent implements OnInit, OnDestroy {

    @ViewChild('videoPlayer') _videoPlayer: ElementRef;

    @ViewChild('audioPlayer') _audioPlayer: ElementRef;

    uploadedFile: any = [];
    isFileSelected: boolean = false;

    public displayMode: string;
    recordType: string;
    subscription: Subscription;
    contSubscription: Subscription;
    selectedDoc: string;
    pdfSrc: SafeResourceUrl;
    sanitiz: DomSanitizer;
    zoomOption: SelectItem[] = [];
    selectedOption: string;
    selectedUrl: string;
    selectedVideoUrl: string;
    video_html: string = "";
    selectedZoom: string = "#zoom=auto";
    isCurrentModule: boolean = false;
    showStatusLinkDlg: boolean = false;
    public gridOptions: GridOptions;
    columnDefs: any[];
    selectedStatusLink: any[] = [];
    selectedTaskDocList: number[] = [];

    public canUpload: boolean = false;
    canMXRLinkToStatus: boolean = false;
    canLogLinkToStatus: boolean = false;
    canGenLinkToStatus: boolean = false;
    canDocLinkToStatus: boolean = false;

    canSearchLinkToStatus: boolean = false;
    displayissuedialogue: boolean = false;
    commobj: CommPartial = null;
    stateToggle: boolean = true;

    canDetachMXR: boolean = false;
    canDetachLog: boolean = false;
    canDetachGen: boolean = false;
    canDetachDoc: boolean = false;
    canDetachSearch: boolean = false;
    currentType: string = 'mxr';
    fileType: string = 'application/pdf';
    canvas: fabric.Canvas;
    canvasObjects: any[] = [];

    selectedMarkerType: any;
    zoomPercentage: number = 100;
    currentImg: any = null

    public appHotkeys: Hotkey[] = [];
    ///////////////////PDF Manipulation//////////////////

    canManipulatePDF: boolean = false;

    ///////////////////PDF Manipulation//////////////////

    public isIEOrEdge: boolean = false;
    showPDF_or_ImageViewer: boolean = true;
    showAudioPlayer: boolean = true;
    showVideoPlayer: boolean = true;
    isAudioTypeFound: string = null;
    isVideoTypeFound: string = null;

    isNonPreviewTypeFound: string = null;
    isDocumentTypeFound: string = null;
    isOfficeDocumentTypeFound: string = null;
    isImageTypeFound: string = null;
    public maxAllowedFileSize: number = 0;
    public maxAllowedFileSizeMB: number = 0;
    public acceptFileTypes = "";
    public showUpdateFileDlg: boolean = false;
    public showUploadAsImage = false;
    public disableImageLoadOption: boolean = false;
    public isImageSwitch:number=0;
    public BlobName:string;
    public componentName='RECP';
    constructor(private _sharedService: SharedService, private _router: Router, private sanitizer: DomSanitizer, private _taskDocumentService: TaskDocumentService,
        private _confirmationService: ConfirmationService, private _hotkeysService: HotkeysService, private embedVideoService: EmbedVideoService, private _dashboardService: DashboardService, private _azureBlobService: AzureBlobService, private _apiService: APIService,private cRef: ChangeDetectorRef) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.ISedgeBrowser();
            this.sanitiz = sanitizer;
            this.getZoomOptions();
            //this.setHotKeys();
            this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_MXR_LOG_GEN_CATEGORY;
            this.recordType = this._sharedService.recordType;
            if (this.recordType == "DOC" || this.recordType == "BND") {
                this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_DOC_CATEGORY + "," + this._sharedService.allowedAzureIndexFileContentTypes.toString();
            }

        }
    }

    ngOnInit() {
        this.maxAllowedFileSize = this._sharedService.getMaxDocumentLimitInBytes();
        this.maxAllowedFileSizeMB = this._sharedService.getMaxDocumentLimit();
        this.ISedgeBrowser();
        this.setHotKeys();
        //if (!this._sharedService.isAuthUser()) {
        //    this._router.navigate(['login']);
        //}
        this.getDFPStatus();
        this.initStatusLinkGrid();
        //Getting/Subscribe selected Document
        this.subscription = this._sharedService._selectedDocument
        .subscribe({
            next: (selDoc:any) => {
                if (selDoc == null) {
                    this.showPDF_or_ImageViewer = false;
                    this.isNonPreviewTypeFound='txt';
                    this.fileType = this.isNonPreviewTypeFound;
                    this.mimeTypeFileName = null;
                    this.mimeTypeImageSrc='/assets/img/MimeTypes/file.png'
                    this.selectedDocLength = 0
                    return;
                }
                ////R.A.
                //console.log("this.subscription = this._sharedService._selectedDocument.subscribe");
                if (selDoc && selDoc.Url) {
                    if (selDoc.Url && selDoc.Url.indexOf('api/Blob/GetDocument/') == -1) {
                        let body = { Url: selDoc.Url }
                        this._azureBlobService.getDocumentURLWithSASToken(body)
                        .subscribe({
                            next: (res) => {
                                selDoc.Url = res;
                                this.UpdateSelectedDoc(selDoc);
                                this.cRef.markForCheck();
                            },
                            error: (err) => {
                                console.log(err);
                            }
                        });
                    } else {
                        this.UpdateSelectedDoc(selDoc);
                    }
                } else {
                    this.UpdateSelectedDoc(selDoc);
                }
                this._sharedService.setBrowseDocumentHeight();
            },
            error: (error) => {
            }
        });
        this.contSubscription = this._sharedService._selectedContainer
        .subscribe({
            next: (response) => {
                this.UpdateRoles();
            },
            error: (error) => {
            }
        });

        this.isCurrentModule = this._sharedService.selectedModule == "record" || this._sharedService.selectedModule == "transition"
        this.setDisplayMode();
        this.UpdateRoles();
        this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_MXR_LOG_GEN_CATEGORY;
        this.recordType = this._sharedService.recordType;
        if (this.recordType == "DOC" || this.recordType == "BND") {
            this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_DOC_CATEGORY + "," + this._sharedService.allowedAzureIndexFileContentTypes.toString();
        }
        if (this.recordType == "BND") {
            this.componentName ='TRAP'
        }
        else{
            this.componentName='RECP'
        }

    }

    ISedgeBrowser() {
        this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    }

    ngOnDestroy() {
        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];


        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.contSubscription) {
            this.contSubscription.unsubscribe();
        }

    }
    mimeTypeFileName;
    mimeTypeImageSrc='';
    selectedDocLength=0;
    UpdateSelectedDoc(selDoc: any) {
        //console.trace();//R.A.
        this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_MXR_LOG_GEN_CATEGORY;
        this.recordType = this._sharedService.recordType;
        if (this.recordType == "DOC" || this.recordType == "BND") {
            this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_DOC_CATEGORY + "," + this._sharedService.allowedAzureIndexFileContentTypes.toString();
        }
        this.fileType = 'application/pdf';
        this.showPDF_or_ImageViewer = true;
        this.isVideoTypeFound = null;
        this.isAudioTypeFound = null;
        this.isNonPreviewTypeFound = null;
        this.isDocumentTypeFound = null;
        this.isOfficeDocumentTypeFound = null;
        this.isImageTypeFound = null;
        this.pdfSrc = null;
        this.mimeTypeFileName=selDoc.BlobName;
        this.selectedVideoUrl = null;
        this.selectedUrl = null;
        if (!selDoc || selDoc.length == 0) {
            this.fileType = '';
            this.resetCanvas();
            return;
        }
        this.selectedDocLength = selDoc.length;
        this.selectedUrl = selDoc.Url;
        if (selDoc.IsImage == "true") {
            this.fileType = 'image/png';
            this.showPDF_or_ImageViewer = true;
            //this.setCanvasBackgroundImage(selDoc.Url, true);

            //this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(doc.DocumentPath);
            setTimeout(() => {
                this.setCanvasBackgroundImage(selDoc.Url, true);
            }, 1000);
        }
        else if (selDoc.Url && (selDoc.VideoUrl == undefined || selDoc.VideoUrl == "")) {
            this.selectedUrl = selDoc.Url;
            let docType = "";
            let urlSegments = selDoc.Url.split('?');
            if (urlSegments.length == 2) {
                docType = urlSegments[0].split(".").pop();
            }
            else {
                //to do
                docType = urlSegments[0].split(".").pop();
            }
            if (docType) {
                this.resetCanvas();
                this.fileType = docType;

                this.isAudioTypeFound = this._sharedService.allowedAudioFileTypes.filter(x => x == docType)[0];
                this.isVideoTypeFound = this._sharedService.allowedVideoFileTypes.filter(x => x == docType)[0];
                this.isNonPreviewTypeFound = this._sharedService.allowedNonPreviewFileTypes.filter(x => x == docType)[0];
                this.isDocumentTypeFound = this._sharedService.allowedDocumentTypes.filter(x => x == docType)[0];
                this.isImageTypeFound = this._sharedService.allowedImageFileTypes.filter(x => x == docType)[0];

                this.selectedUrl = selDoc.Url;

                if (!this.isAudioTypeFound && !this.isVideoTypeFound &&
                    !this.isNonPreviewTypeFound && !this.isDocumentTypeFound && !this.isImageTypeFound) {
                    this.isNonPreviewTypeFound = docType;// handle blob with non-preview file type
                }


                if (this.isVideoTypeFound) {
                    this.fileType = this.isVideoTypeFound;
                    this.showPDF_or_ImageViewer = false;
                    selDoc.IsImage = "false";

                    //for VM case
                    if (this.selectedUrl.lastIndexOf('api/Blob/GetDocument/') >= 0) {
                        if (this.selectedUrl.lastIndexOf('.') >= 0) {
                            let lastIndex = this.selectedUrl.lastIndexOf('.');
                            //this.selectedUrl = this._azureBlobService.replaceAt(this.selectedUrl, lastIndex, 1, '^|^');
                            this.selectedUrl = this.selectedUrl;
                            this.load_VM_AudioVideo(this.selectedUrl);
                        }
                    }
                    else {//Azure
                        setTimeout(() => {
                            if (this._videoPlayer) {
                                const video: HTMLVideoElement = this._videoPlayer.nativeElement;
                                video.setAttribute('src', this.selectedUrl);
                                video.setAttribute('type', 'video/mp4');

                                video.load();
                                video.play();

                                //setTimeout(() => {
                                const vid: HTMLVideoElement = this._videoPlayer.nativeElement;
                                if (vid.error) {
                                    console.log("Video err: " + vid.error.code);
                                }
                                //}, 2000);
                            }
                        }, 2000);
                    }
                }
                else if (this.isAudioTypeFound) {
                    this.fileType = this.isAudioTypeFound;
                    this.showPDF_or_ImageViewer = false;
                    selDoc.IsImage = "false";
                    //for VM case
                    if (this.selectedUrl.lastIndexOf('api/Blob/GetDocument/') >= 0) {
                        if (this.selectedUrl.lastIndexOf('.') >= 0) {
                            let lastIndex = this.selectedUrl.lastIndexOf('.');
                            //this.selectedUrl = this._azureBlobService.replaceAt(this.selectedUrl, lastIndex, 1, '^|^');
                            this.selectedUrl = this.selectedUrl;
                            this.load_VM_AudioVideo(this.selectedUrl);
                        }
                    }
                    else {//Azure

                        setTimeout(() => {
                            if (this._audioPlayer) {
                                const audio: HTMLAudioElement = this._audioPlayer.nativeElement;
                                audio.setAttribute('src', this.selectedUrl);
                                audio.setAttribute('type', 'audio/mpeg');
                                audio.load();
                                audio.play();

                                //setTimeout(() => {
                                const aud: HTMLAudioElement = this._audioPlayer.nativeElement;
                                if (aud.error) {
                                    console.log("Audio err: " + aud.error.code);
                                }
                                //}, 2000);
                            }
                        }, 2000);
                    }

                }
                else if (this.isNonPreviewTypeFound) {
                    this.showPDF_or_ImageViewer = false;
                    selDoc.IsImage = "false";
                    this.fileType = this.isNonPreviewTypeFound;
                    let IsTypeFound = this._sharedService.allowedMimeTypesImages.filter(x=>x==this.fileType)[0]
                    if(IsTypeFound){
                        this.mimeTypeImageSrc='/assets/img/MimeTypes/'+this.fileType+'.png'
                    }
                    else{
                        this.mimeTypeImageSrc='/assets/img/MimeTypes/file.png'
                    }
                }
                else if (this.isImageTypeFound) {
                    this.fileType = 'image/png';
                    this.setCanvasBackgroundImage(selDoc.Url, true);
                }
                else if (this.isDocumentTypeFound) {
                    this.fileType = this.isDocumentTypeFound == 'pdf' ? 'application/pdf' : this.isDocumentTypeFound;
                    this.isOfficeDocumentTypeFound = this.isDocumentTypeFound != 'pdf' ? this.isDocumentTypeFound : null;

                    this.showPDF_or_ImageViewer = true;// this.isOfficeDocumentTypeFound ? false : true;
                    this.selectedUrl = selDoc.Url;
                    this.selectedVideoUrl = null;

                    // if (this.isOfficeDocumentTypeFound)
                    // {
                    //     //this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(this.selectedUrl);
                    //     //this.pdfSrc = this.sanitiz.sanitize(SecurityContext.URL, this.selectedUrl);//(this.selectedUrl);
                    //     this.pdfSrc= this.sanitizer.bypassSecurityTrustResourceUrl(this.selectedUrl);
                    // }
                    //else
                    {
                        if (this._sharedService.searchText && this._sharedService.searchText.indexOf('"') == -1) {
                            if (this.selectedUrl.indexOf('api/Blob/GetDocument/') != -1) {
                                this.loadImage(this.selectedUrl, false);
                            } else {
                                
                                this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(this.selectedUrl + this.selectedZoom + '&search="' + this._sharedService.searchText + '"');
                            }
                        } else {
                            if (this.selectedUrl.indexOf('api/Blob/GetDocument/') != -1) {
                                this.loadImage(this.selectedUrl, true);
                            } else {
                                if (this._sharedService.searchText == null) {
                                    this._sharedService.searchText = "";
                                }
                                this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(this.selectedUrl + this.selectedZoom + '&search="' + this._sharedService.searchText + '"&phrase=true');
                            }
                        }
                    }
                }
                else if (this.isImageTypeFound) {
                    this.fileType = 'image/png';
                    this.setCanvasBackgroundImage(selDoc.Url, true);
                }
            }
        }

        else {
            this.fileType = 'application/pdf';
            this.resetCanvas();
            if (selDoc.VideoUrl) {
                this.selectedVideoUrl = selDoc.VideoUrl;
                this.selectedUrl = selDoc.Url;
                try {
                    this.video_html = this.embedVideoService.embed(this.selectedVideoUrl, {
                        query: { portrait: 0, color: '333' },
                        attr: { width: '100%' }
                    });
                } catch (ex) {
                    console.log(ex);
                }
            } else {

                //this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(undefined);
                if (!selDoc.Url) {
                    this.selectedUrl = undefined;
                    return;
                }

                if (this.selectedUrl == selDoc.Url) {
                    return;
                }

                if (this._sharedService.searchText && this._sharedService.searchText.indexOf('"') == -1) {
                    if (this.selectedUrl.indexOf('api/Blob/GetDocument/') != -1) {
                        this.loadImage(this.selectedUrl, false);
                    } else {
                        this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(this.selectedUrl + this.selectedZoom + '&search="' + this._sharedService.searchText + '"');

                    }

                } else {

                    if (this.selectedUrl.indexOf('api/Blob/GetDocument/') != -1) {
                        this.loadImage(this.selectedUrl, true);
                    } else {
                        this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(this.selectedUrl + this.selectedZoom + '&search="' + this._sharedService.searchText + '"&phrase=true');
                    }
                }

                this.selectedUrl = selDoc.Url;
                this.selectedVideoUrl = null;

            }


        }
    }
    btnDownloadBlob() {
        if (this._sharedService.selectedDocument.Url.indexOf('?sv=') != -1) {
            var fileNameWOExt = this._sharedService.selectedDocument.BlobName.split('.')[0];
            this.downloadBlobFromStorage(this._sharedService.selectedDocument.Url, fileNameWOExt);
        }
        else {

            this._sharedService.showLoader("Processing...");
            this._azureBlobService.getPDFBlob(this._sharedService.selectedDocument.Url)
            .subscribe({
                next: (res:any) => {
                    this._sharedService.hideLoader();
                    let blob = new Blob([res], { type: res.type });
                    let url = URL.createObjectURL(blob);
                    //var fileNameWOExt = this._sharedService.selectedDocument.BlobName.split('.')[0];
                    //this.downloadBlobFromStorage(this._sharedService.selectedDocument.Url, this._sharedService.selectedDocument.BlobName);
                    this.downloadBlobFromStorage(url, this._sharedService.selectedDocument.BlobName);
                },
                error: (err) => {
                    this._sharedService.hideLoader();
                    console.log(err);
                }
            });
        }
    }


    downloadBlobFromStorage(fileURL: string, fileName: string) {
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = fileURL;
        a.target = '_blank';
        a.download = fileName;
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
    }


    onChange(event: any) {
        this.uploadedFile = [];
        if (event.srcElement.files.length > 0) {
            this.isFileSelected = true;
            // if (event.srcElement.files[0].type == 'application/pdf')
            //if (this._sharedService.allowedFileTypes.includes(event.srcElement.files[0].type.toString()) == true) {

            if (((this.recordType != 'DOC' && this.recordType != 'BND') && (this._sharedService.allowedImageFileContentTypes.includes(event.srcElement.files[0].type) || event.srcElement.files[0].type == 'application/pdf')) || (this.recordType == 'DOC' || this.recordType == 'BND')) {
                if (event.srcElement.files[0].size <= this._sharedService.MAX_ALLOWED_FILE_SIZE_FOR_DOC) {
                    if ((event.srcElement.files[0].size <= this.maxAllowedFileSize) || (event.srcElement.files[0].type != "application/pdf")) {
                        this.disableImageLoadOption = true;
                        if (this.recordType == "DOC" || this.recordType.toLowerCase() == "bnd") {
                            this.disableImageLoadOption = false;
                          //  this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_DOC_CATEGORY + "," + this._sharedService.allowedAzureIndexFileContentTypes.toString();
                        }
                        this.uploadedFile = event.srcElement.files
                        if (event.srcElement.files[0].type.indexOf('image/') != -1) {
                            this.showUploadAsImage = true;
                            this.isImageSwitch=1;
                        
                        } else {
                            this.showUploadAsImage = false;
                            this.isImageSwitch=0;
                           // this.disableImageLoadOption=false;
                        }
                    }
                    else {
                        this.isFileSelected = false;
                        this.uploadedFile = [];
                        event.srcElement.files = null;
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File', detail: 'File size exceeds the maximum limit of ' + this.maxAllowedFileSizeMB + ' MB for PDF or 1 GB for other types' });
                    }
                }
                else {
                    this.isFileSelected = false;
                    this.uploadedFile = [];
                    event.srcElement.files = null;
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File', detail: 'File size exceeds the maximum limit of ' + this.maxAllowedFileSizeMB + ' MB for PDF or 1 GB for other types' });
                }

            }
            else {
                this.uploadedFile = [];
                this.isFileSelected = false;
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Invalid File', detail: 'Only valid files are allowed' });

            }
        }
        else {
            this.uploadedFile = [];
            this.isFileSelected = false;
        }
    }
    loadImage(url: string, isPharase: Boolean): any {
        this._azureBlobService.getPDFBlob(url)
        .subscribe({
            next: (res) => {
                this.fileType = res.type;
                if (isPharase) {
                    this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(
                        URL.createObjectURL(res) + this.selectedZoom + '&search="' + this._sharedService.searchText + '"&phrase=true'
                    );
                } else {
        
                    this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(
                        URL.createObjectURL(res) + this.selectedZoom + '&search="' + this._sharedService.searchText + '"'
                    );
                }
            },
            error: (err) => {
                this.selectedUrl = undefined;
                //.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(undefined);
                console.log(err);
                //        // ERROR HANDLING HERE
            }
        });

    }
    load_VM_AudioVideo(url: string): any {
        this._azureBlobService.getPDFBlob(url)
        .subscribe({
            next: (res:any) => {
                this.resetCanvas();
                this.fileType = res.type;
                this.selectedUrl = URL.createObjectURL(res);
        
                if (this.fileType.indexOf('video/') != -1) {
        
                    this.isVideoTypeFound = this._sharedService.allowedVideoFileTypes.filter(x => x == this.fileType)[0];
                    if (this.isVideoTypeFound) {
        
                        setTimeout(() => {
                            if (this._videoPlayer) {
                                const video: HTMLVideoElement = this._videoPlayer.nativeElement;
                                video.setAttribute('src', this.selectedUrl);
                                video.setAttribute('type', this.fileType);
                                video.load();
                                video.play();
        
                                setTimeout(() => {
                                    const vid: HTMLVideoElement = this._videoPlayer.nativeElement;
                                    if (vid.error) {
                                        console.log("Video err: " + vid.error.code);
                                    }
                                }, 2000);
                            }
                        }, 2000);
                    }
                    else {
                        this.isNonPreviewTypeFound = this.fileType;
                    }
                }
                else {
        
                    this.isAudioTypeFound = this._sharedService.allowedAudioFileTypes.filter(x => x == this.fileType)[0];
                    if (this.isAudioTypeFound) {
                        setTimeout(() => {
                            if (this._audioPlayer) {
                                const audio: HTMLAudioElement = this._audioPlayer.nativeElement;
                                audio.setAttribute('src', this.selectedUrl);
                                audio.setAttribute('type', this.fileType);
                                audio.load();
                                audio.play();
        
                                //setTimeout(() => {
                                const aud: HTMLAudioElement = this._audioPlayer.nativeElement;
                                if (aud.error) {
                                    console.log("Audio err: " + aud.error.code);
                                }
                                //}, 2000);
                            }
                        }, 2000);
                    }
                    else {
                        this.isNonPreviewTypeFound = this.fileType;
                    }
                }
            },
            error: (err) => {
                this.selectedUrl = undefined;
                //.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(undefined);
                console.log(err);
                //        // ERROR HANDLING HERE
            }
        });
    }

    btnUploadBlob(event: any) {
        if (this.isFileSelected && this.uploadedFile.length > 0) {

            //if (this._sharedService.allowedFileTypes.includes(this.uploadedFile[0].type.toString()) == true) {
            if (((this.recordType != 'DOC' && this.recordType !== 'BND')
                && (this._sharedService.allowedImageFileContentTypes.includes(this.uploadedFile[0].type) || this.uploadedFile[0].type == 'application/pdf'))
                || (this.recordType == 'DOC' || this.recordType == 'BND')) {

                if (this.uploadedFile[0].size <= this._sharedService.MAX_ALLOWED_FILE_SIZE_FOR_DOC) {

                    if ((this.uploadedFile[0].size <= this.maxAllowedFileSize) || this.uploadedFile[0].type != "application/pdf") {
                        let xhr = new XMLHttpRequest(), formData = new FormData();

                        //Append File and Form Data in xhr Request
                        formData.append("uploads[]", this.uploadedFile[0], this.uploadedFile[0].name);
                        if(this.isImageSwitch==1){
                        formData.append("IsImage","False");
                        this.BlobName=this.uploadedFile[0].name.split('.')[0]+'.pdf';
                        formData.append("BlobName",this.BlobName);
                        }
                        else{
                            this.BlobName=this.uploadedFile[0].name;
                            formData.append("IsImage","True");
                            formData.append("BlobName",this.BlobName);
                        }
                        formData.append('ExistingBlobURL', this.selectedUrl);


                        let uploadUrl = `${this._sharedService.baseUrl}/api/AzureBlob/UpdateUploadBlob`;
                        xhr.open('POST', uploadUrl, true);
                        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
                        xhr.send(formData);
                        this._sharedService.showLoader("Processing...");
                        xhr.onreadystatechange = () => {
                            if (xhr.readyState === 4) {
                                this._sharedService.hideLoader();
                                if (xhr.status === 200) {
                                  // var data=  xhr.response;

                                   // this.UpdateSelectedDoc(data.Uri);
                                    this.showUpdateFileDlg = false;
                                    this.isFileSelected = false;
                                    this.uploadedFile = [];
                                    this._sharedService.clearToast();
                                    this._sharedService.showToast({ severity: 'success', summary: 'Document uploaded', detail: 'Document has been uploaded successfully.' });
                                    //    this.isDiaplayDialog = false;
                                    //    this.changeButtonsStatus(false);
                                    //    this.clearSingleFile();
                                    this.showUploadAsImage = false;
                                    this.disableImageLoadOption= false;
                                    this.isImageSwitch=0;
                                  
                                    this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                                    this._sharedService.onAssetChanged(this._sharedService.selectedAsset);//R.A 13-09-2020
                                    
                                }
                                else if (xhr.status == 501) {
                                    this.showUploadAsImage = false;
                                    this.disableImageLoadOption= false;
                                    this.isImageSwitch=0;
                                    this._sharedService.clearToast();
                                    this._sharedService.showToast({ severity: 'warn', summary: 'Unable to update document URL', detail: xhr.responseText });
                                    //    this.isDiaplayDialog = false;
                                    //    this.changeButtonsStatus(false);
                                    //    this.clearSingleFile();
                                    this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                                }
                                else {
                                    this.showUploadAsImage = false;
                                    this.disableImageLoadOption= false;
                                    this.isImageSwitch=0;
                                    //    this.isDiaplayDialog = false;
                                    //    this.changeButtonsStatus(false);
                                    this._sharedService.clearToast();
                                    this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while uploading document.' });

                                }
                            }
                        };
                    }
                    else {
                        this.isFileSelected = false;
                        this.uploadedFile = [];
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File', detail: 'File size exceeds the maximum limit of ' + this.maxAllowedFileSizeMB + ' MB for PDF or 1 GB for other types' })

                    }
                }
                else {
                    this.isFileSelected = false;
                    this.uploadedFile = [];
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File', detail: 'File size exceeds the maximum limit of ' + this.maxAllowedFileSizeMB + ' MB for PDF or 1 GB for other types' });

                }
            }
            else {
                this.uploadedFile = [];
                this.isFileSelected = false;
                event.srcElement.files = null;
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Invalid File', detail: 'Only valid files are allowed' });

            }
        }
        else {
            this.uploadedFile = [];
        }
    }
  
    onChange_UploadBlob(event: any) {
        this.onChange(event);
        //this.btnUploadBlob(event);
    }

    btnShowUpdateFileDlg() {
        this.showUpdateFileDlg = true;
    }

    btnCancelUpdateFileClick() {
        this.showUpdateFileDlg = false;
        this.showUploadAsImage = false;
        this.disableImageLoadOption= false;
        this.isImageSwitch=0;
    }

    setHotKeys() {

        let hotKeyObj: Hotkey = null;

        //Status Links
        hotKeyObj = new Hotkey('ctrl+shift+l', (event: KeyboardEvent): boolean => {
            this.showStatusLinksPopup();
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

        //Inbox
        hotKeyObj = new Hotkey('ctrl+shift+i', (event: KeyboardEvent): boolean => {
            this.showInboxPopup();
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

    }

    zoomChange(event: any) {
        if (this._sharedService.searchText && this._sharedService.searchText.indexOf('"') == -1) {
            if (this.selectedUrl.indexOf('api/Blob/GetDocument/') != -1) {
                this.loadImage(this.selectedUrl, false);
            } else {
                this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(this.selectedUrl + this.selectedOption + '&search="' + this._sharedService.searchText + '"');
            }
        } else {
            if (this.selectedUrl.indexOf('api/Blob/GetDocument/') != -1) {

                this.loadImage(this.selectedUrl, true);
            } else {
                this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(this.selectedUrl + this.selectedOption + '&search="' + this._sharedService.searchText + '"&phrase=true');
            }
        }
        this.selectedZoom = this.selectedOption;
    }

    getZoomOptions() {
        this.zoomOption.push({ value: '#zoom=auto', label: 'Automatic Zoom' })
        this.zoomOption.push({ value: '#zoom=scale', label: 'Actual Size' })
        this.zoomOption.push({ value: '#zoom=50', label: 'Page Fit' })
        this.zoomOption.push({ value: '#zoom=auto', label: 'Page Width' })
        this.zoomOption.push({ value: '#zoom=50', label: '50%' })
        this.zoomOption.push({ value: '#zoom=100', label: '100%' })
        this.zoomOption.push({ value: '#zoom=125', label: '125%' })
        this.zoomOption.push({ value: '#zoom=150', label: '150%' })
        this.zoomOption.push({ value: '#zoom=200', label: '200%' })
        this.zoomOption.push({ value: '#zoom=300', label: '300%' })
        this.zoomOption.push({ value: '#zoom=400', label: '400%' })
    }

    // #region Status Popup

    initStatusLinkGrid() {
        this.gridOptions = <GridOptions>
            {
                rowSelection: 'multiple',
                onGridReady: () => {
                    this.setStatusLinkGridColumns();
                    //this.gridOptions.api.sizeColumnsToFit();
                },
                suppressContextMenu: true,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    filter: true
                }
            };
    }

    setStatusLinkGridColumns(): void {
        this.columnDefs =
            [
                { headerName: ' ', field: '', minWidth: 22, maxWidth: 25, checkboxSelection: true, headerCheckboxSelection: true, filter: false, sortable: false, menuTabs: [] },
                //{ headerName: 'Seq No', field: 'TaskID', menuTabs: [], Width:90 },
                { headerName: 'ASN', field: 'ASN', menuTabs: ['filterMenuTab'], Width: 120 },
                { headerName: 'Task Type', field: 'TaskType', menuTabs: ['filterMenuTab'], Width: 120 },
                { headerName: 'DFP Status', field: 'DFPStatus', filter: false, suppressMenu: true, cellRenderer: this.statusDFPInnerCellRenderer, Width: 200 },
                { headerName: 'Comments', field: 'Comments', menuTabs: ['filterMenuTab'] },
            ];
    }

    statusDFPCellRenderer(params: any) {
        let html: string = '';

        if (params.value) {

            switch (params.value.trim()) {

                case 'New':
                    html = html + '<span class="label-default" style="padding: 5px;color: white;">' + params.value.trim() + '</span>';
                    break;
                case 'Suggested - no document found':
                    html = html + '<span style="padding: 3px;color:#a90329;">' + params.value.trim() + '</span>';
                    break;
                case 'Suggested - partial documents found':
                    html = html + '<span style="padding: 3px;color:#c79121;">' + params.value.trim() + '</span>';
                    break;
                case 'Suggested - documents found':
                    html = html + '<span style="padding: 3px;color:#71843f;">' + params.value.trim() + '</span>';
                    break;
                case 'Suggested - not applicable':
                    html = html + '<span style="padding: 3px;color:#999;">' + params.value.trim() + '</span>';
                    break;
                case 'No document found':
                    html = html + '<span class="label-danger" style="padding: 5px;color: white;">' + params.value.trim() + '</span>';
                    break;
                case 'Partial documents found':
                    html = html + '<span class="label-warning" style="padding: 5px;color: white;">' + params.value.trim() + '</span>';
                    break;
                case 'Documents found':
                    html = html + '<span class="bg-color-greenLight" style="padding: 5px;color: white;">' + params.value.trim() + '</span>';
                    break;
                case 'Not applicable':
                    html = html + '<span class="label-default" style="padding: 5px;color: white;">' + params.value.trim() + '</span>';
                    break;

                default:
                    break;
            }
        }

        var eDiv = document.createElement('div');
        eDiv.innerHTML = html;
        eDiv.style.textAlign = 'center';
        return eDiv;
    }

    showStatusLinksPopup() {
        this.clearAndCloseStatusPopup();
        this.UpdateRoles();
        this.showStatusLinkDlg = true;
        this.populateDocReference();
    }

    updateSelectedLinks(event: any) {
        //this.selectedTaskDocList.push(event.data.TaskDocumentId);
    }

    btnDetachClick() {
        //let selectedLink = this.gridOptions.api.getSelectedRows().map(row => { return {TaskDocumentId: row.TaskDocumentId}; });
        let selectedLink = this.gridOptions.api.getSelectedRows().map(row => {
            return {
                TaskDocumentId: row.TaskDocumentId,
                DocumentPath: this.selectedUrl
            };
        });
        if (selectedLink && selectedLink.length > 0) {
            this._confirmationService.confirm({
                key: "confirmdetachdoc",
                message: "Do you really want to detach document(s)?",
                accept: () => {
                    this._sharedService.showLoader("Processing...");
                    let viewTaskDocument = {
                        TaskDocumentList: selectedLink,
                        View: { Source: true }
                    }

                    this._taskDocumentService.removeTaskDocuments(viewTaskDocument)
                    .subscribe({
                        next: (response) => {
                            this._sharedService.hideLoader();
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Document(s) have been detached succesfully.' });
                            this.clearAndCloseStatusPopup();
                        },
                        error: (error) => {
                            this._sharedService.hideLoader();
                            console.log(error);
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'error', summary: 'Error Occurred', detail: 'Please try again later.' });
                        }
                    });
                },
                reject: () => {

                }
            });

        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Warning', detail: 'No task/document selected.' });
        }

    }

    btnCancelDlgClick() {
        this.clearAndCloseStatusPopup();
    }

    clearAndCloseStatusPopup() {
        this.showStatusLinkDlg = false;
        this.selectedStatusLink = [];
        this.selectedTaskDocList = [];
    }

    populateDocReference() {
        if (this.selectedUrl) {
            this.selectedStatusLink = [];
            this._taskDocumentService.getDocumentTask(this.selectedUrl)
            .subscribe({
                next: (response) => {
                    this.selectedStatusLink = response;
                    this.gridOptions.api.sizeColumnsToFit();
                },
                error: (error) => {
                    console.log(error);
                    this.selectedStatusLink = [];
                    this.gridOptions.api.sizeColumnsToFit();
                }
            });
        }
    }

    // #endregion

    //#region Wide / Full Screen
    widePdfPanal() {
        if ((this._router.url.indexOf("/record/search") == -1)
            && (this._router.url.indexOf("/transition/search") == -1)) {
            this.wideRecordPdfPanal();
        }
        else {
            this.wideSearhPdfPanal();
        }
    }
    wideRecordPdfPanalTitle="Expand half";
    wideRecordPdfPanal() {
        let containerPanel = document.getElementById('col1');
        let docPanel = document.getElementById('col2');
        let pdfPanel = document.getElementById('col3');
        let btnFullScreen = document.getElementById('btnPdfFullScreen');

        if (pdfPanel.classList.contains('col-sm-6')) {
            //Remove old Classes
            pdfPanel.classList.remove('col-sm-6', 'col-md-6', 'col-lg-6');
            //Add new Classes
            pdfPanel.classList.add('col-sm-9', 'col-md-9', 'col-lg-9');
            containerPanel.style.display = 'none';
            //Update Doc Panel if it's wide
            if (docPanel.classList.contains('col-sm-6')) {
                docPanel.classList.remove('col-sm-6', 'col-md-6', 'col-lg-6');
                docPanel.classList.add('col-sm-3', 'col-md-3', 'col-lg-3');
            }
            this.wideRecordPdfPanalTitle = "Back to normal view";
            //Disable Full Screen Button
            btnFullScreen.classList.add('disable-anchor');

        } else {
            //Remove old Classes
            pdfPanel.classList.remove('col-sm-9', 'col-md-9', 'col-lg-9');
            //Add new Classes
            pdfPanel.classList.add('col-sm-6', 'col-md-6', 'col-lg-6');
            containerPanel.style.display = 'block';
            this.wideRecordPdfPanalTitle = "Expand half";
            //Enable Full Screen Button
            btnFullScreen.classList.remove('disable-anchor');
        }

        if (this.fileType == 'image/png') {
            this.resetCanvas('image/png');
            this.initCanvas();
            this.resetBackgroundImage(false);

        }
    }

    wideSearhPdfPanal() {
        let filterPanel = document.getElementById('search-col1');
        let docPanel = document.getElementById('search-col2');
        let pdfPanel = document.getElementById('col3');
        let btnFullScreen = document.getElementById('btnPdfFullScreen');

        if (pdfPanel.classList.contains('col-sm-6')) {
            //Remove old Classes
            pdfPanel.classList.remove('col-sm-6', 'col-md-6', 'col-lg-6');
            //Add new Classes
            pdfPanel.classList.add('col-sm-8', 'col-md-8', 'col-lg-8');
            filterPanel.style.display = 'none';
            //Update Doc Panel if it's wide
            if (docPanel.classList.contains('col-sm-6')) {
                docPanel.classList.remove('col-sm-6', 'col-md-6', 'col-lg-6');
                docPanel.classList.add('col-sm-4', 'col-md-4', 'col-lg-4');
            }

            //Disable Full Screen Button
            btnFullScreen.classList.add('disable-anchor');

        } else {
            //Remove old Classes
            pdfPanel.classList.remove('col-sm-8', 'col-md-8', 'col-lg-8');
            //Add new Classes
            pdfPanel.classList.add('col-sm-6', 'col-md-6', 'col-lg-6');
            filterPanel.style.display = 'block';

            //Enable Full Screen Button
            btnFullScreen.classList.remove('disable-anchor');
        }
    }
    fullScreenPdfPanalTitle="Expand full"
    fullScreenPdfPanal() {
        let containerPanel, docPanel;
        let pdfPanel = document.getElementById('col3');
        let btnWideScreen = document.getElementById('btnPdfWideScreen');

        if ((this._router.url.indexOf("/record/search") == -1)
            && (this._router.url.indexOf("/transition/search") == -1)) {
            containerPanel = document.getElementById('col1');
            docPanel = document.getElementById('col2');
        }
        else {
            containerPanel = document.getElementById('search-col1');
            docPanel = document.getElementById('search-col2');
        }

        if (pdfPanel.classList.contains('col-sm-6')) {
            //Remove old Classes
            pdfPanel.classList.remove('col-sm-6', 'col-md-6', 'col-lg-6');
            //Add new Classes
            pdfPanel.classList.add('col-sm-12', 'col-md-12', 'col-lg-12');
            containerPanel.style.display = 'none';
            docPanel.style.display = 'none';
            this.fullScreenPdfPanalTitle="Back to normal view";
            //Disable Wide Screen Button
            btnWideScreen.classList.add('disable-anchor');

        } else {
            //Remove old Classes
            pdfPanel.classList.remove('col-sm-12', 'col-md-12', 'col-lg-12');
            //Add new Classes
            pdfPanel.classList.add('col-sm-6', 'col-md-6', 'col-lg-6');
            containerPanel.style.display = 'block';
            docPanel.style.display = 'block';
            this.fullScreenPdfPanalTitle = "Expand full";
            //Enable Wide Screen Button
            btnWideScreen.classList.remove('disable-anchor');
        }
    }

    //#endregion

    setDisplayMode() {
        let url = this._router.url;
        if (url.indexOf("/record/") > -1) {
            this.displayMode = "record";
        } 
        else if (url.indexOf("/transition/") > -1) {
            this.displayMode = "transition";
        }
        else if (url.indexOf("/status/") > -1) {
            this.displayMode = "status";
        } else if (url.indexOf("/admin/") > -1) {
            this.displayMode = "admin";
        }
    }
    showInboxPopup() {
        this.commobj = new CommPartial();
        let url = this._router.url;
        let module = "Record";
        if (url.indexOf("/record/") > -1) {
            module = "Record";
        } else if (url.indexOf("/transition/") > -1) {
            module = "Transition";
        }


        //US-8181 //this.commobj.ASN = this._sharedService.selectedAsset[0].data.split('|')[1];
        this.commobj.ASN = JSON.parse(this._sharedService.selectedAsset[0].data).ASN;
        this.commobj.Module = module;//"Record";
        this.commobj.Record = this.getFilePath(this.selectedUrl);
        this.commobj.Section = this._sharedService.selectedContainer.ContainerID;
        this._sharedService.setModulesSelectedOptionForComm(this.commobj);

        this.displayissuedialogue = true;

    }
    getFilePath(fileUrl: string): string {
        if (fileUrl) {
            let hostname = (new URL(fileUrl)).hostname;

            let file = fileUrl.split(hostname)[1].split('?')[0];
            return file;

        }
        return null;
    }
    //#region Roles
    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }
        this.canManipulatePDF = false;
        let url = this._router.url;

        if (url.indexOf("/record/browse") > -1) {
            this.canMXRLinkToStatus = this._sharedService.UserRole.Records_MXR_Doc_LinkStatus;
            this.canManipulatePDF = this._sharedService.UserRole.Records_MXR_Doc_Edit_PDF;
            this.canDetachMXR = this._sharedService.UserRole.Records_MXR_Doc_Detach;
            this.canUpload = this._sharedService.UserRole.Records_MXR_Doc_Single_Upload;
            this.currentType = 'mxr';
        }
        else if (url.indexOf("/record/logs") > -1) {
            this.canLogLinkToStatus = this._sharedService.UserRole.Records_LOG_Doc_LinkStatus;
            this.canManipulatePDF = this._sharedService.UserRole.Records_LOG_Doc_Edit_PDF;
            this.canDetachLog = this._sharedService.UserRole.Records_LOG_Doc_Detach;
            this.canUpload = this._sharedService.UserRole.Records_LOG_Doc_Single_Upload;
            this.currentType = 'log';
        }
        else if (url.indexOf("/record/general") > -1) {
            this.canGenLinkToStatus = this._sharedService.UserRole.Records_GEN_Doc_LinkStatus;
            this.canManipulatePDF = this._sharedService.UserRole.Records_GEN_Doc_Edit_PDF;
            this.canDetachGen = this._sharedService.UserRole.Records_GEN_Doc_Detach;
            this.canUpload = this._sharedService.UserRole.Records_GEN_Doc_Single_Upload;
            this.currentType = 'gen';
        }
        else if (url.indexOf("/record/documents") > -1) {
            this.canDocLinkToStatus = this._sharedService.UserRole.Records_DOC_Doc_LinkStatus;
            this.canManipulatePDF = this._sharedService.UserRole.Records_DOC_Doc_Edit_PDF;
            this.canDetachDoc = this._sharedService.UserRole.Records_DOC_Doc_Detach;
            this.canUpload = this._sharedService.UserRole.Records_DOC_Doc_Single_Upload;
            this.currentType = 'doc';
        }
        else if (url.indexOf("/transition/binders") > -1) {
            this.canDocLinkToStatus = this._sharedService.UserRole.Transition_BND_Doc_LinkStatus;
            this.canManipulatePDF = this._sharedService.UserRole.Transition_BND_Doc_Edit_PDF;
            this.canDetachDoc = this._sharedService.UserRole.Transition_BND_Doc_Detach;
            this.canUpload = this._sharedService.UserRole.Transition_BND_Doc_Single_Upload;
            this.currentType = 'bnd';
        }
        else if (url.indexOf("/record/search") > -1) {
            this.canSearchLinkToStatus = this._sharedService.UserRole.Records_OCRSearch_Doc_LinkStatus;
            this.canDetachSearch = this._sharedService.UserRole.Records_OCRSearch_Doc_Detach
            this.currentType = 'search';
        }
        else if (url.indexOf("/transition/search") > -1) {
            this.canSearchLinkToStatus = this._sharedService.UserRole.Transition_OCRSearch_Doc_LinkStatus;
            this.canDetachSearch = this._sharedService.UserRole.Transition_OCRSearch_Doc_Detach
            this.currentType = 'search';
        }
    }
    getDFPStatus() {
        this._dashboardService.getAllDFPStatuses()
        .subscribe({
            next: (response) => {
                dBdfpStatuses = [];
                dBdfpStatuses = response;
            },
            error: (error) => {
            }
        });
    }
    statusDFPInnerCellRenderer(params: any) {
        var eSpan = document.createElement('span');
        if (params.value) {
            let dfpStatusName: string = params.value.trim();
            let currentStatus: DFPStatusModel = dBdfpStatuses.filter(x => x.Name == dfpStatusName)[0];
            if (currentStatus && currentStatus.Name) {
                if (currentStatus.Name == 'Suggested - no document found' || currentStatus.Name == 'Suggested - partial documents found' || currentStatus.Name == 'Suggested - documents found' || currentStatus.Name == 'Suggested - not applicable') {
                    eSpan.style.color = currentStatus.CSSClass;
                }
                else {
                    eSpan.style.color = 'white';
                    eSpan.style.backgroundColor = currentStatus.CSSClass;
                }
            }
        }

        eSpan.style.padding = '3px';
        eSpan.innerHTML = params.value;

        var eDiv = document.createElement('div');
        eDiv.appendChild(eSpan);
        eDiv.style.textAlign = 'center';
        return eDiv;
    }
    //#endregion

    onKeyDocumentInbox() {

        this.displayissuedialogue = false;

    }

    //#region Fabricjs ImageViewer
    initCanvas(isFullScreen: boolean = false) {
        if (this.canvas) {
            this.canvas.clear();
            //this.canvas.dispose();
        }

        this.canvas = new fabric.Canvas('recordCanvas', { selection: false });

        //Reset Canvas Width
        this.canvas.setWidth(0);
        this.canvas.setHeight(0);
        this.zoomPercentage = 100;
        //Set Canvas Width
        let selectedElement = document.getElementById('documentview');
        if (!isFullScreen) {
            this.canvas.setWidth(Math.floor(selectedElement.clientWidth));
            this.canvas.setHeight(Math.floor(selectedElement.clientHeight - 25));
            if (selectedElement.classList.contains('fullscreen')) { selectedElement.classList.remove("fullscreen"); }
        } else {
            if (!selectedElement.classList.contains('fullscreen')) { selectedElement.classList.add("fullscreen"); }
            this.canvas.setWidth(Math.floor(selectedElement.clientWidth));
            this.canvas.setHeight(Math.floor(selectedElement.clientHeight));
        }

        this.canvas.preserveObjectStacking = true;

    }

    resetBackgroundImage(fitToImageSize: boolean = true, zoomFactor: number = 1) {

        let imgObj = new fabric.Image(this.currentImg);
        if (fitToImageSize) {
            this.canvas.setWidth(Math.floor(this.currentImg.width));
            this.canvas.setHeight(Math.floor(this.currentImg.height));


        } else {
            let docPanal = document.getElementById('documentview');

            let canvasDiv = document.getElementById('recordCanvasDiv');
            canvasDiv.style.width = Math.floor(docPanal.clientWidth * zoomFactor).toString() + 'px';
            canvasDiv.style.height = Math.floor((docPanal.clientHeight - 25) * zoomFactor).toString() + 'px';

            if ((docPanal.clientWidth * zoomFactor) > this.currentImg.width) {
                this.canvas.setWidth(Math.floor(docPanal.clientWidth));
                canvasDiv.style.overflowX = 'hidden';
            } else {
                this.canvas.setWidth(Math.floor(this.currentImg.width));
                canvasDiv.style.overflowX = 'scroll';
            }

            if ((docPanal.clientHeight * zoomFactor) > this.currentImg.height) {
                this.canvas.setHeight(Math.floor(docPanal.clientHeight));
                canvasDiv.style.overflowY = 'hidden';
            } else {
                this.canvas.setHeight(Math.floor(this.currentImg.height));
                canvasDiv.style.overflowY = 'scroll';
            }
        }

        this.canvas.setBackgroundImage(imgObj, this.canvas.renderAll.bind(this.canvas), {
            // width: this.canvas.width,
            // height: this.canvas.height,
            originX: 'left',
            originY: 'top',
            // scaleX: this.canvas.width / img.width,
            // scaleY: this.canvas.height / img.height
        });

        // let height = this.canvas.height / this.currentImg.height;
        // let width = this.canvas.width / this.currentImg.width;      
        // let delta = height < width? height : width;
        // if(delta < 1){
        //     this.btnZoomOut(delta);
        // }


    }


    setCanvasBackgroundImage(imageUrl: any, initCanvas: boolean = false) {

        if (initCanvas) { this.initCanvas(); }
        if (imageUrl.indexOf('api/Blob/GetDocument/') != -1) {

            this._azureBlobService.getPDFBlob(imageUrl)
            .subscribe({
                next: (res) => {
                    let url = URL.createObjectURL(res);
        
                    fabric.util.loadImage(url, (img) => {
                        if (!img) { return; }
                        this.currentImg = img;
                        let docPanal = document.getElementById('documentview');
        
                        let canvasDiv = document.getElementById('recordCanvasDiv');
                        canvasDiv.style.width = Math.floor(docPanal.clientWidth).toString() + 'px';
                        canvasDiv.style.height = Math.floor(docPanal.clientHeight - 25).toString() + 'px';
        
                        if (docPanal.clientWidth > this.currentImg.width) {
                            this.canvas.setWidth(Math.floor(docPanal.clientWidth));
                            canvasDiv.style.overflowX = 'hidden';
                        } else {
                            this.canvas.setWidth(Math.floor(this.currentImg.width));
                            canvasDiv.style.overflowX = 'scroll';
                        }
        
                        if (docPanal.clientHeight > this.currentImg.height) {
                            this.canvas.setHeight(Math.floor(docPanal.clientHeight));
                            canvasDiv.style.overflowY = 'hidden';
                        } else {
                            this.canvas.setHeight(Math.floor(this.currentImg.height));
                            canvasDiv.style.overflowY = 'scroll';
                        }
        
                        let imgObj = new fabric.Image(img);
                        this.canvas.setBackgroundImage(imgObj, this.canvas.renderAll.bind(this.canvas), {
                            // width: this.canvas.width,
                            // height: this.canvas.height,
                            originX: 'left',
                            originY: 'top',
                            // scaleX: this.canvas.width / img.width,
                            // scaleY: this.canvas.height / img.height
                        });
        
                        // let height = this.canvas.height / img.height;
                        // let width = this.canvas.width / img.width;      
                        // let delta = height < width? height : width;
                        // if(delta < 1){
                        //     this.btnZoomOut(delta);
                        // }
        
                        //}, null, 'anonymous');
                    }, { crossOrigin: 'anonymous' });
                },
                error: (err) => {
                    console.log(err);
                }
            });
        } else {
            imageUrl = imageUrl.indexOf('?') == -1 ? imageUrl : imageUrl.split('?')[0];
            this._azureBlobService.getBlob(imageUrl)
            .subscribe({
                next: (res) => {
                    let url = URL.createObjectURL(res);
        
                    fabric.util.loadImage(url, (img) => {
                        if (!img) { return; }
                        this.currentImg = img;
                        let docPanal = document.getElementById('documentview');
        
                        let canvasDiv = document.getElementById('recordCanvasDiv');
                        canvasDiv.style.width = Math.floor(docPanal.clientWidth).toString() + 'px';
                        canvasDiv.style.height = Math.floor(docPanal.clientHeight - 25).toString() + 'px';
        
                        if (docPanal.clientWidth > this.currentImg.width) {
                            this.canvas.setWidth(Math.floor(docPanal.clientWidth));
                            canvasDiv.style.overflowX = 'hidden';
                        } else {
                            this.canvas.setWidth(Math.floor(this.currentImg.width));
                            canvasDiv.style.overflowX = 'scroll';
                        }
        
                        if (docPanal.clientHeight > this.currentImg.height) {
                            this.canvas.setHeight(Math.floor(docPanal.clientHeight));
                            canvasDiv.style.overflowY = 'hidden';
                        } else {
                            this.canvas.setHeight(Math.floor(this.currentImg.height));
                            canvasDiv.style.overflowY = 'scroll';
                        }
        
                        let imgObj = new fabric.Image(img);
                        this.canvas.setBackgroundImage(imgObj, this.canvas.renderAll.bind(this.canvas), {
                            // width: this.canvas.width,
                            // height: this.canvas.height,
                            originX: 'left',
                            originY: 'top',
                            // scaleX: this.canvas.width / img.width,
                            // scaleY: this.canvas.height / img.height
                        });
        
                        // let height = this.canvas.height / img.height;
                        // let width = this.canvas.width / img.width;      
                        // let delta = height < width? height : width;
                        // if(delta < 1){
                        //     this.btnZoomOut(delta);
                        // }
        
                        //}, null, 'anonymous');
                    }, { crossOrigin: 'anonymous' });
                },
                error: (err) => {
                    console.log(err);
                }
            });
        }
    }

    btnZoomIn() {
        let delta = 1.1;//opt.e.deltaY;
        let zoom = this.canvas.getZoom();
        zoom = zoom * delta;
        if (zoom > 20 || this.zoomPercentage == 300) {
            zoom = this.canvas.getZoom();
            this.zoomPercentage = this.zoomPercentage;
        } else {
            this.zoomPercentage = this.zoomPercentage + 10;
            this.canvas.setZoom(zoom);
            let zoomFactor = this.zoomPercentage / 100;

            let docPanal = document.getElementById('documentview');
            let canvasDiv = document.getElementById('recordCanvasDiv');
            canvasDiv.scrollTop = 0;
            canvasDiv.scrollLeft = 0;
            let canvasWidth = Math.floor(this.currentImg.width * zoom);
            let canvasHeight = Math.floor(this.currentImg.height * zoom);

            if (docPanal.clientWidth > canvasWidth) {
                this.canvas.setWidth(docPanal.clientWidth);
                canvasDiv.style.overflowX = 'hidden';
            } else {
                this.canvas.setWidth(canvasWidth);
                canvasDiv.style.overflowX = 'scroll';
            }

            if (docPanal.clientHeight > canvasHeight) {
                this.canvas.setHeight(docPanal.clientHeight);
                canvasDiv.style.overflowY = 'hidden';
            } else {
                this.canvas.setHeight(canvasHeight);
                canvasDiv.style.overflowY = 'scroll';
            }
        }
    }

    btnZoomOut(deltaNumber: any) {
        let delta = 1.1;   //opt.e.deltaY;
        let zoom = this.canvas.getZoom();
        zoom = zoom / delta;
        if (zoom < 0.1 || this.zoomPercentage == 10) {
            zoom = this.canvas.getZoom();
            this.zoomPercentage = this.zoomPercentage;
        } else {
            this.canvas.setZoom(zoom);
            this.zoomPercentage = this.zoomPercentage - 10;
            let zoomFactor = this.zoomPercentage / 100;

            let docPanal = document.getElementById('documentview');
            let canvasDiv = document.getElementById('recordCanvasDiv');
            canvasDiv.scrollTop = 0;
            canvasDiv.scrollLeft = 0;
            let canvasWidth = Math.floor(this.currentImg.width * zoom);
            let canvasHeight = Math.floor(this.currentImg.height * zoom);

            if (docPanal.clientWidth > canvasWidth) {
                this.canvas.setWidth(docPanal.clientWidth);
                canvasDiv.style.overflowX = 'hidden';
            } else {
                this.canvas.setWidth(canvasWidth);
                canvasDiv.style.overflowX = 'scroll';
            }

            if (docPanal.clientHeight > canvasHeight) {
                this.canvas.setHeight(docPanal.clientHeight);
                canvasDiv.style.overflowY = 'hidden';
            } else {
                this.canvas.setHeight(canvasHeight);
                canvasDiv.style.overflowY = 'scroll';
            }
        }
    }


    btnMove(direction: string) {
        let units = 10;
        let delta = null;
        switch (direction) {
            case 'left':
                delta = new fabric.Point(-units, 0);
                break;
            case 'right':
                delta = new fabric.Point(units, 0);
                break;
            case 'up':
                delta = new fabric.Point(0, -units);
                break;
            case 'down':
                delta = new fabric.Point(0, units);
                break;
            default:
                break;
        }

        if (delta) {
            this.canvas.relativePan(delta);
        }

    }

    resetCanvas(fileType: string = 'application/pdf') {
        this.fileType = fileType;

        this.isVideoTypeFound = undefined;
        this.isAudioTypeFound = undefined;
        this.isNonPreviewTypeFound = undefined;
        if (this.canvas) {
            //Reset Canvas Width
            this.canvas.setWidth(0);
            this.canvas.setHeight(0);

            this.canvas.clear();
        }

        if (this._videoPlayer) {
            const video: HTMLVideoElement = this._videoPlayer.nativeElement;
            video.pause();
            video.currentTime = 0;
        }
        if (this._audioPlayer) {
            const audio: HTMLAudioElement = this._audioPlayer.nativeElement;
            audio.pause();
            audio.currentTime = 0;
        }
    }

    //#endregion


    //#region download document content
    Download() {

        // let selectedContainer=this._sharedService.selectedContainer;

        // let selectedSearchKeywords = {

        //     ContainerIndex: "blobindex",
        //     Container: this.selectedContainer.ContainerName,
        //     ContainerID: this.selectedContainer.ID,
        //     ContainerType: this.selectedContainer.Type,
        //     AssetMSNFacets: assetASN,
        //     SearchQuery: this.formattedSearchkeyword,//will be a plain text
        //     SearchTerm: this.searchMode,
        //     Top: "1000"
        // }

        let selectedDocument = this._sharedService.selectedDocument;
        let selectedDocumetURi = "";
        if (selectedDocument.VideoUrl) {
            selectedDocumetURi = selectedDocument.VideoUrl;
        }
        else {
            selectedDocumetURi = selectedDocument.Url;
        }
        let docName = selectedDocumetURi ? selectedDocumetURi.split('/').pop().split('?')[0] : "";//this.docUrl;
        if (!docName) {
            return;
        }

        this._apiService.downloadSearchDocument(docName)
        .subscribe({
            next: (response) => {
                let resList = response;
                if (resList) {
                    this.downloadFile(docName, resList);
                }
            },
            error: (error) => {
                this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Not found, please try again later.' });
            }
        });
    }

    downloadFile(docName: string, content: any) {
        //docName = docName.replace(".pdf", ".txt").replace(".PDF", ".txt");
        let docNamenExt = docName.split('.');
        if (docNamenExt.length >= 2) {
            let docExt = '.' + docNamenExt[1];
            docName = docName.replace(docExt, ".txt");
        }
        else {
            docName = docName.replace(".pdf", ".txt").replace(".PDF", ".txt");
        }
        let blob = new Blob([content], { type: 'application/text' });
        let url = URL.createObjectURL(blob);
        let fileName = docName;
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.target = '_blank';
        a.download = fileName;
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    //#endregion



}
