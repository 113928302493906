import { Component } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';

@Component({
  selector: 'app-export-container',
  template: `<export-container-configuration *ngIf="config"
              [sourceType]="config.sourceType" [isTempFeed]="true"
              [isRecordExport]="config.isRecordExport"
              [redirectUri]="config.redirectUri" ></export-container-configuration>`
})
export class ExportContainerComponent {

  /**
   *
   */
  constructor(private _route: ActivatedRoute) {
    this.showExportContainerConfiguration()
  }
  

  routeParams;
  config: { sourceType: any, redirectUri: string, isRecordExport: boolean };
  showExportContainerConfiguration() {
    let params: ActivatedRouteSnapshot = this._route.snapshot;
    this.routeParams = params.data[0];
    this.config = {
      sourceType: this.routeParams.sourceType, // 'Container Export',
      redirectUri: this.routeParams.redirectUri,
      isRecordExport: this.routeParams.isRecordExport
    };
  }
  
}
