import { Router, ActivatedRoute } from '@angular/router';
import { ArticleService } from './../../services/article/article.service';
import { SharedService } from './../../../shared/services/shared.service';
import { NgForm } from '@angular/forms';
import { articleModel } from './../articles/article';
import { articleSectionModel } from './../section/section';
import { SelectItem } from 'primeng/api';
import { Component, OnInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';

import { EditorModule } from '@tinymce/tinymce-angular';
import { UserService } from '../../../records/services/User/user.service';

declare const tinymce: any;
@Component({
  selector: 'add-article',
  templateUrl: './add-article.component.html',
  styles: []
})

export class AddArticleComponent implements OnInit {
  @ViewChild('editor') editor: any;

  public screenName: string = 'Add Article';
  selectCategory: SelectItem[];
  public model: articleModel;
  public isSubmitted: boolean = false;
  public btnlabel = 'save';
  public canAddEditArticle: boolean = false;
  public addArticlestPath: string = "/help/articles";
  ArticleCategoryList: SelectItem[] = [];
  ArticleSectionList: SelectItem[] = [];
  public ArticlestPath: string = "/help/articles";
  public fileupload: any;
  public init;
  public uploadfile: '';
  selectSection: articleSectionModel;
  public EditorKey = '';
  tenantSelectionList: SelectItem[] = [];
  availableRoles = [];
  selectedRoles = [];
  availableComponents = [];
  selectedComponents = [];
  displayArticleUserRole: boolean = false;
  /*  @ViewChild('hiddenInput1') hiddenInput1:ElementRef; */

  constructor(private _sharedService: SharedService, private _articleService: ArticleService, private router: Router, route: ActivatedRoute, private _userService: UserService) {

    if (!this._sharedService.isAuthUser()) {
      this.router.navigate(['login']);
    }
    else {
      this.updateRoles();
    }


  }

  ngOnInit() {
    this.availableRoles = [];
    this.selectedRoles = [];
    this.availableComponents = [];
    this.selectedComponents = [];
    this.availableComponents = this._sharedService.getHelpArticleComponents();
    this.getAllRolesAndGroups();
    this.getListArticleCategory();
    this.resetModel();
    this.populateTenantsList();
    this.init = [];
    this.EditorKey = this._sharedService.TinyMecAPIKey;
    this.init = ({
      selector: 'textarea',
      plugins: 'preview fullpage  fullscreen link table image media  code  codesample  fullscreen hr  file ',
      statusbar: false,
      menubar: false,
      media_filter_html: true,
      media_live_embeds: true,
      toolbar: "styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | fontsizeselect |fontselect | link | image |media |insert|code | codesample|hr |preview |fullscreen ",
      automatic_uploads: true,
      file_picker_types: 'file image media',
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*,.doc,.docx,.txt,.rtf,.odt,.pdf,.mp4');

        input.onchange = function () {
          var file = input.files[0];
          var reader = new FileReader();

          reader.onload = function () {
            var id = 'blobid' + (new Date()).getTime();
            var blobCache = tinymce.activeEditor.editorUpload.blobCache;
            // var blobCache =this.editor.nativeElement.value.get("editor").editorUpload.blobCache;
            // var blobCache =this.editor.nativeElement.value.editorUpload.blobCache;
            var base64 = (<string>reader.result).split(',')[1];
            var blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);

            // call the callback and populate the Title field with the file name
            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      }

    })
  }

  updateRoles() {
    if (!this._sharedService.UserRole) {
      this._sharedService.updateRole();
    }

    this.canAddEditArticle = true;

  }
  populateTenantsList() {
    this._userService.getAllTenantsList()
      .subscribe({
        next: (response) => {
          this.tenantSelectionList = response;

          this.model.SelectedTenants = [];
          this.model.Tenants = null;

          //select all tenants initially
          this.tenantSelectionList.forEach(_tenant => {
            this.model.SelectedTenants.push(_tenant.value);
          });
        },
        error: (error) => {
        }
      });
  }

  getListArticleCategory() {
    this._articleService.getListArticleCatgory(null)
      .subscribe({
        next: (response) => {
          this.ArticleCategoryList = response;
        },
        error: (error) => {
        }
      });
  }

  getArticleSectionbyCategory(event) {

    let ArticleCategoryId = event;
    this._articleService.getArticleSectionbyCategory(ArticleCategoryId)
      .subscribe({
        next: (response) => {
          this.ArticleSectionList = response;
        },
        error: (error) => {
        }
      });
  }

  returntoAction(url: string) {

    setTimeout(() => {

      this.router.navigateByUrl(url);

    },
      2000);
  }

  resetModel() {
    this.model = new articleModel();
    this.model.Status = false;
    this.model.Color = '#0976e3';
    this.model.ArticleSectionName = '';
    this.model.ArticleSectionName = '';
    this.model.ArticleCategoryId = null;
    this.model.ArticleSectionId = null;

  }

  btnCancelClick() {

    this.returntoAction(this.ArticlestPath);
  }
  btnSubmitClick(form: NgForm) {

    this.isSubmitted = true;
    let article = this.model;

    if (this.model.SelectedTenants == undefined || this.model.SelectedTenants.length == 0) {
      article.Tenants = null;
    }
    else if (this.model.SelectedTenants.length == this.tenantSelectionList.length) {
      article.Tenants = null;
    }
    else {
      article.Tenants = article.SelectedTenants.toString();
    }
    if (this.selectedRoles.length == 0 || !this.model.showRoles) {
      article.UserRoles = null;
    } else {
      article.UserRoles = JSON.stringify(this.selectedComponents);// this.selectedRoles.map(p => p.Name.trim()).toString();
    }
    if (this.selectedComponents.length == 0) {
      article.ArticleComponents = null;
    } else {
      article.ArticleComponents = JSON.stringify(this.selectedComponents);// this.selectedRoles.map(p => p.Name.trim()).toString();
    }
    this.addArticle(article, form);
  }

  addArticle(body: articleModel, from: NgForm) {

    this._sharedService.showLoader("Processing...");
    this._articleService.addArticle(body)
      .subscribe({
        next: (response) => {
          this._sharedService.hideLoader();
          this.changeButtonsStatus(false);
          // this.resetModel();
          this.returntoAction(this.addArticlestPath);
        },
        error: (error) => {
          this._sharedService.hideLoader();
          this._sharedService.clearToast();
          if (error.status === 409) {
            this._sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Article  already exists.' });
          }
          else {
            this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Article should have some text.' });
          }

          this.resetModel();
          this.changeButtonsStatus(false);
        }
      })
  }

  changeButtonsStatus(status: boolean) {
    this.isSubmitted = status;
  }

  getAllRolesAndGroups() {
    this._userService.getAllRolesAndGroups(this._sharedService.baseUrl, null)
      .subscribe({
        next: (response) => {
          this.availableRoles = response;
          //this.cRef.markForCheck();

          if (this.model.UserRoles != null && this.model.UserRoles != undefined) {
            this.model.showRoles = true;
          }
          else {
            this.model.showRoles = false;
          }
        },
        error: (error) => {
        }
      });
  }

  openAssignedUserRolesDlg() {

    this.displayArticleUserRole = true;
    if (this.model.UserRoles != null && this.model.UserRoles != undefined && this.model.UserRoles != '[]') {
      this.model.showRoles = true;
    }
    else {
      this.model.showRoles = false;
    }
  }

  onSubmit(form: NgForm) {
    let _availableRoles = this.availableRoles;
    let _selectedRoles = this.selectedRoles;

    // get role names only
    let _selectedArticleRoles = this.selectedRoles.map(p => p.Name.trim()).toString();
    this.model.UserRoles = JSON.stringify(this.selectedRoles);//_selectedArticleRoles;
    this.model.ArticleComponents = JSON.stringify(this.selectedComponents);//_selectedArticleRoles;
    this.displayArticleUserRole = false;
  }

  btnCancelArticleUserRoleClick(cancelForm: NgForm) {
    this.displayArticleUserRole = false;
    cancelForm.resetForm();
  }

}
