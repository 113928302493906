<add-comm *ngIf="isAddVisible" [browseDocumentParams]="statusTaskObj" [recordType]="recordType" [isDirectModal]="true"></add-comm>
<p-confirmDialog id="confirmDlg" header="Confirmation" key="confirmDlg" icon="fa fa-question-circle"
    [style]="{width: '450px'}"></p-confirmDialog>
<p-confirmDialog header="Warning!" key="confirmTaskStatusChange" icon="fa fa-question-circle" acceptLabel="Continue"
    rejectLabel="Cancel" [style]="{width: '488px'}"></p-confirmDialog>
<form #containerForm="ngForm">
    <section id="widget-grid" class="adminaircraftcls" (click)="onPanalsClick($event)">
        <div class="row">
            <article (click)="onPanalsClick($event)"
                [ngClass]="{'col-sm-6 col-md-6 col-lg-6 sortable-grid p-sortable': (!feedlogid || feedlogid=='0' ),'col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable':(!feedlogid &&  feedlogid!='0')}"
                id="listcol1">
                <!--<article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable" id="col1">-->
                <div class="no-margin jarviswidget jarviswidget-sortable" id="wid-id-2" data-widget-colorbutton="false"
                    data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false"
                    data-widget-togglebutton="false" role="widget">
                    <header role="heading" id="taskHeader" class="p-sortable-handle" style="cursor: pointer;">
                        <span class="widget-icon"><i class="fa fa-list"></i></span>
                        <h2>Task List <span *ngIf="totalCount">({{totalCount}})</span><span id="lastUpdatedSpan"
                                *ngIf="lastUpdatedDate!=''" style="font-size: smaller;"> - [Last updated
                                {{lastUpdatedDate}}]</span></h2>
                        <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>
                        <div class="jarviswidget-ctrls" *ngIf="!feedlogid">
                            <a title={{wideStatusViewListPanalTotle}} [attr.tabindex]="0"
                                class="button-icon tablIndexFocus" id="btnListWideScreen"
                                (keyup.enter)="wideStatusViewListPanal()" (click)="wideStatusViewListPanal()">
                                <!--(click)="wideRecordDocPanal()"-->
                                <i class="glyphicon glyphicon-resize-horizontal"></i>
                            </a>
                        </div>
                        <div class="jarviswidget-ctrls" title="Run Auto Search Batch Job" role="menu"
                            *ngIf="!feedlogid && canViewInRecord && !isProcessed && canRunSearchAutoLink">
                            <a [attr.tabindex]="0" class="button-icon tablIndexFocus" id="btnrunFeed"
                                (keyup.enter)="RunAutoSearch()" (click)="RunAutoSearch()">
                                <i class="glyphicon glyphicon-play"></i>
                            </a>
                        </div>

                        <div class="jarviswidget-ctrls pdfoperations" title="Run Auto Search Batch Job" role="menu"
                            *ngIf="!feedlogid && canViewInRecord && isProcessed && canRunSearchAutoLink">
                            <!--canDownloadPDF &&-->
                            <div class="btn-group" *ngIf="!feedlogid && canViewInRecord && isProcessed">
                                <a [attr.tabindex]="0" class="tablIndexFocus button-icon dropdown-toggle"
                                    data-toggle="dropdown" aria-expanded="true" title="Run">
                                    <i class="glyphicon glyphicon-play"></i>
                                </a>
                                <ul class="dropdown-menu pull-right js-status-update">
                                    <li>
                                        <a [attr.tabindex]="0" (click)="RunAutoSearch()" (keyup.enter)="RunAutoSearch()"
                                            class="tablIndexFocus">
                                            <i class="fa fa-play txt-color-green">
                                            </i>&nbsp;Run Another Job</a>
                                    </li>
                                    <li>
                                        <a [attr.tabindex]="0" (keyup.enter)="showRunningTasksbyView()"
                                            (click)="showRunningTasksbyView()" class="tablIndexFocus">
                                            <i class="fa fa-tasks txt-color-green"></i>&nbsp;Show Progress
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>



                        <!-- <div class="jarviswidget-ctrls" title="Run Auto Search" role="menu" *ngIf="!feedlogid && canViewInRecord">
                            <a class="button-icon" id="btnShowFeed" (click)="showRunningTasksbyView()">
                                <i class="glyphicon glyphicon-play"></i>
                            </a>
                        </div> -->
                        <taskcomponent (addUpdateTaskItem)="onAddUpdateTaskItem($event)"
                            [currentViewColDef]="columnDefs" [asn]="asn" [currentViewId]="ViewSettingId"
                            [feedLogId]="taskFeedlogId" [dfpStatusList]="filteredDfpStatusList"
                            [selectedTask]="selectedTask" [isInternalView]="isInternalView"
                            *ngIf="!feedlogid && canViewInRecord"></taskcomponent>
                        <!-- <div class="jarviswidget-ctrls" role="menu" *ngIf="!feedlogid && canViewInRecord && canAddTask">
                            <a class="button-icon" id="btnAddTask" (click)="showAddTask()" title="Add Entry (Ctrl+A)">
                                <i class="glyphicon glyphicon-plus"></i>
                            </a>
                        </div>
                        <div class="jarviswidget-ctrls" role="menu"
                            *ngIf="!feedlogid && canViewInRecord && canEditTask">
                            <a class="button-icon" id="btnEditTask" (click)="showEditTask()"
                                title="Edit Entry (Ctrl+E)">
                                <i class="glyphicon glyphicon-pencil"></i>
                            </a>
                        </div> -->
                        <div class="jarviswidget-ctrls" role="menu"
                            *ngIf="!feedlogid && canViewInRecord && canDeleteTask && isInternalView">
                            <a [attr.tabindex]="0" (keyup.enter)="showDeleteTask()" class="button-icon tablIndexFocus"
                                id="btnDeleteTask" (click)="showDeleteTask()" title="Delete Entry (Ctrl+D)">
                                <i class="glyphicon glyphicon-trash"></i>
                            </a>
                        </div>

                        <div class="jarviswidget-ctrls" role="menu"
                            *ngIf="!feedlogid && canViewInRecord && canViewStatusAudit && isInternalView">
                            <a class="button-icon tablIndexFocus" id="btnTaskHistory" [attr.tabindex]="0"
                                (keyup.enter)="showTaskHistory()" (click)="showTaskHistory()"
                                title="Show History (Ctrl+Shift+H)">
                                <i class="fa fa-history"></i>
                            </a>
                        </div>
                        <div class="jarviswidget-ctrls pdfoperations" role="menu"
                            *ngIf="!feedlogid && canViewInRecord && chartMenuList && chartMenuList.length > 0">
                            <div class="btn-group">
                                <a class="button-icon dropdown-toggle tablIndexFocus" data-toggle="dropdown"
                                    [attr.tabindex]="0" aria-expanded="true" title="View Graph">
                                    <i class="fa fa-bar-chart"></i>
                                </a>
                                <ul class="dropdown-menu pull-right js-status-update">
                                    <li *ngFor="let chart of chartMenuList;">
                                        <a href="javascript:void(0);" class="tablIndexFocus" [attr.tabindex]="0"
                                            (keyup.enter)="generateStatusChart(chart.label)"
                                            (click)="generateStatusChart(chart.label)"><i
                                                class="fa fa-bar-chart txt-color-green"></i>&nbsp;{{chart.label}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="jarviswidget-ctrls pdfoperations" role="menu" *ngIf="!feedlogid && canViewInRecord">
                            <div class="btn-group">
                                <a class="button-icon dropdown-toggle tablIndexFocus" data-toggle="dropdown"
                                    [attr.tabindex]="0" aria-expanded="true" title="Download">
                                    <i class="glyphicon glyphicon-save"></i>
                                </a>
                                <ul class="dropdown-menu pull-right js-status-update">
                                    <li>
                                        <a href="javascript:void(0);" class="tablIndexFocus" [attr.tabindex]="0"
                                            (keyup.enter)="exportExcelGrid()" (click)="exportExcelGrid()"><i
                                                class="fa fa-columns txt-color-green"></i>&nbsp;Excel (Ctrl+Z)</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);" class="tablIndexFocus" (click)="exportGridData()"
                                            [attr.tabindex]="0" (keyup.enter)="exportGridData()">
                                            <i class="fa fa-save txt-color-green"></i>&nbsp;CSV (Ctrl+X)
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div *ngIf="!feedlogid && canViewInRecord && canViewFeedFromStatus && isInternalView"
                            class="jarviswidget-ctrls" role="menu">
                            <a class="button-icon btnShowFeed tablIndexFocus" id="btnShowFeed" (click)="btnShowFeed()"
                                [attr.tabindex]="0" (keyup.enter)="btnShowFeed()" title="API">
                                <i class="glyphicon glyphicon-import"></i>
                            </a>
                        </div>
                        <div class="jarviswidget-ctrls" title="Help">
                            <app-component-articles [componentName]="componentName">
                            </app-component-articles>
                        </div>
                        <div style="float: left;" *ngIf="isContainerFilterVisible" (click)="changeContainerFilterState()">
                            <a class="badge bg-color-green btnShowFeed" style="margin-left:15px;margin-top:7px"
                                [attr.tabindex]="0"><i class="fa fa-remove"></i>
                                Filter</a>

                        </div>
                        <!-- <div class="jarviswidget-ctrls" *ngIf="lastUpdatedDate!=''" style="margin-right:5px;padding-top: 0.25em !important;">
                            <span>Last updated {{lastUpdatedDate}}</span>
                        </div> -->
                    </header>
                    <div class="widgetdiv fix-status-pdf-viewer">
                        <div class="jarviswidget-editbox">
                        </div>
                        <div id="viewList" class="no-bottom-padding widget-body">
                            <section id="widget-grid2" class="">
                                <form *ngIf="ManualFilterList.length> 0" [formGroup]="ManualFilterFormGroup">
                                    <div class="row" style="padding-left: 1px;" id="viewsearchblock"
                                        *ngIf="ManualFilterList && ManualFilterList.length>0">
                                        <div id="viewManualFilter"
                                            class="col-sm-12 col-md-12 col-lg-12 nopaddingnmargin ">
                                            <div *ngFor="let col of ManualFilterList;">
                                                <div class="col-sm-3 col-md-3 col-lg-3 nopaddingnmargin ocrsearchbar"
                                                    *ngIf="col.FieldName.indexOf('Date') == -1">
                                                    <div class="input-group"
                                                        style="height: 32px !important;padding-top: 3px !important;">
                                                        <input style="height: 27px;" id="field_{{col.FieldName}}"
                                                            type="text" placeholder="{{col.FieldLabel}}"
                                                            class="form-control searchcontrol"
                                                            name="field_{{col.FieldName}}"
                                                            formControlName="field_{{col.FieldName}}" required>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3 col-md-3 col-lg-3 nopaddingnmargin ocrsearchbar"
                                                    *ngIf="col.FieldName.indexOf('Date') >= 0">
                                                    <fieldset
                                                        style="height: 31px !important;padding-top: 2px !important;padding-left: 1px !important;">
                                                        <p-calendar appendTo="body" class='datepicker' placeholder="{{col.FieldLabel}}"
                                                            id="field_{{col.FieldName}}"
                                                            formControlName="field_{{col.FieldName}}"
                                                            [style]="{'width':'89%'}" name="field_{{col.FieldName}}"
                                                            [monthNavigator]="true" [yearNavigator]="true"
                                                            yearRange="1950:2030" [dateFormat]="dateFormatCalender">
                                                        </p-calendar>
                                                        <i class='icon-append fa fa-calendar'
                                                            style="font-size:medium;"></i>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div style="padding-top: 3px !important;padding-left: 1px !important;"
                                                class="col-sm-1 col-md-1 col-lg-1 nopaddingnmargin ocrsearchbar">
                                                <button pButton type="button" (click)="populateGrid()"
                                                    icon="fa fa-search" style="height: 26px;" label="Load"
                                                    [disabled]="!ManualFilterFormGroup.valid"></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="row jqgridboxadminaircraft">
                                    <!--style="height:80vh !important;"-->
                                    <ag-grid-angular id="viewGrid" [gridOptions]="gridOptions" [columnDefs]="columnDefs"
                                        [rowData]="gridData" [excelStyles]="excelStyles"
                                        (rowGroupOpened)="populateDetailGrid($event)"
                                        (rowClicked)='onTaskSelectionChanged($event)'
                                        (rowDoubleClicked)="taskRowDoubleClicked($event)"
                                        (cellClicked)="onCellClicked($event)" [getRowId]="getRowNodeId" 
                                        sortable="true" filter=true style="width: 100%;" [enableRangeSelection]="true"
                                        [enableCharts]="true" 
                                        [ngClass]="{'sv-viewlistgridhieght': (!feedlogid || feedlogid=='0'),'api-viewlistgridhieght':(feedlogid &&feedlogid != '0')}"
                                        class="ag-theme-balham">
                                    </ag-grid-angular>
                                    <div style="display: none;">
                                        <ag-grid-angular id="viewGrid" [gridOptions]="chartGridOptions"
                                            [columnDefs]="chartColumnDefs" [rowData]="chartGridData"
                                            [getRowId]="getRowNodeId" maintainColumnOrder = true sortable="true" filter=true
                                            (chartCreated)="onChartCreated($event)"
                                            [pivotMode]="true" class="ag-theme-balham">

                                        </ag-grid-angular>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </article>
            <article (click)="onPanalsClick($event)" class="col-sm-6 col-md-6 col-lg-6 sortable-grid p-sortable"
                id="listcol3" *ngIf="!feedlogid">

                <div class="jarviswidget no-margin " style="padding-left: 0px;" id="wid-id-3"
                    data-widget-sortable="false" data-widget-colorbutton="false" data-widget-editbutton="false"
                    data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false"
                    role="widget">

                    <header role="heading" id="pdfPanelHeader" style="margin: 0px;">

                        <ul id="widget-tab-1" class="nav nav-tabs" *ngIf="taskDocuments && taskDocuments.length<3">
                            <li *ngFor="let doc of taskDocuments; let i = index;" [class.active]="i==0">
                                <a data-toggle="tab" href="#hr{{i}}" aria-expanded="false"
                                    (click)="GetTokenAndUpdateDoc(doc)" title="{{doc.DocumentName}}">
                                    <i class="fa {{getDocIcon(doc)}}"
                                        style="margin-right: 5px; color: black;font-size: 15px;"></i>
                                    <span class="hidden-mobile hidden-tablet">{{doc.DocumentType}} </span>
                                </a>
                            </li>
                        </ul>
                        <div class="jarviswidget-ctrls pull-left" role="menu"
                            *ngIf="taskDocuments && taskDocuments.length>2">
                            <p-dropdown appendTo="body" id="docSelect" [options]="taskDocumentsList" tabindex="0"
                                (onChange)="GetTokenAndUpdateTaskDoc($event)" [style]="{'width':'140px !important'}">
                                <ng-template let-item pTemplate="selectedItem">
                                    <div>
                                        <i class="fa {{item.icon}}"
                                            style="margin: 5px; color: black;font-size: 15px;"></i>
                                        <span class="hidden-mobile hidden-tablet">{{item.label}} </span>
                                    </div>
                                </ng-template>
                                <ng-template let-country pTemplate="item">
                                    <div>
                                        <i class="fa {{country.icon}}"
                                            style="margin-right: 5px; color: black;font-size: 15px;"></i>
                                        <span class="hidden-mobile hidden-tablet">{{country.label}} </span>
                                    </div>
                                </ng-template>


                            </p-dropdown>
                        </div>
                        <!--<div class="jarviswidget-ctrls" role="menu" *ngIf="isCurrentModule && (gridData && gridData.length>0) && canLinkDocumentStatus">
                            <a class="button-icon" href="javascript:void(0);" id="btnMetadata" title="Metadata">
                                <i class="glyphicon glyphicon-pencil"></i>
                            </a>
                        </div>-->

                        <div class="jarviswidget-ctrls" role="menu">
                            <a title={{wideDocPanalTitle}} class="button-icon tablIndexFocus" [attr.tabindex]="0"
                                (keyup.enter)="wideDocPanal()" (click)="wideDocPanal()" href="javascript:void(0);"
                                id="btnDocWideScreen">
                                <i class="glyphicon glyphicon-resize-horizontal"></i>
                            </a>
                        </div>
                        <edit-metadata
                            *ngIf="isCurrentModule && (gridData && gridData.length>0) && canEditDocumentStatus">
                        </edit-metadata>
                        <div class="jarviswidget-ctrls" role="menu"
                            *ngIf="isCurrentModule && (gridData && gridData.length>0) && canDetachDocumentStatus">
                            <a class="button-icon" href="javascript:void(0);" (click)="btnDetachDocument()"
                                id="btnDetach" title="Detach Document(s) (Ctrl+Shift+D)">
                                <i class="fa fa-unlink"></i>
                            </a>
                        </div>
                        <div class="jarviswidget-ctrls" role="menu"
                            *ngIf="isCurrentModule && (gridData && gridData.length>0) && canUploadDocumentStatus">
                            <upload-from-computer></upload-from-computer>
                        </div>
                        <div class="jarviswidget-ctrls" role="menu"
                            *ngIf="isCurrentModule && (gridData && gridData.length>0) && canLinkDocumentStatus">
                            <a class="button-icon" href="javascript:void(0);" id="btnAttachRecord"
                                (click)="btnAttachRecord()" title="Attach From Record (Ctrl+Shift+A)">
                                <i class="fa fa-link"></i>
                            </a>
                        </div>

                        <div class="jarviswidget-ctrls"
                            *ngIf="!(selectedUrl==null && selectedUrl==undefined) && canUploadDocumentStatus">
                            <a class="button-icon tablIndexFocus" [attr.tabindex]="0"
                                (keyup.enter)="btnShowUpdateFileDlg()" id="UpdateBlob" (click)="btnShowUpdateFileDlg()"
                                title="Replace Document">
                                <i class="fa fa-retweet"></i>
                            </a>
                        </div>

                        <div class="jarviswidget-ctrls" *ngIf="!(selectedUrl==null && selectedUrl==undefined)">
                            <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="btnDownloadBlob()"
                                id="DownloadBlob" (click)="btnDownloadBlob()" title="Download File">
                                <i class="fa fa-download"></i>
                            </a>
                        </div>


                        <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>
                    </header>

                    <div class="widgetdiv no-padding standardpadding fix-status-pdf-viewer">
                        <div class="jarviswidget-editbox">
                        </div>
                        <div class="widget-body">
                            <section id="widget-grid" class="">
                                <div class="form-group">
                                    <div class="documentview" id="documentview">
                                        <div id="DocumentShow" class="fix-status-pdf-doc">
                                            <object [data]="pdfSrc" type="application/pdf" width="100%" height="100%"
                                                *ngIf="showPDF_or_ImageViewer && taskDocuments && taskDocuments.length>0 && pdfSrc && selectedUrl && fileType == 'application/pdf'">
                                                <param name="view" value="Fit" />
                                            </object>
                                            <div id="videolink"
                                                *ngIf="!(selectedVideoUrl==null && selectedVideoUrl==undefined) && fileType == 'application/pdf'"
                                                [innerHtml]="video_html"></div>
                                            <div id="statusImageViewer" *ngIf="showPDF_or_ImageViewer">
                                                <div id="imgViewertoolbar" *ngIf="fileType.indexOf('image/') != -1"
                                                    style="height: 25px;background-color: lightgray;">
                                                    <div style="display: inline-block;" *ngIf="isInRepairModule">
                                                        <a class="btn btn-default drawing-toolbar-btn"
                                                            *ngIf="selectedMarkerTypeFilter && selectedMarkerTypeFilter.Name == 'Current'"
                                                            href="javascript:void(0);" (click)="setMode();"
                                                            title="{{editModeText}}"><i
                                                                class="{{editModeIcon}}"></i></a>
                                                        <p-dropdown appendTo="body" [options]="markerTypeList"
                                                            *ngIf="selectedMarkerTypeFilter && selectedMarkerTypeFilter.Name == 'Current'"
                                                            (onChange)="onMarkerTypeChange($event)" name="markerTypes"
                                                            placeholder="Marker Type" optionLabel="Name"
                                                            [resetFilterOnHide]="true" [(ngModel)]="selectedMarkerType"
                                                            [filter]="true">
                                                        </p-dropdown>
                                                        <a class="btn btn-default drawing-toolbar-btn"
                                                            *ngIf="selectedMarkerTypeFilter && selectedMarkerTypeFilter.Name == 'Current'"
                                                            href="javascript:void(0);" title="Point"
                                                            (click)="addObject('point');"
                                                            [ngStyle]="{ 'background-color': selectedMarkerType.CSSClass }"><i
                                                                class="fa fa-circle"
                                                                style="font-size: xx-small;color: white;"></i></a>
                                                        <a class="btn btn-default drawing-toolbar-btn"
                                                            *ngIf="selectedMarkerTypeFilter && selectedMarkerTypeFilter.Name == 'Current'"
                                                            href="javascript:void(0);" title="Circle"
                                                            (click)="addObject('circle')"
                                                            [ngStyle]="{ 'background-color': selectedMarkerType.CSSClass }"><i
                                                                class="fa fa-circle-o"
                                                                style="font-size: small;color: white;"></i></a>
                                                        <a class="btn btn-default drawing-toolbar-btn"
                                                            *ngIf="selectedMarkerTypeFilter && selectedMarkerTypeFilter.Name == 'Current'"
                                                            href="javascript:void(0);" title="Draw Free Path"
                                                            (click)="addObject('path')"
                                                            [ngStyle]="{ 'background-color': selectedMarkerType.CSSClass }"><i
                                                                class="fa fa-pencil"
                                                                style="font-size: small;color: white;"></i></a>
                                                    </div>

                                                    <div style="float: right;">
                                                        <a class="btn btn-default drawing-toolbar-btn"
                                                            href="javascript:void(0);" title="Zoom In"
                                                            (click)="btnZoomIn()"><i class="fa fa-search-plus"
                                                                style="font-size: small;"></i></a>
                                                        <a class="btn btn-default drawing-toolbar-percent-button"
                                                            href="javascript:void(0);">{{zoomPercentage}}%</a>
                                                        <a class="btn btn-default drawing-toolbar-btn"
                                                            href="javascript:void(0);" title="Zoom Out"
                                                            (click)="btnZoomOut()"><i class="fa  fa-search-minus"
                                                                style="font-size: small;"></i></a>

                                                        <!-- <a class="btn btn-default drawing-toolbar-btn" href="javascript:void(0);" title="Move Down" (click)="btnMove('down')"><i class="fa fa-angle-down" style="font-size: small;"></i></a>
                                                      <a class="btn btn-default drawing-toolbar-btn" href="javascript:void(0);" title="Move Up"(click)="btnMove('up')"><i class="fa fa-angle-up" style="font-size: small;"></i></a>
                                                      <a class="btn btn-default drawing-toolbar-btn" href="javascript:void(0);" title="Move Left"(click)="btnMove('left')"><i class="fa fa-angle-left" style="font-size: small;"></i></a>
                                                      <a class="btn btn-default drawing-toolbar-btn" href="javascript:void(0);" title="Move Right"(click)="btnMove('right')"><i class="fa fa-angle-right" style="font-size: small;"></i></a> -->

                                                        <p-dropdown appendTo="body" [options]="markerTypeFilterList"
                                                            *ngIf="isInRepairModule" name="markerTypeFilter"
                                                            (onChange)="onMarkerTypeFilterChange($event)"
                                                            placeholder="Marker Type" optionLabel="Name"
                                                            [(ngModel)]="selectedMarkerTypeFilter" [filter]="true">
                                                        </p-dropdown>

                                                        <a class="btn btn-default drawing-toolbar-btn"
                                                            *ngIf="isInRepairModule" href="javascript:void(0);"
                                                            title="Save Drawing" (click)="saveObject()"><i
                                                                class="fa fa-save" style="font-size: small;"></i></a>
                                                        <a class="btn btn-default drawing-toolbar-btn"
                                                            *ngIf="isInRepairModule" href="javascript:void(0);"
                                                            title="Download" (click)="downloadPDF()"><i
                                                                class="fa fa-download"
                                                                style="font-size: small;"></i></a>
                                                    </div>
                                                </div>
                                                <div id="statusCanvasDiv">
                                                    <canvas id="statusCanvas" width="0" height="0"
                                                        style="border-width:0px;"></canvas>
                                                </div>
                                            </div>

                                            <div class="video"
                                                *ngIf="!(isVideoTypeFound==null && isVideoTypeFound==undefined)">
                                                <video controls autoplay #videoPlayer>
                                                    <source src="{{selectedUrl}}" type="video/mp4" />
                                                    Browser not supported
                                                </video>
                                            </div>

                                            <div class="audio"
                                                *ngIf="!(isAudioTypeFound==null && isAudioTypeFound==undefined)">
                                                <audio controls autoplay #audioPlayer>
                                                    <source src="{{selectedUrl}}" type="audio/mpeg">
                                                    Your browser does not support the audio tag.
                                                </audio>
                                            </div>



                                            <div role="main"
                                                *ngIf="!(isNonPreviewTypeFound==null && isNonPreviewTypeFound==undefined)">
                                                <!-- MAIN CONTENT -->
                                                <div id="content" style="opacity: 1;">
                                                    <section id="widget-grid-main" class="well" style="height:91vh;">
                                                        <div id="content">
                                                            <!-- row -->
                                                            <div class="row">
                                                                <div class="col-sm-12">
                                                                    <div class="well padding-10"
                                                                        style="background-color: #fff;margin-top: 20px;">
                                                                        <div class="row">
                                                                            <div class="col-md-offset-1 col-md-3">
                                                                                <img style="height: 92px;"
                                                                                    src="{{mimeTypeImageSrc}}"
                                                                                    class="img-responsive" alt="img">
                                                                            </div>
                                                                            <div class="col-md-8 padding-left-0 ">
                                                                                <h3
                                                                                    class="margin-top-0 margin-bottom-9">
                                                                                    <a href="javascript:void(0);">No
                                                                                        preview available. </a>

                                                                                    <br>
                                                                                </h3>
                                                                                <p>
                                                                                    {{mimeTypeFileName}}
                                                                                </p>
                                                                                <div
                                                                                    class="p-dialog-buttonpane">
                                                                                    <button type="button" pButton
                                                                                        icon="fa fa-download"
                                                                                        class="btn btn-primary minzindex"
                                                                                        (click)="btnDownloadBlob()"
                                                                                        label="Download"></button>
                                                                                    <button *ngIf="canUploadDocumentStatus" style="margin-left: 10px;"
                                                                                        type="button" pButton
                                                                                        icon="fa fa-retweet"
                                                                                        class="btn btn-default minzindex"
                                                                                        (click)="btnShowUpdateFileDlg()"
                                                                                        label="Replace Document"></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    </section>
</form>
<ng-template [ngIf]="isCurrentModule && displayDialog">
    <p-dialog *ngIf="displayDialog" [(visible)]="displayDialog" [style]="{width:'99.9vw',top:'1px'}" id="attachDocumentDlg"
        header="Attach Document" (onHide)="resetPopup($event)" (onShow)="onShowAttachDocumnet($event)" showEffect="fade"
        [modal]="true" [resizable]="true" responsive="true" styleClass="fix-model">
        <div style="overflow: hidden;" class="upload-dialog">
            <div class="smart-form">
                <div class="row sv-custommargin" *ngIf="showSelectedTask" style="padding-bottom:3px;">
                    <ag-grid-angular id="taskGrid" [gridOptions]="taskGridOptions" [columnDefs]="taskColumnDefs"
                        [rowData]="taskGridData" style="width: 100%;height:115px" class="ag-theme-balham">
                    </ag-grid-angular>
                </div>
                <div class="row">
                    <div class="form-group col-sm-3 fix-Padding">
                        <fieldset>
                            <span>Asset </span>
                            <!-- <p-dropdown appendTo="body" id="asn" name="asn" filter="filter" [options]="assets"
                                placeholder="Select Asset" required [(ngModel)]="containerModel.asn" #asn="ngModel"
                                (onChange)="onChangeAsset($event)">
                            </p-dropdown> -->

                            <p-dropdown appendTo="body" id="asn" name="asn" filter="filter" [options]="assets"
                                placeholder="Select Asset" required [(ngModel)]="containerModel.asn" #asn="ngModel"
                                (onChange)="onChangeAsset($event)">
                                <ng-template style="padding-bottom:20px;padding-left: 0px !important;" let-item
                                    pTemplate="selectedItem">
                                    <span>{{item.label}}</span>
                                    <!-- <span style="font-size:14px;background-color:lightgreen;">{{item.AssetType}}</span> -->
                                </ng-template>
                                <ng-template style="padding-bottom:20px !important;padding-left: 0px !important;"
                                    let-asset pTemplate="item">
                                    <div style="padding-bottom: 20px;">
                                        <!-- <span > -->
                                        <span style="padding:0" class="col-md-8">{{asset.label}}</span>

                                        <!-- </div> -->
                                        <!-- <div class="col-md-4"> -->
                                        <span style="padding:0" class="col-md-4">
                                            <span
                                                style="font-size: 11px;padding: 4px;background-color:lightgreen;color: white;border-radius: 3px;">
                                                {{asset.AssetType}}
                                            </span>
                                        </span>

                                        <!-- </div> -->
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-5 fix-Padding">
                        <fieldset>
                            <div class="btn-group-justified" style="height:28px;">
                                <a (click)="onCategorySelection('AutoSearch')" class="btn btn-default"
                                    [ngClass]="{active: isActive == 'AutoSearch'}">Auto Search</a>
                                <a *ngIf="showMXRBtn" (click)="onCategorySelection('MXR')" class="btn btn-default"
                                    [ngClass]="{active: isActive =='MXR'}">MX Records</a>
                                <a *ngIf="showLogBookBtn" (click)="onCategorySelection('LOG')" class="btn btn-default"
                                    [ngClass]="{active: isActive =='LOG'}">Log Books</a>
                                <a *ngIf="showGeneralBtn" (click)="onCategorySelection('GEN')" class="btn btn-default"
                                    [ngClass]="{active: isActive =='GEN'}">General</a>
                                <a *ngIf="showDocumentBtn" (click)="onCategorySelection('DOC')" class="btn btn-default"
                                    [ngClass]="{active: isActive =='DOC'}">Documents</a>
                                <a *ngIf="showBindersBtn" (click)="onCategorySelection('BND')" class="btn btn-default"
                                    [ngClass]="{active: isActive =='BND'}">Binders</a>
                                <a (click)="onCategorySelection('Search')" class="btn btn-default"
                                    [ngClass]="{active: isActive == 'Search'}">Search</a>
                            </div>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-2">
                        <div class='form-group sbfrm-fieldgroup col-sm-12'>
                            <fieldset>
                                <div>
                                    <!-- class="input-group"> -->
                                    <button type="button" class="btn btn-default btn-custom" tabindex="-1"
                                        (click)="attachDocument()" style="width: 60%; height: 28px;">Attach
                                        Document(s)</button>
                                    <button type="button" class="btn btn-default dropdown-toggle drp-custom"
                                        data-toggle="dropdown" tabindex="-1" aria-expanded="false"
                                        style="height: 28px;">
                                        <span class="caret"></span>
                                    </button>
                                    <ul class="dropdown-menu" role="menu">
                                        <li><a (click)="attachDocument()">Attach Document(s)</a></li>
                                        <li><a (click)="OpenExtractModal()">Attach Selected Pages</a></li>
                                    </ul>
                                </div>
                            </fieldset>
                        </div>
                        <!-- <fieldset class="pull-right">
                            <button pButton type="button" (click)="attachDocument()" icon="fa fa-paperclip"
                                style="height: 26px;" label="Attach Document"
                                [disabled]="!containerForm.form.valid"></button>
                        </fieldset> -->


                        <!-- <div class="jarviswidget-ctrls pdfoperations" role="menu">
                            <div class="btn-group" style="cursor: pointer;"> -->
                        <!-- <a class="button-icon dropdown-toggle tablIndexFocus" [attr.tabindex]="0"
                                    data-toggle="dropdown" aria-expanded="true" title="Attach">
                                    <i class="fa fa-paperclip"></i>
                                </a> -->

                        <!-- <div class="input-group-btn" style="width:10%;">
                                    <button type="button" class="btn btn-default btn-custom" tabindex="-1">Attach Document(s)</button>
                                    <button type="button" class="btn btn-default dropdown-toggle drp-custom" data-toggle="dropdown" tabindex="-1" aria-expanded="false">
                                        <span class="caret"></span>
                                    </button>
                                    <ul class="dropdown-menu" role="menu">
                                        <li><a (click)="attachDocument()">Attach Document(s)</a></li>
                                        <li><a (click)="OpenExtractModal()">Attach Selected Pages</a></li>
                                    </ul>
                                </div> -->

                        <!-- <button pButton type="button" icon="fa fa-paperclip" class="button-icon dropdown-toggle tablIndexFocus"
                                [attr.tabindex]="0" data-toggle="dropdown" aria-expanded="true" title="Attach"
                                style="height: 26px;" label="Attach" [disabled]="!containerForm.form.valid"></button>

                                <ul class="dropdown-menu pull-right js-status-update">
                                    <li>
                                        <a href="javascript:void(0);" class="tablIndexFocus" [attr.tabindex]="0"
                                            title="Attach Document" (keyup.enter)="attachDocument()"
                                            (click)="attachDocument();">
                                            <i class="fa fa-paperclip"></i>&nbsp;Attach Document
                                        </a>
                                    </li>
                                    <li *ngIf="_sharedService.docToAttach.length == 1">
                                        <a href="javascript:void(0);" class="tablIndexFocus" [attr.tabindex]="0"
                                            title="Creates a PDF Copy of Selected Pages only"
                                            (keyup.enter)="OpenExtractModal()" (click)="OpenExtractModal();">
                                            <i class="fa fa-paperclip"></i>&nbsp;Attach Selected Pages
                                        </a>
                                    </li>
                                </ul> -->
                        <!-- </div>
                        </div> -->

                    </div>
                    <div class="form-group col-sm-2">
                        <fieldset style="padding-right: 0px;" class="pull-right">
                            <button class="custom-button" pButton type="button" title="{{taskButtonTitle}}" (click)="showHideSelectedTask()"
                                label="{{taskButtonTitle}}" style="height: 26px;" icon="fa fa-eye"></button>
                        </fieldset>
                    </div>
                </div>
                <div class="row" id="recordPopup" *ngIf="!showSearch">
                    <browse-container *ngIf="displayDialog"></browse-container>
                    <browse-document *ngIf="displayDialog"></browse-document>
                    <browse-pdf *ngIf="displayDialog"></browse-pdf>
                </div>
                <div class="row" id="searchPopup" *ngIf="showSearch">
                    <search *ngIf="displayDialog" [ViewSettingId]=viewID></search>
                </div>

            </div>
        </div>
    </p-dialog>

</ng-template>

<!-- <doc-manpulation *ngIf="!(selectedUrl==null && selectedUrl==undefined) && (selectedVideoUrl==null && selectedVideoUrl==undefined)
                                            && fileType=='application/pdf'">
                                        </doc-manpulation> -->

<!----------------------------------------- Extract PDF dialogue------------------------------------------------------------------->
<form *ngIf="isDisplayExtractPagesDialog" #frmUploadDoc="ngForm">
    <!--name="frmUploadDoc"-->
    <p-dialog *ngIf="isDisplayExtractPagesDialog" [(visible)]="isDisplayExtractPagesDialog" id="docUpload"
        showEffect="fade" [resizable]="false" [modal]="true" [style]="{width: '700px'}">
        <ng-template pTemplate="header"  (keyup.shift.tab)="onKey()">
            Attach Selected Pages
        </ng-template>

        <!--Contents Start Here-->


        <div class="upload-dialog">
            <!-- commented for tabing -->
            <!-- <div class="alert alert-danger fade in" style="display: none">
                <button class="close" data-dismiss="alert">×</button>
                <h4 class="alert-heading"><i class="fa-fw fa fa-times"></i>Error!</h4>
                <p id="errorMsg"></p>
            </div> -->
            <div id="smart-form" class="smart-form">
                <div id="form-fields">
                    <div class="row">
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <!-- <label class="label">From<span class="text-danger">*</span></label>
                                <label class="input">
                                    <input type="text" placeholder="From Page" #focus required class="input-smText"
                                        id="dfFrompage" name="dfFrompage" [(ngModel)]="metaData.FromPage" required />
                                </label> -->

                                <label class="label">Pages
                                    <!-- <span class="text-danger">*</span> -->
                                </label>
                                <label class="input">
                                    <!-- <input type="text" placeholder="From Page" #focus required class="input-smText"
                                        id="dfFrompage" name="dfFrompage" [(ngModel)]="metaData.FromPage" required /> -->
                                </label>
                            </fieldset>
                        </div>
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <!-- <label class="label">To<span class="text-danger">*</span></label>
                                <label class="input">
                                    <input type="text" required placeholder="To Page" class="input-smText" id="dftopage"
                                        name="dftoPage" [(ngModel)]="metaData.ToPage" required />
                                </label>

                            [pattern]="_sharedService.re_SelectPageRange"-->


                                <label class="input">
                                    <input type="text" required placeholder="  e.g. 1-5, 8, 11-13" class="input-smText"
                                        id="PageRange" name="PageRange" [(ngModel)]="PageRange" required />
                                </label>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--Contents End Here
        !frmUploadDoc.form.valid ||
        -->

        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary"
                    (click)="SplitAndAttachDocument(frmUploadDoc)"
                    [disabled]="isSubmitted || PageRange==null || PageRange==''" label="Attach"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default"
                    (click)="btnCancelClick(frmUploadDoc)" label="Cancel" [disabled]="isSubmitted"
                    (focusout)="applyFocusToInput()"></button>
            </div>
        </ng-template>
    </p-dialog>
</form>
<!----------------------------------------- Extract PDF dialogue------------------------------------------------------------------->

<p-dialog *ngIf="displayissuedialogue" [(visible)]="displayissuedialogue" showEffect="false" [modal]="true"
    [style]="{width: '100vw'}" [resizable]="false">
    <ng-template pTemplate="header" >
        Item Details
    </ng-template>
    <div class="upload-dialog">
        <list-comm *ngIf="displayissuedialogue" id="taskDetailPopup"></list-comm>
    </div>
</p-dialog>


<audit-history *ngIf="canViewStatusAudit"></audit-history>

<p-dialog *ngIf="displayRunningTaskDialog" [(visible)]="displayRunningTaskDialog" showEffect="false" [modal]="true"
    [style]="{width: '700px'}" [resizable]="false">
    <ng-template pTemplate="header" >
        Auto Search Batch Jobs (User initiated)
    </ng-template>
    <view-job-progress *ngIf="displayRunningTaskDialog" id="taskProgressPopup"></view-job-progress>
</p-dialog>

<p-dialog id="statusListFeed" *ngIf="displayFeedDialog && canViewFeedFromStatus"
    [(visible)]="displayFeedDialog" showEffect="false" [modal]="true" [style]="{width: '100vw'}"
    [resizable]="false">
    <ng-template pTemplate="header" >
        Feeds
    </ng-template>
    <list-datafeed *ngIf="displayFeedDialog && canViewFeedFromStatus" id="feedsPopup" [feedType]="feedType"
        [viewId]="ViewSettingId" [targetType]="targetType" [viewDataType]="viewDataType"></list-datafeed>
</p-dialog>

<!-- //Replace Document dialog  check canUploadDocumentStatus -->
<p-dialog *ngIf="showUpdateFileDlg && canUploadDocumentStatus" [(visible)]="showUpdateFileDlg" showEffect="fade"
    [modal]="true" [style]="{width: '730px'}" [resizable]="false">
    <ng-template pTemplate="header"  (keyup.shift.tab)="btnCancelDlgClick()">
        Replace Document
    </ng-template>
    <div class="top-border-statuslinks" [attr.tabindex]="0">
        <div class="row">
            <div id="UpdateUploadBlob" class="col-sm-6">
                <input #singleFileUpload type="file" class="form-control" (change)="onChange_UploadBlob($event)"
                    accept={{acceptFileTypes}}>
                <!-- (change)="onChange($event)"-->
                <!--accept="application/pdf,image/bmp,image/dcx,image/pcx,image/png,image/jpg,image/jpeg,image/jp2,image/jpc,image/jfif,image/tif,image/tiff,image/gif,image/djvu,image/djv,image/jb2,audio/mpeg,audio/wav,audio/ogg,video/webm,video/mp4,video/ogg,application/zip,application/vnd.rar,application/rar,.zip,.rar,.7zip"-->

            </div>
            <div class="col-sm-3">
                <button type="button" class="btn btn-default" (click)="btnUploadBlob()" title="Replace Document">
                    Replace Document </button>
            </div>

            <div class="col-sm-3">
                <span class="onoffswitch pull-right" *ngIf="showUploadAsImage">
                    <input type="checkbox" name="isImageSwitch" class="onoffswitch-checkbox" checked="checked"
                        [disabled]="disableImageLoadOption" id="isImageSwitch" [(ngModel)]="isImageSwitch"
                        #IsImage="ngModel">
                    <label class="onoffswitch-label" for="isImageSwitch">
                        <span class="onoffswitch-inner" data-swchon-text="YES" data-swchoff-text="NO"></span><span
                            class="onoffswitch-switch"></span>
                    </label>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3"><span class="pull-right" style="padding-top: 3px;color: #333;"
                    *ngIf="isImageSwitch==1">Convert to
                    PDF</span>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">
            <button type="button" [attr.tabindex]="0" pButton icon="fa fa-close" class="btn btn-default minzindex"
                label="Cancel" (click)="btnCancelUpdateFileClick(containerForm)"></button>
        </div>
    </ng-template>
</p-dialog>