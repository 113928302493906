<head>
  <meta charset="utf-8" />
</head>
<style>
  input-group .form-control, .input-group-addon, .input-group-btn {
      display: table-cell !important;
  }
  .input-group-lg>.form-control, .input-group-lg>.input-group-addon, .input-group-lg>.input-group-btn>.btn {
      height: 45px !important;
      padding: 5px 10px !important;
      font-size: 17px !important;
      line-height: 1.33 !important;
      border-radius: 3px !important;
  }
   .input-group .form-control{
      position: relative !important;
      z-index: 2 !important;
      float: left !important;
      width: 100% !important;
      margin-bottom: 0 !important;
  }
  .p-autocomplete{
      width: 100% ! important;
    }

  </style>


   <div class="input-group input-group-lg" >
     <p-autoComplete  (onSelect)="redirect($event)"  name='val' #val='ngModel' [(ngModel)]="searchdata"  class="form-control input-lg" [style]="{'width':'100%','font-size':'16px !important'}" [inputStyle]="{'width':'100%','border':'none','padding-right':'0','font-size':'16px !important'}" placeholder="Enter your search  here..."  [minLength]="3" [suggestions]="results"  (completeMethod)="search($event)" >
        <ng-template let-value pTemplate="results">
        <span (click)=redirect(value.ArticleId)    name="autoComplete" style="font-size:14px" innerHtml="{{value.Title | searchighlight:searchdata }}">   </span>
        <div class="badge bg-color-blue " style="color:#fff !important;margin-left: 0.2em;"> {{value.ArticleSectionName}}</div>
        </ng-template>
    </p-autoComplete>
   <div class="input-group-btn">
      <button type="button" (click)="btnSubmitClick()"  class="btn btn-default">
        <i class="fa fa-fw fa-search fa-lg"></i>
      </button>
  </div>

  </div>

