//Components
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//Services
import { ConfirmationService, SelectItem } from 'primeng/api';
import { SharedService } from '../../../shared/services/shared.service';
import { NgForm } from '@angular/forms';
import { GridOptions, GridApi } from "ag-grid-community";
import { Subscription } from 'rxjs';
import { AssetService } from '../../../admin/services/asset/asset.service';
import { ContainerService } from '../../../records/services/container/container.service';
import * as moment from 'moment/moment';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
import { RecordMappingModel } from './record-mapping';
import { APIService } from '../../services/api.service';
import { CustomDateComponent } from '../../../shared/components/custom-date/custom-date.component';
import { container, SearchContainerModel } from 'src/app/records/services/container/container';
import { ContainerCategory, TargetType } from '../../services/api.helper-classes';
import { IConfig } from '../../../shared/helpers/config';

var dateFormatF: string;
var dateSeparatorS: string;
var dayD: number;
var monthM: number;
var yearY: number;
var dateTimeConfig: IDateFormatInfo;
@Component({
    selector: 'record-configuration',
    templateUrl: './record-configuration.component.html',
    providers: [AssetService, ContainerService, APIService, ConfirmationService]
})

export class RecordConfigurationComponent implements OnInit {

    public gridOptions: GridOptions;
    public gridData: any[] = [];
    public columnDefs: any[] = [];
    feedId: string;
    WorkOrderLabel: string = '';
    screenName: string = "";
    routeName: string = "";
    asn: string = '';
    feedType: string = '';
    recordModel: RecordMappingModel[] = [];
    getRowNodeId: any;
    feedName: string = null;
    moduleName: string = null;
    @Input() feedTypeInput: string = '';
    @Input() inputFeedId: string = null;
    buttonLabelName: string = 'Save';
    generateButtonLabel: string = 'Save';
    assetSubscription: Subscription;
    public referenceKeyLabel: string = "";
    public dataTypes: any[] = [];
    public selectedDataType: any;
    public TargetType: any;
    public dateDisplay: boolean;
    public expiryDate: any;
    public sharedAccessExpiryTime = new Date();
    minDateValue: Date = new Date();
    maxDateValue: Date;
    public emailButtonDisable: any = false
    dateFormatCalender = "";
    iconClass: any;
    containerButtonLabel: string = 'Generate to ROAM Container';
    public dateConfig: IConfig[] = [];
    public conGridOptions: GridOptions;
    public containerSelectionDlg: boolean = false;
    conRowSelected: boolean = false;
    conGridData: any[] = [];
    public selectedComboCatergorySelection: string = "";
    categories: SelectItem[] = [];
    containerModel: SearchContainerModel;
    conTotalCount: number = 0;
    conTypes: SelectItem[] = [];
    assets: SelectItem[] = [];
    conColumnDefs: any[];

    constructor(private _sharedService: SharedService, private router: Router, private _confirmationService: ConfirmationService, private route: ActivatedRoute, private _assetService: AssetService, private _containerService: ContainerService, private _apiService: APIService) {
        if (!this._sharedService.isAuthUser()) {
            this.router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.updateRoles();
            this.getRowNodeId = function (params: any) {
                return params.data.ContainerID;
            }
        }
    }

    updateRoles() {
        //if (!this._sharedService.UserRole) {
        this._sharedService.updateRole();
        //}
    }
    public height;
    setGridHeight() {
        if (this.router.url == '/record/exportrecordsdocumentslist' || this.router.url == '/record/exportrecordsdocuments' ||
            this.router.url == '/transition/exporttransitiondocumentslist' || this.router.url == '/transition/exporttransitiondocuments') {
            this.height = '75vh'
        }
        else {
            this.height = '65vh'
        }
    }
    gridIcon() {
        if (this.router.url == '/status/exportstatuscompliancedocuments' || this.router.url == '/repair/exportrepaircompliancedocuments' || this.router.url == '/record/exportrecordsdocuments' || this.router.url == '/transition/exporttransitiondocuments') {
            this.iconClass = 'fa fa-file-pdf'
        }
        else if (this.router.url == '/status/exportstatusdata' || this.router.url == '/repair/exportrepairdata' || this.router.url == '/record/exportrecordsdocumentslist' || this.router.url == '/transition/exporttransitiondocumentslist') {
            this.iconClass = 'fa fa-file-o'

        }
    }
    ngOnInit() {
        this.setGridHeight();
        this.gridIcon();
        if (this.feedTypeInput == 'ExportTemp') {
            this.buttonLabelName = 'Email';
            this.buttonLabelName = 'Generate and Email';
            this.generateButtonLabel = 'Generate to My Reports';
        }
        else {
            this.buttonLabelName = 'Save';
        }
        if (this.inputFeedId) {
            this.feedId = this.inputFeedId;
        }
        else {
            this.feedId = this.route.snapshot.params["id"];
        }

        this.referenceKeyLabel = this._sharedService.getReferenceKeyLabel();
        if (this.feedId) {
            if (this.inputFeedId) {
                //this.screenName = this.route.snapshot.params["screen"];
                this.screenName = 'Export';
            }
            else {
                this.screenName = this.route.snapshot.params["screen"];
            }
            this.asn = this._sharedService.selectedOutgoingFeed.SourceDetail;
            var templateValue = this._sharedService.selectedOutgoingFeed.TemplateName;
            if (templateValue = "Export Transition Documents List" || templateValue == "Export Records Documents List" || templateValue == "Export Views Status Data" || templateValue == "Export Views Repair Data") {
                this.feedType = "";

            }
            else {
                this.feedType = this._sharedService.selectedOutgoingFeed.DataType;

            }
            this.routeName = this.screenName.toLowerCase();
        }
        else {
            this.feedId = this._sharedService.recordConfig.FeedId.toString();
            this.screenName = 'Export';
            this.asn = this._sharedService.recordConfig.ASN;
            this.feedType = this._sharedService.recordConfig.FeedType;
            this.routeName = 'Export';
        }
        if (this._sharedService.recordConfig && this._sharedService.recordConfig.DataType.length > 0) {
            this.dataTypes = this._sharedService.recordConfig.DataType;
            this.selectedDataType = this.dataTypes[0].value;
            this.minDateValue.setHours(this.minDateValue.getHours() + 1)
            this.sharedAccessExpiryTime = this.minDateValue;
            //this.maxDateValue = new Date( this._sharedService.getSharedAccessExpiryTimeThreshold());
            this.maxDateValue = this._sharedService.getSharedAccessExpiryTimeThreshold();
        }
        this.init();
        this.containerModel = new SearchContainerModel("", null, null, false);
        this.dateConfig = this._sharedService.getConfiguration();
    }


    init(): void {
        dateTimeConfig = this._sharedService.getDateTimeFormatConfiguration();
        this.setCalenderDateFormat();
        this.gridOptions = <GridOptions>
            {
                rowSelection: 'multiple',
                rowData: [],
                onGridReady: () => {
                    this.setGridColumns();
                },
                suppressContextMenu: true,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    wrapText: true
                },
                components: { agDateInput: CustomDateComponent },
                onModelUpdated: (event: any) => {
                    if (this.gridOptions.api) {
                        this.gridOptions.api.sizeColumnsToFit();
                    }

                }
            }

        this.populateContainerGrid(this.asn);
        this.assetSubscription = this._sharedService._feedUpdated
        .subscribe({
            next: (response) => {
                (this.populateContainerGrid(JSON.parse(this._sharedService.selectedAsset[0].data).ASN))
            },
            error: (error) => {
            }
        });
    }

    ngOnDestroy() {
        if (this.assetSubscription) {
            this.assetSubscription.unsubscribe();
        }
        if (this._sharedService.statusConfig) {
            this.dataTypes = [];
            this._sharedService.statusConfig.DataType = [];
            this.sharedAccessExpiryTime = this.minDateValue;
        }

    }
    setCalenderDateFormat() {
        if (!dateTimeConfig.dateSeparator) {
            dateTimeConfig = this._sharedService.getDateTimeFormatConfigFromLocalStorage();
        }

        let dateParts: string[] = dateTimeConfig.dateformat.split(dateTimeConfig.dateSeparator);

        if (dateTimeConfig.year == 0) {
            this.dateFormatCalender = (dateParts[dateTimeConfig.year].substr(0, 2) + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day]).toLowerCase();
        }
        else if (dateTimeConfig.year == 2) {
            if (dateTimeConfig.month == 1) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
            else if (dateTimeConfig.month == 0) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
        }
        else {
            this.dateFormatCalender = "yy-mm-dd"
        }

    }

    populateContainerGrid(asn: string): void {
        let moduleName: string = null;
        if (this._sharedService.selectedFeedRowData) {
            if (this._sharedService.selectedFeedRowData.SourceType.toLowerCase().indexOf("transition") != -1) {
                moduleName = "Transition";
            }
            else if (this._sharedService.selectedFeedRowData.SourceType.toLowerCase().indexOf("records") != -1) {
                moduleName = "Records";
            }
        }
        else if (this._sharedService.selectedModule == "record") {
            moduleName = "Records";
        }
        else if (this._sharedService.selectedModule == "transition") {
            moduleName = "Transition";
        }
        if (moduleName == null) {
            this._containerService.getContainerByAsn(asn)
            .subscribe({
                next: (response) => {
                    this.gridData = response;
                    this.gridOptions.api.sizeColumnsToFit();
                    this.populateSelectedContainer();
                },
                error: (error) => {
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Cannot get containers, please try again.' });
                }
            });
        }
        // || this._sharedService.selectedFeedRowData.SourceType == "Transition Export")

        else if (this._sharedService.selectedFeedRowData != null && this._sharedService.selectedFeedRowData.TargetType == TargetType.ROAMContainer && (this._sharedService.selectedFeedRowData.SourceType == "Records Export")) {
            let containerPath = this._sharedService.selectedFeedRowData.TargetDetail
            this._containerService.getContainerInfoForFeed(asn, moduleName, containerPath)
            .subscribe({
                next: (response) => {
                    this.gridData = response;
                    this.gridOptions.api.sizeColumnsToFit();
                    this.populateSelectedContainer();
                },
                error: (error) => {
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Cannot get containers, please try again.' });
                }
            });
        }
        else {
            this._containerService.getContainerByAsnAndModule(asn, moduleName)
            .subscribe({
                next: (response) => {
                    this.HasAccessToCategoryInCurrenPlanRoles(response);
                },
                error: (error) => {
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Cannot get containers, please try again.' });
                }
            });
        }
    }

    HasAccessToCategoryInCurrenPlanRoles(res: any) {
        var _selectedAsset;
        if (this._sharedService.selectedAsset != undefined && this._sharedService.selectedAsset.length > 0) {
            _selectedAsset = this._sharedService.selectedAsset[0];
            var _assetObj = JSON.parse(_selectedAsset.data);
            //var _assetObj = _selectedAsset
            let allowedContainerList: any[] = [];
            if (_assetObj.MXRecords && this._sharedService.UserRole['Records_MXR_Readonly'] == true) {
                res.filter(x => x.Category == 'MXR').forEach(element => {
                    allowedContainerList.push(element);
                });

            }
            if (_assetObj.LogBooks && this._sharedService.UserRole['Records_LOG_Readonly'] == true) {
                res.filter(x => x.Category == 'LOG').forEach(element => {
                    allowedContainerList.push(element);
                });
            }
            if (_assetObj.General && this._sharedService.UserRole['Records_GEN_Readonly'] == true) {
                res.filter(x => x.Category == 'GEN').forEach(element => {
                    allowedContainerList.push(element);
                });
            }
            if (_assetObj.Documents && this._sharedService.UserRole['Records_DOC_Readonly'] == true) {
                res.filter(x => x.Category == 'DOC').forEach(element => {
                    allowedContainerList.push(element);
                });
            }
            if (_assetObj.Binders && this._sharedService.UserRole['Transition_BND_Readonly'] == true) {
                res.filter(x => x.Category == 'BND').forEach(element => {
                    allowedContainerList.push(element);
                });
            }
            this.gridData = allowedContainerList;
            this.gridOptions.api.sizeColumnsToFit();
            this.populateSelectedContainer();
        } else {
            this._assetService.getAssetByASN(res[0].ASN)
            .subscribe({
                next: (response) => {
                    _selectedAsset = response;
                    // var _assetObj = JSON.parse(_selectedAsset.data);
                    var _assetObj = _selectedAsset
                    let allowedContainerList: any[] = [];
                    if (_assetObj.MXRecords && this._sharedService.UserRole['Records_MXR_Readonly'] == true) {
                        res.filter(x => x.Category == 'MXR').forEach(element => {
                            allowedContainerList.push(element);
                        });
        
                    }
                    if (_assetObj.LogBooks && this._sharedService.UserRole['Records_LOG_Readonly'] == true) {
                        res.filter(x => x.Category == 'LOG').forEach(element => {
                            allowedContainerList.push(element);
                        });
                    }
                    if (_assetObj.General && this._sharedService.UserRole['Records_GEN_Readonly'] == true) {
                        res.filter(x => x.Category == 'GEN').forEach(element => {
                            allowedContainerList.push(element);
                        });
                    }
                    if (_assetObj.Documents && this._sharedService.UserRole['Records_DOC_Readonly'] == true) {
                        res.filter(x => x.Category == 'DOC').forEach(element => {
                            allowedContainerList.push(element);
                        });
                    }
                    if (_assetObj.Binders && this._sharedService.UserRole['Transition_BND_Readonly'] == true) {
                        res.filter(x => x.Category == 'BND').forEach(element => {
                            allowedContainerList.push(element);
                        });
                    }
                    this.gridData = allowedContainerList;
                    this.gridOptions.api.sizeColumnsToFit();
                    this.populateSelectedContainer();
                },
                error: (error) => {
                }
            })
        }
    }

    populateSelectedContainer(): void {
        let feedData: any = { FeedId: this.feedId };

        this._apiService.getFeedDataByIDForRecord(feedData)
        .subscribe({
            next: (response) => {
                let feedData = response;
                this.feedName = feedData.FeedName;
                if (this._sharedService.selectedFeedRowData) {
                    if (this._sharedService.selectedFeedRowData.SourceType.toLowerCase().indexOf("transition") != -1) {
                        this.moduleName = "Transition";
                    }
                    else if (this._sharedService.selectedFeedRowData.SourceType.toLowerCase().indexOf("records") != -1) {
                        this.moduleName = "Records";
                    }
                }
                else if (this._sharedService.selectedModule == "record") {
                    this.moduleName = "Records";
                }
                else if (this._sharedService.selectedModule == "transition") {
                    this.moduleName = "Transition";
                }
                else { this.moduleName = "Records"; }
                if (feedData.Mapping) {
                    let selectedContainer: any[] = feedData.Mapping.split(',');
                    for (let i = 0; i < selectedContainer.length; i++) {
                        let rowNode = this.gridOptions.api.getRowNode(selectedContainer[i]);
                        this.sharedAccessExpiryTime = this.minDateValue;
                        rowNode.setSelected(true);
                    }
                }
            },
            error: (error) => {
                this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Cannot get containers, please try again.' });
            }
        });
    }

    setGridColumns(): void {
        this.columnDefs = [];

        if (this.feedTypeInput == 'ExportTemp') {
            this.columnDefs =
                [
                    {
                        headerName: ' ', field: '', headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true, headerCheckboxSelection: true, filter: false, sortable: true, menuTabs: []
                    },
                    {
                        headerName: 'Asset', field: 'ASN', filter: 'set', tooltipField: 'ASN', menuTabs: ['filterMenuTab'],
                    },
                    {
                        headerName: 'Category', field: 'Category', filter: 'set', tooltipField: 'Category', menuTabs: ['filterMenuTab'],
                        valueFormatter: function (param: any) {
                            switch (param.value) {
                                case "MXR":
                                    return "MX Records";
                                case "LOG":
                                    return "Log Books";
                                case "GEN":
                                    return "General";
                                case "DOC":
                                    return "Documents";
                                case "BND":
                                    return "Binders";
                                default:
                                    return '';
                            }
                        }
                    },
                    {
                        headerName: 'Type', field: 'Type', filter: 'set', tooltipField: 'TypeDescription', menuTabs: ['filterMenuTab'],
                    },
                    {
                        headerName: 'Date', field: 'Date', filter: 'agDateColumnFilter', filterParams:
                        {
                            comparator: this.dateComparater,
                            inRangeInclusive: 'true'
                        }, valueFormatter: this.dateValueFormatter, menuTabs: ['filterMenuTab']
                    },
                    {
                        headerName: 'End Date', field: 'EndDate', filter: 'agDateColumnFilter', filterParams: {
                            comparator: this.dateComparater,
                            inRangeInclusive: 'true'

                        }, valueFormatter: this.dateValueFormatter, menuTabs: ['filterMenuTab']
                    },
                    {
                        headerName: `${this.referenceKeyLabel}/Title`, field: 'ReferenceKey', tooltipField: 'ReferenceKey', menuTabs: ['filterMenuTab'], filter: 'set'
                        , valueFormatter: function (params: any) {
                            if (params.data.Category != null && params.data.Category != "" && params.data.Category != "LOG") {
                                return params.value;
                            }
                            else {
                                return "";//"LOG-Test";
                            }
                        }
                    },
                    {
                        headerName: 'Description', field: 'Description', tooltipField: 'Description', menuTabs: ['filterMenuTab'], filter: 'set'
                    }
                ];
        }
        else {
            this.columnDefs =
                [
                    {
                        headerName: ' ', field: '', headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true, headerCheckboxSelection: true, filter: false, sortable: true, menuTabs: []
                    },
                    {
                        headerName: 'Asset', field: 'ASN', filter: 'set', tooltipField: 'ASN', menuTabs: ['filterMenuTab'],
                    },
                    {
                        headerName: 'Category', field: 'Category', filter: 'set', tooltipField: 'Category', menuTabs: ['filterMenuTab'],
                        valueFormatter: function (param: any) {
                            switch (param.value) {
                                case "MXR":
                                    return "MX Records";
                                case "LOG":
                                    return "Log Books";
                                case "GEN":
                                    return "General";
                                case "DOC":
                                    return "Documents";
                                case "BND":
                                    return "Binders";
                                default:
                                    return '';
                            }
                        }
                    },
                    {
                        headerName: 'Type', field: 'Type', filter: 'set', tooltipField: 'TypeDescription', menuTabs: ['filterMenuTab']
                    },
                    {
                        headerName: 'Date', field: 'Date', filter: 'agDateColumnFilter', filterParams:
                        {
                            comparator: this.dateComparater,
                            inRangeInclusive: 'true'
                        }, valueFormatter: this.dateValueFormatter, menuTabs: ['filterMenuTab']
                    },
                    {
                        headerName: 'End Date', field: 'EndDate', filter: 'agDateColumnFilter', filterParams: {
                            comparator: this.dateComparater,
                            inRangeInclusive: 'true'

                        }, valueFormatter: this.dateValueFormatter, menuTabs: ['filterMenuTab']
                    },
                    {
                        headerName: `${this.referenceKeyLabel}/Title`, field: 'ReferenceKey', tooltipField: 'ReferenceKey', menuTabs: ['filterMenuTab'], filter: 'set'
                        , valueFormatter: function (params: any) {
                            if (params.data.Category != null && params.data.Category != "" && params.data.Category != "LOG") {
                                return params.value;
                            }
                            else {
                                return "";//"LOG-Test";
                            }
                        }
                    },
                    {
                        headerName: 'Description', field: 'Description', tooltipField: 'Description', menuTabs: ['filterMenuTab'], filter: 'set'
                    }
                ];
        }
    }
    dateChange() {
        if (this.sharedAccessExpiryTime == null || this.sharedAccessExpiryTime == undefined) {
            this.emailButtonDisable = true
        }
        else {
            this.emailButtonDisable = false
        }
    }
    confirmSave() {
        let selectedRows = this.gridOptions.api.getSelectedNodes();
        if (selectedRows.length > 10) {
            this._confirmationService.confirm({
                message: 'You have selected more than 10 Containers, this may take quite a long time to download.',
                header: 'More than 10 Containers',
                icon: 'fa fa-info-circle',
                accept: () => {
                    if (this.feedTypeInput == 'ExportTemp') {
                        this.sharedAccessExpiryTime = this.minDateValue;
                        this.TargetType = 'Email Link'
                        this.dateDisplay = true
                        this.emailButtonDisable = false
                    }
                    else {
                        this.btnSaveClick();
                    }
                },
                reject: () => {
                }
            });
        } else {
            if (selectedRows && selectedRows.length > 0) {
                if (this.feedTypeInput == 'ExportTemp') {
                    this.sharedAccessExpiryTime = this.minDateValue;
                    this.TargetType = 'Email Link';
                    this.dateDisplay = true
                    // this.sharedAccessExpiryTime=this.minDateValue;
                    this.emailButtonDisable = false
                }
                else {
                    this.btnSaveClick();
                }
            }
            //     if(this.feedTypeInput=='ExportTemp')
            //         {
            //             this.TargetType='Email Link'
            //             this.btnSaveClick();
            //         }
            //         else{
            //             this.btnSaveClick();
            //         }
            // }
            else {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'No Container Selected', detail: 'Please select a container.' });
            }

        }
    }
    configurationSelectedRows;
    confirmContainerExport() {
        this.configurationSelectedRows = this.gridOptions.api.getSelectedNodes();
        let selectedRows = this.gridOptions.api.getSelectedNodes();
        if (selectedRows.length > 10) {
            this._confirmationService.confirm({
                message: 'You have selected more than 10 Containers, this may take quite a long time to download.',
                header: 'More than 10 Containers',
                icon: 'fa fa-info-circle',
                accept: () => {
                    if (this.feedTypeInput == 'ExportTemp') {

                        this.TargetType = TargetType.ROAMContainer
                        this.btnSaveClick();
                    }
                },
                reject: () => {
                }
            });
        } else {
            if (selectedRows && selectedRows.length > 0) {
                if (this.feedTypeInput == 'ExportTemp') {

                    this.TargetType = TargetType.ROAMContainer;
                    this.btnSaveClick();
                }
            }
            else {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'No Container Selected', detail: 'Please select a container.' });
            }

        }
    }

    btnExpiryTime() {
        this.dateDisplay = false;
        this.btnSaveClick();
    }
    btnExpiryCancelClick() {
        this.dateDisplay = false;
        // this.sharedAccessExpiryTime=this.minDateValue;
        //this.emailButtonDisable=false;
    }
    confirmGenrate() {
        let selectedRows = this.gridOptions.api.getSelectedNodes();
        if (selectedRows.length > 10) {
            this._confirmationService.confirm({
                message: 'You have selected more than 10 Containers, this may take quite a long time to download.',
                header: 'More than 10 Containers',
                icon: 'fa fa-info-circle',
                accept: () => {
                    this.TargetType = 'My Report'
                    this.btnSaveClick();
                },
                reject: () => {
                }
            });
        } else {
            if (selectedRows && selectedRows.length > 0) {
                this.TargetType = 'My Report'
                this.btnSaveClick();
            }
            else {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'No Container Selected', detail: 'Please select a container.' });
            }

        }
    }

    btnSaveClick() {
        let selectedRows = this.gridOptions.api.getSelectedNodes();
                    let body: any;
                    if (this.feedTypeInput == 'ExportTemp') {
                        body =
                        {
                            FeedId: this.feedId,
                            SelectedContainers: selectedRows.map(c => c.data.ContainerID).join(','),
                            DataType: this.selectedDataType,
                            TargetType: this.TargetType,
                        }
                        if (this.TargetType == 'Email Link') {
                            body.SharedAccessExpiryTime = this.sharedAccessExpiryTime;
                        }
                        if (this.TargetType == TargetType.ROAMContainer) {
                            body.TargetDetail = this.targetdetail;

                        }
                    }
                    else {
                        body =
                        {
                            FeedId: this.feedId,
                            SelectedContainers: selectedRows.map(c => c.data.ContainerID).join(',')
                        }
                    }

                    this._sharedService.showLoader("Processing...");
                    if ((this.router.url.indexOf("/record/exportrecordsdocumentslist") != -1)
                        || (this.router.url.indexOf("/record/exportrecordsdocuments") != -1)
                        || (this.router.url.indexOf("/transition/exporttransitiondocumentslist") != -1)
                        || (this.router.url.indexOf("/transition/exporttransitiondocuments") != -1)
                        || (this.router.url.indexOf("/record/csv") != -1)
                        || (this.router.url.indexOf("/record/tsv") != -1)
                        || (this.router.url.indexOf("/repair/xml") != -1)
                        || (this.router.url.indexOf("/repair/pdf") != -1)
                        || (this.router.url.indexOf("/repair/excel") != -1)
                        || (this.router.url.indexOf("/repair/csv") != -1)
                        || (this.router.url.indexOf("/repair/tsv") != -1)
                        || (this.router.url.indexOf("/repair/xml") != -1)
                    ) {
                        this._apiService.addUpdateRecordMappingAndRunFeed(body)
                        .subscribe({
                            next: (response) => {
                                this._sharedService.hideLoader();
                                if(response == "Maximum limit exceeded")
                                {
                                    this._sharedService.showToast({ severity: 'warn', summary: 'Maximum limit exceeded', detail: 'Maximum limit for Email and My Report is 300 documents.' });
                                }
                                else{
                                let config: any = {
                                    showDialogue: false,
                                    showMessage: true
                                };
                                this._sharedService.changeRecordConfigDialogueStatus(config);
                                if (this.TargetType == 'Email Link') {
                                    this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'The Compilation process has now started, a URL to download the final compilation .ZIP file will be sent to you by email.' });
                                }
                                else {
                                    this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'The Compilation process has now started' });
                                }
                            }
                            },
                            error: (error) => {
                                this._sharedService.hideLoader();
                                if (error.error) {
                                    this._sharedService.showToast({ severity: 'error', summary: 'Access Denied', detail: error.error });
                                }
                                else {
                                    this._sharedService.showToast({ severity: 'error', summary: 'Feed failed to start', detail: 'Request to run feed is un-successful.' });
                                }
                            }
                        });
                    }
                    else {
                        this._apiService.addUpdateRecordMapping(body)
                        .subscribe({
                            next: (response) => {
                                this._sharedService.hideLoader();
                                this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Configuration updated succesfully.' });
                                this._sharedService.onRecordConfigDialogueStatusChanged(false);
                                // setTimeout(() => {
                                //     this.router.navigate([`/apifeed/${this.routeName}`]);
                                // },
                                //     1500);
                            },
                            error: (error) => {
                                this._sharedService.hideLoader();
                                this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Configuration not updated, please try again.' });
                            }
                        });
                    }
    }

    btnCancelClick() {

        if (this.router.url.indexOf("/record") != -1 && this.feedTypeInput == 'ExportTemp') {
            let config: any = {
                showDialogue: false,
                showMessage: false
            };
            this.sharedAccessExpiryTime = this.minDateValue;
            this._sharedService.changeRecordConfigDialogueStatus(config);


            /// Delete the current Feed
            let body: any =
            {
                FeedId: this.feedId
            }
            this._apiService.removeTempFeed(body)
            .subscribe({
                next: (response) => {
                },
                error: (error) => {
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Error occurred while removing feed.' });
                }
            });

            this.router.navigate(["/record/browse"]);
        }
        else if (this.router.url.indexOf("/transition") != -1 && this.feedTypeInput == 'ExportTemp') {
            let config: any = {
                showDialogue: false,
                showMessage: false
            };
            this.sharedAccessExpiryTime = this.minDateValue;
            this._sharedService.changeRecordConfigDialogueStatus(config);


            /// Delete the current Feed
            let body: any =
            {
                FeedId: this.feedId
            }
            this._apiService.removeTempFeed(body)
            .subscribe({
                next: (response) => {
                },
                error: (error) => {
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Error occurred while removing feed.' });
                }
            });

            this.router.navigate(["/transition/binders"]);
        }
        else {
            if (this.inputFeedId) {
                this._sharedService.onRecordConfigDialogueStatusChanged(false);
            }
            else {
                this.router.navigate([`/apifeed/${this.routeName}`]);
            }

        }
    }

    // #region Date Time Section
    dateValueFormatter(params: any) {
        if (params.value != "" && params.value != null) {

            let dateString = params.value.substring(0, 10);
            return moment(dateString).format(dateTimeConfig.dateformat.toUpperCase());
        }
    }
    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).split(dateTimeConfig.dateSeparator);
            var day = Number(dateParts[Number(dateTimeConfig.day)].substring(0, 2));
            var month = Number(dateParts[Number(dateTimeConfig.month)]) - 1;
            var year = Number(dateParts[Number(dateTimeConfig.year)]);

            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        }
        else {
            return -1;
        }
    }

    setFeedDataType(event) {
        this.selectedDataType = event.value;

        // let body: datafeed =
        // {
        //     FeedId: this.feedId,
        //     DataType: dataType,
        // }
        // this._apiService.updateFeedDataType(body).subscribe( response => {
        //     if (response.AddUpdateStatusCode == AddUpdateStatusCode.Success) {

        //     }
        //     else if (response.AddUpdateStatusCode == AddUpdateStatusCode.Duplicate) {

        //     }
        //     else if (response.AddUpdateStatusCode == AddUpdateStatusCode.SameFeedName) {

        //     }
        // }, error => {
        // }

        // );
    }
    // #endregion

    resetContainerModel() {
        this.containerModel = new SearchContainerModel("", null, null, false);
        this.conGridData = [];
        this.categories = []
    }

    populateContainerType(category: string) {
        this.conTypes = [];
        let body: container = {
            Category: category
        }
        this._containerService.getContainerType(body)
        .subscribe({
            next: (response) => {
                let res = response;
                for (let i = 0; i < res.length; i++) {
                    this.conTypes.push({ value: res[i].Type, label: res[i].Type })
                }
            },
            error: (error) => {
                this.conGridData = [];
                this.conTotalCount = 0;
            }
        });
    }
    public selContainer = null;
    public targetdetail = null;
    onTargetContainerChange(event: any) {
        this.targetdetail = event.targetDetail;
        this.selContainer = event.selectedContainer;
        this.confirmContainerExport();


    }
}
