import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CardModule } from 'primeng/card';
import { ConversationList, EditCommModel } from '../../../comm/components/edit-comm/edit-comm';
import * as moment from 'moment/moment';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
import { SharedService } from '../../../shared/services/shared.service';
import { UserService } from '../../../records/services/User/user.service';
import { CommService } from '../../../comm/services/comm.service';
import { DomSanitizer } from '@angular/platform-browser';
var dateTimeConfig: IDateFormatInfo;


@Component({
  selector: 'app-card-view-list',
  templateUrl: './card-view-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserService, CommService],
  //encapsulation: ViewEncapsulation.None
})
export class CardViewListComponent implements OnInit {
  model: EditCommModel;

  //commList: EditCommModel[] = [];
  commList: any[] = [];
  oldConversation: string = "";
  allConversations: ConversationList;
  CreatedOn: string;
  ModifiedOn: string;
  ViewDateFormat: string;
  // assignedToList: SelectItem[] = [];
  // requestedByList: SelectItem[] = [];
  // commStatusList: SelectItem[] = [];
  //constructor(private cRef: ChangeDetectorRef) { }

  constructor(private _sharedService: SharedService, private cRef: ChangeDetectorRef, private _userService: UserService, private _commService: CommService, private domSanitizer: DomSanitizer) {

    if (!this._sharedService.isAuthUser()) {
      //this._router.navigate(['login']);
    }
    else {
      this._sharedService.getDateTimeFormatConfiguration();
      dateTimeConfig = this._sharedService.getDateTimeFormatConfigFromLocalStorage();
      var dateConfig = this._sharedService.getDateTimeFormatConfiguration();
      this.ViewDateFormat = dateConfig.dateformat;
    }
  }

  agInit(params: any) {
    // this.requestedByList = params.RequestedByList;
    // this.assignedToList = params.AssignedToList;
    // this.commStatusList = params.commStatusList;
    dateTimeConfig = this._sharedService.getDateTimeFormatConfigFromLocalStorage();
    var dateConfig = this._sharedService.getDateTimeFormatConfiguration();
    this.ViewDateFormat = dateConfig.dateformat;
    if (params.value) {
      let comObjs = JSON.parse(params.value);

      //this.fillModelValues(result);
      if (comObjs.length > 0) {
        comObjs.forEach(comm => {
          this.model = new EditCommModel(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);

          this.allConversations = new ConversationList();
          this.allConversations.Conversation = [];
          this.fillModelValues(comm);
          let _comm = {
            comm: this.model,
            conversationList: this.allConversations.Conversation,
            descriptionSafeHTML: this.domSanitizer.bypassSecurityTrustHtml(this.model.Description)
          }
          this.commList.push(_comm);
        });
      }

    }


  }
  ngOnInit() {
    dateTimeConfig = this._sharedService.getDateTimeFormatConfigFromLocalStorage();
    var dateConfig = this._sharedService.getDateTimeFormatConfiguration();
    this.ViewDateFormat = dateConfig.dateformat;
  }


  fillModelValues(result: any) {

    this.oldConversation = result.Conversation;
    this.fillConversation(result.Conversation);

    this.model.AssignedToName = result.AssignedToName;


    this.model.RequestedByName = result.RequestedByName;


    this.model.CCs = result.CCs ? result.CCs.split(';') : [];

    this.model.History = result.History;


    this.model.CommStatusDescription = result.CommStatusDescription;

    if (result.CommTypeId)
      this.model.CommTypeId = result.CommTypeId.toString();

    this.model.CommTypeDescription = result.CommTypeDescription;

    this.model.Conversation = null;
    this.model.Description = result.Description;
    //this.model.Description = this.domSanitizer.bypassSecurityTrustHtml(result.Description);//result.Description;
    this.model.Priority = result.Priority;

    this.model.DueDate = this.getFormatedDate(result.DueDate);
    this.model.ASN = result.ASN;
    this.model.Module = result.Module;
    this.model.Record = result.Record;
    this.model.Section = result.Section;
    this.model.Attachment = result.Attachment;
    this.model.Subject = result.Subject;
    this.model.CommId = result.CommId;
    this.CreatedOn = this.getDateFormatTime(result.CreatedOn);
    this.model.CreatedBy = result.CreatedBy;
    this.ModifiedOn = this.getDateFormatTime(result.ModifiedOn);
    this.model.ModifiedBy = result.ModifiedBy;
  }


  fillConversation(conversation: string) {
    this.allConversations = new ConversationList();
    this.allConversations.Conversation = [];
    if (conversation != '' && conversation != null) {
      let allConvList = [];
      allConvList = conversation.split("?|?");
      if (allConvList.length > 0) {
        for (var i = 0; i < allConvList.length; i++) {
          let eachCon = [];
          eachCon = allConvList[i].split("||");
          if (eachCon.length > 1) {
            this.allConversations.Conversation.push({ Name: eachCon[0], Comment: eachCon[2], Date: moment(eachCon[1]).format(dateTimeConfig.dateformat.toUpperCase() + " h:mm a").toString() });
          }

        }
      }

    }

  }
  getFormatedDate(date?: any): Date {
    if (date != null) {
      let dateString = date.substr(0, 10);
      let dateParts = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).split(dateTimeConfig.dateSeparator);
      let day = Number(dateParts[Number(dateTimeConfig.day)].substring(0, 2));
      let month = Number(dateParts[Number(dateTimeConfig.month)]) - 1;
      let year = Number(dateParts[Number(dateTimeConfig.year)]);
      let cellDate = new Date(year, month, day);
      return cellDate;
    }
    else {
      return null;
    }
  }
  getDateFormatTime(date: any) {

    if (date) {
      var dateConfig = this._sharedService.getDateTimeFormatConfiguration();
      this.ViewDateFormat = dateConfig.dateformat;
      return moment.utc(date).format(dateConfig.timeformat + "  " + dateConfig.dateformat.toUpperCase()).toString();
    } else {
      return '';
    }
  }


}


