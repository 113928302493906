//#region References
import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { APIService } from '../../services/api.service';
import { feedlog } from '../../components/feed-log/feed-log';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { GridOptions } from "ag-grid-community";
import "ag-grid-enterprise";
import { Subscription } from 'rxjs';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
import { IConfig } from '../../../shared/helpers/config';

import * as moment from 'moment/moment';
import { CustomDateComponent } from '../../../shared/components/custom-date/custom-date.component';

var dateTimeConfig: IDateFormatInfo;

//#endregion

//#region Type Decorator
@Component({
    selector: 'feed-log',
    templateUrl: './feed-log.component.html',
    providers: [APIService]
})
//#endregion

export class FeedLogComponent implements OnInit {

    //#region Locals Declaration

    feedlogid: string;
    screenName: string = "";
    routeName: string = "";

    totalRecords: Number;
    public totalCount: number;
    dataFeedId: string;

    feedlogobj: feedlog;
    subscription: Subscription;
    public gridOptions: GridOptions;

    selectedDpJob: feedlog[];
    columnDefs: any[];
    
    public gridData: any[] = [];
    @Input() inputFeedId: string;
    @Input() inputScreen: string;
    //#endregion

    //#region Initialization of Component
    constructor(private _sharedService: SharedService, private _router: Router, private _route: ActivatedRoute, private _apiService: APIService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            dateTimeConfig = this._sharedService.getDateTimeFormatConfiguration();
        }
    }
    ngOnInit()
    {
        // let feedId: string = this._route.snapshot.params["id"];
        // let screen: string = this._route.snapshot.params["screen"];

        this.screenName = this.inputScreen;
        this.routeName = this.screenName.toLowerCase();  
        this.dataFeedId = this.inputFeedId;
        this.gridOptions = <GridOptions>
            {
                rowSelection: 'single',
                rowData: [],
                onGridReady: () => {
                    this.setFeedLogGridColumns();
                    if (this.gridOptions.api != undefined) {
                        if (this.gridData.length > 0) {
                            this.gridOptions.api.hideOverlay();
                            try {
                                this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSizeHavingBeadCrumb('agGridFeedLog'));
                                this.gridOptions.api.hideOverlay();
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }

                },
                onModelUpdated: (event: any) => {
                    if (this.gridOptions.api) {
                        this.totalCount = this.gridOptions.api.getDisplayedRowCount();
                        this.gridOptions.api.sizeColumnsToFit();
                    }
                },
                pagination: true,
                paginationPageSize: 20,
                getContextMenuItems: this.getCustomContextMenuItems,
                context: {
                    gridContext: this
                },defaultColDef: {
                    sortable: true,
                    resizable:true
                },
                components : { agDateInput: CustomDateComponent }
            }

        this.populateFeedLogGrid();

    }

    //#endregion

    //#region Ag Grid Date Comparer
    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).split(dateTimeConfig.dateSeparator);
            var day = Number(dateParts[Number(dateTimeConfig.day)].substring(0, 2));
            var month = Number(dateParts[Number(dateTimeConfig.month)]) - 1;
            var year = Number(dateParts[Number(dateTimeConfig.year)]);

            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        }
        else {
            return -1;
        }
    }
    //#endregion

    //#region Ag Grid Population Event

    populateFeedLogGrid(): void {
        let body: feedlog = {
            FeedId: this.dataFeedId
        }
        this._apiService.getDataFeedLog(body)
        .subscribe({
            next: (response) => {
                this.gridData = [];
                this.gridData = response;
                this.totalCount = this.gridData.length;
            },
            error: (error) => {
            }
        });

    }

    reBindGrid(selAsset: any) {
        this.populateFeedLogGrid();
    }

    clickedJobRow(event: any) {
        this.feedlogobj = {};
         this.feedlogobj ={
                FeedLogId:event.data.FeedLogId,
                FileName: event.data.FileName,
                FeedId: this.dataFeedId
        }       
        //  this.feedlogid = Number(event.data.FeedLogId);
        this.feedlogid = event.data.FeedLogId;
    }
    //#endregion

    //#region Set Columns of Data Feeds Ag Grid
    setFeedLogGridColumns(): void {
        this.columnDefs = [];
        if (this.routeName == 'import') {
            this.columnDefs =
                [
                    {
                        headerName: 'File Name', field: 'FileName', filter: 'set', menuTabs: ['filterMenuTab']
                    },
                    {
                        headerName: 'Executed On', field: 'ExecutedOn', filter: 'agDateColumnFilter', filterParams: {
                            comparator: this.dateComparater,
                            inRangeInclusive: 'true'

                        }, valueFormatter: function (params: any) {
                            if (params.value != "" && params.value != null) {
                                let dateString = params.value;
                                return moment(dateString).format(dateTimeConfig.dateformat.toUpperCase() + ' ' + dateTimeConfig.timeformat);
                            }
                        }, menuTabs: ['filterMenuTab'], minWidth: 140,
                    },
                    {
                        headerName: 'Rows Recieved', field: 'RowsRecieved', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 50,
                    },
                    {
                        headerName: 'Rows Populated', field: 'RowsPopulated', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 50,
                    },
                    {
                        headerName: 'Rows Inserted', field: 'RowsInserted', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 30
                    },
                    {
                        headerName: 'Rows Updated', field: 'RowsUpdated', filter: 'set', menuTabs: ['filterMenuTab']
                    },
                    {
                        headerName: 'Feed Status', field: 'FeedStatus', filter: 'set', menuTabs: ['filterMenuTab'], cellRenderer: this.statusCellRenderer
                    },
                ];
        } else if (this.routeName == 'export') {
            this.columnDefs =
                [
                    {
                        headerName: 'Executed On', field: 'ExecutedOn', filter: 'agDateColumnFilter', filterParams: {
                            comparator: this.dateComparater,
                            inRangeInclusive: 'true'

                        }, valueFormatter: function (params: any) {
                            if (params.value != "" && params.value != null) {
                                let dateString = params.value;
                                return moment(dateString).format(dateTimeConfig.dateformat.toUpperCase() + ' ' + dateTimeConfig.timeformat);
                            }
                        }, menuTabs: ['filterMenuTab'], minWidth: 140,
                    },
                    {
                        headerName: 'Files Processed', field: 'RowsPopulated', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 50,
                    },
                    {
                        headerName: 'Feed Status', field: 'FeedStatus', filter: 'set', menuTabs: ['filterMenuTab'], cellRenderer: this.statusCellRenderer
                    },
                ];
        }

    }

    //#endregion

    //#region custom cell render
    statusCellRenderer(params: any) {
        let html: string = '';
        var eDiv = document.createElement('div');
        if (params.value) {

            if (params.value == 'Success') {
                eDiv.style.background = 'green';
                html = html  + params.value ;
            }
           else if (params.value == 'In progress') {
            eDiv.style.background = '#2196F3';
            html = html  + params.value ;
            }
            else {
                eDiv.style.background = 'red';
                html = html  + params.value ;            }
        }

        eDiv.innerHTML = html;
        eDiv.style.height = '100%';
        eDiv.style.width = '100%';
        eDiv.style.color = 'white';
        eDiv.style.textAlign = 'center';
        //eDiv.style.paddingTop = '6px';
        eDiv.style.textAlign = 'center';
        return eDiv;
    }
    //#endregion

    //#region Roles
   
    //#endregion

    //#region Helper Methods
    
    //#endregion
    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];
        let childResult: any[] = [];
        if (params.context.gridContext.routeName == 'import') {
        childResult.push({
            name: "Log",
            action: function () {
                params.context.gridContext.feedlogid = null;
                if (params.node) {
                    params.context.gridContext.feedlogid = params.node.data.FeedLogId;
                    params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                    params.context.gridContext._sharedService.feedLogDownloadLog({
                        FeedLogId: params.context.gridContext.feedlogid,
                        FileName: params.node.data.FileName,
                        FeedId: params.context.gridContext.dataFeedId
                    });
                }

            },
            icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
        });

        childResult.push({
            name: "Data",
            action: function () {
                params.context.gridContext.feedlogid = null;
                if (params.node) {
                    params.context.gridContext.feedlogid = params.node.data.FeedLogId;
                    params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                    params.context.gridContext._sharedService.feedLogDownloadData({
                        FeedLogId: params.context.gridContext.feedlogid,
                        FileName: params.node.data.FileName,
                        FeedId: params.context.gridContext.dataFeedId
                    });
                }

            },
            icon: params.context.gridContext._sharedService.saveLogFeedIconHTMLasString,
        });

      
            result.push({
                name: "View Feed Data",
                action: function () {
                    params.context.gridContext.feedlogid = null;
                    if (params.node) {
                        params.context.gridContext.feedlogid = params.node.data.FeedLogId;
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext._sharedService.feedLogDetail(params.context.gridContext.feedlogid);
                    }

                },
                icon: params.context.gridContext._sharedService.logIconHTMLasString,
            });

            result.push({
                name: "Download Feed",
                action: function () {
                    params.context.gridContext.feedlogid = null;
                    if (params.node) {
                        params.context.gridContext.feedlogid = params.node.data.FeedLogId;
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);                        
                    }

                },
                icon: params.context.gridContext._sharedService.downloadsFeedIconHTMLasString,
                subMenu: childResult,
            });
        }
        else if (params.context.gridContext.routeName == 'export') {
            result.push({
                name: "Download Feed File",
                action: function () {
                    params.context.gridContext.feedlogid = null;
                    if (params.node) {
                        params.context.gridContext.feedlogid = params.node.data.FeedLogId;
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext._sharedService.feedLogDownloadLog({
                            FeedLogId: params.context.gridContext.feedlogid,
                            FileName: params.node.data.FileName,
                            FeedId: params.context.gridContext.dataFeedId
                        });
                    }

                },
                icon: params.context.gridContext._sharedService.downloadsFeedIconHTMLasString,
            });
        }
        return result;


    }
    //#endregion
}
