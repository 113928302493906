<section id="widget-grid" class="adminaircraftcls">

    <div class="row">
        <article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable" id="col1">

            <div class="no-margin jarviswidget jarviswidget-sortable" id="wid-id-2" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false" role="widget">

                <header role="heading" class="p-sortable-handle">

                    <span class="widget-icon"><i class="fa fa-cogs"></i></span>
                    <h2>Configurations</h2>
                    <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>

                    <div class="jarviswidget-ctrls" role="menu" title="Edit Configuration (Ctrl+E)">
                        <a class="button-icon hand-cursor  tablIndexFocus"  [attr.tabindex]="0"  (keyup.enter)="btnEditClick()" id="btneditUserGroup" (click)="btnEditClick()">
                            <i class="glyphicon glyphicon-pencil"></i>
                        </a>
                    </div>

                </header>


                <div class="widgetdiv">


                    <div class="jarviswidget-editbox">


                    </div>

                    <div id="userGrouplisting" class="no-bottom-padding widget-body">

                        <section id="widget-grid2" class="">

                            <div class="jqgridboxadminaircraft">
                                <!--style="height:80vh !important;"-->

                                <ag-grid-angular id="agGridUserGroup"  [navigateToNextCell]="navigateToNextCell" #hiddenconfig [gridOptions]="gridOptions" [columnDefs]="columnDefs"  [rowData]="gridData" sortable="true" filter=true style="width: 100%;" class="ag-theme-balham usergroupgrid" (rowClicked)='updateSelectedRow($event)' (rowDoubleClicked)="containerRowDoubleClicked($event)">
                                    <!--width: 100%;-->
                                </ag-grid-angular>
                            </div>
                        </section>

                    </div>

                </div>

            </div>

        </article>

    </div>
</section>
<form #editconfigform="ngForm">
    <p-dialog [(visible)]="display" showEffect="fade" [modal]="true" [style]="{width: '420px'}" [resizable]="false">
        <ng-template pTemplate="header"   (keyup.shift.tab)="btnCancelClick(editconfigform)">
            {{dialogTitle}}
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Key
                            </label>

                            <label class="input">
                                <input id="editKey" type="text" name="editKey" #focuskey
                                       placeholder="Key" [(ngModel)]="model.Key" #editKey="ngModel" readonly>

                            </label>

                        </fieldset> 
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Value<span [hidden]="editValue?.valid || model.Value" class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="editValue" type="text" name="editValue"
                                       placeholder="Value" required [(ngModel)]="model.Value" #editValue="ngModel">

                            </label>

                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex" (click)="onSubmit(editconfigform)" [label]="lblsubmitButton" [disabled]="!editconfigform.form.valid || isSubmitted"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" (click)="btnCancelClick(editconfigform)" label="Cancel" [disabled]="isSubmitted" (focusout)="applyFocusToInput()" ></button>
            </div>
        </ng-template>

    </p-dialog>
</form>
