import { Component, Input, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
import { TagService } from '../../../shared/services/tag/tag.service';
import { AzureBlobService } from '../../../shared/services/AzureBlob/azure-blob.service';
import { DocumentTypeService } from '../../../admin/services/document-type/document-type.service';
import { BlobMetaData } from '../../../shared/services/AzureBlob/blob-metadata';
import { IConfig } from '../../../shared/helpers/config';
import { NgForm } from '@angular/forms';
import * as moment from 'moment/moment';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { Subscription } from "rxjs";
//const maxAllowedFileSize: number = 104857600;
// import _ from 'lodash';
import { QualityStatusService } from 'src/app/admin/services/qualitystatus/qualitystatus.service';

@Component({
    selector: 'upload-document',
    templateUrl: './upload-document.component.html',
    providers: [DocumentTypeService, ConfirmationService, TagService, QualityStatusService]
})

export class UploadDocument implements OnInit, OnDestroy {
    @Input() recordType: string;
    isDisplayDialog: boolean = false;
    isDisplayBulkDialog: boolean = false;
    DocType: SelectItem[];
    isuploadDoc: boolean = true;
    isuploadBulkDoc: boolean = true;
    selectedDocType: SelectItem;
    FileTypeList: SelectItem[];
    dialogTitle: string;
    VideoUrlList: SelectItem[] = [];
    selectedBulkUrl: string;
    bulkDialogTitle: string;
    uploadUrl: string;
    uploadedFile: any = [];
    uploadedFiles: any[] = [];
    fileNames: any[] = [];
    metaData: BlobMetaData;
    metaDataBulk: BlobMetaData;
    tags: SelectItem[] = [];
    selectedTags: string[] = [];
    parentWoNumber: string;

    //dateFormatCalender = "";
    dateFormatCalender = "";
    dateSeparator = "";
    dateFormat = "";
    configuration: IConfig[] = [];
    public bulkUploadArray: any = [];
    private day: number;
    private month: number;
    private year: number
    isSubmitted: boolean = false;
    public canUpload: boolean = false;
    public canBulkUpload: boolean = false;
    public bulkFile: any[] = [];
    public isNonPdfFileExist: boolean = false;
    @ViewChild('bulkFileUpload') selectedBulkFiles: any;
    @ViewChild('singleFileUpload') selectedSingleFile: any;
    @ViewChild('bulkUrl') bulkUrlEle: any;
    singleUploadSub: Subscription;
    bulkUploadSub: Subscription;
    subscription: Subscription
    public referenceKeyLabel: string = "";
    public maxAllowedFileSize: number = 0;
    public maxAllowedFileSizeMB: number = 0;

    public appHotkeys: Hotkey[] = [];
    public canMXRQualityStatus: boolean = false;
    public canLOGQualityStatus: boolean = false;
    public canGENQualityStatus: boolean = false;
    public canDOCQualityStatus: boolean = false;
    public canBNDQualityStatus: boolean = false;

    public processMXRQualityStatusEnabled: boolean = false;
    public processLOGQualityStatusEnabled: boolean = false;
    public processGENQualityStatusEnabled: boolean = false;
    public processDOCQualityStatusEnabled: boolean = false;
    public processBNDQualityStatusEnabled: boolean = false;

    public disableImageLoadOption: boolean = false;

    public ViewDateFormat: string;
    qualityStatusList: SelectItem[] = [];
    public showUploadAsImage = false;
    public showUploadAsImageBulk = false;
    public showExtractPageRangePDF = false;

    public acceptFileTypes = "";

    constructor(private _sharedService: SharedService, private _azureBlobService: AzureBlobService, private _documentTypeService: DocumentTypeService,
        private confirmationService: ConfirmationService,
        private _tagService: TagService, private _router: Router, private _hotkeysService: HotkeysService, private _qualityStatusService: QualityStatusService) {
        //this.uploadUrl = `${this._sharedService.baseUrl}/api/AzureBlob/UploadBlob`;
        this.metaData = new BlobMetaData("", "", "", "", "", null, "", "", 'File', "", null, null, 0, null, null, "1");
        this.metaDataBulk = new BlobMetaData("", "", "", "", "", null, "", "", 'File', "", null, null, 0, null, null, "1");
    }

    ngOnInit() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        this.maxAllowedFileSize = this._sharedService.getMaxDocumentLimitInBytes();
        this.maxAllowedFileSizeMB = this._sharedService.getMaxDocumentLimit();
        this.referenceKeyLabel = this._sharedService.getReferenceKeyLabel();
        this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_MXR_LOG_GEN_CATEGORY;
        this.UpdateRoles();
        this.subscription = this._sharedService._selectedContainer
        .subscribe({
            next: (response) => {
                this.UpdateRoles();
                this.distroyHotKeys();
                this.setHotKeys();
            }
        });

        this.setHotKeys();
        this.populateQualityStatusDropdown();
        this.singleUploadSub = this._sharedService._singleUpload
        .subscribe({
            next: (response) => {
                this.OpenSingleModal();
            }
        });

        this.bulkUploadSub = this._sharedService._bulkUpload
        .subscribe({
            next: (response) => {
                this.OpenBulkModal();
            }
        });
    }
    ngOnDestroy() {
        if (this.singleUploadSub) {
            this.singleUploadSub.unsubscribe();
        }
        if (this.bulkUploadSub) {
            this.bulkUploadSub.unsubscribe();
        }

        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.distroyHotKeys();
    }

    distroyHotKeys() {
        //console.log('destroying component =>' + this._router.url);
        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
    }


    populateQualityStatusDropdown() {
        this._qualityStatusService.getActiveQualityStatusDataForDropDown(null)
        .subscribe({
            next: (response) => {
                this.qualityStatusList = response;
            }
        });
    }
    //#region Bulk Upload Code

    OpenBulkModal() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }

        this.changeButtonsStatus(false);
        this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_MXR_LOG_GEN_CATEGORY;

        if (this._sharedService.selectedAsset && this._sharedService.selectedContainer) {
            let selectedRow = this._sharedService.selectedContainer;
            if (selectedRow.length > 1) {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Multi Row selected', detail: 'Please select single container row.' });
                return;
            }
            this.disableImageLoadOption = true;
            if (this.recordType == "DOC" || this.recordType == "BND") {
                this.disableImageLoadOption = false;
                this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_DOC_CATEGORY + "," + this._sharedService.allowedAzureIndexFileContentTypes.toString();
            }

            this.uploadUrl = `${this._sharedService.baseUrl}/api/AzureBlob/UploadBlobBulk`;
            this.setCalenderDateFormat();
            this.initBulkPopupModel();
            this.bulkDialogTitle = this.recordType + ' > Bulk Upload Document';


            //this.populateQualityStatusDropdown();
            this.populateDocTypeDropdown();
            this.populateFileTypeDropdown();
            this.ViewDateFormat = this._sharedService.getLabelTextDateFormat();
            this.isDisplayBulkDialog = true;
            this.uploadedFiles = [];
            this.clearSelectedFiles();
            this.showUploadAsImageBulk = false;

        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Row selected', detail: 'Please select container row.' });
        }


    }

    onChangeDocTypeBulk(event: any) {
        if (event.value == 'File') {
            this.uploadedFiles = [];
            this.isuploadBulkDoc = true;
        } else {
            this.isuploadBulkDoc = false;
        }
    }

    addbulkUrl() {
        let value = this.bulkUrlEle.nativeElement.value;
        if (value && (this.parseYouTube(value) || this.parseVimeo(value))) {            
            this.VideoUrlList= [...this.VideoUrlList,{ label: value, value: value }];
            this.bulkUrlEle.nativeElement.value = "";
        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Link', detail: 'Empty/Invalid Video Link found.' });
        }
    }

    removeVideoUrl() {
        if (this.selectedBulkUrl) {
            this.VideoUrlList = this.VideoUrlList.filter(item => item.label !== this.selectedBulkUrl)
        }
    }
    setHotKeys() {

        let hotKeyObj: Hotkey = null;
        //Single upload
        if (this.canUpload) {
            hotKeyObj = new Hotkey('ctrl+u', (event: KeyboardEvent): boolean => {
                this.OpenSingleModal();
                return false; // Prevent bubbling
            });
            this._hotkeysService.add(hotKeyObj);
            this.appHotkeys.push(hotKeyObj);
        }

        //Bulk Upload
        if (this.canBulkUpload) {
            hotKeyObj = new Hotkey('ctrl+shift+u', (event: KeyboardEvent): boolean => {
                this.OpenBulkModal();
                return false; // Prevent bubbling
            });
            this._hotkeysService.add(hotKeyObj);
            this.appHotkeys.push(hotKeyObj);
        }

    }

    //btnBulkUpload(event: any) {
    //    for (let file of event.files) {
    //        this.uploadedFiles.push(file);
    //    }
    //}
    btnBulkCancelClick(form: NgForm) {
        this.isNonPdfFileExist = false;
        this.isDisplayBulkDialog = false;
        form.resetForm();
        this.metaDataBulk = new BlobMetaData("", "", "", "", "", null, "", "", 'File', "", null, null, 0, 0);
        this.clearSelectedFiles();
        this.changeButtonsStatus(false);
    }
    onBeforeUpload(event: any) {

        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }
    }



    onRemove(event: any) {

        for (let i = 0; i < this.uploadedFiles.length; i++) {
            if (this.uploadedFiles[i].name == event.file.name) {
                this.uploadedFiles.splice(i, 1);
                break;
            }
        }

        if (this.uploadedFiles.find(f => f.type.indexOf('image/') != -1)) {
            this.showUploadAsImageBulk = true;
        } else {
            this.showUploadAsImageBulk = false;
        }

    }

    onClear(event: any) {
        //console.log("In Clear All Event");
    }

    initBulkPopupModel() {
        //  this.isNonPdfFileExist = false;
        this.uploadUrl = `${this._sharedService.baseUrl}/api/AzureBlob/UploadBlobBulk`;
        this.parentWoNumber = '';
        if (this.recordType == 'DOC' || this.recordType == 'BND') {
            this.metaDataBulk = new BlobMetaData("", "", "", "", "", null, "", "", 'File', "", null, null, 0);
        }
        else {
            this.parentWoNumber = this._sharedService.selectedContainer ? this._sharedService.selectedContainer.ReferenceKey : '';
            if (this._sharedService.selectedContainer.Date.length > 10) {
                let dateString = this._sharedService.selectedContainer.Date.substr(0, 10);
                let dateParts = moment(dateString).format(this.dateFormat.toUpperCase()).split(this.dateSeparator);
                let day = Number(dateParts[Number(this.day)].substring(0, 2));
                let month = Number(dateParts[Number(this.month)]) - 1;
                let year = Number(dateParts[Number(this.year)]);
                let cellDate = new Date(year, month, day);

                if (this.parentWoNumber) {
                    this.metaDataBulk = new BlobMetaData("", "", "", "", "", null, this._sharedService.selectedContainer.ReferenceKey, "", 'File', "", null, cellDate, 0, 0);
                } else {
                    if (this.parentWoNumber == null) {
                        this.parentWoNumber = '';
                    }
                    this.metaDataBulk = new BlobMetaData("", "", "", null, "", null, "", "", 'File', "", null, cellDate, 0, 0);
                }
            }
            else {
                if (this.parentWoNumber) {
                    this.metaDataBulk = new BlobMetaData("", "", "", "", "", null, this._sharedService.selectedContainer.ReferenceKey, "", 'File', "", null, this._sharedService.selectedContainer.Date.substr(0, 10), 0, 0);
                } else {
                    if (this.parentWoNumber == null) {
                        this.parentWoNumber = '';
                    }
                    this.metaDataBulk = new BlobMetaData("", "", "", null, "", null, "", "", 'File', "", null, this._sharedService.selectedContainer.Date.substr(0, 10), 0, 0);
                }
            }
        }

        this.metaDataBulk.isImageSwitch = 1;
        this.metaDataBulk.Status = this.qualityStatusList[0].value;
    }

    onSelect(event: any) {
        let invalidFiles: string[] = [];
        for (let file of event.files) {
            //if (file.type != 'application/pdf')
            //{
            //    this.isNonPdfFileExist = true;
            //}
            // if (file.type == 'application/pdf')
            //allowedAzureIndexFileContentTypes

            // if (((this.recordType != 'DOC') && (this._sharedService.allowedImageFileContentTypes.includes(file.type) || file.type == 'application/pdf'))
            //     || (this.recordType == 'DOC')) {

            if (((this.recordType != 'DOC' && this.recordType != 'BND') && (this._sharedService.allowedImageFileContentTypes.includes(file.type) || file.type == 'application/pdf')) || (this.recordType == 'DOC' || this.recordType == 'BND')) {
                if (file.size <= this._sharedService.MAX_ALLOWED_FILE_SIZE_FOR_DOC) {

                    if (file.size <= this.maxAllowedFileSize || !this._sharedService.allowedAzureIndexFileContentTypes.includes(file.type)) {
                        // 104857600
                        //1073741824
                        let fileCurrent: any = this.uploadedFiles.filter(x => x.name == file.name);
                        if (fileCurrent.length > 0) {
                            for (let i = 0; i < this.selectedBulkFiles.files.length; i++) {
                                if (this.selectedBulkFiles.files[i].name == file.name) {
                                    this.selectedBulkFiles.files.splice(i, 1);
                                    break;
                                }
                            }
                        }
                        else {
                            this.uploadedFiles.push(file);
                        }

                        if (file.type.indexOf('image/') != -1) {
                            this.showUploadAsImageBulk = true;
                        }
                    }
                    else {
                        invalidFiles.push(file.name);
                    }
                }
                else {
                    invalidFiles.push(file.name);
                }



            }
        }
        if (invalidFiles.length > 0) {
            for (let j = 0; j < invalidFiles.length; j++) {
                let index: any = this.selectedBulkFiles.files.findIndex((x: any) => x.name == invalidFiles[j]);

                this.selectedBulkFiles.files.splice(index, 1);
            }

            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File(s)', detail: 'File(s) size exceeds the maximum limit of ' + this.maxAllowedFileSizeMB + ' MB for PDF or 1 GB for other types' });
        }
    }

    validateBulkUpdate() {
        if (this.uploadedFiles.length > 0 || this.VideoUrlList.length > 0) {
            return true;
        }
        return false;
    }

    parseYouTube(str) {
        // link : //youtube.com/watch?v=Bo_deCOd1HU
        // share : //youtu.be/Bo_deCOd1HU
        // embed : //youtube.com/embed/Bo_deCOd1HU

        let re = /\/\/(?:www\.)?youtu(?:\.be|be\.com)\/(?:watch\?v=|embed\/)?([a-z0-9_\-]+)/i;
        let matches = re.exec(str);
        return matches && matches[1];
    }

    parseVimeo(str) {
        // embed & link: http://vimeo.com/86164897

        let re = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
        let matches = re.exec(str);
        return matches && matches[1];
    }

    onBulkUpload(event: any) {
        if (!this.validateBulkUpdate()) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File/Link', detail: 'No File/Video Link found.' });
            return;
        }
        this.changeButtonsStatus(true);
        this.bulkFile;
        let xhr = new XMLHttpRequest(), formData = new FormData();
        if (this.isuploadBulkDoc) {
            for (let i = 0; i < this.uploadedFiles.length; i++) {
                formData.append("uploads['" + i + "']", this.uploadedFiles[i], this.uploadedFiles[i].name);
            }
        } else {
            let urlList = "";
            for (let i = 0; i < this.VideoUrlList.length; i++) {
                urlList = urlList + (i == 0 ? "" : ";") + this.VideoUrlList[i].value;
            }

            formData.append('VideoUrl', urlList);
            formData.append('Url', urlList);
        }

        //Append File and Form Data in xhr Request
        //formData.append("uploads[]", this.uploadedFile[0], this.uploadedFile[0].name);
        //US-8181 //let asset = this._sharedService.selectedAsset[0].data.split('|');
        //formData.append('ContainerName', asset[0])

        formData.append('ContainerName', JSON.parse(this._sharedService.selectedAsset[0].data).StoragePath);//asset[0])        
        formData.append('ASN', this._sharedService.selectedContainer.ASN);
        formData.append('Type', this._sharedService.selectedContainer.Type);
        formData.append('ID', this._sharedService.selectedContainer.ID);
        formData.append('RunDuplicationCheck', 'true');

        this.metaDataBulk.Tags = '';
        if (this.metaDataBulk.docNumberSwitch == 0) {
            this.metaDataBulk.DocNo = "";
        }
        else if (this.metaDataBulk.docNumberSwitch == 1 && (!this.metaDataBulk.DocNo || this.metaDataBulk.DocNo.trim() == "")) {
            this.metaDataBulk.DocNo = "{-1}";
        }
        if (!this.metaDataBulk.isImageSwitch || this.metaDataBulk.isImageSwitch == 0) {
            this.metaDataBulk.IsImage = "true";
        }
        else if (this.metaDataBulk.isImageSwitch == 1) {
            this.metaDataBulk.IsImage = "false";
        }
        this.metaDataBulk.OCR = this.getOCRStatus(this.metaDataBulk.OCR);
        let postData = this.metaDataBulk;
        if (postData) {
            for (let prop in postData) {
                if (prop != "DocDate") {
                    formData.append(prop, postData[prop]);
                }
                else {
                    //if (typeof (postData.DocDate) == "string") {
                    //    formData.append(prop, postData[prop].toString());
                    //}
                    //else {
                    //    let date = moment(postData.DocDate).format(this.dateFormat.toUpperCase()).toString();
                    //    formData.append(prop, date);
                    //}
                    if (typeof (postData.DocDate) == "string") {
                        let dateString = postData[prop].toString().substr(0, 10);
                        let date = moment(dateString).format("YYYY-MM-DD").toString();
                        formData.append(prop, date);
                    }
                    else {
                        let date = moment(postData.DocDate).format("YYYY-MM-DD").toString();
                        formData.append(prop, date);
                    }
                }
            }
        }
        //Clear DocNo
        if (this.metaDataBulk.docNumberSwitch == 1 && this.metaDataBulk.DocNo.trim() == "{-1}") {
            this.metaDataBulk.DocNo = "";
        }

        xhr.open('POST', this.uploadUrl, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        xhr.send(formData);
        this._sharedService.showLoader("Processing...");
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                this._sharedService.hideLoader();
                if (xhr.status === 200) {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Document uploaded', detail: 'Document has been uploaded successfully.' });
                    this.changeButtonsStatus(false);
                    this.isDisplayBulkDialog = false;
                    this.uploadedFiles = [];
                    this.clearSelectedFiles();
                    this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                    this._sharedService.onAssetChanged(this._sharedService.selectedAsset);//R.A 13-09-2020
                }
                else if (xhr.status === 409) {
                    //this.isDisplayBulkDialog = false;
                    this.changeButtonsStatus(false);
                    var array = xhr.responseText.split(',');
                    var count = 1;
                    var tab = "<h3>Over Write?</h3>"
                    tab = tab + "<div  class='confirmOverflow'><table class='table'><thead><tr> <th scope='col'>#</th><th scope='col'>File Name</th></tr></thead><tbody>";
                    array.forEach(element => {
                        tab = tab + "<tr><th scope='row'>" + count + "</th><td>" + element.replace('"', '') + "</td></tr>";
                        count = count + 1;
                    });
                    tab = tab + "</tbody> </table></div>"
                    this.confirmationService.confirm({
                        // message: JSON.parse(xhr.responseText),

                        message: tab,
                        accept: () => {
                            // var fileNames: string = JSON.parse(xhr.responseText);
                            var fileNames: string = xhr.responseText;
                            this.bulkUploadOverride(fileNames);
                        },
                        reject: () => {
                            this.changeButtonsStatus(false);
                            this.isDisplayBulkDialog = false;
                            this.uploadedFiles = [];
                            this.clearSelectedFiles();
                            this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                        }
                    });
                    //console.log(xhr.response);
                }
                else if (xhr.status == 501) {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'warn', summary: 'Unable to update document URL', detail: xhr.responseText });
                    this.changeButtonsStatus(false);
                    this.isDisplayBulkDialog = false;
                    this.uploadedFiles = [];
                    this.clearSelectedFiles();
                    this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                }
                else {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while uploading document.' });
                    this.uploadedFiles = [];
                    this.isDisplayBulkDialog = false;
                    this.changeButtonsStatus(false);
                }
            }
        };

    }

    bulkUploadOverride(fileNames: string) {
        let files: string[];
        files = fileNames.split(',');


        let xhr = new XMLHttpRequest(), formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            let file: any = this.uploadedFiles.filter(x => x.name == files[i].replace('"', ''));
            formData.append("uploads['" + i + "']", file[0], file[0].name);

        }
        //formData.append("uploads['" + i + "']", this.uploadedFiles[i], this.uploadedFiles[i].name);
        //Append File and Form Data in xhr Request
        //US-8181 //let asset = this._sharedService.selectedAsset[0].data.split('|');
        //formData.append('ContainerName', asset[0])

        formData.append('ContainerName', JSON.parse(this._sharedService.selectedAsset[0].data).StoragePath);
        formData.append('ASN', this._sharedService.selectedContainer.ASN);
        formData.append('Type', this._sharedService.selectedContainer.Type);
        formData.append('ID', this._sharedService.selectedContainer.ID);
        formData.append('RunDuplicationCheck', 'false');

        this.metaDataBulk.Tags = this.selectedTags.join(';');
        if (this.metaDataBulk.docNumberSwitch == 0) {
            this.metaDataBulk.DocNo = "";
        }
        else if (this.metaDataBulk.docNumberSwitch == 1 && (!this.metaDataBulk.DocNo || this.metaDataBulk.DocNo.trim() == "")) {
            this.metaDataBulk.DocNo = "{-1}";
        }
        this.metaDataBulk.OCR = this.getOCRStatus(this.metaDataBulk.OCR);
        let postData = this.metaDataBulk;
        if (postData) {
            for (let prop in postData) {
                if (prop != "DocDate") {
                    formData.append(prop, postData[prop]);
                }
                else {
                    //if (typeof (postData.DocDate) == "string") {
                    //    formData.append(prop, postData[prop].toString());
                    //}
                    //else {
                    //    let date = moment(postData.DocDate).format(this.dateFormat.toUpperCase()).toString();
                    //    formData.append(prop, date);
                    //}
                    if (typeof (postData.DocDate) == "string") {
                        let dateString = postData[prop].toString().substr(0, 10);
                        let date = moment(dateString).format("YYYY-MM-DD").toString();
                        formData.append(prop, date);
                    }
                    else {
                        let date = moment(postData.DocDate).format("YYYY-MM-DD").toString();
                        formData.append(prop, date);
                    }
                }
            }
        }
        if (this.metaDataBulk.docNumberSwitch == 1 && this.metaDataBulk.DocNo.trim() == "{-1}") {
            this.metaDataBulk.DocNo = "";
        }
        xhr.open('POST', this.uploadUrl, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        xhr.send(formData);
        this._sharedService.showLoader("Processing...");
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                this._sharedService.hideLoader();
                if (xhr.status === 200) {
                    this.clearSelectedFiles();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Document uploaded', detail: 'Document has been uploaded successfully.' });
                    this.isDisplayBulkDialog = false;
                    this.uploadedFiles = [];
                    this.clearSelectedFiles();
                    this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                    //console.log(xhr.response);
                }
                else if (xhr.status == 501) {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'warn', summary: 'Unable to update document URL', detail: xhr.responseText });
                    this.isDisplayBulkDialog = false;
                    this.uploadedFiles = [];
                    this.clearSelectedFiles();
                    this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                }
                else {

                    this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while uploading document.' });
                    this.uploadedFiles = [];
                    this.isDisplayBulkDialog = false;
                }
            }
        };

    }

    clearSelectedFiles() {
        if (this.selectedBulkFiles && this.selectedBulkFiles.files) {
            this.selectedBulkFiles.files = [];
        }
        this.metaDataBulk.FileType = 'File';
        this.VideoUrlList = [];
        this.isuploadBulkDoc = true;
    }
    //#endregion


    //#region Single Upload
    clearSingleFile() {
        if (this.selectedSingleFile && this.selectedSingleFile.nativeElement && this.selectedSingleFile.nativeElement.value) {
            this.selectedSingleFile.nativeElement.value = "";
        }
        this.metaData.FileType = 'File';
        this.metaData.Url = "";
        this.isuploadDoc = true;
    }
    initPopupModel() {
        this.uploadUrl = `${this._sharedService.baseUrl}/api/AzureBlob/UploadBlob`;
        this.parentWoNumber = '';
        if (this.recordType == 'DOC' || this.recordType == 'BND') {
            this.metaData = new BlobMetaData("", "", "", "", "", null, "", "", 'File', "", null, null, 0);
        }
        else {
            this.parentWoNumber = this._sharedService.selectedContainer ? this._sharedService.selectedContainer.ReferenceKey : '';
            if (this._sharedService.selectedContainer.Date.length > 10) {
                let dateString = this._sharedService.selectedContainer.Date.substr(0, 10);
                let dateParts = moment(dateString).format(this.dateFormat.toUpperCase()).split(this.dateSeparator);
                let day = Number(dateParts[Number(this.day)].substring(0, 2));
                let month = Number(dateParts[Number(this.month)]) - 1;
                let year = Number(dateParts[Number(this.year)]);
                let cellDate = new Date(year, month, day);

                if (this.parentWoNumber) {
                    this.metaData = new BlobMetaData("", "", "", "", "", null, this._sharedService.selectedContainer.ReferenceKey, "", 'File', "", null, cellDate, 0);
                }
                else {
                    if (this.parentWoNumber == null) {
                        this.parentWoNumber = '';
                    }
                    this.metaData = new BlobMetaData("", "", "", "", "", null, "", "", 'File', "", null, null, 0);
                }
            }
            else {
                if (this.parentWoNumber) {
                    this.metaData = new BlobMetaData("", "", "", "", "", null, this._sharedService.selectedContainer.ReferenceKey, "", 'File', "", null, this._sharedService.selectedContainer.Date.substr(0, 10), 0);
                }
                else {
                    if (this.parentWoNumber == null) {
                        this.parentWoNumber = '';
                    }
                    this.metaData = new BlobMetaData("", "", "", "", "", null, "", "", 'File', "", null, null, 0);
                }
            }
        }

        this.metaData.PageRange = "";
        this.metaData.isImageSwitch = 1;
        this.metaData.Status = this.qualityStatusList[0].value;
        this.selectedTags = [];
    }

    OpenSingleModal() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }

        this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_MXR_LOG_GEN_CATEGORY;
        if (this._sharedService.selectedAsset && this._sharedService.selectedContainer) {
            let selectedRow = this._sharedService.selectedContainer;
            if (selectedRow.length > 1) {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Multi Row selected', detail: 'Please select single container row.' });
                return;
            }
            this.disableImageLoadOption = true;
            if (this.recordType == "DOC" || this.recordType == "BND") {
                this.disableImageLoadOption = false;
                this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_DOC_CATEGORY + "," + this._sharedService.allowedAzureIndexFileContentTypes.toString();
            }
            this.changeButtonsStatus(false);
            this.setCalenderDateFormat();
            this.initPopupModel();
            this.dialogTitle = this.recordType + ' > Upload Document';

            this.populateDocTypeDropdown();
            //this.populateQualityStatusDropdown();
            this.populateFileTypeDropdown();
            this.populateTagsDropdown();
            this.ViewDateFormat = this._sharedService.getLabelTextDateFormat();
            this.isDisplayDialog = true;
            this.showUploadAsImage = false;
            this.showExtractPageRangePDF = false;

        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Asset/Container Selected', detail: 'Please select and Asset and Container.' });
        }
    }

    onChangeDocTypeSingle(event: any) {
        if (event.value == 'File') {
            this.isuploadDoc = true;
        } else {
            this.isuploadDoc = false;
        }
    }

    btnCancelClick(form: NgForm) {
        this.changeButtonsStatus(false);
        this.isDisplayDialog = false;
        form.resetForm();
        this.metaData = new BlobMetaData("", "", "", null, "", null, "", "", 'File', "", null, null, 0);
        this.clearSingleFile();
    }

    validateUpload() {
        if (this.isuploadDoc) {
            if (this.uploadedFile[0]) {
                return true;
            }
        } else {
            if (this.metaData.Url && (this.parseYouTube(this.metaData.Url) || this.parseVimeo(this.metaData.Url))) {
                return true;
            }

        }

        return false;
    }

    btnUpload() {
        if (!this.validateUpload()) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File/Link', detail: 'Empty/Invalid File/Video Link found.' });
            return;
        }
        this.changeButtonsStatus(true);
        let xhr = new XMLHttpRequest(), formData = new FormData();
        if ((this.recordType === 'DOC' || this.recordType === 'BND') && this.isuploadDoc) {
            this.metaData.BlobName = this.getFileName();
        }
        if (this.isuploadDoc) {
            //Append File and Form Data in xhr Request
            formData.append("uploads[]", this.uploadedFile[0], this.metaData.BlobName);
            if (this.metaData.BlobName == null || this.metaData.BlobName.trim() == "") {
                this.metaData.BlobName = this.uploadedFile[0].name;
            }
        } else {
            formData.append('VideoUrl', this.metaData.Url);
            if (this.metaData.BlobName == null || this.metaData.BlobName.trim() == "") {
                this.metaData.BlobName = this.metaData.Url;
            }
            this.metaData.PageRange = '';
        }

        //US-8181 //let asset = this._sharedService.selectedAsset[0].data.split('|');
        //formData.append('ContainerName', asset[0])
        formData.append('ContainerName', JSON.parse(this._sharedService.selectedAsset[0].data).StoragePath);
        formData.append('ASN', this._sharedService.selectedContainer.ASN);
        formData.append('Type', this._sharedService.selectedContainer.Type);
        formData.append('ID', this._sharedService.selectedContainer.ID);
        formData.append('RunDuplicationCheck', 'true');
        //formData.append('PageRange', this.metaData.PageRange);
        if (!this.metaData.isImageSwitch || this.metaData.isImageSwitch == 0) {
            this.metaData.IsImage = "true";
        }
        else if (this.metaData.isImageSwitch == 1) {
            this.metaData.IsImage = "false";
        }
        this.metaData.Tags = this.selectedTags.join(',');
        this.metaData.OCR = this.getOCRStatus(this.metaData.OCR);
        let postData = this.metaData;

        if (postData) {
            for (let prop in postData) {
                if (prop != "DocDate") {
                    formData.append(prop, postData[prop]);
                }
                else {
                    if (typeof (postData.DocDate) == "string") {
                        let dateString = postData[prop].toString().substr(0, 10);
                        let date = moment(dateString).format("YYYY-MM-DD").toString();
                        formData.append(prop, date);
                    }
                    else {
                        //let date = moment(postData.DocDate).format(this.dateFormat.toUpperCase()).toString();
                        let date = moment(postData.DocDate).format("YYYY-MM-DD").toString();
                        formData.append(prop, date);
                    }
                }
            }
        }

        xhr.open('POST', this.uploadUrl, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        xhr.send(formData);
        this._sharedService.showLoader("Processing...");
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                this._sharedService.hideLoader();
                if (xhr.status === 200) {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Document uploaded', detail: 'Document has been uploaded successfully.' });
                    this.isDisplayDialog = false;
                    this.changeButtonsStatus(false);
                    this.clearSingleFile();
                    this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                    this._sharedService.onAssetChanged(this._sharedService.selectedAsset);//R.A 13-09-2020
                }
                else if (xhr.status == 501) {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'warn', summary: 'Unable to update document URL', detail: xhr.responseText });
                    this.isDisplayDialog = false;
                    this.changeButtonsStatus(false);
                    this.clearSingleFile();
                    this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                }
                else if (xhr.status === 409) {
                    this.changeButtonsStatus(false);

                    if (xhr.responseText.toLowerCase().indexOf("out of range") > -1) {
                        var errMsg = "";
                        errMsg = xhr.responseText.replace(/"/g, "");//globally replace
                        //errMsg = xhr.responseText.replace(/^"|"$/g, '');// replaces boundary quotes.
                        this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: errMsg });
                    }
                    else {
                        this.confirmationService.confirm({
                            message: "File already exists, Override Y/N ?",
                            accept: () => {
                                this.singleUploadOverride();
                            },
                            reject: () => {
                                this.uploadedFiles = [];
                                this.clearSingleFile();
                                this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                                this.isDisplayDialog = false;
                                this.changeButtonsStatus(false);
                            }
                        });
                    }
                    //console.log(xhr.response);
                } else {
                    this.isDisplayDialog = false;
                    this.changeButtonsStatus(false);
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while uploading document.' });
                    // var errMsg = "";
                    // errMsg = xhr.responseText.replace(/"/g,"");//globally replace
                    // //errMsg = xhr.responseText.replace(/^"|"$/g, '');// replaces boundary quotes.
                    // this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: errMsg });

                }
            }

        };
    }

    getFileName() {
        let orignalFileName = this.uploadedFile[0].name.split('.');
        let finalFileName = this.metaData.BlobName.split('.');

        if (this.metaData.isImageSwitch == 1) { // Not an Image
            if (this.uploadedFile[0].type.indexOf('image/') != -1) {
                return finalFileName[0] + '.pdf';
            }
            else {
                return `${finalFileName[0]}.${orignalFileName[1]}`;
            }
        } else {
            return (this.metaData.BlobName.indexOf(`.${orignalFileName[1]}`) == -1) ? (`${this.metaData.BlobName}.${orignalFileName[1]}`) : this.metaData.BlobName;
        }
    }

    onChange(event: any) {
        if (event.srcElement.files.length > 0) {
            let file = event.srcElement.files[0];
            // if (event.srcElement.files[0].type == 'application/pdf')
            //if (this._sharedService.allowedFileTypes.includes(event.srcElement.files[0].type.toString()) == true) {

            if (((this.recordType != 'DOC' && this.recordType != 'BND') && (this._sharedService.allowedImageFileContentTypes.includes(file.type) || file.type == 'application/pdf')) || (this.recordType == 'DOC' || this.recordType == 'BND')) {

                if (file.size <= this._sharedService.MAX_ALLOWED_FILE_SIZE_FOR_DOC) {
                    if (file.size <= this.maxAllowedFileSize || !this._sharedService.allowedAzureIndexFileContentTypes.includes(file.type)) {
                        this.uploadedFile = event.srcElement.files
                        this.metaData.BlobName = this.uploadedFile[0].name;
                        if (file.type.indexOf('image/') != -1) {
                            this.showUploadAsImage = true;
                        } else {
                            this.showUploadAsImage = false;
                        }
                        if (file.type.indexOf('application/pdf') != -1) {
                            this.showExtractPageRangePDF = true;
                        } else {
                            this.showExtractPageRangePDF = false;
                        }
                    }
                    else {
                        this.uploadedFile = [];
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File', detail: 'File size exceeds the maximum limit of ' + this.maxAllowedFileSizeMB + ' MB for PDF or 1 GB for other types' });
                        this.clearSingleFile();
                    }
                }
                else {
                    this.uploadedFile = [];
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File', detail: 'File size exceeds the maximum limit of ' + this.maxAllowedFileSizeMB + ' MB for PDF or 1 GB for other types' });
                    this.clearSingleFile();
                }
            }
            else {
                this.uploadedFile = [];
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Invalid File', detail: 'Only valid files are allowed' });
                this.clearSingleFile();
            }
        }
        else {
            this.uploadedFile = [];
            this.selectedSingleFile.nativeElement.value = "";
        }
    }
    singleUploadOverride() {
        let xhr = new XMLHttpRequest(), formData = new FormData();
        //Append File and Form Data in xhr Request
        formData.append("uploads[]", this.uploadedFile[0], this.uploadedFile[0].name);
        //US-8181 //let asset = this._sharedService.selectedAsset[0].data.split('|');

        formData.append('ContainerName', JSON.parse(this._sharedService.selectedAsset[0].data).StoragePath);//asset[0])
        formData.append('ASN', this._sharedService.selectedContainer.ASN);
        formData.append('Type', this._sharedService.selectedContainer.Type);
        formData.append('ID', this._sharedService.selectedContainer.ID);
        formData.append('RunDuplicationCheck', 'false');
        this.metaData.Tags = this.selectedTags.join(',');
        this.metaData.OCR = this.getOCRStatus(this.metaData.OCR);
        let postData = this.metaData;
        if (postData) {
            for (let prop in postData) {
                if (prop != "DocDate") {
                    formData.append(prop, postData[prop]);
                }
                else {
                    //if (typeof (postData.DocDate) == "string") {
                    //    formData.append(prop, postData[prop].toString());
                    //}
                    //else {
                    //    let date = moment(postData.DocDate).format(this.dateFormat.toUpperCase()).toString();
                    //    formData.append(prop, date);
                    //}
                    if (typeof (postData.DocDate) == "string") {
                        let dateString = postData[prop].toString().substr(0, 10);
                        let date = moment(dateString).format("YYYY-MM-DD").toString();
                        formData.append(prop, date);
                    }
                    else {
                        let date = moment(postData.DocDate).format("YYYY-MM-DD").toString();
                        formData.append(prop, date);
                    }
                }

            }
        }

        xhr.open('POST', this.uploadUrl, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        xhr.send(formData);
        this._sharedService.showLoader("Processing...");
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                this._sharedService.hideLoader();
                if (xhr.status === 200) {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Document uploaded', detail: 'Document has been uploaded successfully.' });
                    this.isDisplayDialog = false;
                    this.uploadedFile = [];
                    this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                    this.clearSingleFile();

                }
                else if (xhr.status == 501) {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'warn', summary: 'Unable to update document URL', detail: xhr.responseText });
                    this.isDisplayDialog = false;
                    this.uploadedFile = [];
                    this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                    this.clearSingleFile();
                }
                else {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while uploading document.' });

                }
            }
        };
    }

    // #endregion

    populateDocTypeDropdown() {
        this._documentTypeService.getDocumentTypes('')
        .subscribe({
            next: (response) => {
                //this.DocType = [];
                this.DocType = response;
            }
        });
    }
    populateFileTypeDropdown() {

        this.FileTypeList = [{ label: "File Input", value: "File" }, { label: "Video URL", value: "VideoURL" }];
    }

    populateTagsDropdown() {
        this._tagService.getAllTags()
        .subscribe({
            next: (response) => {
                this.tags = response;
            }
        });
    }


    setCalenderDateFormat() {
        this.configuration = this._sharedService.getConfiguration();
        this.dateFormat = this.configuration.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
        this.dateSeparator = this.configuration.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
        this.day = Number(this.configuration.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
        this.month = Number(this.configuration.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
        this.year = Number(this.configuration.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
        let dateParts: string[] = this.dateFormat.split(this.dateSeparator);

        if (this.year == 0) {
            this.dateFormatCalender = (dateParts[this.year].substr(0, 2) + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.day]).toLowerCase();
        }
        else if (this.year == 2) {
            if (this.month == 1) {
                this.dateFormatCalender = (dateParts[this.day] + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
            }
            else if (this.month == 0) {
                this.dateFormatCalender = (dateParts[this.month] + this.dateSeparator + dateParts[this.day] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
            }

        }
        else {
            this.dateFormatCalender = "yy-mm-dd"
        }

    }


    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }
        switch (this.recordType) {
            case 'MXR':
                this.canUpload = this._sharedService.UserRole.Records_MXR_Doc_Single_Upload;
                this.canBulkUpload = this._sharedService.UserRole.Records_MXR_Doc_Bulk_Upload;
                this.canMXRQualityStatus = this._sharedService.UserRole.Records_MXR_Doc_Quality_Status;
                this.processMXRQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('MX_Records_Quality_Process');
                break;
            case 'LOG':
                this.canUpload = this._sharedService.UserRole.Records_LOG_Doc_Single_Upload;
                this.canBulkUpload = this._sharedService.UserRole.Records_LOG_Doc_Bulk_Upload;
                this.canLOGQualityStatus = this._sharedService.UserRole.Records_LOG_Doc_Quality_Status;
                this.processLOGQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('Log_Books_Quality_Process');
                break;
            case 'GEN':
                this.canUpload = this._sharedService.UserRole.Records_GEN_Doc_Single_Upload;
                this.canBulkUpload = this._sharedService.UserRole.Records_GEN_Doc_Bulk_Upload;
                this.canGENQualityStatus = this._sharedService.UserRole.Records_GEN_Doc_Quality_Status;
                this.processGENQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('General_Quality_Process');
                break;
            case 'DOC':
                this.canUpload = this._sharedService.UserRole.Records_DOC_Doc_Single_Upload;
                this.canBulkUpload = this._sharedService.UserRole.Records_DOC_Doc_Bulk_Upload;
                this.canDOCQualityStatus = this._sharedService.UserRole.Records_DOC_Doc_Quality_Status;
                this.processDOCQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('Documents_Quality_Process');
                break;
            case 'BND':
                this.canUpload = this._sharedService.UserRole.Transition_BND_Doc_Single_Upload;
                this.canBulkUpload = this._sharedService.UserRole.Transition_BND_Doc_Bulk_Upload;
                this.canBNDQualityStatus = this._sharedService.UserRole.Transition_BND_Doc_Quality_Status;
                this.processBNDQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('Binders_Quality_Process');
                break;
        }
    }

    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }
    getOCRStatus(value: any): number {
        if (value) {
            return 1;
        }
        else {
            return 0;
        }
    }
    onCloseDocDate() {
        var scannedby: any = document.getElementById('filetypesingle');
        scannedby.focus();
    }
    onCloseDocDateMultipleUpload() {
        var onoffswitch: any = document.getElementById('onoffswitch');
        onoffswitch.focus();
    }
    isChecked: boolean = false
    ToggleChecked() {

        if (this.isChecked) {
            this.isChecked = false;
            this.metaDataBulk.docNumberSwitch = 0;
        }
        else {
            this.isChecked = true;
            this.metaDataBulk.docNumberSwitch = 1;
        }
    }
}
