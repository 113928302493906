export class CopyMoveDocumentInputEntity
    {
        TenantId : string
        SourceContainerId : string
        DestinationcontainerId : string
        BlobNameList:string[]
        RecordType : string
        PageRange : string
        IsSkipReferencedDoc: boolean
        IsCopy:boolean
        UserName : string
    }