<section id="widget-grid" class="adminaircraftcls" *ngIf="canAddEditTag">

    <div class="row">
        <article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable" id="col1">

            <div class="no-margin jarviswidget jarviswidget-sortable" id="wid-id-2" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false" role="widget">

                <header role="heading" class="p-sortable-handle">

                    <span class="widget-icon"><i class="fa fa-tags"></i></span>
                    <h2>Tags</h2>
                    <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>

                    <div class="jarviswidget-ctrls" role="menu" *ngIf="canAddEditTag" title="Edit Tag (Ctrl+E)">
                        <a class="button-icon hand-cursor tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="btnEditClick()" id="btneditUserGroup" (click)="btnEditClick()">
                            <i class="glyphicon glyphicon-pencil"></i>
                        </a>
                    </div>
                    <div class="jarviswidget-ctrls" role="menu" *ngIf="canAddEditTag" title="Add Tag (Ctrl+A)">
                        <a class="button-icon hand-cursor tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="btnAddClick()" id="btnaddUserGroup" (click)="btnAddClick()">
                            <i class="glyphicon glyphicon-plus"></i>
                        </a>
                    </div>

                </header>


                <div class="widgetdiv">


                    <div class="jarviswidget-editbox">


                    </div>

                    <div id="tagslisting" class="no-bottom-padding widget-body">

                        <section id="widget-grid2" class="">

                            <div class="jqgridboxadminaircraft">
                                <!--style="height:80vh !important;"-->

                                <ag-grid-angular id="agGridTags" [navigateToNextCell]="navigateToNextCell"  #agGridTags [gridOptions]="gridOptions" [columnDefs]="columnDefs"  [rowData]="gridData" sortable="true" filter=true style="width: 100%;" class="ag-theme-balham tagsgrid" (rowClicked)='updateSelectedRow($event)' (rowDoubleClicked)="containerRowDoubleClicked($event)">
                                    <!--width: 100%;-->
                                </ag-grid-angular>
                            </div>
                        </section>

                    </div>

                </div>

            </div>

        </article>

    </div>
</section>
<form #addedittagsform="ngForm">
    <p-dialog id="tagsdlg" [(visible)]="display" showEffect="fade" [modal]="true" [style]="{width: '420px'}" [resizable]="false">
        <ng-template pTemplate="header"   (keyup.shift.tab)="btnCancelClick(addedittagsform)">
            {{dialogTitle}}
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Tag Name<span [hidden]="addeditTagName?.valid || model.Name" class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="addeditTagName" type="text" name="addeditTagName" #tagfocus
                                       placeholder="Tag Name" required [(ngModel)]="model.Name"  #addeditTagName="ngModel">

                            </label>

                        </fieldset>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Color Code<span [hidden]="(addeditTagsColorCode?.valid && addeditTagsColorCodeEditor?.valid)|| model.Color" class="text-danger">
                                    *
                                </span>
                            </label>
                            <p-colorPicker appendTo="body" id="addeditTagsColorCode" name="addeditTagsColorCode" [(ngModel)]="model.Color" #addeditTagsColorCode="ngModel"></p-colorPicker>
                            <input id="addeditTagsColorCodeEditor" class="color-picker-input" type="text" name="addeditTagsColorCodeEditor" required [(ngModel)]="model.Color" #addeditTagsColorCodeEditor="ngModel" style="width:30%">
                        </fieldset>
                    </div>

                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">Status</label>
                            <label class="checkbox">
                                <span class="onoffswitch tablIndexFocus onoffswitch-Width" [attr.tabindex]="0">
                                    <input type="checkbox" name="addedittagscolorcodestatus"
                                           class="onoffswitch-checkbox" checked="checked" id="addedittagscolorcodestatus" [(ngModel)]="model.Status" #addedittagscolorcodestatus="ngModel">
                                    <label class="onoffswitch-label"
                                           for="addedittagscolorcodestatus">
                                        <span class="onoffswitch-inner" data-swchon-text="Active" data-swchoff-text="Inactive"></span><span class="onoffswitch-switch onoffswitch-switch-Right"></span>
                                    </label>
                                </span>
                            </label>
                        </fieldset>
                    </div>
                </div>

            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex" (click)="onSubmit(addedittagsform)" [label]="lblsubmitButton" [disabled]="!addedittagsform.form.valid || isSubmitted"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" (click)="btnCancelClick(addedittagsform)" label="Cancel" [disabled]="isSubmitted" (focusout)="applyFocusToInput()"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>
