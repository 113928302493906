export class AddJobModel {

    constructor(
        public asset: string,

        public type: string,

        public date: Date,

        public priority: string,

        public dpjobstatusid: string,

        public assignedto: string,

        public documents: number,

        public pages: number,

        public status: boolean,

        public enddate?: Date,

        public referencekey?: string,

        public dpjobinternalstatusid?: string,

        public comments?: string,

        public history?: string,

        public createdby?: string,

        public createdon?: Date,

        public modifiedby?: string,

        public modifiedon?: Date,

        public conversation?:string,
        public containerid?: string,
        public batchtypeid?: string,
    ) { }

}
