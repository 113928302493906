<div id="widget-grid-main" class="ocrsearchcls">

    <p-overlayPanel #op id="searchoverly" styleClass="searchoverly-custom">
       <div>Special characters must be escaped to be used as part of the search text.<br/>You can escape them using phrase in quotation marks. Special characters that need to be escaped include the following:<br /> + - && || ! ( ) {{ '{' }} {{ '}' }} [ ] ^ " ~ * ? : \ / <br />For example: 1/2345 should be written as "1/2345"</div>
    </p-overlayPanel>
    <article id="search-col1" class="col-sm-2 col-md-2 col-lg-2 sortable-grid p-sortable no-padding standardpadding">
        <!-- NEW COL START -->
        <article id="searchId" [style.height]="height" style="overflow-y: auto;overflow-x:hidden;">
            <!--id="col2"-->
            <!-- Widget ID (each widget will need unique ID)-->
            <!--<div id="tempDiv" (click)="tempDivClick()"></div>-->
            <div class="row">
                <div style="padding-bottom: 5px;" class="col-sm-12  col-md-12 col-lg-12 ocrsearchbar">
                    <!--id="col1"-->
                    <fieldset>
                        <div class="form-group">
                            <div class="form-group col-sm-3 col-md-3 col-lg-3 nopaddingnmargin">
                                <select class="form-control searchtype nopaddingnmargin valid" [(ngModel)]="searchMode" tabindex="1" id="searchtype" aria-invalid="false" (onChange)="searchModeChange()">
                                    <option *ngFor="let x of _searchTypes" [value]="x.searchType">{{x.searchType}}</option>
                                    <!--<option value="2">Begin</option>-->
                                </select>
                            </div>
                            <div class="form-group col-sm-9 col-md-9 col-lg-9 nopaddingnmargin">
                                <div class="input-group">
                                    <input id="searchinput" class="form-control searchcontrol" tabindex="2" [(ngModel)]="searchQuery"  #searchinput type="text" placeholder="OCR Search..." (keyup.enter)="handleSearchButtonClick()" (ngModelChange)="onModelChange($event,op,searchcol)"/> <!-- (ngModelChange)="onModelChange($event,op,searchcol)"-->
                                    <span title="Clear" [ngStyle]="{'visibility': searchQuery?.length ? 'visible':''}" (click)="resetRearchQuery()">×</span>

                                    <div class="input-group-btn">
                                        <button #searchbutton pButton class="btn btn-default btn-primary search-input-pdf fix-font-width" icon="fa fa-search" type="button" (click)="handleSearchButtonClick()">
                                            <!--<i class="fa fa-search"></i>-->
                                        </button>
                                    </div>
                                    <!-- <div class="input-group-btn"> -->
                                        <a class="button-icon hand-cursor pull-right" style="padding-left: 5px;padding-bottom: 6px;" id="btnAddkeywordsearch" (click)="addAdvanceSearch()">
                                            <i class="glyphicon glyphicon-plus"></i>
                                        </a>
                                        <!-- <button #searchbutton pButton class="p-button-secondary search-input-pdf fix-font-width" icon="fa fad fa-plus fa-w-16 fa-7x" type="button" (click)="addAdvanceSearch()">
                                        </button> -->
                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset>
                        <div *ngFor="let advanceSearch of advanceSearch; let j = index">
                            <div style="padding: 0px !important;margin: 0px !important" class="form-group col-sm-3 col-md-3 col-lg-3">
                                <ng-template [ngIf]="j >=0">
                                    <select class="form-control searchtype nopaddingnmargin valid" id="condition{{j}}" name="condition{{j}}"
                                        [(ngModel)]="advanceSearch.Condition" placeholder="Select Condition">
                                        <option *ngFor="let c of keywordConditions" [value]="c.value">{{c.label}}
                                        </option>
                                    </select>
                                </ng-template>
                            </div>
                            <div class="form-group col-sm-9 col-md-9 col-lg-9 nopaddingnmargin">
                                <div class="input-group">
                                    <input id="cndValue{{j}}" class="form-control searchcontrol" type="text" name="cndValue{{j}}"
                                     [(ngModel)]="advanceSearch.SearchValue" placeholder="OCR Search..."/>
                                    <div class="input-group-btn">
                                        <button #searchbutton pButton class="p-button-secondary search-input-pdf fix-font-width" icon="fa fas fa-close fa-w-16 fa-7x" type="button" (click)="deleteInstance(advanceSearch)">
                                            <!--<i class="fa fa-search"></i>-->
                                        </button>
                                    </div>
                                    </div>
                                </div>
                            </div>
                    </fieldset>
                </div>
            </div>

            <div class="jarviswidget jarviswidget-sortable" id="wid-id-2" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-togglebutton="false" data-widget-deletebutton="false" data-widget-fullscreenbutton="false" data-widget-custombutton="false" role="widget">
                <!-- widget options:
                            usage: <div class="jarviswidget" id="wid-id-0" data-widget-editbutton="false">

                            data-widget-colorbutton="false"
                            data-widget-editbutton="false"
                            data-widget-togglebutton="false"
                            data-widget-deletebutton="false"
                            data-widget-fullscreenbutton="false"
                            data-widget-custombutton="false"
                            data-widget-collapsed="true"
                            data-widget-sortable="false"

                        -->
                <header role="heading" class="tab-header" id="docPanelHeader" style="cursor:default;">
                    <ul id="widget-tab-1" class="nav nav-tabs">
                        <li class="active">
                            <a id="anAdvanceFilter" #fltPanel data-toggle="tab" tabindex="4" data-target="#hr1" aria-expanded="true" style="cursor: pointer;">
                                <span class="hidden-mobile hidden-tablet">Filter </span>
                                <ng-template [ngIf]="toggleSearchIcon">
                                    <span id="spanClearFilter" onclick="return false;" (click)="ClearFilters()" class="hidden-mobile hidden-tablet"><i class="fa fa-times"></i></span>
                                </ng-template>
                            </a>
                        </li>
                        <li class="">
                            <a id="anRefinerFilter" #refinerPanel data-toggle="tab" tabindex="11" data-target="#hr2" aria-expanded="false" style="cursor: pointer;">
                                <span class="hidden-mobile hidden-tablet">Refiner </span>
                                <ng-template [ngIf]="toggleRefinerIcon">
                                    <span id="spanClearrefiner" onclick="return false;" (click)="handleSearchButtonClick()" class="hidden-mobile hidden-tablet"><i class="fa fa-times"></i></span>
                                </ng-template>
                            </a>
                        </li>
                    </ul>
                    <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>
                </header>

                <!-- widget div-->
                <div role="content" class="filtercontent widgetdiv">
                    <!--style="height:535px;"-->
                    <!-- widget edit box -->
                    <div class="jarviswidget-editbox">
                        <!-- This area used as dropdown edit box -->

                    </div>
                    <!-- end widget edit box -->
                    <!-- widget content -->
                    <div class="widget-body no-padding set-filterpanel-height">
                        <!--style="height:535px;"-->
                        <!-- widget body text-->

                        <div class="tab-content">
                            <div class="tab-pane fade active in" id="hr1">
                                <div class="popover-content">
                                    <div class="form-group col-sm-12  col-md-12 col-lg-12 filterInContentTab">
                                        <!--<%--<ul id="myTab1" class="nav nav-tabs bordered">
                                            <li class="active">
                                                <a href="#s1" data-toggle="tab">Aircrafts</a>
                                            </li>
                                            <li>
                                                <a href="#s2" data-toggle="tab">Assets</a>
                                            </li>
                                            <li>
                                                <a href="#s3" data-toggle="tab">Others</a>
                                            </li>
                                        </ul>
                                        <div id="myTabContent1" class="tab-content">
                                            <div class="tab-pane fade in active" id="s1">

                                            </div>
                                            <div class="tab-pane fade" id="s2">

                                            </div>
                                            <div class="tab-pane fade" id="s3">

                                            </div>
                                        </div>--%>-->
                                        <div class="wrap">
                                            <div style="padding-top: 0px;" id="filter-form" class="smart-form">
                                                <div class='row'>
                                                    <div class='form-group col-sm-12'>
                                                        <fieldset>
                                                            <label class='searchConterol label'>Document Date (From)</label>
                                                            <label class='input set-date-control-width'>
                                                                <i class='icon-append fa fa-calendar'></i>

                                                                <p-calendar appendTo="body" #dateFrom (onShow)="onShowOfDateTo(dateFrom)" class='datepicker hasDatepicker' panelStyleClass="panelTop" placeholder="{{ViewDateFormat}}" [(ngModel)]="fltFromDate" [dateFormat]="dateFormatCalender" yearNavigator="true" yearRange="1950:2030" (onSelect)="handleFCalendarSelect($event)"
                                                                            monthNavigator="true">
                                                                </p-calendar>
                                                                <!-- <p-calendar appendTo="body" class='datepicker hasDatepicker' [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030" [(ngModel)]="fltFromDate" [dateFormat]="dateFormatCalender" (onSelect)="handleFCalendarSelect($event)">
                                                                </p-calendar> -->
                                                                <!--yearRange="(1988:2020)"
                                                                yearNavigator="true"
                                                                monthNavigator="true"-->
                                                                <!--<input id="fltdocdate" tabindex="5" value="{{name}}" type='text' placeholder='Select a date' class='datepicker hasDatepicker' data-property="DocDateOWSDATE" readonly='readonly'>-->
                                                            </label>
                                                        </fieldset>
                                                    </div>
                                                </div>

                                                <div class='row'>
                                                    <div class='form-group col-sm-12'>
                                                        <fieldset>

                                                            <label class='searchConterol label'>Document Date (To)</label>
                                                            <label class='input set-date-control-width'>
                                                                <i class='icon-append fa fa-calendar'></i>
                                                              
                                                                 <p-calendar appendTo="body" #dateTo class='datepicker hasDatepicker' (onShow)="onShowOfDateTo(dateTo)" panelStyleClass="panelTop" placeholder="{{ViewDateFormat}}" [(ngModel)]="fltToDate" [dateFormat]="dateFormatCalender" yearNavigator="true" yearRange="1950:2030"
                                                                 (onSelect)="handleTCalendarSelect($event)" monthNavigator="true">
                                                                </p-calendar> 
                                                                <!-- <p-calendar appendTo="body" class='datepicker hasDatepicker' [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030" [(ngModel)]="fltToDate" [dateFormat]="dateFormatCalender">
                                                                </p-calendar> -->
                                                            </label>
                                                        </fieldset>
                                                    </div>
                                                </div>

                                                <div class='row'>
                                                    <div class='form-group col-sm-12' id="drpDocTypes">
                                                        <fieldset>
                                                            <label class="searchConterol label">Document Type</label>
                                                            <!--<p-dropdown appendTo="body" [options]="documentTypes" ></p-dropdown>-->
                                                            <p-dropdown appendTo="body" class="dropdown-options" [options]="documentTypes" [(ngModel)]="fltSelectedDocType" [filter]="true"  placeholder="{{PlaceholderDocumentType}}"></p-dropdown>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div class='row'>
                                                    <div class='form-group col-sm-12'>
                                                        <fieldset>
                                                            <label class="searchConterol label">Document Number</label>
                                                            <label class="input rgxErr">
                                                                <i *ngIf="docno.value && !docno.valid && (docno.touched || docno.dirty )"
                                                                    class="icon-append fa fa-ban"
                                                                    title="{{_sharedService.error_rgx_pre}} {{_sharedService.re_DocumentNumber_Message}} {{_sharedService.error_rgx_post}}">
                                                                </i>
                                                                <input id="fltdocno" type="text" tabindex="7" #docno="ngModel" class="input-sm" data-property="DocNumberOWSTEXT"
                                                                [pattern]="_sharedService.re_DocumentNumber"
                                                                [(ngModel)]="fltDocumentNumber" />
                                                            </label>
                                                        </fieldset>
                                                    </div>
                                                </div>

                                                <div class='row'>
                                                    <div class='form-group col-sm-12'>
                                                        <fieldset>
                                                            <label class="searchConterol label">{{referenceKeyLabel}}</label>

                                                            <label class="input rgxErr">
                                                                <i *ngIf="fltwono.value && !fltwono.valid && (fltwono.touched || fltwono.dirty )"
                                                                    class="icon-append fa fa-ban"
                                                                    title="{{_sharedService.error_rgx_pre}} {{_sharedService.re_WorkOrderNumber_Message}} {{_sharedService.error_rgx_post}}">
                                                                </i>
                                                                <input id="fltwono" type="text" tabindex="8" class="input-sm" data-property="WONumberOWSTEXT"
                                                                [pattern]="_sharedService.re_WorkOrderNumber" #fltwono="ngModel"
                                                                [(ngModel)]="fltWONumber" />
                                                            </label>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div class='row'>
                                                    <div class='form-group col-sm-12 flttags' id="drpTags">
                                                        <fieldset>
                                                            <label class="searchConterol label">Tags</label>
                                                            <p-multiSelect id="multiSelectTags" [options]="tags" [(ngModel)]="fltselectedTags" [maxSelectedLabels]="0" selectedItemsLabel="{0} items selected"></p-multiSelect>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="hr2" style="margin-right: 9px;">
                                <section id="widget-grid-refiner" style="overflow: auto; margin-right: -13px;" *ngIf="rowData?.length > 0">
                                    <ul *ngFor="let facet of facets let i = index" [attr.data-index]="i" class="collapse in col-xs-12 col-md-6 col-lg-12 product">
                                        <li class="list">
                                            <a data-toggle="collapse" data-parent=".product" href="#refiner{{i}}" class="collapsed">
                                                <!-- <div> -->
                                                    <ng-container *ngIf="facet.Key=='ASN';else second">Asset MSN</ng-container>
                                                    <ng-template #second>
                                                        <ng-container *ngIf="facet.Key=='DocType';else third">Document Type</ng-container>
                                                    </ng-template>
                                                    <ng-template #third>Status</ng-template>
                                                <!-- </div>  -->

                                                <!-- <ng-template [ngIf]="facet.Key=='ASN'">Asset MSN</ng-template>
                                                <ng-template [ngIf]="facet.Key=='DocType'">Document Type</ng-template>
                                                <ng-template [ngIf]="facet.Key=='Status'">Status</ng-template>-->
                                            </a>
                                            <ul id="refiner{{i}}" class="collapse in productList">

                                                <li *ngFor="let facetvalue of facet.Value" class="sub-list"><a href="javascript:void(0)" (click)="handleRefinerClick($event)" class="refiner" [attr.data-type]="facet.Key"  [attr.data-refiner]="facetvalue.value" [attr.data-from]="facetvalue.from">{{facetvalue.value}}</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </section>
                            </div>
                        </div>
                        <!-- end widget body text-->
                    </div>
                    <!-- end widget content -->

                </div>
                <!-- end widget div -->

            </div>
            <!-- end widget -->
        </article>
        <!-- END COL -->
    </article>
    <article id="search-col2" class="col-sm-4 col-md-4 col-lg-4 sortable-grid p-sortable no-padding standardpadding" #searchcol>
        <!-- NEW COL START -->
        <article>
            <!--id="col3"-->
            <!-- Widget ID (each widget will need unique ID)-->
            <div class="jarviswidget" id="wid-id-3" data-widget-fullscreenbutton="true" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="true">

                <header id="searchDocPanelHeader">

                    <div class="jarviswidget-ctrls" role="menu">

                        <div style="float:left;" *ngIf="isAutoSearch">

                            <div class="jarviswidget-ctrls">
                                <a class="button-icon" id="btnclipboard" title="OCR Query" style="padding-top: 10px;padding-right: 4px;">
                                    <i class='fa fa-copy fa-lg pull-right' pTooltip="{{clpSearchQuery}}" ngxClipboard [cbContent]="clpSearchQuery"></i>
                                </a>
                            </div>
                        </div>
                        <!--search-min-width-->
                        <edit-metadata *ngIf="(canEditMetaData || canEditMetaDataReadonly) && rowData && _displayMode!='status'" style="float:left"></edit-metadata>
                        <ng-template [ngIf]="!isAutoSearch">
                            <a title={{wideSearchDocPanalTitle}} class="button-icon" href="javascript:void(0);" id="btnSearchWideScreen" (click)="wideSearchDocPanal(true)"><i class="glyphicon glyphicon-resize-horizontal"></i></a>
                        </ng-template>
                        <!-- <a href="javascript:void(0);"  *ngIf="displayMode && displayMode!='status'" class="button-icon jarviswidget-fullscreen-btn" id="btnSearchFullScreen" rel="tooltip" title="" data-placement="bottom" data-original-title="Fullscreen" (click)="fullScreenSearchDocPanal()"><i class="fa fa-expand"></i></a> -->
                        <a title={{fullScreenSearchDocPanalTitle}} href="javascript:void(0);"  *ngIf="_displayMode && _displayMode!='status'" class="button-icon jarviswidget-fullscreen-btn" id="btnSearchFullScreen" rel="tooltip" data-placement="bottom" data-original-title="Fullscreen" (click)="fullScreenSearchDocPanal()"><i class="fa fa-expand"></i></a>
                    </div>

                    <h2>
                        <strong id="searchtotalcount">{{searchResultCount}} </strong>
                        <span id="searchresultext">{{searchresultext}}</span>
                    </h2>
                    <ng-template [ngIf]="isSearchCount">
                        <select class="searchcount" id="searchcount" [(ngModel)]="SelectMaxDocumentCount">
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="300">300</option>
                            <option value="400">400</option>
                            <option value="500">500</option>
                        </select>
                    </ng-template>
                </header>
                <!-- widget div-->
                <div class="widgetdiv">

                    <!-- widget edit box -->
                    <div class="jarviswidget-editbox">
                        <!-- This area used as dropdown edit box -->

                    </div>
                    <!-- end widget edit box -->
                    <!-- widget content -->
                    <div class="widget-body set-searchDoc-height">
                        <!--style="height:563px;"-->
                        <!--
                        The ID "widget-grid" will start to initialize all widgets below
                        You do not need to use widgets if you dont want to. Simply remove
                        the <section></section> and you can use wells or panels instead
                            (modelUpdated)='handleGridReady($event)'
                        -->
                        <!-- widget grid -->
                        <section id="widget-grid" class="">
                            <div class="navbar-form form-group">
                                <div id='gridContainer' *ngIf="rowData!=undefined">
                                    <!--AG Grid Start-->
                                    <ag-grid-angular id="documentGrid" #docGrid [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="rowData" sortable="true" filter=true style="width: 100% !important;" class="ag-theme-balham set-searchgrid-width" (selectionChanged)="handleSelectedDoc()" (rowDoubleClicked)="docGridRowDoubleClicked($event)">
                                        <!--(rowClicked)='handleSelectedDoc($event)'-->
                                    </ag-grid-angular>
                                    <!--AG Grid End-->
                                </div>

                                <!--<div id="dvNoRecordContainer" class="alert alert-block alert-warning" *ngIf="rowData==undefined">
                                    <h4 class="alert-heading">Warning!</h4>
                                    Please select Asset!
                                </div>-->
                                <div id="pjqgrid"></div>
                            </div>
                            <!-- end row -->

                        </section>
                        <!-- end widget grid -->
                    </div>
                    <!-- end widget content -->
                </div>
                <!-- end widget div -->
            </div>
            <!-- end widget -->
        </article>
        <!-- END COL -->
    </article>

    <!-- NEW COL START -->
    <browse-pdf></browse-pdf> <!--[recordType]="recordType"-->
    <!-- END COL -->
</div>

<!--<style>
    #hr2::-webkit-scrollbar {
        width: 10px;
        background-color: #F5F5F5;
        border: 2px solid #555555;
    }
</style>-->
