
//#region References
//Components
import { Component, OnInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import { Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';
import { NgForm } from '@angular/forms';
import { SettingsService } from '../../services/settings/setting.service';
import { SelectItem} from 'primeng/api';
import { GridOptions } from 'ag-grid-community';
import { OperationMode } from '../../../shared/helpers/config';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { HiddenConfigModel } from './hidden.model';
var _router_: Router;
//#endregion
//#region Type Decorator
@Component({
    selector: 'hidden',
    templateUrl: './hidden.component.html',
    providers: [SettingsService]
})
//#endregion

export class HiddenComponent implements OnInit, OnDestroy {
    display: boolean = false;
    public gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[]=[];

    public canAddEditUserGroup: boolean = false;

    selectedConfigID: string = null;

    public totalCount: number;

    public isSubmitted: boolean = false;
    userGroupList: SelectItem[] = [];
    public dialogTitle: string = 'Edit Configuration';
    public lblsubmitButton: string = 'Edit';
    public operationMode: OperationMode = 2;
    public model:HiddenConfigModel;
    public appHotkeys:  Hotkey[] = [];

    @ViewChild('focuskey') focuskey:ElementRef;

    //#region Initialization of Component
    constructor(private _sharedService: SharedService, private _router: Router, private _hotkeysService: HotkeysService,private _settingsService:SettingsService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            _router_ = this._router;
            this.setHotKeys();
            this.navigateToNextCell=this.navigateToNextCell.bind(this);
        }
    }
    setHotKeys() {

        let hotKeyObj : Hotkey = null;
        //Edit setting
        hotKeyObj = new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/config")
            {
                this.btnEditClick();
            }
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
        hotKeyObj = new Hotkey('enter', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/config")
            {
                this.btnEditClick();
            }
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
    }
    ngOnInit() {
        this.model=new HiddenConfigModel();
            this.gridOptions = <GridOptions>
                {
                    rowSelection: 'single',

                    onGridReady: () => {
                        this.setConfigGridColumns();
                        try {
                            this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridUserGroup'));
                            this.gridOptions.api.hideOverlay();
                        } catch (e) {
                            console.log(e);
                        }
                    },
                    //suppressContextMenu: true,
                    pagination: true,
                    paginationPageSize: 20,
                    getContextMenuItems: this.getCustomContextMenuItems,
                    context: {
                        gridContext:this
                    },
                    defaultColDef: {
                        sortable: true,
                        filter:true
                    }
                };
           // this.getCategoryList();
            this.populateHiddenSettingsGrid();

    }

    ngOnDestroy() {
        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
    }
    //#endregion

    //#region Ag Grid Population Event
    populateHiddenSettingsGrid(): void {

        this._settingsService.getAllSettings()
        .subscribe({
            next: (response) => {
                this.gridOptions.api.sizeColumnsToFit();
                this.gridData = [];
                this.gridData = response;
                this.totalCount = response.length;                
            },
            error: (error) => {
                this.gridData = [];
                this.totalCount = 0;                
            }
        });
    }
    //#endregion
    //#region Set Columns of Browse Ag Grid
    setConfigGridColumns(): void {
        this.columnDefs = [];
        this.columnDefs =
            [
            { headerName: 'Key', field: 'Key', menuTabs: ['filterMenuTab'] },
            { headerName: 'Value', field: 'Value', menuTabs: ['filterMenuTab'] }
            ];

    }
    //#endregion

    //#region Ag-Grid Events
    updateSelectedRow(event: any) {
        this.selectedConfigID = null;
        this.selectedConfigID = event.data.ConfigID;
    }
    //#endregion

    //#region Edit User Events
    containerRowDoubleClicked(event: any) {

            this.selectedConfigID = null;
            this.selectedConfigID = event.data.ConfigID;
            this.btnEditClick();

    }
    btnEditClick() {
        let selectedRows = this.gridOptions.api.getSelectedRows();
            if (this.selectedConfigID && (selectedRows && selectedRows.length > 0)) {
            this.operationMode = 2;
            this.dialogTitle = 'Edit Configuration';
            this.lblsubmitButton = 'Update';

            this._settingsService.getConfigById(this.selectedConfigID)
            .subscribe({
                next: (response) => {
                    let data = response;
                    this.model = new HiddenConfigModel();
                    this.model.ConfigID = data.ConfigID;
                    this.model.Key = data.Key;
                    this.model.Value = data.Value;
                    this.showHideDialogue(true);                    
                },
                error: (error) => {
                    this._sharedService.clearToast();
                    this._sharedService.showToast(
                        { 
                            severity: 'error', 
                            summary: 'Error Occured', 
                            detail: 'Error occured , please try again.' 
                        }
                    );
        
                    this.showHideDialogue(false);                    
                }
            });
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No row Selected', detail: 'Please select a record.' });
        }
    }
    //#endregion

    //#region Cancel Dialogue
    btnCancelClick(cancelForm: NgForm) {
        this.showHideDialogue(false);
        this.model = new HiddenConfigModel();
        cancelForm.resetForm();
    }
    //#endregion
    //#region Helper Methods
    showHideDialogue(flag: boolean) {
        this.display = flag;
    }
    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }
    //#endregion

    //#region form submit
    onSubmit(form: NgForm) {
        this.changeButtonsStatus(true);
        this._sharedService.showLoader("Processing...");
        if (this.operationMode == 2) {
            let body: any =
                {
                    ConfigID: this.selectedConfigID,
                    Key: this.model.Key,
                    Value: this.model.Value
                }
            this._settingsService.updateConfigByID(body)
            .subscribe({
                next: (response) => {
                    this.showHideDialogue(false);
                    form.resetForm();
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast(
                        { 
                            severity: 'success', 
                            summary: 'Success', 
                            detail: 'Configuration updated succesfully.' 
                        }
                    );
                    this.changeButtonsStatus(false);
                    this.populateHiddenSettingsGrid();                    
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
        
                    this._sharedService.showToast(
                        { 
                            severity: 'error', 
                            summary: 'Error Occured', 
                            detail: 'Configuration not updated, please try again.' 
                        }
                    );
        
                    this.showHideDialogue(false);
                    this.model = new HiddenConfigModel();
                    form.resetForm();
                    this.changeButtonsStatus(false);                    
                }
            })
        }



    }
    //#endregion

    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any
    {
        let result: any[] = [];

            result.push({
                name: "Edit Configuration",
                action: function () {
                    params.context.gridContext.selectedConfigID = null;
                    if (params.node) {
                        params.context.gridContext.selectedConfigID = params.node.data.ConfigID;
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext.btnEditClick();
                    }

                },
                icon: params.context.gridContext._sharedService.editIconHTMLasString,
            });
            let childResult: any[] = [];
            childResult.push({
                name: 'Excel',
                action: function () {
                    
                    params.api.exportDataAsExcel();
                    
                },
                icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
            });

            childResult.push({
                name: 'CSV',
                action: function () {
                    params.api.exportDataAsCsv();
                },
                icon: params.context.gridContext._sharedService.saveLogFeedIconHTMLasString,
            });

            result.push({
                name: 'Download',
                action: function () {


                },
                icon: params.context.gridContext._sharedService.downloadsFeedIconHTMLasString,
                subMenu: childResult,
            });
            return result;


    }
    //#endregion

    applyFocusToInput(){

      this.focuskey.nativeElement.focus();
    }
    navigateToNextCell(params: any) {
        var previousCell = params.previousCellPosition;
        var suggestedNextCell = params.nextCellPosition;
        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        switch (params.key) {
            case KEY_DOWN:
                var nextRowIndex = previousCell.rowIndex + 1;
                var renderedRowCount = this.gridOptions.api.getModel().getRowCount();
                if (nextRowIndex >= renderedRowCount) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedConfigID = null;
                    this.selectedConfigID = rowNode.data.ConfigID;
                        return {
                        rowIndex: nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_UP:
                var _nextRowIndex = previousCell.rowIndex - 1;
                if (_nextRowIndex < 0) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedConfigID = null;
                    this.selectedConfigID = rowNode.data.ConfigID;
                        return {
                        rowIndex: _nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw 'this will never happen, navigation is always on of the 4 keys above';
        }
    }
}
