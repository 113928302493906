<div>
    <!--style="height:650px;overflow:scroll;"-->
    <!--#settingForm="ngForm"-->
    <header role="heading" class="tab-header">

        <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>

        <p-confirmDialog [style]="{width: '517px'}"></p-confirmDialog>
    </header>
    <div class="row addaircraftcls">
        <article class="col-sm-12 col-md-12 col-lg-12" id="col2">

            <div class="jarviswidget" style="margin-bottom: 0px;" id="wid-id-0" data-widget-colorbutton="false"
                data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false"
                data-widget-togglebutton="false">

                <header>
                    <span class="widget-icon"><i class="fa fa-cogs"></i></span>
                    <h2>Settings</h2>
                </header>

                <!-- widget div-->
                <div class="widgetdiv row">
                    <!--style="height:1060px !important"-->
                    <!-- widget content -->
                    <div class="widget-body sticky-Menu" style="padding-bottom:0px;">
                        <!-- widget grid -->
                        <section id="widget-grid0" class="">
                            <div class="navbar-form form-group">

                                <div id="myTabContent1" class="tab-content" style="height: 83vh;">
                                    <div class="tab-pane fade active in" id="s1">
                                        <div class="smart-form">
                                            <div class="sticky-Menu">
                                                <ul id="widget-tab-1" class="nav nav-tabs" role="tablist">
                                                    <li id="applicationsettings1"
                                                        [ngClass]="(isActiveTabApplication==true)?'active':''">
                                                        <a #panal1 data-toggle="tab" [attr.tabindex]="0"
                                                            data-target="#applicationsettings"
                                                            [attr.aria-expanded]="isActiveTabApplication"
                                                            style="cursor: pointer;"
                                                            (keyup.enter)="getSettings('Application',true,$event)"
                                                            (click)="getSettings('Application',true,$event)">
                                                            <!--[attr.aria-expanded]="!isAutoSearchTab"-->
                                                            <i class="glyphicon glyphicon-modal-window"></i><span
                                                                class="hidden-mobile hidden-tablet"
                                                                style="padding-left: 3px">Application</span>
                                                        </a>
                                                    </li>
                                                    <li id="usersettingssettings2"
                                                        [ngClass]="(isActiveTabUser==true)?'active':''"
                                                        *ngIf="Current_SubDomain_Tenant != SubDomain_Tenant_Internal">
                                                        <a #panal2 data-toggle="tab"
                                                            [attr.aria-expanded]="isActiveTabUser" [attr.tabindex]="0"
                                                            data-target="#usersettings" style="cursor: pointer;"
                                                            (keyup.enter)="getSettings('User',true,$event)"
                                                            (click)="getSettings('User',true,$event)">
                                                            <i class="fa fa-user blackiconcolor"></i><span
                                                                class="hidden-mobile hidden-tablet"
                                                                style="padding-left: 3px">User</span>
                                                        </a>
                                                    </li>
                                                    <li id="inboxsettingssettings3"
                                                        [ngClass]="(isActiveTabInbox==true)?'active':''"
                                                        *ngIf="Current_SubDomain_Tenant != SubDomain_Tenant_Internal">
                                                        <a #panal3 data-toggle="tab"
                                                            [attr.aria-expanded]="isActiveTabInbox" [attr.tabindex]="0"
                                                            data-target="#inboxsettings" style="cursor: pointer;"
                                                            (keyup.enter)="getSettings('Inbox',true,$event)"
                                                            (click)="getSettings('Inbox',true,$event)">
                                                            <i class="fa fa-envelope"></i><span
                                                                class="hidden-mobile hidden-tablet"
                                                                style="padding-left: 3px">Inbox</span>
                                                        </a>
                                                    </li>
                                                    <li id="dashboardsettings4"
                                                        [ngClass]="(isActiveTabDashboard==true)?'active':''"
                                                        *ngIf="Current_SubDomain_Tenant != SubDomain_Tenant_Internal">
                                                        <a #panal4 data-toggle="tab"
                                                            [attr.aria-expanded]="isActiveTabDashboard"
                                                            [attr.tabindex]="0" data-target="#dashboardsettings"
                                                            style="cursor: pointer;"
                                                            (keyup.enter)="getSettings('Dashboard',true,$event)"
                                                            (click)="getSettings('Dashboard',true,$event)">
                                                            <i class="fa fa-gauge-high blackiconcolor"></i><span
                                                                class="hidden-mobile hidden-tablet"
                                                                style="padding-left: 3px">Dashboard</span>
                                                        </a>
                                                    </li>
                                                    <li id="autosearchsettings5"
                                                        [ngClass]="(isActiveTabAutoSearch==true)?'active':''"
                                                        *ngIf="Current_SubDomain_Tenant != SubDomain_Tenant_Internal">
                                                        <a #panal5 data-toggle="tab"
                                                            [attr.aria-expanded]="isActiveTabAutoSearch"
                                                            [attr.tabindex]="0" data-target="#autosearchsettings"
                                                            style="cursor: pointer;"
                                                            (keyup.enter)="getSettings('AutoSearch',true,$event)"
                                                            (click)="getSettings('AutoSearch',true,$event)">
                                                            <i class="fa fa-search blackiconcolor"></i><span
                                                                class="hidden-mobile hidden-tablet"
                                                                style="padding-left: 3px">Auto Search</span>
                                                        </a>
                                                    </li>
                                                    <li id="gapanalyzer"
                                                        [ngClass]="(isActiveTabGapAnalyzer==true)?'active':''"
                                                        *ngIf="Current_SubDomain_Tenant != SubDomain_Tenant_Internal &&  _sharedService.getRunAutoGapAnalyserValue()=='true'">
                                                        <a #panal5 data-toggle="tab"
                                                            [attr.aria-expanded]="isActiveTabGapAnalyzer"
                                                            [attr.tabindex]="0" data-target="#gapanalyzersettings"
                                                            style="cursor: pointer;"
                                                            (keyup.enter)="getSettings('GapAnalyzer',true,$event)"
                                                            (click)="getSettings('GapAnalyzer',true,$event)">
                                                            <i class="fa fa-exchange blackiconcolor"></i><span
                                                                class="hidden-mobile hidden-tablet"
                                                                style="padding-left: 3px">Gap Analyser</span>
                                                        </a>
                                                    </li>
                                                    <li id="jobssettings6"
                                                        [ngClass]="(isActiveTabJobs==true)?'active':''"
                                                        *ngIf="Current_SubDomain_Tenant != SubDomain_Tenant_Internal">
                                                        <a #panal6 data-toggle="tab"
                                                            [attr.aria-expanded]="isActiveTabJobs" [attr.tabindex]="0"
                                                            data-target="#jobssettings" style="cursor: pointer;"
                                                            (keyup.enter)="getSettings('Jobs',true,$event)"
                                                            (click)="getSettings('Jobs',true,$event)">
                                                            <i class="fa fa-ticket blackiconcolor"></i><span
                                                                class="hidden-mobile hidden-tablet"
                                                                style="padding-left: 3px">Jobs</span>
                                                        </a>
                                                    </li>
                                                    <li id="exportsettings7"
                                                        [ngClass]="(isActiveTabExport==true)?'active':''"
                                                        *ngIf="Current_SubDomain_Tenant != SubDomain_Tenant_Internal">
                                                        <a #panal7 data-toggle="tab"
                                                            [attr.aria-expanded]="isActiveTabExport" [attr.tabindex]="0"
                                                            data-target="#exportsettings" style="cursor: pointer;"
                                                            (keyup.enter)="getSettings('Export',true,$event)"
                                                            (click)="getSettings('Export',true,$event)">
                                                            <i class="glyphicon glyphicon-export"></i><span
                                                                class="hidden-mobile hidden-tablet"
                                                                style="padding-left: 3px">Export</span>
                                                        </a>
                                                    </li>
                                                    <li id="apisettings8" [ngClass]="(isActiveTabAPI==true)?'active':''"
                                                        *ngIf="Current_SubDomain_Tenant != SubDomain_Tenant_Internal">
                                                        <a #panal8 data-toggle="tab"
                                                            [attr.aria-expanded]="isActiveTabAPI" [attr.tabindex]="0"
                                                            data-target="#apisettings" style="cursor: pointer;"
                                                            (keyup.enter)="getSettings('API',true,$event)"
                                                            (click)="getSettings('API',true,$event)">
                                                            <i class="glyphicon glyphicon-transfer"></i><span
                                                                class="hidden-mobile hidden-tablet"
                                                                style="padding-left: 3px">API</span>
                                                        </a>
                                                    </li>
                                                    <!-- <li id="ocrsettings9" [ngClass]="(isActiveTabOCR==true)?'active':''"
                                                        *ngIf="Current_SubDomain_Tenant != SubDomain_Tenant_Internal">
                                                        <a #panal9 data-toggle="tab"
                                                            [attr.aria-expanded]="isActiveTabOCR" [attr.tabindex]="0"
                                                            data-target="#ocrsettings" style="cursor: pointer;"
                                                            (keyup.enter)="getSettings('OCR',true,$event)"
                                                            (click)="getSettings('OCR',true,$event)">
                                                            <i class="fa fa-search blackiconcolor"></i><span
                                                                class="hidden-mobile hidden-tablet"
                                                                style="padding-left: 3px">OCR</span>
                                                        </a>
                                                    </li> -->
                                                    <li id="alertsettings10"
                                                        [ngClass]="(isActiveTabAlert==true)?'active':''"
                                                        *ngIf="isSysAdmin && Current_SubDomain_Tenant != SubDomain_Tenant_Internal">
                                                        <a #panal10 data-toggle="tab"
                                                            [attr.aria-expanded]="isActiveTabAlert" [attr.tabindex]="0"
                                                            data-target="#alertsettings" style="cursor: pointer;"
                                                            (keyup.enter)="getSettings('Alert',true,$event)"
                                                            (click)="getSettings('Alert',true,$event)">
                                                            <i class="fa fa-bell"></i><span
                                                                class="hidden-mobile hidden-tablet"
                                                                style="padding-left: 3px">Alert</span>
                                                        </a>
                                                    </li>
                                                    <li id="qualitysettings11"
                                                        [ngClass]="(isActiveTabQualityStatus==true)?'active':''"
                                                        *ngIf="Current_SubDomain_Tenant != SubDomain_Tenant_Internal">
                                                        <a #panal11 data-toggle="tab"
                                                            [attr.aria-expanded]="isActiveTabQualityStatus"
                                                            [attr.tabindex]="0" data-target="#qualitysettings"
                                                            (keyup.enter)="getSettings('QualityStatus',true,$event)"
                                                            style="cursor: pointer;"
                                                            (click)="getSettings('QualityStatus',true,$event)">
                                                            <i class="glyphicon glyphicon-check"></i><span
                                                                class="hidden-mobile hidden-tablet"
                                                                style="padding-left: 3px">Document</span>
                                                        </a>
                                                    </li>
                                                    <li id="containersettings12"
                                                        [ngClass]="(isActiveTabContainer==true)?'active':''"
                                                        *ngIf="Current_SubDomain_Tenant != SubDomain_Tenant_Internal">
                                                        <a #panal12 data-toggle="tab"
                                                            [attr.aria-expanded]="isActiveTabContainer"
                                                            [attr.tabindex]="0" data-target="#containersettings"
                                                            style="cursor: pointer;"
                                                            (keyup.enter)="getSettings('Container',true,$event)"
                                                            (click)="getSettings('Container',true,$event)">
                                                            <i class="glyphicon glyphicon-check"></i><span
                                                                class="hidden-mobile hidden-tablet"
                                                                style="padding-left: 3px">Container</span>
                                                        </a>
                                                    </li>

                                                </ul>
                                            </div>
                                            <div class="tab-content">
                                                <div class="tab-pane fade margin-configuration tab-content-scroll"
                                                    [ngClass]="(isActiveTabApplication==true)?'active in':''"
                                                    id="applicationsettings">
                                                    <form #applicationsettingsform="ngForm"
                                                        *ngIf="Current_SubDomain_Tenant != SubDomain_Tenant_Internal">
                                                        <div class="panel panel-default">
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Date & Time Format</h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal"
                                                                style="min-height: 200px !important;">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <div class="detailtext">Application
                                                                                    level date and time format.</div>
                                                                            </fieldset>
                                                                        </div>

                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Date Format<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <p-dropdown id="dateformatsetting"
                                                                                    name="dateformatsetting"
                                                                                    [options]="dateFormatList" required
                                                                                    [(ngModel)]="Settings.DateFormat"
                                                                                    #dateformatsetting="ngModel" appendTo="body">

                                                                                </p-dropdown>
                                                                                <div class="detailtext">Application
                                                                                    level date format used in all
                                                                                    screen/reports for Application.
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Time Format<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <p-dropdown id="timeformatsetting"
                                                                                    name="timeformatsetting"
                                                                                    [options]="timeFormatList" required
                                                                                    [(ngModel)]="Settings.TimeFormat"
                                                                                    #timeformatsetting="ngModel" appendTo="body">

                                                                                </p-dropdown>
                                                                                <div class="detailtext">Application
                                                                                    level time format used in all
                                                                                    screen/reports for Application.
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Document Size</h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal"
                                                                style="min-height: 90px !important;">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <div class="detailtext"></div>
                                                                            </fieldset>
                                                                        </div>

                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Document Size (MB)<span
                                                                                    class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset class="input">
                                                                                <input style="width: auto;"
                                                                                    id="maxdocumentsizesetting"
                                                                                    name="maxdocumentsizesetting"
                                                                                    type="text"
                                                                                    placeholder="Document size"
                                                                                    [(ngModel)]="Settings.MaxDocumentSize"
                                                                                    required
                                                                                    #maxdocumentsizesetting="ngModel">
                                                                                <div class="detailtext">Application
                                                                                    level maximum allowed pdf document
                                                                                    size.</div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Two-Factor Authentication</h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal"
                                                                style="min-height: 90px !important;">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <div class="detailtext"></div>
                                                                            </fieldset>
                                                                        </div>

                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Two-Factor Authentication <span
                                                                                    class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-4">
                                                                            <fieldset>
                                                                                <!-- <p-dropdown id="twoFactAuth" name="twoFactAuth" [options]="twoFactList" required [(ngModel)]="Settings.TwoFactAuth" #twofactAuthsetting="ngModel">

                                                                                </p-dropdown> -->
                                                                                <p-selectButton id="twoFactAuth"
                                                                                    name="twoFactAuth"
                                                                                    [options]="twoFactList" required
                                                                                    [(ngModel)]="Settings.TwoFactAuth"
                                                                                    #twofactAuthsetting="ngModel">
                                                                                </p-selectButton>
                                                                                <div class="detailtext">Application
                                                                                    level two factor Authentication for
                                                                                    external users.</div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Tenant Logo</h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal"
                                                                style="min-height: 90px !important;">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <div class="detailtext"></div>
                                                                            </fieldset>
                                                                        </div>

                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Tenant Logo
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <!-- <input id="maxdocumentsizesetting" name="maxdocumentsizesetting" type="text"
                                                                                            placeholder="Document size" [(ngModel)]="Settings.MaxDocumentSize" required #maxdocumentsizesetting="ngModel"> -->
                                                                                <input #singleFileUploadLogo type="file"
                                                                                    class="form-control"
                                                                                    (change)="onChangeUpload(singleFileUploadLogo.files)"
                                                                                    accept="image/png,image/jpg,image/jpeg">


                                                                                <div class="detailtext">Application
                                                                                    level Tenant Logo. Only image file
                                                                                    with maximum 300kb of size is
                                                                                    allowed.Image dimension should be 1
                                                                                    height * 3 width</div>

                                                                                <div class="detailtext col-sm-8"><img
                                                                                        [src]="Settings.TenantLogo"
                                                                                        height="50" width="150"
                                                                                        *ngIf="Settings.TenantLogo">
                                                                                </div>

                                                                                <button
                                                                                    style="margin-top: 15px !important"
                                                                                    (click)="RemoveLogo()"
                                                                                    *ngIf="Settings.TenantLogo"
                                                                                    icon="fa fa-times" pbutton=""
                                                                                    type="button"
                                                                                    class="p-button p-component p-button-icon-only">
                                                                                    <span aria-hidden="true"
                                                                                        class="p-button-icon-left p-clickable fa fa-times">
                                                                                    </span><span
                                                                                        class="p-button-text p-clickable"></span>
                                                                                </button>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="widget-footer">
                                                            <button type="button" class="btn btn-primary custom-button"
                                                                [disabled]="!applicationsettingsform.form.valid"
                                                                (click)="setSettings('Application',applicationsettingsform)">
                                                                Update
                                                            </button>
                                                        </div>
                                                    </form>

                                                    <form #applicationsettingsform="ngForm"
                                                        *ngIf="Current_SubDomain_Tenant == SubDomain_Tenant_Internal">
                                                        <div class="panel panel-default">
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Date & Time Format</h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal"
                                                                style="min-height: 200px !important;">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <div class="detailtext">Application
                                                                                    level date and time format.</div>
                                                                            </fieldset>
                                                                        </div>

                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Date Format<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <p-dropdown id="dateformatsetting"
                                                                                    name="dateformatsetting"
                                                                                    [options]="dateFormatList" required
                                                                                    [(ngModel)]="Settings.DateFormat"
                                                                                    #dateformatsetting="ngModel" appendTo="body">

                                                                                </p-dropdown>
                                                                                <div class="detailtext">Application
                                                                                    level date format used in all
                                                                                    screen/reports for Application.
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Time Format<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <p-dropdown id="timeformatsetting"
                                                                                    name="timeformatsetting"
                                                                                    [options]="timeFormatList" required
                                                                                    [(ngModel)]="Settings.TimeFormat"
                                                                                    #timeformatsetting="ngModel" appendTo="body">

                                                                                </p-dropdown>
                                                                                <div class="detailtext">Application
                                                                                    level time format used in all
                                                                                    screen/reports for Application.
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Report Data Refresh Interval
                                                                </h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <div class="detailtext">Reports gather
                                                                                    data from Asset and Jobs. Here you
                                                                                    can define time interval after which
                                                                                    system refresh data for reports.
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Finance<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>

                                                                                <p-selectButton
                                                                                    id="assetRptintervalsetting"
                                                                                    name="assetRptintervalsetting"
                                                                                    [options]="intervalList" required
                                                                                    [(ngModel)]="Settings.IntervalAssetReport"
                                                                                    #assetRptintervalsetting="ngModel">
                                                                                </p-selectButton>

                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Job<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>

                                                                                <p-selectButton
                                                                                    id="jobRptintervalsetting"
                                                                                    name="jobRptintervalsetting"
                                                                                    [options]="intervalList" required
                                                                                    [(ngModel)]="Settings.IntervalJobReport"
                                                                                    #jobRptintervalsetting="ngModel">
                                                                                </p-selectButton>

                                                                            </fieldset>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="widget-footer">
                                                            <button type="button" class="btn btn-primary custom-button"
                                                                [disabled]="!applicationsettingsform.form.dirty"
                                                                (click)="setSettings('Application',applicationsettingsform)">
                                                                Update
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>

                                                <div id="usersettings"
                                                    class="tab-pane fade margin-configuration tab-content-scroll"
                                                    [ngClass]="(isActiveTabUser==true)?'active in':''">

                                                    <form #usersettingsform="ngForm">
                                                        <div class="panel panel-default">
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Forget Password Email Template
                                                                </h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <div class="detailtext">When user select
                                                                                    forgot password option, system
                                                                                    generated email is sent to the user.
                                                                                    Following data is used in email
                                                                                    generation and same appear in email
                                                                                    client of user, who has requested
                                                                                    password.</div>
                                                                            </fieldset>
                                                                        </div>

                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                From<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="frgtemailfrom"
                                                                                        type="text" name="frgtemailfrom"
                                                                                        placeholder="Email"
                                                                                        [(ngModel)]="Settings.ForgetFrom"
                                                                                        required
                                                                                        #frgtemailfrom="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">Sender email
                                                                                    address for forgot password email.
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Alias<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="frgtemailalias"
                                                                                        type="text"
                                                                                        name="frgtemailalias"
                                                                                        placeholder="Email Alias"
                                                                                        [(ngModel)]="Settings.ForgetAlias"
                                                                                        required
                                                                                        #frgtemailalias="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">Alias or name
                                                                                    for forgot password email..</div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Subject<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="frgtemailsubject"
                                                                                        type="text"
                                                                                        name="frgtemailsubject"
                                                                                        placeholder="Email Subject"
                                                                                        [(ngModel)]="Settings.ForgetSubject"
                                                                                        required
                                                                                        #frgtemailsubject="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">Subject for
                                                                                    forgot password email.</div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Body<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="textarea">
                                                                                    <p-editor id="frgtemailbody"
                                                                                        name="frgtemailbody"
                                                                                        [(ngModel)]="Settings.ForgetBody"
                                                                                        [style]="{'height':'150px'}"
                                                                                        required #frgtemailbody>
                                                                                    </p-editor>
                                                                                </label>
                                                                                <div class="detailtext">
                                                                                    Contents of email for forgot
                                                                                    password user. Standard HTML tags
                                                                                    alongwith special
                                                                                    {{ '{' }}tags{{ '}' }} from user
                                                                                    screen.
                                                                                    Tokens are:
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('frgtemailbody', _sharedService.Token_FirstName)">{{_sharedService.Token_FirstName
                                                                                        }}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('frgtemailbody', _sharedService.Token_LastName)">{{_sharedService.Token_LastName
                                                                                        }}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('frgtemailbody', _sharedService.Token_Password)">{{_sharedService.Token_Password}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('frgtemailbody', _sharedService.Token_Email)">{{_sharedService.Token_Email}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('frgtemailbody', _sharedService.Token_UserName)">{{_sharedService.Token_UserName}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('frgtemailbody', _sharedService.Token_TenantURL)">{{_sharedService.Token_TenantURL}}</label>
                                                                                    .
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="panel panel-default">
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Send Shared Key Email Template
                                                                </h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <div class="detailtext">When user send
                                                                                    shared key option, system generated
                                                                                    email is sent to the user. Following
                                                                                    data is used in email generation and
                                                                                    same appear in email client of user,
                                                                                    who has requested password.</div>
                                                                            </fieldset>
                                                                        </div>

                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                From<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="shardKeyemailfrom"
                                                                                        type="text"
                                                                                        name="shardKeyemailfrom"
                                                                                        placeholder="Email"
                                                                                        [(ngModel)]="Settings.ShardKeyFrom"
                                                                                        required
                                                                                        #shardkeyemailfrom="ngModel">
                                                                                </label>
                                                                                <div class="detailtext">Sender email
                                                                                    address for send shared key email.
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Alias<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="shardKeyemailalias"
                                                                                        type="text"
                                                                                        name="shardKeyemailalias"
                                                                                        placeholder="Email Alias"
                                                                                        [(ngModel)]="Settings.ShardKeyAlias"
                                                                                        required
                                                                                        #shardKeyemailalias="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">Alias or name
                                                                                    for send shared key email..</div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Subject<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="shardKeyemailsubject"
                                                                                        type="text"
                                                                                        name="shardKeyemailsubject"
                                                                                        placeholder="Email Subject"
                                                                                        [(ngModel)]="Settings.ShardKeySubject"
                                                                                        required
                                                                                        #shardKeyemailsubject="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">Subject for send
                                                                                    shared key email.</div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Body<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="textarea">
                                                                                    <p-editor id="shardKeyemailbody"
                                                                                        name="shardKeyemailbody"
                                                                                        [(ngModel)]="Settings.ShardKeyBody"
                                                                                        [style]="{'height':'150px'}"
                                                                                        required #shardKeyemailbody>
                                                                                    </p-editor>
                                                                                </label>
                                                                                <div class="detailtext">
                                                                                    Contents of email for send shard key
                                                                                    user. Standard HTML tags alongwith
                                                                                    special {{ '{' }}tags{{ '}' }} from
                                                                                    user screen.
                                                                                    Tokens are:
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('shardKeyemailbody', _sharedService.Token_FirstName)">{{_sharedService.Token_FirstName
                                                                                        }}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('shardKeyemailbody', _sharedService.Token_LastName)">{{_sharedService.Token_LastName
                                                                                        }}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('shardKeyemailbody', _sharedService.Token_SharedKey)">{{_sharedService.Token_SharedKey}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('shardKeyemailbody', _sharedService.Token_Email)">{{_sharedService.Token_Email}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('shardKeyemailbody', _sharedService.Token_UserName)">{{_sharedService.Token_UserName}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('shardKeyemailbody', _sharedService.Token_TenantURL)">{{_sharedService.Token_TenantURL}}</label>
                                                                                    .
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div class="panel panel-default">
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">New User Email Template</h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <div class="detailtext">When new user is
                                                                                    created, a system generated email is
                                                                                    sent to new user with login and
                                                                                    password information. Following data
                                                                                    is used in email.</div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                From<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="newusremailfrom"
                                                                                        type="text"
                                                                                        name="newusremailfrom"
                                                                                        placeholder="Email"
                                                                                        [(ngModel)]="Settings.NewFrom"
                                                                                        required
                                                                                        #newusremailfrom="ngModel">
                                                                                </label>
                                                                                <div class="detailtext">Sender email
                                                                                    address for new user email.</div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Alias<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="newusremailalias"
                                                                                        type="text"
                                                                                        name="newusremailalias"
                                                                                        placeholder="Email Alias"
                                                                                        [(ngModel)]="Settings.NewAlias"
                                                                                        required
                                                                                        #newusremailalias="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">Alias or name
                                                                                    for new user email.</div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Subject<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="newusremailsubject"
                                                                                        type="text"
                                                                                        name="newusremailsubject"
                                                                                        placeholder="Email Subject"
                                                                                        [(ngModel)]="Settings.NewSubject"
                                                                                        required
                                                                                        #newusremailsubject="ngModel">
                                                                                </label>
                                                                                <div class="detailtext">Subject for new
                                                                                    user email.</div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Body<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="textarea">
                                                                                    <p-editor id="newusremailbody"
                                                                                        name="newusremailbody"
                                                                                        [(ngModel)]="Settings.NewBody"
                                                                                        required
                                                                                        [style]="{'height':'150px'}"
                                                                                        #newusremailbody>
                                                                                    </p-editor>
                                                                                </label>
                                                                                <div class="detailtext">
                                                                                    Contents of email for new user.
                                                                                    Standard HTML tags alongwith special
                                                                                    {{ '{' }}tags{{ '}' }} from user
                                                                                    screen.
                                                                                    Tokens are:
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('newusremailbody', _sharedService.Token_FirstName)">{{_sharedService.Token_FirstName
                                                                                        }}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('newusremailbody', _sharedService.Token_LastName)">{{_sharedService.Token_LastName
                                                                                        }}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('newusremailbody', _sharedService.Token_Password)">{{_sharedService.Token_Password}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('newusremailbody', _sharedService.Token_SharedKey)">{{_sharedService.Token_SharedKey}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('newusremailbody', _sharedService.Token_Email)">{{_sharedService.Token_Email}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('newusremailbody', _sharedService.Token_UserName)">{{_sharedService.Token_UserName}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('newusremailbody', _sharedService.Token_TenantURL)">{{_sharedService.Token_TenantURL}}</label>
                                                                                    .
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="widget-footer">
                                                            <button type="button" class="btn btn-primary custom-button"
                                                                [disabled]="!usersettingsform.form.valid"
                                                                (click)="setSettings('User',usersettingsform)">
                                                                Update
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>

                                                <div id="inboxsettings" class="tab-pane fade margin-configuration"
                                                    [ngClass]="(isActiveTabInbox==true)?'active in':''">
                                                    <form #inboxsettingsform="ngForm">
                                                        <div class="panel panel-default" style="height: 70vh;">
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Communication Inbox Alert Email
                                                                    Template</h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <div class="detailtext">For
                                                                                    communication Inbox email alert from
                                                                                    application, system generate email
                                                                                    is sent to user. Following data is
                                                                                    used:</div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Alias<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="inboxemailalias"
                                                                                        type="text"
                                                                                        name="inboxemailalias"
                                                                                        placeholder="Email Alias"
                                                                                        [(ngModel)]="Settings.InboxAlias"
                                                                                        required
                                                                                        #inboxemailalias="ngModel">
                                                                                </label>
                                                                                <div class="detailtext">Alias or name
                                                                                    for notification email.</div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Body<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="textarea">
                                                                                    <p-editor id="inboxemailbody"
                                                                                        name="inboxemailbody"
                                                                                        [(ngModel)]="Settings.InboxBody"
                                                                                        [style]="{'height':'150px'}"
                                                                                        required #inboxemailbody>
                                                                                    </p-editor>
                                                                                </label>
                                                                                <div class="detailtext">
                                                                                    Notification contents of email for
                                                                                    user. Standard HTML tags alongwith
                                                                                    {{ '{' }}tags{{ '}' }} from
                                                                                    Communication Inbox screen.
                                                                                    Tokens are:
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('inboxemailbody', _sharedService.Token_RequestedBy)">{{_sharedService.Token_RequestedBy
                                                                                        }}
                                                                                    </label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('inboxemailbody', _sharedService.Token_AssignedTo)">{{_sharedService.Token_AssignedTo
                                                                                        }}
                                                                                    </label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('inboxemailbody', _sharedService.Token_State)">{{_sharedService.Token_State}}
                                                                                    </label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('inboxemailbody', _sharedService.Token_Type)">{{_sharedService.Token_Type}}
                                                                                    </label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('inboxemailbody', _sharedService.Token_Priority)">{{_sharedService.Token_Priority}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('inboxemailbody', _sharedService.Token_Description)">{{_sharedService.Token_Description}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('inboxemailbody', _sharedService.Token_Tenant)">{{_sharedService.Token_Tenant}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('inboxemailbody', _sharedService.Token_TenantURL)">{{_sharedService.Token_TenantURL}}</label>
                                                                                    .
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="widget-footer">
                                                            <button type="button" class="btn btn-primary custom-button"
                                                                [disabled]="!inboxsettingsform.form.valid"
                                                                (click)="setSettings('Inbox',inboxsettingsform)">
                                                                Update
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>

                                                <div class="tab-pane fade margin-configuration tab-content-scroll"
                                                    id="dashboardsettings"
                                                    [ngClass]="(isActiveTabDashboard==true)?'active in':''">
                                                    <form #dashboardsettingsform="ngForm">
                                                        <div class="panel panel-default">
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Data Refresh Interval</h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <div class="detailtext">Dashboard views
                                                                                    gather data from Records, Status,
                                                                                    Asset Storage Stats and Asset
                                                                                    document counts. Here you can define
                                                                                    time interval after which system
                                                                                    refresh data for dashbaord views.
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Record<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <!--<p-dropdown id="recordintervalsetting" name="recordintervalsetting" [options]="intervalList" required [(ngModel)]="Settings." #recordintervalsetting="ngModel">

                                                                                </p-dropdown>-->
                                                                                <p-selectButton
                                                                                    id="recordintervalsetting"
                                                                                    name="recordintervalsetting"
                                                                                    [options]="intervalList" required
                                                                                    [(ngModel)]="Settings.IntervalRecord"
                                                                                    #recordintervalsetting="ngModel">
                                                                                </p-selectButton>
                                                                                <!--<div class="detailtext">Dashboard views gather data from Records.Here you can define time interval after which system refresh data for dashbaord views. </div>-->
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Status<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <!--<p-dropdown id="statusintervalsetting" name="statusintervalsetting" [options]="intervalList" required [(ngModel)]="Settings." #statusintervalsetting="ngModel">

                                                                                </p-dropdown>-->
                                                                                <p-selectButton
                                                                                    id="statusintervalsetting"
                                                                                    name="statusintervalsetting"
                                                                                    [options]="intervalList" required
                                                                                    [(ngModel)]="Settings.IntervalStatus"
                                                                                    #statusintervalsetting="ngModel">
                                                                                </p-selectButton>
                                                                                <!--<div class="detailtext">Dashboard views gather data from Status.Here you can define time interval after which system refresh data for dashbaord views. .</div>-->
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Stats<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <!--<p-dropdown id="statsintervalsetting" name="statsintervalsetting" [options]="intervalList" required [(ngModel)]="Settings." #statsintervalsetting="ngModel">

                                                                                </p-dropdown>-->
                                                                                <p-selectButton
                                                                                    id="statsintervalsetting"
                                                                                    name="statsintervalsetting"
                                                                                    [options]="intervalList" required
                                                                                    [(ngModel)]="Settings.IntervalStats"
                                                                                    #statsintervalsetting="ngModel">
                                                                                </p-selectButton>
                                                                                <!--<div class="detailtext">Dashboard views gather data from Asset. Here you can define time interval after which system refresh data for dashbaord views. </div>-->
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Asset Count<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <!--<p-dropdown id="assetsintervalsetting" name="assetsintervalsetting" [options]="intervalList" required [(ngModel)]="Settings." #assetsintervalsetting="ngModel">

                                                                                </p-dropdown>-->
                                                                                <p-selectButton
                                                                                    id="assetsintervalsetting"
                                                                                    name="assetsintervalsetting"
                                                                                    [options]="intervalList" required
                                                                                    [(ngModel)]="Settings.IntervalAssetCount"
                                                                                    #assetsintervalsetting="ngModel">
                                                                                </p-selectButton>
                                                                                <!--<div class="detailtext">Dashboard views gather data from Asset Storage Stats and Asset document counts. Here you can define time interval after which system refresh data for dashbaord views.</div>-->
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Inbox<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <p-selectButton
                                                                                    id="Inboxsintervalsetting"
                                                                                    name="Inboxsintervalsetting"
                                                                                    [options]="intervalList" required
                                                                                    [(ngModel)]="Settings.IntervalInbox"
                                                                                    #Inboxsintervalsetting="ngModel">
                                                                                </p-selectButton>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Container Status<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <p-selectButton
                                                                                    id="transitionintervalsetting"
                                                                                    name="transitionintervalsetting"
                                                                                    [options]="intervalList" required
                                                                                    [(ngModel)]="Settings.ContainerStatus"
                                                                                    #transitionintervalsetting="ngModel">
                                                                                </p-selectButton>
                                                                                <!--<div class="detailtext">Dashboard views gather data from Asset Storage Stats and Asset document counts. Here you can define time interval after which system refresh data for dashbaord views.</div>-->
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="widget-footer">
                                                            <button type="button" class="btn btn-primary custom-button"
                                                                [disabled]="!dashboardsettingsform.form.valid"
                                                                (click)="setSettings('Dashboard',dashboardsettingsform)">
                                                                Update
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>

                                                <div class="tab-pane fade margin-configuration tab-content-scroll"
                                                    id="autosearchsettings"
                                                    [ngClass]="(isActiveTabAutoSearch==true)?'active in':''">
                                                    <form #autosearchsettingsform="ngForm">
                                                        <div class="panel panel-default">
                                                            <!-- style="height: 60vh;" -->
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Auto Search Interval</h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal-alt">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <div class="detailtext">System
                                                                                    automatically search and attach
                                                                                    Records module data with Status
                                                                                    module views based on the search
                                                                                    criteria given for each view.</div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Interval<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <!--<p-dropdown id="autosearchintervalsetting" name="autosearchintervalsetting" [options]="intervalList" required [(ngModel)]="Settings." #autosearchintervalsetting="ngModel">

                                                                                </p-dropdown>-->
                                                                                <p-selectButton
                                                                                    id="autosearchintervalsetting"
                                                                                    name="autosearchintervalsetting"
                                                                                    [options]="intervalList" required
                                                                                    [(ngModel)]="Settings.IntervalAutoSearch"
                                                                                    #autosearchintervalsetting="ngModel">
                                                                                </p-selectButton>
                                                                                <div class="detailtext">Interval
                                                                                    specifies time interval for system
                                                                                    to perfom this task.</div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                        <div class="form-group col-sm-1">
                                                                            <label class="label"
                                                                                style="padding-top:7px;">
                                                                                Last Run
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-4">
                                                                            <label class="label"
                                                                                style="padding-top:7px;">
                                                                                {{Settings.AutoSearchLastRun}}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div class="panel panel-default" *ngIf="isSysAdmin">
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Auto Search Mandatory Default
                                                                    Conditions</h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal"
                                                                style="min-height: auto !important;">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <div class="detailtext">Batch Auto
                                                                                    Search conditions are criteria to
                                                                                    Select or Not Select rows from
                                                                                    Status module views to search and
                                                                                    attach data from Records module.
                                                                                    This is mostly practiced based on
                                                                                    DFPStatus.</div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Default Selected<span
                                                                                    class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="autosearchselected"
                                                                                        type="text"
                                                                                        name="autosearchselected"
                                                                                        placeholder="Selected Condition"
                                                                                        [(ngModel)]="Settings.DefaultSelected"
                                                                                        required
                                                                                        #autosearchselected="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">
                                                                                    Syntax :
                                                                                    Fieldname|=|value|;Fieldname|=|value|Operator;Fieldname|=|value|Operator
                                                                                </div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Default Not Selected<span
                                                                                    class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="autosearchnotselected"
                                                                                        type="text"
                                                                                        name="autosearchnotselected"
                                                                                        placeholder="Not Selected Condition"
                                                                                        [(ngModel)]="Settings.DefaultNotSelected"
                                                                                        required
                                                                                        #autosearchnotselected="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">
                                                                                    Syntax :
                                                                                    Fieldname|=|value|;Fieldname|=|value|Operator;Fieldname|=|value|Operator
                                                                                </div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="widget-footer">
                                                            <button type="button" class="btn btn-primary custom-button"
                                                                [disabled]="!autosearchsettingsform.form.valid"
                                                                (click)="setSettings('AutoSearch',autosearchsettingsform)">
                                                                Update
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>

                                                <div class="tab-pane fade margin-configuration tab-content-scroll"
                                                id="gapanalyzersettingsform"
                                                [ngClass]="(isActiveTabGapAnalyzer==true)?'active in':''">
                                                <form #gapanalyzersettingsform="ngForm">
                                                    <div class="panel panel-default">
                                                        <!-- style="height: 60vh;" -->
                                                        <div class="panel-heading panalheading-padding">
                                                            <h3 class="panel-title">Gap Analyser</h3>
                                                        </div>
                                                        <div class="panel-body margin-configuration-panal-alt">
                                                            <div class="col-sm-12">
                                                                <div class="row">
                                                                    <div class="form-group col-sm-2">
                                                                        <label class="label">
                                                                            Interval<span class="text-danger">
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-group col-sm-5">
                                                                        <fieldset>
                                                                            <p-selectButton
                                                                                id="gapanalyzerintervalsetting"
                                                                                name="gapanalyzerintervalsetting"
                                                                                [options]="intervalList" required
                                                                                [(ngModel)]="Settings.IntervalGapAnalyzer"
                                                                                #gapanalyzerintervalsetting="ngModel">
                                                                            </p-selectButton>
                                                                            <div class="detailtext">Interval
                                                                                specifies time interval for system
                                                                                to perfom this task.</div>
                                                                        </fieldset>
                                                                        <!--<br />
                                                                        <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                        <br />-->
                                                                    </div>
                                                                    <div class="form-group col-sm-1">
                                                                        <label class="label"
                                                                            style="padding-top:7px;">
                                                                            Last Run
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-group col-sm-4">
                                                                        <label class="label"
                                                                            style="padding-top:7px;">
                                                                            {{Settings.GapAnalyzerLastRun}}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div class="panel panel-default" *ngIf="isSysAdmin">
                                                        <div class="panel-heading panalheading-padding">
                                                            <h3 class="panel-title">Gap Analyser Quality Status</h3>
                                                        </div>
                                                        <div class="panel-body margin-configuration-panal"
                                                            style="min-height: auto !important;">
                                                            <div class="col-sm-12">
                                                                <div class="row">
                                                                    <div class="form-group col-sm-2">
                                                                        <label class="label">
                                                                            Gap Analyser Quality Status<span
                                                                                class="text-danger">
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-group col-sm-5">
                                                                        <fieldset>
                                                                            <label class="input">
                                                                                <input id="gapanalyzerqualitystatus"
                                                                                    type="text"
                                                                                    name="gapanalyzerqualitystatus"
                                                                                    placeholder="Selected Gap Analyser Quality Status"
                                                                                    [(ngModel)]="Settings.GapAnalyzerQualityStatus"
                                                                                    required
                                                                                    #gapanalyzerqualitystatus="ngModel">

                                                                            </label>
                                                                        </fieldset>
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="widget-footer">
                                                        <button type="button" class="btn btn-primary custom-button"
                                                            [disabled]="!gapanalyzersettingsform.form.valid"
                                                            (click)="setSettings('GapAnalyzer',gapanalyzersettingsform)">
                                                            Update
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>



                                                <div class="tab-pane fade margin-configuration" id="jobssettings"
                                                    [ngClass]="(isActiveTabJobs==true)?'active in':''">
                                                    <div class="tab-content-scroll">
                                                        <form #jobssettingsform="ngForm">
                                                            <div class="panel panel-default">
                                                                <div class="panel-heading panalheading-padding">
                                                                    <h3 class="panel-title">New Job Alert Email Template
                                                                    </h3>
                                                                </div>
                                                                <div class="panel-body margin-configuration-panal">
                                                                    <div class="col-sm-12">
                                                                        <div class="row">
                                                                            <div class="form-group col-sm-2">
                                                                            </div>
                                                                            <div class="form-group col-sm-5">
                                                                                <fieldset>
                                                                                    <div class="detailtext">For New job
                                                                                        related alert from application,
                                                                                        system generated email is sent
                                                                                        as alert to user. Following data
                                                                                        is used:</div>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="form-group col-sm-2">
                                                                                <label class="label">
                                                                                    From<span class="text-danger">
                                                                                        *
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-group col-sm-5">
                                                                                <fieldset>
                                                                                    <label class="input">
                                                                                        <input id="newjobemailfrom"
                                                                                            type="text"
                                                                                            name="newjobemailfrom"
                                                                                            placeholder="Email"
                                                                                            [(ngModel)]="Settings.JobNewFrom"
                                                                                            required
                                                                                            #newjobemailfrom="ngModel">
                                                                                    </label>
                                                                                    <div class="detailtext">Sender email
                                                                                        address for new job alert email.
                                                                                    </div>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <br />
                                                                        <div class="row">
                                                                            <div class="form-group col-sm-2">
                                                                                <label class="label">
                                                                                    Alias<span class="text-danger">
                                                                                        *
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-group col-sm-5">
                                                                                <fieldset>
                                                                                    <label class="input">
                                                                                        <input id="newjobemailalias"
                                                                                            type="text"
                                                                                            name="newjobemailalias"
                                                                                            placeholder="Email Alias"
                                                                                            [(ngModel)]="Settings.JobNewAlias"
                                                                                            required
                                                                                            #newjobemailalias="ngModel">

                                                                                    </label>
                                                                                    <div class="detailtext">Alias or
                                                                                        name for new job alert email.
                                                                                    </div>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <br />
                                                                        <div class="row">
                                                                            <div class="form-group col-sm-2">
                                                                                <label class="label">
                                                                                    To
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-group col-sm-5">
                                                                                <fieldset>
                                                                                    <label class="input">
                                                                                        <input id="newjobemailto"
                                                                                            type="text"
                                                                                            name="newjobemailto"
                                                                                            placeholder="Email"
                                                                                            [(ngModel)]="Settings.JobNewTo"
                                                                                            #newjobemailto>

                                                                                    </label>
                                                                                    <div class="detailtext">Persons to
                                                                                        keep in loop for new job alert
                                                                                        emails.Tokens <label
                                                                                            class="token"
                                                                                            (click)="AssignToken('newjobemailto', _sharedService.Token_AssignedTo)">{{_sharedService.Token_AssignedTo}}
                                                                                        </label> and <label
                                                                                            class="token"
                                                                                            (click)="AssignToken('newjobemailto', _sharedService.Token_CreatedBy)">{{_sharedService.Token_CreatedBy}}
                                                                                        </label>, can be used separated
                                                                                        by semicolon(;).</div>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <br />

                                                                        <div class="row">
                                                                            <div class="form-group col-sm-2">
                                                                                <label class="label">
                                                                                    Subject<span class="text-danger">
                                                                                        *
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-group col-sm-5">
                                                                                <fieldset>
                                                                                    <label class="input">
                                                                                        <input id="newjobemailsbjt"
                                                                                            type="text"
                                                                                            name="newjobemailsbjt"
                                                                                            placeholder="Email Subject"
                                                                                            [(ngModel)]="Settings.JobNewSubject"
                                                                                            required #newjobemailsbjt>

                                                                                    </label>
                                                                                    <div class="detailtext">: New Job
                                                                                        alert subject of email for user.
                                                                                    </div>
                                                                                    Tokens are:
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('newjobemailsbjt', _sharedService.Token_jobid)">{{_sharedService.Token_jobid
                                                                                        }}
                                                                                    </label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('newjobemailsbjt', _sharedService.Token_tenantname)">{{_sharedService.Token_tenantname
                                                                                        }}
                                                                                    </label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('newjobemailsbjt', _sharedService.Token_ASN)">{{_sharedService.Token_ASN}}
                                                                                    </label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('newjobemailsbjt', _sharedService.Token_Priority)">{{_sharedService.Token_Priority}}
                                                                                    </label>.
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <br />
                                                                        <div class="row">
                                                                            <div class="form-group col-sm-2">
                                                                                <label class="label">
                                                                                    Body<span class="text-danger">
                                                                                        *
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-group col-sm-5">
                                                                                <fieldset>
                                                                                    <label class="textarea">
                                                                                        <p-editor id="newjobemailbody"
                                                                                            name="newjobemailbody"
                                                                                            [(ngModel)]="Settings.JobNewBody"
                                                                                            [style]="{'height':'150px'}"
                                                                                            required #newjobemailbody>
                                                                                        </p-editor>
                                                                                    </label>
                                                                                    <div class="detailtext">
                                                                                        New Job alert contents of email
                                                                                        for user. Standard HTML tags
                                                                                        alongwith {{ '{' }}tags{{ '}' }}
                                                                                        from user screen.
                                                                                        Tokens are:
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('newjobemailbody', _sharedService.Token_jobid)">{{_sharedService.Token_jobid
                                                                                            }}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('newjobemailbody', _sharedService.Token_tenantname)">{{_sharedService.Token_tenantname
                                                                                            }}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('newjobemailbody', _sharedService.Token_ASN)">{{_sharedService.Token_ASN}}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('newjobemailbody', _sharedService.Token_Priority)">{{_sharedService.Token_Priority}}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('newjobemailbody', _sharedService.Token_AssignedTo)">{{_sharedService.Token_AssignedTo}}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('newjobemailbody', _sharedService.Token_Conversation)">{{_sharedService.Token_Conversation}}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('newjobemailbody', _sharedService.Token_Status)">{{_sharedService.Token_Status}}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('newjobemailbody', _sharedService.Token_TenantURL)">{{_sharedService.Token_TenantURL}}</label>
                                                                                        .
                                                                                    </div>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div class="panel panel-default">
                                                                <div class="panel-heading panalheading-padding">
                                                                    <h3 class="panel-title">Complete Job Alert Email
                                                                        Template</h3>
                                                                </div>
                                                                <div class="panel-body margin-configuration-panal">
                                                                    <div class="col-sm-12">
                                                                        <div class="row">
                                                                            <div class="form-group col-sm-2">
                                                                            </div>
                                                                            <div class="form-group col-sm-5">
                                                                                <fieldset>
                                                                                    <div class="detailtext">For
                                                                                        completed job related email from
                                                                                        application, system generated
                                                                                        email is sent as alert to user.
                                                                                        Following data is used:</div>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="form-group col-sm-2">
                                                                                <label class="label">
                                                                                    From<span class="text-danger">
                                                                                        *
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-group col-sm-5">
                                                                                <fieldset>
                                                                                    <label class="input">
                                                                                        <input id="cmpltjobemailfrom"
                                                                                            type="text"
                                                                                            name="cmpltjobemailfrom"
                                                                                            placeholder="Email"
                                                                                            [(ngModel)]="Settings.JobCompleteFrom"
                                                                                            required
                                                                                            #cmpltjobemailfrom="ngModel">
                                                                                    </label>
                                                                                    <div class="detailtext">Sender email
                                                                                        address for completed job alert
                                                                                        email.</div>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <br />
                                                                        <div class="row">
                                                                            <div class="form-group col-sm-2">
                                                                                <label class="label">
                                                                                    Alias<span class="text-danger">
                                                                                        *
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-group col-sm-5">
                                                                                <fieldset>
                                                                                    <label class="input">
                                                                                        <input id="cmpltjobemailalias"
                                                                                            type="text"
                                                                                            name="cmpltjobemailalias"
                                                                                            placeholder="Email Alias"
                                                                                            [(ngModel)]="Settings.JobCompleteAlias"
                                                                                            required
                                                                                            #cmpltjobemailalias="ngModel">
                                                                                    </label>
                                                                                    <div class="detailtext">Alias or
                                                                                        name for completed job alert
                                                                                        email.</div>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <br />
                                                                        <div class="row">
                                                                            <div class="form-group col-sm-2">
                                                                                <label class="label">
                                                                                    To
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-group col-sm-5">
                                                                                <fieldset>
                                                                                    <label class="input">
                                                                                        <input id="cmpltjobemailto"
                                                                                            type="text"
                                                                                            name="cmpltjobemailto"
                                                                                            placeholder="Email"
                                                                                            [(ngModel)]="Settings.JobCompleteTo"
                                                                                            #cmpltjobemailto>
                                                                                    </label>
                                                                                    <div class="detailtext">Persons to
                                                                                        keep in loop for completed job
                                                                                        alert emails.Tokens <label
                                                                                            class="token"
                                                                                            (click)="AssignToken('cmpltjobemailto', _sharedService.Token_AssignedTo)">{{_sharedService.Token_AssignedTo}}
                                                                                        </label> and <label
                                                                                            class="token"
                                                                                            (click)="AssignToken('cmpltjobemailto', _sharedService.Token_CreatedBy)">{{_sharedService.Token_CreatedBy}}
                                                                                        </label> can be used separated
                                                                                        by semicolon(;).</div>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <br />

                                                                        <div class="row">
                                                                            <div class="form-group col-sm-2">
                                                                                <label class="label">
                                                                                    Subject<span class="text-danger">
                                                                                        *
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-group col-sm-5">
                                                                                <fieldset>
                                                                                    <label class="input">
                                                                                        <input id="cmpltjobemailsbjt"
                                                                                            type="text"
                                                                                            name="cmpltjobemailsbjt"
                                                                                            placeholder="Email Subject"
                                                                                            [(ngModel)]="Settings.JobCompleteSubject"
                                                                                            required #cmpltjobemailsbjt>
                                                                                    </label>
                                                                                    <div class="detailtext">Completed
                                                                                        job alert subject of email for
                                                                                        user.</div>
                                                                                    Tokens are:
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('cmpltjobemailsbjt', _sharedService.Token_jobid)">{{_sharedService.Token_jobid
                                                                                        }}
                                                                                    </label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('cmpltjobemailsbjt', _sharedService.Token_tenantname)">{{_sharedService.Token_tenantname
                                                                                        }}
                                                                                    </label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('cmpltjobemailsbjt', _sharedService.Token_ASN)">{{_sharedService.Token_ASN}}
                                                                                    </label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('cmpltjobemailsbjt', _sharedService.Token_Priority)">{{_sharedService.Token_Priority}}
                                                                                    </label>.
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <br />
                                                                        <div class="row">
                                                                            <div class="form-group col-sm-2">
                                                                                <label class="label">
                                                                                    Body<span class="text-danger">
                                                                                        *
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-group col-sm-5">
                                                                                <fieldset>
                                                                                    <label class="textarea">
                                                                                        <p-editor id="cmpltjobemailbody"
                                                                                            name="cmpltjobemailbody"
                                                                                            [(ngModel)]="Settings.JobCompleteBody"
                                                                                            [style]="{'height':'150px'}"
                                                                                            required #cmpltjobemailbody>
                                                                                        </p-editor>
                                                                                    </label>
                                                                                    <div class="detailtext">
                                                                                        Completed job alert contents of
                                                                                        email for user. Standard HTML
                                                                                        tags alongwith
                                                                                        {{ '{' }}tags{{ '}' }} from user
                                                                                        screen
                                                                                        Tokens are:
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('cmpltjobemailbody', _sharedService.Token_jobid)">{{_sharedService.Token_jobid
                                                                                            }}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('cmpltjobemailbody', _sharedService.Token_tenantname)">{{_sharedService.Token_tenantname
                                                                                            }}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('cmpltjobemailbody', _sharedService.Token_ASN)">{{_sharedService.Token_ASN}}
                                                                                        </label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('cmpltjobemailbody', _sharedService.Token_Priority)">{{_sharedService.Token_Priority}}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('cmpltjobemailbody', _sharedService.Token_AssignedTo)">{{_sharedService.Token_AssignedTo}}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('cmpltjobemailbody', _sharedService.Token_Conversation)">{{_sharedService.Token_Conversation}}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('cmpltjobemailbody', _sharedService.Token_Status)">{{_sharedService.Token_Status}}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('cmpltjobemailbody', _sharedService.Token_TenantURL)">{{_sharedService.Token_TenantURL}}</label>
                                                                                        .
                                                                                    </div>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div class="panel panel-default">
                                                                <div class="panel-heading panalheading-padding">
                                                                    <h3 class="panel-title">Update Job Alert Email
                                                                        Template</h3>
                                                                </div>
                                                                <div class="panel-body margin-configuration-panal">
                                                                    <div class="col-sm-12">
                                                                        <div class="row">
                                                                            <div class="form-group col-sm-2">
                                                                            </div>
                                                                            <div class="form-group col-sm-5">
                                                                                <fieldset>
                                                                                    <div class="detailtext">For update
                                                                                        job related email from
                                                                                        application, system generated
                                                                                        email is sent as alert to user.
                                                                                        Following data is used:</div>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="form-group col-sm-2">
                                                                                <label class="label">
                                                                                    From<span class="text-danger">
                                                                                        *
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-group col-sm-5">
                                                                                <fieldset>
                                                                                    <label class="input">
                                                                                        <input id="updtjobemailfrom"
                                                                                            type="text"
                                                                                            name="updtjobemailfrom"
                                                                                            placeholder="Email"
                                                                                            [(ngModel)]="Settings.JobUpdateFrom"
                                                                                            required
                                                                                            #updtjobemailfrom="ngModel">
                                                                                    </label>
                                                                                    <div class="detailtext">Sender email
                                                                                        address for updated job alert
                                                                                        email.</div>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <br />
                                                                        <div class="row">
                                                                            <div class="form-group col-sm-2">
                                                                                <label class="label">
                                                                                    Alias<span class="text-danger">
                                                                                        *
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-group col-sm-5">
                                                                                <fieldset>
                                                                                    <label class="input">
                                                                                        <input id="updtjobemailalias"
                                                                                            type="text"
                                                                                            name="updtjobemailalias"
                                                                                            placeholder="Email Alias"
                                                                                            [(ngModel)]="Settings.JobUpdateAlias"
                                                                                            required
                                                                                            #updtjobemailalias="ngModel">
                                                                                    </label>
                                                                                    <div class="detailtext">Alias or
                                                                                        name for updated job alert
                                                                                        email.</div>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <br />
                                                                        <div class="row">
                                                                            <div class="form-group col-sm-2">
                                                                                <label class="label">
                                                                                    To
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-group col-sm-5">
                                                                                <fieldset>
                                                                                    <label class="input">
                                                                                        <input id="updtjobemailto"
                                                                                            type="text"
                                                                                            name="updtjobemailto"
                                                                                            placeholder="Email"
                                                                                            [(ngModel)]="Settings.JobUpdateTo"
                                                                                            #updtjobemailto>
                                                                                    </label>
                                                                                    <div class="detailtext">Persons to
                                                                                        keep in loop for updated job
                                                                                        alert emails.Tokens <label
                                                                                            class="token"
                                                                                            (click)="AssignToken('updtjobemailto', _sharedService.Token_AssignedTo)">{{_sharedService.Token_AssignedTo}}
                                                                                        </label> and <label
                                                                                            class="token"
                                                                                            (click)="AssignToken('updtjobemailto', _sharedService.Token_CreatedBy)">{{_sharedService.Token_CreatedBy}}
                                                                                        </label>, can be used separated
                                                                                        by semicolon(;).</div>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <br />

                                                                        <div class="row">
                                                                            <div class="form-group col-sm-2">
                                                                                <label class="label">
                                                                                    Subject<span class="text-danger">
                                                                                        *
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-group col-sm-5">
                                                                                <fieldset>
                                                                                    <label class="input">
                                                                                        <input id="updtjobemailsbjt"
                                                                                            type="text"
                                                                                            name="updtjobemailsbjt"
                                                                                            placeholder="Email Subject"
                                                                                            [(ngModel)]="Settings.JobUpdateSubject"
                                                                                            required #updtjobemailsbjt>

                                                                                    </label>
                                                                                    <div class="detailtext">Updated job
                                                                                        alert subject of email for user.
                                                                                    </div>
                                                                                    Tokens are:
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('updtjobemailsbjt', _sharedService.Token_jobid)">{{_sharedService.Token_jobid
                                                                                        }}
                                                                                    </label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('updtjobemailsbjt', _sharedService.Token_tenantname)">{{_sharedService.Token_tenantname
                                                                                        }}
                                                                                    </label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('updtjobemailsbjt', _sharedService.Token_ASN)">{{_sharedService.Token_ASN}}
                                                                                    </label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('updtjobemailsbjt', _sharedService.Token_Priority)">{{_sharedService.Token_Priority}}
                                                                                    </label>
                                                                                    <div class=""></div>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <br />
                                                                        <div class="row">
                                                                            <div class="form-group col-sm-2">
                                                                                <label class="label">
                                                                                    Body<span class="text-danger">
                                                                                        *
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-group col-sm-5">
                                                                                <fieldset>
                                                                                    <label class="textarea">
                                                                                        <p-editor id="updtjobemailbody"
                                                                                            name="updtjobemailbody"
                                                                                            [(ngModel)]="Settings.JobUpdateBody"
                                                                                            [style]="{'height':'150px'}"
                                                                                            required #updtjobemailbody>
                                                                                        </p-editor>
                                                                                    </label>
                                                                                    <div class="detailtext">
                                                                                        Completed job alert contents of
                                                                                        email for user. Standard HTML
                                                                                        tags alongwith
                                                                                        {{ '{' }}tags{{ '}' }} from user
                                                                                        screen
                                                                                        Tokens are:
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('updtjobemailbody', _sharedService.Token_jobid)">{{_sharedService.Token_jobid
                                                                                            }}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('updtjobemailbody', _sharedService.Token_tenantname)">{{_sharedService.Token_tenantname
                                                                                            }}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('updtjobemailbody', _sharedService.Token_ASN)">{{_sharedService.Token_ASN}}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('updtjobemailbody', _sharedService.Token_Priority)">{{_sharedService.Token_Priority}}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('updtjobemailbody', _sharedService.Token_AssignedTo)">{{_sharedService.Token_AssignedTo}}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('updtjobemailbody', _sharedService.Token_Conversation)">{{_sharedService.Token_Conversation}}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('updtjobemailbody', _sharedService.Token_Status)">{{_sharedService.Token_Status}}</label>,
                                                                                        <label class="token"
                                                                                            (click)="AssignToken('updtjobemailbody', _sharedService.Token_TenantURL)">{{_sharedService.Token_TenantURL}}</label>
                                                                                        .
                                                                                    </div>
                                                                                </fieldset>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div class="widget-footer">
                                                                <button type="button"
                                                                    class="btn btn-primary custom-button"
                                                                    [disabled]="!jobssettingsform.form.valid"
                                                                    (click)="setSettings('Jobs',jobssettingsform)">
                                                                    Update
                                                                </button>
                                                            </div>

                                                        </form>
                                                    </div>
                                                </div>

                                                <div id="exportsettings"
                                                    class="tab-pane fade margin-configuration tab-content-scroll"
                                                    [ngClass]="(isActiveTabExport==true)?'active in':''">
                                                    <form #exportsettingsform="ngForm">
                                                        <div class="panel panel-default">
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">FTP Settings</h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal-alt">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <div class="detailtext">FTP credentials
                                                                                    is one of the media for saving API
                                                                                    module, outgoing menu, scheduled
                                                                                    feeds.</div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Host Name
                                                                                <!-- <span class="text-danger">*</span> -->
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="hostnamesetting"
                                                                                        type="text"
                                                                                        name="hostnamesetting"
                                                                                        placeholder="Host Name"
                                                                                        [(ngModel)]="Settings.FTPHostName"
                                                                                        #hostnamesetting="ngModel">
                                                                                </label>
                                                                                <!--<div class="detailtext">FTP credentials is one of the media for saving API module, outgoing menu, scheduled feeds.</div>-->
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                User Name
                                                                                <!-- <span class="text-danger">*</span> -->
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="usernamesetting"
                                                                                        type="text"
                                                                                        name="usernamesetting"
                                                                                        placeholder="User Name"
                                                                                        [(ngModel)]="Settings.FTPUserName"
                                                                                        #usernamesetting="ngModel">
                                                                                </label>
                                                                                <!--<div class="detailtext">FTP credentials is one of the media for saving API module, outgoing menu, scheduled feeds. </div>-->
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Password
                                                                                <!-- <span class="text-danger">*</span> -->
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="pswdsetting"
                                                                                        type="password"
                                                                                        name="pswdsetting"
                                                                                        placeholder="Password"
                                                                                        [(ngModel)]="Settings.FTPPswd"
                                                                                        #pswdsetting="ngModel">
                                                                                </label>
                                                                                <!--<div class="detailtext">FTP credentials is one of the media for saving API module, outgoing menu, scheduled feeds. </div>-->
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div class="panel panel-default" *ngIf="isSysAdmin">
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Excel Export Headers Template
                                                                </h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Record<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="textarea"><textarea
                                                                                        id="appexportrecord"
                                                                                        name="appexportrecord"
                                                                                        #appexportrecord="ngModel"
                                                                                        [(ngModel)]="Settings.RecordHeader"
                                                                                        class="custom-scrollNote no-overflow-x"
                                                                                        rows="10"></textarea></label>
                                                                                <!--<div class="detailtext">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>-->
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Status<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="textarea"><textarea
                                                                                        id="appexportstatus"
                                                                                        name="appexportstatus"
                                                                                        #appexportstatus="ngModel"
                                                                                        [(ngModel)]="Settings.StatusHeader"
                                                                                        class="custom-scrollNote no-overflow-x"
                                                                                        rows="10"></textarea></label>
                                                                                <!--<div class="detailtext">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>-->
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Job<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="textarea"><textarea
                                                                                        id="appexportjob"
                                                                                        name="appexportjob"
                                                                                        #appexportjob="ngModel"
                                                                                        [(ngModel)]="Settings.JobHeader"
                                                                                        class="custom-scrollNote no-overflow-x"
                                                                                        rows="10"></textarea></label>
                                                                                <!--<div class="detailtext">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>-->
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />


                                                        <div class="panel panel-default" *ngIf="isSysAdmin">
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">PDF Export Template
                                                                </h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Status Header<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="textarea"><textarea
                                                                                        id="appexportstatuspdf"
                                                                                        name="appexportstatuspdf"
                                                                                        #appexportrecord="ngModel"
                                                                                        [(ngModel)]="Settings.PDFAPIStatusHeader"
                                                                                        class="custom-scrollNote no-overflow-x"
                                                                                        rows="10"></textarea></label>
                                                                                <!--<div class="detailtext">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>-->
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Status Footer<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="textarea"><textarea
                                                                                        id="appexportstatusfooterpdf"
                                                                                        name="appexportstatusfooterpdf"
                                                                                        #appexportrecord="ngModel"
                                                                                        [(ngModel)]="Settings.PDFAPIStatusFooter"
                                                                                        class="custom-scrollNote no-overflow-x"
                                                                                        rows="10"></textarea></label>
                                                                                <!--<div class="detailtext">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>-->
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />





                                                        <div class="panel panel-default" *ngIf="isSysAdmin">
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">API Excel Export Headers
                                                                    Template</h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal-alt">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Record<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="textarea"><textarea
                                                                                        id="autoexportrecord"
                                                                                        name="autoexportrecord"
                                                                                        #autoexportrecord="ngModel"
                                                                                        [(ngModel)]="Settings.APIRecordHeader"
                                                                                        class="custom-scrollNote no-overflow-x"
                                                                                        rows="10"></textarea></label>
                                                                                <!--<div class="detailtext">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>-->
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Status<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="textarea"><textarea
                                                                                        id="autoexportstatus"
                                                                                        name="autoexportstatus"
                                                                                        #autoexportstatus="ngModel"
                                                                                        [(ngModel)]="Settings.APIStatusHeader"
                                                                                        class="custom-scrollNote no-overflow-x"
                                                                                        rows="10"></textarea></label>
                                                                                <!--<div class="detailtext">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>-->
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div class="panel panel-default">
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Export Completion Email Template
                                                                </h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <div class="detailtext">For export
                                                                                    completion alert from application,
                                                                                    system generated email is sent as
                                                                                    alert to user. Following data is
                                                                                    used:</div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                From<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="outgoingfileemailfrom"
                                                                                        type="text"
                                                                                        name="outgoingfileemailfrom"
                                                                                        placeholder="Email"
                                                                                        [(ngModel)]="Settings.ExportCompleteFrom"
                                                                                        required
                                                                                        #outgoingfileemailfrom="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">Sender email
                                                                                    address for export completion alert
                                                                                    email.</div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Alias<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="outgoingfileemailalias"
                                                                                        type="text"
                                                                                        name="outgoingfileemailalias"
                                                                                        placeholder="Email Alias"
                                                                                        [(ngModel)]="Settings.ExportCompleteAlias"
                                                                                        required
                                                                                        #outgoingfileemailalias="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">Alias or name
                                                                                    for export completion alert email.
                                                                                </div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Subject<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="outgoingfileemailsubject"
                                                                                        type="text"
                                                                                        name="outgoingfileemailsubject"
                                                                                        placeholder="Email Subject"
                                                                                        [(ngModel)]="Settings.ExportCompleteSubject"
                                                                                        required
                                                                                        #outgoingfileemailsubject="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">Export
                                                                                    completion alert subject of email
                                                                                    for user.</div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Body<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="textarea">
                                                                                    <p-editor id="outgoingfileemailbody"
                                                                                        name="outgoingfileemailbody"
                                                                                        [(ngModel)]="Settings.ExportCompleteBody"
                                                                                        [style]="{'height':'150px'}"
                                                                                        required #outgoingfileemailbody>
                                                                                    </p-editor>
                                                                                </label>
                                                                                <!--<label class="textarea"><textarea id="outgoingfileemailbody" name="outgoingfileemailbody" #outgoingfileemailbody="ngModel" [(ngModel)]="Settings.ExportCompleteBody" class="custom-scrollNote no-overflow-x" rows="8"></textarea></label>-->
                                                                                <div class="detailtext">
                                                                                    Export completion alert contents of
                                                                                    email for user. Standard HTML tags
                                                                                    alongwith {{ '{' }}tags{{ '}' }}
                                                                                    from user screen

                                                                                    Tokens are:
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('outgoingfileemailbody', _sharedService.Token_FileName)">{{_sharedService.Token_FileName}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('outgoingfileemailbody', _sharedService.Token_ZipPassword)">{{_sharedService.Token_ZipPassword}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('outgoingfileemailbody', _sharedService.Token_FileURL)">{{_sharedService.Token_FileURL}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('outgoingfileemailbody', _sharedService.Token_Tenant)">{{_sharedService.Token_Tenant}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('outgoingfileemailbody', _sharedService.Token_TenantURL)">{{_sharedService.Token_TenantURL}}</label>
                                                                                    .

                                                                                </div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                        <!--<div class="form-group col-sm-5">
                                                                            <div [innerHTML]="Settings.ExportCompleteBody"></div>
                                                                        </div>-->
                                                                        <!--<div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <legend class="no-top-padding">Preview</legend>
                                                                                <p [innerHTML]="Settings.ExportCompleteBody"></p>
                                                                            </fieldset>
                                                                        </div>-->
                                                                        <!--<div class="form-group col-sm-4 div-text-aria">
                                                                            <fieldset>
                                                                                <label class="textarea" [innerHTML]="Settings.ExportCompleteBody"></label>
                                                                            </fieldset>
                                                                        </div>-->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="widget-footer">
                                                            <button type="button" class="btn btn-primary custom-button"
                                                                [disabled]="!exportsettingsform.form.valid"
                                                                (click)="setSettings('Export',exportsettingsform)">
                                                                Update
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>

                                                <div id="apisettings"
                                                    class="tab-pane fade margin-configuration tab-content-scroll"
                                                    [ngClass]="(isActiveTabAPI==true)?'active in':''">
                                                    <form #apisettingsform="ngForm">
                                                        <div class="panel panel-default">
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Import - Error Alert Email
                                                                    Template</h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <div class="detailtext">For API - Import
                                                                                    feed processing, in case of error
                                                                                    while processing, a system generated
                                                                                    email is sent as alert to user.
                                                                                    Following data is used:</div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                From<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="incomingissueemailfrom"
                                                                                        type="text"
                                                                                        name="incomingissueemailfrom"
                                                                                        placeholder="Email"
                                                                                        [(ngModel)]="Settings.IncomingIssueFrom"
                                                                                        required
                                                                                        #incomingissueemailfrom="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">Sender email
                                                                                    address.</div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                To<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="incomingissueemailto"
                                                                                        type="text"
                                                                                        name="incomingissueemailto"
                                                                                        placeholder="Email"
                                                                                        [(ngModel)]="Settings.IncomingIssueTo"
                                                                                        required
                                                                                        #incomingissueemailto="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">List persons
                                                                                    which should be informed in case of
                                                                                    error.</div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Alias<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="incomingissueemailalias"
                                                                                        type="text"
                                                                                        name="incomingissueemailalias"
                                                                                        placeholder="Email Alias"
                                                                                        [(ngModel)]="Settings.IncomingIssueAlias"
                                                                                        required
                                                                                        #incomingissueemailalias="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">Alias or name.
                                                                                </div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Subject<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input
                                                                                        id="incomingissueemailsubject"
                                                                                        type="text"
                                                                                        name="incomingissueemailsubject"
                                                                                        placeholder="Email Subject"
                                                                                        [(ngModel)]="Settings.IncomingIssueSubject"
                                                                                        required
                                                                                        #incomingissueemailsubject="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">Subject of email
                                                                                    for user.</div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Body<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="textarea">
                                                                                    <p-editor
                                                                                        id="incomingissueemailbody"
                                                                                        name="incomingissueemailbody"
                                                                                        [(ngModel)]="Settings.IncomingIssueBody"
                                                                                        [style]="{'height':'150px'}"
                                                                                        required
                                                                                        #incomingissueemailbody>
                                                                                    </p-editor>
                                                                                </label>
                                                                                <!--<label class="textarea"><textarea id="incomingissueemailbody" name="incomingissueemailbody" #incomingissueemailbody="ngModel" [(ngModel)]="Settings.IncomingIssueBody" class="custom-scrollNote no-overflow-x" rows="8"></textarea></label>-->
                                                                                <div class="detailtext">
                                                                                    Contents of email for user. Standard
                                                                                    HTML tags alongwith
                                                                                    {{ '{' }}tags{{ '}' }} from Feed
                                                                                    screen.

                                                                                    Tokens are:
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('incomingissueemailbody', _sharedService.Token_FeedName)">{{_sharedService.Token_FeedName}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('incomingissueemailbody', _sharedService.Token_Tenant)">{{_sharedService.Token_Tenant}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('incomingissueemailbody', _sharedService.Token_TenantURL)">{{_sharedService.Token_TenantURL}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('incomingissueemailbody', _sharedService.Token_SourceFilePath)">{{_sharedService.Token_SourceFilePath}}</label>,
                                                                                    <!-- <label class="token" (click)="AssignToken('incomingissueemailbody', _sharedService.Token_ErrorLogPath)">{{_sharedService.Token_ErrorLogPath}}</label> -->
                                                                                    .

                                                                                </div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                        <!--<div class="form-group col-sm-5">
                                                                            <div [innerHTML]="Settings.IncomingIssueBody"></div>
                                                                        </div>-->
                                                                        <!--<div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <legend class="no-top-padding">Preview</legend>
                                                                                <p [innerHTML]="Settings.IncomingIssueBody"></p>
                                                                            </fieldset>
                                                                        </div>-->
                                                                        <!--<div class="form-group col-sm-4 div-text-aria">
                                                                            <fieldset>
                                                                                <label class="textarea" [innerHTML]="Settings.IncomingIssueBody"></label>
                                                                            </fieldset>
                                                                        </div>-->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div class="panel panel-default">
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Outgoing - Error Alert Email
                                                                    Template</h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <div class="detailtext">For API -
                                                                                    Outgoing feed processing, in case of
                                                                                    error while processing, a system
                                                                                    generated email is sent as alert to
                                                                                    user. Following data is used:</div>
                                                                            </fieldset>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                From<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="outgoingissueemailfrom"
                                                                                        type="text"
                                                                                        name="outgoingissueemailfrom"
                                                                                        placeholder="Email"
                                                                                        [(ngModel)]="Settings.OutgoingIssueFrom"
                                                                                        required
                                                                                        #outgoingissueemailfrom="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">Sender email
                                                                                    address.</div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                To<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="outgoingissueemailto"
                                                                                        type="text"
                                                                                        name="outgoingissueemailto"
                                                                                        placeholder="Email"
                                                                                        [(ngModel)]="Settings.OutgoingIssueTo"
                                                                                        required
                                                                                        #outgoingissueemailto="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">List persons
                                                                                    which should be informed in case of
                                                                                    error.</div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Alias<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="outgoingissueemailalias"
                                                                                        type="text"
                                                                                        name="outgoingissueemailalias"
                                                                                        placeholder="Email Alias"
                                                                                        [(ngModel)]="Settings.OutgoingIssueAlias"
                                                                                        required
                                                                                        #outgoingissueemailalias="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">Alias or name.
                                                                                </div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Subject<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input
                                                                                        id="outgoingissueemailsubject"
                                                                                        type="text"
                                                                                        name="outgoingissueemailsubject"
                                                                                        placeholder="Email Subject"
                                                                                        [(ngModel)]="Settings.OutgoingIssueSubject"
                                                                                        required
                                                                                        #outgoingissueemailsubject="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">Subject of email
                                                                                    for user.</div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Body<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="textarea">
                                                                                    <p-editor
                                                                                        id="outgoingissueemailbody"
                                                                                        name="outgoingissueemailbody"
                                                                                        [(ngModel)]="Settings.OutgoingIssueBody"
                                                                                        [style]="{'height':'150px'}"
                                                                                        required
                                                                                        #outgoingissueemailbody>
                                                                                    </p-editor>
                                                                                </label>
                                                                                <!--<label class="textarea"><textarea id="outgoingissueemailbody" name="outgoingissueemailbody" #outgoingissueemailbody="ngModel" [(ngModel)]="Settings.OutgoingIssueBody" class="custom-scrollNote no-overflow-x" rows="8"></textarea></label>-->
                                                                                <div class="detailtext">
                                                                                    Contents of email for user. Standard
                                                                                    HTML tags alongwith
                                                                                    {{ '{' }}tags{{ '}' }} from Feed
                                                                                    screen.
                                                                                    Tokens are:
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('outgoingissueemailbody', _sharedService.Token_FeedName)">{{_sharedService.Token_FeedName}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('outgoingissueemailbody', _sharedService.Token_Tenant)">{{_sharedService.Token_Tenant}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('outgoingissueemailbody', _sharedService.Token_TenantURL)">{{_sharedService.Token_TenantURL}}</label>,
                                                                                    <!-- <label class="token" (click)="AssignToken('outgoingissueemailbody', _sharedService.Token_SourceFilePath)">{{_sharedService.Token_SourceFilePath}}</label>,
                                                                                    <label class="token" (click)="AssignToken('outgoingissueemailbody', _sharedService.Token_ErrorLogPath)">{{_sharedService.Token_ErrorLogPath}}</label> -->
                                                                                    .
                                                                                </div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                        <!--<div class="form-group col-sm-5">
                                                                            <div [innerHTML]="Settings.OutgoingIssueBody"></div>
                                                                        </div>-->
                                                                        <!--<div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <legend class="no-top-padding">Preview</legend>
                                                                                <p [innerHTML]="Settings.OutgoingIssueBody"></p>
                                                                            </fieldset>
                                                                        </div>-->
                                                                        <!--<div class="form-group col-sm-4 div-text-aria">
                                                                            <fieldset>
                                                                                <label class="textarea" [innerHTML]="Settings.OutgoingIssueBody"></label>
                                                                            </fieldset>
                                                                        </div>-->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />

                                                        <div class="widget-footer">
                                                            <button type="button" class="btn btn-primary custom-button"
                                                                [disabled]="!apisettingsform.form.valid"
                                                                (click)="setSettings('API',apisettingsform)">
                                                                Update
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>

                                                <div class="tab-pane fade margin-configuration tab-content-scroll"
                                                    id="ocrsettings" [ngClass]="(isActiveTabOCR==true)?'active in':''">
                                                    <form #ocrsettingsform="ngForm">
                                                        <div class="panel panel-default" style="height: 63vh;">
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Document Web OCR Interval</h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Interval<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <!--<p-dropdown id="ocrintervalsetting" name="ocrintervalsetting" [options]="intervalList" required [(ngModel)]="Settings." #ocrintervalsetting="ngModel">

                                                                                </p-dropdown>-->
                                                                                <p-selectButton id="ocrintervalsetting"
                                                                                    name="ocrintervalsetting"
                                                                                    [options]="intervalList" required
                                                                                    [(ngModel)]="Settings.IntervalOCR"
                                                                                    #ocrintervalsetting="ngModel">
                                                                                </p-selectButton>
                                                                                <div class="detailtext">Set interval
                                                                                    after which backend job should be
                                                                                    executed to detect and convert Non
                                                                                    OCR documents to OCR document.</div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                        <div class="form-group col-sm-1">
                                                                            <label class="label"
                                                                                style="padding-top:7px;">
                                                                                Last Run
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-4">
                                                                            <label class="label"
                                                                                style="padding-top:7px;">
                                                                                {{Settings.OCRLastRun}}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="widget-footer">
                                                            <button type="button" class="btn btn-primary custom-button"
                                                                [disabled]="!ocrsettingsform.form.valid"
                                                                (click)="setSettings('OCR',ocrsettingsform)">
                                                                Update
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>

                                                <div id="alertsettings"
                                                    class="tab-pane fade margin-configuration tab-content-scroll"
                                                    [ngClass]="(isActiveTabAlert==true)?'active in':''"
                                                    *ngIf="isSysAdmin">
                                                    <form #alertsettingsform="ngForm">
                                                        <div class="panel panel-default">
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Alert Email Template</h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <div class="detailtext">When user select
                                                                                    alert option, system generated email
                                                                                    is sent to the users. Following data
                                                                                    is used in email generation.</div>
                                                                            </fieldset>
                                                                        </div>

                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                From<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="alertemailfrom"
                                                                                        type="text"
                                                                                        name="alertemailfrom"
                                                                                        placeholder="Email"
                                                                                        [(ngModel)]="Settings.AlertFrom"
                                                                                        required
                                                                                        #alertemailfrom="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">Sender email
                                                                                    address for alert email.</div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Alias<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="alertemailalias"
                                                                                        type="text"
                                                                                        name="alertemailalias"
                                                                                        placeholder="Email Alias"
                                                                                        [(ngModel)]="Settings.AlertAlias"
                                                                                        required
                                                                                        #alertemailalias="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">Alias or name
                                                                                    for alert email..</div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Subject<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="input">
                                                                                    <input id="alertemailsubject"
                                                                                        type="text"
                                                                                        name="alertemailsubject"
                                                                                        placeholder="Email Subject"
                                                                                        [(ngModel)]="Settings.AlertSubject"
                                                                                        required
                                                                                        #alertemailsubject="ngModel">

                                                                                </label>
                                                                                <div class="detailtext">Subject for
                                                                                    alert email.</div>
                                                                            </fieldset>
                                                                            <!--<br />
                                                                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                                                            <br />-->
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <br />
                                                                    <div class="row">
                                                                        <div class="form-group col-sm-2">
                                                                            <label class="label">
                                                                                Body<span class="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-group col-sm-5">
                                                                            <fieldset>
                                                                                <label class="textarea">
                                                                                    <p-editor id="alertmailbody"
                                                                                        name="alertmailbody"
                                                                                        [(ngModel)]="Settings.AlertBody"
                                                                                        [style]="{'height':'150px'}"
                                                                                        required #alertmailbody>
                                                                                    </p-editor>
                                                                                </label>
                                                                                <div class="detailtext">
                                                                                    Contents of email alert. Standard
                                                                                    HTML tags alongwith special
                                                                                    {{ '{' }}tags{{ '}' }} from user
                                                                                    screen.
                                                                                    Tokens are:
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('alertmailbody', _sharedService.Token_FirstName)">{{_sharedService.Token_FirstName}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('alertmailbody', _sharedService.Token_LastName)">{{_sharedService.Token_LastName}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('alertmailbody', _sharedService.Token_Tenant)">{{_sharedService.Token_Tenant}}</label>,
                                                                                    <label class="token"
                                                                                        (click)="AssignToken('alertmailbody', _sharedService.Token_TenantURL)">{{_sharedService.Token_TenantURL}}</label>
                                                                                    .
                                                                                </div>
                                                                            </fieldset>

                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="widget-footer">
                                                            <button type="button" class="btn btn-primary custom-button"
                                                                [disabled]="!alertsettingsform.form.valid"
                                                                (click)="setSettings('Alert',alertsettingsform)">
                                                                Update
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>

                                                <div id="qualitysettings"
                                                    class="tab-pane fade margin-configuration tab-content-scroll"
                                                    [ngClass]="(isActiveTabQualityStatus==true)?'active in':''">
                                                    <form #qualitysettingsform="ngForm">
                                                        <div class="panel panel-default ng-star-inserted">
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Document Quality Status & List
                                                                    Column Settings</h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal">
                                                                <div class="col-sm-12">
                                                                    <div id="documentTabContent1" class="tab-content"
                                                                        style="min-height: auto !important;">
                                                                        <div class="tab-pane fade active in" id="c1">
                                                                            <div class="smart-form">
                                                                                <div class="sticky-Menu">
                                                                                    <ul id="widget-tab-1"
                                                                                        class="nav nav-tabs"
                                                                                        role="tablist">
                                                                                        <li id="documentColList_MXR1"
                                                                                            [ngClass]="(isActiveTabDocumentColList_MXR1==true)?'active':''">
                                                                                            <a #docPanal1
                                                                                                data-toggle="tab"
                                                                                                [attr.tabindex]="0"
                                                                                                data-target="#documentColList_MXR"
                                                                                                [attr.aria-expanded]="isActiveTabDocumentColList_MXR1"
                                                                                                style="cursor: pointer;">
                                                                                                <i
                                                                                                    class="fa fa-gear blackiconcolor"></i><span
                                                                                                    class="hidden-mobile hidden-tablet"
                                                                                                    style="padding-left: 3px">MX
                                                                                                    Records</span>
                                                                                            </a>
                                                                                        </li>
                                                                                        <li id="documentColList_Log2"
                                                                                            [ngClass]="(isActiveTabDocumentColList_Log2==true)?'active':''">
                                                                                            <a #docPanal2
                                                                                                data-toggle="tab"
                                                                                                [attr.tabindex]="0"
                                                                                                data-target="#documentColList_Log"
                                                                                                [attr.aria-expanded]="isActiveTabDocumentColList_Log2"
                                                                                                style="cursor: pointer;">
                                                                                                <i
                                                                                                    class="fa fa-gear blackiconcolor"></i><span
                                                                                                    class="hidden-mobile hidden-tablet"
                                                                                                    style="padding-left: 3px">Log
                                                                                                    Books</span>
                                                                                            </a>
                                                                                        </li>
                                                                                        <li id="documentColList_Gen3"
                                                                                            [ngClass]="(isActiveTabDocumentColList_Gen3==true)?'active':''">
                                                                                            <a #docPanal3
                                                                                                data-toggle="tab"
                                                                                                [attr.tabindex]="0"
                                                                                                data-target="#documentColList_Gen"
                                                                                                [attr.aria-expanded]="isActiveTabDocumentColList_Gen3"
                                                                                                style="cursor: pointer;">
                                                                                                <i
                                                                                                    class="fa fa-gear blackiconcolor"></i><span
                                                                                                    class="hidden-mobile hidden-tablet"
                                                                                                    style="padding-left: 3px">General</span>
                                                                                            </a>
                                                                                        </li>
                                                                                        <li id="documentColList_Doc4"
                                                                                            [ngClass]="(isActiveTabDocumentColList_Doc4==true)?'active':''">
                                                                                            <a #docPanal4
                                                                                                data-toggle="tab"
                                                                                                [attr.tabindex]="0"
                                                                                                data-target="#documentColList_Doc"
                                                                                                [attr.aria-expanded]="isActiveTabDocumentColList_Doc4"
                                                                                                style="cursor: pointer;">
                                                                                                <i
                                                                                                    class="fa fa-gear blackiconcolor"></i><span
                                                                                                    class="hidden-mobile hidden-tablet"
                                                                                                    style="padding-left: 3px">Document</span>
                                                                                            </a>
                                                                                        </li>
                                                                                        <li id="documentColList_Bnd5"
                                                                                            [ngClass]="(isActiveTabDocumentColList_Bnd5==true)?'active':''">
                                                                                            <a #docPanal5
                                                                                                data-toggle="tab"
                                                                                                [attr.tabindex]="0"
                                                                                                data-target="#documentColList_Bnd"
                                                                                                [attr.aria-expanded]="isActiveTabDocumentColList_Bnd5"
                                                                                                style="cursor: pointer;">
                                                                                                <i
                                                                                                    class="fa fa-gear blackiconcolor"></i><span
                                                                                                    class="hidden-mobile hidden-tablet"
                                                                                                    style="padding-left: 3px">Binders</span>
                                                                                            </a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                                <div class="tab-content">

                                                                                    <div id="documentColList_MXR"
                                                                                        class="tab-pane fade margin-configuration"
                                                                                        [ngClass]="(isActiveTabDocumentColList_MXR1==true)?'active in':''">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label
                                                                                                    class="label margin-top-10">
                                                                                                    MX Records
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <fieldset>
                                                                                                    <label
                                                                                                        for="mxrquality"
                                                                                                        class="checkbox col-sm-2">
                                                                                                        <span
                                                                                                            class="onoffswitch tablIndexFocus"
                                                                                                            [attr.tabindex]="0"
                                                                                                            (keyup.enter)="ToggleChecked_MX()">
                                                                                                            <input
                                                                                                                [checked]="Settings.MXRQualityStatus"
                                                                                                                type="checkbox"
                                                                                                                name="onoffswitchmxr"
                                                                                                                class="onoffswitch-checkbox"
                                                                                                                [(ngModel)]="Settings.MXRQualityStatus"
                                                                                                                id="mxrquality">
                                                                                                            <label
                                                                                                                class="onoffswitch-label"
                                                                                                                for="mxrquality"><span
                                                                                                                    class="onoffswitch-inner"
                                                                                                                    data-swchon-text="YES"
                                                                                                                    data-swchoff-text="NO"></span><span
                                                                                                                    class="onoffswitch-switch"></span></label>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </fieldset>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-3">
                                                                                                <label class="label margin-top-10">
                                                                                                    Enable Quality Status column in Records module.
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Default column for sorting
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="document_mxr_sortbysetting"
                                                                                                        name="document_mxr_sortbysetting"
                                                                                                        [options]="Document_MXR_Sort_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Document_MXR_Sort_By"
                                                                                                        #document_mxr_sortbysetting="ngModel"
                                                                                                        appendTo="body">
                                                                                                    </p-dropdown>
                                                                                                </fieldset>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Order By
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="document_mxr_orderbysetting"
                                                                                                        name="document_mxr_orderbysetting"
                                                                                                        [options]="Column_Order_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Document_MXR_Order_By"
                                                                                                        #document_mxr_orderbysetting="ngModel"
                                                                                                        appendTo="body">
                                                                                                    </p-dropdown>
                                                                                                </fieldset>
                                                                                            </div>
                                                                                        </div>


                                                                                        <form
                                                                                            #documentColList_MXRform="ngForm"
                                                                                            *ngIf="Current_SubDomain_Tenant != SubDomain_Tenant_Internal">
                                                                                            <div
                                                                                                class="col-sm-12 col-md-12 col-lg-12 nopaddingnmargin">
                                                                                                <div
                                                                                                    id="gridDocumentMXR">
                                                                                                    <!--AG Grid Start-->
                                                                                                    <!-- [frameworkComponents]="frameworkComponents" -->
                                                                                                    <!-- (componentStateChanged) ="preSelectDocGridRows($event)" -->
                                                                                                    <ag-grid-angular
                                                                                                        id="documentColGridMXR"
                                                                                                        #documentColGridMXR
                                                                                                        [gridOptions]="gridOptionsDocumentCol_MXR"
                                                                                                        [columnDefs]="documentColumnDefs"
                                                                                                        [rowData]="Document_MX_Records_Column_Setting_Grid_Data"
                                                                                                        [rowDragManaged]="true"
                                                                                                        (componentStateChanged)="preSelectGridRows($event)"
                                                                                                        [rowSelection]="rowSelection"
                                                                                                        (gridReady)="onGridReady_DocumentMXR($event)"
                                                                                                        [animateRows]="true"
                                                                                                        sortable="true"
                                                                                                        [suppressRowClickSelection]="true"
                                                                                                        filter=true
                                                                                                        style="width: 100% !important; "
                                                                                                        class="ag-theme-balham set-docgrid-width">
                                                                                                    </ag-grid-angular>
                                                                                                    <!--AG Grid End-->
                                                                                                </div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>

                                                                                    <div id="documentColList_Log"
                                                                                        class="tab-pane fade margin-configuration"
                                                                                        [ngClass]="(isActiveTabDocumentColList_Log2==true)?'active in':''">

                                                                                        <div class="row">

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label
                                                                                                    class="label margin-top-10">
                                                                                                    Log Books
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <fieldset>
                                                                                                    <label
                                                                                                        for="logquality"
                                                                                                        class="checkbox col-sm-2">
                                                                                                        <span
                                                                                                            class="onoffswitch  tablIndexFocus"
                                                                                                            [attr.tabindex]="0"
                                                                                                            (keyup.enter)="ToggleChecked_Log()">
                                                                                                            <input
                                                                                                                [checked]="Settings.LOGQualityStatus"
                                                                                                                type="checkbox"
                                                                                                                name="onoffswitchlog"
                                                                                                                class="onoffswitch-checkbox"
                                                                                                                [(ngModel)]="Settings.LOGQualityStatus"
                                                                                                                id="logquality">
                                                                                                            <label
                                                                                                                class="onoffswitch-label"
                                                                                                                for="logquality"><span
                                                                                                                    class="onoffswitch-inner"
                                                                                                                    data-swchon-text="YES"
                                                                                                                    data-swchoff-text="NO"></span><span
                                                                                                                    class="onoffswitch-switch"></span></label>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </fieldset>
                                                                                            </div>


                                                                                            <div
                                                                                                class="form-group col-sm-3">
                                                                                                <label class="label margin-top-10">
                                                                                                    Enable Quality Status column in Records module.
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Default column for sorting
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="document_log_sortbysetting"
                                                                                                        name="document_log_sortbysetting"
                                                                                                        [options]="Document_LOG_Sort_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Document_LOG_Sort_By"
                                                                                                        #document_log_sortbysetting="ngModel"
                                                                                                        appendTo="body">
                                                                                                    </p-dropdown>
                                                                                                </fieldset>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Order By
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="document_log_orderbysetting"
                                                                                                        name="document_log_orderbysetting"
                                                                                                        [options]="Column_Order_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Document_LOG_Order_By"
                                                                                                        #document_log_orderbysetting="ngModel"
                                                                                                        appendTo="body">
                                                                                                    </p-dropdown>
                                                                                                </fieldset>
                                                                                            </div>
                                                                                        </div>


                                                                                        <form
                                                                                            #documentColList_Logform="ngForm">
                                                                                            <div
                                                                                                class="col-sm-12 col-md-12 col-lg-12 nopaddingnmargin">
                                                                                                <div
                                                                                                    id="gridDocumentLog">
                                                                                                    <!-- [frameworkComponents]="frameworkComponents" -->
                                                                                                    <!--AG Grid Start-->
                                                                                                    <ag-grid-angular
                                                                                                        id="documentColGridLog"
                                                                                                        #documentColGridLog
                                                                                                        [gridOptions]="gridOptionsDocumentCol_Log"
                                                                                                        [columnDefs]="documentColumnDefs"
                                                                                                        [rowData]="Document_Log_Column_Setting_Grid_Data"
                                                                                                        [rowDragManaged]="true"
                                                                                                        [animateRows]="true"
                                                                                                        sortable="true"
                                                                                                        [suppressRowClickSelection]="true"
                                                                                                        filter=true
                                                                                                        (gridReady)="onGridReady_DocumentLOG($event)"
                                                                                                        (componentStateChanged)="preSelectGridRows($event)"
                                                                                                        [rowSelection]="rowSelection"
                                                                                                        style="width: 100% !important; "
                                                                                                        class="ag-theme-balham set-docgrid-width">
                                                                                                    </ag-grid-angular>
                                                                                                    <!--AG Grid End-->
                                                                                                </div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>

                                                                                    <div id="documentColList_Gen"
                                                                                        class="tab-pane fade margin-configuration"
                                                                                        [ngClass]="(isActiveTabDocumentColList_Gen3==true)?'active in':''">

                                                                                        <div class="row">

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label
                                                                                                    class="label margin-top-10">
                                                                                                    General
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <fieldset>
                                                                                                    <label
                                                                                                        for="genquality"
                                                                                                        class="checkbox col-sm-2">
                                                                                                        <span
                                                                                                            class="onoffswitch  tablIndexFocus"
                                                                                                            [attr.tabindex]="0"
                                                                                                            (keyup.enter)="ToggleChecked_G()">
                                                                                                            <input
                                                                                                                [checked]="Settings.GENQualityStatus"
                                                                                                                type="checkbox"
                                                                                                                name="onoffswitchgen"
                                                                                                                class="onoffswitch-checkbox"
                                                                                                                [(ngModel)]="Settings.GENQualityStatus"
                                                                                                                id="genquality">
                                                                                                            <label
                                                                                                                class="onoffswitch-label"
                                                                                                                for="genquality"><span
                                                                                                                    class="onoffswitch-inner"
                                                                                                                    data-swchon-text="YES"
                                                                                                                    data-swchoff-text="NO"></span><span
                                                                                                                    class="onoffswitch-switch"></span></label>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </fieldset>
                                                                                            </div>


                                                                                            <div
                                                                                                class="form-group col-sm-3">
                                                                                                <label class="label margin-top-10">
                                                                                                    Enable Quality Status column in Records module.
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Default column for sorting
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="document_gen_sortbysetting"
                                                                                                        name="document_gen_sortbysetting"
                                                                                                        [options]="Document_GEN_Sort_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Document_GEN_Sort_By"
                                                                                                        #document_gen_sortbysetting="ngModel" appendTo="body">
                                                                                                    </p-dropdown>
                                                                                                </fieldset>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Order By
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="document_gen_orderbysetting"
                                                                                                        name="document_gen_orderbysetting"
                                                                                                        [options]="Column_Order_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Document_GEN_Order_By"
                                                                                                        #document_gen_orderbysetting="ngModel"
                                                                                                        appendTo="body">
                                                                                                    </p-dropdown>
                                                                                                </fieldset>
                                                                                            </div>
                                                                                        </div>
                                                                                        <form
                                                                                            #documentColList_Genform="ngForm">
                                                                                            <div
                                                                                                class="col-sm-12 col-md-12 col-lg-12 nopaddingnmargin">
                                                                                                <div
                                                                                                    id="gridDocumentGen">
                                                                                                    <!-- [frameworkComponents]="frameworkComponents" -->
                                                                                                    <!--AG Grid Start-->
                                                                                                    <ag-grid-angular
                                                                                                        id="documentColGridGen"
                                                                                                        #documentColGridGen
                                                                                                        [gridOptions]="gridOptionsDocumentCol_Gen"
                                                                                                        [columnDefs]="documentColumnDefs"
                                                                                                        [rowData]="Document_Gen_Column_Setting_Grid_Data"
                                                                                                        [rowDragManaged]="true"
                                                                                                        [animateRows]="true"
                                                                                                        sortable="true"
                                                                                                        [suppressRowClickSelection]="true"
                                                                                                        filter=true
                                                                                                        [rowSelection]="rowSelection"
                                                                                                        (gridReady)="onGridReady_DocumentGEN($event)"
                                                                                                        (componentStateChanged)="preSelectGridRows($event)"
                                                                                                        style="width: 100% !important; "
                                                                                                        class="ag-theme-balham set-docgrid-width">
                                                                                                    </ag-grid-angular>
                                                                                                    <!--AG Grid End-->
                                                                                                </div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>

                                                                                    <div id="documentColList_Doc"
                                                                                        class="tab-pane fade margin-configuration"
                                                                                        [ngClass]="(isActiveTabDocumentColList_Doc4==true)?'active in':''">

                                                                                        <div class="row">

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label
                                                                                                    class="label margin-top-10">
                                                                                                    Document
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <fieldset>
                                                                                                    <label
                                                                                                        for="docquality"
                                                                                                        class="checkbox col-sm-2">
                                                                                                        <span
                                                                                                            class="onoffswitch  tablIndexFocus"
                                                                                                            [attr.tabindex]="0"
                                                                                                            (keyup.enter)="ToggleChecked_Doc()">
                                                                                                            <input
                                                                                                                [checked]="Settings.DOCQualityStatus"
                                                                                                                type="checkbox"
                                                                                                                name="onoffswitchdoc"
                                                                                                                class="onoffswitch-checkbox"
                                                                                                                [(ngModel)]="Settings.DOCQualityStatus"
                                                                                                                id="docquality">
                                                                                                            <label
                                                                                                                class="onoffswitch-label"
                                                                                                                for="docquality"><span
                                                                                                                    class="onoffswitch-inner"
                                                                                                                    data-swchon-text="YES"
                                                                                                                    data-swchoff-text="NO"></span><span
                                                                                                                    class="onoffswitch-switch"></span></label>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </fieldset>
                                                                                            </div>


                                                                                            <div
                                                                                                class="form-group col-sm-3">
                                                                                                <label class="label margin-top-10">
                                                                                                    Enable Quality Status column in Records module.
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Default column for sorting
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="document_doc_sortbysetting"
                                                                                                        name="document_doc_sortbysetting"
                                                                                                        [options]="Document_DOC_Sort_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Document_DOC_Sort_By"
                                                                                                        #document_doc_sortbysetting="ngModel" appendTo="body">
                                                                                                    </p-dropdown>

                                                                                                </fieldset>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Order By
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="document_doc_orderbysetting"
                                                                                                        name="document_doc_orderbysetting"
                                                                                                        [options]="Column_Order_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Document_DOC_Order_By"
                                                                                                        #document_doc_orderbysetting="ngModel" appendTo="body">
                                                                                                    </p-dropdown>

                                                                                                </fieldset>
                                                                                            </div>
                                                                                        </div>

                                                                                        <form
                                                                                            #documentColList_Docform="ngForm">
                                                                                            <div
                                                                                                class="col-sm-12 col-md-12 col-lg-12 nopaddingnmargin">
                                                                                                <div
                                                                                                    id="gridDocumentDoc">
                                                                                                    <!-- [frameworkComponents]="frameworkComponents" -->
                                                                                                    <!--AG Grid Start-->
                                                                                                    <ag-grid-angular
                                                                                                        id="documentColGridDoc"
                                                                                                        #documentColGridDoc
                                                                                                        [gridOptions]="gridOptionsDocumentCol_Doc"
                                                                                                        [columnDefs]="documentColumnDefs"
                                                                                                        [rowData]="Document_Doc_Column_Setting_Grid_Data"
                                                                                                        [rowDragManaged]="true"
                                                                                                        [animateRows]="true"
                                                                                                        sortable="true"
                                                                                                        [suppressRowClickSelection]="true"
                                                                                                        filter=true
                                                                                                        [rowSelection]="rowSelection"
                                                                                                        (gridReady)="onGridReady_DocumentDOC($event)"
                                                                                                        (componentStateChanged)="preSelectGridRows($event)"
                                                                                                        style="width: 100% !important; "
                                                                                                        class="ag-theme-balham set-docgrid-width">
                                                                                                    </ag-grid-angular>
                                                                                                    <!--AG Grid End-->
                                                                                                </div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>

                                                                                    <div id="documentColList_Bnd"
                                                                                        class="tab-pane fade margin-configuration"
                                                                                        [ngClass]="(isActiveTabDocumentColList_Bnd5==true)?'active in':''">

                                                                                        <div class="row">

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label
                                                                                                    class="label margin-top-10">
                                                                                                    Binders
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <fieldset>
                                                                                                    <label
                                                                                                        for="bndquality"
                                                                                                        class="checkbox col-sm-2">
                                                                                                        <span
                                                                                                            class="onoffswitch  tablIndexFocus"
                                                                                                            [attr.tabindex]="0"
                                                                                                            (keyup.enter)="ToggleChecked_Bnd()">
                                                                                                            <input
                                                                                                                [checked]="Settings.BNDQualityStatus"
                                                                                                                type="checkbox"
                                                                                                                name="onoffswitchbnd"
                                                                                                                class="onoffswitch-checkbox"
                                                                                                                [(ngModel)]="Settings.BNDQualityStatus"
                                                                                                                id="bndquality">
                                                                                                            <label
                                                                                                                class="onoffswitch-label"
                                                                                                                for="bndquality"><span
                                                                                                                    class="onoffswitch-inner"
                                                                                                                    data-swchon-text="YES"
                                                                                                                    data-swchoff-text="NO"></span><span
                                                                                                                    class="onoffswitch-switch"></span></label>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </fieldset>
                                                                                            </div>


                                                                                            <div
                                                                                                class="form-group col-sm-3">
                                                                                                <label class="label margin-top-10">
                                                                                                    Enable Quality Status column in Transation module.
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Default column for sorting
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="document_bnd_sortbysetting"
                                                                                                        name="document_bnd_sortbysetting"
                                                                                                        [options]="Document_BND_Sort_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Document_BND_Sort_By"
                                                                                                        #document_bnd_sortbysetting="ngModel" appendTo="body">
                                                                                                    </p-dropdown>
                                                                                                </fieldset>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Order By
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="document_bnd_orderbysetting"
                                                                                                        name="document_bnd_orderbysetting"
                                                                                                        [options]="Column_Order_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Document_BND_Order_By"
                                                                                                        #document_bnd_orderbysetting="ngModel" appendTo="body">
                                                                                                    </p-dropdown>
                                                                                                </fieldset>
                                                                                            </div>


                                                                                        </div>

                                                                                        <form
                                                                                            #documentColList_Bndform="ngForm">
                                                                                            <div
                                                                                                class="col-sm-12 col-md-12 col-lg-12 nopaddingnmargin">
                                                                                                <div
                                                                                                    id="gridDocumentBnd">
                                                                                                    <!-- [frameworkComponents]="frameworkComponents" -->
                                                                                                    <!--AG Grid Start-->
                                                                                                    <ag-grid-angular
                                                                                                        id="documentColGridBnd"
                                                                                                        #documentColGridBnd
                                                                                                        [gridOptions]="gridOptionsDocumentCol_Bnd"
                                                                                                        [columnDefs]="documentColumnDefs"
                                                                                                        [rowData]="Document_Bnd_Column_Setting_Grid_Data"
                                                                                                        [rowDragManaged]="true"
                                                                                                        [animateRows]="true"
                                                                                                        sortable="true"
                                                                                                        [suppressRowClickSelection]="true"
                                                                                                        filter=true
                                                                                                        [rowSelection]="rowSelection"
                                                                                                        (gridReady)="onGridReady_DocumentBND($event)"
                                                                                                        (componentStateChanged)="preSelectGridRows($event)"
                                                                                                        style="width: 100% !important; "
                                                                                                        class="ag-theme-balham set-docgrid-width">
                                                                                                    </ag-grid-angular>
                                                                                                    <!--AG Grid End-->
                                                                                                </div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <hr />
                                                                    <br /> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="widget-footer">
                                                            <button type="button" class="btn btn-primary custom-button"
                                                                [disabled]="!qualitysettingsform.form.valid"
                                                                (click)="setSettings('QualityStatus',qualitysettingsform)">
                                                                Update
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>

                                                <div id="containersettings"
                                                    class="tab-pane fade margin-configuration tab-content-scroll"
                                                    [ngClass]="(isActiveTabContainer==true)?'active in':''">
                                                    <form #containersettingsform="ngForm">

                                                        <div class="panel panel-default">
                                                            <div class="panel-heading panalheading-padding">
                                                                <h3 class="panel-title">Container Quality Status & List
                                                                    Column Settings</h3>
                                                            </div>
                                                            <div class="panel-body margin-configuration-panal">
                                                                <div class="col-sm-12">
                                                                    <div id="containerTabContent1" class="tab-content"
                                                                        style="min-height: auto !important;">
                                                                        <div class="tab-pane fade active in" id="c1">
                                                                            <div class="smart-form">
                                                                                <div class="sticky-Menu">
                                                                                    <ul id="widget-tab-1"
                                                                                        class="nav nav-tabs"
                                                                                        role="tablist">
                                                                                        <li id="containerColList_MXR1"
                                                                                            [ngClass]="(isActiveTabContainerColList_MXR1==true)?'active':''">
                                                                                            <a #panal1 data-toggle="tab"
                                                                                                [attr.tabindex]="0"
                                                                                                data-target="#containerColList_MXR"
                                                                                                [attr.aria-expanded]="isActiveTabContainerColList_MXR1"
                                                                                                style="cursor: pointer;">
                                                                                                <i
                                                                                                    class="fa fa-gear blackiconcolor"></i><span
                                                                                                    class="hidden-mobile hidden-tablet"
                                                                                                    style="padding-left: 3px">MX
                                                                                                    Records</span>
                                                                                            </a>
                                                                                        </li>
                                                                                        <li id="containerColList_Log2"
                                                                                            [ngClass]="(isActiveTabContainerColList_Log2==true)?'active':''">
                                                                                            <a #panal2 data-toggle="tab"
                                                                                                [attr.tabindex]="0"
                                                                                                data-target="#containerColList_Log"
                                                                                                [attr.aria-expanded]="isActiveTabContainerColList_Log2"
                                                                                                style="cursor: pointer;">
                                                                                                <i
                                                                                                    class="fa fa-gear blackiconcolor"></i><span
                                                                                                    class="hidden-mobile hidden-tablet"
                                                                                                    style="padding-left: 3px">Log
                                                                                                    Books</span>
                                                                                            </a>
                                                                                        </li>
                                                                                        <li id="containerColList_Gen3"
                                                                                            [ngClass]="(isActiveTabContainerColList_Gen3==true)?'active':''">
                                                                                            <a #panal3 data-toggle="tab"
                                                                                                [attr.tabindex]="0"
                                                                                                data-target="#containerColList_Gen"
                                                                                                [attr.aria-expanded]="isActiveTabContainerColList_Gen3"
                                                                                                style="cursor: pointer;">
                                                                                                <i
                                                                                                    class="fa fa-gear blackiconcolor"></i><span
                                                                                                    class="hidden-mobile hidden-tablet"
                                                                                                    style="padding-left: 3px">General</span>
                                                                                            </a>
                                                                                        </li>
                                                                                        <li id="containerColList_Doc4"
                                                                                            [ngClass]="(isActiveTabContainerColList_Doc4==true)?'active':''">
                                                                                            <a #panal4 data-toggle="tab"
                                                                                                [attr.tabindex]="0"
                                                                                                data-target="#containerColList_Doc"
                                                                                                [attr.aria-expanded]="isActiveTabContainerColList_Doc4"
                                                                                                style="cursor: pointer;">
                                                                                                <i
                                                                                                    class="fa fa-gear blackiconcolor"></i><span
                                                                                                    class="hidden-mobile hidden-tablet"
                                                                                                    style="padding-left: 3px">Document</span>
                                                                                            </a>
                                                                                        </li>
                                                                                        <li id="containerColList_Bnd5"
                                                                                            [ngClass]="(isActiveTabContainerColList_Bnd5==true)?'active':''">
                                                                                            <a #panal4 data-toggle="tab"
                                                                                                [attr.tabindex]="0"
                                                                                                data-target="#containerColList_Bnd"
                                                                                                [attr.aria-expanded]="isActiveTabContainerColList_Bnd5"
                                                                                                style="cursor: pointer;">
                                                                                                <i
                                                                                                    class="fa fa-gear blackiconcolor"></i><span
                                                                                                    class="hidden-mobile hidden-tablet"
                                                                                                    style="padding-left: 3px">Binders</span>
                                                                                            </a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                                <div class="tab-content">
                                                                                    <div id="containerColList_MXR"
                                                                                        class="tab-pane fade margin-configuration"
                                                                                        [ngClass]="(isActiveTabContainerColList_MXR1==true)?'active in':''">

                                                                                        <div class="row">

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label
                                                                                                    class="label margin-top-10">
                                                                                                    MX Records
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <fieldset>
                                                                                                    <label
                                                                                                        for="containermxrquality"
                                                                                                        class="checkbox col-sm-2">
                                                                                                        <span
                                                                                                            class="onoffswitch tablIndexFocus"
                                                                                                            [attr.tabindex]="0"
                                                                                                            (keyup.enter)="ToggleChecked_Container_MXR()">
                                                                                                            <input
                                                                                                                [checked]="Settings.Container_MXRQualityStatus"
                                                                                                                type="checkbox"
                                                                                                                name="onoffswitchmxr"
                                                                                                                class="onoffswitch-checkbox"
                                                                                                                [(ngModel)]="Settings.Container_MXRQualityStatus"
                                                                                                                id="containermxrquality">
                                                                                                            <label
                                                                                                                class="onoffswitch-label"
                                                                                                                for="containermxrquality"><span
                                                                                                                    class="onoffswitch-inner"
                                                                                                                    data-swchon-text="YES"
                                                                                                                    data-swchoff-text="NO"></span><span
                                                                                                                    class="onoffswitch-switch"></span></label>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </fieldset>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-3">
                                                                                                <label class="label margin-top-10">
                                                                                                    Enable Quality Status column in Records module.
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Default column for sorting
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="container_mxr_sortbysetting"
                                                                                                        name="container_mxr_sortbysetting"
                                                                                                        [options]="Container_MXR_Sort_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Container_MXR_Sort_By"
                                                                                                        #container_mxr_sortbysetting="ngModel" appendTo="body">
                                                                                                    </p-dropdown>
                                                                                                </fieldset>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Order By
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="container_mxr_orderbysetting"
                                                                                                        name="container_mxr_orderbysetting"
                                                                                                        [options]="Column_Order_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Container_MXR_Order_By"
                                                                                                        #container_mxr_orderbysetting="ngModel" appendTo="body">
                                                                                                    </p-dropdown>
                                                                                                </fieldset>
                                                                                            </div>
                                                                                        </div>
                                                                                        <form #containerColList_MXR
                                                                                            form="ngForm"
                                                                                            *ngIf="Current_SubDomain_Tenant != SubDomain_Tenant_Internal">
                                                                                            <div
                                                                                                class="col-sm-12 col-md-12 col-lg-12 nopaddingnmargin">
                                                                                                <div
                                                                                                    id="gridContainerMXR">
                                                                                                    <!-- [frameworkComponents]="frameworkComponents" -->
                                                                                                    <!--AG Grid Start-->
                                                                                                    <ag-grid-angular
                                                                                                        id="containerColGridMXR"
                                                                                                        #containerColGridMXR
                                                                                                        [gridOptions]="gridOptionsContainerCol_MXR"
                                                                                                        [columnDefs]="containerColumnDefs"
                                                                                                        [rowData]="Container_MX_Records_Column_Setting_Grid_Data"
                                                                                                        [rowDragManaged]="true"
                                                                                                        [animateRows]="true"
                                                                                                        sortable="true"
                                                                                                        [suppressRowClickSelection]="true"
                                                                                                        filter=true
                                                                                                        [rowSelection]="rowSelection"
                                                                                                        (gridReady)="onGridReady_ContainerMXR($event)"
                                                                                                        (componentStateChanged)="preSelectGridRows($event)"
                                                                                                        style="width: 100% !important;"
                                                                                                        class="ag-theme-balham set-docgrid-width">
                                                                                                    </ag-grid-angular>
                                                                                                    <!--AG Grid End-->
                                                                                                </div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>

                                                                                    <div id="containerColList_Log"
                                                                                        class="tab-pane fade margin-configuration"
                                                                                        [ngClass]="(isActiveTabContainerColList_Log2==true)?'active in':''">

                                                                                        <div class="row">

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label
                                                                                                    class="label margin-top-10">
                                                                                                    Log Books
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <fieldset>
                                                                                                    <label
                                                                                                        for="containerlogquality"
                                                                                                        class="checkbox col-sm-2">
                                                                                                        <span
                                                                                                            class="onoffswitch  tablIndexFocus"
                                                                                                            [attr.tabindex]="0"
                                                                                                            (keyup.enter)="ToggleChecked_Container_Log()">
                                                                                                            <input
                                                                                                                [checked]="Settings.Container_LOGQualityStatus"
                                                                                                                type="checkbox"
                                                                                                                name="onoffswitchlog"
                                                                                                                class="onoffswitch-checkbox"
                                                                                                                [(ngModel)]="Settings.Container_LOGQualityStatus"
                                                                                                                id="containerlogquality">
                                                                                                            <label
                                                                                                                class="onoffswitch-label"
                                                                                                                for="containerlogquality"><span
                                                                                                                    class="onoffswitch-inner"
                                                                                                                    data-swchon-text="YES"
                                                                                                                    data-swchoff-text="NO"></span><span
                                                                                                                    class="onoffswitch-switch"></span></label>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </fieldset>
                                                                                            </div>


                                                                                            <div
                                                                                                class="form-group col-sm-3">
                                                                                                <label class="label margin-top-10">
                                                                                                    Enable Quality Status column in Records module.
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Default column for sorting
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="container_log_sortbysetting"
                                                                                                        name="container_log_sortbysetting"
                                                                                                        [options]="Container_LOG_Sort_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Container_LOG_Sort_By"
                                                                                                        #container_log_sortbysetting="ngModel" appendTo="body">
                                                                                                    </p-dropdown>
                                                                                                </fieldset>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Order By
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="container_log_orderbysetting"
                                                                                                        name="container_log_orderbysetting"
                                                                                                        [options]="Column_Order_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Container_LOG_Order_By"
                                                                                                        #container_log_orderbysetting="ngModel" appendTo="body">
                                                                                                    </p-dropdown>
                                                                                                </fieldset>
                                                                                            </div>
                                                                                        </div>

                                                                                        <form #containerColList_Log
                                                                                            form="ngForm">
                                                                                            <div
                                                                                                class="col-sm-12 col-md-12 col-lg-12 nopaddingnmargin">
                                                                                                <div
                                                                                                    id="gridContainerLog">
                                                                                                    <!-- [frameworkComponents]="frameworkComponents" -->
                                                                                                    <!--AG Grid Start-->
                                                                                                    <ag-grid-angular
                                                                                                        id="containerColGridLog"
                                                                                                        #containerColGridLog
                                                                                                        [gridOptions]="gridOptionsContainerCol_Log"
                                                                                                        [columnDefs]="containerColumnDefs"
                                                                                                        [rowData]="Container_Log_Column_Setting_Grid_Data"
                                                                                                        [rowDragManaged]="true"
                                                                                                        [animateRows]="true"
                                                                                                        sortable="true"
                                                                                                        [suppressRowClickSelection]="true"
                                                                                                        filter=true
                                                                                                        [rowSelection]="rowSelection"
                                                                                                        (gridReady)="onGridReady_ContainerLOG($event)"
                                                                                                        (componentStateChanged)="preSelectGridRows($event)"
                                                                                                        style="width: 100% !important;"
                                                                                                        class="ag-theme-balham set-docgrid-width">
                                                                                                    </ag-grid-angular>
                                                                                                    <!--AG Grid End-->
                                                                                                </div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>

                                                                                    <div id="containerColList_Gen"
                                                                                        class="tab-pane fade margin-configuration"
                                                                                        [ngClass]="(isActiveTabContainerColList_Gen3==true)?'active in':''">

                                                                                        <div class="row">

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label
                                                                                                    class="label margin-top-10">
                                                                                                    General
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <fieldset>
                                                                                                    <label
                                                                                                        for="containergenquality"
                                                                                                        class="checkbox col-sm-2">
                                                                                                        <span
                                                                                                            class="onoffswitch  tablIndexFocus"
                                                                                                            [attr.tabindex]="0"
                                                                                                            (keyup.enter)="ToggleChecked_Container_Gen()">
                                                                                                            <input
                                                                                                                [checked]="Settings.Container_GENQualityStatus"
                                                                                                                type="checkbox"
                                                                                                                name="onoffswitchgen"
                                                                                                                class="onoffswitch-checkbox"
                                                                                                                [(ngModel)]="Settings.Container_GENQualityStatus"
                                                                                                                id="containergenquality">
                                                                                                            <label
                                                                                                                class="onoffswitch-label"
                                                                                                                for="containergenquality"><span
                                                                                                                    class="onoffswitch-inner"
                                                                                                                    data-swchon-text="YES"
                                                                                                                    data-swchoff-text="NO"></span><span
                                                                                                                    class="onoffswitch-switch"></span></label>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </fieldset>
                                                                                            </div>


                                                                                            <div
                                                                                                class="form-group col-sm-3">
                                                                                                <label class="label margin-top-10">
                                                                                                    Enable Quality Status column in Records module.
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Default column for sorting
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="container_gen_sortbysetting"
                                                                                                        name="container_gen_sortbysetting"
                                                                                                        [options]="Container_GEN_Sort_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Container_GEN_Sort_By"
                                                                                                        #container_gen_sortbysetting="ngModel" appendTo="body">
                                                                                                    </p-dropdown>
                                                                                                </fieldset>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Order By
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="container_gen_orderbysetting"
                                                                                                        name="container_gen_orderbysetting"
                                                                                                        [options]="Column_Order_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Container_GEN_Order_By"
                                                                                                        #container_gen_orderbysetting="ngModel" appendTo="body">
                                                                                                    </p-dropdown>

                                                                                                </fieldset>
                                                                                            </div>

                                                                                        </div>

                                                                                        <form #containerColList_Gen
                                                                                            form="ngForm">
                                                                                            <div
                                                                                                class="col-sm-12 col-md-12 col-lg-12 nopaddingnmargin">
                                                                                                <div
                                                                                                    id="gridContainerGen">
                                                                                                    <!-- [frameworkComponents]="frameworkComponents" -->
                                                                                                    <!--AG Grid Start-->
                                                                                                    <ag-grid-angular
                                                                                                        id="containerColGridGen"
                                                                                                        #containerColGridGen
                                                                                                        [gridOptions]="gridOptionsContainerCol_Gen"
                                                                                                        [columnDefs]="containerColumnDefs"
                                                                                                        [rowData]="Container_Gen_Column_Setting_Grid_Data"
                                                                                                        [rowDragManaged]="true"
                                                                                                        [animateRows]="true"
                                                                                                        sortable="true"
                                                                                                        [suppressRowClickSelection]="true"
                                                                                                        filter=true
                                                                                                        [rowSelection]="rowSelection"
                                                                                                        (gridReady)="onGridReady_ContainerGEN($event)"
                                                                                                        (componentStateChanged)="preSelectGridRows($event)"
                                                                                                        style="width: 100% !important;"
                                                                                                        class="ag-theme-balham set-docgrid-width">
                                                                                                    </ag-grid-angular>
                                                                                                    <!--AG Grid End-->
                                                                                                </div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>

                                                                                    <div id="containerColList_Doc"
                                                                                        class="tab-pane fade margin-configuration"
                                                                                        [ngClass]="(isActiveTabContainerColList_Doc4==true)?'active in':''">

                                                                                        <div class="row">

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label
                                                                                                    class="label margin-top-10">
                                                                                                    Documents
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <fieldset>
                                                                                                    <label
                                                                                                        for="containerdocquality"
                                                                                                        class="checkbox col-sm-2">
                                                                                                        <span
                                                                                                            class="onoffswitch  tablIndexFocus"
                                                                                                            [attr.tabindex]="0"
                                                                                                            (keyup.enter)="ToggleChecked_Container_Doc()">
                                                                                                            <input
                                                                                                                [checked]="Settings.Container_DOCQualityStatus"
                                                                                                                type="checkbox"
                                                                                                                name="onoffswitchdoc"
                                                                                                                class="onoffswitch-checkbox"
                                                                                                                [(ngModel)]="Settings.Container_DOCQualityStatus"
                                                                                                                id="containerdocquality">
                                                                                                            <label
                                                                                                                class="onoffswitch-label"
                                                                                                                for="containerdocquality"><span
                                                                                                                    class="onoffswitch-inner"
                                                                                                                    data-swchon-text="YES"
                                                                                                                    data-swchoff-text="NO"></span><span
                                                                                                                    class="onoffswitch-switch"></span></label>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </fieldset>
                                                                                            </div>


                                                                                            <div
                                                                                                class="form-group col-sm-3">
                                                                                                <label class="label margin-top-10">
                                                                                                    Enable Quality Status column in Records module.
                                                                                                </label>
                                                                                            </div>


                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Default column for sorting
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="container_doc_sortbysetting"
                                                                                                        name="container_doc_sortbysetting"
                                                                                                        [options]="Container_DOC_Sort_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Container_DOC_Sort_By"
                                                                                                        #container_doc_sortbysetting="ngModel" appendTo="body">
                                                                                                    </p-dropdown>
                                                                                                </fieldset>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Order By
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="container_doc_orderbysetting"
                                                                                                        name="container_doc_orderbysetting"
                                                                                                        [options]="Column_Order_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Container_DOC_Order_By"
                                                                                                        #container_doc_orderbysetting="ngModel" appendTo="body">
                                                                                                    </p-dropdown>

                                                                                                </fieldset>
                                                                                            </div>


                                                                                        </div>

                                                                                        <form #containerColList_Doc
                                                                                            form="ngForm">
                                                                                            <div
                                                                                                class="col-sm-12 col-md-12 col-lg-12 nopaddingnmargin">
                                                                                                <div
                                                                                                    id="gridContainerDoc">
                                                                                                    <!-- [frameworkComponents]="frameworkComponents" -->
                                                                                                    <!--AG Grid Start-->
                                                                                                    <ag-grid-angular
                                                                                                        id="containerColGridDoc"
                                                                                                        #containerColGridDoc
                                                                                                        [gridOptions]="gridOptionsContainerCol_Doc"
                                                                                                        [columnDefs]="containerColumnDefs"
                                                                                                        [rowData]="Container_Doc_Column_Setting_Grid_Data"
                                                                                                        [rowDragManaged]="true"
                                                                                                        [animateRows]="true"
                                                                                                        sortable="true"
                                                                                                        [suppressRowClickSelection]="true"
                                                                                                        filter=true
                                                                                                        [rowSelection]="rowSelection"
                                                                                                        (gridReady)="onGridReady_ContainerDOC($event)"
                                                                                                        (componentStateChanged)="preSelectGridRows($event)"
                                                                                                        style="width: 100% !important;"
                                                                                                        class="ag-theme-balham set-docgrid-width">
                                                                                                    </ag-grid-angular>
                                                                                                    <!--AG Grid End-->
                                                                                                </div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>

                                                                                    <div id="containerColList_Bnd"
                                                                                        class="tab-pane fade margin-configuration"
                                                                                        [ngClass]="(isActiveTabContainerColList_Bnd5==true)?'active in':''">

                                                                                        <div class="row">

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label
                                                                                                    class="label margin-top-10">
                                                                                                    Binders
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <fieldset>
                                                                                                    <label
                                                                                                        for="containerbndquality"
                                                                                                        class="checkbox col-sm-2">
                                                                                                        <span
                                                                                                            class="onoffswitch  tablIndexFocus"
                                                                                                            [attr.tabindex]="0"
                                                                                                            (keyup.enter)="ToggleChecked_Container_Bnd()">
                                                                                                            <input
                                                                                                                [checked]="Settings.Container_BNDQualityStatus"
                                                                                                                type="checkbox"
                                                                                                                name="onoffswitchbnd"
                                                                                                                class="onoffswitch-checkbox"
                                                                                                                [(ngModel)]="Settings.Container_BNDQualityStatus"
                                                                                                                id="containerbndquality">
                                                                                                            <label
                                                                                                                class="onoffswitch-label"
                                                                                                                for="containerbndquality"><span
                                                                                                                    class="onoffswitch-inner"
                                                                                                                    data-swchon-text="YES"
                                                                                                                    data-swchoff-text="NO"></span><span
                                                                                                                    class="onoffswitch-switch"></span></label>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </fieldset>
                                                                                            </div>


                                                                                            <div
                                                                                                class="form-group col-sm-3">
                                                                                                <label class="label margin-top-10">
                                                                                                    Enable Quality Status column in Transation module.
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Default column for sorting
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group col-sm-2">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="container_bnd_sortbysetting"
                                                                                                        name="container_bnd_sortbysetting"
                                                                                                        [options]="Container_BND_Sort_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Container_BND_Sort_By"
                                                                                                        #container_bnd_sortbysetting="ngModel" appendTo="body">
                                                                                                    </p-dropdown>
                                                                                                </fieldset>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <label class="label margin-top-10" style="float: right;">
                                                                                                    Order By
                                                                                                </label>
                                                                                            </div>

                                                                                            <div
                                                                                                class="form-group col-sm-1">
                                                                                                <fieldset>
                                                                                                    <p-dropdown
                                                                                                        id="container_bnd_orderbysetting"
                                                                                                        name="container_bnd_orderbysetting"
                                                                                                        [options]="Column_Order_By_Setting_Options"
                                                                                                        required
                                                                                                        [(ngModel)]="Settings.Container_BND_Order_By"
                                                                                                        #container_bnd_orderbysetting="ngModel" appendTo="body">
                                                                                                    </p-dropdown>

                                                                                                </fieldset>
                                                                                            </div>


                                                                                        </div>

                                                                                        <form #containerColList_Bnd
                                                                                            form="ngForm">
                                                                                            <div
                                                                                                class="col-sm-12 col-md-12 col-lg-12 nopaddingnmargin">
                                                                                                <div
                                                                                                    id="gridContainerBnd">
                                                                                                    <!-- [frameworkComponents]="frameworkComponents" -->
                                                                                                    <!--AG Grid Start-->
                                                                                                    <ag-grid-angular
                                                                                                        id="containerColGridBnd"
                                                                                                        #containerColGridBnd
                                                                                                        [gridOptions]="gridOptionsContainerCol_Bnd"
                                                                                                        [columnDefs]="containerColumnDefs"
                                                                                                        [rowData]="Container_Bnd_Column_Setting_Grid_Data"
                                                                                                        [rowDragManaged]="true"
                                                                                                        [animateRows]="true"
                                                                                                        sortable="true"
                                                                                                        [suppressRowClickSelection]="true"
                                                                                                        filter=true
                                                                                                        [rowSelection]="rowSelection"
                                                                                                        (gridReady)="onGridReady_ContainerBND($event)"
                                                                                                        (componentStateChanged)="preSelectGridRows($event)"
                                                                                                        style="width: 100% !important;"
                                                                                                        class="ag-theme-balham set-docgrid-width">
                                                                                                    </ag-grid-angular>
                                                                                                    <!--AG Grid End-->
                                                                                                </div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <hr />
                                                                            <br /> -->

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <br />

                                                        <div class="widget-footer">
                                                            <button type="button" class="btn btn-primary custom-button"
                                                                [disabled]="!containersettingsform.form.valid"
                                                                (click)="setSettings('Container',containersettingsform)">
                                                                Update
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <!-- end widget -->
        </article>
        <!-- END COL -->
    </div>
</div>