//Components
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { Subscription, Subject } from 'rxjs';
import * as moment from 'moment/moment';

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { datafeed } from '../../../api/components/list-datafeed/data-feed';
import { StatusConfig } from '../../../api/components/status-configuration/status-configuration';
import { APIService } from '../../../api/services/api.service';


@Component({
    selector: 'status-export',
    templateUrl: './export.component.html',
    providers: [APIService]

})



export class StatusExportComponent implements OnInit, OnDestroy {

    //#region View List  Variables




    dialoguesubscription: Subscription;
    assetSubscription: Subscription;
    currentFeedId: string;
    currentFeedDataType: string;


    //#endregion

    //#region PDF Viewer variables

    public displayConfigdialogue: boolean = false;
    public feedTypeInput: string = "ExportTemp";
    //#endregion

    //#region Initialization of Component
    constructor(private _sharedService: SharedService, private _router: Router, private _route: ActivatedRoute, private _apiService: APIService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }

    }

    ngOnInit() {


        if (this._router.url.indexOf("status/") != -1 || this._router.url.indexOf("repair/") != -1) {

            this.dialoguesubscription = this._sharedService._changeStatusConfigDialogueStatus
            .subscribe({
                next: (flag:any) => {
                    this.OpencloseConfigDialogue(flag.showDialogue)
        
                    if (flag.showMessage) {
        
                        if (this._sharedService.selectedAssetStatus && this._sharedService.selectedAssetStatus.length > 0) {
                            this._sharedService.clearToast();
                            // this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'The Compilation process has now started, a URL to download the final compilation .ZIP file will be sent to you by email.' });
        
                            setTimeout(() => {
                                if (this._router.url.indexOf("status/") != -1) {
                                    this._router.navigate(["/status/myreports"]);
                                } else {
                                    this._router.navigate(["/repair/myreports"]);
                                }
                            },
                                4000);
        
                        }
                        else {
        
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'warn', summary: 'No asset selected', detail: 'Please select asset' });
                            setTimeout(() => {
        
                                if (this._router.url.indexOf("status/") != -1) {
                                    this._router.navigate(["/status/viewlist"]);
                                } else {
                                    this._router.navigate(["/repair/viewlist"]);
                                }
                            },
                                1500);
                        }
        
        
                    }
                    else {
        
                        if (this._router.url.indexOf("status/") != -1) {
                            this._router.navigate(["/status/viewlist"]);
                        } else {
                            this._router.navigate(["/repair/viewlist"]);
                        }
                    }
                },
                error: (error) => {
                }
            });

            let routeValue: string = this._route.snapshot.data[0].recordType;
            if (routeValue != '') {
                this.currentFeedDataType = routeValue;
                this.showBulkExport(routeValue);
            }


            this.assetSubscription = this._sharedService._currentTempFeedASN
            .subscribe({
                next: (asn) => {
                    this.updateFeedAndRefreshScreen(asn)
                }
            });
        }

    }

    ngOnDestroy() {
        if (this.dialoguesubscription) {
            this.dialoguesubscription.unsubscribe();
        }
    }
    showBulkExport(templateName: string) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            if (this._sharedService.selectedAssetStatus && this._sharedService.selectedAssetStatus.length > 0) {
                this._apiService.GetFeedDataTypeFromTemplate(templateName)
                .subscribe({
                    next: (response) => {
                        let feedDataTypes = response.split(',');
                        let SourceType;
                        let feedName;
                        if (templateName == "Export Views Repair Data") {
                            feedName= "RPR_"+"LST";
                            SourceType = "Repair Export";
                        }
                        else if( templateName == "Export Views Repair Compliance Documents"){
                            feedName= "RPR_"+"DOC";
                            SourceType = "Repair Export";
                        }
                        else if(templateName == "Export Views Status Compliance Documents"){
                            feedName= "STS_"+"DOC";
                            SourceType = "Status Export";
                        }
                        else {
                            feedName= "STS_"+"LST";
                            SourceType = "Status Export"
                        }
                        let feedData: datafeed =
                        {
        
                            // DataType: feedDataTypes[0],
                            FeedName: feedName + '_' + this._apiService.getTempFeedTimeStamp(),
                            FeedType: "ExportTemp",
                            Interval: "None",
                            SourceType: SourceType,
                            //US-8181 //SourceDetail: this._sharedService.selectedAssetStatus[0].data.split('|')[1],
                            SourceDetail: JSON.parse(this._sharedService.selectedAssetStatus[0].data).ASN,
        
                            // TargetType: 'Email Link',
                            TargetDetail: '',
                            Status: true,
                            TemplateName: templateName,
                            FeedScope: "Private"
                        }
        
                        this._apiService.addFeedAndReturnFeedID(feedData)
                        .subscribe({
                            next: (response) => {
                                let feedDataId = response;
                                this.currentFeedId = feedDataId;
                                this._sharedService.statusConfig = new StatusConfig(feedDataId, feedData.FeedName, feedData.DataType, feedData.SourceDetail, feedDataTypes);
                                this.OpencloseConfigDialogue(true);
                            },
                            error: (error) => {
                                this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Error Occurred, please try again.' });
                            }
                        });
                    },
                    error:(error)=>{
                        this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Error Occurred, please try again.' });
                    }
                });
            }
            else {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'No Asset Selected', detail: 'Please select an asset' });

                let config: any = {
                    showDialogue: false,
                    showMessage: true
                };
                this._sharedService.changeStatusConfigDialogueStatus(config);

            }
        }


    }

    updateFeedAndRefreshScreen(asn: any) {

        let feedData: datafeed =
        {
            FeedId: this.currentFeedId,
            DataType: this.currentFeedDataType,
            FeedName: this._apiService.getTempFeedName(),
            FeedType: "ExportTemp",
            Interval: "None",
            SourceType: "Records",
            SourceDetail: asn,
            TargetType: 'Email Link',
            TargetDetail: '',
            Status: true
        }
        this._sharedService.showLoader('Loading...');
        this._apiService.updateTempFeed(feedData)
        .subscribe({
            next: (response) => {
                this._sharedService.feedUpdated();
                this._sharedService.statusConfig = new StatusConfig(feedData.FeedId, feedData.FeedName, feedData.DataType, feedData.SourceDetail);
                this.OpencloseConfigDialogue(true);
            },
            error: (error) => {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Error Occurred, please try again.' });
            }
        });
    }


    OpencloseConfigDialogue(flag: boolean) {
        this.displayConfigdialogue = flag;
    }
    adjustConfigDlgHeight(event: any) {
        let tskDlgfrm = document.getElementById('statusConfigDlg');
        let tskDlgfrm1 = document.getElementById('tblStatusMapping');
        if (tskDlgfrm) {
            tskDlgfrm.style.height = '543px';
            tskDlgfrm1.style.height = '60vh';
            tskDlgfrm.style.overflowX = 'hidden';
        }
    }
    // #endregion
}
