import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchighlight'
})
export class SearchighlightPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!args) { return value; }
    
    try {
      var re = new RegExp(args, 'gi');
      return value.replace(re, "<mark>$&</mark>");
    }
    catch (error) {
      return value;//.replace(re, "<mark>$&</mark>");

    }

  }

}
