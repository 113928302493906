//Components
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';
import * as moment from 'moment/moment';
import { GridOptions, GridApi, ExcelRow } from 'ag-grid-community';
import { IConfig } from '../../../shared/helpers/config';

import { ReportService } from '../../services/report.service';
import { CustomDateComponent } from '../../../shared/components/custom-date/custom-date.component';
var dateFormatF: string;
var dateSeparatorS: string;
var dayD: number;
var monthM: number;
var yearY: number;
var jobReportTimeout: any;
@Component({
    selector: 'job-report',
    templateUrl: './job.component.html',
    providers: [ReportService]
})

export class JobReportComponent implements OnInit, OnDestroy {
    //@Input() recordType: string;
    recordType: string;
    jobId: string = "";
    selectedAsset: any;
    totalRecords: Number;
    columnDefs: any[];
    //tempCols: any[];
    public gridOptions: GridOptions;
    public gridData: any[] = [];
    public totalCount: number;
    public browseRowData: any;
    public dateConfig: IConfig[] = [];
    public isAddVisible: boolean = false;
    public isEditVisible: boolean = false;
    //public isHideLiveRecords: number = 1;
    public isEditVisibleReadonly: boolean = false;
    isCurrentUserOnly: boolean = false;
    userType: string = "";

    //public excelStyles: ExcelStyle;
    public excelStyles: any;
    public gridApiJob: GridApi;
    public selectedRowData: any = null;
    recordLastUpdated: string = '';
    updatingRecord: boolean = false;
    public processState: string = '';
    //#region Component Initilization
    constructor(public _sharedService: SharedService, public _router: Router, public _route: ActivatedRoute, private reportService: ReportService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.dateConfig = this._sharedService.getConfiguration();
            this.userType = this._sharedService.getCurrentUserType();
        }
    }

    ngOnInit() {
        if (!this.dateConfig || this.dateConfig.length == 0) {
            this._sharedService.getConfigurationFromDB(this._sharedService.baseUrl, null)
                .subscribe({
                    next: (response) => {
                        this._sharedService.setConfiguration(response as IConfig[]);
                        this.dateConfig = response;
                        this.getDateFormatInfo();
                        this.setGridOptions();
                        this.populateJobGrid();
                    },
                    error: (error) => {
                        console.log(error);
                    }
                })
        }
        else {
            this.getDateFormatInfo();
            this.setGridOptions();
            this.populateJobGrid();
        }
        this.setExcelStyle();
    }
    setGridOptions() {
        this.gridOptions = <GridOptions>
            {
                rowSelection: 'single',
                onGridReady: (params) => {
                    this.setDpJobGridColumns();
                    if (this.gridOptions.api != undefined) {
                        if (this.gridData.length > 0) {
                            //this.gridOptions.api.paginationSetPageSize(this._sharedService.getReportGridPageSize('agGridlistJobsReport'));
                            this.gridOptions.api.hideOverlay();
                        }
                        if (this.gridOptions.api != undefined) {
                            if (this.gridData.length > 0) {
                                this.gridOptions.api.hideOverlay();
                                try {
                                    this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridlistJobsReport'));
                                    this.gridOptions.api.hideOverlay();
                                } catch (e) {
                                    console.log(e);
                                }
                            }
                        }
                    }
                    if (params.api) {
                        this.gridApiJob = params.api;

                    }
                },
                onModelUpdated: (event: any) => {
                    if (this.gridOptions.api) {
                        this.totalCount = this.gridOptions.api.getDisplayedRowCount();
                        this.gridOptions.api.sizeColumnsToFit();
                    }

                },
                //suppressContextMenu: true,
                pagination: true,
                paginationPageSize: 19,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                },
                components: { agDateInput: CustomDateComponent },
                rowGroupPanelShow: 'always',
                suppressMakeColumnVisibleAfterUnGroup: true,
                suppressDragLeaveHidesColumns: true
            }
    }
    ngOnDestroy() {
        if (jobReportTimeout) {
            clearTimeout(jobReportTimeout);
        }
    }
    refresh() {

        this.populateJobGrid();
    }
    runJob() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            let requestBody: any = {
                TenantId: 0,
                TableId: 2
            };
            this.reportService.runCopyDataUtility(requestBody)
                .subscribe({
                    next: (response) => {
                        let body = response;
                        this._sharedService.clearToast();
                        if (body.indexOf("Success") != -1) {
                            this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Request processed successfuly.' });

                            this.populateJobGrid();
                        }
                        else {
                            this._sharedService.showToast({ severity: 'error', summary: 'Request failed', detail: 'Request to run Job report is un-successful.' });
                        }

                        //this._sharedService.clearToast();
                        //this._sharedService.showToast({ severity: 'success', summary: 'Feed started', detail: 'Request to run feed is successful.' });
                    },
                    error: (error) => {
                        if (error.status === 409) {
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'warn', summary: 'Warning', detail: 'Already in progress.' });
                        }
                        else {
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'error', summary: 'Request failed', detail: 'Request to run Job report is un-successful.' });
                        }
                    }
                })

        }

    }
    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateFormatF.toUpperCase()).split(dateSeparatorS);
            var day = Number(dateParts[Number(dayD)].substring(0, 2));
            var month = Number(dateParts[Number(monthM)]) - 1;
            var year = Number(dateParts[Number(yearY)]);
            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        }
        else {
            return -1;
        }
    }
    //#endregion

    //#region Ag Grid Population Event
    populateJobGrid(): void {
        this.updatingRecord = true;
        this.reportService.getJobReport()
        .subscribe({
            next: (response) => {
                this.updatingRecord = false;
                this.gridData = [];
                this.gridData = response[0];
                this.lastRun(response[1]);
                this.processState = response[2];
        
                jobReportTimeout = setTimeout(() => {
                    if (this.processState == 'In progress') {
                        this.populateJobGrid();
                    }
                }, 7000);
                this.totalCount = this.gridData.length;
            },
            error: (error) => {
                this.updatingRecord = false;
                console.log(error);
                //    this._sharedService.hideLoader();
            }
        });
    }

    reBindGrid(selAsset: any) {
        this.populateJobGrid();
    }

    getDateFormatTime(date: any) {
        if (date) {
            return moment.utc(date).format("DD-MM-YYYY h:mm a").toString() + ' (GMT)';
        } else {
            return '';
        }
    }
    //#endregion

    lastRun(lastRunValue: string) {
        this.recordLastUpdated = lastRunValue;
    }
    getFormatedDate(date: any) {
        if (date) {
            return moment(date).format(this._sharedService.getDateTimeFormatConfiguration().dateformat.toUpperCase());
        } else {
            return '';
        }
    }

    getFilterString(): string {
        let filterModel = this.gridOptions.api.getFilterModel();
        let filterString = '';
        for (var filter in filterModel) {
            filterString = filterString.concat(filter, ', ');
        }

        return filterString ? filterString.slice(0, -2) : 'No';
    }
    //#endregion
    // #region All Jobs or Current Jobs Switch Events
    showAllUsersRecords() {
        this.populateJobGrid();
    }
    //#endregion

    //#region Set Columns of Browse Ag Grid
    setDpJobGridColumns(): void {
        this.columnDefs = [];
        this.columnDefs =
            [
                {
                    headerName: 'Job #', field: 'DpJobId', filter: 'set', menuTabs: ['filterMenuTab'], enableRowGroup: true
                },
                {
                    headerName: 'Created On', field: 'CreatedOn', filter: 'agDateColumnFilter', enableRowGroup: true, filterParams: {
                        comparator: this.dateComparater,
                        inRangeInclusive: 'true'

                    }, valueFormatter: function (params: any) {
                        if (params.value != "" && params.value != null) {

                            let dateString = params.value.substring(0, 10);
                            return moment(dateString).format(dateFormatF.toUpperCase());
                        }
                    }, menuTabs: ['filterMenuTab'], minWidth: 80,
                },
                {
                    headerName: 'Priority', field: 'Priority', filter: 'set', enableRowGroup: true, menuTabs: ['filterMenuTab'], valueFormatter: function (params: any) {
                        if (params.value != "" && params.value != null) {

                            if (params.value == "1") {
                                return "High";
                            }
                            else if (params.value == "2") {
                                return "Normal";
                            }
                            else {
                                return "Low";
                            }
                        }
                    }
                },
            ];
        //    if (this.userType == 'Internal') {
        //        this.columnDefs.push({
        //            headerName: 'Assigned To', field: 'AssignedTo', filter: 'set', menuTabs: ['filterMenuTab']
        //        });
        //    }
        this.columnDefs.push(
            {
                headerName: 'Asset', field: 'Asset', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 50, hide: true
            },
            {
                headerName: 'Title', field: 'Title', filter: 'set', menuTabs: ['filterMenuTab'], minWidth: 50, enableRowGroup: true,
            },

            {
                headerName: 'Date', field: 'Date', filter: 'agDateColumnFilter', enableRowGroup: true, filterParams:
                {
                    comparator: this.dateComparater,
                    inRangeInclusive: 'true'
                }, valueFormatter: function (params: any) {
                    if (params.value != "" && params.value != null) {

                        let dateString = params.value.substring(0, 10);
                        return moment(dateString).format(dateFormatF.toUpperCase());
                    }
                }, menuTabs: ['filterMenuTab'], minWidth: 80,
            },
            {
                headerName: 'End Date', field: 'EndDate', filter: 'agDateColumnFilter', enableRowGroup: true, filterParams: {
                    comparator: this.dateComparater,
                    inRangeInclusive: 'true'

                }, valueFormatter: function (params: any) {
                    if (params.value != "" && params.value != null) {
                        let dateString = params.value.substring(0, 10);
                        return moment(dateString).format(dateFormatF.toUpperCase());
                    }
                }, menuTabs: ['filterMenuTab'], minWidth: 80,
            },
            {
                headerName: 'WO/WP No', field: 'ReferenceKey', filter: 'set', enableRowGroup: true, menuTabs: ['filterMenuTab'], minWidth: 120
            },
            {
                headerName: 'Type', field: 'Type', filter: 'set', enableRowGroup: true, menuTabs: ['filterMenuTab'], minWidth: 50,
            },
            {
                headerName: 'Document Count', field: 'Documents', enableRowGroup: true, filter: 'set', menuTabs: ['filterMenuTab']
            },
            {
                headerName: 'Page Count', field: 'Pages', filter: 'set', enableRowGroup: true, menuTabs: ['filterMenuTab']
            },
            {
                headerName: 'Status', field: 'DpJobStatus', filter: 'set', enableRowGroup: true, menuTabs: ['filterMenuTab']
            },

            {
                headerName: 'Created By', field: 'CreatedBy', filter: 'set', enableRowGroup: true, menuTabs: ['filterMenuTab']
            },
            { headerName: 'Tenant', field: 'TenantName', filter: 'text', enableRowGroup: true, menuTabs: ['filterMenuTab'] },

        );
    }

    //#endregion

    // #region date Format Settings

    getDateFormatInfo(): any {

        if (this.dateConfig != undefined) {
            dateFormatF = this.dateConfig.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
            dateSeparatorS = this.dateConfig.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
            dayD = Number(this.dateConfig.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
            monthM = Number(this.dateConfig.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
            yearY = Number(this.dateConfig.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
        }
        else {
            dateFormatF = localStorage.getItem('DateFormat');
            dateSeparatorS = localStorage.getItem('DateSeparater');
            dayD = Number(localStorage.getItem('DayIndex'));
            monthM = Number(localStorage.getItem('MonthIndex'));
            yearY = Number(localStorage.getItem('YearIndex'));

        }
    }


    // #endregion

    //#region Roles

    //#endregion

    //#region Pagination Logic
    getGridPageSize(): number {
        let defaultSize: number = 20;
        let gridHeight = document.getElementById('agGridlistJobs').clientHeight;
        try {
            defaultSize = (gridHeight / 27);
        } catch (e) {
            console.log(e);
        }
        let size: number = Math.round(defaultSize);

        return size - 1;
    }
    //#endregion
    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];
        let childResult: any[] = [];
        if (params.context.gridContext.isAddVisible) {
            result.push({
                name: "Add Job",
                action: function () {

                    params.context.gridContext._sharedService.addJob(null);
                },
                icon: params.context.gridContext._sharedService.addIconHTMLasString,
            });
        }
        if (params.context.gridContext.isEditVisible || params.context.gridContext.isEditVisibleReadonly) {
            result.push({
                name: "Edit Job",
                action: function () {
                    params.context.gridContext.jobId = null;
                    if (params.node) {
                        params.context.gridContext.jobId = params.node.data.DpJobId;
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext._sharedService.updateJobUsingId(params.context.gridContext.jobId);
                    }

                },
                icon: params.context.gridContext._sharedService.editIconHTMLasString,
            });
        }

        childResult.push({
            name: "Excel",
            action: function () {
                params.context.gridContext.exportExcelGrid();
            },
            icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
        });

        childResult.push({
            name: "CSV",
            action: function () {

                params.context.gridContext.exportGridData();

            },
            icon: params.context.gridContext._sharedService.saveLogFeedIconHTMLasString,
        });

        result.push({
            name: "Download",
            action: function () {


            },
            icon: params.context.gridContext._sharedService.downloadsFeedIconHTMLasString,
            subMenu: childResult,
        });

        if (params.context.gridContext.userType == 'Internal') {
            if (params.context.gridContext.isCurrentUserOnly) {
                result.push({
                    name: "All Jobs",
                    action: function () {
                        params.context.gridContext.isCurrentUserOnly = false;
                        params.context.gridContext.showAllUsersRecords();
                    },
                    icon: params.context.gridContext._sharedService.jobsIconHTMLasString,

                });
            }
            else {
                result.push({
                    name: "My Jobs",
                    action: function () {
                        params.context.gridContext.isCurrentUserOnly = true;
                        params.context.gridContext.showAllUsersRecords();
                    },
                    icon: params.context.gridContext._sharedService.jobsIconHTMLasString,
                });
            }

        }
        return result;


    }
    onJobsGridExport() {
        let headerString = null
        var params: any = {
            olumnBestFit: true,
            skipColumnGroupHeaders: false,
            allColumns: false,
            onlySelected: false,
            fileName: 'Jobs-Report-' + moment(Date.now()).format('YYYYMMDD') + '.xlsx',
            sheetName: 'Report',
            processCellCallback: function (params: any) {
                if (params.column.colDef.field == 'Date' || params.column.colDef.field == 'EndDate' || params.column.colDef.field == 'CreatedOn') {
                    return params.value ? params.value.substring(0, 10) : params.value;
                }
                else {
                    return params.value;
                }
            }
        };
        const getRows: () => ExcelRow[] = () => JSON.parse(headerString);
        params.prependContent = getRows();
        var api = this.gridApiJob;
        api.exportDataAsExcel(
            params
        );
    }
    setExcelStyle() {
        this.excelStyles = [
            {
                id: "header",
                interior: {
                    color: "#CCCCCC",
                    pattern: "Solid"
                }
            },
            {
                id: "twoDecimalPlaces",
                numberFormat: { format: "#,##0.00" }
            },
            {
                id: "textFormat",
                dataType: "string"
            },
        ];
    }

    //#endregion

    //#region Helper Methods

    //#endregion

}
