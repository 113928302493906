//#region References

import { Component, Input, OnInit, OnDestroy} from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { APIService } from '../../services/api.service';
import { datafeed, feedRunRequestEntity } from '../../components/list-datafeed/data-feed';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { GridOptions, GridApi, RowNode, RowDataTransaction, IRowNode } from 'ag-grid-community';
import 'ag-grid-enterprise';
import { Subscription } from 'rxjs';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';

import * as moment from 'moment/moment';
//import { RowDataTransaction } from 'ag-grid-community/dist/lib/rowModels/clientSide/clientSideRowModel';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { CustomDateComponent } from '../../../shared/components/custom-date/custom-date.component';
import { HelpService } from '../../../shared/services/Help/help.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ExportContainerConfigurationComponent } from '../export-container-configuration/export-container-configuration.component';
var _router_: Router;
var selectedFeedId: string = "";

var dateTimeConfig: IDateFormatInfo;
var gridTimeout: any[] = [];
//#endregion

//#region Type Decorator
@Component({
  selector: 'list-datafeed',
  templateUrl: './list-datafeed.component.html',
  providers: [APIService, HelpService,DialogService]
})
//#endregion

export class ListDataFeedComponent implements OnInit, OnDestroy {
  //#region Locals Declaration

  recordType: string;
  feedId: "";


  columnDefs: any[];
  public gridData: any[] = [];
  public selectedRowData: any = null;
  public totalCount: number = 0;

  public gridOptions: GridOptions;
  subscription: Subscription;
  subscriptionMappingDialogue: Subscription;
  subscriptionFeedLogDialogue: Subscription;
  subscriptionRecordConfigDialogue: Subscription;
  subscriptionStatusConfigDialogue: Subscription;

  public isAddVisibleIncoming: boolean = false;
  public isEditVisibleIncoming: boolean = false;
  public isMappingVisibleIncoming: boolean = false;
  public isLogVisibleIncoming: boolean = false;

  public isAddVisibleOutgoing: boolean = false;
  public isEditVisibleOutgoing: boolean = false;
  public isMappingVisibleOutgoing: boolean = false;
  public isLogVisibleOutgoing: boolean = false;

  public isAddVisibleIncomingStatus: boolean = false;
  public isEditVisibleIncomingStatus: boolean = false;
  public isMappingVisibleIncomingStatus: boolean = false;
  public isLogVisibleIncomingStatus: boolean = false;
  public isFeedDataRetrieved: boolean = false;
  public API_Import_Readonly: boolean = false;
  public API_Export_Readonly: boolean = false;
  public isFeedMappingOfChildComponent=false;

  public col: string[] = this.getListOfRefresehCol();
  getRowNodeId: any;
  public displayMapping: boolean = false;
  public displayFeedLog: boolean = false;
  @Input() feedType: string = null;
  @Input() viewId: string = null;
  @Input() targetType: string = null;
  @Input() viewDataType: string = null;


  displayRecordsExport: boolean = false;
  displayStatusExport: boolean = false;
  public appHotkeys: Hotkey[] = [];

  public feedTempleInterval: string = "";
  public feedTempleMapping: string = "";
  public listIcon = '';
  public componentName = 'APII';
  public shoePlayButton: boolean = true;
  getFeedSubsription: Subscription;
  //#endregion
  updatingRecord: boolean = false;
  exportContainerConfigRef: DynamicDialogRef;
  //#region Component Initilization
  constructor(public _sharedService: SharedService, public _apiService: APIService, public _router: Router, public _route: ActivatedRoute, private _hotkeysService: HotkeysService,
    private _dialogService: DialogService) {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(['login']);
      return;
    }
    else {
      dateTimeConfig = this._sharedService.getDateTimeFormatConfiguration();
      this._sharedService.updateAssignedRole();
      this.UpdateRoles();
      this.setHotKeys();
      _router_ = this._router;
      if (_router_.url == '/apifeed/import') {
        this.listIcon = 'glyphicon glyphicon-import';   
        this._sharedService.UpdateFeedMappingComponentValue(false);
      }
      else {
        this.listIcon = 'glyphicon glyphicon-export'
        this.componentName = 'APIE'
      }
      this.getRowNodeId = function (params: any) {
        selectedFeedId = params.data.FeedId;
        return params.data.FeedId;
      };
      this.navigateToNextCell = this.navigateToNextCell.bind(this);

      this._sharedService.isFeedMappingOfChildComponent$.subscribe((value) => {
        this.isFeedMappingOfChildComponent = value;
      });
    }
  }

  setHotKeys() {

    let _hotKeyObj: Hotkey = null;

    let hotKeyObj = this._hotkeysService.get('ctrl+x');
    if (hotKeyObj == null || hotKeyObj == undefined) {
      //feed log
      _hotKeyObj = new Hotkey('ctrl+x', (event: KeyboardEvent): boolean => {

        this.ExportFeed();
        return false;
      });
      this._hotkeysService.add(_hotKeyObj);
      this.appHotkeys.push(_hotKeyObj);
    }
    hotKeyObj = this._hotkeysService.get('ctrl+shift+l');
    if (hotKeyObj == null || hotKeyObj == undefined) {
      //feed log
      _hotKeyObj = new Hotkey('ctrl+shift+l', (event: KeyboardEvent): boolean => {
        if (_router_.url == "/apifeed/import" || _router_.url == "/apifeed/export") {
          this.openFeedLog();
        }
        return false; // Prevent bubbling
      });
      this._hotkeysService.add(_hotKeyObj);
      this.appHotkeys.push(_hotKeyObj);
    }
    hotKeyObj = this._hotkeysService.get('ctrl+m');
    if (hotKeyObj == null || hotKeyObj == undefined) {
      //Mapping
      _hotKeyObj = new Hotkey('ctrl+m', (event: KeyboardEvent): boolean => {
        if (_router_.url == "/apifeed/import") {
          this.openFieldMapping();
        }
        return false; // Prevent bubbling
      });
      this._hotkeysService.add(_hotKeyObj);
      this.appHotkeys.push(_hotKeyObj);
    }
    hotKeyObj = this._hotkeysService.get('ctrl+c');
    if (hotKeyObj == null || hotKeyObj == undefined) {
      //Configuration
      _hotKeyObj = new Hotkey('ctrl+c', (event: KeyboardEvent): boolean => {
        if (_router_.url == "/apifeed/export") {
          this.openConfiguration();
        }
        return false; // Prevent bubbling
      });
      this._hotkeysService.add(_hotKeyObj);
      this.appHotkeys.push(_hotKeyObj);
    }
    hotKeyObj = this._hotkeysService.get('ctrl+shift+u');
    if (hotKeyObj == null || hotKeyObj == undefined) {
      //Run feed
      _hotKeyObj = new Hotkey('ctrl+shift+u', (event: KeyboardEvent): boolean => {
        if (_router_.url == "/apifeed/import") {

          this.runFeed();
        }
        if (_router_.url == "/apifeed/export") {
          this.runOutgoingFeed();
        }
        return false; // Prevent bubbling
      });

      this._hotkeysService.add(_hotKeyObj);
      this.appHotkeys.push(_hotKeyObj);
    }

  }

  ngOnInit() {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(['login']);
      return;
    }
    if (this.feedType && this.feedType == "Import") {
      this.recordType = this.feedType;
    }
    else {
      this.recordType = this._route.snapshot.data[0].recordType;
    }

    if (this.recordType == "Import" && this.API_Import_Readonly == false) {
      this._router.navigate(['/apifeed/export']);
    }

    this.gridOptions = <GridOptions>
      {
        rowSelection: 'single',
        rowData: [],
        onGridReady: () => {
          this.setDataFeedGridColumns();
          if (this.gridOptions.api != undefined) {
            if (this.gridData.length > 0) {
              this.gridOptions.api.hideOverlay();
              try {
                this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridFeedData'));
                this.gridOptions.api.hideOverlay();
              } catch (e) {
                console.log(e);
              }
            }
          }
        },
        defaultColDef: {
          sortable: true,
          resizable: true,
          wrapText: true
        },
        components: { agDateInput: CustomDateComponent },
        onModelUpdated: (event: any) => {
          // this.gridOptions.api.sizeColumnsToFit();
          if (this.gridOptions.api) {
            this.totalCount = this.gridOptions.api.getDisplayedRowCount();
            this.gridOptions.api.sizeColumnsToFit();
          }
        },
        //suppressContextMenu: true,
        pagination: true,
        paginationPageSize: 20,
        getContextMenuItems: this.getCustomContextMenuItems,
        context: {
          gridContext: this
        }
      }

    this.populateDataFeedGrid();

    this.subscription = this._sharedService._isRefreshDataFeedGrid
      .subscribe({
        next: (status) => {
          this.reBindGrid(status)
        },
        error: (error) => {
        }
      });

    this.subscriptionMappingDialogue = this._sharedService._openCloseMappingDialogue
      .subscribe({
        next: (status) => {
          this.openCloseMappingDialogue(status)
        },
        error: (error) => {
        }
      });

    this.subscriptionFeedLogDialogue = this._sharedService._openCloseFeedLogDialogue
      .subscribe({
        next: (status) => {
          this.openCloseFeedLogDialogue(status)
        },
        error: (error) => {
        }
      });

    this.subscriptionRecordConfigDialogue = this._sharedService._openCloseRecordConfigDialogue
      .subscribe({
        next: (status) => {
          this.openCloseRecordConfigurationDialogue(status)
        },
        error: (error) => {
        }
      });

    this.subscriptionStatusConfigDialogue = this._sharedService._openCloseStatusConfigDialogue
      .subscribe({
        next: (status) => {
          this.openCloseStatusConfigurationDialogue(status)
        },
        error: (error) => {
        }
      });
  }
  //#endregion

  //#region Component Destroy
  ngOnDestroy() {
    if (this.getFeedSubsription) {
      this.getFeedSubsription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscriptionMappingDialogue) {
      this.subscriptionMappingDialogue.unsubscribe();
    }
    if (this.subscriptionFeedLogDialogue) {
      this.subscriptionFeedLogDialogue.unsubscribe();
    }
    if (this.subscriptionRecordConfigDialogue) {
      this.subscriptionRecordConfigDialogue.unsubscribe();
    }
    if (this.subscriptionStatusConfigDialogue) {
      this.subscriptionStatusConfigDialogue.unsubscribe();
    }
    if (gridTimeout && gridTimeout.length > 0) {
      gridTimeout.forEach(element => {
        clearTimeout(element);
      });
      gridTimeout = [];
    }

    this.appHotkeys.forEach(hotkey => {
      //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
      this._hotkeysService.remove(hotkey);
    });
    this.appHotkeys = [];
  }

  //#endregion

  //#region Open Feed Log
  openFeedLog() {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(['login']);
    }
    else {
      if (this.feedId) {
        let id = this.feedId; //this.feedId;
        let rowNode: IRowNode = this.gridOptions.api.getRowNode(this.selectedRowData.node.id);
        if (rowNode && (rowNode.data.SourceType == 'Status'))// || rowNode.data.SourceType == 'Shared Folder'))
        //    || rowNode.data.SourceType =='FTP'
        {
          this._sharedService.clearToast();
          this._sharedService.showToast({ severity: 'warn', summary: 'Not Allowed', detail: `Not functional for ${rowNode.data.SourceType}` });
        }
        else {
          let screen = this.recordType;
          // this._router.navigate(['/apifeed/feedlog', screen, id]);
          this.openCloseFeedLogDialogue(true);
        }
      }
      else {
        this._sharedService.clearToast();
        this._sharedService.showToast({ severity: 'warn', summary: 'No Data Feed Selected', detail: 'Please select a record.' });
      }
    }

  }

  openCloseFeedLogDialogue(flag: boolean) {
    this.displayFeedLog = flag;
  }
  //#endregion

  //#region Run Feed Manually
  runFeed() {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(['login']);
    }
    else {
      if (this.feedId) {

        this.updatingRecord = true;
        let body: feedRunRequestEntity = {
          FeedId: this.feedId,
          ASN: null,
          TenantId: null,
          ViewDataType: null
        }

        let selectedRow = this.gridOptions.api.getSelectedNodes()[0].data;
        if (selectedRow && selectedRow.Toolbox == true) {
          this._sharedService.clearToast();
          this._sharedService.showToast({ severity: 'warn', summary: 'Unable to execute', detail: 'This feed will be executed from toolbox.' });
          return;

        }

        if (selectedRow) {
          let hasASNToken = false;
          let hasDataTypeToken = false;
          let feedMapping = null;
          if (selectedRow.Mapping == null) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Unable to execute', detail: 'No mapping exist for this feed' });
            return;

          }
          if (selectedRow.Mapping) {
            feedMapping = selectedRow.Mapping.toString();
            hasASNToken = (feedMapping.indexOf(this._sharedService.Token_ASN) != -1);
            hasDataTypeToken = (feedMapping.indexOf(this._sharedService.Token_DataType) != -1);

          }

          if (this._router.url.indexOf("/apifeed/import") != -1) {
            if (hasASNToken || hasDataTypeToken) {
              this._sharedService.clearToast();
              this._sharedService.showToast({ severity: 'warn', summary: 'Unable to execute', detail: 'Tokens are used in mapping, this feed can not be executed from API module.' });
              return;
            }
          }
          else if (this._router.url.indexOf("/status/viewlist/") != -1 || this._router.url.indexOf("/repair/viewlist/") != -1) {
            if (hasDataTypeToken) {
              if (!this.viewDataType) {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Unable to execute', detail: 'View does not have data type' });
                return;
              }
              else { body.ViewDataType = this.viewDataType; }
            }

            if (hasASNToken) {
              if (!(this._sharedService.selectedAsset && this._sharedService.selectedAsset.length > 0)) {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Unable to execute', detail: 'Unable to find Asset' });
                return;
              } else { body.ASN = JSON.parse(this._sharedService.selectedAsset[0].data).ASN; }
            }

          }
          else if (this._router.url.indexOf("/record/") != -1 || this._router.url.indexOf("/transition/") != -1) {
            if (hasASNToken) {
              if (!(this._sharedService.selectedAsset && this._sharedService.selectedAsset.length > 0)) {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Unable to execute', detail: 'Unable to find Asset' });
                return;
              } else { body.ASN = JSON.parse(this._sharedService.selectedAsset[0].data).ASN; }
            }

          }
        }

        if (selectedRow && selectedRow.Status) {
          this._apiService.runFeed(body)
            .subscribe({
              next: (response) => {
                let body = response;
                this._sharedService.clearToast();
                if (body.indexOf("Success") != -1) {
                  this._sharedService.showToast(
                    { 
                      severity: 'success', 
                      summary: 'Success', 
                      detail: 'Request to process feed started.' 
                    }
                  );

                  this.populateDataFeedGrid();
                  //setTimeout(() => {
                  //    this.populateDataFeedGrid();
                  //}, 10000);
                }
                else if (body.indexOf("In Active") != -1) {
                  this._sharedService.showToast(
                    { 
                      severity: 'warn', 
                      summary: 'Feed Status Inactive', 
                      detail: 'Change the Feed Status to Active.' 
                    }
                  );
                }
                else if (body.indexOf("In progress") != -1) {
                  this._sharedService.showToast(
                    { 
                      severity: 'warn', 
                      summary: 'Feed Status In progress', 
                      detail: 'Another feed is in progress.' 
                    }
                  );
                }
                else if (body.indexOf("Failed") != -1) {
                  this._sharedService.showToast(
                    { 
                      severity: 'error', 
                      summary: 'Feed failed to start', 
                      detail: 'Request to run feed is un-successful.' 
                    }
                  );
                }
                else if (body.indexOf("Access Denied") != -1) {
                  this._sharedService.showToast(
                    { 
                      severity: 'error', 
                      summary: 'Access Denied', 
                      detail: 'Access Denied on this feed.' 
                    }
                  );
                }
                else {
                  this._sharedService.showToast(
                    { 
                      severity: 'error', 
                      summary: 'Feed failed to start', 
                      detail: 'Request to run feed is un-successful.' 
                    }
                  );
                }

                //this._sharedService.clearToast();
                //this._sharedService.showToast({ severity: 'success', summary: 'Feed started', detail: 'Request to run feed is successful.' });
              },
              error: (error) => {
              }
            });
        }
        else {
          this._sharedService.clearToast();
          this._sharedService.showToast(
            { 
              severity: 'warn', 
              summary: 'Feed Status Inactive', 
              detail: 'Change the Feed Status to Active.' 
            }
          );

        }
      }
      else {
        this._sharedService.clearToast();
        this._sharedService.showToast(
          { 
            severity: 'warn', 
            summary: 'No Data Feed Selected', 
            detail: 'Please select a record.' 
          }
        );
      }
    }
  }

  runOutgoingFeed() {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(['login']);
    }
    else {
      if (this.feedId) {
        let feedData: any = { FeedId: this.feedId };
        this._apiService.getFeedDataByID(feedData)
          .subscribe({
            next: (response) => {
              let selectedRow = this.gridOptions.api.getSelectedNodes()[0].data;
              if (selectedRow && selectedRow.Toolbox == true) {
                this._sharedService.clearToast();
                this._sharedService.showToast(
                  {
                    severity: 'warn',
                    summary: 'Unable to execute',
                    detail: 'This feed will be executed from toolbox.'
                  }
                );
                return;
              }
              if (response.Mapping == null) {
                this._sharedService.clearToast();
                this._sharedService.showToast(
                  {
                    severity: 'warn',
                    summary: 'Unable to execute',
                    detail: 'No mapping exist for this feed'
                  }
                );
                return;
              }
              if (selectedRow && selectedRow.Status) {
                this._apiService.runOutgoingFeed(this.feedId)
                  .subscribe({
                    next: (response) => {
                      let body = response;
                      this._sharedService.clearToast();
                      if (body.indexOf("Success") != -1) {
                        this._sharedService.showToast(
                          {
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Request to process feed started.'
                          }
                        );

                        this.populateDataFeedGrid();
                        //setTimeout(() => {
                        //    this.populateDataFeedGrid();
                        //}, 10000);
                      }
                      else if (body.indexOf("Maximum limit exceeded") != -1) {
                        this._sharedService.showToast(
                          {
                            severity: 'warn',
                            summary: 'Maximum limit exceeded',
                            detail: 'Maximum limit for Email and My Report is 300 documents.'
                          }
                        );
                      }
                      else if (body.indexOf("Status limit exceeded") != -1) {
                        this._sharedService.showToast(
                          {
                            severity: 'warn',
                            summary: 'Maximum limit exceeded',
                            detail: 'Maximum limit for Email and My Report is 300 documents.'
                          }
                        );
                      }
                      else if (body.indexOf("In Active") != -1) {
                        this._sharedService.showToast(
                          {
                            severity: 'warn',
                            summary: 'Feed Status Inactive',
                            detail: 'Change the Feed Status to Active.'
                          }
                        );
                      }
                      else if (body.indexOf("In progress") != -1) {
                        this._sharedService.showToast(
                          {
                            severity: 'warn',
                            summary: 'Feed Status In progress',
                            detail: 'Another feed is in progress.'
                          }
                        );
                      }
                      else if (body.indexOf("Failed") != -1) {
                        this._sharedService.showToast(
                          {
                            severity: 'error',
                            summary: 'Feed failed to start',
                            detail: 'Request to run feed is un-successful.'
                          }
                        );
                      }
                      else if (body.indexOf("Access Denied") != -1) {
                        this._sharedService.showToast(
                          {
                            severity: 'error',
                            summary: 'Access Denied',
                            detail: 'Access Denied on this feed.'
                          }
                        );
                      }
                      else {
                        this._sharedService.showToast(
                          {
                            severity: 'error',
                            summary: 'Feed failed to start',
                            detail: 'Request to run feed is un-successful.'
                          }
                        );
                      }
                    },
                    error: (error) => {
                    }
                  })
              }
              else {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Feed Status Inactive', detail: 'Change the Feed Status to Active.' });

              }
            },
            error: (error) => {
              this._sharedService.clearToast();
              this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again' });
            }
          });
      }
      //if no id is selected
      else {
        this._sharedService.clearToast();
        this._sharedService.showToast({ severity: 'warn', summary: 'No Data Feed Selected', detail: 'Please select a record.' });
      }
    }
  }


  //#endregion
  //#region Open Field Mapping
  openFieldMapping() {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(['login']);
    }
    else {

      if (this.feedId) {
        // let id = this.feedId;//this.feedId;
        // let screen = this.recordType;
        this._sharedService.selectedFeedRowData = null;
        let rowNode: IRowNode = this.gridOptions.api.getRowNode(this.selectedRowData.node.id);
        this._sharedService.selectedFeedRowData = rowNode.data;
        this.openCloseMappingDialogue(true);
        //  this._router.navigate(['/apifeed/fieldmapping', screen, id]);
      }
      else {
        this._sharedService.clearToast();
        this._sharedService.showToast({ severity: 'warn', summary: 'No Data Feed Selected', detail: 'Please select a record.' });
      }
    }
  }

  openCloseMappingDialogue(flag: boolean) {
    this.displayMapping = flag;
  }
  //#endregion

  //#region Open Configuration
  openConfiguration() {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(['login']);
      return;
    }
    else {
      if (this.feedId) {
        let id = this.feedId; //this.feedId;
        let screen = this.recordType.toLowerCase();
        let dataRow = this.gridOptions.api.getSelectedRows();
        
        if (dataRow.length > 0) {
          this._sharedService.selectedOutgoingFeed = dataRow[0];
          let templateMapping = dataRow[0].TemplateMapping;
          switch (templateMapping) {
            case '{StatusViews}':
            case '{RepairViews}':
              //this._router.navigate(['/apifeed/status', screen, id]);
              this._sharedService.selectedFeedRowData = null;
              let rowNodeStatusExp: IRowNode = this.gridOptions.api.getRowNode(this.selectedRowData.node.id);
              this._sharedService.selectedFeedRowData = rowNodeStatusExp.data;
              this.openCloseStatusConfigurationDialogue(true);
              break;
            case '{ContainerSelection}':
              //this._router.navigate(['/apifeed/records', screen, id]);
              this._sharedService.selectedFeedRowData = null;
              let rowNodeRecordExp: IRowNode = this.gridOptions.api.getRowNode(this.selectedRowData.node.id);
              this._sharedService.selectedFeedRowData = rowNodeRecordExp.data;
              this.openCloseRecordConfigurationDialogue(true);
              break;
            case 'Blank Source Target':
              this._sharedService.selectedFeedRowData = null;
              let rowNode: IRowNode = this.gridOptions.api.getRowNode(this.selectedRowData.node.id);
              this._sharedService.selectedFeedRowData = rowNode.data;
              this.openCloseMappingDialogue(true);
              break;
            case '{AssetSelection}':
              this._sharedService.selectedFeedRowData = null;
              let rowNodeContainer: IRowNode = this.gridOptions.api.getRowNode(this.selectedRowData.node.id);

              this._sharedService.selectedFeedRowData = rowNodeContainer.data;
              this.selectedFeedName = rowNodeContainer.data.FeedName;
              this.showExportContainerConfiguration(rowNodeContainer.data);
              break;
          }
        }
      }
      else {
        this._sharedService.clearToast();
        this._sharedService.showToast({ severity: 'warn', summary: 'No Data Feed Selected', detail: 'Please select a record.' });
      }
    }
  }

  showContainerExport: boolean = false;
  selectedFeedName: string;
  showExportContainerConfiguration(feedData: any) {
    this.showContainerExport = true;
    return
    this._dialogService.open(
      ExportContainerConfigurationComponent, {
      header: 'Configuraion',
      contentStyle: { class: 'no-overflow-y' },
      modal: true,
      style: { width: '100vw' },
      showHeader: true,
      resizable: false,
      closeOnEscape: false,
      closable: true,
      data: {
        recordType: this.recordType,
        inputFeedId: this.feedId,
        templateMapping: this.feedTempleMapping,
        selectedFeed:feedData
      }
    });
  }
  hideExportContainerConfiguration() {
    this.showContainerExport = false;
    this.selectedFeedName = '';
  }
  openCloseRecordConfigurationDialogue(flag: boolean) {
    this.displayRecordsExport = flag;
  }
  onHideRecordsConfigDialogue(event: any) {
    this.openCloseRecordConfigurationDialogue(false);
  }

  openCloseStatusConfigurationDialogue(flag: boolean) {
    this.displayStatusExport = flag;
  }
  onHideStatusConfigDialogue(event: any) {
    this.openCloseStatusConfigurationDialogue(false);

  }
  //#endregion

  //#region AG Grid Date Comparer
  dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
    if (cellValue != null) {
      let dateString = cellValue.substring(0, 10);
      var dateParts = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).split(dateTimeConfig.dateSeparator);
      var day = Number(dateParts[Number(dateTimeConfig.day)].substring(0, 2));
      var month = Number(dateParts[Number(dateTimeConfig.month)]) - 1;
      var year = Number(dateParts[Number(dateTimeConfig.year)]);

      var cellDate = new Date(year, month, day);

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      } else {
        return 0;
      }
    }
    else {
      return -1;
    }
  }
  //#endregion
  //#region Refresh Listing Grid
  refresh() {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(['login']);
    }
    else {
      this.populateDataFeedGrid();
    }

  }
  //#endregion
  //#region Ag Grid Cell Updation
  populateDataFeedGridCells(): void {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(['login']);
    }
    else {

      this.updatingRecord = true;
      let body: datafeed = {
        FeedType: this.recordType,
        TargetDetail: this.viewId,
        TargetType: this.targetType
      }
      this.getFeedSubsription = this._apiService.getDataFeeds(body)
        .subscribe({
          next: (response) => {
            this.updatingRecord = false;
            this.gridData = response;
            this.isFeedDataRetrieved = true;
            this.totalCount = this.gridData.length;
            if (this.gridData && this.gridData != null && this.totalCount > 0) {
              if (this.gridOptions.api) {
                //this.gridOptions.api.updateRowData(this.gridData as RowDataTransaction);
                this.gridOptions.api.applyTransaction(this.gridData as RowDataTransaction);

                //this.gridOptions.api.applyTransaction({update:this.gridData});
                if (this.gridOptions.api) {
                  this.gridOptions.api.sizeColumnsToFit();
                }
              }
            }

            if (this.totalCount && this.totalCount > 0) { /*&& this.recordType == 'Import'*/
              let gridTime = setTimeout(() => {
                this.populateDataFeedGridCells();
              }, 9000);
              gridTimeout.push(gridTime);
            }
          },
          error: (error) => {
            this.updatingRecord = false;
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again later.' });
          }
        });
    }
  }

  getListOfRefresehCol(): string[] {

    let col: string[] = [];
    col.push('LastRun', 'FeedStatus', 'Status');
    return col;
  }
  //#endregion
  //#region Ag Grid Population Event
  populateDataFeedGrid(): void {
    this.updatingRecord = true;
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(['login']);
    }
    else {
      let body: datafeed = {
        FeedType: this.recordType,
        TargetDetail: this.viewId,
        TargetType: this.targetType
      }
      // this.feedId = null;
      // selectedFeedId = null;
      this._apiService.getDataFeeds(body)
        .subscribe({
          next: (response) => {
            this.updatingRecord = false;
            // this.feedId = null;
            // selectedFeedId = null;
            this.gridData = [];
            this.isFeedDataRetrieved = true;
            this.gridData = response;
            this.totalCount = this.gridData.length;
            if (this.totalCount > 0) {
              let gridTime = setTimeout(() => {
                this.populateDataFeedGridCells();
              }, 7000);
              gridTimeout.push(gridTime);
            }
          },
          error: (error) => {
            this.updatingRecord = false;
            this._sharedService.clearToast();
            this._sharedService.showToast(
              { 
                severity: 'error', 
                summary: 'Error', 
                detail: 'Error occurred, please try again later.' 
              }
            );
          }
        });
    }
  }

  reBindGrid(selAsset: any) {
    this.populateDataFeedGrid();
  }
  datafeedRowDoubleClicked(event: any) {
    this.feedId = "";
    this.feedId = event.data.FeedId;
    selectedFeedId = this.feedId;
    this._sharedService.updateApiFeed(null);

    try {
      this._sharedService.selectedFeedRowForView = event.data;
      this._sharedService.uploadFeedOptions(event.data);
      if (event.data.FeedTemplateInterval) {
        this.feedTempleInterval = event.data.FeedTemplateInterval;
      }

      if (event.data.TemplateMapping) {
        this.feedTempleMapping = event.data.TemplateMapping;
      }
    }
    catch (ex) {
      console.log(ex);
    }
  }
  clickedFeedRow(event: any) {
    this.feedId = "";
    this.feedId = event.data.FeedId;
    selectedFeedId = this.feedId;
    this.selectedRowData = event;
    if (event.data.Interval == "Not Applicable") {
      this.shoePlayButton = false;
    }
    else {
      this.shoePlayButton = true;
    }
    try {
      let rowNode: IRowNode = this.gridOptions.api.getRowNode(this.selectedRowData.node.id);
      this._sharedService.uploadFeedOptions(rowNode.data);
      this._sharedService.selectedFeedRowForView = rowNode.data;
      if (rowNode.data.FeedTemplateInterval) {
        this.feedTempleInterval = event.data.FeedTemplateInterval;
      }

      if (event.data.TemplateMapping) {
        this.feedTempleMapping = event.data.TemplateMapping;
      }
    }
    catch (ex) {
      console.log(ex);
    }
  }
  //#endregion

  //#region Set Columns of Data Feeds Ag Grid
  setDataFeedGridColumns(): void {
    let screenWidth = window.innerWidth;
    let setWidthDataType = 90;
    let setWidthSourceDetail = 250;
    let setWidthTargetType = 250;
    let setWidthSourceType = 100;
    let setWidthFeedName = 170;
    let setWidthInterval = 90;
    let setWidthTargetDetail = 250;
    let setWidthFeedStatus = 150;
    let setWidtStatus = 80;
    let setWdithLastRun = 170;

    if (screenWidth < 1025) {

      setWidthDataType = 170;
      setWidthSourceDetail = 150;
      setWidthTargetType = 170;
      setWidthSourceType = 70;
      setWidthFeedName = 110;
      setWidthInterval = 150;
      setWidthTargetDetail = 150;
      setWidthFeedName = 200;
      setWidtStatus = 150;
      setWdithLastRun = 140;
    }


    this.columnDefs = [];
    this.columnDefs =
      [
        {
          headerName: 'Feed Name', field: 'FeedName', filter: 'agSetColumnFilter', menuTabs: ['filterMenuTab'], minWidth: setWidthFeedName,
        },
        {
          headerName: 'Data Type', field: 'DataType', filter: 'agSetColumnFilter', menuTabs: ['filterMenuTab'], width: setWidthDataType,
        },
        {
          headerName: 'Source Type', field: 'SourceType', filter: 'agSetColumnFilter', menuTabs: ['filterMenuTab'], minWidth: setWidthSourceType,
        },
        {
          headerName: 'Source Detail', field: 'SourceDetail', filter: 'agSetColumnFilter', menuTabs: ['filterMenuTab'], minWidth: setWidthSourceDetail,
        },
        {
          headerName: 'Target Type', field: 'TargetType', filter: 'agSetColumnFilter', menuTabs: ['filterMenuTab'], width: setWidthTargetType,
        },
        {
          headerName: 'Target Detail', field: 'TargetDetail', filter: 'agSetColumnFilter', menuTabs: ['filterMenuTab'], minWidth: setWidthTargetDetail,
        },
        {
          headerName: 'Interval', field: 'Interval', filter: 'agSetColumnFilter', menuTabs: ['filterMenuTab'], width: setWidthInterval,
        },
        {
          headerName: 'Last Run', field: 'LastRun', filter: 'agDateColumnFilter', filterParams: {
            comparator: this.dateComparater,
            inRangeInclusive: 'true'

          }, valueFormatter: function (params: any) {
            if (params.value != "" && params.value != null) {
              let dateString = params.value;
              return moment(dateString).format(dateTimeConfig.dateformat.toUpperCase() + ' ' + dateTimeConfig.timeformat);
            }
          }, menuTabs: ['filterMenuTab'], minWidth: 170
        },

        {
          headerName: 'Feed Status', field: 'FeedStatus', cellRenderer: this.statusCellRenderer, width: setWidthFeedStatus, filter: 'agSetColumnFilter', menuTabs: ['filterMenuTab']
        },
        {
          headerName: 'Status', field: 'Status', filter: false, suppressMenu: true, width: setWidtStatus, valueFormatter: function (params: any) {
            if (params.value == true) {
              return "Active";
            }
            else {
              return "Inactive";
            }
          }
        },
      ];

  }

  //#endregion

  //#region custom cell render
  statusCellRenderer(params: any) {
    
    let html: string = '';
    var eDiv = document.createElement('div');
    if (params.value) {
      if (params.data.Interval == "Not Applicable") {
        if (params.data.Status == true) {
          html = html;
        }
        else {
          eDiv.style.background = '#CCCC00';
          html = html + 'Pause';
        }
      }
      else if (params.value == 'Live') {
        eDiv.style.background = 'green';
        html = html + params.value;
      }
      else if (params.value == 'In progress') {
        eDiv.style.background = '#2196F3';
        html = html + params.value;
      }
      else if (params.value == 'Pause') {
        eDiv.style.background = '#CCCC00';
        html = html + params.value;
      }
      else {
        eDiv.style.background = 'red';
        html = html + params.value;
      }
    }


    eDiv.innerHTML = html;
    eDiv.style.height = '100%';
    eDiv.style.width = '100%';
    eDiv.style.color = 'white';
    eDiv.style.textAlign = 'center';
    //eDiv.style.paddingTop = '6px';
    eDiv.style.textAlign = 'center';
    return eDiv;
  }
  //#endregion

  //#region Roles
  UpdateRoles() {
    if (!this._sharedService.UserRole) {
      this._sharedService.updateRole();
    }
    this.isAddVisibleIncoming = false;
    this.isEditVisibleIncoming = false;
    this.isMappingVisibleIncoming = false;
    this.isLogVisibleIncoming = false;

    this.isAddVisibleOutgoing = false;
    this.isEditVisibleOutgoing = false;
    this.isMappingVisibleOutgoing = false;
    this.isLogVisibleOutgoing = false;

    this.isAddVisibleIncomingStatus = false;
    this.isEditVisibleIncomingStatus = false;
    this.isMappingVisibleIncomingStatus = false;
    this.isLogVisibleIncomingStatus = false;

    this.API_Import_Readonly = false;
    this.API_Export_Readonly = false;

    this.isAddVisibleIncoming = this._sharedService.UserRole.Import_Add;
    this.isEditVisibleIncoming = this._sharedService.UserRole.Import_Edit;
    this.isMappingVisibleIncoming = this._sharedService.UserRole.Import_Mapping;
    this.isLogVisibleIncoming = this._sharedService.UserRole.Import_Log;

    this.isAddVisibleOutgoing = this._sharedService.UserRole.Export_Add;
    this.isEditVisibleOutgoing = this._sharedService.UserRole.Export_Edit;
    this.isMappingVisibleOutgoing = this._sharedService.UserRole.Export_Mapping;
    this.isLogVisibleOutgoing = this._sharedService.UserRole.Export_Log;

    this.isAddVisibleIncomingStatus = this._sharedService.UserRole.Import_Add;
    this.isEditVisibleIncomingStatus = this._sharedService.UserRole.Import_Edit;
    this.isMappingVisibleIncomingStatus = this._sharedService.UserRole.Import_Edit;
    this.isLogVisibleIncomingStatus = this._sharedService.UserRole.Import_Log;
    this.API_Import_Readonly = this._sharedService.UserRole.API_Import_Readonly;
    this.API_Export_Readonly = this._sharedService.UserRole.API_Export_Readonly;

  }
  //#endregion

  //#region Helper Methods
  IsDataFileAllowed(params: any) {
    try {
      if (params.node.data) {
        if ((params.context.gridContext.recordType == 'Import' && params.node.data.SourceType == 'Status') || params.node.data.SourceType == 'Web Service Pull' || params.node.data.SourceType == 'Web Service Push') {
          return false;

        }
        else if (params.context.gridContext.recordType == 'Import' && (params.node.data.SourceType == 'Web Service Pull' || params.node.data.SourceType == 'Shared Folder' || params.node.data.SourceType == 'FTP')) {
          return true;
        }
        else if (params.context.gridContext.recordType == 'Import') {
          // Allowed
          return true;
        }
        else if (params.context.gridContext.recordType == 'Export' && (params.node.data.DataType == 'XML') && (params.node.data.SourceType == 'RDOC' || params.node.data.SourceType == 'Records')) {
          return true;
        }
        else if (params.context.gridContext.recordType == 'Export' && params.node.data.SourceType == 'Status Link') {
          return true;
        }
        else {
          return false;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  IsTemplateFileAllowed(params: any) {
    try {

      if (params.node.data) {
        if (params.context.gridContext.recordType == 'Import' && params.node.data.SourceType == 'Status') {
          return false;

        }
        else if (params.node.data.feedTempleInterval = "Not Applicable") {
          return false;
        }
        else if (params.context.gridContext.recordType == 'Import' && (params.node.data.SourceType == 'Web Service Pull' || params.node.data.SourceType == 'Shared Folder' || params.node.data.SourceType == 'FTP')) {
          return false;
        }
        else if (params.context.gridContext.recordType == 'Import') {
          // Allowed
          return true;
        }
        else if (params.context.gridContext.recordType == 'Export' && (params.node.data.DataType == 'XML') && (params.node.data.SourceType == 'RDOC' || params.node.data.SourceType == 'Records Link')) {
          return false;
        }
        else if (params.context.gridContext.recordType == 'Export' && params.node.data.SourceType == 'Status Link') {
          return false;
        }
        else {
          return false;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  actionNotAllowed(params: any) {
    params.context.gridContext._sharedService.clearToast();
    params.context.gridContext._sharedService.showToast({ severity: 'warn', summary: 'Not Allowed', detail: `Not functional for ${params.node.data.SourceType}` });
  }
  //#endregion

  //#region Grid Context Menu
  getCustomContextMenuItems(params: any): any {
    let result: any[] = [];
    let childResult: any[] = [];
    let importFeedChildResult: any[] = [];
    if ((params.context.gridContext.recordType == 'Import' && params.context.gridContext.isAddVisibleIncoming && params.context.gridContext.feedType != 'Import') || (params.context.gridContext.recordType == 'Export' && params.context.gridContext.isAddVisibleOutgoing) || (params.context.gridContext.recordType == 'Import' && params.context.gridContext.isAddVisibleIncomingStatus && params.context.gridContext.feedType == 'Import')) {
      result.push({
        name: "Add Feed",
        action: function () {
          params.context.gridContext._sharedService.addFeed(null);
        },
        icon: params.context.gridContext._sharedService.addIconHTMLasString,
      });
    }
    if ((params.context.gridContext.recordType == 'Import' && params.context.gridContext.isEditVisibleIncoming && params.context.gridContext.feedType != 'Import') || (params.context.gridContext.recordType == 'Export' && params.context.gridContext.isEditVisibleOutgoing) || (params.context.gridContext.recordType == 'Import' && params.context.gridContext.isEditVisibleIncomingStatus && params.context.gridContext.feedType == 'Import')) {
      result.push({
        name: "Edit Feed",
        action: function () {
          params.context.gridContext.feedId = null;
          if (params.node) {
            params.context.gridContext.feedId = params.node.data.FeedId;
            selectedFeedId = params.node.data.FeedId;
            params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
            params.context.gridContext._sharedService.editFeedContextMenu(params.context.gridContext.feedId);
          }

        },
        icon: params.context.gridContext._sharedService.editIconHTMLasString,
      });
    }

    result.push({
      name: "Refresh",
      action: function () {
        if (params.node) {
          params.context.gridContext.refresh();
        }

      },
      icon: params.context.gridContext._sharedService.refreshIconHTMLasString,
    });



    if ((params.context.gridContext.isLogVisibleIncoming && params.context.gridContext.recordType == 'Import' && params.context.gridContext.feedType != 'Import') || (params.context.gridContext.isLogVisibleOutgoing && params.context.gridContext.recordType == 'Export') || (params.context.gridContext.isLogVisibleIncomingStatus && params.context.gridContext.recordType == 'Import' && params.context.gridContext.feedType == 'Import')) {
      result.push({
        name: "Feed Log",
        action: function () {
          params.context.gridContext.feedId = null;
          if (params.node) {
            params.context.gridContext.feedId = params.node.data.FeedId;
            selectedFeedId = params.node.data.FeedId;
            params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
            params.context.gridContext.openFeedLog();
          }

        },
        icon: params.context.gridContext._sharedService.logIconHTMLasString,
      });
    }

    if (params.context.gridContext.recordType == 'Import') {
      result.push({
        name: "Run Feed",
        action: function () {
          params.context.gridContext.feedId = null;
          if (params.node) {
            params.context.gridContext.feedId = params.node.data.FeedId;
            selectedFeedId = params.node.data.FeedId;
            params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
            params.context.gridContext.runFeed();
          }

        },
        icon: params.context.gridContext._sharedService.runIconHTMLasString,
      });
    }
    else if (params.context.gridContext.recordType == 'Export') {
      result.push({
        name: "Run Feed",
        action: function () {
          params.context.gridContext.feedId = null;
          if (params.node) {
            params.context.gridContext.feedId = params.node.data.FeedId;
            selectedFeedId = params.node.data.FeedId;
            params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
            params.context.gridContext.runOutgoingFeed();
          }

        },
        icon: params.context.gridContext._sharedService.runIconHTMLasString,
      });
    }
    if ((params.context.gridContext.isMappingVisibleIncoming && params.context.gridContext.recordType == 'Import' && params.context.gridContext.feedType != 'Import') || (params.context.gridContext.isMappingVisibleIncomingStatus && params.context.gridContext.recordType == 'Import' && params.context.gridContext.feedType == 'Import')) {
      result.push({
        name: "Mapping",
        action: function () {
          params.context.gridContext.feedId = null;
          if (params.node) {
            params.context.gridContext.feedId = params.node.data.FeedId;
            selectedFeedId = params.node.data.FeedId;
            params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
            params.context.gridContext.openFieldMapping();
          }
        },
        icon: params.context.gridContext._sharedService.feedConfigIconHTMLasString,
      });
    }
    else if (params.context.gridContext.isMappingVisibleOutgoing && params.context.gridContext.recordType == 'Export') {
      result.push({
        name: "Configuration",
        action: function () {
          params.context.gridContext.feedId = null;
          if (params.node) {
            params.context.gridContext.feedId = params.node.data.FeedId;
            selectedFeedId = params.node.data.FeedId;
            params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
            params.context.gridContext.openConfiguration();
          }
        },
        icon: params.context.gridContext._sharedService.feedConfigIconHTMLasString,
      });
    }

    if (params.context.gridContext.recordType == 'Import') {
      if (params.context.gridContext.IsDataFileAllowed(params)) {
        childResult.push({
          name: "Data File",
          action: function () {
            params.context.gridContext.feedlogid = null;
            if (params.node) {
              params.context.gridContext.feedId = params.node.data.FeedId;
              selectedFeedId = params.node.data.FeedId;
              params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
              if (params.context.gridContext.IsDataFileAllowed(params)) {
                params.context.gridContext._sharedService.uploadFeed({ feedID: params.context.gridContext.feedId, fileType: '1' });
              }
              else {
                params.context.gridContext.actionNotAllowed(params);
              }
            }

          },
          icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
        });
      }
      childResult.push({
        name: "Template File",
        action: function () {
          params.context.gridContext.feedlogid = null;
          if (params.node) {
            params.context.gridContext.feedId = params.node.data.FeedId;
            selectedFeedId = params.node.data.FeedId;
            params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
            if (params.context.gridContext.IsTemplateFileAllowed(params)) {
              params.context.gridContext._sharedService.uploadFeed({ feedID: params.context.gridContext.feedId, fileType: '2' });
            }
            else {
              params.context.gridContext.actionNotAllowed(params);
            }

          }

        },
        icon: params.context.gridContext._sharedService.saveLogFeedIconHTMLasString,
      });
      result.push({
        name: "Upload",
        action: function () {
          params.context.gridContext.feedId = null;
          if (params.node) {
            params.context.gridContext.feedId = params.node.data.FeedId;
            selectedFeedId = params.node.data.FeedId;
            params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
          }

        },
        icon: params.context.gridContext._sharedService.uploadFeedIconHTMLasString,
        subMenu: childResult,
      });
    }


    importFeedChildResult.push({
      name: "Import from App Storage",
      action: function () {
        params.context.gridContext.feedlogid = null;
        if (params.node) {
          params.context.gridContext.feedId = params.node.data.FeedId;
          selectedFeedId = params.node.data.FeedId;
          params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
          params.context.gridContext._sharedService.ImportFeedStorageContextMenu(params.context.gridContext.feedId);
        }

      },
      icon: params.context.gridContext._sharedService.importStorageIconHTMLasString,
    });
    importFeedChildResult.push({
      name: "Import from Computer",
      action: function () {
        params.context.gridContext.feedId = null;
        if (params.node) {
          params.context.gridContext.feedId = params.node.data.FeedId;
          selectedFeedId = params.node.data.FeedId;
          params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
          params.context.gridContext._sharedService.ImportFeedComputerContextMenu(params.context.gridContext.feedId);
        }

      },
      icon: params.context.gridContext._sharedService.importFeedIconHTMLasString,
    });

    result.push({
      name: "Import Feed",
      action: function () {
        params.context.gridContext.feedId = null;
        if (params.node) {
          params.context.gridContext.feedId = params.node.data.FeedId;
          selectedFeedId = params.node.data.FeedId;
          params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
        }

      },
      icon: params.context.gridContext._sharedService.importFeedIconHTMLasString,
      subMenu: importFeedChildResult,
    });
    result.push({
      name: "Export Feed",
      action: function () {
        params.context.gridContext.feedId = null;
        if (params.node) {
          params.context.gridContext.feedId = params.node.data.FeedId;
          selectedFeedId = params.node.data.FeedId;
          params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
          params.context.gridContext.ExportFeed();
        }

      },
      icon: params.context.gridContext._sharedService.exportFeedIconHTMLasString,
    });
    return result;
  }
  navigateToNextCell(params: any) {
    var previousCell = params.previousCellPosition;
    // var previousCell = params.previousCellDef;
    var suggestedNextCell = params.nextCellPosition;
    // var suggestedNextCell = params.nextCellDef;
    var self = this;
    var KEY_UP = 'ArrowUp';
    var KEY_DOWN = 'ArrowDown';
    var KEY_LEFT = 'ArrowLeft';
    var KEY_RIGHT = 'ArrowRight';
    switch (params.key) {
      case KEY_DOWN:
        var nextRowIndex = previousCell.rowIndex + 1;
        var renderedRowCount = this.gridOptions.api.getModel().getRowCount();
        if (nextRowIndex >= renderedRowCount) {
          return null;
        } else {
          var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
          rowNode.setSelected(true);
          this.feedId = "";
          this.feedId = rowNode.data.FeedId;
          selectedFeedId = this.feedId;
          this.selectedRowData = rowNode;

          try {
            // let rowNode: RowNode = this.gridOptions.api.getRowNode(rowNode.id);
            this._sharedService.uploadFeedOptions(rowNode.data);
            this._sharedService.selectedFeedRowForView = rowNode.data;
            if (rowNode.data.FeedTemplateInterval) {
              this.feedTempleInterval = rowNode.data.FeedTemplateInterval;
            }

            if (rowNode.data.TemplateMapping) {
              this.feedTempleMapping = rowNode.data.TemplateMapping;
            }
          }
          catch (ex) {
            console.log(ex);
          }
          return {
            rowIndex: nextRowIndex,
            column: previousCell.column,
            floating: previousCell.floating
          };
        }


      case KEY_UP:
        var _nextRowIndex = previousCell.rowIndex - 1;
        if (_nextRowIndex < 0) {
          return null;
        } else {
          var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
          rowNode.setSelected(true);
          this.feedId = "";
          this.feedId = rowNode.data.FeedId;
          selectedFeedId = this.feedId;
          this.selectedRowData = rowNode;

          try {
            // let rowNode: RowNode = this.gridOptions.api.getRowNode(this.selectedRowData.node.id);
            this._sharedService.uploadFeedOptions(rowNode.data);
            this._sharedService.selectedFeedRowForView = rowNode.data;
            if (rowNode.data.FeedTemplateInterval) {
              this.feedTempleInterval = rowNode.data.FeedTemplateInterval;
            }

            if (rowNode.data.TemplateMapping) {
              this.feedTempleMapping = rowNode.data.TemplateMapping;
            }
          }
          catch (ex) {
            console.log(ex);
          }
          return {
            rowIndex: _nextRowIndex,
            column: previousCell.column,
            floating: previousCell.floating
          };
        }
      case KEY_LEFT:
      case KEY_RIGHT:
        return suggestedNextCell;
      default:
        throw 'this will never happen, navigation is always on of the 4 keys above';
    }

  }

  createAndDownloadBlobFile(body, extension = 'rfeed') {
    var selRow: any = this.gridOptions.api.getSelectedRows()[0];
    var filename = selRow.FeedName;
    const blob = new Blob([body]);
    const fileName = `${filename}.${extension}`;
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  ExportFeed() {
    if (this.feedId) {
      this._sharedService.showLoader("Processing...");
      this._apiService.ExportFeedData(this.feedId)
      .subscribe({
        next: (response) => {
          this.createAndDownloadBlobFile(response)
          this._sharedService.hideLoader();
		},
        error: (error) => {
		}
    });
    }
    else {
      this._sharedService.hideLoader();
      this._sharedService.clearToast();
      this._sharedService.showToast({ severity: 'warn', summary: 'No Feed Selected', detail: 'Please select a Feed.' });
    }
  }

  //#endregion
}
