<div class="jarviswidget-ctrls" title="Edit Feed (Ctrl+E)">
    <a [attr.tabindex]="0" class="button-icon tablIndexFocus" id="btneditfeed" (keyup.enter)="showEditDialog()"
        (click)="showEditDialog()">
        <i class="glyphicon glyphicon-pencil"></i>
    </a>
</div>

<p-confirmDialog header="Are you sure?" key="confirmdelete" icon="fa fa-question-circle" [style]="{width: '435px'}">
</p-confirmDialog>
<p-confirmDialog header="Warning!" key="confirmdeleteincoming" icon="fa fa-question-circle" acceptLabel="Continue"
    rejectLabel="Cancel" [style]="{width: '488px'}"></p-confirmDialog>
<p-confirmDialog header="Warning!" key="confirmdeleteoutgoing" icon="fa fa-question-circle" acceptLabel="Continue"
    rejectLabel="Cancel" [style]="{width: '488px'}"></p-confirmDialog>
<form #editfeedForm="ngForm">
    <p-dialog [(visible)]="display" showEffect="fade" [modal]="true" [style]="{width: '720px'}" [resizable]="false">
        <ng-template pTemplate="header"  (keyup.shift.tab)="onKey()">
            {{dialogTitle}}
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-12">

                        <fieldset>
                            <label class="label">
                                Feed Name<span [hidden]="feedname.valid || model.feedname" class="text-danger">
                                    *
                                </span>
                            </label>
                            <label class="input">
                                <input id="feedname" type="text" name="feedname" placeholder="Feed Name"
                                    [(ngModel)]="model.feedname" #focus required #feedname="ngModel" />

                            </label>

                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Template<span class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="feedTemplate" name="feedTemplate" [options]="feedTemplateList"
                                #feedTemplate="ngModel" [(ngModel)]="selectedFeedTemplate" optionLabel="TemplateName"
                                [filter]="true" [resetFilterOnHide]="true" required placeholder="Select Feed Template"
                                (onChange)=feedTemplateChange($event?.value)>

                            </p-dropdown>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6">
                        <!-- && model.sourcetype!='Status Link' -->
                        <fieldset>
                            <label class="label">
                                Scope<span [hidden]="feedscope.valid || model.feedscope" class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="feedscope" name="feedscope" [options]="feedScopeList" #feedscope="ngModel"
                                [(ngModel)]="model.feedscope" required placeholder="Select Feed Scope">

                            </p-dropdown>

                        </fieldset>
                    </div>


                </div>
                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Source Type<span
                                    [hidden]="sourcetype.valid|| (model.sourcetype!=null && model.sourcetype.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="sourcetype" name="sourcetype" [options]="sourceTypeList" required
                                placeholder="Select Source Type" [(ngModel)]="model.sourcetype" #sourcetype="ngModel"
                                (onChange)=sourceTypeChanged($event?.value)>

                            </p-dropdown>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6"
                        *ngIf="recordType=='Import' && model.sourcetype!='Status' && model.sourcetype!='FTP' && model.sourcetype!='Azure File Storage' && model.sourcetype!='Web Service Pull' && model.sourcetype!='Web Service Push' && model.sourcetype!='Records' && (templateSourceDetail=='' || templateSourceDetail==null)">
                        <!-- && model.sourcetype!='Status Link' -->
                        <fieldset>
                            <label class="label">
                                Source Detail<span [hidden]="sourcedetail.valid || model.sourcedetail"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="sourcedetail" type="text" name="sourcedetail" placeholder="Source Detail"
                                    required [(ngModel)]="model.sourcedetail" #sourcedetail="ngModel">

                            </label>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6"
                        *ngIf="recordType=='Import' && model.sourcetype=='FTP' && (templateSourceDetail=='' || templateSourceDetail==null)">
                        <fieldset>
                            <label class="label">
                                Source Detail<span [hidden]="sourcedetail.valid || model.sourcedetail"
                                    class="text-danger">
                                    *
                                </span>
                                <i class='fa fa-cog fa-lg pull-right' (click)="openFTPDialogue()"></i>
                            </label>

                            <label class="input">
                                <input id="sourcedetail" type="text" name="sourcedetail" placeholder="Source Detail"
                                    required [(ngModel)]="model.sourcedetail" #sourcedetail="ngModel" [disabled]="true"
                                    class="readonlyinput2">

                            </label>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6"
                        *ngIf="recordType=='Import' && model.sourcetype=='Azure File Storage' && (templateSourceDetail=='' || templateSourceDetail==null)">
                        <fieldset>
                            <label class="label">
                                Source Detail<span [hidden]="sourcedetail.valid || model.sourcedetail"
                                    class="text-danger">
                                    *
                                </span>
                                <i class='fa fa-cog fa-lg pull-right' (click)="openASFDialogue()"></i>
                            </label>

                            <label class="input">
                                <input id="sourcedetail" type="text" name="sourcedetail" placeholder="Source Detail"
                                    required [(ngModel)]="model.sourcedetail" #sourcedetail="ngModel" [disabled]="true"
                                    class="readonlyinput2">

                            </label>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6"
                        *ngIf="recordType=='Import' && model.sourcetype=='Web Service Pull' && (templateSourceDetail=='' || templateSourceDetail==null)">
                        <fieldset>
                            <label class="label">
                                Source Detail<span [hidden]="sourcedetail.valid || model.sourcedetail"
                                    class="text-danger">
                                    *
                                </span>
                                <i class='fa fa-cog fa-lg pull-right' (click)="openWebServiceDialogue()"></i>
                            </label>

                            <label class="input">
                                <input id="sourcedetail" type="text" name="sourcedetail" placeholder="Source Detail"
                                    required [(ngModel)]="model.sourcedetail" #sourcedetail="ngModel" [disabled]="true"
                                    class="readonlyinput2">

                            </label>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6"
                        *ngIf="recordType=='Import' && model.sourcetype=='Web Service Push' && (templateSourceDetail=='' || templateSourceDetail==null)">
                        <fieldset>
                            <label class="label">
                                Source Details<span [hidden]="sourcedetail.valid || model.sourcedetail"
                                    class="text-danger">
                                    *
                                </span>
                                <i class='fa fa-cog fa-lg pull-right' (click)="openWebServicePushDialogue()"></i>
                            </label>

                            <label class="input">
                                <input id="sourcedetail" type="text" name="sourcedetail" placeholder="Source Detail"
                                    required [(ngModel)]="model.sourcedetail" #sourcedetail="ngModel" [disabled]="true"
                                    class="readonlyinput2">

                            </label>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6 job-dropdown"
                        *ngIf="recordType=='Import' && templateSourceDetail && !templateSourceDetail.includes('{')">
                        <fieldset>
                            <label class="label">
                                Source Detail<span
                                    [hidden]="addsourcedetail.valid|| (model.sourcedetail!=null && model.sourcedetail.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="addsourcedetail" name="addsourcedetail" [options]="templateSourceDetailList"
                                required placeholder="Select Source Detail" [(ngModel)]="model.sourcedetail"
                                #addsourcedetail="ngModel" [filter]="true" #addsourcedetailfilter
                                [resetFilterOnHide]="true">

                            </p-dropdown>

                        </fieldset>
                    </div>

                    <div class="form-group col-sm-6 job-dropdown"
                        *ngIf="(recordType=='Import') && templateSourceDetail=='{StatusViews}'">
                        <fieldset>
                            <label class="label">
                                Source Detail<span
                                    [hidden]="sourcedetail.valid|| (model.sourcedetail!=null && model.sourcedetail.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="sourcedetail" name="sourcedetail" class="comboFit"
                                [options]="sourceViewListStatus" required placeholder="Select Source Detail"
                                [(ngModel)]="model.sourcedetail" #sourcedetail="ngModel" [filter]="true"
                                #sourcedetailfilter [resetFilterOnHide]="true">

                            </p-dropdown>

                        </fieldset>
                    </div>


                    <div class="form-group col-sm-6 job-dropdown"
                        *ngIf="(recordType=='Import') && templateSourceDetail=='{RepairViews}'">
                        <fieldset>
                            <label class="label">
                                Source Detail<span
                                    [hidden]="sourcedetail.valid|| (model.sourcedetail!=null && model.sourcedetail.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="sourcedetail" name="sourcedetail" class="comboFit"
                                [options]="sourceViewListRepair" required placeholder="Select Source Detail"
                                [(ngModel)]="model.sourcedetail" #sourcedetail="ngModel" [filter]="true"
                                #sourcedetailfilter [resetFilterOnHide]="true">

                            </p-dropdown>

                        </fieldset>
                    </div>


                    <div class="form-group col-sm-6 job-dropdown"
                        *ngIf="(recordType=='Import') && templateSourceDetail=='{ViewSelection}'">
                        <fieldset>
                            <label class="label">
                                Source Detail<span
                                    [hidden]="sourcedetail.valid|| (model.sourcedetail!=null && model.sourcedetail.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="sourcedetail" name="sourcedetail" class="comboFit"
                                [options]="sourceViewListAll" required placeholder="Select Source Detail"
                                [(ngModel)]="model.sourcedetail" #sourcedetail="ngModel" [filter]="true"
                                #sourcedetailfilter [resetFilterOnHide]="true">

                            </p-dropdown>

                        </fieldset>
                    </div>


                    <div class="form-group col-sm-6 job-dropdown"
                        *ngIf="(recordType=='Export') && templateSourceDetail=='{StatusViews}'">
                        <fieldset>
                            <label class="label">
                                Source Detail<span
                                    [hidden]="sourcedetail.valid|| (model.sourcedetail!=null && model.sourcedetail.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="sourcedetail" name="sourcedetail" class="comboFit"
                                [options]="exportSourceViewListStatus" required placeholder="Select Source Detail"
                                [(ngModel)]="model.sourcedetail" #sourcedetail="ngModel" [filter]="true"
                                #sourcedetailfilter [resetFilterOnHide]="true">

                            </p-dropdown>

                        </fieldset>
                    </div>


                    <div class="form-group col-sm-6 job-dropdown"
                        *ngIf="(recordType=='Export') && templateSourceDetail=='{RepairViews}'">
                        <fieldset>
                            <label class="label">
                                Source Detail<span
                                    [hidden]="sourcedetail.valid|| (model.sourcedetail!=null && model.sourcedetail.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="sourcedetail" name="sourcedetail" class="comboFit"
                                [options]="exportSourceViewListRepair" required placeholder="Select Source Detail"
                                [(ngModel)]="model.sourcedetail" #sourcedetail="ngModel" [filter]="true"
                                #sourcedetailfilter [resetFilterOnHide]="true">

                            </p-dropdown>

                        </fieldset>
                    </div>


                    <div class="form-group col-sm-6 job-dropdown"
                        *ngIf="(recordType=='Export') && templateSourceDetail=='{ViewSelection}'">
                        <fieldset>
                            <label class="label">
                                Source Detail<span
                                    [hidden]="sourcedetail.valid|| (model.sourcedetail!=null && model.sourcedetail.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>
                            <p-dropdown appendTo="body" id="sourcedetail" name="sourcedetail" class="comboFit"
                                [options]="exportSourceViewListAll" required placeholder="Select Source Detail"
                                [(ngModel)]="model.sourcedetail" #sourcedetail="ngModel" [filter]="true"
                                #sourcedetailfilter [resetFilterOnHide]="true">
                            </p-dropdown>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6 job-dropdown"
                        *ngIf="(recordType=='Export') && templateSourceDetail=='{CategoryList}'">
                        <fieldset>
                            <label class="label">
                                Source Detail<span
                                    [hidden]="sourcedetail.valid|| (model.sourcedetail!=null && model.sourcedetail.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>
                            <p-dropdown appendTo="body" id="sourcedetail" name="sourcedetail" class="comboFit"
                                [options]="categoryList" required placeholder="Select Source Detail"
                                [(ngModel)]="model.sourcedetail" #sourcedetail="ngModel" [filter]="true"
                                #sourcedetailfilter [resetFilterOnHide]="true">
                            </p-dropdown>
                        </fieldset>
                    </div>


                    <div class="form-group col-sm-6 job-dropdown"
                        *ngIf="recordType=='Export' && templateSourceDetail=='{AssetSelection}'">
                        <fieldset>

                            <label class="label">
                                Source Detail<span
                                    [hidden]="sourcedetail.valid|| (model.sourcedetail!=null && model.sourcedetail.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <!-- <p-dropdown appendTo="body" id="sourcedetail"   class="comboFit" name="sourcedetail" [options]="sourceAssetDetailList" required placeholder="Select Source Detail" [(ngModel)]="model.sourcedetail" #sourcedetail="ngModel" [filter]="true" #editsourcedetailfilter [resetFilterOnHide]="true">

                            </p-dropdown> -->


                            <p-dropdown appendTo="body" id="sourcedetail" class="comboFit" name="sourcedetail"
                                [options]="sourceAssetDetailList" required placeholder="Select Source Detail"
                                [(ngModel)]="model.sourcedetail" #sourcedetail="ngModel" [filter]="true"
                                #editsourcedetailfilter [resetFilterOnHide]="true">
                                <ng-template style="padding-bottom:20px;padding-left: 0px !important;" let-item
                                    pTemplate="selectedItem">
                                    <span>{{item.label}}</span>
                                    <!-- <span style="font-size:14px;background-color:lightgreen;">{{item.AssetType}}</span> -->
                                </ng-template>
                                <ng-template style="padding-bottom:20px !important;padding-left: 0px !important;"
                                    let-asset pTemplate="item">
                                    <div style="padding-bottom: 20px;">
                                        <!-- <span > -->
                                        <span style="padding:0" class="col-md-9">{{asset.label}}</span>

                                        <!-- </div> -->
                                        <!-- <div class="col-md-4"> -->
                                        <span style="padding:0" class="col-md-3">
                                            <span
                                                style="font-size: 11px;padding: 4px; background-color:lightgreen;color: white;border-radius: 4px;">
                                                {{asset.AssetType}}
                                            </span>
                                        </span>

                                        <!-- </div> -->
                                    </div>
                                </ng-template>
                            </p-dropdown>

                        </fieldset>
                    </div>

                    <!-- <div class="form-group col-sm-6 job-dropdown" *ngIf="recordType=='Export' && templateSourceDetail=='{StatusViews}'">
                        <fieldset>
                            <label class="label">
                                Source Detail<span [hidden]="sourcedetail.valid|| (model.sourcedetail!=null && model.sourcedetail.length >0)" class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="sourcedetail"   class="comboFit" name="sourcedetail" [options]="sourceViewList" required placeholder="Select Source Detail" [(ngModel)]="model.sourcedetail" #sourcedetail="ngModel" [filter]="true" #sourcedetailfilter [resetFilterOnHide]="true">

                            </p-dropdown>

                        </fieldset>
                    </div> -->
                    <div class="form-group col-sm-6 job-dropdown"
                        *ngIf="(recordType=='Export' && templateSourceDetail && !templateSourceDetail.includes('{') )">
                        <fieldset>
                            <label class="label">
                                Source Detail<span
                                    [hidden]="sourcedetail.valid|| (model.sourcedetail!=null && model.sourcedetail.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="sourcedetail" name="sourcedetail" class="comboFit"
                                [options]="templateSourceDetailList" required placeholder="Select Source Detail"
                                [(ngModel)]="model.sourcedetail" #sourcedetail="ngModel" [filter]="true"
                                #editsourcedetailfilter [resetFilterOnHide]="true">

                            </p-dropdown>

                        </fieldset>
                    </div>
                    <!-- <div class="form-group col-sm-6 job-dropdown" *ngIf="(recordType=='Export' && model.sourcetype=='RDOC')">
                        <fieldset>
                            <label class="label">
                                Source Detail<span [hidden]="sourcedetail.valid|| (model.sourcedetail!=null && model.sourcedetail.length >0)" class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="sourcedetail" name="sourcedetail"   class="comboFit" [options]="sourceDetailListOutgoingRDOC" required placeholder="Select Source Detail" [(ngModel)]="model.sourcedetail" #sourcedetail="ngModel" [filter]="true" #editsourcedetailfilter [resetFilterOnHide]="true" (onChange)=sourceDetailChanged($event?.value)>

                            </p-dropdown>
                        </fieldset>
                    </div> -->


                    <!-- <div class="form-group col-sm-6 job-dropdown" *ngIf="recordType=='Export' && model.sourcetype=='Status Link'">
                        <fieldset>
                            <label class="label">
                                Source Detail<span [hidden]="sourcedetail.valid|| (model.sourcedetail!=null && model.sourcedetail.length >0)" class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="sourcedetail"   class="comboFit" name="sourcedetail" [options]="sourceViewList" required placeholder="Select Source Detail" [(ngModel)]="model.sourcedetail" #sourcedetail="ngModel" [filter]="true" #sourcedetailfilter [resetFilterOnHide]="true">

                            </p-dropdown>

                        </fieldset>
                    </div> -->


                </div>
                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Target Type<span
                                    [hidden]="targettype.valid|| (model.targettype!=null && model.targettype.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>
                            <span [ngClass]="{'readonlyinput': isStatusModule==true}" class="width-100-percent">
                                <p-dropdown appendTo="body" id="targettype" name="targettype" [options]="targetModuleList" required
                                    placeholder="Select Target Type" [(ngModel)]="model.targettype"
                                    (onChange)="targetTypeChange($event)" #targettype="ngModel"
                                    (onChange)=targetTypeChanged($event?.value) [disabled]="isStatusModule==true">
                                </p-dropdown>
                            </span>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6 job-dropdown"
                        *ngIf="recordType=='Import'  && templateTargetDetail=='{StatusViews}'">
                        <fieldset>
                            <label class="label">
                                Target Detail<span
                                    [hidden]="targetdetail.valid|| (model.targetdetail!=null && model.targetdetail.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>
                            <span [ngClass]="{'readonlyinput': isStatusModule==true}" class="width-100-percent">
                                <p-dropdown appendTo="body" id="targetdetail" name="targetdetail"
                                    (onChange)=targetDetailChanged($event.value) class="comboFit"
                                    [options]="targetViewListStatus" required placeholder="Select Target Detail"
                                    [(ngModel)]="model.targetdetail" #targetdetail="ngModel" [filter]="true"
                                    #edittargetdetailfilter [resetFilterOnHide]="true"
                                    [disabled]="isStatusModule==true">
                                </p-dropdown>
                            </span>
                        </fieldset>
                    </div>

                    <div class="form-group col-sm-6 job-dropdown"
                        *ngIf="recordType=='Import'  && templateTargetDetail=='{RepairViews}'">
                        <fieldset>
                            <label class="label">
                                Target Detail<span
                                    [hidden]="targetdetail.valid|| (model.targetdetail!=null && model.targetdetail.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>
                            <span [ngClass]="{'readonlyinput': isStatusModule==true}" class="width-100-percent">
                                <p-dropdown appendTo="body" id="targetdetail" name="targetdetail"
                                    (onChange)=targetDetailChanged($event.value) class="comboFit"
                                    [options]="targetViewListRepair" required placeholder="Select Target Detail"
                                    [(ngModel)]="model.targetdetail" #targetdetail="ngModel" [filter]="true"
                                    #edittargetdetailfilter [resetFilterOnHide]="true"
                                    [disabled]="isStatusModule==true">
                                </p-dropdown>
                            </span>
                        </fieldset>
                    </div>

                    <div class="form-group col-sm-6 job-dropdown"
                        *ngIf="recordType=='Import'  && templateTargetDetail=='{ViewSelection}'">
                        <fieldset>
                            <label class="label">
                                Target Detail<span
                                    [hidden]="targetdetail.valid|| (model.targetdetail!=null && model.targetdetail.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>
                            <span [ngClass]="{'readonlyinput': isStatusModule==true}" class="width-100-percent">
                                <p-dropdown appendTo="body" id="targetdetail" name="targetdetail"
                                    (onChange)=targetDetailChanged($event.value) class="comboFit"
                                    [options]="targetViewListAll" required placeholder="Select Target Detail"
                                    [(ngModel)]="model.targetdetail" #targetdetail="ngModel" [filter]="true"
                                    #edittargetdetailfilter [resetFilterOnHide]="true"
                                    [disabled]="isStatusModule==true">
                                </p-dropdown>
                            </span>
                        </fieldset>
                    </div>

                    <div class="form-group col-sm-6 job-dropdown"
                        *ngIf="recordType=='Import' && templateTargetDetail && !templateTargetDetail.includes('{')">
                        <fieldset>
                            <label class="label">
                                Target Detail<span
                                    [hidden]="targetdetail.valid|| (model.targetdetail!=null && model.targetdetail.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="targetdetail" name="targetdetail" class="comboFit"
                                [options]="templateTargetDetailList" required placeholder="Select Target Detail"
                                [(ngModel)]="model.targetdetail" #targetdetail="ngModel" [filter]="true"
                                #edittargetdetailfilter [resetFilterOnHide]="true">

                            </p-dropdown>

                        </fieldset>
                    </div>
                    <!-- <div class="form-group col-sm-6 job-dropdown" *ngIf="recordType=='Import' && model.targettype=='RDOC'">
                            <fieldset>
                                <label class="label">
                                    Target Detail<span [hidden]="targetdetail.valid|| (model.targetdetail!=null && model.targetdetail.length >0)" class="text-danger">
                                        *
                                    </span>
                                </label>

                                <p-dropdown appendTo="body" id="targetdetail" name="targetdetail" class="comboFit" [options]="targetDetailListRDOC" required placeholder="Select Target Detail" [(ngModel)]="model.targetdetail" #targetdetail="ngModel" [filter]="true" #edittargetdetailfilter [resetFilterOnHide]="true">

                                </p-dropdown>

                            </fieldset>
                        </div> -->
                    <div class="form-group col-sm-6"
                        *ngIf="recordType=='Export' && model.targettype !='ROAM Container' && model.targettype!='Azure File Storage' && model.targettype!='FTP' && (templateTargetDetail=='' || templateTargetDetail==null)">
                        <fieldset>
                            <label class="label">
                                Target Detail<span [hidden]="targetdetail.valid || model.targetdetail"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="targetdetail" type="text" name="targetdetail" placeholder="Target Detail"
                                    required [(ngModel)]="model.targetdetail" #targetdetail="ngModel">

                            </label>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6"
                    *ngIf="recordType=='Export' && model.targettype=='Azure File Storage' &&  (templateTargetDetail=='' || templateTargetDetail==null)">
                    <fieldset>
                        <label class="label">
                            Target Detail<span [hidden]="targetdetail.valid || model.targetdetail"
                                class="text-danger">
                                *
                            </span>
                            <i class='fa fa-cog fa-lg pull-right' (click)="openASFDialogue()"></i>
                        </label>
                        <label class="input">
                            <input id="targetdetail" type="text" name="targetdetail" placeholder="Target Detail"
                                required [(ngModel)]="model.targetdetail" #targetdetail="ngModel" [disabled]="true"
                                class="readonlyinput2">

                        </label>

                    </fieldset>
                </div>
                    <div class="form-group col-sm-6"
                        *ngIf="recordType=='Export' && model.targettype=='FTP' &&  (templateTargetDetail=='' || templateTargetDetail==null)">
                        <fieldset>
                            <label class="label">
                                Target Detail<span [hidden]="targetdetail.valid || model.targetdetail"
                                    class="text-danger">
                                    *
                                </span>
                                <i class='fa fa-cog fa-lg pull-right' (click)="openFTPDialogue()"></i>
                            </label>

                            <label class="input">
                                <input id="targetdetail" type="text" name="targetdetail" placeholder="Target Detail"
                                    required [(ngModel)]="model.targetdetail" #targetdetail="ngModel" [disabled]="true"
                                    class="readonlyinput2">

                            </label>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6"
                        *ngIf="recordType=='Export' && model.targettype =='ROAM Container'">
                        <fieldset>
                            <label class="label">
                                Target Detail<span [hidden]="addtargetdetail.valid || model.targetdetail"
                                    class="text-danger">
                                    *
                                </span>
                                <select-target-container [feedTemplate]="selectedFeedTemplate" (targetContainer)="onTargetContainerChange($event)">
                                </select-target-container>
                                <!-- <i class='fa fa-cog fa-lg pull-right'(click)="TargetContainerSelection()"></i> -->
                            </label>

                            <label class="input">
                                <input id="addtargetdetail" type="text" name="addtargetdetail"
                                    placeholder="Target Detail" required [(ngModel)]="model.targetdetail"
                                    #addtargetdetail="ngModel" [disabled]="true" class="readonlyinput2">

                            </label>

                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-6"
                        *ngIf="(model.sourcetype!='Status' && model.sourcetype!='Web Service Pull' && model.sourcetype!='Records')">
                        <fieldset>
                            <label class="label">
                                Data Type<span
                                    [hidden]="datatype.valid || (model.datatype!=null && model.datatype.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="datatype" name="datatype" [options]="dataTypeList" required
                                placeholder="Select Data Type" [(ngModel)]="model.datatype" #datatype="ngModel">

                            </p-dropdown>

                        </fieldset>
                    </div>
                    <!-- <div class="form-group col-sm-6" *ngIf="recordType=='Export' && model.sourcetype=='RDOC'">
                            <fieldset>
                                <label class="label">
                                    Data Type<span [hidden]="datatype.valid || (model.datatype!=null && model.datatype.length >0)" class="text-danger">
                                        *
                                    </span>
                                </label>

                                <p-dropdown appendTo="body" id="datatype" name="datatype" [options]="dataTypeListOutgoingRDOC" required placeholder="Select Data Type" [(ngModel)]="model.datatype" #datatype="ngModel">

                                </p-dropdown>

                            </fieldset>
                        </div> -->
                    <div class="form-group col-sm-6 job-dropdown">

                        <fieldset>
                            <label class="label">
                                Interval<span
                                    [hidden]="interval.valid|| (model.interval!=null && model.interval.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" id="interval" name="interval" class="comboFit" [options]="intervalList" required
                                placeholder="Select Interval" [(ngModel)]="model.interval" #interval="ngModel">

                            </p-dropdown>

                        </fieldset>

                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">Status</label>
                            <label class="checkbox">
                                <span class="onoffswitch tablIndexFocus onoffswitch-Width" [attr.tabindex]="0"
                                    (keyup.enter)="ToggleChecked()">
                                    <input [checked]="isChecked" type="checkbox" name="statusedit" [attr.tabindex]="-1"
                                        class="onoffswitch-checkbox" checked="checked" id="statusedit"
                                        [(ngModel)]="model.status" #statusedit="ngModel">
                                    <label class="onoffswitch-label" for="statusedit">
                                        <span class="onoffswitch-inner" data-swchon-text="Active"
                                            data-swchoff-text="Inactive"></span><span class="onoffswitch-switch onoffswitch-switch-Right"></span>
                                    </label>
                                </span>
                            </label>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <p class="pull-left CreatedbyBottomText">Created by {{Createdby}} on {{CreatedOn}} </p>
                <button *ngIf="(canOutgoingDelete && recordType=='Export')" type="button" pButton icon="fa fa-trash"
                    class="btn btn-danger minzindex" label="Delete"
                    (click)="confirmDeleteOutgoingFeed(editfeedForm)"></button>
                <button
                    *ngIf="(canIncomingDelete && recordType=='Import' && (!isStatusModule) ||(canIncomingDeleteStatus && recordType=='Import' && isStatusModule))"
                    type="button" pButton icon="fa fa-trash" class="btn btn-danger minzindex"
                    label="Delete" (click)="confirmDeleteIncomingFeed(editfeedForm)"></button>
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex"
                    (click)="onSubmit(editfeedForm)" label="Update"
                    [disabled]="(!editfeedForm.form.valid || isSubmitted)"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnCancelClick(editfeedForm)" label="Cancel" [disabled]="isSubmitted"
                    (focusout)="applyFocusToInput()"></button>


            </div>
        </ng-template>

    </p-dialog>
</form>
<form #editFTPform="ngForm">
    <p-dialog [(visible)]="isFtpDialogueOpen" showEffect="fade" [modal]="true" [style]="{width: '420px'}"
        [resizable]="false">
        <ng-template pTemplate="header" >
            FTP Configurations
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                User Name<span [hidden]="editFtpUserName?.valid || ftpModel.FtpUserName"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="editFtpUserName" type="text" name="editFtpUserName" placeholder="User Name"
                                    required [(ngModel)]="ftpModel.FtpUserName" #editFtpUserName="ngModel">

                            </label>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Password<span [hidden]="editFtpPassword?.valid || ftpModel.FtpPasswordTemp"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <!-- <input id="editFtpPassword" type="text" name="editFtpPassword"
                                       placeholder="Password" required [(ngModel)]="ftpModel.FtpPassword" #editFtpPassword="ngModel"> -->
                                <input id="editFtpPassword" type="text" name="editFtpPassword" placeholder="Password"
                                    required [(ngModel)]="ftpModel.FtpPasswordTemp" #editFtpPassword="ngModel">

                            </label>

                        </fieldset>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Host<span [hidden]="editFtpHost?.valid || ftpModel.FtpHost" class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="editFtpHost" type="text" name="editFtpHost" placeholder="Host" required
                                    [(ngModel)]="ftpModel.FtpHost" #editFtpHost="ngModel">

                            </label>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Port<span [hidden]="editFtpPort?.valid || ftpModel.FtpPort" class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="editFtpPort" type="text" name="editFtpPort" placeholder="Port" required
                                    [(ngModel)]="ftpModel.FtpPort" #editFtpPort="ngModel">

                            </label>

                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Path<span [hidden]="editFtpPath?.valid || ftpModel.FtpPath" class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="editFtpPath" type="text" name="editFtpPath" placeholder="Path" required
                                    [(ngModel)]="ftpModel.FtpPath" #editFtpPath="ngModel">

                            </label>

                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex"
                    (click)="onSubmitFtpDailogue(editFTPform)" label="Update"
                    [disabled]="!editFTPform.form.valid"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnFtpCancelClick(editFTPform)" label="Cancel"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>
<form #editAFSform="ngForm">
    <p-dialog [(visible)]="isAzureFileStorageDialogueOpen" showEffect="fade" [modal]="true" [style]="{width: '420px'}"
        [resizable]="false">
        <ng-template pTemplate="header" >
            Azure File Storage Configurations
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Storage Name<span [hidden]="editAfsStorageName?.valid || afsModel.AFSStorageName"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="editAfsStorageName" type="text" name="editAfsStorageName"
                                    placeholder="Storage Name" required [(ngModel)]="afsModel.AFSStorageName"
                                    #editAfsStorageName="ngModel">

                            </label>

                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Storage Key<span [hidden]="editAfsStorageKey?.valid || afsModel.AFSKey"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">

                                <input id="editAfsStorageKey" type="text" name="editAfsStorageKey"
                                    placeholder="Storage Key" required [(ngModel)]="afsModel.AFSKey"
                                    #editAfsStorageKey="ngModel">

                            </label>

                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Storage Path<span [hidden]="editAfsStoragePath?.valid || afsModel.AFSPath"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="editAfsStoragePath" type="text" name="editAfsStoragePath"
                                    placeholder="Storage Path" required [(ngModel)]="afsModel.AFSPath"
                                    #editAfsStoragePath="ngModel">

                            </label>

                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex"
                    (click)="onSubmitAsfDailogue(editAFSform)" label="Update"
                    [disabled]="!editAFSform.form.valid"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnAsfCancelClick(editAFSform)" label="Cancel"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>
<form #docDefinitionListingForm="ngForm">
    <p-dialog [(visible)]="displayDocDefinitionDlg" showEffect="fade" [modal]="true"
        [style]="{width: '551px', height:'500px'}" [resizable]="false">
        <ng-template pTemplate="header" >
            {{feedMessage}}
        </ng-template>
        <div class="upload-dialog" style="max-height: 365px;height: 365px;">
            <div class="smart-form">
                <div class="row"> Following RDOC document definition(s) have been deactivated.</div>
                <div class="row">Kindly visit RDOC and activate document definitions after verifying Metadata API
                    setting.</div>
                <div class="row" style="max-height: 270px !important;overflow-y: auto !important;">
                    <table class="table table-hover">
                        <tbody>
                            <tr *ngFor="let docDefinitionName of docDefinitionList">
                                <td>{{docDefinitionName}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="displayDocDefinitionDlg=!displayDocDefinitionDlg" label="Ok"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>
<form #editWebServiceform="ngForm">
    <p-dialog [(visible)]="isWebServiceDialogueOpen" showEffect="fade" [modal]="true" [style]="{width: '520px'}"
        [resizable]="false">
        <ng-template pTemplate="header" >
            Web Service Configurations
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Path<span [hidden]="editWebServicePath?.valid || webServiceModel.WebServicePath"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="editWebServicePath" type="text" name="editWebServicePath" placeholder="Path"
                                    required [(ngModel)]="webServiceModel.WebServicePath" #editWebServicePath="ngModel">

                            </label>

                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Auth Type<span
                                    [hidden]="editWebServiceAuthType?.valid || webServiceModel.WebServiceAuthType"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <!-- <label class="input">
                                    <input id="editWebServiceAuthType" type="text" name="editWebServiceAuthType"
                                            placeholder="Auth Type" required [(ngModel)]="webServiceModel.WebServiceAuthType" #editWebServiceAuthType="ngModel">

                                </label> -->
                            <p-dropdown appendTo="body" id="editWebServiceAuthType" name="editWebServiceAuthType"
                                [options]="authTypeList" required placeholder="Select Auth Type"
                                [(ngModel)]="webServiceModel.WebServiceAuthType" #editWebServiceAuthType="ngModel">

                            </p-dropdown>

                        </fieldset>
                    </div>
                    <!-- <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">
                                    Port<span [hidden]="editWebServicePort?.valid || webServiceModel.WebServicePort" class="text-danger">
                                        *
                                    </span>
                                </label>

                                <label class="input">
                                    <input id="editWebServicePort" type="text" name="editWebServicePort"
                                            placeholder="Port" required [(ngModel)]="webServiceModel.WebServicePort" #editWebServicePort="ngModel">

                                </label>

                            </fieldset>
                        </div>                                      -->
                </div>
                <div class="row" *ngIf="webServiceModel.WebServiceAuthType == 'Basic'">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                User Name<span
                                    [hidden]="editWebServiceUserName?.valid || webServiceModel.WebServiceUserName"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="editWebServiceUserName" type="text" name="editWebServiceUserName"
                                    placeholder="User Name" required [(ngModel)]="webServiceModel.WebServiceUserName"
                                    #editWebServiceUserName="ngModel">

                            </label>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Password<span
                                    [hidden]="editWebServicePassword?.valid || webServiceModel.WebServicePasswordTemp"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <!-- <input id="editWebServicePassword" type="text" name="editWebServicePassword"
                                        placeholder="Password" required [(ngModel)]="webServiceModel.WebServicePassword" #editWebServicePassword="ngModel"> -->
                                <input id="editWebServicePassword" type="text" name="editWebServicePassword"
                                    placeholder="Password" required [(ngModel)]="webServiceModel.WebServicePasswordTemp"
                                    #editWebServicePassword="ngModel">

                            </label>

                        </fieldset>
                    </div>
                </div>
                <!-- <div class="row" *ngIf="webServiceModel.WebServiceAuthType == 'OAuth'">
                        <div class="form-group col-sm-12">
                            <fieldset>
                                <label class="label">
                                    User Name<span [hidden]="editWebServiceUserName?.valid || webServiceModel.WebServiceUserName" class="text-danger">
                                        *
                                    </span>
                                </label>

                                <label class="input">
                                        <textarea id="editWebServicePassword"  name="editWebServicePassword"
                                        placeholder="Access Token" required [(ngModel)]="webServiceModel.WebServicePasswordTemp" #editWebServicePassword="ngModel"></textarea>

                                </label>

                            </fieldset>
                        </div>
                    </div> -->



            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex"
                    (click)="onSubmitWebServiceDailogue(editWebServiceform)" label="Update"
                    [disabled]="!editWebServiceform.form.valid"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnWebServiceCancelClick(editWebServiceform)" label="Cancel"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>
<form #editWebServicePushform="ngForm">
    <p-dialog [(visible)]="isWebServicePushDialogueOpen" showEffect="fade" [modal]="true" [style]="{width: '520px'}"
        [resizable]="false">
        <ng-template pTemplate="header" >
            Web Service Configurations
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-12">

                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-6">

                    </div>

                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                User Name<span
                                    [hidden]="editWebServiceUserName?.valid || webServiceModel.WebServiceUserName"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="editWebServiceUserName" type="text" name="editWebServiceUserName"
                                    placeholder="User Name" required [(ngModel)]="webServiceModel.WebServiceUserName"
                                    #editWebServiceUserName="ngModel">

                            </label>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-6">

                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex"
                    (click)="onSubmitWebServicePushDailogue(editWebServicePushform)" label="Update"
                    [disabled]="!editWebServicePushform.form.valid"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnWebServiceCancelClick(editWebServicePushform)" label="Cancel"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>