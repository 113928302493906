
import { ArticleService } from './../../services/article/article.service';
import { Router } from '@angular/router';
import { SharedService } from './../../../shared/services/shared.service';
import { GridApi } from 'ag-grid-community';
import { GridOptions } from 'ag-grid-community';
import { Component, OnInit } from '@angular/core';

var _router: Router;

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',

})
export class CategoryComponent implements OnInit {

  public CategoryID: string;
  public gridOptions: GridOptions;
  public columnDefs: any[];
  public rowData: any[];
  public gridData: any[];
  private api: GridApi;
  public canAddEditAricleCategory: boolean = false;
  totalCount: string;
  constructor(private _sharedService: SharedService, private router: Router, private _aricleCategory: ArticleService) {

    if (!this._sharedService.isAuthUser()) {
      this.router.navigate(['login']);
    }
    else {
      this.updateRoles();
      _router = this.router;
    }

  }

  ngOnInit() {
    this.bindGrid();
    this.loadGridData();
  }
  updateRoles() {
    if (!this._sharedService.UserRole) {
      this._sharedService.updateRole();
    }

    this.canAddEditAricleCategory = true;
  }
  bindGrid() {

    this.gridOptions = <GridOptions>{
      rowSelection: 'single',
      /* pagination:true, */
      onGridReady: () => {
        this.setGridColumns();
        if (this.gridOptions.api != undefined) {
          this.gridOptions.api.sizeColumnsToFit();
          this.api = this.gridOptions.api;
          try {
            this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridCategory'));
            this.gridOptions.api.hideOverlay();
          } catch (e) {
            console.log(e);
          }

        }
      },
      onModelUpdated: (event: any) => {
        if (this.gridOptions.rowData != null && this.gridOptions.rowData != undefined && this.gridOptions.rowData.length > 0) {
          if (this.api != undefined) {
            this.api.sizeColumnsToFit();
          }
        }
      },
      pagination: true,
      paginationPageSize: 20,
      getContextMenuItems: this.getCustomContextMenuItems,
      context: {
        gridContext: this
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      }
    }
  }

  setGridColumns(): void {
    this.columnDefs = [];
    this.columnDefs = [
      { headerName: 'Name', field: 'Name', menuTabs: ['filterMenuTab'], cellRenderer: this.tagsCellRenderer },
      { headerName: 'Description', field: 'Description', menuTabs: ['filterMenuTab'] },
      { headerName: 'Order', field: 'Order', menuTabs: ['filterMenuTab'] },

      {
        headerName: 'Status', field: 'Status', filter: false, suppressMenu: true, valueFormatter: function (params: any) {
          if (params.value == true) {
            return "Active";
          }
          else {
            return "Inactive";
          }
        }
      },

    ];
  }

  tagsCellRenderer(params: any) {
    let html: string = '';

    if (params.value) {

      html = html + '<span class="padding-2 label" style= "background-color:' + params.data.Color + ';">' + params.value + '</span>';

    }

    var eDiv = document.createElement('div');
    eDiv.innerHTML = html

    return eDiv;
  }







  loadGridData(): void {

    let userRole: string = localStorage.getItem('roles').trim();
    this._aricleCategory.getArticleCategoryDetail()
      .subscribe({
        next: (response) => {
          this.gridData = [];
          this.gridData = response;
          this.totalCount = response.length;
        },
        error: (error) => {
          console.log("some thing wrong !");
        }
      });
  }

  //#region Grid Context Menu
  getCustomContextMenuItems(params: any): any {
    let result: any[] = [];

    if (params.context.gridContext.canAddEditAricleCategory) {
      result.push({
        name: "Add Category",
        action: function () {
          params.context.gridContext._sharedService.addAdminCategory(null);
        },
        icon: params.context.gridContext._sharedService.addIconHTMLasString,
      });

      result.push({
        name: "Edit Category",
        action: function () {
          params.context.gridContext.categoryID = null;
          if (params.node) {
            params.context.gridContext.categoryID = params.node.data.ArticleCategoryId;
            params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
            params.context.gridContext._sharedService.updateAdminCategory(params.node.data.ArticleCategoryId);
          }
        },
        icon: params.context.gridContext._sharedService.editIconHTMLasString,
      });

    }
    return result;


  }

  //#endregion


  handlereloadGrid(condition: boolean): void {

    this.loadGridData();
  }
  handleSelectedCategory() {

    this._sharedService.addIconHTMLasString;
    var rowCount = this.api.getSelectedNodes().length;
    if (rowCount > 0) {
      var lastRowData = this.api.getSelectedNodes()[rowCount - 1].data;
      this.CategoryID = lastRowData.ArticleCategoryId.toString();

    }
  }
  RowDoubleClicked(event: any) {

    this.CategoryID = event.data.ArticleCategoryId;
    this._sharedService.updateFromMenuAdminCategory(event.data.ArticleCategoryId);
  }

}
