<section id="widget-grid" class="adminaircraftcls" >

    <div class="row">
        <article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable" id="col1">

            <div class="no-margin jarviswidget jarviswidget-sortable" id="wid-id-2" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false" role="widget">

                <header role="heading" class="p-sortable-handle">

                    <span class="widget-icon"><i class="fa fa-table"></i></span>
                    <h2>Role Group</h2>
                    <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>

                    <div class="jarviswidget-ctrls" role="menu" *ngIf="canAddEditPGroup" title="Edit Role Group (Ctrl+E)">
                        <a class="button-icon hand-cursor tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="btnEditClick()" id="btneditUserGroup" (click)="btnEditClick()">
                            <i class="glyphicon glyphicon-pencil"></i>
                        </a>
                    </div>
                    <div class="jarviswidget-ctrls" role="menu" *ngIf="canAddEditPGroup" title="Add Role Group (Ctrl+A)">
                        <a class="button-icon hand-cursor tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="btnAddClick()" id="btnaddUserGroup" (click)="btnAddClick()">
                            <i class="glyphicon glyphicon-plus"></i>
                        </a>
                    </div>

                </header>


                <div class="widgetdiv">
                    <div class="jarviswidget-editbox">
                    </div>
                    <div id="tagslisting" class="no-bottom-padding widget-body">
                        <section id="widget-grid2" class="">
                            <div class="jqgridboxadminaircraft">
                                <!--style="height:80vh !important;"-->

                                <ag-grid-angular id="agGridPGroups" [navigateToNextCell]="navigateToNextCell"  #agGridPGroups [gridOptions]="gridOptions" [columnDefs]="columnDefs"  [rowData]="gridData" sortable="true" filter=true style="width: 100%;" class="ag-theme-balham tagsgrid" (rowClicked)='updateSelectedRow($event)' (rowDoubleClicked)="rowDoubleClicked($event)">
                                    <!--width: 100%;-->
                                </ag-grid-angular>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </article>

    </div>
</section>
<form #addeditPGform="ngForm" *ngIf="display">
    <p-dialog [(visible)]="display" showEffect="fade" [modal]="true" [style]="{width: '700px'}" [resizable]="false">
        <ng-template pTemplate="header"   (keyup.shift.tab)="btnCancelClick(addeditPGform)">
            {{dialogTitle}}
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row" style="margin-bottom: 10px;">
                    <!-- <div class="form-group col-sm-6">
                        <fieldset style="margin-left: 3px;">
                            <label class="label">
                                Group Name<span [hidden]="addeditRoleGroupName?.valid || model.UserGroup.Name" class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="addeditRoleGroupName" type="text" name="addeditRoleGroupName"
                                       placeholder="Group Name" required [(ngModel)]="model.UserGroup.Name" #addeditRoleGroupName="ngModel">

                            </label>

                        </fieldset>
                    </div>  -->
                    <div class="form-group col-sm-12">
                        <fieldset style="margin-left: 3px;padding-right: 1px;">
                            <label class="label">
                                Group Label<span [hidden]="addeditRoleGroupLabel?.valid || model.UserGroup.RoleLabel" class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="addeditRoleGroupLabel" type="text" name="addeditRoleGroupLabel"
                                       placeholder="Group Label" required [(ngModel)]="model.UserGroup.RoleLabel" #addeditRoleGroupLabel="ngModel">

                            </label>

                        </fieldset>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-sm-12">
                        <div class="panel panel-default">
                            <div class="panel-heading panalheading-padding">
                                <h3 class="panel-title">Permissions</h3>
                            </div>
                            <div class="panel-body">
                                <div class="col-sm-12 picklist-padding fix-picklist-width" >  <!-- style="height: 53vh;" -->
                                    <p-pickList id="rolespicklist" responsive="true" sourceHeader="Available Roles" targetHeader="Selected Roles" [source]="model.AvailableUserRoles" [target]="model.SelectedUserRoles" filterBy="RoleLabel" dragdrop="true" sourceFilterPlaceholder="Search by role" targetFilterPlaceholder="Search by role" [sourceStyle]="{'height':'270px'}" [targetStyle]="{'height':'270px'}">
                                        <ng-template let-role pTemplate="item">
                                            <div>
                                                <div style="font-size:14px">{{role.RoleLabel}}</div>
                                            </div>
                                        </ng-template>
                                    </p-pickList>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex" (click)="onSubmit(addeditPGform)" [label]="lblsubmitButton" [disabled]="!addeditPGform.form.valid || isSubmitted"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" (click)="btnCancelClick(addeditPGform)" label="Cancel" [disabled]="isSubmitted"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>
