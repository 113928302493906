//#region References
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SharedService } from '../../shared/services/shared.service';
import { comm } from '../components/comm/comm';
//#endregion

@Injectable()
export class CommService {
    constructor(private http: HttpClient, private _sharedService: SharedService) {
    }

    getCommData(body: comm): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Comm/GetCommData`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getCommDataWithSearch(body: comm): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Comm/GetCommDataWithSearch`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getPriorityList() {
        return [
            { value: 1, label: 'Urgent' },
            { value: 2, label: 'High' },
            { value: 3, label: 'Normal' },
            { value: 4, label: 'Low' }
        ];
    }
    getAllCommType(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Comm/GetAllCommType`, null, this._sharedService.getReqOptWithAccessToken());
    }
    getAllCommStatus(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Comm/GetAllCommStatus`, null, this._sharedService.getReqOptWithAccessToken());
    }

    getItemByID(body: comm): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Comm/GetCommItemByID`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getAttachmentByID(body: comm): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Comm/GetAttachmentByID`, body, this._sharedService.getReqOptWithAccessToken());
    }
    downloadAttachedFile(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Comm/DownloadAttachment`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getAllActiveCommStatus(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Comm/GetAllActiveCommStatus`, null, this._sharedService.getReqOptWithAccessToken());
    }
    updateCommStatus(body: comm): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Comm/UpdateCommStatus`, body, this._sharedService.getReqOptWithAccessToken());
    }
    addAutoComm(body: comm): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Comm/AddAutoComm`, body, this._sharedService.getReqOptWithAccessToken());
    }
    isAddCommItem(docpath: string, module: string, qualityStatus: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Comm/IsAddCommItem?docPathOrTaskId=${docpath}&module=${module}&status=${qualityStatus}`, this._sharedService.getReqOptWithAccessToken());
    }
    getCommColorCodes() {
        var colorCodes = [
            {
                status: 'new',
                colorCode: '#0082c8'
            },
            {
                status: 'open',
                colorCode: '#a90329'
            },
            {
                status: 'pending',
                colorCode: '#999'
            },
            {
                status: 'closed',
                colorCode: '#71843f'
            },
            {
                status: 'solved',
                colorCode: '#c79121'
            }
        ]
        return colorCodes;
    }
}
