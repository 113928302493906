
export class articleModel {
  public ArticleId?: string;
  public ArticleCategoryId?: string;
  public ArticleCategoryName?: string;
  public ArticleSectionId?: string;
  public ArticleSectionName?: string;
  public ArticleContent: any[];
  public ArticleContentHtml: string;
  public Title?: string;
  public Order?: Number;
  public Color?: string;
  public Status?: boolean;
  public CreatedBy?: string;
  public CreatedOn?: Date;
  public ModifiedBy?: string;
  public ModifiedOn?: Date;
  public Tenants?: string;
  public UserRoles?: string;
  public ArticleComponents?: string;
  public SelectedTenants?: any[];
  public showRoles: boolean = false;
  

}

export interface CatSecAndArticle {
  ArticleCategoryId?: string,
  Name?: string,
  Description?: string,
  Color?: string,
  Status?: boolean,
  CreatedBy?: string,
  CreatedOn?: Date,
  ModifiedBy?: string,
  ModifiedOn?: Date,
  Sections?: {
    ArticleSectionId?: string,
    Description?: string,
    Name?: string,
    Articles?: {
      ArticleId?: number;
      ArticleCategoryId?: string;
      ArticleCategoryName?: string;
      ArticleSectionId?: string;
      ArticleSectionName?: string;
      ArticleContent?: string;
      ArticleContentPlainText?: string;
      Title?: string;
      Order?: Number;
      Color?: string;
      Status?: boolean;
      CreatedBy?: string;
      CreatedOn?: Date;
      ModifiedBy?: string;
      ModifiedOn?: Date;
      Tenants: string;
      UserRoles: string;
    }
  }
}

export interface sectionDetail {
  ArticleSectionId?: string,
  Description?: string,
  Name?: string,
  DisplayColumn?: any,
  Articles?: {
    ArticleId?: string;
    ArticleCategoryId?: string;
    ArticleCategoryName?: string;
    ArticleSectionId?: string;
    ArticleSectionName?: string;
    ArticleContent?: string;
    Title?: string;
    Order?: Number;
    Color?: string;
    Status?: boolean;
    CreatedBy?: string;
    CreatedOn?: Date;
    ModifiedBy?: string;
    ModifiedOn?: Date;
    Tenants: string;
    UserRoles: string;
  }

}



