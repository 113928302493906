//Components
import { Component, OnInit, Input, OnChanges, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgForm, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
//import { GridOptions } from "ag-grid-community";
import { SelectItem, TreeNode } from 'primeng/api';
import { Subscription } from 'rxjs';
import { BrowserModule, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { AssetService } from '../../../admin/services/asset/asset.service'
import { ViewService } from '../../../admin/services/view/view.service';
import { SearchContainerModel } from '../../../records/services/container/container';
import { TaskDocumentService } from '../../../admin/services/task-document/task-document.service';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { APIService } from '../../../api/services/api.service';
import { datafeed } from '../../../api/components/list-datafeed/data-feed';
import { AzureBlobService } from '../../../shared/services/AzureBlob/azure-blob.service';
import * as moment from 'moment/moment';
//import { debug } from 'util';
//var _gridOptions: GridOptions;

@Component({
    selector: 'status-popup',
    templateUrl: './status-popup.component.html',
    providers: [ViewService, AssetService, TaskDocumentService, APIService, AzureBlobService]

})


export class StatusPopup implements OnInit, OnDestroy {

    //#region View List  Variables

    //columnDefs: any[];
    //public gridData: any[];
    //public gridOptions: GridOptions;
    private totalCount: number;
    recordTypeSub: Subscription;
    referenceNumber: string[] = [];
    //#endregion

    //#region Record Popup Variables

    diaplayDialog: boolean = false;
    diaplayExternalDialog: boolean = false;
    containerModel: SearchContainerModel;
    containerData: SelectItem[] = [];
    assets: SelectItem[] = [];
    viewList: SelectItem[] = [];
    assetViewList: SelectItem[] = [];
    categories: SelectItem[] = [];
    recordType: string;
    selectedAsset: TreeNode[] = [];
    showSearch: boolean = false;


    //#endregion

    //#region PDF Viewer variables
    //public displayMode: string;
    subscription: Subscription;
    selectedDoc: string;
    pdfSrc: SafeResourceUrl;
    sanitiz: DomSanitizer;
    zoomOption: SelectItem[] = [];
    selectedOption: string;
    selectedUrl: string;
    selectedZoom: string = "#zoom=auto";
    isCurrentModule: boolean = false;
    diaplayDocLinkDlg: boolean = false;
    docUrl: string;
    dateFormatCalender: string = '';

    //#endregion

    canLinkToExternal: boolean = false;
    FeedMapping: any[] = [];
    externalLinkForm: UntypedFormGroup = new UntypedFormGroup({});

    public appHotkeys: Hotkey[] = [];

    @ViewChild('dCopy') dCopy: ElementRef;

    //#region Initialization of Component
    constructor(private _sharedService: SharedService, private _router: Router, private _assetService: AssetService,
        private sanitizer: DomSanitizer, private _viewService: ViewService, private _taskDocumentService: TaskDocumentService,
        private _hotkeysService: HotkeysService, private _apiService: APIService, private _blobService: AzureBlobService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        } else {
            this.UpdateRoles();
        }
        this.dateFormatCalender = this._sharedService.getCalenderDateFormat();

        this.populateAsset();
        this.populateViews();
        this.sanitiz = sanitizer;
        this.getZoomOptions();
        this.setHotKeys();
    }

    ngOnInit() {
        this.containerModel = new SearchContainerModel("", "", null, false);
        this.isCurrentModule = this._sharedService.selectedModule == "record" || this._sharedService.selectedModule == "transition";
        // this.populateMapping();
    }
    Download() {
        let docName = this.docUrl.split('/').pop().split('?')[0];//this.docUrl;
        this._apiService.downloadSearchDocument(docName)
        .subscribe({
            next: (response) => {
                let resList = response;
                if (resList) {
                    this.downloadFile(docName, resList);
                }
            },
            error: (error) => {
                this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Not found, please try again later.' });
            }
        });
    }


    downloadFile(docName: string, content: any) {

        let docNamenExt = docName.split('.');
        if (docNamenExt.length >= 2) {
            let docExt = '.' + docNamenExt[1];
            docName = docName.replace(docExt, ".txt");
        }
        else {
            docName = docName.replace(".pdf", ".txt").replace(".PDF", ".txt");
        }
        let blob = new Blob([content], { type: 'application/text' });
        let url = URL.createObjectURL(blob);
        let fileName = docName;
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.target = '_blank';
        a.download = fileName;
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    setHotKeys() {

        let hotKeyObj: Hotkey = null;

        //Attach From Status
        hotKeyObj = new Hotkey('ctrl+shift+f', (event: KeyboardEvent): boolean => {
            this.btnAddClick();
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

        hotKeyObj = new Hotkey('ctrl+shift+s', (event: KeyboardEvent): boolean => {
            if (this.canLinkToExternal) { this.btnLinkExternalClick(); }
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
    }

    populateMapping() {
        // let body: datafeed = {
        //     FeedType: 'Outgoing',
        //     TargetType: 'Web Service',
        //     SourceType: 'Records',
        //     SourceDetail: 'Document Url'
        // };
        this._apiService.getFeedDataForTransferScan()
        .subscribe({
            next: (response) => {
                let resList = response.MappingList;
                resList.forEach(ele => {
                    let field = ele.MappingColumn.split('||');
                    if (field[1]) {
                        this.FeedMapping.push({ Field: field[1].trim(), Label: field[0] });
                        this.externalLinkForm.addControl(`field_${field[1].trim()}`, new UntypedFormControl());
                    }
                });
            },
            error: (error) => {
                this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again later.' });
            }
        });
    }

    getLinkClick() {
        this._sharedService.clearToast();
        this._sharedService.showToast({ severity: 'success', summary: 'Copied!', detail: 'Document link has been copied succesfully.' });
    }

    CreateUpdateLink() {
        
        let formData = this.externalLinkForm.getRawValue();
        
        console.log("formdata",formData);
        if (formData.field_DocNo != "") {
            let body = { ...this._sharedService.selectedDocument };
            if (formData.field_ASN) {
                body.asn = formData.field_ASN;
            }
            if (formData.field_DocNo) {
                body.DocNo = formData.field_DocNo;
            }
            if (formData.field_ReferenceKey) {
                body.ReferenceKey = formData.field_ReferenceKey;
            }
            if (formData.field_DocDate) {
                body.DocDate = this.getDate(formData.field_DocDate);
            }
            if (formData.field_DocType) {
                body.DocType = formData.field_DocType;
            }
            if (formData.field_Comments) {
                body.Comments = formData.field_Comments;
            }
            if (formData.field_uuid) {
                body.Uuid = formData.field_uuid;
            }
            if (this._sharedService.selectedContainer) {
                body.ID = this._sharedService.selectedContainer.ID;
                body.Type = this._sharedService.selectedContainer.Type;
                body.ContainerName = this._sharedService.selectedContainer.ContainerName;
            }
            body.url = formData.field_URL;
            body.Location = "Document_Link_Records_Manual_Setting";
            this._sharedService.showLoader("Processing...");
            this._apiService.updateLink(body)
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.diaplayExternalDialog = false;
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Link has been created succesfully.' });
                    }
                    this._sharedService.hideLoader();
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    if (error.status == 501) {
                        this._sharedService.showToast({ severity: 'warn', summary: 'Unable to update document URL', detail: error.error });
                    } else {
                        this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again later.' });
                    }
                }
            });
        } else {
            this._sharedService.hideLoader();
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Document Number is required to Link the DFP Document.' });
        }
    }

    ShowGetDocDlg() {
        this.docUrl = `${window.location.origin}/Document?${this.getDocUrl(this._sharedService.selectedDocument.Url)}`;
        this.diaplayDocLinkDlg = true;
    }

    getDate(date: Date): string {
        if (date != null) {
            //let newDate = moment(date).format("YYYY-MM-DD");
            //return date.toDateString()
            let newDate = moment(date).format("YYYY-MM-DD");
            return new Date(newDate).toDateString();
        }
        return null;
    }


    CloseLinkDlgClick() {
        this.diaplayDocLinkDlg = false;
    }

    //#endregion

    //#region View List


    ngOnDestroy() {
        if (this.recordTypeSub) {
            this.recordTypeSub.unsubscribe();
        }

        //console.log('destroying component =>' + this._router.url);
        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
    }

    updateSelectedDocument(event: any) {

        this.UpdateSelectedDoc(event.data);
    }

    //#endregion

    //#region Task Selection Popup

    populateAsset() {
        this._assetService.getAssetByUserRole()
        .subscribe({
            next: (response) => {
                this.assets = response;
            },
            error: (error) => {
                this.assets = [];
                console.log(error);
            }
        });
    }

    populateViews() {
        //US-8181 //let asn = this._sharedService.selectedAsset[0].data.split('|')[1];
        let asn = JSON.parse(this._sharedService.selectedAsset[0].data).ASN;
        this._viewService.getViewsDropDownList(asn)
        .subscribe({
            next: (response) => {
                this.viewList = response;
            },
            error: (error) => {
                console.log(error);
            }
        });
    }

    onChangeView(event: any) {
        
        if (this.selectedAsset.length > 0) {
            //Update view setting Id
            //US-8181 //19-06-2021
            // let dataArray: any[] = this.selectedAsset[0].data.toString().split("|");
            // if (dataArray.length > 2) {
            //     dataArray[2] = this.containerModel.viewSettingId.split('|')[3];
            //     this.selectedAsset[0].data = dataArray.join("|");
            // } else {
            //     this.selectedAsset[0].data = this.selectedAsset[0].data + "|" + this.containerModel.viewSettingId.split('|')[3];
            // }


            let assetDataObj = JSON.parse(this.selectedAsset[0].data);
            if (assetDataObj.viewSettingId) {
                assetDataObj.viewSettingId = this.containerModel.viewSettingId.split('|')[3];
            } else { assetDataObj.viewSettingId = this.containerModel.viewSettingId.split('|')[3]; }

            this.selectedAsset[0].data = JSON.stringify(assetDataObj);

        } else {
            this.selectedAsset.push({ data: "||" + this.containerModel.viewSettingId });
        }
        try {

            let assetList = [{
                label: this._sharedService.selectedAsset[0].label,
                collapsedIcon: this._sharedService.selectedAsset[0].collapsedIcon,
                expandedIcon: this._sharedService.selectedAsset[0].expandedIcon,
                data: this.selectedAsset[0].data
            }];
            this._sharedService.onAssetStatusChanged(assetList);
        }
        catch (ex) {
            console.log(ex);
        }
        // this._sharedService.onAssetStatusChanged(this._sharedService.selectedAsset);
    }

    onChangeAsset(event: any) {

        //value = string.Format("{0}|{1}|{2}", ast.StoragePath, ast.ASN, ast.AssetType),
        //US-8181 //let asn = event.value.split('|')[1];
        let assetDataObj = JSON.parse(event.value);
        this._viewService.getViewsDropDownList(assetDataObj.ASN)
        .subscribe({
            next: (response) => {
                this.viewList = response;
                this.selectedAsset = [];
                this.selectedAsset.push({ data: event.value });
                this.assetViewList = [];
                let assetType = assetDataObj.AssetType; //event.value.split('|')[2];
        
                for (var i = 0; i < this.viewList.length; i++) {
                    let viewDetail: string[] = this.viewList[i].value.split('|');
                    let availableAssets: string[] = viewDetail[2] ? viewDetail[2].split(',') : null;
        
                    //0-ViewType, 1-AllAsset, 2-Available Assets list, 3-ViewId
                    if (assetType == viewDetail[0] && viewDetail[1] == 'True') {
                        this.assetViewList.push(this.viewList[i]);
                    } else if (availableAssets && availableAssets.length > 0 && availableAssets.find(a => a == assetDataObj.ASN)) {
                        this.assetViewList.push(this.viewList[i]);
                    }
                }
        
                if (this.containerModel.viewSettingId != '0') {
                    //US-8181 //let dataArray = this.selectedAsset[0].data.split('|');
                    //this.selectedAsset[0].data = dataArray[0] + '|' + dataArray[1] + '|' + this.containerModel.viewSettingId.split('|')[3];
                    //US-8181 //let sharedDataArray = this._sharedService.selectedAsset[0].data.split('|');
                    let assetList = [{
                        label: this._sharedService.selectedAsset[0].label,
                        collapsedIcon: this._sharedService.selectedAsset[0].collapsedIcon,
                        expandedIcon: this._sharedService.selectedAsset[0].expandedIcon,
                        //data: dataArray[0] + '|' + dataArray[1] + '|' + this.containerModel.viewSettingId.split('|')[3] + '|' + sharedDataArray[3] + '|' + sharedDataArray[4]
        
                        //Todo: //R.A./ critical object structure updates for AssetStatus
                        //19-06-2021
                        data: this.selectedAsset[0].data,
                        sharedData: this._sharedService.selectedAsset[0].data,
                        viewSetting: this.containerModel.viewSettingId,
                        viewSettingId: this.containerModel.viewSettingId.split('|')[3]
                    }];
                    this._sharedService.onAssetStatusChanged(assetList);
                } else {
                    //dropdown Asset selection should not be published globaly did due to this error
                    //Bug 8022: Records> PDF Section> "Link to ROAM-Status": Ambiguous Behavior
                    // this._sharedService.onAssetStatusChanged(this._sharedService.selectedAsset);
                }
            },
            error: (error) => {
                console.log(error);
            }
        });
    }

    attachDocument() {
        if (this._sharedService.docToAttach.length && this._sharedService.docToAttach[0].taskId && this._sharedService.docToAttach[0].taskId.length > 0 && this.containerModel.viewSettingId != '0') {
            this._sharedService.showLoader("Processing...");
            let viewTaskDocument = {
                TaskBlobList: this._sharedService.docToAttach,
                View: { Source: true }
            }
            this._taskDocumentService.addTaskDocuments(viewTaskDocument)
            .subscribe({
                next: (response) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Task(s) attached succesfully.' });
                    this.diaplayDialog = false;
                    this._sharedService.docToAttach = [];
                },
                error: (error) => {
                    if (error.status == 501) {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'Unable to update document URL', detail: error.error });
                        this._sharedService.hideLoader();
                        this.diaplayDialog = false;
                        this._sharedService.docToAttach = [];
                    }
                    else {
                        this._sharedService.hideLoader();
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Cannot attach task(s), please try again.' });
                    }
                }
            });            

        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Row selected', detail: 'Please select row(s) to attach task.' });
        }
    }
    afterStatusPopupClosed(event: any) {
        //R.A.;
        // dropdown Asset selection should not be published globaly did due to this error
        //Bug 8022: Records> PDF Section> "Link to ROAM-Status": Ambiguous Behavior
        //this._sharedService.onAssetStatusChanged(null);    
        // this._sharedService.onAssetChanged(null);
        //this._sharedService.onAssetStatusChanged(this._sharedService.selectedAsset);
        // this._sharedService.onAssetChanged(this._sharedService.selectedAsset);
        this.containerModel = new SearchContainerModel("", "", null, false);
        this.containerModel.viewSettingId = "0";
    }
    updateCss() {
        let conPanelHeader = document.getElementById('conPanelHeader');
        let docPanelHeader = document.getElementById('docPanelHeader');
        let pdfPanelHeader = document.getElementById('pdfPanelHeader');

        if (conPanelHeader) { conPanelHeader.style.margin = '0px'; }
        if (docPanelHeader) { docPanelHeader.style.margin = '0px'; }
        if (pdfPanelHeader) { pdfPanelHeader.style.margin = '0px'; }
    }

    btnAddClick() {
        this._sharedService.onAssetStatusChanged(null);
        this.containerModel.viewSettingId = "0";
        this.setSelectedAsset();
        this.actionAfterinterval();
        this._sharedService.docToAttach = [];
        this.diaplayDialog = true;
        return false;
    }

    btnLinkExternalClick() {
        // let body: datafeed = {
        //     FeedType: 'Outgoing',
        //     TargetType: 'File Storage',
        //     SourceType: 'Records',
        //     SourceDetail: 'Document Url'
        // };
        this._apiService.getFeedDataForTransferScan()
        .subscribe({
            next: (response) => {
                this._sharedService.clearToast();
                if (!response) {
                    this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'No active feed found for connectivity in API>Outgoing' });
                    return false;
                }
        
                if (response && response.MappingList && response.MappingList.length > 0) {
                    let resList = response.MappingList;
                    this.FeedMapping = [];
                    resList.forEach(ele => {
                        let field = ele.MappingColumn.split('||');
                        if (field[1]) {
                            this.FeedMapping.push({ Field: field[1].trim(), Label: field[0] });
                            this.externalLinkForm.addControl(`field_${field[1].trim()}`, new UntypedFormControl());
                        }
                    });
        
                    this.diaplayExternalDialog = true;
                    this.poulateFieldData();
                } else {
        
                    this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Mapping does not exist.  ' });
                }
            },
            error: (error) => {
                this._sharedService.showToast({ severity: 'error', life: 3000, summary: 'Error', detail: 'Error occurred, please try again later.' });
            }
        });

        //this.woNo.nativeElement.value = this._sharedService.selectedDocument.DocNo? this._sharedService.selectedDocument.DocNo : '';
        //this.documentUrl.nativeElement.value = this._sharedService.selectedDocument.Url.split('?')[0];
        return false;
    }
    poulateFieldData() {
        
        if (this.externalLinkForm.contains('field_ASN')) {
            let assetDataObj = JSON.parse(this._sharedService.selectedAsset[0].data).ASN;
            // let asnVal = this._sharedService.selectedAsset[0].data.toString().split("|")[0];
            this.externalLinkForm.controls['field_ASN'].setValue(assetDataObj);
        }
        
        if (this.externalLinkForm.contains('field_DocNo')) {
            let docNoVal = this._sharedService.selectedDocument.DocNo ? this._sharedService.selectedDocument.DocNo : '';
            this.externalLinkForm.controls['field_DocNo'].setValue(docNoVal);
        }
        if (this.externalLinkForm.contains('field_DocType')) {
            let docNoVal = this._sharedService.selectedDocument.DocType ? this._sharedService.selectedDocument.DocType : '';
            this.externalLinkForm.controls['field_DocType'].setValue(docNoVal);
        }
        if (this.externalLinkForm.contains('field_Comments')) {
            let commentVal = this._sharedService.selectedDocument.Comments ? this._sharedService.selectedDocument.Comments : '';
            this.externalLinkForm.controls['field_Comments'].setValue(commentVal);
        }
        if (this.externalLinkForm.contains('field_DocDate')) {
            let docNoVal = this._sharedService.selectedDocument.DocDate ? new Date(this._sharedService.selectedDocument.DocDate) : '';
            this.externalLinkForm.controls['field_DocDate'].setValue(docNoVal);
        }
        if (this.externalLinkForm.contains('field_ReferenceKey')) {
            let referenceKeyVal = this._sharedService.selectedDocument.ReferenceKey ? this._sharedService.selectedDocument.ReferenceKey : '';
            this.externalLinkForm.controls['field_ReferenceKey'].setValue(referenceKeyVal);
        }
        if (this.externalLinkForm.contains('field_URL')) {
            let urlVal = `${window.location.origin}/Document?${this.getDocUrl(this._sharedService.selectedDocument.Url)}`;
            this.externalLinkForm.controls['field_URL'].setValue(urlVal);
        }
        if (this.externalLinkForm.contains('field_uuid')) {
            let uuidVal = this._sharedService.selectedDocument.Uuid ? this._sharedService.selectedDocument.Uuid : '';
            this.externalLinkForm.controls['field_uuid'].setValue(uuidVal);
        }

    }

    getDocUrl(fileUrl: string): string {

        let documentURL = fileUrl;

        if (fileUrl.indexOf('api/Blob/GetDocument/') != -1) {

            documentURL = fileUrl.replace('api/Blob/GetDocument/', "");
        }

        let newString: string[] = documentURL.split('?')[0].split('/');
        newString.shift();
        newString.shift();
        newString.shift();
        let docUrl: string = newString.join('/');
        return docUrl;

    }

    CancelExternalLinkClick() {
        this.diaplayExternalDialog = false;
    }

    setSelectedAsset() {
        //Set selected Asset and
        if (this._sharedService.selectedAsset && this._sharedService.selectedAsset[0]) {
            //US-8181 //let dataArray = this._sharedService.selectedAsset[0].data.split('|');
            // let dataArray = this._sharedService.selectedAsset[0].data.split('|');
            //let formatedData = dataArray[0] + '|' + dataArray[1] + '|' + dataArray[4];
            let formatedData = this._sharedService.selectedAsset[0].data;
            this.containerModel.asn = formatedData;
            this.selectedAsset = [];
            this.selectedAsset.push({ data: formatedData });
            this.onChangeAsset({ value: formatedData });
        }
    }
    //#endregion

    actionAfterinterval() {

        setTimeout(() => {

            this.updateCss();

        },
            2000);
    }

    //#region PDF Viewer
    UpdateSelectedDoc(selDoc: any) {
        this.selectedUrl = selDoc.Url;
        if (this._sharedService.searchText && this._sharedService.searchText.indexOf('"') == -1) {
            this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(selDoc.Url + this.selectedZoom + '&search="' + this._sharedService.searchText + '"');
        } else {
            this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(selDoc.Url + this.selectedZoom + '&search="' + this._sharedService.searchText + '"&phrase=true');
        }
    }

    zoomChange(event: any) {
        if (this._sharedService.searchText && this._sharedService.searchText.indexOf('"') == -1) {
            this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(this.selectedUrl + this.selectedOption + '&search="' + this._sharedService.searchText + '"');
        } else {
            this.pdfSrc = this.sanitiz.bypassSecurityTrustResourceUrl(this.selectedUrl + this.selectedOption + '&search="' + this._sharedService.searchText + '"&phrase=true');
        }
        this.selectedZoom = this.selectedOption;
    }

    getZoomOptions() {
        this.zoomOption.push({ value: '#zoom=auto', label: 'Automatic Zoom' })
        this.zoomOption.push({ value: '#zoom=scale', label: 'Actual Size' })
        this.zoomOption.push({ value: '#zoom=50', label: 'Page Fit' })
        this.zoomOption.push({ value: '#zoom=auto', label: 'Page Width' })
        this.zoomOption.push({ value: '#zoom=50', label: '50%' })
        this.zoomOption.push({ value: '#zoom=100', label: '100%' })
        this.zoomOption.push({ value: '#zoom=200', label: '125%' })
        this.zoomOption.push({ value: '#zoom=150', label: '150%' })
        this.zoomOption.push({ value: '#zoom=200', label: '200%' })
        this.zoomOption.push({ value: '#zoom=300', label: '300%' })
        this.zoomOption.push({ value: '#zoom=400', label: '400%' })
    }

    //#region Wide / Full Screen
    widePdfPanal() {
        if ((this._router.url.indexOf("/record/search") == -1)
            && (this._router.url.indexOf("/transition/search") == -1)) {
            this.wideRecordPdfPanal();
        }
        else {
            this.wideSearhPdfPanal();
        }
    }

    wideRecordPdfPanal() {
        let containerPanel = document.getElementById('col1');
        let docPanel = document.getElementById('col2');
        let pdfPanel = document.getElementById('col3');
        let btnFullScreen = document.getElementById('btnPdfFullScreen');

        if (pdfPanel.classList.contains('col-sm-6')) {
            //Remove old Classes
            pdfPanel.classList.remove('col-sm-6', 'col-md-6', 'col-lg-6');
            //Add new Classes
            pdfPanel.classList.add('col-sm-9', 'col-md-9', 'col-lg-9');
            containerPanel.style.display = 'none';
            //Update Doc Panel if it's wide
            if (docPanel.classList.contains('col-sm-6')) {
                docPanel.classList.remove('col-sm-6', 'col-md-6', 'col-lg-6');
                docPanel.classList.add('col-sm-3', 'col-md-3', 'col-lg-3');
            }

            //Disable Full Screen Button
            btnFullScreen.classList.add('disable-anchor');

        } else {
            //Remove old Classes
            pdfPanel.classList.remove('col-sm-9', 'col-md-9', 'col-lg-9');
            //Add new Classes
            pdfPanel.classList.add('col-sm-6', 'col-md-6', 'col-lg-6');
            containerPanel.style.display = 'block';

            //Enable Full Screen Button
            btnFullScreen.classList.remove('disable-anchor');
        }
    }

    wideSearhPdfPanal() {
        let filterPanel = document.getElementById('search-col1');
        let docPanel = document.getElementById('search-col2');
        let pdfPanel = document.getElementById('col3');
        let btnFullScreen = document.getElementById('btnPdfFullScreen');

        if (pdfPanel.classList.contains('col-sm-6')) {
            //Remove old Classes
            pdfPanel.classList.remove('col-sm-6', 'col-md-6', 'col-lg-6');
            //Add new Classes
            pdfPanel.classList.add('col-sm-8', 'col-md-8', 'col-lg-8');
            filterPanel.style.display = 'none';
            //Update Doc Panel if it's wide
            if (docPanel.classList.contains('col-sm-6')) {
                docPanel.classList.remove('col-sm-6', 'col-md-6', 'col-lg-6');
                docPanel.classList.add('col-sm-4', 'col-md-4', 'col-lg-4');
            }

            //Disable Full Screen Button
            btnFullScreen.classList.add('disable-anchor');

        } else {
            //Remove old Classes
            pdfPanel.classList.remove('col-sm-8', 'col-md-8', 'col-lg-8');
            //Add new Classes
            pdfPanel.classList.add('col-sm-6', 'col-md-6', 'col-lg-6');
            filterPanel.style.display = 'block';

            //Enable Full Screen Button
            btnFullScreen.classList.remove('disable-anchor');
        }
    }

    fullScreenPdfPanal() {
        let containerPanel, docPanel;
        let pdfPanel = document.getElementById('col3');
        let btnWideScreen = document.getElementById('btnPdfWideScreen');

        if ((this._router.url.indexOf("/record/search") == -1)
            && (this._router.url.indexOf("/transition/search") == -1)) {
            containerPanel = document.getElementById('col1');
            docPanel = document.getElementById('col2');
        }
        else {
            containerPanel = document.getElementById('search-col1');
            docPanel = document.getElementById('search-col2');
        }

        if (pdfPanel.classList.contains('col-sm-6')) {
            //Remove old Classes
            pdfPanel.classList.remove('col-sm-6', 'col-md-6', 'col-lg-6');
            //Add new Classes
            pdfPanel.classList.add('col-sm-12', 'col-md-12', 'col-lg-12');
            containerPanel.style.display = 'none';
            docPanel.style.display = 'none';

            //Disable Wide Screen Button
            btnWideScreen.classList.add('disable-anchor');

        } else {
            //Remove old Classes
            pdfPanel.classList.remove('col-sm-12', 'col-md-12', 'col-lg-12');
            //Add new Classes
            pdfPanel.classList.add('col-sm-6', 'col-md-6', 'col-lg-6');
            containerPanel.style.display = 'block';
            docPanel.style.display = 'block';

            //Enable Wide Screen Button
            btnWideScreen.classList.remove('disable-anchor');
        }
    }

    //#endregion

    //setDisplayMode() {
    //    let url = this._router.url;
    //    if (url.indexOf("/record/") > -1) {
    //        this.displayMode = "record";

    //    } else if (url.indexOf("/status/") > -1) {
    //        this.displayMode = "status";

    //    } else if (url.indexOf("/admin/") > -1) {
    //        this.displayMode = "admin";
    //    }
    //}



    //#endregion

    //#region Roles
    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }
        let url = this._router.url;
        this._sharedService.getConfigurationByKey("Document_Link_Records_Manual")
        .subscribe({
            next: (response) => {
                let isFeatureEnabled: boolean = Boolean(response.Value == 'True' ? true : false);
        
                if (url.indexOf("/record/browse") > -1) {
                    this.canLinkToExternal = this._sharedService.UserRole.Records_MXR_Link_To_External_System && isFeatureEnabled;
        
                }
                else if (url.indexOf("/record/logs") > -1) {
                    this.canLinkToExternal = this._sharedService.UserRole.Records_LOG_Link_To_External_System && isFeatureEnabled;
                }
                else if (url.indexOf("/record/general") > -1) {
                    this.canLinkToExternal = this._sharedService.UserRole.Records_GEN_Link_To_External_System && isFeatureEnabled;
        
                }
                else if (url.indexOf("/record/documents") > -1) {
                    this.canLinkToExternal = this._sharedService.UserRole.Records_DOC_Link_To_External_System && isFeatureEnabled;
        
                }
                else if ((url.indexOf("/record/search") > -1) || (url.indexOf("/transition/search") > -1)) {
                    this.canLinkToExternal = this._sharedService.UserRole.Records_GEN_Link_To_External_System && isFeatureEnabled;
        
                }
            },
            error: (error) => {
                console.log(error);
            }
        });


    }

    //#endregion


    onKeyStatus() {

        this.diaplayDialog = false;
    }

    applyFocusToCopyLink() {

        this.dCopy.nativeElement.focus();
    }
}
