<div class="jarviswidget-ctrls" title="Add Item (Ctrl+Shift+A)" *ngIf="!this.isDirectModal">
    <a [attr.tabindex]="0" class="button-icon tablIndexFocus" id="btnadditem" (keyup.enter)="showAddDialog()"
        (click)="showAddDialog()">
        <i class="glyphicon glyphicon-plus"></i>
    </a>
</div>

<form *ngIf="commdisplay" #addItemForm="ngForm">
    <p-dialog id="additemdlg" *ngIf="commdisplay" [(visible)]="commdisplay" showEffect="fade" [modal]="true"
        [style]="{width: '80vw'}" [resizable]="false" (onShow)="adjustComDlgHeight($event)">
        <ng-template pTemplate="header" (keyup.shift.tab)="onKey()">
            {{dialogTitle}}
        </ng-template>
        <div class="upload-dialog" id="commAddDlgOuter">
            <div id="commAddDlg" class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                Type<span [hidden]="commtype.valid || (model.CommTypeId!=null && model.CommTypeId >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" [attr.tabindex]="0" [autofocus]="true" id="commtype"
                                name="commtype" [options]="commTypeList" required placeholder="Select Type"
                                [(ngModel)]="model.CommTypeId" #commtype="ngModel" [style]="{height:'31px'}">

                            </p-dropdown>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                Priority<span
                                    [hidden]="commpriority.valid || (model.Priority!=null && model.Priority >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" [attr.tabindex]="0" id="commpriority" name="commpriority"
                                [options]="priorityList" required placeholder="Select Priority"
                                [(ngModel)]="model.Priority" #commpriority="ngModel" [style]="{height:'31px'}">

                            </p-dropdown>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                Status<span
                                    [hidden]="commstatus.valid || (model.CommStatusId!=null && model.CommStatusId != '0')"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" [attr.tabindex]="0" id="commstatus" name="commstatus"
                                [options]="commStatusList" [filter]="false" placeholder="Select Status" required
                                [(ngModel)]="model.CommStatusId" #commstatus="ngModel" [style]="{height:'31px'}">
                            </p-dropdown>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                Requested by<span
                                    [hidden]="requestedby.valid || (model.RequestedBy!=null && model.RequestedBy.length >0)"
                                    class="text-danger">
                                    *
                                </span>
                            </label>

                            <p-dropdown appendTo="body" [attr.tabindex]="0" id="requestedby" name="requestedby"
                                [options]="requestedByList" filter=true [resetFilterOnHide]="true" required
                                [(ngModel)]="model.RequestedBy" #requestedby="ngModel" [style]="{width:'341px',height:'31px'}"
                                [autoDisplayFirst]="false">

                            </p-dropdown>

                        </fieldset>
                    </div>
                    <div class="form-group col-sm-4">
                        <fieldset>
                            <label class="label">
                                Assigned To
                            </label>

                            <!--<p-dropdown appendTo="body" id="commassignedto" name="commassignedto" [options]="assignedToList" placeholder="Select Type" [(ngModel)]="model.AssignedTo" #commassignedto="ngModel">

                            </p-dropdown>-->
                            <p-dropdown appendTo="body" [attr.tabindex]="0" id="commassignedto" name="commassignedto"
                                [options]="assignedToList" filter=true #comassto [resetFilterOnHide]="true"
                                placeholder="Select Type" [(ngModel)]="model.AssignedTo" #commassignedto="ngModel" [style]="{height:'31px'}">
                                <ng-template let-item pTemplate="selectedItem">
                                    <div class="col-md-9" [ngClass]="getSelectedClass(item.value)">
                                        <span>{{item.label}}</span></div>
                                    <div class="col-md-3"><span class="group-tags"
                                            [style.background-color]="getTagColor(item.value)">{{getUserDepartment(item.value)}}</span>
                                    </div>
                                </ng-template>
                                <ng-template let-users pTemplate="item">
                                    <div style="position: relative;height: 14px;">
                                        <div class="col-md-9" [ngClass]="getSelectedClass(users.value)">
                                            <span>{{users.label}}</span></div>
                                        <div class="col-md-3"><span class="group-tags"
                                                [style.background-color]="getTagColor(users.value)">{{getUserDepartment(users.value)}}</span>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </fieldset>
                    </div>
                    <div class="form-group col-sm-4 width-100">
                        <fieldset>
                            <label class="label">
                                Due Date ({{ViewDateFormat}})
                            </label>
                            <label class="input">

                                <span class="col-sm-11">
                                    <p-calendar appendTo="body" (onClose)="onCloseDate($event)"
                                        placeholder="{{ViewDateFormat}}" [attr.tabindex]="0"
                                        class='datepicker hasDatepicker' id='duedate' name="duedate"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030"
                                        [(ngModel)]="model.DueDate" #duedate="ngModel" [dateFormat]="dateFormatCalender"
                                        [style]="{width:'100%'}">

                                    </p-calendar>
                                </span>
                                <span class="col-sm-1">
                                    <i class='icon-append fa fa-calendar padding-left-tablet'></i>
                                </span>



                            </label>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                CCs
                            </label>
                            <!-- <p-multiSelect id="emailccs" [options]="emailCcsList" [(ngModel)]="selectedCCs" [style]="{width:'100%',height:'33px'}" name="emailccs" #emailccs="ngModel"></p-multiSelect> -->

                            <div [attr.tabindex]="0" class="tablIndexFocus">
                                <!-- <angular2-multiselect [attr.tabindex]="0" (keyup.esc)="emailCCEscClick($event)" id="emailccs" [data]="emailCcsList" [(ngModel)]="selectedCCsTemp" [settings]="dropdownSettings" (onAddFilterNewItem)="onAddItem($event)" name="emailccs" #emailccs="ngModel">
                            </angular2-multiselect> -->
                                <p-multiSelect id="emailccs" [options]="emailCcsList" placeholder="Select Users"
                                    [(ngModel)]="selectedCCsTemp" name="emailccs" [maxSelectedLabels]="4"
                                    selectedItemsLabel="{0} items selected" appendTo="body"></p-multiSelect>

                            </div>
                        </fieldset>
                    </div>
                    <!--<div class="form-group col-sm-8">
                        <fieldset>
                            <label class="label">
                                CCs(Other User)
                            </label>
                            <p-chips id="otheremailcss" [(ngModel)]="model.CssOther" name="otheremailcss" #otheremailcss="ngModel" styleClass="chips-control" inputStyleClass="chips-control-input" addOnBlur="true" addOnTab="true" allowDuplicate="false"></p-chips>
                        </fieldset>
                    </div>-->
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Subject<span [hidden]="subject.valid && model.Subject" class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input [attr.tabindex]="0" id="subject" type="text" name="subject"
                                    (keyup.enter)="subjectEnterClick($event)" placeholder="Subject"
                                    [(ngModel)]="model.Subject" required #subject="ngModel">
                            </label>

                        </fieldset>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Description
                            </label>
                            <label class="textarea">
                                <p-editor name="description" [attr.tabindex]="0" [(ngModel)]="model.Description"
                                    [style]="{'height':'120px'}">
                                    <!--<ng-template pTemplate="header" >
                                        <span class="ql-formats">
                                            <button class="ql-bold" aria-label="Bold"></button>
                                            <button class="ql-italic" aria-label="Italic"></button>
                                            <button class="ql-underline" aria-label="Underline"></button>
                                        </span>
                                        <span class="ql-formats">
                                            <button class="ql-list" value="ordered" type="button"></button>
                                            <button class="ql-list" value="bullet" type="button"></button>
                                            <span class="ql-align ql-picker ql-icon-picker"></span>
                                        </span>
                                    </ng-template>-->
                                </p-editor>
                                <!--<textarea id="description" name="description" #description="ngModel" [(ngModel)]="model.Description" class="custom-scrollNote no-overflow-x" rows="4"></textarea>--></label>

                        </fieldset>

                    </div>
                </div>
                <div class="row">
                    <fieldset>
                        <div class="jarviswidget" id="wid-id-1" data-widget-togglebutton="false"
                            data-widget-sortable="false" data-widget-fullscreenbutton="false"
                            data-widget-colorbutton="false" data-widget-editbutton="false"
                            data-widget-custombutton="false" data-widget-deletebutton="false" role="widget">

                            <header role="heading" style="margin-left: 0px;margin-right: 0px;">
                                <span class="widget-icon"><i class="fa fa-comments"></i></span>
                                <h2>Conversation</h2>
                            </header>
                            <!-- widget div-->
                            <div class="widgetdiv no-padding">
                                <!-- widget content -->
                                <div class="widget-body">
                                    <div>
                                        <div class="comments-list">
                                            <div class="media">
                                                <div class="media-body">
                                                    <textarea id="conversation" [attr.tabindex]="0"
                                                        placeholder="Write your comment..." name="conversation"
                                                        #conversation="ngModel" [(ngModel)]="model.Conversation"
                                                        class="custom-scrollNote no-overflow-x padding-left inbox-textarea"
                                                        rows="2" style="width:100%"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end widget content -->
                                </div>

                                <!-- end widget div -->
                            </div>
                            <!-- end widget -->
                        </div>
                        <!--<div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Conversation
                            </label>

                            <label class="textarea">

                                <textarea id="conversation" name="conversation" #conversation="ngModel" [(ngModel)]="model.Conversation" class="custom-scrollNote no-overflow-x" rows="6"></textarea>
                            </label>

                        </fieldset>

                    </div>-->
                    </fieldset>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">Attachment</label>
                            <p-fileUpload name="comm" [attr.tabindex]="0" (onSelect)="onSelect($event)"
                                (onRemove)="onRemove($event)" multiple="multiple" customUpload="true"
                                uploadHandler="onBulkUpload($event,addItemForm)" chooseLabel="Browse"
                                [(ngModel)]="bulkFiles" #comm ngDefaultControl [showCancelButton]="false"
                                [showUploadButton]="false">
                            </p-fileUpload>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex"
                    (click)="onBulkUpload($event,addItemForm)" label="Add"
                    [disabled]="!addItemForm.form.valid || isSubmitted"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                    (click)="btnCancelClick(addItemForm)" label="Cancel" [disabled]="isSubmitted"
                    (focusout)="dd.applyFocus()"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>