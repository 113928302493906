<style>
a:visited {
    color: #3276b1 !important;
}

</style>

<div class="no-top-padding" style="background-color:#f3f3f3 !important;">

  <!-- MAIN CONTENT -->
  <div class="container">

      <div class="row login-top-padding" >
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 hidden-xs hidden-sm">

          </div>
          <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8" >
              <div style="margin-top:16.5vh;height: 100vh;">
                <div *ngIf="IsEmailCorrect==false"  class="alert adjusted alert-warning fade in" style="margin-bottom:10px;">
                  <button class="close" data-dismiss="alert">
                      ×
                  </button>
                  <i class="fa fa-fw fa-lg fa-exclamation"></i>
                  The server could not find you. Make sure your E-mail is correct and then try again
                </div>

                  <form class="smart-form client-form"   #FormUrl="ngForm" style="border:1px solid #ddd;background-color: gray;" >
                      <header>
                          <!--Sign In-->
                          <span id="logo" style="margin:0px;">
                              <img src="../../../../assets/img/ROAMLogo.png" alt="ROAM">

                          </span>
                      </header>


                       <fieldset>
                         <section>
                          <h3 style="padding-bottom: 1em;color: red;font-size: 17px; text-align: justify;">
                            Please use your company’s dedicated url to login to ROAM, if you do not know your dedicated url enter your ROAM email account.
                          </h3>
                              <label class="label">E-mail
                              </label>
                              <label class="input">
                                  <i class="icon-append fa fa-user"></i>
                                  <input [attr.tabindex]="0" class="tablIndexFocus" type="email"   name="email" required [(ngModel)]="UserEmail"
                                         id="email"  >
                                  <b class="tooltip tooltip-top-right">
                                      <i class="fa fa-user txt-color-teal"></i> Please enter email address
                                  </b>
                              </label>
                              <label *ngIf="tenantsList.length>0" class="label" style="margin-top: 0.7em;">Please click on following dedicated url and note down this url for direct access to ROAM next time
                              </label>
                              <div class="note hand-cursor" *ngFor="let url of tenantsList" >
                                <a [attr.tabindex]="0"  href="{{url}}" class="tablIndexFocus"  >{{url}}</a>
                            </div>

                          </section>
                    </fieldset>
                      <footer>

                         <button [attr.tabindex]="0"  type="button" class="btn btn-primary"  [disabled]="!FormUrl.form.valid" (click)="btnSubmitClick()" >
                              <i ></i>  Get url
                          </button>


                      </footer>
                  </form>

              </div>

          </div>

      </div>
  </div>

</div>

