<article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable no-padding standardpadding" style="margin-left:0px" id="col1">
    <div class="jarviswidget" id="wid-id-1" data-widget-togglebutton="false" data-widget-sortable="false" data-widget-fullscreenbutton="false" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" role="widget">

        <header role="heading">
            <span class="widget-icon"><i [className]="iconClass"></i></span>
            <h2><span *ngIf="state && state!=''">{{state}}</span> Items <span *ngIf="totalCount">({{totalCount}})</span></h2>
            <div class="jarviswidget-ctrls" title="View Item" *ngIf="isViewVisible">
                <a tabindex="0" class="button-icon tablIndexFocus" id="btnviewitem" (click)="viewItem()"  [attr.tabindex]="0" (keyup.enter)="viewItem()" >
                    <i class="fa fa-paperclip" aria-hidden="true"></i>
                </a>

            </div>
            <edit-comm *ngIf="isEditVisible" [recordType]="recordType" [commId]="commId"></edit-comm>
            <add-comm  *ngIf="isAddVisible" [recordType]="recordType"></add-comm>
            <div class="float-div-right" *ngIf='isViewVisible'>
                <div class="jarviswidget-ctrls" title="Help">
                    <app-component-articles [componentName]="componentName">
                    </app-component-articles>
                </div>
            </div>
            <div class="float-div-right"> <!--*ngIf="recordType!='5'"-->
                <label for="allcomms" class="checkbox col-sm-1 no-margin">

                    <span class="onoffswitch tablIndexFocus" [attr.tabindex]="0"  >
                        <input [disabled]="myCommsOnly" type="checkbox" name="allcomms" class="onoffswitch-checkbox" id="allcomms" [(ngModel)]="isCurrentUserOnly" (ngModelChange)="showAllUsersRecords()">
                        <label class="onoffswitch-label" for="allcomms"><span class="onoffswitch-inner" data-swchon-text="My" data-swchoff-text="All"></span><span class="onoffswitch-switch"></span></label>
                    </span>
                </label>
            </div>
            <!--<div class="jarviswidget-ctrls" title="Search Criteria" *ngIf="recordType=='5'">
                <a class="button-icon" id="btnSearchCriteria" (click)="onSearch()">
                    <i class="fa fa-search"></i>
                </a>
            </div>-->
            <!--<div class="jarviswidget-ctrls" title="Reset Search Criteria" *ngIf="recordType=='5'">
                <a class="button-icon" id="btnSearchReset" (click)="btnResetClick()">
                    <i class="fa fa-times"></i>
                </a>
            </div>-->
        </header>
        <!-- widget div-->
        <div class="widgetdiv no-padding" *ngIf="displaysidebar">

            <div class="widget-body inbox-searchcriteria">
                <div>

                    <form #searchcomm="ngForm">
                        <div class="smart-form">
                            <div class="row">
                                <!--<div class="form-group col-sm-1">
                                    <fieldset>
                                        <label class="label">
                                            Item Number
                                        </label>

                                        <label class="input">
                                            <input id="searchitemnum" type="text" name="searchitemnum"
                                                   placeholder="Item No." [(ngModel)]="model.ItemNumber" #searchitemnum="ngModel">

                                        </label>

                                    </fieldset>
                                </div>
                                <div class="form-group col-sm-1">
                                    <fieldset>
                                        <label class="label">
                                            ASN
                                        </label>

                                        <label class="input">
                                            <input id="searchasn" type="text" name="searchasn"
                                                   placeholder="ASN" [(ngModel)]="model.ASN" #searchasn="ngModel">

                                        </label>

                                    </fieldset>
                                </div>
                                <div class="form-group col-sm-2">
                                    <fieldset>
                                        <label class="label">
                                            Subject
                                        </label>

                                        <label class="input">
                                            <input id="searchsubject" type="text" name="searchsubject"
                                                   placeholder="Subject" [(ngModel)]="model.Subject" #searchsubject="ngModel">

                                        </label>

                                    </fieldset>
                                </div>
                                <div class="form-group col-sm-2">
                                    <fieldset>
                                        <label class="label">
                                            Type
                                        </label>

                                        <p-dropdown appendTo="body" id="searchcommtype" name="searchcommtypecommtype" [options]="commTypeList" placeholder="Select Type" [(ngModel)]="model.CommTypeId" #searchcommtype="ngModel">

                                        </p-dropdown>

                                    </fieldset>
                                </div>
                                <div class="form-group col-sm-2">
                                    <fieldset>
                                        <label class="label">
                                            Requested by
                                        </label>

                                        <p-dropdown appendTo="body" id="searchrequestedby" name="searchrequestedby" placeholder="Select Requested By" [options]="requestedByList" [(ngModel)]="model.RequestedBy" #searchrequestedby="ngModel">

                                        </p-dropdown>

                                    </fieldset>
                                </div>-->
                                <div class="form-group col-sm-6">
                                    <fieldset>
                                        <label class="label">
                                            Search
                                        </label>

                                        <label class="input">
                                            <input  [attr.tabindex]="0" id="searchitem" type="text" name="searchitem" class="tablIndexFocus"
                                                   placeholder="Search" [(ngModel)]="model.Search" #searchitem="ngModel">

                                        </label>

                                    </fieldset>
                                </div>
                                <div class="form-group col-sm-2">
                                    <fieldset>
                                        <label class="label">
                                            Created On (From)
                                        </label>
                                        <label class="input">
                                            <i class='icon-append fa fa-calendar'></i>
                                            <p-calendar appendTo="body" [attr.tabindex]="-1" class='datepicker hasDatepicker tablIndexFocus' id='rangedatefrom' name="rangedatefrom" [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030" [(ngModel)]="model.FromDate" #rangedatefrom="ngModel" [dateFormat]="dateFormatCalender" [style]="{width:ChangeWidthCalendar}">
                                            </p-calendar>

                                        </label>
                                    </fieldset>
                                </div>
                                <div class="form-group col-sm-2">
                                    <fieldset>
                                        <label class="label">
                                            Created On (To)
                                        </label>

                                        <label class="input">
                                            <i class='icon-append fa fa-calendar'></i>
                                            <p-calendar appendTo="body" [attr.tabindex]="-1" class='datepicker hasDatepicker tablIndexFocus' id='rangedateto' name="rangedateto" [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030" [(ngModel)]="model.ToDate" #rangedateto="ngModel" [dateFormat]="dateFormatCalender" [style]="{width:ChangeWidthCalendar}">
                                            </p-calendar>

                                        </label>
                                    </fieldset>
                                </div>
                                <div class="form-group col-sm-1 noPadding-sm">
                                    <fieldset>
                                        <label class="label">
                                           <br />
                                        </label>
                                        <div class="input-group-btn_">
                                            <button id="input-group-btn-custom-search"  pButton class="btn btn-default btn-primary fix-search-button" icon="fa fa-search" label="Search" type="button" (click)="onSearch()"></button>
                                        </div>
                                    </fieldset>
                                </div>
                                <div class="form-group col-sm-1 no-padding-left">
                                    <fieldset>
                                        <label class="label">
                                            <br />
                                        </label>
                                        <div class="input-group-btn_">
                                            <button id="input-group-btn-custom-clear" pButton class="btn btn-default btn-primary fix-search-button" icon="fa fa-times" label="Clear" type="button" (click)="btnResetClick()"></button>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>

                        </div>

                    </form>
                </div>

            </div>
        </div>

        <div class="widgetdiv no-padding standardpadding inbox-statuses-grid">

            <!-- widget content -->
            <div class="widget-body set-widget-body-width">
                <div>
                    <fieldset>
                        <div class="form-group">
                            <div id="s1">

                                <div class="jqgridboxcontainer" *ngIf="this.gridData.length > 0">
                                    <ag-grid-angular id="agGridlistItems" [navigateToNextCell]="navigateToNextCell" #agGridlistItems [gridOptions]="gridOptions" [columnDefs]="columnDefs" (rowClicked)='clickedItemsRow($event)' (rowDoubleClicked)="commRowDoubleClicked($event)" [rowData]="gridData" [components]="frameworkComponents" sortable="true" filter=true style="width: 100% !important;" class="ag-theme-balham">
                                    </ag-grid-angular>
                                </div>
                                <div id="nojoblist" class="alert alert-block alert-warning" *ngIf="this.gridData.length == 0">

                                    <h4 class="alert-heading">Warning!</h4>
                                    No Results Found!
                                </div>
                            </div>

                        </div>
                    </fieldset>
                </div>

            </div>
            <!-- end widget content -->
        </div>

        <!-- end widget div -->
    </div>
    <!-- end widget -->
</article>
