import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SharedService } from '../../../shared/services/shared.service';

@Injectable()
export class UserGroupService {
    constructor(private http : HttpClient, private _sharedService: SharedService ) {
    }

    addUpdateUserGroup(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/UserGroup/AddUpdateUserGroup`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getUserGroupByID(body: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/UserGroup/GetUserGroupByID?userGroupID=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getUserGroupList(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/UserGroup/GetUserGroupList`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getAllUserGroupsData(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/UserGroup/GetAllUserGroupsData`, body, this._sharedService.getReqOptWithAccessToken());
    }
}