<div class="jarviswidget-ctrls" title="Download Log File" *ngIf="!showDataButton">
    <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="downloadFeedLogFile()" id="btndownloadlog" (click)="downloadFeedLogFile()">
        <i class="glyphicon glyphicon-save"></i>
    </a>
</div>
<div class="jarviswidget-ctrls pdfoperations" *ngIf="showDataButton">
    <div class="btn-group">
        <a class="button-icon dropdown-toggle  tablIndexFocus"  [attr.tabindex]="0"   data-toggle="dropdown" aria-expanded="true" title="Download Feed">
            <i class="glyphicon glyphicon-download-alt"></i><!--fa fa-lg fa-fw fa-download-->
        </a>
        <ul class="dropdown-menu pull-right js-status-update">
            <li>
                <a (click)="downloadFeedLogFile()"><i class="fa fa-columns txt-color-green"></i>&nbsp;Log</a>
            </li>
            <li>
                <a (click)="downloadFeedFile()">
                    <i class="fa fa-save txt-color-green"></i>&nbsp;Data
                </a>
            </li>

        </ul>
    </div>
</div>

