//Components
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { GridOptions } from "ag-grid-community";

//Services
import { SharedService } from '../../../shared/services/shared.service';

import { ContainerService } from '../../../records/services/container/container.service'
import { ViewService } from '../../services/view/view.service'
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { Subscription } from 'rxjs';
var _router_: Router;
@Component({
    selector: 'viewsetting',
    templateUrl: './view-setting.component.html',
    providers: [ContainerService, ViewService]

})

export class ViewSettingComponent implements OnInit, OnDestroy {
    display: boolean = false;
    public gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[]=[];
    selectedViewId: string;
    componentName: 'SADV';
    public appHotkeys:  Hotkey[] = [];

    private totalCount: number;
    subscription: Subscription;
    // #region Initialization of Component
    constructor(private _sharedService: SharedService, private _router: Router, private _containerService: ContainerService, private _viewService: ViewService, private route: ActivatedRoute, private _hotkeysService: HotkeysService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            //this.UpdateRoles();
            _router_ = this._router;
            this.setHotKeys();
            this.navigateToNextCell=this.navigateToNextCell.bind(this);
        }
    }
    setHotKeys() {



        let hotKeyObj : Hotkey = null;

        //Add view
        hotKeyObj = new Hotkey('ctrl+a', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/viewsetting")
            {
                _router_.navigate(["/admin/configview"]);
            }
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

        //Edit view
        hotKeyObj = new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/viewsetting")
            {
                this.btnEditClick(null);
            }
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
        hotKeyObj = new Hotkey('enter', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/viewsetting")
            {
                this.btnEditClick(null);
            }
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

    }
    ngOnInit() {
        this.gridOptions = <GridOptions>
            {
                rowSelection: 'single',
                onGridReady: () => {
                    this.setViewGridColumns();
                    if (this.gridOptions.api != undefined) {
                        try {
                            this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridViewSettings'));
                            this.gridOptions.api.hideOverlay();
                        } catch (e) {
                            console.log(e);
                        }
                    }
                },
                onModelUpdated: (event: any) => {
                    this.gridOptions.api.sizeColumnsToFit();
                },
                //suppressContextMenu: true,
                pagination: true,
                paginationPageSize: 20,
                getContextMenuItems: this.getCustomContextMenuItems,
                context: {
                    gridContext: this
                },
                defaultColDef: {
                    sortable: true,
                    filter:true
                }
            };
            this.subscription = this._sharedService._isRefreshViewGrid
            .subscribe({
                next: (response) => {
                    this.populateViewGrid()
                },
                error: (error) => {
                }
            });
        this.populateViewGrid();
        this.componentName= 'SADV';
    }

    ngOnDestroy() {
        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
    }

    // #endregion

    // #region Ag Grid Population Event
    populateViewGrid(): void {

        this._viewService.getAllViews()
        .subscribe({
            next: (response) => {
                this.gridData = [];
                this.gridData = response;
                this.totalCount = response.length;
            },
            error: (error) => {
                this.gridData = [];
                this.totalCount = 0;
            }
        })
    }
    // #endregion
    // #region Set Columns of Browse Ag Grid
    setViewGridColumns(): void {
        //this.columnDefs = [];
        this.columnDefs =
            [
                { headerName: 'View Name', filter:true, field: 'ViewName', menuTabs: ['filterMenuTab'] },
                { headerName: 'Display Name', field: 'DisplayName', menuTabs: ['filterMenuTab'] },
                { headerName: 'Asset Type', field: 'AssetType', menuTabs: ['filterMenuTab'] },
                {
                    headerName: 'Visible', field: 'Visible', filter:false, suppressMenu: true,width:50, valueFormatter: function (params: any) {
                        if (params.value == true) {
                            return "Yes";
                        }
                        else {
                            return "No";
                        }
                    }
                },
                {
                    headerName: 'Status', field: 'Status', filter:false,width:50, suppressMenu: true, valueFormatter: function (params: any) {
                        if (params.value == true) {
                            return "Active";
                        }
                        else {
                            return "Inactive";
                        }
                    }
                },
                { headerName: 'Display Order', filter:false,suppressMenu: true,width:50, field: 'DisplayOrder' },
            ];
    }


    // #endregion

    // #region Ag-Grid Events
    updateSelectedRow(event: any) {
        this.selectedViewId = event.data.ViewSettingId;
    }
    // #endregion

    // #region Edit View Events
    btnEditClick(event: any) {
        if (event) {
            let id = event.data.ViewSettingId;
            this._router.navigate(['/admin/configview', id]);

        }
        else {
            if (this.selectedViewId && this.selectedViewId.toString().length > 0) {
                let id = this.selectedViewId;
                this._router.navigate(['/admin/configview', id]);
            }
            else {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'No View Selected', detail: 'Please select a record.' });
            }
        }
    }
    // #endregion
    // #region Add User Events
    btnAddClick() {

        this._router.navigate(['/admin/containertypemanagement']);
    }
    // #endregion

    // #region User Roles Assignment
    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }
    }
    // #endregion
    // #region Helper Methods

    // #endregion
    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];

            result.push({
                name: "Add View",
                action: function () {
                    params.context.gridContext._router.navigate(['/admin/configview']);
                },
                icon: params.context.gridContext._sharedService.addIconHTMLasString,
            });

            result.push({
                name: "Edit View",
                action: function () {
                    params.context.gridContext.selectedViewId = null;
                    if (params.node) {
                        params.context.gridContext.selectedViewId = params.node.data.ViewSettingId;
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext.btnEditClick(null);
                    }

                },
                icon: params.context.gridContext._sharedService.editIconHTMLasString,
            });

            let childResult: any[] = [];
            childResult.push({
                name: 'Excel',
                action: function () {
                    
                    params.api.exportDataAsExcel();
                    
                },
                icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
            });

            childResult.push({
                name: 'CSV',
                action: function () {
                    params.api.exportDataAsCsv();
                },
                icon: params.context.gridContext._sharedService.saveLogFeedIconHTMLasString,
            });

            result.push({
                name: 'Download',
                action: function () {


                },
                icon: params.context.gridContext._sharedService.downloadsFeedIconHTMLasString,
                subMenu: childResult,
            });

        return result;
    }
    navigateToNextCell(params: any) {
        var previousCell = params.previousCellPosition;
        var suggestedNextCell = params.nextCellPosition;
        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        switch (params.key) {
            case KEY_DOWN:
                var nextRowIndex = previousCell.rowIndex + 1;
                var renderedRowCount = this.gridOptions.api.getModel().getRowCount();
                if (nextRowIndex >= renderedRowCount) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedViewId = rowNode.data.ViewSettingId;
                        return {
                        rowIndex: nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_UP:
                var _nextRowIndex = previousCell.rowIndex - 1;
                if (_nextRowIndex < 0) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedViewId = rowNode.data.ViewSettingId;
                        return {
                        rowIndex: _nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw 'this will never happen, navigation is always on of the 4 keys above';
        }
    }

    base64ToArrayBuffer(base64: string) {
        const bytes = new Uint8Array(base64.length);
        return bytes.map((byte, i) => base64.charCodeAt(i));
      }

    createAndDownloadBlobFile(body,  extension = 'rview') {
        var selRow:any=this.gridOptions.api.getSelectedRows()[0];
        var filename=selRow.ViewName;
        const blob = new Blob([body]);
        const fileName = `${filename}.${extension}`;
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, fileName);
        } else {
          const link = document.createElement('a');
          // Browsers that support HTML5 download attribute
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }
      
    ExportView() {
        let viewId = this.selectedViewId;
        if (viewId) {
            this._sharedService.showLoader("Processing...");
            this._viewService.exportView(viewId)
            .subscribe({
                next: (response) => {
                    this.createAndDownloadBlobFile(response)
                    this._sharedService.hideLoader();
                },
                error: (error) => {
                }
            });
        }
        else {
            this._sharedService.hideLoader();
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No View Selected', detail: 'Please select a record.' });
        }
    }
    //#endregion
}
