import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { UserService } from '../../../records/services/User/user.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  providers: [UserService]
})
export class PortalComponent implements OnInit {

  public UserEmail = '';
  public IsEmailCorrect: boolean;
  public tenantsList: string[] = [];
  constructor(private _sharedService: SharedService, private _userService: UserService) {
  }

  ngOnInit() {
    this.addClassOnBody();
  }

  btnSubmitClick() {

    this._userService.getUserTenantUrlByEmail(this.UserEmail)
      .subscribe({
        next: (response) => {
          this.tenantsList = [];
          if (response) {
            this.IsEmailCorrect = true;
            for (let index = 0; index < response.length; index++) {
              this.tenantsList.push(environment.tenantUrl.replace('{tenant}', response[index]));
            }
          }

          return;
        },
        error: (error) => {
          this.IsEmailCorrect = false;
          this.tenantsList = [];
          console.error(error);
        }
      })

    return;
  }

  addClassOnBody() {
    //Add the class to body tag when the View is initialized
    let body = document.getElementsByTagName('body')[0];
    body.classList.add("remove-padding-margin");
    body.classList.remove("menu-on-top");
    body.classList.remove("fixed-header");
  }

}
