export class AssetTypes {

    constructor(
        public Airframe: number,

        public APU: number,

        public Engine: number,

        public LDG: number,
        
        public Propeller: number
      
    ) { }

}

export class Stats {

    constructor(
        public  Aircraft:string ,

        public DocumentCount:number,

        public StorageSpace: string ,

        public StatsPieChart: any

    ) { }

}
