import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';

import { SharedService } from '../../../shared/services/shared.service';
@Injectable()
export class SearchSynonymService {
    constructor(private http : HttpClient, private _sharedService: SharedService ) {
    }

    
    // getContainerData(body: container): Observable<any> {
    //     return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainer`, body, this._sharedService.getReqOptWithAccessToken());
    // }

    getContainerByAsn(asn: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainerByAsn?asn=${asn}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    // getFilteredContainer(body: container): Observable<any> {
    //     return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetFilteredContainer`, body, this._sharedService.getReqOptWithAccessToken());
    // }

    getContainerDetail(containerId: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainerDetail?containerId=${containerId}`, '', this._sharedService.getReqOptWithAccessToken());
    }
    
    // addContainerData(body: containerForAdd): Observable<any> {
    //     return this.http.post(`${this._sharedService.baseUrl}/api/Container/AddContainer`, body, this._sharedService.getReqOptWithAccessToken());
    // }

    // updateContainerData(body: containerForEdit): Observable<any> {
    //     return this.http.post(`${this._sharedService.baseUrl}/api/Container/UpdateContainer`, body, this._sharedService.getReqOptWithAccessToken());
    // }
    deleteContainer(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Container/DeleteContainer`, body, this._sharedService.getReqOptWithAccessToken());
    }

    // getContainerType(body: container): Observable<any> {
    //     return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainerType`, body, this._sharedService.getReqOptWithAccessToken());
    // }

    // getBoxType(body: container): Observable<any> {
    //     return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainerBoxType`, body, this._sharedService.getReqOptWithAccessToken());
    // }

    // getBoxLocation(body: container): Observable<any> {
    //     return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainerLocation`, body, this._sharedService.getReqOptWithAccessToken());
    // }

    // getScannedBy(body: container): Observable<any> {
    //     return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainerScannedBy`, body, this._sharedService.getReqOptWithAccessToken());
    // }
    
    // getBoxID(body: container): Observable<any> {
    //     return this.http.post(`${this._sharedService.baseUrl}/api/Container/GetContainerBoxID`, body, this._sharedService.getReqOptWithAccessToken());
    // }

    getSearchSynonymMap(body:any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Search/SynonymMap?searchDatabase=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }
  
    getContainerCategories():any {
        return  [
                { value: 'Equivalent', label: 'Equivalent' },
                { value: 'Explicit', label: 'Explicit' }
            ];

    }
    addUpdateSearchSynonymType(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Search/AddUpdateSynonymMap`, body, this._sharedService.getReqOptWithAccessToken());
    }
  
   
}
