<section id="widget-grid" class="adminaircraftcls">

    <div class="row">
        <article class="col-sm-12 col-md-12 col-lg-12" id="col1">
            <!-- Widget ID (each widget will need unique ID)-->
            <div class="jarviswidget" id="wid-id-2" data-widget-colorbutton="false" data-widget-editbutton="false"
                data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false">
            
                <header>
                    <span class="widget-icon"><i class="fa fa-list"></i></span>
                    <h2>Asset</h2>
                    <!-- <div class="jarviswidget-ctrls" title="Refresh">
                        <a class="button-icon" id="btnRefresh" (click)="refresh()">
                            <i class="glyphicon glyphicon-refresh"></i>
                        </a>
                    </div> -->

                    <div class="jarviswidget-ctrls pdfoperations" role="menu">
                        <div class="btn-group">
                            <a class="button-icon" (click)="onFinanceGridExport()" [attr.tabindex]="0"
                                data-toggle="dropdown" aria-expanded="false" title="Download">
                                <i class="glyphicon glyphicon-save"></i>
                            </a>
                        </div>
                    </div>


                    <div class="widget-toolbar hidden-mobile" style="cursor:auto;" id="btnRefresh" title="Refresh">
                        <span (click)="refresh()" class="onoffswitch-title" style="display: inline !important"><i
                                class="glyphicon glyphicon-refresh" [ngClass]="{'fa-spin': updatingRecord}"
                                style="margin-left: 5px;cursor:pointer;"></i></span>
                    </div>
                    <div class="jarviswidget-ctrls" title="Run Finance Report">
                        <a class="button-icon" id="btnrunFeed" (click)="runJob()">
                            <i class="glyphicon glyphicon-play"></i>
                        </a>
                    </div>
                    <h2 style="float:right;" *ngIf="recordLastUpdated!='' && processState!='In progress'"> Last updated
                        {{getDateFormatTime(recordLastUpdated)}} </h2>
                    <h2 style="float:right;margin-right: 5px;" *ngIf="processState=='In progress'"> <span
                            class="padding-5 label"
                            style="background-color:#2196F3;font-size: small;">{{processState}}</span></h2>
                </header>
                <!-- widget div-->

                <div class="widgetdiv no-padding">

                    <div class="widget-body inbox-searchcriteria">
                        <div>
        
                            <form #auditform="ngForm">
                                <div class="smart-form" id="auditScreen">
                                    <div class="row">
                                        <div class="form-group col-sm-2">
                                            <fieldset>
                                                <label class="label">
                                                    Tenant
                                                </label>
                                                <p-dropdown appendTo="body" id="tenant" name="tenant" [options]="tenantList" [resetFilterOnHide]="true" [showClear]="true" placeholder="Choose Tenant" filter=true  required [(ngModel)]="model.Tenant" #tenant="ngModel" >
                                                </p-dropdown>
        
                                            </fieldset>
                                        </div>
                                        <div class="form-group col-sm-2">
                                            <fieldset>
                                                <label class="label">
                                                    From
                                                </label>
                                                <label class="input">
                                                    <i class='icon-append fa fa-calendar'></i>
                                                    <p-calendar appendTo="body" class='datepicker hasDatepicker audit-datepicker' showButtonBar="true" placeholder="{{ViewDateFormat}}"  id='rangedatefrom' name="rangedatefrom" [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030" [(ngModel)]="model.FromDate" #rangedatefrom="ngModel" [dateFormat]="dateFormatCalender" [style]="{width:'100%'}">
                                                    </p-calendar>
        
                                                </label>
                                            </fieldset>
                                        </div>
                                        <div class="form-group col-sm-2">
                                            <fieldset>
                                                <label class="label">
                                                   To
                                                </label>
        
                                                <label class="input">
                                                    <i class='icon-append fa fa-calendar'></i>
                                                    <p-calendar appendTo="body" class='datepicker hasDatepicker audit-datepicker' showButtonBar="true" id='rangedateto' placeholder="{{ViewDateFormat}}" name="rangedateto" [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030" [(ngModel)]="model.ToDate" #rangedateto="ngModel" [dateFormat]="dateFormatCalender" [style]="{width:'100%'}">
                                                    </p-calendar>
        
                                                </label>
                                            </fieldset>
                                        </div>
                                        
                                        <div class="form-group col-sm-1">
                                            <fieldset>
                                                <label class="label">
                                                    <br />
                                                </label>
                                                <a href="javascript:void(0);" style="padding-top: 5px;" (click)="SearchAssets()" class="btn btn-primary btn-sm"><i class="fa fa-search fa-lg"></i></a>
                                                
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
        
                            </form>
                        </div>
        
                    </div>
                </div>
                <div class="widgetdiv">


                    <div class="widget-body" [ngClass]="{'custom-min-height': this.gridData.length == 0}">

                        <section id="widget-grid2" class="">
                            <div class="jqgridboxadminaircraft" *ngIf="this.gridData && this.gridData.length > 0">
                                <ag-grid-angular id="agGridAircraft" [excelStyles]="excelStyles" #agGridAircraft
                                    [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="gridData"
                                    filter=true sortable="true" style="width: 100%;"
                                    class="ag-theme-balham internalaircraftsgrid">
                                </ag-grid-angular>
                                <!-- end row -->
                            </div>
                        </section>
                        <!-- end widget grid -->
                    </div>
                    <!-- end widget content -->
                </div>
                <!-- end widget div -->
            </div>
            <!-- end widget -->
        </article>
        <!-- END COL -->
    </div>
</section>