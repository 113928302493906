import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from '../../../shared/services/shared.service';


@Injectable()
export class TaskDocumentService {

    //#region Global Variables



    //#endregion


    constructor(private _sharedService: SharedService, private http: HttpClient) {
    }

    /// start region service methods


    getTaskDocument(task: any) {
        return this.http.post<any>(`${this._sharedService.baseUrl}/api/TaskDocument/GetTaskDocument`, task, this._sharedService.getReqOptWithAccessToken());
    }

    addTaskDocuments(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/TaskDocument/AddTaskDocuments`,
            body,
            this._sharedService.getReqOptWithAccessToken());
    }

    updateTaskDocuments(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/TaskDocument/UpdateTaskDocuments`,
            body,
            this._sharedService.getReqOptWithAccessToken());
    }

    removeTaskDocuments(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/TaskDocument/RemoveTaskDocuments`,
            body,
            this._sharedService.getReqOptWithAccessToken());
    }

    getDocumentTask(docUrl: any) {
        return this.http.post<any[]>(`${this._sharedService.baseUrl}/api/TaskDocument/GetDocumentTask?docUrl=${docUrl}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getImagePoints(body: any) {
        return this.http.post<any[]>(`${this._sharedService.baseUrl}/api/TaskDocument/GetImagePoints`,
         body,
          this._sharedService.getReqOptWithAccessToken());
    }
    /// end region service methods


}