//#region References
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SharedService } from '../../shared/services/shared.service';
//#endregion

@Injectable()
export class IssueService {
    constructor(private http: HttpClient, private _sharedService: SharedService) {
    }

    getIssueDetail(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/Issue/GetIssueDetail`, body, this._sharedService.getReqOptWithAccessToken());
    } 
}