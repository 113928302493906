<div class="row adduserscls">

    <article class="mainArcticlePadding col-sm-12 col-md-12 col-lg-12" id="col2">
        <!-- Widget ID (each widget will need unique ID)-->
        <div class="jarviswidget" id="wid-id-0" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false">
            <header>
                <span class="widget-icon"><i class="fa fa-bell"></i></span>
                <h2>Maintenance Alert</h2>
            </header>
            <!-- widget div-->
            <div class="widgetdiv" style="height:85vh;overflow-x: hidden !important; overflow-y: scroll !important;">
                <!--style="height:80vh;overflow-x: hidden !important;-->
                <!--max-height: 529.444px; overflow-x: hidden; overflow-y: scroll;-->
                <!-- widget content -->
                <div class="widget-body">
                    <!-- widget grid -->
                    <section id="widget-grid0" style="height: 80vh !important;">
                        <form #alertsform="ngForm">
                            <!--style="height: 70vh !important;"-->
                            <div class="navbar-form form-group">
                                <div class="smart-form">

                                    <div class="row">
                                        <div class="form-group col-sm-1">
                                        </div>
                                        <div class="form-group col-sm-5">
                                            <fieldset>
                                                <div class="detailtext">When user select maintenance option, system generated email is sent to the users. Following data is used in email generation and same appear in email client of user.</div>
                                            </fieldset>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="form-group col-sm-1">
                                            <label class="label">
                                                Tenant<span class="text-danger">
                                                    *
                                                </span>
                                            </label>
                                        </div>
                                        <div class="form-group col-sm-5">
                                            <fieldset>
                                                <p-multiSelect id="tenantselectalert" [options]="tenantSelectionList" [(ngModel)]="Settings.UserTenants" name="tenantselectalert" #tenantselectalert="ngModel" [style]="{width:'220px'}" (onChange)="changeShowUsersOnTenantChange()" [maxSelectedLabels]="0" selectedItemsLabel="{0} items selected "></p-multiSelect>
                                            </fieldset>
                                            <div class="detailtext"></div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-sm-1">
                                            <label class="label">
                                                Users
                                            </label>
                                        </div>
                                        <div class="form-group col-sm-5">
                                            <fieldset>

                                                <div class="inline-group" id="rdobuttonusers">
                                                    <label class="radio" style="padding-left:4px;">
                                                        <input type="radio" name="radio-inlineusers" checked="checked" [(ngModel)]="Settings.ShowUsers" [value]="false" (ngModelChange)="changeShowUsers()">
                                                        <i></i>All
                                                    </label>
                                                    <label class="radio" style="padding-left:4px;">
                                                        <input type="radio" name="radio-inlineusers" [(ngModel)]="Settings.ShowUsers" [value]="true" (ngModelChange)="changeShowUsers()">
                                                        <i></i>Selected
                                                    </label>
                                                </div>
                                                <div class="detailtext"></div>
                                            </fieldset>
                                        </div>

                                    </div>

                                    <div class="row" *ngIf="Settings.ShowUsers">
                                        <div class="form-group col-sm-1">
                                            <label class="label">

                                            </label>
                                        </div>
                                        <div class="form-group col-sm-6" >
                                            <div class="panel panel-default">
                                                <div class="panel-heading">
                                                    <h3 class="panel-title panalheading-padding">Users </h3>
                                                </div>
                                                <div class="panel-body">
                                                    <div class="col-sm-12 picklist-padding fix-picklist-width" > <!-- style="height:53vh;" -->
                                                        <p-pickList id="userspicklist" responsive="true" sourceHeader="Available Users" targetHeader="Selected Users" [source]="availableUsers" [target]="selectedUsers" filterBy="label" sourceFilterPlaceholder="Search by user" targetFilterPlaceholder="Search by user" [sourceStyle]="{'height':'270px'}" [targetStyle]="{'height':'270px'}">
                                                            <ng-template let-users pTemplate="selectedItem">
                                                                <div>
                                                                    <div class="col-md-7" [ngClass]="getSelectedClass(users.value)" style="font-size:14px"><span>{{users.label}}</span></div>
                                                                    <div class="col-md-4" style="font-size:14px;margin-right: 1px;padding-left:0px !important;padding-right:0px !important"><span class="group-tags" style="background-color:#808080">{{getUserDepartment(users.value)}}</span></div>
                                                                </div>
                                                            </ng-template>
                                                        </p-pickList>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="Settings.ShowUsers"><br /></div>
                                    <div class="row">
                                        <div class="form-group col-sm-1">
                                            <label class="label">
                                                From<span class="text-danger">
                                                    *
                                                </span>
                                            </label>
                                        </div>
                                        <div class="form-group col-sm-5">
                                            <fieldset>
                                                <label class="input">
                                                    <input id="mntcfrom" type="text" name="mntcfrom"
                                                           placeholder="Email" [(ngModel)]="Settings.MaintenanceFrom" required #mntcfrom="ngModel">

                                                </label>
                                                <div class="detailtext">Sender email address for maintenance email.</div>
                                            </fieldset>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-sm-1">
                                            <label class="label">
                                                Alias<span class="text-danger">
                                                    *
                                                </span>
                                            </label>
                                        </div>
                                        <div class="form-group col-sm-5">
                                            <fieldset>
                                                <label class="input">
                                                    <input id="mntcalias" type="text" name="mntcalias"
                                                           placeholder="Email Alias" [(ngModel)]="Settings.MaintenanceAlias" required #mntcalias="ngModel">

                                                </label>
                                                <div class="detailtext">Alias or name for maintenance email..</div>
                                            </fieldset>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-sm-1">
                                            <label class="label">
                                                Subject<span class="text-danger">
                                                    *
                                                </span>
                                            </label>
                                        </div>
                                        <div class="form-group col-sm-5">
                                            <fieldset>
                                                <label class="input">
                                                    <input id="mntcsubject" type="text" name="mntcsubject"
                                                           placeholder="Email Subject" [(ngModel)]="Settings.MaintenanceSubject" required #mntcsubject="ngModel">

                                                </label>
                                                <div class="detailtext">Subject for maintenance email.</div>
                                            </fieldset>

                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="form-group col-sm-1">
                                            <label class="label">
                                                Body<span class="text-danger">
                                                    *
                                                </span>
                                            </label>
                                        </div>
                                        <div class="form-group col-sm-5">
                                            <fieldset>
                                                <label class="textarea">
                                                    <p-editor id="mtncemailbody" name="mtncemailbody" [(ngModel)]="Settings.MaintenanceBody" [style]="{'height':'160px'}" required #mtncemailbody>
                                                    </p-editor>
                                                </label>

                                                <div class="detailtext">
                                                    Contents of email for maintenance alert.Special {{ '{' }}tags{{ '}' }} from user screen.
                                                    Tokens are:
                                                    <label class="token" (click)="AssignToken('mtncemailbody', _sharedService.Token_FirstName)">{{_sharedService.Token_FirstName }}</label>,
                                                    <label class="token" (click)="AssignToken('mtncemailbody', _sharedService.Token_LastName)">{{_sharedService.Token_LastName }}</label>,
                                                    <label class="token" (click)="AssignToken('mtncemailbody', _sharedService.Token_Tenant)">{{_sharedService.Token_Tenant}}</label>,
                                                    <label class="token" (click)="AssignToken('mtncemailbody', _sharedService.Token_TenantURL)">{{_sharedService.Token_TenantURL}}</label>
                                                    .
                                                </div>
                                            </fieldset>

                                        </div>


                                    </div>

                                </div>
                            </div>
                            <!-- end row -->
                            <div class="widget-footer">
                                <button type="button" class="btn btn-primary custom-button" [disabled]="!alertsform.form.valid||(Settings.ShowUsers && selectedUsers.length==0)||(Settings.UserTenants.length==0)" (click)="sendNotification(alertsform)">
                                    Send
                                </button>
                            </div>
                        </form>
                    </section>

                </div>

                <!-- end widget content -->
            </div>
            <!-- end widget div -->
        </div>
        <!-- end widget -->
    </article>
    <!-- END COL -->
</div>
