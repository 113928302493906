import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';

import { SharedService } from '../../../shared/services/shared.service';

@Injectable()
export class ViewDataTypeService {
    constructor(private http : HttpClient, private _sharedService: SharedService ) {
    }

    addUpdateViewDataType(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/ViewDataType/AddUpdateViewDataType`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getViewDataTypeByID(body: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/ViewDataType/GetViewDataTypeByID?ViewDataTypeID=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getAllViewDataTypeData(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/ViewDataType/GetAllViewDataTypeData`, body, this._sharedService.getReqOptWithAccessToken());
    }

    getAllActiveViewDataTypeData(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/ViewDataType/GetAllActiveViewDataTypeData`, body, this._sharedService.getReqOptWithAccessToken());
    }
}