<form *ngIf="isDisplayExtractPagesDialog" #frmUploadDoc="ngForm">
    <!--name="frmUploadDoc"-->
    <p-dialog *ngIf="isDisplayExtractPagesDialog" [(visible)]="isDisplayExtractPagesDialog" id="docUpload"
        showEffect="fade" [resizable]="false" [modal]="false" [style]="{width: '700px'}">
        <ng-template pTemplate="header"  style="float: left;" (keyup.shift.tab)="onKey()">
            Copy Selected Pages
        </ng-template>

        <!--Contents Start Here-->


        <div class="upload-dialog">
            <div id="smart-form" class="smart-form">
                <div id="form-fields">
                    <div class="row">
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="label">Pages
                                    <!-- <span class="text-danger">*</span> -->
                                </label>
                                <label class="input">
                                </label>
                            </fieldset>
                        </div>
                        <div class="form-group col-sm-6">
                            <fieldset>
                                <label class="input">
                                    <input type="text" required placeholder="  e.g. 1-5, 8, 11-13" class="input-smText"
                                        id="PageRange" name="PageRange" [(ngModel)]="PageRange" required #focus/>
                                </label>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary"
                    (click)="EmitSelectedPages(frmUploadDoc)"
                    [disabled]="isSubmitted || PageRange==null || PageRange==''" label="Copy"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default"
                    (click)="btnCancelClick(frmUploadDoc)" label="Cancel" [disabled]="isSubmitted"></button>
            </div>
        </ng-template>
    </p-dialog>
</form>


<button [disabled]="!FormDisabled || !ConRowSelected" id="copy-selected-pages" icon="fa fa-copy" style="height: 30px" type="button"  class="btn btn-primary minzindex p-component p-button-text-icon-left"
(click)="AttachSelectedPaged()">Copy Selected Pages
</button>