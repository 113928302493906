import * as $ from 'jquery'
import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, Renderer2, RendererStyleFlags2, HostListener, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { GridOptions, CsvExportParams, ExcelExportParams, ExcelStyle, ExcelCell, GridApi, RowNode, ColDef, SideBarDef, ToolPanelDef, ChartType, ColumnState, ExcelRow } from "ag-grid-community";
//import { GridOptions, CsvExportParams, ExcelExportParams, ExcelStyle, ExcelCell, GridApi, RowNode, ColDef, SideBarDef, ToolPanelDef } from "ag-grid-enterprise";
//import { AllModules } from "ag-grid-enterprise";
import { Subscription, Observable } from 'rxjs';
import { SelectItem, ConfirmationService } from 'primeng/api';
import * as cloneDeep from 'lodash/cloneDeep';
import { SharedService } from '../../../shared/services/shared.service';
import { TagService } from '../../../shared/services/tag/tag.service';
import { AzureBlobService } from '../../../shared/services/AzureBlob/azure-blob.service';
import { container, SearchContainerModel, OutgoingParams, KeyValPair, OutGoingParamKeyVal, BatchGapAnalyzer } from '../../../records/services/container/container';
import { Role } from '../../../shared/services/Menu/role';
import { IConfig } from '../../../shared/helpers/config';
import { AzureSearchService } from '../../../shared/services/AzureSearch/azure-search.service'
import { azureSearchKeywords } from '../../../shared/services/AzureSearch/azure-search.keywords'
import * as moment from 'moment/moment';
import { Tag } from '../../../shared/services/tag/tag';
import { AzureBlob, BlobMetaData, AzureBlobForWOGap } from '../../../shared/services/AzureBlob/blob-metadata';
import { ContainerService } from '../../services/container/container.service'
import { AssetService } from '../../../admin/services/asset/asset.service'
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { isNullOrUndefined } from '../../../shared/classes/nullorundefined';
import { APIService } from '../../../api/services/api.service';
import { SettingsService } from '../../../admin/services/settings/setting.service';
import { CustomDateComponent } from '../../../shared/components/custom-date/custom-date.component';
import { QualityStatusService } from '../../../admin/services/qualitystatus/qualitystatus.service';
import { AdvanceSearch } from '../../services/search/search-type';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
import { element } from 'protractor';
import { AddCommModel } from 'src/app/comm/components/add-comm/add-comm';
import { CommService } from 'src/app/comm/services/comm.service';
import { comm } from 'src/app/comm/components/comm/comm';
import { CopyMoveDocumentInputEntity } from 'src/app/shared/classes/CopyMoveDocumentInputEntity';
//import { IDateFormatInfo } from 'src/app/shared/helpers/dateFormatInfo';

//#region Global Variables for Ag-Grid
var dateFormatF: string;
var dateSeparatorS: string;
var dayD: number;
var monthM: number;
var yearY: number;
var Tags: Tag[] = [];
var sharedService: SharedService;
var _gridOptions: GridOptions
var _docCopyFromGridOptions: GridOptions
var _searchQuery: string;
var displayMode: string = 'record';
var statusList: any[] = [];
var containerQualityStatusList: any[] = [];
var dateTimeConfig: IDateFormatInfo;

var documentColumnDefs_1: any[];
var documentColumnDefs_CopyFrom: any[];
var referenceKeyLabel: string = '';
var conColumnDefs_1: any[];

//var documentSelectedBeforeChangeofSeqNo: any = null;
//#endregion


@Component({
    selector: 'browse-document',
    templateUrl: './browse-document.component.html',
    styles: [
        `.ag-dialog
        {
        left: 5vw !important;
        width: 90vw !important;
        max-width: 90vw !important;
        height: 80vh !important;
        top: 10vh !important;
        max-height: 88vh !important;
        }
        `],
    encapsulation: ViewEncapsulation.None,
    providers: [AzureBlobService, AzureSearchService, TagService, ContainerService, AssetService, ConfirmationService, APIService, SettingsService, QualityStatusService, CommService]
})

export class BrowseDocumentComponent implements OnInit, OnDestroy {


    //#region Global Variable

    subscriptionMessage: Subscription;
    model: AddCommModel;
    isDisplayBulkDialog: boolean = false;
    recordType: string;
    searchSelectedRows: any;
    subscription: Subscription;

    columnDefs: any[];
    columnDefsCopyFrom: any[];
    chartColumnDefs: any[];
    docGridSideBar: any[];
    columnDefsDpt: any[];
    columnDefsInvalidOrMissingInfoDoc: any[];
    columnDefsImageToPDF: any[];

    batchSize: number = 100 // default batch size is 5
    selectedBatchSize: number = 0
    isFrom:boolean=false;

    public isAddVisible: Boolean = false;
    public browseDocumentObj: any;
    public gridOptions: GridOptions;
    public chartGridOptions: GridOptions;
    public gridOptionsInvalidOrMissingInfoDoc: GridOptions;
    public gridOptionsImageToPDF: GridOptions;
    public gridOptionsInboxLinkDpt: GridOptions;
    public gridOptionsDependencies: GridOptions;
    public docGridData: any[] = [];
    public chartDocGridData: any[] = [];
    public selectedContainer: container;
    public selectedDoc: any[];  // primeNG
    public totalCount: number = 0;
    public directoryCount: number = 0;
    public dateConfig: IConfig[] = [];
    public canEdit: boolean = false;
    public canDelete: boolean = false;
    // public canDownloadPDF: boolean = false;
    // public canDownloadCSV: boolean = false;
    public canEditMetaData: boolean = false;
    public canCopyMove: boolean = false; // To-Do Add in role
    public canEditMetaDataReadonly: boolean = false;
    //#region Copy/Move
    public displayMoveCopyDlg: boolean = false;
    public displayCopyFromDlg: boolean = false;
    conTypes: SelectItem[] = [];
    categories: SelectItem[] = [];
    assets: SelectItem[] = [];
    conGridOptions: GridOptions;
    copyDocGridOptions: GridOptions;
    conColumnDefs: any[];
    conGridData: any[] = [];
    conTotalCount: number = 0;
    containerModel: SearchContainerModel;
    conRowSelected: boolean = false;
    _displayMode: string = 'record';
    //public excelStyles: ExcelStyle;
    public excelStyles: any;
    public canUpload: boolean = false;
    public canBulkUpload: boolean = false;
    public isDragable: boolean = false;
    public isSidebarVisible: boolean = false;
    public iconcolor: string = "black";
    public iconcolorSidebar: string = "black";
    public titleValue: string = "Drag & Drop Disabled";
    public sideBarTitleValue: string = "Side Bar Disabled";
    public documentSelectedBeforeChangeofSeqNo: any = null;
    public thresholdValueForSeqNo: number = .1000;
    public directoryResult: boolean = false;
    public canAnalyzeTechLog: boolean = false;

    public canRunWOGapAnalysis: boolean = false;
    public inWOGapAnalysisMode: boolean = false;
    public inTechLogGapAnalysisMode: boolean = false;
    public canLogAnalyserRunByDefault: boolean = false;
    public canDuplicateAnalyserRunByDefault: boolean = false;
    public woGapButtonTitle: string = "Gap Analyser (CTRL+Y) ";
    public woGapButtonManualTitle: string = "Gap Analyser (CTRL+M) ";
    public diaplayWOGapManualDialog: boolean = false;
    public WOGapManualDialogHeight: number = 0;
    public WOGapMappingList: OutgoingParams[] = [];
    WOGapFormGroup: UntypedFormGroup = new UntypedFormGroup({});
    dateFormatCalender = "";
    woGapManualParams: KeyValPair[] = [];

    //public showStatusLinkDptDlg: boolean = false;
    //public showInboxLinkDptDlg: boolean = false;
    public showDependencyDlg: boolean = false;
    public showInvalidOrMissingInfoDlg: boolean = false;
    public showImageToPDFDlg: boolean = false;

    public showUpdateFileDlg: boolean = false;

    public maxAllowedFileSize: number = 0;
    public maxAllowedFileSizeMB: number = 0;
    public acceptFileTypes = "";
    public componentName = 'RECD'
    //#endregion

    //#endregion

    //#region search variables
    selectedSearchKeywords: azureSearchKeywords;
    searchQuery: string;
    isBlankSearchQuery: boolean;
    formattedSearchkeyword: string;
    searchMode: string = "Exact";
    SelectMaxDocumentCount: string = "50";
    fltFromDate: string;
    fltToDate: string;
    //#endregion
    //#region delete Confirm Dialogue
    public displayDeleteConfirmDlg: boolean = false;
    docDeleteConfirmGridOptions: GridOptions;
    docDeleteConfirmColumnDefs: any[];
    docDeleteConfirmGridData: any[] = [];
    docDeleteConfirmGridDataComplete: any;
    //public referenceKeyLabel: string = "";
    public selectedComboCatergorySelection: string = "";
    public appHotkeys: Hotkey[] = [];

    //#endregion
    //#region Quality Status Analysis
    public canMXRQualityStatus: boolean = false;
    public canLOGQualityStatus: boolean = false;
    public canGENQualityStatus: boolean = false;
    public canDOCQualityStatus: boolean = false;
    public canEditMetadataQualityStatus: boolean = false;
    public canViewFeedFromStatus: boolean = false;
    qualityStatusList: any[] = [];
    //#endregion
    //#region Init

    //#region Hide Show White Rows
    showHideWhiteRowLabel: string = 'Hide compared documents';
    showHideRedRowLabel: string = 'Hide No document found';
    showHideWhiteRowIcon: string = 'fa-filter';//'fa-eye-slash';
    hideWhiteRow: boolean = false;
    filterGrid: boolean = false;
    //#endregion

    public docDependencies: any[] = [];
    public docRefToInbox: any[] = [];
    public docRefToStatus: any[] = [];
    public docRefToDependencies: any[] = [];

    public docRefToDependenciesGridData: any[] = [];

    public docInvalidOrMissingInfoGridData: any[] = [];
    public docImageToPDFGridData: any[] = [];
    public docRefToInboxGridData: any[] = [];
    public docRefToStatusGridData: any[] = [];
    // public moveActionForDocInbox: string = "";
    // public moveActionForDocStatus: string = "";
    public moveActionForDependencies: string = "";
    public moveActionForInvalidFiles: string = "";
    public moveActionForImageFileToPDF: string = "";

    simpleObservable: any;
    ValidsimpleObservable: any;
    public selectedTargetContainer: any;
    public selectedDocuments: any;
    public listOfCommsIdsToDelete: string = "";

    public Document_MXR_Columns: any[] = [];
    public Document_LOG_Columns: any[] = [];
    public Document_GEN_Columns: any[] = [];
    public Document_DOC_Columns: any[] = [];
    public Document_BND_Columns: any[] = [];

    public Document_MXR_Sort_By: string = "";
    public Document_LOG_Sort_By: string = "";
    public Document_GEN_Sort_By: string = "";
    public Document_DOC_Sort_By: string = "";
    public Document_BND_Sort_By: string = "";


    public Container_MXR_Columns: any[] = [];
    public Container_LOG_Columns: any[] = [];
    public Container_GEN_Columns: any[] = [];
    public Container_DOC_Columns: any[] = [];
    public Container_BND_Columns: any[] = [];


    public Container_MXR_Sort_By: string = "";
    public Container_LOG_Sort_By: string = "";
    public Container_GEN_Sort_By: string = "";
    public Container_DOC_Sort_By: string = "";
    public Container_BND_Sort_By: string = "";


    public advanceSearch: Array<AdvanceSearch> = [];
    public keywordConditions: any[];
    public feedType: string = 'Import';
    public ViewSettingId: string = '0';
    public targetType = "Records";
    feedlogid: string = '';
    isInternalView: boolean = true;
    public displayFeedDialog: boolean = false;

    private sortOnCol = null;
    private orderBy = null;

    private sideBar;
    selectedUrl: any;
    private _channel = "RecordGapanalyser";

    //copymove by batch

    public showCopyMoveDlg: boolean = false;
    public showCopyMoveSkipLinkedDocsDlg: boolean = false
    public batchBlobList: string[] = [];
    public isCopy = false;
    //copymove by batch end

    @HostListener('window:resize', ['$event'])
    onWindowResize(event: Event) {
        this.calculateGridHeight();
    }
    constructor(private elRef: ElementRef, private renderer: Renderer2, private _sharedService: SharedService, private _azureBlobService: AzureBlobService, private _router: Router,
        private _azureSearchService: AzureSearchService, private _tagService: TagService, private _containerService: ContainerService,
        private _assetService: AssetService, private _confirmationService: ConfirmationService, private _hotkeysService: HotkeysService,
        private _apiService: APIService, private _settingService: SettingsService, private _qualityStatusService: QualityStatusService, private _commService: CommService) {

        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        } else {
            // this.SetWindowChangeEventforScreenResolution();
            this.setDisplayMode();
            this.loadTags();
            this.populateAsset()
            //this.populateCategory();
            this.recordType = this._sharedService.recordType;
            this.setHotKeys();
            this.CanRunWOGap();
            this.CanLogAnalyserRunByDefault();
            this.CanDuplicateAnalyserRunByDefault();

            dateTimeConfig = this._sharedService.getDateTimeFormatConfiguration();

            this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_MXR_LOG_GEN_CATEGORY;
            this.recordType = this._sharedService.recordType;
            if (this.recordType == "DOC" || this.recordType == "BND") {
                this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_DOC_CATEGORY + "," + this._sharedService.allowedAzureIndexFileContentTypes.toString();
            }
        }
    }


    // SetWindowChangeEventforScreenResolution() {
    //     window.addEventListener('resize', function (event) {

    //         if (!$('browse-container>article>div').css('height') || !$('browse-document>article>div').css('height')) {

    //             return;
    //         }
    //         let browseContainerHeight: number = $('browse-container>article>div').css('height').replace('px', '');
    //         let browseDocumentHeight: number = $('browse-document>article>div').css('height').replace('px', '');
    //         let innerGridHeaderHeight = 0;
    //         if (browseContainerHeight == 0) {
    //             let fieldSetheight: number = $('.fset').css('height').replace('px', '');
    //             if (fieldSetheight < 12) {
    //                 fieldSetheight = fieldSetheight + $('.fset').css('padding').replace('px', '');
    //             }
    //             let innerGridHeight: number = $('browse-document>article>div>div').css('height').replace('px', '');
    //             let innerGridPadding: number = $('browse-document>article>div>div').css('padding').replace('px', '');
    //             if (!this.inWOGapAnalysisMode)
    //                 innerGridHeaderHeight = $('browse-document>article>div>div>div:nth-child(2)').find('#searchblock').css('height').replace('px', '');
    //             let ActualGridHeight = innerGridHeight - innerGridPadding - innerGridHeaderHeight - fieldSetheight - 1;
    //             let currentHeight: string = ActualGridHeight + 'px !important';
    //             $("#documentGrid").attr('style', 'height:' + currentHeight + '');
    //         }
    //         else {
    //             if (browseDocumentHeight > browseContainerHeight) {
    //                 let difference = browseDocumentHeight - browseContainerHeight;
    //                 let fieldSetheight: number = $('.fset').css('height').replace('px', '');
    //                 if (fieldSetheight < 12) {
    //                     fieldSetheight = fieldSetheight + $('.fset').css('padding').replace('px', '');
    //                 }
    //                 let innerGridHeight: number = $('browse-document>article>div>div').css('height').replace('px', '');
    //                 let innerGridPadding: number = $('browse-document>article>div>div').css('padding').replace('px', '');
    //                 if (!this.inWOGapAnalysisMode)
    //                     innerGridHeaderHeight = $('browse-document>article>div>div>div:nth-child(2)').find('#searchblock').css('height').replace('px', '');
    //                 let ActualGridHeight = innerGridHeight - innerGridPadding - innerGridHeaderHeight - difference - fieldSetheight - 1;
    //                 let currentHeight: string = ActualGridHeight + 'px !important';
    //                 $("#documentGrid").attr('style', 'height:' + currentHeight + '');
    //             }
    //             else {
    //                 let fieldSetheight: number = $('.fset').css('height').replace('px', '');
    //                 if (fieldSetheight < 12) {
    //                     fieldSetheight = fieldSetheight + $('.fset').css('padding').replace('px', '');
    //                 }
    //                 let innerGridHeight: number = $('browse-document>article>div>div').css('height').replace('px', '');
    //                 let innerGridPadding: number = $('browse-document>article>div>div').css('padding').replace('px', '');
    //                 if (!this.inWOGapAnalysisMode)
    //                     innerGridHeaderHeight = $('browse-document>article>div>div>div:nth-child(2)').find('#searchblock').css('height').replace('px', '');
    //                 let ActualGridHeight = innerGridHeight - innerGridPadding - innerGridHeaderHeight - fieldSetheight - 1;
    //                 let currentHeight: string = ActualGridHeight + 'px !important';
    //                 $("#documentGrid").attr('style', 'height:' + currentHeight + '');
    //             }
    //         }
    //     }.bind(this));
    // }

    setHotKeys() {
        let hotKeyObj: Hotkey = null;
        //Export CSV
        hotKeyObj = new Hotkey('ctrl+shift+x', (event: KeyboardEvent): boolean => {
            this.exportGridData();
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

        //Export Excel
        hotKeyObj = new Hotkey('ctrl+shift+z', (event: KeyboardEvent): boolean => {
            this.exportExcelGrid();
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

        //Download PDF
        hotKeyObj = new Hotkey('ctrl+shift+p', (event: KeyboardEvent): boolean => {
            this.validatePdfRequest();
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

        //Delete Documents
        if (this.canDelete) {
            hotKeyObj = new Hotkey('ctrl+d', (event: KeyboardEvent): boolean => {
                this.confirmDelete();
                return false; // Prevent bubbling
            });
            this._hotkeysService.add(hotKeyObj);
            this.appHotkeys.push(hotKeyObj);
        }

        //Copy/Move Document
        if (this.canCopyMove) {
            hotKeyObj = new Hotkey('ctrl+q', (event: KeyboardEvent): boolean => {
                this.copyMoveDocuments();
                return false; // Prevent bubbling
            });
            this._hotkeysService.add(hotKeyObj);
            this.appHotkeys.push(hotKeyObj);
        }

        if (this.canCopyMove) {
            hotKeyObj = new Hotkey('ctrl+b', (event: KeyboardEvent): boolean => {
                this.OpenCopyFromModel();
                return false; // Prevent bubbling
            });
            this._hotkeysService.add(hotKeyObj);
            this.appHotkeys.push(hotKeyObj);
        }

        //WO Gap & //TechlogAnalyzer
        hotKeyObj = new Hotkey('ctrl+y', (event: KeyboardEvent): boolean => {
            // if (this._router.url == "/record/browse" && this.canRunWOGapAnalysis) {
            //     this.wOGapAnalysisClick();
            // }
            // else
            if (this._router.url == "/record/logs" && this.totalCount > 1) {
                this.runTechlogAnalyzer();
            }
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

        //Duplicate Analyzer
        hotKeyObj = new Hotkey('ctrl+shift+y', (event: KeyboardEvent): boolean => {
            this.runDuplicateAnalyzer();
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

        //WO Gap Manual
        hotKeyObj = new Hotkey('ctrl+m', (event: KeyboardEvent): boolean => {
            if (this._router.url == "/record/browse" && this.canRunWOGapAnalysis) {
                this.showWoGapManualDlg();
            }
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

    }

    CanRunWOGap() {
        this._settingService.getConfigurationValueByKey("MXRecords_WO_Gap_Analysis")
            .subscribe({
                next: (response) => {
                    this.canRunWOGapAnalysis = this._sharedService.UserRole.Records_MXR_WO_GAP_Analysis
                        && this._router.url == "/record/browse"
                        && response == 'True';
                },
                error: (error) => {
                    console.log(error);
                }
            });
    }
    CanLogAnalyserRunByDefault() {
        this._settingService.getConfigurationValueByKey("Log_Analyser_Run_By_Default")
            .subscribe({
                next: (response) => {
                    this.canLogAnalyserRunByDefault = response == 'True' ? true : false;
                },
                error: (error) => {
                    console.log(error);
                }
            });
    }

    CanDuplicateAnalyserRunByDefault() {
        this.canDuplicateAnalyserRunByDefault = false;// no db script
        // this._settingService.getConfigurationValueByKey("Duplicate_Analyser_Run_By_Default").subscribe( response => {
        //     this.canDuplicateAnalyserRunByDefault = response == 'True' ? true : false;
        // }, error => {
        //     console.log(error);
        // });
    }

    helpComponentName() {
        if (this._router.url == '/record/browse') {
            this.componentName = 'RECD'
            // this.componentName='RECD'
            // 
        }
        else if (this._router.url == '/record/logs') {
            this.componentName = 'RECD'
        }
        else if (this._router.url == '/record/general') {
            this.componentName = 'RECD'
        }
        else if (this._router.url == '/record/documents') {
            this.componentName = 'RECD'
        }
        else if (this._router.url == '/transition/binders') {
            this.componentName = 'TRAD'
        }
    }

    ngOnInit() {

        this.maxAllowedFileSize = this._sharedService.getMaxDocumentLimitInBytes();
        this.maxAllowedFileSizeMB = this._sharedService.getMaxDocumentLimit();

        this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_MXR_LOG_GEN_CATEGORY;
        this.recordType = this._sharedService.recordType;
        if (this.recordType == "DOC" || this.recordType == "BND") {
            this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_DOC_CATEGORY + "," + this._sharedService.allowedAzureIndexFileContentTypes.toString();
        }
        //this.sideBar = 'columns';
        this.sideBar = {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressSideButtons: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
            defaultToolPanel: 'columns',
        };


        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        } else {

            this.prepareDocumentGridColumns();
            this.prepareContainerGridColumns();

            this.dateConfig = this._sharedService.getConfiguration();
            referenceKeyLabel = this._sharedService.getReferenceKeyLabel();
            this.recordType = this._sharedService.recordType;
            this.selectedComboCatergorySelection = this._sharedService.recordType;
            this.dateFormatCalender = this._sharedService.getCalenderDateFormat();
            this.populateQualityStatus();
            this.helpComponentName();
        }

        this.UpdateRoles();

        //Getting/Subscribe selected Container
        this.subscription &&
            this.subscription.unsubscribe();
        this.subscription = this._sharedService._selectedContainer
            .subscribe({
                next: (selContainer) => {
                    this.UpdateRoles();
                    this.distroyHotkeys();
                    this.setHotKeys();
                    if (this._sharedService.isCurrentAssetReadOnly()) {
                        this.setDocumentGridColumns();
                    }
                    //Bug 8510://Reset to default sort=> Records> Log Books: Gap Analyzer: Change the error text-message
                    //this.setDocumentGridColumns();

                    this.setDisplayMode();
                    //GapAnalysiser prevent rebinding
                    if (this.inWOGapAnalysisMode != true)
                        this.reBindGrid(selContainer);


                    this.goBackToNormalView();
                },
                error: (error) => {
                }
            });

        this.setExcelStyle();

        //Init For Ag-Grid
        sharedService = this._sharedService;
        this.gridOptions = <GridOptions>{
            rowSelection: 'multiple',
            rowData: [],
            // onFilterChanged:this.getFilteredRowsByProperty,
            //suppressRowClickSelection: true,
            onSelectionChanged: this.updateSelectedDocument,
            onGridReady: () => {

                this.setDocumentGridColumns();
                if (this.gridOptions.api != undefined) {
                    if (this.docGridData.length > 0) {
                        this.gridOptions.api.hideOverlay();
                    }
                    this.gridOptions.api.setSuppressRowDrag(true);
                    this.isDragable = false;
                }

                this.gridOptions.api.setSideBarVisible(false);
                this.iconcolorSidebar = "black";
                this.isSidebarVisible = false;
                this.sideBarTitleValue = "Side Bar Disabled";
            },
            onModelUpdated: (event: any) => {
                try {
                    this.totalCount = this.gridOptions.api.getDisplayedRowCount();
                } catch (e) {
                    console.log(e);
                }
            },
            defaultColDef: {
                sortable: true,
                resizable: true,
            },
            components: { agDateInput: CustomDateComponent },
            //suppressContextMenu: true,
            getContextMenuItems: this.getCustomContextMenuItems,
            context: {
                gridContext: this
            },
            suppressScrollOnNewData: true,
            navigateToNextCell: this.navigateToNextCell,
            getRowClass: (params: any) => {
                try {
                    if (this.recordType !== "DOC" && this.recordType != "BND" && this.inWOGapAnalysisMode == true) {
                        if (params.columnApi.getColumn('DocNoExternal') && params.columnApi.getColumn('DocNoExternal').visible) {
                            let docnoExtArray = params.data.DocNoExternal ? params.data.DocNoExternal.split('>') : params.data.DocNoExternal;
                            if (params.data.DocNoExternal && params.data.DocNo && docnoExtArray[docnoExtArray.length - 1].trim() == params.data.DocNo.trim()) {
                                return 'green-row-background';
                                //} else if (params.data.DocNo && params.data.DocNoExternal && params.data.DocNo != params.data.DocNoExternal) {
                            } else if (params.data.DocNoExternal && params.data.DocNo && params.data.DocNo.trim() != params.data.DocNoExternal.trim()) {
                                return 'yellow-row-background';
                            } else if (!params.data.DocNo && !params.data.DocType) {
                                return 'red-row-background';
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
                return '';
            },
            //onCellEditingStopped: this.updateDocumentQualityStatus,
            onCellValueChanged: this.updateDocumentQualityStatus.bind(this),
            singleClickEdit: true
            //floatingFilter: true,

        }
        this.chartGridOptions = <GridOptions>
            {
                onGridReady: () => {

                },
                context: {
                    //gridContext: this
                    gridContextChart: this
                }
            }
        _gridOptions = this.gridOptions;
        setTimeout(() => {
            if (this.gridOptions.columnApi)
                this.gridOptions.columnApi.setColumnWidth('checkboxcol', 25, true);
        }, 2000);
        this.getDateFormatInfo();


        //#region Move/Copy

        this.conGridOptions = <GridOptions>
            {
                rowSelection: 'single',

                onGridReady: () => {
                    this.setContainerGridColumns();
                },
                suppressContextMenu: true,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                }

            }
        this.copyDocGridOptions = <GridOptions>
            {
                rowSelection: 'multiple',
                onSelectionChanged: this.docCopyFromRowClick,
                // onGridReady: () => {
                //     this.setContainerGridColumns();
                // },
                suppressContextMenu: true,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                },
                context: {
                    gridContext: this
                },

            }
        _docCopyFromGridOptions = this.copyDocGridOptions
        this.containerModel = new SearchContainerModel("", null, null, false);
        //#endregion
        //#region Delete Docs Details
        this.docDeleteConfirmGridOptions = <GridOptions>
            {
                rowSelection: 'single',
                rowData: [],
                onGridReady: () => {
                    this.setDeleteListingGridColumns();
                    if (this.docDeleteConfirmGridOptions.api != undefined) {
                        if (this.docDeleteConfirmGridData.length > 0) {
                            //R.A.
                            this.docDeleteConfirmGridOptions.columnApi.setColumnVisible("checkboxcol", false);
                            this.docDeleteConfirmGridOptions.api.hideOverlay();
                        }
                    }
                },
                onModelUpdated: (event: any) => {
                    try {
                        if (this.docDeleteConfirmGridOptions.api) {
                            //this.totalCount = this.gridOptions.api.getDisplayedRowCount();
                        }
                        //this.docDeleteConfirmGridOptions.api.sizeColumnsToFit();
                    } catch (e) {
                        console.log(e);
                    }
                },
                pagination: true,
                paginationPageSize: 20,
            }
        //#endregion
        this.populateKeywordConditions()
        this.subscriptionMessage = this._sharedService._isShowMsg
            .subscribe({
                next: (msg: any) => {
                    this._sharedService.showToast({ severity: msg.Severity, summary: msg.Summary, detail: msg.Detail });
                }
            })
    }




    generateDocCharts(selectedChart) {
        if (this.docGridData && this.docGridData.length > 0) {
            let rowStartIndex = 0;
            let rowEndIndex = this.columnDefs.length;
            this._sharedService.showLoader("Processing...");
            var columns = this.columnDefs.filter(x => x.hide == false).map(ele => ele.field);
            // var columns = this.docChartSettings.chartConfigurationList.map(ele=>ele.FieldName);
            if (this.gridOptions.api.getCellRanges().length > 0) {
                rowStartIndex = this.gridOptions.api.getCellRanges()[0].startRow.rowIndex;
                rowEndIndex = this.gridOptions.api.getCellRanges()[0].endRow.rowIndex;
                columns = [];
                this.gridOptions.api.getCellRanges()[0].columns.forEach(x => {
                    columns.push(x.getColDef().field);
                });
            }
            this.chartColumnDefs = cloneDeep(this.columnDefs)
            this.chartDocGridData = cloneDeep(this.docGridData);
            var selectedChartData = this.docChartSettings.find(x => x.chartType == selectedChart)
            this.chartColumnDefs.forEach(chartColDef => {
                const chartConfig = selectedChartData.chartConfigurationList.find(x => x.FieldName === chartColDef.field);
                if (chartConfig) {
                    chartColDef.aggFunc = chartConfig.aggFunc || null;
                    chartColDef.rowGroup = chartConfig.rowGroup || false;
                    chartColDef.pivot = chartConfig.pivot || false;
                    chartColDef.chartDataType = chartConfig.chartDataType || 'Excluded';
                    chartColDef.rowGroupIndex = chartConfig.rowGroupIndex || 0;
                }
            });
            var params = selectedChartData.chartParams;
            // var params=this.docChartSettings.chartParams;
            params.cellRange["rowStartIndex"] = rowStartIndex;
            params.cellRange["rowEndIndex"] = rowEndIndex;
            params.cellRange["columns"] = columns;
            setTimeout(() => {
                this.chartGridOptions.api.createPivotChart(params);
                this._sharedService.hideLoader();
            }, 2000);
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Record', detail: 'No record exists.' });
        }
    }
    prepareContainerGridColumns(): void {

        var appConfigs = this._sharedService.getConfiguration();
        if (appConfigs != null) {
            this.Container_MXR_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_MX_Records_Column_Setting')[0].Value);
            this.Container_LOG_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Log_Books_Column_Setting')[0].Value);
            this.Container_GEN_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_General_Column_Setting')[0].Value);
            this.Container_DOC_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Documents_Column_Setting')[0].Value);
            this.Container_BND_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Binders_Column_Setting')[0].Value);

            this.Container_MXR_Sort_By = appConfigs.filter(x => x.Key == 'Container_MXR_Sort_By')[0].Value;
            this.Container_LOG_Sort_By = appConfigs.filter(x => x.Key == 'Container_LOG_Sort_By')[0].Value;
            this.Container_GEN_Sort_By = appConfigs.filter(x => x.Key == 'Container_GEN_Sort_By')[0].Value;
            this.Container_DOC_Sort_By = appConfigs.filter(x => x.Key == 'Container_DOC_Sort_By')[0].Value;
            this.Container_BND_Sort_By = appConfigs.filter(x => x.Key == 'Container_BND_Sort_By')[0].Value;
        }
    }

    updateStatusCol() {
        let statusCol = this.columnDefs.find(c => c.field == 'Status');
        statusCol.editable = this.canEditMetaData;
        this.gridOptions.api.refreshCells({ force: true });
    }


    navigateToNextCell(params) {
        var previousCell = params.previousCellPosition;;
        // var previousCell = params.previousCellDef;
        // var suggestedNextCell = params.nextCellDef;
        var suggestedNextCell = params.nextCellPosition;
        var currentSelectedNode = null;

        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        switch (params.key) {
            // case KEY_DOWN:
            //     // previousCell = params.previousCellDef;
            //     _gridOptions.api.deselectAll();
            //     // set selected cell on current cell + 1
            //     _gridOptions.api.forEachNode((node) => {
            //         if (previousCell.rowIndex + 1 === node.rowIndex) {
            //             node.setSelected(true);
            //         }
            //         if (previousCell.rowIndex === node.rowIndex) {
            //             currentSelectedNode = node;
            //         }
            //     });
            //     //return suggestedNextCell;
            //     if (suggestedNextCell != null) {
            //         return suggestedNextCell;
            //     } else {
            //         currentSelectedNode.setSelected(true);
            //         return currentSelectedNode
            //     }
            // case KEY_UP:
            //     // previousCell = params.previousCellDef;
            //     _gridOptions.api.deselectAll();
            //     // set selected cell on current cell - 1
            //     _gridOptions.api.forEachNode((node) => {
            //         if (previousCell.rowIndex - 1 === node.rowIndex) {
            //             node.setSelected(true);
            //         }
            //         if (previousCell.rowIndex === node.rowIndex) {
            //             currentSelectedNode = node;
            //         }
            //     });
            //     //return suggestedNextCell;
            //     if (suggestedNextCell != null) {
            //         return suggestedNextCell;
            //     } else {
            //         currentSelectedNode.setSelected(true);
            //         return currentSelectedNode
            //     }
            case KEY_DOWN:
                var nextRowIndex = previousCell.rowIndex + 1;
                var renderedRowCount = _gridOptions.api.getModel().getRowCount();
                if (nextRowIndex >= renderedRowCount) {
                    return null;
                } else {
                    _gridOptions.api.deselectAll();
                    var rowNode = _gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
                    rowNode.setSelected(true);
                    // sharedService.onBrowseContainerSelection(rowNode.data);
                    return {
                        rowIndex: nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }


            case KEY_UP:
                var _nextRowIndex = previousCell.rowIndex - 1;
                if (_nextRowIndex < 0) {
                    return null;
                } else {
                    _gridOptions.api.deselectAll();
                    var rowNode = _gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
                    rowNode.setSelected(true);
                    // sharedService.onBrowseContainerSelection(rowNode.data);
                    return {
                        rowIndex: _nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw "this will never happen, navigation is always one of the 4 keys above";
        }
    }


    ngOnDestroy() {
        //this._sharedService._selectedContainer.unsubscribe();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.subscriptionMessage) {
            this.subscriptionMessage.unsubscribe();
        }
        this.obj && this.obj.unsubscribe();

        this.distroyHotkeys();
    }


    distroyHotkeys() {
        //console.log('destroying component =>' + this._router.url);
        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
    }

    //#endregion

    //#region Events and Utilties

    autoRunLogAnalyser() {
        if (this._router.url == "/record/logs"//
            && this.totalCount > 1
            && this.canLogAnalyserRunByDefault) {
            this.runTechlogAnalyzer();
        }

        return;
    }

    autoRunDuplicateAnalyzer() {
        if (
            /*this._router.url == "/record/logs"//
            && */
            this.totalCount > 1
            && this.canDuplicateAnalyserRunByDefault) {
            this.runDuplicateAnalyzer();
        }

        return;
    }
    reBindGrid(selContainer: any) {
        _searchQuery = null
        this.selectedContainer = selContainer;

        this._sharedService.selectedDocuments = null;
        this.populateDocumentGrid();
    }


    updateSelectedDocument() {//(event: any) {

        // sharedService.onDocumentContainerSelection(event.node.data);
        try {

            var rowCount = _gridOptions.api.getSelectedNodes().length;
            if (rowCount > 0) {
                if (sharedService.inWOGapAnalysisMode) {
                    const containerElement = document.getElementById('documentGrid');
                    // Remove the specific class from all elements within the container
                    const elements = containerElement.querySelectorAll('.ag-row-selected');
                    elements.forEach(element => {
                        element.classList.remove('ag-row-selected');
                    });
                }
                if (_gridOptions.api.getSelectedNodes()[0].data.ASN != null) {

                    getFilteredRowsByProperty()
                    // sharedService.bulkSelectDocuments(_gridOptions.api.getSelectedNodes().map(x => x.data))
                    var lastRowData = _gridOptions.api.getSelectedNodes()[rowCount - 1].data;
                    if (!_searchQuery) {

                        sharedService.updateSearchText(null);
                    }
                    sharedService.onDocumentContainerSelection(lastRowData);
                    sharedService.onSelectedDocumentsChange(_gridOptions.api.getSelectedRows(), _gridOptions.api.getSelectedNodes()[0].rowIndex.toString());
                    sharedService.selectedDocCount = rowCount;
                    // console.log("-----update Selected Document event----");
                    // console.table(_gridOptions.api.getSelectedRows());
                    // console.log("------------------------------");
                    if (displayMode == 'status') {
                        sharedService.docToAttach = _gridOptions.api.getSelectedNodes().map(node => {
                            let doc: BlobMetaData = node.data;
                            doc.taskId = [sharedService.selectedTaskID];
                            return doc;
                        });
                    }
                }
                else {
                    sharedService.onDocumentContainerSelection(null);
                }
            }


        } catch (e) {
            console.log(e);
        }
    }


    GetDirectoryFiles() {

        this.directoryResult = true;
        this.populateDocumentGrid();
    }
    public obj: any = undefined;
    populateDocumentGrid(): void {
        this.searchQuery = null;
        this.isBlankSearchQuery = false;
        if (!this.isDragable) {
            this.gridOptions.api.setSuppressRowDrag(true);
            this.iconcolor = "black";

        }

        if (this.selectedContainer && this.selectedContainer.ASN) {

            this.selectedContainer.IsDirectory = this.directoryResult;

            //US-8181 //this.selectedContainer.ContainerName = this._sharedService.selectedAsset[0].data.split('|')[0];
            this.selectedContainer.ContainerName = JSON.parse(this._sharedService.selectedAsset[0].data).StoragePath;
            // this._sharedService.showLoader("Processing...");
            this.obj && this.obj.unsubscribe();
            this.obj = this._azureBlobService.getContainerData(this.selectedContainer)
                .subscribe({
                    next: (response) => {
                        //this.enableDisableRowDrag();
                        // For Navigation to a Document from dashboard
                        if (this._router.url.indexOf('/record/') != -1 && this._sharedService.navigationInfoDashboard && this._sharedService.navigationInfoDashboard.isNavigate && this._sharedService.isNavigationFilterCanceled) {
                            this._sharedService.setAssetControlTextForDashboard(this._sharedService.selectedAsset);
                            let documents: any[] = response.Documents;


                            this.docGridData = documents;
                            this._sharedService.docCount = this.docGridData.length;
                            this.totalCount = this.docGridData.length;
                            this.directoryCount = response.DirectoryFileCount;
                            let navUrl = "/record/" + (this._sharedService.navigationInfoDashboard.cat == 'mxr' ? 'browse' : this._sharedService.navigationInfoDashboard.cat);
                            if (this._router.url == navUrl && this._sharedService.navigationInfoDashboard.isNavigate) {
                                this._sharedService.navigationInfoDashboard = null;
                            } else {
                                this._sharedService.navigationInfoDashboard.isNavigate = true;
                            }
                        }
                        // For Navigation to a Document from dashboard
                        else if (this._router.url.indexOf('/transition/') != -1 && this._sharedService.navigationInfoDashboard && this._sharedService.navigationInfoDashboard.isNavigate && this._sharedService.isNavigationFilterCanceled) {
                            this._sharedService.setAssetControlTextForDashboard(this._sharedService.selectedAsset);
                            let documents: any[] = response.Documents;


                            this.docGridData = documents;
                            this._sharedService.docCount = this.docGridData.length;
                            this.totalCount = this.docGridData.length;
                            this.directoryCount = response.DirectoryFileCount;
                            let navUrl = "/transition/" + (this._sharedService.navigationInfoDashboard.cat == 'bnd' ? 'bnd' : this._sharedService.navigationInfoDashboard.cat);
                            if (this._router.url == navUrl && this._sharedService.navigationInfoDashboard.isNavigate) {
                                this._sharedService.navigationInfoDashboard = null;
                            } else {
                                this._sharedService.navigationInfoDashboard.isNavigate = true;
                            }
                        }
                        else {
                            //For Navigation to a Documnet
                            if (this._router.url.indexOf('/record/') != -1 && this._sharedService.navigationInfo && this._sharedService.navigationInfo.isNavigate && this._sharedService.isNavigationFilterCanceled) {
                                let documents: any[] = response.Documents;
                                this.docGridData = documents.filter(d => d.Url.indexOf(this._sharedService.navigationInfo.recordId) != -1);
                                if (this.docGridData && this.docGridData.length == 0 && (documents && documents.length > 0)) {
                                    this.docGridData = documents;
                                }
                                this._sharedService.docCount = this.docGridData.length;
                                this.totalCount = this.docGridData.length;
                                this.directoryCount = response.DirectoryFileCount;
                                let navUrl = "/record/" + (this._sharedService.navigationInfo.cat == 'mxr' ? 'browse' : this._sharedService.navigationInfo.cat);
                                if (this._router.url == navUrl && this._sharedService.navigationInfo.Navigated) {
                                    this._sharedService.navigationInfo = null;
                                } else {
                                    this._sharedService.navigationInfo.Navigated = true;
                                }
                            }
                            else if (this._router.url.indexOf('/transition/') != -1 && this._sharedService.navigationInfo && this._sharedService.navigationInfo.isNavigate && this._sharedService.isNavigationFilterCanceled) {
                                let documents: any[] = response.Documents;
                                this.docGridData = documents.filter(d => d.Url.indexOf(this._sharedService.navigationInfo.recordId) != -1);
                                if (this.docGridData && this.docGridData.length == 0 && (documents && documents.length > 0)) {
                                    this.docGridData = documents;
                                }
                                this._sharedService.docCount = this.docGridData.length;
                                this.totalCount = this.docGridData.length;
                                this.directoryCount = response.DirectoryFileCount;
                                let navUrl = "/transition/" + (this._sharedService.navigationInfo.cat == 'bnd' ? 'bnd' : this._sharedService.navigationInfo.cat);
                                if (this._router.url == navUrl && this._sharedService.navigationInfo.Navigated) {
                                    this._sharedService.navigationInfo = null;
                                } else {
                                    this._sharedService.navigationInfo.Navigated = true;
                                }
                            } else {
                                if (this._sharedService.isStatusAttachDocDialogueOpen) {
                                    this.setDocumentGridColumns();
                                }
                                this.docGridData = response.Documents;
                                this.totalCount = this.docGridData.length;
                                this.directoryCount = response.DirectoryFileCount;
                                this._sharedService.docCount = this.docGridData.length;
                            }
                        }
                        //this.gridOptions.api.refreshView();
                        this.calculateGridHeight()
                    },
                    error: (error) => {
                        this._sharedService.hideLoader();
                        this.docGridData = [];
                        this.totalCount = 0;
                    },
                    complete: () => {
                        //console.log('Completed!!!');

                        this._sharedService.hideLoader();
                    }
                });

        } else {
            this._sharedService.hideLoader();
            this.docGridData = [];
            this.totalCount = 0;
        }
        this.directoryResult = false;
    }

    prepareDocumentGridColumns(): void {
        var appConfigs = this._sharedService.getConfiguration();
        if (appConfigs != null) {
            this.Document_MXR_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Document_MX_Records_Column_Setting')[0].Value);
            this.Document_LOG_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Document_Log_Books_Column_Setting')[0].Value);
            this.Document_GEN_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Document_General_Column_Setting')[0].Value);
            this.Document_DOC_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Document_Documents_Column_Setting')[0].Value);
            this.Document_BND_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Document_Binders_Column_Setting')[0].Value);

            this.Document_MXR_Sort_By = appConfigs.filter(x => x.Key == 'Document_MXR_Sort_By')[0].Value;
            this.Document_LOG_Sort_By = appConfigs.filter(x => x.Key == 'Document_LOG_Sort_By')[0].Value;
            this.Document_GEN_Sort_By = appConfigs.filter(x => x.Key == 'Document_GEN_Sort_By')[0].Value;
            this.Document_DOC_Sort_By = appConfigs.filter(x => x.Key == 'Document_DOC_Sort_By')[0].Value;
            this.Document_BND_Sort_By = appConfigs.filter(x => x.Key == 'Document_BND_Sort_By')[0].Value;

        }
    }
    docChartSettings = null;
    popupParent;
    chartMenuList = [];
    //shouldUpdateGridColumns : boolean = true, shouldUpdateGridColumnSorting : boolean = true
    setDocumentGridColumns() {
        this.columnDefs = [];
        documentColumnDefs_1 = [];
        this.chartMenuList = [];
        var documentGridCols: any[];
        var document_Col_Sort_On_By: string = '';
        var appConfigs = this._sharedService.getConfiguration();
        switch (this._sharedService.recordType.toUpperCase()) {
            case 'MXR':
                this.columnDefs = [];
                documentColumnDefs_1 = [];
                documentColumnDefs_1.push({ headerName: '', field: '', minWidth: 25, maxWidth: 50, checkboxSelection: true, headerCheckboxSelection: true, filter: false, sortable: false, menuTabs: [], rowDrag: true, colId: "checkboxcol" });
                documentGridCols = this.Document_MXR_Columns;
                document_Col_Sort_On_By = this.Document_MXR_Sort_By;
                this.docChartSettings = JSON.parse(appConfigs.filter(x => x.Key == 'Document_MX_Records_Chart_Settings')[0].Value);
                if (this.docChartSettings && this.docChartSettings.length > 0) {
                    this.docChartSettings.forEach(element => {
                        let chartConfig = {
                            label: element.chartType
                        }
                        this.chartMenuList.push(chartConfig)
                    });
                }
                break;

            case 'LOG':
                this.columnDefs = [];
                documentColumnDefs_1 = [];
                documentColumnDefs_1.push({ headerName: '', field: '', minWidth: 25, maxWidth: 50, checkboxSelection: true, headerCheckboxSelection: true, filter: false, sortable: false, menuTabs: [], rowDrag: true, colId: "checkboxcol" });
                documentGridCols = this.Document_LOG_Columns;
                document_Col_Sort_On_By = this.Document_LOG_Sort_By;
                this.docChartSettings = JSON.parse(appConfigs.filter(x => x.Key == 'Document_Log_Books_Chart_Settings')[0].Value);
                if (this.docChartSettings && this.docChartSettings.length > 0) {
                    this.docChartSettings.forEach(element => {
                        let chartConfig = {
                            label: element.chartType
                        }
                        this.chartMenuList.push(chartConfig)
                    });
                }
                break;

            case 'GEN':
                this.columnDefs = [];
                documentColumnDefs_1 = [];
                documentColumnDefs_1.push({ headerName: '', field: '', minWidth: 25, maxWidth: 50, checkboxSelection: true, headerCheckboxSelection: true, filter: false, sortable: false, menuTabs: [], rowDrag: true, colId: "checkboxcol" });
                documentGridCols = this.Document_GEN_Columns;
                document_Col_Sort_On_By = this.Document_GEN_Sort_By;
                this.docChartSettings = JSON.parse(appConfigs.filter(x => x.Key == 'Document_General_Chart_Settings')[0].Value);
                if (this.docChartSettings && this.docChartSettings.length > 0) {
                    this.docChartSettings.forEach(element => {
                        let chartConfig = {
                            label: element.chartType
                        }
                        this.chartMenuList.push(chartConfig)
                    });
                }
                break;

            case 'DOC':
                this.columnDefs = [];
                documentColumnDefs_1 = [];
                documentColumnDefs_1.push({ headerName: '', field: '', minWidth: 25, maxWidth: 50, checkboxSelection: true, headerCheckboxSelection: true, filter: false, sortable: false, menuTabs: [], rowDrag: true, colId: "checkboxcol" });
                documentGridCols = this.Document_DOC_Columns;
                document_Col_Sort_On_By = this.Document_DOC_Sort_By;
                this.docChartSettings = JSON.parse(appConfigs.filter(x => x.Key == 'Document_Documents_Chart_Settings')[0].Value);
                if (this.docChartSettings && this.docChartSettings.length > 0) {
                    this.docChartSettings.forEach(element => {
                        let chartConfig = {
                            label: element.chartType
                        }
                        this.chartMenuList.push(chartConfig)
                    });
                }
                break;

            case 'BND':
                this.columnDefs = [];
                documentColumnDefs_1 = [];
                documentColumnDefs_1.push({ headerName: '', field: '', minWidth: 25, maxWidth: 50, checkboxSelection: true, headerCheckboxSelection: true, filter: false, sortable: false, menuTabs: [], rowDrag: true, colId: "checkboxcol" });
                documentGridCols = this.Document_BND_Columns;
                document_Col_Sort_On_By = this.Document_BND_Sort_By;
                this.docChartSettings = JSON.parse(appConfigs.filter(x => x.Key == 'Document_Binders_Chart_Settings')[0].Value);
                if (this.docChartSettings && this.docChartSettings.length > 0) {
                    this.docChartSettings.forEach(element => {
                        let chartConfig = {
                            label: element.chartType
                        }
                        this.chartMenuList.push(chartConfig)
                    });
                }
                break;

            default: //MXR
            case 'MXR':
                this.columnDefs = [];
                documentColumnDefs_1 = [];
                documentColumnDefs_1.push({ headerName: '', field: '', minWidth: 25, maxWidth: 50, checkboxSelection: true, headerCheckboxSelection: true, filter: false, sortable: false, menuTabs: [], rowDrag: true, colId: "checkboxcol" });
                documentGridCols = this.Document_MXR_Columns;
                document_Col_Sort_On_By = this.Document_MXR_Sort_By;
                break;
        }
        this.popupParent = document.body;
        documentGridCols.forEach((col, index) => {

            let _filter: any;
            _filter = 'set';

            if (col.FieldName == 'Date' || col.FieldName == 'EndDate' || col.FieldName == 'DocDate' || col.FieldName == 'CreatedOn' || col.FieldName == 'ModifiedOn') {
                _filter = 'agDateColumnFilter';
            }
            else if (col.FieldName == 'DocNo' || col.FieldName == 'DocNoExternal' || col.FieldName == 'ReferenceKey') {
                _filter = 'text';
            }
            else if (col.FieldName == 'Tags') {
                _filter = false;
            }

            // var chartConfig = this.docChartSettings.chartConfigurationList.find(x => x.FieldName == col.FieldName);
            let gridCol: any = {
                headerName: col.ColumnLabel,
                field: col.FieldName,
                width: col.Width,
                filter: _filter,
                tooltipField: col.FieldName,
                menuTabs: col.FieldName == 'Tags' ? [] : ['filterMenuTab'],
                sortable: col.FieldName == 'Tags' ? false : true,
                hide: !col.IsVisible
            };
            // if(chartConfig && chartConfig.chartDataType)
            // gridCol.chartDataType = chartConfig.chartDataType
            // else{
            //     gridCol.chartDataType = 'excluded'
            // }
            // if(chartConfig && chartConfig.aggFunc)
            // gridCol.aggFunc = chartConfig.aggFunc
            if (col.FieldName == 'Date' || col.FieldName == 'EndDate' || col.FieldName == 'DocDate' || col.FieldName == 'CreatedOn' || col.FieldName == 'ModifiedOn') {
                gridCol.filterParams =
                {
                    //comparator: this.dateComparater,
                    inRangeInclusive: 'true'
                }
                gridCol.valueFormatter = function (params: any) {
                    if (params.value != '' && params.value != null) {

                        let dateString = params.value.substring(0, 10);
                        return moment(dateString).format(dateFormatF.toUpperCase());
                    }
                }
            } else if (col.FieldName == 'Status') {
                gridCol.cellRenderer = this.statusCellRenderer;
                gridCol.filterParams = this.gridStatusFilterParams;
                gridCol.editable = this.canEditMetadataQualityStatus;
                if (this.canEditMetadataQualityStatus) {
                    gridCol.cellEditor = 'agRichSelectCellEditor';
                    gridCol.cellEditorParams = {
                        values: statusList.map(sts => sts.Name.trim()),
                        cellRenderer: this.statusCellRendererEdit
                    };
                }
            }
            else if (col.FieldName == 'Tags') {
                gridCol.cellRenderer = this.tagsCellRenderer;
            }

            if (col.FieldName == 'DocNo' && col.ContainerCategory == 'LOG') {
                //gridCol.comparator = this.DocNoComparator(valueA, valueB, nodeA, nodeB, isInverted)
                gridCol.comparator = function (valueA, valueB, nodeA, nodeB, isInverted) {
                    var currentNumeric = isNullOrUndefined(valueA) == false ? (valueA.replace(/\D/g, "")) : 0;
                    var nextNumeric = isNullOrUndefined(valueB) == false ? (valueB.replace(/\D/g, "")) : 0;

                    return Number(currentNumeric) < Number(nextNumeric) ? -1 : Number(currentNumeric) > Number(nextNumeric) ? 1 : 0;
                }
            }

            documentColumnDefs_1.push(gridCol);
        });
        documentColumnDefs_1.filter(x => x.headerName == '')[0].chartDataType = 'excluded'
        this.columnDefs = documentColumnDefs_1;
        this.chartColumnDefs = cloneDeep(this.columnDefs);
        let sortOnCol = document_Col_Sort_On_By.split("||")[0];
        let orderBy = document_Col_Sort_On_By.split("||")[1];
        this.sortOnCol = sortOnCol;
        this.orderBy = orderBy;
        this.columnDefs.forEach(function (col, index) {
            if (col.field == sortOnCol) {
                this.columnDefs[index].sort = orderBy;
            }
        }.bind(this));

    }
    gridStatusFilterParams = { cellRenderer: this.statusCellRenderer };
    containerGridStatusFilterParams = { cellRenderer: this.containerStatusCellRenderer };
    selectFirstRow(event: any) {

        // if (this.gridOptions && this.gridOptions.api) { this.gridOptions.api.sizeColumnsToFit(); }
        // this.setGridColumnWidth();
        if (this.docGridData.length) {
            let selectedDocs = sharedService.selectedDocumentsGridState;
            let isDocManipulated = sharedService.isDocManipulated;
            if (selectedDocs && isDocManipulated == true) {
                //Bug 11529: Roam> records> edit file meta data loses focus on that document.
                // _gridOptions.api.forEachNode(function (node) {
                //     let gridDocURL = node.data.Url.split('?')[0];
                //     for (var i = 0; i < selectedDocs.length; i++) {
                //         let selectedNode = selectedDocs[i];
                //         let url = selectedNode.Url.split("?")[0]
                //         if (gridDocURL === url) {
                //             // _gridOptions.api.selectNode(node, true);
                //             _gridOptions.api.getRowNode(node.id).setSelected(true);
                //         }
                //     }
                // });
                _gridOptions.api.getRowNode(sharedService.selectedDocumentNode).setSelected(true);
                sharedService.isDocManipulated = false;
            } else { this.gridOptions.api.getDisplayedRowAtIndex(0).setSelected(true); }
            let selRow = this.gridOptions.api.getSelectedRows();
            this.searchSelectedRows = this.gridOptions.api.getSelectedRows();
            this._sharedService.onDocumentContainerSelection(selRow[0]);
            this.autoRunLogAnalyser();
            this.autoRunDuplicateAnalyzer();

        } else {
            this._sharedService.onDocumentContainerSelection(this.docGridData);
        }
    }
    DocNoComparator(valueA, valueB, nodeA, nodeB, isInverted) {
        // not in use
    }

    tagsCellRenderer(params: any) {
        let tags: string = '';
        let html: string = '';

        if (params.value) {
            let tagArray = String(params.value).split(',');
            for (let i = 0; i < tagArray.length; i++) {
                let color: string = '';
                //Get Tag Color
                for (let j = 0; j < Tags.length; j++) {
                    if (Tags[j].Name == tagArray[i]) {
                        color = Tags[j].Color;
                    }
                }

                //Update Tag
                if (displayMode == 'status') {
                    html = html + '<span class="padding-5 label" style= "background-color:' + color + ';width: 15px;height: 20px;padding-left: 3px !important;padding-top:1px !important;" title="' + tagArray[i] + '" >' + tagArray[i].substring(0, 1).toUpperCase() + '</span>';
                } else {

                    html = html + '<span class="padding-5 label" style= "background-color:' + color + ';" title="' + tagArray[i] + '" >' + tagArray[i].substring(0, 1).toUpperCase() + '</span>';
                }
            }
        }

        var eDiv = document.createElement('div');
        eDiv.innerHTML = html

        //var eButton = eDiv.querySelectorAll('.btn-simple')[0];

        //var eButton.addEventListener('click', function () {
        //    console.log('button was clicked!!');
        //});

        return eDiv;
    }

    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }
        this.canMXRQualityStatus = this._sharedService.UserRole.Records_MXR_Doc_Quality_Status;
        this.canLOGQualityStatus = this._sharedService.UserRole.Records_LOG_Doc_Quality_Status;
        this.canGENQualityStatus = this._sharedService.UserRole.Records_GEN_Doc_Quality_Status;
        switch (this._sharedService.recordType.toUpperCase()) {
            case 'MXR':
                this.canEdit = this._sharedService.UserRole.Records_MXR_Con_Edit;
                this.canDelete = this._sharedService.UserRole.Records_MXR_Doc_Delete;
                //this.canDownloadPDF = this._sharedService.UserRole.Records_MXR_Doc_Edit_PDF;
                //this.canDownloadCSV = this._sharedService.UserRole.Records_MXR_Doc_Edit_PDF;
                this.canEditMetaData = this._sharedService.UserRole.Records_MXR_Doc_Edit_Metadata;
                this.canCopyMove = this._sharedService.UserRole.Records_MXR_Doc_CopyMove;
                this.canEditMetaDataReadonly = this._sharedService.UserRole.Records_MXR_Readonly;
                this.canUpload = this._sharedService.UserRole.Records_MXR_Doc_Single_Upload;
                this.canBulkUpload = this._sharedService.UserRole.Records_MXR_Doc_Bulk_Upload;
                this.canAnalyzeTechLog = false;
                this.canMXRQualityStatus = this._sharedService.UserRole.Records_MXR_Doc_Quality_Status;
                this.canEditMetadataQualityStatus = this._sharedService.UserRole.Records_MXR_Doc_Quality_Status;
                break;
            case 'LOG':
                this.canEdit = this._sharedService.UserRole.Records_LOG_Con_Edit;
                this.canDelete = this._sharedService.UserRole.Records_LOG_Doc_Delete;
                //this.canDownloadPDF = this._sharedService.UserRole.Records_LOG_Doc_Edit_PDF;
                //this.canDownloadCSV = this._sharedService.UserRole.Records_LOG_Doc_Edit_PDF;
                this.canEditMetaData = this._sharedService.UserRole.Records_LOG_Doc_Edit_Metadata;
                this.canCopyMove = this._sharedService.UserRole.Records_LOG_Doc_CopyMove;
                this.canEditMetaDataReadonly = this._sharedService.UserRole.Records_LOG_Readonly;
                this.canUpload = this._sharedService.UserRole.Records_LOG_Doc_Single_Upload;
                this.canBulkUpload = this._sharedService.UserRole.Records_LOG_Doc_Bulk_Upload;
                this.canAnalyzeTechLog = true;
                this.canLOGQualityStatus = this._sharedService.UserRole.Records_LOG_Doc_Quality_Status;
                this.canEditMetadataQualityStatus = this._sharedService.UserRole.Records_LOG_Doc_Quality_Status;
                break;
            case 'GEN':
                this.canEdit = this._sharedService.UserRole.Records_GEN_Con_Edit;
                this.canDelete = this._sharedService.UserRole.Records_GEN_Doc_Delete;
                //this.canDownloadPDF = this._sharedService.UserRole.Records_GEN_Doc_Edit_PDF;
                //this.canDownloadCSV = this._sharedService.UserRole.Records_GEN_Doc_Edit_PDF;
                this.canEditMetaData = this._sharedService.UserRole.Records_GEN_Doc_Edit_Metadata;
                this.canCopyMove = this._sharedService.UserRole.Records_GEN_Doc_CopyMove;
                this.canEditMetaDataReadonly = this._sharedService.UserRole.Records_GEN_Readonly;
                this.canUpload = this._sharedService.UserRole.Records_GEN_Doc_Single_Upload;
                this.canBulkUpload = this._sharedService.UserRole.Records_GEN_Doc_Bulk_Upload;
                this.canAnalyzeTechLog = false;
                this.canGENQualityStatus = this._sharedService.UserRole.Records_GEN_Doc_Quality_Status;
                this.canEditMetadataQualityStatus = this._sharedService.UserRole.Records_GEN_Doc_Quality_Status;
                break;
            case 'DOC':
                this.canEdit = this._sharedService.UserRole.Records_DOC_Con_Edit;
                this.canDelete = this._sharedService.UserRole.Records_DOC_Doc_Delete;
                //this.canDownloadPDF = this._sharedService.UserRole.Records_DOC_Doc_Edit_PDF;
                //this.canDownloadCSV = this._sharedService.UserRole.Records_DOC_Doc_Edit_PDF;
                this.canEditMetaData = this._sharedService.UserRole.Records_DOC_Doc_Edit_Metadata;
                this.canCopyMove = this._sharedService.UserRole.Records_DOC_Doc_CopyMove;
                this.canEditMetaDataReadonly = this._sharedService.UserRole.Records_DOC_Readonly;
                this.canUpload = this._sharedService.UserRole.Records_DOC_Doc_Single_Upload;
                this.canBulkUpload = this._sharedService.UserRole.Records_DOC_Doc_Bulk_Upload;
                this.canAnalyzeTechLog = false;
                this.canDOCQualityStatus = this._sharedService.UserRole.Records_DOC_Doc_Quality_Status;
                this.canEditMetadataQualityStatus = this._sharedService.UserRole.Records_DOC_Doc_Quality_Status;
                break;
            case 'BND':
                this.canEdit = this._sharedService.UserRole.Transition_BND_Con_Edit;
                this.canDelete = this._sharedService.UserRole.Transition_BND_Doc_Delete;
                //this.canDownloadPDF = this._sharedService.UserRole.Transition_BND_Doc_Edit_PDF;
                //this.canDownloadCSV = this._sharedService.UserRole.Transition_BND_Doc_Edit_PDF;
                this.canEditMetaData = this._sharedService.UserRole.Transition_BND_Doc_Edit_Metadata;
                this.canCopyMove = this._sharedService.UserRole.Transition_BND_Doc_CopyMove;
                this.canEditMetaDataReadonly = this._sharedService.UserRole.Transition_BND_Readonly;
                this.canUpload = this._sharedService.UserRole.Transition_BND_Doc_Single_Upload;
                this.canBulkUpload = this._sharedService.UserRole.Transition_BND_Doc_Bulk_Upload;
                this.canAnalyzeTechLog = false;
                this.canDOCQualityStatus = this._sharedService.UserRole.Transition_BND_Doc_Quality_Status;
                this.canEditMetadataQualityStatus = this._sharedService.UserRole.Transition_BND_Doc_Quality_Status;
                break;
        }


        this.canViewFeedFromStatus = this._sharedService.UserRole.API_Import_Readonly;
        this.CanRunWOGap();
    }


    resetGridSize() {
        let gridHeight: number = $('#documentGrid').css('height').replace('px', '');
        var currentHeight = ''
        if (this._router.url.indexOf("/status/") > -1) {
            currentHeight = (gridHeight - 31.99) + 'px !important';
        }
        else {
            currentHeight = (gridHeight - 47) + 'px !important';
        }

        $("#documentGrid").attr('style', 'height:' + currentHeight + '');
        var myEl = document.getElementById('fset');

        myEl.addEventListener('click', function () {

            if (!$('browse-container>article>div').css('height') || !$('browse-document>article>div').css('height') || !$('browse-document>article>div>div>div:nth-child(2)').find('#searchblock').css('height')) {

                return;
            }

            let browseContainerHeight: number = $('browse-container>article>div').css('height').replace('px', '');
            let browseDocumentHeight: number = $('browse-document>article>div').css('height').replace('px', '');
            if (browseContainerHeight == 0) {
                let fieldSetheight: number = $('.fset').css('height').replace('px', '');
                if (fieldSetheight < 12) {
                    fieldSetheight = fieldSetheight + $('.fset').css('padding').replace('px', '');
                }
                let innerGridHeight: number = $('browse-document>article>div>div').css('height').replace('px', '');
                let innerGridPadding: number = $('browse-document>article>div>div').css('padding').replace('px', '');
                let innerGridHeaderHeight: number = $('browse-document>article>div>div>div:nth-child(2)').find('#searchblock').css('height').replace('px', '');
                let ActualGridHeight = innerGridHeight - innerGridPadding - innerGridHeaderHeight - fieldSetheight - 1;
                let currentHeight: string = ActualGridHeight + 'px !important';
                $("#documentGrid").attr('style', 'height:' + currentHeight + '');
            }
            else {
                if (browseDocumentHeight > browseContainerHeight) {
                    let difference = browseDocumentHeight - browseContainerHeight;
                    let fieldSetheight: number = $('.fset').css('height').replace('px', '');
                    if (fieldSetheight < 12) {
                        fieldSetheight = fieldSetheight + $('.fset').css('padding').replace('px', '');
                    }
                    let innerGridHeight: number = $('browse-document>article>div>div').css('height').replace('px', '');
                    let innerGridPadding: number = $('browse-document>article>div>div').css('padding').replace('px', '');
                    let innerGridHeaderHeight: number = $('browse-document>article>div>div>div:nth-child(2)').find('#searchblock').css('height').replace('px', '');
                    let ActualGridHeight = innerGridHeight - innerGridPadding - innerGridHeaderHeight - difference - fieldSetheight - 1;
                    let currentHeight: string = ActualGridHeight + 'px !important';
                    $("#documentGrid").attr('style', 'height:' + currentHeight + '');
                }
                else {

                    var fieldSetheight: number = parseFloat($('.fset').css('height').replace('px', ''));
                    if (fieldSetheight < 12) {
                        fieldSetheight = fieldSetheight + $('.fset').css('padding').replace('px', '');
                    }
                    let innerGridHeight: number = $('browse-document>article>div>div').css('height').replace('px', '');
                    let innerGridPadding: number = $('browse-document>article>div>div').css('padding').replace('px', '');
                    let innerGridHeaderHeight: number = $('browse-document>article>div>div>div:nth-child(2)').find('#searchblock').css('height').replace('px', '');
                    if ($(location).attr('href').indexOf('status') > -1) {
                        fieldSetheight = 7 + fieldSetheight;
                    }
                    let ActualGridHeight = innerGridHeight - innerGridPadding - innerGridHeaderHeight - fieldSetheight - 1;
                    let currentHeight: string = ActualGridHeight + 'px !important';
                    $("#documentGrid").attr('style', 'height:' + currentHeight + '');
                }
            }
        }, false);
    }


    deleteInstance(objKeyWord: any) {
        this.resetGridSize();
        let index: number = this.advanceSearch.indexOf(objKeyWord);
        this.advanceSearch.splice(index, 1);
    }
    populateKeywordConditions() {
        this.keywordConditions = [
            { label: "OR", value: " || " },
            { label: "AND", value: " && " },
            { label: "NOT", value: " -" },
        ];
    }
    addAdvanceSearch() {


        let advanceSearch = new AdvanceSearch();
        advanceSearch.Condition = ' || ';
        advanceSearch.SearchValue = '';
        this.advanceSearch.push(advanceSearch);
        this.resetGridSize();
    }
    handleSearchButtonClick() {
        if ((this.searchQuery == undefined || this.searchQuery == "") && this.advanceSearch.length > 0) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Warning', detail: 'Search string in missing' });
        }
        else {
            let searchQuery = this.searchQuery
            if (!(this._sharedService.selectedAsset.length > 0)) {
                return false;
            }
            if (this.searchQuery) {
                //this.searchQuery = this.searchQuery.trim() == "" ? this.searchQuery = null : this.searchQuery.trim();
                for (let i = 0; i < this.advanceSearch.length; i++) {
                    if (this.advanceSearch[i].SearchValue != "") {
                        // if(this.advanceSearch[i].Condition == '*')
                        // {
                        //     if(this.searchQuery==undefined || this.searchQuery == "")
                        //     {
                        //         this.searchQuery=this.advanceSearch[i].SearchValue + "*";
                        //     }
                        //     else{
                        //         this.searchQuery=this.searchQuery + " || "+this.advanceSearch[i].SearchValue + "*";
                        //     }
                        // }
                        // else if(this.advanceSearch[i].Condition == '"'){
                        //     if(this.searchQuery==undefined || this.searchQuery == "")
                        //     {
                        //     this.searchQuery = this.advanceSearch[i].SearchValue +'"';
                        //     }
                        //     else{
                        //         this.searchQuery=this.searchQuery + " "+'"'+this.advanceSearch[i].SearchValue +'"';
                        //     }
                        // }
                        // else if(this.advanceSearch[i].Condition == '('){
                        //     if(this.searchQuery==undefined || this.searchQuery == "")
                        //     {
                        //     this.searchQuery = this.searchQuery + ' ('+this.advanceSearch[i].SearchValue +')';
                        //     }
                        //     else{
                        //         this.searchQuery = this.searchQuery + '('+this.advanceSearch[i].SearchValue +')';
                        //     }
                        // }

                        if (this.searchQuery == undefined || this.searchQuery == "") {
                            this.searchQuery = "" + this.advanceSearch[i].Condition
                            this.searchQuery = this.searchQuery + this.advanceSearch[i].SearchValue
                        }
                        else {
                            if (this.searchMode.toString() == "Partial") {
                                if (i == 0) {
                                    this.searchQuery = this.searchQuery + ".*/" + this.advanceSearch[i].Condition + "/.*"
                                }
                                else {
                                    this.searchQuery = this.searchQuery + this.advanceSearch[i].Condition + "/.*"

                                }
                                if (i + 1 == this.advanceSearch.length) {
                                    this.searchQuery = this.searchQuery + this.advanceSearch[i].SearchValue
                                }
                                else {
                                    this.searchQuery = this.searchQuery + this.advanceSearch[i].SearchValue + ".*/"
                                }
                            }
                            else if (this.searchMode.toString() == "Begin") {

                                if (i == 0) {
                                    this.searchQuery = this.searchQuery + "*" + this.advanceSearch[i].Condition

                                }
                                else {
                                    this.searchQuery = this.searchQuery + this.advanceSearch[i].Condition

                                }
                                if (i + 1 == this.advanceSearch.length) {
                                    this.searchQuery = this.searchQuery + this.advanceSearch[i].SearchValue
                                }
                                else {
                                    this.searchQuery = this.searchQuery + this.advanceSearch[i].SearchValue + "*"
                                }
                            }
                            else if (this.searchMode.toString() == "End") {
                                if (i == 0) {
                                    this.searchQuery = this.searchQuery + "/" + this.advanceSearch[i].Condition + "/.*"

                                }
                                else {
                                    this.searchQuery = this.searchQuery + this.advanceSearch[i].Condition + "/.*"

                                }
                                if (i + 1 == this.advanceSearch.length) {
                                    this.searchQuery = this.searchQuery + this.advanceSearch[i].SearchValue
                                }
                                else {
                                    this.searchQuery = this.searchQuery + this.advanceSearch[i].SearchValue + "/"
                                }
                            }
                            else {
                                this.searchQuery = this.searchQuery + this.advanceSearch[i].Condition
                                this.searchQuery = this.searchQuery + this.advanceSearch[i].SearchValue
                            }

                        }
                    }
                }
                this._sharedService.updateSearchText(this.searchQuery);
                _searchQuery = this.searchQuery;
                //this.isBlankSearchQuery = false;
            } else {
                this.isBlankSearchQuery = true;
            }

            this.loadGridData();
            this.isDragable = false;
            this.gridOptions.api.setSuppressRowDrag(true);
            this.searchQuery = searchQuery;
        }
    }

    loadGridData() {
        this.formattedSearchkeyword = this.searchQuery;
        let assetASN = [];
        let searchDatabase = "0"
        //FolderPath:/.*path.*/ AND content:query " // search query pattern

        // if (this.searchQuery != undefined && this.searchQuery != null && this.searchMode == "Begin") {
        //     this.formattedSearchkeyword += "*";
        // }
        // else if (this.searchQuery != undefined && this.searchQuery != null && this.searchMode == "End") {
        //     this.formattedSearchkeyword = "/.*" + this.formattedSearchkeyword + "/";
        // }
        // else if (this.searchQuery != undefined && this.searchQuery != null && this.searchMode == "Partial") {
        //     this.formattedSearchkeyword = "/.*" + this.formattedSearchkeyword + ".*/";
        // }

        assetASN.push(this.selectedContainer.ASN);
        if (this._sharedService.selectedAsset.length > 0) {
            //US-8181 //
            //let firstSelectedAsset = this._sharedService.selectedAsset[0].data.split('|');
            //searchDatabase = firstSelectedAsset[firstSelectedAsset.length - 1];

            searchDatabase = JSON.parse(this._sharedService.selectedAsset[0].data).SearchDatabaseID;
        }

        this.selectedSearchKeywords = {

            ContainerIndex: "blobindex",
            Container: this.selectedContainer.ContainerName,
            ContainerID: this.selectedContainer.ID,
            ContainerType: this.selectedContainer.Type,
            AssetMSNFacets: assetASN,
            SearchQuery: this.formattedSearchkeyword,//will be a plain text
            SearchTerm: this.searchMode,
            Top: "1000",
            SearchDatabaseID: searchDatabase,
            Module: null
        }

        this._azureSearchService.getAzureSearchData(this.selectedSearchKeywords)
            .subscribe({
                next: (response) => {
                    this.docGridData = [];
                    this.docGridData = response["ListAzureBlob"];
                    this.totalCount = +response["TotalCount"];
                },
                error: (error) => {
                }
            });
    }

    //#endregion

    //// #region date Format Settings

    getDateFormatInfo(): any {

        if (this.dateConfig != undefined) {
            dateFormatF = this.dateConfig.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
            dateSeparatorS = this.dateConfig.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
            dayD = Number(this.dateConfig.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
            monthM = Number(this.dateConfig.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
            yearY = Number(this.dateConfig.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
        } else {
            dateFormatF = localStorage.getItem('DateFormat');
            dateSeparatorS = localStorage.getItem('DateSeparater');
            dayD = Number(localStorage.getItem('DayIndex'));
            monthM = Number(localStorage.getItem('MonthIndex'));
            yearY = Number(localStorage.getItem('YearIndex'));

        }
    }


    //// #endregion

    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            // let dateString = cellValue.substring(0, 10).split(dateSeparatorS);
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateFormatF.toUpperCase()).split(dateSeparatorS);
            var day = Number(dateParts[Number(dayD)].substring(0, 2));
            var month = Number(dateParts[Number(monthM)]) - 1;
            var year = Number(dateParts[Number(yearY)]);
            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        } else {
            return -1;
        }
    }

    loadTags() {
        this._tagService.getAllTagsWithColor()
            .subscribe({
                next: (response) => {
                    Tags = response;
                },
                error: (error) => {
                }
            });
    }

    getDisplayedColumnNames(): any[] {
        //var columns = this.gridOptions.columnApi.getAllDisplayedColumns()[0].colDef.field

        var displayedColumns = this.gridOptions.columnApi.getAllDisplayedColumns();//[0].colDef.field
        let columnFields: any[] = [];

        displayedColumns.forEach(function (col, index) {
            columnFields.push(col.getColId());//colDef.field);
        });

        return columnFields;
    }

    exportGridData() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        var columnsToExport = this.getDisplayedColumnNames();
        columnsToExport = columnsToExport.splice(1);
        if (this.gridOptions.rowData.length > 0) {
            let fileName = this._sharedService.selectedContainer.ASN + "-" + this._sharedService.selectedContainer.Category + "-" + this._sharedService.selectedContainer.ID + ".csv";
            var params: CsvExportParams = {
                skipColumnHeaders: false,
                skipColumnGroupHeaders: false,
                skipRowGroups: false,
                //skipPinnedTop: false,
                //skipPinnedBottom: false,
                allColumns: false,
                onlySelected: false, //true
                suppressQuotes: false,
                fileName: fileName,
                columnSeparator: ',',
                columnKeys: columnsToExport,
                processCellCallback(params: any) {
                    if (params.column.colDef.field == 'Status') {
                        let _status: any = statusList.filter(x => x.Name == params.value)[0];
                        return _status.Name.trim();
                    }
                    else if (params.column.colDef.field.includes('Date') || params.column.colDef.field == 'ModifiedOn' || params.column.colDef.field == 'CreatedOn') {
                        if (params.value) {
                            var date: Date = params.value;
                            var formattedDate = moment(date).format(dateTimeConfig.dateformat.toUpperCase());;
                            return formattedDate;
                        }
                        else {
                            return params.value;
                        }
                    }
                    else {
                        return params.value;
                    }
                }
            };

            switch (this._sharedService.recordType.toUpperCase()) {

                case 'MXR':
                    //params.columnKeys = ['DocType', 'DocNo', 'Tags', 'ReferenceKey'];
                    if (this.canMXRQualityStatus && this.isQualityStatusAllowed('MXR')) {
                        params.columnKeys = ['Status', 'DocType', 'DocNo', 'Tags', 'ReferenceKey'];
                    }
                    else {
                        params.columnKeys = ['DocType', 'DocNo', 'Tags', 'ReferenceKey'];
                    }
                    break;
                case 'LOG':
                    //params.columnKeys = ['DocType', 'DocNo', 'Tags'];
                    if (this.canLOGQualityStatus && this.isQualityStatusAllowed('LOG')) {
                        params.columnKeys = ['Status', 'DocType', 'DocNo', 'Tags'];
                    }
                    else {
                        params.columnKeys = ['DocType', 'DocNo', 'Tags'];
                    }
                    break;
                case 'GEN':
                    //params.columnKeys = ['DocType', 'DocNo', 'Tags'];
                    if (this.canGENQualityStatus && this.isQualityStatusAllowed('GEN')) {
                        params.columnKeys = ['Status', 'DocType', 'DocNo', 'Tags'];
                    } else {
                        params.columnKeys = ['DocType', 'DocNo', 'Tags'];
                    }
                    break;
            }

            //params.columnKeys = ['DocType', 'DocNo', 'Tags'];
            params.columnKeys = columnsToExport;
            this.gridOptions.api.exportDataAsCsv(params);
        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Document', detail: 'There is no  document to export' });
        }
    }

    exportExcelGrid() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            var columnsToExport = this.getDisplayedColumnNames();
            columnsToExport = columnsToExport.splice(1);
            if (this.gridOptions.rowData.length > 0) {
                let fileName = this._sharedService.selectedContainer.ASN + "-" + this._sharedService.selectedContainer.Category + "-" + this._sharedService.selectedContainer.ID + ".xlsx";
                var params: ExcelExportParams = {
                    skipColumnGroupHeaders: false,
                    allColumns: false,
                    onlySelected: false,
                    fileName: fileName,
                    sheetName: 'Records-Documents',
                    columnKeys: columnsToExport,
                    columnWidth: 170,
                    processCellCallback(params: any) {
                        if (params.column.colDef.field == 'Status') {
                            let _status: any = statusList.filter(x => x.QualityStatusID == params.value)[0];
                            if (_status)
                                return _status.Name.trim();
                        }
                        else if (params.column.colDef.field.includes('Date') || params.column.colDef.field == 'ModifiedOn' || params.column.colDef.field == 'CreatedOn') {
                            if (params.value) {
                                var date: Date = params.value;
                                var formattedDate = moment(date).format(dateTimeConfig.dateformat.toUpperCase());;
                                return formattedDate;
                            }
                            else {
                                return params.value;
                            }
                        }
                        else {
                            return params.value;
                        }
                    }
                };

                switch (this._sharedService.recordType.toUpperCase()) {

                    case 'MXR':
                        //params.columnKeys = ['DocType', 'DocNo', 'Tags', 'ReferenceKey'];
                        if (this.canMXRQualityStatus && this.isQualityStatusAllowed('MXR')) {
                            params.columnKeys = ['Status', 'DocType', 'DocNo', 'Tags', 'ReferenceKey'];
                        } else {
                            params.columnKeys = ['DocType', 'DocNo', 'Tags', 'ReferenceKey'];
                        }
                        break;
                    case 'LOG':
                        //params.columnKeys = ['DocType', 'DocNo', 'Tags'];
                        if (this.canLOGQualityStatus && this.isQualityStatusAllowed('LOG')) {
                            params.columnKeys = ['Status', 'DocType', 'DocNo', 'Tags'];
                        } else {
                            params.columnKeys = ['DocType', 'DocNo', 'Tags'];
                        }
                        break;
                    case 'GEN':
                        //params.columnKeys = ['DocType', 'DocNo', 'Tags'];
                        if (this.canGENQualityStatus && this.isQualityStatusAllowed('GEN')) {
                            params.columnKeys = ['Status', 'DocType', 'DocNo', 'Tags'];
                        } else {
                            params.columnKeys = ['DocType', 'DocNo', 'Tags'];
                        }

                        break;
                }

                params.columnKeys = columnsToExport;
                this.setCustomHeaderAndExport(params);

            } else {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'No Document', detail: 'There is no  document to export' });
            }
        }
    }

    setCustomHeaderAndExport(params: ExcelExportParams) {
        this._sharedService.getConfigurationByKey("Excel_Export_Header_Records")
            .subscribe({
                next: (response) => {
                    let result = response;
                    let headerString = result.Value ? result.Value : '';
                    let moduleName = this._sharedService.selectedModule == 'transition' ? 'Transition' : "Records";
                    headerString = headerString.replace('{ModuleName}', moduleName);
                    headerString = headerString.replace('{DataType}', 'Documents');
                    headerString = headerString.replace('{TenantName}', this._sharedService.tenantName);
                    headerString = headerString.replace('{AirframeTitle}', this._sharedService.selectedAsset[0].label);
                    //US-8181 //  headerString = headerString.replace('{AssetType}', this._sharedService.selectedAsset[0].data.split('|')[4]);
                    headerString = headerString.replace('{AssetType}', JSON.parse(this._sharedService.selectedAsset[0].data).AssetType);
                    headerString = headerString.replace('{AssetTitle}', this._sharedService.selectedAsset[0].label);
                    //US-8181 //  headerString = headerString.replace('{ASN}', this._sharedService.selectedAsset[0].data.split('|')[1]);
                    headerString = headerString.replace('{ASN}', JSON.parse(this._sharedService.selectedAsset[0].data).ASN);
                    headerString = headerString.replace('{UserName}', this._sharedService.getCurrentUserName());
                    headerString = headerString.replace('{DateTime}', this.getFormatedDate(new Date()));
                    headerString = headerString.replace('{Filter}', this.getFilterString());
                    let container = 'Type = ' + this.selectedContainer.Type + ', Date = ' + this.getFormatedDate(this.selectedContainer.Date) + ', End Date = ' + this.getFormatedDate(this.selectedContainer.EndDate) + (this.selectedContainer.ReferenceKey ? (' ReferenceKey  = ' + this.selectedContainer.ReferenceKey) : '');
                    headerString = headerString.replace('{Container}', container);
                    const getRows: () => ExcelRow[] = () => JSON.parse(headerString);
                    params.prependContent = getRows();
                    this.gridOptions.api.exportDataAsExcel(params);
                },
                error: (error) => {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while downloading.' });
                    console.log(error);
                }
            });
    }

    setExcelStyle() {
        this.excelStyles = [
            {
                id: "greenBackground",
                interior: {
                    color: "#90ee90",
                    pattern: "Solid"
                }
            },
            {
                id: "redFont",
                font: {
                    underline: "Single",
                    italic: true,
                    color: "#ff0000"
                }
            },
            {
                id: "darkGreyBackground",
                interior: {
                    color: "#888888",
                    pattern: "Solid"
                }
            },
            {
                id: "boldBorders",
                borders: {
                    borderBottom: {
                        color: "#000000",
                        lineStyle: "Continuous",
                        weight: 3
                    },
                    borderLeft: {
                        color: "#000000",
                        lineStyle: "Continuous",
                        weight: 3
                    },
                    borderRight: {
                        color: "#000000",
                        lineStyle: "Continuous",
                        weight: 3
                    },
                    borderTop: {
                        color: "#000000",
                        lineStyle: "Continuous",
                        weight: 3
                    }
                }
            },
            {
                id: "header",
                interior: {
                    color: "#CCCCCC",
                    pattern: "Solid"
                }
            },
            {
                id: "twoDecimalPlaces",
                numberFormat: { format: "#,##0.00" }
            },
            {
                id: "textFormat",
                dataType: "string"
            },
            {
                id: "bigHeader",
                font: {
                    size: 16,
                    bold: true
                },
                alignment: {
                    horizontal: 'Center', vertical: 'Center'
                }
            }
        ];
    }
    getFormatedDate(date: any) {
        if (date) {
            return moment(date).format(this._sharedService.getDateTimeFormatConfiguration().dateformat.toUpperCase());
        } else {
            return '';
        }
    }

    getFilterString(): string {
        let filterModel = this.gridOptions.api.getFilterModel();
        let filterString = '';
        for (var filter in filterModel) {
            filterString = filterString.concat(filter, ', ');
        }

        return filterString ? filterString.slice(0, -2) : 'No';
    }

    //#region Delete Document(s)
    confirmDelete() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        let selectedRows = this.gridOptions.api.getSelectedRows();
        if (selectedRows.length > 0) {
            this._confirmationService.confirm({
                message: 'Do you want to delete document(s)?',
                header: 'Delete Confirmation',
                icon: 'fa fa-trash',
                accept: () => {
                    let listOfIdsToDelete: string = "";
                    let isCheckCommsExistence: boolean = true;
                    this.deleteDocuments(listOfIdsToDelete, isCheckCommsExistence)
                },
                reject: () => {
                }
            });
        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No document(s) selected', detail: 'Select document(s) to delete' });
        }
    }
    confirmDeleteComms(CompletelistOfIdsToDelete: string) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }

        let selectedRows = this.gridOptions.api.getSelectedRows();
        if (selectedRows.length > 0) {

            this._confirmationService.confirm({
                message: 'There is communication/ task attach to this record, do you still want to delete?',
                header: 'Delete Confirmation',
                icon: 'fa fa-trash',
                accept: () => {
                    let listOfIdsToDelete: string = CompletelistOfIdsToDelete;
                    let isCheckCommsExistence: boolean = false;
                    this.deleteDocuments(listOfIdsToDelete, isCheckCommsExistence)
                },
                reject: () => {
                }
            });
        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No document(s) selected', detail: 'Select document(s) to delete' });
        }
    }
    deleteDocuments(completelistOfIdsToDelete: string, isCheckCommsExistence: boolean) {
        let selectedRows = this.gridOptions.api.getSelectedRows();
        let blobList = {
            BlobList: this.getBlobListForDelete(),
            listOfIdsToDelete: completelistOfIdsToDelete,
            IsCheckCommsExistence: isCheckCommsExistence,
            ContainerID: this.selectedContainer.ContainerID,
            ASN: this.selectedContainer.ASN,
            ID: this.selectedContainer.ID,
            Type: this.selectedContainer.Type
        };
        if (blobList.BlobList.length <= 50) {

            this._sharedService.showLoader("Processing...");
            this._azureBlobService.deleteBlob(blobList)
                .subscribe({
                    next: (response) => {
                        this._sharedService.clearToast();
                        let resp = response;
                        switch (resp.ResponseType) {
                            case 1: // Deleted Successfully
                                this._sharedService.onAssetChanged(this._sharedService.selectedAsset);
                                this.refreshDocGrid();
                                this._sharedService.hideLoader();
                                this._sharedService.showToast({ severity: 'success', summary: 'Document(s) deleted', detail: 'Document(s) have been deleted successfully.' });
                                break;
                            case 2:
                                //this._sharedService.hideLoader();
                                //this.confirmDeleteDocumentsMessage(resp.data);
                                break;
                            case 3: // Task Documents linkage Found
                                this._sharedService.hideLoader();
                                this.confirmDeleteDocumentsMessage(resp.ResponseBody);
                                break;
                            case 4: // In case unable to delete document from search index
                                this.refreshDocGrid();
                                this._sharedService.hideLoader();
                                this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: resp.ResponseBody });
                                break;
                            default:// Deleted Successfully
                                this._sharedService.onAssetChanged(this._sharedService.selectedAsset);
                                this.refreshDocGrid();
                                this._sharedService.hideLoader();
                                this._sharedService.showToast({ severity: 'success', summary: 'Document(s) deleted', detail: 'Document(s) have been deleted successfully.' });
                        }
                    },
                    error: (error) => {
                        console.log(error);
                        if (error.status == 406) {
                            let listOfIdsToDelete: string = error.error;
                            let str = error.error.replace(/^"(.*)"$/, '$1');
                            this.listOfCommsIdsToDelete = str;
                            this._sharedService.clearToast();
                            // this.refreshDocGrid();
                            this._sharedService.hideLoader();
                            this.confirmDeleteComms(str);
                        } else {
                            this._sharedService.hideLoader();
                            this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while deleting document(s).' });
                        }
                    }
                });
        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Limit Exceeded', detail: 'Only 50 or less documents can be deleted at once.' });
        }
    }


    getBlobListForDelete(): AzureBlob[] {
        let blobList: AzureBlob[] = [];
        var selectedRows = this._sharedService._bulkSelectedDocumentsLst;
        //ContainerID: this.selectedContainer.ContainerID
        for (let i = 0; i < selectedRows.length; i++) {
            blobList.push(new AzureBlob(this._sharedService.getFileNameFromURL(selectedRows[i].Url, true), this.selectedContainer.ContainerName, this.selectedContainer.ID,
                this.selectedContainer.Type, this.selectedContainer.ASN,
                //selectedRows[i].Url,
                this._sharedService.getFileNameFromURL(selectedRows[i].Url, true), // this.getFileName(selectedRows[i].Url),
                selectedRows[i].DocType, selectedRows[i].DocDate, selectedRows[i].DocNo, null, selectedRows[i].Comments, selectedRows[i].ReferenceKey, null, null, selectedRows[i].ReferenceKey, selectedRows[i].Uuid));
        }
        return blobList;
    }
    getBlobList(selectedRows: any[]): AzureBlob[] {
        let blobList: AzureBlob[] = [];

        //ContainerID: this.selectedContainer.ContainerID
        for (let i = 0; i < selectedRows.length; i++) {
            blobList.push(new AzureBlob(this._sharedService.getFileNameFromURL(selectedRows[i].Url, true), this.selectedContainer.ContainerName, this.selectedContainer.ID,
                this.selectedContainer.Type, this.selectedContainer.ASN,
                //selectedRows[i].Url,
                this._sharedService.getFileNameFromURL(selectedRows[i].Url, true), // this.getFileName(selectedRows[i].Url),
                selectedRows[i].DocType, selectedRows[i].DocDate, selectedRows[i].DocNo, null, selectedRows[i].Comments, selectedRows[i].ReferenceKey, null, null, selectedRows[i].ReferenceKey, selectedRows[i].Uuid));
        }
        return blobList;
    }

    // getFileName(fileUrl: string): string {
    //     let newString: string[] = fileUrl.split('?')[0].split('/');
    //     let fileName: string = newString[newString.length - 1];
    //     return fileName;
    // }

    refreshDocGrid() {
        this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
        this._sharedService.onAssetChanged(this._sharedService.selectedAsset);
        // setTimeout(() => {
        //     this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
        //     this._sharedService.onAssetChanged(this._sharedService.selectedAsset);
        // }, 2000);
    }

    //#endregion

    //#region Copy/Move Document(s)
    copyMoveDocuments() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }

        let selDocuments = this.gridOptions.api.getSelectedRows();
        if (selDocuments.length > 0) {
            this.resetContainerModel();
            this.selectedComboCatergorySelection = this._sharedService.recordType;
            //  this.setContainerGridColumns();
            this.displayMoveCopyDlg = true;
            this.conRowSelected = false;
            this.pageRange = null;
        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No document(s) selected', detail: 'Select document(s) to Copy/Move' });
        }

    }

    resetContainerModel() {
        this.containerModel = new SearchContainerModel("", null, null, false);
        this.conGridData = [];
    }

    populateAsset() {
        this._assetService
            .getAssetWithAssetIdByUserRole()
            .subscribe({
                next: (response) => {
                    this.assets = response;
                },
                error: (error) => {
                    this.assets = [];
                    console.log(error);
                }
            });
    }

    populateContainerType(category: string) {
        this.conTypes = [];
        let body: container = {
            Category: category
        }
        this._containerService.getContainerType(body)
            .subscribe({
                next: (response) => {
                    let res = response;
                    for (let i = 0; i < res.length; i++) {
                        this.conTypes.push({ value: res[i].Type, label: res[i].Type })
                    }
                },
                error: (error) => {
                    this.conGridData = [];
                    this.conTotalCount = 0;
                }
            });
    }

    onChangeAsset(event: any) {
        this.conRowSelected = false;
        this.conGridData = [];
        //US-8181 //let assetid = event.value.split('|')[3].trim();
        let assetid = JSON.parse(event.value).AssetID;
        this.populateCategoryByAssetID(assetid);
    }
    selectedASNForSourceCon: any;
    populateCategoryByAssetID(assetID: string): void {
        this.categories = [];
        this._assetService.getAssetbyID(assetID)
            .subscribe({
                next: (response) => {
                    this.categories = [];
                    this.selectedASNForSourceCon = response
                    if (response.MXRecords) {
                        this.categories.push({ value: 'MXR', label: 'MX Records' });
                    }
                    if (response.LogBooks) {
                        this.categories.push({ value: 'LOG', label: 'Log Books' });
                    }
                    if (response.General) {
                        this.categories.push({ value: 'GEN', label: 'General' });
                    }
                    if (response.Documents) {
                        this.categories.push({ value: 'DOC', label: 'Documents' });
                    }
                    if (response.Binders) {
                        this.categories.push({ value: 'BND', label: 'Binders' });
                    }
                },
                error: (error) => {
                    this.categories = [];
                    console.log(error);
                }
            });
    }


    onChangeCategory(event: any) {
        this.conRowSelected = false;
        this.conGridData = [];
        this.docCopyFromGridData = [];
        this.populateContainerType(event.value);
        this.selectedComboCatergorySelection = event.value;
        this.setContainerGridColumns();
        this.setDocumentGridColumnsForCopyFrom(event.value)
    }

    populateContainerGrid(): void {
        this._containerService.getFilteredContainer(this.containerModel)
            .subscribe({
                next: (response) => {
                    this.setContainerGridColumns();
                    this.conGridData = response;
                    //    this.conTotalCount = this.conGridData.length;
                    //this.gridOptions.api.sizeColumnsToFit();
                },
                error: (error) => {
                    this.conGridData = [];
                    this.conTotalCount = 0;
                    console.log(error);
                }
            });
    }

    btnCancelContainerClick(containerForm: NgForm) {
        this.displayMoveCopyDlg = false;
        if (containerForm != null) {
            containerForm.resetForm();
        }
        // this.showInboxLinkDptDlg = false;
        // this.showStatusLinkDptDlg = false;
        this.showDependencyDlg = false;
        this.showInvalidOrMissingInfoDlg = false;
        this.showImageToPDFDlg = false;
        this.pageRange = null;

    }

    //this.docImageToPDFGridData = [];

    initImageToPDFGrid() {
        this.gridOptionsImageToPDF = <GridOptions>
            {
                rowSelection: 'multiple',
                onGridReady: () => {
                    this.setImageToPDFGridColumns();
                },
                suppressContextMenu: true,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    filter: true
                }
            };
    }
    setImageToPDFGridColumns(): void {
        this.columnDefsImageToPDF = [];

        if (this.recordType == "DOC" || this.recordType == "BND") {
            this.columnDefsImageToPDF.splice(0, 0, { headerName: 'File Name', minWidth: 180, field: 'BlobName' });
        }
    }
    initInvalidOrMissingInfoDocGrid() {
        this.gridOptionsInvalidOrMissingInfoDoc = <GridOptions>
            {
                rowSelection: 'multiple',
                onGridReady: () => {
                    this.setInvalidOrMissingInfoDocGridColumns();
                },
                suppressContextMenu: true,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    filter: true
                }
            };
    }
    setInvalidOrMissingInfoDocGridColumns(): void {
        this.columnDefsInvalidOrMissingInfoDoc = [];
        // if (this.recordType == "DOC") {
        //     this.columnDefsInvalidOrMissingInfoDoc.push(
        //         { headerName: 'File Name', minWidth: 180, field: 'BlobName' }
        //     );
        // }
        this.columnDefsInvalidOrMissingInfoDoc =
            [
                {
                    headerName: 'Document Type', minWidth: 100, width: 190, field: 'DocType', valueFormatter: function (params: any) {
                        if (params.value != "" && params.value != null) {
                            return params.value;
                        }
                        else {
                            return 'Missing';
                        }
                    }
                },
                // { headerName: 'Number', field: 'DocNo', minWidth: 60, width: 130 },
                // { headerName: referenceKeyLabel, field: 'ReferenceKey', minWidth: 50, width: 120 },
                {
                    headerName: 'Doc Date', field: 'DocDate', filter: 'agDateColumnFilter', minWidth: 50, width: 100, valueFormatter: function (params: any) {
                        if (params.value != "" && params.value != null) {

                            let dateString = params.value.substring(0, 10);
                            return moment(dateString).format(dateFormatF.toUpperCase());
                        }
                        else {
                            return 'Missing';
                        }
                    }
                },
            ];

        if (this.recordType == "DOC" || this.recordType == "BND") {
            this.columnDefsInvalidOrMissingInfoDoc.splice(0, 0, { headerName: 'File Name', minWidth: 180, field: 'BlobName' });
        }
    }

    initDependentLinkGridDpt() {
        this.gridOptionsDependencies = <GridOptions>
            {
                rowSelection: 'multiple',
                onGridReady: () => {
                    this.setDocumentDptGridColumns();
                },
                suppressContextMenu: true,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    filter: true
                }
            };
    }

    setDocumentDptGridColumns(): void {
        this.columnDefsDpt = [];
        // if (this.recordType == "DOC") {
        //     this.columnDefsDpt.push(
        //         { headerName: 'File Name', minWidth: 180, field: 'BlobName' }
        //     );
        // }
        this.columnDefsDpt =
            [
                { headerName: 'Document Type', minWidth: 100, width: 190, field: 'DocType' },
                { headerName: 'Number', field: 'DocNo', minWidth: 60, width: 130 },
                { headerName: referenceKeyLabel, field: 'ReferenceKey', minWidth: 50, width: 120 },
                {
                    headerName: 'Doc Date', field: 'DocDate', filter: 'agDateColumnFilter', minWidth: 50, width: 100, valueFormatter: function (params: any) {
                        if (params.value != "" && params.value != null) {

                            let dateString = params.value.substring(0, 10);
                            return moment(dateString).format(dateFormatF.toUpperCase());
                        }
                    }
                },
                {
                    headerName: 'Inbox Links', field: 'HasComms', minWidth: 50, width: 80, valueFormatter: function (params: any) {
                        return params.value == true ? "Yes" : "No";
                    }
                },
                {
                    headerName: 'Status Links', field: 'HasTasks', minWidth: 50, width: 80, valueFormatter: function (params: any) {
                        return params.value == true ? "Yes" : "No";
                    }
                },
            ];

        if (this.recordType == "DOC" || this.recordType == "BND") {
            this.columnDefsDpt.splice(0, 0, { headerName: 'File Name', minWidth: 180, field: 'BlobName' });
        }
    }

    initInboxDocumentLinkGridDpt() {
        this.gridOptionsInboxLinkDpt = <GridOptions>
            {
                rowSelection: 'multiple',
                onGridReady: () => {
                    //this.setDocumentDptGridColumn_s();
                },
                suppressContextMenu: true,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    filter: true
                }
            };
    }


    btnImageFileToPDFDocClick(actionForDependencies: string) {
        this.moveActionForImageFileToPDF = actionForDependencies;
        this.showImageToPDFDlg = false;

        this.simpleObservable
            .subscribe({
                next: (bodyDataNew) => {
                    // do stuff with our data here.
                    // ....

                    // asign data to our class property in the end
                    // so it will be available to our template
                    this.moveBlobServiceCheck(bodyDataNew);
                },
                error: (error) => {
                }
            });
    }

    btnInvalidOrMissingDocClick(actionForDependencies: string) {
        this.moveActionForInvalidFiles = actionForDependencies;
        this.showInvalidOrMissingInfoDlg = false;
        let docCount = _gridOptions.api.getSelectedRows();
        if (this.docInvalidOrMissingInfoGridData.length == docCount.length) {
            this._sharedService.showToast({ severity: 'warn', summary: 'Document(s) moved/copied', detail: 'No Document(s) have been moved/copied.' });
        } else {
            this.simpleObservable
                .subscribe({
                    next: (bodyDataNew) => {
                        // do stuff with our data here.
                        // ....

                        // asign data to our class property in the end
                        // so it will be available to our template
                        this.moveBlobServiceCheck(bodyDataNew);
                    },
                    error: (error) => {
                    }
                });
        }
    }


    btnDocDependencyClick(actionForDependencies: string) {
        this.moveActionForDependencies = actionForDependencies;
        this.showDependencyDlg = false;

        this.simpleObservable
            .subscribe({
                next: (bodyDataNew) => {
                    // do stuff with our data here.
                    // ....

                    // asign data to our class property in the end
                    // so it will be available to our template
                    this.moveBlobServiceCheck(bodyDataNew);
                },
                error: (error) => {
                }
            });
    }
    pageRange: string
    selectedPageRange(pageRange, containerForm: NgForm): void {
        this.pageRange = pageRange;

        if (this.displayCopyFromDlg) {
            let docCount = _docCopyFromGridOptions.api.getSelectedRows();
            if (docCount.length > 0)
                this.btnMoveCopyCopyFrom(true, containerForm)
            else {
                this._sharedService.clearToast();
                this._sharedService.showToast({
                    severity: 'warn', summary: 'No document(s) selected', detail: 'Select document(s) to Copy/Move'
                });
            }

        }
        else {

            let docCount = _gridOptions.api.getSelectedRows();
            if (docCount.length > 0)
                this.btnMoveCopyDocumentClick(true, containerForm);
            else {
                this._sharedService.clearToast();
                this._sharedService.showToast({
                    severity: 'warn', summary: 'No document(s) selected', detail: 'Select document(s) to Copy/Move'
                });
            }
        }
    }

    btnMoveCopyCopyFrom(isCopy: boolean, containerForm: NgForm) {
        //US-8181 //if (this.containerModel.asn.split('|')[4] == 'True')
        //    var sourceContainer = this.conGridOptions.api.getSelectedRows()[0]
        this.isFrom=true;
        let docCount = _docCopyFromGridOptions.api.getSelectedRows();
        if (docCount.length > 0) {
            if (JSON.parse(containerForm.value.asn).ReadOnly) {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Readonly Asset', detail: 'Cannot Move/Copy documnet(s) to readonly asset.' });
                return;
            }
            this._sharedService.showLoader("Processing...");
            let selContainer = this.conGridOptions.api.getSelectedRows();
            let selContainerObject = selContainer[0];
            if (selContainer.length > 0) {
                //set Target ContainerName and IsCopy Flag
                //US-8181 //selContainer[0].ContainerName = this.containerModel.asn.split('|')[0];
                selContainer[0].ContainerName = JSON.parse(containerForm.value.asn).StoragePath;
                selContainer[0].isCopy = isCopy;
                this.isCopy = isCopy;

                let selDocuments = _docCopyFromGridOptions.api.getSelectedRows();
                this.selectedBatchSize = selDocuments.length;
                
                if (selDocuments.length < this.batchSize) 
                {
                    //Set Source Container Name, Type, ID, ASN and save File Name in Url field
                    for (let i = 0; i < selDocuments.length; i++) {
                        selDocuments[i].ContainerName = selContainerObject.ContainerName;
                        selDocuments[i].Type = selContainerObject.Type;
                        selDocuments[i].ID = selContainerObject.ID;
                        selDocuments[i].ASN = selContainerObject.ASN;
                        //selDocuments[i].Url = this._sharedService.getFileNameFromURL(selDocuments[i].Url, true); // this.getFileName(selDocuments[i].Url);
                        selDocuments[i].Url = selDocuments[i].Url;
                        //if(selDocuments[i].VideoUrl!=null){
                        //    selDocuments[i].BlobName =selDocuments[i].VideoUrl;
                        // }
                        //  else{
                        selDocuments[i].BlobName = this._sharedService.getFileNameFromURL(selDocuments[i].Url, true);

                    }

                    this.selectedTargetContainer = this.selectedContainer;
                    this.selectedTargetContainer.isCopy = isCopy;
                    this.selectedDocuments = selDocuments;

                    let bodyData = {
                        Container: this.selectedContainer,
                        BlobList: selDocuments,
                        // MoveActionForDocStatus: this.moveActionForDocStatus,
                        // MoveActionForDocInbox: this.moveActionForDocInbox,
                        MoveActionForDependencies: this.moveActionForDependencies,
                        MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                        MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                        PageRange: null
                    };
                    if (!isCopy)//move(true)//
                    {
                        var AlreadyExistsDocs = [];
                        var ValidDocs = [];
                        // get document dependencies by communications & statuses
                        this._azureBlobService.getDocumentReferences(bodyData)
                            .subscribe({
                                next: (response) => {
                                    this.docRefToInbox = [];
                                    this.docRefToStatus = [];

                                    this.docRefToInboxGridData = [];
                                    this.docRefToStatusGridData = [];
                                    // this.moveActionForDocStatus = '';
                                    // this.moveActionForDocInbox = '';

                                    this.docRefToDependencies = [];
                                    this.docRefToDependenciesGridData = [];
                                    this.docInvalidOrMissingInfoGridData = [];
                                    this.docImageToPDFGridData = [];
                                    this.moveActionForDependencies = '';
                                    this.moveActionForInvalidFiles = '';
                                    this.moveActionForImageFileToPDF = '';


                                    this.docDependencies = response;
                                    this.docRefToInbox = this.docDependencies.filter(item => item.Comms.length > 0);
                                    this.docRefToStatus = this.docDependencies.filter(item => item.Tasks.length > 0);
                                    //this.docRefToDependencies = this.docDependencies.filter(item => item.Tasks.length > 0 || item.Comms.length > 0);
                                    let invalidDocs = [];
                                    this.docDependencies.forEach((element, index) => {
                                        if (element.HasMissingDataOrInvalidBlob) {
                                            element.AzureBlobEntity.HasMissingDataOrInvalidBlob = element.HasMissingDataOrInvalidBlob;
                                            invalidDocs.push(element.AzureBlobEntity);

                                        }
                                        if (element.HasAlreadyFile) {
                                            AlreadyExistsDocs.push(element.AzureBlobEntity);
                                        }
                                        else if (!element.HasComms && !element.HasMissingDataOrInvalidBlob && !element.HasTasks && !element.IsImage) {
                                            ValidDocs.push(element.AzureBlobEntity);
                                        }
                                    });

                                    if (invalidDocs.length > 0) {
                                        this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Document(s) references found', detail: 'Some document(s) are invalid or have missing information. Please verify..' });

                                        // create observable
                                        this.simpleObservable = new Observable((observer) => {
                                            // observable execution
                                            let bodyDataNew = {
                                                Container: this.selectedTargetContainer,
                                                BlobList: this.selectedDocuments,
                                                MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                                                MoveActionForDependencies: this.moveActionForDependencies,
                                                MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                                            };
                                            observer.next(bodyDataNew);
                                            observer.complete();
                                        });

                                        if (invalidDocs.length > 0) {

                                            this.initInvalidOrMissingInfoDocGrid();
                                            invalidDocs.forEach((element, index) => {
                                                this.docInvalidOrMissingInfoGridData.push(element);
                                            });
                                            this.showInvalidOrMissingInfoDlg = true;
                                        }
                                        else {
                                            this.moveActionForInvalidFiles = "N/A";
                                        }

                                        this._sharedService.clearToast();
                                        this._sharedService.hideLoader();
                                        this.displayCopyFromDlg = false;

                                    }
                                    else {
                                        this.moveActionForInvalidFiles = "N/A";
                                        bodyData.MoveActionForDependencies = this.moveActionForDependencies;
                                        bodyData.MoveActionForInvalidFiles = this.moveActionForInvalidFiles;
                                        bodyData.MoveActionForImageFileToPDF = this.moveActionForImageFileToPDF;
                                        //R.A. this.moveCopyBlobService(bodyData, containerForm);
                                    }

                                    let dependentDocs = [];
                                    this.docDependencies.forEach((element, index) => {
                                        if ((element.HasTasks || element.HasComms) && (!element.HasMissingDataOrInvalidBlob)) {
                                            element.AzureBlobEntity.HasTasks = element.HasTasks;
                                            element.AzureBlobEntity.HasComms = element.HasComms;
                                            dependentDocs.push(element.AzureBlobEntity);
                                        }
                                    });

                                    if (dependentDocs.length > 0 || this.docRefToStatus.length > 0 || this.docRefToInbox.length > 0) {
                                        this._sharedService.showToast({ severity: 'warn', summary: 'Document(s) references found', detail: 'Some document(s) have referenced with Inbox/ Status. Please verify..' });

                                        // create observable
                                        this.simpleObservable = new Observable((observer) => {
                                            // observable execution

                                            let bodyDataNew = {
                                                Container: this.selectedTargetContainer,
                                                BlobList: this.selectedDocuments,
                                                MoveActionForDependencies: this.moveActionForDependencies,
                                                MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                                                MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                                            };
                                            observer.next(bodyDataNew);
                                            observer.complete();
                                        });

                                        if (dependentDocs.length > 0) {

                                            this.initDependentLinkGridDpt();
                                            dependentDocs.forEach((element, index) => {
                                                this.docRefToDependenciesGridData.push(element);
                                            });
                                            this.showDependencyDlg = true;
                                        }
                                        else {
                                            this.moveActionForDependencies = "N/A";
                                        }

                                        this._sharedService.clearToast();
                                        this._sharedService.hideLoader();
                                        this.displayCopyFromDlg = false;

                                    }
                                    else {
                                        this.moveActionForDependencies = "N/A";
                                        bodyData.MoveActionForDependencies = this.moveActionForDependencies;
                                        bodyData.MoveActionForInvalidFiles = this.moveActionForInvalidFiles;
                                        bodyData.MoveActionForImageFileToPDF = this.moveActionForImageFileToPDF;
                                        //R.A. this.moveCopyBlobService(bodyData, containerForm);
                                    }

                                    let imageToPDFDocs = [];
                                    this.docDependencies.forEach((element, index) => {
                                        if (element.IsImage && !element.HasMissingDataOrInvalidBlob) {
                                            element.AzureBlobEntity.IsImage = element.IsImage;
                                            imageToPDFDocs.push(element.AzureBlobEntity);
                                        }
                                    });

                                    if (imageToPDFDocs.length > 0) {
                                        this._sharedService.showToast({ severity: 'warn', summary: 'Image Document(s) found', detail: 'These image document(s) will be converted to PDF. Please verify...' });

                                        // create observable
                                        this.simpleObservable = new Observable((observer) => {
                                            // observable execution
                                            let bodyDataNew = {
                                                Container: this.selectedTargetContainer,
                                                BlobList: this.selectedDocuments,
                                                MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                                                MoveActionForDependencies: this.moveActionForDependencies,
                                                MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                                            };
                                            observer.next(bodyDataNew);
                                            observer.complete();
                                        });



                                        if (imageToPDFDocs.length > 0) {

                                            this.initImageToPDFGrid();
                                            imageToPDFDocs.forEach((element, index) => {
                                                this.docImageToPDFGridData.push(element);
                                            });
                                            this.showImageToPDFDlg = true;
                                        }
                                        else {
                                            this.moveActionForImageFileToPDF = "N/A";
                                        }

                                        this._sharedService.clearToast();

                                        this._sharedService.hideLoader();


                                        this.displayCopyFromDlg = false;

                                    }
                                    else {
                                        this.moveActionForImageFileToPDF = 'N/A';
                                        bodyData.MoveActionForDependencies = this.moveActionForDependencies;
                                        bodyData.MoveActionForInvalidFiles = this.moveActionForInvalidFiles;
                                        bodyData.MoveActionForImageFileToPDF = this.moveActionForImageFileToPDF;
                                        //R.A. this.moveCopyBlobService(bodyData, containerForm);
                                    }


                                    if (invalidDocs.length == 0 && dependentDocs.length == 0 && imageToPDFDocs.length == 0 &&
                                        this.moveActionForInvalidFiles == "N/A" && this.moveActionForDependencies == "N/A" && this.moveActionForImageFileToPDF == 'N/A') {
                                        if (invalidDocs.length < 1) {
                                            if (AlreadyExistsDocs.length > 0) {
                                                if (ValidDocs.length > 0) {
                                                    let bodyDataNew = {
                                                        Container: this.selectedTargetContainer,
                                                        BlobList: ValidDocs,
                                                        MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                                                        MoveActionForDependencies: this.moveActionForDependencies,
                                                        MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                                                    };
                                                    this.moveCopyBlobService(bodyDataNew, containerForm, false);
                                                }

                                                this._sharedService.clearToast();
                                                this._sharedService.hideLoader();
                                                var array = AlreadyExistsDocs;
                                                var count = 1;
                                                var tab = "<h3>Over Write?</h3>"
                                                tab = tab + "<div class='confirmOverflow' ><table class='table'><thead><tr> <th scope='col'>#</th><th scope='col'>File Name</th></tr></thead><tbody>";
                                                array.forEach(element => {
                                                    tab = tab + "<tr><th scope='row'>" + count + "</th><td>" + element.BlobName + "</td></tr>";
                                                    count = count + 1;
                                                });
                                                tab = tab + "</tbody> </table></div>"
                                                this.isDisplayBulkDialog = true;
                                                this._confirmationService.confirm({
                                                    // message: JSON.parse(xhr.responseText),
                                                    message: tab,
                                                    header: 'Duplicate Files!',
                                                    accept: () => {
                                                        this.isDisplayBulkDialog = false;
                                                        let bodyDataNew = {
                                                            Container: this.selectedTargetContainer,
                                                            BlobList: AlreadyExistsDocs,
                                                            MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                                                            MoveActionForDependencies: this.moveActionForDependencies,
                                                            MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                                                        };
                                                        this.moveCopyBlobService(bodyDataNew, containerForm);

                                                    },
                                                    reject: () => {
                                                        this.isDisplayBulkDialog = false;
                                                    }
                                                });

                                            }
                                            else {
                                                this.moveCopyBlobService(bodyData, containerForm);
                                            }
                                        }
                                    }
                                },
                                error: (error) => {
                                    console.log(error);
                                }
                            });

                    }
                    else {
                        var invalidDocs = [];
                        var AlreadyExistsDocs = [];
                        var ValidDocs = [];
                        this._azureBlobService.getDocumentReferences(bodyData)
                            .subscribe({
                                next: (response) => {
                                    this.docInvalidOrMissingInfoGridData = [];
                                    this.docDependencies = response;
                                    //this.docRefToDependencies = this.docDependencies.filter(item => item.Tasks.length > 0 || item.Comms.length > 0);

                                    this.docDependencies.forEach((element, index) => {
                                        if (element.HasMissingDataOrInvalidBlob) {
                                            element.AzureBlobEntity.HasMissingDataOrInvalidBlob = element.HasMissingDataOrInvalidBlob;
                                            invalidDocs.push(element.AzureBlobEntity);
                                        }
                                        if (element.HasAlreadyFile) {
                                            AlreadyExistsDocs.push(element.AzureBlobEntity);
                                        }
                                        else if (!element.HasComms && !element.HasMissingDataOrInvalidBlob && !element.HasTasks && !element.IsImage) {
                                            ValidDocs.push(element.AzureBlobEntity);
                                        }
                                    })
                                    if (invalidDocs.length > 0) {
                                        this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Document(s) references found', detail: 'Some document(s) are invalid or have missing information. Please verify..' });

                                        // create observable
                                        this.simpleObservable = new Observable((observer) => {
                                            // observable execution
                                            let bodyDataNew = {
                                                Container: this.selectedTargetContainer,
                                                BlobList: this.selectedDocuments,
                                                MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                                                MoveActionForDependencies: this.moveActionForDependencies,
                                                MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                                            };
                                            observer.next(bodyDataNew);
                                            observer.complete();
                                        });

                                        if (invalidDocs.length > 0) {

                                            this.initInvalidOrMissingInfoDocGrid();
                                            invalidDocs.forEach((element, index) => {
                                                this.docInvalidOrMissingInfoGridData.push(element);
                                            });
                                            this.showInvalidOrMissingInfoDlg = true;
                                        }
                                        else {
                                            this.moveActionForInvalidFiles = "N/A";
                                        }

                                        this._sharedService.clearToast();
                                        this._sharedService.hideLoader();
                                        this.displayCopyFromDlg = false;
                                    }


                                    this.moveActionForImageFileToPDF = 'N/A';
                                    this.moveActionForDependencies = "N/A";
                                    this.moveActionForInvalidFiles = "N/A";
                                    bodyData.MoveActionForDependencies = this.moveActionForDependencies;
                                    bodyData.MoveActionForInvalidFiles = this.moveActionForInvalidFiles;
                                    bodyData.MoveActionForImageFileToPDF = this.moveActionForImageFileToPDF;
                                    if (invalidDocs.length < 1) {
                                        if (AlreadyExistsDocs.length > 0) {
                                            if (ValidDocs.length > 0) {
                                                let bodyDataNew = {
                                                    Container: this.selectedTargetContainer,
                                                    BlobList: ValidDocs,
                                                    MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                                                    MoveActionForDependencies: this.moveActionForDependencies,
                                                    MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                                                    PageRange: null
                                                };
                                                if (this.pageRange != null) {
                                                    bodyDataNew.PageRange = this.pageRange
                                                }
                                                this.moveCopyBlobService(bodyDataNew, containerForm, false);
                                            }

                                            this._sharedService.clearToast();
                                            this._sharedService.hideLoader();
                                            var array = AlreadyExistsDocs;
                                            var count = 1;
                                            var tab = "<h3>Over Write?</h3>"
                                            tab = tab + "<div class='confirmOverflow' ><table class='table'><thead><tr> <th scope='col'>#</th><th scope='col'>File Name</th></tr></thead><tbody>";
                                            array.forEach(element => {
                                                tab = tab + "<tr><th scope='row'>" + count + "</th><td>" + element.BlobName + "</td></tr>";
                                                count = count + 1;
                                            });
                                            tab = tab + "</tbody> </table></div>"
                                            this.isDisplayBulkDialog = true;
                                            this._confirmationService.confirm({
                                                // message: JSON.parse(xhr.responseText),
                                                message: tab,
                                                header: 'Duplicate Files!',
                                                accept: () => {
                                                    this.isDisplayBulkDialog = false;
                                                    let bodyDataNew = {
                                                        Container: this.selectedTargetContainer,
                                                        BlobList: AlreadyExistsDocs,
                                                        MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                                                        MoveActionForDependencies: this.moveActionForDependencies,
                                                        MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                                                        PageRange: null
                                                    };
                                                    if (this.pageRange != null) {
                                                        bodyDataNew.PageRange = this.pageRange
                                                    }
                                                    this.moveCopyBlobService(bodyDataNew, containerForm);

                                                },
                                                reject: () => {
                                                    this.isDisplayBulkDialog = false;
                                                }
                                            });

                                        }
                                        else {
                                            this.moveActionForImageFileToPDF = 'N/A';
                                            this.moveActionForDependencies = "N/A";
                                            this.moveActionForInvalidFiles = "N/A";
                                            bodyData.MoveActionForDependencies = this.moveActionForDependencies;
                                            bodyData.MoveActionForInvalidFiles = this.moveActionForInvalidFiles;
                                            bodyData.MoveActionForImageFileToPDF = this.moveActionForImageFileToPDF;
                                            if (this.pageRange != null) {
                                                bodyData.PageRange = this.pageRange
                                            }

                                            this.moveCopyBlobService(bodyData, containerForm);
                                        }
                                    }
                                },
                                error: (error) => {
                                }
                            });
                    }
                }
                else if (selDocuments.length >= this.batchSize) ///NOTE: Bilal 5 is the documents count which is minimum number of documents to start batch process
                {
                    this.selectedTargetContainer = selContainer[0];
                    this.batchBlobList = selDocuments.map(a => a.BlobName)
                    this.btnOpenDocumentsBatchDialog()
                }
            } else {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'No container selected', detail: 'Select a container to Copy/Move document(s)' });
            }
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({
                severity: 'warn', summary: 'No document(s) selected', detail: 'Select document(s) to Copy/Move'
            });
        }
    }

    btnMoveCopyDocumentClick(isCopy: boolean, containerForm: NgForm) {
        this.isFrom = false;
        this.isCopy = isCopy;
        //US-8181 //if (this.containerModel.asn.split('|')[4] == 'True')
        if (JSON.parse(this.containerModel.asn).ReadOnly) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Readonly Asset', detail: 'Cannot Move/Copy documnet(s) to readonly asset.' });
            return;
        }
        this._sharedService.showLoader("Processing...");
        let selContainer = this.conGridOptions.api.getSelectedRows();
        if (selContainer.length > 0) {
            //set Target ContainerName and IsCopy Flag
            //US-8181 //selContainer[0].ContainerName = this.containerModel.asn.split('|')[0];
            selContainer[0].ContainerName = JSON.parse(this.containerModel.asn).StoragePath;
            selContainer[0].isCopy = isCopy;
            this.selectedTargetContainer = selContainer[0];

            let selDocuments = this.gridOptions.api.getSelectedRows();
            this.selectedBatchSize = selDocuments.length;
            if (selDocuments.length < this.batchSize) 
            {
                //Set Source Container Name, Type, ID, ASN and save File Name in Url field
                for (let i = 0; i < selDocuments.length; i++) {
                    selDocuments[i].ContainerName = this.selectedContainer.ContainerName;
                    selDocuments[i].Type = this.selectedContainer.Type;
                    selDocuments[i].ID = this.selectedContainer.ID;
                    selDocuments[i].ASN = this.selectedContainer.ASN;
                    //selDocuments[i].Url = this._sharedService.getFileNameFromURL(selDocuments[i].Url, true); // this.getFileName(selDocuments[i].Url);
                    selDocuments[i].Url = selDocuments[i].Url;
                    //if(selDocuments[i].VideoUrl!=null){
                    //    selDocuments[i].BlobName =selDocuments[i].VideoUrl;
                    // }
                    //  else{
                    selDocuments[i].BlobName = this._sharedService.getFileNameFromURL(selDocuments[i].Url, true);

                }

                this.selectedDocuments = selDocuments;

                let bodyData = {
                    Container: selContainer[0],
                    BlobList: selDocuments,
                    // MoveActionForDocStatus: this.moveActionForDocStatus,
                    // MoveActionForDocInbox: this.moveActionForDocInbox,
                    MoveActionForDependencies: this.moveActionForDependencies,
                    MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                    MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                    PageRange: null
                };
                if (!isCopy)//move(true)//
                {
                    var AlreadyExistsDocs = [];
                    var ValidDocs = [];
                    // get document dependencies by communications & statuses
                    this._azureBlobService.getDocumentReferences(bodyData)
                        .subscribe({
                            next: (response) => {
                                this.docRefToInbox = [];
                                this.docRefToStatus = [];

                                this.docRefToInboxGridData = [];
                                this.docRefToStatusGridData = [];
                                // this.moveActionForDocStatus = '';
                                // this.moveActionForDocInbox = '';

                                this.docRefToDependencies = [];
                                this.docRefToDependenciesGridData = [];
                                this.docInvalidOrMissingInfoGridData = [];
                                this.docImageToPDFGridData = [];
                                this.moveActionForDependencies = '';
                                this.moveActionForInvalidFiles = '';
                                this.moveActionForImageFileToPDF = '';


                                this.docDependencies = response;
                                this.docRefToInbox = this.docDependencies.filter(item => item.Comms.length > 0);
                                this.docRefToStatus = this.docDependencies.filter(item => item.Tasks.length > 0);
                                //this.docRefToDependencies = this.docDependencies.filter(item => item.Tasks.length > 0 || item.Comms.length > 0);
                                let invalidDocs = [];
                                this.docDependencies.forEach((element, index) => {
                                    if (element.HasMissingDataOrInvalidBlob) {
                                        element.AzureBlobEntity.HasMissingDataOrInvalidBlob = element.HasMissingDataOrInvalidBlob;
                                        invalidDocs.push(element.AzureBlobEntity);

                                    }
                                    if (element.HasAlreadyFile) {
                                        AlreadyExistsDocs.push(element.AzureBlobEntity);
                                    }
                                    else if (!element.HasComms && !element.HasMissingDataOrInvalidBlob && !element.HasTasks && !element.IsImage) {
                                        ValidDocs.push(element.AzureBlobEntity);
                                    }
                                });

                                if (invalidDocs.length > 0) {
                                    this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Document(s) references found', detail: 'Some document(s) are invalid or have missing information. Please verify..' });

                                    // create observable
                                    this.simpleObservable = new Observable((observer) => {
                                        // observable execution
                                        let bodyDataNew = {
                                            Container: this.selectedTargetContainer,
                                            BlobList: this.selectedDocuments,
                                            MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                                            MoveActionForDependencies: this.moveActionForDependencies,
                                            MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                                        };
                                        observer.next(bodyDataNew);
                                        observer.complete();
                                    });

                                    if (invalidDocs.length > 0) {

                                        this.initInvalidOrMissingInfoDocGrid();
                                        invalidDocs.forEach((element, index) => {
                                            this.docInvalidOrMissingInfoGridData.push(element);
                                        });
                                        this.showInvalidOrMissingInfoDlg = true;
                                    }
                                    else {
                                        this.moveActionForInvalidFiles = "N/A";
                                    }

                                    this._sharedService.clearToast();
                                    this._sharedService.hideLoader();
                                    this.displayMoveCopyDlg = false;
                                }
                                else {
                                    this.moveActionForInvalidFiles = "N/A";
                                    bodyData.MoveActionForDependencies = this.moveActionForDependencies;
                                    bodyData.MoveActionForInvalidFiles = this.moveActionForInvalidFiles;
                                    bodyData.MoveActionForImageFileToPDF = this.moveActionForImageFileToPDF;
                                    //R.A. this.moveCopyBlobService(bodyData, containerForm);
                                }

                                let dependentDocs = [];
                                this.docDependencies.forEach((element, index) => {
                                    if ((element.HasTasks || element.HasComms) && (!element.HasMissingDataOrInvalidBlob)) {
                                        element.AzureBlobEntity.HasTasks = element.HasTasks;
                                        element.AzureBlobEntity.HasComms = element.HasComms;
                                        dependentDocs.push(element.AzureBlobEntity);
                                    }
                                });

                                if (dependentDocs.length > 0 || this.docRefToStatus.length > 0 || this.docRefToInbox.length > 0) {
                                    this._sharedService.showToast({ severity: 'warn', summary: 'Document(s) references found', detail: 'Some document(s) have referenced with Inbox/ Status. Please verify..' });

                                    // create observable
                                    this.simpleObservable = new Observable((observer) => {
                                        // observable execution

                                        let bodyDataNew = {
                                            Container: this.selectedTargetContainer,
                                            BlobList: this.selectedDocuments,
                                            MoveActionForDependencies: this.moveActionForDependencies,
                                            MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                                            MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                                        };
                                        observer.next(bodyDataNew);
                                        observer.complete();
                                    });

                                    if (dependentDocs.length > 0) {

                                        this.initDependentLinkGridDpt();
                                        dependentDocs.forEach((element, index) => {
                                            this.docRefToDependenciesGridData.push(element);
                                        });
                                        this.showDependencyDlg = true;
                                    }
                                    else {
                                        this.moveActionForDependencies = "N/A";
                                    }

                                    this._sharedService.clearToast();
                                    this._sharedService.hideLoader();
                                    this.displayMoveCopyDlg = false;

                                }
                                else {
                                    this.moveActionForDependencies = "N/A";
                                    bodyData.MoveActionForDependencies = this.moveActionForDependencies;
                                    bodyData.MoveActionForInvalidFiles = this.moveActionForInvalidFiles;
                                    bodyData.MoveActionForImageFileToPDF = this.moveActionForImageFileToPDF;
                                    //R.A. this.moveCopyBlobService(bodyData, containerForm);
                                }

                                let imageToPDFDocs = [];
                                this.docDependencies.forEach((element, index) => {
                                    if (element.IsImage && !element.HasMissingDataOrInvalidBlob) {
                                        element.AzureBlobEntity.IsImage = element.IsImage;
                                        imageToPDFDocs.push(element.AzureBlobEntity);
                                    }
                                });

                                if (imageToPDFDocs.length > 0) {
                                    this._sharedService.showToast({ severity: 'warn', summary: 'Image Document(s) found', detail: 'These image document(s) will be converted to PDF. Please verify...' });

                                    // create observable
                                    this.simpleObservable = new Observable((observer) => {
                                        // observable execution
                                        let bodyDataNew = {
                                            Container: this.selectedTargetContainer,
                                            BlobList: this.selectedDocuments,
                                            MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                                            MoveActionForDependencies: this.moveActionForDependencies,
                                            MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                                        };
                                        observer.next(bodyDataNew);
                                        observer.complete();
                                    });



                                    if (imageToPDFDocs.length > 0) {

                                        this.initImageToPDFGrid();
                                        imageToPDFDocs.forEach((element, index) => {
                                            this.docImageToPDFGridData.push(element);
                                        });
                                        this.showImageToPDFDlg = true;
                                    }
                                    else {
                                        this.moveActionForImageFileToPDF = "N/A";
                                    }

                                    this._sharedService.clearToast();

                                    this._sharedService.hideLoader();


                                    this.displayMoveCopyDlg = false;

                                }
                                else {
                                    this.moveActionForImageFileToPDF = 'N/A';
                                    bodyData.MoveActionForDependencies = this.moveActionForDependencies;
                                    bodyData.MoveActionForInvalidFiles = this.moveActionForInvalidFiles;
                                    bodyData.MoveActionForImageFileToPDF = this.moveActionForImageFileToPDF;
                                    //R.A. this.moveCopyBlobService(bodyData, containerForm);
                                }


                                if (invalidDocs.length == 0 && dependentDocs.length == 0 && imageToPDFDocs.length == 0 &&
                                    this.moveActionForInvalidFiles == "N/A" && this.moveActionForDependencies == "N/A" && this.moveActionForImageFileToPDF == 'N/A') {
                                    if (invalidDocs.length < 1) {
                                        if (AlreadyExistsDocs.length > 0) {
                                            if (ValidDocs.length > 0) {
                                                let bodyDataNew = {
                                                    Container: this.selectedTargetContainer,
                                                    BlobList: ValidDocs,
                                                    MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                                                    MoveActionForDependencies: this.moveActionForDependencies,
                                                    MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                                                };
                                                this.moveCopyBlobService(bodyDataNew, containerForm, false);
                                            }

                                            this._sharedService.clearToast();
                                            this._sharedService.hideLoader();
                                            var array = AlreadyExistsDocs;
                                            var count = 1;
                                            var tab = "<h3>Over Write?</h3>"
                                            tab = tab + "<div class='confirmOverflow' ><table class='table'><thead><tr> <th scope='col'>#</th><th scope='col'>File Name</th></tr></thead><tbody>";
                                            array.forEach(element => {
                                                tab = tab + "<tr><th scope='row'>" + count + "</th><td>" + element.BlobName + "</td></tr>";
                                                count = count + 1;
                                            });
                                            tab = tab + "</tbody> </table></div>"
                                            this.isDisplayBulkDialog = true;
                                            this._confirmationService.confirm({
                                                // message: JSON.parse(xhr.responseText),
                                                message: tab,
                                                header: 'Duplicate Files!',
                                                accept: () => {
                                                    this.isDisplayBulkDialog = false;
                                                    let bodyDataNew = {
                                                        Container: this.selectedTargetContainer,
                                                        BlobList: AlreadyExistsDocs,
                                                        MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                                                        MoveActionForDependencies: this.moveActionForDependencies,
                                                        MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                                                    };
                                                    this.moveCopyBlobService(bodyDataNew, containerForm);

                                                },
                                                reject: () => {
                                                    this.isDisplayBulkDialog = false;
                                                }
                                            });

                                        }
                                        else {
                                            this.moveCopyBlobService(bodyData, containerForm);
                                        }
                                    }
                                }
                            },
                            error: (error) => {
                                console.log(error);
                            }
                        });

                }
                else {
                    var invalidDocs = [];
                    var AlreadyExistsDocs = [];
                    var ValidDocs = [];
                    this._azureBlobService.getDocumentReferences(bodyData)
                        .subscribe({
                            next: (response) => {
                                this.docInvalidOrMissingInfoGridData = [];
                                this.docDependencies = response;
                                //this.docRefToDependencies = this.docDependencies.filter(item => item.Tasks.length > 0 || item.Comms.length > 0);

                                this.docDependencies.forEach((element, index) => {
                                    if (element.HasMissingDataOrInvalidBlob) {
                                        element.AzureBlobEntity.HasMissingDataOrInvalidBlob = element.HasMissingDataOrInvalidBlob;
                                        invalidDocs.push(element.AzureBlobEntity);
                                    }
                                    if (element.HasAlreadyFile) {
                                        AlreadyExistsDocs.push(element.AzureBlobEntity);
                                    }
                                    else if (!element.HasComms && !element.HasMissingDataOrInvalidBlob && !element.HasTasks && !element.IsImage) {
                                        ValidDocs.push(element.AzureBlobEntity);
                                    }
                                })
                                if (invalidDocs.length > 0) {
                                    this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Document(s) references found', detail: 'Some document(s) are invalid or have missing information. Please verify..' });

                                    // create observable
                                    this.simpleObservable = new Observable((observer) => {
                                        // observable execution
                                        let bodyDataNew = {
                                            Container: this.selectedTargetContainer,
                                            BlobList: this.selectedDocuments,
                                            MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                                            MoveActionForDependencies: this.moveActionForDependencies,
                                            MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                                        };
                                        observer.next(bodyDataNew);
                                        observer.complete();
                                    });

                                    if (invalidDocs.length > 0) {

                                        this.initInvalidOrMissingInfoDocGrid();
                                        invalidDocs.forEach((element, index) => {
                                            this.docInvalidOrMissingInfoGridData.push(element);
                                        });
                                        this.showInvalidOrMissingInfoDlg = true;
                                    }
                                    else {
                                        this.moveActionForInvalidFiles = "N/A";
                                    }

                                    this._sharedService.clearToast();
                                    this._sharedService.hideLoader();
                                    this.displayMoveCopyDlg = false;
                                }


                                this.moveActionForImageFileToPDF = 'N/A';
                                this.moveActionForDependencies = "N/A";
                                this.moveActionForInvalidFiles = "N/A";
                                bodyData.MoveActionForDependencies = this.moveActionForDependencies;
                                bodyData.MoveActionForInvalidFiles = this.moveActionForInvalidFiles;
                                bodyData.MoveActionForImageFileToPDF = this.moveActionForImageFileToPDF;
                                if (invalidDocs.length < 1) {
                                    if (AlreadyExistsDocs.length > 0) {
                                        if (ValidDocs.length > 0) {
                                            let bodyDataNew = {
                                                Container: this.selectedTargetContainer,
                                                BlobList: ValidDocs,
                                                MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                                                MoveActionForDependencies: this.moveActionForDependencies,
                                                MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                                                PageRange: null
                                            };
                                            if (this.pageRange != null) {
                                                bodyDataNew.PageRange = this.pageRange
                                            }
                                            this.moveCopyBlobService(bodyDataNew, containerForm, false);
                                        }

                                        this._sharedService.clearToast();
                                        this._sharedService.hideLoader();
                                        var array = AlreadyExistsDocs;
                                        var count = 1;
                                        var tab = "<h3>Over Write?</h3>"
                                        tab = tab + "<div class='confirmOverflow' ><table class='table'><thead><tr> <th scope='col'>#</th><th scope='col'>File Name</th></tr></thead><tbody>";
                                        array.forEach(element => {
                                            tab = tab + "<tr><th scope='row'>" + count + "</th><td>" + element.BlobName + "</td></tr>";
                                            count = count + 1;
                                        });
                                        tab = tab + "</tbody> </table></div>"
                                        this.isDisplayBulkDialog = true;
                                        this._confirmationService.confirm({
                                            // message: JSON.parse(xhr.responseText),
                                            message: tab,
                                            header: 'Duplicate Files!',
                                            accept: () => {
                                                this.isDisplayBulkDialog = false;
                                                let bodyDataNew = {
                                                    Container: this.selectedTargetContainer,
                                                    BlobList: AlreadyExistsDocs,
                                                    MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                                                    MoveActionForDependencies: this.moveActionForDependencies,
                                                    MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                                                    PageRange: null
                                                };
                                                if (this.pageRange != null) {
                                                    bodyDataNew.PageRange = this.pageRange
                                                }
                                                this.moveCopyBlobService(bodyDataNew, containerForm);

                                            },
                                            reject: () => {
                                                this.isDisplayBulkDialog = false;
                                            }
                                        });

                                    }
                                    else {
                                        this.moveActionForImageFileToPDF = 'N/A';
                                        this.moveActionForDependencies = "N/A";
                                        this.moveActionForInvalidFiles = "N/A";
                                        bodyData.MoveActionForDependencies = this.moveActionForDependencies;
                                        bodyData.MoveActionForInvalidFiles = this.moveActionForInvalidFiles;
                                        bodyData.MoveActionForImageFileToPDF = this.moveActionForImageFileToPDF;
                                        if (this.pageRange != null) {
                                            bodyData.PageRange = this.pageRange
                                        }

                                        this.moveCopyBlobService(bodyData, containerForm);
                                    }
                                }
                            },
                            error: (error) => {
                            }
                        });
                }

                // if (isCopy) {
                //     ////R.A. this.moveCopyBlobService(bodyData, containerForm);
                // }
            }
            else if (selDocuments.length >= this.batchSize) ///NOTE: Bilal 5 is the documents count which is minimum number of documents to start batch process
            {
                this.batchBlobList = selDocuments.map(a => a.BlobName)
                this.btnOpenDocumentsBatchDialog()
            }
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No container selected', detail: 'Select a container to Copy/Move document(s)' });
        }
    }


    moveBlobServiceCheck(bodyData: any) {
        if (bodyData.MoveActionForDependencies != "" && bodyData.MoveActionForInvalidFiles != "" && bodyData.MoveActionForImageFileToPDF != "") {
            this.moveCopyBlobService(bodyData, null);
        }

    }
    moveCopyBlobService(bodyData: any, containerForm: any, ShowAlert: boolean = true) {
        this._sharedService.showLoader("Processing...");
        if (this.pageRange != null && bodyData.PageRange == null) {
            bodyData.PageRange = this.pageRange;
        }
        this._azureBlobService.moveCopyBlob(bodyData)
            .subscribe({
                next: (response) => {
                    this._sharedService.clearToast();
                    this._sharedService.hideLoader();

                    this.refreshDocGrid();

                    if (ShowAlert) {
                        this.displayMoveCopyDlg = false;
                        this.displayCopyFromDlg = false;
                    }
                    if (containerForm != null) {
                        containerForm.resetForm();
                    }
                    if (ShowAlert) {
                        this._sharedService.showToast({ severity: 'success', summary: 'Document(s) moved/copied', detail: 'Document(s) have been moved/copied successfully.' });
                    }
                    this.pageRange = null;
                },
                error: (error) => {
                    if (error.status == 409) {
                        this._sharedService.clearToast();
                        //let resp = response;
                        this._sharedService.hideLoader();
                        //  this.refreshDocGrid();
                        if (this.containerModel.category == 'DOC' || this.containerModel.category == 'BND') {
                            this._sharedService.showToast({ severity: 'success', summary: 'Document(s) moved/copied', detail: 'Document(s) have been moved/copied successfully.' });
                        }
                        else {
                            //commented due to pre-action choosen 
                            //this._sharedService.showToast({ severity: 'warn', summary: 'Some document(s) not moved', detail: 'Document(s) are attached to status row(s), please detach them before moving..' });
                            this._sharedService.showToast({ severity: 'success', summary: 'Document(s) moved/copied', detail: 'Document(s) have been moved/copied successfully.' });
                        }
                        this.displayMoveCopyDlg = false;
                        this.displayCopyFromDlg = false;
                        //containerForm.resetForm();
                        if (containerForm != null) {
                            containerForm.resetForm();
                        }
                        this.refreshDocGrid();
                    }
                    else if (error.status == 501) {
                        this._sharedService.clearToast();
                        this._sharedService.hideLoader();
                        this.refreshDocGrid();
                        this._sharedService.showToast({ severity: 'warn', summary: 'Unable to update document URL', detail: error.error });
                        this.displayMoveCopyDlg = false;
                        this.displayCopyFromDlg = false;
                        if (containerForm != null) {
                            containerForm.resetForm();
                        }
                    }
                    else if (error.status == 412) {
                        this._sharedService.clearToast();
                        this._sharedService.hideLoader();
                        this.refreshDocGrid();
                        this._sharedService.showToast({ severity: 'warn', summary: 'Some document(s) not moved', detail: error.error });
                        this.displayMoveCopyDlg = false;
                        this.displayCopyFromDlg = false;
                        if (containerForm != null) {
                            containerForm.resetForm();
                        }
                    }
                    else if (error.status == 500) {
                        this._sharedService.hideLoader();
                        this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Page Range', detail: 'Some document(s) have invalid page range' });
                    }
                    else {
                        this._sharedService.hideLoader();
                        this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while moving/coping document(s).' });
                    }
                    this.pageRange = null;
                }
            });
    }


    //     } else {
    //         this._sharedService.clearToast();
    //         this._sharedService.showToast({ severity: 'warn', summary: 'No container selected', detail: 'Select a container to Copy/Move document(s)' });
    //     }
    // }
    docGridRowDoubleClicked(event: any) {
        if (this._displayMode && this._displayMode != 'status') {

            this._sharedService.updateEditMetadata(null);
        }
    }
    setContainerGridColumns(): void {
        var conCat = this.selectedComboCatergorySelection.toUpperCase();
        this.conColumnDefs = [];
        conColumnDefs_1 = [];
        var containerGridCols: any[];
        var container_Col_Sort_On_By: string = '';

        if (conCat && conCat == 'MXR') {
            this.conColumnDefs = [];
            conColumnDefs_1 = [];
            containerGridCols = this.Container_MXR_Columns;
            container_Col_Sort_On_By = this.Container_MXR_Sort_By;
        }
        else if (conCat && conCat == 'LOG') {
            this.conColumnDefs = [];
            conColumnDefs_1 = [];
            containerGridCols = this.Container_LOG_Columns;
            container_Col_Sort_On_By = this.Container_LOG_Sort_By;
        }
        else if (conCat && conCat == 'GEN') {

            this.conColumnDefs = [];
            conColumnDefs_1 = [];
            containerGridCols = this.Container_GEN_Columns;
            container_Col_Sort_On_By = this.Container_GEN_Sort_By;
        }
        else if (conCat && conCat == 'DOC') {
            this.conColumnDefs = [];
            conColumnDefs_1 = [];
            containerGridCols = this.Container_DOC_Columns;
            container_Col_Sort_On_By = this.Container_DOC_Sort_By;
        }
        else if (conCat && conCat == 'BND') {
            this.conColumnDefs = [];
            conColumnDefs_1 = [];
            containerGridCols = this.Container_BND_Columns;
            container_Col_Sort_On_By = this.Container_BND_Sort_By;
        }

        containerGridCols.forEach((col, index) => {

            let _filter = 'set';

            if (col.FieldName == 'Date' || col.FieldName == 'EndDate' || col.FieldName == 'ScannedOn') {
                _filter = 'agDateColumnFilter';
            }
            let gridCol: any;
            if (col.FieldName == 'ContainerQualityStatus') {
                gridCol = {
                    headerName: col.ColumnLabel,
                    field: 'QualityStatus',//'QualityStatus---ID',//24-02-2021
                    width: col.Width,
                    filter: _filter,
                    tooltipField: col.FieldName,
                    menuTabs: ['filterMenuTab'],
                    hide: !col.IsVisible,
                    filterParams: this.containerGridStatusFilterParams,
                };
            }
            else {
                gridCol = {
                    headerName: col.ColumnLabel,
                    field: col.FieldName,
                    width: col.Width,
                    filter: _filter,
                    tooltipField: col.FieldName,
                    menuTabs: ['filterMenuTab'],
                    hide: !col.IsVisible
                };
            }


            if (col.FieldName == 'Date' || col.FieldName == 'EndDate' || col.FieldName == 'ScannedOn') {
                gridCol.filterParams =
                {
                    comparator: this.dateComparater,
                    inRangeInclusive: 'true'
                }

                gridCol.valueFormatter = function (params: any) {
                    if (params.value != '' && params.value != null) {

                        let dateString = params.value.substring(0, 10);
                        return moment(dateString).format(dateFormatF.toUpperCase());
                    }
                }
            }
            else if (col.FieldName == 'ContainerQualityStatus') {
                gridCol.filterParams = {
                    cellRenderer: this.containerStatusCellRenderer, // formats filter values
                }
                gridCol.cellRenderer = this.containerStatusCellRenderer;
            }
            // if (col.FieldName != 'ContainerQualityStatus') {//Temporary check
            conColumnDefs_1.push(gridCol);
            // }

        });

        this.conColumnDefs = conColumnDefs_1;

        let sortOnCol = container_Col_Sort_On_By.split("||")[0];
        let orderBy = container_Col_Sort_On_By.split("||")[1];
        if (sortOnCol == "ContainerQualityStatus") {
            sortOnCol = "QualityStatus";
        }
        this.conColumnDefs.forEach(function (col, index) {
            if (col.field == sortOnCol) {
                this.conColumnDefs[index].sort = orderBy;
            }
        }.bind(this));

    }

    setContainerGridColumns_BackUp(): void {
        this.conColumnDefs = [];

        this.conColumnDefs =
            [
                {
                    headerName: this._sharedService.getContainerLabel(this.selectedComboCatergorySelection, 'Type'), field: 'Type', filter: 'set', tooltipField: 'Description', menuTabs: ['filterMenuTab'], minWidth: 70,
                    //, filterParams: { comparator: this.ContainerTypeComparater }
                },
                {
                    headerName: this._sharedService.getContainerLabel(this.selectedComboCatergorySelection, 'Date'), field: 'Date', filter: 'agDateColumnFilter', filterParams:
                    {
                        comparator: this.dateComparater,
                        inRangeInclusive: 'true'
                    }, valueFormatter: function (params: any) {
                        if (params.value != "" && params.value != null) {

                            let dateString = params.value.substring(0, 10);
                            return moment(dateString).format(dateFormatF.toUpperCase());
                        }
                    }, sort: 'desc', menuTabs: ['filterMenuTab'], minWidth: 90,
                },
                {
                    headerName: this._sharedService.getContainerLabel(this.selectedComboCatergorySelection, 'EndDate'), field: 'EndDate', filter: 'agDateColumnFilter', filterParams: {
                        comparator: this.dateComparater,
                        inRangeInclusive: 'true'

                    }, valueFormatter: function (params: any) {
                        if (params.value != "" && params.value != null) {
                            let dateString = params.value.substring(0, 10);
                            return moment(dateString).format(dateFormatF.toUpperCase());
                        }
                    }, menuTabs: ['filterMenuTab'], minWidth: 90,
                },
            ];
        if (this.selectedComboCatergorySelection == 'MXR') {
            this.conColumnDefs.push({ headerName: this._sharedService.getContainerLabel(this.selectedComboCatergorySelection, 'ReferenceKey'), field: 'ReferenceKey', tooltipField: 'ReferenceKey', menuTabs: ['filterMenuTab'], minWidth: 90, filter: 'set' });
        } else if (this.selectedComboCatergorySelection == 'LOG') {
            this.conColumnDefs.push({ headerName: this._sharedService.getContainerLabel(this.selectedComboCatergorySelection, 'Description'), field: 'Description', tooltipField: 'Description', menuTabs: ['filterMenuTab'], minWidth: 110, filter: 'set' });
        } else if (this.selectedComboCatergorySelection == 'GEN') {
            this.conColumnDefs.push({ headerName: this._sharedService.getContainerLabel(this.selectedComboCatergorySelection, 'ReferenceKey'), field: 'ReferenceKey', tooltipField: 'ReferenceKey', menuTabs: ['filterMenuTab'], minWidth: 90, filter: 'set' });
        }
    }

    formatContainerGrid(event: any) {
        //this.conGridOptions.api.sizeColumnsToFit();
    }
    docCopyFromGridData: any[] = [];
    docCopyFromSelectedRows
    copyToContainer
    isDocCopyFromSelectedRows: boolean = false;
    docCopyFromRowClick() {
        this.docCopyFromSelectedRows = _docCopyFromGridOptions.api.getSelectedRows()
        if (this.docCopyFromSelectedRows.length > 0) {
            this.isDocCopyFromSelectedRows = true;
        }
        else {
            this.isDocCopyFromSelectedRows = false;
        }
    }
    onContainerRowClick(event: any) {
        this.conRowSelected = true;
        var selContainer: container = this.conGridOptions.api.getSelectedRows()[0];
        // this.searchQuery = null;
        // this.isBlankSearchQuery = false;
        // if (!this.isDragable) {
        //     this.gridOptions.api.setSuppressRowDrag(true);
        //     this.iconcolor = "black";

        // }

        if (selContainer && selContainer.ASN) {

            selContainer.IsDirectory = this.directoryResult;

            //US-8181 //selContainer.ContainerName = this._sharedService.selectedAsset[0].data.split('|')[0];
            // selContainer.ContainerName = JSON.parse(this._sharedService.selectedAsset[0].data).StoragePath;
            selContainer.ContainerName = this.selectedASNForSourceCon.StoragePath;
            // this._sharedService.showLoader("Processing...");

            this._azureBlobService.getContainerData(selContainer)
                .subscribe({
                    next: (response) => {
                        if (this._sharedService.isStatusAttachDocDialogueOpen) {
                            this.setDocumentGridColumns();
                        }
                        this.docCopyFromGridData = response.Documents;
                    },
                    error: (error) => {
                        this._sharedService.hideLoader();
                        this.docCopyFromGridData = [];
                    },
                    complete() {
                        //console.log('Completed!!!');

                        this._sharedService.hideLoader();
                    },
                });

        } else {
            this._sharedService.hideLoader();
            this.docGridData = [];
            this.totalCount = 0;
        }
        this.directoryResult = false;
    }

    changeSelectedDoc() {
        let rowNodes = _docCopyFromGridOptions.api.getSelectedRows()
        if (rowNodes.length > 0) {
            this.isDocCopyFromSelectedRows = true;
            let selDoc = rowNodes[rowNodes.length - 1];
            if (selDoc.Url && selDoc.Url.indexOf('api/Blob/GetDocument/') == -1) {
                let body = { Url: selDoc.Url }
                this._azureBlobService.getDocumentURLWithSASToken(body)
                    .subscribe({
                        next: (res) => {
                            selDoc.Url = res;
                            window.open(selDoc.Url, '_blank');
                        },
                        error: (err) => {
                            console.log(err);
                        }
                    });
            }
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Document', detail: 'There is no  document to view' });
        }
    }
    //#endregion

    //#region Download PDF

    validatePdfRequest() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }

        if (this.gridOptions.rowData.length > 0) {
            let selectedRows = this.gridOptions.api.getSelectedRows();
            if (selectedRows.length == 0) {
                this._confirmationService.confirm({
                    message: 'Do you want to download all documents?',
                    header: 'Full grid download alert!',
                    icon: 'fa fa-cloud-download',
                    accept: () => {
                        this.downloadZipPdf(true);
                    },
                    reject: () => {
                    }
                });

            } else if (selectedRows.length > 0) {
                this.downloadZipPdf();
            }
        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Document', detail: 'There is no  document to download' });
        }
    }

    downloadZipPdf(isDownloadFullGrid: boolean = false) {
        this._sharedService.showLoader("Processing...");
        let selectedRows: any[] = [];
        if (!isDownloadFullGrid) {
            selectedRows = this.gridOptions.api.getSelectedRows();
        } else {
            selectedRows = this.gridOptions.rowData;
        }

        let blobList = this.getBlobList(selectedRows);

        this._azureBlobService.downloadBlob(blobList)
            .subscribe({
                next: (response) => {
                    this._sharedService.hideLoader();
                    let blob = new Blob([response], { type: 'application/zip' });
                    let url = URL.createObjectURL(blob);
                    //“ASN + “-“ Container.Category+ “-“ + Container.ID+ “-PDF.Zip”
                    let zipFileName = `${selectedRows[0].ASN}-${this._sharedService.selectedContainer.Category}-${this._sharedService.selectedContainer.ID}-PDF`;
                    this.formatZipFileName(url, zipFileName);
                },
                error: (error) => {
                    console.log(error);
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while downloading document(s).' });
                }
            });

    }

    formatZipFileName(fileURL: string, fileName: string) {
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = fileURL;
        a.target = '_blank';
        a.download = fileName;
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
    }


    //#endregion

    //#region Wide / Full Screen

    expandRecordPanal() {
        let containerPanel = document.getElementById('col1');
        let docPanel = document.getElementById('col2');
        let pdfPanel = document.getElementById('col3');
        let btnFullScreen = document.getElementById('btnRecordFullScreen');
        let btnWideScreen = document.getElementById('btnRecordWideScreen');


        this.gridOptions.columnApi.applyColumnState({
            defaultState: { sort: null },
        });
        this.columnDefs.forEach(function (col, index) {
            this.columnDefs[index].sort = false;
        }
            .bind(this));

        if (docPanel.classList.contains('col-sm-3')) {
            //Remove old Classes
            docPanel.classList.remove('col-sm-3', 'col-md-3', 'col-lg-3');
            //Add new Classes
            docPanel.classList.add('col-sm-6', 'col-md-6', 'col-lg-6');
            containerPanel.style.display = 'none';
            //Update Doc Panel if it's wide
            if (pdfPanel.classList.contains('col-sm-9')) {
                pdfPanel.classList.remove('col-sm-9', 'col-md-9', 'col-lg-9');
                pdfPanel.classList.add('col-sm-6', 'col-md-6', 'col-lg-6');
            }

            //Disable Full Screen Button
            if (btnFullScreen) { btnFullScreen.classList.add('disable-anchor'); }
            //Disable Wide Screen Button
            if (btnWideScreen) { btnWideScreen.classList.add('disable-anchor'); }
        }
    }

    collapseRecordPanal() {
        let containerPanel = document.getElementById('col1');
        let docPanel = document.getElementById('col2');
        let pdfPanel = document.getElementById('col3');
        let btnFullScreen = document.getElementById('btnRecordFullScreen');
        let btnWideScreen = document.getElementById('btnRecordWideScreen');


        //Remove old Classes
        docPanel.classList.remove('col-sm-12', 'col-md-12', 'col-lg-12');
        //Add new Classes
        docPanel.classList.add('col-sm-3', 'col-md-3', 'col-lg-3');
        containerPanel.style.display = 'block';
        pdfPanel.style.display = 'block';

        //Enable Wide Screen Button
        if (btnWideScreen) { btnWideScreen.classList.remove('disable-anchor'); }

        //Enable Full Screen Button
        if (btnFullScreen) { btnFullScreen.classList.remove('disable-anchor'); }
    }

    wideRecordDocPanalTitle = "Expand half";
    wideRecordDocPanal() {
        let containerPanel = document.getElementById('col1');
        let docPanel = document.getElementById('col2');
        let pdfPanel = document.getElementById('col3');
        let btnFullScreen = document.getElementById('btnRecordFullScreen');

        if (docPanel.classList.contains('col-sm-3')) {
            //Remove old Classes
            docPanel.classList.remove('col-sm-3', 'col-md-3', 'col-lg-3');
            //Add new Classes
            docPanel.classList.add('col-sm-6', 'col-md-6', 'col-lg-6');
            containerPanel.style.display = 'none';
            //Update Doc Panel if it's wide
            if (pdfPanel.classList.contains('col-sm-9')) {
                pdfPanel.classList.remove('col-sm-9', 'col-md-9', 'col-lg-9');
                pdfPanel.classList.add('col-sm-6', 'col-md-6', 'col-lg-6');
            }
            this.wideRecordDocPanalTitle = "Back to normal view";
            //Disable Full Screen Button
            if (btnFullScreen) { btnFullScreen.classList.add('disable-anchor'); }


        } else {
            //Remove old Classes
            docPanel.classList.remove('col-sm-6', 'col-md-6', 'col-lg-6');
            //Add new Classes
            docPanel.classList.add('col-sm-3', 'col-md-3', 'col-lg-3');
            containerPanel.style.display = 'block';
            this.wideRecordDocPanalTitle = "Expand half";

            //Enable Full Screen Button
            if (btnFullScreen) { btnFullScreen.classList.remove('disable-anchor'); }
        }
        this._sharedService.setBrowseDocumentHeight();
    }
    fullScreenRecordDocPanalTitle = "Expand full";
    fullScreenRecordDocPanal() {
        let containerPanel = document.getElementById('col1');
        let docPanel = document.getElementById('col2');
        let pdfPanel = document.getElementById('col3');
        let btnWideScreen = document.getElementById('btnRecordWideScreen');
        //    let searchdv= document.getElementById('searchdv');//.style.paddingLeft = "80px";
        //    searchdv.style.paddingLeft="6.5%";
        if (docPanel.classList.contains('col-sm-3')) {
            //Remove old Classes
            docPanel.classList.remove('col-sm-3', 'col-md-3', 'col-lg-3');
            //Add new Classes
            docPanel.classList.add('col-sm-12', 'col-md-12', 'col-lg-12');
            containerPanel.style.display = 'none';
            pdfPanel.style.display = 'none';
            this.fullScreenRecordDocPanalTitle = "Back to normal view";
            //Disable Wide Screen Button
            btnWideScreen.classList.add('disable-anchor');

        } else {
            //Remove old Classes
            docPanel.classList.remove('col-sm-12', 'col-md-12', 'col-lg-12');
            //Add new Classes
            docPanel.classList.add('col-sm-3', 'col-md-3', 'col-lg-3');
            containerPanel.style.display = 'block';
            pdfPanel.style.display = 'block';
            // searchdv.style.paddingLeft="2%";
            //Enable Wide Screen Button
            this.fullScreenRecordDocPanalTitle = "Expand full";
            btnWideScreen.classList.remove('disable-anchor');
        }
        this._sharedService.setBrowseDocumentHeight();
    }

    //#endregion

    //#region MISC


    runTechlogAnalyzer() {
        this.sortByDocumentNumberAsc();
        this.disbleDragDrop();
        this.AnalyzeLog();
    }

    runDuplicateAnalyzer() {
        this.sortByDocumentNumberAsc();
        this.disbleDragDrop();
        this.AnalyzeDuplicates();
    }



    sortByDocumentNumberAsc() {
        var sort = [
            {
                colId: "DocNo",
                sort: "asc"
            }
        ];
        // _gridOptions.api.setSortModel(sort);
        _gridOptions.columnApi.applyColumnState({
            state: [{ colId: 'DocNo', sort: 'asc' }],
            defaultState: { sort: null },
        });
        _gridOptions.api.deselectAll();

        this.gridOptions.columnApi.applyColumnState({
            state: [{ colId: 'DocNo', sort: 'asc' }],
            defaultState: { sort: null },
        });
        this.gridOptions.api.deselectAll();
    }
    disbleDragDrop() {
        this.gridOptions.api.setSuppressRowDrag(true);
        this.iconcolor = "black";
        this.isDragable = false;
        this.titleValue = "Drag & Drop Disabled";
    }
    AnalyzeLog() {
        //this.inTechLogGapAnalysisMode = true;
        //this.selectedContainer.IncrementValue
        var noOfRows = this.gridOptions.api.getDisplayedRowCount();
        var differentRows = [];
        var uniqueRows = [];
        this._sharedService.clearToast();
        var IsFirstRowChecked = false;

        for (let currentRowIndex = 0; currentRowIndex < noOfRows - 1; currentRowIndex++) {

            if (currentRowIndex == 0 && IsFirstRowChecked == false) {
                IsFirstRowChecked = true;
                var currentRow = this.gridOptions.api.getDisplayedRowAtIndex(currentRowIndex);


                var currentNumeric = isNullOrUndefined(currentRow.data.DocNo) == false ? (+currentRow.data.DocNo.replace(/\D/g, "")) : 0;
                if (currentNumeric != this.selectedContainer.StartNumber && this.selectedContainer.StartNumber != null) {
                    currentRow.data.IsDifferent = true;
                    currentRow.data.HasDifferentStartNumber = true;
                    differentRows.push(currentRow);
                }

            }

            var nextRowIndex = currentRowIndex + 1;
            var currentRow = this.gridOptions.api.getDisplayedRowAtIndex(currentRowIndex);
            var nextRow = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
            var currentNumeric = isNullOrUndefined(currentRow.data.DocNo) == false ? (+currentRow.data.DocNo.replace(/\D/g, "")) : 0;
            var nextNumeric = isNullOrUndefined(nextRow.data.DocNo) == false ? (+nextRow.data.DocNo.replace(/\D/g, "")) : 0;

            // if ((currentNumeric - nextNumeric > this.selectedContainer.IncrementValue)
            //     //|| (currentNumeric - nextNumeric < this.selectedContainer.IncrementValue)
            //     || (currentNumeric - nextNumeric < -this.selectedContainer.IncrementValue)
            //     || (currentNumeric - nextNumeric == 0)) {

            if (Math.abs(currentNumeric - nextNumeric) !== Math.abs(this.selectedContainer.IncrementValue)) {

                if ((this.selectedContainer.StartNumber == null)
                    && (this.selectedContainer.NumberOfPages != null && this.selectedContainer.NumberOfPages > 0)) {
                    //book change logic check here
                    //Highlight light color Book Break otherwise Normal Series BreakHighlight
                    //Number of Pages(Start Number is Blank) > optional, 
                    //a highlight in a different color if the series breaks on the number of pages factor value. Last Value/Number of pages, reminder is zero.

                    //V1 logic
                    // if (currentNumeric % this.selectedContainer.NumberOfPages == 0) {
                    //     currentRow.data.IsBookChange = true;
                    //     differentRows.push(currentRow);


                    //     if (currentNumeric % this.selectedContainer.NumberOfPages == 1) {
                    //         nextRow.data.IsBookChange = true;
                    //         differentRows.push(nextRow);
                    //     }
                    //     else {
                    //         nextRow.data.IsDifferent = true;
                    //         differentRows.push(nextRow);
                    //     }
                    // }

                    //V2 Logic
                    //prevBookEnd=((AA42-(AA36*AA34)+AA34)-1)/AA36
                    //newBookStart=(AA43-1)/AA36

                    //prevBookEnd=((currentNumeric-(this.selectedContainer.NumberOfPages*this.selectedContainer.IncrementValue)+this.selectedContainer.IncrementValue)-1)/this.selectedContainer.NumberOfPages
                    //newBookStart=(nextNumeric-1)/this.selectedContainer.NumberOfPages

                    var prevBookEnd = ((currentNumeric - (this.selectedContainer.NumberOfPages * this.selectedContainer.IncrementValue) + this.selectedContainer.IncrementValue) - 1) / this.selectedContainer.NumberOfPages
                    var newBookStart = (nextNumeric - 1) / this.selectedContainer.NumberOfPages
                    if (this.HasDecimal(prevBookEnd)) {
                        currentRow.data.IsDifferent = true;
                        differentRows.push(currentRow);
                    }
                    else {

                        currentRow.data.IsBookChange = true;
                        differentRows.push(currentRow);
                    }

                    if (this.HasDecimal(newBookStart)) {
                        nextRow.data.IsDifferent = true;
                        differentRows.push(nextRow);
                    }
                    else {

                        nextRow.data.IsBookChange = true;
                        differentRows.push(nextRow);
                    }
                }
                else {

                    currentRow.data.IsDifferent = true;
                    nextRow.data.IsDifferent = true;
                    differentRows.push(currentRow);
                    differentRows.push(nextRow);
                }
            }
            // if ((currentNumeric - nextNumeric > 1)
            //     || (currentNumeric - nextNumeric < -1)
            //     || (currentNumeric - nextNumeric == 0)) {
            //     currentRow.data.IsDifferent = true;
            //     nextRow.data.IsDifferent = true;
            //     differentRows.push(currentRow);
            //     differentRows.push(nextRow);
            // }
        }

        if ((this.selectedContainer.IncrementValue != null && this.selectedContainer.IncrementValue > 0)
            && (this.selectedContainer.StartNumber != null && this.selectedContainer.StartNumber > 0)
            && (this.selectedContainer.NumberOfPages != null && this.selectedContainer.NumberOfPages > 0)) {

            //check for Document Count
            // if (noOfRows != this.selectedContainer.NumberOfPages) {
            //     var lastRowIndex: number = noOfRows - 1;
            //     var lastRow = this.gridOptions.api.getDisplayedRowAtIndex(lastRowIndex);
            //     lastRow.data.IsDifferent = true;
            //     differentRows.push(lastRow);
            // }

            //given formula
            //Highlight Last Value
            //If the Start Number value is available and it is not the (Start + (Number of pages-1) x increment)= last value in the list then highlight 	

            {
                var lastRowIndex: number = noOfRows - 1;
                var lastRow = this.gridOptions.api.getDisplayedRowAtIndex(lastRowIndex);

                var lastRowNumeric = isNullOrUndefined(lastRow.data.DocNo) == false ? (+lastRow.data.DocNo.replace(/\D/g, "")) : 0;

                if (this.selectedContainer.StartNumber + (this.selectedContainer.NumberOfPages - 1) * this.selectedContainer.IncrementValue != lastRowNumeric) {
                    lastRow.data.IsDifferent = true;
                    differentRows.push(lastRow);
                }
            }
        }
        else {
            //book change logic check here
            //Highlight light color Book Break otherwise Normal Series BreakHighlight
            //Number of Pages(Start Number is Blank) > optional, 
            //a highlight in a different color if the series breaks on the number of pages factor value. Last Value/Number of pages, reminder is zero.
        }

        let gapCount: number = 0;
        let bookChangesCount: number = 0;
        uniqueRows = [...new Set(differentRows)];
        if (uniqueRows.length >= 1) {
            this.gridOptions.getRowStyle = function (params) {
                if (!isNullOrUndefined(params.data.IsDifferent)
                    && params.data.IsDifferent == true) {
                    gapCount = gapCount + 1;
                    return { background: 'coral' }
                }
                if (!isNullOrUndefined(params.data.IsBookChange)
                    && params.data.IsBookChange == true) {
                    bookChangesCount = bookChangesCount + 1;
                    return { background: '#aaffaa' }
                }
            }

            this.gridOptions.api.redrawRows({ rowNodes: uniqueRows });


            //this.gridOptions.api.redrawRows();
            //this._sharedService.showToast({ severity: 'error', summary: 'Gap analyser result', detail: (differentRows.length / 2) + ' gap(s) found!' });
            if (gapCount > 0) {
                this._sharedService.showToast({ severity: 'error', summary: 'Gap analyser result', detail: 'Gap(s) found!' });
            }
            else {
                this._sharedService.showToast({ severity: 'success', summary: 'Gap analyser result', detail: 'Zero gap found!' });
            }
        } else {
            this._sharedService.showToast({ severity: 'success', summary: 'Gap analyser result', detail: uniqueRows.length + ' gap found!' });
        }
        //this.inTechLogGapAnalysisMode = true;
        //this.expandRecordPanal();

    }

    HasDecimal(num) {
        //return !!(num % 1);
        var result = (num - Math.floor(num)) !== 0;
        return result;
    }

    AnalyzeDuplicates() {

        var noOfRows = this.gridOptions.api.getDisplayedRowCount();
        var duplicateRows = [];
        var uniqueRows = [];
        this._sharedService.clearToast();

        for (let currentRowIndex = 0; currentRowIndex < noOfRows - 1; currentRowIndex++) {
            var nextRowIndex = currentRowIndex + 1;
            var currentRow = this.gridOptions.api.getDisplayedRowAtIndex(currentRowIndex);
            var nextRow = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);

            var currentDocNo = isNullOrUndefined(currentRow.data.DocNo) == false ? currentRow.data.DocNo : "";
            var nextDocNo = isNullOrUndefined(nextRow.data.DocNo) == false ? nextRow.data.DocNo : "";
            currentDocNo = currentDocNo.toLowerCase();
            nextDocNo = nextDocNo.toLowerCase();
            if (currentDocNo == nextDocNo
                && currentDocNo.trim() != ""
                && nextDocNo.trim() != "") {
                currentRow.data.IsDuplicate = true;
                nextRow.data.IsDuplicate = true;
                duplicateRows.push(currentRow);
                duplicateRows.push(nextRow);
            }
        }
        uniqueRows = [...new Set(duplicateRows)];
        if (uniqueRows.length > 1) {
            this.gridOptions.getRowStyle = function (params) {
                if (!isNullOrUndefined(params.data.IsDuplicate)
                    && params.data.IsDuplicate == true) {
                    return { background: '#c026ae' }
                }
            }

            this.gridOptions.api.redrawRows({ rowNodes: uniqueRows });
            //this.gridOptions.api.redrawRows();
            this._sharedService.showToast({ severity: 'error', summary: 'Duplicate analyser result', detail: ((uniqueRows.length)) + ' duplicate(s) found!' });
        } else {
            this._sharedService.showToast({ severity: 'success', summary: 'Duplicate analyser result', detail: uniqueRows.length + ' duplicate found!' });
        }

    }

    setGridColumnWidth() {
        // get the current grids width
        //var gridWidth = document.getElementById('documentGrid').offsetWidth;
        var allColumns = _gridOptions.columnApi.getColumns();

        _gridOptions.columnApi.autoSizeColumns(allColumns);

    }

    setDisplayMode() {
        let url = this._router.url;
        if (url.indexOf("/record/") > -1) {
            displayMode = "record";
            this._displayMode = "record";
            this.targetType = 'Records';
            // this.componentName='RECD'
            // this.componentName='TRAD'
        }
        else if (url.indexOf("/transition/") > -1) {
            displayMode = "transition";
            this._displayMode = "transition";
            this.targetType = 'transition';
            // this.componentName='TRAD'
        }
        else if (url.indexOf("/status/") > -1 || url.indexOf("/repair/") > -1) {
            displayMode = "status";
            this._displayMode = "status";
        }
    }
    onModelChange(event: any, overl: any, target: any) {
        if (this.searchQuery && this.searchQuery != null) {
            let status: boolean = /[\+\-()&[\]?*~^|!{}:\\/"]/g.test(this.searchQuery);
            if (status) {
                overl.show(event, target);

            } else {
                overl.hide();
            }
        } else {
            overl.hide();
        }

    }

    ///#endregion
    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];
        let childResultDownload: any[] = [];
        let childResultUpload: any[] = [];
        if (params.context.gridContext.selectedContainer && params.context.gridContext._displayMode != 'status') {
            if (params.context.gridContext.canUpload) {
                childResultUpload.push({
                    name: "Single",
                    action: function () {
                        params.context.gridContext._sharedService.singleDocumentUpload();
                    },
                    icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
                });
            }

            if (params.context.gridContext.canBulkUpload) {
                childResultUpload.push({
                    name: "Bulk",
                    action: function () {
                        params.context.gridContext._sharedService.bulkDocumentUpload();
                    },
                    icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
                });
            }

            result.push({
                name: "Upload Document(s)",
                action: function () {

                },
                icon: params.context.gridContext._sharedService.uploadIconHTMLasString,
                subMenu: childResultUpload,
            });
        }
        if ((params.context.gridContext.canEditMetaData || params.context.gridContext.canEditMetaDataReadonly) && params.context.gridContext.selectedContainer && params.context.gridContext._displayMode != 'status') {
            result.push({
                name: "Edit Metadata",
                action: function () {
                    if (params.node) {
                        params.context.gridContext.gridOptions.api.deselectAll();
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext.updateSelectedDocument();
                        params.context.gridContext._sharedService.updateEditMetadata(null);
                    }

                },
                icon: params.context.gridContext._sharedService.editIconHTMLasString,
            });
        }
        if (params.context.gridContext.selectedContainer && params.context.gridContext._displayMode != 'status') {
            childResultDownload.push({
                name: "PDF",
                action: function () {
                    params.context.gridContext.validatePdfRequest();
                },
                icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
            });

            childResultDownload.push({
                name: "Excel",
                action: function () {
                    params.context.gridContext.exportExcelGrid();
                },
                icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
            });

            childResultDownload.push({
                name: "CSV",
                action: function () {

                    params.context.gridContext.exportGridData();

                },
                icon: params.context.gridContext._sharedService.saveLogFeedIconHTMLasString,
            });

            result.push({
                name: "Download",
                action: function () {


                },
                icon: params.context.gridContext._sharedService.downloadsFeedIconHTMLasString,
                subMenu: childResultDownload,
            });


        }
        if (params.context.gridContext.canDelete && params.context.gridContext.selectedContainer && params.context.gridContext._displayMode != 'status') {
            result.push({
                name: "Delete Document(s)",
                action: function () {
                    if (params.node) {
                        let allSelectedRows: RowNode[] = params.context.gridContext.gridOptions.api.getSelectedNodes();
                        if (allSelectedRows.length > 0) {
                            let SelectedNode = allSelectedRows.find(x => x.rowIndex == params.node.rowIndex);
                            if (SelectedNode) {
                                params.context.gridContext.updateSelectedDocument();
                                params.context.gridContext.confirmDelete();
                            } else {
                                params.context.gridContext.gridOptions.api.deselectAll();
                                params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                                params.context.gridContext.updateSelectedDocument();
                                params.context.gridContext.confirmDelete();
                            }
                        } else {
                            params.context.gridContext.gridOptions.api.deselectAll();
                            params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                            params.context.gridContext.updateSelectedDocument();
                            params.context.gridContext.confirmDelete();
                        }

                    }

                },
                icon: params.context.gridContext._sharedService.trashIconHTMLasString,
            });
        }

        if (params.context.gridContext.canCopyMove && params.context.gridContext.selectedContainer && params.context.gridContext._displayMode != 'status') {
            result.push({
                name: "Copy/Move Document(s)",
                action: function () {
                    if (params.node) {
                        let allSelectedRows: RowNode[] = params.context.gridContext.gridOptions.api.getSelectedNodes();
                        if (allSelectedRows.length > 0) {
                            let SelectedNode = allSelectedRows.find(x => x.rowIndex == params.node.rowIndex);
                            if (SelectedNode) {
                                params.context.gridContext.updateSelectedDocument();
                                params.context.gridContext.copyMoveDocuments();
                            } else {
                                params.context.gridContext.gridOptions.api.deselectAll();
                                params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                                params.context.gridContext.updateSelectedDocument();
                                params.context.gridContext.copyMoveDocuments();
                            }
                        } else {
                            params.context.gridContext.gridOptions.api.deselectAll();
                            params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                            params.context.gridContext.updateSelectedDocument();
                            params.context.gridContext.copyMoveDocuments();
                        }
                    }

                },
                icon: params.context.gridContext._sharedService.copyIconHTMLasString,
            });
        }
        return result;


    }
    //#endregion

    //#region Update Document SeqNo
    docRowDragEnd(event: any) {
        let newSeqNo: number = 0;
        let nextNode: RowNode;
        let previousNode: RowNode;
        let previousIndex: number = 0
        let nextIndex: number = 0;

        try {
            this._sharedService.showLoader("Processing...");
            this._sharedService.onDocumentContainerSelection(event.node.data);
            // console.log("-----On Row Drag End Event----");
            // console.table(event.node.data);
            // console.log("------------------------------");
            // console.log("In Row Drag End document container selection: "+ event.node.data);
            this.documentSelectedBeforeChangeofSeqNo = event.node.data;
            let apiNodes: RowNode[] = event.api.getRenderedNodes();
            previousIndex = event.node.rowIndex - 1;
            // If row is the first row of grid
            if (previousIndex == -1) {
                nextIndex = event.node.rowIndex + 1;
                nextNode = apiNodes.find(x => x.rowIndex == nextIndex);
                newSeqNo = nextNode.data.SeqNo - this.thresholdValueForSeqNo;
                this.updateDocumentSeqNo(newSeqNo.toFixed(4));
                return;
            }
            previousNode = apiNodes.find(x => x.rowIndex == previousIndex);
            nextIndex = event.node.rowIndex + 1;
            // If row is the last row of grid
            if (nextIndex == apiNodes.length) {
                //previousIndex = event.node.rowIndex-1;
                //previousNode = apiNodes.find(x => x.rowIndex == previousIndex);
                newSeqNo = previousNode.data.SeqNo + this.thresholdValueForSeqNo;
                this.updateDocumentSeqNo(newSeqNo.toFixed(4));
                return;
            }
            nextNode = apiNodes.find(x => x.rowIndex == nextIndex);
            newSeqNo = (previousNode.data.SeqNo + nextNode.data.SeqNo) / 2;

            this.updateDocumentSeqNo(newSeqNo.toFixed(4));
        } catch (e) {
            this._sharedService.hideLoader();
            console.log(e);
        }

    }
    updateDocumentSeqNo(seqNo: string): void {
        //US-8181 // let asset = this._sharedService.selectedAsset[0].data.split('|');
        let assetDataObj = JSON.parse(this._sharedService.selectedAsset[0].data);
        let extractedUrl = decodeURIComponent(this._sharedService.selectedDocument.Url).split('?')[0].split('/');
        // console.log("-----Update Doc Seq No with url Info----");
        //     console.log(extractedUrl);
        //     console.log("------------------------------");
        // console.log("Update Doc Seq No with url Info: "+this._sharedService.selectedDocument.Url);
        let body: any = {
            ASN: this._sharedService.selectedContainer.ASN,
            SeqNo: seqNo,
            Url: extractedUrl[extractedUrl.length - 1],
            ID: this._sharedService.selectedContainer.ID,
            Type: this._sharedService.selectedContainer.Type,
            ContainerName: assetDataObj.StoragePath// asset[0]

        }
        this.documentSelectedBeforeChangeofSeqNo.SeqNo = seqNo;
        //  console.log("-----Update Doc Before Request to server ----");
        this._azureBlobService.updateDocumentSeqNo(body)
            .subscribe({
                next: (response) => {
                    this.populateDocumentGrid();
                    //  this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                    sharedService.isDocManipulated = true;
                    //  console.log("-----Update Doc After Response from server ----");
                    setTimeout(() => {
                        this._sharedService.hideLoader();
                    },
                        2000);
                },
                error: (error) => {
                    console.log(error);
                    setTimeout(() => {
                        this._sharedService.hideLoader();
                    },
                        2000);
                }
            });

    }

    enableDisableRowDrag() {
        this.resetGridRows();
        if (this.isDragable) {
            this.gridOptions.api.setSuppressRowDrag(true);
            this.iconcolor = "black";
            this.isDragable = false;
            this.titleValue = "Drag & Drop Disabled";
            this.gridOptions.columnApi.setColumnWidth('checkboxcol', 25, true);

        } else {
            this.gridOptions.api.setSuppressRowDrag(false);
            this.iconcolor = "red";
            this.isDragable = true;
            this.titleValue = "Drag & Drop Enabled";
            this.gridOptions.columnApi.setColumnWidth('checkboxcol', 50, true);
        }
    }

    enableDisableDocGridSideBar() {

        if (this.isSidebarVisible) {
            //this.gridApi.setSideBarVisible(value);
            this.gridOptions.api.setSideBarVisible(false);
            //this.gridOptions.api.side

            //defaultToolPanel: 'columns',
            //     hiddenByDefault: true,
            this.iconcolorSidebar = "black";
            this.isSidebarVisible = false;
            this.sideBarTitleValue = "Side Bar Disabled";
        } else {
            this.gridOptions.api.setSideBarVisible(true);
            this.iconcolorSidebar = "red";
            this.isSidebarVisible = true;
            this.sideBarTitleValue = "Side Bar Enabled";
        }
    }
    resetGridRows() {
        //this.gridOptions.api.setSortModel(null);
        _gridOptions.columnApi.applyColumnState({
            defaultState: { sort: null },
        });
        //_gridOptions.api.deselectAll();

        var noOfRows = this.gridOptions.api.getDisplayedRowCount();
        var rows = [];
        this.gridOptions.api.forEachNode((node, index) => {
            node.data.IsDifferent = false;
            rows.push(node);
        });
        this.gridOptions.api.redrawRows({ rowNodes: rows });
    }

    confirmDeleteDocumentsMessage(CompletelistOfIdsToDelete: any) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        let documents: any = CompletelistOfIdsToDelete;
        this.docDeleteConfirmGridData = documents.BlobList;
        this.docDeleteConfirmGridDataComplete = documents;
        this.displayDeleteConfirmDlg = true;
    }
    setDeleteListingGridColumns() {
        this.docDeleteConfirmColumnDefs = [];
        this.docDeleteConfirmColumnDefs =
            [
                { headerName: 'Document Type', minWidth: 180, field: 'DocType' },
                { headerName: 'Number', field: 'DocNo', minWidth: 90 },
                { headerName: referenceKeyLabel, field: 'ReferenceKey', minWidth: 110 },
                {
                    headerName: 'DocDate', field: 'DocDate', filter: 'agDateColumnFilter', minWidth: 100, valueFormatter: function (params: any) {
                        if (params.value != "" && params.value != null) {

                            let dateString = params.value.substring(0, 10);
                            return moment(dateString).format(dateFormatF.toUpperCase());
                        }
                    }
                },
            ];

    }
    deleteAllDocuments(data: boolean) {
        let selectedRows = this.gridOptions.api.getSelectedRows();
        let blobList = {
            BlobList: this.getBlobListForDelete(),
            TaskDocumentIds: this.docDeleteConfirmGridDataComplete.TaskDocumentIds,
            isCheckTaskDocument: data,
            IsCheckCommsExistence: data,
            listOfIdsToDelete: this.listOfCommsIdsToDelete,// completelistOfIdsToDelete,
            ContainerID: this.selectedContainer.ContainerID,
            ASN: this.selectedContainer.ASN,
            ID: this.selectedContainer.ID,
            Type: this.selectedContainer.Type
        };
        this.displayDeleteConfirmDlg = false;
        this._sharedService.showLoader("Processing...");
        this._azureBlobService.deleteAllDocuments(blobList)
            .subscribe({
                next: (response) => {
                    this._sharedService.clearToast();
                    let resp = response;
                    switch (resp.ResponseType) {
                        case 1: // Deleted Successfully
                            this.refreshDocGrid();
                            this._sharedService.hideLoader();
                            this._sharedService.showToast({ severity: 'success', summary: 'Document(s) deleted', detail: 'Document(s) have been deleted successfully.' });

                            break;
                        case 2:
                            this._sharedService.hideLoader();
                            //this.confirmDeleteDocumentsMessage(resp.data);
                            break;
                        case 3: // Task Documents linkage Found
                            this._sharedService.hideLoader();
                            this.confirmDeleteDocumentsMessage(resp.ResponseBody);
                            break;
                        default:// Deleted Successfully
                            this.refreshDocGrid();
                            this._sharedService.hideLoader();
                            this._sharedService.showToast({ severity: 'success', summary: 'Document(s) deleted', detail: 'Document(s) have been deleted successfully.' });

                    }
                },
                error: (error) => {
                    if (error.status == 501) {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: error.error });
                        this._sharedService.hideLoader();
                        this.refreshDocGrid();
                    }
                    else if (error.status == 412) {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: error.error });
                        this._sharedService.hideLoader();
                        this.displayDeleteConfirmDlg = false;
                    } else {
                        console.log(error);
                        this._sharedService.hideLoader();
                        this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while deleting document(s).' });
                        this.displayDeleteConfirmDlg = false;
                    }
                }
            });
    }
    crossSignClicked() {
        this.isBlankSearchQuery = false;
        this.searchQuery = null;
        sharedService.updateSearchText(this.searchQuery);
        this._sharedService.selectedDocuments = null;
        this.populateDocumentGrid();
    }
    //#endregion

    wOGapAnalysisClick() {
        this._sharedService.showLoader("Processing...");
        if (!this.inWOGapAnalysisMode) {
            //let referenceKeyVal = this._sharedService.selectedDocument.ReferenceKey ? this._sharedService.selectedDocument.ReferenceKey : '';
            let body: container = this._sharedService.selectedContainer;
            body.OutGoingParam = [];
            this._azureBlobService.getContainerWOGapData(body)
                .subscribe({
                    next: (response) => {
                        //this.woGapButtonTitle = "Go back to normal view";
                        this.inWOGapAnalysisMode = true;
                        this._sharedService.inWOGapAnalysisMode = true;

                        this.gridOptions.columnApi.setColumnVisible('DocNoExternal', true);
                        this.gridOptions.columnApi.setColumnVisible('checkboxcol', false);
                        this.docGridData = response.Documents;
                        this.totalCount = this.docGridData.length;
                        this.directoryCount = response.DirectoryFileCount;
                        this._sharedService.docCount = this.docGridData.length;
                        this.gridOptions.api.getColumnDef('DocNoExternal').headerName = response.Documents[0].DocExternalLabel;
                        this.gridOptions.api.refreshHeader();
                        this.expandRecordPanal();

                        let gapCount: any[] = response.Documents.filter(c => {
                            let docnoExtArray = c.DocNoExternal ? c.DocNoExternal.split('>') : c.DocNoExternal;
                            if (c.DocNoExternal && c.DocNo && docnoExtArray[docnoExtArray.length - 1].trim() == c.DocNo.trim()) {
                                return false;
                                //} else if (c.DocNo && c.DocNoExternal && c.DocNo != c.DocNoExternal) {
                            } else if (c.DocNo && c.DocNo.trim() != c.DocNoExternal.trim()) {
                                return false;
                            } else if (!c.DocNo && !c.DocType) {
                                return true;
                            }
                        });

                        if (gapCount && gapCount.length > 0) {
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'success', summary: 'Gap analysis result. ', detail: gapCount.length + ' Gap(s) found!' });
                        }
                        this._sharedService.hideLoader();
                    },
                    error: (error) => {
                        this._sharedService.clearToast();
                        if (error.status == '404') {
                            this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: error.error });
                        } else {
                            this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again later.' });
                        }
                        this._sharedService.hideLoader();
                    }
                });

        }
    }

    downloadGapanalyserGridExcel() {
        this.gridOptions.api.exportDataAsExcel();
    }
    downloadGapanalyserGridCSV() {
        this.gridOptions.api.exportDataAsCsv();
    }

    goBackToNormalView() {
        if (this.inWOGapAnalysisMode) {
            this.inWOGapAnalysisMode = false;
            this._sharedService.inWOGapAnalysisMode = false;
            this.gridOptions.columnApi.setColumnVisible('DocNoExternal', false);
            this.gridOptions.columnApi.setColumnVisible('checkboxcol', true);
            this.populateDocumentGrid();
            var sortData: ColumnState[] = [
                {
                    colId: this.sortOnCol,
                    sort: (this.orderBy.toLowerCase()) == "asc" ? 'asc' : 'desc'
                }
            ];
            _gridOptions.columnApi.applyColumnState({
                state: sortData,
                defaultState: { sort: null },
            });
            this.gridOptions.columnApi.applyColumnState({
                state: sortData,
                defaultState: { sort: null },
            });
            this._sharedService.hideLoader();
            this.collapseRecordPanal();
            this.filterGrid = false;
        }
        else if (this.inTechLogGapAnalysisMode) {
            let documentGridCols = this.Document_GEN_Columns;
            let document_Col_Sort_On_By = this.Document_GEN_Sort_By;
            let sortOnCol = document_Col_Sort_On_By.split("||")[0];
            let orderBy = document_Col_Sort_On_By.split("||")[1];
            var sortColumn: ColumnState[] = [
                {
                    colId: sortOnCol,
                    sort: (orderBy.toLowerCase()) == "asc" ? 'asc' : 'desc'
                }
            ];
            _gridOptions.columnApi.applyColumnState({
                state: sortColumn,
                defaultState: { sort: null },
            });
            _gridOptions.api.deselectAll();
            this.gridOptions.columnApi.applyColumnState({
                state: sortColumn,
                defaultState: { sort: null },
            });
            this.gridOptions.api.deselectAll();

            this.inTechLogGapAnalysisMode = false;
            this.populateDocumentGrid();
            this._sharedService.hideLoader();
            this.collapseRecordPanal();
            //this.setDocumentGridColumns();
        }
        else {
            // Reset column sorting on & by to config;
            var documentGridCols: any[];
            var document_Col_Sort_On_By: string = '';

            switch (this._sharedService.recordType.toUpperCase()) {
                case 'MXR':
                    documentGridCols = this.Document_MXR_Columns;
                    document_Col_Sort_On_By = this.Document_MXR_Sort_By;
                    break;

                case 'LOG':
                    documentGridCols = this.Document_LOG_Columns;
                    document_Col_Sort_On_By = this.Document_LOG_Sort_By;
                    break;

                case 'GEN':
                    documentGridCols = this.Document_GEN_Columns;
                    document_Col_Sort_On_By = this.Document_GEN_Sort_By;
                    break;

                case 'DOC':
                    documentGridCols = this.Document_DOC_Columns;
                    document_Col_Sort_On_By = this.Document_DOC_Sort_By;
                    break;

                case 'BND':
                    documentGridCols = this.Document_BND_Columns;
                    document_Col_Sort_On_By = this.Document_BND_Sort_By;
                    break;

                default: //MXR
                    documentGridCols = this.Document_MXR_Columns;
                    document_Col_Sort_On_By = this.Document_MXR_Sort_By;
                    break;
            }


            let sortOnCol = document_Col_Sort_On_By.split("||")[0];
            let orderBy = document_Col_Sort_On_By.split("||")[1];
            if (this.columnDefs && this.columnDefs.length > 0) {
                this.columnDefs.forEach(function (col, index) {
                    if (col.field == sortOnCol) {
                        this.columnDefs[index].sort = orderBy;
                    }
                }.bind(this));

            }
            var sortColumn: ColumnState[] = [
                {
                    colId: sortOnCol,
                    sort: (orderBy.toLowerCase()) == "asc" ? 'asc' : 'desc'
                }
            ];
            _gridOptions.columnApi.applyColumnState({
                state: sortColumn,
                defaultState: { sort: null },
            });
            _gridOptions.api.deselectAll();
            this.gridOptions.columnApi.applyColumnState({
                state: sortColumn,
                defaultState: { sort: null },
            });
            this.gridOptions.api.deselectAll();
        }
    }

    showHideWhiteRows() {
        let docGridDatafiltered = this._sharedService.deepCopy(this.docGridData);
        while (docGridDatafiltered.findIndex(d => d.DocNo === null && d.DocType !== null) != -1) {
            let currIdex = docGridDatafiltered.findIndex(d => d.DocNo === null && d.DocType !== null);
            docGridDatafiltered.splice(currIdex, 1);
        }
        this.gridOptions.api.setRowData(docGridDatafiltered);
        this.showHideWhiteRowLabel = 'Show compared documents';
        this.hideWhiteRow = true;
        this.filterGrid = true;
    }
    showHideRedRows() {
        let docGridDatafiltered = this._sharedService.deepCopy(this.docGridData);
        while (docGridDatafiltered.findIndex(d => d.DocType !== null) !== -1) {
            let currIdex = docGridDatafiltered.findIndex(d => d.DocType !== null);
            docGridDatafiltered.splice(currIdex, 1);
        }
        this.gridOptions.api.setRowData(docGridDatafiltered);
        this.showHideRedRowLabel = 'Show No document found';
        this.hideWhiteRow = true;
        this.filterGrid = true;
    }
    resetGridFilter() {
        this.gridOptions.api.setRowData(this.docGridData);
        this.filterGrid = false;
    }

    showWoGapManualDlg() {
        if (this._sharedService._ContainerGridSelectedRows.length > 1) {
            let qualityStatusListValues = this._sharedService.getContainerQualityStatusList();
            if (qualityStatusListValues.filter(a => a.Name.toLowerCase() == "match").length == 0 || qualityStatusListValues.filter(a => a.Name.toLowerCase() == "gap").length == 0) {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Container quality status (Match or Gap) is missing or inactive' });
                return;
            }
            this._sharedService.showLoader("Processing...");
            this.runGapAnalyzer();
            // if(this._sharedService._ContainerGridSelectedRows.length > 2){
            //     this.runGapAnalyzer();
            //     }
            //     else{
            //     this._azureBlobService.getContainerListData(this._sharedService._ContainerGridSelectedRows).subscribe( Response => {

            //         if (!Response) {
            //             this._sharedService.hideLoader();
            //             this._sharedService.clearToast();
            //             this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Some error found while analysing the data.' });
            //             return;
            //         } else {
            //             this._sharedService.hideLoader();
            //             var data = Response;
            //             if (data[0].Container.OutGoingParam == null) {
            //                 this.refreshDocGrid();
            //                 this._sharedService.clearToast();
            //                 this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'No active feed found.' });

            //             }
            //             else if (data.length > 0 && data[0].DirectoryDocumentEntity.Documents.length > 0 && data[0].Container.OutGoingParam.length > 0) {
            //                 this.refreshDocGrid();
            //                 this._sharedService.clearToast();
            //                 this._sharedService.showToast({ severity: 'success', summary: 'success', detail: 'Gap Analysis performed successfully' });
            //             } else {
            //                 this._sharedService.hideLoader();
            //                 this._sharedService.clearToast();
            //                 this._sharedService.showToast({ severity: 'warn', summary: 'Warning', detail: 'No Documents Found' });
            //             }
            //             return;
            //         }
            //     }, error => {
            //         this._sharedService.hideLoader();
            //         this._sharedService.clearToast();
            //         this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again later.' });
            //         console.log(error)
            //     }
            //     );
            // }
        }
        else {
            if (!this.inWOGapAnalysisMode) {
                this.WOGapMappingList = [];
                this._apiService.getWoGapManualOutgoingParams()
                    .subscribe({
                        next: (response) => {
                            if (!response) {
                                this._sharedService.clearToast();
                                this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'No active feed found.' });
                                return;
                            }
                            if (response.length > 0) {
                                this.woGapManualParams = response;
                                this.WOGapFormGroup = new UntypedFormGroup({});
                                let uniqueDocList = [];
                                let isContainerDescUsed = this.woGapManualParams.find(s => s.Key == 'ContainerDescription');
                                if (isContainerDescUsed) {
                                    if (this._sharedService.selectedContainer.Description) {
                                        let contDescList = this._sharedService.selectedContainer.Description.split(',');
                                        for (let index = 0; index < contDescList.length; index++) {
                                            let paramObj = {};
                                            paramObj[this.woGapManualParams[0].Key] = contDescList[index];
                                            uniqueDocList.push(paramObj);
                                            // /{paramName: contDescList[index]}
                                        }
                                    }
                                } else {
                                    uniqueDocList = this.getUniqueDocumentList();
                                }

                                for (var i = 0; i < uniqueDocList.length; i++) {
                                    let id = new Date().valueOf() + i;
                                    let param = new OutgoingParams(id, this.woGapManualParams);
                                    this.WOGapMappingList.push(param);

                                    for (var j = 0; j < this.WOGapMappingList[i].stringParam.length; j++) {
                                        let fieldName = this.WOGapMappingList[i].stringParam[j];
                                        let ctrlId = "field" + this.WOGapMappingList[i].id + "_" + fieldName.Value;
                                        this.WOGapFormGroup.addControl(ctrlId, new UntypedFormControl('', Validators.required));
                                        this.WOGapFormGroup.controls[ctrlId].setValue(this.getCtrlVal(uniqueDocList[i], fieldName.Key));

                                        let OrignalCtrlId = "field" + this.WOGapMappingList[i].id + "_" + fieldName.Value + '1';
                                        this.WOGapFormGroup.addControl(OrignalCtrlId, new UntypedFormControl());

                                        //Only set value if container desc is not being used
                                        if (!isContainerDescUsed) {
                                            this.WOGapFormGroup.controls[OrignalCtrlId].setValue(this.getCtrlVal(uniqueDocList[i], fieldName.Key));
                                        }
                                    }
                                }

                                this.diaplayWOGapManualDialog = true;
                            } else {
                                this._sharedService.clearToast();
                                this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'No outgoing parameters found.' });
                            }
                        },
                        error: (error) => {
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again later.' });
                            console.log(error)
                        }
                    });
            } else {
                this.woGapButtonManualTitle = "Gap Analyser (CTRL+M) ";
                this.inWOGapAnalysisMode = false;
                this._sharedService.inWOGapAnalysisMode = false;
                this.gridOptions.columnApi.setColumnVisible('DocNoExternal', false);
                this.gridOptions.columnApi.setColumnVisible('checkboxcol', true);
                this.populateDocumentGrid();
                this.wideRecordDocPanal();
                this._sharedService.hideLoader();
            }
        }
    }

    getUniqueDocumentList() {
        let uniqueDocumentList: any[] = [];

        for (const sourceDoc of this.docGridData) {
            let destDoc = new AzureBlobForWOGap();
            for (let param of this.woGapManualParams) {
                switch (param.Key) {
                    case 'ReferenceKey':
                        destDoc.ReferenceKey = sourceDoc.ReferenceKey;
                        break;
                    case 'DocNo':
                        destDoc.DocNo = sourceDoc.DocNo;
                        break;
                    case 'ASN':
                        destDoc.ASN = sourceDoc.ASN;
                        break;
                    case 'DocType':
                        destDoc.DocType = sourceDoc.DocType;
                        break;
                    case 'Comments':
                        destDoc.Comments = sourceDoc.Comments;
                        break;
                    case 'DocDate':
                        destDoc.DocDate = sourceDoc.DocDate;
                        break;
                    case 'Tags':
                        destDoc.Tags = sourceDoc.Tags;
                        break;
                }
            }

            if (!uniqueDocumentList.find(d => d.DocNo == destDoc.DocNo
                && d.ContainerDescription == destDoc.ContainerDescription
                && d.ReferenceKey == destDoc.ReferenceKey
                && d.ASN == destDoc.ASN
                && d.Tags == destDoc.Tags
                && d.DocDate == destDoc.DocDate
                && d.Comments == destDoc.Comments
                && d.DocType == destDoc.DocType)
                && (destDoc.DocNo || destDoc.ContainerDescription || destDoc.ReferenceKey
                    || destDoc.ASN || destDoc.DocDate || destDoc.Comments || destDoc.DocType)) {
                uniqueDocumentList.push(destDoc);
            }
        }

        return uniqueDocumentList;
    }

    getCtrlVal(document: AzureBlobForWOGap, fieldName: string) {

        switch (fieldName) {
            case 'ContainerDescription':
                return document.ContainerDescription;

            case 'ReferenceKey':
                return document.ReferenceKey;

            case 'DocNo':
                return document.DocNo;

            case 'ASN':
                return document.ASN;

            case 'DocType':
                return document.DocType;

            case 'Comments':
                return document.Comments;

            case 'DocDate':
                return document.DocDate;

            case 'Tags':
                return document.Tags;
        }
    }

    addWOManualParam() {

        let param = new OutgoingParams(new Date().getTime(), this.woGapManualParams);
        this.WOGapMappingList.push(param);
        let index = this.WOGapMappingList.length - 1;
        for (var j = 0; j < this.WOGapMappingList[index].stringParam.length; j++) {
            this.WOGapFormGroup.addControl("field" + param.id + "_" + this.WOGapMappingList[index].stringParam[j].Value, new UntypedFormControl('', Validators.required));
            //For Orignal Ctrl Value
            this.WOGapFormGroup.addControl("field" + param.id + "_" + this.WOGapMappingList[index].stringParam[j].Value + '1', new UntypedFormControl());
        }
        //}
    }

    removeWOManualParam(id: any) {
        //Remove Controls
        let param = this.WOGapMappingList.find(m => m.id == id);
        if (param) {
            for (var j = 0; j < param.stringParam.length; j++) {
                this.WOGapFormGroup.removeControl("field" + param.id + "_" + param.stringParam[j].Value);
                //Remove Orignal Val Conttrol
                this.WOGapFormGroup.removeControl("field" + param.id + "_" + param.stringParam[j].Value + '1');
            }
            //Remove Item
            this.WOGapMappingList.splice(this.WOGapMappingList.indexOf(param), 1);
        }
    }

    getWOManualFieldLabel(field: string): string {
        switch (field) {
            case 'ReferenceKey':
                return this._sharedService.getReferenceKeyLabel();
            case 'DocNo':
                return 'Document Number';
            case 'DocType':
                return 'Document Type';
            case 'ASN':
                return 'Asset Serial No.';
            case 'Comments':
            case 'Tags':
                return field;
            case 'DocDate':
                return 'Document Date';
            case 'ContainerDescription':
                return 'Container Description';
        }
    }

    wOGapAnalysisManualClick() {
        // // let currentHeight: string = 81 + 'vh !important';
        // //     $("#documentGrid").attr('style', 'height:' + currentHeight + '');


        //     let innerGridHeight: number = $('browse-document>article>div>div').css('height').replace('px', '');
        //     let innerGridPadding: number = $('browse-document>article>div>div').css('padding').replace('px', '');
        //     let ActualGridHeight = innerGridHeight - innerGridPadding- 1;
        //     let currentHeight: string = ActualGridHeight + 'px !important';
        //     $("#documentGrid").attr('style', 'height:' + currentHeight + '');


        let innerGridHeight: number = $('browse-document>article>div>div').css('height').replace('px', '');
        let innerGridPadding: number = $('browse-document>article>div>div').css('padding').replace('px', '');
        let ActualGridHeight = innerGridHeight - innerGridPadding - 1;
        let currentHeight: string = ActualGridHeight + 'px !important';
        $("#documentGrid").attr('style', 'height:' + currentHeight + '');




        this._sharedService.showLoader("Processing...");
        if (!this.inWOGapAnalysisMode) {
            //this.woGapButtonManualTitle = "Go back to normal view";
            this.inWOGapAnalysisMode = true;
            this._sharedService.inWOGapAnalysisMode = true;
            //let referenceKeyVal = this._sharedService.selectedDocument.ReferenceKey ? this._sharedService.selectedDocument.ReferenceKey : '';
            let body: container = this._sharedService.selectedContainer;
            body.OutGoingParam = [];

            let formData = this.WOGapFormGroup.getRawValue();
            //Add outgoing Params
            for (var i = 0; i < this.WOGapMappingList.length; i++) {
                let outgoingParamItem: OutGoingParamKeyVal = new OutGoingParamKeyVal();
                outgoingParamItem.KeyValList = [];
                for (var j = 0; j < this.WOGapMappingList[i].stringParam.length; j++) {
                    let fieldName = this.WOGapMappingList[i].stringParam[j].Value;
                    let fieldId = "field" + this.WOGapMappingList[i].id + "_" + fieldName;
                    let OrignalfieldId = "field" + this.WOGapMappingList[i].id + "_" + fieldName + '1';
                    let fieldVal;
                    let OrignalfieldVal;
                    if (fieldName.toString().indexOf('Date') != -1) {
                        fieldVal = this.getDate(formData[fieldId]);
                        OrignalfieldVal = this.getDate(formData[OrignalfieldId]);
                    } else {
                        fieldVal = formData[fieldId];
                        OrignalfieldVal = formData[OrignalfieldId];
                    }
                    if (fieldVal == OrignalfieldVal) {
                        outgoingParamItem.KeyValList.push({ Key: fieldName, Value: fieldVal });
                    } else {
                        outgoingParamItem.KeyValList.push({ Key: fieldName, Value: OrignalfieldVal + '<>' + fieldVal });
                    }
                }

                body.OutGoingParam.push(outgoingParamItem);
            }


            this._azureBlobService.getContainerWOGapData(body)
                .subscribe({
                    next: (response) => {
                        this.WOGapManualDialogHeight = 37;
                        this.calculateGridHeight();
                        this.gridOptions.columnApi.setColumnVisible('DocNoExternal', true);
                        this.gridOptions.columnApi.setColumnVisible('checkboxcol', false);
                        this.docGridData = response.Documents;
                        this.totalCount = this.docGridData.length;
                        this._sharedService.docCount = this.docGridData.length;
                        this.gridOptions.api.getColumnDef('DocNoExternal').headerName = response.Documents[0].DocExternalLabel;
                        this.gridOptions.columnApi.setColumnWidth("DocNoExternal", 250, true);
                        // this.gridOptions.columnApi.autoSizeColumn('DocNoExternal',true);
                        this.gridOptions.api.refreshHeader();
                        this.expandRecordPanal();

                        let gapCount: any[] = response.Documents.filter(c => {
                            let docnoExtArray = c.DocNoExternal ? c.DocNoExternal.split('>') : c.DocNoExternal;
                            if (c.DocNoExternal && c.DocNo && docnoExtArray[docnoExtArray.length - 1].trim() == c.DocNo.trim()) {
                                return false;
                            } else if (c.DocNo && c.DocNoExternal && c.DocNo.trim() != c.DocNoExternal.trim()) {
                                return false;
                            } else if (!c.DocNo && !c.DocType) {
                                return true;
                            }
                        });

                        if (gapCount && gapCount.length > 0) {
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'success', summary: 'Gap analysis result. ', detail: gapCount.length + ' Gap(s) found!' });
                        }
                        this.WOGapMappingList = [];
                        this._sharedService.hideLoader();
                        this.diaplayWOGapManualDialog = false;
                    },
                    error: (error) => {
                        this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again later.' });
                        this._sharedService.hideLoader();
                        this.WOGapMappingList = [];
                        this.diaplayWOGapManualDialog = false;
                    }
                })

        }
        // else {
        //     this.woGapButtonManualTitle = "Gap Analyser(Custom) (CTRL+M) ";
        //     this.inWOGapAnalysisMode = false;
        //     this._sharedService.inWOGapAnalysisMode = false;
        //     this.gridOptions.columnApi.setColumnVisible('DocNoExternal', false);
        //     this.gridOptions.columnApi.setColumnVisible('checkboxcol', true);
        //     this.populateDocumentGrid();
        //     this.collapseRecordPanal();
        //     this._sharedService.hideLoader();
        // }
    }

    getDate(date: Date): string {
        if (date != null) {
            //let newDate = moment(date).format("YYYY-MM-DD");
            //return date.toDateString()
            let newDate = moment(date).format("YYYY-MM-DD");
            return newDate; //new Date(newDate).toDateString();
        }
        return null;
    }

    onBlurWoDate(event: any) {
        let tskDlgfrm = document.getElementById('woGapDate');
        if (tskDlgfrm) {
            tskDlgfrm.style.height = '215px';
        }
    }

    onCloseWoDate(event: any) {
        let tskDlgfrm = document.getElementById('woGapDate');
        if (tskDlgfrm) {
            tskDlgfrm.style.height = '50px';
        }
    }

    CancelWOGapManualClick() {
        this.WOGapMappingList = [];
        this.diaplayWOGapManualDialog = false;

    }
    setFocus(event: Event) {
        try {
            let ids = document.querySelectorAll("[id^='field']");
            if (ids) {
                document.getElementById(ids[0].id).focus();
            }
        } catch (err) {
            console.log(err);
        }
    }
    //#region Quality Status
    isQualityStatusAllowed(qualityStatus: string) {
        let permission: boolean = false;
        try {
            switch (qualityStatus) {
                case 'MXR':
                    permission = this._sharedService.getQualityStatusConfigurations('MX_Records_Quality_Process');
                    break;
                case 'LOG':
                    permission = this._sharedService.getQualityStatusConfigurations('Log_Books_Quality_Process');
                    break;
                case 'GEN':
                    permission = this._sharedService.getQualityStatusConfigurations('General_Quality_Process');
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log(error);
        }
        return permission;
    }
    populateQualityStatus() {
        //  let qualityStatusListValues = this._sharedService.getContainerQualityStatusList();
        this.qualityStatusList = this._sharedService.getDocumentQualityStatusList();
        statusList = this.qualityStatusList
        containerQualityStatusList = this._sharedService.getContainerQualityStatusList();
        // if (qualityStatusListValues == null || qualityStatusListValues.length == 0) {
        // this._qualityStatusService.getActiveQualityStatusData(null).subscribe( response => {
        //     try {

        //         this.qualityStatusList = response;
        //         statusList = response;
        //     } catch (ex) {
        //         this.qualityStatusList = response;
        //         statusList = response;
        //         console.log(ex);
        //     }

        // },
        //     error => {

        //     });
        // }
        //  else {
        //     this.qualityStatusList = qualityStatusListValues;
        //     statusList = qualityStatusListValues;
        // }
    }
    containerStatusCellRenderer(params: any) {
        let html: string = '';
        var eDiv = document.createElement('div');
        if (params.value) {
            try {
                let currentStatus: any = containerQualityStatusList.filter(x => x.QualityStatusID == params.value)[0];
                if (currentStatus && currentStatus.Name) {
                    eDiv.style.background = currentStatus.CSSClass;
                    html = html + currentStatus.Name;
                } else {
                    let currentStatus: any = containerQualityStatusList.filter(x => x.Name == params.value)[0];//params.value)[0];
                    if (currentStatus && currentStatus.Name) {
                        eDiv.style.background = currentStatus.CSSClass;
                        html = html + currentStatus.Name;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            return params.value;
        }


        eDiv.style.height = '100%';
        eDiv.style.width = '100%';
        eDiv.style.color = 'white';
        eDiv.style.textAlign = 'left';
        eDiv.style.paddingLeft = '5px';
        eDiv.innerHTML = html;
        return eDiv;
    }

    statusCellRenderer(params: any) {
        let html: string = '';
        var eDiv = document.createElement('div');
        if (params.value) {
            try {
                let currentStatus: any = statusList.filter(x => x.QualityStatusID == params.value)[0];
                if (currentStatus && currentStatus.Name) {
                    eDiv.style.background = currentStatus.CSSClass;
                    html = html + currentStatus.Name;
                } else {
                    let currentStatus: any = statusList.filter(x => x.Name == params.value)[0];
                    if (currentStatus && currentStatus.Name) {
                        eDiv.style.background = currentStatus.CSSClass;
                        html = html + currentStatus.Name;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            return params.value;
        }


        eDiv.style.height = '100%';
        eDiv.style.width = '100%';
        eDiv.style.color = 'white';
        eDiv.style.textAlign = 'left';
        eDiv.style.paddingLeft = '5px';
        eDiv.innerHTML = html;
        return eDiv;
    }

    statusCellRendererEdit(params: any) {
        let html: string = '';
        var eDiv = document.createElement('div');
        if (params.value) {
            try {
                let currentStatus: any = statusList.filter(x => x.QualityStatusID == params.value)[0];
                if (currentStatus && currentStatus.Name) {
                    eDiv.style.background = currentStatus.CSSClass;
                    html = html + currentStatus.Name;
                } else {
                    let currentStatus: any = statusList.filter(x => x.Name == params.value)[0];
                    if (currentStatus && currentStatus.Name) {
                        eDiv.style.background = currentStatus.CSSClass;
                        html = html + currentStatus.Name;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            return params.value;
        }


        eDiv.style.height = '100%';
        eDiv.style.width = '100%';
        eDiv.style.color = 'white';
        eDiv.style.textAlign = 'left';
        eDiv.style.paddingTop = '6px';
        eDiv.style.paddingLeft = '5px';
        eDiv.innerHTML = html;
        return eDiv;
    }
    updateDocumentQualityStatus(event: any) {

        this._sharedService.showLoader("Processing...");
        this.showHideCommDialogue(false)
        if (event.colDef.field == 'Status') {
            if (event.oldValue != event.newValue) {
                try {

                    sharedService.isDocManipulated = true;
                    let qualityStatusId = event.context.gridContext.qualityStatusList.find(x => x.Name == event.data.Status).QualityStatusID;
                    //US-8181 //let asset = event.context.gridContext._sharedService.selectedAsset[0].data.split('|');

                    let assetDataObj = JSON.parse(event.context.gridContext._sharedService.selectedAsset[0].data);
                    let body: any = {
                        ContainerName: assetDataObj.StoragePath,// asset[0],
                        ASN: event.context.gridContext._sharedService.selectedContainer.ASN,
                        Type: event.context.gridContext._sharedService.selectedContainer.Type,
                        ID: event.context.gridContext._sharedService.selectedContainer.ID,
                        Status: qualityStatusId,
                        Url: event.context.gridContext.extractBlobReferenceFromURL()
                    };
                    event.context.gridContext._azureBlobService.updateQualityStatus(body)
                        .subscribe({
                            next: (response) => {
                                event.context.gridContext._sharedService.onAssetChanged(event.context.gridContext._sharedService.selectedAsset);
                                event.context.gridContext._sharedService.clearToast();

                                this._sharedService.hideLoader();
                                event.context.gridContext._sharedService.showToast({ severity: 'success', summary: 'MetaData updated', detail: 'Document metadata has been updated successfully.' });
                                this.isAddCommItem(event.data);
                            },
                            error: (error) => {
                                event.context.gridContext._sharedService.clearToast();
                                this._sharedService.hideLoader();
                                event.context.gridContext._sharedService.showToast({ severity: 'error', summary: 'MetaData not updated', detail: 'Unable to update document metadata' });
                            }
                        });

                } catch (error) {
                    console.log(error);
                }
            }
        }


    }
    extractBlobReferenceFromURL(): string {
        let newString: string[] = [];

        newString = decodeURIComponent(this._sharedService.selectedDocument.Url).split('?')[0].split('/');

        return newString[newString.length - 1];
    }
    //#endregion


    //#StartRegion
    btnShowFeed(event: any) {
        if (!this.ViewSettingId) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No view Selected', detail: 'Please select a view.' });
            return;
        }
        this.showHideFeedDialogue(true);
    }

    showHideFeedDialogue(flag: boolean) {
        this.displayFeedDialog = flag;
    }
    containerForm
    OpenCopyFromModel() {
        this.containerModel = new SearchContainerModel("", null, null, false);
        this.updateCss();
        this.pageRange = null;
        this.docCopyFromGridData = [];
        this.conGridData = [];
        this.columnDefsCopyFrom = [];
        this.pageRange = null;
        this.displayCopyFromDlg = true;

    }
    updateCss() {
        let docCopyFromPanelHeader = document.getElementById('docCopyFromPanelHeader');
        if (docCopyFromPanelHeader) { docCopyFromPanelHeader.style.margin = '0px'; }

    }
    setDocumentGridColumnsForCopyFrom(category) {
        this.columnDefsCopyFrom = [];
        documentColumnDefs_CopyFrom = [];
        var documentGridCols: any[];
        var document_Col_Sort_On_By: string = '';
        var appConfigs = this._sharedService.getConfiguration();
        switch (category) {
            case 'MXR':
                this.columnDefsCopyFrom = [];
                documentColumnDefs_CopyFrom = [];
                documentColumnDefs_CopyFrom.push({ headerName: '', field: '', minWidth: 15, maxWidth: 25, checkboxSelection: true, headerCheckboxSelection: true, filter: false, sortable: false, menuTabs: [], colId: "checkboxcol" });
                documentGridCols = this.Document_MXR_Columns;
                document_Col_Sort_On_By = this.Document_MXR_Sort_By;
                this.docChartSettings = JSON.parse(appConfigs.filter(x => x.Key == 'Document_MX_Records_Chart_Settings')[0].Value);
                break;

            case 'LOG':
                this.columnDefsCopyFrom = [];
                documentColumnDefs_CopyFrom = [];
                documentColumnDefs_CopyFrom.push({ headerName: '', field: '', minWidth: 15, maxWidth: 25, checkboxSelection: true, headerCheckboxSelection: true, filter: false, sortable: false, menuTabs: [], colId: "checkboxcol" });
                documentGridCols = this.Document_LOG_Columns;
                document_Col_Sort_On_By = this.Document_LOG_Sort_By;
                this.docChartSettings = JSON.parse(appConfigs.filter(x => x.Key == 'Document_Log_Books_Chart_Settings')[0].Value);
                break;

            case 'GEN':
                this.columnDefsCopyFrom = [];
                documentColumnDefs_CopyFrom = [];
                documentColumnDefs_CopyFrom.push({ headerName: '', field: '', minWidth: 15, maxWidth: 25, checkboxSelection: true, headerCheckboxSelection: true, filter: false, sortable: false, menuTabs: [], colId: "checkboxcol" });
                documentGridCols = this.Document_GEN_Columns;
                document_Col_Sort_On_By = this.Document_GEN_Sort_By;
                this.docChartSettings = JSON.parse(appConfigs.filter(x => x.Key == 'Document_General_Chart_Settings')[0].Value);
                break;

            case 'DOC':
                this.columnDefsCopyFrom = [];
                documentColumnDefs_CopyFrom = [];
                documentColumnDefs_CopyFrom.push({ headerName: '', field: '', minWidth: 15, maxWidth: 25, checkboxSelection: true, headerCheckboxSelection: true, filter: false, sortable: false, menuTabs: [], colId: "checkboxcol" });
                documentGridCols = this.Document_DOC_Columns;
                document_Col_Sort_On_By = this.Document_DOC_Sort_By;
                this.docChartSettings = JSON.parse(appConfigs.filter(x => x.Key == 'Document_Documents_Chart_Settings')[0].Value);
                break;

            case 'BND':
                this.columnDefsCopyFrom = [];
                documentColumnDefs_CopyFrom = [];
                documentColumnDefs_CopyFrom.push({ headerName: '', field: '', minWidth: 15, maxWidth: 25, checkboxSelection: true, headerCheckboxSelection: true, filter: false, sortable: false, menuTabs: [], colId: "checkboxcol" });
                documentGridCols = this.Document_BND_Columns;
                document_Col_Sort_On_By = this.Document_BND_Sort_By;
                this.docChartSettings = JSON.parse(appConfigs.filter(x => x.Key == 'Document_Binders_Chart_Settings')[0].Value);
                break;

            default: //MXR
            case 'MXR':
                this.columnDefsCopyFrom = [];
                documentColumnDefs_CopyFrom = [];
                documentColumnDefs_CopyFrom.push({ headerName: '', field: '', minWidth: 15, maxWidth: 25, checkboxSelection: true, headerCheckboxSelection: true, filter: false, sortable: false, menuTabs: [], colId: "checkboxcol" });
                documentGridCols = this.Document_MXR_Columns;
                document_Col_Sort_On_By = this.Document_MXR_Sort_By;
                break;
        }
        this.popupParent = document.body;
        documentGridCols.forEach((col, index) => {

            let _filter: any;
            _filter = 'set';

            if (col.FieldName == 'Date' || col.FieldName == 'EndDate' || col.FieldName == 'DocDate' || col.FieldName == 'CreatedOn' || col.FieldName == 'ModifiedOn') {
                _filter = 'agDateColumnFilter';
            }
            else if (col.FieldName == 'DocNo' || col.FieldName == 'DocNoExternal' || col.FieldName == 'ReferenceKey') {
                _filter = 'text';
            }
            else if (col.FieldName == 'Tags') {
                _filter = false;
            }

            // var chartConfig = this.docChartSettings.chartConfigurationList.find(x => x.FieldName == col.FieldName);
            let gridCol: any = {
                headerName: col.ColumnLabel,
                field: col.FieldName,
                width: col.Width,
                filter: _filter,
                tooltipField: col.FieldName,
                menuTabs: col.FieldName == 'Tags' ? [] : ['filterMenuTab'],
                sortable: col.FieldName == 'Tags' ? false : true,
                hide: !col.IsVisible
            };
            if (col.FieldName == 'Date' || col.FieldName == 'EndDate' || col.FieldName == 'DocDate' || col.FieldName == 'CreatedOn' || col.FieldName == 'ModifiedOn') {
                gridCol.filterParams =
                {
                    //comparator: this.dateComparater,
                    inRangeInclusive: 'true'
                }
                gridCol.valueFormatter = function (params: any) {
                    if (params.value != '' && params.value != null) {

                        let dateString = params.value.substring(0, 10);
                        return moment(dateString).format(dateFormatF.toUpperCase());
                    }
                }
            } else if (col.FieldName == 'Status') {
                gridCol.cellRenderer = this.statusCellRenderer;
                gridCol.filterParams = this.gridStatusFilterParams;
                gridCol.editable = this.canEditMetadataQualityStatus;
                if (this.canEditMetadataQualityStatus) {
                    gridCol.cellEditor = 'agRichSelectCellEditor';
                    gridCol.cellEditorParams = {
                        values: statusList.map(sts => sts.Name.trim()),
                        cellRenderer: this.statusCellRendererEdit
                    };
                }
            }
            else if (col.FieldName == 'Tags') {
                gridCol.cellRenderer = this.tagsCellRenderer;
            }

            if (col.FieldName == 'DocNo' && col.ContainerCategory == 'LOG') {
                //gridCol.comparator = this.DocNoComparator(valueA, valueB, nodeA, nodeB, isInverted)
                gridCol.comparator = function (valueA, valueB, nodeA, nodeB, isInverted) {
                    var currentNumeric = isNullOrUndefined(valueA) == false ? (valueA.replace(/\D/g, "")) : 0;
                    var nextNumeric = isNullOrUndefined(valueB) == false ? (valueB.replace(/\D/g, "")) : 0;

                    return Number(currentNumeric) < Number(nextNumeric) ? -1 : Number(currentNumeric) > Number(nextNumeric) ? 1 : 0;
                }
            }

            documentColumnDefs_CopyFrom.push(gridCol);
        });
        documentColumnDefs_CopyFrom.filter(x => x.headerName == '')[0].chartDataType = 'excluded'
        this.columnDefsCopyFrom = documentColumnDefs_CopyFrom;
        let sortOnCol = document_Col_Sort_On_By.split("||")[0];
        let orderBy = document_Col_Sort_On_By.split("||")[1];
        this.columnDefsCopyFrom.forEach(function (col, index) {
            if (col.field == sortOnCol) {
                this.columnDefsCopyFrom[index].sort = orderBy;
            }
        }.bind(this));

    }
    runGapAnalyzer() {
        let selectedContainer;
        let selectedContainerName;
        let selectedContainerASN;
        if (this._sharedService._ContainerGridSelectedRows.length > 0) {
            selectedContainer = this._sharedService._ContainerGridSelectedRows;
            selectedContainerName = selectedContainer[0].ContainerName;
            selectedContainerASN = selectedContainer[0].ASN;
        }
        else {
            selectedContainer = this._sharedService.selectedContainer
            selectedContainerName = selectedContainer.ContainerName;
            selectedContainerASN = selectedContainer.ASN;
        }
        let containerIDs = this.getSelectedCOntainerIDs(selectedContainer)
        let body: BatchGapAnalyzer = {
            ContainerIDs: containerIDs,
            ContainerName: selectedContainerName,
            ASN: selectedContainerASN,
            JobID: Math.random().toString(),
            ActiveChannel: this._sharedService.getGroupNameKey(this._channel),
            AccessToken: this._sharedService.GetToken()
        }
        this._containerService.runBatchGapAnalyzer(body)
            .subscribe({
                next: (response) => {
                    if (response == 'Success') {
                        this._sharedService.hideLoader();
                        this._sharedService.showToast({ severity: 'success', summary: 'success', detail: 'Gap Analysis started successfully' });

                    }
                    else {
                        this._sharedService.hideLoader();
                        this._sharedService.showToast({ severity: 'error', summary: 'error', detail: 'Error occurred, please try again later.' });

                    }
                },
                error: (error) => {
                    this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again later.' });
                    this._sharedService.hideLoader();
                }
            });
    }
    getSelectedCOntainerIDs(container: any) {
        let containerIDs = [];
        if (container.length > 0)
            container.forEach(element => {
                containerIDs.push(element.ContainerID)
            });
        else {
            containerIDs.push(container.ContainerID)
        }
        return containerIDs
    }

    btnCancelCopyFromDlgClick(containerForm: NgForm) {
        this.displayCopyFromDlg = false;
        if (containerForm != null) {
            containerForm.resetForm();
        }
        this.docCopyFromGridData = [];
        this.conGridData = [];
        this.columnDefsCopyFrom = [];
        this.pageRange = null;
    }
    //#endRegion
    calculateGridHeight() {

        if (this._router.url.indexOf('/status/') == -1 && this._router.url.indexOf('/repair/') == -1)
            setTimeout(() => {

                // Get the header element
                const headerElement = this.elRef.nativeElement.querySelector('#docPanelHeader');

                // Calculate header height (including padding and borders)
                const headerHeight = headerElement.offsetHeight;

                // Set the remaining height to the div below header
                const contentElement = this.elRef.nativeElement.querySelector('#documentGrid')
                const windowHeight = window.innerHeight;
                const remainingHeight = windowHeight - headerHeight - 111 + this.WOGapManualDialogHeight;
                this.renderer.setStyle(contentElement, 'height', `${remainingHeight}px`, RendererStyleFlags2.Important);
                this.WOGapManualDialogHeight = 0;
            }, 300);
    }


    showHideCommDialogue(value: Boolean) {
        this.isAddVisible = value
    }

    prepareAddCommObj(event: any, commTypeId: string) {
        let commTypeIdInNumber = Number(commTypeId);

        let url = this._router.url;
        let module = "Record";
        if (url.indexOf("/record/") > -1) {
            module = "Record";
        } else if (url.indexOf("/transition/") > -1) {
            module = "Transition";
        }
        this.browseDocumentObj =
        {
            CommTypeId: commTypeIdInNumber,
            ASN: event.ASN,
            Module: module,
            Subject: `${module} | ${event.DocType} | ${event.ASN} | ${this.selectedContainer.ContainerID} | ${event.SeqNo}`,
            Section: this.selectedContainer.ContainerID,
            Record: this.prepareRecordUrl(true, event.BlobName)
        };
        setTimeout(() => {
            this.showHideCommDialogue(true);
        }, 100);
    }
    prepareRecordUrl(isSlash: boolean, blobName: string): string {
        let url = '';
        if (isSlash)
            url = `/${this.selectedContainer.ContainerName}/${this.selectedContainer.ASN}/${this.selectedContainer.Type}/${this.selectedContainer.ID}/${blobName}`;
        else {
            url = `${this.selectedContainer.ContainerName}/${this.selectedContainer.ASN}/${this.selectedContainer.Type}/${this.selectedContainer.ID}/${blobName}`;
        }
        return url;
    }

    isAddCommItem(event: any, isContainStatusId: Boolean = false) {
        //validating either status is changed or not
        if (isContainStatusId) {
            let oldId = this.qualityStatusList.find(a => a.Name == this._sharedService.selectedDocument.Status).QualityStatusID
            if (oldId == event.Status)
                return;
        }
        if (event) {
            let url = this.prepareRecordUrl(false, event.BlobName)
            let status = isContainStatusId ? event.Status : this.qualityStatusList.find(a => a.Name == event.Status).QualityStatusID
            this._commService.isAddCommItem(url, 'Record', status).subscribe(commTypeId => {
                if (commTypeId) {

                    this.showHideCommDialogue(false);
                    this.prepareAddCommObj(event, commTypeId);
                }
            })
        }
    }
    //copymove documents code
    btnCloseDocumentsBatchDialog() {
        //write code to close documents batch dialog
        this.showCopyMoveDlg = false;

    }
    btnOpenDocumentsBatchDialog() {
        //write code to open documents batch dialog
        this.showCopyMoveDlg = true;
        this._sharedService.hideLoader();

    }
    btnOpenDocumentsBatchSkipLinkedDocsDlg() {
        //write code to open documents batch skip linked docs dialog
        this.btnCloseDocumentsBatchDialog()
        if (!this.isCopy) {
            this.showCopyMoveSkipLinkedDocsDlg = true;
            this._sharedService.hideLoader();
        }
        if (this.isCopy)
            this.btnProcessDocumentsBatch();
    }
    btnCloseDocumentsBatchSkipLinkedDocsDlg() {
        //write code to close documents batch skip linked docs dialog
        this.showCopyMoveSkipLinkedDocsDlg = false
    }
    btnProcessDocumentsBatch(isSkipReferencedDoc: boolean = false) {
        //write code to process documents batch
        var inputData = new CopyMoveDocumentInputEntity();
        inputData.IsSkipReferencedDoc = isSkipReferencedDoc;
        inputData.BlobNameList = this.batchBlobList;
        inputData.DestinationcontainerId = this.isFrom ? this.selectedContainer.ContainerID : this.selectedTargetContainer.ContainerID;
        inputData.SourceContainerId = this.isFrom ? this.selectedTargetContainer.ContainerID : this.selectedContainer.ContainerID;
        inputData.RecordType = this.recordType;
        inputData.PageRange = this.pageRange;
        inputData.IsCopy = this.isCopy;
        inputData.UserName = this._sharedService.getCurrentUserName();
        //inputData.TenantId would be initialized from server side before hitting to function App.
        this._azureBlobService.copyMoveBlobBatch(inputData).subscribe({
            next: (response) => {
                this._sharedService.hideLoader();
                this.btnCloseDocumentsBatchSkipLinkedDocsDlg();
                this._sharedService.clearToast();
                this._sharedService.showToast({
                    severity: "success",
                    summary: "Success",
                    detail:
                        "Copy/Move documents process has been started.",
                });
                this.displayMoveCopyDlg = false;
                this.displayCopyFromDlg=false;
            },
            error: (error) => {
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                this._sharedService.showToast({
                    severity: "error",
                    summary: "Error Occured",
                    detail: "Documents are not copy/moved, please try again.",
                });
            }
        });
    }

    //copymove documents code end
}

function getFilteredRowsByProperty() {

    // Get the current filter model
    const filterModel = _gridOptions.api.getFilterModel();
    if (Object.keys(filterModel).length === 0 || filterModel === null) {
        sharedService.bulkSelectDocuments(_gridOptions.api.getSelectedNodes().map(x => x.data));
    }
    else {
        // Find the filtered property and its filter value
        let filteredProperty = '';
        var filteredRowData: any[] = [];
        for (const property in filterModel) {
            if (filterModel.hasOwnProperty(property)) {
                filteredProperty = property;
                if (filterModel[filteredProperty].values.length > 0) {
                    for (let i = 0; i < filterModel[filteredProperty].values.length; i++) {
                        var filteredValue = filterModel[filteredProperty].values[i];
                        // Get the filtered rows based on the filtered property and filter value
                        const filteredRows = _gridOptions.api.getSelectedNodes().filter(node => {
                            if (node.data[filteredProperty] == filteredValue) {
                                filteredRowData.push(node.data);
                            }
                        });
                        //end of for loop for filtered values
                    }
                    //end of if filtered column has data
                }
                //end of if properties has filter property
            }
            //end of filter for loop
        }



        // Extract the filtered row data into an array
        sharedService.bulkSelectDocuments(filteredRowData)

        //   return filteredRowData;

    }


}