export class AzureSearchSynonym {
    constructor(
        public RowID?: number,
        public Type?: string,
        public Keyword?: string,
        public Synonym?: string,
        public OperationMode?: string
    ) { }

}

export interface SearchSynonymSetting {

    azureSearchSynonyms: AzureSearchSynonym[],
    searchDatabaseID: string,
    searchDatabaseList: any
}

export enum OperationMode {
    Add = 1,
    Edit = 2,
    Delete = 3
}