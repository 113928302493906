import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SharedService } from '../../../shared/services/shared.service';
import { azureSearchKeywords } from '../../../shared/services/AzureSearch/azure-search.keywords';

@Injectable()
export class BatchAutoSearchService {
    constructor(private _sharedService: SharedService, private http: HttpClient) {
    }

    getAzureAutoSearchData(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/AutoSearch/GetTaskQueryResult`, body,this._sharedService.getReqOptWithAccessToken());
    }
}