//Components
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy,ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//Services
import { SelectItem } from 'primeng/api';
import { SharedService } from '../../../shared/services/shared.service';
import { NgForm } from '@angular/forms';
import { GridOptions, GridApi } from "ag-grid-community";
import { DocumentTypeService } from '../../services/document-type/document-type.service';
import { DocumentTypeEntity } from '../../services/document-type/document-type';
import { Subscription } from 'rxjs';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
var _router: Router;
var assetID: string;
 
@Component({
    selector: 'document-type',
    templateUrl: './document-type.component.html',
    providers: [DocumentTypeService]
})
export class DocumentTypeComponent implements OnInit, OnDestroy {

    
    @Input() docTypeID: string;
    @ViewChild('mffocus') mffocus: ElementRef;

    DocTypeCategory: SelectItem[] = [];
    isAdd: boolean;
    btnlabel: string;
    isEditVisible: boolean = true;
    isAddVisible: boolean = true;
    display: boolean = false;
    dialogTitle: string;
    model: DocumentTypeEntity;
    isSubmitted: boolean = false;
    totalCount: string;

    gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[];
    public canAddEditDocType: boolean = false;
    public documentTypesURL: string = "/admin/documenttypes";
    public assetPath = "/admin/asset";
    private api: GridApi;
    private editDocTypeSub: Subscription;
    private addDocTypeSub: Subscription;
    private editFromMenuDocTypeSub: Subscription;
    public appHotkeys:  Hotkey[] = [];

    @Output()
    refreshGridEvent = new EventEmitter<boolean>();

    constructor(public sharedService: SharedService, private router: Router, private documentTypeService: DocumentTypeService, private _hotkeysService: HotkeysService) {
        if (!this.sharedService.isAuthUser()) {
            this.router.navigate(['login']);
            this.sharedService.SessionExpiredMessage();
        }
        else {
            _router = this.router;
            this.updateRoles();
            this.model = new DocumentTypeEntity();
            this.setHotKeys();
        }
    }
    setHotKeys() {

        let hotKeyObj : Hotkey = null;

        //Add document type
        hotKeyObj = new Hotkey('ctrl+a', (event: KeyboardEvent): boolean => {
            if(_router.url == "/admin/documenttypes")
            {
                this.showAddDialog();
            }
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

        //Edit document type
        hotKeyObj = new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
            if(_router.url == "/admin/documenttypes")
            {
                this.showEditDialog();
            }
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
        hotKeyObj = new Hotkey('enter', (event: KeyboardEvent): boolean => {
            if(_router.url == "/admin/documenttypes")
            {
                this.showEditDialog();
            }
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
    }

    ngOnInit() {
        if (this.canAddEditDocType) {
            this.editDocTypeSub = this.sharedService._editAdminDocType
            .subscribe({
                next: (response) => {
                    this.showEditDialog();
                },
                error: (error) => {
                    console.log(error)
                }
            });

            this.addDocTypeSub = this.sharedService._addAdminDocType
            .subscribe({
                next: (response) => {
                    this.showAddDialog();
                },
                error: (error) => {
                    console.log(error)
                }
            });

            this.editFromMenuDocTypeSub = this.sharedService._editFromMenuDocType
            .subscribe({
                next: (docTypeID:string) => {
                    this.docTypeID = null;
                    this.docTypeID = docTypeID;
                    this.showEditDialog();
                },
                error: (error) => {
                    console.log(error)
                }
            });
        }
    }

    updateRoles() {
        if (!this.sharedService.UserRole) {
            this.sharedService.updateRole();
        }

        this.canAddEditDocType = this.sharedService.UserRole.Admin_User;

    }

    initializeForm(DocTypeID: string): void {
        if (DocTypeID) {
            this.loadDocumentTypeData(DocTypeID);
        }
      
    }

    loadDocumentTypeData(DocTypeID: string): void {

        let userRole: string = localStorage.getItem('roles').trim();

        this.documentTypeService.getDocumentTypebyID(DocTypeID)
        .subscribe({
            next: (response) => {
                //   this.gridOptions.api.sizeColumnsToFit();
                this.model = response;
            },
            error: (error) => {
                console.log(error)
            }
        });
    }

    btnSubmitClick(form: NgForm) {
        this.changeButtonsStatus(true);
        //this.model.assetType = "Airframe";
        let docTypeModel = this.model;
        if (docTypeModel.DocumentTypeID != null && docTypeModel.DocumentTypeID != "0") {
            this.updateDocType(docTypeModel, form);
        }
        else {
            this.addDocType(docTypeModel, form);
        }


    }


    addDocType(body: DocumentTypeEntity, form: NgForm) {
        this.sharedService.showLoader("Processing...");
        this.documentTypeService.addDocumentType(body)
        .subscribe({
            next: (response) => {
                this.sharedService.hideLoader();
                this.display = false;
                this.resetModel();
                form.resetForm();
                this.sharedService.clearToast();
                this.sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Document Type has been added succesfully.' });
                this.changeButtonsStatus(false);
                this.refreshGridEvent.emit(true);
                //this.returntoAction(this.documentTypesURL);                
            },
            error: (error) => {
                this.sharedService.hideLoader();
                this.sharedService.clearToast();
                if (error.status === 409) {
                    this.sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Document Type already exists.' });
                }
                else {
                    this.sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Document Type not added, please try again.' });
                }
                this.display = false;
                this.resetModel();
                this.changeButtonsStatus(false);                
            }
        });
    }

    updateDocType(body: DocumentTypeEntity, form: NgForm) {
        this.sharedService.showLoader("Processing...");
        this.documentTypeService.updateDocumentType(body)
        .subscribe({
            next: (response) => {
                this.sharedService.hideLoader();
                this.display = false;
                this.resetModel();
                form.resetForm();
                this.sharedService.clearToast();
                this.sharedService.showToast(
                    {
                         severity: 'success',
                         summary: 'Success', 
                         detail: 'Document Type updated succesfully.' 
                    }
                );
                this.changeButtonsStatus(false);
                this.refreshGridEvent.emit(true);
                //this.returntoAction(this.documentTypesURL);                
            },
            error: (error) => {
                this.sharedService.hideLoader();
                this.sharedService.clearToast();
                if (error.status === 409) {
                    this.sharedService.showToast(
                        {
                             severity: 'error',
                             summary: 'Already Exists', 
                             detail: 'Document Type already exists.' 
                        }
                    );
                }
                else {
                    this.sharedService.showToast(
                        {
                             severity: 'error',
                             summary: 'Error Occured', 
                             detail: 'Asset not updated, please try again.' 
                        }
                    );
                }
                this.display = false;
                this.resetModel();
                this.changeButtonsStatus(false);                
            }
        });
    }



    btnCancelClick(cancelForm: NgForm) {
        this.display = false;
        cancelForm.resetForm();
        this.model = new DocumentTypeEntity();
    }

    returntoAction(url: string) {

        setTimeout(() => {

            this.router.navigateByUrl(url);

        },
            2000);
    }

    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }

    resetModel() {
        this.model = new DocumentTypeEntity();

    }



    handleSelectedDoc(): void {

        var rowCount = this.api.getSelectedNodes().length;
        if (rowCount > 0) {
            var lastRowData = this.api.getSelectedNodes()[rowCount - 1].data;
            assetID = lastRowData.AssetID;
        }
    }

    showEditDialog(): void {

        this.model = new DocumentTypeEntity();
        if (this.docTypeID) {
            this.isAdd = false;
            this.dialogTitle = "Document Type > Edit";
            this.btnlabel = "Update";
            this.display = true;
            this.initializeForm(this.docTypeID);
            //assetID = null;
        }
        else {
            this.sharedService.clearToast();
            this.sharedService.showToast({ severity: 'warn', summary: 'No Document Type selected', detail: 'Please select Document Type' });
        }
    }
    ngOnDestroy() {
        if (this.editDocTypeSub) {
            this.editDocTypeSub.unsubscribe();
        }
        if (this.addDocTypeSub) {
            this.addDocTypeSub.unsubscribe();
        }
        if (this.editFromMenuDocTypeSub) {
            this.editFromMenuDocTypeSub.unsubscribe();
        }

        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
    }
    showAddDialog(): void {
        this.model = new DocumentTypeEntity();
        this.isAdd = true;
        this.dialogTitle = "Document Type > Add";
        this.btnlabel = "Add";
        this.display = true;
    }

    applyFocusToInput(){
        this.mffocus.nativeElement.focus();
      }
}