
//Components
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
//Services
import { SharedService } from '../../../shared/services/shared.service';
import { MenuService } from '../../../shared/services/Menu/menu.service';
//interfaces
import { IMenu } from '../../../shared/services/Menu/menu';
import { NgForm } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { UserPersonalInfo } from '../../../admin/components/user-management/user-management';
import { ChangePasswordModel, IChangePasswordModel } from '../../../shared/services/Menu/change-password'
import { UserService } from '../../../records/services/User/user.service';
import { SettingsService } from 'src/app/admin/services/settings/setting.service';
import { ImageCropperComponent } from '../../image-cropper/image-cropper.component';
import { ImageCroppedEvent } from "ngx-image-cropper";
import { userInfo } from 'os';
import Cropper from 'cropperjs';
import { ViewChild } from '@angular/core';
import { state } from '@angular/animations';
import { elementAt } from 'rxjs/operators';
import { HomeDashboardSettings } from 'src/app/records/components/home/WidgetSetting';
@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MenuService, UserService, SettingsService, UserPersonalInfo]
})

export class MenuComponent implements OnInit,OnDestroy {

  //#region Gloabal Variables


  public croppable: Boolean = false;
  private cropper: Cropper;
  public ImageDestination: string;
  CroppedCanvas: any;
  RoundCanvas: any;
  img: any;
  greetinglabel: string;
  name: string;
  tenantName: string;
  menuList: IMenu[];
  parentMenuList: IMenu[];
  childMenuList: IMenu[];
  display: boolean = false;
  displayPersonalInfoDlg: boolean = false;
  model: ChangePasswordModel;

  isSubmitted: boolean = false;
  routeInfoSub: Subscription;
  routeInfoDashboardSub: Subscription;
  isMenuVisible: boolean = false;
  isDashboardVisible: boolean = true;
  tenantLogo: string;
  subscription: Subscription;
  SignaturefileData: File = null;
  SignaturepreviewUrl: any = null;
  ProfilefileData: File = null;
  ProfilepreviewUrl: any;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  imageSource: string = null;
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  imgChangeEvt: any = "";
  imgChageEvtSig: any = "";
  cropImgPreview: any = "";
  cropImgSigPreview: any = "";
  showSignature: boolean = false;
  showProfile: boolean = false;
  LastLoggedIn: string = "";
  ExpiryDate: string = "";
  DisplayProfileDlg: boolean = false;
  DisplaySignatureDlg: boolean = false;
  SignatureCropperImg: any;
  ProfileCropperImg: any;
  checkprofileEmpty: string = "";
  checkSignatureEmpty: string = "";
  tempPhoneNumber: string;
  scale: number = 1;
  isProfileSubmit: boolean = false;
  isSignatureSubmit: boolean = false;
  showdummyProfileImage: boolean = true;
  showdummySignatureImage: boolean = true;
  @ViewChild('takeInput')
  InputVar: ElementRef;
  @ViewChild('takeInput2')
  InputVarSig: ElementRef;
  redirectIfValueIsChanged: boolean;
  parentMenuChangedSubscription$: Subscription;

  //#region Initialization of Component
  constructor(public _sharedService: SharedService, private cRef: ChangeDetectorRef, private _menuService: MenuService, private _router: Router, private _activatedRoute: ActivatedRoute, private _userService: UserService, private _settingService: SettingsService, public _userInfo: UserPersonalInfo, public _DomSanitizationService: DomSanitizer) {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(['login']);
      this._sharedService.SessionExpiredMessage();
    }
    _userInfo.FirstName = localStorage.getItem('firstName');
    _userInfo.LastName = localStorage.getItem('lastName');
    _userInfo.Email = localStorage.getItem('userName');
    this._router.events.subscribe((val) => {
      let elem: HTMLElement = document.getElementById('menudotsinner');
      if (elem) {
        elem.setAttribute("style", "display:none !important;");
        this.cRef.markForCheck();
      }
      //this.OpenCloseMenu(false);
    });
    this.parentMenuChangedSubscription$ = this._sharedService
      ._menuIDFromParentMenu$
      .subscribe((menuId: number) => {
        this.PrepareSidebarMenu();        
    })

  }
  public isNextButton = false
  public isNext = false
  public statusTotalcount;
  ngOnInit() {

    if (this._router.url.indexOf("/login") != 0) {
      this._userService.GetUserByEmail(this._sharedService.baseUrl, this._userInfo)
        .subscribe({
          next: (response) => {
            this._sharedService.hideLoader();

            var responseData = response;
            this.SignaturepreviewUrl = this._DomSanitizationService.bypassSecurityTrustUrl(responseData.SignatureImage);
            this.ProfilepreviewUrl = this._DomSanitizationService.bypassSecurityTrustUrl(responseData.ProfileImage);
            this.SignatureCropperImg = this._DomSanitizationService.bypassSecurityTrustUrl(responseData.SignatureImage);
            this.ProfileCropperImg = this._DomSanitizationService.bypassSecurityTrustUrl(responseData.ProfileImage);
            this._userInfo.FirstName = responseData.FirstName;
            this._userInfo.LastName = responseData.LastName;
            this._userInfo.Email = responseData.Email;
            this._userInfo.PhoneNumber = responseData.PhoneNumber;
            this.tempPhoneNumber = this._userInfo.PhoneNumber;
            this.name = this._userInfo.FirstName + " " + this._userInfo.LastName;
            localStorage.setItem('tempPhoneNumber', this.tempPhoneNumber);
            if (this.ProfilepreviewUrl != null) {
              this.checkprofileEmpty = this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
              localStorage.setItem('checkprofileEmpty', this.checkprofileEmpty);
              localStorage.setItem('ProfilepreviewUrl', this.ProfilepreviewUrl);
            }
            if (this.SignaturepreviewUrl != null) {
              this.checkSignatureEmpty = this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
              localStorage.setItem('checkSignatureEmpty', this.checkSignatureEmpty);
              localStorage.setItem('SignaturepreviewUrl', this.SignaturepreviewUrl);
            }
            //this.LastLoggedIn=responseData.LastIP;
            this.ExpiryDate = responseData.ExpiryDate;
            this.cRef.markForCheck();
            this._sharedService.clearToast();
          },
          error: (error) => {
            this._sharedService.hideLoader()
            this._sharedService.clearToast();
            // this._sharedService.showToast({ severity: 'error', summary: 'Error Occurred', detail: 'User is not updated.' });
            this.isSubmitted = false;
          }
        });

      this._userService.getLoggedInUserIP()
        .subscribe({
          next: (response) => {
            //console.log('Current IP: ' + response);

            let currentIP = response;
            this.LastLoggedIn = currentIP;
          },
          error: (error) => {
            console.log(error)
          }
        });

    }
    this.subscription = this._sharedService._statusMenuButtons
      .subscribe({
        next: (isNext: any) => {
          this.isNext = false
          // this.isNextButton=isNext;
          this.cRef.markForCheck();
          
          if (isNext && this.statusTotalcount > 0) {
            this.isNext = isNext;
            this.isNextButton = true
          }
          else {
            this.isNextButton = false
            this.isNext = false
          }
          if (isNext) {
            this.isNext = true

          }
          this.cRef.markForCheck();
        },
        error: (error) => {
          console.log(error)
        }
      });

    this.subscription = this._sharedService._statusMenuCount
      .subscribe({
        next: (countObj: any) => {
          this.statusTotalcount = 0;
          if (this.isNext && countObj.totclcount > 0) {
            this.statusTotalcount = countObj.totclcount;
            this.isNextButton = true
          }
          else {
            this.isNextButton = false
          }
          if (countObj.totclcount > 0) {
            this.statusTotalcount = countObj.totclcount;
          }
          this.cRef.markForCheck();
        },
        error: (error) => {
          console.log(error)
        }
      });

    this.isDashboardVisible = this.shouldDashboardVisible();
    this.setGreetings();
    this.display = false;
    this.displayPersonalInfoDlg = false;
    this.resetModel();
    if (this.isDashboardVisible) { this.getTenantLogo(); }

    this.populateMenu();

    this.routeInfoSub = this._sharedService._routeInfo
      .subscribe({
        next: (routeInfo: any) => {
          this.parentClick(routeInfo);
          this.cRef.markForCheck();
        },
        error: (error) => {
          console.log(error)
        }
      });

    this.routeInfoDashboardSub = this._sharedService._routeInfoDashboard
      .subscribe({
        next: (routeInfo: any) => {
          this.parentClick(routeInfo);
          this.cRef.markForCheck();
        },
        error: (error) => {
          console.log(error)
        }
      });

    this._sharedService._isTabViewChange
      .subscribe({
        next: (valueChangeData) => {
          this.redirectIfValueIsChanged = valueChangeData;
        },
        error: (error) => {
          console.log(error)
        }
      });

  }
  shouldDashboardVisible(): boolean {
    if (
      this._sharedService.getSubdomain().toLowerCase() == this._sharedService.Tenant_Help ||
      this._sharedService.getSubdomain().toLowerCase() == this._sharedService.Tenant_Internal
    ) {
      return false;
    }
    else {
      return true;
    }
  }
  getTenantLogo() {
    this._settingService.getConfigurationValueByKey("Tenant_Logo")
      .subscribe({
        next: (response) => {
          this.tenantLogo = response;
          this.cRef.markForCheck();
        },
        error: (error) => {
          console.log(error)
          this._router.navigate(['login']);
        }
      });

  }
  ngAfterViewInit() {
    this.checkForMenus();
  }
  checkForMenus() {
    try {
      if (SharedService.current_ParentMenu_Id == 113
        || SharedService.current_ParentMenu_Id == 16) {
        this._sharedService.showHideRecordMenu();
      } else if (SharedService.current_ParentMenu_Id == 22
        || SharedService.current_ParentMenu_Id == 99) {
        this._sharedService.showHideStatusMenu();        
      }
      
      this.cRef.markForCheck();
    } catch (e) {
      console.log(e);
    }   
  }

  ngOnDestroy() {
    if (this.routeInfoSub) {
      this.routeInfoSub.unsubscribe();
    }
    if (this.routeInfoDashboardSub) {
      this.routeInfoDashboardSub.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.parentMenuChangedSubscription$) {
      this.parentMenuChangedSubscription$.unsubscribe();
    }
  }

  setGreetings() {
    //Set User Name
    this.name = this._sharedService.getCurrentUserName();
    this._userInfo.UserName = localStorage.getItem('userName');
    //Set Greeting Label
    this.greetinglabel = this._sharedService.getGreetingLabel() + ', ';
    //Set Tenant Name
    this._userService.getLoggedInTenantName(this._sharedService.baseUrl, null)
      .subscribe({
        next: (response) => {
          this.tenantName = response;
          this._sharedService.tenantName = this.tenantName;
          this.cRef.markForCheck();
        },
        error: (error) => {
          console.log(error)
          this._router.navigate(['login']);
        }
      });
  }
  private navToDashboardURL = null
  navToDashboard() {
    this.setParentColor({ ColorCode: 'bg-color-black' })
    
    this._router.navigateByUrl("dashboard/record");
    this.navToDashboardURL = "dashboard/record";
    this.prepareMenuObject();
    if (this.redirectIfValueIsChanged) {
      // this.prepareDashboardMenu();
    }

    this.cRef.markForCheck();
  }

  populateMenu() {

    this.menuList = this._sharedService.getMenuList();
    //Get Menu from Local Stroage
    if (this.menuList && this.menuList.length > 0) {
      this.prepareMenuObject();
    } else {
      //If Menu not exist in Local Storage Load from DB
      let userRole: string = localStorage.getItem('roles');
      let body = { UserRole: userRole.trim() };
      let menuData: any = this._menuService.getUserMenu(this._sharedService.baseUrl, body)
        .subscribe({
          next: (response) => {
            this.menuList = response;
            this._sharedService.setMenuList(this.menuList);
            this.prepareMenuObject();
            this.cRef.markForCheck();
          },
          error: (error) => {
            console.log(error)
            this._router.navigate(['login']);
          }
        });
    }
  }

  updateMenuURLsForStatusViews(menuList: any[]) {
    let updatedMenuList: any[] = [];
    menuList.forEach(_menu => {
      if (_menu.MenuID < 0 && _menu.PageURL.indexOf('/status/dashboard/') != -1) {
        _menu.PageURL = _menu.PageURL.replace('/status/dashboard/', '/status/viewlist/');

      } else if (_menu.MenuID < 0 && _menu.PageURL.indexOf('/repair/dashboard/') != -1) {
        _menu.PageURL = _menu.PageURL.replace('/repair/dashboard/', '/repair/viewlist/');

      }
      updatedMenuList.push(_menu);
    });
    return updatedMenuList;
  }

  prepareMenuObject() {
    this.PrepareSidebarMenu();
    this.cRef.markForCheck()
  }
  public homeDashboardSettings: HomeDashboardSettings = new HomeDashboardSettings();

  prepareDashboardMenu() {
    
    let homeDashboardSetting = localStorage.getItem("HomeDashboardSettings");
    if (homeDashboardSetting != "") {
      this.homeDashboardSettings = JSON.parse(homeDashboardSetting);

      this.childMenuList = this.childMenuList ? this.childMenuList : [];

      this.homeDashboardSettings.dashboardPageSettings.sort((a, b) =>
        a.DisplayOrder - b.DisplayOrder);
      if (!(this.childMenuList && this.childMenuList.length > 0)) {
        for (let index = 0; index < this.homeDashboardSettings.dashboardPageSettings.length; index++) {
          const element = this.homeDashboardSettings.dashboardPageSettings[index];
          let obj = {
            Title: element.pageName,
            DisplayOrder: element.DisplayOrder,
            PageURL: `/dashboard/${element.routePath}`,
            UpdatedPageURL: null,
            ToolTip: null,
            IconClass: "fa fa-gauge-high",
            MenuID: null,
            Status: null,
            OpenInNewTab: null,
            Parent: -1,
            UserRole: null,
            ColorCode: null,
            OnClick: null,
            IsDashboardSubMenu: true
          }
          this.childMenuList.push(obj)
        }        
      } else {
        const moduleDashboard = this.homeDashboardSettings
          .dashboardPageSettings
          .find(a => a.selectedModuleId == SharedService.previous_ParentMenu_Id);
        if (!moduleDashboard) return;
        let obj = {
          Title: 'Dashboard',
          DisplayOrder: 0,
          PageURL: `/dashboard/${moduleDashboard.routePath}`,
          UpdatedPageURL: null,
          ToolTip: null,
          IconClass: "fa fa-gauge-high",
          MenuID: null,
          Status: null,
          OpenInNewTab: null,
          Parent: null,
          UserRole: null,
          ColorCode: null,
          OnClick: null,
          IsDashboardSubMenu: false
        }
        this.childMenuList.unshift(obj);
      }
    }
  }
  setParentMenuDefaultRoute() {  
    
    let homeDashboardSetting = localStorage.getItem("HomeDashboardSettings");
    if (homeDashboardSetting == "") return;
    
    let homeDashboardSettings = JSON.parse(homeDashboardSetting);

    this.parentMenuList.forEach(a => {
      let moduleDashboard = homeDashboardSettings
        .dashboardPageSettings
        .find(b => b.selectedModuleId == a.MenuID);
      
      if (!moduleDashboard) return;
    
      if (a.MenuID == moduleDashboard.selectedModuleId)
      {
        a.UpdatedPageURL = `/dashboard/${moduleDashboard.routePath}`;
      }
      
    });
  }

  getSelectedClass(): string {
    if (this._router.url.indexOf('help/helparticles') == -1) {
      return 'menuFirstChild';
    }
    else {
      return 'menuMain';
    }
  }
  // getParentModuleId() {
  //   let currentURL: string = this._router.url;
  //   let parentId: number = this.parentMenuList.find(a => a.PageURL == currentURL && !a['IsDashboardSubMenu'])?.MenuID;
  //   if (parentId) this._sharedService.Previous_ParentMenu_Id = parentId;
    
  //   return parentId ?? this._sharedService.Previous_ParentMenu_Id;
  // }
  setDefaultParentMenu() {
    let obj:IMenu = {
      Title: 'Dashboard',
      DisplayOrder: 0,
      PageURL: `/dashboard/record`,
      UpdatedPageURL: null,
      ToolTip: null,
      IconClass: "fa fa-gauge-high",
      MenuID: -1,
      Status: null,
      OpenInNewTab: null,
      Parent: 0,
      UserRole: null,
      ColorCode: 'bg-color-black',
      OnClick: null,
    };
    this.parentMenuList.unshift(obj);
  }
  PrepareSidebarMenu() {
    //Parent Menu
    
    this.parentMenuList = [];
    // deep copy of array
    this.parentMenuList = JSON.parse(JSON.stringify(this.menuList.filter(item => item.Parent == 0))) ;
    this.setDefaultParentMenu();

    //1st Level Children
    
    this.setParentMenuDefaultRoute();
    
    let parentId = SharedService.current_ParentMenu_Id;
    this.childMenuList = this.menuList.filter(item => item.Parent > 0 && item.Parent == parentId );
    
    // prepare dashboards menus for dashboard module or other relevent modules.
    this.prepareDashboardMenu()
    
    if (!this.childMenuList) return;

    // 2nd level children
      
    for (let i = 0; i < this.childMenuList.length; i++) {
      let children = this.menuList.filter(item => item.Parent > 0 && item.Parent == this.childMenuList[i].MenuID);
      if (children.length > 0) {
        this.childMenuList[i].Childs = children;

        if (this.childMenuList[i].PageURL) {
          this.childMenuList[i].PageURL = this.childMenuList[i].PageURL;
        }
        else {
          this.childMenuList[i].PageURL = children[0].PageURL;
        }
      }
    }
    
    this.cRef.detectChanges(); 
    //      this._router.url == '/record/browse'
    //   || this._router.url == '/record/logs'
    //   || this._router.url == '/record/general'
    //   || this._router.url == '/record/documents'
    //   || this._router.url == '/transition/binders'
    if (SharedService.current_ParentMenu_Id == 113
      || SharedService.current_ParentMenu_Id == 16) {
      this._sharedService.showHideRecordMenu();
    } else if (SharedService.current_ParentMenu_Id == 22
      || SharedService.current_ParentMenu_Id == 99) {
      this._sharedService.showHideStatusMenu();
    }

    this.setParentColor({ ColorCode: 'bg-color-black' });
  }

  setParentColor(selectedParentMenu: any) {
    let menudots = document.getElementById('menudots');
    let colorCode = "";
    if (selectedParentMenu) {
      colorCode = selectedParentMenu.ColorCode.trim();
      menudots.className = "";
      menudots.classList.add('mainmenu', colorCode);
      this.cRef.markForCheck();
    }
  }

  onPanalsClick(event: any) {
    if (this._router.url.indexOf('/status/viewlist') == -1) {
      this._sharedService.onGridRowClick(true);
    } else {
      this._sharedService.onGridRowClickStatus(true);
    }
  }

  //#endregion

  //#region Parent Menu Click Logic
  parentClick(parent: any) {

    //this.OpenCloseMenu(false);
    this._sharedService.clearToast();
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(['login']);
      this._sharedService.SessionExpiredMessage();
      return
    }
    
    var isAllowed = this.isMenuAllowedInAssetPlan(parent);
    if (isAllowed && this.menuList && this.menuList.length > 0) {
      if (!this._sharedService.isUnsavedChanges ||
        this._router.url == (parent.UpdatedPageURL ?? parent.PageURL)) {
        this._sharedService.onParentMenuChanged(parent.MenuID);
        this._sharedService.next_not_navigated_ParentMenu_Id = null;
      } else {
        this._sharedService.next_not_navigated_ParentMenu_Id = parent.MenuID;
      }
        

      //if (this._router.url != '/record' && parent.PageURL != '/record') { //For Dashboard

      let activeMenuItem = this.menuList.filter(item => item.PageURL == parent.PageURL);
      if (activeMenuItem && activeMenuItem.length > 0) {
        activeMenuItem[0].AdditionalData = activeMenuItem[0].AdditionalData;
      }
      // this.parentMenuList = this.menuList.filter(item => item.Parent == 0);

      // if (this.redirectIfValueIsChanged == true) {

      //   this.childMenuList = this.menuList.filter(item => item.Parent == parent.MenuID);

      //   for (let childMenuElement of this.childMenuList) {

      //     if (this.menuList.filter(item => item.Parent == childMenuElement.MenuID).length > 1) {
      //       childMenuElement.Childs = [];
      //       childMenuElement.Childs = this.menuList.filter(item => item.Parent == childMenuElement.MenuID);
      //     }
      //   }
      // }

      //} else {
      //    this.prepareMenuObjectForDashboard();
      //}
      this._router.navigate([parent.UpdatedPageURL ?? parent.PageURL]).finally((op?) => {
        setTimeout(() => {
          this.setParentColor({ ColorCode: parent.ColorCode });
          this._sharedService.parentMenuClicked = true;
          // this.prepareMenuObject();
        }, 40);
      });
    }
    else {
      this._sharedService.clearToast();
      var notAllowedMsg = 'Access denied';
      if (this._sharedService.selectedAsset && this._sharedService.selectedAsset.length > 0 && this._sharedService.selectedAsset[0].data) {
        let assetDataObj = JSON.parse(this._sharedService.selectedAsset[0].data);
        notAllowedMsg = 'This asset is on ' + assetDataObj.PlanName + ', please contact administrator to upgrade plan for this asset.';//PlanName
      }
      //this._sharedService.showToast({ severity: 'warn', summary: 'Access denied', detail: notAllowedMsg });
      // setTimeout(() => {
      //     this._sharedService.showToast({ severity: 'warn', summary: 'Access denied', detail: notAllowedMsg });
      //     console.log("Delayed menu.");
      // }, 500);

      this._router.navigate([parent.UpdatedPageURL ?? parent.PageURL]).finally((op?) => {
        setTimeout(() => {
          this.setParentColor({ ColorCode: parent.ColorCode });
          this._sharedService.parentMenuClicked = true;
          // this.prepareMenuObject();
          if (parent.MenuID == 16 // records module
            || parent.MenuID == 113 // transition module
          ) {
            this._sharedService.showHideRecordMenu();
          } else if (parent.MenuID == 22 // status module
            || parent.MenuID == 99 // repairs module
          ) {
            this._sharedService.showHideStatusMenu();
          }
        }, 40);
      });
    }
  }
  //#endregion


  isMenuAllowedInAssetPlan(selectedMenu: any): boolean {
    let isAllowed: boolean = false;
    if (selectedMenu.UserRole == null || selectedMenu.UserRole.trim() == '') {
      isAllowed = true;
    }
    else {
      this.updateRoles();
      isAllowed = this._sharedService.UserRole[selectedMenu.UserRole];
      //Ticket 8833: Records> Select an Asset of Plan-A> Go-to Main Dashboard> Click Status/Transition/Repairs warring will appear in this case don't display the warring message move to the selected module and unselect (Select-Asset) option.
      // if (!isAllowed) {
      //     // this._sharedService.onAssetChanged(null);
      //     // this._sharedService.onAssetStatusChanged(null);
      //     isAllowed = true;
      // }
    }
    return isAllowed;
  }

  updateRoles() {
    this._sharedService.updateRole();
  }

  //#region CHange Password
  showChangePswdDialogue() {
    this.resetModel();
    this.display = true;
    this.displayPersonalInfoDlg = false;
    this.OpenCloseMenu(false);
    this.cRef.markForCheck();
  }
  showp() {
    console.log("onshow call");
  }
  loadform() {
    if (this._router.url.indexOf("/record") != -1) {
      this._userService.GetUserByEmail(this._sharedService.baseUrl, this._userInfo)
        .subscribe({
          next: (response) => {
            this._sharedService.showLoader("Processing...");

            var responseData = response;
            this.SignaturepreviewUrl = this._DomSanitizationService.bypassSecurityTrustUrl(responseData.SignatureImage);
            this.ProfilepreviewUrl = this._DomSanitizationService.bypassSecurityTrustUrl(responseData.ProfileImage);
            this.SignatureCropperImg = responseData.SignatureImage;//this._DomSanitizationService.bypassSecurityTrustUrl(responseData.SignatureImage);
            this.ProfileCropperImg = this.ProfilepreviewUrl;
            this._userInfo.Email = responseData.Email;
            this._userInfo.PhoneNumber = responseData.PhoneNumber;
            this.tempPhoneNumber = this._userInfo.PhoneNumber;
            if (this.ProfilepreviewUrl != null)
              this.checkprofileEmpty = this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
            if (this.SignaturepreviewUrl != null)
              this.checkSignatureEmpty = this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
            //this.LastLoggedIn=responseData.LastIP;
            this.ExpiryDate = responseData.ExpiryDate;
            this._sharedService.hideLoader();
            this.cRef.markForCheck();
            this._sharedService.clearToast();
          },
          error: (error) => {
            this._sharedService.hideLoader();
            this._sharedService.clearToast();
            // this._sharedService.showToast({ severity: 'error', summary: 'Error Occurred', detail: 'User is not updated.' });
            this.isSubmitted = false;
          }
        });
    }
  }
  showUserProfileDialogue() {

    this.display = false;
    this.showProfile = false;
    this.showSignature = false;
    this.displayPersonalInfoDlg = true;
    this.OpenCloseMenu(false);
    this.cRef.markForCheck();
    // this._userInfo.FirstName=localStorage.getItem('firstName');
    //this._userInfo.LastName=localStorage.getItem('lastName');
    // this._userInfo.PhoneNumber=localStorage.getItem('tempPhoneNumber');
    setTimeout(function () {
      // this.ProfilepreviewUrl=localStorage.getItem('ProfilepreviewUrl');
      //this.btnproLoadImage();
      // this.ProfilepreviewUrl=this._DomSanitizationService.bypassSecurityTrustResourceUrl(this.ProfilepreviewUrl);
      //this.SignaturepreviewUrl=localStorage.getItem('SignaturepreviewUrl');//this._DomSanitizationService.bypassSecurityTrustResourceUrl(localStorage.getItem('SignaturepreviewUrl'));
      // this.checkSignatureEmpty=localStorage.getItem('checkSignatureEmpty'); 
      // this.checkprofileEmpty=localStorage.getItem('checkprofileEmpty'); 
      // this.ProfilepreviewUrl=this._DomSanitizationService.bypassSecurityTrustUrl(localStorage.getItem('ProfilepreviewUrl')); 
      // this.SignaturepreviewUrl=this._DomSanitizationService.bypassSecurityTrustUrl(localStorage.getItem('SignaturepreviewUrl'));
      // setTimeout(function () {
      // document.getElementById("btn").click();
    }, 1000);

  }
  showDialogue() {
    this.resetModel();
    this.display = true;
    this.OpenCloseMenu(false);
    this.cRef.markForCheck();
  }
  onSubmit(form: NgForm) {
    this.changeButtonsStatus(true);
    this.display = false;
    this.displayPersonalInfoDlg = false;
    let body: IChangePasswordModel =
    {
      OldPassword: this.model.oldPassword,
      NewPassword: this.model.newPassword,
      ConfirmPassword: this.model.confirmPassword,
    }

    this._menuService.changePassword(this._sharedService.baseUrl, body)
      .subscribe({
        next: (response) => {
          this.display = false;
          if (form) { form.resetForm(); }
          this.resetModel();
          this._sharedService.clearToast();
          this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Password has been changed.' });
          this.changeButtonsStatus(false);
          this.OpenCloseMenu(false);
          this.cRef.markForCheck();
        },
        error: (error) => {
          this._sharedService.clearToast();

          this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Current password is different than existing password.' });

          this.display = false;
          this.resetModel();
          this.changeButtonsStatus(false);
          this.OpenCloseMenu(false);
          this.cRef.markForCheck();
        }
      });
  }
  onSubmitProfile(form: NgForm) {

    // if(this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity==undefined){
    //     if(this.ProfilepreviewUrl==undefined || this.ProfilepreviewUrl==""){
    //         this.ProfilepreviewUrl="";
    //     }
    // }
    // if(this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity==undefined){
    //     if(this.SignaturepreviewUrl==undefined || this.SignaturepreviewUrl==""){
    //         this.SignaturepreviewUrl="";
    //     }
    // }

    this.changeButtonsStatus(true);

    let body: UserPersonalInfo = {
      FirstName: this._userInfo.FirstName,
      LastName: this._userInfo.LastName,
      Email: this._userInfo.Email,
      UserName: this._userInfo.UserName,
      PhoneNumber: this._userInfo.PhoneNumber,
      ProfileImage: this._userInfo.ProfileImage,
      SignatureImage: this._userInfo.SignatureImage
    }

    this._userService.UpdateUser(this._sharedService.baseUrl, body)
      .subscribe({
        next: (response) => {
          this._sharedService.hideLoader();
          this.isSubmitted = false;
          this._sharedService.clearToast();
          this.LoadDataForCropper();
          this._sharedService.showToast({ severity: 'success', summary: 'Update Successful', detail: 'User has been updated successfully.' });
        },
        error: (error) => {
          this._sharedService.hideLoader()
          this._sharedService.clearToast();
          this._sharedService.showToast({ severity: 'error', summary: 'Error Occurred', detail: 'User is not updated.' });
          this.isSubmitted = false;
        }
      })

    this.display = false;
    this.displayPersonalInfoDlg = false;
    this.DisplaySignatureDlg = false;
    this.DisplayProfileDlg = false;
  }
  resetModel() {
    this.model = new ChangePasswordModel(null, null, null);
  }
  btnCancelClicks(cancelForm: NgForm) {
    this.changeButtonsStatus(false);
    //this.loadform();
    this.getBlobexistURL(this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1]);
    this.getBlobSigexistURL(this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1]);

  }
  btnCancelClick(cancelForm: NgForm) {
    this.display = false;
    cancelForm.resetForm();
    this.resetModel();
    this.changeButtonsStatus(false);
    this.OpenCloseMenu(false);
  }
  btnProfileCancelClick(cancelForm: NgForm) {

    this.display = false;
    this.displayPersonalInfoDlg = false;
    this.DisplaySignatureDlg = false;
    this.DisplayProfileDlg = false;

    //cancelForm.resetForm();
    // this.resetModel();
    this.changeButtonsStatus(false);
    //this.OpenCloseMenu(false);
  }
  btnCancelClickSigDlg(cancelForm: NgForm) {
    this.DisplaySignatureDlg = false;
    //this.SignatureCropperImg=false;
  }
  btnCancelClickProDlg(cancelForm: NgForm) {
    this.DisplayProfileDlg = false;
    //this.SignatureCropperImg=false;
  }
  changeButtonsStatus(status: boolean) {
    this.isSubmitted = status;
    if (this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
      this._userInfo.SignatureImage = this.SignaturepreviewUrl;
    } else {
      this._userInfo.SignatureImage = this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
    }
    if (this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
      this._userInfo.ProfileImage = this.ProfilepreviewUrl;
    } else {
      this._userInfo.ProfileImage = this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
    }
  }
  menuDotsClick() {
    if (this.isMenuVisible) {
      this.OpenCloseMenu(false);
    }
    else {
      this.OpenCloseMenu(true);
    }
    this.cRef.markForCheck();

  }
  OpenCloseMenu(status: boolean) {
    this.isMenuVisible = status;
    let elem: HTMLElement = document.getElementById('menudotsinner');
    if (elem) {
      if (status) {
        elem.setAttribute("style", "display:block !important;");
      }
      else {
        elem.setAttribute("style", "display:none !important;");
      }
      this.cRef.markForCheck();
    }


  }
  SignaturefileProgress(fileInput: any) {

    var fileobj = fileInput.srcElement;

    this.SignaturefileData = fileInput.srcElement.files[0];
    this.Signaturepreview(this.SignaturefileData);
    this.cRef.markForCheck();

  }
  ProfilefileProgress(fileInput: any) {

    var fileobj = fileInput.srcElement;

    this.ProfilefileData = fileInput.srcElement.files[0];
    this.Profilepreview(this.ProfilefileData);
    this.cRef.markForCheck();




  }

  Signaturepreview(fileData: any) {
    // Show preview 
    var mimeType = fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = (_event) => {
      this.SignaturepreviewUrl = reader.result;
      this._userInfo.SignatureImage = this.SignaturepreviewUrl;
      //this.checkSignatureEmpty=this.SignaturepreviewUrl.split(',')[1];
      this.imageSource = this.SignaturepreviewUrl;

      // this.child.ngAfterViewInit();
    }
    document.getElementById("btn").click();
  }
  Profilepreview(fileData: any) {
    // Show preview 
    var mimeType = fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = (_event) => {
      this.ProfilepreviewUrl = reader.result;
      //this.checkprofileEmpty=this.ProfilepreviewUrl.split(',')[1];
      //this.checkSignatureEmpty=this.SignaturepreviewUrl.split(',')[1];
      this._userInfo.ProfileImage = this.ProfilepreviewUrl;
      this.imageSource = this.ProfilepreviewUrl;
      //this.checkSignatureEmpty=this.SignaturepreviewUrl.split(',')[1];
      //this.img = document.getElementById('image');
      this.img.src = this.imageSource;

      //   this.img.onload = () => {
      //    console.log(this.img.src); 
      //    //this.onImageLoad(); 
      //   }
      //this.img.src = this.imageSource;

    }
    document.getElementById("btn").click();
    /* this.cropper=new Cropper(this.img,{
         zoomable:true,
         scalable:true,
         aspectRatio:1,
         viewMode:1,
   
             })*/

    //this.showUserProfileDialogue();
  }


  getFile() {
    document.getElementById("uppfile").click();

  }
  getsFile() {
    document.getElementById("upsfile").click();
  }
  onFileChangeSig(event: any): void {
    if (event != null) {
      this.imgChageEvtSig = event;
      this.showProfile = false;
      this.showSignature = true;
      var SigFileSize = Math.round(event.target.files[0].size / 1024);
      if (SigFileSize > 300) {
        this.showSignature = false;
        this.imgChageEvtSig = "";
        this._sharedService.clearToast();
        this._sharedService.showToast({ severity: 'warn', summary: 'Upload file size', detail: 'File size exceeds by its limit of 300 kb.' });
        this.InputVarSig.nativeElement.value = "";
      }
    } else {
      this.imgChageEvtSig = "";
      this.showSignature = false;
      this.cropImgSigPreview = "";
      this.SignaturepreviewUrl = "";
      this.checkSignatureEmpty = "";
    }
  }
  onFileChange(event: any): void {
    if (event != null) {
      this.imgChangeEvt = event;
      this.showProfile = true;
      this.showSignature = false;
      var ProFileSize = Math.round(event.target.files[0].size / 1024);
      if (ProFileSize > 300) {
        this.showProfile = false;
        this.imgChangeEvt = "";
        this._sharedService.clearToast();
        this._sharedService.showToast({ severity: 'warn', summary: 'Upload file size', detail: 'File size exceeds by its limit of 300 kb.' });
        this.InputVar.nativeElement.value = "";
      }
    } else {

      this.showProfile = false;
      this.imgChangeEvt = null;
      this.cropImgPreview = "";
      this.ProfilepreviewUrl = "";
      this.checkprofileEmpty = "";
      //this.ProfilepreviewUrl;
      //this.ProfileCropperImg=null;
    }

  }
  onFileChangeSig1(event: any): void {
    if (event != null) {
      this.imgChageEvtSig = event;
      this.showProfile = false;
      this.showSignature = true;
      var SigFileSize = Math.round(event.target.files[0].size / 1024);
      if (SigFileSize > 300) {
        this.showSignature = false;
        this.imgChageEvtSig = "";
      }
    } else {
      this.showSignature = false;
      this.imgChageEvtSig = "";
      this.cropImgSigPreview = "";
      this.SignaturepreviewUrl = "";
      this.checkSignatureEmpty = "";
    }
  }
  cropImg(e: ImageCroppedEvent) {
    this.cropImgPreview = e.base64;
    //this.ProfilepreviewUrl=e.base64;
    // this._userInfo.ProfileImage=this.ProfilepreviewUrl;
  }
  cropImgSig(e: ImageCroppedEvent) {
    this.cropImgSigPreview = e.base64;
    //this.SignaturepreviewUrl=e.base64;

  }
  imageLoaded(image: HTMLImageElement) {
    // this.cropImgPreview=this.ProfilepreviewUrl;
    // this.imgChageEvtSig=this.ProfilepreviewUrl;

  }
  imageLoadedPro(image: HTMLImageElement) {

  }
  imageLoaded1(image: HTMLImageElement) {
    this.cropImgPreview = this.ProfilepreviewUrl;
    this.imgChageEvtSig = this.ProfilepreviewUrl;

  }
  cropperReady(e) {
    // cropper ready
  }
  loadImageFailed(e) {
    // show message
  }
  //#endregion
  ShowProfileImageDialogue() {

    //setTimeout(function () {
    this.showProfile = true;
    this.DisplayProfileDlg = true;
    // if (this.ProfileCropperImg.changingThisBreaksApplicationSecurity == undefined) {
    //     var splitvalue = this.ProfileCropperImg.split(',')[1];
    //     if (splitvalue == "") {
    //         this.showdummyProfileImage = false;
    //     } else {
    //         this.showdummyProfileImage = true;
    //     }
    // } else {
    //     var splitvalue = this.ProfileCropperImg.changingThisBreaksApplicationSecurity.split(',')[1];
    //     if (splitvalue == "") {
    //         this.showdummyProfileImage = false;
    //     } else {
    //         this.showdummyProfileImage = true;
    //     }
    // }
    //},500);
    // this._sharedService.setProfileVisible(null)

  }
  showimg() {

    if (this.ProfileCropperImg.changingThisBreaksApplicationSecurity == undefined) {
      var splitvalue = this.ProfileCropperImg.split(',')[1];
      if (splitvalue == "") {
        this.showdummyProfileImage = false;
      } else {
        this.showdummyProfileImage = true;
      }
    } else {
      var splitvalue = this.ProfileCropperImg.changingThisBreaksApplicationSecurity.split(',')[1];
      if (splitvalue == "") {
        this.showdummyProfileImage = false;
      } else {
        this.showdummyProfileImage = true;
      }
    }
  }
  showSigimg() {

    if (this.SignatureCropperImg.changingThisBreaksApplicationSecurity == undefined) {
      var splitvalue = this.SignatureCropperImg.split(',')[1];
      if (splitvalue == "") {
        this.showdummySignatureImage = false;
      } else {
        this.showdummySignatureImage = true;
      }
    } else {
      var splitvalue = this.SignatureCropperImg.changingThisBreaksApplicationSecurity.split(',')[1];
      if (splitvalue == "") {
        this.showdummySignatureImage = false;
      } else {
        this.showdummySignatureImage = true;
      }
    }
  }
  ShowSignatureImageDialogue() {
    this.DisplaySignatureDlg = true;
    this.showSignature = true;

    // setTimeout(function () {
    //    // document.getElementById("btnimg").click();
    //    // this.searchsigElement.nativeElement.focus();
    // }, 2000);
    //this.SignatureCropperImg=this._DomSanitizationService.bypassSecurityTrustUrl(this.SignaturepreviewUrl);
  }
  Loadimg() {

    var bimage = document.getElementById("imagec").getAttribute("src");
    // console.log(bimage);


  }
  focusbtn(elm: HTMLInputElement) {
    //var that = this;
    setTimeout(function () {
      document.getElementById("btnproimg").click();
      elm.focus();
      document.getElementById("btnclks").click();
    }, 500);
  }
  focusSigbtn(elm: HTMLInputElement) {
    setTimeout(function () {
      document.getElementById("btnimg").click();
      elm.focus();
      document.getElementById("btnclk").click();
    }, 500);
  }
  btnLoadImage() {

    if (this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
      this.getBlobURL(this.SignaturepreviewUrl.split(',')[1]);
      this.showSigimg();
    } else {
      this.getBlobURL(this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1]);
      this.showSigimg();
    }
  }


  btnproLoadImage() {
    if (this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
      this.getBlobProURL(this.ProfilepreviewUrl.split(',')[1]);
      this.showimg();
    } else {

      this.getBlobProURL(this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1]);
      this.showimg();
    }
  }

  imageCroppedFileObj(image: any = document.getElementById("imagec")) {
    this.cropImgSigPreview = image;
    //console.log(this.cropImgSigPreview);
  }
  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  getBlobURL(blobdata: string) {
    const contentType = 'image/png';
    const b64Data = blobdata;
    const blob = this.b64toBlob(b64Data, contentType);
    const blobUrl = URL.createObjectURL(blob);
    var reader = new FileReader();

    reader.readAsDataURL(blob);
    reader.onload = (_event) => {
      this.SignatureCropperImg = reader.result;
      //this.SignatureCropperImg=this._DomSanitizationService.bypassSecurityTrustUrl(this.SignatureCropperImg);

    }
  }
  getBlobexistURL(blobdata: string) {
    const contentType = 'image/png';
    const b64Data = blobdata;
    const blob = this.b64toBlob(b64Data, contentType);
    const blobUrl = URL.createObjectURL(blob);
    var reader = new FileReader();

    reader.readAsDataURL(blob);
    reader.onload = (_event) => {
      this.ProfilepreviewUrl = reader.result;

    }
  }
  getBlobSigexistURL(blobdata: string) {
    const contentType = 'image/png';
    const b64Data = blobdata;
    const blob = this.b64toBlob(b64Data, contentType);
    const blobUrl = URL.createObjectURL(blob);
    var reader = new FileReader();

    reader.readAsDataURL(blob);
    reader.onload = (_event) => {
      this.SignaturepreviewUrl = reader.result;

    }
  }

  getBlobProURL(blobdata: string) {
    const contentType = 'image/png';
    const b64Data = blobdata;
    const blob = this.b64toBlob(b64Data, contentType);
    const blobUrl = URL.createObjectURL(blob);
    var reader = new FileReader();

    reader.readAsDataURL(blob);
    reader.onload = (_event) => {
      this.ProfileCropperImg = reader.result;

    }
  }

  BtnCropSignature(form: NgForm) {
    if (this.SignaturepreviewUrl == undefined)
      this.SignaturepreviewUrl = "";
    if ((this.SignaturepreviewUrl == "" || this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity == undefined) && this.cropImgSigPreview != "") {
      if (this.cropImgSigPreview == "" || this.cropImgSigPreview == null) {

      } else {
        this.SignaturepreviewUrl = this.cropImgSigPreview;

        this.showSignature = true;
      }
      if (this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
        this._userInfo.SignatureImage = this.SignaturepreviewUrl;
      } else {
        this._userInfo.SignatureImage = this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
      }
      if (this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
        this._userInfo.ProfileImage = this.ProfilepreviewUrl;
      } else {
        this._userInfo.ProfileImage = this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
      }


      if (this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
        this.checkSignatureEmpty = this.SignaturepreviewUrl.split(',')[1];
      } else {
        this.checkSignatureEmpty = this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
      }
      this.DisplaySignatureDlg = true;
    } else if (this.SignaturepreviewUrl == "" && this.cropImgSigPreview == "") {
      this.showSignature = false;
      this.imgChageEvtSig = "";
      this._userInfo.SignatureImage = null;
      this.checkSignatureEmpty = "";
      this.DisplaySignatureDlg = false;
      if (this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
        this._userInfo.SignatureImage = this.SignaturepreviewUrl;
      } else {
        this._userInfo.SignatureImage = this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
      }
      if (this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
        this._userInfo.ProfileImage = this.ProfilepreviewUrl;
      } else {
        this._userInfo.ProfileImage = this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
      }
    } else if ((this.SignaturepreviewUrl != "" || this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity.length > 22) && this.cropImgSigPreview != "") {
      if (this.cropImgSigPreview == "" || this.cropImgSigPreview == null) {

      } else {
        this.SignaturepreviewUrl = this.cropImgSigPreview;
        this.showSignature = true;
      }
      if (this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
        this._userInfo.SignatureImage = this.SignaturepreviewUrl;
      } else {
        this._userInfo.SignatureImage = this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
      }
      if (this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
        this._userInfo.ProfileImage = this.ProfilepreviewUrl;
      } else {
        this._userInfo.ProfileImage = this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
      }
      if (this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
        this.checkSignatureEmpty = this.SignaturepreviewUrl.split(',')[1];
      } else {
        this.checkSignatureEmpty = this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
      }
      this.DisplaySignatureDlg = true;
    }

    //   this.SignaturepreviewUrl=this.cropImgSigPreview;
    //   this._userInfo.SignatureImage=this.SignaturepreviewUrl;
    //   this.checkSignatureEmpty=this.SignaturepreviewUrl.split(',')[1];
    this.DisplaySignatureDlg = false;
    this.isSignatureSubmit = false;
    //this.onFileChangeSig(null);

  }
  BtnCropProfile(form: NgForm) {
    if (this.ProfilepreviewUrl == undefined)
      this.ProfilepreviewUrl = "";
    if ((this.ProfilepreviewUrl == "" || this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity == undefined) && this.cropImgPreview != "") {
      if (this.cropImgPreview == "" || this.cropImgPreview == null) {

      } else {
        this.ProfilepreviewUrl = this.cropImgPreview;
        this.showProfile = true;
      }
      if (this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
        this._userInfo.SignatureImage = this.SignaturepreviewUrl;
      } else {
        this._userInfo.SignatureImage = this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
      }
      if (this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
        this._userInfo.ProfileImage = this.ProfilepreviewUrl;
      } else {
        this._userInfo.ProfileImage = this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
      }
      if (this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
        this.checkprofileEmpty = this.ProfilepreviewUrl.split(',')[1];
      } else {
        this.checkprofileEmpty = this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
      }
      this.DisplayProfileDlg = true;
    } else if (this.ProfilepreviewUrl == "" && this.cropImgPreview == "") {
      this.showProfile = false;
      this.imgChangeEvt = "";
      this._userInfo.ProfileImage = null;

      this.checkprofileEmpty = "";
      this.DisplayProfileDlg = false;
      if (this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
        this._userInfo.SignatureImage = this.SignaturepreviewUrl;
      } else {
        this._userInfo.SignatureImage = this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
      }
      if (this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
        this._userInfo.ProfileImage = this.ProfilepreviewUrl;
      } else {
        this._userInfo.ProfileImage = this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
      }
    } else if ((this.ProfilepreviewUrl != "" || this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity.length > 22) && this.cropImgPreview != "") {
      if (this.cropImgPreview == "" || this.cropImgPreview == null) {

      } else {
        this.ProfilepreviewUrl = this.cropImgPreview;
        this.showProfile = true;
      }
      if (this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
        this._userInfo.SignatureImage = this.SignaturepreviewUrl;
      } else {
        this._userInfo.SignatureImage = this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
      }
      if (this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
        this._userInfo.ProfileImage = this.ProfilepreviewUrl;
      } else {
        this._userInfo.ProfileImage = this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
      }
      if (this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity == undefined) {
        this.checkprofileEmpty = this.ProfilepreviewUrl.split(',')[1];
      } else {
        this.checkprofileEmpty = this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
      }
      this.DisplayProfileDlg = true;
    }
    this.isProfileSubmit = false;
    this.DisplayProfileDlg = false;
    //  this.onFileChange(null);

  }
  RemoveProfileImage() {
    this.onFileChange(null);
    this.isProfileSubmit = true;
    this.InputVar.nativeElement.value = "";
  }
  RemoveSignatureImage() {
    this.onFileChangeSig(null);
    this.isSignatureSubmit = true;
    this.InputVarSig.nativeElement.value = "";
  }

  LoadDataForCropper() {
    if (this._router.url.indexOf("/login") != 0) {
      this._userService.GetUserByEmail(this._sharedService.baseUrl, this._userInfo)
        .subscribe({
          next: (response) => {
            this._sharedService.hideLoader();

            var responseData = response;
            this.SignaturepreviewUrl = this._DomSanitizationService.bypassSecurityTrustUrl(responseData.SignatureImage);
            this.ProfilepreviewUrl = this._DomSanitizationService.bypassSecurityTrustUrl(responseData.ProfileImage);
            this.SignatureCropperImg = this._DomSanitizationService.bypassSecurityTrustUrl(responseData.SignatureImage);
            this.ProfileCropperImg = this._DomSanitizationService.bypassSecurityTrustUrl(responseData.ProfileImage);
            this._userInfo.FirstName = responseData.FirstName;
            this._userInfo.LastName = responseData.LastName;
            this._userInfo.Email = responseData.Email;
            this._userInfo.PhoneNumber = responseData.PhoneNumber;
            this.tempPhoneNumber = this._userInfo.PhoneNumber;
            this.name = this._userInfo.FirstName + " " + this._userInfo.LastName;
            localStorage.setItem('tempPhoneNumber', this.tempPhoneNumber);
            if (this.ProfilepreviewUrl != null) {
              this.checkprofileEmpty = this.ProfilepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
              localStorage.setItem('checkprofileEmpty', this.checkprofileEmpty);
              localStorage.setItem('ProfilepreviewUrl', this.ProfilepreviewUrl);
            }
            if (this.SignaturepreviewUrl != null) {
              this.checkSignatureEmpty = this.SignaturepreviewUrl.changingThisBreaksApplicationSecurity.split(',')[1];
              localStorage.setItem('checkSignatureEmpty', this.checkSignatureEmpty);
              localStorage.setItem('SignaturepreviewUrl', this.SignaturepreviewUrl);
            }
            //this.LastLoggedIn=responseData.LastIP;
            this.ExpiryDate = responseData.ExpiryDate;
            this.cRef.markForCheck();
            this._sharedService.clearToast();
          },
          error: (error) => {
            this._sharedService.hideLoader()
            this._sharedService.clearToast();
            // this._sharedService.showToast({ severity: 'error', summary: 'Error Occurred', detail: 'User is not updated.' });
            this.isSubmitted = false;
          }
        });
    }
  }
  HideDummyProfileImage() {
    this.showdummyProfileImage = false;
  }
  HideDummySignatureImage() {
    this.showdummySignatureImage = false;
  }

}
