//#region References
//Components
import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription, Subject, Observable} from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';
import { AuditService } from '../../../shared/services/Audit/audit.service';

import { AuditModel } from '../../../shared/services/Audit/audit';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
import * as moment from 'moment/moment';
var dateTimeConfig: IDateFormatInfo;
//#endregion

//#region Type Decorator
@Component({
    selector: 'audit-history',
    templateUrl: './audit-history.component.html',
    changeDetection:ChangeDetectionStrategy.OnPush,
    providers: [AuditService]
})
//#endregion

export class AuditHistoryComponent implements OnInit {

    //#region Locals Declaration

    model: AuditModel;
    
    displayHistoryDlg: boolean = false;
    auditSubscription: Subscription;
    auditHistoryList: AuditModel[] = [];
    //#endregion

    //#region Initialization of Component
    constructor(private _sharedService: SharedService, private _router: Router, private _auditService: AuditService, private cRef:ChangeDetectorRef) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            dateTimeConfig = this._sharedService.getDateTimeFormatConfiguration();
        }
    }
    ngOnInit() {
        this.auditSubscription = this._sharedService._currentAuditScreen
        .subscribe({
            next: (auditCong: AuditModel) => {
                this.showAuditHistory(auditCong);
            },
            error: (error) => {
                console.log(error)
            }
        });
    }

    showAuditHistory(auditCong: AuditModel) {
        if ((this._router.url != '/admin/audit')){

            this._auditService.getRecordHistory(auditCong)
            .subscribe({
                next: (response) => {
                    let result: any[] = response;
                    if (result.length > 0) {
                        this.auditHistoryList = [];
                        this.auditHistoryList = result;
                        this.displayHistoryDlg = true;
                        this.cRef.markForCheck();
                    } else {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'No History Found!', detail: 'No history found for this record.' });
                    }
                },
                error: (error) => {
                    console.log(error);
                }
            });

        } else {
            this._auditService.getRecordDetail(auditCong)
            .subscribe({
                next: (response) => {
                    let result: any[] = response;
                    if (result.length > 0) {
                        this.auditHistoryList = [];
                        this.auditHistoryList = result;
                        this.displayHistoryDlg = true;
                        this.cRef.markForCheck();
                    } else {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'No Detail Found!', detail: 'No detail found for this record.' });
                        this.cRef.markForCheck();
                    }
                },
                error: (error) => {
                    console.log(error)
                }
            });
        }
    }



    ngOnDestroy() {
        if (this.auditSubscription) {
            this.auditSubscription.unsubscribe();
        }
    }


    //#endregion


    //#region AG Grid Date Comparer
    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).split(dateTimeConfig.dateSeparator);
            var day = Number(dateParts[Number(dateTimeConfig.day)].substring(0, 2));
            var month = Number(dateParts[Number(dateTimeConfig.month)]) - 1;
            var year = Number(dateParts[Number(dateTimeConfig.year)]);

            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        }
        else {
            return -1;
        }
    }
    //#endregion

    //#region Helper Methods
    getPlainDate(date: Date): string {
        if (date != null) {
            var formatted = moment(date).format(dateTimeConfig.dateformat.toUpperCase());
            if (dateTimeConfig.dateSeparator == "-") {
                let newStr = formatted.toString().replace(/-/g, "");
                return newStr;
            }
            else if (dateTimeConfig.dateSeparator == "/") {
                let newStr = formatted.toString().replace(/\//g, "");
                return newStr;
            }
        }
        else {
            return "";

        }
    }

    btnCancelClick() {
        this.displayHistoryDlg = false;
    }

    

    getDate(date: Date): string {
        if (date != null) {
            let newDate = moment(date).format("YYYY-MM-DD");
            return new Date(newDate).toDateString();
        }
        return null;
    }

    

    getDateTime(date: Date): string {
      if (date != null) {

            var dateConfig = this._sharedService.getDateTimeFormatConfiguration();
            //  this.ViewDateFormat=dateConfig.dateformat;
             return moment.utc(date).format(dateConfig.dateformat.toUpperCase()+ "  " + dateConfig.timeformat).toString();
         } else {
             return '';
         }

        // if (date != null) {
        //     let result = moment(date).format("MM/DD/YYYY" + " HH:mm");
        //     return result;
        // }
        // return null;
    }
    //#endregion

}
