import { Renderer2 } from '@angular/core';
//#region References
//Components
import { Component, OnInit, Input, ViewChild, ElementRef, HostListener, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Observable, Subscription, Subscriber } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';
import { SelectItem } from 'primeng/api';
import { AddCommModel } from './add-comm';
import { comm } from '../comm/comm';
import { NgForm } from '@angular/forms';
import { CommService } from '../../services/comm.service';
import { UserService } from '../../../records/services/User/user.service';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
import * as moment from 'moment/moment';
var dateTimeConfig: IDateFormatInfo;
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
//#endregion

//#region Type Decorator
@Component({
    selector: 'add-comm',
    templateUrl: './add-comm.component.html',
    // changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UserService, CommService]
})
//#endregion

export class AddCommComponent implements OnInit, OnDestroy {

    //#region Locals Declaration
    public appHotkeys: Hotkey[] = [];

    commdisplay: boolean = false;
    isSubmitted: boolean = false;

    dialogTitle: string = "";
    dateFormatCalender = "";
    userType: string = "";
    uploadUrl: string;

    priorityList: SelectItem[] = [];
    commStatusList: SelectItem[] = [];
    requestedByList: SelectItem[] = [];
    assignedToList: SelectItem[] = [];
    commTypeList: SelectItem[] = [];
    emailCcsList: any[] = [];
    model: AddCommModel;
    //selectedCCs: string[] = [];
    selectedCCsTemp: any[] = [];
    public bulkFiles: any[] = [];
    uploadedFiles: any[] = [];

    addCommSub: Subscription;
    @ViewChild('comm') selectedComFiles: any;
    @Input() recordType: string;
    @Input() isDirectModal: boolean = false;
    @Input() browseDocumentParams: any;

    @HostListener('window:resize') onResize() {
        try {
            let tskDlgfrm = document.getElementById('commAddDlg');
            if (tskDlgfrm) {
                var tskDlgfrmOuter = document.getElementById("additemdlg").children[0] as HTMLElement;
                tskDlgfrmOuter.style.height = "99vh";
                tskDlgfrm.style.height = '82vh';
                tskDlgfrm.style.overflowY = 'scroll';
            }
        } catch (error) {
            console.log(error);
        }
    }
    dropdownSettings = {};
    ViewDateFormat: string;


    //#endregion



    //#region Initialization of Component
    constructor(private _sharedService: SharedService, private cRef: ChangeDetectorRef, private _router: Router, private _userService: UserService, private _commService: CommService, private _hotkeysService: HotkeysService) {

        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            dateTimeConfig = this._sharedService.getDateTimeFormatConfiguration();
        }
        this.setHotKeys();
    }
    setHotKeys() {

        let hotKeyObj: Hotkey = null;

        //Add Inbox
        hotKeyObj = new Hotkey('ctrl+shift+a', (event: KeyboardEvent): boolean => {
            this.showAddDialog();
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

    }
    ngOnInit() {
        this.multiSelectDropdownSettings();
        this.initializeEmptyModel();

        this.addCommSub = this._sharedService._addComm.subscribe((params: any) => {

            this.showAddDialog();
        });

        this.ViewDateFormat = this.getDateFormatTime();
        console.log(this.browseDocumentParams);
        if (this.isDirectModal) {
            this.showAddDialog()
        }
        if (this.browseDocumentParams) {
            this.model.ASN = this.browseDocumentParams.ASN
            this.model.CommTypeId = this.browseDocumentParams.CommTypeId.toString()
            this.model.Module = this.browseDocumentParams.Module
            this.model.Subject = this.browseDocumentParams.Subject
            this.model.Section = this.browseDocumentParams.Section
            this.model.Record = this.browseDocumentParams.Record

                this.cRef.markForCheck();
        }
        //#region multiselect drop down control


        //#endregion
    }


    multiSelectDropdownSettings(): void {
        this.dropdownSettings = {
            singleSelection: false,
            text: "Select Users",
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true,
            addNewItemOnFilter: true,
            labelKey: 'label',
            primaryKey: 'value'
        };

    }
    onAddItem(data: string) {
        this.emailCcsList.push({ label: data, value: data });
        this.selectedCCsTemp.push({ label: data, value: data });
    }
    //#endregion

    //#region Destroy
    ngOnDestroy() {
        if (this.addCommSub) {
            this.addCommSub.unsubscribe();
        }
        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];

    }
    //#endregion

    // #region Form Events

    showAddDialog() {
        this.setCalenderDateFormat();
        this.dialogTitle = "Add Item";
        this.setDefaultComponentValues();
        this.commdisplay = true;
        this.cRef.markForCheck();
    }

    btnCancelClick(cancelForm: NgForm) {
        cancelForm.resetForm();
        this.clearSelectedFiles();
        this.commdisplay = false;
        this.initializeEmptyModel();

    }
    //#endregion

    //#region AG Grid Date Comparer
    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).split(dateTimeConfig.dateSeparator);
            var day = Number(dateParts[Number(dateTimeConfig.day)].substring(0, 2));
            var month = Number(dateParts[Number(dateTimeConfig.month)]) - 1;
            var year = Number(dateParts[Number(dateTimeConfig.year)]);

            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        }
        else {
            return -1;
        }
    }
    //#endregion

    // #region Priority List
    populatePriortityList() {
        this.priorityList = this._commService.getPriorityList();
        this.model.Priority = 3;
        this.cRef.markForCheck();
    }
    //#endregion

    //#region Comm Type
    populateCommType() {
        this._commService.getAllCommType().subscribe({
            next: (response) => {
                this.commTypeList = response
            }
        });
    }
    //#endregion

    //#region File Uploader Events
    onSelect(event: any) {
        for (let file of event.files) {
            let fileCurrent: any = this.uploadedFiles.filter(x => x.name == file.name);
            if (fileCurrent.length > 0) {
                for (let i = 0; i < this.selectedComFiles.files.length; i++) {
                    if (this.selectedComFiles.files[i].name == file.name) {
                        this.selectedComFiles.files.splice(i, 1);
                        break;
                    }

                }

            }
            else {

                this.uploadedFiles.push(file);

            }
        }

    }
    onRemove(event: any) {

        for (let i = 0; i < this.uploadedFiles.length; i++) {
            if (this.uploadedFiles[i].name == event.file.name) {
                this.uploadedFiles.splice(i, 1);
                break;
            }
        }

    }
    onBeforeUpload(event: any) {

        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }
    }

    clearSelectedFiles() {
        if (this.selectedComFiles && this.selectedComFiles.files) { this.selectedComFiles!.files = []; }
    }

    onBulkUpload(event: any, addForm: NgForm) {
        this.changeButtonsStatus(true);
        this.bulkFiles;
        let xhr = new XMLHttpRequest(), formData = new FormData();
        for (let i = 0; i < this.uploadedFiles.length; i++) {
            formData.append("uploads['" + i + "']", this.uploadedFiles[i], this.uploadedFiles[i].name);
        }
        //Append File and Form Data in xhr Request
        if (!this.browseDocumentParams)
            if (this._router.url.indexOf("status") != -1 || this._router.url.indexOf("repair") != -1 || this._router.url.indexOf("record") != -1 || this._router.url.indexOf("transition") != -1) {
                let com = this._sharedService.getModulesSelectedOptionForComm();
                this.model.ASN = com.ASN;
                this.model.Module = com.Module;
                this.model.Section = com.Section;
                this.model.Record = com.Record;

            }
        //else if (this._router.url.indexOf("record") != -1)
        //{

        //}
        this.model.CCs = this.selectedCCsTemp.join(';')
        //this.model.CCs = this.selectedCCs.join(';');

        if (this.uploadedFiles.length > 0) {
            this.model.Attachment = true;
        }
        else {
            this.model.Attachment = false;
        }
        this.model.Description = this._sharedService.filterHTMLBody(this.model.Description).replace(new RegExp("<\/p>", 'g'), "").replace(new RegExp("<p>", 'g'), "");
        let postData = this.model;
        if (postData) {
            for (let prop in postData) {
                if (prop != "DueDate") {
                    if (prop == "Conversation") {
                        formData.append(prop, this.getConversation());
                    }
                    else {
                        if (prop == "AssignedTo") {
                            if (postData.AssignedTo != null) {
                                formData.append(prop, postData[prop].split('||')[0]);
                            }
                            else {
                                formData.append(prop, postData[prop]);
                            }
                            if (postData[prop]) {
                                //this.assignedToList
                                let commAssignedNameLabel = this.assignedToList.find(x => x.value == postData[prop]).label;
                                formData.append("AssignedToName", commAssignedNameLabel);
                            }
                        }
                        else if (prop == "CssOther") {
                            formData.append(prop, postData[prop].join(';'));
                        }
                        else if (prop == "RequestedBy") {
                            formData.append(prop, postData[prop]);
                            if (postData[prop]) {
                                //let commRequestedByLabel = this.requestedByList.find(x => x.label == this._sharedService.getCurrentUserName()).label;
                                let commRequestedByLabel = this.requestedByList.find(x => x.value == postData[prop]).label;
                                formData.append("RequestedByName", commRequestedByLabel);
                            }
                        }
                        else if (prop == "CommTypeId") {
                            formData.append(prop, postData[prop].toString());
                            if (postData[prop]) {
                                //this.commTypeList
                                let commTypeLabel = this.commTypeList.filter(ct => ct.value == postData[prop])[0].label;
                                formData.append("CommTypeDescription", commTypeLabel);
                            }
                        }
                        else if (prop == "CommStatusId") {
                            formData.append(prop, postData[prop]);
                            if (postData[prop]) {
                                //this.commStatusList 
                                let commStatusLabel = this.commStatusList.filter(cs => cs.value == postData[prop])[0].label;
                                formData.append("CommStatusDescription", commStatusLabel);
                            }
                        }
                        else if (prop == "Priority") {
                            formData.append(prop, postData[prop].toString());
                            if (postData[prop]) {
                                //this.priorityList
                                let commPriorityLabel = this.priorityList.filter(cp => cp.value == postData[prop])[0].label;
                                formData.append("PriorityDescription", commPriorityLabel);
                            }
                        }
                        else {
                            formData.append(prop, postData[prop]);
                        }

                    }

                }
                else {
                    if (postData.DueDate != null) {
                        if (typeof (postData.DueDate) == "string") {
                            let dateString = postData[prop].toString().substr(0, 10);
                            let date = moment(dateString).format("YYYY-MM-DD").toString();
                            formData.append(prop, date);
                        }
                        else {
                            let date = moment(postData.DueDate).format("YYYY-MM-DD").toString();
                            formData.append(prop, date);
                        }
                    }
                    else {
                        formData.append(prop, null);
                    }
                }
            }
        }

        this._sharedService.showLoader("Processing...");
        xhr.open('POST', this.uploadUrl, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        xhr.send(formData);

        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                this._sharedService.hideLoader();
                if (xhr.status === 200) {
                    //if (this._router.url.indexOf("status") != -1 || this._router.url.indexOf("record") != -1) {
                    //    this._sharedService.changeDialgoueStatus(false);
                    //}

                    this.commdisplay = false;
                    if (this._router.url.indexOf("inbox") != -1) {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'success', summary: 'Item added', detail: 'Item has been added successfully.' });
                        this.cRef.markForCheck();
                    }
                    else {
                        let msg = {
                            Severity: 'success',
                            Summary: 'Item added',
                            Detail: 'Item has been added successfully.',
                            commStatus: xhr.responseText
                        };

                        this._sharedService.showAlertMsg(msg);
                    }

                    this.uploadedFiles = [];
                    this.clearSelectedFiles();
                    addForm.resetForm();
                    this.changeButtonsStatus(false);


                    this._sharedService.resfreshInboxGrid(true);
                    if (this._router.url.indexOf("status") != -1 || this._router.url.indexOf("repair") != -1) {

                        this._sharedService.onStatusGridRefresh(true);
                    }
                }
                else {
                    this.commdisplay = false;
                    if (this._router.url.indexOf("inbox") != -1) {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while adding item.' });
                    }
                    else {
                        let msg = {
                            Severity: 'error',
                            Summary: 'An error occured',
                            Detail: 'An error occured while adding item.'
                        };

                        this._sharedService.showAlertMsg(msg);
                    }


                    addForm.resetForm();
                    this.initializeEmptyModel();

                    this.changeButtonsStatus(false);

                }
            }
        };
    }
    //#endregion
    //#region check Email To And CC
    // checkEmailToAndCCList(): boolean
    // {
    //     if (this.selectedCCs && this.selectedCCs.length > 0)
    //     {
    //         let requestedByResultIndex = this.selectedCCs.findIndex(x => x == this.model.RequestedBy);
    //         let assignedByResultIndex = -1;
    //         if (this.model.AssignedTo && this.model.AssignedTo != "")
    //         {
    //             assignedByResultIndex = this.selectedCCs.findIndex(x => x == this.model.AssignedTo.split('||')[0]);
    //         }
    //         if (requestedByResultIndex != -1 || assignedByResultIndex != -1)
    //         {
    //             return false;
    //         }
    //         else {
    //             return true;
    //         }

    //     }
    //     else
    //     {
    //         return true;
    //     }

    // }
    //#endregion
    //#region Comm Status
    populateCommStatus() {
        this._commService.getAllCommStatus()
            .subscribe({
                next: (response) => {
                    this.commStatusList = response;
                    this.model.CommStatusId = this.commStatusList[0].value;
                    // this.model.CommStatusId = "1";
                    this.cRef.markForCheck();
                },
                error: (error) => {
                }
            });
    }
    //#endregion

    //#region Helper Methods
    adjustComDlgHeight(event: any) {
        let tskDlgfrm = document.getElementById('commAddDlg');
        let tskDlgfrmOuter = document.getElementById('additemdlg');
        if (tskDlgfrm) {
            // tskDlgfrmOuter.style.height = '99vh';
            tskDlgfrm.style.height = '82vh';
            tskDlgfrm.style.overflowY = 'scroll';
        }
        this.cRef.markForCheck();
    }
    getPlainDate(date: Date): string {
        if (date != null) {
            var formatted = moment(date).format(dateTimeConfig.dateformat.toUpperCase());
            if (dateTimeConfig.dateSeparator == "-") {
                let newStr = formatted.toString().replace(/-/g, "");
                return newStr;
            }
            else if (dateTimeConfig.dateSeparator == "/") {
                let newStr = formatted.toString().replace(/\//g, "");
                return newStr;
            }
        }
        else {
            return "";

        }
    }

    setCalenderDateFormat() {
        let dateParts: string[] = dateTimeConfig.dateformat.split(dateTimeConfig.dateSeparator);

        if (dateTimeConfig.year == 0) {
            this.dateFormatCalender = (dateParts[dateTimeConfig.year].substr(0, 2) + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day]).toLowerCase();
        }
        else if (dateTimeConfig.year == 2) {
            if (dateTimeConfig.month == 1) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
            else if (dateTimeConfig.month == 0) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
        }
        else {
            this.dateFormatCalender = "yy-mm-dd"
        }

    }

    setDefaultComponentValues(): void {
        this.initializeEmptyModel();
        this.getAssignedToUsersList();
        this.populateUsersList();
        this.populateUsersEmailList();
        this.populatePriortityList();
        this.populateCommType();
        this.populateCommStatus();
        this.changeButtonsStatus(false);
        this.uploadUrl = `${this._sharedService.baseUrl}/api/Comm/AddItem`;

    }

    getDate(date: Date): string {
        if (date != null) {
            let newDate = moment(date).format("YYYY-MM-DD");
            return new Date(newDate).toDateString();
        }
        return null;
    }

    getConversation() {
        if (this.model.Conversation != "" && this.model.Conversation != "null" && this.model.Conversation != null) {

            //return this._sharedService.getCurrentUserName() + " added a comment - " + this.getCurrentDateTime() + "\r\n" + this.model.Conversation;
            return this._sharedService.getCurrentUserName() + "||" + this.getCurrentDateTime() + "||" + this.model.Conversation + "?|?";
        }
        return null;
    }

    getCurrentDateTime() {
        let result = moment().format("MM/DD/YYYY" + " HH:mm");
        return result;
    }

    initializeEmptyModel() {
        this.model = new AddCommModel(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, []);
        //this.selectedCCs = [];
        this.selectedCCsTemp = [];
        this.bulkFiles = [];
        this.uploadedFiles = [];
        this.clearSelectedFiles();
        this.cRef.markForCheck();
    }
    //initializeModel() {

    //    this.model = new AddCommModel(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
    //    this.model.CommStatusId = 1;
    //    this.model.RequestedBy = this.requestedByList.find(x => x.label == this._sharedService.getCurrentUserName()).value;
    //    this.selectedCCs = [];
    //}



    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }
    //#endregion

    //#region Application Users
    populateUsersList() {
        this._userService.getCurrentTenantUsers(this._sharedService.baseUrl, null)
            .subscribe({
                next: (response) => {
                    this.requestedByList = response;
                    //this.assignedToList = response;

                    //       this.emailCcsList = response;

                    this.model.RequestedBy = this.requestedByList.find(x => x.label == this._sharedService.getCurrentUserName()).value;
                    this.cRef.markForCheck();
                },
                error: (error) => {
                }
            });
    }
    populateUsersEmailList() {
        this._userService.getCurrentTenantUsersWithEmail(this._sharedService.baseUrl, null)
            .subscribe({
                next: (response) => {
                    this.emailCcsList = response;
                },
                error: (error) => {
                }
            });
    }
    //#endregion

    //#region Assigned to list
    getAssignedToUsersList() {
        this._userService.getCurrentTenantUsersWithType(this._sharedService.baseUrl, null)
            .subscribe({
                next: (response) => {
                    this.assignedToList = response;
                },
                error: (error) => {
                }
            });
    }
    getSelectedClass(param: string): string {
        let userType = param.split('||')[1];
        if (userType == "Internal") {
            return 'text-danger';
        }
        else {
            return 'text-dark';
        }
    }
    getOptionsClass(param: string): string {

        let userType = param.split('||')[1];
        if (userType == "Internal") {
            return 'glyphicon glyphicon-user text-primary';
        }
        else {
            return 'glyphicon glyphicon-user text-success';
        }
    }
    //html = html + '<span class="padding-5 label" style= "background-color:red;font-size: small;" title="' + params.value + '" >' + params.value + '</span>';
    getUserDepartment(param: string) {
        return param.split('||')[2];
    }
    getTagColor(param: string) {
        return param.split('||')[3];
    }

    //#endregion
    onKey() {
        this.clearSelectedFiles();
        this.commdisplay = false;
        this.initializeEmptyModel();
    }

    getDateFormatTime() {
        var dateConfig = this._sharedService.getDateTimeFormatConfiguration();
        return dateConfig.dateformat;
    }


    onCloseDate() {
        var btnDiv: any = document.getElementsByClassName("c-btn");
        btnDiv[0].click();
        var btnDivFirstElement: any = document.getElementsByClassName("c-btn")[0];
        btnDivFirstElement.focus();
    }
    emailCCEscClick() {
        var subject: any = document.getElementById("subject");
        subject.focus();
    }
    subjectEnterClick() {
        var editorContent: any = document.getElementsByClassName('p-editor-content')[0].children[0];
        editorContent.focus();
    }
}
