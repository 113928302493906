export class ChangePasswordModel {

    constructor(
        public oldPassword: string,
        public newPassword: string,
        public confirmPassword: string
       
    ) { }

}

export interface IChangePasswordModel {
    OldPassword: string,
    NewPassword: string,
    ConfirmPassword: string
}