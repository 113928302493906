//Components
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';
import { TaskService } from '../../../admin/services/task/task.service';

import { Subscription } from 'rxjs';
import { GridOptions } from 'ag-grid-community';
import { DashboardService } from '../../../records/services/dashboard/dashboard.service';
import { DFPStatusModel } from '../../../records/components/home/dfpstatus.model';
var dBdfpStatuses: DFPStatusModel[] = [];
@Component({
    selector: 'msr',
    templateUrl: './msr.component.html',
    providers: [TaskService, DashboardService]
})

export class MSRComponent implements OnInit {

    gridData: any[] = [];

    columnDefs: any[] = [];
    asn: string = "";
    assetType: string = "";
    assetStatusSub: Subscription;
    public gridOptions: GridOptions;

    constructor(private _sharedService: SharedService, private _taskService: TaskService, private _router: Router, private _dashboardService: DashboardService) {
    }


    ngOnInit() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.getDFPStatus();
            this.gridOptions = <GridOptions>
                {
                    suppressContextMenu: true,
                    defaultColDef: {
                        sortable: true,
                        resizable: true,
                        filter: true
                    }
                };

            this.assetStatusSub = this._sharedService._selectedAssetStatus
            .subscribe({
                next: (selAsset:any) => {
                    if (this._sharedService.selectedAssetStatus && this._sharedService.selectedAssetStatus[0]) {
        
                        //US-8181 //// if (this._sharedService.selectedAssetStatus[0].data.split('|')[1]) {
                        //     this.asn = this._sharedService.selectedAssetStatus[0].data.split('|')[1];
                        //     this.assetType = this._sharedService.selectedAssetStatus[0].data.split('|')[4];
                        //     this.getMasterStatusReport();
                        // }
                        let assetDataObj = JSON.parse(this._sharedService.selectedAssetStatus[0].data);
        
                        this.asn = assetDataObj.ASN;
                        this.assetType = assetDataObj.AssetType;
                        this.getMasterStatusReport();
        
                    }
                },
                error: (error) => {
                }
            });

            //US-8181 //// if (this._sharedService.selectedAssetStatus && this._sharedService.selectedAssetStatus[0].data.split('|')[1]) {
            //     this.asn = this._sharedService.selectedAssetStatus[0].data.split('|')[1];
            //     this.assetType = this._sharedService.selectedAssetStatus[0].data.split('|')[4];
            //     this.getMasterStatusReport();
            // } 
            if (this._sharedService.selectedAssetStatus && this._sharedService.selectedAssetStatus[0].data) {
                let assetDataObj = JSON.parse(this._sharedService.selectedAssetStatus[0].data);

                this.asn = assetDataObj.ASN;
                this.assetType = assetDataObj.AssetType;
                this.getMasterStatusReport();
            }
            else {
                this.gridData = [];
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Warning', detail: 'No Asset selected.' });
            }
        }

    }


    // #region Master Status Report
    getMasterStatusReport() {
        if (this.asn) {
            this._sharedService.showLoader("Processing...");
            this._taskService.getMasterStatusReport(this.asn, this.assetType)
            .subscribe({
                next: (response) => {
                    this._sharedService.hideLoader();
                    let result: any[] = response;
                    if (result.length == 1 && !result[0].DFPStatus) {
                        this.gridData = [];
                    } else {
                        this.gridData = result;
                    }
        
                    this.populateGridColumnDef(Object.getOwnPropertyNames(result[0]));
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Cannot get Master Status Report, please try again.' });
                }
            });
        }
    }

    populateGridColumnDef(columnNames: string[]) {
        this.columnDefs = [];
        for (var i = 0; i < columnNames.length; i++) {
            let headerName = columnNames[i].indexOf("_") > 0 ? columnNames[i].substring(columnNames[i].indexOf("_") + 1) : columnNames[i];
            headerName = headerName.replace('_', '-');
            if (columnNames[i] == "DFPStatus") {
                this.columnDefs.push({ headerName: headerName, field: columnNames[i], menuTabs: ['filterMenuTab'], width: 250, cellRenderer: this.statusDFPInnerCellRenderer, })
            } else {
                this.columnDefs.push({ headerName: headerName, field: columnNames[i], menuTabs: ['filterMenuTab'], width: 90 });
            }
        }

    }

    statusDFPCellRenderer(params: any) {
        let html: string = '';

        if (params.value) {

            switch (params.value.trim()) {

                case 'New':
                    html = html + '<span class="label-default" style="padding: 5px;color: white;">' + params.value.trim() + '</span>';
                    break;
                case 'Suggested - no document found':
                    html = html + '<span style="padding: 3px;color:#a90329;">' + params.value.trim() + '</span>';
                    break;
                case 'Suggested - partial documents found':
                    html = html + '<span style="padding: 3px;color:#c79121;">' + params.value.trim() + '</span>';
                    break;
                case 'Suggested - documents found':
                    html = html + '<span style="padding: 3px;color:#71843f;">' + params.value.trim() + '</span>';
                    break;
                case 'Suggested - not applicable':
                    html = html + '<span style="padding: 3px;color:#999;">' + params.value.trim() + '</span>';
                    break;
                case 'No document found':
                    html = html + '<span class="label-danger" style="padding: 5px;color: white;">' + params.value.trim() + '</span>';
                    break;
                case 'Partial documents found':
                    html = html + '<span class="label-warning" style="padding: 5px;color: white;">' + params.value.trim() + '</span>';
                    break;
                case 'Documents found':
                    html = html + '<span class="bg-color-greenLight" style="padding: 5px;color: white;">' + params.value.trim() + '</span>';
                    break;
                case 'Not applicable':
                    html = html + '<span class="label-default" style="padding: 5px;color: white;">' + params.value.trim() + '</span>';
                    break;

                default:
                    break;
            }
        }

        var eDiv = document.createElement('div');
        eDiv.innerHTML = html;
        eDiv.style.textAlign = 'center';
        return eDiv;
    }

    statusDFPInnerCellRenderer(params: any) {
        var eSpan = document.createElement('span');
        if (params.value) {
            let dfpStatusName: string = params.value.trim();
            let currentStatus: DFPStatusModel = dBdfpStatuses.filter(x => x.Name == dfpStatusName)[0];
            if (currentStatus && currentStatus.Name) {
                if (currentStatus.Name == 'Suggested - no document found' || currentStatus.Name == 'Suggested - partial documents found' || currentStatus.Name == 'Suggested - documents found' || currentStatus.Name == 'Suggested - not applicable') {
                    eSpan.style.color = currentStatus.CSSClass;
                }
                else {
                    eSpan.style.color = 'white';
                    eSpan.style.backgroundColor = currentStatus.CSSClass;
                }
            }
        }

        eSpan.style.padding = '3px';
        eSpan.innerHTML = params.value;

        var eDiv = document.createElement('div');
        eDiv.appendChild(eSpan);
        eDiv.style.textAlign = 'center';
        return eDiv;
    }
    //#endregion


    ngOnDestroy() {
        if (this.assetStatusSub) {
            this.assetStatusSub.unsubscribe();
        }
    }

    //#region DFP Status
    getDFPStatus() {

        this._dashboardService.getAllDFPStatuses()
        .subscribe({
            next: (response) => {
                dBdfpStatuses = [];
                dBdfpStatuses = response;
            },
            error: (error) => {
            }
        });
    }

    getDFPStatusColor(stausName: string): string {
        let status = dBdfpStatuses.filter(x => x.Name == stausName);
        if (status && status.length > 0) {
            return status[0].CSSClass;
        }
        else {
            return '';
        }
    }
    //#endregion
}
