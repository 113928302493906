import { SelectItem } from "primeng/api";
import { AssetModel } from "../../services/asset/asset";

export class AssetGroupModel {
    constructor(
        public Name?:string,
        public AvailableAssets?: SelectItem[],
        public SelectedAssets?: SelectItem[],
        public Status:boolean = true
     ) {
    }

}

export enum OperationMode {
    Add = 1,
    Edit = 2,
    Delete = 3
}

