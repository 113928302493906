import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SharedService } from './shared/services/shared.service';

//#region Shared Components
import { AppComponent } from './shared/components/app-component/app.component';
import { PageNotFoundComponent } from './shared/components/not-found/not-found.component';
import { LoginComponent } from './shared/components/login/login.component';
import { LogoutComponent } from './shared/components/logout/logout.component';
import { SSOComponent } from './shared/components/sso/sso.component';
import { PortalComponent } from './shared/components/portal/portal.component';

//#endregion
//#region Record's Components
import { HomeComponent } from './records/components/home/home.component';
import { RecordComponent } from './records/components/record/record.component';
import { BrowseComponent } from './records/components/browse/browse.component';
import { SearchComponent } from './records/components/search/search.component';
import { RecordExportComponent } from './records/components/export/export.component';
import { DocumentViewerComponent } from './records/components/document-viewer/document-viewer.component';
//#endregion
//#region Admin's Components
import { AdminComponent } from './admin/components/admin/admin.component';
import { UsersComponent } from './admin/components/users/users.component';
import { WorkOrderComponent } from './admin/components/work-order/work-order.component';
import { AircraftComponent } from './admin/components/aircraft/aircraft.component';
import { AddAircraftComponent } from './admin/components/add-aircraft/add-aircraft.component';
import { EditAircraftComponent } from './admin/components/edit-aircraft/edit-aircraft.component';
import { DocumentTypesComponent } from './admin/components/document-types/document-types.component';
import { DocumentTypeComponent } from './admin/components/document-type/document-type.component';
import { InboxComponent } from './admin/components/inbox/inbox.component';
import { UserManagementComponent } from './admin/components/user-management/user-management.component';
import { ContainerTypeComponent } from './admin/components/container-types/container-type.component';
import { ViewSettingComponent } from './admin/components/view-setting/view-setting.component';
import { ConfigViewComponent } from './admin/components/config-view/config-view.component';
import { AddBuilderSearchComponent } from './admin/components/add-builderSearch/add-builderSearch.component';
import { SettingsComponent } from './admin/components/settings/settings.component';
import { AuditComponent } from './admin/components/audit/audit.component';
import { AlertComponent } from './admin/components/alert/alert.component';
import { UserGroupComponent } from './admin/components/user-groups/user-groups.component';
import { TagsComponent } from './admin/components/tags/tags.component';
import { DFPStatusComponent } from './admin/components/dfpstatus/dfpstatus.component';
import { HiddenComponent } from './admin/components/hidden/hidden.component';
import { ResetAzureSearchComponent } from './admin/components/reset-azure-search/reset-azure-search.component';
import { QualityStatusComponent } from './admin/components/qualitystatus/qualitystatus.component';
import { SearchSynonymsComponent } from './admin/components/search-synonyms/search-synonyms.component';
import { MarkerTypeComponent } from './admin/components/marker-type/marker-type.component';
import { ViewDataTypeComponent } from './admin/components/view-data-type/view-data-type.component';
import { AssetGroupComponent } from './admin/components/asset-group/asset-group.component';


//#endregion
//#region Dp jobs Components
import { JobsComponent } from './jobs/components/job/job.component';
import { ListJobsComponent } from './jobs/components/list-job/list-job.component';

//#endregion
//#region Status Services/Components
import { StatusComponent } from './status/components/status/status.component';
import { ViewList } from './status/components/view-list/view-list.component';
import { MSRComponent } from './status/components/msr/msr.component';
import { StatusExportComponent } from './status/components/export/export.component';
import { StatusDashboardComponent } from '../app/status/components/status-dashboard/status-dashboard.component';
//#endregion

//#region API Services/Components
import { APIComponent } from './api/components/api/api.component';
import { ListDataFeedComponent } from './api/components/list-datafeed/list-datafeed.component';
import { FieldMappingComponent } from './api/components/field-mapping/field-mapping.component';
import { FeedLogComponent } from './api/components/feed-log/feed-log.component';
import { RecordConfigurationComponent } from './api/components/record-configuration/record-configuration.component';
import { StatusConfigurationComponent } from './api/components/status-configuration/status-configuration.component';
//#endregion

//#region Inbox Services/Components
import { CommComponent } from './comm/components/comm/comm.component';
import { ListCommComponent } from './comm/components/list-comm/list-comm.component';
//#endregion

//#region Help/Components
import { PDFExtensionComponent } from './help/components/pdf-extension/pdf-extension.component';
import { DownloadComponent } from '../app/help/components/download/download.component';
import { FTPAplicationComponent } from './help/components/ftp-application/ftp-application.component';
import { TrainingComponent } from './help/components/training/training.component';
//#endregion

//#region  Report
import { ReportComponent } from '../app/reports/components/report/report.component';
import { AssetReportComponent } from '../app/reports/components/asset/asset.component';
import { JobReportComponent } from '../app/reports/components/job/job.component';
import { RoleGroupComponent } from './admin/components/role-group/role-group.component';
import { AddArticleComponent } from './help/components/add-article/add-article.component';
import { HelpComponent } from './help/components/help/help.component';
import { ArticleViewComponent } from './help/components/article-view/article-view.component';
import { EditArticleComponent } from './help/components/edit-article/edit-article.component';
import { CategoryComponent } from './help/components/category/category.component';
import { SectionComponent } from './help/components/section/section.component';
import { ArticleComponent } from './help/components/articles/articles.component';
import { FolderComponent } from './help/components/folder/folder.component';
import { SearchedArticlesComponent } from './help/components/searched-articles/searched-articles.component';
import { CanDeactivateDashoardGuard } from './shared/guards/can-deactive-dashboard-guard';
import { ContainerQualityStatusComponent } from './admin/components/container-quality-status/container-quality-status.component';
import { ReportScreenComponent } from './status/components/report-screen/report-screen.component';
import { ToolboxDownloadComponent } from './help/components/toolbox-download/toolbox-download.component';
import { TechSupportComponent } from './help/components/tech-support/tech-support.component';
import { CanDeactivateSettingsGuard } from './shared/guards/can-deactive-settings-guard';
import { ExportContainerConfigurationComponent } from './api/components/export-container-configuration/export-container-configuration.component';
import { ExportContainerComponent } from './records/components/export/export-container/export-container.component';


//#endregion
const routes: Routes = [
    //{ path: '', redirectTo: '', pathMatch: 'full' },
    {
        path: 'my-app',
        component: AppComponent
    },
    {
        path: 'default',
        component: LoginComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: 'Document',
        component: DocumentViewerComponent
    },
    {
        path: 'sso',
        component: SSOComponent
    },
    {
        path: 'portal',
        component: PortalComponent
    },
    {
        path: 'dashboard',
        component: RecordComponent,
        children: [
            {
                path: 'record',
                component: HomeComponent,
                canDeactivate: [CanDeactivateDashoardGuard]
            },
            {
                path: ':name',
                component: HomeComponent,
                canDeactivate: [CanDeactivateDashoardGuard]
            },
        ]
    },
    {
        path: 'record',
        component: RecordComponent,
        children: [
            {
                path: '',
                component: HomeComponent,
                // / canDeactivate: [DeactivateGuard]
            },
            {
                path: 'browse',
                component: BrowseComponent,
                data: [{ recordType: 'MXR' }]
            },
            {
                path: 'logs',
                component: BrowseComponent,
                data: [{ recordType: 'LOG' }]
            },
            {
                path: 'general',
                component: BrowseComponent,
                data: [{ recordType: 'GEN' }]
            },
            {
                path: 'documents',
                component: BrowseComponent,
                data: [{ recordType: 'DOC' }]
            },
            {
                path: 'search',
                component: SearchComponent,
                data: [{ recordType: 'Search' }]
            },

            {
                path: 'exportrecordsdocumentslist',
                component: RecordExportComponent,
                data: [{ recordType: 'Export Records Documents List' }]
            },
            {
                path: 'exportrecordsdocuments',
                component: RecordExportComponent,
                data: [{ recordType: 'Export Records Documents' }]
            },
            {
                path: 'exportcontainer',
                component: ExportContainerComponent,
                data: [
                  {
                    sourceType: 'Export Records Container List',
                    redirectUri: '/record/myreports',
                    isRecordExport: true
                  }
                ]
            },
            {
                path: 'myreports',
                component: ReportScreenComponent,
              data: [{ recordType: 'Records Export' }]
            },
            {
                path: 'pdf',
                component: RecordExportComponent,
                data: [{ recordType: 'PDF' }]
            },
            {
                path: 'xml',
                component: RecordExportComponent,
                data: [{ recordType: 'XML' }]
            },
        ]
    },
    {
        path: 'admin',
        component: AdminComponent,
        children: [
            {
                path: 'users',
                component: UsersComponent,
                },
            {
                path: 'asset',
                component: AircraftComponent,
               

            },
            {
                path: 'addAsset',
                component: AddAircraftComponent,

            },
            {
                path: 'editAsset/:id',
                component: EditAircraftComponent,

            },
            {
                path: 'workorder',
                component: WorkOrderComponent,

            },
            {
                path: 'documenttypes',
                component: DocumentTypesComponent,
                },
            {
                path: 'documenttype',
                component: DocumentTypeComponent,
            },
            {
                path: 'viewsetting',
                component: ViewSettingComponent,
                },
            {
                path: 'configview',
                component: ConfigViewComponent,
            },
            {
                path: 'configview/:id',
                component: ConfigViewComponent,
            },
            {
                path: 'configview/:id/:tab',
                component: ConfigViewComponent,
            },
            {
                path: 'inbox',
                component: InboxComponent,
                },
            {
                path: 'usermanagement',
                component: UserManagementComponent,
            },
            {
                path: 'usermanagement/:id',
                component: UserManagementComponent,
            },
            {
                path: 'containertype',
                component: ContainerTypeComponent,
                },
            {
                path: 'addBuilderSearch/:vID',
                component: AddBuilderSearchComponent,
            },
            {
                path: 'addBuilderSearch/:vID/:id',
                component: AddBuilderSearchComponent,
            },
            {
                path: 'settings',
                component: SettingsComponent,
                canDeactivate: [CanDeactivateSettingsGuard]
            },
            {
                path: 'audit',
                component: AuditComponent,
                },
            {
                path: 'alert',
                component: AlertComponent,
                },
            {
                path: 'usergroup',
                component: UserGroupComponent,
                },
            {
                path: 'tags',
                component: TagsComponent,
               

            },
            {
                path: 'dfpstatus',
                component: DFPStatusComponent,
               

            },
            {
                path: 'config',
                component: HiddenComponent,
                },
            {
                path: 'rolegroup',
                component: RoleGroupComponent,
               

            },
            {
                path: 'resetSearch',
                component: ResetAzureSearchComponent,
               

            },
            {
                path: 'searchsynonyms',
                component: SearchSynonymsComponent,
               

            },
            {
                path: 'qualitystatus',
                component: QualityStatusComponent,
                },
            {
                path: 'markertype',
                component: MarkerTypeComponent,
                },
            {
                path: 'containerqualitystatus',
                component: ContainerQualityStatusComponent,
                },
            {
                path: 'viewdatatype',
                component: ViewDataTypeComponent,
                },
            {
                path: 'assetgroup',
                component: AssetGroupComponent,
               

            }

        ]
    },
    {
        path: 'jobs',
        component: JobsComponent,
        children: [
            {
                path: 'new',
                component: ListJobsComponent,
                data: [{ recordType: '1' }]
            },
            {
                path: 'recieved',
                component: ListJobsComponent,
                data: [{ recordType: '2' }]

            },
            {
                path: 'pending',
                component: ListJobsComponent,
                data: [{ recordType: '3' }]

            },
            {
                path: 'inprogress',
                component: ListJobsComponent,
                data: [{ recordType: '4' }]

            },
            {
                path: 'complete',
                component: ListJobsComponent,
                data: [{ recordType: '5' }]

            },
            {
                path: 'ready',
                component: ListJobsComponent,
                data: [{ recordType: '6' }]

            },
        ]
    },
    {
        path: 'status',
        component: StatusComponent,
        children: [
            {
                path: 'viewlist',
                component: ViewList,
            },
            {
                path: 'viewlist/:id',
                component: ViewList,
            },
            {
                path: 'msr',
                component: MSRComponent,
            },
            {
                path: 'dashboard',
                component: StatusDashboardComponent,
            },
            {
                path: 'exportstatusdata',
                component: StatusExportComponent,
                data: [{ recordType: 'Export Views Status Data' }]
            },
            {
                path: 'myreports',
                component: ReportScreenComponent,
                data: [{ recordType: 'Status Export' }]
            },
            {
                path: 'tsv',
                component: StatusExportComponent,
                data: [{ recordType: 'TSV' }]
            },
            {
                path: 'exportstatuscompliancedocuments',
                component: StatusExportComponent,
                data: [{ recordType: 'Export Views Status Compliance Documents' }]
            },
            {
                path: 'xml',
                component: StatusExportComponent,
                data: [{ recordType: 'XML' }]
            },

        ]
    },
    {
        path: 'repair',
        component: StatusComponent,
        children: [
            {
                path: '',
                component: StatusDashboardComponent,//ViewList,
            },
            {
                path: 'viewlist',
                component: ViewList,
            },
            {
                path: 'viewlist/:id',
                component: ViewList,
            },
            {
                path: 'msr',
                component: MSRComponent,
            },
            {
                path: 'dashboard',
                component: StatusDashboardComponent,
            },
            {
                path: 'exportrepairdata',
                component: StatusExportComponent,
                data: [{ recordType: 'Export Views Repair Data' }]
            },
            {
                path: 'exportrepaircompliancedocuments',
                component: StatusExportComponent,
                data: [{ recordType: 'Export Views Repair Compliance Documents' }]
            },
            {
                path: 'myreports',
                component: ReportScreenComponent,
                data: [{ recordType: 'Repair Export' }]
            },
            {
                path: 'pdf',
                component: StatusExportComponent,
                data: [{ recordType: 'PDF' }]
            },
            {
                path: 'xml',
                component: StatusExportComponent,
                data: [{ recordType: 'XML' }]
            },

        ]
    },
    {
        path: 'apifeed',
        component: APIComponent,
        children: [
            {
                path: 'import',
                component: ListDataFeedComponent,
                data: [{ recordType: 'Import' }]
            },
            {
                path: 'export',
                component: ListDataFeedComponent,
                data: [{ recordType: 'Export' }]

            },
            //   {
            //       path: 'fieldmapping/:screen/:id',
            //       component: FieldMappingComponent,
            //   },
            //   {
            //       path: 'feedlog/:screen/:id',
            //       component: FeedLogComponent
            //   },
            {
                path: 'records/:screen/:id',
                component: RecordConfigurationComponent
            },
            {
                path: 'status/:screen/:id',
                component: StatusConfigurationComponent
            }
        ]
    },
    {
        path: 'inbox',
        component: CommComponent,
        children: [
            {
                path: 'new',
                component: ListCommComponent,
                data: [{ recordType: '1' }]
            },
            {
                path: 'open',
                component: ListCommComponent,
                data: [{ recordType: '2' }]

            },
            {
                path: 'pending',
                component: ListCommComponent,
                data: [{ recordType: '3' }]

            },
            {
                path: 'solved',
                component: ListCommComponent,
                data: [{ recordType: '4' }]

            },
            {
                path: 'closed',
                component: ListCommComponent,
                data: [{ recordType: '5' }]

            },
            {
                path: 'active',
                component: ListCommComponent,
                data: [{ recordType: '6' }]

            },
        ]
    },
    {
        path: 'help',
        component: CommComponent,
        children: [
            {
                path: 'support',
                component: InboxComponent
            },
            {
                path: 'techsupport',
                component: TechSupportComponent
            },
            {
                path: 'pdfextension',
                component: PDFExtensionComponent

            },
            {
                path: 'download',
                component: DownloadComponent

            },
            {
                path: 'toolboxdownload',
                component: ToolboxDownloadComponent

            },
            {
                path: 'ftpapplication',
                component: FTPAplicationComponent

            },
            {
                path: 'training',
                component: TrainingComponent

            },
            {
                path: 'search-articles/:id',
                component: SearchedArticlesComponent,
            },
            //   {
            //   path:'helparticles',
            //   component:HelpComponent,
            //     },
            {
                path: 'helparticles/:id',
                component: HelpComponent,
                },
            {
                path: 'articles',
                component: ArticleComponent,
            },
            {
                path: 'article/:id',
                component: ArticleViewComponent,
                },
            {
                path: 'folder/:id',
                component: FolderComponent,
            },
            {
                path: 'addArticle',
                component: AddArticleComponent,
            },
            {
                path: 'editArticle/:id',
                component: EditArticleComponent,
            },
            {
                path: 'category',
                component: CategoryComponent,
            },
            {
                path: 'section',
                component: SectionComponent,
            }
        ]
    },
    {
        path: 'reports',
        component: ReportComponent,
        children: [
            {
                path: 'finance',
                component: AssetReportComponent,
            },
            {
                path: 'jobs',
                component: JobReportComponent,
            },
        ]
    },
    {
        path: 'transition',
        component: RecordComponent,
        children: [
            {
                path: '',
                component: HomeComponent,
            },
            {
                path: 'binders',
                component: BrowseComponent,
                data: [{ recordType: 'BND' }]
            },
            {
                path: 'search',
                component: SearchComponent,
                data: [{ recordType: 'Search' }]
            },
            {
                path: 'myreports',
                component: ReportScreenComponent,
              data: [{ recordType: 'Transition Export' }]
            },
            {
              path: 'exportcontainer',
              component: ExportContainerComponent,
              data: [
                {
                  sourceType: 'Export Transition Container List',
                  redirectUri: '/transition/myreports',
                  isRecordExport: false
                }
              ]
            },
            {
                path: 'exporttransitiondocuments',
                component: RecordExportComponent,
                data: [{ recordType: 'Export Transition Documents' }]
            },
            {
                path: 'exporttransitiondocumentslist',
                component: RecordExportComponent,
                data: [{ recordType: 'Export Transition Documents List' }]
            },
            // {
            //     path: 'pdf',
            //     component: RecordExportComponent,
            //     data: [{ recordType: 'PDF' }]
            // },
            // {
            //     path: 'xml',
            //     component: RecordExportComponent,
            //     data: [{ recordType: 'XML' }]
            // }

        ]
    },

    { path: '**', component: PageNotFoundComponent }

];
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
  providers: [CanDeactivateDashoardGuard, CanDeactivateSettingsGuard]
})
export class AppRoutingModule {


}
