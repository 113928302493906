//Components
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { GridOptions } from "ag-grid-community";

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { SearchSynonymService } from '../../../admin/services/search-synonym/search-synonym-service'
import { AzureSearchSynonym, OperationMode, SearchSynonymSetting } from './search-synonym';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { SearchService } from '../../services/search/search.service';
var _router_: Router;
@Component({
    selector: 'app-search-synonyms',
    templateUrl: './search-synonyms.component.html',
    providers: [SearchSynonymService, ConfirmationService, SearchService]
})
export class SearchSynonymsComponent implements OnInit {
    display: boolean = false;
    public gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[];
    public canAddEditContainerType: boolean = false;
    public canAddContainerType: boolean = false;
    public canEditContainerType: boolean = false;
    searchSynonymID: string = null;
    ishidden: boolean = false;

    public totalCount: number;
    public model: AzureSearchSynonym;
    public searchSynonyms: AzureSearchSynonym[] = [];
    public isSubmitted: boolean = false;
    categoryTypeList: SelectItem[] = [];
    public dialogTitle: string = 'Add Synonym Set';
    public lblsubmitButton: string = 'Add';
    public synonymTypeMessage: string = "";
    public operationMode: OperationMode = OperationMode.Add;// 1;
    searchDatabaseList: SelectItem[] = [];
    searchDatabaseID: string = "0";

    public appHotkeys: Hotkey[] = [];

    constructor(public _sharedService: SharedService, private _router: Router, private _searchSynonymService: SearchSynonymService, private _hotkeysService: HotkeysService, private _confirmationService: ConfirmationService, private adminSearchService: SearchService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.UpdateRoles();
            _router_ = this._router;
            this.setHotKeys();
            this.navigateToNextCell=this.navigateToNextCell.bind(this);
        }
    }
    setHotKeys() {

        let hotKeyObj: Hotkey = null;

        //Add synonym set
        hotKeyObj = new Hotkey('ctrl+a', (event: KeyboardEvent): boolean => {
            if (_router_.url == "/admin/searchsynonyms") {
                this.btnAddClick();
            }
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

        //Edit synonym set
        hotKeyObj = new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
            if (_router_.url == "/admin/searchsynonyms") {
                this.btnEditClick();
            }
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
        hotKeyObj = new Hotkey('enter', (event: KeyboardEvent): boolean => {
            if (_router_.url == "/admin/searchsynonyms") {
                this.btnEditClick();
            }
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

        //Edit synonyms set
        hotKeyObj = new Hotkey('ctrl+d', (event: KeyboardEvent): boolean => {
            if (_router_.url == "/admin/searchsynonyms") {
                this.confirmDelete();
            }
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

    }
    ngOnInit() {
        this.model = new AzureSearchSynonym();
        if (this.canAddEditContainerType) {
            this.gridOptions = <GridOptions>
                {
                    rowSelection: 'single',

                    onGridReady: () => {
                        this.setUserGridColumns();
                        try {
                            this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridContainerTypes'));
                            this.gridOptions.api.hideOverlay();
                        } catch (e) {
                            console.log(e);
                        }
                    },
                    // suppressContextMenu: true,
                    pagination: true,
                    paginationPageSize: 20,
                    getContextMenuItems: this.getCustomContextMenuItems,
                    context: {
                        gridContext: this
                    },
                    defaultColDef: {
                        sortable: true,
                        filter: true
                    }
                };
            this.getCategoryList();
            this.populateSearchDatabase();
            this.populateSearchSynonymsGrid();
        }
    }
    ngOnDestroy() {
        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
    }


    populateSearchDatabase() {
        this.searchDatabaseList = [];

        this.adminSearchService.getSearchDatabase()
        .subscribe({
            next: (response) => {
                let res = response;
                this.searchDatabaseList = res;
            },
            error: (error) => {
            }
        });
    }

    onSearchDatabaseChange(event: any) {
        console.log(this.searchDatabaseID);
        this.populateSearchSynonymsGrid();
    }


    //#endregion
    // #region Category List
    getCategoryList(): void {
        this.categoryTypeList = this._searchSynonymService.getContainerCategories();
    }
    //#endregion
    //#region Ag Grid Population Event
    populateSearchSynonymsGrid(): void {

        this._searchSynonymService.getSearchSynonymMap(this.searchDatabaseID)
        .subscribe({
            next: (response) => {
                this.gridOptions.api.sizeColumnsToFit();
                this.gridData = [];
                this.searchSynonyms = [];
                this.gridData = response;
                this.searchSynonyms = response;
                this.totalCount = response.length;
            },
            error: (error) => {
                this.gridData = [];
                this.totalCount = 0;
            }
        });
    }
    //#endregion
    //#region Set Columns of Browse Ag Grid
    setUserGridColumns(): void {
        this.columnDefs = [];
        this.columnDefs =
            [
                { headerName: 'Keyword', field: 'Keyword', menuTabs: ['filterMenuTab'] },
                { headerName: 'Synonym', field: 'Synonym', menuTabs: ['filterMenuTab'] },
                { headerName: 'Type', field: 'Type', menuTabs: ['filterMenuTab'] },

            ];

    }
    tagsCellRenderer(params: any) {
        let html: string = '';

        if (params.value) {

            html = html + '<span class="padding-2 label" style= "background-color:' + params.data.ColorCode + ';">' + params.value + '</span>';

        }

        var eDiv = document.createElement('div');
        eDiv.innerHTML = html

        return eDiv;
    }

    //#endregion

    //#region Ag-Grid Events
    updateSelectedRow(event: any) {
        this.searchSynonymID = null;
        this.searchSynonymID = event.data.RowID;
    }
    //#endregion

    //#region Edit User Events
    containerRowDoubleClicked(event: any) {
        if (this.canEditContainerType) {
            this.searchSynonymID = null;
            this.searchSynonymID = event.data.RowID;
            this.btnEditClick();
        }
    }
    btnEditClick() {
        if (this.searchSynonymID) {
            this.operationMode = 2;
            this.dialogTitle = 'Edit Synonym Set';
            this.lblsubmitButton = 'Update';

            this.ishidden = true;

            let selectedRow = this.searchSynonyms ? this.searchSynonyms!.filter(a => a.RowID.toString() == this.searchSynonymID)[0] : null
            if (!selectedRow) {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'No Synonym Set Selected', detail: 'Please select a record.' });
            }


            // let body: any =
            //     {
            //         ContainerTypeID: this.searchSynonymID
            //     }
            //this._searchSynonymService.getSearchSynonymMap().subscribe( response => {
            let data = selectedRow;
            this.model = new AzureSearchSynonym();
            this.model.Type = data.Type;
            this.model.Keyword = data.Keyword;
            this.model.Synonym = data.Synonym;
            this.model.RowID = data.RowID;
            this.onTypeChange(null);
            this.showHideDialogue(true);

            // }, error => {
            //     this._sharedService.clearToast();
            //     this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Search Synonym not added, please try again.' });

            //     this.showHideDialogue(false);
            // }

            // );


        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Synonym Set Selected', detail: 'Please select a record.' });
        }
    }


    confirmDelete() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }


        let selectedRow = this.searchSynonyms ? this.searchSynonyms!.filter(a => a.RowID.toString() == this.searchSynonymID)[0] : null
        if (!selectedRow) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Synonym Set Selected', detail: 'Please select a record.' });
            return;
        }


        if (selectedRow) {
            this._confirmationService.confirm({
                message: 'Do you want to delete Synonym Set?',
                header: 'Delete Confirmation',
                icon: 'fa fa-trash',
                accept: () => {
                    this.deleteDocuments();
                },
                reject: () => {
                }
            });
        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Synonym Set Selected', detail: 'Please select a record.' });
        }
    }


    deleteDocuments() {
        let index = this.searchSynonyms.findIndex(synonymSet => synonymSet.RowID.toString() == this.searchSynonymID);
        this.searchSynonyms.splice(index, 1);
        //this.searchSynonyms
        let body: SearchSynonymSetting = {
            azureSearchSynonyms: this.searchSynonyms,
            searchDatabaseID: this.searchDatabaseID,
            searchDatabaseList: this.searchDatabaseList
        }
        this._searchSynonymService.addUpdateSearchSynonymType(body)
        .subscribe({
            next: (response) => {
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                this._sharedService.showToast(
                    { 
                        severity: 'success', 
                        summary: 'Deleted successfully', 
                        detail: 'Synonym set have been deleted successfully.' 
                    }
                );
                this.changeButtonsStatus(false);
                this.populateSearchSynonymsGrid();
            },
            error: (error) => {
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                this.populateSearchSynonymsGrid();
                if (error.status === 409) {
                    this._sharedService.showToast(
                        { 
                            severity: 'error', 
                            summary: 'An error occured.', 
                            detail: 'Please try again' 
                        }
                    );
                }
                else {
                    this._sharedService.showToast(
                        { 
                            severity: 'error', 
                            summary: 'An error occured.', 
                            detail: 'Please try again' 
                        }
                    );
                }
                this.model = new AzureSearchSynonym();
            }
        });
    }
    //#endregion
    //#region Add User Events
    btnAddClick() {
        this.operationMode = 1;
        this.dialogTitle = 'Add Synonyms Set';
        this.lblsubmitButton = 'Add';
        this.model = new AzureSearchSynonym();
        //let maxID=Math.max.apply(Math, this.searchSynonyms.map(function(o) { return o.RowID; }))
        //this.model.RowID = maxID + 1;
        // this.model.ColorCode = '#0976e3';
        this.showHideDialogue(true);
        this.synonymTypeMessage = "";
        this.ishidden = false;
    }
    //#endregion

    //#region User Roles Assignment
    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }

        this.canAddEditContainerType = this._sharedService.UserRole.Admin_Search_Synonym_Management;
        this.canAddContainerType = this._sharedService.UserRole.Admin_Search_Synonym_Management;
        this.canEditContainerType = this._sharedService.UserRole.Admin_Search_Synonym_Management;
    }
    //#endregion

    //#region Cancel Dialogue
    btnCancelClick(cancelForm: NgForm) {
        this.showHideDialogue(false);
        this.model = new AzureSearchSynonym();
        cancelForm.resetForm();
    }
    //#endregion
    //#region Helper Methods

    showHideDialogue(flag: boolean) {
        this.display = flag;
    }
    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }
    //#endregion

    //#region form submit
    onSubmit(form: NgForm) {

        this.changeButtonsStatus(true);
        this._sharedService.showLoader("Processing...");
        if (this.operationMode == 1) {
            this.model.OperationMode = "Add";//OperationMode.Add.toString();//this.operationMode.toString();
            this.searchSynonyms.push(this.model);
            //let body: any = this.searchSynonyms;

            let body: SearchSynonymSetting = {
                azureSearchSynonyms: this.searchSynonyms,
                searchDatabaseID: this.searchDatabaseID,
                searchDatabaseList: this.searchDatabaseList
            }

            // {
            //     ContainerTypeID: 0,
            //     Status: this.model.Status,
            //     Type: this.model.ContainerType,
            //     Category: this.model.ContainerCategory,
            //     Description: this.model.ContainerDescription,
            //     ColorCode: this.model.ColorCode
            // }
            this._searchSynonymService.addUpdateSearchSynonymType(body)
            .subscribe({
                next: (response) => {
                    this.showHideDialogue(false);
                    form.resetForm();
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast(
                        { 
                            severity: 'success', 
                            summary: 'Success', 
                            detail: 'Synonym Set added succesfully.' 
                        }
                    );
                    this.changeButtonsStatus(false);
                    this.populateSearchSynonymsGrid();
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this.populateSearchSynonymsGrid();
                    if (error.status === 409) {
                        this._sharedService.showToast(
                            { 
                                severity: 'error', 
                                summary: 'Error Occured', 
                                detail: 'Please try again.' 
                            }
                        );
                    }
                    else {
                        this._sharedService.showToast(
                            { 
                                severity: 'error', 
                                summary: 'Error Occured', 
                                detail: 'Synonym Set not added, please try again.' 
                            }
                        );
                    }
                    this.showHideDialogue(false);
                    this.model = new AzureSearchSynonym();
                    form.resetForm();
                    this.changeButtonsStatus(false);
                }
            });
        }
        else if (this.operationMode == 2) {

            this.model.OperationMode = "Edit";//OperationMode.Edit.toString();//this.operationMode.toString();
            let index = this.searchSynonyms.findIndex(synonymSet => synonymSet.RowID == this.model.RowID);
            this.searchSynonyms[index] = this.model;

            //let body: any = this.searchSynonyms;//
            let body: SearchSynonymSetting = {
                azureSearchSynonyms: this.searchSynonyms,
                searchDatabaseID: this.searchDatabaseID,
                searchDatabaseList: this.searchDatabaseList
            }

            this._searchSynonymService.addUpdateSearchSynonymType(body)
            .subscribe({
                next: (response) => {
                    this.showHideDialogue(false);
                    form.resetForm();
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast(
                        { 
                            severity: 'success', 
                            summary: 'Success', 
                            detail: 'Synonym Set updated succesfully.' 
                        }
                    );
                    this.changeButtonsStatus(false);
                    this.populateSearchSynonymsGrid();
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this.populateSearchSynonymsGrid();
                    if (error.status === 409) {
                        this._sharedService.showToast(
                            { 
                                severity: 'error', 
                                summary: 'Error Occured', 
                                detail: 'Synonym Set not updated, please try again.' 
                            }
                        );
                    }
                    else {
                        this._sharedService.showToast(
                            { 
                                severity: 'error', 
                                summary: 'Error Occured', 
                                detail: 'Synonym Set not updated, please try again.' 
                            }
                        );
                    }
                    this.showHideDialogue(false);
                    this.model = new AzureSearchSynonym();
                    form.resetForm();
                    this.changeButtonsStatus(false);
                }
            }); 
        }



 
  
   
}
    //#endregion
    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];

        if (params.context.gridContext.canAddEditContainerType) {
            result.push({
                name: "Add Synonym Set",
                action: function () {
                    params.context.gridContext.btnAddClick()
                },
                icon: params.context.gridContext._sharedService.addIconHTMLasString,
            });

            result.push({
                name: "Edit Synonym Set",
                action: function () {
                    params.context.gridContext.selectedContainerTypeID = null;
                    if (params.node) {
                        params.context.gridContext.selectedContainerTypeID = params.node.data.ContainerTypeID;
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext.btnEditClick()
                    }

                },
                icon: params.context.gridContext._sharedService.editIconHTMLasString,
            });

            let childResult: any[] = [];
            childResult.push({
                name: 'Excel',
                action: function () {
                    
                    params.api.exportDataAsExcel();
                    
                },
                icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
            });

            childResult.push({
                name: 'CSV',
                action: function () {
                    params.api.exportDataAsCsv();
                },
                icon: params.context.gridContext._sharedService.saveLogFeedIconHTMLasString,
            });

            result.push({
                name: 'Download',
                action: function () {


                },
                icon: params.context.gridContext._sharedService.downloadsFeedIconHTMLasString,
                subMenu: childResult,
            });
        }
        return result;


    }

    onTypeChange(event: any) {
        this.synonymTypeMessage = "";

        if (this.model && this.model.Type == "Equivalent") {
            this.synonymTypeMessage = 'Keyword:[date]=>Synonym:[date,dated,dates] Search query "date" will expand to "date" OR "dated" OR "dates"';
        } else if (this.model && this.model.Type == "Explicit") {
            this.synonymTypeMessage = 'Keyword:[date]=>Synonym:[date,dated,dates] Search queries "date","dated" or "dates" will all be replaced to "date"';
        }
    }
    navigateToNextCell(params: any) {
        var previousCell = params.previousCellPosition;
        var suggestedNextCell = params.nextCellPosition;
        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        switch (params.key) {
            case KEY_DOWN:
                var nextRowIndex = previousCell.rowIndex + 1;
                var renderedRowCount = this.gridOptions.api.getModel().getRowCount();
                if (nextRowIndex >= renderedRowCount) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
                    rowNode.setSelected(true);
                    this.searchSynonymID = null;
                    this.searchSynonymID = rowNode.data.RowID;
                        return {
                        rowIndex: nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_UP:
                var _nextRowIndex = previousCell.rowIndex - 1;
                if (_nextRowIndex < 0) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
                    rowNode.setSelected(true);
                    this.searchSynonymID = null;
                    this.searchSynonymID = rowNode.data.RowID;
                        return {
                        rowIndex: _nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw 'this will never happen, navigation is always on of the 4 keys above';
        }
    }

    //#endregion
}
