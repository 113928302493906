import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { Router } from '@angular/router';
import { IConfig } from '../../../shared/helpers/config';
//import flatpickr from '../../../../../node_modules/flatpickr/dist/flatpickr';


import * as _flatpickr from '../../../../../node_modules/flatpickr';
import { FlatpickrFn } from '../../../../../node_modules/flatpickr/dist/types/instance';
const flatpickr: FlatpickrFn = _flatpickr as any;

var dateFormatF: string;
var finalDateFormat: string;

var dateSeparatorS: string;
var dayD: number;
var monthM: number;
var yearY: number;

var sharedService: SharedService;

@Component({
  selector: 'app-custom-date',
  //templateUrl: './custom-date.component.html',
  //styleUrls: ['./custom-date.component.sass']
  template: `
        <div #flatpickrEl class="ag-input-wrapper custom-date-filter" role="presentation">
            <input type='text' data-input />
            <a class='input-button' title='clear' data-clear>
                <i class='fa fa-times'></i>
            </a>
        </div>
    `,
    styles: [
        `
  .custom-date-filter a {
    position: absolute;
    right: 20px;
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer;
  }

  .custom-date-filter:after {
    position: absolute;
    content: 'f073';
    display: block;
    font-weight: 400;
    font-family: 'Font Awesome 5 Free';
    right: 5px;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.54);
  }
    `
    ]
})
export class CustomDateComponent {//implements OnInit {

  public _finalDateFormat: string;
  public dateConfig: IConfig[] = [];

  @ViewChild("flatpickrEl", { read: ElementRef }) flatpickrEl: ElementRef;
  private date: Date;
  private params: any;
  private picker: any;

    public selectedDate: Date = new Date();

  constructor(private _sharedService: SharedService,  private _router: Router) {
    if (!this._sharedService.isAuthUser()) {
        this._router.navigate(['login']);
    }
    else {
        sharedService = this._sharedService;
        this.dateConfig = this._sharedService.getConfiguration();
        this.getDateFormatInfo();

    }
  }

  agInit(params: any): void {
    this.params = params;
    this.getDateFormatInfo();
}


    

  // ngOnInit() {
  //   this.getDateFormatInfo();
  // }

 
  ngAfterViewInit(): void {
    // outputs `I am span`
    this.picker = flatpickr(this.flatpickrEl.nativeElement, {
        allowInput: true,
        onChange: this.onDateChanged.bind(this),
        dateFormat: finalDateFormat,
        wrap: true
    });

    this.picker.calendarContainer.classList.add('ag-custom-component-popup');
}
  

  
  ngOnDestroy() {
      //console.log(`Destroying DateComponent`);
  }

  onDateChanged(selectedDates) {
    this.date = selectedDates[0] || null;
    this.params.onDateChanged();
    this.picker.close();
}

getDate(): Date {
    return this.date;
}

setDate(date: Date): void {
  
  this.date = date || null;
  this.picker.setDate(date);
  this.picker.close();
}

  //     //// #region date Format Settings

      getDateFormatInfo(): any {
        
        if (this.dateConfig != undefined)
        {
            dateFormatF = this.dateConfig.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
            dateSeparatorS = this.dateConfig.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
            dayD = Number(this.dateConfig.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
            monthM = Number(this.dateConfig.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
            yearY = Number(this.dateConfig.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
        }
        else
        {
                dateFormatF = localStorage.getItem('DateFormat');
                dateSeparatorS = localStorage.getItem('DateSeparater');
                dayD = Number(localStorage.getItem('DayIndex'));
                monthM = Number(localStorage.getItem('MonthIndex'));
                yearY = Number(localStorage.getItem('YearIndex'));
        }
        this.getfinalDateFormat();
        // console.log('dateFormatF => ' + dateFormatF);
        // console.log('finalDateFormat => ' + finalDateFormat);
        this._finalDateFormat = dateFormatF;
    }

    getfinalDateFormat(): any {
      //finalDateFormat = dateFormatF.toUpperCase(); // bs-date-format
      //yyyy-MM-dd
      finalDateFormat = dateFormatF;
      let dtFormat = dateFormatF.split(dateSeparatorS);
      dtFormat.forEach(element => {
        switch(element)
        {
          case 'yyyy':
            finalDateFormat = finalDateFormat.replace(element, 'Y');
            break;
          case 'MM':
            finalDateFormat = finalDateFormat.replace(element, 'm');
            break;
          case 'dd':
            finalDateFormat = finalDateFormat.replace(element, 'd');
            break;
        }
        
      });
      

     }


}
