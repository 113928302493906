<loader></loader>
<p-toast></p-toast>
<div id="main" class="clear-padding">

    <!-- MAIN CONTENT -->
    <div id="content" class="clear-padding">
        <router-outlet></router-outlet>

    </div>
    <!-- END MAIN CONTENT -->

</div>