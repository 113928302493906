export class Settings {

    constructor(
        public TimeFormat: string = "",
        public DateFormat: string = "",
        public TwoFactAuth: string = "",
        public TenantLogo: string = "",

        public ForgetFrom: string = "",
        public ForgetSubject: string = "",
        public ForgetAlias: string = "",
        public ForgetBody: string = "",

        public ShardKeyFrom: string = "",
        public ShardKeySubject: string = "",
        public ShardKeyAlias: string = "",
        public ShardKeyBody: string = "",

        public NewFrom: string = "",
        public NewSubject: string = "",
        public NewAlias: string = "",
        public NewBody: string = "",

        public InboxFrom: string = "",
        public InboxAlias: string = "",
        public InboxBody: string = "",

        public StorageCapacity: number = 10,
        public IntervalRecord: string = "",
        public IntervalStatus: string = "",
        public IntervalStats: string = "",
        public IntervalAssetCount: string = "",
        public IntervalInbox: string = "",
        public ContainerStatus: string = "",

        public IntervalGapAnalyzer: string = "",
        public GapAnalyzerQualityStatus: string = "",
        public GapAnalyzerLastRun: string = "",

        public IntervalAutoSearch: string = "",
        public DefaultSelected: string = "",
        public DefaultNotSelected: string = "",
        public AutoSearchLastRun: string = "",

        public JobNewFrom: string = "",
        public JobNewSubject: string = "",
        public JobNewAlias: string = "",
        public JobNewBody: string = "",
        public JobNewTo: string = "",

        public JobCompleteFrom: string = "",
        public JobCompleteSubject: string = "",
        public JobCompleteAlias: string = "",
        public JobCompleteBody: string = "",
        public JobCompleteTo: string = "",

        public JobUpdateFrom: string = "",
        public JobUpdateSubject: string = "",
        public JobUpdateAlias: string = "",
        public JobUpdateBody: string = "",
        public JobUpdateTo: string = "",

        public FTPHostName: string = "",
        public FTPUserName: string = "",
        public FTPPswd: string = "",

        public RecordHeader: string = "",
        public StatusHeader: string = "",
        public JobHeader: string = "",

        public APIRecordHeader: string = "",
        public APIStatusHeader: string = "",

        public PDFAPIRecordHeader: string = "",
        public PDFAPIRecordFooter: string = "",
        public PDFAPIStatusFooter: string = "",
        public PDFAPIStatusHeader: string = "",

        public ExportCompleteFrom: string = "",
        public ExportCompleteSubject: string = "",
        public ExportCompleteAlias: string = "",
        public ExportCompleteBody: string = "",

        public IncomingIssueFrom: string = "",
        public IncomingIssueSubject: string = "",
        public IncomingIssueAlias: string = "",
        public IncomingIssueBody: string = "",
        public IncomingIssueTo: string = "",

        public OutgoingIssueFrom: string = "",
        public OutgoingIssueSubject: string = "",
        public OutgoingIssueAlias: string = "",
        public OutgoingIssueBody: string = "",
        public OutgoingIssueTo: string = "",

        public IntervalOCR: string = "",
        public OCRLastRun: string = "",

        public AlertFrom: string = "",
        public AlertSubject: string = "",
        public AlertAlias: string = "",
        public AlertBody: string = "",
        public MaxDocumentSize: string = "",

        public IntervalAssetReport: string = "",
        public IntervalJobReport: string = "",

        public MXRQualityStatus: boolean = false,
        public LOGQualityStatus: boolean = false,
        public GENQualityStatus: boolean = false,
        public DOCQualityStatus: boolean = false,
        public BNDQualityStatus: boolean = false,

        public Container_MXRQualityStatus: boolean = false,
        public Container_LOGQualityStatus: boolean = false,
        public Container_GENQualityStatus: boolean = false,
        public Container_DOCQualityStatus: boolean = false,
        public Container_BNDQualityStatus: boolean = false,

        public Container_MX_Records_Column_Setting: string = "",
        public Container_Log_Books_Column_Setting: string = "",
        public Container_General_Column_Setting: string = "",
        public Container_Documents_Column_Setting: string = "",
        public Container_Binders_Column_Setting: string = "",

        public Container_MX_Records_Column_Setting_Data: any[] = [],
        public Container_Log_Books_Column_Setting_Data: any[] = [],
        public Container_General_Column_Setting_Data: any[] = [],
        public Container_Documents_Column_Setting_Data: any[] = [],
        public Container_Binders_Column_Setting_Data: any[] = [],

        public Document_MX_Records_Column_Setting: string = "",
        public Document_Log_Books_Column_Setting: string = "",
        public Document_General_Column_Setting: string = "",
        public Document_Documents_Column_Setting: string = "",
        public Document_Binders_Column_Setting: string = "",

        public Document_MX_Records_Column_Setting_Data: any[] = [],
        public Document_Log_Books_Column_Setting_Data: any[] = [],
        public Document_General_Column_Setting_Data: any[] = [],
        public Document_Documents_Column_Setting_Data: any[] = [],
        public Document_Binders_Column_Setting_Data: any[] = [],

        public Container_MXR_Sort_By: string = "",
        public Container_LOG_Sort_By: string = "",
        public Container_GEN_Sort_By: string = "",
        public Container_DOC_Sort_By: string = "",
        public Container_BND_Sort_By: string = "",

        public Document_MXR_Sort_By: string = "",
        public Document_LOG_Sort_By: string = "",
        public Document_GEN_Sort_By: string = "",
        public Document_DOC_Sort_By: string = "",
        public Document_BND_Sort_By: string = "",

        public Container_MXR_Order_By: string = "",
        public Container_LOG_Order_By: string = "",
        public Container_GEN_Order_By: string = "",
        public Container_DOC_Order_By: string = "",
        public Container_BND_Order_By: string = "",

        public Document_MXR_Order_By: string = "",
        public Document_LOG_Order_By: string = "",
        public Document_GEN_Order_By: string = "",
        public Document_DOC_Order_By: string = "",
        public Document_BND_Order_By: string = "",

    ) { }

}
export enum AppVersionXML {
    RDOCVersionXml =  0,
    ToolboxVersionXml = 1
  }
export interface settingsEntity {

    Key: string,
    Value: string
}
export interface settingsEntity {

    Key: string,
    Value: string
}
export interface IsettingsUpdateEntity {

    setting: settingsEntity[],
    settingType: string
}
export class settingsUpdateEntity {
    constructor(
        setting: settingsEntity[],
        settingType: string = "") { }
}

export class settingsEntityList {
    Key: string;
    Value: string;

    constructor(Key: string = "", Value: string = "") {
        this.Key = Key;
        this.Value = Value;

    }
}