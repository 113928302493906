export const environment = {
  production: true,
  baseUrl: 'https://api.roam.aero',
  hubUrl: 'https://api.roam.aero/signalr',
  hubName: 'EventHub',
  tenantUrl: 'https://{tenant}.roam.aero',
  tinyMceKey: 'penvdam45nu7sincf4odzgoxviqwdtxhovkabskp9x8sj5od'
  //tinyMceKey:'9tuba290qfuaxhfjso9i3edt864yx6xjo5eg4a5fkmd3cqp7'
};

