//Components
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { Loader } from '../loader/loader';
@Component({
    selector: 'loader',
    templateUrl: './loader.component.html',

})

export class LoaderComponent implements OnInit, OnDestroy {
    showHideLoader: string = "none";
    loadingText:string = "Loading..."
    iconClass: string = 'fa-spinner fa-spin';
    subscription: Subscription;

    constructor(private _sharedService: SharedService) {
    }

    ngOnInit() {

        this.subscription = this._sharedService._loading.subscribe(
            (loader: Loader) => {
                this.showHideLoader = loader.cssClass;
                this.loadingText = loader.loadingText;
                this.iconClass = loader.iconClass? loader.iconClass: this.iconClass;
            }
        );

    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }


}