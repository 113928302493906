import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { viewJobProgress } from '../../../status/components/view-job-progress/view-job-progress';

@Component({
  selector: 'view-job-progress',
  templateUrl: './view-job-progress.component.html',
})
export class ViewJobProgressComponent implements OnInit {

  public _processes: viewJobProgress[] = [];

  constructor(private _SharedService: SharedService) {
    this._processes = [];

    this._SharedService._viewTasksChangeEvent
      .subscribe({
        next: (data: viewJobProgress[]) => {
          if (data && data.length > 0) {
            this.updateProgress(data);
          }
        }
      });
  }


  ngOnInit() {
    if (this._SharedService.runningViewTasks) {
      this.updateProgress(this._SharedService.runningViewTasks);
    }
  }


  updateProgress(viewJobProgress: viewJobProgress[]): void {
    this._processes = [];
    for (let index = 0; index < viewJobProgress.length; index++) {
      if (viewJobProgress[index].State != "Complete") {
        this._processes.push(viewJobProgress[index]);
      }

    }


  }

  btnProgressCancelClick() {




  }

}
