//Components
import { Component, OnInit, Input, OnDestroy,ViewChild, ElementRef  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { GridOptions,GridApi } from "ag-grid-community";

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { SelectItem } from 'primeng/api';
import { TagService } from '../../../shared/services/tag/tag.service';
import { TagsModel, OperationMode } from './tags';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
var _router_: Router;
@Component({
    selector: 'tags',
    templateUrl: './tags.component.html',
    providers: [TagService]

})

export class TagsComponent implements OnInit, OnDestroy {
    display: boolean = false;
    public gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[];

    public canAddEditTag: boolean = false;

    selectedTagID: string = null;

    public totalCount: number;
    public model: TagsModel;
    public isSubmitted: boolean = false;
    TagList: SelectItem[] = [];
    public dialogTitle: string = 'Add Tag';
    public lblsubmitButton: string = 'Add';
    public operationMode: OperationMode = 1;
    @ViewChild('tagfocus') tagfocus: ElementRef;
    public appHotkeys:  Hotkey[] = [];

    //#region Initialization of Component
    constructor(private _sharedService: SharedService, private _router: Router, private _tagService: TagService, private _hotkeysService: HotkeysService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.UpdateRoles();
            _router_ = this._router;
            this.setHotKeys();
            this.navigateToNextCell=this.navigateToNextCell.bind(this);
        }
    }
    setHotKeys() {

        let hotKeyObj : Hotkey = null;

        //Add tag
        hotKeyObj = new Hotkey('ctrl+a', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/tags")
            {
                this.btnAddClick();
            }
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);


        //Edit tag
        hotKeyObj = new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/tags")
            {
                this.btnEditClick();
            }
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
        hotKeyObj = new Hotkey('enter', (event: KeyboardEvent): boolean => {
            if(_router_.url == "/admin/tags")
            {
                this.btnEditClick();
            }
            return false; // Prevent bubbling
        });
        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);

    }
    ngOnInit() {
        this.model = new TagsModel();
        if (this.canAddEditTag) {
            this.gridOptions = <GridOptions>
                {
                    rowSelection: 'single',

                    onGridReady: () => {
                        this.setUserGridColumns();
                        try {
                            this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridTags'));
                            this.gridOptions.api.hideOverlay();
                        } catch (e) {
                            console.log(e);
                        }
                    },
                    //suppressContextMenu: true,
                    pagination: true,
                    paginationPageSize: 20,
                    getContextMenuItems: this.getCustomContextMenuItems,
                    context: {
                        gridContext: this
                    },
                    defaultColDef: {
                        sortable: true,
                        filter:true
                    }

                };
           // this.getCategoryList();
            this.populateTagGrid();
        }
    }

    ngOnDestroy() {
        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
    }

    //#endregion

    //#region Ag Grid Population Event
    populateTagGrid(): void {

        this._tagService.getAllTagsData(null)
        .subscribe({
            next: (response) => {
                this.gridOptions.api.sizeColumnsToFit();
                this.gridData = [];
                this.gridData = response;
                this.totalCount = response.length;
            },
            error: (error) => {
                this.gridData = [];
                this.totalCount = 0;
            }
        });
    }
    //#endregion
    //#region Set Columns of Browse Ag Grid
    setUserGridColumns(): void {
        this.columnDefs = [];
        this.columnDefs =
            [
            { headerName: 'Tag Name', field: 'Name', menuTabs: ['filterMenuTab'], cellRenderer: this.tagsCellRenderer },
                {
                    headerName: 'Status', field: 'Status', filter:false, suppressMenu: true, valueFormatter: function (params: any) {
                        if (params.value == true) {
                            return "Active";
                        }
                        else {
                            return "Inactive";
                        }
                    }
                }
            ];

    }
    tagsCellRenderer(params: any) {
        let html: string = '';

        if (params.value) {

            html = html + '<span class="padding-2 label" style= "background-color:' + params.data.Color + ';">' + params.value + '</span>';

        }

        var eDiv = document.createElement('div');
        eDiv.innerHTML = html

        return eDiv;
    }

    //#endregion

    //#region Ag-Grid Events
    updateSelectedRow(event: any) {
        this.selectedTagID = null;
        this.selectedTagID = event.data.TagID;
    }
    //#endregion

    //#region Edit User Events
    containerRowDoubleClicked(event: any) {
        if (this.canAddEditTag)
        {
            this.selectedTagID = null;
            this.selectedTagID = event.data.TagID;
            this.btnEditClick();
        }
    }
    btnEditClick() {
        let selectedRows=this.gridOptions.api.getSelectedRows();
        if (this.selectedTagID && (selectedRows && selectedRows.length>0)) {
            this.operationMode = 2;
            this.dialogTitle = 'Edit Tag';
            this.lblsubmitButton = 'Update';

            this._tagService.getTagByID(this.selectedTagID)
            .subscribe({
                next: (response) => {
                    let data = response;
                    this.model = new TagsModel();
                    this.model.Color = data.Color;
                    this.model.TagID = data.TagID;
                    this.model.Status = data.Status;
                    this.model.Name = data.Name;
                    this.showHideDialogue(true);
                },
                error: (error) => {
                    this._sharedService.clearToast();
                    this._sharedService.showToast(
                        { 
                            severity: 'error', 
                            summary: 'Error Occured', 
                            detail: 'Tag not added, please try again.' 
                        }
                    );
        
                    this.showHideDialogue(false);
                }
            });
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Tag Selected', detail: 'Please select a record.' });
        }
    }
    //#endregion
    //#region Add User Events
    btnAddClick() {
        this.operationMode = 1;
        this.dialogTitle = 'Add Tag';
        this.lblsubmitButton = 'Add';

        this.model = new TagsModel();

        this.model.Status = true;
        this.model.Color = '#0976e3';
        this.showHideDialogue(true);
    }
    //#endregion

    //#region User Roles Assignment
    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }

        this.canAddEditTag = this._sharedService.UserRole.Admin_Tag;
    }
    //#endregion

    //#region Cancel Dialogue
    btnCancelClick(cancelForm: NgForm) {
        this.showHideDialogue(false);
        this.model = new TagsModel();
        cancelForm.resetForm();
    }
    //#endregion
    //#region Helper Methods
    showHideDialogue(flag: boolean) {
        this.display = flag;
    }
    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }
    //#endregion

    //#region form submit
    onSubmit(form: NgForm) {
        this.changeButtonsStatus(true);
        this._sharedService.showLoader("Processing...");
        if (this.operationMode == 1) {
            let body: any =
                {
                    TagID: 0,
                    Status: this.model.Status,
                    Name: this.model.Name,
                    Color: this.model.Color
                }
            this._tagService.addUpdateTags(body)
            .subscribe({
                next: (response) => {
                    this.showHideDialogue(false);
                    form.resetForm();
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast(
                        { 
                            severity: 'success', 
                            summary: 'Success', 
                            detail: 'Tag added succesfully.' 
                        }
                    );
                    this.changeButtonsStatus(false);
                    this.populateTagGrid();
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    if (error.status === 409) {
                        this._sharedService.showToast(
                            { 
                                severity: 'error', 
                                summary: 'Already Exists', 
                                detail: 'Tag already exists.' 
                            }
                        );
                    }
                    else {
                        this._sharedService.showToast(
                            { 
                                severity: 'error', 
                                summary: 'Error Occured', 
                                detail: 'Tag not added, please try again.' 
                            }
                        );
                    }
                    this.showHideDialogue(false);
                    this.model = new TagsModel();
                    form.resetForm();
                    this.changeButtonsStatus(false);
                }
            });
        }
        else if (this.operationMode == 2) {
            let body: any =
                {
                    TagID: this.selectedTagID,
                    Status: this.model.Status,
                    Name: this.model.Name,
                    Color: this.model.Color
                }
            this._tagService.addUpdateTags(body)
            .subscribe({
                next: (response) => {
                    this.showHideDialogue(false);
                    form.resetForm();
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast(
                        { 
                            severity: 'success', 
                            summary: 'Success', 
                            detail: 'Tag updated succesfully.' 
                        }
                    );
                    this.changeButtonsStatus(false);
                    this.populateTagGrid();
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    if (error.status === 409) {
                        this._sharedService.showToast(
                            { 
                                severity: 'error', 
                                summary: 'Already Exists', 
                                detail: 'Tag already exists.' 
                            }
                        );
                    }
                    else {
                        this._sharedService.showToast(
                            { 
                                severity: 'error', 
                                summary: 'Error Occured', 
                                detail: 'Tag not updated, please try again.' 
                            }
                        );
                    }
                    this.showHideDialogue(false);
                    this.model = new TagsModel();
                    form.resetForm();
                    this.changeButtonsStatus(false);
                }
            });
        }



    }
    //#endregion

    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];

        if (params.context.gridContext.canAddEditTag) {
            result.push({
                name: "Add Tag",
                action: function () {

                    params.context.gridContext.btnAddClick()
                },
                icon: params.context.gridContext._sharedService.addIconHTMLasString,
            });

            result.push({
                name: "Edit Tag",
                action: function () {
                    params.context.gridContext.selectedTagID = null;
                    if (params.node) {
                        params.context.gridContext.selectedTagID = params.node.data.TagID;
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext.btnEditClick()
                    }

                },
                icon: params.context.gridContext._sharedService.editIconHTMLasString,
            });

            let childResult: any[] = [];
                childResult.push({
                    name: 'Excel',
                    action: function () {
                        
                        params.api.exportDataAsExcel();
                        
                    },
                    icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
                });
    
                childResult.push({
                    name: 'CSV',
                    action: function () {
                        params.api.exportDataAsCsv();
                    },
                    icon: params.context.gridContext._sharedService.saveLogFeedIconHTMLasString,
                });
    
                result.push({
                    name: 'Download',
                    action: function () {
    
    
                    },
                    icon: params.context.gridContext._sharedService.downloadsFeedIconHTMLasString,
                    subMenu: childResult,
                });
        }
        return result;


    }
    //#endregion

    applyFocusToInput(){

        this.tagfocus.nativeElement.focus();
      }
      navigateToNextCell(params: any) {
        var previousCell = params.previousCellPosition;
        var suggestedNextCell = params.nextCellPosition;
        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        switch (params.key) {
            case KEY_DOWN:
                var nextRowIndex = previousCell.rowIndex + 1;
                var renderedRowCount = this.gridOptions.api.getModel().getRowCount();
                if (nextRowIndex >= renderedRowCount) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedTagID = null;
                    this.selectedTagID = rowNode.data.TagID;
                        return {
                        rowIndex: nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_UP:
                var _nextRowIndex = previousCell.rowIndex - 1;
                if (_nextRowIndex < 0) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedTagID = null;
                    this.selectedTagID = rowNode.data.TagID;
                        return {
                        rowIndex: _nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw 'this will never happen, navigation is always on of the 4 keys above';
        }
    }
}
