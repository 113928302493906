//Components
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs'

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { DocumentTypeService } from '../../services/document-type/document-type.service';
import { DocumentTypeEntity } from '../../services/document-type/document-type';
import { NgForm } from '@angular/forms';
import { GridOptions, GridApi } from "ag-grid-community";
import { ViewService } from '../../services/view/view.service'
import { ViewModel, Column, Condition, OrderBy } from '../../services/view/view';
var _router: Router;

@Component({
    selector: 'buildersetting',
    templateUrl: './builder-settings.component.html',
    providers: [DocumentTypeService, ViewService]

})

export class BuilderSettingsComponent implements OnInit {

    public statusConfigID: string;
    public gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[];
    public canAddEditDocType: boolean = false;
    public addStatusConfigURL: string = "/admin/addStatusConfig";
    private api: GridApi;
    totalCount: string;
    model: ViewModel;
    
    conditions: any[];
    whereConditions: any[];
    orderBy: any[];
    fields: any[];
    mappingfields: any[];
    private data: Observable<string>;
    trackByIndex:number;

    constructor(private sharedService: SharedService, private router: Router, private _viewService: ViewService ) {
        if (!this.sharedService.isAuthUser()) {
            this.router.navigate(['login']);
            this.sharedService.SessionExpiredMessage();
        }
        else {
            //this.updateRoles();
            _router = this.router;
        }
    }


    ngOnInit() {
        this.gridOptions = <GridOptions>
            {
                rowSelection: 'single',

                onGridReady: () => {
                    this.setGridColumns();

                },
                onModelUpdated: (event: any) => {
                    this.gridOptions.api.sizeColumnsToFit();
                },
                suppressContextMenu: true,
                defaultColDef:{
                    resizable:true,
                }                
            }
            //this.bindGrid();
        this.loadGridData();
        this.model = new ViewModel();
        this.populateFields();
        this.populateConditions();
        this.populateWhereConditions();
        this.populateOrderByDropDown();
       // this.populateView();
        this.populateMappingColumns();
    }

    
    //#region Populate Dropdowns and  View's Data
    populateMappingColumns() {
        this.mappingfields = [];
        this.mappingfields = [{ label: "Sq No", value: "Sq No" },
        { label: "Serial No.", value: "Serial No." },
        { label: "Type", value: "Type" },
        { label: "DFP Status", value: "DFP Status" },
        { label: "Issues (Flag)", value: "Issues (Flag)" },
        {
            label: "AD - Section", value: "AD - Section"
        },
        { label: "AD - Number", value: "AD - Number" },
        { label: "AD - Rev", value: "AD - Rev" },
        { label: "References", value: "References" },
        { label: "Description", value: "Description" },
        { label: "Type", value: "Type" },
        { label: "Status", value: "Status" },
        { label: "Threshold - FH", value: "Threshold - FH" },
        { label: "Threshold - FC", value: "Threshold - FC" },
        { label: "Threshold - Day", value: "Threshold - Day" },
        { label: "Interval - FH", value: "Interval - FH" },
        { label: "Interval - FC", value: "Interval - FC" },
        { label: "Interval - Day", value: "Interval - Day" },
        { label: "Last Done - Date", value: "Last Done - Date" },
        { label: "Last Done - FH", value: "Last Done - FH" },
        { label: "Last Done - FC", value: "Last Done - FC" },
        { label: "Next Due - Date", value: "Next Due - Date" },
        { label: "Next Due - FH", value: "Next Due - FH" },
        { label: "Next Due - FC", value: "Next Due - FC" },
        { label: "Time Remaining - Date", value: "Time Remaining - Date" },
        { label: "Time Remaining - FH", value: "Time Remaining - FH" },
        { label: "Time Remaining - FC", value: "Time Remaining - FC" },
        { label: "Remarks", value: "Remarks" },
        ];

    }
    populateFields() {
        this._viewService.getColumns("Task")
        .subscribe({
            next: (response) => {
                this.fields = response;
            },
            error: (error) => {
                console.log(error)
            }
        });
    }
    populateConditions() {
        this.conditions = [{ label: " is equal to", value: "Eq" },
        { label: "is not equal to", value: "Neq" },
        { label: "is greater than", value: "Gt" },
        { label: " is less than", value: "Lt" },
        { label: "is greater than or equal to", value: "Geq" },
        { label: " is less than or equal to", value: "Leq" },
        { label: "begins with", value: "BeginsWith" },
        { label: " contains", value: "Contains" }
        ];
    }

    populateOrderByDropDown() {
        this.orderBy = [{ label: "Ascending", value: "Ascending" },
        { label: "Descending ", value: "Descending" }
        ];
    }

    populateWhereConditions() {
        this.whereConditions = [{ label: "And", value: "And" },
        { label: "Or", value: "Or" }
        ];
    }

 

    // populateView() {

    //     this.model.Columns = [{ FieldName: "Text1", FieldLabel: "AD No", GroupLabel: "", ColumnWidth: "", FieldOrder: 0 },
    //         { FieldName: "Text2", FieldLabel: "SB No", GroupLabel: "", ColumnWidth: "", FieldOrder: 1 },
    //         { FieldName: "Text3", FieldLabel: "HT No", GroupLabel: "", ColumnWidth: "", FieldOrder: 2 }
    //     ];

    //     this.model.Conditions = [{ FieldName: "ASN", ConditionType: "Equals", FieldLabel: "", Value: "{currentASN}", JoinCondition: "" },
    //         { FieldName: "TaskType", ConditionType: "Equals", Value: "AD", FieldLabel: "", JoinCondition: "Or" },
    //     ];

    //     this.model.OrderBy = [{ FieldName: "DocDate", Order: "Ascending" },
    //     { FieldName: "RecordStatus", Order: "Descending" }];

    //     this.model.GroupBy = ["DocDate", "RecordStatus",];

    // }

    //#endregion

    //#region Add/Delete Query Components

    addColumn() {
        let col: any = { FieldName: "", FieldLabel: "", GroupLabel: "", FieldOrder: this.model.Columns.length };
        this.model.Columns.push(col);

    }

    deleteColumn(column: Column) {
        let index: number = this.model.Columns.indexOf(column);
        this.model.Columns.splice(index, 1);
        //this.model.Columns = this.model.Columns.filter(item => item.FieldName !== column.FieldName);
    }

    addCondition() {
        this.model.Conditions.push(new Condition(''));

    }

    deleteCondition(index: number) {
        this.model.Conditions.splice(index, 1);
    }

    addOrderBy() {
        this.model.OrderBy.push(new OrderBy(''));

    }

    deleteOrderBy(index: number) {
        this.model.OrderBy.splice(index, 1);
    }

    addGroupBy() {
        this.model.GroupBy.push("");

    }

    deleteGroupBy(index: number) {
        this.model.GroupBy.splice(index, 1);
    }

    //#endregion
    setGridColumns(): void {
        this.columnDefs = [];
        this.columnDefs =
            [
            { headerName: 'Order', field: 'Order', filter: 'text',  suppressMenu: true },
            { headerName: 'Execute', field: 'Execute', filter: 'text',  suppressMenu: true },
                {
                    headerName: 'Status', field: 'Status', filter: 'text',  suppressMenu: true, valueFormatter: function (params: any) {
                        if (params.value == true) {
                            return "Active";
                        }
                        else {
                            return "Inactive";
                        }
                    }
                }
            ];
    }
    loadGridData(): void {

        let userRole: string = localStorage.getItem('roles').trim();
        this.gridData = [];
            this.gridData = this.getBuilderSettingsData();
            this.totalCount = "5";
        //this.documentTypeService.getDocumentTypesDetail().subscribe( response => {
        //    this.gridData = [];
        //    this.gridData = response;
        //    this.totalCount = response.length;
        //});

    }
    updateRoles() {
        if (!this.sharedService.UserRole) {
            this.sharedService.updateRole();
        }

        this.canAddEditDocType = this.sharedService.UserRole.Admin_Doctype;
    }

    handleSelectedDocTypes() {

        var rowCount = this.api.getSelectedNodes().length;
        if (rowCount > 0) {
            var lastRowData = this.api.getSelectedNodes()[rowCount - 1].data;
            this.statusConfigID = lastRowData.DocumentTypeID.toString();
        }
    }
    handlereloadGrid(condition: boolean): void {
        this.loadGridData();
    }
    getBuilderSettingsData():any[]
    {
        return [{ Order: "1", Execute: "All", Status: true },
            { Order: "2", Execute: "All", Status: false },
            { Order: "3", Execute: "All", Status: true },
            { Order: "4", Execute: "All", Status: true },
            { Order: "5", Execute: "All", Status: false },
        ];
    }
    /**
 * Window resize event handling
 */
    //@HostListener('window:resize', ['$event'])
    //onResize(event: any) {
    //    console.log("Width: " + event.target.innerWidth);
    //    if (this.api) {
    //        this.api.sizeColumnsToFit();
    //    }
    //}  
}   