//Components
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
//Services
import { SharedService } from "../../../shared/services/shared.service";
import { SelectItem } from "primeng/api";
import { AddContainerModel } from "./add-container";
import { NgForm } from "@angular/forms";
import { ContainerService } from "../../services/container/container.service";
import { container, containerForAdd } from "../../services/container/container";
import { IConfig } from "../../../shared/helpers/config";
import * as moment from "moment/moment";
import { HotkeysService, Hotkey } from "angular2-hotkeys";
import { Subscription } from "rxjs";
import { ContainerQualityStatusService } from "../../../admin/services/containerqualitystatus/container-quality-status.service";
import { isNullOrUndefined } from '../../../shared/classes/nullorundefined';

//import 'moment/locale/uk';
@Component({
  selector: "add-container",
  templateUrl: "./add-container.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ContainerService, ContainerQualityStatusService],
})
export class AddContainerComponent implements OnInit, OnDestroy {
  display: boolean = false;
  @Input() recordType: string;
  dialogTitle: string = "";
  selectedWOType: string;
  WOTypes: SelectItem[] = [];
  model: AddContainerModel;
  dateSeparator = "";
  dateFormat = "";
  dateFormatCalender = "";
  configuration: IConfig[] = [];
  private day: number;
  private month: number;
  private year: number;
  isSubmitted: boolean = false;
  boxTypeResults: string[] = [];
  boxTypeSuggestion: any[];

  boxLocationResults: string[] = [];
  boxLocationSuggestion: any[];

  scannedByResults: string[] = [];
  scannedBySuggestion: any[];

  boxIDResults: string[] = [];
  boxIDSuggestion: any[];

  isAuthorizedUser: boolean = false;
  private userRole: string = localStorage.getItem("roles");

  canMakeContainerLive: boolean = false;

  WorkOrderLabel: string = "";
  addContainerSub: Subscription;
  public referenceKeyLabel: string = "";
  public Log_Books_IncrementValue: number = null;

  public appHotkeys: Hotkey[] = [];
  public ViewDateFormat: string;
  public lastIsDot: boolean = false;
  public lastIsDotWonum: boolean = false;

  public canMXRQualityStatus: boolean = false;
  public canLOGQualityStatus: boolean = false;
  public canGENQualityStatus: boolean = false;
  public canDOCQualityStatus: boolean = false;
  public canBNDQualityStatus: boolean = false;
  public processMXRQualityStatusEnabled: boolean = false;
  public processLOGQualityStatusEnabled: boolean = false;
  public processGENQualityStatusEnabled: boolean = false;
  public processDOCQualityStatusEnabled: boolean = false;
  public processBNDQualityStatusEnabled: boolean = false;
  qualityStatusList: SelectItem[] = [];

  //#region Initialization of Component
  constructor(
    public _sharedService: SharedService,
    private _router: Router,
    private _containerService: ContainerService,
    private _hotkeysService: HotkeysService,
    private cRef: ChangeDetectorRef,
    private _qualityStatusService: ContainerQualityStatusService
  ) {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(["login"]);
    } else {
      this.setHotKeys();
    }
  }
  ngOnInit() {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(["login"]);
      this._sharedService.SessionExpiredMessage();
    }
    this.referenceKeyLabel = this._sharedService.getReferenceKeyLabel();
    this.setDefaultComponentValues();
    this.populateQualityStatusDropdown();
    try {

      this.recordType = this._sharedService.recordType.toUpperCase();
      switch (this.recordType) {
        case "MXR":
          this.canMXRQualityStatus = this._sharedService.UserRole.Records_MXR_Container_Quality_Status;
          this.processMXRQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations(
            "Container_MX_Records_Quality_Process"
          );
          break;
        case "LOG":
          this.canLOGQualityStatus = this._sharedService.UserRole.Records_LOG_Container_Quality_Status;
          this.processLOGQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations(
            "Container_Log_Books_Quality_Process"
          );
          break;
        case "GEN":
          this.canGENQualityStatus = this._sharedService.UserRole.Records_GEN_Container_Quality_Status;
          this.processGENQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations(
            "Container_General_Quality_Process"
          );
          break;
        case 'DOC':
          this.canDOCQualityStatus = this._sharedService.UserRole.Records_DOC_Container_Quality_Status;
          this.processDOCQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations(
            "Container_Documents_Quality_Process"
          );
          break;

        case 'BND':
          this.canBNDQualityStatus = this._sharedService.UserRole.Transition_BND_Container_Quality_Status;
          this.processBNDQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations(
            "Container_Binders_Quality_Process"
          );
          break;
      }
    } catch (e) {
      console.log(e);
    }
    this.addContainerSub = this._sharedService._addContainerByObject
      .subscribe({
        next: (response) => {
          this.showAddDialog();
        },
        error: (error) => {
        }
      })

    this.ViewDateFormat = this.getDateFormatTime();
  }

  setHotKeys() {
    let hotKeyObj: Hotkey = null;

    hotKeyObj = new Hotkey("ctrl+a", (event: KeyboardEvent): boolean => {
      this.showAddDialog();
      return false; // Prevent bubbling
    });

    this._hotkeysService.add(hotKeyObj);
    this.appHotkeys.push(hotKeyObj);
  }
  ngOnDestroy() {
    if (this.addContainerSub) {
      this.addContainerSub.unsubscribe();
    }

    this.appHotkeys.forEach((hotkey) => {
      //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
      this._hotkeysService.remove(hotkey);
    });
    this.appHotkeys = [];
  }

  populateQualityStatusDropdown() {
    this._qualityStatusService.getActiveQualityStatusDataForDropDown(null)
      .subscribe({
        next: (response) => {
          this.qualityStatusList = response;
        },
        error: (error) => {
        }
      });
  }
  //#endregion

  // #region Form Events

  showAddDialog() {
    if (!this._sharedService.isAuthUser()) {
      this._router.navigate(["login"]);
    }
    let isAssetSelected: string[] = this._sharedService.selectedAsset;
    let selectedRow = this._sharedService.selectedContainer;
    if (selectedRow.length > 1) {
      this._sharedService.clearToast();
      this._sharedService.showToast({ severity: 'warn', summary: 'Multi Row selected', detail: 'Please select single container row.' });
      return;
    }
    if (
      this._sharedService.selectedAsset != undefined &&
      isAssetSelected.length > 0
    ) {

      this.configuration = (this.configuration == null || this.configuration.length == 0) ? this._sharedService.getConfiguration() : this.configuration;
      this.Log_Books_IncrementValue = Number(this.configuration.filter((x) => x.Key == "Log_Books_IncrementValue").map(({ Value }) => Value));
      this.setCalenderDateFormat();
      this.setWOType();
      this.setBoxTypeSuggestions();
      this.setBoxLocationSuggestions();
      this.setScannedBySuggestions();
      this.setBoxIDSuggestions();
      this.checkUserRoles();
      this.dialogTitle = this.getDialogueTitle();
      this.resetModel();
      if (this.qualityStatusList && this.qualityStatusList.length > 1) {
        this.model.QualityStatusID = this.qualityStatusList[0].value;
      }
      this.display = true;
      this.cRef.markForCheck();

    } else {
      this._sharedService.clearToast();
      this._sharedService.showToast({
        severity: "warn",
        summary: "No Asset selected",
        detail: "Please select an Asset.",
      });
    }
  }

  onSubmit(form: NgForm) {
    this.changeButtonsStatus(true);
    //US-8181 ////let assetInfo: string = this._sharedService.selectedAsset[0].data.split("|");

    let assetDataObj = JSON.parse(this._sharedService.selectedAsset[0].data);

    let body: containerForAdd = {
      Category: this.recordType,
      ASN: assetDataObj.ASN,//assetInfo[1],
      BoxID: this.model.boxid,
      BoxLocation: this.model.boxlocation,
      BoxType: this.model.boxtype,
      ID: this.getID(),
      Description: this.model.description,
      Status: this.model.isactive,
      Type: this.model.wotype,
      // WONumber: this.model.wonumber,
      ReferenceKey: this.recordType == "LOG" ? this.setTechLogAnalyzerConfig() : this.getWONumber(),
      CreatedBy: this._sharedService.getCurrentUserName(),
      Live: this.model.islive ? 1 : 0,
      ContainerName: assetDataObj.StoragePath,//assetInfo[0],
      ScannedBy: this.model.scannedby,
      //Date: this.model.crsdate.toDateString(),
      //EndDate: this.model.enddate.toDateString(),
      ScannedOn: this.getDate(this.model.scanneddate),
      Date: this.getDate(this.model.crsdate),
      EndDate: this.getDate(this.model.enddate),
      QualityStatusID: this.model.QualityStatusID
    };
    this._sharedService.showLoader("Processing...");
    this._containerService.addContainerData(body)
      .subscribe({
        next: (response) => {
          this._sharedService.hideLoader();
          this.display = false;
          response;
          this.resetModel();
          form.resetForm();
          this.resetModel();
          this._sharedService.onAssetChanged(this._sharedService.selectedAsset);
          this._sharedService.clearToast();
          this._sharedService.showToast({
            severity: "success",
            summary: "Success",
            detail: "Container added succesfully.",
          });
          this.changeButtonsStatus(false);
        },
        error: (error) => {
          this._sharedService.hideLoader();
          this._sharedService.clearToast();
          if (error.status === 409) {
            this._sharedService.showToast({
              severity: "error",
              summary: "Already Exists",
              detail: "Container already exists.",
            });
          } else {
            this._sharedService.showToast({
              severity: "error",
              summary: "Error Occured",
              detail: "Container not added, please try again.",
            });
          }
          this.display = false;
          this.resetModel();
          this.changeButtonsStatus(false);
        }
      });
  }

  btnCancelClick(cancelForm: NgForm) {
    this.display = false;
    cancelForm.resetForm();
    this.resetModel();
  }
  // #endregion

  //#region Form Controls
  setWOType() {
    this.WOTypes = [];
    let body: container = {
      Category: this.recordType,
    };
    this._containerService.getContainerType(body)
      .subscribe({
        next: (response) => {
          let res = response;
          for (let i = 0; i < res.length; i++) {
            this.WOTypes.push({ value: res[i].Type, label: res[i].Type });
          }
        },
        error: (error) => {
        }
      });
  }
  //#endregion

  // #region AutoComplete Box Type

  setBoxTypeSuggestions() {
    this.boxTypeResults = [];
    let body: container = {
      Category: this.recordType,
    };
    this._containerService.getBoxType(body)
      .subscribe({
        next: (response) => {
          this.boxTypeResults = response;
          this.boxTypeSuggestion = this.boxTypeResults;
        },
        error: (error) => {
        }
      })
  }

  searchBoxType(event: any) {
    let filtered: any[] = [];
    for (let i = 0; i < this.boxTypeResults.length; i++) {
      let brand = this.boxTypeResults[i];
      if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        filtered.push(brand);
      }
    }
    this.boxTypeSuggestion = filtered;
  }
  // #endregion

  // #region AutoComplete Box Location

  setBoxLocationSuggestions() {
    this.boxLocationSuggestion = [];
    let body: container = {
      Category: this.recordType,
    };
    this._containerService.getBoxLocation(body)
      .subscribe({
        next: (response) => {
          this.boxLocationResults = response;
          this.boxLocationSuggestion = this.boxLocationResults;
        },
        error: (error) => {
        }
      });
  }

  searchBoxLocation(event: any) {
    let filtered: any[] = [];
    for (let i = 0; i < this.boxLocationResults.length; i++) {
      let brand = this.boxLocationResults[i];
      if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        filtered.push(brand);
      }
    }
    this.boxLocationSuggestion = filtered;
  }
  // #endregion

  // #region AutoComplete Scanned By

  setScannedBySuggestions() {
    this.scannedBySuggestion = [];
    let body: container = {
      Category: this.recordType,
    };
    this._containerService.getScannedBy(body)
      .subscribe({
        next: (response) => {
          this.scannedByResults = response;
          this.scannedBySuggestion = this.scannedByResults;
        },
        error: (error) => {
        }
      });
  }

  searchScannedBy(event: any) {
    let filtered: any[] = [];
    for (let i = 0; i < this.scannedByResults.length; i++) {
      let brand = this.scannedByResults[i];
      if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        filtered.push(brand);
      }
    }
    this.scannedBySuggestion = filtered;
  }
  // #endregion

  // #region AutoComplete Box ID

  setBoxIDSuggestions() {
    this.boxIDResults = [];
    let body: container = {
      Category: this.recordType,
    };
    this._containerService.getBoxID(body)
      .subscribe({
        next: (response) => {
          this.boxIDResults = response;
          this.boxIDSuggestion = this.boxIDResults;
        },
        error: (error) => {
        }
      });
  }

  searchBoxID(event: any) {
    let filtered: any[] = [];
    for (let i = 0; i < this.boxIDResults.length; i++) {
      let brand = this.boxIDResults[i];
      if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        filtered.push(brand);
      }
    }
    this.boxIDSuggestion = filtered;
  }
  // #endregion
  // #region ID Manipulation (Business Rules)
  getID(): string {
    if (this.recordType == "MXR") {
      if (this.model.wonumber.length > 0) {
        return (
          this.convertToShortISOFormat(this.model.crsdate) +
          "-" +
          this.model.wonumber
          // this.removeSlashFromString(this.model.wonumber)
        );
      } else {
        return (
          this.convertToShortISOFormat(this.model.crsdate) +
          "-" +
          this.convertToShortISOFormat(this.model.enddate)
        );
      }
    } else if (this.recordType == "LOG") {
      return (
        this.convertToShortISOFormat(this.model.crsdate) +
        "-" +
        this.convertToShortISOFormat(this.model.enddate)
      );
    } else if (this.recordType == "GEN") {
      // return this.model.generalID
      return (
        this.convertToShortISOFormat(this.model.crsdate) +
        "-" +
        this.model.generalID
        // this.removeSlashFromString(this.model.generalID)
      );
    }
    else if (this.recordType == "DOC" || this.recordType == "BND") {
      return (this.model.ID);
    }
  }
  // #endregion

  //#region Date Manipulation Helper Methods
  getPlainDate(date: Date): string {
    if (date != null) {
      var formatted = moment(date).format(this.dateFormat.toUpperCase());
      if (this.dateSeparator == "-") {
        let newStr = formatted.toString().replace(/-/g, "");
        return newStr;
      } else if (this.dateSeparator == "/") {
        let newStr = formatted.toString().replace(/\//g, "");
        return newStr;
      }
    } else {
      return "";
    }
  }

  setCalenderDateFormat() {
    this.configuration = this._sharedService.getConfiguration();
    this.dateFormat = this.configuration
      .filter((x) => x.Key == "DateFormat")
      .map(({ Value }) => Value)
      .toString();
    this.dateSeparator = this.configuration
      .filter((x) => x.Key == "DateSeparater")
      .map(({ Value }) => Value)
      .toString();
    this.day = Number(
      this.configuration
        .filter((x) => x.Key == "DayIndex")
        .map(({ Value }) => Value)
    );
    this.month = Number(
      this.configuration
        .filter((x) => x.Key == "MonthIndex")
        .map(({ Value }) => Value)
    );
    this.year = Number(
      this.configuration
        .filter((x) => x.Key == "YearIndex")
        .map(({ Value }) => Value)
    );
    let dateParts: string[] = this.dateFormat.split(this.dateSeparator);

    if (this.year == 0) {
      this.dateFormatCalender = (
        dateParts[this.year].substr(0, 2) +
        this.dateSeparator +
        dateParts[this.month] +
        this.dateSeparator +
        dateParts[this.day]
      ).toLowerCase();
    } else if (this.year == 2) {
      //this.dateFormatCalender = (dateParts[this.day] + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
      if (this.month == 1) {
        this.dateFormatCalender = (
          dateParts[this.day] +
          this.dateSeparator +
          dateParts[this.month] +
          this.dateSeparator +
          dateParts[this.year].substr(0, 2)
        ).toLowerCase();
      } else if (this.month == 0) {
        this.dateFormatCalender = (
          dateParts[this.month] +
          this.dateSeparator +
          dateParts[this.day] +
          this.dateSeparator +
          dateParts[this.year].substr(0, 2)
        ).toLowerCase();
      }
    } else {
      this.dateFormatCalender = "yy-mm-dd";
    }
  }

  setDefaultComponentValues(): void {
    this.resetModel();
    this.changeButtonsStatus(false);
    //this.setCalenderDateFormat();
  }
  getWONumber(): string {
    if (this.recordType != "GEN") {
      return this.model.wonumber;
    } else {
      return this.model.generalID;
    }
  }

  setTechLogAnalyzerConfig(): string {
    if (this.recordType == "LOG") {
      //return this.model.IncrementValue + "-" + this.model.StartNumber + "-" + this.model.NumberOfPages;

      let _IncrementValue: string = '';
      let _StartNumber: string = '';
      let _NumberOfPages: string = '';

      _IncrementValue = isNullOrUndefined(this.model.IncrementValue) ? "" : this.model.IncrementValue.toString();
      _StartNumber = isNullOrUndefined(this.model.StartNumber) ? "" : this.model.StartNumber.toString();
      _NumberOfPages = isNullOrUndefined(this.model.NumberOfPages) ? "" : this.model.NumberOfPages.toString();

      return _IncrementValue + "-" + _StartNumber + "-" + _NumberOfPages;
    }
  }

  //#endregion

  //#region User Roles
  checkUserRoles() {
    this.canMakeContainerLive = false;
    let roles: string[] = localStorage.getItem("roles").split(",");
    if (this.recordType == "MXR") {
      for (let i = 0; i < roles.length; i++) {
        if (roles[i] == "Records_MXR_Con_Admin") {
          this.canMakeContainerLive = true;
        }
      }
      this.WorkOrderLabel = "Work Order/Package Number";
    } else if (this.recordType == "LOG") {
      for (let i = 0; i < roles.length; i++) {
        if (roles[i] == "Records_LOG_Con_Admin") {
          this.canMakeContainerLive = true;
        }
      }
    } else if (this.recordType == "GEN") {
      for (let i = 0; i < roles.length; i++) {
        if (roles[i] == "Records_GEN_Con_Admin") {
          this.canMakeContainerLive = true;
        }
      }
      this.WorkOrderLabel = "Short Title/Reference";
    }
    else if (this.recordType == "DOC") {
      for (let i = 0; i < roles.length; i++) {
        if (roles[i] == "Records_DOC_Con_Admin") {
          this.canMakeContainerLive = true;
        }
      }
    }
    else if (this.recordType == "BND") {
      for (let i = 0; i < roles.length; i++) {
        //this._sharedService.UserRole.Transition_BND_Con_Admin
        if (roles[i] == "Transition_BND_Con_Admin") {
          this.canMakeContainerLive = true;
        }
      }
    }
  }
  //#endregion
  isLastDot(str) {
    let val = str.replace(/\s/g, "");
    if (val != "") {
      let lastChar;
      lastChar = val.slice(-1);
      if (lastChar === ".") {
        this.lastIsDot = true;
      } else {
        this.lastIsDot = false;
      }
    } else {
      this.lastIsDot = false;
    }
  }

  isLastDotWonum(str) {
    let val = str.replace(/\s/g, "");
    if (val != "") {
      let lastChar;
      lastChar = val.slice(-1);
      if (lastChar === ".") {
        this.lastIsDotWonum = true;
      } else {
        this.lastIsDotWonum = false;
      }
    } else {
      this.lastIsDotWonum = false;
    }
  }
  resetModel() {
    this.model = new AddContainerModel(
      null,
      null,
      null,
      null,
      "",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      false,
      true,
      "1",

      this.Log_Books_IncrementValue,
      null,
      null

    );
    this.lastIsDot = false;
    this.lastIsDotWonum = false;
  }

  getDialogueTitle(): string {
    if (this.recordType == "MXR") {
      return "MX Records > Add";
    } else if (this.recordType == "LOG") {
      return "Logs > Add";
    } else if (this.recordType == "GEN") {
      return "General > Add";
    } else if (this.recordType == "DOC") {
      return "Documents > Add";
    } else if (this.recordType == "BND") {
      return "Binders > Add";
    } else {
      return "Add";
    }
  }
  convertToISOFormat(date: Date) {
    return moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  }

  convertToShortISOFormat(date: Date): string {
    return moment(date).format("YYYYMMDD").toString();
  }

  getDate(date: Date): string {
    if (date != null) {
      //let newDate = moment(date).format("YYYY-MM-DD");
      //return date.toDateString()
      let newDate = moment(date).format("YYYY-MM-DD");
      return new Date(newDate).toDateString();
    }
    return null;
  }

  changeButtonsStatus(status: boolean) {
    this.isSubmitted = status;
  }
  removeSlashFromString(inputValue: string) {
    let outputValue = inputValue.replace(/[\/\\]/g, "");
    return outputValue;
  }

  onKey() {
    this.display = false;
    this.resetModel();
  }
  getDateFormatTime() {
    var dateConfig = this._sharedService.getDateTimeFormatConfiguration();
    return dateConfig.dateformat;
  }
  onCloseDate() {
    var enddate: any = document.getElementsByClassName('enddate')[0].children[1]
    enddate.focus();
  }
  onCloseEnddate() {
    var desr = document.getElementById('desr');
    desr.focus();
  }
  onCloseScannedOn() {
    var scannedby = document.getElementById('scannedby');
    scannedby.focus();
  }
  isChecked: boolean = false
  ToggleChecked() {

    if (this.isChecked) {
      this.isChecked = false;
      this.model.islive = false;
    }
    else {
      this.isChecked = true;
      this.model.islive = true;
    }
  }
}
