import { Component, OnInit, ElementRef, ViewChild, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router'

import { TreeNode } from 'primeng/api';
import { SharedService } from '../../../shared/services/shared.service';
import { AssetService } from '../../../admin/services/asset/asset.service';
import { Subscription } from 'rxjs';

@Component({
    moduleId: module.id,
    selector: 'asset-status-tree',
    templateUrl: './asset-status.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AssetService]
})
export class AssetStatusComponent implements OnInit, OnDestroy {


    //#region globals variables
    //@ViewChild('myinout') myinout: any;
    tabValue: string = "0";
    @ViewChild('txtbox') txtbox: ElementRef;
    isTextclicked: boolean = false;
    assets: TreeNode[];
    tempAsset: TreeNode[];
    selectedAsset: TreeNode[];
    isBrowseScreen: boolean;
    isClassVisible: boolean = false;
    selectedAssetName: string;
    selectedClassName: string;
    searchAsset: string;
    selectedFontClassName: string = "standard-fontsize";
    panalClicksubs: Subscription;
    //#endregion

    //#region init

    ngOnInit() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            if (this._sharedService.selectedAssetStatus && this._sharedService.selectedAssetStatus.length == 1) {
                this.selectedAsset = [];
                this.selectedAsset.push(this._sharedService.selectedAssetStatus[0]);
                //this.selectedAsset = this._sharedService.selectedAsset;
                if (this.selectedAsset[0]) {
                    this.selectedAssetName = this.selectedAsset[0].label, this.selectedClassName = this.selectedAsset[0].expandedIcon ? this.selectedAsset[0].expandedIcon : this.selectedAsset[0].icon;
                    this.setAssetClass();
                    if (!this.selectedAssetName) {
                        this.selectedAssetName = "Select An Asset";
                    }
                }
            } else {
                this.selectedAssetName = "Select An Asset";
                this.setAssetClass();
            }

            this._sharedService.setAssetStatusControlVisible(true);
            this.panalClicksubs = this._sharedService._ishideStatusTreeControl
            .subscribe({
                next: (response:boolean) => {
                    if (this.isClassVisible) {
                        this.isClassVisible = false;
                        this.cRef.markForCheck();
                    }
                },
                error: (error) => {
                }
            });
        }
    }

    constructor(private _router: Router, private cRef: ChangeDetectorRef, private _sharedService: SharedService, private _assetService: AssetService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            let userRole: string = localStorage.getItem('roles').trim();
            let body = { UserRole: userRole };
            this._assetService.getUserAsset(this._sharedService.baseUrl, body)
            .subscribe({
                next: (response) => {
                    this._sharedService.setAssetStatusList(response);
                    let assetsList = this._sharedService.getAssetStatusList();
                    this.assets = assetsList;
                    this.tempAsset = [];
                    this.tempAsset = this.deepCopy(response);
                    this.cRef.markForCheck();
                }
            });

            this._sharedService._selectedAssetStatus
            .subscribe({
                next: (selectedAsset:any) => {
                    //  if (selectedAsset[0]) { ****OCR Search:for multiple asset selection message should be display instead of asset value *****
                    if (selectedAsset && selectedAsset.length > 1) {
                        let aircraftLabel = this.getAirCraftifSelected(selectedAsset);
                        if (aircraftLabel) {
                            this.selectedAssetName = aircraftLabel, this.selectedClassName = null;
                            this.setAssetClass();
                            this.cRef.markForCheck();
                        }
                        else {
        
                            this.selectedAssetName = "Multiple selection", this.selectedClassName = null;
                            this.setAssetClass();
                            this.cRef.markForCheck();
                        }
        
        
                    }
                    else if (selectedAsset && selectedAsset.length == 1) {
                        this.selectedAssetName = selectedAsset[0].label, this.selectedClassName = selectedAsset[0].expandedIcon ? selectedAsset[0].expandedIcon : selectedAsset[0].icon;
                        this.setAssetClass();
                        this.cRef.markForCheck();
                    }
                    else {
                        this.selectedAssetName = "Select An Asset";
                        this.selectedClassName = "";
                        this.setAssetClass();
                        this.cRef.markForCheck();
                    }
                }
            });

            this._sharedService._isAssetStatusControlVisible
            .subscribe({
                next: (assetVisibility:any) => {
                    this.setAssetControlVisibility(assetVisibility)
                }
            });

            this._sharedService._resetAssetStatusControl
            .subscribe({
                next: (resetAsset:any) => {
                    this.resetAsetControle(resetAsset)
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.panalClicksubs) {
            this.panalClicksubs.unsubscribe();
        }

    }

    modelChanged(event: any) { // without type info

        if (event) {
            this.searchAsset = event;

            this.assets = this.deepCopy(this.tempAsset);
            this.assets = this.filterNodeArray(this.assets, this.searchAsset);
        } else {
            this.assets = this.deepCopy(this.tempAsset);
        }
    }

    filterNodeArray(oNodes: TreeNode[], filter: string): TreeNode[] {

        let tempNodes: TreeNode[] = oNodes;
        let fnlNodes: TreeNode[] = [];
        let airCraft: TreeNode;
        let assets: TreeNode[];

        tempNodes.forEach((pNode) => {
            let ch2Node = pNode.children;
            airCraft = pNode;
            airCraft.expanded = true;
            assets = [];
            if (ch2Node) {
                ch2Node.forEach((cNode) => {
                    //let ch3Nodes = cNode.children;
                    let ch3Nodes: TreeNode[] = [];
                    if (cNode && cNode.children) {
                        ch3Nodes = cNode.children == null ? [] : cNode.children;
                    }
                    let filterNodes = ch3Nodes.filter(a => a.label.toLowerCase().includes(filter.toLowerCase()));
                    cNode.children = filterNodes;
                    if (cNode.children && cNode.children.length > 0) {
                        cNode.expanded = true;
                        assets.push(cNode);

                    }
                })
            }

            if (assets && assets.length > 0) {
                airCraft.children = assets;
                fnlNodes.push(airCraft);
            }
        });

        return fnlNodes;

    }



    public deepCopy(o: any) {
        var copy = o, k;

        if (o && typeof o === 'object') {
            copy = Object.prototype.toString.call(o) === '[object Array]' ? [] : {};
            for (k in o) {
                copy[k] = this.deepCopy(o[k]);
            }
        }

        return copy;
    }

    //#endregion


    //#region Asset Class
    setAssetClass() {
        if (this.selectedAssetName) {
            if (this.selectedAssetName.length > 16) {
                this.selectedFontClassName = "small-fontsize";
            }
            else {
                this.selectedFontClassName = "standard-fontsize";
            }
        }

    }
    //#endregion
    //#region internal methods
    getAirCraftifSelected(selectedAsset: TreeNode[]): string {

        let assets: TreeNode[];
        let aircraft: any[] = [];
        let parentAsset: any[] = [];
        assets = selectedAsset;
        let aircraftName = "";

        assets.forEach((asset) => {
            let aircarft = asset.styleClass.split(" ")[0];
            if (aircarft && aircarft == "P") {
                aircraft.push(asset.label);
            }

            let childAssets = asset.styleClass.split(" ")[0];
            if (childAssets && childAssets != "P" && parentAsset.indexOf(childAssets) == -1) {
                parentAsset.push(childAssets);
            }

        });

        if (aircraft && parentAsset && parentAsset.length == 1 && aircraft.length == 1) {
            return aircraft[0];
        }
        else {

            return null;

        }


    }
    //#endregion



    //#region events

    resetAsetControle(resetAsset: any) {
        //  this.assets = [];
        if (this.assets)
            if (this._router.url.indexOf("/record/search") == -1) {
                this.assets.forEach(a => a.selectable = false);
            }
            else if (this._router.url.indexOf("/transition/search") == -1) {
                this.assets.forEach(a => a.selectable = false);
            }
            else {
                this.assets.forEach(a => a.selectable = true);
            }

        if (resetAsset == true) {
            this.selectedAsset = [];
            this._sharedService.selectedAssetStatus = [];
            this.selectedAssetName = "Select An Asset";
            this.selectedClassName = "";
            this.setAssetClass();
        }
        this.cRef.markForCheck();

    }

    assetSelect(event: any) {
        if (this._router.url.indexOf("/record/search") == -1) {
            if (this.selectedAsset && this.selectedAsset.length > 1) {
                this.selectedAsset = [];
                this.selectedAsset.push(event.node);
            }
        }
        else if (this._router.url.indexOf("/transition/search") == -1) {
            if (this.selectedAsset && this.selectedAsset.length > 1) {
                this.selectedAsset = [];
                this.selectedAsset.push(event.node);
            }
        }
        this._sharedService.selectedAssetTreeNode = this.selectedAsset;
        this._sharedService.onAssetStatusChanged(this.selectedAsset);
        this._sharedService.onAssetChanged(this.selectedAsset);
        this._sharedService.showHideStatusMenu();
        if (this._router.url.indexOf('/status/') != -1) {

            if (this._router.url == '/status/dashboard') {
                this._router.navigateByUrl('/status/dashboard');
            }
            else if (this._router.url != '/status/msr'
                && this._router.url != '/status/excel'
                && this._router.url != '/status/csv'
                && this._router.url != '/status/tsv'
                && this._router.url != '/status/pdf'
                && this._router.url != '/status/xml'
            ) { this._router.navigateByUrl('/status/viewlist'); }

            if (this._router.url == '/status/excel'
                || this._router.url == '/status/csv'
                || this._router.url == '/status/tsv'
                || this._router.url == '/status/pdf'
                || this._router.url == '/status/xml') {

                //US-8181 //this._sharedService.setCurrentFeedASN(this.selectedAsset[0].data.split('|')[1]);
                this._sharedService.setCurrentFeedASN(JSON.parse(this.selectedAsset[0].data).ASN);
            }

        } else if (this._router.url.indexOf('/repair/') != -1) {
            if (this._router.url == '/repair/dashboard') {
                this._router.navigateByUrl('/repair/dashboard');
            }
            else if (this._router.url != '/repair/msr'
                && this._router.url != '/repair/excel'
                && this._router.url != '/repair/csv'
                && this._router.url != '/repair/tsv'
                && this._router.url != '/repair/pdf'
                && this._router.url != '/repair/xml'
            ) { this._router.navigateByUrl('/repair/viewlist'); }

            if (this._router.url == '/repair/excel'
                || this._router.url == '/repair/csv'
                || this._router.url == '/repair/tsv'
                || this._router.url == '/repair/pdf'
                || this._router.url == '/repair/xml') {

                //US-8181 //this._sharedService.setCurrentFeedASN(this.selectedAsset[0].data.split('|')[1]);
                this._sharedService.setCurrentFeedASN(JSON.parse(this.selectedAsset[0].data).ASN);
            }

        }
        this.cRef.markForCheck();

    }

    assetClick() {
        //this.myinout.nativeElement.focus();
        if (!this.isClassVisible) {
            this.isClassVisible = true;
        }
        this.cRef.markForCheck();
    }

    setAssetControlVisibility(status: any) {
        this.isBrowseScreen = status;
        this.cRef.markForCheck();
    }

    assetUnselect(event: any) {
        if (this.selectedAsset && this.selectedAsset.length == 0) {
            this.selectedAsset = [];
            this.selectedAssetName = "Select An Asset";
            this.selectedClassName = "";
            this.setAssetClass();
            //this.selectedAsset[0].label = "";
            //this.selectedAsset[0].collapsedIcon = "";
            //this._sharedService.onAssetChanged(this.selectedAsset);
        }
        this._sharedService.onAssetStatusChanged(this.selectedAsset);
        this.cRef.markForCheck();
    }




    txtSearchMouseDown() {
        this.isTextclicked = true;
    }

    onBlur(control?: string) {

        if (this.isTextclicked == false) {
            this.isClassVisible = false;
        }
        else
            if (control && control == "child") {
                this.isTextclicked = false;
                this.txtbox.nativeElement.focus();
            }
    }



    //#endregion
}
