export interface container {
    ContainerID?: string,
    ASN?: string,
    ID?: string,
    Description?: string,
    Category?: string,
    Type?: string,
    Date?: Date,
    EndDate?: Date,
    ReferenceKey?: string,
    BoxType?: string,
    BoxID?: string,
    BoxLocation?: string,
    ScannedBy?: string,
    ScannedOn?: Date,
    Live?: number,
    Status?: boolean,
    CreatedBy?: string,
    CreatedOn?: Date,
    ModifiedBy?: string,
    ModifiedOn?: Date,
    ContainerName?: string,
    OutGoingParam?: OutGoingParamKeyVal[],
    IsDirectory?: boolean,

    IncrementValue?: number,
    StartNumber?: number,
    NumberOfPages?: number
}

export class OutGoingParamKeyVal {
    constructor(
        public KeyValList?: KeyValPair[]) { }

}

export interface KeyValPair {
    Key: string,
    Value: string
}
export interface MoveContainerModel {
    SourceASN?: string,
    DestinationASN?: string,
    MoveContainerIDs?: string,
    TenantId?: string,
    UserId?: string,
    UserEmail?: string,
}

export interface containerForAdd {
    ContainerID?: string,
    ASN?: string,
    ID?: string,
    Description?: string,
    Category?: string,
    Type?: string,
    Date?: string,
    EndDate?: string,
    ReferenceKey?: string,
    BoxType?: string,
    BoxID?: string,
    BoxLocation?: string,
    ScannedBy?: string,
    ScannedOn?: string,
    Live?: number,
    Status?: boolean,
    CreatedBy?: string,
    CreatedOn?: Date,
    ModifiedBy?: string,
    ModifiedOn?: Date,
    ContainerName?: string,
    QualityStatusID?: string
}
export interface BatchGapAnalyzer {
        ContainerName?: string,
        ASN?:string,
        ContainerIDs?: string[],
        JobID?: string,
        ActiveChannel?:string,
        AccessToken?:string
}

export interface containerForEdit {
    ContainerID?: string,
    ASN?: string,
    ID?: string,
    Description?: string,
    Category?: string,
    Type?: string,
    Date?: string,
    EndDate?: string,
    ReferenceKey?: string,
    BoxType?: string,
    BoxID?: string,
    BoxLocation?: string,
    ScannedBy?: string,
    ScannedOn?: string,
    Live?: number,
    Status?: boolean,
    CreatedBy?: string,
    CreatedOn?: Date,
    ModifiedBy?: string,
    ModifiedOn?: Date,
    ContainerName?: string,
    QualityStatusID?: string,
    QualityStatus?: string,
}

export interface containerForDateChange {
    ID?: string,
    ContainerID?: string,
    Type?: string,
    Date?: string,
    EndDate?: string,
    ReferenceKey?: string,
    
}

export interface containerForSearch {
    ContainerID?: string,
    ASN?: string,
    ID?: string,
    Description?: string,
    Category?: string,
    Type?: string,
    Date?: Date,
    EndDate?: Date,
    ReferenceKey?: string,
    BoxType?: string,
    BoxID?: string,
    BoxLocation?: string,
    ScannedBy?: string,
    ScannedOn?: Date,
    Live?: number,
    Status?: boolean,
    CreatedBy?: string,
    CreatedOn?: Date,
    ModifiedBy?: string,
    ModifiedOn?: Date,
    ContainerName?: string,
    Url?: string
}
export class SearchContainerModel {

    constructor(
        public asn: string,
        public category?: string,
        public Type?: string,
        public isCopy?: boolean,
        public viewSettingId?: string
    ) { }

}

export class OutgoingParams {
    constructor(
        public id?: number,
        public stringParam?: KeyValPair[]
    ) { }
}