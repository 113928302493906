import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SharedService } from '../../../shared/services/shared.service';

@Injectable()
export class RoleGroupService {
    constructor(private http : HttpClient, private _sharedService: SharedService ) {
    }

    addUpdateRoleGroup(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/RoleGroup/AddUpdateRoleGroup`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getRoleGroupByID(body: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/RoleGroup/GetRoleGroupByID?groupId=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getGroupRoleByID(body: string): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/RoleGroup/GetGroupRoleByID?groupId=${body}`, '', this._sharedService.getReqOptWithAccessToken());
    }

    getRoleGroupList(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/RoleGroup/GetAllRoleGroup`, null, this._sharedService.getReqOptWithAccessToken());
    }
}