//#region References
//Components
import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';

import { AddDataFeedModel, FtpModel, WebServiceModel, FeedTemplate, AFSModel } from './add-datafeed';
import { datafeed } from '../list-datafeed/data-feed';
import { NgForm } from '@angular/forms';

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { APIService } from '../../services/api.service';
import { ViewService } from '../../../admin/services/view/view.service';
import { ContainerCategory, FeedType, FileType, SourceType, TargetType } from '../../services/api.helper-classes';
import { AssetService } from '../../../admin/services/asset/asset.service';
import { Subscription } from "rxjs";
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { AddUpdateStatusCode } from '../../../shared/helpers/config';


//#region Type Decorator
@Component({
    selector: 'add-datafeed',
    templateUrl: './add-datafeed.component.html',
    providers: [APIService, ViewService, AssetService]
})
//#endregion

export class AddDataFeedComponent implements OnInit, OnDestroy {

    //#region Locals Declaration
    display: boolean = false;
    isSubmitted: boolean = false;
    isAuthorizedUser: boolean = false;

    @Input() recordType: string;
    @Input() viewId: string = null;
    @Input() statusOrRepair: string = null;

    dialogTitle: string = "";
    dataTypeListOutgoingInteractive: SelectItem[] = [];
    dataTypeList: SelectItem[] = [];
    targetModuleList: SelectItem[] = [];

    intervalList: SelectItem[] = [];
    sourceTypeList: SelectItem[] = [];
    sourceAssetDetailList: SelectItem[] = [];
    templateSourceDetailList: SelectItem[] = [];
    sourceDetailListOutgoingRDOC: SelectItem[] = [];
    templateTargetDetailList: SelectItem[] = [];
    targetDetailListRDOC: SelectItem[] = [];
    templateSourceDetail: string = "";
    templateTargetDetail: string = "";

    model: AddDataFeedModel;
    feedTemplateList: FeedTemplate[] = [];
    feedScopeList: SelectItem[] = [];
    selectedFeedTemplate: FeedTemplate;

    //sourceViewList: SelectItem[] = [];


    private addFeedSub: Subscription;

    sourceViewListStatus: SelectItem[] = [];
    sourceViewListRepair: SelectItem[] = [];
    sourceViewListAll: SelectItem[] = [];
    categoryList: SelectItem[] = [];

    exportSourceViewListAll: SelectItem[] = [];
    exportSourceViewListRepair: SelectItem[] = [];
    exportSourceViewListStatus: SelectItem[] = [];
    tempExportSourceViewListRepair: any[] = [];
    tempExportSourceViewListStatus: any[] = [];

    targetViewListStatus: SelectItem[] = [];
    targetViewListRepair: SelectItem[] = [];
    targetViewListAll: SelectItem[] = [];

    sourceDetailForRecord: SelectItem[] = [];
    isFtpDialogueOpen: boolean = false;
    isAzureFileStorageDialogueOpen: boolean = false;
    ftpModel: FtpModel;
    afsModel: AFSModel;
    isWebServiceDialogueOpen: boolean = false;
    isWebServicePushDialogueOpen: boolean = false;
    webServiceModel: WebServiceModel;
    authTypeList: SelectItem[] = [];
    isStatusModule: boolean = false;
    allViewData: any[] = []
    public appHotkeys: Hotkey[] = [];
    defaultFTPPswd: string = "*****";
    defaultWebServicePswd: string = "*****";
    ftpPswdIdentificationCharacter: string = "*";
    public selContainer = null;
    // isChecked:boolean=false;
    //#endregion
    @ViewChild('focus') focusInput: ElementRef;

    //#region Initialization of Component
    constructor(public _sharedService: SharedService, private _router: Router, private _apiService: APIService, private _viewService: ViewService, private _assetService: AssetService, private _hotkeysService: HotkeysService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
      this.setHotKeys();
      this.populateCategoryList()
    }
    setHotKeys() {

        let hotKeyObj: Hotkey = null;
        //Add datafeed
        hotKeyObj = new Hotkey('ctrl+a', (event: KeyboardEvent): boolean => {
            this.showAddDialog();
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
  }
    populateCategoryList() {
      this.categoryList = this._sharedService.getContainerCategoryList();
    }
    ngOnInit() {
        this.initializeModel();
        this.initializeModelFtp();
        this.initializeModelAfs()
        this.initializeModelWebService();
        this.populateAuthTypeDropDown();
        this.addFeedSub = this._sharedService._addFeedSub
        .subscribe({
            next: (response) => {
                this.showAddDialog();
            },
            error: (error) => {
            }
        });

        this.sourceDetailForRecord = this._apiService.getSourceDetailForRecord();
    }
    ngOnDestroy() {
        if (this.addFeedSub) {
            this.addFeedSub.unsubscribe();
        }
        this.appHotkeys.forEach(hotkey => {
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
    }
    //#endregion

    // #region Form Events

    showAddDialog() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.dialogTitle = "Add Feed";
            this.setDefaultComponentValues();
            this.display = true;
        }
    }


    onSubmit(form: NgForm) {
        this.changeButtonsStatus(true);
        let sourceDetail = this.model.sourcedetail;
        let targetDetail = this.model.targetdetail;
        let filesharename = "";
        let allChecksPassed = false;
        if (this.recordType == FeedType.Incoming) {
            if (this.model.sourcetype == SourceType.FileStorage) {

                let changeSlash = sourceDetail.replace(/[\\]/g, "/");
                changeSlash = changeSlash.replace(/^[/\\]/, "");
                changeSlash = changeSlash.replace(/[/\\]$/, "");
                sourceDetail = changeSlash;
                if (sourceDetail.indexOf("/") != -1) {
                    let manipulatePath = sourceDetail.split('/');

                    for (var i = 0; i < manipulatePath.length; i++) {
                        if (i == 0) {
                            filesharename = manipulatePath[i].toLowerCase();
                        }
                        else {
                            filesharename = filesharename + "/" + manipulatePath[i];
                        }
                    }

                    allChecksPassed = true;
                }
                else {

                    this.changeButtonsStatus(false);
                    this._sharedService.clearToast();

                    this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Source Details', detail: 'Directory name not provided.' });

                }
            }
            else if (this.model.targettype == TargetType.EmailLink) {

                if (this._apiService.validMail(targetDetail)) {

                    allChecksPassed = true;
                }
                else {

                    this.changeButtonsStatus(false);
                    this._sharedService.clearToast();

                    this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Target Details', detail: 'Email not valid.' });

                }
            }

            else {
                allChecksPassed = true;
            }
        }
        else {
            if (this.model.targettype == SourceType.FileStorage) {

                let changeSlash = targetDetail.replace(/[\\]/g, "/");
                changeSlash = changeSlash.replace(/^[/\\]/, "");
                changeSlash = changeSlash.replace(/[/\\]$/, "");
                targetDetail = changeSlash;
                if (targetDetail.indexOf("/") != -1) {
                  let manipulatePath = targetDetail.split("/");

                  for (var i = 0; i < manipulatePath.length; i++) {
                    if (i == 0) {
                      filesharename = manipulatePath[i].toLowerCase();
                      if (
                        filesharename.length < 3 ||
                        filesharename.length > 63
                      ) {
                        this.changeButtonsStatus(false);
                        this._sharedService.clearToast();
                        this._sharedService.showToast({
                          severity: "warn",
                          summary: "Invalid Target Detail",
                          detail:
                            "The file share name must be between 3 and 63 characters long.",
                        });
                        return;
                      }
                    } else {
                      filesharename = filesharename + "/" + manipulatePath[i];
                    }
                  }

                  allChecksPassed = true;
                }
                else {

                    this.changeButtonsStatus(false);
                    this._sharedService.clearToast();

                    this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Source Details', detail: 'Directory name not provided.' });

                }
            }
            else if (this.model.targettype == TargetType.EmailLink) {

                if (this._apiService.validMail(targetDetail)) {

                    allChecksPassed = true;
                }
                else {

                    this.changeButtonsStatus(false);
                    this._sharedService.clearToast();

                    this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Target Details', detail: 'Email not valid.' });

                }
            }
            else if (this.model.targettype == TargetType.ROAMContainer) {
                if (this.selContainer[0].Category == ContainerCategory.ContainerCategoryMXR || this.selContainer[0].Category == ContainerCategory.ContainerCategoryLOG || this.selContainer[0].Category == ContainerCategory.ContainerCategoryGEN) {
                    if (this.model.datatype != FileType.PDF) {
                        this.changeButtonsStatus(false);
                        this._sharedService.showToast({ severity: 'warn', summary: 'Invalid DataType', detail: 'Data types except PDF, can be exported only in Documents or Binders categories.' });
                    }
                    else {
                        allChecksPassed = true;
                    }
                }
                else {
                    allChecksPassed = true;
                }
            }

            else {
                allChecksPassed = true;
            }
        }
        if (allChecksPassed) {

            let feedName = this.removeInvalidChars(this.model.feedname);//!.replace(/[\s\-\/\\\?%\*#\^&\$!~\`:<>\.\(\)\@\|]+/g, "")!.trim();
            if (!feedName) {
                this.changeButtonsStatus(false);
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Invalid feed name', detail: 'Special character(s) not aalowed in feed name.' });
                return;

            }

            let body: datafeed =
            {
                DataType: this.model.datatype,
                FeedName: feedName,
                FeedType: this.recordType,
                Interval: this.model.interval,
                SourceType: this.model.sourcetype,
                SourceDetail: (this.recordType == FeedType.Incoming && this.model.sourcetype == SourceType.FileStorage ? filesharename.trim() : sourceDetail),
                TargetType: this.model.targettype,
                TargetDetail: (((this.recordType != FeedType.Incoming) && (this.model.targettype == SourceType.FileStorage)) ? filesharename.trim() : this.model.targetdetail),
                Status: this.model.status,
                TemplateName: this.model.templatename,
                FeedScope: this.model.feedscope,
            }
            this._sharedService.showLoader("Processing...");
            this._apiService.addFeedData(body)
            .subscribe({
                next: (response) => {
                    
                    this._sharedService.hideLoader();
                    if (response == AddUpdateStatusCode.Success) {
        
                        this.display = false;
                        this.isFtpDialogueOpen = false;
                        this._sharedService.resfreshDataFeedGrid(true);
        
                        this.initializeModel();
                        form.resetForm();
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Feed added succesfully.' });
                        this.changeButtonsStatus(false);
                    }
                    else if (response == AddUpdateStatusCode.Duplicate) {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'Feed not added', detail: 'One similar feed is already active.' });
                        this.changeButtonsStatus(false);
                    }
                    else if (response == AddUpdateStatusCode.SameFeedName) {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'Feed not added', detail: 'Feed having same name or values already exist.' });
                        this.changeButtonsStatus(false);
                    }
                },
                error: (error) => {
                    
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Feed not added, please try again.' });
                    this.display = false;
                    this.isFtpDialogueOpen = false;
                    this.initializeModel();
                    form.resetForm();
                    this.changeButtonsStatus(false);
                }
            });
        }
    }
    removeInvalidChars(filename: string): string {
        return filename.replace(/[^a-zA-Z0-9\-\s]+/g, "")!.trim();
    }

    btnCancelClick(cancelForm: NgForm) {
        this.display = false;
        this.isFtpDialogueOpen = false;
        this.initializeModel();
        cancelForm.resetForm();

    }
    // #endregion

    //Start of Base Issue 13 Implimentation


    targetDetailChanged(event) {
        var selectedViewData = this.allViewData.find(x => x.ViewName == event)
        if (selectedViewData.Source) {
            this.model.datatype = null
            this.model.interval = null
            // this.dataTypeList = this._apiService.getDataType();
            // this.intervalList = this._apiService.getIntervalDetails();

        }
        else {
            this.model.datatype = null
            this.model.interval = null
            //  this.dataTypeList = this._apiService.getIncomingDataTypeInteractive();
            // this.intervalList = this._apiService.getIntervalDetailsInteractive();
        }
    }





    //End Start of Base Issue 13 Implimentation




    //#region Date Manipulation And Helper Methods

    setDefaultComponentValues(): void {
        this.initializeModel();
        this.populateFeedTemplate();
        //this.populateDataType();
        //this.populateInterval();
        //this.populateTargetModule();
        this.populateSourceViewsDetails();
        this.populateTargetView();
        //this.populateSourceType();
        //this.populateTargetDetailsRecord();
        //this.populateTargetDetailsRDOC();
        this.initializeModelFtp();
        this.initializeModelAfs();
        // this.initializeModel();
        this.changeButtonsStatus(false);
        if (this.viewId) {
            this.model.targettype = "Status";
            this.isStatusModule = true;
        }
    }

    initializeModel() {

        let _router_ = this._router;
        if (_router_.url == '/repair/viewlist/' + this.viewId) {
            this.statusOrRepair = "Repair";
        }
        else if (_router_.url == '/status/viewlist/' + this.viewId) {
            this.statusOrRepair = "Status";
        }
        else {
            this.statusOrRepair = null;
        }

        this.selectedFeedTemplate = null;
        this.sourceTypeList = [];
        this.dataTypeList = [];
        this.targetModuleList = [];
        this.intervalList = [];
        this.feedScopeList = [];
        this.initializeModelAfs();
        this.model = new AddDataFeedModel(null, null, null, null, null, null, null, null, null, null, null, null, true, null, null, null, null);

    }
    initializeModelFtp() {

        this.ftpModel = new FtpModel(null, null, null, null, null, this.defaultFTPPswd);

    }
    initializeModelAfs() {

        this.afsModel = new AFSModel(null, null, null);

    }

    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }
    //#endregion

    //#region Data Type
    populateDataType() {
        if (this.recordType == "Import") {
            this.dataTypeList = this._apiService.getDataType();
        }
        else {
            this.dataTypeList = this._apiService.getOutgoingDataType();
            //   this.dataTypeListOutgoingInteractive=this._apiService.getOutgoingDataTypeInteractive();
        }
    }
    //#endregion
    //# region Target Detail
    populateTargetDetailsRecord() {
        this.templateTargetDetailList = this._apiService.getTargetDetailsListRecord();
    }
    populateTargetDetailsRDOC() {
        this.targetDetailListRDOC = this._apiService.getTargetDetailsListRDOC();
    }
    //#endregion
    //#region Target Module
    populateTargetModule() {
        if (this.recordType == "Import") {
            this.targetModuleList = this._apiService.getTargetModule();
        }
        else {
            this.targetModuleList = this._apiService.getSourceOutgoinType();
        }
    }
    //#endregion

    //#region  Feed Template
    populateFeedTemplate() {
        if (this.recordType == "Import" || this.recordType == "Export") {
            this._apiService.getFeedTemplates(this.recordType)
            .subscribe({
                next: (response) => {
                    if (response && this.statusOrRepair == "Status") {
                        this.feedTemplateList = response.filter(a => a.TargetType.every(g => g.value == "Status"))
                    }
                    else if (response && this.statusOrRepair == "Repair") {
                        this.feedTemplateList = response.filter(a => a.TargetType.every(g => g.value == "Repair"))
                    }
                    else {
                        this.feedTemplateList = response;
                        //this.feedTemplateList = response;
                    }
                },
                error: (error) => {
                }
            });
        }
    }


    feedTemplateChange(value: any) {


        if (this.viewId) {
            this.model.targettype = this.statusOrRepair;
            if (this.statusOrRepair == "Status" && this.tempExportSourceViewListStatus && this.tempExportSourceViewListStatus.length > 0) {
                let viewSelectItem = this.tempExportSourceViewListStatus.find(asv => asv.ViewID == this.viewId);
                if (viewSelectItem) {
                    this.model.targetdetail = viewSelectItem.value;
                }
            }
            else if (this.statusOrRepair == "Repair" && this.tempExportSourceViewListRepair && this.tempExportSourceViewListRepair.length > 0) {
                let viewSelectItem = this.tempExportSourceViewListRepair.find(asv => asv.ViewID == this.viewId);
                if (viewSelectItem) {
                    this.model.targetdetail = viewSelectItem.value;
                }
            }
        }
        else {
            this.model.targettype = null;
            this.model.targetdetail = null;
        }

        this.model.sourcetype = null;
        this.model.sourcedetail = null;
        this.model.interval = null;
        this.model.datatype = null;
        this.model.feedscope = null;

        this.model.templatename = value.TemplateName
        this.dataTypeList = value.DataType;
        this.sourceTypeList = value.SourceType;
        //this.sourceDetailList = value.SourceDetail;
        this.targetModuleList = value.TargetType;
        //this.targetViewList=value.TargetDetail;
        this.intervalList = value.Interval;
        this.feedScopeList = value.FeedScope;
        this.templateSourceDetail = null;
        this.templateTargetDetail = null;
        this.templateSourceDetailList = [];
        this.templateTargetDetailList = [];

        if (value.SourceDetail && value.SourceDetail.length > 0) {
            this.templateSourceDetail = value.SourceDetail[0].value;
            this.templateSourceDetailList = [{ value: this.templateSourceDetail, label: this.templateSourceDetail }];
        }

        if (value.TargetDetail && value.TargetDetail.length > 0) {
            this.templateTargetDetail = value.TargetDetail[0].value;
            this.templateTargetDetailList = value.TargetDetail;
        }


    }

    //#endregion 


    //#region Target View
    getViewNameByViewId() {
        this._viewService.getViewNameByViewId(this.viewId)
        .subscribe({
            next: (response) => {
                this.model.targetdetail = response;
            },
            error: (error) => {
                console.log(error);
            }
        });
    }




    populateTargetView() {
        if (this.recordType == "Import") {
            this._viewService.getUserViewsList()
            .subscribe({
                next: (response) => {
                    this.targetViewListAll = response;
                    this.exportSourceViewListAll = response;
                    if (response) {
                        this.targetViewListRepair = response.filter(a => a.ViewType == "Repair");
                        this.targetViewListStatus = response.filter(a => a.ViewType == "Status");
                        this.exportSourceViewListStatus = this.targetViewListStatus;
                        this.exportSourceViewListRepair = this.targetViewListRepair;
                        this.tempExportSourceViewListStatus = this.targetViewListStatus;
                        this.tempExportSourceViewListRepair = this.targetViewListRepair;
                    }
                    //this.sourceViewList = response;
                    if (this.viewId) {
                        // get view name and set
                        this.getViewNameByViewId();
        
                    }
                    this.selectedFeedTemplate = this.feedTemplateList[0];
                    this.feedTemplateChange(this.selectedFeedTemplate);
                },
                error: (error) => {
                    console.log(error);
                }
            });

            this._viewService.getAllViews()
            .subscribe({
                next: (response) => {
                    this.allViewData = response;
                    // this.totalCount = response.length;
                },
                error: (error) => {
                    console.log(error);
                    this.allViewData = [];
                }
            });
        }
        else {
            this._assetService.getAllAssetsWithAsn()
            .subscribe({
                next: (response) => {
                    this.sourceAssetDetailList = response;
                    //this.templateSourceDetailList = this._apiService.getSourceDetailOutgoingForRecord();
                    //this.sourceDetailListOutgoingRDOC = this._apiService.getSourceDetailOutgoingForRDOC();
                },
                error: (error) => {
                    console.log(error);
                }
            });

            this._viewService.getAllActiveViewsList(null)
            .subscribe({
                next: (response) => {
                    this.targetViewListAll = response;
                    this.exportSourceViewListAll = response;
                    if (response) {
                        this.targetViewListRepair = response.filter(a => a.ViewType == "Repair");
                        this.targetViewListStatus = response.filter(a => a.ViewType == "Status");
                        this.exportSourceViewListStatus = this.targetViewListStatus;
                        this.exportSourceViewListRepair = this.targetViewListRepair;
                    }
                    //this.sourceViewList = response;
                },
                error: (error) => {
                    console.log(error);
                }
            });
        }
    }

    openFTPDialogue() {

        if ((this.recordType == "Import" && this.model.sourcedetail) || (this.recordType == "Export" && this.model.targetdetail)) {
            this._sharedService.showLoader("Processing...");
            try {
                let ftpDetails = this.recordType == "Import" ? encodeURIComponent(this.model.sourcedetail) : encodeURIComponent(this.model.targetdetail);
                this._apiService.getFtpDetails(ftpDetails)
                .subscribe({
                    next: (response) => {
                        let protocol = response.Protocol;
                        let hostName = response.HostName;
                        if ((protocol == null || protocol == '') || (hostName == null || hostName == '')) {
                            this.ftpModel.FtpHost = null;
                        }
                        else {
                            this.ftpModel.FtpHost = `${protocol != null ? protocol : ''}://${hostName != null ? hostName : ''}`;
                        }
        
                        this.ftpModel.FtpPath = response.Path;
                        this.ftpModel.FtpPassword = response.Password;
                        this.ftpModel.FtpPort = response.Port;
                        this.ftpModel.FtpUserName = response.UserName;
        
        
                        this.ftpModel.FtpPasswordTemp = this.defaultFTPPswd;
        
                        this._sharedService.hideLoader();
                        this.isFtpDialogueOpen = true;
                    },
                    error: (error) => {
                        this._sharedService.hideLoader();
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again' });
                    }
                });
            }
            catch (error) {
                console.log(error);
            }
        }
        else {
            this.ftpModel.FtpPasswordTemp = null;
            this.isFtpDialogueOpen = true;
        }

    }
    openAzureFileStorageDialogue() {

        if ((this.recordType == "Import" && this.model.sourcedetail) || (this.recordType == "Export" && this.model.targetdetail)) {
            this._sharedService.showLoader("Processing...");
            try {
                let ftpDetails = this.recordType == "Import" ? this.model.sourcedetail : this.model.targetdetail;
                let splitedValues = ftpDetails.split(":");
                let obj={
                    KeyValue:splitedValues[0]
                }
                this._apiService.getdecryptedString(obj)
                .subscribe({
                    next: (response) => {
                        this.afsModel = new AFSModel(response, splitedValues[1], splitedValues[2]);
                        this.isAzureFileStorageDialogueOpen = true;
                        this._sharedService.hideLoader();
                    },
                    error: (error) => {
                        this._sharedService.hideLoader();
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again' });
                    }
                });
            }
            catch (error) {
                console.log(error);
            }
        }
        else {

            this.isAzureFileStorageDialogueOpen = true;
        }

    }
    onSubmitFtpDailogue(submitForm: NgForm) {
        let encryptedPassword: string = null;
        let protocol: string = null;
        let host: string = null;
        if (!this.isValidFTPPassword()) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Password', detail: 'Please provide valid password' });
            return;
        }
        else {
            if (this.ftpModel.FtpPasswordTemp != this.defaultFTPPswd) {
                this.ftpModel.FtpPassword = this.ftpModel.FtpPasswordTemp;
            }
        }

        this._sharedService.showLoader("Processing...");
        try {
            let hostString: string = this.ftpModel.FtpHost;
            if (hostString.startsWith("ftp://") || hostString.startsWith("sftp://") || hostString.startsWith("ftps://")) {
                let splitedValues = hostString.split("://");
                protocol = splitedValues[0];
                host = splitedValues[1];
                this._apiService.getEncryptedString(this.ftpModel.FtpPassword)
                .subscribe({
                    next: (response) => {
                        encryptedPassword = response;
                        if (this.recordType == "Import") {
                            this.model.sourcedetail = `${protocol}://${this.ftpModel.FtpUserName}:${encryptedPassword}@${host}:${this.ftpModel.FtpPort}/${this.ftpModel.FtpPath}`;
                        }
                        else if (this.recordType == "Export") {
                            this.model.targetdetail = `${protocol}://${this.ftpModel.FtpUserName}:${encryptedPassword}@${host}:${this.ftpModel.FtpPort}/${this.ftpModel.FtpPath}`;
                        }
                        this._sharedService.hideLoader();
                        this.isFtpDialogueOpen = false;
                    },
                    error: (error) => {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again' });
                    }
                });
            }
            else {
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Host Name', detail: 'Please provide protocol information.' });
            }
        }
        catch (error) {
            console.log(error);
        }


    }
    btnFtpCancelClick(cancelForm: NgForm) {
        this.isFtpDialogueOpen = false;
        this.initializeModelFtp();
        cancelForm.resetForm();

    }
    //#endregion

    //#region Interval
    populateInterval() {
        this.intervalList = this._apiService.getIntervalDetails();
    }
    //#endregion

    //#region Source Type
    populateSourceType() {
        if (this.recordType == "Import") {
            if (this.viewId) {
                this.sourceTypeList = this._apiService.getSourceTypesForStatusIncoming();
            }
            else {
                this.sourceTypeList = this._apiService.getSourceType();
            }
        }
        else {
            this.sourceTypeList = this._apiService.getTargetModuleOutgoing();
        }
    }
    sourceTypeChanged(value: any) {
        if (this.recordType == "Import") {
            this.model.sourcedetail = '';
            this.model.sourcedetail = null;
            //this.targetModuleList = [];
            this.model.datatype = null;
            switch (value) {
                case 'App Storage':
                    //this.targetModuleList = this._apiService.getTargetModule();
                    //this.dataTypeList=[];
                    if (this.model.targettype == 'RDOC' || this.model.targettype == 'Records') {
                        // this.dataTypeList=this._apiService.getIncomingDataTypeInteractive();
                    }
                    else {
                        // this.dataTypeList=this._apiService.getDataType();
                    }

                    break;
                case 'Web Service Pull':
                    //this.targetModuleList = this._apiService.getTargetModule();
                    break;
                case 'Web Service Push':
                    //this.targetModuleList = this._apiService.getTargetModule();
                    break;
                case 'Shared Folder':
                    //this.targetModuleList = this._apiService.getTargetTypeIncomingSharedFolder();
                    // this.dataTypeList=[];
                    if (this.model.targettype == 'RDOC' || this.model.targettype == 'Records') {
                        // this.dataTypeList=this._apiService.getIncomingDataTypeInteractive();
                    }
                    else {
                        //this.dataTypeList=this._apiService.getDataType();
                    }
                    break;
                case 'Status':
                    // this.targetModuleList = this._apiService.getTargetTypeIncomingStatus();
                    break;
                case 'FTP':
                    // this.targetModuleList = this._apiService.getTargetModule();

                    //this.dataTypeList=[];
                    if (this.model.targettype == 'RDOC' || this.model.targettype == 'Records') {
                        //this.dataTypeList=this._apiService.getIncomingDataTypeInteractive();
                    }
                    else {
                        // this.dataTypeList=this._apiService.getDataType();
                    }
                    break;
                case 'Status Link':
                    // this.targetModuleList = this._apiService.getTargetTypeIncomingStatus();
                    break;
                case 'Records':
                    this.targetModuleList = this._apiService.getTargetTypeForRecordsIncomingStatus();
                    break;
            }
        }
        else if (this.recordType == "Export" || this.recordType == "Export") {
            //this.targetModuleList = [];
            this.model.datatype = null;
            //this.dataTypeList=[];
            switch (value) {
                case 'Status Export':
                    // this.targetModuleList = this._apiService.getSourceOutgoinType();
                    //this.dataTypeList=this._apiService.getOutgoingDataType();
                    break;
                case 'Records Export':
                    //  this.targetModuleList = this._apiService.getSourceOutgoinType();
                    // this.dataTypeList=this._apiService.getOutgoingDataType();
                    break;
                case 'Records':
                    // this.targetModuleList = this._apiService.getSourceOutgoinTypeRecords();
                    // this.dataTypeList=this._apiService.getOutgoingDataTypeInteractive();
                    break;
                case 'RDOC':
                    // this.targetModuleList = this._apiService.getSourceOutgoinTypeRDOC();
                    // this.dataTypeList=this._apiService.getOutgoingDataTypeInteractive();
                    break;
                case 'Status Link':
                    //   this.targetModuleList = this._apiService.getSourceOutgoinTypeRecords();
                    //   this.dataTypeList=this._apiService.getOutgoingDataTypeInteractive();
                    break;
            }

        }
    }


    sourceDetailChanged(value: any) {

        if ((this.recordType == "Export" || this.recordType == "Export") && this.model.sourcetype == "RDOC") {
            //this.targetModuleList = [];
            //this.model.datatype = null;
            //this.dataTypeList = [];
            switch (value) {
                case "Document Export":
                    //this.targetModuleList = this._apiService.getSourceOutgoinTypeRDOCDocumentExport();
                    //this.dataTypeList = this._apiService.getOutgoingDataTypeRDOCDocumentExport();
                    break;
                case "Document URL":
                    // this.targetModuleList = this._apiService.getSourceOutgoinTypeRDOC();
                    //this.dataTypeList = this._apiService.getOutgoingDataTypeInteractive();
                    break;
                default:
                    break;
            }
        }

    }

    targetTypeChanged(value: any) {
        if (this.recordType == "Import") {
            // this.model.datatype=null;
            //this.dataTypeList=[];
            switch (value) {
                case 'Status':
                    //this.dataTypeList=this._apiService.getDataType();

                    break;
                case 'Records':

                    //this.dataTypeList=this._apiService.getIncomingDataTypeInteractive();

                    break;
                case 'RDOC':

                    //  this.dataTypeList=this._apiService.getIncomingDataTypeInteractive();
                    break;
                case 'Status Link':
                    // this.dataTypeList=this._apiService.getDataType();
                    break;

            }
        }
    }
    populateSourceViewsDetails() {
        this._viewService.getAllActiveViewsListWithId(null)
        .subscribe({
            next: (response) => {
                this.sourceViewListAll = response;
                if (response) {
                    this.sourceViewListStatus = response.filter(a => a.ViewType == "Repair");
                    this.sourceViewListRepair = response.filter(a => a.ViewType == "Status");;
                }
            },
            error: (error) => {
                console.log(error);
            }
        });
    }


    isValidFTPPassword() {
        if (this.ftpModel.FtpPasswordTemp && this.ftpModel.FtpPasswordTemp != '' && this.ftpModel.FtpPasswordTemp != null && this.ftpModel.FtpPasswordTemp != this.defaultFTPPswd) {
            return true;
        }
        return false;
    }
    //#endregion


    //#region Web Service
    openWebServiceDialogue() {

        if ((this.recordType == "Import" && this.model.sourcedetail) || (this.recordType == "Export" && this.model.targetdetail)) {
            this._sharedService.showLoader("Processing...");
            try {
                let ftpDetails = this.recordType == "Import" ? encodeURIComponent(this.model.sourcedetail) : encodeURIComponent(this.model.targetdetail);
                this._apiService.getWebServiceDetails(ftpDetails)
                .subscribe({
                    next: (response) => {
                        this.webServiceModel.WebServicePath = response.Path;
                        this.webServiceModel.WebServiceAuthType = response.AuthType;
        
                        if (response.WebServiceAuthType != 'NoAuth') {
                            this.webServiceModel.WebServicePassword = response.Password;
                            this.webServiceModel.WebServiceUserName = response.UserName;
                        }
        
                        this.webServiceModel.WebServicePasswordTemp = this.defaultWebServicePswd;
        
                        this._sharedService.hideLoader();
                        this.isWebServiceDialogueOpen = true;
                    },
                    error: (error) => {
                        this._sharedService.hideLoader();
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again' });
                    }
                });
            }
            catch (error) {
                console.log(error);
            }
        }
        else {
            this.webServiceModel.WebServicePasswordTemp = null;
            this.isWebServiceDialogueOpen = true;
        }

    }
    openWebServicePushDialogue() {

        if ((this.recordType == "Import" && this.model.sourcedetail) || (this.recordType == "Export" && this.model.targetdetail)) {
            this._sharedService.showLoader("Processing...");
            try {
                let ftpDetails = this.recordType == "Import" ? encodeURIComponent(this.model.sourcedetail) : encodeURIComponent(this.model.targetdetail);
                ftpDetails = "None!!OAuth!!" + ftpDetails + "!!nopassword";
                this._apiService.getWebServiceDetails(ftpDetails)
                .subscribe({
                    next: (response) => {
                        this.webServiceModel.WebServicePath = response.Path;
                        this.webServiceModel.WebServiceAuthType = response.AuthType;
        
                        //if (response.WebServiceAuthType != 'NoAuth') {
                        // this.webServiceModel.WebServicePassword = response.Password;
                        this.webServiceModel.WebServiceUserName = response.UserName;
                        // }
        
                        // this.webServiceModel.WebServicePasswordTemp = this.defaultWebServicePswd;
        
                        this._sharedService.hideLoader();
                        this.isWebServicePushDialogueOpen = true;
                    },
                    error: (error) => {
                        this._sharedService.hideLoader();
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again' });
                    }
                });
            }
            catch (error) {
                console.log(error);
            }
        }
        else {
            this.webServiceModel.WebServicePasswordTemp = null;
            this.isWebServicePushDialogueOpen = true;
        }

    }
    onSubmitWebServiceDailogue(submitForm: NgForm) {
        let encryptedPassword: string = null;
        if (this.webServiceModel.WebServiceAuthType != 'NoAuth') {
            if (!this.isValidWebServicePassword()) {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Password', detail: 'Please provide valid password' });
                return;
            }
            else {
                if (this.webServiceModel.WebServicePasswordTemp != this.defaultWebServicePswd) {
                    this.webServiceModel.WebServicePassword = this.webServiceModel.WebServicePasswordTemp;
                }
            }
        }
        this._sharedService.showLoader("Processing...");
        try {
            if (this.webServiceModel.WebServiceAuthType != 'NoAuth') {
                this._apiService.getEncryptedString(this.webServiceModel.WebServicePassword)
                .subscribe({
                    next: (response) => {
                        encryptedPassword = response;
                        if (this.recordType == "Import") {
                            this.model.sourcedetail = `${this.webServiceModel.WebServicePath}!!${this.webServiceModel.WebServiceAuthType}!!${this.webServiceModel.WebServiceUserName}!!${encryptedPassword}`;
                        }
        
                        else if (this.recordType == "Export") {
                            this.model.targetdetail = `${this.webServiceModel.WebServicePath}!!${this.webServiceModel.WebServiceAuthType}!!${this.webServiceModel.WebServiceUserName}!!${encryptedPassword}`;
                        }
                        this._sharedService.hideLoader();
                        this.isWebServiceDialogueOpen = false;
                        this.isWebServicePushDialogueOpen = false;
                    },
                    error: (error) => {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again' });
                    }
                });
            } else {

                if (this.recordType == "Import") {
                    this.model.sourcedetail = `${this.webServiceModel.WebServicePath}!!${this.webServiceModel.WebServiceAuthType}`;
                }
                else if (this.recordType == "Export") {
                    this.model.targetdetail = `${this.webServiceModel.WebServicePath}!!${this.webServiceModel.WebServiceAuthType}`;
                }
                this._sharedService.hideLoader();
                this.isWebServiceDialogueOpen = false;
                this.isWebServicePushDialogueOpen = false;

            }
        }
        catch (error) {
            console.log(error);
        }


    }
    onSubmitWebServicePushDailogue(submitForm: NgForm) {
        let encrypteduserName: string = null;
        this.webServiceModel.WebServiceUserName = this.webServiceModel.WebServiceUserName;
        this._sharedService.showLoader("Processing...");
        try {
            this._apiService.getEncryptedString(this.webServiceModel.WebServiceUserName)
            .subscribe({
                next: (response) => {
                    encrypteduserName = response;
                    if (this.recordType == "Import" && !this.isWebServicePushDialogueOpen) {
                        this.model.sourcedetail = `${this.webServiceModel.WebServicePath}!!${this.webServiceModel.WebServiceAuthType}!!${encrypteduserName}!!${''}`;
                    }
                    else if (this.recordType == "Import" && this.isWebServicePushDialogueOpen) {
                        this.model.sourcedetail = `${encrypteduserName}`;
                    }
                    else if (this.recordType == "Export") {
                        this.model.targetdetail = `${this.webServiceModel.WebServicePath}!!${this.webServiceModel.WebServiceAuthType}!!${encrypteduserName}!!${''}`;
                    }
                    this._sharedService.hideLoader();
                    this.isWebServiceDialogueOpen = false;
                    this.isWebServicePushDialogueOpen = false;
                },
                error: (error) => {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again' });
                }
            });
        }
        catch (error) {
            console.log(error);
        }


    }
    btnWebServiceCancelClick(cancelForm: NgForm) {
        this.isWebServiceDialogueOpen = false;
        this.isWebServicePushDialogueOpen = false;
        this.initializeModelWebService();
        cancelForm.resetForm();

    }
    initializeModelWebService() {

        this.webServiceModel = new WebServiceModel(null, null, null, null, null, this.defaultWebServicePswd);

    }

    isValidWebServicePassword() {
        if (this.webServiceModel.WebServicePasswordTemp && this.webServiceModel.WebServicePasswordTemp != '' && this.webServiceModel.WebServicePasswordTemp != null && this.webServiceModel.WebServicePasswordTemp != this.defaultWebServicePswd) {
            return true;
        }
        return false;
    }
    isValidWebServiceUserName() {
        if (this.webServiceModel.WebServiceUserName && this.webServiceModel.WebServiceUserName != '') {
            return true;
        }
        return false;
    }

    populateAuthTypeDropDown() {
        this.authTypeList = [{ label: 'No Auth', value: 'NoAuth' },
        { label: 'Basic Auth', value: 'Basic' }//,
            //{label: 'OAuth', value: 'OAuth'}
        ];
    }

    //#endregion


    applyFocusToInput() {

        this.focusInput.nativeElement.focus();
    }

    onKey() {
        this.isFtpDialogueOpen = false;
        this.initializeModelFtp();
        this.display = false;
    }
    isChecked: boolean = false
    ToggleChecked() {
        if (this.isChecked) {
            this.isChecked = false;
            this.model.status = false;
        }
        else {
            this.isChecked = true;
            this.model.status = true;
        }
    }
    onTargetContainerChange(event: any) {
        this.model.targetdetail = event.targetDetail;
        this.selContainer = event.selectedContainer
    }


    onSubmitAfsDailogue(submitForm: NgForm) {
        let encryptedKey: string = null;
        let protocol: string = null;
        let host: string = null;
        // if (!this.isValidFTPPassword()) {
        //     this._sharedService.clearToast();
        //     this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Password', detail: 'Please provide valid password' });
        //     return;
        // }
        // else {
        //     if (this.ftpModel.FtpPasswordTemp != this.defaultFTPPswd) {
        //         this.ftpModel.FtpPassword = this.ftpModel.FtpPasswordTemp;
        //     }
        // }

        this._sharedService.showLoader("Processing...");
        try {
            // let storageString: string = this.afsModel.AFSStorageName;
            this._apiService.getEncryptedAzureFileStorageConnectionObject(this.afsModel)
            .subscribe({
                next: (response) => {
                    encryptedKey = response;
                    if (this.recordType == "Import") {
                        this.model.sourcedetail = `${response.AFSStorageName}:${response.AFSKey}:${response.AFSPath}`;
                    }
                    else if (this.recordType == "Export") {
                        this.model.targetdetail = `${response.AFSStorageName}:${response.AFSKey}:${response.AFSPath}`;
                    }
                    this._sharedService.hideLoader();
                    this.isAzureFileStorageDialogueOpen = false;
                },
                error: (error) => {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: 'Error occurred, please try again' });
                }
            });
        }

        catch (error) {
            console.log(error);
        }


    }
    btnAfsCancelClick(cancelForm: NgForm) {
        this.isAzureFileStorageDialogueOpen = false;
        this.initializeModelAfs();
        cancelForm.resetForm();

    }
}
