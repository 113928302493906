import { SelectItem } from 'primeng/api';

export class MappingModel {
    public FeedId: number;
    public FeedName: string;
    public Map: Mapping[];
    public FileColumns: SelectItem[];
    public MappingColumns: SelectItem[];
}

//UniqueKey: boolean = false, HistoryKey: boolean = false,
export class Mapping {
    constructor(FileColumn: string, FixedValue: string, MappingColumn: string, FieldOrder: number = 0) {
        this.FileColumn = FileColumn;
        this.MappingColumn = MappingColumn;
        // this.UniqueKey = UniqueKey;
        // this.HistoryKey = HistoryKey;
        this.FieldOrder = FieldOrder;
    }
    public FileColumn: string;
    public FixedValue: string;
    public MappingColumn: string;
    // public UniqueKey: boolean;    
    // public HistoryKey: boolean; 
    public FieldOrder: number;
}