<style>
  :host ::ng-deep img {
    max-width: 100% !important;
    height: auto !important;
  }

  body {
    padding-top: 1% !important;
  }

  #main {

    padding-bottom: 0 !important;
  }
</style>

<!-- <app-search-article></app-search-article> -->
<!-- MAIN PANEL -->
<div role="main" id="main"
  style="background-color: #fbfbfb !important;height: 100vh;overflow-x: hidden; overflow-y: scroll;">




  <!-- MAIN CONTENT -->
  <div id="content" style="opacity: 1; ">
    <section id="widget-grid-main" class="well" style="border: none !important;">
      <div id="content" style="margin-bottom: 3em !important;">
        <div class="row" style="padding-left:6%;padding-right:6%;">
          <div class="col-sm-12">
            <app-search-article></app-search-article>
          </div>
        </div>
        <!-- row -->
        <div class="row" style="padding-left:6%;padding-right:6%;margin-top:1em;">
          <div class="col-sm-12">
            <div>
              <div class="row" style="display: flex !important; flex-wrap: wrap !important;">

                <div class="col-sm-8 "
                  style="background-color: #fff;border:1px solid #ddd; border-right: none !important;">

                  <!-- <div class="forum-attachment" style="margin-top: 1em;">
                                    <a routerLink="/help/helparticles"  href="javascript:void(0);"  style="color: #3276b1;"> Solution Home</a>  /
                                    <a routerLink="/help/helparticles"   href="javascript:void(0);" style="color: #3276b1;"> ROAM Help Center</a>
                              </div> -->

                  <h1
                    style="font-family: poppins, helvetica, sans-serif !important;;font-size:22px;font-weight: 600;height: 26px;color:rgb(24,50,71) !important;">
                    {{articleDetail.Title}} </h1>

                  <div class="row">
                    <div class="col-sm-6">
                      <p
                        style="font-family:poppins, helvetica, sans-serif !important;font-size:14px;font-weight: 400;height: 21px;color:rgb(24,50,71) !important;">
                        Created by: {{articleDetail.CreatedBy}}</p>
                      <p *ngIf="articleDetail.ModifiedBy"
                        style="font-family:poppins, helvetica, sans-serif !important;font-size:14px;font-weight: 400;height: 21px;color:rgb(24,50,71) !important;">
                        Modified by: {{articleDetail.ModifiedBy}}</p>
                    </div>
                    <div class="col-sm-6">
                      <p
                        style="font-family:poppins, helvetica, sans-serif !important;font-size:14px;font-weight: 400;height: 21px;color:rgb(24,50,71) !important;">
                        Created on: {{articleDetail.CreatedOn}}</p>

                      <p *ngIf="articleDetail.ModifiedBy"
                        style="font-family:poppins, helvetica, sans-serif !important;font-size:14px;font-weight: 400;height: 21px;color:rgb(24,50,71) !important;">
                        Modified on: {{articleDetail.ModifiedOn}}</p>
                    </div>
                  </div>

                  <!-- <p style="font-family:poppins, helvetica, sans-serif !important;font-size:14px;font-weight: 400;height: 21px;color:rgb(24,50,71) !important;">Created by: {{articleDetail.CreatedBy}}</p>
                              <p  style="font-family:poppins, helvetica, sans-serif !important;font-size:14px;font-weight: 400;height: 21px;color:rgb(24,50,71) !important;">Modified on: {{articleDetail.ModifiedOn}}</p> -->
                  <hr />
                  <div [innerHTML]='content'></div>

                </div>
                <div class="col-md-4 " style="padding-left: 0 !important;padding-right: 0 !important;">
                  <div class="well"
                    style="padding-left: 0 !important;padding-right: 0 !important;height: 100% !important; ">

                    <div class=" padding-10" style="padding-left:0 ! important">
                      <div>

                      </div>
                      <div style="margin-bottom: 3px !important;padding-left: 0.5em !important;">

                        <ul class="text-left" style="list-style-type:none;">
                          <h6
                            style="font-size: poppins, helvetica, sans-serif !important;;font-size:22px;font-weight: 600;height: 26px;color:rgb(24,50,71) !important;">
                            Related Articles
                          </h6>

                          <li style="margin-top: 0.9em;" *ngFor="let relatedArticle of SameSectionArticle;">
                            <div [hidden]="relatedArticle.ArticleId ==articleDetail.ArticleId">

                              <p style=" line-height:0.7m;height: 1.5em;overflow: hidden;">
                                <span class="fa fa-files-o"> </span>&nbsp;
                                <a (click)="redirectTOArticle(relatedArticle.ArticleId)"
                                  style="cursor: pointer;font-family:poppins, helvetica, sans-serif !important;font-size:14px;font-weight: 400;height: 21px;color:rgb(24,50,71) !important;">
                                  {{relatedArticle.Title}}</a>
                              </p>

                            </div>

                          </li>
                        </ul>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>


        </div>
      </div>
    </section>
  </div>

</div>