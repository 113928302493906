import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { Router } from '@angular/router';
import { OAuthService, AuthConfig, JwksValidationHandler  } from 'angular-oauth2-oidc';
import { UserService } from '../../../records/services/User/user.service';
import { IConfig } from '../../helpers/config';
import {filter} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [ UserService ]
})
export class AppComponent implements OnInit {

    private jwtHelper: JwtHelperService = new JwtHelperService();
    isAuthUser: boolean = false;
    constructor(private oauthService: OAuthService,private _sharedService: SharedService, private _router: Router, private _userService: UserService) {
        this.isAuthUser = this._sharedService.isAuthUser();
        if (!this.isAuthUser) {
            let subDomain = this._sharedService.getSubdomain();
            if(subDomain == 'portal'){
                 this._router.navigate(['portal']); 
                 return;
             }

            if (window.location.pathname.indexOf('/Document') != -1) {
                this._sharedService.isViewDocOnly = true;
                this._sharedService.documentUrl = decodeURIComponent(window.location.search);
            }

            if(window.location.href.indexOf('access_token')< 0){
                if(!window.location.href.includes('/default')){
                this._userService.getTenantBySubDomain(subDomain)
                .subscribe({
                    next: (response) => {
                        if (response) {
                            let authConfig = JSON.parse(response.value);
                            this._sharedService.configureSSOAuth(authConfig);
                        } else {
        
                            this._router.navigate(['login']);
                        }
                    },
                    error: (error) => {
                        console.log(error);
                        this._router.navigate(['login']);
                    }
                });

                }else {
                    this._sharedService.isDefaultLogin = true;
                }
            }
           
        } else {
            if (window.location.pathname.indexOf('/Document') != -1) {
                this.updateRoles();
                this._sharedService.documentUrl = decodeURIComponent(window.location.search);
                this._router.navigate(['Document']);
            } else {
                
                this._router.navigate(['dashboard','record']);
                // this._router.navigate(['record']);
            }
        }

    }

    ngOnInit() {

    }

   updateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }
    }
}
