<form id="recordConfigDlg" #addForm="ngForm">
  <header role="heading" class="tab-header">
  </header>
  <div id="row" class="row addaircraftcls">
    <article class="col-sm-12 col-md-12 col-lg-12" id="col2">
      <div class="jarviswidget" id="wid-id-0" data-widget-colorbutton="false" data-widget-editbutton="false"
           data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false">
        <header>
          <span class="widget-icon"><i ></i></span>
          <h2>{{selectedFeedConfiguration}} Configuration</h2>
          <div>
            <input type="text" (input)="onSearch($event.target.value)" 
                   placeholder="Search Asset"
                   style="float: right; height: 2.5rem; margin: .4rem; border: #c2c2c2 1px solid;border-radius: 3px;">
          </div>
        </header>
        <!-- widget div-->
        <div class="widgetdiv">
          <!-- widget content -->
          <div class="widget-body">
            <!-- widget grid -->
            <section id="widget-grid0" style="min-height: 65vh;max-height: 65vh;overflow-y: scroll;overflow-x: hidden;">
              <div class="smart-form">
                <div class="row">
                  <div class="form-group col-sm-12">
                    <table class="table table-bordered " id="tblStatusMapping">
                      <thead>
                        <tr>
                          <th style="text-align: center;">
                            <label class=""><input type="checkbox" 
                                   name="chkIncludeAll" id="chkIncludeAll"
                                   (change)="chechAll($event)" [(ngModel)]="isCheckedAll">
                            </label>
                          </th>
                          <th style="width: 97%;">Asset</th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr *ngFor="let asset of tempAssetList; let i = index; trackBy:trackByIndex">
                          <td style="text-align: center;">
                            <label class="">
                              <input type="checkbox" name="chkInclude_{{i}}" 
                                     id="chkInclude_{{i}}"
                                     [(ngModel)]="asset.isSelected" (change)="onChangeCheckBox($event,asset)">
                            </label>
                          </td>
                          <td>{{asset.label}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
            <div class="widget-footer">
              <p-dropdown appendTo="body" [panelStyle]="{'bottom': 'calc(100% + 5px)'}"
                          [style]="{'text-align':'left','max-width':'100%','width':'100%'}"
                          *ngIf="config.recordType=='ExportTemp'" class="col-md-2" [(ngModel)]="tempFeed.DataType"
                          name='selectedDataType' [autofocus]="true"
                          [options]="dataTypes">
              </p-dropdown>
              <p-dropdown appendTo="body" [panelStyle]="{'bottom': 'calc(100% + 5px)'}"
                          [style]="{'text-align':'left','max-width':'100%','width':'100%'}"
                          *ngIf="config.recordType=='ExportTemp'" class="col-md-2" [(ngModel)]="tempFeed.SourceDetail"
                          name='selectedContainerCategory' [autofocus]="true"
                          [options]="containerCategoriesList">
              </p-dropdown>
              <select-target-container class="margin-right-3px" 
                                       *ngIf="config.recordType=='ExportTemp'"
                                       [inputDataType]='tempFeed.DataType'
                                       [configurationSelectedRows]="configurationSelectedRows"
                                       (targetContainer)="onTargetContainerChange($event)"></select-target-container>

              <button *ngIf="config.recordType=='ExportTemp'" type="button"
                      class="btn btn-primary margin-right-3px custom-button" 
                      (click)="generateToMyReports()">{{generateoMyReportsBtn}}</button>
              <button type="button" class="btn btn-primary margin-right-3px custom-button" (click)="generateAndEmail()">
                {{generateButtonLabel}}
              </button>
              <a class="btn btn-default" (click)="btnCancelClick()">Cancel</a>
            </div>
          </div>
        </div>
      </div>
      <!-- end widget -->
    </article>
    <!-- END COL -->
  </div>
</form>