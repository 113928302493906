//#region References
import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GridOptions, GridApi } from "ag-grid-community";
import "ag-grid-enterprise";
import { Subscription } from 'rxjs';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
import { SelectItem }  from 'primeng/api';
import * as moment from 'moment/moment';
import { AuditService } from '../../../shared/services/Audit/audit.service';
import { AuditFilterModel } from '../../../shared/services/Audit/audit';
import { UserService } from '../../../records/services/User/user.service';
import { AuditTableService } from '../../../admin/services/audit-table/audit-table.service';
import { NgForm } from '@angular/forms';
import { AuditModel } from '../../../shared/services/Audit/audit';
import { CustomDateComponent } from '../../../shared/components/custom-date/custom-date.component';
var dateTimeConfig: IDateFormatInfo;

//#endregion

//#region Type Decorator
@Component({
    selector: 'audit',
    templateUrl: './audit.component.html',
    providers: [AuditService, UserService, AuditTableService]
})
//#endregion

export class AuditComponent implements OnInit {
    //#region Locals Declaration

    
    columnDefs: any[];
    public gridData: any[] = [];
    public gridOptions: GridOptions;
    public api: GridApi;
    subscription: Subscription;
    model: AuditFilterModel;
    dateFormatCalender = "";
    userType: string;
    totalAuditCount: number;
    userList: SelectItem[] = [];
    auditTableList: SelectItem[] = [];
    fieldNameList: SelectItem[] = [];
    public ViewDateFormat:string;
    //#endregion

    //#region Component Initilization
    constructor(private _sharedService: SharedService, private _auditService: AuditService, private _auditTableService: AuditTableService, private _userService: UserService, private _router: Router, private _route: ActivatedRoute) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            dateTimeConfig = this._sharedService.getDateTimeFormatConfiguration();
            this.userType = this._sharedService.getCurrentUserType();
            this.UpdateRoles();
            this.navigateToNextCell=this.navigateToNextCell.bind(this);
        }
    }
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    ngOnInit() {
        this.setCalenderDateFormat();
        this.initializeEmptyModel();
        this.populateUsersList();
        this.populateAuditTableList();
        this.gridOptions = <GridOptions>
            {
                rowSelection: 'single',
                rowData: [],
                onGridReady: (param:any) => {
                    if (this.gridOptions.api != undefined) {
                        if (this.gridData.length > 0) { this.gridOptions.api.hideOverlay(); }
                        this.gridOptions.api.sizeColumnsToFit();
                        this.api = param.api;
                        try {
                            //this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridAudit'));
                            this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSizeAudit('agGridAudit'));
                            this.gridOptions.api.hideOverlay();
                        } catch (e) {
                            console.log(e);
                        }
                    }
                },
                onModelUpdated: (event: any) => {
                    this.gridOptions.api.sizeColumnsToFit();
                },
                rowGroupPanelShow: 'always',
                //suppressContextMenu: true,
                pagination: true,
                getContextMenuItems: this.getCustomContextMenuItems,
                context: {
                    gridContext: this
                },
                paginationPageSize: 20,
                onRowDoubleClicked: (event: any)=>{
                    this.gridDoubleClick(event);
                },
                defaultColDef: {
                    sortable: true,
                    resizable:true,
                },
                components : { agDateInput: CustomDateComponent }
            }
        this.setGridColumns();
        this.ViewDateFormat=this._sharedService.getLabelTextDateFormat();
    }

    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];
        if (params.context.gridContext.totalAuditCount > 0) {

            let childResult: any[] = [];
            childResult.push({
                name: 'Excel',
                action: function () {
                    
                    params.api.exportDataAsExcel();
                    
                },
                icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
            });

            childResult.push({
                name: 'CSV',
                action: function () {
                    params.api.exportDataAsCsv();
                },
                icon: params.context.gridContext._sharedService.saveLogFeedIconHTMLasString,
            });

            result.push({
                name: 'Download',
                action: function () {


                },
                icon: params.context.gridContext._sharedService.downloadsFeedIconHTMLasString,
                subMenu: childResult,
            });
        }
        return result;
    }
    //#endregion

    //#region AG Grid Date Comparer
    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).split(dateTimeConfig.dateSeparator);
            var day = Number(dateParts[Number(dateTimeConfig.day)].substring(0, 2));
            var month = Number(dateParts[Number(dateTimeConfig.month)]) - 1;
            var year = Number(dateParts[Number(dateTimeConfig.year)]);

            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        }
        else {
            return -1;
        }
    }
    //#endregion


    //#region Search Section

    initializeEmptyModel() {
        this.model = new AuditFilterModel();
        this.totalAuditCount = 0;
    }
    getFromDate(): Date {
        let currentDate = new Date();
        let cellDate = new Date(currentDate.getFullYear(), currentDate.getMonth()-1, currentDate.getDate());
        return cellDate;
    }
   
    populateUsersList() {
        this._userService.getCurrentTenantUserList()
        .subscribe({
            next: (response) => {
                this.userList = response;                
            },
            error: (error) => {
                console.error(error);                
            }
        });
    }

    populateAuditTableList() {
        this._auditTableService.getAuditTableData()
        .subscribe({
            next: (response) => {
                this.auditTableList = response;
                this.totalAuditCount = response.length;                
            },
            error: (error) => {
                console.error(error);                
            }
        });
    }

    onAuditTableChange(event:any) {
        if (event.value) {
            this._auditTableService.getEntityFieldNames(event.value)
            .subscribe({
                next: (response) => {
                    this.fieldNameList = [];
                    this.fieldNameList = response;                    
                },
                error: (error) => {
                    this.fieldNameList = [];
                    console.error(error);                    
                }
            });
        }
    }

    //btnCancelClick(form: NgForm) {
    //    this.initializeEmptyModel();
    //    form.resetForm();
    //}
    //btnResetClick() {
    //    this.initializeEmptyModel();
    //    this.model.FromDate = this.getFromDate();
    //    this.model.ToDate = this.getToDate();
    //}

    searchAudit() {
        let body: any =
            {
                AuditId: this.model.AuditId,
                PK: this.model.PK,
                FieldName: this.model.FieldName,
                //OldValue: this.model.OldValue,
                //NewValue: this.model.NewValue,
                Changeset: this.model.Changeset,
                TableName: this.model.TableName,
                UserName: this.model.UserName,
                FromDate: this.getDate(this.model.FromDate),
                ToDate: this.getDate(this.model.ToDate),
            }
        this._sharedService.showLoader("Processing...");
        this._auditService.getAuditData(body)
        .subscribe({
            next: (response) => {
                this.api.setRowData(response);
                this._sharedService.hideLoader();                
            },
            error: (error) => {
                this._sharedService.hideLoader();
                this._sharedService.showToast(
                    { 
                        severity: 'error', 
                        summary: 'Error',
                        detail: 'Error occurred, please try again later.'
                    }
                );                
            }
        });
    }
    setCalenderDateFormat() {
        let dateParts: string[] = dateTimeConfig.dateformat.split(dateTimeConfig.dateSeparator);

        if (dateTimeConfig.year == 0) {
            this.dateFormatCalender = (dateParts[dateTimeConfig.year].substr(0, 2) + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day]).toLowerCase();
        }
        else if (dateTimeConfig.year == 2) {
            if (dateTimeConfig.month == 1) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
            else if (dateTimeConfig.month == 0) {
                this.dateFormatCalender = (dateParts[dateTimeConfig.month] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.day] + dateTimeConfig.dateSeparator + dateParts[dateTimeConfig.year].substr(0, 2)).toLowerCase();
            }
        }
        else {
            this.dateFormatCalender = "yy-mm-dd"
        }

    }
    getDate(date: Date): string {  
        if (date != null) {
            let newDate = moment(date).format("YYYY-MM-DD");
            return new Date(newDate).toDateString();
        }
        return null;
    }
   
    //#endregion

    //#region Set Columns of Inbox Ag Grid
    setGridColumns(): void {
        this.columnDefs = [];
        this.columnDefs =
            [
                //{
                //headerName: 'AuditId', field: 'AuditId', filter: 'set', menuTabs: ['filterMenuTab'], width: 100
                //},
                {
                headerName: 'User Name', field: 'UserName', tooltipField: 'UserName', filter: 'set', menuTabs: ['filterMenuTab'], enableRowGroup: true
                },
                {
                    headerName: 'Entity Name', field: 'TableName', tooltipField: 'TableName', filter: 'set', menuTabs: ['filterMenuTab'], enableRowGroup: true
                },
                {
                    headerName: 'Record ID', field: 'PK', enableRowGroup: true, filter: 'set', width: 100, menuTabs: ['filterMenuTab']
                    //, valueFormatter: function (params: any) {
                    //    if (params.value != "" && params.value != null) {
                    //        let pk = '';
                    //        let pkString:string[] = params.value.split("=");
                    //        if (pkString.length == 2) {
                    //           pk = pkString[1].split('>')[0];
                    //        }
                    //        return pk;
                    //    }
                    //}
                },
                {
                    headerName: 'Operation', field: 'Type', enableRowGroup: true, filter: 'set', width: 100, menuTabs: ['filterMenuTab'], valueFormatter: function (params: any) {
                        if (params.value != "" && params.value != null) {

                            if (params.value == "I") {
                                return "Created";
                            }
                            else if (params.value == "U") {
                                return "Modified";
                            }
                            else if (params.value == "D") {
                                return "Deleted";
                            }
                            else if (params.value == "L") {
                                return "Log";
                            }
                            else if (params.value == "E") {//Eingeloggt
                                return "Logged-In";
                            }
                            else if (params.value == "A") {//Abgemeldet
                                return "Logged-Out";
                            }                            
                        }
                    }
                },
                //{
                //    headerName: 'Field Name', field: 'FieldName', tooltipField: 'FieldName', enableRowGroup: true, width: 140, filter: 'set', menuTabs: ['filterMenuTab']
                //},
                //{
                //    headerName: 'Old Value', field: 'OldValue', tooltipField: 'OldValue', enableRowGroup: true, filter: 'set', menuTabs: ['filterMenuTab']
                //},
                //{
                //    headerName: 'New Value', field: 'NewValue', tooltipField: 'NewValue', enableRowGroup: true, filter: 'set', menuTabs: ['filterMenuTab']
                //},
                // {
                //     headerName: 'Changeset', field: 'Changeset', tooltipField: 'Changeset', enableRowGroup: true, filter: 'set', menuTabs: ['filterMenuTab']
                //},
                {
                    headerName: 'Date', field: 'UpdateDate', enableRowGroup: true, filter: 'agDateColumnFilter', filterParams: {
                        comparator: this.dateComparater,
                        inRangeInclusive: 'true'

                    }, valueFormatter: function (params: any) {
                        if (params.value != "" && params.value != null) {
                            let dateString = params.value;
                            return moment(dateString).format(dateTimeConfig.dateformat.toUpperCase() + ' ' + dateTimeConfig.timeformat);
                        }
                    }, menuTabs: ['filterMenuTab'], width: 150,
                },
            ];
    }
    //#endregion



    //#region Roles
    UpdateRoles() {
        //if (!this._sharedService.UserRole) {
        //    this._sharedService.updateRole();
        //}
        //this.isAddVisible = false;
        //this.isEditVisible = false;

        //this.isAddVisible = this._sharedService.UserRole.Comm_Add;
        //this.isEditVisible = this._sharedService.UserRole.Comm_Edit;
        //if (this._router.url.indexOf("status/viewlist") != -1 || this._router.url.indexOf("record") != -1) {
        //    this.isViewVisible = false;
        //}
        //else {
        //    this.isViewVisible = true;
        //}
    }
    //#endregion


    // #region Audit

    gridDoubleClick(event: any) {
        if (event) {
            //let id = event.data.Id;
            //let auditCong: AuditModel = new AuditModel();
            //auditCong.PK = event.data.
            //auditCong.TableName = "Task"; 

            this._sharedService.showAuditHistory(event.data);
        }
    }

    
    navigateToNextCell(params: any) {
        var previousCell = params.previousCellPosition;
        var suggestedNextCell = params.nextCellPosition;
        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        switch (params.key) {
            case KEY_DOWN:
                var nextRowIndex = previousCell.rowIndex + 1;
                var renderedRowCount = this.gridOptions.api.getModel().getRowCount();
                if (nextRowIndex >= renderedRowCount) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
                    rowNode.setSelected(true);
                    return {
                        rowIndex: nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }


            case KEY_UP:
                var _nextRowIndex = previousCell.rowIndex - 1;
                if (_nextRowIndex < 0) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
                    rowNode.setSelected(true);
                    return {
                        rowIndex: _nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw 'this will never happen, navigation is always on of the 4 keys above';
        }
    }
// #endregion
}