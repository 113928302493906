import { articleModel, CatSecAndArticle, sectionDetail } from '../articles/article';
import { SharedService } from '../../../shared/services/shared.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ArticleService } from '../../services/article/article.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as moment from 'moment';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
//import { defaultIterableDiffers } from '@angular/core/src/change_detection/change_detection';
var _router: Router;
var dateTimeConfig: IDateFormatInfo;
@Component({
  selector: 'app-component-articles',
  templateUrl: './component-articles.component.html',
  styles: []
})
export class ViewArticleComponent implements OnInit {


  public CatSecAndArticle: CatSecAndArticle = {};
  public SectionDescription;
  public ArticleDetail;
  public Sections: sectionDetail[] = [];
  public LeftSideSections: sectionDetail[] = [];
  public RightSideSections: sectionDetail[] = [];
  public Name: '';
  public SameSectionArticle: articleModel;
  paramsSub: Subscription;
  @Input() componentName: string;
  constructor(private _sharedService: SharedService, private sanitizer: DomSanitizer, private _route: ActivatedRoute, private _articleHelp: ArticleService, private router: Router) {

    if (!this._sharedService.isAuthUser()) {
      this.router.navigate(['login']);
    }
    else {

      this._sharedService.updateRole();
      _router = this.router;
    }
  }
  public helpId;
  display = false
  showEditDialog() {
    if (document.getElementById('articleInputId')) {
      (<HTMLInputElement>document.getElementById('articleInputId')).value = '';
    }
    this.display = true;
    this.Sections = [];
    this.loadData('"value":"' + this.componentName + '"');
  }

  ngOnInit() {
    this._sharedService.prevURL = _router.url;
    dateTimeConfig = this._sharedService.getDateTimeFormatConfigFromLocalStorage();
    if (this.paramsSub) { this.paramsSub.unsubscribe(); }
    this.paramsSub = this._route.params
      .subscribe((params: Params) => {
        if (params['id']) {
          this.helpId = params['id'];
          // this.loadData(this.helpId);
          // this.loadData('res');
        }
        else {
          var splitUrl = window.location.href.split('/');
          // this.loadData(splitUrl.length - 1);
          // this.loadData('res');

        }
      });

    // this.loadData('res');
    // this.loadData(null);
  }

  trackByIndexFunc(index: number, value: number) {
    return index;
  }

  updateRoles() {
    if (!this._sharedService.UserRole) {
      this._sharedService.updateRole();
    }

  }

  redirect(id) {
    this.SectionDescription = this.CatSecAndArticle[0].Sections.find(x => x.ArticleSectionId === id);
    this._articleHelp.sectionChangeInfo(this.SectionDescription as any);
    _router.navigate(["/help/folder", id]);
  }

  redirectArticleId;
  articleDetails = false;
  redirectToArticle(ArticleId) {
    this.articleDetails = true;
    this.redirectArticleId = ArticleId;
    this.ArticleById(ArticleId)
    // _router.navigate(["/help/article", ArticleId]);

  }
  loadData(id): void {

    this._sharedService.showLoader("Processing...");
    this._articleHelp.getArticleDetailByComponent(id)
      .subscribe({
        next: (response) => {
          this._sharedService.hideLoader();
          // this.CatSecAndArticle = response;
          // this.Sections = this.CatSecAndArticle[0].Sections;
          this.Sections = response;
          this.RightSideSections = this.Sections.filter(({ DisplayColumn }) => DisplayColumn === 2)
          this.LeftSideSections = this.Sections.filter(({ DisplayColumn }) => DisplayColumn === 1)
          // this.Name = this.CatSecAndArticle[0].Name;
        },
        error: (error) => {
          console.log("some thing wrong  while getting data !");
        }
      });
  }
  ngOnDestroy() {
    if (this.paramsSub) {
      this.paramsSub.unsubscribe();
    }
  }






  // @HostListener('document:click', ['$event'])
  // onclickAnchor(event) {

  //   if (event.target.matches('a')) {

  //     if (event.target.attributes["href"].nodeValue.indexOf('../article/') >= 0) {
  //       let hrf = event.target.attributes["href"].nodeValue;
  //       let newString = "";
  //       for (var i = hrf.length - 1; i >= 0; i--) {
  //         newString += hrf[i];
  //       }
  //       let index = newString.indexOf("/");
  //       let result = newString.substr(0, index);
  //       let aricleId = "";
  //       for (var i = result.length - 1; i >= 0; i--) {
  //         aricleId += result[i];
  //       }
  //       this.ArticleById(aricleId);
  //       this.router.navigate(["/help/article", aricleId]);

  //       return false;

  //     }

  //   }

  // }

  redirectTOArticle(ArticleId) {
    // _router.navigate(["/help/article", ArticleId]);
    this.articleDetails = true;
    this.redirectArticleId = ArticleId;
    this.ArticleById(ArticleId)

  }
  // updateRoles() {
  //   if (!this._sharedService.UserRole) {
  //     this._sharedService.updateRole();
  //   }
  // }
  public articleDetail;
  public content: SafeHtml;

  ArticleById(id) {
    // var articleInputId:any=document.getElementById('articleInputId')
    // if(articleInputId && articleInputId.value)
    // (<HTMLInputElement>document.getElementById('articleInputId')).value=articleInputId.value
    // if(this.articleInputId && this.articleInputId.value)
    // (<HTMLInputElement>document.getElementById('articleInputId')).value=this.articleInputId.value
    this.articleDetail = [];
    this.searchdata = this.searchdata
    this._sharedService.showLoader("Processing...");
    this._articleHelp.getArticlebyId(id)
      .subscribe({
        next: (res) => {
          if (document.getElementById('articleInputId')) {
            (<HTMLInputElement>document.getElementById('articleInputId')).value = '';
            (<HTMLInputElement>document.getElementById('articleInputId1')).value = ''
          }
          this.articleDetail = res;
          var modifiedDate = new Date(res.ModifiedOn).toString();
          this.articleDetail.ModifiedOn = moment(modifiedDate).format(dateTimeConfig.dateformat.toUpperCase() + " h:mm a").toString();

          var createdDate = new Date(res.CreatedOn).toString();
          this.articleDetail.CreatedOn = moment(createdDate).format(dateTimeConfig.dateformat.toUpperCase() + " h:mm a").toString();

          this.content = this.sanitizer.bypassSecurityTrustHtml(this.articleDetail.ArticleContentHtml);
          this.relatedArticles(this.articleDetail.ArticleSectionId);
          this._sharedService.hideLoader();
        },
        error: (error) => {
          console.log("something went wrong!");
          this._sharedService.hideLoader();
        }
      });
  }

  relatedArticles(ArticleSectionId) {
    this._articleHelp.relatedArticles(ArticleSectionId).subscribe(r => this.SameSectionArticle = r);
  }




  articleInputId: any = ''

  redirectSearchArticle(event: any) {
    (<HTMLInputElement>document.getElementById('articleInputId')).value = '';

    if (event.ArticleId) {
      this.articleDetails = true;
      this.redirectArticleId = event.ArticleId;
      this.ArticleById(event.ArticleId)
    }

  }
  searchdata = ''
  // btnSubmitClick() {
  //   _router.navigate(["/help/search-articles", this.searchdata]);
  // }

  search(event) {

    let query = event.query;
    this.GetFilteredData(query);

  }

  public results = [];

  GetFilteredData(query) {

    //  this.results=[];
    this._articleHelp.getArticlesForSearch(query).subscribe(
      res => {
        this.results = res;
      }, error => {
        console.log("Server Error !");
      }
    );

  }
}
