import { Pipe, PipeTransform } from '@angular/core';
import { TreeNode } from 'primeng/api';


@Pipe({ name: 'AssetFilter' })

export class AssetFilterPipe implements PipeTransform {

    transform(nodes: TreeNode[], filter: string, oNodes: TreeNode[]): any {

        let refNodes: TreeNode[] = [];
        let assets: TreeNode[] = [];

        if (filter !== undefined && filter != "") {
            assets = oNodes; //JSON.parse(JSON.stringify(oNodes));
            return filterNodeArray(assets, filter);
        } else
            return nodes;
    }
}

function deepCopy(o: any) {
    var copy = o, k;

    if (o && typeof o === 'object') {
        copy = Object.prototype.toString.call(o) === '[object Array]' ? [] : {};
        for (k in o) {
            copy[k] = deepCopy(o[k]);
        }
    }

    return copy;
}


function filterNodeArray(oNodes: TreeNode[], filter: string): TreeNode[] {

    let tempNodes: TreeNode[] = oNodes;
    let fnlNodes: TreeNode[] = [];
    let airCraft: TreeNode;
    let assets: TreeNode[];
    // for (let i = 0; i < tempNodes.length; i++) {
    //     //tempNodes.forEach((pNode) => {
    //     let ch2Node:TreeNode[] = tempNodes[i].children == null? [] :tempNodes[i].children;
    //     airCraft = tempNodes[i];
    //     airCraft.expanded = true;
    //     assets = [];
    //     //ch2Node.forEach((cNode) => {
    //     for (let j = 0; j < ch2Node.length; j++) {
    //         let ch3Nodes:TreeNode[] = [];
    //         if(ch2Node[j] && ch2Node[j].children){
    //             ch3Nodes = ch2Node[j].children == null? [] :ch2Node[j].children
    //         }

    //         let filterNodes = ch3Nodes.filter(a => a.label.toLowerCase().includes(filter.toLowerCase()));
    //         ch2Node[j].children = filterNodes;
    //         if (ch2Node[j].children && ch2Node[j].children.length > 0) {
    //             ch2Node[j].expanded = true;
    //             assets.push(ch2Node[j]);

    //         }
    //         //})
    //     }

    //     if (assets && assets.length > 0) {
    //         airCraft.children = assets;
    //         fnlNodes.push(airCraft);
    //     }
    //     //})
    // }

    tempNodes.forEach((pNode) => {
        let ch2Node = pNode.children;
        airCraft = pNode;
        airCraft.expanded = true;
        assets = [];
        if (ch2Node) {
            ch2Node.forEach((cNode) => {
                //let ch3Nodes = cNode.children;
                let ch3Nodes: TreeNode[] = [];
                if (cNode && cNode.children) {
                    ch3Nodes = cNode.children == null ? [] : cNode.children;
                }
                let filterNodes = ch3Nodes.filter(a => a.label.toLowerCase().includes(filter.toLowerCase()));
                cNode.children = filterNodes;
                if (cNode.children && cNode.children.length > 0) {
                    cNode.expanded = true;
                    assets.push(cNode);

                }
            })
        }

        if (assets && assets.length > 0) {
            airCraft.children = assets;
            fnlNodes.push(airCraft);
        }
    });

    return fnlNodes;

}




function filterNodeArrayDepricated(nodes: TreeNode[], filter: string): TreeNode[] {
    if (nodes) {
        let finalNodes: TreeNode[] = [];
        let resultNodes = nodes.filter((node) => filterNodeRecursively(node, filter));
        return resultNodes;
    }
}

function filterNodeRecursively(node: TreeNode, filter: string) {
    if (node.children) {
        return filterNodeArray(node.children, filter).length > 0;
    } else {
        return matches(node, filter);
    }
}

function matches(node: TreeNode, filter: string): TreeNode {

    let isMatch = node.label.toUpperCase().startsWith(filter.toUpperCase());
    return isMatch ? node : null; // || node.id.toUpperCase().startsWith(filter.toUpperCase());
}
