<section id="widget-grid" class="adminaircraftcls" *ngIf="canAddEditUser">
    
    <div class="row">
        <article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable" id="col1">

            <div class="no-margin jarviswidget jarviswidget-sortable" id="wid-id-2" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false" role="widget">
               
                <header role="heading" class="p-sortable-handle">

                    <span class="widget-icon"><i class="fa fa-user"></i></span>
                    <h2>Users</h2>                
                    <div class="jarviswidget-ctrls" title="Edit User (Ctrl+E)">
                        <a class="button-icon hand-cursor tablIndexFocus"  [attr.tabindex]="0"  (keyup.enter)="btnEditClick()" id="btnedit" (click)="btnEditClick()">
                            <i class="glyphicon glyphicon-pencil"></i>
                        </a>
                    </div>
                    <div class="jarviswidget-ctrls" role="menu" title="Add User (Ctrl+A)">
                        <a class="button-icon hand-cursor tablIndexFocus"  [attr.tabindex]="0"  (keyup.enter)="btnAddClick()" id="btnadd" (click)="btnAddClick()">
                            <i class="glyphicon glyphicon-plus"></i>
                        </a>
                    </div>
                    <!-- <div class="jarviswidget-ctrls" title="Upload Template">
                        <a class="button-icon" id="btnuploadusertemplate" (click)="uploadTemplateFile()">
                            <i class="glyphicon glyphicon-open"></i>
                        </a>
                    </div> -->
                    <download-template [templateType]="templateType"></download-template>
                    <upload-template [templateType]="templateType" *ngIf="canUploadTemplate"></upload-template>
                    <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>
                    <div class="float-div-right"> <!--*ngIf="recordType!='5'"-->
                        <label for="allcomms" class="checkbox col-sm-1 no-margin">
        
                            <span class="onoffswitch tablIndexFocus" [attr.tabindex]="0" style="width: 53px !important;margin-top: 7px !important;"  >
                                <input  type="checkbox" name="allusers" class="onoffswitch-checkbox" id="allusers" [(ngModel)]="checkuser" (ngModelChange)="showAllUsersRecords()">
                                <label class="onoffswitch-label" for="allusers"><span class="onoffswitch-inner onoffswitchpadding" data-swchon-text="Active" data-swchoff-text="All" style="padding-left: 0% !important;"></span><span class="onoffswitch-switch" style="height:19px;width:19px;"></span></label>
                            </span>
                        </label>
                    </div>
                </header>
              

                <div class="widgetdiv" > 


                    <div class="jarviswidget-editbox">


                    </div>

                    <div id="userlisting" class="no-bottom-padding widget-body">
                       
                        <section id="widget-grid2" class="">
                            
                            <div class="jqgridboxadminaircraft">  <!--style="height:80vh !important;"-->
                               
                                <ag-grid-angular id="agGridUserManagement" [navigateToNextCell]="navigateToNextCell"   #agGridUserManagement [gridOptions]="gridOptions" [columnDefs]="columnDefs" (rowClicked)='updateSelectedUserRow($event)' (rowDoubleClicked)='btnEditClick($event)' [rowData]="gridData" sortable="true" filter=true style="width: 100%;" class="ag-theme-balham usersgrid"><!--width: 100%;-->
                                </ag-grid-angular>
                            </div>
                        </section>
                      
                    </div>
                 
                </div>
              
            </div>
            
        </article>
      
    </div>
   
</section>