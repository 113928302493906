export class EditDataFeedModel {

    constructor(
        public feedid?: number,

        public feedname?: string,

        public feedtype?: string,

        public datatype?: string,

        public sourcetype?: string,

        public sourcedetail?: string,

        public targettype?: string,

        public targetdetail?: string,

        public interval?: string,

        public lastrun?: Date,

        public mapping?: string,

        public feedstatus?: string,

        public status?: boolean,

        public createdby?: string,

        public createdon?: Date,

        public modifiedby?: string,

        public modifiedon?: Date,

        public templatename?: string,

        public feedscope?: string
    ) { }

}