//Components
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, Params, NavigationEnd, NavigationStart } from '@angular/router';
import { GridOptions, GridApi, ColumnApi,AgEvent,AgGridEvent } from "ag-grid-community";

@Component({
    selector: 'checkboxComp-history',
    changeDetection:ChangeDetectionStrategy.OnPush,
    templateUrl: './checkbox-history.component.html',

})
export class CheckboxHistoryComponent implements OnInit {
    index: number = 0;
    currentValue: boolean = false;
    paramsGlobal: any;
    constructor() {

    }
    agInit(params:any)
    {
        this.paramsGlobal = params;
        this.currentValue = params.data.HistoryKey;
        //console.log(this.currentValue);
        this.index = params.data.FieldOrder
      //  console.log(this.index);

    }
    updateCheckbox(index: any, currentValue:any,event:any)
    {
        this.paramsGlobal.context.gridCtx.updateRowNode(index, "historyKey");
      //  console.log('Index::' + index + ' CurrentValue:' + currentValue + 'Event:' + event);
    }
    ngOnInit() {
    }

}