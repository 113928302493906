import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SharedService } from '../../shared/services/shared.service';
import { jobs} from '../components/job/job';

@Injectable()
export class JobService {    
    constructor(private http: HttpClient, private _sharedService: SharedService) {
    }

    getDpJob(body: jobs): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/DpJob/GetDpJob`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getAllActiveJobStatus(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/DpJob/GetAllActiveJobStatus`, null, this._sharedService.getReqOptWithAccessToken());
    }
    getAllActiveInternalJobStatus(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/DpJob/GetAllActiveInternalJobStatus`, null, this._sharedService.getReqOptWithAccessToken());
    }
    addJob(body: jobs): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/DpJob/AddJob`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getDpJobByID(body: jobs): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/DpJob/GetDpJobByID`, body, this._sharedService.getReqOptWithAccessToken());
    }
    updateJob(body: jobs): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/DpJob/UpdateJob`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getViewJobDetails(body: any): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/DpJob/GetViewJobDetails`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getAllActiveBatchTypes(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/DpJob/GetAllActiveBatchTypes`, null, this._sharedService.getReqOptWithAccessToken());
    }
    updateJobStatus(body: jobs): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/DpJob/UpdateJobStatus`, body, this._sharedService.getReqOptWithAccessToken());
    }
    getActiveJobsStatus(): Observable<any> {
        return this.http.post(`${this._sharedService.baseUrl}/api/DpJob/GetActiveJobsStatus`, null, this._sharedService.getReqOptWithAccessToken());
    }
}