<!-- MAIN PANEL -->
<div role="main">

    <!-- MAIN CONTENT -->
    <div id="content" style="opacity: 1;">
        <section id="widget-grid-main" class="well" style="height:91vh;">
            <div id="content">
                <!-- row -->
                <div class="row">
                    <div class="col-sm-12">
                        <div class="well padding-10" style="background-color: #fff;margin-top: 20px;">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h2 class="margin-top-0">
                                        <a href="javascript:void(0);">
                                            Recommended FTP Client Tool:
                                        </a>
                                        <br>
                                    </h2>
                                    <div class="row">
                                        <div class="col-md-1">
                                            <img src="/assets/img/help/icon_filezilla.png" class="center-block img-responsive" alt="img">
                                        </div>
                                        <div class="col-md-11 padding-left-0">

                                            <h3 class="margin-top-0">
                                                <a href="javascript:void(0);">FileZilla</a>
                                                <br>
                                            </h3>
                                            <a class="btn btn-primary" target="_blank" href="https://filezilla-project.org/download.php?type=client">Download FileZilla Client</a>
                                        </div>
                                    </div>
                                    <hr>
                                    <h2 class="margin-top-0">
                                        <a href="javascript:void(0);">
                                            FTP Browser Extension:
                                        </a>
                                        <br>
                                    </h2>
                                    <div class="row">

                                        <div class="col-md-1">
                                            <img src="/assets/img/help/icon_firefox.jpg" class="img-responsive" alt="img">
                                        </div>
                                        <div class="col-md-11 padding-left-0">
                                            <h3 class="margin-top-0">
                                                <a href="javascript:void(0);">Fire FTP extension for FireFox Browser </a>
                                                <br>
                                            </h3>
                                            <a class="btn btn-primary" target="_blank" href="https://addons.mozilla.org/en-GB/firefox/addon/fireftp/">FTP Extension </a>
                                        </div>
                                    </div>
                                    <hr />
                                    <h2 class="margin-top-0">
                                        <a href="javascript:void(0);">
                                            FTP Server Connection Details:
                                        </a>
                                        <br>
                                    </h2>
                                    <p>
                                        Contact support for FTP server connection details.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

</div>