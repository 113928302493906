<section id="widget-grid" class="adminaircraftcls" *ngIf="canAddEditViewDataType">
    
    <div class="row">
        <article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable" id="col1">

            <div class="no-margin jarviswidget jarviswidget-sortable" id="wid-id-2" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false" role="widget">

                <header role="heading" class="p-sortable-handle">

                    <span class="widget-icon"><i class="fa fa-list-alt"></i></span>
                    <h2>View Data Type</h2>                    
                    <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>

                    <div class="jarviswidget-ctrls" role="menu" *ngIf="canAddEditViewDataType" title="Edit View Data Type (Ctrl+E)">
                        <a class="button-icon hand-cursor tablIndexFocus" [attr.tabindex]="0" id="btneditViewDataType"  (keyup.enter)="btnEditClick()" (click)="btnEditClick()">
                            <i class="glyphicon glyphicon-pencil"></i>
                        </a>
                    </div>
                    <div class="jarviswidget-ctrls" role="menu" *ngIf="canAddEditViewDataType" title="Add View Data Type (Ctrl+A)">
                        <a class="button-icon hand-cursor tablIndexFocus" [attr.tabindex]="0" id="btnaddViewDataType" (keyup.enter)="btnAddClick()" (click)="btnAddClick()">
                            <i class="glyphicon glyphicon-plus"></i>
                        </a>
                    </div>

                </header>


                <div class="widgetdiv">


                    <div class="jarviswidget-editbox">


                    </div>

                    <div id="tagslisting" class="no-bottom-padding widget-body">

                        <section id="widget-grid2" class="">

                            <div class="jqgridboxadminaircraft">
                                <!--style="height:80vh !important;"-->

                                <ag-grid-angular id="agGridViewDataType" [navigateToNextCell]="navigateToNextCell"  #agGridViewDataType [gridOptions]="gridOptions" [columnDefs]="columnDefs"  [rowData]="gridData" sortable="true" filter=true style="width: 100%;" class="ag-theme-balham viewdatatypegrid" (rowClicked)='updateSelectedRow($event)' (rowDoubleClicked)="containerRowDoubleClicked($event)">
                                    <!--width: 100%;-->
                                </ag-grid-angular>
                            </div>
                        </section>

                    </div>

                </div>
 
            </div>

        </article>

    </div>    
</section>
<form #addeditdfpstatusform="ngForm">
    <p-dialog id="viewdatatypedlg" [(visible)]="display" showEffect="fade" [modal]="true" [style]="{width: '420px'}" [resizable]="false">
        <ng-template pTemplate="header"  (keyup.shift.tab)="btnCancelClick(addeditdfpstatusform)">
            {{dialogTitle}}
        </ng-template>
        <div class="upload-dialog">
            <div class="smart-form">
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                View Data Type<span [hidden]="addeditDFPStatus?.valid || model.Name" class="text-danger">
                                    *
                                </span>
                            </label>

                            <label class="input">
                                <input id="addeditDFPStatus" type="text" name="addeditDFPStatus"
                                       placeholder="View Data Type" required [(ngModel)]="model.Name" #addeditDFPStatus="ngModel" [disabled]="readonlyMode" [ngClass]="{'readonlyinput2': readonlyMode}">

                            </label>
                             <!-- <label class="label" style="color: red;" *ngIf="model.DFPStatusID"> 
                                    *Changing View Data Type label will not reflect in existing data where it is already used.
                            </label> -->

                        </fieldset> 
                    </div>                                    
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <fieldset>
                            <label class="label">
                                Description
                            </label>

                            <label class="input">
                                <input id="addeditDFPStatusDescription" type="text" name="addeditDFPStatusDescription"
                                       placeholder="Description" [(ngModel)]="model.Description" #addeditDFPStatusDescription="ngModel">

                            </label>

                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">
                                Color Code<span [hidden]="(addeditDFPStatusColorCode?.valid && addeditDFPStatusColorCodeEditor?.valid)|| model.CSSClass" class="text-danger">
                                    *
                                </span>
                            </label>
                            <p-colorPicker appendTo="body" id="addeditDFPStatusColorCode" name="addeditDFPStatusColorCode" [(ngModel)]="model.CSSClass" #addeditDFPStatusColorCode="ngModel"></p-colorPicker>
                            <input class="tablIndexFocus color-picker-input" id="addeditDFPStatusColorCodeEditor" [attr.tabindex]="0"  type="text" name="addeditDFPStatusColorCodeEditor" required [(ngModel)]="model.CSSClass" #addeditDFPStatusColorCodeEditor="ngModel" style="width:30%">
                        </fieldset>
                    </div>
                   
                    <div class="form-group col-sm-6">
                        <fieldset>
                            <label class="label">Status</label>
                            <label class="checkbox">
                                <span class="onoffswitch tablIndexFocus onoffswitch-Width" [attr.tabindex]="0">
                                    <input type="checkbox" name="addeditdfpstatuscolorcodestatus"
                                           class="onoffswitch-checkbox" checked="checked" id="addeditdfpstatuscolorcodestatus" [(ngModel)]="model.Status" #addeditdfpstatuscolorcodestatus="ngModel" [disabled]="readonlyMode">
                                    <label class="onoffswitch-label"
                                           for="addeditdfpstatuscolorcodestatus">
                                        <span class="onoffswitch-inner" data-swchon-text="Active" data-swchoff-text="Inactive"></span><span class="onoffswitch-switch onoffswitch-switch-Right"></span>
                                    </label>
                                </span>
                            </label>
                        </fieldset>
                    </div>
                </div>

            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary minzindex" (click)="onSubmit(addeditdfpstatusform)" [label]="lblsubmitButton" [disabled]="!addeditdfpstatusform.form.valid || isSubmitted"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" (click)="btnCancelClick(addeditdfpstatusform)" label="Cancel" [disabled]="isSubmitted"></button>
            </div>
        </ng-template>

    </p-dialog>
</form>