<!-- <a class="button-icon"  title="Container Selection" (click)="onContainerOpenClick();">
        <i class="glyphicon glyphicon-plus"></i>
    </a> -->
    <button type="button" [attr.tabindex]="0" pButton icon="fa fa-list" class="btn btn-primary" style="width:13.382352941176471vw;height: 28px" (click)="onContainerOpenClick()" label="Select Container"></button>


    <p-dialog *ngIf="isDisplayDialog" [(visible)]="isDisplayDialog" appendTo="body" id="containerSelection" showEffect="fade" [modal]="true" [style]="{width: '55.2vw'}" closable="false" (onShow)="adjustDlgHeight($event)">  <!-- {width: '750px' -->
        <ng-template pTemplate="header"  (keyup.shift.tab)="btnCancelClick(containerSelectionForm)">
           Select Container
        </ng-template>


        <div class="upload-dialog">
            <!-- <div class="alert alert-danger fade in" style="display: none">
                <button class="close" data-dismiss="alert">×</button>
                <h4 class="alert-heading"><i class="fa-fw fa fa-times"></i>Error!</h4>
                <p id="errorMsg"></p>
            </div> -->
            <div id="smart-form" class="smart-form">
                <div id="form-fields">
                    <form #containerSelectionForm="ngForm">
                        <div class="row">
                            <div class="form-group col-sm-5 fix-Padding">
                                <fieldset>
                                    <label class="label">Asset</label>
                                    <!-- <p-dropdown appendTo="body" autofocus="true" (onChange)="onChangeAsset($event)"   id="asn" name="asn" [options]="assets" placeholder="Select Asset" (onChange)="onChangeAsset($event)" required [(ngModel)]="containerModel.asn" #asn="ngModel" [filter]="true" #asnuploadfilter [resetFilterOnHide]="true">
                                    </p-dropdown> -->


                                <p-dropdown appendTo="body"  autofocus="true" (onChange)="onChangeAsset($event)"   id="asn" name="asn" [options]="assets" placeholder="Select Asset" (onChange)="onChangeAsset($event)" required [(ngModel)]="containerModel.asn" #asn="ngModel" [filter]="true" #asnuploadfilter [resetFilterOnHide]="true">
                                    <ng-template style="padding-bottom:20px;padding-left: 0px !important;" let-item pTemplate="selectedItem">
                                        <span>{{item.label}}</span>
                                        <!-- <span style="font-size:14px;background-color:lightgreen;">{{item.AssetType}}</span> -->
                                    </ng-template>
                                    <ng-template  style="padding-bottom:20px !important;padding-left: 0px !important;" let-asset pTemplate="item">
                                        <div style="padding-bottom: 20px;">
                                            <!-- <span > -->
                                                <span style="padding:0" class="col-md-8">{{asset.label}}</span>

                                            <!-- </div> -->
                                            <!-- <div class="col-md-4"> -->
                                                <span style="padding:0" class="col-md-4">
                                                    <span  style="font-size: 11px;padding: 4px; background-color:lightgreen;color: white;border-radius: 4px;">
                                                        {{asset.AssetType}}
                                                </span>
                                                </span>

                                        <!-- </div> -->
                                        </div>
                                    </ng-template>
                                </p-dropdown>


                                </fieldset>
                            </div>
                            <div class="form-group col-sm-5 fix-Padding">
                                <fieldset>
                                    <label class="label">Category </label>
                                    <p-dropdown appendTo="body"  autofocus="true"  id="category" name="category" [options]="categories" (onChange)="onChangeCategory($event)" placeholder="Select Category" [(ngModel)]="containerModel.category" #category="ngModel">
                                    </p-dropdown>
                                </fieldset>
                            </div>
                            <div class="form-group col-sm-2">
                                <fieldset class="pull-right" style="margin-top: 20px;">
                                    <label class="label"></label>
                                    <button pButton type="button" (click)="populateContainerGrid()" icon="fa fa-search" label="Search" [disabled]="!containerSelectionForm.form.valid"></button>
                                </fieldset>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 5px;">
                            <div class="form-group col-sm-12">
                                <ag-grid-angular   #containerGrid [gridOptions]="conGridOptions" [columnDefs]="conColumnDefs" [rowData]="conGridData" (componentStateChanged)="formatContainerGrid($event)" enableFiltering sortable="true" style="width: 100% !important; height: 450px !important" class="ag-theme-balham" (rowClicked)="onContainerRowClick($event)">
                                </ag-grid-angular>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
        
        <ng-template pTemplate="footer">
            <div class="p-dialog-buttonpane" style="padding-top: 5px !important">
                <button type="button" pButton icon="fa fa-check" class="btn btn-primary" (click)="onContainerSelected()" label="Select" [disabled]="(!containerSelectionForm.form.valid||!conRowSelected)"></button>
                <button type="button" pButton icon="fa fa-close" class="btn btn-default" (click)="btnCancelClick(containerSelectionForm)" label="Cancel" (focusout)="asnuploadfilter.applyFocus()"></button>
            </div>
        </ng-template>
    </p-dialog>
