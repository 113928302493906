import { Component, Input, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
import { AzureBlobService } from '../../../shared/services/AzureBlob/azure-blob.service';
import { DocumentTypeService } from '../../../admin/services/document-type/document-type.service';
import { BlobMetaData } from '../../../shared/services/AzureBlob/blob-metadata';
import { IConfig } from '../../../shared/helpers/config';
import { NgForm } from '@angular/forms';
import * as moment from 'moment/moment';
import { GridOptions } from "ag-grid-community";
import { container, SearchContainerModel } from '../../../records/services/container/container';
import { AssetService } from '../../../admin/services/asset/asset.service';
import { ContainerService } from '../../../records/services/container/container.service';
import { IDateFormatInfo } from '../../../shared/helpers/dateFormatInfo';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { CustomDateComponent } from '../../../shared/components/custom-date/custom-date.component';
import { QualityStatusService } from '../../../admin/services/qualitystatus/qualitystatus.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
//#region Global Variables for Ag-Grid
var dateFormatF: string;
var dateSeparatorS: string;
var dayD: number;
var monthM: number;
var yearY: number;
var sharedService: SharedService;
var _gridOptions: GridOptions
var _searchQuery: string;
var dateTimeConfig: IDateFormatInfo;
var conColumnDefs_1: any[];
var statusList: any[] = [];
var columnDefs_1: any[];
//#endregion



@Component({
    selector: 'upload-from-computer',
    templateUrl: './upload-from-computer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DocumentTypeService, ConfirmationService, AzureBlobService, AssetService, ContainerService, QualityStatusService]
})



export class UploadFromComputer implements OnInit, OnDestroy {
    @Input() recordType: string;
    isDiaplayDialog: boolean = false;
    isDisplayBulkDialog: boolean = false;
    DocType: SelectItem[];
    selectedDocType: SelectItem;
    dialogTitle: string;
    bulkDialogTitle: string;
    uploadUrl: string;
    uploadedFile: any = [];
    uploadedFiles: any[] = [];
    fileNames: any[] = [];
    metaData: BlobMetaData;
    metaDataBulk: BlobMetaData;
    tags: SelectItem[] = [];
    selectedTags: string[] = [];
    parentWoNumber: string;

    qualityStatusList: any[] = [];
    public maxAllowedFileSize: number = 0;
    public maxAllowedFileSizeMB: number = 0;
    public acceptFileTypes: string = "";

    //dateFormatCalender = "";
    dateFormatCalender = "";
    dateSeparator = "";
    dateFormat = "";
    configuration: IConfig[] = [];
    public dateConfig: IConfig[] = [];
    public bulkUploadArray: any = [];
    private day: number;
    private month: number;
    private year: number
    isSubmitted: boolean = false;
    public canUpload: boolean = false;
    public canBulkUpload: boolean = false;
    public bulkFile: any[] = [];
    public isNonPdfFileExist: boolean = false;
    containerModel: SearchContainerModel;
    selectedContainer: container;
    public gridOptions: GridOptions;
    conGridOptions: GridOptions;
    assets: SelectItem[] = [];
    conTypes: SelectItem[] = [];
    conGridData: any[] = [];
    conTotalCount: number = 0;
    categories: SelectItem[] = [];
    conColumnDefs: any[];
    conRowSelected: boolean = false;
    isuploadBulkDoc: boolean = true;
    FileTypeList: SelectItem[];
    selectedBulkUrl: string;
    VideoUrlList: SelectItem[] = [];
    @ViewChild('bulkUrl') bulkUrlEle: any;
    public referenceKeyLabel: string = "";
    showUploadButton = false;
    @ViewChild('bulkFileUpload') selectedBulkFiles: any;
    @ViewChild('singleFileUpload') selectedSingleFile: any;

    public Container_MXR_Columns: any[] = [];
    public Container_LOG_Columns: any[] = [];
    public Container_GEN_Columns: any[] = [];
    public Container_DOC_Columns: any[] = [];
    public Container_BND_Columns: any[] = [];


    public Container_MXR_Sort_By: string = "";
    public Container_LOG_Sort_By: string = "";
    public Container_GEN_Sort_By: string = "";
    public Container_DOC_Sort_By: string = "";
    public Container_BND_Sort_By: string = "";

    public lbl_DocNo: string;
    public lbl_ReferenceKey: string;
    public lbl_DocType: string;
    public lbl_Status: string;
    public lbl_DocDate: string;

    public canMXRQualityStatus: boolean = false;
    public canLOGQualityStatus: boolean = false;
    public canGENQualityStatus: boolean = false;
    public canDOCQualityStatus: boolean = false;
    public canBNDQualityStatus: boolean = false;

    public processMXRQualityStatusEnabled: boolean = false;
    public processLOGQualityStatusEnabled: boolean = false;
    public processGENQualityStatusEnabled: boolean = false;
    public processDOCQualityStatusEnabled: boolean = false;
    public processBNDQualityStatusEnabled: boolean = false;

    public appHotkeys: Hotkey[] = [];
    public ViewDateFormat: string;
    public showUploadAsImageBulk = false;
    public disableImageLoadOption: boolean = false;

    constructor(private _sharedService: SharedService, private _azureBlobService: AzureBlobService, private _documentTypeService: DocumentTypeService, private _confirmationService: ConfirmationService,
        private _router: Router, private _assetService: AssetService, private _containerService: ContainerService, private _hotkeysService: HotkeysService, private _qualityStatusService: QualityStatusService,
        private cRef: ChangeDetectorRef) {
        dateTimeConfig = this._sharedService.getDateTimeFormatConfiguration();
        this.dateConfig = this._sharedService.getConfiguration();
        //this.uploadUrl = `${this._sharedService.baseUrl}/api/AzureBlob/UploadBlob`;
        this.metaData = new BlobMetaData("", "", "", "", "", null, "", "", 'File', "", null, null, 0, null, null, null, 1);
        this.metaDataBulk = new BlobMetaData("", "", "", "", "", null, "", "", 'File', "", null, null, 0, null, null, null, 1);
        this.populateAsset()
        this.populateFileTypeDropdown();
        //this.setHotKeys();
    }

    ngOnInit() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        this.maxAllowedFileSize = this._sharedService.getMaxDocumentLimitInBytes();
        this.maxAllowedFileSizeMB = this._sharedService.getMaxDocumentLimit();
        this.populateQualityStatusDropdownForAgGrid();
        this.populateQualityStatusDropdown();
        this.referenceKeyLabel = this._sharedService.getReferenceKeyLabel();
        this.UpdateRoles();
        this.prepareContainerGridColumns();
        this.UpdateScreenLables(null);
        this.conGridOptions = <GridOptions>
            {
                rowSelection: 'single',

                onGridReady: () => {
                    this.setContainerGridColumns();
                    //this.conGridOptions.api.sizeColumnsToFit();
                },
                suppressContextMenu: true,
                defaultColDef: {
                    resizable: true,
                },
                components: { agDateInput: CustomDateComponent }
            }
        this.containerModel = new SearchContainerModel("", null, null, false);
        this.setHotKeys();
        this.ViewDateFormat = this._sharedService.getLabelTextDateFormat();
        this.getDateFormatInfo();
    }



    setHotKeys() {

        let hotKeyObj: Hotkey = null;

        hotKeyObj = new Hotkey('ctrl+u', (event: KeyboardEvent): boolean => {
            this.OpenBulkModal();
            this.cRef.markForCheck();
            return false; // Prevent bubbling
        });

        this._hotkeysService.add(hotKeyObj);
        this.appHotkeys.push(hotKeyObj);
    }

    ngOnDestroy() {

        if (this._router.url.indexOf("/status/viewlist") == -1) {
            this.appHotkeys.forEach(hotkey => {
                this._hotkeysService.remove(hotkey);
            });
            this.appHotkeys = [];
        }
    }

    //// #region date Format Settings

    getDateFormatInfo(): any {

        if (this.dateConfig != undefined) {
            dateFormatF = this.dateConfig.filter(x => x.Key == 'DateFormat').map(({ Value }) => Value).toString();
            dateSeparatorS = this.dateConfig.filter(x => x.Key == 'DateSeparater').map(({ Value }) => Value).toString();
            dayD = Number(this.dateConfig.filter(x => x.Key == 'DayIndex').map(({ Value }) => Value));
            monthM = Number(this.dateConfig.filter(x => x.Key == 'MonthIndex').map(({ Value }) => Value));
            yearY = Number(this.dateConfig.filter(x => x.Key == 'YearIndex').map(({ Value }) => Value));
        } else {
            dateFormatF = localStorage.getItem('DateFormat');
            dateSeparatorS = localStorage.getItem('DateSeparater');
            dayD = Number(localStorage.getItem('DayIndex'));
            monthM = Number(localStorage.getItem('MonthIndex'));
            yearY = Number(localStorage.getItem('YearIndex'));

        }
    }
    //// #endregion

    //#region Bulk Upload Code

    OpenBulkModal() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_MXR_LOG_GEN_CATEGORY;
        this.disableImageLoadOption = true;
        if (this.recordType == "DOC" || this.recordType == "BND") {
            this.disableImageLoadOption = false;
            this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_DOC_CATEGORY + "," + this._sharedService.allowedAzureIndexFileContentTypes;
        }
        this.changeButtonsStatus(false);
        this.uploadUrl = `${this._sharedService.baseUrl}/api/AzureBlob/UploadAndAttach`;
        this.setCalenderDateFormat();
        this.populateDocTypeDropdown();
        this.isDisplayBulkDialog = true;
        this.uploadedFiles = [];
        this.resetPopup();
        this.showUploadAsImageBulk = false;
        //if (this._sharedService.selectedAsset[0] && this._sharedService.selectedAsset[0].data) {
        //    let asset = this._sharedService.selectedAsset[0].data.split('|');
        //    this.containerModel.asn = asset[0] + '|' + asset[1];
        //}

        if (this._sharedService.selectedAssetStatus[0] && this._sharedService.selectedAssetStatus[0].data) {
            //let asset = this._sharedService.selectedAssetStatus[0].data.split('|');

            let assetlabel = this._sharedService.selectedAssetStatus[0].label
            let assetValue = this.assets.find(
                asset => asset.label === assetlabel).value;

            this.containerModel.asn = assetValue;
            //this.containerModel.asn = asset[0] + '|' + asset[1];

            //US-8181 //let assetid = this._sharedService.selectedAssetStatus[0].data.split('|')[3].trim();
            let assetDataObj = JSON.parse(this._sharedService.selectedAssetStatus[0].data);
            let assetid = assetDataObj.AssetID;//this._sharedService.selectedAssetStatus[0].data.split('|')[3].trim();
            this.populateCategoryByAssetID(assetid);

        }
        this.metaDataBulk.isImageSwitch = 1;
        this.metaDataBulk.Status = this.qualityStatusList[0].value;

    }
    
    populateQualityStatusDropdown() {
        this._qualityStatusService.getActiveQualityStatusDataForDropDown(null)
        .subscribe({
            next: (response) => {
                this.qualityStatusList = response;
            }
        });
    }

    btnBulkCancelClick(form: NgForm) {
        this.isNonPdfFileExist = false;
        this.isDisplayBulkDialog = false;
        form.resetForm();
        this.metaDataBulk = new BlobMetaData("", "", "", "", "", null, "", "", 'File', "", null, null, 0, 0);
        this.resetPopup();
        this.changeButtonsStatus(false);
    }

    onBeforeUpload(event: any) {

        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }
    }

    onRemove(event: any) {

        for (let i = 0; i < this.uploadedFiles.length; i++) {
            if (this.uploadedFiles[i].name == event.file.name) {
                this.uploadedFiles.splice(i, 1);
                break;
            }
        }

        if (this.uploadedFiles.find(f => f.type.indexOf('image/') != -1)) {
            this.showUploadAsImageBulk = true;
        } else {
            this.showUploadAsImageBulk = false;
        }

    }

    onClear(event: any) {
        //console.log("In Clear All Event");
    }

    onSelect(event: any) {
        let invalidFiles: string[] = [];
        for (let file of event.files) {

            //if (this._sharedService.allowedFileTypes.includes(file.type)) {
            if (((this.recordType != 'DOC' && this.recordType != 'BND') && (this._sharedService.allowedImageFileContentTypes.includes(file.type) || file.type == 'application/pdf')) || (this.recordType == 'DOC' || this.recordType == 'BND')) {

                if (file.size <= this._sharedService.MAX_ALLOWED_FILE_SIZE_FOR_DOC) {
                    //if (file.size <= this.maxAllowedFileSize || (file.type != "application/pdf")) {
                    if (file.size <= this.maxAllowedFileSize || !this._sharedService.allowedAzureIndexFileContentTypes.includes(file.type)) {


                        let fileCurrent: any = this.uploadedFiles.filter(x => x.name == file.name);
                        if (fileCurrent.length > 0) {
                            for (let i = 0; i < this.selectedBulkFiles.files.length; i++) {
                                if (this.selectedBulkFiles.files[i].name == file.name) {
                                    this.selectedBulkFiles.files.splice(i, 1);
                                    break;
                                }
                            }
                        }
                        else {
                            this.uploadedFiles.push(file);
                        }

                        if (file.type.indexOf('image/') != -1) {
                            this.showUploadAsImageBulk = true;
                        }
                    }
                    else {
                        invalidFiles.push(file.name);
                    }
                }
                else {
                    invalidFiles.push(file.name);
                }
            }
        }

        if (invalidFiles.length > 0) {
            for (let j = 0; j < invalidFiles.length; j++) {
                let index: any = this.selectedBulkFiles.files.findIndex((x: any) => x.name == invalidFiles[j]);
                this.selectedBulkFiles.files.splice(index, 1);
            }

            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File(s)', detail: 'File(s) size exceeds the maximum limit of ' + this.maxAllowedFileSizeMB + ' MB for PDF or 1 GB for other types' });
        }

    }

    validateBulkUpdate() {
        if (this.uploadedFiles.length > 0 || this.VideoUrlList.length > 0) {
            return true;
        }
        return false;
    }

    onBulkUpload(event: any) {
        //US-8181 //if (this.containerModel.asn.split('|')[4] == 'True') {
        let assetDataObj = JSON.parse(this.containerModel.asn);
        if (assetDataObj.ReadOnly) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Readonly Asset', detail: 'Cannot Move/Copy documnet(s) to readonly asset.' });
            return;
        }


        if (!this.validateBulkUpdate()) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Invalid File/Link', detail: 'No File/Video Link found.' });
            return;
        }

        this.changeButtonsStatus(true);
        this.bulkFile;
        let xhr = new XMLHttpRequest(), formData = new FormData();
        if (this.isuploadBulkDoc) {
            for (let i = 0; i < this.uploadedFiles.length; i++) {
                formData.append("uploads['" + i + "']", this.uploadedFiles[i], this.uploadedFiles[i].name);
            }
        } else {
            let urlList = "";
            for (let i = 0; i < this.VideoUrlList.length; i++) {
                urlList = urlList + (i == 0 ? "" : ";") + this.VideoUrlList[i].value;
            }

            formData.append('VideoUrl', urlList);
            formData.append('Url', urlList);
        }
        //Append File and Form Data in xhr Request
        //formData.append("uploads[]", this.uploadedFile[0], this.uploadedFile[0].name);
        //US-8181 // use above assetDataObj //let asset = this.containerModel.asn.split('|');
        // formData.append('ContainerName', asset[0])
        // formData.append('ASN', asset[1]);

        formData.append('ContainerName', assetDataObj.StoragePath)
        formData.append('ASN', assetDataObj.ASN);
        formData.append('Type', this.selectedContainer.Type);
        formData.append('ID', this.selectedContainer.ID);
        formData.append('TaskID', this._sharedService.selectedTaskID);
        formData.append('RunDuplicationCheck', 'true');
        //this.metaDataBulk.Status = this.qualityStatusList[0].value;
        //formData.append('Status', this.metaDataBulk.Status);

        formData.append('IsInternalView', this._sharedService.isInternalView.toString());
        formData.append('CommSummary', '');

        Object.keys(this._sharedService.selectedTaskEntity)
            .forEach(key => {
                if (!formData.has(key)) {
                    if (this._sharedService.selectedTaskEntity[key] != null) {
                        formData.append(key, this._sharedService.selectedTaskEntity[key]);
                    }
                }
            });

        this.metaDataBulk.Tags = '';
        if (this.metaDataBulk.docNumberSwitch == 0) {
            this.metaDataBulk.DocNo = "";
        }
        else if (this.metaDataBulk.docNumberSwitch == 1 && (this.metaDataBulk.DocNo == null || this.metaDataBulk.DocNo.trim() == "")) {
            this.metaDataBulk.DocNo = "{-1}";
        }
        if (!this.metaDataBulk.isImageSwitch || this.metaDataBulk.isImageSwitch == 0) {
            this.metaDataBulk.IsImage = "true";
        }
        else if (this.metaDataBulk.isImageSwitch == 1) {
            this.metaDataBulk.IsImage = "false";
        }
        this.metaDataBulk.OCR = this.getOCRStatus(this.metaDataBulk.OCR);
        let postData = this.metaDataBulk;

        if (postData) {
            for (let prop in postData) {
                if (prop != "DocDate") {
                    formData.append(prop, postData[prop]);
                }
                else {
                    //if (typeof (postData.DocDate) == "string") {
                    //    formData.append(prop, postData[prop].toString());
                    //}
                    //else {
                    //    let date = moment(postData.DocDate).format(this.dateFormat.toUpperCase()).toString();
                    //    formData.append(prop, date);
                    //}
                    if (typeof (postData.DocDate) == "string") {
                        let dateString = postData[prop].toString().substr(0, 10);
                        let date = moment(dateString).format("YYYY-MM-DD").toString();
                        formData.append(prop, date);
                    }
                    else {
                        let date = moment(postData.DocDate).format("YYYY-MM-DD").toString();
                        formData.append(prop, date);
                    }
                }
            }
        }
        //Clear DocNo
        if (this.metaDataBulk.docNumberSwitch == 1 && this.metaDataBulk.DocNo.trim() == "{-1}") {
            this.metaDataBulk.DocNo = "";
        }

        xhr.open('POST', this.uploadUrl, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        xhr.send(formData);
        this._sharedService.showLoader("Processing...");
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                this._sharedService.hideLoader();
                if (xhr.status === 200) {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Document uploaded', detail: 'Document has been uploaded successfully.' });
                    this._sharedService.resfreshTaskDocuments(true);
                    this.changeButtonsStatus(false);
                    this.isDisplayBulkDialog = false;
                    this.uploadedFiles = [];
                    this.resetPopup();
                    this.cRef.markForCheck();
                }
                else if (xhr.status === 409) {
                    //this.isDisplayBulkDialog = false;
                    this.changeButtonsStatus(false);
                    this.cRef.markForCheck();
                    var array = xhr.responseText.split(',');
                    var count = 1;
                    var tab = "<h3>Over Write?</h3>"
                    tab = tab + "<div class='confirmOverflow' ><table class='table'><thead><tr> <th scope='col'>#</th><th scope='col'>File Name</th></tr></thead><tbody>";
                    array.forEach(element => {
                        tab = tab + "<tr><th scope='row'>" + count + "</th><td>" + element.replace('"', '') + "</td></tr>";
                        count = count + 1;
                    });
                    tab = tab + "</tbody> </table></div>"
                    this._confirmationService.confirm({
                        // message: JSON.parse(xhr.responseText),
                        message: tab,
                        accept: () => {
                            var fileNames: string = JSON.parse(xhr.responseText);
                            this.bulkUploadOverride(fileNames);
                        },
                        reject: () => {
                            this.uploadedFiles = [];
                            this.resetPopup();
                        }
                    });
                    //console.log(xhr.response);
                }
                else if (xhr.status == 501) {

                    this._sharedService.showToast({ severity: 'warn', summary: 'Unable to update document URL', detail: xhr.responseText });
                    this._sharedService.resfreshTaskDocuments(true);
                    this.changeButtonsStatus(false);
                    this.isDisplayBulkDialog = false;
                    this.uploadedFiles = [];
                    this.resetPopup();
                    this.cRef.markForCheck();
                }
                else {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while uploading document.' });
                    this.uploadedFiles = [];
                    this.isDisplayBulkDialog = false;
                    this.changeButtonsStatus(false);
                }
            }
        };

    }

    onChangeDocTypeBulk(event: any) {
        if (event.value == 'File') {
            this.isuploadBulkDoc = true;
        } else {
            this.isuploadBulkDoc = false;
        }
    }

    parseYouTube(str) {
        // link : //youtube.com/watch?v=Bo_deCOd1HU
        // share : //youtu.be/Bo_deCOd1HU
        // embed : //youtube.com/embed/Bo_deCOd1HU

        let re = /\/\/(?:www\.)?youtu(?:\.be|be\.com)\/(?:watch\?v=|embed\/)?([a-z0-9_\-]+)/i;
        let matches = re.exec(str);
        return matches && matches[1];
    }

    parseVimeo(str) {
        // embed & link: http://vimeo.com/86164897

        let re = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
        let matches = re.exec(str);
        return matches && matches[1];
    }

    addbulkUrl() {
        let value = this.bulkUrlEle.nativeElement.value;
        if (value && (this.parseYouTube(value) || this.parseVimeo(value))) {
            this.VideoUrlList= [...this.VideoUrlList,{ label: value, value: value }];
            this.bulkUrlEle.nativeElement.value = "";
        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Link', detail: 'Empty/Invalid Video Link found.' });
        }
    }

    removeVideoUrl() {
        if (this.selectedBulkUrl) {
            this.VideoUrlList = this.VideoUrlList.filter(item => item.label !== this.selectedBulkUrl)
        }
    }

    bulkUploadOverride(fileNames: string) {

        let files: string[];
        files = fileNames.split(',');

        let xhr = new XMLHttpRequest(), formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            let file: any = this.uploadedFiles.filter(x => x.name == files[i].replace('"', ''));
            formData.append("uploads['" + i + "']", file[0], file[0].name);

        }
        //formData.append("uploads['" + i + "']", this.uploadedFiles[i], this.uploadedFiles[i].name);
        //Append File and Form Data in xhr Request
        //US-8181 //let asset = this.containerModel.asn.split('|');
        let assetDataObj = JSON.parse(this.containerModel.asn);

        formData.append('ContainerName', assetDataObj.StoragePath);// asset[0])
        formData.append('ASN', assetDataObj.ASN);//asset[1]);
        formData.append('Type', this.selectedContainer.Type);
        formData.append('ID', this.selectedContainer.ID);
        formData.append('TaskID', this._sharedService.selectedTaskID);
        formData.append('RunDuplicationCheck', 'false');

        formData.append('IsInternalView', this._sharedService.isInternalView.toString());
        formData.append('CommSummary', '');

        Object.keys(this._sharedService.selectedTaskEntity)
            .forEach(key => {
                if (!formData.has(key)) {
                    if (this._sharedService.selectedTaskEntity[key] != null) {
                        formData.append(key, this._sharedService.selectedTaskEntity[key]);
                    }
                }
            });

        this.metaDataBulk.Tags = this.selectedTags.join(';');
        if (this.metaDataBulk.docNumberSwitch == 0) {
            this.metaDataBulk.DocNo = "";
        }
        else if (this.metaDataBulk.docNumberSwitch == 1 && this.metaDataBulk.DocNo.trim() == "") {
            this.metaDataBulk.DocNo = "{-1}";
        }
        this.metaDataBulk.OCR = this.getOCRStatus(this.metaDataBulk.OCR);
        let postData = this.metaDataBulk;
        if (postData) {
            for (let prop in postData) {
                if (prop != "DocDate") {
                    formData.append(prop, postData[prop]);
                }
                else {
                    //if (typeof (postData.DocDate) == "string") {
                    //    formData.append(prop, postData[prop].toString());
                    //}
                    //else {
                    //    let date = moment(postData.DocDate).format(this.dateFormat.toUpperCase()).toString();
                    //    formData.append(prop, date);
                    //}
                    if (typeof (postData.DocDate) == "string") {
                        let dateString = postData[prop].toString().substr(0, 10);
                        let date = moment(dateString).format("YYYY-MM-DD").toString();
                        formData.append(prop, date);
                    }
                    else {
                        let date = moment(postData.DocDate).format("YYYY-MM-DD").toString();
                        formData.append(prop, date);
                    }
                }
            }
        }
        if (this.metaDataBulk.docNumberSwitch == 1 && this.metaDataBulk.DocNo.trim() == "{-1}") {
            this.metaDataBulk.DocNo = "";
        }
        xhr.open('POST', this.uploadUrl, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        xhr.send(formData);
        this._sharedService.showLoader("Processing...");
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                this._sharedService.hideLoader();
                if (xhr.status === 200) {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Document uploaded', detail: 'Document has been uploaded successfully.' });
                    this.isDisplayBulkDialog = false;
                    this.uploadedFiles = [];
                    this.resetPopup();
                    this.cRef.markForCheck();
                    //console.log(xhr.response);
                }
                else if (xhr.status == 501) {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'warn', summary: 'Unable to update document URL', detail: xhr.responseText });
                    this.isDisplayBulkDialog = false;
                    this.uploadedFiles = [];
                    this.resetPopup();
                    this.cRef.markForCheck();
                }
                else {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while uploading document.' });
                    this.uploadedFiles = [];
                    this.isDisplayBulkDialog = false;
                }
            }
        };

    }

    resetPopup() {
        if (this.selectedBulkFiles && this.selectedBulkFiles.files) {
            this.selectedBulkFiles.files = [];
        }
        this.metaDataBulk.FileType = 'File';
        this.VideoUrlList = [];
        this.isuploadBulkDoc = true;

        this.containerModel = new SearchContainerModel("", null, null, false);
        this.conGridData = [];
        this.metaDataBulk = new BlobMetaData("", "", "", null, "", null, "", "", 'File', "", null, null, 0, 0);
    }
    //#endregion

    populateDocTypeDropdown() {
        this._documentTypeService.getDocumentTypes('')
        .subscribe({
            next: (response) => {
                //this.DocType = [];
                this.DocType = response;
            }
        });
    }

    setCalenderDateFormat() {
        this.configuration = this._sharedService.getConfiguration();
        this.dateFormat = this.configuration.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
        this.dateSeparator = this.configuration.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
        this.day = Number(this.configuration.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
        this.month = Number(this.configuration.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
        this.year = Number(this.configuration.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
        let dateParts: string[] = this.dateFormat.split(this.dateSeparator);

        if (this.year == 0) {
            this.dateFormatCalender = (dateParts[this.year].substr(0, 2) + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.day]).toLowerCase();
        }
        else if (this.year == 2) {
            if (this.month == 1) {
                this.dateFormatCalender = (dateParts[this.day] + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
            }
            else if (this.month == 0) {
                this.dateFormatCalender = (dateParts[this.month] + this.dateSeparator + dateParts[this.day] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
            }

        }
        else {
            this.dateFormatCalender = "yy-mm-dd"
        }

    }

    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }

        this.canMXRQualityStatus = false;
        this.processMXRQualityStatusEnabled = false;
        this.canLOGQualityStatus = false;
        this.processLOGQualityStatusEnabled = false;
        this.canGENQualityStatus = false;
        this.processGENQualityStatusEnabled = false;
        this.canDOCQualityStatus = false;
        this.processDOCQualityStatusEnabled = false;
        this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_MXR_LOG_GEN_CATEGORY;
        switch (this.recordType) {
            case 'MXR':
                this.canUpload = this._sharedService.UserRole.Records_MXR_Doc_Single_Upload;
                this.canBulkUpload = this._sharedService.UserRole.Records_MXR_Doc_Bulk_Upload;
                this.canMXRQualityStatus = this._sharedService.UserRole.Records_MXR_Doc_Quality_Status;
                this.processMXRQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('MX_Records_Quality_Process');
                this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_MXR_LOG_GEN_CATEGORY;
                break;
            case 'LOG':
                this.canUpload = this._sharedService.UserRole.Records_LOG_Doc_Single_Upload;
                this.canBulkUpload = this._sharedService.UserRole.Records_LOG_Doc_Bulk_Upload;
                this.canLOGQualityStatus = this._sharedService.UserRole.Records_LOG_Doc_Quality_Status;
                this.processLOGQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('Log_Books_Quality_Process');
                this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_MXR_LOG_GEN_CATEGORY;
                break;
            case 'GEN':
                this.canUpload = this._sharedService.UserRole.Records_GEN_Doc_Single_Upload;
                this.canBulkUpload = this._sharedService.UserRole.Records_GEN_Doc_Bulk_Upload;
                this.canGENQualityStatus = this._sharedService.UserRole.Records_GEN_Doc_Quality_Status;
                this.processGENQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('General_Quality_Process');
                this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_MXR_LOG_GEN_CATEGORY;
                break;
            case 'DOC':
                this.canUpload = this._sharedService.UserRole.Records_DOC_Doc_Single_Upload;
                this.canBulkUpload = this._sharedService.UserRole.Records_DOC_Doc_Bulk_Upload;
                this.canDOCQualityStatus = this._sharedService.UserRole.Records_DOC_Doc_Quality_Status;
                this.processDOCQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('Documents_Quality_Process');

                this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_DOC_CATEGORY + "," + this._sharedService.allowedAzureIndexFileContentTypes;
                break;

            case 'BND':
                this.canUpload = this._sharedService.UserRole.Transition_BND_Doc_Single_Upload;
                this.canBulkUpload = this._sharedService.UserRole.Transition_BND_Doc_Bulk_Upload;
                this.canBNDQualityStatus = this._sharedService.UserRole.Transition_BND_Doc_Quality_Status;
                this.processBNDQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('Binders_Quality_Process');

                this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_DOC_CATEGORY + "," + this._sharedService.allowedAzureIndexFileContentTypes;
                break;
        }
    }

    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }

    getOCRStatus(value: any): number {
        if (value) {
            return 1;
        }
        else {
            return 0;
        }
    }

    //#region Container

    populateAsset() {
        let userRole: string = localStorage.getItem('roles').trim();
        let body = { UserRole: userRole };

        this._assetService
            .getAssetWithAssetIdByUserRole()
            .subscribe({
                next: (response) => {
                    this.assets = response;
                },
                error: (error) => {
                    this.assets = [];
                    console.log(error);
                }
            });
    }

    populateFileTypeDropdown() {

        this.FileTypeList = [{ label: "File Input", value: "File" }, { label: "Video URL", value: "VideoURL" }];
    }

    populateContainerType(category: string) {
        this.conTypes = [];
        let body: container = {
            Category: category
        }
        this._containerService.getContainerType(body)
        .subscribe({
            next: (response) => {
                let res = response;
                for (let i = 0; i < res.length; i++) {
                    this.conTypes.push({ value: res[i].Type, label: res[i].Type })
                }
            },
            error: (error) => {
                this.conGridData = [];
                this.conTotalCount = 0;
            }
        });
    }


    onChangeCategory(event: any) {
        this.showUploadButton = false;
        this.conGridData = [];
        this.selectedComboCatergorySelection = event.value;
        this._sharedService.recordType = event.value;
        this.recordType = event.value;
        this.setContainerGridColumns();
        this.UpdateScreenLables(event.value);
        this.populateContainerType(event.value);
        this.UpdateRoles();
        this.metaDataBulk.Status = this.qualityStatusList[0].value;
        this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_MXR_LOG_GEN_CATEGORY;
        this.disableImageLoadOption = true;
        this.metaDataBulk.isImageSwitch = 1;
        if (this.recordType == "DOC" || this.recordType == "BND") {
            this.disableImageLoadOption = false;
            this.acceptFileTypes = this._sharedService.ACCEPT_FILE_TYPES_DOC_CATEGORY + "," + this._sharedService.allowedAzureIndexFileContentTypes;
        }
    }

    onChangeAsset(event: any) {
        this.showUploadButton = false;
        this.conGridData = [];
        //let assetid = event.value.split('|').pop().trim();
        //US-8181 //let assetid = event.value.split('|')[3].trim();

        let assetDataObj = JSON.parse(event.value);
        this.populateCategoryByAssetID(assetDataObj.AssetID);
    }

    populateCategoryByAssetID(assetID: string): void {
        this.categories = [];

        this._assetService.getAssetbyID(assetID)
        .subscribe({
            next: (response) => {
                this.categories = [];
        
                if (response.MXRecords) {
                    this.categories.push({ value: 'MXR', label: 'MX Records' });
                }
                if (response.LogBooks) {
                    this.categories.push({ value: 'LOG', label: 'Log Books' });
                }
                if (response.General) {
                    this.categories.push({ value: 'GEN', label: 'General' });
                }
                if (response.Documents) {
                    this.categories.push({ value: 'DOC', label: 'Documents' });
                }
                if (response.Binders) {
                    this.categories.push({ value: 'BND', label: 'Binders' });
                }
            },
            error: (error) => {
                this.categories = [];
                console.log(error);
            }
        });
    }

    UpdateScreenLables(recordType: any) {
        if (recordType == null) {
            recordType = ((this._sharedService.recordType == undefined) ||
                (this._sharedService.recordType == null)) ? "" : this._sharedService.recordType.toUpperCase();
        }

        this.lbl_DocNo = this._sharedService.getDocumentLabel(recordType, "DocNo");
        this.lbl_ReferenceKey = this._sharedService.getDocumentLabel(recordType, "ReferenceKey");
        this.lbl_DocType = this._sharedService.getDocumentLabel(recordType, "DocType");
        this.lbl_Status = this._sharedService.getDocumentLabel(recordType, "Status");
        this.lbl_DocDate = this._sharedService.getDocumentLabel(recordType, "DocDate");

    }

    populateContainerGrid(): void {

        this._containerService.getFilteredContainer(this.containerModel)
        .subscribe({
            next: (response) => {
                this.conGridData = response;
                this.conTotalCount = this.conGridData.length;
                //this.gr.api.sizeColumnsToFit();
                this.cRef.markForCheck();
            },
            error: (error) => {
                this.conGridData = [];
                this.conTotalCount = 0;
                console.log(error);
            }
        });
    }


    prepareContainerGridColumns(): void {

        var appConfigs = this._sharedService.getConfiguration();
        if (appConfigs != null) {
            this.Container_MXR_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_MX_Records_Column_Setting')[0].Value);
            this.Container_LOG_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Log_Books_Column_Setting')[0].Value);
            this.Container_GEN_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_General_Column_Setting')[0].Value);
            this.Container_DOC_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Documents_Column_Setting')[0].Value);
            this.Container_BND_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Binders_Column_Setting')[0].Value);
            this.Container_MXR_Sort_By = appConfigs.filter(x => x.Key == 'Container_MXR_Sort_By')[0].Value;
            this.Container_LOG_Sort_By = appConfigs.filter(x => x.Key == 'Container_LOG_Sort_By')[0].Value;
            this.Container_GEN_Sort_By = appConfigs.filter(x => x.Key == 'Container_GEN_Sort_By')[0].Value;
            this.Container_DOC_Sort_By = appConfigs.filter(x => x.Key == 'Container_DOC_Sort_By')[0].Value;
            this.Container_BND_Sort_By = appConfigs.filter(x => x.Key == 'Container_BND_Sort_By')[0].Value;
        }
    }

    populateQualityStatusDropdownForAgGrid() {
        let qualityStatusListValues = this._sharedService.getContainerQualityStatusList();
        if (qualityStatusListValues == null || qualityStatusListValues.length == 0) {
            this._qualityStatusService.getActiveQualityStatusData(null)
            .subscribe({
                next: (response) => {
                    try {
                        statusList = response;
                    } catch (ex) {
                        statusList = response;
                        console.log(ex);
                    }
                }
            });
        } else {
            statusList = qualityStatusListValues;
        }
    }

    public selectedComboCatergorySelection: string = "";
    setContainerGridColumns(): void {
        if (!this.selectedComboCatergorySelection) {
            return;
        }
        this.conColumnDefs = [];
        columnDefs_1 = [];
        var containerGridCols: any[];
        var container_Col_Sort_On_By: string = '';
        if (this.selectedComboCatergorySelection.toUpperCase() == 'MXR') {
            containerGridCols = this.Container_MXR_Columns;
            container_Col_Sort_On_By = this.Container_MXR_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'LOG') {
            containerGridCols = this.Container_LOG_Columns;
            container_Col_Sort_On_By = this.Container_LOG_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'GEN') {
            containerGridCols = this.Container_GEN_Columns;
            container_Col_Sort_On_By = this.Container_GEN_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'DOC') {
            containerGridCols = this.Container_DOC_Columns;
            container_Col_Sort_On_By = this.Container_DOC_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'BND') {
            containerGridCols = this.Container_BND_Columns;
            container_Col_Sort_On_By = this.Container_BND_Sort_By;
        }
        containerGridCols.forEach((col, index) => {
            let _filter = 'set';
            if (col.FieldName == 'Date' || col.FieldName == 'EndDate' || col.FieldName == 'ScannedOn') {
                _filter = 'agDateColumnFilter';
            }
            let gridCol: any;
            if (col.FieldName == 'ContainerQualityStatus') {
                gridCol = {
                    headerName: col.ColumnLabel,
                    field: 'QualityStatus',//'QualityStatus---ID',//24-02-2021
                    width: col.Width,
                    filter: _filter,
                    tooltipField: col.FieldName,
                    menuTabs: ['filterMenuTab'],
                    hide: !col.IsVisible,
                    filterParams: this.gridStatusFilterParams,
                };
            }
            else {
                gridCol = {
                    headerName: col.ColumnLabel,
                    field: col.FieldName,
                    width: col.Width,
                    filter: _filter,
                    tooltipField: col.FieldName,
                    menuTabs: ['filterMenuTab'],
                    hide: !col.IsVisible
                };
            }
            if (col.FieldName == 'Date' || col.FieldName == 'EndDate' || col.FieldName == 'ScannedOn') {
                gridCol.filterParams =
                {
                    comparator: this.dateComparater,
                    inRangeInclusive: 'true'
                }
                gridCol.valueFormatter = function (params: any) {
                    if (params.value != '' && params.value != null) {
                        let dateString = params.value.substring(0, 10);
                        return moment(dateString).format(dateFormatF.toUpperCase());
                    }
                }
            }
            else if (col.FieldName == 'ContainerQualityStatus') {
                gridCol.filterParams = {
                    cellRenderer: this.statusCellRenderer, // formats filter values
                }
                gridCol.cellRenderer = this.statusCellRenderer;
            }
            columnDefs_1.push(gridCol);
        });
        this.conColumnDefs = columnDefs_1;
        let sortOnCol = container_Col_Sort_On_By.split("||")[0];
        let orderBy = container_Col_Sort_On_By.split("||")[1];
        if (sortOnCol == "ContainerQualityStatus") {
            sortOnCol = "QualityStatus";
        }
        this.conColumnDefs.forEach(function (col, index) {
            if (col.field == sortOnCol) {
                this.conColumnDefs[index].sort = orderBy;
            }
        }.bind(this));
    }


    gridStatusFilterParams = { cellRenderer: this.statusCellRenderer };

    statusCellRenderer(params: any) {
        let html: string = '';
        var eDiv = document.createElement('div');
        if (params.value) {
            try {
                let currentStatus: any = statusList.filter(x => x.QualityStatusID == params.value)[0];
                if (currentStatus && currentStatus.Name) {
                    eDiv.style.background = currentStatus.CSSClass;
                    html = html + currentStatus.Name;
                } else {
                    let currentStatus: any = statusList.filter(x => x.Name == params.value)[0];//params.value)[0];
                    if (currentStatus && currentStatus.Name) {
                        eDiv.style.background = currentStatus.CSSClass;
                        html = html + currentStatus.Name;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            return params.value;
        }
        eDiv.style.height = '100%';
        eDiv.style.width = '100%';
        eDiv.style.color = 'white';
        eDiv.style.textAlign = 'left';
        eDiv.style.paddingLeft = '5px';
        eDiv.innerHTML = html;
        return eDiv;
    }

    formatContainerGrid(event: any) {
        this.conGridOptions.api.sizeColumnsToFit();
    }

    onContainerRowClick(event: any) {
        this.conRowSelected = true;
        this.selectedContainer = event.data;
        this.showUploadButton = true;
        this.parentWoNumber = '';
        this.parentWoNumber = this.selectedContainer ? this.selectedContainer.ReferenceKey : '';
        if (this.selectedContainer.Date && this.selectedContainer.Date.toString().length > 10) {
            let dateString = this.selectedContainer.Date.toString().substr(0, 10);
            let dateParts = moment(dateString).format(this.dateFormat.toUpperCase()).split(this.dateSeparator);
            let day = Number(dateParts[Number(this.day)].substring(0, 2));
            let month = Number(dateParts[Number(this.month)]) - 1;
            let year = Number(dateParts[Number(this.year)]);
            let cellDate = new Date(year, month, day);

            if (this.parentWoNumber) {
                this.metaDataBulk = new BlobMetaData("", "", "", "", "", null, this.selectedContainer.ReferenceKey, "", 'File', "", null, cellDate, 0, 0);
            } else {
                if (this.parentWoNumber == null) {
                    this.parentWoNumber = '';
                }
                this.metaDataBulk = new BlobMetaData("", "", "", null, "", null, "", "", 'File', "", null, cellDate, 0, 0);
            }
        }
        else {
            if (this.parentWoNumber) {
                this.metaDataBulk = new BlobMetaData("", "", "", "", "", null, this.selectedContainer.ReferenceKey, "", 'File', "", null, this.selectedContainer.Date, 0, 0);
            } else {
                if (this.parentWoNumber == null) {
                    this.parentWoNumber = '';
                }
                this.metaDataBulk = new BlobMetaData("", "", "", null, "", null, "", "", 'File', "", null, this.selectedContainer.Date, 0, 0);
            }
        }
        this.metaDataBulk.isImageSwitch = 1;
        this.metaDataBulk.Status = this.qualityStatusList[0].value;
    }

    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateTimeConfig.dateformat.toUpperCase()).split(dateTimeConfig.dateSeparator);
            var day = Number(dateParts[Number(dateTimeConfig.day)].substring(0, 2));
            var month = Number(dateParts[Number(dateTimeConfig.month)]) - 1;
            var year = Number(dateParts[Number(dateTimeConfig.year)]);

            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        }
        else {
            return -1;
        }
    }

    dateValueFormatter(params: any) {
        if (params.value != "" && params.value != null) {

            let dateString = params.value.substring(0, 10);
            return moment(dateString).format(dateTimeConfig.dateformat.toUpperCase());
        }
    }

    //#endregion
}
