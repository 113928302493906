export class ContainerQualityStatusModel {
    constructor(
        public QualityStatusID?: string,
        public Name?: string,
        public Description?: string,
        public CSSClass?: string,
        public Status?: boolean,
        public CreatedBy?: string,
        public CreatedOn?: Date,
        public ModifiedBy?: string,
        public ModifiedOn?: Date,
        public ResetChangeID?: string, // Container quality Status id
        public ResetChangeName?: string,
        public ResetChangeCSSClass?: string


    ) {
    }
}

export enum OperationMode {
    Add = 1,
    Edit = 2,
    Delete = 3
}