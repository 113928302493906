//#region Plugin_settings
export class PluginSettingsWidget {
    public id: string;
    public title: string;
    public hidden: number;
    public collapsed: number;
    public style: string;
}

export class WidgetPluginSettings {
    public widget: PluginSettingsWidget[]
}
//#endregion


//#region Plugin_position
export class Plugin_positionSection {
    public id: string
}

export class Plugin_positionGrid {
    public constructor() {
        this.section = new Array<Plugin_positionSection>();
    }
    public section: Plugin_positionSection[];
}

export class Plugin_position {
    public constructor() {
        this.grid = new Array<Plugin_positionGrid>();
    }
    public grid: Plugin_positionGrid[];
}


export class WidgetPluginPositionsGridSection {
    public constructor() {
        this.noOfColumns = "0";
    }
    public noOfColumns: string;
}





export class WidgetPluginPositionsGrid {
    public constructor() {
        this.section = new Array<WidgetPluginPositionsGridSection>();
        this.widgetHeight = '300';
    }
    public section: WidgetPluginPositionsGridSection[];
    // public noOfColumns: string;
    public rowId: string;
    public dashboardName: string;
    public widgetHeight: string;
}

export class WidgetPluginPositions {
    public constructor() {
        this.grid = new Array<WidgetPluginPositionsGrid>();
    }
    public grid: WidgetPluginPositionsGrid[];
}

//#region Plugin_settings
export class PluginGeneralSettingsWidget {
    public id: string;
    public showGraph: boolean;
}

export class WidgetPluginGeneralSettings {
    constructor() {
        this.widget = new Array<PluginGeneralSettingsWidget>();
    }
    public widget: PluginGeneralSettingsWidget[]
}
export class DashboardSettings {
    public constructor() {
        this.widgetPluginPositionsGrid = new WidgetPluginPositions();
        this.widgetPluginSettings = new WidgetPluginSettings();
    }
    public widgetPluginPositionsGrid: WidgetPluginPositions;
    public widgetPluginSettings: WidgetPluginSettings;
    public widgetPluginPositionsLocalStorage: string;


}
export class DashboardPageSettings {
    public constructor() {
        this.widgetPluginPositionsGrid = new WidgetPluginPositions();
        this.widgetPluginSettings = new WidgetPluginSettings();
        this.widgetPluginPositionsLocalStorage = new Plugin_position();
        this.widgetPluginGeneralSettings = new WidgetPluginGeneralSettings();
        this.DisplayOrder=1;
    }
    public widgetPluginPositionsGrid: WidgetPluginPositions;
    public widgetPluginSettings: WidgetPluginSettings;
    public widgetPluginGeneralSettings: WidgetPluginGeneralSettings;
    public widgetPluginPositionsLocalStorage: Plugin_position;
    public pageName: string;
    public routePath: string;
    public DisplayOrder: number;
    public selectedModuleId: number;
}

export class HomeDashboardSettings {
    public constructor() {
        this.dashboardPageSettings = new Array<DashboardPageSettings>();
    }
    dashboardPageSettings: DashboardPageSettings[];
}
//#endregion