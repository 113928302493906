<div class="jarviswidget-ctrls" role="menu" *ngIf="canCopyMoveDocument">
  <a class="button-icon tablIndexFocus" href="javascript:void(0);" id="btnMoveCopy"
    title="Copy/Move Document(s) (Ctrl+Q)" tabindex="1" (keyup.enter)="copyMoveDocuments()"
    (click)="copyMoveDocuments()"><i class="glyphicon glyphicon-copy"></i></a>
</div>
<div class="jarviswidget-ctrls" style="cursor: pointer;">
  
  <a class="button-icon tablIndexFocus" [attr.tabindex]="0" (keyup.enter)="showMetaEditDialog()" id="btnMetaEdit"
    (click)="showMetaEditDialog()" title="Edit Metadata (Ctrl+Shift+E)">
    <i class="glyphicon glyphicon-pencil"></i>
  </a>
  
</div>
<form #MetacontainerForm="ngForm" *ngIf="displayMetaMoveCopyDlg">
  <p-dialog [(visible)]="displayMetaMoveCopyDlg" *ngIf="displayMetaMoveCopyDlg" showEffect="fade" [modal]="true"
      [style]="{width: '720px'}" [resizable]="false">
      <ng-template pTemplate="header" >
          Select Target Container
      </ng-template>
      <div class="upload-dialog">
          <!--style="overflow-y: hidden !important;overflow-x:hidden !important"-->
          <div id="smart-form" class="smart-form dialogSettings">
            <div id="form-fields form-group">
              <div class="row">
                  <div class="col-sm-5">
                      <fieldset>
                          <label class="label">Asset</label>
                          <!-- <p-dropdown appendTo="body" id="asn" class="comboFit" name="asn" [options]="assets"
                              (onChange)="onChangeAsset($event)" placeholder="Select Asset" required
                              [(ngModel)]="containerModel.asn" #asn="ngModel" [filter]="true" #asncopymovefilter
                              [resetFilterOnHide]="true">
                          </p-dropdown> -->

                          <p-dropdown appendTo="body" id="asn" class="comboFit" name="asn" [options]="assets"
                              (onChange)="onChangeAsset($event)" placeholder="Select Asset" required
                              [(ngModel)]="containerModel.asn" #asn="ngModel" [filter]="true" #asncopymovefilter
                              [resetFilterOnHide]="true">
                              <ng-template style="padding-bottom:20px;padding-left: 0px !important;" let-item
                                  pTemplate="selectedItem">
                                  <span>{{item.label}}</span>
                                  <!-- <span style="font-size:14px;background-color:lightgreen;">{{item.AssetType}}</span> -->
                              </ng-template>
                              <ng-template style="padding-bottom:20px !important;padding-left: 0px !important;"
                                  let-asset pTemplate="item">
                                  <div style="padding-bottom: 20px;">
                                      <!-- <span > -->
                                      <span style="padding:0" class="col-md-8">{{asset.label}}</span>

                                      <!-- </div> -->
                                      <!-- <div class="col-md-4"> -->
                                      <span style="padding:0" class="col-md-4">
                                          <span
                                              style="font-size: 11px;padding: 4px;background-color:lightgreen;color: white;border-radius: 3px;">
                                              {{asset.AssetType}}
                                          </span>
                                      </span>

                                      <!-- </div> -->
                                  </div>
                              </ng-template>
                          </p-dropdown>


                      </fieldset>
                  </div>
                  <div class="col-sm-5">
                      <fieldset>
                          <label class="label">Category </label>
                          <p-dropdown appendTo="body" id="category" name="category" class="comboFit" [options]="categories"
                              (onChange)="onChangeCategory($event)" placeholder="Select Category"
                              [(ngModel)]="containerModel.category" #category="ngModel">
                          </p-dropdown>
                      </fieldset>
                  </div>
                  <div class="col-sm-2">
                      <fieldset class="pull-right">
                          <label class="label" style="height: 19px;"></label>
                          <button pButton style="height: 29px;" type="button"  (click)="populateContainerGrid()" class="custom-button"
                              icon="fa fa-search" label="Search" [disabled]="!MetacontainerForm.form.valid"></button>
                      </fieldset>
                  </div>
              </div>
              <div class="row" style="margin-top: 5px;">
                  <div class="form-group col-sm-12">
                      <ag-grid-angular #MetacontainerGrid [gridOptions]="conGridOptions" [columnDefs]="conColumnDefs"
                          [rowData]="conGridData" (componentStateChanged)="formatContainerGrid($event)"
                          enableFiltering sortable="true" style="width: 100% !important; height:300px !important;"
                          class="ag-theme-balham" (rowClicked)='onContainerRowClick($event)'>
                          <!--(rowClicked)='updateSelectedDocument($event)'-->
                      </ag-grid-angular>
                  </div>
              </div>
          </div>
          </div>
      </div>
      <ng-template pTemplate="footer">
          <div class="p-dialog-buttonpane">
            <app-attach-selected-pages
             [FormDisabled]="MetacontainerForm.form.valid"
            [ConRowSelected]="conRowSelected"
            (emitSelectedPageEmitter)="selectedPageRange($event,containerForm)"></app-attach-selected-pages>
              <button type="button" pButton icon="fa fa-copy" class="btn btn-primary minzindex"
                  (click)="btnMoveCopyDocumentClick(true, MetacontainerForm)"
                  [disabled]="!MetacontainerForm.form.valid ||!conRowSelected" label="Copy"></button>
              <button type="button" pButton icon="fa fa-angle-double-right" class="btn btn-primary minzindex"
                  [disabled]="!MetacontainerForm.form.valid || !conRowSelected"
                  (click)="btnMoveCopyDocumentClick(false, MetacontainerForm)" label="Move"></button>
              <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex"
                  (click)="btnCancelContainerClick(MetacontainerForm)" label="Cancel"></button>

          </div>
      </ng-template>

  </p-dialog>
</form>
<form #frmEditMeta="ngForm" *ngIf="isDisplayDialog">
  <p-dialog [(visible)]="isDisplayDialog" id="docEditMetaUpload" showEffect="fade" [modal]="true"
    [style]="{ width: '700px' }" [resizable]="false">
    <ng-template pTemplate="header" >
      {{ dialogTitle }}
    </ng-template>

    <div class="upload-dialog">
      <div id="smart-form" class="smart-form dialogSettings">
        <div id="form-fields form-group">
          <div class="row">
            <div class="col-sm-6">
              <fieldset>
                <label class="label">
                  <!-- File Name -->
                  {{_sharedService.getDocumentLabel(containerCatergory, "BlobName")}}
                  <span class="text-danger"></span>
                </label>
                <label class="input">
                  <input id="fileName" type="text" class="input-smText"
                    [ngClass]="{'readonlyinput2': (recordType !== 'DOC' && containerCatergory != 'DOC' && recordType !== 'BND' && containerCatergory != 'BND')}"
                    [(ngModel)]="metaData.FileName" name="fileName" #fileName="ngModel"
                    [disabled]="((recordType !== 'DOC' && containerCatergory != 'DOC') || (recordType !== 'BND' && containerCatergory != 'BND'))" />
                </label>
              </fieldset>
            </div>

            <div class="col-sm-6" [ngClass]="{
                'metadata-fields-status': isStatusModuleSelected == true
              }" *ngIf="
                (canGENQualityStatus && processGENQualityStatusEnabled) ||
                (canMXRQualityStatus && processMXRQualityStatusEnabled) ||
                (canLOGQualityStatus && processLOGQualityStatusEnabled) ||
                (canDOCQualityStatus && processDOCQualityStatusEnabled) ||
                (canBNDQualityStatus && processBNDQualityStatusEnabled)
              ">
              <fieldset>
                <label class="label">
                  <!-- Status  -->
                  {{_sharedService.getDocumentLabel(containerCatergory, "Status")}} <span
                    [hidden]="(containerCatergory=='DOC' || containerCatergory=='BND')" class="text-danger">*</span>
                </label>
                <!-- <span [ngClass]="{ readonlyinput2: recordType == 'Search' }"> -->
                <span>
                  <p-dropdown appendTo="body" [options]="qualityStatusList" requried name="qualityStatus" #qualityStatus="ngModel"
                    placeholder="Select Quality Status" [style]="{ width: '300px' }" [(ngModel)]="metaData.Status"
                    [filter]="true"></p-dropdown>
                </span>
              </fieldset>
            </div>
          </div>
          <!-- <div class="row" >
                        <div class="form-group col-sm-6" >
                            <fieldset>
                                <label class="label">Status <span class="text-danger">*</span></label>
                                <span [ngClass]="{'readonlyinput2': recordType == 'Search'}">
                                    <p-dropdown appendTo="body" [options]="qualityStatusList" requried name="qualityStatus" #qualityStatus="ngModel" placeholder="Select Quality Status" [style]="{width:'300px'}" [(ngModel)]="metaData.Status" [filter]="true"  [disabled]="recordType =='Search'"></p-dropdown>
                                </span>
                            </fieldset>
                        </div>
                    </div> -->
          <div class="row" id="diveditmeta">
            <div class="form-group col-sm-6" [ngClass]="{
                'metadata-fields-status': isStatusModuleSelected == true
              }">
              <!--[style.width]="isStatusModuleSelected==true?'316px':null"-->
              <fieldset>
                <label class="label">
                  <!-- Document Type  -->
                  {{_sharedService.getDocumentLabel(containerCatergory, "DocType")}} <span
                    [hidden]="(containerCatergory=='DOC' || containerCatergory=='BND')" class="text-danger">*</span>
                </label>
                <span
                  [ngClass]="{ readonlyinput2: recordType == 'Search'  && containerCatergory != 'DOC' && containerCatergory != 'BND' }">
                  <p-dropdown appendTo="body" [options]="DocType" requried name="docType" #docType="ngModel"
                    placeholder="Select Document Type" [style]="{ width: '300px' }" [(ngModel)]="metaData.DocType"
                    [filter]="true"
                    [disabled]="recordType == 'Search'  && containerCatergory != 'DOC' && containerCatergory != 'BND'">
                  </p-dropdown>
                </span>
              </fieldset>
            </div>
            <div class="col-sm-6">
              <fieldset>
                <label class="label">
                  <!-- Document Date  -->
                  {{_sharedService.getDocumentLabel(containerCatergory, "DocDate")}}
                  <span [hidden]="(containerCatergory=='DOC' || containerCatergory=='BND')" class="text-danger">*</span>
                </label>
                <label class="input" [ngClass]="{
                    readonlyinput:
                      (parentWoNumber != '' && recordType == 'MXR') ||
                      recordType == 'Search' && containerCatergory != 'DOC' && containerCatergory != 'BND'
                  }">
                  <i class="icon-append fa fa-calendar"></i>
                  <p-calendar appendTo="body" [monthNavigator]="true" [yearNavigator]="true" (onClose)="onCloseDate()"
                    class="datepicker hasDatepicker" yearRange="1950:2030" [style]="{ width: '285px' }" requried
                    name="docDate" #docDate="ngModel" [(ngModel)]="metaData.DocDate" [dateFormat]="dateFormatCalender"
                    [disabled]="
                      (parentWoNumber != '' && recordType == 'MXR') ||
                      recordType == 'Search' && containerCatergory != 'DOC' && containerCatergory != 'BND'
                    "></p-calendar>
                </label>
              </fieldset>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6" [ngClass]="{
                'metadata-fields-status': isStatusModuleSelected == true
              }">
              <fieldset>
                <label class="label">
                  <!-- Document Number -->
                  {{_sharedService.getDocumentLabel(containerCatergory, "DocNo")}}
                </label>

                <label class="input rgxErr">
                  <i *ngIf="
                      docNumberMeta.value &&
                      !docNumberMeta.valid &&
                      (docNumberMeta.touched || docNumberMeta.dirty)
                    " class="icon-append fa fa-ban" title="{{ _sharedService.error_rgx_pre }} {{
                      _sharedService.re_DocumentNumber_Message
                    }} {{ _sharedService.error_rgx_post }}">
                  </i>
                  <span id="docNumberMetaId" [attr.tabindex]="0">
                    <input id="docNumberMeta" type="text" class="input-smText" [attr.tabindex]="0"
                      [(ngModel)]="metaData.DocNo" [pattern]="_sharedService.re_DocumentNumber" name="docNumberMeta"
                      #docNumberMeta="ngModel"
                      [disabled]="recordType == 'Search' && containerCatergory != 'DOC' && containerCatergory != 'BND'"
                      [ngClass]="{ readonlyinput2: recordType == 'Search' && containerCatergory != 'DOC' && containerCatergory != 'BND' }" />
                  </span>
                </label>
              </fieldset>
            </div>
            <div class="col-sm-6" *ngIf="recordType == 'MXR' || recordType == 'Search'">
              <fieldset>
                <label class="label">

                  <!-- {{referenceKeyLabel}}  -->
                  {{_sharedService.getDocumentLabel(containerCatergory, "ReferenceKey")}}
                  <span [hidden]="(containerCatergory=='DOC' || containerCatergory=='BND')" class="text-danger">*</span>
                </label>
                <label class="input">
                  <input id="woNumber" requried name="woNumber" #woNumber="ngModel" [(ngModel)]="metaData.ReferenceKey"
                    [disabled]="parentWoNumber != '' || (recordType == 'Search' && containerCatergory != 'DOC' && containerCatergory != 'BND')"
                    [ngClass]="{
                      readonlyinput2:
                        parentWoNumber != '' || (recordType == 'Search' && containerCatergory != 'DOC' && containerCatergory != 'BND')
                    }" type="text" class="input-smText" />
                </label>
              </fieldset>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <fieldset>
                <label class="label">
                  <!-- Tags -->
                  {{_sharedService.getDocumentLabel(containerCatergory, "Tags")}}
                </label>
                <p-multiSelect id="tagsctrledit" [options]="tags" [(ngModel)]="selectedTags" [style]="{ width: '100%' }" placeholder="Choose"
                  name="tagsctrledit" #tagsctrledit="ngModel" [maxSelectedLabels]="4" selectedItemsLabel="{0} items selected" appendTo="body">
                </p-multiSelect>
              </fieldset>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <fieldset>
                <label class="label">
                  <!-- Comments -->
                  {{_sharedService.getDocumentLabel(containerCatergory, "Comments")}}

                </label>
                <label class="textarea">
                  <textarea id="comments" name="comments" #comments="ngModel" [(ngModel)]="metaData.Comments"
                    class="custom-scrollNote no-overflow-x" rows="2"></textarea>
                </label>
              </fieldset>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <fieldset>
                <label class="label">
                  <!-- History -->
                  {{_sharedService.getDocumentLabel(containerCatergory, "History")}}
                </label>
                <label class="textarea">
                  <textarea id="history" name="history" #history="ngModel" [(ngModel)]="metaData.History"
                    class="custom-scrollNote readonlyinput2 no-overflow-x" rows="3" disabled></textarea>
                </label>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Contents End Here-->

    <ng-template pTemplate="footer">
      <div class="p-dialog-buttonpane">
        <div class="pull-left">
          <!-- <div class="smart-form"></div> -->
          <span class="smart-form">{{ metaData.SeqNo }}</span>
          <span class="CreatedbyBottomText" style="margin-left: 1em;">
            <!-- Created by  -->
            {{_sharedService.getDocumentLabel(containerCatergory, "CreatedBy")}}
            {{ CreatedBy }} on
            {{ CreatedOn }}
          </span>
        </div>

        <button type="button" pButton icon="fa fa-check" class="btn btn-primary" (click)="btnUpdateMetaData()"
          [disabled]="
            !frmEditMeta.form.valid ||
            isSubmitted ||
            metaData.DocType == '' ||
            (recordType == 'MXR' && metaData.ReferenceKey.length == 0) ||
            !canEditMetaData ||
            inWOGapAnalysisMode
          " label="Update"></button>
        <!--|| (metaData.DocType=='')|| (metaData.DocNo.length==0)||(recordType == 'MXR' && metaData.ReferenceKey.length==0)||metaData.DocDate==null-->
        <button type="button" pButton icon="fa fa-close" class="btn btn-default" (click)="btnCancelClick(frmEditMeta)"
          label="Cancel" [disabled]="isSubmitted"></button>
      </div>
    </ng-template>
  </p-dialog>
</form>

<form #frmEditBulkMeta="ngForm" *ngIf="isDisplayBulkMetaDataDialog">
  <p-dialog [(visible)]="isDisplayBulkMetaDataDialog" id="docEditMetaDataMetaUpload" showEffect="fade" [modal]="true"
    [style]="{ width: '350px' }" [resizable]="false">
    <ng-template pTemplate="header" >
      {{ dialogTitle }}
    </ng-template>

    <div class="upload-dialog" >
      <div id="smart-form" class="smart-form dialogSettings" style="height: 300px;">
        <div id="form-fields form-group">
          <div class="row">

            <div class="col-sm-12">
              <fieldset>
                <label class="label">
                  <!-- Status  -->
                  {{_sharedService.getDocumentLabel(containerCatergory, "Status")}}
                </label>
                <!-- <span [ngClass]="{ readonlyinput2: recordType == 'Search' }"> -->
                <span>
                  <p-dropdown appendTo="body" [options]="qualityStatusList" requried name="qualityStatus" #qualityStatus="ngModel"
                    placeholder="Select Quality Status" [style]="{ width: '300px' }" [(ngModel)]="metaData.Status"
                    [filter]="true"></p-dropdown>
                </span>
              </fieldset>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <fieldset>
                <label class="label">
                  <!-- Tags -->
                  {{_sharedService.getDocumentLabel(containerCatergory, "Tags")}}
                </label>
                <p-multiSelect id="tagsctrledit" [options]="tags" [(ngModel)]="selectedTags" [style]="{ width: '100%' }" placeholder="Choose"
                  name="tagsctrledit" #tagsctrledit="ngModel" [maxSelectedLabels]="4" selectedItemsLabel="{0} items selected" appendTo="body">
                </p-multiSelect>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Contents End Here-->

    <ng-template pTemplate="footer">
      <div class="p-dialog-buttonpane">
        <button type="button" pButton icon="fa fa-check" class="btn btn-primary" (click)="btnUpdateBulkMetaData()"
          label="Update"></button>
        <button type="button" pButton icon="fa fa-close" class="btn btn-default" (click)="btnBulkMetaDataCancelClick(frmEditBulkMeta)"
          label="Cancel" [disabled]="isBulkSubmitted"></button>
      </div>
    </ng-template>
  </p-dialog>
</form>
