<section id="widget-grid" class="adminaircraftcls" >

    <div class="row">
        <article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable" id="col1">
            <div class="no-margin jarviswidget jarviswidget-sortable" id="wid-id-2" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false" role="widget">
                <header role="heading" class="p-sortable-handle">
                    <span class="widget-icon"><i class="fa fa-list"></i></span>
                    <h2>Status Views</h2>
                    <span class="jarviswidget-loader"><i class="fa fa-refresh fa-spin"></i></span>
                    <div class="jarviswidget-ctrls" role="menu" title="Edit View (Ctrl+E)">
                        <a class="button-icon hand-cursor tablIndexFocus"  [attr.tabindex]="0" id="btnedit" style="color: black;"  (keyup.enter)="btnEditClick(null)" (click)="btnEditClick(null)">
                            <i class="glyphicon glyphicon-pencil"></i>
                        </a>
                    </div>
                    <div class="jarviswidget-ctrls hand-cursor" title="Export Status View">
                        <a class="button-icon  tablIndexFocus"  [attr.tabindex]="0" id="Downloadviewdata" (click)="ExportView()">
                            <i class="glyphicon glyphicon-download"></i>
                        </a>
                    </div>
                    <!-- for upload files -->
                    <dataimporter></dataimporter>
                    <div class="jarviswidget-ctrls" role="menu" title="Add View (Ctrl+A)">
                        <a class="button-icon hand-cursor  tablIndexFocus"  [attr.tabindex]="0" id="btnadd" style="color: black;" routerLink="/admin/configview" >
                            <i class="glyphicon glyphicon-plus"></i>
                        </a>
                    </div>
                    <div class="jarviswidget-ctrls" title="Help">
                        <app-component-articles class="hand-cursor" [componentName]="componentName">
                        </app-component-articles>
                    </div>
                </header>
                <div class="widgetdiv">
                    <div class="jarviswidget-editbox">
                    </div>
                    <div id="containerTypelisting" class="no-bottom-padding widget-body">
                        <section id="widget-grid2" class="">
                            <div class="jqgridboxadminaircraft">
                                <!--style="height:80vh !important;"-->
                                <ag-grid-angular id="agGridViewSettings" [navigateToNextCell]="navigateToNextCell"  #agGridViewSettings [gridOptions]="gridOptions" [columnDefs]="columnDefs"  [rowData]="gridData" sortable="true" filter=true style="width: 100%;" class="ag-theme-balham statusviewgrid" (rowClicked)='updateSelectedRow($event)' (rowDoubleClicked)='btnEditClick($event)'>
                                    <!--width: 100%;-->
                                </ag-grid-angular>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>
