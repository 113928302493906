

<!-- <button type="button" (click)="showDialog()" pButton icon="pi pi-info-circle" label="Show"> </button> -->


<div class="jarviswidget-ctrls" role="menu" title="Edit Section">
  <a class="button-icon" id="btnedit" (click)="showEditDialog()">
      <i class="glyphicon glyphicon-pencil"></i>
  </a>
</div>
<div class="jarviswidget-ctrls" role="menu" title="Add Section">
  <a class="button-icon hand-cursor" id="btnadd" style="color: black;"  (click)="showDialog()">
      <i class="glyphicon glyphicon-plus"></i>
  </a>
</div>

<form (ngSubmit)="btnSubmitClick(articleSectionForm)"  #articleSectionForm="ngForm">
  <p-dialog [(visible)]="display" showEffect="fade" [modal]="true" [style]="{width: '400px'}" [resizable]="false">
      <ng-template pTemplate="header" >
        {{dialogTitle}}


      </ng-template>
      <!-- Modal -->

      <div class="upload-dialog">
          <!--<div class="modal-body">-->
          <div class="smart-form">
              <div class="row">
                  <div class="form-group col-sm-12">
                    <fieldset >
                      <label class="label">
                        Name
                           <span [hidden]="name?.valid || model.Name" class="text-danger">
                              *
                          </span>

                      </label>

                        <label class="input">
                            <input id="name" name="name"  placeholder="Section Name" required [(ngModel)]="model.Name" #name="ngModel" type="text"  class="input-sm" />
                        </label>
                    </fieldset>


                  </div>
              </div>

              <div class="row">
                <div class="form-group col-sm-12">

                  <fieldset>

                    <label class="label">
                      Category<span [hidden]="articleCategory.valid|| (model.ArticleCategoryId)" class="text-danger">
                          *
                      </span>
                  </label>

                    <p-dropdown appendTo="body"  [style]="{padding:'0 0 0 0.5em',background:'#fff'}"  name="articleCategory"   id="articleCategory"  placeholder="Select Category" [options]="ArticleCategoryList"   [filter]="true" required [(ngModel)]="model.ArticleCategoryId" #articleCategory="ngModel" placeholder="Select Category"  > </p-dropdown>
                 </fieldset>
                </div>
            </div>

              <div class="row">
                <div class="form-group col-sm-12">


                  <fieldset >
                    <label class="label">
                      Description

                      <span   [hidden]="description?.valid || model.Description" class="text-danger">
                        *
                    </span>
                    </label>

                      <label class="input">
                          <input name="description" id="description" placeholder="Category Description"  required [(ngModel)]="model.Description" #description="ngModel" type="text"  class="input-sm" />
                      </label>
                  </fieldset>

                </div>
            </div>


            <div class="row">
              <div class="form-group col-sm-12">
                 <fieldset>
                  <label class="label">
                    Order


                  </label>

                    <label class="input">
                        <input name="order"  id="order" placeholder="Order Number"   [(ngModel)]="model.Order" #order="ngModel" type="text"  class="input-sm" />
                    </label>
                  </fieldset>
              </div>


          </div>

            <div class="row">
              <div class="form-group col-sm-12">
                 <fieldset>
                  <label class="label">
                    Display Column


                  </label>

                    <label class="input">
                        <input name="DisplayColumn"  id="displayColumn" placeholder="Display Number"   [(ngModel)]="model.DisplayColumn" #order="ngModel" min="1" max="2" onKeyUp="if(this.value>2){this.value='2';}else if(this.value<1){this.value='1';}" type="number" value=1  class="input-sm" />
                    </label>
                  </fieldset>
              </div>


          </div>


          <div class="row">
            <div class="form-group col-sm-6">
               <fieldset>
                    <label class="label">
                        Color Code<span [hidden]="(addeditTagsColorCode?.valid && addeditTagsColorCodeEditor?.valid)|| model.Color"  class="text-danger">
                            *
                        </span>
                    </label>
                    <p-colorPicker appendTo="body" id="addeditTagsColorCode" name="addeditTagsColorCode" [(ngModel)]="model.Color" #addeditTagsColorCode="ngModel"></p-colorPicker>
                    <input id="addeditTagsColorCodeEditor" type="text" class="color-picker-input" name="addeditTagsColorCodeEditor" required [(ngModel)]="model.Color" #addeditTagsColorCodeEditor="ngModel" class="colorCode">
                </fieldset>
            </div>

            <div class="form-group col-sm-6">
              <fieldset>
                <label class="label">Status</label>
                <label class="checkbox">
                    <span class="onoffswitch tablIndexFocus onoffswitch-Width">
                        <input type="checkbox" name="onoffswitch" [(ngModel)]="model.Status" class="onoffswitch-checkbox" checked="checked" id="isMactive">
                        <label class="onoffswitch-label" for="isMactive"><span class="onoffswitch-inner" data-swchon-text="Active" data-swchoff-text="Inactive"></span><span class="onoffswitch-switch onoffswitch-switch-Right"></span></label>
                    </span>
                </label>
              </fieldset>
            </div>
        </div>

          </div>
      </div>
      <ng-template pTemplate="footer">
          <div class="p-dialog-buttonpane">
            <button type="submit" pButton icon="fa fa-check" class="btn btn-primary minzindex" label="{{btnlabel}}" [disabled]="!articleSectionForm.form.valid"></button>
            <button type="button" pButton icon="fa fa-close" class="btn btn-default minzindex" (click)="btnCancelClick()" label="Cancel" [disabled]="isSubmitted"></button>

          </div>
      </ng-template>
  </p-dialog>
</form>

