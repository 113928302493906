//Components
import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { GridOptions, GridApi } from "ag-grid-community";

//Services
import { SharedService } from '../../../shared/services/shared.service';
import { SelectItem } from 'primeng/api';
import { OperationMode, AssetGroupModel } from './asset-group';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { AssetGroupService } from '../../services/asset-group/asset-group.service';
import { AssetService } from '../../../admin/services/asset/asset.service';
import { AssetModel } from '../../services/asset/asset';
var _router_: Router;
@Component({
    selector: 'asset-group',
    templateUrl: './asset-group.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [AssetGroupService, AssetService]

})

export class AssetGroupComponent implements OnInit {
    display: boolean = false;
    public gridOptions: GridOptions;
    columnDefs: any[];
    public gridData: any[];
    // availableRoles: any[] = [];
    // selectedRoles: any[] = [];

    public canAddEditAGroup: boolean = false;

    selectedAGroupID: string = null;

    public totalCount: number;
    public model: AssetGroupModel;
    public isSubmitted: boolean = false;
    PGroupList: SelectItem[] = [];
    public dialogTitle: string = 'Add Asset Permission Group';
    public lblsubmitButton: string = 'Add';
    public operationMode: OperationMode = 1;
    //#region Initialization of Component
    constructor(private _sharedService: SharedService, private cRef: ChangeDetectorRef, private _router: Router, private _AssetGroupService: AssetGroupService, private _hotkeysService: HotkeysService, private _assetService: AssetService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.UpdateRoles();
            _router_ = this._router;
            this.setHotKeys();
            this.initModel();
            this.navigateToNextCell=this.navigateToNextCell.bind(this);
        }
    }
    setHotKeys() {
        //Add Asset Group
        this._hotkeysService.add(new Hotkey('ctrl+a', (event: KeyboardEvent): boolean => {
            if (_router_.url == "/admin/assetgroup") {
                this.btnAddClick();
            }
            return false; // Prevent bubbling
        }));
        //Edit Asset Group
        this._hotkeysService.add(new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
            if (_router_.url == "/admin/assetgroup") {
                this.btnEditClick();
            }
            return false; // Prevent bubbling
        }));
        this._hotkeysService.add(new Hotkey('enter', (event: KeyboardEvent): boolean => {
            if (_router_.url == "/admin/assetgroup") {
                this.btnEditClick();
            }
            return false; // Prevent bubbling
        }));
    }
    ngOnInit() {
        if (this.canAddEditAGroup) {
            this.gridOptions = <GridOptions>
                {
                    rowSelection: 'single',

                    onGridReady: () => {
                        this.setAssetGridColumns();
                        try {
                            this.gridOptions.api.paginationSetPageSize(this._sharedService.getGridPageSize('agGridPGroups'));
                            this.gridOptions.api.hideOverlay();
                        } catch (e) {
                            console.log(e);
                        }
                    },
                    //suppressContextMenu: true,
                    pagination: true,
                    paginationPageSize: 20,
                    getContextMenuItems: this.getCustomContextMenuItems,
                    context: {
                        gridContext: this
                    },
                    defaultColDef: {
                        sortable: true,
                        filter: true
                    }

                };
            // this.getCategoryList();
            this.populateAssetGroupGrid();
        }
    }

    //#endregion

    //#region Ag Grid Population Event
    populateAssetGroupGrid(): void {

        this._AssetGroupService.getAssetGroupList()
        .subscribe({
            next: (response) => {
                this.gridOptions.api.sizeColumnsToFit();
                this.gridData = [];
                this.gridData = response;
                this.totalCount = response.length;                
            },
            error: (error) => {
                this.gridData = [];
                this.totalCount = 0;
            }
        });

    }
    //#endregion
    //#region Set Columns of Browse Ag Grid
    setAssetGridColumns(): void {
        this.columnDefs = [];
        this.columnDefs =
            [
                { headerName: 'Group Name', field: 'Name', menuTabs: ['filterMenuTab'] },
                // {
                //     headerName: 'Status', field: 'Status', filter:false, suppressMenu: true, valueFormatter: function (params: any) {
                //         if (params.value == true) {
                //             return "Active";
                //         }
                //         else {
                //             return "Inactive";
                //         }
                //     }
                // }
            ];

    }


    //#endregion

    //#region Ag-Grid Events
    updateSelectedRow(event: any) {
        this.selectedAGroupID = null;
        this.selectedAGroupID = event.data.Id;
    }
    //#endregion

    //#region Edit Asset Events
    rowDoubleClicked(event: any) {
        if (this.canAddEditAGroup) {
            this.btnEditClick();
        }
    }
    btnEditClick() {
        let selectedRows = this.gridOptions.api.getSelectedRows();
        if (this.canAddEditAGroup && (selectedRows && selectedRows.length > 0)) {
            this.initModel();
            this.operationMode = 2;
            this.dialogTitle = 'Edit Asset Permission Group';
            this.lblsubmitButton = 'Update';

            this._AssetGroupService.getAssetGroupByID(selectedRows[0].AssetGroupId)
            .subscribe({
                next: (response) => {
                    let data = response;
                    this.model = response;
                    this.showHideDialogue(true);                    
                },
                error: (error) => {
                    console.log(error);
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Asset Group not added, please try again.' });
        
                    this.showHideDialogue(false);                    
                }
            });
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Asset Group Selected', detail: 'Please select a record.' });
        }
    }
    //#endregion
    //#region Add Asset Events
    btnAddClick() {
        this.initModel();

        this.operationMode = 1;
        this.dialogTitle = 'Add Asset Permission Group';
        this.lblsubmitButton = 'Add';

        this._assetService.getAllAssetList(this._sharedService.baseUrl, null)
        .subscribe({
            next: (response) => {
                this.model.SelectedAssets = [];
                this.model.AvailableAssets = response;
                this.showHideDialogue(true);                
            },
            error: (error) => {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Asset Group not added, please try again.' });
        
                this.showHideDialogue(false);                
            }
        });

    }

    initModel() {
        this.model = new AssetGroupModel();
        this.model.Status = true;
        
    }

    //#endregion

    //#region Asset Roles Assignment
    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }

        this.canAddEditAGroup = this._sharedService.UserRole.Admin_Asset_Group;
    }
    //#endregion

    //#region Cancel Dialogue
    btnCancelClick(cancelForm: NgForm) {
        this.showHideDialogue(false);
        this.initModel();
        cancelForm.resetForm();
    }
    //#endregion
    //#region Helper Methods
    showHideDialogue(flag: boolean) {
        this.display = flag;
    }
    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }
    //#endregion

    //#region form submit
    onSubmit(form: NgForm) {
        this.changeButtonsStatus(true);
        this._sharedService.showLoader("Processing...");
        if (this.operationMode == 1) {
            this._AssetGroupService.addUpdateAssetGroup(this.model)
            .subscribe({
                next: (response) => {
                    this.showHideDialogue(false);
                    form.resetForm();
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Asset Group added succesfully.' });
                    this.changeButtonsStatus(false);
                    this.populateAssetGroupGrid();                    
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    if (error.status === 409) {
                        this._sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Asset Group already exists.' });
                    }
                    else {
                        this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Asset Group not added, please try again.' });
                    }
                    this.showHideDialogue(false);
                    this.initModel();
                    form.resetForm();
                    this.changeButtonsStatus(false);                    
                }
            });
        }
        else if (this.operationMode == 2) {
            this._AssetGroupService.addUpdateAssetGroup(this.model)
            .subscribe({
                next: (response) => {
                    this.showHideDialogue(false);
                    form.resetForm();
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Asset Group updated succesfully.' });
                    this.changeButtonsStatus(false);
                    this.populateAssetGroupGrid();                    
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    if (error.status === 409) {
                        this._sharedService.showToast({ severity: 'error', summary: 'Already Exists', detail: 'Asset Group already exists.' });
                    }
                    else {
                        this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Asset Group not updated, please try again.' });
                    }
                    this.showHideDialogue(false);
                    this.initModel();
                    form.resetForm();
                    this.changeButtonsStatus(false);                    
                }
            });
        }



    }
    //#endregion

    //#region Grid Context Menu
    getCustomContextMenuItems(params: any): any {
        let result: any[] = [];
        if (params.context.gridContext.totalCount > 0) {
            result.push({
                name: "Add Asset Group",
                action: function () {

                    params.context.gridContext.btnAddClick()
                },
                icon: params.context.gridContext._sharedService.addIconHTMLasString,
            });

            result.push({
                name: "Edit Asset Group",
                action: function () {
                    params.context.gridContext.selectedAGroupID = null;
                    if (params.node) {
                        params.context.gridContext.selectedAGroupID = params.node.data.GroupID;
                        params.context.gridContext.gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex).setSelected(true);
                        params.context.gridContext.btnEditClick()
                    }

                },
                icon: params.context.gridContext._sharedService.editIconHTMLasString,
            });

            let childResult: any[] = [];
            childResult.push({
                name: 'Excel',
                action: function () {
                    
                    params.api.exportDataAsExcel();
                    
                },
                icon: params.context.gridContext._sharedService.downloadLogFeedIconHTMLasString,
            });

            childResult.push({
                name: 'CSV',
                action: function () {
                    params.api.exportDataAsCsv();
                },
                icon: params.context.gridContext._sharedService.saveLogFeedIconHTMLasString,
            });

            result.push({
                name: 'Download',
                action: function () {


                },
                icon: params.context.gridContext._sharedService.downloadsFeedIconHTMLasString,
                subMenu: childResult,
            });
			
        }
        return result;
    }
    navigateToNextCell(params: any) {
        var previousCell = params.previousCellPosition;
        var suggestedNextCell = params.nextCellPosition;
        var KEY_UP = 'ArrowUp';
        var KEY_DOWN = 'ArrowDown';
        var KEY_LEFT = 'ArrowLeft';
        var KEY_RIGHT = 'ArrowRight';
        switch (params.key) {
            case KEY_DOWN:
                var nextRowIndex = previousCell.rowIndex + 1;
                var renderedRowCount = this.gridOptions.api.getModel().getRowCount();
                if (nextRowIndex >= renderedRowCount) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedAGroupID = null;
                    this.selectedAGroupID = rowNode.data.Id;
                        return {
                        rowIndex: nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_UP:
                var _nextRowIndex = previousCell.rowIndex - 1;
                if (_nextRowIndex < 0) {
                    return null;
                } else {
                    this.gridOptions.api.deselectAll();
                    var rowNode = this.gridOptions.api.getDisplayedRowAtIndex(_nextRowIndex);
                    rowNode.setSelected(true);
                    this.selectedAGroupID = null;
                    this.selectedAGroupID = rowNode.data.Id;
                        return {
                        rowIndex: _nextRowIndex,
                        column: previousCell.column,
                        floating: previousCell.floating
                    };
                }
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw 'this will never happen, navigation is always on of the 4 keys above';
        }
    }
    //#endregion
}