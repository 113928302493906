import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { Router, ActivatedRoute } from '@angular/router';


import { Subscription } from 'rxjs';

import * as moment from 'moment/moment';
import { APIService } from '../../../api/services/api.service';
import { datafeed } from '../../../api/components/list-datafeed/data-feed';
import { RecordConfig } from '../../../api/components/record-configuration/record-mapping'

@Component({
    selector: 'record-export',
    templateUrl: './export.component.html',
    providers: [APIService]
})
export class RecordExportComponent implements OnInit, OnDestroy {

    dialoguesubscription: Subscription;
    assetSubscription: Subscription;
    currentFeedId: string;
    currentFeedDataType: string;


    public displayConfigdialogue: boolean = false;
    public feedTypeInput: string = "ExportTemp";
    public isBulkDownloadVisible: boolean = false;
    constructor(private _sharedService: SharedService, private _router: Router, private _apiService: APIService, private _route: ActivatedRoute,) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
    }

    ngOnInit() {
        this.dialoguesubscription = this._sharedService._changeRecordConfigDialogueStatus
        .subscribe({
            next: (flag:any) => {
                this.OpencloseConfigDialogue(flag.showDialogue)
                if (flag.showMessage) {
        
                    if (this._sharedService.selectedAsset && this._sharedService.selectedAsset.length) {
                        this._sharedService.clearToast();
                        // this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'The Compilation process has now started, a URL to download the final compilation .ZIP file will be sent to you by email.' });
        
                        setTimeout(() => {
                            if (this._router.url.indexOf("record/") != -1) {
                                this._router.navigate(["/record/myreports"]);
                            } else if (this._router.url.indexOf("transition/") != -1) {
                                this._router.navigate(["/transition/myreports"]);
                            }
                        },
                            4000);
        
                    }
                    else {
        
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'No asset selected', detail: 'Please select asset' });
                        setTimeout(() => {
                            //this._router.navigate(["/record/browse"]);
        
                            if (this._router.url.indexOf("record/") != -1) {
                                this._router.navigate(["/record/browse"]);
                            } else if (this._router.url.indexOf("transition/") != -1) {
                                this._router.navigate(["/transition/binders"]);
                            }
                        },
                            1500);
                    }
        
        
                }
                else {
                    //this._router.navigate(["/record/browse"]);
                    if (this._router.url.indexOf("record/") != -1) {
                        this._router.navigate(["/record/browse"]);
                    } else if (this._router.url.indexOf("transition/") != -1) {
                        this._router.navigate(["/transition/binders"]);
                    }
                }
            }
        });

        let routeValue: string = this._route.snapshot.data[0].recordType;
        if (routeValue != '') {
            this.currentFeedDataType = routeValue;
            this.showBulkExport(routeValue);
        }

        this.assetSubscription = this._sharedService._currentTempFeedASN
        .subscribe({
            next: (asn:any) => {
                this.updateFeedAndRefreshScreen(asn)
            }
        })
    }

    ngOnDestroy() {
        if (this.dialoguesubscription) {
            this.dialoguesubscription.unsubscribe();
        }
    }

    //#region Export
    showBulkExport(templateName: string) {
        if (this._sharedService.selectedAsset && this._sharedService.selectedAsset.length) {
            this._apiService.GetFeedDataTypeFromTemplate(templateName)
            .subscribe({
                next: (response) => {
                    let feedDataTypes = response.split(',');
                    let SourceType;
                    let feedName;
                    // if (templateName.includes("Transition"))// == "Export Transition Documents List"  || templateName == "Toolbox - Export Transition Documents List" || templateName == "Toolbox - Export Transition Documents" || templateName == "Export Transition Documents") {
                    if (templateName == "Export Transition Documents List"){
                        feedName= "TRN_"+"LST"
                    }
                    else if(templateName == "Export Transition Documents") {
                        feedName= "TRN_"+"DOC"
                    }
                    else if(templateName == "Export Records Documents") {
                        feedName= "REC_"+"DOC"
                    }
                    else {
                        feedName= "REC_"+"LST"
                    }
                    if (templateName.includes("Transition"))// == "Export Transition Documents List"  || templateName == "Toolbox - Export Transition Documents List" || templateName == "Toolbox - Export Transition Documents" || templateName == "Export Transition Documents") {
                    {
                        SourceType = "Transition Export"
                    }
                    else {
                        SourceType = "Records Export"
                    }
                    let feedData: datafeed =
                    {
                        // DataType: dataType,
                        // FeedName: this._apiService.getTempFeedName(),
                        FeedName: feedName + '_' + this._apiService.getTempFeedTimeStamp(),
                        FeedType: "ExportTemp",
                        Interval: "None",
                        SourceType: SourceType,
                        //US-8181 //SourceDetail: this._sharedService.selectedAsset[0].data.split('|')[1],
                        SourceDetail: JSON.parse(this._sharedService.selectedAsset[0].data).ASN,
                        TargetType: 'Email Link',
                        TargetDetail: '',
                        Status: true,
                        TemplateName: templateName,
                        FeedScope: "Private"
                    }
                    this._apiService.addFeedAndReturnFeedID(feedData)
                    .subscribe({
                        next: (response) => {
                            let feedDataId = response;
                            this.currentFeedId = feedDataId;
                            this._sharedService.recordConfig = new RecordConfig(feedDataId, feedData.FeedName, feedData.DataType, feedData.SourceDetail, feedDataTypes);
                            this.OpencloseConfigDialogue(true);
                        },
                        error: (error) => {
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'error', summary: 'Error Occured', detail: 'Error Occurred, please try again.' });
                        }
                    });
                }
            });
        }
        else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No asset selected', detail: 'Please select asset' });
            let config: any = {
                showDialogue: false,
                showMessage: true
            };

            //R.A.//24-06-2021
            //this._router.navigate(["/record/browse"]);
            this._sharedService.changeRecordConfigDialogueStatus(config);
        }

    }

    updateFeedAndRefreshScreen(asn: string) {
        let feedData: datafeed =
        {
            FeedId: this.currentFeedId,
            DataType: this.currentFeedDataType,
            FeedName: this._apiService.getTempFeedName(),
            FeedType: "ExportTemp",
            Interval: "None",
            SourceType: "Records",
            SourceDetail: asn,
            TargetType: 'Email Link',
            TargetDetail: '',
            Status: true
        }
        this._apiService.updateTempFeed(feedData)
        .subscribe({
            next: (response) => {
                this._sharedService.feedUpdated();
                this._sharedService.recordConfig = new RecordConfig(feedData.FeedId, feedData.FeedName, feedData.DataType, feedData.SourceDetail);
                this.OpencloseConfigDialogue(true);
            },
            error: (error) => {
                this._sharedService.clearToast();
                this._sharedService.showToast(
                    { 
                        severity: 'error', 
                        summary: 'Error Occured', 
                        detail: 'Error Occurred, please try again.' 
                    }
                );
            }
        });
    }

    OpencloseConfigDialogue(flag: boolean) {
        this.displayConfigdialogue = flag;
    }
    adjustConfigDlgHeight(event: any) {
        let tskDlgfrm = document.getElementById('recordConfigDlg');
        let tskDlgfrm1 = document.getElementById('recordDocPackGrid');
        if (tskDlgfrm) {
            tskDlgfrm.style.height = '543px';
            tskDlgfrm1.style.height = '60vh';
            tskDlgfrm.style.overflowX = 'hidden';
        }
    }
    //#endregion
}
