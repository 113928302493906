import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SharedService } from '../../../shared/services/shared.service';

// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class ContainerQualityStatusService {

  constructor(private http: HttpClient, private _sharedService: SharedService) {
  }

  addUpdateQualityStatus(body: any): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/ContainerQualityStatus/AddUpdateQualityStatus`, body, this._sharedService.getReqOptWithAccessToken());
  }
  getQualityStatusByID(body: string): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/ContainerQualityStatus/GetQualityStatusByID?qualityStatusID=${body}`, '', this._sharedService.getReqOptWithAccessToken());
  }

  getAllQualityStatusData(body: any): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/ContainerQualityStatus/GetAllQualityStatusData`, body, this._sharedService.getReqOptWithAccessToken());
  }
  getActiveQualityStatusData(body: any): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/ContainerQualityStatus/GetActiveQualityStatusData`, body, this._sharedService.getReqOptWithAccessToken());
  }
  getActiveQualityStatusDataForDropDown(body: any): Observable<any> {
    return this.http.post(`${this._sharedService.baseUrl}/api/ContainerQualityStatus/getActiveQualityStatusDataForDropDown`, body, this._sharedService.getReqOptWithAccessToken());
  }
}
