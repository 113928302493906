import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from '../../../shared/services/shared.service';

@Injectable()
export class AlertService {
    constructor(private _shareService: SharedService, private http: HttpClient) {
    }

    GetSelectedTenantUsersWithTenantName(apiUrl: string, body: any, reqOption: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Alert/GetSelectedTenantUsersWithTenantName`, body, reqOption)

    }

    SendNotification(apiUrl: string, body: any, reqOption: any): Observable<any> {
        return this.http.post(`${apiUrl}/api/Alert/SendNotification`, body, reqOption)

    }
}