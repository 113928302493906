import { Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
import { TagService } from '../../../shared/services/tag/tag.service';
import { AzureBlobService } from '../../../shared/services/AzureBlob/azure-blob.service';
import { DocumentTypeService } from '../../../admin/services/document-type/document-type.service';
import { BlobEditMetaData } from '../../../shared/services/AzureBlob/blob-metadata';
import { IConfig } from '../../../shared/helpers/config';
import { NgForm } from '@angular/forms';
import * as moment from 'moment/moment';
import { containerForSearch } from '../../../records/services/container/container';
import { Subscription } from 'rxjs';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { QualityStatusService } from '../../../admin/services/qualitystatus/qualitystatus.service';
import { container, SearchContainerModel, OutgoingParams, KeyValPair, OutGoingParamKeyVal } from '../../../records/services/container/container';
import { GridOptions, CsvExportParams, ExcelExportParams, ExcelStyle, ExcelCell, GridApi, RowNode, ColDef, SideBarDef, ToolPanelDef } from "ag-grid-community";
import { AssetService } from 'src/app/admin/services/asset/asset.service';
import { ContainerService } from '../../services/container/container.service';
import { OCRdocument } from './OCRDocuments';
var dateFormatF: string;
var dateSeparatorS: string;
var dayD: number;
var monthM: number;
var yearY: number;
var statusList: any[] = [];
var columnDefs_1: any[];

@Component({
    selector: 'edit-metadata',
    templateUrl: './edit-metadata.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DocumentTypeService, ConfirmationService, TagService, AzureBlobService, QualityStatusService,AssetService,ContainerService]
})

export class EditMetaDataComponent implements OnInit, OnDestroy {
    @Input() recordType: string;
    @Output() updateDocumentItem  = new EventEmitter<any>();
    containerCatergory: string;
    isDisplayDialog: boolean = false;
    isDisplayBulkMetaDataDialog: boolean = false;
    DocType: SelectItem[];
    qualityStatusList: SelectItem[] = [];
    selectedDocType: SelectItem;
    dialogTitle: string;
    uploadUrl: string;
    metaData: BlobEditMetaData;
    orignalMetaData: BlobEditMetaData;
    newMetaData: BlobEditMetaData;
    isDataChanged: boolean = false;
    tags: SelectItem[] = [];
    selectedTags: string[] = [];
    parentWoNumber: string;

    dateFormatCalender = "";
    dateSeparator = "";
    dateFormat = "";
    configuration: IConfig[] = [];
    public bulkUploadArray: any = [];
    private day: number;
    private month: number;
    private year: number
    isSubmitted: boolean = false;
    isBulkSubmitted: boolean = false;
    NewFileName: string = "";
    FileDateTimeStamp: string = "";
    private containerSearch: containerForSearch;
    public canEditMetaDataReadonly: boolean = false;
    public canEditMetaData: boolean = false;
    public canCopyMoveDocument:boolean=false;
    public isStatusModuleSelected: boolean = false;
    private editMetaDataSub: Subscription;
    subscription: Subscription;
    public referenceKeyLabel: string = "";
    public inWOGapAnalysisMode: boolean = false;
    public canMXRQualityStatus: boolean = false;
    public canLOGQualityStatus: boolean = false;
    public canGENQualityStatus: boolean = false;
    public canDOCQualityStatus: boolean = false;
    public canBNDQualityStatus: boolean = false;

    public processMXRQualityStatusEnabled: boolean = false;
    public processLOGQualityStatusEnabled: boolean = false;
    public processGENQualityStatusEnabled: boolean = false;
    public processDOCQualityStatusEnabled: boolean = false;
    public processBNDQualityStatusEnabled: boolean = false;
    public selectedTargetContainer: any;
    public selectedDocuments: any;
    public CreatedOn: string;
    public CreatedBy: string;
    public appHotkeys: Hotkey[] = [];
    public selectedComboCatergorySelection: string = "";
    public displayMetaMoveCopyDlg: boolean = false;
    conRowSelected: boolean = false;
    containerModel: SearchContainerModel;
    conGridData: any[] = [];
    categories: SelectItem[]=[];
    conTypes: SelectItem[] = [];
    conTotalCount: number = 0;
    assets: SelectItem[] = [];
    conColumnDefs: any[];
    public dateConfig: IConfig[] = [];
    conGridOptions: GridOptions;
    public docDependencies: any[] = [];
    public docRefToInbox: any[] = [];
    public docRefToStatus: any[] = [];
    public docRefToDependencies: any[] = [];

    public docRefToDependenciesGridData: any[] = [];

    public docInvalidOrMissingInfoGridData: any[] = [];
    public docImageToPDFGridData: any[] = [];
    public docRefToInboxGridData: any[] = [];
    public docRefToStatusGridData: any[] = [];
    // public moveActionForDocInbox: string = "";
    // public moveActionForDocStatus: string = "";
    public moveActionForDependencies: string = "";
    public moveActionForInvalidFiles: string = "";
    public moveActionForImageFileToPDF: string = "";
    private _OCRdocument:OCRdocument[]=[];

    public Document_MXR_Columns: any[] = [];
    public Document_LOG_Columns: any[] = [];
    public Document_GEN_Columns: any[] = [];
    public Document_DOC_Columns: any[] = [];
    public Document_BND_Columns: any[] = [];

    public Document_MXR_Sort_By: string = "";
    public Document_LOG_Sort_By: string = "";
    public Document_GEN_Sort_By: string = "";
    public Document_DOC_Sort_By: string = "";
    public Document_BND_Sort_By: string = "";

    public Container_MXR_Columns: any[] = [];
    public Container_LOG_Columns: any[] = [];
    public Container_GEN_Columns: any[] = [];
    public Container_DOC_Columns: any[] = [];
    public Container_BND_Columns: any[] = [];


    public Container_MXR_Sort_By: string = "";
    public Container_LOG_Sort_By: string = "";
    public Container_GEN_Sort_By: string = "";
    public Container_DOC_Sort_By: string = "";
    public Container_BND_Sort_By: string = "";

    simpleObservable: any;
    constructor(private _sharedService: SharedService, private _azureBlobService: AzureBlobService,
        private _documentTypeService: DocumentTypeService, private confirmationService: ConfirmationService,
        private _tagService: TagService, private _router: Router, private _hotkeysService: HotkeysService,
        private cRef: ChangeDetectorRef, private _qualityStatusService: QualityStatusService,
        private _assetService: AssetService,private _containerService: ContainerService
        ) {
            if (!this._sharedService.isAuthUser()) {
                this._router.navigate(['login']);
                this._sharedService.SessionExpiredMessage();
            }
            else {
                this.populateAsset();
                this.dateConfig = this._sharedService.getConfiguration();

            }
    }
    documentsNameList: any[] = [];
    multiDocumentsSubscription(){
        this.subscription = this._sharedService._bulkSelectedDocuments
        .subscribe({
            next: (selDocuments:any) => {
                if(selDocuments.length > 1){
                    this.documentsNameList=[];
                    selDocuments.forEach(x=>{
                        this.documentsNameList.push(x.BlobName)
                    })
                }
                else{
                    this.documentsNameList=[];
                }
            }
        });
    }
    ngOnInit() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        else {
            this.multiDocumentsSubscription()
            this.referenceKeyLabel = this._sharedService.getReferenceKeyLabel();
            this.metaData = new BlobEditMetaData("", "", "", "", "", "", "", "", null, "", "");
            this.recordType = this._sharedService.recordType ? (this._sharedService.recordType != "Search" ? this._sharedService.recordType.toUpperCase() : this._sharedService.recordType) : this._sharedService.recordType;
            this.containerCatergory = this.recordType;
            if (this._router.url.indexOf("status/viewlist") != -1 || this._router.url.indexOf("repair/viewlist") != -1) {
                this.recordType = "Search";
                this.containerCatergory = this.recordType;
                this.isStatusModuleSelected = true;
            }
            this.UpdateRoles();

            this.subscription = this._sharedService._selectedContainer
            .subscribe({
                next: (response) => {
                    this.UpdateRoles();
                    this.distroyhotKeys();
                    this.setHotKeys();
                }
            });
            this.editMetaDataSub = this._sharedService._editMetaData
            .subscribe({
                next: (param:any) => {
                    this.showMetaEditDialog();
                },
                error: (error) => {
                }
            });
            this.getDateFormatInfo();
            this.setHotKeys();

            this.conGridOptions = <GridOptions>
            {
                rowSelection: 'single',
                rowData: [],
                onGridReady: () => {
                    this.setContainerGridColumns();
                },
                suppressContextMenu: true,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                }

            }
            this.containerModel = new SearchContainerModel("", null, null, false);
          
            
        }
        this.prepareDocumentGridColumns();
        this.populateQualityStatus();
        
    }
    formatContainerGrid(event: any) {
        //this.conGridOptions.api.sizeColumnsToFit();
    }
    //#region Destroy
    ngOnDestroy() {
        if (this.editMetaDataSub) {
            this.editMetaDataSub.unsubscribe();
        }


        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.distroyhotKeys();
    }

    distroyhotKeys() {
        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
    }

    setHotKeys() {
        if (this._router.url.indexOf("/transition/binders") != -1 || this._router.url.indexOf("/record/browse") != -1 || this._router.url.indexOf("/record/logs") != -1 || this._router.url.indexOf("/record/general") != -1 || this._router.url.indexOf("/record/documents") != -1  || this._router.url.indexOf("/record/search") != -1 ||  this._router.url.indexOf("/transition/search") != -1) {
            let hotKeyObj: Hotkey = null;

            //Export CSV
            if (this.canEditMetaData) {
                hotKeyObj = new Hotkey('ctrl+shift+e', (event: KeyboardEvent): boolean => {
                    this.showMetaEditDialog();
                    return false; // Prevent bubbling
                });

                this._hotkeysService.add(hotKeyObj);
                this.appHotkeys.push(hotKeyObj);
                hotKeyObj = new Hotkey('ctrl+enter', (event: KeyboardEvent): boolean => {
                    this.showMetaEditDialog();
                    return false; // Prevent bubbling
                });


                this._hotkeysService.add(hotKeyObj);
                this.appHotkeys.push(hotKeyObj);
            }
        }
    }
    //#endregion
    //#region Edit Meta Data

    initPopupModel() {
        this.parentWoNumber = '';
        this.FileDateTimeStamp = ""
        //this.parentWoNumber = this._sharedService.selectedDocument.ReferenceKey!=null ? this._sharedService.selectedDocument.ReferenceKey : null;
        this.parentWoNumber = this._sharedService.selectedContainer ? this._sharedService.selectedContainer.ReferenceKey : '';
        if (this.parentWoNumber == null) {
            this.parentWoNumber = '';
        }

        this.metaData = new BlobEditMetaData(this.getBlobName(), this._sharedService.selectedDocument.ASN, this.getDocType(), this.getDocNo(), this.getTags(), this.getComments(), this.getWONo(), this._sharedService.selectedDocument.Url, this.getDocumentDate(), this.getHistory(), this._sharedService.getFileNameFromURL('') /*getFileName()*/, "");
        this.orignalMetaData = new BlobEditMetaData(this.metaData.BlobName, this.metaData.ASN, this.metaData.DocType, this.metaData.DocNo, this.metaData.Tags, this.metaData.Comments, this.metaData.ReferenceKey, this._sharedService.selectedDocument.Url, this.metaData.DocDate, this.metaData.History, this.metaData.FileName, "");
        // this.getNewFileName();
    }

    initPopupModelSearch() {
        this.parentWoNumber = '';
        this.FileDateTimeStamp = ""
        this.parentWoNumber = this._sharedService.selectedContainer ? this._sharedService.selectedContainer.ReferenceKey : '';
        if (this.parentWoNumber == null) {
            this.parentWoNumber = '';
        }
        let blobReference: string[] = this.extractBlobReferenceFromURL();
        let body: containerForSearch =
        {
            ContainerName: blobReference[0],
            ASN: blobReference[1],
            Type: blobReference[2],
            ID: blobReference[3],
            Url: blobReference[4]
        }
        this._azureBlobService.getSpecificBlobData(body)
        .subscribe({
            next: (response) => {
                let body = response;
                this.CreatedBy = body.CreatedBy;
                this.CreatedOn = this._sharedService.getDateFormatTimeDialogBottom(body.CreatedOn)
                this.metaData = new BlobEditMetaData(this.getBlobName(body), blobReference[1], this.getDocType(body), this.getDocNo(body), this.getTags(body), this.getComments(body), this.getWONo(body), blobReference[4], this.getDocumentDate(body), this.getHistory(body), this._sharedService.getFileNameFromURL(body.Url), "", body.SeqNo, body.Status, body.ContainerCatergory);
                this.orignalMetaData = new BlobEditMetaData(this.metaData.BlobName, this.metaData.ASN, this.metaData.DocType, this.metaData.DocNo, this.metaData.Tags, this.metaData.Comments, this.metaData.ReferenceKey, this.metaData.Url, this.metaData.DocDate, this.metaData.History, this.metaData.FileName, "", body.SeqNo, body.Status, body.ContainerCatergory);
        
                this.containerCatergory = (body.ContainerCatergory == null || body.ContainerCatergory == '') ? this.recordType : body.ContainerCatergory;
        
                if (this.recordType === "DOC" || this.recordType === "BND") {
                    this.isDisplayDialog = true;
                    this.cRef.markForCheck();
                }
                else {
                    if (this.CheckDocType()) {
                        this.isDisplayDialog = true;
                        this.cRef.markForCheck();
                    }
                }
            },
            error: (error) => {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'File Does Not Exist', detail: 'File does not exist' });
            }
        });

        //this.metaData = new BlobEditMetaData(this._sharedService.selectedDocument.ASN, this.getDocType(), this.getDocNo(), this.getTags(), this.getComments(), this.getWONo(), this._sharedService.selectedDocument.Url, this.getDocumentDate(), this.getHistory(), this.getFileName(), "");
        // this.orignalMetaData = new BlobEditMetaData(this.metaData.ASN, this.metaData.DocType, this.metaData.DocNo, this.metaData.Tags, this.metaData.Comments, this.metaData.ReferenceKey, this._sharedService.selectedDocument.Url, this.metaData.DocDate, this.metaData.History, this.metaData.FileName, "");
        //// this.getNewFileName();
    }

    showMetaEditDialog() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        this.inWOGapAnalysisMode = this._sharedService.inWOGapAnalysisMode;
        if(this.documentsNameList.length > 1 && this.documentsNameList.length < 100)
        {
                this.NewFileName = "";
                this.changeBulkButtonsStatus(false);
                this.populateQualityStatusDropdown();
                this.populateTagsDropdown();
                this.metaData = new BlobEditMetaData("", this._sharedService.selectedDocument.ASN, "", "", "", "", "", "", null, "", "");
                this.isDisplayBulkMetaDataDialog = true;
                this.cRef.markForCheck();
                this.dialogTitle = this.recordType.toUpperCase() + ' > Edit MetaData';
        }
else{
        if (this.isStatusModuleSelected) {
            let selectedDocumentUrl = this._sharedService.getStatusModuleSelectedDocument();
            if (selectedDocumentUrl && selectedDocumentUrl != null && selectedDocumentUrl != "null") {
                this.NewFileName = "";
                this.isDataChanged = false;
                this.changeButtonsStatus(false);
                this.setCalenderDateFormat();
                this.populateDocTypeDropdown();
                this.populateQualityStatusDropdown();
                this.populateTagsDropdown();
                this.initPopupModelSearch();
                this.dialogTitle = 'Status > Edit MetaData';
            }
            else {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'No Document', detail: 'Please select document.' });
            }

        }
        else {
            if (this._sharedService.selectedDocCount == 0 || this._sharedService.selectedDocCount == 1) {
                if (this.recordType != "Search") {
                    if (this._sharedService.selectedAsset && this._sharedService.selectedContainer) {
                        if (this._sharedService.selectedDocument && this._sharedService.selectedDocument.ASN != undefined) {
                            this.NewFileName = "";
                            this.isDataChanged = false;
                            this.changeButtonsStatus(false);
                            this.setCalenderDateFormat();
                            this.populateDocTypeDropdown();
                            this.populateQualityStatusDropdown();
                            this.populateTagsDropdown();
                            this.initPopupModelSearch();
                            //this.initPopupModel();
                            this.dialogTitle = this.recordType.toUpperCase() + ' > Edit MetaData';
                            // this.isDisplayDialog = true;
                        }
                        else {
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'warn', summary: 'No Document', detail: 'Please select document.' });
                        }
                    } else {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'No Asset/Container Selected', detail: 'Please select an Asset.' });
                    }
                }
                else {
                    if (this._sharedService.selectedAsset) {
                        if (this._sharedService.selectedDocument && this._sharedService.selectedDocument.ASN != undefined) {
                            this.NewFileName = "";
                            this.isDataChanged = false;
                            this.changeButtonsStatus(false);
                            this.setCalenderDateFormat();
                            this.populateDocTypeDropdown();
                            this.populateQualityStatusDropdown();
                            this.populateTagsDropdown();
                            this.initPopupModelSearch();
                            this.dialogTitle = this.recordType + ' > Edit MetaData';
                            //this.isDisplayDialog = true;
                        }
                        else {
                            this._sharedService.clearToast();
                            this._sharedService.showToast({ severity: 'warn', summary: 'No Document', detail: 'Please select document.' });
                        }
                    } else {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'No Asset Selected', detail: 'Please select an Asset.' });
                    }
                }

            } else {
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'warn', summary: 'Multiple Documents Selected', detail: 'Please select a single document to edit.' });
            }
        }
    }
}



    btnCancelClick(form: NgForm) {
        
        this.changeButtonsStatus(false);
        this.isDataChanged = false;
        this.isDisplayDialog = false;
        form.resetForm();
        this.metaData = new BlobEditMetaData("", "", "", "", "", "", "", "", null, "");

    }
    btnBulkMetaDataCancelClick(form: NgForm) {
        this.changeButtonsStatus(false);
        this.isDataChanged = false;
        this.selectedTags=[];
        this.isDisplayBulkMetaDataDialog = false;
        form.resetForm();
        this.metaData = new BlobEditMetaData("", "", "", "", "", "", "", "", null, "");

    }
    btnUpdateBulkMetaData() {
        this.changeButtonsStatus(true);
        this.metaData.Tags = this.selectedTags.length > 0 ? this.selectedTags.join(",") : null;
        if (this.documentsNameList.length > 1 && (this.metaData.Tags || this.metaData.Status)) {
            var blob: any = {};
            let assetDataObj = JSON.parse(this._sharedService.selectedAsset[0].data);
            blob.ASN = this._sharedService.selectedContainer.ASN;
            blob.Type = this._sharedService.selectedContainer.Type;
            blob.ID = this._sharedService.selectedContainer.ID;
            blob.Url = this.documentsNameList[0];
            blob.ContainerName = assetDataObj.StoragePath;// asset[0],
            blob.BolbNames = this.documentsNameList;
            blob.Status = this.metaData.Status;
            blob.Tags = this.metaData.Tags;
            this._sharedService.showLoader("Processing...");
            this._azureBlobService.updateBlobMetaDataBulk(blob)
            .subscribe({
                next: (response) => {
                    if(response=202){
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'success', summary: 'MetaData updated', detail: 'Documents metadata has been updated successfully.' });
                    this.isDisplayBulkMetaDataDialog = false;
                    this.selectedTags = [];
                    this.changeButtonsStatus(false);
                    if (this.recordType != "Search") {
                        this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                        this._sharedService.onAssetChanged(this._sharedService.selectedAsset);
                    }
                }
                this.cRef.markForCheck();
                },
                error: (error) => {
                    this._sharedService.hideLoader();
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'warn', summary: 'Unable to update some documents'});
                }
            });
        }
        else {
            this.isDisplayBulkMetaDataDialog = false;
            this.changeButtonsStatus(false);
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'info', summary: 'No Change Applied', detail: 'Document metadata not changed.' });

        }
    }

    btnUpdateMetaData() {
        
        this.changeButtonsStatus(true);
        this.metaData.Tags = this.selectedTags.length > 0 ? this.selectedTags.join(",") : null;
        this.getUpdatedObject(this.orignalMetaData, this.metaData);
        if (this.isDataChanged) {
            let xhr = new XMLHttpRequest(), formData = new FormData();
            if (this.recordType != "Search") {
                //US-8181 //let asset = this._sharedService.selectedAsset[0].data.split('|');
                let assetDataObj = JSON.parse(this._sharedService.selectedAsset[0].data);
                formData.append('ContainerName', assetDataObj.StoragePath);//asset[0])
                formData.append('ASN', this._sharedService.selectedContainer.ASN);
                formData.append('Type', this._sharedService.selectedContainer.Type);
                formData.append('ID', this._sharedService.selectedContainer.ID);
                formData.append('ContainerID', this._sharedService.selectedContainer.ContainerID);

                if (this.recordType == "DOC" || this.recordType == "BND") {
                    formData.append('NewFileName', this.metaData.FileName);
                    formData.append('Url', this.orignalMetaData.Url);
                }
                let changes = this.newMetaData;
                if (changes) {
                    for (let prop in changes) {
                        if (changes[prop] != null) {
                            if (prop != "DocDate") {
                                formData.append(prop, changes[prop]);
                            }
                            else {
                                let date = this.getDate(changes[prop]);
                                formData.append(prop, date);
                            }
                        }
                    }

                }
            }
            else {
                let blobReference: string[] = this.extractBlobReferenceFromURL();
                if (this._sharedService.selectedContainer && this._sharedService.selectedContainer.ContainerID) {
                    formData.append('ContainerID', this._sharedService.selectedContainer.ContainerID);
                }
                //formData.append('ContainerID',(this.metaData.ContainerID).toString());
                formData.append('ContainerName', blobReference[0])
                formData.append('ASN', blobReference[1]);
                formData.append('Type', blobReference[2]);
                formData.append('ID', blobReference[3]);
                // if(this.recordType == "DOC")
                // {
                // formData.append('NewFileName', this.metaData.FileName);
                // }
                let changes = this.newMetaData;
                if (changes) {
                    for (let prop in changes) {
                        if (changes[prop] != null) {
                            if (prop != "DocDate") {
                                formData.append(prop, changes[prop]);
                            }
                            else {
                                let date = this.getDate(changes[prop]);
                                formData.append(prop, date);
                            }
                        }
                    }

                }
            }
            this.uploadUrl = `${this._sharedService.baseUrl}/api/AzureBlob/UpdateMetaData`;
            xhr.open('POST', this.uploadUrl, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
            xhr.send(formData);
            this._sharedService.showLoader("Processing...");
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    this._sharedService.hideLoader();
                    if (xhr.status === 200) {
                        this._sharedService.isDocManipulated = true;
                        this._sharedService.clearToast();
                        this.isDisplayDialog = false;
                        this._sharedService.showToast({ severity: 'success', summary: 'MetaData updated', detail: 'Document metadata has been updated successfully.' });
                        this.changeButtonsStatus(false);
                        this.updateDocumentItem.emit(this.metaData);
                        if (this.recordType != "Search") {
                            this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                            // this._sharedService.onAssetChanged(this._sharedService.selectedAsset);
                        }
                        else {
                            if (this._sharedService.selectedDocument) {
                                this._sharedService.selectedDocument.Status = this.metaData.Status;
                            }
                            //this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                        }
                    }
                    else if (xhr.status == 501) {
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'warn', summary: 'Unable to update document URL', detail: xhr.responseText });
                        this.isDisplayDialog = false;
                        this.changeButtonsStatus(false);
                        if (this.recordType != "Search") {
                            this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                        }
                    }
                    else {
                        this.isDisplayDialog = false;
                        this.changeButtonsStatus(false);
                        this._sharedService.clearToast();
                        this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while updating metadata of document.' });

                    }
                    this.cRef.markForCheck();
                }
            };
        }
        else {
            this.isDisplayDialog = false;
            this.changeButtonsStatus(false);
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'info', summary: 'No Change Applied', detail: 'Document metadata not changed.' });

        }
    }



    // #endregion

    populateDocTypeDropdown() {
        this._documentTypeService.getDocumentTypes("")
        .subscribe({
            next: (response) => {
                this.DocType = response;
            }
        });
    }
    populateQualityStatusDropdown() {
        this._qualityStatusService.getActiveQualityStatusDataForDropDown(null)
        .subscribe({
            next: (response) => {
                this.qualityStatusList = response;
            }
        });
    }
    CheckDocType(): boolean {
        if (this.isStatusModuleSelected) {
            return true;
        }
        let docTypeList: any = this.DocType.find(d => d.value == this._sharedService.selectedDocument.DocType);
        if (!docTypeList) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Document Type is not defined', detail: 'Current document Type is not defined in the system, please contact Application Administrator.' });
            return false;
        }

        return true;
    }

    populateTagsDropdown() {
        this._tagService.getAllTags()
        .subscribe({
            next: (response) => {
                this.tags = response;
            }
        });
    }

    setCalenderDateFormat() {
        this.configuration = this._sharedService.getConfiguration();
        this.dateFormat = this.configuration.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
        this.dateSeparator = this.configuration.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
        this.day = Number(this.configuration.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
        this.month = Number(this.configuration.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
        this.year = Number(this.configuration.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
        let dateParts: string[] = this.dateFormat.split(this.dateSeparator);

        if (this.year == 0) {
            this.dateFormatCalender = (dateParts[this.year].substr(0, 2) + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.day]).toLowerCase();
        }
        else if (this.year == 2) {
            if (this.month == 1) {
                this.dateFormatCalender = (dateParts[this.day] + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
            }
            else if (this.month == 0) {
                this.dateFormatCalender = (dateParts[this.month] + this.dateSeparator + dateParts[this.day] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
            }

        }
        else {
            this.dateFormatCalender = "yy-mm-dd"
        }

    }

    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }
    changeBulkButtonsStatus(status: boolean) {
        this.isBulkSubmitted = status;
    }

    //# region Model Values
    // getFileName(body?: any): string {
    //     let newString: string[] = body.Url.split('/');
    //     let fileName: string = newString[newString.length - 1];
    //     return fileName;
    // }

    getDocType(body?: any): string {
        let documentType = body.DocType;
        this.selectedDocType = documentType;
        return documentType;
    }
    getBlobName(body?: any): string {
        let blobName = body.BlobName;
        return blobName;
    }
    getDocNo(body?: any): string {
        let docNumber = body.DocNo;
        return docNumber;
    }
    getTags(body?: any): string {
        this.selectedTags = [];
        let tags = body.Tags;
        if (tags != null && tags != "") {
            if (tags.indexOf(';') != -1) {
                let allTags = tags.split(';');
                for (let i = 0; i < allTags.length; i++) {
                    this.selectedTags.push(allTags[i]);
                }
            }
            else {
                let allTags = tags.split(',');
                for (let i = 0; i < allTags.length; i++) {
                    this.selectedTags.push(allTags[i]);
                }
            }
            return tags;
        }
        else {
            return null;
        }

    }
    getComments(body?: any): string {
        let comments = body.Comments != null ? body.Comments : "";
        try {
            return decodeURIComponent(comments);
        } catch (error) {
            return comments;
        }
    }
    getWONo(body?: any): string {
        let woNumber = body.ReferenceKey ? body.ReferenceKey : "";
        return woNumber;
    }
    getDocumentDate(body?: any): Date {
        if (this.recordType != "Search") {
            if (this._sharedService.selectedDocument.DocDate != null
                && this._sharedService.selectedDocument.DocDate != "Invalid date") {
                let dateString = this._sharedService.selectedDocument.DocDate!.substr(0, 10);
                let dateParts = moment(dateString).format(this.dateFormat.toUpperCase()).split(this.dateSeparator);
                let day = Number(dateParts[Number(this.day)].substring(0, 2));
                let month = Number(dateParts[Number(this.month)]) - 1;
                let year = Number(dateParts[Number(this.year)]);
                let cellDate = new Date(year, month, day);
                return cellDate;
            }
            else {
                return null;
            }
        }
        else {
            if (body.DocDate != null && body.DocDate != "Invalid date") {
                let dateString = body.DocDate.substr(0, 10);
                let dateParts = moment(dateString).format(this.dateFormat.toUpperCase()).split(this.dateSeparator);
                let day = Number(dateParts[Number(this.day)].substring(0, 2));
                let month = Number(dateParts[Number(this.month)]) - 1;
                let year = Number(dateParts[Number(this.year)]);
                let cellDate = new Date(year, month, day);
                return cellDate;
            }
            else {
                return null;
            }
        }
    }
    getDocumentDateString(): string {

        let dateString = moment(this.metaData.DocDate).format("YYYY-MM-DD");
        //  let dateString= new Date(momentDate).toDateString();

        //let dateString = this.metaData.DocDate.toString().substr(0, 10);
        if (this.dateSeparator == "-") {
            let newStr = dateString.replace(/-/g, "");
            return newStr;
        }
        else if (this.dateSeparator == "/") {
            let newStr = dateString.replace(/\//g, "");
            return newStr;
        }
        return dateString;
    }
    getHistory(body?: any): string {

        let history: string = body.History != null ? body.History : "";
        try {
            return decodeURIComponent(history);
        } catch (error) {
            return history;
        }

    }

    getUpdatedObject(orignalMetaData: BlobEditMetaData, currentMetaData: BlobEditMetaData) {
        // let changedData = [];
        this.newMetaData = new BlobEditMetaData(null, null, null, null, null, null, null, null, null);
        let oldHistory = orignalMetaData.History != null ? orignalMetaData.History : "";
        let newHistory = '';
        let currentTimeStamp = this.getCurrentDateTime();
        for (let prop in orignalMetaData) {
            if (prop != "History" && prop != "Url") {
                if (orignalMetaData[prop] !== currentMetaData[prop]) {
                    this.isDataChanged = true;
                    this.newMetaData[prop] = currentMetaData[prop];
                    if (prop != "DocDate") {
                        if (prop != "Status") {
                            newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + prop + "," + orignalMetaData[prop] + "," + currentMetaData[prop] + "\r\n" + newHistory;
                        }
                        else {
                            newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + prop + "," + this.getQualityStatus(orignalMetaData[prop]) + "," + this.getQualityStatus(currentMetaData[prop]) + "\r\n" + newHistory;
                        }
                    }
                    else {
                        newHistory = "[" + currentTimeStamp + " by " + this._sharedService.getCurrentUserName() + "] " + prop + "," + this.getDate(orignalMetaData[prop]) + "," + this.getDate(currentMetaData[prop]) + "\r\n" + newHistory;
                    }
                }
                else if (prop == "Tags") {
                    this.newMetaData[prop] = currentMetaData[prop];
                }
                else if (prop == "DocNo" && currentMetaData[prop] != null) {
                    this.newMetaData[prop] = "null";
                }
                else if (prop == "Comments" && currentMetaData[prop] != null) {
                    this.newMetaData[prop] = currentMetaData[prop];//"null";
                }

            }

        }

        if (this.isDataChanged) {
            this.newMetaData.History = newHistory + oldHistory;
            this.newMetaData.Url = currentMetaData.FileName;
            if (this.recordType != "Search" && this.recordType != "DOC") {

                if (!this._sharedService.isDocFileNameConventionGUID() && this._sharedService.isRegenerateFileName()) {
                    this.newMetaData.NewFileName = this.getNewFileName();
                    if (this.newMetaData.Url.trim() == this.newMetaData.NewFileName.trim()) {
                        this.newMetaData.NewFileName = "";
                    }
                }
            }
            else {
                this.newMetaData.NewFileName = "";
            }
        }

    }
    getQualityStatus(qualityStatus: string) {
        if (qualityStatus && qualityStatus != "0") {
            let status = this.qualityStatusList.filter(x => x.value == qualityStatus)[0];
            return status.label;
        }
        else {
            return null;
        }
    }
    // Configuration Dependent
    //getCurrentDateTime() {
    //    let result = moment().format(this.dateFormat.toUpperCase() + " HH:mm");
    //    return result;
    //}
    // Hard Code Value
    getCurrentDateTime() {
        let result = moment().format(this.dateFormat.toUpperCase()+ " HH:mm");;
        // let result = moment().format("MM/DD/YYYY" + " HH:mm");
        return result;
    }
    getFileTimeStamp() {
        if (this.FileDateTimeStamp == "") {
            let values: string[] = this.metaData.FileName.split('-');
            this.FileDateTimeStamp = values[values.length - 1];
            return this.FileDateTimeStamp;
        }
        else {
            return this.FileDateTimeStamp;
        }
    }
    getFileCreatedTimeStamp() {
        return moment(this.metaData.DocDate).format("YYYYMMDD" + "HHmm");
    }
    getNewFileName() {


        let tempFileName = [];
        //if (this.recordType!="Search")
        //{
        //    if (this._sharedService.selectedContainer.ASN != null && this._sharedService.selectedContainer.ASN != "")
        //    {
        //        tempFileName.push(this._sharedService.selectedContainer.ASN.replace(/[\s\-\\\/]/g, ""));
        //    }
        //}
        //else
        //{
        let blobReference: string[] = this.extractBlobReferenceFromURL();

        if (blobReference[1] != null && blobReference[1] != "") {
            tempFileName.push(blobReference[1].replace(/[\s\/\\\?%\*#\^&\$!~\`:<>\.\(\)\@\|]+/g, ""));
        }
        // }
        if (this.metaData.ReferenceKey != null && this.metaData.ReferenceKey != "") {
            tempFileName.push(this.metaData.ReferenceKey.replace(/[\s\/\\\?%\*#\^&\$!~\`:<>\.\(\)\@\|]+/g, ""));
        }
        if (this.metaData.DocType != null && this.metaData.DocType != "") {
            tempFileName.push(this.getstringWithOutSpaces(this.metaData.DocType));
        }
        let docDate = this.getDocumentDateString();

        if (docDate != null && docDate != "") {
            tempFileName.push(docDate);
        }
        if (this.metaData.DocNo != null && this.metaData.DocNo != "") {
            tempFileName.push(this.metaData.DocNo.replace(/[\s\/\\\?%\*#\^&\$!~\`:<>\.\(\)\@\|]+/g, ""));
        }

        //R.A. 30-12-2019 -- fileTimeStamp dhould be appended at server-side
        // let fileTimeStamp = this.getFileCreatedTimeStamp(); // this.getFileTimeStamp();
        // if (fileTimeStamp != null && fileTimeStamp != "") {
        //     tempFileName.push(fileTimeStamp);
        // }
        this.NewFileName = tempFileName.join("_");
        // this.NewFileName = this._sharedService.selectedContainer.ASN + "-" + this.metaData.ReferenceKey + "-" + this.metaData.DocType + "-" + this.getDocumentDateString() + "-" + this.metaData.DocNo + "-" + this.getFileTimeStamp();
        //}
        //else {
        //    this.NewFileName = this._sharedService.selectedContainer.ASN + "-" + this.metaData.DocType + "-" + this.getDocumentDateString() + "-" + this.metaData.DocNo + "-" + this.getFileTimeStamp();
        //}

        return this.NewFileName;
    }
    getDate(date: Date): string {
        if (date != null) {
            //let newDate = moment(date).format(this.dateFormat.toUpperCase());
            // let newDate = moment(date).format("YYYY-MM-DD");
            //return new Date(newDate).toDateString();
            let typeCheck = date;
            if (typeCheck.toString().length == 10) {
                let dateString = date.toString().substring(0, 10);
                let dateParts = dateString.split(this.dateSeparator);
                let day = Number(dateParts[Number(this.day)].substring(0, 2));
                let month = Number(dateParts[Number(this.month)]) - 1;
                let year = Number(dateParts[Number(this.year)]);
                return new Date(year, month, day).toDateString();
            }
            else {
                let newDate = moment(date).format("YYYY-MM-DD");
                // return new Date(newDate).toDateString();
                return newDate;
            }
        }
        return null;
    }

    getstringWithOutSpaces(value: string): string {
        let newStr = value.replace(/[\s\-\/\\\?%\*#\^&\$!~\`:<>\.\(\)\@\|]+/g, "");
        return newStr;
    }
    extractBlobReferenceFromURL(): string[] {
        let newString: string[] = [];
        if (!this.isStatusModuleSelected) {
            newString = decodeURIComponent(this._sharedService.selectedDocument.Url).split('?')[0].split('/');
        }
        else {
            newString = this._sharedService.getStatusModuleSelectedDocument().split('?')[0].split('/');
        }
        let blobReference: string[] = [];
        blobReference.push(newString[newString.length - 5]); // Container Name 0
        blobReference.push(newString[newString.length - 4]); // ASN 1
        blobReference.push(newString[newString.length - 3]); // Type 2
        blobReference.push(newString[newString.length - 2]); // ID 3
        blobReference.push(newString[newString.length - 1]); // File
        return blobReference;
    }
    //#endregion

    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }
        switch (this.recordType) {
            case 'MXR':
                this.canEditMetaDataReadonly = this._sharedService.UserRole.Records_MXR_Readonly;
                this.canEditMetaData = this._sharedService.UserRole.Records_MXR_Doc_Edit_Metadata;
                this.canMXRQualityStatus = this._sharedService.UserRole.Records_MXR_Doc_Quality_Status;
                this.processMXRQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('MX_Records_Quality_Process');
                break;
            case 'LOG':
                this.canEditMetaDataReadonly = this._sharedService.UserRole.Records_LOG_Readonly;
                this.canEditMetaData = this._sharedService.UserRole.Records_LOG_Doc_Edit_Metadata;
                this.canLOGQualityStatus = this._sharedService.UserRole.Records_LOG_Doc_Quality_Status;
                this.processLOGQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('Log_Books_Quality_Process');
                break;
            case 'GEN':
                this.canEditMetaDataReadonly = this._sharedService.UserRole.Records_GEN_Readonly;
                this.canEditMetaData = this._sharedService.UserRole.Records_GEN_Doc_Edit_Metadata;
                this.canGENQualityStatus = this._sharedService.UserRole.Records_GEN_Doc_Quality_Status;
                this.processGENQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('General_Quality_Process');
                break;
            case 'DOC':
                this.canEditMetaDataReadonly = this._sharedService.UserRole.Records_DOC_Readonly;
                this.canEditMetaData = this._sharedService.UserRole.Records_DOC_Doc_Edit_Metadata;
                this.canDOCQualityStatus = this._sharedService.UserRole.Records_DOC_Doc_Quality_Status;
                this.processDOCQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('Documents_Quality_Process');
                break;
            case 'BND':
                this.canEditMetaDataReadonly = this._sharedService.UserRole.Transition_BND_Readonly;
                this.canEditMetaData = this._sharedService.UserRole.Transition_BND_Doc_Edit_Metadata;
                this.canBNDQualityStatus = this._sharedService.UserRole.Transition_BND_Doc_Quality_Status;
                this.processBNDQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('Binders_Quality_Process');
                break;
            case 'Search':
                // Bug 8587: ‘Update’ button is disabled on Status > Edit Meta data popup
                this.canEditMetaDataReadonly = this._sharedService.UserRole.Records_OCRSearch_Readonly;
                this.canEditMetaData = this._sharedService.UserRole.Records_OCRSearch_Edit_Metadata;
                if (this._router.url.indexOf("record/search") != -1) {
                    this.canEditMetaDataReadonly = this._sharedService.UserRole.Records_OCRSearch_Readonly;
                    this.canEditMetaData = this._sharedService.UserRole.Records_OCRSearch_Edit_Metadata;
                    this.canCopyMoveDocument=this._sharedService.UserRole.Records_MXR_Doc_CopyMove
                }
                else if (this._router.url.indexOf("transition/search") != -1) {
                    this.canEditMetaDataReadonly = this._sharedService.UserRole.Transition_OCRSearch_Readonly;
                    this.canEditMetaData = this._sharedService.UserRole.Transition_OCRSearch_Edit_Metadata;
                    this.canCopyMoveDocument=this._sharedService.UserRole.Transition_BND_Doc_CopyMove
                }
                //MXR
                this.canMXRQualityStatus = this._sharedService.UserRole.Records_MXR_Doc_Quality_Status;
                this.processMXRQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('MX_Records_Quality_Process');

                //LOG
                this.canLOGQualityStatus = this._sharedService.UserRole.Records_LOG_Doc_Quality_Status;
                this.processLOGQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('Log_Books_Quality_Process');

                //GEN
                this.canGENQualityStatus = this._sharedService.UserRole.Records_GEN_Doc_Quality_Status;
                this.processGENQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('General_Quality_Process');

                //DOC
                this.canDOCQualityStatus = this._sharedService.UserRole.Records_DOC_Doc_Quality_Status;
                this.processDOCQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('Documents_Quality_Process');

                //BND
                this.canBNDQualityStatus = this._sharedService.UserRole.Transition_BND_Doc_Quality_Status;
                this.processBNDQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations('Binders_Quality_Process');
                break;
        }


    }
    isQualityStatusAllowed(qualityStatus: string) {
        let permission: boolean = false;
        try {
            switch (qualityStatus) {
                case 'MXR':
                    permission = this._sharedService.getQualityStatusConfigurations('MX_Records_Quality_Process');
                    break;
                case 'LOG':
                    permission = this._sharedService.getQualityStatusConfigurations('Log_Books_Quality_Process');
                    break;
                case 'GEN':
                    permission = this._sharedService.getQualityStatusConfigurations('General_Quality_Process');
                    break;
                case 'DOC':
                    permission = this._sharedService.getQualityStatusConfigurations('Documents_Quality_Process');
                    break;
                case 'BND':
                    permission = this._sharedService.getQualityStatusConfigurations('Binders_Quality_Process');
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log(error);
        }
        return permission;
    }
    onCloseDate() {
        var docNumberMetaId = document.getElementById('docNumberMetaId');
        docNumberMetaId.focus();
    }
    copyMoveDocuments() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        this.resetContainerModel();
       // this.selectedComboCatergorySelection = this._sharedService.recordType;
       // this.displayMetaMoveCopyDlg = true;

        let selDocuments =  this._sharedService.selectedDocumentforMetaData;//this.grid .api.getSelectedRows();
        if (selDocuments.length > 0) {
            this.resetContainerModel();
            this.selectedComboCatergorySelection = this._sharedService.recordType;
            //  this.setContainerGridColumns();
            this.displayMetaMoveCopyDlg = true;
            this.conRowSelected = false;
        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No document(s) selected', detail: 'Select document(s) to Copy/Move' });
        }

    }
    onChangeAsset(event: any) {
        this.conRowSelected = false;
        this.conGridData = [];
        //US-8181 //let assetid = event.value.split('|')[3].trim();
        let assetid = JSON.parse(event.value).AssetID;
        this.populateCategoryByAssetID(assetid);
    }
    populateCategoryByAssetID(assetID: string): void {
        this.categories = [];
        this._assetService.getAssetbyID(assetID)
        .subscribe({
            next: (response) => {
                this.categories = [];
        
                if (response.MXRecords) {
                    this.categories.push({ value: 'MXR', label: 'MX Records' });
                }
                if (response.LogBooks) {
                    this.categories.push({ value: 'LOG', label: 'Log Books' });
                }
                if (response.General) {
                    this.categories.push({ value: 'GEN', label: 'General' });
                }
                if (response.Documents) {
                    this.categories.push({ value: 'DOC', label: 'Documents' });
                }
                if (response.Binders) {
                    this.categories.push({ value: 'BND', label: 'Binders' });
                }
            },
            error: (error) => {
                this.categories = [];
                console.log(error);
            }
        });
    }
    onChangeCategory(event: any) {
        this.conRowSelected = false;
        this.conGridData = [];
        this.populateContainerType(event.value);
        this.selectedComboCatergorySelection = event.value;
        this.setContainerGridColumns();
    }
    populateContainerType(category: string) {
        this.conTypes = [];
        let body: container = {
            Category: category
        }
        this._containerService.getContainerType(body)
        .subscribe({
            next: (response) => {
                let res = response;
                for (let i = 0; i < res.length; i++) {
                    this.conTypes.push({ value: res[i].Type, label: res[i].Type })
                }
            },
            error: (error) => {
                this.conGridData = [];
                this.conTotalCount = 0;
            }
        });
    }
    populateAsset() {
        this._assetService
            .getAssetWithAssetIdByUserRole()
            .subscribe({
                next: (response) => {
                    this.assets = response;
                },
                error: (error) => {
                    this.assets = [];
                    console.log(error);
                }
            });
    }
    pageRange: string
  selectedPageRange(pageRange, containerForm: NgForm): void {
    this.pageRange = pageRange;
    this.btnMoveCopyDocumentClick(true, containerForm);
  }

    populateContainerGrid(): void {

        // this._containerService.getContainerData(this.containerModel).subscribe( response => {
        this._containerService.getFilteredContainer(this.containerModel)
        .subscribe({
            next: (response) => {
                this.setContainerGridColumns();
                this.conGridData = response;
                this.cRef.markForCheck();
                //    this.conTotalCount = this.conGridData.length;
                //this.gridOptions.api.sizeColumnsToFit();
            },
            error: (error) => {
                this.conGridData = [];
                this.conTotalCount = 0;
                console.log(error);
            }
        });
    }

    prepareDocumentGridColumns(): void {
        var appConfigs = this._sharedService.getConfiguration();
        if (appConfigs != null) {
            var appConfigs = this._sharedService.getConfiguration();
            if (appConfigs != null) {
                this.Container_MXR_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_MX_Records_Column_Setting')[0].Value);
                this.Container_LOG_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Log_Books_Column_Setting')[0].Value);
                this.Container_GEN_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_General_Column_Setting')[0].Value);
                this.Container_DOC_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Documents_Column_Setting')[0].Value);
                this.Container_BND_Columns = JSON.parse(appConfigs.filter(x => x.Key == 'Container_Binders_Column_Setting')[0].Value);
                this.Container_MXR_Sort_By = appConfigs.filter(x => x.Key == 'Container_MXR_Sort_By')[0].Value;
                this.Container_LOG_Sort_By = appConfigs.filter(x => x.Key == 'Container_LOG_Sort_By')[0].Value;
                this.Container_GEN_Sort_By = appConfigs.filter(x => x.Key == 'Container_GEN_Sort_By')[0].Value;
                this.Container_DOC_Sort_By = appConfigs.filter(x => x.Key == 'Container_DOC_Sort_By')[0].Value;
                this.Container_BND_Sort_By = appConfigs.filter(x => x.Key == 'Container_BND_Sort_By')[0].Value;
            }
        }
    }

    setContainerGridColumns(): void {
        if (!this.selectedComboCatergorySelection) {
            return;
        }
        this.conColumnDefs = [];
        columnDefs_1 = [];
        var containerGridCols: any[];
        var container_Col_Sort_On_By: string = '';
        if (this.selectedComboCatergorySelection.toUpperCase() == 'MXR') {
            containerGridCols = this.Container_MXR_Columns;
            container_Col_Sort_On_By = this.Container_MXR_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'LOG') {
            containerGridCols = this.Container_LOG_Columns;
            container_Col_Sort_On_By = this.Container_LOG_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'GEN') {
            containerGridCols = this.Container_GEN_Columns;
            container_Col_Sort_On_By = this.Container_GEN_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'DOC') {
            containerGridCols = this.Container_DOC_Columns;
            container_Col_Sort_On_By = this.Container_DOC_Sort_By;
        }
        else if (this.selectedComboCatergorySelection.toUpperCase() == 'BND') {
            containerGridCols = this.Container_BND_Columns;
            container_Col_Sort_On_By = this.Container_BND_Sort_By;
        }
        if(containerGridCols.length >0)
        containerGridCols.forEach((col, index) => {
            let _filter = 'set';
            if (col.FieldName == 'Date' || col.FieldName == 'EndDate' || col.FieldName == 'ScannedOn') {
                _filter = 'agDateColumnFilter';
            }
            let gridCol: any;
            if (col.FieldName == 'ContainerQualityStatus') {
                gridCol = {
                    headerName: col.ColumnLabel,
                    field: 'QualityStatus',//'QualityStatus---ID',//24-02-2021
                    width: col.Width,
                    filter: _filter,
                    tooltipField: col.FieldName,
                    menuTabs: ['filterMenuTab'],
                    hide: !col.IsVisible,
                    filterParams: this.gridStatusFilterParams,
                };
            }
            else {
                gridCol = {
                    headerName: col.ColumnLabel,
                    field: col.FieldName,
                    width: col.Width,
                    filter: _filter,
                    tooltipField: col.FieldName,
                    menuTabs: ['filterMenuTab'],
                    hide: !col.IsVisible
                };
            }
            if (col.FieldName == 'Date' || col.FieldName == 'EndDate' || col.FieldName == 'ScannedOn') {
                gridCol.filterParams =
                {
                    comparator: this.dateComparater,
                    inRangeInclusive: 'true'
                }
                gridCol.valueFormatter = function (params: any) {
                    if (params.value != '' && params.value != null) {
                        let dateString = params.value.substring(0, 10);
                        return moment(dateString).format(dateFormatF.toUpperCase());
                    }
                }
            }
            else if (col.FieldName == 'ContainerQualityStatus') {
                gridCol.filterParams = {
                    cellRenderer: this.statusCellRenderer, // formats filter values
                }
                gridCol.cellRenderer = this.statusCellRenderer;
            }
            columnDefs_1.push(gridCol);
        });
        this.conColumnDefs = columnDefs_1;
        let sortOnCol = container_Col_Sort_On_By.split("||")[0];
        let orderBy = container_Col_Sort_On_By.split("||")[1];
        if (sortOnCol == "ContainerQualityStatus") {
            sortOnCol = "QualityStatus";
        }
        this.conColumnDefs.forEach(function (col, index) {
            if (col.field == sortOnCol) {
                this.conColumnDefs[index].sort = orderBy;
            }
        }.bind(this));
    }
    populateQualityStatus() {
        let qualityStatusListValues = this._sharedService.getContainerQualityStatusList();
        if (qualityStatusListValues == null || qualityStatusListValues.length == 0) {
            this._qualityStatusService.getActiveQualityStatusData(null)
            .subscribe({
                next: (response) => {
                    try {
                        this.qualityStatusList = response;
                        statusList = response;
                    } catch (ex) {
                        this.qualityStatusList = response;
                        statusList = response;
                        console.log(ex);
                    }
                },
                error: (error) => {
                }
            });
        } else {
            this.qualityStatusList = qualityStatusListValues;
            statusList = qualityStatusListValues;
        }
    }
    gridStatusFilterParams = { cellRenderer: this.statusCellRenderer };
    statusCellRenderer(params: any) {
        let html: string = '';
        var eDiv = document.createElement('div');
        if (params.value) {
            try {
                let currentStatus: any = statusList.filter(x => x.QualityStatusID == params.value)[0];
                if (currentStatus && currentStatus.Name) {
                    eDiv.style.background = currentStatus.CSSClass;
                    html = html + currentStatus.Name;
                } else {
                    let currentStatus: any = statusList.filter(x => x.Name == params.value)[0];
                    if (currentStatus && currentStatus.Name) {
                        eDiv.style.background = currentStatus.CSSClass;
                        html = html + currentStatus.Name;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            return params.value;
        }


        eDiv.style.height = '100%';
        eDiv.style.width = '100%';
        eDiv.style.color = 'white';
        eDiv.style.textAlign = 'left';
        eDiv.style.paddingLeft = '5px';
        eDiv.innerHTML = html;
        return eDiv;
    }
    statusCellRendererEdit(params: any) {
        let html: string = '';
        var eDiv = document.createElement('div');
        if (params.value) {
            try {
                let currentStatus: any = statusList.filter(x => x.QualityStatusID == params.value)[0];
                if (currentStatus && currentStatus.Name) {
                    eDiv.style.background = currentStatus.CSSClass;
                    html = html + currentStatus.Name;
                } else {
                    let currentStatus: any = statusList.filter(x => x.Name == params.value)[0];
                    if (currentStatus && currentStatus.Name) {
                        eDiv.style.background = currentStatus.CSSClass;
                        html = html + currentStatus.Name;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            return params.value;
        }


        eDiv.style.height = '100%';
        eDiv.style.width = '100%';
        eDiv.style.color = 'white';
        eDiv.style.textAlign = 'left';
        eDiv.style.paddingTop = '6px';
        eDiv.style.paddingLeft = '5px';
        eDiv.innerHTML = html;
        return eDiv;
    }
    // setContainerGridColumns(): void {
    //     this.conColumnDefs = [];

    //     this.conColumnDefs =
    //         [
    //             {
    //                 headerName: this._sharedService.getContainerLabel(this.selectedComboCatergorySelection, 'Type'), field: 'Type', filter: 'set', tooltipField: 'Description', menuTabs: ['filterMenuTab'], minWidth: 70,
    //                 //, filterParams: { comparator: this.ContainerTypeComparater }
    //             },
    //             {
    //                 headerName: this._sharedService.getContainerLabel(this.selectedComboCatergorySelection, 'Date'), field: 'Date', filter: 'agDateColumnFilter', filterParams:
    //                 {
    //                     comparator: this.dateComparater,
    //                     inRangeInclusive: 'true'
    //                 }, valueFormatter: function (params: any) {
    //                     if (params.value != "" && params.value != null) {

    //                         let dateString = params.value.substring(0, 10);
    //                         return moment(dateString).format(dateFormatF.toUpperCase());
    //                     }
    //                 }, sort: 'desc', menuTabs: ['filterMenuTab'], minWidth: 90,
    //             },
    //             {
    //                 headerName: this._sharedService.getContainerLabel(this.selectedComboCatergorySelection, 'EndDate'), field: 'EndDate', filter: 'agDateColumnFilter', filterParams: {
    //                     comparator: this.dateComparater,
    //                     inRangeInclusive: 'true'

    //                 }, valueFormatter: function (params: any) {
    //                     if (params.value != "" && params.value != null) {
    //                         let dateString = params.value.substring(0, 10);
    //                         return moment(dateString).format(dateFormatF.toUpperCase());
    //                     }
    //                 }, menuTabs: ['filterMenuTab'], minWidth: 90,
    //             },
    //         ];
    //     if (this.selectedComboCatergorySelection == 'MXR') {
    //         this.conColumnDefs.push({ headerName: this._sharedService.getContainerLabel(this.selectedComboCatergorySelection, 'ReferenceKey'), field: 'ReferenceKey', tooltipField: 'ReferenceKey', menuTabs: ['filterMenuTab'], minWidth: 90, filter: 'set' });
    //     } else if (this.selectedComboCatergorySelection == 'LOG') {
    //         this.conColumnDefs.push({ headerName: this._sharedService.getContainerLabel(this.selectedComboCatergorySelection, 'Description'), field: 'Description', tooltipField: 'Description', menuTabs: ['filterMenuTab'], minWidth: 110, filter: 'set' });
    //     } else if (this.selectedComboCatergorySelection == 'GEN') {
    //         this.conColumnDefs.push({ headerName: this._sharedService.getContainerLabel(this.selectedComboCatergorySelection, 'ReferenceKey'), field: 'ReferenceKey', tooltipField: 'ReferenceKey', menuTabs: ['filterMenuTab'], minWidth: 90, filter: 'set' });
    //     }
    //     else if (this.selectedComboCatergorySelection == 'Search') {
    //         this.conColumnDefs.push({ headerName: "Work Pack", field: 'ReferenceKey', tooltipField: 'ReferenceKey', menuTabs: ['filterMenuTab'], minWidth: 90, filter: 'set' });
    //     }
    // }
    dateComparater(filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue != null) {
            // let dateString = cellValue.substring(0, 10).split(dateSeparatorS);
            let dateString = cellValue.substring(0, 10);
            var dateParts = moment(dateString).format(dateFormatF.toUpperCase()).split(dateSeparatorS);
            var day = Number(dateParts[Number(dayD)].substring(0, 2));
            var month = Number(dateParts[Number(monthM)]) - 1;
            var year = Number(dateParts[Number(yearY)]);
            var cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        } else {
            return -1;
        }
    }
    getDateFormatInfo(): any {

        if (this.dateConfig != undefined) {
            dateFormatF = this.dateConfig.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
            dateSeparatorS = this.dateConfig.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
            dayD = Number(this.dateConfig.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
            monthM = Number(this.dateConfig.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
            yearY = Number(this.dateConfig.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
        } else {
            dateFormatF = localStorage.getItem('DateFormat');
            dateSeparatorS = localStorage.getItem('DateSeparater');
            dayD = Number(localStorage.getItem('DayIndex'));
            monthM = Number(localStorage.getItem('MonthIndex'));
            yearY = Number(localStorage.getItem('YearIndex'));

        }
    }
    onContainerRowClick(event: any) {
        this.conRowSelected = true;
    }
    btnCancelContainerClick(MetacontainerForm: NgForm) {
        this.displayMetaMoveCopyDlg = false;
        if (MetacontainerForm != null) {
            MetacontainerForm.resetForm();
        }
        // this.showInboxLinkDptDlg = false;
        // this.showStatusLinkDptDlg = false;
       

    }
    
    btnMoveCopyDocumentClick(isCopy: boolean, containerForm: NgForm) {
        //US-8181 //if (this.containerModel.asn.split('|')[4] == 'True')
        //alert(this.recordType);
        if (JSON.parse(this.containerModel.asn).ReadOnly) {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'Readonly Asset', detail: 'Cannot Move/Copy documnet(s) to readonly asset.' });
            return;
        }
        this._sharedService.showLoader("Processing...");
        let selContainer = this.conGridOptions.api.getSelectedRows();
        if (selContainer.length > 0) {
            //set Target ContainerName and IsCopy Flag
            //US-8181 //selContainer[0].ContainerName = this.containerModel.asn.split('|')[0];
            selContainer[0].ContainerName = JSON.parse(this.containerModel.asn).StoragePath;
            selContainer[0].isCopy = isCopy;
            
            this._OCRdocument = this._sharedService.selectedDocumentforMetaData;
            //Set Source Container Name, Type, ID, ASN and save File Name in Url field
            for (let i = 0; i < this._OCRdocument.length; i++) {
                this._OCRdocument[i].ContainerName = this._OCRdocument[i].Container;//this.selectedContainer.ContainerName;
                this._OCRdocument[i].ContainerType = this._OCRdocument[i].ContainerType;
                this._OCRdocument[i].ContainerID = this._OCRdocument[i].ContainerID;
                this._OCRdocument[i].ASN = this._OCRdocument[i].ASN;
                this._OCRdocument[i].ID=this._OCRdocument[i].ContainerID;
                this._OCRdocument[i].Type = this._OCRdocument[i].ContainerType;
                //selDocuments[i].Url = this._sharedService.getFileNameFromURL(selDocuments[i].Url, true); // this.getFileName(selDocuments[i].Url);
                this._OCRdocument[i].Url =  this._OCRdocument[i].Url;
                //if(selDocuments[i].VideoUrl!=null){
                //    selDocuments[i].BlobName =selDocuments[i].VideoUrl;
                // }
                //  else{
                    this._OCRdocument[i].BlobName = this._sharedService.getFileNameFromURL( this._OCRdocument[i].Url, true);

            }

            this.selectedTargetContainer = selContainer[0];
            this.selectedDocuments =  this._OCRdocument;

            let bodyData = {
                Container: selContainer[0],
                BlobList:  this._OCRdocument,
                // MoveActionForDocStatus: this.moveActionForDocStatus,
                // MoveActionForDocInbox: this.moveActionForDocInbox,
                MoveActionForDependencies: this.moveActionForDependencies,
                MoveActionForInvalidFiles:this.moveActionForInvalidFiles,
                MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                recordType:this.recordType,
                PageRange:null
            };

            if (!isCopy)//move
            {
                // get document dependencies by communications & statuses
                this._azureBlobService.getDocumentReferences(bodyData)
                .subscribe({
                    next: (response) => {
                        this.docRefToInbox = [];
                        this.docRefToStatus = [];
        
                        this.docRefToInboxGridData = [];
                        this.docRefToStatusGridData = [];
                        // this.moveActionForDocStatus = '';
                        // this.moveActionForDocInbox = '';
        
                        this.docRefToDependencies = [];
                        this.docRefToDependenciesGridData = [];
                        this.docInvalidOrMissingInfoGridData = [];
                        this.docImageToPDFGridData = [];
                        this.moveActionForDependencies = '';
                        this.moveActionForInvalidFiles = '';
                        this.moveActionForImageFileToPDF = '';
        
        
                        this.docDependencies = response;
                        this.docRefToInbox = this.docDependencies.filter(item => item.Comms.length > 0);
                        this.docRefToStatus = this.docDependencies.filter(item => item.Tasks.length > 0);
                        //this.docRefToDependencies = this.docDependencies.filter(item => item.Tasks.length > 0 || item.Comms.length > 0);
                        let invalidDocs = [];
                        this.docDependencies.forEach((element, index) => {
                            if (element.HasMissingDataOrInvalidBlob) {
                                element.AzureBlobEntity.HasMissingDataOrInvalidBlob = element.HasMissingDataOrInvalidBlob;
                                invalidDocs.push(element.AzureBlobEntity);
                            }
                        });
        
                        if (invalidDocs.length > 0) {
                            this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Document(s) references found', detail: 'Some document(s) are invalid or have missing information. Please verify..' });
        
                            // create observable
                            this.simpleObservable = new Observable((observer) => {
                                // observable execution
                                let bodyDataNew = {
                                    Container: this.selectedTargetContainer,
                                    BlobList: this.selectedDocuments,
                                    MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                                    MoveActionForDependencies: this.moveActionForDependencies,
                                    MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                                    recordType:this.recordType,
                                };
                                observer.next(bodyDataNew);
                                observer.complete();
                            });
        
                            if (invalidDocs.length > 0) {
                                //Need to understand this
                                // this.initInvalidOrMissingInfoDocGrid();
                                // invalidDocs.forEach((element, index) => {
                                //     this.docInvalidOrMissingInfoGridData.push(element);
                                // });
                                // this.showInvalidOrMissingInfoDlg = true;
                            }
                            else {
                                this.moveActionForInvalidFiles = "N/A";
                            }
        
                            this._sharedService.clearToast();
                            this._sharedService.hideLoader();
                            this.displayMetaMoveCopyDlg = false;
                        }
                        else {
                            this.moveActionForInvalidFiles = "N/A";
                            bodyData.MoveActionForDependencies = this.moveActionForDependencies;
                            bodyData.MoveActionForInvalidFiles = this.moveActionForInvalidFiles;
                            bodyData.MoveActionForImageFileToPDF = this.moveActionForImageFileToPDF;
                            //R.A. this.moveCopyBlobService(bodyData, containerForm);
                        }
        
                        let dependentDocs = [];
                        this.docDependencies.forEach((element, index) => {
                            if ((element.HasTasks || element.HasComms) && (!element.HasMissingDataOrInvalidBlob)) {
                                element.AzureBlobEntity.HasTasks = element.HasTasks;
                                element.AzureBlobEntity.HasComms = element.HasComms;
                                dependentDocs.push(element.AzureBlobEntity);
                            }
                        });
        
                        if (dependentDocs.length > 0 || this.docRefToStatus.length > 0 || this.docRefToInbox.length > 0) {
                            this._sharedService.showToast({ severity: 'warn', summary: 'Document(s) references found', detail: 'Some document(s) have referenced with Inbox/ Status. Please verify..' });
        
                            // create observable
                            this.simpleObservable = new Observable((observer) => {
                                // observable execution
        
                                let bodyDataNew = {
                                    Container: this.selectedTargetContainer,
                                    BlobList: this.selectedDocuments,
                                    MoveActionForDependencies: this.moveActionForDependencies,
                                    MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                                    MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                                    recordType:this.recordType,
                                };
                                observer.next(bodyDataNew);
                                observer.complete();
                            });
        
                            if (dependentDocs.length > 0) {
        
                                // this.initDependentLinkGridDpt();
                                // dependentDocs.forEach((element, index) => {
                                //     this.docRefToDependenciesGridData.push(element);
                                // });
                                // this.showDependencyDlg = true;
                            }
                            else {
                                this.moveActionForDependencies = "N/A";
                            }
        
                            this._sharedService.clearToast();
                            this._sharedService.hideLoader();
                            this.displayMetaMoveCopyDlg = false;
        
                        }
                        else {
                            this.moveActionForDependencies = "N/A";
                            bodyData.MoveActionForDependencies = this.moveActionForDependencies;
                            bodyData.MoveActionForInvalidFiles = this.moveActionForInvalidFiles;
                            bodyData.MoveActionForImageFileToPDF = this.moveActionForImageFileToPDF;
                            //R.A. this.moveCopyBlobService(bodyData, containerForm);
                        }
        
                        let imageToPDFDocs = [];
                        this.docDependencies.forEach((element, index) => {
                            if (element.IsImage && !element.HasMissingDataOrInvalidBlob) {
                                element.AzureBlobEntity.IsImage = element.IsImage;
                                imageToPDFDocs.push(element.AzureBlobEntity);
                            }
                        });
        
                        if (imageToPDFDocs.length > 0) {
                            this._sharedService.showToast({ severity: 'warn', summary: 'Image Document(s) found', detail: 'These image document(s) will be converted to PDF. Please verify...' });
        
                            // create observable
                            this.simpleObservable = new Observable((observer) => {
                                // observable execution
                                let bodyDataNew = {
                                    Container: this.selectedTargetContainer,
                                    BlobList: this.selectedDocuments,
                                    MoveActionForImageFileToPDF: this.moveActionForImageFileToPDF,
                                    MoveActionForDependencies: this.moveActionForDependencies,
                                    MoveActionForInvalidFiles: this.moveActionForInvalidFiles,
                                    recordType:this.recordType,
                                };
                                observer.next(bodyDataNew);
                                observer.complete();
                            });
        
        
        
                            if (imageToPDFDocs.length > 0) {
        
                                // this.initImageToPDFGrid();
                                // imageToPDFDocs.forEach((element, index) => {
                                //     this.docImageToPDFGridData.push(element);
                                // });
                                // this.showImageToPDFDlg = true;
                            }
                            else {
                                this.moveActionForImageFileToPDF = "N/A";
                            }
        
                            this._sharedService.clearToast();
        
                            this._sharedService.hideLoader();
        
        
                            this.displayMetaMoveCopyDlg = false;
        
                        }
                        else {
                            this.moveActionForImageFileToPDF = 'N/A';
                            bodyData.MoveActionForDependencies = this.moveActionForDependencies;
                            bodyData.MoveActionForInvalidFiles = this.moveActionForInvalidFiles;
                            bodyData.MoveActionForImageFileToPDF = this.moveActionForImageFileToPDF;
                            //R.A. this.moveCopyBlobService(bodyData, containerForm);
                        }
        
        
                        if (invalidDocs.length == 0 && dependentDocs.length == 0 && imageToPDFDocs.length == 0 &&
                            this.moveActionForInvalidFiles == "N/A" && this.moveActionForDependencies == "N/A" && this.moveActionForImageFileToPDF == 'N/A') {
                            this.moveCopyBlobService(bodyData, containerForm);
                        }
                    },
                    error: (error) => {
                        console.log(error);
                    }
                });
            }
            else {
                this.moveCopyBlobService(bodyData, containerForm);
            }

            // if (isCopy) {
            //     ////R.A. this.moveCopyBlobService(bodyData, containerForm);
            // }
        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No container selected', detail: 'Select a container to Copy/Move document(s)' });
        }
    }
    moveCopyBlobService(bodyData: any, containerForm: any) {
        this._sharedService.showLoader("Processing...");
        if (this.pageRange != null && bodyData.PageRange == null){
            bodyData.PageRange=this.pageRange;
        }
        this._azureBlobService.moveCopyBlob(bodyData)
        .subscribe({
            next: (response) => {
                this._sharedService.clearToast();
                this._sharedService.hideLoader();
                this._sharedService.showToast({ severity: 'success', summary: 'Document(s) moved/copied', detail: 'Document(s) have been moved/copied successfully.' });
               // this.refreshDocGrid();
                this.displayMetaMoveCopyDlg = false;
                if (containerForm != null) {
                    containerForm.resetForm();
                }
                this.cRef.markForCheck();
            },
            error: (error) => {
                if (error.status == 409) {
                    this._sharedService.clearToast();
                    //let resp = response;
                    this._sharedService.hideLoader();
                    //  this.refreshDocGrid();
                    if (this.containerModel.category == 'DOC' || this.containerModel.category == 'BND') {
                        this._sharedService.showToast({ severity: 'success', summary: 'Document(s) moved/copied', detail: 'Document(s) have been moved/copied successfully.' });
                    }
                    else {
                        //commented due to pre-action choosen 
                        //this._sharedService.showToast({ severity: 'warn', summary: 'Some document(s) not moved', detail: 'Document(s) are attached to status row(s), please detach them before moving..' });
                        this._sharedService.showToast({ severity: 'success', summary: 'Document(s) moved/copied', detail: 'Document(s) have been moved/copied successfully.' });
                    }
                    this.displayMetaMoveCopyDlg = false;
                    //containerForm.resetForm();
                    if (containerForm != null) {
                        containerForm.resetForm();
                    }
                   // this.refreshDocGrid();
                }
                else if (error.status == 501) {
                    this._sharedService.clearToast();
                    this._sharedService.hideLoader();
                   // this.refreshDocGrid();
                    this._sharedService.showToast({ severity: 'warn', summary: 'Unable to update document URL', detail: error.error });
                    this.displayMetaMoveCopyDlg = false;
                    if (containerForm != null) {
                        containerForm.resetForm();
                    }
                }
                else if (error.status == 412) {
                    this._sharedService.clearToast();
                    this._sharedService.hideLoader();
                    //this.refreshDocGrid();
                    this._sharedService.showToast({ severity: 'warn', summary: 'Some document(s) not moved', detail: error.error });
                    this.displayMetaMoveCopyDlg = false;
                    if (containerForm != null) {
                        containerForm.resetForm();
                    }
                }
                else if (error.status== 500)
                {
                    this._sharedService.hideLoader();
                    this._sharedService.showToast({ severity: 'warn', summary: 'Invalid Page Range', detail: 'Some document(s) have invalid page range' });
                } else {
                    this._sharedService.hideLoader();
                    this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while moving/coping document(s).' });
                }
            }
        });
    }

    resetContainerModel() {
        this.containerModel = new SearchContainerModel("", null, null, false);
        this.conGridData = [];
    }
}
