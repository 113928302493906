<form *ngIf="display" #changePswdForm="ngForm">
<p-dialog contentStyleClass="no-overflow-y" *ngIf="display" id="changepswdDlg" [(visible)]="display" appendTo="body" showEffect="fade" [modal]="true"
  [style]="{width: '500px', height: '30vh'}" [resizable]="false">

  <!--appendTo="body" showEffect="fade" [modal]="true"-->
  <!--[modal]="true"-->
  <!--showEffect="fade"-->
  <ng-template pTemplate="header" >
    Change Password
  </ng-template>
  
    <!--(ngSubmit)="onSubmit(changePswdForm)"-->
    <div>
      <!--class="upload-dialog" style="overflow-y: hidden !important;overflow-x:hidden !important"-->
      <div class="smart-form">
        <div class="row custom-bottom-padding">
          <label class="label">
            Current Password<span [hidden]="model.oldPassword" class="text-danger">
              *
            </span>
          </label>
          <div class="input-group">
            <input id="oldpassword" type="password" name="oldpassword" placeholder="&nbsp;Current Password"
              [(ngModel)]="model.oldPassword" required #oldpassword="ngModel" class="form-control" />
            <div class="input-group-addon"><i class="fa fa-user"></i></div>

          </div>
        </div>
        <!--</section>-->
        <div class="row custom-bottom-padding">

          <label class="label">
            New Password<span [hidden]="model.newPassword" class="text-danger">
              *
            </span><span class="text-danger" *ngIf="model.newPassword && model.newPassword.length<3" > (Password cannot
              be less than 3 characters) </span>
          </label>
          <div class="input-group">
            <input id="newpassword" type="password" name="newpassword" placeholder="&nbsp;New Password"
              [(ngModel)]="model.newPassword" required #newpassword="ngModel" class="form-control" />
            <div class="input-group-addon"><i class="fa fa-lock"></i></div>

          </div>
        </div>
        <div class="row custom-bottom-padding">

          <label class="label">
            Confirm Password<span [hidden]="model.confirmPassword" class="text-danger">
              *
            </span><span class="text-danger"
              *ngIf="(model.newPassword && model.confirmPassword) &&(model.newPassword!=model.confirmPassword)">
              (Confirm password must match new password) </span>
          </label>
          <div class="input-group">
            <input id="confirmpassword" type="password" name="confirmpassword" placeholder="&nbsp;Confirm Password"
              [(ngModel)]="model.confirmPassword" required #confirmpassword="ngModel" class="form-control" />
            <div class="input-group-addon"><i class="fa fa-lock"></i></div>

          </div>
        </div>
      </div>
    </div>
 
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane">
      <button type="submit" pButton icon="fa fa-check" class="btn btn-primary" (click)="onSubmit(changePswdForm)"
        label="Save"
        [disabled]="changePswdForm && (!changePswdForm.form.valid || isSubmitted||(model.newPassword!=model.confirmPassword) ||(model.newPassword && model.newPassword.length<3))"></button>
      <button type="button" pButton icon="fa fa-close" class="btn btn-default" (click)="btnCancelClick(changePswdForm)"
        label="Cancel" [disabled]="isSubmitted"></button>
    </div>
  </ng-template>
</p-dialog>
</form>
<form *ngIf="DisplayProfileDlg" #userProfileImgForm="ngForm">
  <p-dialog *ngIf="DisplayProfileDlg" id="userDisplayProfileImgDlg" [(visible)]="DisplayProfileDlg" appendTo="body"
    showEffect="fade" [modal]="true"  [style]="{'width': '600px','height':'45vh'}" [resizable]="false" 
    (onShow)="focusbtn(focuspro)">

    <!--appendTo="body" showEffect="fade" [modal]="true"-->
    <!--[modal]="true"-->
    <!--showEffect="fade"-->
    <ng-template pTemplate="header" >
      User Profile Picture
    </ng-template>

    <div id="dv" class="smart-form" style="height:45vh;overflow-y: auto;overflow-x: hidden;"  tabindex="0"
      (mouseover)="HideDummyProfileImage()" #focuspro>
      <div class="row">
        <div class="form-group col-sm-9">
          <div class="row" *ngIf="showProfile">
            <div class="col-md-12">
              <img [src]="ProfileCropperImg" *ngIf="showdummyProfileImage" height="120px" width="120px" style="position: absolute;left: 150px;">
              <image-cropper [imageChangedEvent]="imgChangeEvt" [imageBase64]="ProfileCropperImg"
                *ngIf="ProfileCropperImg || showProfile" [maintainAspectRatio]="true" [aspectRatio]="1/1"
                [resizeToWidth]="512" format="png,jpg,jpeg,gif" (imageCropped)="cropImg($event)" [imageQuality]="92"
                (imageLoaded)="imageLoadedPro($event)" (cropperReady)="cropperReady($event)" 
                 [cropperMinWidth]="240" [cropperMinHeight]="240"
                (loadImageFailed)="loadImageFailed($event)" roundCropper="true">
               
              </image-cropper>
             
            </div>

          </div>
          <div class="row">
            <div class="col-md-12" >
              
            </div>
          </div>
        </div>
        <div class="form-group col-sm-3">

          <label for="file-upload" class="custom-file-upload" id="uploadbtn" (click)=" getFile()">
            <i class="fa fa-cloud-upload"></i> Choose File
          </label>

        </div>
        <span style='height: 0px;width:0px; overflow:hidden;'> <input style="padding-left: 13px;display: none;"
            type="file" #takeInput  accept="image/gif, image/jpeg, image/png,.png, .jpg, .jpeg" (change)="onFileChange($event)"
            id="uppfile" ></span>
      </div>
      <div class="row">
        <div class="form-group col-sm-9">
          <div class="row">
            <div class="form-group col-sm-4"></div>
            <div class="form-group col-sm-4"></div>
            <div class="form-group col-sm-4"></div>
          </div>
        </div>
        <div class="form-group col-sm-3">

        </div>

      </div>
    </div>

    <ng-template pTemplate="footer">
      <div class="p-dialog-buttonpane">
        <button type="submit" pButton icon="fa fa-check" class="btn btn-primary"
          (click)="BtnCropProfile(userProfileImgForm)" label="Save"></button>
        <button type="submit" pButton icon="fa fa-trash" class="btn btn-primary" (click)="RemoveProfileImage()"
          label="Remove"></button>
        <!-- [disabled]="userProfileForm && (!userProfileForm.form.valid || isSubmitted) || _userInfo.PhoneNumber.length==0"></button> -->
        <button type="button" [disabled]="isProfileSubmit==true" pButton icon="fa fa-close" class="btn btn-default"
          (click)="btnCancelClickProDlg(userProfileImgForm)" label="Cancel"></button>
        <button id="btnproimg" type="submit" (click)="btnproLoadImage()" style="display: none;width: 100px;"></button>
        <button id="btnclks" type="submit"  style="display: none;width: 100px;">sss</button>
      </div>

    </ng-template>
  </p-dialog>
</form>
<form *ngIf="DisplaySignatureDlg" #userSignatureImgForm="ngForm">
  <p-dialog *ngIf="DisplaySignatureDlg" id="userDisplaySignatureImgDlg" [(visible)]="DisplaySignatureDlg"
    appendTo="body" showEffect="fade" [modal]="true" [style]="{width: '600px', height: '45vh'}" [resizable]="false"  
    style="overflow-y: auto; overflow-x: hidden;" (onShow)="focusSigbtn(focusSig)">

    <!--appendTo="body" showEffect="fade" [modal]="true"-->
    <!--[modal]="true"-->
    <!--showEffect="fade"-->
    <ng-template pTemplate="header" >
      User Signature Picture
    </ng-template>

    <div class="smart-form" style="height:45vh;overflow-y: auto;overflow-x: hidden;" (mouseover)="HideDummySignatureImage()" tabindex="0" #focusSig>
      <div class="row">
        <div class="form-group col-sm-9">
          <div class="row" id="signature" *ngIf="showSignature">
            
            <div class="col-md-12">
              <div style="position: absolute;left:150px;"><img [src]="SignatureCropperImg" *ngIf="showdummySignatureImage" height="50px" width="150px"></div>
              <image-cropper [imageChangedEvent]="imgChageEvtSig" [imageBase64]="SignatureCropperImg"
                *ngIf="SignatureCropperImg || showSignature" [maintainAspectRatio]="false" [aspectRatio]="4/3"
                [resizeToWidth]="256" format="png,jpg,jpeg,gif" (imageCropped)="cropImgSig($event)"
                (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady($event)" [cropperMinWidth]="360"
                [cropperMinHeight]="120" (loadImageFailed)="loadImageFailed($event)">
              </image-cropper>
              <span style="position: absolute;" > </span>
            </div>

          </div>
          <div class="row">
            <div class="col-md-12" >
              
            </div>
          </div>
          <!-- <image-cropper  *ngIf="SignatureCropperImg" [imageBase64]="SignatureCropperImg" [resizeToWidth]="200" resizeToHeight="200" format="bmp" [aspectRatio]="16 / 9" [maintainAspectRatio]="true"></image-cropper>
          <image-cropper [imageURL]="SignatureCropperImg" *ngIf="SignatureCropperImg" [resizeToWidth]="200" resizeToHeight="200" format="bmp" [aspectRatio]="16 / 9" [maintainAspectRatio]="true"></image-cropper> -->
          <!-- <img [src]="SignatureCropperImg" id="imagec" height="100px" width="100px" > -->
        </div>
        <div class="form-group col-sm-3">

          <label for="file-upload" class="custom-file-upload" id="uploadbtn" (click)=" getsFile()">
            <i class="fa fa-cloud-upload"></i> Choose File
          </label>

        </div>
        <span style='height: 0px;width:0px; overflow:hidden;'> <input style="padding-left: 13px;display: none;"
            type="file" #takeInput2 accept="image/gif, image/jpeg, image/png,.png, .jpg, .jpeg" (change)="onFileChangeSig($event)"
            id="upsfile"></span>
      </div>
      <div class="row">
        <div class="form-group col-sm-9">
          <div class="row">
            <div class="form-group col-sm-4"></div>
            <div class="form-group col-sm-4"> </div>
            <div class="form-group col-sm-4"></div>
          </div>
        </div>
        <div class="form-group col-sm-3">

        </div>

      </div>
    </div>

    <ng-template pTemplate="footer">
      <div class="p-dialog-buttonpane">
        <button type="submit" pButton icon="fa fa-check" class="btn btn-primary"
          (click)="BtnCropSignature(userSignatureImgForm)" label="Save"></button>
        <button type="submit" pButton icon="fa fa-trash" class="btn btn-primary" (click)="RemoveSignatureImage()"
          label="Remove"></button>
        <!-- [disabled]="userProfileForm && (!userProfileForm.form.valid || isSubmitted) || _userInfo.PhoneNumber.length==0"></button> -->
        <button type="button" [disabled]="isSignatureSubmit==true" pButton icon="fa fa-close" class="btn btn-default"
          (click)="btnCancelClickSigDlg(userSignatureImgForm)" label="Cancel"></button>
        <button id="btnimg" type="button" (click)="btnLoadImage()" style="display: none;width: 100px;"></button>
        <button id="btnclk" type="submit" style="display: none;width: 100px;">sss</button>
      </div>

    </ng-template>
  </p-dialog>
</form>
<form *ngIf="displayPersonalInfoDlg" #userProfileForm="ngForm">
  <p-dialog *ngIf="displayPersonalInfoDlg" id="userProfileDlg" [(visible)]="displayPersonalInfoDlg" appendTo="body"
    showEffect="fade" [modal]="true" [style]="{width: '800px'}" [resizable]="false" [closable]="false">

    <!--appendTo="body" showEffect="fade" [modal]="true"-->
    <!--[modal]="true"-->
    <!--showEffect="fade"-->
    <ng-template pTemplate="header" >
      User Profile Information
    </ng-template>

    <div>

      <div class="smart-form" style="height: 60vh;overflow-y: unset;">
        <div class="row">
          <div class="form-group col-sm-6">
            <!--[ngClass]="{userOfType=='Internal'?'col-sm-4':'col-sm-3' }"-->
            <fieldset>
              <label class="label">
                First Name<span [hidden]="_userInfo.FirstName.length>0" class="text-danger">
                  *
                </span>
              </label>
              <label class="input">
                <i class="icon-append fa fa-user"></i>
                <input id="fname" type="text" name="fname" placeholder="First name" [(ngModel)]="_userInfo.FirstName"
                  required>
              </label>
            </fieldset>
          </div>
          <div class="form-group col-sm-6">
            <!--[ngClass]="{userOfType=='Internal'?'col-sm-4':'col-sm-3' }"-->
            <fieldset>
              <label class="label">
                Last Name<span [hidden]="_userInfo.LastName.length>0" class="text-danger">
                  *
                </span>
              </label>
              <label class="input">
                <i class="icon-append fa fa-user"></i>
                <input id="lname" type="text" name="lname" [(ngModel)]="_userInfo.LastName" placeholder="Last name"
                  required>
              </label>
            </fieldset>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-6">
            <!--[ngClass]="{userOfType=='Internal'?'col-sm-4':'col-sm-3' }"-->
            <fieldset>
              <label class="label">
                Email<span [hidden]="_userInfo.Email.length>0" class="text-danger">
                  *
                </span>
              </label>
              <label class="input">
                <i class="icon-append fa fa-envelope-o"></i>
                <input type="email" id="email" name="email" [(ngModel)]="_userInfo.Email" placeholder="E-mail"
                  [disabled]="true">
              </label>
            </fieldset>
          </div>
          <div class="form-group col-sm-6">
            <!--[ngClass]="{userOfType=='Internal'?'col-sm-4':'col-sm-3' }"-->
            <fieldset>
              <label class="label">
                Phone<span class="text-danger">

                </span>
              </label>
              <label class="input">
                <i class="icon-append fa fa-phone"></i>
                <input type="text" id="PhoneNumber" name="PhoneNumber" [(ngModel)]="_userInfo.PhoneNumber"
                  placeholder="Phone Number" [disabled]="false">
              </label>
            </fieldset>
          </div>
        </div>
       
        <div class="row" style="padding-bottom: 40px;">
          <div class="form-group col-sm-2">

            <fieldset>
              <label class="label">
                Profile Image
              </label>
            </fieldset>
          </div>
          <div class="form-group col-sm-4">
            <img [src]="ProfilepreviewUrl" *ngIf="checkprofileEmpty" id="pid"
              style="margin-top: 21px;margin-right:82px;float: right;" class="avatar">
              <img src="assets/img/avatar.jpg" id="piddd" *ngIf="!checkprofileEmpty"
            style="margin-top: 21px;margin-right:72px;float: right;" class="avatar">
            <br />
            <span>
              <i class="fa fa-camera fa-x" style="margin-top: 1px;margin-left: 173px;"
                (click)="ShowProfileImageDialogue()"></i>
            </span>
          </div>
          <div class="form-group col-sm-6">
           
          </div>
        </div>
        <div class="row" style="padding-bottom: 40px;">
          <div class="form-group col-sm-2">

            <fieldset>
              <label class="label">
                Signature Image
              </label>
            </fieldset>
          </div>
          <div class="form-group col-sm-6">
            <!-- <img [src]="src/assets/img/ROAMLogo.png" id="sidd"   class="avatarRectangle" style="margin-top: 30px;margin-left:21px;"> -->
            <img [src]="SignaturepreviewUrl" *ngIf="checkSignatureEmpty" id="sid" class="avatarRectangle"
              style="margin-top: 30px;margin-left:21px;">
            <br /><span><i class="fa fa-camera fa-x" style="margin-top: 11px;margin-left: 174px;"
                (click)="ShowSignatureImageDialogue()"></i> </span>
          </div>
          <div class="form-group col-sm-4">

          </div>
        </div>


        <div class="row" style="padding-left: 15px;">
          <div class="form-group col-sm-12">
         <span class="label"> Image file with maximum 300kb of size is allowed.Image dimension for Signature should be 1 height * 3 width</span>
          </div>
          <hr style="width:70% ;">
        </div>

      </div>

    </div>

    <ng-template pTemplate="footer">
      <div class="p-dialog-buttonpane">
        <button type="submit" pButton icon="fa fa-check" class="btn btn-primary"
          (click)="onSubmitProfile(userProfileForm)" label="Save"
          [disabled]="_userInfo.LastName.length==0 || _userInfo.FirstName.length==0" ></button>
        <!-- [disabled]="userProfileForm && (!userProfileForm.form.valid || isSubmitted) || _userInfo.PhoneNumber.length==0"></button> -->
        <button type="button" pButton icon="fa fa-close" class="btn btn-default"
          (click)="btnProfileCancelClick(userProfileForm)" label="Cancel" [disabled]="isSubmitted"></button>
        <button id="btn" type="button" (click)="btnCancelClicks()" style="display: none;width: 100px;"></button>
        <span style="float: left;" ><span *ngIf="ExpiryDate" >User Expires on :{{ExpiryDate}}  </span><span *ngIf="LastLoggedIn">Last Login from: {{LastLoggedIn}} </span></span>
      </div>
    </ng-template>
  </p-dialog>
</form>
<div style="padding: 0;display: flex;" class="row col-md-10">
  <!-- Dots Menu on extreme Left -->
  <div [ngClass]="getSelectedClass()" style="padding: 0;">
    <nav id="menuList" (click)="onPanalsClick($event)">
      <!--
    NOTE: Notice the gaps after each icon usage <i></i>..
    Please note that these links work a bit different than
    traditional href="" links. See documentation for details.
    -->

      <ul>
        <li class="mainmenu" id="menudots" (click)="menuDotsClick()">
          <!--(focus)="menuDotsFocus()"-->
          <!--bg-color-msgreen-->
          <!--bg-color-black-->
          <a role="tab" aria-selected="true" [attr.tabindex]="0" class="tablIndexFocus" (keyup.enter)="menuDotsClick()"
            title="Main"><i class="glyphicon glyphicon-th"></i><span class="menu-item-parent"></span></a>
          <ul id="menudotsinner" class="mmetro leftpanal-window"
            [ngClass]="{'menu-visible': isMenuVisible,'menu-hide':!isMenuVisible}">
            <!--// add class here and remove on event-->
            <li class="mmetroliheader" style="margin-bottom:20px !important;">
              <div class="row" style="margin-top: 5px;">
                <div class="col-sm-8" style="margin-top:8px;">
                  <div style="margin-bottom: 18px;"> {{greetinglabel}}</div>
                  <div><label style="white-space: nowrap;width: 160px;overflow: hidden;text-overflow: ellipsis;line-height: 2;position: absolute;top: 12px;bottom: -17px;">{{name}}</label></div>
                </div>
                <div class="col-sm-4" style="position: absolute;left: 175px;bottom: -16px;">
                  <img [src]="ProfilepreviewUrl" *ngIf="checkprofileEmpty" height="60px" width="60px"
                    style="border-radius: 50px;">
                    <img src="assets/img/avatar.jpg" *ngIf="!checkprofileEmpty" height="60px" width="60px"
                    style="border-radius: 50px;">
                </div>
              </div>

              <!--<i  class="fa fa-gauge-high fa-2x" style="margin-left: 20px;color:#58ca54"></i>-->
            </li>
            <!-- <li (click)="navToDashboard()" *ngIf="isDashboardVisible">
              <a [attr.tabindex]="0" (keyup.enter)="navToDashboard()"
                class="jarvismetro-tile cubes bg-color-black tablIndexFocus">
                style="color:#f58231"
                <span class="iconbox">
                  <i class="fa fa-gauge-high fa-4x"></i>Dashboard
                </span>
              </a>
            </li> -->
            <li *ngFor="let parentItem of parentMenuList">
              <a [attr.tabindex]="0"
                [className]="'tablIndexFocus jarvismetro-tile cubes '+parentItem.ColorCode"
                (click)="parentClick(parentItem)"> <span class="iconbox"><i
                    [className]="parentItem.IconClass+' fa-4x'"></i>{{parentItem.Title}}</span></a>
            </li>
            <!-- <li class="mmetrolifooter" style="text-align: center">
                        <img [src]="tenantLogo" height="100" width="100">
                </li> -->
            <li class="mmetrolifooter noHover" style="left: 15px !important;">
              <a class="mmetrofooter" style="text-align: center"><img [src]="tenantLogo" height="50" width="150"
                  *ngIf="tenantLogo"></a>
              <a class="mmetrofooter"><span class="iconbox">{{tenantName}}</span></a>
            </li>
            <li class="metrolidivider" style="margin-bottom: 14px;"></li>
            <li class="mmetrolifooter" style="padding-left: 5px !important;margin-bottom: 8px; ">
              <a (keyup.enter)="showUserProfileDialogue()" [attr.tabindex]="0"
                class="mmetrofooter hand-cursor tablIndexFocus" (click)="showUserProfileDialogue()"><span
                  class="iconbox"><i class="fa fa-lg fa-fw fa-user" style="padding-right: 9px;"></i><u>U</u>ser
                  <u>P</u>rofile</span></a>
            </li>
            <li class="mmetrolifooter" style="padding-left: 5px !important;margin-bottom: 4px;">
              <a (keyup.enter)="showChangePswdDialogue()" [attr.tabindex]="0"
                class="mmetrofooter hand-cursor tablIndexFocus" (click)="showChangePswdDialogue()"><span
                  class="iconbox"><i class="fa fa-lock fa-4x" style="padding-right: 9px;"></i><u>C</u>hange
                  <u>P</u>assword</span></a>
            </li>

            <li class="mmetrolifooter" style="padding-left: 5px !important;">

              <!--<a routerLink="/logout" class="mmetrofooter" data-action="userLogout"><span class="iconbox"><i class="fa fa-sign-out fa-4x"></i><strong><u>L</u>ogout</strong></span></a>-->
              <a [attr.tabindex]="0" routerLink="/logout" class="mmetrofooter tablIndexFocus"><span class="iconbox"><i
                    class="fa fa-sign-out fa-4x"
                    style="padding-right: 5px;"></i><strong><u>L</u>ogout</strong></span></a>
            </li>
          </ul>

        </li>
      </ul>
    </nav>
  </div>
  <!-- Dots Menu on extreme Left -->
  <!-- all entire menu expect Dots -->
  <div id="MenuDropDown" class="menuList" style="overflow-x: auto;">
    <nav style="padding: 0;" id="menuList">
      <!--
    NOTE: Notice the gaps after each icon usage <i></i>..
    Please note that these links work a bit different than
    traditional href="" links. See documentation for details.
    -->
      <ul style="padding: 0;display: -webkit-box;">
        <!-- <li *ngIf="_sharedService.showPrevious" class="mainmenu">
          <a role="tab" (click)="statusBackClick()" aria-selected="true" [attr.tabindex]="0" class="tablIndexFocus "
            (keyup.enter)="statusBackClick()" title="Back">
            <i class="fa fa-angle-left"></i>
          </a>
        </li> -->
        <li *ngFor="let childItem of childMenuList">
          <!--Non Status Menus-->
          <a [attr.tabindex]="0" [routerLink]="childItem.PageURL" routerLinkActive="active"
            class="{{childItem.AdditionalData}} tablIndexFocus q"
            *ngIf="(childItem.Parent != '99' && childItem.Parent !='5f508e688a84b797ec4c7feb') && (childItem.Parent != 22 && childItem.Parent !='5e80972f8c488e5cd2ba5085')">
            <i [className]="childItem.IconClass">

            </i>
            <span class="menu-item-parent">
              {{childItem.Title}}
            </span>
          </a>
          <ul *ngIf="childItem.Childs" style="position: fixed !important;">
            <li *ngFor="let subMenuItem of childItem.Childs">
              <a [attr.tabindex]="0" class="tablIndexFocus" [routerLink]="subMenuItem.PageURL" routerLinkActive="active"
                *ngIf="(childItem.Parent != '99' && childItem.Parent !='5f508e688a84b797ec4c7feb') && (childItem.Parent != '22' && childItem.Parent !='5e80972f8c488e5cd2ba5085') ">
                <i [className]="subMenuItem.IconClass "></i>{{subMenuItem.Title}}
              </a>
            </li>
          </ul>
          <!--Non Status Menus-->
          <!--Repair Menus -->
          <a [attr.tabindex]="0" [routerLink]="childItem.PageURL" routerLinkActive="active"
            class="repair-menu {{childItem.AdditionalData}} tablIndexFocus q1"
            *ngIf="(childItem.Parent == '99' || childItem.Parent =='5f508e688a84b797ec4c7feb') &&(childItem.Parent != '22' || childItem.Parent !='5e80972f8c488e5cd2ba5085') && childItem.Title != 'MSR' && childItem.Title != 'Reports' && childItem.Title != 'Excel' && childItem.Title != 'CSV - Comma Delimited' && childItem.Title != 'CSV - Tab Delimited' && childItem.Title != 'PDF' && childItem.Title != 'XML'"
            style="display:none;">
            <i [className]="childItem.IconClass">

            </i>
            <span class="menu-item-parent">
              {{childItem.Title}}
            </span>
          </a>
          <ul *ngIf="childItem.Childs" style="position: fixed !important;">
            <li *ngFor="let subMenuItem of childItem.Childs">
              <a [attr.tabindex]="0" [routerLink]="subMenuItem.PageURL" routerLinkActive="active"
                class="repair-menu tablIndexFocus {{subMenuItem.AdditionalData}} q2"
                *ngIf="(childItem.Parent == '99' || childItem.Parent =='5f508e688a84b797ec4c7feb') && (childItem.Parent != '22' || childItem.Parent !='5e80972f8c488e5cd2ba5085') && childItem.Title != 'MSR' && childItem.Title != 'Reports' && childItem.Title != 'Excel' && childItem.Title != 'CSV - Comma Delimited' && childItem.Title != 'CSV - Tab Delimited' && childItem.Title != 'PDF' && childItem.Title != 'XML'"
                style="display:none;">
                <i [className]="subMenuItem.IconClass"></i>{{subMenuItem.Title}}
              </a>
            </li>
          </ul>
          <!--Repair Menus -->
          <!--Status Menus Except MSR-->
          <a [attr.tabindex]="0" [routerLink]="childItem.PageURL" routerLinkActive="active"
            class="status-menu {{childItem.AdditionalData}} tablIndexFocus q3"
            *ngIf="(childItem.Parent == '22' || childItem.Parent =='5e80972f8c488e5cd2ba5085') && childItem.Parent != '99' && childItem.Parent !='5f508e688a84b797ec4c7feb' &&  childItem.Title != 'MSR' && childItem.Title != 'Reports' && childItem.Title != 'Excel' && childItem.Title != 'CSV - Comma Delimited' && childItem.Title != 'CSV - Tab Delimited' && childItem.Title != 'PDF' && childItem.Title != 'XML'"
            style="display:none;">
            <i [className]="childItem.IconClass">

            </i>
            <span class="menu-item-parent">
              {{childItem.Title}}
            </span>
          </a>
          <ul *ngIf="childItem.Childs" style="position: fixed !important;">
            <li *ngFor="let subMenuItem of childItem.Childs">
              <a [attr.tabindex]="0" [routerLink]="subMenuItem.PageURL" routerLinkActive="active"
                class="status-menu tablIndexFocus {{subMenuItem.AdditionalData}}"
                *ngIf="(childItem.Parent == '22' || childItem.Parent =='5e80972f8c488e5cd2ba5085')  && childItem.Parent != '99' && childItem.Parent !='5f508e688a84b797ec4c7feb' &&  childItem.Title != 'MSR' && childItem.Title != 'Reports' && childItem.Title != 'Excel' && childItem.Title != 'CSV - Comma Delimited' && childItem.Title != 'CSV - Tab Delimited' && childItem.Title != 'PDF' && childItem.Title != 'XML'"
                style="display:none;">
                <i [className]="subMenuItem.IconClass"></i>{{subMenuItem.Title}}
              </a>
            </li>
          </ul>
          <!--Status Menus Except MSR-->
          <!--Dashboard, Export and (MSR)-->
          <a [attr.tabindex]="0" [routerLink]="childItem.PageURL" routerLinkActive="active"
            class="tablIndexFocus {{childItem.AdditionalData}} q4"
            *ngIf="(childItem.Parent == '22' || childItem.Parent =='5e80972f8c488e5cd2ba5085'|| childItem.Parent == '99' || childItem.Parent =='5f508e688a84b797ec4c7feb') &&  (childItem.Title == 'MSR' || childItem.Title == 'Reports' || childItem.Title == 'Dashboard')">
            <i [className]="childItem.IconClass">

            </i>
            <span class="menu-item-parent">
              {{childItem.Title}}
            </span>
          </a>
          <ul *ngIf="childItem.Childs" style="position: fixed !important;">
            <li *ngFor="let subMenuItem of childItem.Childs">
              <a [attr.tabindex]="0" [routerLink]="subMenuItem.PageURL" routerLinkActive="active"
                class="tablIndexFocus {{subMenuItem.AdditionalData}}"
                *ngIf="(childItem.Parent == '22' || childItem.Parent =='5e80972f8c488e5cd2ba5085'|| childItem.Parent == '99' || childItem.Parent =='5f508e688a84b797ec4c7feb') && (childItem.Title == 'MSR' || childItem.Title == 'Reports' || childItem.Title == 'Excel' || childItem.Title == 'CSV - Comma Delimited' || childItem.Title == 'CSV - Tab Delimited' || childItem.Title == 'PDF' || childItem.Title == 'XML')">
                <i [className]="subMenuItem.IconClass"></i>{{subMenuItem.Title}}
              </a>
            </li>
          </ul>
          <!--Dashboard, Export and (MSR)-->
        </li>
        <li *ngIf="isNextButton" class="mainmenu">
          <a role="tab" (click)="statusNextClick($event)" aria-selected="true" [attr.tabindex]="0"
            class="tablIndexFocus " (keyup.enter)="statusNextClick()" title="Next">
            <i class="fa fa-angle-right"></i>

          </a>
        </li>
      </ul>

    </nav>
  </div>
  <!-- all entire menu expect Dots -->
</div>