
//Components
import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
//Services
import { SharedService } from '../../../shared/services/shared.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { DateContainer, EditContainer } from './edit-container'
import { NgForm } from '@angular/forms';
import { ContainerService } from '../../services/container/container.service'
import { container, containerForEdit,containerForDateChange } from '../../services/container/container'
import { IConfig } from '../../../shared/helpers/config';
import { ClipboardModule } from 'ngx-clipboard';
import * as moment from 'moment/moment';
import { Subscription } from 'rxjs';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { GridOptions, GridApi, RowNode, ColDef } from "ag-grid-community";
import { ContainerQualityStatusService } from '../../../admin/services/containerqualitystatus/container-quality-status.service';
import { isNullOrUndefined } from '../../../shared/classes/nullorundefined';
import { stdin } from 'process';
var dateFormatF: string;
var dateFormatF: string;
var dateSeparatorS: string;
var dayD: number;
var monthM: number;
var yearY: number;
//import 'moment/locale/uk';
@Component({
    selector: 'edit-container',
    templateUrl: './edit-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ContainerService, ConfirmationService, ContainerQualityStatusService]
})

export class EditContainerComponent implements OnInit, OnDestroy {
    private dateConfig: IConfig[] = [];
    display: boolean = false;
    @Input() recordType: string;
    @Output() editDateShow: EventEmitter<any> = new EventEmitter();
    dialogTitle: string = "";
    selectedWOType: string;
    WOTypes: SelectItem[] = [];
    model: EditContainer;
    dateSeparator = "";
    dateFormat = "";
    dateFormatCalender = "";
    configuration: IConfig[] = [];
    private day: number;
    private month: number;
    private year: number;
    isSubmitted: boolean = false;
    boxTypeResults: string[] = [];
    boxTypeSuggestion: any[];

    boxLocationResults: string[] = [];
    boxLocationSuggestion: any[];

    scannedByResults: string[] = [];
    scannedBySuggestion: any[];

    boxIDResults: string[] = [];
    boxIDSuggestion: any[];

    isAuthorizedUser: boolean = false;
    isEditVisibleReadonly: boolean = false;
    isEditVisible: boolean = false;
    private userRole: string = localStorage.getItem("roles");
    isContainerAdmin: boolean = false;

    WorkOrderLabel: string = '';
    editContainerSub: Subscription;
    editContainerByObjectSub: Subscription;
    //#region delete Confirm Dialogue
    public displayDeleteConfirmDlg: boolean = false;
    docDeleteConfirmGridOptions: GridOptions;
    docDeleteConfirmColumnDefs: any[];
    docDeleteConfirmGridData: any[] = [];
    docDeleteConfirmGridDataComplete: any;
    public referenceKeyLabel: string = "";
    public appHotkeys: Hotkey[] = [];
    //#endregion
    CreatedOn: string;
    CreatedBy: string;
    ViewDateFormat: string;

    public canMXRQualityStatus: boolean = false;
    public canLOGQualityStatus: boolean = false;
    public canGENQualityStatus: boolean = false;
    public canDOCQualityStatus: boolean = false;
    public canBNDQualityStatus: boolean = false;
    public processMXRQualityStatusEnabled: boolean = false;
    public processLOGQualityStatusEnabled: boolean = false;
    public processGENQualityStatusEnabled: boolean = false;
    public processDOCQualityStatusEnabled: boolean = false;
    public processBNDQualityStatusEnabled: boolean = false;
    qualityStatusList: SelectItem[] = [];
    isAssetReadOnly: boolean = false;

    //#region Initialization of Component

    @ViewChild('focus') focusInput: ElementRef;

    constructor(public _sharedService: SharedService, private _router: Router, private cRef: ChangeDetectorRef,
        private _containerService: ContainerService, private _confirmationService: ConfirmationService, private _hotkeysService: HotkeysService, private _qualityStatusService: ContainerQualityStatusService) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        } else {
            //this.setHotKeys();
        }
    }
    ngOnInit() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        this.setHotKeys();
        this.referenceKeyLabel = this._sharedService.getReferenceKeyLabel();
        this.dateConfig = this._sharedService.getConfiguration();
        try {
            this.recordType = this._sharedService.recordType.toUpperCase();
        } catch (e) {
            console.log(e);
        }
        this.populateQualityStatusDropdown();
        this.setDefaultComponentValues();
        this.editContainerSub = this._sharedService._editContainer
        .subscribe({
            next: (response) => {
                this.showEditDialog();
            }
        })
        this.editContainerByObjectSub = this._sharedService._editContainerByObject
        .subscribe({
            next: (response) => {
                this.showEditDialog();
            }
        })

        //#region Delete Docs Details
        this.docDeleteConfirmGridOptions = <GridOptions>
            {
                rowSelection: 'single',
                rowData: [],
                onGridReady: () => {
                    this.setDeleteListingGridColumns();
                    if (this.docDeleteConfirmGridOptions.api != undefined) {
                        if (this.docDeleteConfirmGridData.length > 0) {
                            this.docDeleteConfirmGridOptions.api.hideOverlay();
                        }
                    }
                },
                onModelUpdated: (event: any) => {
                    try {

                        if (this.docDeleteConfirmGridOptions.api) {
                            //this.totalCount = this.gridOptions.api.getDisplayedRowCount();
                        }
                        this.docDeleteConfirmGridOptions.api.sizeColumnsToFit();
                    } catch (e) {
                        console.log(e);
                    }
                },
                pagination: true,
                paginationPageSize: 20,
                paginationAutoPageSize: true
            }
        this.getDateFormatInfo();
        //#endregion
    }

    setHotKeys() {
        if (this._router.url.indexOf("/transition/binders") != -1 || this._router.url.indexOf("/record/browse") != -1 || this._router.url.indexOf("/record/logs") != -1 || this._router.url.indexOf("/record/general") != -1 || this._router.url.indexOf("/record/documents") != -1 || this._router.url.indexOf("/record/search") != -1 || this._router.url.indexOf("/transition/search") != -1) {

            let hotKeyObj: Hotkey = null;

            hotKeyObj = new Hotkey('ctrl+e', (event: KeyboardEvent): boolean => {
                this.showEditDialog();
                return false; // Prevent bubbling
            });
            this._hotkeysService.add(hotKeyObj);
            this.appHotkeys.push(hotKeyObj);
            hotKeyObj = new Hotkey('enter', (event: KeyboardEvent): boolean => {
                this.showEditDialog();
                return false; // Prevent bubbling
            });

            this._hotkeysService.add(hotKeyObj);
            this.appHotkeys.push(hotKeyObj);
        }
    }

    ngOnDestroy() {
        if (this.editContainerSub) {
            this.editContainerSub.unsubscribe();
        }
        if (this.editContainerByObjectSub) {
            this.editContainerByObjectSub.unsubscribe();
        }
        this.appHotkeys.forEach(hotkey => {
            //console.log('removing hotkey obj => ' + JSON.stringify(hotkey));
            this._hotkeysService.remove(hotkey);
        });
        this.appHotkeys = [];
    }


    populateQualityStatusDropdown() {
        this._qualityStatusService.getActiveQualityStatusDataForDropDown(null)
        .subscribe({
            next: (response) => {
                this.qualityStatusList = response;
            }
        });
    }
    //#endregion

    // #region Form Events

    showEditDialog() {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }

        let isAssetSelected: string[] = this._sharedService.selectedAsset;
        if (this._sharedService.selectedAsset != undefined && isAssetSelected.length > 0) {
            this.isAssetReadOnly = this._sharedService.isCurrentAssetReadOnly();
            let selectedRow = this._sharedService.selectedContainer;
            if (selectedRow.ASN) {
                this.setCalenderDateFormat();
                // this.setWOType();
                this.dialogTitle = this.getDialogueTitle();
                this.setBoxTypeSuggestions();
                this.setBoxLocationSuggestions();
                this.setScannedBySuggestions();
                this.setBoxIDSuggestions();
                // this.checkUserRoles();
                this.UpdateRoles();
                this.resetModel(selectedRow);
            } else {
                if (selectedRow.length > 1) {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'warn', summary: 'Multi Row selected', detail: 'Please select single container row.' });
                } else {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'warn', summary: 'No Row selected', detail: 'Please select container row.' });
                }
            }
        } else {
            this._sharedService.clearToast();
            this._sharedService.showToast({ severity: 'warn', summary: 'No Asset selected', detail: 'Please select an Asset.' });
        }
    }


    onSubmit(form: NgForm) {
        this.changeButtonsStatus(true);
        //US-8181 //let assetInfo: string = this._sharedService.selectedAsset[0].data.split('|');
        let assetDataObj = JSON.parse(this._sharedService.selectedAsset[0].data);
        let body: containerForEdit =
        {
            ContainerID: this.model.containerID.toString(),
            Category: this.recordType,
            ASN: assetDataObj.ASN,//assetInfo[1],
            BoxID: this.model.boxid,
            BoxLocation: this.model.boxlocation,
            BoxType: this.model.boxtype,
            ID: this.model.generalID,
            Description: this.model.description,
            Status: this.model.isactive,
            Type: this.model.wotype,
            //ReferenceKey: this.model.wonumber,
            ReferenceKey: this.recordType == "LOG" ? this.setTechLogAnalyzerConfig() : this.model.wonumber,
            Live: this.model.islive ? 1 : 0,
            ContainerName: assetDataObj.StoragePath,//assetInfo[0],
            ScannedBy: this.model.scannedby,
            ScannedOn: this.getDate(this.model.scanneddate),
            Date: this.getDate(this.model.crsdate),
            EndDate: this.getDate(this.model.enddate),
            ModifiedBy: this.model.modifiedby,
            QualityStatusID: this.model.QualityStatusID
        }
        this._sharedService.showLoader("Processing...");
        this._containerService.updateContainerData(body)
        .subscribe({
            next: (response) => {
                this.changeButtonsStatus(false);
                this.display = false;
                response;
                this.setDefaultComponentValues();
                form.resetForm();
                //this.setDefaultComponentValues();
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'success', summary: 'Success', detail: 'Container updated succesfully.' });
                this._sharedService.onAssetChanged(this._sharedService.selectedAsset);
                this._sharedService.onAssetStatusChanged(this._sharedService.selectedAsset);
        
                this._sharedService.onBrowseContainerSelection(this._sharedService.selectedContainer);
                this._sharedService.isChangeSelectedContainer=true;
            },
            error: (error) => {
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                this._sharedService.showToast({ severity: 'error', summary: 'Error Occurred', detail: 'Please try again' });
                this.changeButtonsStatus(false);
            }
        });
    }

    btnCancelClick(cancelForm: NgForm) {
        this.display = false;
        this.setDefaultComponentValues();
        this.changeButtonsStatus(false);
        cancelForm.resetForm();
    }

    OnDateChange(form: NgForm)
    {
        let body: DateContainer = {
            ID : this.model.generalID,
            containerID : this.model.containerID,
            wotype: this.model.wotype,
            crsdate: this.model.crsdate,
            enddate: this.model.enddate,
            wonumber: this.model.wonumber,
            generalID: this.model.generalID,
            IncrementValue: this.model.IncrementValue,
            StartNumber: this.model.StartNumber,
            NumberOfPages: this.model.NumberOfPages,
        }
        this.editDateShow.emit(body);
        this.display = false;
        this.setDefaultComponentValues();
        this.changeButtonsStatus(false);
        form.resetForm();

    }
    // #endregion

    //#region Form Controls
    //setWOType() {
    //    this.WOTypes = [];
    //    let body: container = {
    //        Category: this.recordType
    //    }
    //    this._containerService.getContainerType(body).subscribe( response => {
    //        let res = response;
    //        for (let i = 0; i < res.length; i++) {
    //            this.WOTypes.push({ value: res[i].Type, label: res[i].Type })

    //        }

    //    });

    //}
    //#endregion

    // #region AutoComplete Box Type


    setBoxTypeSuggestions() {
        this.boxTypeResults = [];
        let body: container = {
            Category: this.recordType
        }
        this._containerService.getBoxType(body)
        .subscribe({
            next: (response) => {
                this.boxTypeResults = response;
                this.boxTypeSuggestion = this.boxTypeResults;
            },
            error: (error) => {
            }
        });

    }

    searchBoxType(event: any) {
        let filtered: any[] = [];
        for (let i = 0; i < this.boxTypeResults.length; i++) {
            let brand = this.boxTypeResults[i];
            if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
                filtered.push(brand);
            }
        }
        this.boxTypeSuggestion = filtered;
    }
    // #endregion

    // #region AutoComplete Box Location


    setBoxLocationSuggestions() {
        this.boxLocationSuggestion = [];
        let body: container = {
            Category: this.recordType
        }
        this._containerService.getBoxLocation(body)
        .subscribe({
            next: (response) => {
                this.boxLocationResults = response;
                this.boxLocationSuggestion = this.boxLocationResults;
            },
            error: (error) => {
            }
        });
    }

    searchBoxLocation(event: any) {
        let filtered: any[] = [];
        for (let i = 0; i < this.boxLocationResults.length; i++) {
            let brand = this.boxLocationResults[i];
            if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
                filtered.push(brand);
            }
        }
        this.boxLocationSuggestion = filtered;
    }
    // #endregion

    // #region AutoComplete Scanned By


    setScannedBySuggestions() {
        this.scannedBySuggestion = [];
        let body: container = {
            Category: this.recordType
        }
        this._containerService.getScannedBy(body)
        .subscribe({
            next: (response) => {
                this.scannedByResults = response;
                this.scannedBySuggestion = this.scannedByResults;
            },
            error: (error) => {
            }
        });

    }

    searchScannedBy(event: any) {
        let filtered: any[] = [];
        for (let i = 0; i < this.scannedByResults.length; i++) {
            let brand = this.scannedByResults[i];
            if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
                filtered.push(brand);
            }
        }
        this.scannedBySuggestion = filtered;
    }
    // #endregion
    // #region AutoComplete Box ID


    setBoxIDSuggestions() {
        this.boxIDResults = [];
        let body: container = {
            Category: this.recordType
        }
        this._containerService.getBoxID(body)
        .subscribe({
            next: (response) => {
                this.boxIDResults = response;
                this.boxIDSuggestion = this.boxIDResults;
            }
        });

    }

    searchBoxID(event: any) {
        let filtered: any[] = [];
        for (let i = 0; i < this.boxIDResults.length; i++) {
            let brand = this.boxIDResults[i];
            if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
                filtered.push(brand);
            }
        }
        this.boxIDSuggestion = filtered;
    }
    // #endregion
    // #region ID Manipulation (Business Rules)
    getID(): string {

        if (this.recordType == "MXR") {

            if (this.model.wonumber.length > 0) {
                return this.getPlainDate(this.model.crsdate) + "-" + this.removeSlashFromString(this.model.wonumber)
            }
            else {

                return this.getPlainDate(this.model.crsdate) + "-" + this.getPlainDate(this.model.enddate)

            }

        }
        else if (this.recordType == "LOG") {

            return this.getPlainDate(this.model.crsdate) + "-" + this.getPlainDate(this.model.enddate)

        }
        else if (this.recordType == "GEN") {
            // return this.model.generalID
            return this.getPlainDate(this.model.crsdate) + "-" + this.removeSlashFromString(this.model.generalID)
        }

    }
    // #endregion

    //#region Date Manipulation Helper Methods
    getPlainDate(date: Date): string {
        if (date != null) {
            var formatted = moment(date).format(this.dateFormat.toUpperCase());
            if (this.dateSeparator == "-") {
                let newStr = formatted.toString().replace(/-/g, "");
                return newStr;
            }
            else if (this.dateSeparator == "/") {
                let newStr = formatted.toString().replace(/\//g, "");
                return newStr;
            }
        }
        else {
            return "";

        }
    }

    setCalenderDateFormat() {
        this.configuration = this._sharedService.getConfiguration();
        this.dateFormat = this.configuration.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
        this.dateSeparator = this.configuration.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
        this.day = Number(this.configuration.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
        this.month = Number(this.configuration.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
        this.year = Number(this.configuration.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
        let dateParts: string[] = this.dateFormat.split(this.dateSeparator);

        if (this.year == 0) {
            this.dateFormatCalender = (dateParts[this.year].substr(0, 2) + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.day]).toLowerCase();
        }
        else if (this.year == 2) {
            if (this.month == 1) {
                this.dateFormatCalender = (dateParts[this.day] + this.dateSeparator + dateParts[this.month] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
            }
            else if (this.month == 0) {
                this.dateFormatCalender = (dateParts[this.month] + this.dateSeparator + dateParts[this.day] + this.dateSeparator + dateParts[this.year].substr(0, 2)).toLowerCase();
            }

        }
        else {
            this.dateFormatCalender = "yy-mm-dd"
        }

    }

    setDefaultComponentValues(): void {

        this.model = new EditContainer('',0, null, null, null, '', null, null, null, null, null, null, null, false, true, null, null, null, null, null);
        this.changeButtonsStatus(false);
    }

    //#endregion

    //#region User Roles

    UpdateRoles() {
        if (!this._sharedService.UserRole) {
            this._sharedService.updateRole();
        }
        this.isContainerAdmin = false;
        switch (this.recordType) {
            case 'MXR':
                this.isContainerAdmin = this._sharedService.UserRole.Records_MXR_Con_Admin;
                this.isEditVisibleReadonly = this._sharedService.UserRole.Records_MXR_Readonly;
                this.isEditVisible = this._sharedService.UserRole.Records_MXR_Con_Edit;
                this.WorkOrderLabel = this.referenceKeyLabel;
                this.canMXRQualityStatus = this._sharedService.UserRole.Records_MXR_Container_Quality_Status;
                this.processMXRQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations(
                    "Container_MX_Records_Quality_Process"
                );
                break;
            case 'LOG':///Updated Code by Waqar
                this.isContainerAdmin = this._sharedService.UserRole.Records_LOG_Con_Admin;
                this.isEditVisibleReadonly = this._sharedService.UserRole.Records_LOG_Readonly;
                this.isEditVisible = this._sharedService.UserRole.Records_LOG_Con_Edit;
                this.canLOGQualityStatus = this._sharedService.UserRole.Records_LOG_Container_Quality_Status;
                this.processLOGQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations(
                    "Container_Log_Books_Quality_Process"
                );
                break;
            case 'GEN':
                this.isContainerAdmin = this._sharedService.UserRole.Records_GEN_Con_Admin;
                this.isEditVisibleReadonly = this._sharedService.UserRole.Records_GEN_Readonly;
                this.isEditVisible = this._sharedService.UserRole.Records_GEN_Con_Edit;
                this.WorkOrderLabel = 'Short Title/Reference';
                this.canGENQualityStatus = this._sharedService.UserRole.Records_GEN_Container_Quality_Status;
                this.processGENQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations(
                    "Container_General_Quality_Process"
                );
                break;
            case 'DOC':
                this.isContainerAdmin = this._sharedService.UserRole.Records_DOC_Con_Admin;
                this.isEditVisibleReadonly = this._sharedService.UserRole.Records_DOC_Readonly;
                this.isEditVisible = this._sharedService.UserRole.Records_DOC_Con_Edit;
                //this.WorkOrderLabel = 'Short Title/Reference';
                this.canDOCQualityStatus = this._sharedService.UserRole.Records_DOC_Container_Quality_Status;
                this.processDOCQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations(
                    "Container_Documents_Quality_Process"
                );
                break;
            case 'BND':
                this.isContainerAdmin = this._sharedService.UserRole.Transition_BND_Con_Admin;
                this.isEditVisibleReadonly = this._sharedService.UserRole.Transition_BND_Readonly;
                this.isEditVisible = this._sharedService.UserRole.Transition_BND_Con_Edit;
                //this.WorkOrderLabel = 'Short Title/Reference';
                this.canBNDQualityStatus = this._sharedService.UserRole.Transition_BND_Container_Quality_Status;
                this.processBNDQualityStatusEnabled = this._sharedService.getQualityStatusConfigurations(
                    "Container_Binders_Quality_Process"
                );
                break;
        }


    }

    //#endregion

    resetModel(selectedRow: any) {


        let crsDate: Date;
        let endDate: Date;
        let scannedOn: Date;

        if (selectedRow.Date != null) {

            let dateString = selectedRow.Date.substr(0, 10);
            crsDate = this.getStringAsDate(dateString);
            //selectedRow.Date = moment(dateString).format(this.dateFormat.toUpperCase());

        }
        if (selectedRow.EndDate != null) {

            let dateString = selectedRow.EndDate.substr(0, 10);
            endDate = this.getStringAsDate(dateString);
            //selectedRow.EndDate = moment(dateString).format(this.dateFormat.toUpperCase());
        }
        if (selectedRow.ScannedOn != null) {

            let dateString = selectedRow.ScannedOn.substr(0, 10);
            scannedOn = this.getStringAsDate(dateString);
            //selectedRow.ScannedOn = moment(dateString).format(this.dateFormat.toUpperCase());
        }

        this.model = new EditContainer(selectedRow.ID, selectedRow.ContainerID, selectedRow.Type, crsDate, endDate, selectedRow.ReferenceKey, this.getContainerPath(selectedRow),
            selectedRow.Description, selectedRow.BoxType, selectedRow.BoxID, selectedRow.BoxLocation, scannedOn, selectedRow.ScannedBy, selectedRow.Live == 1 ? true : false, selectedRow.Status, this._sharedService.getCurrentUserName(), selectedRow.QualityStatusID,
            selectedRow.IncrementValue, selectedRow.StartNumber, selectedRow.NumberOfPages);

        this.model.isactive = selectedRow.Status;
        // this.model.createdby=result.CreatedBy;
        this.CreatedOn = this._sharedService.getDateFormatTimeDialogBottom(selectedRow.CreatedOn);
        this.CreatedBy = selectedRow.CreatedBy;
        this.ViewDateFormat = this._sharedService.getLabelTextDateFormat();
        this.display = true;
        this.cRef.markForCheck();
    }

    getContainerPath(selectedRow: any): string {
        //US-8181 //let assetInfo: string = this._sharedService.selectedAsset[0].data.split('|');
        //US-8181 //return `${assetInfo[0]}/${assetInfo[1]}/${selectedRow.Type}/${selectedRow.ID}`;

        let assetDataObj = JSON.parse(this._sharedService.selectedAsset[0].data);
        return `${assetDataObj.StoragePath}/${assetDataObj.ASN}/${selectedRow.Type}/${selectedRow.ID}`;
    }

    getDialogueTitle(): string {
        if (this.recordType == "MXR") {
            return "MX Records > Edit";
        }
        else if (this.recordType == "LOG") {
            return "Logs > Edit";
        }
        else if (this.recordType == "GEN") {
            return "General > Edit";
        }
        else if (this.recordType == "DOC") {
            return "Documents > Edit";
        }
        else if (this.recordType == "BND") {
            return "Binders > Edit";
        }
        else {
            return "Edit";
        }

    }

    getDate(date: Date): string {
        if (date != null) {
            //let newDate = moment(date).format(this.dateFormat.toUpperCase());
            // let newDate = moment(date).format("YYYY-MM-DD");
            //return new Date(newDate).toDateString();
            let typeCheck = date;
            if (typeCheck.toString().length == 10) {
                let dateString = date.toString().substring(0, 10);
                let dateParts = dateString.split(this.dateSeparator);
                let day = Number(dateParts[Number(this.day)].substring(0, 2));
                let month = Number(dateParts[Number(this.month)]) - 1;
                let year = Number(dateParts[Number(this.year)]);
                return new Date(year, month, day).toDateString();
            }
            else {
                let newDate = moment(date).format("YYYY-MM-DD");
                return new Date(newDate).toDateString();
            }
        }
        return null;
    }
    setTechLogAnalyzerConfig(): string {
        if (this.recordType == "LOG") {
            let _IncrementValue: string = '';
            let _StartNumber: string = '';
            let _NumberOfPages: string = '';

            _IncrementValue = isNullOrUndefined(this.model.IncrementValue) ? "" : this.model.IncrementValue.toString();
            _StartNumber = isNullOrUndefined(this.model.StartNumber) ? "" : this.model.StartNumber.toString();
            _NumberOfPages = isNullOrUndefined(this.model.NumberOfPages) ? "" : this.model.NumberOfPages.toString();

            return _IncrementValue + "-" + _StartNumber + "-" + _NumberOfPages;
            //return this.model.IncrementValue + "-" + this.model.StartNumber + "-" + this.model.NumberOfPages;
        }
    }
    changeButtonsStatus(status: boolean) {
        this.isSubmitted = status;
    }

    getStringAsDate(dateString: string): Date {

        let dateParts = moment(dateString).format(this.dateFormat.toUpperCase()).split(this.dateSeparator);
        let day = Number(dateParts[Number(this.day)].substring(0, 2));
        let month = Number(dateParts[Number(this.month)]) - 1;
        let year = Number(dateParts[Number(this.year)]);
        let cellDate = new Date(year, month, day);
        return cellDate;
    }


    //#region Delete Container
    confirmDeleteContainer(containerForm: NgForm) {
        let selectedRow = this._sharedService.selectedContainer;
        if (this._sharedService.docCount == 0) {
            this._confirmationService.confirm({
                key: "confirmdelete",
                message: "Are you sure you want to delete empty container?",
                accept: () => {
                    this.deleteContainer(containerForm);
                },
                reject: () => {

                }
            });
        } else {
            this._confirmationService.confirm({
                key: "confirmdelete",
                message: "You want to delete this container including all documents !",
                accept: () => {
                    this.reConfirmDeleteContainer(containerForm);
                },
                reject: () => {

                }
            });
        }
        this.cRef.markForCheck();
    }

    reConfirmDeleteContainer(containerForm: NgForm) {
        this._confirmationService.confirm({
            key: "reconfirmdelete",
            icon: "fa fa-exclamation-triangle",
            message: "There are (" + this._sharedService.docCount + ") documents in this container, all documents, and their communication links will be deleted.",
            accept: () => {
                this.deleteContainer(containerForm);
                this.cRef.markForCheck();
            },
            reject: () => {
                this.cRef.markForCheck();
            }
        });
    }


    deleteContainer(containerForm: NgForm) {
        this._sharedService.showLoader("Processing...");
        let selectedRow = this._sharedService.selectedContainer;
        this._containerService.deleteContainer(selectedRow)
        .subscribe({
            next: (response) => {
                this._sharedService.clearToast();
                let resp = response;
                this.display = false;
                this._sharedService.hideLoader();
                switch (resp.ResponseType) {
                    case 1: // Deleted Successfully
                        this.refreshConGrid();
                        this._sharedService.showToast({ severity: 'success', summary: 'Container deleted', detail: 'Container Deletion process initiated.' });
                        this.display = false;
                        this.setDefaultComponentValues();
                        this.changeButtonsStatus(false);
                        containerForm.resetForm();
        
                        break;
                    case 2:
                        //this._sharedService.hideLoader();
                        //this.confirmDeleteDocumentsMessage(resp.data);
                        break;
                    case 3: // Task Documents linkage Found
                        this.confirmDeleteDocumentsMessage(resp.ResponseBody);
                        break;
                    default:// Deleted Successfully
                        this.refreshConGrid();
                        this._sharedService.showToast({ severity: 'success', summary: 'Container deleted', detail: 'Container Deletion process initiated.' });
        
                        // this.display = false;
                        this.setDefaultComponentValues();
                        this.changeButtonsStatus(false);
                        containerForm.resetForm();
                        this.displayDeleteConfirmDlg = false;
                }
                this.cRef.markForCheck();
            },
            error: (error) => {
                console.log(error);
                //if (error.status == 409)
                //{
                //
                // //   this.refreshConGrid();
                //    this._sharedService.hideLoader();
                //    this._sharedService.showToast({ severity: 'warn', summary: 'Container not deleted', detail: 'Document(s) are attached to status row(s), please detach them before deleting.' });
        
                //    this.display = false;
                //    this.setDefaultComponentValues();
                //    this.changeButtonsStatus(false);
                //    containerForm.resetForm();
                //}
                //else
                //{
                this._sharedService.hideLoader();
                this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while deleting container.' });
                //  }
        
                this.cRef.markForCheck();
            }
        });
    }

    refreshConGrid() {
        this._sharedService.onAssetChanged(this._sharedService.selectedAsset);
        this._sharedService.containerSelectFirstRow=true;
    }
    removeSlashFromString(inputValue: string) {
        let outputValue = inputValue.replace(/[\/\\]/g, '');
        return outputValue;
    }
    //#endregion

    //#region Delete
    confirmDeleteDocumentsMessage(CompletelistOfIdsToDelete: any) {
        if (!this._sharedService.isAuthUser()) {
            this._router.navigate(['login']);
            this._sharedService.SessionExpiredMessage();
        }
        let documents: any = CompletelistOfIdsToDelete;
        this.docDeleteConfirmGridData = documents.BlobList;
        this.docDeleteConfirmGridDataComplete = documents;
        this.displayDeleteConfirmDlg = true;
    }
    setDeleteListingGridColumns() {
        this.docDeleteConfirmColumnDefs = [];
        this.docDeleteConfirmColumnDefs =
            [
                { headerName: 'Document Type', minWidth: 180, field: 'DocType' },
                { headerName: 'Number', field: 'DocNo', minWidth: 90 },
                { headerName: 'Work Order', field: 'ReferenceKey', minWidth: 110 },
                {
                    headerName: 'DocDate', field: 'DocDate', filter: 'agDateColumnFilter', minWidth: 100, valueFormatter: function (params: any) {
                        if (params.value != "" && params.value != null) {

                            let dateString = params.value.substring(0, 10);
                            return moment(dateString).format(dateFormatF.toUpperCase());
                        }
                    }
                },
            ];

    }
    getDateFormatInfo(): any {

        if (this.dateConfig != undefined) {
            dateFormatF = this.dateConfig.filter(x => x.Key == "DateFormat").map(({ Value }) => Value).toString();
            dateSeparatorS = this.dateConfig.filter(x => x.Key == "DateSeparater").map(({ Value }) => Value).toString();
            dayD = Number(this.dateConfig.filter(x => x.Key == "DayIndex").map(({ Value }) => Value));
            monthM = Number(this.dateConfig.filter(x => x.Key == "MonthIndex").map(({ Value }) => Value));
            yearY = Number(this.dateConfig.filter(x => x.Key == "YearIndex").map(({ Value }) => Value));
        }
        else {
            dateFormatF = localStorage.getItem('DateFormat');
            dateSeparatorS = localStorage.getItem('DateSeparater');
            dayD = Number(localStorage.getItem('DayIndex'));
            monthM = Number(localStorage.getItem('MonthIndex'));
            yearY = Number(localStorage.getItem('YearIndex'));

        }
    }
    deleteAllDocuments(data: boolean) {
        let blobList = {
            BlobList: this.docDeleteConfirmGridDataComplete.BlobList,
            TaskDocumentIds: this.docDeleteConfirmGridDataComplete.TaskDocumentIds,
            isCheckTaskDocument: data,
            ContainerID: this._sharedService.selectedContainer.ContainerID,
            ASN: this._sharedService.selectedContainer.ASN,
            Type: this._sharedService.selectedContainer.Type,
            ID: this._sharedService.selectedContainer.ID,
            ContainerName: this._sharedService.selectedContainer.ContainerName,
        };
        this.displayDeleteConfirmDlg = false;
        this._sharedService.showLoader("Processing...");
        this._containerService.deleteAllDocuments(blobList)
        .subscribe({
            next: (response) => {
                this._sharedService.hideLoader();
                this._sharedService.clearToast();
                let resp = response;
                switch (resp.ResponseType) {
                    case 1: // Deleted Successfully
                        this.refreshConGrid();
        
                        this._sharedService.showToast({ severity: 'success', summary: 'Container deleted', detail: 'Container Deletion process initiated.' });
        
                        break;
                    case 2:
                        this.refreshConGrid();
                        this._sharedService.hideLoader();
                        //this.confirmDeleteDocumentsMessage(resp.data);
                        break;
                    case 3: // Task Documents linkage Found
                        this.refreshConGrid();
                        this._sharedService.hideLoader();
                        this.confirmDeleteDocumentsMessage(resp.ResponseBody);
                        break;
                    default:// Deleted Successfully
                        this.refreshConGrid();
                        this._sharedService.showToast({ severity: 'success', summary: 'Container deleted', detail: 'Container Deletion process initiated' });
                }
            },
            error: (error) => {
                if (error.status == 501) {
                    this._sharedService.clearToast();
                    this._sharedService.showToast({ severity: 'error', summary: 'Error', detail: error.error });
                    this._sharedService.hideLoader();
                    this.refreshConGrid();
                }
                else {
                    console.log(error);
                    this._sharedService.hideLoader();
                    this._sharedService.showToast({ severity: 'error', summary: 'An error occured.', detail: 'An error occured while deleting container.' });
                    this.displayDeleteConfirmDlg = false;
                }
            }
        });
    }
    //#endregion

    applyFocusToInput() {

        this.focusInput.nativeElement.focus();
    }
    onKey() {

        this.display = false;
        this.setDefaultComponentValues();
        this.changeButtonsStatus(false);

    }

    onCloseDate() {
        var enddate: any = document.getElementsByClassName('enddate')[0].children[1]
        enddate.focus();
    }
    onCloseEnddate() {
        var desr = document.getElementById('desr');
        desr.focus();
    }
    onCloseScannedOn() {
        var scannedby = document.getElementById('scannedby');
        scannedby.focus();
    }
    isChecked: boolean = false
    ToggleChecked() {

        if (this.isChecked) {
            this.isChecked = false;
            this.model.islive = false;
        }
        else {
            this.isChecked = true;
            this.model.islive = true;
        }
    }
}
