import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AzureSearchKeyword } from 'src/app/admin/services/builderSearch/builderSearch';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
})
export class SearchFormComponent implements OnInit {

  public display=false;
  azureSearchKeywords: Array<AzureSearchKeyword>;
  keywordConditions: any[];
  _searchTypes: any[];
  public finalString=""
  public searchQuery=""
  public searchMode
  constructor() { }

  ngOnInit() {
    this.populateKeywordConditions();
  }
  showAddDialog(){
    this.display = true;
    this.azureSearchKeywords=[];
    this.populateKeywordConditions();
    this.populateSearchTypeDropdown();
  }
  btnCancelClick(cancelForm: NgForm) {
    this.display = false;
    cancelForm.resetForm();
    // this.resetModel();
  }

  addSearchKeyword() {
    
    let azureSearchKeyword = new AzureSearchKeyword();
    this.azureSearchKeywords.push(azureSearchKeyword);

}
populateKeywordConditions() {
  this.keywordConditions = [{ label: "AND", value: "AND" },
  { label: "OR", value: "OR" },
  ];
}
deleteInstance(objKeyWord: any) {

  let index: number = this.azureSearchKeywords.indexOf(objKeyWord);
  this.azureSearchKeywords.splice(index, 1);
  //let col: Column = new Column();
  //let col: Column = this.model.Columns.find(c => c.FieldOrder == fieldOrder);
  //    this.model.Conditions = this.model.Conditions.filter(c => c.Key != objCond.Key);
}
populateSearchTypeDropdown() {
  this._searchTypes = [
      { id: 1, searchType: "Exact" },
      { id: 4, searchType: "Partial" },
      { id: 2, searchType: "Begin" },
      { id: 3, searchType: "End" }

  ];
}
onSubmit(){
  this.finalString=this.searchQuery
  for(let i=0;i<this.azureSearchKeywords.length;i++){
    this.finalString=this.finalString + this.azureSearchKeywords[i].Condition
    this.finalString=this.finalString + this.azureSearchKeywords[i].PostFixValue
  }
  var a=this.finalString;
}
  // resetModel() {
  //   this.model = new AddContainerModel(
  //     null,
  //     null,
  //     null,
  //     null,
  //     "",
  //     null,
  //     null,
  //     null,
  //     null,
  //     null,
  //     null,
  //     null,
  //     false,
  //     true,
  //     "1"
  //   );
  //   this.lastIsDot = false;
  //   this.lastIsDotWonum = false;
  // }

}
