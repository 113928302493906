<section id="widget-grid" class="adminaircraftcls">
    
    <div class="row">
        <article class="col-sm-12 col-md-12 col-lg-12 sortable-grid p-sortable" id="col1">
            <!-- Widget ID (each widget will need unique ID)-->
            <div class="no-margin jarviswidget jarviswidget-sortable" id="wid-id-2" data-widget-colorbutton="false" data-widget-editbutton="false" data-widget-custombutton="false" data-widget-deletebutton="false" data-widget-togglebutton="false" role="widget">                 
               
                <header>
                    <span class="widget-icon"><i class="fa fa-plane"></i></span>
                    <h2>Asset</h2>
                    <!--<div class="jarviswidget-ctrls" role="menu">
                        <a class="button-icon" id="btnhidefilter" href="javascript:void(0);"><i class="glyphicon glyphicon-resize-horizontal"></i></a>
                    </div>-->
                    

                    <div class="jarviswidget-ctrls" title="Edit Asset (Ctrl+E)"> 
                        <a class="button-icon tablIndexFocus hand-cursor"  [attr.tabindex]="0"  (keyup.enter)="editAircraft(null)" id="btnMetaEdit" (click)="editAircraft(null)">
                            <i class="glyphicon glyphicon-pencil"></i>
                        </a>
                    </div>                    
                    <div class="jarviswidget-ctrls" role="menu" title="Add Asset (Ctrl+A)">
                        <a class="button-icon hand-cursor tablIndexFocus"  [attr.tabindex]="0"  id="btnadd" style="color: black;" routerLink="{{addAircraftPath}}">
                            <i class="glyphicon glyphicon-plus"></i>
                        </a>
                    </div>
                    
                    <div class="jarviswidget-ctrls hand-cursor" title="Create Container (Ctrl+C)"> 
                        <a class="button-icon tablIndexFocus"  [attr.tabindex]="0"  (keyup.enter)="createContainer()" id="btnCreateContainer" (click)="createContainer()">
                            <i class="glyphicon glyphicon-compressed"></i>
                        </a>
                    </div>
                    <download-template [templateType]="templateType"></download-template>
                    <upload-template [templateType]="templateType"  *ngIf="canUploadTemplate"></upload-template>
                    <div class="jarviswidget-ctrls">
                      <p-autoComplete class="aircraft-search" placeholder="Search Asset By Title"
                                      [field]="'Label'"
                                      [minLength]="3"
                                      [suggestions]="assetSeachOptions$ | async"
                                      (completeMethod)="search($event)"
                                      (onSelect)="onAirCraftSelect($event)"
                                      >
                      </p-autoComplete>
                    </div>
                </header>
                <!-- widget div-->
                <div class="widgetdiv">

                    <!-- widget edit box -->
                    <div class="jarviswidget-editbox">
                        <!-- This area used as dropdown edit box -->

                    </div>
                    <!-- end widget edit box -->
                    <!-- widget content -->
                    <div class="widget-body">
                        <!--
                        The ID "widget-grid" will start to initialize all widgets below
                        You do not need to use widgets if you dont want to. Simply remove
                        the <section></section> and you can use wells or panels instead
                        -->
                        <!-- widget grid -->
                        <section id="widget-grid2" class="">
                            <div class="jqgridboxadminaircraft">
                                <!--<div class="p-pg-button p-corner-all jqgridnavbtn">
                                    <a routerLink="{{addAircraftPath}}" tabindex="1" class="btn btn-primary btn-labeled">
                                        <span class="btn-label"><i class="fa fa-lg fa-fw fa-plane"></i></span>New Asset
                                    </a>
                                </div>-->

                                <ag-grid-angular id="agGridAircraft" [navigateToNextCell]="navigateToNextCell" #agGridAircraft [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="gridData" (rowDoubleClicked)='editAircraft($event)' filter=true sortable="true" style="width: 100%;" class="ag-theme-balham aircraftsgrid">
                                </ag-grid-angular>
                                <!-- end row -->
                            </div>
                        </section>
                        <!-- end widget grid -->
                    </div>
                    <!-- end widget content -->
                </div>
                <!-- end widget div -->
            </div>
            <!-- end widget -->
        </article>
        <!-- END COL -->
    </div>
</section>