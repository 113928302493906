import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from './../../../shared/services/shared.service';
import { ArticleService } from './../../services/article/article.service';
import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { articleModel } from '../articles/article';
import { IDateFormatInfo } from 'src/app/shared/helpers/dateFormatInfo';
import * as moment from 'moment/moment';
var _router: Router
var dateTimeConfig: IDateFormatInfo;

@Component({
  selector: 'app-article-view',
  templateUrl: './article-view.component.html',
  styles: []
})
export class ArticleViewComponent implements OnInit {

  // @ViewChild('content', {static: false}) content: SafeHtml;
  public content: SafeHtml;
  public sectionName = '';
  public articleDetail;
  public folder;
  public model: articleModel;
  public routId;
  public Article = [];
  public AllArticles;
  public SameSectionArticle: articleModel;


  constructor(private elem: ElementRef, private _article: ArticleService, private _sharedService: SharedService, private router: Router, private sanitizer: DomSanitizer, private route: ActivatedRoute) {

    if (!this._sharedService.isAuthUser()) {
      this.router.navigate(['login']);
    }
    else {
      this.updateRoles();
      _router = this.router;


    }
  }


  ngOnInit() {
    this.articleDetail = [];
    dateTimeConfig = this._sharedService.getDateTimeFormatConfigFromLocalStorage();
    this.route.params.subscribe(
      params => {
        let id = params['id'];
        this.ArticleById(id);

      }
    );
  }

  @HostListener('document:click', ['$event'])
  onclickAnchor(event) {

    if (event.target.matches('a')) {

      if (event.target.attributes["href"].nodeValue.indexOf('../article/') >= 0) {
        let hrf = event.target.attributes["href"].nodeValue;
        let newString = "";
        for (var i = hrf.length - 1; i >= 0; i--) {
          newString += hrf[i];
        }
        let index = newString.indexOf("/");
        let result = newString.substr(0, index);
        let aricleId = "";
        for (var i = result.length - 1; i >= 0; i--) {
          aricleId += result[i];
        }
        this.ArticleById(aricleId);
        this.router.navigate(["/help/article", aricleId]);

        return false;

      }

    }

  }

  redirectTOArticle(ArticleId) {
    _router.navigate(["/help/article", ArticleId]);

  }
  updateRoles() {
    if (!this._sharedService.UserRole) {
      this._sharedService.updateRole();
    }
  }

  ArticleById(id) {
    this.articleDetail = [];
    this._sharedService.showLoader("Processing...");
    this._article.getArticlebyId(id)
    .subscribe({
      next: (res) => {
        this.articleDetail = res;
        var modifiedDate = new Date(res.ModifiedOn).toString();
        this.articleDetail.ModifiedOn = moment(modifiedDate).format(dateTimeConfig.dateformat.toUpperCase() + " h:mm a").toString();
      
        var createdDate = new Date(res.CreatedOn).toString();
        this.articleDetail.CreatedOn = moment(createdDate).format(dateTimeConfig.dateformat.toUpperCase() + " h:mm a").toString();
      
        this.content = this.sanitizer.bypassSecurityTrustHtml(this.articleDetail.ArticleContentHtml);
        this.relatedArticles(this.articleDetail.ArticleSectionId);
        this._sharedService.hideLoader();
  },
      error: (error) => {
        console.log("something went wrong!");
        this._sharedService.hideLoader();
  }
  });
  }

  relatedArticles(ArticleSectionId) {
    this._article.relatedArticles(ArticleSectionId).subscribe(r => this.SameSectionArticle = r);
  }

}
