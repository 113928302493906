<!-- MAIN PANEL -->
<div role="main">

    <!-- MAIN CONTENT -->
    <div id="content" style="opacity: 1;">
        <section id="widget-grid-main" class="well" style="height:91vh;">
            <div id="content">
                <!-- row -->
                <div class="row">
                    <div class="col-sm-12">
                        <div class="well padding-10" style="background-color: #fff;margin-top: 20px;">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="row">
                                        <div class="col-md-11 padding-left-0">
                                            <h1 class="margin-top-0">
                                                ROAM Technical Support Portal
                                                <br>
                                            </h1>
                                            <p>

                                                Please email to <a href="mailto:support@roam.aero">support@roam.aero</a>
                                                for technical issues and support.
                                            </p>
                                            <!-- <a style="color: white;" class="btn btn-primary" href="https://roamtechsupport.zendesk.com/" target="_blank">Launch Portal </a> -->
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>